import {
    Targeting,
    TargetingReferences,
    useGetSeatReusableTargetingByIdQuery,
    useGetSeatReusableTargetingReferencesByIdQuery,
} from "@app/core/services";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

interface UseTargeting {
    targeting: Targeting;
    references: TargetingReferences[];
    isLoading: boolean;
    hasReferences: boolean;
    error?: FetchBaseQueryError | SerializedError;
    seatId: string;
    targetingId: string;
}

export const useGetTargetingAndReferences = (): UseTargeting => {
    const { seatId, targetingId } = useParams<{ seatId: string; targetingId: string }>();
    const {
        data: targeting,
        isLoading: isLoadingTargeting,
        error,
    } = useGetSeatReusableTargetingByIdQuery(Number(targetingId), {
        skip: !targetingId,
    });
    const { data: references = [], isLoading: isLoadingReferences } = useGetSeatReusableTargetingReferencesByIdQuery(
        Number(targetingId),
        { skip: !targeting }
    );
    const isLoading = useMemo(
        () => isLoadingTargeting || isLoadingReferences,
        [isLoadingTargeting, isLoadingReferences]
    );
    const hasReferences = useMemo(() => references?.length > 0, [references]);

    return {
        targeting: targeting as Targeting,
        references,
        isLoading,
        hasReferences,
        error,
        targetingId,
        seatId,
    };
};
