import { FC } from "react";
import { Col, Popover, Row, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import DOMPurify from "dompurify";
import { HelpArticle } from "@app/core/services/console";
import { TargetingDimensionTypes, TargetingDimensionTypeLabels } from "../../constants";
import { UsedDimensions } from "../../types";
import { brandCobalt } from "@rubicon/antd-components";
import { isEnrichmentTargetingDisabled } from "@app/features/targeting";

interface Props {
    type: TargetingDimensionTypes;
    label: string;
    isDisabled: boolean;
    usedDimensions: UsedDimensions;
    usedDimensionsAll: UsedDimensions[];
    helpContent?: HelpArticle;
    canAddIncludeAndExcludeRows?: boolean;
}

// TODO: How to reference these from ant instead of hardcoding?
const DROPDOWN_Z_INDEX = 1050;
const MAX_TOOLTIP_WIDTH_PX = 682;

const getDisabledTooltip = (
    type: TargetingDimensionTypes,
    label: string,
    usedDimensions: UsedDimensions,
    usedDimensionsAll: UsedDimensions[],
    canAddIncludeAndExcludeRows?: boolean
) => {
    if (type === TargetingDimensionTypes.Audiences) {
        if (usedDimensions.audiences.includes) {
            return `You can't select another Audience since you already have an include row`;
        }
        if (usedDimensionsAll.some((v) => isEnrichmentTargetingDisabled(v))) {
            return `You can't target multiple Audiences or mix Audience Targeting with ${TargetingDimensionTypeLabels.SegmentRules} Targeting.`;
        }
        return `You can't use Audience Targeting with ${TargetingDimensionTypeLabels.SegmentRules} Targeting`;
    }
    if (type === TargetingDimensionTypes.SegmentRules) {
        if (usedDimensions.segmentRules.includes && usedDimensions.segmentRules.excludes) {
            return `You can’t select another ${label} since you already have an include and exclude row`;
        }
        return `You can't use Audience Targeting with ${TargetingDimensionTypeLabels.SegmentRules} Targeting`;
    }
    if (canAddIncludeAndExcludeRows) {
        return `You can't select another ${label} since you already have an include and exclude row`;
    }
    return `You can’t select another ${label} since you can only have an include or exclude row and not both`;
};

export const DimensionMenuLabel: FC<Props> = ({
    type,
    label,
    helpContent = null,
    isDisabled,
    canAddIncludeAndExcludeRows,
    usedDimensions,
    usedDimensionsAll,
}) => (
    <Row justify="space-between">
        <Col>{label}</Col>
        {(helpContent || isDisabled) && (
            <Col>
                <Popover
                    zIndex={DROPDOWN_Z_INDEX + 1}
                    placement="right"
                    title={`${label} Targeting`}
                    content={
                        <div style={{ maxWidth: `${MAX_TOOLTIP_WIDTH_PX}px` }}>
                            {isDisabled && (
                                <Typography.Paragraph style={{ color: "red" }}>
                                    {getDisabledTooltip(
                                        type,
                                        label,
                                        usedDimensions,
                                        usedDimensionsAll,
                                        canAddIncludeAndExcludeRows
                                    )}
                                </Typography.Paragraph>
                            )}
                            {helpContent && (
                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(helpContent?.body) }} />
                            )}
                        </div>
                    }
                >
                    <InfoCircleOutlined style={{ color: brandCobalt }} />
                </Popover>
            </Col>
        )}
    </Row>
);
