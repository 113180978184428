import { FC } from "react";
import { Typography } from "antd";
import { infoColor } from "@rubicon/antd-components";

interface PercentageMetricProps {
    percentage: string;
}

export const PercentageMetric: FC<PercentageMetricProps> = ({ percentage }) => {
    return <Typography.Text style={{ color: infoColor }}>{`(${percentage}%)`}</Typography.Text>;
};
