import { Button } from "antd";
import { download } from "@rubicon/utils";
import { getAuditLogLabel } from "./helpers";
import { formatDateTime, formatEntity } from "@app/core/components/ChangeHistoryTable/ChangeLogTable";

export const AuditLogDownloadButton = ({ data }) => {
    const href = window.location.href;
    const onClick = () => {
        const csvData = data.map(({ element, lineage, id, elementType, userId, action, changeDate }) => ({
            name: getAuditLogLabel(element, lineage),
            id,
            userId,
            elementType: formatEntity(elementType),
            action,
            changeDate: formatDateTime(changeDate),
        }));
        download.csvFromArray(csvData, `Audit Log Results for ${href}`, {
            name: "Entity Name",
            id: "Record ID",
            userId: "User",
            elementType: "Entity",
            action: "Action",
            changeDate: "Date/Time",
        });
    };

    return <Button onClick={onClick}>Download Page Results</Button>;
};
