import { UpdateSeatSeparationGroupsPayload } from "@app/core/services";
import { InitialValuesType } from "./constants";

export const parseValueToApi = ({
    id,
    name,
    industries,
    iabCategories,
    mode,
}: InitialValuesType): UpdateSeatSeparationGroupsPayload => {
    return {
        id: id,
        name: name?.trim(),
        iabCategories: mode ? iabCategories.map((category) => ({ id: Number(category.value) })) : [],
        industries: !mode ? industries.map((industry) => ({ id: Number(industry.value) })) : [],
    };
};
