import { Form } from "antd";
import { useState } from "react";
import { CAPPING_MODES } from "../constants";
import { MarketplaceInfo } from "@app/core/services";

export const useAddFrequencyCappingItem = () => {
    const [minutes, setMinutes] = useState<number | null>(null);
    const [total, setTotal] = useState<number | null>(null);
    const [mode, setMode] = useState<keyof typeof CAPPING_MODES>("IMPRESSIONS");
    const [timing, setTiming] = useState<number>(1);
    const { setFieldValue, getFieldValue } = Form.useFormInstance();
    const handleAddItem = (): void => {
        if (!minutes || !total) return;
        const currentCappings: MarketplaceInfo["dealFreqCappings"] = getFieldValue("dealFreqCappings") || [];
        const fcapItem = {
            mode: CAPPING_MODES[mode],
            timeDurationMin: minutes * timing,
            total: Number(total),
            id: `internal_${currentCappings.length}`,
        };
        setFieldValue("dealFreqCappings", [...currentCappings, fcapItem]);
        setTotal(null);
        setMinutes(null);
    };
    const handleSetMinutes = (value: number): void => setMinutes(value);

    const handleSetTotal = (value: number): void => setTotal(value);

    return {
        handleAddItem,
        handleSetMinutes,
        handleSetTotal,
        setMode,
        setTiming,
        minutes,
        total,
        mode,
        timing,
    };
};
