import { FC } from "react";
import { Form, Input } from "antd";
import { Seat } from "@app/core/services";
import { NONE } from "@app/core/components/constants";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

interface DefaultBillingCodeProps {
    seat: Seat;
    edit?: boolean;
}

export const DefaultBillingCode: FC<DefaultBillingCodeProps> = ({ edit = false, seat }) => {
    const { defaultBillingCode } = seat;
    return (
        <Form.Item
            label={INVENTORY_FORM_FIELDS.DEFAULT_BILLING_CODE.label}
            name={INVENTORY_FORM_FIELDS.DEFAULT_BILLING_CODE.name}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 24, xxl: 20 }}
        >
            {edit ? (
                <Input placeholder={INVENTORY_FORM_FIELDS.DEFAULT_BILLING_CODE.placeholder} allowClear />
            ) : (
                defaultBillingCode || NONE
            )}
        </Form.Item>
    );
};
