import { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Auth0Login } from "@app/features/login/Auth0Login";
import { TokenExchangePage } from "@app/features/login/TokenExchange";
import { LoginPage } from "./LoginPage";

export const LoginSwitch: FC = () => {
    return (
        <Switch>
            <Route path="/login/magnite">
                <Auth0Login />
            </Route>
            <Route path="/login/token">
                <TokenExchangePage />
            </Route>
            <Route path="/login/:step">
                <LoginPage />
            </Route>
            <Redirect exact from="/login" to="/login/email">
                <LoginPage />
            </Redirect>
        </Switch>
    );
};
