import { uri } from "@rubicon/utils";
import { useEffect, useState } from "react";
import { useAppSelector } from "@app/core/store";
import { useSeatAuthContext } from "@app/core/auth";
import {
    InventoryDetailsDrawerType,
    selectInventoryDetailsDrawerType,
} from "@app/features/inventory/DetailsDrawer/reducer";
import { useHistory, useLocation } from "react-router-dom";
import { useNestedData } from "@app/features/inventory/DetailsDrawer/DetailsDrawerContent/useNestedData";
export const enum FloorAnalysisTabsType {
    estimatedFloorBreakdown = "estimatedFloorBreakdown",
    historicalFloorAnalysis = "historicalFloorAnalysis",
}

const enum FloorAnalysisTabsUrlSerachParams {
    activeTab = "activeTab",
}
interface UseTagParams {
    activeTab: FloorAnalysisTabsType;
    handleChangeTab: (activeTab: FloorAnalysisTabsType) => void;
    isLoading: boolean;
    title: string;
    breadcrumbs?: string;
}

export const useFloorAnalysisContent = (id: number | string): UseTagParams => {
    const { search } = useLocation();
    const history = useHistory();
    const { context } = useSeatAuthContext();
    const { nestedBreadcrumbs, isLoading } = useNestedData(id);
    const detailsDrawerType = useAppSelector(selectInventoryDetailsDrawerType);

    const isSeat = detailsDrawerType === InventoryDetailsDrawerType.SEAT;
    const title = isSeat ? `Seat Floor Analysis: ${String(context?.name)}` : "Floor Analysis";
    const breadcrumbs = isSeat ? undefined : nestedBreadcrumbs;

    const [activeTab, setActiveTab] = useState<FloorAnalysisTabsType>(
        (new URLSearchParams(search).get(FloorAnalysisTabsUrlSerachParams.activeTab) as FloorAnalysisTabsType) ||
            FloorAnalysisTabsType.estimatedFloorBreakdown
    );
    const handleChangeTab = (value: FloorAnalysisTabsType): void => {
        const nextSearchUpdated = uri.setSearchParam("", FloorAnalysisTabsUrlSerachParams.activeTab, value);
        history.push({ search: nextSearchUpdated });
    };

    useEffect((): void => {
        const urlSerachParams = new URLSearchParams(search);
        const savedActiveTab = urlSerachParams.get(FloorAnalysisTabsUrlSerachParams.activeTab);

        if (urlSerachParams.get(FloorAnalysisTabsUrlSerachParams.activeTab)) {
            setActiveTab(savedActiveTab as FloorAnalysisTabsType);
            return;
        }
        setActiveTab(FloorAnalysisTabsType.estimatedFloorBreakdown);
    }, [search]);

    return {
        title,
        isLoading,
        activeTab,
        breadcrumbs,
        handleChangeTab,
    };
};
