import { FC } from "react";
import { Drawer } from "antd";
import { SIDE_NAV_WIDTH } from "../constants";
import { css } from "@emotion/css";
import { SideDrawerLayout } from "./SideDrawerLayout";

interface Props {
    isOpen: boolean;
    handleClose: () => void;
    handleTypeClose: () => void;
}
export const SideDrawer: FC<Props> = ({ isOpen, handleClose, handleTypeClose, children }) => {
    return (
        <Drawer
            width="28rem"
            placement="left"
            open={isOpen}
            closable={false}
            onClose={handleClose}
            // Delay triggering handleMenuClose until the drawer is fully closed
            // so that the correct drawer state is shown while the close animation is playing
            afterOpenChange={(isOpen) => !isOpen && handleTypeClose()}
            rootClassName={css`
                z-index: 1020;
                left: ${SIDE_NAV_WIDTH};

                .ant-drawer-body {
                    padding-top: 0.8rem;
                }

                .ant-drawer-header-close-only {
                    padding: 0;
                }
            `}
        >
            <SideDrawerLayout closeHandler={handleClose}>{children}</SideDrawerLayout>
        </Drawer>
    );
};
