import { Form } from "antd";
import { LabeledValue } from "antd/es/select";
import { currency as currencyUtils } from "@rubicon/utils";
import { AFFILIATE_COST_MODEL } from "@app/features/inventory/constants";
import { INVENTORY_FORM_FIELDS, REVSHARE_PERCENTAGE_COST_MODEL } from "@app/features/inventory/InventorySeat/constants";

const { useWatch } = Form;

interface UseCostValue {
    name: string;
    label: string;
    toFixed: number;
    message: string;
    suffix: string;
    prefix: string | undefined;
}

export const useCostValue = (): UseCostValue => {
    const affiliateCostModel: LabeledValue = useWatch(AFFILIATE_COST_MODEL);
    const floorCurrency: LabeledValue = useWatch(INVENTORY_FORM_FIELDS.FLOOR_CURRENCY.name);

    const isRevsharePercentageCostModel: boolean = affiliateCostModel?.value === REVSHARE_PERCENTAGE_COST_MODEL.value;
    const currencyInfo = currencyUtils.getCurrencyInfo((floorCurrency?.label as string) || "USD");

    const suffix: string = isRevsharePercentageCostModel ? "%" : (floorCurrency?.label as string);
    const prefix: string | undefined = isRevsharePercentageCostModel ? undefined : currencyInfo?.symbol;

    const toFixed: number = isRevsharePercentageCostModel ? 2 : 3;

    const name: string = isRevsharePercentageCostModel
        ? INVENTORY_FORM_FIELDS.AFFILIATE_COST_VALUE_PERCENT.name
        : INVENTORY_FORM_FIELDS.AFFILIATE_COST_VALUE_FIXED.name;

    const message: string = isRevsharePercentageCostModel
        ? INVENTORY_FORM_FIELDS.AFFILIATE_COST_VALUE_PERCENT.rulesMessage
        : INVENTORY_FORM_FIELDS.AFFILIATE_COST_VALUE_FIXED.rulesMessage;

    return {
        name,
        prefix,
        suffix,
        message,
        toFixed,
        label: INVENTORY_FORM_FIELDS.AFFILIATE_COST_VALUE_PERCENT.label,
    };
};
