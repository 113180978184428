export const ADVANCED_FLOOR_LABELS = {
    floorName: "Floor Name",
    floorPrice: "Floor Price",
    lastUpdate: "Last Update",
    dateCreated: "Date Created",
};

export enum ADVANCED_FLOOR_SECTION_NAMES {
    GENERAL = "General",
    TARGETING = "Targeting",
}
