import { useNotificationError } from "@app/core/components/hooks";
import { LabelValue, useDeleteLabelValueMutation } from "@app/core/services";
import { Modal, Typography, notification } from "antd";
import { FC, useState } from "react";

export const CLASSIFICATIONS_LABEL_VALUES_DELETE_MODAL_SDET = "classifications-label-values-delete-modal";
export const CLASSIFICATIONS_LABEL_VALUES_DELETE_MODAL_PROPS = {
    destroyOnClose: true,
    "data-sdet": CLASSIFICATIONS_LABEL_VALUES_DELETE_MODAL_SDET,
    title: "Confirmation",
    okText: "Delete",
};

const initialState = {
    isOpen: false,
    labelValue: null,
    labelKey: "",
    bulkLabelValues: null,
};

export const useClassificationsLabelValuesDeleteModal = () => {
    const [state, setState] = useState<{
        isOpen: boolean;
        labelValue: LabelValue | null;
        labelKey: string;
        bulkLabelValues: number[] | null;
    }>(initialState);
    const [deleteClassificationsLabelValue, { isLoading, error }] = useDeleteLabelValueMutation();
    const handleOpenDeleteOne = (nextLabelValue: LabelValue, nextLabelKey: string) =>
        setState({ isOpen: true, labelValue: nextLabelValue, labelKey: nextLabelKey, bulkLabelValues: null });

    const handleOpenDeleteAll = (nextBulkLabelValues: number[], nextLabelKey: string) =>
        setState({ isOpen: true, bulkLabelValues: nextBulkLabelValues, labelKey: nextLabelKey, labelValue: null });

    const onCancel = () => setState(initialState);
    useNotificationError(error);
    const onOk = () => {
        if (state.labelValue) {
            deleteClassificationsLabelValue(state.labelValue.id)
                .unwrap()
                .then(() => {
                    notification.success({
                        message: (
                            <>
                                <Typography.Text strong>{state.labelValue?.value}</Typography.Text> has successfully
                                been deleted
                            </>
                        ),
                    });
                    onCancel();
                });
        } else if (state.bulkLabelValues) {
            const bulkDeletePromise = state.bulkLabelValues.map((id) => deleteClassificationsLabelValue(id).unwrap());
            Promise.all(bulkDeletePromise).then(() => {
                notification.success({
                    message: "All Label Values have successfully been deleted",
                });
                onCancel();
            });
        }
    };
    return {
        isOpen: state.isOpen,
        labelValue: state.labelValue,
        labelKey: state.labelKey,
        bulkLabelValues: state.bulkLabelValues,
        handleOpenDeleteOne,
        handleOpenDeleteAll,
        onCancel,
        onOk,
        isLoading,
    };
};

interface Props {
    isOpen: boolean;
    onCancel: () => void;
    onOk: () => void;
    isLoading: boolean;
    labelValue: LabelValue | null;
    labelKey: string;
}

export const ClassificationsLabelValuesDeleteModal: FC<Props> = ({
    onOk,
    isOpen,
    onCancel,
    isLoading,
    labelValue,
    labelKey,
}) => {
    return (
        <Modal
            {...CLASSIFICATIONS_LABEL_VALUES_DELETE_MODAL_PROPS}
            open={isOpen}
            onCancel={onCancel}
            onOk={onOk}
            okButtonProps={{ danger: true, loading: isLoading }}
        >
            Are you sure you want to delete{" "}
            {labelValue ? <Typography.Text strong>{labelValue.value}</Typography.Text> : "all label values"} from{" "}
            <Typography.Text strong>{labelKey}</Typography.Text>?
        </Modal>
    );
};
