import { RootState } from "@app/core/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface InventoryFloorAdvancedSearchFilter {
    keyword: string;
}

interface InventoryFloorAdvancedState {
    searchFilter: InventoryFloorAdvancedSearchFilter;
}

const initialState: InventoryFloorAdvancedState = {
    searchFilter: {
        keyword: "",
    },
};

const inventoryFloorAdvancedSlice = createSlice({
    name: "inventoryFloorAdvanced",
    initialState,
    reducers: {
        setSearchFilter: (state, action: PayloadAction<InventoryFloorAdvancedSearchFilter>) => {
            state.searchFilter = action.payload;
        },
        clearSearchFilter: (state) => {
            state.searchFilter = initialState.searchFilter;
        },
    },
});

export const selectInventoryFloorAdvancedSearchFilter = (state: RootState) => state.inventoryFloorAdvanced.searchFilter;

export const { setSearchFilter, clearSearchFilter } = inventoryFloorAdvancedSlice.actions;
export const inventoryFloorAdvancedReducer = inventoryFloorAdvancedSlice.reducer;
