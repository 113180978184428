import { Loading } from "@app/core/components";
import { TagParams } from "@app/features/inventory/TagParams";
import { css } from "@emotion/css";
import { Col, Flex } from "antd";
import { DetailsDrawerLayout } from "../../DetailsDrawerLayout";
import { useInventoryDetailsDrawerUrlId } from "../../useInventoryDetailsDrawerUrlId";
import { useTagParamsContent } from "./useTagParmasContent";
import { useUnitContent } from "../UnitContent/useUnitContent";

export const TagParamsContent = () => {
    const unitId = useInventoryDetailsDrawerUrlId();
    const unit = useUnitContent(unitId);
    const code = unit?.adUnitData?.code;
    const { title, breadcrumbs, isLoading } = useTagParamsContent(unitId);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Flex vertical>
            <DetailsDrawerLayout title={title} breadcrumbs={breadcrumbs} />
            <Col
                className={css`
                    padding: 1.5rem;
                    width: 100%;
                `}
            >
                <TagParams id={code ? code : unitId} />
            </Col>
        </Flex>
    );
};
