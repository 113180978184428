import { FC } from "react";
import { css } from "@emotion/css";
import { Loading } from "@app/core/components";
import { SeatAdSourcesPriorityCollapse } from "./SeatAdSourcesPriorityCollapse";
import { useSeatAdSourcesListGrid } from "./useSeatAdSourcesListGrid";
import { Col, Row } from "antd";
import { SeatAdSourcesFilterSearch } from "../SeatAdSourcesListFilterSearch";
import { SeatAdSourcesListViewRadio } from "../SeatAdSourcesListViewRadio";
import { NoDataAvailable } from "@app/core/components/LoadableComponent/NoDataAvailable";
import { SeatAdSourcesCurrencyFilters } from "../SeatAdSourcesCurrencyFilters";

export const SeatAdSourcesListGrid: FC = () => {
    const { filteredPriorityCandidates, allFilteredPrioritiesLoaded, hasAdSourcesToDisplay } =
        useSeatAdSourcesListGrid();

    return (
        <div
            data-sdet="seat-ad-sources-list-grid"
            style={{ minHeight: "300px", position: "relative" }}
            className={css`
                .ant-collapse > .ant-collapse-item > .ant-collapse-header {
                    padding: 0;
                }
                .ant-collapse-content > .ant-collapse-content-box {
                    padding: 16px 0;
                }
                .ant-empty-normal {
                    width: 100%;
                }
            `}
        >
            {!allFilteredPrioritiesLoaded && <Loading />}
            <Row gutter={[16, 16]}>
                <Col>
                    <SeatAdSourcesFilterSearch />
                </Col>
                <Col>
                    <SeatAdSourcesCurrencyFilters />
                </Col>
                <Col flex={1} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <SeatAdSourcesListViewRadio />
                </Col>
            </Row>
            {filteredPriorityCandidates.map((priority) => (
                <SeatAdSourcesPriorityCollapse
                    key={priority}
                    priority={priority}
                    allPrioritiesLoaded={allFilteredPrioritiesLoaded}
                />
            ))}
            {allFilteredPrioritiesLoaded && !hasAdSourcesToDisplay && <NoDataAvailable />}
        </div>
    );
};
