import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { useGetSeatsClientTypesQuery } from "@app/core/services";

interface UseClientType {
    options: LabeledValue[];
    isFetching: boolean;
}

export const useClientType = (): UseClientType => {
    const { data = [], isFetching } = useGetSeatsClientTypesQuery();
    const options = useMemo(
        () => (data || []).map((clientType) => ({ value: clientType.id, label: clientType.name })),
        [data]
    );
    return {
        isFetching,
        options,
    };
};
