import { useGetAdSourceAuctionTypesQuery } from "@app/core/services";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { LabeledValue } from "antd/lib/select";
import { useMemo } from "react";

interface UseAdSourceAuctionTypeField {
    options: LabeledValue[];
    isLoading: boolean;
    value: number;
    handleChange: (value: number) => void;
}

export const useAdSourceAuctionTypeField = (): UseAdSourceAuctionTypeField => {
    const dispatch = useAppDispatch();
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_AUCTION_TYPE]);
    const { data, isFetching } = useGetAdSourceAuctionTypesQuery();
    const options = useMemo(() => (data || []).map(({ name, id }) => ({ label: name, value: id })), [data]);

    const handleChange = (value: number) => {
        dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_AUCTION_TYPE, value }));
    };

    return {
        isLoading: isFetching,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value,
        options,
        handleChange,
    };
};
