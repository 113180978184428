import { useState } from "react";
import { HolderOutlined } from "@ant-design/icons";
import { Typography, Checkbox, Space } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { gray4, gray2 } from "@rubicon/antd-components";

export interface ColumnItem {
    column: string;
    label: string;
    checked: boolean;
}

const data: ColumnItem[] = [
    { column: "code", label: "Code", checked: true },
    { column: "requests", label: "Requests", checked: true },
    { column: "rejectedRequests", label: "Rejected Requests", checked: true },
    { column: "fillRate", label: "Fill Rate", checked: true },
    { column: "useRate", label: "Use Rate", checked: true },
    { column: "impressions", label: "Impressions", checked: true },
    { column: "fills", label: "Fills", checked: true },
    { column: "grossRevenue", label: "Gross Revenue", checked: true },
    { column: "netRevenue", label: "Net Revenue", checked: true },
    { column: "adPodRequest", label: "Ad Pod Request", checked: false },
    { column: "playlistRequest", label: "Playlist Request", checked: false },
    { column: "podSlotRequest", label: "Pod Slot Request", checked: false },
    { column: "avgFillTime", label: "Avg. Fill Time", checked: false },
    { column: "breaksPerPlaylist", label: "Breaks Per Playlist", checked: false },
    { column: "slotsPerBreak", label: "Slots Per Break", checked: false },
    { column: "slotsPerPlaylist", label: "Slots Per Playlist", checked: false },
];

const grid = 2;
const listBackgroundColor = gray2;
const itemColor = gray4;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? itemColor : "transparent",
    ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? listBackgroundColor : "transparent",
    padding: grid,
    width: "100%",
});

const reorder = (list: ColumnItem[], startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const ManageColumns = () => {
    const [items, setItems] = useState<ColumnItem[]>(data);

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const newItems = reorder(items, result.source.index, result.destination.index);
        setItems(newItems);
    };

    const handleChecked = (event) => {
        const key = event.target.value;
        const value = event.target.checked;
        setItems((items) => {
            const targetIndex = items.findIndex((el) => el.column === key);
            const newItems = [...items];
            newItems[targetIndex] = { ...items[targetIndex], checked: value };
            return newItems;
        });
    };

    return (
        <>
            <Typography.Paragraph>
                Select/De-select metrics to show and hide columns in table. Drag up and down a metric to order your
                columns
            </Typography.Paragraph>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {items.map((item, index) => (
                                <Draggable key={item.column} draggableId={item.column} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                        >
                                            <Space>
                                                <HolderOutlined />
                                                <Checkbox
                                                    checked={item.checked}
                                                    value={item.column}
                                                    onChange={handleChecked}
                                                />
                                                {item.label}
                                            </Space>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
};
