import { FileFormat, MomentRange } from "@app/core/utils/types";

export const enum VastErrorsEntityTypes {
    AdSource = "adSources",
    Seat = "seats",
    Publisher = "publishers",
    Brand = "brands",
    Supply = "supply",
    AdUnit = "adUnits",
}

export const enum VastErrorsUrlSearchParams {
    entityType = "supplyLevel",
    entityId = "entityId",
}

export interface VastErrorsApiParams {
    entityType: VastErrorsEntityTypes;
    entityId: number;
    ntimeFilter?: MomentRange;
}

export type VastErrorTotalsFileFormat = Extract<FileFormat, "csv" | "xlsx">;

export interface VastErrorTotalsApiParams {
    adSourceId: number;
    fileFormat: VastErrorTotalsFileFormat;
}

export interface VastErrors {
    errorCountsByNap: ErrorCount[];
    ntime: number;
    otime: number;
}

export interface ErrorCount {
    totalErrorCount: number;
    secure: boolean;
    errors: {
        [key: string]: number;
    };
    creativeId: string;
    networkId: number;
    networkName: string;
    adomain: string;
}

export interface VastErrorOffender {
    networkId: number;
    networkName: string;
    adomain: string;
    creativeId: string;
    errorCount: number;
}

export interface VastErrorDescription {
    errorCode: string;
    description: string;
}

export type VastErrorSum = { [key: number]: number };
