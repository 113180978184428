import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormRadioGroupChange } from "@app/features/deals/DealForm/reducer";
import { DealAdSourceTransparenceyMasked } from "@app/features/deals/DealForm/types";

interface UseAdSourceUserIdIfaField {
    onChange: (value: DealAdSourceTransparenceyMasked) => void;
    value?: DealAdSourceTransparenceyMasked | null;
}

export const useAdSourceUserIdIfaField = (): UseAdSourceUserIdIfaField => {
    const dispatch = useAppDispatch();
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_USER_ID]);
    const onChange = (value: DealAdSourceTransparenceyMasked) => {
        dispatch(dealFormRadioGroupChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_USER_ID, value }));
    };

    return {
        onChange,
        value,
    };
};
