import { RuleObject } from "antd/lib/form";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import moment from "moment-timezone";

export const targetDateValidator =
    (startDate: moment.Moment, endDate: moment.Moment) => (_: RuleObject, targetDate: moment.Moment) => {
        if (!targetDate) {
            return Promise.resolve();
        }
        if (!targetDate.isAfter(startDate) || !endDate.isAfter(targetDate)) {
            return Promise.reject(
                `${AD_SOURCE_FIELDS.TARGET_DATE.label} must be within the range of the start date and the end date`
            );
        }

        return Promise.resolve();
    };
