import { w3cwebsocket, IMessageEvent } from "websocket";
import { EventTypes, RawEventData } from "./types";

export const isSocketOpen = (socket: w3cwebsocket | null): socket is w3cwebsocket => {
    return Boolean(socket && socket.readyState === socket.OPEN);
};

export const parseMessageData = (message: IMessageEvent): RawEventData => {
    if (typeof message.data !== "string") {
        return { type: EventTypes.None, message: "" };
    }
    try {
        const eventData = JSON.parse(message.data) as RawEventData;
        return eventData;
    } catch (e) {
        // TODO: Something more robust
        console.error(e);
        return { type: EventTypes.None, message: "" };
    }
};
