import { InfoCircleOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Button, Collapse, Drawer } from "antd";
import { FC, useState } from "react";
import { DealsHelpKeys, DEAL_STATS_HELP_FIELDS } from "../../../constants";
import DOMPurify from "dompurify";
import { useHelpArticles } from "@app/core/components/hooks";
import { brandCobalt } from "@rubicon/antd-components";
import { DrawerCloseButton, SectionTitle } from "@app/core/components";

const configurationCollapseClassList = css`
    .ant-collapse-expand-icon {
        align-self: flex-end;
    }
`;

export const ChartsSidePanel: FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { helpArticles } = useHelpArticles(DealsHelpKeys);
    return (
        <>
            <Button
                onClick={() => setIsOpen(true)}
                type="link"
                style={{ color: brandCobalt, padding: 0, height: "fit-content" }}
                data-sdet="charts"
            >
                <InfoCircleOutlined />
                Charts
            </Button>
            <Drawer
                styles={{ body: { padding: 0 } }}
                width="40%"
                title="Charts"
                open={isOpen}
                onClose={() => setIsOpen(false)}
                closable={false}
                extra={<DrawerCloseButton onClose={() => setIsOpen(false)} />}
            >
                <Collapse
                    className={configurationCollapseClassList}
                    defaultActiveKey={Object.values(DEAL_STATS_HELP_FIELDS).map((field) => field.helpPath)}
                >
                    {Object.values(DEAL_STATS_HELP_FIELDS).map((field) => (
                        <Collapse.Panel key={field.helpPath} header={<SectionTitle title={field.label} />} forceRender>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(helpArticles[field.helpPath]?.body),
                                }}
                            />
                        </Collapse.Panel>
                    ))}
                </Collapse>
            </Drawer>
        </>
    );
};
