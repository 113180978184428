import { FC } from "react";
import { Form, Radio } from "antd";
import { CHANNEL_FORM_FIELDS, INVENTORY_CODE_TYPE_OPTIONS } from "@app/features/inventory/HierarchyForms/constants";
import { useCodeType } from "./useCodeType";

export const CodeType: FC = () => {
    const { code, isReadOnly } = useCodeType();
    if (isReadOnly) {
        return (
            <Form.Item name={CHANNEL_FORM_FIELDS.PUBLISHER_CODE.name} label={CHANNEL_FORM_FIELDS.PUBLISHER_CODE.label}>
                {code}
            </Form.Item>
        );
    }
    return (
        <Form.Item name={CHANNEL_FORM_FIELDS.CODE_TYPE.name} label={CHANNEL_FORM_FIELDS.CODE_TYPE.label}>
            <Radio.Group options={INVENTORY_CODE_TYPE_OPTIONS} />
        </Form.Item>
    );
};
