import { useMemo } from "react";
import { XYSeries } from "../types";
import { useSlidingXYSeries } from "./useSlidingXYSeries";
import { getWindowedXYSeriesAverages } from "./utils";

export const useSlidingXYSeriesAverages = (
    liveData: XYSeries | undefined,
    maxEntries: number,
    windowSize: number
): XYSeries[] => {
    // buffer the entry size so that we can still calculate an average for the first few entries
    const bufferedMaxEntrySize = maxEntries + windowSize;
    const slidingXYSeries = useSlidingXYSeries(liveData, bufferedMaxEntrySize);

    return useMemo(() => {
        const averagedXYSeries = getWindowedXYSeriesAverages(slidingXYSeries, windowSize);
        if (averagedXYSeries.length > maxEntries) {
            return averagedXYSeries.slice(-maxEntries);
        } else {
            return averagedXYSeries;
        }
    }, [slidingXYSeries, windowSize, maxEntries]);
};
