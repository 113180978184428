import { FC } from "react";
import { css } from "@emotion/css";
import { Row } from "antd";
import { AdSourceListItem } from "@app/core/services";
import { useConnectLiveAdSourcesAdStats } from "@app/features/seatAdSources/data";
import { SeatAdSourcesAdSourceCard } from "./SeatAdSourcesAdSourceCard";
import { Loading } from "@app/core/components";

interface Props {
    priority: number;
    adSources: AdSourceListItem[];
    isUpdatingPagination: boolean;
}

export const SeatAdSourcesPriorityCollapseContents: FC<Props> = ({ priority, adSources, isUpdatingPagination }) => {
    const liveAdSources = useConnectLiveAdSourcesAdStats(adSources, true);

    const renderCardsWhenPaginationApplied = () => {
        if (isUpdatingPagination) {
            return <Loading position="relative" />;
        } else {
            return liveAdSources.map((liveAdSource, index) => (
                <SeatAdSourcesAdSourceCard key={`adSourceCard-${priority}-${index}`} liveAdSource={liveAdSource} />
            ));
        }
    };

    return (
        <div
            data-sdet="seat-ad-sources-priority-collapse-contents"
            className={css`
                .ant-card {
                    width: 423px;
                    min-height: 167px;
                    border: 1px solid rgb(217, 217, 217);
                    .ant-card-head {
                        padding: 0 6px 0 16px;
                        min-height: auto;
                        .ant-card-head-title {
                            margin-right: 6px;
                        }
                        .ant-card-head-title,
                        .ant-card-extra {
                            padding: 6px 0;
                        }
                    }
                    .ant-card-body {
                        padding: 16px 6px 6px 16px;
                        .ant-tag:last-child {
                            margin-right: 0;
                        }
                    }
                }
            `}
        >
            <Row gutter={[16, 16]}>{renderCardsWhenPaginationApplied()}</Row>
        </div>
    );
};
