import { FC } from "react";
import { LimitInput } from "@app/features/inventory/components/Fields";
import { usePriceInput } from "@app/features/inventory/InventorySeat/SeatDefaultPricesPage/PriceInput/usePriceInput";
import { Price } from "@app/features/inventory/InventorySeat/SeatDefaultPricesPage/DefaultPricesTable/useDefaultPricesForm";

interface PriceInputProps {
    price: Price;
    onChange: (event: string | number) => void;
}

export const PriceInput: FC<PriceInputProps> = ({ onChange, price }) => {
    const { prefix, suffix, value, toFixed } = usePriceInput(price);
    return (
        <LimitInput
            value={value}
            suffix={suffix}
            prefix={prefix}
            toFixed={toFixed}
            onChange={onChange}
            style={{ width: "70%" }}
        />
    );
};
