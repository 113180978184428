import { RootState } from "@app/core/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DealDetailsDrawerTitles, DEAL_SECTION_KEYS } from "@app/features/deals/constants";
import { DealDetailsDrawerHeader } from "@app/features/deals/DealDetailsDrawer/DealDetailsDrawerHeader/DealDetailsDrawerHeaderTitleSwitch";

interface DealDetailsDrawerActionOpen {
    requestedDealId: number;
    openedFromDetailsView?: boolean;
    secondaryDetailsView?: DealDetailsDrawerTitles;
}
interface DealDetailsDrawerState {
    requestedDealId: number | null;
    openedFromDetailsView: boolean;
    secondaryDrawerHeader: DealDetailsDrawerHeader | null;
    secondaryDetailsView: DealDetailsDrawerTitles | null;
    collapse: {
        availableKeys: string[];
        keys: string[];
    };
}

const initialState: DealDetailsDrawerState = {
    requestedDealId: null,
    secondaryDetailsView: null,
    secondaryDrawerHeader: null,
    openedFromDetailsView: false,
    collapse: {
        availableKeys: [DEAL_SECTION_KEYS.DEAL_TERMS],
        keys: [DEAL_SECTION_KEYS.DEAL_TERMS], //INFO only 3 key is shown always, rest under condition
    },
};

const dealDetailsDrawerSlice = createSlice({
    name: "dealDetailsDrawer",
    initialState,
    reducers: {
        closeDrawer: (state) => {
            state.requestedDealId = null;
            state.secondaryDrawerHeader = null;
            state.secondaryDetailsView = null;
            state.openedFromDetailsView = false;
        },
        openDrawer: (state: DealDetailsDrawerState, action: PayloadAction<DealDetailsDrawerActionOpen>) => {
            const { requestedDealId, secondaryDetailsView, openedFromDetailsView } = action.payload;

            state.requestedDealId = requestedDealId;
            state.openedFromDetailsView = openedFromDetailsView || false;
            state.secondaryDrawerHeader = secondaryDetailsView ? DealDetailsDrawerHeader.Direct : null;
            state.secondaryDetailsView = secondaryDetailsView ?? null;
        },
        switchToDefaultView: (state) => {
            if (!state.openedFromDetailsView) {
                state.requestedDealId = null;
            }
            state.secondaryDrawerHeader = null;
            state.secondaryDetailsView = null;
            state.openedFromDetailsView = false;
        },
        switchToNestedView: (state, action: PayloadAction<DealDetailsDrawerTitles>) => {
            state.secondaryDrawerHeader = DealDetailsDrawerHeader.Nested;
            state.secondaryDetailsView = action.payload;
            state.openedFromDetailsView = true;
        },
        collapseAll: (state) => {
            state.collapse.keys = [];
        },
        expandAll: (state) => {
            state.collapse.keys = state.collapse.availableKeys;
        },
        collapseChange: (
            state,
            { payload: { keys, availableKeys } }: PayloadAction<{ keys: string[]; availableKeys?: string[] }>
        ) => {
            state.collapse.keys = keys;
            if (availableKeys) {
                state.collapse.availableKeys = availableKeys;
            }
        },
    },
});

export const selectDealRequestedId = (state: RootState) => state.dealDetailsDrawer.requestedDealId;
export const selectSecondaryDetailsView = (state: RootState) => state.dealDetailsDrawer.secondaryDetailsView;
export const selectSecondaryDrawerHeader = (state: RootState) => state.dealDetailsDrawer.secondaryDrawerHeader;
export const selectIsDealDetailsDrawerOpen = (state: RootState): boolean =>
    typeof state.dealDetailsDrawer.requestedDealId === "number";
export const selectCollapseKeys = (state: RootState): string[] => state.dealDetailsDrawer.collapse.keys;
export const selectCollapseAvailableKeys = (state: RootState): string[] =>
    state.dealDetailsDrawer.collapse.availableKeys;
export const selectIsCollapseExpandedAll = (state: RootState): boolean =>
    state.dealDetailsDrawer.collapse.availableKeys.length !== state.dealDetailsDrawer.collapse.keys.length;

export const {
    switchToNestedView,
    openDrawer,
    closeDrawer,
    switchToDefaultView,
    expandAll,
    collapseAll,
    collapseChange,
} = dealDetailsDrawerSlice.actions;
export default dealDetailsDrawerSlice.reducer;
