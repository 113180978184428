import { useState } from "react";

export const useAdSourceTargetingToggle = () => {
    const [showExistingAdSourceTargeting, setShowExistingAdSourceTargeting] = useState(false);

    return {
        showExistingAdSourceTargeting,
        setShowExistingAdSourceTargeting,
    };
};
