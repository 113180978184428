import { SeparationGroup } from "@app/core/services";
import { css } from "@emotion/css";
import { Card, Flex, Form, Tag } from "antd";
import { FC } from "react";

interface Props {
    separationGroup: SeparationGroup;
}
export const CompetitiveSeparationGroupsReadOnlyForm: FC<Props> = ({ separationGroup, children }) => (
    <Card
        type="inner"
        bordered={false}
        className={css`
            &.ant-card {
                box-shadow: none;
            }
        `}
    >
        <Flex justify="space-between">
            <Form layout="vertical">
                <Form.Item label="Name">{separationGroup.name}</Form.Item>
                <Form.Item label={`${separationGroup.industries.length ? "Industries" : "IAB Categories"}`}>
                    {[...separationGroup.iabCategories, ...separationGroup.industries].map(({ id, name }) => (
                        <Tag key={id}>{name}</Tag>
                    ))}
                </Form.Item>
            </Form>
            {children}
        </Flex>
    </Card>
);
