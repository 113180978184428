import { useLocation, useParams } from "react-router-dom";
import { DealDetailsDrawerHeader } from "./DealDetailsDrawerHeader/DealDetailsDrawerHeaderTitleSwitch";
import { DEAL_DETAILS_DRAWER_LOCATION } from "../constants";
import { convertSecondaryViewFromURL } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/normalizeSecondaryViewFromURL";
import { selectSecondaryDrawerHeader } from "./reducer";
import { useAppSelector } from "@app/core/store";

interface UseDealsDetailsDrawerTitle {
    title: DealDetailsDrawerHeader | null;
}

export const useDealsDetailsDrawerTitle = (): UseDealsDetailsDrawerTitle => {
    const { pathname } = useLocation();
    const secondaryHeader = useAppSelector(selectSecondaryDrawerHeader);
    const { secondaryView } = useParams<{ secondaryView: string }>();
    const convertedView = convertSecondaryViewFromURL(secondaryView);

    if (pathname.includes(DEAL_DETAILS_DRAWER_LOCATION.DEALS_DETAILS)) {
        return {
            title: secondaryHeader,
        };
    }

    const title = convertedView
        ? pathname.includes(DEAL_DETAILS_DRAWER_LOCATION.DETAILS_DRAWER) ||
          pathname.includes(DEAL_DETAILS_DRAWER_LOCATION.DEAL_DETAILS_DRAWER)
            ? DealDetailsDrawerHeader.Nested
            : DealDetailsDrawerHeader.Direct
        : null;
    return {
        title,
    };
};
