import { consoleApi, QUERY_RANGES_TAG } from "./console";

export interface QueryRange {
    creationDate: string;
    updateDate: string;
    id: number;
    code: string;
    name: string;
    sort: number;
    entityType: "QueryRange";
}

export const queryRangesApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getQueryRanges: builder.query<QueryRange[], void>({
            query: () => "queryRanges",
            providesTags: [QUERY_RANGES_TAG],
        }),
    }),
    overrideExisting: false,
});

export const { useGetQueryRangesQuery } = queryRangesApi;
