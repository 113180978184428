import { FC } from "react";
import { Dropdown, Menu, Button } from "antd";
import { MoreOutlined } from "@ant-design/icons";

interface Props {
    isDuplicateDisabled?: boolean;
    onClickDelete: () => void;
    onClickDownload: () => void;
    onClickDuplicate: () => void;
}

export const MoreMenu: FC<Props> = ({
    isDuplicateDisabled = false,
    onClickDelete,
    onClickDownload,
    onClickDuplicate,
}) => {
    return (
        <Dropdown
            trigger={["click"]}
            dropdownRender={() => (
                <Menu
                    items={[
                        {
                            label: "Duplicate",
                            key: "duplicate",
                            disabled: isDuplicateDisabled,
                            onClick: () => {
                                onClickDuplicate();
                            },
                        },
                        {
                            label: "Delete",
                            key: "delete",
                            onClick: () => {
                                onClickDelete();
                            },
                        },
                        {
                            label: "Download Selected Values",
                            key: "download",
                            onClick: () => {
                                onClickDownload();
                            },
                        },
                    ]}
                />
            )}
        >
            <Button icon={<MoreOutlined />} />
        </Dropdown>
    );
};
