import { FC } from "react";
import { Col, Row } from "antd";
import { FloorAnalysisFilters } from "@app/features/inventory/DetailsDrawer/DetailsDrawerContent/FloorAnalysisContent/FloorAnalysisFilters";
import { FloorAnalysisTabsType } from "@app/features/inventory/DetailsDrawer/DetailsDrawerContent/FloorAnalysisContent/useFloorAnalysisContent";
import {
    FloorAnalysisType,
    useFloorAnalysisFilters,
} from "@app/features/inventory/DetailsDrawer/DetailsDrawerContent/FloorAnalysisContent/FloorAnalysisFilters/useFloorAnalysisFilters";
import { HistoricalFloorAnalysisChart } from "@app/features/inventory/DetailsDrawer/DetailsDrawerContent/FloorAnalysisContent/HistoricalFloorAnalysis/HistoricalFloorAnalysisChart/HistoricalFloorAnalysisChart";
import { useHistoricalAnalysis } from "./useHistoricalAnalysis";
import { useHistoricalFloorAnalysisChart } from "./HistoricalFloorAnalysisChart/useHistoricalFloorAnalysisChart";
import { HistoricalQueriesResults } from "@app/core/services";

interface HistoricalFloorAnalysisProps {
    activeTab: FloorAnalysisTabsType;
    id: number | string;
}

export const HistoricalFloorAnalysis: FC<HistoricalFloorAnalysisProps> = ({ activeTab, id }) => {
    const {
        country,
        dateRange,
        adSourceType,
        isFetchingCountries,
        isFetchingQueryRanges,
        countriesOptions,
        queryRangesOptions,
        adSourceTypeOptions,
        handleChangeCountry,
        handleChangeDateRange,
        handleSearchCountries,
        handleChangeAdSourceType,
    } = useFloorAnalysisFilters(FloorAnalysisType.HIST);

    const { isFetching, isFetchingEstPrice, queryResults, handleRunAnalysis } = useHistoricalAnalysis({
        id,
        country,
        dateRange,
        adSourceType,
    });

    const { estPriceOptions, estPriceValue, handleChangeEstPrice, config, legendData, setLegendDataState } =
        useHistoricalFloorAnalysisChart(queryResults as HistoricalQueriesResults[], isFetching);

    return (
        <div data-sdet="historical-floor-analysis-stats">
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <FloorAnalysisFilters
                        isFetching={isFetching}
                        country={country}
                        activeTab={activeTab}
                        dateRange={dateRange}
                        adSourceType={adSourceType}
                        estPriceOptions={estPriceOptions}
                        estPriceValue={estPriceValue}
                        isFetchingEstPrice={isFetchingEstPrice}
                        countriesOptions={countriesOptions}
                        queryRangesOptions={queryRangesOptions}
                        adSourceTypeOptions={adSourceTypeOptions}
                        isFetchingCountries={isFetchingCountries}
                        handleChangeCountry={handleChangeCountry}
                        handleChangeEstPrice={handleChangeEstPrice}
                        handleRunAnalysis={handleRunAnalysis}
                        isFetchingQueryRanges={isFetchingQueryRanges}
                        handleSearchCountries={handleSearchCountries}
                        handleChangeDateRange={handleChangeDateRange}
                        handleChangeAdSourceType={handleChangeAdSourceType}
                    />
                </Col>
                <Col span={24}>
                    <HistoricalFloorAnalysisChart
                        isFetching={isFetching}
                        config={config}
                        legendData={legendData}
                        setLegendData={setLegendDataState}
                    />
                </Col>
            </Row>
        </div>
    );
};
