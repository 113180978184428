import { Input } from "antd";
import { FC } from "react";
import { css } from "@emotion/css";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAdSourceBundleIdOverrideField } from "./useAdSourceBundleIdOverrideField";
import { TransparencyFormItem } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceTransparencySection/Fields";

const className = css`
    width: 189px;
`;

export const AdSourceBundleIdOverrideField: FC = () => {
    const { handleBlur, handleChange, value } = useAdSourceBundleIdOverrideField();
    return (
        <TransparencyFormItem
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BUNDLE_ID_OVERRIDE}
            data-sdet="ad-source-bundle-id-override-field"
            label="Bundle ID Override"
            rules={[{ required: true, message: "Bundle ID Override is required", whitespace: true }]}
        >
            <Input
                className={className}
                placeholder="Bundle ID Override"
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                data-sdet="bundle-id"
            />
        </TransparencyFormItem>
    );
};
