import { FC } from "react";
import { AdSource } from "@app/core/services";
import { ReadOnlyAdSourceTargeting } from "@app/features/targeting/ReadOnlyTargeting/ReadOnlyAdSourceTargeting/ReadOnlyAdSourceTargeting";

interface Props {
    adSource: AdSource;
}

export const SeatAdSourcesTargetingDetails: FC<Props> = ({ adSource }) => {
    const dealsWithTargeting = adSource.marketplaceInfoList.filter(
        (marketPlaceInfo) => marketPlaceInfo.targeting.length > 0
    ).length;

    return (
        <ReadOnlyAdSourceTargeting
            targetingBlocks={adSource.targeting}
            targetingModeId={adSource.targetingMode.id}
            dealsWithTargeting={dealsWithTargeting}
        />
    );
};
