import { FC } from "react";
import { Button, Col, Row, Space } from "antd";
import { ResizableDrawer } from "@rubicon/antd-components";
import {
    CustomRuleIds,
    CustomRuleOperators,
    GroupConditions,
    TargetingDimensionTypeLabels,
} from "@app/features/targeting/constants";
import { CustomRuleGroup, CustomRuleTargetingDimension } from "@app/features/targeting/types";
import { ConditionTitle } from "../../ConditionTitle";
import { isCustomRuleGroupEmpty } from "../helpers";
import { CustomRuleDefinitionsAndTipsDrawerButton } from "./CustomRuleDefinitionsAndTipsDrawerButton";
import { CustomRuleGroupRow } from "./CustomRuleGroupRow";
import { useCustomRulesDrawer } from "./useCustomRulesDrawer";

interface Props {
    dimension: CustomRuleTargetingDimension;
    onChangeCondition: (path: number[], value: GroupConditions) => void;
    onChangeId: (path: number[], id: CustomRuleIds) => void;
    onChangeOperator: (path: number[], operator: CustomRuleOperators) => void;
    onChangeRuleGroup: (group: CustomRuleGroup | null) => void;
    onChangeValue: (path: number[], value: string | string[] | number | number[] | null) => void;
    onClickAddRule: (path: number[]) => void;
    onClickAddRuleGroup: (path: number[]) => void;
    onClickDeleteRule: (path: number[]) => void;
}

export const CustomRulesDrawerButton: FC<Props> = ({
    dimension,
    onChangeCondition,
    onChangeId,
    onChangeOperator,
    onChangeRuleGroup,
    onChangeValue,
    onClickAddRule,
    onClickAddRuleGroup,
    onClickDeleteRule,
}) => {
    const { handleApply, handleCancel, handleOpen, isOpen, isValidationShown } = useCustomRulesDrawer(
        onChangeRuleGroup,
        dimension.ruleGroup
    );

    return (
        <>
            <ResizableDrawer
                placement="right"
                width="70%"
                title={`${TargetingDimensionTypeLabels.CustomRules} - Specify Rule`}
                open={isOpen}
                onClose={handleCancel}
                footer={
                    <Row justify="end" gutter={8}>
                        <Col>
                            <Space>
                                <Button data-sdet="advanced-rule-drawer-cancel" onClick={handleCancel}>
                                    Cancel
                                </Button>
                                <Button data-sdet="advanced-rule-drawer-apply" type="primary" onClick={handleApply}>
                                    Apply
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                }
            >
                <Row align="top" justify="space-between">
                    <Col>
                        <Space align="start">
                            <ConditionTitle label="Custom Rule" condition={dimension.condition} />
                            <Button type="link" size="small" onClick={() => onClickDeleteRule([])}>
                                Clear All
                            </Button>
                        </Space>
                    </Col>
                    <Col>
                        <CustomRuleDefinitionsAndTipsDrawerButton />
                    </Col>
                </Row>
                <CustomRuleGroupRow
                    group={dimension.ruleGroup}
                    isValidationShown={isValidationShown}
                    onChangeCondition={onChangeCondition}
                    onChangeId={onChangeId}
                    onChangeOperator={onChangeOperator}
                    onChangeValue={onChangeValue}
                    onClickAddRule={onClickAddRule}
                    onClickAddRuleGroup={onClickAddRuleGroup}
                    onClickDeleteRule={onClickDeleteRule}
                    path={[]}
                />
            </ResizableDrawer>
            <Button type="link" onClick={() => handleOpen(dimension.ruleGroup)}>
                {isCustomRuleGroupEmpty(dimension.ruleGroup) ? "Specify" : "Edit"} Rule
            </Button>
        </>
    );
};
