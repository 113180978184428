import debounce from "lodash.debounce";
import { useParams } from "react-router-dom";
import { LabeledValue } from "antd/es/select";
import { useCallback, useMemo, useState } from "react";
import { useGetIndustriesQuery } from "@app/core/services";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
interface UseIndustries {
    isFetching: boolean;
    localValue: string;
    options: LabeledValue[];
    handleReset: () => void;
    handleChange: (v: string) => void;
}

export const useIndustries = (): UseIndustries => {
    const { seatId } = useParams<{ seatId: string }>();
    const [keyword, setKeyword] = useState<string>("");
    const [localValue, setLocalValue] = useState<string>("");

    const { data, isFetching } = useGetIndustriesQuery({ seatId: Number(seatId), params: { keyword } });

    const handleSearch = useCallback((value: string): void => {
        setKeyword(value);
    }, []);

    const debouncedHandleSearch = useMemo(
        () => debounce((value: string) => handleSearch(value), FILTER_INPUT_DEBOUNCE_TIME),
        [handleSearch]
    );

    const handleReset = (): void => setLocalValue("");

    const handleChange = (v: string): void => {
        setLocalValue(v);
        debouncedHandleSearch(v);
    };

    const options: LabeledValue[] = useMemo(
        () => (data || []).map(({ id, name }) => ({ value: id, label: name })),
        [data]
    );

    return {
        options,
        isFetching,
        localValue,
        handleReset,
        handleChange,
    };
};
