import { FC, useState } from "react";
import { gray10 } from "@rubicon/antd-components";
import {
    seatThirdPartyPixelMapper,
    publisherThirdPartyPixelMapper,
} from "@app/features/inventory/HierarchyForms/helpers";
import { Button, Col, Row, Tag, Typography } from "antd";
import {
    CHANNEL_FORM_FIELDS,
    CUSTOM_PIXELS_EMPTY_NOTIFICATION,
} from "@app/features/inventory/HierarchyForms/constants";
import { ResizableDrawer } from "@rubicon/antd-components";
import { ThirdPartyPixelAppendSource } from "@app/core/services";
import { usePublisherChannelFormInstance } from "@app/features/inventory/HierarchyForms/PublisherChannelForm/PublisherChannelFormInstance";

export const InheritedCustomPixelsDrawer: FC = () => {
    const [visible, setIsOpen] = useState(false);
    const { mode, seat, channel } = usePublisherChannelFormInstance();

    const thirdPartyPixels: ThirdPartyPixelAppendSource[] = [
        ...seatThirdPartyPixelMapper(seat),
        ...publisherThirdPartyPixelMapper(channel?.publisher),
    ];
    const isThirdPartyPixels = !!thirdPartyPixels.length;
    const isCreateMode: boolean = mode === "create";
    const onClose = (): void => setIsOpen(false);
    const handleClick = (): void => setIsOpen(true);

    if (isCreateMode) return <>{CHANNEL_FORM_FIELDS.CUSTOM_PIXELS.label}</>;

    return (
        <>
            <Row justify="space-between" align="middle" style={{ width: "100%" }}>
                <Col>
                    <Typography.Text style={{ color: gray10, paddingRight: "0.3rem" }}>
                        {CHANNEL_FORM_FIELDS.CUSTOM_PIXELS.label}
                    </Typography.Text>
                </Col>
                <Col>
                    <Button onClick={handleClick} type="link" size="small" style={{ padding: 0 }}>
                        View Inherited Custom Pixels
                    </Button>
                </Col>
            </Row>
            <ResizableDrawer
                width="30%"
                open={visible}
                placement="right"
                onClose={onClose}
                title="Inherited Custom Pixels"
                styles={{ body: { padding: "0.75rem 1.5rem" } }}
            >
                {isThirdPartyPixels
                    ? thirdPartyPixels.map(({ id, name, type, source }) => {
                          return (
                              <Tag key={id} color="processing">
                                  {name} [{type?.name}] [{"Source"}: {source} ]
                              </Tag>
                          );
                      })
                    : CUSTOM_PIXELS_EMPTY_NOTIFICATION}
            </ResizableDrawer>
        </>
    );
};
