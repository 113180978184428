import { useState, useMemo } from "react";
import { useGetAdUnitsMimesQuery } from "@app/core/services/console";
import { mimeTypeToLabelValue } from "../../../../helpers";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";

export const useDimensionsSelect = () => {
    const [search, setSearch] = useState("");
    const [, setKeyword] = useState("");

    const { data, isFetching } = useGetAdUnitsMimesQuery();
    const options = useMemo(() => mimeTypeToLabelValue(data || []), [data]);

    const debouncedHandleSearch = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        []
    );

    const handleChangeSearch = (search: string): void => {
        setSearch(search);
        debouncedHandleSearch(search);
    };

    return {
        handleChangeSearch,
        isLoading: isFetching,
        options,
        search,
    };
};
