import { FC, useEffect, useMemo, useState } from "react";
import { Col, Row, Space } from "antd";
import { AdStat, EntityTypes, useInventoryHealthStats } from "@app/features/adStats";
import { LiveStatsMetrics } from "../LiveStatsMetrics";
import { PerformanceStatsGraph } from "../PerformanceStatsGraph";
import { PerformanceStatsTypeButtons } from "../../PerformanceStatsTypeButtons";

interface Props {
    adStatsEntity: EntityTypes;
    id: number | string;
    handlePerformanceTypeSwitch: (isLiveActive: boolean) => void;
}

export const LiveStatsTabContent: FC<Props> = ({ adStatsEntity, id, handlePerformanceTypeSwitch }) => {
    const { setEntityIds, adStatsById } = useInventoryHealthStats(adStatsEntity);
    const adStat = useMemo(() => adStatsById[id], [adStatsById, id]);
    const [isExtended, setIsExtended] = useState(false);

    useEffect(() => setEntityIds([Number(id)]), [id, setEntityIds]);

    const handleExtend = () => {
        setIsExtended(!isExtended);
    };

    return (
        <Space direction="vertical" size={50} style={{ display: "flex" }}>
            <Row gutter={[16, 16]}>
                <Col span={isExtended ? 12 : 6}>
                    <LiveStatsMetrics handleExtend={handleExtend} isExtended={isExtended} adStat={adStat} />
                </Col>
                <Col span={isExtended ? 12 : 18}>
                    <Row
                        style={{
                            margin: "0 0 16px 0",
                            padding: "0 16px",
                        }}
                    >
                        <PerformanceStatsTypeButtons handlePerformanceTypeSwitch={handlePerformanceTypeSwitch} />
                    </Row>
                    <PerformanceStatsGraph adStat={adStat as AdStat} />
                </Col>
            </Row>
        </Space>
    );
};
