import { FC } from "react";
import { Button, Col, Row, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { CUSTOM_RULE_ID_OPTIONS, CustomRuleIds, CustomRuleOperators } from "@app/features/targeting/constants";
import { CustomRuleIdentifier } from "@app/features/targeting/types";
import { getCustomRuleOperatorOptions } from "../helpers";
import { CustomRuleInput } from "./CustomRuleInput";

interface Props {
    isValidationShown: boolean;
    onChangeId: (path: number[], id: CustomRuleIds) => void;
    onChangeOperator: (path: number[], operator: CustomRuleOperators) => void;
    onChangeValue: (path: number[], value: string | string[] | number | number[] | null) => void;
    onClickDeleteRule: (path: number[]) => void;
    path: number[];
    rule: CustomRuleIdentifier;
}

export const CustomRuleRow: FC<Props> = ({
    isValidationShown,
    onChangeId,
    onChangeOperator,
    onChangeValue,
    onClickDeleteRule,
    path,
    rule,
}) => {
    const onChangeTrimStringValues = (value: string | string[] | number | number[] | null) => {
        if (Array.isArray(value)) {
            const trimmedValue = value.map((value) => {
                if (typeof value === "string") {
                    return value.trim();
                }
                return value;
            });
            return onChangeValue(path, trimmedValue);
        }
        return onChangeValue(path, value);
    };

    return (
        <Row gutter={[8, 8]} align="middle">
            <Col flex="1">
                <Select
                    style={{ width: "100%" }}
                    onChange={(id: CustomRuleIds) => onChangeId(path, id)}
                    value={rule.id}
                    options={CUSTOM_RULE_ID_OPTIONS}
                />
            </Col>
            <Col flex="1">
                <Select
                    style={{ width: "100%" }}
                    onChange={(operator: CustomRuleOperators) => onChangeOperator(path, operator)}
                    value={rule.operator}
                    options={getCustomRuleOperatorOptions(rule.id)}
                />
            </Col>
            <Col flex="3">
                <CustomRuleInput
                    input={rule.input}
                    isValidationShown={isValidationShown}
                    onChange={(v) => onChangeTrimStringValues(v)}
                    operator={rule.operator}
                    style={{ width: "100%" }}
                    value={rule.value}
                />
            </Col>
            <Col>
                <Button icon={<DeleteOutlined />} onClick={() => onClickDeleteRule(path)} />
            </Col>
        </Row>
    );
};
