import { FC } from "react";
import { Form } from "antd";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const AdvertisersAsAllowList: FC = () => {
    return (
        <Form.Item {...CHANNEL_FORM_FIELDS.ADVERTISERS_AS_ALLOW_LIST}>
            <BooleanRadioGroup
                trueLabel={CHANNEL_FORM_FIELDS.ADVERTISERS_AS_ALLOW_LIST.trueLabel}
                falseLabel={CHANNEL_FORM_FIELDS.ADVERTISERS_AS_ALLOW_LIST.falseLabel}
            />
        </Form.Item>
    );
};
