export const TARGETING_SECTIONS_NAME = {
    GENERAL: "General",
    TARGETING: "Targeting",
    USED_IN: "Targeting Used In",
} as const;
export const TARGETING_FORM_ITEMS = {
    [TARGETING_SECTIONS_NAME.GENERAL]: {
        NAME: {
            name: "name",
            label: "Name",
        },
        PARENT: {
            name: "parent",
            label: "Parent",
        },
    },
};

export const TARGETING_HELP_KEYS = {
    [TARGETING_SECTIONS_NAME.GENERAL]: [
        {
            key: "parent",
            label: "Targeting Parent",
            helpPath: "reusabletargeting.parent",
        },
        {
            key: "name",
            label: "Name",
            helpPath: "name",
        },
    ],
    [TARGETING_SECTIONS_NAME.TARGETING]: [
        {
            key: "targeting",
            label: "Targeting Blocks",
            helpPath: "targeting.overview",
        },
    ],
};

export interface TargetingFormValues {
    parent: string | undefined;
    name: string | null;
}

export const TARGETING_DIMENSION_MESSAGE = "At least one targeting dimension should be selected";
