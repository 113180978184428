import { FC } from "react";
import { IabCategorieTree } from "@app/features/inventory/components/FormItems";
import { BRAND_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const BlockedCategories: FC = () => {
    return (
        <IabCategorieTree
            name={BRAND_FORM_FIELDS.BLOCKED_CATEGORIES.name}
            label={BRAND_FORM_FIELDS.BLOCKED_CATEGORIES.label}
            placeholder={BRAND_FORM_FIELDS.BLOCKED_CATEGORIES.placeholder}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 22, xxl: 22 }}
        />
    );
};
