import { FC } from "react";
import { CollapsePanelProps, Input, Typography } from "antd";
import { ExpandCollapse } from "@rubicon/antd-components";
import { PMP_ANY_VALUE, PMP_ALL_VALUE } from "../constants";

export interface ReadOnlyPmpPanelProps extends CollapsePanelProps {
    targets: string[];
}

export const ReadOnlyPmpPanel: FC<ReadOnlyPmpPanelProps> = ({ targets, ...rest }) => {
    if (!targets.length) {
        return null;
    }
    const [pmp] = targets;
    const isAll = targets.length === 1 && pmp === PMP_ALL_VALUE;
    const isAny = targets.length <= 2 && pmp === PMP_ANY_VALUE;

    if (isAll) {
        return null;
    }

    return (
        <ExpandCollapse.Panel {...rest}>
            {isAny ? (
                <Typography.Text>Inbound PMP Deals</Typography.Text>
            ) : (
                <Input.TextArea disabled value={targets.join("\n")} />
            )}
        </ExpandCollapse.Panel>
    );
};
