import { useUserAccess } from "@app/core/auth";
import {
    SeatHelpKeys,
    PublisherDrawerTitles,
    publisherChannelHelpKeys,
    BrandChannelTitles,
    brandChannelHelpKeys,
} from "@app/features/inventory/InventorySeat/constants";
import { DefinationField } from "@app/core/components/HelpKeysDrawer";
import { filterDefinitionsFieldsByHelpPathKeys } from "@app/features/inventory/helpers";

interface UsePublisherChannelFormDefinitionsFields {
    generalDefinitionsFields: DefinationField[];
    defaultsDefinitionFields: DefinationField[];
    labelsDefinitionsFields: DefinationField[];
    adPodDefinitionFields: DefinationField[];
    customPixelsDefinitionFields: DefinationField[];
    adBreakRulesDefinitionFields: DefinationField[];
    brandSafetyDefinitionFields: DefinationField[];
    playlistConfigurationDefinitionFields: DefinationField[];
    detailConfigurationDefinitionsFields: DefinationField[];
}

const GENERAL_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS: string[] = [
    SeatHelpKeys.SeatAccountType,
    SeatHelpKeys.PublisherBillingCode,
    SeatHelpKeys.Code,
];

const DEFAULTS_HELP_PATH_KEYS = [SeatHelpKeys.SupplySsliveStream];

const DETAILED_CONFIGURATION_HELP_PATH_KEYS: string[] = [
    SeatHelpKeys.SeatBusinessDomain,
    SeatHelpKeys.SeatEnableAdvertisersAsWhitelist,
    SeatHelpKeys.SupplyEnableNoAdCookieSync,
    SeatHelpKeys.AdUnitPublisherReauction,
];

const LABELS_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS: string[] = [SeatHelpKeys.GlobalLabels];
export const usePublisherChannelFormDefinitionsFields = (): UsePublisherChannelFormDefinitionsFields => {
    const { isSysAdmin, isPubAcctMgr, hasInternalAccess, isTremorUser } = useUserAccess();

    const generalDefinitionsFields: DefinationField[] =
        isSysAdmin || isPubAcctMgr
            ? publisherChannelHelpKeys[PublisherDrawerTitles.General]
            : filterDefinitionsFieldsByHelpPathKeys(
                  publisherChannelHelpKeys[PublisherDrawerTitles.General],
                  GENERAL_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS
              );

    const defaultsDefinitionFields: DefinationField[] =
        isSysAdmin || isPubAcctMgr
            ? publisherChannelHelpKeys[BrandChannelTitles.Defaults]
            : filterDefinitionsFieldsByHelpPathKeys(
                  publisherChannelHelpKeys[BrandChannelTitles.Defaults],
                  DEFAULTS_HELP_PATH_KEYS
              );

    const adPodDefinitionFields: DefinationField[] = brandChannelHelpKeys[BrandChannelTitles.AdPod];
    const customPixelsDefinitionFields: DefinationField[] = brandChannelHelpKeys[BrandChannelTitles.CustomPixels];
    const adBreakRulesDefinitionFields: DefinationField[] = brandChannelHelpKeys[BrandChannelTitles.AdBreakRules];
    const brandSafetyDefinitionFields: DefinationField[] = brandChannelHelpKeys[BrandChannelTitles.BrandSafety];
    const playlistConfigurationDefinitionFields: DefinationField[] =
        publisherChannelHelpKeys[BrandChannelTitles.PlaylistConfiguration];

    const detailConfigurationDefinitionsFields: DefinationField[] = isTremorUser
        ? publisherChannelHelpKeys[BrandChannelTitles.DetailConfiguration]
        : filterDefinitionsFieldsByHelpPathKeys(
              publisherChannelHelpKeys[BrandChannelTitles.DetailConfiguration],
              DETAILED_CONFIGURATION_HELP_PATH_KEYS
          );

    const labelsDefinitionsFields = hasInternalAccess
        ? brandChannelHelpKeys[BrandChannelTitles.Labels]
        : filterDefinitionsFieldsByHelpPathKeys(
              brandChannelHelpKeys[BrandChannelTitles.Labels],
              LABELS_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS
          );

    return {
        generalDefinitionsFields,
        defaultsDefinitionFields,
        adPodDefinitionFields,
        labelsDefinitionsFields,
        adBreakRulesDefinitionFields,
        brandSafetyDefinitionFields,
        customPixelsDefinitionFields,
        detailConfigurationDefinitionsFields,
        playlistConfigurationDefinitionFields,
    };
};
