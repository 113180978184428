import { FC } from "react";
import { Select } from "antd";
import { LabeledValue } from "antd/lib/select";
import { TagRender } from "@app/core/components/TagRender";
import { useApiFrameworksSelect } from "./useApiFrameworksSelect";

interface Props {
    onChange: (value: LabeledValue[]) => void;
    value: LabeledValue[];
}

export const ApiFrameworksSelect: FC<Props> = ({ onChange, value }) => {
    const { isLoading, options } = useApiFrameworksSelect();

    return (
        <Select
            allowClear
            data-sdet="SupportedAPIsDimensionSelect"
            loading={isLoading}
            mode="multiple"
            onChange={onChange}
            options={options}
            placeholder="Select Values"
            style={{ width: "100%" }}
            labelInValue
            optionFilterProp="label"
            tagRender={TagRender}
            value={value}
        />
    );
};
