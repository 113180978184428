import { Select, Form } from "antd";
import { useVideoPlaybacks } from "./useVideoPlaybacks";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const VideoPlaybacks = () => {
    const { isFetching, options } = useVideoPlaybacks();

    return (
        <Form.Item name={AD_UNIT_FORM_FIELDS.VIDEO_PLAYBACKS.name} label={AD_UNIT_FORM_FIELDS.VIDEO_PLAYBACKS.label}>
            <Select
                showSearch
                allowClear
                labelInValue
                mode="multiple"
                options={options}
                loading={isFetching}
                placeholder={AD_UNIT_FORM_FIELDS.VIDEO_PLAYBACKS.placeholder}
                optionFilterProp="label"
                style={{ width: "100%" }}
            />
        </Form.Item>
    );
};
