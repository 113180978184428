import { FC } from "react";
import { Form, Select } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAdSourcePixelsField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceCustomPixelsSection/Fields/AdSourcePixelsField/useAdSourcePixelsField";

export const AdSourcePixelsField: FC = () => {
    const { options, isFetching, handleChange, value } = useAdSourcePixelsField();

    return (
        <Form.Item
            data-sdet="ad-source-pixels-field"
            label="Pixels"
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PIXELS}
        >
            <Select
                allowClear
                loading={isFetching}
                mode="multiple"
                onChange={handleChange}
                options={options}
                value={value}
                placeholder="Select value"
                optionFilterProp="label"
            />
        </Form.Item>
    );
};
