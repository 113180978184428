import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useSeatAuthContext } from "@app/core/auth";
import {
    clearFilters,
    selectSeatAdSourcesPacingFilterCount,
    SeatAdSourcesPacingState,
    selectSeatAdSourcesPacingFilters,
    selectSeatAdSourcesPacingSearchFilter,
} from "@app/features/seatAdSources/SeatAdSourcesPacingPage/reducer";
import { UseSeatAdSourcesPacingTableFilters } from "./types";
import { LabeledValue } from "antd/lib/select";
import { skipToken } from "@reduxjs/toolkit/query";
import { Seat } from "@app/core/services/console";

export const getFilter = (filter: LabeledValue[] | null) => {
    if (filter && filter.length > 0) {
        return filter.map((filter) => String(filter.value));
    }
};

export const getSeatAllSeatAdSourcesParams = (context: Seat | null, filters: SeatAdSourcesPacingState["filters"]) => {
    if (!context) {
        return skipToken;
    }

    return {
        seatId: context.id,
        labelKeyId: getFilter(filters.seatAdSourcesLabelKey),
        labelValueId: getFilter(filters.seatAdSourcesLabelValue),
    };
};

export const getWaterfallPacingParams = (
    context: Seat | null,
    filters: SeatAdSourcesPacingState["filters"],
    search: SeatAdSourcesPacingState["searchFilter"]
) => {
    if (!context) {
        return skipToken;
    }

    return {
        seatId: context.id,
        networks: getFilter(filters.seatAdSourcesDsp),
        deals: getFilter(filters.seatAdSourcesDeal),
        userId: getFilter(filters.seatAdSourcesAssignee),
        keyword: search.keyword || "",
    };
};

export const useSeatAdSourcesPacingTableFilters = (): UseSeatAdSourcesPacingTableFilters => {
    const dispatch = useAppDispatch();
    const seatAdSourcesPacingFilterCount = useAppSelector(selectSeatAdSourcesPacingFilterCount);
    const { context } = useSeatAuthContext();
    const seatAdSourcesPacingFilters = useAppSelector(selectSeatAdSourcesPacingFilters);
    const seatAdSourcesPacingSearchFilter = useAppSelector(selectSeatAdSourcesPacingSearchFilter);

    const handleClearFilters = () => {
        dispatch(clearFilters());
    };

    return {
        filterCount: seatAdSourcesPacingFilterCount,
        handleClearFilters,
        seatAllAdSourcesParams: getSeatAllSeatAdSourcesParams(context, seatAdSourcesPacingFilters),
        seatWaterfallPacingParams: getWaterfallPacingParams(
            context,
            seatAdSourcesPacingFilters,
            seatAdSourcesPacingSearchFilter
        ),
    };
};
