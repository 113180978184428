import { isValidElement, ReactNode } from "react";
import { Typography } from "antd";

export const applyCustomLabel = (label: ReactNode) => {
    if (typeof label === "string") {
        const [category, rest] = label.split(":");
        if (category && rest) {
            return (
                <>
                    <Typography.Text type="secondary">{category}:</Typography.Text> {rest}
                </>
            );
        }
    } else if (typeof label === "object") {
        // Just return the text (not bolded)
        if (isValidElement(label) && label.props.strong) {
            return label.props.children;
        }
    }
    return label;
};
