import { FC } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { TableSearchInputProps } from "./types";

const searchIconCss = css`
    color: rgba(0, 0, 0, 0.25);
    height: 12;
`;

export const TableSearchInput: FC<TableSearchInputProps> = (props) => {
    const { dataSdet, entityType, ...antdInputProps } = props;
    return (
        <Input
            allowClear
            data-sdet={dataSdet ?? "table-search-input"}
            placeholder={`Search ${entityType} Name or ID`}
            suffix={!antdInputProps?.value && <SearchOutlined className={searchIconCss} />}
            {...antdInputProps}
        />
    );
};
