import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import conf from "@app/core/conf";

export const AD_SOURCES_TIMING_STATS_TAG = "AdSourcesTimingStatsTag";
export const AD_UNIT_TIMING_STATS_SNAPSHOT = "AdUnitTimingStatsSnapshot";

export const timingStatsApi = createApi({
    reducerPath: "timingStats",
    tagTypes: [AD_SOURCES_TIMING_STATS_TAG, AD_UNIT_TIMING_STATS_SNAPSHOT],
    baseQuery: fetchBaseQuery({
        baseUrl: `${conf.mctvTimingStatsApiRoot}/cast/resources/stats/timingStats/`,
        credentials: "include",
        prepareHeaders: (headers) => {
            return headers;
        },
    }),
    endpoints: () => ({}),
});
