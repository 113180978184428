import { FC } from "react";
import { Form, Radio, RadioChangeEvent, Typography } from "antd";
import {
    BRAND_SAFETY_BLOCK_TYPE,
    BRAND_SAFETY_TYPES_OPTIONS_LABEL_VALUE,
    CREATE_BRAND_SAFETY_FORM_ITEMS_NAME,
} from "@app/features/inventory/InventoryBrandSafety/InventoryAdvancedBrandSafetyPage/constants";
import { useWatch } from "antd/lib/form/Form";
import { gray7 } from "@rubicon/antd-components";

interface BrandSafetyTypeFieldProps {
    onChange: (e: RadioChangeEvent) => void;
    isSubmitting: boolean;
}

export const BrandSafetyTypeField: FC<BrandSafetyTypeFieldProps> = ({ onChange, isSubmitting }) => {
    const value = useWatch(CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.TYPE);

    return (
        <Form.Item
            label="Type"
            name={CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.TYPE}
            rules={[{ required: true, message: "Type is required" }]}
        >
            <Form.Item name={CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.TYPE} style={{ marginBottom: 0 }}>
                <Radio.Group
                    onChange={onChange}
                    disabled={isSubmitting}
                    options={BRAND_SAFETY_TYPES_OPTIONS_LABEL_VALUE}
                />
            </Form.Item>

            {value === BRAND_SAFETY_BLOCK_TYPE.value ? (
                <Typography.Text style={{ color: gray7 }}>
                    Block Mode will prevent the nominated Advertisers from running against the targeted supply. Please
                    double-check the Mode, Advertisers & Targeting prior to saving.
                </Typography.Text>
            ) : (
                <Typography.Text style={{ color: gray7 }}>
                    Allow Mode allows only the nominated Advertisers to run against the targeted supply, and will block
                    any Advertisers not listed within this rule. This mode will also override any Block mode rules
                    targeted at the same Supply. Please double-check the Mode, Advertisers & Targeting prior to saving.
                </Typography.Text>
            )}
        </Form.Item>
    );
};
