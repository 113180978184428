import { FC } from "react";
import { Input } from "antd";

interface Props {
    handleSearch: (value: string) => void;
    searchValue: string;
}

const InventoryAdvancedBrandSafetyPageSearchFilter: FC<Props> = ({ handleSearch }) => {
    return (
        <Input.Search
            style={{ width: "100%" }}
            onChange={(e) => handleSearch(e.target.value)}
            onSearch={handleSearch}
            allowClear
            placeholder="Filter by Name"
        />
    );
};

export default InventoryAdvancedBrandSafetyPageSearchFilter;
