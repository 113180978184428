import { FC } from "react";
import { Form, Select } from "antd";
import { Seat } from "@app/core/services";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { useDefaultExtendedImpressionWaitTime } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/SeatDetailsFormFields/SeatDefaultsInternalOnly/Fields/DefaultExtendedImpressionWaitTime/useDefaultExtendedImpressionWaitTime";

interface DefaultExtendedImpressionWaitTimeProps {
    seat: Seat;
    edit?: boolean;
}

export const DefaultExtendedImpressionWaitTime: FC<DefaultExtendedImpressionWaitTimeProps> = ({
    edit = false,
    seat,
}) => {
    const { isFetching, options } = useDefaultExtendedImpressionWaitTime();
    const { defaultExtendedImpressionWaitTime } = seat;
    return (
        <Form.Item
            label={INVENTORY_FORM_FIELDS.DEFAULT_EXTENDED_IMPRESSION_WAIT_TIME.label}
            name={INVENTORY_FORM_FIELDS.DEFAULT_EXTENDED_IMPRESSION_WAIT_TIME.name}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 24, xxl: 20 }}
        >
            {edit ? (
                <Select
                    showSearch
                    allowClear
                    labelInValue
                    options={options}
                    loading={isFetching}
                    optionFilterProp="label"
                    style={{ width: "100%" }}
                    placeholder={INVENTORY_FORM_FIELDS.DEFAULT_EXTENDED_IMPRESSION_WAIT_TIME.placeholder}
                />
            ) : (
                defaultExtendedImpressionWaitTime?.name ||
                INVENTORY_FORM_FIELDS.DEFAULT_EXTENDED_IMPRESSION_WAIT_TIME.placeholder
            )}
        </Form.Item>
    );
};
