import { useMemo, useState } from "react";
import { Form } from "antd";
import {
    AD_SOURCE_FIELDS,
    AdSourceAdvertiserDomainModeIds,
    AdSourceIabCategoryModeIds,
    VERTICAL_LAYOUT_FIX,
} from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { IabCategory, SeatAdSourcesType, useGetIabCategoriesQuery } from "@app/core/services";
import { formatCategoryLabel, formatCategorySearchValue } from "@app/features/targeting";
import { AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { TagRender } from "@app/core/components";
import { SelectAllButtonDropdownRender } from "@app/core/components/SelectAllButtonDropdownRender";

export const IABCategoriesSelect = () => {
    const [search, setSearch] = useState("");
    const { isLoading, data: options = [] } = useGetIabCategoriesQuery();
    const orderedOptions = useMemo(() => {
        return [...options]
            .sort((a, b) => a.code.localeCompare(b.code, undefined, { numeric: true }))
            .map((option) => ({ ...option, search: formatCategorySearchValue(option) }))
            .filter((option) => option.search.toLowerCase().includes(search.toLowerCase()));
    }, [options, search]);
    const iabCategoryBlockMode = Form.useWatch<AdSourcesForm["iabCategoryBlockMode"]>(
        AD_SOURCE_FIELDS.IAB_CATEGORY_BLOCK_MODE.name
    );
    const form = Form.useFormInstance();
    const value = Form.useWatch<AdSourcesForm["blockedIabCategories"]>(AD_SOURCE_FIELDS.IAB_BLOCKED_CATEGORIES.name);
    const advertiserDomainMode = Form.useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.MODE.name);
    const disabled: boolean = advertiserDomainMode?.id === AdSourceAdvertiserDomainModeIds.ALLOW_OVERRIDE_INVENTORY;

    const handleChange = (v: IabCategory[]): void => {
        form.setFieldValue(AD_SOURCE_FIELDS.IAB_BLOCKED_CATEGORIES.name, v);
        form.validateFields([AD_SOURCE_FIELDS.AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES.name]);
    };

    return (
        <Form.Item
            {...VERTICAL_LAYOUT_FIX}
            label={AD_SOURCE_FIELDS.IAB_BLOCKED_CATEGORIES.label}
            name={AD_SOURCE_FIELDS.IAB_BLOCKED_CATEGORIES.name}
            extra={
                iabCategoryBlockMode?.id === AdSourceIabCategoryModeIds.BLOCK_OVERRIDE_INVENTORY && !value?.length
                    ? AD_SOURCE_FIELDS.IAB_BLOCKED_CATEGORIES.extra
                    : undefined
            }
        >
            <SelectWithValue<IabCategory>
                allowClear
                disabled={disabled}
                dropdownRender={(menu) => (
                    <SelectAllButtonDropdownRender<IabCategory>
                        getPrimaryKey={(v) => v.id}
                        menu={menu}
                        onChange={handleChange}
                        options={orderedOptions}
                        search={search}
                        value={value}
                    />
                )}
                fieldAsLabel={(category) => formatCategoryLabel(category)}
                fieldAsValue={["id"]}
                onChange={handleChange}
                loading={isLoading}
                mode="multiple"
                onBlur={() => setSearch("")}
                onSearch={setSearch}
                optionFilterProp="search"
                options={orderedOptions}
                placeholder="Select IAB Categories…"
                searchValue={search}
                showSearch
                tagRender={TagRender}
            />
        </Form.Item>
    );
};
