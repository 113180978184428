import { FC } from "react";
import { Form, Select } from "antd";
import { TargetingDimensionTypeLabels } from "@app/features/targeting/constants";
import { BulkUploadDrawerButton } from "../../BulkUploadDrawerButton";
import { useBulkPostalCodeDrawerButton } from "./useBulkPostalCodeDrawer";
import { GeoTarget } from "@app/core/services/console";

interface Props {
    onChange: (value: GeoTarget[]) => void;
    values: GeoTarget[];
}

const COUNTRY_CODES = [
    { label: "Austrailia", value: "AU" },
    { label: "Canada", value: "CA" },
    { label: "France", value: "FR" },
    { label: "Germany", value: "DE" },
    { label: "Great Britain", value: "GB" },
    { label: "New Zealand", value: "NZ" },
    { label: "Spain", value: "ES" },
    { label: "United States", value: "US" },
];

interface CountryCodeSelectProps {
    value: string;
    onChange: (value: string) => void;
}

const CountryCodeSelect: FC<CountryCodeSelectProps> = ({ value, onChange }) => (
    <Form.Item label="Country">
        <Select options={COUNTRY_CODES} value={value} onChange={onChange} />
    </Form.Item>
);

export const BulkPostalCodeDrawerButton: FC<Props> = ({ onChange, values }) => {
    const {
        countryCode,
        handleAdd,
        handleChangeCountryCode,
        handleChangeValue,
        handleClose,
        handleOpen,
        handleReplace,
        isLoadingAdd,
        isLoadingReplace,
        isOpen,
        value,
    } = useBulkPostalCodeDrawerButton(onChange, values);

    return (
        <BulkUploadDrawerButton
            extra={<CountryCodeSelect value={countryCode} onChange={handleChangeCountryCode} />}
            handleAdd={handleAdd}
            handleChangeValue={handleChangeValue}
            handleClose={handleClose}
            handleOpen={handleOpen}
            handleReplace={handleReplace}
            isLoadingAdd={isLoadingAdd}
            isLoadingReplace={isLoadingReplace}
            isOpen={isOpen}
            value={value}
            title={`${TargetingDimensionTypeLabels.GeoTargets} Targeting - Bulk Postal Code`}
            placeholder="Enter postal codes one per row"
        />
    );
};
