import { useCreativeReviewContent } from "./useCreativeReviewContent";

interface UseDefinitionCreativeReviewContent {
    isLoading: boolean;
    breadcrumbs: string;
}

export const useDefinitionCreativeReviewContent = (id: string | number): UseDefinitionCreativeReviewContent => {
    const { isLoading, breadcrumbs } = useCreativeReviewContent(id);
    const breadCrumbsWithCreative = breadcrumbs
        ? `${breadcrumbs} ${breadcrumbs && "/"} Creative Review`
        : "Creative Review";

    return {
        isLoading,
        breadcrumbs: breadCrumbsWithCreative,
    };
};
