import { FC } from "react";
import { Form, Input } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAdSourceDescriptionField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceConfigurationSection/Fields/AdSourceDescriptionField/useAdSourceDescriptionField";

export const AdSourceDescriptionField: FC = () => {
    const { value, handleChange, handleBlur } = useAdSourceDescriptionField();
    return (
        <Form.Item
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DESCRIPTION}
            data-sdet="ad-source-description-field"
            label="Description"
        >
            <Input.TextArea
                rows={4}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                autoComplete="off"
                allowClear
                placeholder="Type here..."
            />
        </Form.Item>
    );
};
