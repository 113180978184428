import { FC } from "react";
import { Form, Select } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useDealFrequencyCappingField } from "@app/features/deals/DealForm/DealDemandFormSections/DealDemandConfigurationSection/Fields/DealFrequencyCappingField/useDealFrequencyCappingField";

export const DealFrequencyCappingField: FC = () => {
    const { value, options, isFetching, handleSelect } = useDealFrequencyCappingField();
    return (
        <Form.Item
            data-sdet="deal-frequency-capping-field"
            label="Deal Frequency Capping"
            name={CREATE_DEAL_FORM_ITEMS_NAME.FREQUENCY_CAPPING}
        >
            <Select
                showSearch
                optionFilterProp="label"
                onSelect={handleSelect}
                value={value}
                options={options}
                loading={isFetching}
            />
        </Form.Item>
    );
};
