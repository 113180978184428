import { FC } from "react";
import { AdSource } from "@app/core/services";
import { Row } from "antd";
import {
    AdResponsePriceOverrideSelect,
    AlwaysSendFloorRadio,
    AuctionTypeSelect,
    BookingBudgetField,
    CurrencySelect,
    FixedCpmInput,
    FloorTypeSelect,
    OverrideFloorCpmInput,
    PriceModelSelect,
    VastDynamicPricingLocationRadio,
    VastDynamicPricingMultiplierInput,
    RunUnderInboundFloor,
} from "./Fields";

interface Props {
    adSource: AdSource;
}

export const SeatAdSourcesPricingDetails: FC<Props> = ({ adSource }) => {
    return (
        <>
            <Row>
                <PriceModelSelect adSource={adSource} />
            </Row>
            <Row>
                <FloorTypeSelect adSource={adSource} />
            </Row>
            <Row>
                <VastDynamicPricingLocationRadio adSource={adSource} />
            </Row>
            <Row>
                <VastDynamicPricingMultiplierInput adSource={adSource} />
            </Row>
            <Row>
                <FixedCpmInput adSource={adSource} />
                <OverrideFloorCpmInput adSource={adSource} />
                <CurrencySelect adSource={adSource} />
            </Row>
            <Row>
                <AuctionTypeSelect adSource={adSource} />
                <AlwaysSendFloorRadio adSource={adSource} />
            </Row>
            <Row>
                <BookingBudgetField adSource={adSource} />
            </Row>
            <Row>
                <RunUnderInboundFloor adSource={adSource} />
            </Row>
            <Row>
                <AdResponsePriceOverrideSelect adSource={adSource} />
            </Row>
        </>
    );
};
