import { ChangeEvent } from "react";
import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormInputFieldChange } from "@app/features/deals/DealForm/reducer";
import { useDispatchOnBlur } from "@app/features/deals/DealForm/hooks";
import { AdSourceAdvertiserDomainModeIds } from "@app/features/seatAdSources/constants";

interface UseAdSourceAdditionalAdvertiserDomainField {
    handleBlur: () => void;
    handleChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
    isShowingEmptyWarning: boolean;
    value: string | number | readonly string[] | undefined;
}

export const useAdSourceAdditionalAdvertiserDomainField = (): UseAdSourceAdditionalAdvertiserDomainField => {
    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ADDITIONAL_ADVERTISER_DOMAIN]
    );
    const advertiserDomainMode = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE]
    );
    const reusableAdvertiserDomains = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_REUSABLE_ADVERTISER_DOMAIN]
    );
    const isAdvertiserDomainModeBlockOverride =
        advertiserDomainMode === AdSourceAdvertiserDomainModeIds.BLOCK_OVERRIDE_INVENTORY;
    const isShowingEmptyWarning = isAdvertiserDomainModeBlockOverride && !value && !reusableAdvertiserDomains.length;
    const { onChange, onBlur } = useDispatchOnBlur(value);

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;
        onChange(value);
    };
    const handleBlur = () => {
        onBlur(dealFormInputFieldChange, CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ADDITIONAL_ADVERTISER_DOMAIN);
    };
    return {
        handleBlur,
        handleChange,
        isShowingEmptyWarning,
        value,
    };
};
