import { FC } from "react";
import { Radio } from "antd";
import { TRANSPARENCY_OPTIONS } from "@app/features/deals/constants";
import { useAdSourceReferUrlField } from "./useAdSourceReferUrlField";
import { TransparencyFormItem } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceTransparencySection/Fields";
export const AdSourceReferUrlField: FC = () => {
    const { onChange, value, isDisabled, overrideText } = useAdSourceReferUrlField();
    return (
        <TransparencyFormItem extra={overrideText} label="Refer URL" data-sdet="refer-url">
            <Radio.Group
                disabled={isDisabled}
                onChange={(e) => onChange(e?.target?.value)}
                value={value}
                options={TRANSPARENCY_OPTIONS}
            />
        </TransparencyFormItem>
    );
};
