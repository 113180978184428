import { ColumnsType } from "antd/es/table";
import { PriceOverrideConfig } from "@app/core/services";
import { ReactNode } from "react";
import { Typography } from "antd";
import { FIXED_CLEARING_PRICE_TYPE } from "../../constants";
import { NA } from "@app/core/components/constants";
import { brandCobalt } from "@rubicon/antd-components";

export type TableRows = {
    className?: string;
    overridePrice: ReactNode;
    name?: ReactNode;
    adUnit?: ReactNode;
    id?: ReactNode;
    priceOverrideType: ReactNode;
    deletedDate?: ReactNode | string;
};

export const PRICE_OVERRIDES_TABLE_SEARCH_SDET = "price-overrides-table-search";
export const PRICE_OVERRIDES_TABLE_NAME_COLUMN_SDET = "price-overrides-table-name-column";
export const PRICE_OVERRIDES_TABLE_AD_UNIT_COLUMN_SDET = "price-overrides-table-ad-unit-column";
export const PRICE_OVERRIDES_TABLE_TYPE_COLUMN_SDET = "price-overrides-table-type-column";
export const PRICE_OVERRIDES_TABLE_PRICE_COLUMN_SDET = "price-overrides-table-price-column";

export const getColumns = (): ColumnsType<TableRows> =>
    [
        {
            dataIndex: "name",
            key: "name",
            fixed: "left" as const,
            sorter: false,
            title: <span data-sdet={PRICE_OVERRIDES_TABLE_NAME_COLUMN_SDET}>Name</span>,
            width: 200,
        },
        {
            dataIndex: "adUnit",
            key: "adUnit",
            sorter: false,
            title: <span data-sdet={PRICE_OVERRIDES_TABLE_AD_UNIT_COLUMN_SDET}>Ad Unit</span>,
            width: 200,
        },
        {
            dataIndex: "priceOverrideType",
            fixed: "right" as const,
            key: "priceOverrideType",
            sorter: false,
            title: <span data-sdet={PRICE_OVERRIDES_TABLE_TYPE_COLUMN_SDET}>Price Override Type</span>,
            width: 200,
        },
        {
            dataIndex: "overridePrice",
            fixed: "right" as const,
            key: "overridePrice",
            sorter: false,
            title: <span data-sdet={PRICE_OVERRIDES_TABLE_PRICE_COLUMN_SDET}>Override Price</span>,
            width: 100,
        },
    ].filter(Boolean);

export const getTableRows = (data: PriceOverrideConfig[] | undefined, onClickName: (priceOverrideId: number) => void) =>
    data
        ? data.map<TableRows>((priceOverride) => {
              return {
                  className: "",
                  name: (
                      <Typography.Link
                          data-sdet={`go-to-${priceOverride.id}-link`}
                          onClick={() => onClickName(priceOverride.id)}
                          style={{
                              color: brandCobalt,
                          }}
                      >
                          {priceOverride.name}
                      </Typography.Link>
                  ),
                  adUnit: priceOverride.adUnit.name,
                  key: priceOverride.id,
                  priceOverrideType: priceOverride.priceOverrideType.name,
                  overridePrice:
                      priceOverride.priceOverrideType.id === FIXED_CLEARING_PRICE_TYPE
                          ? priceOverride.overridePrice
                          : NA,
              };
          })
        : [];
