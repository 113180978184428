import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { useLoadOnScroll } from "@app/core/components";

const MAX = 10;

const getParamsDefault = (
    keyword,
    pageByKeyword,
    seatId
): {
    addUnknown?: boolean;
    keyword: string;
    max: number;
    page: number;
    seatId: number;
} => ({
    addUnknown: !keyword && (pageByKeyword[keyword] || 1) === 1,
    keyword,
    max: MAX,
    page: pageByKeyword[keyword] || 1,
    seatId: Number(seatId),
});

export const useDimensionRowSearch = (
    useGetDimensionRowQuery,
    dimensionRowToLabelValue,
    getParams = getParamsDefault
) => {
    const { seatId } = useParams<{ seatId: string }>();
    const [search, setSearch] = useState("");
    const [keyword, setKeyword] = useState("");
    const [pageByKeyword, setPageByKeyword] = useState({
        "": 1,
    });
    const params = getParams(keyword, pageByKeyword, seatId);

    const { data, isFetching, originalArgs } = useGetDimensionRowQuery(params);
    const {
        options: rawOptions,
        hasMore,
        loadMore,
    } = useLoadOnScroll(
        data,
        isFetching,
        originalArgs?.keyword || "",
        originalArgs?.page || 1,
        data?.length >= MAX, // Some APIs return 1 additional "unknown" result no matter what, so we check >= MAX
        () =>
            setPageByKeyword((prev) => {
                const currentPage = prev[originalArgs?.keyword || ""] || 1;
                return {
                    ...prev,
                    [originalArgs?.keyword || ""]: currentPage + 1,
                };
            })
    );

    const options = useMemo(() => dimensionRowToLabelValue(rawOptions || []), [rawOptions, dimensionRowToLabelValue]);
    const debouncedHandleSearch = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        []
    );
    const isCurrent = useMemo(() => originalArgs?.keyword === search, [originalArgs, search]);
    const handleChangeSearch = (search: string): void => {
        setSearch(search);
        debouncedHandleSearch(search);
    };
    return [search, handleChangeSearch, options, isFetching, hasMore, loadMore, isCurrent];
};
