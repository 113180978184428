import { Unsubscribe } from "@reduxjs/toolkit";
import { IMessageEvent, w3cwebsocket } from "websocket";
import { selectUserTimezone } from "@app/core/authClient/reducer";
import conf from "@app/core/conf";
import { Session } from "@app/core/clients/console";
import { store } from "@app/core/store";
import { sendHeartbeatEvent, sendLoginEvent } from "@app/core/socket/events";
import { EventTypes, RawEventData, RawEventDataWithMessage } from "@app/core/socket/types";
import { sendStartHistoryStatsEvent, sendStartStatsEvent, sendStopStatsEvent } from "../events";
import { AdStatEventMessage, AdStatHistoryEventMessage, EntityTypes } from "../types";
import {
    AdStatSubscriptions,
    addSocketSubscriber,
    allSocketSubscribersFinished,
    apiAuthenticated,
    apiAuthenticating,
    apiReauthenticating,
    historyAdStatsReceived,
    historySubscriptionsSent,
    liveAdStatsReceived,
    liveSubscriptionsSent,
    logMessageReceived,
    removeSocketSubscriber,
    selectHistoryHasPendingSubscriptionUpdates,
    selectHistoryIsAwaitingNextResponse,
    selectHistorySubscriptionIds,
    selectLiveHasPendingSubscriptionUpdates,
    selectLiveIsAwaitingNextResponse,
    selectLiveSubscriptionIds,
    selectSocketHasSubscribers,
    selectSocketIsAuthenticated,
    selectSocketIsConnected,
    selectSocketSession,
    socketClosed,
    socketConnecting,
    socketFailed,
    socketOpened,
    SocketSession,
    updateHistorySubscriptions,
    updateLiveSubscriptions,
    updateSocketSession,
    selectTimeZoneCode,
    updateTimeZone,
} from "./reducer";

const AD_STATS_WEB_SOCKET_URL = `${conf.mctvAdStatsWebsocketRoot}/cast/websocket/adstats`;
const HEARTBEAT_INTERVAL_MILLISECONDS = 1000 * 30;
const WEB_SOCKET_TEARDOWN_DELAY_MILLISECONDS = 1100;

export class AdSourceAdStatsWebSocket {
    private static instance: AdSourceAdStatsWebSocket | null;

    public static getInstance(session: Session): AdSourceAdStatsWebSocket {
        if (!this.instance) {
            this.instance = new AdSourceAdStatsWebSocket(session);
        }
        this.instance.onAddSocketSubscriber();
        return this.instance;
    }

    private socket: w3cwebsocket;
    private heartbeatInterval: NodeJS.Timeout | undefined;
    private appSessionWatchUnsubcribe: Unsubscribe | undefined;
    private appSessionUserTimeZoneWatchUnsubscribe: Unsubscribe | undefined;

    private constructor(session: Session) {
        const initialSocketSession = { code: session.sessionCode, userEmail: session.user.emailAddress };
        this.onUpdateSocketSession(initialSocketSession);
        this.onSocketConnecting();
        this.socket = new w3cwebsocket(AD_STATS_WEB_SOCKET_URL);
        this.socket.onopen = this.socketOpenEvent;
        this.socket.onerror = this.socketErrorEvent;
        this.socket.onclose = this.socketCloseEvent;
        this.socket.onmessage = this.socketMessageEvent;
    }

    private readonly attemptTearDown = () => {
        // Give React / pending API calls that feed subscriptions a chance to stabilize before tearing down the web socket.
        // This helps to avoid jamming up the web socket when components completely unsubscribe, only to immediately resubscribe.
        //
        // For example, suppose component A and component B are both sole subscribers to the web socket and live in mutually exclusive tabs.
        // When a user switches tabs from component A to component B, there is no need to immediately tear the web socket down... and doing
        // so can cause the web socket to lock up.
        setTimeout(() => {
            if (!this.hasSocketSubscribers()) {
                this.attemptSendStopAdStatsMessage();
                this.onAllSocketSubscribersFinished();
                if (this.heartbeatInterval) {
                    clearInterval(this.heartbeatInterval);
                }
                if (this.appSessionWatchUnsubcribe) {
                    this.appSessionWatchUnsubcribe();
                }
                if ([w3cwebsocket.CONNECTING, w3cwebsocket.OPEN].includes(this.socket?.readyState)) {
                    this.socket.close();
                }
                AdSourceAdStatsWebSocket.instance = null;
            }
        }, WEB_SOCKET_TEARDOWN_DELAY_MILLISECONDS);
    };

    public readonly updateLiveAdStatSubscriptions = (
        subscribeAdSourceIds: number[],
        unsubscribeAdSourceIds: number[]
    ) => {
        const subscriptionUpdates = {
            subscribes: subscribeAdSourceIds,
            unsubscribes: unsubscribeAdSourceIds,
        };
        this.onUpdateLiveSubscriptions(subscriptionUpdates);
        this.attemptSendAdStatsMessage();
    };

    public readonly updateHistoryAdStatSubscriptions = (
        subscribeAdSourceIds: number[],
        unsubscribeAdSourceIds: number[]
    ) => {
        const subscriptionUpdates = {
            subscribes: subscribeAdSourceIds,
            unsubscribes: unsubscribeAdSourceIds,
        };
        this.onUpdateHistorySubscriptions(subscriptionUpdates);
        this.attemptSendAdStatsMessage();
    };

    public readonly liveSubscriberFinished = (adSourceIds: number[]) => {
        this.updateLiveAdStatSubscriptions([], adSourceIds);
        this.removeSocketSubscriber();
        this.attemptTearDown();
    };

    public readonly historySubscriberFinished = (adSourceIds: number[]) => {
        this.updateHistoryAdStatSubscriptions([], adSourceIds);
        this.removeSocketSubscriber();
    };

    private readonly removeSocketSubscriber = () => {
        this.onRemoveSocketSubscriber();
    };

    private readonly authenticate = () => {
        if (this.isSocketConnected()) {
            this.onApiAuthenticating();
            const socketSession = this.socketSession();
            if (socketSession?.code && socketSession?.userEmail) {
                sendLoginEvent(this.socket, socketSession.code, socketSession.userEmail);
            }
        }
    };

    private readonly reauthenticate = () => {
        if (this.isSocketAuthenticated()) {
            this.attemptSendStopAdStatsMessage();
            this.onApiReauthenticating();
            this.authenticate();
        }
    };

    private readonly socketOpenEvent = () => {
        this.onSocketOpened();
        this.authenticate();
    };

    private readonly socketErrorEvent = (error: Error) => {
        console.error("An ad source ad stats socket error occured.", error);
        this.onSocketFailed(error);
        this.attemptTearDown();
    };

    private readonly socketCloseEvent = () => {
        this.onSocketClosed();
        this.attemptTearDown();
    };

    private readonly socketMessageEvent = (message: IMessageEvent) => {
        const eventData = this.parseMessageData(message);
        if (!eventData) {
            return;
        }

        switch (eventData.type) {
            case EventTypes.AuthResult: {
                const rawAuthResultMessage = eventData as RawEventDataWithMessage;
                const authenticationEventMessage = JSON.parse(rawAuthResultMessage.message) as boolean;
                this.onApiAuthenticated(authenticationEventMessage);
                this.attemptSendAdStatsMessage();
                this.startHeartbeatIfNotRunning();
                this.startAppSessionWatchIfNotRunning();
                this.startAppSessionUserTimeZoneWatchIfNotRunning();
                break;
            }
            case EventTypes.AdStat: {
                const rawAdStatMessage = eventData as RawEventDataWithMessage;
                const adStatEventMessage = JSON.parse(rawAdStatMessage.message) as AdStatEventMessage;
                this.onLiveAdStatsReceived(adStatEventMessage);
                this.attemptSendAdStatsMessage();
                break;
            }
            case EventTypes.AdStatHistory: {
                const rawAddStatMessage = eventData as RawEventDataWithMessage;
                const adStatHistoryEventMessage = JSON.parse(rawAddStatMessage.message) as AdStatHistoryEventMessage;
                this.onHistoryAdStatsReceived(adStatHistoryEventMessage);
                this.attemptSendAdStatsMessage();
                return;
            }
            case EventTypes.Log: {
                const logMessage = (eventData as RawEventDataWithMessage).message;
                this.onLogMessageReceived(logMessage);
                break;
            }
        }
    };

    private readonly parseMessageData = (message: IMessageEvent) => {
        if (typeof message?.data !== "string") {
            console.warn("Unexpected ad source ad stats socket message received.");
            return null;
        }
        try {
            const eventData = JSON.parse(message.data) as RawEventData;
            return eventData;
        } catch (e) {
            console.error("Unparsable ad source ad stats socket message received.", e);
            return null;
        }
    };

    private readonly attemptSendAdStatsMessage = () => {
        if (!this.isSocketAuthenticated()) {
            return;
        }

        if (this.hasPendingLiveSubscriptionUpdates() && !this.isAwaitingNextLiveResponse()) {
            sendStartStatsEvent(this.socket, {
                entityType: EntityTypes.AdSource,
                entityIds: this.liveSubscriptionIds(),
                oneAndDone: null,
                timezone: this.timeZoneCode(),
            });
            this.onLiveSubscriptionsSent();
        }

        if (this.hasPendingHistorySubscriptionUpdates() && !this.isAwaitingNextHistoryResponse()) {
            sendStartHistoryStatsEvent(this.socket, {
                entityType: EntityTypes.AdSource,
                entityIds: this.historySubscriptionIds(),
                oneAndDone: true,
                timezone: this.timeZoneCode(),
            });
            this.onHistorySubscriptionsSent();
        }
    };

    private readonly attemptSendStopAdStatsMessage = () => {
        if (this.isSocketAuthenticated()) {
            sendStopStatsEvent(this.socket);
        }
    };

    private readonly attemptSendHearbeatMessage = () => {
        if (this.isSocketAuthenticated()) {
            sendHeartbeatEvent(this.socket);
        }
    };

    private readonly startHeartbeatIfNotRunning = () => {
        if (this.isSocketAuthenticated() && !this.heartbeatInterval) {
            this.heartbeatInterval = setInterval(this.attemptSendHearbeatMessage, HEARTBEAT_INTERVAL_MILLISECONDS);
        }
    };

    private readonly startAppSessionWatchIfNotRunning = () => {
        if (!this.appSessionWatchUnsubcribe) {
            this.appSessionWatchUnsubcribe = store.subscribe(() => {
                if (this.isSocketAuthenticated() && this.applicationSessionUpdated()) {
                    const appSession = this.applicationSession();
                    const updatedSocketSession = { code: appSession.code, userEmail: appSession.userEmail };
                    this.onUpdateSocketSession(updatedSocketSession);
                    this.reauthenticate();
                }
            });
        }
    };

    private readonly applicationSessionUpdated = () => {
        const appSession = this.applicationSession();
        const appSessionExists = appSession.code && appSession.userEmail;
        if (appSessionExists) {
            const socketSession = this.socketSession();
            const sessionCodeUpdated = socketSession?.code !== appSession.code;
            const sessionUserEmailUpdated = socketSession?.userEmail !== appSession?.userEmail;

            return sessionCodeUpdated || sessionUserEmailUpdated;
        }

        return false;
    };

    private readonly startAppSessionUserTimeZoneWatchIfNotRunning = () => {
        if (!this.appSessionUserTimeZoneWatchUnsubscribe) {
            this.appSessionUserTimeZoneWatchUnsubscribe = store.subscribe(() => {
                if (this.isSocketAuthenticated() && this.applicationSessionTimeZoneUpdated()) {
                    this.onUpdateTimeZone();
                    this.attemptSendAdStatsMessage();
                }
            });
        }
    };

    private readonly applicationSessionTimeZoneUpdated = () => {
        const appSessionUserTimeZoneCode = this.applicationSessionUserTimeZoneCode();
        if (appSessionUserTimeZoneCode) {
            if (appSessionUserTimeZoneCode !== this.timeZoneCode()) {
                return true;
            }
        }

        return false;
    };

    private readonly isSocketOpen = () => this.socket && this.socket.readyState === w3cwebsocket.OPEN;

    private readonly applicationSession = () => {
        const state = store.getState();
        const appSessionCode = state.auth?.session?.sessionCode;
        const appSessionEmail = state.auth?.session?.user?.emailAddress;
        return {
            code: appSessionCode,
            userEmail: appSessionEmail,
        };
    };

    /** REDUX METHODS */

    // socket selects
    private readonly hasSocketSubscribers = () => selectSocketHasSubscribers(store.getState());
    private readonly isSocketAuthenticated = () => this.isSocketOpen() && selectSocketIsAuthenticated(store.getState());
    private readonly isSocketConnected = () => this.isSocketOpen() && selectSocketIsConnected(store.getState());
    private readonly socketSession = () => selectSocketSession(store.getState());

    // socket actions
    private readonly onAddSocketSubscriber = () => store.dispatch(addSocketSubscriber());
    private readonly onAllSocketSubscribersFinished = () => store.dispatch(allSocketSubscribersFinished());
    private readonly onApiAuthenticated = (authenticationResult: boolean) =>
        store.dispatch(apiAuthenticated(authenticationResult));
    private readonly onApiAuthenticating = () => store.dispatch(apiAuthenticating());
    private readonly onApiReauthenticating = () => store.dispatch(apiReauthenticating());
    private readonly onLogMessageReceived = (logMessage: string) => store.dispatch(logMessageReceived(logMessage));
    private readonly onRemoveSocketSubscriber = () => store.dispatch(removeSocketSubscriber());
    private readonly onSocketClosed = () => store.dispatch(socketClosed());
    private readonly onSocketConnecting = () => store.dispatch(socketConnecting());
    private readonly onSocketFailed = (error: Error) => store.dispatch(socketFailed(error));
    private readonly onSocketOpened = () => store.dispatch(socketOpened());
    private readonly onUpdateSocketSession = (updatedAppSession: SocketSession) =>
        store.dispatch(updateSocketSession(updatedAppSession));

    // time zone selects
    private readonly timeZoneCode = () => selectTimeZoneCode(store.getState());
    private readonly applicationSessionUserTimeZoneCode = () => selectUserTimezone(store.getState());

    // time zone actions
    private readonly onUpdateTimeZone = () => store.dispatch(updateTimeZone(this.applicationSessionUserTimeZoneCode()));

    // live selects
    private readonly hasPendingLiveSubscriptionUpdates = () =>
        selectLiveHasPendingSubscriptionUpdates(store.getState());
    private readonly isAwaitingNextLiveResponse = () => selectLiveIsAwaitingNextResponse(store.getState());
    private readonly liveSubscriptionIds = () => selectLiveSubscriptionIds(store.getState());

    // live actions
    private readonly onLiveAdStatsReceived = (liveAdStats: AdStatEventMessage) =>
        store.dispatch(liveAdStatsReceived(liveAdStats));
    private readonly onLiveSubscriptionsSent = () => store.dispatch(liveSubscriptionsSent());
    private readonly onUpdateLiveSubscriptions = (subscriptionUpdates: AdStatSubscriptions) =>
        store.dispatch(updateLiveSubscriptions(subscriptionUpdates));

    // history selects
    private readonly hasPendingHistorySubscriptionUpdates = () =>
        selectHistoryHasPendingSubscriptionUpdates(store.getState());
    private readonly isAwaitingNextHistoryResponse = () => selectHistoryIsAwaitingNextResponse(store.getState());
    private readonly historySubscriptionIds = () => selectHistorySubscriptionIds(store.getState());

    // history actions
    private readonly onHistoryAdStatsReceived = (historyAdStats: AdStatHistoryEventMessage) =>
        store.dispatch(historyAdStatsReceived(historyAdStats));
    private readonly onHistorySubscriptionsSent = () => store.dispatch(historySubscriptionsSent());
    private readonly onUpdateHistorySubscriptions = (subscriptionUpdates: AdStatSubscriptions) =>
        store.dispatch(updateHistorySubscriptions(subscriptionUpdates));
}
