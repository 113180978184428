import { FC } from "react";
import { Operations } from "../constants";
import { Tag } from "antd";
import { Connection } from "@rubicon/antd-components";

interface Props {
    operation: Operations;
    direction?: "horizontal" | "vertical";
}

const getGroupLabel = (operation: Operations) => (operation === Operations.Any ? "OR" : "AND");
const getGroupColor = (operation: Operations) => (operation === Operations.Any ? "#7666F9" : "#C1715B");

export const ReadOnlyOperation: FC<Props> = ({ operation, direction }) => {
    const groupLabel = getGroupLabel(operation);
    const groupColor = getGroupColor(operation);

    const tagOperator = (
        <Tag color={groupColor} style={{ margin: 0 }}>
            {groupLabel}
        </Tag>
    );

    return (
        <>
            {direction !== undefined ? (
                <Connection color={groupColor} direction={direction} label={tagOperator} />
            ) : (
                tagOperator
            )}
        </>
    );
};
