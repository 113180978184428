import { GeoTarget } from "@app/core/services";
import { GeoTargetTypeIds } from "../constants";

const getCmaDmaSubLabel = (geoTarget: GeoTarget) => {
    const { metroName, subDivisionName, city, countryName } = geoTarget.geo;

    if (metroName && subDivisionName) {
        return `${metroName} - ${subDivisionName} - ${countryName}`;
    }
    if (city && subDivisionName) {
        return `${city} - ${subDivisionName} - ${countryName}`;
    }
    if (metroName) {
        return `${metroName} - ${countryName}`;
    }
    if (city) {
        return ` ${city} - ${countryName}`;
    }
    if (subDivisionName) {
        return `${subDivisionName} - ${countryName}`;
    }

    return countryName;
};

const getCitySubLabel = (geoTarget: GeoTarget) => {
    if (geoTarget.geo.subDivisionName) {
        return `${geoTarget.geo.city} - ${geoTarget.geo.subDivisionName} - ${geoTarget.geo.countryName}`;
    }
    return `${geoTarget.geo.city} - ${geoTarget.geo.countryName}`;
};

export const getGeoTargetLabel = (geoTarget: GeoTarget) => {
    if (geoTarget.type.id === GeoTargetTypeIds.PostalCode) {
        // Postal codes that come back from validatePostalCodes endpoint do not have city
        return `${geoTarget.type.name}: ${[geoTarget.postalCode, geoTarget.geo.city, geoTarget.geo.countryName]
            .filter(Boolean)
            .join(" - ")}`;
    }
    if (geoTarget.type.id === GeoTargetTypeIds.City) {
        return `${geoTarget.type.name}: ${getCitySubLabel(geoTarget)}`;
    }
    if (geoTarget.type.id === GeoTargetTypeIds.State) {
        return `${geoTarget.type.name}: ${geoTarget.geo.subDivisionName} - ${geoTarget.geo.countryName}`;
    }
    if (geoTarget.type.id === GeoTargetTypeIds.Cma || geoTarget.type.id === GeoTargetTypeIds.Dma) {
        return `${geoTarget.type.name}: ${getCmaDmaSubLabel(geoTarget)}`;
    }
    return `${geoTarget.type.name}: ${geoTarget.geo.countryName}`;
};

export const getGeoTargetValue = (geoTarget: GeoTarget) => {
    if (geoTarget.postalCode) {
        return `${geoTarget.geo.id}-${geoTarget.type.id}-${geoTarget.postalCode}`;
    }

    return `${geoTarget.geo.id}-${geoTarget.type.id}`;
};
