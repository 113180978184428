import { FC } from "react";
import { Form } from "antd";
import { useAssigneeSelect } from "./useAssigneeSelect";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { ExternalUser } from "@app/core/services/console";

export const AssigneeSelect: FC = () => {
    const { externalUsers, isLoading } = useAssigneeSelect();

    return (
        <Form.Item
            {...VERTICAL_LAYOUT_FIX}
            label={AD_SOURCE_FIELDS.ASSIGNEE.label}
            name={AD_SOURCE_FIELDS.ASSIGNEE.name}
        >
            <SelectWithValue<ExternalUser>
                data-sdet="adsource-assignee"
                fieldAsLabel={(user) => `${user.name} [${user.emailAddress}]`}
                fieldAsValue={["id"]}
                loading={isLoading}
                optionFilterProp="label"
                options={externalUsers}
                placeholder="Select…"
                showSearch
            />
        </Form.Item>
    );
};
