import {
    consoleApi,
    AD_UNITS_INHERITED_PIXELS_TAG,
    BRAND_INHERITED_PIXELS_TAG,
    PUBLISHER_INHERITED_PIXELS_TAG,
    SEAT_THIRD_PARTY_PIXELS_TAG,
    SUPPLY_INHERITED_PIXELS_TAG,
    SEAT_THIRD_PARTY_PIXEL_BY_ID_TAG,
} from "./console";
import { toQueryString } from "./utils";

export interface IdNameEntity {
    id: number;
    name: string;
}
export interface ThirdPartyPixel extends IdNameEntity {
    addToAdSourceByDefault?: boolean;
    creationDate: string;
    entityType: "ThirdPartyPixel";
    marketplaceId?: unknown | null;
    seatId?: number;
    sourceClassName: string | null;
    type: {
        creationDate: string;
        id: number;
        name: string;
        sort?: number;
        updateDate: string;
    };
    updateDate: string;
    value?: string;
}
export interface ThirdPartyPixelAppendSource extends ThirdPartyPixel {
    source: "Seat" | "Publisher" | "Brand" | "Supply";
}

interface PublisherPixelsParams {
    publisherId: number;
    includeMine: boolean;
}

interface BrandPixelParams {
    brandId: number;
    includeMine: boolean;
}

interface SupplyPixelParams {
    supplyId: number;
    includeMine: boolean;
}

interface AdUnitPixelParams {
    adUnitId: number;
    includeMine: boolean;
}

export interface SeatThirdPartyPixel extends IdNameEntity {
    creationDate: string;
    updateDate: string;
    value: string;
    type: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string;
    };
    addToAdSourceByDefault: boolean;
    sourceClassName: string | null;
}

export interface ErrorResponse {
    status: number;
    data: {
        errorCode: string;
        errorDescription: string;
        errors: null | { message: string }[];
        type: string;
    };
}
export interface ThirdPartyPixelType extends IdNameEntity {
    creationDate: string;
    updateDate: string;
    deleted: boolean;
    parentId: number;
    parentName: string;
    code: string;
}
export type ThirdPartyPixelPayload = Pick<SeatThirdPartyPixel, "name" | "value" | "addToAdSourceByDefault"> & {
    type: { id: number };
};
export const pixelsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getPublisherInheritedPixels: builder.query<ThirdPartyPixel[], PublisherPixelsParams>({
            query: ({ publisherId, ...rest }: PublisherPixelsParams) => {
                const params: Omit<PublisherPixelsParams, "publisherId"> = {
                    ...rest,
                };
                return `publishers/${publisherId}/inheritedThirdpartyPixels;${toQueryString(params)}`;
            },
            providesTags: (_, __, { publisherId }) => [{ type: PUBLISHER_INHERITED_PIXELS_TAG, id: publisherId }],
        }),
        getBrandInheritedPixels: builder.query<ThirdPartyPixel[], BrandPixelParams>({
            query: ({ brandId, ...rest }: BrandPixelParams) => {
                const params: Omit<BrandPixelParams, "brandId"> = {
                    ...rest,
                };
                return `brands/${brandId}/inheritedThirdpartyPixels;${toQueryString(params)}`;
            },
            providesTags: (_, __, { brandId }) => [{ type: BRAND_INHERITED_PIXELS_TAG, id: brandId }],
        }),
        getSupplyInheritedPixels: builder.query<ThirdPartyPixel[], SupplyPixelParams>({
            query: ({ supplyId, ...rest }: SupplyPixelParams) => {
                const params: Omit<SupplyPixelParams, "supplyId"> = {
                    ...rest,
                };
                return `supply/${supplyId}/inheritedThirdpartyPixels;${toQueryString(params)}`;
            },
            providesTags: (_, __, { supplyId }) => [{ type: SUPPLY_INHERITED_PIXELS_TAG, id: supplyId }],
        }),
        getAdUnitInheritedPixels: builder.query<ThirdPartyPixel[], AdUnitPixelParams>({
            query: ({ adUnitId, ...rest }: AdUnitPixelParams) => {
                const params: Omit<AdUnitPixelParams, "adUnitId"> = {
                    ...rest,
                };
                return `adUnits/${adUnitId}/inheritedThirdpartyPixels;${toQueryString(params)}`;
            },
            providesTags: (_, __, { adUnitId }) => [{ type: AD_UNITS_INHERITED_PIXELS_TAG, id: adUnitId }],
        }),
        getSeatThirdPartyPixels: builder.query<SeatThirdPartyPixel[], number>({
            query: (id: number) => {
                return `seats/${id}/thirdPartyPixels`;
            },
            providesTags: (_, __, id) => [{ type: SEAT_THIRD_PARTY_PIXELS_TAG, id }],
        }),
        getSeatThirdPartyPixelById: builder.query<SeatThirdPartyPixel, string | number>({
            query: (pixelId) => {
                return `thirdPartyPixels/${pixelId}`;
            },
            providesTags: (_, __, pixelId) => [{ type: SEAT_THIRD_PARTY_PIXEL_BY_ID_TAG, id: pixelId }],
        }),
        getThirdPartyPixelsTypes: builder.query<ThirdPartyPixelType[], void>({
            query: () => "thirdPartyPixels/types",
        }),
        createSeatThirdPartyPixel: builder.mutation<ThirdPartyPixel, { seatId: string; body: ThirdPartyPixelPayload }>({
            query: ({ seatId, body }) => ({
                url: `seats/${seatId}/thirdPartyPixels`,
                method: "POST",
                body,
            }),
            invalidatesTags: (_, __, { seatId }) => [{ type: SEAT_THIRD_PARTY_PIXELS_TAG, id: seatId }],
        }),
        updateSeatThirdPartyPixel: builder.mutation<
            ThirdPartyPixel,
            { seatId: string | number; pixelId: string | number; body: ThirdPartyPixelPayload }
        >({
            query: ({ pixelId, body }) => ({
                url: `thirdPartyPixels/${pixelId}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: (_, __, { seatId, pixelId }) => [
                { type: SEAT_THIRD_PARTY_PIXELS_TAG, id: seatId },
                { type: SEAT_THIRD_PARTY_PIXEL_BY_ID_TAG, id: pixelId },
            ],
        }),
        deleteSeatThirdPartyPixel: builder.mutation<void, { seatId: number; pixelId: number }>({
            query: ({ pixelId }) => ({
                url: `thirdPartyPixels/${pixelId}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, __, { seatId, pixelId }) => [
                { type: SEAT_THIRD_PARTY_PIXELS_TAG, id: seatId },
                { type: SEAT_THIRD_PARTY_PIXEL_BY_ID_TAG, id: pixelId },
            ],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetAdUnitInheritedPixelsQuery,
    useGetBrandInheritedPixelsQuery,
    useDeleteSeatThirdPartyPixelMutation,
    useGetPublisherInheritedPixelsQuery,
    useGetSeatThirdPartyPixelsQuery,
    useGetSeatThirdPartyPixelByIdQuery,
    useGetThirdPartyPixelsTypesQuery,
    useCreateSeatThirdPartyPixelMutation,
    useUpdateSeatThirdPartyPixelMutation,
    useGetSupplyInheritedPixelsQuery,
} = pixelsApi;
