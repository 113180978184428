import { FC } from "react";
import { Select, Typography } from "antd";
import { useTypeSelect } from "@app/features/inventory/InventorySeat/SeatDefaultPricesPage/TypeSelect/useTypeSelect";
import { Price } from "@app/features/inventory/InventorySeat/SeatDefaultPricesPage/DefaultPricesTable/useDefaultPricesForm";

interface TypeSelectProps {
    price: Price;
    onChange: (value: number) => void;
    validate: string | null;
}

const { Text } = Typography;
export const TypeSelect: FC<TypeSelectProps> = ({ onChange, price, validate }) => {
    const { isFetching, options, filterOption } = useTypeSelect();
    return (
        <Select
            allowClear
            value={price?.adSourceType}
            status={validate ? "error" : undefined}
            filterOption={filterOption}
            loading={isFetching}
            placeholder={validate ? <Text type="danger">{validate}</Text> : "Select Type..."}
            options={options}
            onChange={onChange}
            showSearch
            style={{ minWidth: "50%" }}
        />
    );
};
