import { Card, Col, Row, Collapse } from "antd";
import { FC } from "react";
import {
    mainStepSectionClassList,
    AD_SOURCE_SECTIONS,
    cardBodyStyle,
    AD_SOURCE_SECTIONS_NAME,
    COL_SIZES,
    GUTTER,
} from "../../../constants";
import { DefinitionSidePanel } from "../../DefinitionSidePanel";
import { useIsPbsDealType } from "../../useIsPbsDealType";
import { PixelsMultiselect } from "./Fields";

export const CustomPixelsSection: FC = () => {
    const isPbsDealType = useIsPbsDealType();
    const isVisible = !isPbsDealType;
    if (!isVisible) {
        return null;
    }
    return (
        <Collapse
            bordered
            ghost={false}
            className={mainStepSectionClassList}
            items={[
                {
                    key: "1",
                    forceRender: true,
                    label: AD_SOURCE_SECTIONS_NAME.CUSTOM_PIXELS,
                    extra: (
                        <DefinitionSidePanel
                            panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.CUSTOM_PIXELS]}
                            section={AD_SOURCE_SECTIONS.CUSTOM_PIXELS}
                            sectionSelector="#adSourceForm"
                        />
                    ),
                    children: (
                        <Card bordered={false} styles={{ body: cardBodyStyle }}>
                            <Row gutter={GUTTER}>
                                <Col {...COL_SIZES} md={16} lg={16} xl={16} xxl={16}>
                                    <PixelsMultiselect />
                                </Col>
                            </Row>
                        </Card>
                    ),
                },
            ]}
        />
    );
};
