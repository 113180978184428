import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { selectCustomPixelsSearchFilter, setSort } from "../reducer";
import { SeatThirdPartyPixel, useGetSeatThirdPartyPixelsQuery } from "@app/core/services";
import { useMemo } from "react";
import { SorterResult } from "antd/lib/table/interface";

interface UseCustomPixelsTable {
    customPixels: SeatThirdPartyPixel[];
    handleChange: (_: unknown, __: unknown, sorter: SorterResult<SeatThirdPartyPixel>) => void;
    isLoading: boolean;
}
// this hook is for filtering locally
export const useCustomPixelsTable = (): UseCustomPixelsTable => {
    const dispatch = useAppDispatch();
    const { seatId } = useParams<{ seatId: string }>();
    const { searchKeyword } = useAppSelector<{ searchKeyword: string }>(selectCustomPixelsSearchFilter);
    const { data = [], isFetching } = useGetSeatThirdPartyPixelsQuery(Number(seatId));

    // allowing to search name, value(Pixel URL), type
    const customPixels = useMemo(
        () =>
            data.filter(({ name, type, value }) =>
                [name, type.name, value].find((str) => str.toLowerCase().includes(searchKeyword.toLowerCase()))
            ),
        [searchKeyword, data]
    );

    const handleChange = (_a, _b, sort): void => {
        const { field, order } = sort;

        dispatch(setSort({ sortBy: field, sortOrder: order }));
    };

    return {
        customPixels,
        handleChange,
        isLoading: isFetching,
    };
};
