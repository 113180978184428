import { FC } from "react";
import { Card, Col, Row } from "antd";
import { COL_SIZES, CREATE_DEAL_FORM_ITEMS_NAME, GUTTER } from "@app/features/deals/constants";

import { cardBodyStyle } from "@app/features/deals/DealForm";
import {
    DealCurrencyField,
    DealImpressionsField,
    DealPriceModelField,
    DealRateField,
} from "@app/features/deals/DealForm/DealDemandFormSections/DealPricingSection/Fields";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import {
    AdSourceAdResponsePriceOverrideField,
    AdSourceUnderInboundFloor,
} from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceFloorSettingsSection/Fields";

export interface DealPricingSectionProps {
    priceModelShown: boolean;
    dealRateShown: boolean;
    dealImpressionsShown: boolean;
    mode: DealFormMode;
    adSourceAdResponsePriceOverrideShown: boolean;
}

export const DealPricingSection: FC<DealPricingSectionProps> = ({
    priceModelShown,
    dealImpressionsShown,
    dealRateShown,
    mode,
    adSourceAdResponsePriceOverrideShown,
}) => {
    return (
        <Card bordered={false} styles={{ body: cardBodyStyle }} data-sdet="deal-create-form-pricing-section">
            {priceModelShown && (
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <DealPriceModelField />
                    </Col>
                </Row>
            )}
            <Row gutter={GUTTER}>
                {dealRateShown && (
                    <Col {...COL_SIZES}>
                        <DealRateField />
                    </Col>
                )}
                <Col {...COL_SIZES}>
                    <DealCurrencyField name={CREATE_DEAL_FORM_ITEMS_NAME.CURRENCY} mode={mode} />
                </Col>
            </Row>
            {priceModelShown && (
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <AdSourceUnderInboundFloor />
                    </Col>
                </Row>
            )}
            {dealImpressionsShown && (
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <DealImpressionsField />
                    </Col>
                    <Col {...COL_SIZES}>
                        <AdSourceUnderInboundFloor />
                    </Col>
                </Row>
            )}
            {adSourceAdResponsePriceOverrideShown && (
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <AdSourceAdResponsePriceOverrideField />
                    </Col>
                </Row>
            )}
        </Card>
    );
};
