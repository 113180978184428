import { Form, Select } from "antd";
import { useCuePoints } from "./useCuePoints";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const CuePoints = () => {
    const { onChange } = useCuePoints();
    return (
        <Form.Item
            style={{ paddingBottom: "24px" }}
            name={CHANNEL_FORM_FIELDS.CUE_POINTS.name}
            label={CHANNEL_FORM_FIELDS.CUE_POINTS.label}
            help="These cuepoints will be used as fallback if they aren’t found in the Content Metadata feed. If no cuepoints are found then there won’t be any Mid-Roll Ad Breaks"
        >
            <Select style={{ maxWidth: "560px" }} mode="tags" onChange={onChange} tokenSeparators={[","]} />
        </Form.Item>
    );
};
