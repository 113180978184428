import { Targeting } from "@app/core/services";
import { Operations } from "@app/features/targeting/constants";
import { TargetingStub } from "@app/features/targeting/types";
import { gray10 } from "@rubicon/antd-components";
import { Typography } from "antd";
import { FC } from "react";
import {
    ExcludeConditionBlockHeader,
    IncludeConditionBlockHeader,
    getSelectedTargetingDimensionsCount,
    getSelectedTargetingDimensionsCountExcludes,
} from "../ReadOnlyTargetingBlocks";
import { ReadOnlyIncludeTargetingCollapse } from "../ReadOnlyTargetingCollapseInclude";
import { ReadOnlyExcludeTargetingCollapse } from "../ReadOnlyTargetingCollapseExclude";

const { Text } = Typography;

interface Props {
    targeting?: Targeting | TargetingStub;
}
export const ReadOnlyControlsTargeting: FC<Props> = ({ targeting }) => {
    if (!targeting) {
        return null;
    }

    const targetingIncludes = targeting.include;
    const includesDimensionCount = getSelectedTargetingDimensionsCount(targetingIncludes);

    const targetingExcludes = targeting.exclude;
    const excludesDimensionCount = getSelectedTargetingDimensionsCountExcludes(targetingExcludes);

    return (
        <>
            {includesDimensionCount > 0 && (
                <>
                    <IncludeConditionBlockHeader operation={Operations.All} />
                    <ReadOnlyIncludeTargetingCollapse targetingBlock={targeting} />
                </>
            )}
            {includesDimensionCount > 0 && excludesDimensionCount > 0 && (
                <Text style={{ color: gray10, fontWeight: 500 }}>And... </Text>
            )}

            {excludesDimensionCount > 0 && (
                <>
                    <ExcludeConditionBlockHeader operation={Operations.Any} />
                    <ReadOnlyExcludeTargetingCollapse targetingBlock={targeting} />
                </>
            )}
        </>
    );
};
