import { useUserAccess } from "@app/core/auth";
import { SEARCH } from "@app/core/components";
import { usePagination, useTableChanges } from "@app/core/components/hooks";
import { SeatDomain, useGetSeatDomainsQuery } from "@app/core/services";
import { uri } from "@rubicon/utils";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { SortOrder } from "antd/lib/table/interface";
import { useHistory, useLocation, useParams } from "react-router-dom";

export const CLASSIFICATIONS_ADVERTISER_DOMAINS_TABLE_SDET = "classifications-advertiser-domains-table";

export const getAdvertiserDomainssColumns = (
    sortBy: string,
    sortOrder: SortOrder,
    canEditSeat: boolean,
    onClickTransfer: (advertiserId: string | number) => void
) =>
    [
        {
            key: "adomain",
            dataIndex: "adomain",
            title: "Advertiser Domain",
            sorter: (a, b) => a.adomain.localeCompare(b.adomain),
            sortOrder: sortBy === "adomain" ? sortOrder : null,
        },
        {
            key: "advertiserName",
            dataIndex: "advertiserName",
            title: "Advertiser",
            sorter: (a, b) => a.advertiserName.localeCompare(b.advertiserName),
            sortOrder: sortBy === "advertiserName" ? sortOrder : null,
        },
        canEditSeat && {
            key: "actions",
            dataIndex: "actions",
            title: "Actions",
            render: (_, { advertiserId }) => <Button onClick={() => onClickTransfer(advertiserId)}>Transfer</Button>,
        },
    ].filter(Boolean) as ColumnsType<SeatDomain>;

export const getClassificationsAdvertiserDomainsParams = (queryString: string) => {
    const keyword = new URLSearchParams(queryString).get("search") || "";

    return {
        keyword,
        page: 1, // no pagination for this feature, however api allows for pagination if needed
        max: 100,
    };
};
export const ClassificationsAdvertiserDomainsTable = () => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const { search: queryString } = useLocation();

    const keyword = new URLSearchParams(queryString).get(SEARCH.key) || SEARCH.default;
    const { data = [], isFetching } = useGetSeatDomainsQuery({ seatId, params: { keyword } });

    const { handleChange, paginationConfig } = usePagination({ totalCount: data?.length || 0 });
    const { onChange, sortBy, sortOrder } = useTableChanges({
        handlePaginateChange: handleChange,
        sortParam: { key: "sort", default: "adomain" },
        filtersParams: [],
    });
    const { canEditSeat } = useUserAccess();

    const onClickTransfer = (advertiserId: string | number) => {
        const setDrawerParam = uri.setSearchParam(queryString, "drawer", "transfer");
        const nextQueryString = uri.setSearchParam(setDrawerParam, "advertiserId", String(advertiserId));
        history.push({
            search: nextQueryString,
        });
    };
    return (
        <Table
            size="small"
            dataSource={data}
            onChange={onChange}
            columns={getAdvertiserDomainssColumns(sortBy, sortOrder, canEditSeat, onClickTransfer)}
            showSorterTooltip={false}
            pagination={paginationConfig}
            loading={isFetching}
            data-sdet={CLASSIFICATIONS_ADVERTISER_DOMAINS_TABLE_SDET}
            rowKey="adomain"
        />
    );
};
