import { Form } from "antd";
import {
    INVENTORY_FORM_FIELDS,
    AUDIENCE_LOCK_OFF_ENTIRE_SEAT_TYPE,
} from "@app/features/inventory/InventorySeat/constants";

const { useWatch } = Form;

interface UseAudienceLockAllowedDomains {
    isOffEntireSeat: boolean;
}

export const useAudienceLockAllowedDomains = (): UseAudienceLockAllowedDomains => {
    const audienceLockType: number = useWatch(INVENTORY_FORM_FIELDS.AUDIENCE_LOCK_TYPE.name);
    const isOffEntireSeat: boolean = audienceLockType === AUDIENCE_LOCK_OFF_ENTIRE_SEAT_TYPE.value;
    return { isOffEntireSeat };
};
