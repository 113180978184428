import { FC, useRef } from "react";
import { useDealCurrencyConversion } from "@app/features/deals/useDealCurrencyConversion";
import { AdStat, getFills, getImpressions, getSkips, getTotalRevenue, getTries } from "@app/features/adStats";
import { useSelectAdStatDealById } from "@app/features/adStats/reducer";
import { TogglableChart } from "@app/core/components/charts/TogglableChart/TogglableChart";
import {
    FILLS_LABEL,
    IMPRESSIONS_LABEL,
    NET_REVENUE_LABEL,
    SKIPS_LABEL,
    TRIES_LABEL,
} from "@app/core/components/charts/constants";
import { ValueMetric1, ValueMetric2 } from "@app/core/components/charts/DualAxesChart";
import { RevenueTypes } from "@app/core/clients/console";
import moment from "moment-timezone";
import { MixCountAreaCurrencyLineChart } from "@app/core/components/charts/DualAxesChart/MixChartWithDualAxesCountAreaCurrencyLineChart";

export interface DealsChartStats {
    time: number;
    tries: number;
    fills: number;
    impressions: number;
    netRev: number;
    skips: number;
}

/**
 * The maximum number of events to render on the graph for a given line
 */
const MAX_EVENTS = 15;

/**
 * Events / Second
 *
 * @param existingMetrics The existing metrics for Events / Second
 * @param stats The current state of the stats coming in from AdStats
 * @returns The Events per second metrics with a new set of metrics added for the current time
 */
const getEventMetrics = (existingMetrics, stats: DealsChartStats) => {
    const time = moment(stats.time).format("HH:mm:ss");
    const newMetrics = [
        ...existingMetrics,
        {
            time,
            value1: stats?.skips || 0,
            name1: SKIPS_LABEL,
        },
        {
            time,
            value1: stats?.tries || 0,
            name1: TRIES_LABEL,
        },
        {
            time,
            value1: stats?.fills || 0,
            name1: FILLS_LABEL,
        },
        {
            time,
            value1: stats?.impressions || 0,
            name1: IMPRESSIONS_LABEL,
        },
    ];

    // inserts 4 events at a time
    if (newMetrics.length > MAX_EVENTS * 4) {
        newMetrics.splice(0, 4);
    }

    const timeSet = new Set<string>();
    const filteredNewMetrics = newMetrics.filter((metric) => {
        if (timeSet.has(`${metric.time}-${metric.name1}`)) {
            return false;
        }
        timeSet.add(`${metric.time}-${metric.name1}`);
        return true;
    });
    return filteredNewMetrics;
};

/**
 * Revenue / Second
 *
 * @param existingMetrics The existing metrics for Revenue / Second
 * @param stats The current state of the stats coming in from AdStats
 * @returns The Revenue per second metrics with a new set of metrics added for the current time
 */
const getRevenueMetrics = (existingMetrics, stats: DealsChartStats) => {
    const time = moment(stats.time).format("HH:mm:ss");
    const newMetrics = [
        ...existingMetrics,
        {
            time,
            value2: stats?.netRev || 0,
            name2: NET_REVENUE_LABEL,
        },
    ];

    if (newMetrics.length > MAX_EVENTS) {
        newMetrics.splice(0, 1);
    }

    const timeSet = new Set<string>();
    const filteredNewMetrics = newMetrics.filter((metric) => {
        if (timeSet.has(`${metric.time}-${metric.name1}`)) {
            return false;
        }
        timeSet.add(`${metric.time}-${metric.name1}`);
        return true;
    });
    return filteredNewMetrics;
};

interface DealsLiveChartProps {
    dealId: number;
    sourceCurrencyCode: string | undefined;
    chartId?: string;
}

export const DealsLiveChart: FC<DealsLiveChartProps> = ({ dealId, sourceCurrencyCode, chartId }) => {
    const { preferredCurrency, currencyConversions, currencyConversionMode } =
        useDealCurrencyConversion(sourceCurrencyCode);
    const revenueType = RevenueTypes.NET_REVENUE;
    const previousStat = useRef<DealsChartStats>();
    const eventsMetric = useRef<ValueMetric1[]>([]);
    const revMetrics = useRef<ValueMetric2[]>([]);

    const stats = useSelectAdStatDealById<DealsChartStats | undefined>(dealId, (adStat: AdStat | null) => {
        if (adStat) {
            return {
                time: adStat.ntime,
                skips: getSkips(adStat),
                tries: getTries(adStat),
                fills: getFills(adStat, preferredCurrency, currencyConversionMode),
                impressions: getImpressions(adStat, preferredCurrency, currencyConversionMode),
                netRev: getTotalRevenue(
                    adStat,
                    preferredCurrency,
                    currencyConversions,
                    currencyConversionMode,
                    revenueType
                ),
            };
        }
    });

    const isDuplicateTime = stats && previousStat.current && stats.time === previousStat.current.time;
    if (previousStat.current && stats && !isDuplicateTime) {
        /*
         * Chart is to show the difference in stats from event to event.
         * The stats should always be rising or at least remaining constant,
         * so we show here how many of each stat has been added since the last event.
         */
        const diff = {
            time: stats.time,
            skips: stats.skips - previousStat.current.skips,
            tries: stats.tries - previousStat.current.tries,
            fills: stats.fills - previousStat.current.fills,
            impressions: stats.impressions - previousStat.current.impressions,
            netRev: stats.netRev - previousStat.current.netRev,
        };

        eventsMetric.current = getEventMetrics(eventsMetric.current, diff);
        revMetrics.current = getRevenueMetrics(revMetrics.current, diff);
    }

    if (!isDuplicateTime) {
        previousStat.current = stats;
    }

    return (
        <>
            <TogglableChart
                metricOne={eventsMetric.current}
                metricTwo={revMetrics.current}
                chartRenderer={(filteredMetricOne, filteredMetricTwo) => (
                    <MixCountAreaCurrencyLineChart
                        height={300}
                        chartId={`deal-live-chart-${chartId}`}
                        metricOne={filteredMetricOne}
                        metricTwo={filteredMetricTwo}
                        metricOneYAxisTitle="Events / Sec"
                        metricTwoYAxisTitle="Rev / Sec"
                    />
                )}
            />
        </>
    );
};
