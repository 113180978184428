import { FC } from "react";
import { Col, Form, Row, Select } from "antd";
import { ChangeLogs } from "@app/core/services";
import { useChangeLogFilters } from "./useChangeLogFilters";

interface Props {
    changeLogs: ChangeLogs;
    onFiltersChange: (changed, allValues) => void;
}

export const ChangeLogFilters: FC<Props> = ({ changeLogs, onFiltersChange }) => {
    const { form, actionOptions, userOptions } = useChangeLogFilters(changeLogs);

    return (
        <Form
            layout="vertical"
            form={form}
            onFieldsChange={onFiltersChange}
            initialValues={{ actionType: actionOptions[0], email: userOptions[0] }}
        >
            <Row gutter={16}>
                <Col span={6}>
                    <Form.Item name="actionType" label="Action">
                        <Select options={actionOptions} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="email" label="User">
                        <Select options={userOptions} showSearch />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
