import { HelpKeysDrawer, HelpKeysDrawerContent } from "@app/core/components/HelpKeysDrawer";

export const DEFINITION_FIELDS = [
    { label: "Name", key: "name", helpPath: "advertiserdomains.name" },
    { label: "Type", key: "type", helpPath: "advertiserdomains.type" },
    { label: "Domains", key: "domains", helpPath: "advertiserdomains.domainFilters" },
];

export const AdvertiserDomainDefinitionsContent = () => <HelpKeysDrawerContent definitionsFields={DEFINITION_FIELDS} />;

export const AdvertiserDomainDefinitionsButtonAndDrawer = () => (
    <HelpKeysDrawer
        buttonName="Advertiser Domains"
        panelLabel="Advertiser Domain Definitions"
        definitionsFields={DEFINITION_FIELDS}
    />
);
