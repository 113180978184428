import { FC, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Publisher } from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import { BrandSafetyActions } from "../BrandSafetyActions";
import {
    setEditActiveKey,
    selectInventoryBrandSafetyEditActiveKey,
} from "@app/features/inventory/InventoryBrandSafety/reducer";
import { FORM_FIELDS } from "../../../BrandSafetyForm/constants";
import { BrandSafetyPublisherForm } from "../BrandSafetyPublisherForm";
import { Collapse, CollapsePanelProps, CollapseProps, Form } from "antd";
import { FORM_GRID_OPTIONS } from "../../BrandSafetyPublisherPage/constants";
import { BlockedBuyerSeatListFormItem } from "../../../BrandSafetyForm/BuyerBlockSeatListFormItem";
import { usePublisherBrandSafetyEditForm } from "../../../BrandSafetyForm/usePublisherBrandSafetyEditForm";
import { BlockedAdvertiserDomainsFormItem } from "../../../BrandSafetyForm/BlockedAdvertiserDomainsFormItem";
import { BlockedAdvertiserDomainListsFormItem } from "../../../BrandSafetyForm/BlockedAdvertiserDomainListsFormItem";
import { BrandSafetyCollapsePanelKeys } from "@app/features/inventory/InventoryBrandSafety/InventoryBrandSafetyPage/BrandSafetyCollapsePanel";

type Props = {
    publisher: Publisher;
    onChange?: CollapseProps["onChange"];
} & CollapsePanelProps;

export const BrandSafetyPublisherCollapsePanel: FC<Props> = ({ publisher, onChange, ...props }) => {
    const dispatch = useDispatch();
    const editActiveKey = useAppSelector(selectInventoryBrandSafetyEditActiveKey);

    const { publisherForm, resetFormsFields, initialValues, handleSubmit, isUpdating, handleDelete, isDeleting } =
        usePublisherBrandSafetyEditForm(publisher);

    const isOnEdit: boolean = editActiveKey === BrandSafetyCollapsePanelKeys.PUBLISHER;

    const onEdit = (): void => {
        onChange?.(BrandSafetyCollapsePanelKeys.PUBLISHER);
        dispatch(setEditActiveKey(BrandSafetyCollapsePanelKeys.PUBLISHER));
    };

    const onCancel = useCallback((): void => {
        resetFormsFields();
        dispatch(setEditActiveKey(null));
    }, [resetFormsFields, dispatch]);

    useEffect(() => {
        return () => {
            onCancel();
        };
    }, [publisher, onCancel]);

    return (
        <Collapse.Panel
            extra={
                <BrandSafetyActions
                    isDeleting={isDeleting}
                    handleDelete={handleDelete}
                    isOnEdit={isOnEdit}
                    handleEdit={onEdit}
                    handleCancel={onCancel}
                    handleSubmit={handleSubmit}
                    isUpdating={isUpdating}
                />
            }
            {...props}
        >
            {isOnEdit ? (
                <Form
                    layout="vertical"
                    {...FORM_GRID_OPTIONS["vertical"]}
                    labelAlign="left"
                    form={publisherForm}
                    initialValues={initialValues}
                >
                    <BlockedAdvertiserDomainListsFormItem {...FORM_FIELDS.BLOCKED_ADVERTISER_DOMAIN_LISTS} />
                    <BlockedAdvertiserDomainsFormItem {...FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS} />
                    <BlockedBuyerSeatListFormItem {...FORM_FIELDS.BLOCKED_BUYER_SEAT_LIST} />
                </Form>
            ) : (
                <BrandSafetyPublisherForm publisher={publisher} />
            )}
        </Collapse.Panel>
    );
};
