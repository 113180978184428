import { FC } from "react";
import { Form, Input } from "antd";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const PartnerDomain: FC = () => {
    return (
        <Form.Item
            name={CHANNEL_FORM_FIELDS.PARTNER_DOMAIN.name}
            label={CHANNEL_FORM_FIELDS.PARTNER_DOMAIN.label}
            wrapperCol={{ xs: 24, sm: 24, md: 10, lg: 6, xl: 11, xxl: 9 }}
        >
            <Input allowClear placeholder={CHANNEL_FORM_FIELDS.PARTNER_DOMAIN.placeholder} />
        </Form.Item>
    );
};
