import { Button } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { FC } from "react";
import { useHelpKeysButton } from "./useHelpKeysButton";
import { ADVANCED_FLOOR_SECTION_NAMES } from "../constants";
import { brandCobalt } from "@rubicon/antd-components";

interface Props {
    label: string;
    sectionKey: ADVANCED_FLOOR_SECTION_NAMES;
    mode?: "create" | "edit";
    handleOpen?: () => void;
}
export const HelpKeysButton: FC<Props> = ({ label, sectionKey, mode, handleOpen = () => {} }) => {
    const { onClick } = useHelpKeysButton(sectionKey, mode);

    return (
        <Button
            onClick={() => {
                onClick();
                handleOpen();
            }}
            type="link"
            style={{
                color: brandCobalt,
                padding: 0,
                height: "fit-content",
            }}
            data-sdet={sectionKey.toLocaleLowerCase().replace(/ /g, "-")}
        >
            <InfoCircleOutlined />
            {label}
        </Button>
    );
};
