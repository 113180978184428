import { useEffect, useMemo } from "react";
import { message } from "antd";
import { useAppSelector } from "@app/core/store";
import { useUpdateUserPreferencesMutation, UserPreference } from "@app/core/services";
import { InventorySettings } from "@app/features/inventory/InventoryHealth/InventoryHealthPage/reducer";
import { useSeatAuthContext } from "@app/core/auth";

export const useUpdateSettingsOnChange = () => {
    const { session } = useSeatAuthContext();
    const settings = useAppSelector((state) => state.inventoryHealth.settings);

    const [updateUserPreferences] = useUpdateUserPreferencesMutation();

    const handleUpdateUserPreferences = useMemo(
        () => async (userPreferences: InventorySettings) => {
            const body = Object.keys(userPreferences).reduce<
                { key: keyof InventorySettings; value: InventorySettings[keyof InventorySettings] }[]
            >((preferences, key: keyof typeof userPreferences) => {
                if (userPreferences[key]) {
                    preferences.push({
                        key,
                        value: userPreferences[key],
                    });
                }
                return preferences;
            }, []) as Partial<UserPreference>[];
            try {
                if (session) {
                    await updateUserPreferences({ id: session.user.id, body });
                }
            } catch (err) {
                message.error("Failed to Update Inventory Settings");
            }
        },
        [session, updateUserPreferences]
    );

    useEffect(() => {
        const { values, shouldUpdate } = settings;
        if (shouldUpdate) {
            handleUpdateUserPreferences(values);
        }
    }, [settings, handleUpdateUserPreferences]);
};
