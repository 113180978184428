import { numberNormalize } from "@app/core/components/helpers";
import { CSSProperties, FC, useState } from "react";

interface InputProps {
    component: FC;
    onChange: (value: number | null) => void;
    placeholder: string;
    style?: CSSProperties;
}

export const NormalizedInput: FC<InputProps> = ({ onChange, component: Component, ...props }) => {
    const [value, setValue] = useState<number | null>(null);
    const handleChange = (e) => {
        const normalizedValue = numberNormalize(e.target.value);
        setValue(normalizedValue);
        onChange(normalizedValue);
    };

    // @ts-expect-error // Component here is either AntD component or customized component
    return <Component {...props} value={value} onChange={handleChange} />;
};
