import {
    LINEARITY_LINEAR,
    AD_UNIT_FORM_FIELDS,
    AD_UNIT_CUSTOM_TYPE,
    YES_OPTION,
} from "@app/features/inventory/HierarchyForms/constants";
import { useAdUnitFormInstance } from "../AdUnitFormInstance";
import { LabeledValue } from "antd/lib/select";

interface UseDefaultsSection {
    isTypeShown: boolean;
    isStartDelayShown: boolean;
    isSkippableMinimumAfterShown: boolean;
}

export const useSupplyDetailsSection = (): UseDefaultsSection => {
    const { useWatch } = useAdUnitFormInstance();
    const linearity: number = useWatch(AD_UNIT_FORM_FIELDS.LINEARITY.name);
    const type: LabeledValue = useWatch(AD_UNIT_FORM_FIELDS.TYPE.name);
    const skippable: boolean | string = useWatch(AD_UNIT_FORM_FIELDS.SKIPPABLE.name);

    const isTypeShown: boolean = linearity === LINEARITY_LINEAR.value;
    const isStartDelayShown: boolean = type?.value === AD_UNIT_CUSTOM_TYPE.value;
    const isSkippableMinimumAfterShown: boolean = skippable === YES_OPTION.value;

    return { isTypeShown, isStartDelayShown, isSkippableMinimumAfterShown };
};
