import { useSeatAuthContext } from "@app/core/auth";
import { SnapshotsEntityTypes, useSnapshotsPrefetch } from "@app/core/services/adStats/statsSnapshots";
import { useEffect } from "react";

export const useUnitPrefetchSnapshos = (snapShotEntity: SnapshotsEntityTypes) => {
    const { context } = useSeatAuthContext();
    const prefetchSnapshotFolders = useSnapshotsPrefetch("getSeatStatsSnapshotsFolders");

    useEffect(() => {
        if (context) {
            prefetchSnapshotFolders({ seatId: context.id, entityType: snapShotEntity });
        }
    }, [context, prefetchSnapshotFolders, snapShotEntity]);
};
