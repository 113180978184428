import { DefinationField } from "@app/core/components/HelpKeysDrawer";
import { useMemo, useState } from "react";
import { TARGETING_HELP_KEYS } from "../TargetingForm/constants";

interface UseTargetingDetailsDefinitionDrawer {
    showDefinition: boolean;
    title: string;
    handleShowDefinition: (type: string) => void;
    handleCloseDefinition: () => void;
    definitions: DefinationField[];
}

export const useTargetingDetailsDefinitionDrawer = (): UseTargetingDetailsDefinitionDrawer => {
    const [showDefinition, setShowDefinition] = useState<boolean>(false);
    const [definitionType, setDefinitionType] = useState<string>("");

    const { title, definitions } = useMemo(
        () => ({
            definitions: TARGETING_HELP_KEYS[definitionType] || [],
            title: `${definitionType} Definition`,
        }),
        [definitionType]
    );

    const handleShowDefinition = (type: string) => {
        setShowDefinition(true);
        setDefinitionType(type);
    };

    const handleCloseDefinition = () => {
        setShowDefinition(false);
        setDefinitionType("");
    };

    return {
        showDefinition,
        title,
        definitions,
        handleShowDefinition,
        handleCloseDefinition,
    };
};
