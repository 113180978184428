import { Form } from "antd";
import {
    AD_SOURCE_FIELDS,
    VERTICAL_LAYOUT_FIX,
    AdSourceAdvertiserDomainModeIds,
} from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { SeatAdSourcesType, useGetAdSourceIabCategoryBlockModesQuery } from "@app/core/services";

const { useWatch } = Form;

export const IABCategoryBlockModeSelect = () => {
    const { isLoading, data: options = [] } = useGetAdSourceIabCategoryBlockModesQuery();
    const advertiserDomainMode = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.MODE.name);
    const disabled: boolean = advertiserDomainMode?.id === AdSourceAdvertiserDomainModeIds.ALLOW_OVERRIDE_INVENTORY;

    return (
        <Form.Item
            {...VERTICAL_LAYOUT_FIX}
            label={AD_SOURCE_FIELDS.IAB_CATEGORY_BLOCK_MODE.label}
            name={AD_SOURCE_FIELDS.IAB_CATEGORY_BLOCK_MODE.name}
        >
            <SelectWithValue
                disabled={disabled}
                placeholder="Select…"
                loading={isLoading}
                options={options}
                fieldAsLabel={["name"]}
                fieldAsValue={["id"]}
            />
        </Form.Item>
    );
};
