import { Form, Input } from "antd";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { useIsFieldVisible } from "./useExternalContractNumberIsVisible";

export const ExternalContractNumberInput = () => {
    const isVisible = useIsFieldVisible();
    return isVisible ? (
        <Form.Item
            {...VERTICAL_LAYOUT_FIX}
            label={AD_SOURCE_FIELDS.EXTERNAL_CONTRACT_NUMBER.label}
            name={AD_SOURCE_FIELDS.EXTERNAL_CONTRACT_NUMBER.name}
        >
            <Input data-sdet="adsource-external-contract-number" />
        </Form.Item>
    ) : null;
};
