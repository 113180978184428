import { FC } from "react";
import { Card } from "antd";
import { useParams } from "react-router-dom";
import { FloorsTable } from "@app/features/inventory/components/FloorsTable";

export const FloorsSection: FC = () => {
    const { supplyId } = useParams<{ supplyId: string }>();
    return (
        <Card bordered={false}>
            <FloorsTable id={Number(supplyId)} sourceSelf="Supply" hiddenColumns={["customPrice"]} />
        </Card>
    );
};
