import { InfoCircleOutlined } from "@ant-design/icons";
import { FC } from "react";
import { SECTIONS_NAMES } from "../constants";
import { useHelpKeysButton } from "./useHelpKeysButton";
import { StyledButtonLink } from "@app/core/components";

interface Props {
    label: string;
    sectionKey: SECTIONS_NAMES;
}
export const HelpKeysButton: FC<Props> = ({ label, sectionKey }) => {
    const { onClick } = useHelpKeysButton(sectionKey);

    return (
        <StyledButtonLink onClick={onClick} type="link" data-sdet={sectionKey.toLocaleLowerCase().replace(/ /g, "-")}>
            <InfoCircleOutlined />
            {label}
        </StyledButtonLink>
    );
};
