import { consoleApi, IAB_CATEGORIES_TAG } from "./console";

export interface IabCategory {
    creationDate: string;
    updateDate: string;
    id: number;
    parentId: number;
    parentName: string;
    name: string;
    code: string;
    entityType: string;
}

export const iabCategoriesApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getIabCategories: builder.query<IabCategory[], void>({
            query: () => `/iabCategories`,
            providesTags: [IAB_CATEGORIES_TAG],
        }),
    }),
    overrideExisting: false,
});

export const { useGetIabCategoriesQuery } = iabCategoriesApi;
