import { FC } from "react";
import { Button, Modal, Typography } from "antd";
import { useDispatch } from "react-redux";
import { setCompleteWithErrorsMessage } from "../reducer";
import { useClipBoardButton } from "@app/core/components/ClipBoardButton/useClipBoardButton";

interface Props {
    errorMessage: string;
}
export const BulkOperationExecutionCompleteWithErrorsModal: FC<Props> = ({ errorMessage }) => {
    const dispatch = useDispatch();
    const handleOk = () => dispatch(setCompleteWithErrorsMessage(null));

    const { handleCopy } = useClipBoardButton();
    const errorMessages = errorMessage.split(/<|>/).filter((str) => str !== "\n");

    return (
        <Modal
            open={Boolean(errorMessage)}
            onOk={handleOk}
            onCancel={handleOk}
            title="Operation Completed"
            footer={(_, { OkBtn }) => (
                <>
                    <Button onClick={() => handleCopy(errorMessages.join("\n"), "Error report")}>Copy Errors</Button>
                    <OkBtn />
                </>
            )}
        >
            <Typography.Paragraph>
                Your job has completed but not all entities updated successfully. See error report below.
            </Typography.Paragraph>
            {errorMessages.map((message) => (
                <Typography.Paragraph key={message}>{message}</Typography.Paragraph>
            ))}
        </Modal>
    );
};
