import { FC } from "react";
import { useAdSourceTotalImpressionsField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceDeliveryDetailsSection/Fields/AdSourceTotalImpressionsField/useAdSourceTotalImpressionsField";
import { ReadOnlyField } from "@app/core/components/Fields/ReadOnlyField";

export const AdSourceTotalImpressionsField: FC = () => {
    const { value } = useAdSourceTotalImpressionsField();
    return (
        <ReadOnlyField
            required={true}
            message="Total Impressions is required"
            dataSdet="ad-source-total-impressions-field"
            label="Total Impressions"
        >
            {value}
        </ReadOnlyField>
    );
};
