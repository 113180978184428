import { MarketplaceInfo, useGetMarketplacesQuery } from "@app/core/services";
import { ReadonlyControl, SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { Col, Form } from "antd";

export const SharedMarketplaceMultiselect = () => {
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const isReadonly = dealObjectMode === "view" || dealObjectMode === "edit";
    const { data: marketplaces = [], isLoading } = useGetMarketplacesQuery(undefined, { skip: isReadonly });
    return (
        <Col span={isReadonly ? 12 : 24}>
            <Form.Item
                label="Shared Marketplaces"
                extra={
                    !isReadonly &&
                    "Additional marketplaces selected above determine where else this demand deal may be utilized"
                }
                name={["dealUnderEdit", "sharedMarketplaces"]}
            >
                {isReadonly ? (
                    <ReadonlyControl<MarketplaceInfo["deal"]["sharedMarketplaces"]>
                        getLabel={(marketplace) =>
                            marketplace.length ? marketplace.map(({ name }) => name).join(", ") : "None"
                        }
                    />
                ) : (
                    <SelectWithValue
                        fieldAsLabel={["name"]}
                        fieldAsValue={["id"]}
                        loading={isLoading}
                        mode="multiple"
                        optionFilterProp="label" // For internal LabelValue
                        options={marketplaces}
                        placeholder="Select Shared Marketplaces…"
                    />
                )}
            </Form.Item>
        </Col>
    );
};
