import { FC, useState } from "react";
import { Brand, BulkOperationSourceEntity, ChangeLogEntityType, useGetBrandChannelQuery } from "@app/core/services";
import { useUserAccess } from "@app/core/auth";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ItemType } from "antd/lib/menu/interface";
import { Space, Button, Dropdown, Tooltip } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { InventoryDetailsDrawerType } from "@app/features/inventory/DetailsDrawer/reducer";
import { TagsOutlined, EditOutlined, MoreOutlined, MonitorOutlined, PlusOutlined } from "@ant-design/icons";
import { AdsTextDrawer } from "@app/features/inventory/AdsTextDrawer";
import { downloadTags } from "@app/features/inventory/helpers";
import { openChangelog, setChangelogEntity } from "@app/features/inventory/InventoryChangelog";
import { useAppDispatch } from "@app/core/store";
import { BulkOperationsUrlState } from "@app/core/components";

interface InventoryHealthBrandListTableActionsProps {
    brand: Brand;
    publisherId: number | null;
}

export const InventoryHealthBrandListTableActions: FC<InventoryHealthBrandListTableActionsProps> = ({
    brand,
    publisherId,
}) => {
    const isChannel = brand.type.name === "Channel";
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { hasSeatWriteAccess } = useUserAccess();
    const { seatId } = useParams<{ seatId: string }>();
    const [isAdsTextDrawerVisible, setIsAdsTextDrawerVisible] = useState(false);
    const { data: brandChannelData } = useGetBrandChannelQuery(Number(brand.id), {
        skip: !isChannel,
    });

    const handleChangelog = (): void => {
        dispatch(openChangelog());
        dispatch(setChangelogEntity({ entity: brand, entityType: ChangeLogEntityType.Brand }));
    };
    const handleTagParams = (): void =>
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_TAG_PARAMS(
                false,
                seatId,
                InventoryDetailsDrawerType.BRAND,
                publisherId as number,
                brand.id
            )
        );

    const handleCreativeReview = (): void =>
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_CREATIVE_REVIEW(
                false,
                seatId,
                InventoryDetailsDrawerType.BRAND,
                publisherId as number,
                brand.id
            )
        );

    const handleDiagnostics = () => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_DIAGNOSTICS(
                false,
                seatId,
                InventoryDetailsDrawerType.BRAND,
                publisherId as number,
                brand.id
            )
        );
    };
    const floorAnalysis = (): void =>
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_FLOOR_ANALYSIS(
                false,
                seatId,
                InventoryDetailsDrawerType.BRAND,
                publisherId as number,
                brand.id
            )
        );

    const handleCopyBrand = (): void => {
        if (publisherId)
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_COPY(Number(seatId), publisherId, brand.id)
            );
    };

    const handleAddFloor = (): void =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_PUBLISHER_BRAND(seatId, brand.id));

    const handleCopyWithChildren = (): void => {
        const routeState: BulkOperationsUrlState = {
            type: "copy",
            entityType: brand.entityType.toLowerCase() as BulkOperationSourceEntity,
            entityId: String(brand.id),
            withChildren: true,
        };
        history.push({
            pathname: ROUTE_FORMATTERS.SEAT_CONTROLS_BULK_OPERATIONS_CREATE(Number(seatId)),
            state: routeState,
        });
    };

    const handleEdit = (): void => {
        if (!publisherId) return;
        if (isChannel) {
            return history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_CHANNEL_EDIT(
                    Number(seatId),
                    Number(publisherId),
                    Number(brand.id)
                )
            );
        }
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_EDIT(Number(seatId), publisherId, brand.id)
        );
    };
    const handleAddSupply = (): void => {
        if (publisherId)
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_CREATE(seatId, publisherId, brand.id)
            );
    };

    const handleOpenAdsTextDrawer = () => {
        setIsAdsTextDrawerVisible(true);
    };

    const handleGetTags = () => {
        downloadTags(seatId);
    };

    const handleCloseAdsTextDrawer = () => {
        setIsAdsTextDrawerVisible(false);
    };
    const handleLadle = () => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_LADLE(
                false,
                seatId,
                InventoryDetailsDrawerType.BRAND_CHANNEL,
                Number(publisherId),
                Number(brandChannelData?.adUnit?.id)
            ) as string
        );
    };

    const items: ItemType[] = [
        {
            label: "Creative Review",
            key: "creativeReview",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleCreativeReview();
            },
        },
        {
            label: "Floor Analysis",
            key: "floorAnalysis",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                floorAnalysis();
            },
        },
        hasSeatWriteAccess && {
            label: "Change History",
            key: "changeHistory",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleChangelog();
            },
        },
        hasSeatWriteAccess && {
            label: "Ads.txt Pub ID",
            key: "adsTxtPubId",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleOpenAdsTextDrawer();
            },
        },
        isChannel && {
            label: "Get Tag",
            key: "getTag",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleGetTags();
            },
        },
        isChannel && {
            label: "Ladle",
            key: "ladle",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleLadle();
            },
        },
    ].filter(Boolean) as ItemType[];

    const createActionsItems: ItemType[] = [
        hasSeatWriteAccess &&
            !isChannel && {
                label: "Add a Supply for this Brand",
                key: "addSupply",
                onClick: (e) => {
                    e.domEvent.stopPropagation();
                    handleAddSupply();
                },
            },
        hasSeatWriteAccess &&
            !isChannel && {
                label: "Copy Brand Only",
                key: "copyBrand",
                onClick: (e) => {
                    e.domEvent.stopPropagation();
                    handleCopyBrand();
                },
            },
        hasSeatWriteAccess &&
            !isChannel && {
                label: "Copy Brand + Children",
                key: "copyBrandChildren",
                onClick: (e) => {
                    e.domEvent.stopPropagation();
                    handleCopyWithChildren();
                },
            },
        hasSeatWriteAccess && {
            label: "Add a Floor",
            key: "createFloor",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleAddFloor();
            },
        },
    ].filter(Boolean) as ItemType[];

    return (
        <Space style={{ width: "100%" }}>
            <Button.Group>
                <Tooltip title="Inbound Request Params">
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleTagParams();
                        }}
                        icon={<TagsOutlined />}
                        size="small"
                    />
                </Tooltip>
                <Tooltip title="Diagnostic">
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDiagnostics();
                        }}
                        icon={<MonitorOutlined />}
                        size="small"
                    />
                </Tooltip>
                {hasSeatWriteAccess && (
                    <Tooltip title="Edit">
                        <Button
                            icon={<EditOutlined />}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleEdit();
                            }}
                            size="small"
                        />
                    </Tooltip>
                )}
                {hasSeatWriteAccess && (
                    <Tooltip title="Create">
                        <Dropdown placement="bottomLeft" trigger={["click"]} menu={{ items: createActionsItems }}>
                            <Button
                                icon={<PlusOutlined rotate={90} />}
                                size="small"
                                onClick={(e) => e.stopPropagation()}
                            />
                        </Dropdown>
                    </Tooltip>
                )}
                <Dropdown menu={{ items }} placement="bottomLeft" trigger={["click"]}>
                    <Button icon={<MoreOutlined rotate={90} />} size="small" onClick={(e) => e.stopPropagation()} />
                </Dropdown>
            </Button.Group>
            {isAdsTextDrawerVisible && (
                <AdsTextDrawer
                    visible={isAdsTextDrawerVisible}
                    publisherId={Number(publisherId)}
                    handleClose={handleCloseAdsTextDrawer}
                />
            )}
        </Space>
    );
};
