import { useCallback, useRef } from "react";
import { FormInstance } from "antd";
import { MarketplaceInfo, useUpdateBuyerDealMutation, useUpdateDemandDealMutation } from "@app/core/services";
import { useAppDispatch } from "@app/core/store";
import { parseDateStringFromApi } from "@app/core/utils";
import {
    SyncedFields,
    notifySyncedFieldsError,
    notifySyncedFieldsSuccess,
    useIsAdSourceOutOfSync,
} from "@app/features/syncedFields";
import { AD_SOURCE_FIELDS } from "../../constants";
import { AdSourcesForm } from "..";
import { useMapSyncedFieldsToDeal } from "./useMapSyncedFieldsToDeal";
import { setAdSourceHasDealChanges } from "../../reducer";
import { parsePriceModelFieldsToApi } from "../utils/parsePriceModelFieldsToApi";

interface UseUpdateDeal {
    updateDeal: (syncedFields: SyncedFields) => Promise<void>;
}

export const useUpdateDeal = (
    marketplaceInfo: MarketplaceInfo | null,
    adSourceForm: FormInstance<AdSourcesForm>,
    adSourceTypeId: number | undefined,
    adSourceFloorTypeId: number | undefined
): UseUpdateDeal => {
    const isUpdatingRef = useRef<boolean>(false);
    const [updateBuyerSeatDeal] = useUpdateBuyerDealMutation();
    const [updateDemandSeatDeal] = useUpdateDemandDealMutation();
    const { mapSyncedFieldsToDealFields, mapSyncedFieldsToLegacyDealFields } = useMapSyncedFieldsToDeal();
    const { setIsAdSourceOutOfSync, setIsSyncingAdSourceChangesToDeal } = useIsAdSourceOutOfSync();
    const dispatch = useAppDispatch();

    const updateDeal = useCallback(
        async (syncedFields: SyncedFields) => {
            const hasSyncedFieldUpdates = Boolean(Object.keys(syncedFields).length);
            if (!hasSyncedFieldUpdates) {
                console.warn("attempted to update synced deal with no values");
                return;
            }

            if (!marketplaceInfo || isUpdatingRef.current) {
                notifySyncedFieldsError(syncedFields, adSourceTypeId, adSourceFloorTypeId);
                return;
            }

            isUpdatingRef.current = true;
            try {
                const dealType = marketplaceInfo?.deal.entityType;
                const isBuyerDeal = dealType === "BuyerDeal";
                const isDemandDeal = dealType === "DemandDeal";
                const isLegacyDeal = dealType === "Deal";

                let updatedMarketplaceInfo: MarketplaceInfo | undefined;

                if (isBuyerDeal) {
                    setIsSyncingAdSourceChangesToDeal(true);
                    updatedMarketplaceInfo = mapSyncedFieldsToDealFields(syncedFields, marketplaceInfo, adSourceForm);
                    const dealResponse = await updateBuyerSeatDeal({
                        id: updatedMarketplaceInfo.deal.id,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        body: updatedMarketplaceInfo.deal,
                    }).unwrap();
                    const updatedAdSourceFormValues = {
                        ...updatedMarketplaceInfo,
                        deal: {
                            ...updatedMarketplaceInfo.deal,
                            ...parsePriceModelFieldsToApi(adSourceTypeId),
                            startTime: parseDateStringFromApi(dealResponse.startTime, dealResponse.timeZone?.code),
                            endTime: dealResponse.endTime
                                ? parseDateStringFromApi(dealResponse.endTime, dealResponse.timeZone?.code)
                                : null,
                        },
                    };
                    adSourceForm.setFieldValue(AD_SOURCE_FIELDS.DEMAND.name, [updatedAdSourceFormValues]);
                    const didShowSyncNotifications = notifySyncedFieldsSuccess(
                        syncedFields,
                        adSourceTypeId,
                        adSourceFloorTypeId
                    );
                    if (didShowSyncNotifications) {
                        setIsAdSourceOutOfSync(didShowSyncNotifications);
                    }
                    dispatch(setAdSourceHasDealChanges(true));
                    setIsSyncingAdSourceChangesToDeal(false);
                } else if (isDemandDeal) {
                    setIsSyncingAdSourceChangesToDeal(true);
                    updatedMarketplaceInfo = mapSyncedFieldsToDealFields(syncedFields, marketplaceInfo, adSourceForm);
                    const dealResponse = await updateDemandSeatDeal({
                        id: updatedMarketplaceInfo.deal.id,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        body: updatedMarketplaceInfo.deal,
                    }).unwrap();
                    const updatedAdSourceFormValues = {
                        ...updatedMarketplaceInfo,
                        deal: {
                            ...updatedMarketplaceInfo.deal,
                            ...parsePriceModelFieldsToApi(adSourceTypeId),
                            startTime: parseDateStringFromApi(dealResponse.startTime, dealResponse.timeZone?.code),
                            endTime: dealResponse.endTime
                                ? parseDateStringFromApi(dealResponse.endTime, dealResponse.timeZone?.code)
                                : null,
                        },
                    };
                    adSourceForm.setFieldValue(AD_SOURCE_FIELDS.DEMAND.name, [updatedAdSourceFormValues]);
                    const didShowSyncNotifications = notifySyncedFieldsSuccess(
                        syncedFields,
                        adSourceTypeId,
                        adSourceFloorTypeId
                    );
                    if (didShowSyncNotifications) {
                        setIsAdSourceOutOfSync(true);
                    }
                    dispatch(setAdSourceHasDealChanges(true));
                    setIsSyncingAdSourceChangesToDeal(false);
                } else if (isLegacyDeal) {
                    const hasLegacyFieldUpdates = [
                        syncedFields.startDate,
                        syncedFields.endDate,
                        syncedFields.timeZone,
                    ].some((syncedField) => syncedField !== undefined);
                    if (hasLegacyFieldUpdates) {
                        updatedMarketplaceInfo = mapSyncedFieldsToLegacyDealFields(syncedFields, marketplaceInfo);
                        adSourceForm.setFieldValue(AD_SOURCE_FIELDS.DEMAND.name, [updatedMarketplaceInfo]);
                    }
                }
            } catch (e) {
                setIsSyncingAdSourceChangesToDeal(false);
                console.error(`error attempting to update synced deal: ${e}`);
                notifySyncedFieldsError(syncedFields, adSourceTypeId, adSourceFloorTypeId);
            }

            isUpdatingRef.current = false;
        },
        [
            marketplaceInfo,
            adSourceForm,
            adSourceTypeId,
            adSourceFloorTypeId,
            updateBuyerSeatDeal,
            updateDemandSeatDeal,
            mapSyncedFieldsToDealFields,
            mapSyncedFieldsToLegacyDealFields,
            setIsAdSourceOutOfSync,
            setIsSyncingAdSourceChangesToDeal,
            dispatch,
        ]
    );

    return {
        updateDeal,
    };
};
