import { uri } from "@rubicon/utils";
import { useLocation, useHistory } from "react-router-dom";
import { TAG_TYPE } from "../constants";
import { getParamFromSearch } from "../helpers";
import { useGetSupplyTypesQuery } from "@app/core/services";
import { useNotificationError } from "@app/core/components/hooks/useNotificationError";

export const useTagTypeFilter = () => {
    const { search } = useLocation();
    const { push } = useHistory();

    const selected = getParamFromSearch(search, TAG_TYPE);

    const handleTagTypeChange = (label: string) => {
        const nextSearch = uri.setSearchParam(search, TAG_TYPE.key, label);
        push({ search: nextSearch });
    };
    const { data, error, isLoading } = useGetSupplyTypesQuery();
    useNotificationError(error);

    return {
        isLoading,
        tagTypeOptions: data?.map(({ name, id }) => ({ label: name, value: String(id) })) || [],
        handleTagTypeChange,
        selected,
    };
};
