import { TimingStats } from "./types";

export const getTimingStatsRegions = (timingStats: TimingStats): string[] => Object.keys(timingStats.data);

export const getTimingStatsSortedTimeRangesByRegion = (timingStats: TimingStats, region: string): string[] =>
    Object.keys(timingStats.data[region].dData.def.tData).sort((a, b) => {
        const getRangeStart = (range: string) => {
            const splitCharacter = range.includes("-") ? "-" : "+";
            return Number(range.split(splitCharacter)[0]);
        };
        return getRangeStart(a) - getRangeStart(b);
    });

export const getTimingStatsTotalEventCountByRegion = (timingStats: TimingStats, region: string): number =>
    Object.values(timingStats.data[region].dData.def.tData).reduce(
        (totalEventCount, timingData) => (totalEventCount += timingData.ct),
        0
    );
