import { FC } from "react";
import { FilterSelect } from "@app/core/components/FilterSelect";
import { useSeatAdSourcesTargetingFilter } from "./useSeatAdSourcesTargetingFilter";
import { HelpTooltip, SelectAllDropdownRender } from "@app/core/components";
import { AdSourcesHealthHelpKeys } from "@app/features/seatAdSources/constants";

export const SeatAdSourcesTargetingFilter: FC = () => {
    const { handleChangeValue, options, value, areAllOptionsSelected, toggleSelectDeselectAll } =
        useSeatAdSourcesTargetingFilter();

    return (
        <FilterSelect
            dataSdet="seat-ad-sources-targeting-filter"
            dropdownRender={(menu) => (
                <SelectAllDropdownRender
                    menu={menu}
                    onClick={toggleSelectDeselectAll}
                    areAllOptionsSelected={areAllOptionsSelected}
                />
            )}
            label={
                <HelpTooltip
                    helpKey={AdSourcesHealthHelpKeys.Targeting}
                    helpKeyList={AdSourcesHealthHelpKeys}
                    popover={true}
                >
                    Targeting
                </HelpTooltip>
            }
            mode="multiple"
            onChange={handleChangeValue}
            options={options}
            value={value}
        />
    );
};
