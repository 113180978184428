import { AdSourceListItem } from "@app/core/services";
import { AD_SOURCE_TYPES_NEXT_UI_NAMES, AdSourceTypes } from "@app/features/seatAdSources/constants";

type AdSourceCpmRateType = "Cpm" | "CpmUp" | "CpmDown" | "PercentUp" | "PercentDown" | "--";

export const getRateTypeToBeDisplayed = (adSource: AdSourceListItem): AdSourceCpmRateType => {
    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSource.type.name];

    if (adSourceTypeName === AdSourceTypes.FIXED_PRICE || adSourceTypeName === AdSourceTypes.LINEAR_FIXED_PRICE) {
        if (adSource.adSourcePriceModel?.name === "Tiered price") {
            return "--";
        }
    }

    if (
        adSourceTypeName === AdSourceTypes.PREBID_DEALS_CONTAINER ||
        adSourceTypeName === AdSourceTypes.SERVER_SIDE_DYNAMIC_PRICE ||
        adSourceTypeName === AdSourceTypes.FALLBACK_TAG
    ) {
        return "--";
    }

    if (
        adSourceTypeName === AdSourceTypes.AUCTION_PRICE ||
        adSourceTypeName === AdSourceTypes.OPEN_AUCTION ||
        adSourceTypeName === AdSourceTypes.MARKETPLACE ||
        adSourceTypeName === AdSourceTypes.PREBID_AD_SOURCE ||
        adSourceTypeName === AdSourceTypes.LINEAR_AUCTION_PRICE
    ) {
        if (adSource.adSourceFloorType?.name === "IncreaseCPM") {
            return "CpmUp";
        }
        if (adSource.adSourceFloorType?.name === "DecreaseCPM") {
            return "CpmDown";
        }
        if (adSource.adSourceFloorType?.name === "IncreasePercent") {
            return "PercentUp";
        }
        if (adSource.adSourceFloorType?.name === "DecreasePercent") {
            return "PercentDown";
        }
    }

    return "Cpm";
};
