import { FC } from "react";
import { useSeatAuthContext } from "@app/core/auth";
import { getFloorPrice } from "@app/features/inventory/helpers";
import { CurrencyInput } from "@app/core/components/Fields/CurrencyInput";
import { FloorValue } from "@app/features/inventory/components/FloorsTable";

interface FloorTablePriceProps {
    record: FloorValue;
    editingIds: Set<number | null>;
    handleChangePrice: (id: number | null, event: number) => void;
}

export const FloorTablePrice: FC<FloorTablePriceProps> = ({ record, editingIds, handleChangePrice }) => {
    const { context } = useSeatAuthContext();
    const currencyCode = context?.floorCurrency?.code || "USD";
    const onChange = (value: number): void => handleChangePrice(record.id, value);

    if (editingIds.has(record.id))
        return <CurrencyInput currency={currencyCode} value={record.price} onChange={onChange} />;

    return <>{getFloorPrice(record.price, currencyCode)}</>;
};
