import { SeatAdSourcesType } from "@app/core/services";
import { AdSourceTypes, AD_SOURCE_FIELDS, AD_SOURCE_TYPES_NEXT_UI_NAMES } from "@app/features/seatAdSources/constants";
import { Form } from "antd";

const { useWatch } = Form;

export const useIsFieldVisible = () => {
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);

    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSourceType?.name] ?? adSourceType?.name;

    switch (adSourceTypeName) {
        case AdSourceTypes.PROGRAMATIC_GUARANTEED:
        case AdSourceTypes.FIXED_PRICE:
        case AdSourceTypes.MARKETPLACE:
        case AdSourceTypes.SERVER_SIDE_TAG_GUARANTEED:
        case AdSourceTypes.CLIENT_SIDE_TAG_GUARANTEED:
        case AdSourceTypes.SERVER_SIDE_TAG_NON_GUARANTEED:
        case AdSourceTypes.AUCTION_PRICE:
            return false;
        case AdSourceTypes.SERVER_SIDE_DYNAMIC_PRICE:
            return true;
        default:
            return false;
    }
};
