import { useGetBrandByIdQuery } from "@app/core/services/console";
import { useParams } from "react-router-dom";

interface BrandFloorsPageParams {
    brandId: string;
}

export const useBrandById = () => {
    const { brandId } = useParams<BrandFloorsPageParams>();
    const { data: brand, isFetching } = useGetBrandByIdQuery(Number(brandId));

    return {
        brand,
        isFetching,
    };
};
