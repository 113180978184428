import { SeatPublisher, useGetSeatPublishersQuery } from "@app/core/services";
import { useParams } from "react-router-dom";

interface UsePublishers {
    data?: SeatPublisher[];
    isFetching: boolean;
    seatId: string;
}

export const usePublishers = (): UsePublishers => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data, isFetching } = useGetSeatPublishersQuery({ seatId: Number(seatId) });

    return {
        data,
        isFetching,
        seatId,
    };
};
