import { Form, InputNumber } from "antd";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { useIsFIeldVisible } from "./useIsFieldVisible";
import { useIsReadOnly } from "./useIsReadOnly";

export const TotalImpressionsInput = () => {
    const isVisible = useIsFIeldVisible();
    const isReadOnly = useIsReadOnly();
    if (!isVisible) {
        return null;
    }
    return (
        <Form.Item
            name={AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.name}
            label={AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.label}
            data-sdet={`test-${AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.name}-field`}
            rules={[
                {
                    required: true,
                    message: `${AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.label} is required`,
                },
            ]}
            help={isReadOnly ? "Inherited from Demand Deal" : undefined}
            {...VERTICAL_LAYOUT_FIX}
        >
            <InputNumber
                style={{ width: "100%" }}
                disabled={isReadOnly}
                placeholder={`Enter ${AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.label}`}
                data-sdet={`test-${AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.name}-input`}
            />
        </Form.Item>
    );
};
