import { ROUTE_FORMATTERS } from "@app/core/routing";
import { AdvancedBrandSafetyItem, useGetAdvancedBrandSafetyByIdQuery } from "@app/core/services";
import { CONTROLS_ROUTES } from "@app/features/controls/constants";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SECTION_NAMES } from "../constants";

interface UseAdvancedBrandSafetyDetailsDrawer {
    open: boolean;
    definitionType: SECTION_NAMES | null;
    data: AdvancedBrandSafetyItem;
    isLoading: boolean;
    handleClose: () => void;
    handleDefinition: (type: SECTION_NAMES) => void;
    handleBack: () => void;
    handlePostDeleteEvent: () => void;
    handleAfterOpenChange: (open: boolean) => void;
}

export const useAdvancedBrandSafetyDetailsDrawer = (
    seatId: string,
    id: string,
    initialOpen = true,
    onBack?: () => void
): UseAdvancedBrandSafetyDetailsDrawer => {
    const history = useHistory();
    const { search: queryString } = useLocation();

    const [open, setOpen] = useState<boolean>(false);
    const [definitionType, setDefinitionType] = useState<SECTION_NAMES | null>(null);

    const { data, isFetching } = useGetAdvancedBrandSafetyByIdQuery(Number(id));

    const handleClose = () => {
        setOpen(false);
        setDefinitionType(null);
    };

    const handleDefinition = (type: SECTION_NAMES) => setDefinitionType(type);

    const handleBack = () => {
        setDefinitionType(null);
        onBack?.();
    };

    const handlePostDeleteEvent = () => {
        history.replace(
            ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_TABS(seatId, CONTROLS_ROUTES.ADVANCED_BRAND_SAFETY)
        );
    };

    const handleAfterOpenChange = (open: boolean) => {
        !open &&
            history.push(
                `${ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_TABS(
                    seatId,
                    CONTROLS_ROUTES.ADVANCED_BRAND_SAFETY
                    // route SEAT_CONTROLS_BRAND_SAFETY has "?" trailing, remove it from queryString to avoid double "??"
                    // TODO: refactor to a better way of handling brand safety tab urls without adding "?" to its path constant SEAT_CONTROLS_BRAND_SAFETY
                )}${queryString.replace("?", "")}`
            );
    };

    useEffect(() => {
        setOpen(initialOpen);
    }, [initialOpen]);

    return {
        open,
        definitionType,
        data: data as AdvancedBrandSafetyItem,
        isLoading: isFetching,
        handleClose,
        handleDefinition,
        handleBack,
        handlePostDeleteEvent,
        handleAfterOpenChange,
    };
};
