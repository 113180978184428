import { usePerformanceSectionContext } from "@app/features/inventory/InventoryHealth/InventoryHealthPage/PerformanceSection/usePerformanceSectionContext";
import { Col, Radio } from "antd";
import { FC } from "react";

interface PerformanceStatsTypeButtonsProps {
    handlePerformanceTypeSwitch: (isLiveActive: boolean) => void;
}

export const PerformanceStatsTypeButtons: FC<PerformanceStatsTypeButtonsProps> = () => {
    const { handlePerformanceTypeSwitch, isLiveActive } = usePerformanceSectionContext();
    return (
        <Col>
            <Radio.Group
                options={[
                    { label: "Last 24 hrs", value: false },
                    { label: "Live stats", value: true },
                ]}
                onChange={(e) => handlePerformanceTypeSwitch(e.target.value)}
                optionType="button"
                value={isLiveActive}
            />
        </Col>
    );
};
