import { useUserAccess } from "@app/core/auth";
import {
    BrandChannel,
    ChannelPayload,
    useCreateChannelMutation,
    useDeleteChannelMutation,
    useUpdateChannelMutation,
} from "@app/core/services";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { LabeledValue } from "antd/lib/select";
import { FormInstance, notification } from "antd";
import { Store } from "rc-field-form/lib/interface";
import { InventoryCodeType, CHANNEL_FORM_FIELDS } from "../constants";
import { DEFAULT_FALLBACK_MESSAGE } from "@app/features/inventory/constants";
import { usePublisherChannelFormInstance } from "./PublisherChannelFormInstance";
import { LabeledValueOpotions } from "@app/features/inventory/components/Fields/FrequencyCaps/useFrequencyCaps";
import { getPublisherChannelFormInitialValue, parsePublisherChannelFormValuesToApi } from "./publisherChannelFormUtils";
import { AdBreakRuleMidRoll, AdBreakRulePostRoll, AdBreakRulePreRoll } from "@app/features/inventory/components/Fields";
import { LabelValueOption } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/useSeatDetailsForm";
import { SeparationGroupOption } from "@app/features/inventory/components/FormItems/AdUnitCompetitiveSeparationGroups/useAdUnitCompetitiveSeparationGroups";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { InventoryDetailsDrawerType } from "../../DetailsDrawer/reducer";

export interface PublisherChannelForm {
    // General
    [CHANNEL_FORM_FIELDS.NAME.name]: string;
    [CHANNEL_FORM_FIELDS.CODE_TYPE.name]: InventoryCodeType;
    [CHANNEL_FORM_FIELDS.CODE.name]: string;
    [CHANNEL_FORM_FIELDS.DESCRIPTION.name]: string | null;
    [CHANNEL_FORM_FIELDS.STATUS.name]: string;
    [CHANNEL_FORM_FIELDS.ACCOUNT_TYPE.name]: LabeledValue | null;
    [CHANNEL_FORM_FIELDS.BILLING_CODE.name]: string;
    // Defaults Section
    [CHANNEL_FORM_FIELDS.MIMES.name]: LabeledValue[];
    [CHANNEL_FORM_FIELDS.SUPPORTED_APIS.name]: LabeledValue[];
    [CHANNEL_FORM_FIELDS.LINEARITY.name]: number | string;
    [CHANNEL_FORM_FIELDS.TYPE.name]: LabeledValue;
    [CHANNEL_FORM_FIELDS.START_DELAY.name]: number;
    [CHANNEL_FORM_FIELDS.INTERSTITIAL.name]: boolean;
    [CHANNEL_FORM_FIELDS.PLACEMENT.name]: LabeledValue;
    [CHANNEL_FORM_FIELDS.COPPA.name]: boolean | string;
    [CHANNEL_FORM_FIELDS.PRIVACY_POLICY.name]: boolean | string;
    [CHANNEL_FORM_FIELDS.CATEGORIES.name]: LabeledValue[];
    [CHANNEL_FORM_FIELDS.SUPPORTED_PROTOCOLS.name]: LabeledValue[];
    [CHANNEL_FORM_FIELDS.MIN_DURATION.name]: number;
    [CHANNEL_FORM_FIELDS.MAX_DURATION.name]: number;
    [CHANNEL_FORM_FIELDS.MAX_EXTENDED.name]: number | string;
    [CHANNEL_FORM_FIELDS.ADDITIONAL_TIME.name]: number;
    [CHANNEL_FORM_FIELDS.MIN_BITRATE.name]: number | null;
    [CHANNEL_FORM_FIELDS.MAX_BITRATE.name]: number | null;
    [CHANNEL_FORM_FIELDS.SSAI_TYPE.name]: LabeledValue;
    [CHANNEL_FORM_FIELDS.LIVE_STREAM.name]: boolean;
    [CHANNEL_FORM_FIELDS.SKIPPABLE.name]: boolean | string;
    [CHANNEL_FORM_FIELDS.SKIPPABLE_AFTER.name]: number | null;
    [CHANNEL_FORM_FIELDS.SKIPPABLE_MINIMUM.name]: number | null;
    [CHANNEL_FORM_FIELDS.MULTIPLICITY.name]: number;
    //Pod
    [CHANNEL_FORM_FIELDS.MAX_POD_SECONDS.name]: number;
    [CHANNEL_FORM_FIELDS.MAX_ADS_PER_POD.name]: number;
    //Playlist Configuration
    [CHANNEL_FORM_FIELDS.FILL_MODE.name]: number | undefined;
    [CHANNEL_FORM_FIELDS.POD_ENFORCEMENT.name]: number | undefined;
    [CHANNEL_FORM_FIELDS.COMPETITIVE_SEPARATION_MODE.name]: boolean | undefined;
    [CHANNEL_FORM_FIELDS.COMPETITIVE_SEPARATIO_GROUPS.name]: SeparationGroupOption[];
    [CHANNEL_FORM_FIELDS.MAX_ADS_PER_ADV.name]: number | null;
    [CHANNEL_FORM_FIELDS.PLAYLIST_DEFINITION_MODE.name]: number;
    //Ad Break Rules
    [CHANNEL_FORM_FIELDS.AD_BREAK_RULES_PRE.name]: [AdBreakRulePreRoll];
    [CHANNEL_FORM_FIELDS.AD_BREAK_RULES_MID.name]: [AdBreakRuleMidRoll];
    [CHANNEL_FORM_FIELDS.AD_BREAK_RULES_POST.name]: [AdBreakRulePostRoll];
    [CHANNEL_FORM_FIELDS.CUE_POINTS.name]: string[];
    // Brand Safety Section
    [CHANNEL_FORM_FIELDS.BLOCK_ADVERTISER_DOMAIN_LISTS.name]: LabeledValue[];
    [CHANNEL_FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: string;
    [CHANNEL_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.name]: LabeledValue | null;
    [CHANNEL_FORM_FIELDS.BLOCKED_CATEGORIES.name]: LabeledValue[];
    //Detailed Configuration
    [CHANNEL_FORM_FIELDS.PUBLISHER_NAME.name]: string | null;
    [CHANNEL_FORM_FIELDS.SUPPLY_NAME.name]: string | null;
    [CHANNEL_FORM_FIELDS.BUSINESS_DOMAIN.name]: string;
    [CHANNEL_FORM_FIELDS.PARTNER_DOMAIN.name]: string | null;
    [CHANNEL_FORM_FIELDS.ALLOW_VAST_WRAPPERS.name]: boolean;
    [CHANNEL_FORM_FIELDS.LIVE_STREAM_ACCELERATION.name]: boolean;
    [CHANNEL_FORM_FIELDS.LSA_WINDOW_SIZE_SECONDS.name]: number | null;
    [CHANNEL_FORM_FIELDS.UNWRAP_VAST.name]: boolean;
    [CHANNEL_FORM_FIELDS.EXTENDED_IMP_WAIT_TIME.name]: LabeledValue;
    [CHANNEL_FORM_FIELDS.ADV_AS_WHITELIST.name]: boolean;
    [CHANNEL_FORM_FIELDS.ADV_FREQ_CAPP.name]: LabeledValue;
    [CHANNEL_FORM_FIELDS.ADV_DOMAIN_FREQ_CAPPS.name]: LabeledValueOpotions[] | null;
    [CHANNEL_FORM_FIELDS.NO_AD_SYNC.name]: boolean;
    [CHANNEL_FORM_FIELDS.PUBLISHER_RE_AUCTION.name]: boolean | string;
    [CHANNEL_FORM_FIELDS.UD_OPT_OUT.name]: boolean;
    // Custom Pixels
    [CHANNEL_FORM_FIELDS.CUSTOM_PIXELS.name]: LabeledValue[];
    // Labels
    [CHANNEL_FORM_FIELDS.LABELS.name]: LabelValueOption[];
    [CHANNEL_FORM_FIELDS.INTERNAL_LABELS.name]: LabelValueOption[];
}

export interface UsePublisherChannelForm {
    open: boolean;
    loading: boolean;
    isEditMode: boolean;
    isDeleting: boolean;
    initialValues: Store;
    showModal: () => void;
    handleCancel: () => void;
    handleDelete: () => void;
    submitButtonTitle: string;
    channel: BrandChannel | undefined;
    form: FormInstance<PublisherChannelForm>;
    handleCancelModal: () => void;
    isInternalSectionShown: boolean;
    handleSubmit: () => void;
}

export const usePublisherChannelForm = (): UsePublisherChannelForm => {
    const history = useHistory();
    const [open, setOpen] = useState<boolean>(false);
    const { isSysAdmin, isPubAcctMgr, hasInternalAccess, isTremorUser } = useUserAccess();
    const { mode, channel, form, seat, seatId } = usePublisherChannelFormInstance();
    const initialValues: Store = getPublisherChannelFormInitialValue({ channel, mode });

    const isEditMode: boolean = mode === "edit";
    const submitButtonTitle = mode !== "create" ? "Save" : "Submit";

    const [updatePublisherChannel, { isLoading: isUpdating }] = useUpdateChannelMutation();
    const [createPublisherChannel, { isLoading: isCreating }] = useCreateChannelMutation();
    const [deletePublisherChannel, { isLoading: isDeleting }] = useDeleteChannelMutation();

    const handleCancelModal = (): void => setOpen(false);
    const showModal = (): void => setOpen(true);
    const handleCancel = (): void => history.goBack();

    const handleCreate = async (body: ChannelPayload): Promise<void> => {
        if (!seat) return notification.error({ message: DEFAULT_FALLBACK_MESSAGE });

        try {
            const res = await createPublisherChannel({ id: seat.id, payload: body }).unwrap();
            notification.success({ message: "Channel created successfully" });
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT(
                    InventoryDetailsDrawerType.CHANNEL,
                    seatId,
                    res.id
                )
            );
        } catch (err) {
            notification.error({ message: err?.data?.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        }
    };
    const handleUpdate = async (body: ChannelPayload): Promise<void> => {
        if (!channel) return notification.error({ message: DEFAULT_FALLBACK_MESSAGE });

        try {
            await updatePublisherChannel({ id: channel.id, payload: body }).unwrap();
            notification.success({ message: "Channel updated successfully" });
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT(
                    InventoryDetailsDrawerType.CHANNEL,
                    seatId,
                    channel.id
                )
            );
        } catch (err) {
            notification.error({ message: err?.data?.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        }
    };
    const handleDelete = async (): Promise<void> => {
        if (!channel || !isEditMode) return notification.error({ message: DEFAULT_FALLBACK_MESSAGE });

        try {
            deletePublisherChannel(channel.id).unwrap();
            notification.success({ message: "Channel deleted successfully" });
            history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH(seatId));
        } catch (error) {
            notification.error({ message: error?.data?.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        }
    };
    const handleSubmit = async (): Promise<void> => {
        try {
            await form.validateFields();

            const payload: ChannelPayload = parsePublisherChannelFormValuesToApi({
                mode,
                values: form.getFieldsValue(),
                isSysAdmin,
                isPubAcctMgr,
                isTremorUser,
                hasInternalAccess,
            });
            if (isEditMode) return handleUpdate(payload);
            return handleCreate(payload);
        } catch (error) {
            form.scrollToField(error.errorFields[0].name, { behavior: "smooth", block: "center" });
        }
    };

    return {
        open,
        form,
        channel,
        showModal,
        isEditMode,
        isDeleting,
        handleCancel,
        handleSubmit,
        handleDelete,
        initialValues,
        handleCancelModal,
        submitButtonTitle,
        loading: isCreating || isUpdating,
        isInternalSectionShown: isSysAdmin || isPubAcctMgr,
    };
};
