import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { formatTimeDuration } from "@app/features/seatAdSources/SeatAdSourcesForm/DealFormDrawer/DealFormContent/DealFormSections/DemandConfigurationSection/Fields/FrequencyCapping/DealFreqCappingsMultiselect";

interface UseDealFreqCappings {
    options: {
        value: string;
        label: string;
    }[];
    value: string[];
}

export const useDealFreqCappings = (): UseDealFreqCappings => {
    const fcapCappings = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.DEAL_FREQUENCY_CAPPINGS]
    );
    const options = (fcapCappings || []).map((capping) => ({
        value: capping?.id,
        label: `${capping.total} ${capping.mode.name} in ${formatTimeDuration(capping.timeDurationMin)}`,
    }));

    const value = fcapCappings?.map((capping) => capping?.id);
    return { options, value };
};
