import { FC } from "react";
import { Card, Col, Table, Row, Form } from "antd";
import { getYesNoLabel } from "@app/core/utils/labels";
import { FinanceDefault, Seat } from "@app/core/services";
import { INVENTORY_CARD_BODY_STYLE } from "@app/features/inventory/constants";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import {
    financeDefaultsColumn,
    useFinancialInternalOnly,
} from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/SeatDetailsFormFields/FinancialInternalOnly/useFinancialInternalOnly";
import {
    CostModel,
    CostValue,
    DefaultBillingCode,
} from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/SeatDetailsFormFields/FinancialInternalOnly/Fields";

interface FinancialInternalOnlyProps {
    seat: Seat;
    edit?: boolean;
}

const COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 };

export const FinancialInternalOnly: FC<FinancialInternalOnlyProps> = ({ seat, edit = false }) => {
    const {
        financeDefaults,
        isFetching,
        shownFinanceDefaultsTable,
        affiliateCostEnabledShown,
        affiliateCostModelValueShown,
    } = useFinancialInternalOnly({
        seat,
        edit,
    });
    return (
        <Card title="Internal Only" type="inner" bodyStyle={INVENTORY_CARD_BODY_STYLE}>
            <Row gutter={16}>
                <Col {...COL_SIZES}>
                    <DefaultBillingCode seat={seat} edit={edit} />
                </Col>
                {affiliateCostEnabledShown && (
                    <Col {...COL_SIZES}>
                        <Form.Item
                            label={INVENTORY_FORM_FIELDS.AFFILIATE_COST_ENABLED.label}
                            name={INVENTORY_FORM_FIELDS.AFFILIATE_COST_ENABLED.name}
                        >
                            {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.fillGuaranteed)}
                        </Form.Item>
                    </Col>
                )}
            </Row>
            {affiliateCostModelValueShown && (
                <Row gutter={16}>
                    <Col {...COL_SIZES}>
                        <CostModel seat={seat} edit={edit} />
                    </Col>
                    <Col {...COL_SIZES}>
                        <CostValue seat={seat} edit={edit} />
                    </Col>
                </Row>
            )}
            {shownFinanceDefaultsTable && (
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <Table<FinanceDefault>
                            bordered
                            size="small"
                            pagination={false}
                            scroll={{ x: 500 }}
                            loading={isFetching}
                            showSorterTooltip={false}
                            dataSource={financeDefaults}
                            columns={financeDefaultsColumn}
                        />
                    </Col>
                </Row>
            )}
        </Card>
    );
};
