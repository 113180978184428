import { FC } from "react";
import { FilterSelect } from "@app/core/components/FilterSelect";
import { useSeatAdSourcesPacingDealFilter } from "./useSeatAdSourcesPacingDealFilter";
import { SelectAllDropdownRender } from "@app/core/components";

export const SeatAdSourcesPacingDealFilter: FC = () => {
    const {
        handleChangeValue,
        options,
        value,
        isLoading,
        areAllOptionsSelected,
        toggleSelectDeselectAll,
        handleChangeSearch,
    } = useSeatAdSourcesPacingDealFilter();

    return (
        <FilterSelect
            dataSdet="seat-ad-sources-pacing-deals-filter"
            dropdownRender={(menu) => (
                <SelectAllDropdownRender
                    menu={menu}
                    onClick={toggleSelectDeselectAll}
                    areAllOptionsSelected={areAllOptionsSelected}
                />
            )}
            isLoading={isLoading}
            label="Deals"
            mode="multiple"
            onChange={handleChangeValue}
            options={options}
            value={value}
            onSearch={handleChangeSearch}
        />
    );
};
