import { Dmp } from "@app/core/services";
import { consoleApi, EXTERNAL_CODE_NAMESPACES_TAG } from "./console";

export const dmpsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getDmps: builder.query<Dmp[], void>({
            query: () => "dmps",
            providesTags: [EXTERNAL_CODE_NAMESPACES_TAG],
        }),
    }),
});

export const { useGetDmpsQuery } = dmpsApi;
