import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@app/core/store";
import { historicalChartDateRange } from "@app/core/components/charts/HistoricalChart/HistoricalChartDateRangeFilter/useHistoricalDateRangeFilter";

export interface HistoricalChartState {
    adSourceId: number;
    dealId: number | null;
    dateRange: historicalChartDateRange;
    groupBy: string;
}

const initialState: HistoricalChartState = {
    adSourceId: 0,
    dealId: null,
    dateRange: "lastThreeDays",
    groupBy: "day",
};

const HistoricalChartSlice = createSlice({
    name: "historicalChart",
    initialState,
    reducers: {
        setHistoricalChartAdSource: (state, action: PayloadAction<number>) => {
            state.adSourceId = action.payload;
        },
        setHistoricalChartDealId: (state, action: PayloadAction<number | null>) => {
            state.dealId = action.payload;
        },
        setHistoricalChartDateRange: (state, action: PayloadAction<historicalChartDateRange>) => {
            state.dateRange = action.payload;
        },
        setHistoricalChartGroupBy: (state, action: PayloadAction<string>) => {
            state.groupBy = action.payload;
        },
        resetHistoricalChartState: (state) => {
            const { adSourceId, dealId, dateRange, groupBy } = initialState;
            state.adSourceId = adSourceId;
            state.dealId = dealId;
            state.dateRange = dateRange;
            state.groupBy = groupBy;
        },
    },
});

export const selectHistoricalChartAdSource = (state: RootState) => state.historicalChart.adSourceId;
export const selectHistoricalChartDealId = (state: RootState) => state.historicalChart.dealId;
export const selectHistoricalChartDateRange = (state: RootState) => state.historicalChart.dateRange;
export const selectHistoricalChartGroupBy = (state: RootState) => state.historicalChart.groupBy;

export const {
    setHistoricalChartAdSource,
    setHistoricalChartDealId,
    setHistoricalChartDateRange,
    setHistoricalChartGroupBy,
    resetHistoricalChartState,
} = HistoricalChartSlice.actions;
export default HistoricalChartSlice.reducer;
