import cloneDeep from "lodash.clonedeep";
import { Currency, DashboardSupplyReportDatum } from "@app/core/services";

import { CurrencyConversion } from "./../../core/services/console/currencies";
import { AdStat, AdStatsById, StatsByCurrency } from "./types";
import { OVERALL, PODS_AND_PLAYLISTS, STANDARD } from "../dashboard/ViewFilter";
import { CurrencyConversionModes, RevenueTypes } from "@app/core/clients/console";
import moment from "moment-timezone";

const toPercent = (num) => Math.round(num * 100);

export const getCurrencyConversionRate = (
    code: string,
    currencyConversions: CurrencyConversion[],
    preferredCurrencyId: number | undefined,
    currencyConversionMode: CurrencyConversionModes | null
) => {
    const targetCurrencId =
        currencyConversionMode === CurrencyConversionModes.ORIGINATING_CURRENCY ? Number(code) : preferredCurrencyId;

    const currencyConversion = currencyConversions.find(
        (conversion) => conversion.sourceCurrencyId === Number(code) && conversion.targetCurrencyId === targetCurrencId
    );

    return currencyConversion?.rate || 1;
};

type CurrencyStatKey = keyof Pick<StatsByCurrency, "cost" | "dFeeCost" | "fee" | "rev" | "trev">;

const getCurrencyStat = (currencyStatKey: CurrencyStatKey, statsByCurrency: StatsByCurrency): number =>
    statsByCurrency[currencyStatKey] ?? 0;

export const getTotalCurrencyStat = (
    currencyStatKey: CurrencyStatKey,
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency | undefined | null,
    currencyConversions: CurrencyConversion[],
    currencyConversionMode: CurrencyConversionModes | null
): number => {
    if (!adStat || !currencyConversions) {
        return 0;
    }

    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((allEntitiesAdStat, entityAdStat: AdStat) => {
        if (currencyConversionMode === CurrencyConversionModes.FILTER) {
            if (!preferredCurrency?.id) {
                return 0;
            }
            return (
                allEntitiesAdStat +
                    getCurrencyStat(currencyStatKey, entityAdStat.statsByCurrency[preferredCurrency.id]) / 1000000 || 0
            );
        }

        const total = Object.keys(entityAdStat.statsByCurrency).reduce((allCurrenciesStats, code) => {
            const currencyStats = entityAdStat.statsByCurrency[Number(code)];
            if (!currencyStats) {
                return allCurrenciesStats;
            }

            return (
                allCurrenciesStats +
                getCurrencyStat(currencyStatKey, currencyStats) *
                    getCurrencyConversionRate(code, currencyConversions, preferredCurrency?.id, currencyConversionMode)
            );
        }, 0);

        return allEntitiesAdStat + total / 1000000;
    }, 0);
};

const getRevenue = (revenueType: RevenueTypes | null, statsByCurrency: StatsByCurrency) => {
    if (!statsByCurrency) {
        return 0;
    }
    if (revenueType === RevenueTypes.NET_REVENUE) {
        return statsByCurrency.cost;
    }
    return statsByCurrency.rev;
};

export const getTotalRevenue = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency | undefined | null,
    currencyConversions: CurrencyConversion[],
    currencyConversionMode: CurrencyConversionModes | null,
    revenueType: RevenueTypes | null
): number => {
    if (!adStat || !currencyConversions) {
        return 0;
    }
    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((allEntitiesAdStat, entityAdStat: AdStat) => {
        if (currencyConversionMode === CurrencyConversionModes.FILTER) {
            if (!preferredCurrency?.id) {
                return 0;
            }
            return (
                allEntitiesAdStat +
                    getRevenue(revenueType, entityAdStat.statsByCurrency[preferredCurrency.id]) / 1000000 || 0
            );
        }

        const total = Object.keys(entityAdStat.statsByCurrency).reduce((allCurrenciesStats, code) => {
            const currencyStats = entityAdStat.statsByCurrency[Number(code)];
            if (!currencyStats) {
                return allCurrenciesStats;
            }

            return (
                allCurrenciesStats +
                getRevenue(revenueType, currencyStats) *
                    getCurrencyConversionRate(code, currencyConversions, preferredCurrency?.id, currencyConversionMode)
            );
        }, 0);

        return allEntitiesAdStat + total / 1000000;
    }, 0);
};

export const getAdStatsTotalRevenue = (
    adStats: AdStatsById | null,
    preferredCurrency: Currency | undefined,
    currencyConversions: CurrencyConversion[],
    currencyConversionMode: CurrencyConversionModes,
    revenueType: RevenueTypes
): number => {
    if (!adStats) {
        return 0;
    }

    return Object.values(adStats).reduce((acc, data: AdStat) => {
        return acc + getTotalRevenue(data, preferredCurrency, currencyConversions, currencyConversionMode, revenueType);
    }, 0);
};
export const getTotalNetRev = (
    adStat: AdStat | null,
    preferredCurrency: Currency | undefined,
    currencyConversions: CurrencyConversion[],
    currencyConversionMode: CurrencyConversionModes
): number => {
    if (!adStat || !currencyConversions) {
        return 0;
    }

    if (currencyConversionMode === CurrencyConversionModes.FILTER) {
        if (!preferredCurrency?.id) {
            return 0;
        }
        return adStat.statsByCurrency[preferredCurrency.id]?.cost / 1000000 || 0;
    }

    const total = Object.keys(adStat.statsByCurrency).reduce((acc, code) => {
        const currencyStats = adStat.statsByCurrency[Number(code)];
        if (!currencyStats) {
            return acc;
        }

        return (
            acc +
            currencyStats.cost *
                getCurrencyConversionRate(code, currencyConversions, preferredCurrency?.id, currencyConversionMode)
        );
    }, 0);

    return total / 1000000;
};

export const getRequests = (adStat: AdStat | AdStat[] | null): number => {
    if (!adStat) {
        return 0;
    }
    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((total, entityAdStat: AdStat) => {
        return total + entityAdStat.requests;
    }, 0);
};
export const getTotalRequests = (adStats: AdStatsById | null): number => {
    if (!adStats) {
        return 0;
    }
    return Object.values(adStats).reduce<number>((total, entityAdStat: AdStat) => {
        return total + entityAdStat?.requests;
    }, 0);
};
export const getPlaylistRequests = (adStat: AdStat | AdStat[] | null): number => {
    if (!adStat) {
        return 0;
    }
    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((total, entityAdStat: AdStat) => {
        return total + entityAdStat.playlistStats.playlistCount;
    }, 0);
};

export const getPodRequests = (adStat: AdStat | AdStat[] | null): number => {
    if (!adStat) {
        return 0;
    }
    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((total, entityAdStat: AdStat) => {
        return total + entityAdStat.podStats.podsCount;
    }, 0);
};

export const getPodSlotsRequests = (adStat: AdStat | AdStat[] | null): number => {
    if (!adStat) {
        return 0;
    }
    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((total, entityAdStat: AdStat) => {
        return total + entityAdStat.podStats.podsCount + entityAdStat.playlistStats.podsCount;
    }, 0);
};

export const getFallThroughs = (adStat: AdStat | AdStat[] | null): number => {
    if (!adStat) {
        return 0;
    }
    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((total, entityAdStat: AdStat) => {
        return total + (entityAdStat.results["100"] || 0);
    }, 0);
};

export const getStandardRequests = (adStat: AdStat | AdStat[] | null): number => {
    return getRequests(adStat) - (getPlaylistRequests(adStat) + getPodRequests(adStat));
};

export const getStandardAcceptedRequests = (adStat: AdStat | AdStat[] | null): number => {
    const totalCleanRequests = getTotalCleanRequests(adStat);
    const playlistRequests = getPlaylistRequests(adStat);
    const podRequests = getPodRequests(adStat);
    const acceptedReqs = totalCleanRequests - (playlistRequests + podRequests);

    return acceptedReqs <= 0 ? 0 : acceptedReqs;
};

export const getStandardRequestsAcceptRate = (adStat: AdStat | AdStat[] | null): number => {
    const totalRequests = getRequests(adStat);
    const playlistRequests = getPlaylistRequests(adStat);
    const podRequests = getPodRequests(adStat);
    const totalRequestsWithMoka = totalRequests + getMokas(adStat as AdStat);
    const totalCleanReqs = getTotalCleanRequests(adStat);
    const standardAcceptRate =
        totalRequestsWithMoka - (podRequests + playlistRequests) <= 0
            ? 0
            : ((totalCleanReqs - (podRequests + playlistRequests)) /
                  (totalRequests - (podRequests + playlistRequests))) *
              100;
    return Math.round(standardAcceptRate);
};

export const getFills = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency | undefined,
    currencyConversionMode: CurrencyConversionModes | null
): number => {
    if (!adStat) {
        return 0;
    }
    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((allEntitiesAdStat, entityAdStat: AdStat) => {
        if (currencyConversionMode === CurrencyConversionModes.FILTER) {
            const filledRequests = preferredCurrency?.id
                ? entityAdStat.statsByCurrency[preferredCurrency.id]?.filledRequests
                : null;
            return allEntitiesAdStat + (filledRequests || 0);
        }

        const total = Object.keys(entityAdStat.statsByCurrency).reduce((allCurrenciesStats, code) => {
            const currencyStats = entityAdStat.statsByCurrency[Number(code)];
            if (!currencyStats) {
                return allCurrenciesStats;
            }
            return allCurrenciesStats + currencyStats.filledRequests;
        }, 0);

        return allEntitiesAdStat + total;
    }, 0);
};

export const getTotalFills = (
    adStats: AdStatsById | null,
    preferredCurrency: Currency | undefined,
    currencyConversionMode: CurrencyConversionModes
): number => {
    if (!adStats) {
        return 0;
    }

    return Object.values(adStats).reduce((acc, data: AdStat) => {
        return acc + getFills(data, preferredCurrency, currencyConversionMode);
    }, 0);
};
export const getAllOpportunities = (adStat: AdStat | AdStat[] | null): number => {
    if (!adStat) {
        return 0;
    }

    return getPodsAndPlaylistSlots(adStat) + getStandardRequests(adStat);
};

export const getRequestsAcceptRate = (adStat: AdStat | AdStat[] | null): number => {
    if (!adStat) {
        return 0;
    }

    const requests = getRequests(adStat);
    const cleanRequests = getTotalCleanRequests(adStat);

    return requests ? toPercent(cleanRequests / requests) : 0;
};

export const getLiveOpportunitiesAcceptRate = (adStat: AdStat | AdStat[] | null): number => {
    const allOpportunities = getAllOpportunities(adStat);
    if (allOpportunities === 0) {
        return 0;
    }
    const cleanOpportunities = getOpportunities(adStat);
    return Math.floor((cleanOpportunities / allOpportunities) * 100);
};

export const getOpportunities = (adStat: AdStat | AdStat[] | null): number => {
    if (!adStat) {
        return 0;
    }

    return (
        getTotalCleanRequests(adStat) -
        (getPlaylistRequests(adStat) + getPodRequests(adStat)) +
        getPodSlots(adStat) +
        getPlaylistSlots(adStat)
    );
};

export const getTotalCleanRequests = (adStat: AdStat | AdStat[] | null): number => {
    if (!adStat) {
        return 0;
    }
    const totalRequests = getRequests(adStat);
    const errors = getErrors(adStat as AdStat, true);
    const userRejections = getUserRejections(adStat as AdStat);
    const seatRejections = getSeatRejections(adStat as AdStat, true);
    return totalRequests - errors - userRejections - seatRejections;
};

export const getFillRate = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency | undefined,
    currencyConversionMode: CurrencyConversionModes,
    round = true
): number => {
    if (!adStat) {
        return 0;
    }
    const fills = getFills(adStat, preferredCurrency, currencyConversionMode);
    const opportunities = getAllOpportunities(adStat);

    if (opportunities === 0) {
        return 0;
    }
    if (round) {
        return toPercent(fills / opportunities);
    }

    return (fills / opportunities) * 100;
};

export const getImpressions = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency | undefined,
    currencyConversionMode: CurrencyConversionModes | null
): number => {
    if (!adStat) {
        return 0;
    }

    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((allEntitiesAdStat, entityAdStat: AdStat) => {
        if (currencyConversionMode === CurrencyConversionModes.FILTER) {
            const impressions = preferredCurrency?.id
                ? entityAdStat.statsByCurrency[preferredCurrency.id]?.impressions
                : null;
            return allEntitiesAdStat + (impressions || 0);
        }

        const total = Object.keys(entityAdStat.statsByCurrency).reduce((allCurrenciesStats, code) => {
            const currencyStats = entityAdStat.statsByCurrency[Number(code)];
            if (!currencyStats) {
                return allCurrenciesStats;
            }
            return allCurrenciesStats + currencyStats.impressions;
        }, 0);

        return allEntitiesAdStat + total;
    }, 0);
};

export const getLsaPotentialFills = (adStat: AdStat | AdStat[] | null): number => {
    if (!adStat) {
        return 0;
    }
    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((total, entityAdStat: AdStat) => {
        return total + (entityAdStat.cpFills || 0);
    }, 0);
};

export const getLsaPodRequests = (adStat: AdStat | AdStat[] | null): number => {
    if (!adStat) {
        return 0;
    }
    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((total, entityAdStat: AdStat) => {
        return total + (entityAdStat.cpRequests || 0);
    }, 0);
};

export const getLsaSlotRequests = (adStat: AdStat | AdStat[] | null): number => {
    if (!adStat) {
        return 0;
    }
    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((total, entityAdStat: AdStat) => {
        return total + (entityAdStat.csRequests || 0);
    }, 0);
};

export const getLsaFills = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency | undefined,
    currencyConversionMode: CurrencyConversionModes
): number => {
    if (!adStat) {
        return 0;
    }

    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((allEntitiesAdStat, entityAdStat: AdStat) => {
        if (currencyConversionMode === CurrencyConversionModes.FILTER) {
            const cFills = preferredCurrency?.id ? entityAdStat.statsByCurrency[preferredCurrency.id]?.cFills : null;
            return allEntitiesAdStat + (cFills || 0);
        }

        const total = Object.keys(entityAdStat.statsByCurrency).reduce((allCurrenciesStats, code) => {
            const currencyStats = entityAdStat.statsByCurrency[Number(code)];
            if (!currencyStats) {
                return allCurrenciesStats;
            }
            return allCurrenciesStats + (currencyStats.cFills || 0);
        }, 0);

        return allEntitiesAdStat + total;
    }, 0);
};

export const getLsaImps = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency | undefined,
    currencyConversionMode: CurrencyConversionModes
): number => {
    if (!adStat) {
        return 0;
    }

    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((allEntitiesAdStat, entityAdStat: AdStat) => {
        if (currencyConversionMode === CurrencyConversionModes.FILTER) {
            const cImps = preferredCurrency?.id ? entityAdStat.statsByCurrency[preferredCurrency.id]?.cImps : null;
            return allEntitiesAdStat + (cImps || 0);
        }

        const total = Object.keys(entityAdStat.statsByCurrency).reduce((allCurrenciesStats, code) => {
            const currencyStats = entityAdStat.statsByCurrency[Number(code)];
            if (!currencyStats) {
                return allCurrenciesStats;
            }
            return allCurrenciesStats + (currencyStats.cImps || 0);
        }, 0);

        return allEntitiesAdStat + total;
    }, 0);
};

export const getTotalImpressions = (
    adStats: AdStatsById | null,
    preferredCurrency: Currency | undefined,
    currencyConversionMode: CurrencyConversionModes
): number => {
    if (!adStats) {
        return 0;
    }

    return Object.values(adStats).reduce((acc, data: AdStat) => {
        return acc + getImpressions(data, preferredCurrency, currencyConversionMode);
    }, 0);
};
export const getImpressionsUseRate = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency | undefined,
    currencyConversionMode: CurrencyConversionModes | null,
    round = true
): number => {
    if (!adStat) {
        return 0;
    }
    const fills = getFills(adStat, preferredCurrency, currencyConversionMode);
    const impressions = getImpressions(adStat, preferredCurrency, currencyConversionMode);

    if (fills === 0) {
        return 0;
    }
    if (round) {
        return toPercent(impressions / fills);
    }

    return (impressions / fills) * 100;
};

export const getEstimatedECPM = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency,
    currencyConversions: CurrencyConversion[],
    currencyConversionMode: CurrencyConversionModes,
    revenueType: RevenueTypes
) => {
    if (!adStat) {
        return 0;
    }
    const totalGrossRev = getTotalRevenue(
        adStat,
        preferredCurrency,
        currencyConversions,
        currencyConversionMode,
        revenueType
    );
    const impressions = getImpressions(adStat, preferredCurrency, currencyConversionMode);

    if (impressions === 0) {
        return 0;
    }

    return ((totalGrossRev / impressions) * 1000).toFixed(2);
};

export const getPlaylistRevenue = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency | undefined,
    currencyConversions: CurrencyConversion[],
    currencyConversionMode: CurrencyConversionModes | null,
    revenueType: RevenueTypes | null
) => {
    if (!adStat || !currencyConversions) {
        return 0;
    }

    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((allEntitiesAdStat, entityAdStat: AdStat) => {
        if (currencyConversionMode === CurrencyConversionModes.FILTER) {
            if (!preferredCurrency?.id) {
                return 0;
            }
            return (
                allEntitiesAdStat +
                    getRevenue(revenueType, entityAdStat.playlistStats.statsByCurrency[preferredCurrency.id]) /
                        1000000 || 0
            );
        }
        const total = Object.keys(entityAdStat.playlistStats.statsByCurrency).reduce((allCurrenciesStats, code) => {
            const currencyStats = entityAdStat.playlistStats.statsByCurrency[Number(code)];
            if (!currencyStats) {
                return allCurrenciesStats;
            }

            return (
                allCurrenciesStats +
                getRevenue(revenueType, currencyStats) *
                    getCurrencyConversionRate(code, currencyConversions, preferredCurrency?.id, currencyConversionMode)
            );
        }, 0);

        return allEntitiesAdStat + total / 1000000;
    }, 0);
};

export const getPodRevenue = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency | undefined,
    currencyConversions: CurrencyConversion[],
    currencyConversionMode: CurrencyConversionModes | null,
    revenueType: RevenueTypes | null
) => {
    if (!adStat || !currencyConversions) {
        return 0;
    }

    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((allEntitiesAdStat, entityAdStat: AdStat) => {
        if (currencyConversionMode === CurrencyConversionModes.FILTER) {
            if (!preferredCurrency?.id) {
                return 0;
            }
            return (
                allEntitiesAdStat +
                    getRevenue(revenueType, entityAdStat.podStats.statsByCurrency[preferredCurrency.id]) / 1000000 || 0
            );
        }
        const total = Object.keys(entityAdStat.podStats.statsByCurrency).reduce((allCurrenciesStats, code) => {
            const currencyStats = entityAdStat.podStats.statsByCurrency[Number(code)];
            if (!currencyStats) {
                return allCurrenciesStats;
            }

            return (
                allCurrenciesStats +
                getRevenue(revenueType, currencyStats) *
                    getCurrencyConversionRate(code, currencyConversions, preferredCurrency?.id, currencyConversionMode)
            );
        }, 0);

        return allEntitiesAdStat + total / 1000000;
    }, 0);
};

export const getPodsAndPlaylistRevenue = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency | undefined,
    currencyConversions: CurrencyConversion[],
    currencyConversionMode: CurrencyConversionModes | null,
    revenueType: RevenueTypes | null
) => {
    return (
        getPlaylistRevenue(adStat, preferredCurrency, currencyConversions, currencyConversionMode, revenueType) +
        getPodRevenue(adStat, preferredCurrency, currencyConversions, currencyConversionMode, revenueType)
    );
};

export const getSlots = (adStat: AdStat | AdStat[] | null): number => {
    if (!adStat) {
        return 0;
    }
    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((total, entityAdStat: AdStat) => {
        return total + entityAdStat.playlistStats.slotsCount;
    }, 0);
};

export const getPlaylistSlots = (adStat: AdStat | AdStat[] | null): number => {
    if (!adStat) {
        return 0;
    }
    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((total, entityAdStat: AdStat) => {
        return total + entityAdStat.playlistStats.slotsCount;
    }, 0);
};

export const getPodSlots = (adStat: AdStat | AdStat[] | null): number => {
    if (!adStat) {
        return 0;
    }
    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((total, entityAdStat: AdStat) => {
        return total + entityAdStat.podStats.slotsCount;
    }, 0);
};

export const getPodsAndPlaylistSlots = (adStat: AdStat | AdStat[] | null): number => {
    return getPlaylistSlots(adStat) + getPodSlots(adStat);
};

export const getPodsAndPlaylistAdBreaks = (adStat: AdStat | AdStat[] | null): number => {
    if (!adStat) {
        return 0;
    }
    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((total, entityAdStat: AdStat) => {
        return total + entityAdStat.podStats.podsCount + entityAdStat.playlistStats.podsCount;
    }, 0);
};

export const getPlaylistAdBreaks = (adStat: AdStat | AdStat[] | null): number => {
    if (!adStat) {
        return 0;
    }
    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((total, entityAdStat: AdStat) => {
        return total + entityAdStat.playlistStats.podsCount;
    }, 0);
};

export const getSlotsPerPlaylist = (adStat: AdStat | AdStat[] | null): number => {
    const playlistRequests = getPlaylistRequests(adStat);

    if (!playlistRequests) {
        return 0;
    }

    return getPlaylistSlots(adStat) / playlistRequests;
};

export const getBreaksPerPlaylist = (adStat: AdStat | AdStat[] | null): number => {
    const playlistRequests = getPlaylistRequests(adStat);

    if (!playlistRequests) {
        return 0;
    }

    return getPlaylistAdBreaks(adStat) / playlistRequests;
};

export const getSlotsPerBreak = (adStat: AdStat | AdStat[] | null): number => {
    const playlistAdBreaks = getPlaylistAdBreaks(adStat);

    if (!playlistAdBreaks) {
        return 0;
    }

    return (playlistAdBreaks + getPodRequests(adStat)) / playlistAdBreaks;
};

export const getTotalTime = (adStat: AdStat | AdStat[] | null): number => {
    if (!adStat) {
        return 0;
    }
    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((total, entityAdStat: AdStat) => {
        return total + entityAdStat.totalTime;
    }, 0);
};

export const getAverageFillTime = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency,
    currencyConversionMode: CurrencyConversionModes | null
): number => {
    const fills = getFills(adStat, preferredCurrency, currencyConversionMode);
    return fills > 0 ? getTotalTime(adStat) / fills : 0;
};

export const getPlaylistImpressions = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency | undefined,
    currencyConversionMode: CurrencyConversionModes
): number => {
    if (!adStat) {
        return 0;
    }

    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((allEntitiesAdStat, entityAdStat: AdStat) => {
        if (currencyConversionMode === CurrencyConversionModes.FILTER && preferredCurrency) {
            return (
                allEntitiesAdStat + entityAdStat.playlistStats.statsByCurrency[preferredCurrency?.id]?.impressions || 0
            );
        }

        const total = Object.keys(entityAdStat.playlistStats.statsByCurrency).reduce((allCurrenciesStats, code) => {
            const currencyStats = entityAdStat.playlistStats.statsByCurrency[Number(code)];
            if (!currencyStats) {
                return allCurrenciesStats;
            }
            return allCurrenciesStats + currencyStats.impressions;
        }, 0);

        return allEntitiesAdStat + total;
    }, 0);
};

export const getPodsImpressions = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency | undefined,
    currencyConversionMode: CurrencyConversionModes
): number => {
    if (!adStat) {
        return 0;
    }

    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((allEntitiesAdStat, entityAdStat: AdStat) => {
        if (currencyConversionMode === CurrencyConversionModes.FILTER && preferredCurrency) {
            return allEntitiesAdStat + entityAdStat.podStats.statsByCurrency[preferredCurrency?.id]?.impressions || 0;
        }

        const total = Object.keys(entityAdStat.podStats.statsByCurrency).reduce((allCurrenciesStats, code) => {
            const currencyStats = entityAdStat.podStats.statsByCurrency[Number(code)];
            if (!currencyStats) {
                return allCurrenciesStats;
            }
            return allCurrenciesStats + currencyStats.impressions;
        }, 0);

        return allEntitiesAdStat + total;
    }, 0);
};

export const getPodsAndPlaylistImpressions = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency | undefined,
    currencyConversionMode: CurrencyConversionModes
): number => {
    return (
        getPlaylistImpressions(adStat, preferredCurrency, currencyConversionMode) +
        getPodsImpressions(adStat, preferredCurrency, currencyConversionMode)
    );
};

export const getPlaylistEstimatedECPM = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency,
    currencyConversions: CurrencyConversion[],
    currencyConversionMode: CurrencyConversionModes,
    revenueType: RevenueTypes
) => {
    if (!adStat || !preferredCurrency || !currencyConversions) {
        return 0;
    }
    const totalRev = getPlaylistRevenue(
        adStat,
        preferredCurrency,
        currencyConversions,
        currencyConversionMode,
        revenueType
    );
    const impressions = getPlaylistImpressions(adStat, preferredCurrency, currencyConversionMode);

    if (impressions === 0) {
        return 0;
    }

    return ((totalRev / impressions) * 1000).toFixed(2);
};

export const getPodsEstimatedECPM = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency,
    currencyConversions: CurrencyConversion[],
    currencyConversionMode: CurrencyConversionModes,
    revenueType: RevenueTypes
) => {
    if (!adStat || !preferredCurrency || !currencyConversions) {
        return 0;
    }
    const totalRev = getPodRevenue(adStat, preferredCurrency, currencyConversions, currencyConversionMode, revenueType);
    const impressions = getPodsImpressions(adStat, preferredCurrency, currencyConversionMode);

    if (impressions === 0) {
        return 0;
    }

    return ((totalRev / impressions) * 1000).toFixed(2);
};

export const getPodsAndPlaylistEstimatedECPM = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency,
    currencyConversions: CurrencyConversion[],
    currencyConversionMode: CurrencyConversionModes,
    revenueType: RevenueTypes
) => {
    const playlistEstimatedECPM = +getPlaylistEstimatedECPM(
        adStat,
        preferredCurrency,
        currencyConversions,
        currencyConversionMode,
        revenueType
    );

    const podsEstimatedECPM = +getPodsEstimatedECPM(
        adStat,
        preferredCurrency,
        currencyConversions,
        currencyConversionMode,
        revenueType
    );

    return ((playlistEstimatedECPM + podsEstimatedECPM) / 2).toFixed(2);
};

export const getPlaylistFills = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency | null | undefined,
    currencyConversionMode: CurrencyConversionModes
): number => {
    if (!adStat) {
        return 0;
    }

    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((allEntitiesAdStat, entityAdStat: AdStat) => {
        if (currencyConversionMode === CurrencyConversionModes.FILTER && preferredCurrency) {
            return (
                allEntitiesAdStat + entityAdStat.playlistStats.statsByCurrency[preferredCurrency?.id]?.filledRequests ||
                0
            );
        }

        const total = Object.keys(entityAdStat.playlistStats.statsByCurrency).reduce((allCurrenciesStats, code) => {
            const currencyStats = entityAdStat.playlistStats.statsByCurrency[Number(code)];
            if (!currencyStats) {
                return allCurrenciesStats;
            }
            return allCurrenciesStats + currencyStats.filledRequests;
        }, 0);

        return allEntitiesAdStat + total;
    }, 0);
};

export const getPodFills = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency | undefined | null,
    currencyConversionMode: CurrencyConversionModes
): number => {
    if (!adStat) {
        return 0;
    }

    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((allEntitiesAdStat, entityAdStat: AdStat) => {
        if (currencyConversionMode === CurrencyConversionModes.FILTER && preferredCurrency) {
            return (
                allEntitiesAdStat + entityAdStat.podStats.statsByCurrency[preferredCurrency?.id]?.filledRequests || 0
            );
        }

        const total = Object.keys(entityAdStat.podStats.statsByCurrency).reduce((allCurrenciesStats, code) => {
            const currencyStats = entityAdStat.podStats.statsByCurrency[Number(code)];
            if (!currencyStats) {
                return allCurrenciesStats;
            }
            return allCurrenciesStats + currencyStats.filledRequests;
        }, 0);

        return allEntitiesAdStat + total;
    }, 0);
};

export const getPodsAndPlaylistFills = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency | undefined,
    currencyConversionMode: CurrencyConversionModes
): number => {
    return (
        getPlaylistFills(adStat, preferredCurrency, currencyConversionMode) +
        getPodFills(adStat, preferredCurrency, currencyConversionMode)
    );
};

export const getPlaylistFillRate = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency,
    currencyConversionMode: CurrencyConversionModes
): number => {
    if (!adStat) {
        return 0;
    }
    const slots = getPlaylistSlots(adStat);
    const fills = getPlaylistFills(adStat, preferredCurrency, currencyConversionMode);

    if (slots === 0) {
        return 0;
    }

    return toPercent(fills / slots);
};

export const getPodFillRate = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency,
    currencyConversionMode: CurrencyConversionModes
): number => {
    if (!adStat) {
        return 0;
    }
    const slots = getPodSlots(adStat);
    const fills = getPodFills(adStat, preferredCurrency, currencyConversionMode);

    if (slots === 0) {
        return 0;
    }

    return toPercent(fills / slots);
};

export const getPodsAndPlaylistFillRate = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency,
    currencyConversionMode: CurrencyConversionModes
): number => {
    if (!adStat) {
        return 0;
    }
    const playlistslots = getPlaylistSlots(adStat);
    const playlistfills = getPlaylistFills(adStat, preferredCurrency, currencyConversionMode);
    const podslots = getPodSlots(adStat);
    const podfills = getPodFills(adStat, preferredCurrency, currencyConversionMode);
    if (playlistslots + podslots <= 0) {
        return 0;
    }

    return toPercent((playlistfills + podfills) / (playlistslots + podslots));
};

export const getStandardFillRate = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency,
    currencyConversionMode: CurrencyConversionModes
): number => {
    if (!adStat) {
        return 0;
    }
    const fills = getStandardFills(adStat, preferredCurrency, currencyConversionMode);
    const reqs = getStandardAcceptedRequests(adStat);

    if (reqs === 0) {
        return 0;
    }

    return toPercent(fills / reqs);
};

export const getPlaylistImpressionsUseRate = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency,
    currencyConversionMode: CurrencyConversionModes
): number => {
    if (!adStat) {
        return 0;
    }
    const fills = getPlaylistFills(adStat, preferredCurrency, currencyConversionMode);
    const playlistImpressions = getPlaylistImpressions(adStat, preferredCurrency, currencyConversionMode);

    if (playlistImpressions === 0) {
        return 0;
    }

    return toPercent(playlistImpressions / fills);
};

export const getPodsImpressionsUseRate = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency,
    currencyConversionMode: CurrencyConversionModes
): number => {
    if (!adStat) {
        return 0;
    }
    const fills = getPodFills(adStat, preferredCurrency, currencyConversionMode);
    const podsImpressions = getPodsImpressions(adStat, preferredCurrency, currencyConversionMode);

    if (podsImpressions === 0) {
        return 0;
    }

    return toPercent(podsImpressions / fills);
};

export const getPodsAndPlaylistImpressionsUseRate = (
    podsAndPlaylistImpressions: number,
    podsAndPlaylistFills: number
): number => {
    if (podsAndPlaylistFills <= 0) {
        return 0;
    }

    return toPercent(podsAndPlaylistImpressions / podsAndPlaylistFills);
};

export const getStandardRevenue = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency | undefined,
    currencyConversions: CurrencyConversion[],
    currencyConversionMode: CurrencyConversionModes,
    revenueType: RevenueTypes | null
) => {
    return (
        getTotalRevenue(adStat, preferredCurrency, currencyConversions, currencyConversionMode, revenueType) -
        getPodsAndPlaylistRevenue(adStat, preferredCurrency, currencyConversions, currencyConversionMode, revenueType)
    );
};

export const getStandardEstimatedECPM = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency,
    currencyConversions: CurrencyConversion[],
    currencyConversionMode: CurrencyConversionModes,
    revenueType: RevenueTypes
) => {
    if (!adStat || !preferredCurrency || !currencyConversions) {
        return 0;
    }
    const revenue = getStandardRevenue(
        adStat,
        preferredCurrency,
        currencyConversions,
        currencyConversionMode,
        revenueType
    );
    const standardImpressions = getStandardImpressions(adStat, preferredCurrency, currencyConversionMode);

    if (standardImpressions === 0) {
        return 0;
    }

    return ((revenue / standardImpressions) * 1000).toFixed(2);
};

export const getStandardFills = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency | undefined,
    currencyConversionMode: CurrencyConversionModes
): number => {
    return (
        getFills(adStat, preferredCurrency, currencyConversionMode) -
        getPodsAndPlaylistFills(adStat, preferredCurrency, currencyConversionMode)
    );
};

export const getStandardImpressions = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency | undefined,
    currencyConversionMode: CurrencyConversionModes
): number => {
    return (
        getImpressions(adStat, preferredCurrency, currencyConversionMode) -
        getPodsAndPlaylistImpressions(adStat, preferredCurrency, currencyConversionMode)
    );
};

export const getStandardImpressionsUseRate = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency,
    currencyConversionMode: CurrencyConversionModes
): number => {
    if (!adStat) {
        return 0;
    }
    const fills = getStandardFills(adStat, preferredCurrency, currencyConversionMode);
    const standardImpressions = getStandardImpressions(adStat, preferredCurrency, currencyConversionMode);

    if (fills === 0) {
        return 0;
    }

    return toPercent(standardImpressions / fills);
};

export const getTries = (adStat: AdStat | null): number => adStat?.tries || 0;
export const getRejectedRequests = (
    adStat: AdStat | AdStat[] | null,
    preferredCurrency: Currency,
    currencyConversionMode: CurrencyConversionModes
): number => {
    if (!adStat) {
        return 0;
    }
    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce((allEntitiesAdStat, entityAdStat: AdStat) => {
        if (currencyConversionMode === CurrencyConversionModes.FILTER) {
            return allEntitiesAdStat + entityAdStat.statsByCurrency[preferredCurrency?.id]?.rejections || 0;
        }

        const total = Object.keys(entityAdStat.statsByCurrency).reduce((allCurrenciesStats, code) => {
            const currencyStats = entityAdStat.statsByCurrency[Number(code)];
            if (!currencyStats) {
                return allCurrenciesStats;
            }
            return allCurrenciesStats + currencyStats.rejections;
        }, 0);

        return allEntitiesAdStat + total;
    }, 0);
};

export const getRevenueReport = (
    data: DashboardSupplyReportDatum,
    revenueType: RevenueTypes | null,
    viewFilter
): number => {
    switch (viewFilter) {
        case OVERALL.id: {
            return revenueType === RevenueTypes.NET_REVENUE ? data.netRevenue : data.grossRevenue;
        }
        case PODS_AND_PLAYLISTS.id: {
            return revenueType === RevenueTypes.NET_REVENUE
                ? data.podsNetRevenue + data.playlistNetRevenue
                : data.podsGrossRevenue + data.playlistGrossRevenue;
        }
        case STANDARD.id: {
            return revenueType === RevenueTypes.NET_REVENUE ? data.standardNetRevenue : data.standardGrossRevenue;
        }
        default:
            return 0;
    }
};

export const getTotalRevenueReport = (
    report: DashboardSupplyReportDatum[],
    revenueType: RevenueTypes | null,
    viewFilter
): number => {
    if (!report) {
        return 0;
    }

    const total = report.reduce((acc, data: DashboardSupplyReportDatum) => {
        return acc + getRevenueReport(data, revenueType, viewFilter);
    }, 0);

    return +total.toFixed(2);
};

export const getRevenueCPMReport = (revenue: number, impressions: number): number => {
    return impressions === 0 ? 0 : Math.round((revenue / impressions) * 100000) / 100;
};

export const getRequestReport = (data: DashboardSupplyReportDatum, viewFilter): number => {
    switch (viewFilter) {
        case OVERALL.id: {
            return data.requests;
        }
        case PODS_AND_PLAYLISTS.id: {
            return data.playlistRequests + data.podRequests;
        }
        case STANDARD.id: {
            return data.standardRequests;
        }
        default: {
            return 0;
        }
    }
};

export const getTotalAcceptedRequestsReport = (report: DashboardSupplyReportDatum[]): number => {
    if (!report) {
        return 0;
    }

    return report.reduce((acc, data: DashboardSupplyReportDatum) => {
        return acc + data.acceptedRequests;
    }, 0);
};

export const getTotalRequestReport = (report: DashboardSupplyReportDatum[], viewFilter): number => {
    if (!report) {
        return 0;
    }

    return report.reduce((acc, data: DashboardSupplyReportDatum) => {
        return acc + getRequestReport(data, viewFilter);
    }, 0);
};

export const getOpportunitiesReport = (report: DashboardSupplyReportDatum[]): number => {
    if (!report) {
        return 0;
    }

    return report.reduce((acc, data: DashboardSupplyReportDatum) => {
        return acc + data.overallOpportunities;
    }, 0);
};

export const getFillsReport = (data: DashboardSupplyReportDatum, viewFilter): number => {
    switch (viewFilter) {
        case OVERALL.id: {
            return data.fills;
        }
        case PODS_AND_PLAYLISTS.id: {
            return data.playlistFills + data.podFills;
        }
        case STANDARD.id: {
            return data.standardFills;
        }
        default: {
            return 0;
        }
    }
};

export const getTotalFillsReport = (report: DashboardSupplyReportDatum[], viewFilter): number => {
    if (!report || !viewFilter) {
        return 0;
    }

    return report.reduce((acc, data: DashboardSupplyReportDatum) => {
        return acc + getFillsReport(data, viewFilter);
    }, 0);
};

export const getFillRateReport = (data: DashboardSupplyReportDatum, viewFilter): number => {
    switch (viewFilter) {
        case OVERALL.id: {
            return data.fillRate;
        }
        case PODS_AND_PLAYLISTS.id: {
            return data.playlistFillRate + data.podFillRate;
        }
        case STANDARD.id: {
            return data.standardFillRate;
        }
        default: {
            return 0;
        }
    }
};

export const getTotalFillRateReport = (report: DashboardSupplyReportDatum[], fills, adPodEnabled: boolean): number => {
    if (!report || !report.length) {
        return 0;
    }

    if (!adPodEnabled) {
        const fills = getTotalFillsReport(report, OVERALL.id);
        const requests = getTotalRequestReport(report, OVERALL.id);

        return requests === 0 ? 0 : toPercent(fills / requests);
    }

    const standardRequests = getStandardRequestsReport(report);
    const podSlots = getPodSlotsReport(report);
    const playlistSlots = getPlaylistSlotsReport(report);

    return standardRequests + podSlots + playlistSlots <= 0
        ? 0
        : Math.round((fills / (standardRequests + podSlots + playlistSlots)) * 100);
};

export const getAcceptRateReport = (report: DashboardSupplyReportDatum[]): number => {
    if (!report || !report.length) {
        return 0;
    }

    const totalRequests = getTotalRequestReport(report, OVERALL.id);
    const totalAcceptedRequests = getTotalAcceptedRequestsReport(report);

    return totalRequests ? toPercent(totalAcceptedRequests / totalRequests) : 0;
};

export const getStandardRequestsReport = (report: DashboardSupplyReportDatum[]): number => {
    if (!report || !report.length) {
        return 0;
    }

    return report.reduce((acc, data: DashboardSupplyReportDatum) => {
        return acc + data.standardRequests;
    }, 0);
};

export const getPodSlotsReport = (report: DashboardSupplyReportDatum[]): number => {
    if (!report || !report.length) {
        return 0;
    }

    return report.reduce((acc, data: DashboardSupplyReportDatum) => {
        return acc + data.slotsForAdPods;
    }, 0);
};

export const getPlaylistSlotsReport = (report: DashboardSupplyReportDatum[]): number => {
    if (!report || !report.length) {
        return 0;
    }

    return report.reduce((acc, data: DashboardSupplyReportDatum) => {
        return acc + data.slotsForPlaylists;
    }, 0);
};

export const getImpressionsReport = (data: DashboardSupplyReportDatum, viewFilter): number => {
    switch (viewFilter) {
        case OVERALL.id: {
            return data.impressions;
        }
        case PODS_AND_PLAYLISTS.id: {
            return data.playlistImpressions + data.podImpressions;
        }
        case STANDARD.id: {
            return data.standardImpressions;
        }
        default: {
            return 0;
        }
    }
};

export const getTotalImpressionsReport = (report: DashboardSupplyReportDatum[], viewFilter): number => {
    if (!report) {
        return 0;
    }

    return report.reduce((acc, data: DashboardSupplyReportDatum) => {
        return acc + getImpressionsReport(data, viewFilter);
    }, 0);
};

export const getUseRateReport = (data: DashboardSupplyReportDatum, viewFilter): number => {
    switch (viewFilter) {
        case OVERALL.id: {
            return data.useRate;
        }
        case PODS_AND_PLAYLISTS.id: {
            return data.playlistUseRate + data.podUseRate;
        }
        case STANDARD.id: {
            return data.standardUseRate;
        }
        default: {
            return 0;
        }
    }
};

export const getTotalUseRateReport = (imp: number, fills: number): number => (fills === 0 ? 0 : toPercent(imp / fills));

export const percentageDifference = (newNumber: number, originalNumber: number) => {
    if (!originalNumber) {
        return 0;
    }
    return Number((((newNumber - originalNumber) / originalNumber) * 100).toFixed(2));
};

export const getTimePerTry = (adStat: AdStat) => {
    if (!adStat?.totalTime) {
        return 0;
    }

    return adStat.totalTime / getTries(adStat);
};

export const getIsAtRisk = (
    adStat: AdStat,
    preferredCurrency: Currency | undefined,
    currencyConversionMode: CurrencyConversionModes
) => {
    if (!adStat) {
        return false;
    }

    const TRIES_THRESHOLD = 2000000;
    const RISK_THRESHOLD = 0.005;

    const tries = getTries(adStat);
    if (tries >= TRIES_THRESHOLD) {
        const impressions = getImpressions(adStat, preferredCurrency, currencyConversionMode);
        return Math.ceil(impressions / tries) < RISK_THRESHOLD;
    }

    return false;
};

export const getMokas = (adStat: AdStat) => adStat?.results?.["-27"] ?? 0;

export const getSeatRejections = (adStat: AdStat, isTremorUser: boolean) => {
    if (!adStat?.results) {
        return 0;
    }

    const seatRejectionCodes = ["-6", "-18", "-19", "-20"];
    const seatRejections = Object.keys(adStat.results).reduce(
        (rejectionCount, resultCode) =>
            rejectionCount + (seatRejectionCodes.includes(resultCode) ? adStat.results[resultCode] : 0),
        0
    );

    return isTremorUser ? seatRejections + getMokas(adStat) : seatRejections;
};

export const getUserRejections = (adStat: AdStat) => adStat?.results?.["-5"] ?? 0;

export const getBlackListed = (adStat: AdStat) => adStat?.results?.["-7"] ?? 0 + adStat?.results?.["-8"] ?? 0;

export const getDenyListed = (adStat: AdStat) => {
    if (!adStat?.results) {
        return 0;
    }

    const denyListedCodes = ["-7", "-8", "-10", "-11", "-12"];
    return Object.keys(adStat.results).reduce(
        (denyCount, resultCode) => denyCount + (denyListedCodes.includes(resultCode) ? adStat.results[resultCode] : 0),
        0
    );
};

export const getTotalRejectedRequests = (adStat: AdStat | null, isTremorUser: boolean): number => {
    if (!adStat) {
        return 0;
    }
    const userRejections = getUserRejections(adStat);
    const seatRejections = getSeatRejections(adStat, isTremorUser);
    return userRejections + seatRejections;
};

export const getErrors = (adStat: AdStat, isTremorUser: boolean) => {
    if (!adStat?.results) {
        return 0;
    }

    const errorResultCodes = ["-13", "-27", "-999"];
    const errors = Object.keys(adStat.results).reduce(
        (errorCount, resultCode) =>
            errorCount +
            (parseInt(resultCode) < 0 && !errorResultCodes.includes(resultCode) ? adStat.results[resultCode] : 0),
        0
    );
    const adjustedErrors =
        errors - (getSeatRejections(adStat, isTremorUser) + getUserRejections(adStat) + getDenyListed(adStat));

    return adjustedErrors > 0 ? adjustedErrors : 0;
};

export const getFallbacks = (adStat: AdStat) => adStat?.fallbacks ?? 0;

export const getNtime = (adStat: AdStat) => adStat?.ntime ?? 0;

export const getOTime = (adStat: AdStat) => adStat?.otime ?? 0;

export const getSkips = (adStat: AdStat) => adStat?.results?.["-999"] ?? 0;

export const makeDataForToday = ({
    reportData,
    timeFrameDataProp,
    currentTz,
    last24ConsoleCastData,
    preferredCurrency,
    currencyConversions,
    currencyConversionMode,
}) => {
    let thisPeriodData: DashboardSupplyReportDatum[] = [...reportData.thisPeriod[timeFrameDataProp].data];
    const lastPeriodData: DashboardSupplyReportDatum[] = [...reportData.lastPeriod[timeFrameDataProp].data];

    const currentDay = currentTz ? getCurrentDayTZ(currentTz) : moment.utc().date();
    const previousDay = currentTz ? getCurrentDayTZ(currentTz, 1) : moment.utc().subtract(1, "days").date();
    const currentHour = currentTz ? getCurrentHourOfDayTZ(currentTz) : moment.utc().hour();

    const tzOffset = Math.trunc(moment().tz(currentTz).utcOffset() / 60);

    const statsByHours = getHistoryStatsBucketedByHour(last24ConsoleCastData);

    let currentHourlyDataLength = reportData.thisPeriod.hourlyData
        ? reportData.thisPeriod.hourlyData.data
            ? reportData.thisPeriod.hourlyData.data.length
            : 0
        : 0;
    const previousHourlyDataLength = reportData.lastPeriod.hourlyData
        ? reportData.lastPeriod.hourlyData.data
            ? reportData.lastPeriod.hourlyData.data.length
            : 0
        : 0;

    if (currentHour !== 0) {
        if (currentHourlyDataLength > currentHour - 1) {
            while (currentHourlyDataLength > currentHour - 1) {
                thisPeriodData.pop();
                currentHourlyDataLength = thisPeriodData.length;
            }
        }
    }

    if (0 === currentHour) {
        // add hours 20, 21, 22 & 23 of yesterday to previousPeriodHourlyData
        // and set today's data to null / empty
        for (let i = 20; i <= 23; i++) {
            const hourIndex = currentHour - i;
            const theData = consoleCastDataFormatter(
                statsByHours[getUtcKeyForCcData(previousDay, i, tzOffset)],
                preferredCurrency,
                currencyConversions,
                currencyConversionMode,
                i
            );
            if (hourIndex <= previousHourlyDataLength) {
                lastPeriodData[i] = theData;
            } else {
                lastPeriodData.push(theData);
            }
        }
        thisPeriodData = [];
    } else if (currentHour >= 1 && currentHour <= 3) {
        // 1 = 21,22,23,0
        // 2 = 22,23,0,1
        // 3 = 23,0,1,2

        if (currentHour === 1) {
            const hour21Data = consoleCastDataFormatter(
                statsByHours[getUtcKeyForCcData(previousDay, 21, tzOffset)],
                preferredCurrency,
                currencyConversions,
                currencyConversionMode,
                21
            );
            if (lastPeriodData[21]) {
                lastPeriodData[21] = hour21Data;
            } else {
                lastPeriodData.push(hour21Data);
            }
        }
        if (currentHour <= 2) {
            const hour22Data = consoleCastDataFormatter(
                statsByHours[getUtcKeyForCcData(previousDay, 22, tzOffset)],
                preferredCurrency,
                currencyConversions,
                currencyConversionMode,
                22
            );
            if (lastPeriodData[22]) {
                lastPeriodData[22] = hour22Data;
            } else {
                lastPeriodData.push(hour22Data);
            }
        }
        if (currentHour <= 3) {
            const hour23Data = consoleCastDataFormatter(
                statsByHours[getUtcKeyForCcData(previousDay, 23, tzOffset)],
                preferredCurrency,
                currencyConversions,
                currencyConversionMode,
                23
            );
            if (lastPeriodData[23]) {
                lastPeriodData[23] = hour23Data;
            } else {
                lastPeriodData.push(hour23Data);
            }
        }

        const hour0Data = consoleCastDataFormatter(
            statsByHours[getUtcKeyForCcData(currentDay, 0, tzOffset)],
            preferredCurrency,
            currencyConversions,
            currencyConversionMode,
            0
        );

        if (currentHourlyDataLength > 1) {
            thisPeriodData.pop();
        }

        thisPeriodData[0] = hour0Data;

        if (currentHour >= 2) {
            const hour1Data = consoleCastDataFormatter(
                statsByHours[getUtcKeyForCcData(currentDay, 1, tzOffset)],
                preferredCurrency,
                currencyConversions,
                currencyConversionMode,
                1
            );

            if (currentHourlyDataLength > 2) {
                thisPeriodData.pop();
            }

            thisPeriodData[1] = hour1Data;
        }
        if (currentHour === 3) {
            const hour2Data = consoleCastDataFormatter(
                statsByHours[getUtcKeyForCcData(currentDay, 2, tzOffset)],
                preferredCurrency,
                currencyConversions,
                currencyConversionMode,
                2
            );

            if (currentHourlyDataLength > 3) {
                thisPeriodData.pop();
            }

            thisPeriodData[2] = hour2Data;
        }
    } else if (currentHour >= 4) {
        for (let i = currentHour - 4; i < currentHour; i++) {
            const consoleCastKey = getUtcKeyForCcData(currentDay, i, tzOffset);
            const theData = consoleCastDataFormatter(
                statsByHours[consoleCastKey],
                preferredCurrency,
                currencyConversions,
                currencyConversionMode,
                consoleCastKey.split("-")[1],
                tzOffset
            );

            if (i < currentHourlyDataLength) {
                if (thisPeriodData[i].hour + "" === theData.hour + "") {
                    thisPeriodData[i] = theData;
                } else {
                    for (let j = 0; j < thisPeriodData.length; j++) {
                        if (thisPeriodData[j].hour + "" === theData.hour + "") {
                            thisPeriodData[j] = theData;
                            break;
                        }
                    }
                }
            } else {
                let found = false;
                for (let j = 0; j < thisPeriodData.length; j++) {
                    if (thisPeriodData[j].hour + "" === theData.hour + "") {
                        thisPeriodData[j] = theData;
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    thisPeriodData.push(theData);
                }
            }
        }
    }

    return { thisPeriodData, lastPeriodData };
};

export const makeWeeklyData = ({
    reportData,
    timeFrameDataProp,
    currentTz,
    last24ConsoleCastData,
    preferredCurrency,
    currencyConversions,
    currencyConversionMode,
}) => {
    const thisPeriodData: DashboardSupplyReportDatum[] = [...reportData.thisPeriod[timeFrameDataProp].data];
    const lastPeriodData: DashboardSupplyReportDatum[] = [...reportData.lastPeriod[timeFrameDataProp].data];

    const currentDay = currentTz ? getCurrentDayTZ(currentTz) : moment.utc().date();
    const previousDay = currentTz ? getCurrentDayTZ(currentTz, 1) : moment.utc().subtract(1, "days").date();
    const currentHour = moment.utc().hour();

    const statsByHours = getHistoryStatsBucketedByHour(last24ConsoleCastData);

    const previousDayDate = moment.utc().startOf("day").subtract(1, "days").format("YYYY-MM-DD");
    const currentDayDate = moment.utc().startOf("day").format("YYYY-MM-DD");

    const tzOffset = Math.trunc(moment().tz(currentTz).utcOffset() / 60);

    if (0 === currentHour) {
        for (let i = 22; i <= 23; i++) {
            const hourIndex = currentHour - i;
            const consoleCastKey = previousDay + "-" + hourIndex;
            const theData = consoleCastDataFormatter(
                statsByHours[consoleCastKey],
                preferredCurrency,
                currencyConversions,
                currencyConversionMode,
                hourIndex,
                tzOffset
            );
            theData.day = previousDayDate;
            theData.hour = i;
            thisPeriodData.push(theData);
        }
    } else if (1 === currentHour) {
        let theData = consoleCastDataFormatter(
            statsByHours[previousDay + "-" + 23],
            preferredCurrency,
            currencyConversions,
            currencyConversionMode,
            23,
            tzOffset
        );
        theData.day = previousDayDate;
        theData.hour = 23;
        thisPeriodData.push(theData);

        theData = consoleCastDataFormatter(
            statsByHours[currentDay + "-" + 0],
            preferredCurrency,
            currencyConversions,
            currencyConversionMode,
            0,
            tzOffset
        );

        theData.day = currentDayDate;
        theData.hour = 0;
    } else if (currentHour >= 2) {
        for (let i = 0; i < currentHour; i++) {
            const consoleCastKey = getUtcKeyForCcData(currentDay, i, 0);
            const theData = consoleCastDataFormatter(
                statsByHours[consoleCastKey],
                preferredCurrency,
                currencyConversions,
                currencyConversionMode,
                consoleCastKey.split("-")[1],
                tzOffset
            );
            theData.day = currentDayDate;
            theData.hour = i;
            thisPeriodData.push(theData);
        }
    }
    return { thisPeriodData, lastPeriodData };
};

export const makeDataForMonth = ({ reportData, thisPeriodHourlyData, timeFrameDataProp }) => {
    const thisPeriodData: DashboardSupplyReportDatum[] = [...reportData.thisPeriod[timeFrameDataProp].data];
    const lastPeriodData: DashboardSupplyReportDatum[] = [...reportData.lastPeriod[timeFrameDataProp].data];

    if (!thisPeriodData.length) {
        return { thisPeriodData, lastPeriodData };
    }

    const lastDayOfThisPeriod = moment(thisPeriodData[thisPeriodData.length - 1].day)
        .add(1, "days")
        .format("YYYY-MM-DD");
    const theData = thisPeriodHourlyData.reduce(
        (acc, curr) => {
            for (const key of Object.keys(curr).filter((key) => !["currency", "day"].includes(key))) {
                if (!acc[key]) {
                    acc[key] = curr[key];
                } else {
                    acc[key] += curr[key];
                }
            }
            return acc;
        },
        {
            day: lastDayOfThisPeriod,
        }
    );
    thisPeriodData.push(theData);

    return { thisPeriodData, lastPeriodData };
};

function getHistoryStatsBucketedByHour(origLast24HrStat = []) {
    const hourBucketObj = {};
    const last24HrStat = cloneDeep(origLast24HrStat);
    last24HrStat.forEach((aDataPoint) => {
        const sampleTime = moment.utc(aDataPoint.otime);
        const theHour = sampleTime.date() + "-" + sampleTime.hour();

        aDataPoint.dayOfMonth = sampleTime.date(); // 1-30/31
        aDataPoint.hourOfDay = sampleTime.hour(); // 0-23
        aDataPoint.month = sampleTime.month(); // 0-11

        if (!hourBucketObj.hasOwnProperty(theHour)) {
            hourBucketObj[theHour] = aDataPoint;
        } else {
            addUpCcStatNumbers(hourBucketObj[theHour], aDataPoint);
        }
    });

    return hourBucketObj;
}

function addUpCcStatNumbers(dest = {}, source = {}) {
    if (!dest) {
        dest = {};
    }
    if (!source) {
        source = {};
    }

    Object.keys(source).forEach((key) => {
        if (!dest[key]) {
            dest[key] = source[key];
        } else {
            if (typeof source[key] === "number") {
                dest[key] += source[key];
            } else if (typeof dest[key] !== "string") {
                addUpCcStatNumbers(dest[key], source[key]);
            }
        }
    });
}

function consoleCastDataFormatter(
    stats,
    preferredCurrency,
    currencyConversions,
    currencyConversionMode,
    hour,
    utcOffset?
) {
    return {
        netRevenue: getTotalRevenue(
            stats,
            preferredCurrency,
            currencyConversions,
            currencyConversionMode,
            RevenueTypes.NET_REVENUE
        ),
        grossRevenue: getTotalRevenue(
            stats,
            preferredCurrency,
            currencyConversions,
            currencyConversionMode,
            RevenueTypes.GROSS_REVENUE
        ),
        requests: getRequests(stats),
        overallOpportunities: getOpportunities(stats),
        impressions: getImpressions(stats, preferredCurrency, currencyConversionMode),
        fills: getFills(stats, preferredCurrency, currencyConversionMode),
        netRevenueCpm: +getEstimatedECPM(
            stats,
            preferredCurrency,
            currencyConversions,
            currencyConversionMode,
            RevenueTypes.NET_REVENUE
        ),
        grossRevenueCpm: +getEstimatedECPM(
            stats,
            preferredCurrency,
            currencyConversions,
            currencyConversionMode,
            RevenueTypes.GROSS_REVENUE
        ),
        fillRate: getFillRate(stats, preferredCurrency, currencyConversionMode) / 100,
        useRate: getImpressionsUseRate(stats, preferredCurrency, currencyConversionMode) / 100,
        playlistNetRevenue: getPlaylistRevenue(
            stats,
            preferredCurrency,
            currencyConversions,
            currencyConversionMode,
            RevenueTypes.NET_REVENUE
        ),
        playlistGrossRevenue: getPlaylistRevenue(
            stats,
            preferredCurrency,
            currencyConversions,
            currencyConversionMode,
            RevenueTypes.GROSS_REVENUE
        ),
        playlistRequests: getPlaylistRequests(stats),
        playlistImpressions: getPlaylistImpressions(stats, preferredCurrency, currencyConversionMode),
        playlistFills: getPlaylistFills(stats, preferredCurrency, currencyConversionMode),
        playlistAcceptedRequests: getPlaylistRequests(stats),
        standardFillRate: getStandardFillRate(stats, preferredCurrency, currencyConversionMode) / 100,
        standardFills: getStandardFills(stats, preferredCurrency, currencyConversionMode),
        standardGrossRevenue: getStandardRevenue(
            stats,
            preferredCurrency,
            currencyConversions,
            currencyConversionMode,
            RevenueTypes.GROSS_REVENUE
        ),
        standardImpressions: getStandardImpressions(stats, preferredCurrency, currencyConversionMode),
        standardNetRevenue: getStandardRevenue(
            stats,
            preferredCurrency,
            currencyConversions,
            currencyConversionMode,
            RevenueTypes.NET_REVENUE
        ),
        standardRequests: getStandardRequests(stats),
        slotsForAdPods: getPodSlots(stats),
        slotsForPlaylists: getPlaylistSlots(stats),
        hour: getConsoleCastDataHour(hour, utcOffset),
        acceptedRequests: getTotalCleanRequests(stats),
    } as DashboardSupplyReportDatum;
}

function getConsoleCastDataHour(hour, utcOffset) {
    if (!utcOffset) {
        return hour;
    }

    const utcOffsetHour = (parseInt(hour) + utcOffset) % 24;

    return utcOffsetHour >= 0 ? utcOffsetHour : 24 + utcOffsetHour;
}

function getUtcKeyForCcData(currentDay: number, index: number, offset: number) {
    let retStr: string | null = null;

    if (index - offset < 0) {
        // We are ahead of UTC.
        retStr = currentDay - 1 + "-" + (24 + index - offset);
    } else if (index - offset > 23) {
        // We are behind UTC and the conversion requires rolling forward to next day
        retStr = currentDay + 1 + "-" + (index - offset - 24);
    } else {
        retStr = currentDay + "-" + (index - offset);
    }

    return retStr;
}

function getCurrentHourOfDayTZ(zone) {
    const format = "H";
    const now = moment.utc();
    return parseInt(moment(now).tz(zone).format(format));
}

function getCurrentDayTZ(zone, offset?) {
    const now = moment.utc();
    if (offset) {
        return moment(now).subtract(offset, "days").tz(zone).date();
    }
    return moment(now).tz(zone).date();
}

const getAdStatsAsArray = (adStats: AdStat | AdStat[] | AdStatsById | null | undefined): AdStat[] | undefined => {
    if (!adStats) {
        return undefined;
    }
    if ("otime" in adStats) {
        return [adStats];
    }
    if (Array.isArray(adStats)) {
        return adStats;
    }
    const adStatValues = Object.values(adStats).filter((adStat) => adStat && "otime" in adStat);
    return adStatValues.length ? adStatValues : undefined;
};

export const getOtimesForThrottleUnit = (
    adStats: AdStat | AdStat[] | AdStatsById | null | undefined
): number | number[] | undefined => {
    const adStatsArray = getAdStatsAsArray(adStats);
    if (Array.isArray(adStatsArray) && adStatsArray.length) {
        const otimes = adStatsArray
            .map((adStat) => adStat?.otime)
            .filter((otime) => typeof otime === "number" && otime > 0);
        if (otimes?.length) {
            if (otimes.length === 1) {
                return otimes[0];
            }
            return otimes;
        }
    }
};
