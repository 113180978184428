import { css } from "@emotion/css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ResizableDrawer } from "@rubicon/antd-components";
import { Button, Flex, Typography } from "antd";
import { FC } from "react";
import { DrawerCloseButton } from "@app/core/components";
import { backArrowStyle } from "@app/features/inventory/BrandSafetyDetailsDrawer";

export const SeatFloorsAdvancedDrawerLayout: FC<{
    title: string;
    closeHandler: () => void;
    handleEdit?: () => void;
    handleBack?: () => void;
}> = ({ children, closeHandler, title, handleEdit, handleBack }) => {
    return (
        <Flex vertical>
            <Flex justify="space-between" align="baseline" style={{ padding: "1rem 1rem 1.5rem 1.5rem" }}>
                <Flex vertical flex={1} wrap="wrap" gap="middle">
                    <Flex gap="small" flex={1}>
                        {handleBack && (
                            <Button
                                data-sdet="seat-ad-sources-details-drawer-nested-title-back-button"
                                type="text"
                                onClick={handleBack}
                                className={backArrowStyle}
                                icon={<ArrowLeftOutlined style={{ fontSize: "14px" }} />}
                            />
                        )}
                        <Typography.Title level={4} style={{ marginTop: 0, marginBottom: 0 }}>
                            {title}
                        </Typography.Title>
                    </Flex>
                    {handleEdit && (
                        <div>
                            <Button onClick={handleEdit} type="primary">
                                Edit
                            </Button>
                        </div>
                    )}
                </Flex>
                <DrawerCloseButton onClose={() => closeHandler()} />
            </Flex>
            {children}
        </Flex>
    );
};

interface Props {
    onClose: () => void;
}
export const SeatFloorsAdvancedDrawer: FC<Props> = ({ onClose, children }) => {
    return (
        <ResizableDrawer
            open={true}
            closable={false}
            onClose={onClose}
            width="40%"
            placement="right"
            className={css`
                .ant-drawer-body {
                    padding: 0;
                }
            `}
        >
            {children}
        </ResizableDrawer>
    );
};
