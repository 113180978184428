import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { LabeledValue } from "antd/es/select";
import { useGetSeatThirdPartyPixelsQuery } from "@app/core/services";

interface UseThirdpartyPixels {
    isFetching: boolean;
    options: LabeledValue[];
}

export const useThirdpartyPixels = (): UseThirdpartyPixels => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data = [], isFetching } = useGetSeatThirdPartyPixelsQuery(Number(seatId));

    const options = useMemo(() => (data || []).map((pixel) => ({ value: pixel.id, label: pixel.name })), [data]);
    return {
        isFetching,
        options,
    };
};
