import { FC } from "react";
import { Button, Col, Row, Select, Typography } from "antd";
import { LabeledValue } from "antd/lib/select";
import { DeleteOutlined } from "@ant-design/icons";
import {
    SegmentRuleIdentifierOperators,
    SEGMENT_RULE_IDENTIFIER_OPERATOR_OPTIONS,
} from "@app/features/targeting/constants";
import { SegmentRuleIdentifier } from "@app/features/targeting/types";
import { getSegmentRuleIdentifierFieldLabel } from "../helpers";
import { AdvancedSegmentSelect } from "./AdvancedSegmentSelect";

interface Props {
    isValidationShown: boolean;
    onChangeOperator: (path: number[], operator: SegmentRuleIdentifierOperators) => void;
    onChangeValue: (path: number[], value: LabeledValue | null) => void;
    onClickDeleteRule: (path: number[]) => void;
    path: number[];
    rule: SegmentRuleIdentifier;
}

export const SegmentRuleIdentifierRow: FC<Props> = ({
    isValidationShown,
    rule,
    onChangeOperator,
    onChangeValue,
    onClickDeleteRule,
    path,
}) => {
    return (
        <Row gutter={[8, 8]}>
            <Col style={{ paddingTop: "5px" }}>
                <Typography.Text strong>{getSegmentRuleIdentifierFieldLabel(rule.field)}</Typography.Text>
            </Col>
            <Col>
                <Select
                    onChange={(operator) => onChangeOperator(path, operator)}
                    value={rule.operator}
                    options={SEGMENT_RULE_IDENTIFIER_OPERATOR_OPTIONS}
                    dropdownMatchSelectWidth={false}
                />
            </Col>
            <Col flex="1">
                <AdvancedSegmentSelect
                    onChange={(value) => onChangeValue(path, value)}
                    value={rule.value}
                    isValidationShown={isValidationShown}
                />
            </Col>
            <Col>
                <Button icon={<DeleteOutlined />} onClick={() => onClickDeleteRule(path)} />
            </Col>
        </Row>
    );
};
