import { FC, useMemo } from "react";
import { OptimizerQueriesResults } from "@app/core/services";
import { Metrics } from "@app/core/components/charts/DualAxesChart";
import { TogglableChart } from "@app/core/components/charts/TogglableChart/TogglableChart";
import { ESTIMATED_REVENUE_LABEL, ESTIMATED_WIN_RATE_LABEL } from "@app/core/components/charts/constants";
import { DualAxesCurrencyBarRateLineChart } from "./DualAxesCurrencyBarRateLineChart";

interface Props {
    queryResults: OptimizerQueriesResults[] | undefined;
}

export const EstimatedFloorBreakdownChart: FC<Props> = ({ queryResults = [] }) => {
    const { metricOne, metricTwo, actualRevenue, actualFloorPrice } = useMemo(() => {
        const metrics: Metrics = { metricOne: [], metricTwo: [] };
        let actualRevenue = 0;
        let actualFloorPrice = 0;
        if (queryResults && queryResults.length) {
            let isAddedActualData = false;
            for (const floorEstimatesRecord of queryResults) {
                if (
                    !isAddedActualData &&
                    floorEstimatesRecord.recordedAvgFloorPrice < floorEstimatesRecord.potentialFloor
                ) {
                    actualRevenue = floorEstimatesRecord.recordedRevenue;
                    actualFloorPrice = floorEstimatesRecord.recordedAvgFloorPrice;
                    metrics.metricOne.push({
                        time: floorEstimatesRecord.recordedAvgFloorPrice.toString(),
                        value1: floorEstimatesRecord.recordedRevenue,
                        name1: ESTIMATED_REVENUE_LABEL,
                    });
                    isAddedActualData = true;
                }
                metrics.metricOne.push({
                    time: floorEstimatesRecord.potentialFloor.toString(),
                    value1: floorEstimatesRecord.estimatedRevenue,
                    name1: ESTIMATED_REVENUE_LABEL,
                });

                metrics.metricTwo.push({
                    time: floorEstimatesRecord.potentialFloor.toString(),
                    value2: floorEstimatesRecord.estimatedWinRate * 100,
                    name2: ESTIMATED_WIN_RATE_LABEL,
                });
            }
        }
        return { ...metrics, actualRevenue, actualFloorPrice };
    }, [queryResults]);

    return (
        <TogglableChart
            metricOne={metricOne}
            metricTwo={metricTwo}
            showClearAll={false}
            chartRenderer={(filteredMetricOne, filteredMetricTwo) => (
                <DualAxesCurrencyBarRateLineChart
                    actualRevenue={actualRevenue}
                    actualFloorPrice={actualFloorPrice}
                    chartId="estimated-floor-breakdown-chart"
                    metricOne={filteredMetricOne}
                    metricTwo={filteredMetricTwo}
                />
            )}
        />
    );
};
