import { NONE } from "@app/core/components/constants";
import { ThirdPartyPixel } from "@app/core/services";
import { GUTTER } from "@app/features/inventory/constants";
import { Form, Row, Col, Tag, ColProps } from "antd";
import { FC } from "react";
import { COL_SIZES, FORM_ITEM_OFFSET } from "../../constants";
import { usePixels } from "./usePixels";

const getPixelLabel = (pixel: ThirdPartyPixel): string => {
    const source = pixel?.sourceClassName?.split(".");
    if (!pixel?.sourceClassName) {
        return `${pixel?.name} [ ${pixel?.type?.name} ]`;
    }
    return `${pixel?.name} [ ${pixel?.type?.name} ] [ Source: ${source?.[source?.length - 1]} ]`;
};

interface Props {
    id: number;
    thirdPartyPixels?: ThirdPartyPixel[];
    columnProps?: ColProps;
}

const { Item } = Form;

export const Pixels: FC<Props> = ({ id, thirdPartyPixels, columnProps = { ...COL_SIZES } }) => {
    const { pixels = thirdPartyPixels } = usePixels(id);
    return (
        <div data-sdet="unit-pixels">
            <Row gutter={GUTTER}>
                <Col {...columnProps}>
                    <Item label="Custom Pixels" className={FORM_ITEM_OFFSET}>
                        {pixels?.length
                            ? pixels?.map((pixel) => {
                                  return <Tag key={pixel?.id}>{getPixelLabel(pixel)}</Tag>;
                              })
                            : NONE}
                    </Item>
                </Col>
            </Row>
        </div>
    );
};
