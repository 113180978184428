import { FC } from "react";
import { Row, Col } from "antd";
import { Route, Switch } from "react-router-dom";
import { EmailForm, PasswordForm, ContextSelectForm, ForgotPassword } from "@app/features/login/Steps";
import { ROUTES } from "@app/core/routing";
import { useLoginFormData } from "./useLoginFormData";
import { NoValidContextsForm } from "../Steps/NoValidContextsForm";

export const LoginForm: FC = () => {
    const loginFormData = useLoginFormData();

    return (
        <Switch>
            <Route exact path={ROUTES.LOGIN_FORM_EMAIL_STEP}>
                <Row>
                    <Col xs={24}>
                        <EmailForm {...loginFormData} />
                    </Col>
                </Row>
            </Route>
            <Route exact path={ROUTES.LOGIN_FORM_PASSWORD_STEP}>
                <Row>
                    <Col xs={24}>
                        <PasswordForm {...loginFormData} />
                    </Col>
                </Row>
            </Route>
            <Route exact path={ROUTES.LOGIN_FORM_CONTEXT_STEP}>
                <ContextSelectForm />
            </Route>
            <Route exact path={ROUTES.LOGIN_FORM_NO_VALID_CONTEXTS_STEP}>
                <NoValidContextsForm />
            </Route>
            <Route exact path={ROUTES.LOGIN_FORM_FORGOT_PASSWORD_STEP}>
                <ForgotPassword {...loginFormData} />
            </Route>
        </Switch>
    );
};
