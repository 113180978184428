import { FC } from "react";
import { Form, InputNumber } from "antd";
import { ColProps } from "antd/lib/grid/col";
import { InputNumberProps } from "antd/lib/input-number";
import { MAX_ADS_PER_POD } from "@app/features/inventory/HierarchyForms/constants";

export interface AdUnitMaxAdsPerPodProps extends InputNumberProps {
    name: typeof MAX_ADS_PER_POD;
    wrapperCol?: ColProps;
    labelCol?: ColProps;
    label: string;
}

export const AdUnitMaxAdsPerPod: FC<AdUnitMaxAdsPerPodProps> = ({ name, label, wrapperCol, labelCol, ...rest }) => {
    return (
        <Form.Item
            name={name}
            label={label}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            rules={[
                {
                    required: true,
                    validator(_, value) {
                        if (!value) return Promise.reject(new Error("Max Ads Per Pod is required"));
                        if (value > 8) return Promise.reject(new Error("Can't have more than 8 ads per pod"));
                        return Promise.resolve();
                    },
                },
            ]}
        >
            <InputNumber style={{ width: "100%" }} {...rest} />
        </Form.Item>
    );
};
