import { FC } from "react";
import { AdUnitPodEnforcement } from "@app/features/inventory/components/FormItems";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const PodEnforcement: FC = () => (
    <AdUnitPodEnforcement
        name={CHANNEL_FORM_FIELDS.POD_ENFORCEMENT.name}
        label={CHANNEL_FORM_FIELDS.POD_ENFORCEMENT.label}
    />
);
