import { BulkOperationSourceEntity } from "@app/core/services";
import { Form } from "antd";
import { FC } from "react";
import { BulkOperationCopyPublisherForm } from "./BulkOperationCopyPublisherForm";
import { BulkOperationCopyBrandForm } from "./BulkOperationCopyBrandForm";
import { BulkOperationCopySupplyForm } from "./BulkOperationCopySupplyForm";
import { BulkOperationCopyAdUnitForm } from "./BulkOperationCopyAdUnitForm";

export const BulkOperationCopyForm: FC = () => {
    const form = Form.useFormInstance();
    const { sourceEntity } = form.getFieldsValue(true);

    switch (sourceEntity) {
        case BulkOperationSourceEntity.Publisher:
            return <BulkOperationCopyPublisherForm />;
        case BulkOperationSourceEntity.Brand:
            return <BulkOperationCopyBrandForm />;
        case BulkOperationSourceEntity.Supply:
            return <BulkOperationCopySupplyForm />;
        case BulkOperationSourceEntity.AdUnit:
            return <BulkOperationCopyAdUnitForm />;
        default:
            return null;
    }
};
