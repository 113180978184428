import { FC } from "react";
import { Col, Row, Form, Space, Typography, Slider } from "antd";
import { MomentDatePicker } from "@app/core/components/MomentDatePicker";
import { DualAxes, Column } from "@ant-design/plots";
import { css } from "@emotion/css";
import { useAdSourceCustomPacing } from "./useAdSourceCustomPacing";
import { YEAR_MONTH_DAY } from "@app/core/components/constants";
import { GUTTER } from "@app/features/deals/constants";
import { useAdSourceCustomPacingCharts } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourcePacingSection/Fields/AdSourceCustomPacing/useAdSourceCustomPacingCharts";
import { gray7 } from "@rubicon/antd-components";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";

const className = css`
    font-size: 12px;
    color: ${gray7};
`;

const classNameConclusion = css`
    display: flex;
    flex-direction: column;
`;

const classNameSlider = css`
    width: 100%;
    .ant-space-item:first-child {
        flex-basis: 60%;
    }
`;

export const AdSourceCustomPacing: FC = () => {
    const {
        customCurveImps,
        totalImpressions,
        customCurveStartDate,
        startDate,
        endDate,
        sliderValue,
        sliderPercentage,
        limits,
        formatter,
        handleChangePicker,
        handleChangeSlider,
    } = useAdSourceCustomPacing();
    const { chartConfig, histogramChartConfig } = useAdSourceCustomPacingCharts();

    return (
        <Row gutter={GUTTER}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                <Form.Item label={AD_SOURCE_FIELDS.TARGET_DATE.label} required data-sdet="target-date">
                    <MomentDatePicker
                        style={{ width: "100%" }}
                        value={customCurveStartDate}
                        onChange={handleChangePicker}
                    />
                </Form.Item>
                <Form.Item label="Delivery Percentage" required data-sdet="delivery-percentage">
                    <Space className={classNameSlider}>
                        <Slider
                            min={limits?.min}
                            max={limits?.max}
                            tooltip={{ formatter }}
                            onChange={handleChangeSlider}
                            value={sliderValue}
                        />
                        <div>{sliderPercentage}%</div>
                    </Space>

                    <div className={classNameConclusion}>
                        <Typography.Text className={className}>Total impressions: {totalImpressions}</Typography.Text>
                        <Typography.Text className={className}>
                            Flight Dates: {startDate} to {endDate}
                        </Typography.Text>
                        {Boolean(customCurveImps) && (
                            <Typography.Text className={className}>
                                {customCurveImps} impressions will be delivered by EOD&nbsp;
                                {customCurveStartDate.format(YEAR_MONTH_DAY)}
                            </Typography.Text>
                        )}
                    </div>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={12}>
                <Row gutter={[12, 12]}>
                    <Col xs={24} md={12}>
                        <DualAxes {...chartConfig} />
                    </Col>
                    <Col xs={24} md={12}>
                        <Column {...histogramChartConfig} />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
