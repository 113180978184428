import { Button } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { FC } from "react";
import { useHelpKeysButton } from "./useHelpKeysButton";
import { brandCobalt } from "@rubicon/antd-components";

export type BrandSafetySectionKey = "general" | "targeting";

interface Props {
    label: string;
    sectionKey: BrandSafetySectionKey;
}
export const HelpKeysButton: FC<Props> = ({ label, sectionKey }) => {
    const { onClick } = useHelpKeysButton(sectionKey);

    return (
        <Button
            onClick={onClick}
            type="link"
            style={{ color: brandCobalt, padding: 0, height: "fit-content" }}
            data-sdet={sectionKey.toLocaleLowerCase().replace(/ /g, "-")}
        >
            <InfoCircleOutlined />
            {label}
        </Button>
    );
};
