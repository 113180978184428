export const ENTITY_TYPES = {
    CHANNEL_AD_UNIT: "channelAdUnit",
};

export const ENTITY_CLASS_TYPES = {
    SEAT: "com.tremorvideo.ssp.platform.model.Seat",
    SEAT_CONTACT: "com.tremorvideo.ssp.platform.model.SeatContact",
    PUBLISHER: "com.tremorvideo.ssp.platform.model.Publisher",
    BRAND: "com.tremorvideo.ssp.platform.model.Brand",
    SUPPLY: "com.tremorvideo.ssp.platform.model.Supply",
    AD_UNIT: "com.tremorvideo.ssp.platform.model.AdUnit",
    CHANNEL: "com.tremorvideo.ssp.platform.model.Channel",
    NETWORK: "com.tremorvideo.ssp.platform.model.Network",
    LINE_ITEM: "com.tremorvideo.ssp.platform.model.LineItem",
    END_POINT: "com.tremorvideo.ssp.platform.model.NetworkEndpoint",
    USER: "com.tremorvideo.ssp.identity.model.User",
    USER_SESSION: "com.tremorvideo.ssp.identity.model.UserSession",
    USER_GROUP: "com.tremorvideo.ssp.identity.model.UserGroup",
    AD_SOURCE: "com.tremorvideo.ssp.platform.model.AdSource",
    AD_SOURCE_TYPE: "com.tremorvideo.ssp.platform.model.AdSourceType",
    BUYER_DEAL: "com.tremorvideo.ssp.dealsync.model.BuyerDeal",
    AUCTION_PACKAGE_DEAL: "com.tremorvideo.ssp.dealsync.model.AuctionPackageDeal",
    DEMAND_DEAL: "com.tremorvideo.ssp.dealsync.model.DemandDeal",
    DEAL: "com.tremorvideo.ssp.platform.model.Deal",
    FLOOR: "com.tremorvideo.ssp.platform.model.Floor",
    REUSABLE_TARGETING: "com.tremorvideo.ssp.platform.model.Targeting",
    SUPPLY_DOMAIN_FILTER_LIST: "com.tremorvideo.ssp.platform.model.SupplyDomainFilterList",
    BUNDLE_ID_FILTER_LIST: "com.tremorvideo.ssp.platform.model.BundleIdFilterList",
    ADVANCED_FLOOR: "com.tremorvideo.ssp.platform.model.AdvancedFloor",
    CUSTOM_PIXEL: "com.tremorvideo.ssp.platform.model.ThirdPartyPixel",
    CUSTOM_REPORT: "com.tremorvideo.ssp.reporting.model.CustomReport",
    ADVERTISER: "com.tremorvideo.ssp.platform.model.Advertiser",
    ORDER: "com.tremorvideo.ssp.platform.model.Order",
    LIBRARY_CREATIVE: "com.tremorvideo.ssp.platform.LibraryCreative",
    MARKETPLACE_INFO: "com.tremorvideo.ssp.platform.model.MarketPlaceInfo",
    LINE_ITEM_CREATIVE: "com.tremorvideo.ssp.platform.model.LineItemCreative",
    INDUSTRY: "com.tremorvideo.ssp.platform.model.Industry",
    ADVANCED_BRAND_SAFETY: "com.tremorvideo.ssp.platform.model.AdvancedBrandSafety",
    MARKETPLACE: "com.tremorvideo.ssp.platform.model.Marketplace",
    ADVANCED_AD_BREAK_RULE: "com.tremorvideo.ssp.platform.model.AdvancedAdBreakRule",
    MARKETPLACE_INFO_BUYER_DEAL: "com.tremorvideo.ssp.platform.model.MarketPlaceInfoBuyerDeal",
    MARKETPLACE_INFO_DEMAND_DEAL: "com.tremorvideo.ssp.platform.model.MarketPlaceInfoDemandDeal",
    MARKETPLACE_INFO_AUCTION_PACKAGE_DEAL: "com.tremorvideo.ssp.platform.model.MarketPlaceInfoAuctionPackageDeal",
    LABEL: "com.tremorvideo.ssp.platform.model.Label",
};

export const BRAND_TYPES = {
    CHANNEL: 2,
};

export const GEO_TYPES = {
    COUNTRY: 1,
    STATE: 2,
    CITY: 3,
    DMA: 4,
    POSTAL_CODE: 5,
    CMA: 6,
};
