import { FC } from "react";
import { Form, Select } from "antd";
import { Seat } from "@app/core/services";
import { ConditionalTagRender, TagRender } from "@app/core/components";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { useExternalDataStreams } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/AdvancedFeaturesForm/AdvancedFeaturesFormFields/ExternalDataStreams/useExternalDataStreams";

interface ExternalDataStreamsProps {
    seat: Seat;
    edit?: boolean;
}

export const ExternalDataStreams: FC<ExternalDataStreamsProps> = ({ edit = false, seat }) => {
    const { isFetching, options } = useExternalDataStreams();
    return (
        <Form.Item
            label={INVENTORY_FORM_FIELDS.EXTERNAL_DATA_STREAMS.label}
            name={INVENTORY_FORM_FIELDS.EXTERNAL_DATA_STREAMS.name}
        >
            {edit ? (
                <Select
                    showArrow
                    allowClear
                    showSearch
                    labelInValue
                    mode="multiple"
                    options={options}
                    loading={isFetching}
                    tagRender={TagRender}
                    optionFilterProp="label"
                    autoClearSearchValue={false}
                    placeholder={INVENTORY_FORM_FIELDS.EXTERNAL_DATA_STREAMS.placeholder}
                />
            ) : (
                <ConditionalTagRender data={seat} property={INVENTORY_FORM_FIELDS.EXTERNAL_DATA_STREAMS.name} />
            )}
        </Form.Item>
    );
};
