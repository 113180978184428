export const CLASSIFICATIONS_ADVERTISER_NAME_INPUT_SDET = "classifications-advertiser-name-input";
export const CLASSIFICATIONS_ADVERTISER_CODE_INPUT_SDET = "classifications-advertiser-code-input";
export const CLASSIFICATIONS_ADVERTISER_DESCRIPTION_INPUT_SDET = "classifications-advertiser-description-input";
export const CLASSIFICATIONS_ADVERTISER_PARENT_ADVERTISER_SELECT_SDET =
    "classifications-advertiser-parent-advertiser-select";
export const CLASSIFICATIONS_ADVERTISER_ADVERTISER_DOMAINS_INPUT_SDET =
    "classifications-advertiser-advertiser-domains-input";
export const CLASSIFICATIONS_ADVERTISER_INDUSTRY_SELECT_SDET = "classifications-advertiser-industry-select";
export const CLASSIFICATIONS_ADVERTISER_DELETE_BUTTON_SDET = "classifications-advertiser-delete-btn";

export const ID_FORM_NAME = "id";
export const NAME_FORM_NAME = "name";
export const CODE_FORM_NAME = "code";
export const DESCRIPTION_FORM_NAME = "description";
export const PARENT_ADVERTISER_FORM_NAME = "parentAdvertiser";
export const ADVERTISER_DOMAINS_FORM_NAME = "advertiserDomains";
export const INDUSTRY_FORM_NAME = "industry";

export const CLASSIFICATIONS_ADVERTISER_FORM_ITEMS = {
    ID: {
        hidden: true,
        name: ID_FORM_NAME,
    },
    NAME: {
        required: true,
        name: NAME_FORM_NAME,
        label: "Name",
        rules: [
            { required: true, message: "Name is required.", whitespace: true },
            { max: 70, message: "Name cannot be longer than 70 characters." },
        ],
    },
    CODE: {
        name: CODE_FORM_NAME,
        label: "Advertiser Code",
    },
    DESCRIPTION: {
        name: DESCRIPTION_FORM_NAME,
        label: "Description",
        rules: [{ max: 1024, message: "Description cannot be longer than 1024 characters." }],
    },
    PARENT_ADVERTISER: {
        name: PARENT_ADVERTISER_FORM_NAME,
        label: "Parent Advertiser",
    },
    ADVERTISER_DOMAINS: {
        name: ADVERTISER_DOMAINS_FORM_NAME,
        label: "Advertiser Domains",
    },
    INDUSTRY: {
        name: INDUSTRY_FORM_NAME,
        label: "Industry",
    },
};

interface LabelValue {
    label: string;
    value: number;
}
export type ClassificationsAdvertiserFormValues = {
    [ID_FORM_NAME]: number;
    [NAME_FORM_NAME]: string;
    [CODE_FORM_NAME]?: string;
    [DESCRIPTION_FORM_NAME]?: string;
    [PARENT_ADVERTISER_FORM_NAME]?: LabelValue | null;
    [ADVERTISER_DOMAINS_FORM_NAME]?: string;
    [INDUSTRY_FORM_NAME]?: LabelValue | null;
};
