import { useCallback } from "react";
import { FormInstance } from "antd";
import { MarketplaceInfo } from "@app/core/services";
import { convertLocalDateToApi } from "@app/core/utils";
import { SyncedFields, useMapStatuses } from "@app/features/syncedFields";
import { AD_SOURCE_FIELDS } from "../../constants";
import { AdSourcesForm } from "..";

interface UseMapSyncedFieldsToDeal {
    mapSyncedFieldsToDealFields: (
        syncedFields: SyncedFields,
        marketplaceInfo: MarketplaceInfo,
        adSourceForm: FormInstance<AdSourcesForm>
    ) => MarketplaceInfo;
    mapSyncedFieldsToLegacyDealFields: (
        syncedFields: SyncedFields,
        marketplaceInfo: MarketplaceInfo
    ) => MarketplaceInfo;
}

export const useMapSyncedFieldsToDeal = (): UseMapSyncedFieldsToDeal => {
    const { mapAdSourceStatusToDealStatus } = useMapStatuses();

    const mapSyncedFieldsToDealFields = useCallback(
        (syncedFields: SyncedFields, marketplaceInfo: MarketplaceInfo, adSourceForm: FormInstance<AdSourcesForm>) => {
            const fields = { ...marketplaceInfo };

            if (syncedFields.startDate) {
                const updatedStartDate = convertLocalDateToApi(
                    syncedFields.startDate,
                    adSourceForm.getFieldValue(AD_SOURCE_FIELDS.TIME_ZONE.name)?.code
                );
                if (updatedStartDate) {
                    fields.deal.startTime = updatedStartDate;
                }
            }

            if (syncedFields.endDate !== undefined) {
                fields.deal.endTime = convertLocalDateToApi(
                    syncedFields.endDate,
                    adSourceForm.getFieldValue(AD_SOURCE_FIELDS.TIME_ZONE.name)?.code
                );
            }

            if (syncedFields.timeZone) {
                const updatedStartTime = convertLocalDateToApi(
                    adSourceForm.getFieldValue(AD_SOURCE_FIELDS.START_DATE.name),
                    syncedFields.timeZone.code
                );
                if (updatedStartTime) {
                    fields.deal.startTime = updatedStartTime;
                }
                if (marketplaceInfo.deal.endTime) {
                    fields.deal.endTime = convertLocalDateToApi(
                        adSourceForm.getFieldValue(AD_SOURCE_FIELDS.END_DATE.name),
                        syncedFields.timeZone.code
                    );
                }
                fields.deal.timeZone = syncedFields.timeZone;
            }

            if (syncedFields.cpmRate) {
                fields.deal.rate = syncedFields.cpmRate;
            }

            if (syncedFields.status) {
                const dealStatus = mapAdSourceStatusToDealStatus(syncedFields.status.id);
                if (dealStatus) {
                    fields.deal.status = dealStatus;
                }
            }

            if (syncedFields.currency) {
                fields.deal.currencyType = syncedFields.currency;
            }

            if ("currentlyUsedInAdSources" in fields.deal) {
                // the API returns this field but doesn't allow it when we update buyer deals
                // TODO - remove once fixed in API and update MarketplaceInfo / Deal types
                delete fields.deal["currentlyUsedInAdSources"];
            }

            const dealStartTime = convertLocalDateToApi(fields.deal.startTime, fields.deal.timeZone?.code);
            if (dealStartTime) {
                fields.deal.startTime = dealStartTime;
            }

            fields.deal.endTime = convertLocalDateToApi(fields.deal.endTime, fields.deal.timeZone?.code);

            return fields;
        },
        [mapAdSourceStatusToDealStatus]
    );

    const mapSyncedFieldsToLegacyDealFields = useCallback(
        (syncedFields: SyncedFields, marketplaceInfo: MarketplaceInfo) => {
            const fields = { ...marketplaceInfo };

            if (syncedFields.startDate) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                fields.startDate = syncedFields.startDate;
            }

            if (syncedFields.endDate !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                fields.endDate = syncedFields.endDate;
            }

            return fields;
        },
        []
    );

    return {
        mapSyncedFieldsToDealFields,
        mapSyncedFieldsToLegacyDealFields,
    };
};
