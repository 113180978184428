import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME, DEAL_TYPES } from "@app/features/deals/constants";
import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM, NO_END_DATE } from "@app/core/components/constants";
import { format } from "@rubicon/utils";
import moment from "moment-timezone";

interface UseAdSourceEndDateField {
    value: string;
    required: boolean;
}

export const getEndDateValue = (value: string | null, required: boolean): string => {
    if (value) {
        return moment(value).format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM);
    }
    if (required) {
        return format.constants.DEFAULT_DASHES;
    }
    return NO_END_DATE;
};

export const useAdSourceEndDateField = (): UseAdSourceEndDateField => {
    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_END_DATE]
    );
    const dealType = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TYPE]);

    const required = dealType === DEAL_TYPES.GUARANTEE;
    return {
        value: getEndDateValue(value, required),
        required,
    };
};
