import { Select } from "antd";
import { FC } from "react";
import { LabeledValue } from "antd/lib/select";
import { useVendorSelect } from "./useVendorSelect";

interface TypeSelectProps {
    value: number | undefined;
    onChange: (value: number, option: LabeledValue | LabeledValue[]) => void;
}

export const VendorSelect: FC<TypeSelectProps> = ({ onChange, value }) => {
    const { isFetching, options } = useVendorSelect();
    return (
        <Select
            allowClear
            showSearch
            value={value}
            options={options}
            onChange={onChange}
            loading={isFetching}
            optionFilterProp="label"
            style={{ width: "100%" }}
            placeholder="Select mesurement vendor..."
        />
    );
};
