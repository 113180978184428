import { FC, Fragment } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { Alert } from "antd";

export interface ErrorContainerProps {
    error: FetchBaseQueryError | SerializedError | undefined;
}

const ErrorContainer: FC<ErrorContainerProps> = (props) => {
    const { error, children } = props;

    if (error) {
        if ("status" in error) {
            const errMsg = "error" in error ? error.error : JSON.stringify(error.data);
            return <Alert type="error" message={errMsg} />;
        } else {
            return <Alert type="error" message={error.message} />;
        }
    }

    return <Fragment>{children}</Fragment>;
};

export default ErrorContainer;
