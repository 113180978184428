import { notification } from "antd";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useLazyGetWaterfallPacingAsCsvQuery } from "@app/core/services";
import { downloadFile } from "@app/core/utils/downloadFile";
import { useSeatAdSourcesPacingTableFilters } from "./SeatAdSourcesPacingTableFilters/useSeatAdSourcesPacingTableFilters";

interface UseDownloadPacingSummary {
    downloadPacingSummaryAsCsv: () => void;
}

const displayErrorNotificationToast = () =>
    notification.error({
        message: "Failed to download pacing summary",
    });

export const useDownloadPacingSummary = (): UseDownloadPacingSummary => {
    const [getWaterfallPacingAsCsv] = useLazyGetWaterfallPacingAsCsvQuery();
    const { seatWaterfallPacingParams } = useSeatAdSourcesPacingTableFilters();

    const downloadPacingSummaryAsCsv = () => {
        if (seatWaterfallPacingParams !== skipToken) {
            getWaterfallPacingAsCsv(seatWaterfallPacingParams)
                .then((response) => {
                    if (response.isSuccess && response.data) {
                        downloadFile("Waterfall Pacing", "csv", response.data);
                    } else {
                        displayErrorNotificationToast();
                    }
                })
                .catch(() => {
                    displayErrorNotificationToast();
                });
        } else {
            displayErrorNotificationToast();
        }
    };

    return {
        downloadPacingSummaryAsCsv,
    };
};
