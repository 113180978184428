import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { useGetSeatsAccountTypesQuery } from "@app/core/services";

interface UseAccountType {
    options: LabeledValue[];
    isFetching: boolean;
}

export const useAccountType = (): UseAccountType => {
    const { data = [], isFetching } = useGetSeatsAccountTypesQuery();
    const options = useMemo(
        () => (data || []).map((accountType) => ({ value: accountType.id, label: accountType.name })),
        [data]
    );
    return {
        isFetching,
        options,
    };
};
