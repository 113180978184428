import { useGetSupplyQuery } from "@app/core/services/console";
import { useParams } from "react-router-dom";

interface SupplyFloorsPageParams {
    supplyId: string;
}

export const useSupplyById = () => {
    const { supplyId } = useParams<SupplyFloorsPageParams>();
    const { data: supply, isFetching } = useGetSupplyQuery(Number(supplyId));

    return {
        supply,
        isFetching,
    };
};
