import { FC } from "react";
import { Form, Select } from "antd";
import { useUserGroups } from "./useUserGroups";
import { INVENTORY_PERMISSIONS_FORM_NAMES } from "@app/features/inventory/constants";

interface Props {
    width?: string;
    margin?: string | number;
    label?: string;
}

export const UserGroups: FC<Props> = ({ width, label, margin }) => {
    const { userGroups, isLoading, search, handleChangeSearch } = useUserGroups();

    return (
        <Form.Item name={INVENTORY_PERMISSIONS_FORM_NAMES.GROUP} label={label} style={{ width: width, margin }}>
            <Select
                allowClear
                showSearch
                onSearch={handleChangeSearch}
                searchValue={search}
                optionFilterProp="label"
                options={userGroups}
                loading={isLoading}
                placeholder="Choose one"
            />
        </Form.Item>
    );
};
