import { useState, SetStateAction } from "react";

const initialFormData = { email: "", password: "", isPasswordVisible: false };
export type FormDataType = typeof initialFormData;

export interface UseLoginFormData {
    formData: FormDataType;
    setFormData: (formdata: SetStateAction<FormDataType>) => void;
}

export function useLoginFormData(): UseLoginFormData {
    const [formData, setFormData] = useState<FormDataType>(initialFormData);

    return {
        formData,
        setFormData,
    };
}
