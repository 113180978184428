import { FC } from "react";
import { Col, Row, Typography } from "antd";
import { DealListItem } from "@app/core/services";
import { getCPMRateLabel } from "@app/features/deals/helpers";
import { DEAL_TYPE_AUCTION_PRICE_DEAL } from "@app/features/deals/constants";
import DealFloorPrices from "@app/features/deals/DealFloorPrices";
import { Dashes } from "@app/core/components";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/types";
import { getDisplayValue } from "@app/features/deals/DealFloorPrices/DealFloorPrices";
import { useAppSelector } from "@app/core/store";
import { CurrencyConversionModes } from "@app/core/clients/console";
import { useCurrency } from "../../useCurrency";
import { convert } from "@app/features/seatAdSources/helpers";

interface DealHealthTableCpmRateCellProps {
    deal: DealListItem;
    openDrawer: (adSourceId: number, secondaryDetailsView?: SecondaryDetailsView) => void;
}

const DealTableCpmRateCell: FC<DealHealthTableCpmRateCellProps> = ({ deal, openDrawer }) => {
    const { dealCurrencyConversionMode, dealPreferredCurrency } = useAppSelector(
        (state) => state.deals.settings.values
    );

    const { currencyConversions, getCurrencyById } = useCurrency();

    const auctionPriceDeal: boolean = deal.dealType?.id == DEAL_TYPE_AUCTION_PRICE_DEAL.id;

    if (!deal?.rate) {
        return <Dashes />;
    }

    const dealCpmRate =
        dealCurrencyConversionMode === CurrencyConversionModes.ORIGINATING_CURRENCY
            ? deal?.rate
            : convert(deal?.rate, deal.currencyType.id, currencyConversions, dealPreferredCurrency as number);

    const currencyCode =
        dealCurrencyConversionMode === CurrencyConversionModes.ORIGINATING_CURRENCY
            ? deal.currencyType.code
            : (getCurrencyById(dealPreferredCurrency as number)?.code as string);

    return (
        <Row>
            <Col span={24}>
                <Typography.Text>
                    {auctionPriceDeal ? (
                        <DealFloorPrices deal={deal} openDrawer={openDrawer} />
                    ) : (
                        <span style={{ color: "#1890FF" }}>{getDisplayValue(dealCpmRate, currencyCode)}</span>
                    )}
                </Typography.Text>
            </Col>
            <Col span={24}>
                {deal?.rate ? <Typography.Text type="secondary">{getCPMRateLabel(deal)}</Typography.Text> : null}
            </Col>
        </Row>
    );
};

export default DealTableCpmRateCell;
