import { Form, Radio } from "antd";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { useIsFieldVisible } from "./useIsFieldVisible";

export const ExtendTimeoutRadio = () => {
    const isVisible = useIsFieldVisible();
    return isVisible ? (
        <Form.Item
            {...VERTICAL_LAYOUT_FIX}
            name={AD_SOURCE_FIELDS.EXTEND_TIMEOUT_VALUE.name}
            label={AD_SOURCE_FIELDS.EXTEND_TIMEOUT_VALUE.label}
            initialValue={false}
        >
            <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
            </Radio.Group>
        </Form.Item>
    ) : null;
};
