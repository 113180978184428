import { Layout } from "antd";
import { DealDetailsPageHeader } from "./DealDetailsPageHeader";
import { PageLoader } from "@rubicon/antd-components";
import { DealDetailsContent } from "@app/features/deals/DealDetailsPage/DealDetailsContent";
import { useDealAdSourceById } from "./useDealAdSourceById";
import { useGetCurrenciesPrefetch } from "@app/core/services";

export const DealDetailsPage = () => {
    useGetCurrenciesPrefetch();
    const { dealAdSource, isLoading } = useDealAdSourceById();

    if (isLoading || !dealAdSource) {
        return <PageLoader />;
    }

    return (
        <Layout>
            <DealDetailsPageHeader dealAdSource={dealAdSource} />
            <DealDetailsContent dealAdSource={dealAdSource} />
        </Layout>
    );
};
