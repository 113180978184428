import { useEffect, useState } from "react";
import { useAppDispatch } from "@app/core/store";
import { DealFormFieldType } from "@app/features/deals/DealForm/types";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

interface UseDispatchOnBlur {
    onChange: (value: string | number) => void;
    onBlur: (
        action: ActionCreatorWithPayload<{ field: DealFormFieldType; value: string | number | null | undefined }>,
        field: string
    ) => void;
}

export const useDispatchOnBlur = (value: string | number | null): UseDispatchOnBlur => {
    const dispatch = useAppDispatch();
    const [initialValue, setInitialValue] = useState<string | number | null>(value || null);

    const onChange = (value: string | number): void => setInitialValue(value);
    const onBlur = (
        action: ActionCreatorWithPayload<{ field: DealFormFieldType; value: string | number | null | undefined }>,
        field: DealFormFieldType
    ) => dispatch(action({ field, value: initialValue }));

    useEffect(() => {
        setInitialValue(value);
    }, [value]);

    return {
        onChange,
        onBlur,
    };
};
