import { FC } from "react";
import { Space } from "antd";
import { Loading } from "@app/core/components";
import { useSeatAuthContext } from "@app/core/auth";

import { LiveStatsMetrics } from "./LiveStatsMetrics";
import { PerformanceQuickViewMetrics } from "./PerformanceQuickViewMetrics";
import { DashboardFilters } from "./DashboardFilters";

export const DashboardPage: FC = () => {
    const { context, session } = useSeatAuthContext();

    if (!context || !session) {
        return <Loading />;
    }

    return (
        <Space direction="vertical" size="large" style={{ display: "flex" }}>
            <DashboardFilters />
            <LiveStatsMetrics />
            <PerformanceQuickViewMetrics />
        </Space>
    );
};
