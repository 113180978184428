import { Flex, Radio, Tooltip, Typography } from "antd";
import { Operations, TargetingFormKeys } from "../constants";
import { TargetingFormState } from "../reducer";
import { FC } from "react";

interface Props {
    targetingForm: TargetingFormState;
    setTargetingOperation: (formKey: string, value: string) => void;
    formKey: TargetingFormKeys;
    operationReadOnly?: boolean;
}

const getANDOperationColor = (operation: Operations, isDisabled?: boolean) => {
    if (operation === Operations.All) {
        return "white";
    }
    if (isDisabled) {
        return "#BFBFBF";
    }
    return "black";
};

const getANDOperationBackgroundColor = (operation: Operations, isDisabled?: boolean) => {
    if (operation === Operations.All) {
        if (isDisabled) {
            return "#BFBFBF";
        }
        return "#C1715B";
    }
    return isDisabled ? "#F5F5F5" : "white";
};

const getOROperationColor = (operation: Operations, isDisabled?: boolean) => {
    if (operation === Operations.Any) {
        return "white";
    }
    if (isDisabled) {
        return "#BFBFBF";
    }
    return "black";
};

const getOROperationBackgroundColor = (operation: Operations, isDisabled?: boolean) => {
    if (operation === Operations.Any) {
        if (isDisabled) {
            return "#BFBFBF";
        }
        return "#7666F9";
    }
    return isDisabled ? "#F5F5F5" : "white";
};

export const TargetingMode: FC<Props> = ({ setTargetingOperation, targetingForm, formKey, operationReadOnly }) => {
    return (
        <Flex align="center">
            <Typography.Text strong style={{ marginRight: 10 }}>
                Targeting Mode:{" "}
            </Typography.Text>
            <Tooltip
                title={
                    operationReadOnly
                        ? "Ad Source target mode dictates targeting mode for Deals between blocks if you have multiple blocks."
                        : ""
                }
            >
                <Radio.Group
                    disabled={operationReadOnly}
                    value={targetingForm.targetingOperation}
                    onChange={(e) => setTargetingOperation(formKey, e.target.value)}
                >
                    <Radio.Button
                        style={{
                            backgroundColor: getANDOperationBackgroundColor(
                                targetingForm.targetingOperation,
                                operationReadOnly
                            ),
                            color: getANDOperationColor(targetingForm.targetingOperation, operationReadOnly),
                        }}
                        type="solid"
                        value={Operations.All}
                    >
                        AND
                    </Radio.Button>
                    <Radio.Button
                        type="solid"
                        style={{
                            backgroundColor: getOROperationBackgroundColor(
                                targetingForm.targetingOperation,
                                operationReadOnly
                            ),
                            color: getOROperationColor(targetingForm.targetingOperation, operationReadOnly),
                        }}
                        value={Operations.Any}
                    >
                        OR
                    </Radio.Button>
                </Radio.Group>
            </Tooltip>
        </Flex>
    );
};
