import { FC } from "react";
import { Table, Typography } from "antd";
import { getVastErrorOffenders, VastErrors } from "@app/core/services";
import { sortVastErrorOffendersByErrorCount } from "@app/core/services/diagnostics/vastErrors/utils";

enum ColumnKey {
    Dsp = "networkName",
    AdvertiserDomain = "adomain",
    CreativeID = "creativeId",
    ErrorCount = "errorCount",
}

const columns = [
    {
        title: "DSP",
        dataIndex: ColumnKey.Dsp,
        key: ColumnKey.Dsp,
    },
    {
        title: "Advertiser Domain",
        dataIndex: ColumnKey.AdvertiserDomain,
        key: ColumnKey.AdvertiserDomain,
    },
    {
        title: "Creative ID",
        dataIndex: ColumnKey.CreativeID,
        key: ColumnKey.CreativeID,
    },
    {
        title: "Error Count",
        dataIndex: ColumnKey.ErrorCount,
        key: ColumnKey.ErrorCount,
    },
];

interface Props {
    vastErrors: VastErrors[] | undefined;
}

export const SeatAdSourcesTopOffendersTable: FC<Props> = ({ vastErrors = [] }) => {
    const offenders = getVastErrorOffenders(vastErrors);
    const sortedOffenders = sortVastErrorOffendersByErrorCount(offenders);

    return (
        <div data-sdet="seat-ad-sources-top-offenders-table" className="vast-error-table">
            <Typography.Title level={5}>Top Offenders Past 24 Hours</Typography.Title>
            <Table size="small" bordered={true} columns={columns} dataSource={sortedOffenders} pagination={false} />
        </div>
    );
};
