import { Route, Switch } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { AdSourcesDetailsDrawer } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/AdSourcesDetailsDrawer";

export const AdSourceDetailsDrawerDealsWrapper = () => {
    return (
        <>
            <Switch>
                <Route
                    exact
                    path={[
                        ROUTES.SEAT_DEALS_HEALTH_AD_SOURCES_DRAWER,
                        ROUTES.SEAT_DEALS_HEALTH_AD_SOURCES_DRAWER_SECONDARY_VIEW,
                        ROUTES.SEAT_DEAL_DETAILS_AD_SOURCES_DRAWER,
                        ROUTES.SEAT_DEAL_DETAILS_AD_SOURCES_DRAWER_SECONDARY_VIEW,
                        ROUTES.SEAT_DEAL_EDIT_AD_SOURCES_DRAWER,
                        ROUTES.SEAT_DEAL_EDIT_AD_SOURCES_DRAWER_SECONDARY_VIEW,
                        ROUTES.SEAT_DEALS_AD_SOURCES_DRAWER,
                        ROUTES.SEAT_DEALS_AD_SOURCES_DRAWER_SECONDARY_VIEW,
                        ROUTES.SEAT_DEAL_CREATE_TYPE_AD_SOURCES_DRAWER,
                        ROUTES.SEAT_DEAL_CREATE_TYPE_AD_SOURCES_DRAWER_SECONDARY_VIEW,
                        ROUTES.SEAT_DEAL_COPY_AD_SOURCES_DRAWER,
                        ROUTES.SEAT_DEAL_COPY_AD_SOURCES_DRAWER_SECONDARY_VIEW,
                    ]}
                >
                    <AdSourcesDetailsDrawer />
                </Route>
            </Switch>
        </>
    );
};
