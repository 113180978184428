import { useMemo } from "react";
import { CheckboxOptionType } from "antd/lib/checkbox/Group";
import { useGetSupplyPaidTypesQuery } from "@app/core/services";

interface UsePaidType {
    options: CheckboxOptionType[];
}

export const usePaidType = (): UsePaidType => {
    const { data } = useGetSupplyPaidTypesQuery();

    const options: CheckboxOptionType[] = useMemo(
        () => (data || []).map(({ id, name }) => ({ value: id, label: name })),
        [data]
    );
    return {
        options,
    };
};
