import { FC } from "react";
import { css } from "@emotion/css";
import { Snapshot } from "./Snapshot";
import { Col, Form, Row, Select, Typography } from "antd";
import { LabeledValue } from "antd/lib/select";
import { TimingStatsChartType } from "../useTimingStats";
import { LIMITS_SELECTED_SNAPSHOTS, useSnapshots } from "./useSnapshots";
import { gray7 } from "@rubicon/antd-components";

const classListTypography = css`
    color: ${gray7};
`;

const classListSelectedPlace = css`
    position: absolute;
    z-index: 100;
    width: 90%;
    height: 30px;
    padding: 5px 11px;
    pointer-events: none;
`;

const classListSelect = css`
    width: 100%;

    .ant-select-selection-overflow {
        height: 30px;
        overflow: hidden;
    }
    .ant-select-selection-overflow-item {
        display: none;
    }
`;

const { Option } = Select;

interface Props {
    id: number;
    chartType: TimingStatsChartType;
    region?: LabeledValue;
    multiplicityId: number;
}
export const Snapshots: FC<Props> = ({ id, ...chartSettings }) => {
    const { isLoading, options, handleChange, handleDeselect, values } = useSnapshots(id);

    return (
        <Row gutter={[12, 12]}>
            <Col span={24}>
                <Typography.Text className={classListTypography}>Compare up to 6 snapshots at a time.</Typography.Text>
            </Col>
            <Col span={24}>
                <Form>
                    <Form.Item label="Show Snapshots">
                        <div
                            className={css`
                                width: 20rem;
                                position: relative;
                            `}
                        >
                            {values.length ? (
                                <div className={classListSelectedPlace}>{values.length} selected</div>
                            ) : null}
                            <Select
                                showArrow
                                allowClear
                                labelInValue
                                value={values}
                                mode="multiple"
                                showSearch={false}
                                loading={isLoading}
                                onChange={handleChange}
                                className={classListSelect}
                            >
                                {options.map(({ label, value }) => {
                                    const disabled =
                                        values.length === LIMITS_SELECTED_SNAPSHOTS &&
                                        values.findIndex((item) => item.value === value) === -1;
                                    return (
                                        <Option disabled={disabled} key={value} value={value}>
                                            {label}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </Form.Item>
                </Form>
            </Col>
            {values.map((item) => (
                <Col span={24} key={item.value}>
                    <Snapshot {...item} {...chartSettings} handleDeselect={handleDeselect} />
                </Col>
            ))}
        </Row>
    );
};
