import { FC } from "react";
import { Card, Row, Col } from "antd";
import { Seat } from "@app/core/services";
import { INVENTORY_CARD_BODY_STYLE } from "@app/features/inventory/constants";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { SeatLabels } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/SeatDetailsFormFields";

interface LabelsInternalOnlyProps {
    edit?: boolean;
    seat: Seat;
}

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 };

export const LabelsInternalOnly: FC<LabelsInternalOnlyProps> = ({ seat, edit = false }) => {
    return (
        <Card title="Internal Only" type="inner" bodyStyle={INVENTORY_CARD_BODY_STYLE}>
            <Row gutter={16}>
                <Col {...COL_SIZES}>
                    <SeatLabels {...INVENTORY_FORM_FIELDS.INTERNAL_LABELS} seat={seat} edit={edit} />
                </Col>
            </Row>
        </Card>
    );
};
