import { sectionHeaderCollapseStyle } from "@app/features/deals/DealForm";
import { AD_SOURCE_SECTIONS, AD_SOURCE_SECTIONS_NAME } from "@app/features/seatAdSources/constants";
import { DefinitionSidePanel } from "@app/features/seatAdSources/SeatAdSourcesForm/DefinitionSidePanel";
import { AdSourceFormsContext, DealObjectForm } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { Card, Form, Collapse } from "antd";
import { FC, useContext } from "react";
import { DealObjectFormControls } from "./DealObjectFormControls";
import { DealObjectFormHeader } from "./DealObjectFormHeader";
import { DealTermsSection } from "./DealTermsSection";
import { EntityType } from "./EntityType";
import { PricingSection } from "./PricingSection";
import { SelectOrCreateDeal } from "./Fields/SelectOrCreateDeal";
import { useDealObjectForm } from "./useDealObjectForm";
import { ItemType } from "rc-collapse/es/interface";
import { DealSyncWarningAlert } from "@app/features/seatAdSources/SeatAdSourcesForm/sync";
import { useSyncRelatedFormData } from "@app/features/seatAdSources/SeatAdSourcesForm/sync/useSyncRelatedFormData";

export const DealObjectSection: FC = () => {
    const {
        handleSubmit,
        handleCancel,
        isFormControlsVisible,
        isFormVisible,
        isPricingSectionVisible,
        dealObjectFormInitials,
        formTitle,
        isLoading,
        dealOriginId,
    } = useDealObjectForm();
    const { forms } = useContext(AdSourceFormsContext);
    const { dealObjectForm } = forms;
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const { adSourceFields, adSourceId } = useSyncRelatedFormData();

    return (
        <Form<DealObjectForm>
            name="dealObjectForm"
            layout="vertical"
            form={dealObjectForm}
            initialValues={{ dealUnderEdit: dealObjectFormInitials }}
        >
            <Card bordered={false}>
                <DealSyncWarningAlert
                    adSourceId={adSourceId}
                    dealId={dealOriginId}
                    adSourceTypeId={adSourceFields.type?.id}
                    adSourceFloorTypeId={adSourceFields.floorType?.id}
                />
                <SelectOrCreateDeal isFormVisible={Boolean(isFormVisible)} />
                {isFormVisible && (
                    <>
                        <DealObjectFormHeader formTitle={formTitle} />
                        <EntityType />
                        <Collapse
                            defaultActiveKey={["terms", "pricing"]}
                            style={{ border: dealObjectMode === "edit" ? "1px solid #865aec" : "1px solid #d9d9d9" }}
                            items={
                                [
                                    {
                                        key: "terms",
                                        label: "Deal Terms",
                                        extra: (
                                            <DefinitionSidePanel
                                                panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.DEAL_TERMS]}
                                                section={AD_SOURCE_SECTIONS.DEAL_TERMS}
                                                sectionSelector="#dealObjectForm"
                                            />
                                        ),
                                        children: <DealTermsSection />,
                                    },
                                    isPricingSectionVisible && {
                                        key: "pricing",
                                        style: sectionHeaderCollapseStyle,
                                        label: "Pricing",
                                        extra: (
                                            <DefinitionSidePanel
                                                panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.PRICING]}
                                                section={AD_SOURCE_SECTIONS.PRICING}
                                                sectionSelector="#dealObjectForm"
                                            />
                                        ),
                                        children: <PricingSection />,
                                    },
                                ].filter(Boolean) as ItemType[]
                            }
                        />
                        <DealObjectFormControls
                            visible={isFormControlsVisible}
                            loading={isLoading}
                            cancel={handleCancel}
                            handleSubmit={handleSubmit}
                        />
                    </>
                )}
            </Card>
        </Form>
    );
};
