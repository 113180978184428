import { consoleApi, OZTAM_DEMO_TAG } from "./console";

export interface OztamDemo {
    id: number;
    name: string;
    sort: number;
    entityType: "OztamDemographic";
    creationDate: string;
    updateDate: string;
}

export const oztamDemoApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getOztamDemo: builder.query<OztamDemo[], void>({
            query: () => "oztamDemographics",
            providesTags: [OZTAM_DEMO_TAG],
        }),
    }),
});

export const { useGetOztamDemoQuery } = oztamDemoApi;
