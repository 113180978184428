import { diagnosticsApi, TAG_PARAMS } from "./diagnostics";

export type ParamSamplesType = Record<string, Record<string, string | number>>;

export interface TagParamsProperties {
    ntime: number;
    otime: number;
    paramCounts: Record<string, string | number>;
    paramSamples: ParamSamplesType;
    sampleCount: number;
}

export type AdUnitPathType = "seats" | "publishers" | "brands" | "supply" | "adCodes";

export interface TagRequestParams {
    entityId: number | string;
    type: AdUnitPathType;
}

export const tagParamsApi = diagnosticsApi.injectEndpoints({
    endpoints: (builder) => ({
        getTagParams: builder.query<TagParamsProperties, TagRequestParams>({
            query: ({ type, entityId }) => `${type}/${entityId}/urls`,
            providesTags: (_, __, { type, entityId }) => [{ type: TAG_PARAMS, id: `${type}-${entityId}` }],
        }),
    }),
});

export const { useGetTagParamsQuery } = tagParamsApi;
