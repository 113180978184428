import { LoadingState } from "./LoadableComponent";

export const reduceLoadingStates = (
    loadingStateA: LoadingState,
    loadingStateB: LoadingState,
    ...additionalLoadingStates: LoadingState[]
): LoadingState => {
    return [loadingStateB, ...additionalLoadingStates].reduce((a, b) => {
        if (a === "loading" || b === "loading") {
            return "loading";
        } else if (a === "loadedNoData" && b === "loadedNoData") {
            return "loadedNoData";
        }
        return "loaded";
    }, loadingStateA);
};
