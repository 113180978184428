import { useNotificationError } from "@app/core/components/hooks";
import { useMemo } from "react";
import { LoadOnScrollSelect, TagRender } from "@app/core/components";
import { Select } from "antd";
import { useLoadOnScrollField } from "./useLoadOnScrollField";
import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { QueryDefinition } from "@reduxjs/toolkit/query";
import { LabeledValue } from "antd/es/select";

interface Props<T = unknown> {
    sdet: string;
    disabled?: boolean;
    mode?: "multiple" | "tags" | undefined;
    value?: number[];
    labelInValue?: boolean;
    onChange?: () => void;
    placeholder?: string;
    optionMapper: (item: T) => LabeledValue;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useGetOptionsQuery: UseQuery<QueryDefinition<any, any, any, any>>;
    extraParams?: Record<string, number | string | boolean>;
}
export const GenericLoadOnScrollField = <T,>({
    value,
    placeholder = "Select…",
    optionMapper,
    useGetOptionsQuery,
    extraParams,
    ...restProps
}: Props<T>) => {
    const { options, isLoading, onSearch, hasMore, loadMore, error } = useLoadOnScrollField(
        useGetOptionsQuery,
        extraParams
    );
    useNotificationError(error);

    const labelValueOptions = useMemo(() => options.map(optionMapper), [options, optionMapper]);

    const commonProps = {
        filterOption: false,
        hasMore,
        loading: isLoading,
        loadMore,
        onSearch,
        options: labelValueOptions,
        popupMatchSelectWidth: false,
        showSearch: true,
        tagRender: TagRender,
        value,
        placeholder,
        ...restProps,
    };

    if (loadMore) {
        return <LoadOnScrollSelect {...commonProps} hasMore={Boolean(hasMore)} loadMore={loadMore} />;
    }

    return <Select {...commonProps} />;
};
