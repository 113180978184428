import { AdSource } from "@app/core/services";
import { AdSourceTypeIds } from "../../constants";
import { parseAdSourceFromApiToForm } from "./parseAdSourceFromApiToForm";
import { DEFAULT_TIME_ZONE } from "@app/core/components/constants";
import moment from "moment-timezone";

export const parseAdSourceForCopy = (adSourceOrigin: AdSource) => {
    const adSourceTypeId = adSourceOrigin.type.id;
    const timeZone = adSourceOrigin.timeZone || DEFAULT_TIME_ZONE;
    const isTagType =
        adSourceTypeId === AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED ||
        adSourceTypeId === AdSourceTypeIds.FALLBACK_TAG ||
        adSourceTypeId === AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED ||
        adSourceTypeId === AdSourceTypeIds.SERVER_SIDE_TAG_NON_GUARANTEED;
    const isMarketplaceType = adSourceTypeId === AdSourceTypeIds.MARKETPLACE;
    const isPrebidAdSourceType = adSourceTypeId === AdSourceTypeIds.PRE_BID_AD_SOURCE;
    const shouldClearMarketplaceInfo = [
        AdSourceTypeIds.UNRESERVED_FIXED_RATE,
        AdSourceTypeIds.AUCTION_PRICE,
        AdSourceTypeIds.AUTOMATED_GUARANTEED,
        AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED,
    ].includes(adSourceTypeId);

    // set start date to now if the ad source being copied has a start date that has already been reached
    let bookingStartDate = moment(adSourceOrigin.bookingStartDate).tz(timeZone.code);
    if (moment().isAfter(moment(bookingStartDate).tz(timeZone.code))) {
        bookingStartDate = moment().tz(timeZone.code);
    }

    // set end date to null if the ad source being copied has an end date that has already been reached
    let bookingEndDate = adSourceOrigin.bookingEndDate ? moment(adSourceOrigin.bookingEndDate).tz(timeZone.code) : null;
    if (bookingEndDate && moment().isAfter(moment(bookingEndDate).tz(timeZone.code))) {
        bookingEndDate = null;
    }

    return {
        ...parseAdSourceFromApiToForm(adSourceOrigin),
        name: `${adSourceOrigin.name} - COPY`,
        tagUrl: isTagType ? undefined : adSourceOrigin.tagUrl,
        pbsConfigs: isPrebidAdSourceType ? [] : adSourceOrigin.pbsConfigs,
        associatedMarketplace: isMarketplaceType ? undefined : adSourceOrigin.associatedMarketplace,
        allowedExtendedIdTransparencies:
            adSourceOrigin.allowedExtendedIdTransparencies?.map((idTransparency) => {
                return {
                    extendedId: idTransparency.extendedId,
                    network: idTransparency.network,
                };
            }) || [],
        marketplaceInfoList: shouldClearMarketplaceInfo
            ? []
            : adSourceOrigin.marketplaceInfoList?.map((marketplaceInfo) => ({
                  ...marketplaceInfo,
                  id: undefined,
                  dealFreqCappings: marketplaceInfo.dealFreqCappings?.map((capping) => ({
                      ...capping,
                      id: undefined,
                  })),
              })),
        bookingStartDate,
        bookingEndDate,
    };
};
