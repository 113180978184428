import { Col, Row } from "antd";
import { FC } from "react";
import { EndDatePicker } from "../EndDatePicker";
import { StartDatePicker } from "../StartDatePicker";
import { DurationView } from "./DurationView";
import { useDuration } from "./useDuration";

export const Duration: FC = () => {
    const { isVisible, isReadonly } = useDuration();
    if (!isVisible) {
        return null;
    }
    if (isReadonly) {
        return (
            <Col sm={12}>
                <DurationView />
            </Col>
        );
    }
    return (
        <Col sm={12}>
            <Row gutter={[8, 0]}>
                <Col sm={12}>
                    <StartDatePicker />
                </Col>
                <Col sm={12}>
                    <EndDatePicker />
                </Col>
            </Row>
        </Col>
    );
};
