import { copy } from "@rubicon/utils/dist/clipboard";
import { notification } from "antd";

interface UseClipBoardButton {
    handleCopy: (text: string, type?: string) => void;
}

export const useClipBoardButton = (): UseClipBoardButton => {
    const handleCopy = async (text: string, type?: string) => {
        if ("clipboard" in navigator) {
            try {
                await navigator.clipboard.writeText(text);
                notification.success({ message: `${type} is copied` });
            } catch (err) {
                notification.error({ message: `${type} is not copied because of ${err}` });
            }
        } else {
            copy(text);
        }
    };

    return { handleCopy };
};
