import { Form } from "antd";
import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { useGetAdUnitsMimesQuery } from "@app/core/services";
import { RuleObject, StoreValue } from "rc-field-form/lib/interface";
import { API_TYPES, MIME_TYPES, SUPPORTED_APIS } from "@app/features/inventory/HierarchyForms/constants";

const { useWatch } = Form;

interface UseAdUnitMimes {
    isFetching: boolean;
    options: LabeledValue[];
    validator: (rule: RuleObject, value: StoreValue) => Promise<void>;
}

export const useAdUnitMimes = (): UseAdUnitMimes => {
    const supportedAPIs = useWatch(SUPPORTED_APIS);
    const { data, isFetching } = useGetAdUnitsMimesQuery();
    const options: LabeledValue[] = useMemo(
        () => (data || []).map((mine) => ({ value: mine.id, label: mine.name })),
        [data]
    );
    const validator = (_: RuleObject, value: StoreValue): Promise<void> => {
        if (!value?.length) return Promise.reject(new Error("At least one mime type is required"));

        const hasJs: boolean = value.some(({ value }): boolean => value === MIME_TYPES.JAVASCRIPT.value);
        const hasFlash: boolean = value.some(
            ({ value }): boolean => value === MIME_TYPES.FLASH.value || value === MIME_TYPES.X_FLV.value
        );
        const hasVpaid2: boolean = supportedAPIs?.some(({ value }): boolean => value === API_TYPES.VPAID_2.value);
        const hasVpaid1: boolean = supportedAPIs?.some(({ value }): boolean => value === API_TYPES.VPAID_1.value);

        if (hasVpaid1 && !hasFlash)
            return Promise.reject(
                new Error("VPAID 1.0 requires application/x-shockwave-flash or application/x-flv to be selected")
            );

        if (hasVpaid2 && !hasFlash && !hasJs)
            return Promise.reject(
                new Error(
                    "VPAID 2.0 requires either application/javascript or application/x-shockwave-flash to be selected"
                )
            );

        if (hasJs && !hasVpaid2)
            return Promise.reject(new Error("application/javascript requires VPAID 2.0 to be selected"));

        if (hasFlash && !hasVpaid1 && !hasVpaid2)
            return Promise.reject(
                new Error("application/x-shockwave-flash requires VPAID 1.0 or VPAID 2.0 to be selected")
            );

        return Promise.resolve();
    };

    return {
        isFetching,
        validator,
        options,
    };
};
