import { SyncedFieldKey } from "./types";

type EligibilityType = "auction" | "auctionOverrideOrFallback" | "fixed" | "programmatic";

export const EligibleSyncedFieldKeysByType: Record<EligibilityType, Readonly<Set<SyncedFieldKey>>> = {
    auction: new Set<SyncedFieldKey>(["status", "startDate", "endDate", "currency", "timeZone"]),
    auctionOverrideOrFallback: new Set<SyncedFieldKey>([
        "status",
        "startDate",
        "endDate",
        "cpmRate",
        "currency",
        "timeZone",
    ]),
    fixed: new Set<SyncedFieldKey>(["status", "startDate", "endDate", "cpmRate", "currency", "timeZone"]),
    programmatic: new Set<SyncedFieldKey>(["status"]),
} as const;

export const SyncedFieldLabelsByKey: Record<SyncedFieldKey, string> = {
    startDate: "Start Date",
    endDate: "End Date",
    timeZone: "Time Zone",
    cpmRate: "CPM",
    status: "Status",
    currency: "Currency Code",
    impressions: "Impressions",
} as const;
