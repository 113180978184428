import { FC } from "react";
import { Layout } from "antd";
import { Loading } from "@app/core/components";
import { useSeatAuthContext } from "@app/core/auth";
import { DiagnosticsPage } from "./DiagnosticsPage";
import conf from "@app/core/conf";

export const DiagnosticsLadleDiagnosticsPage: FC = () => {
    const { context, session } = useSeatAuthContext();

    if (!context || !session) {
        return (
            <Layout.Content>
                <Loading />
            </Layout.Content>
        );
    }

    return (
        <DiagnosticsPage
            src={() => `${conf.mctvConsoleRoot}/ssp/admin/diagnostics/ladle-results/user/${session.user.emailAddress}`}
        />
    );
};
