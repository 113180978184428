import { FC } from "react";
import { Form, Radio } from "antd";
import { SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useSrcRelationship } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/SupplyDetailsSection/Fields/SrcRelationship/useSrcRelationship";

export const SrcRelationship: FC = () => {
    const { options } = useSrcRelationship();
    return (
        <Form.Item
            name={SUPPLY_FORM_FIELDS.SOURCE_RELATIONSHIP.name}
            label={SUPPLY_FORM_FIELDS.SOURCE_RELATIONSHIP.label}
        >
            <Radio.Group size="large" options={options} />
        </Form.Item>
    );
};
