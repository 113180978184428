import { useNotificationError } from "@app/core/components/hooks";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { AdvertiserDomainRaw, useGetAdvertiserDomainQuery } from "@app/core/services";
import { CONTROLS_ROUTES } from "@app/features/controls/constants";
import { useHistory } from "react-router-dom";

interface UseAdvertiserDomainDetailsPage {
    name: string;
    isLoading: boolean;
    handleGoBack: () => void;
    postDelete: () => void;
    data: AdvertiserDomainRaw | undefined;
}

export const useAdvertiserDomainDetailsPage = (seatId: string, id: string): UseAdvertiserDomainDetailsPage => {
    const history = useHistory();
    const { data, isFetching, error } = useGetAdvertiserDomainQuery({ id });

    useNotificationError(error);

    const handleGoBack = () => {
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.BRAND_SAFETY));
    };

    const postDelete = () => {
        history.replace(ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.BRAND_SAFETY));
    };

    return {
        name: data?.name as string,
        isLoading: isFetching,
        handleGoBack,
        postDelete,
        data,
    };
};
