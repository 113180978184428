import { Typography } from "antd";
import { FC } from "react";

interface Props {
    mode: "deal" | "adSource";
    type: "dmp" | "audience";
}

export const ModifySegmentsMessage: FC<Props> = ({ mode, type }) => {
    return (
        <Typography.Text style={{ display: "block", paddingBottom: "4px" }}>
            To modify {type === "dmp" ? "DMP Segment" : "Audience"} targeting, please go to the{" "}
            <strong>{mode === "deal" ? "Deal-Level Targeting" : "Targeting"}</strong> section and edit{" "}
            <strong>{type === "dmp" ? "DMP Segment" : "Audiences"}</strong>
        </Typography.Text>
    );
};
