import { ChangeEvent, FC, useState } from "react";
import { Button, Col, Form, Input, Row, Space, Typography } from "antd";
import {
    BulkOperationEntityType,
    useLazyGetSeatAdSourcesQuery,
    useLazyGetSeatCtvNextAdUnitsQuery,
} from "@app/core/services";
import {
    BULK_OPERATION_FORM_ITEMS,
    OPERATION_SELECTED_ENTITIES_TAB,
} from "@app/features/controls/bulkOperations/constants";
import { useNotificationError } from "@app/core/components/hooks";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setUploadedAdSources, setUploadedAdUnits } from "@app/features/controls/bulkOperations/reducer";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { errorColor, successColor } from "@rubicon/antd-components";

interface Props {
    setCurrentTab: (tab: string) => void;
}

const getGroupedIdsFromText = (inputValue: string) => {
    const { allIds, numericIds } = inputValue.split("\n").reduce(
        (acc: { allIds: string[]; numericIds: string[] }, id) => {
            const trimmedId = id.trim();
            if (trimmedId) {
                /^\d+$/.test(trimmedId) && acc.numericIds.push(trimmedId);
                acc.allIds.push(trimmedId);
            }
            return acc;
        },
        { allIds: [], numericIds: [] }
    );

    return { allIds, numericIds };
};

const useBulkUploadTextarea = () => {
    const dispatch = useDispatch();
    const { seatId } = useParams<{ seatId: string }>();
    const [inputValue, setInputValue] = useState("");
    const [validInvalidIds, setValidInvalidIds] = useState<{ validIds: number[]; invalidIds: string[] }>({
        validIds: [],
        invalidIds: [],
    });

    const handleSubmitSuccess = (validIds: number[], allIds: string[], setCurrentTab: (tab: string) => void) => {
        if (validIds.length !== allIds.length) {
            const invalidIds = allIds.filter((id) => !validIds.includes(Number(id)));
            setValidInvalidIds({ validIds, invalidIds });
            setInputValue(invalidIds.join("\n"));
        } else {
            handleResetTextarea();
            setCurrentTab(OPERATION_SELECTED_ENTITIES_TAB);
        }
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValidInvalidIds({ validIds: [], invalidIds: [] });
        setInputValue(e.target.value);
    };

    const handleResetTextarea = () => {
        setValidInvalidIds({ validIds: [], invalidIds: [] });
        setInputValue("");
    };

    const invalidLen = validInvalidIds.invalidIds.length;
    const validLen = validInvalidIds.validIds.length;

    return {
        dispatch,
        handleInputChange,
        handleResetTextarea,
        handleSubmitSuccess,
        inputValue,
        invalidLen,
        seatId,
        setValidInvalidIds,
        validLen,
    };
};

const GenericOperationEntitiesBulkUpload = ({
    entityLabel,
    handleInputChange,
    handleResetTextarea,
    handleSubmit,
    inputValue,
    invalidLen,
    isFetching,
    validLen,
}) => (
    <Space direction="vertical" size="small" style={{ display: "flex" }}>
        <Row gutter={[12, 12]}>
            <Col xs={24} md={12}>
                <Input.TextArea
                    data-sdet="bulk-upload-input"
                    status={invalidLen ? "error" : undefined}
                    style={{ color: invalidLen ? errorColor : undefined }}
                    autoSize={{ minRows: 8, maxRows: 20 }}
                    onChange={handleInputChange}
                    placeholder={`Enter ${entityLabel} IDs one per line`}
                    value={inputValue}
                />
            </Col>
            {invalidLen > 0 && (
                <Col xs={24} md={12} lg={8}>
                    <Space direction="vertical">
                        <Space>
                            <CheckCircleFilled style={{ color: successColor }} />
                            <Typography.Text data-sdet="bulk-upload-success-info">
                                {`${validLen} ${entityLabel.toLowerCase()}${
                                    validLen === 1 ? " has" : "s have"
                                } been added`}
                            </Typography.Text>
                        </Space>
                        <Space>
                            <CloseCircleFilled style={{ color: errorColor }} />
                            <Typography.Text data-sdet="bulk-upload-error-info">
                                {`${invalidLen} ${invalidLen === 1 ? "is" : "are"} invalid highlighted in red`}
                            </Typography.Text>
                        </Space>
                    </Space>
                </Col>
            )}
        </Row>
        <Space>
            <Button data-sdet="bulk-upload-add-button" loading={isFetching} type="primary" onClick={handleSubmit}>
                Add
            </Button>
            {invalidLen > 0 && (
                <Button data-sdet="bulk-upload-remove-ids-button" onClick={handleResetTextarea}>
                    Remove invalid IDs
                </Button>
            )}
        </Space>
    </Space>
);

const AdSourceOperationEntitiesBulkUpload: FC<Props> = ({ setCurrentTab }) => {
    const {
        dispatch,
        handleInputChange,
        handleResetTextarea,
        handleSubmitSuccess,
        inputValue,
        seatId,
        setValidInvalidIds,
        ...restProps
    } = useBulkUploadTextarea();

    const [triggerGetAdSources, { isFetching, error }] = useLazyGetSeatAdSourcesQuery();
    useNotificationError(error);

    const handleSubmit = () => {
        const { allIds, numericIds } = getGroupedIdsFromText(inputValue);

        if (!numericIds.length) {
            setValidInvalidIds({ validIds: [], invalidIds: allIds });
            return;
        }

        triggerGetAdSources({ seatId: Number(seatId), adSourceIds: numericIds })
            .unwrap()
            .then((response) => {
                const validIds = response.adSources.map(({ id }) => id);
                dispatch(setUploadedAdSources({ adSources: response.adSources, ids: validIds }));

                handleSubmitSuccess(validIds, allIds, setCurrentTab);
            });
    };

    return (
        <GenericOperationEntitiesBulkUpload
            entityLabel="Ad Source"
            inputValue={inputValue}
            isFetching={isFetching}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            handleResetTextarea={handleResetTextarea}
            {...restProps}
        />
    );
};

const AdUnitOperationEntitiesBulkUpload: FC<Props> = ({ setCurrentTab }) => {
    const {
        dispatch,
        handleInputChange,
        handleResetTextarea,
        handleSubmitSuccess,
        inputValue,
        seatId,
        setValidInvalidIds,
        ...restProps
    } = useBulkUploadTextarea();

    const [triggerGetAdUnit, { isFetching, error }] = useLazyGetSeatCtvNextAdUnitsQuery();
    useNotificationError(error);

    const handleSubmit = () => {
        const { allIds, numericIds } = getGroupedIdsFromText(inputValue);

        if (!numericIds.length) {
            setValidInvalidIds({ validIds: [], invalidIds: allIds });
            return;
        }

        triggerGetAdUnit({ seatId: Number(seatId), adUnitIds: numericIds })
            .unwrap()
            .then((response) => {
                const validIds = response.adUnits.map(({ id }) => id);
                dispatch(setUploadedAdUnits({ adUnits: response.adUnits, ids: validIds }));

                handleSubmitSuccess(validIds, allIds, setCurrentTab);
            });
    };

    return (
        <GenericOperationEntitiesBulkUpload
            entityLabel="Ad Unit"
            inputValue={inputValue}
            handleInputChange={handleInputChange}
            isFetching={isFetching}
            handleSubmit={handleSubmit}
            handleResetTextarea={handleResetTextarea}
            {...restProps}
        />
    );
};

export const OperationEntitiesBulkUpload: FC<Props> = (props) => {
    const entityType = Form.useWatch(BULK_OPERATION_FORM_ITEMS.ENTITY_TYPE.name);

    switch (entityType) {
        case BulkOperationEntityType.AdSource:
            return <AdSourceOperationEntitiesBulkUpload {...props} />;
        case BulkOperationEntityType.AdUnit:
            return <AdUnitOperationEntitiesBulkUpload {...props} />;
        default:
            return null;
    }
};
