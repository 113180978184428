import { FC } from "react";
import { Typography } from "antd";
import { brandAquamarine, brandRuby } from "@rubicon/antd-components";
import { Conditions, Operations } from "../constants";

interface Props {
    category: string;
    condition: Conditions;
    operation?: Operations;
}

export const getConditionColor = (condition: Conditions) => {
    if (condition === "excludes") {
        return brandRuby;
    }
    return brandAquamarine;
};

export const ReadOnlyCategoryHeader: FC<Props> = ({ category, condition, operation }) => {
    return (
        <Typography.Text>
            <Typography.Text style={{ fontWeight: 500 }}>{category}</Typography.Text>{" "}
            <Typography.Text italic style={{ color: getConditionColor(condition) }}>
                {condition}
            </Typography.Text>{" "}
            {operation && <Typography.Text italic>{operation} of</Typography.Text>}
        </Typography.Text>
    );
};
