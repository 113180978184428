import { Brand, SupplySeat, useGetBrandByIdQuery, useGetSupplySeatQuery } from "@app/core/services";
import { useInventoryDetailsDrawerUrlId } from "@app/features/inventory/DetailsDrawer/useInventoryDetailsDrawerUrlId";
import { useParams } from "react-router-dom";

interface UseGeneral
    extends Pick<Brand, "name" | "code" | "description" | "updateDate" | "creationDate">,
        Partial<Pick<SupplySeat, "lastIndexed">> {}

export const useGeneral = (): UseGeneral => {
    const { seatId } = useParams<{ id: string; seatId: string }>();
    const unitId = useInventoryDetailsDrawerUrlId();
    const { data } = useGetBrandByIdQuery(Number(unitId));
    const { name, code, description, updateDate, creationDate } = data as Brand;
    const { data: supplySeat } = useGetSupplySeatQuery(Number(seatId));

    return {
        code,
        creationDate,
        updateDate,
        description,
        name,
        lastIndexed: supplySeat?.lastIndexed,
    };
};
