import { Button, Card, Col, Form, Layout, Row, Typography } from "antd";
import { FC } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useGetSeatContactQuery } from "@app/core/services";
import { useNotificationError } from "@app/core/components/hooks";
import { PageLoader } from "@rubicon/antd-components";
import {
    SEAT_CONTACT_FIRSTNAME_INPUT_SDET,
    SEAT_CONTACT_LASTNAME_INPUT_SDET,
    SEAT_CONTACT_EMAIL_INPUT_SDET,
    SEAT_CONTACT_TYPE_SDET,
    SEAT_CONTACT_PHONE_INPUT_SDET,
    SEAT_CONTACT_ADDRESS1_INPUT_SDET,
    SEAT_CONTACT_ADDRESS2_INPUT_SDET,
    SEAT_CONTACT_CITY_INPUT_SDET,
    SEAT_CONTACT_STATE_INPUT_SDET,
    SEAT_CONTACT_POSTAL_CODE_INPUT_SDET,
    SEAT_CONTACT_COUNTRY_SDET,
    SEAT_CONTACTS_EDIT_BTN_SDET,
} from "./SeatContactsForm/constants";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { NONE } from "@app/core/components/constants";
import { SeatContactsDeleteModal } from "./SeatContactsDeleteModal/SeatContactsDeleteModal";
import { PageHeader } from "@ant-design/pro-layout";

export const SeatContactsDetailsPage: FC = () => {
    const { contactId, seatId } = useParams<{ contactId: string; seatId: string }>();
    const { data: contact, isLoading, error } = useGetSeatContactQuery(contactId);
    const history = useHistory();

    useNotificationError(error);
    if (isLoading) return <PageLoader />;

    return (
        <Layout>
            <PageHeader
                title={contact?.name}
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, "seat-contacts"))}
                extra={
                    <>
                        <Link
                            data-sdet={SEAT_CONTACTS_EDIT_BTN_SDET}
                            to={ROUTE_FORMATTERS.SEAT_CONTROLS_SEAT_CONTACTS_EDIT(seatId, contactId)}
                        >
                            <Button type="primary">Edit</Button>
                        </Link>
                        {contact && <SeatContactsDeleteModal contact={contact} />}
                    </>
                }
            />
            <Layout.Content>
                <Form layout="vertical" wrapperCol={{ xs: 24, md: 12 }}>
                    <Layout.Content>
                        <Card bordered={false}>
                            <Row>
                                <Col span={8}>
                                    <Form.Item label="First Name" name="firstName">
                                        <Typography.Text data-sdet={SEAT_CONTACT_FIRSTNAME_INPUT_SDET}>
                                            {contact?.firstName}
                                        </Typography.Text>
                                    </Form.Item>

                                    <Form.Item label="Email" name="emailAddress">
                                        <Typography.Text data-sdet={SEAT_CONTACT_EMAIL_INPUT_SDET}>
                                            {contact?.emailAddress}
                                        </Typography.Text>
                                    </Form.Item>

                                    <Form.Item label="Phone Number" name="phone">
                                        <Typography.Text data-sdet={SEAT_CONTACT_PHONE_INPUT_SDET}>
                                            {contact?.phone || NONE}
                                        </Typography.Text>
                                    </Form.Item>

                                    <Form.Item label="Address 1" name="address1">
                                        <Typography.Text data-sdet={SEAT_CONTACT_ADDRESS1_INPUT_SDET}>
                                            {contact?.address1 || NONE}
                                        </Typography.Text>
                                    </Form.Item>
                                    <Form.Item label="City" name="city">
                                        <Typography.Text data-sdet={SEAT_CONTACT_CITY_INPUT_SDET}>
                                            {contact?.city || NONE}
                                        </Typography.Text>
                                    </Form.Item>

                                    <Form.Item label="Postal Code" name="postalCode">
                                        <Typography.Text data-sdet={SEAT_CONTACT_POSTAL_CODE_INPUT_SDET}>
                                            {contact?.postalCode || NONE}
                                        </Typography.Text>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Last Name" name="lastName">
                                        <Typography.Text data-sdet={SEAT_CONTACT_LASTNAME_INPUT_SDET}>
                                            {contact?.lastName}
                                        </Typography.Text>
                                    </Form.Item>
                                    <Form.Item label="Contact Type" name="type">
                                        <Typography.Text data-sdet={SEAT_CONTACT_TYPE_SDET}>
                                            {contact?.type.name}
                                        </Typography.Text>
                                    </Form.Item>
                                    <Form.Item label={"\u00A0"}>
                                        <Typography.Text />
                                    </Form.Item>
                                    <Form.Item label="Address 2" name="address2">
                                        <Typography.Text data-sdet={SEAT_CONTACT_ADDRESS2_INPUT_SDET}>
                                            {contact?.address2 || NONE}
                                        </Typography.Text>
                                    </Form.Item>
                                    <Form.Item label="State" name="state">
                                        <Typography.Text data-sdet={SEAT_CONTACT_STATE_INPUT_SDET}>
                                            {contact?.state || NONE}
                                        </Typography.Text>
                                    </Form.Item>
                                    <Form.Item label="Country" name="countryCode">
                                        <Typography.Text data-sdet={SEAT_CONTACT_COUNTRY_SDET}>
                                            {contact?.countryCode || NONE}
                                        </Typography.Text>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Layout.Content>
                </Form>
            </Layout.Content>
        </Layout>
    );
};

export default SeatContactsDetailsPage;
