import { ROUTE_FORMATTERS } from "@app/core/routing";
import { TargetingReferenceEntityType, TargetingReferences } from "@app/core/services";
import { brandCobalt } from "@rubicon/antd-components";
import { Table, Typography } from "antd";
import { FC } from "react";
import { Link, useParams } from "react-router-dom";

const TABLE_COLUMNS = {
    NAME: "name",
    ENTITY_ID: "entityId",
    ENTITY_TYPE: "entityType",
};

const getTargetingUrlByEntityType = (
    entityType: TargetingReferenceEntityType,
    seatId: string | number,
    id: string | number
) => {
    switch (entityType) {
        case TargetingReferenceEntityType.Adsource:
            return ROUTE_FORMATTERS.SEAT_AD_SOURCES_DETAILS(seatId, id);
        case TargetingReferenceEntityType.AdvancedBrandSafety:
            return ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_DETAILS_PAGE(seatId, id);
        case TargetingReferenceEntityType.AdvancedFloor:
            return ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_ADVANCED_DETAILS(seatId, id);
        // TODO: add appropriate routes once those be done
        case TargetingReferenceEntityType.LineItem:
        case TargetingReferenceEntityType.MarketplaceInfo:
        default:
            break;
    }
};

interface Props {
    references?: TargetingReferences[];
}
export const TargetingReferencesTable: FC<Props> = ({ references }) => {
    const { seatId } = useParams<{ seatId: string }>();

    return (
        <Table
            size="small"
            pagination={false}
            columns={[
                {
                    title: "Name",
                    dataIndex: TABLE_COLUMNS.NAME,
                    key: TABLE_COLUMNS.NAME,
                    render: (name, { id, entityType }) => {
                        const url = getTargetingUrlByEntityType(entityType, seatId, id);
                        //INFO: once all routes be done should be removed
                        if (!url) {
                            return name;
                        }
                        return (
                            <Link target="_blank" component={Typography.Link} style={{ color: brandCobalt }} to={url}>
                                {name}
                            </Link>
                        );
                    },
                },
                {
                    title: "Entity ID",
                    dataIndex: TABLE_COLUMNS.ENTITY_ID,
                    key: TABLE_COLUMNS.ENTITY_ID,
                    render: (_, { id }) => id,
                },
                {
                    title: "Entity Type",
                    dataIndex: TABLE_COLUMNS.ENTITY_TYPE,
                    key: TABLE_COLUMNS.ENTITY_TYPE,
                },
            ]}
            dataSource={references}
        />
    );
};
