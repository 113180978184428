import { consoleApi, COST_MODELS_TAG } from "./console";

export interface CostModel {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    entityType: "CostModel";
}

export const costModelsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getCostModels: builder.query<CostModel[], void>({
            query: () => "costModels",
            providesTags: [COST_MODELS_TAG],
        }),
    }),
    overrideExisting: false,
});

export const { useGetCostModelsQuery } = costModelsApi;
