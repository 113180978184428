import { FC } from "react";
import { Card, Col, Row } from "antd";
import { useUserAccess } from "@app/core/auth";
import {
    BlockBuyerSeaList,
    BrandSafetyInternalOnly,
    BlockedAdvertiserDomains,
    BlockAdvertiserDomainLists,
} from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormSections/BrandSafetySection/Fields";

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 12 };
export const BrandSafetySection: FC = () => {
    const { isSysAdmin } = useUserAccess();
    return (
        <Card bordered={false}>
            <Row gutter={16}>
                <Col {...COL_SIZES}>
                    <BlockAdvertiserDomainLists />
                    <BlockedAdvertiserDomains />
                    <BlockBuyerSeaList />
                </Col>
                {isSysAdmin && (
                    <Col {...COL_SIZES}>
                        <BrandSafetyInternalOnly />
                    </Col>
                )}
            </Row>
        </Card>
    );
};
