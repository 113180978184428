import { FC } from "react";
import { Col, Form, Input, Row, Typography } from "antd";
import { CurrencyInput } from "@app/core/components";
import {
    AD_SOURCE_FIELDS,
    DEFAULT_ENRICHMENT_COST_CURRENCY_CODE,
    DemandAcquisitionCostModelId,
    VERTICAL_LAYOUT_FIX,
} from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "../../components";
import {
    getDemandAcquisitionCostFormValue,
    getDemandAcquisitionCostHelpText,
    getDemandAcquisitionCostLabel,
} from "./utils";
import { useDemandAcquisitionCostUtils } from "./useDemandAcquisitionCostUtils";
import { useGetEnrichmentCostModelsQuery } from "@app/core/services";

export const DemandAcquisitionCostField: FC = () => {
    const { isDemandAcquisitionEnabled, isDemandAcquisitionReadonly } = useDemandAcquisitionCostUtils();
    const { data: enrichmentCostModels } = useGetEnrichmentCostModelsQuery();

    const dac = Form.useWatch<string | null>(AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.name);
    const dacModelId = Form.useWatch(AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST_MODEL.name)?.id;

    if (!isDemandAcquisitionEnabled) {
        return null;
    }

    const dacLabel = getDemandAcquisitionCostLabel(dacModelId);
    const dacHelpText = getDemandAcquisitionCostHelpText(dac, dacModelId);
    const isDacModelPercent = dacModelId === DemandAcquisitionCostModelId.PERCENTAGE;

    return (
        <div data-sdet="demand-acquisition-cost-fields">
            <Row>
                <Col>
                    <Typography.Text strong>{AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.label}</Typography.Text>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <Form.Item name={AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST_MODEL.name} {...VERTICAL_LAYOUT_FIX}>
                        <SelectWithValue
                            data-sdet="ad-source-demand-acquisition-cost-model"
                            disabled={isDemandAcquisitionReadonly}
                            options={enrichmentCostModels}
                            placeholder="Select..."
                            fieldAsLabel={["name"]}
                            fieldAsValue={["id"]}
                        />
                    </Form.Item>
                </Col>
                <Col span={isDacModelPercent ? 12 : 14}>
                    {isDacModelPercent ? (
                        <Form.Item
                            name={AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.name}
                            data-sdet="ad-source-demand-acquisition-cost-field"
                            help={dacHelpText}
                            {...VERTICAL_LAYOUT_FIX}
                        >
                            <Input
                                data-sdet="ad-source-demand-acquisition-cost-input"
                                placeholder={`Enter ${dacLabel}`}
                                suffix="%"
                                disabled={isDemandAcquisitionReadonly}
                                value={getDemandAcquisitionCostFormValue(dac)}
                            />
                        </Form.Item>
                    ) : (
                        <CurrencyInput
                            name={AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.name}
                            dataSdetPrefix="ad-source-demand-acquisition-cost"
                            label={dacLabel}
                            hideLabel
                            disabled={isDemandAcquisitionReadonly}
                            currencyCode={DEFAULT_ENRICHMENT_COST_CURRENCY_CODE}
                            help={dacHelpText}
                            value={getDemandAcquisitionCostFormValue(dac)}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
};
