import { useAppDispatch, useAppSelector } from "@app/core/store";
import {
    selectDashboardCurrencyConversionMode,
    setSettingsCurrencyConversionMode,
} from "@app/features/dashboard/reducer";
import { LabeledValue } from "antd/lib/select";
import { CURRENCY_CONVERSION_MODE_OPTIONS } from "./constants";
import { CurrencyConversionModes } from "@app/core/clients/console";

interface UseCurrencyConversionModeField {
    handleChangeValue: (value: string) => void;
    options: LabeledValue[];
    value: CurrencyConversionModes | null;
}

export const useCurrencyConversionModeField = (): UseCurrencyConversionModeField => {
    const dispatch = useAppDispatch();
    const currencyConversionModeType = useAppSelector(selectDashboardCurrencyConversionMode);

    const handleChangeValue = (value: CurrencyConversionModes) => {
        dispatch(setSettingsCurrencyConversionMode(value));
    };

    return {
        options: CURRENCY_CONVERSION_MODE_OPTIONS,
        value: currencyConversionModeType,
        handleChangeValue,
    };
};
