import { FC } from "react";
import { Form, Input } from "antd";
import { BRAND_FORM_FIELDS, MAX_NAME_LENGTH } from "@app/features/inventory/HierarchyForms/constants";

export const Name: FC = () => {
    return (
        <Form.Item
            name={BRAND_FORM_FIELDS.NAME.name}
            label={BRAND_FORM_FIELDS.NAME.label}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 22, xxl: 22 }}
            rules={[
                { required: true, whitespace: true, message: BRAND_FORM_FIELDS.NAME.rulesMessage.required },
                { max: MAX_NAME_LENGTH, message: BRAND_FORM_FIELDS.NAME.rulesMessage.maxLength },
            ]}
        >
            <Input placeholder={BRAND_FORM_FIELDS.NAME.placeholder} allowClear />
        </Form.Item>
    );
};
