import { Layout } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { Footer } from "@app/core/components";
import { useHistory, useParams } from "react-router-dom";
import { FloorAdvancedForm } from "../FloorAdvancedForm";
import { ROUTE_FORMATTERS } from "@app/core/routing";

const InventoryFloorAdvancedCreatePage = () => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();

    return (
        <>
            <PageHeader
                title="Create Floor"
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_ADVANCED(Number(seatId)))}
            />
            <Layout.Content>
                <FloorAdvancedForm mode="create" />
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};

export default InventoryFloorAdvancedCreatePage;
