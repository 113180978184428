import { notification } from "antd";
import { SyncedFields, getSyncedFieldLabelsAsList } from "@app/features/syncedFields";

export const notifySyncedFieldsSuccess = (
    syncedFields: SyncedFields,
    adSourceTypeId: number | undefined,
    floorTypeId: number | undefined
): boolean => {
    const syncedFieldLabels = getSyncedFieldLabelsAsList(syncedFields, adSourceTypeId, floorTypeId);
    if (!syncedFieldLabels) {
        return false;
    }

    notification.info({
        message: `Updated ${syncedFieldLabels}`,
        description: `Synced Ad Source / Deal fields were updated. Submit the Ad Source to keep these values in sync.`,
    });

    return true;
};

export const notifySyncedFieldsError = (
    syncedFields: SyncedFields,
    adSourceTypeId: number | undefined,
    floorTypeId: number | undefined
): void => {
    const syncedFieldLabels = getSyncedFieldLabelsAsList(syncedFields, adSourceTypeId, floorTypeId);
    if (!syncedFieldLabels) {
        return;
    }

    notification.error({
        message: `Failed to update ${syncedFieldLabels}`,
        description: `Synced Ad Source / Deal fields failed to update. Update the Deal to keep these values in sync.`,
    });
};
