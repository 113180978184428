export const INVENTORY_HEALTH_FRACTIONS_DIGITS = 2;
export const INVENTORY_HEALTH_INVENTORY_TABLE_SEAT_KEY = 0;
export const NAME = "name";
export const SEARCH_PROPERTY = "searchProperty";
export const IS_CHANEL = "isChannel";
export const ALERT = "alert";
export const CODE = "code";
export const REQUESTS = "requests";
export const AUCTIONS = "auctions";
export const REJECTED_REQUESTS = "rejectedRequests";
export const FILL_RATE = "fillRate";
export const USE_RATE = "useRate";
export const IMPRESSIONS = "impressions";
export const FILLS = "fills";
export const GROSS_REVENUE = "grossRevenue";
export const NET_REVENUE = "netRevenue";
export const ACTIONS = "actions";
export const ENTITY_TYPE = "entityType";
export const COLUMN_KEY = {
    NAME,
    ALERT,
    CODE,
    REQUESTS,
    AUCTIONS,
    REJECTED_REQUESTS,
    FILL_RATE,
    USE_RATE,
    IMPRESSIONS,
    FILLS,
    GROSS_REVENUE,
    NET_REVENUE,
    ACTIONS,
    ENTITY_TYPE,
};
