import { Form } from "antd";
import { RuleObject, StoreValue } from "rc-field-form/lib/interface";
import { MIN_DURATION } from "@app/features/inventory/HierarchyForms/constants";

const { useWatch, useFormInstance } = Form;

interface UseAdUnitMaxDuration {
    onChange: (value: number) => void;
    validator: (rule: RuleObject, value: StoreValue) => Promise<void>;
}

export const useAdUnitMaxDuration = (): UseAdUnitMaxDuration => {
    const form = useFormInstance();
    const minDuration = useWatch(MIN_DURATION);
    const onChange = (): Promise<void> => form.validateFields([MIN_DURATION]);
    const validator = (_: RuleObject, value: StoreValue): Promise<void> => {
        if (!value) return Promise.reject(new Error("Max Duration is required"));
        if (minDuration > value) return Promise.reject(new Error("Max Duration can't be less than Min Duration"));
        return Promise.resolve();
    };

    return {
        validator,
        onChange,
    };
};
