import { NO_END_DATE } from "@app/core/components/constants";
import { MarketplaceInfo } from "@app/core/services";
import { Form } from "antd";
import { MomentDatePicker } from "@app/core/components/MomentDatePicker";
import { formItemOffset } from "../../DealTermsSection";
import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM } from "@app/core/components/constants";
import moment from "moment-timezone";

const formatDate = (date: string | undefined | null, timeZone?: { code: string }) => {
    const momentDate = moment(date);
    // TODO: Nasty hack because we're checking the form rather than the real data
    // Need to start reading from the deal itself rather than using Form.useWatch
    const format = momentDate.creationData().format;
    const formatHasTimeZone = String(format).includes("Z");

    if (!timeZone || !formatHasTimeZone) {
        return moment(date).format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM);
    }
    return moment(date).tz(timeZone?.code).format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM);
};

export const DurationView = () => {
    const startTime = Form.useWatch<MarketplaceInfo["deal"]["startTime"]>(["dealOrigin", "startTime"]);
    const endTime = Form.useWatch<MarketplaceInfo["deal"]["endTime"]>(["dealOrigin", "endTime"]);
    const timeZone = Form.useWatch<MarketplaceInfo["deal"]["timeZone"]>(["dealOrigin", "timeZone"]);

    return (
        <>
            <Form.Item className={formItemOffset} label="Start Date - End Date">
                {formatDate(startTime, timeZone)} - {endTime ? formatDate(endTime, timeZone) : NO_END_DATE}
            </Form.Item>
            <Form.Item name={["dealUnderEdit", "startTime"]} hidden noStyle>
                <MomentDatePicker />
            </Form.Item>
            <Form.Item name={["dealUnderEdit", "endTime"]} hidden noStyle>
                <MomentDatePicker />
            </Form.Item>
        </>
    );
};
