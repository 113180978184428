import { ReactNode } from "react";
import { Dashes } from "@app/core/components";

/* eslint-disable @typescript-eslint/no-unused-vars */
export function ReadonlyControl<T = unknown>({
    value,
    onChange,
    getLabel,
}: {
    value?: T;
    onChange?: (value: T) => void;
    getLabel: (value: T) => ReactNode;
}) {
    if (value === null || value === undefined) {
        return <Dashes />;
    }
    return <>{getLabel(value)}</>;
}
