import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME, TRANSPARENCY_SPECIFIC_VALUE } from "@app/features/deals/constants";

interface UseAdSourceExtendedIdList {
    isVisible: boolean;
}
export const useAdSourceExtendedUserIdList = (): UseAdSourceExtendedIdList => {
    const includedExtendedIds = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_ALL_EXTENDED_ID]
    );
    const isVisible = includedExtendedIds === TRANSPARENCY_SPECIFIC_VALUE;

    return { isVisible };
};
