import { FC } from "react";
import { Form, Select } from "antd";
import { useDealTimeZoneField } from "@app/features/deals/DealForm/DealDemandFormSections/DealTermsSection/Fields/DealTimeZoneField/useDealTimeZoneField";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { DealFormMode } from "@app/features/deals/DealForm/types";

interface DealTimeZoneFieldProps {
    mode: DealFormMode;
}

export const DealTimeZoneField: FC<DealTimeZoneFieldProps> = ({ mode }) => {
    const { options, isFetching, value, handleSelect, disabled } = useDealTimeZoneField(mode);

    return (
        <Form.Item label="Time Zone" name={CREATE_DEAL_FORM_ITEMS_NAME.TIME_ZONE}>
            <Select
                data-sdet="deal-timezone-field"
                loading={isFetching}
                value={value}
                disabled={disabled}
                showSearch
                allowClear
                onSelect={handleSelect}
                placeholder="Select Time Zone"
                options={options}
                optionFilterProp="label"
            />
        </Form.Item>
    );
};
