import { consoleApi, AUDIENCES_TAG, AUDIENCE_TAG } from "./console";

export interface Audience {
    id: string;
    name: string;
    description: string;
    minPriceUSD: number;
    maxPriceUSD: number;
    createdAt: string;
    deletedAt: string;
}

export const audiencesApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getSeatAudiences: builder.query<Audience[], number>({
            query: (seatId: number) => `seats/${seatId}/audiences`,
            providesTags: () => [AUDIENCES_TAG],
        }),
        getSeatAudienceById: builder.query<Audience, { id: number; seatId; number }>({
            query: (params: { id: number; seatId: number }) => `seats/${params.seatId}/audiences/${params.id}`,
            providesTags: () => [AUDIENCE_TAG],
        }),
    }),
});

export const { useGetSeatAudiencesQuery, useGetSeatAudienceByIdQuery } = audiencesApi;
