import { diagnosticsApi } from "../diagnostics";
import { VastErrorTotalsApiParams, VastErrors, VastErrorsApiParams } from "./types";
import { filterVastErrorsByNtime } from "./utils";

export const vastErrorsApi = diagnosticsApi.injectEndpoints({
    endpoints: (builder) => ({
        getVastErrors: builder.query<VastErrors[], VastErrorsApiParams>({
            query: (query) => {
                const { entityType, entityId } = query;
                return `${entityType}/${entityId}/vastErrors/naps`;
            },
            transformResponse: (apiResponse: VastErrors[], _, apiParams: VastErrorsApiParams) => {
                if (apiParams.ntimeFilter) {
                    return filterVastErrorsByNtime(apiResponse, apiParams.ntimeFilter);
                }
                return apiResponse;
            },
        }),
        getVastErrorTotals: builder.query<Blob, VastErrorTotalsApiParams>({
            query: (query) => {
                const { adSourceId, fileFormat } = query;
                return {
                    url: `adSources/${adSourceId}/vastErrors/totals?fmt=${fileFormat}`,
                    responseHandler: (response) => response.blob(),
                };
            },
        }),
    }),
    overrideExisting: false,
});

export const { useGetVastErrorsQuery, useLazyGetVastErrorTotalsQuery } = vastErrorsApi;
