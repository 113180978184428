import { FC } from "react";
import { Button, Col, Form, Row, Typography } from "antd";
import { MomentDatePicker } from "@app/core/components/MomentDatePicker";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useDealEndDateField } from "@app/features/deals/DealForm/DealDemandFormSections/DealTermsSection/Fields/DealEndDateField/useDealEndDateField";
import { NO_END_DATE, END_OF_DAY } from "@app/core/components/constants";
import { gray10, red5 } from "@rubicon/antd-components";
import { css } from "@emotion/css";
import { disablePastDates } from "@app/core/utils/disablePastDates";

const { useWatch } = Form;

const datePickerPopupClassname = css`
    .ant-picker-datetime-panel .ant-picker-time-panel .ant-picker-content .ant-picker-time-panel-column {
        &:after {
            height: 230px;
        }
    }
`;

export const DealEndDateField: FC = () => {
    const { handleChange, value, required, onClick, selectEndDateToggle } = useDealEndDateField();
    const startDate = useWatch(CREATE_DEAL_FORM_ITEMS_NAME.START_DATE);

    return (
        <Form.Item
            data-sdet="deal-end-date-field"
            required={false}
            rules={[
                {
                    validator(_, value, callback) {
                        if (startDate && value && startDate.isAfter(value)) {
                            callback("End Date should be after Start Date");
                        }
                        if (!value && required) {
                            callback("End Date is required");
                        } else {
                            callback();
                        }
                    },
                },
            ]}
            className={css(`
                label {
                    width: 100%;
                }
            `)}
            label={
                <Row justify="space-between" wrap={false} align="stretch" style={{ width: "100%", height: "22px" }}>
                    <Col>
                        <Typography.Text style={{ marginRight: "0.25rem", color: gray10 }}>
                            End Date {required && <span style={{ color: red5 }}>*</span>}
                        </Typography.Text>
                    </Col>
                    {!required && (
                        <Col>
                            <Button onClick={onClick} type="link" size="small">
                                {selectEndDateToggle ? "Select End Date" : "No End Date"}
                            </Button>
                        </Col>
                    )}
                </Row>
            }
            name={CREATE_DEAL_FORM_ITEMS_NAME.END_DATE}
        >
            <MomentDatePicker
                value={value}
                showNow={false}
                disabled={!required && selectEndDateToggle}
                placeholder={selectEndDateToggle ? NO_END_DATE : "Select date"}
                showTime={{ defaultValue: END_OF_DAY }}
                onChange={handleChange}
                style={{ width: "100%" }}
                disabledDate={disablePastDates}
                popupClassName={datePickerPopupClassname}
            />
        </Form.Item>
    );
};
