import { css } from "@emotion/css";
import { gray2 } from "@rubicon/antd-components";

// Seat Ad Source form items name
export const CREATE_SEAT_AD_SOURCE_FORM_ITEMS_NAME = {
    START_DATE: "seatAdSourceStartDate",
    END_DATE: "seatAdSourceEndDate",
    TIME_ZONE: "seatAdSourceTimeZone",
    MIN_DURATION: "seatAdSourceMinDuration",
    MAX_DURATION: "seatAdSourceMaxDuration",
    RATE: "test",
    CURRENCY: "currency",
} as const;

export const DATA_SDET = "seat-ad-sources";

export const GUTTER = { xs: 8, sm: 16, md: 24, lg: 32, xl: 32, xxl: 32 };
export const COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 8, xxl: 8 };
export const VERTICAL_LAYOUT_FIX = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };

export enum AD_SOURCE_SECTIONS {
    PERFORMANCE = "PERFORMANCE",
    AD_SOURCE_TERMS = "AD_SOURCE_TERMS",
    DELIVERY = "DELIVERY",
    PRICING = "PRICING",
    LEGACY_PRICING = "LEGACY_PRICING",
    FLOOR_SETTINGS = "FLOOR_SETTINGS",
    PACING = "PACING",
    PACING_STATUS = "PACING_STATUS",
    DEMAND = "DEMAND",
    PBS_BIDDER_CONFIGURATIONS = "PBS_BIDDER_CONFIGURATIONS",
    BRAND_SAFETY = "BRAND_SAFETY",
    CUSTOM_PIXELS = "CUSTOM_PIXELS",
    TRANSPARENCY = "TRANSPARENCY",
    LABELS = "LABELS",
    TARGETING = "TARGETING",
    DEAL_TERMS = "DEAL_TERMS",
    DEMAND_CONFIGURATION = "DEMAND_CONFIGURATION",
    ENRICHMENT_COST = "ENRICHMENT_COST",
}

export enum AD_SOURCE_DRAWER_LOCATION {
    DEALS = "deals",
    DEALS_HEALTH = "deals/health",
    DEALS_CREATE_DEMAND = "deals/create/demand",
    COPY = "copy",
    PACING = "pacing",
    DETAILS_PAGE = "details-page",
    DETAILS_DRAWER = "details-drawer",
    EDIT = "edit",
    DEAL_DETAILS_DRAWER = "deal-details-drawer",
}

export const AD_SOURCE_SECTIONS_NAME = {
    [AD_SOURCE_SECTIONS.PERFORMANCE]: "Performance",
    [AD_SOURCE_SECTIONS.AD_SOURCE_TERMS]: "Ad Source Terms",
    [AD_SOURCE_SECTIONS.DELIVERY]: "Delivery",
    [AD_SOURCE_SECTIONS.PRICING]: "Pricing",
    [AD_SOURCE_SECTIONS.LEGACY_PRICING]: "Legacy Pricing",
    [AD_SOURCE_SECTIONS.FLOOR_SETTINGS]: "Floor Settings",
    [AD_SOURCE_SECTIONS.PACING]: "Pacing",
    [AD_SOURCE_SECTIONS.PACING_STATUS]: "Pacing Status",
    [AD_SOURCE_SECTIONS.DEMAND]: "Demand",
    [AD_SOURCE_SECTIONS.PBS_BIDDER_CONFIGURATIONS]: "PBS Bidder Configurations",
    [AD_SOURCE_SECTIONS.BRAND_SAFETY]: "Brand Safety",
    [AD_SOURCE_SECTIONS.CUSTOM_PIXELS]: "Custom Pixels",
    [AD_SOURCE_SECTIONS.TRANSPARENCY]: "Transparency",
    [AD_SOURCE_SECTIONS.LABELS]: "Labels",
    [AD_SOURCE_SECTIONS.TARGETING]: "Targeting",
    [AD_SOURCE_SECTIONS.DEAL_TERMS]: "Deal Terms",
    [AD_SOURCE_SECTIONS.DEMAND_CONFIGURATION]: "Demand Configuration",
    [AD_SOURCE_SECTIONS.ENRICHMENT_COST]: "Enrichment Costs",
};

export const AD_SOURCE_SECTIONS_KEYS = {
    [AD_SOURCE_SECTIONS.AD_SOURCE_TERMS]: "1",
    [AD_SOURCE_SECTIONS.DELIVERY]: "2",
    [AD_SOURCE_SECTIONS.PRICING]: "3",
    [AD_SOURCE_SECTIONS.LEGACY_PRICING]: "4",
    [AD_SOURCE_SECTIONS.FLOOR_SETTINGS]: "5",
    [AD_SOURCE_SECTIONS.PACING]: "6",
    [AD_SOURCE_SECTIONS.PACING_STATUS]: "7",
    [AD_SOURCE_SECTIONS.DEMAND]: "8",
    [AD_SOURCE_SECTIONS.PBS_BIDDER_CONFIGURATIONS]: "9",
    [AD_SOURCE_SECTIONS.BRAND_SAFETY]: "10",
    [AD_SOURCE_SECTIONS.CUSTOM_PIXELS]: "11",
    [AD_SOURCE_SECTIONS.TRANSPARENCY]: "12",
    [AD_SOURCE_SECTIONS.LABELS]: "13",
    [AD_SOURCE_SECTIONS.TARGETING]: "14",
    [AD_SOURCE_SECTIONS.DEAL_TERMS]: "15",
    [AD_SOURCE_SECTIONS.DEMAND_CONFIGURATION]: "16",
    [AD_SOURCE_SECTIONS.ENRICHMENT_COST]: "17",
    [AD_SOURCE_SECTIONS.PERFORMANCE]: "18",
};

export const AD_SOURCE_FIELDS = {
    TYPE: {
        label: "Ad Source Type",
        helpPath: "adsource.type",
        name: "type",
    },
    NAME: {
        label: "Name",
        helpPath: "adsource.name",
        name: "name",
        validations: {
            max: 250,
            required: true,
            isOnlySpaces: (value: string) => value && value.trim().length === 0,
        },
    },
    DESCRIPTION: {
        label: "Description",
        helpPath: "adsource.description",
        name: "description",
        validations: {
            max: 1024,
        },
    },
    PRIORITY: {
        label: "Priority",
        helpPath: "adsource.priority",
        name: "priority",
    },
    UD_PRIORITY: {
        label: "UD Priority",
        helpPath: "adsource.udePriority",
        name: "udePriority",
    },
    ASSIGNEE: {
        label: "Assignee",
        helpPath: "adsource.assignee",
        name: "assignee",
    },
    STATUS: {
        label: "Status",
        helpPath: "adsource.status",
        name: "status",
    },
    EXTEND_TIMEOUT_VALUE: {
        label: "Extend Timeout Value",
        helpPath: "adsource.nurltimeoutoverride",
        name: "nurlTimeoutOverride",
    },
    TAG_URL: {
        label: "Tag URL",
        helpPath: "adsource.vasturl",
        name: "tagUrl",
    },
    ASSOCIATED_MARKETPLACE: {
        label: "Associated Marketplace",
        //TODO check helpPath
        name: "associatedMarketplace",
    },
    ASSOCIATED_CURATOR_MARKETPLACES: {
        label: "Associated Marketplaces",
        //TODO check helpPath
        name: "curatorMarketplaceIds",
    },
    ASSIGNED_ADVERTISER_DOMAIN: {
        label: "Assigned Advertiser Domain",
        helpPath: "adsource.assignedadomain",
        name: "defaultAdvertiserDomain",
    },
    COST_MODEL: {
        label: "Cost Model",
        helpPath: "adsource.costmodel",
        name: "costModel",
    },
    COST_FIXED: {
        label: "Cost",
        helpPath: "adsource.costvalue",
        name: "costValueFixed",
    },
    COST_PERCENT: {
        label: "Cost",
        helpPath: "adsource.costvalue",
        name: "costValuePercent",
    },
    ALLOW_AUTOSCALE: {
        label: "Allow Autoscale",
        helpPath: "adsource.allowautoscale",
        name: "allowAutoscale",
    },
    REGION: {
        label: "Region",
        helpPath: "adsource.region",
        name: "region",
    },
    SALES_CONTACT: {
        label: "Sales Contact",
        helpPath: "adsource.salescontact",
        name: "salesContact",
    },
    EXTERNAL_CONTRACT_NUMBER: {
        label: "External Contract Number",
        helpPath: "adsource.externalcontractid",
        name: "externalContractId",
    },
    START_DATE: {
        label: "Start Date",
        helpPath: "adsource.bookingstartdate",
        name: "bookingStartDate",
    },
    END_DATE: {
        label: "End Date",
        helpPath: "adsource.bookingenddate",
        name: "bookingEndDate",
    },
    TIME_ZONE: {
        label: "Time Zone",
        helpPath: "adsource.timezone",
        name: "timeZone",
    },
    TOTAL_IMPRESSIONS: {
        label: "Total Impressions",
        helpPath: "adsource.bookingvolume",
        name: "bookingVolume",
    },
    SHARE_OF_VOICE_PERCENT: {
        label: "Share Of Voice Percent",
        helpPath: "adsource.shareofvoicepercent",
        name: "shareOfVoicePercent",
    },
    SHARE_OF_VOICE: {
        label: "Share Of Voice",
        helpPath: "adsource.shareofvoice",
        name: "shareOfVoice", // internal only
    },
    SHARE_OF_VOICE_ENABLED: {
        label: "Share Of Voice Enabled",
        helpPath: "adsource.shareofvoice",
        name: "shareOfVoice", // internal only
    },
    MIN_DURATION: {
        label: "Min Duration",
        helpPath: "adunit.minduration",
        name: "adSourceMinDuration",
    },
    MAX_DURATION: {
        label: "Max Duration",
        helpPath: "adunit.maxduration",
        name: "adSourceMaxDuration",
    },
    INVENTORY_DISTRIBUTION_GROUPS: {
        label: "Inventory Distribution Groups",
        helpPath: "inventory.labels",
        name: "inventoryDistributionGroups",
    },
    VAST_DYNAMIC_PRICING_LOCATION: {
        label: "VAST Dynamic Pricing Location",
        helpPath: "adsource.vastDynamicPricingLocation",
        name: "vastDynamicPricingLocation",
    },
    VAST_DYNAMIC_PRICING_MULTIPLIER: {
        label: "VAST Dynamic Pricing Multiplier",
        helpPath: "adsource.vastDynamicPricingMultiplier",
        name: "vastDynamicPricingMultiplier",
    },
    PRICE_MODEL: {
        label: "Price Model",
        helpPath: "adsource.pricemodel",
        name: "priceModel",
    },
    FLOOR_TYPE: {
        label: "Floor Type",
        helpPath: "adsource.floortype",
        name: "adSourceFloorType",
    },
    FIXED_CPM: {
        label: "Fixed CPM",
        helpPath: "adsource.bookingprice",
        name: "bookingPrice",
    },
    OVERRIDE_FLOOR_CPM: {
        label: "Override Floor (CPM)",
        helpPath: "adsource.bookingpriceunknown",
        name: "bookingPrice",
    },
    FALLBACK_FLOOR_CPM: {
        label: "Fallback Floor (CPM)",
        helpPath: "adsource.bookingpriceunknown",
        name: "bookingPrice",
    },
    CURRENCY: {
        label: "Currency",
        helpPath: "adsource.currencytype",
        name: "currencyType",
    },
    AUCTION_TYPE: {
        label: "Auction Type",
        helpPath: "adsource.auctiontype",
        name: "auctionType",
    },
    ALWAYS_SEND_FLOORS: {
        label: "Always Send Floors",
        helpPath: "adsource.alwayssendfloor",
        name: "alwaysSendFloor",
    },
    BOOKING_BUDGET: {
        label: "Booking Budget",
        helpPath: "adsource.bookingbudget",
        name: "bookingBudget", // readonly
    },
    AUDIENCE_ENRICHMENT_COST: {
        label: "Audience Enrichment Cost (CPM)",
        helpPath: "adsource.enrichmentcost",
        name: "demandfee",
    },
    DEMAND_ACQUISITION_COST: {
        label: "Demand Acquisition Cost",
        helpPath: "",
        name: "demandAcquisitionCost",
    },
    DEMAND_ACQUISITION_COST_MODEL: {
        label: "",
        helpPath: "",
        name: "demandAcquisitionCostModel",
    },
    ENRICHMENT_COST: {
        label: "Enrichment Cost (CPM)",
        helpPath: "adsource.enrichmentcost",
    },
    VENDOR: {
        label: "Vendor Name",
        helpPath: "adsource.enrichmentvendor",
    },
    SEGMENT_NAME: {
        label: "Segment Name",
        helpPath: "adsource.enrichmentname",
    },
    DIGITAL_CPM: {
        label: "Digital CPM",
        helpPath: "adsource.enrichmentdigitalcpm",
    },
    TV_CPM: {
        label: "TV CPM",
        helpPath: "adsource.enrichmenttvcpm",
    },
    PACING_PERIOD: {
        label: "Pacing Period",
        helpPath: "adsource.pacingperiod",
        name: "pacingPeriod",
    },
    PACING_TYPE: {
        label: "Pacing Type",
        helpPath: "adsource.pacingtype",
        name: "pacingType",
    },
    PACING_REDISTRIBUTION: {
        label: "Pacing Redistribution",
        helpPath: "adsource.pacingredistribute",
        name: "redistributePacing",
    },
    DAILY_IMPRESSION_CAP_ENABLED: {
        label: "Daily Impression Cap Enabled",
        helpPath: "adsource.dailycapenabled",
        name: "dailyCapEnabled", //internal only
    },
    DAILY_IMPRESSION_CAP: {
        label: "Daily Impression Cap",
        helpPath: "adsource.dailycap",
        name: "dailyCapVolume",
    },
    FALLBACK_OPPORTUNITY: {
        label: "Fallback Opportunity Estimate",
        helpPath: "adsource.dailyRequestOpportunityFallback",
        name: "dailyRequestOpportunityFallback",
    },
    TARGET_DATE: {
        label: "Target Date",
        name: "customCurveStartDate",
        helpPath: "adsource.pacingtargetdate",
    },
    DELIVERY_PERCENTAGE: {
        label: "Delivery Percentage",
        name: "customCurveImps",
        helpPath: "adsource.pacingdeliverypct",
    },
    EXCLUDE_DEMAND: {
        label: "Exclude Demand",
        name: "excludedNetworks",
        helpPath: "adsource.excludednetworks",
    },
    DEMAND: {
        label: "",
        helpPath: "adsource.marketplaceinfolist",
        name: "marketplaceInfoList",
    },
    PBS_BIDDER_CONFIGS: {
        label: "Choose Configs",
        helpPath: "adsource.pbsconfigs",
        name: "pbsConfigs",
    },
    IAB_CATEGORY_BLOCK_MODE: {
        label: "IAB Category Block Mode",
        helpPath: "adsource.iabcategorymode",
        name: "iabCategoryBlockMode",
    },
    IAB_BLOCKED_CATEGORIES: {
        label: "IAB Categories",
        helpPath: "adsource.iabblockedcategories",
        name: "blockedIabCategories",
        extra: "You have selected Block (Override Inventory Blocked Categories) without selecting any IAB Categories. Therefore, no categories will be blocked for this Ad Source",
    },
    AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES: {
        label: "IAB Category Block Exceptions",
        name: "blockExceptionIabCategories",
    },
    MODE: {
        label: "Advertiser Domain Mode",
        helpPath: "adsource.advertiserblockingmode",
        name: "advertiserBlockingMode",
    },
    REUSABLE_ADVERTISER_DOMAINS: {
        label: "Reusable Advertiser Domains",
        helpPath: "adsource.reusablelistdomains",
        name: "seatAdvertiserDomainFilterListDefs", //?
    },
    ADDITIONAL_ADVERTISER_DOMAINS: {
        label: "Additional Advertiser Domains",
        helpPath: "adsource.staticlistdomain",
        name: "advertiserBlockDomains",
        extra: "You have selected Block (Override Inventory Blocked Domains) without defining any reusable or additional domains. Therefore, no domains will be blocked for this Ad Source.",
    },
    PIXELS: {
        label: "Pixels",
        helpPath: "adsource.thirdpartypixels",
        name: "thirdPartyPixels",
    },
    SUPPLY_NAME: {
        label: "Supply Name/App Name",
        helpPath: "adsource.supplyname",
        name: "allowSiteName",
    },
    DOMAIN_NAME: {
        label: "Domain Name",
        helpPath: "adsource.domainnameallowed",
        name: "allowDomainName",
    },
    DOMAIN_NAME_OVERRIDE: {
        label: "Domain Name Override",
        helpPath: "adsource.domainNameOverride",
        name: "domainNameOverride",
    },
    ALLOW_AUDIENCE_LOCK: {
        label: "Allow Data Lock",
        helpPath: "adsource.allowAudienceLock",
        name: "allowAudienceLock",
    },
    SUPPLY_PAGE: {
        label: "Supply Page/Content URL",
        helpPath: "adsource.supplypageallowed",
        name: "allowSitePage",
    },
    REFER_URL: {
        label: "Refer URL",
        helpPath: "adsource.refallowed",
        name: "allowRef",
    },
    FULL_IP_ADDRESS: {
        label: "Full IP Address",
        helpPath: "adsource.ipallowed",
        name: "allowIp",
    },
    BUNDLE_ID: {
        label: "Bundle ID",
        helpPath: "adsource.bundleallowed",
        name: "allowBundleId",
    },
    BUNDLE_ID_OVERRIDE: {
        label: "Bundle ID Override",
        helpPath: "adsource.bundleIdOverride",
        name: "bundleIdOverride",
    },
    STORE_URL: {
        label: "Store URL",
        helpPath: "adsource.storeallowed",
        name: "allowStoreUrl",
    },
    USER_ID: {
        label: "User ID/IFA",
        helpPath: "adsource.useridallowed",
        name: "allowUserId",
    },
    BVOD_CONNECT_ID: {
        label: "BVOD Connect ID Enabled",
        helpPath: "adsource.oztamallowed",
        name: "allowOztam",
    },
    BVOD_CONNECT_DEMOGRAPHIC: {
        label: "BVOD Connect Demographic",
        helpPath: "adsource.oztamdemoallowed",
        name: "allowOztamDemographic",
    },
    CONTENT: {
        label: "Content",
        helpPath: "adsource.contentallowed",
        name: "allowContent",
    },
    INCLUDED_EXTENDED_IDS: {
        label: "Included Extended IDs",
        helpPath: "adsource.extendedid",
        name: "allowAllExtendedId",
    },
    ALLOW_DATA_LOCK: {
        label: "Allow Data Lock",
        helpPath: "seat.datalock",
        name: "allowDataLock",
    },
    EXTENDED_USER_ID: {
        label: "Extended User ID",
        helpPath: "adsource.extendedid",
        name: "allowedExtendedIdTransparencies",
    },
    WATERFALL_PRE_FILTER_MODE: {
        label: "Waterfall Pre-Filter Mode",
        helpPath: "adsource.prefiltermode",
        name: "strictMode",
    },
    LABEL: {
        label: "Labels",
        helpPath: "adsource.labels",
        name: "labelValues",
    },
    READ_ONLY_TARGETING: {
        label: "",
        name: "targeting",
    },
    TARGETING_MODE: {
        label: "",
        name: "targetingMode",
    },
    DEAL_TYPE: {
        label: "Deal Type",
        name: "dealType",
        helpPath: "deal.type",
    },
    DEAL_TRANSACTION_TYPE: {
        label: "Transaction Type",
        name: "dealTransactionType",
        helpPath: "dealdetails.transactiontype",
    },
    DEAL_PRICING_TYPE: {
        label: "Pricing Type",
        name: "dealPricingType",
        helpPath: "dealdetails.pricingtype",
    },
    DEAL_ID: {
        label: "Deal ID",
        name: "code",
        helpPath: "deal.dealid",
    },
    DEAL_NAME: {
        label: "Deal Name",
        name: "name",
        helpPath: "deal.name",
    },
    DEAL_NETWORK: {
        label: "DSP",
        name: "network",
        helpPath: "deal.demand",
    },
    DEAL_BUYER: {
        label: "Buyer Seat",
        name: "buyerSeats",
        helpPath: "deal.buyer",
    },
    PUBLISHER_NAME: {
        label: "Publisher Name",
        name: "publisherName",
        helpPath: "deal.publishername",
    },
    PUBLISHER_EMAIL: {
        label: "publisherEmail",
        name: "Publisher Email",
        helpPath: "deal.email",
    },
    DEAL_START_DATE: {
        label: "Start Date",
        helpPath: "deal.dates",
    },
    DEAL_END_DATE: {
        label: "End Date",
        helpPath: "deal.dates",
    },
    DEAL_DATES: {
        label: "Start Date - End Date",
        helpPath: "deal.dates",
    },
    DEAL_TIMEZONE: {
        label: "Time Zone",
        name: "timeZone",
        helpPath: "deal.timezone",
    },
    DEAL_STATUS: {
        label: "Status",
        name: "status",
        helpPath: "dealdetails.status",
    },
    DEAL_ORIGIN: {
        label: "Deal Origin",
        name: "dealOrigin",
        helpPath: "deal.origin",
    },
    DEAL_SHARED_SEATS: {
        label: "Shared Seats",
        name: "sharedSeats",
        helpPath: "deal.sharedSeats",
    },
    SHARED_MARKETPLACES: {
        label: "Shared Marketplaces",
        name: "sharedMarketplaces",
        helpPath: "deal.sharedMarketplaces",
    },
    DEAL_PRICING_MODEL: {
        helpPath: "deal.pricemodel",
        label: "Price Model",
        name: "priceModel",
    },
    DEAL_IMPRESSIONS: {
        name: "bookingVolume",
        label: "Impressions",
        helpPath: "deal.bookingvolume",
    },
    DEAL_CPM_RATE: {
        label: "CPM Rate",
        name: "rate",
        helpPath: "deal.bookingprice",
    },
    DEAL_CURRENCY: {
        name: "currencyType",
        label: "Currency",
        helpPath: "deal.currencytype",
    },
    FREQ_CAPPING: {
        label: "Freq Capping",
        helpPath: "deal.freqcapping",
    },
    HAS_TARGETING: {
        label: "Has Targeting",
        helpPath: "dealdetails.hastargeting",
    },
    DEAL_BUYER_STATUS: {
        label: "Buyer Status",
        helpPath: "dealdetails.buyerstatus",
    },
    DEAL_TRANSACTION_CURRENCY: {
        label: "Transaction Currency",
        helpPath: "deal.transactioncurrency",
    },
    DEAL_ENFORCEMENT: {
        label: "Enforcement",
        helpPath: "deal.enforcement",
    },
    DEAL_FREQUENCY_CAPPING: {
        label: "Deal Frequency Capping",
        helpPath: "deal.freqcapping",
    },
    RUN_UNDER_INBOUND_FLOOR: {
        label: "Run Under Inbound Floor",
        name: "overrideDynamicFloor",
        helpPath: "adsource.overrideDynamicFloor",
    },
    RECORDED_IMPRESSIONS: {
        label: "Recorded Imp",
        helpPath: "pacingdiags.recordedimps",
    },
    PROJECTED_IMPRESSIONS: {
        label: "Projected",
        helpPath: "pacingdiags.projectedimps",
    },
    ON_PACE: {
        label: "On Pace",
        helpPath: "pacingdiags.onpace",
    },
    ESTIMATED_DAILY_AVAILS: {
        label: "Est. Daily Avails",
        helpPath: "pacingdiags.dailyavails",
    },
    TARGETING_BLOCKS: {
        label: "Targeting Blocks",
        helpPath: "targetingdetails.blocks",
    },
    DEALS_WITH_TARGETING: {
        label: "Deals with Targeting",
        helpPath: "targetingdetails.dealswithtgt",
    },
    AD_RESPONSE_PRICE_OVERRIDE: {
        label: "Ad Response Price Override",
        name: "adResponsePriceOverrideType",
    },
    AD_STATS_DEAL: {
        key: "AD_STATS_DEAL",
        label: "Deal Ad Stats",
        helpPath: "adsourcedetails.adstats",
    },
    TRIES: {
        key: "Tries",
        label: "Tries",
        helpPath: "adsourcedetails.tries",
    },
    FILLS: {
        key: "Fills",
        label: "Fills",
        helpPath: "adsourcedetails.fills",
    },
    IMPRESSIONS: {
        key: "Impressions",
        label: "Impressions",
        helpPath: "adsourcedetails.impressions",
    },
    NET_REVENUE: {
        key: "Net Revenue",
        label: "Net Revenue",
        helpPath: "adsourcedetails.netrevenue",
    },
    ERRORS: {
        key: "Errors",
        label: "Errors",
        helpPath: "adsourcedetails.errors",
    },
    FALL_BACKS: {
        key: "Fall Backs",
        label: "Fall Backs",
        helpPath: "adsourcedetails.fallbacks",
    },
    SKIPS: {
        key: "Skips",
        label: "Skips",
        helpPath: "adsourcedetails.skips",
    },
    LAST_24_HOURS: {
        key: "LAST_24_HOURS",
        label: "Last 24 Hours",
        helpPath: "adsourcedetails.perflast24",
    },
    BIDDER_STATS_DEAL: {
        key: "BIDDER_STATS_DEAL",
        label: "Deal Bidder Stats",
        helpPath: "adsourcedetails.bidderstats",
    },
} as const;

export const AD_SOURCE_FIELDS_BY_SECTIONS = {
    PERFORMANCE: [
        "AD_STATS_DEAL",
        "TRIES",
        "FILLS",
        "IMPRESSIONS",
        "NET_REVENUE",
        "ERRORS",
        "FALL_BACKS",
        "SKIPS",
        "LAST_24_HOURS",
        "BIDDER_STATS_DEAL",
    ],
    AD_SOURCE_TERMS: [
        "TYPE",
        "NAME",
        "DESCRIPTION",
        "PRIORITY",
        "ASSIGNEE",
        "STATUS",
        "EXTEND_TIMEOUT_VALUE",
        "TAG_URL",
        "ASSIGNED_ADVERTISER_DOMAIN",
        "COST_MODEL",
        "COST_FIXED",
        "ALLOW_AUTOSCALE",
        "REGION",
        "SALES_CONTACT",
        "EXTERNAL_CONTRACT_NUMBER",
    ],
    DELIVERY: [
        "START_DATE",
        "END_DATE",
        "TIME_ZONE",
        "TOTAL_IMPRESSIONS",
        "SHARE_OF_VOICE",
        "SHARE_OF_VOICE_ENABLED",
        "SHARE_OF_VOICE_PERCENT",
        "MIN_DURATION",
        "MAX_DURATION",
    ],
    PRICING: [
        "VAST_DYNAMIC_PRICING_LOCATION",
        "VAST_DYNAMIC_PRICING_MULTIPLIER",
        "PRICE_MODEL",
        "FLOOR_TYPE",
        "FIXED_CPM",
        "OVERRIDE_FLOOR_CPM",
        "CURRENCY",
        "AUCTION_TYPE",
        "ALWAYS_SEND_FLOORS",
        "BOOKING_BUDGET",
        "AUDIENCE_ENRICHMENT_COST",
        "RUN_UNDER_INBOUND_FLOOR",
    ],
    PACING: [
        "PACING_PERIOD",
        "PACING_TYPE",
        "PACING_REDISTRIBUTION",
        "DAILY_IMPRESSION_CAP_ENABLED",
        "DAILY_IMPRESSION_CAP",
        "TARGET_DATE",
        "DELIVERY_PERCENTAGE",
    ],
    DEMAND: [
        "EXCLUDE_DEMAND",
        "DEAL_NAME",
        "DEAL_NETWORK",
        "DEAL_BUYER",
        "DEAL_CPM_RATE",
        "DEAL_CURRENCY",
        "DEAL_START_DATE",
        "DEAL_END_DATE",
        "DEAL_DATES",
        "FREQ_CAPPING",
        "HAS_TARGETING",
        "DEAL_STATUS",
        "DEAL_BUYER_STATUS",
    ],
    PBS_BIDDER_CONFIGURATIONS: ["PBS_BIDDER_CONFIGS"],
    BRAND_SAFETY: ["MODE", "REUSABLE_ADVERTISER_DOMAINS", "ADDITIONAL_ADVERTISER_DOMAINS"],
    CUSTOM_PIXELS: ["PIXELS"],
    TRANSPARENCY: [
        "SUPPLY_NAME",
        "DOMAIN_NAME",
        "SUPPLY_PAGE",
        "REFER_URL",
        "FULL_IP_ADDRESS",
        "BUNDLE_ID",
        "STORE_URL",
        "USER_ID",
        "CONTENT",
        "INCLUDED_EXTENDED_IDS",
        "ALLOW_DATA_LOCK",
    ],
    LABELS: ["WATERFALL_PRE_FILTER_MODE", "LABEL"],
    TARGETING: ["TARGETING_BLOCKS", "DEALS_WITH_TARGETING"],
    DEAL_TERMS: [
        "DEAL_TYPE",
        "DEAL_TRANSACTION_TYPE",
        "DEAL_PRICING_TYPE",
        "DEAL_ID",
        "DEAL_NAME",
        "DEAL_NETWORK",
        "DEAL_BUYER",
        "PUBLISHER_NAME",
        "PUBLISHER_EMAIL",
        "DEAL_START_DATE",
        "DEAL_END_DATE",
        "DEAL_DATES",
        "DEAL_TIMEZONE",
        "DEAL_STATUS",
        "DEAL_ORIGIN",
        "DEAL_SHARED_SEATS",
        "SHARED_MARKETPLACES",
    ],
    DEAL_PRICING: ["DEAL_PRICING_MODEL", "DEAL_IMPRESSIONS", "DEAL_CPM_RATE", "DEAL_CURRENCY"],
    DEMAND_CONFIGURATION: ["DEAL_TRANSACTION_CURRENCY", "DEAL_ENFORCEMENT", "DEAL_FREQUENCY_CAPPING"],
    ENRICHMENT_COST: ["AUDIENCE_ENRICHMENT_COST", "ENRICHMENT_COST", "VENDOR", "SEGMENT_NAME", "DIGITAL_CPM", "TV_CPM"],
    PACING_STATUS: ["RECORDED_IMPRESSIONS", "PROJECTED_IMPRESSIONS", "ON_PACE", "ESTIMATED_DAILY_AVAILS"],
};

export const TARGET_DATE_AND_DELIVERY_PERCENTAGE_LABEL = `${AD_SOURCE_FIELDS.TARGET_DATE.label} and ${AD_SOURCE_FIELDS.DELIVERY_PERCENTAGE.label}`;

export enum AdSourceStatusIds {
    RUNNING = 1,
    PAUSED = 2,
    EXPIRED = 3,
    FUTURE = 200,
}

// TODO - these should be renamed to align with the UI names
export enum AdSourceTypeIds {
    TREMOR_NETWORK = 1,
    AUTOMATED_GUARANTEED = 6,
    CONDITIONAL_AUTOMATED_GUARANTEED = 12,
    SERVER_SIDE_TAG_GUARANTEED = 7,
    PRE_BID_DEALS = 14,
    CLIENT_SIDE_TAG_GUARANTEED = 9,
    SERVER_SIDE_DYNAMIC_PRICE = 11,
    UNRESERVED_FIXED_RATE = 2,
    SERVER_SIDE_TAG_NON_GUARANTEED = 8,
    AUCTION_PRICE = 3,
    OPEN_AUCTION = 4,
    FALLBACK_TAG = 5,
    MARKETPLACE = 10,
    PRE_BID_AD_SOURCE = 13,
    DIRECT_AD_SERVING = 100,
    LINEAR_UNRESERVED_FIXED_RATE = 15,
    LINEAR_INVITE_ONLY_AUCTION = 16,
    CURATOR_MARKETPLACE = 17,
}

export enum AdSourceFloorTypeIds {
    OVERRIDE = 1,
    FALLBACK = 2,
    INCREASE_CPM = 3,
    DECREASE_CPM = 4,
    INCREASE_PERCENT = 5,
    DECREASE_PERCENT = 6,
}

export enum AdSourceIabCategoryModeIds {
    BLOCK_USE_INVENTORY = 1,
    BLOCK_OVERRIDE_INVENTORY = 2,
}

export enum AdSourceAdvertiserDomainModeIds {
    BLOCK_USE_INVENTORY = 1,
    BLOCK_OVERRIDE_INVENTORY = 2,
    ALLOW_OVERRIDE_INVENTORY = 3,
}

export const AdSourceTypeNames: Record<AdSourceTypeIds, string> = {
    [AdSourceTypeIds.AUTOMATED_GUARANTEED]: "Programmatic Guaranteed",
    [AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED]: "Conditional Programmatic Guaranteed",
    [AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED]: "Server-side Tag Guaranteed",
    [AdSourceTypeIds.PRE_BID_DEALS]: "Prebid Deals Container",
    [AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED]: "Client-side Tag Guaranteed",
    [AdSourceTypeIds.SERVER_SIDE_DYNAMIC_PRICE]: "Server-side Dynamic Price",
    [AdSourceTypeIds.UNRESERVED_FIXED_RATE]: "Fixed Price",
    [AdSourceTypeIds.SERVER_SIDE_TAG_NON_GUARANTEED]: "Server-side Tag Non-Guaranteed",
    [AdSourceTypeIds.AUCTION_PRICE]: "Auction Price",
    [AdSourceTypeIds.OPEN_AUCTION]: "Open Auction",
    [AdSourceTypeIds.FALLBACK_TAG]: "Fallback Tag",
    [AdSourceTypeIds.MARKETPLACE]: "Marketplace",
    [AdSourceTypeIds.PRE_BID_AD_SOURCE]: "Prebid Ad Source",
    [AdSourceTypeIds.DIRECT_AD_SERVING]: "Direct AdServing",
    [AdSourceTypeIds.LINEAR_UNRESERVED_FIXED_RATE]: "Linear Fixed Price",
    [AdSourceTypeIds.LINEAR_INVITE_ONLY_AUCTION]: "Linear Auction Price",
    [AdSourceTypeIds.TREMOR_NETWORK]: "Tremor",
    [AdSourceTypeIds.CURATOR_MARKETPLACE]: "Curator Marketplace",
} as const;

export const AdServingEligibleAdSourceTypeIds = [
    AdSourceTypeIds.AUTOMATED_GUARANTEED,
    AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED,
    AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED,
    AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED,
] as const;

export const DealPerformanceEligibleAdSourceTypeIds = [
    AdSourceTypeIds.AUTOMATED_GUARANTEED,
    AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED,
    AdSourceTypeIds.AUCTION_PRICE,
    AdSourceTypeIds.LINEAR_INVITE_ONLY_AUCTION,
    AdSourceTypeIds.OPEN_AUCTION,
    AdSourceTypeIds.UNRESERVED_FIXED_RATE,
] as const;

export const PrebidAdSourceTypeIds = [AdSourceTypeIds.PRE_BID_DEALS, AdSourceTypeIds.PRE_BID_AD_SOURCE] as const;

export enum CostModels {
    REVSHARE_PERCENTAGE = 1,
    FIXED_CPM = 2,
}

export const CostModelsLabels: Record<CostModels, string> = Object.freeze({
    [CostModels.REVSHARE_PERCENTAGE]: "Revshare Percentage",
    [CostModels.FIXED_CPM]: "Cost Fixed CPM",
});

export const CostModelsDefaultValues: Record<CostModels, number> = Object.freeze({
    [CostModels.REVSHARE_PERCENTAGE]: 70,
    [CostModels.FIXED_CPM]: 250,
});

export const AdSourceTypesUsingFixedCpmByDefault = Object.freeze(
    new Set([
        AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED,
        AdSourceTypeIds.SERVER_SIDE_TAG_NON_GUARANTEED,
        AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED,
    ])
);

// TODO: remove once API starts returning adSource.type.abbreviation
export const AdSourceTypeAcronyms = {
    [AdSourceTypeIds.TREMOR_NETWORK]: "TN",
    [AdSourceTypeIds.AUTOMATED_GUARANTEED]: "PG",
    [AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED]: "CAG",
    [AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED]: "SSG",
    [AdSourceTypeIds.PRE_BID_DEALS]: "PBD",
    [AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED]: "CSG",
    [AdSourceTypeIds.SERVER_SIDE_DYNAMIC_PRICE]: "SSDP",
    [AdSourceTypeIds.UNRESERVED_FIXED_RATE]: "FP",
    [AdSourceTypeIds.SERVER_SIDE_TAG_NON_GUARANTEED]: "SSNG",
    [AdSourceTypeIds.AUCTION_PRICE]: "AP",
    [AdSourceTypeIds.OPEN_AUCTION]: "OA",
    [AdSourceTypeIds.FALLBACK_TAG]: "TAG",
    [AdSourceTypeIds.MARKETPLACE]: "MP",
    [AdSourceTypeIds.PRE_BID_AD_SOURCE]: "PB",
    [AdSourceTypeIds.DIRECT_AD_SERVING]: "DAS",
    [AdSourceTypeIds.CURATOR_MARKETPLACE]: "CUR",
} as const;

export enum AdSourceTypes {
    PROGRAMATIC_GUARANTEED = "Programmatic Guaranteed",
    CONDITIONAL_PROGRAMATIC_GUARANTEED = "Conditional Programmatic Guaranteed",
    FIXED_PRICE = "Fixed Price",
    AUCTION_PRICE = "Auction Price",
    OPEN_AUCTION = "Open Auction",
    MARKETPLACE = "Marketplace",
    CURATOR_MARKETPLACE = "Curator Marketplace",
    SERVER_SIDE_TAG_GUARANTEED = "Server Side Tag Guaranteed",
    CLIENT_SIDE_TAG_GUARANTEED = "Client Side Tag Guaranteed",
    SERVER_SIDE_DYNAMIC_PRICE = "Server Side Dynamic Price",
    SERVER_SIDE_TAG_NON_GUARANTEED = "Server Side Tag Non-Guaranteed",
    FALLBACK_TAG = "Fallback Tag",
    TREMOR_NETWORK = "Tremor Network",
    PREBID_DEALS_CONTAINER = "Prebid Deals Container",
    PREBID_AD_SOURCE = "Prebid Ad Source",
    LINEAR_FIXED_PRICE = "Linear Fixed Price",
    LINEAR_AUCTION_PRICE = "Linear Auction Price",
    DIRECT_AD_SERVING = "Direct AdServing",
}

export const AD_SOURCE_TYPES_NEXT_UI_NAMES: Record<string, AdSourceTypes> = {
    "Automated Guaranteed Deal": AdSourceTypes.PROGRAMATIC_GUARANTEED,
    "Conditional Automated Guaranteed Deal": AdSourceTypes.CONDITIONAL_PROGRAMATIC_GUARANTEED,
    "Unreserved Fixed Rate Deal": AdSourceTypes.FIXED_PRICE,
    "Invite Only Auction": AdSourceTypes.AUCTION_PRICE,
    "Open Auction": AdSourceTypes.OPEN_AUCTION,
    Marketplace: AdSourceTypes.MARKETPLACE,
    "Curator AdSource": AdSourceTypes.CURATOR_MARKETPLACE,
    "Server-side Tag Guaranteed": AdSourceTypes.SERVER_SIDE_TAG_GUARANTEED,
    "Client-side Tag Guaranteed": AdSourceTypes.CLIENT_SIDE_TAG_GUARANTEED,
    "Server Side Dynamic Price": AdSourceTypes.SERVER_SIDE_DYNAMIC_PRICE,
    "Server-side Tag Non-Guaranteed": AdSourceTypes.SERVER_SIDE_TAG_NON_GUARANTEED,
    "Fallback Tag": AdSourceTypes.FALLBACK_TAG,
    "Tremor Network": AdSourceTypes.TREMOR_NETWORK,
    "Prebid Deals Container": AdSourceTypes.PREBID_DEALS_CONTAINER,
    "Prebid Ad Source": AdSourceTypes.PREBID_AD_SOURCE,
    "Linear Unreserved Fixed Rate Deal": AdSourceTypes.LINEAR_FIXED_PRICE,
    "Linear Invite Only Auction": AdSourceTypes.LINEAR_AUCTION_PRICE,
    "Direct AdServing": AdSourceTypes.DIRECT_AD_SERVING,
};

export const PacingEligibleAdSourceTypeIds: Readonly<Set<number>> = Object.freeze(
    new Set([
        AdSourceTypeIds.AUTOMATED_GUARANTEED,
        AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED,
        AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED,
        AdSourceTypeIds.UNRESERVED_FIXED_RATE,
        AdSourceTypeIds.SERVER_SIDE_TAG_NON_GUARANTEED,
    ])
);

export const PacingTypeEligibleAdSourceTypeIds: Readonly<Set<number>> = Object.freeze(
    new Set([
        AdSourceTypeIds.AUTOMATED_GUARANTEED,
        AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED,
        AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED,
    ])
);

export const PacingDailyImpressionCapEnabledEligibleAdSourceTypeIds: Readonly<Set<number>> = Object.freeze(
    new Set([AdSourceTypeIds.UNRESERVED_FIXED_RATE, AdSourceTypeIds.SERVER_SIDE_TAG_NON_GUARANTEED])
);

export const PacingFallbackOpportunityEstimateEligibleAdSourceTypeIds: Readonly<Set<number>> = Object.freeze(
    new Set([
        AdSourceTypeIds.AUTOMATED_GUARANTEED,
        AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED,
        AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED,
    ])
);

export const PacingPeriodEligibleAdSourceTypeIds: Readonly<Set<number>> = Object.freeze(
    new Set([
        AdSourceTypeIds.AUTOMATED_GUARANTEED,
        AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED,
        AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED,
    ])
);

export const GuaranteedTagAdSourceTypeIds: Readonly<Set<number>> = Object.freeze(
    new Set([AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED, AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED])
);

export enum PricingModels {
    FIXED_PRICE = 1,
    TIERED_PRICE = 2, // deprecated
}

export enum PacingTypes {
    NONE = 1,
    ASAP = 2,
    EVENLY = 3,
    FRONT_LOAD = 4,
    BACK_LOAD = 5,
    CUSTOM = 6,
}

export const DefaultPacingTypeOptions = [
    { id: PacingTypes.ASAP, name: "ASAP" } as const,
    { id: PacingTypes.EVENLY, name: "Evenly" } as const,
    { id: PacingTypes.CUSTOM, name: "Custom" } as const,
    { id: PacingTypes.FRONT_LOAD, name: "Front Load" } as const,
] as const;

export const NonePacingTypeOption = { id: PacingTypes.NONE, name: "None" } as const;

export const NONE_PACING_TYPE_HELP_TEXT =
    "The 'None' Pacing Type is a special mode that will not enforce any pacing control within the Streaming platform. This includes enforcing the booked impression volume. Please only use this mode if controlling pacing from the requesting ad system.";

export enum PacingPeriods {
    FLIGHT = 3,
}

export const FlightPacingPeriod = {
    creationDate: "2015-03-24T19:31:28.000+0000",
    updateDate: "2015-03-24T19:31:28.000+0000",
    id: 3,
    name: "Flight",
    sort: 3,
    entityType: "PacingPeriod",
} as const;

export const UNSPECIFIED_REGION_OPTION = { id: -1, name: "Unspecified" };

export enum DealTypeIds {
    PROGRAMMATIC_GUARANTEED = 1,
    FIXED_PRICE = 2,
    AUCTION_PRICE = 3,
    LEGACY = 4,
}

export enum DealEntityTypes {
    Demand = "DemandDeal",
    Buyer = "BuyerDeal",
    Legacy = "Deal",
}

export const mainStepSectionClassList = css`
    .ant-collapse-expand-icon {
        align-self: center;
    }

    .ant-typography {
        margin-top: 0;
    }

    .ant-collapse-header {
        background-color: ${gray2};
    }

    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
    }

    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
    }
`;

export const cardBodyStyle = {
    padding: "0.75rem 3rem",
};
export const rowContentStyle = {
    marginTop: "0.75rem",
};

export enum AdSourcesHealthHelpKeys {
    Priority = "adsourcedetails.priority",
    Type = "adsourcedetails.type",
    Region = "adsource.region",
    Status = "adsourcedetails.status",
    Targeting = "adsourcedetails.targeting",
    Assignee = "adsourcedetails.assignee",
    Label = "adsourcedetails.labels",
    AdSourceName = "adsource.name",
    NumberOfDeals = "adsourcedetails.dealcount",
    Pacing = "adsourcedetails.pacing",
    CPMRate = "adsourcedetails.cpmrate",
    Skips = "adsourcedetails.skips",
    Tries = "adsourcedetails.tries",
    Fills = "adsourcedetails.fills",
    Impressions = "adsourcedetails.impressions",
    NetRevenue = "adsourcedetails.netrevenue",
    Time = "adsourcedetails.timing",
}

export enum AdSourcesPacingHelpKeys {
    DSP = "deal.demand",
    Status = "dealdetails.status",
    Assignee = "adsource.assignee",
    Label = "adsource.labels",
    AdSourceName = "adsource.name",
    TimeZone = "adsource.timezone",
    StartDate = "adsource.dates",
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    EndDate = "adsource.dates",
    BookedImps = "adsource.bookingvolume",
    Tries = "adsourcedetails.tries",
    Fills = "adsourcedetails.fills",
    Imps = "adsourcedetails.impressions",
}

export enum DemandAcquisitionCostModelId {
    FIXED = 1,
    PERCENTAGE = 2,
}

export const DefaultDemandAcquisitionCostModel = {
    creationDate: "2024-07-02T12:56:38.000+0000",
    updateDate: "2024-07-02T12:56:38.000+0000",
    id: 1,
    name: "Fixed",
    entityType: "EnrichmentCostModel",
} as const;

export const DEFAULT_ENRICHMENT_COST_CURRENCY_CODE = "USD";

export const PriceModelEligibleAdSourceTypeIds = Object.freeze([
    AdSourceTypeIds.AUTOMATED_GUARANTEED,
    AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED,
    AdSourceTypeIds.LINEAR_UNRESERVED_FIXED_RATE,
    AdSourceTypeIds.UNRESERVED_FIXED_RATE,
]);
