import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM, NONE } from "@app/core/components/constants";
import { BrandSupply } from "@app/core/services";
import { GUTTER } from "@app/features/inventory/constants";
import { Form, Row, Col } from "antd";
import { FC } from "react";
import { COL_SIZES, FORM_ITEM_OFFSET } from "../../../constants";
import moment from "moment-timezone";
import { TextWithCopy } from "@app/features/inventory/components/TextWithCopy";

const { Item } = Form;

interface Props {
    supply: BrandSupply;
}
export const General: FC<Props> = ({ supply }) => {
    return (
        <div data-sdet="supply-general">
            <Row gutter={GUTTER}>
                <Col span={12}>
                    <Item label="Name" className={FORM_ITEM_OFFSET}>
                        <TextWithCopy text={supply?.name} notificationEntity="Name" />
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col span={12}>
                    <Item label="Code" className={FORM_ITEM_OFFSET}>
                        <TextWithCopy text={supply?.code} notificationEntity="Code" />
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col span={12}>
                    <Item label="Description" className={FORM_ITEM_OFFSET}>
                        {supply?.description?.length ? supply.description : NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col span={12}>
                    <Item label="Type" className={FORM_ITEM_OFFSET}>
                        {supply?.type?.name ? supply.type.name : NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col span={12}>
                    <Item label="Mode" className={FORM_ITEM_OFFSET}>
                        {supply?.isDynamic ? "Dynamic" : "Static"}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label="Last Update" className={FORM_ITEM_OFFSET}>
                        {supply?.updateDate ? moment(supply.updateDate).format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM) : NONE}
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label="Last Indexed" className={FORM_ITEM_OFFSET}>
                        {/* TODO figure out what data should be here*/}
                        {NONE}
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label="Date Created" className={FORM_ITEM_OFFSET}>
                        {supply?.creationDate
                            ? moment(supply.creationDate).format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM)
                            : NONE}
                    </Item>
                </Col>
            </Row>
        </div>
    );
};
