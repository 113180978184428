import { FC } from "react";
import { Button, Modal, ModalProps, Typography } from "antd";

interface ConfirmationProps extends ModalProps {
    onYes: () => void;
}

export const Confirmation: FC<ConfirmationProps> = ({ open, onOk, onCancel, onYes, confirmLoading }) => {
    return (
        <Modal
            open={open}
            onOk={onOk}
            onCancel={onCancel}
            title="Supply Traffic"
            confirmLoading={confirmLoading}
            footer={[
                <Button key="cancel" onClick={onCancel} disabled={confirmLoading}>
                    No
                </Button>,
                <Button key="delete" type="primary" loading={confirmLoading} onClick={onYes}>
                    Yes
                </Button>,
            ]}
        >
            <Typography.Paragraph>Are you sure you want to block supply traffic?</Typography.Paragraph>
        </Modal>
    );
};
