import { FC } from "react";
import { Col, Form, Row, Typography } from "antd";
import { WEEK_YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE } from "@app/core/components/constants";
import { formatDateInUtc } from "@app/core/utils";
import { SeatThirdPartyPixel } from "@app/core/services";
import { CUSTOM_PIXEL_FORM_ITEMS } from "./constants";
import { CustomPixelDefinitions } from "../CustomPixelDefinitions";

interface Props {
    customPixel: SeatThirdPartyPixel;
    isDetailsPage?: boolean;
}
export const CustomPixelFormReadonly: FC<Props> = ({ customPixel, isDetailsPage = false }) => (
    <Form layout="vertical" wrapperCol={{ xs: 24, md: isDetailsPage ? 12 : 24 }}>
        <Row justify="space-between">
            <Col xs={{ span: 24, order: 1 }} md={{ span: isDetailsPage ? 12 : 24, order: 0 }}>
                <Form.Item label={CUSTOM_PIXEL_FORM_ITEMS.NAME.label} wrapperCol={{ span: 24 }}>
                    <Typography.Text>{customPixel.name}</Typography.Text>
                </Form.Item>
            </Col>
            {isDetailsPage && (
                <Col xs={{ order: 0 }} md={{ order: 1 }}>
                    <CustomPixelDefinitions />
                </Col>
            )}
        </Row>
        <Form.Item label={CUSTOM_PIXEL_FORM_ITEMS.TYPE.label}>{customPixel.type.name}</Form.Item>
        <Form.Item label={CUSTOM_PIXEL_FORM_ITEMS.ADD_TO_ADD_SOURCE_BY_DEFAULT.label}>
            {customPixel.addToAdSourceByDefault ? "Yes" : "No"}
        </Form.Item>
        <Form.Item label={CUSTOM_PIXEL_FORM_ITEMS.PIXEL_URL.label}>
            <Typography.Text>{customPixel.value}</Typography.Text>
        </Form.Item>
        <Form.Item label={CUSTOM_PIXEL_FORM_ITEMS.UPDATED_DATE.label}>
            {formatDateInUtc(customPixel.updateDate, WEEK_YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE)}
        </Form.Item>
        <Form.Item label={CUSTOM_PIXEL_FORM_ITEMS.CREATION_DATE.label}>
            {formatDateInUtc(customPixel.creationDate, WEEK_YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE)}
        </Form.Item>
    </Form>
);
