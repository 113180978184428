import { useState } from "react";

export enum SeatAdSourcesAdServingTab {
    AdCandidateSelection = "adCandidateSelection",
    AdCandidateSelection24Hours = "adCandidateSelection24Hours",
    LiveOpportunityGraph = "liveOpportunityGraph",
}

const DefaultAdServingTab = SeatAdSourcesAdServingTab.LiveOpportunityGraph;

interface UseSeatAdSourcesDetailsAdServing {
    activeTab: string;
    onSwitchTab: (tab: string) => void;
}

export const useSeatAdSourcesDetailsAdServing = (): UseSeatAdSourcesDetailsAdServing => {
    const [activeTab, setActiveTab] = useState<string>(DefaultAdServingTab);

    const onSwitchTab = (tab: string) => {
        setActiveTab(tab);
    };

    return {
        activeTab,
        onSwitchTab,
    };
};
