import { FC } from "react";
import { FilterSelect } from "@app/core/components/FilterSelect";
import { useSeatAdSourcesPacingLabelValueFilter } from "./useSeatAdSourcesPacingLabelValueFilter";

export const SeatAdSourcesPacingLabelValueFilter: FC = () => {
    const { handleChangeValue, options, value, isLoading, disabled } = useSeatAdSourcesPacingLabelValueFilter();

    return (
        <FilterSelect
            label=" "
            onChange={handleChangeValue}
            isLoading={isLoading}
            options={options}
            value={value}
            dataSdet="seat-ad-sources-pacing-label-value-filter"
            mode="multiple"
            disabled={disabled}
            placeholder="Select Values..."
        />
    );
};
