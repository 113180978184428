import { Form, Space, Table, TableColumnsType } from "antd";
import { FC, Key, useEffect, useMemo, useState } from "react";
import { AdUnit } from "@app/core/services";
import { AD_UNIT_FORM_ITEM_COMMON_CONFIG, BOTH_ALLOWED_OPTION, OFF_OPTION, TO_OPTION_MAPPER } from "../../../constants";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    DerivedAdUnit,
    removeAdUnitTouchedRecordInfo,
    selectAdUnitSelectedRows,
    selectAdUnitTouchedRecordsInfo,
    setAdUnitFieldBulkChange,
    setAdUnitFieldChange,
} from "../../../reducer";
import useTableLocalSearch from "@app/core/components/hooks/useTableLocalSearch";
import { BulkOperationEntitiesEditTableTools } from "./BulkOperationEntitiesEditTableTools";
import { BulkOperationBulkEditButtonModal } from "../BulkOperationBulkEditModal/BulkOperationBulkEditButtonModal";
import { getColumnsConfig, getYesNoStringValue } from "../../../helpers";
import { tableCellFormItemStyle } from "@app/features/controls/constants";
import { AD_UNITS_COLUMNS_BASE } from "../../BulkOperationSetupStep/BulkOperationEntitySelectSection/FindOperationEntitiesTab/OperationEntitiesTable";
import { useBulkOperationEntitiesEditTableRevert } from "./useBulkOperationEntitiesEditTableRevert";

const readonlyColumns: TableColumnsType = AD_UNITS_COLUMNS_BASE.slice(1);

const FORM_ITEM_NAME = "adUnitUpdates";

export const getDerivedAdUnitFromApi = (adUnit: AdUnit): DerivedAdUnit => {
    const {
        blockedCreativeAttributes,
        calculatedPublisherReauction,
        impressionWaitTime,
        labelValues,
        linearity,
        mimes,
        publisherReauction,
        skip,
        ssaiType,
        supportedApis,
        supportedProtocols,
        videoDeliveries,
        status,
        placement,
        type,
    } = adUnit;

    return {
        ...adUnit,
        status: TO_OPTION_MAPPER.status(status),
        placement: TO_OPTION_MAPPER.placement(placement),
        supportedProtocols: supportedProtocols?.map(TO_OPTION_MAPPER.supportedProtocols),
        mimes: mimes?.map(TO_OPTION_MAPPER.mimes),
        supportedApis: supportedApis?.map(TO_OPTION_MAPPER.supportedApis),
        ssaiType: ssaiType ? TO_OPTION_MAPPER.ssaiType(ssaiType) : OFF_OPTION,
        impressionWaitTime: impressionWaitTime ? TO_OPTION_MAPPER.impressionWaitTime(impressionWaitTime) : OFF_OPTION,
        blockedCreativeAttributes: blockedCreativeAttributes?.map(TO_OPTION_MAPPER.blockedCreativeAttributes),
        videoDeliveries: videoDeliveries?.map(TO_OPTION_MAPPER.videoDeliveries),
        linearity: linearity ? TO_OPTION_MAPPER.linearity(linearity) : BOTH_ALLOWED_OPTION,
        type: type ? TO_OPTION_MAPPER.type(type) : type,
        labelValues: labelValues?.map(TO_OPTION_MAPPER.labelValues),
        publisherReauction: getYesNoStringValue(
            calculatedPublisherReauction != null ? calculatedPublisherReauction : publisherReauction
        ),
        skip: Boolean(skip),
    };
};
export const BulkOperationAdUnitEntitiesEditTable: FC = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const touchedRecordsInfo = useSelector(selectAdUnitTouchedRecordsInfo);
    const adUnits = useSelector(selectAdUnitSelectedRows);
    const derivedAdUnits = useMemo(() => adUnits.map(getDerivedAdUnitFromApi), [adUnits]);

    const {
        dataSource: filteredItems,
        handleChange,
        value: searchTerm,
    } = useTableLocalSearch<DerivedAdUnit>([(adUnit) => adUnit.name], derivedAdUnits);
    const { handleValueChange, handleRevert, handleRevertAll, hasChangedField } =
        useBulkOperationEntitiesEditTableRevert<DerivedAdUnit>(
            FORM_ITEM_NAME,
            derivedAdUnits,
            touchedRecordsInfo,
            setAdUnitFieldChange,
            removeAdUnitTouchedRecordInfo
        );

    const [{ selectedRowKeys, selectedRows }, setSelectedRowsInfo] = useState<{
        selectedRowKeys: Key[];
        selectedRows: DerivedAdUnit[];
    }>({ selectedRowKeys: [], selectedRows: [] });

    const form = Form.useFormInstance();

    // useMemo to reduce unnecessary useEffect execution
    const { fieldsToEdit, adUnitUpdates } = useMemo(() => form.getFieldsValue(true), [form]);

    // To make sure form values only get initialized on landing, and do not get reset when doing table search
    useEffect(() => {
        const tableFieldsValue = derivedAdUnits.reduce((acc, derivedAdUnit) => {
            // To make sure when going to other step and (or) change selected rows, the other selected rows prev changed values do not get reset
            const prevItemValue = adUnitUpdates?.[derivedAdUnit.id];
            acc[derivedAdUnit.id] = prevItemValue || derivedAdUnit;
            return acc;
        }, {});

        form.setFieldValue("adUnitUpdates", tableFieldsValue);
    }, [derivedAdUnits, adUnitUpdates, form]);

    const editableFields = useMemo(() => {
        const selectedFieldsToEdit = fieldsToEdit?.flatMap((field) =>
            field === "skip" ? ["skip", "skipmin", "skipafter"] : field
        );

        return ["name", ...(selectedFieldsToEdit || [])];
    }, [fieldsToEdit]);

    return (
        <>
            <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                <BulkOperationEntitiesEditTableTools
                    bulkEditCount={selectedRows.length}
                    entityLabel="Ad Unit"
                    entitiesCount={adUnits.length}
                    handleSearch={handleChange}
                    handleRevertAll={handleRevertAll}
                    isDisabledRevertAll={!hasChangedField}
                    searchTerm={searchTerm}
                >
                    <BulkOperationBulkEditButtonModal<DerivedAdUnit>
                        editableFields={editableFields}
                        entityLabel="Ad Unit"
                        formItemConfig={AD_UNIT_FORM_ITEM_COMMON_CONFIG}
                        formItemName={FORM_ITEM_NAME}
                        seatId={Number(seatId)}
                        selectedRows={selectedRows}
                        setBulkChange={setAdUnitFieldBulkChange}
                    />
                </BulkOperationEntitiesEditTableTools>
                <Table
                    className={tableCellFormItemStyle}
                    dataSource={filteredItems}
                    columns={getColumnsConfig<DerivedAdUnit>(
                        Number(seatId),
                        editableFields,
                        AD_UNIT_FORM_ITEM_COMMON_CONFIG,
                        readonlyColumns,
                        FORM_ITEM_NAME,
                        handleValueChange,
                        touchedRecordsInfo,
                        handleRevert
                    )}
                    rowKey="id"
                    rowSelection={{
                        selectedRowKeys,
                        onChange: (selectedRowKeys, selectedRows: DerivedAdUnit[]) =>
                            setSelectedRowsInfo({ selectedRowKeys, selectedRows }),
                    }}
                    showSorterTooltip={false}
                    scroll={{ x: "max-content" }}
                    pagination={false}
                />
            </Space>
        </>
    );
};
