import { useUserAccess } from "@app/core/auth";
import { DefinationField } from "@app/core/components/HelpKeysDrawer";
import { filterDefinitionsFieldsByHelpPathKeys } from "@app/features/inventory/helpers";
import { adUnitDrawerTitles, adUnitHelpKeys, SeatHelpKeys } from "@app/features/inventory/InventorySeat/constants";

interface UseAdUnitFormDefinitionsFields {
    generalDefinitionsFields: DefinationField[];
    adPodDefinitionsFields: DefinationField[];
    labelsDefinitionsFields: DefinationField[];
    UDDefinitionsFields: DefinationField[];
    brandSafetyDefinitionsFields: DefinationField[];
    internalDefinitionsFields: DefinationField[];
    supplyDetailsDefinitionsFields: DefinationField[];
    customPixelsDefinitionsFields: DefinationField[];
    floorsDefinitionsFields: DefinationField[];
    affiliateDefinitionsFields: DefinationField[];
    demandRequirementsDefinitionsFields: DefinationField[];
    playlistConfigurationDefinitionsFields: DefinationField[];
}

const AD_UNIT_GENERAL_HELP_FILEDS = [SeatHelpKeys.AdUnitCode];
const AD_UNIT_SUPPLY_DETAILS_HELP_KEYS = [SeatHelpKeys.DefaultExtendedImpressionWaitTime];
const LABELS_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS: string[] = [SeatHelpKeys.GlobalLabels];
export const useAdUnitFormDefinitionsFields = (): UseAdUnitFormDefinitionsFields => {
    const { isSysAdmin, isPubAcctMgr, hasInternalAccess, isTremorUser } = useUserAccess();

    const generalDefinitionsFields: DefinationField[] =
        isSysAdmin || isPubAcctMgr
            ? adUnitHelpKeys[adUnitDrawerTitles.General]
            : filterDefinitionsFieldsByHelpPathKeys(
                  adUnitHelpKeys[adUnitDrawerTitles.General],
                  AD_UNIT_GENERAL_HELP_FILEDS
              );
    const brandSafetyDefinitionsFields: DefinationField[] = adUnitHelpKeys[adUnitDrawerTitles.BrandSafety];
    const internalDefinitionsFields: DefinationField[] = adUnitHelpKeys[adUnitDrawerTitles.Internal];
    const demandRequirementsDefinitionsFields: DefinationField[] =
        adUnitHelpKeys[adUnitDrawerTitles.DemandRequirements];

    const supplyDetailsDefinitionsFields: DefinationField[] = isTremorUser
        ? adUnitHelpKeys[adUnitDrawerTitles.SupplyDetails]
        : filterDefinitionsFieldsByHelpPathKeys(
              adUnitHelpKeys[adUnitDrawerTitles.SupplyDetails],
              AD_UNIT_SUPPLY_DETAILS_HELP_KEYS
          );
    const adPodDefinitionsFields: DefinationField[] = adUnitHelpKeys[adUnitDrawerTitles.AdPod];
    const playlistConfigurationDefinitionsFields: DefinationField[] =
        adUnitHelpKeys[adUnitDrawerTitles.PlaylistConfiguration];
    const UDDefinitionsFields: DefinationField[] = adUnitHelpKeys[adUnitDrawerTitles.UD];
    const customPixelsDefinitionsFields: DefinationField[] = adUnitHelpKeys[adUnitDrawerTitles.CustomPixels];
    const labelsDefinitionsFields = hasInternalAccess
        ? adUnitHelpKeys[adUnitDrawerTitles.Labels]
        : filterDefinitionsFieldsByHelpPathKeys(
              adUnitHelpKeys[adUnitDrawerTitles.Labels],
              LABELS_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS
          );
    const floorsDefinitionsFields: DefinationField[] = adUnitHelpKeys[adUnitDrawerTitles.Floors];
    const affiliateDefinitionsFields: DefinationField[] = adUnitHelpKeys[adUnitDrawerTitles.Affiliate];
    return {
        UDDefinitionsFields,
        labelsDefinitionsFields,
        adPodDefinitionsFields,
        generalDefinitionsFields,
        internalDefinitionsFields,
        affiliateDefinitionsFields,
        brandSafetyDefinitionsFields,
        floorsDefinitionsFields,
        customPixelsDefinitionsFields,
        supplyDetailsDefinitionsFields,
        demandRequirementsDefinitionsFields,
        playlistConfigurationDefinitionsFields,
    };
};
