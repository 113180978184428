import { Form, Collapse } from "antd";
import { Title } from "@app/core/components";
import { AffixFormButtonsBar } from "../../components/AffixFormButtonsBar";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { INVENTORY_HEADER_COLLAPSE_CLASS_LIST } from "../../InventorySeat/constants";
import { AdvancedFeaturesSection, GeneralSection } from "./PublisherChannelFormSections";
import { usePublisherChannelForm } from "./usePublisherChannelForm";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import { usePublisherChannelFormDefinitionsFields } from "@app/features/inventory/HierarchyForms/PublisherChannelForm/usePublisherChannelFormDefinitionsFields";
import { css, cx } from "@emotion/css";

const classList = cx(INVENTORY_HEADER_COLLAPSE_CLASS_LIST, css(`.ant-collapse-content { border-top: 0;}`));

export const PublisherChannelForm = () => {
    const {
        open,
        form,
        channel,
        loading,
        showModal,
        isDeleting,
        isEditMode,
        handleSubmit,
        handleCancel,
        handleDelete,
        initialValues,
        handleCancelModal,
        submitButtonTitle,
    } = usePublisherChannelForm();

    const { generalDefinitionsFields } = usePublisherChannelFormDefinitionsFields();

    return (
        <Form
            form={form}
            name="brandForm"
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={initialValues}
            scrollToFirstError={{ behavior: "smooth", block: "center" }}
        >
            <Collapse
                bordered
                ghost={false}
                defaultActiveKey={["0", "1"]}
                className={classList}
                collapsible="header"
                items={[
                    {
                        key: "0",
                        children: <GeneralSection />,
                        label: <Title level={5} title="General" />,
                        extra: <HelpKeysDrawer panelLabel="General" definitionsFields={generalDefinitionsFields} />,
                    },
                    {
                        key: "1",
                        children: <AdvancedFeaturesSection />,
                        label: <Title level={5} title="Advanced Features" />,
                    },
                ]}
            />
            <AffixFormButtonsBar
                loading={loading}
                disabled={loading}
                onSubmit={handleSubmit}
                onDelete={showModal}
                onCancel={handleCancel}
                isDeleting={isDeleting}
                isDeleteButtonShown={isEditMode}
                submitButtonTitle={submitButtonTitle}
            />
            <ConfirmationModal
                open={open}
                onOk={handleDelete}
                name={channel?.name}
                onDelete={handleDelete}
                confirmLoading={isDeleting}
                onCancel={handleCancelModal}
            />
        </Form>
    );
};
