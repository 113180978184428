enum DiagnosticsHelpKeys {
    //VastErrors
    SupplyLevel = "inventorystats.vasterrorsupplylevel",
    Network = "inventorystats.vasterrornetwork",

    //VastStats
    MediaFileContentType = "vaststats.contenttypes",
    MediaFileDuration = "vaststats.durations",
    MediaFileCountsPer = "vaststats.countspercreative",
    MediaFileBitRates = "vaststats.bitrates",
    ApiCounts = "vaststats.apicounts",
    VastVersions = "vaststats.versions",
    VastSizes = "vaststats.sizes",

    //UniqueCount
    PlatformFamily = "adstats-publisher.platformFamily",
    Country = "adstats-publisher.country",
}

export const diagnosticsDefinitionsFields = [
    {
        tab: "Vast Errors",
        children: [
            {
                label: "Supply Level",
                helpPath: DiagnosticsHelpKeys.SupplyLevel,
            },
            {
                label: "Network",
                helpPath: DiagnosticsHelpKeys.Network,
            },
        ],
    },
    {
        tab: "Vast Stats",
        children: [
            {
                label: "MediaFile Content Types",
                helpPath: DiagnosticsHelpKeys.MediaFileContentType,
            },
            {
                label: "MediaFile Duration",
                helpPath: DiagnosticsHelpKeys.MediaFileDuration,
            },
            {
                label: "MediaFile Counts pre Creative",
                helpPath: DiagnosticsHelpKeys.MediaFileCountsPer,
            },
            {
                label: "MediaFile BitRates",
                helpPath: DiagnosticsHelpKeys.MediaFileBitRates,
            },
            {
                label: "API Counts",
                helpPath: DiagnosticsHelpKeys.ApiCounts,
            },
            {
                label: "Vast Versions",
                helpPath: DiagnosticsHelpKeys.VastVersions,
            },
            {
                label: "Vast Sizes",
                helpPath: DiagnosticsHelpKeys.VastSizes,
            },
        ],
    },
    {
        tab: "Unique Count",
        children: [
            {
                label: "Platform Family",
                helpPath: DiagnosticsHelpKeys.PlatformFamily,
            },
            {
                label: "Country",
                helpPath: DiagnosticsHelpKeys.Country,
            },
        ],
    },
];
