import { FC } from "react";

interface Props {
    title: string;
}

export const SideNavBetaLabel: FC<Props> = ({ title }) => {
    return (
        <span>
            {title}
            <div style={{ color: "#F6D16B", fontSize: "0.75rem" }}>Beta</div>
        </span>
    );
};
