import { DrawerBreadcrumbHeader, GoToLink, Loading } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ResizableDrawer } from "@rubicon/antd-components";
import { Flex, Typography } from "antd";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { AdvancedBrandSafetyActions } from "../AdvancedBrandSafetyActions";
import { useAdvancedBrandSafetyDetailsDrawer } from "./useAdvancedBrandSafetyDetailsDrawer";
import { HelpKeysDrawerContent } from "@app/core/components/HelpKeysDrawer";
import { HELP_KEYS } from "../constants";
import { AdvancedBrandSafetyReadOnlyForm } from "../AdvancedBrandSafetyForm";
import { drawerRightClosePosition } from "@app/features/controls/constants";

interface DrawerTitleProps {
    seatId: string;
    id: number;
    name: string;
    detailsPageUrl: string;
    postDeleteEvent: () => void;
}
const DrawerTitle: FC<DrawerTitleProps> = ({ name, detailsPageUrl, seatId, id, postDeleteEvent }) => {
    return (
        <Flex vertical gap="small" style={{ marginRight: "1rem" }}>
            <Flex flex="1 1 0" justify="space-between">
                <Flex gap="small" align="center">
                    <Typography.Title
                        level={5}
                        style={{
                            margin: 0,
                            maxWidth: "15rem",
                        }}
                        ellipsis={{ tooltip: { title: name, placement: "bottom" } }}
                    >
                        {name}
                    </Typography.Title>
                    <GoToLink
                        targetPath={detailsPageUrl}
                        goToText="Go to Advanced Brand Safety"
                        tooltipProps={{ title: "Copy Advanced Brand Safety Link" }}
                    />
                </Flex>
            </Flex>
            <AdvancedBrandSafetyActions seatId={seatId} id={id} name={name} postDeleteEvent={postDeleteEvent} />
        </Flex>
    );
};

export const AdvancedBrandSafetyDetailsDrawer = () => {
    const { seatId, id } = useParams<{ seatId: string; id: string }>();
    const {
        open,
        data,
        definitionType,
        handleBack,
        isLoading,
        handleClose,
        handleDefinition,
        handleAfterOpenChange,
        handlePostDeleteEvent,
    } = useAdvancedBrandSafetyDetailsDrawer(seatId, id);

    return (
        <ResizableDrawer
            open={open}
            placement="right"
            title={
                data ? (
                    definitionType ? (
                        <DrawerBreadcrumbHeader
                            onBack={handleBack}
                            parentLabel={data.name}
                            childLabel={HELP_KEYS[definitionType].label}
                        />
                    ) : (
                        <DrawerTitle
                            name={data.name}
                            seatId={seatId}
                            id={Number(id)}
                            detailsPageUrl={ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_DETAILS_PAGE(
                                seatId,
                                id
                            )}
                            postDeleteEvent={handlePostDeleteEvent}
                        />
                    )
                ) : undefined
            }
            width="45%"
            onClose={handleClose}
            afterOpenChange={handleAfterOpenChange}
            className={drawerRightClosePosition}
        >
            {isLoading && <Loading />}
            {definitionType && <HelpKeysDrawerContent definitionsFields={HELP_KEYS[definitionType].helpKeys} />}
            {data && !definitionType && (
                <AdvancedBrandSafetyReadOnlyForm brandSafety={data} handleDefinition={handleDefinition} />
            )}
        </ResizableDrawer>
    );
};
