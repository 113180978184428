export const NAME = "name";
export const CURRENCY = "currency";
export const CPM = "cpm";
export const DEMAND = "demand";
export const BUYER_SEAT = "buyerSeat";
export const STATUS = "status";
export const BUYER_STATUS = "buyerStatus";
export const START_DATE = "startDate";

export const COLUMN_KEY = {
    NAME,
    CURRENCY,
    CPM,
    DEMAND,
    BUYER_SEAT,
    STATUS,
    BUYER_STATUS,
    START_DATE,
};

export const OVERRIDE_CONTENT = "Uses Domain Name Override";
