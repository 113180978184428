import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME, TRANSPARENCY_OVERRIDE_VALUE } from "@app/features/deals/constants";
import { dealFormRadioGroupChange } from "@app/features/deals/DealForm/reducer";
import { DealAdSourceTransparencey } from "@app/features/deals/DealForm/types";
import { OVERRIDE_CONTENT } from "@app/features/deals/AdSource/constants";

interface UseAdSourceSupplyPageContentUrlField {
    onChange: (value: DealAdSourceTransparencey) => void;
    value: DealAdSourceTransparencey;
    isDisabled: boolean;
    overrideText: string | null;
}

export const useAdSourceSupplyPageContentUrlField = (): UseAdSourceSupplyPageContentUrlField => {
    const dispatch = useAppDispatch();
    const allowDomainName = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_DOMAIN_NAME]
    );
    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_SITE_PAGE]
    );
    const isDisabled = allowDomainName === TRANSPARENCY_OVERRIDE_VALUE;
    const overrideText = isDisabled ? OVERRIDE_CONTENT : null;

    const onChange = (value: DealAdSourceTransparencey) => {
        dispatch(dealFormRadioGroupChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_SITE_PAGE, value }));
    };

    return {
        onChange,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value,
        isDisabled,
        overrideText,
    };
};
