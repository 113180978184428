import { ManagedColumn } from "@app/core/components/ManageColumns/ManageColumns";
import {
    INVENTORY_HEALTH_TABLE_COLUMN_WIDTH,
    INVENTORY_HEALTH_TABLE_COLUMN_WIDTH_HALF,
} from "@app/features/inventory/InventorySeat/constants";
import { COLUMN_KEY } from "@app/features/inventory/InventoryHealth/InventoryHealthPage/constants";
import { InventoryHealthInventoryTableActions } from "./InventoryHealthInventoryTableActions/InventoryHealthInventoryTableActions";
import { Seat } from "@app/core/services";
import { useManageInventoryColumnsDrawer } from "./useManageInventoryColumnsDrawer";
import { useEffect, useState } from "react";
import {
    InventoryHealthInventoryFill,
    InventoryHealthInventoryFillRate,
    InventoryHealthInventoryGrossRevenue,
    InventoryHealthInventoryImpressions,
    InventoryHealthInventoryNetRevenue,
    InventoryHealthInventoryRejectedRequests,
    InventoryHealthInventoryRequests,
    InventoryHealthInventoryUseRate,
} from "./InventoryHealthInventoryTableCells";
import {
    InventoryAdStats,
    isAdUnit,
    isBrandOrBrandChannel,
    isPublisherOrPublisherChannel,
    isSeat,
    isSupply,
} from "./useInventoryHealthInventoryTable";
import { NameLink } from "./NameLink";
import { Row } from "antd";
import {
    getManagedColumnPreferences,
    MANAGE_COLUMNS_KEY_INVENTORY_HELATH,
    saveManagedColumnPreferences,
} from "@app/core/components/ManageColumns/useManageColumnsDrawer";

const isEntityTypeNotExpanded = (entityType: string, expandedKeys: string[]) => {
    return expandedKeys.every((key) => {
        const type = key.split("-")[0];
        return type !== entityType;
    });
};

const HALF_OF_COLUMNS = Math.floor((Object.keys(COLUMN_KEY).length - 1) / 2);

export const getTableColumns = (
    context: Seat | null | undefined,
    adStats: InventoryAdStats,
    expandedKeys: string[],
    columnsVisible: Set<string>,
    columnOrder: string[]
) => {
    const isLessVisibleColumns = columnsVisible.size - 1 < HALF_OF_COLUMNS;
    const columns = [
        {
            dataIndex: COLUMN_KEY.NAME,
            title: `Hierarchy for Seat: ${context?.name || ""}`,
            fixed: "left" as const,
            width: isLessVisibleColumns ? "20%" : INVENTORY_HEALTH_TABLE_COLUMN_WIDTH * 3,
            selectable: false,
            visible: true,
            render: (_, entity) => {
                return (
                    <Row justify="start">
                        <NameLink entity={entity} />
                    </Row>
                );
            },
        },
        {
            dataIndex: COLUMN_KEY.ENTITY_TYPE,
            title: "Type",
            width: isLessVisibleColumns
                ? INVENTORY_HEALTH_TABLE_COLUMN_WIDTH_HALF
                : INVENTORY_HEALTH_TABLE_COLUMN_WIDTH,
            selectable: true,
            visible: columnsVisible.has(COLUMN_KEY.ENTITY_TYPE),
            render: (entityType, record) => {
                if (entityType === "AdUnit") {
                    return "Ad Unit";
                }
                if (entityType === "Channel") {
                    if ("seat" in record) {
                        return "Publisher Channel";
                    }
                    if ("publisher" in record) {
                        return "Brand Channel";
                    }
                }
                return entityType;
            },
        },
        {
            dataIndex: COLUMN_KEY.CODE,
            title: "Code",
            width: isLessVisibleColumns
                ? INVENTORY_HEALTH_TABLE_COLUMN_WIDTH_HALF
                : INVENTORY_HEALTH_TABLE_COLUMN_WIDTH,
            selectable: true,
            visible: columnsVisible.has(COLUMN_KEY.CODE),
        },
        {
            dataIndex: COLUMN_KEY.REQUESTS,
            title: "Requests",
            width: isLessVisibleColumns
                ? INVENTORY_HEALTH_TABLE_COLUMN_WIDTH_HALF
                : INVENTORY_HEALTH_TABLE_COLUMN_WIDTH,
            selectable: true,
            visible: columnsVisible.has(COLUMN_KEY.REQUESTS),
            render: (_, entity) => {
                if (isSeat(entity)) {
                    return (
                        <InventoryHealthInventoryRequests
                            showPercentage={false}
                            sourceId={entity.id}
                            adStats={adStats.seat.byId}
                            isConnected={adStats.seat.isConnected}
                        />
                    );
                }
                if (isPublisherOrPublisherChannel(entity)) {
                    return (
                        <InventoryHealthInventoryRequests
                            showPercentage={isEntityTypeNotExpanded("Publisher", expandedKeys)}
                            sourceId={entity.id}
                            adStats={adStats.publisher.byId}
                            isConnected={adStats.publisher.isConnected}
                        />
                    );
                }
                if (isBrandOrBrandChannel(entity)) {
                    return (
                        <InventoryHealthInventoryRequests
                            showPercentage={isEntityTypeNotExpanded("Brand", expandedKeys)}
                            sourceId={entity.id}
                            adStats={adStats.brand.byId}
                            isConnected={adStats.brand.isConnected}
                        />
                    );
                }
                if (isSupply(entity)) {
                    return (
                        <InventoryHealthInventoryRequests
                            showPercentage={isEntityTypeNotExpanded("Supply", expandedKeys)}
                            sourceId={entity.id}
                            adStats={adStats.supply.byId}
                            isConnected={adStats.supply.isConnected}
                        />
                    );
                }
                if (isAdUnit(entity)) {
                    return (
                        <InventoryHealthInventoryRequests
                            showPercentage={isEntityTypeNotExpanded("AdUnit", expandedKeys)}
                            sourceId={entity.id}
                            adStats={adStats.adUnit.byId}
                            isConnected={adStats.adUnit.isConnected}
                        />
                    );
                }
            },
        },
        {
            dataIndex: COLUMN_KEY.REJECTED_REQUESTS,
            title: "Rejected Requests",
            width: isLessVisibleColumns
                ? INVENTORY_HEALTH_TABLE_COLUMN_WIDTH_HALF
                : INVENTORY_HEALTH_TABLE_COLUMN_WIDTH,
            selectable: true,
            visible: columnsVisible.has(COLUMN_KEY.REJECTED_REQUESTS),
            render: (_, entity) => {
                if (isSeat(entity)) {
                    return (
                        <InventoryHealthInventoryRejectedRequests
                            sourceId={entity.id}
                            adStats={adStats.seat.byId}
                            isConnected={adStats.seat.isConnected}
                        />
                    );
                }
                if (isPublisherOrPublisherChannel(entity)) {
                    return (
                        <InventoryHealthInventoryRejectedRequests
                            sourceId={entity.id}
                            adStats={adStats.publisher.byId}
                            isConnected={adStats.publisher.isConnected}
                        />
                    );
                }
                if (isBrandOrBrandChannel(entity)) {
                    return (
                        <InventoryHealthInventoryRejectedRequests
                            sourceId={entity.id}
                            adStats={adStats.brand.byId}
                            isConnected={adStats.brand.isConnected}
                        />
                    );
                }
                if (isSupply(entity)) {
                    return (
                        <InventoryHealthInventoryRejectedRequests
                            sourceId={entity.id}
                            adStats={adStats.supply.byId}
                            isConnected={adStats.supply.isConnected}
                        />
                    );
                }
                if (isAdUnit(entity)) {
                    return (
                        <InventoryHealthInventoryRejectedRequests
                            sourceId={entity.id}
                            adStats={adStats.adUnit.byId}
                            isConnected={adStats.adUnit.isConnected}
                        />
                    );
                }
            },
        },
        {
            dataIndex: COLUMN_KEY.FILL_RATE,
            title: "Fill Rate",
            width: isLessVisibleColumns
                ? INVENTORY_HEALTH_TABLE_COLUMN_WIDTH_HALF
                : INVENTORY_HEALTH_TABLE_COLUMN_WIDTH,
            selectable: true,
            visible: columnsVisible.has(COLUMN_KEY.FILL_RATE),
            render: (_, entity) => {
                if (isSeat(entity)) {
                    return (
                        <InventoryHealthInventoryFillRate
                            sourceId={entity.id}
                            adStats={adStats.seat.byId}
                            isConnected={adStats.seat.isConnected}
                        />
                    );
                }
                if (isPublisherOrPublisherChannel(entity)) {
                    return (
                        <InventoryHealthInventoryFillRate
                            sourceId={entity.id}
                            adStats={adStats.publisher.byId}
                            isConnected={adStats.publisher.isConnected}
                        />
                    );
                }
                if (isBrandOrBrandChannel(entity)) {
                    return (
                        <InventoryHealthInventoryFillRate
                            sourceId={entity.id}
                            adStats={adStats.brand.byId}
                            isConnected={adStats.brand.isConnected}
                        />
                    );
                }
                if (isSupply(entity)) {
                    return (
                        <InventoryHealthInventoryFillRate
                            sourceId={entity.id}
                            adStats={adStats.supply.byId}
                            isConnected={adStats.supply.isConnected}
                        />
                    );
                }
                if (isAdUnit(entity)) {
                    return (
                        <InventoryHealthInventoryFillRate
                            sourceId={entity.id}
                            adStats={adStats.adUnit.byId}
                            isConnected={adStats.adUnit.isConnected}
                        />
                    );
                }
            },
        },
        {
            dataIndex: COLUMN_KEY.USE_RATE,
            title: "Use Rate",
            width: isLessVisibleColumns
                ? INVENTORY_HEALTH_TABLE_COLUMN_WIDTH_HALF
                : INVENTORY_HEALTH_TABLE_COLUMN_WIDTH,
            selectable: true,
            visible: columnsVisible.has(COLUMN_KEY.USE_RATE),
            render: (_, entity) => {
                if (isSeat(entity)) {
                    return (
                        <InventoryHealthInventoryUseRate
                            sourceId={entity.id}
                            adStats={adStats.seat.byId}
                            isConnected={adStats.seat.isConnected}
                        />
                    );
                }
                if (isPublisherOrPublisherChannel(entity)) {
                    return (
                        <InventoryHealthInventoryUseRate
                            sourceId={entity.id}
                            adStats={adStats.publisher.byId}
                            isConnected={adStats.publisher.isConnected}
                        />
                    );
                }
                if (isBrandOrBrandChannel(entity)) {
                    return (
                        <InventoryHealthInventoryUseRate
                            sourceId={entity.id}
                            adStats={adStats.brand.byId}
                            isConnected={adStats.brand.isConnected}
                        />
                    );
                }
                if (isSupply(entity)) {
                    return (
                        <InventoryHealthInventoryUseRate
                            sourceId={entity.id}
                            adStats={adStats.supply.byId}
                            isConnected={adStats.supply.isConnected}
                        />
                    );
                }
                if (isAdUnit(entity)) {
                    return (
                        <InventoryHealthInventoryUseRate
                            sourceId={entity.id}
                            adStats={adStats.adUnit.byId}
                            isConnected={adStats.adUnit.isConnected}
                        />
                    );
                }
            },
        },
        {
            dataIndex: COLUMN_KEY.IMPRESSIONS,
            title: "Impressions",
            width: isLessVisibleColumns
                ? INVENTORY_HEALTH_TABLE_COLUMN_WIDTH_HALF
                : INVENTORY_HEALTH_TABLE_COLUMN_WIDTH,
            selectable: true,
            visible: columnsVisible.has(COLUMN_KEY.IMPRESSIONS),
            render: (_, entity) => {
                if (isSeat(entity)) {
                    return (
                        <InventoryHealthInventoryImpressions
                            showPercentage={false}
                            sourceId={entity.id}
                            adStats={adStats.seat.byId}
                            isConnected={adStats.seat.isConnected}
                        />
                    );
                }
                if (isPublisherOrPublisherChannel(entity)) {
                    return (
                        <InventoryHealthInventoryImpressions
                            showPercentage={isEntityTypeNotExpanded("Publisher", expandedKeys)}
                            sourceId={entity.id}
                            adStats={adStats.publisher.byId}
                            isConnected={adStats.publisher.isConnected}
                        />
                    );
                }
                if (isBrandOrBrandChannel(entity)) {
                    return (
                        <InventoryHealthInventoryImpressions
                            showPercentage={isEntityTypeNotExpanded("Brand", expandedKeys)}
                            sourceId={entity.id}
                            adStats={adStats.brand.byId}
                            isConnected={adStats.brand.isConnected}
                        />
                    );
                }
                if (isSupply(entity)) {
                    return (
                        <InventoryHealthInventoryImpressions
                            showPercentage={isEntityTypeNotExpanded("Brand", expandedKeys)}
                            sourceId={entity.id}
                            adStats={adStats.supply.byId}
                            isConnected={adStats.supply.isConnected}
                        />
                    );
                }
                if (isAdUnit(entity)) {
                    return (
                        <InventoryHealthInventoryImpressions
                            showPercentage={isEntityTypeNotExpanded("AdUnit", expandedKeys)}
                            sourceId={entity.id}
                            adStats={adStats.adUnit.byId}
                            isConnected={adStats.adUnit.isConnected}
                        />
                    );
                }
            },
        },
        {
            dataIndex: COLUMN_KEY.FILLS,
            title: "Fills",
            width: isLessVisibleColumns
                ? INVENTORY_HEALTH_TABLE_COLUMN_WIDTH_HALF
                : INVENTORY_HEALTH_TABLE_COLUMN_WIDTH,
            selectable: true,
            visible: columnsVisible.has(COLUMN_KEY.FILLS),
            render: (_, entity) => {
                if (isSeat(entity)) {
                    return (
                        <InventoryHealthInventoryFill
                            showPercentage={false}
                            sourceId={entity.id}
                            adStats={adStats.seat.byId}
                            isConnected={adStats.seat.isConnected}
                        />
                    );
                }
                if (isPublisherOrPublisherChannel(entity)) {
                    return (
                        <InventoryHealthInventoryFill
                            showPercentage={isEntityTypeNotExpanded("Publisher", expandedKeys)}
                            sourceId={entity.id}
                            adStats={adStats.publisher.byId}
                            isConnected={adStats.publisher.isConnected}
                        />
                    );
                }
                if (isBrandOrBrandChannel(entity)) {
                    return (
                        <InventoryHealthInventoryFill
                            showPercentage={isEntityTypeNotExpanded("Brand", expandedKeys)}
                            sourceId={entity.id}
                            adStats={adStats.brand.byId}
                            isConnected={adStats.brand.isConnected}
                        />
                    );
                }
                if (isSupply(entity)) {
                    return (
                        <InventoryHealthInventoryFill
                            showPercentage={isEntityTypeNotExpanded("Supply", expandedKeys)}
                            sourceId={entity.id}
                            adStats={adStats.supply.byId}
                            isConnected={adStats.supply.isConnected}
                        />
                    );
                }
                if (isAdUnit(entity)) {
                    return (
                        <InventoryHealthInventoryFill
                            showPercentage={isEntityTypeNotExpanded("Brand", expandedKeys)}
                            sourceId={entity.id}
                            adStats={adStats.adUnit.byId}
                            isConnected={adStats.adUnit.isConnected}
                        />
                    );
                }
            },
        },
        {
            dataIndex: COLUMN_KEY.GROSS_REVENUE,
            title: "Gross Revenue",
            width: isLessVisibleColumns
                ? INVENTORY_HEALTH_TABLE_COLUMN_WIDTH
                : INVENTORY_HEALTH_TABLE_COLUMN_WIDTH * 1.5,
            selectable: true,
            visible: columnsVisible.has(COLUMN_KEY.GROSS_REVENUE),
            render: (_, entity) => {
                if (isSeat(entity)) {
                    return (
                        <InventoryHealthInventoryGrossRevenue
                            showPercentage={false}
                            sourceId={entity.id}
                            adStats={adStats.seat.byId}
                            isConnected={adStats.seat.isConnected}
                        />
                    );
                }
                if (isPublisherOrPublisherChannel(entity)) {
                    return (
                        <InventoryHealthInventoryGrossRevenue
                            showPercentage={isEntityTypeNotExpanded("Publisher", expandedKeys)}
                            sourceId={entity.id}
                            adStats={adStats.publisher.byId}
                            isConnected={adStats.publisher.isConnected}
                        />
                    );
                }
                if (isBrandOrBrandChannel(entity)) {
                    return (
                        <InventoryHealthInventoryGrossRevenue
                            showPercentage={isEntityTypeNotExpanded("Brand", expandedKeys)}
                            sourceId={entity.id}
                            adStats={adStats.brand.byId}
                            isConnected={adStats.brand.isConnected}
                        />
                    );
                }
                if (isSupply(entity)) {
                    return (
                        <InventoryHealthInventoryGrossRevenue
                            showPercentage={isEntityTypeNotExpanded("Supply", expandedKeys)}
                            sourceId={entity.id}
                            adStats={adStats.supply.byId}
                            isConnected={adStats.supply.isConnected}
                        />
                    );
                }
                if (isAdUnit(entity)) {
                    return (
                        <InventoryHealthInventoryGrossRevenue
                            showPercentage={isEntityTypeNotExpanded("AdUnit", expandedKeys)}
                            sourceId={entity.id}
                            adStats={adStats.adUnit.byId}
                            isConnected={adStats.adUnit.isConnected}
                        />
                    );
                }
            },
        },
        {
            dataIndex: COLUMN_KEY.NET_REVENUE,
            title: "Net Revenue",
            width: isLessVisibleColumns
                ? INVENTORY_HEALTH_TABLE_COLUMN_WIDTH
                : INVENTORY_HEALTH_TABLE_COLUMN_WIDTH * 1.5,
            selectable: true,
            visible: columnsVisible.has(COLUMN_KEY.NET_REVENUE),
            render: (_, entity) => {
                if (isSeat(entity)) {
                    return (
                        <InventoryHealthInventoryNetRevenue
                            showPercentage={false}
                            sourceId={entity.id}
                            adStats={adStats.seat.byId}
                            isConnected={adStats.seat.isConnected}
                        />
                    );
                }
                if (isPublisherOrPublisherChannel(entity)) {
                    return (
                        <InventoryHealthInventoryNetRevenue
                            showPercentage={isEntityTypeNotExpanded("Publisher", expandedKeys)}
                            sourceId={entity.id}
                            adStats={adStats.publisher.byId}
                            isConnected={adStats.publisher.isConnected}
                        />
                    );
                }
                if (isBrandOrBrandChannel(entity)) {
                    return (
                        <InventoryHealthInventoryNetRevenue
                            showPercentage={isEntityTypeNotExpanded("Brand", expandedKeys)}
                            sourceId={entity.id}
                            adStats={adStats.brand.byId}
                            isConnected={adStats.brand.isConnected}
                        />
                    );
                }
                if (isSupply(entity)) {
                    return (
                        <InventoryHealthInventoryNetRevenue
                            showPercentage={isEntityTypeNotExpanded("Supply", expandedKeys)}
                            sourceId={entity.id}
                            adStats={adStats.supply.byId}
                            isConnected={adStats.supply.isConnected}
                        />
                    );
                }
                if (isAdUnit(entity)) {
                    return (
                        <InventoryHealthInventoryNetRevenue
                            showPercentage={isEntityTypeNotExpanded("AdUnit", expandedKeys)}
                            sourceId={entity.id}
                            adStats={adStats.adUnit.byId}
                            isConnected={adStats.adUnit.isConnected}
                        />
                    );
                }
            },
        },
        {
            dataIndex: COLUMN_KEY.ACTIONS,
            title: "Actions",
            fixed: "right" as const,
            width: isLessVisibleColumns ? "10%" : 134,
            selectable: false,
            visible: true,
            render: (_, entity) => {
                return <InventoryHealthInventoryTableActions context={context} entity={entity} />;
            },
        },
    ];

    columns.sort((a, b) => {
        if (columnOrder.indexOf(a.dataIndex) > columnOrder.indexOf(b.dataIndex) || a.dataIndex === COLUMN_KEY.ACTIONS) {
            return 1;
        }
        if (columnOrder.indexOf(a.dataIndex) < columnOrder.indexOf(b.dataIndex)) {
            return -1;
        }
        return 0;
    });

    return columns;
};
interface UseManageColumns {
    isDrawerOpen: boolean;
    columns: ManagedColumn[];
    onChangeColumns: (columns: ManagedColumn[]) => void;
    handleOpenManageColumnsDrawer: () => void;
    handleCloseManageColumnsDrawer: () => void;
}

export const useManageColumns = (
    context: Seat | null,
    adStats: InventoryAdStats,
    expandedKeys: string[]
): UseManageColumns => {
    const [columnsVisible, setColumnsVisible] = useState<Set<string>>(
        new Set([
            COLUMN_KEY.NAME,
            COLUMN_KEY.ENTITY_TYPE,
            COLUMN_KEY.CODE,
            COLUMN_KEY.REQUESTS,
            COLUMN_KEY.REJECTED_REQUESTS,
            COLUMN_KEY.FILL_RATE,
            COLUMN_KEY.USE_RATE,
            COLUMN_KEY.IMPRESSIONS,
            COLUMN_KEY.FILLS,
            COLUMN_KEY.GROSS_REVENUE,
            COLUMN_KEY.NET_REVENUE,
        ])
    );
    const [columnOrder, setColumnsOrder] = useState<string[]>([
        COLUMN_KEY.NAME,
        COLUMN_KEY.ENTITY_TYPE,
        COLUMN_KEY.CODE,
        COLUMN_KEY.REQUESTS,
        COLUMN_KEY.REJECTED_REQUESTS,
        COLUMN_KEY.FILL_RATE,
        COLUMN_KEY.USE_RATE,
        COLUMN_KEY.IMPRESSIONS,
        COLUMN_KEY.FILLS,
        COLUMN_KEY.GROSS_REVENUE,
        COLUMN_KEY.NET_REVENUE,
    ]);
    const { isDrawerOpen, handleOpenManageColumnsDrawer, handleCloseManageColumnsDrawer } =
        useManageInventoryColumnsDrawer();

    const onChange = (columns: ManagedColumn[]) => {
        const newColumnsVisible = new Set(columns.filter((column) => column.visible).map((column) => column.dataIndex));
        const newColumnsOrder = columns.map((column) => column.dataIndex);
        setColumnsVisible(newColumnsVisible);
        setColumnsOrder(newColumnsOrder);
        saveManagedColumnPreferences(
            MANAGE_COLUMNS_KEY_INVENTORY_HELATH,
            getTableColumns(context, adStats, expandedKeys, newColumnsVisible, newColumnsOrder)
        );
    };

    useEffect(() => {
        const items = localStorage.getItem(MANAGE_COLUMNS_KEY_INVENTORY_HELATH) as string;
        if (items) {
            const loadedColumnsVisible = new Set(
                (JSON.parse(items) as ManagedColumn[])
                    .filter(({ visible, dataIndex }) => visible && dataIndex !== COLUMN_KEY.ACTIONS)
                    .map(({ dataIndex }) => dataIndex)
            );
            setColumnsVisible(loadedColumnsVisible);
        }
    }, []);

    return {
        isDrawerOpen,
        columns: getManagedColumnPreferences(
            MANAGE_COLUMNS_KEY_INVENTORY_HELATH,
            getTableColumns(context, adStats, expandedKeys, columnsVisible, columnOrder)
        ),
        onChangeColumns: onChange,
        handleOpenManageColumnsDrawer,
        handleCloseManageColumnsDrawer,
    };
};
