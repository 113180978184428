import { Currency, useGetCurrenciesQuery } from "@app/core/services";
import { useCallback, useMemo } from "react";
import { LabeledValue } from "antd/lib/select";

interface UseDealCurrencyField {
    options: LabeledValue[];
    optionsByValue: { [value: number]: LabeledValue };
    isFetching: boolean;
    getCurrencyById: (id: number) => Currency | undefined;
}

export const useCurrencyOptions = (): UseDealCurrencyField => {
    const { data, isFetching } = useGetCurrenciesQuery();

    const options = useMemo(() => (data || []).map(({ id, code }) => ({ label: code, value: id })), [data]);
    const optionsByValue = useMemo(
        () =>
            options.reduce((byValue, option) => {
                byValue[option.value] = option;
                return byValue;
            }, {}),
        [options]
    );

    const getCurrencyById = useCallback((id: number) => (data || []).find((currency) => currency.id === id), [data]);

    return {
        options,
        optionsByValue,
        isFetching,
        getCurrencyById,
    };
};
