import { Button, Layout } from "antd";
import { EncryptionKeyCreateWarningModal, useEncryptionKeyCreateWarningModal } from "./EncryptionKeyCreateWarningModal";
import { EncryptionKeyNewKeyCreatedModal, useEncryptionKeyNewKeyCreatedModal } from "./EncryptionKeyNewKeyCreatedModal";
import {
    EncryptionKeyCreateConfirmationModal,
    useEncryptionKeyCreateConfirmationModal,
} from "./EncryptionKeyCreateConfirmationModal";
import { EncryptionKeysTable } from "./EncryptionKeysTable";
import { PageHeader } from "@ant-design/pro-layout";

export const ENCRYPTION_KEYS_PAGE_SDET = "encryption-keys-page";
export const ENCRYPTION_KEYS_PAGE_HEADER_TITLE = "Encryption Keys";
export const CREATE_KEY_BUTTON_SDET = "create-key-button";
export const CREATE_KEY_BUTTON_TITLE = "Create Key";

export const EncryptionKeysPage = () => {
    const { handleOpen: handleNewKeyCreateModalOpen, ...newKeyCreateModalProps } = useEncryptionKeyNewKeyCreatedModal();
    const { handleOpen: handleCreateConfirmationModalOpen, ...createConfirmationModalProps } =
        useEncryptionKeyCreateConfirmationModal(handleNewKeyCreateModalOpen);
    const { handleOpen: handleCreateWarningModalOpen, ...createWarningModalProps } = useEncryptionKeyCreateWarningModal(
        handleCreateConfirmationModalOpen
    );
    return (
        <Layout data-sdet={ENCRYPTION_KEYS_PAGE_SDET}>
            <PageHeader
                title={ENCRYPTION_KEYS_PAGE_HEADER_TITLE}
                extra={
                    <Button data-sdet={CREATE_KEY_BUTTON_SDET} type="primary" onClick={handleCreateWarningModalOpen}>
                        {CREATE_KEY_BUTTON_TITLE}
                    </Button>
                }
            />
            <Layout.Content>
                <EncryptionKeysTable />
            </Layout.Content>
            <EncryptionKeyCreateWarningModal {...createWarningModalProps} />
            <EncryptionKeyCreateConfirmationModal {...createConfirmationModalProps} />
            <EncryptionKeyNewKeyCreatedModal {...newKeyCreateModalProps} />
        </Layout>
    );
};
