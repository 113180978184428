import { RootState } from "@app/core/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum InventoryDetailsDrawerType {
    SEAT = "seat",
    PUBLISHER = "publisher",
    BRAND = "brand",
    SUPPLY = "supply",
    CHANNEL = "channel",
    BRAND_CHANNEL = "brand-channel",
    AD_UNIT = "ad-unit",
}

interface InventoryDetailsDrawerState {
    isOpen: boolean;
    drawerType?: InventoryDetailsDrawerType;
    isPage: boolean;
    pageUrl: string | undefined;
}
export const initialState: InventoryDetailsDrawerState = {
    isOpen: false,
    isPage: false,
    pageUrl: undefined,
    drawerType: undefined,
};

const inventoryDetailsDrawerSlice = createSlice({
    name: "InventoryDetailsDrawer",
    initialState,
    reducers: {
        closeDrawer: (state: InventoryDetailsDrawerState) => {
            state.isOpen = false;
            state.drawerType = undefined;
        },
        closeDrawerWithoutType: (state: InventoryDetailsDrawerState) => {
            state.isOpen = false;
        },
        setPage: (state: InventoryDetailsDrawerState, action: PayloadAction<{ isPage: boolean; pageUrl?: string }>) => {
            const { isPage, pageUrl } = action.payload;
            state.isPage = isPage;
            if (pageUrl) {
                state.pageUrl = pageUrl;
            }
        },
        openDrawer: (state: InventoryDetailsDrawerState) => {
            state.isOpen = true;
        },
        pickDrawerType: (state: InventoryDetailsDrawerState, action: PayloadAction<InventoryDetailsDrawerType>) => {
            state.drawerType = action.payload;
        },
    },
});

export const selectInventoryDetailsDrawerType = (state: RootState) => state.inventoryDetailsDrawer.drawerType;
export const selectIsInventoryDetailsDrawerOpen = (state: RootState) => state.inventoryDetailsDrawer.isOpen;
export const selectIsPageInventoryDetailsDrawer = (state: RootState) => state.inventoryDetailsDrawer.isPage;
export const selectPageUrlInventoryDetailsDrawer = (state: RootState) => state.inventoryDetailsDrawer.pageUrl;

export const { openDrawer, closeDrawer, pickDrawerType, closeDrawerWithoutType, setPage } =
    inventoryDetailsDrawerSlice.actions;
export default inventoryDetailsDrawerSlice.reducer;
