import { FC } from "react";
import { SeatIFrame } from "@app/core/components";
import conf from "@app/core/conf";
import { useLocation } from "react-router-dom";

const matchInventoryPath =
    /(\/publishers\/(?<publisherId>\d+)?|\/channels\/(?<channelId>\d+)?)(\/brand(s)?\/(?<brandId>\d+))?(\/supply\/(?<supplyId>\d+))?(\/adUnit\/(?<adUnitId>\d+))?/;

const getInventoryIFrameUrl = (location, seatId) => {
    const groups = location.pathname.match(matchInventoryPath)?.groups;

    let url = `${conf.mctvConsoleRoot}/ssp/seats/${seatId}/inventory`;

    if (location.pathname.includes("/floors/advanced")) {
        return `${conf.mctvConsoleRoot}/ssp/seats/${seatId}/controlpanel/advancedfloors`;
    }

    if (location.pathname.includes("/inventory/brand-safety/advanced")) {
        return `${conf.mctvConsoleRoot}/ssp/seats/${seatId}/controlpanel/brandsafety/advancedbrandsafeties`;
    }

    if (groups) {
        if (groups.adUnitId) {
            url += `/adunits/${groups.adUnitId}`;
        } else if (groups.supplyId) {
            url += `/supply/${groups.supplyId}`;
        } else if (groups.brandId) {
            url += `/brands/${groups.brandId}`;
        } else if (groups.publisherId) {
            url += `/publishers/${groups.publisherId}`;
        } else if (groups.channelId) {
            url += `/channels/${groups.channelId}`;
        }
    }

    return url;
};

export const InventoryIFrame: FC = () => {
    const location = useLocation();
    return <SeatIFrame src={(seatId) => getInventoryIFrameUrl(location, seatId)} title="Inventory" />;
};
