import {
    consoleApi,
    SEAT_LABELS_TAG,
    LABEL_VALUES_TAG,
    GLOBALS_LABELS_TAG,
    DISTRIBUTION_GROUP_LABELS_TAG,
    SEAT_PREFILTER_LABELS_TAG,
    LABEL_DETAILS_TAG,
    SEAT_LABEL_VALUES_TAG,
} from "./console";
import { CommonPageParams } from "./seats";
import { toQueryString } from "./utils";

export interface Label {
    creationDate: string;
    enablePrefilter: boolean;
    id: number;
    isDistributionGroup?: boolean;
    isPublic?: boolean;
    key: string;
    multiValue: boolean;
    namespace: string;
    updateDate: string;
}

export interface LabelValue {
    creationDate: string;
    updateDate: string;
    id: number;
    label: Label;
    value: string;
}

export interface LabelPayload {
    enablePrefilter: boolean;
    isDistributionGroup: boolean;
    isPublic: boolean;
    key: string;
    multiValue: boolean;
    namespace: string; // `s:${seatId}`
    seatId: number;
}

export interface CreateSeatLabelParams {
    seatId: number;
    body: LabelPayload;
}
export interface UpdateSeatLabelParams {
    labelId: number;
    body: LabelPayload;
}

export interface LabelValuePayload {
    label: { id: number };
    value: string;
}

export const labelsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        createLabelValues: builder.mutation<LabelValue[], LabelValuePayload[]>({
            query: (labelValuePayloads: LabelValuePayload[]) => ({
                url: "/labelValues",
                method: "POST",
                body: labelValuePayloads,
            }),
            invalidatesTags: (_, err) => (err ? [] : [LABEL_VALUES_TAG]),
        }),
        deleteLabelValue: builder.mutation<void, number>({
            query: (labelValueId) => ({
                url: `labelValues/${labelValueId}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, __, ___) => [{ type: LABEL_VALUES_TAG }],
        }),
        getLabelValues: builder.query<LabelValue[], number>({
            query: (labelId: number) => `/labelValues/key/${labelId}`,
            providesTags: (_, __, id) => [{ type: LABEL_VALUES_TAG, id }],
            transformResponse: (response: (Omit<LabelValue, "value"> & { value: string | null })[]) =>
                response.map(({ value, ...restLabelValue }) => ({
                    ...restLabelValue,
                    value: value || "",
                })),
        }),
        createSeatLabel: builder.mutation<Label, CreateSeatLabelParams>({
            query: ({ seatId, body }: CreateSeatLabelParams) => ({
                url: `/seats/${seatId}/labels`,
                method: "POST",
                body,
            }),
            invalidatesTags: (_, err) => (err ? [] : [SEAT_LABELS_TAG]),
        }),
        updateSeatLabel: builder.mutation<Label, UpdateSeatLabelParams>({
            query: ({ labelId, body }: UpdateSeatLabelParams) => ({
                url: `labels/${labelId}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: (_, __, { labelId }) => [
                { type: SEAT_LABELS_TAG },
                { type: LABEL_DETAILS_TAG, id: labelId },
                { type: LABEL_VALUES_TAG, id: labelId },
            ],
        }),
        deleteSeatLabel: builder.mutation<void, number>({
            query: (labelId) => ({
                url: `labels/${labelId}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, __, labelId) => [{ type: SEAT_LABELS_TAG }, { type: LABEL_DETAILS_TAG, id: labelId }],
        }),
        getSeatLabels: builder.query<Label[], { seatId: number; params?: CommonPageParams }>({
            query: ({ seatId, params }) => `/seats/${seatId}/labels;${toQueryString(params || {})}`,
            providesTags: [SEAT_LABELS_TAG],
        }),
        getSeatLabelValues: builder.query<LabelValue[], number>({
            query: (seatId) => `/seats/${seatId}/labelValues`,
            providesTags: [SEAT_LABEL_VALUES_TAG],
        }),
        getSeatLabelDetails: builder.query<Label, number>({
            query: (labelId) => {
                return `labels/${labelId}`;
            },
            providesTags: (_, __, labelId) => [{ type: LABEL_DETAILS_TAG, id: labelId }],
        }),
        getSeatPrefilterLabels: builder.query<Label[], number>({
            query: (seatId: number) => `/seats/${seatId}/prefilterLabels`,
            providesTags: [SEAT_PREFILTER_LABELS_TAG],
        }),
        getDistributionGroupLabels: builder.query<Label[], number>({
            query: (seatId: number) => `/seats/${seatId}/distributionGroupLabels`,
            providesTags: [DISTRIBUTION_GROUP_LABELS_TAG],
        }),
        getGlobalsLabels: builder.query<Label[], void>({
            query: () => "labels/global",
            providesTags: [GLOBALS_LABELS_TAG],
        }),
    }),
    overrideExisting: false,
});

export const {
    useCreateLabelValuesMutation,
    useCreateSeatLabelMutation,
    useDeleteLabelValueMutation,
    useGetDistributionGroupLabelsQuery,
    useGetSeatPrefilterLabelsQuery,
    useGetGlobalsLabelsQuery,
    useGetLabelValuesQuery,
    useLazyGetLabelValuesQuery,
    useGetSeatLabelsQuery,
    useGetSeatLabelValuesQuery,
    useGetSeatLabelDetailsQuery,
    useUpdateSeatLabelMutation,
    useDeleteSeatLabelMutation,
} = labelsApi;
