import {
    AdSourceGrantsParams,
    useCreateAdSourceGrantsMutation,
    useGetAdSourceGrantsQuery,
    useDeleteAdSourceGrantsMutation,
    AdSourceGrants,
} from "@app/core/services";
import { notification } from "antd";
import { FormInstance } from "antd/es/form";
import { useForm } from "antd/lib/form/Form";
import { useState, useEffect } from "react";
import { Permissions } from "@app/core/services/console/types";

enum PERMISSIONS_FORM_FIELDS {
    EMAIL = "emailAddress",
    GROUP = "groupId",
    PERMISSIONS = "permission",
}

const email = "email";
const group = "group";
const permissions: {
    label: string;
    value: string;
}[] = [
    {
        label: "Admin",
        value: Permissions.ADMIN,
    },
    {
        label: "Write",
        value: Permissions.WRITE,
    },
    {
        label: "Read",
        value: Permissions.READ,
    },
];
const COLUMN_KEYS = {
    EMAIL: "email",
    GROUP_NAME: "group_name",
    PERMISSIONS: "permissions",
    ACTIONS: "actions",
};
const columns = [
    {
        dataIndex: COLUMN_KEYS.EMAIL,
        title: "Email",
    },
    {
        dataIndex: COLUMN_KEYS.GROUP_NAME,
        title: "Group Name",
    },
    {
        dataIndex: COLUMN_KEYS.PERMISSIONS,
        title: "Permissions",
    },
    {
        dataIndex: COLUMN_KEYS.ACTIONS,
        title: "",
    },
];

interface UseSeatAdSourcesDetailsPermissions {
    form: FormInstance;
    handleSubmit: () => void;
    handleClear: () => void;
    handleDelete: (emailAddress: string, permission: Permissions, groupId: number | null) => void;
    PERMISSIONS_FORM_FIELDS: typeof PERMISSIONS_FORM_FIELDS;
    COLUMN_KEYS: typeof COLUMN_KEYS;
    email: string;
    group: string;
    isSubmitting: boolean;
    permissions: { label: string; value: string }[];
    columns: { dataIndex: string; title: string; sorter?: boolean }[];
    tableData: AdSourceGrants[];
    isFetchingTableData: boolean;
}

export const useSeatAdSourcesDetailsPermissions = (id: number): UseSeatAdSourcesDetailsPermissions => {
    const [form] = useForm();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [createAdSourceGrants] = useCreateAdSourceGrantsMutation();
    const [deleteAdSourceGrants] = useDeleteAdSourceGrantsMutation();
    const { data: tableData, isFetching: isFetchingTableData, refetch } = useGetAdSourceGrantsQuery(id);
    const handleClear = () => {
        form.resetFields();
    };
    const handleDelete = async (emailAddress: string, permission: Permissions, groupId: number | null) => {
        await deleteAdSourceGrants({
            id: id,
            body: {
                emailAddress,
                permission,
                groupId,
            },
        }).then(() => {
            refetch();
        });
    };
    const handleSubmit = async () => {
        const { emailAddress, groupId, permission }: AdSourceGrantsParams = form.getFieldsValue(
            Object.values(PERMISSIONS_FORM_FIELDS)
        );

        if ((emailAddress || groupId) && permission) {
            setIsSubmitting(true);
            try {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const { data, error } = await createAdSourceGrants({
                    id,
                    body: {
                        emailAddress,
                        groupId,
                        permission,
                    },
                });

                if (data) {
                    refetch();
                }
                if (error) {
                    notification.open({ message: error?.data?.errorDescription });
                }
            } catch (error) {
            } finally {
                setIsSubmitting(false);
            }
        }
    };
    useEffect(() => {
        refetch();
    }, [refetch]);
    return {
        form,
        handleSubmit,
        handleClear,
        handleDelete,
        PERMISSIONS_FORM_FIELDS,
        COLUMN_KEYS,
        email,
        group,
        isSubmitting,
        permissions,
        columns,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tableData,
        isFetchingTableData,
    };
};
