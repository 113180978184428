import { FC } from "react";
import { Table, Button, Tooltip } from "antd";
import { Seat } from "@app/core/services/console";
import { DeleteOutlined } from "@ant-design/icons";
import { ConfirmationModal } from "@app/features/inventory/components/ConfirmationModal";
import { DeleteContactData, useInternalContactsTable } from "./InternalContactsPageEdit/useInternalContactsTable";

interface Props {
    seat: Seat;
}

const getColumns = (handleShowModal: (deleteData: DeleteContactData) => void) => [
    {
        title: "Type",
        dataIndex: "type",
        key: "type",
    },
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (email: string) => <a href={`mailto:${email}`}>{email}</a>,
    },
    {
        title: "Actions",
        dataIndex: "",
        key: "actions",
        render: ({ key, type, name }: { key: number; type: string; name: string }) => (
            <Tooltip title="Delete Internal Contact">
                <Button
                    onClick={() => handleShowModal({ name: `${type}[${name}]`, id: key })}
                    icon={<DeleteOutlined />}
                />
            </Tooltip>
        ),
    },
];

export const InternalContactsTable: FC<Props> = ({ seat }) => {
    const { open, handleDelete, deleteContactName, isDeleting, handleShowModal, handleCancelModal } =
        useInternalContactsTable(seat);
    return (
        <>
            <Table
                size="small"
                columns={getColumns(handleShowModal)}
                dataSource={seat?.internalSeatContacts?.map((contact) => ({
                    key: contact?.id,
                    type: contact?.type?.name,
                    name: contact?.user?.name,
                    email: contact?.user?.emailAddress,
                }))}
            />
            <ConfirmationModal
                open={open}
                onOk={handleDelete}
                name={deleteContactName}
                onDelete={handleDelete}
                confirmLoading={isDeleting}
                onCancel={handleCancelModal}
            />
        </>
    );
};
