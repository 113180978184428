import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { DiagnosticsSupplyPage } from "./DiagnosticsSupplyPage";
import { DiagnosticsLadleResultsPage } from "./DiagnosticsLadleResultsPage";
import { DiagnosticsDealDashboardPage } from "./DiagnosticsDealDashboardPage";
import { DiagnosticsAdvertiserDashboardPage } from "./DiagnosticsAdvertiserDashboardPage";
import { DiagnosticsBidderDiagnosticsPage } from "./DiagnosticsBidderDiagnosticsPage";
import { DiagnosticsLadleResultsAllPage } from "./DiagnosticsLadleResultsAllPage";

export const DiagnosticsSwitch: FC = () => {
    return (
        <Switch>
            <Route exact path={ROUTES.SEAT_DIAGNOSTICS}>
                <DiagnosticsSupplyPage />
            </Route>
            <Route exact path={ROUTES.SEAT_DIAGNOSTICS_LADLE_RESULTS}>
                <DiagnosticsLadleResultsPage />
            </Route>
            <Route exact path={ROUTES.SEAT_DIAGNOSTICS_DEAL_DASHBOARD}>
                <DiagnosticsDealDashboardPage />
            </Route>
            <Route exact path={ROUTES.SEAT_DIAGNOSTICS_ADVERTISER_DASHBOARD}>
                <DiagnosticsAdvertiserDashboardPage />
            </Route>
            <Route exact path={ROUTES.SEAT_DIAGNOSTICS_BIDDER_DIAGNOSTICS}>
                <DiagnosticsBidderDiagnosticsPage />
            </Route>
            <Route exact path={ROUTES.SEAT_DIAGNOSTICS_LADLE_RESULTS_ALL}>
                <DiagnosticsLadleResultsAllPage />
            </Route>
        </Switch>
    );
};
