import { ChangeEvent, useState, useMemo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import debounce from "lodash.debounce";
import { selectDealHealthSearchFilter, setSearchDealHealthFilter } from "@app/features/deals/DealHealthPage/reducer";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { TableSearchInputProps } from "@app/core/components";

export const useDealHealthTableFilterSearch = (): TableSearchInputProps => {
    const dispatch = useAppDispatch();
    const { searchKeyword } = useAppSelector(selectDealHealthSearchFilter);
    const [value, setValue] = useState(searchKeyword);

    const handleSearch = useCallback(
        (v: string): void => {
            dispatch(setSearchDealHealthFilter({ searchKeyword: v.trim() }));
        },
        [dispatch]
    );

    const debouncedHandleSearch = useMemo(
        () => debounce((v: string) => handleSearch(v), FILTER_INPUT_DEBOUNCE_TIME),
        [handleSearch]
    );

    const onReset = (): void => {
        setValue("");
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setValue(e?.target?.value);
        debouncedHandleSearch(e?.target?.value);
    };

    return {
        dataSdet: "deal-health-table-search-filter",
        entityType: "Deal",
        onChange,
        onReset,
        style: { width: "300px" },
        value,
    };
};
