import { Form } from "antd";
import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { useGetSeatsAllowedFmtJsonOptionsQuery } from "@app/core/services";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

const { useWatch } = Form;

interface UseAllowedFMTJSONOptions {
    options: LabeledValue[];
    isFetching: boolean;
}

export const useAllowedFMTJSONOptions = (): UseAllowedFMTJSONOptions => {
    const { data, isFetching } = useGetSeatsAllowedFmtJsonOptionsQuery();
    const fmtJsonOptions: LabeledValue[] = useMemo(
        () =>
            (data || []).map((fmtJsonOptions) => ({
                label: fmtJsonOptions.name,
                value: fmtJsonOptions.id,
            })),
        [data]
    );
    const allowedFMTJSONOptions = useWatch(INVENTORY_FORM_FIELDS.ALLOWED_FMT_JSON_OPTIONS.name) || [];
    const values = allowedFMTJSONOptions.map((option) => option?.value);
    const options = fmtJsonOptions.filter((options) => !values.includes(options.value));

    return {
        options,
        isFetching,
    };
};
