import { css } from "@emotion/css";
import { Button, Col, Form, Row, Space } from "antd";
import { LoadOnScrollSelect } from "@app/core/components";
import { useInternalContactsPage } from "../../useInternalContactsPage";
import {
    INTERNAL_CONTACTS_FORM_FILEDS,
    INTERNAL_CONTACT_IDS,
    useInternalContactsPageEditForm,
} from "./useInternalContactsPageEditForm";

const placeholder = "Select contact...";

const classListFooter = css`
    border-top: 1px solid #d9d9d9;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    height: 52px;
    line-height: 52px;
`;
const classListFooterCol = css`
    position: fixed;
    right: 16px;
`;

export const InternalContactsPageEditForm = () => {
    const {
        initialValues,
        handleSubmit,
        isSubmitting,
        searchValue,
        isFetching,
        onSearch,
        onChange,
        loadMore,
        options,
        hasMore,
        form,
    } = useInternalContactsPageEditForm();
    const { handleClose } = useInternalContactsPage();

    return (
        <Form layout="vertical" form={form} initialValues={initialValues} onFinish={handleSubmit}>
            <Row
                gutter={16}
                className={css`
                    padding: 1.5rem;
                `}
            >
                <Col span={16}>
                    <Form.Item
                        name={INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_TECHNICAL_ACCOUNT.name}
                        label={INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_TECHNICAL_ACCOUNT.label}
                    >
                        <LoadOnScrollSelect
                            allowClear
                            showSearch
                            labelInValue
                            hasMore={hasMore}
                            options={options}
                            loadMore={loadMore}
                            onSearch={onSearch}
                            onChange={(v) =>
                                onChange(
                                    INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_TECHNICAL_ACCOUNT.name,
                                    v,
                                    INTERNAL_CONTACT_IDS.TECHNICAL
                                )
                            }
                            filterOption={false}
                            loading={isFetching}
                            searchValue={searchValue}
                            placeholder={placeholder}
                        />
                    </Form.Item>
                </Col>

                <Col span={16}>
                    <Form.Item
                        name={INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_ACCOUNT.name}
                        label={INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_ACCOUNT.label}
                    >
                        <LoadOnScrollSelect
                            allowClear
                            showSearch
                            labelInValue
                            hasMore={hasMore}
                            options={options}
                            loadMore={loadMore}
                            onSearch={onSearch}
                            onChange={(v) =>
                                onChange(
                                    INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_ACCOUNT.name,
                                    v,
                                    INTERNAL_CONTACT_IDS.ACCOUNT
                                )
                            }
                            filterOption={false}
                            loading={isFetching}
                            searchValue={searchValue}
                            placeholder={placeholder}
                        />
                    </Form.Item>
                </Col>
                <Col span={16}>
                    <Form.Item
                        name={INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_SALES.name}
                        label={INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_SALES.label}
                    >
                        <LoadOnScrollSelect
                            allowClear
                            showSearch
                            labelInValue
                            hasMore={hasMore}
                            options={options}
                            loadMore={loadMore}
                            onSearch={onSearch}
                            onChange={(v) =>
                                onChange(
                                    INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_SALES.name,
                                    v,
                                    INTERNAL_CONTACT_IDS.SALES
                                )
                            }
                            filterOption={false}
                            loading={isFetching}
                            searchValue={searchValue}
                            placeholder={placeholder}
                        />
                    </Form.Item>
                </Col>
                <Col span={16}>
                    <Form.Item
                        name={INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_BILLING.name}
                        label={INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_BILLING.label}
                    >
                        <LoadOnScrollSelect
                            allowClear
                            showSearch
                            labelInValue
                            hasMore={hasMore}
                            options={options}
                            loadMore={loadMore}
                            onSearch={onSearch}
                            onChange={(v) =>
                                onChange(
                                    INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_BILLING.name,
                                    v,
                                    INTERNAL_CONTACT_IDS.BILLING
                                )
                            }
                            filterOption={false}
                            loading={isFetching}
                            searchValue={searchValue}
                            placeholder={placeholder}
                        />
                    </Form.Item>
                </Col>
                <Col span={16}>
                    <Form.Item
                        name={INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_TECHNICAL_ACCOUNT.name}
                        label={INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_TECHNICAL_ACCOUNT.label}
                    >
                        <LoadOnScrollSelect
                            allowClear
                            showSearch
                            labelInValue
                            hasMore={hasMore}
                            options={options}
                            loadMore={loadMore}
                            onSearch={onSearch}
                            onChange={(v) =>
                                onChange(
                                    INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_TECHNICAL_ACCOUNT.name,
                                    v,
                                    INTERNAL_CONTACT_IDS.D_TECHNICAL
                                )
                            }
                            filterOption={false}
                            loading={isFetching}
                            searchValue={searchValue}
                            placeholder={placeholder}
                        />
                    </Form.Item>
                </Col>
                <Col span={16}>
                    <Form.Item
                        name={INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_ACCOUNT.name}
                        label={INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_ACCOUNT.label}
                    >
                        <LoadOnScrollSelect
                            allowClear
                            showSearch
                            labelInValue
                            hasMore={hasMore}
                            options={options}
                            loadMore={loadMore}
                            onSearch={onSearch}
                            onChange={(v) =>
                                onChange(
                                    INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_ACCOUNT.name,
                                    v,
                                    INTERNAL_CONTACT_IDS.D_ACCOUNT
                                )
                            }
                            filterOption={false}
                            loading={isFetching}
                            searchValue={searchValue}
                            placeholder={placeholder}
                        />
                    </Form.Item>
                </Col>
                <Col span={16}>
                    <Form.Item
                        name={INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_SALES.name}
                        label={INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_SALES.label}
                    >
                        <LoadOnScrollSelect
                            allowClear
                            showSearch
                            labelInValue
                            hasMore={hasMore}
                            options={options}
                            loadMore={loadMore}
                            onSearch={onSearch}
                            onChange={(v) =>
                                onChange(
                                    INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_SALES.name,
                                    v,
                                    INTERNAL_CONTACT_IDS.D_SALES
                                )
                            }
                            filterOption={false}
                            loading={isFetching}
                            searchValue={searchValue}
                            placeholder={placeholder}
                        />
                    </Form.Item>
                </Col>
                <Col span={16}>
                    <Form.Item
                        name={INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_BILLING_ACCOUNT.name}
                        label={INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_BILLING_ACCOUNT.label}
                    >
                        <LoadOnScrollSelect
                            allowClear
                            showSearch
                            labelInValue
                            hasMore={hasMore}
                            options={options}
                            loadMore={loadMore}
                            onSearch={onSearch}
                            onChange={(v) =>
                                onChange(
                                    INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_BILLING_ACCOUNT.name,
                                    v,
                                    INTERNAL_CONTACT_IDS.D_BILLING
                                )
                            }
                            filterOption={false}
                            loading={isFetching}
                            searchValue={searchValue}
                            placeholder={placeholder}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className={classListFooter}>
                <Col className={classListFooterCol}>
                    <Space>
                        <Button disabled={isSubmitting} onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" loading={isSubmitting}>
                            Save
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};
