import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME, WATERFALL_PRE_FILTER_OPTIONS } from "@app/features/deals/constants";
import { dealFormRadioGroupChange } from "@app/features/deals/DealForm/reducer";

interface UseAdSourceWaterfall {
    strictMode: boolean;
    handleChangeMode: (isStrictMode: boolean) => void;
    options: { label: string; value: boolean }[];
}

export const useAdSourceWaterfall = (): UseAdSourceWaterfall => {
    const dispatch = useAppDispatch();
    const strictMode = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_STRICT_MODE]
    );
    const handleChangeMode = (value: boolean) => {
        dispatch(dealFormRadioGroupChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_STRICT_MODE, value }));
    };
    return {
        strictMode,
        handleChangeMode,
        options: WATERFALL_PRE_FILTER_OPTIONS,
    };
};
