import { FC } from "react";
import { Select } from "antd";
import { Conditions } from "../constants";

interface Props {
    onChange: (value: Conditions) => void;
    isExcludesDisabled?: boolean;
    isIncludesDisabled?: boolean;
    value: Conditions;
}

export const ConditionSelect: FC<Props> = ({
    onChange,
    isIncludesDisabled = false,
    isExcludesDisabled = false,
    value,
}) => {
    return (
        <Select
            data-sdet="ConditionSelect"
            options={[
                {
                    label: "Include",
                    value: Conditions.Includes,
                    disabled: isIncludesDisabled,
                },
                {
                    label: "Exclude",
                    value: Conditions.Excludes,
                    disabled: isExcludesDisabled,
                },
            ]}
            onChange={onChange}
            style={{ width: "100%" }}
            value={value}
        />
    );
};
