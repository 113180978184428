import { useGetPlatformsQuery } from "@app/core/services";
import { CalculonBannedLocalResponse, useGetCalculonBannedLocalQuery } from "@app/core/services/console";
import { TableColumnProps } from "antd";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { COLUMN_KEY } from "./CalculonStatas";
import { SorterResult } from "antd/lib/table/interface";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { ColumnProps } from "antd/es/table";

const enum SortOrder {
    ASC = "ascend",
    DESC = "descend",
}

const REQUEST_TYPES_MAP = new Map([
    [1, "Web"],
    [2, "App"],
    [3, "SDK"],
]);

const getSortedByKey = (
    arr: TableColumnProps<CalculonBannedLocalResponse>[],
    field = COLUMN_KEY.ADVERTISER,
    order = SortOrder.ASC
) => {
    return arr.sort((l, r) => {
        const lItem = l?.[field];
        const rItem = r?.[field];

        if (typeof lItem === "string") {
            if (order === SortOrder.ASC) {
                return lItem.toLowerCase().localeCompare(rItem.toLowerCase());
            } else {
                return rItem.toLowerCase().localeCompare(lItem.toLowerCase());
            }
        }

        if (typeof lItem === "number") {
            if (order === SortOrder.ASC) {
                return lItem - rItem;
            } else {
                return rItem - lItem;
            }
        }

        return 0;
    });
};

const getFilteredTalbeItems = (field, value?: string) => {
    if (!value) {
        return true;
    }
    const { demand, advertiser, requestType, platform } = field;
    const textForSearch = `${advertiser.trim().toLowerCase()}${requestType.trim().toLowerCase()}${platform
        .trim()
        .toLowerCase()}${demand.trim().toLowerCase()}`;
    return textForSearch.includes(value);
};

interface UseCalculonStats {
    isLoading: boolean;
    tableItems: TableColumnProps<CalculonBannedLocalResponse>[];
    refetch: () => void;
    handleChange: (_: unknown, __: unknown, sorter: SorterResult<ColumnProps<CalculonBannedLocalResponse>>) => void;
    handleSearch: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const useCalculonStats = (id: number): UseCalculonStats => {
    const { data, refetch, isFetching } = useGetCalculonBannedLocalQuery(id);
    const { data: platforms, isFetching: isFetchingPlatforms } = useGetPlatformsQuery();

    const [tableItems, setTableItems] = useState<TableColumnProps<CalculonBannedLocalResponse>[]>([]);
    const [keyword, setKeyword] = useState<string>();
    const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.ASC);
    const [orderBy, setOrderBy] = useState<(typeof COLUMN_KEY)[keyof typeof COLUMN_KEY]>();

    const searchedSortedTableItems = useMemo(() => {
        const searchedItems = tableItems.filter((field) => getFilteredTalbeItems(field, keyword));
        const sortedItems = getSortedByKey(searchedItems, orderBy, sortOrder);
        return sortedItems;
    }, [tableItems, keyword, orderBy, sortOrder]);

    const isLoading = useMemo(() => isFetching || isFetchingPlatforms, [isFetching, isFetchingPlatforms]);

    const handleSearch = useCallback((value: string) => {
        setKeyword(value);
    }, []);

    const debouncedHandleSearch = useMemo(
        () =>
            debounce(
                (event: ChangeEvent<HTMLInputElement>) => handleSearch(event.target.value),
                FILTER_INPUT_DEBOUNCE_TIME
            ),
        [handleSearch]
    );

    const handleChange = (_a, _b, sort): void => {
        const { field, order } = sort;
        setSortOrder(order);
        setOrderBy(field);
    };

    useEffect(() => {
        if (data) {
            setTableItems((prevItems) =>
                (data?.banned || prevItems).map(
                    ({
                        adomain,
                        estImps,
                        estFills,
                        estUseRate,
                        platformId,
                        demandSeatId,
                        requestTypeId,
                        demandSeatName,
                    }) => {
                        const requestTypeName = REQUEST_TYPES_MAP.get(requestTypeId);
                        const platform = platforms?.find(({ id }) => id === platformId)?.name;
                        const useRateToPercent = estUseRate * 100;
                        const percision = estUseRate % 1 === 0 ? 0 : 2;
                        const useRatePercentage = Number(useRateToPercent.toFixed(percision));

                        return {
                            key: (demandSeatId + estImps + estFills + estUseRate).toString(),
                            [COLUMN_KEY.DEMAND]: demandSeatName,
                            [COLUMN_KEY.ADVERTISER]: adomain,
                            [COLUMN_KEY.REQUEST_TYPE]: requestTypeName,
                            [COLUMN_KEY.PLATFORM]: platform,
                            [COLUMN_KEY.EST_FILLS]: estFills,
                            [COLUMN_KEY.EST_IMPS]: estImps,
                            [COLUMN_KEY.USE_RATE]: useRatePercentage,
                        };
                    }
                )
            );
        }
    }, [data, isFetching, platforms]);

    return {
        tableItems: searchedSortedTableItems,
        refetch,
        isLoading,
        handleChange,
        handleSearch: debouncedHandleSearch,
    };
};
