import { FC } from "react";
import { Loading } from "@app/core/components";
import { ResizableDrawer } from "@rubicon/antd-components";
import { SequesteredDomainsDetailsContent } from "./SequesteredDomainsDetailsContent";
import { useSequesteredDomainsDrawer } from "./useSequesteredDomainsDrawer";
import { EDIT_MODE_CANCEL_SDET, EDIT_MODE_SAVE_SDET } from "@app/core/components/EditModeControls";
import { Col, Space, Button } from "antd";
import { drawerRightClosePosition } from "@app/features/controls/constants";

export const SequesteredDomainsDrawer: FC = () => {
    const {
        isLoading,
        data,
        domain,
        handleDrawerClose,
        handleCancelClick,
        handleChange,
        handleCheckAllChange,
        vlOptions,
        handleSubmit,
        indeterminate,
        checkedAll,
        checkedList,
    } = useSequesteredDomainsDrawer();

    isLoading && <Loading />;

    return (
        <ResizableDrawer
            title="Add Advertiser to List"
            open={Boolean(domain)}
            onClose={handleDrawerClose}
            placement="right"
            width="45%"
            className={drawerRightClosePosition}
            footer={
                Boolean(vlOptions.length) && (
                    <Col style={{ flex: 1, textAlign: "right", alignSelf: "center" }}>
                        <Space align="end">
                            <Button data-sdet={EDIT_MODE_CANCEL_SDET} onClick={handleCancelClick}>
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                data-sdet={EDIT_MODE_SAVE_SDET}
                                onClick={handleSubmit}
                                loading={isLoading}
                            >
                                Add
                            </Button>
                        </Space>
                    </Col>
                )
            }
        >
            {data && (
                <SequesteredDomainsDetailsContent
                    handleChange={handleChange}
                    handleCheckAllChange={handleCheckAllChange}
                    vlOptions={vlOptions}
                    checkedAll={checkedAll}
                    checkedList={checkedList}
                    indeterminate={indeterminate}
                    selected={domain}
                />
            )}
        </ResizableDrawer>
    );
};
