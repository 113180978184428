import { FC } from "react";
import { Typography } from "antd";
import { Operations, TargetingDimensionHelpKeys } from "../constants";
import { HelpTooltip } from "@app/core/components";
import { InfoCircleFilled } from "@ant-design/icons";
import { gold6 } from "@rubicon/antd-components";

interface Props {
    operation: Operations;
    targetingBlocksCount: number;
    reusableTargetingBlocksCount: number;
    dealsWithTargeting: number;
}

export const AdSourceTargetingSectionHeader: FC<Props> = ({
    operation,
    targetingBlocksCount,
    reusableTargetingBlocksCount = 0,
    dealsWithTargeting,
}) => {
    return (
        <Typography.Title level={5} style={{ marginBottom: "10px" }}>
            <span>
                Targeting Mode: <span style={{ fontWeight: 400 }}>{operation === Operations.All ? "MERGE" : "OR"}</span>
            </span>

            <span style={{ marginLeft: "20px" }}>
                Targeting Blocks:{" "}
                {reusableTargetingBlocksCount > 0 ? (
                    <span style={{ fontWeight: 400 }}>
                        {targetingBlocksCount} ({reusableTargetingBlocksCount} reusable)
                    </span>
                ) : (
                    <span style={{ fontWeight: 400 }}>{targetingBlocksCount}</span>
                )}
            </span>

            <span style={{ marginLeft: "30px" }}>
                Deals With Targeting: {dealsWithTargeting}
                <span hidden={!dealsWithTargeting}>
                    <HelpTooltip
                        helpKeyList={TargetingDimensionHelpKeys}
                        helpKey={TargetingDimensionHelpKeys.TargetingDealsWithTargeting}
                        tooltipPlacement={"topRight"}
                        popover={true}
                    >
                        &nbsp; <InfoCircleFilled style={{ color: `${gold6}` }} />
                    </HelpTooltip>
                </span>
            </span>
        </Typography.Title>
    );
};
