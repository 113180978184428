import { useGetDealOriginsQuery } from "@app/core/services/console";
import { setFiltersDealOrigin } from "@app/features/deals/DealListPage/reducer";
import { getUseDealTableFilter } from "../getUseDealTableFilter";

export const useDealOriginFilter = getUseDealTableFilter(
    (state) => state.deals.filters.filterDealOrigin,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setFiltersDealOrigin,
    useGetDealOriginsQuery
);
