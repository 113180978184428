import { FC } from "react";
import { Dropdown, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { UsedDimensions, SupplyTypesTargetsDimension } from "@app/features/targeting/types";
import { AddDimensionMenu } from "./AddDimensionMenu";
import { HelpArticle } from "@app/core/services";

interface Props {
    helpArticles: { [key: string]: HelpArticle };
    isLoading: boolean;
    onClick: (key: string) => void;
    supplyTypes: SupplyTypesTargetsDimension;
    usedDimensions: UsedDimensions;
    usedDimensionsAll: UsedDimensions[];
    buttonStyle?: React.CSSProperties;
}

export const AddDimensionButton: FC<Props> = ({
    helpArticles,
    isLoading,
    onClick,
    usedDimensions,
    usedDimensionsAll,
    supplyTypes,
    buttonStyle,
}) => {
    return (
        <Dropdown
            autoFocus
            trigger={["click"]}
            dropdownRender={() => (
                <AddDimensionMenu
                    helpArticles={helpArticles}
                    isLoading={isLoading}
                    usedDimensions={usedDimensions}
                    usedDimensionsAll={usedDimensionsAll}
                    onAddDimension={onClick}
                    supplyTypes={supplyTypes}
                />
            )}
        >
            <Button type="link" style={{ ...buttonStyle }} icon={<PlusOutlined />}>
                Add Dimension
            </Button>
        </Dropdown>
    );
};
