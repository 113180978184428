import { Loading } from "@app/core/components";
import { css } from "@emotion/css";
import { Col, Flex, Row } from "antd";
import { DetailsDrawerLayout } from "../../DetailsDrawerLayout";
import { useInventoryDetailsDrawerUrlId } from "../../useInventoryDetailsDrawerUrlId";
import { useAdsTxtContent } from "./useAdsTxtContent";

export const AdsTxtContent = () => {
    const unitId = useInventoryDetailsDrawerUrlId();
    const { isLoading, breadcrumbs, adsTxtContent } = useAdsTxtContent({ unitId });

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Flex vertical data-sdet="inventory-creative-review-content">
            <DetailsDrawerLayout title="Ads.txt Pub Id" breadcrumbs={breadcrumbs} />
            <Col
                className={css`
                    padding: 1rem 0;
                    width: 100%;
                `}
            >
                <Col style={{ border: "1px solid lightgrey", padding: "1rem", borderRadius: "0.5rem" }}>
                    {adsTxtContent?.split("\n").map((item) => {
                        return <Row key={item}>{item}</Row>;
                    })}
                </Col>
            </Col>
        </Flex>
    );
};
