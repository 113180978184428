import { useGetAdSourceFloorTypesQuery } from "@app/core/services";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { LabeledValue } from "antd/lib/select";
import { useMemo } from "react";

interface UseAdSourceFloorTypeField {
    options: LabeledValue[];
    isLoading: boolean;
    value: number | null;
    handleChange: (value: number) => void;
}

export const useAdSourceFloorTypeField = (): UseAdSourceFloorTypeField => {
    const dispatch = useAppDispatch();
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_FLOOR_TYPE]);
    const { data, isFetching } = useGetAdSourceFloorTypesQuery();
    const options = useMemo(() => (data || []).map(({ name, id }) => ({ label: name, value: id })), [data]);

    const handleChange = (value: number) => {
        dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_FLOOR_TYPE, value }));
    };

    return {
        isLoading: isFetching,
        value,
        options,
        handleChange,
    };
};
