import { FC, useEffect, useRef } from "react";
import { Mix, MixOptions } from "@antv/g2plot";

interface Props {
    chartId: string;
    config: MixOptions;
}

export const MixChart: FC<Props> = ({ chartId, config }) => {
    const mixChartRef = useRef<Mix | null>(null);

    useEffect(() => {
        const mixChart = new Mix(chartId, config);
        mixChartRef.current = mixChart;
        mixChart.render();

        return () => {
            mixChart.destroy();
        };
    }, [config, chartId]);

    return <div id={chartId} />;
};
