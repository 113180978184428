import { FC } from "react";
import { Select } from "antd";
import { LabeledValue } from "antd/lib/select";
import { UsedDimensions } from "@app/features/targeting/types";
import { useSupplyTypes } from "./useSupplyTypes";

interface Props {
    onChange: (value: LabeledValue[]) => void;
    value: LabeledValue[];
    usedDimensions: UsedDimensions;
}

export const SupplyTypesSelect: FC<Props> = ({ onChange, value, usedDimensions }) => {
    const [supplyType] = value;
    const { options, isLoading } = useSupplyTypes(usedDimensions);

    return (
        <Select
            style={{ width: "100%" }}
            loading={isLoading}
            placeholder="Select Values"
            labelInValue
            optionFilterProp="label"
            options={options}
            onChange={({ label, value }: LabeledValue) => onChange([{ label, value }])}
            value={supplyType}
        />
    );
};
