import {
    AdUnit,
    Brand,
    BrandSupply,
    BulkOperationSourceEntity,
    useGetAdUnitQuery,
    useGetBrandByIdQuery,
    useGetPublisherByIdQuery,
    useGetSupplyQuery,
} from "@app/core/services";
import { LabeledValue } from "antd/es/select";

export const useGetEntityLabeledValue = (
    entityType: BulkOperationSourceEntity | null,
    id: number | null
): LabeledValue => {
    let label: string | undefined;

    const { data: publisher } = useGetPublisherByIdQuery(id as number, {
        skip: entityType !== BulkOperationSourceEntity.Publisher || !id,
    });

    const { data: brand } = useGetBrandByIdQuery(id as number, {
        skip: entityType !== BulkOperationSourceEntity.Brand || !id,
    });

    const { data: supply } = useGetSupplyQuery(id as number, {
        skip: entityType !== BulkOperationSourceEntity.Supply || !id,
    });

    const { data: adUnit } = useGetAdUnitQuery(id as number, {
        skip: entityType !== BulkOperationSourceEntity.AdUnit || !id,
    });

    switch (entityType) {
        case BulkOperationSourceEntity.Publisher:
            label = `${publisher?.seat?.name} > ${publisher?.name}`;
            break;
        case BulkOperationSourceEntity.Brand: {
            const { name, publisher } = brand as Brand;
            const { name: publisherName, seat } = publisher;

            label = `${seat?.name} > ${publisherName} > ${name}`;
            break;
        }
        case BulkOperationSourceEntity.Supply: {
            const { name, brand } = supply as BrandSupply;
            const { name: brandName, publisher } = brand;
            const { name: publisherName, seat } = publisher;

            label = `${seat?.name} > ${publisherName} > ${brandName} > ${name}`;
            break;
        }
        case BulkOperationSourceEntity.AdUnit: {
            const { name, supply } = adUnit as AdUnit;
            const { name: supplyName, brand } = supply;
            const { name: brandName, publisher } = brand;
            const { name: publisherName, seat } = publisher;

            label = `${seat?.name} > ${publisherName} > ${brandName} > ${supplyName} > ${name}`;
            break;
        }
    }

    return { value: id, label } as LabeledValue;
};
