import { PageHeader } from "@ant-design/pro-layout";
import { Layout } from "antd";
import { useParams } from "react-router-dom";
import { BuyerSeatListsForm } from "../BuyerSeatListsForm";
import { BuyerSeatListsHelpKeys } from "../BuyerSeatListsHelpKeys";
import { useBuyerSeatListsCreatePage } from "./useBuyerSeatListsCreatePage";

export const BuyerSeatListsCreatePage = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const { handleCancel, handleGoBack, handleSubmit, isSaving } = useBuyerSeatListsCreatePage(seatId);

    return (
        <Layout>
            <PageHeader title={`Buyer Seat New List`} onBack={handleGoBack} />
            <BuyerSeatListsForm
                isSaving={isSaving}
                initialValues={{}}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
            >
                <BuyerSeatListsHelpKeys />
            </BuyerSeatListsForm>
        </Layout>
    );
};
