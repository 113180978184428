import { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Typography } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { InventoryBulkOperationsLink } from "@app/core/components";

export const SupplyCopyPageHeader: FC = () => {
    const { seatId, supplyId } = useParams<{ seatId: string; supplyId: string }>();
    const history = useHistory();
    const onBack = (): void => history.goBack();

    return (
        <PageHeader onBack={onBack} title="Copy Supply">
            <>
                <Typography.Text>
                    Supply will be placed in same parent destination. To do advanced copy, go to{" "}
                </Typography.Text>
                <InventoryBulkOperationsLink seatId={seatId} id={supplyId} entityType="supply" />
            </>
        </PageHeader>
    );
};
