import { FC } from "react";
import { Typography } from "antd";
import { PageHeaderProps } from "@ant-design/pro-layout";
import { useSeatAuthContext } from "@app/core/auth";
import { StyledPageHeader } from "@app/features/inventory/styled";

const InventoryFloorsToolbar: FC<PageHeaderProps> = (props) => {
    const { context } = useSeatAuthContext();
    return (
        <StyledPageHeader {...props} title={`Floors Management: ${context?.name}`}>
            <Typography.Text>Manage or target floors accross inventory and inventory levels</Typography.Text>
        </StyledPageHeader>
    );
};

export default InventoryFloorsToolbar;
