export const DEAL_FORM_FIELDS = {
    DEAL: {
        name: "deal",
        label: "",
    },
    CURRENCY_TYPE: {
        name: "currencyType",
        label: "Transaction Currency",
    },
    ENFORCEMENTS: {
        name: "enforcement",
        label: "Enforcement",
    },
    FREQUENCY_CAPPING_TYPE: {
        name: "freqCappingType",
        label: "Deal Frequency Capping",
    },
    DEAL_FREQ_CAPPINGS: {
        name: "dealFreqCappings",
        label: "",
    },
    BUYER_SEATS: {
        name: "buyerSeats",
        label: "Buyer Seats",
    },
    END_DATE: {
        name: "endDate",
        label: "End Date",
    },
    START_DATE: {
        name: "startDate",
        label: "Start Date",
    },
    DEMAND: {
        name: "network",
        label: "Demand",
    },
    TARGETING: {
        name: "targeting",
        label: "",
    },
    RUN_DATE_MODE: {
        name: "runDateMode",
        label: "Run Date Mode", //UI only
    },
};

export const LEGACY_DEAL_ENTITY_TYPE = "Deal";
