import { FC } from "react";
import { Space, Button, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Floor } from "@app/core/services";
import { DeleteFloorButton } from "@app/features/inventory/InventoryFloors/DeleteFloorButton";

interface Props {
    floor: Floor;
    onEdit: () => void;
}

export const SeatFloorsTableActions: FC<Props> = ({ floor, onEdit }) => {
    return (
        <Space style={{ width: "100%" }}>
            <Button.Group>
                <Tooltip title="Edit Floor">
                    <Button onClick={onEdit} icon={<EditOutlined />} />
                </Tooltip>
                <DeleteFloorButton floor={floor} />
            </Button.Group>
        </Space>
    );
};
