import { Col, Form, Select as AntdSelect } from "antd";
import { useTagTypeFilter } from "./useTagTypeFilter";

export const TAG_TYPE_SDET = "tag-type-filter";

export const TagTypeFilter = () => {
    const { selected, tagTypeOptions, handleTagTypeChange, isLoading } = useTagTypeFilter();
    return (
        <>
            <Col xs={12} sm={5}>
                <Form.Item label="Tag Type">
                    <AntdSelect
                        loading={isLoading}
                        data-sdet={TAG_TYPE_SDET}
                        onChange={handleTagTypeChange}
                        options={tagTypeOptions}
                        value={selected}
                    />
                </Form.Item>
            </Col>
        </>
    );
};

export default TagTypeFilter;
