import { ROUTES } from "@app/core/routing";
import { FC } from "react";
import { Switch, Route } from "react-router-dom";
import {
    InventoryAdvancedBrandSafetyPageForm,
    InventoryAdvancedBrandSafetyPageFormMode,
} from "./InventoryAdvancedBrandSafetyPageForm";
import { InventoryBrandSafetyPage } from "./InventoryBrandSafetyPage";

const InventorySeatSwitch: FC = () => (
    <Switch>
        <Route exact path={ROUTES.SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_CREATE}>
            <InventoryAdvancedBrandSafetyPageForm mode={InventoryAdvancedBrandSafetyPageFormMode.CREATE} />
        </Route>
        <Route exact path={ROUTES.SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_EDIT}>
            <InventoryAdvancedBrandSafetyPageForm mode={InventoryAdvancedBrandSafetyPageFormMode.EDIT} />
        </Route>
        <Route path={ROUTES.SEAT_INVENTORY_BRAND_SAFETY}>
            <InventoryBrandSafetyPage />
        </Route>
    </Switch>
);

export default InventorySeatSwitch;
