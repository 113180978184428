import { FC } from "react";
import { Layout } from "antd";
import { useParams } from "react-router-dom";
import { Footer } from "@app/core/components";
import { PageLoader } from "@rubicon/antd-components";
import { useGetBrandByIdQuery } from "@app/core/services";
import { BrandForm } from "@app/features/inventory/HierarchyForms/BrandForm";
import { BrandFormInstance } from "@app/features/inventory/HierarchyForms/BrandForm/BrandFormInstance";
import { SupplyTrafficAlertBanner } from "@app/features/inventory/components/SupplyTrafficAlertBanner";
import { BrandEditPageHeader } from "@app/features/inventory/HierarchyFormsPages/BrandPages/BrandEditPage/BrandEditPageHeader";
import { useScrollToTopOnMount } from "@app/core/components/hooks";

export const BrandEditPage: FC = () => {
    useScrollToTopOnMount();
    const { brandId } = useParams<{ brandId: string }>();
    const { data, isFetching } = useGetBrandByIdQuery(Number(brandId));

    if (isFetching || !data) return <PageLoader />;

    return (
        <>
            <BrandEditPageHeader brand={data} />
            <SupplyTrafficAlertBanner sourceSelf="Brand" />
            <Layout.Content>
                <BrandFormInstance mode="edit" brand={data}>
                    <BrandForm />
                </BrandFormInstance>
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
