import { PageHeader } from "@ant-design/pro-layout";
import { ChangeHistoryTable } from "@app/core/components/ChangeHistoryTable";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ChangeLogEntityType } from "@app/core/services";
import { Card, Layout } from "antd";
import { useHistory, useParams } from "react-router-dom";

export const TargetingChangeHistoryPage = () => {
    const { seatId, targetingId } = useParams<{ seatId: string; targetingId: string }>();
    const history = useHistory();

    return (
        <Layout data-sdet="targeting-change-history-page">
            <PageHeader
                title="Targeting Change History"
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TARGETING(seatId))}
            />

            <Card>
                <ChangeHistoryTable entityId={Number(targetingId)} entityType={ChangeLogEntityType.Targeting} />
            </Card>
        </Layout>
    );
};
