import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { useWatch } from "antd/lib/form/Form";
import { PRE_FILTER_MODE_OPTIONS, PRE_FILTER_MODE_OPTIONS_DESCRIPTION } from "./constants";

export const usePreFilterModeDescription = () => {
    const preFilterMode = useWatch(AD_SOURCE_FIELDS.WATERFALL_PRE_FILTER_MODE.name);
    const description =
        preFilterMode === false
            ? PRE_FILTER_MODE_OPTIONS_DESCRIPTION[PRE_FILTER_MODE_OPTIONS.ALL_INVENTORY]
            : PRE_FILTER_MODE_OPTIONS_DESCRIPTION[PRE_FILTER_MODE_OPTIONS.MATCH_INVENTORY_LABEL];
    return description;
};
