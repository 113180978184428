import { Select } from "antd";
import { FC } from "react";
import { LabeledValue } from "antd/lib/select";
import { LabelValueOption } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/useSeatDetailsForm";

interface LabelsValuesSelectProps {
    disabled: boolean;
    loading: boolean;
    options: LabelValueOption[];
    value: LabelValueOption | undefined;
    onChange: (value: LabeledValue, option: LabelValueOption) => void;
}

export const LabelsValuesSelect: FC<LabelsValuesSelectProps> = ({ onChange, value, options, disabled, loading }) => {
    return (
        <Select
            allowClear
            showSearch
            value={value}
            options={options}
            loading={loading}
            onChange={onChange}
            disabled={disabled}
            optionFilterProp="label"
            style={{ width: "100%" }}
            autoClearSearchValue={false}
            placeholder="Select Value..."
        />
    );
};
