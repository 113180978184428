import { FC } from "react";
import merge from "lodash.merge";
import { Y_AXIS_MAX_SCALE } from "../constants";
import { XYSeries } from "../types";
import { getScaledMax, mapXYSeriesDataToPlotData } from "../utils/utils";
import { Legend } from "../Legend";
import { useLegend } from "../Legend/useLegend";
import { DualAxesPlotConfig, Plot, PlotTypes } from "../Plot";
import { DefaultOptions, RequiredFields, RequiredOptions } from "./constants";
import { DualAxesChartOptions, DualAxesChartProps } from "./types";

const getDynamicOptions = (dataA: XYSeries[] = [], dataB: XYSeries[] = []): DualAxesChartOptions => ({
    yAxis: {
        yAxisA: {
            max: getScaledMax(
                mapXYSeriesDataToPlotData(dataA, RequiredFields.yAxisA, RequiredFields.seriesA)?.map((d) => d.yAxisA),
                Y_AXIS_MAX_SCALE
            ),
        },
        yAxisB: {
            max: getScaledMax(
                mapXYSeriesDataToPlotData(dataB, RequiredFields.yAxisB, RequiredFields.seriesB)?.map((d) => d.yAxisB),
                1 + (Y_AXIS_MAX_SCALE - 1) / 2
            ),
        },
    },
});

export const DualAxesChart: FC<DualAxesChartProps> = ({ id, chartOptions, dataA = [], dataB = [], legendOptions }) => {
    const { seriesFilters, requiredLegendProps } = useLegend([dataA, dataB]);

    const dualAxesPlotConfg: DualAxesPlotConfig = {
        type: PlotTypes.DualAxes,
        options: merge({}, DefaultOptions, getDynamicOptions(dataA, dataB), chartOptions, RequiredOptions),
        data: [
            mapXYSeriesDataToPlotData(dataA, RequiredFields.yAxisA, RequiredFields.seriesA, seriesFilters),
            mapXYSeriesDataToPlotData(dataB, RequiredFields.yAxisB, RequiredFields.seriesB, seriesFilters),
        ],
    };

    return (
        <div data-sdet="dual-axes-chart">
            <Plot id={id} plotConfig={dualAxesPlotConfg} />
            <Legend {...requiredLegendProps} legendOptions={legendOptions} />
        </div>
    );
};
