import { FC } from "react";
import { Tag, Typography } from "antd";
import { formatTimeDuration } from "@app/features/seatAdSources/SeatAdSourcesForm/DealFormDrawer/DealFormContent/DealFormSections/DemandConfigurationSection/Fields/FrequencyCapping/DealFreqCappingsMultiselect";

interface Props {
    name: string;
    timeDuration: number;
    total: number;
}

export const FrequencyCappingTagItem: FC<Props> = ({ name, timeDuration, total }) => {
    return (
        <Tag color="blue">
            <Typography.Text strong>
                {total} {name} in
            </Typography.Text>{" "}
            <Typography.Text>{formatTimeDuration(timeDuration)}</Typography.Text>
        </Tag>
    );
};
