import { FC } from "react";
import { css } from "@emotion/css";
import { Collapse, Space } from "antd";
import { Title } from "@app/core/components";
import { ItemType } from "rc-collapse/es/interface";
import { brandCobalt } from "@rubicon/antd-components";
import {
    INVENTORY_NESTED_SECTION_CLASSLIST,
    INVENTORY_SECTION_HEADER_COLLAPSE_STYLE,
} from "@app/features/inventory/InventorySeat/constants";
import {
    AdBreakRules,
    AdPodSection,
    BrandSafetySection,
    CustomPixelsSection,
    DefaultsSection,
    DetailedConfigurationSection,
    LabelsSection,
    PlaylistConfigurationSection,
} from "./Sections";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import { useAdvancedFeaturesSection } from "./useAdvancedFeaturesSection";
import { usePublisherChannelFormDefinitionsFields } from "@app/features/inventory/HierarchyForms/PublisherChannelForm/usePublisherChannelFormDefinitionsFields";

const classListLink = css`
    font-weight: normal;
`;
export const AdvancedFeaturesSection: FC = () => {
    const { isPlaylistSectionShown, isPodSectionShown, isAdBreakRulesSectionShown, advancedAdBreaksUrl } =
        useAdvancedFeaturesSection();
    const {
        defaultsDefinitionFields,
        adPodDefinitionFields,
        playlistConfigurationDefinitionFields,
        adBreakRulesDefinitionFields,
        brandSafetyDefinitionFields,
        detailConfigurationDefinitionsFields,
        labelsDefinitionsFields,
        customPixelsDefinitionFields,
    } = usePublisherChannelFormDefinitionsFields();
    return (
        <Collapse
            className={INVENTORY_NESTED_SECTION_CLASSLIST}
            defaultActiveKey={["0", "1", "2", "3", "4", "5", "6", "7"]}
            items={
                [
                    {
                        key: "0",
                        children: <DefaultsSection key="0" />,
                        label: <Title level={5} title="Defaults" />,
                        extra: <HelpKeysDrawer panelLabel="Defaults" definitionsFields={defaultsDefinitionFields} />,
                    },
                    isPodSectionShown && {
                        key: "1",
                        children: <AdPodSection />,
                        label: <Title level={5} title="Ad Pod" />,
                        style: INVENTORY_SECTION_HEADER_COLLAPSE_STYLE,
                        extra: <HelpKeysDrawer panelLabel="Ad Pod" definitionsFields={adPodDefinitionFields} />,
                    },
                    isPlaylistSectionShown && {
                        key: "2",
                        children: <PlaylistConfigurationSection />,
                        label: <Title level={5} title="Playlist Configuration" />,
                        style: INVENTORY_SECTION_HEADER_COLLAPSE_STYLE,
                        extra: (
                            <HelpKeysDrawer
                                panelLabel="Playlist Configuration"
                                definitionsFields={playlistConfigurationDefinitionFields}
                            />
                        ),
                    },
                    isAdBreakRulesSectionShown && {
                        key: "3",
                        children: <AdBreakRules />,
                        label: (
                            <Space size="large">
                                <Title level={5} title=" Ad Break Rules" />
                                <a
                                    target="_blank"
                                    onClick={(e) => e.stopPropagation()}
                                    href={`/#${advancedAdBreaksUrl}`}
                                    rel="noreferrer"
                                    className={classListLink}
                                    style={{ color: brandCobalt }}
                                >
                                    Add Advanced Ad Break Rules
                                </a>
                            </Space>
                        ),
                        style: INVENTORY_SECTION_HEADER_COLLAPSE_STYLE,
                        extra: (
                            <HelpKeysDrawer
                                panelLabel="Ad Break Rules"
                                definitionsFields={adBreakRulesDefinitionFields}
                            />
                        ),
                    },
                    {
                        key: "4",
                        children: <BrandSafetySection />,
                        label: <Title level={5} title="Brand Safety" />,
                        style: INVENTORY_SECTION_HEADER_COLLAPSE_STYLE,
                        extra: (
                            <HelpKeysDrawer panelLabel="Brand Safety" definitionsFields={brandSafetyDefinitionFields} />
                        ),
                    },
                    {
                        key: "5",
                        children: <DetailedConfigurationSection />,
                        style: INVENTORY_SECTION_HEADER_COLLAPSE_STYLE,
                        label: <Title level={5} title="Detailed Configuration" />,
                        extra: (
                            <HelpKeysDrawer
                                panelLabel="Detailed Configuration"
                                definitionsFields={detailConfigurationDefinitionsFields}
                            />
                        ),
                    },
                    {
                        key: "6",
                        children: <CustomPixelsSection />,
                        label: <Title level={5} title="Custom Pixels" />,
                        style: INVENTORY_SECTION_HEADER_COLLAPSE_STYLE,
                        extra: (
                            <HelpKeysDrawer
                                panelLabel="Custom Pixels"
                                definitionsFields={customPixelsDefinitionFields}
                            />
                        ),
                    },
                    {
                        key: "7",
                        children: <LabelsSection />,
                        label: <Title level={5} title="Labels" />,
                        style: INVENTORY_SECTION_HEADER_COLLAPSE_STYLE,
                        extra: <HelpKeysDrawer panelLabel="Labels" definitionsFields={labelsDefinitionsFields} />,
                    },
                ].filter(Boolean) as ItemType[]
            }
        />
    );
};
