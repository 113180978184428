import { AdomainFreqCapping } from "@app/core/services";
import { getDimension } from "@app/features/inventory/helpers";
import { DEFAULT_FILLS, FREQ_CAPS_MINUTES } from "@app/features/inventory/HierarchyForms/constants";
import { Form } from "antd";
import { LabeledValue } from "antd/lib/select";
import { useMemo, useState } from "react";
import { FormNameType } from "./FrequencyCaps";

export const DURATION_FIELD_NAME = "durationValue";
const LIMIT_OF_CAPPING = 8;

export interface LabeledValueOpotions extends AdomainFreqCapping {
    label: string;
    value: number;
}

const { useFormInstance, useWatch } = Form;

interface UseFrequencyCpas {
    hidden: boolean;
    totalValue?: number;
    handleAdd: () => void;
    limitMessage: string | null;
    durationValue?: number;
    isForbiddenAdd: boolean;
    durationSelectValue: LabeledValue;
    combinedOptions: LabeledValueOpotions[];
    handleChangeTotal: (value: number) => void;
    handleChangeDuration: (value: number) => void;
    handleChangeDurationSelect: (value: LabeledValue) => void;
    onChange: (_: unknown, options: LabeledValueOpotions[]) => void;
}

export const useFrequencyCpas = (name: FormNameType): UseFrequencyCpas => {
    const form = useFormInstance();
    const [totalValue, setTotalValue] = useState<number | undefined>();
    const [durationSelectValue, setDurationSelectValue] = useState<LabeledValue>(FREQ_CAPS_MINUTES);
    const durationValue = useWatch(DURATION_FIELD_NAME);
    const combinedOptions: LabeledValueOpotions[] = useWatch(name) ?? [];
    const reachedAllowedLimit = combinedOptions.length === LIMIT_OF_CAPPING;
    const limitMessage = reachedAllowedLimit ? "Reached max allowed limit of capping 8" : null;
    const hidden = !combinedOptions.length;
    const isForbiddenAdd = useMemo(
        () =>
            !totalValue || !durationValue || Number(totalValue) < 0 || Number(durationValue) < 0 || reachedAllowedLimit,
        [totalValue, durationValue, reachedAllowedLimit]
    );

    const handleChangeTotal = (value?: number) => {
        setTotalValue(value);
    };
    const handleChangeDuration = (value?: number) => {
        form.setFieldValue(DURATION_FIELD_NAME, value);
        form.validateFields();
    };
    const handleChangeDurationSelect = (value: LabeledValue) => {
        form.validateFields();
        setDurationSelectValue(value);
    };
    const handleChange = (options: LabeledValueOpotions[]) => {
        form.setFieldValue(name, options);
    };
    const onChange = (_: unknown, options: LabeledValueOpotions[]) => handleChange(options);
    const handleAdd = () => {
        if (!totalValue || !durationValue || reachedAllowedLimit) return;
        form.validateFields();
        const duration: number = durationValue * Number(durationSelectValue.value);

        const newCombinedValueOption = {
            label: `${totalValue} ${DEFAULT_FILLS.label} in ${getDimension(duration)}`,
            value: duration,
            mode: { id: DEFAULT_FILLS.value, name: DEFAULT_FILLS.label },
            timeDurationMin: duration,
            total: totalValue,
            id: "",
        };
        const newCombinedValueOptions = [...combinedOptions, newCombinedValueOption];

        handleChange(newCombinedValueOptions);
        handleChangeTotal();
        handleChangeDuration();
        handleChangeDurationSelect(FREQ_CAPS_MINUTES);
    };

    return {
        hidden,
        onChange,
        handleAdd,
        totalValue,
        limitMessage,
        durationValue,
        isForbiddenAdd,
        combinedOptions,
        handleChangeTotal,
        durationSelectValue,
        handleChangeDuration,
        handleChangeDurationSelect,
    };
};
