import { Form } from "antd";
import { FC } from "react";
import { Seat } from "@app/core/services/console";
import { ConditionalTagRender } from "@app/core/components";
import { Labels } from "@app/features/inventory/components/Fields/Labels";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

interface SeatLabelsProps {
    edit?: boolean;
    seat: Seat;
    name: typeof INVENTORY_FORM_FIELDS.LABELS.name | typeof INVENTORY_FORM_FIELDS.INTERNAL_LABELS.name;
    label: typeof INVENTORY_FORM_FIELDS.LABELS.label | typeof INVENTORY_FORM_FIELDS.INTERNAL_LABELS.label;
}

export const SeatLabels: FC<SeatLabelsProps> = ({ edit = false, seat, name, label }) => {
    return (
        <Form.Item label={label} wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 }}>
            {edit ? <Labels name={name} /> : <ConditionalTagRender data={seat} property={name} />}
        </Form.Item>
    );
};
