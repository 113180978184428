import { SeatAdSourcesType } from "@app/core/services";
import { AD_SOURCE_FIELDS, AD_SOURCE_TYPES_NEXT_UI_NAMES } from "@app/features/seatAdSources/constants";
import { getPricingSectionName } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/getPricingSectionName";
import { Form } from "antd";

const { useWatch } = Form;

export const usePricingSectionName = () => {
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);
    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSourceType?.name];

    return getPricingSectionName(adSourceTypeName);
};
