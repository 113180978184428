import { FC } from "react";
import { Col, Button, Space } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { NotificationAction } from "@app/core/services";
import { isViewReportAction, getReportFromViewReportDetails } from "./helpers";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { downloadReport } from "@app/core/clients/console";

interface Props {
    onClick: () => void;
    action: NotificationAction;
}

export const ViewReportResultsButtons: FC<Props> = ({ action, onClick }) => {
    if (!isViewReportAction(action.name)) {
        return null;
    }

    const report = getReportFromViewReportDetails(action.details);

    if (!report) {
        return null;
    }

    return (
        <Col>
            <Space size="small">
                <Button size="small" onClick={onClick}>
                    {report.reportId ? (
                        <Link
                            to={ROUTE_FORMATTERS.SEAT_REPORTS_SAVED_RESULTS(
                                report.seatId,
                                report.reportId,
                                report.executionCode
                            )}
                        >
                            View Report
                        </Link>
                    ) : (
                        <Link to={ROUTE_FORMATTERS.SEAT_REPORTS_ADHOC_RESULTS(report.seatId, report.executionCode)}>
                            View Report
                        </Link>
                    )}
                </Button>
                <Button
                    icon={<DownloadOutlined />}
                    size="small"
                    onClick={() => downloadReport(report.executionCode, "csv")}
                >
                    CSV
                </Button>
                <Button
                    icon={<DownloadOutlined />}
                    size="small"
                    onClick={() => downloadReport(report.executionCode, "xls")}
                >
                    XLS
                </Button>
            </Space>
        </Col>
    );
};
