import { AdSourcePriceModel, SeatAdSourcesType } from "@app/core/services";
import {
    AdSourceTypes,
    AD_SOURCE_FIELDS,
    AD_SOURCE_TYPES_NEXT_UI_NAMES,
    PricingModels,
} from "@app/features/seatAdSources/constants";
import { Form } from "antd";

const { useWatch } = Form;

export const useIsFieldVisible = () => {
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);

    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSourceType?.name] ?? adSourceType?.name;

    const priceModel = useWatch<AdSourcePriceModel>(AD_SOURCE_FIELDS.PRICE_MODEL.name);
    switch (adSourceTypeName) {
        case AdSourceTypes.PROGRAMATIC_GUARANTEED:
            return priceModel?.id === PricingModels.FIXED_PRICE;
        case AdSourceTypes.FIXED_PRICE:
            return false;
        case AdSourceTypes.AUCTION_PRICE:
            return false;
        case AdSourceTypes.MARKETPLACE:
            return false;
        case AdSourceTypes.SERVER_SIDE_TAG_GUARANTEED:
        case AdSourceTypes.CLIENT_SIDE_TAG_GUARANTEED:
            return true;
        case AdSourceTypes.SERVER_SIDE_DYNAMIC_PRICE:
        case AdSourceTypes.SERVER_SIDE_TAG_NON_GUARANTEED:
        default:
            return false;
    }
};
