import { useEffect } from "react";
import { useSeatAuthContext } from "@app/core/auth";
import { Loading } from "@app/core/components";
import { Flex, Space, Typography } from "antd";
import { BrandSafetyCollapsePanel, BrandSafetyCollapsePanelKeys } from "../BrandSafetyCollapsePanel";
import { usePublisherById } from "@app/features/inventory/InventoryFloors/usePublisherById";
import { useSeatTree } from "../../SeatTree/useSeatTree";
import { useBrandSafetyCollapsePanel } from "./useBrandSafetyCollapsePanel";
import { BrandSafetyHeaderControls } from "../../BrandSafetyHeaderControls";
import { InventoryFloorsBreadcrumb } from "@app/features/inventory/InventoryFloors/InventoryFloorsBreadcrumb";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { INVENTORY_LIST_PAGES } from "@app/features/inventory/constants";

export const BrandSafetyPublisherPage = () => {
    const { context } = useSeatAuthContext();
    const { expandKeys, setSelectedKeys } = useSeatTree();
    const { publisher, isFetching } = usePublisherById();
    const { activeKey, onChange, expandAll, collapseAll } = useBrandSafetyCollapsePanel({
        defaultActiveKey: BrandSafetyCollapsePanelKeys.PUBLISHER,
    });

    useEffect(() => {
        if (context && publisher) {
            expandKeys([`seat-${context.id}`, `publisher-${publisher.id}`]);
            setSelectedKeys([`publisher-${publisher.id}`]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context, publisher]);

    if (!context || isFetching || !publisher) {
        return <Loading />;
    }

    const items = [
        {
            path: ROUTE_FORMATTERS.SEAT_INVENTORY(context.id, INVENTORY_LIST_PAGES.BRAND_SAFETY),
            title: context.name,
        },
        { title: publisher.name },
    ];

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Flex justify="space-between" align="baseline">
                <Space align="baseline">
                    <Typography.Title level={5}>{publisher.entityType} Brand Safety: </Typography.Title>
                    <InventoryFloorsBreadcrumb items={items} />
                </Space>
                <BrandSafetyHeaderControls expandAll={expandAll} collapseAll={collapseAll} />
            </Flex>
            <BrandSafetyCollapsePanel seat={context} publisher={publisher} activeKey={activeKey} onChange={onChange} />
        </Space>
    );
};
