import { useState } from "react";
import { MomentRange } from "./types";
import moment from "moment-timezone";

interface UseLast24HoursMomentRange {
    last24Hours: MomentRange;
    refreshLast24Hours: () => void;
}

const getNewLast24HoursMomentRange = () => ({ start: moment().subtract(1, "day"), end: moment() });

export const useLast24HoursMomentRange = (): UseLast24HoursMomentRange => {
    const [last24Hours, setLast24Hours] = useState<MomentRange>(getNewLast24HoursMomentRange());

    const refreshLast24Hours = () => {
        const newLast24Hours = getNewLast24HoursMomentRange();
        setLast24Hours(newLast24Hours);
    };

    return {
        last24Hours,
        refreshLast24Hours,
    };
};
