import { ROUTE_FORMATTERS } from "@app/core/routing";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { notification } from "antd";
import { useHistory } from "react-router-dom";

interface RedirectErrorTargetingProps {
    error?: FetchBaseQueryError | SerializedError;
    seatId: string;
    targetingId: string;
}
export const useRedirectErrorTargeting = ({ error, seatId, targetingId }: RedirectErrorTargetingProps) => {
    const history = useHistory();
    if (error) {
        notification.error({ message: `There is no data for this targeting id: ${targetingId}` });
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TARGETING(seatId));
    }
};
