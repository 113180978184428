import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { useGetSeatsSupportTiersQuery } from "@app/core/services";

interface UseSupportTier {
    options: LabeledValue[];
    isFetching: boolean;
}

export const useSupportTier = (): UseSupportTier => {
    const { data = [], isFetching } = useGetSeatsSupportTiersQuery();
    const options = useMemo(() => (data || []).map((tier) => ({ value: tier.id, label: tier.name })), [data]);
    return {
        isFetching,
        options,
    };
};
