import { FC } from "react";
import { Select, Form } from "antd";
import { ColProps } from "antd/lib/grid/col";
import { SelectProps } from "antd/lib/select";
import { SSAI_TYPE } from "@app/features/inventory/HierarchyForms/constants";
import { useAdUnitSSAIType } from "@app/features/inventory/components/FormItems/AdUnitSSAIType/useAdUnitSSAIType";

interface AAdUnitSSAITypeProps extends SelectProps {
    name: typeof SSAI_TYPE;
    wrapperCol?: ColProps;
    labelCol?: ColProps;
    label: string;
}

export const AdUnitSSAIType: FC<AAdUnitSSAITypeProps> = ({ label, name, wrapperCol, labelCol, ...rest }) => {
    const { isFetching, options } = useAdUnitSSAIType();

    return (
        <Form.Item name={name} label={label} labelCol={labelCol} wrapperCol={wrapperCol}>
            <Select
                showSearch
                labelInValue
                options={options}
                loading={isFetching}
                optionFilterProp="label"
                autoClearSearchValue={false}
                {...rest}
            />
        </Form.Item>
    );
};
