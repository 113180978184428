import { Floor, useGetSeatAdvancedFloorsQuery } from "@app/core/services";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { clearSearchFilter, selectInventoryFloorAdvancedSearchFilter } from "../InventoryFloorAdvancedPage/reducer";

interface UseDealListTable {
    floors: Floor[];
    isFetching: boolean;
    seatId: string;
}

export const useSeatFloorsAdvancedTable = (): UseDealListTable => {
    const dispatch = useAppDispatch();
    const { seatId } = useParams<{ seatId: string }>();
    const { keyword } = useAppSelector(selectInventoryFloorAdvancedSearchFilter);
    const { data = [], isFetching } = useGetSeatAdvancedFloorsQuery(Number(seatId));

    useEffect(() => {
        return () => {
            dispatch(clearSearchFilter());
        };
    }, [dispatch]);

    const floors = useMemo(
        () => data.filter((floor) => floor.name.toLowerCase().includes(keyword.toLowerCase())),
        [keyword, data]
    );

    return {
        floors,
        isFetching,
        seatId,
    };
};
