import { DebounceUriSearch } from "@app/core/components";
import { Col, Row, Space, Table } from "antd";
import { AUDIT_LOG_TABLE_SDET, getAuditLogColumns, auditLogExpandedRowRender } from "./helpers";
import { css } from "@emotion/css";
import { usePagination, useTableChanges } from "@app/core/components/hooks";
import { useParams } from "react-router-dom";
import { AuditLogDownloadButton } from "./AuditLogDownloadButton";
import { brandHighlightLight } from "@rubicon/antd-components";

export const AuditLogTable = ({ data, isFetching, totalCount }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const { handleChange, paginationConfig } = usePagination({
        totalCount,
        showTotal: false,
    });
    const { onChange } = useTableChanges({
        handlePaginateChange: handleChange,
    });

    return (
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Row justify="space-between">
                <Col>
                    <DebounceUriSearch />
                </Col>
                <Col>
                    <AuditLogDownloadButton data={data} />
                </Col>
            </Row>
            <Table
                data-sdet={AUDIT_LOG_TABLE_SDET}
                className={css`
                    .ant-table-expanded-row > td {
                        background: ${brandHighlightLight} !important;
                        .ant-table-wrapper .ant-table {
                            margin: 0 !important;
                        }
                    }
                `}
                rowKey="id"
                onChange={onChange}
                showSorterTooltip={false}
                columns={getAuditLogColumns(seatId)}
                dataSource={data}
                loading={isFetching}
                expandable={{ expandedRowRender: auditLogExpandedRowRender, expandRowByClick: true }}
                pagination={paginationConfig}
            />
        </Space>
    );
};
