import { FC } from "react";
import { Col, Form } from "antd";
import { AD_SOURCE_FIELDS, COL_SIZES, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { useAdResponsePriceOverrideSelect } from "./useAdResponsePriceOverrideSelect";

export const AdResponsePriceOverrideSelect: FC = () => {
    const { isFetching, isVisible, options } = useAdResponsePriceOverrideSelect();

    if (!isVisible) {
        return null;
    }

    return (
        <Col {...COL_SIZES}>
            <Form.Item
                name={AD_SOURCE_FIELDS.AD_RESPONSE_PRICE_OVERRIDE.name}
                label={AD_SOURCE_FIELDS.AD_RESPONSE_PRICE_OVERRIDE.label}
                data-sdet={`test-${AD_SOURCE_FIELDS.AD_RESPONSE_PRICE_OVERRIDE.name}-field`}
                {...VERTICAL_LAYOUT_FIX}
            >
                <SelectWithValue
                    data-sdet={`test-${AD_SOURCE_FIELDS.AD_RESPONSE_PRICE_OVERRIDE.name}-select`}
                    placeholder={`Select ${AD_SOURCE_FIELDS.AD_RESPONSE_PRICE_OVERRIDE.label}`}
                    loading={isFetching}
                    options={options}
                    optionFilterProp="label"
                    fieldAsLabel={["name"]}
                    fieldAsValue={["id"]}
                />
            </Form.Item>
        </Col>
    );
};
