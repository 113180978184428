import { css } from "@emotion/css";
import { Col, Flex } from "antd";
import { FC } from "react";
import { useDealDetailsDrawer } from "@app/features/deals/DealDetailsDrawer/useDealDetailsDrawer";
import { useDealDetailsDrawerContent } from "@app/features/deals/DealDetailsDrawer/useDealDetailsDrawerContent";
import { DrawerCloseButton } from "@app/core/components";

export const DealDetailsDrawerHeader: FC = ({ children }) => {
    const { closeDealDetailsDrawer } = useDealDetailsDrawer();
    const { secondaryDetailsView } = useDealDetailsDrawerContent();

    return (
        <Flex
            justify="space-between"
            wrap="nowrap"
            gap={16}
            className={css`
                padding: 16px 24px;
                width: 100%;
                ${secondaryDetailsView && "align-items: center"};
                border-bottom: 1px solid rgba(5, 5, 5, 0.06);
            `}
        >
            <Col flex="auto">{children}</Col>
            <Col flex="1rem">
                <DrawerCloseButton onClose={closeDealDetailsDrawer} />
            </Col>
        </Flex>
    );
};
