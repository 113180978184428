import { useState, useMemo, useRef, useEffect, useCallback } from "react";
import { Typography } from "antd";
import { useHistory } from "react-router-dom";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { ROUTE_FORMATTERS } from "@app/core/routing/routes";
import { SearchItem, useSearchQuery } from "@app/core/services/console/search";
import { useSeatAuthContext } from "@app/core/auth";

const generatePublisherOptions = (datum) => {
    return {
        url: ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_PUBLISHER(datum.seat.id, datum.id),
        label: `${datum.entityType}: ${datum.seat.name} > ${datum.name}`,
    };
};

const generateBrandOptions = (datum) => {
    return {
        url: ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_BRAND(datum.publisher.seat.id, datum.id),
        label: `${datum.entityType}: ${datum.publisher.seat.name} > ${datum.publisher.name} > ${datum.name}`,
    };
};

const generateSupplyOptions = (datum) => {
    return {
        url: ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_SUPPLY(datum.brand.publisher.seat.id, datum.id),
        label: `${datum.entityType}: ${datum.brand.publisher.seat.name} >  ${datum.brand.publisher.name} > ${datum.brand.name} > ${datum.name}`,
    };
};

const generateAdUnitOptions = (datum) => {
    return {
        url: ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_AD_UNIT(
            datum.supply.brand.publisher.seat.id,
            datum.id
        ),
        label: `${datum.entityType}: ${datum.supply.brand.publisher.seat.name} >  ${datum.supply.brand.publisher.name} > ${datum.supply.brand.name} > ${datum.supply.name} > ${datum.name}`,
    };
};

export const useInventorySearch = () => {
    const { context } = useSeatAuthContext();
    const history = useHistory();
    const ref = useRef(null);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState<number>(1);
    const [keyword, setKeyword] = useState("");
    const { data, isFetching } = useSearchQuery(
        { keyword, page, max: 25, seatId: context?.id.toString() },
        { skip: !keyword }
    );

    const getOptionConfigurations = (datum: SearchItem) => {
        switch (datum.entityType) {
            case "Publisher":
                return generatePublisherOptions(datum);
            case "Brand":
                return generateBrandOptions(datum);
            case "Supply":
                return generateSupplyOptions(datum);
            case "AdUnit":
                return generateAdUnitOptions(datum);
            default:
                return [];
        }
    };

    const getOptions = useCallback((datum: SearchItem) => {
        const optionConfigurations = getOptionConfigurations(datum);

        return (Array.isArray(optionConfigurations) ? optionConfigurations : [optionConfigurations]).map(
            (configuration) => ({
                label: <Typography.Text>{configuration.label}</Typography.Text>,
                value: configuration.url,
            })
        );
    }, []);

    const options = useMemo(() => {
        return (ref.current || []).concat(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (data || []).map((datum) => getOptions(datum)).flat()
        );
    }, [data, getOptions]);

    const debouncedSetKeyword = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setKeyword]
    );

    const handleChangeSearch = (value: string) => {
        setSearch(value);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref.current = [];
        debouncedSetKeyword(value);
    };

    const handleSearchSelected = (url: string) => {
        history.push(url);
    };

    const handleScroll = ({ target }) => {
        const isEndOfList = target.scrollTop + target.offsetHeight === target.scrollHeight;
        if (isEndOfList && data?.length && !isFetching) {
            setPage(page + 1);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref.current = options;
    }, [options]);

    return {
        handleScroll,
        handleChangeSearch,
        handleSearchSelected,
        isLoading: isFetching,
        options,
        search,
    };
};
