import { FC } from "react";
import { Button, Modal, ModalProps, Typography } from "antd";

interface ConfirmationModalProps extends ModalProps {
    name: string | undefined;
    onDelete: () => void;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
    open,
    onOk,
    name,
    onCancel,
    onDelete,
    confirmLoading,
    title = "Confirmation",
}) => {
    return (
        <Modal
            open={open}
            onOk={onOk}
            title={title}
            onCancel={onCancel}
            confirmLoading={confirmLoading}
            footer={[
                <Button key="cancel" onClick={onCancel} disabled={confirmLoading}>
                    Cancel
                </Button>,
                <Button key="delete" type="primary" danger loading={confirmLoading} onClick={onDelete}>
                    Delete
                </Button>,
            ]}
        >
            <Typography.Paragraph>Are you sure you want to delete?</Typography.Paragraph>
            <Typography.Paragraph strong>{name}</Typography.Paragraph>
        </Modal>
    );
};
