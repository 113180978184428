import { FC } from "react";
import { useAppSelector } from "@app/core/store";
import { getFills, getImpressions, getTotalRevenue, getTries } from "@app/features/adStats";
import { useDealCurrencyConversion } from "@app/features/deals/useDealCurrencyConversion";
import { Col, Row, Space } from "antd";
import { DealsChartStats } from "../DealsLiveChart/DealsLiveChart";
import { TogglableChart } from "@app/core/components/charts/TogglableChart/TogglableChart";
import { FILLS_LABEL, IMPRESSIONS_LABEL, NET_REVENUE_LABEL, TRIES_LABEL } from "@app/core/components/charts/constants";
import { RevenueTypes } from "@app/core/clients/console";
import moment from "moment-timezone";
import { MixCountAreaCurrencyLineChart } from "@app/core/components/charts/DualAxesChart/MixChartWithDualAxesCountAreaCurrencyLineChart";

interface DealsLast24HoursChartProps {
    dealId: number;
    chartId?: string;
    sourceCurrencyCode?: string | undefined;
}

const HISTORY_AD_STAT_SCALE = 300;
const scale = (n) => n / HISTORY_AD_STAT_SCALE || 0;

export const DealsLast24HoursChart: FC<DealsLast24HoursChartProps> = ({
    dealId,
    chartId = "default",
    sourceCurrencyCode,
}) => {
    const { preferredCurrency, currencyConversions, currencyConversionMode } =
        useDealCurrencyConversion(sourceCurrencyCode);
    const revenueType = RevenueTypes.NET_REVENUE;
    const stats = useAppSelector((state) => state.adStats.events.adStatDealHistoryById[dealId]);
    const metricOne = [];
    const metricTwo = [];

    const totalStats: Omit<DealsChartStats, "time"> = {
        skips: 0,
        tries: 0,
        fills: 0,
        impressions: 0,
        netRev: 0,
    };

    if (stats && stats.length > 0) {
        const latestNTime = stats.slice(-1)[0].ntime;
        const firstMomentWithin24Hours = moment(latestNTime).subtract(23, "hours").subtract(59, "minutes");
        stats
            .filter((stat) => moment(stat.ntime).isAfter(firstMomentWithin24Hours))
            .forEach((stat) => {
                const time = moment(stat.ntime).format("HH:mm");
                const tries = getTries(stat);
                const fills = getFills(stat, preferredCurrency, currencyConversionMode);
                const impressions = getImpressions(stat, preferredCurrency, currencyConversionMode);
                const netRev = getTotalRevenue(
                    stat,
                    preferredCurrency,
                    currencyConversions,
                    currencyConversionMode,
                    revenueType
                );

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                metricOne.push({ time, value1: scale(tries), name1: TRIES_LABEL });
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                metricOne.push({ time, value1: scale(fills), name1: FILLS_LABEL });
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                metricOne.push({ time, value1: scale(impressions), name1: IMPRESSIONS_LABEL });

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                metricTwo.push({ time, value2: scale(netRev), name2: NET_REVENUE_LABEL });

                totalStats.skips += 0;
                totalStats.tries += tries;
                totalStats.fills += fills;
                totalStats.impressions += impressions;
                totalStats.netRev += netRev;
            });
    }

    /* Commented out until dev is implemented */
    // const takeSnapshot = () => {
    //     console.log("take snapshot");
    // };

    return (
        <>
            <Row justify="end" gutter={[16, 16]}>
                <Col>
                    <Space size="large">
                        {/* Commented out until dev is implemented */}
                        {/* <Button onClick={takeSnapshot}>Take Snapshot</Button>
                        <Checkbox>My Snapshots</Checkbox> */}
                    </Space>
                </Col>
            </Row>
            <TogglableChart
                metricOne={metricOne}
                metricTwo={metricTwo}
                chartRenderer={(filteredMetricOne, filteredMetricTwo) => (
                    <MixCountAreaCurrencyLineChart
                        height={300}
                        chartId={`last-24hr-chart-${chartId}`}
                        metricOne={filteredMetricOne}
                        metricTwo={filteredMetricTwo}
                        metricOneYAxisTitle="Events / Sec"
                        metricTwoYAxisTitle="Rev / Sec"
                        disablePoints={true}
                    />
                )}
            />
        </>
    );
};
