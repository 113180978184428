import { FC } from "react";
import { Card, Col, Row, Collapse } from "antd";
import { useWatch } from "antd/lib/form/Form";
import { SeatAdSourcesType } from "@app/core/services";
import {
    AD_SOURCE_FIELDS,
    AD_SOURCE_SECTIONS,
    AD_SOURCE_SECTIONS_NAME,
    cardBodyStyle,
    GUTTER,
    mainStepSectionClassList,
} from "../../../constants";
import { DefinitionSidePanel } from "../../DefinitionSidePanel";
import {
    CustomPacingCharts,
    DailyImpressionEnabledRadio,
    DailyImpressionInput,
    DeliveryPercentageInput,
    FallbackOpportunity,
    PacingPeriodSelect,
    PacingRedistributionRadio,
    PacingTypeSelect,
    TargetDatePicker,
} from "./Fields";
import { isPacingEligible } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/pacingUtils";

export const PacingSection: FC = () => {
    const adSourceTypeId = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name)?.id;
    const isShareOfVoiceEnabled = useWatch<boolean>(AD_SOURCE_FIELDS.SHARE_OF_VOICE.name);

    if (!isPacingEligible(adSourceTypeId, isShareOfVoiceEnabled)) {
        return null;
    }

    return (
        <Collapse
            bordered
            ghost={false}
            className={mainStepSectionClassList}
            defaultActiveKey={["1"]}
            items={[
                {
                    key: "1",
                    forceRender: true,
                    label: AD_SOURCE_SECTIONS_NAME.PACING,
                    extra: (
                        <DefinitionSidePanel
                            panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.PACING]}
                            section={AD_SOURCE_SECTIONS.PACING}
                            sectionSelector="#adSourceForm"
                        />
                    ),
                    children: (
                        <Card bordered={false} styles={{ body: cardBodyStyle }}>
                            <Row gutter={GUTTER}>
                                <Col span={8}>
                                    <Row gutter={GUTTER}>
                                        <Col span={24}>
                                            <PacingTypeSelect />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col span={24}>
                                            <PacingPeriodSelect />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col span={24}>
                                            <PacingRedistributionRadio />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col span={24}>
                                            <DailyImpressionEnabledRadio />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col span={24}>
                                            <DailyImpressionInput />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col span={24}>
                                            <FallbackOpportunity />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col span={24}>
                                            <TargetDatePicker />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col span={24}>
                                            <DeliveryPercentageInput />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={16}>
                                    <Row gutter={GUTTER}>
                                        <CustomPacingCharts />
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    ),
                },
            ]}
        />
    );
};
