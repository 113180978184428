import { toQueryString } from "./utils";
import { consoleApi, RECYCLE_BIN_TAG } from "./console";
import { EntityRaw, ENTITY_QUERY_TYPES } from "@app/features/controls/recycleBin/RecycleBinTable/types";

export const recycleBinApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getDeletedEntitites: builder.query<
            EntityRaw[],
            {
                id: number;
                entity: ENTITY_QUERY_TYPES;
                page?: number;
                keyword?: string;
                max: number;
                typeId?: number;
            }
        >({
            query: ({ id: seatId, entity, page = 1, keyword, ...rest }) =>
                `${entity}/deleted;${toQueryString({ seatId, page, keyword, ...rest })}`,
            providesTags: [RECYCLE_BIN_TAG],
            keepUnusedDataFor: 0,
        }),
        restoreEntity: builder.mutation<unknown, { entity: ENTITY_QUERY_TYPES; id: number }>({
            query: ({ entity, id }) => ({
                url: `${entity}/recover/${id}`,
                method: "PATCH",
            }),
            invalidatesTags: [RECYCLE_BIN_TAG],
        }),
    }),
    overrideExisting: false,
});

export const { useGetDeletedEntititesQuery, useRestoreEntityMutation } = recycleBinApi;
