import { consoleApi, ENCRYPTION_KEYS_TAG } from "./console";

export interface EncryptionKey {
    creationDate: string;
    deleted?: boolean;
    encryptionKey: string;
    id: number;
    integrityKey: string;
    seat: {
        code: string;
        entityType: string;
        id: number;
        name: string;
    };
    updateDate: string;
}
export type NewEncryptionKey = Pick<EncryptionKey, "creationDate" | "encryptionKey" | "id" | "integrityKey" | "seat">;
export const encryptionKeysApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getSeatEncryptionKeys: builder.query<EncryptionKey[], string | number>({
            query: (seatId) => {
                return `seats/${seatId}/encryptionKeys`;
            },
            providesTags: (_, __, seatId) => [{ type: ENCRYPTION_KEYS_TAG, id: seatId }],
        }),
        createSeatEncryptionKey: builder.mutation<NewEncryptionKey, string | number>({
            query: (seatId) => ({
                url: `seats/${seatId}/encryptionKeys`,
                method: "POST",
                body: { seat: { id: Number(seatId) } },
            }),
            invalidatesTags: (_, __, seatId) => [{ type: ENCRYPTION_KEYS_TAG, id: seatId }],
        }),
    }),
    overrideExisting: false,
});

export const { useGetSeatEncryptionKeysQuery, useCreateSeatEncryptionKeyMutation } = encryptionKeysApi;
