import { LabeledValue } from "antd/lib/select";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import {
    selectHistoricalChartDateRange,
    setHistoricalChartDateRange,
} from "@app/core/components/charts/HistoricalChart/reducer";

interface UseHistoricalChartDateRangeFilter {
    handleChange: (e: LabeledValue) => void;
    dateRange: historicalChartDateRange;
}

export type historicalChartDateRange = "lastThreeDays" | "lastSevenDays" | "lastThirtyDays";

export const useHistoricalChartDateRangeFilter = (): UseHistoricalChartDateRangeFilter => {
    const dispatch = useAppDispatch();
    const dateRange = useAppSelector(selectHistoricalChartDateRange);

    const handleChange = (e: LabeledValue): void => {
        dispatch(setHistoricalChartDateRange(e.value?.toString() as historicalChartDateRange));
    };

    return {
        handleChange,
        dateRange,
    };
};
