import { useHistory, useLocation, useParams } from "react-router-dom";
import { useNotificationError } from "@app/core/components/hooks/useNotificationError";
import { useGetPriceOverrideConfigsQuery } from "@app/core/services";
import { uri } from "@rubicon/utils";
import { SEARCH } from "@app/core/components";

export const usePriceOverridesTable = () => {
    const history = useHistory();
    const { search: queryString } = useLocation();
    const { seatId } = useParams<{ seatId: string }>();
    const keyword = uri.getSearchParam(queryString, SEARCH.key) || SEARCH.default;

    const { data, isFetching, error } = useGetPriceOverrideConfigsQuery({ seatId, keyword });
    useNotificationError(error);

    const onClickName = (priceOverrideId: number) => {
        const setDrawerParam = uri.setSearchParam(queryString, "drawer", "details");
        const nextQueryString = uri.setSearchParam(setDrawerParam, "priceOverrideId", String(priceOverrideId));
        history.push({
            search: nextQueryString,
        });
    };

    return {
        data,
        search: queryString,
        isLoading: isFetching,
        onClickName,
    };
};
