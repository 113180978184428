import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ErrorResponse, useDeletePriceOverrideConfigMutation, PriceOverrideConfig } from "@app/core/services";
import { Button, Modal, Typography, notification } from "antd";
import { FC, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { removePriceOverrideDrawerParamsFromSearch } from "../PriceOverridesPage/PriceOverridesDrawer";

export const PRICE_OVERRIDE_DELETE_MODAL_SDET = "price-override-delete-modal";
export const PRICE_OVERRIDE_DELETE_MODAL_PROPS = {
    destroyOnClose: true,
    "data-sdet": PRICE_OVERRIDE_DELETE_MODAL_SDET,
    title: "Confirmation",
    okText: <span data-sdet="modal-confirm-button">Delete</span>,
};
const initialState = {
    isOpen: false,
    priceOverride: null,
};

export const usePriceOverrideDeleteModal = (isDrawer = false) => {
    const [state, setState] = useState<{ isOpen: boolean; priceOverride: PriceOverrideConfig | null }>(initialState);
    const { search: queryString } = useLocation();
    const [deletePriceOverride, { isLoading }] = useDeletePriceOverrideConfigMutation();
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();

    const handleOpen = (nextPriceOverride: PriceOverrideConfig) =>
        setState({ isOpen: true, priceOverride: nextPriceOverride });

    const onCancel = () => setState(initialState);

    const onOk = () => {
        state.priceOverride &&
            deletePriceOverride(state.priceOverride.id)
                .unwrap()
                .then(() => {
                    notification.success({
                        message: (
                            <>
                                <Typography.Text strong>{state.priceOverride?.name}</Typography.Text> has successfully
                                been deleted
                            </>
                        ),
                    });
                    onCancel();

                    const nextQueryString = isDrawer
                        ? removePriceOverrideDrawerParamsFromSearch(queryString)
                        : queryString;
                    history.replace({
                        pathname: ROUTE_FORMATTERS.SEAT_CONTROLS_PRICE_OVERRIDES(seatId),
                        search: nextQueryString,
                    });
                })
                .catch((err) =>
                    notification.error({
                        message:
                            (err as ErrorResponse).data?.errorDescription ||
                            (err as ErrorResponse)?.data?.errors?.[0]?.message ||
                            "Something wrong has occurred, please contact your account manager",
                    })
                );
    };
    return { isOpen: state.isOpen, handleOpen, onCancel, onOk, isLoading, priceOverride: state.priceOverride };
};

interface Props {
    isDrawer?: boolean;
    priceOverride?: PriceOverrideConfig | null;
}

export const PriceOverrideDeleteButtonModal: FC<Props> = ({ priceOverride, isDrawer }) => {
    const { handleOpen, isOpen, onCancel, onOk, isLoading } = usePriceOverrideDeleteModal(isDrawer);

    return (
        <>
            <Button
                danger
                data-sdet="delete-button"
                disabled={!priceOverride}
                type="primary"
                onClick={() => priceOverride && handleOpen(priceOverride)}
            >
                Delete
            </Button>
            <Modal
                {...PRICE_OVERRIDE_DELETE_MODAL_PROPS}
                open={isOpen}
                onCancel={onCancel}
                onOk={onOk}
                okButtonProps={{ danger: true, loading: isLoading }}
            >
                Are you sure you want to delete <Typography.Text strong>{priceOverride?.name}</Typography.Text>?
            </Modal>
        </>
    );
};
