import { GlobalOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { CSSProperties, FC } from "react";

interface Props {
    wrapperStyle?: CSSProperties;
    iconStyle?: CSSProperties;
}

export const GlobalIcon: FC<Props> = ({ wrapperStyle, iconStyle }) => (
    <div
        className={css`
            position: relative;
            width: 20px;
            height: 20px;
            background: #e6edff;
            border-radius: 50%;
        `}
        style={{ ...wrapperStyle }}
    >
        <GlobalOutlined
            style={{
                position: "absolute",
                top: "49%",
                left: "49%",
                transform: "translate(-50%, -50%)",
                fontSize: "12px",
                color: "#2E3DCE",
                ...iconStyle,
            }}
        />
    </div>
);
