import { Form } from "antd";
import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { useGetCurrenciesQuery } from "@app/core/services";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

const { useWatch } = Form;

interface UseUDFee {
    options: LabeledValue[];
    isFetching: boolean;
    isUdEnabled: boolean;
}

export const useUDFee = (): UseUDFee => {
    const { data, isFetching } = useGetCurrenciesQuery();

    const options: LabeledValue[] = useMemo(
        () => (data || []).map(({ id, code }) => ({ label: code, value: id })),
        [data]
    );
    const isUdEnabled = useWatch(INVENTORY_FORM_FIELDS.UD_ENABLED.name);

    return {
        options,
        isFetching,
        isUdEnabled,
    };
};
