import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { Button, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { TransparencyFormItem } from "../TransparencyFormItem";
import { AdSourceExtendedUserIdItemField } from "./AdSourceExtendedUserIdItemField";
import { useAdSourceExtendedUserIdList } from "./useAdSourceExtendedUserIdList";

export const AdSourceExtendedUserIdList = () => {
    const { isVisible } = useAdSourceExtendedUserIdList();
    if (!isVisible) {
        return null;
    }
    return (
        <TransparencyFormItem label={AD_SOURCE_FIELDS.EXTENDED_USER_ID.label}>
            <Form.List name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOWED_EXTENDED_ID_TRANSPARENCIES}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field) => (
                            <AdSourceExtendedUserIdItemField key={field.key} field={field} remove={remove} />
                        ))}
                        <Button
                            style={{ width: "auto", paddingLeft: 0 }}
                            type="link"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                            data-sdet="add-extended-user-id"
                        >
                            Add Extended User ID
                        </Button>
                    </>
                )}
            </Form.List>
        </TransparencyFormItem>
    );
};
