import {
    consoleApi,
    BUYER_DEALS_PRICING_TYPES_TAG,
    BUYER_DEALS_TRANSACTION_TYPES_TAG,
    DEAL_TAG,
    SEAT_ALL_DEALS_TAG,
    SEAT_CTV_NEXT_BUYER_DEALS_TAG,
} from "./console";
import { BuyerDeal } from "./deals";
import { toQueryString } from "./utils";

interface BuyerDealsType<T> {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    entityType: T;
    sort: number;
    value: string;
}

export interface BuyerDealListResponse {
    page: number;
    maxResults: number;
    totalResults: number;
    buyerDeals: BuyerDeal[];
}

export type BuyerDealsPricingType = BuyerDealsType<"DealPricingType">;
export type BuyerDealsTransactionType = BuyerDealsType<"DealTransactionType">;

export interface UpdateBuyerDealArgs {
    id: number;
    body: Partial<BuyerDealsPricingType | BuyerDealsTransactionType>;
}

export interface GetSeatBuyerDealsParams {
    seatId: number;
    page?: number;
    max?: number;
    keyword?: string;
    externalStatus?: string;
    buyerSeatCode?: string;
    type?: (string | number)[];
}

export const buyerDealsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getBuyerDealsPricingTypes: builder.query<BuyerDealsPricingType[], void>({
            query: () => "buyerDeals/pricing/types",
            providesTags: () => [BUYER_DEALS_PRICING_TYPES_TAG],
        }),
        getBuyerDealsTransactionTypes: builder.query<BuyerDealsTransactionType[], void>({
            query: () => "buyerDeals/transaction/types",
            providesTags: () => [BUYER_DEALS_TRANSACTION_TYPES_TAG],
        }),
        updateBuyerDeal: builder.mutation<BuyerDeal, UpdateBuyerDealArgs>({
            query: ({ id, body }: UpdateBuyerDealArgs) => {
                const payloadBody = { ...body };
                if (!("@class" in payloadBody)) {
                    payloadBody["@class"] = "com.tremorvideo.ssp.dealsync.model.BuyerDeal";
                }
                return {
                    url: `/buyerDeals/${id}`,
                    method: "PUT",
                    body: payloadBody,
                };
            },
            invalidatesTags: (_, __, { id }) => [{ type: DEAL_TAG, id }, SEAT_ALL_DEALS_TAG],
        }),
        getSeatCtvNextBuyerDeals: builder.query<BuyerDealListResponse, GetSeatBuyerDealsParams>({
            query: (params: GetSeatBuyerDealsParams) => {
                const { seatId, ...rest } = params;
                return `seats/ctvNext/${seatId}/buyerDeals;${toQueryString(rest)}`;
            },
            providesTags: () => [SEAT_CTV_NEXT_BUYER_DEALS_TAG],
        }),
    }),
});

export const {
    useGetBuyerDealsPricingTypesQuery,
    useGetBuyerDealsTransactionTypesQuery,
    useUpdateBuyerDealMutation,
    useLazyGetSeatCtvNextBuyerDealsQuery,
} = buyerDealsApi;
