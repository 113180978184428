import { useMemo, useState } from "react";
import { LabeledValue } from "antd/es/select";
import { IabCategory, useGetIabCategoriesQuery } from "@app/core/services";
import { TargetingDimensionTypeLabels } from "@app/features/targeting/constants";
import { formatCategoryLabel, formatCategorySearchValue } from "@app/features/targeting";

interface UseCategories {
    setSearch: (value: string) => void;
    options: LabeledValue[];
    isFetching: boolean;
    search: string;
}

export const useCategories = (): UseCategories => {
    const [search, setSearch] = useState("");
    const { data, isFetching } = useGetIabCategoriesQuery();

    const orderedData: IabCategory[] = useMemo(
        () => [...(data || [])].sort((a, b) => a.code.localeCompare(b.code, undefined, { numeric: true })),
        [data]
    );

    const options: LabeledValue[] = useMemo(
        () =>
            (orderedData || [])
                .map((target) => ({
                    value: target.id,
                    label: formatCategoryLabel(target, TargetingDimensionTypeLabels.ContentCategories),
                    search: formatCategorySearchValue(target, TargetingDimensionTypeLabels.ContentCategories),
                }))
                .filter((option) => option.search.toLowerCase().includes(search.toLowerCase())),
        [orderedData, search]
    );
    return {
        search,
        options,
        setSearch,
        isFetching,
    };
};
