import { Form } from "antd";
import { RuleObject, StoreValue } from "rc-field-form/lib/interface";
import { DEFAULT_AD_UNIT_MIN_DURATION, MAX_DURATION } from "@app/features/inventory/HierarchyForms/constants";
const { useWatch, useFormInstance } = Form;

interface UseAdUnitMinDuration {
    onChange: (value: number) => void;
    validator: (rule: RuleObject, value: StoreValue) => Promise<void>;
}

export const useAdUnitMinDuration = (): UseAdUnitMinDuration => {
    const form = useFormInstance();
    const maxDuration = useWatch(MAX_DURATION);

    const onChange = (): Promise<void> => form.validateFields([MAX_DURATION]);
    const validator = (_: RuleObject, value: StoreValue): Promise<void> => {
        if (value < DEFAULT_AD_UNIT_MIN_DURATION)
            return Promise.reject(new Error("Min Duration can't be less than 5 seconds"));
        if (!!maxDuration && value > maxDuration)
            return Promise.reject(new Error("Min Duration can't be greater than Max Duration"));
        return Promise.resolve();
    };

    return {
        validator,
        onChange,
    };
};
