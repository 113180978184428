import { FreqCappingTypes } from "@app/features/inventory/components/FormItems";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const AdvFreqCapping = () => {
    return (
        <FreqCappingTypes
            name={CHANNEL_FORM_FIELDS.ADV_FREQ_CAPP.name}
            label={CHANNEL_FORM_FIELDS.ADV_FREQ_CAPP.label}
            wrapperCol={{ xs: 24, sm: 24, md: 10, lg: 6, xl: 11, xxl: 9 }}
        />
    );
};
