import { FC } from "react";
import { Form, InputNumber } from "antd";
import { getAdSourceDuration } from "@app/features/inventory/helpers";

interface AdSourceDurationProps {
    name: string;
    label: string;
    edit: boolean;
    placeholder: string;
    duration: number | null;
}

export const AdSourceDuration: FC<AdSourceDurationProps> = ({ edit = false, label, name, placeholder, duration }) => {
    return (
        <Form.Item name={name} label={label} wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 24, xxl: 20 }}>
            {edit ? (
                <InputNumber min={0} style={{ width: "100%" }} placeholder={placeholder} />
            ) : (
                getAdSourceDuration(duration)
            )}
        </Form.Item>
    );
};
