import { AD_UNIT_CALCULON_BANNED, consoleApi } from "../console";

export interface CalculonBannedLocalResponse {
    banned: {
        adUnitId: number;
        adomain: string;
        demandSeatId: number;
        demandSeatName: string;
        estFills: number;
        estImps: number;
        estUseRate: number;
        lastUpdated: number;
        platformId: number;
        requestTypeId: number;
    }[];
}
export const calculonApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getCalculonBannedLocal: builder.query<CalculonBannedLocalResponse, number>({
            query: (id) => `/calculon/banned/local/adUnits/${id}`,
            providesTags: (_, __, id) => [{ type: AD_UNIT_CALCULON_BANNED, id }],
        }),
    }),
});

export const { useGetCalculonBannedLocalQuery } = calculonApi;
