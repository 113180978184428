import { Table } from "antd";
import { getColumns, getTableRows } from "./helpers";
import { useSeatContactsTable } from "./useSeatContactsTable";
import { useSeatContactDrawer } from "../SeatContactDrawer/useSeatContactDrawer";

export const SEAT_CONTACTS_TABLE_SDET = "seat-contacts-list-table";

export const SeatContactsTable = () => {
    const { isLoading, data, search, seatId } = useSeatContactsTable();
    const { handleDrawerOpen } = useSeatContactDrawer();

    return (
        <Table
            data-sdet={SEAT_CONTACTS_TABLE_SDET}
            columns={getColumns()}
            dataSource={getTableRows(data, seatId, search, handleDrawerOpen)}
            loading={isLoading}
            scroll={{ x: 1000 }}
            size="small"
            rowKey="id"
            showSorterTooltip={false}
            pagination={false}
        />
    );
};

export default SeatContactsTable;
