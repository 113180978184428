import { resetAppAction, useAppDispatch } from "@app/core/store";
import {
    BUYER_ENTITY_TYPE,
    SEAT_ENTITY_TYPE,
    BuyerPicklistItem,
    SeatPicklistItem,
    getBuyersPicklist,
    getSeatsPicklist,
} from "@app/core/clients/console";
import { useEffect, useMemo, useRef, useState } from "react";
import debounce from "lodash.debounce";
import { useHistory, useLocation } from "react-router-dom";
import { Form, FormInstance } from "antd";
import { ROUTES, ROUTE_FORMATTERS } from "@app/core/routing";
import { LabeledValue } from "antd/es/select";
import { FILTER_INPUT_DEBOUNCE_TIME, SEARCH_BUYERS_PREFIX, SEARCH_SEATS_PREFIX } from "@app/core/components/constants";
import { useOpenHelpCenter } from "@app/core/services/console";
import { useAuthClient } from "@app/core/authClient";

export enum CONTEXT_FORM_FIELDS {
    Context = "context",
}

const CLEAR_ERROR_DATA = [
    {
        name: CONTEXT_FORM_FIELDS.Context,
        errors: [],
    },
];

export interface OptionType extends LabeledValue {
    entityType: typeof SEAT_ENTITY_TYPE | typeof BUYER_ENTITY_TYPE;
}

interface UseContextSelectForm {
    CONTEXT_FORM_FIELDS: typeof CONTEXT_FORM_FIELDS;
    form: FormInstance;
    handleChangeSearch: (search: string) => void;
    handleChangeValue: (value: number | null, option: OptionType | null) => void;
    hasNoValidContexts: boolean;
    isLoading: boolean;
    options: OptionType[];
    search: string;
    value: number | null;
    isWaitingOnRefresh: boolean;
}

const fetchSeatsPicklist = (
    search: string,
    form: FormInstance,
    setSeatOptions: (options: SeatPicklistItem[]) => void,
    setIsSeatsLoading: (isLoading: boolean) => void,
    setIsSeatsLoaded: (isLoading: boolean) => void,
    setInitialOptions?: (options: SeatPicklistItem[]) => void
) => {
    setIsSeatsLoading(true);
    getSeatsPicklist({ keyword: search })
        .then((res) => {
            form.setFields(CLEAR_ERROR_DATA);
            setSeatOptions(res?.data);
            if (setInitialOptions) {
                setInitialOptions(res?.data);
            }
        })
        .catch((err) => {
            // TODO make more robust
            console.error(err);
            form.setFields([
                {
                    name: CONTEXT_FORM_FIELDS.Context,
                    errors: ["Something went wrong"],
                },
            ]);
        })
        .finally(() => {
            setIsSeatsLoaded(true);
            setIsSeatsLoading(false);
        });
};

const fetchBuyersPicklist = (
    search: string,
    form: FormInstance,
    setBuyerOptions: (options: BuyerPicklistItem[]) => void,
    setIsBuyersLoading: (isLoading: boolean) => void,
    setIsBuyersLoaded: (isLoading: boolean) => void,
    setInitialOptions?: (options: BuyerPicklistItem[]) => void
) => {
    setIsBuyersLoading(true);
    getBuyersPicklist({ keyword: search })
        .then((res) => {
            form.setFields(CLEAR_ERROR_DATA);
            setBuyerOptions(res?.data);
            if (setInitialOptions) {
                setInitialOptions(res?.data);
            }
        })
        .catch((err) => {
            // TODO make more robust
            console.error(err);
            form.setFields([
                {
                    name: CONTEXT_FORM_FIELDS.Context,
                    errors: ["Something went wrong"],
                },
            ]);
        })
        .finally(() => {
            setIsBuyersLoaded(true);
            setIsBuyersLoading(false);
        });
};

export const useContextSelectForm = (): UseContextSelectForm => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const [isSeatsLoading, setIsSeatsLoading] = useState(false);
    const [isSeatsLoaded, setIsSeatsLoaded] = useState(false);
    const [isBuyersLoading, setIsBuyersLoading] = useState(false);
    const [isBuyersLoaded, setIsBuyersLoaded] = useState(false);
    const [value, setValue] = useState<number | null>(null);
    const [search, setSearch] = useState("");
    const [seatOptions, setSeatOptions] = useState<SeatPicklistItem[]>([]);
    const [seatInitialOptions, setSeatInitialOptions] = useState<SeatPicklistItem[]>([]);
    const [buyerOptions, setBuyerOptions] = useState<BuyerPicklistItem[]>([]);
    const [buyerInitialOptions, setBuyerInitialOptions] = useState<BuyerPicklistItem[]>([]);

    const history = useHistory();
    const openHelpCenter = useOpenHelpCenter();
    const { isLoggedIn } = useAuthClient();

    const hasNoValidContexts = useMemo(() => {
        return (
            !isSeatsLoading &&
            isSeatsLoaded &&
            !isBuyersLoading &&
            isBuyersLoaded &&
            search === "" &&
            !seatInitialOptions.length &&
            !buyerInitialOptions.length
        );
    }, [
        isSeatsLoading,
        isSeatsLoaded,
        isBuyersLoading,
        isBuyersLoaded,
        search,
        seatInitialOptions,
        buyerInitialOptions,
    ]);

    useEffect(() => {
        if (!isLoggedIn) {
            history.replace("/login/email");
        }
    }, [isLoggedIn, history]);

    const debouncedFetchSeatsPicklist = useMemo(
        () =>
            debounce(
                (search: string) =>
                    fetchSeatsPicklist(search, form, setSeatOptions, setIsSeatsLoading, setIsSeatsLoaded),
                FILTER_INPUT_DEBOUNCE_TIME
            ),
        [form]
    );

    const debouncedFetchBuyersPicklist = useMemo(
        () =>
            debounce(
                (search: string) =>
                    fetchBuyersPicklist(search, form, setBuyerOptions, setIsBuyersLoading, setIsBuyersLoaded),
                FILTER_INPUT_DEBOUNCE_TIME
            ),
        [form]
    );

    const handleChangeSearch = (search: string) => {
        setSearch(search);
        if (search.toLowerCase().startsWith(SEARCH_SEATS_PREFIX)) {
            setBuyerOptions([]);
            debouncedFetchSeatsPicklist(search.toLowerCase().slice(SEARCH_SEATS_PREFIX.length).trim());
            return;
        }
        if (search.toLowerCase().startsWith(SEARCH_BUYERS_PREFIX)) {
            setSeatOptions([]);
            debouncedFetchBuyersPicklist(search.toLowerCase().slice(SEARCH_BUYERS_PREFIX.length).trim());
            return;
        }
        debouncedFetchSeatsPicklist(search.toLowerCase());
        debouncedFetchBuyersPicklist(search.toLowerCase());
    };

    const handleChangeValue = (value: number | null, option: OptionType): void => {
        setValue(value);
        dispatch(resetAppAction());

        const urlParams = new URLSearchParams(history.location.search);
        const redirect = urlParams.get("redirect");
        if (redirect === "help.magnite.com") {
            openHelpCenter(false, option?.entityType === SEAT_ENTITY_TYPE);
            return;
        }

        if (option?.entityType === SEAT_ENTITY_TYPE) {
            history.push(ROUTE_FORMATTERS.SEAT_DASHBOARD(option?.value));
            return;
        }
        history.push(ROUTE_FORMATTERS.BUYER_DASHBOARD(option?.value));
    };

    useEffect(() => {
        fetchSeatsPicklist("", form, setSeatOptions, setIsSeatsLoading, setIsSeatsLoaded, setSeatInitialOptions);
        fetchBuyersPicklist("", form, setBuyerOptions, setIsBuyersLoading, setIsBuyersLoaded, setBuyerInitialOptions);
    }, [form]);

    useEffect(() => {
        return () => {
            debouncedFetchBuyersPicklist.cancel();
            debouncedFetchSeatsPicklist.cancel();
        };
    }, [debouncedFetchBuyersPicklist, debouncedFetchSeatsPicklist]);

    useEffect(() => {
        const urlParams = new URLSearchParams(history.location.search);
        const redirect = urlParams.get("redirect");

        if (redirect && redirect !== "help.magnite.com") {
            history.push(decodeURIComponent(redirect));
        }
    }, [history, history.location.search]);

    useEffect(() => {
        if (hasNoValidContexts) {
            history.push(ROUTES.LOGIN_FORM_NO_VALID_CONTEXTS_STEP);
        }
    }, [hasNoValidContexts, history]);

    const options: OptionType[] = useMemo(
        () =>
            [...seatOptions, ...buyerOptions].map((option) => ({
                label: `${option?.name} (${option?.entityType})`,
                value: option?.id,
                entityType: option?.entityType,
            })),
        [seatOptions, buyerOptions]
    );

    const { search: locationSearch } = useLocation();

    const isWaitingOnRefreshRef = useRef<boolean>(false);
    useEffect(() => {
        const searchParams = new URLSearchParams(locationSearch);
        const shouldRefresh = searchParams.has("runAsRefresh");
        if (shouldRefresh) {
            // Do a hard refresh to fix a run-as issue that was preventing the controls tab from appearing.
            // This should only happen when the "runAsRefresh" parameter is set (run as user or self).
            const paramlessUrl = window.location.href.split("?")[0];
            window.location.replace(paramlessUrl);
            window.location.reload();
            isWaitingOnRefreshRef.current = true;
        }
    }, [locationSearch]);

    return {
        CONTEXT_FORM_FIELDS,
        form,
        handleChangeSearch,
        handleChangeValue,
        hasNoValidContexts,
        isLoading: isSeatsLoading || isBuyersLoading,
        options,
        search,
        value,
        isWaitingOnRefresh: isWaitingOnRefreshRef.current,
    };
};
