import { Button } from "antd";
import { FC, useState } from "react";
import { LegendData, PinnedLegend } from "./types";
import { CustomLegend } from "./CustomLegend";

export const MAX_DATA_LENGTH = 8;

interface Props {
    legendData: LegendData[];
    pinnedLegend: PinnedLegend;
    selectLegend: (item: LegendData) => void;
}

export const ShowMoreLegend: FC<Props> = ({ legendData, pinnedLegend, selectLegend }) => {
    const [isSeeingMore, setIsSeeingMore] = useState(false);
    return (
        <>
            {legendData
                .slice(0, isSeeingMore ? legendData.length : MAX_DATA_LENGTH)
                .map(({ name, value, percentage, color }) => (
                    <CustomLegend
                        pinnedLegend={pinnedLegend}
                        selectLegend={selectLegend}
                        key={name + percentage}
                        name={name}
                        percentage={percentage}
                        value={value}
                        color={color}
                        showMore={setIsSeeingMore}
                        outOfDefault={
                            legendData.findIndex(
                                (ld) => ld.name === pinnedLegend?.value?.name && ld.value === pinnedLegend?.value?.value
                            ) >
                            MAX_DATA_LENGTH - 1
                        }
                    />
                ))}

            {legendData.length > MAX_DATA_LENGTH && (
                <Button
                    data-sdet="show-more-button"
                    type="link"
                    style={{ paddingLeft: "0.5rem" }}
                    onClick={() => setIsSeeingMore((v) => !v)}
                >
                    See {isSeeingMore ? "Less" : "More"}
                </Button>
            )}
        </>
    );
};
