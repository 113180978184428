import { useTransparencyEditModeControls } from "./useTransparencyEditModeControls";
import { EditModeControls } from "@app/core/components/EditModeControls";
import { selectTransparencyFormTouchedFields } from "../reducer";
import { useAppSelector } from "@app/core/store";

export const TransparencyEditModeControls = () => {
    const { isEdit, isSaving, handleCancel, handleEditClick, handleCancelClick, handleOk, open } =
        useTransparencyEditModeControls();
    const touchedFields = useAppSelector(selectTransparencyFormTouchedFields);

    return (
        <EditModeControls
            disabled={!Boolean(touchedFields?.length) || isSaving}
            discardText="Are you sure you want to proceed without saving the changes"
            isEdit={isEdit}
            isSaving={isSaving}
            open={open}
            handleDiscardModalCancel={handleCancel}
            handleCancelClick={handleCancelClick}
            handleEditClick={handleEditClick}
            handleOk={handleOk}
        />
    );
};

export default TransparencyEditModeControls;
