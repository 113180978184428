import { useCallback } from "react";
import {
    DealStatus,
    SeatAdSourcesStatus,
    useGetDealStatusQuery,
    useGetSeatAdSourcesStatusesQuery,
} from "@app/core/services";

export interface UseMapStatuses {
    mapAdSourceStatusToDealStatus: (adSourceStatusId: number) => DealStatus | undefined;
    mapDealStatusToAdSourceStatus: (dealStatusId: number) => SeatAdSourcesStatus | undefined;
}

const AdSourceToDealMap = {
    1: 1, // Running -> Active
    2: 2, // Paused -> Paused
    3: 2, // Expired -> Paused
} as const;

const DealToAdSourceMap = {
    1: 1, // Active -> Running
    2: 2, // Paused -> Paused
} as const;

export const useMapStatuses = (): UseMapStatuses => {
    const { data: adSourceStatuses } = useGetSeatAdSourcesStatusesQuery();
    const { data: dealStatuses } = useGetDealStatusQuery();

    const mapAdSourceStatusToDealStatus = useCallback(
        (adSourceStatusId: number) =>
            dealStatuses?.find((dealStatus) => dealStatus.id === AdSourceToDealMap[adSourceStatusId]),
        [dealStatuses]
    );

    const mapDealStatusToAdSourceStatus = useCallback(
        (dealStatusId: number) =>
            adSourceStatuses?.find((adSourceStatus) => adSourceStatus.id === DealToAdSourceMap[dealStatusId]),
        [adSourceStatuses]
    );

    return {
        mapAdSourceStatusToDealStatus,
        mapDealStatusToAdSourceStatus,
    };
};
