import { FC } from "react";
import { BuyerIFrame } from "@app/core/components";
import conf from "@app/core/conf";

export const BuyerDealDashboardPage: FC = () => {
    return (
        <BuyerIFrame
            src={(buyerId) => `${conf.mctvConsoleRoot}/ssp/buyers/${buyerId}/deal-dashboard`}
            title="Deal Dashboard"
        />
    );
};
