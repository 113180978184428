import { useParams } from "react-router-dom";
import { Floor, FloorUpdatePayload, useGetSeatFloorsQuery, useUpdateFloorMutation } from "@app/core/services";
import { FloorTableRecord } from "./SeatFloorsTable";
import { getTargetingFromCountry } from "@app/features/inventory/InventoryFloors/helpers";
import { notification } from "antd";

interface UseDealListTable {
    data?: Array<Floor>;
    isFetching: boolean;
    seatId: string;
    isSaving: boolean;
    editFloor: (originalFloor: FloorTableRecord, newFloor) => void;
}

export const useSeatFloorsTable = (): UseDealListTable => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data, isFetching } = useGetSeatFloorsQuery(Number(seatId));
    const [updateFloor, { isLoading }] = useUpdateFloorMutation();

    const editFloor = async (originalFloor: FloorTableRecord, newFloor) => {
        const body: FloorUpdatePayload = {
            ...originalFloor.floor,
            price: newFloor.price * 1000,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            targeting: getTargetingFromCountry(newFloor.country),
        };
        try {
            await updateFloor({ id: originalFloor.floor.id, body }).unwrap();
            notification.success({ message: "Floor edited successfully" });
        } catch (error) {
            notification.error({ message: `Operation has failed: ${error?.data?.errorDescription}` });
        }
    };

    return {
        data,
        isFetching,
        seatId,
        editFloor,
        isSaving: isLoading,
    };
};
