import { NONE } from "@app/core/components/constants";
import { AdUnit, IabCategory } from "@app/core/services";
import { GUTTER } from "@app/features/inventory/constants";
import { SeeMoreUnorderedList } from "@app/features/inventory/SeeMoreUnorderedList";
import { Col, Form, Row, Tag } from "antd";
import { FC } from "react";
import {
    AD_UNIT_DEMAND_REQUIREMENTS_LABELS,
    AD_UNIT_SUPPLY_DETAILS_LABELS,
    BRAND_SUPPLY_DETAILS_LABELS,
    COL_SIZES,
    FORM_ITEM_OFFSET,
    SUPPLY_APP_DETAILS_LABELS,
    SUPPLY_SUPPLY_DETAILS_LABELS,
} from "../../../constants";
import { InfoMessage } from "../../../SharedSections";
import { getYesNoLabel } from "@app/core/utils/labels";

const { Item } = Form;

interface Props {
    adUnit?: AdUnit;
    isCoppa: boolean | null;
    hasPrivacyPolicy: boolean | null;
    inventoryPartnerDomain: string | null;
    iabCategories: IabCategory[];
}

export const Defaults: FC<Props> = ({ adUnit, isCoppa, inventoryPartnerDomain, iabCategories, hasPrivacyPolicy }) => {
    return (
        <div data-sdet="channel-defaults-section">
            <InfoMessage />
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DEMAND_REQUIREMENTS_LABELS.mimes} className={FORM_ITEM_OFFSET}>
                        {adUnit?.mimes?.length ? adUnit?.mimes.map(({ id, name }) => <Tag key={id}>{name}</Tag>) : NONE}
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DEMAND_REQUIREMENTS_LABELS.supportedApis} className={FORM_ITEM_OFFSET}>
                        {adUnit?.supportedApis?.length
                            ? adUnit?.supportedApis.map(({ id, name }) => <Tag key={id}>{name}</Tag>)
                            : NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.linearity} className={FORM_ITEM_OFFSET}>
                        {adUnit?.linearity?.name ?? NONE}
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.multiplicity} className={FORM_ITEM_OFFSET}>
                        {adUnit?.multiplicity?.name ?? NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.type} className={FORM_ITEM_OFFSET}>
                        {adUnit?.type?.name || NONE}
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.interstitial} className={FORM_ITEM_OFFSET}>
                        {getYesNoLabel(Boolean(adUnit?.isInterstitial))}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DEMAND_REQUIREMENTS_LABELS.placement} className={FORM_ITEM_OFFSET}>
                        {adUnit?.placement?.name ?? NONE}
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label={SUPPLY_SUPPLY_DETAILS_LABELS.coppa} className={FORM_ITEM_OFFSET}>
                        {getYesNoLabel(Boolean(isCoppa))}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label={SUPPLY_SUPPLY_DETAILS_LABELS.privacyPolicy} className={FORM_ITEM_OFFSET}>
                        {getYesNoLabel(Boolean(hasPrivacyPolicy))}
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label={SUPPLY_APP_DETAILS_LABELS.partnerDomain} className={FORM_ITEM_OFFSET}>
                        {inventoryPartnerDomain ?? NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label={BRAND_SUPPLY_DETAILS_LABELS.categories} className={FORM_ITEM_OFFSET}>
                        <SeeMoreUnorderedList values={iabCategories?.map((value) => value.name)} />
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DEMAND_REQUIREMENTS_LABELS.supportedProtocols} className={FORM_ITEM_OFFSET}>
                        {adUnit?.supportedProtocols?.length
                            ? adUnit?.supportedProtocols.map(({ id, name }) => <Tag key={id}>{name}</Tag>)
                            : NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DEMAND_REQUIREMENTS_LABELS.minDuration} className={FORM_ITEM_OFFSET}>
                        {Number(adUnit?.minDuration) > 0 ? adUnit?.minDuration : NONE}
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DEMAND_REQUIREMENTS_LABELS.maxDuration} className={FORM_ITEM_OFFSET}>
                        {Number(adUnit?.maxDuration) > 0 ? adUnit?.maxDuration : NONE}
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DEMAND_REQUIREMENTS_LABELS.maxExtended} className={FORM_ITEM_OFFSET}>
                        {Number(adUnit?.maxExtended) > 0 ? adUnit?.maxExtended : NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DEMAND_REQUIREMENTS_LABELS.minBitrate} className={FORM_ITEM_OFFSET}>
                        {adUnit?.minBitrate ?? NONE}
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DEMAND_REQUIREMENTS_LABELS.maxBitrate} className={FORM_ITEM_OFFSET}>
                        {adUnit?.maxBitrate ?? NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.ssaiType} className={FORM_ITEM_OFFSET}>
                        {adUnit?.ssaiType?.name ?? NONE}
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.liveStream} className={FORM_ITEM_OFFSET}>
                        {getYesNoLabel(Boolean(adUnit?.isLiveStream))}
                    </Item>
                </Col>
            </Row>
        </div>
    );
};
