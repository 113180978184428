import { Form } from "antd";
const { useFormInstance } = Form;
import { SEPARATION_GROUPS } from "@app/features/inventory/HierarchyForms/constants";

interface UseAdUnitCompetitiveSeparationMode {
    onChange: (value: boolean) => void;
}

export const useAdUnitCompetitiveSeparationMode = (): UseAdUnitCompetitiveSeparationMode => {
    const form = useFormInstance();
    const onChange = (): void => form.setFieldValue(SEPARATION_GROUPS, []);
    return { onChange };
};
