import { Form } from "antd";
import { useExistingAdSourceField } from "./useExistingAdSourceField";
import { LoadOnScrollSelect } from "@app/core/components";

export const ExistingAdSourceField = () => {
    const { options, isLoading, search, handleChangeSearch, handleChangeValue, hasMore, loadMore, value, valueOption } =
        useExistingAdSourceField();

    return (
        <Form.Item
            data-sdet="existing-ad-source-field"
            required
            label="Ad Source"
            validateStatus={!value ? "error" : undefined}
            help={!value ? "Ad Source is required" : undefined}
        >
            <LoadOnScrollSelect
                data-sdet="existing-ad-source-select"
                filterOption={false}
                hasMore={hasMore}
                loadMore={loadMore}
                loading={isLoading}
                onChange={handleChangeValue}
                onSearch={handleChangeSearch}
                optionFilterProp="label"
                optionLabelProp="label"
                options={options}
                placeholder="Select Ad Source"
                searchValue={search}
                showSearch
                style={{ width: "100%" }}
                value={valueOption}
            />
        </Form.Item>
    );
};
