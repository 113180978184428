import { CheckboxOptionType, Radio, Space } from "antd";
import { FC, useEffect, useState } from "react";
import { OperationEntitiesFilters } from "./OperationEntitiesFilters";
import { OperationEntitiesTable } from "./OperationEntitiesTable";
import { OperationEntitiesBulkUpload } from "./OperationEntitiesBulkUpload";
import { BulkOperationEntityType } from "@app/core/services";

const SELECT_FROM_LIST_RADIO = "selectFromList";
const BULK_UPLOAD_BY_IDS_RADIO = "bulkUploadByIds";

export const FindOperationEntitiesTab: FC<{
    entityType?: BulkOperationEntityType;
    setCurrentTab: (tab: string) => void;
}> = ({ entityType, setCurrentTab }) => {
    const [currentRadio, setCurrentRadio] = useState(SELECT_FROM_LIST_RADIO);
    const showBulkUploadRadio =
        entityType === BulkOperationEntityType.AdSource || entityType === BulkOperationEntityType.AdUnit;

    useEffect(() => {
        !showBulkUploadRadio && setCurrentRadio(SELECT_FROM_LIST_RADIO);
    }, [showBulkUploadRadio]);

    return (
        <Space direction="vertical" style={{ display: "flex" }} size="middle">
            {showBulkUploadRadio && (
                <Radio.Group
                    data-sdet="select-entities-radio-group"
                    value={currentRadio}
                    onChange={({ target: { value } }) => setCurrentRadio(value)}
                    options={
                        [
                            { label: "Select From List", value: SELECT_FROM_LIST_RADIO },
                            showBulkUploadRadio && { label: "Bulk Upload By IDs", value: BULK_UPLOAD_BY_IDS_RADIO },
                        ].filter(Boolean) as CheckboxOptionType[]
                    }
                />
            )}
            {currentRadio === SELECT_FROM_LIST_RADIO && (
                <>
                    <OperationEntitiesFilters />
                    <OperationEntitiesTable />
                </>
            )}
            {currentRadio === BULK_UPLOAD_BY_IDS_RADIO && <OperationEntitiesBulkUpload setCurrentTab={setCurrentTab} />}
        </Space>
    );
};
