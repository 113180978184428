import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { func } from "@rubicon/utils";
import { ASYNC_INPUT_DEBOUNCE_TIME } from "../DebounceUriSearch";

interface UseTableLocalSearch<T> {
    dataSource: T[];
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    value: string;
}

type SearchKeyMapper<T> = (val: T) => string;

const useTableLocalSearch = <T>(searchKeyMappers: SearchKeyMapper<T>[], dataItems: T[]): UseTableLocalSearch<T> => {
    const [value, setValue] = useState("");
    const [dataSource, setDataSource] = useState<T[]>(dataItems);

    const handleSearch = (searchTerm: string, items: T[]) => {
        const searchTermTrim = searchTerm.trim().toLowerCase();
        const result = searchTermTrim
            ? items.filter((item) => {
                  const values: string[] = searchKeyMappers.map((fn) => fn(item));
                  return values.some((value) => value.toLowerCase().includes(searchTermTrim));
              })
            : items;
        setDataSource(result);
    };
    // To make sure after deleting a table item, the dataSource get updated
    useEffect(() => {
        handleSearch(value, dataItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataItems]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleDebounce = useCallback(func.debounce(handleSearch, ASYNC_INPUT_DEBOUNCE_TIME), []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        handleDebounce(e.target.value, dataItems);
    };

    return {
        dataSource,
        handleChange,
        value,
    };
};

export default useTableLocalSearch;
