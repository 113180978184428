import { useUserAccess } from "@app/core/auth";
import { useMemo } from "react";

interface ItemType {
    label: string;
    key: string;
}

export const useControlsNavAccess = () => {
    const { isTremorUser, isDemandPartner, isSeatAdmin, isSupplySeat, canEditSeat } = useUserAccess();

    const controlsNavAccess = useMemo(
        () => ({
            hasTagParametersAccess: isSupplySeat,
            hasSeatContactsAccess: isTremorUser && !isDemandPartner && isSupplySeat,
            hasEncryptionKeysAccess: isSupplySeat && (canEditSeat || isSeatAdmin),
            hasCustomPixelsAccess: isSupplySeat,
            hasAdvancedFloorsAccess: isSupplySeat,
            hasTargetingAccess: !isDemandPartner && canEditSeat,
            hasBrandSafetyAccess: isSupplySeat,
            hasFiltersAccess: isSupplySeat,
            hasBulkOperationsAccess: isSeatAdmin && isSupplySeat,
            hasClassificationsAccess: isSupplySeat,
            hasAuditLogsAccess: isSeatAdmin && isSupplySeat,
            hasRecycleBinAccess: isSeatAdmin && isSupplySeat,
            hasPriceOverridesAccess: isSeatAdmin && isSupplySeat,
        }),
        [isTremorUser, isDemandPartner, isSeatAdmin, isSupplySeat, canEditSeat]
    );
    const controlsNavItems = useMemo(
        () =>
            [
                isSupplySeat && { label: "Tag Parameters", key: "tag-parameters" },
                isTremorUser && !isDemandPartner && isSupplySeat && { label: "Seat Contacts", key: "seat-contacts" },
                isSupplySeat && (canEditSeat || isSeatAdmin) && { label: "Encryption Keys", key: "encryption-keys" },
                isSupplySeat && { label: "Custom Pixels", key: "custom-pixels" },
                // isSupplySeat && { label: "Advanced Floors", key: "advanced-floors" }, // not port for now
                !isDemandPartner && canEditSeat && { label: "Targeting", key: "targeting" },
                isSupplySeat && { label: "Brand Safety", key: "brand-safety" },
                // isSupplySeat && { label: "Filters", key: "filters" }, // hold off porting over
                isSeatAdmin && isSupplySeat && { label: "Bulk Operations", key: "bulk-operations" },
                isSupplySeat && { label: "Classifications", key: "classifications" },
                isSeatAdmin && isSupplySeat && { label: "Audit Logs", key: "audit-logs" },
                isSeatAdmin && isSupplySeat && { label: "Recycle Bin", key: "recycle-bin" },
                isSeatAdmin && isSupplySeat && { label: "Ad Responses", key: "price-overrides" },
            ].filter(Boolean) as ItemType[],
        [isTremorUser, isDemandPartner, isSeatAdmin, isSupplySeat, canEditSeat]
    );

    return {
        ...controlsNavAccess,
        controlsNavItems,
    };
};
