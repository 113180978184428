import { FC } from "react";
import { Layout } from "antd";
import { Footer } from "@app/core/components";
import { useParams } from "react-router-dom";
import { PageLoader } from "@rubicon/antd-components";
import { useGetSupplyQuery } from "@app/core/services";
import { SupplyForm } from "@app/features/inventory/HierarchyForms/SupplyForm";
import { SupplyTrafficAlertBanner } from "@app/features/inventory/components/SupplyTrafficAlertBanner";
import { SupplyFormInstance } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormInstance";
import { SupplyEditPageHeader } from "@app/features/inventory/HierarchyFormsPages/SupplyPages/SupplyEditPage/SupplyEditPageHeader";
import { useScrollToTopOnMount } from "@app/core/components/hooks";

export const SupplyEditPage: FC = () => {
    useScrollToTopOnMount();
    const { supplyId } = useParams<{ supplyId: string }>();
    const { data, isFetching } = useGetSupplyQuery(Number(supplyId));

    if (isFetching || !data) return <PageLoader />;

    return (
        <>
            <SupplyEditPageHeader supply={data} />
            <SupplyTrafficAlertBanner sourceSelf="Supply" />
            <Layout.Content>
                <SupplyFormInstance mode="edit" supply={data}>
                    <SupplyForm />
                </SupplyFormInstance>
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
