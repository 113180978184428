import { FC } from "react";
import { ColProps } from "antd/lib/grid/col";
import { CodeFormItem } from "@app/features/inventory/components/FormItems";
import { SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useSupplyFormInstance } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormInstance";

interface CodeProps {
    wrapperCol?: ColProps;
}

export const Code: FC<CodeProps> = ({ wrapperCol }) => {
    const { mode, seat, useWatch } = useSupplyFormInstance();

    return (
        <CodeFormItem
            mode={mode}
            seat={seat}
            useWatch={useWatch}
            wrapperCol={wrapperCol}
            name={SUPPLY_FORM_FIELDS.CODE.name}
            label={SUPPLY_FORM_FIELDS.CODE.label}
            placeholder={SUPPLY_FORM_FIELDS.CODE.placeholder}
            dataSdet="supply-code"
        />
    );
};
