import { InternalSeatContact } from "@app/core/services";
import { Store } from "rc-field-form/es/interface";
import {
    InternalContact,
    InternalContactsForm,
    INTERNAL_CONTACTS_FORM_FILEDS,
} from "./InternalContactsPageEditForm/useInternalContactsPageEditForm";

export const INTERNAL_CONTACT_IDS = {
    SALES: 1,
    ACCOUNT: 2,
    TECHNICAL: 3,
    BILLING: 4,
    D_SALES: 5,
    D_ACCOUNT: 6,
    D_TECHNICAL: 7,
    D_BILLING: 8,
};

export const parseValueForApi = (values: InternalContactsForm) => {
    const contacts: InternalContact[] = [];

    Object.values(values).forEach((value) => {
        if (value) {
            contacts.push(value.internalContact);
        }
    });
    return contacts;
};

export const getInitialValueFromApi = (internalSeatContacts: InternalSeatContact[] | undefined): Store | undefined => {
    if (!internalSeatContacts?.length) return undefined;

    const defaultValue: InternalContactsForm = {
        [INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_TECHNICAL_ACCOUNT.name]: null,
        [INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_ACCOUNT.name]: null,
        [INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_SALES.name]: null,
        [INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_BILLING.name]: null,
        [INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_BILLING.name]: null,
        [INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_TECHNICAL_ACCOUNT.name]: null,
        [INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_ACCOUNT.name]: null,
        [INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_SALES.name]: null,
        [INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_BILLING_ACCOUNT.name]: null,
    };

    return internalSeatContacts.reduce((acc, { type, user, id, isPrimaryContact }) => {
        const value = {
            label: getLabel(user.name, user.emailAddress),
            value: user.id,
            internalContact: {
                id,
                isPrimaryContact,
                type,
                user: {
                    id: user.id,
                },
            },
        };

        switch (type.id) {
            case INTERNAL_CONTACT_IDS.TECHNICAL:
                acc[INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_TECHNICAL_ACCOUNT.name] = value;
                break;
            case INTERNAL_CONTACT_IDS.ACCOUNT:
                acc[INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_ACCOUNT.name] = value;
                break;
            case INTERNAL_CONTACT_IDS.SALES:
                acc[INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_SALES.name] = value;
                break;
            case INTERNAL_CONTACT_IDS.BILLING:
                acc[INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_BILLING.name] = value;
                break;

            case INTERNAL_CONTACT_IDS.D_TECHNICAL:
                acc[INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_TECHNICAL_ACCOUNT.name] = value;
                break;
            case INTERNAL_CONTACT_IDS.D_ACCOUNT:
                acc[INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_ACCOUNT.name] = value;
                break;
            case INTERNAL_CONTACT_IDS.D_SALES:
                acc[INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_SALES.name] = value;
                break;
            case INTERNAL_CONTACT_IDS.D_BILLING:
                acc[INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_BILLING_ACCOUNT.name] = value;
                break;
        }
        return acc;
    }, defaultValue);
};
export const getLabel = (name: string, emailAddress: string) => `${name} [${emailAddress}]`;
