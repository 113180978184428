import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LabeledValue } from "antd/lib/select";
import { RootState } from "@app/core/store";
import { DEFAULT_PAGE_SIZE } from "@app/core/components/constants";

export const ALL_STATUS_WATERFALL_FILTER: LabeledValue = { label: "All", value: 1 } as const;

interface SeatAdSourcesPacingTableFilters {
    seatAdSourcesAssignee: LabeledValue[] | null;
    seatAdSourcesLabelKey: LabeledValue[] | null;
    seatAdSourcesLabelValue: LabeledValue[] | null;
    seatAdSourcesStatus: LabeledValue | null;
    seatAdSourcesDsp: LabeledValue[] | null;
    seatAdSourcesDeal: LabeledValue[] | null;
    seatAdSourcesDealSearch: string;
}

export interface SeatAdSourcesPacingTablePagination {
    page: number;
    max: number;
    total: number;
}

interface SeatAdSourcesPacingSearchFilter {
    keyword: string | null;
}

export interface SeatAdSourcesPacingState {
    filters: SeatAdSourcesPacingTableFilters;
    pagination: SeatAdSourcesPacingTablePagination;
    searchFilter: SeatAdSourcesPacingSearchFilter;
}

export const initialState: SeatAdSourcesPacingState = {
    filters: {
        seatAdSourcesAssignee: [],
        seatAdSourcesLabelKey: [],
        seatAdSourcesLabelValue: [],
        seatAdSourcesStatus: ALL_STATUS_WATERFALL_FILTER,
        seatAdSourcesDsp: [],
        seatAdSourcesDeal: [],
        seatAdSourcesDealSearch: "",
    },
    pagination: {
        page: 1,
        max: DEFAULT_PAGE_SIZE,
        total: 0,
    },
    searchFilter: {
        keyword: null,
    },
};

const seatAdSourcesPacingSlice = createSlice({
    name: "seatAdSourcesPacing",
    initialState,
    reducers: {
        clearFilters: (state) => {
            state.filters = initialState.filters;
        },
        setFiltersSeatAdSourcesStatus: (state, action: PayloadAction<LabeledValue>) => {
            state.filters.seatAdSourcesStatus = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setFiltersSeatAdSourcesAssignee: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.seatAdSourcesAssignee = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setFiltersSeatAdSourcesLabelKey: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.seatAdSourcesLabelKey = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setFiltersSeatAdSourcesLabelValue: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.seatAdSourcesLabelValue = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setFiltersSeatAdSourcesDsp: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.seatAdSourcesDsp = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setFiltersSeatAdSourcesDeal: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.seatAdSourcesDeal = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setFiltersSeatAdSourcesDealSearch: (state, action: PayloadAction<string>) => {
            state.filters.seatAdSourcesDealSearch = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setSearchFilter: (state, action: PayloadAction<SeatAdSourcesPacingSearchFilter>) => {
            state.searchFilter = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setPagination: (state, action: PayloadAction<Pick<SeatAdSourcesPacingTablePagination, "max" | "page">>) => {
            state.pagination.max = action.payload.max;
            state.pagination.page = action.payload.page;
        },
        setPaginationTotalResults: (state, action: PayloadAction<number>) => {
            state.pagination.total = action.payload;
        },
    },
});

export const selectSeatAdSourcesPacingFilterCount = (state: RootState): number =>
    Object.values(state.seatAdSourcesPacing.filters).filter(
        (filter) => filter && (!Array.isArray(filter) || filter.length > 0)
    ).length;
export const selectSeatAdSourcesPacingPagination = (state: RootState) => state.seatAdSourcesPacing.pagination;
export const selectSeatAdSourcesPacingFilters = (state: RootState) => state.seatAdSourcesPacing.filters;
export const selectSeatAdSourcesPacingSearchFilter = (state: RootState) => state.seatAdSourcesPacing.searchFilter;
export const {
    clearFilters,
    setFiltersSeatAdSourcesAssignee,
    setFiltersSeatAdSourcesDeal,
    setFiltersSeatAdSourcesDealSearch,
    setFiltersSeatAdSourcesDsp,
    setFiltersSeatAdSourcesLabelKey,
    setFiltersSeatAdSourcesLabelValue,
    setFiltersSeatAdSourcesStatus,
    setSearchFilter,
    setPagination,
    setPaginationTotalResults,
} = seatAdSourcesPacingSlice.actions;

export const seatAdSourcesPacingReducer = seatAdSourcesPacingSlice.reducer;
