import { useNotificationError } from "@app/core/components/hooks";
import { useGetSeatContactsQuery } from "@app/core/services";
import { useLocation, useParams } from "react-router-dom";

export const useSeatContactsTable = () => {
    const { search } = useLocation();
    const { seatId } = useParams<{ seatId: string }>();

    const { data, isLoading, isFetching, error } = useGetSeatContactsQuery(Number(seatId));
    useNotificationError(error);

    return {
        isLoading: isLoading || isFetching,
        data,
        seatId,
        search,
    };
};
