import { FC } from "react";
import { Select } from "antd";
import { TagRender } from "@app/core/components/TagRender";

interface Props {
    onChange: (value: string[]) => void;
    value: string[];
}

export const CustomTargetsSelect: FC<Props> = ({ onChange, value }) => {
    return (
        <Select
            data-sdet="CustomIDDimensionSelect"
            mode="tags"
            onChange={onChange}
            tokenSeparators={[","]}
            placeholder="Select Values"
            style={{ width: "100%" }}
            optionFilterProp="label"
            tagRender={TagRender}
            value={value}
        />
    );
};
