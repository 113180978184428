import { FC, useState } from "react";
import { Button } from "antd";
import { Dashes } from "@app/core/components";

interface Props {
    limit?: number;
    values?: string[];
}

export const SeeMoreUnorderedList: FC<Props> = ({ limit = 5, values }) => {
    const [isSeeingMore, setIsSeeingMore] = useState(false);

    if (!values?.length) {
        return (
            <span>
                <Dashes />
            </span>
        );
    }

    return (
        <>
            <ul style={{ margin: 0 }}>
                {values.slice(0, isSeeingMore ? values.length : limit).map((value, i) => (
                    <li key={`${value}-${i}`}>{value}</li>
                ))}
            </ul>
            {values.length > limit && (
                <Button type="link" onClick={() => setIsSeeingMore((v) => !v)}>
                    See {isSeeingMore ? "Less" : "More"}
                </Button>
            )}
        </>
    );
};
