import { useState } from "react";
import { Form, notification } from "antd";
import { useSubmitFeedbackMutation } from "@app/core/services";
import { useAppSelector } from "@app/core/store";

interface FeedbackForm {
    name: string;
    userEmail: string;
    companyName: string;
    feedbackCategory: string;
    body: string;
}

export const addUriToBody = (values: FeedbackForm, uri: string) => {
    return {
        ...values,
        body: `Submitted From: ${uri}\n\n${values.body}`,
    };
};

export const useFeedbackDrawer = () => {
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm<FeedbackForm>();
    const [submitFeedback, { isLoading }] = useSubmitFeedbackMutation();
    const session = useAppSelector((state) => state.authClient.session);

    const onOpen = () => setOpen(true);

    const onClose = () => {
        form.resetFields();
        setOpen(false);
    };

    const onSubmit = async () => {
        try {
            await form.validateFields();
        } catch (e) {
            return;
        }

        const values = form.getFieldsValue();
        const result = await submitFeedback(addUriToBody(values, window.location.href));
        if ("error" in result) {
            const errorMessage =
                "data" in result.error
                    ? (result.error.data as { errorDescription: string }).errorDescription
                    : "Failed to submit feedback";
            notification.error({ message: "Feedback Form Error", description: errorMessage });
            return;
        }
        onClose();
    };

    return {
        form,
        isLoading,
        onClose,
        onOpen,
        onSubmit,
        open,
        session,
    };
};
