import { Layout } from "antd";
import TargetingPageHeader from "./TargetingPageHeader";
import { TargetingTable } from "./TargetingTable";

export const TargetingPage = () => {
    return (
        <>
            <TargetingPageHeader />
            <Layout.Content>
                <TargetingTable />
            </Layout.Content>
        </>
    );
};
