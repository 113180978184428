import { FC } from "react";
import { Button, Space, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import { useUserAccess } from "@app/core/auth";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useDeleteAdvertiserDomainMutation } from "@app/core/services";
import { BrandSafetyDeleteModal } from "../../BrandSafetyDeleteModal";
import { useBrandSafetyDeleteModal } from "../../BrandSafetyDeleteModal/useBrandSafetyDeleteModal";

interface Props {
    seatId: string;
    aDomainId: number;
    name: string;
    postDeleteEvent?: () => void;
}

export const AdvertiserDomainActions: FC<Props> = ({ seatId, aDomainId, name, postDeleteEvent }) => {
    const { canEditSeat } = useUserAccess();
    const history = useHistory();

    const [deleteTrigger, { isLoading: isDeleting }] = useDeleteAdvertiserDomainMutation();
    const { handleOpen, ...restModalProps } = useBrandSafetyDeleteModal(deleteTrigger, postDeleteEvent);

    return canEditSeat ? (
        <Space>
            <Button
                data-sdet="edit-button"
                onClick={() =>
                    history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAIN_EDIT(seatId, aDomainId))
                }
                type="primary"
            >
                Edit
            </Button>

            <Tooltip title="Delete">
                <Button
                    danger
                    data-sdet="delete-button"
                    type="primary"
                    onClick={() => handleOpen({ id: aDomainId, name })}
                >
                    Delete
                </Button>
            </Tooltip>
            <BrandSafetyDeleteModal isDeleting={isDeleting} {...restModalProps} />
        </Space>
    ) : null;
};
