import { css } from "@emotion/css";
import { ResizableDrawer } from "@rubicon/antd-components";
import { FC, ReactNode } from "react";

interface Props {
    open: boolean;
    title: ReactNode;
    closeDrawer: () => void;
}
export const TargetingDrawer: FC<Props> = ({ open, closeDrawer, title, children }) => {
    return (
        <ResizableDrawer
            open={open}
            onClose={closeDrawer}
            placement="right"
            title={title}
            width="50%"
            className={css`
                .ant-drawer-body {
                    padding: 0;
                }
                .ant-drawer-close {
                    position: absolute;
                    top: 20px;
                    right: 0;
                }
            `}
        >
            {children}
        </ResizableDrawer>
    );
};
