import { FC } from "react";
import { Card, Row, Col } from "antd";
import { Seat } from "@app/core/services";
import { useUserAccess } from "@app/core/auth";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import {
    SeatLabels,
    LabelsInternalOnly,
} from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/SeatDetailsFormFields";

interface LabelsSectionProps {
    edit?: boolean;
    seat: Seat;
}

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 };

export const LabelsSection: FC<LabelsSectionProps> = ({ edit = false, seat }) => {
    const { isSysAdmin, isPubAcctMgr } = useUserAccess();
    const isInternalOnly: boolean = isSysAdmin || isPubAcctMgr;
    return (
        <Card bordered={false}>
            <Row gutter={16}>
                <Col {...COL_SIZES}>
                    <SeatLabels {...INVENTORY_FORM_FIELDS.LABELS} seat={seat} edit={edit} />
                </Col>
                {isInternalOnly && (
                    <Col {...COL_SIZES}>
                        <LabelsInternalOnly seat={seat} edit={edit} />
                    </Col>
                )}
            </Row>
        </Card>
    );
};
