import { FC } from "react";
import { ReportsIFrame } from "./ReportsIFrame";
import conf from "@app/core/conf";
import { useParams } from "react-router-dom";

export const ReportsResultsIFramePage: FC = () => {
    const params = useParams<{ executionCode: string }>();

    return <ReportsIFrame title="Reports" src={(seatId) => `${conf.mctvConsoleRoot}/ssp/seats/${seatId}/reports/custom/results?executionCode=${params.executionCode}`} />;
};
