import { DealEntityTypes, DealTypeIds } from "@app/features/seatAdSources/constants";
import { MarketplaceInfo } from "@app/core/services";

export const findDealsWithSpecificTypes = ({
    deals = [],
    dealTypes = [],
    entityTypes = [],
}: {
    deals: MarketplaceInfo[];
    dealTypes?: DealTypeIds[];
    entityTypes?: DealEntityTypes[];
}) => {
    const dealTypesToFind = dealTypes.length
        ? dealTypes
        : [DealTypeIds.PROGRAMMATIC_GUARANTEED, DealTypeIds.FIXED_PRICE, DealTypeIds.AUCTION_PRICE, DealTypeIds.LEGACY];

    const entityTypesToFind = entityTypes.length
        ? entityTypes
        : [DealEntityTypes.Demand, DealEntityTypes.Buyer, DealEntityTypes.Legacy];

    return deals.filter((dealItem) => {
        return (
            dealTypesToFind.includes(dealItem.deal.dealType.id) &&
            entityTypesToFind.includes(dealItem.deal.entityType as DealEntityTypes)
        );
    });
};
