import { Component, ErrorInfo } from "react";
import { ErrorBoundaryPage } from "./ErrorBoundaryPage";

interface ErrorBoundaryState {
    error: Error | null;
    info: ErrorInfo | null;
}

export class ErrorBoundary extends Component<unknown, ErrorBoundaryState> {
    constructor(props) {
        super(props);
        this.resetErrorState = this.resetErrorState.bind(this);

        this.state = { error: null, info: null };
    }

    componentDidCatch(error, info) {
        this.setState({
            error,
            info,
        });
    }

    resetErrorState() {
        this.setState({
            error: null,
            info: null,
        });
    }

    render() {
        if (this.state.error) {
            return <ErrorBoundaryPage error={this.state.error} resetErrorState={this.resetErrorState} />;
        }

        return this.props.children;
    }
}
