import { FC } from "react";
import { AdUnitMaxDuration } from "@app/features/inventory/components/FormItems";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const MaxDuration: FC = () => (
    <AdUnitMaxDuration
        name={CHANNEL_FORM_FIELDS.MAX_DURATION.name}
        label={CHANNEL_FORM_FIELDS.MAX_DURATION.label}
        placeholder={CHANNEL_FORM_FIELDS.MAX_DURATION.placeholder}
    />
);
