import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { HierarchicalPage } from "./HierarchicalPage";
import InventoryAdvancedBrandSafetyPage from "@app/features/inventory/InventoryBrandSafety/InventoryAdvancedBrandSafetyPage";

export const InventoryBrandSafetyPageSwitch: FC = () => {
    return (
        <Switch>
            <Route path={ROUTES.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL}>
                <HierarchicalPage />
            </Route>
            <Route
                exact
                path={[
                    ROUTES.SEAT_INVENTORY_BRAND_SAFETY_ADVANCED,
                    ROUTES.SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_DETAILS,
                    ROUTES.SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_DEFINITION,
                ]}
            >
                <InventoryAdvancedBrandSafetyPage />
            </Route>
        </Switch>
    );
};
