import { FC } from "react";
import { Form, Radio } from "antd";
import { INVENTORY_YES_NO_OPTIONS, CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useUnwrpaVast } from "./useUnwrapVast";

export const UnwrapVast: FC = () => {
    const allowVastUnwrapping = useUnwrpaVast();
    if (!allowVastUnwrapping) {
        return null;
    }
    return (
        <Form.Item name={CHANNEL_FORM_FIELDS.UNWRAP_VAST.name} label={CHANNEL_FORM_FIELDS.UNWRAP_VAST.label}>
            <Radio.Group options={INVENTORY_YES_NO_OPTIONS} />
        </Form.Item>
    );
};
