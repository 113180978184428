import { TagRender } from "@app/core/components";
import { LoadOnScrollSelect } from "@rubicon/antd-components";
import { useBuyerSeatsSelect } from "./useBuyerSeatsSelect";

export const BuyerSeatsSelect = () => {
    const { isFetching, hasMore, loadMore, options, onChange, onSearch, searchValue, value } = useBuyerSeatsSelect();

    return (
        <LoadOnScrollSelect
            allowClear
            showSearch
            labelInValue
            value={value}
            hasMore={hasMore}
            options={options}
            loadMore={loadMore}
            mode="multiple"
            tagRender={TagRender}
            onSearch={onSearch}
            onChange={onChange}
            filterOption={false}
            loading={isFetching}
            searchValue={searchValue}
            placeholder="Search by Demand or Buyer Seat Code"
        />
    );
};
