import { FC } from "react";
import { useThrottle } from "@app/core/utils";
import { Currency } from "@app/core/services";
import { useUserAccess } from "@app/core/auth";
import {
    AdStat,
    getAverageFillTime,
    getBreaksPerPlaylist,
    getFillRate,
    getFills,
    getImpressions,
    getImpressionsUseRate,
    getOtimesForThrottleUnit,
    getPlaylistRequests,
    getPodRequests,
    getPodsAndPlaylistSlots,
    getRequests,
    getSlotsPerBreak,
    getSlotsPerPlaylist,
    getTotalRejectedRequests,
    getTotalRevenue,
} from "@app/features/adStats";
import { useAppSelector } from "@app/core/store";
import { MetricsCard } from "@rubicon/antd-components";
import { useAnimatedNumber } from "@app/features/dashboard/useAnimatedNumber";
import { useCurrencyConversion } from "@app/features/dashboard/useCurrencyConversion";
import { Space, Typography } from "antd";
import { RevenueTypes } from "@app/core/clients/console";
import { ChartColors } from "@app/core/components/constants";
import { formatNumber } from "@rubicon/utils";

interface Props {
    adStat: AdStat | AdStat[];
    handleExtend: () => void;
    isExtended: boolean;
}

export const LiveStatsMetrics: FC<Props> = ({ adStat, handleExtend, isExtended = false }) => {
    const { isTremorUser } = useUserAccess();
    const hasLoadedAdStat = Boolean(adStat);
    const currencyConversionMode = useAppSelector((state) => state.dashboard.settings.values.currencyConversionMode);
    const { preferredCurrency, currencyConversions } = useCurrencyConversion();
    const otimes = getOtimesForThrottleUnit(adStat);

    const throttledAdStat = useThrottle(adStat, {
        forceUpdateDependencies: [otimes, preferredCurrency, currencyConversionMode],
    });

    const totalGrossRev = useAnimatedNumber({
        value: getTotalRevenue(
            throttledAdStat,
            preferredCurrency as Currency,
            currencyConversions,
            currencyConversionMode,
            RevenueTypes.GROSS_REVENUE
        ),
        isPlaying: hasLoadedAdStat,
    });
    const totalNetRev = useAnimatedNumber({
        value: getTotalRevenue(
            throttledAdStat,
            preferredCurrency as Currency,
            currencyConversions,
            currencyConversionMode,
            RevenueTypes.NET_REVENUE
        ),
        isPlaying: hasLoadedAdStat,
    });
    const impressions = useAnimatedNumber({
        value: getImpressions(throttledAdStat, preferredCurrency as Currency, currencyConversionMode),
        isPlaying: hasLoadedAdStat,
    });
    const fills = useAnimatedNumber({
        value: getFills(throttledAdStat, preferredCurrency as Currency, currencyConversionMode),
        isPlaying: hasLoadedAdStat,
    });
    const requests = useAnimatedNumber({
        value: getRequests(throttledAdStat),
        isPlaying: hasLoadedAdStat,
    });
    const rejectedRequests = useAnimatedNumber({
        value: getTotalRejectedRequests(throttledAdStat as AdStat, isTremorUser),
        isPlaying: hasLoadedAdStat,
    });
    const fillRate = useAnimatedNumber({
        value: getFillRate(throttledAdStat, preferredCurrency as Currency, currencyConversionMode, false),
        isPlaying: hasLoadedAdStat,
    });
    const impressionsUseRate = useAnimatedNumber({
        value: getImpressionsUseRate(throttledAdStat, preferredCurrency as Currency, currencyConversionMode, false),
        isPlaying: hasLoadedAdStat,
    });
    const adPodRequests = useAnimatedNumber({
        value: getPodRequests(throttledAdStat),
        isPlaying: hasLoadedAdStat,
    });

    const playlistRequests = useAnimatedNumber({
        value: getPlaylistRequests(throttledAdStat),
        isPlaying: hasLoadedAdStat,
    });

    const podSlotRequests = useAnimatedNumber({
        value: getPodsAndPlaylistSlots(throttledAdStat),
        isPlaying: hasLoadedAdStat,
    });

    const averageFillTime = useAnimatedNumber({
        value: getAverageFillTime(throttledAdStat, preferredCurrency as Currency, currencyConversionMode),
        isPlaying: hasLoadedAdStat,
    });
    const breaksPerPlaylist = useAnimatedNumber({
        value: getBreaksPerPlaylist(throttledAdStat),
        isPlaying: hasLoadedAdStat,
    });
    const slotsPerBreak = useAnimatedNumber({
        value: getSlotsPerBreak(throttledAdStat),
        isPlaying: hasLoadedAdStat,
    });
    const slotsPerPlaylist = useAnimatedNumber({
        value: getSlotsPerPlaylist(throttledAdStat),
        isPlaying: hasLoadedAdStat,
    });

    const items = [
        {
            title: "Rejected Requests",
            value: formatNumber.asWhole(rejectedRequests),
            color: ChartColors.Red,
        },
        {
            title: "Requests",
            value: formatNumber.asWhole(requests),
        },
        {
            title: "Ad Pod Requests",
            value: formatNumber.asWhole(adPodRequests),
        },
        {
            title: "Playlist Requests",
            value: formatNumber.asWhole(playlistRequests),
        },
        {
            title: "Pod Slot Requests",
            value: formatNumber.asWhole(podSlotRequests),
        },
        {
            title: "Fills",
            value: formatNumber.asWhole(fills),
        },
        {
            title: "Impressions",
            value: formatNumber.asWhole(impressions),
        },
        {
            title: "Gross Revenue",
            value: formatNumber.asMoney(totalGrossRev, preferredCurrency?.code || "USD"),
        },
        {
            title: "Net Revenue",
            value: formatNumber.asMoney(totalNetRev, preferredCurrency?.code || "USD"),
        },
        {
            title: "Fill Rate",
            value: formatNumber.asPercent(fillRate),
        },

        {
            title: "Use Rate",
            value: formatNumber.asPercent(impressionsUseRate),
        },
        {
            title: "Avg. Fill time",
            value: `${formatNumber.asWhole(averageFillTime)} ms`,
        },
        {
            title: "Breaks Per Playlist",
            value: formatNumber.asWhole(breaksPerPlaylist),
        },
        {
            title: "Slots Per Break",
            value: formatNumber.asNumber(slotsPerBreak),
        },
        {
            title: "Slots Per Playlist",
            value: formatNumber.asWhole(slotsPerPlaylist),
        },
    ];

    return (
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <Typography.Text strong>Total Live Stats</Typography.Text>
            <MetricsCard items={items} handleExtend={handleExtend} isExtended={isExtended} isLoading={!adStat} />
        </Space>
    );
};
