import {
    BrandSupply,
    BrandSupplyPayload,
    useCreateSupplyMutation,
    useUpdateSupplyMutation,
    useDeleteSupplyMutation,
} from "@app/core/services";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { LabeledValue } from "antd/lib/select";
import { useUserAccess } from "@app/core/auth";
import { FormInstance, notification } from "antd";
import { Store } from "rc-field-form/lib/interface";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import {
    SUPPLY_CTV_TYPE,
    InventoryCodeType,
    SUPPLY_FORM_FIELDS,
    SUPPLY_LINEAR_TYPE,
    SUPPLY_SITE_TYPE,
} from "@app/features/inventory/HierarchyForms/constants";
import {
    getSupplyFormInitialValues,
    parseSupplyFormValuesToApi,
} from "@app/features/inventory/HierarchyForms/SupplyForm/supplyFormUtils";
import { DEFAULT_FALLBACK_MESSAGE } from "@app/features/inventory/constants";
import { INVENTORY_FLOORS_ADVANCED } from "@app/features/inventory/InventoryFloors";
import { useSupplyFormInstance } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormInstance";
import { LabelValueOption } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/useSeatDetailsForm";
import { InventoryDetailsDrawerType } from "../../DetailsDrawer/reducer";

export interface SupplyForm {
    // General Section
    [SUPPLY_FORM_FIELDS.NAME.name]: string;
    [SUPPLY_FORM_FIELDS.CODE_TYPE.name]: InventoryCodeType;
    [SUPPLY_FORM_FIELDS.CODE.name]: string;
    [SUPPLY_FORM_FIELDS.DESCRIPTION.name]: string;
    [SUPPLY_FORM_FIELDS.TYPE.name]: number;
    [SUPPLY_FORM_FIELDS.MODE.name]: boolean;
    // Affiliate Section
    [SUPPLY_FORM_FIELDS.AFFILIATE_COST_MODEL.name]: LabeledValue;
    [SUPPLY_FORM_FIELDS.AFFILIATE_COST_VALUE_PERCENT.name]: number | null;
    [SUPPLY_FORM_FIELDS.AFFILIATE_COST_VALUE_FIXED.name]: number | null;
    // Brand Safety Section
    [SUPPLY_FORM_FIELDS.BLOCK_ADVERTISER_DOMAIN_LISTS.name]: LabeledValue[];
    [SUPPLY_FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: string;
    [SUPPLY_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.name]: LabeledValue | null;
    // Site Details Section
    [SUPPLY_FORM_FIELDS.EXTERNAL_NAME.name]: string;
    [SUPPLY_FORM_FIELDS.OVERRIDE.name]: boolean;
    [SUPPLY_FORM_FIELDS.DOMAIN.name]: string | null;
    [SUPPLY_FORM_FIELDS.INVENTORY_PARTNER_DOMAIN.name]: string | null;
    // App Details Section
    [SUPPLY_FORM_FIELDS.APP_STORE.name]: LabeledValue;
    [SUPPLY_FORM_FIELDS.BUNDLE_ID.name]: string;
    [SUPPLY_FORM_FIELDS.BUNDLE_ID_IS_OVERRIDE.name]: boolean;
    [SUPPLY_FORM_FIELDS.APP_NAME.name]: string;
    [SUPPLY_FORM_FIELDS.STORE_URL.name]: string;
    [SUPPLY_FORM_FIELDS.PAID_TYPE.name]: number;
    // Exchange Safety Section
    [SUPPLY_FORM_FIELDS.SUPPLY_DOMAIN_LISTS.name]: LabeledValue[];
    [SUPPLY_FORM_FIELDS.APP_BUNDLE_ID_LISTS.name]: LabeledValue[];
    // Internal Section
    [SUPPLY_FORM_FIELDS.RESELL_TYPE.name]: LabeledValue;
    [SUPPLY_FORM_FIELDS.ENABLE_NO_AD_COOKIE_SYNC.name]: boolean;
    [SUPPLY_FORM_FIELDS.LOWER_CALCULON_USE_RATE_OVERRIDE.name]: number | null;
    [SUPPLY_FORM_FIELDS.UPPER_CALCULON_USE_RATE_OVERRIDE.name]: number | null;
    [SUPPLY_FORM_FIELDS.PUBLISHER_RE_AUCTION.name]: boolean | string;
    // Supply Details Section
    [SUPPLY_FORM_FIELDS.VIDEO_QUALITY.name]: LabeledValue;
    [SUPPLY_FORM_FIELDS.SOURCE_RELATIONSHIP.name]: number;
    [SUPPLY_FORM_FIELDS.IFA_MODE.name]: boolean;
    [SUPPLY_FORM_FIELDS.IFA_TYPE.name]: LabeledValue;
    [SUPPLY_FORM_FIELDS.ALLOW_VAST_WRAPPERS.name]: boolean;
    [SUPPLY_FORM_FIELDS.COPPA.name]: boolean | string;
    [SUPPLY_FORM_FIELDS.PRIVACY_POLICY.name]: boolean | string;
    [SUPPLY_FORM_FIELDS.VIEWABILITY_VENDORS.name]: LabeledValue[];
    [SUPPLY_FORM_FIELDS.KEYWORDS.name]: Array<string | number>;
    [SUPPLY_FORM_FIELDS.MASK_IFA_FOR_DEMANDS.name]: LabeledValue[] | undefined;
    // Custom Pixels
    [SUPPLY_FORM_FIELDS.CUSTOM_PIXELS.name]: LabeledValue[];
    // Labels
    [SUPPLY_FORM_FIELDS.LABELS.name]: LabelValueOption[];
    [SUPPLY_FORM_FIELDS.INTERNAL_LABELS.name]: LabelValueOption[];
    // Waterfall Prefiltering
    [SUPPLY_FORM_FIELDS.PREFILTER_LABEL_VALUES.name]: LabelValueOption[];
}

export interface UseSupplyForm {
    open: boolean;
    loading: boolean;
    isEditMode: boolean;
    isDeleting: boolean;
    initialValues: Store;
    showModal: () => void;
    handleCancel: () => void;
    handleDelete: () => void;
    submitButtonTitle: string;
    hasSeatWriteAccess: boolean;
    isFloorsSectionShown: boolean;
    handleCancelModal: () => void;
    form: FormInstance<SupplyForm>;
    supply: BrandSupply | undefined;
    isInternalSectionShown: boolean;
    handleAdvancedFloors: () => void;
    isAffiliateSectionShown: boolean;
    isAppDetailsSectionShown: boolean;
    isSiteDetailsSectionShown: boolean;
    isExchangeSafetySectionShown: boolean;
    handleSubmit: () => void;
}

export const useSupplyForm = (): UseSupplyForm => {
    const history = useHistory();
    const [open, setOpen] = useState<boolean>(false);
    const { isSysAdmin, isPubAcctMgr, isTremorUser, hasInternalAccess, hasSeatWriteAccess } = useUserAccess();
    const { supply, mode, brandId, form, seatId, useWatch, publisherId, seat } = useSupplyFormInstance();
    const isAffiliateSectionShown: boolean = isTremorUser && !!seat?.affiliateCostEnabled;
    const submitButtonTitle = mode !== "create" ? "Save" : "Submit";
    const isEditMode: boolean = mode === "edit";

    const initialValues: Store = getSupplyFormInitialValues({ supply, mode });

    const [createSupply, { isLoading: isCreating }] = useCreateSupplyMutation();
    const [updateSupply, { isLoading: isUpdating }] = useUpdateSupplyMutation();
    const [deleteSupply, { isLoading: isDeleting }] = useDeleteSupplyMutation();

    const handleCancelModal = (): void => setOpen(false);
    const showModal = (): void => setOpen(true);
    const handleCancel = (): void => history.goBack();

    const handleCreate = async (body: BrandSupplyPayload): Promise<void> => {
        if (!brandId) return notification.error({ message: DEFAULT_FALLBACK_MESSAGE });
        try {
            const res = await createSupply({ brandId, body }).unwrap();
            notification.success({ message: "Supply created successfully" });
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT(
                    InventoryDetailsDrawerType.SUPPLY,
                    seatId,
                    publisherId,
                    brandId,
                    res.id
                )
            );
        } catch (err) {
            notification.error({ message: err?.data?.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        }
    };
    const handleUpdate = async (body: BrandSupplyPayload): Promise<void> => {
        if (!supply) return notification.error({ message: DEFAULT_FALLBACK_MESSAGE });
        try {
            await updateSupply({ ...body, id: supply.id }).unwrap();
            notification.success({ message: "Supply updated successfully" });
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT(
                    InventoryDetailsDrawerType.SUPPLY,
                    seatId,
                    publisherId,
                    brandId,
                    supply.id
                )
            );
        } catch (err) {
            notification.error({ message: err?.data?.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        }
    };
    const handleDelete = async (): Promise<void> => {
        if (!isEditMode || !supply) return notification.error({ message: DEFAULT_FALLBACK_MESSAGE });
        try {
            await deleteSupply(supply.id).unwrap();
            notification.success({ message: "Supply deleted successfully" });
            history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH(seatId));
        } catch (err) {
            notification.error({ message: err?.data?.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        }
    };
    const handleSubmit = async (): Promise<void> => {
        try {
            await form.validateFields();
            const payload: BrandSupplyPayload = parseSupplyFormValuesToApi({
                seat,
                mode,
                brandId,
                isSysAdmin,
                isPubAcctMgr,
                isTremorUser,
                hasInternalAccess,
                values: form.getFieldsValue(),
            });
            if (isEditMode) return handleUpdate(payload);
            return handleCreate(payload);
        } catch (error) {
            form.scrollToField(error.errorFields[0].name, { behavior: "smooth", block: "center" });
        }
    };

    const handleAdvancedFloors = (): void =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_TABS(seatId, INVENTORY_FLOORS_ADVANCED));

    const type: number = useWatch(SUPPLY_FORM_FIELDS.TYPE.name, form);
    const isDynamic: boolean = useWatch(SUPPLY_FORM_FIELDS.MODE.name, form);

    const isCTVType: boolean = type === SUPPLY_CTV_TYPE.value;
    const isAppDetailsSectionShown: boolean = isCTVType && !isDynamic;

    const isLinearType: boolean = type === SUPPLY_LINEAR_TYPE.value;
    const isSiteType: boolean = type === SUPPLY_SITE_TYPE.value;

    const isSiteDetailsSectionShown: boolean = (isSiteType || isLinearType) && !isDynamic;

    return {
        open,
        form,
        supply,
        showModal,
        isEditMode,
        isDeleting,
        handleCancel,
        handleSubmit,
        handleDelete,
        initialValues,
        handleCancelModal,
        submitButtonTitle,
        hasSeatWriteAccess,
        handleAdvancedFloors,
        isAffiliateSectionShown,
        isAppDetailsSectionShown,
        isSiteDetailsSectionShown,
        isFloorsSectionShown: isEditMode,
        loading: isCreating || isUpdating,
        isInternalSectionShown: isTremorUser,
        isExchangeSafetySectionShown: isSysAdmin || isPubAcctMgr,
    };
};
