import { FC } from "react";
import { useSeatAuthContext } from "@app/core/auth";
import { Loading, NavBar } from "@app/core/components";
import { SeatAdSourcesSwitch } from "./SeatAdSourcesSwitch";
import { SeatAdSourcesNavMenu } from "@app/features/seatAdSources/SeatAdSourcesNavMenu/SeatAdSourcesNavMenu";
import { useSetInitialSettings } from "./useSetInitialSettings";

export const SeatAdSources: FC = () => {
    const { context, session } = useSeatAuthContext();
    useSetInitialSettings();

    if (!context || !session) {
        return <Loading />;
    }

    return (
        <>
            <NavBar label="Ad Sources" />
            <SeatAdSourcesNavMenu />
            <SeatAdSourcesSwitch />
        </>
    );
};
