import { Form } from "antd";
import { AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { AD_SOURCE_FIELDS, AD_SOURCE_TYPES_NEXT_UI_NAMES, AdSourceTypes } from "@app/features/seatAdSources/constants";
import { SeatAdSourcesType } from "@app/core/services";

interface UseDurationField {
    setFieldValue: (name: keyof AdSourcesForm, value: string | null) => void;
    isVisible: boolean;
}

const { useFormInstance, useWatch } = Form;

export const useDurationField = (): UseDurationField => {
    const { setFieldValue } = useFormInstance<AdSourcesForm>();
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);
    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSourceType?.name];

    const isVisible = adSourceTypeName !== AdSourceTypes.SERVER_SIDE_DYNAMIC_PRICE;
    return {
        isVisible,
        setFieldValue,
    };
};
