import { useHistory, useLocation } from "react-router-dom";
import {
    FilterValue,
    SortOrder,
    SorterResult,
    TableCurrentDataSource,
    TablePaginationConfig,
} from "antd/es/table/interface";
import { table, uri } from "@rubicon/utils";
import { SearchParam } from "..";

export const SORT: SearchParam = { key: "sort", default: "name" };
export const STATUS: SearchFilterParam = { key: "status", default: "", reset: "" };

export type SearchFilterParam = {
    key: string;
    default: string;
    reset: string;
};
type UseTableChanges = {
    filters: { [key: string]: string[] };
    onChange: <T>(
        pagination: TablePaginationConfig,
        changedFilters: Record<string, FilterValue | null>,
        sorter: SorterResult<T> | SorterResult<T>[],
        extra: TableCurrentDataSource<T>
    ) => void;
    queryString: string;
    sortBy: string;
    sortOrder: SortOrder;
};

type Params = {
    sortParam?: SearchParam;
    filtersParams?: Array<SearchFilterParam>;
    onSortChange?: (sortBy: string, sortOrder: string) => void;
    handlePaginateChange?: (page: number, pageSize: number) => void;
};

export const useTableChanges = ({
    sortParam = SORT,
    filtersParams = [STATUS],
    onSortChange,
    handlePaginateChange,
}: Params): UseTableChanges => {
    const history = useHistory();
    const { search: queryString } = useLocation();

    const sort = uri.getSearchParam(queryString, sortParam.key) || sortParam.default;
    const isDescending = table.isDescending(sort);
    const sortBy = table.getSortField(sort);
    const sortOrder = isDescending ? "descend" : "ascend";

    const filters = filtersParams.reduce((acc, param) => {
        const defaultFilter = param.default ? [param.default] : [];
        const paramValue = uri.getSearchParam(queryString, param.key);
        const value = paramValue ? paramValue.split(",") : defaultFilter;
        return {
            ...acc,
            [param.key]: value,
        };
    }, {});

    const onChange = (pagination, changedFilters, sorter, { action }) => {
        if (action === "paginate") {
            handlePaginateChange?.(pagination.current, pagination.pageSize);
        } else if (action === "filter") {
            const nextQueryString = filtersParams.reduce(
                (nextQueryString, param) =>
                    uri.setSearchParam(
                        nextQueryString,
                        param.key,
                        changedFilters[param.key] ? changedFilters[param.key].join(",") : param.reset
                    ),
                queryString
            );

            history.push({ search: nextQueryString });
        } else {
            onSortChange?.(sorter.columnKey, sorter.order);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            table.handleSort(sort, sorter.columnKey, queryString, history.push);
        }
    };

    return {
        filters,
        onChange,
        queryString,
        sortBy,
        sortOrder,
    };
};
