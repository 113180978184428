import { FC } from "react";
import { Form, Radio } from "antd";
import { usePublisherFormInstance } from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormInstance";
import { INVENTORY_CODE_TYPE_OPTIONS, PUBLISHER_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
export const CodeType: FC = () => {
    const { mode } = usePublisherFormInstance();
    const hidden: boolean = mode === "edit";
    return (
        <Form.Item
            hidden={hidden}
            name={PUBLISHER_FORM_FIELDS.CODE_TYPE.name}
            label={PUBLISHER_FORM_FIELDS.CODE_TYPE.label}
        >
            <Radio.Group options={INVENTORY_CODE_TYPE_OPTIONS} />
        </Form.Item>
    );
};
