import { css } from "@emotion/css";

export const DEFAULT_FALLBACK_MESSAGE = "Something went wrong";
import { FloorsTableDataIndexes } from "@app/features/inventory/components/FloorsTable";

export const INVENTORY_LIST_PAGES = {
    DEALS: "deals",
    HEALTH: "health",
    SEAT: "seat",
    FLOORS: "floors",
    BRAND_SAFETY: "brand-safety",
    PERMISSIONS: "permissions",
};
export const EMPTY_HELP_KEYS_BODY = "The help article doesn't seem to exist yet!";

export const INTERNAL_LABEL_VALUES = "internalLabelValues";
export const AFFILIATE_COST_MODEL = "affiliateCostModel";
export const AFFILIATE_COST_VALUE_PERCENT = "affiliateCostValuePercent";
export const AFFILIATE_COST_VALUE_FIXED = "affiliateCostValueFixed";
export const DISTRIBUTION_GROUP_LABEL_VALUES = "distributionGroupLabelValues";
export const PREFILTER_LABEL_VALUES = "prefilterLabelValues";
export const LABEL_VALUES = "labelValues";
export const FREQUENCY_CAPPS = "adomainFreqCappings";
export const AD_BREAK_RULES = "adBreakRules";
export const AD_BREAK_RULES_PRE = "adBreakRulesPre";
export const AD_BREAK_RULES_MID = "adBreakRulesMid";
export const AD_BREAK_RULES_POST = "adBreakRulesPost";

export const email = "email";
export const group = "group";

export const INVENTORY_PERMISSIONS_FORM_NAMES = {
    EMAIL: "emailAddress",
    GROUP: "groupId",
    PERMISSIONS: "permission",
};
export const GUTTER = { xs: 8, sm: 16, md: 24, lg: 32, xl: 32, xxl: 32 };
export const COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 6, xxl: 6 };

export const TWO_COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 12 };
export const INNER_COL_SIZES = { xs: 14, sm: 12, md: 24 }; // according TWO_COL_SIZES

export const CREATE_FLOOR_FORM_ITEMS_NAME = {
    NAME: "name",
    PRICE: "price",
    COUNTRY: "country",
} as const;

export const LABEL_CLASS_LIST = css`
    label {
        width: 100%;
    }
`;
export const INVENTORY_CARD_BODY_STYLE = { padding: "1rem 1.5rem" };

export const FLOOR_PRICE_MODEL_KEY = "price";
export const FLOOR_COUNTRY_KEY = "country";
export const FLOOR_SOURCE_KEY = "source";
export const FLOOR_INHERITED_KEY = "inherited";
export const FLOOR_ACTUAL_PRICE_KEY = "actualPrice";
export const FLOOR_CUSTOM_PRICE_KEY = "customPrice";
export const FLOOR_ACTIONS_KEY = "actions";
export const FLOORS_TABLE_COLUMNS = [
    {
        width: "15%",
        title: "Floor Price",
        fixed: "left" as const,
        dataIndex: FLOOR_PRICE_MODEL_KEY as FloorsTableDataIndexes,
        sorter: (a, b) => a.price.props.record.price - b.price.props.record.price,
    },
    {
        title: "Country",
        dataIndex: FLOOR_COUNTRY_KEY as FloorsTableDataIndexes,
        sorter: (a, b) =>
            String(a.country.props.record.country?.label || "All Geos").localeCompare(
                String(b.country.props.record.country?.label || "All Geos")
            ),
    },
    {
        title: "Source",
        dataIndex: FLOOR_SOURCE_KEY as FloorsTableDataIndexes,
        sorter: (a, b) => String(a.source).localeCompare(String(b.source)),
    },
    {
        title: "Inherited",
        dataIndex: FLOOR_INHERITED_KEY as FloorsTableDataIndexes,
        sorter: (a, b) => String(a.inherited).localeCompare(String(b.inherited)),
    },
    {
        title: "Actual Price?",
        dataIndex: FLOOR_ACTUAL_PRICE_KEY as FloorsTableDataIndexes,
    },
    {
        title: "Custom Price",
        dataIndex: FLOOR_CUSTOM_PRICE_KEY as FloorsTableDataIndexes,
    },
    { title: "Actions", dataIndex: FLOOR_ACTIONS_KEY, width: 182, fixed: "right" as const },
];
