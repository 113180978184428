import { FC } from "react";
import { Card, Form, Row, Col } from "antd";
import { useUserAccess } from "@app/core/auth";
import { Seat, SupplySeat } from "@app/core/services";
import {
    Name,
    Code,
    Description,
    BusinessDomain,
    GeneralInternalOnly,
} from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/SeatDetailsFormFields";
import { formatDate } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/PacingSection/formatDate";
import { INVENTORY_FORM_FIELDS, SEAT_DETAILS_SECTION_COL_SIZES } from "@app/features/inventory/InventorySeat/constants";
import moment from "moment-timezone";

interface GeneralSectionProps {
    seat: Seat;
    edit?: boolean;
    supplySeat?: SupplySeat | null;
}

const COL_SIZES = { xs: 24, sm: 24, md: 8, lg: 8, xl: 8, xxl: 8 };
export const GeneralSection: FC<GeneralSectionProps> = ({ seat, supplySeat, edit = false }) => {
    const { isSysAdmin, isPubAcctMgr } = useUserAccess();
    const isInternalOnly: boolean = isSysAdmin || isPubAcctMgr;

    return (
        <Card bordered={false}>
            <Row gutter={16}>
                <Col {...SEAT_DETAILS_SECTION_COL_SIZES}>
                    <Name seat={seat} edit={edit} />
                    <Code seat={seat} edit={edit} />
                    {!edit && (
                        <Row gutter={16}>
                            <Col {...COL_SIZES}>
                                <Form.Item label={INVENTORY_FORM_FIELDS.DATE_CREATED.label}>
                                    {formatDate(moment(seat.creationDate))}
                                </Form.Item>
                            </Col>
                            <Col {...COL_SIZES}>
                                <Form.Item label={INVENTORY_FORM_FIELDS.LAST_UPDATED.label}>
                                    {formatDate(moment(seat.updateDate))}
                                </Form.Item>
                            </Col>
                            <Col {...COL_SIZES}>
                                <Form.Item label={INVENTORY_FORM_FIELDS.LAST_INDEXED.label}>
                                    {formatDate(moment(supplySeat?.lastIndexed))}
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    <BusinessDomain seat={seat} edit={edit} />
                    <Description seat={seat} edit={edit} />
                </Col>
                {isInternalOnly && (
                    <Col {...SEAT_DETAILS_SECTION_COL_SIZES}>
                        <GeneralInternalOnly seat={seat} edit={edit} />
                    </Col>
                )}
            </Row>
        </Card>
    );
};
