import { PageHeader } from "@ant-design/pro-layout";
import { Loading } from "@app/core/components";
import { useDeleteSeatSeparationGroupsMutation } from "@app/core/services";
import { Button, Layout } from "antd";
import { BrandSafetyDeleteModal } from "../../BrandSafetyDeleteModal";
import { useBrandSafetyDeleteModal } from "../../BrandSafetyDeleteModal/useBrandSafetyDeleteModal";
import { CompetitiveSeparationGroupsForm } from "../CompetitiveSeparationGroupsForm";
import { useCompetitiveSeparationGroupEditPage } from "./useCompetitiveSeparationGroupEditPage";

export const CompetitiveSeparationGroupEditPage = () => {
    const {
        name,
        isLoading,
        isSubmitting,
        handleBack,
        handleCancel,
        handlePostDelete,
        handleSubmit,
        initialValues,
        separationGroupId,
    } = useCompetitiveSeparationGroupEditPage();
    const [deleteTrigger, { isLoading: isDeleting }] = useDeleteSeatSeparationGroupsMutation();
    const { handleOpen, ...restModalProps } = useBrandSafetyDeleteModal(deleteTrigger, handlePostDelete);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Layout>
            <PageHeader title={`Edit: ${name}`} onBack={handleBack} />
            <CompetitiveSeparationGroupsForm
                initialValues={initialValues}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                deleteButton={
                    <>
                        <Button
                            danger
                            data-sdet="delete-button"
                            type="primary"
                            onClick={() => handleOpen({ id: separationGroupId, name })}
                            disabled={isSubmitting}
                        >
                            Delete
                        </Button>
                        <BrandSafetyDeleteModal isDeleting={isDeleting} {...restModalProps} />
                    </>
                }
            />
        </Layout>
    );
};
