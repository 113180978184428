import { useEffect, useState } from "react";
import { useAppSelector } from "@app/core/store";
import {
    InventoryDetailsDrawerType,
    selectInventoryDetailsDrawerType,
} from "@app/features/inventory/DetailsDrawer/reducer";
import {
    OptimizerEntityTypes,
    useGetCurrenciesQuery,
    useGetOptimizerQueriesQuery,
    useGetOptimizerQueriesResultsQuery,
    useGetHistoricalQuery,
    HistoricalQueriesResults,
} from "@app/core/services";
import { selectDashboardPreferredCurrency } from "@app/features/dashboard/reducer";
import { FloorAnalysisParams } from "@app/features/inventory/DetailsDrawer/DetailsDrawerContent/FloorAnalysisContent/FloorAnalysisFilters/useFloorAnalysisFilters";
import { useSeatAuthContext } from "@app/core/auth";

const TARGET_STATUS_CODE = 3;
const POLLING_INTERVAL = 1000;

interface HistoricalFloorAnalysisProps {
    id: number | string;
    dateRange: string;
    country?: string;
    adSourceType: number;
}

interface UseHistoricalFloorAnalysis {
    handleRunAnalysis: () => void;
    queryResults: HistoricalQueriesResults[] | undefined;
    isFetchingEstPrice: boolean;
    isFetching: boolean;
}

export const useHistoricalAnalysis = ({
    id,
    dateRange,
    country,
    adSourceType,
}: HistoricalFloorAnalysisProps): UseHistoricalFloorAnalysis => {
    const { isDemoContext } = useSeatAuthContext();
    const { data: currencies } = useGetCurrenciesQuery();
    const currencyCodeOrId = useAppSelector(selectDashboardPreferredCurrency);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [polling, setPolling] = useState<number>(POLLING_INTERVAL);
    const currency =
        typeof currencyCodeOrId === "number"
            ? currencyCodeOrId
            : (currencies || []).find((currency) => currency.code === currencyCodeOrId)?.id || undefined;

    const drawerType = useAppSelector(selectInventoryDetailsDrawerType);
    let entityType = OptimizerEntityTypes.SEAT;

    switch (drawerType) {
        case InventoryDetailsDrawerType.SEAT:
            entityType = OptimizerEntityTypes.SEAT;
            break;
        case InventoryDetailsDrawerType.PUBLISHER:
        case InventoryDetailsDrawerType.CHANNEL:
            entityType = OptimizerEntityTypes.PUBLISHER;
            break;
        case InventoryDetailsDrawerType.BRAND:
        case InventoryDetailsDrawerType.BRAND_CHANNEL:
            entityType = OptimizerEntityTypes.BRAND;
            break;
        case InventoryDetailsDrawerType.SUPPLY:
            entityType = OptimizerEntityTypes.SUPPLY;
            break;
        case InventoryDetailsDrawerType.AD_UNIT:
            entityType = OptimizerEntityTypes.AD_UNIT;
            break;
    }

    const {
        data: floorsOptimizer,
        isFetching: isFloorsOptimizerFetching,
        refetch: refetchFloorsHistorical,
    } = useGetHistoricalQuery({
        id,
        country,
        currency,
        entityType,
        adSourceType,
        async: true,
        [FloorAnalysisParams.DateRange]: dateRange,
    });
    const {
        currentData: query,
        isFetching: isQueryFetching,
        refetch: refetchQueries,
    } = useGetOptimizerQueriesQuery(
        { queries: floorsOptimizer?.code as string },
        { skip: !floorsOptimizer?.code || isDemoContext, pollingInterval: polling }
    );

    const { data: queryResults, isFetching: isQueryResultsFetching } = useGetOptimizerQueriesResultsQuery(
        { queries: floorsOptimizer?.code as string },
        { skip: query?.status !== TARGET_STATUS_CODE }
    );

    useEffect(() => {
        if (isQueryResultsFetching || isFloorsOptimizerFetching || isQueryFetching) setIsFetching(true);

        if (query?.status === TARGET_STATUS_CODE || isDemoContext) {
            setIsFetching(false);
            setPolling(0);
        } else {
            //INFO restart polling because the second response query is cached value
            setIsFetching(true);
            setPolling(POLLING_INTERVAL);
        }
    }, [
        isFloorsOptimizerFetching,
        isQueryResultsFetching,
        query,
        floorsOptimizer,
        refetchQueries,
        isQueryFetching,
        isDemoContext,
    ]);

    const handleRunAnalysis = (): void => {
        refetchFloorsHistorical();
        setPolling(POLLING_INTERVAL);
    };

    return {
        isFetching,
        isFetchingEstPrice: isQueryResultsFetching,
        queryResults: queryResults as HistoricalQueriesResults[],
        handleRunAnalysis,
    };
};
