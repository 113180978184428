import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { LabeledValue } from "antd/es/select";
import { useGetBundleIdFilterListIdAndNameQuery } from "@app/core/services";
import { SUPPLY_DOMAIN_AND_BUNDLE_ID_FILTER_LISTS_TYPE } from "@app/features/inventory/HierarchyForms/constants";

interface UseAppBundleIDLists {
    isFetching: boolean;
    options: LabeledValue[];
}

export const useAppBundleIDLists = (): UseAppBundleIDLists => {
    const { seatId } = useParams<{ seatId: string }>();
    // For Supply Domain and Bundle Id Filter Lists on Supply, only allow List Type 2(Allow Lists)
    const { data, isFetching } = useGetBundleIdFilterListIdAndNameQuery({
        seatId,
        type: SUPPLY_DOMAIN_AND_BUNDLE_ID_FILTER_LISTS_TYPE,
    });

    const options = useMemo(() => (data || []).map(([value, label]) => ({ value, label })), [data]);
    return {
        isFetching,
        options,
    };
};
