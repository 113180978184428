import { Form } from "antd";
import { FC } from "react";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import { BRAND_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const AdvertisersAsAllowList: FC = () => {
    return (
        <Form.Item {...BRAND_FORM_FIELDS.ADVERTISERS_AS_WHITELIST}>
            <BooleanRadioGroup
                trueLabel={BRAND_FORM_FIELDS.ADVERTISERS_AS_WHITELIST.trueLabel}
                falseLabel={BRAND_FORM_FIELDS.ADVERTISERS_AS_WHITELIST.falseLabel}
            />
        </Form.Item>
    );
};
