import { Form } from "antd";
import { FC } from "react";
import { SeatBuyerSeatLists } from "@app/features/inventory/components/Fields";
import { SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const BlockBuyerSeaList: FC = () => {
    return (
        <Form.Item
            name={SUPPLY_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.name}
            label={SUPPLY_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.label}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 }}
        >
            <SeatBuyerSeatLists placeholder={SUPPLY_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.placeholder} />
        </Form.Item>
    );
};
