import { FC } from "react";
import { Drawer } from "antd";
import { DrawerLayout } from "@app/core/components";
import { css } from "@emotion/css";
import { RootState, useAppDispatch, useAppSelector } from "@app/core/store";
import { closeChangelog } from "@app/features/inventory/InventoryChangelog";
import { ChangeHistoryTable } from "@app/core/components/ChangeHistoryTable";

const drawerClasses = css`
    .ant-drawer-body {
        padding: 0;
    }
`;

const ChangelogDrawer: FC = () => {
    const dispatch = useAppDispatch();
    const visible = useAppSelector((state: RootState) => state.inventoryChangelog.isDrawerOpen);
    const changelogEntity = useAppSelector((state: RootState) => state.inventoryChangelog.entity);
    const entityType = useAppSelector((state: RootState) => state.inventoryChangelog.entityType);

    const handleClose = () => dispatch(closeChangelog());

    return (
        <Drawer
            width="60%"
            placement="right"
            open={visible}
            closable={false}
            onClose={handleClose}
            className={drawerClasses}
        >
            <DrawerLayout closeHandler={handleClose} title={`Change History: ${changelogEntity?.name}`}>
                <ChangeHistoryTable entityId={changelogEntity?.id} entityType={entityType} />
            </DrawerLayout>
        </Drawer>
    );
};

export default ChangelogDrawer;
