import { FC } from "react";
import { ROUTES } from "@app/core/routing";
import { useUserAccess } from "@app/core/auth";
import { Route, Switch } from "react-router-dom";
import { SeatDetailsForm } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm";
import { AdvancedFeaturesForm } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/AdvancedFeaturesForm";
import { ExchangeProtectionsForm } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm";

export const InventorySeatEditPageSwitch: FC = () => {
    const { isSysAdmin, isPubAcctMgr, hasSeatWriteAccess } = useUserAccess();
    const isInternalOnly: boolean = isSysAdmin || isPubAcctMgr;
    return (
        <Switch>
            {hasSeatWriteAccess && (
                <Route exact path={ROUTES.SEAT_INVENTORY_SEAT_EDIT_DETAILS}>
                    <SeatDetailsForm />
                </Route>
            )}
            {isInternalOnly && hasSeatWriteAccess && (
                <Route exact path={ROUTES.SEAT_INVENTORY_SEAT_EDIT_PROTECTIONS}>
                    <ExchangeProtectionsForm />
                </Route>
            )}
            <Route exact path={ROUTES.SEAT_INVENTORY_SEAT_EDIT_ADVANCED}>
                <AdvancedFeaturesForm />
            </Route>
        </Switch>
    );
};
