import { LabeledValue } from "antd/lib/select";

export const TIME_ZONE_UTC = "UTC";

export const NON_UTC_TIME_FRAME_OPTIONS = [
    {
        id: "today",
        name: "Today",
        config: {
            dataProp: "hourlyData",
            timeProp: "hour",
            thisPeriod: "Today's Hour",
            lastPeriod: "Yesterday's Hour",
        },
    },
    {
        id: "yesterday",
        name: "Yesterday",
        config: {
            dataProp: "hourlyData",
            timeProp: "hour",
            thisPeriod: "Yesterday",
            lastPeriod: "last week's Yesterday",
        },
    },
    {
        id: "weekly",
        name: "This Week",
        config: {
            dataProp: "hourlyData",
            timeProp: "day",
            thisPeriod: "this week's Day",
            lastPeriod: "last week's Day",
        },
    },
    {
        id: "lastSevenDays",
        name: "Last 7 days",
        config: {
            dataProp: "hourlyData",
            timeProp: "day",
            thisPeriod: "last 7 Days",
            lastPeriod: "Prior Period",
        },
    },
];

// From console source src/main/webapp/js/vm/home/supplyhome.vm.js
export const TIME_FRAME_OPTIONS = [
    ...NON_UTC_TIME_FRAME_OPTIONS,
    {
        id: "monthly",
        name: "This Month",
        config: {
            dataProp: "dailyData",
            timeProp: "day",
            thisPeriod: "this Month",
            lastPeriod: "Prior Period",
        },
    },
    {
        id: "lastThirtyDays",
        name: " Last 30 days",
        config: {
            dataProp: "dailyData",
            timeProp: "day",
            thisPeriod: "last 30 Days",
            lastPeriod: "Prior Period",
        },
    },
    {
        id: "quarterly",
        name: "This Quarter",
        config: {
            dataProp: "dailyData",
            timeProp: "dayOfQuarter",
            thisPeriod: "this Quarter",
            lastPeriod: "Prior Period",
        },
    },
    {
        id: "yearly",
        name: "This Year",
        config: {
            dataProp: "monthlyData",
            timeProp: "month",
            thisPeriod: "this Year",
            lastPeriod: "Prior Period",
        },
    },
];
export const getTimeFrameOptionConfig = (id) => TIME_FRAME_OPTIONS.find((option) => option.id === id)?.config;

export const TIME_FRAME_OPTIONS_LABEL_VALUE = TIME_FRAME_OPTIONS.map<LabeledValue>((option) => ({
    value: option.id,
    label: option.name,
}));

export const NON_UTC_TIME_FRAME_OPTIONS_LABEL_VALUE = NON_UTC_TIME_FRAME_OPTIONS.map<LabeledValue>((option) => ({
    value: option.id,
    label: option.name,
}));

export const TODAY_LABEL_VALUE = TIME_FRAME_OPTIONS_LABEL_VALUE[0];
