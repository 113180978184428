interface Message {
    company: string;
    email: string;
    error: string;
    stackTrace: string;
    uri: string;
    user: string;
    maskingUser: string | null;
    version: string;
}

export const ErrorMessage = ({ user, maskingUser, email, company, uri, error, stackTrace, version }: Message) => ({
    blocks: [
        {
            type: "section",
            text: {
                type: "mrkdwn",
                text: `*Type:* 💥 Application Crash 💥`,
            },
        },
        {
            type: "divider",
        },
        {
            type: "section",
            text: {
                type: "mrkdwn",
                text: `*User:* ${user}`,
            },
        },
        maskingUser && {
            type: "section",
            text: {
                type: "mrkdwn",
                text: `*Masking User:* ${maskingUser}`,
            },
        },
        {
            type: "section",
            text: {
                type: "mrkdwn",
                text: `*Email:* ${email}`,
            },
        },
        {
            type: "section",
            text: {
                type: "mrkdwn",
                text: `*Company:* ${company}`,
            },
        },
        {
            type: "divider",
        },
        {
            type: "section",
            text: {
                type: "mrkdwn",
                text: `*Version:* ${version}`,
            },
        },
        {
            type: "section",
            text: {
                type: "mrkdwn",
                text: `*URI:* ${uri}`,
            },
        },
        {
            type: "section",
            text: {
                type: "mrkdwn",
                text: `*Error:* \`${error}\``,
            },
        },
        {
            type: "section",
            text: {
                type: "mrkdwn",
                text: `*Stack Trace:* \`\`\`\n${stackTrace}\n\`\`\``,
            },
        },
        {
            type: "divider",
        },
    ].filter(Boolean),
});
