import { Form } from "antd";
import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { useUserAccess } from "@app/core/auth";
import { useGetMarketplacesQuery } from "@app/core/services";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

const { useWatch } = Form;

interface UseAuthorizedMarketplaces {
    hasInternalAccess: boolean;
    options: LabeledValue[];
    isFetching: boolean;
}

export const useAuthorizedMarketplaces = (): UseAuthorizedMarketplaces => {
    const { data, isFetching } = useGetMarketplacesQuery();
    const { isSysAdmin, isPubAcctMgr } = useUserAccess();

    const authorizedMarketplaces: LabeledValue[] = useMemo(
        () =>
            (data || []).map((marketplace) => ({
                label: marketplace.name,
                value: marketplace.id,
            })),
        [data]
    );
    const marketplaces = useWatch(INVENTORY_FORM_FIELDS.AUTHORIZED_MARKETPLACES.name) || [];
    const values = marketplaces.map((option) => option?.value);
    const options = authorizedMarketplaces.filter((options) => !values.includes(options.value));

    return {
        options,
        isFetching,
        hasInternalAccess: isSysAdmin || isPubAcctMgr,
    };
};
