import { AuctionType } from "@app/core/services";
import { AdSourceTypeIds } from "../../constants";

const FIRST_PRICE_AUCTION_TYPE = {
    creationDate: "2014-05-22T18:12:32.000+0000",
    entityType: "AdSourceAuctionType",
    id: 1,
    key: 1,
    name: "1st Price Auction",
    updateDate: "2014-05-22T18:12:32.000+0000",
};

export const parseAuctionTypeToApi = (
    adSourceTypeId: AdSourceTypeIds,
    auctionType: AuctionType | null
): AuctionType | null => {
    if (adSourceTypeId === AdSourceTypeIds.CURATOR_MARKETPLACE) {
        return FIRST_PRICE_AUCTION_TYPE;
    }

    return auctionType;
};
