import { w3cwebsocket } from "websocket";
import { useSeatAuthContext } from "@app/core/auth";
import { parseMessageData } from "@app/core/socket/utils";
import { sendHeartbeatEvent, sendLoginEvent } from "@app/core/socket/events";
import { EventTypes, RawEventDataWithMessage } from "@app/core/socket/types";
import { useEffect, useRef, MutableRefObject, useState, SetStateAction } from "react";
import { sendStartHistoryStatsEvent, sendStartStatsEvent, sendStopStatsEvent } from "./events";
import {
    AdStat,
    AdStatsById,
    EntityTypes,
    AdStatHistoryById,
    AdStatEventMessage,
    AdStatHistoryEventMessage,
} from "./types";
import adStatHistoryDemoDataEvent from "@app/features/adStats/data/inventoryentitystathistory.json";
import adStatDemoDataEvent from "@app/features/adStats/data/supplyseatstat.json";
import { getRandomKey, RawData } from "@app/features/adStats/useDealAdStats";
import { getRandomNumber } from "@app/features/adStats/useAdStats";
import { useAppSelector } from "@app/core/store";
import { selectUserTimezone } from "@app/core/authClient/reducer";

interface UseInventoryHealthStats {
    adStatsById: AdStatsById;
    adStatsHistoryById: AdStatHistoryById;
    error: Error | null;
    isConnected: boolean;
    isAuthenticated: boolean;
    setEntityType: (type: SetStateAction<EntityTypes>) => void;
    setEntityIds: (ids: SetStateAction<number[]>) => void;
    entityIds: number[];
}

const ADSTATS_WEBSOCKET = "wss://adstats.cast.telaria.com/cast/websocket/adstats";
const HEARTBEAT_INTERVAL = 1000 * 30;

const incrementDemoAdStats = (mockInventoryAdStats: { [id: number]: AdStat }, itemsIds: number[]) => {
    if (itemsIds && itemsIds.length > 0) {
        return itemsIds.reduce((acc, itemId) => {
            const mockInventoryId = getRandomKey(mockInventoryAdStats);
            let adStat;
            if (mockInventoryAdStats && mockInventoryAdStats[itemId]) {
                adStat = { ...mockInventoryAdStats[itemId] };
            } else if (mockInventoryAdStats && mockInventoryAdStats[mockInventoryId]) {
                adStat = { ...mockInventoryAdStats[mockInventoryId] };
            }
            acc[itemId] = {
                ...adStat,
                requests: adStat.requests + getRandomNumber(10000, 5000),
                results: { ...adStat.results, "-20": adStat.results["-20"] + getRandomNumber(1000, 50) },
                statsByCurrency: {
                    ...adStat.statsByCurrency,
                    1: {
                        ...adStat.statsByCurrency[1],
                        filledRequests: adStat.statsByCurrency[1].filledRequests + getRandomNumber(1000, 50),
                        impressions: adStat.statsByCurrency[1].impressions + getRandomNumber(10000, 50),
                        rev: adStat.statsByCurrency[1].rev + getRandomNumber(1000000, 50000),
                        cost: adStat.statsByCurrency[1].cost + getRandomNumber(1000000, 50000),
                        rejections: adStat.statsByCurrency[1].rejections + getRandomNumber(10, 5),
                    },
                },
            };
            return acc;
        }, {} as { [id: number]: AdStat });
    }
};

const adStatHistoryDemoDataRaw = (adStatHistoryDemoDataEvent as RawData).message;
const demoInventoryAdStatsRaw = (adStatDemoDataEvent as RawData).message;

const DEMO_EVENT_INTERVAL = 2000;
const DEMO_INVENTORY_ID = "_INVENTORY_ENTITY_";

const adStatHistoryDemoDataByInventoryId = JSON.parse(adStatHistoryDemoDataRaw) as { [DEMO_INVENTORY_ID]: AdStat[] };
const adStatHistoryDemoData = adStatHistoryDemoDataByInventoryId[DEMO_INVENTORY_ID];

const demoInventoryAdStatsById = JSON.parse(demoInventoryAdStatsRaw) as { [dealId: string]: AdStat };

const isSocketOpen = (socket: MutableRefObject<w3cwebsocket | null>): socket is MutableRefObject<w3cwebsocket> => {
    return Boolean(socket.current && socket.current.readyState === socket.current.OPEN);
};

export const useInventoryHealthStats = (InitialEntityType: EntityTypes): UseInventoryHealthStats => {
    const timezone = useAppSelector(selectUserTimezone);
    const mockInventoryAdStatRef = useRef(demoInventoryAdStatsById);
    const socket = useRef<w3cwebsocket | null>(null);

    const { context, session, isDemoContext } = useSeatAuthContext();
    const [entityType, setEntityType] = useState(InitialEntityType);

    const [entityIds, setEntityIds] = useState<number[]>([]);
    const [adStatsById, setAdStatsById] = useState<AdStatsById>({});

    const [adStatsHistoryById, setAdStatsHistoryById] = useState<AdStatHistoryById>({});
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        if (isDemoContext) {
            return;
        }
        socket.current = new w3cwebsocket(ADSTATS_WEBSOCKET);
        socket.current.onopen = () => {
            setIsConnected(true);
        };

        socket.current.onclose = () => {
            setIsAuthenticated(false);
            setIsConnected(false);
        };

        socket.current.onerror = (error) => {
            setError(error);
        };

        socket.current.onmessage = (message) => {
            const eventData = parseMessageData(message);
            switch (eventData.type) {
                case EventTypes.AuthResult: {
                    const rawAuthResultMessage = eventData as RawEventDataWithMessage;
                    const isAuthResultAuthenticated = JSON.parse(rawAuthResultMessage.message) as boolean;
                    setIsAuthenticated(isAuthResultAuthenticated);
                    return;
                }
                case EventTypes.AdStat: {
                    const rawAddStatMessage = eventData as RawEventDataWithMessage;
                    const adStatEventMessage = JSON.parse(rawAddStatMessage.message) as AdStatEventMessage;
                    setAdStatsById(adStatEventMessage);
                    return;
                }
                case EventTypes.AdStatHistory: {
                    const rawAddStatMessage = eventData as RawEventDataWithMessage;
                    const adStatHistoryEventMessage = JSON.parse(
                        rawAddStatMessage.message
                    ) as AdStatHistoryEventMessage;
                    setAdStatsHistoryById(adStatHistoryEventMessage);
                    return;
                }
            }
        };

        return () => {
            if (isSocketOpen(socket)) {
                sendStopStatsEvent(socket.current);
                socket.current.close();
            }
        };
    }, [context, isDemoContext]);

    // Login after socket connected and user is authenticated
    useEffect(() => {
        if (isSocketOpen(socket) && isConnected && session) {
            sendLoginEvent(socket.current, session.sessionCode, session.user.emailAddress);
        }
    }, [socket, isConnected, session, context]);

    // Start stats after Socket Connected, user is authenticated, and context is selected
    // Stop stats after unmount

    useEffect(() => {
        if (isSocketOpen(socket) && isConnected && isAuthenticated && context) {
            sendStartHistoryStatsEvent(socket.current, {
                entityType,
                entityIds: entityIds,
                oneAndDone: true,
                timezone,
            });
            sendStartStatsEvent(socket.current, {
                entityType,
                entityIds: entityIds,
                oneAndDone: null,
                timezone,
            });
        }

        return () => {
            if (isSocketOpen(socket)) {
                sendStopStatsEvent(socket.current);
            }
        };
    }, [socket, isConnected, isAuthenticated, context, entityType, entityIds, timezone]);

    useEffect(() => {
        if (isSocketOpen(socket) && isConnected && isAuthenticated) {
            const interval = setInterval(() => {
                if (isSocketOpen(socket)) {
                    sendHeartbeatEvent(socket.current);
                }
            }, HEARTBEAT_INTERVAL);

            return () => {
                clearInterval(interval);
            };
        }
    }, [socket, isConnected, isAuthenticated, context]);

    useEffect(() => {
        if (!context || !isDemoContext || !session) {
            return;
        }
        setTimeout(() => setIsConnected(true), 2000);
        const increment = () => {
            const incremented = incrementDemoAdStats(mockInventoryAdStatRef.current, entityIds);
            if (incremented) {
                mockInventoryAdStatRef.current = incremented;
            }
            setAdStatsById(mockInventoryAdStatRef.current);
        };

        increment();
        const interval = setInterval(() => {
            if (session && context && isDemoContext) {
                increment();
            }
        }, DEMO_EVENT_INTERVAL);

        setAdStatsHistoryById(
            entityIds.reduce<{ [inventoryItem: number]: AdStat[] }>((acc, id) => {
                return {
                    ...acc,
                    [id]: adStatHistoryDemoData,
                };
            }, {})
        );

        return () => {
            clearInterval(interval);
        };
    }, [session, context, isDemoContext, entityIds]);

    return {
        adStatsById,
        adStatsHistoryById,
        isConnected,
        isAuthenticated,
        error,
        entityIds,
        setEntityType,
        setEntityIds,
    };
};
