import { useNotificationError } from "@app/core/components/hooks";
import { useGetContentTransparencyRuleQuery } from "@app/core/services";
import { uri } from "@rubicon/utils";
import { useLocation, useHistory } from "react-router-dom";

export const DRAWER = { key: "drawer", default: "" };

export const useContentTransparencyRulesDrawer = () => {
    const { search } = useLocation();

    const id = new URLSearchParams(search).get(DRAWER.key) || DRAWER.default;
    const history = useHistory();

    const handleDrawerClose = () => {
        const nextSearch = uri.deleteSearchParam(search, DRAWER.key);
        history.push({ search: nextSearch });
    };
    const handleDrawerOpen = (id: string) => {
        const nextSearch = uri.setSearchParam(search, DRAWER.key, id);
        history.push({ search: nextSearch });
    };
    const {
        data: entity,
        isLoading,
        isFetching,
        error,
    } = useGetContentTransparencyRuleQuery({ id }, { skip: !id || Number.isNaN(Number(id)) });

    useNotificationError(error);

    return {
        isLoading: isLoading || isFetching,
        entity,
        id,
        handleDrawerClose,
        handleDrawerOpen,
    };
};
