import { FixedSaveButtons } from "@app/core/components";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import { Flex, Form, Input, Layout, Card, Radio } from "antd";
import { FC, ReactNode } from "react";
import { COMPETITIVE_SEPARATION_GROUPS_FORM_NAMES, HELP_KEYS, InitialValuesType, TYPE } from "../constants";
import { IabCategorieTree, Industries } from "./fields";

const MAX_NAME_LENGTH = 70;

interface Props {
    initialValues: Partial<InitialValuesType>;
    handleCancel: () => void;
    handleSubmit: (values: InitialValuesType) => void;
    deleteButton?: ReactNode;
    isSubmitting?: boolean;
}

export const CompetitiveSeparationGroupsForm: FC<Props> = ({
    initialValues,
    handleCancel,
    handleSubmit,
    deleteButton,
    isSubmitting,
}) => {
    const [form] = Form.useForm<InitialValuesType>();
    const mode: boolean = Form.useWatch("mode", form);

    return (
        <Form
            layout="vertical"
            style={{ width: "inherit" }}
            form={form}
            wrapperCol={{ span: 10 }}
            initialValues={initialValues}
            onFinish={handleSubmit}
        >
            <Layout.Content>
                <Card style={{ minHeight: "77vh" }} bordered={false}>
                    <Flex justify="space-between">
                        <Flex flex={1} vertical>
                            <Form.Item
                                label="Name"
                                name={COMPETITIVE_SEPARATION_GROUPS_FORM_NAMES.NAME}
                                rules={[
                                    { required: true, whitespace: true },
                                    {
                                        max: MAX_NAME_LENGTH,
                                        message: `Name should not be greater than ${MAX_NAME_LENGTH}`,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item name={COMPETITIVE_SEPARATION_GROUPS_FORM_NAMES.MODE} label="Mode" required>
                                <Radio.Group>
                                    <Radio value={true}>{TYPE.IAB_CATEGORIES}</Radio>
                                    <Radio value={false}>{TYPE.INDUSTRIES}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {mode ? (
                                <IabCategorieTree
                                    name={COMPETITIVE_SEPARATION_GROUPS_FORM_NAMES.IAB_CATEGORIES}
                                    rules={[{ required: true }]}
                                    label={TYPE.IAB_CATEGORIES}
                                />
                            ) : (
                                <Industries
                                    name={COMPETITIVE_SEPARATION_GROUPS_FORM_NAMES.INDUSTRIES}
                                    rules={[{ required: true }]}
                                    label={TYPE.INDUSTRIES}
                                />
                            )}
                        </Flex>
                        <HelpKeysDrawer panelLabel="Separation Group" definitionsFields={HELP_KEYS} />
                    </Flex>
                </Card>
            </Layout.Content>
            <FixedSaveButtons
                submitLabel="Save"
                isLoading={isSubmitting}
                onCancel={handleCancel}
                suffixAddOn={deleteButton}
            />
        </Form>
    );
};
