import { FC } from "react";
import { Form, Input } from "antd";
import { LABEL_CLASS_LIST } from "@app/features/inventory/constants";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { InheritedDomainsBlocksDrawer } from "./InheritedDomainsBlocksDrawer";

const { TextArea } = Input;

export const BlockedAdvertiserDomains: FC = () => {
    return (
        <Form.Item
            className={LABEL_CLASS_LIST}
            extra="Enter one domain per line"
            label={<InheritedDomainsBlocksDrawer />}
            name={CHANNEL_FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name}
        >
            <TextArea rows={4} allowClear placeholder={CHANNEL_FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.placeholder} />
        </Form.Item>
    );
};
