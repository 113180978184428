import { FC } from "react";
import { AdUnitMimes } from "@app/features/inventory/components/FormItems";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const MIMEs: FC = () => (
    <AdUnitMimes
        name={CHANNEL_FORM_FIELDS.MIMES.name}
        label={CHANNEL_FORM_FIELDS.MIMES.label}
        placeholder={CHANNEL_FORM_FIELDS.MIMES.placeholder}
    />
);
