import { Card } from "antd";
import { FC, useState } from "react";
import { LiveStatsTabContent } from "./LiveStatsTabContent";
import { Last24HoursStatsTabContent } from "./Last24HoursStatsTabContent";
import { PerformanceSectionContext } from "@app/features/inventory/InventoryHealth/InventoryHealthPage/PerformanceSection/usePerformanceSectionContext";

export const PerformanceSection: FC = () => {
    const [isLiveActive, setIsLiveActive] = useState(false);
    const handlePerformanceTypeSwitch = (isLiveActive: boolean): void => setIsLiveActive(isLiveActive);

    return (
        <Card>
            <PerformanceSectionContext.Provider value={{ isLiveActive, handlePerformanceTypeSwitch }}>
                {!isLiveActive && (
                    <Last24HoursStatsTabContent handlePerformanceTypeSwitch={handlePerformanceTypeSwitch} />
                )}
                {isLiveActive && <LiveStatsTabContent handlePerformanceTypeSwitch={handlePerformanceTypeSwitch} />}
            </PerformanceSectionContext.Provider>
        </Card>
    );
};
