import { useMemo } from "react";
import { DealList, UpdateDealParams, useUpdateDealMutation } from "@app/core/services/console";
import { Form, notification } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useUserAccess } from "@app/core/auth";

export const FIELDS = {
    id: "id",
    name: "name",
    status: "status",
    dealOrigin: "dealOrigin",
    updateDate: "updateDate",
    creationDate: "creationDate",
} as const;

interface DealFormLegacy {
    [FIELDS.id]: string;
    [FIELDS.name]: string;
    [FIELDS.status]: { label: string; value: number };
    [FIELDS.dealOrigin]: { label: string; value: number };
    [FIELDS.updateDate]: string;
    [FIELDS.creationDate]: string;
}

const toFormValues = (dealAdSource: DealList, hasInternalAccess: boolean): DealFormLegacy => {
    const { deal } = dealAdSource;
    const { name, status, dealOrigin, code, updateDate, creationDate } = deal;

    const initialValues = {
        [FIELDS.id]: code,
        [FIELDS.name]: name,
        [FIELDS.status]: { label: status.name, value: status.id },
        [FIELDS.updateDate]: updateDate,
        [FIELDS.creationDate]: creationDate,
    };

    if (hasInternalAccess) {
        Object.assign(initialValues, {
            [FIELDS.dealOrigin]: dealOrigin ? { label: dealOrigin.name, value: dealOrigin.id } : undefined,
        });
    }

    return initialValues as DealFormLegacy;
};

const toUpdateDealParams = (dealAdSource: DealList, values: DealFormLegacy): UpdateDealParams => {
    return {
        id: dealAdSource.deal.id,
        body: {
            code: values.id,
            name: values.name,
            status: { id: values.status.value },
            dealOrigin: { id: values.dealOrigin.value },
        },
    };
};

export const useDealFormLegacy = (dealAdSource: DealList) => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const [form] = Form.useForm();
    const [updateDeal, { isLoading }] = useUpdateDealMutation();
    const { hasInternalAccess } = useUserAccess();

    const initialValues = useMemo(
        () => toFormValues(dealAdSource, hasInternalAccess),
        [dealAdSource, hasInternalAccess]
    );

    const onSubmit = async () => {
        try {
            await form.validateFields();
        } catch (e) {
            return;
        }

        try {
            const values = form.getFieldsValue();
            const nextDeal = toUpdateDealParams(dealAdSource, values);
            await updateDeal(nextDeal).unwrap();
        } catch (e) {
            notification.error({
                message: "Deal Update Error",
                description: e.data?.errorDescription || "Error updating deal",
            });
            return;
        }
        onCancel();
    };

    const onCancel = () => {
        form.resetFields();
        history.push(ROUTE_FORMATTERS.SEAT_DEAL_DETAILS(seatId, Number(dealAdSource.deal.id)));
    };

    return {
        form,
        initialValues,
        isUpdating: isLoading,
        onSubmit,
        onCancel,
        hasInternalAccess,
    };
};
