import { FC } from "react";
import { Form, Input } from "antd";
import { LABEL_CLASS_LIST } from "@app/features/inventory/constants";
import { PUBLISHER_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { InheritedDomainsBlocksDrawer } from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormSections/BrandSafetySection/Fields/BlockedAdvertiserDomains/InheritedDomainsBlocksDrawer";

const { TextArea } = Input;

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 18, xl: 22, xxl: 22 };
export const BlockedAdvertiserDomains: FC = () => {
    return (
        <Form.Item
            labelCol={COL_SIZES}
            wrapperCol={COL_SIZES}
            className={LABEL_CLASS_LIST}
            extra="Enter one domain per line"
            label={<InheritedDomainsBlocksDrawer />}
            name={PUBLISHER_FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name}
        >
            <TextArea rows={4} allowClear placeholder={PUBLISHER_FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.placeholder} />
        </Form.Item>
    );
};
