import { AuthClient, Authenticator } from "./AuthClient";

interface UseAuthenticatorInstance {
    authenticatorInstance: Authenticator;
}

// wrapper to make mocking easier in unit tests
export const useAuthenticatorInstance = (): UseAuthenticatorInstance => ({
    authenticatorInstance: AuthClient.getInstance(),
});
