import { useCallback, useEffect, useMemo } from "react";
import moment from "moment-timezone";
import { AdSource, Currency, CurrencyConversion, DealStatus, SeatAdSourcesStatus, TimeZone } from "@app/core/services";
import { useAppDispatch } from "@app/core/store";
import { convertLocalDateToLocalDate, formatDateAsUtcOffsetFreeString, parseDateStringFromApi } from "@app/core/utils";
import { dealFormInputFieldChange, dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import {
    convertCurrency,
    shouldSyncCpmRate,
    shouldSyncCurrency,
    shouldSyncEndDate,
    shouldSyncGroupCpmRate,
    shouldSyncGroupEndDate,
    shouldSyncGroupStartDate,
    shouldSyncStartDate,
    shouldSyncStatus,
    shouldSyncTimeZone,
    useMapStatuses,
    useSyncedFieldsConsumer,
} from "@app/features/syncedFields";
import { CREATE_AD_SOURCE_TYPES_ADD, CREATE_DEAL_FORM_ITEMS_NAME } from "../../constants";
import { DealAdSourceModeConfiguration, DealFormMode, DealType } from "../types";

export const useDealSyncFields = (
    dealFormMode: DealFormMode,
    adSourceModeConfiguration: DealAdSourceModeConfiguration,
    dealCount: number | undefined,
    addToExistingDealCount: number | undefined,
    adSourceCount: number | undefined,
    adSourceTypeId: DealType,
    dealPriceModelId: number | null,
    adSourcePriceFloorId: number | null,
    dealTimeZoneCode: string | undefined,
    adSourceTimeZoneCode: string | undefined,
    dealCpmRate: string | number | null,
    dealCurrencyId: number | null,
    adSourceCurrencyId: number | null,
    dealStartDate: moment.Moment | string | null,
    dealEndDate: moment.Moment | string | null,
    currencyConversions: CurrencyConversion[] = [],
    adSource: AdSource | undefined
) => {
    const dispatch = useAppDispatch();
    const { mapAdSourceStatusToDealStatus, mapDealStatusToAdSourceStatus } = useMapStatuses();

    const adSourceStartDate = parseDateStringFromApi(adSource?.bookingStartDate, adSource?.timeZone?.code);
    const adSourceEndDate = parseDateStringFromApi(adSource?.bookingEndDate, adSource?.timeZone?.code);
    const adSourceCpmRate = adSource?.bookingPrice;
    const readOnlyAdSourceFields = adSource?.readOnlyFields;

    const _dealStartDate = moment(dealStartDate);
    const _dealEndDate = dealEndDate ? moment(dealEndDate) : null;

    const isEdit = dealFormMode === "edit";

    const isOneToOne = useMemo(() => {
        if (dealFormMode === "create") {
            return true;
        }
        if (dealFormMode === "copy") {
            if (adSourceModeConfiguration !== CREATE_AD_SOURCE_TYPES_ADD) {
                return true;
            }
            if (addToExistingDealCount === 0) {
                return true;
            }
            return false;
        }
        return dealCount === 1 && adSourceCount === 1;
    }, [dealFormMode, adSourceModeConfiguration, dealCount, addToExistingDealCount, adSourceCount]);

    const isOneToMany = useMemo(() => {
        if (isOneToOne) {
            return false;
        }
        if (dealFormMode === "create") {
            return false;
        }
        if (dealFormMode === "copy") {
            if (adSourceModeConfiguration !== CREATE_AD_SOURCE_TYPES_ADD) {
                return false;
            }
            if (typeof addToExistingDealCount === "number" && addToExistingDealCount >= 1) {
                return false;
            }
            return false;
        }
        return typeof dealCount === "number" && dealCount > 1 && adSourceCount === 1;
    }, [isOneToOne, dealFormMode, adSourceModeConfiguration, dealCount, addToExistingDealCount, adSourceCount]);

    const onConsumeCurrencyUpdate = useCallback(
        (updatedCurrencyType: Currency) => {
            if (shouldSyncCurrency(isOneToOne, updatedCurrencyType, adSourceTypeId)) {
                // sync deal -> ad source
                dispatch(
                    dealFormInputFieldChange({
                        field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_CURRENCY,
                        value: updatedCurrencyType.id,
                    })
                );
                // sync ad source -> deal
                dispatch(
                    dealFormInputFieldChange({
                        field: CREATE_DEAL_FORM_ITEMS_NAME.CURRENCY,
                        value: updatedCurrencyType.id,
                    })
                );
            }

            if (
                shouldSyncGroupCpmRate(
                    isOneToMany,
                    adSourceTypeId,
                    dealPriceModelId,
                    dealCpmRate,
                    updatedCurrencyType.id,
                    undefined,
                    undefined,
                    adSourceCpmRate,
                    adSourceCurrencyId,
                    currencyConversions
                )
            ) {
                // sync deal -> ad source
                const dealCpmInAdSourceCurrency = convertCurrency(
                    dealCpmRate,
                    updatedCurrencyType.id,
                    adSourceCurrencyId,
                    currencyConversions
                );
                dispatch(
                    dealFormInputFieldChange({
                        field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_PRICE,
                        value: dealCpmInAdSourceCurrency,
                    })
                );
            }
        },
        [
            isOneToOne,
            isOneToMany,
            adSourceTypeId,
            dealPriceModelId,
            dealCpmRate,
            adSourceCpmRate,
            adSourceCurrencyId,
            currencyConversions,
            dispatch,
        ]
    );

    const onConsumeStartDateUpdate = useCallback(
        (updatedStartDate: moment.Moment) => {
            if (shouldSyncStartDate(isOneToOne, isEdit, updatedStartDate, adSourceTypeId, readOnlyAdSourceFields)) {
                // sync deal -> ad source
                dispatch(
                    dealFormInputFieldChange({
                        field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_START_DATE,
                        value: formatDateAsUtcOffsetFreeString(updatedStartDate),
                    })
                );
            }

            if (
                shouldSyncGroupStartDate(
                    isOneToMany,
                    adSourceTypeId,
                    updatedStartDate,
                    dealTimeZoneCode,
                    undefined,
                    undefined,
                    adSourceStartDate,
                    adSourceTimeZoneCode
                )
            ) {
                // sync deal -> ad source
                const dealStartDateInAdSourceTimeZone = convertLocalDateToLocalDate(
                    updatedStartDate,
                    dealTimeZoneCode,
                    adSourceTimeZoneCode
                );
                dispatch(
                    dealFormInputFieldChange({
                        field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_START_DATE,
                        value: formatDateAsUtcOffsetFreeString(dealStartDateInAdSourceTimeZone),
                    })
                );
            }
        },
        [
            isOneToOne,
            isOneToMany,
            adSourceTypeId,
            dealTimeZoneCode,
            adSourceStartDate,
            adSourceTimeZoneCode,
            readOnlyAdSourceFields,
            isEdit,
            dispatch,
        ]
    );

    const onConsumeEndDateUpdate = useCallback(
        (updatedEndDate: moment.Moment | null) => {
            if (shouldSyncEndDate(isOneToOne, updatedEndDate, adSourceTypeId)) {
                // sync deal -> ad source
                dispatch(
                    dealFormInputFieldChange({
                        field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_END_DATE,
                        value: formatDateAsUtcOffsetFreeString(updatedEndDate),
                    })
                );
            }

            if (
                shouldSyncGroupEndDate(
                    isOneToMany,
                    adSourceTypeId,
                    updatedEndDate,
                    dealTimeZoneCode,
                    undefined,
                    undefined,
                    adSourceEndDate,
                    adSourceTimeZoneCode
                )
            ) {
                const dealEndDateInAdSourceTimeZone = updatedEndDate
                    ? convertLocalDateToLocalDate(updatedEndDate, dealTimeZoneCode, adSourceTimeZoneCode)
                    : null;
                dispatch(
                    dealFormInputFieldChange({
                        field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_END_DATE,
                        value: formatDateAsUtcOffsetFreeString(dealEndDateInAdSourceTimeZone),
                    })
                );
            }
        },
        [isOneToOne, isOneToMany, adSourceTypeId, dealTimeZoneCode, adSourceEndDate, adSourceTimeZoneCode, dispatch]
    );

    const onConsumeTimeZoneUpdate = useCallback(
        (updatedTimeZone: TimeZone) => {
            if (shouldSyncTimeZone(isOneToOne, isEdit, updatedTimeZone, adSourceTypeId, readOnlyAdSourceFields)) {
                // sync deal -> ad source
                dispatch(
                    dealFormSelectFieldChange({
                        field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_TIME_ZONE,
                        value: updatedTimeZone?.id || null,
                    })
                );
            }

            if (
                shouldSyncGroupStartDate(
                    isOneToMany,
                    adSourceTypeId,
                    _dealStartDate,
                    updatedTimeZone.code,
                    undefined,
                    undefined,
                    adSourceStartDate,
                    adSourceTimeZoneCode
                )
            ) {
                // sync deal -> ad source
                const dealStartDateInAdSourceTimeZone = convertLocalDateToLocalDate(
                    _dealStartDate,
                    updatedTimeZone.code,
                    adSourceTimeZoneCode
                );
                dispatch(
                    dealFormInputFieldChange({
                        field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_START_DATE,
                        value: formatDateAsUtcOffsetFreeString(dealStartDateInAdSourceTimeZone),
                    })
                );
            }

            if (
                shouldSyncGroupEndDate(
                    isOneToMany,
                    adSourceTypeId,
                    _dealEndDate,
                    updatedTimeZone.code,
                    undefined,
                    undefined,
                    adSourceEndDate,
                    adSourceTimeZoneCode
                )
            ) {
                // sync deal -> ad source
                const dealEndDateInAdSourceTimeZone = convertLocalDateToLocalDate(
                    _dealEndDate,
                    updatedTimeZone.code,
                    adSourceTimeZoneCode
                );
                dispatch(
                    dealFormInputFieldChange({
                        field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_END_DATE,
                        value: formatDateAsUtcOffsetFreeString(dealEndDateInAdSourceTimeZone),
                    })
                );
            }
        },
        [
            isOneToOne,
            isOneToMany,
            adSourceTypeId,
            _dealStartDate,
            _dealEndDate,
            adSourceStartDate,
            adSourceEndDate,
            adSourceTimeZoneCode,
            readOnlyAdSourceFields,
            isEdit,
            dispatch,
        ]
    );

    const onConsumeCpmRateUpdate = useCallback(
        (updatedCpmRate: string | null) => {
            if (shouldSyncCpmRate(isOneToOne, updatedCpmRate, adSourceTypeId, dealPriceModelId, adSourcePriceFloorId)) {
                // sync deal -> ad source
                dispatch(
                    dealFormInputFieldChange({
                        field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_PRICE,
                        value: updatedCpmRate,
                    })
                );
                // sync ad source -> deal
                dispatch(
                    dealFormInputFieldChange({
                        field: CREATE_DEAL_FORM_ITEMS_NAME.RATE,
                        value: updatedCpmRate,
                    })
                );
            }

            if (
                shouldSyncGroupCpmRate(
                    isOneToMany,
                    adSourceTypeId,
                    dealPriceModelId,
                    updatedCpmRate,
                    dealCurrencyId,
                    undefined,
                    undefined,
                    adSourceCpmRate,
                    adSourceCurrencyId,
                    currencyConversions
                )
            ) {
                // sync deal -> ad source
                const dealCpmInAdSourceCurrency = convertCurrency(
                    dealCpmRate,
                    dealCurrencyId,
                    adSourceCurrencyId,
                    currencyConversions
                );
                dispatch(
                    dealFormInputFieldChange({
                        field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_PRICE,
                        value: dealCpmInAdSourceCurrency,
                    })
                );
            }
        },
        [
            isOneToOne,
            isOneToMany,
            adSourceTypeId,
            dealPriceModelId,
            adSourcePriceFloorId,
            dealCurrencyId,
            adSourceCpmRate,
            adSourceCurrencyId,
            currencyConversions,
            dealCpmRate,
            dispatch,
        ]
    );

    const onConsumeStatusUpdate = useCallback(
        (updatedStatus: DealStatus | SeatAdSourcesStatus | null) => {
            if (!shouldSyncStatus(isOneToOne, updatedStatus)) {
                return;
            }

            if (updatedStatus?.entityType === "DealStatus") {
                const updatedAdSourceStatus = mapDealStatusToAdSourceStatus(updatedStatus.id);
                // sync deal -> ad source
                dispatch(
                    dealFormSelectFieldChange({
                        field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_STATUS,
                        value: updatedAdSourceStatus?.id || null,
                    })
                );
            } else if (updatedStatus?.entityType === "AdSourceStatus") {
                const updatedDealStatus = mapAdSourceStatusToDealStatus(updatedStatus.id);
                // sync ad source -> deal
                dispatch(
                    dealFormSelectFieldChange({
                        field: CREATE_DEAL_FORM_ITEMS_NAME.STATUS,
                        value: updatedDealStatus?.id || null,
                    })
                );
            }
        },
        [isOneToOne, mapAdSourceStatusToDealStatus, mapDealStatusToAdSourceStatus, dispatch]
    );

    // Note - impressions are synced by the deal form as they are inheritied from the deal

    const updateConsumerCallbacks = useMemo(() => {
        if (!isOneToOne && !isOneToMany) {
            return;
        }

        return {
            onConsumeCurrencyUpdate,
            onConsumeStartDateUpdate,
            onConsumeEndDateUpdate,
            onConsumeTimeZoneUpdate,
            onConsumeCpmRateUpdate,
            onConsumeStatusUpdate,
        };
    }, [
        isOneToOne,
        isOneToMany,
        onConsumeCurrencyUpdate,
        onConsumeStartDateUpdate,
        onConsumeEndDateUpdate,
        onConsumeTimeZoneUpdate,
        onConsumeCpmRateUpdate,
        onConsumeStatusUpdate,
    ]);

    const { consumeAllSyncedFields } = useSyncedFieldsConsumer(updateConsumerCallbacks);

    useEffect(() => {
        // Ensure we start with fresh synced field state when the deal form is first opened.
        consumeAllSyncedFields();
        return () => {
            // Clean up any remaining synced field state when we leave the deal form.
            consumeAllSyncedFields();
        };
        // Empty dependency array is necessary to ensure that we only reset state once per initialiation / cleanup.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
