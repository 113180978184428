import {
    AdServerLadle,
    Deal,
    DealListItem,
    useGetNetworkEndpointsAwsRegionsQuery,
    useGetSeatAvailableNetworksQuery,
} from "@app/core/services";
import { Form, FormInstance, notification } from "antd";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { LabeledValue } from "antd/lib/select";
import { useCreateAdServerLadlesForDealMutation } from "@app/core/services/console/adServerLadles";
import { useDealDetailsDrawerContent } from "@app/features/deals/DealDetailsDrawer/useDealDetailsDrawerContent";

enum LADLE_FORM_FIELDS {
    Region = "awsRegionCode",
    LadleName = "ladleName",
    SupplyDomain = "sourceDomain",
    AdvertiserDomain = "adomain",
    CreativeId = "creativeId",
    Creative = "creative",
    Demand = "networkCode",
}
const initialFormData = {
    [LADLE_FORM_FIELDS.Region]: null,
    [LADLE_FORM_FIELDS.LadleName]: null,
    [LADLE_FORM_FIELDS.SupplyDomain]: null,
    [LADLE_FORM_FIELDS.AdvertiserDomain]: null,
    [LADLE_FORM_FIELDS.CreativeId]: null,
    [LADLE_FORM_FIELDS.Creative]: null,
    [LADLE_FORM_FIELDS.Demand]: null,
};

export type LadleFormDataType = typeof initialFormData;

export type LadleFormFieldsValueType = {
    [key in LADLE_FORM_FIELDS]: string | number | boolean;
};

interface UseDealDetailsDrawerLadle {
    initialValues: LadleFormDataType;
    awsRegionsOptions: LabeledValue[];
    form: FormInstance;
    handleFormChange: (values: LadleFormFieldsValueType) => void;
    handleSubmit: () => void;
    isFetchingAwsRegions: boolean;
    isFetchingNetworks: boolean;
    LADLE_FORM_FIELDS: typeof LADLE_FORM_FIELDS;
    networksOptions: LabeledValue[];
    isSubmitting: boolean;
    onClose: () => void;
}

export const useDealDetailsDrawerLadle = (deal: Deal | DealListItem): UseDealDetailsDrawerLadle => {
    const [form] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [formData, setFormData] = useState<LadleFormDataType>(initialFormData);
    const { seatId } = useParams<{ seatId: string }>();
    const { switchToDefaultDetailsView } = useDealDetailsDrawerContent();
    const [createAdServerLadle] = useCreateAdServerLadlesForDealMutation();
    const { data: networks, isFetching: isFetchingNetworks } = useGetSeatAvailableNetworksQuery(Number(seatId));
    const { data: awsRegions, isFetching: isFetchingAwsRegions } = useGetNetworkEndpointsAwsRegionsQuery();

    const handleFormChange = (values: LadleFormFieldsValueType): void => {
        for (const [key, value] of Object.entries(values)) {
            setFormData((prevFormData) => ({ ...prevFormData, [key]: value }));
        }
    };

    const onClose = () => {
        switchToDefaultDetailsView();
    };

    const networksOptions = useMemo(
        () =>
            (networks || []).map((network) => ({
                value: network.code,
                label: network.name,
            })),
        [networks]
    );
    const awsRegionsOptions = useMemo(
        () =>
            (awsRegions || []).map((region) => ({
                value: region.code,
                label: region.name,
            })),
        [awsRegions]
    );

    const handleSubmit = async () => {
        setIsSubmitting(true);
        setFormData(initialFormData);

        const payload: AdServerLadle = {
            dealCode: deal.id,
            hasCreative: formData[LADLE_FORM_FIELDS.Creative],
        };

        if (formData[LADLE_FORM_FIELDS.AdvertiserDomain]) {
            payload.adomain = formData[LADLE_FORM_FIELDS.AdvertiserDomain];
        }
        if (formData[LADLE_FORM_FIELDS.Region]) {
            payload.awsRegionCode = formData[LADLE_FORM_FIELDS.Region];
        }
        if (formData[LADLE_FORM_FIELDS.CreativeId]) {
            payload.creativeId = formData[LADLE_FORM_FIELDS.CreativeId];
        }
        if (formData[LADLE_FORM_FIELDS.LadleName]) {
            payload.ladleName = formData[LADLE_FORM_FIELDS.LadleName];
        }
        if (formData[LADLE_FORM_FIELDS.Demand]) {
            payload.networkCode = formData[LADLE_FORM_FIELDS.Demand];
        }
        if (formData[LADLE_FORM_FIELDS.SupplyDomain]) {
            payload.sourceDomain = formData[LADLE_FORM_FIELDS.SupplyDomain];
        }

        try {
            const adLadleRes = await createAdServerLadle({ id: Number(deal.id), body: payload }).unwrap();

            notification.success({
                message: "Ladle Processing...",
                description: (
                    <>
                        <div>
                            {adLadleRes.ladleName} is currently processing. Once finished you can view the results under
                            <a href={`#/seats/${deal["seat"].id}/seat-diagnostics`}> Ladle Results</a>
                        </div>
                    </>
                ),
                duration: 10,
            });

            form.resetFields();
        } catch (err) {
            notification.error({
                message: "Ladle Error",
                description: `An error has occurred while creating a ladle for ${deal.name}`,
                duration: 10,
            });
        } finally {
            setIsSubmitting(false);
            onClose();
        }
    };

    return {
        initialValues: initialFormData,
        form,
        handleFormChange,
        LADLE_FORM_FIELDS,
        handleSubmit,
        networksOptions,
        awsRegionsOptions,
        isFetchingNetworks,
        isFetchingAwsRegions,
        isSubmitting,
        onClose,
    };
};
