import { EncryptionKey } from "@app/core/services";
import { ColumnsType } from "antd/lib/table";
import { Tag } from "antd";
import { ClipBoardButton } from "@app/core/components";
import { formatEncryptionKey } from "../helpers";
import { CodeOutlined } from "@ant-design/icons";
import { formatDateInUtc } from "@app/core/utils";
import { YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE } from "@app/core/components/constants";
import { CopyString } from "../CopyString";

export const ENCRYPTION_KEYS_TABLE_SDET = "encryption-keys-table";
export const COPY_ENCRYPTION_KEY_BUTTON_SDET = "copy-encryption-key-button";
export const COPY_INTEGRITY_KEY_BUTTON_SDET = "copy-integrity-key-button";

export const ENCRYPTION_KEYS_TABLE_COLUMNS: ColumnsType<EncryptionKey> = [
    {
        key: "status",
        dataIndex: "deleted",
        title: "Status",
        render: (deleted) => <Tag color={deleted ? "" : "green"}>{deleted ? "Expired" : "Active"}</Tag>,
    },
    {
        key: "id",
        dataIndex: "id",
        title: "ID",
    },
    {
        key: "encryptionKey",
        dataIndex: "encryptionKey",
        title: "Encryption Key",
        render: (encryptionKey) => (
            <CopyString
                entity="Encryption Key"
                tooltipProps={{ title: "Copy Encryption Key" }}
                copyText={encryptionKey}
                sdet={COPY_ENCRYPTION_KEY_BUTTON_SDET}
            />
        ),
    },
    {
        key: "integrityKey",
        dataIndex: "integrityKey",
        title: "Integrity Key",
        render: (integrityKey) => (
            <CopyString
                entity="Integrity Key"
                copyText={integrityKey}
                tooltipProps={{ title: "Copy Integrity Key" }}
                sdet={COPY_INTEGRITY_KEY_BUTTON_SDET}
            />
        ),
    },
    {
        key: "creationDate",
        dataIndex: "creationDate",
        title: "Creation Date",
        render: (creationDate) => formatDateInUtc(creationDate, YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE),
    },
    {
        key: "json",
        dataIndex: "json",
        title: "JSON",
        render: (_, encryptionKey) => {
            return (
                <ClipBoardButton
                    noStyle
                    entity="JSON"
                    copyText={formatEncryptionKey(encryptionKey)}
                    tooltipProps={{ title: "Copy JSON" }}
                    icon={<CodeOutlined />}
                />
            );
        },
    },
];
