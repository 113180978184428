import { FC } from "react";
import { Card, Row, Col } from "antd";
import { Seat } from "@app/core/services";
import { CustomPixels } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/SeatDetailsFormFields";

interface CustomPixelsSectionProps {
    edit?: boolean;
    seat: Seat;
}

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 };

export const CustomPixelsSection: FC<CustomPixelsSectionProps> = ({ seat, edit = false }) => (
    <Card bordered={false}>
        <Row gutter={16}>
            <Col {...COL_SIZES}>
                <CustomPixels seat={seat} edit={edit} />
            </Col>
        </Row>
    </Card>
);
