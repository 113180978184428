import { Table } from "antd";
import { getColumns, getTableRows } from "./helpers";
import { useSequesteredDomains } from "./useSequesteredDomains";
import { SequesteredDomainsDrawer } from "../SequesteredDomains/SequesteredDomainsDrawer/SequesteredDomainsDrawer";
import { ROUTES } from "@app/core/routing";
import { Route } from "react-router-dom";

export const SEQUESTERED_DOMAINS_TABLE_SDET = "sequestered-domains-list-table";

export const SequesteredDomainsTable = () => {
    const { isLoading, data, search, seatId, paginationConfig, handleTableChange } = useSequesteredDomains();

    return (
        <>
            <Table
                data-sdet={SEQUESTERED_DOMAINS_TABLE_SDET}
                columns={getColumns()}
                dataSource={getTableRows(data, seatId, search)}
                onChange={handleTableChange}
                loading={isLoading}
                scroll={{ x: 1000 }}
                size="small"
                rowKey="id"
                showSorterTooltip={false}
                pagination={paginationConfig}
            />
            <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_SEQUESTERED_DOMAIN_EDIT}>
                <SequesteredDomainsDrawer />
            </Route>
        </>
    );
};

export default SequesteredDomainsTable;
