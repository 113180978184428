import { FC } from "react";
import { Col, Row, Tag } from "antd";
import { AdSource } from "@app/core/services/console/adSources";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { SeatAdSourcesDealCountTable } from "@app/features/seatAdSources/SeatAdSourcesDealCountTable";
import {
    isDemandTableVisible,
    isExcludeDemandVisible,
} from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections";
import { DetailsItem } from "../DetailsItem";

interface Props {
    adSource: AdSource;
}

export const SeatAdSourcesDemandDetails: FC<Props> = ({ adSource }) => {
    const adSourceTypeName = adSource.type.name;
    return (
        <>
            {isExcludeDemandVisible(adSourceTypeName) && (
                <Row>
                    <Col sm={8}>
                        <DetailsItem label={AD_SOURCE_FIELDS.EXCLUDE_DEMAND.label}>
                            {adSource[AD_SOURCE_FIELDS.EXCLUDE_DEMAND.name].map(({ name, id }) => (
                                <Tag key={id}>{name}</Tag>
                            ))}
                        </DetailsItem>
                    </Col>
                </Row>
            )}
            {isDemandTableVisible(adSourceTypeName) && <SeatAdSourcesDealCountTable adSource={adSource} />}
        </>
    );
};
