import { Typography } from "antd";
import { FC } from "react";

export const CLASSIFICATIONS_ADVERTISER_TRANSFER_DRAWER_TITLE_SDET = "classifications-advertiser-transfer-drawer-title";

interface Props {
    advertiserName?: string;
}
export const ClassificationsAdvertiserTransferDrawerTitle: FC<Props> = ({ advertiserName }) =>
    advertiserName ? (
        <Typography.Text data-sdet={CLASSIFICATIONS_ADVERTISER_TRANSFER_DRAWER_TITLE_SDET}>
            Transfer from {advertiserName}
        </Typography.Text>
    ) : null;
