import { FC } from "react";
import { Button, Checkbox, Col, Form, Input, Row, Typography } from "antd";
import { RightSquareFilled } from "@ant-design/icons";
import { isValidEmail } from "@rubicon/utils";
import { useEmailForm } from "@app/features/login/Steps/hooks/useEmailForm";
import { css } from "@emotion/css";
import { UseLoginFormData } from "../LoginForm/useLoginFormData";

const { Link } = Typography;

const EmailForm: FC<UseLoginFormData> = (loginFormData) => {
    const { form, handleSubmit, handleFormChange, handleChangeCheckbox, EMAIL_FORM_FIELDS, isRememberMe } =
        useEmailForm(loginFormData);

    return (
        <Form
            form={form}
            initialValues={loginFormData}
            layout="vertical"
            onFinish={handleSubmit}
            onValuesChange={handleFormChange}
            validateTrigger="onBlur"
        >
            <Row>
                <Col xs={24}>
                    <Form.Item
                        label="Email"
                        name={EMAIL_FORM_FIELDS.Email}
                        rules={[
                            {
                                validator(_, value) {
                                    if (!value) {
                                        return Promise.reject(new Error("Please enter your email address"));
                                    }
                                    if (isValidEmail.isValid(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error("Invalid email address"));
                                },
                            },
                        ]}
                        className={css`
                            margin-bottom: 0.5rem;
                            .ant-form-item-label > label {
                                font-size: 1rem;
                            }
                            .ant-form-item-control-input {
                                min-height: 42px;
                            }
                            .ant-input {
                                min-height: 42px;
                            }
                        `}
                    >
                        <Input type={EMAIL_FORM_FIELDS.Email} autoFocus data-sdet="email" />
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <Form.Item style={{ marginBottom: "1rem" }}>
                        <Checkbox checked={isRememberMe} onChange={handleChangeCheckbox} data-sdet="remember-my-email">
                            Remember my email
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[0, 10]} justify="end">
                <Col xs={24}>
                    <Button
                        htmlType="submit"
                        data-sdet="submit"
                        block
                        type="primary"
                        className={css`
                            height: 42px;
                        `}
                    >
                        Submit
                    </Button>
                </Col>
            </Row>
            <Row justify="space-between" align="bottom" style={{ marginTop: "1.5rem" }}>
                <Col>
                    <Link href="https://www.magnite.com/contact-us" target="_blank" data-sdet="dont-have-an-account">
                        Don&#39;t have an account?
                    </Link>
                </Col>
                <Col style={{ textAlign: "right" }}>
                    <Link
                        href="https://login.rubiconproject.com"
                        target="_blank"
                        className={css`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        Go to Magnite DV+
                        <RightSquareFilled
                            className={css`
                                margin-left: 0.25rem;
                                font-size: 1rem;
                            `}
                        />
                    </Link>
                </Col>
            </Row>
        </Form>
    );
};

export default EmailForm;
