import { useGetEnrichmentCostModelsQuery } from "@app/core/services";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormInputFieldChange, dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";

export const useDemandAcquisitionCostFormItem = () => {
    const dispatch = useAppDispatch();
    const { data: enrichmentCostModels } = useGetEnrichmentCostModelsQuery();

    const dac = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DEMAND_ACQUISITION_COST]
    );

    const dacModelId = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DEMAND_ACQUISITION_COST_MODEL]
    );

    const dacModelOptions = (enrichmentCostModels || []).map(({ name, id }) => ({
        label: name,
        value: id,
    }));

    const dacOnBlur = (value: string) => {
        dispatch(
            dealFormInputFieldChange({
                field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DEMAND_ACQUISITION_COST,
                value,
            })
        );
    };

    const dacModelOnSelect = (value: number) => {
        dispatch(
            dealFormSelectFieldChange({
                field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DEMAND_ACQUISITION_COST_MODEL,
                value,
            })
        );
    };

    return {
        dac,
        dacModelId,
        dacModelOptions,
        dacOnBlur,
        dacModelOnSelect,
    };
};
