import { Layout } from "antd";
import { PageLoader } from "@rubicon/antd-components";
import { Footer } from "@app/core/components";
import { DealCopyFormPageContent } from "./DealCopyFormPageContent";
import { DealCopyFormPageHeader } from "./DealCopyFormPageHeader";
import { useDealAdSourceById } from "@app/features/deals/DealDetailsPage/useDealAdSourceById";

export const DealCopyFormPage = () => {
    const { dealAdSource, isLoading } = useDealAdSourceById();

    if (isLoading || !dealAdSource) {
        return <PageLoader />;
    }
    return (
        <Layout>
            <DealCopyFormPageHeader title={`Copy ${dealAdSource?.deal?.name}`} />
            <Layout.Content>
                <DealCopyFormPageContent dealAdSource={dealAdSource} />
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </Layout>
    );
};
