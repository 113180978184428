import { FC } from "react";
import { AdSource } from "@app/core/services";
import { Col, Input, Row, Tag, Typography } from "antd";
import { format } from "@rubicon/utils";
import { formatCategoryLabel } from "@app/features/targeting";

const { NONE } = format.constants;

interface Props {
    adSource: AdSource;
}

const { Paragraph, Text } = Typography;
const { TextArea } = Input;

const handleBlockedDomainsString = (blockedDomainsArray) => {
    return blockedDomainsArray.toString().replace(/,/g, "\n");
};

export const SeatAdSourcesBrandSafetyDetails: FC<Props> = ({ adSource }) => {
    return (
        <>
            <Row>
                <Col sm={8}>
                    <Text strong>IAB Category Mode</Text>
                    <Paragraph>{format.asSelf(adSource?.iabCategoryBlockMode.name)}</Paragraph>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <Text strong>IAB Categories</Text>
                    <Paragraph>
                        {adSource?.blockedIabCategories?.length > 0
                            ? adSource?.blockedIabCategories.map((iabCat) => (
                                  <Tag key={iabCat.id}>{formatCategoryLabel(iabCat)}</Tag>
                              ))
                            : NONE}
                    </Paragraph>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <Text strong>IAB Category Block Exceptions</Text>
                    <Paragraph>
                        {adSource?.blockExceptionIabCategories?.length > 0
                            ? adSource?.blockExceptionIabCategories.map((iabCat) => (
                                  <Tag key={iabCat.id}>{formatCategoryLabel(iabCat)}</Tag>
                              ))
                            : NONE}
                    </Paragraph>
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    <Text strong>Advertiser Domain Mode</Text>
                    <Paragraph>{format.asSelf(adSource?.advertiserBlockingMode.name)}</Paragraph>
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    <Text strong>Reusable Advertiser Domains</Text>
                    <Paragraph>
                        {typeof adSource?.seatAdvertiserDomainFilterListDefs?.length === "number"
                            ? adSource?.seatAdvertiserDomainFilterListDefs.length > 0
                                ? adSource?.seatAdvertiserDomainFilterListDefs.map((domainList) => (
                                      <Tag key={domainList.id}>{domainList.name}</Tag>
                                  ))
                                : NONE
                            : NONE}
                    </Paragraph>
                </Col>
            </Row>
            <Row>
                <Col sm={14}>
                    <Text strong>Additional Advertiser Domains</Text>
                    {typeof adSource?.advertiserBlockDomains?.length === "number" ? (
                        adSource?.advertiserBlockDomains.length > 0 ? (
                            <TextArea
                                defaultValue={handleBlockedDomainsString(adSource?.advertiserBlockDomains)}
                                readOnly
                                size="middle"
                                style={{ height: "86px" }}
                            ></TextArea>
                        ) : (
                            <Paragraph>{NONE}</Paragraph>
                        )
                    ) : (
                        <Paragraph>{NONE}</Paragraph>
                    )}
                </Col>
            </Row>
        </>
    );
};
