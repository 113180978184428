import { Form } from "antd";
import { useContext } from "react";
import { AdSourceTypes, AD_SOURCE_FIELDS, AD_SOURCE_TYPES_NEXT_UI_NAMES } from "../constants";
import { AdSourceFormsContext } from "./SeatAdSourcesForm";

export const useIsPbsDealType = () => {
    const {
        forms: { adSourceForm },
    } = useContext(AdSourceFormsContext);
    const adSourceType = Form.useWatch(AD_SOURCE_FIELDS.TYPE.name, adSourceForm);
    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSourceType?.name] ?? adSourceType?.name;

    return adSourceTypeName === AdSourceTypes.PREBID_DEALS_CONTAINER;
};
