import { useMemo } from "react";
import { CheckboxOptionType } from "antd/lib/checkbox/Group";
import { useGetAdUnitsLinearitiesQuery } from "@app/core/services";
import { LINEARITY_BOTH_ALLOWED } from "@app/features/inventory/HierarchyForms/constants";

interface UseAdUnitLinearity {
    options: CheckboxOptionType[];
}

export const useAdUnitLinearity = (): UseAdUnitLinearity => {
    const { data } = useGetAdUnitsLinearitiesQuery();

    const options: CheckboxOptionType[] = useMemo(
        () => (data || []).map(({ id, name }) => ({ value: id, label: name })),
        [data]
    );

    return {
        options: [LINEARITY_BOTH_ALLOWED, ...options],
    };
};
