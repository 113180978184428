import { DealDetailsDrawerTitles } from "../constants";

export const convertSecondaryViewFromURL = (secondaryView: string): DealDetailsDrawerTitles | undefined => {
    switch (secondaryView) {
        case "change-history":
            return DealDetailsDrawerTitles.ChangeHistory;
        case "ladle":
            return DealDetailsDrawerTitles.Ladle;
        case "performance":
            return DealDetailsDrawerTitles.Performance;
        default:
            return undefined;
    }
};

export const convertSecondaryViewToURL = (secondaryView: DealDetailsDrawerTitles): string => {
    switch (secondaryView) {
        case DealDetailsDrawerTitles.ChangeHistory:
            return "change-history";
        case DealDetailsDrawerTitles.Ladle:
            return "ladle";
        case DealDetailsDrawerTitles.Performance:
            return "performance";
        default:
            return "";
    }
};
