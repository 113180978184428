import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { useWatch } from "antd/lib/form/Form";

export const useExtendedUserIdList = () => {
    const includedExtendedIds = useWatch<AdSourcesForm["allowAllExtendedId"]>(
        AD_SOURCE_FIELDS.INCLUDED_EXTENDED_IDS.name
    );
    const isVisible = includedExtendedIds === "specific";
    return { isVisible };
};
