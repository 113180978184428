import { Button, Col, Row, Space, Table, Tooltip, Typography } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { SortOrder } from "antd/lib/table/interface";
import { usePagination, useTableChanges } from "@app/core/components/hooks";
import { useUserAccess } from "@app/core/auth";
import { ColumnsType } from "antd/es/table";
import { DebounceUriSearch, SEARCH } from "@app/core/components";
import { YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE } from "@app/core/components/constants";
import { formatDateInUtc } from "@app/core/utils";
import { EditOutlined } from "@ant-design/icons";
import { uri } from "@rubicon/utils";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Label, useGetSeatLabelsQuery } from "@app/core/services";
import { brandCobalt } from "@rubicon/antd-components";

export const CLASSIFICATIONS_LABELS_TABLE_SDET = "classifications-labels-table";
export const CLASSIFICATIONS_LABELS_TABLE_SEARCH_SDET = "classifications-labels-table-search";
export const CLASSIFICATIONS_ADD_LABEL_BUTTON_SDET = "classifications-label-add-btn";
export const CLASSIFICATIONS_LABELS_TABLE_EDIT_BUTTON_SDET = "classifications-labels-table-edit-btn";
export const CLASSIFICATIONS_LABELS_TABLE_CHANGE_HISTORY_BUTTON_SDET =
    "classifications-labels-table-change-history-btn";

export const getLabelsColumns = (
    sortBy: string,
    sortOrder: SortOrder,
    canEditSeat: boolean,
    onClickKey: (labelId: string | number) => void,
    onClickEdit: (labelId: string | number) => void
) =>
    [
        {
            key: "key",
            dataIndex: "key",
            title: "Key",
            sorter: (a, b) => a.key.localeCompare(b.key),
            sortOrder: sortBy === "key" ? sortOrder : null,
            render: (name, { id }) => (
                <Typography.Link onClick={() => onClickKey(id)} style={{ color: brandCobalt }}>
                    {name}
                </Typography.Link>
            ),
        },
        {
            key: "enablePrefilter",
            dataIndex: "enablePrefilter",
            title: "Waterfall Pre-filter",
            render: (enablePrefilter) => (enablePrefilter ? "Yes" : "No"),
        },
        {
            key: "multiValue",
            dataIndex: "multiValue",
            title: "Multi-Instance",
            render: (multiValue) => (multiValue ? "Yes" : "No"),
        },
        {
            key: "creationDate",
            dataIndex: "creationDate",
            title: "Date Created",
            render: (creationDate) => formatDateInUtc(creationDate, YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE),
            sorter: (a, b) => new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime(),
            sortOrder: sortBy === "creationDate" ? sortOrder : null,
        },
        {
            key: "updateDate",
            dataIndex: "updateDate",
            title: "Last Updated",
            render: (updateDate) => formatDateInUtc(updateDate, YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE),
            sorter: (a, b) => new Date(a.updateDate).getTime() - new Date(b.updateDate).getTime(),
            sortOrder: sortBy === "updateDate" ? sortOrder : null,
        },
        canEditSeat && {
            key: "actions",
            dataIndex: "actions",
            title: "Actions",
            render: (_, { id }) => (
                <>
                    {canEditSeat && (
                        <Tooltip title="Edit">
                            <Button
                                data-sdet={CLASSIFICATIONS_LABELS_TABLE_EDIT_BUTTON_SDET}
                                onClick={() => onClickEdit(id)}
                                icon={<EditOutlined />}
                            />
                        </Tooltip>
                    )}
                </>
            ),
        },
    ].filter(Boolean) as ColumnsType<Label>;

export const getClassificationsLabelsParams = (queryString: string) => {
    const keyword = new URLSearchParams(queryString).get("search") || "";

    return {
        keyword,
        page: 1, // no pagination for this feature, however api allows for pagination if needed
        max: 100,
    };
};

export const ClassificationsLabelsTable = () => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const { search: queryString } = useLocation();

    const keyword = new URLSearchParams(queryString).get(SEARCH.key) || SEARCH.default;
    const { data = [], isFetching } = useGetSeatLabelsQuery({ seatId: Number(seatId), params: { keyword } });

    const { handleChange, paginationConfig } = usePagination({ totalCount: data?.length || 0 });
    const { onChange, sortBy, sortOrder } = useTableChanges({
        handlePaginateChange: handleChange,
        sortParam: { key: "sort", default: "-updateDate" },
        filtersParams: [],
    });
    const { canEditSeat } = useUserAccess();

    const onClickKey = (labelId: string | number) => {
        const setDrawerParam = uri.setSearchParam(queryString, "drawer", "details");
        const nextQueryString = uri.setSearchParam(setDrawerParam, "labelId", String(labelId));
        history.push({
            search: nextQueryString,
        });
    };

    const onClickEdit = (labelId: string | number) => {
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_LABEL_EDIT_PAGE(seatId, labelId));
    };
    return (
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Row justify="space-between">
                <Col>
                    <DebounceUriSearch sdet={CLASSIFICATIONS_LABELS_TABLE_SEARCH_SDET} />
                </Col>
                {canEditSeat && (
                    <Col>
                        <Button
                            data-sdet={CLASSIFICATIONS_ADD_LABEL_BUTTON_SDET}
                            type="primary"
                            onClick={() =>
                                history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_LABEL_CREATE_PAGE(seatId))
                            }
                        >
                            Add Label
                        </Button>
                    </Col>
                )}
            </Row>
            <Table
                size="small"
                data-sdet={CLASSIFICATIONS_LABELS_TABLE_SDET}
                onChange={onChange}
                showSorterTooltip={false}
                dataSource={data}
                columns={getLabelsColumns(sortBy, sortOrder, canEditSeat, onClickKey, onClickEdit)}
                loading={isFetching}
                pagination={paginationConfig}
                rowKey="id"
            />
        </Space>
    );
};
