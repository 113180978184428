import { Col, Typography } from "antd";
import { AdSource } from "@app/core/services";
import { AdSourceTypes } from "@app/features/seatAdSources/constants";
import { getAddOnContent } from "@app/core/components/Fields/CurrencyInput";
import { useAudienceEnrichmentCostHelp } from "./useAudienceEnrichmentCostHelp";

const { Text, Paragraph } = Typography;

export const AudienceEnrichmentCostField = ({ adSource }: { adSource: AdSource }) => {
    const help = useAudienceEnrichmentCostHelp(adSource.type.name as AdSourceTypes);
    const [currencySymbol] = getAddOnContent(adSource?.currencyType?.code || "USD");

    const cpmRange = `${currencySymbol} ${adSource?.demandFee || 0} - ${currencySymbol} ${
        adSource?.bookingPrice ? adSource?.bookingPrice / 1000 : 0
    }`;

    return (
        <Col sm={8}>
            <Text strong>Audience Enrichment Cost (CPM)</Text>
            {/* ToDo Fix  View Details link */}
            <Paragraph>{`CPM range ${cpmRange}`}</Paragraph>
            <Paragraph type="secondary">{help}</Paragraph>
        </Col>
    );
};
