import { LabeledValue } from "antd/es/select";

export const COLUMN_NAMES = {
    NAME: "name",
    TYPE: "type",
    ELEMENTS: "iabCategories",
    ACTIONS: "actions",
};

export const TYPE = {
    INDUSTRIES: "Industries",
    IAB_CATEGORIES: "IAB Categories",
};

export const HELP_KEYS = [
    {
        key: "name",
        label: "Name",
        helpPath: "separationgroup.name",
    },
    {
        key: "iabCategories",
        label: "IAB Categories",
        helpPath: "separationgroup.iabcategorylist",
    },
];

export const COMPETITIVE_SEPARATION_GROUPS_FORM_NAMES = {
    NAME: "name",
    MODE: "mode",
    IAB_CATEGORIES: "iabCategories",
    INDUSTRIES: "industries",
};

export interface InitialValuesType {
    id?: number;
    name: string;
    mode: boolean;
    iabCategories: LabeledValue[];
    industries: LabeledValue[];
}
