import { consoleApi, SEAT_SEGMENTS_TAG } from "./console";
import { toQueryString } from "./utils";

interface Entity {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    entityType?: string;
}

interface Dmp {
    creationDate: string;
    updateDate: string;
    id: number;
    code: string;
    name: string;
    daysToKeep: number | null;
    dmpDataType: Entity;
    dmpIngestionMode?: Entity & { sort: number };
    dmpVendor: Entity;
    description: string;
    seatId: null;
    datastoreCode?: string;
    membershipExpiration?: boolean;
    config: {
        creationDate: string;
        updateDate: string;
        id: number;
        prefix: string;
        regex: string;
        processor: string;
        chunkSize: number;
        checkpointSize: number;
        writeConcurrency: number;
        readConcurrency: number;
        processEphemeral: boolean;
        entityType: string;
    } | null;
    serviceRegions: Entity[];
    supportedIdentifiers: Entity[];
    tcfId?: null;
    lastRefreshed?: string;
    lastUpdated?: string;
    entityType: string;
}

export interface SeatSegment {
    code: string;
    creationDate: string;
    ctvPrice: number;
    digitalPrice: number;
    dmp: Dmp;
    expiration: number;
    hidden: boolean;
    id: number;
    name: string;
    segmentMembershipCount: number;
    segmentStatus: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string;
    };
    updateDate: string;
}

export interface GetSeatSegmentsParams {
    seatId: number;
    page?: number;
    max?: number;
    keyword?: string;
    hidden?: boolean;
}

export interface GetSegmentParams {
    dmpId: number;
    code: string;
}

export const segmentsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getSeatSegments: builder.query<SeatSegment[], GetSeatSegmentsParams>({
            query: (args: GetSeatSegmentsParams) => {
                const params = {
                    page: 1,
                    max: 100,
                    keyword: "",
                    hidden: false,
                    ...args,
                };
                const { seatId, ...restParams } = params;
                const queryString = toQueryString(restParams);
                return `seats/${seatId}/segments;${queryString}`;
            },
            providesTags: [SEAT_SEGMENTS_TAG],
        }),
        getSegment: builder.query<SeatSegment, GetSegmentParams>({
            query: (args: GetSegmentParams) => {
                const { dmpId, code } = args;

                return `dmps/${dmpId}/segments/codes/${encodeURIComponent(code)}`;
            },
            providesTags: [SEAT_SEGMENTS_TAG],
        }),
    }),
    overrideExisting: false,
});

export const { useGetSeatSegmentsQuery, useGetSegmentQuery, useLazyGetSegmentQuery } = segmentsApi;
