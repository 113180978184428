import { OptionType, useContextSelectForm } from "../Steps/hooks/useContextSelectForm";
import { Form, Row, Select, Typography } from "antd";
import { css } from "@emotion/css";
import { Loading } from "@app/core/components";

interface ContextSelectFormProps {
    handleSelect?: (value: number | string | null) => void;
}

const ContextSelectForm = ({ handleSelect }: ContextSelectFormProps) => {
    const {
        handleChangeSearch,
        handleChangeValue,
        isLoading,
        options,
        search,
        value,
        form,
        CONTEXT_FORM_FIELDS,
        isWaitingOnRefresh,
    } = useContextSelectForm();
    const handleSelectValue = (value: number | null, option: OptionType): void => {
        handleSelect?.(value);
        handleChangeValue(value, option);
    };

    if (isWaitingOnRefresh) {
        return (
            <Row
                className={css`
                    height: 5rem;
                `}
            >
                <Loading position="relative" />
            </Row>
        );
    }

    return (
        <Form layout="vertical" form={form}>
            <Form.Item
                className={css`
                    margin-bottom: 0;
                `}
                name={CONTEXT_FORM_FIELDS.Context}
                label={
                    <Typography.Text
                        strong
                        className={css`
                            font-size: 1rem;
                        `}
                    >
                        Select Context
                    </Typography.Text>
                }
            >
                <Select
                    className={css`
                        &.ant-select-single {
                            .ant-select-selector {
                                height: 42px;

                                .ant-select-selection-search-input {
                                    height: 42px;
                                }

                                .ant-select-selection-placeholder {
                                    line-height: 42px;
                                }

                                .ant-select-selection-item {
                                    line-height: 42px;
                                }
                            }
                        }
                    `}
                    allowClear
                    autoClearSearchValue={false}
                    autoFocus
                    defaultOpen
                    loading={isLoading}
                    onSearch={handleChangeSearch}
                    onSelect={handleSelectValue}
                    placeholder="e.g. seat, buyer"
                    filterOption={false}
                    options={options}
                    searchValue={search}
                    showSearch
                    value={value || undefined}
                    data-sdet="context"
                />
            </Form.Item>
        </Form>
    );
};

export default ContextSelectForm;
