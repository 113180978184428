import { FC, ReactNode } from "react";
import { Form, Select } from "antd";
import { useGetFreqCappingTypesQuery } from "@app/core/services";

interface Props {
    name: string;
    label: ReactNode;
}

export const AdvertiserFrequencyCappingTypeFormItem: FC<Props> = ({ name, label }) => {
    const { data = [], isFetching } = useGetFreqCappingTypesQuery();

    return (
        <Form.Item name={name} label={label} style={{ maxWidth: "275px" }}>
            <Select
                labelInValue
                optionFilterProp="label"
                options={data.map((list) => ({
                    value: list.id,
                    label: list.name,
                }))}
                loading={isFetching}
                placeholder="Select Advertiser Frequency Capping"
            />
        </Form.Item>
    );
};
