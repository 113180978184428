import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LabeledValue } from "antd/lib/select";
import { RootState } from "@app/core/store";
import {
    DealListSearchFilter,
    DealListTableFilters,
    DealListTablePagination,
    DealListTableSort,
} from "@app/features/deals/DealListPage/types";
import { DEFAULT_PAGE_SIZE, DEFAULT_TOTAL_RESULTS } from "@app/core/components/constants";
import { CurrencyConversionModes } from "@app/core/clients/console";
import { CURRENCIES_BY_CODE } from "@app/core/utils/currencies";

export const USD_OPTION_ID = 1;

export interface DealSettings {
    dealCurrencyConversionMode: CurrencyConversionModes;
    dealPreferredCurrency: number;
}

export interface DealsState {
    filters: DealListTableFilters;
    sort: DealListTableSort;
    pagination: DealListTablePagination;
    searchFilter: DealListSearchFilter;
    settings: {
        shouldUpdate: boolean;
        values: DealSettings;
    };
}

const initialState: DealsState = {
    filters: {
        filterBuyerStatus: [],
        filterDealOrigin: null,
        pricingType: null,
        filterStatus: [],
        filterDsp: [],
        filterStartDate: null,
        filterEndDate: null,
        filterPriorities: [],
        filterDealType: [],
    },
    sort: {
        orderBy: "",
        asc: null,
    },
    pagination: {
        page: 1,
        maxResults: DEFAULT_PAGE_SIZE,
        totalResults: DEFAULT_TOTAL_RESULTS,
    },
    searchFilter: {
        searchKeyword: "",
    },
    settings: {
        shouldUpdate: false,
        values: {
            dealCurrencyConversionMode: CurrencyConversionModes.CONVERT,
            dealPreferredCurrency: USD_OPTION_ID,
        },
    },
};

const dealsSlice = createSlice({
    name: "deals",
    initialState,
    reducers: {
        clearFilters: (state) => {
            state.filters = initialState.filters;
        },
        setFiltersBuyerStatus: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.filterBuyerStatus = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setFiltersDealOrigin: (state, action: PayloadAction<LabeledValue | null>) => {
            state.filters.filterDealOrigin = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setFiltersPricingType: (state, action: PayloadAction<LabeledValue | null>) => {
            state.filters.pricingType = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setFiltersStatus: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.filterStatus = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setFiltersDsp: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.filterDsp = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setFiltersStartDate: (state, action: PayloadAction<string | null>) => {
            state.filters.filterStartDate = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setSort: (state, action: PayloadAction<DealListTableSort>) => {
            state.sort = action.payload;
        },
        setFiltersEndDate: (state, action: PayloadAction<string | null>) => {
            state.filters.filterEndDate = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setPagination: (state, action: PayloadAction<DealListTablePagination>) => {
            state.pagination = action.payload;
        },
        setSearchFilter: (state, action: PayloadAction<DealListSearchFilter>) => {
            state.searchFilter = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setFiltersDealListPriority: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.filterPriorities = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setFiltersDealListType: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.filterDealType = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setSettingsCurrencyConversionMode: (state, action: PayloadAction<CurrencyConversionModes | null>) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.settings.values.dealCurrencyConversionMode = action.payload;
            state.settings.shouldUpdate = true;
        },
        setSettingsPreferredCurrency: (state, action: PayloadAction<number | null>) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.settings.values.dealPreferredCurrency = action.payload;
            state.settings.shouldUpdate = true;
        },
        setInitialSettings: (state, action: PayloadAction<Partial<DealSettings>>) => {
            const { dealPreferredCurrency, ...rest } = action.payload;
            state.settings.values = {
                ...state.settings.values,
                ...rest,
            };
            if (dealPreferredCurrency) {
                if (typeof dealPreferredCurrency === "number") {
                    state.settings.values.dealPreferredCurrency = dealPreferredCurrency;
                } else {
                    const currency = CURRENCIES_BY_CODE[dealPreferredCurrency];
                    if (currency) {
                        state.settings.values.dealPreferredCurrency = currency.id;
                    }
                }
            }
            state.settings.shouldUpdate = false;
        },
    },
});

export const selectDealFilters = (state: RootState) => state.deals.filters;
export const selectDealSort = (state: RootState) => state.deals.sort;
export const selectDealPagination = (state: RootState) => state.deals.pagination;
export const selectDealSearchFilter = (state: RootState) => state.deals.searchFilter;
export const selectDealFilterCount = (state: RootState): number =>
    Object.values(state.deals.filters).filter((filter) => filter && (!Array.isArray(filter) || filter.length > 0))
        .length;

export const {
    clearFilters,
    setFiltersBuyerStatus,
    setFiltersDealOrigin,
    setFiltersDsp,
    setFiltersPricingType,
    setFiltersStatus,
    setPagination,
    setFiltersStartDate,
    setFiltersEndDate,
    setSearchFilter,
    setSort,
    setFiltersDealListPriority,
    setFiltersDealListType,
    setSettingsCurrencyConversionMode,
    setSettingsPreferredCurrency,
    setInitialSettings,
} = dealsSlice.actions;
export default dealsSlice.reducer;
