import { ROUTE_FORMATTERS } from "@app/core/routing";
import { AdUnit } from "@app/core/services";
import { ColumnType } from "antd/lib/table";
import { CONTROLS_ROUTES } from "@app/features/controls/constants";
import { useParams } from "react-router-dom";

const JSTREE_NEW = -999; //TODO no need when we create our controls page instead of iframe

export enum Rules {
    PRE_ROLL = "Pre-Roll",
    MID_ROLL = "Mid-Roll",
    POST_ROLL = "Post-Roll",
}

const COLUMN_KEY = {
    INDEX: "index",
    NAME: "name",
    OF_ADS: "of-ads",
    DURATION: "duration",
} as const;

const columns: ColumnType<AdBreak>[] = [
    {
        dataIndex: COLUMN_KEY.NAME,
        title: "Name",
    },
    {
        dataIndex: COLUMN_KEY.OF_ADS,
        title: "# of Ads",
    },
    {
        dataIndex: COLUMN_KEY.DURATION,
        title: "Duration",
    },
];

const adBreakColumns: ColumnType<AdBreak>[] = [
    {
        dataIndex: COLUMN_KEY.INDEX,
        title: "Index",
    },
    ...columns,
];

export interface AdBreak {
    index?: number | null;
    key: number;
    name: string;
    "of-ads": number;
    duration: number;
}

export interface AdBreakDatum {
    preRollDataSource: AdBreak[];
    defaultDataSource: AdBreak[];
    adBreakDataSource: AdBreak[];
    postRollDataSource: AdBreak[];
}

interface UseAdBreakRules {
    columns: ColumnType<AdBreak>[];
    adBreakColumns: ColumnType<AdBreak>[];
    preRollDataSource: AdBreak[];
    defaultDataSource: AdBreak[];
    adBreakDataSource: AdBreak[];
    postRollDataSource: AdBreak[];
    isCuepoints: boolean;
    advancedAdBreaksUrl: string;
}

export const useAdBreakRules = (adUnit: AdUnit): UseAdBreakRules => {
    const { seatId } = useParams<{ seatId: string }>();
    const isCuepoints = Boolean(adUnit?.cuepoints?.length);
    const defaultValue: {
        preRollDataSource: AdBreak[];
        defaultDataSource: AdBreak[];
        adBreakDataSource: AdBreak[];
        postRollDataSource: AdBreak[];
    } = {
        preRollDataSource: [],
        defaultDataSource: [],
        adBreakDataSource: [],
        postRollDataSource: [],
    };
    const { adBreakDataSource, defaultDataSource, postRollDataSource, preRollDataSource } =
        adUnit?.adBreakRules?.reduce(
            (acc, { id, type, adBreakLabel, maxAdsPerPod, midRollIndex, maxPodLengthSeconds }) => {
                const value = {
                    key: id,
                    [COLUMN_KEY.NAME]: adBreakLabel,
                    [COLUMN_KEY.OF_ADS]: maxAdsPerPod,
                    [COLUMN_KEY.DURATION]: maxPodLengthSeconds,
                };
                switch (type.name) {
                    case Rules.PRE_ROLL:
                        acc.preRollDataSource.push(value);
                        break;
                    case Rules.MID_ROLL:
                        Number(midRollIndex) < 0
                            ? acc.defaultDataSource.push({
                                  [COLUMN_KEY.INDEX]: midRollIndex,
                                  ...value,
                              })
                            : acc.adBreakDataSource.push({
                                  [COLUMN_KEY.INDEX]: midRollIndex,
                                  ...value,
                              });
                        break;
                    case Rules.POST_ROLL:
                        acc.postRollDataSource.push(value);
                        break;
                }
                return acc;
            },
            defaultValue
        );

    const advancedAdBreaksUrl = ROUTE_FORMATTERS.SEAT_CONTROLS_TABS_CREATE(
        seatId,
        CONTROLS_ROUTES.ADVANCED_AD_BREAKS,
        JSTREE_NEW
    );

    return {
        columns,
        adBreakColumns,
        preRollDataSource,
        postRollDataSource,
        defaultDataSource,
        adBreakDataSource,
        isCuepoints,
        advancedAdBreaksUrl,
    };
};
