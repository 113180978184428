import { format } from "@rubicon/utils";
import { SegmentRuleGroup, SegmentRuleIdentifier } from "@app/features/targeting/types";
import { SegmentRuleIdentifierOperators } from "@app/features/targeting/constants";

export const getSegmentRuleIdentifierFieldLabel = (field: string) => {
    // Looks like USER_ID is the only field we have for now, but there may be more in the future
    if (field === "USER_ID") {
        return "User";
    }
    return "Unknown Field";
};

export const getSegmentRuleIdentifierOperatorLabel = (operator: SegmentRuleIdentifierOperators) => {
    if (operator === SegmentRuleIdentifierOperators.IsMember) {
        return "is a member of";
    }
    return "is not a member of";
};

export const isSegmentRuleGroupEmpty = (group: SegmentRuleGroup) => {
    return group.rules.length === 1 && "value" in group.rules[0] && group.rules[0].value === null;
};

export const isSegmentRuleIdentifierValid = (rule: SegmentRuleIdentifier) => {
    return rule.operator !== null && rule.value !== null;
};

export const isSegmentRuleTargetingValid = (group: SegmentRuleGroup) => {
    return group.rules.every((rule) => {
        if ("rules" in rule) {
            return Boolean(rule.rules.length) && isSegmentRuleTargetingValid(rule);
        }
        return isSegmentRuleIdentifierValid(rule);
    });
};

export const getSegmentRuleGroupString = (group: SegmentRuleGroup) => {
    return group.rules
        .map((rule) => {
            if ("rules" in rule) {
                return `(${getSegmentRuleGroupString(rule)})`;
            }
            return `${getSegmentRuleIdentifierFieldLabel(rule.field)} ${getSegmentRuleIdentifierOperatorLabel(
                rule.operator
            )} ${format.asSelf(rule.value?.label)}`;
        })
        .join(` ${group.condition} `);
};

const PAGE_SIZE_MAX = 100;

export const getParams = (keyword, pageByKeyword, seatId) => ({
    keyword,
    max: PAGE_SIZE_MAX,
    page: pageByKeyword[keyword] || 1,
    seatId: Number(seatId),
});
