import { ResizableDrawer } from "@rubicon/antd-components";
import { FC, useState } from "react";
import { drawerRightClosePosition } from "../../constants";
import { BulkOperationExecution } from "@app/core/services";
import { Col, Form, Row } from "antd";
import { toTitleCase } from "@rubicon/utils/dist/string";
import { startCase } from "lodash";
import { formatDateInUtc } from "@app/core/utils";
import { YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE } from "@app/core/components/constants";
import { getEntitiesIds, getExecutionErrorsMessage, getFailedEntities } from "../helpers";

export const useBulkOperationDetailsDrawer = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [bulkOperationExecution, setBulkOperationExecution] = useState<BulkOperationExecution | null>(null);

    const handleOpen = (record: BulkOperationExecution) => {
        setIsOpen(true);
        setBulkOperationExecution(record);
    };

    const handleClose = () => setIsOpen(false);

    const handleAfterOpenChange = () => !isOpen && setBulkOperationExecution(null);

    return {
        bulkOperationExecution,
        isOpen,
        handleClose,
        handleOpen,
        handleAfterOpenChange,
    };
};

interface Props {
    bulkOperationExecution: BulkOperationExecution;
    isOpen: boolean;
    handleClose: () => void;
    handleAfterOpenChange: () => void;
}

export const BulkOperationDetailsDrawer: FC<Props> = ({
    isOpen,
    handleClose,
    handleAfterOpenChange,
    bulkOperationExecution,
}) => {
    const {
        code,
        error,
        updateDate,
        definition: { type, entityType, sourceEntity },
    } = bulkOperationExecution;

    return (
        <ResizableDrawer
            afterOpenChange={handleAfterOpenChange}
            open={isOpen}
            onClose={handleClose}
            placement="right"
            width="45%"
            title="Bulk Operation Details"
            className={drawerRightClosePosition}
        >
            <Form layout="vertical">
                <Row>
                    <Col xs={24} md={8}>
                        <Form.Item label="Operation Type">{toTitleCase(type)}</Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item label="Entity Type">{startCase(entityType || sourceEntity)}</Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={8}>
                        <Form.Item label="Execution Code">{code}</Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item label="Execution Date">
                            {formatDateInUtc(updateDate, YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE)}
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label="Entities Included">{getEntitiesIds(bulkOperationExecution.definition)}</Form.Item>
                <Form.Item label="Entities Failed">{getFailedEntities(error)}</Form.Item>
                <Form.Item label="Errors">{getExecutionErrorsMessage(error)}</Form.Item>
            </Form>
        </ResizableDrawer>
    );
};
