import { useUserAccess } from "@app/core/auth";
import { useOpenHelpCenter } from "@app/core/services/console";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { NavMenuItemProps } from "../Nav";
import { getMoreMenuItems } from "./helpers";
interface UseBuyerMoreSubMenuItems {
    moreSubMenuItems: NavMenuItemProps[];
    hasMoreMenu: boolean;
}

export const useBuyerMoreSubMenuItems = (
    contextId: string | number | null | undefined,
    handleMenuClose: () => void
): UseBuyerMoreSubMenuItems => {
    const history = useHistory();
    const { seatAccessFlags } = useUserAccess();
    const openHelpCenter = useOpenHelpCenter();
    const [moreSubMenuItems, setMoreSubMenuItems] = useState<NavMenuItemProps[]>(
        getMoreMenuItems(seatAccessFlags, contextId, history, handleMenuClose, openHelpCenter)
    );
    const hasMoreMenu = Boolean(moreSubMenuItems.length);

    useEffect(() => {
        setMoreSubMenuItems(getMoreMenuItems(seatAccessFlags, contextId, history, handleMenuClose, openHelpCenter));
    }, [seatAccessFlags, history, handleMenuClose, contextId, openHelpCenter]);

    return {
        moreSubMenuItems,
        hasMoreMenu,
    };
};
