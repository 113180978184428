import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useHistory, useParams } from "react-router-dom";
import { BrandSafetySectionKey } from "./helpKeysButton";

interface UseHelpKeysButton {
    onClick: () => void;
}
export const useHelpKeysButton = (sectionKey: BrandSafetySectionKey): UseHelpKeysButton => {
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();

    const onClick = () => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_DEFINITION(seatId, sectionKey));
    };

    return {
        onClick,
    };
};
