import {
    Brand,
    BrandPayload,
    useCreateBrandMutation,
    useUpdateBrandMutation,
    useDeleteBrandMutation,
} from "@app/core/services";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useUserAccess } from "@app/core/auth";
import { LabeledValue } from "antd/lib/select";
import { FormInstance, notification } from "antd";
import { Store } from "rc-field-form/lib/interface";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { InventoryDetailsDrawerType } from "../../DetailsDrawer/reducer";
import {
    getBrandFormInitialValues,
    parseBrandFormValuesToApi,
} from "@app/features/inventory/HierarchyForms/BrandForm/brandFormUtils";
import { DEFAULT_FALLBACK_MESSAGE } from "@app/features/inventory/constants";
import { INVENTORY_FLOORS_ADVANCED } from "@app/features/inventory/InventoryFloors";
import { BRAND_FORM_FIELDS, InventoryCodeType } from "@app/features/inventory/HierarchyForms/constants";
import { useBrandFormInstance } from "@app/features/inventory/HierarchyForms/BrandForm/BrandFormInstance";
import { LabelValueOption } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/useSeatDetailsForm";

export interface BrandForm {
    // General Section
    [BRAND_FORM_FIELDS.NAME.name]: string;
    [BRAND_FORM_FIELDS.CODE_TYPE.name]: InventoryCodeType;
    [BRAND_FORM_FIELDS.CODE.name]: string;
    [BRAND_FORM_FIELDS.DESCRIPTION.name]: string;
    // Affiliate Section
    [BRAND_FORM_FIELDS.AFFILIATE_COST_MODEL.name]: LabeledValue;
    [BRAND_FORM_FIELDS.AFFILIATE_COST_VALUE_PERCENT.name]: number | null;
    [BRAND_FORM_FIELDS.AFFILIATE_COST_VALUE_FIXED.name]: number | null;
    // Brand Safety Section
    [BRAND_FORM_FIELDS.BLOCK_ADVERTISER_DOMAIN_LISTS.name]: LabeledValue[];
    [BRAND_FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: string;
    [BRAND_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.name]: LabeledValue | null;
    [BRAND_FORM_FIELDS.BLOCKED_CATEGORIES.name]: LabeledValue[];
    [BRAND_FORM_FIELDS.CREATIVE_BLOCK_MODE.name]: number;
    // Internal Section
    [BRAND_FORM_FIELDS.LOWER_CALCULON_USE_RATE_OVERRIDE.name]: number | null;
    [BRAND_FORM_FIELDS.UPPER_CALCULON_USE_RATE_OVERRIDE.name]: number | null;
    [BRAND_FORM_FIELDS.PUBLISHER_RE_AUCTION.name]: boolean | string;
    // Supply Details Section
    [BRAND_FORM_FIELDS.CATEGORIES.name]: LabeledValue[];
    [BRAND_FORM_FIELDS.ADVERTISERS_AS_WHITELIST.name]: boolean;
    // Custom Pixels
    [BRAND_FORM_FIELDS.CUSTOM_PIXELS.name]: LabeledValue[];
    // Labels
    [BRAND_FORM_FIELDS.LABELS.name]: LabelValueOption[];
    [BRAND_FORM_FIELDS.INTERNAL_LABELS.name]: LabelValueOption[];
    [BRAND_FORM_FIELDS.DISTRIBUTION_GROUP_LABELS.name]: LabelValueOption[];
}

export interface UseBrandForm {
    open: boolean;
    loading: boolean;
    isEditMode: boolean;
    isDeleting: boolean;
    initialValues: Store;
    showModal: () => void;
    handleSubmit: () => void;
    brand: Brand | undefined;
    handleCancel: () => void;
    handleDelete: () => void;
    submitButtonTitle: string;
    hasSeatWriteAccess: boolean;
    isFloorsSectionShown: boolean;
    form: FormInstance<BrandForm>;
    handleCancelModal: () => void;
    isInternalSectionShown: boolean;
    isAffiliateSectionShown: boolean;
    handleAdvancedFloors: () => void;
}

export const useBrandForm = (): UseBrandForm => {
    const history = useHistory();
    const { isTremorUser } = useUserAccess();
    const [open, setOpen] = useState<boolean>(false);
    const { isSysAdmin, isPubAcctMgr, hasInternalAccess, hasSeatWriteAccess } = useUserAccess();
    const { brand, mode, publisherId, form, seatId, seat } = useBrandFormInstance();

    const isEditMode: boolean = mode === "edit";
    const submitButtonTitle = mode !== "create" ? "Save" : "Submit";
    const isAffiliateSectionShown: boolean = isTremorUser && !!seat?.affiliateCostEnabled;
    const initialValues: Store = getBrandFormInitialValues({ brand, mode });

    const [createBrand, { isLoading: isCreating }] = useCreateBrandMutation();
    const [updateBrand, { isLoading: isUpdating }] = useUpdateBrandMutation();
    const [deleteBrand, { isLoading: isDeleting }] = useDeleteBrandMutation();

    const handleCancelModal = (): void => setOpen(false);
    const showModal = (): void => setOpen(true);
    const handleCancel = (): void => history.goBack();

    const handleCreate = async (body: BrandPayload): Promise<void> => {
        if (!publisherId) return notification.error({ message: DEFAULT_FALLBACK_MESSAGE });
        try {
            const res = await createBrand({ publisherId: publisherId, body }).unwrap();
            notification.success({ message: "Brand created successfully" });
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT(
                    InventoryDetailsDrawerType.BRAND,
                    seatId,
                    publisherId,
                    res.id
                )
            );
        } catch (err) {
            notification.error({ message: err?.data?.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        }
    };
    const handleUpdate = async (body: BrandPayload): Promise<void> => {
        if (!brand) return notification.error({ message: DEFAULT_FALLBACK_MESSAGE });
        try {
            await updateBrand({ ...body, id: brand.id }).unwrap();
            notification.success({ message: "Brand updated successfully" });
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT(
                    InventoryDetailsDrawerType.BRAND,
                    seatId,
                    publisherId,
                    brand.id
                )
            );
        } catch (err) {
            notification.error({ message: err?.data?.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        }
    };
    const handleDelete = async (): Promise<void> => {
        if (!isEditMode || !brand) return notification.error({ message: DEFAULT_FALLBACK_MESSAGE });
        try {
            await deleteBrand(brand.id).unwrap();
            notification.success({ message: "Brand deleted successfully" });
            history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH(seatId));
        } catch (err) {
            notification.error({ message: err?.data?.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        }
    };
    const handleSubmit = async (): Promise<void> => {
        try {
            await form.validateFields();
            const payload: BrandPayload = parseBrandFormValuesToApi({
                mode,
                seat,
                isSysAdmin,
                publisherId,
                isPubAcctMgr,
                isTremorUser,
                hasInternalAccess,
                values: form.getFieldsValue(),
            });
            if (isEditMode) return handleUpdate(payload);
            return handleCreate(payload);
        } catch (error) {
            form.scrollToField(error.errorFields[0].name, { behavior: "smooth", block: "center" });
        }
    };
    const handleAdvancedFloors = (): void =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_TABS(seatId, INVENTORY_FLOORS_ADVANCED));

    return {
        open,
        form,
        brand,
        showModal,
        isEditMode,
        isDeleting,
        handleCancel,
        handleSubmit,
        handleDelete,
        initialValues,
        handleCancelModal,
        submitButtonTitle,
        hasSeatWriteAccess,
        handleAdvancedFloors,
        isAffiliateSectionShown,
        isFloorsSectionShown: isEditMode,
        loading: isCreating || isUpdating,
        isInternalSectionShown: isSysAdmin || isPubAcctMgr,
    };
};
