import { Form, Select } from "antd";
import { FC, useEffect } from "react";
import { Seat, VastErrorsUrlSearchParams, useGetSeatAllBrandsQuery } from "@app/core/services";
import { useSeatAuthContext } from "@app/core/auth";
import { useHistory, useLocation } from "react-router-dom";
import { uri } from "@rubicon/utils";

interface Props {
    id: number | null;
}

export const BrandFitler: FC<Props> = ({ id }) => {
    const { context } = useSeatAuthContext();
    const history = useHistory();
    const { search } = useLocation();
    const { data, isLoading } = useGetSeatAllBrandsQuery({ seatId: (context as Seat).id }, { skip: !context?.id });

    useEffect(() => {
        if (!id && !isLoading && data) {
            handleChange(data[0].id.toString());
        }
    });

    const handleChange = (value) => {
        const nextSearchUpdated = uri.setSearchParam(search, VastErrorsUrlSearchParams.entityId, value.toString());
        history.push({ search: nextSearchUpdated });
    };

    return (
        <Form.Item label="Brand">
            <Select
                style={{ width: "100%" }}
                defaultValue={id || data?.[0].id}
                value={id || data?.[0].id}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                options={data?.map((brand) => ({ label: brand.name, value: brand.id }))}
                onChange={handleChange}
                disabled={isLoading}
                loading={isLoading}
            />
        </Form.Item>
    );
};
