import { useGetSeatThirdPartyPixelsQuery } from "@app/core/services";
import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { useParams } from "react-router-dom";

interface UseAdSourcePixelsField {
    isFetching: boolean;
    options: LabeledValue[];
    value: number[];
    handleChange: (value: number[]) => void;
}

export const useAdSourcePixelsField = (): UseAdSourcePixelsField => {
    const dispatch = useAppDispatch();
    const { seatId } = useParams<{ seatId: string }>();
    const { data, isFetching } = useGetSeatThirdPartyPixelsQuery(Number(seatId));

    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PIXELS]);
    const options = useMemo(() => (data || []).map((item) => ({ label: item.name, value: item.id })), [data]);

    const handleChange = (value: number[]): void => {
        dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PIXELS, value }));
    };

    return {
        options,
        isFetching,
        handleChange,
        value,
    };
};
