import { SeatStatsSnapshot, useGetSeatStatsSnapshotsQuery } from "@app/core/services/adStats/statsSnapshots";
import { LabeledValue } from "antd/lib/select";
import { useState } from "react";
import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM } from "@app/core/components/constants";
import { TimingStatsSnapshotsResponse } from "@app/core/services";
import moment from "moment-timezone";

export const getSnapshotDateLabel = (snapshot: SeatStatsSnapshot | TimingStatsSnapshotsResponse) => {
    const [, timestamp] = snapshot.filename.match(/\/(\d+)\.json/) || [];

    if (!timestamp) {
        return snapshot.filename;
    }

    const currentDate = moment.unix(+timestamp / 1000);
    const previousDate = moment.unix(+timestamp / 1000).subtract(1, "day");

    return `${previousDate.format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM)} - ${currentDate.format(
        MONTH_DAY_YEAR_HOUR_MINUTE_AMPM
    )}`;
};

export const useSnapshots = (seat) => {
    const [value, setValue] = useState<string | null>(null);
    const [snapshotTimestamp, setSnapshotTimestamp] = useState<string | null>(null);
    const [selectedSnapshotLabel, setSelectedSnapshotLabel] = useState<string | null>(null);
    const { data, isLoading } = useGetSeatStatsSnapshotsQuery({ seatId: seat?.id }, { skip: !seat });

    const options = data?.map((snapshot, index) => ({
        label: getSnapshotDateLabel(snapshot),
        value: snapshot.filename,
        filename: snapshot.filename,
        key: index.toString(),
    }));

    const handleChange = (e: LabeledValue & { filename: string }): void => {
        setValue(e.value as string);
        setSnapshotTimestamp(e.filename.match(/\/(\d+)\.json/)?.[1] || null);
        setSelectedSnapshotLabel(e.label as string);
    };

    return {
        data,
        isLoading,
        options,
        value,
        handleChange,
        snapshotTimestamp,
        selectedSnapshotLabel,
    };
};
