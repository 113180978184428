import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { getTimeZoneLabel, useGetTimeZonesQuery } from "@app/core/services";

interface UseTimeZone {
    options: LabeledValue[];
    isFetching: boolean;
}

export const useTimeZone = (): UseTimeZone => {
    const { data, isFetching } = useGetTimeZonesQuery();
    const options: LabeledValue[] = useMemo(
        () =>
            (data || []).map((timeZone) => ({
                label: getTimeZoneLabel(timeZone),
                value: timeZone.id,
            })),
        [data]
    );
    return {
        options,
        isFetching,
    };
};
