export interface TimingStats {
    data: {
        [key: string]: {
            // region ex. "us-east-1"
            dData: {
                def: {
                    ntime: number;
                    otime: number;
                    tData: {
                        [key: string]: {
                            // range ex. "200-249"
                            ct: number;
                            tt: number;
                        };
                    };
                };
            };
            ntime: number;
            otime: number;
        };
    };
    ntime: number;
    otime: number;
}

export interface TimingStatsApiParams {
    entityType: TimingStatsEntityTypes;
    entityId: number;
    multiplicity?: number | string;
}

export enum TimingStatsEntityTypes {
    AdSource = "adSources",
    AdUnit = "adUnits",
}

export interface TimingStatsEventCount {
    millisecondRange: string;
    eventCount: number;
    cumulativePercentage: number;
    averageTiming: number;
    runningCount: number;
    runningAverageTiming: number;
}

export interface TimingStatsSnapshots extends Required<Pick<TimingStatsApiParams, "multiplicity">> {
    id: number;
}

export interface TimingStatsSnapshot extends TimingStatsSnapshots {
    snapshotId: number | string;
}

export interface TimingStatsSnapshotsResponse {
    emailAddress: string;
    entityId: string;
    entityName: null;
    entityType: string;
    filename: string;
    message: string;
    subEntityId: null;
    subEntityType: null;
}
