import { YEAR_MONTH_DAY } from "@app/core/components/constants";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormDateFieldChange, dealFormInputFieldChange } from "@app/features/deals/DealForm/reducer";
import { useCallback, useEffect, useMemo } from "react";
import { getPercentage } from "./helpers";
import moment from "moment-timezone";
import { PacingCurveLimits } from "@app/core/utils/pacingCalculator";

interface UseAdSourceCustomPacing {
    customCurveImps: number;
    totalImpressions: number;
    customCurveStartDate: moment.Moment;
    startDate: moment.Moment | string;
    endDate: moment.Moment | string;
    sliderValue: number;
    sliderPercentage: number;
    limits: {
        min: number;
        max: number;
    };
    formatter: (value: number) => string;
    handleChangePicker: (date: moment.Moment) => void;
    handleChangeSlider: (value: number) => void;
}

export const useAdSourceCustomPacing = (): UseAdSourceCustomPacing => {
    const dispatch = useAppDispatch();

    const totalImpressions =
        useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.IMPRESSION]) ?? 0;

    const customCurveStartDate = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_CUSTOM_CURVE_START_DATE]
    );

    const customCurveImps =
        useAppSelector(
            (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_CUSTOM_CURVE_IMPS]
        ) ?? 0;
    const startDate = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.START_DATE]);
    const endDate = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.END_DATE]);

    const limits: { min: number; max: number } | null = useMemo(() => {
        const mEndDate = endDate ? moment(endDate) : null;
        const mCurveStartDate = customCurveStartDate ? moment(customCurveStartDate) : null;

        if (!mEndDate || !startDate || !mCurveStartDate || !totalImpressions) {
            return {
                min: 0,
                max: 0,
            };
        }
        const campaignLength = mEndDate.diff(startDate, "days");
        const targetDay = mCurveStartDate.diff(startDate, "days");
        const completionPercentage = (targetDay + 1) / campaignLength;

        return {
            max: Math.floor(PacingCurveLimits.cubicFront(completionPercentage) * totalImpressions),
            min: Math.ceil(PacingCurveLimits.cubicBack(completionPercentage) * totalImpressions),
        };
    }, [endDate, startDate, customCurveStartDate, totalImpressions]);

    const handleChangePicker = (targetDate: moment.Moment) => {
        dispatch(
            dealFormDateFieldChange({
                field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_CUSTOM_CURVE_START_DATE,
                value: targetDate,
            })
        );
    };

    const handleChangeSlider = useCallback(
        (value: number) => {
            dispatch(
                dealFormInputFieldChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_CUSTOM_CURVE_IMPS,
                    value,
                })
            );
        },
        [dispatch]
    );

    const formatter = (value: number = 0) => `${value} (${getPercentage(value, totalImpressions)}%)`;

    useEffect(() => {
        // TODO: Make it support edit/copy mode
        handleChangeSlider(limits?.min);
    }, [limits, dispatch, handleChangeSlider]);

    return {
        customCurveImps,
        totalImpressions,
        customCurveStartDate: customCurveStartDate ? moment(customCurveStartDate) : moment(),
        startDate: startDate ? moment(startDate).format(YEAR_MONTH_DAY) : "",
        endDate: endDate ? moment(endDate).format(YEAR_MONTH_DAY) : "",
        sliderValue: customCurveImps ? customCurveImps : 0,
        sliderPercentage: getPercentage(customCurveImps ? customCurveImps : 0, totalImpressions),
        limits,
        formatter,
        handleChangePicker,
        handleChangeSlider,
    };
};
