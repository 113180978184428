import { FC } from "react";
import { Col, Row, Space, Typography } from "antd";
import { LabeledValue } from "antd/lib/select";
import { Conditions, TargetingDimensionTypeLabels } from "../../../constants";
import { OztamDemoDimension, UsedDimensions } from "../../../types";
import { ConditionSelect } from "../../ConditionSelect";
import { MoreMenu } from "../../MoreMenu";
import { BvodSelect } from "./BvodSelect";

interface Props {
    dimension: OztamDemoDimension;
    onChangeCondition: (value: Conditions) => void;
    onChangeDimensionValues: (value: LabeledValue[]) => void;
    onClickDelete: () => void;
    onClickDownload: () => void;
    onClickDuplicate: () => void;
    usedDimensions: UsedDimensions;
}

export const BvodDimensionRow: FC<Props> = ({
    dimension,
    onChangeCondition,
    onChangeDimensionValues,
    onClickDelete,
    onClickDownload,
    onClickDuplicate,
    usedDimensions,
}) => {
    return (
        <Row gutter={8}>
            <Col flex="150px">
                <Typography.Text strong>{TargetingDimensionTypeLabels.Bvod}</Typography.Text>
            </Col>
            <Col flex="128px">
                <ConditionSelect
                    onChange={onChangeCondition}
                    isExcludesDisabled={
                        dimension.condition !== Conditions.Excludes && usedDimensions.oztamDemographics.excludes
                    }
                    isIncludesDisabled={
                        dimension.condition !== Conditions.Includes && usedDimensions.oztamDemographics.includes
                    }
                    value={dimension.condition}
                />
            </Col>
            <Col flex="10">
                <BvodSelect onChange={onChangeDimensionValues} value={dimension.values} />
            </Col>
            <Col flex="146px" style={{ display: "flex", justifyContent: "flex-end" }}>
                <Space>
                    <MoreMenu
                        onClickDelete={onClickDelete}
                        onClickDownload={onClickDownload}
                        onClickDuplicate={onClickDuplicate}
                        isDuplicateDisabled={
                            usedDimensions.oztamDemographics.includes && usedDimensions.oztamDemographics.excludes
                        }
                    />
                </Space>
            </Col>
        </Row>
    );
};
