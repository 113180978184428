import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useSupplyFormInstance } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormInstance";

interface UseExternalName {
    onChange: (e: CheckboxChangeEvent) => void;
    placeholder: string | undefined;
    disabled: boolean;
    required: boolean;
    extra: string;
}

export const useExternalName = (): UseExternalName => {
    const { useWatch, form } = useSupplyFormInstance();
    const override = useWatch(SUPPLY_FORM_FIELDS.OVERRIDE.name);
    const name = useWatch(SUPPLY_FORM_FIELDS.NAME.name);
    const onChange = (): void => form.setFieldValue(SUPPLY_FORM_FIELDS.EXTERNAL_NAME.name, "");

    const placeholder = name || SUPPLY_FORM_FIELDS.EXTERNAL_NAME.placeholder;
    const extra = "This value will be sent in bid requests.";
    return { extra, disabled: !override, onChange, placeholder, required: override };
};
