import { VastStatsPieProps } from "../useVastStats";

export interface LegendData extends VastStatsPieProps {
    color: string;
}

export enum PinnedType {
    Select = "select",
    Hover = "hover",
    Default = "default",
}
export interface PinnedLegend {
    type: PinnedType;
    value: LegendData | null;
}
