import { FC, ReactNode } from "react";
import { useAnimatedNumber } from "@app/features/dashboard/useAnimatedNumber";

export const AnimatedCell: FC<{
    value: number;
    decimals?: number;
    prefix?: ReactNode;
    suffix?: ReactNode;
}> = ({ value, decimals = 0, prefix, suffix }) => {
    const animatedValue = useAnimatedNumber({
        value,
        isPlaying: true,
    });

    const addThousandsSeparator = (value: string, separator: string) =>
        value.replace(/\B(?=(\d{3})+(?!\d))/g, separator);

    if (prefix || suffix) {
        return (
            <>
                <>{prefix}</> {addThousandsSeparator(`${animatedValue.toFixed(decimals)}`, ",")} <>{suffix}</>
            </>
        );
    }
    return <>{addThousandsSeparator(`${animatedValue.toFixed(decimals)}`, ",")}</>;
};
