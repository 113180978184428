import { FC } from "react";
import { Card, Col, Row, Collapse } from "antd";
import { rowContentStyle } from "@app/features/deals/DealForm";
import { COL_SIZES, GUTTER } from "@app/features/deals/constants";
import {
    DealEnforcementField,
    DealFrequencyCappingField,
    DealTransactionCurrencyField,
    AddFrequencyCappingItem,
    DealFreqCappings,
} from "@app/features/deals/DealForm/DealDemandFormSections/DealDemandConfigurationSection/Fields";
import { DealAdditionalTargetingSection } from "@app/features/deals/DealForm/DealDemandFormSections";
import { additionalTargetingCollapsePanelClassList } from "@app/features/targeting/constants";
import { DealFormMode } from "@app/features/deals/DealForm/types";

export interface DealDemandConfigurationSectionProps {
    enforcementShown: boolean;
    frequencyCappingItemShown: boolean;
    mode: DealFormMode;
}

export const DealDemandConfigurationSection: FC<DealDemandConfigurationSectionProps> = ({
    enforcementShown,
    frequencyCappingItemShown,
    mode,
}) => {
    return (
        <Card
            bordered={false}
            styles={{ body: { padding: "0.75rem 3rem 0.75rem 3rem" } }}
            data-sdet="deal-create-form-demand-configuration-section"
        >
            <Row gutter={GUTTER} style={rowContentStyle}>
                <Col {...COL_SIZES}>
                    <DealTransactionCurrencyField />
                </Col>
                {enforcementShown && (
                    <Col {...COL_SIZES}>
                        <DealEnforcementField />
                    </Col>
                )}
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <DealFrequencyCappingField />
                </Col>
            </Row>
            {frequencyCappingItemShown && (
                <>
                    <Row gutter={GUTTER}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
                            <AddFrequencyCappingItem />
                        </Col>
                    </Row>
                    <Row gutter={GUTTER}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
                            <DealFreqCappings />
                        </Col>
                    </Row>
                </>
            )}
            {mode !== "create" && (
                <Row>
                    <Col span={24}>
                        <Collapse
                            ghost
                            defaultActiveKey={["1"]}
                            expandIconPosition="end"
                            items={[
                                {
                                    key: "1",
                                    label: "Additional Targeting",
                                    children: <DealAdditionalTargetingSection />,
                                    className: additionalTargetingCollapsePanelClassList,
                                },
                            ]}
                        />
                    </Col>
                </Row>
            )}
        </Card>
    );
};
