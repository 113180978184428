import { FC } from "react";
import { Select } from "antd";
import { LabeledValue } from "antd/lib/select";
import { ClockCircleOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { useTimeZoneField } from "./useTimeZoneField";
import { gray10 } from "@rubicon/antd-components";

const wrapperClassName = css`
    position: relative;
    .anticon {
        position: absolute;
        z-index: 1;
        height: 100%;
        left: 12px;
        display: flex;
        align-items: center;
        width: 3rem;
        color: ${gray10};
    }
    .ant-select-selection-item,
    .ant-select-selection-search-input {
        margin-left: 24px;
    }
    .ant-select-arrow {
        right: 32px;
    }
    .ant-select-selection-search {
        padding-left: 24px;
    }
`;

export const TimeZoneField: FC = () => {
    const { options, value, isLoading, handleChangeValue } = useTimeZoneField();

    return (
        <div className={wrapperClassName}>
            <ClockCircleOutlined />
            <Select
                style={{ width: "300px" }}
                data-sdet="timezone-field"
                loading={isLoading}
                onChange={(id, option) => {
                    // Ant's TS definitions don't play nicely with option groups
                    handleChangeValue(id, option as unknown as LabeledValue);
                }}
                optionFilterProp="label"
                options={options}
                showSearch
                value={value}
            />
        </div>
    );
};
