import { Form, Select } from "antd";
import { FC } from "react";
import { css } from "@emotion/css";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAdSourcePriority } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceConfigurationSection/Fields/AdSourcePriorityField/useAdSourcePriority";
import { brandCobalt } from "@rubicon/antd-components";

export const AdSourcePriorityField: FC = () => {
    const { options, isFetching, value, onSelect } = useAdSourcePriority();

    return (
        <Form.Item
            className={css`
                width: 8.1rem;
            `}
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PRIORITY}
            data-sdet="ad-source-priority-field"
            rules={[{ required: true, message: "Priority is required" }]}
            tooltip={{
                placement: "topRight",
                title: "Priority Priority determines where this item will sit within the Waterfall. There are 16 levels of priority available",
                icon: <QuestionCircleOutlined style={{ color: brandCobalt }} />,
            }}
            label="Priority"
        >
            <Select
                options={options}
                onSelect={onSelect}
                value={value}
                loading={isFetching}
                showSearch
                optionFilterProp="label"
            />
        </Form.Item>
    );
};
