import { useHistory, useParams } from "react-router-dom";
import { useGetAdvertiserDetailsQuery } from "@app/core/services";
import { Loading } from "@app/core/components";
import { useNotificationError } from "@app/core/components/hooks";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ClassificationsAdvertiserForm } from "./ClassificationsAdvertiserForm";
import { ClassificationsAdvertiserDefinitionsButtonAndDrawer } from "./ClassificationsAdvertisersTab/ClassificationsAdvertiserDefinitions";
import { PageHeader } from "@ant-design/pro-layout";

export const ClassificationAdvertiserEditPage = () => {
    const { seatId, advertiserId } = useParams<{ seatId: string; advertiserId: string }>();
    const { data: advertiser, isLoading, error } = useGetAdvertiserDetailsQuery(Number(advertiserId));
    const history = useHistory();

    useNotificationError(error);

    if (isLoading) return <Loading />;

    if (!advertiser) return null;

    return (
        <>
            <PageHeader
                onBack={() =>
                    history.push(
                        ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISER_DETAILS_PAGE(seatId, advertiserId)
                    )
                }
                title={`Edit: ${advertiser.name}`}
                extra={<ClassificationsAdvertiserDefinitionsButtonAndDrawer />}
            />
            <ClassificationsAdvertiserForm advertiser={advertiser} />
        </>
    );
};
