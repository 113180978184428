import { Typography, notification } from "antd";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { pixelPayload } from "../CustomPixelForm";

export const onFinishCreatePixel = (values, seatId, createSeatThirdPartyPixel, history) => {
    const body = pixelPayload(Number(seatId), values);
    createSeatThirdPartyPixel({ seatId, body })
        .unwrap()
        .then((res) => {
            notification.success({
                message: (
                    <>
                        <Typography.Text strong>{res.name}</Typography.Text> has successfully been created
                    </>
                ),
            });
            history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_CUSTOM_PIXELS_DETAILS(seatId, res.id));
        })
        .catch((err) => {
            notification.error({
                message:
                    err.data?.errorDescription ||
                    err.data?.errors?.[0]?.message ||
                    "Something wrong has occurred, please contact your account manager",
            });
        });
};
