import { FC, ReactNode } from "react";
import { Col, Row, Space, Typography } from "antd";

interface Props {
    condition: ReactNode;
    label: ReactNode;
    menu?: ReactNode;
    mode?: ReactNode;
    value: ReactNode;
    menuJustifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
}

export const DimensionRow: FC<Props> = ({
    label,
    condition,
    mode = null,
    value,
    menu = null,
    menuJustifyContent = "flex-end",
}) => {
    return (
        <Row gutter={8} align="top">
            <Col flex="150px">
                <Typography.Text strong>{label}</Typography.Text>
            </Col>
            <Col flex="128px">{condition}</Col>
            {mode && <Col flex="128px">{mode}</Col>}
            <Col flex="1">{value}</Col>
            {menu && (
                <Col flex="146px" style={{ display: "flex", justifyContent: menuJustifyContent }}>
                    <Space>{menu}</Space>
                </Col>
            )}
        </Row>
    );
};
