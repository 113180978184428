import { useState } from "react";

interface UseDealTermsSection {
    syncDsp: boolean;
    handleChangeSyncDsp: (hasSyncDsp: boolean) => void;
}
export const useDealTermsSection = (): UseDealTermsSection => {
    const [syncDsp, setSyncDps] = useState<boolean>(false);
    const handleChangeSyncDsp = (hasSyncDsp: boolean) => {
        setSyncDps(hasSyncDsp);
    };

    return {
        syncDsp,
        handleChangeSyncDsp,
    };
};
