import { Form } from "antd";
import { FC } from "react";
import { Seat } from "@app/core/services";
import { NONE } from "@app/core/components/constants";
import { SeatBuyerSeatLists } from "@app/features/inventory/components/Fields";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

interface BlockBuyerSeaListProps {
    seat: Seat;
    edit?: boolean;
}

export const BlockBuyerSeaList: FC<BlockBuyerSeaListProps> = ({ seat, edit = false, ...rest }) => {
    const { buyerSeatList } = seat;

    return (
        <Form.Item
            label={INVENTORY_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.label}
            name={INVENTORY_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.name}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 }}
            {...rest}
        >
            {edit ? (
                <SeatBuyerSeatLists placeholder={INVENTORY_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.placeholder} />
            ) : (
                buyerSeatList?.name || NONE
            )}
        </Form.Item>
    );
};
