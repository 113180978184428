import { FC } from "react";
import { MessageOutlined } from "@ant-design/icons";
import { Drawer, Button, Form, Input, Radio, Space } from "antd";
import { useFeedbackDrawer } from "./useFeedbackDrawer";

const FEEDBACK_CATEGORIES = [
    { label: "Feature Request", value: "feature" },
    { label: "Bug Report", value: "bug" },
    { label: "General Feedback", value: "general" },
];

const FEEDBACK_FORM_FIELDS = {
    name: { label: "Full Name", name: "name", rules: [{ required: true, message: "Please enter your full name" }] },
    userEmail: { label: "Email", name: "userEmail", rules: [{ required: true, message: "Please enter your email" }] },
    companyName: {
        label: "Company Name",
        name: "companyName",
        rules: [{ required: true, message: "Please enter your company name" }],
    },
    feedbackCategory: { label: "Feedback Category", name: "feedbackCategory" },
    body: {
        label: "Tell us in your own words what we can improve.",
        name: "body",
        rules: [{ required: true, message: "Please tell us what we can improve" }],
    },
};

interface Props {
    name: string;
}

export const FeedbackDrawer: FC<Props> = ({ name }) => {
    const { form, isLoading, onClose, onOpen, onSubmit, open, session } = useFeedbackDrawer();

    return (
        <>
            <Button icon={<MessageOutlined />} type="link" onClick={onOpen} style={{ color: "#FFF" }}>
                Feedback on {name} UI
            </Button>
            <Drawer
                onClose={onClose}
                open={open}
                placement="right"
                title="Feedback Form"
                width={"33%"}
                footer={
                    <Space>
                        <Button type="primary" onClick={onSubmit} loading={isLoading}>
                            Submit
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </Space>
                }
            >
                {session && (
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={{
                            name: `${session.user.firstName || ""} ${session.user.lastName || ""}`.trim(),
                            userEmail: session.user.emailAddress || "",
                            companyName: session.user.company || "",
                            feedbackCategory: "feature",
                            body: "",
                        }}
                    >
                        <Form.Item {...FEEDBACK_FORM_FIELDS.name}>
                            <Input />
                        </Form.Item>
                        <Form.Item {...FEEDBACK_FORM_FIELDS.userEmail}>
                            <Input />
                        </Form.Item>
                        <Form.Item {...FEEDBACK_FORM_FIELDS.companyName}>
                            <Input />
                        </Form.Item>
                        <Form.Item {...FEEDBACK_FORM_FIELDS.feedbackCategory}>
                            <Radio.Group options={FEEDBACK_CATEGORIES} />
                        </Form.Item>
                        <Form.Item {...FEEDBACK_FORM_FIELDS.body}>
                            <Input.TextArea rows={6} />
                        </Form.Item>
                    </Form>
                )}
            </Drawer>
        </>
    );
};
