import { Card, Form, Input, Layout, Select } from "antd";
import { FC } from "react";
import {
    COMMON_ERROR_MESSAGE,
    COUNTRY_CODE_OPTIONS,
    EMAIL_ERROR_MESSAGE,
    EMAIL_REGEXP,
    NAME_LEN_ERROR_MESSAGE,
    NOT_ONLY_NUMBERS_REGEXP,
    PHONE_ERROR_MESSAGE,
    PHONE_REGEXP,
    REQUIRED_ERROR_MESSAGE,
    SEAT_CONTACT_ADDRESS1_INPUT_SDET,
    SEAT_CONTACT_ADDRESS2_INPUT_SDET,
    SEAT_CONTACT_CITY_INPUT_SDET,
    SEAT_CONTACT_COUNTRY_SDET,
    SEAT_CONTACT_EMAIL_INPUT_SDET,
    SEAT_CONTACT_FIRSTNAME_INPUT_SDET,
    SEAT_CONTACT_LASTNAME_INPUT_SDET,
    SEAT_CONTACT_NAME_FIELD_MAX_LENGTH,
    SEAT_CONTACT_PHONE_INPUT_SDET,
    SEAT_CONTACT_POSTAL_CODE_INPUT_SDET,
    SEAT_CONTACT_STATE_INPUT_SDET,
} from "./constants";
import { useHistory, useParams } from "react-router-dom";
import { FixedSaveButtons } from "@app/core/components";
import { useGetSeatContactTypesQuery } from "@app/core/services";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { SeatContactPayload } from "../types";
import { useNotificationError } from "@app/core/components/hooks";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { SeatContactsDeleteModal } from "../SeatContactsDeleteModal/SeatContactsDeleteModal";

interface Props {
    handleSubmit: (values: SeatContactPayload) => void;
    handleOpen?: (values: SeatContactPayload) => void;
    isLoading: boolean;
    initialValues?: SeatContactPayload;
}

export const SeatContactsForm: FC<Props> = ({ isLoading, initialValues, handleSubmit }) => {
    const [form] = Form.useForm();
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();
    const { data, isFetching, error } = useGetSeatContactTypesQuery();
    useNotificationError(error);
    return (
        <Form
            form={form}
            name="seatContactsForm"
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
            wrapperCol={{ xs: 24, md: 12 }}
            scrollToFirstError={{ behavior: "smooth", block: "center" }}
        >
            <Layout.Content>
                <Card bordered={false}>
                    <Form.Item
                        label="First Name"
                        name="firstName"
                        required
                        rules={[
                            {
                                whitespace: true,
                                required: true,
                                message: REQUIRED_ERROR_MESSAGE("First Name"),
                            },
                            {
                                max: SEAT_CONTACT_NAME_FIELD_MAX_LENGTH,
                                message: NAME_LEN_ERROR_MESSAGE(SEAT_CONTACT_NAME_FIELD_MAX_LENGTH),
                            },
                        ]}
                    >
                        <Input data-sdet={SEAT_CONTACT_FIRSTNAME_INPUT_SDET} />
                    </Form.Item>
                    <Form.Item
                        label="Last Name"
                        name="lastName"
                        required
                        rules={[
                            {
                                whitespace: true,
                                required: true,
                                message: REQUIRED_ERROR_MESSAGE("Last Name"),
                            },
                            {
                                max: SEAT_CONTACT_NAME_FIELD_MAX_LENGTH,
                                message: NAME_LEN_ERROR_MESSAGE(SEAT_CONTACT_NAME_FIELD_MAX_LENGTH),
                            },
                        ]}
                    >
                        <Input data-sdet={SEAT_CONTACT_LASTNAME_INPUT_SDET} />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="emailAddress"
                        required
                        rules={[
                            {
                                whitespace: true,
                                required: true,
                                message: REQUIRED_ERROR_MESSAGE("Email"),
                            },
                            {
                                pattern: EMAIL_REGEXP,
                                message: EMAIL_ERROR_MESSAGE,
                            },
                        ]}
                    >
                        <Input data-sdet={SEAT_CONTACT_EMAIL_INPUT_SDET} />
                    </Form.Item>
                    <Form.Item label="Contact Type" name="type">
                        <SelectWithValue
                            allowClear
                            fieldAsLabel={["name"]}
                            fieldAsValue={["id"]}
                            loading={isFetching}
                            optionFilterProp="label"
                            options={data}
                            showSearch
                        />
                    </Form.Item>
                    <Form.Item
                        label="Phone Number"
                        name="phone"
                        rules={[
                            {
                                pattern: PHONE_REGEXP,
                                message: PHONE_ERROR_MESSAGE,
                            },
                        ]}
                    >
                        <Input data-sdet={SEAT_CONTACT_PHONE_INPUT_SDET} />
                    </Form.Item>
                    <Form.Item hidden name="id" initialValue={initialValues?.id ?? null} />
                    <Form.Item hidden name="seat" initialValue={{ id: Number(seatId) }} />
                    <Form.Item label="Address 1" name="address1">
                        <Input data-sdet={SEAT_CONTACT_ADDRESS1_INPUT_SDET} />
                    </Form.Item>
                    <Form.Item label="Address 2" name="address2">
                        <Input data-sdet={SEAT_CONTACT_ADDRESS2_INPUT_SDET} />
                    </Form.Item>
                    <Form.Item
                        label="City"
                        name="city"
                        rules={[
                            {
                                pattern: NOT_ONLY_NUMBERS_REGEXP,
                                message: COMMON_ERROR_MESSAGE,
                            },
                        ]}
                    >
                        <Input data-sdet={SEAT_CONTACT_CITY_INPUT_SDET} />
                    </Form.Item>
                    <Form.Item
                        label="State"
                        name="state"
                        rules={[
                            {
                                pattern: NOT_ONLY_NUMBERS_REGEXP,
                                message: COMMON_ERROR_MESSAGE,
                            },
                        ]}
                    >
                        <Input data-sdet={SEAT_CONTACT_STATE_INPUT_SDET} />
                    </Form.Item>
                    <Form.Item label="Postal Code" name="postalCode">
                        <Input data-sdet={SEAT_CONTACT_POSTAL_CODE_INPUT_SDET} />
                    </Form.Item>
                    <Form.Item
                        label="Country"
                        name="countryCode"
                        rules={[
                            {
                                pattern: NOT_ONLY_NUMBERS_REGEXP,
                                message: COMMON_ERROR_MESSAGE,
                            },
                        ]}
                    >
                        <Select
                            data-sdet={SEAT_CONTACT_COUNTRY_SDET}
                            showArrow
                            showSearch
                            options={COUNTRY_CODE_OPTIONS}
                            optionFilterProp="label"
                        />
                    </Form.Item>
                </Card>
            </Layout.Content>
            <FixedSaveButtons
                isLoading={isLoading}
                onCancel={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, "seat-contacts"))}
                submitLabel={initialValues?.id ? "Save" : "Submit"}
                suffixAddOn={initialValues?.id && <SeatContactsDeleteModal contact={initialValues} />}
            />
        </Form>
    );
};
