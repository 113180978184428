import { Modal, Typography } from "antd";
import { FC } from "react";

interface Props {
    handleCancel: () => void;
    handleOk: () => void;
    open: boolean;
    text?: string;
}

export const DiscardChangesModal: FC<Props> = ({
    handleCancel,
    handleOk,
    open,
    text = "Are you sure you want to proceed without saving the changes?",
}) => {
    return (
        <Modal cancelText="No" open={open} onOk={handleOk} onCancel={handleCancel} okText="Yes" title="Warning">
            <Typography.Text>{text}</Typography.Text>
        </Modal>
    );
};

export default DiscardChangesModal;
