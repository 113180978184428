import { getKey, isAdUnit, isBrandOrBrandChannel, isPublisherOrPublisherChannel, isSupply } from "./utils";
import { AdUnit, Brand, BrandSupply, SeatPublisher, Seat, Publisher } from "@app/core/services/console";
import { TreeData } from "./types";
import { notification } from "antd";

export const loadInitialPublishersBrands = (
    seat: Seat,
    publishers: SeatPublisher[],
    getPublisherBrands: (args: { publisherId: number }) => { unwrap: () => Promise<Brand[]> },
    setData: (treeData: TreeData) => void,
    setIsLoadingInitialData: (isLoading: boolean) => void,
    setInitialDataCount: (cb: (initialDataCount: Set<string>) => Set<string>) => void
) => {
    setInitialDataCount((initialDataCount) => {
        const nextSet = new Set([...initialDataCount, ...publishers.map((publisher) => getKey(publisher))]);
        return nextSet;
    });
    setIsLoadingInitialData(true);
    Promise.allSettled(
        publishers.map((publisher) => {
            return getPublisherBrands({ publisherId: publisher.id }).unwrap();
        })
    )
        .then((promiseSettled) => {
            const brandsList = promiseSettled.map((promise) => promise.status === "fulfilled" && promise.value);
            const atLeastOneCallFailed = promiseSettled.some((promise) => promise.status === "rejected");

            if (atLeastOneCallFailed) {
                const errorIndex = promiseSettled.findIndex((promise) => promise.status === "rejected");

                notification.error({
                    message: "Error Loading Brands",
                    description: `Failed to load brands for Publisher ${publishers[errorIndex].name}`,
                });
            }

            brandsList.forEach((brands) => {
                if (!brands) {
                    return;
                }
                setInitialDataCount((initialDataCount) => {
                    const nextSet = new Set([...initialDataCount, ...brands.map((brand) => getKey(brand))]);
                    return nextSet;
                });
            });

            return publishers.map((publisher, pIndex) => {
                const publisherBrands = brandsList[pIndex];

                if (!publisherBrands || !publisherBrands.length || publisher.entityType === "Channel") {
                    return publisher;
                }

                const publisherBrandsWithEmptyChildren = publisherBrands.map((brand) => ({
                    ...brand,
                    children: [],
                }));

                return {
                    ...publisher,
                    children: publisherBrandsWithEmptyChildren,
                };
            });
        })
        .then((data) => {
            setData([
                {
                    ...seat,
                    entityType: "Seat",
                    children: data,
                },
            ]);
            setIsLoadingInitialData(false);
        });
};

export const updatePublisherChildren = (brands: Brand[], publisher: SeatPublisher, setData) => {
    setData((seats) => {
        return seats.map((seat) => {
            return {
                ...seat,
                children: seat.children.map((curPublisher) => {
                    if (curPublisher.id !== publisher.id) {
                        return curPublisher;
                    }

                    const brandsWithChildren = brands.map((brand) => ({
                        ...brand,
                        children: [],
                    }));

                    return {
                        ...curPublisher,
                        children: brandsWithChildren,
                    };
                }),
            };
        });
    });
};

export const updateBrandChildren = (brandSupplyList: BrandSupply[], brand: Brand, setData) => {
    setData((seats) => {
        return seats.map((seat) => {
            return {
                ...seat,
                children: seat.children.map((publisher) => {
                    if (publisher.id !== brand.publisher.id) {
                        return publisher;
                    }
                    return {
                        ...publisher,
                        children: publisher.children.map((curBrand) => {
                            if (curBrand.id !== brand.id) {
                                return curBrand;
                            }

                            if (!brandSupplyList.length) {
                                const { children, ...brandWithoutChildren } = curBrand;

                                return brandWithoutChildren;
                            }

                            const brandSupplyListWithChildren = brandSupplyList.map((brandSupply) => ({
                                ...brandSupply,
                                children: [],
                            }));

                            return {
                                ...curBrand,
                                children: brandSupplyListWithChildren,
                            };
                        }),
                    };
                }),
            };
        });
    });
};

export const updateSupplyChildren = (adUnitsList: AdUnit[], supply: BrandSupply, setData) => {
    setData((data) => {
        return data.map((seat) => {
            return {
                ...seat,
                children: seat.children.map((publisher) => {
                    if (publisher.id !== supply.brand.publisher.id) {
                        return publisher;
                    }
                    return {
                        ...publisher,
                        children: publisher.children.map((brand) => {
                            if (brand.id !== supply.brand.id) {
                                return brand;
                            }
                            return {
                                ...brand,
                                children: brand.children.map((curSupply) => {
                                    if (curSupply.id !== supply.id) {
                                        return curSupply;
                                    }

                                    if (!adUnitsList.length) {
                                        const { children, ...supplyWithoutChildren } = curSupply;

                                        return supplyWithoutChildren;
                                    }

                                    return {
                                        ...curSupply,
                                        children: adUnitsList,
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        });
    });
};

export const updateEntityChildren = (
    entity,
    getChildren,
    childrenArgs,
    updateChildren,
    loadedEntities,
    setLoadedEntities,
    loadingEntities,
    setLoadingEntities,
    setAdStatsEntityIds,
    setData
) => {
    if (loadingEntities.has(getKey(entity)) || loadedEntities.has(getKey(entity))) {
        return;
    }
    setLoadingEntities((loadingEntities) => new Set(loadingEntities.add(getKey(entity))));
    getChildren(childrenArgs)
        .unwrap()
        .then((children) => {
            updateChildren(children, entity, setData);
            setLoadingEntities((loadingEntities) => {
                const newLoadingEntities = new Set(loadingEntities);
                newLoadingEntities.delete(getKey(entity));
                return newLoadingEntities;
            });
            setLoadedEntities((loadedEntities) => new Set(loadedEntities.add(getKey(entity))));
            setAdStatsEntityIds(children.map((child) => child.id));
        });
};

const getPath = (
    entity: Seat | SeatPublisher | Brand | BrandSupply | AdUnit | null
): (SeatPublisher | Publisher | Brand | BrandSupply)[] => {
    if (!entity) {
        return [];
    }
    if (isPublisherOrPublisherChannel(entity)) {
        return [entity];
    }
    if (isBrandOrBrandChannel(entity)) {
        return [entity.publisher, entity];
    }
    if (isSupply(entity)) {
        return [entity.brand.publisher, entity.brand, entity];
    }
    if (isAdUnit(entity)) {
        return [entity.supply.brand.publisher, entity.supply.brand, entity.supply];
    }
    return [];
};

export const updateEntityChildrenByPath = (
    entity,
    typePromises,
    adStatsEntityIdSetters,
    updateChildrenSetters,
    setLoadingEntities,
    setLoadedEntities,
    setIsLoadingSearch,
    setData
) => {
    const path = getPath(entity);

    setIsLoadingSearch(true);
    setLoadingEntities((loadingEntities) => {
        const newLoadingEntities = new Set([...loadingEntities, ...path.map(getKey)]);
        return newLoadingEntities;
    });

    return path
        .reduce((acc, entity) => {
            return acc.then((results) => {
                const typePromise = typePromises[entity.entityType];
                return typePromise(entity.id).then((children) => {
                    results.push(children);
                    return results;
                });
            });
        }, Promise.resolve<(Brand[] | BrandSupply[] | AdUnit[])[]>([]))
        .then((childrenList: (Brand[] | BrandSupply[] | AdUnit[])[]) => {
            path.forEach((entity, index) => {
                const children = childrenList[index];
                const updateChildren = updateChildrenSetters[entity.entityType];
                updateChildren(children, entity, setData);
                setLoadingEntities((loadingEntities) => {
                    const newLoadingEntities = new Set(loadingEntities);
                    newLoadingEntities.delete(getKey(entity));
                    return newLoadingEntities;
                });
                setLoadedEntities((loadedEntities) => loadedEntities.add(getKey(entity)));
                const adStatsEnitityIdSetter = adStatsEntityIdSetters[entity.entityType];
                adStatsEnitityIdSetter(children.map((child) => child.id));
            });
            setIsLoadingSearch(false);
        });
};
