import { useUpdateSeatInContext, useUserAccess } from "@app/core/auth";
import { LabeledValue } from "antd/lib/select";
import { Store } from "rc-field-form/lib/interface";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Form, FormInstance, notification } from "antd";
import { useParams, useHistory } from "react-router-dom";
import {
    Seat,
    Label,
    SupplySeat,
    UpdateSeatPayload,
    useGetSeatSelfQuery,
    useGetSupplySeatQuery,
} from "@app/core/services/console";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import {
    getSeatDetailsFormInitialValues,
    parseSeatDetailsFormValuesToApi,
} from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/seatDetailsFormUtils";

export interface LabelValueOption {
    value: number;
    label: string;
    seatLabel: Label;
    labelValue: string;
}

export interface SeatDetailsForm {
    // General Section
    [INVENTORY_FORM_FIELDS.NAME.name]: string;
    [INVENTORY_FORM_FIELDS.CODE.name]: string;
    [INVENTORY_FORM_FIELDS.DESCRIPTION.name]: string;
    [INVENTORY_FORM_FIELDS.BUSINESS_DOMAIN.name]: string;
    [INVENTORY_FORM_FIELDS.ACCOUNT_TYPE.name]: LabeledValue;
    [INVENTORY_FORM_FIELDS.CLIENT_TYPE.name]: LabeledValue;
    [INVENTORY_FORM_FIELDS.REGION.name]: LabeledValue;
    [INVENTORY_FORM_FIELDS.SUPPORT_TIER.name]: LabeledValue | null;
    [INVENTORY_FORM_FIELDS.IS_MVPD.name]: boolean;
    [INVENTORY_FORM_FIELDS.IS_FILL_GUARANTEED.name]: boolean;
    // Financial Section
    [INVENTORY_FORM_FIELDS.FLOOR_CURRENCY.name]: LabeledValue;
    [INVENTORY_FORM_FIELDS.FALLBACK_FLOOR.name]: number;
    [INVENTORY_FORM_FIELDS.DEFAULT_BILLING_CODE.name]: string;
    [INVENTORY_FORM_FIELDS.AFFILIATE_COST_ENABLED.name]: boolean;
    [INVENTORY_FORM_FIELDS.AFFILIATE_COST_MODEL.name]: LabeledValue;
    [INVENTORY_FORM_FIELDS.AFFILIATE_COST_VALUE_PERCENT.name]: number;
    [INVENTORY_FORM_FIELDS.AFFILIATE_COST_VALUE_FIXED.name]: number;
    // Seat Defaults
    [INVENTORY_FORM_FIELDS.TIME_ZONE.name]: LabeledValue | null;
    [INVENTORY_FORM_FIELDS.AD_SOURCE_MIN_DURATION_DEFAULT.name]: number;
    [INVENTORY_FORM_FIELDS.AD_SOURCE_MAX_DURATION_DEFAULT.name]: number;
    [INVENTORY_FORM_FIELDS.AD_SOURCE_AUCTION_TYPE_DEFAULT.name]: LabeledValue | null;
    [INVENTORY_FORM_FIELDS.DEFAULT_EXTENDED_IMPRESSION_WAIT_TIME.name]: LabeledValue | null;
    // Brand Safety
    [INVENTORY_FORM_FIELDS.BLOCK_ADVERTISER_DOMAIN_LISTS.name]: LabeledValue[];
    [INVENTORY_FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: string;
    [INVENTORY_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.name]: LabeledValue | null;
    // Custom Pixels
    [INVENTORY_FORM_FIELDS.CUSTOM_PIXELS.name]: LabeledValue[];
    // Labels
    [INVENTORY_FORM_FIELDS.LABELS.name]: LabelValueOption[];
    [INVENTORY_FORM_FIELDS.INTERNAL_LABELS.name]: LabelValueOption[];
}

interface UseSeatDetailsForm {
    isLoading: boolean;
    isUpdating: boolean;
    seat: Seat | null | undefined;
    handleCancel: () => void;
    initialValues: Store | undefined;
    form: FormInstance<SeatDetailsForm>;
    supplySeat: SupplySeat | null | undefined;
    handleSubmit: () => void;
}

export const useSeatDetailsForm = (): UseSeatDetailsForm => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const { isSysAdmin, isPubAcctMgr } = useUserAccess();
    const [form] = Form.useForm<SeatDetailsForm>();
    const { data, isFetching: isLoading } = useGetSeatSelfQuery(seatId);
    const initialValues: Store | undefined = getSeatDetailsFormInitialValues(data?.seat);

    const { updateSeatSelf, isLoading: isUpdating } = useUpdateSeatInContext();
    const { data: supplySeat, isFetching } = useGetSupplySeatQuery(Number(seatId));

    const isInternalOnly: boolean = isSysAdmin || isPubAcctMgr;
    const handleCancel = (): void => history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_SEAT_DETAILS(seatId));

    const handleSubmit = async (): Promise<void> => {
        try {
            await form.validateFields();
            if (!data?.seat) return;

            const values = form.getFieldsValue();
            const body: UpdateSeatPayload = parseSeatDetailsFormValuesToApi(data.seat, values, isInternalOnly);

            try {
                await updateSeatSelf(body);
                notification.success({ message: "Seat updated successfully" });
                handleCancel();
            } catch (err) {
                notification.error({ message: err.data.errorDescription });
            }
        } catch (error) {
            form.scrollToField(error.errorFields[0].name, { behavior: "smooth", block: "center" });
        }
    };

    return {
        form,
        supplySeat,
        isUpdating,
        handleSubmit,
        handleCancel,
        initialValues,
        seat: data?.seat,
        isLoading: isLoading || isFetching,
    };
};
