import { FC } from "react";
import { AdStat, getTries } from "@app/features/adStats";
import { useAppSelector } from "@app/core/store";
import { selectAdStatDealById } from "@app/features/adStats/reducer";
import { useThrottle } from "@app/core/utils";
import { LoadableAnimatedNumberCell } from "@app/core/components";
import { useDealCurrencyConversion } from "@app/features/deals/useDealCurrencyConversion";

interface DealHealthTableTriesCellProps {
    dealId: number;
    sourceCurrencyCode: string | undefined;
}

const DealHealthTableTriesCell: FC<DealHealthTableTriesCellProps> = ({ dealId, sourceCurrencyCode }) => {
    const isConnectedToLiveAdStats = useAppSelector((state) => state.adStats.isConnected);
    const { targetCurrencyCode } = useDealCurrencyConversion(sourceCurrencyCode);
    const { tries, otime } = useAppSelector((state) => {
        const adStat: AdStat | null = selectAdStatDealById(state, dealId);
        return adStat ? { tries: getTries(adStat), otime: adStat.otime } : { tries: undefined, otime: undefined };
    });
    const throttledTries = useThrottle<number | undefined>(tries, {
        forceUpdateDependencies: [otime, sourceCurrencyCode, targetCurrencyCode],
    });

    return <LoadableAnimatedNumberCell isLoading={!isConnectedToLiveAdStats} value={throttledTries} />;
};

export default DealHealthTableTriesCell;
