import { FC } from "react";
import { Form, Select } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { TagRender } from "@app/core/components";
import { useAdSourceReusableAdvertiserDomainField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceBrandSafetySection/Fields/AdSourceReusableAdvertiserDomainField/useAdSourceReusableAdvertiserDomainField";
import { getReusableAdvertiserDomainsValidator } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/BrandSafety/utils";

export const AdSourceReusableAdvertiserDomainField: FC = () => {
    const { value, onChange, options, isFetching } = useAdSourceReusableAdvertiserDomainField();
    return (
        <Form.Item
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_REUSABLE_ADVERTISER_DOMAIN}
            data-sdet="ad-source-reusable-advertiser-domain-field"
            label="Reusable Advertiser Domain"
            dependencies={[
                CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE,
                CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ADDITIONAL_ADVERTISER_DOMAIN,
            ]}
            rules={[
                getReusableAdvertiserDomainsValidator(
                    CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE,
                    CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ADDITIONAL_ADVERTISER_DOMAIN
                ),
            ]}
        >
            <Select
                showSearch
                mode="multiple"
                options={options}
                loading={isFetching}
                optionFilterProp="label"
                placeholder="Select Reusable Advertiser Domain"
                onChange={onChange}
                value={value}
                allowClear
                tagRender={TagRender}
            />
        </Form.Item>
    );
};
