import { FC } from "react";
import { Form, Input } from "antd";
import { NA } from "@app/core/components/constants";
import { PUBLISHER_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { usePublisherFormInstance } from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormInstance";

export const BillingCode: FC = () => {
    const { seat } = usePublisherFormInstance();
    const extra = `Inherited value: ${seat?.defaultBillingCode || NA}`;
    return (
        <Form.Item
            extra={extra}
            name={PUBLISHER_FORM_FIELDS.BILLING_CODE.name}
            label={PUBLISHER_FORM_FIELDS.BILLING_CODE.label}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 24, xxl: 20 }}
            normalize={(value) => value?.trim()}
        >
            <Input placeholder={PUBLISHER_FORM_FIELDS.BILLING_CODE.placeholder} allowClear />
        </Form.Item>
    );
};
