import { Form, Input } from "antd";
import { CREATE_FLOOR_FORM_ITEMS_NAME } from "@app/features/inventory/constants";

const MAX_NAME_LENGTH = 70;

export const FloorNameField = () => {
    return (
        <Form.Item
            rules={[
                { required: true, message: "Name is required", whitespace: true },
                { max: MAX_NAME_LENGTH, message: `Name can't be greater than ${MAX_NAME_LENGTH} characters` },
            ]}
            label="Name"
            name={CREATE_FLOOR_FORM_ITEMS_NAME.NAME}
        >
            <Input />
        </Form.Item>
    );
};
