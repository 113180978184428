import { FC } from "react";
import { Seat } from "@app/core/services";
import { Form, Select, InputNumber, Space } from "antd";
import { getUdeCpmFee } from "@app/features/inventory/helpers";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { useUDFee } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/AdvancedFeaturesForm/AdvancedFeaturesFormFields/UDFee/useUDFee";

interface ContentMetadataKeysProps {
    seat: Seat;
    edit?: boolean;
}

export const UDFee: FC<ContentMetadataKeysProps> = ({ edit = false, seat }) => {
    const { isFetching, options, isUdEnabled } = useUDFee();
    if (edit && !isUdEnabled) return null;
    return (
        <Form.Item label={INVENTORY_FORM_FIELDS.UD_FEE.label}>
            {edit ? (
                <Space.Compact>
                    <Form.Item noStyle name={INVENTORY_FORM_FIELDS.UD_FEE.name}>
                        <InputNumber
                            disabled={!isUdEnabled}
                            style={{ width: "70%" }}
                            placeholder={INVENTORY_FORM_FIELDS.UD_FEE.placeholder}
                        />
                    </Form.Item>
                    <Form.Item noStyle name={INVENTORY_FORM_FIELDS.UDE_CPM_FEE_CURRENCY.name}>
                        <Select
                            showSearch
                            labelInValue
                            options={options}
                            loading={isFetching}
                            disabled={!isUdEnabled}
                            style={{ width: "30%" }}
                            optionFilterProp="label"
                            autoClearSearchValue={false}
                        />
                    </Form.Item>
                </Space.Compact>
            ) : (
                getUdeCpmFee(seat.udeCpmFee, seat.udeCpmFeeCurrency)
            )}
        </Form.Item>
    );
};
