import { FC } from "react";
import { AdUnitSSAIType } from "@app/features/inventory/components/FormItems";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const SSAIType: FC = () => (
    <AdUnitSSAIType
        name={CHANNEL_FORM_FIELDS.SSAI_TYPE.name}
        label={CHANNEL_FORM_FIELDS.SSAI_TYPE.label}
        placeholder={CHANNEL_FORM_FIELDS.SSAI_TYPE.placeholder}
    />
);
