import { FC } from "react";
import { Form, Radio } from "antd";
import { format } from "@rubicon/utils";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { useShareOfVoiceRadio } from "./useShareOfVoiceRadio";

const SHARE_OF_VOICE_OPTIONS = [
    { label: format.constants.YES, value: true },
    { label: format.constants.NO, value: false },
];

export const ShareOfVoiceRadio: FC = () => {
    const { isVisible, onChange } = useShareOfVoiceRadio();

    if (!isVisible) {
        return null;
    }

    return (
        <Form.Item
            name={AD_SOURCE_FIELDS.SHARE_OF_VOICE.name}
            label={AD_SOURCE_FIELDS.SHARE_OF_VOICE.label}
            data-sdet={`test-${AD_SOURCE_FIELDS.SHARE_OF_VOICE.name}-field`}
            initialValue={false}
            {...VERTICAL_LAYOUT_FIX}
        >
            <Radio.Group onChange={onChange} options={SHARE_OF_VOICE_OPTIONS} />
        </Form.Item>
    );
};
