import { css } from "@emotion/css";
import { Button, Col, Flex, Form, Input, Radio, Row, Select, Space, Typography } from "antd";
import { DetailsDrawerLayout } from "../../../DetailsDrawerLayout";
import { LADLE_FORM_FIELDS, useLadleContent } from "./useLadleContent";

const CREATIVE_OPTIPONS = [
    {
        label: "All",
        value: null,
    },
    {
        label: "Filled",
        value: true,
    },
    {
        label: "Unfilled",
        value: false,
    },
];

const formItemOffset = css({ marginBottom: "1rem" });
const classListFooter = css`
    border-top: 1px solid #d9d9d9;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    height: 52px;
    line-height: 52px;
`;
const classListFooterCol = css`
    position: fixed;
    right: 16px;
`;

export const LadleContent = () => {
    const {
        form,
        initialValues,
        breadcrumbs,
        isSubmitting,
        handleSubmit,
        handleCancel,
        ordersOptions,
        hasAdminAccess,
        networksOptions,
        handleDefinition,
        lineItemsOptions,
        isFetchingOrders,
        awsRegionsOptions,
        isFetchingNetworks,
        isFetchingLineItems,
        isFetchingAwsRegions,
        handleSaveAndActivate,
    } = useLadleContent();

    return (
        <Flex vertical data-sdet="inventory-creative-review-content">
            <DetailsDrawerLayout
                title="Ladle"
                breadcrumbs={breadcrumbs}
                definitionTitle="Ladle"
                handleDefintion={handleDefinition}
            />
            <Col
                className={css`
                    padding: 0;
                    width: 100%;
                `}
            >
                <Form layout="vertical" form={form} initialValues={initialValues}>
                    <Row
                        gutter={16}
                        className={css`
                            padding: 1.5rem 1.5rem 2rem;
                        `}
                    >
                        <Col>
                            <Typography.Paragraph>
                                Ladle is a troubleshooting tool that allows you to debug bid response in JSON for
                                tag-based and open RTB traffic. Please fill out the necessary information below to
                                generate a ladle. Once the ladle is generated you can find the results in the
                                diagnostics under &quot;Ladle Results&quot;.
                            </Typography.Paragraph>
                        </Col>
                        <Col span={22}>
                            <Form.Item className={formItemOffset} name={LADLE_FORM_FIELDS.Region} label="Region">
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp="label"
                                    options={awsRegionsOptions}
                                    loading={isFetchingAwsRegions}
                                    placeholder="Select Region"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={22}>
                            <Form.Item
                                className={formItemOffset}
                                name={LADLE_FORM_FIELDS.LadleName}
                                label="Ladle Name"
                                rules={[{ required: true, whitespace: true, message: "Ladle Name is required" }]}
                            >
                                <Input placeholder="Enter Name" />
                            </Form.Item>
                        </Col>
                        <Col span={22}>
                            <Form.Item className={formItemOffset} name={LADLE_FORM_FIELDS.DealCode} label="Deal ID">
                                <Input placeholder="Enter Deal ID" />
                            </Form.Item>
                        </Col>
                        <Col span={22}>
                            <Form.Item
                                className={formItemOffset}
                                name={LADLE_FORM_FIELDS.SupplyDomain}
                                label="Supply Domain"
                            >
                                <Input placeholder="Enter Supply Domain" />
                            </Form.Item>
                        </Col>
                        <Col span={22}>
                            <Form.Item className={formItemOffset} name={LADLE_FORM_FIELDS.BundleId} label="Bundle ID">
                                <Input placeholder="Enter Bundle ID" />
                            </Form.Item>
                        </Col>
                        <Col span={22}>
                            <Form.Item className={formItemOffset} name={LADLE_FORM_FIELDS.LineItemId} label="Line Item">
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp="label"
                                    options={lineItemsOptions}
                                    loading={isFetchingLineItems}
                                    placeholder="Select..."
                                />
                            </Form.Item>
                        </Col>
                        <Col span={22}>
                            <Form.Item className={formItemOffset} name={LADLE_FORM_FIELDS.OrderId} label="Order">
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp="label"
                                    options={ordersOptions}
                                    loading={isFetchingOrders}
                                    placeholder="Select..."
                                />
                            </Form.Item>
                        </Col>
                        <Col span={22}>
                            <Form.Item
                                className={formItemOffset}
                                name={LADLE_FORM_FIELDS.AdvertiserDomain}
                                label="Advertiser Domain"
                            >
                                <Input placeholder="Enter Advertiser Domain" />
                            </Form.Item>
                        </Col>
                        <Col span={22}>
                            <Form.Item
                                className={formItemOffset}
                                name={LADLE_FORM_FIELDS.CreativeId}
                                label="Creative ID"
                            >
                                <Input placeholder="Enter Creative ID" />
                            </Form.Item>
                        </Col>
                        <Col span={22}>
                            <Form.Item className={formItemOffset} name={LADLE_FORM_FIELDS.Demand} label="Demand">
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp="label"
                                    options={networksOptions}
                                    loading={isFetchingNetworks}
                                    placeholder="Select..."
                                />
                            </Form.Item>
                        </Col>
                        <Col span={22}>
                            <Form.Item className={formItemOffset} label="Creative" name={LADLE_FORM_FIELDS.Creative}>
                                <Radio.Group options={CREATIVE_OPTIPONS} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className={classListFooter}>
                        <Col className={classListFooterCol}>
                            <Space>
                                <Button disabled={isSubmitting} onClick={handleCancel}>
                                    Cancel
                                </Button>
                                {hasAdminAccess && (
                                    <Button onClick={handleSaveAndActivate}>Admin Only: Save and Activate</Button>
                                )}
                                <Button type="primary" loading={isSubmitting} onClick={() => handleSubmit()}>
                                    Activate
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Flex>
    );
};
