import { Card } from "antd";
import { FC } from "react";
import { INVENTORY_CARD_BODY_STYLE } from "@app/features/inventory/constants";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { AdUnitLabels } from "@app/features/inventory/HierarchyForms/AdUnitForm/LabelsSection/Fields";

export const LabelsInternalOnly: FC = () => {
    return (
        <Card title="Internal Only" type="inner" bodyStyle={INVENTORY_CARD_BODY_STYLE}>
            <AdUnitLabels {...AD_UNIT_FORM_FIELDS.INTERNAL_LABELS} />
        </Card>
    );
};
