import { PieConfig, Pie, Plot } from "@ant-design/plots";
import { useMemo, useState } from "react";
import { Card, Col, Empty, Row } from "antd";
import { FC } from "react";
import { VastStatsPieProps } from "../useVastStats";
import { ShowMoreLegend } from "./ShowMoreLegend";
import { LegendData, PinnedLegend, PinnedType } from "./types";
import { PieOptions } from "@antv/g2plot";
import { css } from "@emotion/css";
import { LABEL_COLORS } from "@app/core/components/charts/constants";

const MAX_CHART_COLORS = 20;
const COLORS = Object.values(LABEL_COLORS).filter((_, index) => index < MAX_CHART_COLORS);

const getSortedData = (pinnedLegend: PinnedLegend, data: LegendData[]) => {
    switch (pinnedLegend.type) {
        case PinnedType.Default:
            return data;
        case PinnedType.Select:
            return data.filter((d) => pinnedLegend?.value?.name === d.name && pinnedLegend?.value?.value === d.value);

        default:
            return data;
    }
};

interface Props {
    title: string;
    data: VastStatsPieProps[];
}
export const CardPieChart: FC<Props> = ({ title, data }) => {
    const [pinnedLegend, setPinnedLegend] = useState<PinnedLegend>({
        type: PinnedType.Default,
        value: null,
    });

    const color = useMemo<string | string[]>(
        () => (pinnedLegend.type === PinnedType.Select ? (pinnedLegend?.value?.color as string) : COLORS),
        [pinnedLegend]
    );

    const dataWithColor = useMemo(
        () =>
            data.map((item, index) => ({
                ...item,
                color: COLORS[index % COLORS.length],
            })),
        [data]
    );

    const sortedData = useMemo(() => getSortedData(pinnedLegend, dataWithColor), [pinnedLegend, dataWithColor]);

    const config: PieConfig = useMemo(
        () => ({
            theme: { colors10: color, colors20: color },
            statistic: {
                title: false,
                content: {
                    content: "",
                },
            },
            data: sortedData,
            angleField: "value",
            colorField: "name",
            legend: false,
            radius: 0.9,
            innerRadius: 0.5,
            label: false,
            state: {
                active: {
                    style: (properties: { model: { color: string } }) => {
                        return {
                            lineWidth: 8,
                            stroke: properties.model.color,
                            fillOpacity: 1,
                            strokeOpacity: 1,
                        };
                    },
                },
            },
            tooltip: {
                formatter: ({ name, value }) => {
                    const percentage = sortedData.find((item) => item.name === name)?.percentage;
                    return {
                        name,
                        value: `${value}, <b>${percentage}</b>`,
                    };
                },
            },
            interactions: [
                {
                    type: "element-active",
                },
            ],
        }),
        [sortedData, color]
    );

    const selectItem = (item: LegendData) => {
        setPinnedLegend(({ value, type }) => {
            if (value?.name === item.name && value.value === item.value && type === PinnedType.Select) {
                return {
                    type: PinnedType.Default,
                    value: null,
                };
            }
            return {
                type: PinnedType.Select,
                value: item,
            };
        });
    };

    const handleReady = (plot: Plot<PieOptions>) => {
        plot.on("plot:click", (evt: { x: number; y: number }) => {
            const { x, y } = evt;
            const [item] = plot.chart.getTooltipItems({ x, y });
            setPinnedLegend({ type: PinnedType.Hover, value: item.data });
        });
    };

    return (
        <Card
            type="inner"
            className={css`
                width: 100%;
                .ant-card-body {
                    padding-left: 1rem;
                }
            `}
            title={title}
        >
            {sortedData?.length ? (
                <Row style={{ width: "100%" }}>
                    <Col span={10}>
                        <ShowMoreLegend
                            legendData={dataWithColor}
                            pinnedLegend={pinnedLegend}
                            selectLegend={selectItem}
                        />
                    </Col>
                    <Col span={14}>
                        <Pie {...config} onReady={handleReady} />
                    </Col>
                </Row>
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </Card>
    );
};
