import { FC } from "react";
import { Form, InputNumber } from "antd";
import { ColProps } from "antd/lib/grid/col";
import { InputNumberProps } from "antd/lib/input-number";
import { MAX_ADS_PER_ADV } from "@app/features/inventory/HierarchyForms/constants";

export interface Props extends InputNumberProps {
    name: typeof MAX_ADS_PER_ADV;
    wrapperCol?: ColProps;
    labelCol?: ColProps;
    label: string;
}

export const ChannelMaxAdsPerAdvertiser: FC<Props> = ({ name, label, wrapperCol, labelCol, ...rest }) => {
    return (
        <Form.Item name={name} label={label} labelCol={labelCol} wrapperCol={wrapperCol}>
            <InputNumber style={{ width: "100%" }} {...rest} />
        </Form.Item>
    );
};
