import { FC } from "react";
import { FilterSelect } from "@app/core/components/FilterSelect";
import { useStatusFilter } from "./useStatusFilter";
import { SelectAllDropdownRender } from "@app/core/components";

export const InternalStatusFilter: FC = () => {
    const { options, value, isLoading, handleChange, areAllOptionsSelected, toggleSelectDeselectAll } =
        useStatusFilter();

    return (
        <FilterSelect
            isLoading={isLoading}
            label="Internal Status"
            onChange={handleChange}
            options={options}
            value={value}
            dataSdet="status-filter"
            mode="multiple"
            placeholder="All"
            dropdownRender={(menu) => (
                <SelectAllDropdownRender
                    menu={menu}
                    onClick={toggleSelectDeselectAll}
                    areAllOptionsSelected={areAllOptionsSelected}
                />
            )}
        />
    );
};
