const MIN_VALID_POSITION = -1;
const MAX_VALID_POSITION = Number.MAX_SAFE_INTEGER - 1;

export const validatePositions = (values: (string | number)[]) => {
    const validNumbers = values
        .map((v) => Number(v))
        .filter((v) => Number.isSafeInteger(v) && v >= MIN_VALID_POSITION && v <= MAX_VALID_POSITION);
    const dedupedValidNumbers = [...new Set(validNumbers)];

    return dedupedValidNumbers;
};
