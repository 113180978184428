import { HelpKeysDrawer, HelpKeysDrawerContent } from "@app/core/components/HelpKeysDrawer";

export const DEFINITION_FIELDS = [
    { label: "Name", key: "name", helpPath: "contenttransparency.name" },
    { label: "Description", key: "description", helpPath: "contenttransparency.description" },
    { label: "Allowed Content Fields", key: "contentFields", helpPath: "contenttransparency.contentFields" },
];

export const ContentTransparencyRulesDefinitionsContent = () => (
    <HelpKeysDrawerContent definitionsFields={DEFINITION_FIELDS} />
);

export const ContentTransparencyRulesDefinitionsButtonAndDrawer = () => (
    <HelpKeysDrawer
        buttonName="Content Transparency"
        panelLabel="Content Transparency Definitions"
        definitionsFields={DEFINITION_FIELDS}
    />
);
