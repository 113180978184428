import { Select } from "antd";
import { FC } from "react";
import { SelectProps } from "antd/lib/select";
import { useAppStoreSelect } from "@app/features/inventory/components/Fields/AppStoreSelect/useAppStoreSelect";

export const AppStoreSelect: FC<SelectProps> = (props) => {
    const { isFetching, options } = useAppStoreSelect();

    return (
        <Select
            {...props}
            showSearch
            allowClear
            labelInValue
            options={options}
            loading={isFetching}
            optionFilterProp="label"
            style={{ width: "100%" }}
        />
    );
};
