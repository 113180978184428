import { BaseOption } from "@app/core/services";
import { UNSPECIFIED_REGION_OPTION } from "../../constants";

export const parseRegionSelectToApi = (option: BaseOption) => {
    if (option === undefined) {
        return undefined;
    }
    if (option === null || option?.id === UNSPECIFIED_REGION_OPTION.id) {
        return null;
    }
    return option;
};

export const parseRegionSelectFromApi = (region: BaseOption | null) => {
    if (!region) {
        return UNSPECIFIED_REGION_OPTION;
    }
    return region;
};
