import { AD_SOURCE_SECTIONS, AD_SOURCE_SECTIONS_NAME, AdSourceTypes } from "@app/features/seatAdSources/constants";

export const getPricingSectionName = (typeName: string) => {
    if (
        typeName === AdSourceTypes.PROGRAMATIC_GUARANTEED ||
        typeName === AdSourceTypes.CONDITIONAL_PROGRAMATIC_GUARANTEED ||
        typeName === AdSourceTypes.FIXED_PRICE ||
        typeName === AdSourceTypes.LINEAR_FIXED_PRICE
    ) {
        return AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.LEGACY_PRICING];
    }

    if (
        typeName === AdSourceTypes.AUCTION_PRICE ||
        typeName === AdSourceTypes.CURATOR_MARKETPLACE ||
        typeName === AdSourceTypes.LINEAR_AUCTION_PRICE ||
        typeName === AdSourceTypes.MARKETPLACE ||
        typeName === AdSourceTypes.OPEN_AUCTION
    ) {
        return AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.FLOOR_SETTINGS];
    }
    return AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.PRICING];
};
