import { HelpKeysDrawer, HelpKeysDrawerContent } from "@app/core/components/HelpKeysDrawer";

export const CLASSIFICATIONS_LABEL_DEFINITION_FIELDS = [
    { label: "Label Key", key: "key", helpPath: "label.key" },
    {
        label: "Multi-Instance",
        key: "multiinstance",
        helpPath: "label.multiinstance",
    },
    { label: "Waterfall Pre-Filter", key: "waterfallprefilter", helpPath: "label.waterfallprefilter" },
    { label: "Label Visibility", key: "visibilty", helpPath: "label.visibilty" },
    { label: "Values", key: "values", helpPath: "label.values" },
];

export const ClassificationsLabelDefinitionsContent = () => (
    <HelpKeysDrawerContent definitionsFields={CLASSIFICATIONS_LABEL_DEFINITION_FIELDS} />
);

export const ClassificationsLabelDefinitionsButtonAndDrawer = () => (
    <HelpKeysDrawer
        buttonName="Labels"
        panelLabel="Label Definitions"
        definitionsFields={CLASSIFICATIONS_LABEL_DEFINITION_FIELDS}
    />
);
