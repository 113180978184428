import { FC } from "react";
import { Form, Radio } from "antd";
import { SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { usePaidType } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/AppDetailsSection/Fields/PaidType/usePaidType";

export const PaidType: FC = () => {
    const { options } = usePaidType();
    return (
        <Form.Item name={SUPPLY_FORM_FIELDS.PAID_TYPE.name} label={SUPPLY_FORM_FIELDS.PAID_TYPE.label}>
            <Radio.Group size="large" options={options} />
        </Form.Item>
    );
};
