import { FC } from "react";
import { Form, Radio } from "antd";
import {
    CHANNEL_FORM_FIELDS,
    INVENTORY_YES_NO_SPECIFIED_OPTIONS,
} from "@app/features/inventory/HierarchyForms/constants";

export const PrivacyPolicy: FC = () => {
    return (
        <Form.Item name={CHANNEL_FORM_FIELDS.PRIVACY_POLICY.name} label={CHANNEL_FORM_FIELDS.PRIVACY_POLICY.label}>
            <Radio.Group size="large" options={INVENTORY_YES_NO_SPECIFIED_OPTIONS} />
        </Form.Item>
    );
};
