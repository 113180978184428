import { Form } from "antd";
import {
    INVENTORY_FORM_FIELDS,
    AUDIENCE_LOCK_ON_SPECIFY_AD_SOURCE_TYPE,
} from "@app/features/inventory/InventorySeat/constants";

const { useWatch } = Form;

interface UseAudienceLock {
    message: string | undefined;
}

const helperMessage =
    "You will need to go to specific Ad Source to toggle “On” if you want specific Ad Source to be allowed Data Lock. Otherwise, by default Ad Sources will have Data Lock turned “Off”";

export const useAudienceLock = (): UseAudienceLock => {
    const audienceLockType: number = useWatch(INVENTORY_FORM_FIELDS.AUDIENCE_LOCK_TYPE.name);
    const isOnSpecifyAdSource: boolean = audienceLockType === AUDIENCE_LOCK_ON_SPECIFY_AD_SOURCE_TYPE.value;
    const message: string | undefined = isOnSpecifyAdSource ? helperMessage : undefined;
    return { message };
};
