import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import Hose, { HoseMessage } from "@rubicon/utils-hose";
import conf from "@app/core/conf";

const getUrlSegment = (hash: string) => {
    const newQuerystring = hash; // #demand
    return newQuerystring.replace(/#/, "");
};

export const useHose = (APP_ID: string) => {
    const history = useHistory();
    const hose = useMemo(() => new Hose(conf.streamingAppRoot, APP_ID), [APP_ID]);
    const syncHash = useCallback(
        (hoseMessage) => {
            const newHashQueryString = getUrlSegment(hoseMessage.message.hash);
            history.replace(newHashQueryString);
        },
        [history]
    );

    const handlers = [
        {
            action: "hashchange",
            handler: syncHash,
        },
    ];

    const init = () => {
        hose.addEventListeners(handlers);
        hose.initiateHashChangePosting();
        hose.post(new HoseMessage("launch-app"));
    };

    const launchApplication = () => {
        hose.post(new HoseMessage("launch-app", { hash: window.location.hash }));
    };

    return {
        init,
        launchApplication,
    };
};
