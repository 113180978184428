import { Button, Col, Row, Space } from "antd";
import { Loading } from "@app/core/components";
import { useSeatAuthContext } from "@app/core/auth";
import { InternalUseOnlyInfo } from "../InternalUseOnlyInfo";
import { InternalContactsTable } from "./InternalContactsTable";
import { useInternalContactsPage } from "./useInternalContactsPage";
import { InternalContactsPageEditSwitch } from "./InternalContactsPageEdit";

export const InternalContactsPage = () => {
    const { context } = useSeatAuthContext();
    const { handleOpen, handleClose } = useInternalContactsPage();

    if (!context) {
        return <Loading />;
    }

    return (
        <Space direction="vertical" style={{ width: "100%" }} size="middle">
            <Row justify="space-between">
                <Col>
                    <InternalUseOnlyInfo />
                </Col>
                <Col>
                    <Button onClick={handleOpen} type="primary">
                        Edit
                    </Button>
                </Col>
            </Row>
            <InternalContactsTable seat={context} />
            <InternalContactsPageEditSwitch handleOpen={handleOpen} handleClose={handleClose} />
        </Space>
    );
};
