import { useState } from "react";
import { IdNameEntity, useDeleteSeatThirdPartyPixelMutation } from "@app/core/services";
import { useParams } from "react-router-dom";
import { Typography, message, notification } from "antd";

const initialState = {
    isOpen: false,
    pixelIdName: null,
};

interface UseCustomPixelDeleteModal {
    isOpen: boolean;
    isDeleting: boolean;
    handleOpen: (pixelIdName: IdNameEntity) => void;
    handleCancel: () => void;
    handleConfirm: () => void;
    name: string;
}

export const useCustomPixelDeleteModal = (postDeleteEvent?: () => void): UseCustomPixelDeleteModal => {
    const { seatId } = useParams<{ seatId: string }>();
    const [deleteTrigger, { isLoading: isDeleting }] = useDeleteSeatThirdPartyPixelMutation();
    const [state, setState] = useState<{ isOpen: boolean; pixelIdName: null | IdNameEntity }>(initialState);

    const handleOpen = (pixelIdName: IdNameEntity) => setState({ isOpen: true, pixelIdName });

    const handleCancel = () => setState(initialState);

    const handleConfirm = () => {
        state.pixelIdName &&
            deleteTrigger({ seatId: Number(seatId), pixelId: state.pixelIdName.id })
                .unwrap()
                .then(() => {
                    setState(initialState);
                    message.success(
                        <>
                            <Typography.Text strong>{state.pixelIdName?.name}</Typography.Text> is deleted successfully!
                        </>
                    );
                    postDeleteEvent?.();
                })
                .catch((error) => {
                    setState({ ...state, isOpen: false });
                    notification.error({ message: error.message || error.data.errorDescription });
                });
    };

    return {
        isOpen: state.isOpen,
        name: state.pixelIdName?.name || "",
        isDeleting,
        handleOpen,
        handleCancel,
        handleConfirm,
    };
};
