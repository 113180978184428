import { w3cwebsocket } from "websocket";
import { EventTypes, OutgoingEvent } from "./types";

export const sendEvent = (client: w3cwebsocket, eventType: EventTypes, message?: Record<string, unknown>) => {
    const event: OutgoingEvent = {
        type: eventType,
    };

    if (message) {
        event.message = JSON.stringify(message);
    }

    client.send(JSON.stringify(event));
};

export const sendLoginEvent = (client: w3cwebsocket, session: string, user: string) =>
    sendEvent(client, EventTypes.Login, { session, user });

export const sendHeartbeatEvent = (client: w3cwebsocket) => sendEvent(client, EventTypes.Heartbeat);
