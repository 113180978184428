import { CSSProperties, FC } from "react";
import { Select } from "antd";
import { useDemandTypeFilter } from "./useDemandTypeFilter";
import { SelectAllDropdownRender } from "@app/core/components";

interface Props {
    style?: CSSProperties;
}

export const DemandTypeFilter: FC<Props> = ({ style }) => {
    const { options, value, handleChangeValue, areAllOptionsSelected, toggleSelectDeselectAll } = useDemandTypeFilter();

    return (
        <Select
            data-sdet="demand-type-filter"
            allowClear={Boolean(value)}
            onChange={handleChangeValue}
            optionFilterProp="label"
            placeholder="All"
            options={options}
            showSearch
            style={style}
            value={value}
            mode="multiple"
            dropdownRender={(menu) => (
                <SelectAllDropdownRender
                    menu={menu}
                    onClick={toggleSelectDeselectAll}
                    areAllOptionsSelected={areAllOptionsSelected}
                />
            )}
        />
    );
};
