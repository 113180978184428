import { useAuthClient } from "@app/core/authClient";
import { Session } from "@app/core/clients/console";
import { Buyer } from "@app/core/services/console/buyers";
import { useAppSelector } from "@app/core/store";
import { selectBuyerContext, selectIsBuyerDemoContext, selectIsLoadingBuyer } from "./reducer";

interface UseBuyerAuthContext {
    context: Buyer | null;
    contextType: "buyer";
    isLoading: boolean;
    session: Session | null;
    isDemoContext: boolean;
}

export const useBuyerAuthContext = (): UseBuyerAuthContext => {
    const { session } = useAuthClient();

    const isLoading = useAppSelector(selectIsLoadingBuyer);
    const buyer = useAppSelector(selectBuyerContext);
    const isDemoContext = useAppSelector(selectIsBuyerDemoContext);

    return {
        context: buyer,
        contextType: "buyer",
        isDemoContext,
        isLoading,
        session: session || null,
    };
};
