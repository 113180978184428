import { AdSourceAdvertiserDomainModeIds } from "@app/features/seatAdSources/constants";

const getAdvertiserDomainModeId = (advertiserDomainMode: number | { id: number } | undefined | null) => {
    if (advertiserDomainMode === undefined || advertiserDomainMode === null) {
        return 0;
    }
    if (typeof advertiserDomainMode === "number") {
        return advertiserDomainMode;
    }
    return advertiserDomainMode.id;
};

export const getReusableAdvertiserDomainsValidator =
    (advertiserDomainModeFieldName, additionalAdvertiserDomainsFieldName) =>
    ({ getFieldValue }) => ({
        validator(_, value) {
            const advertiserDomainMode = getFieldValue(advertiserDomainModeFieldName);
            const additionalAdvertiserDomains = getFieldValue(additionalAdvertiserDomainsFieldName);
            const advertiserDomainModeId = getAdvertiserDomainModeId(advertiserDomainMode);

            if (
                advertiserDomainModeId === AdSourceAdvertiserDomainModeIds.ALLOW_OVERRIDE_INVENTORY &&
                !additionalAdvertiserDomains &&
                !value?.length
            ) {
                return Promise.reject();
            }
            return Promise.resolve();
        },
    });

export const getAdditionalAdvertiserDomainsValidator =
    (advertiserDomainModeFieldName, reusableAdvertiserDomainsFieldName) =>
    ({ getFieldValue }) => ({
        validator(_, value) {
            const advertiserDomainMode = getFieldValue(advertiserDomainModeFieldName);
            const reusableAdvertiserDomains = getFieldValue(reusableAdvertiserDomainsFieldName);
            const advertiserDomainModeId = getAdvertiserDomainModeId(advertiserDomainMode);

            if (
                advertiserDomainModeId === AdSourceAdvertiserDomainModeIds.ALLOW_OVERRIDE_INVENTORY &&
                !reusableAdvertiserDomains?.length &&
                !value
            ) {
                return Promise.reject(
                    "You must select a Reusable Advertiser Domain or enter Advertiser Domains in the Additional Advertiser Domains field when you select Allow (Override Inventory Blocked Domains)"
                );
            }
            return Promise.resolve();
        },
    });
