import { css } from "@emotion/css";
import DOMPurify from "dompurify";
import { Collapse, Drawer } from "antd";
import { FC, useMemo, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { DrawerCloseButton, SectionTitle, StyledButtonLink } from "@app/core/components";
import { useHelpArticles } from "../hooks";

const configurationCollapseClassList = css`
    .ant-collapse-expand-icon {
        align-self: flex-end;
    }
`;

export interface DefinationField {
    label: string;
    helpPath: string;
    key: string;
    children?: DefinationField[];
}

interface Props {
    onPanelOpen?: () => void;
    panelLabel: string;
    buttonName?: string;
    definitionsFields: DefinationField[];
    defaultActiveKey?: string | number | (string | number)[];
}

interface ButtonProps {
    onClick: () => void;
    buttonName: string;
}
export const HelpKeysButton: FC<ButtonProps> = ({ onClick, buttonName }) => (
    <StyledButtonLink onClick={onClick} type="link" data-sdet={buttonName.toLocaleLowerCase().replace(/ /g, "-")}>
        <InfoCircleOutlined />
        {buttonName}
    </StyledButtonLink>
);

interface DrawerContentProps {
    definitionsFields: DefinationField[];
    defaultActiveKey?: string | number | (string | number)[];
}

const getHelpKeys = (definitionsFields: DefinationField[], helpKeys: string[]) => {
    definitionsFields.forEach((field) => {
        field.helpPath && helpKeys.push(field.helpPath);
        if (field.children) {
            getHelpKeys(field.children, helpKeys);
        }
    });
    return helpKeys;
};

const EMPTY_BODY = "The help article associated with this field doesn't seem to exist yet!";

export const HelpKeysDrawerContent: FC<DrawerContentProps> = ({ defaultActiveKey, definitionsFields }) => {
    const helpKeys = useMemo(() => getHelpKeys(definitionsFields, []), [definitionsFields]);
    const allKeys = definitionsFields.map((field) => field.key);

    const { helpArticles } = useHelpArticles(helpKeys);
    return (
        <Collapse defaultActiveKey={defaultActiveKey || allKeys} className={configurationCollapseClassList}>
            {definitionsFields.map((field) => (
                <Collapse.Panel key={field.key} header={<SectionTitle title={field.label} />} forceRender>
                    {field.children ? (
                        <Collapse
                            className={configurationCollapseClassList}
                            defaultActiveKey={field.children.map((field) => field.key)}
                        >
                            {field.children.map((child) => (
                                <Collapse.Panel
                                    key={child.key}
                                    header={<SectionTitle title={child.label} />}
                                    forceRender
                                >
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                helpArticles[child.helpPath]?.body || EMPTY_BODY
                                            ),
                                        }}
                                    />
                                </Collapse.Panel>
                            ))}
                        </Collapse>
                    ) : (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(helpArticles[field.helpPath]?.body || EMPTY_BODY),
                            }}
                        />
                    )}
                </Collapse.Panel>
            ))}
        </Collapse>
    );
};

export const HelpKeysDrawer: FC<Props> = ({
    onPanelOpen,
    panelLabel,
    buttonName = panelLabel,
    definitionsFields,
    defaultActiveKey,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    if (!definitionsFields) return null;

    const handleOpen = () => {
        setIsOpen(true);
        onPanelOpen?.();
    };

    return (
        <>
            <HelpKeysButton onClick={handleOpen} buttonName={buttonName} />
            <Drawer
                styles={{ body: { padding: 0 } }}
                width="40%"
                title={panelLabel}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                closable={false}
                extra={<DrawerCloseButton onClose={() => setIsOpen(false)} />}
            >
                <HelpKeysDrawerContent defaultActiveKey={defaultActiveKey} definitionsFields={definitionsFields} />
            </Drawer>
        </>
    );
};
