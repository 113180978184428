import { FC } from "react";
import { Form, Select } from "antd";
import { useCurrencyConversionModeField } from "./useCurrencyConversionModeField";

export const CurrencyConversionModeField: FC = () => {
    const { options, handleChangeValue, value } = useCurrencyConversionModeField();

    return (
        <Form.Item
            label="Conversion Mode"
            colon={false}
            data-sdet="currency-conversion-mode"
            style={{ marginInlineEnd: 0 }}
        >
            <Select
                style={{ width: "138px" }}
                onChange={handleChangeValue}
                value={value}
                optionFilterProp="label"
                options={options}
            />
        </Form.Item>
    );
};
