import { Loading, SectionTitle } from "@app/core/components";
import { creativeReviewHelpKeys } from "@app/core/components/CreativeReview/helpKeys";
import { HelpKeyItem } from "@app/core/components/HelpKeysDrawer/HelpKeyItem";
import { css } from "@emotion/css";
import { Col, Collapse, Row } from "antd";
import { DetailsDrawerLayout } from "../../DetailsDrawerLayout";
import { useInventoryDetailsDrawerUrlId } from "../../useInventoryDetailsDrawerUrlId";
import { useDefinitionCreativeReviewContent } from "./useDefnitionCreativeReviewContent";

const parentCollpaseClassName = css`
    width: 100%;

    & .ant-collapse-item > .ant-collapse-header {
        padding-left: 1.5rem;
    }
    .ant-collapse-content-box {
        padding: 0 0 32px 0;
    }
`;

const innerCollapseClassName = css`
    &.ant-collapse-ghost .ant-collapse-content .ant-collapse-content-box {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 48px;
        padding-right: 48px;
    }
`;

export const DefinitionCreativeReviewContent = () => {
    const unitId = useInventoryDetailsDrawerUrlId();
    const { isLoading, breadcrumbs } = useDefinitionCreativeReviewContent(unitId);
    const allKeys = creativeReviewHelpKeys.map(({ key }) => key);

    if (isLoading) {
        return <Loading />;
    }
    return (
        <Row data-sdet="inventory-creative-review-definition">
            <DetailsDrawerLayout title="Creative Review Definition" breadcrumbs={breadcrumbs} />
            <Col
                className={css`
                    padding: 0;
                    width: 100%;
                `}
            >
                <Collapse defaultActiveKey={allKeys} className={parentCollpaseClassName}>
                    {creativeReviewHelpKeys.map((field) => (
                        <Collapse.Panel key={field.key} header={<SectionTitle title={field.label} />} forceRender>
                            <Collapse
                                ghost
                                className={innerCollapseClassName}
                                defaultActiveKey={field.children.map((field) => field.label)}
                            >
                                {field.children.map((child) => (
                                    <Collapse.Panel
                                        key={child.label}
                                        header={<SectionTitle title={child.label} />}
                                        forceRender
                                    >
                                        <HelpKeyItem helpPath={child.helpPath} />
                                    </Collapse.Panel>
                                ))}
                            </Collapse>
                        </Collapse.Panel>
                    ))}
                </Collapse>
            </Col>
        </Row>
    );
};
