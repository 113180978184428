import { Form } from "antd";
import { useMemo } from "react";
import { AdBreakRuleIndex } from "./AdBreakRules";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

const { useWatch } = Form;

export const useAdBreakRules = () => {
    const midRollValues = useWatch(CHANNEL_FORM_FIELDS.AD_BREAK_RULES_MID.name);

    const hasCuepoints = useMemo<boolean>(() => {
        return (midRollValues || []).some((val) => val?.hasOwnProperty(AdBreakRuleIndex.MID_ROLL_INDEX));
    }, [midRollValues]);

    return {
        hasCuepoints,
    };
};
