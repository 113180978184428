import { css } from "@emotion/css";
import { CreativeDetails } from "@app/core/services/diagnostics/mediaUrls";
import { Column } from "@ant-design/charts";
import { useCreativeReviewLoudnessChart } from "./useCreativeReviewLoudnessChart";

interface Props {
    loudnessSamples: CreativeDetails["loudnessSamples"];
}

export const CreativeReviewLoudnessChart = ({ loudnessSamples = [] }: Props) => {
    const { config } = useCreativeReviewLoudnessChart(loudnessSamples);
    return (
        <Column
            className={css(`
    width: 100%;
    height: 100px !important;
    border: 1px solid #ccc;
`)}
            {...config}
        />
    );
};
