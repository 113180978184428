import { FC } from "react";
import { Form, Radio } from "antd";
import { AD_UNIT_FORM_FIELDS, AD_UNIT_SKIPPABLE_OPTIONS } from "@app/features/inventory/HierarchyForms/constants";

export const Skippable: FC = () => {
    return (
        <Form.Item name={AD_UNIT_FORM_FIELDS.SKIPPABLE.name} label={AD_UNIT_FORM_FIELDS.SKIPPABLE.label}>
            <Radio.Group size="large" options={AD_UNIT_SKIPPABLE_OPTIONS} />
        </Form.Item>
    );
};
