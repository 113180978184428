import { Button, Dropdown, MenuProps, Space } from "antd";
import { FC } from "react";
import { useHistoricalChartExportButton } from "./useHistoricalChartExportButton";
import { DownOutlined } from "@ant-design/icons";

export const HistoricalChartExportButton: FC = () => {
    const { handleMenuClick } = useHistoricalChartExportButton();

    const items: MenuProps["items"] = [
        {
            label: "CSV",
            key: "csv",
        },
        {
            label: "Excel",
            key: "xls",
        },
    ];

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return (
        <Dropdown menu={menuProps}>
            <Button>
                <Space>
                    Export
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    );
};
