import { LabeledValue } from "antd/lib/select";
import { Store } from "rc-field-form/lib/interface";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Form, FormInstance, notification } from "antd";
import { useParams, useHistory } from "react-router-dom";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import {
    Seat,
    UpdateSeatPayload,
    AdSourceQpsLimit,
    AdSourceCountLimit,
    useGetSeatSelfQuery,
} from "@app/core/services/console";
import {
    getExchangeProtectionsFormInitialValues,
    parseExchangeProtectionsFormValuesToApi,
} from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm/exchangeProtectionFormUtils";
import { useUpdateSeatInContext } from "@app/core/auth";

export interface AdSourceCountLimitOption extends LabeledValue {
    adSourceCountLimit: AdSourceCountLimit;
}

export interface AdSourceQpsLimitOption extends LabeledValue {
    qpsLimit: AdSourceQpsLimit;
}

export interface ViewabilityMesurement {
    sampleRate: number;
    vendor: {
        id: number;
    };
}

export interface ViewabilityMesurementOption extends LabeledValue {
    viewabilityMeasurement: ViewabilityMesurement;
}

export const DEFAULT_REQUEST_GOVERNOR_LIMIT_PER_MIN = 0;

export interface ExchangeProtectionsForm {
    //Inventory Limits Section
    [INVENTORY_FORM_FIELDS.COUNT_LIMITS.name]: AdSourceCountLimitOption[];
    [INVENTORY_FORM_FIELDS.QPS_LIMITS.name]: AdSourceQpsLimitOption[];
    [INVENTORY_FORM_FIELDS.GOVERNOR_REQUEST.name]: number;
    [INVENTORY_FORM_FIELDS.TWO_QPS_LIMIT.name]: number;
    [INVENTORY_FORM_FIELDS.THREE_QPS_LIMIT.name]: number;
    [INVENTORY_FORM_FIELDS.VIEWABILITY_MESUREMENT.name]: ViewabilityMesurementOption[];
    [INVENTORY_FORM_FIELDS.MOKA_FILTERING_MODE.name]: number;
    // Quality Control section
    [INVENTORY_FORM_FIELDS.FRAUD_DETECTION.name]: number;
    [INVENTORY_FORM_FIELDS.DOMAIN_FILTER_MODE.name]: boolean;
    [INVENTORY_FORM_FIELDS.SUPPLY_DOMAIN_FILTER.name]: LabeledValue[];
    [INVENTORY_FORM_FIELDS.BUNDLE_ID_FILTER_MODE.name]: boolean;
    [INVENTORY_FORM_FIELDS.BUNDLE_ID_FILTER.name]: LabeledValue[];
}

interface UseExchangeProtectionsForm {
    isUpdating: boolean;
    isFetching: boolean;
    handleCancel: () => void;
    seat: Seat | null | undefined;
    initialValues: Store | undefined;
    form: FormInstance<ExchangeProtectionsForm>;
    handleSubmit: () => void;
}

export const useExchangeProtectionsForm = (): UseExchangeProtectionsForm => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const { data, isFetching } = useGetSeatSelfQuery(seatId);
    const [form] = Form.useForm<ExchangeProtectionsForm>();

    const initialValues: Store | undefined = getExchangeProtectionsFormInitialValues(data?.seat);

    const { updateSeatSelf, isLoading: isUpdating } = useUpdateSeatInContext();
    const handleCancel = (): void => history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_PROTECTIONS_DETAILS(seatId));
    const handleSubmit = async (): Promise<void> => {
        try {
            await form.validateFields();
            if (!data?.seat) return;

            const values = form.getFieldsValue();
            const body: UpdateSeatPayload = parseExchangeProtectionsFormValuesToApi(data.seat, values);

            try {
                await updateSeatSelf(body);
                notification.success({ message: "Seat updated successfully" });
                handleCancel();
            } catch (err) {
                notification.error({ message: err.data.errorDescription });
            }
        } catch (error) {
            form.scrollToField(error.errorFields[0].name, { behavior: "smooth", block: "center" });
        }
    };

    return {
        form,
        isUpdating,
        isFetching,
        handleCancel,
        handleSubmit,
        initialValues,
        seat: data?.seat,
    };
};
