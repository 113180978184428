import client from "@app/core/clients/console/client";
import { notification } from "antd";
import { store } from "@app/core/store";
import { onLoginError } from "@app/core/authClient/reducer";
import { logout } from "@app/core/clients/console";

export const exchangeAccessTokenForSession = async (accessToken: string) => {
    try {
        // Call tokenExchange endpoint to exchange auth0 access token for Streaming session cookie
        await client.post(`/platform/resources/sessions/auth0/tokenExchange`, {
            token: accessToken,
        });
    } catch (e) {
        notification.error({ message: "User has an invalid access token" });
        store.dispatch(onLoginError());
        throw Error("Invalid access Token");
    }

    try {
        // Call endpoint to retrieve Session object
        const sessionObj = await client.get(`/platform/resources/sessions/self`);
        return { sessionObj };
    } catch (e) {
        notification.error({ message: "Cannot get user's information" });
        store.dispatch(onLoginError());
        logout();
        throw Error("Cannot get user's information");
    }
};
