import { YEAR_MONTH_DAY } from "@app/core/components/constants";
import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useCallback, useMemo } from "react";
import { getCompletionPercentage, getPercentage, xAxisLabelFormatter } from "./helpers";
import { PacingCurveLimits, getPacingCurveFunction } from "@app/core/utils/pacingCalculator";
import moment from "moment-timezone";
import { DualAxesConfig, ColumnConfig } from "@ant-design/plots";

const SERIES_FIELD = "Delivery";
const UPPER_TYPE = "Upper Bound";
const LOWER_TYPE = "Lower Bound";

export interface UpperLower {
    x: number;
    yy: number;
    type: typeof UPPER_TYPE | typeof LOWER_TYPE;
}

export interface Desired {
    x: number;
    y: number;
    name: typeof SERIES_FIELD;
}

export interface DataPoints {
    upperLower: UpperLower[];
    desired: Desired[];
}

interface UseAdSourceCustomPacingCharts {
    chartConfig: DualAxesConfig;
    histogramChartConfig: ColumnConfig;
}
export const useAdSourceCustomPacingCharts = (): UseAdSourceCustomPacingCharts => {
    const deliveryImps: number = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_CUSTOM_CURVE_IMPS]
    ) as number;
    const bookingVolume: number = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.IMPRESSION]
    ) as number;
    const startDate: string = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.START_DATE]
    ) as string;
    const endDate: string = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.END_DATE]
    ) as string;
    const targetDate = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_CUSTOM_CURVE_START_DATE]
    );
    const mEndDate = moment(endDate);
    const mTargetDate = moment(targetDate);

    const getSeries = useCallback(
        (desiredYCb: (arg: number) => number) => {
            const campaignDays = mEndDate.diff(startDate, "days");
            const dataPoints: DataPoints = { upperLower: [], desired: [] };
            const { cubicFront, cubicBack } = PacingCurveLimits;

            for (let i = 1; i <= campaignDays; i++) {
                const completionPercentage = i / campaignDays;
                const upperDelivery = cubicFront(completionPercentage) * bookingVolume;
                const lowerDelivery = cubicBack(completionPercentage) * bookingVolume;

                const desiredDelivery = desiredYCb(completionPercentage);

                const x = i - 1;

                dataPoints.upperLower.push(
                    { x, yy: upperDelivery, type: UPPER_TYPE },
                    { x, yy: lowerDelivery, type: LOWER_TYPE }
                );
                dataPoints.desired.push({ x, y: desiredDelivery, name: SERIES_FIELD });
            }

            return dataPoints;
        },
        [bookingVolume, mEndDate, startDate]
    );

    const getHistogramSeries = (series: { x: number; y: number }[]) =>
        series.map((point, i, s) => ({ x: point.x, y: i === 0 ? point.y : point.y - s[i - 1].y, key: SERIES_FIELD }));

    const { columns, spline } = useMemo(() => {
        const completionPercentage = getCompletionPercentage(startDate, mEndDate, mTargetDate);
        const deliveryPercentage = deliveryImps / bookingVolume;
        const curveFunction = getPacingCurveFunction(completionPercentage, deliveryPercentage);
        const series = getSeries(
            (currentX) => curveFunction(completionPercentage, deliveryPercentage, currentX) * bookingVolume
        );
        return {
            spline: series,
            columns: getHistogramSeries(series.desired),
        };
    }, [bookingVolume, startDate, mEndDate, mTargetDate, deliveryImps, getSeries]);

    const chartConfig: DualAxesConfig = useMemo(
        () => ({
            data: [spline.upperLower, spline.desired],
            legend: {
                layout: "horizontal",
                position: "bottom",
                offsetY: 10,
            },
            appendPadding: [0, 0, -11, 0],
            xField: "x",
            yField: ["yy", "y"],
            xAxis: {
                label: {
                    formatter: (text: string) => xAxisLabelFormatter(text, startDate),
                },
            },
            yAxis: {
                y: {
                    min: 0,
                },
                yy: {
                    min: 0,
                },
            },
            tooltip: {
                formatter: (datum: UpperLower | Desired) => {
                    const title = moment(startDate).add(datum.x, "days").format(YEAR_MONTH_DAY);
                    let name = "";
                    let value = 0;

                    if ("name" in datum) {
                        name = datum.name;
                    }
                    if ("type" in datum) {
                        name = datum.type;
                    }
                    if ("y" in datum) {
                        value = datum.y;
                    }
                    if ("yy" in datum) {
                        value = datum.yy;
                    }

                    return {
                        title,
                        name,
                        value,
                    };
                },
            },
            geometryOptions: [
                {
                    geometry: "line",
                    seriesField: "type",
                    lineStyle: {
                        lineWidth: 3,
                        lineDash: [1, 5],
                    },
                    color: "#5ad8a6",
                    smooth: true,
                },
                {
                    geometry: "line",
                    seriesField: "name",
                    color: "#6C96F2",
                    point: {
                        shape: () => "square",
                        style: (datum: Desired) => {
                            const defaultStyle = {
                                fillOpacity: 0,
                                strokeOpacity: 0,
                            };
                            let style = Object.assign({}, defaultStyle);

                            if (Math.round(datum.y) === deliveryImps) {
                                style = Object.assign({}, defaultStyle, { fillOpacity: 1, strokeOpacity: 1 });
                            }
                            return style;
                        },
                    },
                    label: {
                        formatter: (datum: Desired) => {
                            const targetImpression = Math.round(datum.y);
                            if (targetImpression !== deliveryImps) {
                                return "";
                            }
                            return String(targetImpression);
                        },
                    },
                },
            ],
        }),
        [spline, startDate, deliveryImps]
    );

    const histogramChartConfig: ColumnConfig = useMemo(
        () => ({
            data: columns,
            xField: "x",
            yField: "y",
            xAxis: {
                label: {
                    autoHide: true,
                    autoRotate: false,
                    formatter: (text: string) => xAxisLabelFormatter(text, startDate),
                },
            },

            seriesField: "key",
            legend: {
                layout: "horizontal",
                position: "bottom",
            },
            tooltip: {
                formatter: (datum: { x: number; y: number; key: string }) => {
                    const title = moment(startDate).add(datum.x, "days").format(YEAR_MONTH_DAY);
                    const value = `${Math.floor(datum.y)} (${getPercentage(datum.y, bookingVolume)}%)`;
                    return {
                        title,
                        value,
                        name: "Delivery",
                    };
                },
            },
            color: "#6c96f2",
        }),
        [columns, bookingVolume, startDate]
    );

    return {
        chartConfig,
        histogramChartConfig,
    };
};
