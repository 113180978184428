import { Form } from "antd";
import { useRegionSelect } from "./useRegionSelect";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { useIsFieldVisible } from "./useIsFieldVisible";

export const RegionSelect = () => {
    const { isLoading, regions } = useRegionSelect();

    const isVisible = useIsFieldVisible();

    return isVisible ? (
        <Form.Item {...VERTICAL_LAYOUT_FIX} label={AD_SOURCE_FIELDS.REGION.label} name={AD_SOURCE_FIELDS.REGION.name}>
            <SelectWithValue
                data-sdet="adsource-region"
                fieldAsLabel={["name"]}
                fieldAsValue={["id"]}
                loading={isLoading}
                optionFilterProp="label" // For internal LabelValue
                options={regions}
                placeholder="Select…"
                showSearch
            />
        </Form.Item>
    ) : null;
};
