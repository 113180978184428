import { Audience, SeatSegment, useGetCurrenciesConversionsByCodeQuery } from "@app/core/services";
import { css } from "@emotion/css";
import { ResizableDrawer } from "@rubicon/antd-components";
import { Typography, Col, Form } from "antd";
import { FC } from "react";
import { getAudienceEnrichmentCostHelp, getAudienceEnrichmentCost } from "../utils";
import { EcDetailsSection } from "./EcDetailsSection";

interface Props {
    mode: "adSource" | "deal";
    audiences: Audience[];
    segments: SeatSegment[];
    visible: boolean;
    onClose: () => void;
    isRangedCost: boolean;
    dealName?: string;
    adSourceCurrencyCode: string;
}

export const OneLevelEcDetailsDrawer: FC<Props> = ({
    mode,
    onClose,
    visible,
    isRangedCost,
    audiences,
    segments,
    dealName,
    adSourceCurrencyCode,
}) => {
    const { data: conversions } = useGetCurrenciesConversionsByCodeQuery("USD");
    const conversionRate =
        conversions?.find((conversion) => conversion.targetCurrencyCode === adSourceCurrencyCode)?.rate || 1;
    const enrichmentCost = getAudienceEnrichmentCost(audiences, segments, isRangedCost, conversionRate);
    return (
        <ResizableDrawer
            open={visible}
            onClose={onClose}
            title={`${mode === "deal" ? "Deal" : "Ad Source"} Audience Enrichment Cost Details`}
            placement="right"
            width="1024px"
            className={css`
                .ant-drawer-body {
                    padding: 0;
                }
            `}
        >
            <Form layout="vertical">
                <Col span={24} style={{ padding: "16px", paddingBottom: 0 }}>
                    <Typography.Text>{getAudienceEnrichmentCostHelp(isRangedCost)}</Typography.Text>
                    {mode === "deal" && dealName ? (
                        <Form.Item label="Deal Name" style={{ marginBottom: 0, marginTop: "8px" }}>
                            <Typography.Text>{dealName}</Typography.Text>
                        </Form.Item>
                    ) : null}
                </Col>
                <EcDetailsSection
                    mode={mode}
                    audiences={audiences}
                    segments={segments}
                    ecValue={enrichmentCost}
                    adSourceCurrencyCode={adSourceCurrencyCode}
                    conversionRate={conversionRate}
                />
            </Form>
        </ResizableDrawer>
    );
};
