import { FC } from "react";
import { CollapsePanelProps, Input } from "antd";
import { ExpandCollapse } from "@rubicon/antd-components";

export interface ReadOnlyTextAreaTargetingPanelProps extends CollapsePanelProps {
    targets: string[];
}

export const ReadOnlyTextAreaTargetingPanel: FC<ReadOnlyTextAreaTargetingPanelProps> = ({ targets, ...rest }) => {
    if (!targets.length) {
        return null;
    }
    return (
        <ExpandCollapse.Panel {...rest}>
            <Input.TextArea disabled value={targets.join("\n")} />
        </ExpandCollapse.Panel>
    );
};
