import { FC } from "react";
import { Col, Form, Row } from "antd";
import { NONE } from "@app/core/components/constants";
import { AFFILIATE_LABELS, FORM_ITEM_OFFSET } from "../../constants";
import { AdUnit, Brand, BrandSupply, Publisher } from "@app/core/services";
import { REVSHARE_PERCENTAGE_COST_MODEL } from "@app/features/inventory/InventorySeat/constants";

const { Item } = Form;

interface AffiliateProps {
    unit: Publisher | Brand | BrandSupply | AdUnit;
}

const getDisplayValue = (unit: Publisher | Brand | BrandSupply | AdUnit): number | string => {
    if (
        unit?.affiliateCostModel?.id === REVSHARE_PERCENTAGE_COST_MODEL.value &&
        typeof unit?.affiliateCostValuePercent === "number"
    )
        return `${unit?.affiliateCostValuePercent} %`;

    if (typeof unit?.affiliateCostValueFixed === "number") return `${unit?.affiliateCostValueFixed / 1000} CPM`;

    return NONE;
};

export const Affiliate: FC<AffiliateProps> = ({ unit }) => {
    const displayValue = getDisplayValue(unit);
    return (
        <Row gutter={16} data-sdet="affiliate">
            <Col span={24}>
                <Item label={AFFILIATE_LABELS.affiliateCostModel} className={FORM_ITEM_OFFSET}>
                    {unit?.affiliateCostModel?.name ?? NONE}
                </Item>
            </Col>
            <Col span={24}>
                <Item label={AFFILIATE_LABELS.affiliateCostValue} className={FORM_ITEM_OFFSET}>
                    {displayValue}
                </Item>
            </Col>
        </Row>
    );
};
