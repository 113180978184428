import { useAppDispatch, useAppSelector } from "@app/core/store";
import {
    CREATE_DEAL_FORM_ITEMS_NAME,
    TRANSPARENCY_OVERRIDE_VALUE,
    TRANSPARENCY_SHARED_VALUE,
} from "@app/features/deals/constants";
import { dealFormInputFieldChange, dealFormRadioGroupChange } from "@app/features/deals/DealForm/reducer";
import { DealAdSourceTransparenceyOverride } from "@app/features/deals/DealForm/types";
import { useMemo } from "react";

interface UseAdSourceDomainNameField {
    onChange: (value: DealAdSourceTransparenceyOverride) => void;
    value: DealAdSourceTransparenceyOverride | null;
    helpText: string | null;
}

export const useAdSourceDomainNameField = (): UseAdSourceDomainNameField => {
    const dispatch = useAppDispatch();
    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_DOMAIN_NAME]
    );
    const helpText = useMemo<string | null>(
        () =>
            value === TRANSPARENCY_OVERRIDE_VALUE
                ? "Enter Domain Name Override or use hidden if you want the Domain Name value to be blank"
                : null,
        [value]
    );
    const onChange = (value: DealAdSourceTransparenceyOverride) => {
        dispatch(dealFormRadioGroupChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_DOMAIN_NAME, value }));
        dispatch(
            dealFormInputFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DOMAIN_NAME_OVERRIDE, value: null })
        );

        if (value === TRANSPARENCY_OVERRIDE_VALUE) {
            dispatch(
                dealFormRadioGroupChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_SITE_PAGE,
                    value: TRANSPARENCY_SHARED_VALUE,
                })
            );
            dispatch(
                dealFormRadioGroupChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_REF,
                    value: TRANSPARENCY_SHARED_VALUE,
                })
            );
        }
    };

    return {
        onChange,
        value,
        helpText,
    };
};
