import { FC } from "react";
import { Form, Input } from "antd";
import { LABEL_CLASS_LIST } from "@app/features/inventory/constants";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { InheritedDomainsBlocksDrawer } from "@app/features/inventory/HierarchyForms/AdUnitForm/BrandSafetySection/Fields/InheritedDomainsBlocksDrawer";

export const BlockAdvertiserDomains: FC = () => {
    return (
        <Form.Item
            className={LABEL_CLASS_LIST}
            extra="Enter one domain per line"
            label={<InheritedDomainsBlocksDrawer />}
            name={AD_UNIT_FORM_FIELDS.BLOCK_ADVERTISER_DOMAINS.name}
        >
            <Input.TextArea
                rows={4}
                allowClear
                placeholder={AD_UNIT_FORM_FIELDS.BLOCK_ADVERTISER_DOMAINS.placeholder}
            />
        </Form.Item>
    );
};
