import { Tag } from "antd";
import { MouseEvent } from "react";
import { css } from "@emotion/css";
import { blue6 } from "@rubicon/antd-components";
import type { LiteralUnion } from "antd/es/_util/type";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import type { PresetColorType, PresetStatusColorType } from "antd/es/_util/colors";

interface TagRenderProps extends CustomTagProps {
    color?: LiteralUnion<PresetColorType | PresetStatusColorType>;
    closeIconColor?: string;
}

export const TagRender = ({
    label,
    closable,
    onClose,
    color = "processing",
    closeIconColor = blue6,
}: TagRenderProps) => {
    const onPreventMouseDown = (event: MouseEvent<HTMLSpanElement>): void => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <Tag
            color={color}
            closable={closable}
            onClose={onClose}
            onMouseDown={onPreventMouseDown}
            style={{ margin: "2px 4px 2px 0" }}
            className={css`
                & {
                    max-width: 100%;
                }

                .ant-tag-close-icon {
                    color: ${closeIconColor};
                }
            `}
        >
            <span
                className={css`
                    & {
                        overflow-x: hidden;
                        text-overflow: ellipsis;
                        max-width: calc(100% - 15px);
                        float: left;
                    }
                `}
            >
                {label}
            </span>
        </Tag>
    );
};
