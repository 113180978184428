import { FC } from "react";
import { Floor } from "@app/core/services";
import { ColumnsType } from "antd/es/table";
import {
    FLOOR_SOURCE_KEY,
    FLOOR_ACTIONS_KEY,
    FLOOR_COUNTRY_KEY,
    FLOOR_INHERITED_KEY,
    FLOOR_PRICE_MODEL_KEY,
    FLOOR_ACTUAL_PRICE_KEY,
    FLOORS_TABLE_COLUMNS,
    FLOOR_CUSTOM_PRICE_KEY,
} from "@app/features/inventory/constants";
import { LabeledValue } from "antd/lib/select";
import { NONE } from "@app/core/components/constants";
import { Button, Table, Space, Row, Col } from "antd";
import {
    isActualPrice,
    getFloorSourceLabel,
    getFloorInheritedFromName,
    getCountryValue,
    getCountryGeo,
} from "@app/features/inventory/InventoryFloors/helpers";
import {
    FloorTablePrice,
    FloorTableCountry,
    FloorTableActions,
} from "@app/features/inventory/components/FloorsTable/FloorTableRows";
import { useFloorsTable } from "@app/features/inventory/components/FloorsTable/useFloorsTable";

export const getValuesFromFloor = (floor: Floor, key: number): FloorValue => ({
    key,
    id: floor.id,
    price: floor.price / 1000,
    country: getCountryValue(getCountryGeo(floor)),
    floor,
});

export const getFloorInitialValues = (floors: Floor[] | null): FloorValue[] => {
    if (!floors) return [];
    return floors.map((floor, i) => getValuesFromFloor(floor, i));
};

export interface FloorValue {
    key: number;
    price: number;
    id: number | null;
    floor: Floor | null;
    country: LabeledValue | null;
}

export type SourceSelf = "Seat" | "Publisher" | "Brand" | "Supply" | "Ad Unit";
export type FloorTableSourceSelf = Exclude<SourceSelf, "Seat">;

export type FloorsTableDataIndexes =
    | typeof FLOOR_SOURCE_KEY
    | typeof FLOOR_ACTIONS_KEY
    | typeof FLOOR_COUNTRY_KEY
    | typeof FLOOR_INHERITED_KEY
    | typeof FLOOR_PRICE_MODEL_KEY
    | typeof FLOOR_ACTUAL_PRICE_KEY
    | typeof FLOOR_CUSTOM_PRICE_KEY;

interface FloorsTableProps {
    id: number;
    readOnly?: boolean;
    sourceSelf: FloorTableSourceSelf;
    hiddenColumns?: FloorsTableDataIndexes[];
}

interface FloorsColumns {
    readOnly: boolean;
    hiddenColumns: FloorsTableDataIndexes[];
}

export const getFloorsColumns = ({ readOnly, hiddenColumns }: FloorsColumns): ColumnsType => {
    if (hiddenColumns?.length && readOnly)
        return FLOORS_TABLE_COLUMNS.filter(
            ({ dataIndex }) => ![FLOOR_ACTIONS_KEY, ...hiddenColumns].includes(dataIndex as FloorsTableDataIndexes)
        );
    if (hiddenColumns?.length)
        return FLOORS_TABLE_COLUMNS.filter(
            ({ dataIndex }) => !hiddenColumns.includes(dataIndex as FloorsTableDataIndexes)
        );

    return FLOORS_TABLE_COLUMNS;
};

interface FloorsTableItems {
    floors: FloorValue[];
    data: Floor[] | undefined;
    handleDeleteNew: () => void;
    savingIds: Set<number | null>;
    editingIds: Set<number | null>;
    deletingIds: Set<number | null>;
    sourceSelf: FloorTableSourceSelf;
    handleEdit: (id: number) => void;
    handleDelete: (id: number) => void;
    handleEditCancel: (id: number) => void;
    handleSave: (id: number | null) => void;
    handleChangePrice: (id: number | null, event: number) => void;
    handleChangeCountry: (id: number | null, value: LabeledValue | null) => void;
}

const getFloorsTableItems = ({
    data,
    floors,
    sourceSelf,
    editingIds,
    handleChangePrice,
    handleChangeCountry,
    ...rest
}: FloorsTableItems) =>
    floors.map((record: FloorValue) => ({
        key: record?.id,
        [FLOOR_PRICE_MODEL_KEY]: (
            <FloorTablePrice record={record} editingIds={editingIds} handleChangePrice={handleChangePrice} />
        ),
        [FLOOR_COUNTRY_KEY]: (
            <FloorTableCountry record={record} editingIds={editingIds} handleChangeCountry={handleChangeCountry} />
        ),
        [FLOOR_SOURCE_KEY]: record.floor ? getFloorSourceLabel(record.floor, sourceSelf) : NONE,
        [FLOOR_INHERITED_KEY]: record.floor ? getFloorInheritedFromName(record.floor) : NONE,
        [FLOOR_ACTUAL_PRICE_KEY]: record.floor && data ? (isActualPrice(record.floor, data) ? "Yes" : "No") : NONE,
        //TODO use floor field value once these fields be added to API(new fields)
        [FLOOR_CUSTOM_PRICE_KEY]: NONE,
        [FLOOR_ACTIONS_KEY]: (
            <FloorTableActions record={record} sourceSelf={sourceSelf} editingIds={editingIds} {...rest} />
        ),
    }));

export const FloorsTable: FC<FloorsTableProps> = ({ id, readOnly = false, hiddenColumns = [], sourceSelf }) => {
    const { hasSeatWriteAccess, isLoading, isCreateMode, handleAdd, ...rest } = useFloorsTable({
        sourceSelf,
        id,
    });
    return (
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Row gutter={[0, 12]} justify="start">
                <Col span={24}>
                    <Table
                        size="small"
                        pagination={false}
                        loading={isLoading}
                        scroll={{ x: 1100 }}
                        showSorterTooltip={false}
                        columns={getFloorsColumns({
                            readOnly,
                            hiddenColumns,
                        })}
                        dataSource={getFloorsTableItems({ sourceSelf, ...rest })}
                    />
                </Col>
                {hasSeatWriteAccess && !readOnly && (
                    <Col>
                        <Button type="link" style={{ padding: 0 }} disabled={isCreateMode} onClick={handleAdd}>
                            + Add Floor
                        </Button>
                    </Col>
                )}
            </Row>
        </Space>
    );
};
