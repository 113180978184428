import { FC } from "react";
import { Col, Row, Card } from "antd";

import {
    FillMode,
    MaxAdsPerAdvertiser,
    PlaylistDefinitionMode,
    PodEnforcement,
    SeparationGroups,
    SeparationMode,
} from "./Fields";

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 18, xl: 17, xxl: 12 };

export const PlaylistConfigurationSection: FC = () => (
    <Card bordered={false}>
        <Row>
            <Col {...COL_SIZES}>
                <FillMode />
                <PodEnforcement />
                <SeparationMode />
                <SeparationGroups />
                <MaxAdsPerAdvertiser />
                <PlaylistDefinitionMode />
            </Col>
        </Row>
    </Card>
);
