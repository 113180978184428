import { FC } from "react";
import { Seat } from "@app/core/services";
import { Card, Col, Form, Row } from "antd";
import { useUserAccess } from "@app/core/auth";
import { getYesNoLabel } from "@app/core/utils/labels";
import { INVENTORY_CARD_BODY_STYLE } from "@app/features/inventory/constants";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import { INVENTORY_FORM_FIELDS, SEAT_DETAILS_SECTION_COL_SIZES } from "@app/features/inventory/InventorySeat/constants";
import { EnableDmpList } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/AdvancedFeaturesForm/AdvancedFeaturesFormFields";

interface IdentityAndRegulationSectionProps {
    seat: Seat;
    edit?: boolean;
}

const INTERNAL_COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 };

export const IdentityAndRegulationSection: FC<IdentityAndRegulationSectionProps> = ({ seat, edit = false }) => {
    const { isSysAdmin, isSysReadOnly, isPubAcctMgr, isTremorUser } = useUserAccess();
    const hasInternalAccess: boolean = isSysAdmin || isPubAcctMgr || isTremorUser;
    return (
        <Card bordered={false}>
            <Row gutter={16}>
                <Col {...SEAT_DETAILS_SECTION_COL_SIZES}>
                    <EnableDmpList seat={seat} edit={edit} />
                </Col>
                {hasInternalAccess && (
                    <Col {...SEAT_DETAILS_SECTION_COL_SIZES}>
                        <Card type="inner" title="Internal Only" bodyStyle={INVENTORY_CARD_BODY_STYLE}>
                            {(isSysAdmin || isPubAcctMgr) && (
                                <Row gutter={16}>
                                    <Col {...INTERNAL_COL_SIZES}>
                                        <Form.Item
                                            label={INVENTORY_FORM_FIELDS.DMP_CREATION_ENABLED.label}
                                            name={INVENTORY_FORM_FIELDS.DMP_CREATION_ENABLED.name}
                                        >
                                            {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.dmpCreationEnabled)}
                                        </Form.Item>
                                    </Col>
                                    <Col {...INTERNAL_COL_SIZES}>
                                        <Form.Item
                                            label={INVENTORY_FORM_FIELDS.IDL_ENABLED.label}
                                            name={INVENTORY_FORM_FIELDS.IDL_ENABLED.name}
                                        >
                                            {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.idlEnabled)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                            {(isSysAdmin || isPubAcctMgr) && (
                                <Row gutter={16}>
                                    <Col {...INTERNAL_COL_SIZES}>
                                        <Form.Item
                                            label={INVENTORY_FORM_FIELDS.OZTAM_ENABLED.label}
                                            name={INVENTORY_FORM_FIELDS.OZTAM_ENABLED.name}
                                        >
                                            {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.oztamEnabled)}
                                        </Form.Item>
                                    </Col>
                                    <Col {...INTERNAL_COL_SIZES}>
                                        <Form.Item
                                            label={INVENTORY_FORM_FIELDS.MASK_DEVICE_ID.label}
                                            name={INVENTORY_FORM_FIELDS.MASK_DEVICE_ID.name}
                                        >
                                            {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.maskDeviceId)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                            <Row gutter={16}>
                                {(isSysAdmin || isPubAcctMgr) && (
                                    <Col {...INTERNAL_COL_SIZES}>
                                        <Form.Item
                                            label={INVENTORY_FORM_FIELDS.GDPR_GOOGLE_CONSENT_ALL.label}
                                            name={INVENTORY_FORM_FIELDS.GDPR_GOOGLE_CONSENT_ALL.name}
                                        >
                                            {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.gdprGoogleConsentAll)}
                                        </Form.Item>
                                    </Col>
                                )}
                                {isTremorUser && (
                                    <Col {...INTERNAL_COL_SIZES}>
                                        <Form.Item
                                            label={INVENTORY_FORM_FIELDS.ENABLE_AUDIENCE_TARGETING.label}
                                            name={INVENTORY_FORM_FIELDS.ENABLE_AUDIENCE_TARGETING.name}
                                        >
                                            {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.enableAudienceTargeting)}
                                        </Form.Item>
                                    </Col>
                                )}
                            </Row>
                            {isTremorUser && (
                                <Row gutter={16}>
                                    <Col {...INTERNAL_COL_SIZES}>
                                        <Form.Item
                                            label={INVENTORY_FORM_FIELDS.ENABLE_MAGNITE_MATCH.label}
                                            name={INVENTORY_FORM_FIELDS.ENABLE_MAGNITE_MATCH.name}
                                        >
                                            {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.enableMagniteMatch)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                            {(isSysAdmin || isPubAcctMgr || isSysReadOnly) && (
                                <Row gutter={16}>
                                    <Col {...INTERNAL_COL_SIZES}>
                                        <Form.Item
                                            label={INVENTORY_FORM_FIELDS.ENABLE_LIVERAMP_ATS.label}
                                            name={INVENTORY_FORM_FIELDS.ENABLE_LIVERAMP_ATS.name}
                                        >
                                            {edit && (isSysAdmin || isPubAcctMgr) ? (
                                                <BooleanRadioGroup />
                                            ) : (
                                                getYesNoLabel(seat.liverampAtsEnabled)
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                        </Card>
                    </Col>
                )}
            </Row>
        </Card>
    );
};
