import React, { FC } from "react";
import { useSeatAuthContext } from "@app/core/auth";
import { Loading, SeatIFrame } from "@app/core/components";
import { Layout } from "antd";
import conf from "@app/core/conf";

export const Demand: FC = () => {
    const { context, session } = useSeatAuthContext();

    if (!context || !session) {
        return (
            <Layout.Content>
                <Loading />
            </Layout.Content>
        );
    }

    return <SeatIFrame src={(seatId) => `${conf.mctvConsoleRoot}/ssp/seats/${seatId}/demand/`} title="Demand" />;
};
