import { FC } from "react";
import {
    AccountType,
    BusinessDomain,
    ExternalComment,
} from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormSections/GeneralSection/Fields/GeneralInternalOnly/Fields";
import { Code } from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormSections/GeneralSection/Fields";
import { InternalOnly } from "@app/features/inventory/components/InternalOnly";

interface Props {
    isEditing: boolean;
}
export const GeneralInternalOnly: FC<Props> = ({ isEditing }) => {
    return (
        <InternalOnly>
            <AccountType />
            <BusinessDomain />
            {isEditing && <Code wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 }} />}
            <ExternalComment />
        </InternalOnly>
    );
};
