import { useMemo } from "react";
import { HelpArticle, useGetHelpArticlesQuery } from "@app/core/services";

export const useHelpArticles = (helpKeysFilter: { [key: string]: string } | string[]) => {
    const helpKeys = Array.isArray(helpKeysFilter) ? helpKeysFilter : Object.values(helpKeysFilter).filter(Boolean);
    const { data, isFetching, error } = useGetHelpArticlesQuery({ helpKeys });

    const helpArticles = useMemo(() => {
        return (data || []).reduce((acc, article) => {
            acc[article.helpKey] = article;
            return acc;
        }, {} as { [key: string]: HelpArticle });
    }, [data]);

    return {
        helpArticles: error && !isFetching ? {} : helpArticles,
        isLoading: isFetching,
    };
};
