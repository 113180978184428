import { FC } from "react";
import { Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { useInventorySearch } from "./useInventorySearch";

export const InventorySearch: FC = () => {
    const { isLoading, options, search, handleChangeSearch, handleSearchSelected, handleScroll } = useInventorySearch();

    return (
        <Select
            popupClassName={css`
                .ant-select-item-option-content {
                    overflow: visible;
                    white-space: normal;
                }
            `}
            style={{ width: "100%" }}
            filterOption={false}
            loading={isLoading}
            onSearch={handleChangeSearch}
            onChange={handleSearchSelected}
            onPopupScroll={handleScroll}
            options={options}
            placeholder="Search All Inventory"
            searchValue={search}
            showSearch
            suffixIcon={isLoading ? undefined : <SearchOutlined />}
            value={null}
            notFoundContent={Boolean(search) ? undefined : null}
        />
    );
};
