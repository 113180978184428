import { FC } from "react";
import { css } from "@emotion/css";
import { SIDE_NAV_WIDTH } from "../constants";
import { Divider, Layout, Tooltip } from "antd";
import { Icons } from "@rubicon/antd-components";
import { useSeatAuthContext } from "@app/core/auth";
import {
    Nav,
    useSeatSideNavState,
    LogoText,
    NavGroup,
    NavGroupItem,
    AccountNavGroupItem,
    SeatAccountDrawer,
} from "@app/core/components";
import { useTheme } from "@app/core/theme";
import { NotificationsDrawer } from "../NotificationsDrawer";
import { SeatMoreDrawer } from "../SeatMoreDrawer";
import { useMenuItems } from "./useMenuItems";
import { useMoreSubMenuItems } from "./useMoreSubMenuItems";
import { useEvents } from "@app/features/events";
import { useWatchSeatPermissions } from "@app/core/auth/useWatchSeatPermissions";

const menuMoreItem = {
    key: "more",
    label: "More",
    icon: <Icons.MoreIcon />,
};

const notificationsItem = {
    key: "notifications",
    label: "Notifications",
};

export const SeatSideNav: FC = () => {
    useWatchSeatPermissions();
    const {
        activeApp,
        activeGroup,
        handleAppClick,
        handleMenuClose,
        handleGroupClose,
        handleGroupClick,
        isAccount,
        handleAccountClick,
        isOpen,
    } = useSeatSideNavState();
    const { theme } = useTheme();
    const { context } = useSeatAuthContext();
    const { menuItems } = useMenuItems(Number(context?.id));
    const { hasMoreMenu, moreSubMenuItems } = useMoreSubMenuItems(Number(context?.id), handleMenuClose);
    const accountLabel = context ? `${context.name} (${context.entityType || "Seat"})` : "";
    const { notificationsCount, notifications } = useEvents();
    return (
        <Layout.Sider width={SIDE_NAV_WIDTH}>
            <Nav backgroundColor={theme?.meta.navbarBackgroundColor}>
                <LogoText src={theme?.meta.logo} />
                <NavGroup backgroundColor={theme?.meta.navbarBackgroundColor}>
                    <NavGroupItem
                        isActive={activeGroup === notificationsItem.key}
                        key={notificationsItem.key}
                        label={notificationsItem.label}
                        icon={<Icons.NotificationsIcon />}
                        indicator={notificationsCount}
                        onClick={() => handleGroupClick(notificationsItem.key)}
                        backgroundColor={theme?.meta.navbarBackgroundColor}
                        backgroundHighlightColor={theme?.meta.navbarBackgroundHighlightColor}
                    />
                    {menuItems.map((app) => (
                        <NavGroupItem
                            isActive={activeApp === app.key}
                            key={app.key}
                            icon={app.icon}
                            label={app.label}
                            onClick={() => handleAppClick(app)}
                            route={app.route}
                            backgroundColor={theme?.meta.navbarBackgroundColor}
                            backgroundHighlightColor={theme?.meta.navbarBackgroundHighlightColor}
                        />
                    ))}
                    {hasMoreMenu && (
                        <NavGroupItem
                            isActive={activeGroup === menuMoreItem.key}
                            key={menuMoreItem.key}
                            icon={menuMoreItem.icon}
                            label={menuMoreItem.label}
                            onClick={() => handleGroupClick(menuMoreItem.key)}
                            backgroundColor={theme?.meta.navbarBackgroundColor}
                            backgroundHighlightColor={theme?.meta.navbarBackgroundHighlightColor}
                        />
                    )}
                </NavGroup>
                <div>
                    <Divider className={css({ margin: "0.5rem 0", borderTop: "1px solid rgba(255,255,255,0.5)" })} />
                    {context && (
                        <Tooltip placement="right" title={accountLabel}>
                            <div>
                                <AccountNavGroupItem
                                    backgroundColor={theme?.meta.navbarBackgroundColor}
                                    backgroundHighlightColor={theme?.meta.navbarBackgroundHighlightColor}
                                    icon={<Icons.MyAccountIcon isOpen={isAccount && isOpen} />}
                                    isActive={isAccount}
                                    label={accountLabel}
                                    onClick={handleAccountClick}
                                />
                            </div>
                        </Tooltip>
                    )}
                </div>
            </Nav>
            <SeatAccountDrawer />
            <NotificationsDrawer
                isOpen={Boolean(isOpen && activeGroup === notificationsItem.key)}
                handleClose={handleMenuClose}
                handleTypeClose={handleGroupClose}
                notifications={notifications}
            />
            {hasMoreMenu && (
                <SeatMoreDrawer
                    isOpen={Boolean(isOpen && activeGroup === menuMoreItem.key)}
                    handleClose={handleMenuClose}
                    handleTypeClose={handleGroupClose}
                    menuItems={moreSubMenuItems}
                />
            )}
        </Layout.Sider>
    );
};
