import { FC } from "react";
import { Form, Input } from "antd";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const ContentChannel: FC = () => {
    return (
        <Form.Item name={AD_UNIT_FORM_FIELDS.CONTENT_CHANNEL.name} label={AD_UNIT_FORM_FIELDS.CONTENT_CHANNEL.label}>
            <Input placeholder={AD_UNIT_FORM_FIELDS.CONTENT_CHANNEL.placeholder} allowClear />
        </Form.Item>
    );
};
