import { useContext } from "react";
import { useWatch } from "antd/lib/form/Form";
import { RadioChangeEvent } from "antd/es/radio";
import { SeatAdSourcesType } from "@app/core/services/console/adSources";
import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { AdSourceFormsContext } from "@app/features/seatAdSources/SeatAdSourcesForm";

const { TOTAL_IMPRESSIONS, SHARE_OF_VOICE_PERCENT } = AD_SOURCE_FIELDS;
const { SERVER_SIDE_TAG_GUARANTEED, CLIENT_SIDE_TAG_GUARANTEED } = AdSourceTypeIds;

interface UseShareOfVoiceRadio {
    isVisible: boolean;
    onChange: (event: RadioChangeEvent) => void;
}

export const useShareOfVoiceRadio = (): UseShareOfVoiceRadio => {
    const { setFieldValue } = useContext(AdSourceFormsContext).forms.adSourceForm;
    const adSourceTypeId = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name)?.id;

    const isVisible = [SERVER_SIDE_TAG_GUARANTEED, CLIENT_SIDE_TAG_GUARANTEED].includes(adSourceTypeId);

    const onChange = (event: RadioChangeEvent): void => {
        const isEnabled = event.target.value;
        if (isEnabled) {
            setFieldValue(TOTAL_IMPRESSIONS.name, null);
        } else {
            setFieldValue(SHARE_OF_VOICE_PERCENT.name, null);
        }
    };

    return {
        isVisible,
        onChange,
    };
};
