import { Typography } from "antd";
import { CSSProperties, FC } from "react";
import { Asterisk } from "./formRequiredMark";

interface Props {
    required?: boolean;
    label: string;
    level?: 1 | 2 | 3 | 4 | 5;
    style?: CSSProperties;
}
export const TitleAsLabel: FC<Props> = ({ label, level = 5, style, required = false }) => (
    <>
        <Typography.Title level={level} style={{ margin: 0, ...style }}>
            {label}
        </Typography.Title>
        {required && <Asterisk />}
    </>
);
