import { FC } from "react";
import {
    BlockBuyerSeatList,
    BlockAdvertiserDomains,
    BlockAdvertiserDomainList,
    AdvertiserDomainFrequencyCaps,
    AdvertiserFrequencyCappingType,
} from "./Fields";
import { Card, Col, Row } from "antd";
import { LabeledValue } from "antd/lib/select";
import {
    AD_UNIT_FORM_FIELDS,
    DEFAULT_ADVERTISER_FREQUENCY_CAPPING,
} from "@app/features/inventory/HierarchyForms/constants";
import { useAdUnitFormInstance } from "@app/features/inventory/HierarchyForms/AdUnitForm/AdUnitFormInstance";

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 };
const INPUTS_COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 };

export const BrandSafetySection: FC = () => {
    const { useWatch } = useAdUnitFormInstance();
    const freqCapp: LabeledValue = useWatch(AD_UNIT_FORM_FIELDS.ADVERTISER_FREQUENCY_CAPPING_TYPE.name);

    const isAdvertiserDomainFrequencyCapsShown: boolean =
        freqCapp?.value !== DEFAULT_ADVERTISER_FREQUENCY_CAPPING.value;

    return (
        <Card bordered={false}>
            <Row>
                <Col {...COL_SIZES}>
                    <BlockAdvertiserDomainList />
                    <BlockAdvertiserDomains />
                    <Row gutter={32}>
                        <Col {...INPUTS_COL_SIZES}>
                            <BlockBuyerSeatList />
                        </Col>
                    </Row>
                    <Row gutter={32}>
                        <Col {...INPUTS_COL_SIZES}>
                            <AdvertiserFrequencyCappingType />
                        </Col>
                    </Row>
                    {isAdvertiserDomainFrequencyCapsShown && <AdvertiserDomainFrequencyCaps />}
                </Col>
            </Row>
        </Card>
    );
};
