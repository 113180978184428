import { Form } from "antd";
import { FC } from "react";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import { SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const IFAMode: FC = () => {
    return (
        <Form.Item {...SUPPLY_FORM_FIELDS.IFA_MODE}>
            <BooleanRadioGroup trueLabel="Automatically Detect" falseLabel="Specify" />
        </Form.Item>
    );
};
