import { Loading } from "@app/core/components";
import { DealListSource } from "@app/core/services";
import { Row } from "antd";
import { DealDetailsDrawerTitles } from "@app/features/deals/constants";
import { useDealAdSourceById } from "@app/features/deals/DealDetailsPage/useDealAdSourceById";
import { DealDetailsDrawerHeader } from "@app/features/deals/DealDetailsDrawer/DealDetailsDrawerHeader";
import { DealDetailsDrawerHeaderTitleSwitch } from "@app/features/deals/DealDetailsDrawer/DealDetailsDrawerHeader/DealDetailsDrawerHeaderTitleSwitch";
import { DealDetailsDrawerViewSwitch } from "@app/features/deals/DealDetailsDrawer/DealDetailsDrawerView/DealDetailsDrawerViewSwitch";
import { useDealDetailsDrawerContent } from "@app/features/deals/DealDetailsDrawer/useDealDetailsDrawerContent";

export interface DealDetailsDrawerTitleInfo {
    dealId: number;
    dealName: string;
    dealEntityType: string;
    adSources?: DealListSource[];
    isDealShared: boolean;
    secondaryDetailsView: DealDetailsDrawerTitles | null;
}

export const DealDetailsDrawerContent = () => {
    const { requestedDealId, secondaryDetailsView } = useDealDetailsDrawerContent();
    const { dealAdSource, isLoading } = useDealAdSourceById(Number(requestedDealId));

    // TODO - investigate why we are seeing dealAdSource as undefined in some cases
    if (isLoading || !dealAdSource?.deal) {
        return <Loading />;
    }

    const { deal } = dealAdSource;
    const { entityType, id, name, sharedMarketplaces, sharedSeats, adSources } = deal;
    const isDealShared =
        (sharedMarketplaces && sharedMarketplaces.length > 0) || (sharedSeats && sharedSeats.length > 0) || false;

    const titleInfo: DealDetailsDrawerTitleInfo = {
        dealId: id,
        dealName: name,
        dealEntityType: entityType,
        isDealShared,
        secondaryDetailsView,
    };

    if (adSources?.length) {
        Object.assign(titleInfo, { adSources: adSources });
    }

    return (
        <Row data-sdet="deal-details-drawer-content">
            <DealDetailsDrawerHeader>
                <DealDetailsDrawerHeaderTitleSwitch titleInfo={titleInfo} />
            </DealDetailsDrawerHeader>
            <DealDetailsDrawerViewSwitch dealAdSource={dealAdSource} secondaryDetailsView={secondaryDetailsView} />
        </Row>
    );
};
