import { CurrencyConversion } from "@app/core/services";

export const getCurrencyConversionRate = (
    currencyId: number,
    currencyConversions: CurrencyConversion[],
    preferredCurrencyId: number
) => {
    const currencyConversion = currencyConversions.find(
        (conversion) =>
            conversion.sourceCurrencyId === currencyId && conversion.targetCurrencyId === preferredCurrencyId
    );

    return currencyConversion?.rate || 1;
};

export const convert = (
    amount: number,
    currencyId: number,
    currencyConversions: CurrencyConversion[],
    preferredCurrencyId: number
) => {
    if (currencyId === preferredCurrencyId) {
        return amount;
    }
    return amount * getCurrencyConversionRate(currencyId, currencyConversions, preferredCurrencyId);
};
