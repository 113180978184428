import { useUserAccess } from "@app/core/auth";
import { Button, Col, Row, Space } from "antd";
import { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import {
    ClassificationsAdvertiserDeleteModal,
    useClassificationsAdvertiserDeleteModal,
} from "../ClassificationsAdvertisersTab/ClassificationsAdvertisersTabDrawer/ClassificationsAdvertiserDeleteModal";
import { AdvertiserDetails } from "@app/core/services";

export const CLASSIFICATIONS_ADVERTISER_DETAILS_ACTIONS_SDET = "classifications-advertiser-details-actions";
export const CLASSIFICATIONS_ADVERTISER_DETAILS_ACTIONS_EDIT_BUTTON_SDET =
    "classifications-advertiser-details-actions-edit-btn";
export const CLASSIFICATIONS_ADVERTISER_DETAILS_ACTIONS_DELETE_BUTTON_SDET =
    "classifications-advertiser-details-actions-delete-btn";
export const CLASSIFICATIONS_ADVERTISER_DETAILS_ACTIONS_CHANGE_HISTORY_BUTTON_SDET =
    "classifications-advertiser-details-actions-change-history-btn";

interface Props {
    advertiser?: AdvertiserDetails;
    isDrawer?: boolean;
    onClickChangeHistory: () => void;
}

export const ClassificationsAdvertiserDetailsActions: FC<Props> = ({
    advertiser,
    isDrawer = false,
    onClickChangeHistory,
}) => {
    const { canEditSeat, isSeatAdmin } = useUserAccess();
    const { handleOpen, ...deleteModalProps } = useClassificationsAdvertiserDeleteModal(isDrawer);
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();

    return advertiser && !advertiser.deleted ? (
        <>
            <Row
                data-sdet={CLASSIFICATIONS_ADVERTISER_DETAILS_ACTIONS_SDET}
                justify={canEditSeat ? "space-between" : "end"}
            >
                {canEditSeat && (
                    <Col>
                        <Space>
                            <Button
                                onClick={() =>
                                    history.push(
                                        ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISER_EDIT_PAGE(
                                            seatId,
                                            advertiser.id
                                        )
                                    )
                                }
                                data-sdet={CLASSIFICATIONS_ADVERTISER_DETAILS_ACTIONS_EDIT_BUTTON_SDET}
                                type="primary"
                            >
                                Edit
                            </Button>
                            <Button
                                danger
                                onClick={() => handleOpen(advertiser)}
                                data-sdet={CLASSIFICATIONS_ADVERTISER_DETAILS_ACTIONS_DELETE_BUTTON_SDET}
                                type="primary"
                            >
                                Delete
                            </Button>
                        </Space>
                    </Col>
                )}
                {isSeatAdmin && (
                    <Col>
                        <Button
                            onClick={onClickChangeHistory}
                            data-sdet={CLASSIFICATIONS_ADVERTISER_DETAILS_ACTIONS_CHANGE_HISTORY_BUTTON_SDET}
                        >
                            Change History
                        </Button>
                    </Col>
                )}
            </Row>
            <ClassificationsAdvertiserDeleteModal {...deleteModalProps} />
        </>
    ) : null;
};
