import { useNotificationError } from "@app/core/components/hooks";
import { uri } from "@rubicon/utils";
import { FC, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Loading } from "@app/core/components";
import { ClassificationsIndustryDetailsDrawerTitle } from "./ClassificationsIndustryDetailsDrawerTitle";
import { ChangeLogEntityType, useGetIndustryDetailsQuery } from "@app/core/services";
import { HelperKeysDrawerBreadcrumbHeader } from "@app/core/components/HelperKeysDrawerBreadcrumbHeader";
import { useUserAccess } from "@app/core/auth";
import { ClassificationsIndustryChangeHistoryDrawerTitle } from "./ClassificationsIndustryChangeHistoryDrawerTitle";
import { ChangeHistoryTable } from "@app/core/components/ChangeHistoryTable";
import { ClassificationsIndustryDetailsContent } from "./ClassificationsIndustryDetailsContent";
import { ClassificationsIndustryDefinitionsContent } from "../../ClassificationsIndustryDefinitions";
import { ResizableDrawer } from "@rubicon/antd-components";
import { drawerRightClosePosition } from "@app/features/controls/constants";

export const CLASSIFICATIONS_INDUSTRY_CHANGE_HISTORY_TABLE_SDET = "classifications-industry-change-history-table";

export const removeIndustryDrawerParamsFromSearch = (queryString: string) => {
    const removeDrawerParam = uri.deleteSearchParam(queryString, "drawer");
    const nextQueryString = uri.deleteSearchParam(removeDrawerParam, "industryId");
    return nextQueryString;
};

interface Props {
    showDirectLabel?: boolean;
}

export const ClassificationsIndustriesTabDrawer: FC<Props> = ({ showDirectLabel = false }) => {
    const { search: queryString } = useLocation();
    const { isSeatAdmin } = useUserAccess();

    const drawerParam = useMemo(() => uri.getSearchParam(queryString, "drawer"), [queryString]);
    const industryIdQueryParam = useMemo(() => uri.getSearchParam(queryString, "industryId"), [queryString]);
    const { industryId: industryIdParam } = useParams<{ industryId?: string }>();
    const industryId = industryIdQueryParam || industryIdParam;

    const history = useHistory();
    const onClose = () => {
        const nextQueryString = removeIndustryDrawerParamsFromSearch(queryString);
        history.push({ search: nextQueryString });
    };

    const {
        data: industry,
        isLoading,
        error: labelError,
    } = useGetIndustryDetailsQuery(Number(industryId), { skip: !drawerParam || !industryId });

    useNotificationError(labelError);

    return (
        <ResizableDrawer
            open={Boolean(drawerParam && ["details", "definitions", "history"].includes(drawerParam))}
            onClose={onClose}
            placement="right"
            title={
                <>
                    {drawerParam === "details" && <ClassificationsIndustryDetailsDrawerTitle industry={industry} />}
                    {drawerParam === "definitions" && (
                        <HelperKeysDrawerBreadcrumbHeader
                            parentLabel={industry?.name}
                            childLabel="Industry Definitions"
                        />
                    )}
                    {drawerParam === "history" && isSeatAdmin && (
                        <ClassificationsIndustryChangeHistoryDrawerTitle
                            industry={industry}
                            showDirectLabel={showDirectLabel}
                        />
                    )}
                </>
            }
            className={drawerRightClosePosition}
            width={drawerParam === "history" ? "70%" : "45%"}
        >
            {isLoading && <Loading />}
            {drawerParam === "details" && <ClassificationsIndustryDetailsContent industry={industry} />}
            {drawerParam === "definitions" && <ClassificationsIndustryDefinitionsContent />}
            {drawerParam === "history" && isSeatAdmin && industry && (
                <ChangeHistoryTable
                    entityId={industry.id}
                    entityType={ChangeLogEntityType.Industry}
                    sdet="classifications-industry-change-history-table"
                />
            )}
        </ResizableDrawer>
    );
};
