import { ROUTES } from "@app/core/routing";
import { Route, Switch } from "react-router-dom";
import InventoryFloorsPublisherPage from "../InventoryFloorsPublisherPage";
import InventoryFloorsPublisherBrandPage from "../InventoryFloorsPublisherBrandPage";
import { SeatFloorsTable } from "../SeatFloorsTable";
import InventoryFloorsSupplyFloorsPage from "../InventoryFloorsSupplyFloorsPage";
import InventoryFloorsAdUnitFloorsPage from "../InventoryFloorsAdUnitFloorsPage";

export const InventoryFloorHierarchicalPageSwitch = () => {
    return (
        <Switch>
            <Route exact path={ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL}>
                <SeatFloorsTable />
            </Route>
            <Route exact path={ROUTES.SEAT_INVENTORY_FLOOR_PUBLISHER}>
                <InventoryFloorsPublisherPage />
            </Route>
            <Route exact path={ROUTES.SEAT_INVENTORY_FLOOR_PUBLISHER_BRAND}>
                <InventoryFloorsPublisherBrandPage />
            </Route>
            <Route exact path={ROUTES.SEAT_INVENTORY_SUPPLY_FLOORS}>
                <InventoryFloorsSupplyFloorsPage />
            </Route>
            <Route exact path={ROUTES.SEAT_INVENTORY_AD_UNITS_FLOORS}>
                <InventoryFloorsAdUnitFloorsPage />
            </Route>
        </Switch>
    );
};
