import { useUserAccess } from "@app/core/auth";
import { DefinationField } from "@app/core/components/HelpKeysDrawer";
import {
    brandChannelHelpKeys,
    BrandChannelTitles,
    SeatHelpKeys,
} from "@app/features/inventory/InventorySeat/constants";
import { filterDefinitionsFieldsByHelpPathKeys } from "@app/features/inventory/helpers";

interface UseBrandChannelFormDefinitionsFields {
    generalDefinitionsFields: DefinationField[];
    defaultsDefinitionsFields: DefinationField[];
    adPodDefinitionsFields: DefinationField[];
    playlistConfigurationDefinitionsFields: DefinationField[];
    brandSafetyDefinitionsFields: DefinationField[];
    detailConfigurationDefinitionsFields: DefinationField[];
    customPixelsDefinitionsFields: DefinationField[];
    labelsDefinitionsFields: DefinationField[];
    floorsDefinitionsFields: DefinationField[];
}

const DETAILED_CONFIGURATION_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS: string[] = [SeatHelpKeys.SupplyEnableNoAdCookieSync];

const LABELS_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS: string[] = [SeatHelpKeys.GlobalLabels];
export const useBrandChannelFormDefinitionsFields = (): UseBrandChannelFormDefinitionsFields => {
    const { hasInternalAccess, isTremorUser } = useUserAccess();

    const generalDefinitionsFields = brandChannelHelpKeys[BrandChannelTitles.General];
    const defaultsDefinitionsFields = brandChannelHelpKeys[BrandChannelTitles.Defaults];
    const adPodDefinitionsFields = brandChannelHelpKeys[BrandChannelTitles.AdPod];
    const playlistConfigurationDefinitionsFields = brandChannelHelpKeys[BrandChannelTitles.PlaylistConfiguration];
    const brandSafetyDefinitionsFields = brandChannelHelpKeys[BrandChannelTitles.BrandSafety];

    const detailConfigurationDefinitionsFields: DefinationField[] = isTremorUser
        ? brandChannelHelpKeys[BrandChannelTitles.DetailConfiguration]
        : filterDefinitionsFieldsByHelpPathKeys(
              brandChannelHelpKeys[BrandChannelTitles.DetailConfiguration],
              DETAILED_CONFIGURATION_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS
          );
    const customPixelsDefinitionsFields = brandChannelHelpKeys[BrandChannelTitles.CustomPixels];
    const labelsDefinitionsFields = hasInternalAccess
        ? brandChannelHelpKeys[BrandChannelTitles.Labels]
        : filterDefinitionsFieldsByHelpPathKeys(
              brandChannelHelpKeys[BrandChannelTitles.Labels],
              LABELS_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS
          );

    const floorsDefinitionsFields = brandChannelHelpKeys[BrandChannelTitles.Floors];

    return {
        generalDefinitionsFields,
        defaultsDefinitionsFields,
        adPodDefinitionsFields,
        playlistConfigurationDefinitionsFields,
        detailConfigurationDefinitionsFields,
        brandSafetyDefinitionsFields,
        customPixelsDefinitionsFields,
        labelsDefinitionsFields,
        floorsDefinitionsFields,
    };
};
