import { FC, useState } from "react";
import { Col, Row, Space, Typography } from "antd";
import { GeoTarget } from "@app/core/services/console";
import { Conditions, TargetingDimensionTypeLabels } from "../../../constants";
import { GeoTargetsTargetingDimension, UsedDimensions } from "../../../types";
import { ConditionSelect } from "../../ConditionSelect";
import { MoreMenu } from "../../MoreMenu";
import { BulkPostalCodeDrawerButton } from "./BulkPostalCodeDrawerButton";
import { DimensionsSelect } from "./DimensionsSelect";
import { TYPE_FILTER_ALL } from "./constants";
import { TypeFilterSelect } from "./TypeFilterSelect";
import { LabeledValue } from "antd/lib/select";

interface Props {
    dimension: GeoTargetsTargetingDimension;
    onChangeCondition: (value: Conditions) => void;
    onChangeDimensionValues: (value: GeoTarget[]) => void;
    onClickDelete: () => void;
    onClickDownload: () => void;
    onClickDuplicate: () => void;
    usedDimensions: UsedDimensions;
}

export const GeoTargetsDimensionRow: FC<Props> = ({
    dimension,
    onChangeCondition,
    onChangeDimensionValues,
    onClickDelete,
    onClickDownload,
    onClickDuplicate,
    usedDimensions,
}) => {
    const [filter, setFilter] = useState<LabeledValue>(TYPE_FILTER_ALL);
    return (
        <Row gutter={8}>
            <Col flex="150px">
                <Typography.Text strong>{TargetingDimensionTypeLabels.GeoTargets}</Typography.Text>
            </Col>
            <Col flex="128px">
                <ConditionSelect
                    onChange={onChangeCondition}
                    isExcludesDisabled={
                        dimension.condition !== Conditions.Excludes && usedDimensions.geoTargets.excludes
                    }
                    isIncludesDisabled={
                        dimension.condition !== Conditions.Includes && usedDimensions.geoTargets.includes
                    }
                    value={dimension.condition}
                />
            </Col>
            <Col flex="128px">
                <TypeFilterSelect onChange={setFilter} value={filter} />
            </Col>
            <Col flex="1">
                <DimensionsSelect filter={filter} onChange={onChangeDimensionValues} value={dimension.values} />
            </Col>
            <Col flex="146px" style={{ display: "flex", justifyContent: "flex-end" }}>
                <Space>
                    <BulkPostalCodeDrawerButton onChange={onChangeDimensionValues} values={dimension.values} />
                    <MoreMenu
                        onClickDelete={onClickDelete}
                        onClickDownload={onClickDownload}
                        onClickDuplicate={onClickDuplicate}
                        isDuplicateDisabled={usedDimensions.geoTargets.includes && usedDimensions.geoTargets.excludes}
                    />
                </Space>
            </Col>
        </Row>
    );
};
