import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useSeatAuthContext } from "@app/core/auth";
import {
    clearFilters,
    selectSeatAdSourcesFilterCount,
    SeatAdSourcesListState,
    selectSeatAdSourcesFilters,
    selectSeatAdSourcesListTableViewPagination,
    selectSeatAdSourcesSearchFilter,
    selectSeatAdSourcesTableViewSort,
} from "@app/features/seatAdSources/SeatAdSourcesListPage/reducer";
import { UseSeatAdSourcesListFilters } from "./types";
import { LabeledValue } from "antd/lib/select";
import { skipToken } from "@reduxjs/toolkit/query";
import { Seat } from "@app/core/services/console";

const contentFilterOptions: Array<string> = [
    "Series",
    "Season",
    "Episode",
    "Video Title / ID",
    "Content Length",
    "Content Rating",
    "Producer",
    "Genre",
    "Content Network",
    "Content Channel",
    "Content Categories",
];
const customFilterOptions: Array<string> = ["C1", "C2", "C3", "C4"];
const DMP_TARGETING = "DMP Targeting";

export const getFilter = (filter: LabeledValue[] | null) => {
    if (filter && filter.length > 0) {
        return filter.map((filter) => String(filter.value));
    }
};

export const getDmpTargetingFilter = (filter: LabeledValue[] | null) => {
    if (filter && filter.length > 0) {
        const dmpFilterValue = filter
            .filter((element) => String(element.label) === DMP_TARGETING)
            .map((filter) => String(filter.value));
        if (dmpFilterValue.includes("true")) {
            return true;
        }
    }
};

export const getCustomOptionsTargetingFilter = (filter: LabeledValue[] | null, options: Array<string>) => {
    if (filter && filter.length > 0) {
        const filteredResults = filter
            .filter((element) => options.includes(String(element.label)))
            .map((filter) => String(filter.value));
        if (filteredResults && filteredResults.length > 0) {
            return filteredResults;
        }
    }
};

export const getSeatAllSeatAdSourcesParams = (
    context: Seat | null,
    filters: SeatAdSourcesListState["filters"],
    pagination: SeatAdSourcesListState["tableView"]["pagination"],
    search: SeatAdSourcesListState["searchFilter"],
    sort: SeatAdSourcesListState["tableView"]["sort"]
) => {
    if (!context) {
        return skipToken;
    }

    return {
        max: pagination.max,
        page: pagination.page,
        seatId: context.id,
        adSourceType: getFilter(filters.seatAdSourcesType),
        labelKeyId: getFilter(filters.seatAdSourcesLabelKey),
        labelValueId: getFilter(filters.seatAdSourcesLabelValue),
        priority: getFilter(filters.seatAdSourcesPriority),
        region: getFilter(filters.seatAdSourcesRegion),
        statusIds: getFilter(filters.seatAdSourcesStatus),
        dmpFilter: getDmpTargetingFilter(filters.seatAdSourcesTargeting),
        contentFilter: getCustomOptionsTargetingFilter(filters.seatAdSourcesTargeting, contentFilterOptions),
        customTarget: getCustomOptionsTargetingFilter(filters.seatAdSourcesTargeting, customFilterOptions),
        userId: getFilter(filters.seatAdSourcesAssignee),
        keyword: search.keyword,
        orderBy: sort.orderBy,
        asc: sort.asc,
    };
};

export const useSeatAdSourcesListFilters = (): UseSeatAdSourcesListFilters => {
    const dispatch = useAppDispatch();
    const seatAdSourcesFilterCount = useAppSelector(selectSeatAdSourcesFilterCount);
    const { context } = useSeatAuthContext();
    const seatAdSourcesFilters = useAppSelector(selectSeatAdSourcesFilters);
    const seatAdSourcesListPagination = useAppSelector(selectSeatAdSourcesListTableViewPagination);
    const seatAdSourcesFilterSearch = useAppSelector(selectSeatAdSourcesSearchFilter);
    const seatAdSourcesSort = useAppSelector(selectSeatAdSourcesTableViewSort);

    const handleClearFilters = () => {
        dispatch(clearFilters());
    };

    return {
        filterCount: seatAdSourcesFilterCount,
        handleClearFilters,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        seatAllAdSourcesParams: getSeatAllSeatAdSourcesParams(
            context,
            seatAdSourcesFilters,
            seatAdSourcesListPagination,
            seatAdSourcesFilterSearch,
            seatAdSourcesSort
        ),
    };
};
