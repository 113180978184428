import { FC } from "react";
import { Form, Select } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useDealStatusField } from "@app/features/deals/DealForm/DealDemandFormSections/DealTermsSection/Fields/DealStatusField/useDealStatusField";

export const DealStatusField: FC = () => {
    const { options, isFetching, value, handleSelect } = useDealStatusField();
    return (
        <Form.Item data-sdet="deal-status-field" label="Status" name={CREATE_DEAL_FORM_ITEMS_NAME.STATUS}>
            <Select
                value={value}
                onSelect={handleSelect}
                options={options}
                placeholder="Select status"
                loading={isFetching}
            />
        </Form.Item>
    );
};
