import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { Form, Input } from "antd";
import { useDomainNameOverride } from "./useDomainNameOverride";

export const DomainNameOverrideInput = () => {
    const { isVisible } = useDomainNameOverride();

    return isVisible ? (
        <Form.Item
            name={AD_SOURCE_FIELDS.DOMAIN_NAME_OVERRIDE.name}
            label={AD_SOURCE_FIELDS.DOMAIN_NAME_OVERRIDE.label}
            labelCol={{ lg: 8 }}
            labelAlign="left"
            colon={false}
            style={{ marginBottom: 8 }}
            rules={[{ required: true, whitespace: true, message: "Domain Name Override is required" }]}
        >
            <Input />
        </Form.Item>
    ) : null;
};
