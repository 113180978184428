import { FC } from "react";
import { Form, Select } from "antd";
import { PUBLISHER_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useAccountType } from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormSections/GeneralSection/Fields/GeneralInternalOnly/Fields/AccountType/useAccountType";

export const AccountType: FC = () => {
    const { isFetching, options, extra } = useAccountType();
    return (
        <Form.Item
            extra={extra}
            name={PUBLISHER_FORM_FIELDS.ACCOUNT_TYPE.name}
            label={PUBLISHER_FORM_FIELDS.ACCOUNT_TYPE.label}
            wrapperCol={{ xs: 24, sm: 24, md: 10, lg: 9, xl: 12, xxl: 11 }}
        >
            <Select
                showSearch
                allowClear
                labelInValue
                options={options}
                loading={isFetching}
                optionFilterProp="label"
                style={{ width: "100%" }}
                placeholder={PUBLISHER_FORM_FIELDS.ACCOUNT_TYPE.placeholder}
            />
        </Form.Item>
    );
};
