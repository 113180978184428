import { useContext, useEffect, useState } from "react";
import { Form } from "antd";
import { AdSourceFormsContext, AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { AD_SOURCE_FIELDS, AD_SOURCE_TYPES_NEXT_UI_NAMES, AdSourceTypes } from "@app/features/seatAdSources/constants";
import { minEndDateValidator, minGroupEndDateValidator } from "./validators";
import { SeatAdSourcesType } from "@app/core/services";
import { Rule } from "antd/lib/form";
import { useSyncedFieldsProducer } from "@app/features/syncedFields";
import moment from "moment-timezone";

const { useFormInstance, useWatch } = Form;

export const useEndDateField = () => {
    const { setFieldsValue } = useFormInstance<AdSourcesForm>();
    const { updateSyncedFields } = useSyncedFieldsProducer();
    const startDate = useWatch<AdSourcesForm[typeof AD_SOURCE_FIELDS.START_DATE.name]>(
        AD_SOURCE_FIELDS.START_DATE.name
    );
    const { forms } = useContext(AdSourceFormsContext);
    const { adSourceForm } = forms;

    const endDate = useWatch<AdSourcesForm[typeof AD_SOURCE_FIELDS.END_DATE.name]>(AD_SOURCE_FIELDS.END_DATE.name);

    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);
    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSourceType?.name] ?? adSourceType?.name;

    const isNoEndDateButtonVisible =
        adSourceTypeName !== AdSourceTypes.PROGRAMATIC_GUARANTEED &&
        adSourceTypeName !== AdSourceTypes.CONDITIONAL_PROGRAMATIC_GUARANTEED &&
        adSourceTypeName !== AdSourceTypes.SERVER_SIDE_TAG_GUARANTEED &&
        adSourceTypeName !== AdSourceTypes.CLIENT_SIDE_TAG_GUARANTEED;

    const [isFieldDisabled, setIsFieldDisabled] = useState(isNoEndDateButtonVisible);
    const onClick = (): void => {
        setIsFieldDisabled((noEndDate) => !noEndDate);
        adSourceForm.validateFields([AD_SOURCE_FIELDS.END_DATE.name]);
        if (endDate) {
            setFieldsValue({ [AD_SOURCE_FIELDS.END_DATE.name]: null });
            updateSyncedFields({ endDate: null });
        }
    };

    const isRulesDisabled = isNoEndDateButtonVisible ? isFieldDisabled : isNoEndDateButtonVisible;

    const rules: Rule[] | undefined = isRulesDisabled
        ? undefined
        : [
              {
                  required: !isNoEndDateButtonVisible || !isFieldDisabled,
                  message: `${AD_SOURCE_FIELDS.END_DATE.label} is required`,
              },
              {
                  validator: minEndDateValidator(startDate),
              },
              {
                  validator: minGroupEndDateValidator(adSourceForm),
              },
          ];

    useEffect(() => {
        if (!isNoEndDateButtonVisible || endDate) {
            setIsFieldDisabled(false);
        }
        if (!endDate) {
            setIsFieldDisabled(true);
        }
    }, [isNoEndDateButtonVisible, endDate]);

    const onChange = (endDate: moment.Moment | null) => {
        updateSyncedFields({ endDate });
    };

    return {
        isNoEndDateButtonVisible,
        onClick,
        isFieldDisabled,
        rules,
        onChange,
    };
};
