import { FC } from "react";
import { useAppSelector } from "@app/core/store";
import { AdStat, getImpressions, getOtimesForThrottleUnit } from "@app/features/adStats";
import { useDealCurrencyConversion } from "@app/features/deals/useDealCurrencyConversion";
import { selectAdStatDealById } from "@app/features/adStats/reducer";
import { useThrottle } from "@app/core/utils";
import { LoadableAnimatedNumberCell } from "@app/core/components";

interface DealHealthTableImpressionsCellProps {
    dealId: number;
    sourceCurrencyCode: string | undefined;
}

const DealHealthTableImpressionsCell: FC<DealHealthTableImpressionsCellProps> = ({ dealId, sourceCurrencyCode }) => {
    const isConnectedToLiveAdStats = useAppSelector((state) => state.adStats.isConnected);
    const { preferredCurrency, currencyConversionMode, targetCurrencyCode } =
        useDealCurrencyConversion(sourceCurrencyCode);

    const { impressions, otime } = useAppSelector((state) => {
        const adStat: AdStat | null = selectAdStatDealById(state, dealId);
        return adStat
            ? {
                  impressions: getImpressions(adStat, preferredCurrency, currencyConversionMode),
                  otime: getOtimesForThrottleUnit(adStat),
              }
            : { impressions: undefined, otime: undefined };
    });

    const throttledImpressions = useThrottle<number | undefined>(impressions, {
        forceUpdateDependencies: [
            otime,
            sourceCurrencyCode,
            preferredCurrency,
            targetCurrencyCode,
            currencyConversionMode,
        ],
    });

    return <LoadableAnimatedNumberCell isLoading={!isConnectedToLiveAdStats} value={throttledImpressions} />;
};

export default DealHealthTableImpressionsCell;
