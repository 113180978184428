import { Card } from "antd";
import { INVENTORY_CARD_BODY_STYLE } from "../../constants";

export const InternalOnly = ({ children }) => {
    return (
        <Card title="Internal Only" type="inner" styles={{ body: INVENTORY_CARD_BODY_STYLE }}>
            {children}
        </Card>
    );
};
