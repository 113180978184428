import { SeatGrantsTableRecord } from "../InventoryPermissionsTable";
import { useState } from "react";

export const useRemoveConfirmationModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [permission, setPermission] = useState<SeatGrantsTableRecord | null>(null);

    const open = (record: SeatGrantsTableRecord) => {
        setIsOpen(true);
        setPermission(record);
    };

    const close = () => {
        setIsOpen(false);
        setPermission(null);
    };

    return { isOpen, open, close, permission };
};
