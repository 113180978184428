const openAuctionType = 4;
const marketplaceType = 999; // TODO no currently exist marketplace type https://magnite.atlassian.net/browse/PL-10562
export const notExtendableTypes = [openAuctionType, marketplaceType];

export const DRAWER = { key: "drawer", default: "" };

export interface LV {
    label: string;
    value: string | number;
}

export const STATIC_OPTIONS = [
    {
        label: "Shared",
        value: "shared",
    },
    {
        label: "Hidden",
        value: "hidden",
    },
];

export const USER_ID_IFA_OPTIONS = [
    ...STATIC_OPTIONS,
    {
        label: "Masked",
        value: "masked",
    },
];

export type ExtendedUserSelectValueType = "all" | "custom" | "none";

export const SPECIFIC = "custom" as ExtendedUserSelectValueType;

export const EXTENDED_USER_ID_OPTIONS = [
    {
        label: "All",
        value: "all", // true
    },
    {
        label: "Specific",
        value: SPECIFIC,
    },
    {
        label: "None",
        value: "none", // false
    },
];
