import { AdSourceTypeIds } from "../constants";
import { AdSourceServingStat, AdSourceServingStatResult, AdSourceStat } from "./types";

export const adSourceStatContainsData = (stat: AdSourceStat): boolean => {
    const { nTime, oTime, isAtRisk, currencyCode, ...numericStats } = stat;

    return Object.values(numericStats).some((numericStat) => typeof numericStat === "number" && numericStat > 0);
};

export const getDiffInSeconds = (nTimeDiff: number): number => nTimeDiff / 1000;

export const diffAdSourceStats = (nextStat: AdSourceStat, lastStat: AdSourceStat): AdSourceStat => ({
    errors: nextStat.errors - lastStat.errors,
    fallbacks: nextStat.fallbacks - lastStat.fallbacks,
    fills: nextStat.fills - lastStat.fills,
    impressions: nextStat.impressions - lastStat.impressions,
    isAtRisk: nextStat.isAtRisk,
    netRevenue: nextStat.netRevenue - lastStat.netRevenue,
    nTime: nextStat.nTime - lastStat.nTime,
    oTime: nextStat.oTime - lastStat.oTime,
    skips: nextStat.skips - lastStat.skips,
    time: nextStat.time - lastStat.time,
    tries: nextStat.tries - lastStat.tries,
    currencyCode: nextStat.currencyCode,
    getTagRevenue: (adSourceTypeId: number) =>
        nextStat.getTagRevenue(adSourceTypeId) - lastStat.getTagRevenue(adSourceTypeId),
});

export const scaleAdSourceStat = (
    stat: AdSourceStat,
    scale: number,
    nTimeOverride: number,
    oTimeOverride: number
): AdSourceStat => ({
    nTime: nTimeOverride,
    oTime: oTimeOverride,
    errors: stat.errors / scale,
    fallbacks: stat.fallbacks / scale,
    fills: stat.fills / scale,
    impressions: stat.impressions / scale,
    isAtRisk: stat.isAtRisk,
    netRevenue: stat.netRevenue / scale,
    skips: stat.skips / scale,
    time: stat.time / scale,
    tries: stat.tries / scale,
    currencyCode: stat.currencyCode,
    getTagRevenue: (adSourceTypeId: number) => stat.getTagRevenue(adSourceTypeId) / scale,
});

interface TagOrNetRevenue {
    name: "Tag Revenue" | "Net Revenue";
    value: number;
}

export const getTagOrNetRevenue = (stat: AdSourceStat | undefined, adSourceTypeId: number): TagOrNetRevenue => {
    if (
        [
            AdSourceTypeIds.SERVER_SIDE_DYNAMIC_PRICE,
            AdSourceTypeIds.FALLBACK_TAG,
            AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED,
            AdSourceTypeIds.SERVER_SIDE_TAG_NON_GUARANTEED,
            AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED,
            AdSourceTypeIds.SERVER_SIDE_DYNAMIC_PRICE,
            AdSourceTypeIds.PRE_BID_AD_SOURCE,
        ].includes(adSourceTypeId)
    ) {
        return {
            name: "Tag Revenue",
            value: stat?.getTagRevenue(adSourceTypeId) || 0,
        };
    }

    return {
        name: "Net Revenue",
        value: stat?.netRevenue || 0,
    };
};

export const scaleAdSourceStats = (stats: AdSourceStat[] = [], scale: number): AdSourceStat[] => {
    return stats?.map((stat) => scaleAdSourceStat(stat, scale, stat.nTime, stat.oTime));
};

export const diffAdSourceServingStats = (
    nextStat: AdSourceServingStat,
    lastStat: AdSourceServingStat
): AdSourceServingStat => {
    const resultsDiff: AdSourceServingStatResult[] = [];
    nextStat.results?.forEach((nextResult) => {
        const nextResultCopy = Object.assign({}, nextResult);
        const eventDiff = lastStat?.results.reduce(
            (lastResultEvents, lastResult) =>
                nextResultCopy.code === lastResult.code ? nextResultCopy.events - lastResult.events : lastResultEvents,
            0
        );
        nextResultCopy.events = eventDiff;
        resultsDiff.push(nextResultCopy);
    });

    return {
        nTime: nextStat.nTime - lastStat.nTime,
        oTime: nextStat.oTime - lastStat.oTime,
        opportunities: nextStat.opportunities - lastStat.opportunities,
        floors: nextStat.floors - lastStat.floors,
        frequencyCapping: nextStat.frequencyCapping - lastStat.frequencyCapping,
        pacing: nextStat.pacing - lastStat.pacing,
        chosen: nextStat.chosen - lastStat.chosen,
        bidSuccess: getPossiblyNullDiff(nextStat.bidSuccess, lastStat.bidSuccess),
        creativeSuccess: getPossiblyNullDiff(nextStat.creativeSuccess, lastStat.creativeSuccess),
        results: resultsDiff,
    };
};

export const scaleAdSourceServingStat = (
    stat: AdSourceServingStat,
    scale: number,
    nTimeOverride: number,
    oTimeOverride: number
): AdSourceServingStat => ({
    nTime: nTimeOverride,
    oTime: oTimeOverride,
    opportunities: stat.opportunities / scale,
    floors: stat.floors / scale,
    frequencyCapping: stat.frequencyCapping / scale,
    pacing: stat.pacing / scale,
    chosen: stat.chosen / scale,
    bidSuccess: stat.bidSuccess === null ? null : stat.bidSuccess / scale,
    creativeSuccess: stat.creativeSuccess === null ? null : stat.creativeSuccess / scale,
    results: stat.results.map((result) => ({
        ...result,
        events: result.events / scale,
    })),
});

export const adSourceServingStatContainsData = (stat: AdSourceServingStat): boolean => {
    const { nTime, oTime, results, ...numericStats } = stat;

    return Object.values(numericStats).some((numericStat) => typeof numericStat === "number" && numericStat > 0);
};

export const getPossiblyNullDiff = (a: number | null, b: number | null): number | null => {
    if (typeof a === "number" && typeof b === "number") {
        return a - b;
    }
    if (typeof a === "number") {
        return a;
    }
    if (typeof b === "number") {
        return -b;
    }
    return null;
};
