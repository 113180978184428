import { FC } from "react";
import { Col, Layout, Row, Typography } from "antd";
import { LoginForm } from "./LoginForm";
import { css } from "@emotion/css";
import { brandAmethyst, gray1, gray5 } from "@rubicon/antd-components";
import { Footer } from "@app/core/components";

const classList = css`
    background-image: url(https://apps.magnite.com/assets/images/brand/bg-element-amethyst-streaming-min.jpg);
    background-color: ${brandAmethyst};
    background-repeat: no-repeat;
    background-size: cover;

    .login-page-wrapper {
        max-width: 560px;
        margin: 10vh auto 0;
    }
    .login-content {
        padding: 0;
    }
    .login-header {
        min-height: 212px;
        background-color: #222;
        padding: 2rem 2rem 1.2rem;
        width: 100%;
        border-radius: 4px 4px 0 0;
    }
    .login-header-wrapper {
        position: relative;
        margin: 0 auto;
    }
    .login-logo {
        display: block;
        fill: #fff;
    }
    .login-typography {
        position: absolute;
        font-size: 0.7rem;
        line-height: 1;
        color: #fff;
    }
    .login-typography--telaria {
        right: 0;
        bottom: 18px;
    }
    .login-typography--ctv {
        bottom: -145px;
        left: 240px;
        font-size: 2.5rem;
    }
    .form-wrapper {
        background: white;
        padding: 0.9rem 2rem 1rem;
        border: 0.063rem solid ${gray5};
        a:hover {
            text-decoration: underline;
        }
        border-radius: 0 0 4px 4px;
    }
    #magnite-logo {
        width: 342px;
        position: absolute;
        top: 28px;
        left: 48px;
    }
    footer {
        background: none;
        .ant-divider {
            border-left: 0.063rem solid ${gray1};
        }
        a,
        .footer {
            color: ${gray1};
            font-size: 0.75rem;
            line-height: 1.3333333333;
            text-decoration: none;
        }
        a:hover {
            text-decoration: underline;
        }
    }
`;

export const LoginPage: FC = () => {
    return (
        <Layout className={classList}>
            <Layout.Content className="login-content">
                <Row className="login-page-wrapper">
                    <Col span={24}>
                        <header className="login-header">
                            <div className="login-header-wrapper">
                                <svg
                                    id="magnite-logo"
                                    viewBox="0 0 1293 419"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-labelledby="magnite-logo-title"
                                    focusable="false"
                                    role="img"
                                    data-sdet="login:logo"
                                    className="login-logo"
                                >
                                    <title id="login-logo-title">Magnite logo</title>
                                    <path
                                        fillRule="nonzero"
                                        d="M39.4 270.3v-253l62.6 253h64.9l63-254.9v254.9H270V0h-75.7l-59.5 244.9L75.3 0H0v270.3h39.4zm348.7 2c25.1 0 47.9-10 64.9-26.6v24.7h37.8V77.2H453V102c-17-16.6-39.8-26.6-64.9-26.6-52.5 0-95 44-95 98.5 0 54 42.5 98.4 95 98.4zm3.9-35.6c-34 0-61-28.2-61-63s27-63 61-63 61 28.2 61 63-27 63-61 63zM606.7 75.3c-53.7 0-92.7 40.9-92.7 94.2 0 39 23.6 70.3 49 82.6 39 18.9 58.3 3.9 85.4 26.6 13.9 11.6 20.1 27.8 20.1 45.6 0 32.4-25.9 58.3-57.9 58.3-32.8 0-58.7-25.9-58.7-59.9 0-18.5 6.6-34 14.3-44.4h-40.6c-7.7 13.1-11.6 29.4-11.6 46 0 51.8 42.9 94.2 96.2 94.2 53.3 0 96.2-42.5 96.2-94.2 0-32.1-15.8-59.9-40.6-76.9 24.7-16.6 41.3-44.4 40.9-76.5V77.2H670v23.6c-16.6-15.8-38.6-25.5-63.3-25.5zm3.9 153.3c-32.8 0-58.7-26.3-58.7-59.1s25.9-59.5 58.7-59.5c32.8 0 58.7 26.6 58.7 59.5s-25.9 59.1-58.7 59.1zm161 41.7V161.4c0-29 22-51 49.4-51s49.4 22 49.4 51v108.9h37.8V159.5c0-50.6-33.2-84.2-79.6-84.2-22.4 0-42.9 10.4-57.2 27.4V77.2h-37.8v193.1h38zm202-226.7V0H935v43.6h38.6zm-.4 226.7V77.2h-37.8v193.1h37.8zm88.1 0V111.2h35.1v-34h-35.1V0h-37.8v77.2h-35.1v34h35.1v159.1h37.8zm185.3-60.2c-12.4 19.7-28.6 26.6-49.4 26.6-29 0-52.9-20.5-59.1-47.9h154.1v-15.1c0-54.8-43.3-98.9-96.9-98.9-53.3 0-96.9 44-96.9 98.9 0 54.5 44.4 98.9 98.9 98.9 41.7 0 76.9-25.5 91.9-62.6h-42.6v.1zm-51.3-99.3c27.4 0 49.8 19.3 56.8 46h-113.5c6.9-26.7 29.3-46 56.7-46z"
                                    ></path>
                                </svg>
                                <Typography.Text className="login-typography login-typography--ctv">
                                    STREAMING
                                </Typography.Text>
                            </div>
                        </header>
                    </Col>
                    <Col span={24} className="form-wrapper">
                        <LoginForm />
                    </Col>
                </Row>
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </Layout>
    );
};
