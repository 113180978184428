import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { PriceOverrideForm } from "./PriceOverrideForm";
import { PriceOverrideDefinitionsButtonAndDrawer } from "./PriceOverridesPage/PriceOverrideDefinitions";
import { PageHeader } from "@ant-design/pro-layout";

export const PriceOverrideCreatePage = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();

    return (
        <>
            <PageHeader
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_PRICE_OVERRIDES(seatId))}
                title="Add Price Override"
                extra={<PriceOverrideDefinitionsButtonAndDrawer />}
            />
            <PriceOverrideForm />
        </>
    );
};
