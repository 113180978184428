import { FC } from "react";
import { LoadableComponent } from "@app/core/components";
import { LineChart } from "@app/features/seatAdSources/seatAdSourcesCharts/charts";
import { useSeatAdSourcesLiveOpportunityChart } from "./useSeatAdSourcesLiveOpportunityChart";

interface Props {
    adSourceId: number;
}

export const SeatAdSourcesLiveOpportunityChart: FC<Props> = ({ adSourceId }) => {
    const { loadingState, lineChartProps } = useSeatAdSourcesLiveOpportunityChart(adSourceId);

    return (
        <LoadableComponent loadingState={loadingState}>
            {lineChartProps && <LineChart {...lineChartProps} />}
        </LoadableComponent>
    );
};
