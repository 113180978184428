import { AdSource } from "@app/core/services";
import { AD_SOURCE_FIELDS } from "../../constants";
import { AdSourcesForm } from "../SeatAdSourcesForm";
import { parseBookingPriceFromApi } from "./parseBookingPrice";
import { parseCostFixedFromApi } from "./parseCostFixedFromApi";
import { parseRegionSelectFromApi } from "./parseRegionSelect";
import { parseEnrichmentCostFieldsFromApi } from "./useParseEnrichmentCostFields";
import { format } from "@rubicon/utils";
import { DEFAULT_TIME_ZONE } from "@app/core/components/constants";
import { formatCategoryLabel } from "@app/features/targeting";
import { TargetingDimensionTypeLabels } from "@app/features/targeting/constants";
import { parseDateStringFromApi } from "@app/core/utils";
import { parsePacingFromApi } from "./pacingUtils";

const { SHARED, HIDDEN } = format.constants;

export const parseAdSourceFromApiToForm = (adSourceOrigin: AdSource): Partial<AdSourcesForm> => {
    const timeZone = adSourceOrigin[AD_SOURCE_FIELDS.TIME_ZONE.name] || DEFAULT_TIME_ZONE;
    const { demandAcquisitionCost, demandAcquisitionCostModel } = parseEnrichmentCostFieldsFromApi(adSourceOrigin);

    const adSourceFormFields = {
        [AD_SOURCE_FIELDS.TYPE.name]: adSourceOrigin[AD_SOURCE_FIELDS.TYPE.name],
        [AD_SOURCE_FIELDS.NAME.name]: adSourceOrigin[AD_SOURCE_FIELDS.NAME.name],
        [AD_SOURCE_FIELDS.DESCRIPTION.name]: adSourceOrigin[AD_SOURCE_FIELDS.DESCRIPTION.name],
        [AD_SOURCE_FIELDS.PRIORITY.name]: adSourceOrigin[AD_SOURCE_FIELDS.PRIORITY.name],
        [AD_SOURCE_FIELDS.UD_PRIORITY.name]: adSourceOrigin[AD_SOURCE_FIELDS.UD_PRIORITY.name],
        [AD_SOURCE_FIELDS.ASSIGNEE.name]: adSourceOrigin[AD_SOURCE_FIELDS.ASSIGNEE.name],
        [AD_SOURCE_FIELDS.ASSOCIATED_MARKETPLACE.name]: adSourceOrigin[AD_SOURCE_FIELDS.ASSOCIATED_MARKETPLACE.name],
        [AD_SOURCE_FIELDS.ASSOCIATED_CURATOR_MARKETPLACES.name]: adSourceOrigin.curatorMarketplaceIds,
        [AD_SOURCE_FIELDS.STATUS.name]: adSourceOrigin[AD_SOURCE_FIELDS.STATUS.name],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [AD_SOURCE_FIELDS.EXTEND_TIMEOUT_VALUE.name]: adSourceOrigin[AD_SOURCE_FIELDS.EXTEND_TIMEOUT_VALUE.name],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [AD_SOURCE_FIELDS.TAG_URL.name]: adSourceOrigin[AD_SOURCE_FIELDS.TAG_URL.name],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [AD_SOURCE_FIELDS.ASSIGNED_ADVERTISER_DOMAIN.name]:
            adSourceOrigin[AD_SOURCE_FIELDS.ASSIGNED_ADVERTISER_DOMAIN.name],
        [AD_SOURCE_FIELDS.COST_MODEL.name]: adSourceOrigin[AD_SOURCE_FIELDS.COST_MODEL.name],
        [AD_SOURCE_FIELDS.COST_PERCENT.name]: adSourceOrigin[AD_SOURCE_FIELDS.COST_PERCENT.name],
        [AD_SOURCE_FIELDS.COST_FIXED.name]: parseCostFixedFromApi(adSourceOrigin[AD_SOURCE_FIELDS.COST_FIXED.name]),
        [AD_SOURCE_FIELDS.ALLOW_AUTOSCALE.name]: adSourceOrigin[AD_SOURCE_FIELDS.ALLOW_AUTOSCALE.name],
        [AD_SOURCE_FIELDS.REGION.name]: parseRegionSelectFromApi(adSourceOrigin[AD_SOURCE_FIELDS.REGION.name]),
        [AD_SOURCE_FIELDS.SALES_CONTACT.name]: adSourceOrigin[AD_SOURCE_FIELDS.SALES_CONTACT.name],
        [AD_SOURCE_FIELDS.EXTERNAL_CONTRACT_NUMBER.name]:
            adSourceOrigin[AD_SOURCE_FIELDS.EXTERNAL_CONTRACT_NUMBER.name],
        [AD_SOURCE_FIELDS.MODE.name]: adSourceOrigin[AD_SOURCE_FIELDS.MODE.name],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [AD_SOURCE_FIELDS.REUSABLE_ADVERTISER_DOMAINS.name]:
            adSourceOrigin[AD_SOURCE_FIELDS.REUSABLE_ADVERTISER_DOMAINS.name],
        [AD_SOURCE_FIELDS.ADDITIONAL_ADVERTISER_DOMAINS.name]:
            adSourceOrigin[AD_SOURCE_FIELDS.ADDITIONAL_ADVERTISER_DOMAINS.name].join("\n"),
        [AD_SOURCE_FIELDS.START_DATE.name]: adSourceOrigin[AD_SOURCE_FIELDS.START_DATE.name]
            ? parseDateStringFromApi(adSourceOrigin[AD_SOURCE_FIELDS.START_DATE.name], timeZone?.code)
            : null,
        [AD_SOURCE_FIELDS.END_DATE.name]: adSourceOrigin[AD_SOURCE_FIELDS.END_DATE.name]
            ? parseDateStringFromApi(adSourceOrigin[AD_SOURCE_FIELDS.END_DATE.name], timeZone?.code)
            : null,
        [AD_SOURCE_FIELDS.TIME_ZONE.name]: timeZone,
        [AD_SOURCE_FIELDS.MIN_DURATION.name]: adSourceOrigin[AD_SOURCE_FIELDS.MIN_DURATION.name],
        [AD_SOURCE_FIELDS.MAX_DURATION.name]: adSourceOrigin[AD_SOURCE_FIELDS.MAX_DURATION.name],
        [AD_SOURCE_FIELDS.INVENTORY_DISTRIBUTION_GROUPS.name]:
            adSourceOrigin[AD_SOURCE_FIELDS.INVENTORY_DISTRIBUTION_GROUPS.name],
        [AD_SOURCE_FIELDS.SHARE_OF_VOICE.name]:
            (adSourceOrigin[AD_SOURCE_FIELDS.SHARE_OF_VOICE_PERCENT.name] &&
                adSourceOrigin[AD_SOURCE_FIELDS.SHARE_OF_VOICE_PERCENT.name] >= 0) ||
            false,
        [AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.name]: adSourceOrigin[AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.name],
        [AD_SOURCE_FIELDS.SHARE_OF_VOICE_PERCENT.name]: adSourceOrigin[AD_SOURCE_FIELDS.SHARE_OF_VOICE_PERCENT.name],
        [AD_SOURCE_FIELDS.PRICE_MODEL.name]: adSourceOrigin[AD_SOURCE_FIELDS.PRICE_MODEL.name],
        [AD_SOURCE_FIELDS.FIXED_CPM.name]: parseBookingPriceFromApi(adSourceOrigin[AD_SOURCE_FIELDS.FIXED_CPM.name]),
        [AD_SOURCE_FIELDS.FLOOR_TYPE.name]: adSourceOrigin[AD_SOURCE_FIELDS.FLOOR_TYPE.name],
        [AD_SOURCE_FIELDS.CURRENCY.name]: adSourceOrigin[AD_SOURCE_FIELDS.CURRENCY.name],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [AD_SOURCE_FIELDS.ALWAYS_SEND_FLOORS.name]: adSourceOrigin[AD_SOURCE_FIELDS.ALWAYS_SEND_FLOORS.name],
        [AD_SOURCE_FIELDS.AUCTION_TYPE.name]: adSourceOrigin[AD_SOURCE_FIELDS.AUCTION_TYPE.name],
        [AD_SOURCE_FIELDS.BOOKING_BUDGET.name]: adSourceOrigin[AD_SOURCE_FIELDS.BOOKING_BUDGET.name],
        [AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_LOCATION.name]:
            adSourceOrigin[AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_LOCATION.name]?.id,
        [AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_MULTIPLIER.name]:
            adSourceOrigin[AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_MULTIPLIER.name],
        ...parsePacingFromApi(adSourceOrigin),
        [AD_SOURCE_FIELDS.PIXELS.name]: adSourceOrigin[AD_SOURCE_FIELDS.PIXELS.name],
        [AD_SOURCE_FIELDS.SUPPLY_NAME.name]: adSourceOrigin[AD_SOURCE_FIELDS.SUPPLY_NAME.name],
        [AD_SOURCE_FIELDS.DOMAIN_NAME.name]: adSourceOrigin[AD_SOURCE_FIELDS.DOMAIN_NAME_OVERRIDE.name]
            ? "override"
            : adSourceOrigin[AD_SOURCE_FIELDS.DOMAIN_NAME.name],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [AD_SOURCE_FIELDS.DOMAIN_NAME_OVERRIDE.name]: adSourceOrigin[AD_SOURCE_FIELDS.DOMAIN_NAME_OVERRIDE.name],
        [AD_SOURCE_FIELDS.ALLOW_AUDIENCE_LOCK.name]: adSourceOrigin[AD_SOURCE_FIELDS.ALLOW_AUDIENCE_LOCK.name],
        [AD_SOURCE_FIELDS.SUPPLY_PAGE.name]: adSourceOrigin[AD_SOURCE_FIELDS.SUPPLY_PAGE.name],
        [AD_SOURCE_FIELDS.REFER_URL.name]: adSourceOrigin[AD_SOURCE_FIELDS.REFER_URL.name],
        [AD_SOURCE_FIELDS.FULL_IP_ADDRESS.name]: adSourceOrigin[AD_SOURCE_FIELDS.FULL_IP_ADDRESS.name],
        [AD_SOURCE_FIELDS.BUNDLE_ID.name]: adSourceOrigin[AD_SOURCE_FIELDS.BUNDLE_ID_OVERRIDE.name]
            ? "override"
            : adSourceOrigin[AD_SOURCE_FIELDS.BUNDLE_ID.name],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [AD_SOURCE_FIELDS.BUNDLE_ID_OVERRIDE.name]: adSourceOrigin[AD_SOURCE_FIELDS.BUNDLE_ID_OVERRIDE.name],
        [AD_SOURCE_FIELDS.STORE_URL.name]: adSourceOrigin[AD_SOURCE_FIELDS.STORE_URL.name],
        [AD_SOURCE_FIELDS.USER_ID.name]: adSourceOrigin.maskUserId
            ? "masked"
            : adSourceOrigin[AD_SOURCE_FIELDS.USER_ID.name],
        [AD_SOURCE_FIELDS.BVOD_CONNECT_ID.name]: adSourceOrigin[AD_SOURCE_FIELDS.BVOD_CONNECT_ID.name],
        [AD_SOURCE_FIELDS.BVOD_CONNECT_DEMOGRAPHIC.name]:
            adSourceOrigin[AD_SOURCE_FIELDS.BVOD_CONNECT_DEMOGRAPHIC.name],
        [AD_SOURCE_FIELDS.CONTENT.name]: adSourceOrigin.contentTransparencyRule
            ? adSourceOrigin.contentTransparencyRule
            : adSourceOrigin[AD_SOURCE_FIELDS.CONTENT.name]
            ? { name: SHARED, value: true }
            : { name: HIDDEN, value: false },
        [AD_SOURCE_FIELDS.INCLUDED_EXTENDED_IDS.name]: adSourceOrigin[AD_SOURCE_FIELDS.EXTENDED_USER_ID.name].length
            ? "specific"
            : adSourceOrigin[AD_SOURCE_FIELDS.INCLUDED_EXTENDED_IDS.name],
        [AD_SOURCE_FIELDS.EXTENDED_USER_ID.name]: adSourceOrigin[AD_SOURCE_FIELDS.EXTENDED_USER_ID.name],
        [AD_SOURCE_FIELDS.WATERFALL_PRE_FILTER_MODE.name]:
            adSourceOrigin[AD_SOURCE_FIELDS.WATERFALL_PRE_FILTER_MODE.name],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [AD_SOURCE_FIELDS.LABEL.name]: adSourceOrigin[AD_SOURCE_FIELDS.LABEL.name],
        [AD_SOURCE_FIELDS.DEMAND.name]: adSourceOrigin[AD_SOURCE_FIELDS.DEMAND.name],
        [AD_SOURCE_FIELDS.PBS_BIDDER_CONFIGS.name]: adSourceOrigin[AD_SOURCE_FIELDS.PBS_BIDDER_CONFIGS.name],
        [AD_SOURCE_FIELDS.EXCLUDE_DEMAND.name]: adSourceOrigin[AD_SOURCE_FIELDS.EXCLUDE_DEMAND.name],
        [AD_SOURCE_FIELDS.READ_ONLY_TARGETING.name]: adSourceOrigin[AD_SOURCE_FIELDS.READ_ONLY_TARGETING.name],
        [AD_SOURCE_FIELDS.TARGETING_MODE.name]: adSourceOrigin[AD_SOURCE_FIELDS.TARGETING_MODE.name],
        [AD_SOURCE_FIELDS.RUN_UNDER_INBOUND_FLOOR.name]: adSourceOrigin[AD_SOURCE_FIELDS.RUN_UNDER_INBOUND_FLOOR.name],
        [AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.name]: demandAcquisitionCost,
        [AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST_MODEL.name]: demandAcquisitionCostModel,
        [AD_SOURCE_FIELDS.IAB_CATEGORY_BLOCK_MODE.name]: adSourceOrigin[AD_SOURCE_FIELDS.IAB_CATEGORY_BLOCK_MODE.name],
        [AD_SOURCE_FIELDS.IAB_BLOCKED_CATEGORIES.name]: adSourceOrigin[AD_SOURCE_FIELDS.IAB_BLOCKED_CATEGORIES.name],
        [AD_SOURCE_FIELDS.AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES.name]:
            adSourceOrigin[AD_SOURCE_FIELDS.AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES.name]?.map((category) => ({
                value: category.id,
                label: formatCategoryLabel(category, TargetingDimensionTypeLabels.ContentCategories),
            })) ?? [],
        [AD_SOURCE_FIELDS.AD_RESPONSE_PRICE_OVERRIDE.name]:
            adSourceOrigin[AD_SOURCE_FIELDS.AD_RESPONSE_PRICE_OVERRIDE.name],
    };

    return Object.fromEntries(
        Object.entries(adSourceFormFields).filter(([_, value]) => value !== null && value !== undefined)
    );
};
