import { OPERATORS_TAG, consoleApi } from "./console";

export type Operator = {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    sort: number;
    entityType: string;
};

export const operatorsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getOperators: builder.query<Operator[], void>({
            query: () => "/operators",
            providesTags: [OPERATORS_TAG],
        }),
    }),
    overrideExisting: false,
});

export const { useGetOperatorsQuery } = operatorsApi;
