export const PRICING_TYPE_OPTIONS = {
    FIXED: {
        id: 1,
        name: "Fixed Price",
        value: "FIXED_PRICE",
    },
    AUCTION: {
        id: 2,
        name: "Auction Price",
        value: "AUCTION_PRICE",
    },
};
