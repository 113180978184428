import { FC } from "react";
import { Col, Form, Row, Spin } from "antd";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { useDealSelect } from "./useDealSelect";

interface Props {
    isVisible: boolean;
}

export const DealSelect: FC<Props> = ({ isVisible }) => {
    const { deals, isLoading, onSearch, search, onSelect } = useDealSelect();

    return (
        <Form.Item name="dealOrigin" hidden={!isVisible} style={{ marginBottom: 0 }}>
            <SelectWithValue
                options={deals}
                loading={isLoading}
                dropdownRender={(menu) => {
                    return (
                        <>
                            {isLoading ? (
                                <Row justify="center">
                                    <Col>
                                        <Spin />
                                    </Col>
                                </Row>
                            ) : (
                                menu
                            )}
                        </>
                    );
                }}
                onSelect={onSelect}
                placeholder="Select an Existing Deal"
                fieldAsLabel={(item) => `${item.name} - ${item?.code}`}
                fieldAsValue={["id"]}
                showSearch
                searchValue={search}
                onSearch={onSearch}
                filterOption={(input, option) =>
                    (option?.label && typeof option?.label === "string" ? option.label : "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                }
            />
        </Form.Item>
    );
};
