import { useState } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useAdUnitFormInstance } from "../../../AdUnitFormInstance";

export const useCreativeSampleMinutesOff = () => {
    const { form } = useAdUnitFormInstance();
    const [isDisabled, setIsDisabled] = useState(false);

    const handleCreativeSamplesMinutesOff = (event: CheckboxChangeEvent) => {
        form.setFieldValue(AD_UNIT_FORM_FIELDS.CREATIVE_SAMPLES_MINUTE.name, 0);
        setIsDisabled(event.target.checked);
    };

    return {
        handleCreativeSamplesMinutesOff,
        isDisabled,
    };
};
