import { FC, useContext } from "react";
import { Col, Form } from "antd";
import { ReadonlyControl } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { MarketplaceInfo } from "@app/core/services";
import { AD_SOURCE_FIELDS, PriceModelEligibleAdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { AdSourceFormsContext } from "@app/features/seatAdSources/SeatAdSourcesForm";

export const PricingModelSelect: FC = () => {
    const { adSourceForm } = useContext(AdSourceFormsContext).forms;
    const adSourceTypeId: number | undefined = Form.useWatch(AD_SOURCE_FIELDS.TYPE.name, adSourceForm)?.id;
    const isVisible = PriceModelEligibleAdSourceTypeIds.includes(adSourceTypeId);

    if (!isVisible) {
        return null;
    }

    return (
        <Col sm={12}>
            <Form.Item name={["dealUnderEdit", "priceModel"]} label="Price Model">
                <ReadonlyControl<MarketplaceInfo["deal"]["priceModel"]> getLabel={(model) => model.name} />
            </Form.Item>
        </Col>
    );
};
