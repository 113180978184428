import { useState } from "react";
interface UseTargetingChangeHistory {
    showChangeHistory: boolean;
    handleShowChangeHistory: () => void;
    handleCloseChangeHistory: () => void;
}
export const useTargetingChangeHistory = (): UseTargetingChangeHistory => {
    const [showChangeHistory, setShowChangeHistory] = useState<boolean>(false);

    const handleShowChangeHistory = () => {
        setShowChangeHistory(true);
    };
    const handleCloseChangeHistory = () => {
        setShowChangeHistory(false);
    };

    return {
        showChangeHistory,
        handleShowChangeHistory,
        handleCloseChangeHistory,
    };
};
