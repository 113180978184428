import { Dashes, StyledButtonLink } from "@app/core/components";
import { DealListSource } from "@app/core/services";
import { Table } from "antd";
import { FC } from "react";
import { SeatAdSourceStatusTag } from "@app/features/seatAdSources/SeatAdSourceStatusTag";
import { useSeatAdSourcesDetailsDrawer } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/useSeatAdSourcesDetailsDrawer";

const COLUMN_KEY = {
    NAME: "ad_source_name",
    PRIORITY: "priority",
    TYPE: "type",
    CPM: "cpm",
    CURRENCY: "currency",
    STATUS: "status",
};

const columns = [
    {
        dataIndex: COLUMN_KEY.NAME,
        title: "Ad Source Name",
        sorter: true,
    },

    {
        dataIndex: COLUMN_KEY.PRIORITY,
        title: "Priority",
        sorter: true,
    },
    {
        dataIndex: COLUMN_KEY.TYPE,
        title: "Type",
        sorter: true,
    },
    {
        dataIndex: COLUMN_KEY.CPM,
        title: "CPM",
        sorter: true,
    },
    {
        dataIndex: COLUMN_KEY.CURRENCY,
        title: "Currency",
        sorter: true,
    },
    {
        dataIndex: COLUMN_KEY.STATUS,
        title: "Status",
        sorter: true,
    },
];

const getTableItems = (adSources: DealListSource[], handleOpenAdSourceDrawer: (id: number) => void) =>
    adSources.map(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        ({ id, name, priority, type, bookingPrice, currencyType, synthesizedStatus }) => ({
            key: id,
            [COLUMN_KEY.NAME]: (
                <StyledButtonLink
                    type="link"
                    onClick={() => {
                        handleOpenAdSourceDrawer(id);
                    }}
                >
                    {name}
                </StyledButtonLink>
            ),
            [COLUMN_KEY.PRIORITY]: <Dashes value={priority?.name} />,
            [COLUMN_KEY.TYPE]: <Dashes value={type?.name} />,
            [COLUMN_KEY.CPM]: bookingPrice / 1000 ?? <Dashes />,
            [COLUMN_KEY.CURRENCY]: <Dashes value={currencyType?.code} />,
            [COLUMN_KEY.STATUS]: synthesizedStatus ? (
                <SeatAdSourceStatusTag synthesizedStatus={synthesizedStatus} />
            ) : (
                <Dashes />
            ),
        })
    );

interface Props {
    adSources: DealListSource[];
}
export const DealDetailsPageTableAdSource: FC<Props> = ({ adSources, children }) => {
    const { openSeadAdSourceDrawerDeals } = useSeatAdSourcesDetailsDrawer();
    return (
        <>
            {children}
            <Table
                size="small"
                columns={columns}
                dataSource={getTableItems(adSources, openSeadAdSourceDrawerDeals)}
                pagination={false}
            />
        </>
    );
};
