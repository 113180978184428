import { Form } from "antd";
import { FC } from "react";
import { Labels } from "@app/features/inventory/components/Fields/Labels";
import { PREFILTER_LABEL_VALUES } from "@app/features/inventory/constants";
import { SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const PrefilterLabel: FC = () => {
    return (
        <Form.Item
            label={SUPPLY_FORM_FIELDS.PREFILTER_LABEL_VALUES.label}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 }}
            extra="Labels set here will force Ad Source Matching, which only allows Ad Sources marked with the specified Label to run on this inventory."
        >
            <Labels name={PREFILTER_LABEL_VALUES} />
        </Form.Item>
    );
};
