import { getBuyersPicklist, getSeatsPicklist } from "@app/core/clients/console";
import { useEffect, useState } from "react";

const loadContextCount = async () => {
    const { data: seats } = await getSeatsPicklist();
    const { data: buyers } = await getBuyersPicklist();
    return seats.length + buyers.length;
};

export const useHasMultipleContextsAccess = () => {
    const [count, setCount] = useState<number>(0);

    useEffect(() => {
        loadContextCount().then(setCount);
    }, []);

    return count > 0;
};
