import { useParams } from "react-router-dom";
import { AdvancedBrandSafety, useGetAdvancedBrandSafetyQuery } from "@app/core/services";

interface UseInventoryAdvancedBrandSafetyTable {
    data?: AdvancedBrandSafety[];
    isFetching: boolean;
    seatId: string;
}

export const useInventoryAdvancedBrandSafetyTable = (): UseInventoryAdvancedBrandSafetyTable => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data, isFetching } = useGetAdvancedBrandSafetyQuery(seatId);

    return {
        data,
        isFetching,
        seatId,
    };
};
