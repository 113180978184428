import { Form } from "antd";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { LabeledValue } from "antd/lib/select";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { useGetLibraryCreativesQuery } from "@app/core/services/console/libraryCreatives";

const { useWatch } = Form;

interface UseTestCreativeId {
    isFetching: boolean;
    isEnableTestCreativeUpload: boolean;
    options: LabeledValue[];
    optionsByValue: { [value: number]: LabeledValue };
}

export const useTestCreativeId = (): UseTestCreativeId => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data, isFetching } = useGetLibraryCreativesQuery({ seatId: Number(seatId) });

    const isEnableTestCreativeUpload = useWatch(INVENTORY_FORM_FIELDS.TEST_CREATIVES_ENABLED.name);
    const options: LabeledValue[] = useMemo(
        () => (data || []).map(({ id, name }) => ({ label: name, value: id })),
        [data]
    );
    const optionsByValue = useMemo(
        () =>
            options.reduce((byValue, option) => {
                byValue[option.value] = option;
                return byValue;
            }, {}),
        [options]
    );

    return {
        options,
        isFetching,
        optionsByValue,
        isEnableTestCreativeUpload,
    };
};
