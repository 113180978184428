import { LabeledValue } from "antd/lib/select";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { IS_GOOGLE_DEAL } from "@app/features/deals/DealForm/useDealForm";
import { DealFormFieldType, DealFormMode } from "@app/features/deals/DealForm/types";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { useCurrencyOptions } from "@app/features/deals/DealForm/useCurrencyOptions";
import { useSyncedFieldsProducer } from "@app/features/syncedFields";

interface UseDealCurrencyField {
    options: LabeledValue[];
    optionsByValue: { [value: number]: LabeledValue };
    isFetching: boolean;
    value: number;
    handleSelect: (value: number) => void;
    disabled: boolean;
}

export const useDealCurrencyField = (name: DealFormFieldType, mode: DealFormMode): UseDealCurrencyField => {
    const dispatch = useAppDispatch();
    const { options, optionsByValue, isFetching, getCurrencyById } = useCurrencyOptions();
    const { updateSyncedFields } = useSyncedFieldsProducer();

    const value = useAppSelector((state) => state.dealForm.values[name]);
    const dsp = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.DSP]);

    const handleSelect = (value: number): void => {
        dispatch(dealFormSelectFieldChange({ field: name, value }));
        const selectedCurrency = getCurrencyById(value);
        updateSyncedFields({ currency: selectedCurrency });
    };

    const disabled = mode === "edit" && dsp === IS_GOOGLE_DEAL;

    return {
        options,
        optionsByValue,
        isFetching,
        disabled,
        value,
        handleSelect,
    };
};
