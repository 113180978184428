import { Dashes } from "@app/core/components";
import { SeatContentTransparencyRule } from "@app/core/services";
import { DealAdSourceTransparencyExtendedUserId } from "@app/features/deals/DealForm/types";
import { Col, Form, Row, Tag } from "antd";
import { FC } from "react";
import { format } from "@rubicon/utils";

const getSpecificText = (value: boolean, specific: DealAdSourceTransparencyExtendedUserId[]) =>
    specific?.length ? "Specific" : value ? "All" : "None";
const getOverrideShareHiddenText = (value: boolean, specific: string | number | null) =>
    specific ? "Override" : format.asSharedOrHidden(value);

interface Props {
    allowSiteName: boolean;
    allowDomainName: boolean;
    allowSitePage: boolean;
    allowRef: boolean;
    allowBundleId: boolean;
    allowIp: boolean;
    allowStoreUrl: boolean;
    allowUserId: boolean;
    allowContent: boolean;
    allowAllExtendedId: boolean;
    domainNameOverride: string | null;
    bundleIdOverride: string | null;
    allowedExtendedIdTransparencies: DealAdSourceTransparencyExtendedUserId[] | [];
    contentTransparencyRule: SeatContentTransparencyRule | null;
    allowAudienceLock: boolean | null;
}

export const AdSourceTransparency: FC<Props> = ({
    allowSitePage,
    allowDomainName,
    allowSiteName,
    allowRef,
    allowBundleId,
    allowIp,
    allowUserId,
    allowStoreUrl,
    allowContent,
    allowAllExtendedId,
    domainNameOverride,
    bundleIdOverride,
    allowedExtendedIdTransparencies,
    contentTransparencyRule,
    allowAudienceLock,
}) => {
    return (
        <>
            <Row>
                <Col sm={8}>
                    <Form.Item label="Supply Name/App Name">{format.asSharedOrHidden(allowSiteName)}</Form.Item>
                </Col>
                <Col sm={8}>
                    <Form.Item label="Domain Name">
                        {getOverrideShareHiddenText(allowDomainName, domainNameOverride)}
                    </Form.Item>
                </Col>
                <Col sm={8}>
                    <Form.Item label="Domain Name Override">
                        <Dashes value={domainNameOverride} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    <Form.Item label="Supply Page/Content URL">{format.asSharedOrHidden(allowSitePage)}</Form.Item>
                </Col>
                <Col sm={8}>
                    <Form.Item label="Refer URL">{format.asSharedOrHidden(allowRef)}</Form.Item>
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    <Form.Item label="IP Address">{format.asSharedOrHidden(allowIp)}</Form.Item>
                </Col>
                <Col sm={8}>
                    <Form.Item label="Bundle ID">
                        {getOverrideShareHiddenText(allowBundleId, bundleIdOverride)}
                    </Form.Item>
                </Col>
                <Col sm={8}>
                    <Form.Item label="Bundle ID Override">
                        <Dashes value={bundleIdOverride} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    <Form.Item label="Store URL">{format.asSharedOrHidden(allowStoreUrl)}</Form.Item>
                </Col>
                <Col sm={8}>
                    <Form.Item label="User ID/IFA">{format.asSharedOrHidden(allowUserId)}</Form.Item>
                </Col>
                <Col sm={8}>
                    <Form.Item label="Content">
                        {contentTransparencyRule?.name || format.asSharedOrHidden(allowContent)}
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    <Form.Item label="Share Extended IDs">
                        {allowedExtendedIdTransparencies?.length
                            ? allowedExtendedIdTransparencies.map(
                                  ({ extendedId, network }: DealAdSourceTransparencyExtendedUserId) => (
                                      <Tag key={`${extendedId.id}${network?.id}`}>
                                          {extendedId.name} {network?.name && `- ${network.name}`}
                                      </Tag>
                                  )
                              )
                            : "None"}
                    </Form.Item>
                </Col>
                <Col sm={8}>
                    <Form.Item label="Included Extended IDs">
                        {getSpecificText(allowAllExtendedId, allowedExtendedIdTransparencies)}
                    </Form.Item>
                </Col>
                <Col sm={8}>
                    <Form.Item label="Allow Data Lock">{format.asYesOrNo(allowAudienceLock)}</Form.Item>
                </Col>
            </Row>
        </>
    );
};
