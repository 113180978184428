import { FC } from "react";
import { Form, Select } from "antd";
import { TagRender } from "@app/core/components";
import { CREATE_BRAND_SAFETY_FORM_ITEMS_NAME } from "@app/features/inventory/InventoryBrandSafety/InventoryAdvancedBrandSafetyPage/constants";
import { useCategories } from "@app/features/inventory/InventoryBrandSafety/InventoryAdvancedBrandSafetyPageForm/BrandSafetyFormFields/useCategories";

interface BlockIABCategoriesFieldProps {
    isSubmitting: boolean;
}

const { useFormInstance } = Form;

export const BlockIABCategoriesField: FC<BlockIABCategoriesFieldProps> = ({ isSubmitting }) => {
    const { search, options, isFetching, setSearch } = useCategories();
    const { validateFields } = useFormInstance();

    const onChange = (): Promise<void> =>
        validateFields([CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.IAB_CATEGORY_BLOCK_EXCEPTIONS]);

    return (
        <Form.Item name={CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.IABCATEGORIES} label="Block IAB Categories">
            <Select
                allowClear
                showSearch
                labelInValue
                mode="multiple"
                options={options}
                onChange={onChange}
                searchValue={search}
                filterOption={false}
                loading={isFetching}
                onSearch={setSearch}
                tagRender={TagRender}
                disabled={isSubmitting}
                autoClearSearchValue={false}
                placeholder="Select Block IAB Categories"
            />
        </Form.Item>
    );
};
