import { SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { Form, Select } from "antd";

export const Keywords = () => {
    return (
        <Form.Item
            name={SUPPLY_FORM_FIELDS.KEYWORDS.name}
            label={SUPPLY_FORM_FIELDS.KEYWORDS.label}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 }}
        >
            <Select
                placeholder={SUPPLY_FORM_FIELDS.KEYWORDS.placeholder}
                tokenSeparators={[","]}
                mode="tags"
                allowClear
            />
        </Form.Item>
    );
};
