import { Checkbox, Col, Divider, Flex, Form, Row, Typography } from "antd";
import { FC } from "react";
import { css } from "@emotion/css";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { LabeledValue } from "antd/lib/select";
import { Link, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";

interface Props {
    selected: string;
    indeterminate: boolean;
    checkedAll: boolean;
    checkedList: unknown[];
    handleChange: (e: CheckboxChangeEvent[]) => void;
    handleCheckAllChange: (ev: CheckboxChangeEvent) => void;
    vlOptions: LabeledValue[];
}
export const SEQUESTERED_DOMAINS_DETAILS_CONTENT_SDET = "sequestered-domains-details-content";
export const SEQUESTERED_DOMAIN_DETAILS_CHECKBOX_SDET = "advertiser-domain-name-input";

export const styles = css`
    display: inline-grid;
    &.ant-checkbox-group {
        margin-right: 0;
    }
    .ant-checkbox-wrapper {
        margin-bottom: 1em;
    }
`;
export const SequesteredDomainsDetailsContent: FC<Props> = ({
    handleChange,
    handleCheckAllChange,
    vlOptions,
    checkedList,
    selected,
    checkedAll,
    indeterminate,
}) => {
    const { seatId } = useParams<{ seatId: string; domain: string }>();

    return Boolean(vlOptions.length) ? (
        <Form
            size="small"
            layout="vertical"
            data-sdet={SEQUESTERED_DOMAINS_DETAILS_CONTENT_SDET}
            wrapperCol={{ xs: 24, md: 24 }}
        >
            <Flex gap="middle" vertical>
                <Typography.Text>
                    Add <Typography.Text strong>{selected}</Typography.Text> to any of the following lists.
                </Typography.Text>
                <Row gutter={[16, 12]} align="middle">
                    <Col span={24}>
                        <Form.Item data-sdet={SEQUESTERED_DOMAIN_DETAILS_CHECKBOX_SDET}>
                            <Checkbox
                                indeterminate={indeterminate}
                                onChange={handleCheckAllChange}
                                checked={checkedAll}
                            >
                                {indeterminate || checkedList.length === 0 ? "Select All" : "Deselect All"}
                            </Checkbox>
                            <Divider />
                            <Checkbox.Group className={styles} value={checkedList} onChange={handleChange}>
                                {vlOptions.map((option, idx) => (
                                    <Checkbox key={idx} value={option.value}>
                                        {option.label}
                                    </Checkbox>
                                ))}
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Flex>
            <Divider />
        </Form>
    ) : (
        <>
            <Typography.Text>No any advertiser lists. </Typography.Text>
            <Link to={ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAIN_CREATE(seatId)}>Add +</Link>
        </>
    );
};
