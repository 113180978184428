import { useSeatAuthContext } from "@app/core/auth";
import {
    CreativeSamplesEntityTypes,
    useGetCreativeSamplesQuery,
    useUpdateSeatBlockedDomainsMutation,
} from "@app/core/services";
import { useEffect, useState } from "react";
import { CreativeItem } from "./CreativeReview";
import { parseCreativeSamplesFromApi } from "./utils";
import { LoadingState } from "../LoadableComponent";
import { useAppSelector } from "@app/core/store";
import {
    InventoryDetailsDrawerType,
    selectInventoryDetailsDrawerType,
} from "@app/features/inventory/DetailsDrawer/reducer";

export const useCreativeReview = (id: number, entityType: CreativeSamplesEntityTypes) => {
    const { context, isDemoContext } = useSeatAuthContext();
    const inventoryDetailsDrawer = useAppSelector(selectInventoryDetailsDrawerType);
    const advertiserBlockDomains = context?.advertiserBlockDomains || [];

    const [updateBlockedDomains, { isLoading: isBlockLoading }] = useUpdateSeatBlockedDomainsMutation();

    switch (inventoryDetailsDrawer) {
        case InventoryDetailsDrawerType.SEAT:
            break;

        default:
            break;
    }

    const {
        data,
        isLoading: isSamplesLoading,
        refetch: samplesRefetch,
    } = useGetCreativeSamplesQuery(
        { id, entityType, isDemo: isDemoContext },
        {
            skip: !entityType,
        }
    );

    const creativeSamples = data ? data.creativeSamples || {} : {};
    const options = parseCreativeSamplesFromApi(creativeSamples);

    const [selectedCreative, setSelectedCreative] = useState<CreativeItem | null>(null);

    useEffect(() => {
        if (options.length && !selectedCreative) {
            setSelectedCreative(options[0]);
        }
    }, [options, selectedCreative]);

    const onBlockDomain = () => {
        if (!selectedCreative?.domain) {
            return;
        }

        updateBlockedDomains({ seatId: Number(id), domainList: [selectedCreative.domain], entityType });
    };
    const isDomainBlocked = (selectedCreative && advertiserBlockDomains.includes(selectedCreative.domain)) ?? false;

    const onRefresh = () => {
        samplesRefetch();
    };

    const loadingState: LoadingState = isSamplesLoading ? "loading" : options.length > 0 ? "loaded" : "loadedNoData";

    return {
        options,
        isDomainBlocked,
        isBlockLoading,
        selectedCreative,
        setSelectedCreative,
        onBlockDomain,
        onRefresh,
        loadingState,
    };
};
