import { useAppDispatch, useAppSelector } from "@app/core/store";
import { RadioChangeEvent } from "antd";
import {
    selectHistoricalChartGroupBy,
    setHistoricalChartGroupBy,
} from "@app/core/components/charts/HistoricalChart/reducer";
import { HISTORICAL_CHART_GROUP_BY_OPTIONS } from "../../constants";

interface UseHistoricalChartGroupBy {
    handleChange: (e: RadioChangeEvent) => void;
    value: string;
    options: typeof HISTORICAL_CHART_GROUP_BY_OPTIONS;
}

export const useHistoricalChartGroupBy = (): UseHistoricalChartGroupBy => {
    const dispatch = useAppDispatch();
    const value = useAppSelector(selectHistoricalChartGroupBy);

    const handleChange = (e: RadioChangeEvent): void => {
        dispatch(setHistoricalChartGroupBy(e?.target?.value));
    };

    return {
        handleChange,
        value,
        options: HISTORICAL_CHART_GROUP_BY_OPTIONS,
    };
};
