import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME, TRANSPARENCY_OVERRIDE_VALUE } from "@app/features/deals/constants";
import { dealFormInputFieldChange, dealFormRadioGroupChange } from "@app/features/deals/DealForm/reducer";
import { DealAdSourceTransparenceyOverride } from "@app/features/deals/DealForm/types";
import { useMemo } from "react";

interface UseAdSourceBundleIdField {
    onChange: (value: DealAdSourceTransparenceyOverride) => void;
    value: DealAdSourceTransparenceyOverride | null;
    helpText: string | null;
}

export const useAdSourceBundleIdField = (): UseAdSourceBundleIdField => {
    const dispatch = useAppDispatch();
    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_BUNDLE_ID]
    );
    const helpText = useMemo<string | null>(
        () =>
            value === TRANSPARENCY_OVERRIDE_VALUE
                ? "Enter Bundle ID Override or use hidden if you want the Bundle ID value to be blank"
                : null,
        [value]
    );

    const onChange = (value: DealAdSourceTransparenceyOverride) => {
        dispatch(dealFormRadioGroupChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_BUNDLE_ID, value }));
        if (value !== TRANSPARENCY_OVERRIDE_VALUE) {
            dispatch(
                dealFormInputFieldChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BUNDLE_ID_OVERRIDE,
                    value: null,
                })
            );
        }
    };

    return {
        onChange,
        helpText,
        value,
    };
};
