import { useCallback, useMemo, useState } from "react";
import { SearchItem, useSearchQuery } from "@app/core/services/console/search";
import { useParams } from "react-router-dom";
import {
    AdUnit,
    Brand,
    ChannelItem,
    Publisher,
    Supply,
    useGetAdUnitPicklistQuery,
    useGetChannelsQuery,
    useGetSeatBrandsPicklistQuery,
    useGetSeatPublishersPicklistQuery,
    useGetSupplySeatPicklistQuery,
} from "@app/core/services";
import { InventoryCategoryTypes, InventoryLabelValue } from "@app/features/targeting/constants";
import { inventoryToLabelValue } from "@app/features/targeting/helpers";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { useLoadOnScroll } from "@app/core/components";

export type InventorySelectOptions = SearchItem[] | ChannelItem[] | Brand[] | Publisher[] | Supply[] | AdUnit[];

interface UseDimensionsSelect {
    isCurrent: boolean;
    isLoading: boolean;
    options: InventoryLabelValue[];
    onSearch: (value: string) => void;
    onBlur: () => void;
    hasMore: boolean;
    loadMore: () => void;
    search: string;
}

const INITIAL_PAGE = 1;
const INITIAL_KEYWORD = "";
const MAX = 25;

export const useDimensionsSelect = (category: InventoryCategoryTypes): UseDimensionsSelect => {
    const { seatId } = useParams<{ seatId: string }>();
    const [search, setSearch] = useState(INITIAL_KEYWORD);
    const [keyword, setKeyword] = useState(INITIAL_KEYWORD);
    const [pageByKeyword, setPageByKeyword] = useState({
        [`${category}-${keyword}`]: INITIAL_PAGE,
    });

    const page = pageByKeyword[`${category}-${keyword}`];

    const params = useMemo(
        () => ({
            max: MAX,
            page,
            keyword,
            seatId,
        }),
        [page, keyword, seatId]
    );

    const {
        data: allCategories,
        isFetching: allCategoriesIsFetching,
        originalArgs: allCategoriesOriginalArgs,
    } = useSearchQuery({ ...params, type: "inventory" }, { skip: category !== InventoryCategoryTypes.All });
    const {
        data: channels,
        isFetching: channelsIsFetching,
        originalArgs: channelsOriginalArgs,
    } = useGetChannelsQuery(params, {
        skip: category !== InventoryCategoryTypes.Channels,
    });
    const {
        data: publishers,
        isFetching: publishersIsFetching,
        originalArgs: publishersOriginalArgs,
    } = useGetSeatPublishersPicklistQuery(params, {
        skip: category !== InventoryCategoryTypes.Publishers,
    });
    const {
        data: brands,
        isFetching: brandsIsFetching,
        originalArgs: brandsOriginalArgs,
    } = useGetSeatBrandsPicklistQuery(params, {
        skip: category !== InventoryCategoryTypes.Brands,
    });
    const {
        data: supply,
        isFetching: supplyIsFetching,
        originalArgs: supplyOriginalArgs,
    } = useGetSupplySeatPicklistQuery(params, {
        skip: category !== InventoryCategoryTypes.Supply,
    });
    const {
        data: adUnits,
        isFetching: adUnitsIsFetching,
        originalArgs: adUnitsOriginalArgs,
    } = useGetAdUnitPicklistQuery(params, {
        skip: category !== InventoryCategoryTypes.AdUnits,
    });

    const onLoadMore = useCallback(
        () =>
            setPageByKeyword((prev) => {
                const currentPage = prev[`${category}-${keyword}`] || INITIAL_PAGE;
                return {
                    ...prev,
                    [`${category}-${keyword}`]: currentPage + 1,
                };
            }),
        [category, keyword]
    );

    const {
        loadMore: loadMoreAllCategories,
        hasMore: hasMoreAllCategories,
        options: rawOptionsAllCategories,
    } = useLoadOnScroll(
        allCategories,
        allCategoriesIsFetching,
        allCategoriesOriginalArgs?.keyword || "",
        allCategoriesOriginalArgs?.page || 1,
        allCategories?.length === MAX,
        onLoadMore
    );

    const {
        loadMore: loadMoreChannels,
        hasMore: hasMoreChannels,
        options: rawOptionsChannels,
    } = useLoadOnScroll(
        channels,
        channelsIsFetching,
        channelsOriginalArgs?.keyword || "",
        channelsOriginalArgs?.page || 1,
        channels?.length === MAX,
        onLoadMore
    );

    const {
        loadMore: loadMorePublishers,
        hasMore: hasMorePublishers,
        options: rawOptionsPublishers,
    } = useLoadOnScroll(
        publishers,
        publishersIsFetching,
        publishersOriginalArgs?.keyword || "",
        publishersOriginalArgs?.page || 1,
        publishers?.length === MAX,
        onLoadMore
    );

    const {
        loadMore: loadMoreBrands,
        hasMore: hasMoreBrands,
        options: rawOptionsBrands,
    } = useLoadOnScroll(
        brands,
        brandsIsFetching,
        brandsOriginalArgs?.keyword || "",
        brandsOriginalArgs?.page || 1,
        brands?.length === MAX,
        onLoadMore
    );

    const {
        loadMore: loadMoreSupply,
        hasMore: hasMoreSupply,
        options: rawOptionsSupply,
    } = useLoadOnScroll(
        supply,
        supplyIsFetching,
        supplyOriginalArgs?.keyword || "",
        supplyOriginalArgs?.page || 1,
        supply?.length === MAX,
        onLoadMore
    );

    const {
        loadMore: loadMoreAdUnits,
        hasMore: hasMoreAdUnits,
        options: rawOptionsAdUnits,
    } = useLoadOnScroll(
        adUnits,
        adUnitsIsFetching,
        adUnitsOriginalArgs?.keyword || "",
        adUnitsOriginalArgs?.page || 1,
        adUnits?.length === MAX,
        onLoadMore
    );

    const curOriginalArgs = useMemo(
        () =>
            ({
                [InventoryCategoryTypes.All]: allCategoriesOriginalArgs,
                [InventoryCategoryTypes.Brands]: brandsOriginalArgs,
                [InventoryCategoryTypes.Supply]: supplyOriginalArgs,
                [InventoryCategoryTypes.AdUnits]: adUnitsOriginalArgs,
                [InventoryCategoryTypes.Channels]: channelsOriginalArgs,
                [InventoryCategoryTypes.Publishers]: publishersOriginalArgs,
            }[category]),
        [
            category,
            allCategoriesOriginalArgs,
            brandsOriginalArgs,
            supplyOriginalArgs,
            adUnitsOriginalArgs,
            channelsOriginalArgs,
            publishersOriginalArgs,
        ]
    );

    const isFetching = useMemo(
        () =>
            ({
                [InventoryCategoryTypes.All]: allCategoriesIsFetching,
                [InventoryCategoryTypes.Brands]: brandsIsFetching,
                [InventoryCategoryTypes.Supply]: supplyIsFetching,
                [InventoryCategoryTypes.AdUnits]: adUnitsIsFetching,
                [InventoryCategoryTypes.Channels]: channelsIsFetching,
                [InventoryCategoryTypes.Publishers]: publishersIsFetching,
            }[category]),
        [
            category,
            allCategoriesIsFetching,
            brandsIsFetching,
            supplyIsFetching,
            adUnitsIsFetching,
            channelsIsFetching,
            publishersIsFetching,
        ]
    );

    const rawOptions = useMemo(
        () =>
            ({
                [InventoryCategoryTypes.All]: rawOptionsAllCategories,
                [InventoryCategoryTypes.Brands]: rawOptionsBrands,
                [InventoryCategoryTypes.Supply]: rawOptionsSupply,
                [InventoryCategoryTypes.AdUnits]: rawOptionsAdUnits,
                [InventoryCategoryTypes.Channels]: rawOptionsChannels,
                [InventoryCategoryTypes.Publishers]: rawOptionsPublishers,
            }[category]),
        [
            category,
            rawOptionsAllCategories,
            rawOptionsBrands,
            rawOptionsSupply,
            rawOptionsAdUnits,
            rawOptionsChannels,
            rawOptionsPublishers,
        ]
    );

    const loadMore = useMemo(
        () =>
            ({
                [InventoryCategoryTypes.All]: loadMoreAllCategories,
                [InventoryCategoryTypes.Brands]: loadMoreBrands,
                [InventoryCategoryTypes.Supply]: loadMoreSupply,
                [InventoryCategoryTypes.AdUnits]: loadMoreAdUnits,
                [InventoryCategoryTypes.Channels]: loadMoreChannels,
                [InventoryCategoryTypes.Publishers]: loadMorePublishers,
            }[category]),
        [
            category,
            loadMoreAllCategories,
            loadMoreBrands,
            loadMoreSupply,
            loadMoreAdUnits,
            loadMoreChannels,
            loadMorePublishers,
        ]
    );

    const hasMore = useMemo(
        () =>
            ({
                [InventoryCategoryTypes.All]: hasMoreAllCategories,
                [InventoryCategoryTypes.Brands]: hasMoreBrands,
                [InventoryCategoryTypes.Supply]: hasMoreSupply,
                [InventoryCategoryTypes.AdUnits]: hasMoreAdUnits,
                [InventoryCategoryTypes.Channels]: hasMoreChannels,
                [InventoryCategoryTypes.Publishers]: hasMorePublishers,
            }[category]),
        [
            category,
            hasMoreAllCategories,
            hasMoreBrands,
            hasMoreSupply,
            hasMoreAdUnits,
            hasMoreChannels,
            hasMorePublishers,
        ]
    );

    const options = useMemo(() => inventoryToLabelValue(rawOptions as InventorySelectOptions), [rawOptions]);

    const debouncedSetKeyword = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setKeyword]
    );
    const onSearch = useCallback(
        (value: string) => {
            setSearch(value);
            debouncedSetKeyword(value);
        },
        [debouncedSetKeyword, setSearch]
    );
    const onBlur = useCallback(() => {
        setSearch(INITIAL_KEYWORD);
        setKeyword(INITIAL_KEYWORD);
    }, [setKeyword]);

    return {
        isLoading: isFetching,
        options,
        onSearch,
        onBlur,
        loadMore,
        hasMore,
        search,
        isCurrent: curOriginalArgs?.keyword === search,
    };
};
