import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { useGetCostModelsQuery } from "@app/core/services";

interface UseAffiliateCostModel {
    isFetching: boolean;
    options: LabeledValue[];
}

export const useAffiliateCostModel = (): UseAffiliateCostModel => {
    const { data, isFetching } = useGetCostModelsQuery();
    const options = useMemo(() => (data || []).map((model) => ({ value: model.id, label: model.name })), [data]);
    return {
        isFetching,
        options,
    };
};
