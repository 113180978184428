import { css, cx } from "@emotion/css";
import { FC } from "react";

interface Props {
    name: string;
    value: number | string;
    tooltipColor?: string;
}
export const ChartTooltipItem: FC<Props> = ({ value, name, tooltipColor = "none" }) => {
    return (
        <div>
            <div className="g2-tooltip-list-item">
                <span
                    className={cx(
                        "g2-tooltip-marker",
                        css`
                            background: ${tooltipColor};
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            display: inline-block;
                            margin-right: 8px;
                        `
                    )}
                ></span>
                <span className="g2-tooltip-name">{name}</span>
                <span className="g2-tooltip-value">{value}</span>
            </div>
        </div>
    );
};
