import { Seat, SeatPublisher, Brand, Publisher, AdUnit, BrandSupply, Supply } from "@app/core/services/console";

type InventoryEntity = Seat | SeatPublisher | Publisher | Brand | BrandSupply | Supply | AdUnit;

export const getKey = (entity) => `${entity.entityType}-${entity.id}`;

export const isSeat = (entity: InventoryEntity): entity is Seat => entity.entityType === "Seat";

export const isPublisherOrPublisherChannel = (entity: InventoryEntity): entity is SeatPublisher =>
    entity.entityType === "Publisher" || (entity.entityType === "Channel" && "seat" in entity);

export const isBrandOrBrandChannel = (entity: InventoryEntity): entity is Brand =>
    entity.entityType === "Brand" || (entity.entityType === "Channel" && "publisher" in entity);

export const isSupply = (entity: InventoryEntity): entity is Supply => entity.entityType === "Supply";

export const isAdUnit = (entity: InventoryEntity): entity is AdUnit => entity.entityType === "AdUnit";
