import { Layout } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { PageLoader } from "@rubicon/antd-components";
import { Footer } from "@app/core/components";
import { useHistory, useParams } from "react-router-dom";
import { FloorForm } from "../FloorForm";
import { useFloorById } from "../useFloorById";
import { ROUTE_FORMATTERS } from "@app/core/routing";

const InventoryFloorHierarchicalEditPage = () => {
    const { floor, isLoading } = useFloorById();
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();

    if (isLoading || !floor) {
        return <PageLoader />;
    }

    return (
        <Layout>
            <PageHeader
                title={`Edit Floor`}
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_HIERARCHICAL(seatId))}
            />
            <Layout.Content>
                <FloorForm mode="edit" floor={floor} />
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </Layout>
    );
};

export default InventoryFloorHierarchicalEditPage;
