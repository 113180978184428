import { useMemo } from "react";
import { useGetAdSourcesTypeQuery } from "@app/core/services";
import { AD_SOURCE_TYPES_NEXT_UI_NAMES } from "@app/features/seatAdSources/constants";

interface UseTypeSelect {
    filterOption: (input: string, option: { label: string; value: number }) => boolean;
    options: { label: string; value: number }[];
    isFetching: boolean;
}

export const useTypeSelect = (): UseTypeSelect => {
    const { data = [], isFetching } = useGetAdSourcesTypeQuery();
    const options = useMemo(
        () =>
            data.map((type) => ({
                value: type?.id,
                label: AD_SOURCE_TYPES_NEXT_UI_NAMES[type?.name],
            })),
        [data]
    );
    const filterOption = (input: string, option: { label: string; value: number }): boolean =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
    return {
        isFetching,
        options,
        filterOption,
    };
};
