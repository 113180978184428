import { useState, useMemo, FC } from "react";
import debounce from "lodash.debounce";
import { useParams } from "react-router-dom";
import { Row, Col, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { AdSource, useGetAdSourceByIdQuery, useGetSeatAdSourcesQuery } from "@app/core/services/console";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import {
    CREATE_AD_SOURCE_TYPES_ADD,
    CREATE_AD_SOURCE_TYPES_COPY,
    CREATE_DEAL_FORM_ITEMS_NAME,
} from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { useLoadOnScroll } from "@app/core/components";
import { brandCobalt } from "@rubicon/antd-components";
import { useSeatAdSourcesDetailsDrawer } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/useSeatAdSourcesDetailsDrawer";

const AdSourceLabel: FC<{ adSource: AdSource; isSelectHasIcon: boolean; handleOpen: (id: number) => void }> = ({
    adSource,
    isSelectHasIcon,
    handleOpen,
}) => (
    <Row>
        <Col
            style={{
                display: "flex",
            }}
            xs={isSelectHasIcon ? 22 : 24}
        >
            <Typography.Text
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
                ellipsis
            >
                {adSource.name}
            </Typography.Text>
        </Col>
        {isSelectHasIcon && (
            <Col xs={2}>
                <Row justify="end">
                    <Col>
                        <InfoCircleOutlined
                            data-sdet="open-drawer-info-icon"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleOpen(adSource.id);
                            }}
                            style={{ color: brandCobalt }}
                        />
                    </Col>
                </Row>
            </Col>
        )}
    </Row>
);

const getAdSourceLabelValue = (adSource, isSelectHasIcon, handleOpen) => ({
    value: adSource.id,
    label: (
        <AdSourceLabel
            adSource={adSource}
            handleOpen={handleOpen}
            isSelectHasIcon={isSelectHasIcon}
            key={`existingAdSource-${adSource.id}`}
        />
    ),
});

export const useExistingAdSourceField = () => {
    const dispatch = useAppDispatch();
    const dealType = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TYPE]);
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_COPY_ID]);
    const adSourceMode = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE_CONFIGURATION]
    );
    const [search, setSearch] = useState("");
    const [keyword, setKeyword] = useState("");
    const [pageByKeyword, setPageByKeyword] = useState({
        [`${dealType}-`]: 1,
    });
    const { seatId } = useParams<{ seatId: string }>();
    const key = `${dealType}-${keyword || ""}`;

    const { data, isFetching, originalArgs } = useGetSeatAdSourcesQuery({
        adSourceType: dealType,
        keyword,
        seatId: Number(seatId),
        page: pageByKeyword[key] || 1,
        max: 100,
    });

    const { data: adSource, isFetching: isFetchingAdSource } = useGetAdSourceByIdQuery(Number(value), { skip: !value });

    const {
        options: rawOptions,
        hasMore,
        loadMore,
    } = useLoadOnScroll(
        data?.adSources,
        isFetching,
        key,
        originalArgs?.page || 1,
        data?.adSources.length === originalArgs?.max,
        () =>
            setPageByKeyword((prev) => {
                const currentPage = prev[key] || 1;
                return {
                    ...prev,
                    [key]: currentPage + 1,
                };
            })
    );

    const { openSeadAdSourceDrawerDeals: openSeatAdSourcesDetailsDrawer } = useSeatAdSourcesDetailsDrawer();
    const isSelectHasIcon = useMemo(
        () => adSourceMode === CREATE_AD_SOURCE_TYPES_ADD || adSourceMode === CREATE_AD_SOURCE_TYPES_COPY,
        [adSourceMode]
    );

    const options = useMemo(() => {
        // TODO: Fix bug in ad sources endpoint that returns duplicate ad sources on page end/start
        const optionsById = new Map(rawOptions?.map((adSource) => [adSource.id, adSource]) || []);
        const dedupedRawOptions = Array.from(optionsById.values());

        return (dedupedRawOptions || []).map((adSource) =>
            getAdSourceLabelValue(adSource, isSelectHasIcon, openSeatAdSourcesDetailsDrawer)
        );
    }, [rawOptions, isSelectHasIcon, openSeatAdSourcesDetailsDrawer]);

    const handleChangeValue = (value: number) => {
        dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_COPY_ID, value }));
    };

    const debouncedSetKeyword = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setKeyword]
    );

    const handleChangeSearch = (value: string) => {
        setSearch(value);
        debouncedSetKeyword(value);
    };

    const valueOption = useMemo(() => {
        if (!value || !adSource) {
            return null;
        }
        return getAdSourceLabelValue(adSource, isSelectHasIcon, openSeatAdSourcesDetailsDrawer);
    }, [value, adSource, isSelectHasIcon, openSeatAdSourcesDetailsDrawer]);

    return {
        hasMore,
        loadMore,
        handleChangeValue,
        handleChangeSearch,
        isLoading: isFetching || isFetchingAdSource,
        options,
        search,
        value,
        valueOption,
    };
};
