import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { useGetAdUnitWaitTimesQuery } from "@app/core/services";

interface UseDefaultExtendedImpressionWaitTime {
    isFetching: boolean;
    options: LabeledValue[];
}

export const useDefaultExtendedImpressionWaitTime = (): UseDefaultExtendedImpressionWaitTime => {
    const { data = [], isFetching } = useGetAdUnitWaitTimesQuery();
    const options = useMemo(() => (data || []).map((time) => ({ value: time.id, label: time.name })), [data]);
    return {
        isFetching,
        options,
    };
};
