import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormRadioGroupChange } from "@app/features/deals/DealForm/reducer";

interface UseAdSourceRedistributionField {
    value: boolean | null;
    onChange: (value: boolean) => void;
}

export const useAdSourceRedistributionField = (): UseAdSourceRedistributionField => {
    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_REDISTRIBUTE]
    );
    const dispatch = useAppDispatch();

    const onChange = (value: boolean) => {
        dispatch(dealFormRadioGroupChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_REDISTRIBUTE, value }));
    };

    return {
        onChange,
        value,
    };
};
