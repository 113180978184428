import { FC } from "react";
import { Layout } from "antd";
import { NavBar, Loading } from "@app/core/components";
import { useSeatAuthContext } from "@app/core/auth";
import { InventorySwitch } from "./InventorySwitch";
import { InventoryNavMenu } from "./InventoryNavMenu";
import { ChangelogDrawer } from "./InventoryChangelog";
import { ManageColumnsDrawer } from "./ManageColumns";
import { useSetInitialSettings } from "./useSetInitialSettings";
import { useGetCurrenciesPrefetch } from "@app/core/services";
import { IframeBetaLabel } from "@app/core/components/SeatIFrame";
import { FeedbackDrawer } from "@app/core/components/FeedbackDrawer/FeedbackDrawer";

export const Inventory: FC = () => {
    const { context, session } = useSeatAuthContext();
    useGetCurrenciesPrefetch();
    useSetInitialSettings();

    if (!context || !session) {
        return (
            <Layout.Content>
                <Loading />
            </Layout.Content>
        );
    }

    return (
        <>
            <NavBar extra={<FeedbackDrawer name="Inventory Beta" />} label={<IframeBetaLabel title="Inventory" />} />
            <InventoryNavMenu />
            <InventorySwitch />
            <ChangelogDrawer />
            <ManageColumnsDrawer />
        </>
    );
};
