import { Form, InputNumber } from "antd";
import { FC } from "react";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const SkippableMinimum: FC = () => {
    return (
        <Form.Item
            name={CHANNEL_FORM_FIELDS.SKIPPABLE_MINIMUM.name}
            label={CHANNEL_FORM_FIELDS.SKIPPABLE_MINIMUM.label}
        >
            <InputNumber style={{ width: "100%" }} placeholder={CHANNEL_FORM_FIELDS.SKIPPABLE_MINIMUM.placeholder} />
        </Form.Item>
    );
};
