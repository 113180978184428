import { FC } from "react";
import { Form, Radio } from "antd";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { isDailyImpressionCapEnabledEligible } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/pacingUtils";
import { usePacingFormData } from "../usePacingFormData";

const { label, name } = AD_SOURCE_FIELDS.DAILY_IMPRESSION_CAP_ENABLED;

export const DailyImpressionEnabledRadio: FC = () => {
    const { adSourceTypeId, pacingTypeId } = usePacingFormData();

    if (!isDailyImpressionCapEnabledEligible(adSourceTypeId, pacingTypeId)) {
        return null;
    }

    return (
        <Form.Item name={name} label={label} data-sdet={`test-${name}-field`} {...VERTICAL_LAYOUT_FIX}>
            <Radio.Group>
                <Radio value={true} data-sdet={`test-${name}-radio-yes`}>
                    Yes
                </Radio>
                <Radio value={false} data-sdet={`test-${name}-radio-no`}>
                    No
                </Radio>
            </Radio.Group>
        </Form.Item>
    );
};
