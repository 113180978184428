import { Col, Form } from "antd";
import { useBookingBudgetField } from "./useBookingBudgetField";
import { AD_SOURCE_FIELDS, COL_SIZES, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";

export const BookingBudgetField = () => {
    const { isVisible, bookingBudgetValue } = useBookingBudgetField();

    if (!isVisible) {
        return null;
    }
    return (
        <Col {...COL_SIZES}>
            <Form.Item
                label={AD_SOURCE_FIELDS.BOOKING_BUDGET.label}
                data-sdet={`test-${AD_SOURCE_FIELDS.BOOKING_BUDGET.name}-field`}
                {...VERTICAL_LAYOUT_FIX}
            >
                {bookingBudgetValue}
            </Form.Item>
        </Col>
    );
};
