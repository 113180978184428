import { FC } from "react";
import { Form, Radio } from "antd";
import { INVENTORY_YES_NO_OPTIONS, AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useAdUnitFormInstance } from "../../../AdUnitFormInstance";

export const LiveStreamAcceleration: FC = () => {
    const { seat } = useAdUnitFormInstance();
    if (!seat?.lsaEnabled) {
        return null;
    }
    return (
        <Form.Item
            name={AD_UNIT_FORM_FIELDS.LIVE_STREAM_ACCELERATION.name}
            label={AD_UNIT_FORM_FIELDS.LIVE_STREAM_ACCELERATION.label}
        >
            <Radio.Group options={INVENTORY_YES_NO_OPTIONS} />
        </Form.Item>
    );
};
