import { Affix, Button } from "antd";
import { useRecycleBinTable } from "./RecycleBinTable/useRecycleBinTable";
import { PageHeader } from "@ant-design/pro-layout";

export const RECYCLE_BIN_HEADER_SDET = "recycle-bin-header";
export const RECYCLE_BIN_REFRESH_BTN_SDET = "recycle-bin-refresh-button";
export const RECYCLE_BIN_RESTORE_BTN_SDET = "recycle-bin-restore-button";

export const RecycleBinHeader = () => {
    const { entity, selectedBy, handleRefreshClick, isLoading, handleRestoreClick } = useRecycleBinTable();

    return (
        <Affix>
            <PageHeader
                data-sdet={RECYCLE_BIN_HEADER_SDET}
                ghost={false}
                title="Recycle Bin"
                extra={
                    <>
                        <Button
                            data-sdet={RECYCLE_BIN_REFRESH_BTN_SDET}
                            disabled={isLoading}
                            onClick={handleRefreshClick}
                        >
                            Refresh
                        </Button>
                        <Button
                            type="primary"
                            data-sdet={RECYCLE_BIN_RESTORE_BTN_SDET}
                            disabled={!Boolean(selectedBy[entity].length) || isLoading}
                            onClick={handleRestoreClick}
                        >
                            Restore
                        </Button>
                    </>
                }
            />
        </Affix>
    );
};

export default RecycleBinHeader;
