import { useHistory, useParams } from "react-router-dom";
import { DEAL_DEMAND } from "@app/features/deals/DealCreatePage";
import { DealCreateFormDemand } from "@app/features/deals/DealForm/DealFormDemand";
import { Layout } from "antd";
import { Footer } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { PageHeader } from "@ant-design/pro-layout";

type Params = {
    seatId: string;
    dealType: string;
};

export const DealCreateFromPage = () => {
    const { dealType, seatId } = useParams<Params>();
    const history = useHistory();

    return (
        <>
            <PageHeader title="Create Deal" onBack={() => history.push(ROUTE_FORMATTERS.SEAT_DEALS(seatId))} />
            <Layout.Content style={{ padding: 0 }}>
                {dealType === DEAL_DEMAND && <DealCreateFormDemand mode="create" />}
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
