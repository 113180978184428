import { FC } from "react";
import { Card } from "antd";
import { useParams } from "react-router-dom";
import { FloorsTable } from "@app/features/inventory/components/FloorsTable";

export const FloorsSection: FC = () => {
    const { brandId } = useParams<{ brandId: string }>();
    return (
        <Card bordered={false}>
            <FloorsTable id={Number(brandId)} sourceSelf="Brand" hiddenColumns={["customPrice"]} />
        </Card>
    );
};
