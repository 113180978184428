import { MarketplaceInfo } from "@app/core/services";
import { ReadonlyControl } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { Col, Form, Input } from "antd";
import { FC } from "react";
import { usePublisherEmail } from "./usePublisherEmail";

export const PublisherEmailInput: FC = () => {
    const { isVisible, isReadonly } = usePublisherEmail();
    if (!isVisible) {
        return null;
    }
    return (
        <Col sm={12}>
            <Form.Item name={["dealUnderEdit", "publisherEmail"]} label="Publisher Email">
                {isReadonly ? (
                    <ReadonlyControl<MarketplaceInfo["deal"]["publisherEmail"]> getLabel={(email) => email} />
                ) : (
                    <Input placeholder="Publisher Email" />
                )}
            </Form.Item>
        </Col>
    );
};
