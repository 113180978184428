import { FC } from "react";
import { Form, Card } from "antd";
import { Seat } from "@app/core/services";
import { ConditionalTagRender } from "@app/core/components";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

interface InventoryLimitsPageSectionProps {
    seat: Seat;
}

export const InventoryLimitsPageSection: FC<InventoryLimitsPageSectionProps> = ({ seat }) => (
    <Card bordered={false}>
        <Form.Item label={INVENTORY_FORM_FIELDS.COUNT_LIMITS.label} data-sdet="ad-source-type-limits">
            <ConditionalTagRender data={seat} property={INVENTORY_FORM_FIELDS.COUNT_LIMITS.name} />
        </Form.Item>
        <Form.Item label={INVENTORY_FORM_FIELDS.QPS_LIMITS.label} data-sdet="qps-limits">
            <ConditionalTagRender data={seat} property={INVENTORY_FORM_FIELDS.QPS_LIMITS.name} />
        </Form.Item>
        <Form.Item label={INVENTORY_FORM_FIELDS.GOVERNOR_REQUEST.label} data-sdet="max-ads-user-in-1-minute">
            {seat.requestGovernorLimitPerMin}
        </Form.Item>
        <Form.Item label={INVENTORY_FORM_FIELDS.TWO_QPS_LIMIT.label} data-sdet="tier-2-minimun-qps-limit-%">
            {seat.tierTwoQpsLimitMinPercent}%
        </Form.Item>
        <Form.Item label={INVENTORY_FORM_FIELDS.THREE_QPS_LIMIT.label} data-sdet="tier-3-minimun-qps-limit-%">
            {seat.tierThreeQpsLimitMinPercent}%
        </Form.Item>
        <Form.Item label={INVENTORY_FORM_FIELDS.VIEWABILITY_MESUREMENT.label} data-sdet="viewability-mesurement">
            <ConditionalTagRender data={seat} property={INVENTORY_FORM_FIELDS.VIEWABILITY_MESUREMENT.name} />
        </Form.Item>
        <Form.Item label={INVENTORY_FORM_FIELDS.MOKA_FILTERING_MODE.label} data-sdet="moka-integration">
            {seat.seatMokaFilteringMode.name}
        </Form.Item>
    </Card>
);
