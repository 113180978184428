import { ChangeEvent, useState, useMemo, useCallback } from "react";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { selectInventoryFloorAdvancedSearchFilter, setSearchFilter } from "../../InventoryFloorAdvancedPage/reducer";

interface SeatFloorsAdvancedTableFilterSearch {
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    value: string;
    handleSearch: (value: string) => void;
}

export const useSeatFloorsAdvancedTableFilterSearch = (): SeatFloorsAdvancedTableFilterSearch => {
    const dispatch = useAppDispatch();
    const { keyword } = useAppSelector(selectInventoryFloorAdvancedSearchFilter);
    const [value, setValue] = useState(keyword);

    const handleSearch = useCallback(
        (v: string): void => {
            dispatch(setSearchFilter({ keyword: v.trim() }));
        },
        [dispatch]
    );

    const debouncedHandleSearch = useMemo(
        () => debounce((value: string) => handleSearch(value), FILTER_INPUT_DEBOUNCE_TIME),
        [handleSearch]
    );

    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setValue(e?.target?.value);
        debouncedHandleSearch(e?.target?.value);
    };

    return {
        handleChange,
        value,
        handleSearch,
    };
};
