import { FC } from "react";
import { Operations } from "../constants";
import { DropdownSelect } from "./DropdownSelect";

interface Props {
    value: Operations;
    onChange: (value: Operations) => void;
}

const OPTIONS = [
    {
        value: Operations.All,
        label: "AND",
    },
    {
        value: Operations.Any,
        label: "OR",
    },
];

const getPrimaryColor = (operation: Operations) => (operation === Operations.Any ? "#7666F9" : "#C1715B");

export const OperationSelect: FC<Props> = ({ value, onChange }) => {
    return <DropdownSelect color={getPrimaryColor(value)} onChange={onChange} options={OPTIONS} value={value} />;
};
