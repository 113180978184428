import { FC, ReactNode } from "react";
import { Button, Tooltip, TooltipProps } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { useClipBoardButton } from "./useClipBoardButton";
import { ButtonType } from "antd/lib/button";
import { brandCobalt } from "@rubicon/antd-components";

interface Props {
    noStyle?: boolean;
    entity?: string;
    copyText: string;
    type?: ButtonType;
    icon?: ReactNode;
    tooltipProps?: TooltipProps;
    sdet?: string;
}
export const ClipBoardButton: FC<Props> = ({
    noStyle,
    copyText,
    type = "link",
    entity = "Link",
    icon = <LinkOutlined />,
    tooltipProps = {},
    sdet = "clip-board-button",
}) => {
    const { handleCopy } = useClipBoardButton();
    return (
        <Tooltip {...tooltipProps}>
            <Button
                data-sdet={sdet}
                onClick={() => handleCopy(copyText, entity)}
                type={type}
                className={
                    !noStyle
                        ? css`
                              padding: 0;
                              width: 18px;
                              height: 16px;
                              color: ${brandCobalt};
                          `
                        : ""
                }
                icon={icon}
            />
        </Tooltip>
    );
};
