import { FC } from "react";
import { useUserAccess } from "@app/core/auth";
import { Loading, Title } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Button, Col, Form, Row, Collapse } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useGetSeatSelfQuery } from "@app/core/services";
import {
    INVENTORY_SEAT_PROTECTIONS,
    INVENTORY_HEADER_COLLAPSE_CLASS_LIST,
} from "@app/features/inventory/InventorySeat/constants";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import {
    QualityControlPageSection,
    InventoryLimitsPageSection,
} from "@app/features/inventory/InventorySeat/ExchangeProtectionsPage/ExchangeProtectionsPageSections";
import { InternalUseOnlyInfo } from "@app/features/inventory/InventorySeat/InventorySeatPage/InternalUseOnlyInfo";
import { useExchangeProtectionsDefenitionsFields } from "@app/features/inventory/InventorySeat/ExchangeProtectionsPage/useExchangeProtectionsDefenitionsFields";

export const ExchangeProtectionsPage: FC = () => {
    const history = useHistory();
    const { hasSeatWriteAccess } = useUserAccess();
    const { seatId } = useParams<{ seatId: string }>();
    const { data, isFetching } = useGetSeatSelfQuery(seatId);
    const { limitsDefinitionsFields, controlDefinitionsFields } = useExchangeProtectionsDefenitionsFields();

    const handleClick = (): void =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_SEAT_EDIT_TABS(seatId, INVENTORY_SEAT_PROTECTIONS));

    if (!data?.seat || isFetching) return <Loading />;

    return (
        <Form layout="vertical">
            <Row justify="space-between" gutter={[0, 8]}>
                <Col>
                    <InternalUseOnlyInfo />
                </Col>
                {hasSeatWriteAccess && (
                    <Col>
                        <Button onClick={handleClick} type="primary">
                            Edit
                        </Button>
                    </Col>
                )}
            </Row>
            <Collapse
                bordered
                ghost={false}
                className={INVENTORY_HEADER_COLLAPSE_CLASS_LIST}
                defaultActiveKey={["0", "1"]}
                items={[
                    {
                        key: "0",
                        collapsible: "header",
                        children: <InventoryLimitsPageSection seat={data?.seat} />,
                        label: <Title level={5} title="Inventory Limits" />,
                        extra: (
                            <HelpKeysDrawer panelLabel="Inventory Limits" definitionsFields={limitsDefinitionsFields} />
                        ),
                    },
                    {
                        key: "1",
                        collapsible: "header",
                        children: <QualityControlPageSection seat={data?.seat} />,
                        label: <Title level={5} title="Quality Control" />,
                        extra: (
                            <HelpKeysDrawer panelLabel="Quality Control" definitionsFields={controlDefinitionsFields} />
                        ),
                    },
                ]}
            />
        </Form>
    );
};
