import { FC } from "react";
import { Form, Input } from "antd";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { useWatch } from "rc-field-form";
import { CostModel } from "@app/core/services";
import { CostModels } from "@app/features/seatAdSources/constants";
import { useIsFieldVisible } from "./useFieldIsVisible";

export const CostInput: FC<{ currentDefaultId: number | undefined; currentDefaultValue?: number | null }> = ({
    currentDefaultId,
    currentDefaultValue,
}) => {
    const costModel = useWatch<CostModel>(AD_SOURCE_FIELDS.COST_MODEL.name);
    const isVisible = useIsFieldVisible();
    const isPercents = costModel?.id === 1;
    return isVisible ? (
        <Form.Item
            {...VERTICAL_LAYOUT_FIX}
            label={AD_SOURCE_FIELDS.COST_PERCENT.label}
            name={isPercents ? AD_SOURCE_FIELDS.COST_PERCENT.name : AD_SOURCE_FIELDS.COST_FIXED.name}
            help={
                currentDefaultId
                    ? `Default Cost Value: ${
                          currentDefaultId === CostModels.REVSHARE_PERCENTAGE
                              ? currentDefaultValue
                              : (currentDefaultValue || 0) / 1000
                      }`
                    : null
            }
        >
            <Input type="number" prefix={costModel?.id === 1 ? "%" : ""} data-sdet="adsource-cost" />
        </Form.Item>
    ) : null;
};
