import { FrequencyCaps } from "@app/features/inventory/components/Fields";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const AdvertiserDomainFrequencyCaps = () => {
    return (
        <FrequencyCaps
            label={CHANNEL_FORM_FIELDS.ADV_DOMAIN_FREQ_CAPPS.label}
            name={CHANNEL_FORM_FIELDS.ADV_DOMAIN_FREQ_CAPPS.name}
        />
    );
};
