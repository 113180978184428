import { ROUTE_FORMATTERS } from "@app/core/routing";
import { BuyerSeatList, useGetSeatBuyerSeatListsByIdQuery } from "@app/core/services";
import { CONTROLS_ROUTES } from "@app/features/controls/constants";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

interface UseBuyerSeatListsDetailsDrawer {
    open: boolean;
    showDefinitionContent: boolean;
    data: BuyerSeatList;
    isLoading: boolean;
    handleClose: () => void;
    handleDefinition: () => void;
    handleBack: () => void;
    handlePostDeleteEvent: () => void;
    handleAfterOpenChange: (open: boolean) => void;
}

export const useBuyerSeatListsDetailsDrawer = (seatId: string, id: string): UseBuyerSeatListsDetailsDrawer => {
    const history = useHistory();
    const [open, setOpen] = useState<boolean>(false);
    const [showDefinitionContent, setShowDefinitionContent] = useState<boolean>(false);

    const { data, isFetching } = useGetSeatBuyerSeatListsByIdQuery(id);

    const handleClose = () => {
        setOpen(false);
        setShowDefinitionContent(false);
    };

    const handleDefinition = () => setShowDefinitionContent(true);

    const handleBack = () => setShowDefinitionContent(false);

    const handlePostDeleteEvent = () => {
        history.replace(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_TABS(seatId, CONTROLS_ROUTES.BUYER_SEAT_LIST));
    };

    const handleAfterOpenChange = (open: boolean) => {
        !open &&
            history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_TABS(seatId, CONTROLS_ROUTES.BUYER_SEAT_LIST));
    };

    useEffect(() => {
        setOpen(true);
    }, []);

    return {
        open,
        showDefinitionContent,
        data: data as BuyerSeatList,
        isLoading: isFetching,
        handleClose,
        handleDefinition,
        handleBack,
        handlePostDeleteEvent,
        handleAfterOpenChange,
    };
};
