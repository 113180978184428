import { FC } from "react";
import { AdSource } from "@app/core/services";
import { Col, Row, Tag } from "antd";
import { DetailsItem } from "../DetailsItem";
import { format } from "@rubicon/utils";

interface Props {
    adSource: AdSource;
}

export const SeatAdSourcesLabelsDetails: FC<Props> = ({ adSource }) => {
    return (
        <>
            <Row>
                <Col sm={8}>
                    <DetailsItem label="Waterfall Pre-Filter Mode">
                        {adSource.strictMode ? "Match Inventory Label(s)" : "All Inventory"}
                    </DetailsItem>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DetailsItem label="Labels">
                        {adSource.labelValues && adSource.labelValues.length > 0
                            ? adSource.labelValues.map((label) => (
                                  <Tag key={label.id}>
                                      {label.label?.key}: {label.value}
                                  </Tag>
                              ))
                            : format.constants.DEFAULT_DASHES}
                    </DetailsItem>
                </Col>
            </Row>
        </>
    );
};
