import { FC } from "react";
import { Form } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAdSourceSalesContactField } from "./useAdSourceSalesContactField";
import { LoadOnScrollSelect } from "@app/core/components";

export const AdSourceSalesContactField: FC = () => {
    const { options, isFetching, value, onChange, searchValue, onSearch, hasMore, loadMore } =
        useAdSourceSalesContactField();
    return (
        <Form.Item
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_SALES_CONTACT}
            data-sdet="ad-source-sales-contact-field"
            label="Sales Contact"
        >
            <LoadOnScrollSelect
                allowClear
                filterOption={false}
                hasMore={hasMore}
                loading={isFetching}
                loadMore={loadMore}
                onChange={onChange}
                onSearch={onSearch}
                options={options}
                placeholder="Select Sales Contact"
                searchValue={searchValue}
                showSearch
                value={value}
            />
        </Form.Item>
    );
};
