import { Form } from "antd";
import { FC } from "react";
import { AppStoreSelect } from "@app/features/inventory/components/Fields";
import { SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const SupplyAppStore: FC = () => {
    return (
        <Form.Item
            name={SUPPLY_FORM_FIELDS.APP_STORE.name}
            label={SUPPLY_FORM_FIELDS.APP_STORE.label}
            rules={[{ required: true, message: SUPPLY_FORM_FIELDS.APP_STORE.rulesMessage }]}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 }}
        >
            <AppStoreSelect placeholder={SUPPLY_FORM_FIELDS.APP_STORE.placeholder} />
        </Form.Item>
    );
};
