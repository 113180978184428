import { Select } from "antd";
import { FC } from "react";
import { LabeledValue } from "antd/lib/select";
import { useTypeSelect } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm/ExchangeProtectionsFormSections/InventoryLimitsSection/Fields/AdSourceTypeLimitsField/TypeSelect/useTypeSelect";

interface TypeSelectProps {
    value: number | undefined;
    onChange: (value: number, option: LabeledValue | LabeledValue[]) => void;
}

export const TypeSelect: FC<TypeSelectProps> = ({ onChange, value }) => {
    const { isFetching, options } = useTypeSelect();
    return (
        <Select
            allowClear
            showSearch
            value={value}
            options={options}
            onChange={onChange}
            loading={isFetching}
            optionFilterProp="label"
            style={{ width: "100%" }}
            placeholder="Select Type..."
        />
    );
};
