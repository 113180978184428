import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useSeatAuthContext } from "@app/core/auth";
import {
    selectDealFilterCount,
    clearFilters,
    selectDealSort,
    selectDealPagination,
    selectDealSearchFilter,
    selectDealFilters,
    DealsState,
} from "@app/features/deals/DealListPage/reducer";
import { Seat, GetSeatAllDealsParams } from "@app/core/services/console";
import { skipToken } from "@reduxjs/toolkit/query";
import { LabeledValue } from "antd/lib/select";

interface UseDealListTableFilters {
    filterCount: number;
    handleClearFilters: () => void;
    seatAllDealsParams: typeof skipToken | GetSeatAllDealsParams;
}

export const getFilter = (filter: LabeledValue | null) => {
    if (filter) {
        return String(filter.value);
    }
};

const getFilterMultiSelects = (filter: LabeledValue[] | null, field = "value") => {
    if (filter && filter.length > 0) {
        return filter.map((filter) => String(filter[field]));
    }
};

const getSeatDealsParams = (
    context: Seat | null,
    filters: DealsState["filters"],
    sort: DealsState["sort"],
    pagination: DealsState["pagination"],
    searchFilter: DealsState["searchFilter"]
) => {
    if (!context) {
        return skipToken;
    }

    return {
        maxResults: pagination.maxResults,
        page: pagination.page,
        orderBy: sort.orderBy,
        isAscending: sort.asc,
        seatId: context.id,
        filterDealOrigin: getFilter(filters.filterDealOrigin),
        filterBuyerStatus: getFilterMultiSelects(filters.filterBuyerStatus, "label"),
        pricingType: getFilter(filters.pricingType),
        filterStartDate: filters.filterStartDate,
        filterEndDate: filters.filterEndDate,
        filterDsp: getFilterMultiSelects(filters.filterDsp),
        filterStatus: getFilterMultiSelects(filters.filterStatus),
        searchKeyword: searchFilter?.searchKeyword,
        filterPriority: getFilterMultiSelects(filters.filterPriorities),
        filterDealType: getFilterMultiSelects(filters.filterDealType),
    };
};

export const useDealListTableFilters = (): UseDealListTableFilters => {
    const { context } = useSeatAuthContext();
    const dispatch = useAppDispatch();
    const dealFilterCount = useAppSelector(selectDealFilterCount);
    const dealFilters = useAppSelector(selectDealFilters);
    const dealSearchFilter = useAppSelector(selectDealSearchFilter);
    const dealSort = useAppSelector(selectDealSort);
    const dealPagination = useAppSelector(selectDealPagination);

    const handleClearFilters = () => {
        dispatch(clearFilters());
    };

    return {
        filterCount: dealFilterCount,
        handleClearFilters,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        seatAllDealsParams: getSeatDealsParams(context, dealFilters, dealSort, dealPagination, dealSearchFilter),
    };
};
