import { useAppDispatch, useAppSelector } from "@app/core/store";
import { setSettingsCurrencyConversionMode } from "@app/features/seatAdSources/reducer";
import { LabeledValue } from "antd/lib/select";
import { CURRENCY_CONVERSION_MODE_OPTIONS } from "./constants";
import { CurrencyConversionModes } from "@app/core/clients/console";

interface UseCurrencyConversionModeField {
    handleChangeValue: (value: string) => void;
    options: LabeledValue[];
    value: CurrencyConversionModes | null;
}

export const useCurrencyConversionModeField = (): UseCurrencyConversionModeField => {
    const dispatch = useAppDispatch();
    const currencyConversionModeType = useAppSelector(
        (state) => state.seatAdSources.settings.values.adSourcesCurrencyConversionMode
    );
    const handleChangeValue = (value: CurrencyConversionModes) => {
        dispatch(setSettingsCurrencyConversionMode(value));
    };

    return {
        options: CURRENCY_CONVERSION_MODE_OPTIONS,
        value: currencyConversionModeType,
        handleChangeValue,
    };
};
