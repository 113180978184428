import { FC } from "react";
import { Select } from "antd";
import { INVENTORY_CATEGORIES, InventoryCategoryTypes } from "../../../constants";
import { LabeledValue } from "antd/lib/select";

interface Props {
    onChange: (category: InventoryCategoryTypes) => void;
    value: LabeledValue;
}
export const SearchCategorySelect: FC<Props> = ({ onChange, value }) => {
    return (
        <Select
            data-sdet="SecondaryConditionSelect"
            options={[
                INVENTORY_CATEGORIES.all,
                INVENTORY_CATEGORIES.channels,
                INVENTORY_CATEGORIES.publishers,
                INVENTORY_CATEGORIES.brands,
                INVENTORY_CATEGORIES.supply,
                INVENTORY_CATEGORIES.adUnits,
            ]}
            onChange={({ value }) => {
                onChange(value as InventoryCategoryTypes);
            }}
            style={{ width: "100%" }}
            defaultValue={INVENTORY_CATEGORIES.all}
            value={value}
            labelInValue
        />
    );
};
