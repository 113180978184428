import { BulkOperationType } from "@app/core/services";
import { Form } from "antd";
import { FC } from "react";
import { BulkOperationCopyReview } from "./BulkOperationCopyReview";
import { BulkOperationEntitiesEditReview } from "./BulkOperationEntitiesEditReview";

export const BulkOperationConfirmationStep: FC<{ goToPrev: () => void }> = ({ goToPrev }) => {
    const form = Form.useFormInstance();
    const { type } = form.getFieldsValue(true);

    if (type === BulkOperationType.Edit) {
        return <BulkOperationEntitiesEditReview goToPrev={goToPrev} />;
    }

    if (type === BulkOperationType.Copy) {
        return <BulkOperationCopyReview goToPrev={goToPrev} />;
    }

    return null;
};
