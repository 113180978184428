import { Targeting, TargetingMode } from "@app/core/services";
import { gray7 } from "@rubicon/antd-components";
import { toTitleCase } from "@rubicon/utils/dist/string";
import { Form, Typography } from "antd";
import { FC } from "react";
import { ReadOnlyControlsTargeting } from "../ReadOnlyControlsTargeting";

interface Props {
    targeting: Targeting[];
    targetingMode: TargetingMode;
}
export const ReadOnlyBrandSafetyTargeting: FC<Props> = ({ targeting, targetingMode }) => {
    if (!targeting.length) {
        return (
            <Typography.Text style={{ color: gray7 }}>
                This rule applies to all inventory, since no specific targeting is being applied.
            </Typography.Text>
        );
    }
    const targetingBlocksCount = targeting.length;
    const reusableTargetingBlocksCount = targeting.filter(({ name }) => name != null).length;
    return (
        <>
            <Form layout="inline" style={{ marginBottom: "10px", width: "100%" }}>
                <Form.Item label="Targeting Mode:">{toTitleCase(targetingMode.name)}</Form.Item>
                <Form.Item label="Targeting Blocks:">
                    {targetingBlocksCount}{" "}
                    {reusableTargetingBlocksCount ? `(${reusableTargetingBlocksCount} reusable)` : null}
                </Form.Item>
            </Form>
            {targeting.map((item, index) => (
                <ReadOnlyControlsTargeting key={index} targeting={item} />
            ))}
        </>
    );
};
