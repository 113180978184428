import { toQueryString } from "../console/utils";
import { AD_UNIT_TIMING_STATS_SNAPSHOT, timingStatsApi } from "./timingStatsApi";
import {
    TimingStats,
    TimingStatsApiParams,
    TimingStatsSnapshot,
    TimingStatsSnapshots,
    TimingStatsSnapshotsResponse,
} from "./types";

export const timingStatsEndpoints = timingStatsApi.injectEndpoints({
    endpoints: (builder) => ({
        getTimingStats: builder.query<TimingStats, TimingStatsApiParams>({
            query: (query) => {
                const { entityType, entityId, ...rest } = query;
                return `${entityType}/${entityId};${toQueryString(rest)}`;
            },
        }),
        getTimingStatsAdUnitSnapshots: builder.query<TimingStatsSnapshotsResponse[], TimingStatsSnapshots>({
            query: ({ id, multiplicity }) => `adUnits/${id}/snapshots;multiplicity=${multiplicity}`,
            providesTags: [AD_UNIT_TIMING_STATS_SNAPSHOT],
        }),
        getTimingStatsAdUnitSnapshot: builder.query<TimingStats, TimingStatsSnapshot>({
            query: ({ id, snapshotId, multiplicity }) =>
                `adUnits/${id}/snapshots/${snapshotId};multiplicity=${multiplicity}`,
            providesTags: [AD_UNIT_TIMING_STATS_SNAPSHOT],
        }),
        createTimingStatsAdUnitSnapshot: builder.mutation<void, TimingStatsSnapshots>({
            query: ({ id, multiplicity }) => {
                return {
                    url: `adUnits/${id}/snapshots;multiplicity=${multiplicity}`,
                    method: "POST",
                    body: { message: "" },
                };
            },
            invalidatesTags: (_, err) => (err ? [] : [AD_UNIT_TIMING_STATS_SNAPSHOT]),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetTimingStatsQuery,
    useGetTimingStatsAdUnitSnapshotsQuery,
    useGetTimingStatsAdUnitSnapshotQuery,
    useCreateTimingStatsAdUnitSnapshotMutation,
} = timingStatsEndpoints;
