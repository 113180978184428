import { FC } from "react";
import { Col, Typography } from "antd";
import { format } from "@rubicon/utils";
import { AdSource } from "@app/core/services";
import { useIsAdResponsePriceOverrideVisible } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/PricingSection/Fields";

const { Text, Paragraph } = Typography;

interface Props {
    adSource: AdSource;
}

export const AdResponsePriceOverrideSelect: FC<Props> = ({ adSource }) => {
    const isVisible = useIsAdResponsePriceOverrideVisible(adSource?.type?.id);

    if (!isVisible) {
        return null;
    }

    return (
        <Col sm={8}>
            <Text strong>Ad Response Price Override</Text>
            <Paragraph>{format.asSelf(adSource?.adResponsePriceOverrideType?.name)}</Paragraph>
        </Col>
    );
};
