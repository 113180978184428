import { END_OF_DAY, NO_END_DATE } from "@app/core/components/constants";
import { css } from "@emotion/css";
import { Form, Row, Col, Button } from "antd";
import { MomentDatePicker } from "@app/core/components/MomentDatePicker";
import { useEndDatePicker } from "./useEndDatePicker";
import { disablePastDates } from "@app/core/utils/disablePastDates";

const datePickerPopupClassname = css`
    .ant-picker-datetime-panel .ant-picker-time-panel .ant-picker-content .ant-picker-time-panel-column {
        &:after {
            height: 230px;
        }
    }
`;

export const EndDatePicker = () => {
    const { isSelectingEndDate, onSelectNoEndDate, isRequired, onChange } = useEndDatePicker();

    return (
        <Form.Item
            data-sdet="deal-end-date-field"
            rules={[{ required: isRequired, message: "End Date is required" }]}
            className={css(`
        label {
            width: 100%;
        }
    `)}
            label={
                isRequired ? (
                    "End Date"
                ) : (
                    <Row justify="space-between" style={{ width: "100%", height: "22px" }}>
                        <Col>End Date</Col>

                        <Col>
                            <Button onClick={onSelectNoEndDate} type="link" size="small">
                                {isSelectingEndDate ? "Select End Date" : "No End Date"}
                            </Button>
                        </Col>
                    </Row>
                )
            }
            name={["dealUnderEdit", "endTime"]}
        >
            <MomentDatePicker
                showNow={false}
                disabled={isSelectingEndDate}
                placeholder={isSelectingEndDate ? NO_END_DATE : "Select date"}
                showTime={{ defaultValue: END_OF_DAY }}
                style={{ width: "100%" }}
                disabledDate={disablePastDates}
                onChange={onChange}
                popupClassName={datePickerPopupClassname}
            />
        </Form.Item>
    );
};
