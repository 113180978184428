import { useHistory, useLocation, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ItemType } from "antd/lib/menu/interface";

interface UseSeatAdSourcesNavMenu {
    menuItems: ItemType[];
    handleClick: (arg: { key: string }) => void;
    getSelectedKey: () => string;
}

export const useSeatAdSourcesNavMenu = (): UseSeatAdSourcesNavMenu => {
    const location = useLocation();
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();

    const handleClick = ({ key }) => {
        switch (key) {
            case "pacingSummary": {
                history.push(ROUTE_FORMATTERS.SEAT_AD_SOURCES_PACING(seatId));
                break;
            }
            default: {
                history.push(ROUTE_FORMATTERS.SEAT_AD_SOURCES(seatId));
                break;
            }
        }
    };

    const getSelectedKey = (): string => {
        return location.pathname.includes("pacing") ? "pacingSummary" : "adSources";
    };

    const menuItems: ItemType[] = [
        {
            label: "Ad Sources",
            key: "adSources",
        },
        {
            label: "Pacing Summary",
            key: "pacingSummary",
        },
    ];

    return {
        menuItems,
        handleClick,
        getSelectedKey,
    };
};
