import { FC } from "react";
import { Col, Row, Space, Typography } from "antd";
import { LabeledValue } from "antd/lib/select";
import { Conditions, TargetingDimensionTypeLabels } from "../../../constants";
import { PlatformTargetingDimension, UsedDimensions } from "../../../types";
import { ConditionSelect } from "../../ConditionSelect";
import { MoreMenu } from "../../MoreMenu";
import { PlatformSelect } from "./PlatformSelect";

interface Props {
    dimension: PlatformTargetingDimension;
    onChangeCondition: (value: Conditions) => void;
    onChangeDimensionValues: (value: LabeledValue[]) => void;
    onClickDelete: () => void;
    onClickDownload: () => void;
    onClickDuplicate: () => void;
    usedDimensions: UsedDimensions;
}

export const PlatformsDimensionRow: FC<Props> = ({
    dimension,
    onChangeCondition,
    onChangeDimensionValues,
    onClickDelete,
    onClickDownload,
    onClickDuplicate,
    usedDimensions,
}) => {
    return (
        <Row gutter={8}>
            <Col flex="150px">
                <Typography.Text strong>{TargetingDimensionTypeLabels.Platforms}</Typography.Text>
            </Col>
            <Col flex="128px">
                <ConditionSelect
                    onChange={onChangeCondition}
                    isExcludesDisabled={
                        dimension.condition !== Conditions.Excludes && usedDimensions.platforms?.excludes
                    }
                    isIncludesDisabled={
                        dimension.condition !== Conditions.Includes && usedDimensions.platforms?.includes
                    }
                    value={dimension.condition}
                />
            </Col>
            <Col flex="10">
                <PlatformSelect onChange={onChangeDimensionValues} value={dimension.values} />
            </Col>
            <Col flex="146px" style={{ display: "flex", justifyContent: "flex-end" }}>
                <Space>
                    <MoreMenu
                        onClickDelete={onClickDelete}
                        onClickDownload={onClickDownload}
                        onClickDuplicate={onClickDuplicate}
                        isDuplicateDisabled={usedDimensions.platforms?.includes && usedDimensions.platforms?.excludes}
                    />
                </Space>
            </Col>
        </Row>
    );
};
