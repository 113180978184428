import { FC } from "react";
import { Card, Col, Row } from "antd";
import { useGeneralSection } from "./useGeneralSection";
import { Code, CodeType, Description, Name, Status } from "./Fields";
import { GUTTER, TWO_COL_SIZES } from "@app/features/inventory/constants";
import { InternalOnly } from "@app/features/inventory/components/InternalOnly";

export const GeneralSection: FC = () => {
    const { isInternalOnly, isEditMode, hasCode } = useGeneralSection();
    return (
        <Card bordered={false}>
            <Row gutter={GUTTER}>
                <Col {...TWO_COL_SIZES}>
                    <Name />
                    <CodeType />
                    {hasCode && <Code />}
                    <Description />
                    <Status />
                </Col>
                {isInternalOnly && isEditMode && (
                    <Col {...TWO_COL_SIZES}>
                        <InternalOnly>
                            <Code wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 }} />
                        </InternalOnly>
                    </Col>
                )}
            </Row>
        </Card>
    );
};
