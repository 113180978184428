import { FC } from "react";
import { Form, Select } from "antd";
import { ColProps } from "antd/lib/grid/col";
import { TagRender } from "@app/core/components";
import { Rule } from "rc-field-form/lib/interface";
import { LabeledValue, SelectProps } from "antd/lib/select";
import { SelectAllButtonDropdownRender } from "@app/core/components/SelectAllButtonDropdownRender";
import { useCategories } from "@app/features/inventory/InventoryBrandSafety/InventoryAdvancedBrandSafetyPageForm/BrandSafetyFormFields/useCategories";

const { useWatch, useFormInstance } = Form;

export interface IabCategorieTreeProps extends SelectProps {
    name: string;
    rules?: Rule[];
    label: string;
    labelCol?: ColProps;
    wrapperCol?: ColProps;
}

export const IabCategorieTree: FC<IabCategorieTreeProps> = ({
    name,
    rules,
    label,
    labelCol,
    wrapperCol,
    placeholder,
    ...rest
}) => {
    const value: LabeledValue[] = useWatch(name);
    const { setFieldValue } = useFormInstance();
    const { search, options, isFetching, setSearch } = useCategories();

    const handleMenuChange = (value: LabeledValue[]): void => setFieldValue(name, value);

    return (
        <Form.Item name={name} rules={rules} label={label} labelCol={labelCol} wrapperCol={wrapperCol}>
            <Select
                allowClear
                showSearch
                labelInValue
                dropdownRender={(menu) => (
                    <SelectAllButtonDropdownRender<LabeledValue>
                        menu={menu}
                        value={value}
                        search={search}
                        options={options}
                        onChange={handleMenuChange}
                        getPrimaryKey={(v) => v.value}
                    />
                )}
                mode="multiple"
                options={options}
                searchValue={search}
                onSearch={setSearch}
                loading={isFetching}
                filterOption={false}
                tagRender={TagRender}
                placeholder={placeholder}
                autoClearSearchValue={false}
                {...rest}
            />
        </Form.Item>
    );
};
