import { FC } from "react";
import { Card } from "antd";
import { CustomPixels } from "@app/features/inventory/HierarchyForms/PublisherChannelForm/PublisherChannelFormSections/AdvancedFeaturesSection/Sections/CustomPixelsSection/Fields";

export const CustomPixelsSection: FC = () => {
    return (
        <Card bordered={false}>
            <CustomPixels />
        </Card>
    );
};
