import { FC } from "react";
import { Layout, Space } from "antd";
import { Footer } from "@app/core/components";
import { PerformanceSection } from "./PerformanceSection";
import { DetailsDrawer } from "@app/features/inventory/DetailsDrawer";
import { SupplyTrafficAlertBanner } from "@app/features/inventory/components/SupplyTrafficAlertBanner";
import InventoryHealthToolbar from "@app/features/inventory/InventoryHealth/InventoryHealthPage/InventoryHealthToolbar";
import { InventoryHealthInventoryTable } from "@app/features/inventory/InventoryHealth/InventoryHealthPage/InventoryHealthInventoryTable";

const InventoryHealthPage: FC = () => {
    return (
        <>
            <InventoryHealthToolbar />
            <SupplyTrafficAlertBanner sourceSelf="Seat" />
            <Layout.Content>
                <Space direction="vertical" size="large" style={{ width: "100%" }}>
                    <PerformanceSection />
                    <InventoryHealthInventoryTable />
                    <DetailsDrawer />
                </Space>
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};

export default InventoryHealthPage;
