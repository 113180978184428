import { DayPartingTableColumns, DayPartTarget } from "@app/core/services/console";
import { DAY_PARTING_COLUMN_HEADERS, HALF_HOURS_COUNT, TimeZoneModeIds, TimeZoneModeLabels } from "../constants";

const dayPartTargetsToTextRepresentation = (targets: DayPartTarget[]) => {
    // Create an object that uses dayOfWeek key to store an array of that day's halfHourOfDay values.
    const halfHoursOfDayByDayIndexObj = targets.reduce<Record<number, number[]>>(
        (obj, { dayOfWeek, halfHourOfDay }) => {
            if (dayOfWeek in obj) {
                obj[dayOfWeek].push(halfHourOfDay);
            } else {
                obj[dayOfWeek] = [halfHourOfDay];
            }
            return obj;
        },
        {}
    );
    // Create an object that stores a text representation of each day's day parting.
    const dayPartingLabelsByDayIndex: Record<number, string> = {};
    DAY_PARTING_COLUMN_HEADERS.forEach((_, dayIndex) => {
        if (!(dayIndex in halfHoursOfDayByDayIndexObj) || dayIndex === 0) return;
        const halfHoursOfDay: number[] = halfHoursOfDayByDayIndexObj[dayIndex];
        const length: number = halfHoursOfDay.length;
        let dayPartingStr = "";
        if (length === 1) {
            dayPartingStr = `${halfHourOfDayToLabelValue(halfHoursOfDay[0])} - ${halfHourOfDayToLabelValue(
                halfHoursOfDay[0] + 1
            )}`;
        } else if (length === HALF_HOURS_COUNT) {
            dayPartingStr = "";
        } else {
            halfHoursOfDay.forEach((halfHourOfDay: number, i: number) => {
                if (i === length - 1) {
                    dayPartingStr += halfHourOfDayToLabelValue(halfHourOfDay + 1);
                } else if (i === 0 || halfHourOfDay + 1 < halfHoursOfDay[i + 1]) {
                    if (halfHourOfDay + 1 === halfHoursOfDay[i + 1]) {
                        dayPartingStr += `${halfHourOfDayToLabelValue(halfHourOfDay)} - `;
                    } else if (i === 0) {
                        dayPartingStr += `${halfHourOfDayToLabelValue(halfHourOfDay)} - ${halfHourOfDayToLabelValue(
                            halfHourOfDay + 1
                        )}, `;
                    } else {
                        dayPartingStr += `${halfHourOfDayToLabelValue(halfHourOfDay + 1)}, `;
                    }
                } else if (halfHourOfDay + 1 === halfHoursOfDay[i + 1]) {
                    if (halfHourOfDay - 1 === halfHoursOfDay[i - 1]) return;
                    dayPartingStr += `${halfHourOfDayToLabelValue(halfHourOfDay)} - `;
                }
            });
        }
        dayPartingLabelsByDayIndex[dayIndex] = dayPartingStr;
    });
    // Compare the day parting label values of adjacent days to identify common day parting across
    // ranges of days, identified with a " - ", and day parting schedules that are distinct from
    // those of adjacent days, identified with a ", ".
    let dayPartingLabelValue = "";
    const usedDayIndices: number[] = Object.keys(dayPartingLabelsByDayIndex)
        .map((i) => Number(i))
        .sort();
    if (usedDayIndices.length === 1) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const dayIndex: number = usedDayIndices.pop();
        dayPartingLabelValue = `${DAY_PARTING_COLUMN_HEADERS[dayIndex]} ${dayPartingLabelsByDayIndex[dayIndex]}`;
    } else if (usedDayIndices.length > 1) {
        const usedDayIndicesLength: number = usedDayIndices.length;
        usedDayIndices.forEach((dayIndex: number, i: number) => {
            if (!(dayIndex in dayPartingLabelsByDayIndex)) return;
            const dayName: string = DAY_PARTING_COLUMN_HEADERS[dayIndex];
            if (i + 1 === usedDayIndicesLength) {
                if (
                    usedDayIndicesLength === DAY_PARTING_COLUMN_HEADERS.length - 1 &&
                    dayPartingLabelValue === `${DAY_PARTING_COLUMN_HEADERS[1]} - `
                ) {
                    dayPartingLabelValue = `Every Day ${dayPartingLabelsByDayIndex[dayIndex]}`;
                } else {
                    dayPartingLabelValue += `${dayName} ${dayPartingLabelsByDayIndex[dayIndex]}`;
                }
                return;
            } else if (i === 0) {
                if (dayPartingLabelsByDayIndex[dayIndex] === dayPartingLabelsByDayIndex[dayIndex + 1]) {
                    dayPartingLabelValue = `${dayName} - `;
                    return;
                }
            } else {
                if (dayPartingLabelsByDayIndex[dayIndex] === dayPartingLabelsByDayIndex[dayIndex + 1]) {
                    if (dayPartingLabelsByDayIndex[dayIndex] === dayPartingLabelsByDayIndex[dayIndex - 1]) return;
                    dayPartingLabelValue += `${dayName} - `;
                    return;
                }
            }
            if (dayPartingLabelsByDayIndex[dayIndex]) {
                dayPartingLabelValue += `${dayName} ${dayPartingLabelsByDayIndex[dayIndex]}, `;
            } else {
                dayPartingLabelValue += `${dayName}, `;
            }
        });
    }
    return dayPartingLabelValue;
};

export const dayPartingTargetsToLabelValue = (targets: DayPartTarget[], timeZoneMode: TimeZoneModeIds) => {
    if (targets.length === 0) return "Select time, day or week";
    const timeZoneModeSuffix: string =
        timeZoneMode === TimeZoneModeIds.UTC
            ? TimeZoneModeLabels.UTC
            : timeZoneMode === TimeZoneModeIds.ClientTimeZone
            ? TimeZoneModeLabels.ClientTimeZone
            : "";
    return `${dayPartTargetsToTextRepresentation(targets)} (${timeZoneModeSuffix})`;
};

export const getDayPartingTableDataTemplate = () =>
    Array.from({ length: HALF_HOURS_COUNT })
        .map((_, halfHourOfDay: number) => {
            const time: string = halfHourOfDayToLabelValue(halfHourOfDay);
            return DAY_PARTING_COLUMN_HEADERS.map((__, dayOfWeek: number) => ({
                dayOfWeek,
                halfHourOfDay,
                time,
            }));
        })
        .map((rowDataArr) =>
            rowDataArr.reduce(
                (resultObj, { dayOfWeek, halfHourOfDay, time }) => ({
                    [dayOfWeek]: false,
                    halfHourOfDay,
                    "Time/Day": time,
                    key: `${halfHourOfDay}-${time}`,
                    ...resultObj,
                }),
                {}
            )
        ) as DayPartingTableColumns[];

export const halfHourOfDayToLabelValue = (halfHourOfDay: number) =>
    `${halfHourOfDay < 20 ? "0" : ""}${Math.floor(halfHourOfDay / 2)}:${halfHourOfDay % 2 ? "30" : "00"}`;
