import { ValueMetric1 } from "@app/core/components/charts/DualAxesChart";
import { useGetVastErrorsQuery } from "@app/core/services";
import {
    VastErrors,
    VastErrorsEntityTypes,
    VastErrorsUrlSearchParams,
} from "@app/core/services/diagnostics/vastErrors/types";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { VastErrorDescriptionsByCode } from "@app/core/services/diagnostics/vastErrors/constants";
import { ErrorDefinition } from "./ErrorDefinition";
import { InventoryDetailsDrawerType, selectInventoryDetailsDrawerType } from "../../../reducer";
import { useAppSelector } from "@app/core/store";
import { useInventoryDetailsDrawerUrlId } from "../../../useInventoryDetailsDrawerUrlId";

const TOP_OFFENDERS_COUNT = 10;

export interface Offender {
    errorCount: number;
    id: number;
    name: string;
    adomain: string;
    creativeId: string;
}

const formatData = (data: VastErrors[], networkFilter: number | null) => {
    const timeSeries: { x: number; data: { [k: number]: number } }[] = [];
    const allCodes = {};

    for (let i = 0; i < data.length; i++) {
        const delta = (data[i].ntime - data[i].otime) / 1000;
        const entry = { x: data[i].ntime, data: {} };

        for (const napObj in data[i].errorCountsByNap) {
            if ((networkFilter && data[i].errorCountsByNap[napObj].networkId == networkFilter) || !networkFilter) {
                for (const errorCode in data[i].errorCountsByNap[napObj].errors) {
                    if (!isNaN(delta) && delta != 0) {
                        entry.data[errorCode] = data[i].errorCountsByNap[napObj].errors[errorCode];
                    }
                    allCodes[errorCode] = 0;
                }

                timeSeries.push(entry);
            }
        }
    }

    function getSeriesDataForCode(code: string) {
        const retArray: [number, number][] = [];

        for (let i = 0; i < timeSeries.length; i++) {
            retArray.push([timeSeries[i].x, timeSeries[i].data[code] ? timeSeries[i].data[code] : 0]);
        }

        return retArray;
    }

    const retSeries: { name: string; data: [number, number][] }[] = [];

    for (const eachCode in allCodes) {
        retSeries.push({
            name: eachCode,
            data: getSeriesDataForCode(eachCode),
        });
    }

    const res: ValueMetric1[] = [];

    for (const code of retSeries) {
        for (const data of code.data) {
            res.push({ time: String(data[0]), value1: data[1], name1: code.name });
        }
    }

    return res.sort((a, b) => Number(a.time) - Number(b.time));
};

const formatOffenders = (data: VastErrors[], networkFilter: number | null): Offender[] => {
    const offenders: Offender[] = [];
    const errorsMap = {};

    if (data && Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].errorCountsByNap) {
                for (const index in data[i].errorCountsByNap) {
                    const napCount = data[i].errorCountsByNap[index];
                    if (networkFilter && napCount.networkId !== networkFilter) {
                        continue;
                    }
                    const networkName = napCount.networkName;
                    const adomain = napCount.adomain;
                    const errorCount = napCount.totalErrorCount;
                    const creativeId = napCount.creativeId;

                    if (!errorsMap[napCount.networkId]) {
                        errorsMap[napCount.networkId] = {};
                        errorsMap[napCount.networkId][napCount.adomain] = {};
                        errorsMap[napCount.networkId][napCount.adomain][creativeId] = {
                            errorCount,
                            id: napCount.networkId,
                            name: networkName,
                            adomain,
                            creativeId,
                        };
                    } else if (!errorsMap[napCount.networkId][napCount.adomain]) {
                        errorsMap[napCount.networkId][napCount.adomain] = {};
                        errorsMap[napCount.networkId][napCount.adomain][creativeId] = {
                            errorCount,
                            id: networkName,
                            name: networkName,
                            adomain,
                            creativeId,
                        };
                    } else if (!errorsMap[napCount.networkId][napCount.adomain][creativeId]) {
                        errorsMap[napCount.networkId][napCount.adomain][creativeId] = {
                            errorCount,
                            id: networkName,
                            name: networkName,
                            adomain,
                            creativeId,
                        };
                    } else {
                        errorsMap[napCount.networkId][napCount.adomain][creativeId].errorCount +=
                            napCount.totalErrorCount;
                    }
                }
            }
        }
    }

    for (const netId in errorsMap) {
        const network = errorsMap[netId];
        for (const dom in network) {
            const domain = network[dom];
            for (const cid in domain) {
                const creative = domain[cid];
                offenders.push(creative);
            }
        }
    }
    offenders.sort(function (a, b) {
        return b.errorCount - a.errorCount;
    });

    return offenders;
};

const formatEntityType = (inventoryDetailsDrawerType: InventoryDetailsDrawerType) => {
    switch (inventoryDetailsDrawerType) {
        case InventoryDetailsDrawerType.PUBLISHER:
        case InventoryDetailsDrawerType.CHANNEL:
            return VastErrorsEntityTypes.Publisher;
        case InventoryDetailsDrawerType.BRAND:
        case InventoryDetailsDrawerType.BRAND_CHANNEL:
            return VastErrorsEntityTypes.Brand;
        case InventoryDetailsDrawerType.SUPPLY:
            return VastErrorsEntityTypes.Supply;
        case InventoryDetailsDrawerType.AD_UNIT:
            return VastErrorsEntityTypes.AdUnit;
        case InventoryDetailsDrawerType.SEAT:
        default:
            return VastErrorsEntityTypes.Seat;
    }
};

export const useVastErrors = () => {
    const { search } = useLocation();
    const unitId = useInventoryDetailsDrawerUrlId();
    const { seatId } = useParams<{ seatId: string }>();

    const inventoryDetailsDrawerType = useAppSelector(selectInventoryDetailsDrawerType) as InventoryDetailsDrawerType;
    const [entityType, setEntityType] = useState<VastErrorsEntityTypes>(
        (new URLSearchParams(search).get(VastErrorsUrlSearchParams.entityType) as VastErrorsEntityTypes) ||
            formatEntityType(inventoryDetailsDrawerType)
    );
    const [entityId, setEntityId] = useState<number | null>(
        Number(new URLSearchParams(search).get(VastErrorsUrlSearchParams.entityId) || unitId || seatId)
    );
    const [networkFilter, setNetworkFilter] = useState<number | null>(null);
    const { data, isFetching } = useGetVastErrorsQuery(
        {
            entityType: entityType,
            entityId: entityType === VastErrorsEntityTypes.Seat ? Number(seatId) : (entityId as number),
        },
        { skip: !entityType || (entityType !== VastErrorsEntityTypes.Seat && !entityId) }
    );

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(search);
        if (urlSearchParams.get(VastErrorsUrlSearchParams.entityType)) {
            setEntityType(urlSearchParams.get(VastErrorsUrlSearchParams.entityType) as VastErrorsEntityTypes);
            setEntityId(null);
        }
        if (urlSearchParams.get(VastErrorsUrlSearchParams.entityId)) {
            setEntityId(Number(urlSearchParams.get(VastErrorsUrlSearchParams.entityId)));
        }
    }, [search]);

    const formattedData = useMemo(() => {
        if (!data) {
            return [];
        }
        return formatData(data, networkFilter);
    }, [data, networkFilter]);

    const { networks, errorDefinitions } = useMemo(() => {
        if (!data) {
            return { networks: [], errorDefinitions: [] };
        }

        const networkList: { [k: string]: string } = {};
        const errorDefinitions: ErrorDefinition[] = [];
        const errorSet = new Set<number>();

        for (let i = 0; i < data.length; i++) {
            for (const napObj in data[i].errorCountsByNap) {
                if (!networkList[data[i].errorCountsByNap[napObj].networkId]) {
                    networkList[data[i].errorCountsByNap[napObj].networkId] =
                        data[i].errorCountsByNap[napObj].networkName;
                    for (const errorCode of Object.keys(data[i].errorCountsByNap[napObj].errors)) {
                        errorSet.add(Number(errorCode));
                    }
                }
            }
        }

        const formattedNetworkList: { id: number; name: string }[] = [];

        for (const [k, v] of Object.entries(networkList)) {
            formattedNetworkList.push({ id: +k, name: v });
        }

        for (const errorCode of Array.from(errorSet).sort()) {
            const error = VastErrorDescriptionsByCode[errorCode];
            if (error) {
                errorDefinitions.push({ code: errorCode, description: error });
            }
        }

        return { networks: formattedNetworkList, errorDefinitions };
    }, [data]);

    const topOffenders = useMemo(() => {
        if (!data) {
            return [];
        }

        const offenders = formatOffenders(data, networkFilter);

        const topTenOffenders = offenders.splice(0, TOP_OFFENDERS_COUNT);

        return topTenOffenders;
    }, [data, networkFilter]);

    return {
        entityType,
        setEntityType,
        entityId,
        setEntityId,
        data: formattedData,
        isFetching,
        networks,
        setNetworkFilter,
        topOffenders,
        errorDefinitions,
    };
};
