import { Typography } from "antd";
import { DollarOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { useAppSelector } from "@app/core/store";
import { useCurrency } from "@app/features/seatAdSources/useCurrency";
import { CurrencyConversionModes } from "@app/core/clients/console";
import { gold6 } from "@rubicon/antd-components";

export const DealCurrencyMode = () => {
    const { dealCurrencyConversionMode, dealPreferredCurrency } = useAppSelector(
        (state) => state.deals.settings.values
    );
    const { getCurrencyById } = useCurrency();

    let label = "";

    if (dealCurrencyConversionMode === CurrencyConversionModes.ORIGINATING_CURRENCY) {
        label = "Originating Currency";
    } else {
        const currency = getCurrencyById(dealPreferredCurrency);
        label = `Converted to ${currency?.code}`;
    }

    return (
        <div
            className={css`
                display: flex;
                align-items: center;
                gap: 4px;
                font-size: 14px;
                color: ${gold6};
            `}
        >
            <DollarOutlined />
            <Typography.Text style={{ color: gold6 }}>{label}</Typography.Text>
        </div>
    );
};
