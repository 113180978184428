import { FC, Key, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    DerivedDemandDeal,
    removeDemandDealTouchedRecordInfo,
    selectDemandDealSelectedRows,
    selectDemandDealTouchedRecordsInfo,
    setDemandDealFieldBulkChange,
    setDemandDealFieldChange,
} from "../../../reducer";
import useTableLocalSearch from "@app/core/components/hooks/useTableLocalSearch";
import { DemandDeal } from "@app/core/services";
import { Form, Space, Table, TableColumnsType } from "antd";
import { BulkOperationEntitiesEditTableTools } from "./BulkOperationEntitiesEditTableTools";
import { BulkOperationBulkEditButtonModal } from "../BulkOperationBulkEditModal/BulkOperationBulkEditButtonModal";
import { tableCellFormItemStyle } from "@app/features/controls/constants";
import { BUYER_DEMAND_DEAL_FORM_ITEM_COMMON_CONFIG, TO_OPTION_MAPPER } from "../../../constants";
import { DEMAND_DEALS_COLUMNS_BASE } from "../../BulkOperationSetupStep/BulkOperationEntitySelectSection/FindOperationEntitiesTab/OperationEntitiesTable";
import { getColumnsConfig } from "../../../helpers";
import moment from "moment-timezone";
import { useBulkOperationEntitiesEditTableRevert } from "./useBulkOperationEntitiesEditTableRevert";

const readonlyColumns: TableColumnsType = DEMAND_DEALS_COLUMNS_BASE.slice(1);

const FORM_ITEM_NAME = "demandDealUpdates";

export const getDerivedDemandDealFromApi = (demandDeal: DemandDeal): DerivedDemandDeal => {
    const { currencyType, startTime, endTime, timeZone, sharedSeats, sharedMarketplaces } = demandDeal;

    return {
        ...demandDeal,
        timeZone: TO_OPTION_MAPPER.timeZone(timeZone),
        currencyType: TO_OPTION_MAPPER.currencyType(currencyType),
        sharedSeats: sharedSeats?.map(TO_OPTION_MAPPER.sharedSeats),
        sharedMarketplaces: sharedMarketplaces?.map(TO_OPTION_MAPPER.sharedMarketplaces),
        startTime: moment(startTime).tz(timeZone.code),
        endTime: endTime ? moment(endTime).tz(timeZone.code) : null,
    };
};

export const BulkOperationDemandDealEntitiesEditTable: FC = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const touchedRecordsInfo = useSelector(selectDemandDealTouchedRecordsInfo);
    const demandDeals = useSelector(selectDemandDealSelectedRows);
    const derivedDemandDeals = useMemo(() => demandDeals.map(getDerivedDemandDealFromApi), [demandDeals]);

    const {
        dataSource: filteredItems,
        handleChange,
        value: searchTerm,
    } = useTableLocalSearch<DerivedDemandDeal>([(demandDeal) => demandDeal.externalName || ""], derivedDemandDeals);
    const { handleValueChange, handleRevert, handleRevertAll, hasChangedField } =
        useBulkOperationEntitiesEditTableRevert<DerivedDemandDeal>(
            FORM_ITEM_NAME,
            derivedDemandDeals,
            touchedRecordsInfo,
            setDemandDealFieldChange,
            removeDemandDealTouchedRecordInfo
        );

    const [{ selectedRowKeys, selectedRows }, setSelectedRowsInfo] = useState<{
        selectedRowKeys: Key[];
        selectedRows: DerivedDemandDeal[];
    }>({ selectedRowKeys: [], selectedRows: [] });

    const form = Form.useFormInstance();

    // useMemo to reduce unnecessary useEffect execution
    const { fieldsToEdit, demandDealUpdates } = useMemo(() => form.getFieldsValue(true), [form]);

    // To make sure form values only get initialized on landing, and do not get reset when doing table search
    useEffect(() => {
        const tableFieldsValue = derivedDemandDeals.reduce((acc, derivedDemandDeal) => {
            // To make sure when going to other step and (or) change selected rows, the other selected rows prev changed values do not get reset
            const prevItemValue = demandDealUpdates?.[derivedDemandDeal.id];
            acc[derivedDemandDeal.id] = prevItemValue || derivedDemandDeal;

            return acc;
        }, {});

        form.setFieldValue("demandDealUpdates", tableFieldsValue);
    }, [derivedDemandDeals, demandDealUpdates, form]);

    const editableFields = ["externalName", ...(fieldsToEdit || [])];

    return (
        <>
            <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                <BulkOperationEntitiesEditTableTools
                    bulkEditCount={selectedRows.length}
                    entityLabel="Demand Deal"
                    entitiesCount={demandDeals.length}
                    handleSearch={handleChange}
                    handleRevertAll={handleRevertAll}
                    isDisabledRevertAll={!hasChangedField}
                    searchTerm={searchTerm}
                >
                    <BulkOperationBulkEditButtonModal<DerivedDemandDeal>
                        editableFields={editableFields}
                        entityLabel="Demand Deal"
                        formItemConfig={BUYER_DEMAND_DEAL_FORM_ITEM_COMMON_CONFIG}
                        formItemName={FORM_ITEM_NAME}
                        seatId={Number(seatId)}
                        selectedRows={selectedRows}
                        setBulkChange={setDemandDealFieldBulkChange}
                    />
                </BulkOperationEntitiesEditTableTools>
                <Table
                    className={tableCellFormItemStyle}
                    dataSource={filteredItems}
                    columns={getColumnsConfig<DerivedDemandDeal>(
                        Number(seatId),
                        editableFields,
                        BUYER_DEMAND_DEAL_FORM_ITEM_COMMON_CONFIG,
                        readonlyColumns,
                        FORM_ITEM_NAME,
                        handleValueChange,
                        touchedRecordsInfo,
                        handleRevert
                    )}
                    rowKey="id"
                    rowSelection={{
                        selectedRowKeys,
                        onChange: (selectedRowKeys, selectedRows: DerivedDemandDeal[]) =>
                            setSelectedRowsInfo({ selectedRowKeys, selectedRows }),
                    }}
                    showSorterTooltip={false}
                    scroll={{ x: "max-content" }}
                    pagination={false}
                />
            </Space>
        </>
    );
};
