import { useContext } from "react";
import { FormInstance } from "antd";
import { useWatch } from "antd/lib/form/Form";
import { AdSourceAbbreviation, AdSourceFloorType, Currency, MarketplaceInfo, AdSourceKey } from "@app/core/services";
import { AD_SOURCE_FIELDS } from "../../constants";
import { AdSourceFormsContext, AdSourcesForm, MarketplaceInfoContext } from "..";
import { useIsDealUsedInOtherAdSources } from "./useIsDealUsedInOtherAdSources";

interface UseSyncRelatedFormData {
    adSourceForm: FormInstance<AdSourcesForm>;
    isAdSourceFormInEditMode: boolean;
    adSourceId: number | undefined;
    adSourceFields: {
        type: AdSourceAbbreviation | null | undefined;
        fixedCpm: string | null | undefined;
        currency: Currency | null | undefined;
        floorType: AdSourceFloorType | null | undefined;
    };
    isDealDrawerOpen: boolean;
    isMultiDealAdSource: boolean;
    singleAttachedDealSyncData: {
        marketplaceInfo: MarketplaceInfo | null;
        isOneToOne: boolean;
        isUsedInOtherAdSources: boolean;
    };
    readOnlyAdSourceFields: AdSourceKey[] | null | undefined;
}

export const useSyncRelatedFormData = (): UseSyncRelatedFormData => {
    const { adSourceForm, adSourceFormMode, adSource } = useContext(AdSourceFormsContext).forms;
    const adSourceId = adSource?.id;
    const [marketplaceInfoMode] = useContext(MarketplaceInfoContext).marketplaceInfoModeState;

    const type = useWatch<AdSourceAbbreviation | undefined>(AD_SOURCE_FIELDS.TYPE.name, adSourceForm);
    const fixedCpm = useWatch<string | number | null | undefined>(AD_SOURCE_FIELDS.FIXED_CPM.name, adSourceForm);
    const currency = useWatch<Currency | null | undefined>(AD_SOURCE_FIELDS.CURRENCY.name, adSourceForm);
    const floorType = useWatch<AdSourceFloorType | null | undefined>(AD_SOURCE_FIELDS.FLOOR_TYPE.name, adSourceForm);
    const marketplaceInfoList = useWatch<MarketplaceInfo[] | undefined>(AD_SOURCE_FIELDS.DEMAND.name, adSourceForm);

    const dealCount = marketplaceInfoList?.length ?? 0;

    const isDealDrawerOpen = marketplaceInfoMode.name !== "closed";

    const isMultiDealAdSource = dealCount > 1;

    const singleAttachedMarketplaceInfo = dealCount === 1 && marketplaceInfoList?.[0] ? marketplaceInfoList[0] : null;
    const isSingleAttachedDealUsedInOtherAdSources = useIsDealUsedInOtherAdSources(
        adSourceId,
        singleAttachedMarketplaceInfo?.deal.id
    );
    const isSingleAttachedDealOneToOne =
        dealCount === 1 && !isSingleAttachedDealUsedInOtherAdSources && Boolean(singleAttachedMarketplaceInfo);

    return {
        adSourceForm,
        isAdSourceFormInEditMode: adSourceFormMode === "edit",
        adSourceId,
        adSourceFields: {
            type,
            fixedCpm: typeof fixedCpm === "number" ? String(fixedCpm) : fixedCpm,
            currency,
            floorType,
        },
        isDealDrawerOpen,
        isMultiDealAdSource,
        singleAttachedDealSyncData: {
            marketplaceInfo: singleAttachedMarketplaceInfo,
            isOneToOne: isSingleAttachedDealOneToOne,
            isUsedInOtherAdSources: isSingleAttachedDealUsedInOtherAdSources,
        },
        readOnlyAdSourceFields: adSource?.readOnlyFields,
    };
};
