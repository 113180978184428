import { FC } from "react";
import { Divider } from "antd";
import styled from "@emotion/styled";
import { gray8 } from "@rubicon/antd-components";
import { useOpenHelpCenter } from "@app/core/services/console";
import { useSeatAuthContext } from "@app/core/auth";

type FooterProps = {
    className?: string;
    id?: string;
    sdet?: string;
};

const StyledWrapper = styled.div`
    text-align: center;
    margin-top: 3rem;
    .ant-divider-vertical {
        border-left: 1px solid ${gray8};
    }
    a {
        text-decoration: underline;
    }
`;

const copyright = `© ${new Date().getFullYear()} Magnite, Inc., All rights reserved.`;

export const Footer: FC<FooterProps> = ({ className = "", id = "", sdet = "" }) => {
    const openHelpCenter = useOpenHelpCenter();
    const { context } = useSeatAuthContext();
    const isSeat = Boolean(context);

    return (
        <StyledWrapper
            className={className ? `footer ${className}` : "footer"}
            id={id}
            data-sdet={`${sdet} antd:footer`}
        >
            {copyright}{" "}
            <a
                href="https://www.magnite.com/legal/advertising-technology-privacy-policy/"
                target="_blank"
                rel="noreferrer"
            >
                Privacy Statement
            </a>
            <Divider type="vertical" />
            <a onClick={() => openHelpCenter(true, isSeat)}>Help Center</a>
            <Divider type="vertical" />
            <a href="https://www.magnite.com/contact-us/" target="_blank" rel="noreferrer">
                Contact Us
            </a>
        </StyledWrapper>
    );
};
