import { FC } from "react";
import { InternalOnly } from "@app/features/inventory/components/InternalOnly";
import { CreativeBlockMode } from "./Fields";

export const BrandSafetyInternalOnly: FC = () => {
    return (
        <InternalOnly>
            <CreativeBlockMode />
        </InternalOnly>
    );
};
