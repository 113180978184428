import { Drawer } from "antd";
import { css } from "@emotion/css";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { DrawerLayout } from "@app/core/components";
import { closeManageColumns } from "./reducer";
import { ManageColumns } from "@app/features/inventory/ManageColumns/ManageColumns";

const drawerClasses = css`
    .ant-drawer-body {
        padding: 0;
    }
`;

export const ManageColumnsDrawer = () => {
    const dispatch = useAppDispatch();
    const visible = useAppSelector((state) => state.manageColumns.isDrawerOpen);

    const handleClose = () => dispatch(closeManageColumns());

    return (
        <Drawer width="25%" open={visible} closable={false} onClose={handleClose} className={drawerClasses}>
            <DrawerLayout closeHandler={handleClose} title="Manage Columns">
                <ManageColumns />
            </DrawerLayout>
        </Drawer>
    );
};
