import { Form, Table } from "antd";
import { useTransparencyDefault } from "./useTransparencyDefault";
import {
    getFormColumns,
    getReadOnlyColumns,
    getTableReadOnlyRows,
    getTableFormRows,
    getFormContentOptions,
    TableEditModeFormRows,
    TableRows,
} from "./helpers";
import { EDIT_MODE } from "../../tagParams/constants";
import { getParamFromSearch } from "../../tagParams/helpers";
import { css } from "@emotion/css";
import { useContentTransparencyRules } from "../ContentTransparencyRules/useContentTransparencyRules";
import { TransparencyExtendedUserDrawer } from "./TransparencyExtendedUserDrawer/TransparencyExtendedUserDrawer";

export const TRANSPARENCY_DEFAULT_TABLE_SDET = "transparency-default-list-table";

export const styles = css`
    .ant-table-cell {
        vertical-align: top;
        text-transform: capitalize;
    }
    .ant-col-md-12 {
        max-width: 100%;
    }
`;
export const TransparencyDefaultTable = () => {
    const { isLoading, data, initialValues, extendedIds, search, handleAdd, handleRemove, handleExtendedUserSelect } =
        useTransparencyDefault();
    const { data: contentTransparencyRules } = useContentTransparencyRules();

    const isEdit = getParamFromSearch(search, EDIT_MODE);
    const contentOptions = getFormContentOptions(contentTransparencyRules || []);
    if (!data) {
        return null;
    }

    return (
        <Form.List name="transparency" initialValue={initialValues}>
            {(fields) => (
                <>
                    <Table<TableEditModeFormRows | TableRows>
                        className={styles}
                        data-sdet={TRANSPARENCY_DEFAULT_TABLE_SDET}
                        size="small"
                        rowKey="id"
                        columns={isEdit ? getFormColumns() : getReadOnlyColumns()}
                        dataSource={
                            isEdit
                                ? getTableFormRows(
                                      initialValues,
                                      {
                                          contentOptions,
                                          handleExtendedUserSelect,
                                          handleRemove,
                                          extendedIds,
                                      },
                                      fields
                                  )
                                : getTableReadOnlyRows(data)
                        }
                        loading={isLoading}
                        scroll={{ x: 1000 }}
                        showSorterTooltip={false}
                        pagination={false}
                    />
                    <TransparencyExtendedUserDrawer handleAdd={handleAdd} handleRemove={handleRemove} />
                </>
            )}
        </Form.List>
    );
};

export default TransparencyDefaultTable;
