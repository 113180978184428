import { FC } from "react";
import { Form, Input } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAdSourceExternalContractNumberField } from "./useAdSourceExternalContractNumberField";

export const AdSourceExternalContractNumberField: FC = () => {
    const { value, handleChange, handleBlur } = useAdSourceExternalContractNumberField();
    return (
        <Form.Item
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_EXTERNAL_CONTACT_NUMBER}
            data-sdet="ad-source-external-contact-number-field"
            label="External Contract Number"
        >
            <Input value={value} onChange={handleChange} onBlur={handleBlur} style={{ width: "100%" }} />
        </Form.Item>
    );
};
