import { FC } from "react";
import { Form } from "antd";
import { BaseOptionType } from "antd/lib/select";
import { BaseOption } from "@app/core/services";
import { AD_SOURCE_FIELDS, AdSourceTypeNames, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { ReadonlyControl, SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { useAdSourceTypeFilter } from "./useAdSourceTypeFilter";
import { useAdSourceTypeSelect } from "./useAdSourceTypeSelect";

const tagBasedTypesIds = [7, 9, 11, 8, 5];
const programmaticTypesIds = [6, 12, 2, 3, 17, 4, 10, 15, 16];
const thirdPartyTypesIds = [14, 13];

const groupTypes = (types: BaseOption[]): BaseOptionType[] => {
    const tagBasedTypes = types.filter((type) => tagBasedTypesIds.includes(type.id));
    const programmaticTypes = types.filter((type) => programmaticTypesIds.includes(type.id));
    const thirdPartyTypes = types.filter((type) => thirdPartyTypesIds.includes(type.id));
    const other = types.filter(
        (type) =>
            !tagBasedTypesIds.includes(type.id) &&
            !programmaticTypesIds.includes(type.id) &&
            !thirdPartyTypesIds.includes(type.id)
    );
    return [
        ...other,
        {
            label: "Programmatic (Full Featured)",
            options: programmaticTypes,
        },
        {
            label: "Tag Based",
            options: tagBasedTypes,
        },
        {
            label: "Third Party",
            options: thirdPartyTypes,
        },
    ].filter((group) => {
        if ("options" in group) {
            return group.options.length > 0;
        }
        return true;
    });
};

export const TypeSelect: FC = () => {
    const { isLoading, isReadonly, types, onChange } = useAdSourceTypeSelect();
    const filteredTypes = useAdSourceTypeFilter(types);

    return (
        <Form.Item
            {...VERTICAL_LAYOUT_FIX}
            required={!isReadonly}
            rules={[{ required: true, message: "Type is required" }]}
            label={AD_SOURCE_FIELDS.TYPE.label}
            name={AD_SOURCE_FIELDS.TYPE.name}
        >
            {isReadonly ? (
                <ReadonlyControl<AdSourcesForm["type"]> getLabel={(type) => AdSourceTypeNames[type.id]} />
            ) : (
                <SelectWithValue<BaseOptionType>
                    fieldAsLabel={({ id }) => AdSourceTypeNames[id]}
                    fieldAsValue={["id"]}
                    data-sdet="adsource-type"
                    placeholder="Select…"
                    showSearch
                    filterOption={(input, option) =>
                        (String(option?.label) ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    loading={isLoading}
                    options={groupTypes(filteredTypes)}
                    onChange={onChange}
                />
            )}
        </Form.Item>
    );
};
