import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormInputFieldChange } from "@app/features/deals/DealForm/reducer";
import { useDispatchOnBlur } from "@app/features/deals/DealForm/hooks";
import { ChangeEvent } from "react";

interface UseAdSourceBundleIdOverrideField {
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleBlur: () => void;
    value: string | number;
}

export const useAdSourceBundleIdOverrideField = (): UseAdSourceBundleIdOverrideField => {
    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BUNDLE_ID_OVERRIDE]
    );
    const { onChange, onBlur } = useDispatchOnBlur(value);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        onChange(value);
    };
    const handleBlur = () => {
        onBlur(dealFormInputFieldChange, CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BUNDLE_ID_OVERRIDE);
    };

    return {
        handleChange,
        handleBlur,
        value,
    };
};
