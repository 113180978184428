import { consoleApi, SEAT_WATERFALL_PACING_TAG } from "./console";
import { toQueryString } from "./utils";

export interface WaterfallPacing {
    adSourceId: number;
    adSourceName: string;
    adSourceType: number;
    assignedUserId: number;
    averageImpsPerDay: number;
    bkEnd: string;
    bkStart: string;
    bkVolume: number;
    customCurveImps: number;
    customCurveStartDate: string;
    dailyCapValue: number;
    dailyImpsRemaining: number;
    daysElapsed: number;
    daysRemaining: number;
    dealId: number;
    deliveryPercentage: number;
    endDate: string;
    entityCode: string;
    entityId: number;
    entityName: string;
    entityType: number;
    impsPerDayToFulfill: number;
    impsRemaining: number;
    lastIndexed: string;
    marketplaceId: number;
    marketplaceName: string;
    monthImpCount: number;
    networkId: number;
    pacingDelta: string;
    pacingPeriod: number;
    pacingProgress: PacingProgress | null;
    pacingStatusPercentage: string;
    pacingType: number;
    recordedTotalImpCount: number;
    reqOpportunity: number;
    seatCode: number;
    seatId: number;
    seatName: string;
    startDate: string;
    timeZone: string;
    todayImpCount: number;
    todaysGoal: number;
    totalImpCount: number;
    yearImpCount: number;
    yesFills: number;
    yesImpressions: number;
    yesOpp: number;
    yesTries: number;
}

export type PacingProgress = {
    deliveryState: number;
    expectedProgress: number;
    pacingDeltaPercentage: number | null;
    pacingStatus: PacingStatus;
    goalCompletionPct: number;
    timeCompletionPct: number;
    opportunitiesLessThanTarget: true;
} | null;

export type PacingStatus = {
    id: number;
    name: string;
} | null;

export interface WaterfallPacingParams {
    seatId: number;
    networks?: number[] | string[];
    deals?: number[] | string[];
    userId?: number[] | string[];
    keyword?: number | string;
    fmt?: "csv";
}

const toCommaDelimitedQueryString = (target: Pick<WaterfallPacingParams, "networks" | "deals" | "keyword">): string =>
    Object.entries(target)
        .filter(([, value]) => value !== undefined && value !== null)
        .map(([key, value]) => {
            if (Array.isArray(value)) {
                return `${encodeURIComponent(key)}=${value.map((v) => encodeURIComponent(v)).join(",")}`;
            }
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join(";");

const getWaterfallPacingUrl = (waterfallPacingParams: WaterfallPacingParams) => {
    const { seatId, networks, deals, ...params } = waterfallPacingParams;

    const commaDelimitedParams = toCommaDelimitedQueryString({ networks, deals });
    const { fmt: _, ...redactedParams } = params;
    const otherParams = toQueryString(redactedParams);
    const queryString = commaDelimitedParams ? `${commaDelimitedParams};${otherParams}` : otherParams;
    const downloadParam = params.fmt ? `?fmt=${params.fmt}` : "";

    return `seats/${seatId}/waterfall/pacing;${queryString}${downloadParam}`;
};

export const waterfallPacingApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getWaterfallPacing: builder.query<WaterfallPacing[], WaterfallPacingParams>({
            query: getWaterfallPacingUrl,
            providesTags: () => [{ type: SEAT_WATERFALL_PACING_TAG }],
        }),
        getWaterfallPacingAsCsv: builder.query<Blob, WaterfallPacingParams>({
            query: (params: WaterfallPacingParams) => ({
                url: `${getWaterfallPacingUrl(params)}?fmt=csv`,
                responseHandler: (response) => response.blob(),
            }),
        }),
    }),
});

export const { useGetWaterfallPacingQuery, useLazyGetWaterfallPacingAsCsvQuery } = waterfallPacingApi;
