import { RootState } from "@app/core/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DEFAULT_PAGE_SIZE } from "@app/core/components/constants";
import { BrandSafetyCollapsePanelKeys } from "@app/features/inventory/InventoryBrandSafety/InventoryBrandSafetyPage/BrandSafetyCollapsePanel";

export enum BrandDrawerMode {
    VIEW = "view",
    EDIT = "edit",
}

export interface InventoryBrandSafetyTableSort {
    orderBy: string;
    asc: boolean | null;
}

export interface InventoryBrandSafetyTablePagination {
    page: number;
    max: number;
}

export interface InventoryBrandSafetySearchFilter {
    keyword: string;
}

interface InventoryBrandSafetyState {
    mode: BrandDrawerMode;
    isOpenBrandDrawer: boolean;
    openedDrawerBrand: {
        id: number;
        name: string;
    };
    isSubmitting: boolean;
    sort: InventoryBrandSafetyTableSort;
    pagination: InventoryBrandSafetyTablePagination;
    searchFilter: InventoryBrandSafetySearchFilter;
    editActiveKey: BrandSafetyCollapsePanelKeys | null;
    isActionsDisabled: boolean;
}

interface PayloadDrawer {
    mode?: BrandDrawerMode;
    id: number;
    name: string;
    isOpenBrandDrawer: boolean;
}

const initialState: InventoryBrandSafetyState = {
    mode: BrandDrawerMode.VIEW,
    isOpenBrandDrawer: false,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    openedDrawerBrand: { id: null, name: "" },
    isSubmitting: false,
    sort: {
        orderBy: "",
        asc: null,
    },
    pagination: {
        page: 1,
        max: DEFAULT_PAGE_SIZE,
    },
    searchFilter: {
        keyword: "",
    },
    editActiveKey: null,
    isActionsDisabled: false,
};

const inventoryBrandSafetySlice = createSlice({
    name: "inventoryBrandSafety",
    initialState,
    reducers: {
        openBrandDrawer: (state, action: PayloadAction<PayloadDrawer>) => {
            const { mode = BrandDrawerMode.VIEW, isOpenBrandDrawer, ...rest } = action.payload;

            state.mode = mode;
            state.isOpenBrandDrawer = isOpenBrandDrawer;
            state.openedDrawerBrand = {
                ...rest,
            };
        },
        closeBrandDrawer: (state) => {
            state.mode = BrandDrawerMode.VIEW;
            state.isOpenBrandDrawer = false;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.openedDrawerBrand = { id: null, name: "" };
        },
        handleSubmitting: (state, action: PayloadAction<boolean>) => {
            state.isSubmitting = action.payload;
        },
        setInventoryBrandSafetyTableSort: (state, action: PayloadAction<InventoryBrandSafetyTableSort>) => {
            state.sort = action.payload;
        },
        setInventoryBrandSafetyTablePagination: (state, action: PayloadAction<InventoryBrandSafetyTablePagination>) => {
            state.pagination = action.payload;
        },
        setInventoryBrandSafetyearchFilter: (state, action: PayloadAction<InventoryBrandSafetySearchFilter>) => {
            state.searchFilter = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setEditActiveKey: (state, action: PayloadAction<BrandSafetyCollapsePanelKeys | null>) => {
            state.editActiveKey = action.payload;
        },
        setIsActionsDisabled: (state, action: PayloadAction<boolean>) => {
            state.isActionsDisabled = action.payload;
        },
    },
});

export const selectInventoryBrandSafetyBrandDrawerMode = (state: RootState) => state.inventoryBrandSafety.mode;
export const selectInventoryBrandSafetyBrandDrawerStatus = (state: RootState) =>
    state.inventoryBrandSafety.isOpenBrandDrawer;
export const selectInventoryBrandSafetyBrandDrawerDetails = (state: RootState) =>
    state.inventoryBrandSafety.openedDrawerBrand;
export const selectInventoryBrandSafetyBrandDrawerSubmitting = (state: RootState) =>
    state.inventoryBrandSafety.isSubmitting;
export const selectInventoryBrandSafetyTableSort = (state: RootState) => state.inventoryBrandSafety.sort;
export const selectInventoryBrandSafetyPagination = (state: RootState) => state.inventoryBrandSafety.pagination;
export const selectInventoryBrandSafetySearchFilter = (state: RootState) => state.inventoryBrandSafety.searchFilter;
export const selectInventoryBrandSafetyEditActiveKey = (state: RootState) => state.inventoryBrandSafety.editActiveKey;
export const selectInventoryBrandSafetyIsActionsDisabled = (state: RootState) =>
    state.inventoryBrandSafety.isActionsDisabled;

export const {
    openBrandDrawer,
    closeBrandDrawer,
    setEditActiveKey,
    setIsActionsDisabled,
    handleSubmitting,
    setInventoryBrandSafetyTableSort,
    setInventoryBrandSafetyearchFilter,
    setInventoryBrandSafetyTablePagination,
} = inventoryBrandSafetySlice.actions;
export default inventoryBrandSafetySlice.reducer;
