import { FC } from "react";
import { Form, InputNumber } from "antd";
import { ColProps } from "antd/lib/grid/col";
import { InputNumberProps } from "antd/lib/input-number";
import { LSA_WINDOW_SIZE_SECONDS } from "@app/features/inventory/HierarchyForms/constants";

export interface AdUnitLSAWindowSizeSecondsProps extends InputNumberProps {
    name: typeof LSA_WINDOW_SIZE_SECONDS;
    wrapperCol?: ColProps;
    labelCol?: ColProps;
    label: string;
}

export const AdUnitLSAWindowSizeSeconds: FC<AdUnitLSAWindowSizeSecondsProps> = ({
    name,
    label,
    wrapperCol,
    labelCol,
    ...rest
}) => {
    return (
        <Form.Item
            name={name}
            label={label}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            rules={[
                {
                    validator(_, value) {
                        if (value === null) return Promise.resolve();
                        if (value < 300)
                            return Promise.reject(new Error("The LSA Window Size Seconds must be greater than 300"));
                        return Promise.resolve();
                    },
                },
            ]}
        >
            <InputNumber style={{ width: "100%" }} {...rest} />
        </Form.Item>
    );
};
