import { ReadonlyControl } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { css } from "@emotion/css";
import { Form } from "antd";
import { MomentDatePicker } from "@app/core/components/MomentDatePicker";
import { DEAL_FORM_FIELDS } from "../../../../constants";
import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM } from "@app/core/components/constants";
import { disablePastDates } from "@app/core/utils/disablePastDates";
import { useSyncedFieldsProducer } from "@app/features/syncedFields/useSyncedFieldsProducer";
import moment from "moment-timezone";

export const StartDatePicker = () => {
    const { marketplaceInfoMode, dealObjectMode } = useMarketplaceInfoWidget();
    const isReadonly = marketplaceInfoMode.name === "view";
    const isDisabled = dealObjectMode === "edit";
    const { updateSyncedFields } = useSyncedFieldsProducer();
    return (
        <Form.Item label={DEAL_FORM_FIELDS.START_DATE.label} name={DEAL_FORM_FIELDS.START_DATE.name}>
            {isReadonly ? (
                <ReadonlyControl<moment.Moment> getLabel={(date) => date.format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM)} />
            ) : (
                <MomentDatePicker
                    disabled={isDisabled}
                    allowClear
                    showTime
                    className={css(`
                .ant-picker-input>input::placeholder {
                        color: gray;
                     }
                `)}
                    placeholder="Select Date..."
                    style={{ width: "100%" }}
                    disabledDate={disablePastDates}
                    onChange={(startDate: moment.Moment | null) => {
                        updateSyncedFields({ startDate });
                    }}
                />
            )}
        </Form.Item>
    );
};
