import { FC } from "react";
import { Modal, Typography } from "antd";

interface Props {
    isOpen: boolean;
    isDeleting: boolean;
    handleCancel: () => void;
    handleConfirm: () => void;
    name: string;
}

export const BrandSafetyDeleteModal: FC<Props> = ({ name, handleCancel, handleConfirm, isDeleting, isOpen }) => (
    <Modal
        destroyOnClose
        styles={{ body: { height: "200px" } }}
        cancelText={<span data-sdet="modal-cancel-button">Cancel</span>}
        okButtonProps={{
            danger: true,
        }}
        okText={<span data-sdet="modal-delete-button">Delete</span>}
        onCancel={handleCancel}
        onOk={handleConfirm}
        confirmLoading={isDeleting}
        title="Confirmation"
        open={isOpen}
    >
        Are you sure you want to delete <Typography.Text strong>{name}</Typography.Text>?
    </Modal>
);
