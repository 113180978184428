import { FC } from "react";
import { cardBodyStyle } from "@app/features/deals/DealForm";
import { Card, Col, Row } from "antd";
import { GUTTER } from "@app/features/deals/constants";
import {
    AdSourceEndDateField,
    AdSourceInventoryDistributionGroupsField,
    AdSourceMaxDurationField,
    AdSourceMinDurationField,
    AdSourceStartDateField,
    AdSourceTimeZoneField,
    AdSourceTotalImpressionsField,
} from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceDeliveryDetailsSection/Fields";
import { DealFormMode } from "../../../types";

export interface AdSourceDeliveryDetailsSectionProps {
    adSourceTotalImpressionsIsShown: boolean;
    mode: DealFormMode;
}

export const AdSourceDeliveryDetailsSection: FC<AdSourceDeliveryDetailsSectionProps> = ({
    adSourceTotalImpressionsIsShown,
    mode,
}) => {
    return (
        <Card
            bordered={false}
            styles={{ body: cardBodyStyle }}
            data-sdet="deal-form-ad-source-delivery-details-section"
        >
            <Row gutter={GUTTER}>
                <Col xs={24} sm={24} md={12} lg={6} xl={4} xxl={3}>
                    <AdSourceStartDateField />
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={4} xxl={3}>
                    <AdSourceEndDateField />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={16} xxl={18}>
                    <AdSourceTimeZoneField />
                </Col>
                {adSourceTotalImpressionsIsShown && (
                    <Col span={24}>
                        <AdSourceTotalImpressionsField />
                    </Col>
                )}
            </Row>
            <Row gutter={GUTTER}>
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                    <AdSourceMinDurationField />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                    <AdSourceMaxDurationField />
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                    <AdSourceInventoryDistributionGroupsField mode={mode} />
                </Col>
            </Row>
        </Card>
    );
};
