import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Session } from "@app/core/clients/console";
import { RootState } from "@app/core/store";
import { UserPreferenceMap } from "../services";
import { DEFAULT_TIME_ZONE } from "@app/core/components/constants";

export interface AuthClientState {
    session?: Session | null;
    isFetchingSession: boolean;
    isLoggingIn: boolean;
    isLoggingOut: boolean;
    isLoggedOutExplicitly: boolean;
    isLoggedInViaAuth0: boolean;
}

const initialState: AuthClientState = {
    session: undefined,
    isFetchingSession: false,
    isLoggingIn: false,
    isLoggingOut: false,
    isLoggedOutExplicitly: false,
    isLoggedInViaAuth0: false,
};

const _isRunningAsOther = (session?: Session | null) =>
    Boolean(session?.grantingUser && session?.user && session.grantingUser.id !== session.user.id);

const authClientSlice = createSlice({
    name: "authClient",
    initialState,
    reducers: {
        onFetchSession: (state) => {
            state.isFetchingSession = true;
        },
        onFetchSessionSuccess: (state, action: PayloadAction<Session>) => {
            state.session = action.payload;
            if (!state.session.user.userPreferenceMap.timezone) {
                state.session.user.userPreferenceMap.timezone = DEFAULT_TIME_ZONE.code;
            }
            state.isFetchingSession = false;
        },
        onFetchSessionError: (state) => {
            state.session = null;
            state.isFetchingSession = false;
        },
        onLogin: (state) => {
            state.isLoggingIn = true;
        },
        onLoginSuccess: (state, action: PayloadAction<{ session: Session; isAuth0: boolean }>) => {
            state.session = action.payload.session;
            state.isLoggedInViaAuth0 = action.payload.isAuth0;
            state.isLoggingIn = false;
        },
        onLoginError: (state) => {
            state.session = null;
            state.isLoggingIn = false;
        },
        onLogout: (state) => {
            state.isLoggingOut = true;
        },
        onLogoutSuccess: (state) => {
            state.session = null;
            state.isLoggingOut = false;
            state.isLoggedOutExplicitly = true;
        },
        onLogoutError: (state) => {
            // logout client-side anyways
            state.session = null;
            state.isLoggingOut = false;
            state.isLoggedOutExplicitly = true;
        },
        onRunAs: (state) => {
            state.isLoggingIn = true;
        },
        onRunAsSuccess: (state, action: PayloadAction<Session>) => {
            state.session = action.payload;
            state.isLoggingIn = false;
        },
        onRunAsError: (state) => {
            state.session = null;
            state.isLoggingIn = false;
        },
        setUserPreferences: (state, action: PayloadAction<UserPreferenceMap>) => {
            // TODO - remove this if possible, copied from existing code (used in user preference update request).
            // hopefully we can just refetch the session after updating preferences
            if (state.session?.user) {
                state.session.user.userPreferenceMap = { ...state.session.user.userPreferenceMap, ...action.payload };
                if (!state.session.user.userPreferenceMap.timezone) {
                    state.session.user.userPreferenceMap.timezone = DEFAULT_TIME_ZONE.code;
                }
            }
        },
    },
});

export const selectAuthClientSession = (state: RootState) => state.authClient.session;
export const selectAuthClientIsFetchingSession = (state: RootState) => state.authClient.isFetchingSession;
export const selectAuthClientIsLoggingIn = (state: RootState) => state.authClient.isLoggingIn;
export const selectAuthClientIsLoggingOut = (state: RootState) => state.authClient.isLoggingOut;
export const selectAuthClientIsLoggedOutExplicitly = (state: RootState) => state.authClient.isLoggedOutExplicitly;
export const selectUserTimezone = (state: RootState) =>
    state.authClient.session?.user.userPreferenceMap.timezone || DEFAULT_TIME_ZONE.code;
export const selectIsLoggedInViaAuth0 = (state: RootState) => state.authClient.isLoggedInViaAuth0;

export const deriveAuthClientHasFetchedSession = (state: RootState) => state.authClient.session !== undefined;
export const deriveAuthClientIsLoggedIn = (state: RootState) => Boolean(state.authClient.session);
export const deriveAuthClientIsLoggedOut = (state: RootState) => !Boolean(state.authClient.session);
export const deriveAuthClientIsRunningAsOther = (state: RootState) =>
    deriveAuthClientIsLoggedIn(state) && _isRunningAsOther(state.authClient.session);
export const deriveAuthClientIsRunningAsSelf = (state: RootState) =>
    deriveAuthClientIsLoggedIn(state) && !_isRunningAsOther(state.authClient.session);

export const {
    onFetchSession,
    onFetchSessionSuccess,
    onFetchSessionError,
    onLogin,
    onLoginSuccess,
    onLoginError,
    onLogout,
    onLogoutSuccess,
    onLogoutError,
    onRunAs,
    onRunAsSuccess,
    onRunAsError,
    setUserPreferences,
} = authClientSlice.actions;

export default authClientSlice.reducer;
