import { Component, ReactNode } from "react";
import { Router } from "react-router-dom";
import setupHashHistory from "./setupHashHistory";

type Props = {
    children: ReactNode;
};

class HashRouter extends Component<Props> {
    history = setupHashHistory();

    render() {
        return <Router history={this.history}>{this.props.children}</Router>;
    }
}

export default HashRouter;
