import { FC } from "react";
import { Seat } from "@app/core/services";
import { Form, Card, Row, Col } from "antd";
import { getYesNoLabel } from "@app/core/utils/labels";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import {
    CreativeBlockMode,
    CalculonUseRateOverride,
    UpperCalculonUseRateOverride,
} from "@app/features/inventory/InventorySeat/InventorySeatEditPage/AdvancedFeaturesForm/AdvancedFeaturesFormFields";
import { INVENTORY_FORM_FIELDS, SEAT_DETAILS_SECTION_COL_SIZES } from "@app/features/inventory/InventorySeat/constants";
import { useUserAccess } from "@app/core/auth";

interface CreativeControlSectionProps {
    seat: Seat;
    edit?: boolean;
}

export const CreativeControlSection: FC<CreativeControlSectionProps> = ({ seat, edit = false }) => {
    const { isSysAdmin } = useUserAccess();

    return (
        <Card bordered={false}>
            <Row gutter={16}>
                <Col {...SEAT_DETAILS_SECTION_COL_SIZES}>
                    <Form.Item
                        label={INVENTORY_FORM_FIELDS.ENABLE_ADVERTISER_WHITELIST.label}
                        name={INVENTORY_FORM_FIELDS.ENABLE_ADVERTISER_WHITELIST.name}
                    >
                        {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.enableAdvertisersWhitelist)}
                    </Form.Item>
                    <CreativeBlockMode seat={seat} edit={edit} />
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} xl={12} xxl={11}>
                            <CalculonUseRateOverride seat={seat} edit={edit} />
                        </Col>
                        <Col xs={24} sm={24} md={12} xl={12} xxl={11}>
                            <UpperCalculonUseRateOverride seat={seat} edit={edit} />
                        </Col>
                    </Row>
                    {isSysAdmin && (
                        <Form.Item
                            label={INVENTORY_FORM_FIELDS.IGNORE_BCAT_BADV_ENABLED.label}
                            name={INVENTORY_FORM_FIELDS.IGNORE_BCAT_BADV_ENABLED.name}
                        >
                            {edit ? (
                                <BooleanRadioGroup />
                            ) : (
                                getYesNoLabel(Boolean(seat.seatFlags?.ignoreBcatBadvEnabled))
                            )}
                        </Form.Item>
                    )}
                </Col>
            </Row>
        </Card>
    );
};
