import { css } from "@emotion/css";
import { Typography } from "antd";
import { FC } from "react";
import { gray10 } from "@rubicon/antd-components";

const className = css`
    color: ${gray10};
    font-weight: 500;
    font-size: 1rem;
`;

interface Props {
    title: string;
}

export const SectionTitle: FC<Props> = ({ title }) => {
    return <Typography.Text className={className}>{title}</Typography.Text>;
};
