import { Col, Form, Input, Row } from "antd";
import { FC } from "react";
import {
    Supply,
    SearchItemSeat,
    useSearchQuery,
    useGetSeatSuppliesQuery,
    useGetSeatBrandsQuery,
    Brand,
} from "@app/core/services";
import { BULK_OPERATION_FORM_ITEMS } from "../../../constants";
import { GenericLoadOnScrollField } from "../../../shared/GenericLoadOnScrollField";
import { CopyInventoryChildrenField } from "./CopyInventoryChildrenField";
import { useDestinationSeat } from "./useDestinationSeat";

export const BulkOperationCopySupplyForm: FC = () => {
    const form = Form.useFormInstance();
    const { seatId, destinationSeat } = useDestinationSeat(form);

    return (
        <>
            <Row>
                <Col xs={24} md={12}>
                    <Form.Item
                        {...BULK_OPERATION_FORM_ITEMS.SUPPLY_TO_COPY}
                        rules={[{ required: true, message: "Please select a Supply to Copy" }]}
                        wrapperCol={{ xs: 24 }}
                    >
                        <GenericLoadOnScrollField<Supply>
                            labelInValue
                            sdet="supply-field"
                            useGetOptionsQuery={useGetSeatSuppliesQuery}
                            extraParams={{ seatId, channels: false }}
                            optionMapper={({
                                name,
                                id,
                                brand: {
                                    name: brandName,
                                    publisher: { name: pubName, seat },
                                },
                            }: Supply) => ({
                                label: `${seat.name} > ${pubName} > ${brandName} > ${name}`,
                                value: id,
                            })}
                            onChange={() => form.setFieldsValue({ recursiveCopy: false, copyScope: null })}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={{ span: 10, offset: 2 }}>
                    <CopyInventoryChildrenField />
                </Col>
            </Row>
            <Form.Item
                {...BULK_OPERATION_FORM_ITEMS.NAME_FOR_NEW_SUPPLY}
                rules={[{ required: true, whitespace: true, message: "Please put a Name for New Supply" }]}
                wrapperCol={{ xs: 24, md: 12 }}
            >
                <Input data-sdet="name-for-new-supply-field" />
            </Form.Item>
            <Form.Item
                {...BULK_OPERATION_FORM_ITEMS.DESTINATION_SEAT}
                rules={[{ required: true, message: "Please select a Destination Seat Filter" }]}
                wrapperCol={{ xs: 24, md: 12 }}
            >
                <GenericLoadOnScrollField<SearchItemSeat>
                    labelInValue
                    sdet="destination-seat-filter-field"
                    useGetOptionsQuery={useSearchQuery}
                    extraParams={{ type: "seat" }}
                    optionMapper={({ name, id }: SearchItemSeat) => ({ label: name, value: id })}
                    onChange={() => form.setFieldValue(BULK_OPERATION_FORM_ITEMS.SELECT_BRAND_PARENT.name, undefined)}
                />
            </Form.Item>
            <Form.Item
                {...BULK_OPERATION_FORM_ITEMS.SELECT_BRAND_PARENT}
                rules={[{ required: true, message: "Please select a Brand Parent" }]}
                wrapperCol={{ xs: 24, md: 12 }}
            >
                <GenericLoadOnScrollField<Brand>
                    labelInValue
                    sdet="brand-parent-field"
                    useGetOptionsQuery={useGetSeatBrandsQuery}
                    extraParams={{ seatId: destinationSeat?.value || seatId, channels: false }}
                    optionMapper={({ name, id, publisher: { name: pubName, seat } }: Brand) => ({
                        label: `${seat.name} > ${pubName} > ${name}`,
                        value: id,
                    })}
                />
            </Form.Item>
        </>
    );
};
