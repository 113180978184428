import { FC } from "react";
import { Seat } from "@app/core/services";
import { Form, Card, Row, Col } from "antd";
import { getYesNoLabel } from "@app/core/utils/labels";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import { INVENTORY_FORM_FIELDS, SEAT_DETAILS_SECTION_COL_SIZES } from "@app/features/inventory/InventorySeat/constants";
import {
    UDFee,
    UdLidDiscoveryMode,
} from "@app/features/inventory/InventorySeat/InventorySeatEditPage/AdvancedFeaturesForm/AdvancedFeaturesFormFields";

interface UnifiedDecisioningSectionProps {
    seat: Seat;
    edit?: boolean;
}

export const UnifiedDecisioningSection: FC<UnifiedDecisioningSectionProps> = ({ seat, edit = false }) => {
    return (
        <Card bordered={false}>
            <Row gutter={16}>
                <Col {...SEAT_DETAILS_SECTION_COL_SIZES}>
                    <Form.Item
                        label={INVENTORY_FORM_FIELDS.UD_ENABLED.label}
                        name={INVENTORY_FORM_FIELDS.UD_ENABLED.name}
                    >
                        {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.udeEnabled)}
                    </Form.Item>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} xl={12} xxl={11}>
                            <UDFee seat={seat} edit={edit} />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} xl={12} xxl={11}>
                            <UdLidDiscoveryMode seat={seat} edit={edit} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={6} xl={5} xxl={4}>
                    <Form.Item
                        label={INVENTORY_FORM_FIELDS.ALLOW_BLIND_COMPETITION.label}
                        name={INVENTORY_FORM_FIELDS.ALLOW_BLIND_COMPETITION.name}
                    >
                        {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.allowBlindCompetition)}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} xl={5} xxl={4}>
                    <Form.Item
                        label={INVENTORY_FORM_FIELDS.MEDIATION_ENABLED.label}
                        name={INVENTORY_FORM_FIELDS.MEDIATION_ENABLED.name}
                    >
                        {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.mediationEnabled)}
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    );
};
