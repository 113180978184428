import { BuyerSeat, BuyerSeatList, useDeleteSeatBuyerSeatListMutation } from "@app/core/services";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Flex, Table, Tag, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { COLUMN_KEYS } from "./constants";
import { useBuyerSeatListsPage } from "./useBuyerSeatListsPage";
import { FC } from "react";
import { NONE } from "@app/core/components/constants";
import { Link, Route } from "react-router-dom";
import { ROUTES, ROUTE_FORMATTERS } from "@app/core/routing";
import { BuyerSeatListsDetailsDrawer } from "./BuyerSeatListsDetailsDrawer";
import { useUserAccess } from "@app/core/auth";
import { BrandSafetyDeleteModal } from "../BrandSafetyDeleteModal";
import { useBrandSafetyDeleteModal } from "../BrandSafetyDeleteModal/useBrandSafetyDeleteModal";
import { usePagination, useTableChanges } from "@app/core/components/hooks";
import { format } from "@rubicon/utils";

export interface DeleteCandidate {
    id: number;
    name: string;
}

interface TableActionsProps {
    candidate: DeleteCandidate;
    handleEdit: (id: number) => void;
    handleDeleteModal: (candidate: DeleteCandidate) => void;
}

const TableActions: FC<TableActionsProps> = ({ candidate, handleEdit, handleDeleteModal }) => {
    return (
        <Button.Group>
            <Tooltip title="Edit">
                <Button icon={<EditOutlined />} onClick={() => handleEdit(candidate.id)} />
            </Tooltip>
            <Tooltip title="Delete">
                <Button icon={<DeleteOutlined />} onClick={() => handleDeleteModal(candidate)} />
            </Tooltip>
        </Button.Group>
    );
};

export const BuyerSeatListsTag: FC<{ buyerSeats: BuyerSeat[] }> = ({ buyerSeats }) => {
    if (!buyerSeats || !buyerSeats.length) {
        return <>{NONE}</>;
    }
    const tags = buyerSeats.map(({ id, code, name }) => (
        <Tag key={id}>
            {code} {name ? `- ${name}` : null}
        </Tag>
    ));
    return (
        <Flex wrap="wrap" gap="small">
            {tags}
        </Flex>
    );
};

const columns = (
    seatId: string,
    canEditSeat: boolean,
    handleEdit: (id: number) => void,
    handleDeleteModal: (candidate: DeleteCandidate) => void
) =>
    [
        {
            title: "Name",
            dataIndex: COLUMN_KEYS.NAME,
            key: COLUMN_KEYS.NAME,
            width: "33%",
            render: (name: string, { id }) => (
                <Link to={ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_DETAILS_DRAWER(seatId, id)}>
                    {name}
                </Link>
            ),
            defaultSortOrder: "ascend",
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: "Description",
            dataIndex: COLUMN_KEYS.DESCRIPTION,
            key: COLUMN_KEYS.DESCRIPTION,
            width: "33%",
            sorter: (a, b) => (a.description || "").localeCompare(b.description || ""),
            render: (description) => <Typography.Text>{format.asSelf(description)}</Typography.Text>,
        },
        {
            title: "Buyer Seats",
            dataIndex: COLUMN_KEYS.BUYER_SEATS,
            key: COLUMN_KEYS.BUYER_SEATS,
            width: "33%",
            render: (buyerSeats: BuyerSeat[]) => <BuyerSeatListsTag buyerSeats={buyerSeats} />,
        },
        canEditSeat && {
            title: "Actions",
            dataIndex: COLUMN_KEYS.ACTIONS,
            key: COLUMN_KEYS.ACTIONS,
            render: (_, { id, name }) => (
                <TableActions candidate={{ id, name }} handleEdit={handleEdit} handleDeleteModal={handleDeleteModal} />
            ),
        },
    ].filter(Boolean) as ColumnsType<BuyerSeatList>;

export const BuyerSeatListsPage = () => {
    const { canEditSeat } = useUserAccess();

    const { data, totalCount, isLoading, seatId, handleEdit } = useBuyerSeatListsPage();
    const { handleChange, paginationConfig } = usePagination({
        totalCount,
        showTotal: false,
    });
    const { onChange } = useTableChanges({
        handlePaginateChange: handleChange,
    });

    const [deleteTrigger, { isLoading: isDeleting }] = useDeleteSeatBuyerSeatListMutation();
    const { handleOpen: handleDeleteModal, ...restModalProps } = useBrandSafetyDeleteModal(deleteTrigger);

    return (
        <>
            <Table<BuyerSeatList>
                data-sdet="buyer-seat-lists-table"
                size="small"
                showSorterTooltip={false}
                rowKey="id"
                onChange={onChange}
                sortDirections={["ascend", "descend", "ascend"]}
                columns={columns(seatId, canEditSeat, handleEdit, handleDeleteModal)}
                dataSource={data}
                loading={isLoading}
                pagination={paginationConfig}
            />

            <BrandSafetyDeleteModal isDeleting={isDeleting} {...restModalProps} />

            <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_DETAILS_DRAWER}>
                <BuyerSeatListsDetailsDrawer />
            </Route>
        </>
    );
};
