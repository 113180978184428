import { ReactElement } from "react";
import { render } from "@testing-library/react";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { rootReducer as reducer } from "@app/core/store";
import { BrowserRouter as Router } from "react-router-dom";
import { SeatSideNavProvider } from "@app/core/components/SeatSideNav";
import { consoleApi } from "@app/core/services/console";

export const renderWithState = (
    ui: ReactElement,
    {
        preloadedState = {},
        store = configureStore({
            reducer,
            middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(consoleApi.middleware),
            preloadedState,
        }),
        ...renderOptions
    } = {}
) => {
    const Wrapper = ({ children }) => (
        <Router>
            <Provider store={store}>
                <SeatSideNavProvider>{children}</SeatSideNavProvider>
            </Provider>
        </Router>
    );

    return render(ui, { wrapper: Wrapper, ...renderOptions });
};
