import { useNotificationError } from "@app/core/components/hooks";
import { useGetSeatContactQuery } from "@app/core/services";
import { uri } from "@rubicon/utils";
import { useLocation, useHistory } from "react-router-dom";
import { DRAWER } from "../SeatContactsForm/constants";

export const useSeatContactDrawer = () => {
    const { search } = useLocation();
    const contactId = new URLSearchParams(search).get(DRAWER.key) || DRAWER.default;
    const history = useHistory();
    const handleDrawerClose = () => {
        const nextSearch = uri.deleteSearchParam(search, DRAWER.key);
        history.push({ search: nextSearch });
    };
    const handleDrawerOpen = (contactId: string) => {
        const nextSearch = uri.setSearchParam(search, DRAWER.key, contactId);
        history.push({ search: nextSearch });
    };
    const { data: seatContact, isLoading, error } = useGetSeatContactQuery(contactId, { skip: !contactId });

    useNotificationError(error);

    return {
        isLoading,
        seatContact,
        contactId,
        handleDrawerClose,
        handleDrawerOpen,
    };
};
