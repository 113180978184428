import { FC } from "react";
import { NavMenu } from "@rubicon/antd-components";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { ItemType } from "antd/lib/menu/interface";
import { ROUTE_FORMATTERS } from "@app/core/routing";

// TODO: Placeholder for demo. Replace with something more robust later
const getSelectedKey = (pathname) => (pathname.includes("health") ? "dealHealth" : "deals");

export const DealsNavMenu: FC = () => {
    const location = useLocation();
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();

    const handleClick = ({ key }) => {
        if (key === "dealHealth") {
            history.push(ROUTE_FORMATTERS.SEAT_DEAL_HEALTH(seatId));
            return;
        }
        history.push(ROUTE_FORMATTERS.SEAT_DEALS(seatId));
    };
    const menuItems: ItemType[] = [
        {
            label: "Deal Health",
            key: "dealHealth",
        },
        {
            label: "Deals Management",
            key: "deals",
        },
    ];

    return <NavMenu selectedKey={getSelectedKey(location.pathname)} onClick={handleClick} items={menuItems} />;
};
