import { FC } from "react";
import { css } from "@emotion/css";
import { Snapshots } from "./Snapshots";
import { DualAxes } from "@ant-design/plots";
import { GRAPHIC_TYPE, useTimingStats } from "./useTimingStats";
import { Button, Col, Divider, Form, Radio, Row, Select, Space } from "antd";

const classListFormItem = css`
    margin-bottom: 0;
`;

const classListDivider = css`
    margin-top: 0;
    margin-bottom: 0;
`;

interface Props {
    id: string | number;
}
export const TimingStats: FC<Props> = ({ id }) => {
    const {
        chartType,
        handleChangeChartType,
        eventsOptions,
        regionOptions,
        region,
        multiplicityId,
        handleChangeRegion,
        handleRefresh,
        handleCreateSnapshot,
        config,
        isLoadingTimingStats,
        isCreatingSnapshot,
    } = useTimingStats(Number(id));

    return (
        <Row gutter={[24, 24]}>
            <Col span={24}>
                <Row gutter={[14, 14]} justify="space-between" align="middle">
                    <Col>
                        <Form layout="vertical">
                            <Space size="large">
                                <Form.Item className={classListFormItem} label="Events">
                                    <Select
                                        labelInValue
                                        value={eventsOptions[0].value}
                                        options={eventsOptions}
                                        style={{ width: "13rem" }}
                                    />
                                </Form.Item>
                                <Form.Item className={classListFormItem} label="Region">
                                    <Select
                                        loading={isLoadingTimingStats}
                                        labelInValue
                                        value={region}
                                        onChange={handleChangeRegion}
                                        options={regionOptions}
                                        style={{ width: "13rem" }}
                                    />
                                </Form.Item>
                                <Form.Item className={classListFormItem} label="Event yAxis Type">
                                    <Radio.Group
                                        onChange={handleChangeChartType}
                                        value={chartType}
                                        options={GRAPHIC_TYPE}
                                    />
                                </Form.Item>
                            </Space>
                        </Form>
                    </Col>
                    <Col>
                        <Space size="small">
                            <Button onClick={handleRefresh}>Refresh</Button>
                            <Button loading={isCreatingSnapshot} onClick={handleCreateSnapshot}>
                                Take Snapshot
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <DualAxes {...config} />
            </Col>
            <Col span={24}>
                <Divider className={classListDivider} />
            </Col>
            <Col span={24}>
                <Snapshots id={Number(id)} chartType={chartType} region={region} multiplicityId={multiplicityId} />
            </Col>
        </Row>
    );
};
