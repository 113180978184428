import { FC } from "react";
import { Form, Select } from "antd";
import { Seat } from "@app/core/services";
import { NONE } from "@app/core/components/constants";
import { ConditionalTagRender, TagRender } from "@app/core/components";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { useAuthorizedPBSServers } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/AdvancedFeaturesForm/AdvancedFeaturesFormFields/AuthorizedPBSServers/useAuthorizedPBSServers";

interface AuthorizedPBSServersProps {
    seat: Seat;
    edit?: boolean;
}

export const AuthorizedPBSServers: FC<AuthorizedPBSServersProps> = ({ edit = false, seat }) => {
    const { isFetching, options, authorizedPBSServersShown, hasInternalAccess } = useAuthorizedPBSServers();

    if (edit && !authorizedPBSServersShown && hasInternalAccess) {
        return (
            <Form.Item
                wrapperCol={{ xs: 24, sm: 24, lg: 16, xl: 24, xxl: 22 }}
                label={INVENTORY_FORM_FIELDS.AUTHORIZED_PBS_SERVERS.label}
            >
                {NONE}
            </Form.Item>
        );
    }
    if (edit && authorizedPBSServersShown) {
        return (
            <Form.Item
                wrapperCol={{ xs: 24, sm: 24, lg: 16, xl: 24, xxl: 22 }}
                name={INVENTORY_FORM_FIELDS.AUTHORIZED_PBS_SERVERS.name}
                label={INVENTORY_FORM_FIELDS.AUTHORIZED_PBS_SERVERS.label}
            >
                <Select
                    allowClear
                    labelInValue
                    showArrow
                    showSearch
                    mode="multiple"
                    options={options}
                    loading={isFetching}
                    tagRender={TagRender}
                    optionFilterProp="label"
                    autoClearSearchValue={false}
                    placeholder={INVENTORY_FORM_FIELDS.AUTHORIZED_PBS_SERVERS.placeholder}
                />
            </Form.Item>
        );
    }

    return (
        <Form.Item
            wrapperCol={{ xs: 24, sm: 24, lg: 16, xl: 24, xxl: 22 }}
            label={INVENTORY_FORM_FIELDS.AUTHORIZED_PBS_SERVERS.label}
        >
            <ConditionalTagRender data={seat} property={INVENTORY_FORM_FIELDS.AUTHORIZED_PBS_SERVERS.name} />
        </Form.Item>
    );
};
