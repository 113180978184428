import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { usePublisherChannelFormInstance } from "@app/features/inventory/HierarchyForms/PublisherChannelForm/PublisherChannelFormInstance";

interface UseSupplyName {
    onChange: (e: CheckboxChangeEvent) => void;
    placeholder: string | undefined;
    disabled: boolean;
    extra: string;
}

export const useSupplyName = (): UseSupplyName => {
    const { useWatch, form, mode, channel } = usePublisherChannelFormInstance();
    const isEditMode = mode === "edit";
    const override = useWatch(CHANNEL_FORM_FIELDS.SUPPLY_OVERRIDE.name);
    const onChange = (): void => form.setFieldValue(CHANNEL_FORM_FIELDS.SUPPLY_NAME.name, "");

    const placeholder = isEditMode ? channel?.supply?.name : CHANNEL_FORM_FIELDS.SUPPLY_NAME.placeholder;
    const extra =
        "When ‘override’ is checked, this will be used instead of the supply name when not present in the tag.";
    return { extra, disabled: !override, onChange, placeholder };
};
