import { useEffect, useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useUserAccess } from "@app/core/auth";
import { useDealsPrefetch } from "@app/core/services";
import { useDealHealthTableFilters } from "../DealHealthTableFilters/useDealHealthTableFilters";
import { ROUTES } from "@app/core/routing";

export const usePrefetchDealHealthData = () => {
    const { pathname: currentRoute } = useLocation();
    const { hasDealsAccess } = useUserAccess().seatAccessFlags;
    const { seatAllDealHealthParams } = useDealHealthTableFilters();
    const prefetchDeals = useDealsPrefetch("getSeatAllDealsCTVNext");

    const isOnEligibleRoute = !useMemo(
        () => [ROUTES.SEAT_DEAL_HEALTH, ROUTES.LOGIN].some((path) => matchPath(currentRoute, { path })),
        [currentRoute]
    );

    useEffect(() => {
        if (hasDealsAccess && isOnEligibleRoute && seatAllDealHealthParams && seatAllDealHealthParams !== skipToken) {
            prefetchDeals(seatAllDealHealthParams);
        }
    }, [hasDealsAccess, isOnEligibleRoute, seatAllDealHealthParams, prefetchDeals]);
};
