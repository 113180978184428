import { LabeledValue } from "antd/lib/select";
import { TargetingCreatePayload, TargetingUpdatePayload, SeatSegment } from "@app/core/services/console";
import { GroupConditions, SegmentRuleIdentifierOperators, SegmentRuleModes } from "../constants";
import {
    SegmentRuleGroup,
    SegmentRuleGroupApi,
    SegmentRuleIdentifier,
    SegmentRule,
    SegmentRuleApi,
    SegmentRulesTargetingDimension,
} from "../types";
import { DimensionValuesByConditionByType, DimensionsByConditionByType } from "./types";

const replaceSegmentRuleGroupLabelValues = (segmentRuleGroup: SegmentRuleGroup): SegmentRuleGroupApi => {
    const nextRules: SegmentRuleApi[] = segmentRuleGroup.rules.map((rule) => {
        if ("rules" in rule) {
            return replaceSegmentRuleGroupLabelValues(rule);
        }

        const ruleParsed = rule.value && JSON.parse(String(rule.value.value));
        return {
            ...rule,
            value: ruleParsed && `${ruleParsed.dmp.id}_${ruleParsed.code}`,
        };
    });
    return {
        ...segmentRuleGroup,
        rules: nextRules,
    };
};

const getSegmentsByKey = (segments: SeatSegment[]) => {
    return segments.reduce((acc, segment) => {
        acc[`${segment.dmp.id}_${segment.code}`] = segment;
        return acc;
    }, {});
};

export const replaceSegmentRuleGroupValues = (
    segmentRuleGroup: SegmentRuleGroupApi,
    segments: SeatSegment[]
): SegmentRuleGroup => {
    const segmentsByKey = getSegmentsByKey(segments);
    const nextRules: SegmentRule[] = segmentRuleGroup.rules.map((rule) => {
        if ("rules" in rule) {
            return replaceSegmentRuleGroupValues(rule, segments);
        }
        const segment = segmentsByKey[String(rule.value)];
        const fallback = {
            id: rule.value,
            code: rule.value?.split("_")[1] || "",
            dmp: {
                id: rule.value?.split("_")[0] || "",
            },
        };
        const value = {
            value: JSON.stringify(segment || fallback),
            label: segment ? getSegmentLabel(segment) : rule.value,
        };

        return {
            ...rule,
            value,
        };
    });
    const nextGroup: SegmentRuleGroup = {
        ...segmentRuleGroup,
        rules: nextRules,
    };
    return nextGroup;
};

const convertSegmentRuleGroupToSegmentRules = (segmentRuleGroup: SegmentRuleGroup | null): string[] => {
    if (!segmentRuleGroup) {
        return [];
    }
    return [JSON.stringify(replaceSegmentRuleGroupLabelValues(segmentRuleGroup))];
};

export const updateTargetingPayloadFromSegmentRuleGroup = (
    includesOrExcludes: "includes" | "excludes",
    targetingPayload: TargetingCreatePayload | TargetingUpdatePayload,
    dimensionByConditionByType: DimensionsByConditionByType,
    dimensionValuesByConditionByType: DimensionValuesByConditionByType
) => {
    const payloadKey = includesOrExcludes === "includes" ? "include" : "exclude";
    const segmentRulesDimension: SegmentRulesTargetingDimension | undefined =
        dimensionByConditionByType[includesOrExcludes].segmentRules;

    if (!segmentRulesDimension) {
        return;
    }

    if (segmentRulesDimension.mode === SegmentRuleModes.Simple) {
        targetingPayload[payloadKey].segments = dimensionValuesByConditionByType[includesOrExcludes].segments.map(
            (segment) => {
                return JSON.parse(segment);
            }
        );
    } else {
        targetingPayload[payloadKey].segmentRules = convertSegmentRuleGroupToSegmentRules(
            dimensionValuesByConditionByType[includesOrExcludes].segmentRules
        );
    }
    targetingPayload[payloadKey].targetedSegments = dimensionValuesByConditionByType[includesOrExcludes].segments.map(
        (segment) => {
            return JSON.parse(segment);
        }
    );
};

const priceDecimalPlaces = 2;

const getSegmentLabel = (target: SeatSegment) => {
    const ctvCPM = target.ctvPrice ? target.ctvPrice.toFixed(priceDecimalPlaces) : "--";
    const digitalCPM = target.digitalPrice ? target.digitalPrice.toFixed(priceDecimalPlaces) : "--";

    return `${target.dmp?.code} > ${target.name || target.id} (TV CPM: US $${ctvCPM}) (Digital CPM: US $${digitalCPM})`;
};

export const segmentToLabelValue = (target: SeatSegment): LabeledValue => {
    return {
        value: JSON.stringify(target),
        label: getSegmentLabel(target),
    };
};

export const segmentsToLabelValue = (targets: SeatSegment[] | null): LabeledValue[] => {
    return (targets || []).map((target) => segmentToLabelValue(target));
};

export const getDefaultSegmentRuleGroup = (): SegmentRuleGroup => ({
    condition: GroupConditions.And,
    rules: [getDefaultSegmentRule()],
    valid: true,
    readonly: false,
});

export const getDefaultSegmentRule = (): SegmentRuleIdentifier => ({
    id: "USER_ID",
    field: "USER_ID",
    type: "segment",
    input: "text",
    operator: SegmentRuleIdentifierOperators.IsMember,
    value: null,
    readonly: false,
});

export const getSegmentsFromRuleGroup = (ruleGroup: SegmentRuleGroup): SeatSegment[] => {
    const segments: SeatSegment[] = [];
    ruleGroup.rules.forEach((rule) => {
        if ("rules" in rule) {
            segments.push(...getSegmentsFromRuleGroup(rule));
        } else {
            if (rule.value && "value" in rule.value) {
                segments.push(JSON.parse(String(rule.value.value)));
            }
        }
    });
    return segments;
};

export const updateDimensionValuesFromSegmentRuleGroup = (dimension: SegmentRulesTargetingDimension): void => {
    dimension.values = segmentsToLabelValue(getSegmentsFromRuleGroup(dimension.ruleGroup));
};
