import { useCallback } from "react";
import { useUserAccess } from "@app/core/auth";
import { AdStat, AdStatsById } from "@app/features/adStats";
import { useGetCurrenciesConversionsQuery } from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import { selectAdSourcesCurrencyConversionMode, selectAdSourcesPreferredCurrencyId } from "../reducer";
import { useCurrency } from "../useCurrency";
import { mapAdStatToAdSourceStat } from "./mapper";
import { AdSourceStat, AdSourceStatsById } from "./types";

type MapSingleAdSourceAdStat = (adStat: AdStat) => AdSourceStat | undefined;
type MapMultipleAdSourceAdStats = (adStats: AdStat[]) => AdSourceStat[];
type MapAdSourceAdStatsById = (adStatsById: AdStatsById) => AdSourceStatsById;

interface UseMapAdSourceAdStats {
    mapSingleAdSourceAdStat: MapSingleAdSourceAdStat;
    mapMultipleAdSourceAdStats: MapMultipleAdSourceAdStats;
    mapAdSourceAdStatsById: MapAdSourceAdStatsById;
}

export const useMapAdSourceAdStats = (): UseMapAdSourceAdStats => {
    const { data: currencyConversions } = useGetCurrenciesConversionsQuery();
    const { getCurrencyById } = useCurrency();
    const adSourcesPreferredCurrencyId = useAppSelector(selectAdSourcesPreferredCurrencyId);
    const adSourcesPreferredCurrency = getCurrencyById(adSourcesPreferredCurrencyId);
    const adSourcesCurrencyConversionMode = useAppSelector(selectAdSourcesCurrencyConversionMode);
    const { isTremorUser } = useUserAccess();

    const _mapAdSourceStatWithSettings = useCallback<(adStat: AdStat) => AdSourceStat | undefined>(
        (adStat) =>
            mapAdStatToAdSourceStat(
                adStat,
                adSourcesPreferredCurrency,
                currencyConversions || [],
                adSourcesCurrencyConversionMode,
                isTremorUser,
                getCurrencyById
            ),
        [
            adSourcesPreferredCurrency,
            currencyConversions,
            adSourcesCurrencyConversionMode,
            isTremorUser,
            getCurrencyById,
        ]
    );

    const mapSingleAdSourceAdStat = useCallback<MapSingleAdSourceAdStat>(
        (adStat) => _mapAdSourceStatWithSettings(adStat),
        [_mapAdSourceStatWithSettings]
    );

    const mapMultipleAdSourceAdStats = useCallback<MapMultipleAdSourceAdStats>(
        (adStats: AdStat[]) =>
            adStats.map((adStat) => _mapAdSourceStatWithSettings(adStat)).filter((adStat) => adStat) as AdSourceStat[],
        [_mapAdSourceStatWithSettings]
    );

    const mapAdSourceAdStatsById = useCallback<MapAdSourceAdStatsById>(
        (adStatsById: AdStatsById) =>
            Object.entries(adStatsById).reduce(
                (acc, [adSourceId, adStat]) => ({
                    ...acc,
                    [adSourceId]: _mapAdSourceStatWithSettings(adStat),
                }),
                {}
            ),
        [_mapAdSourceStatWithSettings]
    );

    return {
        mapSingleAdSourceAdStat,
        mapMultipleAdSourceAdStats,
        mapAdSourceAdStatsById,
    };
};
