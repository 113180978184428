import { FC } from "react";
import { Layout } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { Loading } from "@app/core/components";
import { useSeatAdSourcesPageNavigator } from "@app/features/seatAdSources/utils/index";
import { useFetchSeatAdSourceByUrlId } from "../data";
import { Footer } from "@app/core/components";
import { SeatAdSourcesForm } from "../SeatAdSourcesForm";
import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { css } from "@emotion/css";

export const SeatAdSourcesCopyPage: FC = () => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const { adSource, isFetching, isError } = useFetchSeatAdSourceByUrlId();
    const { goToSeatAdSourcesListPage } = useSeatAdSourcesPageNavigator();

    if (isFetching) {
        return <Loading />;
    }
    if (isError || !adSource) {
        history.push(ROUTE_FORMATTERS.SEAT_AD_SOURCES(seatId));
        return null;
    }
    return (
        <div
            data-sdet="seat-ad-sources-copy-page"
            className={css`
                #adSourceForm > .ant-collapse {
                    border: 0;

                    &:first-child {
                        border-top: 1px solid #d9d9d9;
                    }
                }

                #adSourceForm > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
                    padding: 0 16px;
                    line-height: 42px;

                    .ant-collapse-header-text {
                        font-size: 20px;
                        font-weight: 500;
                        flex: auto;

                        .ant-typography {
                            line-height: 42px;
                        }
                    }

                    .ant-collapse-arrow {
                        font-size: 16px !important;
                        vertical-align: -3px;
                    }

                    .ant-collapse-expand-icon {
                        cursor: pointer;
                    }
                }
            `}
        >
            <PageHeader title={`Copy: ${adSource.name}`} onBack={goToSeatAdSourcesListPage} />
            <SeatAdSourcesForm adSource={adSource} mode="copy" />
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </div>
    );
};
