import {
    getBucketedAdServingStats,
    useHistoryAdSourceAdServingAdStat,
    useLiveAdSourceAdServingAdStat,
} from "@app/features/adStats/adSourceAdServing";
import { AdSourceServingStat, LoadableAdSourceAdServingStat, LoadableAdSourceAdServingStats } from "./types";
import { mapAdServingStatToAdSourceAdServingStat, mapAdServingStatToAdSourceAdServingStats } from "./mapper";
import { useRef } from "react";
import {
    adSourceServingStatContainsData,
    diffAdSourceServingStats,
    getDiffInSeconds,
    scaleAdSourceServingStat,
} from "./utils";
import { MomentRange } from "@app/core/utils/types";
import { filterAdServingStatsByNtime } from "@app/features/adStats/adSourceAdServing/filters";

export const useConnectLiveAdSourceAdServingAdStat = (
    adSourceId: number,
    throttle = false
): LoadableAdSourceAdServingStat => {
    const liveAdServingStat = useLiveAdSourceAdServingAdStat(adSourceId, throttle);
    const liveAdSourceAdServingStat = liveAdServingStat
        ? mapAdServingStatToAdSourceAdServingStat(liveAdServingStat)
        : undefined;
    const loadingState = !liveAdSourceAdServingStat
        ? "loading"
        : adSourceServingStatContainsData(liveAdSourceAdServingStat)
        ? "loaded"
        : "loadedNoData";

    return {
        loadingState,
        data: loadingState === "loaded" ? liveAdSourceAdServingStat : undefined,
    };
};

export const useConnectLiveAdSourceAdServingAdStatPerSecond = (adSourceId: number): LoadableAdSourceAdServingStat => {
    const newLoadableStat = useConnectLiveAdSourceAdServingAdStat(adSourceId);
    const oldStatRef = useRef<AdSourceServingStat>();
    const statPerSecondRef = useRef<AdSourceServingStat>();

    if (newLoadableStat.loadingState !== "loaded") {
        return newLoadableStat;
    }

    const newStat = newLoadableStat.data;
    const oldStat = oldStatRef.current;
    if (newStat && oldStat && newStat.nTime > oldStat.nTime) {
        const statDiff = diffAdSourceServingStats(newStat, oldStat);
        const elapsedSeconds = getDiffInSeconds(statDiff.nTime);
        statPerSecondRef.current = scaleAdSourceServingStat(statDiff, elapsedSeconds, newStat.nTime, newStat.oTime);
    }

    oldStatRef.current = newStat;

    return {
        loadingState: statPerSecondRef.current ? "loaded" : "loading",
        data: statPerSecondRef.current,
    };
};

export const useConnectHistoryAdSourceAdServingAdStats = (
    adSourceId: number,
    momentRange: MomentRange,
    granularityHours: number
): LoadableAdSourceAdServingStats => {
    const historyStats = useHistoryAdSourceAdServingAdStat(adSourceId);
    const loadingState = !historyStats ? "loading" : historyStats.length > 0 ? "loaded" : "loadedNoData";

    const inRangeStats = filterAdServingStatsByNtime(historyStats || [], momentRange);
    const bucketStats = getBucketedAdServingStats(inRangeStats, granularityHours);
    const historyAdSourceServingStats = mapAdServingStatToAdSourceAdServingStats(bucketStats);

    return {
        loadingState,
        data:
            historyAdSourceServingStats && historyAdSourceServingStats.length > 0
                ? historyAdSourceServingStats
                : undefined,
    };
};
