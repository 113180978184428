import { ColumnsType } from "antd/es/table";
import { TableRows } from "./types";
import { FixedType } from "../../recycleBin/RecycleBinTable/types";
import { Button, Tag, Tooltip, Typography } from "antd";
import { SEARCH } from "@app/core/components";
import { PlusOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { gray8, gray4 } from "@rubicon/antd-components";
import { SequesteredDomainRaw } from "@app/core/services";
import { NONE } from "@app/core/components/constants";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

export const getColumns = (): ColumnsType<TableRows> =>
    [
        {
            dataIndex: "name",
            key: "name",
            sorter: false,
            title: "Sequestered Advertiser Domain Name",
            width: 250,
        },
        {
            dataIndex: "adSources",
            key: "adSources",
            sorter: false,
            title: "Ad Sources",
            width: 150,
        },
        {
            dataIndex: "status",
            key: "status",
            sorter: false,
            title: "Status",
            width: 100,
        },
        {
            dataIndex: "actions",
            fixed: "right" as FixedType,
            key: "actions",
            sorter: false,
            title: "Actions",
            width: 50,
        },
    ].filter(Boolean);

export const getTableRows = (data: SequesteredDomainRaw | undefined, seatId: string, search: string) => {
    const domainAdSources = data?.domainAdSources;
    const advertiserDomains = data?.sequesteredDomains?.advertiserDomains;
    return advertiserDomains
        ? getUISearch<Record<string, Record<number, number>>>(advertiserDomains, search).map<TableRows>(
              ([domain, adSources]) => {
                  return {
                      className: "",
                      id: domain,
                      name: <Typography.Text>{domain}</Typography.Text>,
                      key: domain,
                      adSources: domainAdSources
                          ?.filter((adSource) =>
                              Object.entries(adSources)
                                  .flatMap((ad) => ad[0])
                                  .includes(String(adSource.id))
                          )
                          .map(({ name, id }) => (
                              <Link key={id} to={ROUTE_FORMATTERS.SEAT_AD_SOURCES_DETAILS(seatId, id)}>
                                  <Tag color="processing" key={id}>
                                      {name}
                                  </Tag>
                              </Link>
                          )),
                      status: moment
                          .utc(data?.sequesteredDomains.ntime)
                          .isAfter(moment(data?.sequesteredDomains.ntime).clone().add(1800, "seconds")) ? (
                          <Tag color="warning">Pending Removal</Tag>
                      ) : (
                          NONE // TODO according business logic
                      ),
                      actions: (
                          <Button.Group>
                              <Tooltip title="Add Advertiser to List">
                                  <Link
                                      to={ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_SEQUESTERED_DOMAIN_EDIT(
                                          seatId,
                                          domain
                                      )}
                                  >
                                      <Button
                                          className={css`
                                              &.ant-btn.ant-btn-text {
                                                  &:hover {
                                                      background: transparent;
                                                  }
                                              }
                                          `}
                                          type="text"
                                      >
                                          <PlusOutlined
                                              className={css`
                                                  svg {
                                                      color: ${gray8};
                                                      outline: 1px solid ${gray4};
                                                      padding: 3px;
                                                      width: 1.45em;
                                                      height: 1.45em;
                                                  }
                                              `}
                                          />
                                      </Button>
                                  </Link>
                              </Tooltip>
                          </Button.Group>
                      ),
                  };
              }
          )
        : [];
};

export const getUISearch = <T extends Record<string, Record<number, unknown>>>(entries: T, search: string) =>
    Object.entries(entries).filter(([domain]) => {
        const keyword = new URLSearchParams(search).get(SEARCH.key);
        return keyword
            ? [domain].some((entity) => entity.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()))
            : true;
    });
