import { FC } from "react";
import { Select } from "antd";
import { LabeledValue } from "antd/lib/select";
import { TagRender } from "@app/core/components/TagRender";
import { useBvodSelect } from "./useBvodSelect";

interface Props {
    onChange: (value: LabeledValue[]) => void;
    value: LabeledValue[];
}

export const BvodSelect: FC<Props> = ({ onChange, value }) => {
    const { isLoading, options } = useBvodSelect();

    return (
        <Select
            allowClear
            data-sdet="BVODConnectDemographicsDimensionSelect"
            labelInValue
            loading={isLoading}
            mode="multiple"
            onChange={onChange}
            optionFilterProp="label"
            options={options}
            placeholder="Select Values"
            style={{ width: "100%" }}
            tagRender={TagRender}
            value={value}
        />
    );
};
