import { FC } from "react";
import {
    AdServing,
    AdUnitRedirect,
    BuyerAdmin,
    BuyerDashboard,
    BuyerDealDashboard,
    BuyerDiagnostics,
    BuyerDiscounts,
    BuyerReports,
    BuyerUserPreferences,
    Controls,
    ControlsConsole,
    Dashboard,
    DealRedirect,
    Deals,
    Diagnostics,
    IntegrationsConsole,
    IntegrationsStreaming,
    Inventory,
    Login,
    Reports,
    SeatAdSources,
    SeatAdmin,
} from "@app/features";
import { Redirect, Route, Switch } from "react-router-dom";
import { NotFound } from "@app/core/components";
import { ROUTES } from "./routes";
import { BuyerDealLibraryHome } from "@app/features/buyerDealLibrary";
import { BuyerAdCampaings } from "@app/features/buyerAdCampaigns";
import { BuyerTransparency } from "@app/features/buyerTransparency";
import { DealLibraryHome } from "@app/features/dealLibrary";
import { DiagnosticsLadleDiagnosticsPage } from "@app/features/diagnostics/DiagnosticsLadleDiagnosticsPage";
import { Admin } from "@app/features/admin";
import { usePrefetchDealHealthData } from "@app/features/deals/DealHealthPage/DealHealthTable/usePrefetchDealHealthData";
import { SessionRequiredRoute } from "./SessionRequiredRoute";
import { InventoryIFrame } from "@app/features/inventory/InventoryIFrame";
import { ReportsIFrameSwitch } from "@app/features/reports/ReportsIFrameSwitch";
import { UserPreferencesLegacy } from "@app/features/userPreferencesLegacy";
import { UserPreferences } from "@app/features/UserPreferences";
import { Demand } from "@app/features/demand";
import { StreamingCreativeReview } from "@app/features/streamingCreativeReview/StreamingCreativeReview";
import { DiagnosticsIframe } from "@app/features/diagnostics/DiagnosticsIframe";

const AppSwitch: FC = () => {
    usePrefetchDealHealthData();

    return (
        <Switch>
            <Redirect exact from={ROUTES.ROOT} to={ROUTES.LOGIN_MAGNITE} />
            <Route path={ROUTES.LOGIN}>
                <Login />
            </Route>
            <SessionRequiredRoute exact path={ROUTES.BUYER_DASHBOARD}>
                <BuyerDashboard />
            </SessionRequiredRoute>
            <SessionRequiredRoute exact path={ROUTES.BUYER_DEAL_DASHBOARD}>
                <BuyerDealDashboard />
            </SessionRequiredRoute>
            <SessionRequiredRoute exact path={ROUTES.BUYER_DEAL_AD_CAMPAIGNS}>
                <BuyerAdCampaings />
            </SessionRequiredRoute>
            <SessionRequiredRoute exact path={ROUTES.BUYER_DEAL_TRANSPARENCY}>
                <BuyerTransparency />
            </SessionRequiredRoute>
            <SessionRequiredRoute exact path={ROUTES.BUYER_DIAGNOSTICS}>
                <BuyerDiagnostics />
            </SessionRequiredRoute>
            <SessionRequiredRoute exact path={ROUTES.BUYER_REPORTS}>
                <BuyerReports />
            </SessionRequiredRoute>
            <SessionRequiredRoute exact path={ROUTES.BUYER_DISCOUNTS}>
                <BuyerDiscounts />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.BUYER_DEAL_LIBRARY}>
                <BuyerDealLibraryHome />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.BUYER_USER_PREFERENCES}>
                <UserPreferences />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.BUYER_USER_PREFERENCES_LEGACY}>
                <BuyerUserPreferences />
            </SessionRequiredRoute>
            <SessionRequiredRoute exact path={ROUTES.ADMIN}>
                <Admin />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.BUYER_USER_ADMIN}>
                <BuyerAdmin />
            </SessionRequiredRoute>
            <SessionRequiredRoute exact path={ROUTES.SEAT_AD_SERVING}>
                <AdServing />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.SEAT_AD_SOURCES}>
                <SeatAdSources />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.SEAT_CONTROLS_TABS}>
                <Controls />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.SEAT_CONTROLS_CONSOLE}>
                <ControlsConsole />
            </SessionRequiredRoute>
            <SessionRequiredRoute exact path={ROUTES.SEAT_DASHBOARD}>
                <Dashboard />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.SEAT_DIAGNOSTICS}>
                <Diagnostics />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.DIAGNOSTICS}>
                <DiagnosticsIframe />
            </SessionRequiredRoute>
            <SessionRequiredRoute exact path={ROUTES.SEAT_LADLE_DIAGNOSTICS}>
                <DiagnosticsLadleDiagnosticsPage />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.AD_UNIT_REDIRECT}>
                <AdUnitRedirect />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.DEAL_REDIRECT}>
                <DealRedirect />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.SEAT_DEALS}>
                <Deals />
            </SessionRequiredRoute>
            <SessionRequiredRoute exact path={ROUTES.SEAT_INTEGRATIONS_TABS_CONSOLE}>
                <IntegrationsConsole />
            </SessionRequiredRoute>
            <SessionRequiredRoute exact path={ROUTES.SEAT_INTEGRATIONS}>
                <IntegrationsStreaming />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.SEAT_DEMAND}>
                <Demand />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.SEAT_INVENTORY_CONSOLE}>
                <InventoryIFrame />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.SEAT_INVENTORY}>
                <Inventory />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.SEAT_REPORTS_CONSOLE}>
                <ReportsIFrameSwitch />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.SEAT_REPORTS}>
                <Reports />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.SEAT_DEAL_LIBRARY}>
                <DealLibraryHome />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.SEAT_USER_PREFERENCES_LEGACY}>
                <UserPreferencesLegacy />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.SEAT_USER_PREFERENCES}>
                <UserPreferences />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.SEAT_USER_ADMIN}>
                <SeatAdmin />
            </SessionRequiredRoute>
            <SessionRequiredRoute path={ROUTES.SEAT_CREATIVE_REVIEW}>
                <StreamingCreativeReview />
            </SessionRequiredRoute>
            <Route path={ROUTES.WILDCARD}>
                <NotFound />
            </Route>
        </Switch>
    );
};

export default AppSwitch;
