import { MarketplaceInfo } from "@app/core/services";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { Form } from "antd";
import { DEAL_TYPES } from "../DealTypeSelect";

export const useDuration = () => {
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const dealType = Form.useWatch<MarketplaceInfo["deal"]["dealType"]>(["dealUnderEdit", "dealType"]);
    const isVisible = dealType?.name !== DEAL_TYPES.DEAL_TYPE_LEGACY.name;
    const isReadonly = dealObjectMode === "view";

    return {
        isVisible,
        isReadonly,
    };
};
