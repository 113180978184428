import { SeatGrantsTableRecord } from "./InventoryPermissionsTable";
import { Permissions } from "@app/core/services/console/types";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
    PERMISSIONS_ENTITY_TYPES,
    Grants,
    useCreateGrantsMutation,
    useDeleteGrantsMutation,
    useGetGrantsQuery,
} from "@app/core/services";

interface UseInventoryPermissionsTable {
    dataSource: Grants[];
    isLoading: boolean;
    refetch: () => void;
    editPermissions: (originalPermissions: SeatGrantsTableRecord, newPermissions) => void;
    createPermissions: (newPermissions) => void;
    isSaving: boolean;
    handleDeleteGrants: (body) => void;
    isLoadingDeletePermissions: boolean;
}

const formatData = (data?: Grants[]) => {
    const ary: Grants[] = [];
    const objPerms = {};
    if (!data) {
        return [];
    }

    for (let i = 0; i < data.length; i++) {
        const anAclObj = data[i];
        const classParts = anAclObj.objectClass.split(".");
        const objPermKey = anAclObj.emailAddress ? anAclObj.emailAddress : anAclObj.groupId ? anAclObj.groupId : "";
        const cType = classParts[classParts.length - 1];
        if (objPermKey !== "") {
            if (!objPerms.hasOwnProperty(objPermKey)) {
                objPerms[objPermKey] = {
                    objectId: anAclObj.objectId,
                    permission: anAclObj.permission,
                    emailAddress: anAclObj.emailAddress ? anAclObj.emailAddress : null,
                    groupId: anAclObj.groupId ? anAclObj.groupId : null,
                    groupName: anAclObj.groupName ? anAclObj.groupName : null,
                    userId: anAclObj.userId,
                    objectClass: cType,
                };
            } else {
                let str = objPerms[objPermKey].permission;
                if (!str.includes(anAclObj.permission)) {
                    str += " ";
                    str += anAclObj.permission;
                    objPerms[objPermKey].permission = str;
                }
            }
        }
    }

    for (const key in objPerms) {
        if (objPerms.hasOwnProperty(key)) {
            ary.push(objPerms[key]);
        }
    }

    return ary;
};

const getEntityId = ({ seatId, publisherId }, entityType: PERMISSIONS_ENTITY_TYPES) => {
    let entityId: number;
    switch (entityType) {
        case PERMISSIONS_ENTITY_TYPES.SEAT:
            entityId = seatId;
            break;
        case PERMISSIONS_ENTITY_TYPES.PUBLISHER:
            entityId = publisherId;
            break;
    }
    return entityId;
};

export const useInventoryPermissionsTable = (
    entityType: PERMISSIONS_ENTITY_TYPES = PERMISSIONS_ENTITY_TYPES.SEAT,
    filter: string
): UseInventoryPermissionsTable => {
    const { seatId, publisherId } = useParams<{ seatId: string; publisherId: string }>();
    const [deleteGrants, { isLoading: isLoadingDeletePermissions }] = useDeleteGrantsMutation();
    const [createGrants] = useCreateGrantsMutation();
    const { data, isFetching, refetch } = useGetGrantsQuery({
        id: getEntityId({ seatId, publisherId }, entityType),
        entityType,
    });
    const [isSaving, setIsSaving] = useState(false);

    const editPermissions = async (originalPermissions: SeatGrantsTableRecord, newPermissions) => {
        setIsSaving(true);
        const permissions = ["administration", "write", "read"];
        const permissionsToAdd: string[] = [];
        const permissionsToRemove: string[] = [];

        for (const permission of permissions) {
            if (originalPermissions[permission] && !newPermissions[permission]) {
                permissionsToRemove.push(permission);
            } else if (!originalPermissions[permission] && newPermissions[permission]) {
                permissionsToAdd.push(permission);
            }
        }

        try {
            if (permissionsToRemove.length) {
                await deleteGrants({
                    id: getEntityId({ seatId, publisherId }, entityType),
                    body: permissionsToRemove.map((p: Permissions) => ({
                        emailAddress: newPermissions.emailAddress || null,
                        groupId: newPermissions.groupId || null,
                        permission: p,
                    })),
                    entityType,
                }).unwrap();
            }

            await Promise.all(
                permissionsToAdd.map((p: Permissions) =>
                    createGrants({
                        id: getEntityId({ seatId, publisherId }, entityType),
                        body: {
                            emailAddress: newPermissions.emailAddress || null,
                            groupId: newPermissions.groupId || null,
                            permission: p,
                        },
                        entityType,
                    }).unwrap()
                )
            );
        } catch (ex) {
            throw new Error(ex.data?.errorDescription);
        } finally {
            setIsSaving(false);
        }
    };

    const createPermissions = async (newPermissions) => {
        setIsSaving(true);
        const permissions = ["administration", "write", "read"];
        const permissionsToAdd: string[] = [];

        for (const permission of permissions) {
            if (newPermissions[permission]) {
                permissionsToAdd.push(permission);
            }
        }

        try {
            await Promise.all(
                permissionsToAdd.map((p: Permissions) =>
                    createGrants({
                        id: getEntityId({ seatId, publisherId }, entityType),
                        body: {
                            emailAddress: newPermissions.emailAddress || null,
                            groupId: newPermissions.groupId || null,
                            permission: p,
                        },
                        entityType,
                    }).unwrap()
                )
            );
        } catch (ex) {
            throw new Error(ex.data?.errorDescription);
        } finally {
            setIsSaving(false);
        }
    };

    const handleDeleteGrants = ({ body }) =>
        deleteGrants({
            id: getEntityId({ seatId, publisherId }, entityType),
            body,
            entityType,
        });

    const dataSource = useMemo(() => {
        const formattedData = formatData(data);
        return formattedData.filter(
            (grants) =>
                (grants.emailAddress && grants.emailAddress.toLowerCase().includes(filter.toLowerCase().trim())) ||
                (grants.groupName && grants.groupName.toLowerCase().includes(filter.toLowerCase().trim()))
        );
    }, [data, filter]);

    return {
        dataSource,
        isLoading: isFetching,
        refetch,
        editPermissions,
        createPermissions,
        isSaving,
        handleDeleteGrants,
        isLoadingDeletePermissions,
    };
};
