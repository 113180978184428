import { FC, ReactNode } from "react";
import { Col, Flex } from "antd";
import { DrawerCloseButton } from "@app/core/components";
import { useSeatAdSourcesDetailsDrawer } from "../../useSeatAdSourcesDetailsDrawer";

interface Props {
    children: ReactNode;
}

export const SeatAdSourcesDetailsDrawerHeader: FC<Props> = ({ children: seatAdSourcesDetailsDrawerTitle }) => {
    const { closeDrawerBackToPage } = useSeatAdSourcesDetailsDrawer();

    return (
        <Flex
            data-sdet="seat-ad-sources-details-drawer-header"
            className="ant-drawer-header"
            style={{ padding: "16px", borderBottom: "1px solid #d9d9d9" }}
        >
            <Flex flex={1} justify="space-between" wrap="nowrap" align="baseline">
                <Col flex="auto">{seatAdSourcesDetailsDrawerTitle}</Col>
                <Col flex="1rem">
                    <DrawerCloseButton onClose={closeDrawerBackToPage} />
                </Col>
            </Flex>
        </Flex>
    );
};
