export const call = (prom: Promise<unknown>) =>
    prom
        .then((res) => {
            return {
                err: null,
                res,
            };
        })
        .catch((err) => {
            return {
                err,
                res: null,
            };
        });

export const timeout = (interval: number) => new Promise((resolve) => setTimeout(resolve, interval));
