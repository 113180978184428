import { Form } from "antd";
import { useState } from "react";
import { isEqualWith, unionBy, differenceBy as removeValuesFromArray } from "lodash";
import { BulkEditAction } from "../../../constants";
import { customizerOfEqual } from "../../../helpers";
import { TouchedFieldInfo } from "../../../reducer";
import { useDispatch } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";

export const useBulkOperationBulkEditButtonModal = <
    T extends { id: number; externalName?: string | null; name: string }
>(
    formItemName: "adSourceUpdates" | "adUnitUpdates" | "buyerDealUpdates" | "demandDealUpdates",
    setBulkChange: (bulkChangeInfo: Record<number, TouchedFieldInfo[]>) => AnyAction
) => {
    const dispatch = useDispatch();
    const form = Form.useFormInstance();
    const { [formItemName]: preUpdates } = form.getFieldsValue(true);
    const [fieldsInfo, setFieldsInfo] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    const handleActionChange = (action, { key, title, getIsDisabled }) =>
        setFieldsInfo({
            ...fieldsInfo,
            [key]: {
                ...fieldsInfo[key],
                action,
                key,
                title,
                getIsDisabled,
            },
        });

    const handleValueChange = (value, { key, title, getIsDisabled }) =>
        setFieldsInfo({
            ...fieldsInfo,
            [key]: {
                ...fieldsInfo[key],
                value,
                key,
                title,
                getIsDisabled,
            },
        });

    const handleOpen = () => setIsOpen(true);

    const handleClose = () => {
        setIsOpen(false);
        setFieldsInfo({});
    };

    const getRecordUpdates = (record: T) => {
        const updatedValues = {};
        const touchedFieldsInfo: TouchedFieldInfo[] = [];
        Object.values(fieldsInfo).forEach(({ action, value, title, key, getIsDisabled }) => {
            let updatedValue: unknown = null;
            // when a field is supposed to be disabled, bulk edit will do nothing on it
            if (getIsDisabled?.(record)) {
                return;
            }
            // default action is "replace", so when no change, it has no value, means "replace"
            if (!action || action === BulkEditAction.Replace) {
                // when input field is touched but clear up, do nothing
                if ((typeof value === "string" && value === "") || value == null) {
                    return;
                }
                updatedValue = value;
            }

            // only for "multiple" mode Select
            if (action === BulkEditAction.Add) {
                const preValue = preUpdates[record.id][key];
                if (preValue == null || Array.isArray(preValue)) {
                    const newValue = unionBy(preValue || [], value, "value");
                    updatedValue = newValue;
                }
            }

            // only for "multiple" mode Select
            if (action === BulkEditAction.Remove) {
                const preValue = preUpdates[record.id][key];
                if (Array.isArray(preValue) && Array.isArray(value)) {
                    const newValue = removeValuesFromArray(preValue, value, "value");
                    updatedValue = newValue;
                }
            }

            // only for "multiple" mode Select
            if (action === BulkEditAction.Clear) {
                updatedValue = [];
            }

            if (updatedValue !== null) {
                updatedValues[key] = updatedValue;
                touchedFieldsInfo.push({
                    // fall back to null to suppress ts
                    name: record.hasOwnProperty("externalName") ? record.externalName || null : record.name,
                    field: key,
                    title,
                    originValue: record[key],
                    currentValue: updatedValue,
                    isChanged: !isEqualWith(record[key], updatedValue, customizerOfEqual),
                });
            }
        });

        return { updatedValues, touchedFieldsInfo };
    };
    const onOk = (selectedRows: T[]) => {
        const updates = {};
        const touchedFieldsInfoById = {};
        selectedRows.forEach((record: T) => {
            const { updatedValues, touchedFieldsInfo } = getRecordUpdates(record);
            updates[record.id] = updatedValues;
            touchedFieldsInfoById[record.id] = touchedFieldsInfo;
        });

        form.setFieldsValue({ [formItemName]: updates });
        dispatch(setBulkChange(touchedFieldsInfoById));
        handleClose();
    };

    return {
        isOpen,
        handleActionChange,
        handleValueChange,
        handleOpen,
        handleClose,
        onOk,
        fieldsInfo,
    };
};
