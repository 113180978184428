import { useEffect, useState } from "react";
import { Session } from "@app/core/clients/console";
import { sendMessage, ErrorMessage } from "@app/core/clients/slack";
import { notification } from "antd";
import conf from "@app/core/conf";
import { useAuthClient } from "@app/core/authClient";
import packageJson from "../../../../package.json";

interface Feedback {
    company: string;
    email: string;
    error: string;
    stackTrace: string;
    uri: string;
    user: string;
    maskingUser: string | null;
    version: string;
}

const getUserName = (user: { firstName: string; lastName: string }) =>
    `${user.firstName || ""} ${user.lastName || ""}`.trim();

const getUser = (session: Session) => getUserName(session.user);

const getMaskingUser = (session: Session) => {
    if (session.grantingUser && session.grantingUser.id !== session.user.id) {
        return getUserName(session.grantingUser);
    }
    return null;
};

const getErrorFeedbackValues = (session: Session, error: Error): Feedback => {
    return {
        company: session.user.company || "",
        email: session.user.emailAddress || "",
        error: error.toString(),
        stackTrace: error.stack || "",
        uri: window.location.href,
        user: getUser(session),
        maskingUser: getMaskingUser(session),
        version: packageJson.version,
    };
};

export const useErrorFeedback = (error: Error | null) => {
    const { session } = useAuthClient();
    const [isErrorHandled, setIsErrorHandled] = useState(false);
    const isProduction = conf.environment === "production";
    const isOriginLocalhost = window.location.origin.includes("localhost");
    const isOriginQa = window.location.origin.includes("apps-qa");

    useEffect(() => {
        if (session && error && isProduction && !isOriginLocalhost && !isOriginQa && !isErrorHandled) {
            const errorFeedbackValues = getErrorFeedbackValues(session, error);

            sendMessage(ErrorMessage(errorFeedbackValues))
                .catch(() => {
                    notification.error({
                        message: "Crash Report Error",
                        description: "Failed to submit error feedback",
                    });
                })
                .finally(() => {
                    setIsErrorHandled(true);
                });
        }
    }, [session, error, isErrorHandled, isProduction, isOriginLocalhost, isOriginQa]);
};
