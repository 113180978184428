import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    DealHealthTableFilters,
    DealHealthTableSort,
    DealHealthTablePagination,
    DealHealthSearchFilter,
    DealHealthDateRanges,
} from "@app/features/deals/DealHealthPage/types";
import { RootState } from "@app/core/store";
import { LabeledValue } from "antd/lib/select";
import { DEFAULT_PAGE_SIZE, DEFAULT_TOTAL_RESULTS } from "@app/core/components/constants";

export interface DealHealthState {
    filters: DealHealthTableFilters;
    sort: DealHealthTableSort;
    pagination: DealHealthTablePagination;
    searchFilter: DealHealthSearchFilter;
    liveStats: boolean;
}

const initialState: DealHealthState = {
    filters: {
        filterDealType: [],
        filterDsp: [],
        pricingType: null,
        status: [],
        timeZone: null,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dateRange: null,
        filterPriorities: [],
    },
    liveStats: false,
    sort: {
        orderBy: "",
        asc: null,
    },
    pagination: {
        page: 1,
        maxResults: DEFAULT_PAGE_SIZE,
        totalResults: DEFAULT_TOTAL_RESULTS,
    },
    searchFilter: {
        searchKeyword: "",
    },
};

const dealHealthSlice = createSlice({
    name: "dealHealth",
    initialState,
    reducers: {
        clearDealHealthFilters: (state) => {
            state.filters = initialState.filters;
        },
        setFiltersDealHealthType: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.filterDealType = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setFiltersDealHealthDsp: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.filterDsp = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setFiltersDealHealthTimeZone: (state, action: PayloadAction<LabeledValue | null>) => {
            state.filters.timeZone = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setFiltersDealHealthDateRange: (state, action: PayloadAction<DealHealthDateRanges>) => {
            state.filters.dateRange = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setFiltersDealHealthPricingType: (state, action: PayloadAction<LabeledValue | null>) => {
            state.filters.pricingType = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setFiltersDealHealthStatus: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.status = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setDealHealthLiveStats: (state, action: PayloadAction<boolean>) => {
            state.liveStats = action.payload;
        },
        setDealHealthTableSort: (state, action: PayloadAction<DealHealthTableSort>) => {
            state.sort = action.payload;
        },
        setDealHealthTablePagination: (state, action: PayloadAction<DealHealthTablePagination>) => {
            state.pagination = action.payload;
        },
        setSearchDealHealthFilter: (state, action: PayloadAction<DealHealthSearchFilter>) => {
            state.searchFilter = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
        setFiltersDealHealthPriority: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.filterPriorities = action.payload;
            state.pagination = { ...state.pagination, page: 1 };
        },
    },
});

export const selectDealHealthFilters = (state: RootState) => state.dealHealth.filters;
export const selectDealHealthSort = (state: RootState) => state.dealHealth.sort;
export const selectDealHealthPagination = (state: RootState) => state.dealHealth.pagination;
export const selectDealHealthSearchFilter = (state: RootState) => state.dealHealth.searchFilter;
export const selectDealHealthLiveStats = (state: RootState) => state.dealHealth.liveStats;
export const selectDealHealthFilterCount = (state: RootState): number =>
    Object.values(state.dealHealth.filters).filter((filter) => filter && (!Array.isArray(filter) || filter.length > 0))
        .length;

export const {
    clearDealHealthFilters,
    setFiltersDealHealthType,
    setDealHealthLiveStats,
    setFiltersDealHealthDsp,
    setFiltersDealHealthPricingType,
    setFiltersDealHealthStatus,
    setDealHealthTableSort,
    setDealHealthTablePagination,
    setSearchDealHealthFilter,
    setFiltersDealHealthTimeZone,
    setFiltersDealHealthDateRange,
    setFiltersDealHealthPriority,
} = dealHealthSlice.actions;
export default dealHealthSlice.reducer;
