import { FC } from "react";
import { AdStat, getOtimesForThrottleUnit, getTotalRevenue } from "@app/features/adStats";
import { useAppSelector } from "@app/core/store";
import { selectAdStatDealById } from "@app/features/adStats/reducer";
import { useThrottle } from "@app/core/utils";
import { RevenueTypes } from "@app/core/clients/console";
import { LoadableAnimatedNumberCell } from "@app/core/components";
import { useDealCurrencyConversion } from "@app/features/deals/useDealCurrencyConversion";

interface DealHealthTableNetRevenueCellProps {
    dealId: number;
    sourceCurrencyCode: string | undefined;
}

const DealHealthTableNetRevenueCell: FC<DealHealthTableNetRevenueCellProps> = ({ dealId, sourceCurrencyCode }) => {
    const isConnectedToLiveAdStats = useAppSelector((state) => state.adStats.isConnected);
    const revenueType = RevenueTypes.NET_REVENUE;
    const { currencyConversions, preferredCurrency, targetCurrencyCode, currencyConversionMode } =
        useDealCurrencyConversion(sourceCurrencyCode);

    const { netRevenue, otime } = useAppSelector((state) => {
        const adStat: AdStat | null = selectAdStatDealById(state, dealId);
        return adStat
            ? {
                  netRevenue: getTotalRevenue(
                      adStat,
                      preferredCurrency,
                      currencyConversions,
                      currencyConversionMode,
                      revenueType
                  ),
                  otime: getOtimesForThrottleUnit(adStat),
              }
            : { netRevenue: undefined, otime: undefined };
    });
    const throttledNetRevenue = useThrottle<number | undefined>(netRevenue, {
        forceUpdateDependencies: [
            otime,
            sourceCurrencyCode,
            preferredCurrency,
            currencyConversionMode,
            targetCurrencyCode,
            revenueType,
        ],
    });

    return (
        <LoadableAnimatedNumberCell
            isLoading={!isConnectedToLiveAdStats}
            value={throttledNetRevenue}
            isMoney={true}
            currencyCode={targetCurrencyCode}
        />
    );
};

export default DealHealthTableNetRevenueCell;
