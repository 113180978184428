import { FC } from "react";
import { Button, Space, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useAppSelector } from "@app/core/store";
import { DeleteOutlined } from "@ant-design/icons";
import { selectInventoryBrandSafetyIsActionsDisabled } from "@app/features/inventory/InventoryBrandSafety/reducer";

interface Props {
    isOnEdit: boolean;
    handleEdit: () => void;
    handleCancel: () => void;
    handleDelete: () => void;
    isDeleting: boolean;
    isUpdating: boolean;
    handleSubmit: () => void;
}

export const BrandSafetyActions: FC<Props> = ({
    isOnEdit,
    handleEdit,
    handleCancel,
    handleSubmit,
    isUpdating,
    handleDelete,
    isDeleting,
}) => {
    const isActionsDisabled: boolean = useAppSelector(selectInventoryBrandSafetyIsActionsDisabled);
    return isOnEdit ? (
        <Space size="middle">
            <Button onClick={handleCancel} disabled={isUpdating}>
                Cancel
            </Button>
            <Button onClick={handleSubmit} disabled={isUpdating} loading={isUpdating} type="primary">
                Save
            </Button>
        </Space>
    ) : (
        <Button.Group key="actions">
            <Tooltip title="Edit Seat">
                <Button onClick={handleEdit} disabled={isActionsDisabled} icon={<EditOutlined />} />
            </Tooltip>
            <Button
                icon={<DeleteOutlined />}
                disabled={isActionsDisabled}
                loading={isDeleting}
                onClick={handleDelete}
            />
        </Button.Group>
    );
};
