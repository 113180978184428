import { Alert } from "antd";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useUserAccess } from "@app/core/auth";
import { SourceSelf } from "@app/features/inventory/components/FloorsTable";
import { SeatNukeStatus, useGetSeatNukeStatusQuery, useGetSupplyQuery } from "@app/core/services";

interface SupplyTrafficAlertBannerProps {
    sourceSelf: SourceSelf;
}

const getMessage = (sourceSelf: SourceSelf): string => {
    switch (sourceSelf) {
        case "Publisher":
            return "A supply entity on this publisher has been blocked by Magnite Admin";
        case "Brand":
            return "A supply entity on this brand has been blocked by Magnite Admin";
        case "Supply":
            return "This supply entity has been blocked by Magnite Admin";
        case "Ad Unit":
            return "The supply entity for this ad unit has been blocked by Magnite Admin";
        default:
            return "A supply entity on this seat has been blocked by Magnite Admin";
    }
};
const getNukedSupplyStatus = (
    sourceSelf: SourceSelf,
    nukeStatus: SeatNukeStatus | undefined,
    publisherId: string,
    brandId: string,
    supplyCode: string | undefined
): boolean => {
    if (nukeStatus?.seatHasNukedSupply && sourceSelf === "Seat") return nukeStatus.seatHasNukedSupply;

    if (nukeStatus?.pubHasNukedSupplyMap && sourceSelf === "Publisher")
        return nukeStatus.pubHasNukedSupplyMap[publisherId];

    if (nukeStatus?.brandHasNukedSupplyMap && sourceSelf === "Brand") return nukeStatus.brandHasNukedSupplyMap[brandId];

    if (nukeStatus?.nukedSupplyMap && supplyCode && (sourceSelf === "Supply" || sourceSelf === "Ad Unit"))
        return nukeStatus.nukedSupplyMap[supplyCode];

    return false;
};

export const SupplyTrafficAlertBanner: FC<SupplyTrafficAlertBannerProps> = ({ sourceSelf }) => {
    const message: string = getMessage(sourceSelf);
    const { hasTremorRole, hasSeatWriteAccess } = useUserAccess();
    const { seatId, publisherId, brandId, supplyId } = useParams<{
        seatId: string;
        publisherId: string;
        brandId: string;
        supplyId: string;
    }>();
    const { data: supply, isFetching: isSupplyFetching } = useGetSupplyQuery(supplyId, {
        skip: !supplyId || !hasTremorRole,
    });
    const { data: nukeStatus, isFetching: isNukeStatusFetching } = useGetSeatNukeStatusQuery(seatId, {
        skip: !seatId || !hasTremorRole,
    });
    const hasNukedSupply: boolean = getNukedSupplyStatus(sourceSelf, nukeStatus, publisherId, brandId, supply?.code);

    if (!hasTremorRole || !hasSeatWriteAccess || !hasNukedSupply || isNukeStatusFetching || isSupplyFetching)
        return null;

    return <Alert message={message} type="warning" showIcon style={{ width: "100%" }} />;
};
