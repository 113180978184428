import { useEffect, useRef, useState } from "react";

const DEFAULT_INTERVAL_SECONDS = 2;

interface UseCooldown {
    isReady: boolean;
    triggerCooldown: () => void;
}

export const useCooldown = (intervalSeconds = DEFAULT_INTERVAL_SECONDS): UseCooldown => {
    const [isReady, setIsReady] = useState<boolean>(true);
    const timeoutIdRef = useRef<NodeJS.Timeout>();

    const triggerCooldown = () => {
        if (isReady) {
            setIsReady(false);
            timeoutIdRef.current = setTimeout(() => setIsReady(true), intervalSeconds * 1000);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => clearTimeout(timeoutIdRef.current), []);

    return {
        isReady,
        triggerCooldown,
    };
};
