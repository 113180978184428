import { useCallback } from "react";
import { LabeledValue } from "antd/lib/select";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import { useFilteredAdSourceStatuses } from "@app/features/seatAdSources/data";
import { useSyncedFieldsProducer } from "@app/features/syncedFields";

interface UseAdSourceStatusField {
    isFetching: boolean;
    options: LabeledValue[];
    value: number | null;
    onChange: (value: number) => void;
}

export const useAdSourceStatusField = (formMode: DealFormMode): UseAdSourceStatusField => {
    const dispatch = useAppDispatch();
    const { statuses, statusOptions, isFetching } = useFilteredAdSourceStatuses(formMode);
    const { updateSyncedFields } = useSyncedFieldsProducer();

    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_STATUS]);

    const onChange = useCallback(
        (value) => {
            const selectedStatus = statuses?.find((status) => status.id === value);
            updateSyncedFields({ status: selectedStatus });
            dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_STATUS, value }));
        },
        [statuses, updateSyncedFields, dispatch]
    );

    return {
        isFetching,
        options: statusOptions,
        value,
        onChange,
    };
};
