import { Form, Select } from "antd";
import { useMemo } from "react";
import { useSnapshots } from "../SnapshotsDrawer/useSnapshots";
import { LabeledValue } from "antd/lib/select";
import { useGetSeatStatsSnapshotQuery } from "@app/core/services/adStats/statsSnapshots";
import { Last24HoursPerformanceStatsGraph } from "../../Last24HoursPerformanceStatsGraph";

export const StatsSnapshotViewer = ({ seat }) => {
    const {
        isLoading: isLoadingSnapshots,
        options,
        handleChange,
        value,
        snapshotTimestamp,
        selectedSnapshotLabel,
    } = useSnapshots(seat);
    const { data } = useGetSeatStatsSnapshotQuery(
        { seatId: seat?.id, timestamp: snapshotTimestamp as string },
        { skip: !seat || !snapshotTimestamp }
    );

    const graph = useMemo(() => {
        if (!data) {
            return null;
        }
        return <Last24HoursPerformanceStatsGraph adStat={data} chartId="last-24-hours-performance-stats-snapshot" />;
    }, [data]);

    return (
        <>
            <Form.Item label="Show Snapshots" colon={false}>
                <Select
                    loading={isLoadingSnapshots}
                    placeholder="Select Snapshot"
                    onChange={(_, option) => handleChange(option as LabeledValue & { filename: string })}
                    options={options}
                    optionFilterProp="label"
                    style={{ width: "465px" }}
                    value={value}
                />
            </Form.Item>

            {selectedSnapshotLabel && (
                <Form.Item label="Snapshot" colon={false}>
                    {selectedSnapshotLabel}
                </Form.Item>
            )}

            {graph}
        </>
    );
};
