import { FC } from "react";
import { Title } from "@app/core/components";
import { ItemType } from "rc-collapse/es/interface";
import { Form, Collapse, Space, Button } from "antd";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import {
    LabelsSection,
    FloorsSection,
    GeneralSection,
    InternalSection,
    AffiliateSection,
    BrandSafetySection,
    CustomPixelsSection,
    SupplyDetailsSection,
} from "@app/features/inventory/HierarchyForms/BrandForm/BrandFormSections";
import { ConfirmationModal } from "@app/features/inventory/components/ConfirmationModal";
import { useBrandForm } from "@app/features/inventory/HierarchyForms/BrandForm/useBrandForm";
import { AffixFormButtonsBar } from "@app/features/inventory/components/AffixFormButtonsBar";
import { INVENTORY_HEADER_COLLAPSE_CLASS_LIST } from "@app/features/inventory/InventorySeat/constants";
import { useBrandFormDefinitionsFields } from "@app/features/inventory/HierarchyForms/BrandForm/useBrandFormDefinitionsFields";

export const BrandForm: FC = () => {
    const {
        open,
        form,
        brand,
        loading,
        showModal,
        isDeleting,
        isEditMode,
        handleCancel,
        handleSubmit,
        handleDelete,
        initialValues,
        handleCancelModal,
        submitButtonTitle,
        hasSeatWriteAccess,
        handleAdvancedFloors,
        isAffiliateSectionShown,
        isFloorsSectionShown,
        isInternalSectionShown,
    } = useBrandForm();

    const {
        generalDefinitionsFields,
        brandSafetyDefinitionsFields,
        intrenalDefinitionsFields,
        supplyDetailsDefinitionsFields,
        customPixelsDefinitionsFields,
        labelsDefinitionsFields,
        affiliateDefinitionsFields,
    } = useBrandFormDefinitionsFields();

    return (
        <Form
            form={form}
            name="brandForm"
            layout="vertical"
            initialValues={initialValues}
            scrollToFirstError={{ behavior: "smooth", block: "center" }}
        >
            <Collapse
                bordered
                ghost={false}
                defaultActiveKey={["0", "1", "2", "3", "4", "5", "6", "7"]}
                className={INVENTORY_HEADER_COLLAPSE_CLASS_LIST}
                collapsible="header"
                items={
                    [
                        {
                            key: "0",
                            children: <GeneralSection />,
                            label: <Title level={5} title="General" />,
                            extra: <HelpKeysDrawer panelLabel="General" definitionsFields={generalDefinitionsFields} />,
                        },
                        isAffiliateSectionShown && {
                            key: "1",
                            children: <AffiliateSection />,
                            label: <Title level={5} title="Affiliate (Internal only)" />,
                            extra: (
                                <HelpKeysDrawer panelLabel="Affiliate" definitionsFields={affiliateDefinitionsFields} />
                            ),
                        },
                        {
                            key: "2",
                            children: <BrandSafetySection />,
                            label: <Title level={5} title="Brand Safety" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Brand Safety"
                                    definitionsFields={brandSafetyDefinitionsFields}
                                />
                            ),
                        },
                        isInternalSectionShown && {
                            key: "3",
                            children: <InternalSection />,
                            label: <Title level={5} title="Internal (Internal only)" />,
                            extra: (
                                <HelpKeysDrawer panelLabel="Internal" definitionsFields={intrenalDefinitionsFields} />
                            ),
                        },
                        {
                            key: "4",
                            children: <SupplyDetailsSection />,
                            label: <Title level={5} title="Supply Details" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Supply Details"
                                    definitionsFields={supplyDetailsDefinitionsFields}
                                />
                            ),
                        },
                        {
                            key: "5",
                            children: <CustomPixelsSection key="4" />,
                            label: <Title level={5} title="Custom Pixels" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Custom Pixels"
                                    definitionsFields={customPixelsDefinitionsFields}
                                />
                            ),
                        },
                        {
                            key: "6",
                            children: <LabelsSection />,
                            label: <Title level={5} title="Labels" />,
                            extra: <HelpKeysDrawer panelLabel="Labels" definitionsFields={labelsDefinitionsFields} />,
                        },
                        isFloorsSectionShown && {
                            key: "7",
                            children: <FloorsSection />,
                            label: (
                                <Space>
                                    <Title level={5} title="Floors" />
                                    {hasSeatWriteAccess && (
                                        <Button onClick={handleAdvancedFloors} type="link" size="small">
                                            Advanced Floors
                                        </Button>
                                    )}
                                </Space>
                            ),
                        },
                    ].filter(Boolean) as ItemType[]
                }
            />
            <AffixFormButtonsBar
                loading={loading}
                disabled={loading}
                onSubmit={handleSubmit}
                onDelete={showModal}
                onCancel={handleCancel}
                isDeleting={isDeleting}
                isDeleteButtonShown={isEditMode}
                submitButtonTitle={submitButtonTitle}
            />
            <ConfirmationModal
                open={open}
                onOk={handleDelete}
                name={brand?.name}
                onDelete={handleDelete}
                confirmLoading={isDeleting}
                onCancel={handleCancelModal}
            />
        </Form>
    );
};
