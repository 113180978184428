import { Form, Input } from "antd";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { useIsFieldVisible } from "./useIsFieldVisible";

const { TextArea } = Input;

export const TagURLTextArea = () => {
    const reg = /https?:\/\/\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/gm;
    const isVisible = useIsFieldVisible();
    return isVisible ? (
        <Form.Item
            rules={[
                { required: true, message: "Tag URL is required" },
                {
                    pattern: reg,
                    message: "Invalid Tag Url, Protocol not present (http:// or https://)",
                },
            ]}
            {...VERTICAL_LAYOUT_FIX}
            label={AD_SOURCE_FIELDS.TAG_URL.label}
            name={AD_SOURCE_FIELDS.TAG_URL.name}
        >
            <TextArea rows={3} placeholder="Type here..." />
        </Form.Item>
    ) : null;
};
