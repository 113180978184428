import { useUserAccess } from "@app/core/auth";

interface UseInternalOnlyFields {
    isPublisherReAuctionShown: boolean;
}

export const useInternalOnlyFields = (): UseInternalOnlyFields => {
    const { isSysAdmin, isPubAcctMgr } = useUserAccess();
    const isPublisherReAuctionShown: boolean = isSysAdmin || isPubAcctMgr;
    return { isPublisherReAuctionShown };
};
