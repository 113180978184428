import { useAppDispatch, useAppSelector } from "@app/core/store";
import { AUCTION_PRICE_CPM_HELP_TEXT, CREATE_DEAL_FORM_ITEMS_NAME, DEAL_TYPES } from "@app/features/deals/constants";
import { dealFormInputFieldChange } from "@app/features/deals/DealForm/reducer";
import { useCurrencyOptions } from "@app/features/deals/DealForm/useCurrencyOptions";
import { XANDR_ID } from "@app/core/components/BuyerSeatsSelect";
import { useSyncedFieldsProducer } from "@app/features/syncedFields";

interface UseDealRateField {
    onBlur: (rate: string, isDirty?: boolean) => void;
    currencyCode: string | undefined;
    isRequired: boolean;
    helpText: string | undefined;
}

export const useDealRateField = (): UseDealRateField => {
    const dispatch = useAppDispatch();
    const { optionsByValue } = useCurrencyOptions();
    const currency = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.CURRENCY]);
    const dealType = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TYPE]);
    const dspNetworkId = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.DSP]);
    const isAuctionPriceDeal = dealType === DEAL_TYPES.AUCTION;
    const isXandr = dspNetworkId === XANDR_ID;
    const { updateSyncedFields } = useSyncedFieldsProducer();

    const onBlur = (rate: string, isDirty?: boolean): void => {
        if (isDirty) {
            dispatch(dealFormInputFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.RATE, value: rate }));
            updateSyncedFields({ cpmRate: rate });
        }
    };

    const currencyCode = optionsByValue[currency]?.label ? String(optionsByValue[currency].label) : undefined;
    const isRequired = !isAuctionPriceDeal || !isXandr;
    const helpText = isAuctionPriceDeal ? AUCTION_PRICE_CPM_HELP_TEXT : undefined;

    return {
        onBlur,
        currencyCode,
        isRequired,
        helpText,
    };
};
