import { MarketplaceInfo, useGetAvailableNetworksQuery } from "@app/core/services";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { AdSourceFormsContext, AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { Form } from "antd";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { DEAL_TYPES } from "../DealTypeSelect";

export const useNetworkSelect = () => {
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const { forms } = useContext(AdSourceFormsContext);
    const adSourceType = Form.useWatch<AdSourcesForm["type"]>(AD_SOURCE_FIELDS.TYPE.name, forms.adSourceForm);
    const { seatId } = useParams<{ seatId: string }>();
    const isReadonly = dealObjectMode === "view" || dealObjectMode === "edit";
    const dealType = Form.useWatch<MarketplaceInfo["deal"]["dealType"]>(["dealUnderEdit", "dealType"]);
    const isVisible = dealType?.name !== DEAL_TYPES.DEAL_TYPE_LEGACY.name;
    const { data: availableNetworks = [], isFetching: isLoading } = useGetAvailableNetworksQuery(
        {
            adSourceTypeId: adSourceType?.id,
            seatId: Number(seatId),
        },
        { skip: !adSourceType || !isVisible || isReadonly }
    );
    const selectedDspNetworkId = Form.useWatch<MarketplaceInfo["network"]>(["dealUnderEdit", "network"])?.id;

    const onChange = () => {
        forms.dealObjectForm.setFieldsValue({
            dealUnderEdit: {
                buyerSeats: undefined,
            },
        });
    };

    return {
        availableNetworks,
        dealObjectMode,
        isLoading,
        isReadonly,
        isVisible,
        selectedDspNetworkId,
        onChange,
    };
};
