import { Form } from "antd";
import { MarketplaceInfo, SeatAdSourcesType } from "@app/core/services";
import {
    AD_SOURCE_FIELDS,
    AdSourceTypes,
    DealEntityTypes,
    AD_SOURCE_TYPES_NEXT_UI_NAMES,
} from "@app/features/seatAdSources/constants";
import { findDealsWithSpecificTypes } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/findDealsWithSpecificTypes";

const { useWatch } = Form;

export const useIsFieldReadOnly = () => {
    // ToDo add actual adSourceType type to useWatch generic
    const deals = useWatch<MarketplaceInfo[]>(AD_SOURCE_FIELDS.DEMAND.name);
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);

    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSourceType?.name] ?? adSourceType?.name;
    const demandDeals = findDealsWithSpecificTypes({
        deals,
        entityTypes: [DealEntityTypes.Demand, DealEntityTypes.Buyer],
    });
    if (
        (adSourceTypeName === AdSourceTypes.PROGRAMATIC_GUARANTEED ||
            adSourceTypeName === AdSourceTypes.CONDITIONAL_PROGRAMATIC_GUARANTEED) &&
        demandDeals.length > 0
    ) {
        return true;
    }

    return false;
};
