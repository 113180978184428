import { useMemo } from "react";
import { CheckboxOptionType } from "antd/lib/checkbox/Group";
import { useGetAdUnitsAdPodFillModesQuery } from "@app/core/services";

interface UseAdUnitFillMode {
    options: CheckboxOptionType[];
}

export const useAdUnitFillMode = (): UseAdUnitFillMode => {
    const { data } = useGetAdUnitsAdPodFillModesQuery();

    const options: CheckboxOptionType[] = useMemo(
        () => (data || []).map(({ id, name }) => ({ value: id, label: name })),
        [data]
    );
    return {
        options,
    };
};
