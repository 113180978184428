import { ROUTES, ROUTE_FORMATTERS } from "@app/core/routing";
import {
    DetailsDrawerLayout,
    InventoryDrawerLayoutProps,
} from "@app/features/inventory/DetailsDrawer/DetailsDrawerLayout";
import { Col, Flex } from "antd";
import { FC } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { INVENTORY_SEAT_INTERNAL, INVENTORY_SEAT_INTERNAL_DEFINITION } from "../../../constants";
import { Definition } from "./Definition";
import { InternalContactsEditDrawer } from "./InternalContactsEditDrawer";
import { InternalContactsPageEditForm } from "./InternalContactsPageEditForm";

const getDrawerLayout = (
    isDefinition: boolean,
    openDefinition: () => void,
    title = "Ladle",
    definitionTitle = "Ladle Definition"
) => {
    const drawerLayoutProps: InventoryDrawerLayoutProps = {
        title,
        definitionTitle: title,
        handleDefintion: openDefinition,
    };

    if (isDefinition) {
        drawerLayoutProps.title = definitionTitle;
        drawerLayoutProps.definitionTitle = undefined;
        drawerLayoutProps.handleDefintion = undefined;
        drawerLayoutProps.breadcrumbs = title;
    }

    return drawerLayoutProps;
};

interface Props {
    handleOpen: () => void;
    handleClose: () => void;
}
export const InternalContactsPageEditSwitch: FC<Props> = ({ handleClose }) => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const handleOpenDefinition = () =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_SEAT_TABS(seatId, INVENTORY_SEAT_INTERNAL_DEFINITION));

    const drawerLayoutProps = getDrawerLayout(false, handleOpenDefinition, "Internal Contacts", "Internal Contacts");
    const handleCloseDrawer = () =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_SEAT_TABS(seatId, INVENTORY_SEAT_INTERNAL));

    return (
        <Switch>
            <Route exact path={ROUTES.SEAT_INVENTORY_SEAT_EDIT_INTERNAL}>
                <InternalContactsEditDrawer handleClose={handleClose}>
                    <Flex vertical data-sdet="inventory-contacts-page-edit">
                        <DetailsDrawerLayout {...drawerLayoutProps} handleCloseDrawer={handleCloseDrawer} />
                        <Col span={24}>
                            <InternalContactsPageEditForm />
                        </Col>
                    </Flex>
                </InternalContactsEditDrawer>
            </Route>
            <Route exact path={ROUTES.SEAT_INVENTORY_SEAT_INTERNAL_DEFINITION}>
                <InternalContactsEditDrawer handleClose={handleClose}>
                    <Flex vertical data-sdet="inventory-contacts-page-edit">
                        <DetailsDrawerLayout
                            title={"Internal Contacts Definition"}
                            breadcrumbs="Internal Contacts"
                            handleCloseDrawer={handleCloseDrawer}
                        />
                        <Col span={24}>
                            <Definition />
                        </Col>
                    </Flex>
                </InternalContactsEditDrawer>
            </Route>
        </Switch>
    );
};
