import { Form, InputNumber } from "antd";
import { RuleObject, StoreValue } from "rc-field-form/lib/interface";
import { useDurationField } from "../hooks/useDurationField";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { numberNormalize } from "@app/core/components/helpers";

export const MinDurationInput = () => {
    const { isVisible } = useDurationField();
    if (!isVisible) {
        return null;
    }

    const validator = (maxDuration: StoreValue, value: StoreValue): Promise<void> => {
        if (maxDuration != null && Number(value) > Number(maxDuration))
            return Promise.reject(new Error("Min Duration can't be greater than Max Duration"));
        return Promise.resolve();
    };

    return (
        <Form.Item
            name={AD_SOURCE_FIELDS.MIN_DURATION.name}
            label={AD_SOURCE_FIELDS.MIN_DURATION.label}
            data-sdet={`test-${AD_SOURCE_FIELDS.MIN_DURATION.name}-field`}
            normalize={numberNormalize}
            dependencies={[AD_SOURCE_FIELDS.MAX_DURATION.name]}
            rules={[
                ({ getFieldValue }) => ({
                    validator: (_: RuleObject, value: StoreValue) => {
                        const maxDuration = getFieldValue(AD_SOURCE_FIELDS.MAX_DURATION.name);
                        return validator(maxDuration, value);
                    },
                }),
            ]}
            {...VERTICAL_LAYOUT_FIX}
        >
            <InputNumber
                data-sdet="adsource-min-duration"
                addonAfter="Seconds"
                style={{ width: "100%" }}
                placeholder={`Enter ${AD_SOURCE_FIELDS.MIN_DURATION.label}`}
            />
        </Form.Item>
    );
};
