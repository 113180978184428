import { LabeledValue } from "antd/lib/select";
import { useParams } from "react-router-dom";
import { useGetSeatAdvertiserDomainFilterListDefsQuery } from "@app/core/services";
import { BRAND_SAFETY_BLOCK_TYPE } from "@app/features/inventory/InventoryBrandSafety/InventoryAdvancedBrandSafetyPage/constants";
import { useMemo } from "react";

interface UseReusableAdvertiserDomainsField {
    isLoading: boolean;
    options: LabeledValue[];
}

export const useReusableAdvertiserDomainsField = (type: number): UseReusableAdvertiserDomainsField => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data = [], isFetching } = useGetSeatAdvertiserDomainFilterListDefsQuery({
        seatId: Number(seatId),
        typeId: type || BRAND_SAFETY_BLOCK_TYPE.value,
    });
    const options: LabeledValue[] = useMemo(
        () =>
            (data || []).map((list) => ({
                value: list?.id,
                label: list?.name,
            })),
        [data]
    );

    return {
        options,
        isLoading: isFetching,
    };
};
