import { useState } from "react";

export type OpenDealCountDrawer = (adSourceId: number, adSourceName: string, dealCount: number) => void;

export interface UseSeatAdSourcesDealCountDrawer {
    requestedAdSourceId: number;
    isDealCountDrawerOpen: boolean;
    adSourceName: string;
    dealCount: number;
    openDealCountDrawer: OpenDealCountDrawer;
    closeDealCountDrawer: () => void;
}

export const useSeatAdSourcesDealCountDrawer = (): UseSeatAdSourcesDealCountDrawer => {
    const [requestedAdSourceId, setRequestedAdSourceId] = useState<number | null>(null);
    const [adSourceName, setAdSourceName] = useState<string>();
    const [dealCount, setDealCount] = useState<number>();

    const isDealCountDrawerOpen = typeof requestedAdSourceId === "number";

    const openDealCountDrawer = (adSourceId: number, adSourceName: string, dealCount: number) => {
        setRequestedAdSourceId(adSourceId);
        setAdSourceName(adSourceName);
        setDealCount(dealCount);
    };

    const closeDealCountDrawer = () => {
        setRequestedAdSourceId(null);
    };

    return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        requestedAdSourceId,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        adSourceName,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dealCount,
        isDealCountDrawerOpen,
        openDealCountDrawer,
        closeDealCountDrawer,
    };
};
