import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import { FC } from "react";
import { AD_SOURCE_FIELDS, AD_SOURCE_SECTIONS } from "../../constants";
import { useDefinitionPanel } from "./useDefinitionPanel";

interface Props {
    section: keyof typeof AD_SOURCE_SECTIONS;
    sectionSelector: string;
    elementType?: string | string[];
    skipVisibilityCheck?: boolean;
    panelLabel: string;
}

export const DefinitionSidePanel: FC<Props> = ({
    section,
    sectionSelector,
    elementType,
    skipVisibilityCheck,
    panelLabel,
}) => {
    const { definitionsFields, onPanelOpen } = useDefinitionPanel(
        section,
        sectionSelector,
        elementType,
        skipVisibilityCheck
    );

    return (
        <HelpKeysDrawer
            onPanelOpen={onPanelOpen}
            panelLabel={panelLabel}
            definitionsFields={definitionsFields.map((field) => ({
                key: field,
                label: AD_SOURCE_FIELDS[field].label,
                helpPath: AD_SOURCE_FIELDS[field].helpPath,
            }))}
        />
    );
};
