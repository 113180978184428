import { PAGE, SIZE } from "@app/core/components/hooks";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { BuyerSeatList, useGetSeatBuyerSeatListsQuery } from "@app/core/services";
import { uri } from "@rubicon/utils";
import { useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

interface UseBuyerSeatListsPage {
    handleEdit: (id: number) => void;
    data: BuyerSeatList[];
    isLoading: boolean;
    seatId: string;
    totalCount: number;
}
export const useBuyerSeatListsPage = (): UseBuyerSeatListsPage => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const { search: queryString } = useLocation();

    const params = useMemo(() => uri.getParamsFromSearch(queryString, [PAGE, SIZE]), [queryString]);
    const page = Number(params.page);
    const max = Number(params.size);

    const { data = [], isFetching } = useGetSeatBuyerSeatListsQuery({
        seatId: Number(seatId),
        max,
        page,
    });

    const handleEdit = (id: number) => {
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_EDIT_PAGE(seatId, id));
    };
    return {
        data,
        seatId: seatId,
        isLoading: isFetching,
        handleEdit,
        // Faked to only ever show next page since we don't have any pagination metadata
        totalCount: page * max + (data?.length === max ? 1 : 0),
    };
};
