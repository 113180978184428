import {
    AdUnitStatsSnapshot,
    BrandStatsSnapshot,
    PublisherStatsSnapshot,
    SupplyStatsSnapshot,
    useGetAdUnitStatsSnapshotsQuery,
    useGetBrandStatsSnapshotsQuery,
    useGetPublisherStatsSnapshotsQuery,
    useGetSupplyStatsSnapshotsQuery,
} from "@app/core/services/adStats/statsSnapshots";
import { useAppSelector } from "@app/core/store";
import {
    InventoryDetailsDrawerType,
    selectInventoryDetailsDrawerType,
} from "@app/features/inventory/DetailsDrawer/reducer";
import { useMemo } from "react";

interface UseGetUnitSnapshots {
    data?: PublisherStatsSnapshot[] | BrandStatsSnapshot[] | SupplyStatsSnapshot[] | AdUnitStatsSnapshot[];
    isLoading: boolean;
}
export const useGetUnitSnapshots = (id: number): UseGetUnitSnapshots => {
    const drawerType = useAppSelector(selectInventoryDetailsDrawerType);

    const { data: publisherData, isFetching: publisherIsLoading } = useGetPublisherStatsSnapshotsQuery(
        { publisherId: id },
        {
            skip:
                drawerType !== InventoryDetailsDrawerType.PUBLISHER &&
                drawerType !== InventoryDetailsDrawerType.CHANNEL,
        }
    );
    const { data: brandData, isFetching: brandIsLoading } = useGetBrandStatsSnapshotsQuery(
        { brandId: id },
        {
            skip:
                drawerType !== InventoryDetailsDrawerType.BRAND &&
                drawerType !== InventoryDetailsDrawerType.BRAND_CHANNEL,
        }
    );
    const { data: supplyData, isFetching: supplyIsLoading } = useGetSupplyStatsSnapshotsQuery(
        { supplyId: id },
        {
            skip: drawerType !== InventoryDetailsDrawerType.SUPPLY,
        }
    );
    const { data: adUnitData, isFetching: adUnitIsLoading } = useGetAdUnitStatsSnapshotsQuery(
        { adUnitId: id },
        { skip: drawerType !== InventoryDetailsDrawerType.AD_UNIT }
    );

    const isLoading = useMemo(
        () => publisherIsLoading || brandIsLoading || supplyIsLoading || adUnitIsLoading,
        [publisherIsLoading, brandIsLoading, supplyIsLoading, adUnitIsLoading]
    );

    return {
        data: publisherData || brandData || supplyData || adUnitData,
        isLoading,
    };
};
