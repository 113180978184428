import { w3cwebsocket } from "websocket";
import { sendEvent } from "@app/core/socket/events";
import { EventTypes } from "@app/core/socket/types";
import { EntityTypes } from "./types";
import { DEFAULT_TIME_ZONE } from "@app/core/components/constants";
import moment from "moment-timezone";

interface StartStatsConfig {
    entityType: EntityTypes;
    entityIds: number[];
    oneAndDone: true | null;
    publisherFilterIds?: number[]; // Omit if none
    adSourceTypeFilterIds?: number[];
    subEntityIds?: string[];
    timezone: string;
}

const getStatsMessage = (config: StartStatsConfig) => ({
    streams: [
        {
            asc: false,
            bucketUntilTime: moment()
                .tz(config.timezone || DEFAULT_TIME_ZONE.code)
                .startOf("day")
                .valueOf(),
            pageNum: null,
            pageSize: null,
            sortBy: null,
            subEntityIds: [],
            ...config,
        },
    ],
});

export const sendStartHistoryStatsEvent = (client: w3cwebsocket, config: StartStatsConfig) =>
    sendEvent(client, EventTypes.StartHistoryStats, getStatsMessage(config));

export const sendStartStatsEvent = (client: w3cwebsocket, config: StartStatsConfig) =>
    sendEvent(client, EventTypes.StartStats, getStatsMessage(config));

export const sendStopStatsEvent = (client: w3cwebsocket) => sendEvent(client, EventTypes.StopStats);
