import { FixedSaveButtons } from "@app/core/components";
import { Flex, Form, Input, Layout, Card } from "antd";
import { LabeledValue } from "antd/es/select";
import { FC, ReactNode } from "react";
import { COLUMN_KEYS, InitialValuesType } from "../constants";
import { BuyerSeatsSelect } from "./Fields/BuyerSeatsSelect/BuyerSeatsSelect";

const MAX_NAME_LENGTH = 64;
const MAX_DESCRIPTION_LENGTH = 256;
export const BUYER_SEAT_LIST_NAME = "buyer-seat-list-name";
export const BUYER_SEAT_LIST_DESCRIPTION = "buyer-seat-list-description";

interface Props {
    initialValues: Partial<InitialValuesType>;
    handleCancel: () => void;
    handleSubmit: (values: InitialValuesType) => void;
    deleteButton?: ReactNode;
    isSaving: boolean;
}

export const BuyerSeatListsForm: FC<Props> = ({
    children,
    initialValues,
    handleCancel,
    handleSubmit,
    deleteButton,
    isSaving,
}) => {
    const [form] = Form.useForm();

    return (
        <Form
            layout="vertical"
            style={{ width: "inherit" }}
            form={form}
            wrapperCol={{ span: 10 }}
            initialValues={initialValues}
            onFinish={handleSubmit}
        >
            <Layout.Content>
                <Card style={{ minHeight: "77vh" }} bordered={false}>
                    <Flex justify="space-between">
                        <Flex flex={1} vertical>
                            <Form.Item
                                label="Name"
                                name={COLUMN_KEYS.NAME}
                                rules={[
                                    { required: true, whitespace: true },
                                    {
                                        max: MAX_NAME_LENGTH,
                                        message: `Name should not be greater than ${MAX_NAME_LENGTH}`,
                                    },
                                ]}
                            >
                                <Input data-sdet={BUYER_SEAT_LIST_NAME} />
                            </Form.Item>
                            <Form.Item
                                label="Description"
                                name={COLUMN_KEYS.DESCRIPTION}
                                rules={[
                                    {
                                        max: MAX_DESCRIPTION_LENGTH,
                                        message: `Description should not be greater than ${MAX_DESCRIPTION_LENGTH}`,
                                    },
                                ]}
                            >
                                <Input.TextArea rows={6} data-sdet={BUYER_SEAT_LIST_DESCRIPTION} />
                            </Form.Item>
                            <Form.Item
                                label="Buyer Seats"
                                name={COLUMN_KEYS.BUYER_SEATS}
                                rules={[
                                    {
                                        required: true,
                                        validator: (_, value: LabeledValue[]) => {
                                            if (!value?.length) {
                                                return Promise.reject("Please select at least one buyer seat.");
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                    {
                                        validator: (_, value: LabeledValue[]) => {
                                            if (value?.length > 20) {
                                                return Promise.reject("Maximum 20 buyer seats can be selected.");
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                                extra="maximum 20 buyer seats"
                            >
                                <BuyerSeatsSelect />
                            </Form.Item>
                        </Flex>
                        {children}
                    </Flex>
                </Card>
            </Layout.Content>
            <FixedSaveButtons
                isLoading={isSaving}
                submitLabel="Save"
                onCancel={handleCancel}
                suffixAddOn={deleteButton}
            />
        </Form>
    );
};
