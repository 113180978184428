import { RootState } from "@app/core/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InventoryPermissionsState {
    isOpenDrawer: boolean;
    isSubmitting: boolean;
    isSubmitted: boolean;
}

const initialState: InventoryPermissionsState = {
    isOpenDrawer: false,
    isSubmitting: false,
    isSubmitted: false,
};

const inventoryPermissionsSlice = createSlice({
    name: "inventoryPermissions",
    initialState,
    reducers: {
        openDrawer: (state) => {
            state.isOpenDrawer = true;
        },
        closeDrawer: (state) => {
            state.isOpenDrawer = false;
        },
        handleSubmitting: (state, action: PayloadAction<boolean>) => {
            state.isSubmitting = action.payload;
        },
        handleSubmitted: (state, action: PayloadAction<boolean>) => {
            state.isSubmitted = action.payload;
        },
    },
});

export const selectInventoryPermissionsDrawerStatus = (state: RootState) => state.inventoryPermissions.isOpenDrawer;
export const selectInventoryPermissionsFormSubmitting = (state: RootState) => state.inventoryPermissions.isSubmitting;
export const selectInventoryPermissionsFormSubmitted = (state: RootState) => state.inventoryPermissions.isSubmitted;

export const { openDrawer, closeDrawer, handleSubmitting, handleSubmitted } = inventoryPermissionsSlice.actions;
export default inventoryPermissionsSlice.reducer;
