import {
    ColumnChartOptions,
    ColumnChartProps,
    LegendOptions,
} from "@app/features/seatAdSources/seatAdSourcesCharts/charts";
import { useConnectHistoryAdSourceAdServingAdStats } from "../../data";
import { adServingColorMapper, AdServingColors } from "../colors";
import { AdServingLegendTextMap } from "../legendText";
import { LoadingState } from "@app/core/components";
import moment from "moment-timezone";

const chartOptions: ColumnChartOptions = {
    color: adServingColorMapper,
} as const;

const legendOptions: LegendOptions = {
    textMap: AdServingLegendTextMap,
    colorMap: AdServingColors,
    layout: "vertical",
} as const;

interface UseSeatAdSources24HourAdCandidateSelectionChart {
    loadingState: LoadingState;
    columnChartProps: ColumnChartProps | undefined;
}

export const useSeatAdSources24HourAdCandidateSelectionChart = (
    adSourceId: number
): UseSeatAdSources24HourAdCandidateSelectionChart => {
    const last24Hours = { start: moment().subtract(1, "day"), end: moment() };
    const granularityHours = 0.5;
    const { loadingState, data: adSourceServingHistoryStats } = useConnectHistoryAdSourceAdServingAdStats(
        adSourceId,
        last24Hours,
        granularityHours
    );

    const chartData =
        adSourceServingHistoryStats?.map((history) => ({
            xValue: moment(history.nTime).format("HH:mm"),
            yValues: Object.keys(history.results).map((resultKey) => {
                const adServingResult = history.results[resultKey];
                return {
                    yValue: adServingResult.events,
                    series: adServingResult.code,
                };
            }),
        })) ?? [];

    return {
        loadingState,
        columnChartProps:
            loadingState === "loaded"
                ? {
                      id: "seat-ad-sources-24-hour-ad-candidate-selection-chart",
                      data: chartData,
                      chartOptions,
                      legendOptions,
                  }
                : undefined,
    };
};
