import { ROUTE_FORMATTERS } from "@app/core/routing";
import {
    BulkOperationDefinition,
    ExecutionStatus,
    useCreateBulkOperationMutation,
    useLazyGetBulkOperationExecutionStatusQuery,
} from "@app/core/services";
import { timeout } from "@app/core/utils";
import { notification } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { setCompleteWithErrorsMessage } from "../../../reducer";

export const useBulkOperationFormRunButton = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const [createBulkOperation] = useCreateBulkOperationMutation();
    const [getBulkOperationStatus] = useLazyGetBulkOperationExecutionStatusQuery();

    const handleSubmit = async (bulkOperationPayload: BulkOperationDefinition) => {
        try {
            setIsLoading(true);

            let executionProgress = await createBulkOperation(bulkOperationPayload).unwrap();

            while (executionProgress.status === ExecutionStatus.Running) {
                const [nextProgress] = await Promise.all([
                    getBulkOperationStatus(executionProgress.code).unwrap(),
                    timeout(2000),
                ]);
                executionProgress = nextProgress;
            }

            if (executionProgress.status === ExecutionStatus.Error) {
                notification.error({ message: executionProgress.error });
                setIsLoading(false);
            }

            if (executionProgress.status === ExecutionStatus.Completed) {
                notification.success({ message: "Your job has completed successfully!" });
                history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BULK_OPERATIONS(seatId));
            }

            if (executionProgress.status === ExecutionStatus.CompletedWithErrors) {
                dispatch(setCompleteWithErrorsMessage(executionProgress.error));
                history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BULK_OPERATIONS(seatId));
            }
        } catch (err) {
            setIsLoading(false);

            notification.error({
                message: err.message || err.data.errorDescription || "There was a problem executing the job.",
            });
        }
    };

    return {
        isLoading,
        handleSubmit,
    };
};
