const ID = "id";
const NAME = "name";
const DEAL_PRIORITY = "sortPriority";
const CODE = "code";
const NETWORK_NAME = "networkName";
const BUYER_SEAT = "buyerSeat";
const CURRENCY = "currency";
const DEAL_ORIGIN_NAME = "dealOriginName";
const DEAL_EXTERNAL_STATUS = "externalStatus";
const DEAL_ENTITY_TYPE = "entityType";
const DEAL_TRANSACTION_TYPE = "transactionType";
const DEAL_PRICING_TYPE = "pricingType";
const DEAL_TRIES = "tries";
const DEAL_FILLS = "fills";
const DEAL_IMPS = "imps";
const DEAL_NET_REV = "revenue";
const DEAL_STATUS_NAME = "statusName";
const DEAL_ACTIONS = "actions";
const START_DATE = "startDate";
const FREQ_CAPPING = "freqCapping";
const FREQ_CAPPING_ITEMS = "dealFreqCappings";
const TARGETING = "targeting";

// Mock
const AD_SOURCE = "adSource";
const END_DATE = "sortEndDate";
const CPM_RATE = "cpmRate";

export const COLUMN_KEY = {
    NAME,
    DEAL_PRIORITY,
    ID,
    CODE,
    NETWORK_NAME,
    DEAL_ORIGIN_NAME,
    DEAL_STATUS_NAME,
    DEAL_EXTERNAL_STATUS,
    DEAL_ENTITY_TYPE,
    DEAL_TRANSACTION_TYPE,
    DEAL_PRICING_TYPE,
    DEAL_TRIES,
    DEAL_FILLS,
    DEAL_IMPS,
    DEAL_NET_REV,
    DEAL_ACTIONS,
    AD_SOURCE,
    END_DATE,
    START_DATE,
    CPM_RATE,
    BUYER_SEAT,
    CURRENCY,
    FREQ_CAPPING,
    FREQ_CAPPING_ITEMS,
    TARGETING,
} as const;

export const TRANSACTION_TYPES = {
    RESERVED: { id: 1, name: "Reserved", label: "Res" },
    NON_RESERVED: { id: 2, name: "Non Reserved", label: "Non Res" },
};
export const PRICING_TYPES = {
    FIXED: { id: 1, name: "Fixed Price", label: "Fixed" },
    AUCTION: { id: 2, name: "Auction Price", label: "Auction" },
};
