import { FC } from "react";
import { Typography } from "antd";
import { Operations, TargetingDimensionHelpKeys } from "../constants";
import { HelpTooltip } from "@app/core/components";
import { InfoCircleFilled } from "@ant-design/icons";
import { gold6 } from "@rubicon/antd-components";

interface Props {
    operation: Operations;
    targetingBlocksCount: number;
    reusableTargetingBlocksCount: number;
    dealsWithTargeting: number;
}

export const InventoryAdvancedFloorsTargetingSectionHeader: FC<Props> = ({
    operation,
    targetingBlocksCount,
    reusableTargetingBlocksCount = 0,
    dealsWithTargeting = 0,
}) => {
    return (
        <Typography.Title level={5} style={{ marginBottom: "10px" }}>
            <span style={{ fontSize: "14px" }}>
                Targeting Mode: <span style={{ fontWeight: 400 }}>{operation === Operations.All ? "Merge" : "OR"}</span>
            </span>

            <span style={{ marginLeft: "20px", fontSize: "14px" }}>
                Targeting Blocks:{" "}
                {reusableTargetingBlocksCount > 0 ? (
                    <span style={{ fontWeight: 400 }}>
                        {targetingBlocksCount} ({reusableTargetingBlocksCount} reusable)
                    </span>
                ) : (
                    <span style={{ fontWeight: 400 }}>{targetingBlocksCount}</span>
                )}
            </span>

            {dealsWithTargeting > 0 && (
                <span style={{ marginLeft: "30px" }}>
                    Deals With Targeting: {dealsWithTargeting}
                    <span hidden={!dealsWithTargeting}>
                        <HelpTooltip
                            helpKeyList={TargetingDimensionHelpKeys}
                            helpKey={TargetingDimensionHelpKeys.TargetingDealsWithTargeting}
                            tooltipPlacement={"topRight"}
                            popover={true}
                        >
                            &nbsp; <InfoCircleFilled style={{ color: `${gold6}` }} />
                        </HelpTooltip>
                    </span>
                </span>
            )}
        </Typography.Title>
    );
};
