import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { CustomPixelDetailsPage } from "./CustomPixelDetailsPage";
import { useUserAccess } from "@app/core/auth";
import { CustomPixelCreatePage } from "./CustomPixelCreatePage";
import { CustomPixelEditPage } from "./CustomPixelEditPage";
import { CustomPixelsPage } from "./CustomPixelsPage";

export const CustomPixelsSwitch: FC = () => {
    const { canEditSeat } = useUserAccess();
    return (
        <Switch>
            <Route exact path={[ROUTES.SEAT_CONTROLS_CUSTOM_PIXELS, ROUTES.SEAT_CONTROLS_CUSTOM_PIXELS_DETAILS_DRAWER]}>
                <CustomPixelsPage />
            </Route>

            {canEditSeat && (
                <Route exact path={ROUTES.SEAT_CONTROLS_CUSTOM_PIXELS_CREATE}>
                    <CustomPixelCreatePage />
                </Route>
            )}
            {canEditSeat && ( // do not combine the same conditions into one block, otherwise Details Page will not render content
                <Route exact path={ROUTES.SEAT_CONTROLS_CUSTOM_PIXELS_EDIT}>
                    <CustomPixelEditPage />
                </Route>
            )}
            <Route exact path={ROUTES.SEAT_CONTROLS_CUSTOM_PIXELS_DETAILS}>
                <CustomPixelDetailsPage />
            </Route>
        </Switch>
    );
};
