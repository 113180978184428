import { FC } from "react";
import { AffiliateCostModel } from "@app/features/inventory/components/FormItems";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useAdUnitFormInstance } from "@app/features/inventory/HierarchyForms/AdUnitForm/AdUnitFormInstance";

export const CostModel: FC = () => {
    const { seat, publisher, brand, supply, adUnit } = useAdUnitFormInstance();
    const extra: string = `Inherited value: ${
        adUnit?.affiliateCostModel?.name ||
        supply?.affiliateCostModel?.name ||
        brand?.affiliateCostModel?.name ||
        publisher?.affiliateCostModel?.name ||
        seat?.affiliateCostModel?.name
    }`;

    return (
        <AffiliateCostModel
            extra={extra}
            name={AD_UNIT_FORM_FIELDS.AFFILIATE_COST_MODEL.name}
            label={AD_UNIT_FORM_FIELDS.AFFILIATE_COST_MODEL.label}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 24, xxl: 20 }}
            placeholder={AD_UNIT_FORM_FIELDS.AFFILIATE_COST_MODEL.placeholder}
        />
    );
};
