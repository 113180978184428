import { Layout, Card } from "antd";
import { ErrorResponse, useGetAdvertiserDetailsQuery } from "@app/core/services";
import { useHistory, useParams } from "react-router-dom";
import { Loading } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import useNotificationError from "@app/core/components/hooks/useNotificationError";
import { ClassificationsAdvertiserDetailsActions } from "./ClassificationsAdvertiserDetails/ClassificationsAdvertiserDetailsActions";
import { ClassificationsAdvertiserDetailsContent } from "./ClassificationsAdvertiserDetails/ClassificationsAdvertiserDetailsContent";
import { ClassificationsAdvertiserDefinitionsButtonAndDrawer } from "./ClassificationsAdvertisersTab/ClassificationsAdvertiserDefinitions";
import { ClassificationsAdvertisersTabDrawer } from "./ClassificationsAdvertisersTab/ClassificationsAdvertisersTabDrawer/ClassificationsAdvertisersTabDrawer";
import { PageHeader } from "@ant-design/pro-layout";

export const ClassificationsAdvertiserDetailsPage = () => {
    const { seatId, advertiserId } = useParams<{ seatId: string; advertiserId: string }>();
    const { data: advertiser, isLoading, error } = useGetAdvertiserDetailsQuery(Number(advertiserId));
    useNotificationError(error as ErrorResponse);

    const history = useHistory();

    if (isLoading) return <Loading />;

    if (!advertiser) return null;

    return (
        <>
            <PageHeader
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_TABS(seatId, "advertisers"))}
                title={`Advertiser: ${advertiser?.name}`}
                extra={<ClassificationsAdvertiserDefinitionsButtonAndDrawer />}
            >
                <ClassificationsAdvertiserDetailsActions
                    advertiser={advertiser}
                    onClickChangeHistory={() => {
                        history.push({ search: "drawer=history" });
                    }}
                />
            </PageHeader>
            <Layout.Content>
                <Card>
                    <ClassificationsAdvertiserDetailsContent advertiser={advertiser} />
                </Card>
                <ClassificationsAdvertisersTabDrawer showDirectLabel />
            </Layout.Content>
        </>
    );
};
