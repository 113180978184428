import { FC } from "react";
import { LabeledValue } from "antd/lib/select";
import { BuyerSeatsSelect } from "@app/core/components/BuyerSeatsSelect";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import { AdSourceTypeIds } from "@app/features/seatAdSources/constants";

interface Props {
    mode: DealFormMode;
    createdWithBuyerSeats: boolean;
}

export const DealBuyerField: FC<Props> = ({ mode, createdWithBuyerSeats }) => {
    const selectedDspNetworkId = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.DSP]);
    const selectedBuyerSeatIds = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.BUYER]
    )?.map((buyerSeat) => JSON.parse(String(buyerSeat.value)).id);
    const selectedAdSourceTypeId = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TYPE]);
    const isProgrammaticGuaranteed = selectedAdSourceTypeId === AdSourceTypeIds.AUTOMATED_GUARANTEED;

    const dispatch = useAppDispatch();
    const handleChange = (value: LabeledValue[]): void => {
        dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.BUYER, value }));
    };

    return (
        <BuyerSeatsSelect
            formItemName={CREATE_DEAL_FORM_ITEMS_NAME.BUYER}
            formMode={mode}
            selectedDspNetworkId={selectedDspNetworkId}
            selectedBuyerSeatIds={selectedBuyerSeatIds}
            createdWithBuyerSeats={createdWithBuyerSeats}
            handleChange={handleChange}
            isProgrammaticGuaranteed={isProgrammaticGuaranteed}
        />
    );
};
