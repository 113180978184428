import { useMemo } from "react";
import { TargetingDimension, SupplyTypesTargetsDimension } from "../types";
import { TargetingDimensionTypes } from "../constants";

export const useSupplyTypeDimension = (dimensions: TargetingDimension[]) => {
    const dimensionTypeMap = useMemo(
        () =>
            dimensions.reduce((acc, dimension) => {
                acc[dimension.type] = dimension;
                return acc;
            }, {}),
        [dimensions]
    );

    const supplyTypeDimension = dimensionTypeMap[TargetingDimensionTypes.SupplyTypes];

    const defaultSupplyType: SupplyTypesTargetsDimension = {
        type: TargetingDimensionTypes.SupplyTypes,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        condition: null,
        values: [],
    };

    return {
        supplyTypeDimension: supplyTypeDimension || defaultSupplyType,
    };
};
