import { AD_UNIT_FORM_FIELDS, SUPPLY_TYPES } from "@app/features/inventory/HierarchyForms/constants";
import { AdUnitExtendedImpWaitTime } from "@app/features/inventory/components/FormItems";
import { useAdUnitFormInstance } from "../../../AdUnitFormInstance";

export const ExtendedImpWaitTime = () => {
    const { supply } = useAdUnitFormInstance();
    const isDisabled = supply?.type?.id === SUPPLY_TYPES.LINEAR;

    return (
        <AdUnitExtendedImpWaitTime
            disabled={isDisabled}
            name={AD_UNIT_FORM_FIELDS.EXTENDED_IMP_WAIT_TIME.name}
            label={AD_UNIT_FORM_FIELDS.EXTENDED_IMP_WAIT_TIME.label}
            placeholder={AD_UNIT_FORM_FIELDS.EXTENDED_IMP_WAIT_TIME.placeholder}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 12 }}
        />
    );
};
