import { SearchOutlined } from "@ant-design/icons";
import { brandAmethyst, brandSlate } from "@rubicon/antd-components";
import { Badge, Button, Col, Form, Input, Row, Space, Typography } from "antd";
import { ChangeEvent, FC, ReactChild, useEffect } from "react";
import { BULK_OPERATION_FORM_ITEMS } from "../../../constants";
import { useSelector } from "react-redux";
import { selectHasChangedEditField, selectIsEntityEditTouched } from "../../../reducer";

interface Props {
    bulkEditCount: number;
    entitiesCount: number;
    entityLabel: string;
    children: ReactChild;
    handleRevertAll: () => void;
    handleSearch: (e: ChangeEvent<HTMLInputElement>) => void;
    isDisabledRevertAll: boolean;
    searchTerm: string;
}

export const BulkOperationEntitiesEditTableTools: FC<Props> = ({
    bulkEditCount,
    entitiesCount,
    entityLabel,
    children,
    handleRevertAll,
    handleSearch,
    isDisabledRevertAll,
    searchTerm,
}) => {
    const form = Form.useFormInstance();
    const { entityType } = form.getFieldsValue(true);
    const isEntityEditTouched = useSelector((state) => selectIsEntityEditTouched(state, entityType));
    const hasChangedEditField = useSelector((state) => selectHasChangedEditField(state, entityType));

    useEffect(() => {
        isEntityEditTouched &&
            form.validateFields([BULK_OPERATION_FORM_ITEMS.OPERATION_ENTITIES_EDIT_SECTION_ERROR_MESSAGE.name]);
    }, [isEntityEditTouched, hasChangedEditField, form]);

    return (
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <Form.Item
                name={BULK_OPERATION_FORM_ITEMS.OPERATION_ENTITIES_EDIT_SECTION_ERROR_MESSAGE.name}
                rules={[
                    {
                        validator: () =>
                            hasChangedEditField ? Promise.resolve() : Promise.reject("Please Edit at least one field"),
                    },
                ]}
                style={{ marginBottom: 0 }}
            >
                <Typography.Title level={5} style={{ margin: 0 }}>{`Total ${entitiesCount} ${entityLabel}${
                    entitiesCount === 1 ? "" : "s"
                }`}</Typography.Title>
            </Form.Item>
            <Row justify="space-between">
                <Col span={12}>
                    <Space size={8}>
                        {children}
                        <Typography.Text>
                            <Badge
                                data-sdet="selected-entities-count"
                                showZero
                                count={bulkEditCount}
                                style={{ backgroundColor: brandAmethyst }}
                            />
                            {` item(s) selected${bulkEditCount ? "." : ", please select items below."}`}
                        </Typography.Text>
                    </Space>
                </Col>
                <Col>
                    <Space>
                        <Input
                            allowClear
                            data-sdet="table-search-input"
                            onChange={handleSearch}
                            placeholder="Search Name"
                            suffix={!searchTerm && <SearchOutlined style={{ color: brandSlate }} />}
                            value={searchTerm}
                        />
                        <Button disabled={isDisabledRevertAll} onClick={handleRevertAll}>
                            Revert All
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Space>
    );
};
