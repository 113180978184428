import { FC } from "react";
import { Skeleton } from "antd";
import { css } from "@emotion/css";

export const LoadingCell: FC = () => (
    // TODO - add sdets
    <span data-sdet="loading-cell">
        <Skeleton.Input
            active={true}
            className={css`
                &.ant-skeleton-element {
                    height: 16px;
                }
                &.ant-skeleton-element .ant-skeleton-input {
                    width: 75px;
                    min-width: 75px;
                    height: 16px;
                    border-radius: 8px;
                }
            `}
        />
    </span>
);
