import { RevenueTypes } from "@app/core/clients/console";

export const REVENUE_TYPE_OPTIONS = [
    {
        label: "Net Revenue",
        value: RevenueTypes.NET_REVENUE,
    },
    {
        label: "Gross Revenue",
        value: RevenueTypes.GROSS_REVENUE,
    },
];
