import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { LabeledValue } from "antd/lib/select";
import { RootState } from "@app/core/store";
import { ActionCreatorWithOptionalPayload } from "@reduxjs/toolkit";

interface UseDealTableFilter {
    handleChangeValue: (value: number | string | undefined, option: LabeledValue | LabeledValue[]) => void;
    isLoading: boolean;
    options: LabeledValue[];
    value: number | string | null;
}

export const getUseDealTableFilter = (
    selectValue: (state: RootState) => LabeledValue | null,
    setValue: ActionCreatorWithOptionalPayload<LabeledValue, string>,
    useFetchOptions: () => { isFetching: boolean; data?: { id: number; name: string }[] }
): (() => UseDealTableFilter) => {
    const useDealTableFilter = (): UseDealTableFilter => {
        const dispatch = useAppDispatch();
        const filter = useAppSelector(selectValue);
        const { data, isFetching } = useFetchOptions();

        const handleChangeValue = (id: string | undefined, option: LabeledValue | LabeledValue[]) => {
            if (!id) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dispatch(setValue(null));
                return;
            }
            if (Array.isArray(option)) {
                dispatch(setValue(option[0]));
                return;
            }
            dispatch(setValue(option));
        };

        const options = useMemo(
            () =>
                (data || []).map((datum) => ({
                    value: datum.id,
                    label: datum.name,
                })),
            [data]
        );

        return {
            options,
            value: (filter?.value as string) || null,
            isLoading: isFetching,
            handleChangeValue,
        };
    };

    return useDealTableFilter;
};
