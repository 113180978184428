import { NONE } from "@app/core/components/constants";
import { Checkbox, Form, Input } from "antd";
import { ChangeEvent } from "react";
import { ReactNode } from "react";
import { KeyedSeatTag } from "../reducer";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ColumnsType } from "antd/es/table";

type TableRows = {
    className?: string;
    name?: ReactNode;
    description?: ReactNode;
    iit?: ReactNode;
    macro?: ReactNode | string;
};
export const PLAYER_WIDTH = "PLAYER_WIDTH";
export interface TagParamDefinitionRaw {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string; // "playerWidth"
    description: string;
    macro: string;
    type: {
        creationDate: string; // "2016-05-17T19:26:43.000+0000",
        updateDate: string;
        id: number;
        name: string;
    };
    importance: number;
    group: string;
}
export interface SeatTagParamSettingsRaw {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    macro: string;
    tagParamDefinition: TagParamDefinitionRaw;
    seat: {
        id: number;
        name: string;
        code: string;
        entityType: string;
    };
    selected: boolean;
    entityType: string;
    type: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string;
    };
    importance: number;
    group: string;
}
export interface TagParamRaw {
    tagParamDefinition: TagParamDefinitionRaw;
    seatTagParamSetting: SeatTagParamSettingsRaw;
}

export type FixedType = "left" | "right";

type TableRowsMeta = {
    isEdit: boolean;
    handleChange: (ev: ChangeEvent<HTMLInputElement>, tagId: number) => void;
    handleChangeCheckbox: (ev: CheckboxChangeEvent, tagId: number) => void;
};

export const getColumns = (): ColumnsType<TableRows> =>
    [
        {
            dataIndex: "iit",
            key: "iit",
            fixed: "left" as FixedType,
            sorter: false,
            title: "Include in Tag?",
            width: 150,
        },
        {
            dataIndex: "name",
            key: "name",
            sorter: false,
            title: "Param Name",
            width: 150,
        },
        {
            dataIndex: "macro",
            key: "macro",
            sorter: false,
            title: "Macro",
            width: 200,
        },
        {
            dataIndex: "description",
            key: "description",
            sorter: false,
            title: "Description",
        },
    ].filter(Boolean);

export const getTableRows = (
    data: TagParamRaw[] | undefined,
    macroValues: KeyedSeatTag | undefined,
    { isEdit, handleChange, handleChangeCheckbox }: TableRowsMeta
) =>
    data && macroValues
        ? data.map<TableRows>(({ tagParamDefinition: tag, seatTagParamSetting: seat }) => {
              return {
                  className: "",
                  iit: isEdit ? (
                      <Form.Item>
                          <Checkbox
                              name="iit"
                              onChange={(ev) => handleChangeCheckbox(ev, tag.id)}
                              defaultChecked={Boolean(macroValues[tag.id]?.selected || seat?.selected)}
                              checked={Boolean(macroValues[tag.id]?.selected)}
                          />
                      </Form.Item>
                  ) : (
                      (seat?.selected && "Yes") || "No"
                  ),
                  name: tag.name,
                  key: tag.id,
                  macro: isEdit ? (
                      <Form.Item>
                          <Input
                              name="macro"
                              onChange={(ev) => handleChange(ev, tag.id)}
                              value={macroValues[tag.id]?.macro}
                          />
                      </Form.Item>
                  ) : !isEdit && macroValues[tag.id]?.macro ? (
                      <Form.Item>
                          <Input name="macro" disabled value={macroValues[tag.id]?.macro || seat?.macro} />
                      </Form.Item>
                  ) : (
                      NONE
                  ),
                  description: tag.description,
              };
          })
        : [];
