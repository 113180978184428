import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { skipToken } from "@reduxjs/toolkit/query/react";
import {
    selectGetTimeZonesResult,
    useGetDashboardSupplyReportQuery,
    useGetCurrenciesQuery,
} from "@app/core/services/console";
import {
    getAcceptRateReport,
    getOpportunitiesReport,
    getRevenueCPMReport,
    getTotalFillRateReport,
    getTotalFillsReport,
    getTotalImpressionsReport,
    getTotalRequestReport,
    getTotalRevenueReport,
    getTotalUseRateReport,
    makeDataForMonth,
    makeDataForToday,
    makeWeeklyData,
    percentageDifference,
} from "@app/features/adStats";
import { useAppSelector } from "@app/core/store";
import { useSeatAuthContext } from "@app/core/auth";
import { DEMAND_TYPE_EXTERNAL_IDS } from "../DemandTypeFilter/constants";
import { getTimeFrameOptionConfig } from "../TimeFrameFilter";
import { getGraphConfig } from "../PerformanceQuickViewChart";
import {
    generateDemoSeatData,
    getChartStatDate,
    getChartStatValue,
    getGroupedChartStatsMetricOne,
    getGroupedChartStatsMetricTwo,
} from "../helpers";
import { selectAdStatHistoryById } from "@app/features/adStats/reducer";
import { useCurrencyConversion } from "../useCurrencyConversion";
import { selectUserTimezone } from "@app/core/authClient/reducer";
import { CurrencyConversionModes } from "@app/core/clients/console";
import {
    selectDashboardCurrencyConversionMode,
    selectDashboardPreferredCurrency,
    selectDashboardRevenueType,
    selectDashboardTimeFrameFilter,
} from "../reducer";
import moment from "moment-timezone";

export const initialPerformanceMetrics = {
    acceptRateThisPeriod: 0,
    acceptRateLastPeriod: 0,
    acceptRatePercentageDifference: 0,
    revenueThisPeriod: 0,
    revenueLastPeriod: 0,
    revenuePercentageDifference: 0,
    revenueCPMThisPeriod: 0,
    revenueCPMLastPeriod: 0,
    revenueCPMPercentageDifference: 0,
    requestsThisPeriod: 0,
    requestsLastPeriod: 0,
    requestsPercentageDifference: 0,
    opportunitiesThisPeriod: 0,
    opportunitiesLastPeriod: 0,
    opportunitiesPercentageDifference: 0,
    fillsThisPeriod: 0,
    fillsLastPeriod: 0,
    fillsPercentageDifference: 0,
    fillRateThisPeriod: 0,
    fillRateLastPeriod: 0,
    fillRatePercentageDifference: 0,
    impressionsThisPeriod: 0,
    impressionsLastPeriod: 0,
    impressionsPercentageDifference: 0,
    useRateThisPeriod: 0,
    useRateLastPeriod: 0,
    useRatePercentageDifference: 0,
};

const useTimeZoneId = () => {
    const timezone = useAppSelector(selectUserTimezone);
    const timeZones = useAppSelector(selectGetTimeZonesResult);
    const selectedTimeZone = timeZones.data?.find((tz) => tz.code === timezone);
    return selectedTimeZone?.id || null;
};

const getDashboardSupplyReportParams = (
    seat: number,
    period: string,
    currencyConversionId: number | null,
    timezoneId: number | null,
    publisherIds: LabeledValue[],
    adSourceTypes: LabeledValue[],
    currencyConversionMode: CurrencyConversionModes | null
) => {
    if (!timezoneId) {
        return skipToken;
    }

    return {
        seat,
        period,
        [currencyConversionMode === CurrencyConversionModes.CONVERT ? "currencyConversionId" : "currencyFilterId"]:
            currencyConversionId,
        timezoneId,
        publisherId: publisherIds.map((publisherOption) => Number(publisherOption.value)),
        adSourceTypeId: adSourceTypes
            .map((demandTypeOption) => DEMAND_TYPE_EXTERNAL_IDS[demandTypeOption.value])
            .flat(),
    };
};

export const useReportBySeatId = () => {
    const { isDemoContext, context } = useSeatAuthContext();
    const period = useAppSelector(selectDashboardTimeFrameFilter);
    const currencyCodeOrId = useAppSelector(selectDashboardPreferredCurrency);
    const currencyConversionMode = useAppSelector(selectDashboardCurrencyConversionMode);
    const timeZoneId = useTimeZoneId();
    const publisherIds = useAppSelector((state) => state.dashboard.filters.publisher);
    const adSourceTypes = useAppSelector((state) => state.dashboard.filters.demandType);
    const metricOneType = useAppSelector((state) => state.dashboard.filters.metricOne?.value);
    const metricTwoType = useAppSelector((state) => state.dashboard.filters.metricTwo?.value);
    const timeFrameConfig = getTimeFrameOptionConfig(period?.value);
    const revenueType = useAppSelector(selectDashboardRevenueType);
    const view = useAppSelector((state) => state.dashboard.filters.view);
    const timeZone = useAppSelector(selectUserTimezone);
    const last24ConsoleCastData = useAppSelector((state) => selectAdStatHistoryById(state, Number(context?.id)));
    const { data: currencies } = useGetCurrenciesQuery();
    const currency =
        typeof currencyCodeOrId === "number"
            ? currencyCodeOrId
            : (currencies || []).find((currency) => currency.code === currencyCodeOrId)?.id || null;
    const { preferredCurrency, currencyConversions } = useCurrencyConversion();

    const { data, isFetching: isLoading } = useGetDashboardSupplyReportQuery(
        getDashboardSupplyReportParams(
            Number(context?.id),
            String(period.value),
            currency,
            timeZoneId,
            publisherIds,
            adSourceTypes,
            currencyConversionMode
        )
    );

    const reportData = isDemoContext ? generateDemoSeatData(period?.value) : data;

    const { metricOne, metricTwo } = useMemo(() => {
        if (!reportData || !timeFrameConfig || isLoading || !last24ConsoleCastData) {
            return { metricOne: [], metricTwo: [] };
        }

        const metricOneConfig = getGraphConfig(metricOneType, revenueType);
        const metricTwoConfig = getGraphConfig(metricTwoType, revenueType);

        let formattedData = {
            thisPeriodData: [...reportData.thisPeriod[timeFrameConfig.dataProp].data],
            lastPeriodData: [...reportData.lastPeriod[timeFrameConfig.dataProp].data],
        };
        if (period.value === "today") {
            formattedData = makeDataForToday({
                reportData,
                timeFrameDataProp: timeFrameConfig.dataProp,
                currentTz: timeZone,
                last24ConsoleCastData,
                preferredCurrency,
                currencyConversions,
                currencyConversionMode,
            });
        } else if (period.value === "monthly") {
            formattedData = makeDataForMonth({
                reportData,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                thisPeriodHourlyData: reportData.thisPeriod.hourlyData.data,
                timeFrameDataProp: timeFrameConfig.dataProp,
            });
        }
        const metricOne = [
            ...getGroupedChartStatsMetricOne(
                formattedData.lastPeriodData.map((stat) => ({
                    value: getChartStatValue(stat, metricOneType, revenueType),
                    date: getChartStatDate(stat, timeFrameConfig.timeProp, period),
                })),
                metricOneConfig.title,
                timeFrameConfig.lastPeriod
            ),
            ...getGroupedChartStatsMetricOne(
                formattedData.thisPeriodData.map((stat) => ({
                    value: getChartStatValue(stat, metricOneType, revenueType),
                    date: getChartStatDate(stat, timeFrameConfig.timeProp, period),
                })),
                metricOneConfig.title,
                timeFrameConfig.thisPeriod
            ),
        ];

        const metricTwo = [
            ...getGroupedChartStatsMetricTwo(
                formattedData.lastPeriodData.map((stat) => ({
                    value: getChartStatValue(stat, metricTwoType, revenueType),
                    date: getChartStatDate(stat, timeFrameConfig.timeProp, period),
                })),
                metricTwoConfig.title,
                timeFrameConfig.lastPeriod
            ),
            ...getGroupedChartStatsMetricTwo(
                formattedData.thisPeriodData.map((stat) => ({
                    value: getChartStatValue(stat, metricTwoType, revenueType),
                    date: getChartStatDate(stat, timeFrameConfig.timeProp, period),
                })),
                metricTwoConfig.title,
                timeFrameConfig.thisPeriod
            ),
        ];

        return { metricOne, metricTwo };
    }, [
        reportData,
        timeFrameConfig,
        metricOneType,
        metricTwoType,
        period,
        isLoading,
        revenueType,
        last24ConsoleCastData,
        currencyConversionMode,
        currencyConversions,
        preferredCurrency,
        timeZone,
    ]);

    const performanceMetrics = useMemo(() => {
        if (!reportData || !timeFrameConfig || isLoading || !view?.value || !last24ConsoleCastData) {
            return initialPerformanceMetrics;
        }

        let formattedData = {
            thisPeriodData: [...reportData.thisPeriod[timeFrameConfig.dataProp].data],
            lastPeriodData: [...reportData.lastPeriod[timeFrameConfig.dataProp].data],
        };
        if (period.value === "today") {
            formattedData = makeDataForToday({
                reportData,
                timeFrameDataProp: timeFrameConfig.dataProp,
                currentTz: timeZone,
                last24ConsoleCastData,
                preferredCurrency,
                currencyConversions,
                currencyConversionMode,
            });
        } else if (period.value === "weekly" || period.value === "lastSevenDays") {
            formattedData = makeWeeklyData({
                reportData,
                timeFrameDataProp: timeFrameConfig.dataProp,
                currentTz: timeZone,
                last24ConsoleCastData,
                preferredCurrency,
                currencyConversions,
                currencyConversionMode,
            });
        } else if (
            period.value === "monthly" ||
            period.value === "lastThirtyDays" ||
            period.value === "quarterly" ||
            period.value === "yearly"
        ) {
            formattedData = makeDataForMonth({
                reportData,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                thisPeriodHourlyData: reportData.thisPeriod.hourlyData.data,
                timeFrameDataProp: timeFrameConfig.dataProp,
            });
        }

        if (period.value === "today") {
            const currentHour = moment().tz(timeZone).hour();
            formattedData.lastPeriodData = formattedData.lastPeriodData.filter((d) => d.hour < currentHour);
        } else if (period.value === "weekly") {
            const lastDay = moment().tz(timeZone).subtract(7, "days");
            formattedData.lastPeriodData = formattedData.lastPeriodData.filter((d) => moment(d.day).isBefore(lastDay));
        } else if (period.value === "monthly") {
            const previousDay = moment().tz(timeZone).subtract(1, "days").subtract(1, "months");
            formattedData.lastPeriodData = formattedData.lastPeriodData.filter((d) =>
                moment(d.day).isBefore(previousDay)
            );
        } else if (period.value === "quarterly") {
            formattedData.lastPeriodData = formattedData.lastPeriodData.filter(
                (d) => d.dayOfQuarter <= formattedData.thisPeriodData.length
            );
        }

        const revenueThisPeriod = getTotalRevenueReport(formattedData.thisPeriodData, revenueType, view.value);
        const revenueLastPeriod = getTotalRevenueReport(formattedData.lastPeriodData, revenueType, view.value);

        const requestsThisPeriod = getTotalRequestReport(formattedData.thisPeriodData, view.value);
        const requestsLastPeriod = getTotalRequestReport(formattedData.lastPeriodData, view.value);

        const opportunitiesThisPeriod = getOpportunitiesReport(formattedData.thisPeriodData);
        const opportunitiesLastPeriod = getOpportunitiesReport(formattedData.lastPeriodData);

        const fillsThisPeriod = getTotalFillsReport(formattedData.thisPeriodData, view.value);
        const fillsLastPeriod = getTotalFillsReport(formattedData.lastPeriodData, view.value);

        const fillRateThisPeriod = getTotalFillRateReport(
            formattedData.thisPeriodData,
            fillsThisPeriod,
            Boolean(context?.adPodEnabled)
        );
        const fillRateLastPeriod = getTotalFillRateReport(
            formattedData.lastPeriodData,
            fillsLastPeriod,
            Boolean(context?.adPodEnabled)
        );

        const impressionsThisPeriod = getTotalImpressionsReport(formattedData.thisPeriodData, view.value);
        const impressionsLastPeriod = getTotalImpressionsReport(formattedData.lastPeriodData, view.value);

        const revenueCPMThisPeriod = getRevenueCPMReport(revenueThisPeriod, impressionsThisPeriod);
        const revenueCPMLastPeriod = getRevenueCPMReport(revenueLastPeriod, impressionsLastPeriod);

        const useRateThisPeriod = getTotalUseRateReport(impressionsThisPeriod, fillsThisPeriod);
        const useRateLastPeriod = getTotalUseRateReport(impressionsLastPeriod, fillsLastPeriod);

        const acceptRateThisPeriod = getAcceptRateReport(formattedData.thisPeriodData);
        const acceptRateLastPeriod = getAcceptRateReport(formattedData.lastPeriodData);

        return {
            acceptRateThisPeriod,
            acceptRateLastPeriod,
            acceptRatePercentageDifference: percentageDifference(acceptRateThisPeriod, acceptRateLastPeriod),
            revenueThisPeriod,
            revenueLastPeriod,
            revenuePercentageDifference: percentageDifference(revenueThisPeriod, revenueLastPeriod),
            revenueCPMThisPeriod,
            revenueCPMLastPeriod,
            revenueCPMPercentageDifference: percentageDifference(revenueCPMThisPeriod, revenueCPMLastPeriod),
            requestsThisPeriod,
            requestsLastPeriod,
            requestsPercentageDifference: percentageDifference(requestsThisPeriod, requestsLastPeriod),
            opportunitiesThisPeriod: opportunitiesThisPeriod,
            opportunitiesLastPeriod: opportunitiesLastPeriod,
            opportunitiesPercentageDifference: percentageDifference(opportunitiesThisPeriod, opportunitiesLastPeriod),
            fillsThisPeriod: fillsThisPeriod,
            fillsLastPeriod: fillsLastPeriod,
            fillsPercentageDifference: percentageDifference(fillsThisPeriod, fillsLastPeriod),
            fillRateThisPeriod: fillRateThisPeriod,
            fillRateLastPeriod: fillRateLastPeriod,
            fillRatePercentageDifference: percentageDifference(fillRateThisPeriod, fillRateLastPeriod),
            impressionsThisPeriod: impressionsThisPeriod,
            impressionsLastPeriod: impressionsLastPeriod,
            impressionsPercentageDifference: percentageDifference(impressionsThisPeriod, impressionsLastPeriod),
            useRateThisPeriod: useRateThisPeriod,
            useRateLastPeriod: useRateLastPeriod,
            useRatePercentageDifference: percentageDifference(useRateThisPeriod, useRateLastPeriod),
        };
    }, [
        reportData,
        isLoading,
        revenueType,
        timeFrameConfig,
        view,
        period,
        last24ConsoleCastData,
        currencyConversionMode,
        currencyConversions,
        preferredCurrency,
        timeZone,
        context?.adPodEnabled,
    ]);

    return {
        data: reportData,
        isLoading: isLoading || !last24ConsoleCastData,
        metricOne,
        metricTwo,
        metricOneType,
        metricTwoType,
        performanceMetrics,
        adPodEnabled: Boolean(context?.adPodEnabled),
    };
};
