import { LineConfig } from "@ant-design/plots";
import { DAY_MONTH, DAY_NAME_MONTH_DAY_YEAR } from "@app/core/components/constants";
import { UniquesCountsEntityTypes, useGetUniquesCountsQuery } from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import { intl } from "@rubicon/utils";
import { useMemo } from "react";
import { InventoryDetailsDrawerType, selectInventoryDetailsDrawerType } from "../../../reducer";
import moment from "moment-timezone";

const lineChartConfig: Omit<LineConfig, "data"> = {
    xField: "x",
    yField: "y",
    yAxis: {
        title: {
            text: "Uniques",
            style: {
                fontSize: 14,
                fontWeight: 500,
                fill: "#262626",
            },
        },
        label: {
            formatter: (v) => intl.shortFormatter(v),
        },
    },
    xAxis: {
        title: {
            text: "Date",
            offset: 40,
            style: {
                fontSize: 14,
                fontWeight: 500,
                fill: "#262626",
            },
        },
        label: {
            formatter: (v) => moment(v).format(DAY_MONTH),
        },
    },
    height: 285,
};

interface UniquesCountNormalized {
    seriesDaily: { x: string; y: number }[];
    series30Day: { x: string; y: number }[];
    lastDailySum: number;
    last30DaySum: number;
}

interface ChartsData extends Omit<UniquesCountNormalized, "lastDailySum" | "last30DaySum"> {
    lastDailyAverage: string;
    last30DayAverage: string;
}

interface UseUniquesStats {
    dailyConfig: LineConfig;
    monthlyConfig: LineConfig;
    lastDailyAverage: string;
    last30DayAverage: string;
    isLoading: boolean;
    isFetching: boolean;
}

export const useUniquesStats = (id: number, platformFamily?: number, country?: string): UseUniquesStats => {
    const drawerType = useAppSelector(selectInventoryDetailsDrawerType);
    let entityType = UniquesCountsEntityTypes.SEAT;

    switch (drawerType) {
        case InventoryDetailsDrawerType.SEAT:
            entityType = UniquesCountsEntityTypes.SEAT;
            break;
        case InventoryDetailsDrawerType.PUBLISHER:
        case InventoryDetailsDrawerType.CHANNEL:
            entityType = UniquesCountsEntityTypes.PUBLISHER;
            break;
        case InventoryDetailsDrawerType.BRAND:
        case InventoryDetailsDrawerType.BRAND_CHANNEL:
            entityType = UniquesCountsEntityTypes.BRAND;
            break;
        case InventoryDetailsDrawerType.SUPPLY:
            entityType = UniquesCountsEntityTypes.SUPPLY;
            break;
        case InventoryDetailsDrawerType.AD_UNIT:
            entityType = UniquesCountsEntityTypes.AD_UNIT;
            break;
    }
    const { data, isLoading, isFetching } = useGetUniquesCountsQuery({
        entityType,
        id,
        country,
        platformFamily,
    });

    const { last30DayAverage, lastDailyAverage, seriesDaily, series30Day } = useMemo<ChartsData>(() => {
        const dataLength = data?.length || 1;
        const initialValue: UniquesCountNormalized = {
            seriesDaily: [],
            series30Day: [],
            lastDailySum: 0,
            last30DaySum: 0,
        };

        const { last30DaySum, lastDailySum, series30Day, seriesDaily } = (data || []).reduce((acc, uniquesCount) => {
            const { dailyUniques, past30DayUniques, day } = uniquesCount;
            acc.seriesDaily.push({ x: day, y: dailyUniques });
            acc.series30Day.push({ x: day, y: past30DayUniques });
            acc.lastDailySum += dailyUniques;
            acc.last30DaySum += past30DayUniques;

            return acc;
        }, initialValue);

        return {
            seriesDaily,
            series30Day,
            lastDailyAverage: intl.numberFormatter(Math.trunc(lastDailySum / dataLength)),
            last30DayAverage: intl.numberFormatter(Math.trunc(last30DaySum / dataLength)),
        };
    }, [data]);

    const dailyConfig: LineConfig = useMemo(
        () => ({
            data: seriesDaily,
            ...lineChartConfig,
            tooltip: {
                title: (v) => moment(v).format(DAY_NAME_MONTH_DAY_YEAR),
                formatter: ({ x, y }) => {
                    return {
                        name: `${moment(x).format("DD")} Daily Uniques`,
                        value: intl.numberFormatter(y),
                    };
                },
            },
        }),
        [seriesDaily]
    );
    const monthlyConfig: LineConfig = useMemo(
        () => ({
            data: series30Day,
            ...lineChartConfig,
            tooltip: {
                title: (v) => moment(v).format(DAY_NAME_MONTH_DAY_YEAR),
                formatter: ({ x, y }) => {
                    return {
                        name: `${moment(x).format("DD")} Day Uniques`,
                        value: intl.numberFormatter(y),
                    };
                },
            },
        }),
        [series30Day]
    );

    return {
        dailyConfig,
        monthlyConfig,
        lastDailyAverage,
        last30DayAverage,
        isLoading,
        isFetching,
    };
};
