import {
    selectGetCurrenciesConversionsResult,
    selectGetCurrenciesResult,
    useGetCurrenciesPrefetch,
} from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import { CURRENCY_DEFS } from "@app/core/utils/currencies";

export const useCurrency = () => {
    useGetCurrenciesPrefetch();
    const currencies = useAppSelector(selectGetCurrenciesResult);
    const currencyConversions = useAppSelector(selectGetCurrenciesConversionsResult);
    const { adSourcesPreferredCurrency } = useAppSelector((state) => state.seatAdSources.settings.values);
    const preferredCurrency = currencies.data?.find((currency) => currency.id === adSourcesPreferredCurrency);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const currencyConfig = CURRENCY_DEFS[preferredCurrency?.code];

    const getCurrencyById = (id: number) => {
        return currencies.data?.find((currency) => currency.id === id);
    };

    return {
        preferredCurrency,
        currencyConversions: currencyConversions?.data || [],
        currencyConfig,
        getCurrencyById,
    };
};
