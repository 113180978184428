import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { useGetAdSourceAuctionTypesQuery } from "@app/core/services";

interface UseAdSourceAuctionTypeDefault {
    isFetching: boolean;
    options: LabeledValue[];
}

export const useAdSourceAuctionTypeDefault = (): UseAdSourceAuctionTypeDefault => {
    const { data = [], isFetching } = useGetAdSourceAuctionTypesQuery();
    const options = useMemo(() => (data || []).map((type) => ({ value: type.id, label: type.name })), [data]);
    return {
        isFetching,
        options,
    };
};
