import { FC, useEffect, useRef } from "react";
import { useInventoryHealthInventoryTable, getKey, TableDatum } from "./useInventoryHealthInventoryTable";
import { Col, Row, Space, Table } from "antd";
import { useSeatAuthContext } from "@app/core/auth";
import { CurrencyFilters } from "@app/features/inventory/CurrencyFilters";
import { ManageColumnsButton, ManageColumnsDrawer } from "@app/core/components/ManageColumns";
import { InventoryHealthInventoryTableFilterSearch } from "./InventoryHealthInventoryTableFilterSearch";
import { ExpandButton } from "./ExpandButton";
import { useManageColumns } from "./useManageColumns";
import { css } from "@emotion/css";

export const InventoryHealthInventoryTable: FC = () => {
    const { context } = useSeatAuthContext();
    const {
        adStats,
        data,
        expandedKeys,
        isLoadingInitialData,
        loadedEntities,
        loadingEntities,
        onCollapse,
        onExpand,
        selectedRow,
        shouldVirtualize,
    } = useInventoryHealthInventoryTable();
    const { columns, handleOpenManageColumnsDrawer, handleCloseManageColumnsDrawer, isDrawerOpen, onChangeColumns } =
        useManageColumns(context, adStats, expandedKeys);

    const tableRef: Parameters<typeof Table>[0]["ref"] = useRef(null);

    useEffect(() => {
        if (!selectedRow.isLoading && selectedRow.key && selectedRow.shouldScrollTo) {
            // Virtualized tables have scroll.y set and will only move the selected row to the bottom of the table
            if (shouldVirtualize) {
                window.scrollTo({
                    top:
                        Number(
                            document.getElementById("inventory-health-inventory-table")?.getBoundingClientRect().top
                        ) + window.scrollY,
                    behavior: "smooth",
                });
            }
            tableRef.current?.scrollTo({ key: selectedRow.key });
        }
    }, [selectedRow.isLoading, selectedRow.key, selectedRow.shouldScrollTo, shouldVirtualize]);

    return (
        <Space direction="vertical" size="small" style={{ width: "100%" }}>
            <Row gutter={[8, 8]}>
                <Col>
                    <InventoryHealthInventoryTableFilterSearch />
                </Col>
                <Col>
                    <CurrencyFilters />
                </Col>
                <Col flex="1" style={{ display: "flex", justifyContent: "flex-end" }}>
                    <ManageColumnsButton handleOpen={handleOpenManageColumnsDrawer} />
                </Col>
            </Row>
            <Table<TableDatum>
                id="inventory-health-inventory-table"
                data-sdet="publisher-list-table"
                ref={tableRef}
                className={css`
                    .ant-table-selection-column {
                        padding: 0 !important;
                    }
                `}
                onRow={(record) => {
                    return {
                        onClick: (e) => {
                            const isTableTarget =
                                "className" in e.target &&
                                typeof e.target.className === "string" &&
                                e.target.className.includes("ant-table");

                            if (!isTableTarget) {
                                return;
                            }
                            if (expandedKeys.includes(getKey(record))) {
                                onCollapse(record);
                                return;
                            }

                            onExpand(record);
                        },
                    };
                }}
                virtual={shouldVirtualize}
                scroll={{
                    x: 1550,
                    y: shouldVirtualize ? 800 : undefined,
                }}
                pagination={false}
                size="small"
                loading={isLoadingInitialData || selectedRow.isLoading}
                columns={columns.filter((item) => item.visible)}
                rowKey={(record) => `${record.entityType}-${record.id}`}
                dataSource={data}
                rowSelection={{
                    selectedRowKeys: selectedRow.key ? [selectedRow.key] : [],
                    hideSelectAll: true,
                    columnWidth: 0,
                    getCheckboxProps: () => ({ style: { display: "none" } }),
                }}
                expandable={{
                    defaultExpandedRowKeys: context ? [`Seat-${context?.id}`] : [],
                    expandedRowKeys: expandedKeys,
                    onExpand: (expanded, record) => {
                        if (expanded) {
                            onExpand(record);
                            return;
                        }
                        onCollapse(record);
                    },
                    expandIcon: ({ prefixCls, record, onExpand, expanded, expandable }) => {
                        return (
                            <ExpandButton
                                prefixCls={prefixCls}
                                record={record}
                                expanded={expanded}
                                expandable={expandable}
                                onExpand={onExpand}
                                isLoading={loadingEntities.has(getKey(record))}
                                isLoaded={loadedEntities.has(getKey(record))}
                            />
                        );
                    },
                }}
            />
            <ManageColumnsDrawer
                visible={isDrawerOpen}
                columns={columns}
                title="Manage Columns for Inventory"
                handleClose={handleCloseManageColumnsDrawer}
                onChange={onChangeColumns}
            />
        </Space>
    );
};
