import { FC } from "react";
import { AffiliateCostModel } from "@app/features/inventory/components/FormItems";
import { PUBLISHER_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { usePublisherFormInstance } from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormInstance";

export const CostModel: FC = () => {
    const { seat, publisher } = usePublisherFormInstance();
    const extra: string = `Inherited value: ${publisher?.affiliateCostModel?.name || seat?.affiliateCostModel?.name}`;

    return (
        <AffiliateCostModel
            extra={extra}
            name={PUBLISHER_FORM_FIELDS.AFFILIATE_COST_MODEL.name}
            label={PUBLISHER_FORM_FIELDS.AFFILIATE_COST_MODEL.label}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 24, xxl: 20 }}
            placeholder={PUBLISHER_FORM_FIELDS.AFFILIATE_COST_MODEL.placeholder}
        />
    );
};
