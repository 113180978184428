import { Layout } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { AdvancedFloor, useGetSeatAdvancedFloorByIdQuery } from "@app/core/services";
import { Footer, Loading } from "@app/core/components";
import { FloorAdvancedForm } from "../FloorAdvancedForm";
import { PageHeader } from "@ant-design/pro-layout";

export const InventoryFloorsAdvancedEditPage = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const { data, isFetching } = useGetSeatAdvancedFloorByIdQuery(Number(id), { skip: !id });

    if (isFetching) {
        return <Loading />;
    }

    return (
        <>
            <PageHeader title={`Edit Floor: ${data?.name}`} onBack={history.goBack} />
            <Layout.Content>
                <FloorAdvancedForm mode="edit" floor={data as AdvancedFloor} />
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
