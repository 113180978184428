import { FC } from "react";
import { CodeFormItem } from "@app/features/inventory/components/FormItems";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useBrandChannelFormInstance } from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormInstance";

export const Code: FC = () => {
    const { mode, seat, useWatch } = useBrandChannelFormInstance();
    return (
        <CodeFormItem
            mode={mode}
            seat={seat}
            useWatch={useWatch}
            name={CHANNEL_FORM_FIELDS.CODE.name}
            label={CHANNEL_FORM_FIELDS.CODE.label}
            placeholder={CHANNEL_FORM_FIELDS.CODE.placeholder}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 }}
            dataSdet="channel-code"
        />
    );
};
