import { Form } from "antd";
import { FC } from "react";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import { SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const NoAdCookieSync: FC = () => {
    return (
        <Form.Item {...SUPPLY_FORM_FIELDS.ENABLE_NO_AD_COOKIE_SYNC}>
            <BooleanRadioGroup />
        </Form.Item>
    );
};
