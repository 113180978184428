import { Button, Col, Layout, Radio, Row, Space } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { uri } from "@rubicon/utils";
import { ClassificationsAdvertisersTable } from "./ClassificationsAdvertisersTable";
import { ClassificationsAdvertiserDomainsTable } from "./ClassificationsAdvertiserDomainsTable";
import { DebounceUriSearch } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ClassificationsAdvertisersTabDrawer } from "./ClassificationsAdvertisersTabDrawer/ClassificationsAdvertisersTabDrawer";
import { useUserAccess } from "@app/core/auth";

export const CLASSIFICATIONS_ADVERTISERS_TAB_SDET = "classifications-advertisers-tab";
export const CLASSIFICATIONS_ADVERTISERS_TAB_SEARCH_SDET = "classifications-advertisers-tab-search";
export const ADVERTISER_RADIO_SDET = "advertiser-radio";
export const ADVERTISER_DOMAIN_RADIO_SDET = "advertiser-domain-radio";
export const ADD_ADVERTISER_BUTTON_SDET = "add-advertiser-button";

export const ClassificationsAdvertisersTab = () => {
    const { search: queryString } = useLocation();
    const { seatId } = useParams<{ seatId: string }>();
    const table = new URLSearchParams(queryString).get("table") || "advertiser";
    const history = useHistory();
    const { canEditSeat } = useUserAccess();

    return (
        <Layout.Content>
            <Space
                data-sdet={CLASSIFICATIONS_ADVERTISERS_TAB_SDET}
                direction="vertical"
                size="large"
                style={{ width: "100%" }}
            >
                <Row justify="space-between">
                    <Col>
                        <Space size="large">
                            <DebounceUriSearch sdet={CLASSIFICATIONS_ADVERTISERS_TAB_SEARCH_SDET} />
                            <Radio.Group
                                onChange={(e) => {
                                    const deleteSort = uri.deleteSearchParam(queryString, "sort");
                                    const nextQueryString = uri.setSearchParam(deleteSort, "table", e.target.value);
                                    history.push({ search: nextQueryString });
                                }}
                                value={table}
                            >
                                <Radio data-sdet={ADVERTISER_RADIO_SDET} value="advertiser">
                                    Advertiser
                                </Radio>
                                <Radio data-sdet={ADVERTISER_DOMAIN_RADIO_SDET} value="advertiserDomain">
                                    Advertiser Domain
                                </Radio>
                            </Radio.Group>
                        </Space>
                    </Col>
                    {canEditSeat && (
                        <Col>
                            <Button
                                data-sdet={ADD_ADVERTISER_BUTTON_SDET}
                                type="primary"
                                onClick={() =>
                                    history.push(
                                        ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISER_CREATE_PAGE(seatId)
                                    )
                                }
                            >
                                Add Advertiser
                            </Button>
                        </Col>
                    )}
                </Row>
                {table === "advertiser" && <ClassificationsAdvertisersTable />}
                {table === "advertiserDomain" && <ClassificationsAdvertiserDomainsTable />}
            </Space>
            <ClassificationsAdvertisersTabDrawer />
        </Layout.Content>
    );
};
