import { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { ClassificationsPageHeader } from "./ClassificationsPageHeader";
import { ClassificationsAdvertisersSwitch } from "./advertisers/ClassificationsAdvertisersSwitch";
import { ClassificationsLabelsSwitch } from "./labels/ClassificationsLabelsSwitch";
import { ClassificationsIndustriesSwitch } from "./industries/ClassificationsIndustriesSwitch";

export const ClassificationsSwitch: FC = () => {
    return (
        <>
            <Switch>
                <Redirect
                    exact
                    from={ROUTES.SEAT_CONTROLS_CLASSIFICATIONS}
                    to={ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISERS}
                />
            </Switch>
            <ClassificationsPageHeader />
            <Switch>
                <Route path={ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISERS}>
                    <ClassificationsAdvertisersSwitch />
                </Route>
                <Route path={ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRIES}>
                    <ClassificationsIndustriesSwitch />
                </Route>
                <Route path={ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_LABELS}>
                    <ClassificationsLabelsSwitch />
                </Route>
            </Switch>
        </>
    );
};
