import { FC } from "react";
import { Select, Button, Row, Col, Typography } from "antd";
import { LabeledValue } from "antd/lib/select";
import { LabelValuesTargetingDimension, UsedDimensions } from "../../../types";
import { Conditions, TargetingDimensionTypes, TargetingDimensionTypeLabels } from "../../../constants";
import { MoreMenu } from "../../MoreMenu";
import { ConditionSelect } from "../../ConditionSelect";
import { DimensionRow } from "../DimensionRow";
import { applyCustomLabel, TagRender } from "@app/core/components/TagRender";
import { isAddDisabled } from "../../../helpers";
import { PlusOutlined } from "@ant-design/icons";
import { useInventoryLabelSelect } from "./useInventoryLabelSelect";

interface Props {
    dimension: LabelValuesTargetingDimension;
    onChangeCondition: (value: Conditions) => void;
    onChangeDimensionValues: (value: LabeledValue[]) => void;
    onClickDelete: () => void;
    onClickDownload: () => void;
    onClickDuplicate: () => void;
    usedDimensions: UsedDimensions;
}

export const InventoryLabelDimensionRow: FC<Props> = ({
    dimension,
    onChangeCondition,
    onChangeDimensionValues,
    onClickDelete,
    onClickDownload,
    onClickDuplicate,
    usedDimensions,
}) => {
    const {
        selectedInventoryLabelId,
        setSelectedInventoryLabelId,
        selectedInventoryLabelValue,
        setSelectedInventoryLabelValue,
        isInventoryLabelOptionsLoading,
        isInventoryLabelValuesLoading,
        inventoryLabelOptions,
        inventoryLabelValuesOptions,
        addInventoryLabelValuePair,
        setIsDropdownVisible,
        isDropdownVisible,
    } = useInventoryLabelSelect(dimension.values, onChangeDimensionValues);

    return (
        <DimensionRow
            label={TargetingDimensionTypeLabels.LabelValues}
            condition={<ConditionSelect onChange={onChangeCondition} value={dimension.condition} />}
            value={
                <Select
                    allowClear
                    mode="multiple"
                    loading={isInventoryLabelOptionsLoading}
                    placeholder="Select Label/Value Pairs"
                    style={{ width: "100%" }}
                    labelInValue
                    tagRender={(props) => {
                        return <TagRender {...props} label={applyCustomLabel(props.label)} />;
                    }}
                    value={dimension.values}
                    open={isDropdownVisible}
                    onChange={onChangeDimensionValues}
                    onDropdownVisibleChange={setIsDropdownVisible}
                    dropdownRender={() => (
                        <Row
                            align="middle"
                            style={{ padding: "18px 16px" }}
                            gutter={16}
                            // Prevent events in custom dropdown from propagating to the Select component
                            onMouseDown={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <Col xs={8}>
                                <Select
                                    onChange={setSelectedInventoryLabelId}
                                    loading={isInventoryLabelOptionsLoading}
                                    options={inventoryLabelOptions}
                                    value={selectedInventoryLabelId}
                                    style={{ width: "100%" }}
                                />
                            </Col>
                            <Col>
                                <Row justify="center">
                                    <Col>
                                        <Typography.Text strong>:</Typography.Text>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={8}>
                                <Select
                                    onChange={setSelectedInventoryLabelValue}
                                    loading={isInventoryLabelValuesLoading}
                                    options={inventoryLabelValuesOptions}
                                    value={selectedInventoryLabelValue}
                                    style={{ width: "100%" }}
                                    disabled={!selectedInventoryLabelId}
                                />
                            </Col>
                            <Col>
                                <Button
                                    size="small"
                                    type="link"
                                    icon={<PlusOutlined />}
                                    onClick={addInventoryLabelValuePair}
                                    disabled={!selectedInventoryLabelId || !selectedInventoryLabelValue}
                                >
                                    Add Label
                                </Button>
                            </Col>
                        </Row>
                    )}
                />
            }
            menu={
                <MoreMenu
                    onClickDelete={onClickDelete}
                    onClickDownload={onClickDownload}
                    onClickDuplicate={onClickDuplicate}
                    isDuplicateDisabled={isAddDisabled(TargetingDimensionTypes.LabelValues, usedDimensions)}
                />
            }
        />
    );
};
