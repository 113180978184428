import { LoadingOutlined } from "@ant-design/icons";
import { FC } from "react";
import { TableDatum } from "./useInventoryHealthInventoryTable";
import cx from "classnames";
import { GlobalIcon } from "@app/core/components";

interface Props {
    expandable: boolean;
    expanded: boolean;
    isLoaded: boolean;
    isLoading: boolean;
    onExpand: (v: TableDatum, e: unknown) => void;
    prefixCls: string;
    record: TableDatum;
}

export const ExpandButton: FC<Props> = ({ expandable, expanded, isLoaded, isLoading, onExpand, prefixCls, record }) => {
    if (isLoading && !isLoaded) {
        return (
            <span
                style={{
                    display: "inline-block",
                    height: "16px",
                    marginInlineEnd: "9px",
                    width: "16px",
                    float: "left",
                    position: "relative",
                }}
            >
                <LoadingOutlined style={{ height: "16px", width: "16px" }} />
            </span>
        );
    }
    if (record.entityType === "Channel") {
        return (
            <span
                style={{
                    display: "inline-block",
                    float: "left",
                    fontWeight: 600,
                    height: "14px",
                    marginInlineEnd: "9px",
                    marginInlineStart: "2px",
                    marginTop: "2.5px",
                    position: "relative",
                    width: "14px",
                }}
            >
                <GlobalIcon wrapperStyle={{ height: "16px", width: "16px" }} />
            </span>
        );
    }
    if (!record.children) {
        return (
            <span
                style={{
                    display: "inline-block",
                    float: "left",
                    height: "16px",
                    marginInlineEnd: "9px",
                    position: "relative",
                    width: "16px",
                }}
            />
        );
    }
    const iconPrefix = `${prefixCls}-row-expand-icon`;

    return (
        <button
            type="button"
            onClick={(e) => {
                onExpand(record, e!);
                e.stopPropagation();
            }}
            className={cx(iconPrefix, {
                [`${iconPrefix}-spaced`]: !expandable,
                [`${iconPrefix}-expanded`]: expandable && expanded,
                [`${iconPrefix}-collapsed`]: expandable && !expanded,
            })}
            aria-expanded={expanded}
        />
    );
};
