import { useRef, useEffect } from "react";
import { useAnimatedValue } from "./useAnimatedValue";

export const useAnimatedNumber = ({
    value = 0,
    duration = 2100,
    isPlaying: isPlayingProps,
}: {
    value: number;
    duration?: number;
    isPlaying?: boolean;
}): number => {
    const prevValueRef = useRef<number>(value);
    const curValueRef = useRef<number>(value);
    const animatedValue = useAnimatedValue({
        start: prevValueRef.current,
        end: curValueRef.current,
        isPlaying: isPlayingProps,
        duration: duration / 1000,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => animatedValue.reset(), []);

    useEffect(() => {
        prevValueRef.current = value;
        curValueRef.current = value;
        animatedValue.reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPlayingProps]);

    useEffect(() => {
        prevValueRef.current = curValueRef.current;
        curValueRef.current = value;
        animatedValue.reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return Number(animatedValue.value);
};
