import conf from "@app/core/conf";
import { useGetRawCreativeSamplesQuery } from "@app/core/services";
import { useGetCreativeDetailsQuery, useGetMediaUrlQuery } from "@app/core/services/diagnostics/mediaUrls";
import { Creative } from "./CreativeReview";
import { useGetCreativeLoudness } from "./utils";

interface MediaFile {
    type?: string;
    id?: string;
    height?: string;
    width?: string;
    delivery?: string;
    bitrate?: string;
    apiFramework?: string;
    url?: string;
}
interface DataFromRawCreative {
    adSystem?: string;
    adTitle?: string;
    duration?: string;
    mediaFiles?: MediaFile[];
}

export const useCreativeReviewContent = (creative: Creative) => {
    const parsedFromRawCreative: DataFromRawCreative = {};
    const { data: rawCreative } = useGetRawCreativeSamplesQuery({
        sampleTime: creative.sampleTime,
        reqId: creative.reqId,
    });

    if (rawCreative) {
        parsedFromRawCreative.adSystem = rawCreative.querySelector("AdSystem")?.textContent || undefined;
        parsedFromRawCreative.adTitle = rawCreative.querySelector("AdTitle")?.textContent || undefined;
        parsedFromRawCreative.duration = rawCreative.querySelector("Duration")?.textContent || undefined;
        parsedFromRawCreative.mediaFiles = [];
        rawCreative.querySelectorAll("MediaFile").forEach((mediaFile) => {
            parsedFromRawCreative.mediaFiles?.push({
                type: mediaFile.attributes["type"]?.value,
                id: mediaFile.attributes["id"]?.value,
                height: mediaFile.attributes["height"]?.value,
                width: mediaFile.attributes["width"]?.value,
                delivery: mediaFile.attributes["delivery"]?.value,
                bitrate: mediaFile.attributes["bitrate"]?.value,
                apiFramework: mediaFile.attributes["apiFramework"]?.value,
                url: mediaFile?.textContent || undefined,
            });
        });
    }

    const { data: mediaData } = useGetMediaUrlQuery(creative.uhash);

    const { data: details } = useGetCreativeDetailsQuery({
        uhash: creative.uhash,
        sampleTime: creative.sampleTime,
    });
    const loudness = useGetCreativeLoudness(details?.loudness);
    const rawResponseUrl = `${conf.mctvDiagnosticsApiRoot}/resources/diagnostics/rawCreativeSamples/${creative.sampleTime}/vast/${creative.reqId}`;

    return { mediaData, details, loudness, rawResponseUrl, parsedFromRawCreative };
};
