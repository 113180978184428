import { FC, useState } from "react";
import { useAppDispatch } from "@app/core/store";
import { useUserAccess } from "@app/core/auth";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ItemType } from "antd/lib/menu/interface";
import { Space, Button, Dropdown, Tooltip } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { downloadTags } from "@app/features/inventory/helpers";
import { AdsTextDrawer } from "@app/features/inventory/AdsTextDrawer";
import { InventoryDetailsDrawerType } from "@app/features/inventory/DetailsDrawer/reducer";
import { MonitorOutlined, TagsOutlined, EditOutlined, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { setChangelogEntity, openChangelog } from "@app/features/inventory/InventoryChangelog";
import {
    BulkOperationSourceEntity,
    ChangeLogEntityType,
    Publisher,
    SeatPublisher,
    useGetChannelQuery,
} from "@app/core/services";
import { CHANNE_AD_UNIT_QUERY_PARAMETER } from "@app/features/inventory/DetailsDrawer/DetailsDrawerContent/UnitContent/LadleContent/useLadleContent";
import { BulkOperationsUrlState } from "@app/core/components";

interface Props {
    publisher: Publisher | SeatPublisher;
    channelsEnabled: boolean;
}

export const InventoryPublishersListTableActions: FC<Props> = ({ publisher, channelsEnabled }) => {
    const isChannel = publisher.type.name === "Channel";
    const { seatId } = useParams<{ seatId: string }>();
    const { hasSeatWriteAccess } = useUserAccess();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [isAdsTextDrawerVisible, setIsAdsTextDrawerVisible] = useState(false);
    const { data: channelData } = useGetChannelQuery(Number(publisher?.id), {
        skip: !isChannel,
    });

    const handleChangelog = (): void => {
        dispatch(openChangelog());
        dispatch(
            setChangelogEntity({
                entity: publisher,
                entityType: isChannel ? ChangeLogEntityType.Channel : ChangeLogEntityType.Publisher,
            })
        );
    };

    const handleAddBrand = (): void =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_CREATE(seatId, publisher.id));

    const handleAddFloor = (): void =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_PUBLISHER(seatId, publisher.id));

    const handleTagParams = (): void => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_TAG_PARAMS(
                false,
                seatId,
                InventoryDetailsDrawerType.PUBLISHER,
                publisher.id
            )
        );
    };

    const handleEditPublisher = (): void => {
        if (isChannel) {
            history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_CHANNEL_EDIT(Number(seatId), publisher.id));
            return;
        }
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_EDIT(Number(seatId), publisher.id));
    };
    const handleCopyPublisher = (): void => {
        if (isChannel) return;
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_COPY(Number(seatId), publisher.id));
    };
    const handleCopyWithChildren = (): void => {
        const routeState: BulkOperationsUrlState = {
            type: "copy",
            entityType: publisher.entityType.toLowerCase() as BulkOperationSourceEntity,
            entityId: String(publisher.id),
            withChildren: true,
        };

        history.push({
            pathname: ROUTE_FORMATTERS.SEAT_CONTROLS_BULK_OPERATIONS_CREATE(Number(seatId)),
            state: routeState,
        });
    };
    const handleAddChannel = (): void => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_CHANNEL_CREATE(Number(seatId), publisher.id)
        );
    };

    const handleCreativeReview = (): void => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_CREATIVE_REVIEW(
                false,
                seatId,
                InventoryDetailsDrawerType.PUBLISHER,
                publisher.id
            )
        );
    };

    const handleDiagnostics = () => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_DIAGNOSTICS(
                false,
                seatId,
                InventoryDetailsDrawerType.PUBLISHER,
                publisher.id
            )
        );
    };

    const floorAnalysis = (): void =>
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_FLOOR_ANALYSIS(
                false,
                seatId,
                InventoryDetailsDrawerType.PUBLISHER,
                publisher.id
            )
        );

    const handleOpenAdsTextDrawer = () => {
        setIsAdsTextDrawerVisible(true);
    };

    const handleCloseAdsTextDrawer = () => {
        setIsAdsTextDrawerVisible(false);
    };

    const handleGetTags = () => {
        downloadTags(seatId);
    };

    const handleLadle = () => {
        history.push({
            pathname: ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_LADLE(
                false,
                seatId,
                InventoryDetailsDrawerType.CHANNEL,
                Number(channelData?.id)
            ) as string,
            //INFO ladle using either channel id or ad unit id
            search: `?${CHANNE_AD_UNIT_QUERY_PARAMETER}=${channelData?.adUnit?.id}`,
        });
    };

    const handlePermissions = (): void => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PERMISSIONS(
                false,
                seatId,
                isChannel ? InventoryDetailsDrawerType.CHANNEL : InventoryDetailsDrawerType.PUBLISHER,
                Number(publisher.id)
            ) as string
        );
    };

    const items: ItemType[] = [
        {
            label: "Creative Review",
            key: "creativeReview",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleCreativeReview();
            },
        },
        {
            label: "Floor Analysis",
            key: "floorAnalysis",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                floorAnalysis();
            },
        },
        hasSeatWriteAccess && {
            label: "Change History",
            key: "changeHistory",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleChangelog();
            },
        },
        hasSeatWriteAccess && {
            label: "Ads.txt Pub ID",
            key: "adsTxtPubId",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleOpenAdsTextDrawer();
            },
        },
        isChannel && {
            label: "Get Tag",
            key: "getTag",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleGetTags();
            },
        },
        isChannel && {
            label: "Ladle",
            key: "ladle",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleLadle();
            },
        },
        hasSeatWriteAccess && {
            label: "Permissions",
            key: "permissions",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handlePermissions();
            },
        },
    ].filter(Boolean) as ItemType[];

    const createActionsItems: ItemType[] = [
        !isChannel &&
            hasSeatWriteAccess && {
                label: "Add a Brand for this Publisher",
                key: "createBrand",
                onClick: (e) => {
                    e.domEvent.stopPropagation();
                    handleAddBrand();
                },
            },
        channelsEnabled &&
            !isChannel &&
            hasSeatWriteAccess && {
                label: "Add a Channel for this Publisher",
                key: "createChannel",
                onClick: (e) => {
                    e.domEvent.stopPropagation();
                    handleAddChannel();
                },
            },
        !isChannel &&
            hasSeatWriteAccess && {
                label: "Copy Publisher Only",
                key: "copyPublisher",
                onClick: (e) => {
                    e.domEvent.stopPropagation();
                    handleCopyPublisher();
                },
            },
        !isChannel &&
            hasSeatWriteAccess && {
                label: "Copy Publisher + Children",
                key: "copyPublisherChildren",
                onClick: (e) => {
                    e.domEvent.stopPropagation();
                    handleCopyWithChildren();
                },
            },
        hasSeatWriteAccess && {
            label: "Add a Floor",
            key: "createFloor",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleAddFloor();
            },
        },
    ].filter(Boolean) as ItemType[];
    return (
        <>
            <Space style={{ width: "100%" }}>
                <Button.Group>
                    <Tooltip title="Inbound Request Params">
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                handleTagParams();
                            }}
                            icon={<TagsOutlined />}
                            size="small"
                        />
                    </Tooltip>
                    <Tooltip title="Diagnostic">
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDiagnostics();
                            }}
                            icon={<MonitorOutlined />}
                            size="small"
                        />
                    </Tooltip>
                    {hasSeatWriteAccess && (
                        <Tooltip title="Edit">
                            <Button
                                icon={<EditOutlined />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleEditPublisher();
                                }}
                                size="small"
                            />
                        </Tooltip>
                    )}
                    {hasSeatWriteAccess && (
                        <Tooltip title="Create">
                            <Dropdown placement="bottomLeft" trigger={["click"]} menu={{ items: createActionsItems }}>
                                <Button
                                    icon={<PlusOutlined rotate={90} />}
                                    size="small"
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </Dropdown>
                        </Tooltip>
                    )}
                    <Dropdown menu={{ items }} placement="bottomLeft" trigger={["click"]}>
                        <Button icon={<MoreOutlined rotate={90} />} size="small" onClick={(e) => e.stopPropagation()} />
                    </Dropdown>
                </Button.Group>
                {isAdsTextDrawerVisible && (
                    <AdsTextDrawer
                        visible={isAdsTextDrawerVisible}
                        publisherId={Number(publisher.id)}
                        handleClose={handleCloseAdsTextDrawer}
                    />
                )}
            </Space>
        </>
    );
};
