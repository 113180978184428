import { FC } from "react";
import { Form, Row, Col } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { NavBar } from "@app/core/components";
import PublisherFilter from "./PublisherFilter";
import DemandTypeFilter from "./DemandTypeFilter";
import { css } from "@emotion/css";

interface HeaderProps {
    seatName: string;
}

const Header: FC<HeaderProps> = ({ seatName }) => {
    return (
        <>
            <NavBar label="Dashboard" />
            <PageHeader
                className={css`
                    .ant-page-header-heading {
                        flex-wrap: wrap;
                    }
                `}
                title={`Dashboard: ${seatName}`}
                extra={
                    <Form colon={false}>
                        <Row gutter={[16, 16]}>
                            <Col>
                                <Form.Item style={{ marginBottom: 0 }} label="Publisher">
                                    <PublisherFilter style={{ minWidth: "15vw" }} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item style={{ marginBottom: 0 }} label="Demand Type">
                                    <DemandTypeFilter style={{ minWidth: "15vw" }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                }
            />
        </>
    );
};

export default Header;
