import { Form } from "antd";
import { RuleObject, StoreValue } from "rc-field-form/lib/interface";
import { MAX_BITRATE } from "@app/features/inventory/HierarchyForms/constants";

const { useWatch, useFormInstance } = Form;

interface UseAdUnitMinBitrate {
    onChange: (value: number) => void;
    validator: (rule: RuleObject, value: StoreValue) => Promise<void>;
}

export const useAdUnitMinBitrate = (): UseAdUnitMinBitrate => {
    const form = useFormInstance();
    const maxBitrate = useWatch(MAX_BITRATE);
    const onChange = (): Promise<void> => form.validateFields([MAX_BITRATE]);
    const validator = (_: RuleObject, value: StoreValue): Promise<void> => {
        if (value === null) return Promise.resolve();
        if (value < 0 || value > 100000)
            return Promise.reject(new Error("Min Bitrate values should be between 0 & 100000"));
        if (!!maxBitrate && value > maxBitrate)
            return Promise.reject(new Error("Min Bitrate can't be greater than Max Bitrate"));
        return Promise.resolve();
    };

    return {
        validator,
        onChange,
    };
};
