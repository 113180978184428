import { useMemo } from "react";
import { CheckboxOptionType } from "antd/lib/checkbox/Group";
import { useGetAdUnitsPlaylistDefinitionModesQuery } from "@app/core/services";

interface UseChannelPlaylistDefinitionMode {
    options: CheckboxOptionType[];
}

export const useChannelPlaylistDefinitionMode = (): UseChannelPlaylistDefinitionMode => {
    const { data } = useGetAdUnitsPlaylistDefinitionModesQuery();

    const options: CheckboxOptionType[] = useMemo(
        () => (data || []).map(({ id, name }) => ({ value: id, label: name })),
        [data]
    );
    return {
        options,
    };
};
