import { consoleApi, NETWORK_ENDPOINTS_AWS_REGIONS_TAG } from "./console";

export interface AwsRegion {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    code: string;
    sort: number;
    active: boolean;
    entityType: "AwsRegion";
}

export const networkEndpointsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getNetworkEndpointsAwsRegions: builder.query<AwsRegion[], void>({
            query: () => "/networkEndpoints/awsRegions",
            providesTags: [NETWORK_ENDPOINTS_AWS_REGIONS_TAG],
        }),
    }),
    overrideExisting: false,
});

export const { useGetNetworkEndpointsAwsRegionsQuery } = networkEndpointsApi;
