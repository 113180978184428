import { Form, Radio } from "antd";
import { useAdSourceUnderInboundFloor } from "./useAdSourceUnderInboundFloor";

export const AdSourceUnderInboundFloor = () => {
    const { onChange, value } = useAdSourceUnderInboundFloor();
    return (
        <Form.Item label="Run Under Inbound Floor" data-sdet="ad-source-rund-under-inbound-floor">
            <Radio.Group onChange={onChange} value={value}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
            </Radio.Group>
        </Form.Item>
    );
};
