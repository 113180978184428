import { LegendDataPoint } from "@app/core/components/charts/TogglableChart/ChartLegend/ChartLegend";
import { HistoricalQueriesResults } from "@app/core/services";

export interface MapByDay extends HistoricalQueriesResults {
    ts: number;
    optimalFloor?: number;
}

export interface ParsedData {
    estPrice: Set<number>;
    mapByDay: MapByDay | Record<string, string | number>;
    mapByPotentialFloor: MapByDay | Record<string, string | number>;
}

export enum GROUP_LINE {
    LINE1 = "line1",
    LINE2 = "line2",
}

export enum GROUP_COLUMN {
    COL1 = "col1",
    COL2 = "col2",
}

export interface LineChartData {
    time: number;
    count: number;
    name: GROUP_LINE;
}

export interface ColumnChartData {
    time: number;
    value: number;
    type: GROUP_COLUMN;
}

export interface LegendDataItem extends LegendDataPoint {
    type: GROUP_LINE | GROUP_COLUMN;
}
