import { Checkbox, Col, Form, InputNumber } from "antd";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useCreativeSampleMinutesOff } from "./useCreativeSampleMinutesOff";

const INPUTS_COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 };

export const CreativeSamplesMinutes = () => {
    const { handleCreativeSamplesMinutesOff, isDisabled } = useCreativeSampleMinutesOff();
    return (
        <>
            <Col {...INPUTS_COL_SIZES}>
                <Form.Item
                    name={AD_UNIT_FORM_FIELDS.CREATIVE_SAMPLES_MINUTE.name}
                    label={AD_UNIT_FORM_FIELDS.CREATIVE_SAMPLES_MINUTE.label}
                >
                    <InputNumber
                        disabled={isDisabled}
                        placeholder={AD_UNIT_FORM_FIELDS.CREATIVE_SAMPLES_MINUTE.placeholder}
                        style={{ width: "100%" }}
                    />
                </Form.Item>
            </Col>

            <Col {...INPUTS_COL_SIZES}>
                <Form.Item label=" ">
                    <Checkbox onChange={(e) => handleCreativeSamplesMinutesOff(e)}>Off</Checkbox>
                </Form.Item>
            </Col>
        </>
    );
};
