import { useAppDispatch, useAppSelector } from "@app/core/store";
import { DEAL_DETAILS_DRAWER_LOCATION, DealDetailsDrawerTitles } from "@app/features/deals/constants";
import { DealDetailsDrawerHeader } from "@app/features/deals/DealDetailsDrawer/DealDetailsDrawerHeader/DealDetailsDrawerHeaderTitleSwitch";
import {
    selectDealRequestedId,
    selectSecondaryDetailsView,
    selectSecondaryDrawerHeader,
    switchToDefaultView,
    switchToNestedView,
} from "./reducer";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { convertSecondaryViewToURL } from "./convertSecondaryView";

interface UseDealDetailsDrawerContent {
    requestedDealId: number | null;
    secondaryDrawerHeader: DealDetailsDrawerHeader | null;
    secondaryDetailsView: DealDetailsDrawerTitles | null;
    switchToNestedDetailsView: (secondaryDrawerView: DealDetailsDrawerTitles) => void;
    switchToDefaultDetailsView: () => void;
}

export const useDealDetailsDrawerContent = (): UseDealDetailsDrawerContent => {
    const dispatch = useAppDispatch();
    const requestedDealId = useAppSelector(selectDealRequestedId);
    const secondaryDrawerHeader = useAppSelector(selectSecondaryDrawerHeader);
    const secondaryDetailsView = useAppSelector(selectSecondaryDetailsView);

    const location = useLocation();
    const history = useHistory();
    const { dealId, seatId } = useParams<{ seatId: string; dealId: string }>();

    const switchToNestedDetailsView = (secondaryDetails: DealDetailsDrawerTitles) => {
        dispatch(switchToNestedView(secondaryDetails));
        if (location.pathname.includes(DEAL_DETAILS_DRAWER_LOCATION.DEALS_HEALTH)) {
            history.push(
                ROUTE_FORMATTERS.SEAT_DEAL_HEALTH_DEAL_DETAILS_DRAWER_SECONDARY_VIEW(
                    seatId,
                    dealId,
                    convertSecondaryViewToURL(secondaryDetails)
                )
            );
            return;
        }
        if (location.pathname.includes(DEAL_DETAILS_DRAWER_LOCATION.DEALS)) {
            if (
                location.pathname.includes(DEAL_DETAILS_DRAWER_LOCATION.EDIT) ||
                location.pathname.includes(DEAL_DETAILS_DRAWER_LOCATION.DEALS_DETAILS)
            ) {
                return;
            }
            history.push(
                ROUTE_FORMATTERS.SEAT_DEALS_DEAL_DETAILS_DRAWER_SECONDARY_VIEW(
                    seatId,
                    dealId,
                    convertSecondaryViewToURL(secondaryDetails)
                )
            );
            return;
        }
    };

    const switchToDefaultDetailsView = () => {
        dispatch(switchToDefaultView());
        if (location.pathname.includes(DEAL_DETAILS_DRAWER_LOCATION.DEALS_HEALTH)) {
            history.push(ROUTE_FORMATTERS.SEAT_DEAL_HEALTH_DEAL_DETAILS_DRAWER(seatId, dealId));
            return;
        }
        if (location.pathname.includes(DEAL_DETAILS_DRAWER_LOCATION.DEALS_DETAILS)) {
            return;
        }
        if (location.pathname.includes(DEAL_DETAILS_DRAWER_LOCATION.DEALS)) {
            history.push(ROUTE_FORMATTERS.SEAT_DEALS_DEAL_DETAILS_DRAWER(seatId, dealId));
            return;
        }
    };

    return {
        requestedDealId,
        secondaryDetailsView,
        secondaryDrawerHeader,
        switchToDefaultDetailsView,
        switchToNestedDetailsView,
    };
};
