import { FC } from "react";
import { Form, Input, Typography } from "antd";
import { CREATE_BRAND_SAFETY_FORM_ITEMS_NAME } from "@app/features/inventory/InventoryBrandSafety/InventoryAdvancedBrandSafetyPage/constants";
import { gray7 } from "@rubicon/antd-components";

interface InventoryDomainListProps {
    isSubmitting: boolean;
}

export const AdditionalAdvertiserDomainsField: FC<InventoryDomainListProps> = ({ isSubmitting }) => {
    return (
        <Form.Item>
            <Form.Item
                label="Additional Advertiser Domains"
                name={CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.ADD_ADVERTISER_DOMAINS}
                style={{ marginBottom: 0 }}
            >
                <Input.TextArea disabled={isSubmitting} allowClear rows={10} />
            </Form.Item>
            <Typography.Text style={{ fontWeight: 400, color: gray7 }}>Enter one domain per line</Typography.Text>
        </Form.Item>
    );
};
