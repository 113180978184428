import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME, FREQUENCY_CAPPING_OFF_ID } from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { useGetFreqCappingTypesQuery } from "@app/core/services";

interface UseDealFrequencyCappingField {
    options: LabeledValue[];
    isFetching: boolean;
    value: number | null;
    handleSelect: (value: number) => void;
}

export const useDealFrequencyCappingField = (): UseDealFrequencyCappingField => {
    const dispatch = useAppDispatch();
    const { data, isFetching } = useGetFreqCappingTypesQuery();

    const options = useMemo(() => (data || []).map(({ id, name }) => ({ label: name, value: id })), [data]);

    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.FREQUENCY_CAPPING]);

    const handleSelect = (value: number): void => {
        dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.FREQUENCY_CAPPING, value }));
        if (value === FREQUENCY_CAPPING_OFF_ID) {
            // Clear frequency capping settings when switched off
            dispatch(
                dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.DEAL_FREQUENCY_CAPPINGS, value: [] })
            );
        }
    };

    return {
        options,
        isFetching,
        value,
        handleSelect,
    };
};
