import { useAdSourceEndDateField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceDeliveryDetailsSection/Fields/AdSourceEndDateField/useAdSourceEndDateField";
import { ReadOnlyField } from "@app/core/components/Fields/ReadOnlyField";

export const AdSourceEndDateField = () => {
    const { value, required } = useAdSourceEndDateField();
    return (
        <ReadOnlyField
            dataSdet="ad-source-end-date-field"
            label="End Date"
            required={required}
            message={required ? "End Date is required" : undefined}
        >
            {value}
        </ReadOnlyField>
    );
};
