import { FC } from "react";
import { Button, Space, Tooltip } from "antd";
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";

interface Props {
    onClickCopy: () => void;
    onClickDelete: () => void;
}

export const CreateTargetingBlockActions: FC<Props> = ({ onClickCopy, onClickDelete }) => {
    return (
        <Space>
            <Tooltip title="Copy Targeting Group">
                <Button
                    onClick={(e) => {
                        e.stopPropagation();
                        onClickCopy();
                    }}
                    icon={<CopyOutlined />}
                />
            </Tooltip>
            <Tooltip title="Delete Targeting Group">
                <Button
                    onClick={(e) => {
                        e.stopPropagation();
                        onClickDelete();
                    }}
                    icon={<DeleteOutlined />}
                />
            </Tooltip>
        </Space>
    );
};
