import { numberNormalize } from "@app/core/components/helpers";
import { Form, Input, Space, Typography } from "antd";
import { FC } from "react";

export const MinMaxDurationFilter: FC = () => (
    <Space>
        <Form.Item label="Min Duration" name={["filters", "minDuration"]} normalize={numberNormalize}>
            <Input data-sdet="min-duration-input" suffix={<Typography.Text>seconds</Typography.Text>} />
        </Form.Item>
        <Form.Item label="Max Duration" name={["filters", "maxDuration"]} normalize={numberNormalize}>
            <Input data-sdet="max-duration-input" suffix={<Typography.Text>seconds</Typography.Text>} />
        </Form.Item>
    </Space>
);
