import { Select } from "antd";
import { FC } from "react";
import { Price } from "@app/features/inventory/InventorySeat/SeatDefaultPricesPage/DefaultPricesTable/useDefaultPricesForm";
import { usePricingModelSelect } from "@app/features/inventory/InventorySeat/SeatDefaultPricesPage/PricingModelSelect/usePricingModelSelect";

interface PricingModelSelectProps {
    price: Price;
    onChange: (value: number) => void;
}

export const PricingModelSelect: FC<PricingModelSelectProps> = ({ onChange, price }) => {
    const { isFetching, options, filterOption } = usePricingModelSelect();
    return (
        <Select
            value={price?.costModel}
            filterOption={filterOption}
            loading={isFetching}
            options={options}
            onChange={onChange}
            showSearch
            style={{ minWidth: "70%" }}
        />
    );
};
