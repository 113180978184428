import { Layout, Flex } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { Footer } from "@app/core/components";
import DealTypeCard, { DealTypeCardProps } from "@app/features/deals/DealCreatePage/DealTypeCard";
import { DealDemandIcon } from "./DealDemandIcon";
import { LegacyDealsIcon } from "./LegacyDealsIcon";
import { useHistory } from "react-router-dom";
import { FC } from "react";

export const DEAL_DEMAND = "demand";

// TODO: Add route path to "to" property
const dealTypes: DealTypeCardProps[] = [
    {
        image: <DealDemandIcon />,
        title: "Demand Deal",
        description:
            "Create deals for any DSP currently integrated on Magnite Streaming Platform. Deals will be automatically synced for eligible buyers’ DSPs.",
        to: `create/${DEAL_DEMAND}`,
    },
    {
        image: <LegacyDealsIcon />,
        title: "Legacy Deals",
        description:
            "Allows you to establish specific deals for specific supply with buyers, set floors, and support dynamic priced bidding",
        to: "",
        disabled: true,
    },
];

export const DealCreatePage: FC = () => {
    const history = useHistory();

    return (
        <>
            <PageHeader title="Create Deal" onBack={() => history.goBack()} />
            <Layout.Content>
                <Flex gap="middle">
                    {dealTypes.map((deal) => (
                        <DealTypeCard
                            key={deal.title}
                            title={deal.title}
                            description={deal.description}
                            image={deal.image}
                            disabled={deal.disabled}
                            to={deal.to}
                        />
                    ))}
                </Flex>
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
