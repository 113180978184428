import { FC } from "react";
import { Seat } from "@app/core/services";
import { useParams } from "react-router-dom";
import { LabeledValue } from "antd/lib/select";
import { useSeatAuthContext } from "@app/core/auth";
import { Button, Table, Space, Row, Col } from "antd";
import {
    COLUMN_KEY_PRICE,
    COLUMN_KEY_SOURCE,
    COLUMN_KEY_ACTIONS,
    COLUMN_KEY_COUNTRY,
    FLOORS_TABLE_COLUMNS,
    COLUMN_KEY_INVENTORY_NAME,
} from "@app/features/inventory/InventorySeat/constants";
import {
    FloorTablePrice,
    FloorTableCountry,
    FloorTableActions,
} from "@app/features/inventory/components/FloorsTable/FloorTableRows";
import { FloorValue } from "@app/features/inventory/components/FloorsTable";
import { useFloorsTable } from "@app/features/inventory/components/FloorsTable/useFloorsTable";

interface SeatFloorsTableItems {
    sourceSelf: "Seat";
    context: Seat | null;
    floors: FloorValue[];
    handleDeleteNew: () => void;
    savingIds: Set<number | null>;
    editingIds: Set<number | null>;
    deletingIds: Set<number | null>;
    handleEdit: (id: number) => void;
    handleDelete: (id: number) => void;
    handleEditCancel: (id: number) => void;
    handleSave: (id: number | null) => void;
    handleChangePrice: (id: number | null, event: number) => void;
    handleChangeCountry: (id: number | null, value: LabeledValue | null) => void;
}

const getSeatFloorsTableItems = ({
    floors,
    context,
    sourceSelf,
    editingIds,
    handleChangePrice,
    handleChangeCountry,
    ...rest
}: SeatFloorsTableItems) =>
    floors.map((record: FloorValue) => ({
        key: record?.id,
        [COLUMN_KEY_INVENTORY_NAME]: context?.name,
        [COLUMN_KEY_SOURCE]: "Seat (Self)",
        [COLUMN_KEY_COUNTRY]: (
            <FloorTableCountry record={record} editingIds={editingIds} handleChangeCountry={handleChangeCountry} />
        ),
        [COLUMN_KEY_PRICE]: (
            <FloorTablePrice record={record} editingIds={editingIds} handleChangePrice={handleChangePrice} />
        ),
        [COLUMN_KEY_ACTIONS]: (
            <FloorTableActions record={record} sourceSelf={sourceSelf} editingIds={editingIds} {...rest} />
        ),
    }));
export const SeatFloorsTable: FC = () => {
    const sourceSelf = "Seat";
    const { context } = useSeatAuthContext();
    const { seatId } = useParams<{ seatId: string }>();
    const { handleAdd, isLoading, isCreateMode, hasSeatWriteAccess, ...rest } = useFloorsTable({
        sourceSelf,
        id: Number(seatId),
    });

    return (
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Row gutter={[0, 24]} justify="end">
                {hasSeatWriteAccess && (
                    <Col>
                        <Button type="primary" disabled={isCreateMode} onClick={handleAdd}>
                            Add Floor
                        </Button>
                    </Col>
                )}
                <Col span={24}>
                    <Table
                        size="small"
                        pagination={false}
                        dataSource={getSeatFloorsTableItems({ context, sourceSelf, ...rest })}
                        loading={isLoading}
                        scroll={{ x: 1000 }}
                        showSorterTooltip={false}
                        columns={FLOORS_TABLE_COLUMNS}
                    />
                </Col>
            </Row>
        </Space>
    );
};
