import { FC } from "react";
import { ColProps } from "antd/lib/grid/col";
import { CodeFormItem } from "@app/features/inventory/components/FormItems";
import { BRAND_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useBrandFormInstance } from "@app/features/inventory/HierarchyForms/BrandForm/BrandFormInstance";

interface CodeProps {
    wrapperCol?: ColProps;
}

export const Code: FC<CodeProps> = ({ wrapperCol }) => {
    const { mode, seat, useWatch } = useBrandFormInstance();
    return (
        <CodeFormItem
            mode={mode}
            seat={seat}
            useWatch={useWatch}
            wrapperCol={wrapperCol}
            name={BRAND_FORM_FIELDS.CODE.name}
            label={BRAND_FORM_FIELDS.CODE.label}
            placeholder={BRAND_FORM_FIELDS.CODE.placeholder}
            dataSdet="brand-code"
        />
    );
};
