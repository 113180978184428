import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { Form, Radio } from "antd";

export const SupplyNameRadio = () => {
    return (
        <Form.Item
            name={AD_SOURCE_FIELDS.SUPPLY_NAME.name}
            label={AD_SOURCE_FIELDS.SUPPLY_NAME.label}
            labelCol={{ lg: 8 }}
            labelAlign="left"
            colon={false}
            style={{ marginBottom: 8 }}
        >
            <Radio.Group>
                <Radio value={true}>Shared</Radio>
                <Radio value={false}>Hidden</Radio>
            </Radio.Group>
        </Form.Item>
    );
};
