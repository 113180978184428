import { OTHER_APP_STORE_ID, SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useSupplyFormInstance } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormInstance";

interface UseAppName {
    disabled: boolean;
}

export const useAppName = (): UseAppName => {
    const { useWatch } = useSupplyFormInstance();
    const appStore = useWatch(SUPPLY_FORM_FIELDS.APP_STORE.name);
    const appStoreId: number = appStore?.value;
    const disabled: boolean = appStoreId !== OTHER_APP_STORE_ID;
    return { disabled };
};
