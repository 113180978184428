import { FC, useState } from "react";
import { LabeledValue } from "antd/lib/select";
import {
    Conditions,
    INVENTORY_CATEGORIES,
    InventoryCategoryTypes,
    InventoryLabelValue,
    TargetingDimensionTypeLabels,
} from "../../../constants";
import { InventoryDimension, UsedDimensions } from "../../../types";
import { ConditionSelect } from "../../ConditionSelect";
import { MoreMenu } from "../../MoreMenu";
import { DimensionRow } from "../DimensionRow";
import { BulkInventoryDrawerButton } from "./BulkInventoryDrawerButton";
import { DimensionsSelect } from "./DimensionsSelect/DimensionsSelect";
import { SearchCategorySelect } from "./SearchCategorySelect";

const inventoryCategoryToLabelValue = (value: InventoryCategoryTypes): LabeledValue =>
    Object.values(INVENTORY_CATEGORIES).filter((item) => item.value === value)[0];

interface Props {
    dimension: InventoryDimension;
    onChangeCondition: (value: Conditions) => void;
    onChangeDimensionValues: (value: InventoryLabelValue[]) => void;
    onClickDelete: () => void;
    onClickDownload: () => void;
    onClickDuplicate: () => void;
    usedDimensions: UsedDimensions;
}

export const InventoryDimensionRow: FC<Props> = ({
    dimension,
    onChangeCondition,
    onChangeDimensionValues,
    onClickDelete,
    onClickDownload,
    onClickDuplicate,
    usedDimensions,
}) => {
    const [category, setCategory] = useState<InventoryCategoryTypes>(INVENTORY_CATEGORIES.all.value);

    const handleChangeCategory = (newCategory: InventoryCategoryTypes) => {
        onChangeDimensionValues([]);
        setCategory(newCategory);
    };

    return (
        <DimensionRow
            label={TargetingDimensionTypeLabels.Inventory}
            condition={
                <ConditionSelect
                    onChange={onChangeCondition}
                    isExcludesDisabled={
                        dimension.condition !== Conditions.Excludes && usedDimensions.inventory.excludes
                    }
                    isIncludesDisabled={
                        dimension.condition !== Conditions.Includes && usedDimensions.inventory.includes
                    }
                    value={dimension.condition}
                />
            }
            mode={
                <SearchCategorySelect value={inventoryCategoryToLabelValue(category)} onChange={handleChangeCategory} />
            }
            value={<DimensionsSelect onChange={onChangeDimensionValues} value={dimension.values} category={category} />}
            menu={
                <>
                    <BulkInventoryDrawerButton onChange={onChangeDimensionValues} values={dimension.values} />
                    <MoreMenu
                        onClickDelete={onClickDelete}
                        onClickDownload={onClickDownload}
                        onClickDuplicate={onClickDuplicate}
                        isDuplicateDisabled={usedDimensions.inventory.includes && usedDimensions.inventory.excludes}
                    />
                </>
            }
        />
    );
};
