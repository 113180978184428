import { useNotificationError, usePagination, useTableChanges } from "@app/core/components/hooks";
import { useGetSequesteredDomainsQuery } from "@app/core/services";
import { useLocation, useParams } from "react-router-dom";
import { getUISearch } from "./helpers";

export const useSequesteredDomains = () => {
    const { search } = useLocation();
    const { seatId } = useParams<{ seatId: string }>();

    const { data, isLoading, isFetching, error, refetch } = useGetSequesteredDomainsQuery(seatId);
    useNotificationError(error);
    const advertiserDomains = data?.sequesteredDomains?.advertiserDomains;
    const { handleChange, paginationConfig } = usePagination({
        totalCount: (advertiserDomains && Object.keys(getUISearch(advertiserDomains, search)).length + 1) || 0,
        showTotal: false,
    });
    const { onChange: handleTableChange } = useTableChanges({
        handlePaginateChange: handleChange,
    });
    return {
        isLoading: isLoading || isFetching,
        data,
        handleRefresh: refetch,
        handleTableChange,
        paginationConfig,
        seatId,
        search,
    };
};
