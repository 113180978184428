import { FC } from "react";

export const LegacyDealsIcon: FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="142.5" height="65" viewBox="0 0 142.5 65">
        <g id="Img_Old_Deals" data-name="Img_Old Deals" transform="translate(-445 115.497)">
            <g id="Img_PG_AdServerControlled" transform="translate(440.664 -125.912)">
                <path
                    id="Path_170"
                    data-name="Path 170"
                    d="M114.062,25.14a7.114,7.114,0,0,1-7.113,7.113H29.035a7.114,7.114,0,0,1-7.113-7.113V18.028a7.114,7.114,0,0,1,7.113-7.113h77.915a7.114,7.114,0,0,1,7.113,7.113Z"
                    transform="translate(32.274)"
                    fill="none"
                    stroke="#8c8c8c"
                    strokeMiterlimit="5.833"
                    strokeWidth="1"
                />
                <path
                    id="Path_171"
                    data-name="Path 171"
                    d="M28.142,14.581a.888.888,0,1,0,.891.888.888.888,0,0,0-.891-.888h0"
                    transform="translate(41.167 6.114)"
                    fill="none"
                    stroke="#8c8c8c"
                    strokeMiterlimit="5.833"
                    strokeWidth="1"
                />
                <path
                    id="Path_172"
                    data-name="Path 172"
                    d="M32.81,14.581a.888.888,0,1,0,.888.888.89.89,0,0,0-.888-.888h0"
                    transform="translate(48.948 6.114)"
                    fill="none"
                    stroke="#8c8c8c"
                    strokeMiterlimit="5.833"
                    strokeWidth="1"
                />
                <path
                    id="Path_173"
                    data-name="Path 173"
                    d="M114.062,41.135a7.112,7.112,0,0,1-7.113,7.113H29.035a7.112,7.112,0,0,1-7.113-7.113V34.023a7.114,7.114,0,0,1,7.113-7.113h77.915a7.114,7.114,0,0,1,7.113,7.113Z"
                    transform="translate(32.274 26.667)"
                    fill="none"
                    stroke="#8c8c8c"
                    strokeMiterlimit="5.833"
                    strokeWidth="1"
                />
                <path
                    id="Path_174"
                    data-name="Path 174"
                    d="M28.142,30.576a.89.89,0,1,0,.891.888.888.888,0,0,0-.891-.888h0"
                    transform="translate(41.167 32.782)"
                    fill="none"
                    stroke="#8c8c8c"
                    strokeMiterlimit="5.833"
                    strokeWidth="1"
                />
                <path
                    id="Path_175"
                    data-name="Path 175"
                    d="M32.81,30.576a.89.89,0,1,0,.888.888.89.89,0,0,0-.888-.888h0"
                    transform="translate(48.948 32.782)"
                    fill="none"
                    stroke="#8c8c8c"
                    strokeMiterlimit="5.833"
                    strokeWidth="1"
                />
                <path
                    id="Path_176"
                    data-name="Path 176"
                    d="M114.062,33.138a7.112,7.112,0,0,1-7.113,7.11H29.035a7.112,7.112,0,0,1-7.113-7.11V26.026a7.112,7.112,0,0,1,7.113-7.113h77.915a7.112,7.112,0,0,1,7.113,7.113Z"
                    transform="translate(32.274 13.33)"
                    fill="none"
                    stroke="#8c8c8c"
                    strokeMiterlimit="5.833"
                    strokeWidth="1"
                />
                <path
                    id="Path_177"
                    data-name="Path 177"
                    d="M28.142,22.578a.89.89,0,1,0,.891.891.89.89,0,0,0-.891-.891h0"
                    transform="translate(41.167 19.442)"
                    fill="none"
                    stroke="#8c8c8c"
                    strokeMiterlimit="5.833"
                    strokeWidth="1"
                />
                <path
                    id="Path_178"
                    data-name="Path 178"
                    d="M32.81,22.578a.89.89,0,1,0,.888.891.893.893,0,0,0-.888-.891h0"
                    transform="translate(48.948 19.442)"
                    fill="none"
                    stroke="#8c8c8c"
                    strokeMiterlimit="5.833"
                    strokeWidth="1"
                />
            </g>
            <g id="Group_2637" data-name="Group 2637">
                <g
                    id="Ellipse_154"
                    data-name="Ellipse 154"
                    transform="translate(445 -105)"
                    fill="none"
                    stroke="#8c8c8c"
                    strokeWidth="1"
                >
                    <circle cx="7" cy="7" r="7" stroke="none" />
                    <circle cx="7" cy="7" r="6.5" fill="none" />
                </g>
                <g
                    id="Ellipse_155"
                    data-name="Ellipse 155"
                    transform="translate(469 -105)"
                    fill="none"
                    stroke="#8c8c8c"
                    strokeWidth="1"
                >
                    <circle cx="7" cy="7" r="7" stroke="none" />
                    <circle cx="7" cy="7" r="6.5" fill="none" />
                </g>
                <g
                    id="Ellipse_156"
                    data-name="Ellipse 156"
                    transform="translate(457 -74)"
                    fill="none"
                    stroke="#8c8c8c"
                    strokeWidth="1"
                >
                    <circle cx="7" cy="7" r="7" stroke="none" />
                    <circle cx="7" cy="7" r="6.5" fill="none" />
                </g>
                <path
                    id="Path_196"
                    data-name="Path 196"
                    d="M451.5-92v6.553l12,6.632V-73"
                    transform="translate(0.5)"
                    fill="none"
                    stroke="#8c8c8c"
                    strokeWidth="1"
                />
                <path
                    id="Path_197"
                    data-name="Path 197"
                    d="M463.5-92v6.553l-12,6.632"
                    transform="translate(12.5)"
                    fill="none"
                    stroke="#8c8c8c"
                    strokeWidth="1"
                />
            </g>
        </g>
    </svg>
);
