import { useParams } from "react-router-dom";

export const useFloorSource = () => {
    const { seatId, publisherId, brandId, supplyId, adUnitId } = useParams<{
        seatId?: string;
        publisherId?: string;
        brandId?: string;
        supplyId?: string;
        adUnitId?: string;
    }>();

    if (adUnitId) {
        return {
            adUnit: { id: Number(adUnitId) },
        };
    }
    if (supplyId) {
        return {
            supply: { id: Number(supplyId) },
        };
    }
    if (brandId) {
        return {
            brand: { id: Number(brandId) },
        };
    }
    if (publisherId) {
        return {
            publisher: { id: Number(publisherId), entityType: "publisher" },
        };
    }
    return { seat: { id: Number(seatId) } };
};
