import { MomentRange } from "@app/core/utils/types";
import { AdServingStat } from "./types";
import moment from "moment-timezone";

export const filterAdServingStatsByNtime = (
    stats: AdServingStat[] = [],
    momentRange?: MomentRange
): AdServingStat[] => {
    return stats?.filter((stat) => !momentRange || moment(stat.ntime).isBetween(momentRange.start, momentRange.end));
};
