import { RootState } from "@app/core/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CURRENCIES_BY_CODE } from "@app/core/utils/currencies";
import { CurrencyConversionModes } from "@app/core/clients/console";
import { USD_OPTION_ID } from "../../CurrencyFilters/PreferredCurrencyField";
import { InventoryHealthSearchFilter } from "@app/features/inventory/InventoryHealth/InventoryHealthPage/types";
import { AdUnit, Brand, BrandSupply, Seat, SeatPublisher } from "@app/core/services";

export interface InventorySettings {
    inventoryCurrencyConversionMode: CurrencyConversionModes;
    inventoryPreferredCurrency: number;
}

export interface InventoryHealthState {
    searchFilter: InventoryHealthSearchFilter;
    liveStats: boolean;
    settings: {
        shouldUpdate: boolean;
        values: InventorySettings;
    };
    expandedRowKeys: {
        seatId: string | null;
        publisherId: string | null;
        brandId: string | null;
        supplyId: string | null;
    };
    selectedRow: {
        entity: Seat | SeatPublisher | Brand | BrandSupply | AdUnit | null;
        key: string | null;
        shouldScrollTo: boolean;
        isLoading: boolean;
    };
}

const initialState: InventoryHealthState = {
    liveStats: false,
    searchFilter: { keyword: "" },
    settings: {
        shouldUpdate: false,
        values: {
            inventoryCurrencyConversionMode: CurrencyConversionModes.CONVERT,
            inventoryPreferredCurrency: USD_OPTION_ID,
        },
    },
    expandedRowKeys: {
        seatId: null,
        publisherId: null,
        brandId: null,
        supplyId: null,
    },
    selectedRow: {
        entity: null,
        key: null,
        shouldScrollTo: false,
        isLoading: false,
    },
};

const inventoryHealthSlice = createSlice({
    name: "inventoryHealth",
    initialState,
    reducers: {
        setInventoryHealthLPublishersTableSearch: (state, action: PayloadAction<InventoryHealthSearchFilter>) => {
            state.searchFilter = action.payload;
        },
        setSettingsCurrencyConversionMode: (state, action: PayloadAction<CurrencyConversionModes>) => {
            state.settings.values.inventoryCurrencyConversionMode = action.payload;
            state.settings.shouldUpdate = true;
        },
        setSettingsPreferredCurrency: (state, action: PayloadAction<number>) => {
            state.settings.values.inventoryPreferredCurrency = action.payload;
            state.settings.shouldUpdate = true;
        },
        setInitialSettings: (state, action: PayloadAction<Partial<InventorySettings>>) => {
            const { inventoryPreferredCurrency, ...rest } = action.payload;
            state.settings.values = {
                ...state.settings.values,
                ...rest,
            };
            if (inventoryPreferredCurrency) {
                if (typeof inventoryPreferredCurrency === "number") {
                    state.settings.values.inventoryPreferredCurrency = inventoryPreferredCurrency;
                } else {
                    const currency = CURRENCIES_BY_CODE[inventoryPreferredCurrency];
                    if (currency) {
                        state.settings.values.inventoryPreferredCurrency = currency.id;
                    }
                }
            }
            state.settings.shouldUpdate = false;
        },
        setExpandedRowKeys: (state, action: PayloadAction<Partial<InventoryHealthState["expandedRowKeys"]>>) => {
            state.expandedRowKeys = {
                ...initialState.expandedRowKeys,
                ...action.payload,
            };
        },
        setSelectedRowKey: (state, action: PayloadAction<InventoryHealthState["selectedRow"]>) => {
            state.selectedRow = action.payload;
        },
        setSelectedRowKeyIsLoading: (state, action: PayloadAction<boolean>) => {
            state.selectedRow.isLoading = action.payload;
        },
        setSelectedRowAndExpandedRowKeys: (
            state,
            action: PayloadAction<{
                selectedRow: InventoryHealthState["selectedRow"];
                expandedRowKeys: InventoryHealthState["expandedRowKeys"];
            }>
        ) => {
            state.selectedRow = action.payload.selectedRow;
            state.expandedRowKeys = {
                ...initialState.expandedRowKeys,
                ...action.payload.expandedRowKeys,
            };
        },
    },
});

export const selectInventoryHealthSearchFilter = (state: RootState) => state.inventoryHealth.searchFilter;
export const selectInventoryHealthCurrencyConversionMode = (state: RootState) =>
    state.inventoryHealth.settings.values.inventoryCurrencyConversionMode;
export const selectInventoryHealthExpandedRowKeys = (state: RootState) => state.inventoryHealth.expandedRowKeys;
export const selectInventoryHealthSelectedRow = (state: RootState) => state.inventoryHealth.selectedRow;

export const {
    setExpandedRowKeys,
    setInitialSettings,
    setInventoryHealthLPublishersTableSearch,
    setSelectedRowAndExpandedRowKeys,
    setSelectedRowKey,
    setSelectedRowKeyIsLoading,
    setSettingsCurrencyConversionMode,
    setSettingsPreferredCurrency,
} = inventoryHealthSlice.actions;
export default inventoryHealthSlice.reducer;
