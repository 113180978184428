import { Button, Row, Col, Input, Select, Typography, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useAddFrequencyCappingItem } from "@app/features/deals/DealForm/DealDemandFormSections/DealDemandConfigurationSection/Fields/AddFrequencyCappingItem/useAddFrequencyCappingItem";
import {
    FCAP_MODES_OPTIONS,
    FCAP_TIME_OPTIONS,
} from "@app/features/seatAdSources/SeatAdSourcesForm/DealFormDrawer/DealFormContent/DealFormSections/DemandConfigurationSection/Fields/FrequencyCapping/constants";

export const AddFrequencyCappingItem = () => {
    const {
        mode,
        disabled,
        totalValue,
        timingValue,
        minutesValue,
        handleAddItem,
        hanldeSetMode,
        handleSetTotal,
        handleSetTiming,
        handleSetMinutes,
    } = useAddFrequencyCappingItem();
    return (
        <Row gutter={[24, 24]} style={{ marginBottom: "1.5rem" }} align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={8}>
                <Space.Compact style={{ display: "flex" }}>
                    <Input
                        data-sdet="frequency-capping-total-field"
                        placeholder="Total..."
                        value={totalValue}
                        onChange={handleSetTotal}
                    />
                    <Select
                        data-sdet="frequency-capping-mode-field"
                        style={{ width: "100%" }}
                        onChange={hanldeSetMode}
                        value={mode}
                        options={FCAP_MODES_OPTIONS}
                    />
                </Space.Compact>
            </Col>
            <Col>
                <Typography.Text>Per</Typography.Text>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={8}>
                <Space.Compact style={{ display: "flex" }}>
                    <Input
                        data-sdet="frequency-capping-time-duration-field"
                        placeholder="Time Duration..."
                        value={minutesValue}
                        onChange={handleSetMinutes}
                    />
                    <Select
                        data-sdet="frequency-capping-timing-value-field"
                        style={{ width: "100%" }}
                        value={timingValue}
                        onChange={handleSetTiming}
                        options={FCAP_TIME_OPTIONS}
                    />
                </Space.Compact>
            </Col>
            <Col>
                <Button
                    type="link"
                    disabled={disabled}
                    onClick={handleAddItem}
                    block
                    icon={<PlusOutlined />}
                    style={{ paddingLeft: 0 }}
                >
                    Add
                </Button>
            </Col>
        </Row>
    );
};
