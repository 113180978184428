import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "@app/core/store";
import { CostModels } from "@app/features/seatAdSources/constants";
import { useGetSeatFinanceDefaultsQuery } from "@app/core/services";
import { useDispatchOnBlur } from "@app/features/deals/DealForm/hooks";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormInputFieldChange } from "@app/features/deals/DealForm/reducer";
import { DEFAULT_AD_SOURCE_COST_MODEL_ID } from "@app/features/deals/DealForm/useDealForm";
import { getDefaultCostModel } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/getDefaultCostModel";

interface UseAdSourceCostField {
    defaultValue: number | null;
    label: string;
    onChange: (value: number) => void;
    prefix: string;
    value: number | null;
    handleBlur: () => void;
}

export const useAdSourceCostField = (): UseAdSourceCostField => {
    const { seatId } = useParams<{ seatId: string }>();
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_COST]);
    const dealType = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TYPE]);
    const costModel = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_COST_MODEL]
    );
    const { onChange, onBlur } = useDispatchOnBlur(value);
    const { data: financeDefaults } = useGetSeatFinanceDefaultsQuery(Number(seatId));

    const defaultCostModel = getDefaultCostModel(dealType, financeDefaults);
    const defaultValue =
        defaultCostModel?.costModel.id === CostModels.FIXED_CPM
            ? (defaultCostModel.costValueFixed || 0) / 1000
            : defaultCostModel?.costValuePercent;

    const label = useMemo(
        () => `${costModel === DEFAULT_AD_SOURCE_COST_MODEL_ID ? "Enter Cost Value Percent" : "Enter Cost Value "}`,
        [costModel]
    );

    const handleBlur = () => {
        onBlur(dealFormInputFieldChange, CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_COST);
    };
    return {
        handleBlur,
        defaultValue: defaultValue || null,
        label,
        onChange,
        prefix: costModel === CostModels.REVSHARE_PERCENTAGE ? "%" : "",
        value,
    };
};
