import { FC } from "react";
import { Form, Collapse } from "antd";
import { Loading, Title } from "@app/core/components";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import { AffixFormButtonsBar } from "@app/features/inventory/components/AffixFormButtonsBar";
import { INVENTORY_HEADER_COLLAPSE_CLASS_LIST } from "@app/features/inventory/InventorySeat/constants";
import { InternalUseOnlyInfo } from "@app/features/inventory/InventorySeat/InventorySeatPage/InternalUseOnlyInfo";
import {
    QualityControlSection,
    InventoryLimitsSection,
} from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm/ExchangeProtectionsFormSections";
import { useExchangeProtectionsForm } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm/useExchangeProtectionsForm";
import { useExchangeProtectionsDefenitionsFields } from "@app/features/inventory/InventorySeat/ExchangeProtectionsPage/useExchangeProtectionsDefenitionsFields";

export const ExchangeProtectionsForm: FC = () => {
    const { form, initialValues, seat, handleCancel, handleSubmit, isUpdating, isFetching } =
        useExchangeProtectionsForm();
    const { limitsDefinitionsFields, controlDefinitionsFields } = useExchangeProtectionsDefenitionsFields();

    if (!seat || isFetching) return <Loading />;

    return (
        <>
            <InternalUseOnlyInfo />
            <Form
                form={form}
                layout="vertical"
                initialValues={initialValues}
                name="exchangeProtectionsForm"
                scrollToFirstError={{ behavior: "smooth", block: "center" }}
            >
                <Collapse
                    bordered
                    ghost={false}
                    className={INVENTORY_HEADER_COLLAPSE_CLASS_LIST}
                    defaultActiveKey={["0", "1"]}
                    items={[
                        {
                            key: "0",
                            children: <InventoryLimitsSection />,
                            label: <Title level={5} title="Inventory Limits" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Inventory Limits"
                                    definitionsFields={limitsDefinitionsFields}
                                />
                            ),
                        },
                        {
                            key: "1",
                            children: <QualityControlSection />,
                            label: <Title level={5} title="Quality Control" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Quality Control"
                                    definitionsFields={controlDefinitionsFields}
                                />
                            ),
                        },
                    ]}
                />
                <AffixFormButtonsBar
                    loading={isUpdating}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    disabled={isUpdating}
                    submitButtonTitle="Save"
                />
            </Form>
        </>
    );
};
