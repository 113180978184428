import { FC } from "react";
import { DrawerCloseButton, Loading } from "@app/core/components";
import { ResizableDrawer } from "@rubicon/antd-components";
import { Col, Row } from "antd";
import { useParams } from "react-router-dom";
import { useGetSeatAdsTextQuery } from "@app/core/services";

interface AdsTextDrawerProps {
    visible: boolean;
    handleClose: () => void;
    publisherId?: number;
}

export const AdsTextDrawer: FC<AdsTextDrawerProps> = ({ handleClose, visible, publisherId }) => {
    const { seatId } = useParams<{ seatId: string }>();

    const { data, isLoading } = useGetSeatAdsTextQuery({
        id: publisherId ? publisherId : Number(seatId),
        isSeat: !publisherId,
    });

    return (
        <ResizableDrawer
            open={visible}
            placement="right"
            extra={<DrawerCloseButton onClose={handleClose} />}
            closable={false}
            onClose={handleClose}
            title="Ads.txt Publisher Ids"
            width="40%"
        >
            {isLoading && <Loading />}
            {!isLoading && (
                <Col style={{ border: "1px solid lightgrey", padding: "1rem", borderRadius: "0.5rem" }}>
                    {data?.split("\n").map((item) => {
                        return <Row key={item}>{item}</Row>;
                    })}
                </Col>
            )}
        </ResizableDrawer>
    );
};
