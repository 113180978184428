import { ClassificationsIndustryForm } from "./ClassificationsIndustryForm";
import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ClassificationsIndustryDefinitionsButtonAndDrawer } from "./ClassificationsIndustryDefinitions";
import { PageHeader } from "@ant-design/pro-layout";

export const ClassificationsIndustryCreatePage = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();

    return (
        <>
            <PageHeader
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_TABS(seatId, "industries"))}
                title="Create New Industry"
                extra={<ClassificationsIndustryDefinitionsButtonAndDrawer />}
            />
            <ClassificationsIndustryForm />
        </>
    );
};
