import React from "react";
import { useHose } from "@app/core/services/useHose";
import conf from "@app/core/conf";
import { IframeBetaLabel, SeatIFrame } from "@app/core/components";
import { useLocation } from "react-router-dom";
import { useGetDeepLink } from "@app/core/components/hooks";

const IFRAME_ID = "streaming-user-preferences";
const USER_PREFERENCES_PATH = "user-preferences";

export const UserPreferencesIframe = () => {
    const { init, launchApplication } = useHose(IFRAME_ID);
    const { pathname, search } = useLocation();
    const deepLink = useGetDeepLink(pathname, USER_PREFERENCES_PATH);

    const srcBuilder = (seatId: string) =>
        `${conf.appsMagniteRoot}/${IFRAME_ID}/#/seats/${seatId}/user-preferences`.concat(deepLink, search);
    const onLoad = () => {
        init();
        launchApplication();
    };

    return (
        <SeatIFrame
            name={IFRAME_ID}
            title={<IframeBetaLabel title="User Preferences" />}
            src={srcBuilder}
            onLoad={onLoad}
        />
    );
};
