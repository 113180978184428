import { TargetingBlock } from "../types";

const getHasAudienceAndSegmentTargeting = (targetingBlocks: TargetingBlock[]) => {
    return targetingBlocks.reduce(
        (acc, block) => {
            if (block.usedDimensions.audiences.includes) {
                acc.hasAudienceTargeting = true;
            }
            if (block.usedDimensions.segmentRules.includes || block.usedDimensions.segmentRules.excludes) {
                acc.hasSegmentTargeting = true;
            }
            return acc;
        },
        { hasAudienceTargeting: false, hasSegmentTargeting: false }
    );
};

export const getHasMixedAudienceAndSegmentTargeting = (
    targetingBlocks: TargetingBlock[],
    validationTargetingBlocks: TargetingBlock[],
    additionalValidationTargetingBlocks: TargetingBlock[]
) => {
    const { hasAudienceTargeting, hasSegmentTargeting } = getHasAudienceAndSegmentTargeting(targetingBlocks);
    const { hasAudienceTargeting: hasValidationAudienceTargeting, hasSegmentTargeting: hasValidationSegmentTargeting } =
        getHasAudienceAndSegmentTargeting(validationTargetingBlocks);
    const {
        hasAudienceTargeting: hasAdditionalValidationAudienceTargeting,
        hasSegmentTargeting: hasAdditionalValidationSegmentTargeting,
    } = getHasAudienceAndSegmentTargeting(additionalValidationTargetingBlocks);

    return (
        (hasAudienceTargeting || hasValidationAudienceTargeting || hasAdditionalValidationAudienceTargeting) &&
        (hasSegmentTargeting || hasValidationSegmentTargeting || hasAdditionalValidationSegmentTargeting)
    );
};

const getTargetingBlocksAudienceCount = (targetingBlocks: TargetingBlock[]) =>
    targetingBlocks.filter((block) => block.usedDimensions.audiences.includes).length;

export const getAudienceTargetingCount = (
    targetingBlocks: TargetingBlock[],
    validationTargetingBlocks: TargetingBlock[],
    additionalValidationTargetingBlocks: TargetingBlock[]
) => {
    const audienceTargetingCount = getTargetingBlocksAudienceCount(targetingBlocks);
    const validationAudienceTargetingCount = getTargetingBlocksAudienceCount(validationTargetingBlocks);
    const additionalValidationAudienceTargetingCount = getTargetingBlocksAudienceCount(
        additionalValidationTargetingBlocks
    );

    return audienceTargetingCount + validationAudienceTargetingCount + additionalValidationAudienceTargetingCount;
};
