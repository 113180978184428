import { AdSourceListItem } from "@app/core/services";
import { AD_SOURCE_TYPES_NEXT_UI_NAMES, AdSourceTypes } from "@app/features/seatAdSources/constants";

export const getPriceTypeToBeDisplayed = (adSource: AdSourceListItem) => {
    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSource.type.name];

    if (
        adSourceTypeName === AdSourceTypes.AUCTION_PRICE ||
        adSourceTypeName === AdSourceTypes.OPEN_AUCTION ||
        adSourceTypeName === AdSourceTypes.MARKETPLACE ||
        adSourceTypeName === AdSourceTypes.PREBID_AD_SOURCE ||
        adSourceTypeName === AdSourceTypes.LINEAR_AUCTION_PRICE
    ) {
        return "Auction";
    }

    if (adSourceTypeName === AdSourceTypes.SERVER_SIDE_DYNAMIC_PRICE) {
        return "Dynamic";
    }

    if (adSourceTypeName === AdSourceTypes.PREBID_DEALS_CONTAINER || adSourceTypeName === AdSourceTypes.FALLBACK_TAG) {
        return "--";
    }

    return "Fixed";
};
