import { NONE } from "@app/core/components/constants";
import { BuyerSeatList } from "@app/core/services";
import { css } from "@emotion/css";
import { Card, Flex, Form } from "antd";
import { FC } from "react";
import { BuyerSeatListsTag } from "../BuyerSeatListsPage";

interface Props {
    buyerSeat: BuyerSeatList;
}
export const BuyerSeatListsReadOnlyForm: FC<Props> = ({ buyerSeat, children }) => (
    <Card
        type="inner"
        bordered={false}
        className={css`
            &.ant-card {
                box-shadow: none;
            }
        `}
    >
        <Flex justify="space-between">
            <Form layout="vertical">
                <Form.Item label="Name">{buyerSeat.name}</Form.Item>
                <Form.Item label="Description">{buyerSeat.description ? buyerSeat.description : NONE}</Form.Item>
                <Form.Item label="Buyer Seats">
                    <BuyerSeatListsTag buyerSeats={buyerSeat.buyerSeats} />
                </Form.Item>
            </Form>
            {children}
        </Flex>
    </Card>
);
