import { FC } from "react";

export const DealDemandIcon: FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="145" height="65" viewBox="0 0 145 65">
        <g id="Img_Demand_Deal" data-name="Img_Demand Deal" transform="translate(-1.836 -10.415)">
            <path
                id="Path_170"
                data-name="Path 170"
                d="M114.062,25.14a7.114,7.114,0,0,1-7.113,7.113H29.035a7.114,7.114,0,0,1-7.113-7.113V18.028a7.114,7.114,0,0,1,7.113-7.113h77.915a7.114,7.114,0,0,1,7.113,7.113Z"
                transform="translate(32.274)"
                fill="none"
                stroke="#8c8c8c"
                strokeMiterlimit="5.833"
                strokeWidth="1"
            />
            <path
                id="Path_171"
                data-name="Path 171"
                d="M28.142,14.581a.888.888,0,1,0,.891.888.888.888,0,0,0-.891-.888h0"
                transform="translate(41.167 6.114)"
                fill="none"
                stroke="#8c8c8c"
                strokeMiterlimit="5.833"
                strokeWidth="1"
            />
            <path
                id="Path_172"
                data-name="Path 172"
                d="M32.81,14.581a.888.888,0,1,0,.888.888.89.89,0,0,0-.888-.888h0"
                transform="translate(48.948 6.114)"
                fill="none"
                stroke="#8c8c8c"
                strokeMiterlimit="5.833"
                strokeWidth="1"
            />
            <path
                id="Path_173"
                data-name="Path 173"
                d="M114.062,41.135a7.112,7.112,0,0,1-7.113,7.113H29.035a7.112,7.112,0,0,1-7.113-7.113V34.023a7.114,7.114,0,0,1,7.113-7.113h77.915a7.114,7.114,0,0,1,7.113,7.113Z"
                transform="translate(32.274 26.667)"
                fill="none"
                stroke="#8c8c8c"
                strokeMiterlimit="5.833"
                strokeWidth="1"
            />
            <path
                id="Path_174"
                data-name="Path 174"
                d="M28.142,30.576a.89.89,0,1,0,.891.888.888.888,0,0,0-.891-.888h0"
                transform="translate(41.167 32.782)"
                fill="none"
                stroke="#8c8c8c"
                strokeMiterlimit="5.833"
                strokeWidth="1"
            />
            <path
                id="Path_175"
                data-name="Path 175"
                d="M32.81,30.576a.89.89,0,1,0,.888.888.89.89,0,0,0-.888-.888h0"
                transform="translate(48.948 32.782)"
                fill="none"
                stroke="#8c8c8c"
                strokeMiterlimit="5.833"
                strokeWidth="1"
            />
            <path
                id="Path_176"
                data-name="Path 176"
                d="M114.062,33.138a7.112,7.112,0,0,1-7.113,7.11H29.035a7.112,7.112,0,0,1-7.113-7.11V26.026a7.112,7.112,0,0,1,7.113-7.113h77.915a7.112,7.112,0,0,1,7.113,7.113Z"
                transform="translate(32.274 13.33)"
                fill="none"
                stroke="#8c8c8c"
                strokeMiterlimit="5.833"
                strokeWidth="1"
            />
            <path
                id="Path_177"
                data-name="Path 177"
                d="M28.142,22.578a.89.89,0,1,0,.891.891.89.89,0,0,0-.891-.891h0"
                transform="translate(41.167 19.442)"
                fill="none"
                stroke="#8c8c8c"
                strokeMiterlimit="5.833"
                strokeWidth="1"
            />
            <path
                id="Path_178"
                data-name="Path 178"
                d="M32.81,22.578a.89.89,0,1,0,.888.891.893.893,0,0,0-.888-.891h0"
                transform="translate(48.948 19.442)"
                fill="none"
                stroke="#8c8c8c"
                strokeMiterlimit="5.833"
                strokeWidth="1"
            />
            <path
                id="Path_179"
                data-name="Path 179"
                d="M2.336,21.374h42.2V57.369H2.336Z"
                transform="translate(0 17.434)"
                fill="none"
                stroke="#8c8c8c"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                id="Path_180"
                data-name="Path 180"
                d="M5.128,27.118a13.653,13.653,0,1,1,27.306,0v7.449H5.128Z"
                transform="translate(4.657 4.251)"
                fill="none"
                stroke="#8c8c8c"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                id="Path_181"
                data-name="Path 181"
                d="M18.128,24.344H13.057a4.381,4.381,0,0,0-1.958,8.3l4.607,2.305a4.381,4.381,0,0,1-1.958,8.3H8.676"
                transform="translate(10.574 22.388)"
                fill="none"
                stroke="#8c8c8c"
                strokeMiterlimit="5.833"
                strokeWidth="1"
            />
            <path
                id="Path_182"
                data-name="Path 182"
                d="M10.447,26.561V23.015"
                transform="translate(13.528 20.171)"
                fill="none"
                stroke="#8c8c8c"
                strokeMiterlimit="5.833"
                strokeWidth="1"
            />
            <path
                id="Path_183"
                data-name="Path 183"
                d="M10.447,34.976V31.43"
                transform="translate(13.528 34.206)"
                fill="none"
                stroke="#8c8c8c"
                strokeMiterlimit="5.833"
                strokeWidth="1"
            />
        </g>
    </svg>
);
