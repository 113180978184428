import { useEffect, useState } from "react";
import { XYSeries } from "../types";
import { getUniqueSeries } from "../utils";
import { LegendState, RequiredLegendProps } from "./types";

const DEFAULTED_CHECKED_STATE = true;

export interface CheckboxState {
    series: string;
    isChecked: boolean;
}

interface UseLegend {
    seriesFilters: LegendState;
    requiredLegendProps: RequiredLegendProps;
}

export const useLegend = (xYSeriesData: XYSeries[][]): UseLegend => {
    const series = getUniqueSeries(xYSeriesData);
    const [legendState, setLegendState] = useState<LegendState>({});

    const hasLegendEntries = Object.keys(legendState).length > 0;
    const isAtLeastOneChecked = Object.values(legendState).reduce(
        (anyChecked, currentChecked) => anyChecked || currentChecked,
        false
    );

    const seriesSet = new Set<string>(series);
    const checkboxesState = Object.entries(legendState)
        .map((entry) => ({
            series: entry[0],
            isChecked: entry[1],
        }))
        .filter((checkbox) => seriesSet.has(checkbox.series));

    const toggleButtonText = isAtLeastOneChecked ? "Clear All" : "Enable All";

    const legendEntriesDependencyCheck = JSON.stringify(series);
    useEffect(() => {
        if (!hasLegendEntries) {
            const allChecked: LegendState = series.reduce((state, legendEntry) => {
                state[legendEntry] = DEFAULTED_CHECKED_STATE;
                return state;
            }, {});
            setLegendState(allChecked);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [legendEntriesDependencyCheck]);

    const toggleSingleChecked = (series: string) => {
        const singleToggledLegendState = Object.assign({}, legendState, {
            [series]: !legendState[series],
        });
        setLegendState(singleToggledLegendState);
    };

    const toggleAllChecked = () => {
        const allToggledLegendState = Object.assign({}, legendState);
        Object.keys(allToggledLegendState).forEach((series) => {
            allToggledLegendState[series] = !isAtLeastOneChecked;
        });
        setLegendState(allToggledLegendState);
    };

    return {
        seriesFilters: legendState,
        requiredLegendProps: {
            hasLegendEntries,
            checkboxesState,
            toggleButtonText,
            toggleSingleChecked,
            toggleAllChecked,
        },
    };
};
