import { FC } from "react";
import { LabeledValue } from "antd/lib/select";
import { Button, Col, Form, Row, Select } from "antd";
import { FloorAnalysisTabsType } from "@app/features/inventory/DetailsDrawer/DetailsDrawerContent/FloorAnalysisContent/useFloorAnalysisContent";

interface FloorAnalysisFiltersProps {
    handleChangeDateRange: (value: string) => void;
    handleChangeAdSourceType: (value: number) => void;
    handleChangeCountry: (value: string) => void;
    handleSearchCountries: (value: string) => void;
    handleChangeEstPrice?: (value: number) => void;
    handleRunAnalysis: () => void;
    adSourceTypeOptions: LabeledValue[];
    queryRangesOptions: LabeledValue[];
    countriesOptions: LabeledValue[];
    estPriceOptions?: LabeledValue[];
    activeTab: FloorAnalysisTabsType;
    isFetchingCountries: boolean;
    isFetchingQueryRanges: boolean;
    isFetchingEstPrice?: boolean;
    estPriceValue?: number;
    adSourceType: number;
    isFetching?: boolean;
    dateRange: string;
    country?: string;
}

const COL_SIZES = { xs: 24, sm: 12, md: 12, lg: 6, xl: 5, xxl: 5 };
export const FloorAnalysisFilters: FC<FloorAnalysisFiltersProps> = ({
    dateRange,
    activeTab,
    estPriceOptions,
    isFetchingEstPrice,
    adSourceTypeOptions,
    handleChangeAdSourceType,
    handleRunAnalysis,
    isFetching,
    adSourceType,
    country,
    estPriceValue,
    handleChangeEstPrice,
    isFetchingQueryRanges,
    isFetchingCountries,
    countriesOptions,
    queryRangesOptions,
    handleSearchCountries,
    handleChangeCountry,
    handleChangeDateRange,
}) => {
    const isEstPriceShown: boolean = activeTab === FloorAnalysisTabsType.historicalFloorAnalysis;
    return (
        <Form layout="vertical" data-sdet="floor-analysis-stats-filter">
            <Row gutter={24}>
                <Col {...COL_SIZES}>
                    <Form.Item label="Date Range">
                        <Select
                            showSearch
                            value={dateRange}
                            style={{ width: "100%" }}
                            options={queryRangesOptions}
                            loading={isFetchingQueryRanges}
                            onChange={handleChangeDateRange}
                        />
                    </Form.Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Form.Item label="Ad Source Type">
                        <Select
                            value={adSourceType}
                            style={{ width: "100%" }}
                            options={adSourceTypeOptions}
                            onChange={handleChangeAdSourceType}
                        />
                    </Form.Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Form.Item label="Country">
                        <Select
                            allowClear
                            showSearch
                            value={country}
                            placeholder="All"
                            style={{ width: "100%" }}
                            options={countriesOptions}
                            loading={isFetchingCountries}
                            onChange={handleChangeCountry}
                            onSearch={handleSearchCountries}
                        />
                    </Form.Item>
                </Col>
                {isEstPriceShown && (
                    <Col {...COL_SIZES}>
                        <Form.Item label="Est. Price">
                            <Select
                                value={estPriceValue}
                                placeholder="Optimal"
                                options={estPriceOptions}
                                onChange={handleChangeEstPrice}
                                loading={isFetchingEstPrice}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                )}
                <Col style={{ alignSelf: "center" }}>
                    <Button onClick={handleRunAnalysis} loading={isFetching} type="primary">
                        Run Analysis
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};
