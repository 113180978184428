import { FC } from "react";
import { useDealHealthTableFilterSearch } from "@app/features/deals/DealHealthPage/DealHealthTable/DealHealthTableFilterSearch/useDealHealthTableFilterSearch";
import { TableSearchInput } from "@app/core/components/TableSearchInput/TableSearchInput";

const DealHealthFilterSearch: FC = () => {
    const tableSearchInputProps = useDealHealthTableFilterSearch();
    return <TableSearchInput {...tableSearchInputProps} />;
};

export default DealHealthFilterSearch;
