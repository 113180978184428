import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormCheckboxChange } from "@app/features/deals/DealForm/reducer";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

interface UseDealConfigureAdSourceField {
    handleChange: (event: CheckboxChangeEvent) => void;
    value: boolean;
}

export const useDealConfigureAdSourceField = (): UseDealConfigureAdSourceField => {
    const dispatch = useAppDispatch();
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.CONFIGURE_AD_SOURCE]);
    const handleChange = (event: CheckboxChangeEvent): void => {
        const { value } = event.target;
        dispatch(dealFormCheckboxChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.CONFIGURE_AD_SOURCE, value }));
    };

    return {
        handleChange,
        value,
    };
};
