import { FC } from "react";
import { Col, Form, Row, Space } from "antd";
import { CurrencyConversionModeField } from "./CurrencyConversionModeField";
import { RevenueTypeField } from "./RevenueTypeField";
import { useUpdateSettingsOnChange } from "./useUpdateSettingsOnChange";
import { PreferredCurrencyField } from "./PreferredCurrencyField";

export const DashboardFilters: FC = () => {
    useUpdateSettingsOnChange();

    return (
        <Form layout="inline">
            <Row>
                <Col>
                    <RevenueTypeField />
                </Col>
                <Col>
                    <Space.Compact>
                        <CurrencyConversionModeField />
                        <PreferredCurrencyField />
                    </Space.Compact>
                </Col>
            </Row>
        </Form>
    );
};
