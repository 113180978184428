import { FC } from "react";
import { Alert, Col, Row } from "antd";
import { Targeting } from "@app/core/services/console";
import { Operations, TargetingFormKeys } from "../../constants";
import { ReadOnlyOperation } from "../ReadOnlyOperation";
import { ReadOnlyTargetingBlocks } from "../ReadOnlyTargetingBlocks";
import { DemandConfigurationTargetingSectionHeader } from "../../TargetingSectionHeader/DemandConfigurationTargetingSectionHeader";
import { mergeTargeting } from "../helpers";
import { DemandConfigurationOperatorExplanation } from "@app/features/targeting/TargetingSectionHeader/DemandConfigurationOperatorExplanation";
import { useAppSelector } from "@app/core/store";
import { toTargetingModePayloadId } from "../../helpers";
import { OR_ID } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections";

interface Props {
    additionalTargetingBlocks: Targeting[] | null;
    adSourceTargetingBlocks: Targeting[] | null;
    showExistingAdSourceTargeting: boolean;
    setShowExistingAdSourceTargeting: (x: boolean) => void;
    adSourceTargetingFormKey: TargetingFormKeys;
    additionalTargetingFormKey: TargetingFormKeys;
    existingAdSourceTargetingModeId: number;
}

const BOXED_TARGETING_STYLE = {
    backgroundColor: "#fafafa",
    padding: "12px",
    border: "1px solid #d9d9d9",
    borderRadius: "0 0 2px 2px",
};

export const ReadOnlyDemandConfigurationTargeting: FC<Props> = ({
    additionalTargetingBlocks,
    adSourceTargetingBlocks,
    showExistingAdSourceTargeting,
    setShowExistingAdSourceTargeting,
    adSourceTargetingFormKey: adSourceTargetingFormKey,
    additionalTargetingFormKey: additionalTargetingFormKey,
    existingAdSourceTargetingModeId,
}) => {
    // The real time operation is the current operation as chosen by the user
    const realTimeAdSourceOperation = useAppSelector(
        (state) => state.targeting.targetingFormsByKey[adSourceTargetingFormKey].targetingOperation
    );

    const existingAdSourceOperation = existingAdSourceTargetingModeId === OR_ID ? Operations.Any : Operations.All;

    /**
     * this is the operation and mode ID that is in effect - if there is a realTimeAdSourceOperation we should use that, because
     * this can change in real time if we are on the edit page and the user changes the ad source operation then opens the
     * demand details drawer in read only mode
     */
    const adSourceOperation = realTimeAdSourceOperation || existingAdSourceOperation;
    const adSourceTargetingModeId = toTargetingModePayloadId(adSourceOperation);

    return (
        <Alert.ErrorBoundary>
            <DemandConfigurationTargetingSectionHeader
                adSourceTargetingExists={Boolean(adSourceTargetingBlocks?.length)}
                showExistingAdSourceTargeting={showExistingAdSourceTargeting}
                onShowExistingAdSourceTargetingChange={setShowExistingAdSourceTargeting}
            />
            <div style={{ marginBottom: 16 }}>
                <DemandConfigurationOperatorExplanation operation={adSourceOperation} />
            </div>
            <Row>
                {showExistingAdSourceTargeting && (
                    <>
                        <Col flex="10">
                            <div style={Boolean(additionalTargetingBlocks?.length) ? BOXED_TARGETING_STYLE : {}}>
                                <ReadOnlyTargetingBlocks
                                    targetingBlocks={mergeTargeting(
                                        adSourceTargetingBlocks || [],
                                        adSourceTargetingModeId
                                    )}
                                    operation={adSourceOperation}
                                    formKey={adSourceTargetingFormKey}
                                />
                            </div>
                        </Col>
                    </>
                )}

                {Boolean(additionalTargetingBlocks?.length) && (
                    <>
                        {showExistingAdSourceTargeting && (
                            <Col style={{ marginTop: "40px" }}>
                                <ReadOnlyOperation direction="horizontal" operation={Operations.All} />
                            </Col>
                        )}
                        <Col
                            xs={showExistingAdSourceTargeting ? undefined : 24}
                            flex={showExistingAdSourceTargeting ? "10" : undefined}
                        >
                            <div style={showExistingAdSourceTargeting ? BOXED_TARGETING_STYLE : {}}>
                                <ReadOnlyTargetingBlocks
                                    targetingBlocks={mergeTargeting(
                                        additionalTargetingBlocks || [],
                                        adSourceTargetingModeId
                                    )}
                                    operation={adSourceOperation}
                                    formKey={additionalTargetingFormKey}
                                />
                            </div>
                        </Col>
                    </>
                )}
            </Row>
        </Alert.ErrorBoundary>
    );
};
