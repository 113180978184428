import { FC } from "react";
import { useDealListTableFilterSearch } from "@app/features/deals/DealListPage/DealListTable/DealListTableFilterSearch/useDealListTableFilterSearch";
import { TableSearchInput } from "@app/core/components";

const DealFilterSearch: FC = () => {
    // TODO: Connect filter to AdSource deal list
    const tableSearchInputProps = useDealListTableFilterSearch();
    return <TableSearchInput {...tableSearchInputProps} />;
};

export default DealFilterSearch;
