import { useState } from "react";
import { useEffect } from "react";
import { notification } from "antd";
import {
    FinanceDefault,
    FinanceDefaultsCreatePayload,
    useGetSeatFinanceDefaultsQuery,
    useDeleteSeatFinanceDefaultsMutation,
    useCreateSeatFinanceDefaultsMutation,
} from "@app/core/services/console";
import { useParams } from "react-router-dom";
import { AD_SOURCE_COST_MODEL_FIXED, DEFAULT_AD_SOURCE_COST_MODEL_ID } from "@app/features/deals/DealForm/useDealForm";

export interface Price {
    key: number;
    id: number | null | undefined;
    costValueFixed: number | null | undefined;
    costValuePercent: number | null | undefined;
    lineItemType: number | null | undefined;
    costModel: number;
    adSourceType: null | number | undefined;
    price: FinanceDefault | null;
}

interface UseDefaultPricesForm {
    isFetching: boolean;
    prices: Price[];
    isMoreThanOneNewPrice: boolean;
    isAddingIndex: Set<number>;
    isSavingIndex: Set<number>;
    isDeletingIndex: Set<number>;
    handleAdd: () => void;
    handleChangeType: (index: number, value: number) => void;
    handleChangePrice: (index: number, value: number | string) => void;
    handleChangeCostModel: (index: number, value: number) => void;
    handleDelete: (index: number) => void;
    handleDeleteNew: (index: number) => void;
    handleSave: (index: number) => void;
    validate: string | null;
}

const getValuesFromPrices = (price: FinanceDefault, key: number): Price => ({
    key,
    id: price?.id,
    costModel: price?.costModel?.id,
    adSourceType: price?.adSourceType?.id,
    costValueFixed: price?.costValueFixed,
    costValuePercent: price?.costValuePercent,
    lineItemType: price?.lineItemType?.id,
    price,
});

const getInitialValues = (prices: FinanceDefault[] | null): Price[] => {
    if (!prices) {
        return [];
    }

    return prices.map((price, i) => getValuesFromPrices(price, i));
};

export const useDefaultPricesForm = (): UseDefaultPricesForm => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data = [], isFetching } = useGetSeatFinanceDefaultsQuery(Number(seatId), { skip: !seatId });
    const [prices, setPrices] = useState<Price[]>([]);
    const [isAdding, setIsAdding] = useState(new Set<number>());
    const [isSaving, setIsSaving] = useState(new Set<number>());
    const [isDeleting, setIsDeleting] = useState(new Set<number>());
    const [validate, setValidate] = useState<string | null>(null);
    const [createSeatFinanceDefault] = useCreateSeatFinanceDefaultsMutation();
    const [deleteSeatFinanceDefault] = useDeleteSeatFinanceDefaultsMutation();

    const isMoreThanOneNewPrice = data?.length + 1 === prices.length;

    useEffect((): void => {
        if (data) {
            setPrices(getInitialValues(data));
        }
    }, [data]);

    const handleSave = (index: number): void => {
        const price: Price = prices[index];
        createPrice(price);
    };

    const handleDelete = (index: number): void => {
        const price: Price = prices[index];
        deletePrice(price);
    };

    const handleAdd = (): void => {
        if (isMoreThanOneNewPrice) return;
        setIsAdding((prev) => new Set(prev).add(prices.length));
        setPrices((prev) => {
            const newPrices = [...prev];
            newPrices.push({
                key: newPrices.length,
                id: null,
                adSourceType: null,
                costModel: DEFAULT_AD_SOURCE_COST_MODEL_ID,
                costValueFixed: null,
                costValuePercent: null,
                lineItemType: null,
                price: null,
            });
            return newPrices;
        });
    };

    const handleDeleteNew = (index: number): void => {
        setPrices((prev) => prev.filter((_, i) => i !== index));
        setValidate(null);
    };

    const handleChangeType = (index: number, value: number): void => {
        setValidate(null);
        setPrices((prev) => {
            const newPrices = [...prev];
            newPrices[index] = { ...newPrices[index], adSourceType: value };
            return newPrices;
        });
    };
    const handleChangeCostModel = (index: number, value: number): void => {
        setPrices((prev) => {
            const newPrices = [...prev];
            newPrices[index] = { ...newPrices[index], costModel: value, costValueFixed: null, costValuePercent: null };
            return newPrices;
        });
    };

    const handleChangePrice = (index: number, value: number | string): void => {
        setPrices((prev) => {
            const newPrices = [...prev];
            const costModel = newPrices[index]?.costModel;
            const valueType = costModel === AD_SOURCE_COST_MODEL_FIXED ? "costValueFixed" : "costValuePercent";
            newPrices[index] = { ...newPrices[index], [valueType]: value };
            return newPrices;
        });
    };

    const createPrice = async (values: Price): Promise<void> => {
        if (!values?.adSourceType) {
            setValidate("Type is required");
            return;
        }
        setIsSaving((prev) => new Set(prev).add(values.key));

        const body: FinanceDefaultsCreatePayload = {
            seat: { id: Number(seatId) },
            costModel: { id: values.costModel },
            adSourceType: { id: values.adSourceType },
            costValueFixed: values.costValueFixed ? values.costValueFixed * 1000 : null,
            costValuePercent: values.costValuePercent ? values.costValuePercent : null,
            id: null,
            lineItemType: null,
        };

        try {
            await createSeatFinanceDefault({ seatId: Number(seatId), body }).unwrap();
            notification.success({ message: "Created Default Price" });
            setIsAdding((prev) => {
                const newSet = new Set(prev);
                newSet.delete(values.key);
                return newSet;
            });
        } catch (err) {
            notification.error({ message: err.data.errorDescription });
        }
        setValidate(null);
        setIsSaving((prev) => {
            const newSet = new Set(prev);
            newSet.delete(values.key);
            return newSet;
        });
    };

    const deletePrice = async (values: Price): Promise<void> => {
        setIsDeleting((prev) => new Set(prev).add(values.key));

        try {
            await deleteSeatFinanceDefault(Number(values.id)).unwrap();
            notification.success({ message: "Deleted Default Price" });
        } catch (err) {
            notification.error({ message: err.data.errorDescription });
        }

        setIsDeleting((prev) => {
            const newSet = new Set(prev);
            newSet.delete(values.key);
            return newSet;
        });
    };

    return {
        prices,
        validate,
        isFetching,
        isMoreThanOneNewPrice,
        isAddingIndex: isAdding,
        isSavingIndex: isSaving,
        isDeletingIndex: isDeleting,
        handleAdd,
        handleSave,
        handleDelete,
        handleDeleteNew,
        handleChangeType,
        handleChangePrice,
        handleChangeCostModel,
    };
};
