import { ROUTE_FORMATTERS } from "@app/core/routing";
import { CreativeSamplesEntityTypes } from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import { useHistory, useParams } from "react-router-dom";
import {
    InventoryDetailsDrawerType,
    selectInventoryDetailsDrawerType,
    selectIsPageInventoryDetailsDrawer,
} from "../../reducer";
import { UnitUrlIds } from "../../useInventoryDetailsDrawerUrlId";
import { useNestedData } from "../useNestedData";

interface UrlParams extends UnitUrlIds {
    seatId: string;
}

interface UseCreativeReviewContent {
    creativeEntityType: CreativeSamplesEntityTypes;
    isLoading: boolean;
    breadcrumbs?: string;
    handleShowDefinition: () => void;
}

export const useCreativeReviewContent = (id: string | number): UseCreativeReviewContent => {
    const { nestedBreadcrumbs, isLoading } = useNestedData(id);
    const { seatId, publisherId, brandId, supplyId, adUnitId } = useParams<UrlParams>();
    const history = useHistory();
    const drawerType = useAppSelector(selectInventoryDetailsDrawerType) as InventoryDetailsDrawerType;
    const isFromPage = useAppSelector(selectIsPageInventoryDetailsDrawer);
    let creativeEntityType: CreativeSamplesEntityTypes;

    switch (drawerType) {
        case InventoryDetailsDrawerType.SEAT:
            creativeEntityType = CreativeSamplesEntityTypes.Seat;
            break;
        case InventoryDetailsDrawerType.PUBLISHER:
        case InventoryDetailsDrawerType.CHANNEL:
            creativeEntityType = CreativeSamplesEntityTypes.Publisher;
            break;
        case InventoryDetailsDrawerType.BRAND:
        case InventoryDetailsDrawerType.BRAND_CHANNEL:
            creativeEntityType = CreativeSamplesEntityTypes.Brand;
            break;
        case InventoryDetailsDrawerType.SUPPLY:
            creativeEntityType = CreativeSamplesEntityTypes.Supply;
            break;
        case InventoryDetailsDrawerType.AD_UNIT:
            creativeEntityType = CreativeSamplesEntityTypes.AdUnit;
            break;
    }

    const handleShowDefinition = () => {
        history.push(
            ROUTE_FORMATTERS.INVENTORY_HEALTH_DETAILS_CREATIVE_REVIEW_DEFINITION(
                isFromPage,
                seatId,
                drawerType,
                Number(publisherId),
                Number(brandId),
                Number(supplyId),
                Number(adUnitId)
            )
        );
    };

    return {
        creativeEntityType,
        isLoading,
        breadcrumbs: nestedBreadcrumbs,
        handleShowDefinition,
    };
};
