import { useMemo, useCallback, ReactNode } from "react";
import { Button } from "antd";

type Props<T> = {
    onChange: (value: T[]) => void;
    options: T[];
    getPrimaryKey: (o: T) => string | number;
    search: string;
    value: T[] | undefined;
    hasMore?: boolean;
    isCurrent?: boolean;
};

const SelectAllButton = <T,>({
    getPrimaryKey,
    hasMore,
    isCurrent,
    onChange,
    options,
    search,
    value = [],
}: Props<T>) => {
    const optionsValues = useMemo(() => options.map((o) => getPrimaryKey(o)), [options, getPrimaryKey]);
    const optionsValuesSet = useMemo(() => new Set(optionsValues), [optionsValues]);
    const selectedValues = useMemo(() => value.map((v) => getPrimaryKey(v)), [value, getPrimaryKey]);
    const selectedValuesSet = useMemo(() => new Set(selectedValues), [selectedValues]);

    const hasSelectedAll = useMemo(() => {
        return options.every((o) => selectedValuesSet.has(getPrimaryKey(o)));
    }, [options, selectedValuesSet, getPrimaryKey]);

    const onClick = useCallback(() => {
        if (hasSelectedAll) {
            const valueWithoutDisplayedOptions = value.filter((v) => !optionsValuesSet.has(getPrimaryKey(v)));
            return onChange(valueWithoutDisplayedOptions);
        }
        const unselectedValues = options.filter((o) => !selectedValues.includes(getPrimaryKey(o)));
        const combinedValues = [...value, ...unselectedValues];

        return onChange(combinedValues);
    }, [selectedValues, options, hasSelectedAll, getPrimaryKey, onChange, optionsValuesSet, value]);

    if (hasMore || !isCurrent || !search || !options.length) {
        return null;
    }

    const messagePrefix = hasSelectedAll ? "DESELECT ALL" : "SELECT ALL";
    const message = `${messagePrefix} results for "${search}"`;

    return (
        <Button type="link" block style={{ textAlign: "left", padding: "5px 12px" }} onClick={onClick}>
            {message}
        </Button>
    );
};

export const SelectAllButtonDropdownRender = <T,>({
    getPrimaryKey,
    hasMore = false,
    isCurrent = true,
    menu,
    onChange,
    options,
    search,
    value,
}: { menu: ReactNode } & Props<T>) => {
    return (
        <div
            onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            {menu}
            <SelectAllButton<T>
                onChange={onChange}
                search={search}
                options={options}
                value={value}
                getPrimaryKey={getPrimaryKey}
                isCurrent={isCurrent}
                hasMore={hasMore}
            />
        </div>
    );
};
