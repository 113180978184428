import { Form, Tag, Typography } from "antd";
import { FC } from "react";
import { SeatContentTransparencyRule } from "@app/core/services";
import { NONE } from "@app/core/components/constants";

export const CONTENT_TRANSPARENCY_DETAILS_NAME_INPUT_SDET = "content-transparency-rules-name-input";
export const CONTENT_TRANSPARENCY_DETAILS_DESCRIPTION_SDET = "content-transparency-rules-description-input";
export const CONTENT_TRANSPARENCY_DETAILS_ALLOWED_CONTENT_SDET = "content-transparency-rules-allowed-content";
export const CONTENT_TRANSPARENCY_DELETE_BTN_SDET = "content-transparency-rules-delete-btn";

interface Props {
    entity?: SeatContentTransparencyRule;
}
export const CONTENT_TRANSPARENCY_DETAILS_CONTENT_SDET = "content-transparency-rules-details-content";

export const ContentTransparencyRuleDetailsContent: FC<Props> = ({ entity }) => {
    return entity ? (
        <Form
            size="small"
            layout="vertical"
            data-sdet={CONTENT_TRANSPARENCY_DETAILS_CONTENT_SDET}
            wrapperCol={{ xs: 24, md: 12 }}
        >
            <Form.Item label="Name">
                <Typography.Text data-sdet={CONTENT_TRANSPARENCY_DETAILS_NAME_INPUT_SDET}>
                    {entity.name}
                </Typography.Text>
            </Form.Item>
            <Form.Item label="Description">
                <Typography.Text data-sdet={CONTENT_TRANSPARENCY_DETAILS_DESCRIPTION_SDET}>
                    {entity.description || NONE}
                </Typography.Text>
            </Form.Item>
            <Form.Item label="Allowed Content Fields">
                {entity.contentFields.map(({ name, id }) => (
                    <Tag data-sdet={CONTENT_TRANSPARENCY_DETAILS_ALLOWED_CONTENT_SDET} key={id}>
                        {name}
                    </Tag>
                ))}
            </Form.Item>
        </Form>
    ) : null;
};
