import { Seat } from "@app/core/services/console/seats";

export const ENTITY_TYPES = {
    ADVERTISER: "advertiser" as const,
    AD_SOURCE: "adSource" as const,
    AD_UNIT: "adUnit" as const,
    BRAND: "brand" as const,
    BRAND_CHANNEL: "brandChannel" as const,
    BUYER_DEAL: "buyerDeal" as const,
    CHANNEL: "channel" as const,
    DEAL: "deal" as const,
    PUBLISHER: "publisher" as const,
    INDUSTRY: "industry" as const,
    ORDER: "order" as const,
    SUPPLY: "supply" as const,
    LINE_ITEM: "lineItem" as const,
};

export const ENTITY_TYPES_QUERY_MAP = {
    [ENTITY_TYPES.ADVERTISER]: { entity: "advertisers" as const, typeId: undefined },
    [ENTITY_TYPES.AD_SOURCE]: { entity: "adSources" as const, typeId: undefined },
    [ENTITY_TYPES.AD_UNIT]: { entity: "adUnits" as const, typeId: undefined },
    [ENTITY_TYPES.BRAND]: { entity: "brands" as const, typeId: 1 },
    [ENTITY_TYPES.BRAND_CHANNEL]: { entity: "brands" as const, typeId: 2 },
    [ENTITY_TYPES.BUYER_DEAL]: { entity: "buyerDeals" as const, typeId: undefined },
    [ENTITY_TYPES.CHANNEL]: { entity: "publishers" as const, typeId: 2 },
    [ENTITY_TYPES.DEAL]: { entity: "deals" as const, typeId: undefined },
    [ENTITY_TYPES.PUBLISHER]: { entity: "publishers" as const, typeId: 1 },
    [ENTITY_TYPES.INDUSTRY]: { entity: "industries" as const, typeId: undefined },
    [ENTITY_TYPES.ORDER]: { entity: "orders" as const, typeId: undefined },
    [ENTITY_TYPES.SUPPLY]: { entity: "supply" as const, typeId: undefined },
    [ENTITY_TYPES.LINE_ITEM]: { entity: "lineItems" as const, typeId: undefined },
};

export const getEntityOptionsByContext = (ctx: Seat | null) => {
    return (
        ctx &&
        [
            { value: ENTITY_TYPES.PUBLISHER, label: "Publishers" },
            { value: ENTITY_TYPES.BRAND, label: "Brands" },
            { value: ENTITY_TYPES.SUPPLY, label: "Supply" },
            { value: ENTITY_TYPES.AD_UNIT, label: "Ad Units" },
            { value: ENTITY_TYPES.AD_SOURCE, label: "Ad Sources" },
            ctx.enableAdServing && { value: ENTITY_TYPES.ORDER, label: "Orders" },
            ctx.enableAdServing && { value: ENTITY_TYPES.LINE_ITEM, label: "Line Items" },
            { value: ENTITY_TYPES.DEAL, label: "Deals" },
            { value: ENTITY_TYPES.BUYER_DEAL, label: "Buyer Deals" },
            { value: ENTITY_TYPES.ADVERTISER, label: "Advertisers" },
            { value: ENTITY_TYPES.INDUSTRY, label: "Industries" },
            ctx.channelsEnabled && { value: ENTITY_TYPES.CHANNEL, label: "Publisher Channels" },
            ctx.channelsEnabled && { value: ENTITY_TYPES.BRAND_CHANNEL, label: "Brand Channels" },
        ].filter(Boolean)
    );
};

export const ENTITY_TYPE = { key: "entityType", default: ENTITY_TYPES.PUBLISHER };
export const SEARCH = { key: "search", default: "" };

export type URL_PARAM = typeof ENTITY_TYPE | typeof SEARCH;
