import { FC, useMemo } from "react";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import { Mode } from "@app/features/seatAdSources/SeatAdSourcesForm/useAdSourceForm";
import { GOOGLE_DV360_ID, TRADE_DESK_ID, XANDR_ID } from "./constants";
import { gold6 } from "@rubicon/antd-components";

const INFO_TEXT = {
    CREATE: {
        NO_BUYERS:
            "Warning, on edit, you will not be able to add a buyer. You can't go from no buyer to adding a buyer(s) at this time.",
        BUYERS: "On edit, you will not be able to leave the buyer field empty. You can't go from having a buyer to no buyer at this time. You can replace or add more buyers.",
    } as const,
    EDIT: {
        NO_BUYERS: "You can't go from no buyer to adding a buyer(s) at this time.",
        BUYERS: "You can't go from having a buyer to no buyer at this time. You can replace or add more buyers.",
        BUYER_DV360_PROGRAMMATIC_GUARANTEED:
            "You can't go from having a buyer to no buyer at this time. You can only replace the buyer.",
    } as const,
} as const;

const getRestrictedDspInfoText = (dspNetworkName: string) =>
    `${dspNetworkName} does not allow changes to buyer field after the deal is created.`;

interface Props {
    selectedDspNetworkId: number | null;
    formMode: DealFormMode | Mode;
    isBuyerSelected: boolean;
    isProgrammaticGuaranteed: boolean;
}

export const BuyerSeatsSelectLabel: FC<Props> = ({
    selectedDspNetworkId,
    formMode,
    isBuyerSelected,
    isProgrammaticGuaranteed,
}) => {
    const helpText = useMemo(() => {
        if (selectedDspNetworkId) {
            if (selectedDspNetworkId === TRADE_DESK_ID) {
                return getRestrictedDspInfoText("TradeDesk");
            }
            if (selectedDspNetworkId === XANDR_ID) {
                return getRestrictedDspInfoText("Xandr");
            }
        }
        if (formMode === "create") {
            if (isBuyerSelected) {
                return INFO_TEXT.CREATE.BUYERS;
            }
            return INFO_TEXT.CREATE.NO_BUYERS;
        }
        if (formMode === "edit") {
            if (isBuyerSelected) {
                if (selectedDspNetworkId === GOOGLE_DV360_ID && isProgrammaticGuaranteed) {
                    return INFO_TEXT.EDIT.BUYER_DV360_PROGRAMMATIC_GUARANTEED;
                }
                return INFO_TEXT.EDIT.BUYERS;
            }
            return INFO_TEXT.EDIT.NO_BUYERS;
        }
        return "";
    }, [selectedDspNetworkId, formMode, isBuyerSelected, isProgrammaticGuaranteed]);

    return (
        <>
            Buyers
            {helpText ? (
                <Tooltip title={helpText}>
                    <InfoCircleOutlined style={{ paddingLeft: "0.25rem", color: gold6 }} />
                </Tooltip>
            ) : null}
        </>
    );
};
