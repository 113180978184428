import { useEffect, useMemo } from "react";
import { matchPath, useHistory } from "react-router-dom";
import { notification } from "antd";
import { ROUTES } from "@app/core/routing/routes";
import { useLazyGetBuyerByIdQuery } from "@app/core/services/console/buyers";
import { useAppDispatch } from "@app/core/store";
import { setBuyerContext, setIsLoadingBuyer } from "./reducer";

export const useWatchBuyerPermissions = (): void => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [getBuyerById] = useLazyGetBuyerByIdQuery();

    const pathBuyerId = useMemo(
        () =>
            Number(
                matchPath<{ buyerId: string }>(history.location.pathname, {
                    path: "/buyers/:buyerId",
                })?.params?.buyerId
            ),
        [history.location.pathname]
    );

    useEffect(() => {
        if (!pathBuyerId) {
            return;
        }

        (async () => {
            dispatch(setIsLoadingBuyer(true));
            const buyerData = (await getBuyerById(pathBuyerId)).data;
            dispatch(setBuyerContext(buyerData));

            if (!buyerData) {
                // A buyer route was hit while the user is not logged in. Do nothing here and return because <SessionRequiredRoute /> in
                // <AppSwitch /> will redirect to the login form with the appropriate redirect parameter.
                return;
            }

            if (buyerData.id !== pathBuyerId) {
                notification.error({ message: "You do not have access to this buyer" });
                history.push(ROUTES.LOGIN_FORM_CONTEXT_STEP);
            }
        })();
    }, [pathBuyerId, history, dispatch, getBuyerById]);
};
