import { uri } from "@rubicon/utils";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { Form, notification } from "antd";
import { EDIT_MODE } from "../../tagParams/constants";
import { getParamFromSearch } from "../../tagParams/helpers";
import { useUpdateTransparencyDefaultMutation, TransparencyDefaultForm } from "@app/core/services";
import { useState } from "react";
import { mapContentRuleFormToApi, mapExtendedUserIdFormToApi, mapUserIdMaskFormToApi } from "./helpers";
import { useTransparencyExtenderUserDrawer } from "./TransparencyExtendedUserDrawer/useTransparencyExtenderUserDrawer";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { selectExtendedIds, selectTransparencyFormTouchedFields, setTransparencyFormTouchedFields } from "../reducer";

export const useTransparencyEditModeControls = () => {
    const { search } = useLocation();
    const { push } = useHistory();
    const requests = [] as unknown[];
    const { seatId } = useParams<{ seatId: string }>();
    const { availableNetworkRaw, extendedUserRaw } = useTransparencyExtenderUserDrawer();
    const dispatch = useAppDispatch();
    const extendedIds = useAppSelector(selectExtendedIds);
    const touchedFields = useAppSelector(selectTransparencyFormTouchedFields);

    const [updateTransparencyDefault, { isLoading: isUpdating }] = useUpdateTransparencyDefaultMutation();
    const form = Form.useFormInstance();
    const isEdit = getParamFromSearch(search, EDIT_MODE);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleModalOpen = () => setIsOpen(true);

    const handleOk = () => {
        setIsOpen(false);
        form.resetFields();
        dispatch(setTransparencyFormTouchedFields([]));
        const nextSearch = uri.setSearchParam(search, EDIT_MODE.key, "");
        push({ search: nextSearch });
    };
    const handleCancel = () => setIsOpen(false);

    const handleEditClick = () => {
        const nextSearch = uri.setSearchParam(search, EDIT_MODE.key, "enabled");
        push({ search: nextSearch });
    };

    const handleCancelClick = () => {
        const nextSearch = uri.setSearchParam(search, EDIT_MODE.key, "");
        if (form.isFieldsTouched()) {
            return handleModalOpen?.();
        }
        push({ search: nextSearch });
    };

    const handleSaveClick = (values: { transparency: TransparencyDefaultForm[] }) => {
        const nextSearch = uri.setSearchParam(search, EDIT_MODE.key, "");
        const payload = values?.transparency.map(
            ({ controlContent, controlExtendedUserId, controlUserIdIfa, ...transparency }) => ({
                ...transparency,
                ...mapUserIdMaskFormToApi(controlUserIdIfa),
                ...mapContentRuleFormToApi(controlContent),
                ...mapExtendedUserIdFormToApi(
                    controlExtendedUserId,
                    { availableNetworkRaw, extendedUserRaw, extendedIds },
                    transparency.id
                ),
            })
        );
        payload.forEach((field, index) => {
            if (touchedFields?.includes(index)) {
                return requests.push(
                    updateTransparencyDefault({ body: { seat: { id: Number(seatId) }, ...field } }).unwrap()
                );
            }
        });
        Promise.all(requests)
            .then(() => notification.success({ message: "Transparency settings have been saved successfully" }))
            .catch((error) => {
                notification.error({ message: error.data?.errorDescription || "Something went wrong" });
            })
            .finally(() => dispatch(setTransparencyFormTouchedFields([])));

        push({ search: nextSearch });
    };

    return {
        handleCancel,
        handleOk,
        handleModalOpen,
        open: isOpen,
        isEdit: Boolean(isEdit),
        isSaving: isUpdating,
        handleEditClick,
        handleCancelClick,
        handleSaveClick,
    };
};
