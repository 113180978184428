import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { Form, Radio } from "antd";
import { useBvodConnect } from "./useBvodConnect";

export const BvodConnectRadio = () => {
    const { isVisible } = useBvodConnect();
    if (!isVisible) {
        return null;
    }
    return (
        <Form.Item
            name={AD_SOURCE_FIELDS.BVOD_CONNECT_ID.name}
            label={AD_SOURCE_FIELDS.BVOD_CONNECT_ID.label}
            labelCol={{ lg: 8 }}
            labelAlign="left"
            colon={false}
            style={{ marginBottom: 8 }}
        >
            <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
            </Radio.Group>
        </Form.Item>
    );
};
