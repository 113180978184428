import { useGetDealOriginsQuery } from "@app/core/services";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";

export const useDealOriginSelect = () => {
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const isReadonly = dealObjectMode === "view";
    const { data: dealOrigins, isFetching: isLoading } = useGetDealOriginsQuery(undefined, { skip: isReadonly });
    return {
        dealOrigins,
        isLoading,
        isReadonly,
    };
};
