import { FC } from "react";
import { ROUTES } from "@app/core/routing";
import { Route, Switch } from "react-router-dom";
import { InventoryHealthSwitch } from "@app/features/inventory/InventoryHealth";
import { InventoryFloorsSwitch } from "@app/features/inventory/InventoryFloors";
import { InventoryPermissions } from "@app/features/inventory/InventoryPermissions";
import InventoryBrandSafetySwitch from "@app/features/inventory/InventoryBrandSafety";
import InventorySeatSwitch from "@app/features/inventory/InventorySeat";

export const InventorySwitch: FC = () => {
    return (
        <Switch>
            <Route path={[ROUTES.SEAT_INVENTORY_HEALTH]}>
                <InventoryHealthSwitch />
            </Route>
            <Route path={ROUTES.SEAT_INVENTORY_SEAT}>
                <InventorySeatSwitch />
            </Route>
            <Route path={ROUTES.SEAT_INVENTORY_FLOOR}>
                <InventoryFloorsSwitch />
            </Route>
            <Route path={ROUTES.SEAT_INVENTORY_BRAND_SAFETY}>
                <InventoryBrandSafetySwitch />
            </Route>
            <Route path={ROUTES.SEAT_INVENTORY_PERMISSIONS}>
                <InventoryPermissions />
            </Route>
        </Switch>
    );
};
