import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Button, Space } from "antd";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { useCustomPixelDeleteModal } from "./useCustomPixelDeleteModal";
import { CustomPixelDeleteModal } from "./CustomPixelDeleteModal";

interface Props {
    seatId: string;
    customPixelId: number;
    name: string;
    postDeleteEvent?: () => void;
}
export const CustomPixelDetailsContentActions: FC<Props> = ({ seatId, customPixelId, name, postDeleteEvent }) => {
    const history = useHistory();
    const { handleOpen, ...restModalProps } = useCustomPixelDeleteModal(postDeleteEvent);

    return (
        <>
            <Space>
                <Button
                    data-sdet="edit-button"
                    type="primary"
                    onClick={() =>
                        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_CUSTOM_PIXELS_EDIT(seatId, customPixelId))
                    }
                >
                    Edit
                </Button>
                <Button
                    danger
                    data-sdet="delete-button"
                    type="primary"
                    onClick={() => handleOpen({ id: customPixelId, name })}
                >
                    Delete
                </Button>
            </Space>
            <CustomPixelDeleteModal {...restModalProps} />
        </>
    );
};
