import { FormInstance } from "antd";
import { FC } from "react";
import { ConfirmationStepBottomButtons } from "./ConfirmationStepBottomButtons";
import { getParentLevel } from "../BulkOperationCopyReview";
import { useBulkOperationFormRunButton } from "./useBulkOperationFormRunButton";

interface Props {
    goToPrev: () => void;
    form: FormInstance;
}

export const BulkOperationCopyConfirmationStepBottomButtons: FC<Props> = ({ goToPrev, form }) => {
    const { isLoading, handleSubmit } = useBulkOperationFormRunButton();

    const buildCopyPayload = () => {
        const { destinationEntityId, destinationEntityName, recursiveCopy, sourceEntity, sourceEntityId, type } =
            form.getFieldsValue(true);

        return {
            destinationEntity: getParentLevel(sourceEntity).toLowerCase(),
            destinationEntityId: destinationEntityId?.value,
            destinationEntityName,
            recursiveCopy,
            sourceEntity,
            sourceEntityId: sourceEntityId?.value,
            type,
        };
    };

    const onClickRun = () => {
        const bulkOperationCopyPayload = buildCopyPayload();
        handleSubmit(bulkOperationCopyPayload);
    };

    return <ConfirmationStepBottomButtons isLoading={isLoading} goToPrev={goToPrev} onClickRun={onClickRun} />;
};
