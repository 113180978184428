import { Col, Form, Row } from "antd";
import { FC } from "react";
import { SupplyLevelFitler } from "./SupplyLevelFilter";
import { PublisherFitler, BrandFitler, AdUnitFitler, SupplyFitler } from "./EntityFilters";
import { VastErrorsEntityTypes } from "@app/core/services/diagnostics/vastErrors/types";
import { NetworkFitler } from "./NetworkFilter";
interface Props {
    entityType: string;
    entityId: number | null;
    networks: { id: number; name: string }[];
    setNetworkFilter: (value: number) => void;
}

export const VastErrorsFitlers: FC<Props> = ({ entityType, entityId, networks, setNetworkFilter }) => {
    return (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col xs={4}>
                    <SupplyLevelFitler entityType={entityType} />
                </Col>
                <Col xs={4}>
                    {entityType === VastErrorsEntityTypes.Publisher && <PublisherFitler id={entityId} />}
                    {entityType === VastErrorsEntityTypes.Brand && <BrandFitler id={entityId} />}
                    {entityType === VastErrorsEntityTypes.Supply && <SupplyFitler id={entityId} />}
                    {entityType === VastErrorsEntityTypes.AdUnit && <AdUnitFitler id={entityId} />}
                </Col>
                <Col xs={4}>
                    <NetworkFitler networks={networks} setNetworkFilter={setNetworkFilter} />
                </Col>
            </Row>
        </Form>
    );
};
