import { FC } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { TagRender } from "@app/core/components";
import { Button, Col, Form, Row, Select, Typography } from "antd";
import { LimitInput } from "@app/features/inventory/components/Fields";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { TypeSelect } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm/ExchangeProtectionsFormSections/InventoryLimitsSection/Fields/AdSourceTypeLimitsField/TypeSelect";
import { useAdSourceTypeLimitsField } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm/ExchangeProtectionsFormSections/InventoryLimitsSection/Fields/AdSourceTypeLimitsField/useAdSourceTypeLimitsField";

export const AdSourceTypeLimitsField: FC = () => {
    const {
        limit,
        hidden,
        typeId,
        onChange,
        handleChangeType,
        handleChangeLimit,
        handleAddTypeLimit,
        adSourceCountLimitsOptions,
    } = useAdSourceTypeLimitsField();

    return (
        <Form.Item label={INVENTORY_FORM_FIELDS.COUNT_LIMITS.label}>
            <Row style={{ width: "100%", marginBottom: "0.3rem" }} gutter={[4, 4]} wrap>
                <Col xs={24} sm={13} md={10} lg={8} xl={7} xxl={5}>
                    <TypeSelect value={typeId} onChange={handleChangeType} />
                </Col>
                <Col>
                    <Typography.Text strong style={{ lineHeight: "32px" }}>
                        :
                    </Typography.Text>
                </Col>
                <Col xs={24} sm={5} md={5} lg={4} xl={4} xxl={3}>
                    <LimitInput value={limit} onChange={handleChangeLimit} toFixed={0} />
                </Col>
                <Col>
                    <Button
                        disabled={!typeId}
                        onClick={handleAddTypeLimit}
                        style={{ paddingLeft: "0.2rem" }}
                        icon={<PlusOutlined />}
                        type="link"
                        block
                    >
                        Add
                    </Button>
                </Col>
            </Row>
            <Form.Item
                hidden={hidden}
                style={{ marginBottom: 0 }}
                name={INVENTORY_FORM_FIELDS.COUNT_LIMITS.name}
                wrapperCol={{ xs: 24, sm: 24, md: 19, lg: 15, xl: 13, xxl: 10 }}
            >
                <Select
                    allowClear
                    labelInValue
                    open={false}
                    mode="multiple"
                    showSearch={false}
                    onChange={onChange}
                    tagRender={TagRender}
                    options={adSourceCountLimitsOptions}
                />
            </Form.Item>
        </Form.Item>
    );
};
