import { SnapshotsEntityTypes } from "@app/core/services/adStats/statsSnapshots";
import { EntityTypes } from "@app/features/adStats";
import { FC, useState } from "react";
import { Last24HoursStatsTabContent } from "./Last24HoursStatsTabContent";
import { LiveStatsTabContent } from "./LiveStatsTabContent";
import { PerformanceSectionContext } from "@app/features/inventory/InventoryHealth/InventoryHealthPage/PerformanceSection/usePerformanceSectionContext";

export interface PerformanceProps {
    adStatsEntity: EntityTypes;
    snapShotEntity: SnapshotsEntityTypes;
    id: number | string;
}
export const Performance: FC<PerformanceProps> = ({ adStatsEntity, snapShotEntity, id }) => {
    const [isLiveActive, setIsLiveActive] = useState(false);

    const handlePerformanceTypeSwitch = (isLiveActive: boolean) => {
        setIsLiveActive(isLiveActive);
    };

    return (
        <PerformanceSectionContext.Provider value={{ isLiveActive, handlePerformanceTypeSwitch }}>
            <>
                {!isLiveActive && (
                    <Last24HoursStatsTabContent
                        handlePerformanceTypeSwitch={handlePerformanceTypeSwitch}
                        id={id}
                        adStatsEntity={adStatsEntity}
                        snapShotEntity={snapShotEntity}
                    />
                )}
                {isLiveActive && (
                    <LiveStatsTabContent
                        handlePerformanceTypeSwitch={handlePerformanceTypeSwitch}
                        adStatsEntity={adStatsEntity}
                        id={id}
                    />
                )}
            </>
        </PerformanceSectionContext.Provider>
    );
};
