import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";

export const useOverrideFloorCpmInputLabel = (floorType: string): { fieldLabel: string; isMoney: boolean } => {
    switch (floorType) {
        case "Override":
            return { fieldLabel: AD_SOURCE_FIELDS.OVERRIDE_FLOOR_CPM.label, isMoney: true };
        case "Fallback":
            return { fieldLabel: AD_SOURCE_FIELDS.FALLBACK_FLOOR_CPM.label, isMoney: true };
        case "IncreaseCPM":
            return { fieldLabel: "Increase Floor by CPM", isMoney: true };
        case "DecreaseCPM":
            return { fieldLabel: "Decrease Floor by CPM", isMoney: true };
        case "IncreasePercent":
            return { fieldLabel: "Increase Floor by %", isMoney: false };
        case "DecreasePercent":
            return { fieldLabel: "Decrease Floor by %", isMoney: false };
        default:
            return { fieldLabel: AD_SOURCE_FIELDS.OVERRIDE_FLOOR_CPM.label, isMoney: true };
    }
};
