import { setUserPreferences } from "@app/core/authClient/reducer";
import { consoleApi, USERS_TAG, USER_GROUPS, USER_PREFERENCES_TAG } from "./console";
import { toQueryString } from "./utils";
import { RootState } from "@app/core/store";
import { CurrencyConversionModes, RevenueTypes } from "@app/core/clients/console";

export interface UserRole {
    roleType: {
        id: number;
        typeCode: string;
        typeName: string;
        userRoleTypeClass: {
            id: number;
            name: string;
            description: string;
            sort: number;
            dateCreated: string;
            lastUpdated: string;
        };
    };
}

export interface User {
    creationDate: string;
    id: number;
    emailAddress: string;
    firstName: string;
    lastName: string;
    countryCode: string;
    timezoneCode: string;
    currencyCode: string;
    localeCode: string;
    company: string;
    userRoles?: UserRole[];
    userPreferenceMap?: UserPreferenceMap;
    deleted?: boolean;
    name: string;
    localeDate?: string | null;
    lockoutMinutes?: string | null;
    numFailedAttempts?: unknown;
}

export interface UserPreference {
    creationDate: string;
    updateDate: string;
    id: number;
    key:
        | "adSourcesCurrencyConversionMode"
        | "adSourcesPreferredCurrency"
        | "currencyConversionMode"
        | "dashboardRevenueType"
        | "dealCurrencyConversionMode"
        | "dealPreferredCurrency"
        | "inventoryCurrencyConversionMode"
        | "inventoryPreferredCurrency"
        | "preferredCurrency"
        | "preferredSeat"
        | "preferredBuyer"
        | "timezone";
    value: string;
    entityType: "UserProperty";
}

export interface UserPreferenceMap {
    currencyConversionMode?: CurrencyConversionModes;
    dashboardRevenueType?: RevenueTypes;
    preferredCurrency?: string;
    timezone?: string;
    preferredBuyer?: number;
    preferredSeat?: number;
    waterfallStatusFilter?: string;
}

export interface UserParams {
    keyword: string;
    max?: number;
    page?: number;
}

export interface UpdateUserPreferencesArgs {
    id: number;
    body: Partial<UserPreference>[];
}

export interface UserGroupsParams {
    keyword?: string;
    max?: number;
    page?: number;
}

export interface UserGroups {
    id: number;
    name: string;
    description: string;
}

export const usersApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserPreferences: builder.query<UserPreference[], number>({
            query: (id: number) => `users/${id}/preferences`,
            providesTags: [USER_PREFERENCES_TAG],
        }),
        updateUserPreferences: builder.mutation<UserPreference[], UpdateUserPreferencesArgs>({
            query: ({ id, body }: UpdateUserPreferencesArgs) => ({
                url: `users/${id}/preferences`,
                method: "PUT",
                body: body,
            }),
            onQueryStarted: async (args: UpdateUserPreferencesArgs, { dispatch, queryFulfilled, getState }) => {
                const { data } = await queryFulfilled;
                const state = getState() as RootState;
                if (state.authClient.session?.user.id === args.id) {
                    dispatch(setUserPreferences(Object.fromEntries(data.map((param) => [param.key, param.value]))));
                }
            },
            invalidatesTags: (_, err) => (err ? [] : [USER_PREFERENCES_TAG]),
        }),
        getUsers: builder.query<User[], UserParams>({
            query: ({ ...rest }: UserParams) => {
                const params = {
                    max: 50,
                    page: 1,
                    ...rest,
                };
                return `users;${toQueryString(params)}`;
            },
            providesTags: [USERS_TAG],
        }),
        getUserGroups: builder.query<UserGroups[], UserGroupsParams>({
            query: (params: UserGroupsParams) => {
                const query = {
                    page: 1,
                    max: 50,
                    ...params,
                    filter: "any",
                };
                return `userGroups;${toQueryString(query)}`;
            },
            providesTags: [USER_GROUPS],
        }),
    }),
    overrideExisting: false,
});

export const { useGetUserPreferencesQuery, useUpdateUserPreferencesMutation, useGetUsersQuery, useGetUserGroupsQuery } =
    usersApi;
