import { Form } from "antd";
import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { useUserAccess } from "@app/core/auth";
import { useGetExternalCodeNamespacesQuery } from "@app/core/services";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

const { useWatch } = Form;

interface UseExternalCodeNamespaces {
    isFetching: boolean;
    options: LabeledValue[];
    hasInternalAccess: boolean;
    isSspConnectEnabled: boolean;
    externalCodeNamespacesShown: boolean;
}

export const useExternalCodeNamespaces = (): UseExternalCodeNamespaces => {
    const { isSysAdmin, isPubAcctMgr } = useUserAccess();
    const { data = [], isFetching } = useGetExternalCodeNamespacesQuery();
    const externalCodeNamespaces: LabeledValue[] = useMemo(
        () =>
            (data || []).map((externalCodeNamespace) => ({
                label: externalCodeNamespace.name,
                value: externalCodeNamespace.id,
            })),
        [data]
    );
    const adUnitCodeNamespaces = useWatch(INVENTORY_FORM_FIELDS.EXTERNAL_AD_UNIT_CODE_NAMESPACES.name) || [];
    const isSspConnectEnabled = useWatch(INVENTORY_FORM_FIELDS.SSP_CONNECT_ENABLED.name);
    const externalCodeNamespacesShown: boolean = isSspConnectEnabled && (isSysAdmin || isPubAcctMgr);
    const values = adUnitCodeNamespaces.map((namespace) => namespace?.value);
    const options = externalCodeNamespaces.filter((options) => !values.includes(options.value));

    return {
        options,
        isFetching,
        isSspConnectEnabled,
        externalCodeNamespacesShown,
        hasInternalAccess: isSysAdmin || isPubAcctMgr,
    };
};
