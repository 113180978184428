import { Typography } from "antd";
import { PageHeaderProps } from "@ant-design/pro-layout";
import { useSeatAuthContext } from "@app/core/auth";
import { StyledPageHeader } from "@app/features/inventory/styled";
import { FC } from "react";

const InventoryBrandSafetyToolbar: FC<PageHeaderProps> = (props) => {
    const { context } = useSeatAuthContext();

    return (
        <StyledPageHeader {...props} title={`Brand Safety Management: ${context?.name}`}>
            <Typography.Text>Manage or target brand safety across inventory and inventory levels</Typography.Text>
        </StyledPageHeader>
    );
};

export default InventoryBrandSafetyToolbar;
