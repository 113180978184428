import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { AdUnitExtendedImpWaitTime } from "@app/features/inventory/components/FormItems";

export const ExtendedImpWaitTime = () => {
    return (
        <AdUnitExtendedImpWaitTime
            name={CHANNEL_FORM_FIELDS.EXTENDED_IMP_WAIT_TIME.name}
            label={CHANNEL_FORM_FIELDS.EXTENDED_IMP_WAIT_TIME.label}
            wrapperCol={{ xs: 24, sm: 24, md: 10, lg: 6, xl: 11, xxl: 9 }}
            placeholder={CHANNEL_FORM_FIELDS.EXTENDED_IMP_WAIT_TIME.placeholder}
        />
    );
};
