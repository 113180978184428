import { FC } from "react";
import { Seat } from "@app/core/services";
import { Card, Form, Row, Col } from "antd";
import { getYesNoLabel } from "@app/core/utils/labels";
import { INVENTORY_CARD_BODY_STYLE } from "@app/features/inventory/constants";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import {
    Region,
    ClientType,
    SupportTier,
    AccountType,
} from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/SeatDetailsFormFields/GeneralInternalOnly/Fields";

interface GeneralInternalOnlyProps {
    seat: Seat;
    edit?: boolean;
}

const COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 };

export const GeneralInternalOnly: FC<GeneralInternalOnlyProps> = ({ seat, edit = false }) => {
    return (
        <Card title="Internal Only" type="inner" bodyStyle={INVENTORY_CARD_BODY_STYLE}>
            <Row gutter={16}>
                <Col {...COL_SIZES}>
                    <AccountType seat={seat} edit={edit} />
                </Col>
                <Col {...COL_SIZES}>
                    <ClientType seat={seat} edit={edit} />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col {...COL_SIZES}>
                    <Region seat={seat} edit={edit} />
                </Col>
                <Col {...COL_SIZES}>
                    <SupportTier seat={seat} edit={edit} />
                </Col>
            </Row>
            {!edit && (
                <Row gutter={16}>
                    <Col {...COL_SIZES}>
                        <Form.Item label={INVENTORY_FORM_FIELDS.IS_SUPPLY_SEAT.label}>
                            {getYesNoLabel(seat.isSupplySeat)}
                        </Form.Item>
                    </Col>
                    <Col {...COL_SIZES}>
                        <Form.Item label={INVENTORY_FORM_FIELDS.IS_DEMAND_SEAT.label}>
                            {getYesNoLabel(seat.isDemandSeat)}
                        </Form.Item>
                    </Col>
                </Row>
            )}
            <Row gutter={16}>
                <Col {...COL_SIZES}>
                    <Form.Item label={INVENTORY_FORM_FIELDS.IS_MVPD.label} name={INVENTORY_FORM_FIELDS.IS_MVPD.name}>
                        {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.isMvpd)}
                    </Form.Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Form.Item
                        label={INVENTORY_FORM_FIELDS.IS_FILL_GUARANTEED.label}
                        name={INVENTORY_FORM_FIELDS.IS_FILL_GUARANTEED.name}
                    >
                        {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.fillGuaranteed)}
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    );
};
