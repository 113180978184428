import { FC } from "react";
import { CurrencyInput } from "@app/core/components/CurrencyInput";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useDealRateField } from "./useDealRateField";

export const DealRateField: FC = () => {
    const { onBlur, currencyCode, isRequired, helpText } = useDealRateField();

    return (
        <CurrencyInput
            dataSdetPrefix="deal-rate"
            name={CREATE_DEAL_FORM_ITEMS_NAME.RATE}
            label="CPM Rate"
            help={helpText}
            onBlur={onBlur}
            currencyCode={currencyCode}
            required={isRequired}
        />
    );
};
