import { NONE } from "@app/core/components/constants";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { AdvertiserDetails } from "@app/core/services";
import { brandCobalt } from "@rubicon/antd-components";
import { Form, Tag, Typography } from "antd";
import { FC } from "react";
import { Link, useParams } from "react-router-dom";

export const CLASSIFICATIONS_ADVERTISER_DETAILS_CONTENT_SDET = "classifications-advertiser-details-content";

interface Props {
    advertiser?: AdvertiserDetails;
}

export const ClassificationsAdvertiserDetailsContent: FC<Props> = ({ advertiser }) => {
    const { seatId } = useParams<{ seatId: string }>();

    return advertiser ? (
        <Form layout="vertical" data-sdet={CLASSIFICATIONS_ADVERTISER_DETAILS_CONTENT_SDET}>
            <Form.Item label="Advertiser Name">
                <Typography.Text>{advertiser.name || NONE}</Typography.Text>
            </Form.Item>
            <Form.Item label="Advertiser Code">{advertiser.code || NONE}</Form.Item>
            <Form.Item label="Description">
                <Typography.Text>{advertiser.description || NONE}</Typography.Text>
            </Form.Item>
            {advertiser.parentAdvertiser && (
                <Form.Item label="Parent Advertiser">
                    <Link
                        to={ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISER_DETAILS_PAGE(
                            seatId,
                            advertiser.parentAdvertiser.id
                        )}
                        style={{ color: brandCobalt, fontWeight: "normal" }}
                    >
                        {advertiser.parentAdvertiser.name}
                    </Link>
                </Form.Item>
            )}
            <Form.Item label="Advertiser Domains">
                {advertiser.advertiserDomains.length
                    ? advertiser.advertiserDomains.map(({ adomain }) => <Tag key={adomain}>{adomain}</Tag>)
                    : NONE}
            </Form.Item>
            <Form.Item label="Advertiser Industry">
                {advertiser.industry?.name ? (
                    <Link
                        to={ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_DETAILS_PAGE(
                            seatId,
                            advertiser.industry.id
                        )}
                        style={{ color: brandCobalt, fontWeight: "normal" }}
                    >
                        {advertiser.industry.name}
                    </Link>
                ) : (
                    NONE
                )}
            </Form.Item>
        </Form>
    ) : null;
};
