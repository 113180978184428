import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useHistory, useParams } from "react-router-dom";
import { ADVANCED_FLOOR_SECTION_NAMES } from "../constants";

interface UseHelpKeysButton {
    onClick: () => void;
}

export const useHelpKeysButton = (
    sectionKey: ADVANCED_FLOOR_SECTION_NAMES,
    mode?: "create" | "edit"
): UseHelpKeysButton => {
    const { seatId, id } = useParams<{ seatId: string; id: string }>();
    const history = useHistory();

    const onClick = () => {
        switch (mode) {
            case "edit":
                return history.push(
                    ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_ADVANCED_EDIT_DEFINITION(seatId, id, sectionKey) as string
                );
            default:
                return history.push(
                    ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_ADVANCED_DEFINITION(seatId, sectionKey) as string
                );
        }
    };

    return {
        onClick,
    };
};
