import { FC } from "react";
import { Button, Col, Form, Input, Row, Typography } from "antd";
import { css } from "@emotion/css";
import { red5, gray10 } from "@rubicon/antd-components";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { Help } from "@rubicon/antd-components";
import { useDealIdField } from "@app/features/deals/DealForm/DealDemandFormSections/DealTermsSection/Fields/DealIdField/useDealIdField";
import { DealFormMode } from "@app/features/deals/DealForm/types";

export const isValidDealIdPattern = new RegExp(/^[a-zA-Z0-9_\-\.]+$/);
const maxIdLength = 48;

interface Props {
    mode: DealFormMode;
}

export const DealIdField: FC<Props> = ({ mode }) => {
    const { handleGenerateId, handleChange, value, handleBlur } = useDealIdField();
    const isEdit = mode === "edit";

    return (
        <Form.Item
            required={false}
            rules={[
                { required: true, message: "Deal ID is required" },
                {
                    pattern: isValidDealIdPattern,
                    message: "Deal ID may only contain hyphens, underscores, periods, and alpha-numeric characters",
                },
                {
                    max: maxIdLength,
                    message: `Deal ID cannot be longer than ${maxIdLength} characters`,
                },
            ]}
            name={CREATE_DEAL_FORM_ITEMS_NAME.ID}
            className={css(`
                label {
                    width: 100%;
                }
            `)}
            data-sdet="deal-id-field"
            label={
                <Row justify="space-between" align="middle" style={{ width: "100%" }}>
                    <Col>
                        <Typography.Text style={{ marginRight: "0.25rem", color: gray10 }}>Deal ID</Typography.Text>
                        <Help
                            placement="topRight"
                            text="The unique identifier associated with a ‘Deal’ entity. This is the ID that is sent to DSPs in ORTB ‘Bid Requests’."
                        />
                        <span style={{ color: red5, marginLeft: "0.125rem" }}>*</span>
                    </Col>
                    <Col>
                        <Button
                            data-sdet="deal-id-auto-generate"
                            disabled={isEdit}
                            onClick={handleGenerateId}
                            type="link"
                            size="small"
                        >
                            Auto Generate ID
                        </Button>
                    </Col>
                </Row>
            }
        >
            <Input
                data-sdet="deal-id-input"
                disabled={isEdit}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        </Form.Item>
    );
};
