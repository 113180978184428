import { Form, Select } from "antd";
import { useDealOriginField } from "./useDealOriginField";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useUserAccess } from "@app/core/auth";
import { brandCobalt } from "@rubicon/antd-components";

export const DealOriginField = () => {
    const { isFetching, options, value, handleSelect } = useDealOriginField();
    const { hasInternalAccess } = useUserAccess();

    if (!hasInternalAccess) {
        return null;
    }

    return (
        <Form.Item
            data-sdet="deal-origin-field"
            rules={[{ required: true, message: "Deal Origin is required" }]}
            name={CREATE_DEAL_FORM_ITEMS_NAME.ORIGIN}
            label="Deal Origin"
            tooltip={{
                placement: "topLeft",
                title: "Origination source for the deal. Primarily for reporting purposes.",
                icon: <QuestionCircleOutlined style={{ color: brandCobalt }} />,
            }}
        >
            <Select
                allowClear
                loading={isFetching}
                onSelect={handleSelect}
                optionFilterProp="label"
                options={options}
                placeholder="Select an Origin"
                showSearch
                value={value}
            />
        </Form.Item>
    );
};
