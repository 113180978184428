import { MINUTES_IN } from "@app/core/components/constants";

export const CAPPING_MODES = {
    FILLS: {
        id: 1,
        name: "Fills",
    },
    IMPRESSIONS: {
        id: 2,
        name: "Impressions",
    },
};

export const FCAP_TIME_OPTIONS = [
    { label: "Minutes", value: 1 },
    { label: "Hours", value: MINUTES_IN.HOUR },
    { label: "Days", value: MINUTES_IN.DAY },
    { label: "Weeks", value: MINUTES_IN.WEEK },
    { label: "Months", value: MINUTES_IN.MONTH },
];

export const FCAP_MODES_OPTIONS: { label: string; value: keyof typeof CAPPING_MODES }[] = [
    { label: CAPPING_MODES.FILLS.name, value: "FILLS" },
    { label: CAPPING_MODES.IMPRESSIONS.name, value: "IMPRESSIONS" },
];
