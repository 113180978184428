import { Form } from "antd";
import { FC, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ClassificationsAdvertiserDetailsDrawerTitle } from "./ClassificationsAdvertisersDetailsDrawer/ClassificationsAdvertiserDetailsDrawerTitle";
import { ClassificationsAdvertiserDetailsContent } from "./ClassificationsAdvertisersDetailsDrawer";
import { useGetAdvertiserDetailsQuery } from "@app/core/services";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Loading } from "@app/core/components";
import { ClassificationsAdvertiserChangeHistoryDrawerTitle } from "./ClassificationsAdvertiserChangeHistoryDrawerTitle";
import { ClassificationsAdvertiserChangeHistoryTable } from "../../ClassificationsAdvertiserChangeHistoryTable";
import {
    ClassificationsAdvertiserDomainsDrawerContent,
    ClassificationsAdvertiserDomainsDrawerTitle,
} from "./ClassificationsAdvertiserDomainsDrawer";
import {
    ClassificationsAdvertiserTransferDrawerContent,
    ClassificationsAdvertiserTransferDrawerFooter,
    ClassificationsAdvertiserTransferDrawerTitle,
    useClassificationsAdvertiserTransferDrawer,
} from "./ClassificationsAdvertiserTransferDrawer";
import { uri } from "@rubicon/utils";
import { useUserAccess } from "@app/core/auth";
import { HelperKeysDrawerBreadcrumbHeader } from "@app/core/components/HelperKeysDrawerBreadcrumbHeader";
import { ClassificationsAdvertiserDefinitionsContent } from "../ClassificationsAdvertiserDefinitions";
import { ResizableDrawer } from "@rubicon/antd-components";
import { drawerRightClosePosition } from "@app/features/controls/constants";

export const removeAdvertiserDrawerParamsFromSearch = (queryString: string) => {
    const removeDrawerParam = uri.deleteSearchParam(queryString, "drawer");
    const nextQueryString = uri.deleteSearchParam(removeDrawerParam, "advertiserId");
    return nextQueryString;
};

interface Props {
    showDirectLabel?: boolean;
}

export const ClassificationsAdvertisersTabDrawer: FC<Props> = ({ showDirectLabel = false }) => {
    const history = useHistory();
    const { search: queryString } = useLocation();

    const drawerParam = useMemo(() => uri.getSearchParam(queryString, "drawer"), [queryString]);
    const advertiserIdQueryParam = useMemo(() => uri.getSearchParam(queryString, "advertiserId"), [queryString]);
    const { advertiserId: advertiserIdParam } = useParams<{ advertiserId?: string }>();
    const advertiserId = advertiserIdParam || advertiserIdQueryParam;

    const advertiserQuery = Boolean(drawerParam) && advertiserId ? Number(advertiserId) : skipToken;
    const { data: advertiser, isLoading: isLoadingAdvertiserDetails } = useGetAdvertiserDetailsQuery(advertiserQuery);
    const [form] = Form.useForm();
    const isLoading = isLoadingAdvertiserDetails;
    const onClose = () => {
        const nextQueryString = removeAdvertiserDrawerParamsFromSearch(queryString);
        history.push({ search: nextQueryString });
        form.resetFields();
    };
    const { onFinish, isTransfering, onSave } = useClassificationsAdvertiserTransferDrawer(form, onClose);
    const { canEditSeat, isSeatAdmin } = useUserAccess();

    return (
        <ResizableDrawer
            open={Boolean(
                drawerParam && ["details", "definitions", "history", "adomains", "transfer"].includes(drawerParam)
            )}
            onClose={onClose}
            placement="right"
            title={
                <>
                    {drawerParam === "details" && (
                        <ClassificationsAdvertiserDetailsDrawerTitle advertiser={advertiser} />
                    )}
                    {drawerParam === "history" && isSeatAdmin && (
                        <ClassificationsAdvertiserChangeHistoryDrawerTitle
                            advertiser={advertiser}
                            showDirectLabel={showDirectLabel}
                        />
                    )}
                    {drawerParam === "adomains" && (
                        <ClassificationsAdvertiserDomainsDrawerTitle advertiserId={advertiser?.id} />
                    )}
                    {drawerParam === "transfer" && canEditSeat && (
                        <ClassificationsAdvertiserTransferDrawerTitle advertiserName={advertiser?.name} />
                    )}
                    {drawerParam === "definitions" && (
                        <HelperKeysDrawerBreadcrumbHeader
                            parentLabel={advertiser?.name}
                            childLabel="Advertisers Definitions"
                        />
                    )}
                </>
            }
            footer={
                drawerParam === "transfer" && (
                    <ClassificationsAdvertiserTransferDrawerFooter
                        onSave={onSave}
                        isLoading={isTransfering}
                        onCancel={onClose}
                    />
                )
            }
            className={drawerRightClosePosition}
            width={drawerParam === "history" ? "70%" : "45%"}
        >
            {isLoading && <Loading />}
            {drawerParam === "details" && <ClassificationsAdvertiserDetailsContent advertiser={advertiser} />}
            {drawerParam === "history" && isSeatAdmin && (
                <ClassificationsAdvertiserChangeHistoryTable advertiserId={advertiser?.id} />
            )}
            {drawerParam === "adomains" && <ClassificationsAdvertiserDomainsDrawerContent advertiser={advertiser} />}
            {drawerParam === "transfer" && canEditSeat && (
                <ClassificationsAdvertiserTransferDrawerContent
                    advertiser={advertiser}
                    form={form}
                    onFinish={onFinish}
                />
            )}
            {drawerParam === "definitions" && <ClassificationsAdvertiserDefinitionsContent />}
        </ResizableDrawer>
    );
};
