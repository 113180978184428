import { FC } from "react";
import { LoadingCell } from "./LoadingCell";

interface Props {
    value: number | string | JSX.Element;
    isLoading?: boolean;
}

export const LoadableCell: FC<Props> = ({ value, isLoading = value === undefined }) => {
    if (isLoading) {
        return <LoadingCell />;
    }

    return <span data-sdet="loadable-cell">{value}</span>;
};
