import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "@app/core/store";
import { useSeatAuthContext } from "@app/core/auth";
import { selectHistoryAdServingStats } from "./reducer";
import { AdServingStatHistoryById } from "./types";
import { AdSourceAdServingAdStatsWebSocket } from "./AdSourceAdServingAdStatsWebSocket";

export const useHistoryAdSourceAdServingAdStatsById = (adSourceIds: number[]): AdServingStatHistoryById => {
    const { session } = useSeatAuthContext();
    const sessionRef = useRef(session);
    const [adSourceAdServingAdStatsSocket, setAdSourceAdServingAdStatsSocket] =
        useState<AdSourceAdServingAdStatsWebSocket | null>(null);
    const adStatsById = useAppSelector(selectHistoryAdServingStats(adSourceIds));
    const adSourceIdsRef = useRef<number[]>([]);

    useEffect(() => {
        if (!sessionRef.current) {
            console.error("Attempted to connect to history ad source ad serving ad stats without a valid session.");
            return;
        }

        const socketHandle = AdSourceAdServingAdStatsWebSocket.getInstance(sessionRef.current);
        setAdSourceAdServingAdStatsSocket(socketHandle);

        return () => {
            if (socketHandle) {
                socketHandle.historySubscriberFinished(adSourceIdsRef.current);
            }
        };
        // Intentional empty dependency array to only run this effect once for initialization.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (adSourceAdServingAdStatsSocket) {
            const addedAdSourceIds = adSourceIds.filter(
                (adSourceId: number) => !adSourceIdsRef.current.includes(adSourceId)
            );
            const removedAdSourceIds = adSourceIdsRef.current.filter(
                (adSourceId: number) => !adSourceIds.includes(adSourceId)
            );

            const hasAdSourceIdUpdates = addedAdSourceIds.length > 0 || removedAdSourceIds.length > 0;
            if (hasAdSourceIdUpdates) {
                adSourceAdServingAdStatsSocket.updateHistoryAdStatSubscriptions(addedAdSourceIds, removedAdSourceIds);
            }

            adSourceIdsRef.current = adSourceIds;
        }
    }, [adSourceIds, adSourceAdServingAdStatsSocket]);

    return adStatsById;
};
