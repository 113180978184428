import { FC } from "react";
import { Button, Flex } from "antd";

interface Props {
    id: number;
    name: string;
    handleEdit: (id: number) => void;
    handleClose: () => void;
    handleDeleteModal?: ({ id, name }: { id: number; name: string }) => void;
}

export const BrandSafetyDetailsDrawerActions: FC<Props> = ({
    id,
    name,
    handleEdit,
    handleClose,
    handleDeleteModal,
}) => {
    const hadleEditCopyClick = (id: number): void => {
        handleClose();
        handleEdit(id);
    };

    return (
        <Flex gap="small">
            <Button type="primary" onClick={() => hadleEditCopyClick(id)}>
                Edit
            </Button>
            <Button danger data-sdet="delete-button" type="primary" onClick={() => handleDeleteModal?.({ id, name })}>
                Delete
            </Button>
        </Flex>
    );
};
