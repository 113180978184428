import { Checkbox, Form } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useDealConsentField } from "@app/features/deals/DealForm/DealDemandFormSections/ConfigureAdSourceShownSection/Fields/DealConsentField/useDealConsentField";

export const DealConsentField = () => {
    const { value, handleChange, configureAdSource } = useDealConsentField();
    return (
        <Form.Item
            data-sdet="deal-consent-field"
            name={CREATE_DEAL_FORM_ITEMS_NAME.CONSENT}
            valuePropName="checked"
            rules={[
                {
                    validator: async (_, value): Promise<void> => {
                        if (!value && !configureAdSource) {
                            return Promise.resolve();
                        } else if (!value) {
                            return Promise.reject(new Error("Consent should be accepted"));
                        }
                    },
                },
            ]}
        >
            <Checkbox data-sdet="deal-consent-checkbox" checked={value} value={value} onChange={handleChange}>
                You acknowledge that by selecting not to configure an ad source will prevent this deal to go live. You
                must attach an ad source
            </Checkbox>
        </Form.Item>
    );
};
