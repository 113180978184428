import axios from "axios";
import conf from "@app/core/conf";

const createClient = (baseURL: string) => {
    const client = axios.create({
        baseURL,
        withCredentials: true,
    });

    return client;
};

const client = createClient(conf.mctvPlatformApiRoot);

export default client;
