import { useMemo } from "react";

//INFO: This hook is used to get additional path for Iframe src path.
//example is in this file: UserPreferencesIframe
export const useGetDeepLink = (pathname: string, afterPrefix: string): string => {
    const deepLink = useMemo(() => {
        const parts = pathname.split("/");
        const index = parts.indexOf(afterPrefix);

        return parts.slice(index + 1).reduce((acc, part) => (acc += `/${part}`), "");
    }, [pathname, afterPrefix]);

    if (!pathname.length || !afterPrefix.length) {
        return "";
    }

    return deepLink;
};
