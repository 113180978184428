import { useSeatAuthContext } from "@app/core/auth";
import { Loading } from "@app/core/components";
import { Layout } from "antd";
import { FC } from "react";
import { SeatDealLibraryPage } from "./DealLibraryPage";

export const DealLibraryHome: FC = () => {
    const { context, session } = useSeatAuthContext();

    if (!context || !session) {
        return (
            <Layout.Content>
                <Loading />
            </Layout.Content>
        );
    }
    return <SeatDealLibraryPage />;
};
