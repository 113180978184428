import { CreativeSampleItem, CreativeSamples } from "@app/core/services";
import { CreativeItem } from "./CreativeReview";

export const useGetCreativeLoudness = (loudness?: number) => {
    if (loudness === undefined || loudness === null) {
        return "--";
    }

    if (loudness < -40) {
        return `Quiet ${loudness} LUFS`;
    }

    if (loudness < -20) {
        return `Moderate ${loudness} LUFS`;
    }

    return `Loud ${loudness} LUFS`;
};
export const millisToMinutesAndSeconds = (millis: number) => {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (+seconds < 10 ? "0" : "") + seconds;
};

export const bytesToSize = (bytes: number, decimals: number) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1000,
        dm = decimals || 2,
        sizes = ["B", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)).toFixed(0) + " " + sizes[i] + "ps";
};

export const parseCreativeSamplesFromApi = (creativeSamples: CreativeSamples) =>
    Object.entries(creativeSamples).reduce((acc, [creativeTypeName, networks]: [string, CreativeSampleItem]) => {
        Object.entries(networks).forEach(([creativeNetworkName, creatives]) => {
            Object.entries(creatives).forEach(([creativeName, value]) => {
                const parsedCreativeNetworkName = creativeNetworkName.split("|").join(" - ");
                const domain = creativeNetworkName.split("|").at(-1) || "";
                const fullCreativeName = `${parsedCreativeNetworkName} - ${creativeName}`;

                acc.push({
                    name: fullCreativeName,
                    type: creativeTypeName,
                    domain,
                    ...value,
                });
            });
        });
        return acc;
    }, [] as CreativeItem[]);
