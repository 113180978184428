import { Form, Input } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { isValidEmail } from "@rubicon/utils";
import { useDealPublisherEmailField } from "@app/features/deals/DealForm/DealDemandFormSections/DealTermsSection/Fields/DealPublisherEmailField/useDealPublisherEmailField";

export const DealPublisherEmailField = () => {
    const { value, handleChange, handleBlur } = useDealPublisherEmailField();
    return (
        <Form.Item
            data-sdet="deal-publisher-email-field"
            label="Publisher Email"
            name={CREATE_DEAL_FORM_ITEMS_NAME.PUBLISHER_EMAIL}
            rules={[
                {
                    validator(_, value) {
                        if (!value || isValidEmail.isValid(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error("Invalid email address"));
                    },
                },
            ]}
        >
            <Input data-sdet="deal-publisher-email-input" value={value} onChange={handleChange} onBlur={handleBlur} />
        </Form.Item>
    );
};
