import { MarketplaceInfo } from "@app/core/services";
import { ReadonlyControl } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { Col, Form, InputNumber } from "antd";
import { ChangeEvent, FC, useCallback, useState } from "react";
import { DEAL_TYPES } from "../../../DealTermsSection/Fields";
import { useSyncedFieldsProducer } from "@app/features/syncedFields";

export const ImpressionsInput: FC = () => {
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const isReadonly = dealObjectMode === "view";
    const dealType = Form.useWatch<MarketplaceInfo["deal"]["dealType"]>(["dealUnderEdit", "dealType"]);
    const isVisible = dealType?.name === DEAL_TYPES.DEAL_TYPE_PG.name;

    const { updateSyncedFields } = useSyncedFieldsProducer();
    const [isDirty, setIsDirty] = useState<boolean>(false);

    const onChange = useCallback(() => {
        setIsDirty(true);
    }, []);

    const onBlur = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            if (isDirty) {
                updateSyncedFields({ impressions: event.target.value });
                setIsDirty(false);
            }
        },
        [isDirty, updateSyncedFields]
    );

    if (!isVisible) {
        return null;
    }

    return (
        <Col sm={12}>
            <Form.Item
                data-sdet="deal-impressions-field"
                label="Impressions"
                rules={[{ required: !isReadonly, message: "Impressions is required" }]}
                name={["dealUnderEdit", "bookingVolume"]}
            >
                {isReadonly ? (
                    <ReadonlyControl<MarketplaceInfo["deal"]["bookingVolume"]> getLabel={(volume) => String(volume)} />
                ) : (
                    <InputNumber
                        style={{ width: "100%" }}
                        placeholder="Impressions"
                        onChange={onChange}
                        onBlur={onBlur}
                    />
                )}
            </Form.Item>
        </Col>
    );
};
