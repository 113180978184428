import { FC } from "react";
import { Form } from "antd";
import { Publisher } from "@app/core/services";
import { FormLayout } from "antd/lib/form/Form";
import { FORM_GRID_OPTIONS } from "../BrandSafetyPublisherPage/constants";
import {
    BlockedAdvertiserDomainListFormItem,
    BlockedAdvertiserDomainsFormItem,
    BlockedBuyerSeatListFormItem,
} from "./ViewFormItems";

interface Props {
    publisher: Publisher;
    layout?: FormLayout;
}

export const BrandSafetyPublisherForm: FC<Props> = ({ publisher, layout = "horizontal" }) => {
    return (
        <Form layout={layout} {...FORM_GRID_OPTIONS[layout]} labelAlign="left">
            <BlockedAdvertiserDomainListFormItem
                seatAdvertiserDomainFilterListDefs={publisher.seatAdvertiserDomainFilterListDefs}
            />
            <BlockedAdvertiserDomainsFormItem advertiserBlockDomains={publisher.advertiserBlockDomains} />
            <BlockedBuyerSeatListFormItem buyerSeatList={publisher.buyerSeatList} />
        </Form>
    );
};
