import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { css } from "@emotion/css";
import { Form } from "antd";
import { MomentDatePicker } from "@app/core/components/MomentDatePicker";
import { FC } from "react";
import { useIsDatePickerReadOnly } from "../hooks/useIsDatePickerReadOnly";
import { disablePastDates } from "@app/core/utils/disablePastDates";
import { useSyncedFieldsProducer } from "@app/features/syncedFields/useSyncedFieldsProducer";
import moment from "moment-timezone";
import { useIsAdSourceStartDateApiReadOnly } from "./useIsAdSourceStartDateApiReadOnly";

export const StartDatePicker: FC = () => {
    const isApiReadOnly = useIsAdSourceStartDateApiReadOnly();
    const isReadOnly = useIsDatePickerReadOnly();
    const { updateSyncedFields } = useSyncedFieldsProducer();

    return (
        <Form.Item
            data-sdet="adsource-start-date"
            rules={[{ required: true, message: `${AD_SOURCE_FIELDS.START_DATE.label} is required` }]}
            label={AD_SOURCE_FIELDS.START_DATE.label}
            name={AD_SOURCE_FIELDS.START_DATE.name}
            help={isReadOnly ? "Inherited from Demand Deal" : undefined}
            {...VERTICAL_LAYOUT_FIX}
        >
            <MomentDatePicker
                disabled={isReadOnly || isApiReadOnly}
                data-sdet={`test-${AD_SOURCE_FIELDS.START_DATE.name}-date-picker`}
                allowClear
                showTime
                className={css(`
                .ant-picker-input>input::placeholder {
                        color: gray;
                     }
                `)}
                placeholder={`Select ${AD_SOURCE_FIELDS.START_DATE.label}`}
                style={{ width: "100%" }}
                disabledDate={disablePastDates}
                onChange={(startDate: moment.Moment | null) => {
                    updateSyncedFields({ startDate });
                }}
            />
        </Form.Item>
    );
};
