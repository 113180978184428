import { Route, Switch } from "react-router-dom";
import { AdSourcesDetailsDrawer } from "../SeatAdSourcesDetailsDrawer/AdSourcesDetailsDrawer";
import { ROUTES } from "@app/core/routing";

export const AdSourceDetailsDrawerWrapper = () => {
    return (
        <>
            <Switch>
                <Route
                    exact
                    path={[
                        ROUTES.SEAT_AD_SOURCES_DETAILS_PAGE_DETAILS_DRAWER,
                        ROUTES.SEAT_AD_SOURCES_DETAILS_PAGE_SECONDARY_VIEW,
                    ]}
                >
                    <AdSourcesDetailsDrawer />
                </Route>
            </Switch>
        </>
    );
};
