import { CREATIVE_SAMPLES_RAW, diagnosticsApi, CREATIVE_SAMPLES } from "./diagnostics";

interface CreativeSampleInnerItem {
    [key: string]: {
        uhash: string;
        reqId: string;
        sampleTime: number;
    };
}
export interface CreativeSampleItem {
    [key: string]: CreativeSampleInnerItem;
}
export interface CreativeSamples {
    "video/mp4"?: CreativeSampleItem;
    "video/quicktime"?: CreativeSampleItem;
}
interface CreativeSampleResponse {
    ntime: number;
    otime: number;
    creativeSamples: CreativeSamples;
}

export const enum CreativeSamplesEntityTypes {
    AdSource = "adSources",
    Seat = "seats",
    Publisher = "publishers",
    Brand = "brands",
    Supply = "supply",
    AdUnit = "adUnits",
}

export interface CreativeSamplesProps {
    entityType: CreativeSamplesEntityTypes;
    id?: number;
    isDemo: boolean;
}

export const creativeSamplesApi = diagnosticsApi.injectEndpoints({
    endpoints: (builder) => ({
        getCreativeSamples: builder.query<CreativeSampleResponse, CreativeSamplesProps>({
            query: ({ id, isDemo, entityType }) =>
                isDemo ? "demo/creativeSamples" : `${entityType}/${id}/creativeSamples/latest`,
            providesTags: (_, err, id) => (err ? [] : id ? [CREATIVE_SAMPLES] : [{ id, type: CREATIVE_SAMPLES }]),
        }),
        getRawCreativeSamples: builder.query<Document, { sampleTime: number; reqId: string }>({
            query: ({ sampleTime, reqId }) => ({
                url: `rawCreativeSamples/${sampleTime}/vast/${reqId}`,
                responseHandler: (response) => {
                    return response?.text().then((str) => new window.DOMParser().parseFromString(str, "text/xml"));
                },
                providesTags: (_, __, { reqId, sampleTime }) => [{ type: CREATIVE_SAMPLES_RAW, reqId, sampleTime }],
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useGetRawCreativeSamplesQuery, useGetCreativeSamplesQuery } = creativeSamplesApi;
