import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import conf from "@app/core/conf";

export const AD_SOURCE_STATS_DEALS = "AdSourceStatsDealsTag";

export const adSourceStatsApi = createApi({
    reducerPath: "adSourceStats",
    tagTypes: [AD_SOURCE_STATS_DEALS],
    baseQuery: fetchBaseQuery({
        baseUrl: `${conf.mctvAdSourceStatsApiRoot}/cast/resources/stats/`,
        credentials: "include",
        prepareHeaders: (headers) => {
            return headers;
        },
    }),
    endpoints: () => ({}),
});
