import { FC } from "react";
import { Badge, Button, Col, Collapse, Form, Row, Space, Typography } from "antd";
import { gray8 } from "@rubicon/antd-components";
import { css } from "@emotion/css";
import { useSeatAdSourcesPacingTableFilters } from "../SeatAdSourcesPacingTableFilters/useSeatAdSourcesPacingTableFilters";
import { SeatAdSourcesPacingLabelKeyFilter } from "./SeatAdSourcesPacingLabelKeyFilter";
import { SeatAdSourcesPacingStatusFilter } from "./SeatAdSourcesPacingStatusFilter";
import { SeatAdSourcesPacingAssigneeFilter } from "./SeatAdSourcesPacingAssigneeFilter";
import { SeatAdSourcesPacingLabelValueFilter } from "./SeatAdSourcesPacingLabelValueFilter";
import { SeatAdSourcesPacingDspFilter } from "./SeatAdSourcesPacingDspFilter";
import { SeatAdSourcesPacingDealFilter } from "./SeatAdSourcesPacingDealFilter";

export const SeatAdSourcesPacingTableFilters: FC = () => {
    const { filterCount, handleClearFilters } = useSeatAdSourcesPacingTableFilters();

    return (
        <div data-sdet="seat-ad-sources-pacing-table-filters">
            <Collapse data-sdet="hello-world" collapsible="header" defaultActiveKey="filters">
                <Collapse.Panel
                    key="filters"
                    header={
                        <Space>
                            <Typography.Text>Filters</Typography.Text>
                            <Badge count={filterCount} style={{ backgroundColor: gray8 }} />
                        </Space>
                    }
                    extra={
                        <Button size="small" onClick={handleClearFilters}>
                            Clear
                        </Button>
                    }
                >
                    <Form layout="vertical">
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} lg={8}>
                                <SeatAdSourcesPacingDspFilter />
                            </Col>
                            <Col xs={24} sm={12} lg={8}>
                                <SeatAdSourcesPacingStatusFilter />
                            </Col>
                            <Col xs={0} sm={0} lg={8} />
                            <Col xs={24} sm={12} lg={8}>
                                <SeatAdSourcesPacingDealFilter />
                            </Col>
                            <Col xs={24} sm={12} lg={8}>
                                <SeatAdSourcesPacingAssigneeFilter />
                            </Col>
                            <Col xs={0} sm={0} lg={8} />
                            <Col xs={24} lg={24} sm={24}>
                                <div
                                    className={css`
                                        .ant-space-compact {
                                            > :first-child {
                                                width: 20%;
                                            }
                                            > :last-child {
                                                width: 80%;
                                            }
                                        }
                                    `}
                                >
                                    <Space.Compact block direction="horizontal">
                                        <SeatAdSourcesPacingLabelKeyFilter />
                                        <SeatAdSourcesPacingLabelValueFilter />
                                    </Space.Compact>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};
