import { ClassificationsAdvertiserForm } from "./ClassificationsAdvertiserForm";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useHistory, useParams } from "react-router-dom";
import { ClassificationsAdvertiserDefinitionsButtonAndDrawer } from "./ClassificationsAdvertisersTab/ClassificationsAdvertiserDefinitions";
import { PageHeader } from "@ant-design/pro-layout";

export const ClassificationAdvertiserCreatePage = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();

    return (
        <>
            <PageHeader
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_TABS(seatId, "advertisers"))}
                title="Create New Advertiser"
                extra={<ClassificationsAdvertiserDefinitionsButtonAndDrawer />}
            />
            <ClassificationsAdvertiserForm />
        </>
    );
};
