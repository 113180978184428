import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { AdStat } from "@app/features/adStats";
import { RootState } from "@app/core/store";
import { DEFAULT_TIME_ZONE } from "@app/core/components/constants";

export interface AdStatSubscriptions {
    subscribes: number[];
    unsubscribes: number[];
}

export interface SocketSession {
    code: string;
    userEmail: string;
}

interface LiveAdStatByAdSourceId {
    [key: number]: AdStat;
}

interface AdStatHistoryByAdSourceId {
    [key: number]: AdStat[];
}

interface SubscriberCountByAdSourceId {
    [key: number]: number;
}

interface AdStatState {
    hasPendingSubscriptionUpdates: boolean;
    isAwaitingNextResponse: boolean;
    subscriptions: SubscriberCountByAdSourceId;
}

interface LiveAdStatState extends AdStatState {
    adStats: LiveAdStatByAdSourceId;
}

interface HistoryAdStatState extends AdStatState {
    adStats: AdStatHistoryByAdSourceId;
}

export enum ConnectionState {
    ERROR,
    AUTHENTICATION_FAILED,
    CLOSED,
    CONNECTING,
    CONNECTED,
    AUTHENTICATING,
    AUTHENTICATED,
}

interface AdSourceAdStatsState {
    apiLogMessages: string[];
    connectionState: ConnectionState;
    socketSession: SocketSession | null;
    socketError: Error | null;
    socketSubscriberCount: number;
    timeZoneCode: string;
    live: LiveAdStatState;
    history: HistoryAdStatState;
}

const initialState = (): AdSourceAdStatsState => ({
    apiLogMessages: [],
    connectionState: ConnectionState.CLOSED,
    socketSession: null,
    socketError: null,
    socketSubscriberCount: 0,
    timeZoneCode: DEFAULT_TIME_ZONE.code,
    live: {
        hasPendingSubscriptionUpdates: false,
        isAwaitingNextResponse: false,
        subscriptions: {},
        adStats: {},
    },
    history: {
        hasPendingSubscriptionUpdates: false,
        isAwaitingNextResponse: false,
        subscriptions: {},
        adStats: {},
    },
});

const adStatsAdSourceSlice = createSlice({
    name: "adStatsAdSource",
    initialState: initialState(),
    reducers: {
        addSocketSubscriber: (state) => {
            state.socketSubscriberCount += 1;
        },
        removeSocketSubscriber: (state) => {
            state.socketSubscriberCount -= 1;
        },
        allSocketSubscribersFinished: (state) => {
            const initState = initialState();
            state.apiLogMessages = initState.apiLogMessages;
            state.connectionState = initState.connectionState;
            state.socketSession = initState.socketSession;
            state.socketError = initState.socketError;
            state.socketSubscriberCount = initState.socketSubscriberCount;
            state.live = initState.live;
            state.history = initState.history;
        },
        socketConnecting: (state) => {
            state.connectionState = ConnectionState.CONNECTING;
        },
        socketOpened: (state) => {
            state.connectionState = ConnectionState.CONNECTED;
        },
        socketClosed: (state) => {
            state.connectionState = ConnectionState.CLOSED;
        },
        socketFailed: (state, action: PayloadAction<Error>) => {
            state.connectionState = ConnectionState.ERROR;
            state.socketError = action.payload;
        },
        apiAuthenticating: (state) => {
            state.connectionState = ConnectionState.AUTHENTICATING;
        },
        apiAuthenticated: (state, action: PayloadAction<boolean>) => {
            const apiAuthenticationResult = action.payload;
            state.connectionState = apiAuthenticationResult
                ? ConnectionState.AUTHENTICATED
                : ConnectionState.AUTHENTICATION_FAILED;
        },
        apiReauthenticating: (state) => {
            const currentState = current(state);
            const hasLivePendingSubscriptionUpdates = currentState.live.hasPendingSubscriptionUpdates;
            state.live.hasPendingSubscriptionUpdates =
                hasLivePendingSubscriptionUpdates || hasActiveLiveSubscriptions(currentState);

            const hasHistoryPendingSubscriptionUpdates = currentState.history.hasPendingSubscriptionUpdates;
            state.history.hasPendingSubscriptionUpdates =
                hasHistoryPendingSubscriptionUpdates || hasActiveHistorySubscriptions(currentState);
        },
        updateLiveSubscriptions: (state, action: PayloadAction<AdStatSubscriptions>) => {
            const subscriptionUpdates = action.payload;
            const subscriptions = Object.assign({}, current(state).live.subscriptions);

            let hasNewSubscribe = false;
            subscriptionUpdates.subscribes.forEach((adSourceId) => {
                if (subscriptions[adSourceId]) {
                    subscriptions[adSourceId] = subscriptions[adSourceId] + 1;
                } else {
                    hasNewSubscribe = true;
                    subscriptions[adSourceId] = 1;
                }
            });

            let hasNewUnsubscribe = false;
            subscriptionUpdates.unsubscribes.forEach((adSourceId) => {
                if (subscriptions[adSourceId]) {
                    if (subscriptions[adSourceId] > 1) {
                        subscriptions[adSourceId] = subscriptions[adSourceId] - 1;
                    } else {
                        hasNewUnsubscribe = true;
                        delete subscriptions[adSourceId];
                    }
                }
            });

            state.live.hasPendingSubscriptionUpdates = hasNewSubscribe || hasNewUnsubscribe;
            state.live.subscriptions = subscriptions;
        },
        liveSubscriptionsSent: (state) => {
            state.live.hasPendingSubscriptionUpdates = false;
            if (hasActiveLiveSubscriptions(current(state))) {
                state.live.isAwaitingNextResponse = true;
            }
        },
        liveAdStatsReceived: (state, action: PayloadAction<{ [id: number]: AdStat }>) => {
            state.live.adStats = action.payload;
            state.live.isAwaitingNextResponse = false;
        },
        updateHistorySubscriptions: (state, action: PayloadAction<AdStatSubscriptions>) => {
            const subscriptionUpdates = action.payload;
            const subscriptions = Object.assign({}, current(state).history.subscriptions);

            let hasNewSubscribe = false;
            subscriptionUpdates.subscribes.forEach((adSourceId) => {
                if (subscriptions[adSourceId]) {
                    subscriptions[adSourceId] = subscriptions[adSourceId] + 1;
                } else {
                    hasNewSubscribe = true;
                    subscriptions[adSourceId] = 1;
                }
            });

            let hasNewUnsubscribe = false;
            subscriptionUpdates.unsubscribes.forEach((adSourceId) => {
                if (subscriptions[adSourceId]) {
                    if (subscriptions[adSourceId] > 1) {
                        subscriptions[adSourceId] = subscriptions[adSourceId] - 1;
                    } else {
                        hasNewUnsubscribe = true;
                        delete subscriptions[adSourceId];
                    }
                }
            });

            state.history.hasPendingSubscriptionUpdates = hasNewSubscribe || hasNewUnsubscribe;
            state.history.subscriptions = subscriptions;
        },
        historySubscriptionsSent: (state) => {
            state.history.hasPendingSubscriptionUpdates = false;
            if (hasActiveHistorySubscriptions(current(state))) {
                state.history.isAwaitingNextResponse = true;
            }
        },
        historyAdStatsReceived: (state, action: PayloadAction<{ [id: number]: AdStat[] }>) => {
            state.history.adStats = action.payload;
            state.history.isAwaitingNextResponse = false;
        },
        logMessageReceived: (state, action: PayloadAction<string>) => {
            state.apiLogMessages = current(state).apiLogMessages.concat(action.payload);
        },
        updateSocketSession: (state, action: PayloadAction<SocketSession>) => {
            state.socketSession = action.payload;
        },
        updateTimeZone: (state, action: PayloadAction<string>) => {
            const currentState = current(state);
            const updatedTimeZoneCode = action.payload;
            if (currentState.timeZoneCode !== updatedTimeZoneCode) {
                state.timeZoneCode = updatedTimeZoneCode;
                if (hasActiveLiveSubscriptions(currentState)) {
                    state.live.hasPendingSubscriptionUpdates = true;
                }
                if (hasActiveHistorySubscriptions(currentState)) {
                    state.history.hasPendingSubscriptionUpdates = true;
                }
            }
        },
    },
});

// socket selects
export const selectSocketHasSubscribers = (state: RootState) => state.adStatsAdSource.socketSubscriberCount > 0;
export const selectSocketIsAuthenticated = (state: RootState) =>
    state.adStatsAdSource.connectionState >= ConnectionState.AUTHENTICATED;
export const selectSocketIsConnected = (state: RootState) =>
    state.adStatsAdSource.connectionState >= ConnectionState.CONNECTED;
export const selectSocketSession = (state: RootState) => state.adStatsAdSource.socketSession;

// time zone selects
export const selectTimeZoneCode = (state: RootState) => state.adStatsAdSource.timeZoneCode;

// live selects
export const selectLiveAdStats = (adSourceIds: number[]) => {
    const adSourceIdsSet = new Set(adSourceIds);
    return (state: RootState) =>
        Object.entries(state.adStatsAdSource.live.adStats).reduce(
            (acc, [adSourceId, adStat]) =>
                adSourceIdsSet.has(Number(adSourceId))
                    ? {
                          ...acc,
                          [adSourceId]: adStat,
                      }
                    : acc,
            {}
        );
};
export const selectLiveHasPendingSubscriptionUpdates = (state: RootState) =>
    state.adStatsAdSource.live.hasPendingSubscriptionUpdates;
export const selectLiveSubscriptionIds = (state: RootState) =>
    Object.keys(state.adStatsAdSource.live.subscriptions).map((key) => Number(key));
export const selectLiveIsAwaitingNextResponse = (state: RootState) => state.adStatsAdSource.live.isAwaitingNextResponse;

// history selects
export const selectHistoryAdStats = (adSourceIds: number[]) => {
    const adSourceIdsSet = new Set(adSourceIds);
    return (state: RootState) =>
        Object.entries(state.adStatsAdSource.history.adStats).reduce(
            (acc, [adSourceId, adStat]) =>
                adSourceIdsSet.has(Number(adSourceId))
                    ? {
                          ...acc,
                          [adSourceId]: adStat,
                      }
                    : acc,
            {}
        );
};
export const selectHistoryHasPendingSubscriptionUpdates = (state: RootState) =>
    state.adStatsAdSource.history.hasPendingSubscriptionUpdates;
export const selectHistorySubscriptionIds = (state: RootState) =>
    Object.keys(state.adStatsAdSource.history.subscriptions).map((key) => Number(key));
export const selectHistoryIsAwaitingNextResponse = (state: RootState) =>
    state.adStatsAdSource.history.isAwaitingNextResponse;

// utils
const hasActiveLiveSubscriptions = (state: AdSourceAdStatsState) => Object.keys(state.live.subscriptions).length > 0;
const hasActiveHistorySubscriptions = (state: AdSourceAdStatsState) =>
    Object.keys(state.history.subscriptions).length > 0;

export const {
    addSocketSubscriber,
    removeSocketSubscriber,
    allSocketSubscribersFinished,
    socketConnecting,
    socketOpened,
    socketClosed,
    socketFailed,
    apiAuthenticated,
    apiAuthenticating,
    apiReauthenticating,
    updateHistorySubscriptions,
    updateLiveSubscriptions,
    updateSocketSession,
    historySubscriptionsSent,
    liveSubscriptionsSent,
    historyAdStatsReceived,
    liveAdStatsReceived,
    logMessageReceived,
    updateTimeZone,
} = adStatsAdSourceSlice.actions;

export default adStatsAdSourceSlice.reducer;
