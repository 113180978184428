import { Targeting, SeatSegment, Audience, BaseOption } from "@app/core/services";
import {
    AD_SOURCE_FIELDS,
    AD_SOURCE_TYPES_NEXT_UI_NAMES,
    AdSourceFloorTypeIds,
    AdSourceTypeIds,
    AdSourceTypes,
    DEFAULT_ENRICHMENT_COST_CURRENCY_CODE,
    DemandAcquisitionCostModelId,
} from "@app/features/seatAdSources/constants";
import { TargetingStub, TargetingBlock } from "@app/features/targeting";
import { TargetingDimensionTypes } from "@app/features/targeting/constants";
import { AdSourcesForm } from "../..";
import { format, formatNumber } from "@rubicon/utils";
import { DealType } from "@app/features/deals/DealForm/types";
import { DEAL_TYPES } from "@app/features/deals/constants";
import { isAuctionPrice, isOverrideOrFallbackFloor } from "@app/features/syncedFields";

const { DEFAULT_DASHES } = format.constants;

export const HARDCODED_CURRENCY = "$";
export const DECIMAL_DIGITS = 2;

export type EnrichmentCost = number | [number, number] | null;

const getSegmentsFromTargeting = (targeting: Targeting | TargetingStub) => {
    const keys = ["include", "exclude"] as const;

    return keys.reduce<SeatSegment[]>((acc, key) => {
        if (targeting[key]?.segmentRules?.length) {
            return [...acc, ...(targeting[key]?.targetedSegments || [])];
        }
        return [...acc, ...(targeting[key]?.segments || [])];
    }, []);
};

const getAudiencesFromTargeting = (targeting: Targeting | TargetingStub) => {
    const keys = ["include", "exclude"];

    return keys.reduce<Audience[]>((acc, key) => {
        const audiences = targeting[key]?.audiences?.filter(Boolean);
        if (audiences?.length) {
            return [...acc, ...audiences];
        }
        return acc;
    }, []);
};

export const getSegmentsFromTargetingForm = (targetingBlocks: (Targeting | TargetingStub | TargetingBlock)[]) => {
    return targetingBlocks.reduce<SeatSegment[]>((acc, current) => {
        if ("include" in current) {
            const segments = getSegmentsFromTargeting(current);
            acc.push(...segments);

            return acc;
        }
        if ("dimensions" in current) {
            current.dimensions
                .filter((dimension) => dimension.type === TargetingDimensionTypes.SegmentRules)
                .reduce<SeatSegment[]>((acc, curr) => {
                    const curSegmentsById = new Map<number, SeatSegment>();
                    if ("values" in curr && curr.values && Array.isArray(curr.values)) {
                        curr.values.forEach((value) => {
                            if ("value" in value) {
                                const segment = JSON.parse(value?.value) as SeatSegment;
                                curSegmentsById.set(segment.id, segment);
                            }
                        });
                    }
                    acc.push(...curSegmentsById.values());
                    return acc;
                }, [])
                .forEach((segment) => acc.push(segment));
        }
        return acc;
    }, []);
};

export const getAudiencesFromTargetingForm = (targetingBlocks: (Targeting | TargetingStub | TargetingBlock)[]) => {
    return targetingBlocks.reduce<Audience[]>((acc, current) => {
        if ("include" in current) {
            const audiences = getAudiencesFromTargeting(current);
            acc.push(...audiences);

            return acc;
        }
        if ("dimensions" in current) {
            current.dimensions
                .filter((dimension) => dimension.type === TargetingDimensionTypes.Audiences)
                .reduce<Audience[]>((acc, current) => {
                    if ("values" in current && current.values && "minPriceUSD" in current.values) {
                        acc.push(current.values);
                    }
                    return acc;
                }, [])
                .forEach((audience) => acc.push(audience));
            return acc;
        }
        return acc;
    }, []);
};

export const getAudienceEnrichmentCostBySegments = (
    segments: SeatSegment[] | null,
    isRangedCost: boolean,
    conversionRate = 1
): EnrichmentCost | null => {
    if (!segments?.length) {
        return null;
    }

    const digitalNoZeroes = segments.map((seg) => seg.digitalPrice).filter((v) => v > 0);
    const ctvNoZeroes = segments.map((seg) => seg.ctvPrice).filter((v) => v > 0);
    const bothNoZeores = [...digitalNoZeroes, ...ctvNoZeroes];
    const prices = bothNoZeores.length ? bothNoZeores : [0];
    const minCpm = Math.min(...prices) * conversionRate;
    const maxCpm = Math.max(...prices) * conversionRate;
    if (isRangedCost) {
        if (!maxCpm) {
            return [0, 0];
        }
        return [minCpm, maxCpm];
    }
    return maxCpm;
};

export const getAudienceEnrichmentCostByAudiences = (
    audiences: Audience[] | null,
    isRangedCost: boolean,
    conversionRate = 1
): EnrichmentCost | null => {
    if (!audiences?.length) {
        return null;
    }
    const minsNoZeroes = audiences.map((audience) => audience.minPriceUSD).filter((v) => v > 0);
    const mins = minsNoZeroes.length ? minsNoZeroes : [0];
    const minCpm = Math.min(...mins) * conversionRate;
    const maxCpm = Math.max(...audiences.map((audience) => audience.maxPriceUSD)) * conversionRate;
    if (isRangedCost) {
        if (!maxCpm) {
            return [0, 0];
        }
        return [minCpm, maxCpm];
    }
    return maxCpm;
};

export const getAudienceEnrichmentCost = (
    audiences: Audience[] | null,
    segments: SeatSegment[] | null,
    isRangedCost: boolean,
    conversionRate = 1
): EnrichmentCost | null => {
    if (audiences?.length) {
        return getAudienceEnrichmentCostByAudiences(audiences, isRangedCost, conversionRate);
    }
    if (segments?.length) {
        return getAudienceEnrichmentCostBySegments(segments, isRangedCost, conversionRate);
    }
    return null;
};

/*
G. CPG, FP, Linear FP,  Server-side Tag Guaranteed, Client-side Tag Guaranteed, Server-side Tag Non-Guaranteed

*/
export const getIsFixedCost = (adSourceType: AdSourcesForm["type"]) => {
    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSourceType?.name] ?? adSourceType?.name;
    return (
        adSourceTypeName === AdSourceTypes.CLIENT_SIDE_TAG_GUARANTEED ||
        adSourceTypeName === AdSourceTypes.CONDITIONAL_PROGRAMATIC_GUARANTEED ||
        adSourceTypeName === AdSourceTypes.FIXED_PRICE ||
        adSourceTypeName === AdSourceTypes.LINEAR_FIXED_PRICE ||
        adSourceTypeName === AdSourceTypes.PROGRAMATIC_GUARANTEED ||
        adSourceTypeName === AdSourceTypes.SERVER_SIDE_TAG_GUARANTEED ||
        adSourceTypeName === AdSourceTypes.SERVER_SIDE_TAG_NON_GUARANTEED
    );
};

export const getIsRangedCost = (adSourceType: AdSourcesForm["type"]) => {
    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSourceType?.name] ?? adSourceType?.name;
    return (
        adSourceTypeName === AdSourceTypes.AUCTION_PRICE ||
        adSourceTypeName === AdSourceTypes.OPEN_AUCTION ||
        adSourceTypeName === AdSourceTypes.MARKETPLACE ||
        adSourceTypeName === AdSourceTypes.LINEAR_AUCTION_PRICE
    );
};

export const getIsRangedCostDeal = (dealType: DealType) => dealType === DEAL_TYPES.AUCTION;

export const displayEnrichmentCost = (
    cost: EnrichmentCost,
    adSourceCurrencyCode = DEFAULT_ENRICHMENT_COST_CURRENCY_CODE,
    adSourceDemandAcquisitionCostModelId: number | null | undefined = DemandAcquisitionCostModelId.FIXED
) => {
    if (!cost) {
        return DEFAULT_DASHES;
    }

    if (adSourceDemandAcquisitionCostModelId === DemandAcquisitionCostModelId.PERCENTAGE) {
        if (Array.isArray(cost)) {
            return `${formatNumber.asMoney(cost[0], adSourceCurrencyCode)} - ${formatNumber.asPercent(cost[1])}`;
        }
        return formatNumber.asPercent(cost);
    }

    if (Array.isArray(cost)) {
        return `${formatNumber.asMoney(cost[0], adSourceCurrencyCode)} - ${formatNumber.asMoney(
            cost[1],
            adSourceCurrencyCode
        )}`;
    }
    return formatNumber.asMoney(cost, adSourceCurrencyCode);
};

export const calcFinalEc = (adSourceLevelEc: EnrichmentCost, dealLevelEc: EnrichmentCost): EnrichmentCost => {
    if (!adSourceLevelEc && !dealLevelEc) {
        return 0;
    }
    if (!adSourceLevelEc) {
        return dealLevelEc;
    }
    if (!dealLevelEc) {
        return adSourceLevelEc;
    }
    if (typeof adSourceLevelEc === "number" && typeof dealLevelEc === "number") {
        return Math.max(adSourceLevelEc, dealLevelEc);
    }
    if (Array.isArray(adSourceLevelEc) && typeof dealLevelEc === "number") {
        return [Math.min(adSourceLevelEc[0], dealLevelEc), Math.max(adSourceLevelEc[1], dealLevelEc)];
    }
    if (typeof adSourceLevelEc === "number" && Array.isArray(dealLevelEc)) {
        return [Math.min(adSourceLevelEc, dealLevelEc[0]), Math.max(adSourceLevelEc, dealLevelEc[1])];
    }
    return [Math.min(adSourceLevelEc[0], dealLevelEc[0]), Math.max(adSourceLevelEc[1], dealLevelEc[1])];
};

type Operation = (a: number, b: number) => number;

export const sum: Operation = (a, b) => a + b;

export const multiplyByPercent: Operation = (n, percent: number) => n * (1 + percent / 100);

export const getDacOperator = (modelId: number | null): Operation =>
    modelId === DemandAcquisitionCostModelId.PERCENTAGE ? multiplyByPercent : sum;

export const ecOperate = (a: EnrichmentCost, b: EnrichmentCost, op: Operation): EnrichmentCost => {
    a = dedupEnrichmentCost(a);
    b = dedupEnrichmentCost(b);

    if (op === multiplyByPercent) {
        if (a === null || b === null) {
            return null;
        }
        if (!a || !b) {
            return 0;
        }
    }

    if (!a) {
        return b;
    }
    if (!b) {
        return a;
    }

    if (typeof a === "number" && typeof b === "number") {
        return op(a, b);
    }
    if (typeof a === "number") {
        return [op(a, b[0]), op(a, b[1])];
    }
    if (typeof b === "number") {
        return [op(a[0], b), op(a[1], b)];
    }

    return [op(a[0], b[0]), op(a[1], b[1])];
};

export const calcFinalizedCpm = (
    finalEc: EnrichmentCost,
    cpm: EnrichmentCost,
    demandAcquisitionCost: number
): EnrichmentCost => {
    return [finalEc, cpm, demandAcquisitionCost].reduce((acc, val) => ecOperate(acc, val, sum));
};

const dedupEnrichmentCost = (cost: EnrichmentCost): EnrichmentCost => {
    if (Array.isArray(cost) && cost[0] === cost[1]) {
        return cost[0];
    }
    return cost;
};

export const formatEcCurrency = (value: EnrichmentCost, currencyCode: string) => {
    value = dedupEnrichmentCost(value);

    if (value === null) {
        return DEFAULT_DASHES;
    }

    if (Array.isArray(value)) {
        return `${formatNumber.asMoney(value[0], currencyCode)} - ${formatNumber.asMoney(value[1], currencyCode)}`;
    }

    return formatNumber.asMoney(value, currencyCode);
};

export const formatDac = (dac: number | null, dacModelId: number | null, currencyCode: string) => {
    return dacModelId === DemandAcquisitionCostModelId.PERCENTAGE
        ? formatNumber.asPercent(dac)
        : formatNumber.asMoney(dac, currencyCode);
};

export const formatCpm = (
    dealCpm: number | null,
    currencyCode: string,
    adSourceCpm: number | null,
    adSourceTypeId: number,
    adSourceFloorTypeId: number | undefined
) => {
    if (isAuctionPrice(adSourceTypeId)) {
        if (
            [AdSourceFloorTypeIds.DECREASE_PERCENT, AdSourceFloorTypeIds.INCREASE_PERCENT].includes(
                Number(adSourceFloorTypeId)
            )
        ) {
            const operator = Number(adSourceFloorTypeId) === AdSourceFloorTypeIds.INCREASE_PERCENT ? "+" : "-";
            const percent = Number(adSourceCpm);
            return `Floor ${operator} ${percent}%`;
        }
        if (
            [AdSourceFloorTypeIds.DECREASE_CPM, AdSourceFloorTypeIds.INCREASE_CPM].includes(Number(adSourceFloorTypeId))
        ) {
            const operator = Number(adSourceFloorTypeId) === AdSourceFloorTypeIds.INCREASE_CPM ? "+" : "-";
            const cpmDisplay = displayEnrichmentCost(Number(adSourceCpm), currencyCode);
            return `Floor ${operator} ${cpmDisplay}`;
        }

        return formatEcCurrency(adSourceCpm, currencyCode);
    }

    return formatEcCurrency(dealCpm, currencyCode);
};

export const formatFinalizedCpm = (
    floorCpmDisplay: string,
    finalEcPlusDemandAcquisitionCostDisplay: string,
    adSourceTypeId: number,
    adSourceFloorTypeId: number | undefined
) => {
    if (isAuctionPrice(adSourceTypeId) && !isOverrideOrFallbackFloor(adSourceTypeId, adSourceFloorTypeId)) {
        return "Not Available";
    }

    return `(${floorCpmDisplay}) + (${finalEcPlusDemandAcquisitionCostDisplay})`;
};

export const formatFinalCpmWithEcAndDac = (
    finalValue: EnrichmentCost,
    currencyCode: string,
    adSourceTypeId: number,
    adSourceFloorTypeId: number | undefined
) => {
    if (isAuctionPrice(adSourceTypeId) && !isOverrideOrFallbackFloor(adSourceTypeId, adSourceFloorTypeId)) {
        return "Not Available";
    }

    return formatEcCurrency(finalValue, currencyCode);
};

export const calcFinalCpmWithEcAndDac = (
    cpm: EnrichmentCost,
    finalEc: EnrichmentCost,
    demandAcquisitionCost: number | null,
    demandAcquisitionCostModelId: number | null
) => {
    if (demandAcquisitionCostModelId === DemandAcquisitionCostModelId.PERCENTAGE) {
        return ecOperate(ecOperate(cpm, finalEc, sum), demandAcquisitionCost, multiplyByPercent);
    }
    return [cpm, finalEc, demandAcquisitionCost].reduce((acc, val) => {
        return ecOperate(acc, val, sum);
    });
};

export const getAudienceEnrichmentCostHelp = (isRangedCost: boolean) => {
    if (isRangedCost) {
        return "Audience Enrichment Cost CPM will be auto-populated and added to the inventory floor based on attributed segment's CPM (range:$X-$Y). $X is lowest CPM segment, $Y is highest cost CPM segment.";
    }
    return "Audience Enrichment Cost CPM will be auto-populated and added to the CPM with the highest cost CPM targeted segment";
};
export const convertPrice = (price: number, conversionRate: number, currencySymbol: string) => {
    if (!price) {
        return DEFAULT_DASHES;
    }
    return `${currencySymbol} ${formatNumber.asFixed(price * conversionRate)}`;
};

export const getDemandFeeFromEnrichmentCost = (enrichmentCost: EnrichmentCost) => {
    if (enrichmentCost === null || Array.isArray(enrichmentCost)) {
        return null;
    }
    return enrichmentCost * 1000;
};

export const getEnrichmentCostHelp = (hasEnrichmentCostTargeting: boolean, hasDemandAcquisitionCost: boolean) => {
    if (!hasEnrichmentCostTargeting && !hasDemandAcquisitionCost) {
        return "Audience Enrichment Cost CPM will be auto-populated and added in addition to the CPM";
    }
    if (hasEnrichmentCostTargeting && !hasDemandAcquisitionCost) {
        return "CPM will be increased by Final Audience Enrichment Cost CPM";
    }
    if (!hasEnrichmentCostTargeting && hasDemandAcquisitionCost) {
        return "CPM will be increased by Demand Acquisition Cost";
    }
    return "CPM will be increased by Final Audience Enrichment Cost CPM and Demand Acquisition Cost";
};

export const convertEnrichmentCostByCurrencyRate = (enrichmentCost: EnrichmentCost, rate: number): EnrichmentCost => {
    if (enrichmentCost === null) {
        return null;
    }
    if (typeof enrichmentCost === "number") {
        return enrichmentCost * rate;
    }
    return [enrichmentCost[0] * rate, enrichmentCost[1] * rate];
};

export const getShouldUseAdSourceValues = (
    adSourceTypeId: AdSourceTypeIds,
    adSourceFloorTypeId: AdSourceFloorTypeIds
) => {
    return (
        [
            AdSourceTypeIds.AUCTION_PRICE,
            AdSourceTypeIds.LINEAR_INVITE_ONLY_AUCTION,
            AdSourceTypeIds.OPEN_AUCTION,
            AdSourceTypeIds.CURATOR_MARKETPLACE,
            AdSourceTypeIds.MARKETPLACE,
        ].includes(adSourceTypeId) &&
        [AdSourceFloorTypeIds.OVERRIDE, AdSourceFloorTypeIds.FALLBACK].includes(adSourceFloorTypeId)
    );
};

export const getRateColumnTitle = (adSourceType: BaseOption | null | undefined) => {
    if (
        adSourceType &&
        [
            AdSourceTypeIds.AUCTION_PRICE,
            AdSourceTypeIds.LINEAR_INVITE_ONLY_AUCTION,
            AdSourceTypeIds.MARKETPLACE,
            AdSourceTypeIds.CURATOR_MARKETPLACE,
            AdSourceTypeIds.OPEN_AUCTION,
        ].includes(adSourceType.id as AdSourceTypeIds)
    ) {
        return "Floor CPM";
    }
    return "CPM";
};

export const getDemandAcquisitionCostLabel = (demandAcquisitionCostModelId: number | null) =>
    `${AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.label} (${
        demandAcquisitionCostModelId === DemandAcquisitionCostModelId.PERCENTAGE ? "%" : "CPM"
    })`;

export const getDemandAcquisitionCostHelpText = (
    demandAcquisitionCost: number | string | null,
    demandAcquisitionCostModelId: number | null
) =>
    demandAcquisitionCost
        ? `CPM will be increased by ${
              demandAcquisitionCostModelId === DemandAcquisitionCostModelId.PERCENTAGE
                  ? formatNumber.asPercent(demandAcquisitionCost)
                  : formatNumber.asMoney(demandAcquisitionCost, DEFAULT_ENRICHMENT_COST_CURRENCY_CODE)
          }`
        : undefined;

export const getDemandAcquisitionCostFormValue = (demandAcquisitionCost) =>
    demandAcquisitionCost === null ? undefined : demandAcquisitionCost;

export const isEnrichmentCostEligible = (adSourceTypeId: number | null | undefined): boolean =>
    Boolean(adSourceTypeId) &&
    adSourceTypeId !== AdSourceTypeIds.MARKETPLACE &&
    adSourceTypeId !== AdSourceTypeIds.CURATOR_MARKETPLACE;
