import { FC } from "react";
import { Form } from "antd";
import { BrandSupply } from "@app/core/services";
import { FormLayout } from "antd/lib/form/Form";
import { FORM_GRID_OPTIONS } from "../BrandSafetyPublisherPage/constants";
import {
    BlockedAdvertiserDomainListFormItem,
    BlockedAdvertiserDomainsFormItem,
    BlockedBuyerSeatListFormItem,
} from "./ViewFormItems";

interface Props {
    supply: BrandSupply;
    layout?: FormLayout;
}

export const BrandSafetySupplyForm: FC<Props> = ({ supply, layout = "horizontal" }) => {
    return (
        <Form layout={layout} {...FORM_GRID_OPTIONS[layout]} labelAlign="left">
            <BlockedAdvertiserDomainListFormItem
                seatAdvertiserDomainFilterListDefs={supply.seatAdvertiserDomainFilterListDefs}
            />
            <BlockedAdvertiserDomainsFormItem advertiserBlockDomains={supply.advertiserBlockDomains} />
            <BlockedBuyerSeatListFormItem buyerSeatList={supply.buyerSeatList} />
        </Form>
    );
};
