import { ColumnsType } from "antd/es/table";
import { TableRows, SeatContactTableRaw, SeatContactPayload } from "../types";
import { FixedType } from "../../recycleBin/RecycleBinTable/types";
import { Button, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { SEAT_CONTACT_DETAILS_ACTIONS_EDIT_BUTTON_SDET } from "../SeatContactDrawer/SeatContactDrawerTitle";
import { SEARCH } from "@app/core/components";
import { SeatContactsDeleteModal } from "../SeatContactsDeleteModal/SeatContactsDeleteModal";

export const getColumns = (): ColumnsType<TableRows> =>
    [
        {
            dataIndex: "firstName",
            key: "firstName",
            sorter: false,
            title: "First Name",
            width: 150,
        },
        {
            dataIndex: "lastName",
            key: "lastName",
            sorter: false,
            title: "Last Name",
            width: 150,
        },
        {
            dataIndex: "email",
            key: "email",
            sorter: false,
            title: "Email",
            width: 250,
        },
        {
            dataIndex: "contactType",
            key: "contactType",
            sorter: false,
            title: "Contact Type",
            width: 150,
        },
        {
            dataIndex: "countryCode",
            key: "countryCode",
            sorter: false,
            title: "Country Code",
            width: 120,
        },
        {
            dataIndex: "postalCode",
            key: "postalCode",
            sorter: false,
            title: "Postal Code",
            width: 120,
        },
        {
            dataIndex: "actions",
            fixed: "right" as FixedType,
            key: "actions",
            sorter: false,
            title: "Actions",
            width: 120,
        },
    ].filter(Boolean);

export const getTableRows = (
    data: SeatContactTableRaw[] | undefined,
    seatId: string,
    search: string,
    handleDrawerOpen: (contactId: string) => void
) =>
    data
        ? data
              .filter((contact) => {
                  const keyword = new URLSearchParams(search).get(SEARCH.key);
                  return keyword
                      ? [contact.name, contact.emailAddress].some((sc) =>
                            sc.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
                        )
                      : true;
              })
              .map<TableRows>((entity) => {
                  return {
                      className: "",
                      id: entity.id,
                      firstName: (
                          <Typography.Link onClick={() => handleDrawerOpen(String(entity.id))}>
                              {entity.firstName}
                          </Typography.Link>
                      ),
                      lastName: entity.lastName,
                      key: entity.id,
                      email: entity.emailAddress,
                      contactType: entity.type.name,
                      countryCode: entity.countryCode,
                      postalCode: entity.postalCode,
                      actions: (
                          <Button.Group>
                              <Link to={ROUTE_FORMATTERS.SEAT_CONTROLS_SEAT_CONTACTS_EDIT(seatId, entity.id)}>
                                  <Button data-sdet={SEAT_CONTACT_DETAILS_ACTIONS_EDIT_BUTTON_SDET}>
                                      <EditOutlined />
                                  </Button>
                              </Link>
                              <SeatContactsDeleteModal isIcon contact={entity as SeatContactPayload} />
                          </Button.Group>
                      ),
                  };
              })
        : [];
