import { Col, Form, Row, Button, Typography } from "antd";
import { css } from "@emotion/css";
import { useAuthClient } from "@app/core/authClient";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { FC } from "react";

export const NoValidContextsForm: FC = () => {
    const { logout } = useAuthClient();
    const history = useHistory();

    return (
        <Form layout="vertical">
            <Row gutter={[0, 10]}>
                <Col xs={24}>
                    <Typography.Paragraph>You do not have access to any seats</Typography.Paragraph>
                    <Typography.Paragraph>
                        Please contact your account manager to complete setup of your account
                    </Typography.Paragraph>
                </Col>
            </Row>
            <Row gutter={[0, 10]} justify="end">
                <Col xs={24}>
                    <Button
                        data-sdet="logout"
                        block
                        onClick={() => {
                            logout().finally(() => history.push(ROUTES.LOGIN_MAGNITE));
                        }}
                        type="primary"
                        className={css`
                            height: 42px;
                        `}
                    >
                        Logout
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};
