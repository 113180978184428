import { FC } from "react";
import { Col, Form, Row } from "antd";
import { BrandSupply } from "@app/core/services";
import { NONE } from "@app/core/components/constants";
import { GUTTER } from "@app/features/inventory/constants";
import { FORM_ITEM_OFFSET } from "@app/features/inventory/InventoryHealthDetailsPages/constants";

const { Item } = Form;

interface Props {
    supply: BrandSupply;
}

export const SiteDetailsSection: FC<Props> = ({ supply }) => {
    return (
        <div data-sdet="supply-site">
            <Row gutter={GUTTER}>
                <Col span={12}>
                    <Item label="External Name" className={FORM_ITEM_OFFSET}>
                        {supply?.supplyNameOverride || NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col span={12}>
                    <Item label="Domain" className={FORM_ITEM_OFFSET}>
                        {supply?.domain || NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col span={12}>
                    <Item label="Inventory Partner Domain" className={FORM_ITEM_OFFSET}>
                        {supply?.inventoryPartnerDomain || NONE}
                    </Item>
                </Col>
            </Row>
        </div>
    );
};
