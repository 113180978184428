import { FC } from "react";
import { Form, Radio } from "antd";
import {
    INVENTORY_FORM_FIELDS,
    SEAT_SUPPLY_BUNDLE_ID_FILTER_MODE_OPTIONS,
} from "@app/features/inventory/InventorySeat/constants";

export const AppBundleIDFilterMode: FC = () => {
    return (
        <Form.Item
            label={INVENTORY_FORM_FIELDS.BUNDLE_ID_FILTER_MODE.label}
            name={INVENTORY_FORM_FIELDS.BUNDLE_ID_FILTER_MODE.name}
        >
            <Radio.Group options={SEAT_SUPPLY_BUNDLE_ID_FILTER_MODE_OPTIONS} />
        </Form.Item>
    );
};
