import { Form, Input } from "antd";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";

const { TextArea } = Input;

export const DescriptionTextArea = () => {
    return (
        <Form.Item
            {...VERTICAL_LAYOUT_FIX}
            label={AD_SOURCE_FIELDS.DESCRIPTION.label}
            name={AD_SOURCE_FIELDS.DESCRIPTION.name}
            rules={[
                {
                    max: AD_SOURCE_FIELDS.DESCRIPTION.validations.max,
                    message: `Description cannot be longer than ${AD_SOURCE_FIELDS.DESCRIPTION.validations.max} characters`,
                },
            ]}
        >
            <TextArea rows={3} data-sdet="adsource-description" placeholder="Type here..." />
        </Form.Item>
    );
};
