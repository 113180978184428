import { Form, Select } from "antd";
import { FC, useState } from "react";
import { LabeledValue } from "antd/lib/select";
import { TagRender } from "@app/core/components";
import { blue6, red5 } from "@rubicon/antd-components";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import { RuleObject, StoreValue } from "rc-field-form/lib/interface";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { SelectAllButtonDropdownRender } from "@app/core/components/SelectAllButtonDropdownRender";
import { useAdSourceIabCategoryBlockExceptionField } from "./useAdSourceIabCategoryBlockExceptionField";

export const AdSourceIabCategoryBlockExceptionField: FC = () => {
    const { value, onChange, handleMenuChange, options, isFetching, setSearch, search, blockedIabCategories } =
        useAdSourceIabCategoryBlockExceptionField();

    const [invalidIabCategories, setInvalidIabCategories] = useState<number[]>([]);

    const validator = (_: RuleObject, value: StoreValue): Promise<void> => {
        const selectedBlockedIabCategories: number[] = (blockedIabCategories || []).map(({ id }) => id);
        const selectedIabCategoryBlockExceptions: number[] = (value || []).map(({ value }) => value);

        const { isValid, invalidIds } = selectedBlockedIabCategories.reduce<{
            isValid: boolean;
            invalidIds: number[];
        }>(
            (accumulator, id) => {
                if (selectedIabCategoryBlockExceptions.includes(id)) {
                    return { isValid: false, invalidIds: [...accumulator.invalidIds, id] };
                }
                return accumulator;
            },
            { isValid: true, invalidIds: [] }
        );

        setInvalidIabCategories(invalidIds);

        if (!isValid)
            return Promise.reject(
                "Can not have the same category selected in both IAB Categories and Block Exceptions"
            );

        return Promise.resolve();
    };

    return (
        <Form.Item
            rules={[{ validator }]}
            label="IAB Category Block Exceptions"
            validateTrigger={["onBlur", "onChange"]}
            data-sdet="ad-source-iab-categories-block-exceptions-field"
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES}
        >
            <Select
                allowClear
                showSearch
                labelInValue
                dropdownRender={(menu) => (
                    <SelectAllButtonDropdownRender<LabeledValue>
                        menu={menu}
                        value={value}
                        search={search}
                        options={options}
                        onChange={handleMenuChange}
                        getPrimaryKey={(v) => v.value}
                    />
                )}
                value={value}
                mode="multiple"
                options={options}
                onChange={onChange}
                filterOption={false}
                searchValue={search}
                onSearch={setSearch}
                loading={isFetching}
                autoClearSearchValue={false}
                placeholder="Select IAB Category Block Exceptions"
                tagRender={(props: CustomTagProps) => (
                    <TagRender
                        closeIconColor={invalidIabCategories.includes(props.value) ? red5 : blue6}
                        color={invalidIabCategories.includes(props.value) ? "error" : "processing"}
                        {...props}
                    />
                )}
            />
        </Form.Item>
    );
};
