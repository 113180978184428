import { ChangeLogEntityType } from "./changeLogs";
import { CHANGELOG_TAG, consoleApi, DEAL_TAG, SEAT_ALL_DEALS_TAG, SEAT_CTV_NEXT_DEMAND_DEALS_TAG } from "./console";
import { DemandDeal } from "./deals";
import { toQueryString } from "./utils";

interface DemandDealsType<T> {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    entityType: T;
    sort: number;
    value: string;
}

export interface DemandDealListResponse {
    page: number;
    maxResults: number;
    totalResults: number;
    demandDeals: DemandDeal[];
}

export type DemandDealsPricingType = DemandDealsType<"DealPricingType">;
export type DemandDealsTransactionType = DemandDealsType<"DealTransactionType">;
export interface UpdateDemandDealArgs {
    id: number;
    body: Partial<DemandDealsPricingType | DemandDealsTransactionType>;
}

export interface GetSeatDemandDealsParams {
    seatId: number;
    page?: number;
    max?: number;
    keyword?: string;
    type?: (string | number)[];
}

export const demandDealsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        updateDemandDeal: builder.mutation<DemandDeal, UpdateDemandDealArgs>({
            query: ({ id, body }: UpdateDemandDealArgs) => {
                const payloadBody = { ...body };
                if (!("@class" in payloadBody)) {
                    payloadBody["@class"] = "com.tremorvideo.ssp.dealsync.model.DemandDeal";
                }
                return {
                    url: `/demandDeals/${id}`,
                    method: "PUT",
                    body: payloadBody,
                };
            },
            invalidatesTags: (_, err, { id }) =>
                err
                    ? []
                    : [
                          { type: DEAL_TAG, id },
                          SEAT_ALL_DEALS_TAG,
                          { type: CHANGELOG_TAG, id: `${ChangeLogEntityType.Deal}-${id}` },
                      ],
        }),
        getSeatCtvNextDemandDeals: builder.query<DemandDealListResponse, GetSeatDemandDealsParams>({
            query: (params: GetSeatDemandDealsParams) => {
                const { seatId, ...rest } = params;
                return `seats/ctvNext/${seatId}/demandDeals;${toQueryString(rest)}`;
            },
            providesTags: [SEAT_CTV_NEXT_DEMAND_DEALS_TAG],
        }),
    }),
});

export const { useUpdateDemandDealMutation, useLazyGetSeatCtvNextDemandDealsQuery } = demandDealsApi;
