import { createContext, useContext } from "react";
import { useSeatSideNav } from "./useSeatSideNav";

export type SeatSideNavContext = ReturnType<typeof useSeatSideNav>;

const Context = createContext<SeatSideNavContext | null>(null);

export function SeatSideNavProvider(props) {
    const headerData = useSeatSideNav();
    return <Context.Provider value={headerData} {...props} />;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const useSeatSideNavState = () => useContext<SeatSideNavContext>(Context);
