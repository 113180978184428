import { FC } from "react";
import { Space, Button } from "antd";
import { Floor } from "@app/core/services";
import { DeleteAdvancedFloorButton } from "./DeleteAdvancedFloorButton";

interface Props {
    floor: Floor;
}

export const SeatFloorsAdvancedTableActions: FC<Props> = ({ floor }) => {
    return (
        <Space style={{ width: "100%" }}>
            <Button.Group>
                <DeleteAdvancedFloorButton floor={floor} />
            </Button.Group>
        </Space>
    );
};
