import { Button, Col, Row, Space } from "antd";
import { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { PriceOverrideConfig } from "@app/core/services";
import { PriceOverrideDeleteButtonModal } from "./PriceOverrideDeleteButtonModal";
import { useUserAccess } from "@app/core/auth";

interface Props {
    priceOverride?: PriceOverrideConfig;
    isDrawer?: boolean;
}

export const PRICE_OVERRIDE_DETAILS_ACTIONS_SDET = "classifications-label-details-actions";
export const PRICE_OVERRIDE_DETAILS_ACTIONS_EDIT_BUTTON_SDET = "classifications-label-details-actions-edit-btn";

export const PriceOverrideDetailsActions: FC<Props> = ({ priceOverride, isDrawer = false }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();
    const { canEditSeat } = useUserAccess();

    return (
        <Row data-sdet={PRICE_OVERRIDE_DETAILS_ACTIONS_SDET}>
            {canEditSeat && (
                <Col>
                    <Space>
                        <Button
                            onClick={() =>
                                priceOverride &&
                                history.push(
                                    ROUTE_FORMATTERS.SEAT_CONTROLS_PRICE_OVERRIDE_EDIT_PAGE(seatId, priceOverride.id)
                                )
                            }
                            data-sdet={PRICE_OVERRIDE_DETAILS_ACTIONS_EDIT_BUTTON_SDET}
                            type="primary"
                        >
                            Edit
                        </Button>
                        <PriceOverrideDeleteButtonModal isDrawer={isDrawer} priceOverride={priceOverride} />
                    </Space>
                </Col>
            )}
        </Row>
    );
};
