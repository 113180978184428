import { FC, useMemo } from "react";
import { useAdBidStats } from "../useAdBidStats";
import { Card, Col, Row } from "antd";
import { formatNumber } from "@rubicon/utils";
import styled from "@emotion/styled";
import { MetricsItem } from "@rubicon/antd-components";
import { Loading } from "@app/core/components";
import { StatsItem } from "../StatsItem";

interface Props {
    adSourceId: number;
}

const StyledCard = styled(Card)`
    .ant-card-body {
        padding: 0px 10px !important;
        height: 100%;
        transition: height 2s;
        transition-timing-function: ease-in-out;
    }
    .ant-card-head {
        font-size: 16px;
        background-color: #f0f2f5;
        min-height: 40px;
        padding: 8px;
    }
    .ant-card-bordered {
        height: 100% !important;
    }
`;

export const BidStatsCard: FC<Props> = ({ adSourceId }) => {
    const { adBidStatsTableData, isFetching } = useAdBidStats(adSourceId);
    const adBidStatsTableDataItem = adBidStatsTableData?.[0];
    const data: MetricsItem[] = useMemo((): MetricsItem[] => {
        return adBidStatsTableDataItem
            ? [
                  {
                      title: "Auction Count",
                      value: formatNumber.asWhole(adBidStatsTableDataItem?.auctionCount),
                  },
                  {
                      title: "No Bid",
                      value: formatNumber.asWhole(adBidStatsTableDataItem?.noBid),
                  },
                  {
                      title: "Losses",
                      value: formatNumber.asWhole(adBidStatsTableDataItem?.losses),
                  },
                  {
                      title: "Winner",
                      value: formatNumber.asWhole(adBidStatsTableDataItem?.wins),
                  },
                  {
                      title: "Deal Mismatch",
                      value: formatNumber.asWhole(adBidStatsTableDataItem?.dealMismatch),
                  },
                  {
                      title: "Timeouts",
                      value: formatNumber.asWhole(adBidStatsTableDataItem?.timeouts),
                  },
                  {
                      title: "Below Deal",
                      value: formatNumber.asWhole(adBidStatsTableDataItem?.belowDeal),
                  },
                  {
                      title: "Above Deal",
                      value: formatNumber.asWhole(adBidStatsTableDataItem?.aboveDeal),
                  },
                  {
                      title: "Deal Mismatch",
                      value: formatNumber.asWhole(adBidStatsTableDataItem?.dealMismatch),
                  },
                  {
                      title: "Throttled",
                      value: formatNumber.asWhole(adBidStatsTableDataItem?.throttled),
                  },
                  {
                      title: "Errors",
                      value: formatNumber.asWhole(adBidStatsTableDataItem?.errors),
                  },
                  {
                      title: "Avg Bid Price",
                      value: formatNumber.asMoney(adBidStatsTableDataItem?.averageBidPrice, undefined),
                  },
              ]
            : [];
    }, [adBidStatsTableDataItem]);

    if (!adBidStatsTableDataItem) {
        return <Loading position="relative" />;
    }

    const firstPart = data.slice(0, 6);
    const secondPart = data.slice(6, 12);

    return (
        <Row
            gutter={[0, 0]}
            style={{
                padding: 0,
                margin: 0,
                width: "100%",
            }}
        >
            <Col span={12}>
                <StyledCard title="Bidder Stats" loading={isFetching}>
                    {!isFetching && firstPart.map((item) => <StatsItem key={item.title + item.value} item={item} />)}
                </StyledCard>
            </Col>
            <Col span={12}>
                <StyledCard title=" ">
                    {!isFetching && secondPart.map((item) => <StatsItem key={item.title + item.value} item={item} />)}
                </StyledCard>
            </Col>
        </Row>
    );
};
