import {
    ErrorResponse,
    useGetSeatThirdPartyPixelByIdQuery,
    useUpdateSeatThirdPartyPixelMutation,
} from "@app/core/services";
import { useHistory, useParams } from "react-router-dom";
import { CUSTOM_PIXEL_EDIT_PAGE_SDET } from "./constants";
import { Alert, Button, Layout } from "antd";
import { CustomPixelForm } from "../CustomPixelForm";
import { getCustomPixelInitialValues, onFinishUpdatePixel } from "./helpers";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ErrorPage, PageLoader } from "@rubicon/antd-components";
import { CustomPixelDeleteModal } from "../CustomPixelDeleteModal";
import { useCustomPixelDeleteModal } from "../useCustomPixelDeleteModal";
import { PageHeader } from "@ant-design/pro-layout";

export const CustomPixelEditPage = () => {
    const { seatId, pixelId } = useParams<{ seatId: string; pixelId: string }>();
    const { data, isLoading, error } = useGetSeatThirdPartyPixelByIdQuery(pixelId);
    const history = useHistory();
    const [updateSeatThirdPartyPixel, { isLoading: isLoadingUpdate }] = useUpdateSeatThirdPartyPixelMutation();
    const { handleOpen, ...restModalProps } = useCustomPixelDeleteModal(() =>
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, "custom-pixels"))
    );

    if (isLoading) return <PageLoader />;

    if (error) {
        const { status, data: errorData } = error as ErrorResponse;
        return <ErrorPage code={status} title={errorData?.errorCode} message={errorData?.errorDescription} />;
    }

    // the API does not error out for requesting a non-existing pixel request, just response void
    if (!data) {
        return <Alert banner showIcon type="warning" message="There is no data for this request" />;
    }
    return (
        <Layout data-sdet={CUSTOM_PIXEL_EDIT_PAGE_SDET}>
            <PageHeader
                title={<span data-sdet="custom-pixel-edit-page-title">{`Edit: ${data?.name}`}</span>}
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_CUSTOM_PIXELS_DETAILS(seatId, pixelId))}
            />
            <CustomPixelForm
                initialValues={getCustomPixelInitialValues(data)}
                onFinish={(values) => onFinishUpdatePixel(values, seatId, pixelId, updateSeatThirdPartyPixel, history)}
                isSubmitting={isLoadingUpdate}
                submitLabel="Save"
                deleteButton={
                    <>
                        <Button danger type="primary" onClick={() => data && handleOpen(data)}>
                            Delete
                        </Button>
                        <CustomPixelDeleteModal {...restModalProps} />
                    </>
                }
            />
        </Layout>
    );
};
