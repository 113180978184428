export const PRICE_MODEL_OPTIONS = {
    FIXED_PRICE: {
        creationDate: "2015-04-01T22:33:21.000+0000",
        updateDate: "2015-04-01T22:33:21.000+0000",
        id: 1,
        name: "Fixed Price",
    },
    TIERED_PRICE: {
        creationDate: "2015-04-01T22:33:21.000+0000",
        updateDate: "2015-04-01T22:33:21.000+0000",
        id: 2,
        name: "Tiered price",
    },
};
