import { FC } from "react";
import { ColProps } from "antd/lib/grid/col";
import { CodeFormItem } from "@app/features/inventory/components/FormItems";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useAdUnitFormInstance } from "@app/features/inventory/HierarchyForms/AdUnitForm/AdUnitFormInstance";

interface CodeProps {
    wrapperCol?: ColProps;
}

export const Code: FC<CodeProps> = ({ wrapperCol }) => {
    const { mode, seat, useWatch } = useAdUnitFormInstance();

    return (
        <CodeFormItem
            mode={mode}
            seat={seat}
            useWatch={useWatch}
            wrapperCol={wrapperCol}
            name={AD_UNIT_FORM_FIELDS.CODE.name}
            label={AD_UNIT_FORM_FIELDS.CODE.label}
            placeholder={AD_UNIT_FORM_FIELDS.CODE.placeholder}
            dataSdet="ad-unit-code"
        />
    );
};
