import { useUserAccess } from "@app/core/auth";
import { DefinationField } from "@app/core/components/HelpKeysDrawer";
import { filterDefinitionsFieldsByHelpPathKeys } from "@app/features/inventory/helpers";
import { SeatHelpKeys, SupplyDrawerTitles, supplyHelpKeys } from "@app/features/inventory/InventorySeat/constants";

interface UseSupplyFormDefinitionsFields {
    generalDefinitionsFields: DefinationField[];
    appDetailsDefinitionsFields: DefinationField[];
    siteDetailsDefinitionsFields: DefinationField[];
    brandSafetyDefinitionsFields: DefinationField[];
    exchangeSafetyDefinitionsFields: DefinationField[];
    internalDefinitionsFields: DefinationField[];
    supplyDetailsDefinitionsFields: DefinationField[];
    customPixelsDefinitionsFields: DefinationField[];
    labelsDefinitionsFields: DefinationField[];
    affiliateDefinitionsFields: DefinationField[];
    waterfallPrefilteringDefinitionsFields: DefinationField[];
    floorsDefinitionsFields: DefinationField[];
}

const INTERNAL_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS: string[] = [];
const LABELS_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS: string[] = [SeatHelpKeys.GlobalLabels];
export const useSupplyFormDefinitionsFields = (): UseSupplyFormDefinitionsFields => {
    const { isSysAdmin, isPubAcctMgr, hasInternalAccess } = useUserAccess();

    const generalDefinitionsFields: DefinationField[] = supplyHelpKeys[SupplyDrawerTitles.General];
    const appDetailsDefinitionsFields: DefinationField[] = supplyHelpKeys[SupplyDrawerTitles.AppDetails];
    const siteDetailsDefinitionsFields: DefinationField[] = supplyHelpKeys[SupplyDrawerTitles.SiteDetails];
    const brandSafetyDefinitionsFields: DefinationField[] = supplyHelpKeys[SupplyDrawerTitles.BrandSafety];
    const exchangeSafetyDefinitionsFields: DefinationField[] = supplyHelpKeys[SupplyDrawerTitles.ExchangeSafety];

    const internalDefinitionsFields: DefinationField[] =
        isSysAdmin || isPubAcctMgr
            ? supplyHelpKeys[SupplyDrawerTitles.Internal]
            : filterDefinitionsFieldsByHelpPathKeys(
                  supplyHelpKeys[SupplyDrawerTitles.Internal],
                  INTERNAL_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS
              );

    const supplyDetailsDefinitionsFields: DefinationField[] = supplyHelpKeys[SupplyDrawerTitles.SupplyDetails];
    const customPixelsDefinitionsFields: DefinationField[] = supplyHelpKeys[SupplyDrawerTitles.CustomPixels];
    const labelsDefinitionsFields = hasInternalAccess
        ? supplyHelpKeys[SupplyDrawerTitles.Labels]
        : filterDefinitionsFieldsByHelpPathKeys(
              supplyHelpKeys[SupplyDrawerTitles.Labels],
              LABELS_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS
          );

    const waterfallPrefilteringDefinitionsFields: DefinationField[] =
        supplyHelpKeys[SupplyDrawerTitles.WaterfallPrefiltering];

    const floorsDefinitionsFields: DefinationField[] = supplyHelpKeys[SupplyDrawerTitles.Floors];
    const affiliateDefinitionsFields: DefinationField[] = supplyHelpKeys[SupplyDrawerTitles.Affiliate];

    return {
        generalDefinitionsFields,
        appDetailsDefinitionsFields,
        siteDetailsDefinitionsFields,
        brandSafetyDefinitionsFields,
        exchangeSafetyDefinitionsFields,
        internalDefinitionsFields,
        supplyDetailsDefinitionsFields,
        customPixelsDefinitionsFields,
        affiliateDefinitionsFields,
        labelsDefinitionsFields,
        waterfallPrefilteringDefinitionsFields,
        floorsDefinitionsFields,
    };
};
