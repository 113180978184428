import { useState, useMemo } from "react";
import { GetGeographiesParams, useGetGeographiesQuery } from "@app/core/services/console";

import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";

import { useLoadOnScroll } from "@app/core/components";

const MAX = 25;
const DMA_MAX = 500;

const getGeographiesParams = ({ keyword, page, filter }: GetGeographiesParams) => {
    // TODO: Temp hack until DMA pagination is fixed in CTVUI-2348
    if (filter === "dma") {
        return {
            max: DMA_MAX,
            keyword,
            filter,
        };
    }
    return {
        max: MAX,
        keyword,
        page,
        filter,
    };
};

export const useDimensionsSelect = (filter: GetGeographiesParams["filter"]) => {
    const [pageByKeyword, setPageByKeyword] = useState({
        "": 1,
    });
    const [search, setSearch] = useState("");
    const [keyword, setKeyword] = useState("");
    const { data, isFetching, originalArgs } = useGetGeographiesQuery(
        getGeographiesParams({
            keyword,
            page: pageByKeyword[`${keyword}${filter}`] || 1,
            filter,
        })
    );
    const { loadMore, hasMore, options } = useLoadOnScroll(
        data,
        isFetching,
        `${originalArgs?.keyword || ""}${originalArgs?.filter || ""}`,
        originalArgs?.page || 1,
        data?.length === MAX,
        () =>
            setPageByKeyword((prev) => {
                const key = `${originalArgs?.keyword || ""}${originalArgs?.filter || ""}`;
                const currentPage = prev[key] || 1;
                return {
                    ...prev,
                    [key]: currentPage + 1,
                };
            })
    );

    const debouncedHandleSearch = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        []
    );

    const handleChangeSearch = (search: string): void => {
        setSearch(search);
        debouncedHandleSearch(search);
    };

    return {
        handleChangeSearch,
        hasMore,
        isLoading: isFetching,
        isCurrent: originalArgs?.keyword === search,
        loadMore,
        options,
        search,
    };
};
