import { FC } from "react";
import { useDealListTypeFilter } from "./useDealListTypeFilter";
import { Form, Select } from "antd";
import { TagRender } from "@app/core/components/TagRender";
import { HelpTooltip } from "@app/core/components/HelpTooltip";
import { DealsHelpKeys } from "@app/features/deals/constants";
import { InfoCircleOutlined } from "@ant-design/icons";
import { brandCobalt } from "@rubicon/antd-components";

export const DealListTypeFilter: FC = () => {
    const { options, value, handleChange } = useDealListTypeFilter();

    return (
        <Form.Item
            data-sdet="deal-list-type-filter"
            label={
                <HelpTooltip
                    helpKeyList={DealsHelpKeys}
                    helpKey={DealsHelpKeys.DealType}
                    tooltipPlacement="topRight"
                    popover={true}
                >
                    Deal Type
                    <InfoCircleOutlined style={{ color: brandCobalt, marginLeft: 2 }} />
                </HelpTooltip>
            }
            style={{ marginBottom: 0 }}
        >
            <Select
                allowClear={Boolean(value)}
                onChange={handleChange}
                optionFilterProp="label"
                showSearch
                value={value}
                style={{ width: "100%" }}
                tagRender={TagRender}
                placeholder={"All"}
                mode="multiple"
                options={options}
            ></Select>
        </Form.Item>
    );
};
