import { FC } from "react";
import { Form, Select } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAdSourceAssigneeField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceConfigurationSection/Fields/AdSourceAssigneeField/useAdSourceAssigneeField";

export const AdSourceAssigneeField: FC = () => {
    const { value, onChange, options, isFetching } = useAdSourceAssigneeField();

    return (
        <Form.Item
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ASSIGNEE}
            data-sdet="ad-source-assignee-field"
            label="Assignee"
        >
            <Select
                allowClear
                loading={isFetching}
                onChange={onChange}
                optionFilterProp="label"
                options={options}
                placeholder="Select Assignee…"
                showSearch
                value={value}
            />
        </Form.Item>
    );
};
