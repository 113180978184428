import { useEffect } from "react";
import { Flex, Space, Typography } from "antd";
import { useSeatAuthContext } from "@app/core/auth";
import { Loading } from "@app/core/components";
import { BrandSafetyCollapsePanel, BrandSafetyCollapsePanelKeys } from "../BrandSafetyCollapsePanel";
import { useGetBrandByIdQuery, useGetPublisherByIdQuery } from "@app/core/services";
import { useSeatTree } from "../../SeatTree/useSeatTree";
import { useSupplyById } from "@app/features/inventory/InventoryFloors/useSupplyById";
import { BrandSafetyHeaderControls } from "../../BrandSafetyHeaderControls";
import { useBrandSafetyCollapsePanel } from "../BrandSafetyPublisherPage/useBrandSafetyCollapsePanel";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { INVENTORY_LIST_PAGES } from "@app/features/inventory/constants";
import { InventoryFloorsBreadcrumb } from "@app/features/inventory/InventoryFloors/InventoryFloorsBreadcrumb";

export const BrandSafetySupplyPage = () => {
    const { context } = useSeatAuthContext();
    const { expandKeys, setSelectedKeys } = useSeatTree();
    const { supply, isFetching: isFetchingSupply } = useSupplyById();
    const { data: brand, isFetching: isFetchingBrand } = useGetBrandByIdQuery(supply?.brand.id as number, {
        skip: !supply?.brand.id,
    });
    const { data: publisher, isFetching: isFetchingPublisher } = useGetPublisherByIdQuery(
        brand?.publisher.id as number,
        {
            skip: !brand?.publisher.id,
        }
    );
    const { activeKey, onChange, expandAll, collapseAll } = useBrandSafetyCollapsePanel({
        defaultActiveKey: BrandSafetyCollapsePanelKeys.SUPPLY,
    });

    useEffect(() => {
        if (context && supply) {
            expandKeys([
                `seat-${context.id}`,
                `publisher-${supply.brand.publisher.id}`,
                `brand-${supply.brand.id}`,
                `supply-${supply.id}`,
            ]);
            setSelectedKeys([`supply-${supply.id}`]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context, supply]);

    if (!context || isFetchingSupply || isFetchingPublisher || isFetchingBrand || !supply) {
        return <Loading />;
    }

    const items = [
        {
            path: ROUTE_FORMATTERS.SEAT_INVENTORY(context.id, INVENTORY_LIST_PAGES.BRAND_SAFETY),
            title: context.name,
        },
        {
            path: ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_PUBLISHER(
                context.id,
                supply.brand.publisher.id
            ),
            title: supply.brand.publisher.name,
        },
        {
            path: ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_BRAND(context.id, supply.brand.id),
            title: supply.brand.name,
        },
        { title: supply.name },
    ];

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Flex justify="space-between" align="baseline">
                <Space align="baseline">
                    <Typography.Title level={5}>Supply Brand Safety: </Typography.Title>
                    <InventoryFloorsBreadcrumb items={items} />
                </Space>
                <BrandSafetyHeaderControls expandAll={expandAll} collapseAll={collapseAll} />
            </Flex>
            <BrandSafetyCollapsePanel
                seat={context}
                publisher={publisher}
                brand={brand}
                supply={supply}
                activeKey={activeKey}
                onChange={onChange}
            />
        </Space>
    );
};
