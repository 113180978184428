import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useHistory, useParams } from "react-router-dom";
import { useDealDetailsDrawer } from "@app/features/deals/DealDetailsDrawer/useDealDetailsDrawer";
import { useMemo, useState } from "react";

interface UseDealDetailsDrawerNavTitle {
    goDealDetailsPage: () => void;
    seatId: number;
    visibleCopy: boolean;
    handleVisibleCopy: () => void;
    handleHideCopy: () => void;
    copyUrl: string;
}

export const useDealDetailsDrawerNavTitle = (dealId: number): UseDealDetailsDrawerNavTitle => {
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();
    const [visibleCopy, setVisibleCopy] = useState<boolean>(false);
    const { closeDealDetailsDrawer } = useDealDetailsDrawer();
    const url = ROUTE_FORMATTERS.SEAT_DEALS_DEAL_DETAILS_DRAWER(seatId, dealId);
    const { origin, pathname } = window.location;
    const copyUrl = useMemo(() => `${origin}${pathname}#${url}`, [url, origin, pathname]);

    const goDealDetailsPage = () => {
        closeDealDetailsDrawer();
        history.push(ROUTE_FORMATTERS.SEAT_DEAL_DETAILS(seatId, dealId));
    };

    const handleVisibleCopy = () => {
        setVisibleCopy(true);
    };
    const handleHideCopy = () => {
        setVisibleCopy(false);
    };

    return {
        goDealDetailsPage,
        seatId: Number(seatId),
        handleVisibleCopy,
        handleHideCopy,
        visibleCopy,
        copyUrl,
    };
};
