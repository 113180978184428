import { FC } from "react";
import { Button, Col, Form, Row, Select } from "antd";
import { LoadableComponent } from "@app/core/components/LoadableComponent";
import { SeatAdSourcesTimingStatsChart } from "@app/features/seatAdSources/seatAdSourcesCharts";
import { useSeatAdSourcesDetailsTimingStats } from "./useSeatAdSourcesDetailsTimingStats";

interface Props {
    adSourceId: number;
}

export const SeatAdSourcesDetailsTimingStats: FC<Props> = ({ adSourceId }) => {
    const {
        timingStats,
        loadingState,
        form,
        regionSelectOptions,
        onOptionsChange,
        selectedRegion,
        refetchTimingStats,
    } = useSeatAdSourcesDetailsTimingStats(adSourceId);

    return (
        <LoadableComponent loadingState={loadingState} dataSdet="seat-ad-sources-details-timing-stats">
            <Form form={form} layout="vertical" onFieldsChange={onOptionsChange}>
                <Row gutter={16} align="middle" justify="space-between">
                    <Col span={6}>
                        <Form.Item name="region" label="Region">
                            <Select options={regionSelectOptions} defaultValue={regionSelectOptions[0]} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Button onClick={refetchTimingStats}>Refresh</Button>
                    </Col>
                </Row>
            </Form>
            <SeatAdSourcesTimingStatsChart timingStats={timingStats} region={selectedRegion} />
        </LoadableComponent>
    );
};
