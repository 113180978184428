import { FC } from "react";
import { DealList } from "@app/core/services";
import { DealCreateFormDemand } from "../DealForm/DealFormDemand";

interface Props {
    dealAdSource: DealList;
}

export const DealCopyFormPageContent: FC<Props> = ({ dealAdSource }) => {
    if (dealAdSource?.deal?.entityType === "Deal") {
        return <span>Copying Legacy Deals is not yet Supported</span>;
    }

    return <DealCreateFormDemand mode="copy" dealAdSource={dealAdSource} />;
};
