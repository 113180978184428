import { MidRoll, PreRoll } from "./Fields";
import { PostRoll } from "./Fields/PostRoll";
import { CuePoints } from "./Fields/CuePoints";
import { useAdBreakRules } from "./useAdBreakRules";

export const enum AdBreakRuleName {
    PRE_ROLL_NAME = "preName",
    MID_ROLL_NAME = "midName",
    POST_ROLL_NAME = "postName",
}

export const enum AdBreakRuleIndex {
    MID_ROLL_INDEX = "midIndex",
}

export const enum AdBreakRuleAds {
    PRE_ROLL_ADS = "preAds",
    MID_ROLL_ADS = "midAds",
    POST_ROLL_ADS = "postAds",
}

export const enum AdBreakRuleDuration {
    PRE_ROLL_DURATION = "preDuration",
    MID_ROLL_DURATION = "midDuration",
    POST_ROLL_DURATION = "postDuration",
}

export interface AdBreakRulePreRoll {
    [AdBreakRuleName.PRE_ROLL_NAME]: string;
    [AdBreakRuleAds.PRE_ROLL_ADS]: number;
    [AdBreakRuleDuration.PRE_ROLL_DURATION]: number;
}

export interface AdBreakRuleMidRoll {
    [AdBreakRuleName.MID_ROLL_NAME]: string;
    [AdBreakRuleIndex.MID_ROLL_INDEX]: number;
    [AdBreakRuleAds.MID_ROLL_ADS]: number;
    [AdBreakRuleDuration.MID_ROLL_DURATION]: number;
}

export interface AdBreakRulePostRoll {
    [AdBreakRuleName.POST_ROLL_NAME]: string;
    [AdBreakRuleAds.POST_ROLL_ADS]: number;
    [AdBreakRuleDuration.POST_ROLL_DURATION]: number;
}

export const ChannelAdBreakRules = () => {
    const { hasCuepoints } = useAdBreakRules();
    return (
        <>
            <PreRoll />
            <MidRoll />
            {hasCuepoints && <CuePoints />}
            <PostRoll />
        </>
    );
};
