import { FC } from "react";
import { Card, Col, Row } from "antd";
import { FrequencyCaps } from "@app/features/inventory/components/Fields";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import {
    LiveStreamAcceleration,
    LSAWindowSizeSeconds,
    AdvFreqCapping,
    AllowVast,
    ExtendedImpWaitTime,
    InternalOnlyFields,
    PartnerDomain,
    PublisherName,
    SupplyName,
    UnwrapVast,
    UDOptOut,
} from "./Fields";
import { useDetailedConfiguration } from "./useDetailedConfigration";

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 12 };

export const DetailedConfigurationSection: FC = () => {
    const { hasFreqCaps, isInternalOnly, isUDOptOutShown, isLSAWindowSizeSecondsShown, isLiveStreamAccelerationShown } =
        useDetailedConfiguration();
    return (
        <Card bordered={false}>
            <Row gutter={16}>
                <Col {...COL_SIZES}>
                    <PublisherName />
                    <SupplyName />
                    <PartnerDomain />
                    <AllowVast />
                    {isLiveStreamAccelerationShown && <LiveStreamAcceleration />}
                    {isLSAWindowSizeSecondsShown && <LSAWindowSizeSeconds />}
                    <UnwrapVast />
                    <ExtendedImpWaitTime />
                    <AdvFreqCapping />
                    {hasFreqCaps && (
                        <FrequencyCaps
                            name={CHANNEL_FORM_FIELDS.ADV_DOMAIN_FREQ_CAPPS.name}
                            label={CHANNEL_FORM_FIELDS.ADV_DOMAIN_FREQ_CAPPS.label}
                            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 24, xxl: 22 }}
                        />
                    )}
                    {isUDOptOutShown && <UDOptOut />}
                </Col>
                {isInternalOnly && (
                    <Col {...COL_SIZES}>
                        <InternalOnlyFields />
                    </Col>
                )}
            </Row>
        </Card>
    );
};
