import { Dashes } from "@app/core/components";
import { Card, Form } from "antd";
import { classNameCardBody } from "../../InventoryHealthDetailsPages/constants";
import { SeeMoreUnorderedList } from "@app/features/inventory/SeeMoreUnorderedList";
import { TextAreaReadOnlyList } from "@app/features/inventory/TextAreaReadOnlyList";
import { BRAND_SAFETY_BLOCK_TYPE } from "@app/features/inventory/InventoryBrandSafety/InventoryAdvancedBrandSafetyPage/constants";
import { AdvancedBrandSafety } from "@app/core/services";
import { FC } from "react";

interface Props {
    brandSafety?: AdvancedBrandSafety;
}

export const BrandSafetyGeneralSection: FC<Props> = ({ brandSafety }) => {
    const blockType: boolean = brandSafety?.type.id === BRAND_SAFETY_BLOCK_TYPE.value;
    return (
        <Card bordered={false} className={classNameCardBody}>
            <Form.Item label="Name">
                <Dashes value={brandSafety?.name} />
            </Form.Item>
            <Form.Item label="Type">
                <Dashes value={brandSafety?.type?.name} />
            </Form.Item>
            <Form.Item label="Blocked IAB Categories" hidden={!blockType}>
                <SeeMoreUnorderedList
                    values={brandSafety?.blockedIabCategories?.map(
                        (iabCategory) => `${iabCategory.code}: ${iabCategory.name}`
                    )}
                />
            </Form.Item>
            <Form.Item label="IAB Category Block Exceptions" hidden={!blockType}>
                <SeeMoreUnorderedList
                    values={brandSafety?.blockExceptionIabCategories?.map(
                        (iabCategory) => `${iabCategory.code}: ${iabCategory.name}`
                    )}
                />
            </Form.Item>
            <Form.Item label="Reusable Advertiser Domains">
                {brandSafety?.seatAdvertiserDomainFilterListDefs && (
                    <TextAreaReadOnlyList
                        style={{ maxWidth: "35rem" }}
                        values={brandSafety?.seatAdvertiserDomainFilterListDefs?.map((domain) => domain?.name)}
                    />
                )}
            </Form.Item>
            <Form.Item label="Additional Advertiser Domains">
                {brandSafety?.advertiserDomains && (
                    <TextAreaReadOnlyList style={{ maxWidth: "35rem" }} values={brandSafety?.advertiserDomains} />
                )}
            </Form.Item>
        </Card>
    );
};
