import { FC } from "react";
import { FilterSelect } from "@app/core/components/FilterSelect";
import { useSeatAdSourcesPacingAssigneeFilter } from "./useSeatAdSourcesPacingAssigneeFilter";
import { HelpTooltip } from "@app/core/components";
import { AdSourcesPacingHelpKeys } from "@app/features/seatAdSources/constants";

export const SeatAdSourcesPacingAssigneeFilter: FC = () => {
    const { handleChangeValue, options, value, isLoading } = useSeatAdSourcesPacingAssigneeFilter();

    return (
        <FilterSelect
            dataSdet="seat-ad-sources-pacing-assignee-filter"
            isLoading={isLoading}
            label={
                <HelpTooltip
                    helpKeyList={AdSourcesPacingHelpKeys}
                    helpKey={AdSourcesPacingHelpKeys.Assignee}
                    popover={true}
                >
                    Assignee
                </HelpTooltip>
            }
            mode="multiple"
            onChange={handleChangeValue}
            options={options}
            value={value}
        />
    );
};
