import { FC } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Loading } from "@app/core/components";
import { Card, Layout } from "antd";
import { useParams } from "react-router-dom";
import { useAdvertiserDomainDetailsPage } from "./useAdvertiserDomainDetailsPage";
import { AdvertiserDomainActions } from "./AdvertiserDomainDrawer/AdvertiserDomainActions";
import { AdvertiserDomainDetailsContent } from "./AdvertiserDomainDrawer/AdvertiserDomainDetailsContent";

export const AdvertiserDomainDetailsPage: FC = () => {
    const { id, seatId } = useParams<{ id: string; seatId: string }>();
    const { name, data, isLoading, handleGoBack, postDelete } = useAdvertiserDomainDetailsPage(seatId, id);

    if (isLoading) {
        return <Loading />;
    }

    if (!data) {
        return null;
    }

    return (
        <>
            <PageHeader title={name} onBack={handleGoBack}>
                <AdvertiserDomainActions
                    seatId={seatId}
                    aDomainId={Number(id)}
                    name={name}
                    postDeleteEvent={postDelete}
                />
            </PageHeader>
            <Layout.Content>
                <Card>
                    <AdvertiserDomainDetailsContent aDomain={data} isDetailsPage />
                </Card>
            </Layout.Content>
        </>
    );
};
