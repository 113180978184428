import { FC } from "react";
import { Form, Radio } from "antd";
import { MarketplaceInfoDrawerMode } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { useEntityTypeRadio } from "./useEntityTypeRadio";

interface Props {
    marketplaceInfoMode: MarketplaceInfoDrawerMode;
    hidden?: boolean;
}

export const EntityTypeRadio: FC<Props> = ({ marketplaceInfoMode, hidden }) => {
    const { onChange } = useEntityTypeRadio(marketplaceInfoMode);

    return (
        <Form.Item name={["dealUnderEdit", "entityType"]} label="Deal" style={{ marginBottom: 0 }} hidden={hidden}>
            <Radio.Group onChange={onChange}>
                <Radio value="DemandDeal">Demand Deal</Radio>
                <Radio value="Deal">Legacy Deal</Radio>
            </Radio.Group>
        </Form.Item>
    );
};
