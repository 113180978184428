import { PageHeader } from "@ant-design/pro-layout";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { css } from "@emotion/css";
import { Tabs } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";

export const ClassificationsPageHeader = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const { pathname } = useLocation();
    const classificationsTab = pathname.split("/")[5];
    const history = useHistory();
    return (
        <PageHeader
            ghost={false}
            title="Classifications"
            style={{ paddingBottom: 0 }}
            footer={
                <Tabs
                    activeKey={classificationsTab}
                    items={[
                        { key: "advertisers", label: "Advertisers" },
                        { key: "industries", label: "Industries" },
                        { key: "labels", label: "Labels" },
                    ]}
                    onTabClick={(key) => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_TABS(seatId, key))}
                    tabBarStyle={{ marginBottom: 0 }}
                    className={css`
                        .ant-tabs-tab {
                            font-size: 16px;
                        }
                    `}
                />
            }
        />
    );
};
