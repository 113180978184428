import { AD_SOURCE_FIELDS, DealTypeIds } from "@app/features/seatAdSources/constants";
import { Form } from "antd";
import { findDealsWithSpecificTypes } from "../../../../utils/findDealsWithSpecificTypes";
import { MarketplaceInfo } from "@app/core/services";

const { useWatch } = Form;

export const useIsReadOnly = () => {
    const deals = useWatch<MarketplaceInfo[]>(AD_SOURCE_FIELDS.DEMAND.name);
    if (!deals || !deals.length) {
        return false;
    }
    const pgDeals = findDealsWithSpecificTypes({ deals, dealTypes: [DealTypeIds.PROGRAMMATIC_GUARANTEED] });
    if (pgDeals.length > 0) {
        return true;
    }
    return false;
};
