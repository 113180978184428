import { Form, FormListFieldData } from "antd";
import { AdBreakRuleIndex, AdBreakRuleMidRoll } from "../../AdBreakRules";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

const { useFormInstance, useWatch } = Form;

const INDEX_DEFAULT = "Default";
const INDEX_AD_BREAK = "Ad Break";

interface UseMidRoll {
    handleAdd: (add: (defaultValue?: Partial<AdBreakRuleMidRoll>) => void) => void;
    getSubTitle: (field: FormListFieldData) => string;
    handleRemoveAll: (remove: (index: number | number[]) => void, totalFields: number) => void;
    preRollValues: unknown[] | undefined;
    postRollValues: unknown[] | undefined;
}

export const useMidRoll = (): UseMidRoll => {
    const form = useFormInstance();
    const values = useWatch(CHANNEL_FORM_FIELDS.AD_BREAK_RULES_MID.name);
    const preRollValues: unknown[] | undefined = useWatch(CHANNEL_FORM_FIELDS.AD_BREAK_RULES_PRE.name);
    const postRollValues: unknown[] | undefined = useWatch(CHANNEL_FORM_FIELDS.AD_BREAK_RULES_POST.name);

    const handleAdd = (add: (defaultValue?: Partial<AdBreakRuleMidRoll>) => void) => {
        const index = values?.length ? values.length : -1;
        add({ [AdBreakRuleIndex.MID_ROLL_INDEX]: index });
    };

    const handleRemoveAll = (remove: (index: number | number[]) => void, totalFields: number) => {
        for (let i = totalFields; i >= 0; i--) {
            remove(i);
        }
        form.setFieldValue(CHANNEL_FORM_FIELDS.CUE_POINTS.name, []);
    };

    const getSubTitle = (field: FormListFieldData) => {
        if (field.name === 0) {
            return INDEX_DEFAULT;
        }

        const indexValue = values?.[field.name]?.[AdBreakRuleIndex.MID_ROLL_INDEX];
        return `${INDEX_AD_BREAK} ${indexValue + 1}`;
    };

    return {
        handleAdd,
        getSubTitle,
        handleRemoveAll,
        preRollValues,
        postRollValues,
    };
};
