export enum SeatAdSourcesActionButton {
    Edit,
    Performance,
    CreativeReview,
}

export enum SeatAdSourcesMoreMenuItem {
    Copy = "copy",
    CreativeReview = "creativeReview",
    AdServing = "adServing",
    VastErrors = "vastErrors",
    Ladle = "ladle",
    TimingStats = "timingStats",
    ChangeHistory = "changeHistory",
}
