import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ContentTransparencyRulesFormPayload, useCreateContentTransparencyRulesMutation } from "@app/core/services";
import { Layout, Typography, notification } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { PageHeader } from "@ant-design/pro-layout";
import {
    CONTENT_TRANSPARENCY_RULES_CREATE_FORM_INITIAL_VALUES,
    ContentTransparencyRulesForm,
} from "./ContentTransparencyRulesForm";
import { CONTROLS_ROUTES } from "@app/features/controls/constants";

export const CONTENT_TRANSPARENCY_RULES_CREATE_PAGE_SDET = "content-transparency-rules-create-page";

export const ContentTransparencyRulesCreatePage = () => {
    const history = useHistory();
    const [createContentTransparencyRules, { isLoading }] = useCreateContentTransparencyRulesMutation();
    const { seatId } = useParams<{ seatId: string }>();

    const handleSubmit = (values: ContentTransparencyRulesFormPayload) => {
        const payload = {
            ...values,
            name: values.name?.trim(),
            contentFields: values.contentFields?.map(({ value, label }) => ({ id: value, name: label })),
        };
        createContentTransparencyRules({
            body: payload,
        })
            .unwrap()
            .then((res) => {
                notification.success({
                    message: (
                        <>
                            <Typography.Text strong>{res.name}</Typography.Text> has successfully been created
                        </>
                    ),
                });
                history.push(
                    ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, [
                        CONTROLS_ROUTES.BRAND_SAFETY,
                        CONTROLS_ROUTES.CONTENT_TRANSPARENCY_RULES,
                    ])
                );
            })
            .catch((err) => {
                notification.error({
                    message:
                        err.data?.errorDescription ||
                        err.data?.errorCode ||
                        "Something wrong has occurred, please contact your account manager",
                });
            });
    };

    return (
        <Layout data-sdet={CONTENT_TRANSPARENCY_RULES_CREATE_PAGE_SDET}>
            <PageHeader
                title="Add Content Transparency Rule"
                onBack={() =>
                    history.push(
                        ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, [
                            CONTROLS_ROUTES.BRAND_SAFETY,
                            CONTROLS_ROUTES.CONTENT_TRANSPARENCY_RULES,
                        ])
                    )
                }
            />
            <ContentTransparencyRulesForm
                isLoading={isLoading}
                initialValues={CONTENT_TRANSPARENCY_RULES_CREATE_FORM_INITIAL_VALUES}
                handleSubmit={handleSubmit}
            />
        </Layout>
    );
};
