import { useGetPbsBidderConfigsQuery } from "@app/core/services";
import { useParams } from "react-router-dom";

export const usePbsBidderConfigs = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data: pbsBidderConfigs = [], isFetching } = useGetPbsBidderConfigsQuery(Number(seatId));
    return {
        pbsBidderConfigs,
        isLoading: isFetching,
    };
};
