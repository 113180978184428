import { GUTTER } from "@app/features/seatAdSources/constants";
import { Card, Row } from "antd";
import { FC } from "react";
import { CpmRateInput, CurrencySelect, ImpressionsInput, PricingModelSelect } from "./Fields";

export const PricingSection: FC = () => {
    return (
        <Card bordered={false}>
            <Row gutter={GUTTER}>
                <PricingModelSelect />
                <ImpressionsInput />
                <CpmRateInput />
                <CurrencySelect />
            </Row>
        </Card>
    );
};
