import { FC } from "react";
import { Col, Collapse, Form, Row, Space, Tag } from "antd";
import { css } from "@emotion/css";
import { brandCobalt, Help } from "@rubicon/antd-components";
import { useUserAccess } from "@app/core/auth";
import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM } from "@app/core/components/constants";
import { format } from "@rubicon/utils";
import { Dashes } from "@app/core/components";
import moment from "moment-timezone";

const className = css`
    .ant-collapse-item > .ant-collapse-header {
        max-width: 85px;
        padding: 0;
        color: ${brandCobalt};
    }
`;

const classNameInternal = css`
    margin-top: 0.7rem;
`;

export const formItemOffset = css({ marginBottom: "1rem", overflowWrap: "break-word" });

const getBuyersName = (buyers: Record<string, unknown>[] | undefined | null) =>
    buyers ? buyers.map(({ name }) => name).join(", ") : format.constants.DEFAULT_DASHES;

interface Props {
    dealTransactionName: string;
    dealPricingTypeName: string;
    statusName: string;
    dealOriginName: string;
    buyers?: Record<string, unknown>[] | null;
    timeZoneCode: string;
    timeZoneName: string;
    dealType: string;
    dealId: string;
    name: string;
    dsp: string;
    startDate: string;
    endDate: string;
    sharedMarketplaces?: { id: number; name: string; code: string }[];
    sharedSeats?: { id: number; name: string; code: string }[];
    publisherName?: string;
    publisherEmail?: string;
}

export const DealDetailsTerms: FC<Props> = ({
    dealOriginName,
    dealType,
    statusName,
    timeZoneCode,
    timeZoneName,
    dealTransactionName,
    dealPricingTypeName,
    buyers,
    dealId,
    name,
    dsp,
    startDate,
    endDate,
    sharedMarketplaces,
    sharedSeats,
    publisherName,
    publisherEmail,
}) => {
    const { hasInternalAccess } = useUserAccess();
    const hasSharedMarketPlaces = Boolean(sharedMarketplaces?.length);
    const hasSharedSeats = Boolean(sharedSeats?.length);
    const isBuyerDeal = Boolean(buyers?.length);

    return (
        <>
            <Row>
                <Col sm={8}>
                    <Form.Item className={formItemOffset} label="Deal Type">
                        {dealType}
                    </Form.Item>
                    <Form.Item className={formItemOffset} label="Transaction Type">
                        {dealTransactionName}
                    </Form.Item>
                    <Form.Item className={formItemOffset} label="Deal ID">
                        {dealId}
                    </Form.Item>
                    <Form.Item className={formItemOffset} label="DSP">
                        {dsp}
                    </Form.Item>
                    <Space size="large">
                        <Form.Item className={formItemOffset} label="Start Date">
                            {moment(startDate).tz(timeZoneCode).format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM)}
                        </Form.Item>
                        {Boolean(endDate) && (
                            <Form.Item className={formItemOffset} label="End Date">
                                {moment(endDate).tz(timeZoneCode).format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM)}
                            </Form.Item>
                        )}
                    </Space>
                    {isBuyerDeal && (
                        <Form.Item className={formItemOffset} label="Publisher Name">
                            <Dashes value={publisherName} />
                        </Form.Item>
                    )}
                    {hasInternalAccess && (
                        <Form.Item
                            className={formItemOffset}
                            label={
                                <Space size="small">
                                    Deal Origin
                                    <Help text="Origination source for the deal. Primarily for reporting purposes." />
                                </Space>
                            }
                        >
                            {dealOriginName}
                        </Form.Item>
                    )}
                </Col>
                <Col sm={8}>
                    <Form.Item className={formItemOffset} label="Pricing Type">
                        {dealPricingTypeName}
                    </Form.Item>
                    <Form.Item className={formItemOffset} label="Deal Name">
                        {name}
                    </Form.Item>
                    <Form.Item className={formItemOffset} label="Buyers">
                        {getBuyersName(buyers)}
                    </Form.Item>
                    <Form.Item className={formItemOffset} label="Time Zone">
                        {timeZoneName}
                    </Form.Item>
                    <Form.Item className={formItemOffset} label="Status">
                        {statusName}
                    </Form.Item>
                    {isBuyerDeal && (
                        <Form.Item className={formItemOffset} label="Publisher Email">
                            <Dashes value={publisherEmail} />
                        </Form.Item>
                    )}
                </Col>
            </Row>
            {hasInternalAccess && (
                <Collapse defaultActiveKey={["1"]} expandIconPosition="end" className={className} ghost>
                    <Collapse.Panel key={1} header="Internal">
                        <Row className={classNameInternal}>
                            <Col sm={8}>
                                <Form.Item label="Shared Seats">
                                    {hasSharedSeats ? (
                                        sharedSeats?.map(({ name, id }) => <Tag key={id}>{name}</Tag>)
                                    ) : (
                                        <Dashes />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col sm={10}>
                                <Form.Item label="Shared Marketplaces">
                                    {hasSharedMarketPlaces ? (
                                        sharedMarketplaces?.map(({ name, id }) => <Tag key={id}>{name}</Tag>)
                                    ) : (
                                        <Dashes />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Collapse.Panel>
                </Collapse>
            )}
        </>
    );
};
