import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import conf from "@app/core/conf";

export const AD_SOURCES_VAST_ERRORS_TAG = "AdSourcesVastErrorsTag";
export const AD_UNIT_VAST_STATS = "adUnitVastStats";
export const BRAND_VAST_STATS = "brandVastStats";
export const CREATIVE_DETAILS = "creativeDetails";
export const CREATIVE_SAMPLES = "creativeSamples";
export const CREATIVE_SAMPLES_RAW = "creativeSamplesRaw";
export const MEDIA_URLS = "mediaUrls";
export const PUBLISHER_VAST_STATS = "publisherVastStats";
export const RAW_CREATIVE_SAMPLES = "rawCreativeSamples";
export const CREATIVE_SAMPLES_AD_SOURCE = "creativeSamplesAdSource";
export const TAG_PARAMS = "tagParams";
export const SEAT_VAST_STATS = "seatVastStats";
export const SUPPLY_VAST_STATS = "supplyVastStats";

export const diagnosticsApi = createApi({
    reducerPath: "diagnostics",
    tagTypes: [
        AD_SOURCES_VAST_ERRORS_TAG,
        AD_UNIT_VAST_STATS,
        BRAND_VAST_STATS,
        CREATIVE_DETAILS,
        CREATIVE_SAMPLES,
        CREATIVE_SAMPLES_RAW,
        MEDIA_URLS,
        PUBLISHER_VAST_STATS,
        SEAT_VAST_STATS,
        SUPPLY_VAST_STATS,
        TAG_PARAMS,
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: `${conf.mctvDiagnosticsApiRoot}/resources/diagnostics/`,
        credentials: "include",
    }),
    endpoints: () => ({}),
});
