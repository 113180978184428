import { useCallback, useEffect } from "react";
import moment from "moment-timezone";
import { Currency, DealStatus, SeatAdSourcesStatus, TimeZone } from "@app/core/services";
import { store, useAppDispatch, useAppSelector } from "@app/core/store";
import { consumeAllSyncedFieldsState, consumeSyncedFieldState, selectSyncedFields } from "./reducer";
import { SyncedFields } from "./types";

export interface ConsumerCallbacks {
    onConsumeStartDateUpdate?: (startDate: moment.Moment | null) => void;
    onConsumeEndDateUpdate?: (endDate: moment.Moment | null) => void;
    onConsumeTimeZoneUpdate?: (timeZone: TimeZone | null) => void;
    onConsumeCpmRateUpdate?: (cpmRate: string | null) => void;
    onConsumeStatusUpdate?: (status: DealStatus | SeatAdSourcesStatus | null) => void;
    onConsumeCurrencyUpdate?: (currency: Currency | null) => void;
    onConsumeImpressionsUpdate?: (impressions: number | string | null) => void;
}

interface UseSyncedFieldsConsumer {
    consumeAllSyncedFields: () => SyncedFields;
}

export const useSyncedFieldsConsumer = (consumerCallbacks?: ConsumerCallbacks): UseSyncedFieldsConsumer => {
    const dispatch = useAppDispatch();
    const syncedFields = useAppSelector(selectSyncedFields);

    const startDate = syncedFields.startDate;
    const endDate = syncedFields.endDate;
    const timeZone = syncedFields.timeZone;
    const cpmRate = syncedFields.cpmRate;
    const status = syncedFields.status;
    const currency = syncedFields.currency;
    const impressions = syncedFields.impressions;

    const consumeStartDateCallback = consumerCallbacks?.onConsumeStartDateUpdate;
    const consumeEndDateCallback = consumerCallbacks?.onConsumeEndDateUpdate;
    const consumeTimeZoneCallback = consumerCallbacks?.onConsumeTimeZoneUpdate;
    const consumeCpmRateCallback = consumerCallbacks?.onConsumeCpmRateUpdate;
    const consumeStatusCallback = consumerCallbacks?.onConsumeStatusUpdate;
    const consumeCurrencyCallback = consumerCallbacks?.onConsumeCurrencyUpdate;
    const consumeImpressionsCallback = consumerCallbacks?.onConsumeImpressionsUpdate;

    useEffect(() => {
        if (consumeStartDateCallback && startDate !== undefined) {
            dispatch(consumeSyncedFieldState("startDate"));
            consumeStartDateCallback(startDate);
        }
    }, [startDate, consumeStartDateCallback, dispatch]);

    useEffect(() => {
        if (consumeEndDateCallback && endDate !== undefined) {
            dispatch(consumeSyncedFieldState("endDate"));
            consumeEndDateCallback(endDate);
        }
    }, [endDate, consumeEndDateCallback, dispatch]);

    useEffect(() => {
        if (consumeTimeZoneCallback && timeZone !== undefined) {
            dispatch(consumeSyncedFieldState("timeZone"));
            consumeTimeZoneCallback(timeZone);
        }
    }, [timeZone, consumeTimeZoneCallback, dispatch]);

    useEffect(() => {
        if (consumeCpmRateCallback && cpmRate !== undefined) {
            dispatch(consumeSyncedFieldState("cpmRate"));
            consumeCpmRateCallback(cpmRate);
        }
    }, [cpmRate, consumeCpmRateCallback, dispatch]);

    useEffect(() => {
        if (consumeStatusCallback && status !== undefined) {
            dispatch(consumeSyncedFieldState("status"));
            consumeStatusCallback(status);
        }
    }, [status, consumeStatusCallback, dispatch]);

    useEffect(() => {
        if (consumeCurrencyCallback && currency !== undefined) {
            dispatch(consumeSyncedFieldState("currency"));
            consumeCurrencyCallback(currency);
        }
    }, [currency, consumeCurrencyCallback, dispatch]);

    useEffect(() => {
        if (consumeImpressionsCallback && impressions !== undefined) {
            dispatch(consumeSyncedFieldState("impressions"));
            consumeImpressionsCallback(impressions);
        }
    }, [impressions, consumeImpressionsCallback, dispatch]);

    const consumeAllSyncedFields = useCallback(() => {
        const currentSyncedFields = store.getState().syncedFields.fields;
        dispatch(consumeAllSyncedFieldsState());
        return currentSyncedFields;
    }, [dispatch]);

    return {
        consumeAllSyncedFields,
    };
};
