import { ErrorResponse } from "@app/core/services";
import { notification } from "antd";
import { useEffect } from "react";

export const useNotificationError = (error: ErrorResponse | unknown) => {
    // To suppress warning: triggering nested component updates from render is not allowed,
    // if necessary, trigger nested updates in componentDidUpdate
    useEffect(() => {
        if (error) {
            notification.error({
                message: (error as ErrorResponse)?.data?.errorDescription || "Something went wrong, please try again",
            });
        }
    }, [error]);
};

export default useNotificationError;
