import {
    Column,
    ColumnOptions,
    DualAxes,
    DualAxesOptions,
    Funnel,
    FunnelOptions,
    Line,
    LineOptions,
} from "@antv/g2plot";
import { FC, useEffect, useRef } from "react";
import { PlotProps, PlotOptions, Plots, PlotTypes, PlotData } from "./types";
import { getOptionsWithData, optionsIgnoreData } from "./utils";

// Note - The "@antv/g2plot" library conflates options with data. The options object includes data,
// yet separate methods are provided to update options and data. Calling update() will update options
// and data, but calling updateData() will only update the data... just noting this here to help
// avoid future headaches.

const updatePlotOptions = (type: PlotTypes, plot: Plots, options: PlotOptions, data: PlotData[]) => {
    switch (type) {
        case PlotTypes.Column:
            const columnPlot = plot as Column;
            const columnOptions = getOptionsWithData(options, data) as ColumnOptions;
            columnPlot.update(columnOptions);
            break;
        case PlotTypes.DualAxes:
            const dualAxesPlot = plot as DualAxes;
            const dualAxesOptions = getOptionsWithData(options, data) as DualAxesOptions;
            dualAxesPlot.update(dualAxesOptions);
            break;
        case PlotTypes.Funnel:
            const funnelPlot = plot as Funnel;
            const funnelOptions = getOptionsWithData(options, data) as FunnelOptions;
            funnelPlot.update(funnelOptions);
            break;
        case PlotTypes.Line:
            const linePlot = plot as Line;
            const lineOptions = getOptionsWithData(options, data) as LineOptions;
            linePlot.update(lineOptions);
            break;
    }
};

const createNewPlot = (id: string, type: PlotTypes, options: PlotOptions, data: PlotData[]): Plots => {
    switch (type) {
        case PlotTypes.Column:
            const columnOptions = getOptionsWithData(options, data) as ColumnOptions;
            return new Column(id, columnOptions);
        case PlotTypes.DualAxes:
            const dualAxesOptions = getOptionsWithData(options, data) as DualAxesOptions;
            return new DualAxes(id, dualAxesOptions);
        case PlotTypes.Funnel:
            const funnelOptions = getOptionsWithData(options, data) as FunnelOptions;
            return new Funnel(id, funnelOptions);
        case PlotTypes.Line:
            const lineOptions = getOptionsWithData(options, data) as LineOptions;
            return new Line(id, lineOptions);
    }
};

export const Plot: FC<PlotProps> = ({ id, plotConfig }) => {
    const { type, data, options } = plotConfig;
    const plotRef = useRef<Plots>();
    const optionsRef = useRef<PlotOptions>();
    const dataRef = useRef<PlotData[]>();

    const optionsDependencyCheck = optionsIgnoreData(options);
    const dataDependencyCheck = JSON.stringify(data);

    const optionsChanged = () => optionsDependencyCheck !== optionsIgnoreData(optionsRef.current);
    const dataChanged = () => dataDependencyCheck !== JSON.stringify(dataRef.current);

    useEffect(() => {
        if (plotRef.current) {
            if (optionsChanged()) {
                // update options (any data changes will also be applied here)
                optionsRef.current = options;
                dataRef.current = data;
                updatePlotOptions(type, plotRef.current, options, data);
            } else if (dataChanged()) {
                // update data only
                dataRef.current = data;
                plotRef.current?.changeData(data);
            }
        } else {
            // create new plot and render
            optionsRef.current = options;
            dataRef.current = data;
            plotRef.current = createNewPlot(id, type, options, data);
            plotRef.current.render();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionsDependencyCheck, dataDependencyCheck]);

    useEffect(() => {
        return () => {
            plotRef.current?.destroy();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // this is how the "@antv/g2plot" library knows where to render the chart
    return <div id={id} />;
};
