import { Layout } from "antd";
import { FC } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Footer } from "@app/core/components";
import { useHistory } from "react-router-dom";
import { BrandChannelForm } from "@app/features/inventory/HierarchyForms/BrandChannelForm";
import { BrandChannelFormInstance } from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormInstance";
import { useScrollToTopOnMount } from "@app/core/components/hooks";

export const BrandChannelCreatePage: FC = () => {
    useScrollToTopOnMount();
    const history = useHistory();
    const onBack = (): void => history.goBack();

    return (
        <>
            <PageHeader title="New Channel" onBack={onBack} />
            <Layout.Content>
                <BrandChannelFormInstance mode="create">
                    <BrandChannelForm />
                </BrandChannelFormInstance>
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
