import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useAppSelector } from "@app/core/store";
import { useHistory, useParams } from "react-router-dom";
import {
    InventoryDetailsDrawerType,
    selectInventoryDetailsDrawerType,
    selectIsPageInventoryDetailsDrawer,
} from "../../DetailsDrawer/reducer";
import { UnitUrlIds } from "../../DetailsDrawer/useInventoryDetailsDrawerUrlId";
import { SECTIONS_NAMES } from "../constants";

interface UseHelpKeysButton {
    onClick: () => void;
}

interface UrlParams extends UnitUrlIds {
    seatId: string;
}

export const useHelpKeysButton = (sectionKey: SECTIONS_NAMES): UseHelpKeysButton => {
    const { seatId, publisherId, brandId, supplyId, adUnitId } = useParams<UrlParams>();
    const drawerType = useAppSelector(selectInventoryDetailsDrawerType) as InventoryDetailsDrawerType;
    const isDetailsUnitPage = useAppSelector(selectIsPageInventoryDetailsDrawer);
    const history = useHistory();

    const onClick = () => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_UNIT_DEFINITION(
                isDetailsUnitPage,
                seatId,
                drawerType,
                sectionKey,
                Number(publisherId),
                Number(brandId),
                Number(supplyId),
                Number(adUnitId)
            ) as string
        );
    };

    return {
        onClick,
    };
};
