import { FC } from "react";
import { Button, Popconfirm } from "antd";
import { NONE } from "@app/core/components/constants";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { SourceSelf, FloorValue } from "@app/features/inventory/components/FloorsTable";

const getFloorSourceSelfPropertyName = (
    sourceSelf: SourceSelf
): "seat" | "publisher" | "brand" | "supply" | "adUnit" => {
    switch (sourceSelf) {
        case "Publisher":
            return "publisher";
        case "Brand":
            return "brand";
        case "Supply":
            return "supply";
        case "Ad Unit":
            return "adUnit";
        default:
            return "seat";
    }
};

interface FloorTableActionsProps {
    record: FloorValue;
    sourceSelf: SourceSelf;
    handleDeleteNew: () => void;
    savingIds: Set<number | null>;
    editingIds: Set<number | null>;
    deletingIds: Set<number | null>;
    handleEdit: (id: number) => void;
    handleDelete: (id: number) => void;
    handleEditCancel: (id: number) => void;
    handleSave: (id: number | null) => void;
}

export const FloorTableActions: FC<FloorTableActionsProps> = ({
    record,
    handleEdit,
    handleSave,
    sourceSelf,
    savingIds,
    editingIds,
    deletingIds,
    handleDelete,
    handleDeleteNew,
    handleEditCancel,
}) => {
    const floorSourceSelfPropertyName: string = getFloorSourceSelfPropertyName(sourceSelf);
    const source: string = record?.floor?.[floorSourceSelfPropertyName];
    const handleSaveClick = (): void => handleSave(record.id);
    const handleCancelClick = (): void => {
        if (record.id) return handleEditCancel(record.id);
        handleDeleteNew();
    };
    const handleEditClick = (): void => {
        if (record.id) handleEdit(record.id);
    };
    const onConfirm = (): void => {
        if (record.id) handleDelete(record.id);
    };

    if (editingIds.has(record.id)) {
        return (
            <Button.Group>
                <Button
                    disabled={savingIds.has(record.id)}
                    style={{ marginRight: "0.5rem" }}
                    onClick={handleCancelClick}
                >
                    Cancel
                </Button>
                <Button type="primary" loading={savingIds.has(record.id)} onClick={handleSaveClick}>
                    Save
                </Button>
            </Button.Group>
        );
    }
    if (source)
        return (
            <Button.Group>
                <Button icon={<EditOutlined />} disabled={deletingIds.has(record.id)} onClick={handleEditClick} />
                <Popconfirm title="Are you sure?" onConfirm={onConfirm}>
                    <Button icon={<DeleteOutlined />} loading={deletingIds.has(record.id)} />
                </Popconfirm>
            </Button.Group>
        );
    return <>{NONE}</>;
};
