import { FC } from "react";
import { Layout } from "antd";
import { Loading } from "@app/core/components";
import { useSeatAuthContext} from "@app/core/auth";
import { ReportsStreamingIFrame } from "./ReportsStreamingIFrame";

export const Reports: FC = () => {
    const { context, session } = useSeatAuthContext();

    if (!context || !session) {
        return (
            <Layout.Content>
                <Loading />
            </Layout.Content>
        );
    }

    return <ReportsStreamingIFrame />
};
