import { FC } from "react";
import { Radio } from "antd";
import { MASKED_TRANSPARENCY_OPTIONS } from "@app/features/deals/constants";
import { useAdSourceUserIdIfaField } from "./useAdSourceUserIdIfaField";
import { TransparencyFormItem } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceTransparencySection/Fields";

export const AdSourceUserIdIfaField: FC = () => {
    const { onChange, value } = useAdSourceUserIdIfaField();
    return (
        <TransparencyFormItem label="User ID/IFA" data-sdet="user-id-ifa">
            <Radio.Group
                onChange={(e) => onChange(e?.target?.value)}
                value={value}
                options={MASKED_TRANSPARENCY_OPTIONS}
            />
        </TransparencyFormItem>
    );
};
