import { FC } from "react";
import { AdUnitMinBitrate } from "@app/features/inventory/components/FormItems";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const MinBitrate: FC = () => (
    <AdUnitMinBitrate
        name={AD_UNIT_FORM_FIELDS.MIN_BITRATE.name}
        label={AD_UNIT_FORM_FIELDS.MIN_BITRATE.label}
        placeholder={AD_UNIT_FORM_FIELDS.MIN_BITRATE.placeholder}
    />
);
