import { FC } from "react";
import { Form } from "antd";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const LiveStreamAcceleration: FC = () => {
    return (
        <Form.Item
            name={CHANNEL_FORM_FIELDS.LIVE_STREAM_ACCELERATION.name}
            label={CHANNEL_FORM_FIELDS.LIVE_STREAM_ACCELERATION.label}
        >
            <BooleanRadioGroup />
        </Form.Item>
    );
};
