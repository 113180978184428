import { NONE } from "@app/core/components/constants";
import { Publisher } from "@app/core/services";
import { getYesNoLabel } from "@app/core/utils/labels";
import { Col, Form, Row } from "antd";
import { FC } from "react";
import { FORM_ITEM_OFFSET, PUBLISHER_INTERNAL_LABELS } from "../../../constants";

const { Item } = Form;
interface Props {
    publisher: Publisher;
}
export const Internal: FC<Props> = ({ publisher }) => {
    return (
        <Row gutter={16} data-sdet="inventory-internal">
            <Col span={24}>
                <Item label={PUBLISHER_INTERNAL_LABELS.billingCode} className={FORM_ITEM_OFFSET}>
                    {publisher?.billingCode || NONE}
                </Item>
            </Col>
            <Col span={24}>
                <Item label={PUBLISHER_INTERNAL_LABELS.publisherReauction} className={FORM_ITEM_OFFSET}>
                    {getYesNoLabel(Boolean(publisher.publisherReauction))}
                </Item>
            </Col>
            <Col span={24}>
                <Item label={PUBLISHER_INTERNAL_LABELS.loverCalculonUseRate} className={FORM_ITEM_OFFSET}>
                    {publisher.calculonUseRateOverride ?? NONE}
                </Item>
            </Col>
            <Col span={24}>
                <Item label={PUBLISHER_INTERNAL_LABELS.upperCalculonUseRate} className={FORM_ITEM_OFFSET}>
                    {publisher.upperCalculonUseRateOverride ?? NONE}
                </Item>
            </Col>
        </Row>
    );
};
