import { FC } from "react";
import { Layout } from "antd";
import PriceOverridesHeader from "./PriceOverridesHeader";
import PriceOverridesTable from "./PriceOverridesTable";
import { PriceOverridesDrawer } from "./PriceOverridesDrawer";

export const PriceOverridesPage: FC = () => (
    <>
        <PriceOverridesHeader />
        <Layout.Content>
            <PriceOverridesTable />
            <PriceOverridesDrawer />
        </Layout.Content>
    </>
);
