import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAuthClient } from "./useAuthClient";

interface UseSessionRefresher {
    isWaitingOnSession: boolean;
}

export const useSessionRefresher = (): UseSessionRefresher => {
    const {
        isLoggedOut,
        isLoggedOutExplicitly,
        isLoggingOut,
        isLoggingIn,
        hasFetchedSession,
        isFetchingSession,
        fetchSession,
    } = useAuthClient();
    const { pathname } = useLocation();

    const shouldFetchSession = useMemo(
        () =>
            !hasFetchedSession &&
            !isFetchingSession &&
            isLoggedOut &&
            !isLoggedOutExplicitly &&
            !isLoggingOut &&
            !isLoggingIn,
        [hasFetchedSession, isFetchingSession, isLoggedOut, isLoggedOutExplicitly, isLoggingOut, isLoggingIn]
    );
    useEffect(() => {
        if (shouldFetchSession) {
            fetchSession().catch(() => {
                // Failed to fetch session. This can be valid, as the self endpoint will return a 404 if the user is not authenticated.
            });
        }
    }, [pathname, shouldFetchSession, fetchSession]);

    return {
        isWaitingOnSession: !hasFetchedSession,
    };
};
