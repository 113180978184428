export const numberNormalize = (amountInput: string | number): number | null => {
    const parsedSeconds = Number(parseFloat(amountInput as string));
    const isInvalidNumber = Number.isNaN(parsedSeconds);

    if (isInvalidNumber) {
        return null;
    }

    return Number(Math.abs(parsedSeconds).toFixed());
};
