import { CloseOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { gray7, gray8 } from "@rubicon/antd-components";
import { Button } from "antd";
import { CSSProperties, FC } from "react";

const classListButton = css`
    color: ${gray7};
    padding: 0;
    height: auto;

    &:hover {
        color: ${gray8};
        background: transparent;
    }
`;

interface Props {
    onClose: () => void;
    buttonStyle?: CSSProperties;
}

const DrawerCloseButton: FC<Props> = ({ onClose, buttonStyle }) => {
    return (
        <Button type="text" onClick={onClose} className={classListButton} style={buttonStyle}>
            <CloseOutlined
                className={css`
                    svg {
                        width: 1.15em;
                        height: 1.15em;
                    }
                `}
            />
        </Button>
    );
};
export default DrawerCloseButton;
