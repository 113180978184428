import { FC } from "react";
import { Space } from "antd";
import { CurrencyConversionModeField } from "@app/features/deals/DealListPage/DealCurrencyFilters/CurrencyConversionModeField";
import { PreferredCurrencyField } from "@app/features/deals/DealListPage/DealCurrencyFilters/PreferredCurrencyField";
import { useUpdateSettingsOnChange } from "./useUpdateSettingsOnChange";
import { useAppSelector } from "@app/core/store";
import { CurrencyConversionModes } from "@app/core/clients/console";

export const DealCurrencyFilters: FC = () => {
    const currencyConversionModeType = useAppSelector(
        (state) => state.deals.settings.values.dealCurrencyConversionMode
    );
    useUpdateSettingsOnChange();
    return (
        <Space.Compact>
            <CurrencyConversionModeField />
            {currencyConversionModeType === CurrencyConversionModes.CONVERT && <PreferredCurrencyField />}
        </Space.Compact>
    );
};
