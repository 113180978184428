import { useLocation, useParams } from "react-router-dom";
import { useNotificationError } from "@app/core/components/hooks/useNotificationError";
import { useGetSeatTagParamSettingIdQuery } from "@app/core/services";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { selectMacroFields, setTagParamsForm, setTagParamsFormInitial } from "../reducer";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { ChangeEvent, useEffect } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useTagTypeFilter } from "../TagTypeFilter/useTagTypeFilter";

export const useTagParamsTable = () => {
    const { search } = useLocation();
    const { seatId } = useParams<{ seatId: string }>();
    const dispatch = useAppDispatch();
    const macroValues = useAppSelector(selectMacroFields);
    const { selected: selectedTagType } = useTagTypeFilter();

    const { data, isFetching, error } = useGetSeatTagParamSettingIdQuery(Number(seatId) || skipToken);
    useNotificationError(error);

    const handleChange = (ev: ChangeEvent<HTMLInputElement>, tagId: number) => {
        if (ev.target && macroValues) {
            const changed = {
                ...macroValues,
                [tagId]: { ...macroValues[tagId], macro: ev.target.value, isDirty: true },
            };
            dispatch(setTagParamsForm(changed));
        }
    };
    const handleChangeCheckbox = (ev: CheckboxChangeEvent, tagId: number) => {
        if (ev.target && macroValues) {
            const changed = {
                ...macroValues,
                [tagId]: { ...macroValues[tagId], selected: ev.target.checked, isDirty: true },
            };
            dispatch(setTagParamsForm(changed));
        }
    };

    useEffect(() => {
        const seatTagParamsForm = data?.reduce((acc, seat) => {
            const key = seat.tagParamDefinition.id;
            const value = {
                macro: seat.seatTagParamSetting?.macro,
                name: seat.tagParamDefinition.name,
                tagParamId: key,
                id: seat?.seatTagParamSetting?.id,
                isDirty: false,
                selected: seat?.seatTagParamSetting?.selected ?? false,
            };
            return { ...acc, [key]: value };
        }, {});

        seatTagParamsForm && dispatch(setTagParamsFormInitial(seatTagParamsForm));
    }, [data, dispatch]);

    return {
        data: data?.filter((tag) => String(tag.tagParamDefinition.type.id) === selectedTagType),
        search,
        handleChange,
        handleChangeCheckbox,
        isLoading: isFetching,
    };
};
