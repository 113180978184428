import { useAppDispatch, useAppSelector } from "@app/core/store";
import { setFiltersView } from "@app/features/dashboard/reducer";
import { LabeledValue } from "antd/lib/select";
import { METRIC_ONE_DEFAULTS_BY_VIEW_ID, METRIC_TWO_DEFAULTS_BY_VIEW_ID } from "../ChartMetricsFilters";
import { VIEW_OPTIONS_LABEL_VALUE } from "./constants";

interface UseViewFilter {
    handleChangeValue: (value: number | undefined, option: LabeledValue | LabeledValue[]) => void;
    options: LabeledValue[];
    value: number;
}

export const useViewFilter = (): UseViewFilter => {
    const dispatch = useAppDispatch();
    const viewFilter = useAppSelector((state) => state.dashboard.filters.view);

    const handleChangeValue = (id: number | undefined, option: LabeledValue | LabeledValue[]) => {
        if (!id || Array.isArray(option) || option.value === viewFilter.value) {
            return;
        }
        dispatch(
            setFiltersView({
                view: option,
                metricOne: METRIC_ONE_DEFAULTS_BY_VIEW_ID[option.value],
                metricTwo: METRIC_TWO_DEFAULTS_BY_VIEW_ID[option.value],
            })
        );
    };

    return {
        options: VIEW_OPTIONS_LABEL_VALUE,
        value: Number(viewFilter.value),
        handleChangeValue,
    };
};
