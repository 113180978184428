import { createContext, useContext } from "react";

interface PreformanceSectionContextType {
    isLiveActive: boolean;
    handlePerformanceTypeSwitch: (isLiveActive: boolean) => void;
}

export const PerformanceSectionContext = createContext<PreformanceSectionContextType | null>(null);

export const usePerformanceSectionContext = () => {
    const performanceSectionContext = useContext(PerformanceSectionContext);

    if (!performanceSectionContext) {
        throw new Error(
            "usePerformanceStatsTypeButtonsContext has to be used within <PreformanceSectionContext.Provider>"
        );
    }

    return performanceSectionContext;
};
