import { PageHeader } from "@ant-design/pro-layout";
import { Loading } from "@app/core/components";
import { HelpKeysDrawerContent } from "@app/core/components/HelpKeysDrawer";
import { useScrollToTopOnMount } from "@app/core/components/hooks";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ResizableDrawer } from "@rubicon/antd-components";
import { useHistory, useParams } from "react-router-dom";
import { AdvancedBrandSafetyActions } from "../AdvancedBrandSafetyActions";
import { useAdvancedBrandSafetyDetailsDrawer } from "../AdvancedBrandSafetyDetailsDrawer/useAdvancedBrandSafetyDetailsDrawer";
import { AdvancedBrandSafetyReadOnlyForm } from "../AdvancedBrandSafetyForm";
import { HELP_KEYS } from "../constants";
import { CONTROLS_ROUTES, drawerRightClosePosition } from "@app/features/controls/constants";

export const AdvancedBrandSafetyDetailsPage = () => {
    useScrollToTopOnMount();
    const history = useHistory();
    const { id, seatId } = useParams<{ seatId: string; id: string }>();
    const { handleDefinition, data, handleBack, isLoading, handlePostDeleteEvent, handleClose, definitionType } =
        useAdvancedBrandSafetyDetailsDrawer(seatId, id, false, () =>
            history.push(
                ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_TABS(seatId, CONTROLS_ROUTES.ADVANCED_BRAND_SAFETY)
            )
        );

    if (isLoading) {
        return <Loading />;
    }
    return (
        <>
            <PageHeader title={data.name} onBack={handleBack}>
                <AdvancedBrandSafetyActions
                    seatId={seatId}
                    id={Number(id)}
                    name={data.name}
                    postDeleteEvent={handlePostDeleteEvent}
                />
            </PageHeader>
            <AdvancedBrandSafetyReadOnlyForm brandSafety={data} handleDefinition={handleDefinition} />
            <ResizableDrawer
                title={HELP_KEYS[definitionType as string]?.label || ""}
                placement="right"
                open={Boolean(definitionType)}
                onClose={handleClose}
                width="45%"
                className={drawerRightClosePosition}
            >
                {definitionType && <HelpKeysDrawerContent definitionsFields={HELP_KEYS[definitionType].helpKeys} />}
            </ResizableDrawer>
        </>
    );
};
