import { FC } from "react";
import { css, cx } from "@emotion/css";
import { Col, Row, Collapse } from "antd";
import { colorLink } from "@rubicon/antd-components";
import { GUTTER } from "@app/features/deals/constants";
import { adminInternalSectionClassList } from "@app/features/deals/DealForm";
import { AdSourceCostModelField, AdSourceCostField, AdSourceAllowAutoscaleField, AdSourceRegionField } from "./Fields";

export const classLilstOptionsSection = css`
    .ant-collapse-item > .ant-collapse-header {
        width: fit-content;
        background: none;
        color: ${colorLink};

        & > .ant-collapse-header-text {
            flex: none;
            margin-inline-end: 0;
        }
    }
`;

export const AdSourceAdminOptionsSection: FC = () => {
    return (
        <Collapse
            className={cx(classLilstOptionsSection, adminInternalSectionClassList)}
            expandIconPosition="end"
            ghost
            items={[
                {
                    key: "0",
                    label: "Admin Options",
                    children: (
                        <>
                            <Row gutter={GUTTER} data-sdet="deal-form-ad-source-admin-options-section">
                                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                                    <AdSourceCostModelField />
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                                    <AdSourceCostField />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                                    <AdSourceAllowAutoscaleField />
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                                    <AdSourceRegionField />
                                </Col>
                            </Row>
                        </>
                    ),
                },
            ]}
        />
    );
};
