import { FC } from "react";
import { Form, FormInstance, Select, Typography, notification } from "antd";
import { useParams } from "react-router-dom";
import useNotificationError from "@app/core/components/hooks/useNotificationError";
import {
    AdvertiserDetails,
    AdvertiserTransferPayload,
    ErrorResponse,
    useGetSeatAdvertisersQuery,
    useTransferAdvertiserMutation,
} from "@app/core/services";
import { TagRender } from "@app/core/components";

export const CLASSIFICATIONS_ADVERTISER_TRANSFER_DRAWER_CONTENT_SDET =
    "classifications-advertiser-transfer-drawer-content";
export const CLASSIFICATION_ADVERTISER_TRANSFER_DRAWER_CONTENT_TEXT_SDET =
    "classifications-advertiser-transfer-drawer-content-text";
export const CLASSIFICATION_ADVERTISER_TRANSFER_DRAWER_CONTENT_TARGET_ADVERTISER_SELECT_SDET =
    "classifications-advertiser-transfer-drawer-content-target-advertiser-select";
export const CLASSIFICATION_ADVERTISER_TRANSFER_DRAWER_CONTENT_ADVERTISER_DOMAINS_SELECT_SDET =
    "classifications-advertiser-transfer-drawer-content-advertiser-domains-select";

export const TARGET_ADVERTISER_NAME = "newAdvertiserId";
export const ADVERTISER_DOMAINS_NAME = "adomains";

interface ClassificationAdvertiserTransferFormValues {
    adomains: string[];
    newAdvertiserId: number | null;
}

export const useClassificationsAdvertiserTransferDrawer = (form: FormInstance, onClose: () => void) => {
    const [transferAdvertiser, { isLoading }] = useTransferAdvertiserMutation();
    const onFinish = (values: AdvertiserTransferPayload, targetAdvertiserName: string) => {
        transferAdvertiser(values)
            .unwrap()
            .then(() => {
                notification.success({
                    message: (
                        <span>
                            {values.adomains.length} Advertiser Domain(s) have successfully been transferred to{" "}
                            <Typography.Text strong>{targetAdvertiserName}</Typography.Text>
                        </span>
                    ),
                });
                onClose();
            })
            .catch((err) => notification.error({ message: err.data.errorDescription }));
    };
    const onSave = () => form.submit();

    return {
        isTransfering: isLoading,
        onSave,
        onFinish,
    };
};
interface Props {
    advertiser?: AdvertiserDetails;
    form: FormInstance;
    onFinish: (values: ClassificationAdvertiserTransferFormValues, targetAdvertiserName: string) => void;
}

export const ClassificationsAdvertiserTransferDrawerContent: FC<Props> = ({ advertiser, form, onFinish }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data: advertisers = [], isLoading, error } = useGetSeatAdvertisersQuery({ seatId: Number(seatId) });
    const targetAdvertiserOptions = advertisers
        .map(({ name, id }) => ({ label: name, value: id }))
        .filter(({ value }) => advertiser?.id !== value);
    const advertiserDomainsOptions = advertiser?.advertiserDomains.map(({ adomain }) => ({
        label: adomain,
        value: adomain,
    }));

    useNotificationError(error as ErrorResponse);

    return advertiser ? (
        <Form
            initialValues={{ adomains: [] }}
            layout="vertical"
            form={form}
            data-sdet={CLASSIFICATIONS_ADVERTISER_TRANSFER_DRAWER_CONTENT_SDET}
            onFinish={(values) => {
                const targetAdvertiserName =
                    advertisers.find((advertiserData) => advertiserData.id === values.newAdvertiserId)?.name || "";
                const nextValues = { ...values, oldAdvertiserId: advertiser.id };
                onFinish(nextValues, targetAdvertiserName);
            }}
        >
            <Form.Item label="Name">{advertiser.name}</Form.Item>
            <Form.Item
                required
                label="Target Advertiser"
                name={TARGET_ADVERTISER_NAME}
                rules={[{ required: true, message: "Target Advertiser is required." }]}
            >
                <Select
                    allowClear
                    showSearch
                    optionFilterProp="label"
                    data-sdet={CLASSIFICATION_ADVERTISER_TRANSFER_DRAWER_CONTENT_TARGET_ADVERTISER_SELECT_SDET}
                    options={targetAdvertiserOptions}
                    loading={isLoading}
                />
            </Form.Item>
            <Form.Item
                required
                label="Advertiser Domains"
                name={ADVERTISER_DOMAINS_NAME}
                rules={[{ required: true, message: "At least one Advertiser Domain is required." }]}
            >
                <Select
                    allowClear
                    showSearch
                    optionFilterProp="label"
                    data-sdet={CLASSIFICATION_ADVERTISER_TRANSFER_DRAWER_CONTENT_ADVERTISER_DOMAINS_SELECT_SDET}
                    mode="multiple"
                    autoClearSearchValue={false}
                    options={advertiserDomainsOptions}
                    tagRender={(props) => <TagRender {...props} />}
                />
            </Form.Item>
            <Form.Item
                shouldUpdate={(prev, curr) =>
                    prev[TARGET_ADVERTISER_NAME] !== curr[TARGET_ADVERTISER_NAME] ||
                    prev[ADVERTISER_DOMAINS_NAME] !== curr[ADVERTISER_DOMAINS_NAME]
                }
            >
                {() => {
                    const newAdvertiser = form.getFieldValue("newAdvertiserId");
                    const adomains = form.getFieldValue("adomains");

                    return newAdvertiser && adomains.length ? (
                        <Typography.Text data-sdet={CLASSIFICATION_ADVERTISER_TRANSFER_DRAWER_CONTENT_TEXT_SDET}>
                            Transfer {adomains.length} domain(s) to{" "}
                            <Typography.Text strong>
                                {advertisers.find((advertiserData) => advertiserData.id === newAdvertiser)?.name}
                            </Typography.Text>
                        </Typography.Text>
                    ) : null;
                }}
            </Form.Item>
        </Form>
    ) : null;
};
