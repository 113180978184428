import { InfoCircleOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Collapse, Drawer } from "antd";
import { FC, useState } from "react";
import { DealsHelpKeys, DEAL_PACING_STATUS_HELP_FIELDS, DEAL_SECTIONS_NAME } from "../../../constants";
import DOMPurify from "dompurify";
import { useHelpArticles } from "@app/core/components/hooks";
import { DrawerCloseButton, SectionTitle, StyledButtonLink } from "@app/core/components";

const configurationCollapseClassList = css`
    .ant-collapse-expand-icon {
        align-self: flex-end;
    }
`;

export const PacingStatusSidePanel: FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { helpArticles } = useHelpArticles(DealsHelpKeys);

    return (
        <>
            <StyledButtonLink onClick={() => setIsOpen(true)} type="link" data-sdet="pacing">
                <InfoCircleOutlined />
                {DEAL_SECTIONS_NAME.PACING_STATUS}
            </StyledButtonLink>
            <Drawer
                styles={{ body: { padding: 0 } }}
                width="40%"
                title={`${DEAL_SECTIONS_NAME.PACING_STATUS} Definitions`}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                closable={false}
                extra={<DrawerCloseButton onClose={() => setIsOpen(false)} />}
            >
                <Collapse
                    className={configurationCollapseClassList}
                    defaultActiveKey={Object.values(DEAL_PACING_STATUS_HELP_FIELDS).map((field) => field.helpPath)}
                >
                    {Object.values(DEAL_PACING_STATUS_HELP_FIELDS).map((field) => (
                        <Collapse.Panel key={field.helpPath} header={<SectionTitle title={field.label} />} forceRender>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(helpArticles[field.helpPath]?.body),
                                }}
                            />
                        </Collapse.Panel>
                    ))}
                </Collapse>
            </Drawer>
        </>
    );
};
