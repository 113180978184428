import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormRadioGroupChange } from "@app/features/deals/DealForm/reducer";
import { RadioChangeEvent } from "antd";

interface UseAdSourceUnderInboundFloor {
    onChange: (event: RadioChangeEvent) => void;
    value: boolean;
}

export const useAdSourceUnderInboundFloor = (): UseAdSourceUnderInboundFloor => {
    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_RUN_UNDER_INBOUND_FLOOR]
    );
    const dispatch = useAppDispatch();

    const onChange = (event: RadioChangeEvent) => {
        const { value } = event?.target;
        dispatch(
            dealFormRadioGroupChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_RUN_UNDER_INBOUND_FLOOR, value })
        );
    };
    return {
        onChange,
        value,
    };
};
