import { FC } from "react";
import { Select } from "antd";
import { LabeledValue } from "antd/lib/select";
import { TYPE_FILTER_OPTIONS } from "./constants";

interface Props {
    onChange: (value: LabeledValue) => void;
    value: LabeledValue;
}

export const TypeFilterSelect: FC<Props> = ({ onChange, value }) => {
    return (
        <Select
            labelInValue
            onChange={onChange}
            options={TYPE_FILTER_OPTIONS}
            style={{ width: "100%" }}
            value={value}
        />
    );
};
