import {
    consoleApi,
    DEAL_HISTORICAL_CHART_AD_SOURCE_TAG,
    DEAL_HISTORICAL_CHART_DATE_RANGE_TAG,
    DEAL_TAG,
    OPTIMIZER_QUERIES_TAG,
    REPORTS_CUSTOM_REPORTS_TAG,
    REPORTS_DASHBOARD_SUPPLY_TAG,
    REPORTS_RECENT_REPORTS_TAG,
    OPTIMIZER_TAG,
    UNIQUES_COUNTS,
    OPTIMIZER_QUERIES_RESULTS_TAG,
} from "./console";
import { Seat } from "./seats";
import { User } from "./users";
import { toQueryString } from "./utils";
import { historicalChartDateRange } from "@app/core/components/charts/HistoricalChart/HistoricalChartDateRangeFilter/useHistoricalDateRangeFilter";

interface DashboardSupplyReportField {
    filterMode: null;
    listResourcePrefix: null;
    listResource: null;
    entityResource: null;
    matrixParams: null;
    integration: null;
    bulkMode: null;
    flags: null;
    id: string;
    name: string;
    category: string;
    description: string | null;
    comment: string | null;
    type: "integer" | "currency" | "percent" | "string" | "general";
    width: number;
    sort: number;
    dependsOn: null;
}

export interface DashboardSupplyReportDatum {
    playlistRequests: number;
    podRequests: number;
    totalPodSlots: number;
    totalAdBreaks: number;
    avgPodsPerPlaylist: number;
    avgPodSlotsPerAdBreak: number;
    avgPodSlotsPerPlaylist: number;
    podFillRate: number;
    playlistFillRate: number;
    adBreaksInPlaylist: number;
    standardRequests: number;
    standardFillRate: number;
    podImpressions: number;
    playlistImpressions: number;
    standardImpressions: number;
    podUseRate: number;
    playlistUseRate: number;
    standardUseRate: number;
    slotsPerPodRequest: number;
    podsNetRevenue: number;
    playlistNetRevenue: number;
    standardNetRevenue: number;
    standardAcceptedRequests: number;
    podAcceptedRequests: number;
    playlistAcceptedRequests: number;
    podFills: number;
    playlistFills: number;
    standardFills: number;
    podsGrossRevenue: number;
    playlistGrossRevenue: number;
    standardGrossRevenue: number;
    slotsForAdPods: number;
    slotsForPlaylists: number;
    overallOpportunities: number;
    impressions: number;
    requests: number;
    netRevenue: number;
    grossRevenue: number;
    useRate: number;
    fillRate: number;
    currency: string;
    currencyId: number;
    acceptedRequestRate: number;
    acceptedRequests: number;
    netRevenueCpm: number;
    grossRevenueCpm: number;
    fills: number;
    day?: string;
    hour?: number;
}

interface DashboardSupplyReportData {
    fields: DashboardSupplyReportField[];
    data: DashboardSupplyReportDatum[];
}

interface ReportQueryExecutionStatus {
    id: number;
    name: string;
    creationDate: string;
    updateDate: string;
}

interface ReportDefinition {
    source: string;
    fields: string[];
    conversions: null; // TODO: get definition
}

interface RecentReportQueryExecution {
    id: number;
    code: string;
    startedDate: string;
    duration: number;
    resultCount: number;
    resultLocation: string;
    status: ReportQueryExecutionStatus;
    definition: ReportDefinition;
    initiatingUser: Pick<User, "id" | "name" | "emailAddress">;
}

interface ReportAdvancedDeliveryType {
    id: number;
    name: string;
    creationDate: string;
    updateDate: string;
    dateCreated: string;
}

interface ReportAdvancedDeliveryLocation {
    id: number;
    name: string;
    creationDate: string;
    updateDate: string;
    url: string;
    verified: boolean;
    advancedDeliveryType: ReportAdvancedDeliveryType;
}

interface GetHistoricalDealParams {
    adSourceId: number;
    dealId: number | null;
    dateRange: historicalChartDateRange;
    groupBy: string;
    currencyId: number;
}

export interface DashboardSupplyReport {
    thisPeriod: {
        hourlyData: DashboardSupplyReportData | null;
        dailyData: DashboardSupplyReportData | null;
        monthlyData: DashboardSupplyReportData | null;
    };
    lastPeriod: {
        hourlyData: DashboardSupplyReportData | null;
        dailyData: DashboardSupplyReportData | null;
        monthlyData: DashboardSupplyReportData | null;
    };
}

export interface GetDashboardSupplyReportParams {
    seat: number;
    period: string;
    currencyConversionId?: number;
    currencyFilterId?: number;
    timezoneId: number;
    publisherId: number[];
    adSourceTypeId: number[];
}

export interface CustomReport {
    id: number;
    name: string;
    description: string;
    creationDate: string;
    updateDate: string;
    notify: boolean;
    insecureLinkAllowed: boolean;
    entityType: "CustomReport";
    schedule: string | null;
    emails: string[];
    definition: ReportDefinition;
    parentSeat: Pick<Seat, "id" | "name" | "code" | "entityType">;
    advancedDeliveryLocation: ReportAdvancedDeliveryLocation | null;
    owner: Pick<User, "id" | "name" | "emailAddress">;
}

export interface RecentReport {
    name: string;
    description: string;
    customReport: CustomReport | null;
    queryExecution: RecentReportQueryExecution;
}

export interface GetRecentReportsParams {
    seat: number;
    page?: number;
    max?: number;
}

export interface HistoricalDealReport {
    day: string;
    hour?: number;
    tries: number;
    validBids: number;
    eligibleBids: number;
    wins: number;
    impressions: number;
    useRate: number;
    validBidRate: number;
    eligibleBidRate: number;
    netRevenue: number;
}

export interface AsyncReportResponse {
    code: string;
    status: number;
    startTime: string;
    error: string | null;
}

export const enum UniquesCountsEntityTypes {
    SEAT = "supplySeat",
    PUBLISHER = "publisher",
    BRAND = "brand",
    SUPPLY = "supply",
    AD_UNIT = "adUnit", // this does not work some why
}

export const enum OptimizerEntityTypes {
    SEAT = "supplySeat",
    PUBLISHER = "publisher",
    BRAND = "brand",
    SUPPLY = "supply",
    AD_UNIT = "adUnit", // this does not work some why
}

interface UniquesCountsParams {
    entityType: UniquesCountsEntityTypes;
    id: number;
    platformFamily?: number;
    country?: string;
}

interface OptimizerParams {
    entityType: OptimizerEntityTypes;
    id: number | string;
    adSourceType: number;
    async: boolean;
    currency?: number;
    country?: string;
    "date-range": string;
}

interface OptimizerQueriesParams {
    queries: string;
}

export interface UniquesCountsResponse {
    dailyUniques: number;
    day: string;
    past30DayUniques: number;
}

export interface OptimizerResponse {
    code: string;
    error: string | null;
    startTime: string;
    status: number;
}

export interface OptimizerQueries {
    code: string;
    error: string | null;
    startTime: string;
    status: number;
}

interface OptimizerQueriesResultsParams {
    queries: string;
}

export interface OptimizerQueriesResults {
    estimatedRevenue: number;
    estimatedWinRate: number;
    potentialFloor: number;
    recordedAvgFloorPrice: number;
    recordedRevenue: number;
    recordedWinRate: number;
}

export interface HistoricalQueriesResults {
    day: string;
    estimatedRevenue: number;
    potentialFloor: number;
    recordedAvgFloorPrice: number;
    recordedRevenue: number;
}

export const reports = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getDashboardSupplyReport: builder.query<DashboardSupplyReport, GetDashboardSupplyReportParams>({
            query: (params) => `/reports/dashboard/supply;${toQueryString(params)}`,
            providesTags: (_, __, { seat: id }) => [{ type: REPORTS_DASHBOARD_SUPPLY_TAG, id }],
        }),
        getRecentReports: builder.query<RecentReport, GetRecentReportsParams>({
            query: ({ seat, page = 1, max = 15 }) => `/reports/recent;page=${page};max=${max};seat=${seat}`,
            providesTags: (_, __, { seat }) => [{ type: REPORTS_RECENT_REPORTS_TAG, id: seat }],
        }),
        getCustomReports: builder.query<CustomReport, number>({
            query: (seatId) => `/reports/custom;seat=${seatId}`,
            providesTags: (_, __, seatId) => [{ type: REPORTS_CUSTOM_REPORTS_TAG, id: seatId }],
        }),
        getAsyncAdSourceReport: builder.query<AsyncReportResponse, GetHistoricalDealParams>({
            query: ({ adSourceId, dateRange, dealId, groupBy, currencyId }) => {
                const dealParam = dealId ? `dealId=${dealId}` : "";
                return `reports/adSources/performance;adSource=${adSourceId};date-range=${dateRange};${dealParam};groupby=${groupBy};currency=${currencyId}?async=true&_=${Date.now()}`;
            },
            providesTags: (_, __, { adSourceId, dateRange, dealId }) => [
                { type: DEAL_TAG, id: dealId as number },
                { type: DEAL_HISTORICAL_CHART_DATE_RANGE_TAG, id: dateRange },
                { type: DEAL_HISTORICAL_CHART_AD_SOURCE_TAG, id: adSourceId },
            ],
        }),
        getHistoricalDealData: builder.query<HistoricalDealReport[], GetHistoricalDealParams>({
            query: ({ adSourceId, dealId, dateRange, groupBy, currencyId }: GetHistoricalDealParams) => {
                const dealParam = dealId ? `dealId=${dealId}` : "";
                return `reports/adSources/performance;adSource=${adSourceId};date-range=${dateRange};${dealParam};groupby=${groupBy};currency=${currencyId}`;
            },
            providesTags: (_, __, { dealId, dateRange, adSourceId }) => [
                { type: DEAL_TAG, id: dealId as number },
                { type: DEAL_HISTORICAL_CHART_DATE_RANGE_TAG, id: dateRange },
                { type: DEAL_HISTORICAL_CHART_AD_SOURCE_TAG, id: adSourceId },
            ],
        }),
        getUniquesCounts: builder.query<UniquesCountsResponse[], UniquesCountsParams>({
            query: ({ entityType, id, ...rest }) =>
                `reports/supply/uniques/counts;${entityType}=${id};${toQueryString(rest)}`,
            providesTags: (_, __, { id }) => [{ type: UNIQUES_COUNTS, id }],
        }),
        getOptimizer: builder.query<OptimizerResponse, OptimizerParams>({
            query: ({ entityType, id, async, ...rest }) =>
                `reports/supply/floors/optimizer;${entityType}=${id};${toQueryString(rest)}?async=${async}`,
            providesTags: (_, __, { id }) => [{ type: OPTIMIZER_TAG, id }],
        }),
        getHistorical: builder.query<OptimizerResponse, OptimizerParams>({
            query: ({ entityType, id, async, ...rest }) =>
                `reports/supply/floors/optimizer/historical;${entityType}=${id};${toQueryString(rest)}?async=${async}`,
            providesTags: (_, __, { id }) => [{ type: OPTIMIZER_TAG, id }],
        }),
        getOptimizerQueries: builder.query<OptimizerQueries, OptimizerQueriesParams>({
            query: ({ queries }) => `queries/${queries}`,
            providesTags: [OPTIMIZER_QUERIES_TAG],
        }),
        getOptimizerQueriesResults: builder.query<
            OptimizerQueriesResults[] | HistoricalQueriesResults[],
            OptimizerQueriesResultsParams
        >({
            query: ({ queries }) => `queries/${queries}/results?fmt=json`,
            providesTags: [OPTIMIZER_QUERIES_RESULTS_TAG],
            transformResponse: (response: OptimizerQueriesResults[]) =>
                response.sort((a, b) => a.potentialFloor - b.potentialFloor),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetDashboardSupplyReportQuery,
    useGetRecentReportsQuery,
    useGetCustomReportsQuery,
    useGetHistoricalDealDataQuery,
    useGetAsyncAdSourceReportQuery,
    useGetUniquesCountsQuery,
    useGetOptimizerQuery,
    useGetHistoricalQuery,
    useGetOptimizerQueriesQuery,
    useGetOptimizerQueriesResultsQuery,
} = reports;
