import { FC } from "react";
import { Form, Input } from "antd";
import { useUserAccess } from "@app/core/auth";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { isFallbackOpportunityEstimateEligible } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/pacingUtils";
import { usePacingFormData } from "../usePacingFormData";

const { label, name } = AD_SOURCE_FIELDS.FALLBACK_OPPORTUNITY;

export const FallbackOpportunity: FC = () => {
    const { adSourceTypeId, pacingTypeId } = usePacingFormData();
    const { showFallbackOpportunity } = useUserAccess();

    if (!isFallbackOpportunityEstimateEligible(adSourceTypeId, pacingTypeId, showFallbackOpportunity)) {
        return null;
    }

    return (
        <Form.Item name={name} label={label} data-sdet={`test-${name}-field`} {...VERTICAL_LAYOUT_FIX}>
            <Input type="number" data-sdet={`test-${name}-input`} placeholder={`Enter ${label}`} />
        </Form.Item>
    );
};
