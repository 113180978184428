import { ROUTE_FORMATTERS } from "@app/core/routing";
import { SeatSeparationGroupsPayload, useCreateSeatSeparationGroupsMutation } from "@app/core/services";
import { notification } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { InitialValuesType } from "../constants";
import { parseValueToApi } from "../helpers";

interface UseBuyerSeatListsCreatePage {
    isSubmitting: boolean;
    initialValues: InitialValuesType;
    handleSubmit: (values: InitialValuesType) => void;
    handleCancel: () => void;
}

export const useCompetitiveSeparationGroupCreatePage = (): UseBuyerSeatListsCreatePage => {
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();
    const [createSeatSeparationGroups, { isLoading }] = useCreateSeatSeparationGroupsMutation();

    const handleSubmit = async (values: InitialValuesType) => {
        const payload = parseValueToApi(values);
        const body = {
            ...payload,
            seat: { id: seatId },
        } as SeatSeparationGroupsPayload;

        try {
            const res = await createSeatSeparationGroups({
                seatId,
                body,
            }).unwrap();
            history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_DETAILS_PAGE(seatId, res.id));
            notification.success({ message: "Separation Group updated successfully" });
        } catch (error) {
            notification.error({ message: `Failed to update Separation Group: ${error?.data?.errorDescription}` });
        }
    };

    const handleCancel = () => {
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS(seatId));
    };

    return {
        isSubmitting: isLoading,
        initialValues: {
            mode: true,
        } as InitialValuesType,
        handleSubmit,
        handleCancel,
    };
};
