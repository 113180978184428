import { Form } from "antd";
import { useMemo, useState } from "react";
import {
    LABEL_VALUES,
    INTERNAL_LABEL_VALUES,
    PREFILTER_LABEL_VALUES,
    DISTRIBUTION_GROUP_LABEL_VALUES,
} from "@app/features/inventory/constants";
import { useParams } from "react-router-dom";
import { LabeledValue } from "antd/lib/select";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { getSeatLabelValueLabel } from "@app/features/inventory/helpers";
import { Label, useGetGlobalsLabelsQuery, useGetSeatPrefilterLabelsQuery } from "@app/core/services/console";
import { useGetDistributionGroupLabelsQuery, useGetLabelValuesQuery, useGetSeatLabelsQuery } from "@app/core/services";
import { LabelValueOption } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/useSeatDetailsForm";

const { useFormInstance, useWatch } = Form;

interface UseLabelsProps {
    name:
        | typeof LABEL_VALUES
        | typeof INTERNAL_LABEL_VALUES
        | typeof PREFILTER_LABEL_VALUES
        | typeof DISTRIBUTION_GROUP_LABEL_VALUES;
}

interface UseLabels {
    hidden: boolean;
    isLoading: boolean;
    labels: Label[] | undefined;
    labelId: number | undefined;
    handleAddLabel: () => void;
    isLabelValuesFetching: boolean;
    labelValuesOptions: LabelValueOption[];
    labelValue: LabelValueOption | undefined;
    seatInternalLabelValuesOptions: LabelValueOption[];
    handleChangeLabel: (value: number | undefined) => void;
    onChange: (value: LabeledValue[], options: LabelValueOption[]) => void;
    handleChangeLabelValue: (value: LabeledValue, option: LabelValueOption) => void;
}

export const useLabels = ({ name }: UseLabelsProps): UseLabels => {
    const form = useFormInstance();
    const { seatId } = useParams<{ seatId: string }>();
    const seatInternalLabelValuesOptions: LabelValueOption[] = useWatch(name) ?? [];

    const hidden = !seatInternalLabelValuesOptions.length;

    const isLabels: boolean = name === LABEL_VALUES;
    const isInternalLabels: boolean = name === INTERNAL_LABEL_VALUES;
    const isPrefilterLabels: boolean = name === PREFILTER_LABEL_VALUES;
    const isDistributionGroupLabels = name === DISTRIBUTION_GROUP_LABEL_VALUES;

    const [labelId, setLabelId] = useState<number | undefined>(undefined);
    const [labelValue, setLabelValue] = useState<LabelValueOption | undefined>(undefined);

    const { data: seatLabelsData, isFetching: isLabelsFetching } = useGetSeatLabelsQuery(
        { seatId: Number(seatId) },
        {
            skip: !isLabels,
        }
    );
    const { data: globalLabelsData, isFetching: isGlobalLabelsFetching } = useGetGlobalsLabelsQuery(undefined, {
        skip: !isInternalLabels,
    });

    const { data: prefilterLabelsData, isFetching: isPrefilterLabelsFetching } = useGetSeatPrefilterLabelsQuery(
        Number(seatId),
        { skip: !isPrefilterLabels }
    );
    const { data: distributionGroupLabelsData, isFetching: isDistributionGroupLabelsFetching } =
        useGetDistributionGroupLabelsQuery(Number(seatId), { skip: !isDistributionGroupLabels });

    const { data: labelValuesData, isFetching: isLabelValuesFetching } = useGetLabelValuesQuery(labelId || skipToken, {
        skip: !labelId,
    });

    const labelsData: Label[] =
        globalLabelsData || seatLabelsData || prefilterLabelsData || distributionGroupLabelsData || [];

    const labels: Label[] = isDistributionGroupLabels
        ? labelsData
        : labelsData.filter((label) => !label?.isDistributionGroup);

    const labelValues: LabelValueOption[] = useMemo(
        () =>
            (labelValuesData || []).map((labelValue) => ({
                value: labelValue.id,
                label: labelValue.value,
                labelValue: labelValue.value,
                seatLabel: labelValue.label,
            })),
        [labelValuesData]
    );

    const seatLabelValuesIds: number[] = seatInternalLabelValuesOptions.map((label) => label.value);
    const labelValuesOptions: LabelValueOption[] = labelValues.filter(
        (label) => !seatLabelValuesIds.includes(label.value)
    );
    const handleChangeLabel = (value: number | undefined): void => {
        setLabelId(value);
        setLabelValue(undefined);
    };

    const handleChangeLabelValue = (_, option: LabelValueOption): void => setLabelValue(option);

    const handleChange = (option: LabelValueOption[]): void => form.setFieldValue(name, option);

    const onChange = (_, options: LabelValueOption[]): void => handleChange(options);

    const handleAddLabel = (): void => {
        if (!labelId || !labelValue) return;
        const newSeatInternalLabelValuesOption: LabelValueOption = {
            ...labelValue,
            label: getSeatLabelValueLabel(labelValue.seatLabel, labelValue.labelValue),
        };
        const newSeatInternalLabelValuesOptions: LabelValueOption[] = [
            ...seatInternalLabelValuesOptions,
            newSeatInternalLabelValuesOption,
        ];
        handleChange(newSeatInternalLabelValuesOptions);
        setLabelId(undefined);
        setLabelValue(undefined);
    };
    return {
        hidden,
        labels,
        labelId,
        onChange,
        labelValue,
        handleAddLabel,
        handleChangeLabel,
        labelValuesOptions,
        isLabelValuesFetching,
        handleChangeLabelValue,
        seatInternalLabelValuesOptions,
        isLoading:
            isDistributionGroupLabelsFetching ||
            isPrefilterLabelsFetching ||
            isGlobalLabelsFetching ||
            isLabelsFetching,
    };
};
