export const CUSTOM_PIXEL_NAME_INPUT_SDET = "custom-pixel-name-input";
export const CUSTOM_PIXEL_TYPE_SELECT_SDET = "custom-pixel-type-select";
export const CUSTOM_PIXEL_ADD_TO_SOURCE_BY_DEFAULT_RADIO_GROUP_SDET = "custom-pixel-radio-group";
export const CUSTOM_PIXEL_PIXEL_URL_INPUT_SDET = "custom-pixel-pixel-url-input";

export const NAME_FORM_NAME = "name";
export const TYPE_FORM_NAME = ["type", "id"];
export const ADD_TO_ADD_SOURCE_BY_DEFAULT_FORM_NAME = "addToAdSourceByDefault";
export const PIXEL_URL_FORM_NAME = "value";

export const CUSTOM_PIXEL_FORM_ITEMS = {
    NAME: {
        name: NAME_FORM_NAME,
        label: "Name",
    },
    TYPE: {
        name: TYPE_FORM_NAME,
        label: "Type",
    },
    ADD_TO_ADD_SOURCE_BY_DEFAULT: {
        name: ADD_TO_ADD_SOURCE_BY_DEFAULT_FORM_NAME,
        label: "Add to Ad Source By Default",
    },
    PIXEL_URL: {
        name: PIXEL_URL_FORM_NAME,
        label: "Pixel URL",
    },
    UPDATED_DATE: {
        name: "",
        label: "Last Updated (UTC)",
    },
    CREATION_DATE: {
        name: "",
        label: "Date Created (UTC)",
    },
};

export interface CustomPixelFormValues {
    [NAME_FORM_NAME]: string;
    type: {
        id: number;
    };
    [ADD_TO_ADD_SOURCE_BY_DEFAULT_FORM_NAME]: boolean;
    [PIXEL_URL_FORM_NAME]: string;
}

export const PIXEL_URL_PREFIX = "https://";
export const NAME_REQUIRED_ERROR_MESSAGE = "Name is required";
export const NAME_LEN_ERROR_MESSAGE = "Name can not be longer than 70 characters";
