import { Loading } from "@app/core/components";
import { useNotificationError } from "@app/core/components/hooks";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Card, Layout } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useGetIndustryDetailsQuery } from "@app/core/services";
import { ClassificationsIndustryDefinitionsButtonAndDrawer } from "./ClassificationsIndustryDefinitions";
import { ClassificationsIndustryDetailsContent } from "./ClassificationsIndustriesTab/ClassificationsIndustriesTabDrawer/ClassificationsIndustryDetailsContent";
import { ClassificationsIndustryDetailsActions } from "./ClassificationsIndustriesTab/ClassificationsIndustriesTabDrawer/ClassificationsIndustryDetailsActions";
import { ClassificationsIndustriesTabDrawer } from "./ClassificationsIndustriesTab/ClassificationsIndustriesTabDrawer";
import { PageHeader } from "@ant-design/pro-layout";

export const ClassificationsIndustryDetailsPage = () => {
    const { seatId, industryId } = useParams<{ seatId: string; industryId: string }>();
    const { data: industry, isLoading, error } = useGetIndustryDetailsQuery(Number(industryId));

    useNotificationError(error);

    const history = useHistory();

    if (isLoading) return <Loading />;

    if (!industry) return null;

    return (
        <>
            <PageHeader
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_TABS(seatId, "industries"))}
                title={`Industry: ${industry.name}`}
                extra={<ClassificationsIndustryDefinitionsButtonAndDrawer />}
            >
                <ClassificationsIndustryDetailsActions
                    industry={industry}
                    onClickChangeHistory={() => {
                        history.push({ search: "drawer=history" });
                    }}
                />
            </PageHeader>
            <Layout.Content>
                <Card>
                    <ClassificationsIndustryDetailsContent industry={industry} />
                </Card>
                <ClassificationsIndustriesTabDrawer showDirectLabel />
            </Layout.Content>
        </>
    );
};
