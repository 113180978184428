import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useGetSeatSeparationGroupByIdQuery, useUpdateSeatSeparationGroupsByIdMutation } from "@app/core/services";
import { notification } from "antd";
import { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { InitialValuesType } from "../constants";
import { parseValueToApi } from "../helpers";

interface UseBuyerSeatListsEditPage {
    separationGroupId: number;
    name: string;
    isLoading: boolean;
    isSubmitting: boolean;
    initialValues: InitialValuesType;
    handleSubmit: (values: InitialValuesType) => void;
    handleCancel: () => void;
    handleBack: () => void;
    handlePostDelete: () => void;
}

export const useCompetitiveSeparationGroupEditPage = (): UseBuyerSeatListsEditPage => {
    const { seatId, id } = useParams<{ seatId: string; id: string }>();
    const history = useHistory();

    const { data, isFetching } = useGetSeatSeparationGroupByIdQuery(Number(id));
    const initialValues: InitialValuesType = useMemo(
        () => ({
            id: data?.id as number,
            name: data?.name as string,
            mode: Boolean(data?.iabCategories.length),
            iabCategories: data?.iabCategories.map((category) => ({ label: category.name, value: category.id })) || [],
            industries: data?.industries.map((industry) => ({ label: industry.name, value: industry.id })) || [],
        }),
        [data]
    );
    const [updateSeatSeparationGroups, { isLoading }] = useUpdateSeatSeparationGroupsByIdMutation();

    const handleSubmit = async (values: InitialValuesType) => {
        const body = parseValueToApi(values);

        try {
            const res = await updateSeatSeparationGroups({
                id,
                body,
            }).unwrap();
            history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_DETAILS_PAGE(seatId, res.id));
            notification.success({ message: "Separation Group updated successfully" });
        } catch (error) {
            notification.error({ message: `Failed to update Separation Group: ${error?.data?.errorDescription}` });
        }
    };

    const handleCancel = () => {
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS(seatId));
    };

    const handleBack = () => {
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_DETAILS_PAGE(seatId, id));
    };

    const handlePostDelete = () => {
        history.replace(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS(seatId));
    };

    return {
        name: data?.name || "",
        separationGroupId: Number(id),
        isLoading: isFetching,
        isSubmitting: isLoading,
        handlePostDelete,
        initialValues,
        handleSubmit,
        handleCancel,
        handleBack,
    };
};
