import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LabeledValue } from "antd/lib/select";
import { OVERALL_LABEL_VALUE } from "./ViewFilter";
import { TODAY_LABEL_VALUE } from "./TimeFrameFilter";
import { METRIC_ONE_DEFAULTS_BY_VIEW_ID, METRIC_TWO_DEFAULTS_BY_VIEW_ID } from "./ChartMetricsFilters";
import { USD_OPTION_ID } from "./DashboardFilters/PreferredCurrencyField";
import { UTC_TIMEZONE_CODE } from "./DashboardFilters/TimeZoneField";
import { CURRENCIES_BY_CODE } from "@app/core/utils/currencies";
import { CurrencyConversionModes, RevenueTypes } from "@app/core/clients/console";
import { RootState } from "@app/core/store";

export interface DashboardSettings {
    currencyConversionMode: CurrencyConversionModes;
    dashboardRevenueType: RevenueTypes | null;
    inventoryTargetingMode: "simple" | "advanced" | null;
    preferredBuyer?: number;
    preferredCurrency: number | string | null;
    preferredSeat?: number;
    // TimeZone elsewhere in app. Inconsistent API response - do not change or initial set will break
    timezone: string | null;
}

export interface DashboardState {
    filters: {
        demandType: LabeledValue[];
        publisher: LabeledValue[];
        view: LabeledValue;
        timeFrame: LabeledValue;
        metricOne: LabeledValue;
        metricTwo: LabeledValue;
    };
    settings: {
        shouldUpdate: boolean;
        values: DashboardSettings;
    };
}

const initialState: DashboardState = {
    filters: {
        demandType: [],
        publisher: [],
        view: OVERALL_LABEL_VALUE,
        timeFrame: TODAY_LABEL_VALUE,
        metricOne: METRIC_ONE_DEFAULTS_BY_VIEW_ID[OVERALL_LABEL_VALUE?.value],
        metricTwo: METRIC_TWO_DEFAULTS_BY_VIEW_ID[OVERALL_LABEL_VALUE?.value],
    },
    settings: {
        shouldUpdate: false,
        values: {
            currencyConversionMode: CurrencyConversionModes.CONVERT,
            dashboardRevenueType: RevenueTypes.NET_REVENUE,
            inventoryTargetingMode: null,
            preferredCurrency: USD_OPTION_ID,
            timezone: UTC_TIMEZONE_CODE,
        },
    },
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        setFiltersDemandType: (state, action: PayloadAction<LabeledValue[]>) => {
            state.filters.demandType = action.payload;
        },
        setFiltersPublisher: (state, action: PayloadAction<LabeledValue[]>) => {
            state.filters.publisher = action.payload;
        },
        setFiltersView: (
            state,
            action: PayloadAction<{
                view: LabeledValue;
                metricOne: LabeledValue;
                metricTwo: LabeledValue;
            }>
        ) => {
            state.filters.view = action.payload.view;
            state.filters.metricOne = action.payload.metricOne;
            state.filters.metricTwo = action.payload.metricTwo;
        },
        setFiltersTimeFrame: (state, action: PayloadAction<LabeledValue>) => {
            state.filters.timeFrame = action.payload;
        },
        setFiltersMetricOne: (state, action: PayloadAction<LabeledValue>) => {
            state.filters.metricOne = action.payload;
        },
        setFiltersMetricTwo: (state, action: PayloadAction<LabeledValue>) => {
            state.filters.metricTwo = action.payload;
        },
        setSettingsCurrencyConversionMode: (state, action: PayloadAction<CurrencyConversionModes>) => {
            state.settings.values.currencyConversionMode = action.payload;
            state.settings.shouldUpdate = true;
        },
        setSettingsDashboardRevenueType: (state, action: PayloadAction<RevenueTypes | null>) => {
            state.settings.values.dashboardRevenueType = action.payload;
            state.settings.shouldUpdate = true;
        },
        setSettingsPreferredCurrency: (state, action: PayloadAction<number | null>) => {
            state.settings.values.preferredCurrency = action.payload;
            state.settings.shouldUpdate = true;
        },
        setSettingsTimezone: (state, action: PayloadAction<string | null>) => {
            state.settings.values.timezone = action.payload;
            state.settings.shouldUpdate = true;
        },
        setInitialSettings: (state, action: PayloadAction<Partial<DashboardSettings>>) => {
            const { preferredCurrency, ...rest } = action.payload;
            state.settings.values = {
                ...state.settings.values,
                ...rest,
            };
            if (preferredCurrency) {
                if (typeof preferredCurrency === "number") {
                    state.settings.values.preferredCurrency = preferredCurrency;
                } else {
                    const currency = CURRENCIES_BY_CODE[preferredCurrency];
                    if (currency) {
                        state.settings.values.preferredCurrency = currency.id;
                    }
                }
            }
            state.settings.shouldUpdate = false;
        },
    },
});

export const selectDashboardCurrencyConversionMode = (state: RootState) =>
    state.dashboard.settings.values.currencyConversionMode;
export const selectDashboardRevenueType = (state: RootState) => state.dashboard.settings.values.dashboardRevenueType;
export const selectDashboardTimeFrameFilter = (state: RootState) => state.dashboard.filters.timeFrame;
export const selectDashboardSettings = (state: RootState) => state.dashboard.settings;
export const selectDashboardPreferredCurrency = (state: RootState) => state.dashboard.settings.values.preferredCurrency;

export const {
    setFiltersDemandType,
    setFiltersMetricOne,
    setFiltersMetricTwo,
    setFiltersPublisher,
    setFiltersTimeFrame,
    setFiltersView,
    setSettingsCurrencyConversionMode,
    setSettingsDashboardRevenueType,
    setSettingsPreferredCurrency,
    setSettingsTimezone,
    setInitialSettings,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
