import { FC } from "react";
import { BuyerIFrame } from "@app/core/components";
import conf from "@app/core/conf";

export const BuyerDiscountsPage: FC = () => {
    return (
        <BuyerIFrame
            src={(buyerId) => `${conf.mctvConsoleRoot}/ssp/buyers/${buyerId}/buyer-discounts`}
            title="Discounts"
        />
    );
};
