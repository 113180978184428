import { FC } from "react";
import { AdUnitMinDuration } from "@app/features/inventory/components/FormItems";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const MinDuration: FC = () => (
    <AdUnitMinDuration
        name={AD_UNIT_FORM_FIELDS.MIN_DURATION.name}
        label={AD_UNIT_FORM_FIELDS.MIN_DURATION.label}
        placeholder={AD_UNIT_FORM_FIELDS.MIN_DURATION.placeholder}
    />
);
