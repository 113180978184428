import { GUTTER } from "@app/features/seatAdSources/constants";
import { css } from "@emotion/css";
import { Card, Row, Col } from "antd";
import { FC } from "react";
import {
    BuyerMultiselect,
    DealIdInput,
    DealNameInput,
    DealOriginSelect,
    DealTypeSelect,
    Duration,
    NetworkSelect,
    PricingTypeSelect,
    PublisherNameInput,
    StatusSelect,
    TimeZoneSelect,
    TransactionTypeSelect,
} from "./Fields";
import { PublisherEmailInput } from "./Fields/PublisherEmailInput";
import { InternalSection } from "./InternalSection";

export const formItemOffset = css({ marginBottom: "1rem" });

export const DealTermsSection: FC = () => {
    return (
        <Card bordered={false}>
            <Row gutter={GUTTER}>
                <Col sm={12}>
                    <DealTypeSelect />
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <TransactionTypeSelect />
                <PricingTypeSelect />
                <DealIdInput />
                <DealNameInput />
                <NetworkSelect />
                <BuyerMultiselect />
                <PublisherNameInput />
                <PublisherEmailInput />
                <Duration />
                <TimeZoneSelect />
                <StatusSelect />
                <DealOriginSelect />
                <InternalSection />
            </Row>
        </Card>
    );
};
