import { Affix } from "antd";
import { PageHeader } from "@ant-design/pro-layout";

export const TAG_PARAMS_HEADER_SDET = "tag-params-header";

export const TagParamsHeader = () => {
    return (
        <Affix>
            <PageHeader data-sdet={TAG_PARAMS_HEADER_SDET} ghost={false} title="Tag Parameters" />
        </Affix>
    );
};

export default TagParamsHeader;
