import { FC, useState, useMemo } from "react";
import { Col, Input, Menu, Row, Spin, Typography } from "antd";
import { brandSlate, gray1, gray5 } from "@rubicon/antd-components";
import { SearchOutlined } from "@ant-design/icons";
import { HelpArticle } from "@app/core/services";
import {
    TargetingDimensionHelpKeys,
    TargetingDimensionTypeLabels,
    TargetingDimensionTypes,
} from "@app/features/targeting/constants";
import { isAddDisabled } from "@app/features/targeting/helpers";
import { SupplyTypesTargetsDimension, UsedDimensions } from "@app/features/targeting/types";
import { DimensionMenuLabel } from "./DimensionMenuLabel";
import { useSeatAuthContext } from "@app/core/auth";

interface Props {
    helpArticles: { [key: string]: HelpArticle };
    isLoading: boolean;
    onAddDimension: (dimensionType: string) => void;
    supplyTypes: SupplyTypesTargetsDimension;
    usedDimensions: UsedDimensions;
    usedDimensionsAll: UsedDimensions[];
}

const ADD_ON_STYLE = { padding: "4px 6px", background: gray1, border: `1px solid ${gray5}` };
const FREQUENTLY_USED_PREFIX = "frequently-used-";

export const AddDimensionMenu: FC<Props> = ({
    onAddDimension,
    usedDimensions,
    usedDimensionsAll,
    supplyTypes,
    isLoading,
    helpArticles,
}) => {
    const [search, setSearch] = useState("");
    const { context } = useSeatAuthContext();

    const MENU_ITEMS_ALL_DIMENSIONS = useMemo(
        () => [
            {
                label: TargetingDimensionTypeLabels.AdBreakPositionTargets,
                type: TargetingDimensionTypes.AdBreakPositionTargets,
                helpKey: TargetingDimensionHelpKeys.AdBreakPositionTargets,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.Audiences,
                type: TargetingDimensionTypes.Audiences,
                helpKey: TargetingDimensionHelpKeys.Audiences,
                canAddIncludeAndExcludeRows: false,
                hidden: !context?.enableAudienceTargeting,
            },
            {
                label: TargetingDimensionTypeLabels.BundleIdTargets,
                type: TargetingDimensionTypes.BundleIdTargets,
                helpKey: TargetingDimensionHelpKeys.BundleIdTargets,
                canAddIncludeAndExcludeRows: false,
                frequentlyUsed: true,
            },
            {
                label: TargetingDimensionTypeLabels.Bvod,
                type: TargetingDimensionTypes.Bvod,
                helpKey: TargetingDimensionHelpKeys.Bvod,
                canAddIncludeAndExcludeRows: true,
                hidden: !context?.oztamEnabled,
            },
            {
                label: TargetingDimensionTypeLabels.Categories,
                type: TargetingDimensionTypes.Categories,
                helpKey: TargetingDimensionHelpKeys.Categories,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.ContentCategories,
                type: TargetingDimensionTypes.ContentCategories,
                helpKey: TargetingDimensionHelpKeys.ContentCategories,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.ContentChannels,
                type: TargetingDimensionTypes.ContentChannels,
                helpKey: TargetingDimensionHelpKeys.ContentChannels,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.ContentLengths,
                type: TargetingDimensionTypes.ContentLengths,
                helpKey: TargetingDimensionHelpKeys.ContentLengths,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.ContentNetworks,
                type: TargetingDimensionTypes.ContentNetworks,
                helpKey: TargetingDimensionHelpKeys.ContentNetworks,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.Producers,
                type: TargetingDimensionTypes.Producers,
                helpKey: TargetingDimensionHelpKeys.Producers,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.ContentRatings,
                type: TargetingDimensionTypes.ContentRatings,
                helpKey: TargetingDimensionHelpKeys.ContentRatings,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.ContentSeries,
                type: TargetingDimensionTypes.ContentSeries,
                helpKey: TargetingDimensionHelpKeys.ContentSeries,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.VideoIds,
                type: TargetingDimensionTypes.VideoIds,
                helpKey: TargetingDimensionHelpKeys.VideoIds,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.Coppa,
                type: TargetingDimensionTypes.Coppa,
                helpKey: TargetingDimensionHelpKeys.Coppa,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.CustomTargets,
                type: TargetingDimensionTypes.CustomTargets,
                helpKey: TargetingDimensionHelpKeys.CustomTargets,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.CustomRules,
                type: TargetingDimensionTypes.CustomRules,
                helpKey: TargetingDimensionHelpKeys.CustomRules,
                canAddIncludeAndExcludeRows: true,
                frequentlyUsed: true,
            },
            {
                label: TargetingDimensionTypeLabels.DayPartTargets,
                type: TargetingDimensionTypes.DayPartTargets,
                helpKey: TargetingDimensionHelpKeys.DayPartTargets,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.SegmentRules,
                type: TargetingDimensionTypes.SegmentRules,
                helpKey: TargetingDimensionHelpKeys.SegmentRules,
                canAddIncludeAndExcludeRows: true,
                hidden: !context?.dmps.length,
                frequentlyUsed: true,
            },
            {
                label: TargetingDimensionTypeLabels.Dnt,
                type: TargetingDimensionTypes.Dnt,
                helpKey: TargetingDimensionHelpKeys.Dnt,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.Genres,
                type: TargetingDimensionTypes.Genres,
                helpKey: TargetingDimensionHelpKeys.Genres,
                canAddIncludeAndExcludeRows: true,
            },
            {
                label: TargetingDimensionTypeLabels.GeoTargets,
                type: TargetingDimensionTypes.GeoTargets,
                helpKey: TargetingDimensionHelpKeys.GeoTargets,
                canAddIncludeAndExcludeRows: true,
                frequentlyUsed: true,
            },
            {
                label: TargetingDimensionTypeLabels.Pmp,
                type: TargetingDimensionTypes.Pmp,
                helpKey: TargetingDimensionHelpKeys.Pmp,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.Inventory,
                type: TargetingDimensionTypes.Inventory,
                helpKey: TargetingDimensionHelpKeys.Inventory,
                canAddIncludeAndExcludeRows: true,
                frequentlyUsed: true,
            },
            {
                label: TargetingDimensionTypeLabels.LabelValues,
                type: TargetingDimensionTypes.LabelValues,
                helpKey: TargetingDimensionHelpKeys.LabelValues,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.LiveStream,
                type: TargetingDimensionTypes.LiveStream,
                helpKey: TargetingDimensionHelpKeys.LiveStream,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.MaxDurationTarget,
                type: TargetingDimensionTypes.MaxDurationTarget,
                helpKey: TargetingDimensionHelpKeys.MaxDurationTarget,
                canAddIncludeAndExcludeRows: false,
                frequentlyUsed: true,
            },
            {
                label: TargetingDimensionTypeLabels.MimeTypes,
                type: TargetingDimensionTypes.MimeTypes,
                helpKey: TargetingDimensionHelpKeys.MimeTypes,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.MinDurationTarget,
                type: TargetingDimensionTypes.MinDurationTarget,
                helpKey: TargetingDimensionHelpKeys.MinDurationTarget,
                canAddIncludeAndExcludeRows: false,
                frequentlyUsed: true,
            },
            {
                label: TargetingDimensionTypeLabels.OperatingSystems,
                type: TargetingDimensionTypes.OperatingSystems,
                helpKey: TargetingDimensionHelpKeys.OperatingSystems,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.Platforms,
                type: TargetingDimensionTypes.Platforms,
                helpKey: TargetingDimensionHelpKeys.Platforms,
                canAddIncludeAndExcludeRows: false,
                frequentlyUsed: true,
            },
            {
                label: TargetingDimensionTypeLabels.PodSlotPositionTargets,
                type: TargetingDimensionTypes.PodSlotPositionTargets,
                helpKey: TargetingDimensionHelpKeys.PodSlotPositionTargets,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.ApiFrameworks,
                type: TargetingDimensionTypes.ApiFrameworks,
                helpKey: TargetingDimensionHelpKeys.ApiFrameworks,
                canAddIncludeAndExcludeRows: false,
            },
            {
                label: TargetingDimensionTypeLabels.Sizes,
                type: TargetingDimensionTypes.Sizes,
                helpKey: TargetingDimensionHelpKeys.Sizes,
                canAddIncludeAndExcludeRows: false,
                frequentlyUsed: true,
            },
            {
                label: TargetingDimensionTypeLabels.SupplyDomainTargets,
                type: TargetingDimensionTypes.SupplyDomainTargets,
                helpKey: TargetingDimensionHelpKeys.SupplyDomainTargets,
                canAddIncludeAndExcludeRows: false,
                frequentlyUsed: true,
            },
            {
                label: TargetingDimensionTypeLabels.SupplyTypes,
                type: TargetingDimensionTypes.SupplyTypes,
                helpKey: TargetingDimensionHelpKeys.SupplyTypes,
                canAddIncludeAndExcludeRows: false,
                frequentlyUsed: true,
            },
        ],
        [context]
    );

    if (isLoading) {
        return (
            <Row style={{ width: "256px" }}>
                <Col xs={24} style={ADD_ON_STYLE}>
                    <Row justify="center">
                        <Col>
                            <Spin />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

    return (
        <Row style={{ width: "256px" }}>
            <Col xs={24} style={ADD_ON_STYLE}>
                <Input
                    onChange={(e) => setSearch(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                    value={search}
                    suffix={<SearchOutlined style={{ color: brandSlate }} />}
                    size="small"
                />
            </Col>
            <Col xs={24}>
                <Menu
                    onClick={(option) => {
                        const cleanedKey = option.key.replace(FREQUENTLY_USED_PREFIX, "");
                        onAddDimension(cleanedKey);
                    }}
                    style={{ maxHeight: "192px", width: "100%", overflowY: "scroll" }}
                    items={[
                        {
                            label: <Typography.Text strong>Frequently Used</Typography.Text>,
                            type: "group",
                            children: MENU_ITEMS_ALL_DIMENSIONS.filter(
                                (item) =>
                                    item.label.toLowerCase().includes(search.toLowerCase()) &&
                                    !item.hidden &&
                                    item.frequentlyUsed
                            ).map((item) => ({
                                label: (
                                    <DimensionMenuLabel
                                        type={item.type}
                                        usedDimensions={usedDimensions}
                                        usedDimensionsAll={usedDimensionsAll}
                                        label={item.label}
                                        isDisabled={isAddDisabled(
                                            item.type,
                                            usedDimensions,
                                            usedDimensionsAll,
                                            supplyTypes
                                        )}
                                        helpContent={helpArticles[item.helpKey]}
                                        canAddIncludeAndExcludeRows={item.canAddIncludeAndExcludeRows}
                                    />
                                ),
                                searchlabel: item.label,
                                key: `${FREQUENTLY_USED_PREFIX}${item.type}`,
                                disabled: isAddDisabled(item.type, usedDimensions, usedDimensionsAll, supplyTypes),
                            })),
                        },
                        {
                            label: <Typography.Text strong={true}>All Dimensions</Typography.Text>,
                            type: "group",
                            children: MENU_ITEMS_ALL_DIMENSIONS.filter(
                                (item) => item.label.toLowerCase().includes(search.toLowerCase()) && !item.hidden
                            ).map((item) => ({
                                label: (
                                    <DimensionMenuLabel
                                        type={item.type}
                                        usedDimensions={usedDimensions}
                                        usedDimensionsAll={usedDimensionsAll}
                                        label={item.label}
                                        isDisabled={isAddDisabled(
                                            item.type,
                                            usedDimensions,
                                            usedDimensionsAll,
                                            supplyTypes
                                        )}
                                        helpContent={helpArticles[item.helpKey]}
                                        canAddIncludeAndExcludeRows={Boolean(item.canAddIncludeAndExcludeRows)}
                                    />
                                ),
                                searchlabel: item.label,
                                key: item.type,
                                disabled: isAddDisabled(item.type, usedDimensions, usedDimensionsAll, supplyTypes),
                            })),
                        },
                    ]}
                />
            </Col>
        </Row>
    );
};
