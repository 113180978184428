import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { Form } from "antd";
import { useEffect } from "react";

export const useReferUrl = () => {
    const allowDomainName = Form.useWatch<AdSourcesForm["allowDomainName"]>(AD_SOURCE_FIELDS.REFER_URL.name);
    const { setFieldValue } = Form.useFormInstance();
    useEffect(() => {
        if (allowDomainName) {
            setFieldValue(AD_SOURCE_FIELDS.REFER_URL.name, true);
        }
    }, [allowDomainName, setFieldValue]);
    const isDisabled = allowDomainName === "override";
    const extraText = isDisabled ? "Uses Domain Name Override" : null;
    return { isDisabled: isDisabled, extraText };
};
