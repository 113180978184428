import { FC } from "react";
import { FeedbackDrawer } from "@app/core/components/FeedbackDrawer/FeedbackDrawer";
import { useHose } from "@app/core/services/useHose";
import conf from "@app/core/conf";
import { SeatIFrame } from "@app/core/components";
import { IframeBetaLabel } from "@app/core/components/SeatIFrame";
import { IFRAMED_APP_ID } from "./constants";

export const IntegrationsStreamingIframe: FC = () => {
    const { init, launchApplication } = useHose(IFRAMED_APP_ID);
    const srcBuilder = (seatId) => `${conf.appsMagniteRoot}/streaming-integrations/#/seats/${seatId}/integrations`;
    const onLoad = () => {
        init();
        launchApplication();
    };
    return (
        <SeatIFrame
            name="streaming-integrations"
            src={srcBuilder}
            title={<IframeBetaLabel title="Integrations" />}
            navBarExtra={<FeedbackDrawer name="Integrations Beta" />}
            onLoad={onLoad}
        />
    );
};
