import { FC } from "react";
import { Typography } from "antd";
import { Operations } from "../constants";

export const DemandConfigurationOperatorExplanation: FC<{ operation: Operations }> = ({ operation }) => {
    return (
        <Typography.Text>
            It is always &quot;AND&quot; between Ad Source and Deals. However, the Ad Source target mode dictates
            targeting mode for Deals between blocks if you have multiple blocks. Currently, Ad Source target mode is set
            to &quot;
            {operation === Operations.All ? "AND" : "OR"}&quot;
        </Typography.Text>
    );
};
