import { css } from "@emotion/css";
import { ColumnsType } from "antd/es/table";
import { TableRows, FixedType, EntityRaw } from "./types";
import { formatDateTime } from "@app/core/components/ChangeHistoryTable/ChangeLogTable";

export const getColumns = (): ColumnsType<TableRows> =>
    [
        {
            dataIndex: "id",
            key: "id",
            sorter: false,
            title: "ID",
            width: 50,
        },
        {
            dataIndex: "name",
            key: "name",
            sorter: false,
            title: "Name",
            width: 100,
        },
        {
            dataIndex: "code",
            key: "code",
            sorter: false,
            title: "Code",
            width: 100,
        },
        {
            dataIndex: "creationDate",
            key: "creationDate",
            sorter: false,
            title: "Date Created",
            width: 120,
        },
        {
            dataIndex: "deletedDate",
            fixed: "right" as FixedType,
            key: "deletedDate",
            sorter: false,
            title: "Deleted On",
            width: 120,
        },
    ].filter(Boolean);

export const getTableRows = (data: EntityRaw[] | undefined) =>
    data
        ? data.map<TableRows>((entity) => {
              return {
                  className: "",
                  id: entity.id,
                  name: entity.name,
                  key: entity.id,
                  code: entity.code,
                  creationDate: formatDateTime(entity.creationDate),
                  deletedDate: formatDateTime(entity.updateDate),
              };
          })
        : [];

export const styles = css`
    .ant-table-selection {
        flex-direction: inherit;
    }
    .ant-table-selection::after {
        content: "Restore";
        padding-left: 5px;
    }
`;
