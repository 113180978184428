import { Floor, useGetAdUnitFloorsQuery } from "@app/core/services";
import { useParams } from "react-router-dom";
import { compareFloorsByPriority } from "../helpers";
import { useMemo } from "react";

interface UseAdUnitFloorsTable {
    floors?: Array<Floor>;
    isFetching: boolean;
    adUnitId: string;
}

export const useAdUnitFloorsTable = (): UseAdUnitFloorsTable => {
    const { adUnitId } = useParams<{ adUnitId: string }>();
    const { data, isFetching } = useGetAdUnitFloorsQuery({ adUnitId: Number(adUnitId), list: "all" });

    const floors = useMemo(() => {
        return [...(data || [])].sort(compareFloorsByPriority);
    }, [data]);

    return {
        floors,
        isFetching,
        adUnitId,
    };
};
