import { AdUnitCompetitiveSeparationGroups } from "@app/features/inventory/components/FormItems";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const SeparationGroups = () => {
    return (
        <AdUnitCompetitiveSeparationGroups
            name={CHANNEL_FORM_FIELDS.COMPETITIVE_SEPARATIO_GROUPS.name}
            label={CHANNEL_FORM_FIELDS.COMPETITIVE_SEPARATIO_GROUPS.label}
        />
    );
};
