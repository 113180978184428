import { Form } from "antd";
import { FC } from "react";
import { LABEL_CLASS_LIST } from "@app/features/inventory/constants";
import { ThirdpartyPixels } from "@app/features/inventory/components/Fields";
import { SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { InheritedCustomPixelsDrawer } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/CustomPixelsSection/Fields/CustomPixels/InheritedCustomPixelsDrawer";

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 };
export const CustomPixels: FC = () => {
    return (
        <Form.Item
            labelCol={COL_SIZES}
            wrapperCol={COL_SIZES}
            className={LABEL_CLASS_LIST}
            label={<InheritedCustomPixelsDrawer />}
            name={SUPPLY_FORM_FIELDS.CUSTOM_PIXELS.name}
        >
            <ThirdpartyPixels placeholder={SUPPLY_FORM_FIELDS.CUSTOM_PIXELS.placeholder} />
        </Form.Item>
    );
};
