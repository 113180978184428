import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormRadioGroupChange } from "@app/features/deals/DealForm/reducer";

interface UseAdSourceDailyImpressionRadio {
    value: boolean;
    handleChange: (value: boolean) => void;
}

export const useAdSourceDailyImpressionRadio = (): UseAdSourceDailyImpressionRadio => {
    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_DAILY_IMPRESSION_CAP_ENABLED]
    );
    const dispatch = useAppDispatch();
    const handleChange = (value: boolean) => {
        dispatch(
            dealFormRadioGroupChange({
                field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_DAILY_IMPRESSION_CAP_ENABLED,
                value,
            })
        );
    };

    return {
        value: Boolean(value),
        handleChange,
    };
};
