import { useMemo, useState } from "react";
import {
    IabCategory,
    SeparationGroup,
    IndustriesCategory,
    SeatSeparationGroupsPayload,
    useGetSeatSeparationGroupsQuery,
    useCreateSeatSeparationGroupsMutation,
} from "@app/core/services";
import { useParams } from "react-router-dom";
import { LabeledValue } from "antd/lib/select";
import { Form, FormInstance, notification } from "antd";
import {
    SEPARATION_GROUPS,
    COMPETITIVE_SEPARATION_MODE,
    INDUSTRIES_COMPETITIVE_SEPARATION_MODE,
    IAB_CATEGORIES_COMPETITIVE_SEPARATION_MODE,
} from "@app/features/inventory/HierarchyForms/constants";
import { getSeparationGroupLabel } from "@app/features/inventory/helpers";
import { SeparationGroupsForm } from "@app/features/inventory/components/FormItems/AdUnitCompetitiveSeparationGroups/AdUnitSeparationGroupFormDrawer";

const { useFormInstance, useWatch } = Form;

export interface SeparationGroupOption {
    industries: IndustriesCategory[];
    iabCategories: IabCategory[];
    value: number;
    label: string;
}

interface UseAdUnitCompetitiveSeparationGroups {
    isOpen: boolean;
    isLoading: boolean;
    isFetching: boolean;
    handleCancel: () => void;
    handleSubmit: () => void;
    isIndustriesMode: boolean;
    handleAddNewGroup: () => void;
    options: SeparationGroupOption[];
    onFinish: (values: SeparationGroupsForm) => void;
    separationGroupForm: FormInstance<SeparationGroupsForm>;
    onChange: (value: LabeledValue[], options: SeparationGroupOption[]) => void;
}

export const useAdUnitCompetitiveSeparationGroups = (): UseAdUnitCompetitiveSeparationGroups => {
    const form = useFormInstance();
    const { seatId } = useParams<{ seatId: string }>();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [separationGroupForm] = Form.useForm<SeparationGroupsForm>();

    const competitiveMode: boolean = useWatch(COMPETITIVE_SEPARATION_MODE);
    const separationGroups: SeparationGroupOption[] = useWatch(SEPARATION_GROUPS);
    const isIndustriesMode: boolean = competitiveMode === INDUSTRIES_COMPETITIVE_SEPARATION_MODE;
    const isCategoriesMode: boolean = competitiveMode === IAB_CATEGORIES_COMPETITIVE_SEPARATION_MODE;

    const [createSeparationGroup, { isLoading }] = useCreateSeatSeparationGroupsMutation();

    const { data, isFetching } = useGetSeatSeparationGroupsQuery({
        seatId,
        iabGroups: isCategoriesMode,
        industryGroups: isIndustriesMode,
    });
    const options: SeparationGroupOption[] = useMemo(
        () =>
            (data || []).map(({ name, id, iabCategories, industries }) => ({
                value: id,
                label: getSeparationGroupLabel(name, iabCategories),
                iabCategories,
                industries,
            })),
        [data]
    );

    const handleCancel = (): void => setIsOpen(false);
    const handleAddNewGroup = (): void => setIsOpen(true);

    const onChange = (value: LabeledValue[], options: SeparationGroupOption[]): void =>
        form.setFieldValue(SEPARATION_GROUPS, options);
    const onFinish = async (values: SeparationGroupsForm): Promise<void> => {
        const body: SeatSeparationGroupsPayload = {
            name: values.name,
            iabCategories: (values.iabCategories || []).map(({ value }) => ({ id: value as number })),
            industries: (values.industries || []).map(({ value }) => ({ id: value as number })),
            seat: { id: seatId },
        };
        try {
            const separationGroup: SeparationGroup = await createSeparationGroup({ seatId, body }).unwrap();
            const options: SeparationGroupOption[] = [
                ...separationGroups,
                {
                    value: separationGroup.id,
                    label: separationGroup.name,
                    iabCategories: separationGroup.iabCategories,
                    industries: separationGroup.industries,
                },
            ];
            form.setFieldValue(SEPARATION_GROUPS, options);
            separationGroupForm.resetFields();
            handleCancel();
            notification.success({ message: "Competitive Separation Group created successfully" });
        } catch (err) {
            notification.error({ message: err?.data?.errorDescription });
        }
    };
    const handleSubmit = (): void => separationGroupForm.submit();

    return {
        isOpen,
        options,
        onFinish,
        onChange,
        isLoading,
        isFetching,
        handleSubmit,
        handleCancel,
        isIndustriesMode,
        handleAddNewGroup,
        separationGroupForm,
    };
};
