import { uri } from "@rubicon/utils";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { EDIT_MODE, MACROS_PICKER } from "../constants";
import { getParamFromSearch } from "../helpers";
import { useCreateSeatTagParamSettingIdMutation, useUpdateSeatTagParamSettingIdMutation } from "@app/core/services";
import { notification } from "antd";
import { useAppSelector } from "@app/core/store";
import { selectMacroFields } from "../reducer";

export const useEditModeControls = (handleModalOpen?: () => void) => {
    const { search } = useLocation();
    const { push } = useHistory();
    const requests = [] as unknown[];
    const { seatId } = useParams<{ seatId: string }>();

    const [updateSeatTagParamSetting, { isLoading: isUpdating }] = useUpdateSeatTagParamSettingIdMutation();
    const [createSeatTagParamSetting, { isLoading: isCreating }] = useCreateSeatTagParamSettingIdMutation();
    const macroValues = useAppSelector(selectMacroFields);

    const isEdit = getParamFromSearch(search, EDIT_MODE) === "enabled";
    const entries = macroValues && Object.values(macroValues);

    const handleEditClick = () => {
        const nextSearch = uri.setSearchParam(search, EDIT_MODE.key, "enabled");
        push({ search: nextSearch });
    };

    const handleCancelClick = () => {
        const nextSearchNoEditModeKey = uri.setSearchParam(search, EDIT_MODE.key, "");
        const nextSearch = uri.setSearchParam(nextSearchNoEditModeKey, MACROS_PICKER.key, "");
        if (
            entries?.some((field) => {
                return field.isDirty;
            })
        ) {
            return handleModalOpen?.();
        }
        push({ search: nextSearch });
    };

    const handleSaveClick = () => {
        const nextSearchNoEditModeKey = uri.setSearchParam(search, EDIT_MODE.key, "");
        const nextSearch = uri.setSearchParam(nextSearchNoEditModeKey, MACROS_PICKER.key, "");

        entries?.forEach((field) => {
            if (field.isDirty) {
                if (field.id) {
                    return requests.push(
                        updateSeatTagParamSetting({
                            selected: field.selected,
                            macro: field.macro,
                            name: field.name,
                            tagParamDefinition: {
                                id: field.tagParamId,
                            },
                            id: field.id,
                        }).unwrap()
                    );
                }

                return requests.push(
                    createSeatTagParamSetting({
                        selected: field.selected,
                        macro: field.macro,
                        name: field.name,
                        tagParamDefinition: {
                            id: field.tagParamId,
                        },
                        seat: {
                            id: Number(seatId),
                        },
                    }).unwrap()
                );
            }
        });
        Promise.all(requests)
            .then(() => notification.success({ message: "Seat(s) updated successfully" }))
            .catch((error) => {
                notification.error({ message: error.data?.errorDescription });
            });

        push({ search: nextSearch });
    };

    return {
        isEdit: Boolean(isEdit),
        isSaving: isCreating || isUpdating,
        handleEditClick,
        handleCancelClick,
        handleSaveClick,
    };
};
