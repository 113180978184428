import { ColumnsType } from "antd/es/table";
import { TableRows } from "./types";
import { FixedType } from "../../recycleBin/RecycleBinTable/types";
import { Button, Tooltip, Typography } from "antd";
import { SEARCH } from "@app/core/components";
import { AdvertiserDomainRaw } from "@app/core/services/console/brandSafety";
import { DeleteOutlined } from "@ant-design/icons";
import { DeleteCandidate } from "../AdvancedBrandSafety";

export const getColumns = (): ColumnsType<TableRows> =>
    [
        {
            dataIndex: "name",
            key: "name",
            sorter: false,
            title: "Name",
            width: 250,
        },
        {
            dataIndex: "type",
            key: "type",
            sorter: false,
            title: "Type",
            width: 150,
        },
        {
            dataIndex: "id",
            key: "id",
            sorter: false,
            title: "ID",
            width: 100,
        },
        {
            dataIndex: "actions",
            fixed: "right" as FixedType,
            key: "actions",
            sorter: false,
            title: "Actions",
            width: 50,
        },
    ].filter(Boolean);

export const getTableRows = (
    data: AdvertiserDomainRaw[] | undefined,
    seatId: string,
    search: string,
    handleDrawerOpen: (id: string) => void,
    handleDeleteModal?: (candidate: DeleteCandidate) => void
) =>
    data
        ? data
              .filter((item) => {
                  const keyword = new URLSearchParams(search).get(SEARCH.key);
                  return keyword
                      ? [item.name, String(item.id), item.advertiserDomainListType.name].some((entity) =>
                            entity.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
                        )
                      : true;
              })
              .map<TableRows>((entity) => {
                  return {
                      className: "",
                      id: entity.id,
                      name: (
                          <Typography.Link onClick={() => handleDrawerOpen(String(entity.id))}>
                              {entity.name}
                          </Typography.Link>
                      ),
                      key: entity.id,
                      type: entity.advertiserDomainListType.name,
                      actions: (
                          <Button.Group>
                              <Tooltip title="Delete">
                                  <Button
                                      icon={<DeleteOutlined />}
                                      onClick={() =>
                                          entity?.id && handleDeleteModal?.({ id: entity.id, name: entity.name })
                                      }
                                  />
                              </Tooltip>
                          </Button.Group>
                      ),
                  };
              })
        : [];
