import { FC, ReactNode } from "react";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useGetThirdPartyPixelsTypesQuery } from "@app/core/services";
import { FixedSaveButtons } from "@app/core/components";
import { Card, Col, Form, Input, Layout, Radio, Row, Select } from "antd";
import { useHistory, useParams } from "react-router-dom";
import {
    CUSTOM_PIXEL_FORM_ITEMS,
    CUSTOM_PIXEL_ADD_TO_SOURCE_BY_DEFAULT_RADIO_GROUP_SDET,
    CUSTOM_PIXEL_NAME_INPUT_SDET,
    CUSTOM_PIXEL_PIXEL_URL_INPUT_SDET,
    CUSTOM_PIXEL_TYPE_SELECT_SDET,
    NAME_REQUIRED_ERROR_MESSAGE,
    PIXEL_URL_PREFIX,
    CustomPixelFormValues,
    NAME_LEN_ERROR_MESSAGE,
} from "./constants";
import { CustomPixelDefinitions } from "../CustomPixelDefinitions";

interface Props {
    onFinish: (values: CustomPixelFormValues) => void;
    initialValues: CustomPixelFormValues;
    submitLabel?: string;
    deleteButton?: ReactNode;
    isSubmitting?: boolean;
}

export const CustomPixelForm: FC<Props> = ({ onFinish, initialValues, submitLabel, deleteButton, isSubmitting }) => {
    const [form] = Form.useForm();
    const history = useHistory();

    const { data, isFetching } = useGetThirdPartyPixelsTypesQuery();
    const options = data?.map(({ id, name }) => ({ label: name, value: id })) || [];
    const { seatId } = useParams<{ seatId: string }>();

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={initialValues}
            onFinish={onFinish}
            wrapperCol={{ xs: 24, md: 12 }}
        >
            <Layout.Content>
                <Card style={{ minHeight: "70vh" }}>
                    <Row justify="space-between">
                        <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 0 }}>
                            <Form.Item
                                {...CUSTOM_PIXEL_FORM_ITEMS.NAME}
                                required
                                rules={[
                                    {
                                        whitespace: true,
                                        required: true,
                                        message: NAME_REQUIRED_ERROR_MESSAGE,
                                    },
                                    {
                                        max: 70,
                                        message: NAME_LEN_ERROR_MESSAGE,
                                    },
                                ]}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input data-sdet={CUSTOM_PIXEL_NAME_INPUT_SDET} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ order: 0 }} md={{ order: 1 }}>
                            <CustomPixelDefinitions />
                        </Col>
                    </Row>
                    <Form.Item {...CUSTOM_PIXEL_FORM_ITEMS.TYPE}>
                        <Select
                            showSearch
                            data-sdet={CUSTOM_PIXEL_TYPE_SELECT_SDET}
                            options={options}
                            optionFilterProp="label"
                            loading={isFetching}
                        />
                    </Form.Item>
                    <Form.Item {...CUSTOM_PIXEL_FORM_ITEMS.ADD_TO_ADD_SOURCE_BY_DEFAULT}>
                        <Radio.Group data-sdet={CUSTOM_PIXEL_ADD_TO_SOURCE_BY_DEFAULT_RADIO_GROUP_SDET}>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item {...CUSTOM_PIXEL_FORM_ITEMS.PIXEL_URL}>
                        <Input addonBefore={PIXEL_URL_PREFIX} data-sdet={CUSTOM_PIXEL_PIXEL_URL_INPUT_SDET} />
                    </Form.Item>
                </Card>
            </Layout.Content>
            <FixedSaveButtons
                isLoading={isSubmitting}
                submitLabel={submitLabel}
                suffixAddOn={deleteButton}
                onCancel={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, "custom-pixels"))}
            />
        </Form>
    );
};
