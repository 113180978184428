import { FC, useEffect } from "react";
import { Col, Row, Form } from "antd";
import { useAppDispatch } from "@app/core/store";
import { HistoricalChart } from "@app/core/components/charts/HistoricalChart/HistoricalChart";
import { HistoricalChartDateRangeFilter } from "@app/core/components/charts/HistoricalChart/HistoricalChartDateRangeFilter/HistoricalChartDateRangeFilter";
import { HistoricalChartGroupBy } from "@app/core/components/charts/HistoricalChart/HistoricalChartGroupBy/HistoricalChartGroupBy";
import { HistoricalChartExportButton } from "@app/core/components/charts/HistoricalChart/HistoricalChartExportButton/HistoricalChartExportButton";
import { HistoricalChartDealFilter } from "./HistoricalChartDealFilter/HistoricalChartDealFilter";
import {
    resetHistoricalChartState,
    setHistoricalChartAdSource,
} from "@app/core/components/charts/HistoricalChart/reducer";
import { useFetchSeatAdSource } from "@app/features";
import { Loading } from "@app/core/components/Loading";

export const SeatAdSourcesPerformanceHistorical: FC<{ adSourceId: number }> = ({ adSourceId }) => {
    const { adSource, isFetching } = useFetchSeatAdSource(adSourceId);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (adSource && adSource.marketplaceInfoList && adSource.marketplaceInfoList.length > 0) {
            dispatch(setHistoricalChartAdSource(adSourceId));
        }

        return () => {
            dispatch(resetHistoricalChartState());
        };
    });

    if (isFetching) {
        return <Loading />;
    }
    if (!adSource) {
        return null;
    }
    return (
        <>
            <Form layout="vertical">
                <Row justify="space-between" align="bottom" style={{ paddingBottom: "26px" }}>
                    <Col xs={24} sm={24} md={24} lg={10}>
                        <HistoricalChartDealFilter marketplaceInfoList={adSource.marketplaceInfoList} />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6}>
                        <HistoricalChartDateRangeFilter />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4}>
                        <HistoricalChartGroupBy />
                    </Col>
                    <Col>
                        <HistoricalChartExportButton />
                    </Col>
                </Row>
            </Form>
            <HistoricalChart />
        </>
    );
};
