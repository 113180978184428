import { EncryptionKey, NewEncryptionKey } from "@app/core/services";
import { Code } from "@rubicon/antd-components";
import { Form, Modal, Typography } from "antd";
import { FC, useState } from "react";
import { formatEncryptionKey } from "./helpers";
import { CopyString } from "./CopyString";
import { css } from "@emotion/css";

export const ENCRYPTION_KEY_NEW_KEY_CREATED_MODAL_SDET = "encryption-key-new-key-created-modal";
export const ENCYRPTION_KEY_NEW_CREATED_MODAL_TITLE = "New Key Created";

export const COPY_NEW_ENCRYPTION_KEY_BUTTON_SDET = "copy-new-encryption-key-button";
export const COPY_NEW_INTEGRITY_KEY_BUTTON_SDET = "copy-new-integrity-key-button";

export const ENCRYPTION_KEY_NEW_KEY_CREATED_MODAL_LABELS = {
    DESCRIPTION: "Please note that your old keys are no longer active.",
    ENCRYPTION_KEY: "Encryption Key",
    INTEGRITY_KEY: "Integrity Key",
    JSON: "JSON Representation",
};

export const useEncryptionKeyNewKeyCreatedModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [encryptionKey, setEncryptionKey] = useState<NewEncryptionKey | null>(null);

    const handleOpen = (nextEncryptionKey: NewEncryptionKey) => {
        setIsOpen(true);
        setEncryptionKey(nextEncryptionKey);
    };

    const onCancel = () => {
        setIsOpen(false);
        setEncryptionKey(null);
    };

    return {
        isOpen,
        handleOpen,
        onCancel,
        encryptionKey,
    };
};

interface Props {
    onCancel: () => void;
    isOpen: boolean;
    encryptionKey: NewEncryptionKey | null;
}

export const EncryptionKeyNewKeyCreatedModal: FC<Props> = ({ onCancel, isOpen, encryptionKey }) => {
    return (
        <Modal
            destroyOnClose
            data-sdet={ENCRYPTION_KEY_NEW_KEY_CREATED_MODAL_SDET}
            title={ENCYRPTION_KEY_NEW_CREATED_MODAL_TITLE}
            open={isOpen}
            onCancel={onCancel}
            footer={null}
        >
            <Form colon={false} layout="vertical">
                <Typography.Title style={{ fontSize: 14, marginBottom: "2rem" }}>
                    {ENCRYPTION_KEY_NEW_KEY_CREATED_MODAL_LABELS.DESCRIPTION}
                </Typography.Title>
                <Form.Item label={ENCRYPTION_KEY_NEW_KEY_CREATED_MODAL_LABELS.ENCRYPTION_KEY}>
                    <CopyString
                        entity={ENCRYPTION_KEY_NEW_KEY_CREATED_MODAL_LABELS.ENCRYPTION_KEY}
                        tooltipProps={{ title: "Copy Encryption Key" }}
                        copyText={String(encryptionKey?.encryptionKey || "")}
                        sdet={COPY_NEW_ENCRYPTION_KEY_BUTTON_SDET}
                    />
                </Form.Item>
                <Form.Item label={ENCRYPTION_KEY_NEW_KEY_CREATED_MODAL_LABELS.INTEGRITY_KEY}>
                    <CopyString
                        entity={ENCRYPTION_KEY_NEW_KEY_CREATED_MODAL_LABELS.INTEGRITY_KEY}
                        tooltipProps={{ title: "Copy Integrity Key" }}
                        copyText={encryptionKey?.integrityKey || ""}
                        sdet={COPY_NEW_INTEGRITY_KEY_BUTTON_SDET}
                    />
                </Form.Item>
                <Form.Item
                    label={ENCRYPTION_KEY_NEW_KEY_CREATED_MODAL_LABELS.JSON}
                    className={css`
                        code {
                            color: #707f90 !important;
                        }
                    `}
                >
                    <Code showLineNumbers={false}>{formatEncryptionKey(encryptionKey as EncryptionKey | null)}</Code>
                </Form.Item>
            </Form>
        </Modal>
    );
};
