import { FC } from "react";
import { Form, Input } from "antd";
import { Seat } from "@app/core/services";
import { NONE } from "@app/core/components/constants";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

interface DescriptionProps {
    seat: Seat;
    edit?: boolean;
}

export const Description: FC<DescriptionProps> = ({ edit = false, seat }) => {
    const { description } = seat;
    return (
        <Form.Item
            label={INVENTORY_FORM_FIELDS.DESCRIPTION.label}
            name={INVENTORY_FORM_FIELDS.DESCRIPTION.name}
            wrapperCol={{ xs: 24, sm: 24, md: 20, lg: 18, xl: 22, xxl: 20 }}
        >
            {edit ? (
                <Input.TextArea rows={4} allowClear placeholder={INVENTORY_FORM_FIELDS.DESCRIPTION.placeholder} />
            ) : (
                description || NONE
            )}
        </Form.Item>
    );
};
