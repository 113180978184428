import { FC, ReactNode } from "react";
import { Button, Card, Flex, Form } from "antd";
import { css } from "@emotion/css";
import { brandText2, gray3, gray5 } from "@rubicon/antd-components";
import { Link } from "react-router-dom";

export interface DealTypeCardProps {
    description: string;
    image: ReactNode;
    sdet?: string;
    title: string;
    to: string;
    onSelect?: () => void;
    disabled?: boolean;
}

const DealTypeCard: FC<DealTypeCardProps> = ({ description, image, title, to, sdet, onSelect, disabled = false }) => (
    <Card
        bodyStyle={{ padding: "16px" }}
        headStyle={{
            textAlign: "center",
            borderColor: gray5,
            padding: "16px 0",
        }}
        className={css`
            width: 300px;
            border-color: ${gray5};
            background: ${disabled ? gray3 : "#fff"};
        `}
        title={image}
        data-sdet={`deal-type-card-${sdet}`}
    >
        <Flex gap={10} vertical>
            <Form layout="vertical" style={{ minHeight: "194px" }}>
                <Form.Item style={{ color: brandText2 }} label={title}>
                    {description}
                </Form.Item>
            </Form>
            {disabled ? (
                <Button disabled style={{ width: "100%", background: "#fff" }}>
                    Select
                </Button>
            ) : (
                <Link to={to}>
                    <Button
                        onClick={() => onSelect?.()}
                        data-sdet={`deal-type-card-select-${sdet}`}
                        className="ant-btn ant-btn-primary"
                        style={{ width: "100%" }}
                    >
                        Select
                    </Button>
                </Link>
            )}
        </Flex>
    </Card>
);

export default DealTypeCard;
