import { FC } from "react";
import { Card, Col, Row } from "antd";
import { GUTTER, TWO_COL_SIZES } from "@app/features/inventory/constants";
import { InternalOnly } from "@app/features/inventory/components/InternalOnly";
import {
    COPPA,
    IFAMode,
    IFAType,
    Keywords,
    VideoQuality,
    PrivacyPolicy,
    SrcRelationship,
    MaskIFAForDemands,
    AllowVASTWrappers,
    ViewabilityVendors,
} from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/SupplyDetailsSection/Fields";
import { useSupplyDetailsSection } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/SupplyDetailsSection/useSupplyDetailsSection";

export const SupplyDetailsSection: FC = () => {
    const { isIfaTypeShown, isIfaModeShown, isViewabilityVendorsShown } = useSupplyDetailsSection();

    return (
        <Card bordered={false}>
            <Row gutter={GUTTER}>
                <Col {...TWO_COL_SIZES}>
                    <VideoQuality />
                    <SrcRelationship />
                    {isIfaModeShown && <IFAMode />}
                    {isIfaTypeShown && <IFAType />}
                    <AllowVASTWrappers />
                    <COPPA />
                    <PrivacyPolicy />
                    <Keywords />
                    <MaskIFAForDemands />
                </Col>
                <Col hidden={!isViewabilityVendorsShown} {...TWO_COL_SIZES}>
                    <InternalOnly>
                        <ViewabilityVendors />
                    </InternalOnly>
                </Col>
            </Row>
        </Card>
    );
};
