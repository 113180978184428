import { ReadonlyControl } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { Form, Radio } from "antd";
import { DEAL_FORM_FIELDS } from "../../../../constants";

export type RunDateMode = "fixed" | "evergreen";

const RUN_DATE_MODE_OPTIONS = {
    FIXED: {
        label: "Fixed Date",
        value: "fixed",
    },
    EVERGREEN: {
        label: "Evergreen",
        value: "evergreen",
    },
};

export const RunDateModeRadio = () => {
    const { marketplaceInfoMode, dealObjectMode } = useMarketplaceInfoWidget();
    const isReadonly = marketplaceInfoMode.name === "view";
    const isDisabled = dealObjectMode === "edit";
    return (
        <Form.Item
            name={DEAL_FORM_FIELDS.RUN_DATE_MODE.name}
            label={DEAL_FORM_FIELDS.RUN_DATE_MODE.label}
            colon={false}
        >
            {isReadonly ? (
                <ReadonlyControl<RunDateMode>
                    getLabel={(mode) =>
                        mode === "fixed" ? RUN_DATE_MODE_OPTIONS.FIXED.label : RUN_DATE_MODE_OPTIONS.EVERGREEN.label
                    }
                />
            ) : (
                <Radio.Group disabled={isDisabled}>
                    <Radio value={RUN_DATE_MODE_OPTIONS.EVERGREEN.value}>{RUN_DATE_MODE_OPTIONS.EVERGREEN.label}</Radio>
                    <Radio value={RUN_DATE_MODE_OPTIONS.FIXED.value}>{RUN_DATE_MODE_OPTIONS.FIXED.label}</Radio>
                </Radio.Group>
            )}
        </Form.Item>
    );
};
