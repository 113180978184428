import { FC } from "react";
import { Card, Space, Button } from "antd";

interface Props {
    visible: boolean;
    loading: boolean;
    cancel: () => void;
    handleSubmit: () => void;
}
export const DealObjectFormControls: FC<Props> = ({ visible, loading, cancel, handleSubmit }) => {
    if (!visible) {
        return null;
    }

    return (
        <Card bordered={false} style={{ paddingTop: 0, boxShadow: "none" }}>
            <Space>
                <Button loading={loading} disabled={loading} type="primary" onClick={handleSubmit}>
                    Save
                </Button>
                <Button onClick={cancel}>Cancel</Button>
            </Space>
        </Card>
    );
};
