import { FC } from "react";
import { Loading } from "@app/core/components";
import { ResizableDrawer } from "@rubicon/antd-components";
import { SeatContactDrawerTitle } from "./SeatContactDrawerTitle";
import { SeatContactsDetailsContent } from "./SeatContactDetailsContent";
import { useSeatContactDrawer } from "./useSeatContactDrawer";
import { drawerRightClosePosition } from "../../constants";

export const SeatContactDrawer: FC = () => {
    const { isLoading, seatContact, contactId, handleDrawerClose } = useSeatContactDrawer();

    isLoading && <Loading />;

    return (
        <ResizableDrawer
            title={<SeatContactDrawerTitle seatContact={seatContact} />}
            open={Boolean(contactId)}
            onClose={handleDrawerClose}
            placement="right"
            width="45%"
            className={drawerRightClosePosition}
        >
            <SeatContactsDetailsContent seatContact={seatContact} />
        </ResizableDrawer>
    );
};
