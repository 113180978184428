import { FC } from "react";
import { Col, Form, Row } from "antd";
import { BrandSupply } from "@app/core/services";
import { getYesNoLabel } from "@app/core/utils/labels";
import { NONE } from "@app/core/components/constants";
import { BASE_INTERNAL_ONLY_LABELS, SUPPLY_INTERNAL_ONLY_LABELS } from "../../../constants";

const { Item } = Form;

interface InternalSectionProps {
    supply: BrandSupply;
}

export const InternalSection: FC<InternalSectionProps> = ({ supply }) => {
    return (
        <Row gutter={16}>
            <Col span={24}>
                <Item label={SUPPLY_INTERNAL_ONLY_LABELS.resellType}>{supply?.resellType?.name ?? NONE}</Item>
            </Col>
            <Col span={24}>
                <Item label={SUPPLY_INTERNAL_ONLY_LABELS.noAdCookieSync}>
                    {getYesNoLabel(Boolean(supply.enableNoAdCookieSync))}
                </Item>
            </Col>
            <Col span={24}>
                <Item label={BASE_INTERNAL_ONLY_LABELS.loverCalculonUseRate}>
                    {supply.calculonUseRateOverride ?? NONE}
                </Item>
            </Col>
            <Col span={24}>
                <Item label={BASE_INTERNAL_ONLY_LABELS.upperCalculonUseRate}>
                    {supply.upperCalculonUseRateOverride ?? NONE}
                </Item>
            </Col>
            <Col span={24}>
                <Item label={BASE_INTERNAL_ONLY_LABELS.publisherReauction}>
                    {getYesNoLabel(Boolean(supply.publisherReauction))}
                </Item>
            </Col>
        </Row>
    );
};
