import { FC } from "react";
import { SeatIFrame } from "@app/core/components";
import conf from "@app/core/conf";
import { useHose } from "@app/core/services/useHose";
import { ROUTE_FORMATTERS } from "@app/core/routing";

const STREAMING_CREATIVE_REVIEW_IFRAME_PROPS = {
    title: "Creative Review",
    name: "streaming-creative-review",
};
export const StreamingCreativeReviewIFrame: FC = () => {
    const { init, launchApplication } = useHose(STREAMING_CREATIVE_REVIEW_IFRAME_PROPS.name);
    const src = (seatId) =>
        `${conf.appsMagniteRoot}/streaming-creative-review/#${ROUTE_FORMATTERS.SEAT_CREATIVE_REVIEW(seatId)}`;
    const onLoad = () => {
        init();
        launchApplication();
    };

    return <SeatIFrame {...STREAMING_CREATIVE_REVIEW_IFRAME_PROPS} src={src} onLoad={onLoad} />;
};
