import { FileFormat } from "./types";

const FileMimeTypes: Record<FileFormat, string> = {
    csv: "text/csv",
    xlsx: "application/vnd.ms-excel",
} as const;

const downloadFileViaATag = (file: File) => {
    const url = URL.createObjectURL(file);

    const link = document.createElement("a");
    link.href = url;
    link.download = file.name;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
};

// TODO - consider moving this to the download util in @rubicon/utils
export const downloadFile = (name: string, extension: FileFormat, contents: Blob) => {
    const file = new File([contents], `${name}.${extension}`, { type: FileMimeTypes[extension] });
    downloadFileViaATag(file);
};
