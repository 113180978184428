import { PageHeader } from "@ant-design/pro-layout";
import { HelpKeysDrawerContent } from "@app/core/components/HelpKeysDrawer";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import {
    Targeting,
    TargetingCreatePayload,
    TargetingIncludeExclude,
    TargetingUpdatePayload,
    UpdateSeatReusableTargetingParams,
    useGetSeatReusableTargetingByIdQuery,
    useGetSeatReusableTargetingReferencesByIdQuery,
    useUpdateSeatReusableTargetingMutation,
} from "@app/core/services";
import { targetingToTargetingBlocks } from "@app/features/targeting";
import { TargetingFormKeys } from "@app/features/targeting/constants";
import { CreateTargetingBlockContent } from "@app/features/targeting/CreateTargeting/CreateTargetingBlockContent";
import {
    getSelectedTargetingDimensionsCount,
    getSelectedTargetingDimensionsCountExcludes,
} from "@app/features/targeting/ReadOnlyTargeting/ReadOnlyTargetingBlocks";
import { useTargetingForm } from "@app/features/targeting/useTargetingForm";
import { PageLoader } from "@rubicon/antd-components";
import { Button, Layout, notification } from "antd";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { TargetingDeleteModal } from "../TargetingDeleteModal";
import { TargetingDrawer } from "../TargetingDrawer";
import { useTargetingDetailsDefinitionDrawer } from "../TargetingDrawer/useTargetingDefinitionDrawer";
import { TargetingForm } from "../TargetingForm";
import { TargetingFormValues, TARGETING_DIMENSION_MESSAGE } from "../TargetingForm/constants";
import { useRedirectErrorTargeting } from "../useRedirectErrorTargeting";
import { useTargetingDeleteModal } from "../useTargetingDeleteModal";

const getInitialValues = (targeting: Targeting) => ({
    parent: targeting.seat?.name,
    name: targeting.name,
});

export const TargetingEditPage = () => {
    const { seatId, targetingId } = useParams<{ seatId: string; targetingId: string }>();
    const history = useHistory();

    const { data, isFetching, error } = useGetSeatReusableTargetingByIdQuery(Number(targetingId));
    const { data: references, isFetching: isLoadingReferences } = useGetSeatReusableTargetingReferencesByIdQuery(
        Number(data?.id),
        { skip: !data?.id }
    );
    const { showDefinition, definitions, title, handleShowDefinition, handleCloseDefinition } =
        useTargetingDetailsDefinitionDrawer();

    const { handleOpen, ...restModalProps } = useTargetingDeleteModal(() =>
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TARGETING(seatId))
    );
    const [updateTargeting, { isLoading }] = useUpdateSeatReusableTargetingMutation();
    const {
        loadTargeting,
        addTargetingBlockDimension,
        addTargetingBlockDimensionRule,
        addTargetingBlockDimensionRuleGroup,
        copyTargetingBlock,
        copyTargetingBlockDimension,
        deleteTargetingBlock,
        deleteTargetingBlockDimension,
        deleteTargetingBlockDimensionRule,
        downloadTargetingBlockDimension,
        setTargetingBlockDimensionCondition,
        setTargetingBlockDimensionMode,
        setTargetingBlockDimensionOperator,
        setTargetingBlockDimensionPmpCondition,
        setTargetingBlockDimensionRuleCondition,
        setTargetingBlockDimensionRuleGroup,
        setTargetingBlockDimensionRuleId,
        setTargetingBlockDimensionRuleOperator,
        setTargetingBlockDimensionRuleValue,
        setTargetingBlockDimensionTimeZoneMode,
        setTargetingBlockDimensionValues,
        setTargetingBlockIsReusable,
        targetingFormsByFormKey,
        targetingPayloadsByFormKey,
    } = useTargetingForm(TargetingFormKeys.Controls);

    const targetingForm = targetingFormsByFormKey[TargetingFormKeys.Controls];
    const targetingBlocks = targetingToTargetingBlocks(targetingForm.targetingBlocks);
    const usedDimensionsTargetingBlocks = targetingBlocks.map((block) => block.usedDimensions);
    const validationTargetingBlocks = targetingToTargetingBlocks(targetingForm.validationTargetingBlocks);
    const usedDimensionsValidationTargetingBlocks = validationTargetingBlocks.map((block) => block.usedDimensions);

    const handleErrorTargetingNotification = (message: string) => {
        notification.error({ message });
    };

    const handleValidationDimensionTargeting = (targetingBlock: TargetingCreatePayload): boolean => {
        const targetingIncludes = targetingBlock.include;
        const targetingExcludes = targetingBlock.exclude;

        const includesDimensionCount = getSelectedTargetingDimensionsCount(
            targetingIncludes as TargetingIncludeExclude
        );

        const excludesDimensionCount = getSelectedTargetingDimensionsCountExcludes(
            targetingExcludes as TargetingIncludeExclude
        );

        if (!includesDimensionCount && !excludesDimensionCount) {
            return false;
        }

        return true;
    };

    useEffect(() => {
        if (data) {
            //HACK: make sure that static targetingMode is ok
            loadTargeting(TargetingFormKeys.Controls, [{ ...data, name: null, seat: null }], { id: 1, name: "MERGE" });
        }
    }, [data, loadTargeting]);

    const handleSubmit = async (values: TargetingFormValues) => {
        const [updatedTargetingBlock] = targetingPayloadsByFormKey[TargetingFormKeys.Controls];
        if (handleValidationDimensionTargeting(updatedTargetingBlock)) {
            try {
                const body = {
                    id: Number(targetingId),
                    marketplace: data?.marketplace,
                    ...updatedTargetingBlock,
                    name: values.name?.trim(),
                } as TargetingUpdatePayload;
                const payload: UpdateSeatReusableTargetingParams = {
                    seatId: Number(seatId),
                    body,
                };
                const res = await updateTargeting(payload).unwrap();
                notification.success({ message: `Targeting ${res.name} updated successfully` });
                history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TARGETING_DETAILS(seatId, res.id));
            } catch (error) {
                notification.error({ message: `Targeting operation has failed: ${error?.data?.errorDescription}` });
            }
        } else {
            handleErrorTargetingNotification(TARGETING_DIMENSION_MESSAGE);
        }
    };

    useRedirectErrorTargeting({ error, targetingId, seatId });

    if (isFetching || isLoadingReferences) {
        return <PageLoader />;
    }

    const initialValues = getInitialValues(data as Targeting);

    return (
        <Layout data-sdet="targeting-edit-page">
            <PageHeader
                title={`Edit ${data?.name}`}
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TARGETING_DETAILS(seatId, targetingId))}
            />
            <TargetingForm
                initialValues={initialValues}
                references={references}
                deleteButton={
                    !references?.length && (
                        <>
                            <Button
                                danger
                                data-sdet="delete-button"
                                type="primary"
                                onClick={() =>
                                    handleOpen({
                                        id: data?.id as number,
                                        name: data?.name as string,
                                    })
                                }
                            >
                                Delete
                            </Button>
                            <TargetingDeleteModal {...restModalProps} />
                        </>
                    )
                }
                isSubmitting={isLoading}
                handleDefinition={handleShowDefinition}
                onFinish={handleSubmit}
                onCancel={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TARGETING(seatId))}
            >
                {Boolean(targetingBlocks.length) ? (
                    <CreateTargetingBlockContent
                        formKey={TargetingFormKeys.Controls}
                        index={0}
                        onChangeDimensionCondition={setTargetingBlockDimensionCondition}
                        onChangeDimensionMode={setTargetingBlockDimensionMode}
                        onChangeDimensionOperator={setTargetingBlockDimensionOperator}
                        onChangeDimensionPmpCondition={setTargetingBlockDimensionPmpCondition}
                        onChangeDimensionRuleCondition={setTargetingBlockDimensionRuleCondition}
                        onChangeDimensionRuleGroup={setTargetingBlockDimensionRuleGroup}
                        onChangeDimensionRuleId={setTargetingBlockDimensionRuleId}
                        onChangeDimensionRuleOperator={setTargetingBlockDimensionRuleOperator}
                        onChangeDimensionRuleValue={setTargetingBlockDimensionRuleValue}
                        onChangeDimensionTimeZoneMode={setTargetingBlockDimensionTimeZoneMode}
                        onChangeDimensionValues={setTargetingBlockDimensionValues}
                        onChangeIsReusable={setTargetingBlockIsReusable}
                        onClickAddDimension={addTargetingBlockDimension}
                        onClickAddDimensionRule={addTargetingBlockDimensionRule}
                        onClickAddDimensionRuleGroup={addTargetingBlockDimensionRuleGroup}
                        onClickCopy={copyTargetingBlock}
                        onClickDelete={deleteTargetingBlock}
                        onClickDeleteDimension={deleteTargetingBlockDimension}
                        onClickDeleteDimensionRule={deleteTargetingBlockDimensionRule}
                        onClickDownload={downloadTargetingBlockDimension}
                        onClickDuplicateDimension={copyTargetingBlockDimension}
                        targetingBlock={targetingBlocks[0]}
                        usedDimensionsTargetingBlocks={usedDimensionsTargetingBlocks}
                        usedDimensionsValidationTargetingBlocks={usedDimensionsValidationTargetingBlocks}
                    />
                ) : null}
            </TargetingForm>
            <TargetingDrawer open={showDefinition} title={title} closeDrawer={handleCloseDefinition}>
                <HelpKeysDrawerContent definitionsFields={definitions} />
            </TargetingDrawer>
        </Layout>
    );
};
