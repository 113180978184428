import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { Form } from "antd";
import { useContentSelect } from "./useContentSelect";

export const ContentSelect = () => {
    const { contentOptions, isLoading } = useContentSelect();
    return (
        <Form.Item
            name={AD_SOURCE_FIELDS.CONTENT.name}
            label={AD_SOURCE_FIELDS.CONTENT.label}
            labelCol={{ lg: 8 }}
            labelAlign="left"
            colon={false}
            style={{ marginBottom: 8 }}
        >
            <SelectWithValue
                loading={isLoading}
                options={contentOptions}
                fieldAsLabel={["name"]}
                fieldAsValue={["name"]}
            />
        </Form.Item>
    );
};
