import { FC } from "react";
import { DealDetailsDrawerTitleInfo } from "@app/features/deals/DealDetailsDrawer/DealDetailsDrawerContent";
import { DealDetailsDrawerDirectTitle, DealDetailsDrawerNavTitle } from "./titles";
import { DealDetailsDrawerNestedTitle } from "./titles/DealDetailsDrawerNestedTitle";
import { useDealsDetailsDrawerTitle } from "../useDealDetailsDrawerTitle";

export enum DealDetailsDrawerHeader {
    Nested = "NestedHeader",
    Direct = "DirectHeader",
}

interface Props {
    titleInfo: DealDetailsDrawerTitleInfo;
}

export const DealDetailsDrawerHeaderTitleSwitch: FC<Props> = ({ titleInfo }) => {
    const { title } = useDealsDetailsDrawerTitle();
    switch (title) {
        case DealDetailsDrawerHeader.Nested:
            return (
                <DealDetailsDrawerNestedTitle
                    dealName={titleInfo.dealName}
                    secondaryDetailsView={titleInfo.secondaryDetailsView}
                />
            );
        case DealDetailsDrawerHeader.Direct:
            return (
                <DealDetailsDrawerDirectTitle
                    dealName={titleInfo.dealName}
                    secondaryDetailsView={titleInfo.secondaryDetailsView}
                />
            );
        default:
            return <DealDetailsDrawerNavTitle titleInfo={titleInfo} />;
    }
};
