import { FC, ReactNode } from "react";
import { Space } from "antd";
import { NavBar as MagniteNavBar } from "@rubicon/antd-components";
import { SearchBar } from "@app/features/search";
import { css } from "@emotion/css";
import { TimeZoneField } from "@app/core/components/TimeZoneField";

interface NavBarProps {
    label: ReactNode;
    extra?: ReactNode;
}

const NavBar: FC<NavBarProps> = ({ label, extra }) => {
    return (
        <MagniteNavBar
            className={css`
                padding-left: 0px !important;
                padding-right: 12px !important;
            `}
            icon={null}
            label={
                <span style={{ marginBottom: "1px" }}>
                    STREAMING <span style={{ marginLeft: "16px" }}>{label}</span>
                </span>
            }
            extra={
                <Space>
                    {extra}
                    <TimeZoneField />
                    <SearchBar style={{ width: "300px" }} />
                </Space>
            }
        />
    );
};

export default NavBar;
