import { FC } from "react";
import { ResizableDrawer } from "@rubicon/antd-components";
import { css } from "@emotion/css";
import { UseSeatAdSourcesDealCountDrawer } from "./useSeatAdSourcesDealCountDrawer";
import { SeatAdSourcesDealCountDrawerContents } from "./SeatAdSourcesDealCountDrawerContents/SeatAdSourcesDealCountDrawerContents";

interface Props {
    useSeatAdSourcesDealCountDrawer: UseSeatAdSourcesDealCountDrawer;
}

export const SeatAdSourcesDealCountDrawer: FC<Props> = ({ useSeatAdSourcesDealCountDrawer }) => {
    const { requestedAdSourceId, adSourceName, dealCount, isDealCountDrawerOpen, closeDealCountDrawer } =
        useSeatAdSourcesDealCountDrawer;

    return (
        <div data-sdet="seat-ad-sources-deal-count-drawer">
            <ResizableDrawer
                open={isDealCountDrawerOpen}
                placement="right"
                width={1024}
                closable={false}
                onClose={closeDealCountDrawer}
                className={css`
                    .ant-drawer-body {
                        padding: 0;
                    }
                `}
            >
                {isDealCountDrawerOpen && (
                    <SeatAdSourcesDealCountDrawerContents
                        adSourceId={requestedAdSourceId}
                        adSourceName={adSourceName}
                        dealCount={dealCount}
                        closeDealCountDrawer={closeDealCountDrawer}
                    />
                )}
            </ResizableDrawer>
        </div>
    );
};
