import { FC } from "react";
import { Alert, Typography } from "antd";
import { getEligibleSyncedFieldLabelsAsList, isFixedPrice } from "@app/features/syncedFields";
import { useSyncRelatedFormData } from "./useSyncRelatedFormData";

const getMultiDealWarningText = (syncedFieldsList: string, isFixedPriceModel: boolean): string =>
    `This Ad Source contains multiple Deals. Changes to ${syncedFieldsList} will not sync with any attached Deals. The Ad Source take the furthest End Date${
        isFixedPriceModel ? " and lowest CPM" : ""
    } out of all Deals or Ad Source.`;

const getMultiAdSourceWarningText = (syncedFieldsList: string): string =>
    `The Deal that's attached to this Ad Source is used in multiple Ad Sources. Changes to ${syncedFieldsList} will not sync with the Deal.`;

const getWarningText = (
    adSourceTypeId: number | undefined,
    adSourceFloorTypeId: number | undefined,
    isMultiDealAdSource: boolean,
    isSingleAttachedDealUsedInOtherAdSources: boolean
): string | null => {
    const syncedFieldsList = getEligibleSyncedFieldLabelsAsList(adSourceTypeId, adSourceFloorTypeId);
    if (!syncedFieldsList) {
        return null;
    }
    if (isMultiDealAdSource) {
        return getMultiDealWarningText(syncedFieldsList, isFixedPrice(adSourceTypeId));
    }
    if (isSingleAttachedDealUsedInOtherAdSources) {
        return getMultiAdSourceWarningText(syncedFieldsList);
    }
    return null;
};

export const AdSourceSyncWarningAlert: FC = () => {
    const { adSourceFields, isMultiDealAdSource, singleAttachedDealSyncData } = useSyncRelatedFormData();
    const adSourceTypeId = adSourceFields.type?.id;
    const adSourceFloorTypeId = adSourceFields.floorType?.id;

    const warningText = getWarningText(
        adSourceTypeId,
        adSourceFloorTypeId,
        isMultiDealAdSource,
        singleAttachedDealSyncData.isUsedInOtherAdSources
    );

    if (!warningText) {
        return null;
    }

    return (
        <Alert
            data-sdet="ad-source-sync-warning-alert"
            type="warning"
            showIcon
            style={{ alignItems: "baseline" }}
            message={<Typography.Text>{warningText}</Typography.Text>}
        />
    );
};
