import { FC } from "react";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { RuleObject, StoreValue } from "rc-field-form/lib/interface";
import { useAdSourceMaxDurationField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceDeliveryDetailsSection/Fields/AdSourceMaxDurationField/useAdSourceMaxDurationField";
import { Form, InputNumber } from "antd";
import { numberNormalize } from "@app/core/components/helpers";

export const AdSourceMaxDurationField: FC = () => {
    const { validator } = useAdSourceMaxDurationField();
    return (
        <Form.Item
            label="Max Duration"
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MAX_DURATION}
            dependencies={[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MIN_DURATION]}
            normalize={numberNormalize}
            data-sdet="deals-ad-source-max-duration-field"
            rules={[
                ({ getFieldValue }) => ({
                    validator: (_: RuleObject, value: StoreValue) => {
                        const minDuration = getFieldValue(CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MIN_DURATION);
                        return validator(minDuration, value);
                    },
                }),
            ]}
        >
            <InputNumber
                data-sdet="deals-ad-source-max-duration-field-input"
                addonAfter="Seconds"
                style={{ width: "100%" }}
            />
        </Form.Item>
    );
};
