import { FC } from "react";
import { Card, Row, Col } from "antd";
import {
    UnwrapVast,
    InternalOnly,
    PartnerDomain,
    AllowVASTWrappers,
    ExtendedImpWaitTime,
    LSAWindowSizeSeconds,
    AdvertisersAsAllowList,
    LiveStreamAcceleration,
    AdvertiserFrequencyCapping,
    AdvertiserDomainFrequencyCaps,
} from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormSections/AdvancedFeaturesSection/Sections/DetailedConfigurationSection/Fields";
import { useDetailedConfigurationSection } from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormSections/AdvancedFeaturesSection/Sections/DetailedConfigurationSection/useDetailedConfigurationSection";

const GUTTER = 32;
const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 22 };
const GROUP_COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 12 };
const INPUTS_COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 };
export const DetailedConfigurationSection: FC = () => {
    const {
        isUnwrapVASTShown,
        isInternalOnlyShown,
        isLSAWindowSizeSecondsShown,
        isLiveStreamAccelerationShown,
        isAdvertisersAsAllowListShown,
        isAdvertiserDomainFrequencyCapsShown,
    } = useDetailedConfigurationSection();
    return (
        <Card bordered={false}>
            <Row>
                <Col {...GROUP_COL_SIZES}>
                    <Row>
                        <Col {...COL_SIZES}>
                            <AllowVASTWrappers />
                            {isLiveStreamAccelerationShown && <LiveStreamAcceleration />}
                            <Row gutter={GUTTER} hidden={!isLSAWindowSizeSecondsShown}>
                                <Col {...INPUTS_COL_SIZES}>
                                    <LSAWindowSizeSeconds />
                                </Col>
                            </Row>
                            {isUnwrapVASTShown && <UnwrapVast />}
                            <Row gutter={GUTTER}>
                                <Col {...INPUTS_COL_SIZES}>
                                    <ExtendedImpWaitTime />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER} hidden={!isAdvertisersAsAllowListShown}>
                                <Col {...INPUTS_COL_SIZES}>
                                    <AdvertisersAsAllowList />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col {...INPUTS_COL_SIZES}>
                                    <AdvertiserFrequencyCapping />
                                </Col>
                            </Row>
                            {isAdvertiserDomainFrequencyCapsShown && <AdvertiserDomainFrequencyCaps />}
                            <PartnerDomain />
                        </Col>
                    </Row>
                </Col>
                {isInternalOnlyShown && (
                    <Col {...GROUP_COL_SIZES}>
                        <InternalOnly />
                    </Col>
                )}
            </Row>
        </Card>
    );
};
