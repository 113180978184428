import { FC } from "react";
import { Form } from "antd";
import { css } from "@emotion/css";
import { AdSourceFloorType, Audience, SeatSegment, useGetCurrenciesConversionsByCodeQuery } from "@app/core/services";
import { ResizableDrawer } from "@rubicon/antd-components";
import { AdSourcesForm } from "../../..";
import {
    calcFinalEc,
    calcFinalCpmWithEcAndDac,
    getAudienceEnrichmentCost,
    getIsFixedCost,
    getIsRangedCost,
    formatCpm,
    formatDac,
    formatEcCurrency,
    formatFinalCpmWithEcAndDac,
} from "../utils";
import { EcDetailsSection } from "./EcDetailsSection";
import { FinalizedCpmSection } from "./FinalizedCpmSection";
import { SectionTitle } from "./SectionTitle";
import { getCurrencyConversionRate } from "@app/features/syncedFields";
import {
    DEFAULT_ENRICHMENT_COST_CURRENCY_CODE,
    DemandAcquisitionCostModelId,
} from "@app/features/seatAdSources/constants";

interface Props {
    adSourceCpm: AdSourcesForm["bookingPrice"];
    adSourceCurrencyCode: string;
    adSourceDemandAcquisitionCost: number | null;
    adSourceDemandAcquisitionCostModelId: number | null;
    adSourceFloorType: Partial<AdSourceFloorType> | undefined;
    adSourceLevelAudiences: Audience[];
    adSourceLevelSegments: SeatSegment[];
    adSourceName: AdSourcesForm["name"];
    adSourceType: AdSourcesForm["type"];
    dealCurrencyCode: string;
    dealLevelAudiences: Audience[];
    dealLevelSegments: SeatSegment[];
    dealName: string;
    dealRate: number;
    isDemandAcquisitionCostEnabled: boolean;
    onClose: () => void;
    open: boolean;
    shouldUseAdSourceValues: boolean;
}

export const EnrichmentCostDetailsDrawer: FC<Props> = ({
    adSourceCpm,
    adSourceCurrencyCode,
    adSourceDemandAcquisitionCost,
    adSourceDemandAcquisitionCostModelId,
    adSourceFloorType,
    adSourceLevelAudiences,
    adSourceLevelSegments,
    adSourceName,
    adSourceType,
    dealCurrencyCode,
    dealLevelAudiences,
    dealLevelSegments,
    dealName,
    dealRate,
    isDemandAcquisitionCostEnabled,
    onClose,
    open,
    shouldUseAdSourceValues,
}) => {
    const { data: conversions } = useGetCurrenciesConversionsByCodeQuery(DEFAULT_ENRICHMENT_COST_CURRENCY_CODE);
    const currencyCode = shouldUseAdSourceValues ? adSourceCurrencyCode : dealCurrencyCode;
    const adSourceCpmNum = Number(adSourceCpm || 0);
    const cpm = shouldUseAdSourceValues ? adSourceCpmNum : dealRate;
    const conversionRate = getCurrencyConversionRate(DEFAULT_ENRICHMENT_COST_CURRENCY_CODE, currencyCode, conversions);

    const adSourceDemandAcquisitionCostConverted =
        adSourceDemandAcquisitionCost === null
            ? 0
            : adSourceDemandAcquisitionCostModelId === DemandAcquisitionCostModelId.PERCENTAGE
            ? adSourceDemandAcquisitionCost
            : adSourceDemandAcquisitionCost * conversionRate;

    const isRangedCost = getIsRangedCost(adSourceType);
    const isFixedCost = getIsFixedCost(adSourceType);
    const adSourceEc = getAudienceEnrichmentCost(
        adSourceLevelAudiences,
        adSourceLevelSegments,
        isRangedCost,
        conversionRate
    );
    const dealEc = getAudienceEnrichmentCost(dealLevelAudiences, dealLevelSegments, isRangedCost, conversionRate);
    const finalEc = calcFinalEc(adSourceEc, dealEc);
    const floorCpmDisplay = formatCpm(cpm, currencyCode, adSourceCpm, adSourceType.id, adSourceFloorType?.id);
    const finalCpmWithEcAndDac = calcFinalCpmWithEcAndDac(
        cpm,
        finalEc,
        adSourceDemandAcquisitionCost,
        adSourceDemandAcquisitionCostModelId
    );
    const finalizedCpmDisplay = formatFinalCpmWithEcAndDac(
        finalCpmWithEcAndDac,
        currencyCode,
        adSourceType.id,
        adSourceFloorType?.id
    );

    return (
        <ResizableDrawer
            open={open}
            onClose={onClose}
            title={`Enrichment Cost: ${dealName}`}
            placement="right"
            width={1024}
            className={css`
                .ant-drawer-body {
                    padding: 0;
                }
            `}
        >
            <Form layout="vertical">
                <FinalizedCpmSection
                    adSourceName={adSourceName}
                    cpm={floorCpmDisplay}
                    currencyCode={currencyCode}
                    dealName={dealName}
                    demandAcquisitionCost={formatDac(
                        adSourceDemandAcquisitionCostConverted,
                        adSourceDemandAcquisitionCostModelId,
                        currencyCode
                    )}
                    demandAcquisitionCostVal={adSourceDemandAcquisitionCostConverted}
                    demandAcquisitionCostModelId={adSourceDemandAcquisitionCostModelId}
                    finalEc={formatEcCurrency(finalEc, currencyCode)}
                    finalEcVal={finalEc}
                    finalizedCpm={finalizedCpmDisplay}
                    isDemandAcquisitionCostEnabled={isDemandAcquisitionCostEnabled}
                    isFixedCost={isFixedCost}
                    isRangedCost={isRangedCost}
                />
                <SectionTitle title="Ad Source EC Details" />
                <EcDetailsSection
                    mode="adSource"
                    audiences={adSourceLevelAudiences}
                    segments={adSourceLevelSegments}
                    ecValue={adSourceEc}
                    adSourceCurrencyCode={dealCurrencyCode}
                    conversionRate={conversionRate}
                />
                <SectionTitle title="Deal EC Details" />
                <EcDetailsSection
                    mode="deal"
                    audiences={dealLevelAudiences}
                    segments={dealLevelSegments}
                    ecValue={dealEc}
                    dealName={dealName}
                    adSourceCurrencyCode={dealCurrencyCode}
                    conversionRate={conversionRate}
                />
            </Form>
        </ResizableDrawer>
    );
};
