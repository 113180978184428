import { Loading } from "@app/core/components";
import { css } from "@emotion/css";
import { Col, Flex, Tabs, TabsProps, Typography } from "antd";
import { DetailsDrawerLayout } from "../../DetailsDrawerLayout";
import { UniquesStats } from "./UniquesStats";
import { CalculonStatas } from "./CalulonStats";
import { DIAGNOSTICS, DiagnosticsTabsType, useDiagnosticsContent } from "./useDiagnosticsContent";
import { VastErrors } from "./VastErrors";
import { VastStats } from "./VastStats";
import { TimingStats } from "./TimingStats";
import { useInventoryDetailsDrawerUrlId } from "../../useInventoryDetailsDrawerUrlId";

const { Text } = Typography;

const getTabs = (id: number, isAdUnit = false) => {
    const tabs = [
        {
            label: <Text strong>Vast Errors</Text>,
            key: DiagnosticsTabsType.VastErrors,
            children: <VastErrors />,
        },
        {
            label: <Text strong>Vast Stats</Text>,
            key: DiagnosticsTabsType.VastStats,
            children: <VastStats id={id} />,
        },
        // TODO make sure that condition is only adUnit level
        isAdUnit && {
            label: <Text strong>Calculon Stats</Text>,
            key: DiagnosticsTabsType.CalculonStats,
            children: <CalculonStatas id={id} />,
        },
        {
            label: <Text strong>Uniques Count</Text>,
            key: DiagnosticsTabsType.UniquesCount,
            children: <UniquesStats id={id} />,
        },
        // TODO should be shown only for adUnit and specific user, figure out what kind of user should have it
        isAdUnit && {
            label: <Text strong>Timing Stats</Text>,
            key: DiagnosticsTabsType.TimingStats,
            children: <TimingStats id={id} />,
        },
    ].filter(Boolean) as TabsProps["items"];

    return tabs;
};

export const DiagnosticsContent = () => {
    const unitId = useInventoryDetailsDrawerUrlId();
    const { activeTab, title, breadcrumbs, handleDefinition, handleChangeTab, isLoading, isAdUnit } =
        useDiagnosticsContent(unitId);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Flex vertical>
            <DetailsDrawerLayout
                title={title}
                breadcrumbs={breadcrumbs}
                definitionTitle={DIAGNOSTICS}
                handleDefintion={handleDefinition}
            />
            <Col
                className={css`
                    padding: 1.5rem;
                    width: 100%;
                `}
            >
                <Tabs
                    activeKey={activeTab}
                    items={getTabs(unitId, isAdUnit)}
                    destroyInactiveTabPane={true}
                    onChange={handleChangeTab}
                />
            </Col>
        </Flex>
    );
};
