import { useState } from "react";
import { SegmentRuleGroup } from "@app/features/targeting/types";
import { isSegmentRuleTargetingValid } from "../helpers";

export const useAdvancedRuleDrawer = (
    onChangeRuleGroup: (segmentRuleGroup: SegmentRuleGroup | null) => void,
    segmentRuleGroup: SegmentRuleGroup
) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isValidationShown, setIsValidationShown] = useState(false);
    const [originalRuleGroup, setOriginalRuleGroup] = useState(null);

    const handleCancel = () => {
        onChangeRuleGroup(originalRuleGroup);
        setOriginalRuleGroup(null);
        setIsOpen(false);
    };

    const handleOpen = (ruleGroup: SegmentRuleGroup) => {
        setOriginalRuleGroup(JSON.parse(JSON.stringify(ruleGroup)));
        setIsOpen(true);
        setIsValidationShown(false);
    };

    const handleApply = () => {
        if (isSegmentRuleTargetingValid(segmentRuleGroup)) {
            setIsOpen(false);
            setIsValidationShown(false);
        } else {
            setIsValidationShown(true);
        }
    };

    return {
        handleApply,
        handleCancel,
        handleOpen,
        isOpen,
        isValidationShown,
    };
};
