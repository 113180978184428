import { useMemo } from "react";
import { useUserAccess } from "@app/core/auth";
import { AdSourceTypeIds, DefaultPacingTypeOptions, NonePacingTypeOption } from "@app/features/seatAdSources/constants";
import { PacingTypeOption } from "./types";

interface UsePacingTypeOptions {
    options: PacingTypeOption[];
}

export const usePacingTypeOptions = (adSourceTypeId: number | undefined): UsePacingTypeOptions => {
    const {
        seatAccessFlags: { hasPacingTypeNoneAccess },
    } = useUserAccess();

    const options = useMemo(
        () =>
            hasPacingTypeNoneAccess && adSourceTypeId === AdSourceTypeIds.AUTOMATED_GUARANTEED
                ? [NonePacingTypeOption, ...DefaultPacingTypeOptions]
                : [...DefaultPacingTypeOptions],
        [hasPacingTypeNoneAccess, adSourceTypeId]
    );

    return {
        options,
    };
};
