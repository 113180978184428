import { FC } from "react";
import { Tabs } from "antd";
import {
    SeatAdSourcesAdServingTab as AdServingTab,
    useSeatAdSourcesDetailsAdServing,
} from "./useSeatAdSourcesDetailsAdServing";
import {
    SeatAdSources24HourAdCandidateSelectionChart,
    SeatAdSourcesAdCandidateSelectionChart,
    SeatAdSourcesLiveOpportunityChart,
} from "@app/features/seatAdSources/seatAdSourcesCharts";

const AdServingTabs = {
    [AdServingTab.AdCandidateSelection]: {
        key: AdServingTab.AdCandidateSelection,
        tab: "Ad Candidate Selection",
    },
    [AdServingTab.AdCandidateSelection24Hours]: {
        key: AdServingTab.AdCandidateSelection24Hours,
        tab: "Ad Candidate Selection 24 Hours",
    },
    [AdServingTab.LiveOpportunityGraph]: {
        key: AdServingTab.LiveOpportunityGraph,
        tab: "Live Opportunity Graph",
    },
};

interface Props {
    adSourceId: number;
}

export const SeatAdSourcesDetailsAdServing: FC<Props> = ({ adSourceId }) => {
    const { activeTab, onSwitchTab } = useSeatAdSourcesDetailsAdServing();

    const renderActivePerformanceTab = () => {
        switch (activeTab) {
            case AdServingTab.AdCandidateSelection:
                return <SeatAdSourcesAdCandidateSelectionChart adSourceId={adSourceId} />;
            case AdServingTab.AdCandidateSelection24Hours:
                return <SeatAdSources24HourAdCandidateSelectionChart adSourceId={adSourceId} />;
            default:
                return <SeatAdSourcesLiveOpportunityChart adSourceId={adSourceId} />;
        }
    };

    return (
        <div data-sdet="seat-ad-sources-details-performance" className="drawer-view-tabs">
            <Tabs activeKey={activeTab} defaultActiveKey={activeTab} onChange={onSwitchTab}>
                <Tabs.TabPane {...AdServingTabs[AdServingTab.AdCandidateSelection]} />
                <Tabs.TabPane {...AdServingTabs[AdServingTab.AdCandidateSelection24Hours]} />
                <Tabs.TabPane {...AdServingTabs[AdServingTab.LiveOpportunityGraph]} />
            </Tabs>
            {renderActivePerformanceTab()}
        </div>
    );
};
