import { Fragment, MouseEvent, ReactNode } from "react";
import { gray1, brandAmethyst, brandAmethystDark, brandText1, brandRuby } from "@rubicon/antd-components";
import { css } from "@emotion/css";
import { Badge, Typography } from "antd";
import { SIDE_NAV_WIDTH } from "../constants";

export interface NavGroupItemProps {
    route?: string;
    icon: ReactNode;
    indicator?: number | string | null;
    backgroundColor?: string;
    backgroundHighlightColor?: string;
    isActive?: boolean;
    label: string;
    labelFontSize?: number;
    onClick?: () => void;
}

const DEFAULT_LABEL_FONT_SIZE_REM = 0.75;

export const NavGroupItem = (props: NavGroupItemProps) => {
    const {
        isActive,
        route,
        onClick,
        indicator = null,
        icon,
        label,
        labelFontSize = DEFAULT_LABEL_FONT_SIZE_REM,
        backgroundColor = brandAmethyst,
        backgroundHighlightColor = brandAmethystDark,
    } = props;

    const classList = css`
        position: relative;
        z-index: 1000;
        width: ${SIDE_NAV_WIDTH};
        border: 0;
        padding: 0.25rem;
        background-color: ${isActive ? gray1 : backgroundColor};
        text-align: center;
        cursor: pointer;

        .ant-badge-multiple-words {
            right: -0.75rem;
            padding: 0 0.25rem 0 0.125rem;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            background-color: ${isActive ? gray1 : backgroundHighlightColor};
        }

        .anticon {
            margin-bottom: 0.125rem;
            padding: 0.25rem 0;
            color: ${isActive ? brandText1 : gray1};
        }

        .label {
            display: block;
            font-size: ${labelFontSize}rem;
            line-height: 1.3333333333;
            color: ${isActive ? brandText1 : gray1};
        }
    `;

    const badgeClassList = css`
        .ant-badge-count {
            background: ${brandRuby};
            box-shadow: 0 0 0 1px ${brandRuby};
            margin-top: 0.5rem;
            right: -0.5rem;
        }
    `;

    const Content = () => (
        <>
            {Boolean(indicator) ? (
                <Badge className={badgeClassList} count={indicator} size="small" data-sdet={`${label} (${indicator})`}>
                    {icon}
                </Badge>
            ) : (
                <Fragment>{icon}</Fragment>
            )}
            <Typography.Text className="label">{label}</Typography.Text>
        </>
    );

    const handleLinkClick = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        onClick?.();
    };

    if (route) {
        return (
            <a href={`/#${route}`} className={classList} onClick={handleLinkClick} data-sdet={label}>
                {Content()}
            </a>
        );
    }

    return (
        <button className={classList} onClick={onClick} data-sdet={label}>
            {Content()}
        </button>
    );
};
