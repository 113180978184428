import { FC } from "react";
import { IabCategorieTree } from "@app/features/inventory/components/FormItems";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const BlockedCategories: FC = () => {
    return (
        <IabCategorieTree
            name={CHANNEL_FORM_FIELDS.BLOCKED_CATEGORIES.name}
            label={CHANNEL_FORM_FIELDS.BLOCKED_CATEGORIES.label}
            placeholder={CHANNEL_FORM_FIELDS.BLOCKED_CATEGORIES.placeholder}
        />
    );
};
