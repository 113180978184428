import { uri } from "@rubicon/utils";
import { Form, Select } from "antd";
import { useHistory, useLocation } from "react-router-dom";

export const AUDIT_LOG_ACTION_TYPE_SELECT_SDET = "audit-log-action-type-select";
export const ACTION_TYPE_QUERY_PARAM = "actionType";
export const getActionTypeQueryParam = (queryString: string) =>
    new URLSearchParams(queryString).get(ACTION_TYPE_QUERY_PARAM) || undefined;

const ACTION_TYPE_SELECT_OPTIONS = [
    { label: "Deleted", value: "deleted" },
    { label: "Created", value: "created" },
    { label: "Changed", value: "changed" },
];

export const AuditLogActionTypeSelect = () => {
    const { search: queryString } = useLocation();
    const actionType = getActionTypeQueryParam(queryString);

    const history = useHistory();

    const onChange = (value: string | undefined) => {
        const nextQueryString = value
            ? uri.setSearchParam(queryString, ACTION_TYPE_QUERY_PARAM, value)
            : uri.deleteSearchParam(queryString, ACTION_TYPE_QUERY_PARAM);

        history.push({ search: nextQueryString });
    };
    return (
        <Form.Item label="Action Type">
            <Select
                showSearch
                allowClear
                data-sdet={AUDIT_LOG_ACTION_TYPE_SELECT_SDET}
                value={actionType}
                onChange={onChange}
                options={ACTION_TYPE_SELECT_OPTIONS}
            />
        </Form.Item>
    );
};
