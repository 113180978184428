import { useAppDispatch, useAppSelector } from "@app/core/store";
import { LabeledValue } from "antd/lib/select";
import { setFiltersSeatAdSourcesDeal, setFiltersSeatAdSourcesDealSearch } from "../../reducer";
import { useSearchQuery, SearchParams } from "@app/core/services/console/search";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";

interface UseSeatAdSourcesPacingDealFilter {
    handleChangeValue: (value: string | number | undefined, option: LabeledValue | LabeledValue[]) => void;
    options: LabeledValue[];
    value: LabeledValue[] | string | null;
    isLoading: boolean;
    areAllOptionsSelected: boolean;
    toggleSelectDeselectAll: () => void;
    handleChangeSearch: (value: string) => void;
}

export const useSeatAdSourcesPacingDealFilter = (): UseSeatAdSourcesPacingDealFilter => {
    const dispatch = useAppDispatch();
    const { seatId } = useParams<{ seatId: string }>();
    const seatAdSourcesPacingDeal = useAppSelector((state) => state.seatAdSourcesPacing.filters.seatAdSourcesDeal);
    const seatAdSourcesPacingDealSearch = useAppSelector(
        (state) => state.seatAdSourcesPacing.filters.seatAdSourcesDealSearch
    );
    const debouncedSetSearchKeyword = useMemo(
        () =>
            debounce((value: string) => dispatch(setFiltersSeatAdSourcesDealSearch(value)), FILTER_INPUT_DEBOUNCE_TIME),
        [dispatch]
    );
    const searchQueryParams: SearchParams = {
        seatId: seatId,
        max: 100,
        page: 1,
        type: "deal",
        keyword: seatAdSourcesPacingDealSearch,
    };
    const { data, isFetching } = useSearchQuery(searchQueryParams);
    const options = useMemo(() => (data || []).map((filter) => ({ label: filter.name, value: filter.id })), [data]);

    const handleChangeValue = (_, option: LabeledValue[]) => {
        dispatch(setFiltersSeatAdSourcesDeal(option));
    };

    const areAllOptionsSelected = options.length === seatAdSourcesPacingDeal?.length;

    const toggleSelectDeselectAll = () => {
        if (areAllOptionsSelected) {
            handleChangeValue(undefined, []);
        } else {
            handleChangeValue(undefined, options);
        }
    };

    const handleChangeSearch = (value: string) => {
        debouncedSetSearchKeyword(value);
    };

    return {
        handleChangeValue,
        options,
        value: seatAdSourcesPacingDeal,
        isLoading: isFetching,
        areAllOptionsSelected,
        toggleSelectDeselectAll,
        handleChangeSearch,
    };
};
