import { LabeledValue } from "antd/lib/select";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { useGetSeatExternalUsersQuery } from "@app/core/services";
import { useParams } from "react-router-dom";

interface UseAdSourceAssigneeField {
    isFetching: boolean;
    onChange: (value: number) => void;
    options: LabeledValue[];
    value: number | null;
}

export const useAdSourceAssigneeField = (): UseAdSourceAssigneeField => {
    const dispatch = useAppDispatch();

    const { seatId } = useParams<{ seatId: string }>();
    const { data, isFetching } = useGetSeatExternalUsersQuery(Number(seatId));

    const options = useMemo(
        () => (data || []).map((item) => ({ label: `${item.name} [${item.emailAddress}]`, value: item.id })),
        [data]
    );

    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ASSIGNEE]);

    const onChange = (value: number): void => {
        dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ASSIGNEE, value }));
    };

    return {
        isFetching,
        onChange,
        options,
        value,
    };
};
