import { AdSourceTypes } from "@app/features/seatAdSources/constants";

export const isTransparencyVisible = (adSourceTypeName: string) => {
    const isVisible =
        adSourceTypeName === AdSourceTypes.PROGRAMATIC_GUARANTEED ||
        adSourceTypeName === AdSourceTypes.CONDITIONAL_PROGRAMATIC_GUARANTEED ||
        adSourceTypeName === AdSourceTypes.FIXED_PRICE ||
        adSourceTypeName === AdSourceTypes.AUCTION_PRICE ||
        adSourceTypeName === AdSourceTypes.OPEN_AUCTION ||
        adSourceTypeName === AdSourceTypes.MARKETPLACE ||
        adSourceTypeName === AdSourceTypes.LINEAR_FIXED_PRICE ||
        adSourceTypeName === AdSourceTypes.LINEAR_AUCTION_PRICE;
    return isVisible;
};
