import { FC, useMemo } from "react";
import { Button, Dropdown, Tooltip } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useUserAccess } from "@app/core/auth";
import { useSeatAdSourcesDetailsDrawer } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/useSeatAdSourcesDetailsDrawer";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/types";
import { SeatAdSourcesMoreMenuItem as MenuItem } from "./types";
import { isAdSourceAdServingEligible, isAdSourceCopyEligible } from "@app/features/seatAdSources/utils";
import { useSeatAdSourcesPageNavigator } from "@app/features/seatAdSources/utils/index";

interface Props {
    menuItems: MenuItem[];
    adSourceId: number;
    adSourceTypeId: number;
}

export const SeatAdSourcesMoreActionButton: FC<Props> = ({ menuItems, adSourceId, adSourceTypeId }) => {
    const { openSeatAdSourcesDetailsDrawer } = useSeatAdSourcesDetailsDrawer();
    const { goToSeatAdSourcesCopyPage } = useSeatAdSourcesPageNavigator();
    const { canEditSeat, isTremorUser } = useUserAccess();

    const filteredMenuItems = useMemo(() => {
        const AllMenuItems = {
            [MenuItem.Copy]: {
                label: "Copy",
                key: MenuItem.Copy,
                onClick: () => goToSeatAdSourcesCopyPage(adSourceId),
            },
            [MenuItem.CreativeReview]: {
                label: "Creative Review",
                key: MenuItem.CreativeReview,
                onClick: () => openSeatAdSourcesDetailsDrawer(adSourceId, SecondaryDetailsView.CreativeReview),
            },
            [MenuItem.AdServing]: {
                label: "Ad Serving",
                key: MenuItem.AdServing,
                onClick: () => openSeatAdSourcesDetailsDrawer(adSourceId, SecondaryDetailsView.AdServing),
            },
            [MenuItem.VastErrors]: {
                label: "VAST Errors",
                key: MenuItem.VastErrors,
                onClick: () => openSeatAdSourcesDetailsDrawer(adSourceId, SecondaryDetailsView.VastErrors),
            },
            [MenuItem.Ladle]: {
                label: "Ladle",
                key: MenuItem.Ladle,
                onClick: () => openSeatAdSourcesDetailsDrawer(adSourceId, SecondaryDetailsView.Ladle),
            },
            [MenuItem.TimingStats]: {
                label: "Timing Stats",
                key: MenuItem.TimingStats,
                onClick: () => openSeatAdSourcesDetailsDrawer(adSourceId, SecondaryDetailsView.TimingStats),
            },
            [MenuItem.ChangeHistory]: {
                label: "Change History",
                key: MenuItem.ChangeHistory,
                onClick: () => openSeatAdSourcesDetailsDrawer(adSourceId, SecondaryDetailsView.ChangeHistory),
            },
        } as const;

        return menuItems
            .map((menuItem) => AllMenuItems[menuItem])
            .filter((menuItem) => {
                switch (menuItem.key) {
                    case MenuItem.Copy:
                        return isAdSourceCopyEligible(canEditSeat);
                    case MenuItem.AdServing:
                        return isAdSourceAdServingEligible(adSourceTypeId);
                    case MenuItem.TimingStats:
                        return isTremorUser;
                }
                return true;
            });
    }, [
        menuItems,
        goToSeatAdSourcesCopyPage,
        adSourceId,
        openSeatAdSourcesDetailsDrawer,
        canEditSeat,
        adSourceTypeId,
        isTremorUser,
    ]);

    return (
        <Tooltip title="More">
            <Dropdown
                trigger={["click"]}
                data-sdet="seat-ad-sources-more-menu-items"
                menu={{
                    items: filteredMenuItems,
                }}
            >
                <Button data-sdet="seat-ad-sources-more-action-button" icon={<MoreOutlined />} />
            </Dropdown>
        </Tooltip>
    );
};
