import { FC } from "react";
import { Tag } from "antd";
import { SynthesizedAdSourceStatus } from "@app/core/services/console/adSources";

const StatusColorMap = {
    Expired: "error",
    Future: "processing",
    Running: "success",
} as const;

interface Props {
    synthesizedStatus: SynthesizedAdSourceStatus;
}

export const SeatAdSourceStatusTag: FC<Props> = ({ synthesizedStatus }) => (
    <Tag color={StatusColorMap[synthesizedStatus] ?? "default"}>{synthesizedStatus}</Tag>
);
