import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { LabeledValue } from "antd/lib/select";
import {
    addExistingTargetingBlock,
    addTargetingBlock,
    addTargetingBlockDimension,
    addTargetingBlockDimensionRule,
    addTargetingBlockDimensionRuleGroup,
    copyTargetingBlock,
    copyTargetingBlockDimension,
    deleteTargetingBlock,
    deleteTargetingBlockDimension,
    deleteTargetingBlockDimensionRule,
    loadTargeting,
    loadValidationTargeting,
    replaceTargetingBlock,
    resetTargetingBlocksByFormKey,
    setTargetingBlockDimensionCondition,
    setTargetingBlockDimensionMode,
    setTargetingBlockDimensionOperator,
    setTargetingBlockDimensionPmpCondition,
    setTargetingBlockDimensionRuleCondition,
    setTargetingBlockDimensionRuleGroup,
    setTargetingBlockDimensionRuleId,
    setTargetingBlockDimensionRuleOperator,
    setTargetingBlockDimensionRuleValue,
    setTargetingBlockDimensionTimeZoneMode,
    setTargetingBlockDimensionValues,
    setTargetingBlockIsReusable,
    setTargetingBlockIsReusableModalOpen,
    setTargetingOperation,
} from "@app/features/targeting/reducer";
import {
    targetingBlockToTargetingPayload,
    toTargetingModePayload,
    targetingBlockDimensionToCsv,
} from "@app/features/targeting/helpers";
import {
    Conditions,
    CustomRuleIds,
    CustomRuleOperators,
    GroupConditions,
    Operations,
    PmpConditions,
    SegmentRuleIdentifierOperators,
    SegmentRuleModes,
    TargetingDimensionTypes,
    TargetingFormKeys,
    TimeZoneModeIds,
} from "@app/features/targeting/constants";
import { SegmentRuleGroup, CustomRuleGroup, TargetingBlock, TargetingStub, TargetingDimension } from "./types";
import { Targeting, TargetingMode, TargetingCreatePayload, TargetingUpdatePayload } from "@app/core/services";

export const toTargetingPayload = (targetingBlocks: (TargetingBlock | TargetingStub | Targeting)[]) => {
    return targetingBlocks.map((targetingBlock) => {
        if ("isReusable" in targetingBlock) {
            return targetingBlockToTargetingPayload(targetingBlock);
        }
        const { creationDate, updateDate, isStub, marketplace, seat, ...rest } = targetingBlock;
        return rest as TargetingUpdatePayload;
    });
};

export const useTargetingForm = (resetOnUnmountFormKey?: TargetingFormKeys) => {
    const dispatch = useAppDispatch();
    const targetingFormsByFormKey = useAppSelector((state) => state.targeting.targetingFormsByKey);

    useEffect(() => {
        return () => {
            if (resetOnUnmountFormKey) {
                dispatch(resetTargetingBlocksByFormKey({ formKey: resetOnUnmountFormKey }));
            }
        };
    }, [dispatch, resetOnUnmountFormKey]);

    const targetingPayloadsByFormKey: {
        [key in TargetingFormKeys]: TargetingCreatePayload[];
    } = useMemo(() => {
        const res = {
            [TargetingFormKeys.AdSource]: toTargetingPayload(
                targetingFormsByFormKey[TargetingFormKeys.AdSource].targetingBlocks
            ),
            [TargetingFormKeys.AdSourceAdditionalTargeting]: toTargetingPayload(
                targetingFormsByFormKey[TargetingFormKeys.AdSourceAdditionalTargeting].targetingBlocks
            ),
            [TargetingFormKeys.Deal]: toTargetingPayload(
                targetingFormsByFormKey[TargetingFormKeys.Deal].targetingBlocks
            ),
            [TargetingFormKeys.DealAdditionalTargeting]: toTargetingPayload(
                targetingFormsByFormKey[TargetingFormKeys.DealAdditionalTargeting].targetingBlocks
            ),
            [TargetingFormKeys.InventoryAdvancedFloors]: toTargetingPayload(
                targetingFormsByFormKey[TargetingFormKeys.InventoryAdvancedFloors].targetingBlocks
            ),
            [TargetingFormKeys.BrandSafety]: toTargetingPayload(
                targetingFormsByFormKey[TargetingFormKeys.BrandSafety].targetingBlocks
            ),
            [TargetingFormKeys.Controls]: toTargetingPayload(
                targetingFormsByFormKey[TargetingFormKeys.Controls].targetingBlocks
            ),
        };
        return res;
    }, [targetingFormsByFormKey]);

    const targetingModePayloadsByFormKey: {
        [key in TargetingFormKeys]: TargetingMode;
    } = useMemo(() => {
        const res = {
            [TargetingFormKeys.AdSource]: toTargetingModePayload(
                targetingFormsByFormKey[TargetingFormKeys.AdSource].targetingOperation
            ),
            [TargetingFormKeys.AdSourceAdditionalTargeting]: toTargetingModePayload(
                targetingFormsByFormKey[TargetingFormKeys.AdSourceAdditionalTargeting].targetingOperation
            ),
            [TargetingFormKeys.Deal]: toTargetingModePayload(
                targetingFormsByFormKey[TargetingFormKeys.Deal].targetingOperation
            ),
            [TargetingFormKeys.DealAdditionalTargeting]: toTargetingModePayload(
                targetingFormsByFormKey[TargetingFormKeys.DealAdditionalTargeting].targetingOperation
            ),
            [TargetingFormKeys.InventoryAdvancedFloors]: toTargetingModePayload(
                targetingFormsByFormKey[TargetingFormKeys.InventoryAdvancedFloors].targetingOperation
            ),
            [TargetingFormKeys.BrandSafety]: toTargetingModePayload(
                targetingFormsByFormKey[TargetingFormKeys.BrandSafety].targetingOperation
            ),
            [TargetingFormKeys.Controls]: toTargetingModePayload(
                targetingFormsByFormKey[TargetingFormKeys.Controls].targetingOperation
            ),
        };
        return res;
    }, [targetingFormsByFormKey]);

    const _downloadTargetingBlockDimension = useCallback(
        (formKey: TargetingFormKeys, index: number, dimension: TargetingDimension) => {
            const targetingPayloadByFormKey = targetingPayloadsByFormKey[formKey];
            const targetingPayload = targetingPayloadByFormKey[index];

            targetingBlockDimensionToCsv(targetingPayload, dimension);
        },
        [targetingPayloadsByFormKey]
    );

    const _addExistingTargetingBlock = useCallback(
        (formKey: TargetingFormKeys, targeting: Targeting | TargetingStub) =>
            dispatch(addExistingTargetingBlock({ formKey, targeting })),
        [dispatch]
    );

    const _addTargetingBlock = useCallback(
        (formKey: TargetingFormKeys) => dispatch(addTargetingBlock({ formKey })),
        [dispatch]
    );

    const _replaceTargetingBlock = useCallback(
        (formKey: TargetingFormKeys, targetingBlockIndex: number, targeting: Targeting) =>
            dispatch(replaceTargetingBlock({ formKey, targetingBlockIndex, targeting })),
        [dispatch]
    );

    const _addTargetingBlockDimension = useCallback(
        (formKey: TargetingFormKeys, index: number, type: TargetingDimensionTypes) => {
            return dispatch(addTargetingBlockDimension({ formKey, index, type }));
        },
        [dispatch]
    );

    const _addTargetingBlockDimensionRule = useCallback(
        (formKey: TargetingFormKeys, targetingBlockIndex: number, dimensionIndex: number, path: number[]) =>
            dispatch(addTargetingBlockDimensionRule({ formKey, targetingBlockIndex, dimensionIndex, path })),
        [dispatch]
    );

    const _addTargetingBlockDimensionRuleGroup = useCallback(
        (formKey: TargetingFormKeys, targetingBlockIndex: number, dimensionIndex: number, path: number[]) =>
            dispatch(addTargetingBlockDimensionRuleGroup({ formKey, targetingBlockIndex, dimensionIndex, path })),
        [dispatch]
    );

    const _copyTargetingBlock = useCallback(
        (formKey: TargetingFormKeys, index: number) => dispatch(copyTargetingBlock({ formKey, index })),
        [dispatch]
    );

    const _copyTargetingBlockDimension = useCallback(
        (formKey: TargetingFormKeys, targetingBlockIndex: number, dimensionIndex: number) =>
            dispatch(copyTargetingBlockDimension({ formKey, targetingBlockIndex, dimensionIndex })),
        [dispatch]
    );

    const _deleteTargetingBlock = useCallback(
        (formKey: TargetingFormKeys, index: number) => dispatch(deleteTargetingBlock({ formKey, index })),
        [dispatch]
    );

    const _deleteTargetingBlockDimension = useCallback(
        (formKey: TargetingFormKeys, targetingBlockIndex: number, dimensionIndex: number) =>
            dispatch(deleteTargetingBlockDimension({ formKey, targetingBlockIndex, dimensionIndex })),
        [dispatch]
    );

    const _deleteTargetingBlockDimensionRule = useCallback(
        (formKey: TargetingFormKeys, targetingBlockIndex: number, dimensionIndex: number, path: number[]) =>
            dispatch(deleteTargetingBlockDimensionRule({ formKey, targetingBlockIndex, dimensionIndex, path })),
        [dispatch]
    );

    const _loadTargeting = useCallback(
        (formKey: TargetingFormKeys, targeting: Targeting[], targetingMode: TargetingMode) =>
            dispatch(loadTargeting({ formKey, targeting, targetingMode })),
        [dispatch]
    );

    const _loadValidationTargeting = useCallback(
        (formKey: TargetingFormKeys, validationTargeting: (Targeting | TargetingStub | TargetingBlock)[]) =>
            dispatch(loadValidationTargeting({ formKey, validationTargeting })),
        [dispatch]
    );

    const _setTargetingBlockDimensionCondition = useCallback(
        (formKey: TargetingFormKeys, targetingBlockIndex: number, dimensionIndex: number, condition: Conditions) =>
            dispatch(setTargetingBlockDimensionCondition({ formKey, targetingBlockIndex, dimensionIndex, condition })),
        [dispatch]
    );

    const _setTargetingBlockDimensionMode = useCallback(
        (formKey, targetingBlockIndex: number, dimensionIndex: number, mode: SegmentRuleModes) =>
            dispatch(setTargetingBlockDimensionMode({ formKey, targetingBlockIndex, dimensionIndex, mode })),
        [dispatch]
    );

    const _setTargetingBlockDimensionOperator = useCallback(
        (
            formKey: TargetingFormKeys,
            targetingBlockIndex: number,
            dimensionIndex: number,
            path: number[],
            operator: SegmentRuleIdentifierOperators
        ) =>
            dispatch(
                setTargetingBlockDimensionOperator({ formKey, targetingBlockIndex, dimensionIndex, path, operator })
            ),
        [dispatch]
    );

    const _setTargetingBlockDimensionPmpCondition = useCallback(
        (
            formKey: TargetingFormKeys,
            targetingBlockIndex: number,
            dimensionIndex: number,
            pmpCondition: PmpConditions
        ) =>
            dispatch(
                setTargetingBlockDimensionPmpCondition({ formKey, targetingBlockIndex, dimensionIndex, pmpCondition })
            ),
        [dispatch]
    );

    const _setTargetingBlockDimensionRuleCondition = useCallback(
        (
            formKey: TargetingFormKeys,
            targetingBlockIndex: number,
            dimensionIndex: number,
            path: number[],
            condition: GroupConditions
        ) =>
            dispatch(
                setTargetingBlockDimensionRuleCondition({
                    formKey,
                    targetingBlockIndex,
                    dimensionIndex,
                    path,
                    condition,
                })
            ),
        [dispatch]
    );

    const _setTargetingBlockDimensionRuleGroup = useCallback(
        (
            formKey: TargetingFormKeys,
            targetingBlockIndex: number,
            dimensionIndex: number,
            ruleGroup: SegmentRuleGroup | CustomRuleGroup
        ) => dispatch(setTargetingBlockDimensionRuleGroup({ formKey, targetingBlockIndex, dimensionIndex, ruleGroup })),
        [dispatch]
    );

    const _setTargetingBlockDimensionRuleId = useCallback(
        (
            formKey: TargetingFormKeys,
            targetingBlockIndex: number,
            dimensionIndex: number,
            path: number[],
            id: CustomRuleIds
        ) => dispatch(setTargetingBlockDimensionRuleId({ formKey, targetingBlockIndex, dimensionIndex, path, id })),
        [dispatch]
    );

    const _setTargetingBlockDimensionRuleOperator = useCallback(
        (
            formKey: TargetingFormKeys,
            targetingBlockIndex: number,
            dimensionIndex: number,
            path: number[],
            operator: CustomRuleOperators
        ) =>
            dispatch(
                setTargetingBlockDimensionRuleOperator({ formKey, targetingBlockIndex, dimensionIndex, path, operator })
            ),
        [dispatch]
    );

    const _setTargetingBlockDimensionRuleValue = useCallback(
        (
            formKey: TargetingFormKeys,
            targetingBlockIndex: number,
            dimensionIndex: number,
            path: number[],
            value: LabeledValue | string | string[] | number | number[] | null
        ) =>
            dispatch(
                setTargetingBlockDimensionRuleValue({ formKey, targetingBlockIndex, dimensionIndex, path, value })
            ),
        [dispatch]
    );

    const _setTargetingBlockDimensionTimeZoneMode = useCallback(
        (
            formKey: TargetingFormKeys,
            targetingBlockIndex: number,
            dimensionIndex: number,
            timeZoneMode: TimeZoneModeIds
        ) =>
            dispatch(
                setTargetingBlockDimensionTimeZoneMode({ formKey, targetingBlockIndex, dimensionIndex, timeZoneMode })
            ),
        [dispatch]
    );

    const _setTargetingBlockDimensionValues = useCallback(
        (formKey: TargetingFormKeys, targetingBlockIndex: number, dimensionIndex: number, values: LabeledValue[]) =>
            dispatch(setTargetingBlockDimensionValues({ formKey, targetingBlockIndex, dimensionIndex, values })),
        [dispatch]
    );

    const _setTargetingBlockIsReusable = useCallback(
        (formKey, index: number, isReusable: boolean) =>
            dispatch(setTargetingBlockIsReusable({ formKey, index, isReusable })),
        [dispatch]
    );

    const _setTargetingBlockIsReusableModalOpen = useCallback(
        (formKey, index: number, isReusableModalOpen: boolean) =>
            dispatch(setTargetingBlockIsReusableModalOpen({ formKey, index, isReusableModalOpen })),
        [dispatch]
    );

    const _setTargetingOperation = useCallback(
        (formKey, operation: Operations) => dispatch(setTargetingOperation({ formKey, operation })),
        [dispatch]
    );

    return {
        addExistingTargetingBlock: _addExistingTargetingBlock,
        addTargetingBlock: _addTargetingBlock,
        addTargetingBlockDimension: _addTargetingBlockDimension,
        addTargetingBlockDimensionRule: _addTargetingBlockDimensionRule,
        addTargetingBlockDimensionRuleGroup: _addTargetingBlockDimensionRuleGroup,
        copyTargetingBlock: _copyTargetingBlock,
        copyTargetingBlockDimension: _copyTargetingBlockDimension,
        deleteTargetingBlock: _deleteTargetingBlock,
        deleteTargetingBlockDimension: _deleteTargetingBlockDimension,
        deleteTargetingBlockDimensionRule: _deleteTargetingBlockDimensionRule,
        downloadTargetingBlockDimension: _downloadTargetingBlockDimension,
        loadTargeting: _loadTargeting,
        loadValidationTargeting: _loadValidationTargeting,
        replaceTargetingBlock: _replaceTargetingBlock,
        setTargetingBlockDimensionCondition: _setTargetingBlockDimensionCondition,
        setTargetingBlockDimensionMode: _setTargetingBlockDimensionMode,
        setTargetingBlockDimensionOperator: _setTargetingBlockDimensionOperator,
        setTargetingBlockDimensionPmpCondition: _setTargetingBlockDimensionPmpCondition,
        setTargetingBlockDimensionRuleCondition: _setTargetingBlockDimensionRuleCondition,
        setTargetingBlockDimensionRuleGroup: _setTargetingBlockDimensionRuleGroup,
        setTargetingBlockDimensionRuleId: _setTargetingBlockDimensionRuleId,
        setTargetingBlockDimensionRuleOperator: _setTargetingBlockDimensionRuleOperator,
        setTargetingBlockDimensionRuleValue: _setTargetingBlockDimensionRuleValue,
        setTargetingBlockDimensionTimeZoneMode: _setTargetingBlockDimensionTimeZoneMode,
        setTargetingBlockDimensionValues: _setTargetingBlockDimensionValues,
        setTargetingBlockIsReusable: _setTargetingBlockIsReusable,
        setTargetingBlockIsReusableModalOpen: _setTargetingBlockIsReusableModalOpen,
        setTargetingOperation: _setTargetingOperation,
        targetingFormsByFormKey,
        targetingModePayloadsByFormKey,
        targetingPayloadsByFormKey,
    };
};
