import { FC, useState } from "react";
import { css } from "@emotion/css";
import { Collapse, Drawer, Space, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import DOMPurify from "dompurify";
import { DealsHelpKeys } from "@app/features/deals/constants";
import { useHelpArticles } from "@app/core/components/hooks";
import { useUserAccess } from "@app/core/auth";
import { DrawerCloseButton, SectionTitle, StyledButtonLink } from "@app/core/components";

const DEFAULT_LABEL = "Deal Object";

export const configurationCollapseClassList = css`
    .ant-collapse-expand-icon {
        align-self: flex-end;
    }
`;

interface Props {
    label?: string;
}
export const DealObjectSidePanel: FC<Props> = ({ label = DEFAULT_LABEL }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { helpArticles } = useHelpArticles(DealsHelpKeys);
    const { hasInternalAccess } = useUserAccess();

    const dealTypeFields = helpArticles[DealsHelpKeys.DealDetailsType];
    const dealIdFields = helpArticles[DealsHelpKeys.DealId];
    const dealNameFields = helpArticles[DealsHelpKeys.DealName];
    const dealDSPFields = helpArticles[DealsHelpKeys.DealDSP];
    const dealBuyerFields = helpArticles[DealsHelpKeys.DealBuyer];
    const dealDatesFields = helpArticles[DealsHelpKeys.DealDates];
    const dealTimeZoneFields = helpArticles[DealsHelpKeys.DealTimeZone];
    const dealStatusFields = helpArticles[DealsHelpKeys.DealStatus];

    return (
        <>
            <StyledButtonLink onClick={() => setIsOpen(true)} type="link" data-sdet="deal-object">
                <InfoCircleOutlined />
                {label}
            </StyledButtonLink>
            <Drawer
                styles={{ body: { padding: 0 } }}
                width="40%"
                title={`${label} Definitions`}
                open={isOpen}
                closable={false}
                onClose={() => setIsOpen(false)}
                extra={<DrawerCloseButton onClose={() => setIsOpen(false)} />}
            >
                <Collapse
                    className={configurationCollapseClassList}
                    defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8"]}
                >
                    <Collapse.Panel key="1" header={<SectionTitle title={dealTypeFields?.title || "Deal Type"} />}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(dealTypeFields?.body),
                            }}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel key="2" header={<SectionTitle title="Deal ID and Deal Name" />}>
                        <Space direction="vertical">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(dealIdFields?.body),
                                }}
                            />
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(dealNameFields?.body),
                                }}
                            />
                        </Space>
                    </Collapse.Panel>
                    <Collapse.Panel key="3" header={<SectionTitle title={dealDSPFields?.title ?? "DSP"} />}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(dealDSPFields?.body),
                            }}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel key="4" header={<SectionTitle title={dealBuyerFields?.title ?? "Buyer"} />}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(dealBuyerFields?.body),
                            }}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel
                        key="5"
                        header={<SectionTitle title={dealDatesFields?.title ?? "Start and End Dates"} />}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(dealDatesFields?.body),
                            }}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel key="6" header={<SectionTitle title={dealTimeZoneFields?.title ?? "Time Zone"} />}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(dealTimeZoneFields?.body),
                            }}
                        />
                    </Collapse.Panel>
                    {hasInternalAccess && (
                        <Collapse.Panel key="7" header={<SectionTitle title="Deal Origin" />}>
                            <Typography.Paragraph>
                                Origination source for the deal. Primarily for reporting purposes.
                            </Typography.Paragraph>
                        </Collapse.Panel>
                    )}
                    <Collapse.Panel key="8" header={<SectionTitle title={dealStatusFields?.title ?? "Status"} />}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(dealStatusFields?.body),
                            }}
                        />
                    </Collapse.Panel>
                </Collapse>
            </Drawer>
        </>
    );
};
