import { FC, ReactNode } from "react";
import { Loading } from "../Loading";
import { NoDataAvailable } from "./NoDataAvailable";

export type LoadingState = "loading" | "loadedNoData" | "loaded";

interface Props {
    children: ReactNode;
    loadingState: LoadingState;
    dataSdet?: string;
}

export const LoadableComponent: FC<Props> = ({ children, loadingState, dataSdet = "loadable-data" }) => {
    switch (loadingState) {
        case "loading":
            return <Loading />;
        case "loadedNoData":
            return <NoDataAvailable />;
        default:
            return <div data-sdet={dataSdet}>{children}</div>;
    }
};
