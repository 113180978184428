import { TagRender } from "@app/core/components";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { Form } from "antd";
import { useExcludeDemand } from "./useExcludeDemand";

export const ExcludeDemandMultiselect = () => {
    const { availableNetworks, isLoading } = useExcludeDemand();
    return (
        <Form.Item
            colon={false}
            {...VERTICAL_LAYOUT_FIX}
            name={AD_SOURCE_FIELDS.EXCLUDE_DEMAND.name}
            label={AD_SOURCE_FIELDS.EXCLUDE_DEMAND.label}
        >
            <SelectWithValue
                allowClear
                showSearch
                placeholder="Select…"
                options={availableNetworks}
                loading={isLoading}
                optionFilterProp="label"
                fieldAsLabel={["name"]}
                fieldAsValue={["id"]}
                mode="multiple"
                tagRender={TagRender}
            />
        </Form.Item>
    );
};
