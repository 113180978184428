import { useNotificationError } from "@app/core/components/hooks";
import { useGetContentTransparencyRulesFieldsQuery } from "@app/core/services";
import { Form } from "antd";
import { LabeledValue } from "antd/es/select";
import { useMemo } from "react";

export const useAllowContentSelect = () => {
    const { data, error, isFetching } = useGetContentTransparencyRulesFieldsQuery();
    useNotificationError(error);
    const options = useMemo(() => data?.map(({ id, name }) => ({ label: name, value: id })), [data]);

    const { setFieldValue, validateFields, getFieldValue } = Form.useFormInstance();

    const onChange = (values: LabeledValue[]): void => {
        setFieldValue("contentFields", values);

        validateFields(["contentFields"]);
    };
    return {
        onChange,
        options,
        isLoading: isFetching,
        value: getFieldValue("contentFields"),
    };
};
