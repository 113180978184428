import { FC } from "react";
import { Card, Col, Row } from "antd";
import { useDemandRequirementsSection } from "./useDemandRequirementsSection";
import {
    AdditionalTime,
    BlockedAttributes,
    MIMEs,
    MaxBitrate,
    MaxDuration,
    MaxExtended,
    MinBitrate,
    MinDuration,
    Placement,
    SupportedAPIs,
    SupportedProtocols,
} from "@app/features/inventory/HierarchyForms/AdUnitForm/DemandRequirementsSection/Fields";

const GUTTER = 32;
const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 };
const INPUTS_COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 };

export const DemandRequirementsSection: FC = () => {
    const { isAdditionalTimeShown } = useDemandRequirementsSection();
    return (
        <Card bordered={false}>
            <Row>
                <Col {...COL_SIZES}>
                    <MIMEs />
                    <SupportedAPIs />
                    <Row gutter={GUTTER}>
                        <Col {...INPUTS_COL_SIZES}>
                            <Placement />
                        </Col>
                    </Row>
                    <SupportedProtocols />
                    <Row gutter={GUTTER}>
                        <Col {...INPUTS_COL_SIZES}>
                            <MinDuration />
                        </Col>
                        <Col {...INPUTS_COL_SIZES}>
                            <MaxDuration />
                        </Col>
                    </Row>
                    <Row gutter={GUTTER}>
                        <Col {...INPUTS_COL_SIZES}>
                            <MaxExtended />
                        </Col>
                        {isAdditionalTimeShown && (
                            <Col {...INPUTS_COL_SIZES}>
                                <AdditionalTime />
                            </Col>
                        )}
                    </Row>
                    <Row gutter={GUTTER}>
                        <Col {...INPUTS_COL_SIZES}>
                            <MinBitrate />
                        </Col>
                        <Col {...INPUTS_COL_SIZES}>
                            <MaxBitrate />
                        </Col>
                    </Row>
                    <BlockedAttributes />
                </Col>
            </Row>
        </Card>
    );
};
