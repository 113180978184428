import { FC } from "react";
import { Form, Radio, RadioProps } from "antd";
import { LINEARITY } from "@app/features/inventory/HierarchyForms/constants";
import { useAdUnitLinearity } from "@app/features/inventory/components/FormItems/AdUnitLinearity/useAdUnitLinearity";

interface AdUnitLinearityProps extends RadioProps {
    name: typeof LINEARITY;
    label: string;
}

export const AdUnitLinearity: FC<AdUnitLinearityProps> = ({ name, label, ...rest }) => {
    const { options } = useAdUnitLinearity();
    return (
        <Form.Item name={name} label={label}>
            <Radio.Group size="large" options={options} {...rest} />
        </Form.Item>
    );
};
