import { FC } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, Select, Space, Tooltip } from "antd";
import { useDealDspField } from "@app/features/deals/DealForm/DealDemandFormSections/DealTermsSection/Fields/DealDspField/useDealDspField";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import { XandrWarningModal } from "@app/core/components";
import { brandCobalt } from "@rubicon/antd-components";

interface Props {
    changeSyncDsp: (hasSync: boolean) => void;
    mode: DealFormMode;
}

export const DealDspField: FC<Props> = ({ changeSyncDsp, mode }) => {
    const { options, isLoading, handleChange, value, searchValue, onSearch } = useDealDspField(changeSyncDsp, mode);
    const isEdit = mode === "edit";

    return (
        <>
            <XandrWarningModal selectedDspNetworkId={value} formMode={mode} />
            <Form.Item
                data-sdet="deal-dsp-field"
                rules={[{ required: !isEdit, message: "DSP is required" }]}
                label="DSP"
                tooltip={{
                    placement: "topRight",
                    overlayInnerStyle: { width: "30rem" },
                    title: "This is the name of the DSP Network, where deal will be created for buyer approval. Only DSPs with active deal sync API integration are available for selection.",
                    icon: <QuestionCircleOutlined style={{ color: brandCobalt }} />,
                }}
                name={CREATE_DEAL_FORM_ITEMS_NAME.DSP}
            >
                <Select
                    data-sdet="deal-dsp-select"
                    disabled={isEdit}
                    value={value}
                    searchValue={searchValue}
                    onSearch={onSearch}
                    showSearch
                    placeholder="Select a DSP"
                    loading={isLoading}
                    onChange={handleChange}
                    optionFilterProp="label"
                >
                    {options.map(({ value, label, hasSync }) => (
                        <Select.Option key={value} hasSync={hasSync} value={value} label={label}>
                            <Space>
                                {label}
                                {hasSync && (
                                    <Tooltip title="This DSP is capable of sync.">
                                        <ExclamationCircleOutlined style={{ color: brandCobalt }} />
                                    </Tooltip>
                                )}
                            </Space>
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </>
    );
};
