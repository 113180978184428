import { FC } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { useHistory } from "react-router-dom";
import { BrandChannel } from "@app/core/services";

interface PublisherChannelEditPageHeaderProps {
    channel: BrandChannel;
}

export const PublisherChannelEditPageHeader: FC<PublisherChannelEditPageHeaderProps> = ({ channel }) => {
    const history = useHistory();
    const onBack = (): void => history.goBack();
    return <PageHeader title={`Edit Publisher: ${channel.name}`} subTitle={channel.publisher.code} onBack={onBack} />;
};
