import { LabeledValue } from "antd/lib/select";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import {
    selectHistoricalChartAdSource,
    setHistoricalChartAdSource,
} from "@app/core/components/charts/HistoricalChart/reducer";
import { DealListSource } from "@app/core/services";

interface UseHistoricalChartAdSourceFilter {
    handleChange: (option: LabeledValue) => void;
    options: LabeledValue[];
    value: number;
}

export const useHistoricalChartAdSourceFilter = (adSources?: DealListSource[]): UseHistoricalChartAdSourceFilter => {
    const dispatch = useAppDispatch();
    const options: LabeledValue[] = (adSources || []).map((adSource) => {
        return { key: adSource.id.toString(), value: adSource.id, label: adSource.name };
    });

    const adSource = useAppSelector(selectHistoricalChartAdSource);

    const handleChange = (e: LabeledValue): void => {
        dispatch(setHistoricalChartAdSource(e.value as number));
    };

    return {
        options,
        handleChange,
        value: +adSource,
    };
};
