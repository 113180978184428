import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { toTargetingModePayloadId } from "@app/features/targeting/helpers";

export const useDealAdditionalTargetingSection = () => {
    const adSourceTargeting = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_TARGETING]
    );
    const additionalTargeting = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.DEMAND_TARGETING]
    );
    const targetingOperation = useAppSelector(
        (state) => state.targeting.targetingFormsByKey.dealAdditionalTargeting.targetingOperation
    );

    return {
        adSourceTargeting,
        additionalTargeting,
        targetingModeId: toTargetingModePayloadId(targetingOperation),
    };
};
