import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@app/core/store";
import { LV } from "./TransparencyDefault/constants";

export interface BrandSafety {
    extendedIds: Record<string, LV[]>;
    touchedFields: number[];
}

export const initialState: BrandSafety = {
    extendedIds: {},
    touchedFields: [],
};

const brandSafetySlice = createSlice({
    name: "brandSafety",
    initialState,
    reducers: {
        setExtendedIds: (state, action: PayloadAction<Record<string, LV[]>>) => {
            state.extendedIds = action.payload;
        },
        setTransparencyFormTouchedFields: (state, action) => {
            state.touchedFields = action.payload;
        },
    },
});

export const selectExtendedIds = (state: RootState) => state.brandSafety.extendedIds;
export const selectTransparencyFormTouchedFields = (state: RootState) => state.brandSafety.touchedFields;
export const { setExtendedIds, setTransparencyFormTouchedFields } = brandSafetySlice.actions;

export const brandSafetyReducer = brandSafetySlice.reducer;
