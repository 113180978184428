import { useAppDispatch, useAppSelector } from "@app/core/store";
import { DEAL_DETAILS_DRAWER_LOCATION, DealDetailsDrawerTitles } from "@app/features/deals/constants";
import {
    closeDrawer,
    collapseAll,
    expandAll,
    openDrawer,
    selectIsCollapseExpandedAll,
    selectIsDealDetailsDrawerOpen,
} from "./reducer";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { convertSecondaryViewToURL } from "./convertSecondaryView";

interface UseDealDetailsDrawer {
    isDealDetailsDrawerOpen: boolean;
    isExpandAll: boolean;
    openDealDetailsDrawer: (
        dealId: number,
        secondaryDetailsView?: DealDetailsDrawerTitles,
        openedFromDetailsView?: boolean
    ) => void;
    closeDealDetailsDrawer: () => void;
    handleChangeCollapse: () => void;
    closeDealDetailsDrawerWithoutHistory: () => void;
}
export const useDealDetailsDrawer = (): UseDealDetailsDrawer => {
    const dispatch = useAppDispatch();
    const isDealDetailsDrawerOpen = useAppSelector(selectIsDealDetailsDrawerOpen);
    const isExpandAll = useAppSelector(selectIsCollapseExpandedAll);

    const history = useHistory();
    const location = useLocation();
    const { seatId } = useParams<{ seatId: string; dealId: string }>();

    const openDealDetailsDrawer = (
        dealId: number,
        secondaryDetailsView?: DealDetailsDrawerTitles,
        openedFromDetailsView = false
    ) => {
        dispatch(openDrawer({ requestedDealId: dealId, secondaryDetailsView, openedFromDetailsView }));
        if (location.pathname.includes(DEAL_DETAILS_DRAWER_LOCATION.DEALS_HEALTH)) {
            if (secondaryDetailsView && openedFromDetailsView) {
                history.push(
                    ROUTE_FORMATTERS.SEAT_DEAL_HEALTH_DEAL_DETAILS_DRAWER_SECONDARY_VIEW(
                        seatId,
                        dealId,
                        convertSecondaryViewToURL(secondaryDetailsView)
                    )
                );
                return;
            }
            history.push(
                secondaryDetailsView
                    ? ROUTE_FORMATTERS.SEAT_DEAL_HEALTH_DEAL_SECONDARY_VIEW(
                          seatId,
                          dealId,
                          convertSecondaryViewToURL(secondaryDetailsView)
                      )
                    : ROUTE_FORMATTERS.SEAT_DEAL_HEALTH_DEAL_DETAILS_DRAWER(seatId, dealId)
            );
            return;
        }
        if (location.pathname.includes(DEAL_DETAILS_DRAWER_LOCATION.DEALS_DETAILS)) {
            return;
        }

        if (location.pathname.includes(DEAL_DETAILS_DRAWER_LOCATION.DEALS)) {
            if (location.pathname.includes(DEAL_DETAILS_DRAWER_LOCATION.EDIT)) {
                return;
            }
            if (secondaryDetailsView && openedFromDetailsView) {
                history.push(
                    ROUTE_FORMATTERS.SEAT_DEALS_DEAL_DETAILS_DRAWER_SECONDARY_VIEW(
                        seatId,
                        dealId,
                        convertSecondaryViewToURL(secondaryDetailsView)
                    )
                );
                return;
            }
            history.push(
                secondaryDetailsView
                    ? ROUTE_FORMATTERS.SEAT_DEALS_SECONDARY_VIEW(
                          seatId,
                          dealId,
                          convertSecondaryViewToURL(secondaryDetailsView)
                      )
                    : ROUTE_FORMATTERS.SEAT_DEALS_DEAL_DETAILS_DRAWER(seatId, dealId)
            );
            return;
        }
    };

    const closeDealDetailsDrawerWithoutHistory = () => {
        dispatch(closeDrawer());
    };

    const closeDealDetailsDrawer = () => {
        dispatch(closeDrawer());
        if (location.pathname.includes(DEAL_DETAILS_DRAWER_LOCATION.DEALS_HEALTH)) {
            history.push(ROUTE_FORMATTERS.SEAT_DEAL_HEALTH(seatId));
            return;
        }
        if (
            location.pathname.includes(DEAL_DETAILS_DRAWER_LOCATION.DEALS) &&
            location.pathname.includes(DEAL_DETAILS_DRAWER_LOCATION.DEAL_DETAILS_DRAWER)
        ) {
            history.push(ROUTE_FORMATTERS.SEAT_DEALS(seatId));
            return;
        }
    };

    const handleChangeCollapse = () => {
        if (isExpandAll) {
            dispatch(expandAll());
        } else {
            dispatch(collapseAll());
        }
    };

    return {
        isExpandAll,
        isDealDetailsDrawerOpen,
        openDealDetailsDrawer,
        closeDealDetailsDrawer,
        handleChangeCollapse,
        closeDealDetailsDrawerWithoutHistory,
    };
};
