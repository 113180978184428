export enum AdSourceAdServingResultCode {
    EXCLUDED_PRICE_BELOW_FLOOR = 101,
    EXCLUDED_EXCEEDED_FREQUENCY_CAP = 102,
    EXCLUDED_PACING = 103,
    CHOSEN_NO_COMPETITION_FILLED = 200003,
    CHOSEN_NO_COMPETITION_NO_BID = 200001,
    CHOSEN_NO_COMPETITION_ERROR = 200002,
    CHOSEN_RANOM_FILLED = 201003,
    CHOSEN_RANDOM_NO_BID = 201001,
    CHOSEN_RANDOM_ERROR = 201002,
    CHOSEN_PACING_PRIORITIZED_FILLED = 202003,
    CHOSEN_PACING_PRIORITIZED_NO_BID = 202001,
    CHOSEN_PACING_PRIORITIZED_ERROR = 202002,
    NOT_CHOSEN_RANDOMLY = 301,
    NOT_CHOSEN_RANDOMLY_FALLBACK_FILLED = 301003,
    NOT_CHOSEN_RANDOMLY_FALLBACK_NO_BID = 301001,
    NOT_CHOSEN_RANDOMLY_FALLBACK_ERROR = 301002,
    NOT_CHOSEN_COMPETITION = 302,
    NOT_CHOSEN_COMPETITION_FALLBACK_FILLED = 302003,
    NOT_CHOSEN_COMPETITION_FALLBACK_NO_BID = 302001,
    NOT_CHOSEN_COMPETITION_FALLBACK_ERROR = 302002,
}

const AdServingDescriptions = {
    EXCLUDED_PRICE_BELOW_FLOOR: "supply floor was higher than CPM",
    EXCLUDED_EXCEEDED_FREQUENCY_CAP: "frequency cap has been met for this user",
    EXCLUDED_PACING: "was pacing on target",
    NO_AVAILABLE_ITEMS: "no other available items",
    CHOSEN_RANDOM: "chosen from other items with similar pacing status",
    CHOSEN_PACING_PRIORITIZED_FILLED: "",
    CHOSEN_PACING_PRIORITIZED: "chosen because this items pacing was more urgent",
    NOT_CHOSEN_RANDOMLY: "not chosen from other items with similar pacing status",
    NOT_CHOSEN_RANDOMLY_FALLBACK: "initially not chosen from other items with similar pacing status",
    NOT_CHOSEN_COMPETITION: "not chosen because a different item had a more urgent pacing status",
    NOT_CHOSEN_COMPETITION_FALLBACK: "initially not chosen, a different item had a more urgent pacing status",
};

export const AdSourceAdServingResultMap = {
    [AdSourceAdServingResultCode.EXCLUDED_PRICE_BELOW_FLOOR]: {
        code: "101",
        name: "Excluded - Price Below Floor (101)",
        description: AdServingDescriptions.EXCLUDED_PRICE_BELOW_FLOOR,
    },
    [AdSourceAdServingResultCode.EXCLUDED_EXCEEDED_FREQUENCY_CAP]: {
        code: "102",
        name: "Excluded - Exceeded Freq Cap (102)",
        description: AdServingDescriptions.EXCLUDED_EXCEEDED_FREQUENCY_CAP,
    },
    [AdSourceAdServingResultCode.EXCLUDED_PACING]: {
        code: "103",
        name: "Excluded - Pacing (103)",
        description: AdServingDescriptions.EXCLUDED_PACING,
    },
    [AdSourceAdServingResultCode.CHOSEN_NO_COMPETITION_FILLED]: {
        code: "200 - 3",
        name: "Chosen - No Competition (200) & Filled (3)",
        description: AdServingDescriptions.NO_AVAILABLE_ITEMS,
    },
    [AdSourceAdServingResultCode.CHOSEN_NO_COMPETITION_NO_BID]: {
        code: "200 - 1",
        name: "Chosen - No Competition (200) & No Bid (1)",
        description: AdServingDescriptions.NO_AVAILABLE_ITEMS,
    },
    [AdSourceAdServingResultCode.CHOSEN_NO_COMPETITION_ERROR]: {
        code: "200 - 2",
        name: "Chosen - No Competition (200) & Error (2)",
        description: AdServingDescriptions.NO_AVAILABLE_ITEMS,
    },
    [AdSourceAdServingResultCode.CHOSEN_RANOM_FILLED]: {
        code: "201 - 3",
        name: "Chosen - Random (201) & Filled (3)",
        description: AdServingDescriptions.CHOSEN_RANDOM,
    },
    [AdSourceAdServingResultCode.CHOSEN_RANDOM_NO_BID]: {
        code: "201 - 1",
        name: "Chosen - Random (201) & No Bid (1)",
        description: AdServingDescriptions.CHOSEN_RANDOM,
    },
    [AdSourceAdServingResultCode.CHOSEN_RANDOM_ERROR]: {
        code: "201 - 2",
        name: "Chosen - Random (201) & No Bid (1)",
        description: AdServingDescriptions.CHOSEN_RANDOM,
    },
    [AdSourceAdServingResultCode.CHOSEN_PACING_PRIORITIZED_FILLED]: {
        code: "202 - 3",
        name: "Chosen - Random (201) & No Bid (1)",
        description: AdServingDescriptions.CHOSEN_PACING_PRIORITIZED_FILLED,
    },
    [AdSourceAdServingResultCode.CHOSEN_PACING_PRIORITIZED_NO_BID]: {
        code: "202 - 1",
        name: "Chosen - Pacing Prioritized (202) & No Bid (1)",
        description: AdServingDescriptions.CHOSEN_PACING_PRIORITIZED,
    },
    [AdSourceAdServingResultCode.CHOSEN_PACING_PRIORITIZED_ERROR]: {
        code: "202 - 2",
        name: "Chosen - Pacing Prioritized (202) & Error (2)",
        description: AdServingDescriptions.CHOSEN_PACING_PRIORITIZED,
    },
    [AdSourceAdServingResultCode.NOT_CHOSEN_RANDOMLY]: {
        code: "301",
        name: "Not Chosen - Randomly (301)",
        description: AdServingDescriptions.NOT_CHOSEN_RANDOMLY,
    },
    [AdSourceAdServingResultCode.NOT_CHOSEN_RANDOMLY_FALLBACK_FILLED]: {
        code: "301 - 3",
        name: "Not Chosen - Randomly (301) & Fallback Filled (3)",
        description: AdServingDescriptions.NOT_CHOSEN_RANDOMLY_FALLBACK,
    },
    [AdSourceAdServingResultCode.NOT_CHOSEN_RANDOMLY_FALLBACK_NO_BID]: {
        code: "301 - 1",
        name: "Not Chosen - Randomly (301) & Fallback No Bid (1)",
        description: AdServingDescriptions.NOT_CHOSEN_RANDOMLY_FALLBACK,
    },
    [AdSourceAdServingResultCode.NOT_CHOSEN_RANDOMLY_FALLBACK_ERROR]: {
        code: "301 - 1",
        name: "Not Chosen - Randomly (301) & Fallback Error (2)",
        description: AdServingDescriptions.NOT_CHOSEN_RANDOMLY_FALLBACK,
    },
    [AdSourceAdServingResultCode.NOT_CHOSEN_COMPETITION]: {
        code: "302",
        name: "Not Chosen - Competition (302)",
        description: AdServingDescriptions.NOT_CHOSEN_COMPETITION,
    },
    [AdSourceAdServingResultCode.NOT_CHOSEN_COMPETITION_FALLBACK_FILLED]: {
        code: "302 - 3",
        name: "Not Chosen - Competition (302) & Fallback Filled (3)",
        description: AdServingDescriptions.NOT_CHOSEN_COMPETITION_FALLBACK,
    },
    [AdSourceAdServingResultCode.NOT_CHOSEN_COMPETITION_FALLBACK_NO_BID]: {
        code: "302 - 1",
        name: "Not Chosen - Competition (302) & Fallback No Bid (1)",
        description: AdServingDescriptions.NOT_CHOSEN_COMPETITION_FALLBACK,
    },
    [AdSourceAdServingResultCode.NOT_CHOSEN_COMPETITION_FALLBACK_ERROR]: {
        code: "302 - 2",
        name: "Not Chosen - Competition (302) & Fallback Error (2)",
        description: AdServingDescriptions.NOT_CHOSEN_COMPETITION_FALLBACK,
    },
};
