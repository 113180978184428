import { MarketplaceInfo } from "@app/core/services";
import { ReadonlyControl, SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { Form } from "antd";
import { FC } from "react";
import { DEAL_TYPES_DEMAND_OPTIONS } from "./constants";
import { useDealTypeSelect } from "./useDealTypeSelect";

export const DealTypeSelect: FC = () => {
    const { isReadonly } = useDealTypeSelect();
    return (
        <Form.Item
            data-sdet="deal-type-field"
            name={["dealUnderEdit", "dealType"]}
            label="Deal Type"
            rules={[{ required: !isReadonly, message: "DealType is required" }]}
        >
            {isReadonly ? (
                <ReadonlyControl<MarketplaceInfo["deal"]["dealType"]> getLabel={(type) => type.name} />
            ) : (
                <SelectWithValue
                    placeholder="Select Deal Type"
                    options={DEAL_TYPES_DEMAND_OPTIONS}
                    fieldAsLabel={["name"]}
                    fieldAsValue={["id"]}
                />
            )}
        </Form.Item>
    );
};
