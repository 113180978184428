import { useHistory } from "react-router-dom";
import { useUserAccess } from "@app/core/auth";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import {
    CHANNEL_FORM_FIELDS,
    AD_UNITS_MULTIPLICITIES,
    DEFAULT_PLAYLIST_DEFINITION_MODE,
} from "@app/features/inventory/HierarchyForms/constants";
import { INVENTORY_FLOORS_ADVANCED } from "@app/features/inventory/InventoryFloors";
import { useBrandChannelFormInstance } from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormInstance";

interface UseAdvancedFeaturesSection {
    isPodSectionShown: boolean;
    hasSeatWriteAccess: boolean;
    isFloorsSectionShown: boolean;
    handAdvancedFloors: () => void;
    isPlaylistSectionShown: boolean;
    isAdBreakRulesSectionShown: boolean;
}

export const useAdvancedFeaturesSection = (): UseAdvancedFeaturesSection => {
    const history = useHistory();
    const { hasSeatWriteAccess } = useUserAccess();
    const { useWatch, seat, mode, seatId } = useBrandChannelFormInstance();
    const multiplicity: number = useWatch(CHANNEL_FORM_FIELDS.MULTIPLICITY.name);

    const isPodSectionShown: boolean = multiplicity === AD_UNITS_MULTIPLICITIES.POD.value && !!seat?.adPodEnabled;
    const isPlaylistSectionShown: boolean =
        multiplicity === AD_UNITS_MULTIPLICITIES.PLAYLIST.value && !!seat?.adPodEnabled;
    const playlistDifinitionMode = useWatch(CHANNEL_FORM_FIELDS.PLAYLIST_DEFINITION_MODE.name);

    const isAdBreakRulesSectionShown: boolean = playlistDifinitionMode === DEFAULT_PLAYLIST_DEFINITION_MODE.value;
    const isFloorsSectionShown: boolean = mode === "edit";

    const handAdvancedFloors = (): void =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_TABS(seatId, INVENTORY_FLOORS_ADVANCED));

    return {
        isPodSectionShown,
        handAdvancedFloors,
        hasSeatWriteAccess,
        isFloorsSectionShown,
        isPlaylistSectionShown,
        isAdBreakRulesSectionShown,
    };
};
