import { MinDurationTarget, MaxDurationTarget } from "@app/features/targeting";
import {
    consoleApi,
    SEAT_REUSABLE_TARGETING_BY_ID_TAG,
    SEAT_REUSABLE_TARGETING_REFERENCES_BY_ID_TAG,
    SEAT_REUSABLE_TARGETING_TAG,
} from "../console";
import { AdUnit, Entity, Supply, SupplyType } from "../supply";
import { OztamDemo } from "../oztamDemo";
import { toQueryString } from "../utils";
import { Audience } from "../audiences";
import { Brand } from "../brands";
import { Publisher } from "../publishers";
import { SeatSegment } from "../segments";
import { ContentMetadataContentLength, ContentMetadataTitles, ContentMetadataTvSeries } from "../contentMetadata";
import { LabelValue } from "../labels";
import { transformTargetingFromApi } from "./utils";

export interface HasIdName {
    creationDate: string;
    updateDate: string;
    id: number | string;
    name: string;
}

export interface OperatingSystem extends HasIdName {
    code: string;
    type?: string;
    version?: string;
    nickname?: string;
    parentId?: number;
    sort?: number;
    entityType?: string;
}

export interface Platform extends HasIdName {
    type?: string;
    parentId?: number;
    sort?: number;
    entityType?: string;
}

export interface GeoTarget {
    type: HasIdName;
    geo: {
        creationDate: string;
        updateDate: string;
        id: number;
        countryCode?: string;
        countryName?: string;
        subDivisionName?: string;
        subDivisionCode?: string;
        city?: string;
        metroCode?: number;
        metroName?: string;
    };
    postalCode: string | null;
}

export interface DayPartTarget {
    dayOfWeek: number;
    halfHourOfDay: number;
}

export interface DayPartingTableColumns extends DayPartTarget {
    title: string;
    dataIndex: number;
    columnName: string;
    render: (selected: boolean, record) => void;
}

export interface ContentCategory extends HasIdName {
    id: number;
    parentId: number | null;
    parentName: string | null;
}

export interface Segment extends HasIdName {
    dmp: {
        creationDate: string;
        updateDate: string;
        id: number;
        code: string;
        name: string;
        description: string;
        seatId: null;
        entityType: "Dmp";
    };
    ctvPrice: number;
    digitalPrice: number;
    segmentMembershipCount: number;
    segmentStatus: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string;
    };
    hidden: boolean;
    expiration: number;
    code: string;
}

export interface Category extends HasIdName {
    id: number;
    parentName: string | null;
}

export type TimeZoneMode = HasIdName;

export interface Size extends HasIdName {
    code: string;
}

type ApiFramework = HasIdName;
export type MimeType = HasIdName;

// TODO: Update all unknown[] to actual types
export interface TargetingIncludeExclude {
    id?: number;
    audiences: (Audience | null)[] | null;
    supplyDomainTargets: string[] | null;
    customTargets: string[] | null;
    customRules: string[] | null;
    segmentRules: string[] | null; // JSON.stringify(SegmentRule)[]
    bundleIdTargets: string[] | null;
    pmpDealIdTargets: string[] | null;
    adBreakPositionTargets: number[] | null;
    podSlotPositionTargets: number[] | null;
    seats: unknown[] | null;
    publishers: Publisher[] | null;
    brands: Brand[] | null;
    supply: Supply[] | null;
    adUnits: AdUnit[] | null;
    segments: SeatSegment[] | null;
    targetedSegments: SeatSegment[] | null;
    oztamDemographics: OztamDemo[] | null;
    categories: Category[] | null;
    sizes: Size[] | null;
    operatingSystems: OperatingSystem[] | null;
    platforms: Platform[] | null;
    supplyTypes: SupplyType[] | null;
    mimeTypes: MimeType[] | null;
    accountTypes: unknown[] | null;
    apiFrameworks: ApiFramework[] | null;
    coppa: boolean[] | null;
    liveStream: boolean[] | null;
    dnt: boolean[] | null;
    geoTargets: GeoTarget[] | null;
    dayPartTargets: DayPartTarget[] | null;
    timeZoneMode: TimeZoneMode | null;
    minDurationTarget: MinDurationTarget[] | null;
    maxDurationTarget: MaxDurationTarget[] | null;
    contentCategories: ContentCategory[] | null;
    contentChannels: string[] | null;
    genres: string[] | null;
    contentLengths: ContentMetadataContentLength[] | null;
    contentNetworks: string[] | null;
    producers: string[] | null;
    contentRatings: string[] | null;
    contentSeries: ContentMetadataTvSeries[] | null;
    videoIds: ContentMetadataTitles[] | null;
    labelValues: LabelValue[] | null;
}

export type ITargetingIncludeExclude = Partial<TargetingIncludeExclude>;

export interface Targeting {
    id: number;
    name: string | null;
    creationDate?: string;
    updateDate?: string;
    include: TargetingIncludeExclude | null;
    exclude: TargetingIncludeExclude | null;
    seat?: {
        id: number;
        name: string;
        code: string;
        entityType: "Seat";
    } | null;
    marketplace?: null;
    isStub?: boolean;
}

export type TargetingCreateIncludeExclude = Partial<
    Omit<
        TargetingIncludeExclude,
        "minDurationTarget" | "maxDurationTarget" | "publishers" | "supply" | "brands" | "adUnits"
    > & {
        audienceIds: string[];
        minDurationTarget?: MinDurationTarget | MinDurationTarget[] | null;
        maxDurationTarget?: MaxDurationTarget | MaxDurationTarget[] | null;
        publishers?: { id: number; name: string }[] | null;
        supply?: { id: number; name: string }[] | null;
        brands?: { id: number; name: string }[] | null;
        adUnits?: { id: number; name: string }[] | null;
    }
>;

export interface TargetingCreatePayload {
    name: string;
    include: TargetingCreateIncludeExclude;
    exclude: TargetingCreateIncludeExclude;
}

export interface TargetingUpdatePayload extends TargetingCreatePayload {
    id: number;
    marketplace?: null; // TODO: Find targeting that has a marketplace attached
}
export interface SeatReusableTargetingListItem {
    id: number;
    name: string;
    seat: Entity | null;
    marketplace: null;
}

export enum TargetingReferenceEntityType {
    Adsource = "AdSource",
    LineItem = "LineItem",
    AdvancedFloor = "AdvancedFloor",
    AdvancedBrandSafety = "AdvancedBrandSafety",
    MarketplaceInfo = "MarketPlaceInfo",
}

export interface TargetingReferences extends Omit<SeatReusableTargetingListItem, "marketplace"> {
    entityType: TargetingReferenceEntityType;
}

interface GetSeatReusableTargetingParams {
    seatId: number;
    max?: number;
    page?: number;
    keyword?: string;
}

interface CreateSeatReusableTargetingParams {
    seatId: number;
    body: TargetingCreatePayload;
}

export interface UpdateSeatReusableTargetingParams {
    seatId: number;
    body: TargetingUpdatePayload;
}

export const targetingApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        createSeatReusableTargeting: builder.mutation<Targeting, CreateSeatReusableTargetingParams>({
            query: ({ seatId, body }: CreateSeatReusableTargetingParams) => ({
                url: `seats/${seatId}/reusableTargeting`,
                method: "POST",
                body,
            }),
            invalidatesTags: (_, err) => (err ? [] : [SEAT_REUSABLE_TARGETING_TAG]),
        }),
        updateSeatReusableTargeting: builder.mutation<Targeting, UpdateSeatReusableTargetingParams>({
            query: ({ seatId, body }: UpdateSeatReusableTargetingParams) => ({
                url: `seats/${seatId}/reusableTargeting/${body.id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: (_, err, { body }) =>
                err ? [] : [SEAT_REUSABLE_TARGETING_TAG, { type: SEAT_REUSABLE_TARGETING_BY_ID_TAG, id: body.id }],
        }),
        deleteSeatReusableTargeting: builder.mutation<void, number>({
            query: (id: number) => ({
                url: `seats/reusableTargeting/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err) => (err ? [] : [SEAT_REUSABLE_TARGETING_TAG]),
        }),
        getSeatReusableTargeting: builder.query<SeatReusableTargetingListItem[], GetSeatReusableTargetingParams>({
            query: ({ seatId, ...rest }: GetSeatReusableTargetingParams) => {
                const params: Omit<GetSeatReusableTargetingParams, "seatId"> = {
                    page: 1,
                    max: 100,
                    keyword: "",
                    ...rest,
                };

                return `seats/${seatId}/reusableTargeting;${toQueryString(params)}`;
            },
            providesTags: [SEAT_REUSABLE_TARGETING_TAG],
        }),
        getSeatReusableTargetingById: builder.query<Targeting, number>({
            query: (id: number) => {
                return `seats/reusableTargeting/${id}`;
            },
            providesTags: (_, __, id) => [{ type: SEAT_REUSABLE_TARGETING_BY_ID_TAG, id }],
            transformResponse: transformTargetingFromApi,
        }),
        getSeatReusableTargetingReferencesById: builder.query<TargetingReferences[], number>({
            query: (id: number) => {
                return `targeting/${id}/references`;
            },
            providesTags: (_, __, id) => [{ type: SEAT_REUSABLE_TARGETING_REFERENCES_BY_ID_TAG, id }],
        }),
    }),
    overrideExisting: false,
});

export const {
    useCreateSeatReusableTargetingMutation,
    useGetSeatReusableTargetingByIdQuery,
    useGetSeatReusableTargetingQuery,
    useLazyGetSeatReusableTargetingByIdQuery,
    useGetSeatReusableTargetingReferencesByIdQuery,
    useUpdateSeatReusableTargetingMutation,
    useDeleteSeatReusableTargetingMutation,
} = targetingApi;
