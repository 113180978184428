import { Typography } from "antd";
import { DollarOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { useAppSelector } from "@app/core/store";
import { useCurrencyConversion } from "@app/features/inventory/useCurrencyConversion";
import { CurrencyConversionModes } from "@app/core/clients/console";
import { gold6 } from "@rubicon/antd-components";

export const InventoryCurrencyMode = () => {
    const { inventoryCurrencyConversionMode, inventoryPreferredCurrency } = useAppSelector(
        (state) => state.inventoryHealth.settings.values
    );
    const { getCurrencyById } = useCurrencyConversion();

    const getLabel = () => {
        const currency = getCurrencyById(inventoryPreferredCurrency);
        if (!currency) {
            return "";
        }
        return `${inventoryCurrencyConversionMode === CurrencyConversionModes.CONVERT ? "Converted" : "Filtered"} to ${
            currency?.code
        }`;
    };

    return (
        <div
            className={css`
                display: flex;
                align-items: center;
                gap: 4px;
                font-size: 14px;
                color: ${gold6};
            `}
        >
            <DollarOutlined />
            <Typography.Text style={{ color: gold6 }}>{getLabel()}</Typography.Text>
        </div>
    );
};
