const NAME = "name";
const CODE = "code";
const DEAL_ENTITY_TYPE = "entityType";
const DEAL_ACTIONS = "actions";
const DEAL_STATUS_NAME = "statusName";
const DEAL_PRIORITY = "priority";
const DEAL_START_DATE = "startDate";

const PACING = "sortPacing";
const ALERT = "alert";
const TRIES = "tries";
const BIDS = "bids";
const BID_RATE = "bidRate";
const WINS = "wins";
const WIN_RATE = "winRate";
const IMPRESSIONS = "impressions";
const USE_RATE = "useRate";
const NET_REVENUE = "netRevenue";
const CPM_RATE = "cpmRate";

export const COLUMN_KEY = {
    NAME,
    ALERT,
    DEAL_ENTITY_TYPE,
    DEAL_PRIORITY,
    DEAL_START_DATE,
    CODE,
    PACING,
    TRIES,
    BIDS,
    BID_RATE,
    WINS,
    WIN_RATE,
    IMPRESSIONS,
    USE_RATE,
    NET_REVENUE,
    DEAL_STATUS_NAME,
    DEAL_ACTIONS,
    CPM_RATE,
} as const;
