import { Loading } from "@app/core/components";
import { SeatAdSourcesLivePerformanceChart } from "@app/features/seatAdSources/seatAdSourcesCharts";
import { Col, Empty, Row, Space } from "antd";
import { FC, useState } from "react";
import { PerformanceStatsTypeButtons } from "@app/features/inventory/InventoryHealthDetailsPages/SharedSections/PerformanceStatsTypeButtons";
import { AdSourcesPerformanceMetrics } from "../AdSourcesPerformanceMetrics";
import { useSeatAdSourcesPerformanceLiveStats } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/SeatAdSourcesDetailsDrawerContent/SeatAdSourcesDetailsDrawerView/secondaryViews/SeatAdSourcesDetailsPerformance/performanceViews/SeatAdSourcesPerformanceLiveStats/useSeatAdSourcesPerformanceLiveStats";

interface Props {
    adSourceId: number;
    adSourceTypeId: number;
    handlePerformanceTypeSwitch: (isLiveActive: boolean) => void;
}

export const LiveStats: FC<Props> = ({ adSourceId, adSourceTypeId, handlePerformanceTypeSwitch }) => {
    const [isExtended, setIsExtended] = useState(false);
    const { loadingState, adSourceStat, totalAdSourceStat } = useSeatAdSourcesPerformanceLiveStats(adSourceId);

    const handleExtend = () => {
        setIsExtended(!isExtended);
    };

    return (
        <Space direction="vertical" size={50} style={{ display: "flex" }}>
            <Row gutter={[16, 16]}>
                <Col span={isExtended ? 12 : 6}>
                    <AdSourcesPerformanceMetrics
                        handleExtend={handleExtend}
                        isExtended={isExtended}
                        adSourceTypeId={adSourceTypeId}
                        adSourceStat={totalAdSourceStat}
                        hasLoadedAdStat={loadingState}
                    />
                </Col>
                <Col span={isExtended ? 12 : 18}>
                    <Row
                        justify={"space-between"}
                        style={{
                            margin: "0 0 16px 0",
                            padding: "0 16px",
                        }}
                    >
                        <PerformanceStatsTypeButtons handlePerformanceTypeSwitch={handlePerformanceTypeSwitch} />
                    </Row>
                    {adSourceStat && <SeatAdSourcesLivePerformanceChart adSourceStat={adSourceStat} />}
                    {loadingState === "loading" && <Loading position="relative" />}
                    {loadingState === "loadedNoData" && (
                        <Empty
                            style={{
                                height: "75%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                    )}
                </Col>
            </Row>
        </Space>
    );
};
