import { FC } from "react";
import { Form, Input } from "antd";
import { Seat } from "@app/core/services";
import { SeeMoreUnorderedList } from "@app/features/inventory/SeeMoreUnorderedList";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

const { TextArea } = Input;

interface BlockedAdvertiserDomainsProps {
    seat: Seat;
    edit?: boolean;
}

export const BlockedAdvertiserDomains: FC<BlockedAdvertiserDomainsProps> = ({ edit = false, seat }) => {
    return (
        <Form.Item
            name={INVENTORY_FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name}
            label={INVENTORY_FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.label}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 }}
            extra={edit ? "Enter one domain per line" : undefined}
        >
            {edit ? (
                <TextArea
                    rows={4}
                    allowClear
                    placeholder={INVENTORY_FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.placeholder}
                />
            ) : (
                <SeeMoreUnorderedList values={seat.advertiserBlockDomains} />
            )}
        </Form.Item>
    );
};
