import { useContext } from "react";
import { FreqCappingType, useGetFreqCappingTypesQuery } from "@app/core/services";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { AdSourceFormsContext } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { FREQUENCY_CAPPING_OFF_ID } from "@app/features/deals/constants";
import { DEAL_FORM_FIELDS } from "../../../../../constants";

export const useFrequencyCappingSelect = () => {
    const { marketplaceInfoMode, dealObjectMode } = useMarketplaceInfoWidget();
    const isDisabled = dealObjectMode === "edit";
    const isReadonly = marketplaceInfoMode.name === "view";
    const { data: freqCappingTypes = [], isFetching } = useGetFreqCappingTypesQuery(undefined, {
        skip: isReadonly || isDisabled,
    });
    const { demandConfigurationForm } = useContext(AdSourceFormsContext).forms;

    const onChange = (value: FreqCappingType) => {
        if (value.id === FREQUENCY_CAPPING_OFF_ID) {
            // Clear frequency capping settings when switched off
            demandConfigurationForm.setFieldValue(DEAL_FORM_FIELDS.DEAL_FREQ_CAPPINGS.name, []);
        }
    };

    return {
        freqCappingTypes,
        isLoading: isFetching,
        isReadonly,
        isDisabled,
        onChange,
    };
};
