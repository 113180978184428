import { Currency, useGetCurrenciesQuery } from "@app/core/services";
import { useIsFieldVisible } from "./useIsFieldVisible";
import { useIsFieldReadOnly } from "./useIsFieldReadOnly";
import { useIsRequiredValidation } from "./useIsRequiredValidation";
import { useSeatAuthContext } from "@app/core/auth";
import { useCallback, useContext, useEffect } from "react";
import { AdSourceFormsContext } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { Form } from "antd";
import { useSyncedFieldsProducer } from "@app/features/syncedFields";

const { useFormInstance } = Form;

export const useCurrencySelect = () => {
    const { setFieldsValue } = useFormInstance();

    const isVisible = useIsFieldVisible();
    const isReadOnly = useIsFieldReadOnly();
    const isRequiredRule = useIsRequiredValidation();
    const { data: currencies, isLoading } = useGetCurrenciesQuery(undefined, { skip: !isVisible });

    const { context } = useSeatAuthContext();
    const {
        forms: { adSourceFormMode },
    } = useContext(AdSourceFormsContext);

    useEffect(() => {
        if (context && context?.floorCurrency && adSourceFormMode === "create") {
            setFieldsValue({ [AD_SOURCE_FIELDS.CURRENCY.name]: context.floorCurrency });
        }
    }, [adSourceFormMode, context, setFieldsValue]);

    const { updateSyncedFields } = useSyncedFieldsProducer();
    const onChange = useCallback(
        (value: Currency) => {
            updateSyncedFields({ currency: value });
        },
        [updateSyncedFields]
    );

    return { currencies, isLoading, isVisible, isReadOnly, isRequiredRule, onChange };
};
