import { FixedSaveButtons, SectionTitle } from "@app/core/components";
import { HelpKeysButton } from "@app/core/components/HelpKeysDrawer";
import { TargetingReferences } from "@app/core/services";
import { css } from "@emotion/css";
import { Card, Col, Collapse, Form, Input, Row, Typography } from "antd";
import { FC, ReactNode } from "react";
import { TargetingReferencesTable } from "../TargetingReferencesTable";
import { TargetingFormValues, TARGETING_FORM_ITEMS, TARGETING_SECTIONS_NAME } from "./constants";

const MAX_NAME_LENGTH = 70;

interface Props {
    initialValues: TargetingFormValues;
    references?: TargetingReferences[];
    handleDefinition: (type: string) => void;
    submitLabel?: string;
    deleteButton?: ReactNode;
    isSubmitting?: boolean;
    onFinish: (values: TargetingFormValues) => Promise<void>;
    onCancel: () => void;
}
export const TargetingForm: FC<Props> = ({
    initialValues,
    references,
    handleDefinition,
    onFinish,
    isSubmitting,
    deleteButton,
    submitLabel,
    onCancel,
    children,
}) => {
    const [form] = Form.useForm();

    return (
        <Form form={form} initialValues={initialValues} onFinish={onFinish} layout="vertical">
            <Collapse
                style={{ minHeight: "77vh" }}
                defaultActiveKey={["1", "2", "3"]}
                items={[
                    {
                        key: "1",
                        label: <SectionTitle title={TARGETING_SECTIONS_NAME.GENERAL} />,
                        collapsible: "header",
                        children: (
                            <Card
                                bordered={false}
                                className={css`
                                    &.ant-card {
                                        box-shadow: none;
                                    }
                                `}
                                styles={{ body: { paddingTop: 0, paddingBottom: 0 } }}
                            >
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            name={TARGETING_FORM_ITEMS[TARGETING_SECTIONS_NAME.GENERAL].PARENT.name}
                                            label={TARGETING_FORM_ITEMS[TARGETING_SECTIONS_NAME.GENERAL].PARENT.label}
                                        >
                                            <Typography.Text>{initialValues.parent}</Typography.Text>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name={TARGETING_FORM_ITEMS[TARGETING_SECTIONS_NAME.GENERAL].NAME.name}
                                            label={TARGETING_FORM_ITEMS[TARGETING_SECTIONS_NAME.GENERAL].NAME.label}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                },
                                                {
                                                    max: MAX_NAME_LENGTH,
                                                    message: `Name cannot exceed ${MAX_NAME_LENGTH} characters`,
                                                },
                                            ]}
                                            wrapperCol={{ span: 6 }}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        ),
                        extra: (
                            <HelpKeysButton
                                onClick={() => handleDefinition(TARGETING_SECTIONS_NAME.GENERAL)}
                                buttonName={TARGETING_SECTIONS_NAME.GENERAL}
                            />
                        ),
                    },
                    {
                        key: "2",
                        collapsible: "header",
                        label: <SectionTitle title={TARGETING_SECTIONS_NAME.TARGETING} />,
                        children: children,
                        extra: (
                            <HelpKeysButton
                                onClick={() => handleDefinition(TARGETING_SECTIONS_NAME.TARGETING)}
                                buttonName={TARGETING_SECTIONS_NAME.TARGETING}
                            />
                        ),
                    },
                    {
                        key: "3",
                        collapsible: "header",
                        label: <SectionTitle title={TARGETING_SECTIONS_NAME.USED_IN} />,
                        children: <TargetingReferencesTable references={references} />,
                        extra: (
                            <HelpKeysButton
                                onClick={() => handleDefinition(TARGETING_SECTIONS_NAME.USED_IN)}
                                buttonName={TARGETING_SECTIONS_NAME.USED_IN}
                            />
                        ),
                    },
                ]}
            />

            <FixedSaveButtons
                isLoading={isSubmitting}
                submitLabel={submitLabel}
                suffixAddOn={deleteButton}
                onCancel={onCancel}
            />
        </Form>
    );
};
