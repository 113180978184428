import { useLocation, useParams } from "react-router-dom";
import { SeatAdSourcesDetailsDrawerSecondaryHeader } from "./SeatAdSourcesDetailsDrawerTitleSwitch";

interface UseSeatAdSourcesDetailsDrawerTitle {
    title: SeatAdSourcesDetailsDrawerSecondaryHeader | null;
}

export const useSeatAdSourcesDetailsDrawerTitle = (): UseSeatAdSourcesDetailsDrawerTitle => {
    const { pathname } = useLocation();
    const { secondaryView } = useParams<{ secondaryView: string }>();

    const title = secondaryView
        ? pathname.includes("details-drawer") || pathname.includes("ad-source-details")
            ? SeatAdSourcesDetailsDrawerSecondaryHeader.NestedHeader
            : SeatAdSourcesDetailsDrawerSecondaryHeader.DirectHeader
        : null;
    return {
        title,
    };
};
