import { FC, useState } from "react";
import { Form, Modal, Select } from "antd";
import { BulkOperationType } from "@app/core/services";
import { BULK_OPERATION_FORM_ITEMS, getEntityTypeOptions } from "../../../constants";
import { useDispatch } from "react-redux";
import { resetBulkOperationSliceState, selectHasTableSelection } from "../../../reducer";
import { useSelector } from "react-redux";

interface Props {
    hasFullOptionsAccess: boolean;
}

const initialModalInfo = { isOpen: false, value: null };
export const BulkOperationEntityTypeField: FC<Props> = ({ hasFullOptionsAccess }) => {
    const dispatch = useDispatch();
    const hasTableSelection = useSelector(selectHasTableSelection);

    const { useFormInstance, useWatch } = Form;
    const form = useFormInstance();
    const type = useWatch(BULK_OPERATION_FORM_ITEMS.OPERATION_TYPE.name);
    const entityType = useWatch(BULK_OPERATION_FORM_ITEMS.ENTITY_TYPE.name);
    const sourceEntity = useWatch(BULK_OPERATION_FORM_ITEMS.SOURCE_ENTITY.name);

    const [info, setInfo] = useState<{ isOpen: boolean; value: number | null } | null>(initialModalInfo);

    const switchType = (value) => {
        form.resetFields();
        const fieldNamePath =
            type === BulkOperationType.Edit
                ? BULK_OPERATION_FORM_ITEMS.ENTITY_TYPE.name
                : BULK_OPERATION_FORM_ITEMS.SOURCE_ENTITY.name;
        form.setFieldsValue({ [fieldNamePath]: value, type });
        // reset filters after entityType field change, since filters initialValues depends on it
        form.resetFields(["filters"]);
        dispatch(resetBulkOperationSliceState());
    };

    const getHasCopyFieldsChange = () => {
        const { destinationEntityId, destinationEntityName, sourceEntityId } = form.getFieldsValue(true);

        return Boolean(destinationEntityId || destinationEntityName || sourceEntityId);
    };

    const handleSelectChange = (value) => {
        if ((entityType && hasTableSelection) || (sourceEntity && getHasCopyFieldsChange())) {
            setInfo({ isOpen: true, value });
        } else {
            switchType(value);
        }
    };

    const handleClose = () => setInfo(initialModalInfo);

    const handleConfirm = () => {
        switchType(info?.value);
        handleClose();
    };

    return (
        <>
            <Form.Item
                label={BULK_OPERATION_FORM_ITEMS.ENTITY_TYPE.label}
                name={
                    type === BulkOperationType.Edit
                        ? BULK_OPERATION_FORM_ITEMS.ENTITY_TYPE.name
                        : BULK_OPERATION_FORM_ITEMS.SOURCE_ENTITY.name
                }
                rules={[{ required: true, message: "Please select an Entity Type" }]}
                trigger="handleSelectChange"
                wrapperCol={{ xs: 24, md: 12 }}
            >
                <Select
                    showSearch
                    data-sdet="entity-type"
                    optionFilterProp="label"
                    onChange={(v) => handleSelectChange(v)}
                    options={getEntityTypeOptions(type, hasFullOptionsAccess)}
                    placeholder="Select…"
                />
            </Form.Item>
            <Modal
                data-sdet="entity-type-switch-modal"
                title="Confirmation"
                okText="Yes"
                open={info?.isOpen}
                onCancel={handleClose}
                onOk={handleConfirm}
            >
                Are you sure you want to switch entity type? All your changes will be cleared up.
            </Modal>
        </>
    );
};
