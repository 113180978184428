import { FC } from "react";
import { LoadableComponent } from "@app/core/components";
import { FunnelChart } from "@app/features/seatAdSources/seatAdSourcesCharts/charts";
import { useSeatAdSourcesAdCandidateSelectionChart } from "./useSeatAdSourcesAdCandidateSelectionChart";

interface Props {
    adSourceId: number;
}

export const SeatAdSourcesAdCandidateSelectionChart: FC<Props> = ({ adSourceId }) => {
    const { loadingState, funnelChartProps } = useSeatAdSourcesAdCandidateSelectionChart(adSourceId);

    return (
        <LoadableComponent loadingState={loadingState}>
            {funnelChartProps && <FunnelChart {...funnelChartProps} />}
        </LoadableComponent>
    );
};
