import { FC } from "react";
import { Form, InputNumber } from "antd";
import { ColProps } from "antd/lib/grid/col";
import { InputNumberProps } from "antd/lib/input-number";
import { ADDITIONAL_TIME } from "@app/features/inventory/HierarchyForms/constants";

interface AdUnitAdditionalTimeProps extends InputNumberProps {
    name: typeof ADDITIONAL_TIME;
    wrapperCol?: ColProps;
    labelCol?: ColProps;
    label: string;
}

export const AdUnitAdditionalTime: FC<AdUnitAdditionalTimeProps> = ({ name, label, labelCol, wrapperCol, ...rest }) => {
    return (
        <Form.Item
            name={name}
            label={label}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            rules={[
                {
                    required: true,
                    validator(_, value) {
                        if (!value) return Promise.reject(new Error("Additional Time in seconds must be defined"));
                        if (value < 0) return Promise.reject(new Error("Additional Time must be above zero"));
                        return Promise.resolve();
                    },
                },
            ]}
        >
            <InputNumber style={{ width: "100%" }} {...rest} />
        </Form.Item>
    );
};
