import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { DualAxesConfig } from "@ant-design/plots";
import { TimingStatsChartType } from "../useTimingStats";
import { useTimingStatsChartConfig } from "../useTimingStatsChartConfig";
import { useGetTimingStatsAdUnitSnapshotQuery } from "@app/core/services";
import { useInventoryDetailsDrawerUrlId } from "@app/features/inventory/DetailsDrawer/useInventoryDetailsDrawerUrlId";

interface UseSnapshot {
    config: DualAxesConfig;
    hasSelectedRegion: boolean;
}
export const useSnapshot = (
    chartType: TimingStatsChartType,
    region: LabeledValue,
    multiplicityId: number,
    snapshotPath: string
): UseSnapshot => {
    const id = useInventoryDetailsDrawerUrlId();
    const [snapshotId] = (snapshotPath.split("/").at(-1) || "").split(".");
    const { data } = useGetTimingStatsAdUnitSnapshotQuery({ id, snapshotId, multiplicity: multiplicityId });
    const hasSelectedRegion = useMemo(() => region.value in (data?.data || {}), [data, region.value]);

    const { config } = useTimingStatsChartConfig(chartType, region, data);
    return { config, hasSelectedRegion };
};
