import { useState } from "react";

interface UseManageInventoryColumnsDrawer {
    isDrawerOpen: boolean;
    handleOpenManageColumnsDrawer: () => void;
    handleCloseManageColumnsDrawer: () => void;
}

export const useManageInventoryColumnsDrawer = (): UseManageInventoryColumnsDrawer => {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

    const handleOpenManageColumnsDrawer = () => setIsDrawerOpen(true);
    const handleCloseManageColumnsDrawer = () => setIsDrawerOpen(false);

    return {
        isDrawerOpen,
        handleOpenManageColumnsDrawer,
        handleCloseManageColumnsDrawer,
    };
};
