import styled from "@emotion/styled";
import { brandAmethyst } from "@rubicon/antd-components";
import { css } from "@emotion/react";

const dynamicBackgroundColor = ({ backgroundColor = brandAmethyst }: { backgroundColor?: string }) => css`
    background-color: ${backgroundColor};
`;

export const NavGroup = styled.div`
    display: flex;
    align-items: center;
    padding: 0;
    flex-grow: 0;
    flex-direction: column;
    position: relative;
    z-index: 1000;
    overflow-y: auto;
    overflow-x: clip;
    ${dynamicBackgroundColor};
    scrollbar-gutter: stable both-edges;
    ::-webkit-scrollbar {
        width: 4px;
    }
    ::-webkit-scrollbar:hover {
        background-color: rgba(0, 0, 0, 0.09);
    }
    ::-webkit-scrollbar-thumb:vertical {
        background: rgba(53, 33, 110, 0.4);
    }
`;
