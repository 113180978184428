import { useParams } from "react-router-dom";
import { ExternalUser, useGetSeatExternalUsersQuery } from "@app/core/services";

interface UseAssigneeSelect {
    externalUsers: ExternalUser[] | undefined;
    isLoading: boolean;
}

export const useAssigneeSelect = (): UseAssigneeSelect => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data: externalUsers, isLoading } = useGetSeatExternalUsersQuery(Number(seatId));

    return { externalUsers, isLoading };
};
