import { FC } from "react";
import { Form, Radio } from "antd";
import { INVENTORY_YES_NO_OPTIONS, AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const AllowDemandUnderInboundFloor: FC = () => {
    return (
        <Form.Item
            name={AD_UNIT_FORM_FIELDS.ALLOW_DEMAND_UNDER_INBOUND_FLOOR.name}
            label={AD_UNIT_FORM_FIELDS.ALLOW_DEMAND_UNDER_INBOUND_FLOOR.label}
        >
            <Radio.Group options={INVENTORY_YES_NO_OPTIONS} />
        </Form.Item>
    );
};
