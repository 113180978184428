import { FC } from "react";
import { CollapsePanelProps, Typography } from "antd";
import { ExpandCollapse } from "@rubicon/antd-components";
import { MinDurationTarget } from "../types";

export interface ReadOnlyMinDurationTargetingPanelProps extends CollapsePanelProps {
    targets: MinDurationTarget[];
}

export const ReadOnlyMinDurationTargetingPanel: FC<ReadOnlyMinDurationTargetingPanelProps> = ({ targets, ...rest }) => {
    if (!targets.length) {
        return null;
    }
    const [target] = targets;

    return (
        <ExpandCollapse.Panel {...rest}>
            <Typography.Text>
                {target.operator?.name} {target.minDuration}
            </Typography.Text>
        </ExpandCollapse.Panel>
    );
};
