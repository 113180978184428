import { useContext, useState } from "react";
import {
    getPacingTypeHelpText,
    isPacingTypeEligible,
    isPacingTypeReadonly,
} from "@app/features/seatAdSources/SeatAdSourcesForm/utils/pacingUtils";
import { AdSourceFormsContext } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { PacingTypeOption } from "./types";
import { usePacingFormData } from "../usePacingFormData";

interface UsePacingTypeSelect {
    isReadonly: boolean;
    isVisible: boolean;
    helpText: string | undefined;
    onChange: (option: PacingTypeOption) => void;
}

export const usePacingTypeSelect = (): UsePacingTypeSelect => {
    const {
        forms: { adSourceFormMode },
    } = useContext(AdSourceFormsContext);
    const { adSourceTypeId, pacingTypeId: selectedPacingTypeId } = usePacingFormData();
    const [hasChanged, setHasChanged] = useState(false);

    const isReadonly = isPacingTypeReadonly(adSourceFormMode, selectedPacingTypeId, hasChanged);

    const isVisible = isPacingTypeEligible(adSourceTypeId);

    const helpText = getPacingTypeHelpText(selectedPacingTypeId);

    const onChange = () => {
        setHasChanged(true);
    };

    return {
        isReadonly,
        isVisible,
        helpText,
        onChange,
    };
};
