import { Button, Col, Form, Row, Select, Tag, Typography } from "antd";
import { CloseOutlined, FilterFilled } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import { useAdSourceLabel } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceLabelsSection/Fields/AdSourceLabel/useAdSourceLabel";
import { blue6, gray7 } from "@rubicon/antd-components";

export const AdSourceLabel = () => {
    const {
        labels,
        labelId,
        valueId,
        labelOptions,
        valueOptions,
        isLoadingLabel,
        isLoadingValue,
        handleChangeLabel,
        handleChangeValue,
        handleAddLabelValue,
        handleRemoveLabelValue,
    } = useAdSourceLabel();
    return (
        <Form.Item label="Label" data-sdet="ad-source-label">
            <Row style={{ width: "100%" }} gutter={[6, 6]}>
                <Col span={4}>
                    <Form.Item style={{ marginBottom: "0.5rem" }} data-sdet="label">
                        <Select
                            value={labelId}
                            placeholder="Label"
                            style={{ width: "100%" }}
                            onChange={handleChangeLabel}
                            loading={isLoadingLabel}
                            options={labelOptions}
                        />
                    </Form.Item>
                    <Typography.Text style={{ fontSize: "12px", color: gray7 }}>
                        <FilterFilled /> Waterfall Pre-Filter labels
                    </Typography.Text>
                </Col>
                <Col>
                    <Typography.Text strong style={{ lineHeight: "32px" }}>
                        :
                    </Typography.Text>
                </Col>
                <Col span={4} data-sdet="value">
                    <Form.Item noStyle>
                        <Select
                            disabled={!labelId}
                            value={valueId}
                            placeholder="Value"
                            loading={isLoadingValue}
                            onChange={handleChangeValue}
                            options={valueOptions}
                        />
                    </Form.Item>
                </Col>
                <Col>
                    <Button
                        disabled={!labelId || !valueId}
                        onClick={handleAddLabelValue}
                        style={{ paddingLeft: "0.2rem" }}
                        block
                        type="link"
                        icon={<PlusOutlined />}
                        data-sdet="add-label"
                    >
                        Add Label
                    </Button>
                </Col>
                {Boolean(labels?.length) && (
                    <Col span={24} data-sdet="ad-source-label-values">
                        {labels.map(({ label, value }) => (
                            <Tag
                                key={value}
                                color="processing"
                                closable
                                closeIcon={<CloseOutlined style={{ color: blue6 }} />}
                                onClose={() => handleRemoveLabelValue(Number(value))}
                                style={{ marginRight: 3 }}
                            >
                                {label}
                            </Tag>
                        ))}
                    </Col>
                )}
            </Row>
        </Form.Item>
    );
};
