import { FC, ReactNode, CSSProperties } from "react";
import { HolderOutlined } from "@ant-design/icons";
import { Typography, Checkbox, Space, Flex } from "antd";
import { DragDropContext, Droppable, Draggable, DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd";
import { COLUMN_KEY } from "@app/features/deals/DealListPage/constants";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { gray2, gray4 } from "@rubicon/antd-components";

export interface ManagedColumn {
    dataIndex: string;
    title: ReactNode;
    visible: boolean;
    selectable: boolean;
    /**
     * String representation of the column header, shown when
     * managing the selection and ordering of columns.
     */
    managedColumnTitle?: string;
}

interface ManagedColumnProps {
    columns: ManagedColumn[];
    onChange: (newItems: ManagedColumn[]) => void;
}

const grid = 2;
const listBackgroundColor = gray2;
const itemColor = gray4;

const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
): CSSProperties => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? itemColor : "transparent",
    ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? listBackgroundColor : "transparent",
    padding: grid,
    width: "100%",
});

const reorder = (list: ManagedColumn[], movedFromIndex: number, movedToIndex: number) => {
    const result = Array.from(list);
    // If 'name' column exists, it must be leftmost/first column; start and end column cannot be index 0
    if (list.some((l) => l.dataIndex === COLUMN_KEY.NAME)) {
        movedFromIndex = movedFromIndex + 1;
        movedToIndex = movedToIndex + 1;
    }
    const [removed] = result.splice(movedFromIndex, 1);
    result.splice(movedToIndex, 0, removed);
    return result;
};

export const ManageColumns: FC<ManagedColumnProps> = ({ columns, onChange }) => {
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const newItems = reorder(columns, result.source.index, result.destination.index);
        onChange(newItems);
    };

    const handleChecked = (event: CheckboxChangeEvent) => {
        const key = event.target.value;
        const value = event.target.checked;
        const targetIndex = columns.findIndex((el) => el.dataIndex === key);
        const newItems = [...columns];
        newItems[targetIndex] = { ...columns[targetIndex], visible: value };
        onChange(newItems);
    };

    return (
        <Flex vertical>
            <Typography.Text style={{ fontWeight: "bold" }}>Column options:</Typography.Text>
            <Typography.Paragraph>
                Select/De-select metrics to show and hide columns in table. Drag <HolderOutlined /> (drag icon) up and
                down on a metric to order your columns in the table.
            </Typography.Paragraph>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {columns
                                ?.filter((c) => c.selectable)
                                .map((item, index) => {
                                    return (
                                        <Draggable key={item.dataIndex} draggableId={item.dataIndex} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <Space>
                                                        <HolderOutlined />
                                                        <Checkbox
                                                            checked={item.visible}
                                                            value={item.dataIndex}
                                                            onChange={handleChecked}
                                                        />
                                                        {item.managedColumnTitle ?? item.title}
                                                    </Space>
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Flex>
    );
};
