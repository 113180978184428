import { FC } from "react";
import { FilterSelect } from "@app/core/components/FilterSelect";
import { useDealListPriorityFilter } from "@app/features/deals/DealListPage/DealListTableFilters/DealListPriorityFilter/useDealListPriorityFilter";
import { HelpTooltip } from "@app/core/components";
import { DealsHelpKeys } from "@app/features/deals/constants";

export const DealListPriorityFilter: FC = () => {
    const { handleChange, options, value, isLoading } = useDealListPriorityFilter();

    return (
        <FilterSelect
            label={
                <HelpTooltip
                    helpKeyList={DealsHelpKeys}
                    helpKey={DealsHelpKeys.DealDetailsPriority}
                    tooltipPlacement="topRight"
                    popover={true}
                >
                    Priority
                </HelpTooltip>
            }
            onChange={handleChange}
            isLoading={isLoading}
            options={options}
            value={value}
            dataSdet="deal-list-priority-type-filter"
            mode="multiple"
            placeholder="All"
        />
    );
};
