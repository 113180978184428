import { AD_SOURCE_FIELDS, COL_SIZES, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { Col, Form, Radio } from "antd";
import { useIsFieldVisible } from "./useIsFieldVisible";

export const VAST_DYNAMIC_PRICING_LOCATIONS = {
    STANDARD_PRICING_ELEMENTS: { id: 1, name: "Standard Pricing Element" },
    NON_STANDARD_PRICING_ELEMENTS: { id: 2, name: "Non-Standard Pricing Element" },
};
export const VastDynamicPricingLocationRadio = () => {
    const isVisible = useIsFieldVisible();
    if (!isVisible) {
        return null;
    }
    return (
        <Col {...COL_SIZES} xl={24} lg={24} md={24}>
            <Form.Item
                name={AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_LOCATION.name}
                label={AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_LOCATION.label}
                data-sdet={`test-${AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_LOCATION.name}-field`}
                {...VERTICAL_LAYOUT_FIX}
            >
                <Radio.Group data-sdet={`test-${AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_LOCATION.name}-radio-group`}>
                    <Radio
                        value={VAST_DYNAMIC_PRICING_LOCATIONS.STANDARD_PRICING_ELEMENTS.id}
                        data-sdet={`test-${AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_LOCATION.name}-1`}
                    >
                        {VAST_DYNAMIC_PRICING_LOCATIONS.STANDARD_PRICING_ELEMENTS.name}
                    </Radio>
                    <Radio
                        value={VAST_DYNAMIC_PRICING_LOCATIONS.NON_STANDARD_PRICING_ELEMENTS.id}
                        data-sdet={`test-${AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_LOCATION.name}-2`}
                    >
                        {VAST_DYNAMIC_PRICING_LOCATIONS.NON_STANDARD_PRICING_ELEMENTS.name}
                    </Radio>
                </Radio.Group>
            </Form.Item>
        </Col>
    );
};
