import { FC } from "react";
import { Col, Row, Space, Typography } from "antd";
import { Conditions, TargetingDimensionTypeLabels, TargetingSupplyLabels } from "../../constants";
import { CoppaTargetingDimension, UsedDimensions } from "../../types";
import { ConditionSelect } from "../ConditionSelect";
import { MoreMenu } from "../MoreMenu";

interface Props {
    dimension: CoppaTargetingDimension;
    onChangeCondition: (value: Conditions) => void;
    onChangeDimensionValues: (value: boolean[]) => void;
    onClickDelete: () => void;
    onClickDownload: () => void;
    onClickDuplicate: () => void;
    usedDimensions: UsedDimensions;
}

export const CoppaDimensionRow: FC<Props> = ({
    dimension,
    onChangeCondition,
    onClickDelete,
    onClickDownload,
    onClickDuplicate,
    usedDimensions,
}) => {
    return (
        <Row gutter={8}>
            <Col flex="150px">
                <Typography.Text strong>{TargetingDimensionTypeLabels.Coppa}</Typography.Text>
            </Col>
            <Col flex="128px">
                <ConditionSelect onChange={onChangeCondition} value={dimension.condition} />
            </Col>
            <Col flex="1" style={{ alignSelf: "center" }}>
                <Typography.Text>{TargetingSupplyLabels.Coppa}</Typography.Text>
            </Col>
            <Col flex="146px" style={{ display: "flex", justifyContent: "flex-end" }}>
                <Space>
                    <MoreMenu
                        onClickDelete={onClickDelete}
                        onClickDownload={onClickDownload}
                        onClickDuplicate={onClickDuplicate}
                        isDuplicateDisabled={usedDimensions.coppa.includes || usedDimensions.coppa.excludes}
                    />
                </Space>
            </Col>
        </Row>
    );
};
