import { Layout } from "antd";
import { Footer } from "@app/core/components";
import InventoryPermissionsToolbar from "@app/features/inventory/InventoryPermissions/InventoryPermissionsToolbar";
import { InventroyPermissionsTable } from "./InventoryPermissionsTable";

export const InventoryPermissions = () => {
    return (
        <>
            <InventoryPermissionsToolbar />
            <Layout.Content>
                <InventroyPermissionsTable />
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
