import { FC } from "react";
import { Collapse, Col, Form, Row, Input, Affix, Space, Button, Card } from "antd";
import { DealList } from "@app/core/services/console";
import { DEAL_SECTIONS_NAME } from "../../constants";
import { DealObjectSidePanel } from "../DefinitionSidePanels";
import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED } from "@app/core/components/constants";
import { useDealFormLegacy, FIELDS } from "./useDealFormLegacy";
import { StatusField } from "./StatusField";
import { DealOriginField } from "./DealOriginField";
import moment from "moment-timezone";
import { mainStepSectionClassList } from "../DealFormDemand";
import { classNameDealDetailsCardBody, PanelHeader } from "../../DealDetailsPage/BuyerDealDetailsForm";

interface Props {
    dealAdSource: DealList;
}

export const DealFormLegacy: FC<Props> = ({ dealAdSource }) => {
    const { form, initialValues, onSubmit, onCancel, isUpdating, hasInternalAccess } = useDealFormLegacy(dealAdSource);

    return (
        <>
            <Form
                layout="vertical"
                form={form}
                initialValues={initialValues}
                style={{ marginTop: "1rem", height: "80vh" }}
            >
                <Collapse defaultActiveKey={[1]} bordered ghost={false} collapsible="header">
                    <Collapse.Panel
                        key="1"
                        header={<PanelHeader title={DEAL_SECTIONS_NAME.DEAL_TERMS} />}
                        extra={<DealObjectSidePanel label={DEAL_SECTIONS_NAME.DEAL_TERMS} />}
                        className={mainStepSectionClassList}
                    >
                        <Card bordered={false} className={classNameDealDetailsCardBody}>
                            <Row gutter={[16, 16]}>
                                <Col xs={8}>
                                    <Form.Item label="Deal ID" name={FIELDS.id} required>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={8}>
                                    <Form.Item label="Name" name={FIELDS.name} required>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col xs={8}>
                                    <Form.Item label="Status" name={FIELDS.status}>
                                        <StatusField />
                                    </Form.Item>
                                </Col>
                                {hasInternalAccess && (
                                    <Col xs={8}>
                                        <Form.Item label="Deal Origin" name={FIELDS.dealOrigin}>
                                            <DealOriginField />
                                        </Form.Item>
                                    </Col>
                                )}
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col xs={8}>
                                    <Form.Item label="Last Updated (UTC)">
                                        {moment(dealAdSource.deal.updateDate).format(
                                            MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={8}>
                                    <Form.Item label="Date Created">
                                        {moment(dealAdSource.deal.creationDate).format(
                                            MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Collapse.Panel>
                </Collapse>
            </Form>
            <Affix offsetBottom={46}>
                <Card styles={{ body: { padding: "0.75rem 1rem" } }} style={{ marginTop: "3rem" }}>
                    <Space>
                        <Button type="primary" onClick={onSubmit} loading={isUpdating}>
                            Submit
                        </Button>
                        <Button onClick={onCancel}>Cancel</Button>
                    </Space>
                </Card>
            </Affix>
        </>
    );
};
