import { Form } from "antd";
import { FC } from "react";
import { Labels } from "@app/features/inventory/components/Fields/Labels";
import { BRAND_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

interface BrandLabelsProps {
    name:
        | typeof BRAND_FORM_FIELDS.LABELS.name
        | typeof BRAND_FORM_FIELDS.INTERNAL_LABELS.name
        | typeof BRAND_FORM_FIELDS.DISTRIBUTION_GROUP_LABELS.name;
    label:
        | typeof BRAND_FORM_FIELDS.LABELS.label
        | typeof BRAND_FORM_FIELDS.INTERNAL_LABELS.label
        | typeof BRAND_FORM_FIELDS.DISTRIBUTION_GROUP_LABELS.label;
}

export const BrandLabels: FC<BrandLabelsProps> = ({ name, label }) => {
    return (
        <Form.Item label={label} wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 }}>
            <Labels name={name} />
        </Form.Item>
    );
};
