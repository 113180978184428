import { FC } from "react";
import { Title } from "@app/core/components";
import { Button, Collapse, Space } from "antd";
import { ItemType } from "rc-collapse/es/interface";
import {
    INVENTORY_NESTED_SECTION_CLASSLIST,
    INVENTORY_SECTION_HEADER_COLLAPSE_STYLE,
} from "@app/features/inventory/InventorySeat/constants";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import {
    AdPodSection,
    AdBreakRules,
    LabelsSection,
    FloorsSection,
    DefaultsSection,
    BrandSafetySection,
    CustomPixelsSection,
    DetailedConfigurationSection,
    PlaylistConfigurationSection,
} from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormSections/AdvancedFeaturesSection/Sections";
import { useBrandChannelFormDefinitionsFields } from "@app/features/inventory/HierarchyForms/BrandChannelForm/useBrandChannelFormDefinitionsFields";
import { useAdvancedFeaturesSection } from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormSections/AdvancedFeaturesSection/useAdvancedFeaturesSection";

export const AdvancedFeaturesSection: FC = () => {
    const {
        isFloorsSectionShown,
        isPlaylistSectionShown,
        isPodSectionShown,
        isAdBreakRulesSectionShown,
        hasSeatWriteAccess,
        handAdvancedFloors,
    } = useAdvancedFeaturesSection();
    const {
        defaultsDefinitionsFields,
        adPodDefinitionsFields,
        playlistConfigurationDefinitionsFields,
        brandSafetyDefinitionsFields,
        detailConfigurationDefinitionsFields,
        customPixelsDefinitionsFields,
        labelsDefinitionsFields,
    } = useBrandChannelFormDefinitionsFields();
    return (
        <Collapse
            destroyInactivePanel
            defaultActiveKey={["0", "1", "2", "3", "4", "5", "6", "7", "8"]}
            className={INVENTORY_NESTED_SECTION_CLASSLIST}
            collapsible="header"
            items={
                [
                    {
                        key: "0",
                        children: <DefaultsSection key="0" />,
                        label: <Title level={5} title="Defaults" />,
                        extra: <HelpKeysDrawer panelLabel="Defaults" definitionsFields={defaultsDefinitionsFields} />,
                    },
                    isPodSectionShown && {
                        key: "1",
                        children: <AdPodSection />,
                        label: <Title level={5} title="Ad Pod" />,
                        style: INVENTORY_SECTION_HEADER_COLLAPSE_STYLE,
                        extra: <HelpKeysDrawer panelLabel="Ad Pod" definitionsFields={adPodDefinitionsFields} />,
                    },
                    isPlaylistSectionShown && {
                        key: "2",
                        children: <PlaylistConfigurationSection />,
                        label: <Title level={5} title="Playlist Configuration" />,
                        style: INVENTORY_SECTION_HEADER_COLLAPSE_STYLE,
                        extra: (
                            <HelpKeysDrawer
                                panelLabel="Playlist Configuration"
                                definitionsFields={playlistConfigurationDefinitionsFields}
                            />
                        ),
                    },
                    isAdBreakRulesSectionShown && {
                        key: "3",
                        children: <AdBreakRules />,
                        label: <Title level={5} title=" Ad Break Rules" />,
                        style: INVENTORY_SECTION_HEADER_COLLAPSE_STYLE,
                    },
                    {
                        key: "4",
                        children: <BrandSafetySection />,
                        label: <Title level={5} title="Brand Safety" />,
                        style: INVENTORY_SECTION_HEADER_COLLAPSE_STYLE,
                        extra: (
                            <HelpKeysDrawer
                                panelLabel="Brand Safety"
                                definitionsFields={brandSafetyDefinitionsFields}
                            />
                        ),
                    },
                    {
                        key: "5",
                        children: <DetailedConfigurationSection />,
                        style: INVENTORY_SECTION_HEADER_COLLAPSE_STYLE,
                        label: <Title level={5} title="Detailed Configuration" />,
                        extra: (
                            <HelpKeysDrawer
                                panelLabel="Detailed Configuration"
                                definitionsFields={detailConfigurationDefinitionsFields}
                            />
                        ),
                    },
                    {
                        key: "6",
                        children: <CustomPixelsSection />,
                        label: <Title level={5} title="Custom Pixels" />,
                        style: INVENTORY_SECTION_HEADER_COLLAPSE_STYLE,
                        extra: (
                            <HelpKeysDrawer
                                panelLabel="Custom Pixels"
                                definitionsFields={customPixelsDefinitionsFields}
                            />
                        ),
                    },
                    {
                        key: "7",
                        children: <LabelsSection />,
                        label: <Title level={5} title="Labels" />,
                        style: INVENTORY_SECTION_HEADER_COLLAPSE_STYLE,
                        extra: <HelpKeysDrawer panelLabel="Labels" definitionsFields={labelsDefinitionsFields} />,
                    },
                    isFloorsSectionShown && {
                        key: "8",
                        children: <FloorsSection />,
                        label: (
                            <Space size="large">
                                <Title level={5} title="Floors" />
                                {hasSeatWriteAccess && (
                                    <Button onClick={handAdvancedFloors} type="link" size="small">
                                        Advanced Floors
                                    </Button>
                                )}
                            </Space>
                        ),
                        style: INVENTORY_SECTION_HEADER_COLLAPSE_STYLE,
                    },
                ].filter(Boolean) as ItemType[]
            }
        />
    );
};
