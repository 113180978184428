import { Column, ColumnConfig } from "@ant-design/plots";
import { FC } from "react";
import { TagParamsChartOptions } from "./useTagParams";

interface Pros {
    chartOptions: TagParamsChartOptions[];
}
export const TagParamsFrequencyChart: FC<Pros> = ({ chartOptions }) => {
    const config: ColumnConfig = {
        data: chartOptions,
        xField: "frequency",
        yField: "percent",
        tooltip: {
            formatter: ({ frequency, percent }: TagParamsChartOptions) => ({
                name: frequency,
                value: `${percent}%`,
            }),
        },
        label: {
            content: () => "",
        },
        yAxis: {
            label: {
                formatter: (v) => `${v}%`,
            },
            title: { text: "Percent" },
        },
        xAxis: {
            label: {
                autoHide: false,
                autoRotate: true,
                style: {
                    fontSize: 10,
                },
            },
            title: { text: "URL Frequency" },
        },
        meta: {
            type: { alias: "URL frequency" },
            percent: { alias: "URL frequency" },
        },
    };
    return <Column {...config} />;
};
