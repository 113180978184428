import { FC } from "react";
import { Alert, Radio, Typography } from "antd";
import { useAdSourceModeConfiguration } from "./useAdSourceModeConfiguration";

export const AdSourceModeConfiguration: FC = () => {
    const { onChange, value, options, syncWarningText } = useAdSourceModeConfiguration();

    return (
        <>
            <Radio.Group
                data-sdet="ad-source-mode-configuration-field"
                style={{ marginBottom: "1.5rem" }}
                value={value}
                options={options}
                onChange={onChange}
            />
            {syncWarningText && (
                <Alert
                    data-sdet="ad-source-mode-configuration-sync-warning"
                    type="warning"
                    showIcon
                    style={{ alignItems: "baseline", marginBottom: "16px" }}
                    message={<Typography.Text>{syncWarningText}</Typography.Text>}
                />
            )}
        </>
    );
};
