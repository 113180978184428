import { PLAYER_SIZES_TAG, consoleApi } from "./console";

export type PlayerSize = {
    creationDate: string;
    updateDate: string;
    id: number;
    code: string;
    name: string;
    sort: number;
    entityType: string;
};

export const playerSizesApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getPlayerSizes: builder.query<PlayerSize[], void>({
            query: () => "/playerSizes",
            providesTags: [PLAYER_SIZES_TAG],
        }),
    }),
    overrideExisting: false,
});

export const { useGetPlayerSizesQuery } = playerSizesApi;
