import { FC, useState } from "react";
import { Alert, Space, Row, Col, Button } from "antd";
import moment from "moment-timezone";
import { Notification } from "@app/core/services";
import { css } from "@emotion/css";
import { gray5 } from "@rubicon/antd-components";
import { ViewLadleResultsButton } from "./ViewLadleResultsButton";
import { isViewLadleResultsAction, isViewReportAction } from "./helpers";
import { ViewReportResultsButtons } from "./ViewReportResultsButtons";
import { useMarkPlatformNotificationAccessedMutation } from "@app/core/services/console";

interface Props {
    onClickAction: () => void;
    notification: Notification;
}

export const NotificationAlert: FC<Props> = ({ notification, onClickAction }) => {
    const [visible, setVisible] = useState(true);
    const [markPlatformNotificationAccessedMutation] = useMarkPlatformNotificationAccessedMutation();

    if (!visible) {
        return null;
    }

    return (
        <Alert
            className={css(`
                background-color: #FFF;
                border: 1px solid ${gray5};
            `)}
            afterClose={() => {
                setVisible(false);
                markPlatformNotificationAccessedMutation(notification.id);
            }}
            message={notification.name}
            description={
                <>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Row>
                            <Col xs={24}>{notification.message}</Col>
                        </Row>
                        {Boolean(notification.actions.length) && (
                            <Row>
                                {notification.actions.map((action) => {
                                    if (isViewLadleResultsAction(action.details)) {
                                        return (
                                            <Col key={action.id}>
                                                <ViewLadleResultsButton action={action} onClick={onClickAction} />
                                            </Col>
                                        );
                                    }
                                    if (isViewReportAction(action.name)) {
                                        return (
                                            <ViewReportResultsButtons
                                                key={action.id}
                                                action={action}
                                                onClick={onClickAction}
                                            />
                                        );
                                    }
                                    return (
                                        <Col key={action.id}>
                                            <Button size="small" onClick={onClickAction}>
                                                {action.name}
                                            </Button>
                                        </Col>
                                    );
                                })}
                            </Row>
                        )}
                    </Space>
                    <Row justify="end">
                        <Col>
                            <span style={{ fontSize: "12px" }}>{moment(notification.creationDate).fromNow()}</span>
                        </Col>
                    </Row>
                </>
            }
            closable
        />
    );
};
