import conf from "@app/core/conf";
import { useEffect } from "react";

declare global {
    interface Window {
        hasIFrameLoadedEventListener: boolean;
    }
}

export const useInitializeIFrameEventListeners = () => {
    useEffect(() => {
        initializeIFrameEventListeners();
    }, []);
};

export const initializeIFrameEventListeners = () => {
    const magniteIFrameLoadedEventListener = (event) => {
        //Validate Event Source
        if (event.origin === conf.mctvConsoleRoot && event.data.type === "iFrameLoadedEvent" && event.data.isLoaded) {
            sendMagniteContextEventToMCTV();
        }
    };

    if (!window.hasOwnProperty("hasIFrameLoadedEventListener")) {
        window.addEventListener("message", magniteIFrameLoadedEventListener);
        window.hasIFrameLoadedEventListener = true;
    }
};

export const sendMagniteContextEventToMCTV = () => {
    const message = { type: "magniteContextEvent", isSubApp: true };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.parent.document.getElementsByTagName("iframe")[0].contentWindow.postMessage(message, conf.mctvConsoleRoot);
};
