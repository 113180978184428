import { FC } from "react";
import { ColProps } from "antd/lib/grid/col";
import { Form, Select, Row, Col } from "antd";
import { SelectProps } from "antd/lib/select";
import { TagRender } from "@app/core/components";
import { SEPARATION_GROUPS } from "@app/features/inventory/HierarchyForms/constants";
import { AdUnitSeparationGroupFormDrawer } from "@app/features/inventory/components/FormItems/AdUnitCompetitiveSeparationGroups/AdUnitSeparationGroupFormDrawer";
import { useAdUnitCompetitiveSeparationGroups } from "@app/features/inventory/components/FormItems/AdUnitCompetitiveSeparationGroups/useAdUnitCompetitiveSeparationGroups";

interface AdUnitCompetitiveSeparationGroupsProps extends SelectProps {
    label: string;
    colSize?: ColProps;
    name: typeof SEPARATION_GROUPS;
}

export const AdUnitCompetitiveSeparationGroups: FC<AdUnitCompetitiveSeparationGroupsProps> = ({
    name,
    label,
    colSize,
    ...rest
}) => {
    const {
        isOpen,
        options,
        onChange,
        onFinish,
        isLoading,
        isFetching,
        handleSubmit,
        handleCancel,
        isIndustriesMode,
        handleAddNewGroup,
        separationGroupForm,
    } = useAdUnitCompetitiveSeparationGroups();
    return (
        <Row wrap align="middle" style={{ marginBottom: "24px" }}>
            <Col {...colSize}>
                <Form.Item name={name} label={label} style={{ marginBottom: "0" }}>
                    <Select
                        showSearch
                        allowClear
                        labelInValue
                        mode="multiple"
                        options={options}
                        onChange={onChange}
                        loading={isFetching}
                        tagRender={TagRender}
                        optionFilterProp="label"
                        style={{ width: "100%" }}
                        autoClearSearchValue={false}
                        {...rest}
                    />
                </Form.Item>
            </Col>
            <Col>
                <AdUnitSeparationGroupFormDrawer
                    open={isOpen}
                    onFinish={onFinish}
                    isLoading={isLoading}
                    onClose={handleCancel}
                    handleSubmit={handleSubmit}
                    isIndustriesMode={isIndustriesMode}
                    handleAddNewGroup={handleAddNewGroup}
                    separationGroupForm={separationGroupForm}
                />
            </Col>
        </Row>
    );
};
