import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { Button, Col, Row, Space, Tooltip, Typography } from "antd";
import { FC } from "react";
import { useIsDealShared } from "./useDealObjectForm";
import { InfoCircleOutlined } from "@ant-design/icons";
import { brandCobalt } from "@rubicon/antd-components";

export const DealObjectFormHeader: FC<{ formTitle: string }> = ({ formTitle }) => {
    const { dealObjectMode, setDealObjectMode, marketplaceInfoMode } = useMarketplaceInfoWidget();
    const { isDealShared } = useIsDealShared();
    const isEditButtonVisible = dealObjectMode === "view" && marketplaceInfoMode.name !== "view" && !isDealShared;

    if (!formTitle && !isEditButtonVisible) {
        return null;
    }

    return (
        <Row justify="space-between">
            <Col>
                <Space>
                    <Typography.Title level={4}>
                        <>
                            {formTitle}
                            {isDealShared && (
                                <Tooltip title="This deal has been shared via an external entity, and cannot be edited here">
                                    <InfoCircleOutlined
                                        style={{
                                            color: brandCobalt,
                                            marginLeft: 8,
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </>
                    </Typography.Title>
                </Space>
            </Col>
            <Col>{isEditButtonVisible && <Button onClick={() => setDealObjectMode("edit")}>Edit Deal</Button>}</Col>
        </Row>
    );
};
