import { disablePastDates } from "@app/core/utils/disablePastDates";
import { Form } from "antd";
import { MomentDatePicker } from "@app/core/components/MomentDatePicker";
import { useSyncedFieldsProducer } from "@app/features/syncedFields/useSyncedFieldsProducer";
import moment from "moment-timezone";

export const StartDatePicker = () => {
    const { updateSyncedFields } = useSyncedFieldsProducer();
    return (
        <Form.Item
            data-sdet="deal-start-date-field"
            rules={[{ required: true, message: "Start date is required" }]}
            label="Start Date"
            name={["dealUnderEdit", "startTime"]}
        >
            <MomentDatePicker
                showTime
                style={{ width: "100%" }}
                disabledDate={disablePastDates}
                onChange={(startDate: moment.Moment | null) => {
                    updateSyncedFields({ startDate });
                }}
            />
        </Form.Item>
    );
};
