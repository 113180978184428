import { FC } from "react";
import { Layout } from "antd";
import { Loading } from "@app/core/components";
import { useBuyerAuthContext } from "@app/core/auth";
import { BuyerTransparencyPage } from "./BuyerTransparencyPage";

export const BuyerTransparency: FC = () => {
    const { context, session } = useBuyerAuthContext();

    if (!context || !session) {
        return (
            <Layout.Content>
                <Loading />
            </Layout.Content>
        );
    }

    return <BuyerTransparencyPage />;
};
