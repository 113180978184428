import { Form, Row } from "antd";
import TagTypeFilter from "./TagTypeFilter/TagTypeFilter";
import MacrosPickerFilter from "./MacrosPickerFilter/MacrosPickerFilter";
import { useTagParamsChangesDiscardModal } from "./TagParamsChangesModal/useTagParamsChangesDiscardModal";
import { useEditModeControls } from "./EditModeControls/useEditModeControls";
import { EditModeControls } from "@app/core/components/EditModeControls";

export const TagParamsToolbar = () => {
    const { handleCancel, handleOk, handleModalOpen, open } = useTagParamsChangesDiscardModal();
    const { isEdit, isSaving, handleEditClick, handleCancelClick, handleSaveClick } =
        useEditModeControls(handleModalOpen);

    return (
        <Form layout="vertical">
            <Row gutter={16}>
                <TagTypeFilter />
                {isEdit && <MacrosPickerFilter />}
                <EditModeControls
                    isEdit={isEdit}
                    isSaving={isSaving}
                    open={open}
                    handleDiscardModalCancel={handleCancel}
                    handleCancelClick={handleCancelClick}
                    handleEditClick={handleEditClick}
                    handleSaveClick={handleSaveClick}
                    handleOk={handleOk}
                />
            </Row>
        </Form>
    );
};

export default TagParamsToolbar;
