import { FC } from "react";
import { Button, Col, Dropdown, Form, Row, Select, Space } from "antd";
import { LabeledValue } from "antd/lib/select";
import { DownOutlined } from "@ant-design/icons";
import { useDownloadVastErrorTotals } from "@app/core/services/diagnostics/vastErrors";
import { SeatAdSourcesVastErrorsChart } from "@app/features/seatAdSources/seatAdSourcesCharts";
import { SeatAdSourcesTopOffendersTable } from "./SeatAdSourcesTopOffendersTable/SeatAdSourcesTopOffendersTable";
import { SeatAdSourcesVastErrorDescriptionTable } from "./SeatAdSourcesVastErrorDescriptionTable/SeatAdSourcesVastErrorDescriptionTable";
import { useSeatAdSourcesDetailsVastErrors } from "./useSeatAdSourcesDetailsVastErrors";
import { LoadableComponent } from "@app/core/components/LoadableComponent";

interface Props {
    adSourceId: number;
}

export const SeatAdSourcesDetailsVastErrors: FC<Props> = ({ adSourceId }) => {
    const { vastErrors, loadingState, networks, selectedNetwork, setSelectedNetwork, refreshLast24Hours } =
        useSeatAdSourcesDetailsVastErrors(adSourceId);
    const { downloadVastErrorTotals } = useDownloadVastErrorTotals();

    return (
        <LoadableComponent loadingState={loadingState} dataSdet="seat-ad-sources-details-vast-errors">
            <Row justify="space-between" align="middle">
                <Col xs={10}>
                    <Form layout="vertical">
                        <Form.Item label="DSP">
                            <Select
                                placeholder="All"
                                options={networks}
                                value={selectedNetwork}
                                loading={false}
                                style={{ width: "100%" }}
                                onChange={(_, network) => setSelectedNetwork(network as LabeledValue)}
                            />
                        </Form.Item>
                    </Form>
                </Col>
                <Col>
                    <Row align="bottom" gutter={8}>
                        <Col>
                            <Button onClick={refreshLast24Hours}>Refresh</Button>
                        </Col>
                        <Col>
                            <Dropdown
                                trigger={["click"]}
                                menu={{
                                    items: [
                                        {
                                            label: "CSV",
                                            key: "csv",
                                            onClick: () => downloadVastErrorTotals(adSourceId, "csv"),
                                        },
                                        {
                                            label: "Excel",
                                            key: "excel",
                                            onClick: () => downloadVastErrorTotals(adSourceId, "xlsx"),
                                        },
                                    ],
                                }}
                            >
                                <Button data-sdet="export-button">
                                    <Space>
                                        Export
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <SeatAdSourcesVastErrorsChart vastErrors={vastErrors} />
            <SeatAdSourcesTopOffendersTable vastErrors={vastErrors} />
            <SeatAdSourcesVastErrorDescriptionTable vastErrors={vastErrors} />
        </LoadableComponent>
    );
};
