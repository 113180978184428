import { Col, Row, Table, TableColumnsType, Typography } from "antd";
import { FC } from "react";
import { intl } from "@rubicon/utils";
import { Offender } from "../useVastErrors";

interface Props {
    data: Offender[];
}

export const TopOffenders: FC<Props> = ({ data }) => {
    const columns: TableColumnsType = [
        {
            title: "Network",
            dataIndex: "name",
            key: "name",
            sorter: (a: Offender, b: Offender) => a.name.localeCompare(b.name),
            width: "25%",
        },
        {
            title: "Advertiser Domain",
            dataIndex: "adomain",
            key: "adomain",
            sorter: (a: Offender, b: Offender) => a.adomain.localeCompare(b.adomain),
            width: "25%",
        },
        {
            title: "Creative ID",
            dataIndex: "creativeId",
            key: "creativeId",
            sorter: (a: Offender, b: Offender) => a.creativeId.localeCompare(b.creativeId),
            width: "25%",
        },
        {
            title: "Error Count",
            dataIndex: "errorCount",
            key: "errorCount",
            sorter: (a: Offender, b: Offender) => a.errorCount - b.errorCount,
            align: "right",
            render: (value) => intl.numberFormatter(value),
            defaultSortOrder: "descend",
            width: "25%",
        },
    ];
    return (
        <>
            <Row>
                <Col>
                    <Typography.Title level={5}>Top Offenders</Typography.Title>
                </Col>
            </Row>
            <Table
                style={{ marginBottom: "24px" }}
                size="small"
                pagination={false}
                columns={columns}
                dataSource={data}
            />
        </>
    );
};
