import { FC } from "react";
import { Form, Radio } from "antd";
import { Seat } from "@app/core/services";
import { INVENTORY_FORM_FIELDS, CREATIVE_BLOCK_MODE_OPTIONS } from "@app/features/inventory/InventorySeat/constants";

interface CreativeBlockModeProps {
    seat: Seat;
    edit?: boolean;
}

export const CreativeBlockMode: FC<CreativeBlockModeProps> = ({ edit = false, seat }) => {
    const { creativeBlockingMode } = seat;
    return (
        <Form.Item
            label={INVENTORY_FORM_FIELDS.CREATIVE_BLOCK_MODE.label}
            name={INVENTORY_FORM_FIELDS.CREATIVE_BLOCK_MODE.name}
        >
            {edit ? <Radio.Group size="large" options={CREATIVE_BLOCK_MODE_OPTIONS} /> : creativeBlockingMode.name}
        </Form.Item>
    );
};
