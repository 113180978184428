import { Form, InputNumber } from "antd";
import { FC } from "react";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

export const MaxAdsUserField: FC = () => {
    return (
        <Form.Item
            label={INVENTORY_FORM_FIELDS.GOVERNOR_REQUEST.label}
            name={INVENTORY_FORM_FIELDS.GOVERNOR_REQUEST.name}
            wrapperCol={{ xs: 24, sm: 14, md: 12, lg: 8, xl: 6, xxl: 4 }}
        >
            <InputNumber style={{ width: "100%" }} placeholder="Maximum Ads Per User in 1 Minute" />
        </Form.Item>
    );
};
