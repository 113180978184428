import { Form } from "antd";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { AdSourcePayload } from "@app/core/services";

const { useWatch } = Form;

export const useOverrideFloorCpmInputLabel = () => {
    const floorType = useWatch<AdSourcePayload[typeof AD_SOURCE_FIELDS.FLOOR_TYPE.name]>(
        AD_SOURCE_FIELDS.FLOOR_TYPE.name
    );

    switch (floorType?.name) {
        case "Override":
            return AD_SOURCE_FIELDS.OVERRIDE_FLOOR_CPM.label;
        case "Fallback":
            return AD_SOURCE_FIELDS.FALLBACK_FLOOR_CPM.label;
        case "IncreaseCPM":
            return "Increase Floor by CPM";
        case "DecreaseCPM":
            return "Decrease Floor by CPM";
        case "IncreasePercent":
            return "Increase Floor by %";
        case "DecreasePercent":
            return "Decrease Floor by %";
        default:
            return AD_SOURCE_FIELDS.OVERRIDE_FLOOR_CPM.label;
    }
};
