import { Store } from "rc-field-form/lib/interface";
import { Brand, BrandPayload, Seat } from "@app/core/services";
import {
    BRAND_FORM_FIELDS,
    HierarchyFormsMode,
    NOT_SPECIFIED_OPTION,
    INVENTORY_CUSTOM_CODE_TYPE,
    INVENTORY_GENERATED_CODE_TYPE,
    DEFAULT_ADVERTISERS_AS_ALLOW_LIST,
} from "@app/features/inventory/HierarchyForms/constants";
import {
    parseCodeValue,
    parseLabelValues,
    parseIabCategories,
    parseBuyerSeatList,
    parseThirdPartyPixels,
    parsePublisherReauction,
    parseAffiliateCostModel,
    parseInternalLabelValues,
    parseBlockedIabCategories,
    parseCreativeBlockingMode,
    parseAdvertiserBlockDomains,
    parseAffiliateCostValueFixed,
    parseBlockedAdvertiserDomains,
    parseAffiliateCostValuePercent,
    parseDistributionGroupLabelValues,
    parseEnableAdvertisersAsWhitelist,
} from "@app/features/inventory/HierarchyForms/helpers";
import { BrandForm } from "@app/features/inventory/HierarchyForms/BrandForm/useBrandForm";

interface ParseBrandFormValuesToApi {
    seat: Seat | null;
    values: BrandForm;
    publisherId: number;
    isSysAdmin: boolean;
    isPubAcctMgr: boolean;
    isTremorUser: boolean;
    mode: HierarchyFormsMode;
    hasInternalAccess: boolean;
}

interface GetBrandFormInitialValues {
    brand: Brand | undefined;
    mode: HierarchyFormsMode;
}

export const getBrandFormInitialValues = ({ brand, mode }: GetBrandFormInitialValues): Store => {
    const isCreateMode = mode === "create";
    const isCopyMode = mode === "copy";
    return {
        // General Section
        [BRAND_FORM_FIELDS.NAME.name]: isCreateMode ? "" : isCopyMode ? `Copy of ${brand?.name}` : brand?.name,
        [BRAND_FORM_FIELDS.CODE_TYPE.name]:
            isCreateMode || isCopyMode ? INVENTORY_GENERATED_CODE_TYPE.value : INVENTORY_CUSTOM_CODE_TYPE.value,
        [BRAND_FORM_FIELDS.CODE.name]: isCreateMode || isCopyMode ? "" : brand?.code,
        [BRAND_FORM_FIELDS.DESCRIPTION.name]: isCreateMode ? "" : brand?.description,

        // Affiliate Section
        [BRAND_FORM_FIELDS.AFFILIATE_COST_MODEL.name]: parseAffiliateCostModel(mode, brand),
        [BRAND_FORM_FIELDS.AFFILIATE_COST_VALUE_PERCENT.name]: parseAffiliateCostValuePercent(mode, brand),
        [BRAND_FORM_FIELDS.AFFILIATE_COST_VALUE_FIXED.name]: parseAffiliateCostValueFixed(mode, brand),

        // Brand Safety Section
        [BRAND_FORM_FIELDS.BLOCK_ADVERTISER_DOMAIN_LISTS.name]: parseBlockedAdvertiserDomains(mode, brand),
        [BRAND_FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: parseAdvertiserBlockDomains(mode, brand),
        [BRAND_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.name]: parseBuyerSeatList(mode, brand),
        [BRAND_FORM_FIELDS.BLOCKED_CATEGORIES.name]: parseBlockedIabCategories(mode, brand),
        [BRAND_FORM_FIELDS.CREATIVE_BLOCK_MODE.name]: parseCreativeBlockingMode(mode, brand),

        // Internal Section
        [BRAND_FORM_FIELDS.LOWER_CALCULON_USE_RATE_OVERRIDE.name]: isCreateMode ? null : brand?.calculonUseRateOverride,
        [BRAND_FORM_FIELDS.UPPER_CALCULON_USE_RATE_OVERRIDE.name]: isCreateMode
            ? null
            : brand?.upperCalculonUseRateOverride,
        [BRAND_FORM_FIELDS.PUBLISHER_RE_AUCTION.name]: parsePublisherReauction(mode, brand),

        // Supply Details Section
        [BRAND_FORM_FIELDS.CATEGORIES.name]: parseIabCategories(mode, brand),
        [BRAND_FORM_FIELDS.ADVERTISERS_AS_WHITELIST.name]: parseEnableAdvertisersAsWhitelist(mode, brand),
        // Custom Pixels
        [BRAND_FORM_FIELDS.CUSTOM_PIXELS.name]: parseThirdPartyPixels(mode, brand),

        // Labels
        [BRAND_FORM_FIELDS.LABELS.name]: parseLabelValues(mode, brand),
        [BRAND_FORM_FIELDS.INTERNAL_LABELS.name]: parseInternalLabelValues(mode, brand),
        [BRAND_FORM_FIELDS.DISTRIBUTION_GROUP_LABELS.name]: parseDistributionGroupLabelValues(mode, brand),
    };
};

export const parseBrandFormValuesToApi = ({
    seat,
    values,
    isSysAdmin,
    publisherId,
    isPubAcctMgr,
    isTremorUser,
    hasInternalAccess,
}: ParseBrandFormValuesToApi): BrandPayload => {
    const {
        // General Section
        name,
        code,
        codeType,
        description,
        // Affiliate Section
        affiliateCostModel,
        affiliateCostValueFixed,
        affiliateCostValuePercent,
        // Brand Safety Section
        seatAdvertiserDomainFilterListDefs,
        advertiserBlockDomains,
        buyerSeatList,
        blockedIabCategories,
        creativeBlockingMode,
        // Internal Section
        publisherReauction,
        upperCalculonUseRateOverride,
        calculonUseRateOverride,
        // Supply Details Section
        iabCategories,
        enableAdvertisersAsWhitelist,
        // Custom Pixels
        thirdPartyPixels,
        // Labels
        labelValues,
        internalLabelValues,
        distributionGroupLabelValues,
    } = values;

    const payload: BrandPayload = {
        publisher: { id: publisherId },
        // General Section
        name,
        code: parseCodeValue(codeType, code),
        description,
        // Brand Safety Section
        seatAdvertiserDomainFilterListDefs: (seatAdvertiserDomainFilterListDefs || []).map((option) => ({
            id: option.value as number,
            name: option.label as string,
        })),
        advertiserBlockDomains: advertiserBlockDomains.trim() ? advertiserBlockDomains.trim().split("\n") : null,
        buyerSeatList: buyerSeatList
            ? { id: buyerSeatList.value as number, name: buyerSeatList.label as string }
            : null,
        blockedIabCategories: (blockedIabCategories || []).map((option) => ({
            id: option.value as number,
        })),
        // Supply Details Section
        iabCategories: (iabCategories || []).map((option) => ({
            id: option.value as number,
        })),
        enableAdvertisersAsWhitelist: enableAdvertisersAsWhitelist || DEFAULT_ADVERTISERS_AS_ALLOW_LIST,
        // Custom Pixels
        thirdPartyPixels: (thirdPartyPixels || []).map((pixel) => ({
            id: pixel.value as number,
            name: pixel.label as string,
        })),
        // Labels
        labelValues: (labelValues || []).map(({ value, seatLabel, labelValue }) => ({
            id: value,
            value: labelValue,
            label: seatLabel,
        })),
    };

    if (isPubAcctMgr || isSysAdmin)
        Object.assign(payload, {
            // Internal Section
            publisherReauction: publisherReauction === NOT_SPECIFIED_OPTION.value ? null : publisherReauction,
            upperCalculonUseRateOverride,
            calculonUseRateOverride,
        });

    if (isSysAdmin)
        Object.assign(payload, {
            // Brand Safety Section
            creativeBlockingMode: { id: creativeBlockingMode },
        });

    if (isTremorUser && seat?.affiliateCostEnabled) {
        Object.assign(payload, {
            // Affiliate Section
            affiliateCostModel: affiliateCostModel
                ? { id: affiliateCostModel.value, name: affiliateCostModel.label }
                : null,
            affiliateCostValuePercent: affiliateCostValuePercent,
            affiliateCostValueFixed:
                typeof affiliateCostValueFixed === "number" ? affiliateCostValueFixed * 1000 : null,
        });
    }
    if (hasInternalAccess)
        Object.assign(payload, {
            // Labels
            internalLabelValues: (internalLabelValues || []).map(({ value, seatLabel, labelValue }) => ({
                id: value,
                value: labelValue,
                label: seatLabel,
            })),
            distributionGroupLabelValues: (distributionGroupLabelValues || []).map(
                ({ value, seatLabel, labelValue }) => ({
                    id: value,
                    value: labelValue,
                    label: seatLabel,
                })
            ),
        });
    return payload;
};
