import { useGetUsersQuery, User } from "@app/core/services";
import debounce from "lodash.debounce";
import { useMemo, useState } from "react";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";

interface UseUsersEmail {
    users: {
        label: string;
        value: string;
    }[];
    isLoading: boolean;
    search: string;
    handleChangeSearch: (value: string) => void;
}

export const useUsersEmail = (): UseUsersEmail => {
    const [search, setSearch] = useState("");
    const [keyword, setKeyword] = useState("");
    const debouncedKeyword = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setKeyword]
    );
    const handleChangeSearch = (value: string) => {
        setSearch(value);
        debouncedKeyword(value);
    };
    const { data, isFetching } = useGetUsersQuery({ keyword: keyword, page: 1, max: 50 });
    const users = useMemo(
        () =>
            (data || []).map(({ emailAddress }: User) => ({
                label: emailAddress,
                value: emailAddress,
            })),
        [data]
    );

    return {
        users,
        isLoading: isFetching,
        search,
        handleChangeSearch,
    };
};
