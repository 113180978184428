import { FC } from "react";
import { Form, Radio, Space } from "antd";
import { Seat } from "@app/core/services";
import { NONE } from "@app/core/components/constants";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { INVENTORY_FORM_FIELDS, AUDIENCE_LOCK_TYPE_OPTIONS } from "@app/features/inventory/InventorySeat/constants";
import { useAudienceLock } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/AdvancedFeaturesForm/AdvancedFeaturesFormFields/AudienceLock/useAudienceLock";
import { gold6 } from "@rubicon/antd-components";

interface AudienceLockProps {
    seat: Seat;
    edit?: boolean;
}

export const AudienceLock: FC<AudienceLockProps> = ({ edit = false, seat }) => {
    const { message } = useAudienceLock();
    const { audienceLockType } = seat;
    return (
        <Form.Item
            label={INVENTORY_FORM_FIELDS.AUDIENCE_LOCK_TYPE.label}
            name={INVENTORY_FORM_FIELDS.AUDIENCE_LOCK_TYPE.name}
            help={
                message && (
                    <Space>
                        <ExclamationCircleOutlined style={{ color: gold6 }} />
                        {message}
                    </Space>
                )
            }
        >
            {edit ? (
                <Radio.Group size="large" options={AUDIENCE_LOCK_TYPE_OPTIONS} />
            ) : (
                AUDIENCE_LOCK_TYPE_OPTIONS.find(({ value }): boolean => value === audienceLockType.id)?.label || NONE
            )}
        </Form.Item>
    );
};
