import { FC } from "react";
import { Card } from "antd";
import { Domain, PartnerDomain } from "@app/features/inventory/HierarchyForms/SupplyForm/Fields";
import {
    AppName,
    BundleID,
    PaidType,
    StoreUrl,
    SupplyAppStore,
} from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/AppDetailsSection/Fields";

export const AppDetailsSection: FC = () => {
    return (
        <Card bordered={false}>
            <SupplyAppStore />
            <BundleID />
            <AppName />
            <StoreUrl />
            <PaidType />
            <Domain />
            <PartnerDomain />
        </Card>
    );
};
