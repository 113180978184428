import { Select, Form } from "antd";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useDeliveryMethods } from "./useDeliveryMethods";

export const DeliveryMethods = () => {
    const { isFetching, options } = useDeliveryMethods();

    return (
        <Form.Item name={AD_UNIT_FORM_FIELDS.DELIVERY_METHODS.name} label={AD_UNIT_FORM_FIELDS.DELIVERY_METHODS.label}>
            <Select
                showSearch
                allowClear
                labelInValue
                mode="multiple"
                options={options}
                loading={isFetching}
                placeholder={AD_UNIT_FORM_FIELDS.DELIVERY_METHODS.placeholder}
                optionFilterProp="label"
                style={{ width: "100%" }}
            />
        </Form.Item>
    );
};
