import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useGetDealStatusQuery } from "@app/core/services";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { useSyncedFieldsProducer } from "@app/features/syncedFields";

export const availableStatuses = new Set([1, 2]);

interface UseDealStatusField {
    options: LabeledValue[];
    isFetching: boolean;
    value: number;
    handleSelect: (value: number) => void;
}

export const useDealStatusField = (): UseDealStatusField => {
    const dispatch = useAppDispatch();
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.STATUS]);
    const { data, isFetching } = useGetDealStatusQuery();
    const options = useMemo(
        () =>
            (data || [])
                .filter((item) => availableStatuses.has(item.id))
                .map((item) => ({ label: item.name, value: item.id })),
        [data]
    );
    const { updateSyncedFields } = useSyncedFieldsProducer();
    const handleSelect = (value: number): void => {
        const selectedStatus = data?.find((status) => status.id === value);
        updateSyncedFields({ status: selectedStatus });
        dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.STATUS, value }));
    };

    return {
        options,
        isFetching,
        value,
        handleSelect,
    };
};
