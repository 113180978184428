import { useUserAccess } from "@app/core/auth";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { DealListItem } from "@app/core/services";
import { useHistory, useParams } from "react-router-dom";

interface UseDealTableActions {
    canEditSeat: boolean;
    isAdSource: () => boolean;
    isLegacyDeal: boolean;
    isDealShared: boolean;
    handleCopy: () => void;
    handleEdit: () => void;
}

export const useDealTableActions = (deal: DealListItem): UseDealTableActions => {
    const { canEditSeat } = useUserAccess();
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();

    const isAdSource = () => deal?.adSources?.length > 0;

    const handleCopy = () => {
        history.push(ROUTE_FORMATTERS.SEAT_DEAL_COPY(seatId, deal.id));
    };

    const handleEdit = () => {
        history.push(ROUTE_FORMATTERS.SEAT_DEAL_EDIT(seatId, deal.id));
    };

    const isLegacyDeal = deal.dealType.name === "Legacy Deal";
    const isDealShared =
        (deal.sharedMarketplaces && deal.sharedMarketplaces.length > 0) ||
        (deal.sharedSeats && deal.sharedSeats?.length > 0) ||
        false;
    return {
        canEditSeat,
        isAdSource,
        isLegacyDeal,
        isDealShared,
        handleCopy,
        handleEdit,
    };
};
