import { FC, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Loading } from "@app/core/components";
import { ResizableDrawer } from "@rubicon/antd-components";
import { uri } from "@rubicon/utils";
import { useGetPriceOverrideConfigByIdQuery } from "@app/core/services";
import { useNotificationError } from "@app/core/components/hooks";
import { PriceOverridesDrawerTitle } from "./PriceOverridesDrawerTitle";
import { HelperKeysDrawerBreadcrumbHeader } from "@app/core/components/HelperKeysDrawerBreadcrumbHeader";
import { PriceOverrideDefinitionsContent } from "../PriceOverrideDefinitions";
import { PriceOverrideDetailsContent } from "./PriceOverrideDetailsContent";
import { drawerRightClosePosition } from "@app/features/controls/constants";

export const removePriceOverrideDrawerParamsFromSearch = (queryString: string) => {
    const removeDrawerParam = uri.deleteSearchParam(queryString, "drawer");
    const nextQueryString = uri.deleteSearchParam(removeDrawerParam, "priceOverrideId");
    return nextQueryString;
};

export const PriceOverridesDrawer: FC = () => {
    const { search: queryString } = useLocation();

    const drawerParam = useMemo(() => uri.getSearchParam(queryString, "drawer"), [queryString]);
    const priceOverrideId = useMemo(() => uri.getSearchParam(queryString, "priceOverrideId"), [queryString]);

    const history = useHistory();
    const onClose = () => {
        const nextQueryString = removePriceOverrideDrawerParamsFromSearch(queryString);
        history.push({ search: nextQueryString });
    };

    const {
        data: priceOverride,
        isLoading: isLoadingPriceOverride,
        error: priceOverrideError,
    } = useGetPriceOverrideConfigByIdQuery(Number(priceOverrideId), { skip: !drawerParam || !priceOverrideId });

    useNotificationError(priceOverrideError);

    return (
        <ResizableDrawer
            title={
                <>
                    {drawerParam === "details" && <PriceOverridesDrawerTitle priceOverride={priceOverride} />}
                    {drawerParam === "definitions" && (
                        <HelperKeysDrawerBreadcrumbHeader
                            parentLabel={priceOverride?.name}
                            childLabel="Price Override Definitions"
                        />
                    )}
                </>
            }
            open={drawerParam === "details" || drawerParam === "definitions"}
            onClose={onClose}
            placement="right"
            width={"45%"}
            className={drawerRightClosePosition}
        >
            {isLoadingPriceOverride && <Loading />}
            {drawerParam === "details" && (
                <PriceOverrideDetailsContent priceOverride={priceOverride} wrapperCol={{ xs: 24 }} />
            )}
            {drawerParam === "definitions" && <PriceOverrideDefinitionsContent />}
        </ResizableDrawer>
    );
};
