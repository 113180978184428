import { uri } from "@rubicon/utils";
import { useLocation, useHistory } from "react-router-dom";
import { MACROS_PICKER, MACROS_PICKER_MAPPER, MACROS_PICKER_OPTIONS } from "../constants";
import { getParamFromSearch } from "../helpers";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { selectMacroFields, setTagParamsForm } from "../reducer";

export const useMacrosPickerFilter = () => {
    const { search } = useLocation();
    const { push } = useHistory();
    const macroValues = useAppSelector(selectMacroFields);

    const dispatch = useAppDispatch();

    const selected = getParamFromSearch(search, MACROS_PICKER);

    const handleMacrosPickerChange = (value: string) => {
        const selectedMacros = MACROS_PICKER_MAPPER[value];
        const entries =
            macroValues &&
            Object.entries(macroValues).map(([key, macroValue]) => [
                key,
                selectedMacros.some((selectedMacro) => selectedMacro.name.includes(macroValue.name))
                    ? {
                          ...macroValue,
                          macro: selectedMacros.find((selectedMacro) => macroValue.name === selectedMacro.name).macro,
                          selected: true,
                          isDirty: true,
                      }
                    : macroValue,
            ]);

        entries && dispatch(setTagParamsForm(Object.fromEntries(entries)));
        const nextSearch = uri.setSearchParam(search, MACROS_PICKER.key, value);
        push({ search: nextSearch });
    };

    return {
        options: MACROS_PICKER_OPTIONS,
        handleMacrosPickerChange,
        selected,
    };
};
