import { useUserAccess } from "@app/core/auth";
import { MarketplaceInfo } from "@app/core/services";
import { css } from "@emotion/css";
import { Col, Collapse, Form, Row } from "antd";
import { SharedMarketplaceMultiselect, SharedSeatsMultiselect } from "./Fields";
import { brandCobalt } from "@rubicon/antd-components";

export const InternalSection = () => {
    const transactionType = Form.useWatch<MarketplaceInfo["deal"]["dealTransactionType"]>([
        "dealUnderEdit",
        "dealTransactionType",
    ]);
    const isNonGuaranteedDeal = transactionType?.name === "Non Reserved";
    const { hasInternalAccess } = useUserAccess();
    const isInternalUser = hasInternalAccess;
    const isVisible = isNonGuaranteedDeal && isInternalUser;
    if (!isVisible) {
        return null;
    }
    return (
        <Col span={24}>
            <Collapse bordered={false} style={{ backgroundColor: "white", border: "none" }} expandIconPosition="end">
                <Collapse.Panel
                    forceRender
                    className={css`
                        & > .ant-collapse-header > .ant-collapse-header-text {
                            color: ${brandCobalt} !important;
                            font-size: 14px !important;
                            font-weight: normal !important;
                        }
                        & > .ant-collapse-header {
                            width: 90px;
                            padding: 0 !important;
                        }
                        & > .ant-collapse-header > .ant-collapse-expand-icon {
                            color: ${brandCobalt} !important;
                        }
                    `}
                    key={1}
                    header="Internal"
                >
                    <Row>
                        <SharedSeatsMultiselect />
                        <SharedMarketplaceMultiselect />
                    </Row>
                </Collapse.Panel>
            </Collapse>
        </Col>
    );
};
