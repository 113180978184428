import { FC } from "react";
import { SeatIFrame } from "@app/core/components";

interface Props {
    src: (seatId: string) => string;
}

export const DiagnosticsPage: FC<Props> = ({ src }) => {
    return <SeatIFrame src={src} title="Diagnostics" />;
};
