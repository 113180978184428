import { FC } from "react";
import { Form, Input } from "antd";
import { Rule } from "antd/lib/form";
import { VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { useCurrencyInput } from "./useCurrencyInput";

interface Props {
    hidden?: boolean;
    name: string | string[];
    label: string;
    hideLabel?: boolean;
    currencyCode: string | undefined;
    dataSdetPrefix?: string;
    help?: string;
    disabled?: boolean;
    required?: boolean;
    additionalRules?: Rule[];
    onBlur?: (value: string, isDirty?: boolean) => void;
    value?: string | number;
    placeHolder?: string;
}

const DEFAULT_CURRENCY_CODE = "USD";
const DEFAULT_DATA_SDET_PREFIX = "currency";

export const CurrencyInput: FC<Props> = ({
    name,
    label,
    hideLabel,
    currencyCode = DEFAULT_CURRENCY_CODE,
    dataSdetPrefix = DEFAULT_DATA_SDET_PREFIX,
    help,
    disabled,
    required,
    additionalRules = [],
    hidden,
    onBlur,
    value,
}) => {
    const { setFieldValue } = Form.useFormInstance();
    const {
        prefix: _prefix,
        rules: _rules,
        placeholder: _placeholder,
        formItemDataSdet: _formItemDataSdet,
        inputDataSdet: _inputDataSdet,
        onBeforeInput: _onBeforeInput,
        onBlur: _onBlur,
        onChange: _onChange,
        onPressEnter: _onPressEnter,
    } = useCurrencyInput(
        name,
        label,
        currencyCode,
        dataSdetPrefix,
        required,
        hidden,
        additionalRules,
        onBlur,
        setFieldValue
    );

    return (
        <Form.Item
            name={name}
            label={hideLabel ? undefined : label}
            help={help}
            data-sdet={_formItemDataSdet}
            rules={_rules}
            hidden={hidden}
            {...VERTICAL_LAYOUT_FIX}
        >
            <Input
                disabled={disabled}
                data-sdet={_inputDataSdet}
                placeholder={_placeholder}
                prefix={_prefix}
                onBeforeInput={_onBeforeInput}
                onBlur={_onBlur}
                onChange={_onChange}
                onPressEnter={_onPressEnter}
                style={{ width: "100%" }}
                value={value}
            />
        </Form.Item>
    );
};
