export type EasingFn = (currentTime: number, startValue: number, changeInValue: number, duration: number) => number;
export type Easing = "easeOutCubic" | "easeInCubic" | "linear" | EasingFn;

export const easings = {
    easeInCubic: (t: number, b: number, c: number, d: number): number => {
        t /= d;
        return c * t * t * t + b;
    },
    easeOutCubic: (t: number, b: number, c: number, d: number): number => {
        t /= d;
        t--;
        return c * (t * t * t + 1) + b;
    },
    linear: (t: number, b: number, c: number, d: number): number => {
        return (c * t) / d + b;
    },
};

export const defaultEasing = easings.linear;

export const getEasing = (easing: Easing) => (typeof easing === "function" ? easing : easings[easing]);
