import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { RuleObject } from "antd/lib/form";

export const vastDynamicPricingMultiplierValidator = (_: RuleObject, value: number) => {
    if (!value && value !== 0) {
        return Promise.resolve();
    }
    if (value >= 0.5 && value <= 1.5) {
        return Promise.resolve();
    }

    return Promise.reject(
        new Error(`${AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_MULTIPLIER.label} should be a value from 0.5 to 1.5!`)
    );
};
