import { FC } from "react";
import { Form, Select } from "antd";
import { Seat } from "@app/core/services";
import { NONE } from "@app/core/components/constants";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { useClientType } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/SeatDetailsFormFields/GeneralInternalOnly/Fields/ClientType/useClientType";

interface ClientTypeProps {
    seat: Seat;
    edit?: boolean;
}

export const ClientType: FC<ClientTypeProps> = ({ seat, edit = false, ...rest }) => {
    const { isFetching, options } = useClientType();
    const { clientType } = seat;
    return (
        <Form.Item
            label={INVENTORY_FORM_FIELDS.CLIENT_TYPE.label}
            name={INVENTORY_FORM_FIELDS.CLIENT_TYPE.name}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 24, xxl: 20 }}
            {...rest}
        >
            {edit ? (
                <Select
                    showSearch
                    labelInValue
                    options={options}
                    loading={isFetching}
                    optionFilterProp="label"
                    style={{ width: "100%" }}
                    placeholder={INVENTORY_FORM_FIELDS.CLIENT_TYPE.placeholder}
                />
            ) : (
                clientType?.name || NONE
            )}
        </Form.Item>
    );
};
