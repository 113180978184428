import { MarketplaceInfo, useGetTimeZonesQuery } from "@app/core/services";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { Form } from "antd";
import { DEAL_TYPES } from "../DealTypeSelect";
import { useSyncedFieldsProducer } from "@app/features/syncedFields";

export const useTimeZoneSelect = () => {
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const isReadonly = dealObjectMode === "view";
    const dealType = Form.useWatch<MarketplaceInfo["deal"]["dealType"]>(["dealUnderEdit", "dealType"]);
    const isVisible = dealType?.name !== DEAL_TYPES.DEAL_TYPE_LEGACY.name;
    const { data: timeZones = [], isFetching: isLoading } = useGetTimeZonesQuery(undefined, {
        skip: isReadonly || !isVisible,
    });
    const { updateSyncedFields } = useSyncedFieldsProducer();
    const onChange = (value) => {
        updateSyncedFields({ timeZone: value });
    };
    return { timeZones, isLoading, isVisible, isReadonly, onChange };
};
