import { Form } from "antd";
import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { useGetAdUnitsApiFrameworksQuery } from "@app/core/services";
import { MIMES } from "@app/features/inventory/HierarchyForms/constants";
const { useFormInstance } = Form;
interface UseAdUnitSupportedAPIs {
    isFetching: boolean;
    options: LabeledValue[];
    onChange: (value: LabeledValue[], option: LabeledValue[]) => void;
}

export const useAdUnitSupportedAPIs = (): UseAdUnitSupportedAPIs => {
    const form = useFormInstance();
    const { data, isFetching } = useGetAdUnitsApiFrameworksQuery();
    const options: LabeledValue[] = useMemo(
        () => (data || []).map((framework) => ({ value: framework.id, label: framework.name })),
        [data]
    );
    const onChange = (): Promise<void> => form.validateFields([MIMES]);

    return {
        isFetching,
        onChange,
        options,
    };
};
