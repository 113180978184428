import { AdvancedBrandSafety } from "@app/core/services";
import { Card } from "antd";
import { FC } from "react";
import { ReadOnlyBrandSafetyTargeting } from "@app/features/targeting/ReadOnlyTargeting/ReadOnlyBrandSafetyTargeting";
import { css } from "@emotion/css";

interface Props {
    brandSafety?: AdvancedBrandSafety;
}

export const BrandSafetyTargetingSection: FC<Props> = ({ brandSafety }) => {
    return (
        <Card
            bordered={false}
            className={css`
                &.ant-card {
                    box-shadow: none;
                }
                .ant-card-body {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            `}
        >
            {brandSafety?.targeting && (
                <ReadOnlyBrandSafetyTargeting
                    targeting={brandSafety?.targeting}
                    targetingMode={brandSafety?.targetingMode}
                />
            )}
        </Card>
    );
};
