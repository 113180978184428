import { FC } from "react";
import { Row, Col, Space, Typography, Switch } from "antd";
import { HelpTooltip } from "@app/core/components";
import { TargetingDimensionHelpKeys } from "../constants";
import { InfoCircleFilled } from "@ant-design/icons";
import { gold6 } from "@rubicon/antd-components";

interface Props {
    adSourceTargetingExists: boolean;
    showExistingAdSourceTargeting: boolean;
    onShowExistingAdSourceTargetingChange: (showExistingAdSourceTargeting: boolean) => void;
}

export const DemandConfigurationTargetingSectionHeader: FC<Props> = ({
    adSourceTargetingExists,
    showExistingAdSourceTargeting,
    onShowExistingAdSourceTargetingChange,
}) => {
    return (
        <Row gutter={32}>
            <Col xs={24}>
                {adSourceTargetingExists ? (
                    <Space>
                        <Typography.Title level={5} style={{ marginBottom: "24px" }}>
                            <span style={{ fontWeight: 500 }}>Ad Source Targeting: Yes</span>

                            <span style={{ marginLeft: "10px" }}>
                                <Switch
                                    onChange={onShowExistingAdSourceTargetingChange}
                                    checked={showExistingAdSourceTargeting}
                                    style={{ marginRight: "10px" }}
                                />
                                Show Inherited Targets from Ad Source
                                <HelpTooltip
                                    helpKeyList={TargetingDimensionHelpKeys}
                                    helpKey={TargetingDimensionHelpKeys.TargetingDealsWithTargeting}
                                    tooltipPlacement={"topRight"}
                                    popover={true}
                                >
                                    &nbsp; <InfoCircleFilled style={{ color: `${gold6}` }} />
                                </HelpTooltip>
                            </span>
                        </Typography.Title>
                    </Space>
                ) : (
                    <Typography.Title level={5} style={{ marginBottom: "24px" }}>
                        <span style={{ fontWeight: 500 }}>Ad Source Targeting: No</span>
                    </Typography.Title>
                )}
            </Col>
        </Row>
    );
};
