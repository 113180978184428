import { FC } from "react";
import { Layout } from "antd";
import TagParamsHeader from "./TagParamsHeader";
import TagParamsToolbar from "./TagParamsToolbar";
import TagParamsTable from "./TagParamsTable/TagParamsTable";

export const TagParamsPage: FC = () => (
    <Layout>
        <TagParamsHeader />
        <Layout.Content>
            <TagParamsToolbar />
            <TagParamsTable />
        </Layout.Content>
    </Layout>
);
