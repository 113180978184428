import { Form } from "antd";
import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { useGetUdeLidDiscoveryModesQuery } from "@app/core/services/console/udeLidDiscoveryModes";

const { useWatch } = Form;

interface UseUdLidDiscoveryMode {
    options: LabeledValue[];
    isFetching: boolean;
    isUdEnabled: boolean;
}

export const useUdLidDiscoveryMode = (): UseUdLidDiscoveryMode => {
    const { data, isFetching } = useGetUdeLidDiscoveryModesQuery();

    const options: LabeledValue[] = useMemo(
        () => (data || []).map(({ id, name }) => ({ label: name, value: id })),
        [data]
    );
    const isUdEnabled = useWatch(INVENTORY_FORM_FIELDS.UD_ENABLED.name);

    return {
        options,
        isFetching,
        isUdEnabled,
    };
};
