import { FC } from "react";
import { Form, Select } from "antd";
import { useCategories } from "../InventoryAdvancedBrandSafetyPageForm/BrandSafetyFormFields/useCategories";
import { TagRender } from "@app/core/components";

interface Props {
    name: string;
    label: string;
}

export const BlockedIABCategoriesFormItem: FC<Props> = ({ name, label }) => {
    const { search, options, isFetching, setSearch } = useCategories();

    return (
        <Form.Item name={name} label={label} style={{ maxWidth: "615px" }}>
            <Select
                allowClear
                showSearch
                labelInValue
                mode="multiple"
                options={options}
                searchValue={search}
                filterOption={false}
                loading={isFetching}
                onSearch={setSearch}
                tagRender={TagRender}
                autoClearSearchValue={false}
                placeholder="Select Block IAB Categories"
            />
        </Form.Item>
    );
};
