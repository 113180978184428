import { useAppDispatch, useAppSelector } from "@app/core/store";
import {
    selectSeatAdSourcesListGridViewFilteredPriorityCandidates as selectFilteredPriorityCandidates,
    setGridViewPriorityPagination,
} from "@app/features/seatAdSources/SeatAdSourcesListPage/reducer";
import { PaginationConfig } from "antd/es/pagination";

export const useSeatAdSourcesListGridPriorityPagination = (priority: number): PaginationConfig => {
    const dispatch = useAppDispatch();
    const { page, max } = useAppSelector(selectFilteredPriorityCandidates)[priority].pagination;

    const onChange = (page: number, max: number) => {
        dispatch(setGridViewPriorityPagination({ priority, page, max }));
    };

    return {
        onChange,
        pageSize: max,
        current: page,
    };
};
