import { FC } from "react";
import { LoadingCell } from "./LoadingCell";
import { AnimatedNumberCell, AnimatedNumberCellProps } from "./AnimatedNumberCell";

interface Props extends AnimatedNumberCellProps {
    isLoading?: boolean;
}

export const LoadableAnimatedNumberCell: FC<Props> = ({
    value,
    isLoading = value === undefined,
    isMoney,
    currencyCode,
    isPercent,
    prefix,
    suffix,
}) => {
    if (isLoading) {
        return <LoadingCell />;
    }

    return (
        <span data-sdet="loadable-animated-number-cell">
            <AnimatedNumberCell
                currencyCode={currencyCode}
                isMoney={isMoney}
                isPercent={isPercent}
                prefix={prefix}
                suffix={suffix}
                value={value}
            />
        </span>
    );
};
