import axios from "axios";
import conf from "@app/core/conf";

interface Block {
    type: string;
    fields?: Block[];
    text?: string | Block;
}

interface Message {
    text?: string;
    blocks?: (Block | null | "")[];
}

export const sendMessage = (message: Message) => {
    return axios.post(`${conf.middleEndApiRoot}/api/v1/errorReporting/streaming`, message);
};
