import { FC } from "react";
import { NavMenu } from "@rubicon/antd-components";
import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useControlsNavAccess } from "./useControlsNavAccess";

export const ControlsNavMenu: FC = () => {
    const history = useHistory();
    const { seatId, tab } = useParams<{ seatId: string; tab: string }>();
    const { controlsNavItems } = useControlsNavAccess();

    const handleClick = ({ key }) => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, key));

    return <NavMenu selectedKey={tab} onClick={handleClick} items={controlsNavItems} />;
};
