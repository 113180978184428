import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { ReportsIFramePage } from "./ReportsIFramePage";
import { ReportsResultsIFramePage } from "./ReportsResultsIFramePage";
import { ReportsTabsIFramePage } from "./ReportsTabsIFramePage";

export const ReportsIFrameSwitch: FC = () => {
    return (
        <Switch>
            <Route exact path={ROUTES.SEAT_REPORTS_CONSOLE}>
                <ReportsIFramePage />
            </Route>
            <Route exact path={ROUTES.SEAT_REPORTS_CONSOLE_TABS}>
                <ReportsTabsIFramePage />
            </Route>
            <Route exact path={ROUTES.SEAT_REPORTS_CONSOLE_RESULTS}>
                <ReportsResultsIFramePage />
            </Route>
        </Switch>
    );
};
