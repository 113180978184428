import { FC, useCallback, useContext } from "react";
import { Form } from "antd";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { AdSourceFormsContext } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { useFilteredAdSourceStatuses } from "@app/features/seatAdSources/data";
import { useSyncedFieldsProducer } from "@app/features/syncedFields";

export const StatusSelect: FC = () => {
    const { adSourceFormMode } = useContext(AdSourceFormsContext).forms;
    const { statuses, isFetching } = useFilteredAdSourceStatuses(adSourceFormMode);
    const { updateSyncedFields } = useSyncedFieldsProducer();

    const onChange = useCallback(
        (value) => {
            updateSyncedFields({ status: value });
        },
        [updateSyncedFields]
    );

    return (
        <Form.Item {...VERTICAL_LAYOUT_FIX} label={AD_SOURCE_FIELDS.STATUS.label} name={AD_SOURCE_FIELDS.STATUS.name}>
            <SelectWithValue
                placeholder="Select…"
                loading={isFetching}
                options={statuses}
                fieldAsLabel={["name"]}
                fieldAsValue={["id"]}
                data-sdet="adsource-status"
                onChange={onChange}
            />
        </Form.Item>
    );
};
