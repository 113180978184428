import { useNestedData } from "../useNestedData";

interface UseDefinitionUnitContent {
    isLoading: boolean;
    breadcrumbs?: string;
}
export const useDefinitionUnitContent = (id: string | number): UseDefinitionUnitContent => {
    const { nestedBreadcrumbs, isLoading } = useNestedData(id);
    return {
        isLoading,
        breadcrumbs: nestedBreadcrumbs,
    };
};
