import { BidderConfig } from "./adSources";
import { consoleApi, PBS_BIDDER_CONFIGS_TAG } from "./console";

export const pbsBidderConfigsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getPbsBidderConfigs: builder.query<BidderConfig[], number>({
            query: (id: number) => {
                return `seats/${id}/pbsBidderConfigs`;
            },
            providesTags: (_, __, id) => [{ type: PBS_BIDDER_CONFIGS_TAG, id }],
        }),
    }),
    overrideExisting: false,
});

export const { useGetPbsBidderConfigsQuery } = pbsBidderConfigsApi;
