import { Card, Col, Form, Layout, Row, Space, Typography } from "antd";
import { FC } from "react";
import { RecursiveCopyHelper } from "../BulkOperationDetailsStep/BulkOperationCopyForm/CopyInventoryChildrenField";
import { useParams } from "react-router-dom";
import { BulkOperationSourceEntity, useGetSeatSelfQuery } from "@app/core/services";
import { format } from "@rubicon/utils";
import { BulkOperationCopyConfirmationStepBottomButtons } from "./BulkOperationConfirmationStepBottomButtons/BulkOperationCopyConfirmationStepBottomButtons";

export const getParentLevel = (sourceEntity: BulkOperationSourceEntity) => {
    switch (sourceEntity) {
        case BulkOperationSourceEntity.Publisher:
            return "Seat";
        case BulkOperationSourceEntity.Brand:
            return "Publisher";
        case BulkOperationSourceEntity.Supply:
            return "Brand";
        case BulkOperationSourceEntity.AdUnit:
            return "Supply";
        default:
            return format.constants.DEFAULT_DASHES;
    }
};
export const BulkOperationCopyReview: FC<{ goToPrev: () => void }> = ({ goToPrev }) => {
    const form = Form.useFormInstance();
    const {
        sourceEntity,
        sourceEntityId,
        recursiveCopy,
        copyScope,
        destinationSeat,
        destinationEntityName,
        destinationEntityId,
    } = form.getFieldsValue(true);

    const { seatId } = useParams<{ seatId: string }>();
    const { data: seatSelfData } = useGetSeatSelfQuery(seatId);
    const seat = seatSelfData?.seat;

    return (
        <>
            <Layout.Content>
                <Typography.Title level={4} style={{ marginTop: 0 }}>
                    Copy Job Details
                </Typography.Title>
                <Card>
                    <Row justify="space-between">
                        <Col xs={24} md={11}>
                            <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 16 }}>
                                Source Information
                            </Typography.Title>
                            <Form.Item label="Seat">{seat?.name}</Form.Item>
                            <Form.Item label="Inventory Level">
                                {sourceEntity.charAt(0).toUpperCase() + sourceEntity.slice(1)}
                            </Form.Item>
                            <Form.Item label="Inventory Name">{sourceEntityId.label.split(">").pop().trim()}</Form.Item>
                            <Form.Item label="Including Inventory Children">
                                {recursiveCopy ? (
                                    <Space>
                                        <span>Yes</span>
                                        <RecursiveCopyHelper scope={copyScope} />
                                    </Space>
                                ) : (
                                    "No"
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={11}>
                            <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 16 }}>
                                Destination Information
                            </Typography.Title>
                            <Form.Item label="New Inventory Name">{destinationEntityName}</Form.Item>
                            <Form.Item label="Parent Seat">{destinationSeat?.label || seat?.name}</Form.Item>
                            <Form.Item label="Parent Level">{getParentLevel(sourceEntity)}</Form.Item>
                            <Form.Item label="Parent Name">
                                {destinationEntityId.label.split(">").pop().trim()}
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Layout.Content>
            <BulkOperationCopyConfirmationStepBottomButtons goToPrev={goToPrev} form={form} />
        </>
    );
};
