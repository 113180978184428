import { Loading } from "@app/core/components";
import { ResizableDrawer } from "@rubicon/antd-components";
import { useTransparencyExtenderUserDrawer } from "./useTransparencyExtenderUserDrawer";
import { Form } from "antd";
import { ExtendedUserIdDrawerContent } from "./ExtendedUserIdDrawerContent";
import { LV } from "../constants";
import { useAppSelector } from "@app/core/store";
import { selectExtendedIds } from "../../reducer";
import { drawerRightClosePosition } from "@app/features/controls/constants";

export const TRANSPARENCY_EXTEND_USER_FORM_SDET = "transparency-extended-user-form";
interface Props {
    handleAdd: (v1: LV, v2: LV, callback?: () => void) => void;
    handleRemove: (val: string, adSourceTypeId: number) => void;
}
export const TransparencyExtendedUserDrawer = ({ handleAdd, handleRemove }: Props) => {
    const { isLoading, drawerId, form, handleDrawerClose } = useTransparencyExtenderUserDrawer();
    const extendedIds = useAppSelector(selectExtendedIds);

    isLoading || (!drawerId && <Loading />);

    return (
        <ResizableDrawer
            title="Extended User Id"
            open={Boolean(drawerId)}
            onClose={handleDrawerClose}
            placement="right"
            width="45%"
            className={drawerRightClosePosition}
        >
            <Form
                size="small"
                layout="vertical"
                form={form}
                name="extendedUserId"
                initialValues={{ extendedId: null, network: null }}
                data-sdet={TRANSPARENCY_EXTEND_USER_FORM_SDET}
                wrapperCol={{ xs: 24, md: 24 }}
            >
                <ExtendedUserIdDrawerContent
                    handleAdd={handleAdd}
                    handleRemove={handleRemove}
                    extendedIdsByAdSourceId={extendedIds?.[Number(drawerId)]}
                />
            </Form>
        </ResizableDrawer>
    );
};
