import { Route, Switch } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { DealDetailsDrawer } from "./DealDetailsDrawer";

export const DealDetailsDrawerWrapper = () => {
    return (
        <>
            <Switch>
                <Route
                    exact
                    path={[
                        ROUTES.SEAT_DEAL_HEALTH_DEAL_DETAILS_DRAWER,
                        ROUTES.SEAT_DEAL_HEALTH_DEAL_DETAILS_DRAWER_SECONDARY_VIEW,
                        ROUTES.SEAT_DEAL_HEALTH_DEAL_SECONDARY_VIEW,
                        ROUTES.SEAT_DEALS_DEAL_DETAILS_DRAWER,
                        ROUTES.SEAT_DEALS_DEAL_DETAILS_DRAWER_SECONDARY_VIEW,
                        ROUTES.SEAT_DEALS_SECONDARY_VIEW,
                        ROUTES.SEAT_DEAL_HEALTH,
                        ROUTES.SEAT_DEAL_LIST,
                        ROUTES.SEAT_DEAL_DETAILS,
                    ]}
                >
                    <DealDetailsDrawer />
                </Route>
            </Switch>
        </>
    );
};
