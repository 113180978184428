import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@app/core/store";
import { ENTITY_TYPES } from "./constants";

export interface RecycleBinForm {
    name: string;
    macro: string;
    tagParamId: number;
    id: number;
    selected: boolean;
    isDirty: boolean;
}

export interface KeyedRecycleBin {
    [key: number]: RecycleBinForm;
}
export interface RecycleBin {
    selectedByEntity: {
        [entity: string]: number[];
    };
}

export const initialState: RecycleBin = {
    selectedByEntity: {
        [ENTITY_TYPES.AD_SOURCE]: [],
        [ENTITY_TYPES.AD_UNIT]: [],
        [ENTITY_TYPES.ADVERTISER]: [],
        [ENTITY_TYPES.BRAND]: [],
        [ENTITY_TYPES.BRAND_CHANNEL]: [],
        [ENTITY_TYPES.BUYER_DEAL]: [],
        [ENTITY_TYPES.CHANNEL]: [],
        [ENTITY_TYPES.DEAL]: [],
        [ENTITY_TYPES.PUBLISHER]: [],
        [ENTITY_TYPES.INDUSTRY]: [],
        [ENTITY_TYPES.ORDER]: [],
        [ENTITY_TYPES.SUPPLY]: [],
        [ENTITY_TYPES.LINE_ITEM]: [],
    },
};

const recycleBinSlice = createSlice({
    name: "recycleBin",
    initialState,
    reducers: {
        pickSelected: (state, action: PayloadAction<{ entity: string; ids: number[] }>) => {
            const { entity, ids } = action.payload;
            state.selectedByEntity[entity] = ids;
        },
    },
});

export const selectSelectedEntities = (state: RootState) => state.recycleBin.selectedByEntity;
export const { pickSelected } = recycleBinSlice.actions;

export const recycleBinReducer = recycleBinSlice.reducer;
