import { ClassificationsIndustryForm } from "./ClassificationsIndustryForm";
import { useHistory, useParams } from "react-router-dom";
import { useNotificationError } from "@app/core/components/hooks";
import { Loading } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useGetIndustryDetailsQuery } from "@app/core/services";
import { ClassificationsIndustryDefinitionsButtonAndDrawer } from "./ClassificationsIndustryDefinitions";
import { PageHeader } from "@ant-design/pro-layout";

export const ClassificationsIndustryEditPage = () => {
    const { seatId, industryId } = useParams<{ seatId: string; industryId: string }>();

    const { data: industry, isLoading, error } = useGetIndustryDetailsQuery(Number(industryId));

    useNotificationError(error);

    const history = useHistory();
    if (isLoading) return <Loading />;

    if (!industry) return null;

    return (
        <>
            <PageHeader
                onBack={() =>
                    history.push(
                        ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_DETAILS_PAGE(seatId, industryId)
                    )
                }
                title={`Edit: ${industry.name}`}
                extra={<ClassificationsIndustryDefinitionsButtonAndDrawer />}
            />
            <ClassificationsIndustryForm industry={industry} />
        </>
    );
};
