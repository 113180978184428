import { FC } from "react";
import { Space, Spin } from "antd";
import { css } from "@emotion/css";

export const LoadingMask: FC = () => {
    return (
        <Space
            data-sdet="loading-mask"
            className={css`
                position: fixed;
                background-color: rgba(0, 0, 0, 0.1);
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                z-index: 1000;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            `}
        >
            <Spin size="large" />
        </Space>
    );
};
