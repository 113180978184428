import { FC } from "react";
import { useAppSelector } from "@app/core/store";
import { Card, Col, Divider, Row, Tooltip } from "antd";
import { cardBodyStyle } from "@app/features/deals/DealForm";
import { CREATE_DEAL_FORM_ITEMS_NAME, GUTTER } from "@app/features/deals/constants";
import { AdSourceAdvertiserDomainModeIds } from "@app/features/seatAdSources/constants";
import {
    AdSourceModeField,
    AdSourceIABCategoriesField,
    AdSourceIABCategoryBlockModeField,
    AdSourceReusableAdvertiserDomainField,
    AdSourceAdditionalAdvertiserDomainField,
    AdSourceIabCategoryBlockExceptionField,
} from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceBrandSafetySection/Fields";

export const AdSourceBrandSafetySection: FC = () => {
    const adSourceMode: number | null = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE]
    );
    const isOverrideInventoryBlockedDomains: boolean =
        adSourceMode === AdSourceAdvertiserDomainModeIds.ALLOW_OVERRIDE_INVENTORY;

    return (
        <Card bordered={false} styles={{ body: cardBodyStyle }} data-sdet="deal-form-ad-source-brand-safety-section">
            <Row gutter={GUTTER}>
                <Col xs={24} sm={24} md={12} lg={12} xl={16} xxl={14}>
                    <AdSourceModeField />
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col xs={24} sm={24} md={12} lg={12} xl={16} xxl={14}>
                    <AdSourceReusableAdvertiserDomainField />
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col xs={24} sm={24} md={12} lg={12} xl={16} xxl={14}>
                    <AdSourceAdditionalAdvertiserDomainField />
                </Col>
            </Row>
            <Divider />
            <Tooltip
                placement="topLeft"
                title={
                    isOverrideInventoryBlockedDomains
                        ? "IAB Category Block is ignored when you allow specified Advertisers to run"
                        : undefined
                }
            >
                <Row gutter={GUTTER}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={16} xxl={14}>
                        <AdSourceIABCategoryBlockModeField />
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={16} xxl={14}>
                        <AdSourceIABCategoriesField />
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={16} xxl={14}>
                        <AdSourceIabCategoryBlockExceptionField />
                    </Col>
                </Row>
            </Tooltip>
        </Card>
    );
};
