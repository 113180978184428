import { PageHeader } from "@ant-design/pro-layout";
import { Layout } from "antd";
import { CompetitiveSeparationGroupsForm } from "../CompetitiveSeparationGroupsForm";
import { useCompetitiveSeparationGroupCreatePage } from "./useCompetitiveSeparationGroupCreatePage";

export const CompetitiveSeparationGroupCreatePage = () => {
    const { isSubmitting, handleCancel, handleSubmit, initialValues } = useCompetitiveSeparationGroupCreatePage();

    return (
        <Layout>
            <PageHeader title="Add Separation Group" onBack={handleCancel} />
            <CompetitiveSeparationGroupsForm
                initialValues={initialValues}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
            />
        </Layout>
    );
};
