import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { Form } from "antd";
import { usePixelsMultiselect } from "./usePixelsMultiselect";
import { TagRender } from "@app/core/components";

export const PixelsMultiselect = () => {
    const { pixels, isLoading } = usePixelsMultiselect();
    return (
        <Form.Item
            label={AD_SOURCE_FIELDS.PIXELS.label}
            name={AD_SOURCE_FIELDS.PIXELS.name}
            colon={false}
            {...VERTICAL_LAYOUT_FIX}
        >
            <SelectWithValue
                allowClear
                showSearch
                optionFilterProp="label"
                options={pixels}
                loading={isLoading}
                placeholder="Select Custom Pixels..."
                mode="multiple"
                tagRender={TagRender}
                fieldAsLabel={["name"]}
                fieldAsValue={["id"]}
            />
        </Form.Item>
    );
};
