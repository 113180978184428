import { consoleApi, EXTERNAL_CODE_NAMESPACES_TAG } from "./console";

interface ExternalCodeNamespace {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    entityType: "ExternalAdUnitCodeNamespace";
}

export const externalCodeNamespacesApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getExternalCodeNamespaces: builder.query<ExternalCodeNamespace[], void>({
            query: () => "externalCodeNamespaces",
            providesTags: [EXTERNAL_CODE_NAMESPACES_TAG],
        }),
    }),
});

export const { useGetExternalCodeNamespacesQuery } = externalCodeNamespacesApi;
