import { Card } from "antd";
import { FC } from "react";
import { INVENTORY_CARD_BODY_STYLE } from "@app/features/inventory/constants";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useBrandChannelFormInstance } from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormInstance";
import { BrandChannelLabels } from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormSections/AdvancedFeaturesSection/Sections/LabelsSection/Fields/BrandChannelLabels";

export const LabelsInternalOnly: FC = () => {
    const { seat } = useBrandChannelFormInstance();
    return (
        <Card title="Internal Only" type="inner" bodyStyle={INVENTORY_CARD_BODY_STYLE}>
            <BrandChannelLabels {...CHANNEL_FORM_FIELDS.INTERNAL_LABELS} />
            {seat?.distributionGroupsEnabled && (
                <BrandChannelLabels {...CHANNEL_FORM_FIELDS.DISTRIBUTION_GROUP_LABELS} />
            )}
        </Card>
    );
};
