import { FC } from "react";
import { Checkbox, Col, Form, Input, Row } from "antd";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useSupplyName } from "./useSupplyName";

export const SupplyName: FC = () => {
    const { disabled, extra, onChange, placeholder } = useSupplyName();
    return (
        <Row gutter={[16, 0]} align="middle">
            <Col xs={24} sm={24} md={20} lg={14} xl={17} xxl={19}>
                <Form.Item
                    extra={extra}
                    name={CHANNEL_FORM_FIELDS.SUPPLY_NAME.name}
                    label={CHANNEL_FORM_FIELDS.SUPPLY_NAME.label}
                >
                    <Input allowClear disabled={disabled} placeholder={placeholder} />
                </Form.Item>
            </Col>
            <Col>
                <Form.Item name={CHANNEL_FORM_FIELDS.SUPPLY_OVERRIDE.name} valuePropName="checked">
                    <Checkbox onChange={onChange}>{CHANNEL_FORM_FIELDS.SUPPLY_OVERRIDE.label}</Checkbox>
                </Form.Item>
            </Col>
        </Row>
    );
};
