import { Button, Table, Typography } from "antd";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { ListAndMore } from "./ListAndMore";
import { SortOrder } from "antd/lib/table/interface";
import { usePagination, useTableChanges } from "@app/core/components/hooks";
import { uri } from "@rubicon/utils";
import { useUserAccess } from "@app/core/auth";
import { ColumnsType } from "antd/es/table";
import { Advertiser, useGetSeatAdvertisersQuery } from "@app/core/services";
import { SEARCH } from "@app/core/components";
import { brandCobalt } from "@rubicon/antd-components";

export const CLASSIFICATIONS_ADVERTISERS_TABLE_SDET = "classificatins-advertisers-table";

export const getAdvertisersColumns = (
    sortBy: string,
    sortOrder: SortOrder,
    canEditSeat: boolean,
    onClickName: (advertiserId: string | number) => void,
    onClickAndMore: (advertiserId: string | number) => void,
    onClickTransfer: (advertiserId: string | number) => void
) =>
    [
        {
            key: "name",
            dataIndex: "name",
            title: "Name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortOrder: sortBy === "name" ? sortOrder : null,
            render: (name, { id: advertiserId }) => (
                <Typography.Link style={{ color: brandCobalt }} onClick={() => onClickName(advertiserId)}>
                    {name}
                </Typography.Link>
            ),
        },
        {
            key: "advertiserDomains",
            dataIndex: "advertiserDomains",
            title: "Advertiser Domains",
            render: (advertiserDomains, { id }) => {
                return (
                    <ListAndMore
                        list={advertiserDomains.map(({ adomain }) => adomain)}
                        onClick={() => onClickAndMore(id)}
                    />
                );
            },
        },
        canEditSeat && {
            key: "actions",
            dataIndex: "actions",
            title: "Actions",
            render: (_, { id }) => <Button onClick={() => onClickTransfer(id)}>Transfer</Button>,
        },
    ].filter(Boolean) as ColumnsType<Advertiser>;

export const ClassificationsAdvertisersTable = () => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const { search: queryString } = useLocation();

    const keyword = new URLSearchParams(queryString).get(SEARCH.key) || SEARCH.default;
    const { data = [], isFetching } = useGetSeatAdvertisersQuery({ seatId: Number(seatId), params: { keyword } });

    const { handleChange, paginationConfig } = usePagination({ totalCount: data?.length || 0 });
    const { onChange, sortBy, sortOrder } = useTableChanges({ handlePaginateChange: handleChange });
    const { canEditSeat } = useUserAccess();

    const onClickName = (advertiserId: string | number) => {
        const setDrawerParam = uri.setSearchParam(queryString, "drawer", "details");
        const nextQueryString = uri.setSearchParam(setDrawerParam, "advertiserId", String(advertiserId));
        history.push({
            search: nextQueryString,
        });
    };
    const onClickAndMore = (advertiserId: string | number) => {
        const setDrawerParam = uri.setSearchParam(queryString, "drawer", "adomains");
        const nextQueryString = uri.setSearchParam(setDrawerParam, "advertiserId", String(advertiserId));
        history.push({
            search: nextQueryString,
        });
    };
    const onClickTransfer = (advertiserId: string | number) => {
        const setDrawerParam = uri.setSearchParam(queryString, "drawer", "transfer");
        const nextQueryString = uri.setSearchParam(setDrawerParam, "advertiserId", String(advertiserId));
        history.push({
            search: nextQueryString,
        });
    };

    return (
        <Table
            size="small"
            data-sdet={CLASSIFICATIONS_ADVERTISERS_TABLE_SDET}
            onChange={onChange}
            showSorterTooltip={false}
            dataSource={data}
            columns={getAdvertisersColumns(
                sortBy,
                sortOrder,
                canEditSeat,
                onClickName,
                onClickAndMore,
                onClickTransfer
            )}
            loading={isFetching}
            pagination={paginationConfig}
            rowKey="id"
        />
    );
};
