import { analytics } from "@rubicon/utils";

type TrackEventParams = {
    action: string;
    callback?: () => void;
    category: string;
    label?: string;
    value?: number;
};

type TrackPageviewParams = {
    callback?: () => void;
    outbound?: boolean;
    title?: string;
    url: string;
};

export const googleTrackingId = "TODO: Change this to the appropriate value";

// TODO: Update with the appropriate values from MCTV
const getUserUid = (user: Record<string, unknown>): string => {
    const { id: userId, contextType, account, seat } = user;
    const accountId = account ? (account as { id: number }).id : null;
    const seatId = seat ? (seat as { id: number }).id : null;

    return `${String(contextType)}-${accountId || String(seatId)}-${String(userId)}`;
};

class GoogleAnalyticsService {
    tid: string;
    uuid: string;
    visitor: analytics.Analytics;

    constructor(tid: string, uuid = "") {
        this.tid = tid;
        this.uuid = uuid;
        this.visitor = new analytics.Analytics(tid, uuid);
    }

    trackEvent({ action, category, label, value, callback }: TrackEventParams): void {
        this.visitor.trackEvent({ action, category, label, value, callback });
    }

    trackPageview({ url, title, callback, outbound }: TrackPageviewParams): void {
        this.visitor.trackPageview({ url, title, callback, outbound });
    }

    /*
     * Google Analytics can track events/pageviews to a specific user. We use the
     * values provided by auth to create an unique uid. Depending on if the user
     * is a buyer or publisher, we use the seat id or publisher id, respectively.
     * cd1 and cd2 are custom dimensions that are pre-set in Google Analytics.
     */
    // TODO: Update with the appropriate values from MCTV
    setUser(user: Record<string, unknown>): void {
        this.uuid = getUserUid(user);
        const { account, contextType, email } = user;

        const accountId = account ? (account as { id: number }).id : null;

        const isRubiconUser = (email as string).indexOf("@rubiconproject.com") !== -1 ? "yes" : "no";

        // IMPORTANT: These dimensions need to be added to
        // the GA property for this app in order to be tracked.
        // https://wiki.rubiconproject.com/display/PLAT/Custom+Dimensions
        // Note: The keys for dimensions are 'cdN', NOT 'dimensionN'.
        // The N is the index for the given dimension as defined in GA.
        const dimensions = {
            cd1: contextType,
            cd2: isRubiconUser,
            cd3: accountId || "missing-account-id",
        };

        this.visitor = new analytics.Analytics(this.tid, this.uuid);
        this.visitor.setUser(dimensions);
    }

    getUser() {
        return this.visitor;
    }
}

export const analyticsService = new GoogleAnalyticsService(googleTrackingId);
