import { FC } from "react";
import { LabeledValue } from "antd/lib/select";
import { TagRender } from "@app/core/components/TagRender";
import { SelectAllButtonDropdownRender } from "@app/core/components/SelectAllButtonDropdownRender";
import { LoadOnScrollSelect } from "@app/core/components";
import { useGetSeatSegmentsQuery } from "@app/core/services/console";
import { segmentsToLabelValue } from "../../../helpers";
import { useDimensionRowSearch } from "../useDimensionRowSearch";
import { getParams } from "./helpers";

interface Props {
    onChange: (value: LabeledValue[]) => void;
    value: LabeledValue[] | undefined;
}

export const SegmentsSelect: FC<Props> = ({ onChange, value }) => {
    const [search, handleChangeSearch, options, isFetching, hasMore, loadMore, isCurrent] = useDimensionRowSearch(
        useGetSeatSegmentsQuery,
        segmentsToLabelValue,
        getParams
    );

    return (
        <LoadOnScrollSelect
            allowClear
            dropdownRender={(menu) => (
                <SelectAllButtonDropdownRender<LabeledValue>
                    getPrimaryKey={(v) => v.value}
                    isCurrent={isCurrent}
                    menu={menu}
                    onChange={onChange}
                    options={options}
                    search={search}
                    value={value}
                />
            )}
            filterOption={false}
            hasMore={hasMore}
            labelInValue
            loadMore={loadMore}
            loading={isFetching}
            mode="multiple"
            onBlur={() => handleChangeSearch("")}
            onChange={onChange}
            onSearch={handleChangeSearch}
            options={options}
            placeholder="Select Values"
            searchValue={search}
            style={{ width: "100%" }}
            tagRender={TagRender}
            value={value}
        />
    );
};
