import { useGetAdUnitsApiFrameworksQuery } from "@app/core/services";
import { useMemo } from "react";
import { apiFrameworkToLabelValue } from "../../../../helpers";

export const useApiFrameworksSelect = () => {
    const { data, isFetching } = useGetAdUnitsApiFrameworksQuery();
    const options = useMemo(() => apiFrameworkToLabelValue(data || []), [data]);

    return {
        isLoading: isFetching,
        options,
    };
};
