import { HelpKeysDrawer, HelpKeysDrawerContent } from "@app/core/components/HelpKeysDrawer";

export const PRICE_OVERRIDE_DEFINITION_FIELDS = [
    { label: "Name", key: "name", helpPath: "priceOverrideConfig.name" },
    { label: "Ad Unit", key: "adUnit", helpPath: "priceOverrideConfig.adUnit" },
    { label: "Price Override Type", key: "priceOverrideType", helpPath: "priceOverrideConfig.priceOverrideType" },
    { label: "Override Price", key: "overridePrice", helpPath: "priceOverrideConfig.overridePrice" },
];

export const PriceOverrideDefinitionsContent = () => (
    <HelpKeysDrawerContent definitionsFields={PRICE_OVERRIDE_DEFINITION_FIELDS} />
);

export const PriceOverrideDefinitionsButtonAndDrawer = () => (
    <HelpKeysDrawer
        buttonName="Price Overrides"
        panelLabel="Price Override Definitions"
        definitionsFields={PRICE_OVERRIDE_DEFINITION_FIELDS}
    />
);
