import { FC, useState } from "react";
import { Col, Input, Row, Space, Typography } from "antd";
import { Conditions, TargetingDimensionTypeLabels } from "../../constants";
import { BundleIdTargetsDimension, UsedDimensions } from "../../types";
import { ConditionSelect } from "../ConditionSelect";
import { MoreMenu } from "../MoreMenu";

interface Props {
    dimension: BundleIdTargetsDimension;
    onChangeCondition: (value: Conditions) => void;
    onChangeDimensionValues: (value: string[]) => void;
    onClickDelete: () => void;
    onClickDownload: () => void;
    onClickDuplicate: () => void;
    usedDimensions: UsedDimensions;
}

export const BundleIdTargetsDimensionRow: FC<Props> = ({
    dimension,
    onChangeCondition,
    onChangeDimensionValues,
    onClickDelete,
    onClickDownload,
    onClickDuplicate,
    usedDimensions,
}) => {
    const [text, setText] = useState<string>(dimension.values.join("\n"));
    const handleFinishedText = ({ target: { value } }) => {
        const domains = (value || "").split("\n");
        onChangeDimensionValues(domains);
    };
    return (
        <Row gutter={8}>
            <Col flex="150px">
                <Typography.Text strong>{TargetingDimensionTypeLabels.BundleIdTargets}</Typography.Text>
            </Col>
            <Col flex="128px">
                <ConditionSelect
                    onChange={onChangeCondition}
                    isExcludesDisabled={
                        dimension.condition !== Conditions.Excludes && usedDimensions.bundleIdTargets.excludes
                    }
                    isIncludesDisabled={
                        dimension.condition !== Conditions.Includes && usedDimensions.bundleIdTargets.includes
                    }
                    value={dimension.condition}
                />
            </Col>
            <Col flex="1">
                <Input.TextArea
                    data-sdet="BundleIDsDimensionSelect"
                    allowClear
                    autoSize
                    onBlur={handleFinishedText}
                    onChange={(e) => setText(e.target.value)}
                    placeholder={`Enter ${TargetingDimensionTypeLabels.BundleIdTargets} one per line`}
                    value={text}
                />
            </Col>
            <Col flex="146px" style={{ display: "flex", justifyContent: "flex-end" }}>
                <Space>
                    <MoreMenu
                        onClickDelete={onClickDelete}
                        onClickDownload={onClickDownload}
                        onClickDuplicate={onClickDuplicate}
                        isDuplicateDisabled={
                            usedDimensions.bundleIdTargets.includes || usedDimensions.bundleIdTargets.excludes
                        }
                    />
                </Space>
            </Col>
        </Row>
    );
};
