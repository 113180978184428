import { Form } from "antd";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { useUDPrioritySelect } from "./useUDPrioritySelect";
import { useIsFieldVisible } from "./useIsFieldVisible";

export const UDPrioritySelect = () => {
    const { isLoading, priorities } = useUDPrioritySelect();
    const isVisible = useIsFieldVisible();

    return isVisible ? (
        <Form.Item
            {...VERTICAL_LAYOUT_FIX}
            label={AD_SOURCE_FIELDS.UD_PRIORITY.label}
            name={AD_SOURCE_FIELDS.UD_PRIORITY.name}
        >
            <SelectWithValue
                placeholder="Select…"
                loading={isLoading}
                options={priorities}
                fieldAsValue={["id"]}
                fieldAsLabel={["name"]}
                data-sdet="adsource-ud-priority"
            />
        </Form.Item>
    ) : null;
};
