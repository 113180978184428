import { FC } from "react";
import { Button, Col, Row, Space, Typography } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { BracketGroup } from "@rubicon/antd-components";
import { red5 } from "@rubicon/antd-components";
import { CustomRuleIds, CustomRuleOperators, GroupConditions } from "@app/features/targeting/constants";
import { CustomRuleIdentifier, CustomRuleGroup, CustomRule } from "@app/features/targeting/types";
import { GroupConditionSelect } from "../../GroupConditionSelect";
import { CustomRuleRow } from "./CustomRuleRow";

interface Props {
    group: CustomRuleGroup;
    isValidationShown: boolean;
    onChangeCondition: (path: number[], value: GroupConditions) => void;
    onChangeId: (path: number[], id: CustomRuleIds) => void;
    onChangeOperator: (path: number[], operator: CustomRuleOperators) => void;
    onChangeValue: (path: number[], value: string | string[] | number | number[] | null) => void;
    onClickAddRule: (path: number[]) => void;
    onClickAddRuleGroup: (path: number[]) => void;
    onClickDeleteRule: (path: number[]) => void;
    path: number[];
}

const isCustomRule = (rule: CustomRule): rule is CustomRuleIdentifier => {
    return "id" in rule;
};

const getGroupColor = (condition: GroupConditions) => (condition === GroupConditions.Or ? "#7666F9" : "#C1715B");

const MAX_SUBGROUP_DEPTH = 2; // 3 total depth with root

export const CustomRuleGroupRow: FC<Props> = ({
    group,
    isValidationShown,
    onChangeCondition,
    onChangeId,
    onChangeOperator,
    onChangeValue,
    onClickAddRule,
    onClickAddRuleGroup,
    onClickDeleteRule,
    path,
}) => {
    return (
        <Row>
            <Col xs={24}>
                <BracketGroup
                    isSubBracket={Boolean(path.length)}
                    color={getGroupColor(group.condition)}
                    label={
                        <Space>
                            <GroupConditionSelect
                                value={group.condition}
                                onChange={(nextCondition) => onChangeCondition(path, nextCondition)}
                            />
                            {path.length > 0 && (
                                <Button
                                    type="link"
                                    size="small"
                                    icon={<DeleteOutlined />}
                                    onClick={() => onClickDeleteRule(path)}
                                />
                            )}
                        </Space>
                    }
                >
                    <Space direction="vertical" style={{ width: "100%" }}>
                        {isValidationShown && !group.rules.length && (
                            <Typography.Text style={{ color: red5 }}>
                                At least one condition or subgroup is required
                            </Typography.Text>
                        )}
                        {group.rules.map((rule, groupIndex) => {
                            const pathStr = path.join(",");

                            if (isCustomRule(rule)) {
                                return (
                                    <CustomRuleRow
                                        isValidationShown={isValidationShown}
                                        key={`${rule.id}-${pathStr}-${groupIndex}`}
                                        onChangeId={(nextPath, id) => onChangeId(nextPath, id)}
                                        onChangeOperator={(nextPath, operator) => onChangeOperator(nextPath, operator)}
                                        onChangeValue={(nextPath, value) => onChangeValue(nextPath, value)}
                                        onClickDeleteRule={onClickDeleteRule}
                                        path={[...path, groupIndex]}
                                        rule={rule}
                                    />
                                );
                            }

                            return (
                                <CustomRuleGroupRow
                                    group={rule}
                                    isValidationShown={isValidationShown}
                                    key={`ruleGroup-${pathStr}-${groupIndex}`}
                                    onChangeCondition={onChangeCondition}
                                    onChangeId={onChangeId}
                                    onChangeOperator={onChangeOperator}
                                    onChangeValue={onChangeValue}
                                    onClickAddRule={onClickAddRule}
                                    onClickAddRuleGroup={onClickAddRuleGroup}
                                    onClickDeleteRule={onClickDeleteRule}
                                    path={[...path, groupIndex]}
                                />
                            );
                        })}
                        <Row>
                            <Col xs={24}>
                                <Space>
                                    <Button
                                        type="link"
                                        size="small"
                                        icon={<PlusOutlined />}
                                        onClick={() => onClickAddRule(path)}
                                    >
                                        Condition
                                    </Button>
                                    {path.length < MAX_SUBGROUP_DEPTH && (
                                        <Button
                                            type="link"
                                            size="small"
                                            icon={<PlusOutlined />}
                                            onClick={() => onClickAddRuleGroup(path)}
                                        >
                                            Subgroup
                                        </Button>
                                    )}
                                </Space>
                            </Col>
                        </Row>
                    </Space>
                </BracketGroup>
            </Col>
        </Row>
    );
};
