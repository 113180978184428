import { useMemo } from "react";
import { VastErrorsFitlers } from "./VastErrorsFilters";
import { useVastErrors } from "./useVastErrors";
import { VastErrorsChart } from "./VastErrorsChart";
import { TogglableChart } from "@app/core/components/charts/TogglableChart/TogglableChart";
import { TopOffenders } from "./TopOffenders";
import { ErrorDefinition } from "./ErrorDefinition";

export const VastErrors = () => {
    const { entityType, entityId, data, networks, setNetworkFilter, topOffenders, errorDefinitions } = useVastErrors();
    const chart = useMemo(
        () => (
            <TogglableChart
                metricOne={data}
                chartRenderer={(filteredMetricOne) => <VastErrorsChart data={filteredMetricOne} />}
            />
        ),
        [data]
    );

    return (
        <>
            <VastErrorsFitlers
                entityType={entityType}
                entityId={entityId}
                networks={networks}
                setNetworkFilter={setNetworkFilter}
            />
            {chart}
            <TopOffenders data={topOffenders} />
            <ErrorDefinition data={errorDefinitions} />
        </>
    );
};
