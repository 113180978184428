import { TimingStats, TimingStatsEventCount } from "./types";
import { getTimingStatsSortedTimeRangesByRegion, getTimingStatsTotalEventCountByRegion } from "./utils";

export const getTimingStatsEventCountsByRegion = (
    timingStats: TimingStats,
    region: string
): TimingStatsEventCount[] => {
    const timingData = timingStats.data[region].dData.def.tData;
    const sortedTimeRangeKeys = getTimingStatsSortedTimeRangesByRegion(timingStats, region);
    const totalEventCount = getTimingStatsTotalEventCountByRegion(timingStats, region);

    let runningCount = 0;
    let runningTime = 0;
    return sortedTimeRangeKeys.map((rangeKey) => {
        const { ct, tt } = timingData[rangeKey];
        runningCount += ct;
        runningTime += tt;
        return {
            millisecondRange: rangeKey,
            eventCount: ct,
            cumulativePercentage: (runningCount / totalEventCount) * 100,
            averageTiming: tt / ct,
            runningCount,
            runningAverageTiming: runningTime / runningCount,
        };
    });
};
