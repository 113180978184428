import { FC } from "react";
import { FilterSelect } from "@app/core/components/FilterSelect";
import { useSeatAdSourcesLabelValueFilter } from "./useSeatAdSourcesLabelValueFilter";

export const SeatAdSourcesLabelValueFilter: FC = () => {
    const { handleChangeValue, options, value, isLoading, disabled } = useSeatAdSourcesLabelValueFilter();

    return (
        <FilterSelect
            label=" "
            onChange={handleChangeValue}
            isLoading={isLoading}
            options={options}
            value={value}
            dataSdet="seat-ad-sources-label-value-filter"
            mode="multiple"
            disabled={disabled}
            placeholder="Select Values..."
        />
    );
};
