// From https://github.com/vydimitrov/use-count-up/blob/master/src/UseAnimatedValue.ts
import { ReactNode } from "react";
import { useElapsedTime, UseElapsedTimeParams } from "./useElapsedTime";
import { defaultEasing, getEasing, Easing } from "./easing";

export type ReturnValue = number | string | ReactNode;

export interface UseAnimatedValue {
    value: ReturnValue;
    reset: () => void;
}

export interface UseAnimatedValueParams {
    isPlaying?: boolean;
    start?: number;
    end?: number;
    duration?: number;
    decimalPlaces?: number;
    decimalSeparator?: string;
    thousandsSeparator?: string;
    onComplete?: UseElapsedTimeParams["onComplete"];
    easing?: Easing;
    formatter?: (value: number) => ReturnValue;
    updateInterval?: number;
    onUpdate?: (value: ReturnValue) => void;
}

const DEFAULT_DURATION_IN_SECONDS = 2;

const getDuration = (end?: number, duration?: number) => {
    if (typeof end !== "number") {
        return undefined;
    }

    return typeof duration === "number" ? duration : DEFAULT_DURATION_IN_SECONDS;
};

const addThousandsSeparator = (value: string, separator: string) => value.replace(/\B(?=(\d{3})+(?!\d))/g, separator);

const getDecimalPartLength = (num: number) => (num.toString().split(".")[1] || "").length;

const getDefaultDecimalPlaces = (start: number, end?: number) => {
    const startDecimals = getDecimalPartLength(start);
    const endDecimals = getDecimalPartLength(end || 1);

    return startDecimals >= endDecimals ? startDecimals : endDecimals;
};

export const useAnimatedValue = ({
    isPlaying = false,
    start = 0,
    end,
    duration,
    decimalPlaces = getDefaultDecimalPlaces(start, end),
    decimalSeparator = ".",
    thousandsSeparator = "",
    onComplete,
    easing = defaultEasing,
    formatter,
    updateInterval,
    onUpdate,
}: UseAnimatedValueParams): UseAnimatedValue => {
    const durationValue = getDuration(end, duration);
    const getValue = (elapsedTime: number) => {
        let rawValue;

        if (durationValue === 0 && typeof end === "number") {
            rawValue = end;
        } else if (typeof end === "number" && typeof durationValue === "number") {
            const easingFn = getEasing(easing);
            // elapsedTime should always be less or equal to the durationValue
            const time = elapsedTime < durationValue ? elapsedTime : durationValue;
            rawValue = easingFn(time, start, end - start, durationValue);
        } else {
            rawValue = start + elapsedTime;
        }

        // Return value after formatting it
        if (typeof formatter === "function") {
            return formatter(rawValue);
        }

        if (decimalPlaces === 0) {
            const valueStr = Math.round(rawValue).toString();
            return addThousandsSeparator(valueStr, thousandsSeparator);
        }

        const [int, decimals] = rawValue.toFixed(decimalPlaces).split(".");
        const intFormatted = addThousandsSeparator(int, thousandsSeparator);
        return `${intFormatted}${decimalSeparator}${decimals}`;
    };

    const { elapsedTime, reset } = useElapsedTime({
        isPlaying,
        duration: durationValue,
        onComplete,
        updateInterval,
        onUpdate: typeof onUpdate === "function" ? (currentTime: number) => onUpdate(getValue(currentTime)) : undefined,
    });

    return { value: getValue(elapsedTime), reset };
};
