import { useAppDispatch, useAppSelector } from "@app/core/store";
import { LabeledValue } from "antd/lib/select";
import { ALL_STATUS_WATERFALL_FILTER, setFiltersSeatAdSourcesStatus } from "../../reducer";

interface UseSeatAdSourcesPacingStatusFilter {
    handleChangeValue: (value: string | number | undefined, option: LabeledValue | LabeledValue[]) => void;
    options: LabeledValue[];
    value: LabeledValue | string | null;
    isLoading: boolean;
}

const options = [
    ALL_STATUS_WATERFALL_FILTER,
    { label: "Under Pacing", value: 2 },
    { label: "Normal Pacing", value: 3 },
    { label: "Over Pacing", value: 4 },
];

export const useSeatAdSourcesPacingStatusFilter = (): UseSeatAdSourcesPacingStatusFilter => {
    const dispatch = useAppDispatch();
    const seatAdSourcesPacingStatus = useAppSelector((state) => state.seatAdSourcesPacing.filters.seatAdSourcesStatus);

    const handleChangeValue = (_, option: LabeledValue) => {
        dispatch(setFiltersSeatAdSourcesStatus(option));
    };

    return {
        handleChangeValue,
        options,
        value: seatAdSourcesPacingStatus,
        isLoading: false,
    };
};
