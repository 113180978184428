import { useGetAvailableNetworksQuery, useGetExtendedIdsQuery } from "@app/core/services";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormAdSourceExtendedUserId } from "@app/features/deals/DealForm/reducer";
import { DealAdSourceTransparencyExtendedUserId } from "@app/features/deals/DealForm/types";
import { LabeledValue } from "antd/lib/select";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

const DISABLED_SOURCE_ID = 2;

export interface SourceOptions extends LabeledValue {
    code: string;
}

interface UseAdSourceExtendedUserIdField {
    sourceOptions: SourceOptions[];
    networkOptions: LabeledValue[];
    isLoadingSource: boolean;
    isLoadingNetwork: boolean;
    onChangeSource: (value: number, option: LabeledValue, index: number) => void;
    onChangeNetwork: (value: number, option: LabeledValue, index: number) => void;
    onRemove: (extendedId: number | null, networkId: number | null, index: number) => void;
    sourceValue: number | null;
    networkValue: number | null;
}

export const useAdSourceExtendedUserIdField = (
    name: number,
    remove: (index: number | number[]) => void
): UseAdSourceExtendedUserIdField => {
    const values = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOWED_EXTENDED_ID_TRANSPARENCIES]
    );
    const value = useMemo(() => values[name], [values, name]);
    const { seatId } = useParams<{ seatId: string }>();
    const dispatch = useAppDispatch();

    const { isFetching: isLoadingSource, data: sourceData } = useGetExtendedIdsQuery(Number(seatId));

    const params = useMemo(() => {
        const prop = {
            seatId: Number(seatId),
        };
        if (value?.extendedId?.code) {
            Object.assign(prop, {
                extendedId: value.extendedId.code,
            });
        }
        return prop;
    }, [value?.extendedId?.code, seatId]);

    const { isFetching: isLoadingNetwork, data: networksData } = useGetAvailableNetworksQuery(params, {
        skip: !Boolean(value?.extendedId?.code),
    });

    const sourceOptions = useMemo(
        () =>
            (sourceData || [])
                .filter(({ id }) => id !== DISABLED_SOURCE_ID)
                .map(({ id, name, code }) => ({ label: name, value: id, code })),
        [sourceData]
    );
    const networkOptions = useMemo(
        () => (networksData || []).map(({ id, name }) => ({ label: name, value: id })),
        [networksData]
    );

    const onChangeSource = (id: number, option: SourceOptions, index: number) => {
        const payload = [
            ...values.slice(0, index),
            {
                ...value,
                extendedId: { id, name: option.label as string, code: option.code },
                network: {},
            },
            ...values.slice(index + 1),
        ];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch(dealFormAdSourceExtendedUserId(payload));
    };
    const onChangeNetwork = (id: number, option: LabeledValue, index: number) => {
        const payload = [
            ...values.slice(0, index),
            {
                ...value,
                network: { id, name: option.label as string },
            },
            ...values.slice(index + 1),
        ];

        dispatch(dealFormAdSourceExtendedUserId(payload));
    };

    const onRemove = (extendedId: number | null, networkId: number | null, index: number) => {
        const newExtendedUseId = values.filter((item: DealAdSourceTransparencyExtendedUserId) => {
            const isNotExtendId = item?.extendedId?.id !== extendedId;
            const isNotNetworkId = item?.network?.id !== networkId;
            return isNotExtendId || isNotNetworkId;
        });
        dispatch(dealFormAdSourceExtendedUserId(newExtendedUseId));
        remove(index);
    };

    return {
        sourceValue: value?.extendedId?.id,
        networkValue: value?.network?.id,
        sourceOptions,
        networkOptions,
        isLoadingSource,
        isLoadingNetwork,
        onChangeSource,
        onChangeNetwork,
        onRemove,
    };
};
