import { InventoryDistributionGroup, SeatAdSourcesType } from "@app/core/services";
import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { Form } from "antd";
import { AdSourceFormMode } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { FC } from "react";
import { InventoryDistributionGroupsField } from "./InventoryDistributionGroupsField";
import { useSeatAuthContext } from "@app/core/auth";

interface Props {
    mode: AdSourceFormMode | undefined;
}

export const InventoryDistributionGroupsList: FC<Props> = ({ mode }) => {
    const currentGroups =
        Form.useWatch<InventoryDistributionGroup[]>(AD_SOURCE_FIELDS.INVENTORY_DISTRIBUTION_GROUPS.name) || [];
    const adSourceType = Form.useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);
    const { context } = useSeatAuthContext();
    const isInventoryDistributionGroupShown =
        adSourceType?.id === AdSourceTypeIds.AUTOMATED_GUARANTEED && Boolean(context?.distributionGroupsEnabled);

    return (
        <InventoryDistributionGroupsField
            isEditing={mode === "edit"}
            isHidden={!isInventoryDistributionGroupShown}
            name={AD_SOURCE_FIELDS.INVENTORY_DISTRIBUTION_GROUPS.name}
            label={AD_SOURCE_FIELDS.INVENTORY_DISTRIBUTION_GROUPS.label}
            value={currentGroups}
        />
    );
};
