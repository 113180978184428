import { FC } from "react";
import { Input } from "antd";
import { useAdSourceDomainNameOverrideField } from "./useAdSourceDomainNameOverrideField";
import { TransparencyFormItem } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceTransparencySection/Fields";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";

export const AdSourceDomainNameOverrideField: FC = () => {
    const { handleBlur, handleChange, value } = useAdSourceDomainNameOverrideField();

    return (
        <TransparencyFormItem
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DOMAIN_NAME_OVERRIDE}
            label="Domain Name Override"
            data-sdet="ad-source-domain-name-override-field"
            rules={[{ required: true, whitespace: true, message: "Domain Name Override is required" }]}
        >
            <Input
                placeholder="Domain Name Override"
                value={value}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{ width: "189px" }}
            />
        </TransparencyFormItem>
    );
};
