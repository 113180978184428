import { CreativeReview, Loading } from "@app/core/components";
import { css } from "@emotion/css";
import { Col, Flex } from "antd";
import { DetailsDrawerLayout } from "../../DetailsDrawerLayout";
import { useInventoryDetailsDrawerUrlId } from "../../useInventoryDetailsDrawerUrlId";
import { useCreativeReviewContent } from "./useCreativeReviewContent";

export const CreativeReviewContent = () => {
    const unitId = useInventoryDetailsDrawerUrlId();
    const { handleShowDefinition, breadcrumbs, isLoading, creativeEntityType } = useCreativeReviewContent(unitId);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Flex vertical data-sdet="inventory-creative-review-content">
            <DetailsDrawerLayout
                title="Creative Review"
                breadcrumbs={breadcrumbs}
                definitionTitle="Creative Review"
                handleDefintion={handleShowDefinition}
            />
            <Col
                className={css`
                    padding: 1rem 0;
                    width: 100%;
                `}
            >
                <CreativeReview id={Number(unitId)} entityType={creativeEntityType} />
            </Col>
        </Flex>
    );
};
