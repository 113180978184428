import { FC, useEffect, useState } from "react";
import { css } from "@emotion/css";
import { Button, Checkbox, Row, Col, Flex } from "antd";

export interface LegendDataPoint {
    label: string;
    color: string;
    checked: boolean;
}

interface ChartLegendProps {
    data: LegendDataPoint[];
    onChange: (data: LegendDataPoint[]) => void;
    showClearAll?: boolean;
}

export const ChartLegend: FC<ChartLegendProps> = ({ data, onChange, showClearAll = true }: ChartLegendProps) => {
    const [clearAll, setClearAll] = useState(true);

    useEffect(() => {
        if (data.every((ldp) => ldp.checked)) {
            setClearAll(true);
        } else if (data.every((ldp) => !ldp.checked)) {
            setClearAll(false);
        }
    }, [data]);

    const onChecked = (label: string) => {
        onChange(
            data.map((dp) => {
                if (dp.label === label) {
                    dp.checked = !dp.checked;
                }
                return dp;
            })
        );
    };

    const toggleAll = () => {
        setClearAll((v) => !v); // toggle the boolean - note: 'clearAll' isnt changed yet
        onChange(
            data.map((dp) => {
                dp.checked = !clearAll; // if 'clearAll' is true, we want to uncheck legend data points
                return dp;
            })
        );
    };

    return (
        <Flex
            wrap="wrap"
            align="center"
            justify={`${showClearAll ? "flex-start" : "center"}`}
            gap="middle"
            className={css`
                & {
                    margin-left: 55px;
                    min-height: 75px;
                }
            `}
        >
            {showClearAll && (
                <Button
                    onClick={toggleAll}
                    className={css`
                        & {
                            float: right;
                            margin-right: 5px;
                        }
                    `}
                >
                    {clearAll ? "Clear All" : "Enable All"}
                </Button>
            )}
            <Col xxl={16} xl={16} lg={18} md={20} sm={20} xs={20}>
                <Row gutter={[2, 2]}>
                    {data.map((ldp: LegendDataPoint) => {
                        return (
                            <Col key={ldp.label}>
                                <Checkbox
                                    checked={ldp.checked}
                                    onChange={() => onChecked(ldp.label)}
                                    className={css`
                                        .ant-checkbox-checked::after {
                                            border-color: ${ldp.color};
                                        }

                                        .ant-checkbox-inner,
                                        .ant-checkbox-input:focus + .ant-checkbox-inner,
                                        .ant-checkbox-wrapper:hover .ant-checkbox-inner,
                                        .ant-checkbox:hover .ant-checkbox-inner {
                                            border-color: ${ldp.color};
                                            background-color: ${ldp.color};
                                        }
                                    `}
                                >
                                    {ldp.label}
                                </Checkbox>
                            </Col>
                        );
                    })}
                </Row>
            </Col>
        </Flex>
    );
};
