import { Typography } from "antd";
import { FC, ReactNode } from "react";

export const DetailsItem: FC<{ label: ReactNode }> = ({ children, label }) => {
    return (
        <>
            <Typography.Text strong>{label}</Typography.Text>
            <Typography.Paragraph>{children}</Typography.Paragraph>
        </>
    );
};
