import { Card } from "antd";
import { FC } from "react";
import { INVENTORY_CARD_BODY_STYLE } from "@app/features/inventory/constants";
import { BRAND_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useBrandFormInstance } from "@app/features/inventory/HierarchyForms/BrandForm/BrandFormInstance";
import { BrandLabels } from "@app/features/inventory/HierarchyForms/BrandForm/BrandFormSections/LabelsSection/Fields/BrandLabels";

export const LabelsInternalOnly: FC = () => {
    const { seat } = useBrandFormInstance();
    return (
        <Card title="Internal Only" type="inner" bodyStyle={INVENTORY_CARD_BODY_STYLE}>
            <BrandLabels {...BRAND_FORM_FIELDS.INTERNAL_LABELS} />
            {seat?.distributionGroupsEnabled && <BrandLabels {...BRAND_FORM_FIELDS.DISTRIBUTION_GROUP_LABELS} />}
        </Card>
    );
};
