import { Table } from "antd";
import { useAdvertiserDomains } from "./useAdvertiserDomains";
import { getColumns, getTableRows } from "./helpers";
import { useAdvertiserDomainsDrawer } from "./AdvertiserDomainDrawer/useAdvertiserDomainsDrawer";
import { useDeleteAdvertiserDomainMutation } from "@app/core/services";
import { BrandSafetyDeleteModal } from "../BrandSafetyDeleteModal";
import { useBrandSafetyDeleteModal } from "../BrandSafetyDeleteModal/useBrandSafetyDeleteModal";

export const ADVERTISER_DOMAINS_TABLE_SDET = "advertiser-domains-list-table";

export const AdvertiserDomainsTable = () => {
    const { isLoading, data, search, seatId } = useAdvertiserDomains({ isIncludeDomains: false });
    const { handleDrawerOpen } = useAdvertiserDomainsDrawer();
    const [deleteTrigger, { isLoading: isDeleting }] = useDeleteAdvertiserDomainMutation();
    const { handleOpen: handleDeleteModal, ...restModalProps } = useBrandSafetyDeleteModal(deleteTrigger);

    return (
        <>
            <Table
                data-sdet={ADVERTISER_DOMAINS_TABLE_SDET}
                columns={getColumns()}
                dataSource={getTableRows(data, seatId, search, handleDrawerOpen, handleDeleteModal)}
                loading={isLoading}
                scroll={{ x: 1000 }}
                size="small"
                rowKey="id"
                showSorterTooltip={false}
                pagination={false}
            />
            <BrandSafetyDeleteModal isDeleting={isDeleting} {...restModalProps} />
        </>
    );
};

export default AdvertiserDomainsTable;
