import { ChannelMaxAdsPerAdvertiser } from "@app/features/inventory/components/FormItems";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 18, xl: 17, xxl: 12 };
export const MaxAdsPerAdvertiser = () => {
    return (
        <ChannelMaxAdsPerAdvertiser
            name={CHANNEL_FORM_FIELDS.MAX_ADS_PER_ADV.name}
            label={CHANNEL_FORM_FIELDS.MAX_ADS_PER_ADV.label}
            placeholder={CHANNEL_FORM_FIELDS.MAX_ADS_PER_ADV.placeholder}
            wrapperCol={COL_SIZES}
        />
    );
};
