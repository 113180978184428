import { FC } from "react";
import { Form, Input } from "antd";
import { Seat } from "@app/core/services";
import { useUserAccess } from "@app/core/auth";
import { NONE } from "@app/core/components/constants";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

interface BusinessDomainProps {
    seat: Seat;
    edit?: boolean;
}

export const BusinessDomain: FC<BusinessDomainProps> = ({ edit = false, seat }) => {
    const { hasInternalAccess } = useUserAccess();
    const { businessDomain } = seat;
    return (
        <Form.Item
            label={INVENTORY_FORM_FIELDS.BUSINESS_DOMAIN.label}
            name={INVENTORY_FORM_FIELDS.BUSINESS_DOMAIN.name}
            rules={[
                {
                    whitespace: true,
                    required: edit && hasInternalAccess,
                    message: INVENTORY_FORM_FIELDS.BUSINESS_DOMAIN.rulesMessage,
                },
            ]}
            wrapperCol={{ xs: 24, sm: 24, md: 20, lg: 18, xl: 22, xxl: 20 }}
        >
            {edit && hasInternalAccess ? (
                <Input placeholder={INVENTORY_FORM_FIELDS.BUSINESS_DOMAIN.placeholder} allowClear />
            ) : (
                businessDomain || NONE
            )}
        </Form.Item>
    );
};
