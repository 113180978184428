import { FC } from "react";
import { css } from "@emotion/css";
import { Col, Row, Select, Table, TableColumnsType, Typography } from "antd";
import { formatNumber } from "@rubicon/utils";
import { BidderStatsColumnKey, useSeatAdSourcesPerformanceBidderStats } from "./useSeatAdSourcesPerformanceBidderStats";

interface Props {
    adSourceId: number;
}

const DataIndex: Record<string, BidderStatsColumnKey> = {
    DSP: "dsp",
    AUCTION_COUNT: "auctionCount",
    AVERAGE_BID_PRICE: "averageBidPrice",
    NO_BID: "noBid",
    BELOW_FLOOR: "belowFloor",
    LOSSES: "losses",
    WINS: "wins",
    DEAL_MISMATCH: "dealMismatch",
    TIMEOUTS: "timeouts",
    BELOW_DEAL: "belowDeal",
    ABOVE_DEAL: "aboveDeal",
    THROTTLED: "throttled",
    ERRORS: "errors",
} as const;

const Columns: TableColumnsType = [
    {
        dataIndex: DataIndex.DSP,
        title: "DSP",
        fixed: "left" as const,
        width: 200 as const,
    },
    {
        dataIndex: DataIndex.AUCTION_COUNT,
        title: "# Auctions",
        render: formatNumber.asWhole,
    },
    {
        dataIndex: DataIndex.AVERAGE_BID_PRICE,
        title: "Avg Bid Price",
        render: (v) => formatNumber.asMoney(v, undefined),
    },
    {
        dataIndex: DataIndex.NO_BID,
        title: "No Bid",
        render: formatNumber.asWhole,
    },
    {
        dataIndex: DataIndex.BELOW_FLOOR,
        title: "Below Floor",
        render: formatNumber.asWhole,
    },
    {
        dataIndex: DataIndex.LOSSES,
        title: "Losses",
        render: formatNumber.asWhole,
    },
    {
        dataIndex: DataIndex.WINS,
        title: "Winner",
        render: formatNumber.asWhole,
    },
    {
        dataIndex: DataIndex.DEAL_MISMATCH,
        title: "Deal Mismatch",
        render: formatNumber.asWhole,
    },
    {
        dataIndex: DataIndex.TIMEOUTS,
        title: "Timeouts",
        render: formatNumber.asWhole,
    },
    {
        dataIndex: DataIndex.BELOW_DEAL,
        title: "Below Deal",
        render: formatNumber.asWhole,
    },
    {
        dataIndex: DataIndex.ABOVE_DEAL,
        title: "Above Deal",
        render: formatNumber.asWhole,
    },
    {
        dataIndex: DataIndex.THROTTLED,
        title: "Throttled",
        render: formatNumber.asWhole,
    },
    {
        dataIndex: DataIndex.ERRORS,
        title: "Errors",
        render: formatNumber.asWhole,
    },
];

export const SeatAdSourcesPerformanceBidderStats: FC<Props> = ({ adSourceId }) => {
    const { handleChangeValue, dealFilterOptions, dealFilterValue, tableData, isFetching } =
        useSeatAdSourcesPerformanceBidderStats(adSourceId);

    return (
        <div data-sdet="seat-ad-sources-details-performance-bidder-stats">
            <Col
                className={css`
                    padding: 1.5rem;
                `}
            >
                <Row justify="start">
                    <Typography.Text style={{ fontWeight: "bold" }}>Deal</Typography.Text>
                </Row>
                <Row
                    className={css`
                        padding-bottom: 20px;
                    `}
                >
                    <Col>
                        <Select
                            placeholder="Deal Name"
                            options={dealFilterOptions}
                            value={dealFilterValue}
                            onChange={handleChangeValue}
                        />
                    </Col>
                </Row>
                <Table
                    size="small"
                    columns={Columns}
                    dataSource={tableData}
                    showSorterTooltip={false}
                    bordered
                    loading={isFetching}
                    pagination={false}
                    scroll={{ x: 1750 }}
                />
            </Col>
        </div>
    );
};
