import { FC } from "react";
import { SectionTitle } from "@app/core/components";
import { HelpKeyItem } from "@app/core/components/HelpKeysDrawer/HelpKeyItem";
import { css } from "@emotion/css";
import { Collapse } from "antd";
import { useParams } from "react-router-dom";
import { ADVANCED_FLOOR_SECTION_HELP_KEYS } from "./helpkeys";
import { SeatFloorsAdvancedDrawerLayout } from "../SeatFloorsAdvancedDrawer";
import { ADVANCED_FLOOR_SECTION_NAMES } from "../SeatFloorsAdvancedDetails/constants";

const configurationCollapseClassList = css`
    width: 100%;

    & .ant-collapse-content-box {
        padding-left: 3.3rem;
    }

    & .ant-collapse-item > .ant-collapse-header {
        padding-left: 1.5rem;
    }

    & .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        margin-right: 18px;
    }

    .ant-collapse-expand-icon {
        align-self: flex-end;
    }
`;

interface Props {
    handleClose: () => void;
    handleBack: () => void;
    sectionKey?: ADVANCED_FLOOR_SECTION_NAMES;
    open?: boolean;
}
export const SeatFloorsAdvancedDefinition: FC<Props> = ({ handleBack, handleClose, sectionKey }) => {
    const { key } = useParams<{ key: string }>();
    const definitionsFields = ADVANCED_FLOOR_SECTION_HELP_KEYS[key || (sectionKey as string)];
    const allKeys = definitionsFields.map(({ key }) => key);

    return (
        <SeatFloorsAdvancedDrawerLayout title="Floor definition" closeHandler={handleClose} handleBack={handleBack}>
            <Collapse defaultActiveKey={allKeys} className={configurationCollapseClassList}>
                {definitionsFields.map((field) => (
                    <Collapse.Panel key={field.key} header={<SectionTitle title={field.label} />} forceRender>
                        <HelpKeyItem helpPath={field.helpPath} />
                    </Collapse.Panel>
                ))}
            </Collapse>
        </SeatFloorsAdvancedDrawerLayout>
    );
};
