import { FC } from "react";
import { Layout, Space } from "antd";
import { Footer } from "@app/core/components";
import DealHealthToolbar from "@app/features/deals/DealHealthPage/DealHealthToolbar";
import DealHealthTable from "@app/features/deals/DealHealthPage/DealHealthTable";
import DealHealthTableFilters from "@app/features/deals/DealHealthPage/DealHealthTableFilters";
import { useGetCurrenciesPrefetch } from "@app/core/services";

const DealHealthPage: FC = () => {
    useGetCurrenciesPrefetch();
    return (
        <>
            <DealHealthToolbar />
            <Layout.Content>
                <Space direction="vertical" size="large" style={{ width: "100%" }}>
                    <DealHealthTableFilters />
                    <DealHealthTable />
                </Space>
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
export default DealHealthPage;
