import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { LabeledValue } from "antd/es/select";
import { useGetSeatAdvertiserDomainFilterListDefsQuery } from "@app/core/services";

interface UseSeatAdvertiserDomainFilterList {
    isFetching: boolean;
    options: LabeledValue[];
}

const BLOCK_TYPE_ID = 1;
export const useSeatAdvertiserDomainFilterList = (): UseSeatAdvertiserDomainFilterList => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data = [], isFetching } = useGetSeatAdvertiserDomainFilterListDefsQuery({
        seatId: Number(seatId),
        typeId: BLOCK_TYPE_ID,
    });
    const options: LabeledValue[] = useMemo(
        () => (data || []).map((domain) => ({ value: domain.id, label: domain.name })),
        [data]
    );

    return {
        isFetching,
        options,
    };
};
