import { DayPartTarget } from "@app/core/services";
import { css } from "@emotion/css";
import { ExpandCollapse } from "@rubicon/antd-components";
import { CollapsePanelProps, Table } from "antd";
import { FC } from "react";
import { DAY_PARTING_COLUMN_HEADERS } from "../constants";
import { halfHourOfDayToLabelValue } from "../helpers";
import { gray5 } from "@rubicon/antd-components";

export interface DayPartTargetingPanelProps extends CollapsePanelProps {
    values: DayPartTarget[];
}

export const DayPartTargetingPanel: FC<DayPartTargetingPanelProps> = ({ values, ...rest }) => {
    if (!values.length) {
        return null;
    }

    const tableData: { [columnIndex: number]: string | true }[] = values.reduce(
        (prev, { dayOfWeek, halfHourOfDay }) => {
            prev[halfHourOfDay][dayOfWeek] = true;
            return prev;
        },
        Array.from({ length: 48 }, (_, index) => ({
            0: halfHourOfDayToLabelValue(index),
        }))
    );

    const columns = DAY_PARTING_COLUMN_HEADERS.map((columnName: string, columnIndex: number) => ({
        title: (
            <div
                key={`${columnName}-${columnIndex}`}
                data-column-index={columnIndex}
                style={{
                    height: "100%",
                    width: "100%",
                    padding: "3px 8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    userSelect: "none",
                }}
            >
                <span style={{ pointerEvents: "none" }}>{columnName}</span>
            </div>
        ),
        dataIndex: columnIndex,
        key: columnName,
        width: "12.5%",
        render: (value) => {
            return {
                children: (
                    <span
                        style={{
                            userSelect: "none",
                            fontWeight: "bold",
                        }}
                    >
                        {value}
                    </span>
                ),
                props: { style: { backgroundColor: value === true ? "#E6EDFF" : "white" } },
            };
        },
    }));

    return (
        <ExpandCollapse.Panel {...rest}>
            <Table
                size="small"
                dataSource={tableData}
                columns={columns}
                pagination={false}
                className={css`
                    .ant-table.ant-table-bordered > .ant-table-container,
                    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
                    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
                    .ant-table.ant-table-bordered
                        > .ant-table-container
                        > .ant-table-content
                        > table
                        > tbody
                        > tr
                        > td {
                        border-color: ${gray5};
                    }
                    .ant-table.ant-table-small .ant-table-thead > tr > th {
                        padding: 0;
                    }
                    .ant-table.ant-table-small .ant-table-tbody > tr > td,
                    .ant-table-body .ant-table-tbody .ant-table-row .ant-table-cell {
                        padding: 3px 8px;
                    }
                `}
                bordered
            />
        </ExpandCollapse.Panel>
    );
};
