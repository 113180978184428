import { FC } from "react";
import merge from "lodash.merge";
import { Y_AXIS_MAX_SCALE } from "../constants";
import { XYSeries } from "../types";
import { getScaledMax, mapXYSeriesDataToPlotData } from "../utils/utils";
import { Legend, useLegend } from "../Legend";
import { LinePlotConfig, Plot, PlotTypes } from "../Plot";
import { DefaultOptions, RequiredFields, RequiredOptions } from "./constants";
import { LineChartOptions, LineChartProps } from "./types";

const getDynamicOptions = (data: XYSeries[] = []): LineChartOptions => ({
    yAxis: {
        max: getScaledMax(
            mapXYSeriesDataToPlotData(data, RequiredFields.yField, RequiredFields.seriesField)?.map((d) => d.yValue),
            Y_AXIS_MAX_SCALE
        ),
    },
});

export const LineChart: FC<LineChartProps> = ({ id, chartOptions, data = [], legendOptions }) => {
    const { seriesFilters, requiredLegendProps } = useLegend([data]);

    const linePlotConfig: LinePlotConfig = {
        type: PlotTypes.Line,
        options: merge({}, DefaultOptions, getDynamicOptions(data), chartOptions, RequiredOptions),
        data: mapXYSeriesDataToPlotData(data, RequiredFields.yField, RequiredFields.seriesField, seriesFilters),
    };

    return (
        <div data-sdet="line-chart">
            <Plot id={id} plotConfig={linePlotConfig} />
            <Legend {...requiredLegendProps} legendOptions={legendOptions} />
        </div>
    );
};
