import { FC } from "react";
import {
    LABEL_VALUES,
    INTERNAL_LABEL_VALUES,
    PREFILTER_LABEL_VALUES,
    DISTRIBUTION_GROUP_LABEL_VALUES,
} from "@app/features/inventory/constants";
import { ColProps } from "antd/lib/grid/col";
import { PlusOutlined } from "@ant-design/icons";
import { TagRender } from "@app/core/components";
import { Button, Col, Form, Row, Select, Typography } from "antd";
import { useLabels } from "@app/features/inventory/components/Fields/Labels/useLabels";

import { LabelsSelect, LabelsValuesSelect } from "@app/features/inventory/components/Fields/Labels/Fields";

const DEFAULT_COL_SIZES = { xs: 24, sm: 24, md: 9, lg: 9, xl: 9, xxl: 9 };

interface LabelsProps {
    name:
        | typeof LABEL_VALUES
        | typeof INTERNAL_LABEL_VALUES
        | typeof PREFILTER_LABEL_VALUES
        | typeof DISTRIBUTION_GROUP_LABEL_VALUES;
    labelsSelectColProps?: ColProps;
    LabelsValuesSelectColProps?: ColProps;
}

export const Labels: FC<LabelsProps> = ({
    name,
    labelsSelectColProps = DEFAULT_COL_SIZES,
    LabelsValuesSelectColProps = DEFAULT_COL_SIZES,
}) => {
    const {
        hidden,
        labels,
        labelId,
        onChange,
        isLoading,
        labelValue,
        handleAddLabel,
        handleChangeLabel,
        labelValuesOptions,
        isLabelValuesFetching,
        handleChangeLabelValue,
        seatInternalLabelValuesOptions,
    } = useLabels({ name });
    return (
        <>
            <Row style={{ width: "100%" }} gutter={[4, 4]} wrap>
                <Col {...labelsSelectColProps}>
                    <LabelsSelect value={labelId} labels={labels} onChange={handleChangeLabel} loading={isLoading} />
                </Col>
                <Col>
                    <Typography.Text strong style={{ lineHeight: "32px" }}>
                        :
                    </Typography.Text>
                </Col>
                <Col {...LabelsValuesSelectColProps}>
                    <LabelsValuesSelect
                        value={labelValue}
                        disabled={!labelId}
                        options={labelValuesOptions}
                        loading={isLabelValuesFetching}
                        onChange={handleChangeLabelValue}
                    />
                </Col>
                <Col>
                    <Button
                        block
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={handleAddLabel}
                        style={{ paddingLeft: "0.2rem" }}
                        disabled={!labelId || !labelValue}
                    >
                        Add Label
                    </Button>
                </Col>
            </Row>
            <Form.Item noStyle name={name} hidden={hidden}>
                <Select
                    allowClear
                    labelInValue
                    open={false}
                    mode="multiple"
                    showSearch={false}
                    onChange={onChange}
                    tagRender={TagRender}
                    style={{ marginTop: "0.3rem" }}
                    options={seatInternalLabelValuesOptions}
                />
            </Form.Item>
        </>
    );
};
