import { FC } from "react";
import { Form, Select } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAdSourceModelField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceBrandSafetySection/Fields/AdSourceModeField/useAdSourceModeField";

export const AdSourceModeField: FC = () => {
    const { value, onChange, options, isFetching } = useAdSourceModelField();
    return (
        <Form.Item
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE}
            data-sdet="ad-source-mode-field"
            label="Advertiser Domain Mode"
        >
            <Select
                showSearch
                options={options}
                loading={isFetching}
                optionFilterProp="label"
                placeholder="Select Advertiser Domain Mode…"
                onChange={onChange}
                value={value}
                allowClear
            />
        </Form.Item>
    );
};
