import { MarketplaceInfo } from "@app/core/services";
import {
    AdSourceFormsContext,
    DemandConfigurationForm,
} from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { Form } from "antd";
import { useContext } from "react";
import { DEAL_TYPES } from "../DealObjectSection/DealTermsSection/Fields";
import { DealTypeIds } from "@app/features/seatAdSources/constants";

export const useDemandConfigurationForm = () => {
    const { forms } = useContext(AdSourceFormsContext);
    const dealType = Form.useWatch<MarketplaceInfo["deal"]["dealType"]>(
        ["dealUnderEdit", "dealType"],
        forms.dealObjectForm
    );
    const isLegacyDeal = dealType?.name === DEAL_TYPES.DEAL_TYPE_LEGACY.name;
    const isProgrammaticGuaranteedOrLegacy = [DealTypeIds.PROGRAMMATIC_GUARANTEED, DealTypeIds.LEGACY].includes(
        dealType?.id
    );

    // see useEntityTypeRadio.ts for dynamic initial values
    const initValues: Partial<DemandConfigurationForm> = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        currencyType: { code: "Inherited From Ad Source", id: null },
        enforcement: { name: "None", id: 3 },
        freqCappingType: { name: "Off", id: 1 },
        runDateMode: "fixed",
        dealFreqCappings: [],
        startDate: null,
    };

    return {
        isLegacyDeal,
        initValues,
        isProgrammaticGuaranteedOrLegacy,
    };
};
