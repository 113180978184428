import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { useGetFreqCappingTypesQuery } from "@app/core/services";

interface UseFreqCappingTypes {
    isFetching: boolean;
    options: LabeledValue[];
}

export const useFreqCappingTypes = (): UseFreqCappingTypes => {
    const { data, isFetching } = useGetFreqCappingTypesQuery();
    const options: LabeledValue[] = useMemo(
        () => (data || []).map(({ id, name }) => ({ value: id, label: name })),
        [data]
    );

    return {
        isFetching,
        options,
    };
};
