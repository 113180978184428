import { consoleApi } from "./console";
import { toQueryString } from "./utils";

export interface SearchParams {
    seatId?: string;
    type?: string;
    page?: number;
    max?: number;
    keyword?: string;
}

interface HasCodeIdName {
    code: string;
    id: number;
    name: string;
}

export interface SearchItemSeat {
    distributionGroupsEnabled: unknown | null;
    enableAdServing: boolean;
    id: number;
    isSupplySeat: boolean;
    name: string;
    udeEnabled: boolean;
    entityType: "Seat";
}

interface SearchItemBuyer extends HasCodeIdName {
    entityType: "Buyer";
}

export interface SearchItemPublisher extends HasCodeIdName {
    entityType: "Publisher";
    seat: HasCodeIdName;
}

export interface SearchItemBrand extends HasCodeIdName {
    entityType: "Brand";
    publisher: HasCodeIdName & {
        seat: HasCodeIdName;
    };
}

export interface SearchItemSupply extends HasCodeIdName {
    entityType: "Supply";
    brand: HasCodeIdName & {
        publisher: HasCodeIdName & {
            seat: HasCodeIdName;
        };
    };
}

export interface SearchItemChannel extends HasCodeIdName {
    entityType: "Channel";
    seat?: SearchItemSeat;
    publisher?: SearchItemPublisher;
}

export interface SearchItemAdUnit extends HasCodeIdName {
    entityType: "AdUnit";
    multiplicity: { id: number };
    supply: SearchItemSupply;
}

export interface SearchItemAdUnitChannel extends Omit<SearchItemAdUnit, "entityType"> {
    entityType: "ChannelAdUnit";
}

interface SearchItemAdSource extends HasCodeIdName {
    entityType: "AdSource";
}

interface SearchItemDeal extends HasCodeIdName {
    entityType: "DemandDeal" | "BuyerDeal" | "Deal";
}

export interface SearchItemUser {
    id: number;
    emailAddress: string;
    company: string;
    entityType: "User";
    name: string;
}

export type SearchItem =
    | SearchItemAdSource
    | SearchItemAdUnit
    | SearchItemBrand
    | SearchItemBuyer
    | SearchItemChannel
    | SearchItemDeal
    | SearchItemPublisher
    | SearchItemSeat
    | SearchItemSupply
    | SearchItemUser
    | SearchItemAdUnitChannel;

export const searchApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        search: builder.query<SearchItem[], SearchParams>({
            query: (params) => {
                return `/search;${toQueryString(params)}`;
            },
        }),
    }),
});

export const { useSearchQuery, useLazySearchQuery } = searchApi;
