import { useForm, useWatch } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { notification } from "antd";
import {
    Floor,
    FloorCreatePayload,
    FloorUpdatePayload,
    useCreateAdUnitFloorMutation,
    useCreateBrandFloorMutation,
    useCreatePublisherFloorMutation,
    useCreateSeatFloorMutation,
    useCreateSupplyFloorMutation,
    useUpdateFloorMutation,
} from "@app/core/services/console";
import { CREATE_FLOOR_FORM_ITEMS_NAME } from "@app/features/inventory/constants";
import { useFloorSource } from "./useFloorSource";
import { LabeledValue } from "antd/lib/select";
import {
    getCountryGeo,
    getCountryValue,
    getTargetingFromCountry,
} from "@app/features/inventory/InventoryFloors/helpers";
import { ROUTE_FORMATTERS } from "@app/core/routing";

interface FloorFormValues {
    [CREATE_FLOOR_FORM_ITEMS_NAME.PRICE]: number;
    [CREATE_FLOOR_FORM_ITEMS_NAME.COUNTRY]: LabeledValue;
}

const getInitialValues = (mode: "create" | "edit", floor?: Floor | null): FloorFormValues | null => {
    if (mode === "create") {
        return {
            [CREATE_FLOOR_FORM_ITEMS_NAME.PRICE]: 0,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            [CREATE_FLOOR_FORM_ITEMS_NAME.COUNTRY]: null,
        };
    }

    if (!floor) {
        return null;
    }

    return {
        [CREATE_FLOOR_FORM_ITEMS_NAME.PRICE]: floor.price / 1000,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [CREATE_FLOOR_FORM_ITEMS_NAME.COUNTRY]: getCountryValue(getCountryGeo(floor)),
    };
};

export const useFloorForm = (mode: "create" | "edit", floor?: Floor | null) => {
    const [createAdUnitFloor] = useCreateAdUnitFloorMutation();
    const [createSupplyFloor] = useCreateSupplyFloorMutation();
    const [createBrandFloor] = useCreateBrandFloorMutation();
    const [createPublisherFloor] = useCreatePublisherFloorMutation();
    const [createSeatFloor] = useCreateSeatFloorMutation();

    const [updateFloor] = useUpdateFloorMutation();

    const [form] = useForm<FloorFormValues>();
    const floorPrice = useWatch(CREATE_FLOOR_FORM_ITEMS_NAME.PRICE, form);
    const {
        seatId,
        publisherId,
        brandId,
        supplyId,
        adUnitId,
        floorId: paramsFloorId,
    } = useParams<{
        seatId: string;
        publisherId: string;
        brandId: string;
        supplyId: string;
        adUnitId: string;
        floorId: string;
    }>();
    const floorSource = useFloorSource();
    const history = useHistory();

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleCancel = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_HIERARCHICAL(seatId));
    };

    const handleChangePrice = (value: string) => {
        form.setFieldsValue({ [CREATE_FLOOR_FORM_ITEMS_NAME.PRICE]: Number(value) });
    };

    const handleCreate = async () => {
        setIsSubmitting(true);
        const { price, country } = form.getFieldsValue(Object.values(CREATE_FLOOR_FORM_ITEMS_NAME));

        const body: FloorCreatePayload = {
            id: null,
            price: price * 1000,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            targeting: getTargetingFromCountry(country),
            ...floorSource,
        };

        try {
            if (floorSource.adUnit) {
                await createAdUnitFloor({ adUnitId: floorSource.adUnit.id, body }).unwrap();
                history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_AD_UNITS_FLOORS(seatId, adUnitId));
            } else if (floorSource.supply) {
                await createSupplyFloor({ supplyId: floorSource.supply.id, body }).unwrap();
                history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_SUPPLY_FLOORS(seatId, supplyId));
            } else if (floorSource.brand) {
                await createBrandFloor({ brandId: floorSource.brand.id, body }).unwrap();
                history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_PUBLISHER_BRAND(seatId, brandId));
            } else if (floorSource.publisher) {
                await createPublisherFloor({ publisherId: floorSource.publisher.id, body }).unwrap();
                history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_PUBLISHER(seatId, publisherId));
            } else {
                await createSeatFloor({ seatId: Number(seatId), body }).unwrap();
                history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_HIERARCHICAL(seatId));
            }
            notification.success({ message: "Floor created successfully" });
            setIsSubmitting(false);
        } catch (err) {
            notification.error({ message: err.data.errorDescription });
            setIsSubmitting(false);
        }
    };

    const handleEdit = async () => {
        setIsSubmitting(true);
        const { price, country } = form.getFieldsValue(Object.values(CREATE_FLOOR_FORM_ITEMS_NAME));

        const body: FloorUpdatePayload = {
            ...floor,
            price: price * 1000,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            targeting: getTargetingFromCountry(country),
            ...floorSource,
        };

        try {
            await updateFloor({ id: Number(paramsFloorId), body }).unwrap();
            history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_HIERARCHICAL(seatId as string));
            notification.success({ message: "Floor updated successfully" });
        } catch (err) {
            notification.error({ message: err.data.errorDescription });
        } finally {
            setIsSubmitting(false);
        }
    };
    const handleSubmit = () => {
        if (mode === "edit") {
            handleEdit();
            return;
        }
        handleCreate();
    };

    useEffect(() => {
        if (floor) {
            form.resetFields();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            form.setFieldsValue(getInitialValues(mode, floor));
        }
    }, [form, floor, mode]);

    return {
        floorPrice,
        form,
        handleCancel,
        handleChangePrice,
        handleSubmit,
        initialValues: getInitialValues(mode, floor),
        isSubmitting,
        setIsSubmitting,
    };
};
