import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { useGetSeatsAccountTypesQuery } from "@app/core/services";
import { usePublisherFormInstance } from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormInstance";

interface UseAccountType {
    isFetching: boolean;
    options: LabeledValue[];
    extra: string | undefined;
}

export const useAccountType = (): UseAccountType => {
    const { seat } = usePublisherFormInstance();
    const { data = [], isFetching } = useGetSeatsAccountTypesQuery();
    const options = useMemo(
        () => (data || []).map((accountType) => ({ value: accountType.id, label: accountType.name })),
        [data]
    );
    const extra: string | undefined = seat?.accountType?.name ? `Inherited value: ${seat.accountType.name}` : undefined;
    return {
        isFetching,
        options,
        extra,
    };
};
