import { Modal } from "antd";
import { FC, useState } from "react";

export const ENCRYPTION_KEY_CREATE_WARNING_MODAL_PROPS = {
    title: "Warning",
    okText: "Continue",
    "data-sdet": "encryption-key-create-warning-modal",
};

export const ENCRYPTION_KEY_CREATE_WARNING_MODAL_CONTENT =
    "Creating a New Key will cause previous keys to automatically expire. Are you sure you would like to proceed?";

export const useEncryptionKeyCreateWarningModal = (onContinue: () => void) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => setIsOpen(true);

    const onCancel = () => setIsOpen(false);

    const onOk = () => {
        setIsOpen(false);
        onContinue();
    };
    return {
        isOpen,
        handleOpen,
        onCancel,
        onOk,
    };
};

interface Props {
    onCancel: () => void;
    onOk: () => void;
    isOpen: boolean;
}

export const EncryptionKeyCreateWarningModal: FC<Props> = ({ onCancel, onOk, isOpen }) => {
    return (
        <Modal
            destroyOnClose
            {...ENCRYPTION_KEY_CREATE_WARNING_MODAL_PROPS}
            open={isOpen}
            onCancel={onCancel}
            onOk={onOk}
        >
            {ENCRYPTION_KEY_CREATE_WARNING_MODAL_CONTENT}
        </Modal>
    );
};
