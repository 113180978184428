import { FC } from "react";
import { Popover, Tooltip } from "antd";
import { useHelpArticles } from "@app/core/components/hooks";
import { HelpArticle } from "@app/core/services";
import { TooltipPlacement } from "antd/es/tooltip";
import DOMPurify from "dompurify";

interface HelpTooltipProps {
    helpKeyList: { [key: string]: string };
    helpKey: string;
    tooltipPlacement?: TooltipPlacement;
    popover?: boolean;
}

const HelpTooltip: FC<HelpTooltipProps> = ({ helpKeyList, helpKey, tooltipPlacement, popover = false, children }) => {
    const { helpArticles } = useHelpArticles(helpKeyList);
    const helpContent: HelpArticle | undefined = helpArticles?.[helpKey];

    return helpContent && helpContent.body !== "" && helpContent.body !== "#N/A" ? (
        popover ? (
            <Popover
                zIndex={2000}
                overlayStyle={{ maxWidth: "300px" }}
                content={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(helpContent.body) }} />}
                placement={tooltipPlacement}
            >
                {children}
            </Popover>
        ) : (
            <Tooltip
                title={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(helpContent.body) }} />}
                placement={tooltipPlacement}
            >
                {children}
            </Tooltip>
        )
    ) : (
        <>{children}</>
    );
};

export default HelpTooltip;
