import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useHistory, useParams } from "react-router-dom";
import {
    closeDrawer,
    closeDrawerWithoutType,
    openDrawer,
    selectIsInventoryDetailsDrawerOpen,
    selectIsPageInventoryDetailsDrawer,
    selectPageUrlInventoryDetailsDrawer,
    setPage,
} from "./reducer";

interface UseDetailsDrawer {
    isOpen: boolean;
    openDetailsDrawer: () => void;
    closeDetailsDrawer: () => void;
    handleLeftPage: () => void;
}

export const useInventoryDetailsDrawer = (): UseDetailsDrawer => {
    const isPage = useAppSelector(selectIsPageInventoryDetailsDrawer);
    const pageUrl = useAppSelector(selectPageUrlInventoryDetailsDrawer);
    const isOpen = useAppSelector(selectIsInventoryDetailsDrawerOpen);
    const dispatch = useAppDispatch();
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();

    const openDetailsDrawer = () => {
        dispatch(openDrawer());
    };

    const closeDetailsDrawer = () => {
        if (isPage) {
            if (isOpen) {
                history.push(pageUrl as string);
                dispatch(closeDrawerWithoutType());
            }
            return;
        }
        if (seatId) {
            history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH(seatId));
        }
        dispatch(closeDrawer());
    };

    const handleLeftPage = () => {
        dispatch(setPage({ isPage: false, pageUrl: undefined }));
        dispatch(closeDrawer());
    };

    return {
        isOpen,
        openDetailsDrawer,
        closeDetailsDrawer,
        handleLeftPage,
    };
};
