import { FC } from "react";
import { ReadOnlyField } from "@app/core/components/Fields/ReadOnlyField";

export const DealPriceModelField: FC = () => {
    return (
        <ReadOnlyField dataSdet="deal-price-model-read-only-field" label="Price Model">
            Fixed Price
        </ReadOnlyField>
    );
};
