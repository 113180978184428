import { FC } from "react";
import { css } from "@emotion/css";
import { useParams } from "react-router-dom";
import { NavBar } from "@app/core/components";

interface Props {
    src: (seatId: string) => string;
    title: string;
}

export const BuyerIFrame: FC<Props> = ({ src, title }) => {
    const { buyerId } = useParams<{ buyerId: string }>();

    return (
        <>
            <NavBar label={title} />
            <iframe
                className={css`
                    border: 0;
                    flex: 1;
                `}
                src={src(buyerId)}
                title={`${title} - Magnite`}
            />
        </>
    );
};
