import { Form } from "antd";
import { useMemo, useState } from "react";
import { LabeledValue } from "antd/lib/select";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { IabCategory, useGetIabCategoriesQuery } from "@app/core/services";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { TargetingDimensionTypeLabels } from "@app/features/targeting/constants";
import { formatCategoryLabel, formatCategorySearchValue } from "@app/features/targeting";

const { useFormInstance } = Form;

interface UseAdSourceIabCategoryBlockExceptionField {
    search: string;
    isFetching: boolean;
    value: LabeledValue[];
    options: LabeledValue[];
    setSearch: (value: string) => void;
    blockedIabCategories: IabCategory[];
    handleMenuChange: (value: LabeledValue[]) => void;
    onChange: (value: LabeledValue[], option: LabeledValue[]) => void;
}

export const useAdSourceIabCategoryBlockExceptionField = (): UseAdSourceIabCategoryBlockExceptionField => {
    const { validateFields } = useFormInstance();
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState("");
    const { data = [], isFetching } = useGetIabCategoriesQuery();

    const orderedData: IabCategory[] = useMemo(
        () => [...(data || [])].sort((a, b) => a.code.localeCompare(b.code, undefined, { numeric: true })),
        [data]
    );

    const options: LabeledValue[] = useMemo(
        () =>
            (orderedData || [])
                .map((target) => ({
                    value: target.id,
                    label: formatCategoryLabel(target, TargetingDimensionTypeLabels.ContentCategories),
                    search: formatCategorySearchValue(target, TargetingDimensionTypeLabels.ContentCategories),
                }))
                .filter((option) => option.search.toLowerCase().includes(search.toLowerCase())),
        [orderedData, search]
    );
    const value: LabeledValue[] = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES]
    );
    const blockedIabCategories: IabCategory[] = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_IAB_CATEGORIES]
    );
    const handleMenuChange = (value: LabeledValue[]): void => {
        dispatch(
            dealFormSelectFieldChange({
                field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES,
                value: value,
            })
        );
        validateFields([CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES]);
    };

    const onChange = (_a: LabeledValue[], option: LabeledValue[]): void => {
        dispatch(
            dealFormSelectFieldChange({
                field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES,
                value: option,
            })
        );
    };

    return {
        isFetching,
        onChange,
        handleMenuChange,
        setSearch,
        options,
        search,
        value,
        blockedIabCategories,
    };
};
