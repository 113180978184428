import { useParams } from "react-router-dom";
import { DealList, useGetDealAdSourceByIdQuery } from "@app/core/services/console/deals";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useEntityRedirect } from "./useEntityRedirect";

export const useDealRedirect = () => {
    const { dealId } = useParams<{ dealId: string }>();

    return useEntityRedirect<DealList, number>(
        Number(dealId),
        useGetDealAdSourceByIdQuery,
        "Deal",
        (data) => ROUTE_FORMATTERS.SEAT_DEAL_DETAILS(data.deal.seat.id, data.deal.id),
        (data: DealList) => data.deal.seat.id
    );
};
