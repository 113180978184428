import { FC } from "react";
import { Form } from "antd";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { SeatAdvertiserDomainFilterList } from "@app/features/inventory/components/Fields";

export const BlockAdvertiserDomainList: FC = () => {
    return (
        <Form.Item
            name={AD_UNIT_FORM_FIELDS.BLOCK_ADVERTISER_DOMAIN_LIST.name}
            label={AD_UNIT_FORM_FIELDS.BLOCK_ADVERTISER_DOMAIN_LIST.label}
        >
            <SeatAdvertiserDomainFilterList
                placeholder={AD_UNIT_FORM_FIELDS.BLOCK_ADVERTISER_DOMAIN_LIST.placeholder}
            />
        </Form.Item>
    );
};
