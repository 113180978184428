import { FC } from "react";
import { useThrottle } from "@app/core/utils";
import { useAppSelector } from "@app/core/store";
import { NONE } from "@app/core/components/constants";
import { LoadingCell } from "@app/core/components/LoadingCell";
import { AnimatedCell } from "@app/core/components/AnimatedCell";
import { AdStat, AdStatsById, getImpressionsUseRate, getOtimesForThrottleUnit } from "@app/features/adStats";
import { useCurrencyConversion } from "@app/features/dashboard/useCurrencyConversion";

interface InventoryHealthInventoryUseRateProps {
    adStats: AdStatsById;
    sourceId: number;
    isConnected: boolean;
}

export const InventoryHealthInventoryUseRate: FC<InventoryHealthInventoryUseRateProps> = ({
    adStats,
    sourceId,
    isConnected,
}) => {
    const adStat: AdStat | null = adStats[sourceId];
    const { preferredCurrency } = useCurrencyConversion();
    const currencyConversionMode = useAppSelector((state) => state.dashboard.settings.values.currencyConversionMode);
    const useRate: number | undefined = getImpressionsUseRate(adStat, preferredCurrency, currencyConversionMode, false);
    const otime = getOtimesForThrottleUnit(adStat);

    const throttledTries: number | undefined = useThrottle<number | undefined>(useRate || undefined, {
        forceUpdateDependencies: [otime, preferredCurrency, currencyConversionMode],
    });

    if (!isConnected || !adStat) return <LoadingCell />;

    if (throttledTries === undefined) return <>{NONE}</>;

    return <AnimatedCell value={throttledTries} decimals={2} suffix="%" />;
};
