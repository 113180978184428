import { Form } from "antd";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { AdSourcePayload } from "@app/core/services";

const { useWatch } = Form;

export const useOverrideFloorCurrencyCodeLabel = () => {
    const currencyCode = Form.useWatch(AD_SOURCE_FIELDS.CURRENCY.name)?.code;
    const floorType = useWatch<AdSourcePayload[typeof AD_SOURCE_FIELDS.FLOOR_TYPE.name]>(
        AD_SOURCE_FIELDS.FLOOR_TYPE.name
    );

    switch (floorType?.name) {
        case "IncreasePercent":
        case "DecreasePercent":
            return "";
        case "Override":
        case "Fallback":
        case "IncreaseCPM":
        case "DecreaseCPM":
        default:
            return currencyCode;
    }
};
