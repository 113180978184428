import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useGetSeatBuyerSeatListsByIdQuery, useUpdateSeatBuyerSeatListsByIdMutation } from "@app/core/services";
import { CONTROLS_ROUTES } from "@app/features/controls/constants";
import { notification } from "antd";
import { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { InitialValuesType } from "../constants";
import { getSelectBuyerSeats, parseValueToApi } from "../helpers";

interface UseBuyerSeatListsEditPage {
    buyerSeatListId: number;
    name: string;
    isLoading: boolean;
    isSubmitting: boolean;
    initialValues: InitialValuesType;
    handleSubmit: (values: InitialValuesType) => void;
    handleCancel: () => void;
    handleBack: () => void;
    handlePostDelete: () => void;
}

export const useBuyerSeatListsEditPage = (): UseBuyerSeatListsEditPage => {
    const { seatId, buyerSeatListId } = useParams<{ seatId: string; buyerSeatListId: string }>();
    const history = useHistory();

    const { data, isFetching } = useGetSeatBuyerSeatListsByIdQuery(buyerSeatListId);
    const initialValues: InitialValuesType = useMemo(
        () => ({
            id: data?.id ? data.id : null,
            name: data?.name as string,
            description: data?.description,
            buyerSeats: getSelectBuyerSeats(data?.buyerSeats),
        }),
        [data]
    );
    const [updateSeatBuyerSeatsList, { isLoading }] = useUpdateSeatBuyerSeatListsByIdMutation();

    const handleSubmit = async (values: InitialValuesType) => {
        const body = parseValueToApi(values);

        try {
            const { id } = await updateSeatBuyerSeatsList({
                id: buyerSeatListId,
                body,
            }).unwrap();
            history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_DETAILS_PAGE(seatId, id));
            notification.success({ message: "Buyer Seat List updated successfully" });
        } catch (error) {
            notification.error({
                message: `Failed to update Buyer Seat List: ${
                    error?.data?.errorDescription || error?.data?.errors?.map(({ message }) => message).join(", ")
                }`,
            });
        }
    };

    const handleCancel = () => {
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_TABS(seatId, CONTROLS_ROUTES.BUYER_SEAT_LIST));
    };

    const handleBack = () => {
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_DETAILS_PAGE(seatId, buyerSeatListId));
    };

    const handlePostDelete = () => {
        history.replace(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_TABS(seatId, CONTROLS_ROUTES.BUYER_SEAT_LIST));
    };

    return {
        name: data?.name || "",
        buyerSeatListId: Number(buyerSeatListId),
        isLoading: isFetching,
        isSubmitting: isLoading,
        handlePostDelete,
        initialValues,
        handleSubmit,
        handleCancel,
        handleBack,
    };
};
