import { Form } from "antd";
import { FC } from "react";
import { Seat } from "@app/core/services";
import { ConditionalTagRender } from "@app/core/components";
import { ThirdpartyPixels } from "@app/features/inventory/components/Fields";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

interface PixelsProps {
    seat: Seat;
    edit?: boolean;
}

export const CustomPixels: FC<PixelsProps> = ({ seat, edit = false, ...rest }) => {
    return (
        <Form.Item
            name={INVENTORY_FORM_FIELDS.CUSTOM_PIXELS.name}
            label={INVENTORY_FORM_FIELDS.CUSTOM_PIXELS.label}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 }}
            {...rest}
        >
            {edit ? (
                <ThirdpartyPixels placeholder={INVENTORY_FORM_FIELDS.CUSTOM_PIXELS.placeholder} />
            ) : (
                <ConditionalTagRender data={seat} property={INVENTORY_FORM_FIELDS.CUSTOM_PIXELS.name} />
            )}
        </Form.Item>
    );
};
