import { CSSProperties, FC, useEffect, useState } from "react";
import { Form, Input, InputNumber, Select } from "antd";
import { CustomRuleOperators } from "@app/features/targeting/constants";
import { TagRender } from "@app/core/components";

interface Props {
    input: "textarea" | "number";
    isValidationShown: boolean;
    onChange: (value: string | string[] | number | number[] | null) => void;
    operator: CustomRuleOperators;
    style: CSSProperties;
    value: string | string[] | number | number[] | null;
}

const isEmptyString = (value) => typeof value === "string" && !value.replace(" ", "").length;
const filterEmptyStrings = (value) =>
    Array.isArray(value) ? value.filter((v) => !isEmptyString(v)) : !isEmptyString(value) ? value : null;

const CustomRuleInputInner: FC<Omit<Props, "isValidationShown">> = ({ input, operator, onChange, value, style }) => {
    // The Redux onChange handler has noticable lag when re-rendering a large Custom Rule,
    // so we use local state to avoid re-rendering the entire tree on every keystroke
    const [localValue, _setLocalValue] = useState(Array.isArray(value) ? value.join("\n") : value);
    const setLocalValue = (v) => _setLocalValue(filterEmptyStrings(v));
    const [localArrValue, _setLocalArrValue] = useState(
        value === null ? [] : Array.isArray(value) ? value : String(value).split("\n")
    );
    const setLocalArrValue = (v) => _setLocalArrValue(filterEmptyStrings(v));

    useEffect(() => {
        setLocalValue(Array.isArray(value) ? value.join("\n") : value);
        setLocalArrValue(value === null ? [] : Array.isArray(value) ? value : String(value).split("\n"));
    }, [value]);

    // Null operators don't need an input at all
    if ([CustomRuleOperators.IsNull, CustomRuleOperators.IsNotNull].includes(operator)) {
        return null;
    }

    // Number rules use a number input
    if (input === "number") {
        return (
            <InputNumber
                style={style}
                onBlur={() => onChange(localValue)}
                onChange={setLocalValue}
                value={localValue}
                placeholder="Enter Value"
            />
        );
    }

    // List operators use a tags mode Select to enter multiple values
    if ([CustomRuleOperators.IsInList, CustomRuleOperators.NotInList].includes(operator)) {
        return (
            <Select
                style={style}
                onChange={(v) => setLocalArrValue(v)}
                onBlur={() => onChange(localArrValue)}
                value={localArrValue}
                mode="tags"
                tokenSeparators={[",", "\n", "\r", "\r\n", "\n\r"]}
                tagRender={TagRender}
                placeholder={`Enter values, press enter or "," to add`}
            />
        );
    }

    // String inputs use a regular input
    // TODO: Confirm whether non-list inputs should also accept one value per line
    // Legacy UI appears to have a "one value per line" placeholder, but sends a non-array value
    // and doesn't allow entering multiple lines of inputs - may be a bug or may be intentional
    return (
        <Input
            style={style}
            onBlur={() => onChange(localValue)}
            onChange={(e) => setLocalValue(e.target.value)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={localValue}
            placeholder="Enter Value"
        />
    );
};

export const CustomRuleInput: FC<Props> = ({ isValidationShown, value, ...props }) => {
    const isInvalid = isValidationShown && !value;

    return (
        <Form.Item
            style={{ margin: 0 }}
            validateStatus={isInvalid ? "error" : undefined}
            help={isInvalid ? "A value is required" : undefined}
        >
            <CustomRuleInputInner value={filterEmptyStrings(value)} {...props} />
        </Form.Item>
    );
};
