import { FC } from "react";
import { Form, Select } from "antd";
import { SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useMaskIFAForDemands } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/SupplyDetailsSection/Fields/MaskIFAForDemands/useMaskIFAForDemands";

export const MaskIFAForDemands: FC = () => {
    const { isFetching, options } = useMaskIFAForDemands();
    return (
        <Form.Item
            name={SUPPLY_FORM_FIELDS.MASK_IFA_FOR_DEMANDS.name}
            label={SUPPLY_FORM_FIELDS.MASK_IFA_FOR_DEMANDS.label}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 }}
        >
            <Select
                showSearch
                allowClear
                labelInValue
                mode="multiple"
                options={options}
                loading={isFetching}
                optionFilterProp="label"
                style={{ width: "100%" }}
                autoClearSearchValue={false}
                placeholder={SUPPLY_FORM_FIELDS.MASK_IFA_FOR_DEMANDS.placeholder}
            />
        </Form.Item>
    );
};
