import { FC } from "react";
import { SeatIFrame } from "@app/core/components";
import conf from "@app/core/conf";

export const AdminPage: FC = () => {
    return (
        <SeatIFrame
            src={(seatId) => `${conf.mctvConsoleRoot}/ssp/admin/platform-admin/seats/${seatId}`}
            title="Admin"
        />
    );
};
