import { useEffect, useRef } from "react";
import { downloadFile } from "@app/core/utils/downloadFile";
import { useLazyGetVastErrorTotalsQuery } from "./vastErrors";
import { VastErrorTotalsFileFormat } from "./types";

const FILE_NAME = "VAST Errors";

interface UseDownloadVastErrorTotals {
    downloadVastErrorTotals: (adSourceId: number, fileFormat: VastErrorTotalsFileFormat) => void;
}

export const useDownloadVastErrorTotals = (): UseDownloadVastErrorTotals => {
    const [getVastErrorTotals, { data: vastErrorTotals, isFetching }] = useLazyGetVastErrorTotalsQuery();
    const requestedDownloadFormatRef = useRef<VastErrorTotalsFileFormat | null>(null);

    useEffect(() => {
        const downloadRequested = requestedDownloadFormatRef.current;
        if (downloadRequested && !isFetching && vastErrorTotals) {
            const fileFormat = requestedDownloadFormatRef.current as VastErrorTotalsFileFormat;
            downloadFile(FILE_NAME, fileFormat, vastErrorTotals);
            requestedDownloadFormatRef.current = null;
        }
    }, [isFetching, vastErrorTotals]);

    const downloadVastErrorTotals = (adSourceId: number, fileFormat: VastErrorTotalsFileFormat) => {
        const stillHandlingLastDownloadRequest = requestedDownloadFormatRef.current;
        if (!stillHandlingLastDownloadRequest) {
            getVastErrorTotals({ adSourceId, fileFormat });
            requestedDownloadFormatRef.current = fileFormat;
        }
    };

    return {
        downloadVastErrorTotals,
    };
};
