import { PriceOverrideConfig } from "@app/core/services";
import { Form, Typography } from "antd";
import { ColProps } from "antd/es/grid";
import { FC } from "react";
import { FIXED_CLEARING_PRICE_TYPE } from "../../constants";

export const PRICE_OVERRIDE_DETAILS_CONTENT_SDET = "classifications-priceOverride-details-content";

interface Props {
    priceOverride?: PriceOverrideConfig;
    wrapperCol?: ColProps;
}

export const PriceOverrideDetailsContent: FC<Props> = ({ priceOverride, wrapperCol = { xs: 24, md: 12 } }) => {
    return priceOverride ? (
        <Form layout="vertical" data-sdet={PRICE_OVERRIDE_DETAILS_CONTENT_SDET} wrapperCol={wrapperCol}>
            <Form.Item label="Name">
                <Typography.Text>{priceOverride.name}</Typography.Text>
            </Form.Item>
            <Form.Item label="Ad Unit">
                <Typography.Text>{priceOverride.adUnit.name}</Typography.Text>
            </Form.Item>
            <Form.Item label="Price Override Type">
                <Typography.Text>{priceOverride.priceOverrideType.name}</Typography.Text>
            </Form.Item>
            {priceOverride.priceOverrideType.id === FIXED_CLEARING_PRICE_TYPE && (
                <Form.Item label="Override Price">
                    <Typography.Text>{priceOverride.overridePrice}</Typography.Text>
                </Form.Item>
            )}
        </Form>
    ) : null;
};
