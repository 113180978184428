import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ErrorResponse, useGetSeatThirdPartyPixelByIdQuery } from "@app/core/services/console";
import { ErrorPage, PageLoader } from "@rubicon/antd-components";
import { Alert, Card, Layout } from "antd";
import { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CustomPixelDetailsContentActions } from "./CustomPixelDetailsContentActions";
import { CustomPixelFormReadonly } from "./CustomPixelForm/CustomPixelFormReadonly";
import { useUserAccess } from "@app/core/auth";
import { PageHeader } from "@ant-design/pro-layout";

export const CustomPixelDetailsPage: FC = () => {
    const { seatId, pixelId } = useParams<{ pixelId: string; seatId: string }>();
    const { data: customPixel, isLoading, error } = useGetSeatThirdPartyPixelByIdQuery(pixelId);
    const history = useHistory();
    const { canEditSeat } = useUserAccess();

    if (isLoading) return <PageLoader />;

    if (error) {
        const { status, data: errorData } = error as ErrorResponse;
        return <ErrorPage code={status} title={errorData?.errorCode} message={errorData?.errorDescription} />;
    }
    // the API does not error out for requesting a non-existing pixel request, just response void
    if (!customPixel) {
        return <Alert banner showIcon type="warning" message="There is no data for this request" />;
    }

    const { id, name } = customPixel;
    return (
        <>
            <PageHeader
                title={<span data-sdet="custom-pixel-details-page-title">{name}</span>}
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, "custom-pixels"))}
            >
                {canEditSeat && (
                    <CustomPixelDetailsContentActions
                        customPixelId={id}
                        name={name}
                        seatId={seatId}
                        postDeleteEvent={() =>
                            history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, "custom-pixels"))
                        }
                    />
                )}
            </PageHeader>
            <Layout.Content>
                <Card>
                    <CustomPixelFormReadonly customPixel={customPixel} isDetailsPage />
                </Card>
            </Layout.Content>
        </>
    );
};
