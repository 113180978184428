import { FC } from "react";
import { Card, Col, Row } from "antd";
import { GUTTER } from "@app/features/deals/constants";
import {
    DealConfigureAdSourceField,
    DealConsentField,
} from "@app/features/deals/DealForm/DealDemandFormSections/ConfigureAdSourceShownSection/Fields";

export const ConfigureAdSourceShownSection: FC = () => {
    return (
        <Card
            style={{ margin: "1.5rem 0" }}
            styles={{ body: { paddingBottom: 0 } }}
            data-sdet="deal-create-form-adsource-shown-section"
        >
            <Row gutter={GUTTER}>
                <Col span={24}>
                    <DealConfigureAdSourceField />
                </Col>
                <Col span={24}>
                    <DealConsentField />
                </Col>
            </Row>
        </Card>
    );
};
