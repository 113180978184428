import { FC } from "react";
import { Card, Form } from "antd";
import { BulkOperationType } from "@app/core/services";
import { BULK_OPERATION_FORM_ITEMS } from "../../../constants";
import { getEditableFieldsOptions } from "../../../helpers";
import { useUserAccess } from "@app/core/auth";
import { SelectFieldWithLocalOptions } from "../../../shared/SelectFieldWithLocalOptions";
import { BulkOperationTypeField } from "./BulkOperationTypeField";
import { BulkOperationEntityTypeField } from "./BulkOperationEntityTypeField";

export const BulkOperationEntityTypeSection: FC = () => {
    const { useWatch } = Form;
    const type = useWatch(BULK_OPERATION_FORM_ITEMS.OPERATION_TYPE.name);
    const entityType = useWatch(BULK_OPERATION_FORM_ITEMS.ENTITY_TYPE.name);

    const { isSysAdmin, isPubAcctMgr, hasInternalAccess } = useUserAccess();

    return (
        <Card>
            <BulkOperationTypeField />
            <BulkOperationEntityTypeField hasFullOptionsAccess={hasInternalAccess} />
            {type === BulkOperationType.Edit && entityType ? (
                <Form.Item {...BULK_OPERATION_FORM_ITEMS.FIELDS_TO_EDIT} wrapperCol={{ xs: 24, md: 12 }}>
                    <SelectFieldWithLocalOptions
                        hasSelectAll
                        sdet="fields-to-edit"
                        mode="multiple"
                        namePath={BULK_OPERATION_FORM_ITEMS.FIELDS_TO_EDIT.name}
                        options={getEditableFieldsOptions(entityType, isSysAdmin || isPubAcctMgr)}
                    />
                </Form.Item>
            ) : null}
        </Card>
    );
};
