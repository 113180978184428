import { FC } from "react";
import { FilterSelect } from "@app/core/components/FilterSelect";
import { useSeatAdSourcesLabelKeyFilter } from "./useSeatAdSourcesLabelKeyFilter";
import { HelpTooltip } from "@app/core/components";
import { AdSourcesHealthHelpKeys } from "@app/features/seatAdSources/constants";

export const SeatAdSourcesLabelKeyFilter: FC = () => {
    const { handleChangeValue, options, value, isLoading } = useSeatAdSourcesLabelKeyFilter();

    return (
        <FilterSelect
            label={
                <HelpTooltip
                    helpKey={AdSourcesHealthHelpKeys.Label}
                    helpKeyList={AdSourcesHealthHelpKeys}
                    popover={true}
                >
                    Label
                </HelpTooltip>
            }
            onChange={handleChangeValue}
            isLoading={isLoading}
            options={options}
            value={value}
            dataSdet="seat-ad-sources-label-key-filter"
            mode="multiple"
            placeholder="Select Key..."
        />
    );
};
