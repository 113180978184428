import { Col, Form, Input, Row } from "antd";
import { FC } from "react";
import { SearchItemSeat, SeatPublisher, useGetSeatPublishersQuery, useSearchQuery } from "@app/core/services";
import { useParams } from "react-router-dom";
import { BULK_OPERATION_FORM_ITEMS } from "../../../constants";
import { GenericLoadOnScrollField } from "../../../shared/GenericLoadOnScrollField";
import { CopyInventoryChildrenField } from "./CopyInventoryChildrenField";

export const BulkOperationCopyPublisherForm: FC = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const form = Form.useFormInstance();

    return (
        <>
            <Row>
                <Col xs={24} md={12}>
                    <Form.Item
                        {...BULK_OPERATION_FORM_ITEMS.PUBLISHER_TO_COPY}
                        rules={[{ required: true, message: "Please select a Publisher to Copy" }]}
                        wrapperCol={{ xs: 24 }}
                    >
                        <GenericLoadOnScrollField<SeatPublisher>
                            labelInValue
                            sdet="publisher-field"
                            useGetOptionsQuery={useGetSeatPublishersQuery}
                            extraParams={{ seatId: Number(seatId), channels: false }}
                            optionMapper={({ name, id, seat }: SeatPublisher) => ({
                                label: `${seat.name} > ${name}`,
                                value: id,
                            })}
                            onChange={() => form.setFieldsValue({ recursiveCopy: false, copyScope: null })}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={{ span: 10, offset: 2 }}>
                    <CopyInventoryChildrenField />
                </Col>
            </Row>
            <Form.Item
                {...BULK_OPERATION_FORM_ITEMS.NAME_FOR_NEW_PUBLISHER}
                rules={[{ required: true, whitespace: true, message: "Please put a Name for New Publisher" }]}
                wrapperCol={{ xs: 24, md: 12 }}
            >
                <Input data-sdet="name-for-new-publisher-field" />
            </Form.Item>
            <Form.Item
                {...BULK_OPERATION_FORM_ITEMS.SELECT_SEAT_PARENT}
                rules={[{ required: true, message: "Please select a Seat Parent" }]}
                wrapperCol={{ xs: 24, md: 12 }}
            >
                <GenericLoadOnScrollField<SearchItemSeat>
                    labelInValue
                    sdet="seat-parent-field"
                    useGetOptionsQuery={useSearchQuery}
                    extraParams={{ type: "seat" }}
                    optionMapper={({ name, id }: SearchItemSeat) => ({ label: name, value: id })}
                />
            </Form.Item>
        </>
    );
};
