import { FC } from "react";
import { Radio } from "antd";
import { OVERRIDE_TRANSPARENCY_OPTIONS } from "@app/features/deals/constants";
import { useAdSourceDomainNameField } from "./useAdSourceDomainNameField";
import { TransparencyFormItem } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceTransparencySection/Fields";

export const AdSourceDomainNameField: FC = () => {
    const { onChange, value, helpText } = useAdSourceDomainNameField();

    return (
        <TransparencyFormItem label="Domain Name" help={helpText} data-sdet="domain-name">
            <Radio.Group
                onChange={(e) => onChange(e?.target?.value)}
                value={value}
                options={OVERRIDE_TRANSPARENCY_OPTIONS}
            />
        </TransparencyFormItem>
    );
};
