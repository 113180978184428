import { FC } from "react";
import { Layout } from "antd";
import RecycleBinHeader from "./RecycleBinHeader";
import RecycleBinToolbar from "./RecycleBinToolbar";
import { RecycleBinTable } from "./RecycleBinTable/";

export const RecycleBinPage: FC = () => (
    <>
        <RecycleBinHeader />
        <Layout.Content>
            <RecycleBinToolbar />
            <RecycleBinTable />
        </Layout.Content>
    </>
);
