import { useUserAccess } from "@app/core/auth";
import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useDispatchOnBlur } from "@app/features/deals/DealForm/hooks";
import { dealFormInputFieldChange } from "@app/features/deals/DealForm/reducer";
import { isFallbackOpportunityEstimateEligible } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/pacingUtils";

interface UseAdSourceFallbackOpportunity {
    isVisible: boolean;
    value: number | null;
    onChange: (value: number) => void;
    handleBlur: () => void;
}

export const useAdSourceFallbackOpportunity = (): UseAdSourceFallbackOpportunity => {
    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_FALLBACK]
    );
    const dealType = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TYPE]);
    const pacingType = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_TYPE]
    );
    const { showFallbackOpportunity } = useUserAccess();
    const isVisible = isFallbackOpportunityEstimateEligible(dealType, pacingType, showFallbackOpportunity);

    const { onChange, onBlur } = useDispatchOnBlur(value);

    const handleBlur = () => {
        onBlur(dealFormInputFieldChange, CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_FALLBACK);
    };

    return {
        isVisible,
        value,
        onChange,
        handleBlur,
    };
};
