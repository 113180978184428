import { consoleApi, BUYER_SEAT_LISTS_TAG, BUYER_SEAT_LIST_ITEM_TAG } from "./console";
import { toQueryString } from "./utils";

interface BuyerSeat {
    creationDate: string;
    updateDate: string;
    id: number;
    code: string;
    name: string;
}

interface BuyerSeatListsBuyerSeats {
    buyerSeat: BuyerSeat;
    network: {
        id: number;
        name: string;
        entityType: string;
    };
}

export interface BuyerSeatListsBuyerSeatsBody {
    buyerSeats?: { id: number }[];
    description?: string;
    name: string;
    id: number | null;
}

export interface BuyerSeatListsBuyerSeatsParams {
    seatId: number | string;
    body: BuyerSeatListsBuyerSeatsBody;
}

interface BuyerSeatListsBuyerSeatsUpdateParams {
    id: number | string;
    body: BuyerSeatListsBuyerSeatsBody;
}

export interface BuyerSeatList {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    description: string;
    buyerSeats: BuyerSeat[];
}

export interface GetBuyerSeatListParams {
    seatId: number;
    max?: number;
    page?: number;
    keyword?: string;
}

export const buyerSeatListsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getSeatBuyerSeatLists: builder.query<BuyerSeatList[], GetBuyerSeatListParams>({
            query: ({ seatId, ...rest }) => {
                const params: Omit<GetBuyerSeatListParams, "seatId"> = {
                    max: 50,
                    page: 1,
                    keyword: "",
                    ...rest,
                };
                return `seats/${seatId}/buyerSeatLists;${toQueryString(params)}`;
            },
            providesTags: () => [BUYER_SEAT_LISTS_TAG],
        }),
        getBuyerSeatListBuyerSeats: builder.query<BuyerSeatListsBuyerSeats[], Omit<GetBuyerSeatListParams, "seatId">>({
            query: (p) => {
                const params = {
                    max: 50,
                    page: 1,
                    keyword: "",
                    ...p,
                };
                return `buyerSeatLists/buyerSeats;${toQueryString(params)}`;
            },
        }),
        getSeatBuyerSeatListsById: builder.query<BuyerSeatList, string>({
            query: (id) => `buyerSeatLists/${id}`,
            providesTags: (_, __, id) => [{ type: BUYER_SEAT_LIST_ITEM_TAG, id }],
        }),
        creteSeatBuyerSeatsList: builder.mutation<BuyerSeatList, BuyerSeatListsBuyerSeatsParams>({
            query: ({ seatId, body }: BuyerSeatListsBuyerSeatsParams) => ({
                url: `seats/${seatId}/buyerSeatLists`,
                method: "POST",
                body,
            }),
            invalidatesTags: [BUYER_SEAT_LISTS_TAG],
        }),
        updateSeatBuyerSeatListsById: builder.mutation<BuyerSeatList, BuyerSeatListsBuyerSeatsUpdateParams>({
            query: ({ id, body }: BuyerSeatListsBuyerSeatsUpdateParams) => ({
                url: `buyerSeatLists/${id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: (_, err, { id }) =>
                err ? [] : [{ type: BUYER_SEAT_LIST_ITEM_TAG, id }, { type: BUYER_SEAT_LISTS_TAG }],
        }),
        deleteSeatBuyerSeatList: builder.mutation<void, string | number>({
            query: (id) => ({
                url: `buyerSeatLists/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err) => (err ? [] : [BUYER_SEAT_LISTS_TAG, BUYER_SEAT_LIST_ITEM_TAG]),
        }),
    }),
});

export const {
    useGetSeatBuyerSeatListsQuery,
    useGetSeatBuyerSeatListsByIdQuery,
    useDeleteSeatBuyerSeatListMutation,
    useGetBuyerSeatListBuyerSeatsQuery,
    useCreteSeatBuyerSeatsListMutation,
    useUpdateSeatBuyerSeatListsByIdMutation,
} = buyerSeatListsApi;
