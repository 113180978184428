import { useUserAccess } from "@app/core/auth";
import {
    DEFAULT_ADVERTISER_FREQUENCY_CAPPING,
    CHANNEL_FORM_FIELDS,
} from "@app/features/inventory/HierarchyForms/constants";
import { usePublisherChannelFormInstance } from "@app/features/inventory/HierarchyForms/PublisherChannelForm/PublisherChannelFormInstance";

interface UseDetailedConfiguration {
    hasFreqCaps: boolean;
    isInternalOnly: boolean;
    isUDOptOutShown: boolean;
    isLSAWindowSizeSecondsShown: boolean;
    isLiveStreamAccelerationShown: boolean;
}

export const useDetailedConfiguration = (): UseDetailedConfiguration => {
    const { isTremorUser } = useUserAccess();
    const { useWatch, seat } = usePublisherChannelFormInstance();
    const freqCapp = useWatch(CHANNEL_FORM_FIELDS.ADV_FREQ_CAPP.name);
    const liveStreamAcceleration: boolean = useWatch(CHANNEL_FORM_FIELDS.LIVE_STREAM_ACCELERATION.name);
    const hasFreqCaps = !freqCapp || !(freqCapp?.value === DEFAULT_ADVERTISER_FREQUENCY_CAPPING.value);

    const isUDOptOutShown = !!seat?.udeEnabled;
    const isLiveStreamAccelerationShown = !!seat?.lsaEnabled;
    const isLSAWindowSizeSecondsShown: boolean = liveStreamAcceleration && isLiveStreamAccelerationShown;

    return {
        hasFreqCaps,
        isUDOptOutShown,
        isLSAWindowSizeSecondsShown,
        isLiveStreamAccelerationShown,
        isInternalOnly: isTremorUser,
    };
};
