import { FC, useMemo } from "react";
import { AdSource } from "@app/core/services";
import {
    SeatAdSourcesPerformanceTab as PerformanceTab,
    useSeatAdSourcesDetailsPerformance,
} from "./useSeatAdSourcesDetailsPerformance";
import { Tabs } from "antd";
import { SeatAdSourcesPerformanceHistorical } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/SeatAdSourcesDetailsDrawerContent/SeatAdSourcesDetailsDrawerView/secondaryViews";
import { isAdSourceDealPerformanceEligible, isAdSourcePrebid } from "@app/features/seatAdSources/utils";
import { Overview } from "./OverviewTab/Overview";
import { AdBidStats } from "./AdBisStats/AdBidStats";

const PerformanceTabs = {
    [PerformanceTab.Overview]: {
        key: PerformanceTab.Overview,
        tab: "Overview",
    },
    [PerformanceTab.Historical]: {
        key: PerformanceTab.Historical,
        tab: "Historical",
    },
    [PerformanceTab.AdBid]: {
        key: PerformanceTab.AdBid,
        tab: "Ad/Bidder Stats",
    },
};

interface Props {
    adSource: AdSource;
}

export const SeatAdSourcesPerformance: FC<Props> = ({ adSource }) => {
    const { activeTab, onSwitchTab } = useSeatAdSourcesDetailsPerformance();
    const isDealPerformanceEligible = useMemo(() => isAdSourceDealPerformanceEligible(adSource.type.id), [adSource]);
    const isPrebidAdSource = useMemo(() => isAdSourcePrebid(adSource.type.id), [adSource]);

    const renderActivePerformanceTab = () => {
        switch (activeTab) {
            case PerformanceTab.Overview:
                return (
                    <Overview adSouceName={adSource.name} adSourceId={adSource.id} adSourceTypeId={adSource.type.id} />
                );
            case PerformanceTab.Historical:
                return <SeatAdSourcesPerformanceHistorical adSourceId={adSource.id} />;
            case PerformanceTab.AdBid:
                return <AdBidStats adSourceId={adSource.id} />;
        }
    };

    return (
        <>
            <Tabs activeKey={activeTab} defaultActiveKey={activeTab} onChange={onSwitchTab}>
                <Tabs.TabPane {...PerformanceTabs[PerformanceTab.Overview]} />
                {isDealPerformanceEligible && <Tabs.TabPane {...PerformanceTabs[PerformanceTab.Historical]} />}
                {(isDealPerformanceEligible || isPrebidAdSource) && (
                    <Tabs.TabPane {...PerformanceTabs[PerformanceTab.AdBid]} />
                )}
            </Tabs>
            {renderActivePerformanceTab()}
        </>
    );
};
