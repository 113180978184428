import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "./types";

export const convertSecondaryViewFromURL = (secondaryView: string): SecondaryDetailsView | undefined => {
    switch (secondaryView) {
        case "ad-serving":
            return SecondaryDetailsView.AdServing;
        case "change-history":
            return SecondaryDetailsView.ChangeHistory;
        case "creative-review":
            return SecondaryDetailsView.CreativeReview;
        case "ladle":
            return SecondaryDetailsView.Ladle;
        case "performance":
            return SecondaryDetailsView.Performance;
        case "permissions":
            return SecondaryDetailsView.Permissions;
        case "timing-stats":
            return SecondaryDetailsView.TimingStats;
        case "vast-errors":
            return SecondaryDetailsView.VastErrors;
        default:
            return undefined;
    }
};

export const convertSecondaryViewToURL = (secondaryView: SecondaryDetailsView): string => {
    switch (secondaryView) {
        case SecondaryDetailsView.AdServing:
            return "ad-serving";
        case SecondaryDetailsView.ChangeHistory:
            return "change-history";
        case SecondaryDetailsView.CreativeReview:
            return "creative-review";
        case SecondaryDetailsView.Ladle:
            return "ladle";
        case SecondaryDetailsView.Performance:
            return "performance";
        case SecondaryDetailsView.Permissions:
            return "permissions";
        case SecondaryDetailsView.TimingStats:
            return "timing-stats";
        case SecondaryDetailsView.VastErrors:
            return "vast-errors";
    }
};
