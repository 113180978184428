import { Col, Form, Select as AntdSelect } from "antd";
import { useMacrosPickerFilter } from "./useMacrosPickerFilter";

export const MACROS_PICKER_SDET = "macros-picker-filter";

export const MacrosPickerFilter = () => {
    const { selected, options, handleMacrosPickerChange } = useMacrosPickerFilter();

    return (
        <Col xs={12} sm={5}>
            <Form.Item label="Common Macros">
                <AntdSelect
                    data-sdet={MACROS_PICKER_SDET}
                    onChange={handleMacrosPickerChange}
                    options={options}
                    value={selected}
                />
            </Form.Item>
        </Col>
    );
};

export default MacrosPickerFilter;
