import { useAppDispatch, useAppSelector } from "@app/core/store";
import { selectDealHealthPagination, setDealHealthTablePagination } from "@app/features/deals/DealHealthPage/reducer";
import { DealHealthTablePagination } from "@app/features/deals/DealHealthPage/types";
import { PAGE_SIZE_OPTIONS } from "@app/core/components/constants";

interface UseDealHealthTablePagination extends DealHealthTablePagination {
    pageSizeOptions: typeof PAGE_SIZE_OPTIONS;
    handleChange: (page: number, max: number) => void;
}
export const useDealHealthTablePagination = (totalResults: number): UseDealHealthTablePagination => {
    const { page, maxResults } = useAppSelector(selectDealHealthPagination);
    const dispatch = useAppDispatch();

    const handleChange = (page: number, maxResults: number): void => {
        dispatch(setDealHealthTablePagination({ page, maxResults, totalResults }));
    };

    return {
        handleChange,
        maxResults,
        page,
        totalResults,
        pageSizeOptions: PAGE_SIZE_OPTIONS,
    };
};
