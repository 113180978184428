import { FC, ReactNode } from "react";
import { Button, Checkbox, Row } from "antd";
import { merge } from "lodash";

import { css } from "@emotion/css";
import { ChartColors } from "@app/core/components/constants";
import { LegendLayout, LegendOptions, LegendProps } from "./types";

const DefaultLegendOptions: LegendOptions = {
    layout: "horizontal",
    showToggleAllButton: true,
};

const renderByLayout = (checkboxes: ReactNode[], toggleAllButton: ReactNode, layout: LegendLayout) => {
    if (layout === "vertical") {
        return (
            <div
                data-sdet="vertical-legend"
                className={css`
                    padding-left: 65px;
                `}
            >
                <Row
                    className={css`
                        margin-bottom: 5px;
                    `}
                >
                    {toggleAllButton}
                </Row>
                {checkboxes.map((checkbox, index) => (
                    <Row key={`checkbox-${index}`}>{checkbox}</Row>
                ))}
            </div>
        );
    } else {
        return (
            <Row data-sdet="horizontal-legend" justify="center" align="middle">
                {toggleAllButton}
                {checkboxes}
            </Row>
        );
    }
};

export const Legend: FC<LegendProps> = ({
    hasLegendEntries,
    checkboxesState,
    toggleButtonText,
    toggleSingleChecked,
    toggleAllChecked,
    legendOptions,
}) => {
    if (!hasLegendEntries) {
        return null;
    }

    const options: LegendOptions = merge({}, DefaultLegendOptions, legendOptions);

    const toggleAllButton = options.showToggleAllButton ? (
        <Button
            onClick={toggleAllChecked}
            className={css`
                margin-right: 20px;
            `}
        >
            {toggleButtonText}
        </Button>
    ) : (
        <></>
    );

    const checkboxes = checkboxesState.map(({ series, isChecked }, index) => {
        const color = options?.colorMap ? options.colorMap[series] : ChartColors.Default;
        return (
            <Checkbox
                data-sdet={`legend-entry-${index}`}
                key={series}
                checked={isChecked}
                onChange={() => toggleSingleChecked(series)}
                className={css`
                    .ant-checkbox-checked::after {
                        border-color: ${color};
                    }
                    .ant-checkbox-inner,
                    .ant-checkbox-input:focus + .ant-checkbox-inner,
                    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
                    .ant-checkbox:hover .ant-checkbox-inner {
                        border-color: ${color};
                        background-color: ${color};
                    }
                `}
            >
                {options?.textMap ? options.textMap[series] : series}
            </Checkbox>
        );
    });

    return (
        <div
            data-sdet="legend"
            className={css`
                margin-top: 25px;
            `}
        >
            {renderByLayout(checkboxes, toggleAllButton, options.layout as LegendLayout)}
        </div>
    );
};
