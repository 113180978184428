import { FC } from "react";
import { Form, Select } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useDealTransactionCurrencyField } from "@app/features/deals/DealForm/DealDemandFormSections/DealDemandConfigurationSection/Fields/DealTransactionCurrencyField/useDealTransactionCurrencyField";

export const DealTransactionCurrencyField: FC = () => {
    const { value, options, isFetching, handleSelect } = useDealTransactionCurrencyField();
    return (
        <Form.Item
            data-sdet="deal-transaction-currency-field"
            label="Transaction Currency"
            name={CREATE_DEAL_FORM_ITEMS_NAME.TRANSACTION_CURRENCY}
        >
            <Select
                showSearch
                optionFilterProp="label"
                onSelect={handleSelect}
                loading={isFetching}
                value={value}
                options={options}
            />
        </Form.Item>
    );
};
