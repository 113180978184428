// From console source src/main/webapp/js/vm/home/supplyhome.vm.js
// kind 1 = MetricOneFilter kind 2 = MetricTwoFilter kind 0 = Both
// If a 0 filter is selected in one metric filter it will not appear in the other
// TODO: Omitted "REGULAR_GRAPH_OPTIONS" due to being a duplicate of overall graph options
const METRIC_ONE = 1;
const METRIC_TWO = 2;
const BOTH = 0;

export const OVERALL_GRAPH_OPTIONS = [
    {
        id: "netRevenue",
        name: "Revenue",
        kind: BOTH,
    },
    {
        id: "requests",
        name: "Requests",
        kind: METRIC_ONE,
    },
    {
        id: "fills",
        name: "Fills",
        kind: METRIC_ONE,
    },
    {
        id: "impressions",
        name: "Impressions",
        kind: METRIC_ONE,
    },
    {
        id: "netRevenueCpm",
        name: "eCPM",
        kind: METRIC_TWO,
    },
    {
        id: "acceptedRequestRate",
        name: "Accept Rate",
        kind: METRIC_TWO,
    },
    {
        id: "fillRate",
        name: "Fill Rate",
        kind: METRIC_TWO,
    },
    {
        id: "useRate",
        name: "Use Rate",
        kind: METRIC_TWO,
    },
];

export const POD_AND_PLAYLIST_GRAPH_OPTIONS = [
    {
        id: "podAndPlaylistRevenue",
        name: "Revenue",
        kind: BOTH,
    },
    {
        id: "podsAndPlaylistRequests",
        name: "Requests",
        kind: METRIC_ONE,
    },
    {
        id: "podsAndPlaylistFills",
        name: "Fills",
        kind: METRIC_ONE,
    },
    {
        id: "podsAndPlaylistImpressions",
        name: "Impressions",
        kind: METRIC_ONE,
    },
    {
        id: "PodAndPlaylistRevenueCpm",
        name: "eCPM",
        kind: METRIC_TWO,
    },
    {
        id: "podsAndPlaylistFillRate",
        name: "Fill Rate",
        kind: METRIC_TWO,
    },
    {
        id: "podsAndPlaylistUseRate",
        name: "Use Rate",
        kind: METRIC_TWO,
    },
];

export const STANDARD_GRAPH_OPTIONS = [
    {
        id: "standardNetRevenue",
        name: "Revenue",
        kind: BOTH,
    },
    {
        id: "standardRequests",
        name: "Requests",
        kind: METRIC_ONE,
    },
    {
        id: "standardFills",
        name: "Fills",
        kind: METRIC_ONE,
    },
    {
        id: "standardImpressions",
        name: "Impressions",
        kind: METRIC_ONE,
    },
    {
        id: "netRevenueCpm",
        name: "eCPM",
        kind: METRIC_TWO,
    },
    {
        id: "standardAcceptedRate",
        name: "Accept Rate",
        kind: METRIC_TWO,
    },
    {
        id: "standardFillRate",
        name: "Fill Rate",
        kind: METRIC_TWO,
    },
    {
        id: "standardUseRate",
        name: "Use Rate",
        kind: METRIC_TWO,
    },
];

export const VIEW_GRAPH_OPTIONS = {
    1: OVERALL_GRAPH_OPTIONS,
    2: POD_AND_PLAYLIST_GRAPH_OPTIONS,
    3: STANDARD_GRAPH_OPTIONS,
};

export const METRIC_ONE_DEFAULTS_BY_VIEW_ID = {
    1: {
        value: OVERALL_GRAPH_OPTIONS[0].id,
        label: OVERALL_GRAPH_OPTIONS[0].name,
    },
    2: {
        value: POD_AND_PLAYLIST_GRAPH_OPTIONS[0].id,
        label: POD_AND_PLAYLIST_GRAPH_OPTIONS[0].name,
    },
    3: {
        value: STANDARD_GRAPH_OPTIONS[0].id,
        label: STANDARD_GRAPH_OPTIONS[0].name,
    },
};

export const METRIC_TWO_DEFAULTS_BY_VIEW_ID = {
    1: {
        value: OVERALL_GRAPH_OPTIONS[6].id,
        label: OVERALL_GRAPH_OPTIONS[6].name,
    },
    2: {
        value: POD_AND_PLAYLIST_GRAPH_OPTIONS[4].id,
        label: POD_AND_PLAYLIST_GRAPH_OPTIONS[4].name,
    },
    3: {
        value: STANDARD_GRAPH_OPTIONS[4].id,
        label: STANDARD_GRAPH_OPTIONS[4].name,
    },
};

export const GRAPH_VALUE_TYPES = {
    MONEY: "MONEY",
    VALUE: "VALUE",
    RATE: "RATE",
};

export const GRAPH_CONFIG_TYPES = {
    NET_REVENUE: {
        type: GRAPH_VALUE_TYPES.MONEY,
        title: "Net Rev",
    },
    GROSS_REVENUE: {
        type: GRAPH_VALUE_TYPES.MONEY,
        title: "Gross Rev",
    },
    REQUESTS: {
        type: GRAPH_VALUE_TYPES.VALUE,
        title: "Requests",
    },
    FILLS: {
        type: GRAPH_VALUE_TYPES.VALUE,
        title: "Fills",
    },
    IMPRESSIONS: {
        type: GRAPH_VALUE_TYPES.VALUE,
        title: "Impressions",
    },
    NET_ECPM: {
        type: GRAPH_VALUE_TYPES.MONEY,
        title: "Net eCPM",
    },
    GROSS_ECPM: {
        type: GRAPH_VALUE_TYPES.MONEY,
        title: "Gross eCPM",
    },
    ACCEPT_RATE: {
        type: GRAPH_VALUE_TYPES.RATE,
        title: "Accept Rate",
    },
    FILL_RATE: {
        type: GRAPH_VALUE_TYPES.RATE,
        title: "Fill Rate",
    },
    USE_RATE: {
        type: GRAPH_VALUE_TYPES.RATE,
        title: "Use Rate",
    },
};

export const GRAPH_CONFIG = {
    [OVERALL_GRAPH_OPTIONS[0].id]: GRAPH_CONFIG_TYPES.NET_REVENUE,
    [OVERALL_GRAPH_OPTIONS[1].id]: GRAPH_CONFIG_TYPES.REQUESTS,
    [OVERALL_GRAPH_OPTIONS[2].id]: GRAPH_CONFIG_TYPES.FILLS,
    [OVERALL_GRAPH_OPTIONS[3].id]: GRAPH_CONFIG_TYPES.IMPRESSIONS,
    [OVERALL_GRAPH_OPTIONS[4].id]: GRAPH_CONFIG_TYPES.NET_ECPM,
    [OVERALL_GRAPH_OPTIONS[5].id]: GRAPH_CONFIG_TYPES.ACCEPT_RATE,
    [OVERALL_GRAPH_OPTIONS[6].id]: GRAPH_CONFIG_TYPES.FILL_RATE,
    [OVERALL_GRAPH_OPTIONS[7].id]: GRAPH_CONFIG_TYPES.USE_RATE,
    [POD_AND_PLAYLIST_GRAPH_OPTIONS[0].id]: GRAPH_CONFIG_TYPES.NET_REVENUE,
    [POD_AND_PLAYLIST_GRAPH_OPTIONS[1].id]: GRAPH_CONFIG_TYPES.REQUESTS,
    [POD_AND_PLAYLIST_GRAPH_OPTIONS[2].id]: GRAPH_CONFIG_TYPES.FILLS,
    [POD_AND_PLAYLIST_GRAPH_OPTIONS[3].id]: GRAPH_CONFIG_TYPES.IMPRESSIONS,
    [POD_AND_PLAYLIST_GRAPH_OPTIONS[4].id]: GRAPH_CONFIG_TYPES.NET_ECPM,
    [POD_AND_PLAYLIST_GRAPH_OPTIONS[5].id]: GRAPH_CONFIG_TYPES.FILL_RATE,
    [POD_AND_PLAYLIST_GRAPH_OPTIONS[6].id]: GRAPH_CONFIG_TYPES.USE_RATE,
    [STANDARD_GRAPH_OPTIONS[0].id]: GRAPH_CONFIG_TYPES.NET_REVENUE,
    [STANDARD_GRAPH_OPTIONS[1].id]: GRAPH_CONFIG_TYPES.REQUESTS,
    [STANDARD_GRAPH_OPTIONS[2].id]: GRAPH_CONFIG_TYPES.FILLS,
    [STANDARD_GRAPH_OPTIONS[3].id]: GRAPH_CONFIG_TYPES.IMPRESSIONS,
    [STANDARD_GRAPH_OPTIONS[4].id]: GRAPH_CONFIG_TYPES.NET_ECPM,
    [STANDARD_GRAPH_OPTIONS[5].id]: GRAPH_CONFIG_TYPES.ACCEPT_RATE,
    [STANDARD_GRAPH_OPTIONS[6].id]: GRAPH_CONFIG_TYPES.FILL_RATE,
    [STANDARD_GRAPH_OPTIONS[7].id]: GRAPH_CONFIG_TYPES.USE_RATE,
};
