import { FC } from "react";
import { useSeatAdSourcesTypeFilter } from "./useSeatAdSourcesTypeFilter";
import { Form, Select } from "antd";
import { TagRender } from "@app/core/components/TagRender";
import { HelpTooltip } from "@app/core/components";
import { AdSourcesHealthHelpKeys } from "@app/features/seatAdSources/constants";

export const SeatAdSourcesTypeFilter: FC = () => {
    const { handleChangeValue, programmaticOptions, tagBasedOptions, value, isLoading } = useSeatAdSourcesTypeFilter();
    const { Option, OptGroup } = Select;
    return (
        <Form.Item
            data-sdet="seat-ad-sources-type-filter"
            label={
                <HelpTooltip
                    helpKey={AdSourcesHealthHelpKeys.Type}
                    helpKeyList={AdSourcesHealthHelpKeys}
                    popover={true}
                >
                    Type
                </HelpTooltip>
            }
            style={{ marginBottom: 0 }}
        >
            <Select
                showSearch
                mode="multiple"
                value={value}
                loading={isLoading}
                tagRender={TagRender}
                placeholder="Select…"
                style={{ width: "100%" }}
                optionFilterProp="children"
                allowClear={Boolean(value)}
                onChange={handleChangeValue}
            >
                {programmaticOptions.length > 0 && (
                    <OptGroup label="Programmatic (Full Featured)">
                        {programmaticOptions.map((option) => {
                            return (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            );
                        })}
                    </OptGroup>
                )}
                {tagBasedOptions.length > 0 && (
                    <OptGroup label="Tag-based">
                        {tagBasedOptions.map((option) => {
                            return (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            );
                        })}
                    </OptGroup>
                )}
            </Select>
        </Form.Item>
    );
};
