import { FC, useMemo, useState } from "react";
import { Button, Col, Drawer, Form, Radio, Row, Table, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { InfoCircleOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { DayPartTarget, DayPartingTableColumns } from "@app/core/services/console";
import { DAY_PARTING_COLUMN_HEADERS, TIME_ZONE_MODE_OPTIONS, TimeZoneModeIds } from "@app/features/targeting/constants";
import { dayPartingTargetsToLabelValue, getDayPartingTableDataTemplate } from "@app/features/targeting/helpers";
import { useDayPartingDrawer } from "./useDayPartingDrawer";
import { brandCobalt, gray5 } from "@rubicon/antd-components";

interface Props {
    onChange: (values: DayPartTarget[]) => void;
    onChangeTimeZoneMode: (value: TimeZoneModeIds) => void;
    values: DayPartTarget[];
    timeZoneMode: TimeZoneModeIds;
    title: string;
}

export const DayPartingDrawerButton: FC<Props> = ({ onChange, onChangeTimeZoneMode, values, timeZoneMode, title }) => {
    const [labelValue, setLabelValue] = useState(dayPartingTargetsToLabelValue(values, timeZoneMode));
    const onChangeWithTimeZoneModeAndDayPartingTargetToLabelValue = (
        newValues: DayPartTarget[],
        newTimeZoneMode: TimeZoneModeIds
    ) => {
        onChange(newValues.sort((a, b) => a.dayOfWeek - b.dayOfWeek || a.halfHourOfDay - b.halfHourOfDay));
        onChangeTimeZoneMode(newTimeZoneMode);
        setLabelValue(dayPartingTargetsToLabelValue(newValues, newTimeZoneMode));
    };
    const {
        handleApply,
        handleClick,
        handleClose,
        handleHeaderClick,
        handleMouseDown,
        handleMouseOver,
        handleMouseUp,
        handleOpen,
        handleRadioChange,
        isOpen,
        mouseDragCoords,
        tableCellIsSelected,
        updatedTimeZoneMode,
    } = useDayPartingDrawer(values, timeZoneMode, onChangeWithTimeZoneModeAndDayPartingTargetToLabelValue);
    const tableDataTemplate = useMemo(() => getDayPartingTableDataTemplate(), []);
    const tableData = tableDataTemplate.map((rowDataObj, halfHourOfDay) => {
        const currentRowDataObj = { ...rowDataObj };
        Array.from({ length: DAY_PARTING_COLUMN_HEADERS.length - 1 }).map(
            (_, i) => (currentRowDataObj[i + 1] = tableCellIsSelected(i + 1, halfHourOfDay))
        );
        return currentRowDataObj;
    });
    const columnsTemplate = useMemo(
        () =>
            DAY_PARTING_COLUMN_HEADERS.reduce((obj, columnName: string, columnIndex: number) => {
                obj[columnName] = {
                    title: (
                        <div
                            key={`${columnName}-${columnIndex}`}
                            data-column-index={columnIndex}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            onClick={columnIndex ? handleHeaderClick : null}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            onContextMenu={columnIndex ? handleHeaderClick : null}
                            style={{
                                height: "100%",
                                width: "100%",
                                padding: "3px 8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                userSelect: "none",
                            }}
                        >
                            <span style={{ pointerEvents: "none" }}>{columnName}</span>
                        </div>
                    ),
                    dataIndex: columnIndex,
                    key: columnName,
                    width: "12.5%",
                    render: (_, record) => ({
                        key: `${columnIndex}-${record.halfHourOfDay}`,
                        props: {
                            "data-day-of-week": columnIndex,
                            "data-half-hour-of-day": record.halfHourOfDay,
                            onClick: columnIndex ? handleClick : null,
                            onContextMenu: columnIndex ? handleClick : null,
                            onMouseDown: columnIndex ? handleMouseDown : null,
                            onMouseOver: columnIndex ? handleMouseOver : null,
                            onMouseUp: columnIndex ? handleMouseUp : null,
                        },
                        children: columnIndex ? null : (
                            <span
                                key={record["Time/Day"]}
                                style={{
                                    userSelect: "none",
                                    fontWeight: "bold",
                                }}
                            >
                                {record["Time/Day"]}
                            </span>
                        ),
                    }),
                };
                return obj;
            }, {}),
        [handleHeaderClick, handleClick, handleMouseDown, handleMouseOver, handleMouseUp]
    );
    const columns: ColumnsType<DayPartingTableColumns> = DAY_PARTING_COLUMN_HEADERS.map(
        (columnName: string, columnIndex: number) => {
            const columnTemplate = columnsTemplate[columnName];
            return {
                ...columnTemplate,
                render: (selected: boolean, record) => {
                    const { halfHourOfDay } = record;
                    const highlighted =
                        columnIndex &&
                        ((mouseDragCoords.length === 2 &&
                            [columnIndex, halfHourOfDay].toString() === mouseDragCoords.toString()) ||
                            (mouseDragCoords.length === 4 &&
                                columnIndex ===
                                    [mouseDragCoords[0], mouseDragCoords[2], columnIndex].sort((a, b) => a - b)[1] &&
                                halfHourOfDay ===
                                    [mouseDragCoords[1], mouseDragCoords[3], halfHourOfDay].sort((a, b) => a - b)[1]));
                    const renderTemplateObj = columnTemplate.render(selected, record);
                    renderTemplateObj.props.style = {
                        backgroundColor: highlighted ? "#DAE0EF" : selected ? "#E6EDFF" : "white",
                    };
                    return renderTemplateObj;
                },
            };
        }
    );
    return (
        <>
            <Drawer
                className={css`
                    .ant-drawer-header-title {
                        flex-direction: row-reverse;
                    }
                `}
                size="large"
                title={title}
                open={isOpen}
                onClose={handleClose}
                footer={
                    <Row justify="end" gutter={8}>
                        <Col>
                            <Button onClick={handleClose} onMouseEnter={handleMouseUp}>
                                Cancel
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" onClick={handleApply} onMouseEnter={handleMouseUp}>
                                Apply
                            </Button>
                        </Col>
                    </Row>
                }
            >
                <Form.Item label="Time Zone Mode" labelCol={{ span: 24 }} style={{ marginBottom: 7, marginTop: -15 }}>
                    <Radio.Group
                        value={updatedTimeZoneMode}
                        onChange={handleRadioChange}
                        options={TIME_ZONE_MODE_OPTIONS}
                        style={{
                            marginTop: -10,
                            position: "absolute",
                            width: 237,
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    />
                    <Tooltip
                        placement="bottomRight"
                        title="If choosing Client Time Zone for Daypart Targeting, it'll look at the user's time zone based on geolocation data in the bid request. Essentially the time will stay the same, but the time zone will depend on the customer (Ex: 5:00-8:00 PM will be for EST, PST, UTC, etc)."
                    >
                        <span>
                            <InfoCircleOutlined
                                style={{
                                    marginLeft: 230,
                                    position: "relative",
                                    top: -10,
                                    color: brandCobalt,
                                }}
                            />
                        </span>
                    </Tooltip>
                </Form.Item>
                <div style={{ marginBottom: 10 }}>
                    Click on a cell to select, right click to deselect. Click and drag to select multiple hours. You can
                    also click on the day header to select the whole column.
                </div>
                <Table<DayPartingTableColumns>
                    size="small"
                    dataSource={tableData}
                    columns={columns}
                    pagination={false}
                    className={css`
                        .ant-table.ant-table-bordered > .ant-table-container {
                            transition-duration: 0.25s;
                        }
                        .ant-table.ant-table-bordered > .ant-table-container,
                        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
                        .ant-table.ant-table-bordered
                            > .ant-table-container
                            > .ant-table-content
                            > table
                            > thead
                            > tr
                            > th,
                        .ant-table.ant-table-bordered
                            > .ant-table-container
                            > .ant-table-content
                            > table
                            > tbody
                            > tr
                            > td {
                            border-color: ${gray5};
                        }
                        .ant-table.ant-table-small .ant-table-thead > tr > th {
                            padding: 0;
                        }
                        .ant-table.ant-table-small .ant-table-tbody > tr > td,
                        .ant-table-body .ant-table-tbody .ant-table-row .ant-table-cell {
                            padding: 3px 8px;
                        }
                    `}
                    bordered
                />
            </Drawer>
            <Button type="link" onClick={handleOpen} style={{ padding: "4px 0px" }}>
                {labelValue}
            </Button>
        </>
    );
};
