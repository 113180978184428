import { FC } from "react";
import { FilterSelect } from "@app/core/components/FilterSelect";
import { useSeatAdSourcesPacingDspFilter } from "./useSeatAdSourcesPacingDspFilter";
import { HelpTooltip, SelectAllDropdownRender } from "@app/core/components";
import { AdSourcesPacingHelpKeys } from "@app/features/seatAdSources/constants";

export const SeatAdSourcesPacingDspFilter: FC = () => {
    const { handleChangeValue, options, value, isLoading, areAllOptionsSelected, toggleSelectDeselectAll } =
        useSeatAdSourcesPacingDspFilter();

    return (
        <FilterSelect
            dataSdet="seat-ad-sources-pacing-dsp-filter"
            dropdownRender={(menu) => (
                <SelectAllDropdownRender
                    menu={menu}
                    onClick={toggleSelectDeselectAll}
                    areAllOptionsSelected={areAllOptionsSelected}
                />
            )}
            isLoading={isLoading}
            label={
                <HelpTooltip helpKeyList={AdSourcesPacingHelpKeys} helpKey={AdSourcesPacingHelpKeys.DSP} popover={true}>
                    DSP
                </HelpTooltip>
            }
            mode="multiple"
            onChange={handleChangeValue}
            options={options}
            value={value}
        />
    );
};
