import { FC } from "react";
import { Form, Input } from "antd";
import { PUBLISHER_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { usePublisherFormInstance } from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormInstance";

export const BusinessDomain: FC = () => {
    const { seat } = usePublisherFormInstance();
    const extra: string | undefined = seat?.businessDomain ? `Inherited value: ${seat.businessDomain}` : undefined;
    return (
        <Form.Item
            extra={extra}
            name={PUBLISHER_FORM_FIELDS.BUSINESS_DOMAIN.name}
            label={PUBLISHER_FORM_FIELDS.BUSINESS_DOMAIN.label}
            wrapperCol={{ xs: 24, sm: 24, md: 10, lg: 9, xl: 12, xxl: 11 }}
        >
            <Input allowClear placeholder={PUBLISHER_FORM_FIELDS.BUSINESS_DOMAIN.placeholder} />
        </Form.Item>
    );
};
