import { FC } from "react";
import { AdUnitMaxAdsPerPod } from "@app/features/inventory/components/FormItems";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const MaxAdsPerPod: FC = () => (
    <AdUnitMaxAdsPerPod
        name={CHANNEL_FORM_FIELDS.MAX_ADS_PER_POD.name}
        label={CHANNEL_FORM_FIELDS.MAX_ADS_PER_POD.label}
        placeholder={CHANNEL_FORM_FIELDS.MAX_ADS_PER_POD.placeholder}
    />
);
