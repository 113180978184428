export const REPORTS_ROUTES = {
    CUSTOM_REPORTS: "custom",
    FORECASTING: "forecasting",
    ADVERTISER_REPORT: "advertiser-report",
    HIERARCHICAL: "hierarchical",
    AUCTIONS: "auctions",
    DEMAND: "pub-demand",
    SUPPLY: "pub-supply",
    FLOORS: "pub-floors",
    DEALS_OVERVIEW: 'deals-overview'
} as const;

type ReportRouteKey = keyof typeof REPORTS_ROUTES;

export type ReportRoute = typeof REPORTS_ROUTES[ReportRouteKey];

export const IFRAMED_APP_ID = "streaming-reporting";
