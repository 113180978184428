import { DEMAND_TYPE_OPTIONS } from "./constants";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { setFiltersDemandType } from "@app/features/dashboard/reducer";
import { LabeledValue } from "antd/lib/select";

interface UsePublisherFilter {
    handleChangeValue: (value: LabeledValue[], option: LabeledValue[]) => void;
    options: LabeledValue[];
    value: LabeledValue[];
    areAllOptionsSelected: boolean;
    toggleSelectDeselectAll: () => void;
}

const OPTIONS_LABEL_VALUE = DEMAND_TYPE_OPTIONS.map<LabeledValue>((option) => ({
    value: option.id,
    label: option.name,
}));

export const useDemandTypeFilter = (): UsePublisherFilter => {
    const dispatch = useAppDispatch();
    const demandTypeFilter = useAppSelector((state) => state.dashboard.filters.demandType);

    const handleChangeValue = (id: LabeledValue[], option: LabeledValue | LabeledValue[]) => {
        if (!id) {
            dispatch(setFiltersDemandType([]));
            return;
        }
        // TS wants us to handle the select potentially being multiselect
        if (Array.isArray(option)) {
            dispatch(setFiltersDemandType(option));
            return;
        }
        dispatch(setFiltersDemandType([option]));
    };

    const areAllOptionsSelected = OPTIONS_LABEL_VALUE.length === demandTypeFilter.length ? true : false;

    const toggleSelectDeselectAll = () => {
        if (areAllOptionsSelected) {
            handleChangeValue([], []);
        } else {
            handleChangeValue(OPTIONS_LABEL_VALUE, OPTIONS_LABEL_VALUE);
        }
    };

    return {
        options: OPTIONS_LABEL_VALUE,
        value: demandTypeFilter,
        handleChangeValue,
        areAllOptionsSelected,
        toggleSelectDeselectAll,
    };
};
