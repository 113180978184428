import { DrawerBreadcrumbHeader } from "@app/core/components";
import { uri } from "@rubicon/utils";
import { FC } from "react";
import { useHistory, useLocation } from "react-router-dom";

interface Props {
    parentLabel?: string;
    childLabel: string;
}

export const HelperKeysDrawerBreadcrumbHeader: FC<Props> = ({ parentLabel, childLabel }) => {
    const history = useHistory();
    const { search: queryString } = useLocation();
    const onBack = () => {
        const nextQueryString = uri.setSearchParam(queryString, "drawer", "details");
        history.push({ search: nextQueryString });
    };

    return parentLabel ? (
        <DrawerBreadcrumbHeader onBack={onBack} parentLabel={parentLabel} childLabel={childLabel} />
    ) : null;
};
