import { FC } from "react";
import { Form, Input, Space, Typography } from "antd";
import { gray7 } from "@rubicon/antd-components";

interface Props {
    name: string;
    label: string;
}

export const BlockedAdvertiserDomainsFormItem: FC<Props> = ({ name, label }) => {
    return (
        <Form.Item
            style={{ maxWidth: "615px" }}
            name={name}
            label={
                <Space>
                    {label}
                    <Typography.Text style={{ fontWeight: 400, color: gray7 }}>(Add one per line)</Typography.Text>
                </Space>
            }
        >
            <Input.TextArea autoComplete="off" allowClear rows={10} />
        </Form.Item>
    );
};
