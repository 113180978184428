import { FC } from "react";
import {
    AdvertisersAsWhitelist,
    BusinessDomain,
    NoAdCookiesSync,
    PublisherReAuction,
} from "@app/features/inventory/HierarchyForms/PublisherChannelForm/PublisherChannelFormSections/AdvancedFeaturesSection/Sections/DetailedConfigurationSection/Fields";
import { InternalOnly } from "@app/features/inventory/components/InternalOnly";
import { useInternalOnlyFields } from "./useInternalOnlyFields";

export const InternalOnlyFields: FC = () => {
    const { isPublisherReAuctionShown } = useInternalOnlyFields();
    return (
        <InternalOnly>
            <BusinessDomain />
            <AdvertisersAsWhitelist />
            <NoAdCookiesSync />
            {isPublisherReAuctionShown && <PublisherReAuction />}
        </InternalOnly>
    );
};
