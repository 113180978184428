import { ChartColors } from "@app/core/components/constants";
import { ColorMap } from "@app/features/seatAdSources/seatAdSourcesCharts/charts";

type SeriesData = { [key: string]: string };

export const PerformanceColors: ColorMap = {
    Tries: ChartColors.Blue,
    Fills: ChartColors.LightGreen,
    Impressions: ChartColors.Grey,
    "Net Revenue": ChartColors.Yellow,
    "Fall Backs": ChartColors.Violet,
    Errors: ChartColors.LightBlue,
    Skips: ChartColors.Viola,
};

export const performanceColorMapper = (seriesData: SeriesData) => {
    const series = Object.values(seriesData)[0];
    return PerformanceColors[series];
};

export const AdServingColors: ColorMap = {
    "101": ChartColors.Red,
    "102": ChartColors.LightBlue,
    "103": ChartColors.Blue,
    "301": ChartColors.Viola,
    "302": ChartColors.Aqua,
    "200 - 1": ChartColors.Grey,
    "200 - 2": ChartColors.Pink,
    "200 - 3": ChartColors.LightGreen,
    "201 - 1": ChartColors.Grey,
    "201 - 2": ChartColors.Pink,
    "201 - 3": ChartColors.LightGreen,
    "202 - 1": ChartColors.Grey,
    "202 - 2": ChartColors.Pink,
    "202 - 3": ChartColors.LightGreen,
    "301 - 1": ChartColors.Grey,
    "301 - 2": ChartColors.Pink,
    "301 - 3": ChartColors.LightGreen,
    "302 - 1": ChartColors.Grey,
    "302 - 2": ChartColors.Pink,
    "302 - 3": ChartColors.LightGreen,
};

export const adServingColorMapper = (seriesData: SeriesData) => {
    const series = Object.values(seriesData)[0];
    return AdServingColors[series];
};

export const VastErrorsColors: ColorMap = {
    "100": ChartColors.Aqua,
    "101": ChartColors.LightGreen,
    "102": ChartColors.Blue,
    "200": ChartColors.Aqua,
    "201": ChartColors.LightGreen,
    "202": ChartColors.Blue,
    "203": ChartColors.LightBlue,
    "204": ChartColors.Pink,
    "205": ChartColors.Violet,
    "206": ChartColors.Red,
    "300": ChartColors.Aqua,
    "301": ChartColors.LightGreen,
    "302": ChartColors.Blue,
    "303": ChartColors.LightBlue,
    "304": ChartColors.Pink,
    "400": ChartColors.Aqua,
    "401": ChartColors.LightGreen,
    "402": ChartColors.Blue,
    "403": ChartColors.LightBlue,
    "404": ChartColors.Pink,
    "405": ChartColors.Violet,
    "406": ChartColors.Red,
    "407": ChartColors.Viola,
    "408": ChartColors.Yellow,
    "409": ChartColors.DarkGreen,
    "410": ChartColors.Orange,
    "411": ChartColors.Purple,
    "500": ChartColors.Aqua,
    "501": ChartColors.LightGreen,
    "502": ChartColors.Blue,
    "503": ChartColors.LightBlue,
    "600": ChartColors.Aqua,
    "601": ChartColors.LightGreen,
    "602": ChartColors.Blue,
    "603": ChartColors.LightBlue,
    "604": ChartColors.Pink,
    "900": ChartColors.Aqua,
    "901": ChartColors.LightGreen,
};

export const vastErrorsColorMapper = (seriesData: SeriesData) => {
    const series = Object.values(seriesData)[0];
    return VastErrorsColors[series];
};

export const TimingStatsColors: ColorMap = {
    "# Events": ChartColors.Blue,
    "Cumulative # Events": ChartColors.LightGreen,
};

export const timingStatsColorMapper = (seriesData: SeriesData) => {
    const series = Object.values(seriesData)[0];
    return TimingStatsColors[series];
};
