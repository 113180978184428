import { FC } from "react";
import { Form } from "antd";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { getTimeZoneLabel, TimeZone } from "@app/core/services";
import { useTimeZoneSelect } from "./useTimeZoneSelect";

export const TimeZoneSelect: FC = () => {
    const { timezones, isTimezonesLoading, isReadOnly, isApiReadOnly, onChange } = useTimeZoneSelect();

    return (
        <Form.Item
            name={AD_SOURCE_FIELDS.TIME_ZONE.name}
            label={AD_SOURCE_FIELDS.TIME_ZONE.label}
            data-sdet={`test-${AD_SOURCE_FIELDS.TIME_ZONE.name}-field`}
            help={isReadOnly ? "Inherited from Demand Deal" : undefined}
            {...VERTICAL_LAYOUT_FIX}
        >
            <SelectWithValue<TimeZone>
                disabled={isReadOnly || isApiReadOnly}
                placeholder={`Select ${AD_SOURCE_FIELDS.TIME_ZONE.label}`}
                data-sdet="adsource-time-zone"
                showSearch
                optionFilterProp="label"
                optionLabelProp="label"
                options={timezones}
                fieldAsValue={["id"]}
                fieldAsLabel={getTimeZoneLabel}
                loading={isTimezonesLoading}
                onChange={onChange}
            />
        </Form.Item>
    );
};
