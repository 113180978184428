import moment from "moment-timezone";
import {
    consoleApi,
    AD_SOURCE_AUCTION_TYPES_TAG,
    AD_SOURCE_FLOOR_TYPES_TAG,
    AD_SOURCE_GRANTS_TAG,
    AD_SOURCE_PACING_PERIODS_TAG,
    AD_SOURCE_PACING_TYPES_TAG,
    AD_SOURCE_PRICE_MODELS_TAG,
    AD_SOURCE_PRIORITIES_TAG,
    AD_SOURCE_TAG,
    AD_SOURCE_TYPES_COUNTS,
    AD_SOURCE_CORE_INDEX_TIME,
    AD_SOURCE_TARGETING_INDEX_TIME,
    AD_SOURCES_ADVERTISER_BLOCKING_MODES_TAG,
    ENFORCEMENTS_TAG,
    FREQ_CAPPING_TYPES_TAG,
    SEAT_AD_SOURCES_PACING_TAG,
    SEAT_AD_SOURCES_PICKLIST_TAG,
    SEAT_AD_SOURCES_STATUSES_TAG,
    SEAT_AD_SOURCES_TAG,
    SEAT_AD_SOURCES_TYPE_TAG,
    DEAL_TAG,
    SEAT_ALL_DEALS_TAG,
    AD_SOURCE_IAB_CATEGORY_BLOCK_MODES_TAG,
    AD_SOURCE_PACING_TAG,
    AD_RESPONSE_PRICE_OVERRIDES_TAG,
    AD_RESPONSE_PRICE_OVERRIDES_TAG_BY_ID,
    DEAL_AD_SOURCE_TAG,
} from "./console";
import { toQueryString } from "./utils";
import { Permissions } from "./types";
import { DealAdSourceTransparencyExtendedUserId, FreqCapping } from "@app/features/deals/DealForm/types";
import { Label, LabelValue, IabCategory } from "@app/core/services";
import {
    CuratorMarketplace,
    Currency,
    PacingProgress,
    SeatContentTransparencyRule,
    Targeting,
} from "@app/core/services/console";
import { transformTargetingArrayFromApi } from "./targeting";
import { AdSourceTypeIds, PricingModels } from "@app/features/seatAdSources/constants";

export interface AdSourceProperties {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
}

export type SynthesizedAdSourceStatus = "Running" | "Expired" | "Future" | "Paused" | "Unknown";

export interface AdSourceBase {
    id: number;
    name: string;
    status: AdSourceProperties;
    priority: AdSourceProperties;
    type: AdSourceAbbreviation;
    currencyType: Currency;
    bookingVolume: number;
    bookingStartDate: string;
    bookingEndDate: string | null;
    costModel: AdSourceProperties;
    pacingType: AdSourceProperties;
    adsourcePacingData: AdSourcePacingData | null;
    adSourceFloorType?: AdSourceFloorType;
    adSourcePriceModel?: AdSourcePriceModel;
    creationDate: string;
    updateDate: string;
    seat: {
        id: number;
        name: string;
        code: string;
        entityType: string;
    };
    timeZone: {
        creationDate: string;
        updateDate: string;
        id: number;
        code: string;
        name: string;
        dayLightTime: boolean;
        dayLightSavingsOffset: number;
        utcOffset: number;
    } | null;
    region: Region | null;
    salesContact: {
        emailAddress: string;
        id: number;
        name: string;
    } | null;
    costValuePercent: number;
    costValueFixed: number;
    bookingPrice: number;
    adSourceMaxDuration: null | number;
    adSourceMinDuration: null | number;
    allowAutoscale: boolean;
    alwaysSendFloor: boolean;
    allowSiteName: boolean;
    allowDomainName: boolean;
    allowSitePage: boolean;
    allowRef: boolean;
    allowIp: boolean;
    allowAllExtendedId: boolean;
    allowBundleId: boolean;
    overrideDynamicFloor: boolean;
    allowUserId: boolean;
    maskUserId: boolean;
    contentTransparencyRule: SeatContentTransparencyRule | null;
    allowContent: boolean;
    auctionType: AdSourceProperties;
    labelValues: LabelValue[];
}

export interface AdSourcesListResponse {
    page: number;
    maxResults: number;
    totalResults: number;
    adSources: AdSourceListItem[];
}

export interface AdSourceListItem extends AdSourceBase {
    adSourceCpm: number;
    dealCount: number;
    synthesizedAdSourceStatus: SynthesizedAdSourceStatus;
}

export type TargetingMode = { id: 1; name: "MERGE" } | { id: 2; name: "OR" };

export type AdSourceKey = keyof AdSource;

export interface AdSource extends AdSourceBase {
    synthesizedAdSourceStatus: SynthesizedAdSourceStatus;
    assignee: {
        id: number;
        emailAddress: string;
        name: string;
    };
    allowStoreUrl: boolean;
    vastDynamicPricingLocation: BaseOption;
    vastDynamicPricingMultiplier: null;
    demandFee: number | null;
    demandAcquisitionCost: number | null;
    demandAcquisitionCostModel: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string;
        entityType: string;
    };
    operatorCost: null;
    targeting: Targeting[];
    seatAdvertiserDomainFilterListDefs: BaseOption[];
    inventoryDistributionGroups: InventoryDistributionGroup[];
    marketplace: null;
    associatedMarketplace: Partial<BaseOption>;
    udePriority: AdSourceProperties;
    metadata: null;
    excludedNetworks: BaseOption[];
    marketplaceInfoList: MarketplaceInfo[];
    priceModel: AdSourcePriceModel;
    resetFrequency: null; // deprecated
    dealPrices: null; // deprecated
    tierResetMode: null; // deprecated
    tierOverflowAllowed: null; // deprecated
    redistributePacing: boolean | null;
    pacingPeriod: AdSourceProperties | null;
    pacingLastUpdatedDate: string | null;
    pacingLastUpdatedImp: string | null;
    customCurveStartDate: string | null;
    customCurveImps: number | null;
    dailyCapVolume: number | null;
    dailyRequestOpportunity: number;
    dailyImpressionOpportunity: number;
    dailyCompletionOpportunity: number;
    dailyFillOpportunity: number;
    dailyRequestOpportunityFallback: number;
    adSourceFloorType: AdSourceFloorType;
    externalContractId: string | null;
    tagUrl: string;
    defaultAdvertiserDomain: null;
    allowDealsUnderFloor: boolean;
    thirdPartyPixels: ThirdPartyPixels[];
    pbsConfigs: BidderConfig[];
    advertiserBlockingMode: AdSourceProperties;
    advertiserBlockDomains: string[];
    nurlTimeoutOverride: boolean;
    shareOfVoicePercent: number;
    targetingMode: TargetingMode;
    allowedExtendedIdTransparencies: DealAdSourceTransparencyExtendedUserId[];
    strictMode: boolean;
    bundleIdOverride: string | null;
    domainNameOverride: string | null;
    allowOztam: boolean;
    allowOztamDemographic: number;
    entityType: "AdSource";
    description: string;
    allowAudienceLock: boolean;
    iabCategoryBlockMode: IabCategoryBlockMode;
    blockedIabCategories: IabCategory[];
    blockExceptionIabCategories: IabCategory[];
    adResponsePriceOverrideType?: AdResponsePriceOverride | null;
    curatorMarketplaceIds?: number[];
    curatorMarketplaces?: CuratorMarketplace[];
    readOnlyFields?: AdSourceKey[] | null;
}

export interface AdSourcePacingData {
    adSourceId: number;
    customCurveImps: number;
    customCurveStartDate: string;
    dailyCapValue: number;
    dailyImpsRemaining: number;
    pacingProgress: PacingProgress;
    daysElapsed: number;
    daysRemaining: number;
    deliveryPercentage: number;
    impsPerDayToFulfill: number;
    impsRemaining: number;
    monthImpCount: number;
    pacingDelta: string;
    pacingStatusPercentage: null;
    recordedTotalImpCount: number;
    reqOpportunity: number;
    todayImpCount: number;
    todaysGoal: null;
    totalImpCount: number;
    yearImpCount: number;
    yesFills: number;
    yesImpressions: number;
    yesTries: number;
}

export interface AdSourceAuctionType extends AdSourceProperties {
    key: number;
    entityType: "AdSourceAuctionType";
}

export interface AdSourcePriceModel extends AdSourceProperties {
    entityType: "AdSourcePriceModel";
    sort: number;
}

export interface AdSourcePriority extends AdSourceProperties {
    entityType: string;
    sort: number;
}

export interface AdSourceAbbreviation extends AdSourceProperties {
    abbreviation: string;
}

export interface GetSeatAdSourcesParams {
    seatId: number;
    page?: number;
    max?: number;
    adSourceIds?: (number | string)[];
    adSourceType?: number | string | string[];
    adSourcePriority?: number | string | string[];
    contentFilter?: number[] | string[];
    costModelIds?: number[] | string[];
    currencyIds?: (number | string)[];
    customTarget?: number[] | string[];
    dmpFilter?: boolean;
    keyword?: string;
    labelKeyId?: number[] | string[];
    labelValueId?: number[] | string[];
    region?: number | string | string[];
    statusIds?: number[] | string[];
    timeZoneIds?: (number | string)[];
    userId?: number[] | string[];
    priority?: number | (number | string)[];
    salesContactIds?: (number | string)[];
    sort?: string;
    asc?: boolean;
}

export interface SeatAdSourcesStatus extends AdSourceProperties {
    sort: number;
    entityType: string;
}

export interface SeatAdSourcesType extends AdSourceProperties {
    sort: number;
    entityType: string;
}

export interface AdSourceType extends AdSourceProperties {
    abbreviation: string;
    sort: number;
    entityType: string;
}

export interface AdSourceAdvertiserBlockingMode {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    sort: number;
    entityType: string;
}

export interface AdSourcePacing {
    entityType: number; //need verify
    entityId: number;
    entityName: string; //need verify
    entityCode: number; //need verify
    lastIndexed: string;
    adSourceId: number;
    adSourceName: string;
    adSourceType: number;
    todayImpCount: number;
    monthImpCount: number;
    yearImpCount: number;
    totalImpCount: number;
    recordedTotalImpCount: number;
    bkVolume: number;
    dailyCapValue: number;
    reqOpportunity: number;
    yesTries: number;
    yesFills: number;
    yesImpressions: number;
    yesOpp: number;
    todaysGoal: number; //need verify
    pacingStatusPercentage: number; //need verify
    bkStart: string;
    bkEnd: string;
    customCurveStartDate: string; //need verify
    customCurveImps: number; //need verify
    pacingType: number;
    pacingPeriod: number;
    deliveryPercentage: number;
    marketplaceId: number; //need verify
    marketplaceName: string; //need verify
    seatId: number; //need verify
    seatName: string; //need verify
    seatCode: number; //need verify
    timeZone: string;
    networkId: number; //need verify
    dealId: number;
    assignedUserId: number; //need verify
    pacingDelta: string;
    startDate: string;
    endDate: string;
    averageImpsPerDay: number;
    dailyImpsRemaining: number;
    impsRemaining: number;
    daysElapsed: number;
    daysRemaining: number;
    impsPerDayToFulfill: number;
}

export interface AdSourceGrantsParams {
    emailAddress: string | null;
    permission: Permissions;
    groupId: number | null;
}

export interface AdSourceGrantsPayload {
    id: number;
    body: AdSourceGrantsParams;
}

export interface AdSourceGrants {
    userId: number;
    emailAddress: string;
    groupId: number | null;
    groupName: string | null;
    permission: string;
    entity: {
        id: number;
        name: string;
    };
    objectId: number;
    objectClass: string;
}

export interface AdSourceDeleteGrantsPayload {
    id: number;
    body: AdSourceDeleteGrants;
}

export interface AdSourceDeleteGrants {
    emailAddress: string;
    permission: Permissions;
    groupId: number | null;
}

interface Assignee extends BaseOption {
    creationDate: string;
    updateDate: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    countryCode: string;
    currencyCode: string;
    company: string;
    numFailedAttempts: unknown;
    lockoutMinutes: unknown;
    lockDate: unknown;
}

export interface PacingType extends BaseOption {
    creationDate: string;
    updateDate: string;
    sort?: number;
    entityType?: string;
}

interface TimeZone extends BaseOption {
    creationDate: string;
    updateDate: string;
    code: string;
    utcOffset: number;
    dayLightSavingsOffset: number;
}

interface Region extends BaseOption {
    creationDate: string;
    updateDate: string;
    sort: number;
    entityType: string;
}

export interface CurrencyType extends Omit<BaseOption, "name"> {
    code: string;
}

export interface ThirdPartyPixels extends BaseOption {
    creationDate: string;
    updateDate: string;
    value: string;
    type: ThirdPartyPixelsType;
    addToAdSourceByDefault: boolean;
    sourceClassName: unknown;
}

interface ThirdPartyPixelsType extends BaseOption {
    creationDate: string;
    updateDate: string;
}

interface DealStatus extends BaseOption {
    creationDate: string;
    updateDate: string;
}

interface DealNetwork extends BaseOption {
    seat: { id: number; name: string; code: string; entityType: string };
    allowedCurrencies: unknown[];
    entityType: string;
    externalDealSyncIntegrations?: AdSourceProperties[];
}

interface DealPricingType extends BaseOption {
    value: string;
}

interface DealTransactionType extends BaseOption {
    value: string;
}

interface DealCurrencyType extends Omit<BaseOption, "name"> {
    creationDate: string;
    updateDate: string;
    code: string;
}

export interface DealPriceModel extends BaseOption {
    creationDate: string;
    updateDate: string;
}

export interface BuyerSeat extends BaseOption {
    creationDate: string;
    updateDate: string;
    code: string;
}

export interface BidderConfig extends BaseOption {
    creationDate: string;
    updateDate: string;
    code: string;
}

interface Deal {
    id: number;
    name: string | null;
    code: string;
    dealType: BaseOption;
    dealOrigin: BaseOption;
    advertiserBlockDomains: unknown;
    status: DealStatus;
    seat: Omit<BaseOption, "name">;
    marketplace: unknown;
    description: unknown;
    network: DealNetwork;
    dealPricingType: DealPricingType;
    dealTransactionType: DealTransactionType;
    rate: string | null;
    deleted?: boolean;
    bookingVolume: number;
    currencyType?: DealCurrencyType;
    startTime: string;
    endTime: string | null;
    sharedSeats: BaseOption[];
    sharedMarketplaces: BaseOption[];
    externalName: string;
    externalParentName: string;
    externalStatus: string;
    revision: number;
    externalId: unknown;
    externalParentId: unknown;
    timeZone: TimeZone;
    pendingNotes: unknown;
    dealPrices: null; // deprecated
    priceModel: DealPriceModel;
    resetFrequency: null; // deprecated
    tierResetMode: null; // deprecated
    tierResetMonthDay: unknown;
    tierOverflowAllowed: null; // deprecated
    "@class": string;
    buyerSeats?: BuyerSeat[];
    publisherEmail: string;
    publisherName: string;
    demandFee: unknown;
    entityType: string;
}

export interface BaseOption {
    id: number;
    name: string;
}

export interface AdSourceTypeCounts {
    typeId: number;
    count: number;
}

export interface AdSourceCoreIndexTime {
    lastIndexed: string;
}

export interface AdSourceTargetingIndexTime {
    lastIndexed: string;
}

export interface DealFreqCapping extends Omit<AdSourceProperties, "name"> {
    mode: AdSourceProperties;
    timeDurationMin: number;
    total: number;
    id: number;
}

export interface MarketplaceInfo {
    id: number;
    currencyType: CurrencyType;
    enforcement?: BaseOption;
    freqCappingType?: BaseOption;
    dealFreqCappings: FreqCapping[];
    targeting: Targeting[];
    deal: Deal;
    network: BaseOption;
    startDate: string;
    endDate: string | null;
    buyerSeats: BuyerSeat[];
    rate: number;
    demandFee: number | null;
    "@class": string;
}

export interface FormMarketplaceInfo extends Omit<MarketplaceInfo, "startDate" | "endDate"> {
    startDate: moment.Moment;
    endDate: moment.Moment | null;
}

export interface AdSourceFloorType extends AdSourceProperties {
    sort: number;
    entityType: "AdSourceFloorType";
}

export interface AuctionType extends BaseOption {
    creationDate: string;
    updateDate: string;
}

export interface InventoryDistributionGroup {
    labelValue: {
        id: number;
        label: Label | null;
        value: string;
    } | null;
    cap: string;
}

export interface AdSourcePayload {
    id?: number;
    seat: Omit<BaseOption, "name">;
    associatedMarketplace?: Partial<BaseOption>;
    curatorMarketplaceIds?: number[];
    name: string;
    description: string;
    advertiserBlockDomains: string[];
    advertiserBlockingMode: Partial<BaseOption>;
    allowSiteName: boolean;
    allowDomainName: boolean;
    allowSitePage: boolean;
    allowRef: boolean;
    allowUserId: boolean;
    allowContent: boolean;
    allowIp: boolean;
    allowBundleId: boolean;
    allowStoreUrl: boolean;
    allowOztam?: boolean;
    allowOztamDemographic?: number;
    allowAudienceLock: boolean;
    assignee: Partial<Assignee>;
    overrideDynamicFloor: boolean;
    nurlTimeoutOverride: boolean;
    alwaysSendFloor: boolean;
    allowAutoscale: boolean;
    allowDealsUnderFloor?: boolean;
    bookingPrice: number | null;
    vastDynamicPricingLocation: Partial<BaseOption>;
    vastDynamicPricingMultiplier: unknown;
    demandFee?: number | null;
    demandAcquisitionCost: number | null;
    demandAcquisitionCostModel: Pick<BaseOption, "id"> & Partial<BaseOption>;
    targeting?: Targeting[];
    bookingStartDate: string;
    bookingEndDate: string | null;
    status: SeatAdSourcesStatus;
    metadata?: unknown;
    costModel: BaseOption;
    costValueFixed?: number;
    costValuePercent: number;
    auctionType: AuctionType;
    priority: BaseOption;
    udePriority: BaseOption;
    type: BaseOption;
    excludedNetworks?: BaseOption[];
    marketplaceInfoList: MarketplaceInfo[];
    priceModel: AdSourcePriceModel;
    resetFrequency: null; // deprecated
    tierResetMode: null; // deprecated
    tierOverflowAllowed: null; // deprecated
    dealPrices: null; // deprecated
    dailyCapVolume: number | null;
    bookingVolume: number;
    pacingType: PacingType | null;
    pacingPeriod: BaseOption | null;
    timeZone: TimeZone;
    adSourceFloorType?: AdSourceFloorType;
    region: Region;
    salesContact: unknown;
    externalContractId: string | null;
    currencyType: CurrencyType;
    thirdPartyPixels: ThirdPartyPixels[];
    tagUrl: string;
    defaultAdvertiserDomain?: string;
    shareOfVoicePercent: unknown;
    contentTransparencyRule: SeatContentTransparencyRule;
    adSourceMinDuration: number;
    adSourceMaxDuration: number;
    dailyRequestOpportunityFallback: number | null;
    labelValues: unknown[];
    strictMode: boolean;
    seatAdvertiserDomainFilterListDefs: BaseOption[];
    bundleIdOverride: string | null;
    domainNameOverride: string;
    maskUserId: boolean;
    pbsConfigs?: BidderConfig[];
    allowAllExtendedId: boolean;
    allowedExtendedIdTransparencies: DealAdSourceTransparencyExtendedUserId[];
    targetingMode?: BaseOption;
    inventoryDistributionGroups?: InventoryDistributionGroup[];
    redistributePacing: boolean | null;
    synthesizedAdSourceStatus: SynthesizedAdSourceStatus;
    iabCategoryBlockMode?: IabCategoryBlockMode;
    blockedIabCategories?: IabCategory[];
    blockExceptionIabCategories?: { id: number }[] | IabCategory[];
    adResponsePriceOverrideType?: AdResponsePriceOverride | null;
}

export interface CreateAdSourceParams {
    seatId: number;
    body: AdSourcePayload;
}

export interface UpdateAdSourceParams {
    id: number;
    body: Partial<AdSourcePayload>;
}

export interface Enforcement {
    creationDate: string;
    updateDate: string;
    id: number;
    sort: number;
    name: string;
    entityType: "DealEnforcement";
}

export interface IabCategoryBlockMode extends BaseOption {
    sort: number;
    entityType: "IabCategoryBlockMode";
    creationDate: string;
    updateDate: string;
}

export interface FreqCappingType {
    creationDate: string;
    entityType: "FreqCappingType";
    id: number;
    name: string;
    sort: number;
    updateDate: string;
}

export interface AdResponsePriceOverride {
    creationDate: string;
    entityType: "AdResponsePriceOverrideType";
    id: number;
    name: string;
    sort: number;
    updateDate: string;
}

export const adSources = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        createAdSourceGrants: builder.mutation<AdSourceGrants[], AdSourceGrantsPayload>({
            query: ({ id, body }) => ({
                url: `adSources/${id}/grants`,
                method: "POST",
                body,
            }),
            invalidatesTags: (_, err) => (err ? [] : [AD_SOURCE_GRANTS_TAG]),
        }),
        deleteAdSourceGrants: builder.mutation<unknown, AdSourceDeleteGrantsPayload>({
            query: ({ id, body }: AdSourceDeleteGrantsPayload) => ({
                url: `adSources/${id}/grants`,
                method: "DELETE",
                body,
            }),
            invalidatesTags: (_, err) => (err ? [] : [AD_SOURCE_GRANTS_TAG]),
        }),
        getAdSourceGrants: builder.query<AdSourceGrants[], number>({
            query: (id: number) => `adSources/${id}/grants`,
            providesTags: [AD_SOURCE_GRANTS_TAG],
        }),
        getAdSourceAuctionTypes: builder.query<AdSourceAuctionType[], void>({
            query: () => "adSources/auctionTypes",
            providesTags: () => [AD_SOURCE_AUCTION_TYPES_TAG],
        }),
        getAdSourceById: builder.query<AdSource, number>({
            query: (id: number) => `adSources/ctvNext/${id}`,
            providesTags: (_, err, id) => (err ? [] : [{ type: AD_SOURCE_TAG, id }]),
            transformResponse: (response: AdSource) => {
                const mappedResponse = {
                    ...response,
                    targeting: transformTargetingArrayFromApi(response.targeting),
                    marketplaceInfoList: response.marketplaceInfoList.map((marketplaceInfo) => ({
                        ...marketplaceInfo,
                        targeting: transformTargetingArrayFromApi(marketplaceInfo.targeting),
                        deal: {
                            ...marketplaceInfo.deal,
                            createdWithBuyerSeats: Boolean(marketplaceInfo.deal.buyerSeats?.length),
                        },
                    })),
                };
                return mappedResponse;
            },
        }),
        getAdSourcePriceModels: builder.query<AdSourcePriceModel[], void>({
            query: () => `adSources/priceModels`,
            providesTags: () => [AD_SOURCE_PRICE_MODELS_TAG],
        }),
        getAdSourceFloorTypes: builder.query<AdSourceFloorType[], void>({
            query: () => `adSources/floorTypes`,
            providesTags: () => [AD_SOURCE_FLOOR_TYPES_TAG],
        }),
        getAdSourceTypesCounts: builder.query<AdSourceTypeCounts[], string>({
            query: (seatId) => `seats/${seatId}/adSources/counts`,
            providesTags: () => [AD_SOURCE_TYPES_COUNTS],
        }),
        getAdSourceCoreIndexTime: builder.query<AdSourceCoreIndexTime, number>({
            query: (id: number) => `solr/platform/adsource/adSource/${id}`,
            providesTags: () => [AD_SOURCE_CORE_INDEX_TIME],
        }),
        getAdSourceTargetingIndexTime: builder.query<AdSourceTargetingIndexTime, number>({
            query: (id: number) => `solr/platform/adsource/adSourceTargeting/${id}`,
            providesTags: () => [AD_SOURCE_TARGETING_INDEX_TIME],
        }),
        getAdSourcePriorities: builder.query<AdSourcePriority[], void>({
            query: () => `adSources/priorities`,
            providesTags: () => [AD_SOURCE_PRIORITIES_TAG],
        }),
        getSeatAdSources: builder.query<AdSourcesListResponse, GetSeatAdSourcesParams>({
            query: ({ seatId, ...rest }: GetSeatAdSourcesParams) => {
                const params: Omit<GetSeatAdSourcesParams, "seatId"> = {
                    ...rest,
                };

                return `seats/ctvNext/${seatId}/adSources;${toQueryString(params)}`;
            },
            providesTags: () => [{ type: SEAT_AD_SOURCES_TAG }],
            transformResponse: (response: AdSourcesListResponse) => ({
                ...response,
                adSources: response.adSources.filter(
                    ({ type, adSourcePriceModel }) =>
                        !(
                            type.id === AdSourceTypeIds.UNRESERVED_FIXED_RATE &&
                            adSourcePriceModel?.id === PricingModels.TIERED_PRICE
                        )
                ),
            }),
        }),
        getSeatAdSourcesStatuses: builder.query<SeatAdSourcesStatus[], void>({
            query: () => `adSources/statuses`,
            providesTags: () => [SEAT_AD_SOURCES_STATUSES_TAG],
        }),
        getSeatAdSourcesPicklist: builder.query<AdSourceListItem[], GetSeatAdSourcesParams>({
            query: ({ seatId, ...rest }: GetSeatAdSourcesParams) => {
                const params: Omit<GetSeatAdSourcesParams, "seatId"> = {
                    ...rest,
                };

                return `seats/ctvNext/${seatId}/adSources/picklist;${toQueryString(params)}`;
            },
            providesTags: () => [{ type: SEAT_AD_SOURCES_PICKLIST_TAG }],
        }),
        getSeatAdSourcesType: builder.query<SeatAdSourcesType[], number>({
            query: (seatId: number) => `adSources/types;seatId=${seatId}`,
            providesTags: (_, __, seatId) => [{ type: SEAT_AD_SOURCES_TYPE_TAG, seatId }],
        }),
        getAdSourcesType: builder.query<AdSourceType[], void>({
            query: () => "adSources/types",
            providesTags: () => [AD_SOURCE_PACING_TYPES_TAG],
        }),
        getSeatAdSourcesPacing: builder.query<[], string>({
            query: (seatId: string) => `seats/${seatId}/adSources/pacing`,
            providesTags: () => [{ type: SEAT_AD_SOURCES_PACING_TAG }],
        }),
        getAdSourcePacing: builder.query<AdSourcePacing, number>({
            query: (adSourceId: number) => `adSources/${adSourceId}/pacing`,
            providesTags: () => [{ type: AD_SOURCE_PACING_TAG }],
        }),
        getAdvertiserBlockingModes: builder.query<AdSourceAdvertiserBlockingMode[], void>({
            query: () => `adSources/advertiserBlockingModes`,
            providesTags: () => [AD_SOURCES_ADVERTISER_BLOCKING_MODES_TAG],
        }),
        getSeatAdSourcesPacingTypes: builder.query<PacingType[], void>({
            query: () => "adSources/pacingTypes",
            providesTags: () => [AD_SOURCE_PACING_TYPES_TAG],
        }),
        getSeatAdSourcesPacingPeriods: builder.query<BaseOption[], void>({
            query: () => "adSources/pacingPeriods",
            providesTags: () => [AD_SOURCE_PACING_PERIODS_TAG],
        }),
        createAdSource: builder.mutation<AdSource, CreateAdSourceParams>({
            query: (arg: CreateAdSourceParams) => {
                const { seatId, body } = arg;
                return {
                    url: `seats/${seatId}/adSources`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (_, err) =>
                err ? [] : [SEAT_AD_SOURCES_TAG, SEAT_AD_SOURCES_TAG, DEAL_TAG, SEAT_ALL_DEALS_TAG, DEAL_AD_SOURCE_TAG],
        }),
        updateAdSource: builder.mutation<AdSource, UpdateAdSourceParams>({
            query: ({ id, body }: UpdateAdSourceParams) => {
                return {
                    url: `/adSources/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: (_, err, { id }) =>
                err ? [] : [{ type: AD_SOURCE_TAG, id }, SEAT_AD_SOURCES_TAG, DEAL_AD_SOURCE_TAG],
        }),
        deleteAdSource: builder.mutation<unknown, number>({
            query: (id: number) => ({ url: `/adSources/${id}`, method: "DELETE" }),
            invalidatesTags: (_, err) => (err ? [] : [SEAT_AD_SOURCES_TAG]),
        }),
        getEnforcements: builder.query<Enforcement[], void>({
            query: () => "/adSources/enforcements",
            providesTags: [ENFORCEMENTS_TAG],
        }),
        getFreqCappingTypes: builder.query<FreqCappingType[], void>({
            query: () => "/adSources/freqCappingTypes",
            providesTags: [FREQ_CAPPING_TYPES_TAG],
        }),
        getAdSourceIabCategoryBlockModes: builder.query<IabCategoryBlockMode[], void>({
            query: () => "/adSources/catBlockingModes",
            providesTags: () => [AD_SOURCE_IAB_CATEGORY_BLOCK_MODES_TAG],
        }),
        getAdResponsePriceOverrides: builder.query<AdResponsePriceOverride[], void>({
            query: () => "/adSources/adResponsePriceOverrides",
            providesTags: () => [AD_RESPONSE_PRICE_OVERRIDES_TAG],
        }),
        getAdResponsePriceOverridesById: builder.query<AdResponsePriceOverride | null, number>({
            query: (id: number) => `/adSources/adResponsePriceOverrides/${id}`,
            providesTags: () => [AD_RESPONSE_PRICE_OVERRIDES_TAG_BY_ID],
        }),
    }),
    overrideExisting: false,
});

export const {
    useCreateAdSourceGrantsMutation,
    useCreateAdSourceMutation,
    useDeleteAdSourceGrantsMutation,
    useDeleteAdSourceMutation,
    useGetAdSourceAuctionTypesQuery,
    useGetAdSourceByIdQuery,
    useGetAdSourceFloorTypesQuery,
    useGetAdSourceGrantsQuery,
    useGetAdSourcePriceModelsQuery,
    useGetAdSourcePrioritiesQuery,
    useGetAdSourceTypesCountsQuery,
    useGetAdSourceCoreIndexTimeQuery,
    useGetAdSourceTargetingIndexTimeQuery,
    useGetAdSourcePacingQuery,
    useGetAdvertiserBlockingModesQuery,
    useGetEnforcementsQuery,
    useGetFreqCappingTypesQuery,
    useGetSeatAdSourcesPacingPeriodsQuery,
    useGetSeatAdSourcesPacingQuery,
    useGetSeatAdSourcesPacingTypesQuery,
    useGetSeatAdSourcesPicklistQuery,
    useGetAdSourcesTypeQuery,
    useGetSeatAdSourcesQuery,
    useLazyGetSeatAdSourcesQuery,
    useGetSeatAdSourcesStatusesQuery,
    useGetSeatAdSourcesTypeQuery,
    useUpdateAdSourceMutation,
    useGetAdSourceIabCategoryBlockModesQuery,
    useGetAdResponsePriceOverridesQuery,
    useGetAdResponsePriceOverridesByIdQuery,
} = adSources;
