import { FC, useState } from "react";
import DealChartDrawerNavMenu, {
    DealChartDrawerMenuItemKey,
    HISTORICAL,
    OVERVIEW,
} from "../../DealChartDrawer/DealChartDrawerNavMenu/DealChartDrawerNavMenu";
import { DealListSource } from "@app/core/services";
import { DealsHistoricalChart } from "../../DealChartDrawer/DealCharts/DealsHistoricalChart/DealsHistoricalChart";
import { Overview } from "./Overview/Overview";

interface Props {
    dealId: number;
    adSources: DealListSource[];
    sourceCurrencyCode: string | undefined;
    isInsideDrawer: boolean;
}

export const PerformanceSection: FC<Props> = ({ adSources, dealId, sourceCurrencyCode, isInsideDrawer }) => {
    const [activeKey, setActiveKey] = useState<DealChartDrawerMenuItemKey>(OVERVIEW);

    return (
        <>
            <DealChartDrawerNavMenu activeKey={activeKey} onChange={(key) => setActiveKey(key)} />
            {activeKey === OVERVIEW && (
                <Overview isInsideDrawer={isInsideDrawer} dealId={dealId} sourceCurrencyCode={sourceCurrencyCode} />
            )}
            {activeKey === HISTORICAL && adSources && <DealsHistoricalChart dealId={dealId} adSources={adSources} />}
        </>
    );
};
