import { FC, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Dropdown, Empty, Input, Menu, Row } from "antd";
import { LoadingOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { brandSlate, gray1, gray5 } from "@rubicon/antd-components";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { Targeting, useGetSeatReusableTargetingQuery } from "@app/core/services/console";
import { getTargetingStub } from "../helpers";
import { TargetingStub } from "../types";

const useAddExistingTargeting = (selectedIds: number[]) => {
    const [search, setSearch] = useState("");
    const [keyword, setKeyword] = useState("");
    const { seatId } = useParams<{ seatId: string }>();
    const { data, isFetching } = useGetSeatReusableTargetingQuery({ seatId: Number(seatId), keyword });

    const options = useMemo(() => {
        return (data || []).map((datum) => ({
            key: datum.id,
            label: datum.name,
            disabled: selectedIds.includes(datum.id),
        }));
    }, [data, selectedIds]);

    const debouncedSetKeyword = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setKeyword]
    );

    const handleChangeSearch = (search: string) => {
        setSearch(search);
        debouncedSetKeyword(search);
    };

    return {
        handleChangeSearch,
        search,
        options,
        isLoading: isFetching,
    };
};

interface Props {
    addExistingTargetingBlock: (targeting: Targeting | TargetingStub) => void;
    selectedIds: number[];
}

export const AddExistingTargetingButton: FC<Props> = ({ addExistingTargetingBlock, selectedIds }) => {
    const { handleChangeSearch, search, options, isLoading } = useAddExistingTargeting(selectedIds);

    return (
        <Dropdown
            autoFocus
            trigger={["click"]}
            dropdownRender={() => (
                <Row style={{ width: "256px" }}>
                    <Col xs={24} style={{ padding: "4px 6px", background: gray1, border: `1px solid ${gray5}` }}>
                        <Input
                            onChange={(e) => handleChangeSearch(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                            value={search}
                            suffix={isLoading ? <LoadingOutlined /> : <SearchOutlined style={{ color: brandSlate }} />}
                            size="small"
                        />
                    </Col>
                    <Col xs={24}>
                        {!options.length ? (
                            <div className="ant-dropdown-menu">
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </div>
                        ) : (
                            <Menu
                                style={{ maxHeight: "192px", width: "100%", overflowY: "scroll" }}
                                items={options}
                                onClick={({ key }) => {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    const name = options.find((option) => option.key === Number(key)).label;
                                    // Create targeting block stub
                                    addExistingTargetingBlock(getTargetingStub(Number(key), name));
                                }}
                            />
                        )}
                    </Col>
                </Row>
            )}
        >
            <Button type="link" icon={<PlusOutlined />} data-sdet="add-existing-targeting-block">
                Add Existing Targeting Group
            </Button>
        </Dropdown>
    );
};
