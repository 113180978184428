import { PageHeader } from "@ant-design/pro-layout";
import { gray2 } from "@rubicon/antd-components";
import styled from "@emotion/styled";

export const StyledPageHeader = styled(PageHeader)`
    &.ant-page-header {
        background-color: ${gray2};
        padding-bottom: 0;
    }
`;
