import { CSSProperties, ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { uri } from "@rubicon/utils";
import { func } from "@rubicon/utils";
import { PAGE } from "../hooks";
import { brandSlate } from "@rubicon/antd-components";

type Props = {
    sdet?: string;
    onSearch?: (value: string) => void;
    placeholder?: string;
    style?: CSSProperties;
    searchParam?: SearchParam;
};

export type SearchParam = {
    key: string;
    default: string;
};

export const SEARCH: SearchParam = { key: "search", default: "" };
export const ASYNC_INPUT_DEBOUNCE_TIME = 400;

export const DebounceUriSearch: FC<Props> = ({
    sdet = "debounce-uri-search",
    placeholder = "Search",
    onSearch,
    style = { width: 300 },
}) => {
    const { search: queryString } = useLocation();
    const history = useHistory();

    const searchTerm = uri.getSearchParam(queryString, SEARCH.key) || SEARCH.default;
    const [value, setValue] = useState(searchTerm);

    // To make sure when manually change url will update input field
    useEffect(() => {
        setValue(searchTerm);
    }, [searchTerm]);

    const handleSearch = (searchValue: string) => {
        if (searchValue) {
            onSearch?.(searchValue);
        }
        const queryStringWithoutPage = uri.deleteSearchParam(queryString, PAGE.key);
        const nextQueryString = uri.setSearchParam(queryStringWithoutPage, SEARCH.key, searchValue);
        history.push({ search: nextQueryString });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleDebounce = useCallback(func.debounce(handleSearch, ASYNC_INPUT_DEBOUNCE_TIME), [history, queryString]);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.currentTarget.value);
        handleDebounce(e.currentTarget.value);
    };

    return (
        <Input
            allowClear
            data-sdet={sdet}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            suffix={<SearchOutlined style={{ color: brandSlate }} />}
            style={style}
        />
    );
};
