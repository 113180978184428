export const TYPE_FILTER_ALL = { label: "All", value: "" } as const;

export const TYPE_FILTER_OPTIONS = [
    TYPE_FILTER_ALL,
    { label: "Country", value: "country" },
    { label: "State/Region", value: "state" },
    { label: "City", value: "city" },
    { label: "Postal Code", value: "postal" },
    { label: "Digital Market Area (DMA)", value: "dma" },
];
