import { FC } from "react";
import { AdUnitAdditionalTime } from "@app/features/inventory/components/FormItems";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const AdditionalTime: FC = () => {
    return (
        <AdUnitAdditionalTime
            name={CHANNEL_FORM_FIELDS.ADDITIONAL_TIME.name}
            label={CHANNEL_FORM_FIELDS.ADDITIONAL_TIME.label}
            placeholder={CHANNEL_FORM_FIELDS.ADDITIONAL_TIME.placeholder}
        />
    );
};
