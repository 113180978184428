import { useAppDispatch, useAppSelector } from "@app/core/store";
import { setFiltersTimeFrame, selectDashboardTimeFrameFilter } from "@app/features/dashboard/reducer";
import { LabeledValue } from "antd/lib/select";
import { useEffect, useMemo } from "react";
import {
    NON_UTC_TIME_FRAME_OPTIONS_LABEL_VALUE,
    TIME_FRAME_OPTIONS_LABEL_VALUE,
    TIME_ZONE_UTC,
    TODAY_LABEL_VALUE,
} from "./constants";
import { selectUserTimezone } from "@app/core/authClient/reducer";

interface UseTimeFrameFilter {
    handleChangeValue: (value: string | undefined, option: LabeledValue | LabeledValue[]) => void;
    options: LabeledValue[];
    value: string;
}

export const useTimeFrameFilter = (): UseTimeFrameFilter => {
    const dispatch = useAppDispatch();
    const timeZone = useAppSelector(selectUserTimezone);
    const timeFrameFilter = useAppSelector(selectDashboardTimeFrameFilter);

    useEffect(() => {
        if (
            timeZone !== TIME_ZONE_UTC &&
            !NON_UTC_TIME_FRAME_OPTIONS_LABEL_VALUE.some((v) => v.value === timeFrameFilter.value)
        ) {
            dispatch(setFiltersTimeFrame(TODAY_LABEL_VALUE));
        }
    }, [timeZone, dispatch, timeFrameFilter]);

    const options = useMemo(
        () => (timeZone === TIME_ZONE_UTC ? TIME_FRAME_OPTIONS_LABEL_VALUE : NON_UTC_TIME_FRAME_OPTIONS_LABEL_VALUE),
        [timeZone]
    );

    const handleChangeValue = (id: string | undefined, option: LabeledValue | LabeledValue[]) => {
        if (!id || Array.isArray(option)) {
            return;
        }
        dispatch(setFiltersTimeFrame(option));
    };

    return {
        options,
        value: timeFrameFilter.value as string,
        handleChangeValue,
    };
};
