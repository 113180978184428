import { ArrowRightOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Flex, Tooltip, Typography } from "antd";
import { FC } from "react";
import { DealToolbar } from "@app/features/deals/DealToolbar";
import { DealDetailsDrawerTitleInfo } from "@app/features/deals/DealDetailsDrawer/DealDetailsDrawerContent";
import { css } from "@emotion/css";
import { useDealDetailsDrawerNavTitle } from "./useDealDetailsDrawerNavTitle";
import { ClipBoardButton } from "@app/core/components";
import { brandCobalt } from "@rubicon/antd-components";

interface Props {
    titleInfo: DealDetailsDrawerTitleInfo;
}

export const DealDetailsDrawerNavTitle: FC<Props> = ({ titleInfo }) => {
    const { dealName, dealId, dealEntityType, isDealShared, adSources } = titleInfo;
    const { copyUrl, seatId, goDealDetailsPage, visibleCopy, handleHideCopy, handleVisibleCopy } =
        useDealDetailsDrawerNavTitle(dealId);

    return (
        <Flex data-sdet="deal-details-drawer-nav-title" wrap="nowrap" vertical gap={10}>
            <Flex align="baseline" flex="auto">
                <Typography.Title
                    level={5}
                    style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        maxWidth: "25rem",
                        paddingRight: "1rem",
                        marginTop: 0,
                    }}
                >
                    {dealName}
                    {isDealShared && (
                        <Tooltip title="This deal has been shared via an external entity, and cannot be edited here">
                            <InfoCircleOutlined
                                data-sdet="deal-details-drawer-nav-title-infor-icon"
                                style={{ color: brandCobalt, marginLeft: 8 }}
                            />
                        </Tooltip>
                    )}
                </Typography.Title>
                {dealId && (
                    <Flex onMouseEnter={handleVisibleCopy} onMouseLeave={handleHideCopy} align="baseline">
                        <Button
                            onClick={goDealDetailsPage}
                            style={{ paddingLeft: 0 }}
                            type="link"
                            className={css`
                                padding: 0;
                            `}
                        >
                            <Typography.Text
                                className={css`
                                    font-size: 0.9rem;
                                    color: ${brandCobalt};
                                `}
                            >
                                Go to deal <ArrowRightOutlined />
                            </Typography.Text>
                        </Button>

                        {visibleCopy && <ClipBoardButton copyText={copyUrl} />}
                    </Flex>
                )}
            </Flex>
            <DealToolbar
                seatId={seatId}
                dealId={dealId}
                dealEntityType={dealEntityType}
                isDealShared={isDealShared}
                adSources={adSources}
                isNested={true}
            />
        </Flex>
    );
};
