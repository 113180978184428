import { Table } from "antd";
import { FC, CSSProperties } from "react";
import { ExternalCode } from "@app/core/services";

interface Props {
    externalCodes: ExternalCode[];
    style?: CSSProperties;
}

const EXTERNAL_CODES_TABLE_COLUMNS = [
    {
        title: "Type",
        dataIndex: "type",
        width: "33%",
    },
    {
        title: "Sub Type",
        dataIndex: "subType",
        width: "33%",
    },
    {
        title: "Code",
        dataIndex: "code",
        width: "34%",
    },
];

const getTableItems = (externalCodes: ExternalCode[]) => {
    return externalCodes.map((externalCode) => ({
        type: externalCode.extAdUnitCodeNamespace.name,
        subType: externalCode.extAdUnitCodeNamespaceType.name,
        code: externalCode.extAdUnitCode,
    }));
};

export const ExternalCodesTable: FC<Props> = ({ externalCodes = [], style }) => {
    return (
        <Table
            size="small"
            style={style}
            pagination={false}
            showSorterTooltip={false}
            columns={EXTERNAL_CODES_TABLE_COLUMNS}
            dataSource={getTableItems(externalCodes)}
        />
    );
};
