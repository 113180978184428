import { FC } from "react";
import { AffiliateCostModel } from "@app/features/inventory/components/FormItems";
import { BRAND_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useBrandFormInstance } from "@app/features/inventory/HierarchyForms/BrandForm/BrandFormInstance";

export const CostModel: FC = () => {
    const { seat, publisher, brand } = useBrandFormInstance();
    const extra: string = `Inherited value: ${
        brand?.affiliateCostModel?.name || publisher?.affiliateCostModel?.name || seat?.affiliateCostModel?.name
    }`;

    return (
        <AffiliateCostModel
            extra={extra}
            name={BRAND_FORM_FIELDS.AFFILIATE_COST_MODEL.name}
            label={BRAND_FORM_FIELDS.AFFILIATE_COST_MODEL.label}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 24, xxl: 20 }}
            placeholder={BRAND_FORM_FIELDS.AFFILIATE_COST_MODEL.placeholder}
        />
    );
};
