import { ROUTES, ROUTE_FORMATTERS } from "@app/core/routing";
import { useGetSeatAdvancedFloorByIdQuery } from "@app/core/services";
import { Col, Row, Spin } from "antd";
import { FC } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { SeatFloorsAdvancedDetails } from "./SeatFloorsAdvancedDetails";
import { SeatFloorsAdvancedDefinition } from "./SeatFloorsAdvancedDefinition";
import { SeatFloorsAdvancedDrawer, SeatFloorsAdvancedDrawerLayout } from "./SeatFloorsAdvancedDrawer";

interface Props {
    handleBack?: () => void;
}
export const SeatFloorsAdvancedSwitch: FC<Props> = ({ handleBack = () => {} }) => {
    const history = useHistory();
    const { seatId, id } = useParams<{ seatId: string; id: string }>();
    const { data, isFetching } = useGetSeatAdvancedFloorByIdQuery(Number(id), { skip: !id });

    const handleCloseDrawer = () => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_ADVANCED(Number(seatId)));
    };

    const handleEdit = () => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_ADVANCED_EDIT(Number(seatId), id));
    };

    return (
        <Switch>
            <Route
                exact
                path={[
                    ROUTES.SEAT_INVENTORY_FLOOR_ADVANCED_DEFINITION,
                    ROUTES.SEAT_INVENTORY_FLOOR_ADVANCED_EDIT_DEFINITION,
                    ROUTES.SEAT_INVENTORY_FLOOR_ADVANCED_DETAILS,
                ]}
            >
                <SeatFloorsAdvancedDrawer onClose={handleCloseDrawer}>
                    <Switch>
                        <Route
                            exact
                            path={[
                                ROUTES.SEAT_INVENTORY_FLOOR_ADVANCED_DEFINITION,
                                ROUTES.SEAT_INVENTORY_FLOOR_ADVANCED_EDIT_DEFINITION,
                            ]}
                        >
                            <SeatFloorsAdvancedDefinition handleClose={handleCloseDrawer} handleBack={handleBack} />
                        </Route>
                        <Route exact path={ROUTES.SEAT_INVENTORY_FLOOR_ADVANCED_DETAILS}>
                            {isFetching ? (
                                <Row justify="center" align="middle" style={{ height: "100%" }}>
                                    <Col>
                                        <Spin />
                                    </Col>
                                </Row>
                            ) : (
                                <SeatFloorsAdvancedDrawerLayout
                                    title={`Floor: ${data?.name}`}
                                    closeHandler={handleCloseDrawer}
                                    handleEdit={handleEdit}
                                >
                                    <SeatFloorsAdvancedDetails advancedFloor={data} />
                                </SeatFloorsAdvancedDrawerLayout>
                            )}
                        </Route>
                    </Switch>
                </SeatFloorsAdvancedDrawer>
            </Route>
        </Switch>
    );
};
