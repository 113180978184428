import { FC } from "react";
import { Form } from "antd";
import { Seat } from "@app/core/services";
import { FormLayout } from "antd/lib/form/Form";
import { FORM_GRID_OPTIONS } from "../BrandSafetyPublisherPage/constants";
import {
    BlockedAdvertiserDomainListFormItem,
    BlockedAdvertiserDomainsFormItem,
    BlockedBuyerSeatListFormItem,
} from "./ViewFormItems";

interface Props {
    seat: Seat;
    layout?: FormLayout;
}

export const BrandSafetySeatForm: FC<Props> = ({ seat, layout = "horizontal" }) => {
    return (
        <Form layout={layout} {...FORM_GRID_OPTIONS[layout]} labelAlign="left">
            <BlockedAdvertiserDomainListFormItem
                seatAdvertiserDomainFilterListDefs={seat.seatAdvertiserDomainFilterListDefs}
            />
            <BlockedAdvertiserDomainsFormItem advertiserBlockDomains={seat.advertiserBlockDomains} />
            <BlockedBuyerSeatListFormItem buyerSeatList={seat.buyerSeatList} />
        </Form>
    );
};
