import { DualAxesConfig } from "@ant-design/plots";
import { TimingStats } from "@app/core/services";
import { LabeledValue } from "antd/lib/select";
import { useMemo } from "react";
import { ChartTooltipContent } from "./ChartTooltip";
import {
    BucketedColumnData,
    getColumnData,
    getCombinedData,
    labelFormatter,
    SplineData,
    TimingStatsCombinedTotal,
    TimingStatsCumulativeEtity,
    TimingStatsEntity,
    TooltipCustomContentData,
} from "./helpers";

import { TimingStatsChartType } from "./useTimingStats";

const emtpyDataConfig: DualAxesConfig = {
    data: [[], []],
    xField: "",
    yField: ["", ""],
};

export const LINES_COLORS = {
    filledCumulative: "#FF9FC6",
    unFilledCumulative: "#726AF0",
    combinedTotal: "#F6C02D",
};

export const HORIZONTAL_LINE_COLOR = "#d9d9d9";

const COLUMNS_COLORS = {
    filled: "#63DAAB",
    unFilled: "#9A67BD",
};

const LEGEND_NAMES = {
    [TimingStatsEntity.FILLED]: "# Filled",
    [TimingStatsEntity.UNFILLED]: "# Unfilled",
    [TimingStatsCumulativeEtity.FILLED_CUMULATIVE]: "Cumulative # Filled",
    [TimingStatsCumulativeEtity.UNFILLED_CUMULATIVE]: "Cumulative # Unfilled",
    [TimingStatsCombinedTotal.COMBINED_TOTAL]: "Combined Total",
};

interface UseTimingStatsChartConfig {
    config: DualAxesConfig;
}

export const useTimingStatsChartConfig = (
    chartType: TimingStatsChartType,
    region?: LabeledValue,
    timingStatsData?: TimingStats
): UseTimingStatsChartConfig => {
    const config = useMemo(() => {
        if (!timingStatsData) {
            return emtpyDataConfig;
        }
        const data: [BucketedColumnData[], SplineData[]] = [[], []];
        const combinedTotal: SplineData[] = [];

        if (region) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { filled, unFilled } = timingStatsData.data?.[region?.value].dData;

            const unFilledKeys = Object.keys(unFilled.tData);
            const filledKeys = Object.keys(filled.tData);
            const allKeys = new Set([...unFilledKeys, ...filledKeys]);

            const { columnData: unFilledData, totalTill: unFilledTotalTill } = getColumnData(
                unFilled.tData,
                TimingStatsEntity.UNFILLED,
                allKeys
            );
            const { columnData: filledData, totalTill: filledTotalTill } = getColumnData(
                filled.tData,
                TimingStatsEntity.FILLED,
                allKeys
            );
            const column = [...filledData, ...unFilledData];
            const { splineData, combinedTotalData } = getCombinedData(filledTotalTill, unFilledTotalTill);
            combinedTotal.push(...combinedTotalData);

            data[0] = column;
            data[1] = splineData;
        }

        return {
            data,
            xField: "time",
            yField: ["y", "count"],
            legend: {
                layout: "horizontal" as const,
                position: "bottom" as const,
                selected: {
                    [TimingStatsCombinedTotal.COMBINED_TOTAL]: false,
                },
                itemName: {
                    formatter: (name: string) => LEGEND_NAMES[name],
                },
            },
            tooltip: {
                customContent: (title, datum: TooltipCustomContentData = []) => (
                    <ChartTooltipContent title={title} datum={datum} combinedData={combinedTotal} />
                ),
            },
            yAxis: {
                y: {
                    type: chartType,
                    tickCount: 8,
                    title: {
                        text: "Number of Events",
                    },
                    label: {
                        formatter: labelFormatter,
                    },
                },
                count: {
                    title: {
                        text: "Cumulative %",
                        rotate: Math.PI / 2,
                    },
                },
            },
            geometryOptions: [
                {
                    geometry: "column",
                    isGroup: true,
                    seriesField: "type",
                    color: Object.values(COLUMNS_COLORS),
                },
                {
                    geometry: "line",
                    seriesField: "name",
                    lineStyle: {
                        lineWidth: 2,
                    },
                    point: {
                        shape: "circle",
                    },
                    color: Object.values(LINES_COLORS),
                },
            ],
        } as DualAxesConfig;
    }, [timingStatsData, region, chartType]);

    return { config };
};
