import { Form } from "antd";
import { useMemo } from "react";
import { ColumnType } from "antd/lib/table";
import { useUserAccess } from "@app/core/auth";
import { NONE } from "@app/core/components/constants";
import { FinanceDefault, Seat, useGetSeatFinanceDefaultsQuery } from "@app/core/services";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

const { useWatch } = Form;

export const financeDefaultsColumn: ColumnType<FinanceDefault>[] = [
    {
        width: "25%",
        key: "pricing",
        title: "Pricing",
        fixed: "left" as const,
        render: (record: FinanceDefault) => {
            if (record.costValuePercent) return `${record.costValuePercent}%`;
            if (record.costValueFixed) return `${record.costValueFixed / 1000} CPM`;
            return NONE;
        },
        sorter: (a, b) => Number(a?.costValuePercent) - Number(b?.costValuePercent),
    },
    {
        dataIndex: ["costModel", "name"],
        key: "pricingModel",
        title: "Pricing Model",
        sorter: (a, b) => String(a?.costModel?.name).localeCompare(String(b?.costModel?.name)),
    },
    {
        dataIndex: ["adSourceType", "name"],
        key: "type",
        title: "Type",
        fixed: "right" as const,
        sorter: (a, b) => String(a?.adSourceType?.name).localeCompare(String(b?.adSourceType?.name)),
    },
];

interface UseFinancialInternalOnlyProps {
    edit: boolean;
    seat: Seat;
}

interface UseFinancialInternalOnly {
    isFetching: boolean;
    financeDefaults: FinanceDefault[];
    shownFinanceDefaultsTable: boolean;
    affiliateCostEnabledShown: boolean;
    affiliateCostModelValueShown: boolean;
}

export const useFinancialInternalOnly = ({ seat, edit }: UseFinancialInternalOnlyProps): UseFinancialInternalOnly => {
    const { isTremorUser } = useUserAccess();
    const { data: financeDefaults = [], isFetching } = useGetSeatFinanceDefaultsQuery(seat.id);
    const isAffiliateCostEnabled = useWatch(INVENTORY_FORM_FIELDS.AFFILIATE_COST_ENABLED.name);

    const affiliateCostModelValueShown: boolean = useMemo<boolean>(() => {
        if (!edit) return seat.affiliateCostEnabled && isTremorUser;
        return isAffiliateCostEnabled && isTremorUser;
    }, [edit, isAffiliateCostEnabled, isTremorUser, seat]);

    return {
        isFetching,
        financeDefaults,
        affiliateCostModelValueShown,
        shownFinanceDefaultsTable: !edit,
        affiliateCostEnabledShown: isTremorUser,
    };
};
