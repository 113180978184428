import { FC } from "react";
import { Form, TreeSelect } from "antd";
import { ColProps } from "antd/lib/grid/col";
import { TagRender } from "@app/core/components";
import { Rule } from "rc-field-form/lib/interface";
import { TreeSelectProps } from "antd/lib/tree-select";
import { useIabCategorieTree } from "@app/features/inventory/components/FormItems/IabCategorieTree/useIabCategorieTree";

export interface IabCategorieTreeProps extends TreeSelectProps {
    name: string;
    rules?: Rule[];
    label: string;
    labelCol?: ColProps;
    wrapperCol?: ColProps;
}

export const IabCategorieTree: FC<IabCategorieTreeProps> = ({
    name,
    rules,
    label,
    labelCol,
    wrapperCol,
    placeholder,
    ...rest
}) => {
    const { isFetching, treeData } = useIabCategorieTree();
    return (
        <Form.Item name={name} rules={rules} label={label} labelCol={labelCol} wrapperCol={wrapperCol}>
            <TreeSelect
                allowClear
                showSearch
                labelInValue
                treeCheckable
                treeData={treeData}
                loading={isFetching}
                tagRender={TagRender}
                placeholder={placeholder}
                treeNodeFilterProp="title"
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                {...rest}
            />
        </Form.Item>
    );
};
