import { Layout } from "antd";
import { ClassificationsIndustriesTable } from "./ClassificationsIndustriesTable";
import { ClassificationsIndustriesTabDrawer } from "./ClassificationsIndustriesTabDrawer";

export const ClassificationsIndustriesTab = () => (
    <Layout.Content>
        <ClassificationsIndustriesTable />
        <ClassificationsIndustriesTabDrawer />
    </Layout.Content>
);
