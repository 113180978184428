import { useSeatAuthContext } from "@app/core/auth";
import { PageLoader } from "@rubicon/antd-components";
import { Button, Col, Row, Space, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { useSupplyById } from "../useSupplyById";
import { InventoryFloorHelp } from "../InventoryFloorHelp";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useSupplyFloorsTable } from "./useSupplyFloorsTable";
import { FloorsTable } from "../FloorsTable";
import { useSeatTree } from "../SeatTree/useSeatTree";
import { InventoryFloorsBreadcrumb } from "../InventoryFloorsBreadcrumb";

const InventoryFloorsSupplyFloorsPage: FC = () => {
    const { context } = useSeatAuthContext();
    const { expandKeys, setSelectedKeys } = useSeatTree();
    const { supply, isFetching: isFetchingSupply } = useSupplyById();
    const { floors, isFetching: isFetchingFloors } = useSupplyFloorsTable();
    const [isCreating, setIsCreating] = useState(false);

    useEffect(() => {
        if (context && supply) {
            expandKeys([
                `seat-${context.id}`,
                `publisher-${supply.brand.publisher.id}`,
                `brand-${supply.brand.id}`,
                `supply-${supply.id}`,
            ]);
            setSelectedKeys([`supply-${supply.id}`]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context, supply]);

    if (isFetchingSupply || !context || !supply || !floors) {
        return <PageLoader />;
    }

    const handleAddFloor = () => {
        setIsCreating(true);
    };

    const handleCancelAddingFloor = () => {
        setIsCreating(false);
    };

    const items = [
        { path: ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_HIERARCHICAL(context.id), title: context.name },
        {
            path: ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_PUBLISHER(context.id, supply.brand.publisher.id),
            title: supply.brand.publisher.name,
        },
        {
            path: ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_PUBLISHER_BRAND(context.id, supply.brand.id),
            title: supply.brand.name,
        },
        { title: supply.name },
    ];

    return (
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Row justify="space-between">
                <Col>
                    <Row>
                        <Space align="baseline">
                            <Typography.Title level={5}>Supply Floors: </Typography.Title>
                            <InventoryFloorsBreadcrumb items={items} />
                        </Space>
                    </Row>
                    <Typography.Text>Pricing rules for this supply below: </Typography.Text>
                </Col>
                <Col style={{ alignSelf: "flex-end" }}>
                    <Space size="large">
                        <InventoryFloorHelp />
                        <Button type="primary" onClick={handleAddFloor}>
                            Add Floor
                        </Button>
                    </Space>
                </Col>
            </Row>
            <FloorsTable
                data={floors}
                isFetching={isFetchingFloors}
                isCreating={isCreating}
                onCancelCreating={handleCancelAddingFloor}
                sourceSelf="Supply"
            />
        </Space>
    );
};

export default InventoryFloorsSupplyFloorsPage;
