import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useUserAccess } from "@app/core/auth";
import { SEARCH } from "@app/core/components";
import { DEFAULT_PAGE_SIZE, NONE, PAGE_SIZE_OPTIONS } from "@app/core/components/constants";
import { ROUTES, ROUTE_FORMATTERS } from "@app/core/routing";
import {
    IabCategory,
    SeparationGroup,
    useDeleteSeatSeparationGroupsMutation,
    useGetSeatSeparationGroupsQuery,
} from "@app/core/services";
import { Button, Table, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC } from "react";
import { Link, Route, useHistory, useLocation, useParams } from "react-router-dom";
import { BrandSafetyDeleteModal } from "../BrandSafetyDeleteModal";
import { useBrandSafetyDeleteModal } from "../BrandSafetyDeleteModal/useBrandSafetyDeleteModal";
import { CompetitiveSeparationGroupsDetailsDrawer } from "./CompetitiveSeparationGroupsDetailsDrawer";
import { COLUMN_NAMES, TYPE } from "./constants";

export interface DeleteCandidate {
    id: number;
    name: string;
}

interface TableActionsProps {
    candidate: DeleteCandidate;
    handleEdit: (id: number) => void;
    handleDeleteModal: (candidate: DeleteCandidate) => void;
}

const TableActions: FC<TableActionsProps> = ({ candidate, handleEdit, handleDeleteModal }) => {
    return (
        <Button.Group>
            <Tooltip title="Edit">
                <Button icon={<EditOutlined />} onClick={() => handleEdit(candidate.id)} />
            </Tooltip>
            <Tooltip title="Delete">
                <Button icon={<DeleteOutlined />} onClick={() => handleDeleteModal(candidate)} />
            </Tooltip>
        </Button.Group>
    );
};

const columns = (
    seatId: string,
    canEditSeat: boolean,
    handleEdit: (id: number) => void,
    handleDeleteModal: (candidate: DeleteCandidate) => void,
    search: string
) =>
    [
        {
            title: "Name",
            dataIndex: COLUMN_NAMES.NAME,
            key: COLUMN_NAMES.NAME,
            width: "33%",
            render: (name: string, { id }) => (
                <Link
                    to={{
                        pathname: ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_DETAILS_DRAWER(
                            seatId,
                            id
                        ),
                        search,
                    }}
                >
                    {name}
                </Link>
            ),
            defaultSortOrder: "ascend",
            sorter: (a: SeparationGroup, b: SeparationGroup) => a.name.localeCompare(b.name),
        },
        {
            title: "Type",
            dataIndex: COLUMN_NAMES.TYPE,
            key: COLUMN_NAMES.TYPE,
            width: "33%",
            sorter: (a: SeparationGroup, b: SeparationGroup) => {
                const aType = a.industries.length ? TYPE.INDUSTRIES : TYPE.IAB_CATEGORIES;
                const bType = b.industries.length ? TYPE.INDUSTRIES : TYPE.IAB_CATEGORIES;
                return aType.localeCompare(bType);
            },
            render: (_: unknown, { industries }: SeparationGroup) => {
                return industries.length ? TYPE.INDUSTRIES : TYPE.IAB_CATEGORIES;
            },
        },
        {
            title: "Elements",
            dataIndex: COLUMN_NAMES.ELEMENTS,
            key: COLUMN_NAMES.ELEMENTS,
            width: "33%",
            render: (_: IabCategory[], { industries, iabCategories }: SeparationGroup) => {
                if (!iabCategories.length && !industries.length) {
                    return <>{NONE}</>;
                }
                return [...iabCategories, ...industries].map(({ id, name }) => <Tag key={id}>{name}</Tag>);
            },
        },
        canEditSeat && {
            title: "Actions",
            dataIndex: COLUMN_NAMES.ACTIONS,
            key: COLUMN_NAMES.ACTIONS,
            render: (_: unknown, { id, name }) => (
                <TableActions candidate={{ id, name }} handleEdit={handleEdit} handleDeleteModal={handleDeleteModal} />
            ),
        },
    ].filter(Boolean) as ColumnsType<SeparationGroup>;

const getTableRows = (data: SeparationGroup[] | undefined, search: string) =>
    (data || []).filter(({ name }) => name.toLocaleLowerCase().includes(search.toLocaleLowerCase()));

export const CompetitiveSeparationGroupsPage = () => {
    const { canEditSeat } = useUserAccess();
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search).get(SEARCH.key) || "";
    const { data, isFetching } = useGetSeatSeparationGroupsQuery({ seatId: Number(seatId) });
    const [deleteTrigger, { isLoading: isDeleting }] = useDeleteSeatSeparationGroupsMutation();
    const { handleOpen, ...restModalProps } = useBrandSafetyDeleteModal(deleteTrigger);

    const handleEdit = (id: number) => {
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_EDIT_PAGE(seatId, id));
    };

    return (
        <>
            <Table<SeparationGroup>
                size="small"
                loading={isFetching}
                columns={columns(seatId, canEditSeat, handleEdit, handleOpen, search)}
                dataSource={getTableRows(data, searchParams)}
                showSorterTooltip={false}
                rowKey="id"
                pagination={{
                    defaultPageSize: DEFAULT_PAGE_SIZE,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                    showSizeChanger: true,
                }}
            />

            <BrandSafetyDeleteModal isDeleting={isDeleting} {...restModalProps} />
            <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_DETAILS_DRAWER}>
                <CompetitiveSeparationGroupsDetailsDrawer />
            </Route>
        </>
    );
};
