import { FC } from "react";
import { LoadOnScrollSelect } from "@app/core/components";
import { useRunAsUserSelect } from "./useRunAsUserSelect";

interface Props {
    handleMenuClose: () => void;
}

export const RunAsUser: FC<Props> = ({ handleMenuClose }) => {
    const { search, handleChange, handleChangeSearch, options, isFetching, hasMore, loadMore } =
        useRunAsUserSelect(handleMenuClose);
    return (
        <LoadOnScrollSelect
            filterOption={false}
            hasMore={hasMore}
            loading={isFetching}
            loadMore={loadMore}
            onChange={handleChange}
            onSearch={handleChangeSearch}
            options={options}
            placeholder="e.g. name or email"
            searchValue={search}
            showSearch
            value={null}
        />
    );
};
