import { Form } from "antd";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { useModeSelect } from "./useModeSelect";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";

export const ModeSelect = () => {
    const { isLoading, blockingModes, handleChange } = useModeSelect();
    return (
        <Form.Item {...VERTICAL_LAYOUT_FIX} label={AD_SOURCE_FIELDS.MODE.label} name={AD_SOURCE_FIELDS.MODE.name}>
            <SelectWithValue
                onChange={handleChange}
                placeholder="Select…"
                options={blockingModes}
                loading={isLoading}
                fieldAsLabel={["name"]}
                fieldAsValue={["id"]}
            />
        </Form.Item>
    );
};
