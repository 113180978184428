import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import cloneDeep from "lodash/cloneDeep";
import {
    BuyerDeal,
    DealList,
    DemandDeal,
    useCreateAdSourceMutation,
    useCreateSeatDealMutation,
    useGetTimeZonesQuery,
    useUpdateBuyerDealMutation,
} from "@app/core/services";
import { useHistory, useParams } from "react-router-dom";
import { notification } from "antd";
import {
    AdSource,
    AdSourcePayload,
    Currency,
    DealAdSourceCreatePayload,
    DealClassAnnotation,
    DealCreatePayload,
    DealStatus,
    SeatAdSourcesStatus,
    Targeting,
    TargetingCreatePayload,
    useCreateSeatDealAdSourceMutation,
    useDeleteDealMutation,
    useGetAdSourceByIdQuery,
    useGetBuyerDealsPricingTypesQuery,
    useGetBuyerDealsTransactionTypesQuery,
    useGetCurrenciesConversionsQuery,
    useGetCurrenciesQuery,
    useGetSeatFinanceDefaultsQuery,
    useGetSeatSelfQuery,
    useGetSeatThirdPartyPixelsQuery,
    useGetTransparencyDefaultsQuery,
    useUpdateAdSourceMutation,
    useUpdateDemandDealMutation,
} from "@app/core/services/console";
import {
    AUDIENCE_LOCK_AD_SOURCE_ENABLED_ID,
    CREATE_AD_SOURCE_TYPES_ADD,
    CREATE_AD_SOURCE_TYPES_COPY,
    CREATE_DEAL_FORM_ITEMS_NAME,
    DEAL_TYPES,
    PRICE_MODELS,
} from "@app/features/deals/constants";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { TargetingFormKeys } from "@app/features/targeting/constants";
import { useTargetingForm } from "@app/features/targeting/useTargetingForm";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import {
    dealFormAdSourceSetDefaultTransparency,
    dealFormAdSourceSetValues,
    dealFormDateFieldChange,
    dealFormInputFieldChange,
    dealFormRadioGroupChange,
    dealFormResetField,
    dealFormSelectFieldChange,
    dealFormSetAsInitialized,
    dealFormSetValues,
} from "@app/features/deals/DealForm/reducer";
import { DealFormMode, DealType } from "@app/features/deals/DealForm/types";
import { useSeatAuthContext, useUserAccess } from "@app/core/auth";
import { getDealFreqCappingsItems, getSharedMarketplacesSeats, getTransparencyToBoolean } from "../helpers";
import {
    getApiDurationValueFromFormValue,
    getApiOverrideValueFromFormValue,
    getDealMarketplaceInfo,
    parseAdditionalAdvertiserDomainsToApi,
    parseAdvertiserDomainsToApi,
    parseFixedCpmToApi,
    parseValueToApi,
    prepareTargetingForCopy,
} from "./utils";
import { getDefaultCostModel } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/getDefaultCostModel";
import { CostModels } from "@app/features/seatAdSources/constants";
import { parseCategoryToApi, targetingToTargetingBlock } from "@app/features/targeting";
import {
    calcFinalEc,
    getAudienceEnrichmentCost,
    getAudiencesFromTargetingForm,
    getIsRangedCostDeal,
    getSegmentsFromTargetingForm,
} from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/EnrichmentCostSection/utils";
import { convertDateToTimeZone, convertLocalDateToUtcString } from "@app/core/utils/date";
import { useCooldown } from "@app/core/utils/useCooldown";
import { parseAdSourceCreatePayloadToApi } from "@app/features/deals/DealForm/utils";
import { useDealSyncFields } from "./sync/useDealSyncFields";
import moment from "moment-timezone";
import {
    convertCurrencyRoundedDownToNearestCent,
    shouldSyncGroupCpmRate,
    shouldSyncGroupEndDate,
    shouldSyncGroupStartDate,
    useSyncedFieldsProducer,
} from "@app/features/syncedFields";
import {
    isPacingTypeCustom,
    isPacingTypeEvenly,
    parsePacingToApi,
} from "@app/features/seatAdSources/SeatAdSourcesForm/utils/pacingUtils";
import { useParseEnrichmentCostFields } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/useParseEnrichmentCostFields";
import { parsePriceModelFieldsToApi } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/parsePriceModelFieldsToApi";

export const DEFAULT_UTC_TIME_ZONE_ID = 614;
export const DEFAULT_USD_CURRENCY_ID = 1;
export const DEFAULT_ACTIVE_STATUS_ID = 1;
export const DEFAULT_AD_SOURCE_RUNNING_STATUS_ID = 1;
export const DEFAULT_FIXED_PRICING_MODEL_ID = PRICE_MODELS.FIXED;
export const DEFAULT_RESET_RATE_ID = 1;
export const DEFAULT_TIER_RESET_MODE_ID = 1;
export const DEFAULT_TIER_OVERFLOW_ALLOWED = false;
export const DEFAULT_AD_SOURCE_SEND_FLOOR = true;
export const DEFAULT_AD_SOURCE_STRICT_MODE = false;
export const DEFAULT_CONFIGURE_AD_SOURCE = false;
export const DEFAULT_CONSENT = false;
export const DEFAULT_AD_SOURCE_AUTOSCALE = false;
export const DEFAULT_AD_SOURCE_PACING_REDISTRIBUTE = false;
export const DEFAULT_AD_SOURCE_COST_MODEL_ID = 1;
export const DEFAULT_AD_SOURCE_AUCTION_TYPE = 1;
export const DEFAULT_AD_SOURCE_FLOOR_TYPE = 1;
export const DEFAULT_AD_SOURCE_PRIRITY_ID = 1;
export const DEFAULT_AD_SOURCE_EXTEND_TIMEOUT = false;
//TODO AD SOURCE PACING check or remove
export const DEFAULT_AD_SOURCE_PACING_PERIOD = {
    id: 3,
    label: "Flight",
};
export const DEFAULT_AD_SOURCE_AD_RESPONSE_PRICE_OVERRIDE_TYPE_ID = 1;
export const DEAL_DEFAULT_FREQUENCY_CAPPING_ID = 1;
export const DEAL_DEFAULT_ENFORCEMENT_ID = 3;
export const DEAL_TRANSACTION_TYPE_GUARANTEED_ID = 1;
export const DEAL_TRANSACTION_TYPE_NON_RESERVED_ID = 2;
export const DEAL_PRICING_TYPE_AUCTION_PRICE = 2;
export const DEAL_ORIGIN_PUB_DIRECT_ID = 1;

export const AD_SOURCE_COST_MODEL_FIXED = 2;
export const IS_GOOGLE_DEAL = 3;
export const PROGRAMMATIC_GUARANTEED_AD_SOURCE_TYPE_ID = 6;
export const AUCTION_PRICE_AD_SOURCE_TYPE_ID = 3;
export const FIXED_PRICE_AD_SOURCE_TYPE_ID = 2;

export const getDealType = (deal: DealCreatePayload): "AuctionPackage" | "Buyer" | "Demand" => {
    if (("format" in deal && deal.format) || ("mediumType" in deal && deal.mediumType)) {
        return "AuctionPackage";
    }
    if ("buyerSeats" in deal && deal.buyerSeats && deal.buyerSeats.length) {
        return "Buyer";
    }
    return "Demand";
};

const getDealClassAnnotation = (deal: DealCreatePayload): DealClassAnnotation => {
    const dealType = getDealType(deal);
    return `com.tremorvideo.ssp.platform.model.MarketPlaceInfo${dealType}Deal` as const;
};

export const getDemandFees = (
    targetingPayloadsByFormKey: {
        adSource: TargetingCreatePayload[];
        adSourceAdditionalTargeting: TargetingCreatePayload[];
        deal: TargetingCreatePayload[];
        dealAdditionalTargeting: TargetingCreatePayload[];
    },
    dealType: DealType
) => {
    const adSourceTargetingBlocks =
        (targetingPayloadsByFormKey[TargetingFormKeys.Deal] as Targeting[])?.map(targetingToTargetingBlock) || [];
    const dealTargetingBlocks =
        (targetingPayloadsByFormKey[TargetingFormKeys.DealAdditionalTargeting] as Targeting[])?.map(
            targetingToTargetingBlock
        ) || [];
    const adSourceAudiences = getAudiencesFromTargetingForm(adSourceTargetingBlocks);
    const adSourceSegments = getSegmentsFromTargetingForm(adSourceTargetingBlocks);
    const dealAudiences = getAudiencesFromTargetingForm(dealTargetingBlocks);
    const dealSegments = getSegmentsFromTargetingForm(dealTargetingBlocks);
    const isRangedCost = getIsRangedCostDeal(dealType);
    const adSourceEc = getAudienceEnrichmentCost(adSourceAudiences, adSourceSegments, isRangedCost, 1);
    const dealEc = getAudienceEnrichmentCost(dealAudiences, dealSegments, isRangedCost, 1);
    const finalEc = calcFinalEc(adSourceEc, dealEc);
    const adSourceLevelDemandFee = typeof adSourceEc === "number" ? (adSourceEc ? adSourceEc * 1000 : null) : null;
    const dealLevelDemandFee = typeof finalEc === "number" ? (finalEc ? finalEc * 1000 : null) : null;

    return {
        adSourceLevelDemandFee,
        dealLevelDemandFee,
    };
};

export const getDemandAcquisitionCost = (
    isDemandAcquisitionEnabled: boolean,
    demandAcquisitionCostFormValue: number | string | null | undefined
) => {
    if (!isDemandAcquisitionEnabled) {
        return null;
    }
    if (
        demandAcquisitionCostFormValue === undefined ||
        demandAcquisitionCostFormValue === null ||
        demandAcquisitionCostFormValue === ""
    ) {
        return null;
    }
    return Number(demandAcquisitionCostFormValue) * 1000;
};

export const useDealForm = (mode: DealFormMode, dealAdSource?: DealList) => {
    const isDealFormInitialized = useAppSelector((state) => state.dealForm.isInitialized);
    const isDealFormInitializedRef = useRef(isDealFormInitialized);
    const initialAdSourcePayloadCompare = useRef<string>();
    const [isSubmitConfirmationModalOpen, setIsSubmitConfirmationModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [form] = useForm();

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const deal = dealAdSource?.deal;
    const dealListSources = dealAdSource?.dealListSources;
    const adSourceLen = dealListSources?.length;
    const hasAttachedAdSource = !!adSourceLen;
    const isSingleAdSource = adSourceLen === 1;
    const adSourceId = isSingleAdSource ? dealListSources?.[0]?.id : null;

    const dispatch = useAppDispatch();
    const history = useHistory();

    const { hasInternalAccess, showAdResponsePriceOverride, showFallbackOpportunity } = useUserAccess();
    const { context } = useSeatAuthContext();
    const { seatId, dealId: paramsDealId } = useParams<{ seatId?: string; dealId?: string }>();

    const { refetch: refetchSeatSelf } = useGetSeatSelfQuery(Number(seatId));
    const { data: seatFinanceDefaults } = useGetSeatFinanceDefaultsQuery(Number(seatId));
    const { data: seatTransparencyDefaults } = useGetTransparencyDefaultsQuery(Number(seatId));
    const { data: seatThirdPartyPixels } = useGetSeatThirdPartyPixelsQuery(Number(seatId));
    const { data: pricingTypes } = useGetBuyerDealsPricingTypesQuery();
    const { data: transactionTypes } = useGetBuyerDealsTransactionTypesQuery();
    const { data: timeZones } = useGetTimeZonesQuery();
    const { data: currencies } = useGetCurrenciesQuery();
    const { data: currencyConversions } = useGetCurrenciesConversionsQuery();

    const [createSeatDeal] = useCreateSeatDealMutation();
    const [createSeatDealAdSource] = useCreateSeatDealAdSourceMutation();
    const [updateBuyerSeatDeal] = useUpdateBuyerDealMutation();
    const [updateDemandSeatDeal] = useUpdateDemandDealMutation();
    const [deleteDeal, { isLoading: isDeleting }] = useDeleteDealMutation();
    const [updateAdSource] = useUpdateAdSourceMutation();
    const [createAdSource] = useCreateAdSourceMutation();
    const { targetingPayloadsByFormKey, targetingModePayloadsByFormKey, loadTargeting, loadValidationTargeting } =
        useTargetingForm(TargetingFormKeys.Deal);

    const { updateSyncedFields } = useSyncedFieldsProducer();
    const { parseEnrichmentCostFieldsToApi } = useParseEnrichmentCostFields();

    const values = useAppSelector((state) => state.dealForm.values);
    const {
        dealConfigureAdSource,
        dealConsent,
        dealType,
        dealPriceType,
        dealTransactionType,
        dealId,
        dealName,
        dealStatus,
        dealOrigin,
        dealTimeZone: dealTimeZoneId,
        dealDsp,
        dealBuyer,
        dealStartDate,
        dealEndDate,
        dealRate,
        dealImpression,
        dealCurrency,
        dealPricingModel,
        adSourceDemandAcquisitionCost,
        adSourceDemandAcquisitionCostModel,
        adSourceModeConfiguration,
        adSourceCopyId,
        adSourceName,
        adSourceDescription,
        adSourcePriority,
        adSourceRegion,
        adSourceCost,
        adSourceCostModel,
        adSourceAssignee,
        adSourceAutoscale,
        adSourceCurrency,
        adSourceSalesContact,
        adSourceExternalContactNumber,
        adSourceExtendTimeout,
        adSourceMode,
        adSourceReusableAdvertiserDomain,
        adSourceAdditionalAdvertiserDomain,
        adSourcePacingType,
        adSourcePacingRedistribute,
        adSourcePacingCustomCurveStartDate,
        adSourcePacingCustomCurveImps,
        adSourcePacingImpressionCap,
        adSourcePacingFallback,
        adSourceLabels,
        adSourceStrictMode,
        adSourceAuctionType,
        adSourceFloorType,
        adSourceBookingPrice,
        adSourceAlwaysSendFloor,
        adSourceRunUnderInboundFloor,
        adSourceAllowSiteName,
        adSourceAllowDomainName,
        adSourceDomainNameOverride,
        adSourceAllowSitePage,
        adSourceAllowRef,
        adSourceAllowIp,
        adSourceAllowBundleId,
        adSourceBundleIdOverride,
        adSourceAllowStoreUrl,
        adSourceAllowUserId,
        adSourceAllowContent,
        adSourceContentTransparencyRule,
        adSourceAllowAllExtendedId,
        adSourceAllowedExtendedIdTransparencies,
        dealPublisherEmail,
        dealPublisherName,
        enforcement,
        transactionCurrency,
        frequencyCapping,
        dealFreqCappings,
        dealSharedMarketplaces,
        dealSharedSeats,
        adResponsePriceOverrideType,
        adSourceAllowAudienceLock,
        adSourceIabCategories,
        adSourceBlockExceptionIabCategories,
        adSourceIabCategoryBlockMode,
        adSourceInventoryDistributionGroups,
        adSourcePixels,
        adSourceStatus,
        adSourceTimeZone: adSourceTimeZoneId,
        adSourceBookingStartDate,
        adSourceBookingEndDate,
    } = values;

    const { data: dealAdSourceData } = useGetAdSourceByIdQuery(Number(adSourceId), {
        skip: !isSingleAdSource || !adSourceId,
    });

    const { data: dealAdSourceCopyData, isFetching: isFetchingDealAdSourceCopyData } = useGetAdSourceByIdQuery(
        Number(adSourceCopyId),
        {
            skip: !adSourceCopyId,
        }
    );

    const isProgrammaticGuaranteeDeal = dealType === DEAL_TYPES.GUARANTEE;
    const isFixedPriceDeal = dealType === DEAL_TYPES.FIXED;
    const isAuctionPriceDeal = dealType === DEAL_TYPES.AUCTION;
    const isFixedDealPricingModel = dealPricingModel === DEFAULT_FIXED_PRICING_MODEL_ID;
    const frequencyCappingItemShown = frequencyCapping !== DEAL_DEFAULT_FREQUENCY_CAPPING_ID;
    const isInternalUser = hasInternalAccess;
    const isEndUser = !hasInternalAccess;
    const hasMultipleAdSources = mode === "edit" && (adSourceLen || 0) > 1;
    const internalFieldsShowm = isInternalUser && !isProgrammaticGuaranteeDeal;

    const isCopyingAdSource = useMemo<boolean>(() => {
        return Boolean(
            adSourceCopyId &&
                adSourceModeConfiguration === CREATE_AD_SOURCE_TYPES_COPY &&
                dealAdSourceCopyData &&
                !isFetchingDealAdSourceCopyData
        );
    }, [adSourceCopyId, isFetchingDealAdSourceCopyData, dealAdSourceCopyData, adSourceModeConfiguration]);

    useEffect(() => {
        if (!adSourceCopyId || adSourceModeConfiguration !== CREATE_AD_SOURCE_TYPES_ADD) {
            loadValidationTargeting(TargetingFormKeys.DealAdditionalTargeting, []);
        }
        if (
            adSourceModeConfiguration === CREATE_AD_SOURCE_TYPES_ADD &&
            dealAdSourceCopyData &&
            !isFetchingDealAdSourceCopyData
        ) {
            loadValidationTargeting(TargetingFormKeys.DealAdditionalTargeting, dealAdSourceCopyData.targeting);
        }
    }, [
        adSourceCopyId,
        isFetchingDealAdSourceCopyData,
        dealAdSourceCopyData,
        adSourceModeConfiguration,
        loadValidationTargeting,
    ]);

    const adSourceData = isCopyingAdSource ? dealAdSourceCopyData : dealAdSourceData;
    const hasMultipleDeals = (adSourceData?.marketplaceInfoList?.length || 0) > 1;

    const dealCount = adSourceData?.marketplaceInfoList?.length;

    const dealTimeZone = useMemo(() => timeZones?.find((tz) => tz.id === dealTimeZoneId), [dealTimeZoneId, timeZones]);
    const adSourceTimeZone = useMemo(
        () => timeZones?.find((tz) => tz.id === adSourceTimeZoneId),
        [adSourceTimeZoneId, timeZones]
    );

    useDealSyncFields(
        mode,
        adSourceModeConfiguration,
        dealCount,
        dealAdSourceCopyData?.marketplaceInfoList.length,
        adSourceLen,
        dealType,
        dealPricingModel,
        adSourceFloorType,
        dealTimeZone?.code,
        adSourceTimeZone?.code,
        dealRate,
        dealCurrency,
        adSourceCurrency,
        dealStartDate,
        dealEndDate,
        currencyConversions,
        dealAdSourceData
    );

    const publisherCredentialShown = Boolean(dealBuyer?.length);

    const dealRateShown = useMemo<boolean>(() => {
        if (isFixedPriceDeal && !isFixedDealPricingModel) {
            return false;
        }
        return true;
    }, [isFixedPriceDeal, isFixedDealPricingModel]);

    const enforcementShown = <boolean>(
        useMemo(
            () =>
                (isFixedPriceDeal && isFixedDealPricingModel) ||
                (isProgrammaticGuaranteeDeal && isFixedDealPricingModel),
            [isFixedDealPricingModel, isProgrammaticGuaranteeDeal, isFixedPriceDeal]
        )
    );
    const configureAdSourceShown = useMemo<boolean>(() => {
        if (mode !== "create" && isInternalUser) return !hasAttachedAdSource;
        return isInternalUser;
    }, [isInternalUser, hasAttachedAdSource, mode]);

    const handleSetFormFields = useCallback(
        (dealAdSourceData: AdSource | undefined) => {
            form.setFieldsValue({
                [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MIN_DURATION]: dealAdSourceData?.adSourceMinDuration,
                [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MAX_DURATION]: dealAdSourceData?.adSourceMaxDuration,
            });
        },
        [form]
    );

    const handleCancel = () => {
        dispatch(dealFormResetField());
        if (mode === "edit") {
            history.push(ROUTE_FORMATTERS.SEAT_DEAL_DETAILS(Number(seatId), Number(paramsDealId)));
            return;
        }
        history.push(ROUTE_FORMATTERS.SEAT_DEALS(Number(seatId)));
    };
    const handleDelete = async () => {
        try {
            if (!deal?.id || !seatId) return;

            await deleteDeal(deal.id).unwrap();
            history.push(ROUTE_FORMATTERS.SEAT_DEALS(seatId));
        } catch (error) {
            notification.error({ message: "Deal Delete Error", description: error.data.errorDescription });
        }
    };
    const handleCreateSingleDeal = async (newDeal: DealCreatePayload) => {
        setIsSubmitting(true);
        try {
            const seatDeal = await createSeatDeal({ seatId: Number(seatId), body: newDeal }).unwrap();
            dispatch(dealFormResetField());

            history.push(ROUTE_FORMATTERS.SEAT_DEAL_DETAILS(Number(seatId), seatDeal.id));
        } catch (err) {
            notification.error({ message: err.data.errorDescription });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleCreateDealAdSource = async (newDeal: DealCreatePayload) => {
        setIsSubmitting(true);
        const { adSourceLevelDemandFee, dealLevelDemandFee } = getDemandFees(targetingPayloadsByFormKey, dealType);
        const marketplaceInfoList = {
            currencyType: parseValueToApi(transactionCurrency),
            dealFreqCappings: getDealFreqCappingsItems(dealFreqCappings),
            enforcement: enforcement && !isAuctionPriceDeal ? parseValueToApi(enforcement) : null,
            freqCappingType: { id: frequencyCapping },
            targeting: [...targetingPayloadsByFormKey[TargetingFormKeys.DealAdditionalTargeting]],
            demandFee: dealLevelDemandFee,
            deal: newDeal,
            "@class": getDealClassAnnotation(newDeal),
        };
        const adSourcePacingFields = parsePacingToApi(
            dealType,
            adSourcePacingType,
            adSourcePacingRedistribute,
            adSourcePacingImpressionCap,
            adSourcePacingFallback,
            adSourcePacingCustomCurveStartDate,
            adSourcePacingCustomCurveImps,
            showFallbackOpportunity
        );
        const adSourceEnrichmentCostFields = parseEnrichmentCostFieldsToApi(
            dealType,
            adSourceDemandAcquisitionCost,
            adSourceDemandAcquisitionCostModel
        );

        const body: DealAdSourceCreatePayload = {
            advertiserBlockingMode: parseValueToApi(adSourceMode),
            advertiserBlockDomains: parseAdditionalAdvertiserDomainsToApi(adSourceAdditionalAdvertiserDomain),
            seatAdvertiserDomainFilterListDefs: parseAdvertiserDomainsToApi(
                adSourceMode,
                adSourceReusableAdvertiserDomain
            ),
            description: adSourceDescription?.length ? adSourceDescription : null,
            allowSiteName: getTransparencyToBoolean(adSourceAllowSiteName),
            allowDomainName: getTransparencyToBoolean(adSourceAllowDomainName),
            domainNameOverride: getApiOverrideValueFromFormValue(adSourceDomainNameOverride),
            allowSitePage: getTransparencyToBoolean(adSourceAllowSitePage),
            allowRef: getTransparencyToBoolean(adSourceAllowRef),
            allowIp: getTransparencyToBoolean(adSourceAllowIp),
            allowBundleId: getTransparencyToBoolean(adSourceAllowBundleId),
            bundleIdOverride: getApiOverrideValueFromFormValue(adSourceBundleIdOverride),
            allowStoreUrl: getTransparencyToBoolean(adSourceAllowStoreUrl),
            allowUserId: getTransparencyToBoolean(adSourceAllowUserId),
            allowContent: getTransparencyToBoolean(adSourceAllowContent),
            contentTransparencyRule: adSourceContentTransparencyRule,
            allowAllExtendedId: getTransparencyToBoolean(adSourceAllowAllExtendedId),
            allowedExtendedIdTransparencies: adSourceAllowedExtendedIdTransparencies,
            ...parsePriceModelFieldsToApi(dealType),
            seat: { id: Number(seatId) },
            name: adSourceName ? adSourceName : dealName,
            alwaysSendFloor: adSourceAlwaysSendFloor,
            bookingPrice: parseFixedCpmToApi(
                isProgrammaticGuaranteeDeal,
                isFixedPriceDeal,
                adSourceBookingPrice,
                dealRate
            ),
            overrideDynamicFloor: adSourceRunUnderInboundFloor,
            bookingStartDate: convertDateToTimeZone(dealStartDate, dealTimeZone) as string,
            bookingEndDate: convertDateToTimeZone(dealEndDate, dealTimeZone),
            status: { id: dealStatus },
            priority: { id: adSourcePriority },
            type: { id: dealType },
            marketplaceInfoList: [marketplaceInfoList],
            bookingVolume: dealImpression,
            ...adSourcePacingFields,
            timeZone: { id: dealTimeZoneId },
            currencyType: { id: adSourceCurrency },
            thirdPartyPixels: adSourcePixels?.map((id) => ({ id })),
            adSourceMinDuration: getApiDurationValueFromFormValue(
                form.getFieldValue(CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MIN_DURATION)
            ),
            adSourceMaxDuration: getApiDurationValueFromFormValue(
                form.getFieldValue(CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MAX_DURATION)
            ),
            labelValues: adSourceLabels,
            strictMode: adSourceStrictMode,
            nurlTimeoutOverride: adSourceExtendTimeout,
            assignee: parseValueToApi(adSourceAssignee),
            region: parseValueToApi(adSourceRegion),
            costModel: parseValueToApi(adSourceCostModel),
            allowAutoscale: adSourceAutoscale,
            salesContact: parseValueToApi(adSourceSalesContact),
            externalContractId: getApiOverrideValueFromFormValue(adSourceExternalContactNumber),
            demandFee: adSourceLevelDemandFee,
            iabCategoryBlockMode: adSourceIabCategoryBlockMode ? { id: adSourceIabCategoryBlockMode } : null,
            blockedIabCategories: adSourceIabCategories?.length ? adSourceIabCategories.map(parseCategoryToApi) : [],
            blockExceptionIabCategories: adSourceBlockExceptionIabCategories?.length
                ? adSourceBlockExceptionIabCategories.map(({ value }) => ({ id: value as number }))
                : [],
            ...adSourceEnrichmentCostFields,
            inventoryDistributionGroups: adSourceInventoryDistributionGroups,
            adResponsePriceOverrideType: adResponsePriceOverrideType
                ? { id: adResponsePriceOverrideType }
                : { id: DEFAULT_AD_SOURCE_AD_RESPONSE_PRICE_OVERRIDE_TYPE_ID },
        };

        if (adSourceCostModel === DEFAULT_AD_SOURCE_COST_MODEL_ID) {
            Object.assign(body, {
                costValuePercent: adSourceCost,
            });
        }

        if (adSourceCostModel === AD_SOURCE_COST_MODEL_FIXED) {
            Object.assign(body, {
                costValueFixed: (adSourceCost || 0) * 1000,
            });
        }

        if (isAuctionPriceDeal) {
            Object.assign(body, {
                auctionType: {
                    id: adSourceAuctionType,
                },
                adSourceFloorType: {
                    id: adSourceFloorType,
                },
            });
        }

        try {
            body.targeting = targetingPayloadsByFormKey[TargetingFormKeys.Deal];
            body.targetingMode = targetingModePayloadsByFormKey[TargetingFormKeys.Deal];

            const seatDeal = await createSeatDealAdSource({ seatId: Number(seatId), body }).unwrap();
            dispatch(dealFormResetField());

            history.push(ROUTE_FORMATTERS.SEAT_DEAL_DETAILS(Number(seatId), seatDeal.id));
        } catch (err) {
            notification.error({ message: err.data.errorDescription });
        } finally {
            setIsSubmitting(false);
        }
    };

    const buildAdSourcePayload = (deal: DealCreatePayload): Partial<AdSourcePayload> | null => {
        if (!hasAttachedAdSource) {
            return null;
        }

        const { adSourceLevelDemandFee, dealLevelDemandFee } = getDemandFees(targetingPayloadsByFormKey, dealType);

        const marketplaceInfoList = (dealAdSourceData?.marketplaceInfoList || []).map((marketplaceInfo) => {
            if (deal?.code !== marketplaceInfo.deal?.code) {
                return marketplaceInfo;
            }

            const newDeal = { ...marketplaceInfo.deal, ...deal };
            return {
                ...marketplaceInfo,
                currencyType: parseValueToApi(transactionCurrency),
                targeting: [...targetingPayloadsByFormKey[TargetingFormKeys.DealAdditionalTargeting]],
                dealFreqCappings: getDealFreqCappingsItems(dealFreqCappings),
                enforcement: enforcement && !isAuctionPriceDeal ? parseValueToApi(enforcement) : null,
                freqCappingType: { id: frequencyCapping },
                deal: newDeal,
                demandFee: dealLevelDemandFee,
                "@class": getDealClassAnnotation(newDeal),
            };
        });
        const adSourceEnrichmentCostFields = parseEnrichmentCostFieldsToApi(
            dealType,
            adSourceDemandAcquisitionCost,
            adSourceDemandAcquisitionCostModel
        );

        const adSourcePayload = {
            ...dealAdSourceData,
            // Ad Source Demand Configuration section
            marketplaceInfoList,

            // Deal Pricing Section
            ...parsePriceModelFieldsToApi(dealType),

            // Ad Source General section
            name: adSourceName,
            description: adSourceDescription || "",
            nurlTimeoutOverride: adSourceExtendTimeout,
            assignee: parseValueToApi(adSourceAssignee),
            priority: { id: adSourcePriority },

            // Ad Source Admin section
            costModel: parseValueToApi(adSourceCostModel),
            allowAutoscale: adSourceAutoscale,
            region: parseValueToApi(adSourceRegion),
            salesContact: parseValueToApi(adSourceSalesContact),
            externalContractId: getApiOverrideValueFromFormValue(adSourceExternalContactNumber),

            // Ad Source Floor Settings section
            bookingPrice: adSourceBookingPrice ? adSourceBookingPrice * 1000 : null,
            alwaysSendFloor: adSourceAlwaysSendFloor,
            overrideDynamicFloor: adSourceRunUnderInboundFloor,
            adResponsePriceOverrideType: adResponsePriceOverrideType
                ? { id: adResponsePriceOverrideType }
                : { id: DEFAULT_AD_SOURCE_AD_RESPONSE_PRICE_OVERRIDE_TYPE_ID },

            // Ad Source Pacing section
            ...parsePacingToApi(
                dealType,
                adSourcePacingType,
                adSourcePacingRedistribute,
                adSourcePacingImpressionCap,
                adSourcePacingFallback,
                adSourcePacingCustomCurveStartDate,
                adSourcePacingCustomCurveImps,
                showFallbackOpportunity
            ),

            // Ad Source Delivery Details section
            adSourceMinDuration: getApiDurationValueFromFormValue(
                form.getFieldValue(CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MIN_DURATION)
            ),
            adSourceMaxDuration: getApiDurationValueFromFormValue(
                form.getFieldValue(CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MAX_DURATION)
            ),
            bookingStartDate: convertDateToTimeZone(adSourceBookingStartDate, adSourceTimeZone),
            bookingEndDate: convertDateToTimeZone(adSourceBookingEndDate, adSourceTimeZone),
            timeZone: { id: adSourceTimeZoneId },
            inventoryDistributionGroups: adSourceInventoryDistributionGroups,

            // Ad Source Brand Safety section
            advertiserBlockingMode: parseValueToApi(adSourceMode),
            seatAdvertiserDomainFilterListDefs: parseAdvertiserDomainsToApi(
                adSourceMode,
                adSourceReusableAdvertiserDomain
            ),
            advertiserBlockDomains: parseAdditionalAdvertiserDomainsToApi(adSourceAdditionalAdvertiserDomain),

            // Ad Source Custom Pixels section
            thirdPartyPixels: adSourcePixels.map((id) => ({ id })),

            // Ad Source Transparency section
            allowSiteName: getTransparencyToBoolean(String(adSourceAllowSiteName)),
            allowDomainName: getTransparencyToBoolean(String(adSourceAllowDomainName)),
            domainNameOverride: getApiOverrideValueFromFormValue(adSourceDomainNameOverride),
            allowSitePage: getTransparencyToBoolean(adSourceAllowSitePage),
            allowRef: getTransparencyToBoolean(adSourceAllowRef),
            allowIp: getTransparencyToBoolean(adSourceAllowIp),
            allowBundleId: getTransparencyToBoolean(adSourceAllowBundleId),
            bundleIdOverride: getApiOverrideValueFromFormValue(adSourceBundleIdOverride),
            allowStoreUrl: getTransparencyToBoolean(adSourceAllowStoreUrl),
            allowUserId: getTransparencyToBoolean(adSourceAllowUserId),
            allowAllExtendedId: getTransparencyToBoolean(adSourceAllowAllExtendedId),
            allowContent: getTransparencyToBoolean(adSourceAllowContent),
            allowedExtendedIdTransparencies: adSourceAllowedExtendedIdTransparencies,
            contentTransparencyRule: adSourceContentTransparencyRule,
            allowAudienceLock:
                context?.audienceLockType?.id === AUDIENCE_LOCK_AD_SOURCE_ENABLED_ID ? adSourceAllowAudienceLock : null,

            // Ad Source Label section
            labelValues: adSourceLabels,
            strictMode: adSourceStrictMode,

            targeting: [...targetingPayloadsByFormKey[TargetingFormKeys.Deal]],
            targetingMode: targetingModePayloadsByFormKey[TargetingFormKeys.Deal],

            //Ad Source Enrichment Cost section
            demandFee: adSourceLevelDemandFee,
            iabCategoryBlockMode: adSourceIabCategoryBlockMode ? { id: adSourceIabCategoryBlockMode } : null,
            blockedIabCategories: adSourceIabCategories?.length ? adSourceIabCategories.map(parseCategoryToApi) : null,
            blockExceptionIabCategories: adSourceBlockExceptionIabCategories?.length
                ? adSourceBlockExceptionIabCategories.map(({ value }) => ({ id: value as number }))
                : [],
            ...adSourceEnrichmentCostFields,
        };

        if (adSourceCostModel === DEFAULT_AD_SOURCE_COST_MODEL_ID) {
            Object.assign(adSourcePayload, {
                costValuePercent: adSourceCost,
            });
        }
        if (adSourceCostModel === AD_SOURCE_COST_MODEL_FIXED) {
            Object.assign(adSourcePayload, {
                costValueFixed: (adSourceCost || 0) * 1000,
            });
        }
        if (isAuctionPriceDeal) {
            Object.assign(adSourcePayload, {
                auctionType: {
                    id: adSourceAuctionType,
                },
                adSourceFloorType: {
                    id: adSourceFloorType,
                },
            });
        }

        const hasLoadedSingleDeal = marketplaceInfoList.length === 1 && marketplaceInfoList[0].deal;
        if (
            (isProgrammaticGuaranteeDeal && hasLoadedSingleDeal) ||
            (isFixedPriceDeal && hasLoadedSingleDeal && !hasMultipleDeals) ||
            (isAuctionPriceDeal && hasLoadedSingleDeal && !hasMultipleDeals)
        ) {
            Object.assign(adSourcePayload, {
                currencyType: marketplaceInfoList[0].deal.currencyType,
            });
        }

        if (showAdResponsePriceOverride && adResponsePriceOverrideType) {
            Object.assign(adSourcePayload, {
                adResponsePriceOverrideType: {
                    id: adResponsePriceOverrideType,
                },
            });
        }

        // TODO: Fix all incompatible properties
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return adSourcePayload;
    };

    const getDealUpdateMutation = () => {
        if (deal?.entityType === "DemandDeal") {
            return updateDemandSeatDeal;
        }
        return updateBuyerSeatDeal;
    };

    const handleEdit = async (newDeal: DealCreatePayload, adSourceOnlyPayload: Partial<AdSourcePayload>) => {
        const adSourcePayload = buildAdSourcePayload(newDeal);
        setIsSubmitting(true);

        try {
            const updateDeal = getDealUpdateMutation();

            try {
                await updateDeal({ id: Number(paramsDealId), body: newDeal }).unwrap();
            } catch (err) {
                notification.error({
                    message: "Deal Update Error",
                    description: err?.data?.errorDescription || "Error while updating Deal",
                });
                return;
            }

            if (adSourcePayload && dealAdSourceData && isSingleAdSource) {
                try {
                    // TODO: Remove when PUT endpoint allows synthesizedAdSourceStatus
                    const { synthesizedAdSourceStatus: _, ...rest } = adSourcePayload;
                    const body = {
                        ...rest,
                        ...adSourceOnlyPayload,
                    };
                    await updateAdSource({ id: Number(dealAdSourceData?.id), body }).unwrap();
                } catch (err) {
                    notification.error({
                        message: "Ad Source Update Error",
                        description: err?.data?.errorDescription || "Error while updating Ad Source",
                    });
                    return;
                }
            }

            dispatch(dealFormResetField());
            history.push(ROUTE_FORMATTERS.SEAT_DEAL_DETAILS(Number(seatId), Number(paramsDealId)));
        } catch (err) {
            notification.error({ message: err?.data?.errorDescription || "Error while updating" });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleCreateDealAndAddToExistingAdSource = async (
        newDeal: DealCreatePayload,
        adSource: AdSource | undefined
    ) => {
        if (!adSource) {
            return;
        }
        setIsSubmitting(true);
        const { adSourceLevelDemandFee, dealLevelDemandFee } = getDemandFees(targetingPayloadsByFormKey, dealType);

        try {
            const deal = await createSeatDeal({ seatId: Number(seatId), body: newDeal }).unwrap();

            const _dealTypeId = deal.dealType?.id;
            const _dealRate = deal.rate;
            const _dealCurrencyId = deal.currencyType.id;
            const _dealPriceModelId = deal.priceModel?.id;
            const _dealTimeZoneCode = deal.timeZone.code;

            const _adSourceBookingPrice = adSource.bookingPrice / 1000;
            const _adSourceCurrencyId = adSource.currencyType.id;
            const _adSourceBookingStartDate = adSource.bookingStartDate;
            const _adSourceBookingEndDate = adSource.bookingEndDate;
            const _adSourceTimeZoneCode = adSource.timeZone?.code;

            const _isOneToMany = adSource.marketplaceInfoList.length >= 1;

            const _syncedFields = {
                bookingPrice: adSource.bookingPrice,
                bookingStartDate: adSource.bookingStartDate,
                bookingEndDate: adSource.bookingEndDate as string | null,
            };

            if (
                shouldSyncGroupCpmRate(
                    _isOneToMany,
                    _dealTypeId,
                    _dealPriceModelId,
                    _dealRate,
                    _dealCurrencyId,
                    undefined,
                    undefined,
                    _adSourceBookingPrice,
                    _adSourceCurrencyId,
                    currencyConversions
                )
            ) {
                _syncedFields.bookingPrice =
                    Number(
                        convertCurrencyRoundedDownToNearestCent(
                            _dealRate,
                            _dealCurrencyId,
                            _adSourceCurrencyId,
                            currencyConversions
                        )
                    ) * 1000;
            }

            const hasAllStartDateInfo = dealStartDate && _dealTimeZoneCode && _adSourceTimeZoneCode;
            if (
                hasAllStartDateInfo &&
                shouldSyncGroupStartDate(
                    _isOneToMany,
                    _dealTypeId,
                    moment(dealStartDate).tz(_dealTimeZoneCode, true),
                    _dealTimeZoneCode,
                    undefined,
                    undefined,
                    moment(_adSourceBookingStartDate),
                    undefined
                )
            ) {
                _syncedFields.bookingStartDate = convertLocalDateToUtcString(
                    dealStartDate,
                    _dealTimeZoneCode,
                    _adSourceTimeZoneCode
                ) as string;
            }

            const hasAllEndDateInfo = _dealTimeZoneCode && _adSourceTimeZoneCode;
            if (
                hasAllEndDateInfo &&
                shouldSyncGroupEndDate(
                    _isOneToMany,
                    _dealTypeId,
                    dealEndDate ? moment(dealEndDate).tz(_dealTimeZoneCode, true) : null,
                    _dealTimeZoneCode,
                    undefined,
                    undefined,
                    _adSourceBookingEndDate ? moment(_adSourceBookingEndDate) : null,
                    undefined
                )
            ) {
                _syncedFields.bookingEndDate = convertLocalDateToUtcString(
                    dealEndDate,
                    _dealTimeZoneCode,
                    _adSourceTimeZoneCode
                );
            }

            const body: Partial<AdSourcePayload> = {
                ...adSource,
                demandFee: adSourceLevelDemandFee,
                bookingPrice: _syncedFields.bookingPrice,
                bookingStartDate: _syncedFields.bookingStartDate,
                bookingEndDate: _syncedFields.bookingEndDate,
                marketplaceInfoList: [
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ...adSource.marketplaceInfoList,
                    {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        targeting: [...targetingPayloadsByFormKey[TargetingFormKeys.DealAdditionalTargeting]],
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        currencyType: parseValueToApi(transactionCurrency),
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        dealFreqCappings: getDealFreqCappingsItems(dealFreqCappings),
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        enforcement: enforcement && !isAuctionPriceDeal ? parseValueToApi(enforcement) : null,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        freqCappingType: { id: frequencyCapping },
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        deal,
                        "@class": getDealClassAnnotation(newDeal),
                        demandFee: dealLevelDemandFee,
                    },
                ],
            };

            // TODO: Remove when PUT endpoint accepts synthesizedAdSourceStatus
            const { synthesizedAdSourceStatus: _, ...rest } = body;
            await updateAdSource({ id: Number(adSource?.id), body: rest }).unwrap();
            dispatch(dealFormResetField());

            history.push(ROUTE_FORMATTERS.SEAT_DEAL_DETAILS(Number(seatId), deal.id));
        } catch (err) {
            notification.error({ message: err?.data?.errorDescription || "Something went wrong" });
        } finally {
            setIsSubmitting(false);
        }
    };

    const buildDealCreatePayload = (): DealCreatePayload => {
        const payloadDealOrigin = isInternalUser
            ? { id: dealOrigin } // use deal origin if internal user
            : ["create", "copy"].includes(mode)
            ? { id: DEAL_ORIGIN_PUB_DIRECT_ID } // default to pub direct if creating / copying as external user
            : { id: dealOrigin }; // use existing deal origin if editing as an external user that cannot see the deal origin field
        const { dealPrices, priceModel } = parsePriceModelFieldsToApi(dealType);

        const deal: DealCreatePayload = {
            name: dealName,
            code: String(dealId),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dealOrigin: payloadDealOrigin,
            status: { id: dealStatus },
            sharedMarketplaces: getSharedMarketplacesSeats(dealSharedMarketplaces),
            sharedSeats: getSharedMarketplacesSeats(dealSharedSeats),
            seat: { id: Number(seatId) },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            network: { id: dealDsp, name: "" },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dealPricingType: { id: dealPriceType },
            dealPrices,
            priceModel,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dealTransactionType: { id: dealTransactionType },
            rate: dealRate,
            bookingVolume: dealImpression,
            currencyType: { id: dealCurrency },
            startTime: convertDateToTimeZone(dealStartDate, dealTimeZone) as string,
            endTime: convertDateToTimeZone(dealEndDate, dealTimeZone),
            externalName: dealName,
            externalParentName: dealName,
            timeZone: { id: dealTimeZoneId },
        };
        if (dealBuyer && dealBuyer?.length) {
            deal.buyerSeats = (dealBuyer || []).map((buyer) => JSON.parse(String(buyer.value)));
        }
        if (dealPublisherEmail) {
            deal.publisherEmail = dealPublisherEmail;
        }
        if (dealPublisherName) {
            deal.publisherName = dealPublisherName;
        }

        return deal;
    };

    const handleEditDealAndCreateAdSource = async (newDeal: DealCreatePayload) => {
        setIsSubmitting(true);
        let deal = {} as BuyerDeal | DemandDeal;
        try {
            const updateDeal = getDealUpdateMutation();
            try {
                deal = (await updateDeal({ id: Number(paramsDealId), body: newDeal }).unwrap()) as unknown as
                    | BuyerDeal
                    | DemandDeal;
            } catch (err) {
                notification.error({
                    message: "Deal Update Error",
                    description: err?.data?.errorDescription || "Error while updating Deal",
                });
                return;
            }
            try {
                const body = parseAdSourceCreatePayloadToApi({
                    values: {
                        ...values,
                        adSourceMinDuration: form.getFieldValue(CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MIN_DURATION),
                        adSourceMaxDuration: form.getFieldValue(CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MAX_DURATION),
                    },
                    seatId: Number(seatId),
                    deal,
                    targetingPayloadsByFormKey,
                    targetingModePayloadsByFormKey,
                    seat: context,
                });

                await createAdSource({ seatId: Number(seatId), body }).unwrap();
            } catch (err) {
                notification.error({
                    message: "Ad Source Create Error",
                    description: err?.data?.errorDescription || "Error while creating Ad Source",
                });
                return;
            }
            dispatch(dealFormResetField());
            history.push(ROUTE_FORMATTERS.SEAT_DEAL_DETAILS(Number(seatId), Number(deal.id)));
        } catch (err) {
            notification.error({ message: err?.data?.errorDescription || "Error while updating" });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSubmit = async () => {
        try {
            await form.validateFields();

            const newDeal = buildDealCreatePayload();
            Object.freeze(newDeal);

            if (mode === "edit" && !dealConfigureAdSource && !dealConsent && !hasAttachedAdSource) {
                handleEditDealAndCreateAdSource(newDeal);
                return;
            }

            if (mode === "edit") {
                const adSourceOnlyPayload: Partial<AdSourcePayload> = {
                    status: { id: adSourceStatus } as SeatAdSourcesStatus,
                };
                handleEdit(newDeal, adSourceOnlyPayload);
                return;
            }

            if (dealConfigureAdSource && dealConsent) {
                handleCreateSingleDeal(newDeal);
                return;
            }

            if (adSourceModeConfiguration === CREATE_AD_SOURCE_TYPES_ADD) {
                handleCreateDealAndAddToExistingAdSource(newDeal, dealAdSourceCopyData);
                return;
            }

            handleCreateDealAdSource(newDeal);
        } catch (error) {
            form.scrollToField(error.errorFields[0].name, { behavior: "smooth", block: "center" });
        }
    };

    useEffect(() => {
        refetchSeatSelf();
        dispatch(dealFormResetField());
        return () => {
            dispatch(dealFormResetField());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            ...values,
            [CREATE_DEAL_FORM_ITEMS_NAME.START_DATE]: dealStartDate && moment(dealStartDate),
            [CREATE_DEAL_FORM_ITEMS_NAME.END_DATE]: dealEndDate && moment(dealEndDate),
        });
    }, [form, values, dealStartDate, dealEndDate]);

    useEffect(() => {
        dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_COPY_ID, value: null }));
    }, [dealType, adSourceModeConfiguration, dispatch]);

    useEffect(() => {
        if (isProgrammaticGuaranteeDeal) {
            dispatch(
                dealFormSelectFieldChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_TYPE,
                    value: null,
                })
            );
        } else {
            dispatch(
                dealFormSelectFieldChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_TYPE,
                    value: null,
                })
            );
        }
    }, [isProgrammaticGuaranteeDeal, dispatch]);

    useEffect(() => {
        const isRedistribution = isProgrammaticGuaranteeDeal && isPacingTypeEvenly(adSourcePacingType);
        const isCustom = isProgrammaticGuaranteeDeal && isPacingTypeCustom(adSourcePacingType);
        const defaultTargetDates = Math.round(moment(dealEndDate).diff(dealStartDate, "days") / 2);

        const curveStartDate =
            dealStartDate && dealEndDate ? moment(dealStartDate).add(defaultTargetDates, "days") : moment();

        if (mode === "create") {
            dispatch(
                dealFormRadioGroupChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_REDISTRIBUTE,
                    value: isRedistribution ? false : null,
                })
            );
            dispatch(
                dealFormDateFieldChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_CUSTOM_CURVE_START_DATE,
                    value: isCustom ? curveStartDate : null,
                })
            );
        }
    }, [isProgrammaticGuaranteeDeal, adSourcePacingType, dealStartDate, dealEndDate, dispatch, form, mode]);

    useEffect(() => {
        if (pricingTypes) {
            const [fixedPrice, auctionPrice] = pricingTypes;
            const pricingValue = isAuctionPriceDeal ? auctionPrice.id : fixedPrice.id;
            dispatch(
                dealFormSelectFieldChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.PRICE_TYPE,
                    value: pricingValue,
                })
            );
        }
    }, [pricingTypes, isAuctionPriceDeal, dispatch]);

    useEffect(() => {
        if (transactionTypes) {
            const [reserved, nonReserved] = transactionTypes;
            const transactionValue = isProgrammaticGuaranteeDeal ? reserved.id : nonReserved.id;
            dispatch(
                dealFormSelectFieldChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.TRANSACTION_TYPE,
                    value: transactionValue,
                })
            );
        }
    }, [dispatch, transactionTypes, isProgrammaticGuaranteeDeal]);

    useEffect(() => {
        if (deal) {
            handleSetFormFields(dealAdSourceData);
            dispatch(
                dealFormSetValues({
                    deal,
                    mode,
                    adSource: dealAdSourceData,
                    seatFinanceDefaults,
                })
            );
            if (dealAdSourceData) {
                dispatch(
                    loadTargeting(
                        TargetingFormKeys.Deal,
                        prepareTargetingForCopy(dealAdSourceData.targeting, mode),
                        dealAdSourceData.targetingMode
                    )
                );

                if (dealAdSourceData.marketplaceInfoList.length) {
                    const marketplaceInfo = getDealMarketplaceInfo(dealAdSourceData.marketplaceInfoList, deal);

                    if (marketplaceInfo) {
                        dispatch(
                            loadTargeting(
                                TargetingFormKeys.DealAdditionalTargeting,
                                prepareTargetingForCopy(marketplaceInfo.targeting, mode),
                                dealAdSourceData.targetingMode
                            )
                        );
                    }
                }

                if (!isDealFormInitializedRef.current) {
                    dispatch(dealFormSetAsInitialized());
                }
            }
        }
    }, [deal, mode, dispatch, handleSetFormFields, loadTargeting, dealAdSourceData, seatFinanceDefaults]);

    const [isCopyComplete, setIsCopyComplete] = useState<boolean>(false);
    const isCopySyncCompleteRef = useRef<boolean>(false);
    if (isCopyingAdSource && isCopyComplete && !isCopySyncCompleteRef.current) {
        // sync all fields on copy to handle case where a multi deal ad source is copied, as the relationship will become 1:1
        // (other deals are not copied as part of the ad source's marketplace info list)
        updateSyncedFields({
            startDate: dealStartDate ? moment(dealStartDate) : undefined,
            endDate: dealEndDate ? moment(dealEndDate) : null,
            timeZone: dealTimeZone ? dealTimeZone : undefined,
            cpmRate: dealRate ? String(dealRate) : undefined,
            status: dealStatus ? ({ id: dealStatus, entityType: "DealStatus" } as DealStatus) : undefined,
            currency: dealCurrency ? ({ id: dealCurrency } as Currency) : undefined,
            impressions: dealImpression ? dealImpression : undefined,
        });
        isCopySyncCompleteRef.current = true;
    }

    useEffect(() => {
        if (isCopyingAdSource) {
            if (dealAdSourceCopyData) {
                handleSetFormFields(dealAdSourceCopyData);
                dispatch(
                    dealFormAdSourceSetValues({
                        adSource: dealAdSourceCopyData,
                        seatFinanceDefaults,
                        isCopyingAdSource,
                        mode,
                    })
                );

                dispatch(
                    loadTargeting(
                        TargetingFormKeys.Deal,
                        prepareTargetingForCopy(dealAdSourceCopyData.targeting, mode),
                        dealAdSourceCopyData.targetingMode
                    )
                );

                if (deal) {
                    if (dealAdSourceCopyData.marketplaceInfoList.length) {
                        const marketplaceInfo = getDealMarketplaceInfo(dealAdSourceCopyData.marketplaceInfoList, deal);

                        if (marketplaceInfo) {
                            dispatch(
                                loadTargeting(
                                    TargetingFormKeys.DealAdditionalTargeting,
                                    prepareTargetingForCopy(marketplaceInfo.targeting, mode),
                                    dealAdSourceCopyData.targetingMode
                                )
                            );
                        }
                    }
                }

                if (!isDealFormInitializedRef.current) {
                    dispatch(dealFormSetAsInitialized());
                }

                setIsCopyComplete(true);
            }
        }
    }, [
        form,
        deal,
        mode,
        dispatch,
        handleSetFormFields,
        loadTargeting,
        dealAdSourceCopyData,
        seatFinanceDefaults,
        isCopyingAdSource,
        updateSyncedFields,
    ]);

    useEffect(() => {
        if (mode === "create") {
            const defaultCostModel = getDefaultCostModel(dealType, seatFinanceDefaults);
            const transparencyDefaults = seatTransparencyDefaults?.find(
                (defaults) => defaults.adSourceType.id === dealType
            );
            if (transparencyDefaults) {
                dispatch(dealFormAdSourceSetDefaultTransparency({ transparencyDefaults }));
            }
            dispatch(
                dealFormInputFieldChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_COST_MODEL,
                    value: defaultCostModel?.costModel.id || null,
                })
            );
        }
    }, [dispatch, mode, dealType, seatFinanceDefaults, seatTransparencyDefaults]);

    useEffect(() => {
        if (mode === "create" && adSourceCostModel) {
            const isFixedCpm = adSourceCostModel === CostModels.FIXED_CPM;
            const defaultCostModel = getDefaultCostModel(dealType, seatFinanceDefaults);
            const defaultCostModelFixedValue =
                defaultCostModel?.costValueFixed && defaultCostModel?.costValueFixed / 1000;
            const defaultCostModelRevShareValue = defaultCostModel?.costValuePercent;

            dispatch(
                dealFormInputFieldChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_COST,
                    value: (isFixedCpm ? defaultCostModelFixedValue : defaultCostModelRevShareValue) || null,
                })
            );
        }
    }, [dispatch, mode, adSourceCostModel, seatFinanceDefaults, dealType]);

    useEffect(() => {
        if (mode === "create" && seatThirdPartyPixels) {
            const defaultPixels = seatThirdPartyPixels.filter((pixel) => pixel.addToAdSourceByDefault);

            dispatch(
                dealFormSelectFieldChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PIXELS,
                    value: defaultPixels.map((pixel) => pixel.id),
                })
            );
        }
    }, [mode, seatThirdPartyPixels, dispatch]);

    useEffect(() => {
        if (publisherCredentialShown && mode === "create") {
            dispatch(
                dealFormInputFieldChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.PUBLISHER_NAME,
                    value: context?.name,
                })
            );
        }
    }, [publisherCredentialShown, dispatch, context, mode]);

    useEffect(() => {
        const isCreate = mode === "create";
        if (context?.floorCurrency?.id && isCreate) {
            dispatch(
                dealFormSelectFieldChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.CURRENCY,
                    value: context.floorCurrency.id,
                })
            );

            const currency = currencies?.find((currency) => currency.id === context.floorCurrency.id);
            updateSyncedFields({ currency });
        }
        if (context?.timeZone && isCreate) {
            dispatch(
                dealFormSelectFieldChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.TIME_ZONE,
                    value: context.timeZone.id,
                })
            );
        }
    }, [context, dispatch, mode, updateSyncedFields, currencies]);

    useEffect(() => {
        if (mode !== "edit") {
            dispatch(
                dealFormRadioGroupChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_RUN_UNDER_INBOUND_FLOOR,
                    value: false,
                })
            );
        }
    }, [mode, dispatch, dealType, dealPricingModel, adSourceModeConfiguration]);

    const showDeleteModal = (): void => setIsDeleteModalOpen(true);
    const hideDeleteModal = (): void => setIsDeleteModalOpen(false);

    const showSubmitConfirmationModal = (): void => setIsSubmitConfirmationModalOpen(true);
    const hideSubmitConfirmationModal = (): void => setIsSubmitConfirmationModalOpen(false);

    const createdWithBuyerSeats = Boolean(deal?.createdWithBuyerSeats);

    const { isReady, triggerCooldown } = useCooldown();
    const wasSubmittingRef = useRef<boolean>(false);
    useEffect(() => {
        if (!isSubmitting && wasSubmittingRef.current) {
            triggerCooldown();
        }
        wasSubmittingRef.current = isSubmitting;
    }, [isSubmitting, triggerCooldown]);

    const buildComparableAdSourcePayloadJsonString = (): string => {
        const dealPayload = buildDealCreatePayload();
        const adSourcePayload = buildAdSourcePayload(dealPayload);
        let redactedAdSourcePayload: Partial<AdSourcePayload> | null = null;
        if (adSourcePayload) {
            const {
                marketplaceInfoList: _a,
                synthesizedAdSourceStatus: _b,
                bookingStartDate: _c,
                bookingEndDate: _d,
                bookingPrice: _e,
                ...rest
            } = cloneDeep(adSourcePayload);
            redactedAdSourcePayload = rest;
        }
        return JSON.stringify(redactedAdSourcePayload);
    };

    const shouldCheckAdSourcePayloadForChanges = mode === "edit" && hasMultipleDeals && isDealFormInitialized;
    if (shouldCheckAdSourcePayloadForChanges && !initialAdSourcePayloadCompare.current) {
        // Capture a "snapshot" of the initial ad source payload to use for later comparison.
        // We use this to warn users that are about to edit an ad source containing multiple deals.
        initialAdSourcePayloadCompare.current = buildComparableAdSourcePayloadJsonString();
    }

    const currentAdSourcePayloadCompare = buildComparableAdSourcePayloadJsonString();
    const isUpdatingMultiDealAdSource = useMemo(
        () =>
            shouldCheckAdSourcePayloadForChanges &&
            initialAdSourcePayloadCompare.current !== currentAdSourcePayloadCompare,
        [shouldCheckAdSourcePayloadForChanges, currentAdSourcePayloadCompare]
    );

    const submitConfirmationText = isUpdatingMultiDealAdSource
        ? "This Ad Source contains multiple Deals. Are you sure you want changes to apply to all Deals configured to this Ad Source?"
        : null;
    const isSubmitConfirmationEligible = Boolean(submitConfirmationText);

    const adSourceAdResponsePriceOverrideShown =
        showAdResponsePriceOverride && (isProgrammaticGuaranteeDeal || isFixedPriceDeal);

    isDealFormInitializedRef.current = isDealFormInitialized;

    return {
        adSourceModeConfiguration,
        adSourceConfigurationShown: !dealConfigureAdSource,
        configureAdSourceShown,
        createdWithBuyerSeats,
        dealAdSourceData,
        dealAdSourceCopyData,
        dealId,
        dealImpressionsShown: isProgrammaticGuaranteeDeal,
        dealOriginShown: isInternalUser,
        dealRateShown,
        demandConfigurationShown: !dealConfigureAdSource,
        enforcementShown,
        form,
        frequencyCappingItemShown,
        handleCancel,
        hideSubmitConfirmationModal,
        handleDelete,
        handleSubmit,
        hasAttachedAdSource,
        hasMultipleAdSources,
        hasMultipleDeals,
        hideDeleteModal,
        internalFieldsShowm,
        isAwaitingSubmitCooldown: !isReady,
        isCopyingAdSource,
        isDeleting,
        isEndUser,
        isInternalUser,
        isSubmitting,
        isSubmitConfirmationEligible,
        submitConfirmationText,
        isSubmitConfirmationModalOpen,
        isDeleteModalOpen,
        priceModelShown: isFixedPriceDeal,
        publisherCredentialShown,
        showDeleteModal,
        showSubmitConfirmationModal,
        adSourceAdResponsePriceOverrideShown,
    };
};
