import { FC } from "react";
import { Layout, Space } from "antd";
import { Footer } from "@app/core/components";
import DealListToolbar from "./DealListToolbar";
import DealListTable from "./DealListTable";
import { DealListTableFilters } from "@app/features/deals/DealListPage/DealListTableFilters";
import { useGetCurrenciesPrefetch } from "@app/core/services";

const DealListPage: FC = () => {
    useGetCurrenciesPrefetch();
    return (
        <>
            <DealListToolbar />
            <Layout.Content>
                <Space direction="vertical" size="large" style={{ width: "100%" }}>
                    <DealListTableFilters />
                    <DealListTable />
                </Space>
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};

export default DealListPage;
