import { Flex } from "antd";
import { DrawerCloseButton } from "../DrawerLayout";

export const SideDrawerLayout = ({ children, closeHandler }) => {
    return (
        <Flex vertical gap={"1.5rem"}>
            <Flex justify="flex-end">
                <DrawerCloseButton onClose={closeHandler} />
            </Flex>
            {children}
        </Flex>
    );
};
