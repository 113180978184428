import { FC, useMemo } from "react";
import { DualAxes } from "@ant-design/plots";
import { formatNumber } from "@rubicon/utils";
import { brandMarble } from "@rubicon/antd-components";
import { GRAPH_CONFIG, GRAPH_CONFIG_TYPES, GRAPH_VALUE_TYPES } from "../ChartMetricsFilters";
import { useAppSelector } from "@app/core/store";
import { useCurrencyConversion } from "../useCurrencyConversion";
import { LabeledValue } from "antd/lib/select";
import { getChartTooltipTitle } from "../helpers";
import { RevenueTypes } from "@app/core/clients/console";
import { selectDashboardRevenueType, selectDashboardTimeFrameFilter } from "../reducer";

interface Props {
    metricOne?: { date: string | number; value: number; type: string }[];
    metricTwo?: { date: string | number; count: number; name: string }[];
    metricOneType: string;
    metricTwoType: string;
    isLoading: boolean;
}

const getTooltipTitle = (v: string, period: LabeledValue) => `${getChartTooltipTitle(period)}: ${v}`;
const formatter = (metricConfig, v, currencyCode) => {
    if (metricConfig?.type === GRAPH_VALUE_TYPES.MONEY) {
        return formatNumber.asMoney(v, currencyCode);
    }
    if (metricConfig?.type === GRAPH_VALUE_TYPES.RATE) {
        return formatNumber.asPercent(v);
    }
    if (metricConfig?.type === GRAPH_VALUE_TYPES.VALUE) {
        return formatNumber.asAbbreviated(v);
    }
    return v;
};

export const getGraphConfig = (metricType, revenueType) => {
    if (metricType === "netRevenue") {
        return revenueType === RevenueTypes.NET_REVENUE
            ? GRAPH_CONFIG_TYPES.NET_REVENUE
            : GRAPH_CONFIG_TYPES.GROSS_REVENUE;
    }
    if (metricType === "netRevenueCpm") {
        return revenueType === RevenueTypes.NET_REVENUE ? GRAPH_CONFIG_TYPES.NET_ECPM : GRAPH_CONFIG_TYPES.GROSS_ECPM;
    }
    if (metricType === "podAndPlaylistRevenue") {
        return revenueType === RevenueTypes.NET_REVENUE
            ? GRAPH_CONFIG_TYPES.NET_REVENUE
            : GRAPH_CONFIG_TYPES.GROSS_REVENUE;
    }
    if (metricType === "standardNetRevenue") {
        return revenueType === RevenueTypes.NET_REVENUE
            ? GRAPH_CONFIG_TYPES.NET_REVENUE
            : GRAPH_CONFIG_TYPES.GROSS_REVENUE;
    }

    return GRAPH_CONFIG[metricType];
};

export const PerformanceQuickViewChart: FC<Props> = ({
    metricOne = [],
    metricTwo = [],
    metricOneType,
    metricTwoType,
    isLoading,
}) => {
    const revenueType = useAppSelector(selectDashboardRevenueType);
    const metricOneConfig = getGraphConfig(metricOneType, revenueType);
    const metricTwoConfig = getGraphConfig(metricTwoType, revenueType);
    const { currencyConfig } = useCurrencyConversion();
    const period = useAppSelector(selectDashboardTimeFrameFilter);

    const config = useMemo(
        () => ({
            loading: isLoading,
            data: [metricOne, metricTwo],
            xField: "date",
            yField: ["value", "count"],
            legend: {
                layout: "horizontal" as const,
                position: "bottom" as const,
                offsetY: 16,
            },
            tooltip: {
                title: (v: string) => getTooltipTitle(v, period),
                formatter: (data) => {
                    let value = "";
                    if (
                        (data.type && metricOneConfig.type === "MONEY") ||
                        (data.name && metricTwoConfig.type === "MONEY")
                    ) {
                        value = formatNumber.asMoney(data.count ?? data.value, currencyConfig?.code);
                    } else if (
                        (data.type && metricOneConfig.type === "RATE") ||
                        (data.name && metricTwoConfig.type === "RATE")
                    ) {
                        value = formatNumber.asPercent(data.count ?? data.value);
                    } else {
                        value = formatNumber.asNumber(data.count ?? data.value);
                    }
                    return {
                        name: data.name || data.type,
                        value,
                    };
                },
            },
            xAxis: {
                label: {
                    formatter: (v: string) => v,
                },
            },
            yAxis: {
                value: {
                    top: true,
                    grid: null,
                    title: {
                        text: metricOneConfig?.title,
                    },
                    min: 0,
                    tickCount: 8,
                    label: {
                        formatter: (v) => formatter(metricOneConfig, v, currencyConfig?.code),
                    },
                },
                count: {
                    top: true,
                    grid: null,
                    title: {
                        text: metricTwoConfig?.title,
                        rotate: Math.PI / 2,
                    },
                    min: 0,
                    tickCount: 8,
                    label: {
                        formatter: (v) => formatter(metricTwoConfig, v, currencyConfig?.code),
                    },
                },
            },
            geometryOptions: [
                {
                    geometry: "column",
                    isGroup: true,
                    seriesField: "type",
                    columnWidthRatio: 0.4,
                    marginRatio: 0.2,
                    color: [brandMarble, "#6395F9"],
                },
                {
                    geometry: "line",
                    seriesField: "name",
                    color: ["#FFD7B8", "#FF9D4F"],
                    point: {
                        shape: "circle",
                    },
                },
            ],
        }),
        [metricOne, metricTwo, metricOneConfig, metricTwoConfig, isLoading, currencyConfig?.code, period]
    );

    return (
        <div data-sdet="graph">
            <DualAxes {...config} />
        </div>
    );
};
