import { CurrencyConversionModes } from "@app/core/clients/console";

export const CURRENCY_CONVERSION_MODE_OPTIONS = [
    {
        label: "Convert To",
        value: CurrencyConversionModes.CONVERT,
    },
    {
        label: "Originating Currency",
        value: CurrencyConversionModes.ORIGINATING_CURRENCY,
    },
];
