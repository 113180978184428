import { Form, Space } from "antd";
import { FC } from "react";
import { AddFrequencyCappingItem } from "./AddFrequencyCapping/AddFrequencyCappingItem";
import { DealFreqCappingsMultiselect } from "./DealFreqCappingsMultiselect";
import { FrequencyCappingTypeSelect } from "./FrequencyCappingTypeSelect/FrequencyCappingTypeSelect";
import { DEAL_FORM_FIELDS } from "../../../../constants";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";

export const FrequencyCapping: FC = () => {
    const { marketplaceInfoMode } = useMarketplaceInfoWidget();
    const freqCappingType = Form.useWatch<{ name: string }>(DEAL_FORM_FIELDS.FREQUENCY_CAPPING_TYPE.name);

    if (marketplaceInfoMode.name === "view") {
        return (
            <>
                <FrequencyCappingTypeSelect />
                <DealFreqCappingsMultiselect />
            </>
        );
    }

    if (freqCappingType?.name === "Off") {
        return <FrequencyCappingTypeSelect />;
    }

    return (
        <>
            <Space>
                <FrequencyCappingTypeSelect />
                <AddFrequencyCappingItem />
            </Space>
            <DealFreqCappingsMultiselect />
        </>
    );
};
