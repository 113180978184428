import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Floor, useGetBrandFloorsQuery } from "@app/core/services";
import { compareFloorsByPriority } from "../helpers";

interface UseBrandFloorsTable {
    floors?: Array<Floor>;
    isFetching: boolean;
    brandId: string;
}

export const useBrandFloorsTable = (): UseBrandFloorsTable => {
    const { brandId } = useParams<{ brandId: string }>();
    const { data, isFetching } = useGetBrandFloorsQuery({ brandId: Number(brandId), list: "all" });

    const floors = useMemo(() => {
        return [...(data || [])].sort(compareFloorsByPriority);
    }, [data]);

    return {
        floors,
        isFetching,
        brandId,
    };
};
