import { FC } from "react";
import { CodeFormItem } from "@app/features/inventory/components/FormItems";
import { PUBLISHER_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { usePublisherFormInstance } from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormInstance";
import { ColProps } from "antd";

interface Props {
    wrapperCol?: ColProps;
}
export const Code: FC<Props> = ({ wrapperCol = { xs: 24, sm: 24, md: 24, lg: 18, xl: 22, xxl: 22 } }) => {
    const { mode, seat, useWatch } = usePublisherFormInstance();
    return (
        <CodeFormItem
            mode={mode}
            seat={seat}
            useWatch={useWatch}
            name={PUBLISHER_FORM_FIELDS.CODE.name}
            label={PUBLISHER_FORM_FIELDS.CODE.label}
            placeholder={PUBLISHER_FORM_FIELDS.CODE.placeholder}
            wrapperCol={wrapperCol}
            dataSdet="publisher-code"
        />
    );
};
