import { Currency } from "@app/core/services/console";

// Console src/main/webapp/js/globals.js
export const CURRENCY_DEFS = {
    AUD: {
        symbol: "A$",
        name: "Australian Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "AUD",
        name_plural: "Australian dollars",
    },
    USD: {
        symbol: "$",
        name: "US Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "USD",
        name_plural: "US dollars",
    },
    CAD: {
        symbol: "CA$",
        name: "Canadian Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "CAD",
        name_plural: "Canadian dollars",
    },
    EUR: {
        symbol: "€",
        name: "Euro",
        symbol_native: "€",
        decimal_digits: 2,
        rounding: 0,
        code: "EUR",
        name_plural: "euros",
    },
    SGD: {
        symbol: "S$",
        name: "Singapore Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "SGD",
        name_plural: "Singapore dollars",
    },
    JPY: {
        symbol: "¥",
        name: "Japanese Yen",
        symbol_native: "￥",
        decimal_digits: 0,
        rounding: 0,
        code: "JPY",
        name_plural: "Japanese yen",
    },
    GBP: {
        symbol: "£",
        name: "British Pound Sterling",
        symbol_native: "£",
        decimal_digits: 2,
        rounding: 0,
        code: "GBP",
        name_plural: "British pounds sterling",
    },
    NZD: {
        symbol: "NZ$",
        name: "New Zealand Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "NZD",
        name_plural: "New Zealand dollars",
    },
};

export const getCurrencySymbol = (code: string | undefined) =>
    code && CURRENCY_DEFS[code] ? CURRENCY_DEFS[code].symbol : "";

export const CURRENCIES: Currency[] = [
    {
        creationDate: "2016-03-23T21:39:26.000+0000",
        updateDate: "2016-03-23T21:39:26.000+0000",
        id: 1,
        code: "USD",
        sort: 1,
        entityType: "CurrencyType",
    },
    {
        creationDate: "2016-03-23T21:39:26.000+0000",
        updateDate: "2016-03-23T21:39:26.000+0000",
        id: 2,
        code: "AUD",
        sort: 2,
        entityType: "CurrencyType",
    },
    {
        creationDate: "2016-10-06T20:39:40.000+0000",
        updateDate: "2016-10-06T20:39:40.000+0000",
        id: 3,
        code: "EUR",
        sort: 3,
        entityType: "CurrencyType",
    },
    {
        creationDate: "2016-10-06T20:39:40.000+0000",
        updateDate: "2016-10-06T20:39:40.000+0000",
        id: 4,
        code: "CAD",
        sort: 4,
        entityType: "CurrencyType",
    },
    {
        creationDate: "2016-10-06T20:39:40.000+0000",
        updateDate: "2016-10-06T20:39:40.000+0000",
        id: 5,
        code: "SGD",
        sort: 5,
        entityType: "CurrencyType",
    },
    {
        creationDate: "2016-10-06T20:39:40.000+0000",
        updateDate: "2016-10-06T20:39:40.000+0000",
        id: 6,
        code: "JPY",
        sort: 6,
        entityType: "CurrencyType",
    },
    {
        creationDate: "2016-10-06T20:39:40.000+0000",
        updateDate: "2016-10-06T20:39:40.000+0000",
        id: 7,
        code: "GBP",
        sort: 7,
        entityType: "CurrencyType",
    },
    {
        creationDate: "2016-10-11T19:12:43.000+0000",
        updateDate: "2016-10-11T19:12:43.000+0000",
        id: 8,
        code: "NZD",
        sort: 8,
        entityType: "CurrencyType",
    },
    {
        creationDate: "2017-11-15T20:39:21.000+0000",
        updateDate: "2017-11-15T20:39:21.000+0000",
        id: 9,
        code: "BRL",
        sort: 9,
        entityType: "CurrencyType",
    },
    {
        creationDate: "2021-05-25T18:20:50.000+0000",
        updateDate: "2021-05-25T18:20:50.000+0000",
        id: 10,
        code: "INR",
        sort: 10,
        entityType: "CurrencyType",
    },
    {
        creationDate: "2022-03-01T19:25:58.000+0000",
        updateDate: "2022-03-01T19:25:58.000+0000",
        id: 11,
        code: "MXN",
        sort: 11,
        entityType: "CurrencyType",
    },
];

export const CURRENCIES_BY_CODE = CURRENCIES.reduce<{ [code: string]: Currency }>((acc, currency) => {
    acc[currency.code] = currency;
    return acc;
}, {});

export const CURRENCIES_BY_ID = CURRENCIES.reduce<{ [id: number]: Currency }>((acc, currency) => {
    acc[currency.id] = currency;
    return acc;
}, {});
