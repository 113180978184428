import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormRadioGroupChange } from "@app/features/deals/DealForm/reducer";

interface UseAdSourceAllowAudienceLockField {
    onChange: (value: boolean) => void;
    value: boolean;
}

export const useAdSourceAllowAudienceLockField = (): UseAdSourceAllowAudienceLockField => {
    const dispatch = useAppDispatch();
    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_AUDIENCE_LOCK]
    );
    const onChange = (value: boolean) => {
        dispatch(dealFormRadioGroupChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_AUDIENCE_LOCK, value }));
    };

    return {
        onChange,
        value,
    };
};
