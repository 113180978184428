import { CSSProperties, FC, ReactNode } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { useSearchBar } from "./useSearchBar";
import { SelectWithValue } from "../seatAdSources/SeatAdSourcesForm/components";

interface Props {
    style?: CSSProperties;
}

export const SearchBar: FC<Props> = ({ style }) => {
    const { isLoading, options, search, handleChangeSearch, handleSearchSelected, handleScroll } = useSearchBar();

    return (
        <SelectWithValue<{ value: string; url: string; label: ReactNode }>
            popupClassName={css`
                .ant-select-item-option-content {
                    overflow: visible;
                    white-space: normal;
                }
            `}
            style={style}
            filterOption={false}
            loading={isLoading}
            onSearch={handleChangeSearch}
            onChange={handleSearchSelected}
            onPopupScroll={handleScroll}
            options={options}
            placeholder="Start typing to search…"
            searchValue={search}
            showSearch
            suffixIcon={isLoading ? undefined : <SearchOutlined />}
            value={null}
            notFoundContent={Boolean(search) ? undefined : null}
            fieldAsLabel={["label"]}
            fieldAsValue={["value"]}
        />
    );
};
