import { useEffect } from "react";
import { consoleApi, CURRENCIES_TAG, CURRENCIES_CONVERSIONS_TAG, CURRENCIES_CONVERSIONS_BY_CODE_TAG } from "./console";

export interface Currency {
    creationDate: string;
    updateDate: string;
    id: number;
    code: string;
    sort?: number;
    entityType?: "CurrencyType";
}

export interface CurrencyConversion {
    sourceCurrencyId: number;
    sourceCurrencyCode: string;
    targetCurrencyId: number;
    targetCurrencyCode: string;
    rate: number;
    lastUpdated: string;
}

export const currenciesApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getCurrencies: builder.query<Currency[], void>({
            query: () => "currencies",
            providesTags: [CURRENCIES_TAG],
        }),
        getCurrenciesConversions: builder.query<CurrencyConversion[], void>({
            query: () => "currencies/conversions",
            providesTags: [CURRENCIES_CONVERSIONS_TAG],
        }),
        getCurrenciesConversionsByCode: builder.query<CurrencyConversion[], string>({
            query: (currencyCode) => `currencies/${currencyCode}/conversions`,
            providesTags: [CURRENCIES_CONVERSIONS_BY_CODE_TAG],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetCurrenciesQuery,
    useGetCurrenciesConversionsQuery,
    usePrefetch: useCurrenciesPrefetch,
    useGetCurrenciesConversionsByCodeQuery,
} = currenciesApi;

export const useGetCurrenciesPrefetch = () => {
    const getCurrenciesPrefetch = useCurrenciesPrefetch("getCurrencies");
    const getCurrenciesConversionsPrefetch = useCurrenciesPrefetch("getCurrenciesConversions");

    useEffect(() => {
        getCurrenciesPrefetch();
        getCurrenciesConversionsPrefetch();
    }, [getCurrenciesPrefetch, getCurrenciesConversionsPrefetch]);
};

export const selectGetCurrenciesResult = currenciesApi.endpoints.getCurrencies.select();
export const selectGetCurrenciesConversionsResult = currenciesApi.endpoints.getCurrenciesConversions.select();
