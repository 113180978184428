import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

export function useBuyerSideNav() {
    const { pathname } = useLocation();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isAccount, setIsAccount] = useState<boolean>(false);
    const [activeApp, setActiveApp] = useState<string | null>(null);
    const [activeGroup, setActiveGroup] = useState<string | null>(null);
    const [prevActive, setPrevActive] = useState<string | null>(null);

    const handleMenuClose = () => {
        if (prevActive) {
            setActiveApp(prevActive);
            setPrevActive(null);
        }
        setIsOpen(false);
    };

    const handleAccountClose = () => {
        setIsAccount(false);
    };

    const handleGroupClose = () => {
        setActiveGroup(null);
    };

    const handleAppClick = (app: string) => {
        setActiveApp(app);
        setIsAccount(false);
        setIsOpen(false);
        // TODO: Make more robust with map of each scene's entry route
        const [, buyer, id] = pathname.split("/");
        const path = `/${buyer}/${id}/${app}`;
        history.push(path);
    };

    const handleGroupClick = (group: string) => {
        if (!isOpen) {
            setPrevActive(activeApp);
        }
        setActiveApp(null);
        setActiveGroup(group);
        setIsAccount(false);
        setIsOpen(true);
    };

    const handleAccountClick = () => {
        setIsAccount(true);
        if (!isOpen) {
            setPrevActive(activeApp);
        }
        setActiveApp(null);
        setActiveGroup(null);
        setIsOpen(true);
    };

    useEffect(() => {
        // TODO: Make more robust. This will silently break if we add any routes that are not in the form of /seat/:seatId/:activeRoute
        const [, , , activeRoute] = pathname.split("/");
        setActiveApp(activeRoute);
        setPrevActive(null);
    }, [pathname]);

    return {
        isOpen,
        isAccount,
        activeApp,
        activeGroup,

        handleAppClick,
        handleGroupClick,
        handleAccountClick,
        handleMenuClose,
        handleAccountClose,
        handleGroupClose,
    };
}
