import { FC } from "react";
import { Form, Select } from "antd";
import { Brand } from "@app/core/services";
import { css } from "@emotion/css";
import { FormLayout } from "antd/lib/form/Form";
import { FORM_GRID_OPTIONS } from "../BrandSafetyPublisherPage/constants";
import {
    BlockedAdvertiserDomainListFormItem,
    BlockedAdvertiserDomainsFormItem,
    BlockedBuyerSeatListFormItem,
} from "./ViewFormItems";

interface Props {
    brand: Brand;
    layout?: FormLayout;
}

export const BrandSafetyBrandForm: FC<Props> = ({ brand, layout = "horizontal" }) => {
    const blockedCategoriesOptions = brand.blockedIabCategories.map((category) => ({
        key: category.id,
        value: `${category.code}: ${category.parentName}: ${category.name}`,
        disabled: true,
    }));

    return (
        <Form layout={layout} {...FORM_GRID_OPTIONS[layout]} labelAlign="left">
            <BlockedAdvertiserDomainListFormItem
                seatAdvertiserDomainFilterListDefs={brand.seatAdvertiserDomainFilterListDefs}
            />
            <BlockedAdvertiserDomainsFormItem advertiserBlockDomains={brand.advertiserBlockDomains} />
            <BlockedBuyerSeatListFormItem buyerSeatList={brand.buyerSeatList} />
            <Form.Item label="Blocked Categories">
                <Select
                    className={css`
                        .ant-select {
                            pointer-events: none;
                        }
                    `}
                    mode="tags"
                    style={{ width: "100%" }}
                    value={blockedCategoriesOptions}
                    options={blockedCategoriesOptions}
                    open={false}
                ></Select>
            </Form.Item>
        </Form>
    );
};
