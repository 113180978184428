import { FC, useEffect, useMemo } from "react";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { Form, Slider, Typography } from "antd";
import { formatDate } from "../../formatDate";
import { getPercentage } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourcePacingSection/Fields/AdSourceCustomPacing/helpers";
import moment from "moment-timezone";
import { PacingCurveLimits } from "@app/core/utils/pacingCalculator";
import { isDeliveryPercentageEligible } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/pacingUtils";
import { usePacingFormData } from "../usePacingFormData";

const { useWatch, useFormInstance } = Form;

const PacingInfo: FC = () => {
    const totalImpressions = useWatch<number>(AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.name) || 0;
    const startDate = useWatch<moment.Moment>(AD_SOURCE_FIELDS.START_DATE.name);
    const endDate = useWatch<moment.Moment>(AD_SOURCE_FIELDS.END_DATE.name);
    const targetDate = useWatch<moment.Moment>(AD_SOURCE_FIELDS.TARGET_DATE.name);
    const deliveryImps = useWatch<number>(AD_SOURCE_FIELDS.DELIVERY_PERCENTAGE.name);

    const formatedStartDate = formatDate(startDate);
    const formatedEndDate = formatDate(endDate);
    const formatedTargetDate = formatDate(targetDate);
    const calculatedImpressions = deliveryImps;
    return (
        <>
            <Typography.Paragraph type="secondary">Total Impressions: {totalImpressions}</Typography.Paragraph>
            <Typography.Paragraph type="secondary">
                Flight Dates: {formatedStartDate} to {formatedEndDate}
            </Typography.Paragraph>
            <Typography.Paragraph type="secondary">
                {calculatedImpressions} Impressions will be deilvered by EOD {formatedTargetDate}
            </Typography.Paragraph>
        </>
    );
};

const useDeliveryPercentageSlider = () => {
    const { setFieldsValue, getFieldsValue } = useFormInstance();
    const totalImpressions = useWatch<number>(AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.name) || 0;
    const startDate = useWatch<moment.Moment>(AD_SOURCE_FIELDS.START_DATE.name);
    const endDate = useWatch<moment.Moment>(AD_SOURCE_FIELDS.END_DATE.name);
    const targetDate = useWatch<moment.Moment>(AD_SOURCE_FIELDS.TARGET_DATE.name);

    const limits: { min: number; max: number } | null = useMemo(() => {
        if (!endDate || !startDate || !targetDate || !totalImpressions) {
            return {
                min: 0,
                max: 0,
            };
        }
        const campaignLength = endDate.diff(startDate, "days");
        const targetDay = targetDate.diff(startDate, "days");
        const completionPercentage = (targetDay + 1) / campaignLength;

        return {
            max: Math.floor(PacingCurveLimits.cubicFront(completionPercentage) * totalImpressions),
            min: Math.ceil(PacingCurveLimits.cubicBack(completionPercentage) * totalImpressions),
        };
    }, [endDate, startDate, targetDate, totalImpressions]);

    useEffect(() => {
        const deliveryPercentage = getFieldsValue(true)?.[AD_SOURCE_FIELDS.DELIVERY_PERCENTAGE.name] || 0;
        setFieldsValue({ [AD_SOURCE_FIELDS.DELIVERY_PERCENTAGE.name]: deliveryPercentage || limits.min });
    }, [getFieldsValue, limits.min, setFieldsValue]);
    return { limits };
};

export const DeliveryPercentageInput: FC = () => {
    const { adSourceTypeId, pacingTypeId, totalImpressions } = usePacingFormData();

    const { limits } = useDeliveryPercentageSlider();

    if (!isDeliveryPercentageEligible(adSourceTypeId, pacingTypeId)) {
        return null;
    }

    const formatter = (value?: number) => `${value} (${getPercentage(value || 0, totalImpressions || 0)}%)`;

    return (
        <Form.Item
            name={AD_SOURCE_FIELDS.DELIVERY_PERCENTAGE.name}
            label={AD_SOURCE_FIELDS.DELIVERY_PERCENTAGE.label}
            data-sdet={`test-${AD_SOURCE_FIELDS.DELIVERY_PERCENTAGE.name}-field`}
            rules={[{ required: true, message: `${AD_SOURCE_FIELDS.DELIVERY_PERCENTAGE.label} is required` }]}
            {...VERTICAL_LAYOUT_FIX}
            help={<PacingInfo />}
        >
            <Slider min={limits?.min} max={limits?.max} tooltip={{ formatter }} />
        </Form.Item>
    );
};
