import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { Form } from "antd";
import { SeatAdSourcesType } from "@app/core/services";

const { useWatch } = Form;

export const useIsFieldVisible = () => {
    const adSourceTypeId = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name)?.id;

    switch (adSourceTypeId) {
        case AdSourceTypeIds.AUCTION_PRICE:
        case AdSourceTypeIds.MARKETPLACE:
        case AdSourceTypeIds.LINEAR_INVITE_ONLY_AUCTION:
        case AdSourceTypeIds.OPEN_AUCTION:
        case AdSourceTypeIds.SERVER_SIDE_DYNAMIC_PRICE:
        case AdSourceTypeIds.TREMOR_NETWORK:
            return false;
        default:
            return true;
    }
};
