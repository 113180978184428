import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import { DealDetailsDrawerTitles, dealDetailsHelpKeys } from "@app/features/deals/constants";
import { Flex, Typography } from "antd";
import { FC } from "react";

interface Props {
    dealName?: string;
    secondaryDetailsView: DealDetailsDrawerTitles | null;
}
export const DealDetailsDrawerDirectTitle: FC<Props> = ({ dealName, secondaryDetailsView }) => {
    return (
        <Flex data-sdet="seat-deal-details-drawer-direct-title" justify="space-between" align="center">
            <Typography.Title level={5} style={{ fontWeight: "bold", marginBottom: 0, marginTop: 0 }}>
                {`${secondaryDetailsView}: `}
                <Typography.Text style={{ fontWeight: "normal" }}>{dealName}</Typography.Text>
            </Typography.Title>
            <HelpKeysDrawer
                panelLabel={secondaryDetailsView as string}
                definitionsFields={dealDetailsHelpKeys[secondaryDetailsView as string]}
            />
        </Flex>
    );
};
