import { useUserAccess } from "@app/core/auth";
import {
    SeatHelpKeys,
    publisherHelpKeys,
    PublisherDrawerTitles,
} from "@app/features/inventory/InventorySeat/constants";
import { DefinationField } from "@app/core/components/HelpKeysDrawer";
import { filterDefinitionsFieldsByHelpPathKeys } from "@app/features/inventory/helpers";

interface UsePublisherFormDefinitionsFields {
    generalDefinitionsFields: DefinationField[];
    brandSafetyDefinitionsFields: DefinationField[];
    internalDefinitionsFields: DefinationField[];
    advancedFeaturesDefinitionsFields: DefinationField[];
    customPixelsDefinitionsFields: DefinationField[];
    affiliateDefinitionsFields: DefinationField[];
    labelsDefinitionsFields: DefinationField[];
    floorsDefinitionsFields: DefinationField[];
}

const GENERAL_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS: string[] = [
    SeatHelpKeys.PublisherExternalComment,
    SeatHelpKeys.SeatBusinessDomain,
    SeatHelpKeys.SeatAccountType,
];
const BRAND_SAFETY_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS: string[] = [];

const INTERNAL_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS: string[] = [];

const LABELS_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS: string[] = [SeatHelpKeys.GlobalLabels];

export const usePublisherFormDefinitionsFields = (): UsePublisherFormDefinitionsFields => {
    const { isSysAdmin, isPubAcctMgr, hasInternalAccess } = useUserAccess();

    const generalDefinitionsFields: DefinationField[] =
        isSysAdmin || isPubAcctMgr
            ? publisherHelpKeys[PublisherDrawerTitles.General]
            : filterDefinitionsFieldsByHelpPathKeys(
                  publisherHelpKeys[PublisherDrawerTitles.General],
                  GENERAL_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS
              );

    const brandSafetyDefinitionsFields: DefinationField[] = isSysAdmin
        ? publisherHelpKeys[PublisherDrawerTitles.BrandSafety]
        : filterDefinitionsFieldsByHelpPathKeys(
              publisherHelpKeys[PublisherDrawerTitles.BrandSafety],
              BRAND_SAFETY_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS
          );

    const internalDefinitionsFields: DefinationField[] =
        isSysAdmin || isPubAcctMgr
            ? publisherHelpKeys[PublisherDrawerTitles.Internal]
            : filterDefinitionsFieldsByHelpPathKeys(
                  publisherHelpKeys[PublisherDrawerTitles.Internal],
                  INTERNAL_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS
              );

    const advancedFeaturesDefinitionsFields: DefinationField[] =
        publisherHelpKeys[PublisherDrawerTitles.AdvancedFeatures];

    const customPixelsDefinitionsFields: DefinationField[] = publisherHelpKeys[PublisherDrawerTitles.CustomPixels];

    const labelsDefinitionsFields: DefinationField[] = hasInternalAccess
        ? publisherHelpKeys[PublisherDrawerTitles.Labels]
        : filterDefinitionsFieldsByHelpPathKeys(
              publisherHelpKeys[PublisherDrawerTitles.Labels],
              LABELS_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS
          );

    const floorsDefinitionsFields: DefinationField[] = publisherHelpKeys[PublisherDrawerTitles.Floors];
    const affiliateDefinitionsFields: DefinationField[] = publisherHelpKeys[PublisherDrawerTitles.Affiliate];

    return {
        generalDefinitionsFields,
        internalDefinitionsFields,
        brandSafetyDefinitionsFields,
        advancedFeaturesDefinitionsFields,
        affiliateDefinitionsFields,
        customPixelsDefinitionsFields,
        labelsDefinitionsFields,
        floorsDefinitionsFields,
    };
};
