import { LabeledValue } from "antd/es/select";

export const COLUMNS_NAME = {
    NAME: "name",
    REUSABLE_ADV_DOMAINS: "reusableAdvDomains",
    ADDT_ADV_DOMAINS: "addtAdvDomains",
    IAB_CATEGORIES: "iabCategories",
    TYPE: "type",
    ACTIONS: "actions",
};

export enum SECTION_NAMES {
    GENERAL = "GENERAL",
    TARGETING = "TARGETING",
}

export const HELP_KEYS = {
    [SECTION_NAMES.GENERAL]: {
        label: "General",
        helpKeys: [
            {
                key: "name",
                label: "Name",
                helpPath: "advancedbrandsafety.name",
            },
            {
                key: "type",
                label: "Type",
                helpPath: "advancedbrandsafety.type",
            },
            {
                key: "iabCategories",
                label: "Block IAB Categories",
                helpPath: "advancedbrandsafety.iabcategories",
            },
            {
                key: "reusableadvertiserdomains",
                label: "Reusable Advertiser Domains",
                helpPath: "advancedbrandsafety.reusableadvertiserdomains",
            },
            {
                key: "adomains",
                label: "Advertiser Domains",
                helpPath: "advancedbrandsafety.domains",
            },
        ],
    },
    [SECTION_NAMES.TARGETING]: {
        label: "Targeting",
        helpKeys: [
            {
                key: "TARGETING_BLOCKS",
                label: "Targeting Blocks",
                helpPath: "targetingdetails.blocks",
            },
        ],
    },
};

export interface InitialValuesType {
    id?: number;
    name: string;
    type: number;
    blockedIabCategories?: LabeledValue[];
    blockedExceptionIabCategories?: LabeledValue[];
    advertiserDomains: string;
    seatAdvertiserDomainFilterListDefs: LabeledValue[];
}

export const FORM_NAMES = {
    NAME: {
        label: "Name",
        name: "name",
    },
    TYPE: {
        label: "Type",
        name: "type",
    },
    IAB_CATEGORIES: {
        label: "IAB Block List",
        name: "blockedIabCategories",
    },
    IAB_EXCEPTIONS_CATEGORIES: {
        label: "IAB Category Block Exceptions",
        name: "blockedExceptionIabCategories",
    },
    REUSABLE_ADV_DOMAINS: {
        label: "Reusable Advertiser Domains",
        name: "seatAdvertiserDomainFilterListDefs",
    },
    ADDT_ADV_DOMAINS: {
        label: "Additional Advertiser Domains",
        name: "advertiserDomains",
    },
};

export const DEFAULT_BLOCK_TYPE_ID = 1;
