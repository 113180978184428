import { FC } from "react";
import { Card, Row, Col } from "antd";
import { Seat } from "@app/core/services";
import { INVENTORY_CARD_BODY_STYLE } from "@app/features/inventory/constants";
import { DefaultExtendedImpressionWaitTime } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/SeatDetailsFormFields/SeatDefaultsInternalOnly/Fields";

interface SeatDefaultsInternalOnlyProps {
    seat: Seat;
    edit?: boolean;
}

const COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 };

export const SeatDefaultsInternalOnly: FC<SeatDefaultsInternalOnlyProps> = ({ seat, edit = false }) => {
    return (
        <Card title="Internal Only" type="inner" bodyStyle={INVENTORY_CARD_BODY_STYLE}>
            <Row gutter={16}>
                <Col {...COL_SIZES}>
                    <DefaultExtendedImpressionWaitTime seat={seat} edit={edit} />
                </Col>
            </Row>
        </Card>
    );
};
