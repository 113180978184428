import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { Form } from "antd";

export const useBvodConnectDemographic = () => {
    const allowBvodConnect = Form.useWatch<AdSourcesForm["allowOztam"]>(AD_SOURCE_FIELDS.BVOD_CONNECT_ID.name);
    const isVisible = allowBvodConnect;
    return {
        isVisible,
    };
};
