import { useGetTimeZonesQuery } from "@app/core/services";
import { useSeatAuthContext } from "@app/core/auth";
import { useContext, useEffect } from "react";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { useIsReadOnly } from "./useIsReadOnly";
import { Form } from "antd";
import { AdSourceFormsContext } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { useSyncedFieldsProducer } from "@app/features/syncedFields";
import { useIsAdSourceStartDateApiReadOnly } from "../StartDatePicker";

const { useFormInstance } = Form;

export const useTimeZoneSelect = () => {
    const { setFieldsValue } = useFormInstance();
    const { data: timezones, isLoading } = useGetTimeZonesQuery();
    const { context } = useSeatAuthContext();
    const {
        forms: { adSourceForm, adSourceFormMode },
    } = useContext(AdSourceFormsContext);
    const { updateSyncedFields } = useSyncedFieldsProducer();
    const isApiReadOnly = useIsAdSourceStartDateApiReadOnly();

    useEffect(() => {
        if (context && context?.timeZone && adSourceFormMode === "create") {
            setFieldsValue({ [AD_SOURCE_FIELDS.TIME_ZONE.name]: context.timeZone });
        }
    }, [adSourceFormMode, context, setFieldsValue]);

    const isReadOnly = useIsReadOnly();

    const onChange = (value) => {
        updateSyncedFields({ timeZone: value });
        adSourceForm.validateFields([AD_SOURCE_FIELDS.START_DATE.name, AD_SOURCE_FIELDS.END_DATE.name]);
    };

    return { timezones, isTimezonesLoading: isLoading, isReadOnly, isApiReadOnly, onChange };
};
