import { FC } from "react";
import { Form } from "antd";
import { SeatBuyerSeatLists } from "@app/features/inventory/components/Fields";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const BlockBuyerSeatList: FC = () => {
    return (
        <Form.Item
            name={AD_UNIT_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.name}
            label={AD_UNIT_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.label}
        >
            <SeatBuyerSeatLists placeholder={AD_UNIT_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.placeholder} />
        </Form.Item>
    );
};
