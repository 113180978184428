import { VIEW_GRAPH_OPTIONS } from "./constants";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { ActionCreatorWithOptionalPayload } from "@reduxjs/toolkit";
import { setFiltersMetricOne, setFiltersMetricTwo } from "../reducer";
import { LabeledValue } from "antd/lib/select";

type HandleChange = (id: number | undefined, option: LabeledValue | LabeledValue[]) => void;

interface UseChartMetricsFilter {
    metricOneOptions: LabeledValue[];
    metricTwoOptions: LabeledValue[];
    metricOneValue: LabeledValue;
    metricTwoValue: LabeledValue;
    handleChangeMetricOneValue: HandleChange;
    handleChangeMetricTwoValue: HandleChange;
}

export const useChartMetricsFilters = (): UseChartMetricsFilter => {
    const dispatch = useAppDispatch();
    const view = useAppSelector((state) => state.dashboard.filters.view);
    const metricOneFilter = useAppSelector((state) => state.dashboard.filters.metricOne);
    const metricTwoFilter = useAppSelector((state) => state.dashboard.filters.metricTwo);

    const metricFilterOptions = (filterNo: number, viewFilter: LabeledValue, otherMetricFilterValue: LabeledValue) => {
        return VIEW_GRAPH_OPTIONS[viewFilter.value]
            .filter((option) => [0, filterNo].includes(option.kind) && option.id !== otherMetricFilterValue.value)
            .map((option) => ({
                value: option.id,
                label: option.name,
            }));
    };

    const handleChangeValue =
        (action: ActionCreatorWithOptionalPayload<LabeledValue | null>) =>
        (id: number | undefined, option: LabeledValue | LabeledValue[]) => {
            if (!id || Array.isArray(option)) {
                return;
            }
            dispatch(action(option));
        };

    return {
        metricOneOptions: metricFilterOptions(1, view, metricTwoFilter),
        metricTwoOptions: metricFilterOptions(2, view, metricOneFilter),
        metricOneValue: metricOneFilter,
        metricTwoValue: metricTwoFilter,
        handleChangeMetricOneValue: handleChangeValue(setFiltersMetricOne),
        handleChangeMetricTwoValue: handleChangeValue(setFiltersMetricTwo),
    };
};
