import { Form } from "antd";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";

const { useWatch } = Form;

interface UsePacingFormData {
    adSourceTypeId: number | undefined;
    pacingTypeId: number | undefined;
    isDailyImpressionEnabled: boolean | undefined;
    totalImpressions: number | undefined;
}

export const usePacingFormData = (): UsePacingFormData => {
    const adSourceTypeId = useWatch(AD_SOURCE_FIELDS.TYPE.name)?.id;
    const pacingTypeId = useWatch(AD_SOURCE_FIELDS.PACING_TYPE.name)?.id;
    const isDailyImpressionEnabled = useWatch(AD_SOURCE_FIELDS.DAILY_IMPRESSION_CAP_ENABLED.name);
    const totalImpressions = useWatch(AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.name);

    return {
        adSourceTypeId,
        pacingTypeId,
        isDailyImpressionEnabled,
        totalImpressions,
    };
};
