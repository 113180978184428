import { FC } from "react";
import { Space } from "antd";
import { CurrencyConversionModeField } from "./CurrencyConversionModeField";
import { PreferredCurrencyField } from "./PreferredCurrencyField";
import { useUpdateSettingsOnChange } from "./useUpdateSettingsOnChange";

export const CurrencyFilters: FC = () => {
    useUpdateSettingsOnChange();
    return (
        <Space.Compact>
            <CurrencyConversionModeField />
            <PreferredCurrencyField />
        </Space.Compact>
    );
};
