import { FC } from "react";
import { useUserAccess } from "@app/core/auth";
import { ItemType } from "rc-collapse/es/interface";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Loading, Title } from "@app/core/components";
import { Button, Col, Form, Row, Collapse } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useGetSeatSelfQuery } from "@app/core/services";
import {
    INVENTORY_SEAT_ADVANCED,
    INVENTORY_HEADER_COLLAPSE_CLASS_LIST,
} from "@app/features/inventory/InventorySeat/constants";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import {
    CTVToolsSection,
    AdminToolsSection,
    AdResponsesSection,
    CreativeControlSection,
    DemandManagementSection,
    UnifiedDecisioningSection,
    InventoryManagementSection,
    IdentityAndRegulationSection,
} from "@app/features/inventory/InventorySeat/AdvancedFeaturesPage/AdvancedFeaturesSections";
import { useAdvancedFeaturesDefenitionsFields } from "@app/features/inventory/InventorySeat/AdvancedFeaturesPage/useAdvancedFeaturesDefenitionsFields";

export const AdvancedFeaturesPage: FC = () => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const { data, isFetching } = useGetSeatSelfQuery(seatId);
    const { hasSeatWriteAccess, isSysAdmin, isPubAcctMgr } = useUserAccess();
    const hasInternalAccess: boolean = isSysAdmin || isPubAcctMgr;

    const {
        CTVToolsDefinitionsFields,
        adminToolsDefinitionsFields,
        adResponsesDefinitionsFields,
        creativeControlDefinitionsFields,
        demandManagementDefinitionsFields,
        unifiedDecisioningDefinitionsFields,
        inventoryManagementDefinitionsFields,
        identityAndRegulationsDefinitionsFields,
    } = useAdvancedFeaturesDefenitionsFields();

    const handleClick = (): void =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_SEAT_EDIT_TABS(seatId, INVENTORY_SEAT_ADVANCED));

    if (!data?.seat || isFetching) return <Loading />;

    return (
        <Form layout="vertical">
            {hasSeatWriteAccess && (
                <Row justify="end">
                    <Col>
                        <Button onClick={handleClick} type="primary">
                            Edit
                        </Button>
                    </Col>
                </Row>
            )}
            <Collapse
                bordered
                ghost={false}
                className={INVENTORY_HEADER_COLLAPSE_CLASS_LIST}
                defaultActiveKey={["0", "1", "2", "3", "4", "5", "6", "7"]}
                items={
                    [
                        {
                            key: "0",
                            collapsible: "header",
                            children: <InventoryManagementSection seat={data?.seat} />,
                            label: <Title level={5} title="Inventory Management" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Inventory Management"
                                    definitionsFields={inventoryManagementDefinitionsFields}
                                />
                            ),
                        },
                        {
                            key: "1",
                            collapsible: "header",
                            children: <CTVToolsSection seat={data?.seat} />,
                            label: <Title level={5} title="CTV Tools" />,
                            extra: (
                                <HelpKeysDrawer panelLabel="CTV Tools" definitionsFields={CTVToolsDefinitionsFields} />
                            ),
                        },
                        {
                            key: "2",
                            collapsible: "header",
                            children: <IdentityAndRegulationSection seat={data?.seat} />,
                            label: <Title level={5} title="Identity and Regulations" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Identity and Regulations"
                                    definitionsFields={identityAndRegulationsDefinitionsFields}
                                />
                            ),
                        },
                        hasInternalAccess && {
                            key: "3",
                            collapsible: "header",
                            children: <CreativeControlSection seat={data?.seat} />,
                            label: <Title level={5} title="Creative Control (Internal Only)" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Creative Control"
                                    definitionsFields={creativeControlDefinitionsFields}
                                />
                            ),
                        },
                        hasInternalAccess && {
                            key: "4",
                            collapsible: "header",
                            children: <AdResponsesSection seat={data?.seat} />,
                            label: <Title level={5} title="Ad Responses (Internal Only)" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Ad Responses"
                                    definitionsFields={adResponsesDefinitionsFields}
                                />
                            ),
                        },
                        {
                            key: "5",
                            collapsible: "header",
                            children: <DemandManagementSection seat={data?.seat} />,
                            label: <Title level={5} title="Demand Management" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Demand Management"
                                    definitionsFields={demandManagementDefinitionsFields}
                                />
                            ),
                        },
                        hasInternalAccess && {
                            key: "6",
                            collapsible: "header",
                            children: <UnifiedDecisioningSection seat={data?.seat} />,
                            label: <Title level={5} title="Unified Decisioning (Internal Only)" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Unified Decisioning"
                                    definitionsFields={unifiedDecisioningDefinitionsFields}
                                />
                            ),
                        },
                        hasInternalAccess && {
                            key: "7",
                            collapsible: "header",
                            children: <AdminToolsSection seat={data?.seat} />,
                            label: <Title level={5} title="Admin Tools (Internal Only)" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Admin Tools"
                                    definitionsFields={adminToolsDefinitionsFields}
                                />
                            ),
                        },
                    ].filter(Boolean) as ItemType[]
                }
            />
        </Form>
    );
};
