import { useNotificationError } from "@app/core/components/hooks";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useDeleteSeatContactMutation } from "@app/core/services";
import { message, notification, Typography } from "antd";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { SeatContactPayload } from "../types";

export const useSeatContactsDelete = (contact: SeatContactPayload) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [deleteSeatContact, { isLoading, error }] = useDeleteSeatContactMutation();
    const { seatId } = useParams<{ seatId: string }>();
    useNotificationError(error);
    const history = useHistory();

    const handleOpenModal = () => setOpen(true);
    const handleCloseModal = () => setOpen(false);

    const handleDelete = () => {
        deleteSeatContact(contact.id)
            .unwrap()
            .then(() => {
                message.success(
                    <>
                        <Typography.Text strong>{contact.name}</Typography.Text> has successfully been deleted
                    </>
                );
                history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, "seat-contacts"));
            })
            .catch((err) => {
                notification.error({
                    message:
                        err.data?.errorDescription ||
                        err.data?.errorCode ||
                        "Something wrong has occurred, please contact your account manager",
                });
            });
    };

    return {
        handleOpenModal,
        handleCloseModal,
        isOpen,
        isLoading,
        handleDelete,
    };
};
