import { FC } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Brand } from "@app/core/services";
import { useHistory } from "react-router-dom";

interface BrandEditPageHeaderProps {
    brand: Brand;
}

export const BrandEditPageHeader: FC<BrandEditPageHeaderProps> = ({ brand }) => {
    const history = useHistory();
    const onBack = (): void => history.goBack();
    return <PageHeader title={`Edit Brand: ${brand.name}`} subTitle={brand.code} onBack={onBack} />;
};
