import { Affix, Button } from "antd";
import { Link, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { PageHeader } from "@ant-design/pro-layout";

export const SEAT_CONTACTS_CREATE_BTN_SDET = "seat-contacts-create-button";

export const SeatContactsHeader = () => {
    const { seatId } = useParams<{ seatId: string }>();

    return (
        <Affix>
            <PageHeader
                ghost={false}
                title="Seat Contacts"
                extra={
                    <Link
                        data-sdet={SEAT_CONTACTS_CREATE_BTN_SDET}
                        to={ROUTE_FORMATTERS.SEAT_CONTROLS_SEAT_CONTACTS_CREATE(seatId)}
                    >
                        <Button type="primary">New Contact</Button>
                    </Link>
                }
            />
        </Affix>
    );
};

export default SeatContactsHeader;
