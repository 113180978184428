import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useDeleteAdvancedBrandSafetyMutation } from "@app/core/services";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

export interface DeleteItem {
    id: number;
    name: string;
}

interface UseInventoryAdvancedBrandSafetyPage {
    deleteName?: string;
    isDeleting: boolean;
    handleAdd: () => void;
    handleDelete: () => void;
    openDeleteModa: boolean;
    handleDetails: (id: number) => void;
    handleEdit: (id: number) => void;
    handleCancelDeleteModal: () => void;
    handleDeleteModal: (item: DeleteItem) => void;
    searchValue: string;
    handleSearch: (value: string) => void;
}

export const useInventoryAdvancedBrandSafetyPage = (): UseInventoryAdvancedBrandSafetyPage => {
    const { seatId } = useParams<{ seatId: string }>();
    const [searchValue, setSearchValue] = useState("");
    const history = useHistory();
    const [deleteBrandSafety, setDeleteBrandSafety] = useState<DeleteItem | null>(null);
    const [handleDeleteAdvancedBrandSafety, { isLoading: isDeleting }] = useDeleteAdvancedBrandSafetyMutation();

    const handleDeleteModal = (item: DeleteItem) => {
        setDeleteBrandSafety(item);
    };

    const handleSearch = (value: string) => {
        setSearchValue(value);
    };

    const handleCancelDeleteModal = () => setDeleteBrandSafety(null);

    const handleDelete = async () => {
        await handleDeleteAdvancedBrandSafety(Number(deleteBrandSafety?.id));
        handleCancelDeleteModal();
    };

    const handleDetails = (id: number) => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_DETAILS(seatId, id));
    };

    const handleAdd = () => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_CREATE(seatId));
    };

    const handleEdit = (id: number) => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_EDIT(seatId, id));
    };

    return {
        handleAdd,
        isDeleting,
        handleEdit,
        handleDelete,
        handleDetails,
        handleDeleteModal,
        deleteName: deleteBrandSafety?.name,
        openDeleteModa: Boolean(deleteBrandSafety?.id),
        handleCancelDeleteModal,
        handleSearch,
        searchValue,
    };
};
