import { FC, useCallback, useContext } from "react";
import { Col, Form } from "antd";
import { CurrencyInput } from "@app/core/components/CurrencyInput";
import { AD_SOURCE_FIELDS, COL_SIZES } from "@app/features/seatAdSources/constants";
import { useIsFieldReadOnly } from "./useIsFieldReadOnly";
import { useIsFieldVisible } from "./useIsFieldVisible";
import {
    getCurrencyConversionRate,
    getMinFormNumber,
    isFixedPriceModel,
    useSyncedFieldsProducer,
} from "@app/features/syncedFields";
import { AdSourceFormsContext } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { MarketplaceInfo, useGetCurrenciesConversionsQuery } from "@app/core/services";
import { formatNumber } from "@rubicon/utils";

export const FixedCpmInput: FC = () => {
    const isVisible = useIsFieldVisible();
    const isReadOnly = useIsFieldReadOnly();
    const currencyCode = Form.useWatch(AD_SOURCE_FIELDS.CURRENCY.name)?.code;
    const { updateSyncedFields } = useSyncedFieldsProducer();
    const { adSourceForm } = useContext(AdSourceFormsContext).forms;
    const { data: currencyConversions } = useGetCurrenciesConversionsQuery();

    const onBlur = useCallback(
        (value: string, isDirty: boolean) => {
            if (isDirty) {
                updateSyncedFields({ cpmRate: value });
            }
        },
        [updateSyncedFields]
    );

    const minGroupFixedPriceRule = () => ({
        validator: (_, value: string) => {
            const adSourceCpmRate = Number(value);

            if (!adSourceCpmRate) {
                return Promise.resolve();
            }

            const adSourceTypeId = adSourceForm.getFieldValue(AD_SOURCE_FIELDS.TYPE.name)?.id;
            const adSourceFloorTypeId = adSourceForm.getFieldValue(AD_SOURCE_FIELDS.FLOOR_TYPE.name)?.id;
            const adSourceCurrency = adSourceForm.getFieldValue(AD_SOURCE_FIELDS.CURRENCY.name);
            const demand = adSourceForm.getFieldValue(AD_SOURCE_FIELDS.DEMAND.name);
            const hasMultipleDeals = demand?.length > 1;

            if (isFixedPriceModel(adSourceTypeId, adSourceFloorTypeId) && hasMultipleDeals) {
                const convertedDealRates: number[] = demand
                    .filter((deal: MarketplaceInfo) => isFixedPriceModel(adSourceTypeId, deal.deal.dealPricingType?.id))
                    .map((deal: MarketplaceInfo) =>
                        deal.deal.rate
                            ? Number(deal.deal.rate) *
                              getCurrencyConversionRate(
                                  deal.deal.currencyType?.id,
                                  adSourceCurrency?.id,
                                  currencyConversions
                              )
                            : null
                    )
                    .filter((rate: number | null) => rate !== null);
                const minConvertedDealRate = getMinFormNumber(convertedDealRates);

                if (minConvertedDealRate && adSourceCpmRate > minConvertedDealRate) {
                    // take the floor of the min rate to avoid because the converted rate can have more than two decimals
                    const minRateFloor = Math.floor(minConvertedDealRate * 100) / 100;
                    return Promise.reject(
                        `The Ad Source CPM must be less than or equal to ${formatNumber.asMoney(
                            minRateFloor,
                            adSourceCurrency?.code
                        )}`
                    );
                }
            }

            return Promise.resolve();
        },
    });

    const comp = (
        <CurrencyInput
            hidden={!isVisible}
            dataSdetPrefix="test-bookingPrice"
            name={AD_SOURCE_FIELDS.FIXED_CPM.name}
            label={AD_SOURCE_FIELDS.FIXED_CPM.label}
            currencyCode={currencyCode}
            help={isReadOnly ? "Inherited from Demand Deal" : undefined}
            disabled={isReadOnly}
            onBlur={onBlur}
            required
            additionalRules={[minGroupFixedPriceRule]}
        />
    );

    // We still need to render the component so the form value doesn't
    // get set to undefined, though we don't want the column to render
    if (!isVisible) {
        return comp;
    }

    return <Col {...COL_SIZES}>{comp}</Col>;
};
