import { isEqualWith } from "lodash";
import { customizerOfEqual } from "../../../helpers";
import { useDispatch } from "react-redux";
import { Form } from "antd";
import { TouchedFieldInfo } from "../../../reducer";
import { AnyAction } from "@reduxjs/toolkit";
import { useMemo } from "react";

export const useBulkOperationEntitiesEditTableRevert = <
    T extends { id: number; externalName?: string | null; name?: string | null }
>(
    formItemName: string,
    derivedEntities: T[],
    touchedRecordsInfo: Record<number, TouchedFieldInfo[] | undefined>,
    setFieldChange: (fieldInfo: TouchedFieldInfo & { id: number }) => AnyAction,
    removeTouchedRecordInfo: (id?: number) => AnyAction
) => {
    const dispatch = useDispatch();
    const form = Form.useFormInstance();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleValueChange = (record: T, key: string, title: string, newValue: any) => {
        const originValue = record[key];
        const currentValue = newValue?.target ? newValue.target.value : newValue;
        const isChanged = !isEqualWith(originValue, currentValue, customizerOfEqual);

        dispatch(
            setFieldChange({
                id: record.id,
                name:
                    (["buyerDeals", "demandDeals"].includes(formItemName) ? record.externalName : record.name) || null,
                field: key,
                title,
                originValue,
                currentValue,
                isChanged,
            })
        );
    };

    const handleRevert = (record: T) => {
        form.setFieldValue([formItemName, String(record.id)], record);
        dispatch(removeTouchedRecordInfo(record.id));
    };

    const handleRevertAll = () => {
        const tableFieldsValue = derivedEntities.reduce((acc, derivedDemandDeal) => {
            acc[derivedDemandDeal.id] = derivedDemandDeal;
            return acc;
        }, {});

        form.setFieldValue(formItemName, tableFieldsValue);
        dispatch(removeTouchedRecordInfo());
    };

    const hasChangedField = useMemo(
        () =>
            Object.values(touchedRecordsInfo)
                .filter(Boolean)
                .flat()
                .some(({ isChanged }: TouchedFieldInfo) => isChanged),
        [touchedRecordsInfo]
    );

    return {
        handleValueChange,
        handleRevert,
        handleRevertAll,
        hasChangedField,
    };
};
