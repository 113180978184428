import { css } from "@emotion/css";
import { intl } from "@rubicon/utils";
import { Divider } from "antd";
import { FC } from "react";
import { BucketedColumnData, TooltipFilledUnFilledCumulativeData, TooltipFilledUnFilledData } from "../helpers";
import { LINES_COLORS } from "../useTimingStatsChartConfig";
import { ChartTooltipItem } from "./ChartTooltipItem";

const classListDivider = css`
    margin-top: 0;
    margin-bottom: 0;
`;

export const TOOLTIP_NAMES = {
    filled: "# Filled",
    unFilled: "# Unfilled",
    avgTiming: "Avg. Timing",
    runningCount: "Running Count",
    runningAvgTiming: "Running Avg. Timing",
    cumulativePerc: "Cumulative Percentage",
    cumulativeTotal: "Cumulative Total",
    cumulativeTotalPerc: "Cumulative Total %",
};

interface Props {
    filledUnfilledData?: TooltipFilledUnFilledData;
    cumulativeData?: TooltipFilledUnFilledCumulativeData;
    hasDivider: boolean;
}
export const FilledUnFilled: FC<Props> = ({ cumulativeData, filledUnfilledData, hasDivider = true }) => {
    const filledData = filledUnfilledData?.data || ({} as BucketedColumnData);
    const type = filledData?.type;
    const color = filledUnfilledData?.color as string;
    const count = intl.numberFormatter(filledData?.y);
    const avgTiming = Math.round(filledData?.avg || 0);
    const runningCount = intl.numberFormatter(filledData?.runningAvgCount);
    const runningAvgTiming = parseInt(String(filledData?.runningAvgCount ? filledData?.totalTime / filledData?.y : 0));

    const cumulativePercentage = `${intl.numberFormatter(Number((cumulativeData?.data?.count || 0).toFixed(1)))}%`;
    const cumulativeColor = cumulativeData?.color || LINES_COLORS[`${type}Cumulative`];
    return (
        <>
            <ChartTooltipItem name={TOOLTIP_NAMES[type]} value={count} tooltipColor={color} />
            <ChartTooltipItem name={TOOLTIP_NAMES.avgTiming} value={`${avgTiming} ms`} />
            <ChartTooltipItem name={TOOLTIP_NAMES.runningCount} value={runningCount} />
            <ChartTooltipItem name={TOOLTIP_NAMES.runningAvgTiming} value={`${runningAvgTiming} ms`} />
            <ChartTooltipItem
                name={TOOLTIP_NAMES.cumulativePerc}
                value={cumulativePercentage}
                tooltipColor={cumulativeColor}
            />
            {hasDivider && <Divider className={classListDivider} />}
        </>
    );
};
