import { FC } from "react";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { AdUnitCompetitiveSeparationMode } from "@app/features/inventory/components/FormItems";

export const CompetitiveSeparationMode: FC = () => {
    return (
        <AdUnitCompetitiveSeparationMode
            name={AD_UNIT_FORM_FIELDS.COMPETITIVE_SEPARATION_MODE.name}
            label={AD_UNIT_FORM_FIELDS.COMPETITIVE_SEPARATION_MODE.label}
        />
    );
};
