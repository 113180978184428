import moment from "moment-timezone";

export const getCompletionPercentage = (
    startDate: string,
    endDate: moment.Moment,
    targetDate: moment.Moment
): number => {
    const campaignDays = endDate.diff(startDate, "days");
    const targetDays = targetDate.diff(startDate, "days");
    return (targetDays + 1) / campaignDays;
};

export const PERCENTAGE = 100;

export const getPercentage = (impression: number, totalImpressions: number) => {
    if (!totalImpressions) {
        return 0;
    }
    return Math.round((impression / totalImpressions) * PERCENTAGE);
};

export const xAxisLabelFormatter = (text: string, startDate: string) => {
    return moment(startDate).add(Number(text), "days").format("DD");
};
