import { Form } from "antd";
import { MarketplaceInfo, SeatAdSourcesType } from "@app/core/services";
import {
    AD_SOURCE_FIELDS,
    AdSourceTypeIds,
    DealEntityTypes,
    PriceModelEligibleAdSourceTypeIds,
} from "@app/features/seatAdSources/constants";
import { findDealsWithSpecificTypes } from "@app/features/seatAdSources/SeatAdSourcesForm/utils";

const { useWatch } = Form;
const { AUTOMATED_GUARANTEED, CONDITIONAL_AUTOMATED_GUARANTEED } = AdSourceTypeIds;

interface UsePriceModelSelect {
    isVisible: boolean;
    isInheritedReadOnly: boolean;
}

export const usePriceModelSelect = (): UsePriceModelSelect => {
    const adSourceTypeId = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name)?.id;
    const deals = useWatch<MarketplaceInfo[]>(AD_SOURCE_FIELDS.DEMAND.name);
    const demandOrBuyerDeals = findDealsWithSpecificTypes({
        deals,
        entityTypes: [DealEntityTypes.Demand, DealEntityTypes.Buyer],
    });

    const isVisible = PriceModelEligibleAdSourceTypeIds.includes(adSourceTypeId);

    const isInheritedReadOnly =
        [AUTOMATED_GUARANTEED, CONDITIONAL_AUTOMATED_GUARANTEED].includes(adSourceTypeId) &&
        Boolean(demandOrBuyerDeals.length);

    return { isVisible, isInheritedReadOnly };
};
