import { useEffect, useState } from "react";
import { AdSourceListItem, GetSeatAdSourcesParams } from "@app/core/services";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useFetchSeatAdSources } from "@app/features/seatAdSources/data";
import {
    updateGridViewPriorityFetchStatus,
    selectSeatAdSourcesListGridViewPrimaryPriority,
    selectSeatAdSourcesListGridViewFilteredPriorityCandidates,
    gridViewPriorityAdSourcesReceived,
} from "../../reducer";
import { useSeatAdSourcesListFilters } from "../../SeatAdSourcesListFilters";

interface UseUseSeatAdSourcesListGridPriorityCollapse {
    adSources: AdSourceListItem[];
    total: number;
    isFetching: boolean;
    isUpdatingPagination: boolean;
    hasAdSources: boolean;
    primaryPriority: number;
    isExpanded: boolean;
    toggleExpanded: (expandedKeys: string[]) => void;
}

export const useSeatAdSourcesPriorityCollapse = (priority: number): UseUseSeatAdSourcesListGridPriorityCollapse => {
    const dispatch = useAppDispatch();

    const seatAllAdSourcesParams = useSeatAdSourcesListFilters().seatAllAdSourcesParams as GetSeatAdSourcesParams;
    const priorityCandidate = useAppSelector(selectSeatAdSourcesListGridViewFilteredPriorityCandidates)[priority];

    const { page, max } = priorityCandidate.pagination;
    seatAllAdSourcesParams.priority = priority;
    seatAllAdSourcesParams.page = page;
    seatAllAdSourcesParams.max = max;

    const { adSourcesListResponse, isFetching } = useFetchSeatAdSources(seatAllAdSourcesParams);
    const adSources = adSourcesListResponse?.adSources;
    const total = adSourcesListResponse?.totalResults || 0;
    const hasAdSources = adSources?.length > 0;

    const isUpdatingPagination = priorityCandidate.isUpdatingPagination;

    const primaryPriority = useAppSelector(selectSeatAdSourcesListGridViewPrimaryPriority);

    const [isExpanded, setIsExpanded] = useState<boolean>(priority === primaryPriority);
    const toggleExpanded = (expandedKeys: string[]) => {
        setIsExpanded(expandedKeys.includes(String(priority)));
    };

    useEffect(() => {
        if (primaryPriority === priority) {
            setIsExpanded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [primaryPriority]);

    useEffect(() => {
        dispatch(
            updateGridViewPriorityFetchStatus({
                priority: priority,
                isFetching: isFetching,
                hasAdSources: hasAdSources,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching]);

    useEffect(() => {
        if (adSources) {
            dispatch(gridViewPriorityAdSourcesReceived({ priority }));
        }
    }, [adSources, dispatch, priority]);

    return {
        adSources,
        total,
        isFetching,
        isUpdatingPagination,
        hasAdSources,
        primaryPriority,
        isExpanded,
        toggleExpanded,
    };
};
