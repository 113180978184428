import { Button, Space } from "antd";
import { FC } from "react";

export const CLASSIFICATION_ADVERTISER_TRANSFER_DRAWER_FOOTER_SDET =
    "classifications-advertiser-transfer-drawer-footer";
export const CLASSIFICATION_ADVERTISER_TRANSFER_DRAWER_FOOTER_SAVE_BUTTON_SDET =
    "classifications-advertiser-transfer-drawer-footer-save-btn";
export const CLASSIFICATION_ADVERTISER_TRANSFER_DRAWER_FOOTER_CANCEL_BUTTON_SDET =
    "classifications-advertiser-transfer-drawer-footer-cancel-btn";

interface Props {
    onSave: () => void;
    onCancel: () => void;
    isLoading: boolean;
}
export const ClassificationsAdvertiserTransferDrawerFooter: FC<Props> = ({ onSave, onCancel, isLoading }) => {
    return (
        <Space data-sdet={CLASSIFICATION_ADVERTISER_TRANSFER_DRAWER_FOOTER_SDET}>
            <Button
                data-sdet={CLASSIFICATION_ADVERTISER_TRANSFER_DRAWER_FOOTER_SAVE_BUTTON_SDET}
                type="primary"
                onClick={onSave}
                loading={isLoading}
                disabled={isLoading}
            >
                Save
            </Button>
            <Button data-sdet={CLASSIFICATION_ADVERTISER_TRANSFER_DRAWER_FOOTER_CANCEL_BUTTON_SDET} onClick={onCancel}>
                Cancel
            </Button>
        </Space>
    );
};
