import { useParams } from "react-router-dom";
import { useNestedData } from "../useNestedData";
import { useGetSeatAdsTextQuery } from "@app/core/services";

interface Props {
    unitId: string | number;
}

interface UseAdsTxtContent {
    isLoading: boolean;
    breadcrumbs?: string;
    adsTxtContent?: string;
}

export const useAdsTxtContent = ({ unitId }: Props): UseAdsTxtContent => {
    const { nestedBreadcrumbs } = useNestedData(unitId);
    const { seatId, publisherId } = useParams<{ seatId: string; publisherId: string }>();

    const { data: adsTxtContent, isLoading } = useGetSeatAdsTextQuery({
        id: Number(publisherId) ? Number(publisherId) : Number(seatId),
        isSeat: !publisherId,
    });

    return {
        isLoading,
        breadcrumbs: nestedBreadcrumbs,
        adsTxtContent,
    };
};
