import { FC } from "react";
import { Card, Col, Row } from "antd";
import {
    AllowDemandUnderInboundFloor,
    Boxing,
    ContentChannel,
    ContentNetwork,
    CreativeSamplesMinutes,
    DeliveryMethods,
    ExtendedImpWaitTime,
    Interstitial,
    Linearity,
    LiveStream,
    LiveStreamAcceleration,
    Multiplicity,
    SSAIType,
    Skippable,
    SkippableAfter,
    SkippableMinimum,
    StartDelay,
    Type,
    UDOptOut,
    UnwrapVast,
    VideoPlaybacks,
} from "./Fields";
import { useSupplyDetailsSection } from "./useSupplyDetailsSection";
import { TWO_COL_SIZES } from "@app/features/inventory/constants";
import { InternalOnly } from "@app/features/inventory/components/InternalOnly";
import { useUserAccess } from "@app/core/auth";

const INPUTS_COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 };
export const SupplyDetailsSection: FC = () => {
    const { isTremorUser } = useUserAccess();
    const { isTypeShown, isStartDelayShown, isSkippableMinimumAfterShown } = useSupplyDetailsSection();
    return (
        <Card bordered={false}>
            <Row gutter={16}>
                <Col {...TWO_COL_SIZES}>
                    <Row>
                        <Linearity />
                    </Row>
                    <Row>
                        <Multiplicity />
                    </Row>
                    {isTypeShown && (
                        <Row gutter={16}>
                            <Col {...INPUTS_COL_SIZES}>
                                <Type />
                            </Col>
                            {isStartDelayShown && (
                                <Col {...INPUTS_COL_SIZES}>
                                    <StartDelay />
                                </Col>
                            )}
                        </Row>
                    )}
                    <Row gutter={16}>
                        <Col {...INPUTS_COL_SIZES}>
                            <VideoPlaybacks />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col {...INPUTS_COL_SIZES}>
                            <DeliveryMethods />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col {...INPUTS_COL_SIZES}>
                            <Skippable />
                        </Col>
                        <Col {...INPUTS_COL_SIZES}>
                            <Interstitial />
                        </Col>
                    </Row>
                    {isSkippableMinimumAfterShown && (
                        <Row gutter={16}>
                            <Col {...INPUTS_COL_SIZES}>
                                <SkippableAfter />
                            </Col>
                            <Col {...INPUTS_COL_SIZES}>
                                <SkippableMinimum />
                            </Col>
                        </Row>
                    )}
                    <Row gutter={16}>
                        <Col {...INPUTS_COL_SIZES}>
                            <SSAIType />
                        </Col>
                        <Col {...INPUTS_COL_SIZES}>
                            <Boxing />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <CreativeSamplesMinutes />
                    </Row>
                    <Row>
                        <UnwrapVast />
                    </Row>
                    <Row>
                        <LiveStream />
                    </Row>

                    <Row>
                        <Col {...INPUTS_COL_SIZES}>
                            {/* TODO: Visible when supple.isDynamic = true */}
                            <ContentChannel />
                        </Col>
                    </Row>
                    <Row>
                        <Col {...INPUTS_COL_SIZES}>
                            {/* TODO: Visible when supple.isDynamic = true */}
                            <ContentNetwork />
                        </Col>
                    </Row>
                    <Row>
                        <UDOptOut />
                    </Row>
                    <Row>
                        <AllowDemandUnderInboundFloor />
                    </Row>
                    <Row>
                        <LiveStreamAcceleration />
                    </Row>
                </Col>
                <Col hidden={!isTremorUser} {...TWO_COL_SIZES}>
                    <InternalOnly>
                        <ExtendedImpWaitTime />
                    </InternalOnly>
                </Col>
            </Row>
        </Card>
    );
};
