import { ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { string as stringUtils } from "@rubicon/utils";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormInputFieldChange } from "@app/features/deals/DealForm/reducer";
import { useDispatchOnBlur } from "@app/features/deals/DealForm/hooks";

interface UseDealIdField {
    value: string;
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleGenerateId: () => void;
    handleBlur: () => void;
}

export const useDealIdField = (): UseDealIdField => {
    const dispatch = useAppDispatch();
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.ID]);
    const { onChange, onBlur } = useDispatchOnBlur(value);

    const handleGenerateId = (): void => {
        const uuid = stringUtils.createUUID();
        onChange(uuid);
        dispatch(dealFormInputFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.ID, value: uuid }));
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        onChange(value);
    };
    const handleBlur = () => {
        onBlur(dealFormInputFieldChange, CREATE_DEAL_FORM_ITEMS_NAME.ID);
    };

    return {
        value,
        handleBlur,
        handleChange,
        handleGenerateId,
    };
};
