import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { formatNumber } from "@rubicon/utils";

interface UseAdSourceTotalImpressionsField {
    value: number | string;
}

export const useAdSourceTotalImpressionsField = (): UseAdSourceTotalImpressionsField => {
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.IMPRESSION]);
    return {
        value: formatNumber.asNumber(value),
    };
};
