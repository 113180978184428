import { FC } from "react";
import { Form, Select } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAdSourceIabCategoryBlockModeField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceBrandSafetySection/Fields/AdSourceIABCategoryBlockModeField/useAdSourceIabCategoryBlockModeField";

export const AdSourceIABCategoryBlockModeField: FC = () => {
    const { value, onChange, options, disabled, isFetching } = useAdSourceIabCategoryBlockModeField();
    return (
        <Form.Item
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_IAB_CATEGORY_BLOCK_MODE}
            data-sdet="ad-source-iab-category-block-mode-field"
            label="IAB Category Block Mode"
        >
            <Select
                showSearch
                options={options}
                disabled={disabled}
                loading={isFetching}
                optionFilterProp="label"
                placeholder="Select IAB Category Block Mode…"
                onChange={onChange}
                value={value}
                allowClear
            />
        </Form.Item>
    );
};
