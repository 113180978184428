import { MarketplaceInfo } from "@app/core/services";
import { DealObjectForm } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { useSyncedFieldsProducer } from "@app/features/syncedFields";
import { Form } from "antd";
import { useState } from "react";
import moment from "moment-timezone";

export const useEndDatePicker = () => {
    const endDate = Form.useWatch<moment.Moment | null | undefined>(["dealUnderEdit", "endTime"]);
    const dealType = Form.useWatch<MarketplaceInfo["deal"]["dealType"]>(["dealUnderEdit", "dealType"]);
    const isRequired = dealType?.name === "Programmatic Guaranteed";
    const [isSelectingEndDate, setIsSelectingEndDate] = useState(false);
    const { setFieldValue } = Form.useFormInstance<DealObjectForm>();
    const { updateSyncedFields } = useSyncedFieldsProducer();

    const onSelectNoEndDate = () => {
        setIsSelectingEndDate((v) => !v);
        setFieldValue(["dealUnderEdit", "endTime"], null);
        if (endDate) {
            updateSyncedFields({ endDate: null });
        }
    };

    const onChange = (endDate: moment.Moment | null) => {
        updateSyncedFields({ endDate });
    };

    return { isSelectingEndDate, onSelectNoEndDate, isRequired, onChange };
};
