import { FC, useEffect, useState } from "react";
import { Col, Input, Row, Space, Typography } from "antd";
import {
    Conditions,
    PmpConditions,
    PMP_ANY_VALUE,
    TargetingDimensionTypeLabels,
    DEFAULT_PMP_EXCLUDE_TARGETING_VALUE,
} from "../../constants";
import { PmpDimension, UsedDimensions } from "../../types";
import { MoreMenu } from "../MoreMenu";
import { PmpConditionSelect } from "../PmpConditionSelect";

interface Props {
    dimension: PmpDimension;
    onChangeCondition: (value: Conditions) => void;
    onChangePmpCondition: (value: PmpConditions) => void;
    onChangeDimensionValues: (value: string[]) => void;
    onClickDelete: () => void;
    onClickDownload: () => void;
    onClickDuplicate: () => void;
    usedDimensions: UsedDimensions;
}

export const PmpDimensionRow: FC<Props> = ({
    dimension,
    onChangeCondition,
    onChangePmpCondition,
    onChangeDimensionValues,
    onClickDelete,
    onClickDownload,
    onClickDuplicate,
    usedDimensions,
}) => {
    const [text, setText] = useState<string>(dimension.values.join("\n"));
    useEffect(() => setText(dimension.values.join("\n")), [dimension.values]);

    const handleFinishedText = ({ target: { value } }) => {
        const domains = (value || "")
            .split("\n")
            .map((s) => s.trim())
            .filter(Boolean);
        onChangeDimensionValues(domains);
    };

    const handleChangeCondition = (pmpCondition: PmpConditions) => {
        onChangePmpCondition(pmpCondition);
        switch (pmpCondition) {
            case PmpConditions.IncludesAny:
                onChangeDimensionValues([PMP_ANY_VALUE]);
                onChangeCondition(Conditions.Includes);
                break;
            case PmpConditions.IncludesSpecific:
                onChangeDimensionValues([]);
                onChangeCondition(Conditions.Includes);
                break;
            case PmpConditions.ExcludesSpecific:
                onChangeDimensionValues([]);
                onChangeCondition(Conditions.Excludes);
                break;
            case PmpConditions.ExcludesAny:
            default:
                onChangeDimensionValues(DEFAULT_PMP_EXCLUDE_TARGETING_VALUE);
                onChangeCondition(Conditions.Excludes);
        }
    };

    return (
        <Row gutter={8}>
            <Col flex="150px">
                <Typography.Text strong>{TargetingDimensionTypeLabels.Pmp}</Typography.Text>
            </Col>
            <Col flex="180px">
                <PmpConditionSelect onChange={handleChangeCondition} value={dimension.pmpCondition} />
            </Col>
            <Col flex="10" style={{ alignSelf: "center" }}>
                {[PmpConditions.IncludesAny, PmpConditions.ExcludesAny].includes(dimension.pmpCondition) ? (
                    <Typography.Text>{TargetingDimensionTypeLabels.Pmp}</Typography.Text>
                ) : (
                    <Input.TextArea
                        allowClear
                        autoSize
                        onBlur={handleFinishedText}
                        onChange={(e) => setText(e.target.value)}
                        placeholder="Enter Deal IDs one per line"
                        value={text}
                    />
                )}
            </Col>
            <Col flex="146px" style={{ display: "flex", justifyContent: "flex-end" }}>
                <Space>
                    <MoreMenu
                        onClickDelete={onClickDelete}
                        onClickDownload={onClickDownload}
                        onClickDuplicate={onClickDuplicate}
                        isDuplicateDisabled={
                            usedDimensions.pmpDealIdTargets.includes || usedDimensions.pmpDealIdTargets.excludes
                        }
                    />
                </Space>
            </Col>
        </Row>
    );
};
