import { Form, Select } from "antd";
import { FC } from "react";
import { LabeledValue } from "antd/lib/select";

interface Props {
    networks: { id: number; name: string }[];
    setNetworkFilter: (value: number) => void;
}

export const NetworkFitler: FC<Props> = ({ networks, setNetworkFilter }) => {
    const options: LabeledValue[] = networks.map((network) => ({ label: network.name, value: network.id }));

    return (
        <Form.Item label="Network">
            <Select
                style={{ width: "100%" }}
                options={options}
                onChange={(value) => {
                    setNetworkFilter(value);
                }}
                placeholder="All"
                allowClear={true}
            />
        </Form.Item>
    );
};
