import { ADD_TO_ADD_SOURCE_BY_DEFAULT_FORM_NAME, NAME_FORM_NAME, PIXEL_URL_FORM_NAME } from "../CustomPixelForm";

export const CUSTOM_PIXELS_CREATE_PAGE_SDET = "custom-pixels-create-page";

export const CUSTOM_PIXELS_CREATE_PAGE_TITLE = "Create Custom Pixel";

export const CUSTOM_PIXELS_CREATE_FORM_INITIAL_VALUES = {
    [NAME_FORM_NAME]: "",
    type: { id: 1 },
    [ADD_TO_ADD_SOURCE_BY_DEFAULT_FORM_NAME]: false,
    [PIXEL_URL_FORM_NAME]: "",
};
