import { FC } from "react";
import { Form, Select } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useDealEnforcementField } from "@app/features/deals/DealForm/DealDemandFormSections/DealDemandConfigurationSection/Fields/DealEnforcementField/useDealEnforcementField";

export const DealEnforcementField: FC = () => {
    const { value, handleSelect, options, isFetching } = useDealEnforcementField();
    return (
        <Form.Item
            data-sdet="deal-enforcement-field"
            label="Enforcement"
            name={CREATE_DEAL_FORM_ITEMS_NAME.ENFORCEMENT}
        >
            <Select
                showSearch
                optionFilterProp="label"
                onSelect={handleSelect}
                value={value}
                options={options}
                loading={isFetching}
            />
        </Form.Item>
    );
};
