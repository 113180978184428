import { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "antd";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { PageHeader } from "@ant-design/pro-layout";

const BulkOperationsPageHeader: FC = () => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const handleCreateBulkOperation = (): void => {
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BULK_OPERATIONS_CREATE(seatId));
    };

    return (
        <PageHeader
            title={<span data-sdet="bulk-operations-header-title">Recent Bulk Operations</span>}
            extra={
                <Button type="primary" onClick={handleCreateBulkOperation} data-sdet="start-new-bulk-operation">
                    Start New Bulk Operations
                </Button>
            }
        />
    );
};

export default BulkOperationsPageHeader;
