import { Loading, SectionTitle } from "@app/core/components";
import { HelpKeyItem } from "@app/core/components/HelpKeysDrawer/HelpKeyItem";
import { css } from "@emotion/css";
import { Collapse, Flex } from "antd";
import { DetailsDrawerLayout } from "../../../DetailsDrawerLayout";
import { useDefinitionLadleContent } from "./useDefinitionLadleContent";
import { adSourceDetailsHelpKeys } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/SeatAdSourcesDetailsDrawerContent/SeatAdSourcesDetailsDrawerHeader/titles/SeatAdSourcesDetailsDrawerNestedTitle/constants";

const configurationCollapseClassList = css`
    width: 100%;

    & .ant-collapse-content-box {
        padding-left: 3.3rem;
    }

    & .ant-collapse-item > .ant-collapse-header {
        padding-left: 1.5rem;
    }

    & .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        margin-right: 18px;
    }

    .ant-collapse-expand-icon {
        align-self: flex-end;
    }
`;
export const DefinitionLadleContent = () => {
    const { breadcrumbs, isLoading } = useDefinitionLadleContent();
    const definitionsFields = adSourceDetailsHelpKeys["Ladle"];
    const allKeys = definitionsFields.map((field) => field.key);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Flex vertical data-sdet="inventory-definition-ladle-content">
            <DetailsDrawerLayout title="Ladle Definition" breadcrumbs={breadcrumbs} />
            <Collapse defaultActiveKey={allKeys} className={configurationCollapseClassList}>
                {definitionsFields.map((field) => (
                    <Collapse.Panel key={field.key} header={<SectionTitle title={field.label} />} forceRender>
                        <HelpKeyItem helpPath={field.helpPath} />
                    </Collapse.Panel>
                ))}
            </Collapse>
        </Flex>
    );
};
