import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { useGetGeographiesCountriesQuery, useGetPlatfromsFamilyQuery } from "@app/core/services";
import { uri } from "@rubicon/utils";
import { LabeledValue } from "antd/lib/select";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const enum UniquesStatsSearchParams {
    PlatformFamily = "platformFamily",
    Country = "country",
}

interface UseUniquesStatsFilter {
    platformFamily?: number;
    country?: string;
    handleChangePlatformFamily: (value: number) => void;
    handleChangeCountry: (value: string) => void;
    isLoadingPlatformFamily: boolean;
    platformsFamilyOptions: LabeledValue[];
    countriesOptions: LabeledValue[];
    isFetchingCountries: boolean;
    handleSearchCountries: (value: string) => void;
}
export const useUniquesStatsFilter = (): UseUniquesStatsFilter => {
    const { search } = useLocation();
    const history = useHistory();
    const loadPlatformFamily = new URLSearchParams(search).get(UniquesStatsSearchParams.PlatformFamily);
    const [platformFamily, setPlatformFamily] = useState<number | undefined>(
        loadPlatformFamily ? Number(loadPlatformFamily) : undefined
    );

    const loadCountry = new URLSearchParams(search).get(UniquesStatsSearchParams.Country);
    const [country, setCountry] = useState<string | undefined>(loadCountry ? loadCountry : undefined);
    const [keyword, setKeyword] = useState("");

    const { data: countries = [], isFetching: isFetchingCountries } = useGetGeographiesCountriesQuery({ keyword });
    const { data: platformsFamily = [], isLoading: isLoadingPlatformFamily } = useGetPlatfromsFamilyQuery();

    const platformsFamilyOptions = useMemo(
        () => platformsFamily.map(({ name, id }) => ({ label: name, value: id })),
        [platformsFamily]
    );

    const countriesOptions = useMemo(
        () =>
            countries.map((datum) => {
                return {
                    value: datum.geo.countryCode as string,
                    key: String(datum.geo.id),
                    label: datum.geo.countryName,
                };
            }),
        [countries]
    );

    const handleChangePlatformFamily = (value: number) => {
        const newSearchParams = uri.setSearchParam(
            search,
            UniquesStatsSearchParams.PlatformFamily,
            value ? String(value) : ""
        );

        setPlatformFamily(value);
        history.push({ search: newSearchParams });
    };

    const handleSearch = useCallback((value: string) => {
        setKeyword(value);
    }, []);

    const debouncedHandleSearch = useMemo(
        () => debounce((value: string) => handleSearch(value), FILTER_INPUT_DEBOUNCE_TIME),
        [handleSearch]
    );

    const handleSearchCountries = (value: string) => {
        debouncedHandleSearch(value);
    };

    const handleChangeCountry = (value: string) => {
        const newSearchParams = uri.setSearchParam(search, UniquesStatsSearchParams.Country, value ? value : "");
        setCountry(value);
        history.push({ search: newSearchParams });
    };

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(search);
        const platformFamilyUrlSearchParams = urlSearchParams.get(UniquesStatsSearchParams.PlatformFamily);
        const countryUrlSearchParams = urlSearchParams.get(UniquesStatsSearchParams.Country);

        if (platformFamilyUrlSearchParams) {
            setPlatformFamily(Number(platformFamilyUrlSearchParams));
        } else {
            setPlatformFamily(undefined);
        }

        if (countryUrlSearchParams) {
            setCountry(countryUrlSearchParams);
        } else {
            setCountry(undefined);
        }
    }, [search]);

    return {
        platformFamily,
        country,
        handleChangePlatformFamily,
        isLoadingPlatformFamily,
        platformsFamilyOptions,
        handleChangeCountry,
        countriesOptions,
        isFetchingCountries,
        handleSearchCountries,
    };
};
