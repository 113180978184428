import { Layout } from "antd";
import { FC } from "react";
import { Footer } from "@app/core/components";
import { useParams } from "react-router-dom";
import { PageLoader } from "@rubicon/antd-components";
import { useGetBrandByIdQuery } from "@app/core/services";
import { BrandForm } from "@app/features/inventory/HierarchyForms/BrandForm";
import { BrandFormInstance } from "@app/features/inventory/HierarchyForms/BrandForm/BrandFormInstance";
import { BrandCopyPageHeader } from "@app/features/inventory/HierarchyFormsPages/BrandPages/BrandCopyPage/BrandCopyPageHeader";
import { useScrollToTopOnMount } from "@app/core/components/hooks";
export const BrandCopyPage: FC = () => {
    useScrollToTopOnMount();
    const { brandId } = useParams<{ brandId: string }>();
    const { data, isFetching } = useGetBrandByIdQuery(Number(brandId));

    if (isFetching || !data) return <PageLoader />;

    return (
        <>
            <BrandCopyPageHeader />
            <Layout.Content>
                <BrandFormInstance mode="copy" brand={data}>
                    <BrandForm />
                </BrandFormInstance>
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
