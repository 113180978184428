import { Form } from "antd";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const NoAdCookiesSync = () => {
    return (
        <Form.Item name={CHANNEL_FORM_FIELDS.NO_AD_SYNC.name} label={CHANNEL_FORM_FIELDS.NO_AD_SYNC.label}>
            <BooleanRadioGroup />
        </Form.Item>
    );
};
