import { useUserAccess } from "@app/core/auth";
import {
    SeatHelpKeys,
    seatDetailsHelpKeys,
    SeatDetailsDrawerTitles,
} from "@app/features/inventory/InventorySeat/constants";
import { DefinationField } from "@app/core/components/HelpKeysDrawer";
import { filterDefinitionsFieldsByHelpPathKeys } from "@app/features/inventory/helpers";

const GENEERAL_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS: string[] = [
    SeatHelpKeys.SeatAccountType,
    SeatHelpKeys.SeatClientType,
    SeatHelpKeys.SeatRegion,
    SeatHelpKeys.SeatSupportTier,
    SeatHelpKeys.SeatIsMvpd,
    SeatHelpKeys.SeatFillGuaranteed,
];
const FINANCIAL_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS: string[] = [
    SeatHelpKeys.PublisherBillingCode,
    SeatHelpKeys.SeatAffiliateCostEnabled,
    SeatHelpKeys.SeatAffiliateCostModel,
    SeatHelpKeys.SeatAffiliateCostValue,
];
const SEAT_DEFAULTS_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS: string[] = [SeatHelpKeys.DefaultExtendedImpressionWaitTime];

const LABELS_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS: string[] = [SeatHelpKeys.GlobalLabels];

interface UseSeatDetailsDefenitionsFields {
    generalDefinitionsFields: DefinationField[];
    financialDefinitionsFields: DefinationField[];
    seatDefaultsDefinitionsFields: DefinationField[];
    brandSafetyDefinitionsFields: DefinationField[];
    customPixelsDefinitionsFields: DefinationField[];
    labelsDefinitionsFields: DefinationField[];
    performanceDefinitionsFields: DefinationField[];
}

export const useSeatDetailsDefenitionsFields = (): UseSeatDetailsDefenitionsFields => {
    const { isSysAdmin, isPubAcctMgr } = useUserAccess();

    const generalDefinitionsFields: DefinationField[] =
        isSysAdmin || isPubAcctMgr
            ? seatDetailsHelpKeys[SeatDetailsDrawerTitles.General]
            : filterDefinitionsFieldsByHelpPathKeys(
                  seatDetailsHelpKeys[SeatDetailsDrawerTitles.General],
                  GENEERAL_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS
              );

    const financialDefinitionsFields: DefinationField[] =
        isSysAdmin || isPubAcctMgr
            ? seatDetailsHelpKeys[SeatDetailsDrawerTitles.Financial]
            : filterDefinitionsFieldsByHelpPathKeys(
                  seatDetailsHelpKeys[SeatDetailsDrawerTitles.Financial],
                  FINANCIAL_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS
              );

    const seatDefaultsDefinitionsFields: DefinationField[] =
        isSysAdmin || isPubAcctMgr
            ? seatDetailsHelpKeys[SeatDetailsDrawerTitles.SeatDefaults]
            : filterDefinitionsFieldsByHelpPathKeys(
                  seatDetailsHelpKeys[SeatDetailsDrawerTitles.SeatDefaults],
                  SEAT_DEFAULTS_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS
              );

    const brandSafetyDefinitionsFields: DefinationField[] = seatDetailsHelpKeys[SeatDetailsDrawerTitles.BrandSafety];
    const customPixelsDefinitionsFields: DefinationField[] = seatDetailsHelpKeys[SeatDetailsDrawerTitles.CustomPixels];
    const performanceDefinitionsFields: DefinationField[] = seatDetailsHelpKeys[SeatDetailsDrawerTitles.Performance];

    const labelsDefinitionsFields: DefinationField[] =
        isSysAdmin || isPubAcctMgr
            ? seatDetailsHelpKeys[SeatDetailsDrawerTitles.Labels]
            : filterDefinitionsFieldsByHelpPathKeys(
                  seatDetailsHelpKeys[SeatDetailsDrawerTitles.Labels],
                  LABELS_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS
              );

    return {
        generalDefinitionsFields,
        financialDefinitionsFields,
        seatDefaultsDefinitionsFields,
        brandSafetyDefinitionsFields,
        customPixelsDefinitionsFields,
        labelsDefinitionsFields,
        performanceDefinitionsFields,
    };
};
