import { Form, Select } from "antd";
import { FC, useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { Last24HoursPerformanceStatsGraph } from "@app/features/inventory/InventoryHealthDetailsPages/SharedSections/Performance/Last24HoursPerformanceStatsGraph";
import { useStatsSnapshotViewer } from "./useStatsSnapshotViewer";
import { useGetUnitSnapshot } from "./useGetUnitSnapshot";
import { css } from "@emotion/css";

export const StatsSnapshotViewer: FC<{ id: number }> = ({ id }) => {
    const { isLoading, options, handleChange, value, snapshotTimestamp, selectedSnapshotLabel } =
        useStatsSnapshotViewer(id);
    const { data } = useGetUnitSnapshot(id, snapshotTimestamp as string);
    const graph = useMemo(() => {
        if (!data) {
            return null;
        }
        return <Last24HoursPerformanceStatsGraph adStat={data} chartId="last-24-hours-performance-stats-snapshot" />;
    }, [data]);

    return (
        <div data-sdet="stats-snapshot-viewer" className={css({ marginTop: "24px" })}>
            <Form.Item label="Show Snapshots" colon={false}>
                <Select
                    loading={isLoading}
                    placeholder="Select Snapshot"
                    onChange={(_, option) => handleChange(option as LabeledValue & { filename: string })}
                    options={options}
                    optionFilterProp="label"
                    style={{ width: "465px" }}
                    value={value}
                />
            </Form.Item>

            {selectedSnapshotLabel && (
                <>
                    <Form.Item label="Snapshot" colon={false}>
                        {selectedSnapshotLabel}
                    </Form.Item>
                </>
            )}

            {graph}
        </div>
    );
};
