import styled from "@emotion/styled";
import { brandAmethyst } from "@rubicon/antd-components";
import { SIDE_NAV_WIDTH } from "../constants";
import { css } from "@emotion/react";

const dynamicBackgroundColor = ({ backgroundColor = brandAmethyst }: { backgroundColor?: string }) => css`
    background-color: ${backgroundColor};
`;

export const Nav = styled.header`
    display: flex;
    flex-direction: column;
    position: fixed;
    max-width: ${SIDE_NAV_WIDTH};
    bottom: 0;
    top: 0;
    z-index: 2000;
    color: whitesmoke;
    ${dynamicBackgroundColor};
`;
