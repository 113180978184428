import { FC } from "react";
import { AdSource } from "@app/core/services";
import {
    EnrichmentCostSectionInnerCard,
    useAdSourceEnrichmentCost,
} from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/EnrichmentCostSection";
import { targetingToTargetingBlock } from "@app/features/targeting";
import {
    getAudiencesFromTargetingForm,
    getSegmentsFromTargetingForm,
} from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/EnrichmentCostSection/utils";
import { useSeatAuthContext } from "@app/core/auth";
import { ReadOnlyDemandAcquisitionCostField } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/EnrichmentCostSection/ReadOnlyDemandAcquisitionCostField";
import { DEFAULT_ENRICHMENT_COST_CURRENCY_CODE } from "@app/features/seatAdSources/constants";

interface Props {
    adSourceFloorType: AdSource["adSourceFloorType"];
    bookingPrice: AdSource["bookingPrice"];
    currencyType: AdSource["currencyType"];
    demandAcquisitionCost: AdSource["demandAcquisitionCost"];
    demandAcquisitionCostModelId: AdSource["demandAcquisitionCostModel"]["id"];
    marketplaceInfoList: AdSource["marketplaceInfoList"];
    name: AdSource["name"];
    targeting: AdSource["targeting"];
    type: AdSource["type"];
}

export const AdSourceEnrichmentCost: FC<Props> = ({
    adSourceFloorType,
    bookingPrice,
    currencyType,
    demandAcquisitionCost,
    demandAcquisitionCostModelId,
    marketplaceInfoList,
    name,
    targeting,
    type,
}) => {
    const { context } = useSeatAuthContext();
    const adSourceCurrencyCode = currencyType?.code || "USD";
    const adSourceDemandAcquisitionCost = demandAcquisitionCost === null ? null : demandAcquisitionCost / 1000;
    const adSourceCpm = bookingPrice / 1000;

    const {
        adSourceEc,
        adSourceLevelAudiences,
        adSourceLevelSegments,
        dealLevelValues,
        hasEnrichmentCostTargeting,
        isDrawerOpen,
        isRangedCost,
        selectedMarketplace,
        setIsDrawerOpen,
        setSelectedMarketplace,
        shouldUseAdSourceValues,
    } = useAdSourceEnrichmentCost({
        adSourceCpm,
        adSourceCurrencyCode,
        adSourceDemandAcquisitionCost,
        adSourceDemandAcquisitionCostModelId: demandAcquisitionCostModelId,
        adSourceFloorTypeId: adSourceFloorType?.id,
        adSourceLevelTargeting: targeting.map(targetingToTargetingBlock),
        adSourceType: type,
        marketplaceInfoList: marketplaceInfoList,
    });

    return (
        <EnrichmentCostSectionInnerCard
            isDemandAcquisitionCostEnabled={Boolean(context?.dacEnabled)}
            adSourceCpm={adSourceCpm}
            adSourceCurrency={adSourceCurrencyCode}
            adSourceDemandAcquisitionCost={adSourceDemandAcquisitionCost}
            adSourceDemandAcquisitionCostModelId={demandAcquisitionCostModelId}
            adSourceEc={adSourceEc}
            adSourceFloorType={adSourceFloorType}
            adSourceLevelAudiences={adSourceLevelAudiences}
            adSourceLevelSegments={adSourceLevelSegments}
            adSourceName={name}
            adSourceType={type}
            cardBodyStyle={null}
            dealLevelValues={dealLevelValues}
            demandAcquisitionCostField={
                <ReadOnlyDemandAcquisitionCostField
                    dac={adSourceDemandAcquisitionCost}
                    dacModelId={demandAcquisitionCostModelId}
                />
            }
            hasEnrichmentCostTargeting={hasEnrichmentCostTargeting}
            isRangedCost={isRangedCost}
            isDrawerOpen={isDrawerOpen}
            selectedDealCurrencyCode={
                selectedMarketplace?.deal.currencyType?.code || DEFAULT_ENRICHMENT_COST_CURRENCY_CODE
            }
            selectedDealLevelAudiences={getAudiencesFromTargetingForm(
                selectedMarketplace?.targeting.map(targetingToTargetingBlock) || []
            )}
            selectedDealLevelSegments={getSegmentsFromTargetingForm(
                selectedMarketplace?.targeting.map(targetingToTargetingBlock) || []
            )}
            selectedDealName={selectedMarketplace?.deal.name || ""}
            selectedDealRate={Number(selectedMarketplace?.deal.rate) || 0}
            setIsDrawerOpen={setIsDrawerOpen}
            setSelectedDeal={(dealId: string) => {
                setSelectedMarketplace(
                    marketplaceInfoList?.find((marketplace) => String(marketplace.deal.id) === dealId) || null
                );
                setIsDrawerOpen(true);
            }}
            shouldUseAdSourceValues={shouldUseAdSourceValues}
        />
    );
};
