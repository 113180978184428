import { Layout } from "antd";
import { PageLoader } from "@rubicon/antd-components";
import { Footer } from "@app/core/components";
import { DealEditFormPageContent } from "./DealEditFormPageContent";
import { DealEditFormPageHeader } from "./DealEditFormPageHeader";
import { useDealAdSourceById } from "@app/features/deals/DealDetailsPage/useDealAdSourceById";

export const DealEditFormPage = () => {
    const { dealAdSource, isLoading } = useDealAdSourceById();

    if (isLoading || !dealAdSource) {
        return <PageLoader />;
    }

    return (
        <Layout>
            <DealEditFormPageHeader title={`Edit ${dealAdSource?.deal?.name}`} />
            <Layout.Content style={{ padding: 0 }}>
                <DealEditFormPageContent dealAdSource={dealAdSource} />
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </Layout>
    );
};
