import { AdUnitPathType, ParamSamplesType, useGetTagParamsQuery } from "@app/core/services/diagnostics/tagParams";
import { useAppSelector } from "@app/core/store";
import { useState } from "react";
import { InventoryDetailsDrawerType, selectInventoryDetailsDrawerType } from "../DetailsDrawer/reducer";

export interface TagParamsChartOptions {
    frequency: string;
    percent: number;
}

const drawerTypeToAdUnitPathMap: Record<string, AdUnitPathType> = {
    [InventoryDetailsDrawerType.SEAT]: "seats",
    [InventoryDetailsDrawerType.BRAND]: "brands",
    [InventoryDetailsDrawerType.BRAND_CHANNEL]: "brands",
    [InventoryDetailsDrawerType.SUPPLY]: "supply",
    [InventoryDetailsDrawerType.AD_UNIT]: "adCodes",
    [InventoryDetailsDrawerType.CHANNEL]: "publishers",
    [InventoryDetailsDrawerType.PUBLISHER]: "publishers",
};

export const useTagParams = (id: string | number) => {
    const drawerType = useAppSelector(selectInventoryDetailsDrawerType);
    const [activeKeys, setActiveKeys] = useState<string[]>([]);

    const tagPath = drawerType ? drawerTypeToAdUnitPathMap[drawerType] : "publishers";

    const { data, error } = useGetTagParamsQuery({ type: tagPath, entityId: id }, { skip: !drawerType || !id });

    const keys: string[] = Object.keys(data?.paramSamples || {});

    const sampleCount = data?.sampleCount || 1;
    const chartOptions: TagParamsChartOptions[] = [];

    for (const name in data?.paramCounts) {
        const percent = Math.round((Number(data?.paramCounts[name]) / sampleCount) * 100);
        chartOptions.push({ frequency: name, percent });
    }

    const handleExpandAll = () => {
        setActiveKeys(keys);
    };

    const handleCollapseAll = () => {
        setActiveKeys([]);
    };

    const handleKeyChange = (keys: string[]) => setActiveKeys(keys);

    return {
        samples: data?.paramSamples as ParamSamplesType,
        chartOptions: chartOptions.sort((a, b) => b.percent - a.percent),
        keys,
        activeKeys,
        handleExpandAll,
        handleCollapseAll,
        handleKeyChange,
        error,
    };
};
