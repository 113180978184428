import { useAppDispatch, useAppSelector } from "@app/core/store";
import { LabeledValue } from "antd/lib/select";
import { setFiltersSeatAdSourcesDsp } from "../../reducer";
import { useGetAvailableNetworksQuery, SeatAvailableNetworksParams } from "@app/core/services/console/seats";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

interface UseSeatAdSourcesPacingDspFilter {
    handleChangeValue: (value: string | number | undefined, option: LabeledValue | LabeledValue[]) => void;
    options: LabeledValue[];
    value: LabeledValue[] | string | null;
    isLoading: boolean;
    areAllOptionsSelected: boolean;
    toggleSelectDeselectAll: () => void;
}

export const useSeatAdSourcesPacingDspFilter = (): UseSeatAdSourcesPacingDspFilter => {
    const dispatch = useAppDispatch();
    const seatAdSourcesPacingDsp = useAppSelector((state) => state.seatAdSourcesPacing.filters.seatAdSourcesDsp);
    const { seatId } = useParams<{ seatId: string }>();
    const SeatAvailableNetworkParams: SeatAvailableNetworksParams = {
        seatId: Number(seatId),
        //TODO: should this always be 6?
        adSourceTypeId: 6,
    };
    const { data, isFetching } = useGetAvailableNetworksQuery(SeatAvailableNetworkParams);
    const options = useMemo(() => (data || []).map((filter) => ({ label: filter.name, value: filter.id })), [data]);

    const handleChangeValue = (value: string | number | undefined, option: LabeledValue[]) => {
        dispatch(setFiltersSeatAdSourcesDsp(option));
    };

    const areAllOptionsSelected = options.length === seatAdSourcesPacingDsp?.length;

    const toggleSelectDeselectAll = () => {
        if (areAllOptionsSelected) {
            handleChangeValue(undefined, []);
        } else {
            handleChangeValue(undefined, options);
        }
    };

    return {
        handleChangeValue,
        options: options,
        value: seatAdSourcesPacingDsp,
        isLoading: isFetching,
        areAllOptionsSelected,
        toggleSelectDeselectAll,
    };
};
