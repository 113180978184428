import { MomentDatePicker } from "@app/core/components/MomentDatePicker";
import { YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE } from "@app/core/components/constants";
import { uri } from "@rubicon/utils";
import { Col, Form, Row } from "antd";
import moment, { Moment } from "moment-timezone";
import { useHistory, useLocation } from "react-router-dom";

export const AUDIT_LOG_START_DATE_PICKER_SDET = "audit-log-start-date-picker";
export const AUDIT_LOG_END_DATE_PICKER_SDET = "audit-log-end-date-picker";

export const START_DATE_QUERY_PARAM = "startDate";
export const END_DATE_QUERY_PARAM = "endDate";

export const getStartDateQueryParam = (queryString: string) =>
    new URLSearchParams(queryString).get(START_DATE_QUERY_PARAM) || undefined;
export const getEndDateQueryParam = (queryString: string) =>
    new URLSearchParams(queryString).get(END_DATE_QUERY_PARAM) || undefined;

export const AuditLogDateRangePickers = () => {
    const { search: queryString } = useLocation();
    const startDate = getStartDateQueryParam(queryString);
    const endDate = getEndDateQueryParam(queryString);
    const history = useHistory();
    const handleChange = (date: Moment | null, queryParam: string) => {
        const isoDate = date?.toISOString() || "";
        const nextQueryString = isoDate
            ? uri.setSearchParam(queryString, queryParam, isoDate)
            : uri.deleteSearchParam(queryString, queryParam);
        history.push({ search: nextQueryString });
    };
    return (
        <Form.Item label="Date Range" style={{ marginBottom: 0 }}>
            <Row gutter={16}>
                <Col xs={24} md={12}>
                    <MomentDatePicker
                        showTime={{ use12Hours: true }}
                        allowClear
                        data-sdet={AUDIT_LOG_START_DATE_PICKER_SDET}
                        format={(value) => value.utc().format(YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE)}
                        style={{ width: "100%" }}
                        onChange={(date) => handleChange(date, START_DATE_QUERY_PARAM)}
                        value={startDate ? moment(startDate) : null}
                        disabledDate={(date) => date.isAfter(moment.now())}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <MomentDatePicker
                        showTime={{ use12Hours: true }}
                        allowClear
                        data-sdet={AUDIT_LOG_END_DATE_PICKER_SDET}
                        format={(value) => value.utc().format(YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE)}
                        style={{ width: "100%" }}
                        onChange={(date) => handleChange(date, END_DATE_QUERY_PARAM)}
                        value={endDate ? moment(endDate) : null}
                        disabledDate={(date) =>
                            date.isAfter(moment.now()) || Boolean(startDate && date.isBefore(moment(startDate)))
                        }
                    />
                </Col>
            </Row>
        </Form.Item>
    );
};
