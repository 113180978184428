import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { Form } from "antd";

export const useBundleIdOverride = () => {
    const allowBundleIdName = Form.useWatch<AdSourcesForm["allowBundleId"]>(AD_SOURCE_FIELDS.BUNDLE_ID.name);
    const isVisible = allowBundleIdName === "override";
    return {
        isVisible,
    };
};
