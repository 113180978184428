import { FC } from "react";
import { Card, Row, Col } from "antd";
import { Seat } from "@app/core/services";
import {
    BlockBuyerSeaList,
    BlockedAdvertiserDomains,
    BlockAdvertiserDomainLists,
} from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/SeatDetailsFormFields";

interface BrandSafetySectionProps {
    edit?: boolean;
    seat: Seat;
}

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 };

export const BrandSafetySection: FC<BrandSafetySectionProps> = ({ seat, edit = false }) => (
    <Card bordered={false}>
        <Row gutter={16}>
            <Col {...COL_SIZES}>
                <BlockAdvertiserDomainLists seat={seat} edit={edit} />
            </Col>
        </Row>
        <Row gutter={16}>
            <Col {...COL_SIZES}>
                <BlockedAdvertiserDomains seat={seat} edit={edit} />
            </Col>
        </Row>
        <Row gutter={16}>
            <Col {...COL_SIZES}>
                <BlockBuyerSeaList seat={seat} edit={edit} />
            </Col>
        </Row>
    </Card>
);
