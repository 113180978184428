import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { useGetViewabilityVendorsQuery } from "@app/core/services/console";

interface UseViewabilityVendors {
    isFetching: boolean;
    options: LabeledValue[];
}

export const useViewabilityVendors = (): UseViewabilityVendors => {
    const { data, isFetching } = useGetViewabilityVendorsQuery();
    const options = useMemo(() => (data || []).map(({ id, name }) => ({ value: id, label: name })), [data]);
    return {
        isFetching,
        options,
    };
};
