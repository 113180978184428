import { useHistory, useLocation } from "react-router-dom";
import { DrawerBreadcrumbHeader } from "@app/core/components";
import { FC } from "react";
import { AdvertiserDetails } from "@app/core/services";
import { uri } from "@rubicon/utils";

interface Props {
    advertiser?: AdvertiserDetails;
    showDirectLabel: boolean;
}

export const CLASSIFICATIONS_ADVERTISER_CHANGE_HISTORY_DRAWER_TITLE_SDET =
    "classifications-advertiser-change-history-drawer-title";

export const ClassificationsAdvertiserChangeHistoryDrawerTitle: FC<Props> = ({ advertiser, showDirectLabel }) => {
    const history = useHistory();
    const { search: queryString } = useLocation();

    return advertiser ? (
        showDirectLabel ? (
            <span data-sdet={CLASSIFICATIONS_ADVERTISER_CHANGE_HISTORY_DRAWER_TITLE_SDET}>
                Advertiser: {advertiser.name}
            </span>
        ) : (
            <DrawerBreadcrumbHeader
                sdet={CLASSIFICATIONS_ADVERTISER_CHANGE_HISTORY_DRAWER_TITLE_SDET}
                onBack={() => {
                    const nextQueryString = uri.setSearchParam(queryString, "drawer", "details");
                    history.push({ search: nextQueryString });
                }}
                parentLabel={`Advertiser: ${advertiser.name}`}
                childLabel={"Change History"}
            />
        )
    ) : null;
};
