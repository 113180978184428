import { Form } from "antd";
import { RuleObject, StoreValue } from "rc-field-form/lib/interface";
import { MIN_BITRATE } from "@app/features/inventory/HierarchyForms/constants";

const { useWatch, useFormInstance } = Form;

interface UseAdUnitMaxBitrate {
    onChange: (value: number) => void;
    validator: (rule: RuleObject, value: StoreValue) => Promise<void>;
}

export const useAdUnitMaxBitrate = (): UseAdUnitMaxBitrate => {
    const form = useFormInstance();
    const minBitrate = useWatch(MIN_BITRATE);
    const onChange = (): Promise<void> => form.validateFields([MIN_BITRATE]);
    const validator = (_: RuleObject, value: StoreValue): Promise<void> => {
        if (value === null) return Promise.resolve();
        if (value < 1 || value > 100000)
            return Promise.reject(new Error("Max Bitrate values should be between 1 & 100000"));
        if (minBitrate > value) return Promise.reject(new Error("Max Bitrate can't be less than Min Bitrate"));
        return Promise.resolve();
    };

    return {
        validator,
        onChange,
    };
};
