import { useEffect, useRef, useState } from "react";
import { useSeatAuthContext } from "@app/core/auth";
import { useAppSelector } from "@app/core/store";
import { useThrottle } from "@app/core/utils";
import { getOtimesForThrottleUnit } from "../mapper";
import { AdStatsById } from "../types";
import { AdSourceAdStatsWebSocket } from "./AdSourceAdStatsWebSocket";
import { selectLiveAdStats } from "./reducer";

export const useLiveAdSourceAdStatsById = (adSourceIds: number[], throttle = false): AdStatsById => {
    const { session } = useSeatAuthContext();
    const sessionRef = useRef(session);
    const [adSourceAdStatsSocket, setAdSourceAdStatsSocket] = useState<AdSourceAdStatsWebSocket | null>(null);
    const adStatsById = useAppSelector(selectLiveAdStats(adSourceIds));
    const adSourceIdsRef = useRef<number[]>([]);
    const oTimes = getOtimesForThrottleUnit(adStatsById);

    const throttledAdStatsById = useThrottle(adStatsById, { forceUpdateDependencies: [oTimes] });

    useEffect(() => {
        if (!sessionRef.current) {
            console.error("Attempted to connect to live ad source ad stats without a valid session.");
            return;
        }

        const socketHandle = AdSourceAdStatsWebSocket.getInstance(sessionRef.current);
        if (socketHandle) {
            setAdSourceAdStatsSocket(socketHandle);
        }

        return () => {
            if (socketHandle) {
                socketHandle.liveSubscriberFinished(adSourceIdsRef.current);
            }
        };
        // Intentional empty dependency array to only run this effect once for initialization.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (adSourceAdStatsSocket) {
            const addedAdSourceIds = adSourceIds.filter(
                (adSourceId: number) => !adSourceIdsRef.current.includes(adSourceId)
            );
            const removedAdSourceIds = adSourceIdsRef.current.filter(
                (adSourceId: number) => !adSourceIds.includes(adSourceId)
            );

            const hasAdSourceIdUpdates = addedAdSourceIds.length > 0 || removedAdSourceIds.length > 0;
            if (hasAdSourceIdUpdates) {
                adSourceAdStatsSocket.updateLiveAdStatSubscriptions(addedAdSourceIds, removedAdSourceIds);
            }

            adSourceIdsRef.current = adSourceIds;
        }
    }, [adSourceIds, adSourceAdStatsSocket]);

    return throttle && throttledAdStatsById ? throttledAdStatsById : adStatsById;
};
