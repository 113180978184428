import { Form, Select } from "antd";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { uri } from "@rubicon/utils";
import { LabeledValue } from "antd/lib/select";
import { VastErrorsEntityTypes, VastErrorsUrlSearchParams } from "@app/core/services/diagnostics/vastErrors/types";

interface Props {
    entityType: string;
}

export const ENTITY_TYPE_OPTIONS: LabeledValue[] = [
    { label: "Seat", value: VastErrorsEntityTypes.Seat },
    { label: "Publisher", value: VastErrorsEntityTypes.Publisher },
    { label: "Brand", value: VastErrorsEntityTypes.Brand },
    { label: "Supply", value: VastErrorsEntityTypes.Supply },
    { label: "Ad Unit", value: VastErrorsEntityTypes.AdUnit },
];

export const SupplyLevelFitler: FC<Props> = ({ entityType }) => {
    const history = useHistory();
    return (
        <Form.Item label="Supply Level">
            <Select
                style={{ width: "100%" }}
                defaultValue={entityType}
                options={ENTITY_TYPE_OPTIONS}
                onChange={(value) => {
                    const nextSearchUpdated = uri.setSearchParam("", VastErrorsUrlSearchParams.entityType, value);
                    history.push({ search: nextSearchUpdated });
                }}
            />
        </Form.Item>
    );
};
