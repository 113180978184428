import { FC } from "react";
import { Form, Select } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAdSourceAdResponsePriceOverrideField } from "./useAdSourceAdResponsePriceOverrideField";

export const AdSourceAdResponsePriceOverrideField: FC = () => {
    const { isFetching, isVisible, options, handleChange } = useAdSourceAdResponsePriceOverrideField();

    if (!isVisible) {
        return null;
    }

    return (
        <Form.Item
            data-sdet="ad-source-ad-response-price-override-field"
            label="Ad Response Price Override"
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_AD_RESPONSE_PRICE_OVERRIDE}
        >
            <Select
                value={null}
                loading={isFetching}
                options={options}
                optionFilterProp="label"
                onSelect={handleChange}
            />
        </Form.Item>
    );
};
