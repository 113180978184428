type Curve = "quadratic" | "cubicFront" | "cubicBack";
type CurveFunction = (desiredX: number, desiredY: number, currentX: number) => number;
type Limit = "cubicFront" | "cubicBack" | "quadraticFront" | "quadraticBack";
type LimitFunction = (x: number) => number;

export const PacingCurveLimits: Record<Limit, LimitFunction> = {
    cubicFront: (x: number) => Math.pow(x, 3) - 3 * Math.pow(x, 2) + 3 * x,
    cubicBack: (x: number) => Math.pow(x, 3),
    quadraticFront: (x: number) => Math.pow(x, 2) - 2 * x,
    quadraticBack: (x: number) => Math.pow(x, 2),
} as const;

const PacingCurveFunction: Record<Curve, CurveFunction> = {
    quadratic: (desiredX: number, desiredY: number, currentX: number) => {
        const denominator = desiredX * (desiredX - 1);
        return (((desiredY - desiredX) * currentX + (Math.pow(desiredX, 2) - desiredY)) * currentX) / denominator;
    },
    cubicFront: (desiredX: number, desiredY: number, currentX: number) => {
        const a =
            (desiredY + Math.pow(desiredX, 2) - 2 * desiredX) / (desiredX * (Math.pow(desiredX, 2) - 2 * desiredX + 1));
        const b = -1 - 2 * a;
        const c = 1 - a - b;
        return currentX * (a * Math.pow(currentX, 2) + b * currentX + c);
    },
    cubicBack: (desiredX: number, desiredY: number, currentX: number) => {
        const a = (desiredY - Math.pow(desiredX, 2)) / (Math.pow(desiredX, 2) * (desiredX - 1));
        const b = 1 - a;
        return Math.pow(currentX, 2) * (a * currentX + b);
    },
} as const;

export const getPacingCurveFunction = (completionPercent: number, desiredDeliveryPercentage: number): CurveFunction => {
    const isCubicFront = desiredDeliveryPercentage - PacingCurveLimits.cubicFront(completionPercent) <= 0;
    const isCubicBack = desiredDeliveryPercentage - PacingCurveLimits.cubicBack(completionPercent) >= 0;
    const isQuadraticFront = PacingCurveLimits.quadraticFront(completionPercent) + desiredDeliveryPercentage <= 0;
    const isQuadraticBack = desiredDeliveryPercentage - PacingCurveLimits.quadraticBack(completionPercent) >= 0;

    if (isCubicFront && !isQuadraticFront) {
        return PacingCurveFunction.cubicFront;
    } else if (isQuadraticFront && isQuadraticBack) {
        return PacingCurveFunction.quadratic;
    } else if (!isQuadraticBack && isCubicBack) {
        return PacingCurveFunction.cubicBack;
    }

    return () => 0;
};
