import { ColumnOptions } from "@antv/g2plot";
import { formatNumber } from "@rubicon/utils";
import { HEIGHT, LEGEND, X_AXIS_TICK_COUNT, Y_AXIS_MIN, Y_AXIS_TICK_COUNT } from "../utils";
import { ColumnChartOptions } from "./types";

export const RequiredFields = {
    xField: "xValue",
    yField: "yValue",
    seriesField: "series",
} as const;

export const RequiredOptions: Pick<ColumnOptions, "data" | "xField" | "yField" | "seriesField"> = {
    data: [],
    ...RequiredFields,
};

export const DefaultOptions: ColumnChartOptions = {
    legend: LEGEND,
    height: HEIGHT,
    isStack: true,
    xAxis: {
        tickCount: X_AXIS_TICK_COUNT,
    },
    yAxis: {
        min: Y_AXIS_MIN,
        tickCount: Y_AXIS_TICK_COUNT,
        label: {
            formatter: formatNumber.asAbbreviated,
        },
    },
} as const;
