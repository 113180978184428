import { FC } from "react";
import { Breadcrumb, Button, Space, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { gray10, gray8 } from "@rubicon/antd-components";

interface Props {
    onBack: () => void;
    parentLabel: string;
    childLabel: string;
    sdet?: string;
}
export const DrawerBreadcrumbHeader: FC<Props> = ({ onBack, parentLabel, childLabel, sdet }) => (
    <Space size={0} data-sdet={sdet || "drawer-breadcrumb-header"}>
        <Button
            type="link"
            size="small"
            icon={<ArrowLeftOutlined style={{ justifyContent: "left" }} />}
            onClick={onBack}
            style={{ color: gray8 }}
        />
        <Breadcrumb
            className={css`
                font-size: 16px;
                li:last-child {
                    color: ${gray10};
                }
                li:not(:last-child) {
                    cursor: pointer;
                }
            `}
        >
            <Breadcrumb.Item onClick={onBack}>
                <Typography.Text ellipsis={{ tooltip: { title: parentLabel } }} style={{ maxWidth: "18rem" }}>
                    {parentLabel}
                </Typography.Text>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{childLabel}</Breadcrumb.Item>
        </Breadcrumb>
    </Space>
);
