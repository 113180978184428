import { FormListFieldData, Form, Space, Button } from "antd";
import { FC } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { useExtendedUserIdItem } from "./useExtendedUserIdItem";
import styled from "@emotion/styled";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";

interface Props {
    field: FormListFieldData;
    remove: (index: number | number[]) => void;
}

const StyledSpace = styled(Space)`
    width: 100%;
    .ant-space-item {
        width: 100%;
    }
    .ant-space-item:last-child {
        width: auto;
    }
`;

export const ExtendedUserIdItem: FC<Props> = ({ field: { name, key, ...restField }, remove }) => {
    const {
        isExtendedIdsFetching,
        extendedIds,
        isAvailableNetworksFetching,
        availableNetworks,
        isNetworksFieldDisabled,
        handleExtendedIdChange,
    } = useExtendedUserIdItem(name);
    return (
        <StyledSpace align="baseline">
            <Form.Item {...restField} name={[name, "extendedId"]}>
                <SelectWithValue
                    loading={isExtendedIdsFetching}
                    options={extendedIds}
                    placeholder="Select extended ID..."
                    onChange={handleExtendedIdChange}
                    fieldAsLabel={["name"]}
                    fieldAsValue={["id"]}
                />
            </Form.Item>
            <Form.Item {...restField} name={[name, "network"]}>
                <SelectWithValue
                    loading={isAvailableNetworksFetching}
                    options={availableNetworks}
                    placeholder="Select network..."
                    disabled={isNetworksFieldDisabled}
                    fieldAsLabel={["name"]}
                    fieldAsValue={["id"]}
                />
            </Form.Item>
            <Button icon={<DeleteOutlined />} onClick={() => remove(name)} />
        </StyledSpace>
    );
};
