import { FC } from "react";
import { Form, Input } from "antd";
import { PUBLISHER_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

const { TextArea } = Input;

export const ExternalComment: FC = () => {
    return (
        <Form.Item
            name={PUBLISHER_FORM_FIELDS.EXTERNAL_COMMENT.name}
            label={PUBLISHER_FORM_FIELDS.EXTERNAL_COMMENT.label}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 24, xxl: 22 }}
        >
            <TextArea rows={4} allowClear placeholder={PUBLISHER_FORM_FIELDS.EXTERNAL_COMMENT.placeholder} />
        </Form.Item>
    );
};
