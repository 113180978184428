import { FC } from "react";
import { Form, InputNumber } from "antd";
import { ColProps } from "antd/lib/grid/col";
import { InputNumberProps } from "antd/lib/input-number";
import { MIN_BITRATE } from "@app/features/inventory/HierarchyForms/constants";
import { useAdUnitMinBitrate } from "@app/features/inventory/components/FormItems/AdUnitMinBitrate/useAdUnitMinBitrate";

export interface AdUnitMinBitrateProps extends InputNumberProps {
    name: typeof MIN_BITRATE;
    wrapperCol?: ColProps;
    labelCol?: ColProps;
    label: string;
}

export const AdUnitMinBitrate: FC<AdUnitMinBitrateProps> = ({ name, label, wrapperCol, labelCol, ...rest }) => {
    const { validator, onChange } = useAdUnitMinBitrate();
    return (
        <Form.Item name={name} label={label} labelCol={labelCol} wrapperCol={wrapperCol} rules={[{ validator }]}>
            <InputNumber onChange={onChange} style={{ width: "100%" }} {...rest} />
        </Form.Item>
    );
};
