import { Table } from "antd";
import { useContentTransparencyRules } from "./useContentTransparencyRules";
import { getColumns, getTableRows } from "./helpers";
import { useContentTransparencyRulesDrawer } from "./ContentTransparencyRulesDrawer/useContentTransparencyRulesDrawer";
import { useDeleteContentTransparencyRuleMutation } from "@app/core/services";
import { BrandSafetyDeleteModal } from "../BrandSafetyDeleteModal";
import { useBrandSafetyDeleteModal } from "../BrandSafetyDeleteModal/useBrandSafetyDeleteModal";

export const CONTENT_TRANSPARENCY_RULES_TABLE_SDET = "content-tranparency-rules-list-table";

export const ContentTransparencyRulesTable = () => {
    const { isLoading, data, seatId } = useContentTransparencyRules();
    const { handleDrawerOpen } = useContentTransparencyRulesDrawer();
    const [deleteTrigger, { isLoading: isDeleting }] = useDeleteContentTransparencyRuleMutation();
    const { handleOpen: handleDeleteModal, ...restModalProps } = useBrandSafetyDeleteModal(deleteTrigger);

    return (
        <>
            <Table
                data-sdet={CONTENT_TRANSPARENCY_RULES_TABLE_SDET}
                columns={getColumns()}
                dataSource={getTableRows(data, seatId, handleDrawerOpen, handleDeleteModal)}
                loading={isLoading}
                scroll={{ x: 1000 }}
                size="small"
                rowKey="id"
                showSorterTooltip={false}
                pagination={false}
            />
            <BrandSafetyDeleteModal isDeleting={isDeleting} {...restModalProps} />
        </>
    );
};

export default ContentTransparencyRulesTable;
