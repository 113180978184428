import { FC, ReactNode } from "react";
import { Button, Typography, Flex } from "antd";
import { css } from "@emotion/css";
import { gray7, gray8 } from "@rubicon/antd-components";
import { CloseOutlined } from "@ant-design/icons";

interface DrawerLayoutProps {
    closeHandler: () => void;
    title: ReactNode;
}

const className = css`
    display: flex;
    flex: 0;
    align-items: center;
    padding: 16px 24px;
    font-size: 16px;
    line-height: 22px;
    border-bottom: 1px solid #f0f0f0;
`;

const DrawerLayout: FC<DrawerLayoutProps> = ({ children, closeHandler, title }) => {
    return (
        <Flex vertical>
            <Flex className={className} align="baseline" justify="space-between">
                <Typography.Title level={4} style={{ margin: 0 }}>
                    {title}
                </Typography.Title>
                <Button
                    type="text"
                    onClick={() => closeHandler()}
                    className={css`
                        color: ${gray7};

                        &:hover {
                            color: ${gray8};
                            background-color: transparent;
                        }
                    `}
                >
                    <CloseOutlined
                        className={css`
                            svg {
                                width: 1.15em;
                                height: 1.15em;
                            }
                        `}
                    />
                </Button>
            </Flex>
            <Flex
                className={css`
                    padding: 1.5rem;
                `}
            >
                {children}
            </Flex>
        </Flex>
    );
};

export default DrawerLayout;
