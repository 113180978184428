import { Form, Input } from "antd";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { useIsFieldVisible } from "./useIsFieldVisible";

export const AssignedAdvertiserDomainInput = () => {
    const isVisible = useIsFieldVisible();
    return isVisible ? (
        <Form.Item
            {...VERTICAL_LAYOUT_FIX}
            label={AD_SOURCE_FIELDS.ASSIGNED_ADVERTISER_DOMAIN.label}
            name={AD_SOURCE_FIELDS.ASSIGNED_ADVERTISER_DOMAIN.name}
        >
            <Input placeholder="Default Advertiser Domain" />
        </Form.Item>
    ) : null;
};
