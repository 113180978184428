import { FC, useMemo } from "react";
import { Card, CollapsePanelProps } from "antd";
import { ExpandCollapse } from "@rubicon/antd-components";
import { CustomRuleGroupApi } from "../../types";
import { CustomRuleGroupRow } from "./ReadOnlyCustomRuleGroupRow";

export interface ReadOnlyCustomRulePanelProps extends CollapsePanelProps {
    targets: string[] | null;
}

export const ReadOnlyCustomRulePanel: FC<ReadOnlyCustomRulePanelProps> = ({ targets, ...rest }) => {
    const groups = useMemo(() => {
        return (targets || []).map((target) => {
            return JSON.parse(target) as CustomRuleGroupApi;
        });
    }, [targets]);

    if (!groups.length) {
        return null;
    }

    return (
        <ExpandCollapse.Panel {...rest}>
            <Card bodyStyle={{ padding: 8 }}>
                {groups.map((group, i) => {
                    return <CustomRuleGroupRow key={i} group={group} path={[]} />;
                })}
            </Card>
        </ExpandCollapse.Panel>
    );
};
