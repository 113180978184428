import { Form } from "antd";
import { useState } from "react";
import { AdSourceQpsLimit } from "@app/core/services";
import { getQpsLimitLabel } from "@app/features/inventory/helpers";
import { RuleObject, StoreValue } from "rc-field-form/lib/interface";
import { INVENTORY_FORM_FIELDS, REQUIRED_QPS_LIMITS_REGIONS } from "@app/features/inventory/InventorySeat/constants";
import { AdSourceQpsLimitOption } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm/useExchangeProtectionsForm";

const { useFormInstance, useWatch } = Form;

const messageBuilder = (mames: string[]): string => {
    if (mames?.length === 1) {
        return `QPS Limit for ${mames.at(0)} needs to be specified!`;
    }
    const names: string = mames.map<string>((name) => name).join(", ");
    return `QPS Limits are missing and required for the following Regions: ${names}`;
};

interface UseQPSLimitsField {
    limit: number | string | undefined;
    regionId: number | undefined;
    handleAddQPSLimit: () => void;
    handleChangeLimit: (value: number | string) => void;
    adSourceQpsLimitOptions: AdSourceQpsLimitOption[];
    validator: (rule: RuleObject, value: StoreValue) => Promise<void>;
    onChange: (value: number, options: AdSourceQpsLimitOption[]) => void;
    handleChangeRegion: (value: number, option: { label: string; value: string; id: number }) => void;
}

export const useQPSLimitsField = (): UseQPSLimitsField => {
    const form = useFormInstance();
    const [regionId, setRegionId] = useState<number | undefined>(undefined);
    const [limit, setLimit] = useState<number | string | undefined>(undefined);
    const [region, setRegion] = useState<{ label: string | null; value: string | null; id: number | null }>({
        label: null,
        value: null,
        id: null,
    });
    const adSourceQpsLimitOptions: AdSourceQpsLimitOption[] = useWatch(INVENTORY_FORM_FIELDS.QPS_LIMITS.name) ?? [];

    const handleChangeRegion = (value: number, option: { label: string; value: string; id: number }): void => {
        setRegion(option);
        setRegionId(value);
    };

    const handleChangeLimit = (value: number | string): void => setLimit(value);
    const handleChange = (options: AdSourceQpsLimitOption[]): void => {
        form.setFieldValue(INVENTORY_FORM_FIELDS.QPS_LIMITS.name, options);
        form.validateFields([INVENTORY_FORM_FIELDS.QPS_LIMITS.name]);
    };

    const onChange = (_, options: AdSourceQpsLimitOption[]): void => handleChange(options);

    const handleAddQPSLimit = (): void => {
        if (!region.id || !region.label || !region.value) return;

        const newAdSourceQpsLimitOptions: AdSourceQpsLimitOption[] = [...adSourceQpsLimitOptions];

        const qpsLimit: AdSourceQpsLimit = {
            awsRegion: {
                id: region.id,
                name: region.label,
                code: region.value,
            },
            qpsLimit: Number(limit),
        };
        const option: AdSourceQpsLimitOption = {
            value: qpsLimit.awsRegion.id,
            label: getQpsLimitLabel(qpsLimit),
            qpsLimit,
        };

        const options: AdSourceQpsLimitOption[] = [...newAdSourceQpsLimitOptions, option];
        handleChange(options);
        setRegionId(undefined);
        setLimit(undefined);
    };

    const validator = (_: RuleObject, value: StoreValue): Promise<void> => {
        const qpsLimits: number[] = value.map(({ value }) => value);
        const { isValid, mames } = REQUIRED_QPS_LIMITS_REGIONS.reduce<{
            isValid: boolean;
            mames: string[];
        }>(
            (accumulator, { id, name }) => {
                if (!qpsLimits.includes(id)) return { isValid: false, mames: [...accumulator.mames, name] };
                return accumulator;
            },
            { isValid: true, mames: [] }
        );

        if (isValid) return Promise.resolve();

        const message: string = messageBuilder(mames);

        return Promise.reject(new Error(message));
    };

    return {
        limit,
        regionId,
        onChange,
        validator,
        handleChangeLimit,
        handleAddQPSLimit,
        handleChangeRegion,
        adSourceQpsLimitOptions,
    };
};
