import { LoadingState, reduceLoadingStates } from "@app/core/components";
import { FunnelChartProps, XYValue } from "@app/features/seatAdSources/seatAdSourcesCharts/charts";
import { useConnectLiveAdSourceAdServingAdStat, useConnectLiveAdSourceAdStat } from "../../data";

interface UseSeatAdSourcesAdCandidateSelectionChart {
    loadingState: LoadingState;
    funnelChartProps: FunnelChartProps | undefined;
}

export const useSeatAdSourcesAdCandidateSelectionChart = (
    adSourceId: number
): UseSeatAdSourcesAdCandidateSelectionChart => {
    const { loadingState: adSourceServingStatLoadingState, data: adSourceServingStat } =
        useConnectLiveAdSourceAdServingAdStat(adSourceId, true);
    const { loadingState: adSourceStatLoadingState, data: adSourceStat } = useConnectLiveAdSourceAdStat(
        adSourceId,
        true
    );

    const loadingState = reduceLoadingStates(adSourceServingStatLoadingState, adSourceStatLoadingState);

    let chartData: XYValue[] | undefined = undefined;
    const hasData = adSourceServingStat && adSourceStat;
    if (hasData) {
        chartData = [
            { xValue: "Opportunities", yValue: adSourceServingStat.opportunities },
            { xValue: "Floors", yValue: adSourceServingStat.floors },
            { xValue: "Freq. Cap", yValue: adSourceServingStat.frequencyCapping },
            { xValue: "Pacing", yValue: adSourceServingStat.pacing },
            { xValue: "Chosen", yValue: adSourceServingStat.chosen },
            { xValue: "Impressions", yValue: adSourceStat.impressions },
        ];
        if (adSourceServingStat.bidSuccess !== null) {
            chartData.push({ xValue: "Bid Success", yValue: adSourceServingStat.bidSuccess });
        }
        if (adSourceServingStat.creativeSuccess !== null) {
            chartData.push({ xValue: "Creative Success", yValue: adSourceServingStat.creativeSuccess });
        }
    }

    return {
        loadingState,
        funnelChartProps: chartData
            ? {
                  id: "seat-ad-sources-ad-candidate-selection-chart",
                  data: chartData,
              }
            : undefined,
    };
};
