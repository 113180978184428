import { FC } from "react";
import { Form, Radio } from "antd";
import {
    INVENTORY_FORM_FIELDS,
    SEAT_MOKA_FILTERING_MODE_OPTIONS,
} from "@app/features/inventory/InventorySeat/constants";

export const MokaIntegration: FC = () => {
    return (
        <Form.Item
            label={INVENTORY_FORM_FIELDS.MOKA_FILTERING_MODE.label}
            name={INVENTORY_FORM_FIELDS.MOKA_FILTERING_MODE.name}
        >
            <Radio.Group options={SEAT_MOKA_FILTERING_MODE_OPTIONS} />
        </Form.Item>
    );
};
