import { FC } from "react";
import { DealAdSource, DealListItem } from "@app/core/services";
import { Popover, Col, Row, Typography } from "antd";
import { formatNumber } from "@rubicon/utils";
import { Dashes } from "@app/core/components";
import { useCurrency } from "@app/features/deals/useCurrency";
import { useAppSelector } from "@app/core/store";
import { CurrencyConversionModes } from "@app/core/clients/console";
import { convert } from "@app/features/seatAdSources/helpers";
import { brandCobalt } from "@rubicon/antd-components";
import { SeatAdSourcesDetailsDrawerSecondaryView } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/types";

interface DealFloorPricesProps {
    deal: DealListItem;
    openDrawer: (adSourceId: number, secondaryDetailsView?: SeatAdSourcesDetailsDrawerSecondaryView) => void;
}

interface PopoverProps {
    adSources: DealAdSource[];
    currencyCode: string;
    handleOpenAdSourceDrawer: (number) => void;
}

export const getDisplayValue = (rate: number, currencyCode: string, isBookingPrice?: boolean): string => {
    const value = isBookingPrice ? rate / 1000 : rate;
    return formatNumber.asMoneyVerbose(value, currencyCode);
};
export const PopoverContent: FC<PopoverProps> = ({ adSources, handleOpenAdSourceDrawer, currencyCode }) => {
    return (
        <>
            <Typography.Paragraph>
                The deal is configured to multiple ad sources with <br />
                different floor prices <br />
            </Typography.Paragraph>
            <Row>
                <Col span={16}>Name</Col>
                <Col span={8}>Floor Price</Col>
            </Row>
            {adSources?.map((adSource) => (
                <Row key={adSource?.id}>
                    <Col span={16}>
                        <a
                            data-sdet="deal-floor-multiple-prices-item"
                            style={{ textTransform: "capitalize", color: brandCobalt }}
                            onClick={() => handleOpenAdSourceDrawer(adSource?.id)}
                        >
                            {adSource?.name}
                        </a>
                    </Col>

                    <Col span={8}>{getDisplayValue(adSource?.bookingPrice, currencyCode, true)}</Col>
                </Row>
            ))}
        </>
    );
};

const DealFloorPrices: FC<DealFloorPricesProps> = ({ deal, openDrawer }) => {
    const { dealCurrencyConversionMode, dealPreferredCurrency } = useAppSelector(
        (state) => state.deals.settings.values
    );

    const { currencyConversions, getCurrencyById } = useCurrency();
    const adSources: DealAdSource[] = [...(deal?.adSources || [])].sort((a, b) => a?.bookingPrice - b?.bookingPrice);
    if (!adSources.length) {
        return <Dashes />;
    }

    const prices = deal?.adSources?.map(({ bookingPrice }) => {
        return dealCurrencyConversionMode === CurrencyConversionModes.ORIGINATING_CURRENCY
            ? bookingPrice
            : convert(bookingPrice, deal.currencyType.id, currencyConversions, dealPreferredCurrency as number);
    });

    const currencyCode =
        dealCurrencyConversionMode === CurrencyConversionModes.ORIGINATING_CURRENCY
            ? deal.currencyType.code
            : (getCurrencyById(dealPreferredCurrency as number)?.code as string);

    return prices.length > 1 ? (
        <Popover
            title="Multiple Floor Prices"
            content={
                <PopoverContent
                    adSources={adSources}
                    handleOpenAdSourceDrawer={openDrawer}
                    currencyCode={currencyCode}
                />
            }
        >
            <span data-sdet="deal-floor-multiple-prices" style={{ color: "#1890FF" }}>
                {getDisplayValue(prices[0], currencyCode, true)} -{" "}
                {getDisplayValue(prices[prices.length - 1], currencyCode, true)} (Floor)
            </span>
        </Popover>
    ) : (
        <span data-sdet="deal-floor-single-price" style={{ color: "#1890FF" }}>
            {getDisplayValue(prices[0], currencyCode, true)} (Floor)
        </span>
    );
};

export default DealFloorPrices;
