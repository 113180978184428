import { FC } from "react";
import NavMenu from "../Nav/NavMenu";
import NavMenuItem from "../Nav/NavMenuItem";
import { NavMenuItemProps } from "../Nav/NavMenuItem";
import { SideDrawer } from "../SideDrawer";

interface Props {
    menuItems: NavMenuItemProps[];
    isOpen: boolean;
    handleClose: () => void;
    handleTypeClose: () => void;
}

export const SeatMoreDrawer: FC<Props> = ({ menuItems, ...restProps }) => {
    return (
        <SideDrawer {...restProps}>
            <NavMenu>
                {menuItems.map(({ id, description, title, onClick }) => (
                    <NavMenuItem key={id} id={id} title={title} description={description} onClick={onClick} />
                ))}
            </NavMenu>
        </SideDrawer>
    );
};
