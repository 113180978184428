export enum SeatAdSourcesDetailsDrawerSecondaryView {
    AdServing = "Ad Serving",
    ChangeHistory = "Change History",
    CreativeReview = "Creative Review",
    Ladle = "Ladle",
    Performance = "Performance",
    Permissions = "Permissions",
    TimingStats = "Timing Stats",
    VastErrors = "VAST Errors",
}

export enum SeatAdSourcesDetailsOpenedFromView {
    DealsHealth = "deal-health",
    DealsEdit = "deal-edit",
    DealsDetailsPage = "deal-details-page",
}
