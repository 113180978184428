import { FC } from "react";
import { Typography } from "antd";
import {
    CustomRuleFields,
    CustomRuleIds,
    CustomRuleOperators,
    GroupConditions,
    TargetingDimensionTypeLabels,
} from "@app/features/targeting/constants";
import { DefinitionsAndTipsDrawerButton } from "../../DefinitionsAndTipsDrawerButton";
import { CustomRuleGroupRow } from "./CustomRuleGroupRow";

const MOCK_SIMPLE_USE_CASE_GROUP = {
    condition: GroupConditions.And,
    rules: [
        {
            id: CustomRuleIds.CustomString,
            field: CustomRuleFields.CustomString,
            type: "string" as const,
            input: "textarea" as const,
            operator: CustomRuleOperators.Equal,
            value: "A",
            readonly: false,
        },
        {
            id: CustomRuleIds.CustomString2,
            field: CustomRuleFields.CustomString2,
            type: "string" as const,
            input: "textarea" as const,
            operator: CustomRuleOperators.Equal,
            value: "B",
            readonly: false,
        },
    ],
    valid: true,
    readonly: false,
};

const MOCK_SUBGROUP_GROUP = {
    condition: GroupConditions.And,
    rules: [
        {
            id: CustomRuleIds.CustomString,
            field: CustomRuleFields.CustomString,
            type: "string" as const,
            input: "textarea" as const,
            operator: CustomRuleOperators.Equal,
            value: "A",
            readonly: false,
        },
        {
            id: CustomRuleIds.CustomString2,
            field: CustomRuleFields.CustomString2,
            type: "string" as const,
            input: "textarea" as const,
            operator: CustomRuleOperators.Equal,
            value: "B",
            readonly: false,
        },
        {
            condition: GroupConditions.Or,
            rules: [
                {
                    id: CustomRuleIds.CustomString3,
                    field: CustomRuleFields.CustomString3,
                    type: "string" as const,
                    input: "textarea" as const,
                    operator: CustomRuleOperators.Equal,
                    value: "C",
                    readonly: false,
                },
                {
                    id: CustomRuleIds.CustomString4,
                    field: CustomRuleFields.CustomString4,
                    type: "string" as const,
                    input: "textarea" as const,
                    operator: CustomRuleOperators.Equal,
                    value: "D",
                    readonly: false,
                },
            ],
            readonly: false,
        },
    ],
    valid: true,
    readonly: false,
};

const noOp = () => {
    /* no-op */
};

const mockGroupCommonProps = {
    index: 0,
    isValidationShown: false,
    onChangeId: noOp,
    onChangeCondition: noOp,
    onChangeOperator: noOp,
    onChangeValue: noOp,
    onClickAddRule: noOp,
    onClickAddRuleGroup: noOp,
    onClickDeleteRule: noOp,
    path: [],
};

export const CustomRuleDefinitionsAndTipsDrawerButton: FC = () => {
    return (
        <DefinitionsAndTipsDrawerButton
            label={TargetingDimensionTypeLabels.CustomRules}
            examples={
                <>
                    <Typography.Title level={5} style={{ marginTop: "2rem" }}>
                        EXAMPLE 1 - Simple use case
                    </Typography.Title>
                    <Typography.Paragraph>
                        For example, let&lsquo;s say that you wanted to target custom string 1 equals A AND custom
                        string 2 equals B
                    </Typography.Paragraph>
                    <CustomRuleGroupRow group={MOCK_SIMPLE_USE_CASE_GROUP} {...mockGroupCommonProps} />
                    <Typography.Title level={5} style={{ marginTop: "2rem" }}>
                        EXAMPLE 2 - Use of subgroup
                    </Typography.Title>
                    <Typography.Paragraph>
                        For example, let&lsquo;s say that you wanted to target custo string 1 equals A AND custom string
                        2 equals B AND (custom string 3 equals C OR custom string 4 equals D).
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        The rule below is stating that the outer AND group must be met AND the subgroup within it must
                        be either C OR D.
                    </Typography.Paragraph>
                    <CustomRuleGroupRow group={MOCK_SUBGROUP_GROUP} {...mockGroupCommonProps} />
                </>
            }
        />
    );
};
