import { FC } from "react";
import { Radio } from "antd";
import { AppstoreOutlined, MenuOutlined } from "@ant-design/icons";
import { SeatAdSourcesListDisplayMode } from "../reducer";
import { useSeatAdSourcesListPage } from "../useSeatAdSourcesListPage";

const ListDisplayOptions = [
    { displayMode: SeatAdSourcesListDisplayMode.TableView, icon: <MenuOutlined /> },
    { displayMode: SeatAdSourcesListDisplayMode.GridView, icon: <AppstoreOutlined /> },
] as const;

export const SeatAdSourcesListViewRadio: FC = () => {
    const { activeDisplayMode, toggleDisplayMode } = useSeatAdSourcesListPage();

    return (
        <Radio.Group
            data-sdet="seat-ad-sources-list-view-radio"
            defaultValue={activeDisplayMode}
            onChange={toggleDisplayMode}
        >
            {ListDisplayOptions.map((displayOption) => (
                <Radio.Button key={displayOption.displayMode} value={displayOption.displayMode}>
                    {displayOption.icon}
                </Radio.Button>
            ))}
        </Radio.Group>
    );
};
