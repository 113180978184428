import { Form } from "antd";
import { FC } from "react";
import { Labels } from "@app/features/inventory/components/Fields/Labels";
import { SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

interface SupplyLabelsProps {
    name: typeof SUPPLY_FORM_FIELDS.LABELS.name | typeof SUPPLY_FORM_FIELDS.INTERNAL_LABELS.name;
    label: typeof SUPPLY_FORM_FIELDS.LABELS.label | typeof SUPPLY_FORM_FIELDS.INTERNAL_LABELS.label;
}

export const SupplyLabels: FC<SupplyLabelsProps> = ({ name, label }) => {
    return (
        <Form.Item label={label} wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 }}>
            <Labels name={name} />
        </Form.Item>
    );
};
