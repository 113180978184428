import { FC, CSSProperties } from "react";
import Icon from "@ant-design/icons";

interface Props {
    style?: CSSProperties;
}

export const ChannelSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="currentColor">
            <g id="icon_channel" transform="translate(11044 -1745)">
                <rect
                    id="Rectangle_704"
                    data-name="Rectangle 704"
                    width="1"
                    height="2"
                    transform="translate(-11039.879 1748.354) rotate(135)"
                />
                <rect
                    id="Rectangle_706"
                    data-name="Rectangle 706"
                    width="1"
                    height="2"
                    transform="translate(-11041.293 1757.121) rotate(-135)"
                />
                <rect
                    id="Rectangle_705"
                    data-name="Rectangle 705"
                    width="1"
                    height="3"
                    transform="translate(-11030 1751.5) rotate(90)"
                />
                <circle
                    id="Ellipse_55"
                    data-name="Ellipse 55"
                    cx="1.5"
                    cy="1.5"
                    r="1.5"
                    transform="translate(-11044 1745)"
                />
                <circle
                    id="Ellipse_57"
                    data-name="Ellipse 57"
                    cx="1.5"
                    cy="1.5"
                    r="1.5"
                    transform="translate(-11044 1756)"
                />
                <circle
                    id="Ellipse_56"
                    data-name="Ellipse 56"
                    cx="1.5"
                    cy="1.5"
                    r="1.5"
                    transform="translate(-11031 1750.5)"
                />
                <path
                    id="Ellipse_54"
                    data-name="Ellipse 54"
                    d="M5,1A4,4,0,1,0,9,5,4,4,0,0,0,5,1M5,0A5,5,0,1,1,0,5,5,5,0,0,1,5,0Z"
                    transform="translate(-11042 1747)"
                />
                <path
                    id="Subtraction_1"
                    data-name="Subtraction 1"
                    d="M3,6A3,3,0,1,1,5.83,2h-1.1a2,2,0,1,0,0,2h1.1A3.006,3.006,0,0,1,3,6Z"
                    transform="translate(-11040 1749)"
                />
            </g>
        </svg>
    );
};

export const ChannelIcon: FC<Props> = ({ style }) => <Icon style={style} component={ChannelSvg} />;
