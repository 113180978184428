import { useGetSeatThirdPartyPixelsQuery } from "@app/core/services";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { AdSourceFormsContext } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

export const usePixelsMultiselect = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data: pixels = [], isFetching } = useGetSeatThirdPartyPixelsQuery(Number(seatId));
    const {
        forms: { adSourceFormMode, adSourceForm },
    } = useContext(AdSourceFormsContext);

    useEffect(() => {
        if (adSourceFormMode === "create") {
            const addToAdSourceByDefaultPixels = pixels.filter((pixel) => pixel.addToAdSourceByDefault);
            adSourceForm.setFieldValue(AD_SOURCE_FIELDS.PIXELS.name, addToAdSourceByDefaultPixels);
        }
    }, [adSourceForm, adSourceFormMode, pixels]);

    return {
        pixels,
        isLoading: isFetching,
    };
};
