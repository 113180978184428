import { Form } from "antd";
import { SeatAdSourcesType, useGetAdvertiserBlockingModesQuery } from "@app/core/services";
import { AD_SOURCE_FIELDS, AdSourceAdvertiserDomainModeIds } from "@app/features/seatAdSources/constants";

export const useModeSelect = () => {
    const form = Form.useFormInstance();
    const { data: blockingModes = [], isLoading } = useGetAdvertiserBlockingModesQuery();

    const handleChange = (value: SeatAdSourcesType): void => {
        const isOverrideInventoryBlockedDomains: boolean =
            value?.id === AdSourceAdvertiserDomainModeIds.ALLOW_OVERRIDE_INVENTORY;
        if (isOverrideInventoryBlockedDomains) form.setFieldValue(AD_SOURCE_FIELDS.IAB_BLOCKED_CATEGORIES.name, []);
    };

    return { blockingModes, isLoading, handleChange };
};
