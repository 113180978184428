import { Form } from "antd";
import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { useUserAccess } from "@app/core/auth";
import { useGetVideoIdMacrosQuery } from "@app/core/services";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

const { useWatch } = Form;

interface UseContentMetadataKeys {
    isFetching: boolean;
    options: LabeledValue[];
    hasInternalAccess: boolean;
}

export const useContentMetadataKeys = (): UseContentMetadataKeys => {
    const { data = [], isFetching } = useGetVideoIdMacrosQuery();
    const { isSysAdmin, isPubAcctMgr } = useUserAccess();
    const videoIdMacros: LabeledValue[] = useMemo(
        () =>
            (data || []).map((videoIdMacro) => ({
                label: videoIdMacro.macro,
                value: videoIdMacro.id,
            })),
        [data]
    );
    const contentMetadataKeys = useWatch(INVENTORY_FORM_FIELDS.SEAT_CONTENT_METADATA_KEYS.name) || [];
    const values = contentMetadataKeys.map((metadata) => metadata?.value);
    const options = videoIdMacros.filter((options) => !values.includes(options.value));

    return {
        options,
        isFetching,
        hasInternalAccess: isSysAdmin || isPubAcctMgr,
    };
};
