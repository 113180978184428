import { MarketplaceInfo } from "@app/core/services";
import { ReadonlyControl } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { Col, Form } from "antd";
import { FC, useEffect } from "react";
import { PRICING_TYPE_OPTIONS } from ".";
import { DEAL_TYPES } from "../DealTypeSelect";

export const PricingTypeSelect: FC = () => {
    const dealType = Form.useWatch<MarketplaceInfo["deal"]["dealType"]>(["dealUnderEdit", "dealType"]);
    const dealObjectForm = Form.useFormInstance();
    const isVisible = dealType?.name !== DEAL_TYPES.DEAL_TYPE_LEGACY.name;
    const { dealObjectMode } = useMarketplaceInfoWidget();

    useEffect(() => {
        if (dealType && dealObjectMode === "create") {
            let dealPricingType;
            if (
                dealType.name === DEAL_TYPES.DEAL_TYPE_PG.name ||
                dealType.name === DEAL_TYPES.DEAL_TYPE_FIXED_PRICE.name
            ) {
                dealPricingType = PRICING_TYPE_OPTIONS.FIXED;
            }
            if (dealType.name === DEAL_TYPES.DEAL_TYPE_AUCTION_PRICE.name) {
                dealPricingType = PRICING_TYPE_OPTIONS.AUCTION;
            }
            return dealObjectForm.setFieldValue(["dealUnderEdit", "dealPricingType"], dealPricingType);
        }
    }, [dealObjectForm, dealObjectMode, dealType]);

    if (!isVisible) {
        return null;
    }

    return (
        <Col sm={12}>
            <Form.Item label="Pricing Type" name={["dealUnderEdit", "dealPricingType"]}>
                <ReadonlyControl<MarketplaceInfo["deal"]["dealPricingType"]> getLabel={(type) => type.name} />
            </Form.Item>
        </Col>
    );
};
