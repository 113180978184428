import { FC } from "react";
import { Select, Form } from "antd";
import { ColProps } from "antd/lib/grid/col";
import { SelectProps } from "antd/lib/select";
import { TagRender } from "@app/core/components";
import { Rule } from "rc-field-form/lib/interface";
import { useIndustries } from "@app/features/inventory/components/FormItems/Industries/useIndustries";

interface IndustriesProps extends SelectProps {
    name: string;
    label: string;
    rules?: Rule[];
    labelCol?: ColProps;
    wrapperCol?: ColProps;
}

export const Industries: FC<IndustriesProps> = ({ label, name, wrapperCol, labelCol, rules, ...rest }) => {
    const { handleChange, handleReset, isFetching, options, localValue } = useIndustries();

    return (
        <Form.Item name={name} label={label} labelCol={labelCol} wrapperCol={wrapperCol} rules={rules}>
            <Select
                showSearch
                allowClear
                labelInValue
                mode="multiple"
                options={options}
                loading={isFetching}
                onClear={handleReset}
                tagRender={TagRender}
                onSearch={handleChange}
                optionFilterProp="label"
                searchValue={localValue}
                style={{ width: "100%" }}
                autoClearSearchValue={false}
                {...rest}
            />
        </Form.Item>
    );
};
