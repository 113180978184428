import { MarketplaceInfo } from "@app/core/services";
import { Space, Button, Form } from "antd";
import { useContext } from "react";
import { FC } from "react";
import { AdSourceFormsContext } from "../SeatAdSourcesForm";
import { useMarketplaceInfoWidget } from "../useMarketplaceInfoWidget";

export const Footer: FC = () => {
    const { forms } = useContext(AdSourceFormsContext);
    const { close, marketplaceInfoMode, dealObjectMode } = useMarketplaceInfoWidget();
    const dealOrigin = Form.useWatch<MarketplaceInfo["deal"]>(["dealOrigin"], forms.dealObjectForm);
    const isSubmitMarketplaceInfoDisabled = dealObjectMode !== "view" || !dealOrigin;
    return (
        <Space>
            <Button onClick={close}>Cancel</Button>
            <Button
                disabled={isSubmitMarketplaceInfoDisabled}
                type="primary"
                onClick={forms.demandConfigurationForm?.submit}
            >
                {marketplaceInfoMode.name === "edit" ? "Save" : "Add Deal"}
            </Button>
        </Space>
    );
};
