import { FC } from "react";

interface Props {
    title: string;
}

export const IframeBetaLabel: FC<Props> = ({ title }) => {
    return (
        <span>
            {title} <span style={{ color: "#F6D16B", fontSize: "0.75rem" }}>Beta</span>
        </span>
    );
};
