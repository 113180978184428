import { useEffect, useMemo, useRef } from "react";
import { w3cwebsocket } from "websocket";
import { useSeatAuthContext } from "@app/core/auth";
import { sendLoginEvent } from "@app/core/socket/events";
import { EventTypes } from "@app/core/socket/types";
import { parseMessageData, isSocketOpen } from "@app/core/socket/utils";
import conf from "@app/core/conf";
import { useGetPlatformNotificationsCountQuery, useGetPlatformNotificationsQuery } from "@app/core/services/console";
import moment from "moment-timezone";

const EVENTS_WEBSOCKET = `${conf.mctvNotificationsWebsocketRoot}/platform/websocket/events`;

export const useEvents = () => {
    const { session } = useSeatAuthContext();
    const socket = useRef<w3cwebsocket | null>(null);
    const { data: notificationsCount = 0, refetch: refetchCount } = useGetPlatformNotificationsCountQuery();
    const { data: notificationsData = [], refetch: refetchNotifications } = useGetPlatformNotificationsQuery();

    const notifications = useMemo(() => {
        const res = [...notificationsData];
        res.sort((a, b) => (moment(a.creationDate).isBefore(b.creationDate) ? 1 : -1));
        return res;
    }, [notificationsData]);

    useEffect(() => {
        if (!session || socket.current) {
            return;
        }

        socket.current = new w3cwebsocket(EVENTS_WEBSOCKET);

        socket.current.onopen = () => {
            if (socket.current) {
                sendLoginEvent(socket.current, session.sessionCode, session.user.emailAddress);
            }
        };

        socket.current.onmessage = (message) => {
            const eventData = parseMessageData(message);

            if (eventData.type === EventTypes.Notification) {
                refetchCount();
            }
        };

        return () => {
            if (isSocketOpen(socket.current)) {
                socket.current.close();
            }
        };
    }, [session, refetchCount, refetchNotifications]);

    useEffect(() => {
        refetchNotifications();
    }, [notificationsCount, refetchNotifications]);

    return {
        notificationsCount,
        notifications,
    };
};
