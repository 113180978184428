import { AD_SOURCE_FIELDS, COL_SIZES, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { Col, Form } from "antd";
import { useFloorTypeSelect } from "./useFloorTypeSelect";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";

export const FloorTypeSelect = () => {
    const { floorTypes, isLoading, isVisible } = useFloorTypeSelect();

    if (!isVisible) {
        return null;
    }
    return (
        <Col {...COL_SIZES}>
            <Form.Item
                name={AD_SOURCE_FIELDS.FLOOR_TYPE.name}
                label={AD_SOURCE_FIELDS.FLOOR_TYPE.label}
                data-sdet={`test-${AD_SOURCE_FIELDS.FLOOR_TYPE.name}-field`}
                {...VERTICAL_LAYOUT_FIX}
            >
                <SelectWithValue
                    placeholder={`Select ${AD_SOURCE_FIELDS.FLOOR_TYPE.label}`}
                    data-sdet={`test-${AD_SOURCE_FIELDS.FLOOR_TYPE.name}-select`}
                    loading={isLoading}
                    showSearch
                    options={floorTypes}
                    optionFilterProp="label"
                    fieldAsLabel={["name"]}
                    fieldAsValue={["id"]}
                />
            </Form.Item>
        </Col>
    );
};
