import { FC, MouseEvent } from "react";
import { Select, Tag } from "antd";
import { useDealFreqCappings } from "@app/features/deals/DealForm/DealDemandFormSections/DealDemandConfigurationSection/Fields/DealFreqCappings/useDealFreqCappings";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import { useAppDispatch } from "@app/core/store";
import { dealFormFrequencyCappingItemRemove } from "@app/features/deals/DealForm/reducer";

export const TagRender = (props: CustomTagProps) => {
    const dispatch = useAppDispatch();
    const { label, value, closable } = props;
    const handleClose = () => {
        dispatch(dealFormFrequencyCappingItemRemove({ id: value }));
    };
    const onPreventMouseDown = (event: MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            data-sdet="deal-frequency-capping-items-tags"
            color="blue"
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={handleClose}
            style={{ marginRight: 3 }}
        >
            {label}
        </Tag>
    );
};
export const DealFreqCappings: FC = () => {
    const { options, value } = useDealFreqCappings();
    return (
        <Select
            data-sdet="deal-frequency-capping-items-field"
            mode="multiple"
            tagRender={TagRender}
            value={value}
            showSearch={false}
            open={false}
            style={{ width: "100%", marginBottom: "1.5rem" }}
            options={options}
        />
    );
};
