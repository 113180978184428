import { Form } from "antd";
import { FC } from "react";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import { SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useMode } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/GeneralSection/Fields/Mode/useMode";

export const Mode: FC = () => {
    const { disabled, extra } = useMode();
    return (
        <Form.Item name={SUPPLY_FORM_FIELDS.MODE.name} label={SUPPLY_FORM_FIELDS.MODE.label} extra={extra}>
            <BooleanRadioGroup disabled={disabled} trueLabel="Dynamic" falseLabel="Static" />
        </Form.Item>
    );
};
