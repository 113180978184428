import { consoleApi } from "./console";

export interface AdServerLadle {
    adomain?: string;
    adSourceId?: number;
    awsRegionCode?: string;
    bundleId?: number;
    creativeId?: number;
    dealCode?: string | number;
    hasCreative?: boolean | null;
    ladleName?: string;
    lineItemId?: number;
    ladleType?: number;
    networkCode?: string;
    orderId?: number;
    sourceDomain?: string;
    winNurlDomain?: string;
}

export interface AdServerLadleResponse {
    id?: number;
    ladleId?: string | number;
    ladleName?: string;
    errorMsg?: string;
}

export interface AdServerLadlesArgs {
    id: number | null;
    body: AdServerLadle;
}

export const adServerLadlesApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        activateAdServerLadle: builder.mutation<AdServerLadleResponse, AdServerLadlesArgs>({
            query: ({ id: ladleId }: AdServerLadlesArgs) => ({
                url: `/adServerLadles/${ladleId}/activate`,
                method: "POST",
                body: {},
            }),
        }),
        createAdServerLadlesForDeal: builder.mutation<AdServerLadleResponse, AdServerLadlesArgs>({
            query: ({ id: dealId, body }: AdServerLadlesArgs) => ({
                url: `/adServerLadles/pub/deal/${dealId}/activate`,
                method: "POST",
                body: body,
            }),
        }),
        activateAdServerLadlesForSeatAdSource: builder.mutation<AdServerLadleResponse, AdServerLadlesArgs>({
            query: ({ id: adSourceId, body }: AdServerLadlesArgs) => ({
                url: `/adServerLadles/pub/adSource/${adSourceId}/activate`,
                method: "POST",
                body: body,
            }),
        }),
        activateAdServerLadlesForAdUnit: builder.mutation<AdServerLadleResponse, AdServerLadlesArgs>({
            query: ({ id: adUnitId, body }: AdServerLadlesArgs) => ({
                url: `/adServerLadles/pub/adUnit/${adUnitId}/activate`,
                method: "POST",
                body: body,
            }),
        }),
        createAdServerLadle: builder.mutation<AdServerLadleResponse, AdServerLadlesArgs>({
            query: ({ body }: AdServerLadlesArgs) => ({
                url: `/adServerLadles`,
                method: "POST",
                body: body,
            }),
        }),
    }),
});

export const {
    useCreateAdServerLadlesForDealMutation,
    useActivateAdServerLadlesForSeatAdSourceMutation,
    useActivateAdServerLadleMutation,
    useActivateAdServerLadlesForAdUnitMutation,
    useCreateAdServerLadleMutation,
} = adServerLadlesApi;
