import { LabeledValue } from "antd/lib/select";
import { useGetUsersQuery } from "@app/core/services";
import { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { useLoadOnScroll } from "@app/core/components";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";

interface UseAdSourceSalesContactField {
    hasMore: boolean;
    isFetching: boolean;
    loadMore: () => void;
    onChange: (value: number) => void;
    onSearch: (value: string) => void;
    options: LabeledValue[];
    searchValue: string | undefined;
    value: number | null;
}

const MAX = 200;

export const useAdSourceSalesContactField = (): UseAdSourceSalesContactField => {
    const dispatch = useAppDispatch();
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_SALES_CONTACT]);

    const [searchValue, setSearchValue] = useState<string>("");
    const [keyword, setKeyword] = useState<string>("");
    const [pageByKeyword, setPageByKeyword] = useState({ "": 1 });
    const page = pageByKeyword[keyword] || 1;

    const { data, isFetching, originalArgs } = useGetUsersQuery({ keyword, max: MAX, page });

    const {
        options: rawOptions,
        hasMore,
        loadMore,
    } = useLoadOnScroll(data, isFetching, keyword, originalArgs?.page || 1, data?.length === MAX, () =>
        setPageByKeyword((prev) => {
            const currentPage = prev[keyword] || 1;
            return {
                ...prev,
                [keyword]: currentPage + 1,
            };
        })
    );

    const options = useMemo(
        () =>
            (rawOptions || []).map((item) => ({
                label: `${item.name} [${item.emailAddress}]`,
                value: item.id,
            })),

        [rawOptions]
    );

    const onChange = (value: number): void => {
        dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_SALES_CONTACT, value }));
    };

    const debouncedSetKeyword = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setKeyword]
    );

    const onSearch = (value: string): void => {
        setSearchValue(value);
        debouncedSetKeyword(value);
    };

    return {
        hasMore,
        isFetching,
        loadMore,
        onChange,
        onSearch,
        options,
        searchValue,
        value,
    };
};
