import { Targeting } from "@app/core/services";
import { Alert, Col, Row } from "antd";
import { FC } from "react";
import { Operations, TargetingFormKeys } from "../../constants";
import { InventoryAdvancedFloorsTargetingSectionHeader } from "../../TargetingSectionHeader/InventoryAdvancedFloorsTargetingSectionHeader";
import { mergeTargeting } from "../helpers";
import { ReadOnlyTargetingBlocks } from "../ReadOnlyTargetingBlocks";

const OR_ID = 2;

interface Props {
    targetingBlocks: Targeting[];
    targetingModeId: number;
    dealsWithTargeting: number;
}

export const ReadOnlyInvnetoryAdvancedFloorsTargeting: FC<Props> = ({
    targetingModeId,
    dealsWithTargeting,
    targetingBlocks,
}) => {
    const operation = targetingModeId === OR_ID ? Operations.Any : Operations.All;

    return (
        <Alert.ErrorBoundary>
            <Row>
                <Col xs={24}>
                    <InventoryAdvancedFloorsTargetingSectionHeader
                        operation={operation}
                        targetingBlocksCount={targetingBlocks.length}
                        reusableTargetingBlocksCount={targetingBlocks.filter((tb) => tb.name != null).length}
                        dealsWithTargeting={dealsWithTargeting || 0}
                    />
                    <ReadOnlyTargetingBlocks
                        targetingBlocks={mergeTargeting(targetingBlocks, targetingModeId)}
                        operation={operation}
                        formKey={TargetingFormKeys.InventoryAdvancedFloors}
                        wrappedCollapse={false}
                    />
                </Col>
            </Row>
        </Alert.ErrorBoundary>
    );
};
