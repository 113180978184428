import { FC } from "react";
import { Col, Form, Row } from "antd";
import { BRAND_SUPPLY_DETAILS_LABELS } from "../../../constants";
import { Brand } from "@app/core/services";
import { getYesNoLabel } from "@app/core/utils/labels";
import { NONE } from "@app/core/components/constants";

const { Item } = Form;

interface InternalSectionProps {
    brand: Brand;
}

export const InternalSection: FC<InternalSectionProps> = ({ brand }) => {
    return (
        <Row gutter={16}>
            <Col span={24}>
                <Item label={BRAND_SUPPLY_DETAILS_LABELS.publisherReauction}>
                    {getYesNoLabel(Boolean(brand.publisherReauction))}
                </Item>
            </Col>
            <Col span={24}>
                <Item label={BRAND_SUPPLY_DETAILS_LABELS.loverCalculonUseRate}>
                    {brand.calculonUseRateOverride ?? NONE}
                </Item>
            </Col>
            <Col span={24}>
                <Item label={BRAND_SUPPLY_DETAILS_LABELS.upperCalculonUseRate}>
                    {brand.upperCalculonUseRateOverride ?? NONE}
                </Item>
            </Col>
        </Row>
    );
};
