import { LabeledValue } from "antd/lib/select";

// From console source src/main/webapp/js/vm/home/supplyhome.vm.js
export const OVERALL = { id: 1, name: "Overall" };
export const PODS_AND_PLAYLISTS = { id: 2, name: "Pods & Playlists" };
export const STANDARD = { id: 3, name: "Standard" };

export const VIEW_OPTIONS = [OVERALL, PODS_AND_PLAYLISTS, STANDARD];

export const VIEW_OPTIONS_LABEL_VALUE = VIEW_OPTIONS.map<LabeledValue>((option) => ({
    value: option.id,
    label: option.name,
}));

export const OVERALL_LABEL_VALUE = { value: OVERALL.id, label: OVERALL.name };
