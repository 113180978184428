import { FC } from "react";
import { AdUnitSupportedProtocols } from "@app/features/inventory/components/FormItems";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const SupportedProtocols: FC = () => (
    <AdUnitSupportedProtocols
        name={CHANNEL_FORM_FIELDS.SUPPORTED_PROTOCOLS.name}
        label={CHANNEL_FORM_FIELDS.SUPPORTED_PROTOCOLS.label}
        placeholder={CHANNEL_FORM_FIELDS.SUPPORTED_PROTOCOLS.placeholder}
    />
);
