import { FC } from "react";
import { Radio } from "antd";
import { TRANSPARENCY_OPTIONS } from "@app/features/deals/constants";
import { useAdSourceFullIpAddressField } from "./useAdSourceFullIpAddressField";
import { TransparencyFormItem } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceTransparencySection/Fields";

export const AdSourceFullIpAddressField: FC = () => {
    const { value, onChange } = useAdSourceFullIpAddressField();
    return (
        <TransparencyFormItem label="Full IP Address" data-sdet="full-ip-address">
            <Radio.Group onChange={(e) => onChange(e?.target?.value)} value={value} options={TRANSPARENCY_OPTIONS} />
        </TransparencyFormItem>
    );
};
