import { FC } from "react";
import { Card, Col, Row } from "antd";
import { GUTTER } from "@app/features/deals/constants";
import { cardBodyStyle } from "@app/features/deals/DealForm";
import {
    AdSourceLabel,
    AdSourceWaterfall,
} from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceLabelsSection/Fields";

export const AdSourceLabelsSection: FC = () => {
    return (
        <Card bordered={false} styles={{ body: cardBodyStyle }} data-sdet="ad-source-labels-section">
            <Row gutter={GUTTER}>
                <Col span={24}>
                    <AdSourceWaterfall />
                </Col>
                <Col span={24}>
                    <AdSourceLabel />
                </Col>
            </Row>
        </Card>
    );
};
