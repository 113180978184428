import { FC } from "react";
import { format } from "@rubicon/utils";
import { Radio, RadioProps } from "antd";
import { RadioChangeEvent } from "antd/lib/radio/interface";

const { YES, NO } = format.constants;

interface BooleanRadioGroupProps extends Omit<RadioProps, "onChange"> {
    onChange?: (value: boolean) => void;
    value?: boolean;
    trueLabel?: string;
    falseLabel?: string;
}

export const BooleanRadioGroup: FC<BooleanRadioGroupProps> = ({
    trueLabel = YES,
    falseLabel = NO,
    onChange,
    ...rest
}) => {
    const handleChange = (e: RadioChangeEvent): void => onChange?.(e.target.value);

    return (
        <Radio.Group onChange={handleChange} {...rest}>
            <Radio data-sdet="radio-input-yes" value={true}>
                {trueLabel}
            </Radio>
            <Radio data-sdet="radio-input-no" value={false}>
                {falseLabel}
            </Radio>
        </Radio.Group>
    );
};
