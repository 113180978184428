import { useGetAdUnitQuery } from "@app/core/services/console";
import { useParams } from "react-router-dom";

interface AdUnitFloorsPageParams {
    adUnitId: string;
}

export const useAdUnitById = () => {
    const { adUnitId } = useParams<AdUnitFloorsPageParams>();
    const { data: adUnit, isFetching } = useGetAdUnitQuery(Number(adUnitId));

    return {
        adUnit,
        isFetching,
    };
};
