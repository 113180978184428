import { useAppDispatch, useAppSelector } from "@app/core/store";

import { SeatAdSourcesDetailsDrawerSecondaryHeader as SecondaryHeaderView } from "../SeatAdSourcesDetailsDrawerContent/SeatAdSourcesDetailsDrawerHeader";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "../types";
import {
    selectRequestedAdSourceId,
    selectSecondaryDrawerHeader,
    switchToDefaultView,
    switchToNestedView,
} from "../reducer";
import { useParams } from "react-router-dom";
import { convertSecondaryViewFromURL } from "../normalizeSecondaryViewFromURL";

interface UseSeatAdSourcesDetailsDrawerContent {
    requestedAdSourceId: number;
    secondaryDrawerHeader: SecondaryHeaderView;
    secondaryDetailsView: SecondaryDetailsView;
    switchToNestedDetailsView: (secondaryDrawerView: SecondaryDetailsView) => void;
    switchToDefaultDetailsView: () => void;
}

export const useSeatAdSourcesDetailsDrawerContent = (): UseSeatAdSourcesDetailsDrawerContent => {
    const dispatch = useAppDispatch();
    const { secondaryView } = useParams<{ secondaryView: string }>();
    const requestedAdSourceId = useAppSelector(selectRequestedAdSourceId);
    const secondaryDrawerHeader = useAppSelector(selectSecondaryDrawerHeader);
    const secondaryDetailsView = convertSecondaryViewFromURL(secondaryView) as SecondaryDetailsView;

    const switchToNestedDetailsView = (secondaryDetails: SecondaryDetailsView) => {
        dispatch(switchToNestedView(secondaryDetails));
    };

    const switchToDefaultDetailsView = () => {
        dispatch(switchToDefaultView());
    };

    return {
        requestedAdSourceId,
        secondaryDrawerHeader,
        secondaryDetailsView,
        switchToNestedDetailsView,
        switchToDefaultDetailsView,
    };
};
