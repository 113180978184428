import { useGetAdSourcePacingQuery } from "@app/core/services";
import { formatNumber } from "@rubicon/utils";
import { YEAR_MONTH_DAY } from "../../constants";
import moment from "moment-timezone";

const NOT_SPECIFIED = "Not Specified";

interface UsePacingAdminDiagnostics {
    isLoading: boolean;
    dailyAvails: string;
    dailyAvailsLastUpdated: string;
    yesterdayOpportunity: string;
    totalImps: number | string;
    impsToday: number | string;
    lastUpdated: string;
    deliveryPct: number | string;
    todaysGoal: string;
}

export const usePacingAdminDiagnostics = (id: number): UsePacingAdminDiagnostics => {
    const { data, isFetching } = useGetAdSourcePacingQuery(id);

    const dailyAvails = data?.reqOpportunity ? formatNumber.asNumber(data.reqOpportunity) : NOT_SPECIFIED;
    const yesterdayOpportunity = data?.yesOpp ? formatNumber.asNumber(data.yesOpp) : NOT_SPECIFIED;
    const totalImps = data?.totalImpCount ? formatNumber.asNumber(data.totalImpCount) : 0;
    const impsToday = data?.todayImpCount ? formatNumber.asNumber(data.todayImpCount) : 0;
    const lastUpdated = moment(data?.lastIndexed).format(YEAR_MONTH_DAY);
    const deliveryPct = data?.deliveryPercentage ? data.deliveryPercentage : NOT_SPECIFIED;
    const todaysGoal = formatNumber.asNumber(data?.todaysGoal || 0);

    return {
        totalImps,
        impsToday,
        todaysGoal,
        dailyAvails,
        lastUpdated,
        deliveryPct,
        isLoading: isFetching,
        yesterdayOpportunity,
        dailyAvailsLastUpdated: NOT_SPECIFIED,
    };
};
