import { FC } from "react";
import { Button, Col, Flex, Row, Spin, Typography } from "antd";
import { css } from "@emotion/css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ResizableDrawer, gray7, gray8 } from "@rubicon/antd-components";
import { useDeleteAdvancedBrandSafetyMutation, useGetAdvancedBrandSafetyByIdQuery } from "@app/core/services";
import { BrandSafetyDetailsDrawerActions } from "@app/features/inventory/BrandSafetyDetailsDrawer/BrandSafetyDetailsDrawerActions";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { useBrandSafetyDetailsDrawer } from "./useBrandSafetyDetailsDrawer";
import { BrandSafetySectionKey } from "./helpKeysButton";
import { BrandSafetyDefinition } from "./BrandSafetyDefinition";
import { BrandSafetyDetails } from "./BrandSafetyDetails";
import { DrawerCloseButton } from "@app/core/components";
import { useBrandSafetyDeleteModal } from "@app/features/controls/brandSafety/BrandSafetyDeleteModal/useBrandSafetyDeleteModal";
import { BrandSafetyDeleteModal } from "@app/features/controls/brandSafety/BrandSafetyDeleteModal";

export const backArrowStyle = css`
    color: ${gray7};
    padding: 0;
    height: auto;

    &.ant-btn.ant-btn-icon-only {
        width: 1rem;
    }

    &:hover {
        color: ${gray8};
        background-color: transparent;
    }
`;

interface DrawerLayoutProps {
    closeHandler: () => void;
    title: string;
    id?: number;
    handleEdit?: (id: number) => void;
    handleBack?: () => void;
    handleDeleteModal?: ({ id, name }: { id: number; name: string }) => void;
}

const DrawerLayout: FC<DrawerLayoutProps> = ({
    children,
    closeHandler,
    title,
    id,
    handleEdit,
    handleBack,
    handleDeleteModal,
}) => {
    return (
        <Flex vertical>
            <Flex className="ant-drawer-header" style={{ paddingRight: "1rem" }}>
                <Flex flex={1} vertical gap="middle">
                    <Flex flex={1} justify="space-between" align="baseline">
                        <Flex align="center" gap="small">
                            {handleBack && (
                                <Button
                                    data-sdet="brand-safety-deatails-drawer-back-button"
                                    type="text"
                                    onClick={handleBack}
                                    className={backArrowStyle}
                                    icon={<ArrowLeftOutlined style={{ fontSize: "14px" }} />}
                                />
                            )}
                            <Typography.Title level={4} style={{ marginBottom: 0, marginTop: 0 }}>
                                {title}
                            </Typography.Title>
                        </Flex>
                        <DrawerCloseButton onClose={closeHandler} />
                    </Flex>
                    {id && (
                        <BrandSafetyDetailsDrawerActions
                            id={id as number}
                            name={title}
                            handleClose={closeHandler}
                            handleEdit={handleEdit as () => void}
                            handleDeleteModal={handleDeleteModal}
                        />
                    )}
                </Flex>
            </Flex>
            {children}
        </Flex>
    );
};

export const BrandSafetyDetailsDrawer: FC = () => {
    const { id, sectionKey, handleBack, handleClose, handleEdit } = useBrandSafetyDetailsDrawer();
    const { data: brandSafety, isFetching } = useGetAdvancedBrandSafetyByIdQuery(Number(id), { skip: !id });
    const [deleteTrigger, { isLoading: isDeleting }] = useDeleteAdvancedBrandSafetyMutation();
    const { handleOpen, ...restModalProps } = useBrandSafetyDeleteModal(deleteTrigger, handleClose);

    return (
        <ResizableDrawer
            open={true}
            placement="right"
            width="40%"
            closable={false}
            onClose={handleClose}
            className={css`
                .ant-drawer-body {
                    padding: 0;
                }
            `}
        >
            {isFetching ? (
                <Row justify="center" align="middle" style={{ height: "100%" }}>
                    <Col>
                        <Spin />
                    </Col>
                </Row>
            ) : (
                <Switch>
                    <Route exact path={ROUTES.SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_DETAILS}>
                        <DrawerLayout
                            title={brandSafety?.name as string}
                            id={brandSafety?.id}
                            closeHandler={handleClose}
                            handleEdit={handleEdit}
                            handleDeleteModal={handleOpen}
                        >
                            <BrandSafetyDetails brandSafety={brandSafety} />
                            <BrandSafetyDeleteModal isDeleting={isDeleting} {...restModalProps} />
                        </DrawerLayout>
                    </Route>
                    <Route exact path={ROUTES.SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_DEFINITION}>
                        <DrawerLayout
                            title="Brand Safety Definition"
                            closeHandler={handleClose}
                            handleBack={handleBack}
                        >
                            <BrandSafetyDefinition sectionKey={sectionKey as BrandSafetySectionKey} />
                        </DrawerLayout>
                    </Route>
                </Switch>
            )}
        </ResizableDrawer>
    );
};
