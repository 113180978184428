import { FC } from "react";
import { AdSource } from "@app/core/services";
import { Col, Row, Tag } from "antd";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { DetailsItem } from "../DetailsItem";

interface Props {
    adSource: AdSource;
}

export const SeatAdSourcesPbsBidderConfigurationsDetails: FC<Props> = ({ adSource }) => {
    return (
        <Row>
            <Col sm={12}>
                <DetailsItem label="Configs">
                    {adSource[AD_SOURCE_FIELDS.PBS_BIDDER_CONFIGS.name].map(({ name, id }) => (
                        <Tag key={id}>{name}</Tag>
                    ))}
                </DetailsItem>
            </Col>
        </Row>
    );
};
