import { FC } from "react";
import { Form, Radio } from "antd";
import { INVENTORY_FORM_FIELDS, SEAT_FRAUD_DETECTION_OPTIONS } from "@app/features/inventory/InventorySeat/constants";

export const FraudDetection: FC = () => {
    return (
        <Form.Item
            label={INVENTORY_FORM_FIELDS.FRAUD_DETECTION.label}
            name={INVENTORY_FORM_FIELDS.FRAUD_DETECTION.name}
        >
            <Radio.Group options={SEAT_FRAUD_DETECTION_OPTIONS} />
        </Form.Item>
    );
};
