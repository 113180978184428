import { LabeledValue } from "antd/lib/select";
import { FC } from "react";
import { useHistoricalChartDateRangeFilter } from "./useHistoricalDateRangeFilter";
import { Form, Select } from "antd";
import { TagRender } from "@app/core/components/TagRender";
import { HISTORICAL_CHART_DATE_RANGE_OPTIONS } from "../../constants";

export const HistoricalChartDateRangeFilter: FC = () => {
    const { handleChange, dateRange } = useHistoricalChartDateRangeFilter();

    return (
        <Form.Item
            data-sdet={"deal-chart-historical-date-range-filter"}
            label={"Time Frame"}
            style={{ marginBottom: 0 }}
        >
            <Select
                allowClear={false}
                onChange={(_, option) => handleChange(option as LabeledValue)}
                options={HISTORICAL_CHART_DATE_RANGE_OPTIONS}
                style={{ width: "100%" }}
                value={dateRange}
                tagRender={TagRender}
            />
        </Form.Item>
    );
};
