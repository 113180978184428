import { adSourceStatsApi, AD_SOURCE_STATS_DEALS } from "./adSourceStats";
import { toQueryString } from "@app/core/services/console/utils";

export interface StaticAdStats {
    dealStatMap?: {
        stats: {
            [key: string]: {
                adStatsByNetwork?: {
                    stats: {
                        [key: string]: {
                            advertiserDomains: {
                                [key: string]: number;
                            };
                            cost: number;
                            filledRequests: number;
                            impressions: number;
                            ntime: number;
                            otime: number;
                            rev: number;
                        };
                    };
                };
                bidderStatsByNetwork?: {
                    stats: {
                        [key: string]: {
                            auctionCount: number;
                            bidResults: {
                                [key: number]: number;
                            };
                            ntime: number;
                            otime: number;
                            sumBidPriceMicros: number;
                            sumResponseTimeMillisAcrossResultTypes: {
                                [key: number]: number;
                            };
                        };
                    };
                };
                ntime: number;
                otime: number;
            };
        };
    };
    ntime: number;
    otime: number;
}

export interface StaticAdStatsParams {
    adSourceId: number | null;
}

export const staticAdStatsApi = adSourceStatsApi.injectEndpoints({
    endpoints: (builder) => ({
        getStaticAdStats: builder.query<StaticAdStats, StaticAdStatsParams>({
            query: ({ adSourceId, ...rest }: StaticAdStatsParams) => {
                const params: Omit<StaticAdStatsParams, "adSourceId"> = {
                    ...rest,
                };
                return `/deals/adsources/${adSourceId}/today?${toQueryString(params)}`;
            },
            providesTags: () => [{ type: AD_SOURCE_STATS_DEALS }],
        }),
    }),
});

export const { useGetStaticAdStatsQuery } = staticAdStatsApi;
