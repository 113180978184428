import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { merge } from "lodash";

type ForceUpdateOption = "initialization" | "objectKeyChange" | "objectValueInitialization";
type ForceUpdateSettings = Record<ForceUpdateOption, boolean>;
type ForceUpdateCheck = (options: ThrottleOptions, last: unknown, current: unknown) => boolean;

interface ThrottleOptions {
    intervalSeconds: number;
    forceUpdatesOn: ForceUpdateSettings;
    forceUpdateDependencies: unknown[] | undefined;
}

interface PartialThrottleOptions extends Omit<Partial<ThrottleOptions>, "forceUpdatesOn"> {
    forceUpdatesOn?: Partial<ForceUpdateSettings>;
    forceUpdateDependencies?: unknown[];
}

export const DefaultThrottleOptions: ThrottleOptions = {
    intervalSeconds: 30,
    forceUpdatesOn: {
        initialization: true,
        objectKeyChange: true,
        objectValueInitialization: true,
    },
    forceUpdateDependencies: undefined,
} as const;

const generateHashCode = (s: string | undefined): number | undefined => {
    if (s === undefined) {
        return undefined;
    }
    let hash = 0;
    for (let i = 0; i < s.length; i++) {
        const char = s.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
};

const areInitializedObjects = (...values: unknown[]): boolean =>
    values.length > 0 && values.every((value) => value && typeof value === "object");

const checkIsInitialization: ForceUpdateCheck = (options, last, current) =>
    options.forceUpdatesOn.initialization &&
    (last === undefined || last === null) &&
    current !== undefined &&
    current !== null;

const checkIsObjectKeyChange: ForceUpdateCheck = (options, last, current) => {
    if (!options.forceUpdatesOn.objectKeyChange || !areInitializedObjects(last, current)) {
        return false;
    }

    const lastKeys = Object.keys(last as object);
    const currentKeys = Object.keys(current as object);
    if (lastKeys.length !== currentKeys.length) {
        return true;
    }

    const lastKeySet = new Set(lastKeys);
    return currentKeys.some((currentKey) => !lastKeySet.has(currentKey));
};

const checkIsObjectValueInitialization: ForceUpdateCheck = (options, last, current) => {
    if (!options.forceUpdatesOn.objectValueInitialization || !areInitializedObjects(last, current)) {
        return false;
    }

    return Object.keys(current as object).some((currentKey) =>
        checkIsInitialization(options, (last as object)[currentKey], (current as object)[currentKey])
    );
};

const checkIsForceUpdateDependencyChange: ForceUpdateCheck = (options, last, current) =>
    Boolean(options.forceUpdateDependencies?.length) && last !== current;

export const useThrottle = <T>(value: T, options: PartialThrottleOptions = DefaultThrottleOptions): T => {
    const throttleOptions: ThrottleOptions = merge({}, DefaultThrottleOptions, options);
    const { intervalSeconds } = throttleOptions;
    const forceUpdateDependenciesHash = useMemo(
        () => generateHashCode(JSON.stringify(throttleOptions.forceUpdateDependencies)),
        [throttleOptions.forceUpdateDependencies]
    );

    const valueRef = useRef<T>(value);
    const forceUpdateDependencyHashRef = useRef<number | undefined>(forceUpdateDependenciesHash);
    const intervalSecondsRef = useRef<number>(intervalSeconds);
    const throttleTimerRef = useRef<NodeJS.Timeout>();

    const [throttledValue, setThrottledValue] = useState<T>(value);

    const setThrottledValueWithInterval = useCallback(() => {
        clearInterval(throttleTimerRef.current);
        setThrottledValue(value);
        throttleTimerRef.current = setInterval(() => setThrottledValue(valueRef.current), intervalSeconds * 1000);
    }, [value, intervalSeconds]);

    const isThrottleTimerInitialized = Boolean(throttleTimerRef.current);
    const isIntervalChange = intervalSecondsRef.current !== intervalSeconds;
    const isForcedUpdate = useMemo(
        () =>
            [
                checkIsInitialization(throttleOptions, valueRef.current, value),
                checkIsObjectKeyChange(throttleOptions, valueRef.current, value),
                checkIsObjectValueInitialization(throttleOptions, valueRef.current, value),
                checkIsForceUpdateDependencyChange(
                    throttleOptions,
                    forceUpdateDependencyHashRef.current,
                    forceUpdateDependenciesHash
                ),
            ].some(Boolean),
        [value, throttleOptions, forceUpdateDependenciesHash]
    );

    if (!isThrottleTimerInitialized || isIntervalChange || isForcedUpdate) {
        setThrottledValueWithInterval();
    }

    useEffect(() => () => clearInterval(throttleTimerRef.current), []);

    valueRef.current = value;
    forceUpdateDependencyHashRef.current = forceUpdateDependenciesHash;
    intervalSecondsRef.current = intervalSeconds;

    return throttledValue;
};
