import { FC } from "react";
import { Button, Space } from "antd";
import { SeatAdSourcesCreativeReviewActionButton } from "./SeatAdSourcesCreativeReviewActionButton";
import { SeatAdSourcesEditActionButton } from "./SeatAdSourcesEditActionButton";
import { SeatAdSourcesMoreActionButton } from "./SeatAdSourcesMoreActionButton";
import { SeatAdSourcesPerformanceActionButton } from "./SeatAdSourcesPerformanceActionButton";
import { SeatAdSourcesActionButton as ActionButton, SeatAdSourcesMoreMenuItem as MoreMenuItem } from "./types";

interface Props {
    adSourceId: number;
    adSourceTypeId: number;
    actionButtons?: ActionButton[];
    moreMenuItems?: MoreMenuItem[];
}

export const SeatAdSourcesActionButtonBar: FC<Props> = ({
    adSourceId,
    adSourceTypeId,
    actionButtons,
    moreMenuItems,
}) => {
    const displayMoreMenu = moreMenuItems && moreMenuItems.length > 0;
    const AllActionButtons = (key: number | string) => ({
        [ActionButton.Edit]: <SeatAdSourcesEditActionButton adSourceId={adSourceId} key={key} />,
        [ActionButton.Performance]: <SeatAdSourcesPerformanceActionButton adSourceId={adSourceId} key={key} />,
        [ActionButton.CreativeReview]: <SeatAdSourcesCreativeReviewActionButton adSourceId={adSourceId} key={key} />,
    });

    const filteredActionButtons = actionButtons?.map((actionButton, i) => AllActionButtons(i)[actionButton]);

    return (
        <Space data-sdet="seat-ad-sources-action-button-bar" onClick={(event) => event.stopPropagation()}>
            <Button.Group>
                {filteredActionButtons}
                {displayMoreMenu && (
                    <SeatAdSourcesMoreActionButton
                        adSourceId={adSourceId}
                        adSourceTypeId={adSourceTypeId}
                        menuItems={moreMenuItems}
                    />
                )}
            </Button.Group>
        </Space>
    );
};
