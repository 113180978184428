import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Targeting } from "@app/core/services";
import { Button, Space } from "antd";
import { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { TargetingDeleteModal } from "./TargetingDeleteModal";
import { useTargetingDeleteModal } from "./useTargetingDeleteModal";

interface Props {
    canEdit: boolean;
    hasDelete: boolean;
    targeting: Pick<Targeting, "id" | "name">;
}
export const TargetingDetailsActions: FC<Props> = ({ canEdit, hasDelete, targeting }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();
    const { handleOpen, ...restModalProps } = useTargetingDeleteModal(() =>
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TARGETING(seatId))
    );
    if (!canEdit && !hasDelete) {
        return null;
    }
    return (
        <Space>
            {canEdit && (
                <Button
                    data-sdet="edit-button"
                    type="primary"
                    onClick={() => {
                        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TARGETING_EDIT(seatId, targeting.id));
                    }}
                >
                    Edit
                </Button>
            )}
            {hasDelete && (
                <Button
                    danger
                    data-sdet="delete-button"
                    type="primary"
                    onClick={() =>
                        handleOpen({
                            id: targeting?.id,
                            name: targeting?.name as string,
                        })
                    }
                >
                    Delete
                </Button>
            )}
            <TargetingDeleteModal {...restModalProps} />
        </Space>
    );
};
