import { FC } from "react";
import { Space, TooltipProps } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { ClipBoardButton } from "@app/core/components";

interface Props {
    copyText: string;
    entity?: string;
    tooltipProps?: TooltipProps;
    sdet?: string;
}

export const CopyString: FC<Props> = ({ copyText, entity, tooltipProps, sdet }) => (
    <Space>
        {copyText}
        <ClipBoardButton
            entity={entity}
            tooltipProps={tooltipProps}
            copyText={copyText}
            type="link"
            sdet={sdet}
            icon={<CopyOutlined />}
        />
    </Space>
);
