import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";

const getValidLimitValue = (value: string | number | undefined, toFixed: number): number => {
    const number = Number(parseFloat(value as string).toFixed(toFixed));
    const isInvalidNumber = Number.isNaN(number);

    if (isInvalidNumber) return 0;

    return number;
};

interface UseLimitInput {
    displayValue: string | number | undefined;
    handleBlur: (e: ChangeEvent<HTMLInputElement>) => void;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onKeyPress: (e: KeyboardEvent<HTMLInputElement>) => void | boolean;
}

interface UseLimitInputProps {
    toFixed?: number;
    value?: string | number | undefined;
    onChange?: (value: string | number) => void;
}

export const useLimitInput = ({ toFixed = 2, onChange, value }: UseLimitInputProps): UseLimitInput => {
    const [displayValue, setDisplayValue] = useState<string | number | undefined>(value);

    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const { value } = e.target;
        setDisplayValue(value);
        onChange?.(value);
    };

    const handleBlur = (e: ChangeEvent<HTMLInputElement>): void => {
        const validValue: number = getValidLimitValue(e.target.value, toFixed);
        setDisplayValue(validValue);
        onChange?.(validValue);
    };

    useEffect((): void => {
        setDisplayValue(value);
    }, [value]);

    const onKeyPress = (event: KeyboardEvent<HTMLInputElement>): void | boolean =>
        !/[0-9.]/.test(event.key) && event.preventDefault();

    return {
        onKeyPress,
        handleBlur,
        handleChange,
        displayValue,
    };
};
