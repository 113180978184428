import { FC } from "react";
import { Form } from "antd";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { ReadonlyControl } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { AdSourcePayload } from "@app/core/services";
import {
    getPacingPeriodText,
    isPacingPeriodEligible,
} from "@app/features/seatAdSources/SeatAdSourcesForm/utils/pacingUtils";
import { usePacingFormData } from "../usePacingFormData";

const { label, name } = AD_SOURCE_FIELDS.PACING_PERIOD;

export const PacingPeriodSelect: FC = () => {
    const { adSourceTypeId, pacingTypeId } = usePacingFormData();

    if (!isPacingPeriodEligible(adSourceTypeId, pacingTypeId)) {
        return null;
    }

    return (
        <Form.Item name={name} label={label} {...VERTICAL_LAYOUT_FIX}>
            <ReadonlyControl<AdSourcePayload[typeof AD_SOURCE_FIELDS.PACING_TYPE.name]>
                getLabel={(type) => getPacingPeriodText(type?.id)}
            />
        </Form.Item>
    );
};
