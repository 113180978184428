import { useEffect, useRef, MutableRefObject, useState } from "react";
import { w3cwebsocket } from "websocket";
import { useSeatAuthContext } from "@app/core/auth";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { sendHeartbeatEvent, sendLoginEvent } from "@app/core/socket/events";
import { parseMessageData } from "@app/core/socket/utils";
import { sendStartHistoryStatsEvent, sendStartStatsEvent, sendStopStatsEvent } from "./events";
import { setAdStatsError, setAdStatsAdStatEvent, setAdStatsAdStatHistoryEvent } from "@app/features/adStats/reducer";
import { EventTypes, RawEventDataWithMessage } from "@app/core/socket/types";
import { AdStat, AdStatEventMessage, AdStatHistoryEventMessage, AdStatsById, EntityTypes } from "./types";
import { DEMAND_TYPE_EXTERNAL_IDS } from "../dashboard/DemandTypeFilter/constants";
import adStatDemoDataEvent from "./data/supplyseatstat.json";
import adStatHistoryDemoDataEvent from "./data/inventoryentitystathistory.json";
import { selectUserTimezone } from "@app/core/authClient/reducer";

interface UseAdStats {
    adStat: AdStat | AdStat[];
    adStatSubEntity: AdStatsById;
    adStatHistory: { [id: number]: AdStat[] };
    isLoading: boolean;
    error: Error | null;
    isAuthenticated: boolean;
    isConnected: boolean;
    setEntityType: (type: EntityTypes) => void;
    setSubEntityIds: (ids: string[]) => void;
}

const ADSTATS_WEBSOCKET = "wss://adstats.cast.telaria.com/cast/websocket/adstats";
const HEARTBEAT_INTERVAL = 1000 * 30;

// Console src/main/webapp/demo/cc/demo-generator.js
const DEMO_EVENT_INTERVAL = 2000;
const DEMO_SEAT_ID = "_CURRENT_SEAT_";
const DEMO_INVENTORY_ID = "_INVENTORY_ENTITY_";

interface RawData {
    message: string;
    type: string;
}

const adStatDemoDataRaw = (adStatDemoDataEvent as RawData).message;
const adStatHistoryDemoDataRaw = (adStatHistoryDemoDataEvent as RawData).message;

const adStatDemoDataById = JSON.parse(adStatDemoDataRaw) as { [DEMO_SEAT_ID]: AdStat };
const adStatHistoryDemoDataById = JSON.parse(adStatHistoryDemoDataRaw) as { [DEMO_INVENTORY_ID]: AdStat[] };

const adStatDemoData = adStatDemoDataById[DEMO_SEAT_ID];
const adStatHistoryDemoData = adStatHistoryDemoDataById[DEMO_INVENTORY_ID];

const isSocketOpen = (socket: MutableRefObject<w3cwebsocket | null>): socket is MutableRefObject<w3cwebsocket> => {
    return Boolean(socket.current && socket.current.readyState === socket.current.OPEN);
};
export const getRandomNumber = (max: number, min: number): number => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

const incrementDemoAdStats = (v: AdStat) => {
    return {
        ...v,
        requests: v.requests + getRandomNumber(10000, 5000),
        results: { ...v.results, "-20": v.results["-20"] + getRandomNumber(1000, 50) },
        statsByCurrency: {
            ...v.statsByCurrency,
            1: {
                ...v.statsByCurrency[1],
                filledRequests: v.statsByCurrency[1].filledRequests + getRandomNumber(1000, 50),
                impressions: v.statsByCurrency[1].impressions + getRandomNumber(10000, 50),
                rev: v.statsByCurrency[1].rev + getRandomNumber(1000000, 50000),
                cost: v.statsByCurrency[1].cost + getRandomNumber(1000000, 50000),
                rejections: v.statsByCurrency[1].rejections + getRandomNumber(10, 5),
            },
        },
    };
};

export const useAdStats = (InitialEntityType: EntityTypes): UseAdStats => {
    const { context, session, isDemoContext } = useSeatAuthContext();
    const dispatch = useAppDispatch();
    const [entityType, setEntityType] = useState(InitialEntityType);
    const [subEntityIds, setSubEntityIds] = useState([]);

    const socket = useRef<w3cwebsocket | null>(null);
    const adStat = useAppSelector((state) => state.adStats.events.adStatById);
    const adStatHistory = useAppSelector((state) => state.adStats.events.adStatHistoryById);
    const error = useAppSelector((state) => state.adStats.error);
    const [isConnected, setIsConnected] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const filters = useAppSelector((state) => state.dashboard.filters);
    const timezone = useAppSelector(selectUserTimezone);

    useEffect(() => {
        if (isDemoContext) {
            return;
        }
        socket.current = new w3cwebsocket(ADSTATS_WEBSOCKET);
        socket.current.onopen = () => {
            setIsConnected(true);
        };

        socket.current.onclose = () => {
            setIsAuthenticated(false);
            setIsConnected(false);
        };

        socket.current.onerror = (error) => {
            dispatch(setAdStatsError(error));
        };

        socket.current.onmessage = (message) => {
            const eventData = parseMessageData(message);

            switch (eventData.type) {
                case EventTypes.AuthResult: {
                    const rawAuthResultMessage = eventData as RawEventDataWithMessage;
                    const isAuthResultAuthenticated = JSON.parse(rawAuthResultMessage.message) as boolean;
                    setIsAuthenticated(isAuthResultAuthenticated);
                    return;
                }
                case EventTypes.AdStat: {
                    const rawAddStatMessage = eventData as RawEventDataWithMessage;
                    const adStatEventMessage = JSON.parse(rawAddStatMessage.message) as AdStatEventMessage;
                    dispatch(setAdStatsAdStatEvent(adStatEventMessage));
                    return;
                }
                case EventTypes.AdStatHistory: {
                    const rawAddStatMessage = eventData as RawEventDataWithMessage;
                    const adStatHistoryEventMessage = JSON.parse(
                        rawAddStatMessage.message
                    ) as AdStatHistoryEventMessage;
                    dispatch(setAdStatsAdStatHistoryEvent(adStatHistoryEventMessage));
                    return;
                }
            }
        };

        return () => {
            if (isSocketOpen(socket)) {
                sendStopStatsEvent(socket.current);
                socket.current.close();
            }
        };
    }, [dispatch, context, isDemoContext]);

    // Login after socket connected and user is authenticated
    useEffect(() => {
        if (isSocketOpen(socket) && isConnected && session) {
            sendLoginEvent(socket.current, session.sessionCode, session.user.emailAddress);
        }
    }, [socket, isConnected, session, context]);

    // Start stats after Socket Connected, user is authenticated, and context is selected
    // Stop stats after unmount
    useEffect(() => {
        if (isSocketOpen(socket) && isConnected && isAuthenticated && context && timezone) {
            sendStartHistoryStatsEvent(socket.current, {
                entityType,
                entityIds: [context.id],
                oneAndDone: true,
                publisherFilterIds: filters.publisher.map((publisherOption) => Number(publisherOption.value)),
                adSourceTypeFilterIds: filters.demandType
                    .map((demandTypeOption) => DEMAND_TYPE_EXTERNAL_IDS[demandTypeOption.value])
                    .flat(),
                subEntityIds,
                timezone,
            });
            sendStartStatsEvent(socket.current, {
                entityType,
                entityIds: [context.id],
                oneAndDone: null,
                publisherFilterIds: filters.publisher.map((publisherOption) => Number(publisherOption.value)),
                adSourceTypeFilterIds: filters.demandType
                    .map((demandTypeOption) => DEMAND_TYPE_EXTERNAL_IDS[demandTypeOption.value])
                    .flat(),
                subEntityIds,
                timezone,
            });
        }

        return () => {
            if (isSocketOpen(socket)) {
                sendStopStatsEvent(socket.current);
            }
        };
    }, [socket, isConnected, isAuthenticated, context, filters, entityType, subEntityIds, timezone]);

    // Send heartbeat every 30 seconds
    useEffect(() => {
        if (isSocketOpen(socket) && isConnected && isAuthenticated) {
            const interval = setInterval(() => {
                if (isSocketOpen(socket)) {
                    sendHeartbeatEvent(socket.current);
                }
            }, HEARTBEAT_INTERVAL);

            return () => {
                clearInterval(interval);
            };
        }
    }, [socket, isConnected, isAuthenticated, context]);

    useEffect(() => {
        if (!context || !isDemoContext || !session) {
            return;
        }

        dispatch(setAdStatsAdStatHistoryEvent({ [context.id]: adStatHistoryDemoData }));

        // Send demo adstat data every 1 second
        const interval = setInterval(() => {
            if (session && context && isDemoContext) {
                const initialEvent = adStatDemoData;
                const lastEvent = adStat[context.id] ?? null;
                const nextEvent = lastEvent ? incrementDemoAdStats(lastEvent) : initialEvent;

                dispatch(setAdStatsAdStatEvent({ [context.id]: nextEvent }));
            }
        }, DEMO_EVENT_INTERVAL);

        return () => {
            clearInterval(interval);
        };
    }, [dispatch, session, context, isDemoContext, adStat]);

    return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        adStat: filters.publisher.length ? Object.values(adStat) : adStat[context.id],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        adStatSubEntity: adStat,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        adStatHistory,
        isLoading: !adStat || !Object.keys(adStat).length,
        error,
        isAuthenticated,
        isConnected,
        setEntityType,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setSubEntityIds,
    };
};
