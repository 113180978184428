import { NONE } from "@app/core/components/constants";
import { Typography } from "antd";
import { FC } from "react";

interface Props {
    list: string[];
    max?: number;
    onClick: () => void;
}

export const AND_MORE_SDET = "add-more-link";

export const ListAndMore: FC<Props> = ({ list, max = 10, onClick }) => {
    const values = list.slice(0, max);

    return list.length ? (
        <Typography.Text>
            {values.join(", ")}
            {list.length > max && (
                <Typography.Link data-sdet={AND_MORE_SDET} onClick={onClick}>
                    {" & "}
                    {list.length - max} more
                </Typography.Link>
            )}
        </Typography.Text>
    ) : (
        <Typography.Text>{NONE}</Typography.Text>
    );
};
