import { PMP_ANY_VALUE } from "@app/features/targeting/constants";
import { Targeting, TargetingCreateIncludeExclude, TargetingIncludeExclude } from "../..";

export type HasTargetingParam = TargetingIncludeExclude | TargetingCreateIncludeExclude | null | undefined;

export const hasTargeting = (targeting: HasTargetingParam): boolean =>
    !!targeting &&
    ((!Array.isArray(targeting.minDurationTarget) && !!targeting.minDurationTarget) ||
        (!Array.isArray(targeting.maxDurationTarget) && !!targeting.maxDurationTarget) ||
        Object.values(targeting).some((target) => Array.isArray(target) && target.length > 0));

export const hasPmpDealIdTargeting = (targeting: HasTargetingParam): boolean =>
    !!targeting &&
    "pmpDealIdTargets" in targeting &&
    Array.isArray(targeting.pmpDealIdTargets) &&
    targeting.pmpDealIdTargets.length > 0;

export const shouldInitializePmpDealIdTargeting = (include: HasTargetingParam, exclude: HasTargetingParam): boolean =>
    (hasTargeting(include) || hasTargeting(exclude)) &&
    !(hasPmpDealIdTargeting(include) || hasPmpDealIdTargeting(exclude));

export const transformTargetingFromApi = (targeting: Targeting): Targeting => {
    const pmpDealIdTargets = targeting.exclude?.pmpDealIdTargets;
    const isExcludeAllDeals =
        Array.isArray(pmpDealIdTargets) && pmpDealIdTargets.length === 1 && pmpDealIdTargets[0] === PMP_ANY_VALUE;
    if (isExcludeAllDeals && targeting.exclude) {
        return {
            ...targeting,
            exclude: {
                ...targeting.exclude,
                // if set to exclude all deals, set to empty array as this is the default option
                pmpDealIdTargets: [],
            },
        };
    }
    return targeting;
};

export const transformTargetingArrayFromApi = (targeting: Targeting[]): Targeting[] =>
    targeting.map((target) => transformTargetingFromApi(target));
