import { css } from "@emotion/css";
import { InfoCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Flex, Space, Typography } from "antd";
import { FC } from "react";
import { useInventoryDetailsDrawer } from "../useInventoryDetailsDrawer";
import { useHistory } from "react-router-dom";
import { gray7, gray8 } from "@rubicon/antd-components";
import { DrawerCloseButton, StyledButtonLink } from "@app/core/components";

export interface InventoryDrawerLayoutProps {
    title: string;
    breadcrumbs?: string;
    definitionTitle?: string;
    handleDefintion?: () => void;
    handleCloseDrawer?: () => void;
}
export const DetailsDrawerLayout: FC<InventoryDrawerLayoutProps> = ({
    title,
    breadcrumbs,
    definitionTitle,
    handleDefintion,
    handleCloseDrawer,
}) => {
    const { closeDetailsDrawer } = useInventoryDetailsDrawer();
    const history = useHistory();
    return (
        <Flex
            data-sdet="inventory-details-drawer-header"
            className="ant-drawer-header"
            justify="space-between"
            flex={1}
            gap={24}
        >
            <Flex flex={1}>
                <Flex align="center" flex={1}>
                    {breadcrumbs && (
                        <Button
                            data-sdet="seat-ad-sources-details-drawer-nested-title-back-button"
                            type="text"
                            onClick={() => history.goBack()}
                            className={css`
                                color: ${gray7};
                                padding-top: 0;
                                padding-bottom: 0;
                                padding-left: 0;
                                height: auto;

                                &:hover {
                                    color: ${gray8};
                                    background-color: transparent;
                                }
                            `}
                        >
                            <ArrowLeftOutlined
                                className={css`
                                    svg {
                                        width: 1em;
                                        height: 1em;
                                    }
                                `}
                            />
                        </Button>
                    )}
                    <Space size={4}>
                        {/* TODO change it to ant breadcrumbs component*/}
                        {breadcrumbs && (
                            <>
                                <Typography.Title style={{ margin: 0, color: gray8 }} level={5}>
                                    {breadcrumbs}
                                </Typography.Title>
                                <Typography.Title style={{ margin: 0 }} level={5}>
                                    /
                                </Typography.Title>
                            </>
                        )}
                        <Typography.Title style={{ margin: 0 }} level={5}>
                            {title}
                        </Typography.Title>
                    </Space>
                </Flex>
                {definitionTitle && (
                    <StyledButtonLink type="link" onClick={handleDefintion}>
                        <InfoCircleOutlined />
                        {definitionTitle}
                    </StyledButtonLink>
                )}
            </Flex>
            <DrawerCloseButton
                onClose={() => {
                    if (handleCloseDrawer) {
                        handleCloseDrawer?.();
                        return;
                    }
                    closeDetailsDrawer();
                }}
            />
        </Flex>
    );
};
