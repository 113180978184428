import { FC } from "react";
import { Row, Typography } from "antd";
import { css } from "@emotion/css";
import { gray8 } from "@rubicon/antd-components";

export const NoDataAvailable: FC = () => (
    <Row
        data-sdet="no-data-available"
        align="middle"
        className={css`
            padding: 2rem;
        `}
    >
        <Typography.Text
            className={css`
                color: ${gray8};
                font-size: 1rem;
                font-weight: normal;
                text-align: center;
                width: 100%;
            `}
        >
            No available data to display at this time
        </Typography.Text>
    </Row>
);
