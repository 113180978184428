import { Form, Input } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";

import { useDealPublisherNameField } from "@app/features/deals/DealForm/DealDemandFormSections/DealTermsSection/Fields/DealPublisherNameField/useDealPublisherNameField";

export const DealPublisherNameField = () => {
    const { value, handleChange, handleBlur, placeholder } = useDealPublisherNameField();

    return (
        <Form.Item
            data-sdet="deal-publisher-name-field"
            label="Publisher Name"
            name={CREATE_DEAL_FORM_ITEMS_NAME.PUBLISHER_NAME}
        >
            <Input value={value} onChange={handleChange} onBlur={handleBlur} placeholder={placeholder} />
        </Form.Item>
    );
};
