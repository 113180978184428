import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import { FC } from "react";
import { CUSTOM_PIXELS_DEFINITION_FIELDS } from "../constants";

export const CustomPixelDefinitions: FC = () => (
    <HelpKeysDrawer
        buttonName="Custom Pixels"
        panelLabel="Custom Pixel Definitions"
        definitionsFields={CUSTOM_PIXELS_DEFINITION_FIELDS}
    />
);
