import { useAppDispatch, useAppSelector } from "@app/core/store";
import {
    selectSecondaryDrawerHeader,
    selectSecondaryDrawerView,
    selectRequestedAdSourceId,
    switchToNestedView,
    selectIsAdSourceDetailsDrawerReadonly,
} from "./reducer";
import { SeatAdSourcesDetailsDrawerSecondaryHeader as SecondaryHeaderView } from "./SeatAdSourcesDetailsDrawerContent/SeatAdSourcesDetailsDrawerHeader";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "./types";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { convertSecondaryViewToURL } from "./normalizeSecondaryViewFromURL";
import { AD_SOURCE_DRAWER_LOCATION } from "../constants";

interface UseSeatAdSourcesDetailsDrawerContent {
    requestedAdSourceId: number;
    secondaryDrawerHeader: SecondaryHeaderView;
    secondaryDetailsView: SecondaryDetailsView;
    isReadonly: boolean;
    switchToNestedDetailsView: (secondaryDrawerView: SecondaryDetailsView) => void;
    switchToDefaultDetailsView: () => void;
}

export const useSeatAdSourcesDetailsDrawerContent = (): UseSeatAdSourcesDetailsDrawerContent => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation();
    const { adSourceId, seatId, dealId, dealType } = useParams<{
        seatId: string;
        adSourceId: string;
        dealId: string;
        dealType: "demand" | "inventory" | "legacy";
    }>();

    const requestedAdSourceId = useAppSelector(selectRequestedAdSourceId);
    const secondaryDrawerHeader = useAppSelector(selectSecondaryDrawerHeader);
    const secondaryDetailsView = useAppSelector(selectSecondaryDrawerView);
    const isReadonly = useAppSelector(selectIsAdSourceDetailsDrawerReadonly);

    const switchToNestedDetailsView = (secondaryDetails: SecondaryDetailsView) => {
        dispatch(switchToNestedView(secondaryDetails));
        if (
            location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.DEALS) &&
            location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.COPY)
        ) {
            history.push(
                ROUTE_FORMATTERS.SEAT_DEAL_COPY_AD_SOURCES_DRAWER_SECONDARY_VIEW(
                    seatId,
                    dealId,
                    adSourceId,
                    convertSecondaryViewToURL(secondaryDetails)
                )
            );
            return;
        }
        if (location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.DEALS_CREATE_DEMAND)) {
            history.push(
                ROUTE_FORMATTERS.SEAT_DEAL_CREATE_TYPE_AD_SOURCES_DRAWER_SECONDARY_VIEW(
                    seatId,
                    adSourceId,
                    dealType,
                    convertSecondaryViewToURL(secondaryDetails)
                )
            );
            return;
        }
        if (location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.DEALS_HEALTH)) {
            history.push(
                ROUTE_FORMATTERS.SEAT_DEALS_HEALTH_AD_SOURCES_DRAWER_SECONDARY_VIEW(
                    seatId,
                    adSourceId,
                    convertSecondaryViewToURL(secondaryDetails)
                )
            );
            return;
        }
        if (location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.PACING)) {
            history.push(
                ROUTE_FORMATTERS.SEAT_AD_SOURCES_PACING_DETAILS_SECONDARY_VIEW(
                    seatId,
                    adSourceId,
                    convertSecondaryViewToURL(secondaryDetails)
                )
            );
            return;
        }
        if (location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.DEALS)) {
            if (location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.EDIT)) {
                history.push(
                    ROUTE_FORMATTERS.SEAT_DEAL_EDIT_AD_SOURCES_DRAWER_SECONDARY_VIEW(
                        seatId,
                        dealId,
                        adSourceId,
                        convertSecondaryViewToURL(secondaryDetails)
                    )
                );
                return;
            }
            if (dealId) {
                history.push(
                    ROUTE_FORMATTERS.SEAT_DEAL_DETAILS_AD_SOURCES_DRAWER_SECONDARY_VIEW(
                        seatId,
                        dealId,
                        adSourceId,
                        convertSecondaryViewToURL(secondaryDetails)
                    )
                );
                return;
            }
            history.push(
                ROUTE_FORMATTERS.SEAT_DEALS_AD_SOURCES_DRAWER_SECONDARY_VIEW(
                    seatId,
                    adSourceId,
                    convertSecondaryViewToURL(secondaryDetails)
                )
            );
            return;
        }
        history.push(
            ROUTE_FORMATTERS.SEAT_AD_SOURCES_DETAILS_DRAWER_SECONDARY_VIEW(
                seatId,
                adSourceId,
                convertSecondaryViewToURL(secondaryDetails)
            )
        );
    };

    const switchToDefaultDetailsView = () => {
        history.goBack();
    };

    return {
        requestedAdSourceId,
        secondaryDrawerHeader,
        secondaryDetailsView,
        isReadonly,
        switchToNestedDetailsView,
        switchToDefaultDetailsView,
    };
};
