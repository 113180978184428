import { Button, Divider } from "antd";
import { FC, ReactNode } from "react";

interface Props {
    menu: ReactNode;
    onClick: () => void;
    areAllOptionsSelected: boolean;
}

export const SelectAllDropdownRender: FC<Props> = ({ menu, onClick, areAllOptionsSelected }) => (
    <>
        {menu}
        <Divider style={{ margin: "4px 0" }} />
        <Button style={{ textAlign: "left" }} block size="small" type="link" onClick={onClick}>
            {areAllOptionsSelected ? "Deselect" : "Select"} All
        </Button>
    </>
);
