import { Form } from "antd";
import { AD_SOURCE_FIELDS, AdSourceTypes, AD_SOURCE_TYPES_NEXT_UI_NAMES } from "@app/features/seatAdSources/constants";
import { SeatAdSourcesType } from "@app/core/services";

const { useWatch } = Form;
export const useIsFIeldVisible = () => {
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);

    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSourceType?.name] ?? adSourceType?.name;
    const isShareOfVoiceEnabled = useWatch(AD_SOURCE_FIELDS.SHARE_OF_VOICE.name);
    if (adSourceTypeName === AdSourceTypes.CONDITIONAL_PROGRAMATIC_GUARANTEED) {
        return true;
    }
    if (
        adSourceTypeName !== AdSourceTypes.PROGRAMATIC_GUARANTEED &&
        adSourceTypeName !== AdSourceTypes.SERVER_SIDE_TAG_GUARANTEED &&
        adSourceTypeName !== AdSourceTypes.CLIENT_SIDE_TAG_GUARANTEED
    ) {
        return false;
    }
    return !isShareOfVoiceEnabled;
};
