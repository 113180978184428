import { FC } from "react";
import { ErrorPage } from "@rubicon/antd-components";

const NotFound: FC = () => {
    return (
        <div style={{ height: "100vh" }}>
            <ErrorPage code={404} />
        </div>
    );
};

export default NotFound;
