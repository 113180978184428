import { SectionTitle } from "@app/core/components";
import { HelpKeysButton } from "@app/core/components/HelpKeysDrawer";
import { Targeting, TargetingReferences } from "@app/core/services";
import { css } from "@emotion/css";
import { Card, Collapse, Form } from "antd";
import { FC } from "react";
import { TargetingReferencesTable } from "../TargetingReferencesTable";
import { TARGETING_FORM_ITEMS, TARGETING_SECTIONS_NAME } from "./constants";
import { ReadOnlyControlsTargeting } from "@app/features/targeting/ReadOnlyTargeting/ReadOnlyControlsTargeting";

interface Props {
    targeting?: Targeting;
    references?: TargetingReferences[];
    handleDefinition: (type: string) => void;
}

export const TargetingFormReadOnly: FC<Props> = ({ targeting, references, handleDefinition }) => {
    return (
        <Form layout="vertical">
            <Collapse
                defaultActiveKey={["1", "2", "3"]}
                items={[
                    {
                        key: "1",
                        collapsible: "header",
                        label: <SectionTitle title={TARGETING_SECTIONS_NAME.GENERAL} />,
                        children: (
                            <Card
                                type="inner"
                                bordered={false}
                                className={css`
                                    &.ant-card {
                                        box-shadow: none;
                                    }
                                `}
                                styles={{ body: { paddingTop: 0, paddingBottom: 0 } }}
                            >
                                <Form.Item label={TARGETING_FORM_ITEMS[TARGETING_SECTIONS_NAME.GENERAL].PARENT.label}>
                                    {targeting?.seat?.name}
                                </Form.Item>
                                <Form.Item label={TARGETING_FORM_ITEMS[TARGETING_SECTIONS_NAME.GENERAL].NAME.label}>
                                    {targeting?.name}
                                </Form.Item>
                            </Card>
                        ),
                        extra: (
                            <HelpKeysButton
                                onClick={() => handleDefinition(TARGETING_SECTIONS_NAME.GENERAL)}
                                buttonName={TARGETING_SECTIONS_NAME.GENERAL}
                            />
                        ),
                    },
                    {
                        key: "2",
                        label: <SectionTitle title={TARGETING_SECTIONS_NAME.TARGETING} />,
                        collapsible: "header",
                        children: (
                            <Card
                                type="inner"
                                bordered={false}
                                className={css`
                                    &.ant-card {
                                        box-shadow: none;
                                    }
                                `}
                                styles={{ body: { paddingTop: 0, paddingBottom: 0 } }}
                            >
                                <ReadOnlyControlsTargeting targeting={targeting} />
                            </Card>
                        ),
                        extra: (
                            <HelpKeysButton
                                onClick={() => handleDefinition(TARGETING_SECTIONS_NAME.TARGETING)}
                                buttonName={TARGETING_SECTIONS_NAME.TARGETING}
                            />
                        ),
                    },
                    {
                        key: "3",
                        label: <SectionTitle title={TARGETING_SECTIONS_NAME.USED_IN} />,
                        collapsible: "header",
                        children: <TargetingReferencesTable references={references} />,
                    },
                ]}
            />
        </Form>
    );
};
