import { FC } from "react";
import { useAdSourceStartDateField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceDeliveryDetailsSection/Fields/AdSourceStartDateField/useAdSourceStartDateField";
import { ReadOnlyField } from "@app/core/components/Fields/ReadOnlyField";

export const AdSourceStartDateField: FC = () => {
    const { value } = useAdSourceStartDateField();
    return (
        <ReadOnlyField
            dataSdet="ad-source-start-date-field"
            label="Start Date"
            required={true}
            message="Start date is required"
        >
            {value}
        </ReadOnlyField>
    );
};
