import { consoleApi, VIDEO_ID_MICROS_TAG } from "./console";

interface VideoIdMacro {
    creationDate: string;
    updateDate: string;
    id: number;
    macro: string;
    usedInPixels: boolean;
    usedInTags: boolean;
    usedInCreatives: boolean;
    usedInDirectLines: boolean;
    synthesizeVideoIdMacro: boolean;
    description: string;
    sampleValue: string;
    mUsedInTags: boolean;
    entityType: "AdServerMacro";
}

export const adServerMacrosApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getVideoIdMacros: builder.query<VideoIdMacro[], void>({
            query: () => "adServerMacros/videoIdMacros",
            providesTags: [VIDEO_ID_MICROS_TAG],
        }),
    }),
});

export const { useGetVideoIdMacrosQuery } = adServerMacrosApi;
