import { MarketplaceInfo } from "@app/core/services";
import { ReadonlyControl, SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { Col, Form } from "antd";
import { FC } from "react";
import { useStatusSelect } from "./useStatusSelect";

export const StatusSelect: FC = () => {
    const { dealStatuses, isLoading, isReadonly, isVisible, onChange } = useStatusSelect();
    if (!isVisible) {
        return null;
    }
    return (
        <Col sm={12}>
            <Form.Item data-sdet="deal-status-field" label="Status" name={["dealUnderEdit", "status"]}>
                {isReadonly ? (
                    <ReadonlyControl<MarketplaceInfo["deal"]["status"]> getLabel={(status) => status.name} />
                ) : (
                    <SelectWithValue
                        options={dealStatuses}
                        placeholder="Select status"
                        loading={isLoading}
                        fieldAsLabel={["name"]}
                        fieldAsValue={["id"]}
                        onChange={onChange}
                    />
                )}
            </Form.Item>
        </Col>
    );
};
