import { Loading } from "@app/core/components";
import { useNotificationError } from "@app/core/components/hooks";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useGetPriceOverrideConfigByIdQuery } from "@app/core/services";
import { Card, Layout } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { PriceOverrideDefinitionsButtonAndDrawer } from "./PriceOverridesPage/PriceOverrideDefinitions";
import { PriceOverrideDetailsActions } from "./PriceOverrideDetailsActions";
import { PriceOverrideDetailsContent } from "./PriceOverridesPage/PriceOverridesDrawer/PriceOverrideDetailsContent";
import { PriceOverridesDrawer } from "./PriceOverridesPage/PriceOverridesDrawer";
import { PageHeader } from "@ant-design/pro-layout";

export const PriceOverrideDetailsPage = () => {
    const { seatId, priceOverrideId } = useParams<{ seatId: string; priceOverrideId: string }>();
    const { data: priceOverride, isLoading, error } = useGetPriceOverrideConfigByIdQuery(Number(priceOverrideId));

    useNotificationError(error);

    const history = useHistory();

    if (isLoading) return <Loading />;

    if (!priceOverride) return null;

    return (
        <>
            <PageHeader
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_PRICE_OVERRIDES(seatId))}
                title={`Price Override: ${priceOverride.name}`}
                extra={<PriceOverrideDefinitionsButtonAndDrawer />}
            >
                <PriceOverrideDetailsActions priceOverride={priceOverride} />
            </PageHeader>
            <Layout.Content>
                <Card>
                    <PriceOverrideDetailsContent priceOverride={priceOverride} />
                </Card>
                <PriceOverridesDrawer />
            </Layout.Content>
        </>
    );
};
