import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormInputFieldChange } from "@app/features/deals/DealForm/reducer";
import { useDispatchOnBlur } from "@app/features/deals/DealForm/hooks";

interface UseDealImpressionsField {
    onChange: (value: number) => void;
    value: number | null;
    handleBlur: () => void;
}

export const useDealImpressionsField = (): UseDealImpressionsField => {
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.IMPRESSION]);
    const { onBlur, onChange } = useDispatchOnBlur(value);

    const handleBlur = (): void => {
        onBlur(dealFormInputFieldChange, CREATE_DEAL_FORM_ITEMS_NAME.IMPRESSION);
    };

    return {
        onChange,
        value,
        handleBlur,
    };
};
