import { FC } from "react";
import { Input } from "antd";
import { useSeatFloorsAdvancedTableFilterSearch } from "./useSeatFloorsAdvancedTableFilterSearch";
import { SearchOutlined } from "@ant-design/icons";

const SeatFloorsAdvancedTableFilterSearch: FC = () => {
    const { value, handleChange } = useSeatFloorsAdvancedTableFilterSearch();

    return (
        <Input
            style={{ width: "100%" }}
            onChange={handleChange}
            value={value}
            allowClear
            placeholder="Search by Floor Name"
            suffix={<SearchOutlined />}
        />
    );
};

export default SeatFloorsAdvancedTableFilterSearch;
