import { useNotificationError } from "@app/core/components/hooks";
import { TransparencyDefaultForm, useGetTransparencyDefaultQuery } from "@app/core/services";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { mapContentRuleApiToForm, mapExtendedUserIdApiToForm, mapUserIdMaskApiToForm } from "./helpers";
import { uri } from "@rubicon/utils";
import { useEffect, useMemo } from "react";
import { DRAWER, ExtendedUserSelectValueType, LV, SPECIFIC } from "./constants";
import { selectExtendedIds, setExtendedIds } from "../reducer";
import { useAppDispatch, useAppSelector } from "@app/core/store";

export const useTransparencyDefault = () => {
    const { search } = useLocation();
    const { seatId } = useParams<{ seatId: string }>();
    const { data, isLoading, isFetching, error } = useGetTransparencyDefaultQuery(seatId);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const extendedIds = useAppSelector(selectExtendedIds);

    const drawerId = useMemo(() => uri.getSearchParam(search, "drawer"), [search]);

    useEffect(() => {
        const extendedOptions = data?.reduce((map, obj) => {
            map[obj.id] = obj.allowedExtendedIdTransparencies?.map(({ extendedId, network }) => ({
                label: extendedId.name.concat(":").concat(network.name),
                value: String(extendedId.id).concat("/").concat(String(network.id)),
            }));
            return map;
        }, {});
        extendedOptions && dispatch(setExtendedIds(extendedOptions));
    }, [data, dispatch]);

    const handleAdd = (extUser: LV, network: LV, callback: () => void) => {
        const result = {
            label: extUser.label.concat(":").concat(network.label),
            value: String(extUser.value).concat("/").concat(String(network.value)),
        };
        const restResult: LV[] = extendedIds?.[Number(drawerId)] || [];
        const newValuesByAdSourceType = { [Number(drawerId)]: [...restResult, result] };
        const newValue = { ...extendedIds, ...newValuesByAdSourceType };
        dispatch(setExtendedIds(newValue));

        callback?.();
    };

    const handleRemove = (val: string, adSourceId: number, callback?: () => void) => {
        const newValuesByAdSourceType = { [adSourceId]: extendedIds[adSourceId].filter((a) => a.value !== val) };
        const newValue = { ...extendedIds, ...newValuesByAdSourceType };

        dispatch(setExtendedIds(newValue));
        // TODO discard Select to None if extendedIds[adSourceId] = []
        callback?.();
    };

    const handleExtendedUserSelect = (value: ExtendedUserSelectValueType) => (id: number) => {
        if (value === SPECIFIC) {
            const nextSearch = uri.setSearchParam(search, DRAWER.key, String(id) || "extendedUser");
            history.push({ search: nextSearch });
        }
        return id;
    };

    const initialValues: TransparencyDefaultForm[] | undefined = useMemo(
        () =>
            data?.map(
                ({
                    allowUserId,
                    maskUserId,
                    allowContent,
                    allowAllExtendedId,
                    allowedExtendedIdTransparencies,
                    contentTransparencyRule,
                    ...transparencyDef
                }) => ({
                    ...transparencyDef,
                    controlUserIdIfa: mapUserIdMaskApiToForm(allowUserId, maskUserId),
                    controlContent: mapContentRuleApiToForm(allowContent, contentTransparencyRule),
                    controlExtendedUserId: mapExtendedUserIdApiToForm(
                        allowAllExtendedId,
                        allowedExtendedIdTransparencies
                    ) as ExtendedUserSelectValueType,
                })
            ),
        [data]
    );

    useNotificationError(error);

    return {
        isLoading: isLoading || isFetching,
        data,
        initialValues,
        extendedIds,
        seatId,
        search,
        handleAdd,
        handleRemove,
        handleExtendedUserSelect,
    };
};
