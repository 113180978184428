import { ChangeEvent } from "react";
import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormInputFieldChange } from "@app/features/deals/DealForm/reducer";
import { useDispatchOnBlur } from "@app/features/deals/DealForm/hooks";

interface UseAdSourceNameField {
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
    value: string | number | readonly string[] | undefined;
    handleBlur: () => void;
}

export const useAdSourceNameField = (): UseAdSourceNameField => {
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_NAME]);
    const { onChange, onBlur } = useDispatchOnBlur(value);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        onChange(value);
    };
    const handleBlur = (): void => {
        onBlur(dealFormInputFieldChange, CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_NAME);
    };
    return {
        handleChange,
        value,
        handleBlur,
    };
};
