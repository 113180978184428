import { FC } from "react";
import { LoadableAnimatedNumberCell } from "@app/core/components";

interface Props {
    netRevenue: number | undefined;
    currencyCode: string | undefined;
}

export const SeatAdSourcesListTableNetRevenueCell: FC<Props> = ({ netRevenue, currencyCode }) => {
    return (
        <LoadableAnimatedNumberCell
            value={netRevenue}
            isMoney={true}
            currencyCode={currencyCode}
            isLoading={netRevenue === undefined}
        />
    );
};
