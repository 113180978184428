import { css } from "@emotion/css";
import { CloseOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, Space, Typography, Flex, Layout } from "antd";
import { FC } from "react";
import { ClipBoardButton } from "@app/core/components";
import { useDetailsDrawerUnitLayout } from "./useDetailsDrawerUnitLayout";
import { useInventoryDetailsDrawer } from "../../useInventoryDetailsDrawer";
import { DetailsDrawerUnitLayoutToolbar } from "./DetailsDrawerUnitLayoutToolbar";
import { brandCobalt, gray7, gray8 } from "@rubicon/antd-components";

interface Props {
    title: string;
}
export const DetailsDrawerUnitLayout: FC<Props> = ({ title }) => {
    const { visibleCopy, copyUrl, handleHideCopy, handleVisibleCopy, gotoInventory } = useDetailsDrawerUnitLayout();
    const { closeDetailsDrawer } = useInventoryDetailsDrawer();

    return (
        <Layout.Content>
            <Flex id={"unit-id"} data-sdet="inventory-details-drawer-header" gap="small" vertical justify="stretch">
                <Flex justify="space-between" style={{ marginBottom: 12 }}>
                    <Col
                        style={{ flex: 95, display: "flex" }}
                        onMouseEnter={handleVisibleCopy}
                        onMouseLeave={handleHideCopy}
                    >
                        <Space align="baseline">
                            <Typography.Title style={{ margin: 0 }} level={5}>
                                {title}
                            </Typography.Title>

                            <Space>
                                <Button
                                    onClick={gotoInventory}
                                    type="link"
                                    className={css`
                                        height: 24px;
                                        padding: 0;
                                        font-size: 16px;
                                        color: ${brandCobalt};
                                    `}
                                >
                                    Go to Inventory
                                    <ArrowRightOutlined />
                                </Button>

                                {visibleCopy && <ClipBoardButton copyText={copyUrl} />}
                            </Space>
                        </Space>
                    </Col>
                    <Col style={{ flex: 1 }}>
                        <Button
                            type="text"
                            onClick={() => closeDetailsDrawer()}
                            className={css`
                                color: ${gray7};
                                padding: 0;
                                height: auto;

                                &:hover {
                                    color: ${gray8};
                                    background-color: transparent;
                                }
                            `}
                        >
                            <CloseOutlined
                                className={css`
                                    svg {
                                        width: 1.15em;
                                        height: 1.15em;
                                    }
                                `}
                            />
                        </Button>
                    </Col>
                </Flex>
                <DetailsDrawerUnitLayoutToolbar />
            </Flex>
        </Layout.Content>
    );
};
