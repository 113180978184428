import { FC } from "react";
import { css } from "@emotion/css";
import { Row, Typography, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { gray7, gray8 } from "@rubicon/antd-components";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "../../../../types";
import { adSourceDetailsHelpKeys } from "./constants";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import { useHistory } from "react-router-dom";

interface Props {
    adSourceName: string;
    secondaryDetailsView: SecondaryDetailsView;
}

export const SeatAdSourcesDetailsDrawerNestedTitle: FC<Props> = ({ adSourceName, secondaryDetailsView }) => {
    const history = useHistory();

    return (
        <Row justify="space-between">
            <Row data-sdet="seat-ad-sources-details-drawer-nested-title">
                <Button
                    data-sdet="seat-ad-sources-details-drawer-nested-title-back-button"
                    type="text"
                    onClick={() => {
                        history.goBack();
                    }}
                    className={css`
                        color: ${gray7};
                        padding-left: 0;
                        &:hover {
                            color: ${gray8};
                            background-color: transparent;
                        }
                    `}
                >
                    <ArrowLeftOutlined
                        className={css`
                            svg {
                                width: 1em;
                                height: 1em;
                            }
                        `}
                    />
                </Button>
                <Typography.Title level={5} style={{ fontWeight: "normal", marginTop: "0.25em", marginBottom: "0" }}>
                    {adSourceName}
                    <Typography.Text
                        style={{ fontWeight: "bold", color: "black" }}
                    >{` / ${secondaryDetailsView}`}</Typography.Text>
                </Typography.Title>
            </Row>
            <Row align="middle" style={{ marginRight: "12px" }}>
                <HelpKeysDrawer
                    panelLabel={secondaryDetailsView}
                    definitionsFields={adSourceDetailsHelpKeys[secondaryDetailsView]}
                />
            </Row>
        </Row>
    );
};
