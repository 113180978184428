import { Col, Typography } from "antd";
import { useBookingBudgetField } from "./useBookingBudgetField";
import { AdSource } from "@app/core/services";

const { Text, Paragraph } = Typography;

export const BookingBudgetField = ({ adSource }: { adSource: AdSource }) => {
    const { isVisible, bookingBudgetValue } = useBookingBudgetField(adSource);

    if (!isVisible) {
        return null;
    }
    return (
        <Col sm={8}>
            <Text strong>Booking Budget</Text>
            <Paragraph>{bookingBudgetValue || "--"}</Paragraph>
        </Col>
    );
};
