import { FC } from "react";
import { Form } from "antd";
import {
    AD_SOURCE_FIELDS,
    DEFAULT_ENRICHMENT_COST_CURRENCY_CODE,
    VERTICAL_LAYOUT_FIX,
} from "@app/features/seatAdSources/constants";
import { formatDac } from "./utils";

interface Props {
    dac: number | null;
    dacModelId: number;
    verticalLayout?: boolean;
}

export const ReadOnlyDemandAcquisitionCostField: FC<Props> = ({ dac, dacModelId, verticalLayout }) => (
    <Form.Item label={AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.label} {...(verticalLayout ? VERTICAL_LAYOUT_FIX : {})}>
        {formatDac(dac, dacModelId, DEFAULT_ENRICHMENT_COST_CURRENCY_CODE)}
    </Form.Item>
);
