import { useAppDispatch, useAppSelector } from "@app/core/store";
import { LabeledValue } from "antd/lib/select";
import { setFiltersSeatAdSourcesTargeting } from "../../reducer";

interface UseSeatAdSourcesTargetingFilter {
    handleChangeValue: (value: string | number | undefined, option: LabeledValue | LabeledValue[]) => void;
    options: LabeledValue[];
    value: LabeledValue[] | string | null;
    areAllOptionsSelected: boolean;
    toggleSelectDeselectAll: () => void;
}

export const useSeatAdSourcesTargetingFilter = (): UseSeatAdSourcesTargetingFilter => {
    const dispatch = useAppDispatch();
    const seatAdSourcesTargeting = useAppSelector((state) => state.seatAdSourcesList.filters.seatAdSourcesTargeting);
    const options = [
        { label: "DMP Targeting", value: "true" },
        { label: "Series", value: 1 },
        { label: "Season", value: 2 },
        { label: "Episode", value: 3 },
        { label: "Video Title / ID", value: 4 },
        { label: "Content Length", value: 5 },
        { label: "Content Rating", value: 6 },
        { label: "Producer", value: 7 },
        { label: "Genre", value: 8 },
        { label: "Content Network", value: 9 },
        { label: "Content Channel", value: 10 },
        { label: "Content Categories", value: 11 },
        { label: "C1", value: "c1" },
        { label: "C2", value: "c2" },
        { label: "C3", value: "c3" },
        { label: "C4", value: "c4" },
    ];

    const handleChangeValue = (value: string | number | undefined, option: LabeledValue[]) => {
        dispatch(setFiltersSeatAdSourcesTargeting(option));
    };

    const areAllOptionsSelected = options.length === seatAdSourcesTargeting?.length;

    const toggleSelectDeselectAll = () => {
        if (areAllOptionsSelected) {
            handleChangeValue(undefined, []);
        } else {
            handleChangeValue(undefined, options);
        }
    };

    return {
        handleChangeValue,
        options: options,
        value: seatAdSourcesTargeting,
        areAllOptionsSelected,
        toggleSelectDeselectAll,
    };
};
