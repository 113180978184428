import { Col, Form, Row } from "antd";
import { DebounceUriSearch } from "@app/core/components/DebounceUriSearch";

export const SEARCH_FILTER_SDET = "seat-contacts-search-filter";

export const SeatContactsToolbar = () => (
    <Form layout="vertical">
        <Row gutter={16}>
            <Col xs={12} sm={5}>
                <Form.Item>
                    <DebounceUriSearch sdet={SEARCH_FILTER_SDET} />
                </Form.Item>
            </Col>
        </Row>
    </Form>
);

export default SeatContactsToolbar;
