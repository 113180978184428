import { FC, useContext, useEffect } from "react";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import {
    CreateTargeting,
    ReadOnlyDemandConfigurationTargeting,
    TargetingBlock,
    targetingBlockToTargetingPayload,
} from "@app/features/targeting";
import { additionalTargetingCollapsePanelClassList, TargetingFormKeys } from "@app/features/targeting/constants";
import { Form, Collapse } from "antd";
import { DEAL_FORM_FIELDS } from "../../constants";
import { AdSourceFormsContext, AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { EditDemandConfigurationTargeting } from "@app/features/targeting/EditTargeting/EditDemandConfigurationTargeting";
import { useAdSourceTargetingToggle } from "@app/features/targeting/ReadOnlyTargeting/useAdSourceTargetingToggle";
import { MarketplaceInfo, Targeting, TargetingCreatePayload } from "@app/core/services";
import { useTargetingForm } from "@app/features/targeting/useTargetingForm";
import { useAppSelector } from "@app/core/store";
import { targetingCreatePayloadToTargeting } from "@app/features/targeting/ReadOnlyTargeting/helpers";
import { toTargetingModePayload } from "@app/features/targeting/helpers";

export const TargetingSettings: FC = () => {
    const { marketplaceInfoMode } = useMarketplaceInfoWidget();
    const { loadTargeting, targetingFormsByFormKey } = useTargetingForm(TargetingFormKeys.AdSourceAdditionalTargeting);
    const { forms } = useContext(AdSourceFormsContext);
    const { showExistingAdSourceTargeting, setShowExistingAdSourceTargeting } = useAdSourceTargetingToggle();
    const additionalTargeting = Form.useWatch<Targeting[]>("targeting");
    const adSourceTargeting = forms.adSourceForm.getFieldValue(AD_SOURCE_FIELDS.READ_ONLY_TARGETING.name);
    const adSourceTargetingOperation = targetingFormsByFormKey.adSource.targetingOperation;
    const adSourceTargetingMode = toTargetingModePayload(adSourceTargetingOperation);
    const readonly = marketplaceInfoMode.name === "view";
    const edit = marketplaceInfoMode.name === "edit";

    useEffect(() => {
        if (additionalTargeting && edit) {
            loadTargeting(TargetingFormKeys.AdSourceAdditionalTargeting, additionalTargeting, adSourceTargetingMode);
        }
        // JSON.stringify(adSourceTargeting) fixes a bug that was causing unbounded rerenders. The value [] for ad source targeting was causing this use effect to rerun indefinitely.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [additionalTargeting, JSON.stringify(adSourceTargeting), loadTargeting, adSourceTargetingMode, edit]);

    // The real time operation is the current operation as chosen by the user
    const realTimeAdSourceOperation = useAppSelector(
        (state) => state.targeting.targetingFormsByKey.adSource.targetingOperation
    );

    const realTimeAdSourceTargeting = useAppSelector(
        (state) => state.targeting.targetingFormsByKey.adSource.targetingBlocks
    );
    const realTimeTargetingPayload = realTimeAdSourceTargeting?.map((target) => {
        if (!target.hasOwnProperty("name") || target.name === null) {
            return targetingBlockToTargetingPayload(target as TargetingBlock);
        } else {
            return target;
        }
    });

    const realTimeAdSourceTargetingDisplay: Targeting[] = targetingCreatePayloadToTargeting(
        realTimeTargetingPayload as TargetingCreatePayload[]
    );

    const {
        forms: { adSourceForm, dealObjectForm },
    } = useContext(AdSourceFormsContext);

    const adSourceType = Form.useWatch<AdSourcesForm["type"]>(AD_SOURCE_FIELDS.TYPE.name, adSourceForm);
    const dealName = Form.useWatch<MarketplaceInfo["deal"]["name"]>(["dealUnderEdit", "name"], dealObjectForm);
    const adSourceCurrency =
        Form.useWatch<AdSourcesForm["currencyType"]>(AD_SOURCE_FIELDS.CURRENCY.name, adSourceForm)?.code || "USD";

    return (
        <>
            <Form.Item hidden name={DEAL_FORM_FIELDS.TARGETING.name} />
            <Collapse style={{ background: "white", width: "100%", border: "none" }} expandIconPosition="end">
                <Collapse.Panel
                    className={additionalTargetingCollapsePanelClassList}
                    header="Additional Targeting"
                    key="1"
                >
                    {marketplaceInfoMode.name === "create" && realTimeAdSourceTargeting.length == 0 ? (
                        <CreateTargeting
                            formKey={TargetingFormKeys.AdSourceAdditionalTargeting}
                            validationFormKey={TargetingFormKeys.AdSource}
                            operation={realTimeAdSourceOperation}
                            operationReadOnly
                        />
                    ) : (
                        (additionalTargeting || realTimeAdSourceTargeting.length > 0) &&
                        (readonly ? (
                            <ReadOnlyDemandConfigurationTargeting
                                additionalTargetingBlocks={additionalTargeting}
                                adSourceTargetingBlocks={adSourceTargeting}
                                showExistingAdSourceTargeting={showExistingAdSourceTargeting}
                                setShowExistingAdSourceTargeting={setShowExistingAdSourceTargeting}
                                adSourceTargetingFormKey={TargetingFormKeys.AdSource}
                                additionalTargetingFormKey={TargetingFormKeys.DealAdditionalTargeting}
                                existingAdSourceTargetingModeId={adSourceTargetingMode?.id}
                            />
                        ) : (
                            <EditDemandConfigurationTargeting
                                additionalTargetingBlocks={additionalTargeting}
                                additionalTargetingFormKey={TargetingFormKeys.AdSourceAdditionalTargeting}
                                adSourceTargetingBlocks={realTimeAdSourceTargetingDisplay}
                                adSourceTargetingFormKey={TargetingFormKeys.AdSource}
                                setShowExistingAdSourceTargeting={setShowExistingAdSourceTargeting}
                                showExistingAdSourceTargeting={showExistingAdSourceTargeting}
                                adSourceType={adSourceType}
                                dealName={dealName}
                                currencyCode={adSourceCurrency}
                            />
                        ))
                    )}
                </Collapse.Panel>
            </Collapse>
        </>
    );
};
