import { FC } from "react";
import { useThrottle } from "@app/core/utils";
import { useUserAccess } from "@app/core/auth";
import { NONE } from "@app/core/components/constants";
import { LoadingCell } from "@app/core/components/LoadingCell";
import { AnimatedCell } from "@app/core/components/AnimatedCell";
import { AdStat, AdStatsById, getOtimesForThrottleUnit, getTotalRejectedRequests } from "@app/features/adStats";

interface InventoryHealthInventoryRejectedRequestsProps {
    adStats: AdStatsById;
    sourceId: number;
    isConnected: boolean;
}

export const InventoryHealthInventoryRejectedRequests: FC<InventoryHealthInventoryRejectedRequestsProps> = ({
    adStats,
    sourceId,
    isConnected,
}) => {
    const adStat: AdStat | null = adStats[sourceId];
    const { isTremorUser } = useUserAccess();
    const rejectedRequests: number | undefined = getTotalRejectedRequests(adStat, isTremorUser);
    const otime = getOtimesForThrottleUnit(adStat);

    const throttledTries: number | undefined = useThrottle<number | undefined>(rejectedRequests || undefined, {
        forceUpdateDependencies: [otime],
    });

    if (!isConnected || !adStat) return <LoadingCell />;

    if (throttledTries === undefined) return <>{NONE}</>;

    return <AnimatedCell value={throttledTries} />;
};
