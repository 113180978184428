import { consoleApi, BUYER_TAG } from "./console";

export interface Buyer {
    buyerSeats: [];
    buyerTransparencyType: {
        creationDate: string;
        id: number;
        name: string;
        sort: number;
        updateDate: string;
    };
    buyerType: {
        creationDate: string;
        deleted: boolean;
        id: number;
        name: string;
        updateDate: string;
    };
    code: string;
    creationDate: string;
    description: string;
    holdingCompany: string;
    id: number;
    name: string;
    region: {
        creationDate: string;
        id: number;
        name: string;
        updateDate: string;
    };
    salesContact: string;
    updateDate: string;
    entityType: "Buyer";
}

export const buyersApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getBuyerById: builder.query<Buyer, number>({
            query: (id: number) => `buyers/${id}`,
            providesTags: (_, __, id) => [{ type: BUYER_TAG, id }],
        }),
    }),
    overrideExisting: false,
});

export const { useGetBuyerByIdQuery, useLazyGetBuyerByIdQuery } = buyersApi;
