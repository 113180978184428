import { Form } from "antd";
import { FC } from "react";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const LiveStream: FC = () => {
    return (
        <Form.Item name={CHANNEL_FORM_FIELDS.LIVE_STREAM.name} label={CHANNEL_FORM_FIELDS.LIVE_STREAM.label}>
            <BooleanRadioGroup />
        </Form.Item>
    );
};
