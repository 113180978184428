import { Alert, Typography, Button, Row, Col } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useAuthClient } from "@app/core/authClient";

export const RunAsUserAlert = () => {
    const { isRunningAsOther, session, runAsSelf } = useAuthClient();

    if (!isRunningAsOther) {
        return null;
    }

    return (
        <Alert
            message={
                <Row justify="space-between" align="bottom">
                    <Col>
                        <Typography.Text>
                            This is what{" "}
                            <Typography.Text strong>
                                {session?.user?.emailAddress} ({session?.user?.name})
                            </Typography.Text>{" "}
                            sees
                        </Typography.Text>
                    </Col>
                    <Col>
                        <Button size="small" onClick={runAsSelf}>
                            Run as Self
                        </Button>
                    </Col>
                </Row>
            }
            style={{ backgroundColor: "#faad14" }}
            type="warning"
            icon={<InfoCircleOutlined style={{ color: "#595959" }} />}
            banner
        />
    );
};
