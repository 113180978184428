import { FC } from "react";
import { Form, Card } from "antd";
import { Seat } from "@app/core/services";
import { ConditionalTagRender } from "@app/core/components";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

interface QualityControlPageSectionProps {
    seat: Seat;
}

export const QualityControlPageSection: FC<QualityControlPageSectionProps> = ({ seat }) => (
    <Card bordered={false}>
        <Form.Item label={INVENTORY_FORM_FIELDS.FRAUD_DETECTION.label} data-sdet="fraud-detection">
            {seat?.seatFraudDetection?.name}
        </Form.Item>
        <Form.Item label={INVENTORY_FORM_FIELDS.DOMAIN_FILTER_MODE.label} data-sdet="supply-domain-filter-mode">
            {seat?.supplyDomainFilterIsBlack ? "Block" : "Allow"}
        </Form.Item>
        <Form.Item label={INVENTORY_FORM_FIELDS.SUPPLY_DOMAIN_FILTER.label} data-sdet="supply-domain-lists">
            <ConditionalTagRender data={seat} property={INVENTORY_FORM_FIELDS.SUPPLY_DOMAIN_FILTER.name} />
        </Form.Item>
        <Form.Item label={INVENTORY_FORM_FIELDS.BUNDLE_ID_FILTER_MODE.label} data-sdet="app-bundle-id-filter-mode">
            {seat?.bundleIdFilterIsBlack ? "Block" : "Allow"}
        </Form.Item>
        <Form.Item label={INVENTORY_FORM_FIELDS.BUNDLE_ID_FILTER.label} data-sdet="app-bundle-id-lists">
            <ConditionalTagRender data={seat} property={INVENTORY_FORM_FIELDS.BUNDLE_ID_FILTER.name} />
        </Form.Item>
    </Card>
);
