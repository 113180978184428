import { createContext, useContext } from "react";
import { useBuyerSideNav } from "./useBuyerSideNav";

export type BuyerSideNavContext = ReturnType<typeof useBuyerSideNav>;

const Context = createContext<BuyerSideNavContext | null>(null);

export function BuyerSideNavProvider(props) {
    const headerData = useBuyerSideNav();
    return <Context.Provider value={headerData} {...props} />;
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const useBuyerSideNavState = () => useContext<BuyerSideNavContext>(Context);
