import { useMemo, useRef } from "react";
import { XYSeries } from "../types";

export const useSlidingXYSeries = (liveData: XYSeries | undefined, maxEntries: number): XYSeries[] => {
    const dataHistoryRef = useRef<XYSeries[]>([]);

    return useMemo(() => {
        if (!liveData) {
            return dataHistoryRef.current;
        }

        const updatedDataHistory: XYSeries[] = Object.assign([], dataHistoryRef.current);

        const receivedUpdatedXValue = liveData.xValue !== updatedDataHistory.slice(-1)[0]?.xValue;
        if (receivedUpdatedXValue) {
            updatedDataHistory.push(liveData);
            const entryCountDiff = updatedDataHistory.length - maxEntries;
            if (entryCountDiff > 0) {
                updatedDataHistory.splice(0, entryCountDiff);
            }
        } else {
            updatedDataHistory[updatedDataHistory.length - 1] = liveData;
        }

        dataHistoryRef.current = updatedDataHistory;
        return updatedDataHistory;
    }, [liveData, maxEntries, dataHistoryRef]);
};
