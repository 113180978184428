import {
    Floor,
    TargetingCreatePayload,
    TargetingUpdatePayload,
    useDeleteFloorMutation,
    useUpdateFloorMutation,
    useGetSeatFloorsQuery,
    useGetBrandFloorsQuery,
    useGetAdUnitFloorsQuery,
    useGetSupplyFloorsQuery,
    useGetPublisherFloorsQuery,
    useCreateSeatFloorMutation,
    useCreateBrandFloorMutation,
    useCreateAdUnitFloorMutation,
    useCreateSupplyFloorMutation,
    useCreatePublisherFloorMutation,
} from "@app/core/services/console";
import { notification } from "antd";
import { useEffect, useState } from "react";
import { useUserAccess } from "@app/core/auth";
import { LabeledValue } from "antd/lib/select";
import {
    FloorValue,
    SourceSelf,
    getValuesFromFloor,
    getFloorInitialValues,
} from "@app/features/inventory/components/FloorsTable/index";
import { getTargetingFromCountry } from "@app/features/inventory/InventoryFloors/helpers";

interface UseFloorsTableProps {
    sourceSelf: SourceSelf;
    id: number;
}

interface UseFloorsTable {
    isLoading: boolean;
    floors: FloorValue[];
    handleAdd: () => void;
    isCreateMode: boolean;
    data: Floor[] | undefined;
    hasSeatWriteAccess: boolean;
    handleDeleteNew: () => void;
    savingIds: Set<number | null>;
    editingIds: Set<number | null>;
    deletingIds: Set<number | null>;
    handleEdit: (id: number) => void;
    handleDelete: (id: number) => void;
    handleEditCancel: (id: number) => void;
    handleSave: (id: number | null) => void;
    handleChangePrice: (id: number | null, event: number) => void;
    handleChangeCountry: (id: number | null, value: LabeledValue | null) => void;
}

export const useFloorsTable = ({ id, sourceSelf }: UseFloorsTableProps): UseFloorsTable => {
    const { hasSeatWriteAccess } = useUserAccess();

    const isSeatSource: boolean = sourceSelf === "Seat";
    const isBrandSource: boolean = sourceSelf === "Brand";
    const isSupplySource: boolean = sourceSelf === "Supply";
    const isAdUnitSource: boolean = sourceSelf === "Ad Unit";
    const isPublisherSource: boolean = sourceSelf === "Publisher";

    const { data: seatFloors, isFetching: isSeatFloorsFetching } = useGetSeatFloorsQuery(id, {
        skip: !id || !isSeatSource,
    });
    const { data: publisherFloors, isFetching: isPublisherFloorsFetching } = useGetPublisherFloorsQuery(
        { publisherId: id, list: "all" },
        { skip: !id || !isPublisherSource }
    );
    const { data: brandFloors, isFetching: isBrandFloorsFetching } = useGetBrandFloorsQuery(
        { brandId: id, list: "all" },
        { skip: !id || !isBrandSource }
    );
    const { data: supplyFloors, isFetching: isSupplyFloorsFetching } = useGetSupplyFloorsQuery(
        { supplyId: id, list: "all" },
        { skip: !id || !isSupplySource }
    );
    const { data: adUnitFloors, isFetching: isAdUnitFloorsFetching } = useGetAdUnitFloorsQuery(
        { adUnitId: id, list: "all" },
        { skip: !id || !isAdUnitSource }
    );

    const data: Floor[] | undefined = publisherFloors || brandFloors || supplyFloors || adUnitFloors || seatFloors;
    const isLoading: boolean =
        isPublisherFloorsFetching ||
        isBrandFloorsFetching ||
        isSupplyFloorsFetching ||
        isAdUnitFloorsFetching ||
        isSeatFloorsFetching;

    const [floors, setFloors] = useState<FloorValue[]>([]);

    const [savingIds, setSavingIds] = useState<Set<number | null>>(new Set<number | null>());
    const [editingIds, setEditingIds] = useState<Set<number | null>>(new Set<number | null>());
    const [deletingIds, setDeletingIds] = useState<Set<number | null>>(new Set<number | null>());

    const [createPublisherFloor] = useCreatePublisherFloorMutation();
    const [createAdUnitFloor] = useCreateAdUnitFloorMutation();
    const [createSupplyFloor] = useCreateSupplyFloorMutation();
    const [createBrandFloor] = useCreateBrandFloorMutation();
    const [createSeatFloor] = useCreateSeatFloorMutation();

    const [updateFloor] = useUpdateFloorMutation();
    const [deleteFloor] = useDeleteFloorMutation();

    const isCreateMode: boolean = floors.some((floor) => !floor?.id);

    useEffect((): void => {
        if (data) {
            setFloors((prev) => {
                const newFloors: FloorValue[] = [...prev];
                const initialValues = getFloorInitialValues(data);
                return newFloors.reduce<FloorValue[]>(
                    (previousValue, currentValue): FloorValue[] =>
                        !currentValue.id ? [...previousValue, { ...currentValue, key: data.length }] : previousValue,
                    initialValues
                );
            });
        }
    }, [data]);

    const handleDeleteNew = (): void => {
        setFloors((prev) => prev.filter((floor) => !!floor.id));
        setEditingIds((prev) => {
            const newSet: Set<number | null> = new Set(prev);
            newSet.delete(null);
            return newSet;
        });
    };
    const handleEdit = (id: number): void => setEditingIds((prev) => new Set(prev).add(id));

    const handleEditCancel = (id: number): void => {
        const floor: Floor | undefined = data?.find((floor): boolean => floor?.id === id);
        if (data && floor)
            setFloors((prev) => {
                const newFloors: FloorValue[] = [...prev];
                const index: number = newFloors.findIndex((el): boolean => el.id === floor.id);
                newFloors[index] = getValuesFromFloor(floor, index);
                return newFloors;
            });
        setEditingIds((prev) => {
            const newSet: Set<number | null> = new Set(prev);
            newSet.delete(id);
            return newSet;
        });
    };

    const handleSave = (id: number | null): Promise<void> => {
        const index: number = floors.findIndex((el): boolean => el.id === id);
        const value: FloorValue = floors[index];
        if (value.id) return update(value);
        return create(value);
    };

    const handleAdd = (): void => {
        setEditingIds((prev) => new Set(prev).add(null));
        setFloors((prev) => {
            const newFloors: FloorValue[] = [...prev];
            newFloors.push({ key: newFloors.length, id: null, price: 0, country: null, floor: null });
            return newFloors;
        });
    };

    const handleDelete = (id: number): Promise<void> => _delete(id);

    const handleChangeCountry = (id: number | null, country: LabeledValue | null): void => {
        setFloors((prev) => {
            const newFloors: FloorValue[] = [...prev];
            const index: number = newFloors.findIndex((el): boolean => el.id === id);
            newFloors[index] = { ...newFloors[index], country };
            return newFloors;
        });
    };

    const handleChangePrice = (id: number | null, price: number): void => {
        setFloors((prev) => {
            const newFloors: FloorValue[] = [...prev];
            const index: number = newFloors.findIndex((el): boolean => el.id === id);
            newFloors[index] = { ...newFloors[index], price };
            return newFloors;
        });
    };

    const onCreatedFloor = (): void => {
        handleDeleteNew();
        notification.success({ message: "Floor created successfully" });
    };

    const create = async (value: FloorValue): Promise<void> => {
        setSavingIds((prev) => new Set(prev).add(value.id));
        const body = {
            id: null,
            price: value.price * 1000,
            targeting: getTargetingFromCountry(value.country) as (TargetingCreatePayload | TargetingUpdatePayload)[],
        };

        switch (sourceSelf) {
            case "Seat":
                try {
                    await createSeatFloor({ seatId: id, body }).unwrap();
                    onCreatedFloor();
                } catch (err) {
                    notification.error({ message: err.data.errorDescription });
                }
                break;
            case "Publisher":
                try {
                    await createPublisherFloor({ publisherId: id, body }).unwrap();
                    onCreatedFloor();
                } catch (err) {
                    notification.error({ message: err.data.errorDescription });
                }
                break;
            case "Brand":
                try {
                    await createBrandFloor({ brandId: id, body }).unwrap();
                    onCreatedFloor();
                } catch (err) {
                    notification.error({ message: err.data.errorDescription });
                }
                break;
            case "Ad Unit":
                try {
                    await createAdUnitFloor({ adUnitId: id, body }).unwrap();
                    onCreatedFloor();
                } catch (err) {
                    notification.error({ message: err.data.errorDescription });
                }
                break;
            case "Supply":
                try {
                    await createSupplyFloor({ supplyId: id, body }).unwrap();
                    onCreatedFloor();
                } catch (err) {
                    notification.error({ message: err.data.errorDescription });
                }
                break;
        }

        setSavingIds((prev): Set<number | null> => {
            const newSet: Set<number | null> = new Set(prev);
            newSet.delete(value.id);
            return newSet;
        });
    };

    const update = async (value: FloorValue): Promise<void> => {
        // Client Side Validation
        if (!data) return;

        const dataCountries: number[] = floors
            .filter((floor): boolean => floor.id !== value.id)
            .map((floor) => floor.country?.value as number);

        const isCountryExists: boolean = dataCountries.includes(value.country?.value as number);

        if (isCountryExists) {
            notification.error({ message: `Floor already exists for the country ${value.country?.label}` });
            return;
        }

        setSavingIds((prev): Set<number | null> => new Set(prev).add(value.id));
        const body = {
            id: value.id,
            price: value.price * 1000,
            targeting: getTargetingFromCountry(value.country) as (TargetingCreatePayload | TargetingUpdatePayload)[],
        };

        try {
            await updateFloor({ id: Number(value.id), body }).unwrap();
            notification.success({ message: "Floor updated successfully" });
            setEditingIds((prev): Set<number | null> => {
                const newSet: Set<number | null> = new Set(prev);
                newSet.delete(value.id);
                return newSet;
            });
        } catch (err) {
            notification.error({ message: err.data.errorDescription });
        }
        setSavingIds((prev): Set<number | null> => {
            const newSet: Set<number | null> = new Set(prev);
            newSet.delete(value.id);
            return newSet;
        });
    };

    const _delete = async (id: number | null): Promise<void> => {
        setDeletingIds((prev): Set<number | null> => new Set(prev).add(id));
        try {
            await deleteFloor(Number(id)).unwrap();
            notification.success({ message: "Floor deleted successfully" });
        } catch (err) {
            notification.error({ message: err.data.errorDescription });
        }
        setDeletingIds((prev): Set<number | null> => {
            const newSet: Set<number | null> = new Set(prev);
            newSet.delete(id);
            return newSet;
        });
    };

    return {
        data,
        floors,
        handleAdd,
        savingIds,
        isLoading,
        editingIds,
        handleEdit,
        handleSave,
        deletingIds,
        isCreateMode,
        handleDelete,
        handleDeleteNew,
        handleEditCancel,
        handleChangePrice,
        hasSeatWriteAccess,
        handleChangeCountry,
    };
};
