import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useGetAdSourceIabCategoryBlockModesQuery } from "@app/core/services";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { AdSourceAdvertiserDomainModeIds } from "@app/features/seatAdSources/constants";

interface UseAdSourceIabCategoryBlockModeField {
    disabled: boolean;
    isFetching: boolean;
    value?: number | null;
    options: LabeledValue[];
    onChange: (value: number) => void;
}

export const useAdSourceIabCategoryBlockModeField = (): UseAdSourceIabCategoryBlockModeField => {
    const dispatch = useAppDispatch();
    const { data, isFetching } = useGetAdSourceIabCategoryBlockModesQuery();

    const value: number | null = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_IAB_CATEGORY_BLOCK_MODE]
    );
    const adSourceMode: number | null = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE]
    );
    const disabled: boolean = adSourceMode === AdSourceAdvertiserDomainModeIds.ALLOW_OVERRIDE_INVENTORY;

    const options: LabeledValue[] = useMemo(
        () =>
            (data || []).map((item) => ({
                label: item.name,
                value: item.id,
            })),
        [data]
    );

    const onChange = (value: number): void => {
        dispatch(
            dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_IAB_CATEGORY_BLOCK_MODE, value })
        );
    };

    return {
        value,
        options,
        onChange,
        disabled,
        isFetching,
    };
};
