import { FC } from "react";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { AdUnitCompetitiveSeparationGroups } from "@app/features/inventory/components/FormItems";

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 };
export const CompetitiveSeparationGroups: FC = () => {
    return (
        <AdUnitCompetitiveSeparationGroups {...AD_UNIT_FORM_FIELDS.COMPETITIVE_SEPARATIO_GROUPS} colSize={COL_SIZES} />
    );
};
