import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { useUserAccess } from "@app/core/auth";
import { PriceOverridesPage } from "./PriceOverridesPage/";
import { PriceOverrideCreatePage } from "./PriceOverrideCreatePage";
import { PriceOverrideEditPage } from "./PriceOverrideEditPage";
import { PriceOverrideDetailsPage } from "./PriceOverrideDetailsPage";

export const PriceOverridesSwitch: FC = () => {
    const { canEditSeat } = useUserAccess();

    return (
        <Switch>
            <Route exact path={[ROUTES.SEAT_CONTROLS_PRICE_OVERRIDES]}>
                <PriceOverridesPage />
            </Route>

            {canEditSeat && (
                <Route exact path={ROUTES.SEAT_CONTROLS_PRICE_OVERRIDE_CREATE_PAGE}>
                    <PriceOverrideCreatePage />
                </Route>
            )}
            {canEditSeat && (
                <Route exact path={ROUTES.SEAT_CONTROLS_PRICE_OVERRIDE_EDIT_PAGE}>
                    <PriceOverrideEditPage />
                </Route>
            )}
            <Route exact path={ROUTES.SEAT_CONTROLS_PRICE_OVERRIDE_DETAILS_PAGE}>
                <PriceOverrideDetailsPage />
            </Route>
        </Switch>
    );
};
