import { FC } from "react";
import { SegmentRuleGroup, SegmentRuleIdentifier } from "@app/features/targeting/types";
import { SegmentRuleIdentifierRow } from "./SegmentRuleIdentifierRow";
import { RuleGroupRow } from "../ReadOnlyRuleGroupRow";

interface Props {
    group: SegmentRuleGroup;
    path: number[];
}

export const SegmentRuleGroupRow: FC<Props> = ({ group, path }) => {
    return (
        <RuleGroupRow<SegmentRuleIdentifier>
            group={group}
            path={path}
            identifierRowRender={(rule, pathStr, groupIndex) => (
                <SegmentRuleIdentifierRow key={`segment-rule-${pathStr}-${groupIndex}`} rule={rule} />
            )}
            groupRowRender={(rule, pathStr, groupIndex) => (
                <SegmentRuleGroupRow
                    group={rule}
                    key={`segment-ruleGroup-${pathStr}-${groupIndex}`}
                    path={[...path, groupIndex]}
                />
            )}
        />
    );
};
