import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormInputFieldChange } from "@app/features/deals/DealForm/reducer";
import { useDispatchOnBlur } from "@app/features/deals/DealForm/hooks";
import { ChangeEvent } from "react";

interface UseAdSourceExternalContactNumberField {
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
    value: string;
    handleBlur: () => void;
}

export const useAdSourceExternalContractNumberField = (): UseAdSourceExternalContactNumberField => {
    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_EXTERNAL_CONTACT_NUMBER]
    );
    const { onChange, onBlur } = useDispatchOnBlur(value);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        onChange(value);
    };

    const handleBlur = () => {
        onBlur(dealFormInputFieldChange, CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_EXTERNAL_CONTACT_NUMBER);
    };
    return {
        value,
        handleChange,
        handleBlur,
    };
};
