import { FC } from "react";
import { useThrottle } from "@app/core/utils";
import { useAppSelector } from "@app/core/store";
import { NONE } from "@app/core/components/constants";
import { LoadingCell } from "@app/core/components/LoadingCell";
import { AnimatedCell } from "@app/core/components/AnimatedCell";
import { useCurrencyConversion } from "@app/features/dashboard/useCurrencyConversion";
import { PercentageMetric } from "@app/features/inventory/components/PercentageMetric";
import {
    AdStat,
    AdStatsById,
    getImpressions,
    getOtimesForThrottleUnit,
    getTotalImpressions,
} from "@app/features/adStats";
import { INVENTORY_HEALTH_FRACTIONS_DIGITS } from "@app/features/inventory/InventoryHealth/InventoryHealthPage/constants";

interface InventoryHealthInventoryImpressionsProps {
    adStats: AdStatsById;
    sourceId: number;
    isConnected: boolean;
    showPercentage?: boolean;
}

export const InventoryHealthInventoryImpressions: FC<InventoryHealthInventoryImpressionsProps> = ({
    adStats,
    sourceId,
    isConnected,
    showPercentage = true,
}) => {
    const adStat: AdStat | null = adStats[sourceId];
    const { preferredCurrency } = useCurrencyConversion();
    const currencyConversionMode = useAppSelector((state) => state.dashboard.settings.values.currencyConversionMode);
    const otime = getOtimesForThrottleUnit(adStat);

    const totalImpressions: number | undefined = getTotalImpressions(
        adStats,
        preferredCurrency,
        currencyConversionMode
    );
    const impressions: number | undefined = getImpressions(adStat, preferredCurrency, currencyConversionMode);

    const throttledTries = useThrottle<number | undefined>(impressions || undefined, {
        forceUpdateDependencies: [otime, preferredCurrency, currencyConversionMode],
    });

    if (!isConnected || !adStat) {
        return <LoadingCell />;
    }

    if (throttledTries === undefined) {
        return <>{NONE}</>;
    }
    const percentage: string = ((impressions / totalImpressions) * 100).toFixed(INVENTORY_HEALTH_FRACTIONS_DIGITS);

    return (
        <AnimatedCell
            value={throttledTries}
            suffix={showPercentage ? <PercentageMetric percentage={percentage} /> : undefined}
        />
    );
};
