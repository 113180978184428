import { FC } from "react";
import { Form, InputNumber } from "antd";
import { ColProps } from "antd/lib/grid/col";
import { InputNumberProps } from "antd/lib/input-number";
import { MAX_DURATION } from "@app/features/inventory/HierarchyForms/constants";
import { useAdUnitMaxDuration } from "@app/features/inventory/components/FormItems/AdUnitMaxDuration/useAdUnitMaxDuration";

export interface AdUnitsMaxDurationProps extends InputNumberProps {
    name: typeof MAX_DURATION;
    wrapperCol?: ColProps;
    labelCol?: ColProps;
    label: string;
}

export const AdUnitMaxDuration: FC<AdUnitsMaxDurationProps> = ({ name, label, wrapperCol, labelCol, ...rest }) => {
    const { validator, onChange } = useAdUnitMaxDuration();
    return (
        <Form.Item
            name={name}
            label={label}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            rules={[{ required: true, validator }]}
        >
            <InputNumber onChange={onChange} style={{ width: "100%" }} addonAfter="Seconds" {...rest} />
        </Form.Item>
    );
};
