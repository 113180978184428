import { FinanceDefault } from "@app/core/services/console";
import {
    AdSourceTypesUsingFixedCpmByDefault,
    CostModels,
    CostModelsDefaultValues,
    CostModelsLabels,
} from "@app/features/seatAdSources/constants";

export const getDefaultCostModel = (
    adSourceTypeId: number | undefined,
    financeDefaults: FinanceDefault[] | undefined
): {
    costModel: { id: number; name: string };
    costValuePercent: number | null;
    costValueFixed: number | null;
} | null => {
    if (!adSourceTypeId) {
        return null;
    }
    const currentDefault = financeDefaults?.find((value) => value.adSourceType?.id === adSourceTypeId);
    if (currentDefault) {
        return {
            costModel: { id: currentDefault.costModel.id, name: currentDefault.costModel.name },
            costValuePercent: currentDefault.costValuePercent,
            costValueFixed: currentDefault.costValueFixed,
        };
    }
    if (AdSourceTypesUsingFixedCpmByDefault.has(adSourceTypeId)) {
        return {
            costModel: { id: CostModels.FIXED_CPM, name: CostModelsLabels[CostModels.FIXED_CPM] },
            costValuePercent: null,
            costValueFixed: CostModelsDefaultValues[CostModels.FIXED_CPM],
        };
    }
    return {
        costModel: { id: CostModels.REVSHARE_PERCENTAGE, name: CostModelsLabels[CostModels.REVSHARE_PERCENTAGE] },
        costValuePercent: CostModelsDefaultValues[CostModels.REVSHARE_PERCENTAGE],
        costValueFixed: null,
    };
};
