import {
    useCreateAdUnitStatsSnapshotMutation,
    useCreateBrandStatsSnapshotMutation,
    useCreatePublisherStatsSnapshotMutation,
    useCreateSupplyStatsSnapshotMutation,
} from "@app/core/services/adStats/statsSnapshots";
import { InventoryDetailsDrawerType } from "@app/features/inventory/DetailsDrawer/reducer";
import { useMemo } from "react";

interface UseUnitStatsSnapshotMutation {
    unitStatsSanpshotMutation: (
        drawerType: InventoryDetailsDrawerType,
        id: number | string,
        message: string
    ) => Promise<void>;
    isLoading: boolean;
}

export const useUnitStatsSnapshotMutation = (): UseUnitStatsSnapshotMutation => {
    const [publisherCreateSnapshot, { isLoading: publisherIsLoading }] = useCreatePublisherStatsSnapshotMutation();
    const [brandCreateSnapshot, { isLoading: brandIsLoading }] = useCreateBrandStatsSnapshotMutation();
    const [supplyCreateSnapshot, { isLoading: supplyIsLoading }] = useCreateSupplyStatsSnapshotMutation();
    const [adUnitCreateSnapshot, { isLoading: adUnitIsLoading }] = useCreateAdUnitStatsSnapshotMutation();

    const unitStatsSanpshotMutation = async (
        drawerType: InventoryDetailsDrawerType,
        id: number | string,
        message: string
    ) => {
        switch (drawerType) {
            case InventoryDetailsDrawerType.PUBLISHER:
            case InventoryDetailsDrawerType.CHANNEL:
                await publisherCreateSnapshot({
                    publisherId: id as number,
                    message,
                });
                break;
            case InventoryDetailsDrawerType.BRAND:
            case InventoryDetailsDrawerType.BRAND_CHANNEL:
                await brandCreateSnapshot({ brandId: id as number, message });
                break;
            case InventoryDetailsDrawerType.SUPPLY:
                await supplyCreateSnapshot({ supplyId: id as number, message });
                break;
            case InventoryDetailsDrawerType.AD_UNIT:
                await adUnitCreateSnapshot({ adUnitId: id as number, message });
        }
    };

    const isLoading = useMemo(
        () => publisherIsLoading || brandIsLoading || supplyIsLoading || adUnitIsLoading,
        [publisherIsLoading, brandIsLoading, supplyIsLoading, adUnitIsLoading]
    );

    return { unitStatsSanpshotMutation, isLoading };
};
