import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { usePublisherChannelFormInstance } from "@app/features/inventory/HierarchyForms/PublisherChannelForm/PublisherChannelFormInstance";

interface UsePublisherName {
    onChange: (e: CheckboxChangeEvent) => void;
    placeholder: string | undefined;
    disabled: boolean;
    extra: string;
}

export const usePublisherName = (): UsePublisherName => {
    const { useWatch, form, mode, channel } = usePublisherChannelFormInstance();
    const isEditMode = mode === "edit";
    const override = useWatch(CHANNEL_FORM_FIELDS.PUBLISHER_OVERRIDE.name);
    const onChange = (): void => form.setFieldValue(CHANNEL_FORM_FIELDS.PUBLISHER_NAME.name, "");

    const placeholder = isEditMode ? channel?.publisher?.code : CHANNEL_FORM_FIELDS.PUBLISHER_NAME.placeholder;
    const extra = "If no override is provided the publisher code will be sent in the request";
    return { extra, disabled: !override, onChange, placeholder };
};
