import { Layout } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { Footer } from "@app/core/components";
import { useHistory, useParams } from "react-router-dom";
import { FloorForm } from "../FloorForm";
import { ROUTE_FORMATTERS } from "@app/core/routing";

const InventoryFloorHierarchicalCreatePage = () => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();

    return (
        <Layout>
            <PageHeader
                title="Create Floor"
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_HIERARCHICAL(seatId))}
            />
            <Layout.Content>
                <FloorForm mode="create" />
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </Layout>
    );
};

export default InventoryFloorHierarchicalCreatePage;
