import { FC } from "react";
import { Form, Select } from "antd";
import { useCurrencyConversionModeField } from "./useCurrencyConversionModeField";

export const CurrencyConversionModeField: FC = () => {
    const { options, handleChangeValue, value } = useCurrencyConversionModeField();

    return (
        <Form.Item label="Currency Mode" colon={false} data-sdet="currency-conversion-mode">
            <Select
                style={{ width: "170px" }}
                onChange={handleChangeValue}
                value={value}
                optionFilterProp="label"
                options={options}
            />
        </Form.Item>
    );
};
