import { useMemo } from "react";
import { Select, SelectProps } from "antd";
import { useGetDealStatusQuery } from "@app/core/services";

const availableStatuses = new Set([1, 2]);

export const StatusField = (props: SelectProps) => {
    const { data, isFetching } = useGetDealStatusQuery();
    const options = useMemo(
        () =>
            (data || [])
                .filter((item) => availableStatuses.has(item.id))
                .map((item) => ({ label: item.name, value: item.id })),
        [data]
    );

    return (
        <Select
            {...props}
            data-sdet="legacy-status-field"
            labelInValue
            loading={isFetching}
            optionFilterProp="label"
            options={options}
            showSearch
        />
    );
};
