import { PageHeader } from "@ant-design/pro-layout";
import { Loading } from "@app/core/components";
import { Layout } from "antd";
import { useParams } from "react-router-dom";
import { BuyerSeatListsActions } from "../BuyerSeatListsActions";
import { BuyerSeatListsReadOnlyForm } from "../BuyerSeatListsForm";
import { BuyerSeatListsHelpKeys } from "../BuyerSeatListsHelpKeys";
import { useBuyerSeatListsDetailsPage } from "./useBuyerSeatListsDetailsPage";

export const BuyerSeatListsDetailsPage = () => {
    const { seatId, buyerSeatListId: id } = useParams<{ seatId: string; buyerSeatListId: string }>();
    const { name, data, isLoading, handleGoBack, postDelete } = useBuyerSeatListsDetailsPage(seatId, id);

    if (isLoading) {
        return <Loading />;
    }

    if (!data) {
        return null;
    }

    return (
        <>
            <PageHeader title={name} onBack={handleGoBack}>
                <BuyerSeatListsActions
                    seatId={seatId}
                    buyerSeatListId={Number(id)}
                    name={name}
                    postDeleteEvent={postDelete}
                />
            </PageHeader>
            <Layout.Content>
                <BuyerSeatListsReadOnlyForm buyerSeat={data}>
                    <BuyerSeatListsHelpKeys />
                </BuyerSeatListsReadOnlyForm>
            </Layout.Content>
        </>
    );
};
