import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ErrorResponse, useGetSeatContactQuery, useUpdateSeatContactMutation } from "@app/core/services";
import { ErrorPage, PageLoader } from "@rubicon/antd-components";
import { Layout, Typography, notification } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { SeatContactsForm } from "../SeatContactsForm/SeatContactsForm";
import { SeatContactPayload } from "../types";
import { PageHeader } from "@ant-design/pro-layout";

export const SEAT_CONTACTS_EDIT_PAGE_SDET = "seat-contacts-edit-page";
export const SeatContactsEditPage = () => {
    const history = useHistory();
    const { contactId, seatId } = useParams<{ contactId: string; seatId: string }>();
    const { data, isLoading, error } = useGetSeatContactQuery(contactId);
    const [updateSeatContact, { isLoading: isLoadingUpdate }] = useUpdateSeatContactMutation();

    if (isLoading) return <PageLoader />;
    if (error) {
        const { status, data: errorData } = error as ErrorResponse;
        return <ErrorPage code={status} title={errorData?.errorCode} message={errorData?.errorDescription} />;
    }
    const handleSubmit = (values: SeatContactPayload) => {
        const payload = {
            ...values,
            name: values?.name?.trim(),
        };
        updateSeatContact(payload)
            .unwrap()
            .then((res) => {
                notification.success({
                    message: (
                        <>
                            <Typography.Text strong>{res.name}</Typography.Text> has successfully been updated
                        </>
                    ),
                });
                history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_SEAT_CONTACT_DETAILS_PAGE(seatId, contactId));
            })
            .catch((err) => {
                notification.error({
                    message:
                        err.data?.errorDescription ||
                        err.data?.errorCode ||
                        "Something wrong has occurred, please contact your account manager",
                });
            });
    };

    return (
        <Layout data-sdet={SEAT_CONTACTS_EDIT_PAGE_SDET}>
            <PageHeader
                title={`Edit: ${data?.name}`}
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, "seat-contacts"))}
            />
            <SeatContactsForm isLoading={isLoadingUpdate} initialValues={data} handleSubmit={handleSubmit} />
        </Layout>
    );
};
