import { AD_SOURCE_FIELDS, AD_SOURCE_FIELDS_BY_SECTIONS, AD_SOURCE_SECTIONS } from "../../constants";
import { useState } from "react";

const isLabelVisible = (selector, title, elementType: string | string[] = "label") =>
    [
        ...document.querySelectorAll(
            typeof elementType === "string"
                ? `${selector} ${elementType}`
                : elementType.map((element) => `${selector} ${element}`).join(",")
        ),
    ].some((el) => el?.textContent?.toLowerCase() === title.toLowerCase());

export const useDefinitionPanel = (
    section: keyof typeof AD_SOURCE_SECTIONS,
    sectionSelector,
    elementType: string | string[] = "label",
    skipVisibilityCheck?
) => {
    const [definitionsFields, setDefinitionsFields] = useState<string[]>([]);
    const onPanelOpen = () => {
        const definitionsFields: string[] = AD_SOURCE_FIELDS_BY_SECTIONS[section].filter(
            (field: keyof typeof AD_SOURCE_FIELDS) =>
                skipVisibilityCheck || isLabelVisible(sectionSelector, AD_SOURCE_FIELDS[field]?.label, elementType)
        );

        if (definitionsFields.length) {
            setDefinitionsFields(definitionsFields);
        }
    };

    return {
        definitionsFields: definitionsFields || [],
        onPanelOpen,
    };
};
