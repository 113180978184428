import { FC } from "react";
import { Spin } from "antd";
import { css } from "@emotion/css";

interface LoadingProps {
    /**
     * Optional height to override the 100% value. Given as a percentage.
     */
    height?: number;

    /**
     * Optional position to override the 'absolute' value.
     */
    position?: string;
}

const Loading: FC<LoadingProps> = ({ height = 100, position = "absolute" }) => {
    const spinClasses = css`
        position: ${position};
        display: flex;
        flex-direction: column;
        width: 100%;
        height: ${height}%;
        align-items: center;
        justify-content: center;
    `;
    return <Spin className={spinClasses} size="large" />;
};

export default Loading;
