import { FC } from "react";
import { Select } from "antd";
import { PMP_CONDITION_OPTIONS, PmpConditions } from "../constants";

interface Props {
    onChange: (value: PmpConditions) => void;
    value: PmpConditions;
}

export const PmpConditionSelect: FC<Props> = ({ onChange, value }) => (
    <Select options={PMP_CONDITION_OPTIONS} onChange={onChange} style={{ width: "100%" }} value={value} />
);
