import { Last24HoursPerformanceStatsGraph } from "@app/features/inventory/InventoryHealthDetailsPages/SharedSections/Performance/Last24HoursPerformanceStatsGraph";
import { Button, Col, Flex, Space, Typography } from "antd";
import { useMemo } from "react";
import { useSnapshotsUnitContent } from "./useSnapshotsUnitContent";
import { InventoryDetailsDrawerType, selectInventoryDetailsDrawerType } from "../../../reducer";
import { StatsSnapshotViewer } from "./StatsSnapshotViewer";
import { useUnitStatsSnapshotMutation } from "@app/features/inventory/InventoryHealthDetailsPages/SharedSections/Performance/Snapshots/SnapshotsControls/useUnitStatsSnapshotMutation";
import { useAppSelector } from "@app/core/store";
import { DetailsDrawerLayout } from "../../../DetailsDrawerLayout";
import { Loading } from "@app/core/components";
import { css } from "@emotion/css";
import { useInventoryDetailsDrawerUrlId } from "../../../useInventoryDetailsDrawerUrlId";

export const SnapshotsUnitContent = () => {
    const unitId = useInventoryDetailsDrawerUrlId();
    const drawerType = useAppSelector(selectInventoryDetailsDrawerType) as InventoryDetailsDrawerType;
    const { adStatHistory, breadcrumbs, isLoading } = useSnapshotsUnitContent(drawerType, unitId);
    const { unitStatsSanpshotMutation, isLoading: snapshotIsLoading } = useUnitStatsSnapshotMutation();

    const graph = useMemo(() => {
        if (!adStatHistory) {
            return null;
        }
        return (
            <Last24HoursPerformanceStatsGraph
                adStat={adStatHistory || null}
                chartId="last-24-hours-performance-stats-snapshots"
            />
        );
    }, [adStatHistory]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Flex vertical data-sdet="inventory-snapshots-unit-content">
            <DetailsDrawerLayout title={"Snapshots"} breadcrumbs={breadcrumbs} />
            <Col
                span={24}
                className={css`
                    padding: 1.5rem;
                `}
            >
                <Space size="middle" style={{ marginBottom: "32px" }}>
                    <Typography.Text strong>Last 24 Hrs</Typography.Text>
                    <Button
                        loading={snapshotIsLoading}
                        onClick={() => unitStatsSanpshotMutation(drawerType, unitId, "")}
                    >
                        Take Snapshot
                    </Button>
                    {/* TODO: Commented out until download functionality is implemented */}
                    {/* <SnapshotsDownload /> */}
                </Space>
                {graph}
                {unitId && <StatsSnapshotViewer id={unitId} />}
            </Col>
        </Flex>
    );
};
