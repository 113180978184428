import { FC } from "react";
import { Card } from "antd";
import {
    FraudDetection,
    SupplyDomainList,
    AppBundleIdLists,
    AppBundleIDFilterMode,
    SupplyDomainFilterMode,
} from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm/ExchangeProtectionsFormSections/QualityControlSection/Fields";

export const QualityControlSection: FC = () => (
    <Card>
        <FraudDetection />
        <SupplyDomainFilterMode />
        <SupplyDomainList />
        <AppBundleIDFilterMode />
        <AppBundleIdLists />
    </Card>
);
