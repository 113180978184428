import { ChangeEvent, useState } from "react";
import {
    CAPPING_MODES,
    FCAP_TIME_OPTIONS,
} from "@app/features/seatAdSources/SeatAdSourcesForm/DealFormDrawer/DealFormContent/DealFormSections/DemandConfigurationSection/Fields/FrequencyCapping/constants";
import { string } from "@rubicon/utils";
import { FreqCapping } from "@app/features/deals/DealForm/types";
import { useAppDispatch } from "@app/core/store";
import { dealFormFrequencyCappingItemChange } from "@app/features/deals/DealForm/reducer";

interface UseAddFrequencyCappingItem {
    totalValue: string | number | readonly string[] | undefined;
    mode: keyof typeof CAPPING_MODES;
    disabled: boolean;
    hanldeSetMode: (mode: keyof typeof CAPPING_MODES) => void;
    handleSetTiming: (timing: keyof typeof FCAP_TIME_OPTIONS) => void;
    handleSetTotal: (e: ChangeEvent<HTMLInputElement>) => void;
    handleSetMinutes: (e: ChangeEvent<HTMLInputElement>) => void;
    minutesValue: string | number | readonly string[] | undefined;
    timingValue: keyof typeof FCAP_TIME_OPTIONS;
    handleAddItem: () => void;
}

export const useAddFrequencyCappingItem = (): UseAddFrequencyCappingItem => {
    const dispatch = useAppDispatch();
    const [mode, setMode] = useState<keyof typeof CAPPING_MODES>("IMPRESSIONS");
    const [timingValue, setTimingValue] = useState<keyof typeof FCAP_TIME_OPTIONS>(1);
    const [minutesValue, setMinutesValue] = useState<string | number | readonly string[] | undefined>("");
    const [totalValue, setTotalValue] = useState<string | number | readonly string[] | undefined>("");
    const handleSetTotal = (e: ChangeEvent<HTMLInputElement>): void => setTotalValue(e.target.value);
    const handleSetMinutes = (e: ChangeEvent<HTMLInputElement>): void => setMinutesValue(e.target.value);
    const hanldeSetMode = (mode: keyof typeof CAPPING_MODES): void => setMode(mode);
    const handleSetTiming = (timing: keyof typeof FCAP_TIME_OPTIONS): void => setTimingValue(timing);

    const disabled = !minutesValue || !timingValue;

    const handleAddItem = (): void => {
        const fcapItem: FreqCapping = {
            mode: CAPPING_MODES[mode],
            timeDurationMin: Number(minutesValue) * Number(timingValue),
            total: Number(totalValue),
            id: string.createUUID(),
        };
        dispatch(dealFormFrequencyCappingItemChange(fcapItem));
        setTotalValue("");
        setMinutesValue("");
    };

    return {
        totalValue,
        handleSetTotal,
        mode,
        disabled,
        hanldeSetMode,
        minutesValue,
        handleSetMinutes,
        timingValue,
        handleSetTiming,
        handleAddItem,
    };
};
