import { Form, InputNumber } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useDealImpressionsField } from "@app/features/deals/DealForm/DealDemandFormSections/DealPricingSection/Fields/DealImpressionsField/useDealImpressionsField";

export const DealImpressionsField = () => {
    const { value, onChange, handleBlur } = useDealImpressionsField();
    return (
        <Form.Item
            data-sdet="deal-impressions-field"
            label="Impressions"
            rules={[{ required: true, message: "Impressions is required" }]}
            name={CREATE_DEAL_FORM_ITEMS_NAME.IMPRESSION}
        >
            <InputNumber value={value} onChange={onChange} onBlur={handleBlur} style={{ width: "100%" }} />
        </Form.Item>
    );
};
