import { FC, useMemo } from "react";
import { ReadOnlyField } from "@app/core/components/Fields/ReadOnlyField";
import { getTimeZoneLabel, useGetTimeZonesQuery } from "@app/core/services/console";
import { format } from "@rubicon/utils";
import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";

export const AdSourceTimeZoneField: FC = () => {
    const { data } = useGetTimeZonesQuery();
    const timeZoneId = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_TIME_ZONE]
    );
    const timeZone = useMemo(
        () => (data ? data.find((timeZone) => timeZone.id === timeZoneId) : undefined),
        [data, timeZoneId]
    );

    return (
        <ReadOnlyField dataSdet="ad-source-time-zone-field" label="Time Zone">
            {timeZone ? getTimeZoneLabel(timeZone) : format.constants.DEFAULT_DASHES}
        </ReadOnlyField>
    );
};
