import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useHistory, useParams } from "react-router-dom";
import { BRAND_SAFETY_ADVANCEDL_KEY } from "../InventoryBrandSafety/InventoryBrandSafetyPage";
import { BrandSafetySectionKey } from "./helpKeysButton";

interface UseBrandSafetyDetailsDrawer {
    id: number;
    sectionKey?: BrandSafetySectionKey;
    handleBack: () => void;
    handleClose: () => void;
    handleEdit: (id: number) => void;
}

export const useBrandSafetyDetailsDrawer = (): UseBrandSafetyDetailsDrawer => {
    const { seatId, id, key } = useParams<{ seatId: string; id?: string; key?: BrandSafetySectionKey }>();
    const history = useHistory();

    const handleClose = () => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_TABS(seatId, BRAND_SAFETY_ADVANCEDL_KEY));
    };

    const handleEdit = (id: number) => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_EDIT(seatId, id));
    };

    const handleBack = () => history.goBack();

    return {
        id: Number(id),
        sectionKey: key,
        handleBack,
        handleEdit,
        handleClose,
    };
};
