import { Button, Col, Form, Input, Row, Space, Table, Tooltip } from "antd";
import { FC } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import {
    ClassificationsLabelValuesDeleteModal,
    useClassificationsLabelValuesDeleteModal,
} from "../ClassificationsLabelValuesDeleteModal";
import { useParams } from "react-router-dom";
import { useNotificationError } from "@app/core/components/hooks";
import { Label, useGetLabelValuesQuery } from "@app/core/services";
import { SearchOutlined } from "@ant-design/icons";
import useTableLocalSearch from "@app/core/components/hooks/useTableLocalSearch";
import { CLASSIFICATIONS_LABEL_FORM_ITEMS } from "../constants";
import { brandSlate } from "@rubicon/antd-components";

export const CLASSIFICATIONS_LABEL_FORM_LABEL_VALUES_TABLE_SDET = "classifications-label-form-label-values-table";

interface Props {
    label: Label;
}

export const ClassificationsLabelFormLabelValuesTable: FC<Props> = ({ label }) => {
    const { labelId } = useParams<{ labelId: string }>();
    const {
        data = [],
        isLoading: isLoadingLabelValues,
        error: labelValuesError,
    } = useGetLabelValuesQuery(Number(labelId));
    const { handleOpenDeleteOne, handleOpenDeleteAll, ...modalProps } = useClassificationsLabelValuesDeleteModal();
    const { dataSource, handleChange, value } = useTableLocalSearch([(labelValue) => labelValue.value], data);
    useNotificationError(labelValuesError);

    return (
        <>
            <Form.Item label={CLASSIFICATIONS_LABEL_FORM_ITEMS.ADDED_VALUES.label}>
                <Form.Item hidden name={CLASSIFICATIONS_LABEL_FORM_ITEMS.ADDED_VALUES.name} />
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Row justify="space-between">
                        <Col>
                            <Input
                                allowClear
                                data-sdet="label-values-table-search-input"
                                onChange={handleChange}
                                placeholder="Search Value…"
                                suffix={!value && <SearchOutlined style={{ color: brandSlate }} />}
                                value={value}
                            />
                        </Col>
                        <Col>
                            <Button
                                onClick={() =>
                                    handleOpenDeleteAll(
                                        data.map(({ id }) => id),
                                        label.key
                                    )
                                }
                                disabled={!data.length}
                            >
                                Delete All
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        data-sdet={CLASSIFICATIONS_LABEL_FORM_LABEL_VALUES_TABLE_SDET}
                        columns={[
                            {
                                key: "Value",
                                dataIndex: "value",
                                title: "Value",
                                sorter: (a, b) => a.value.localeCompare(b.value),
                            },
                            {
                                key: "Actions",
                                dataIndex: "actions",
                                title: "Actions",
                                render: (_, record) => {
                                    return (
                                        <Tooltip title="Delete">
                                            <Button
                                                onClick={() => handleOpenDeleteOne(record, label.key)}
                                                icon={<DeleteOutlined />}
                                            />
                                        </Tooltip>
                                    );
                                },
                                width: 100,
                                fixed: "right",
                            },
                        ]}
                        showSorterTooltip={false}
                        loading={isLoadingLabelValues}
                        dataSource={dataSource}
                        rowKey="id"
                    />
                </Space>
            </Form.Item>
            <ClassificationsLabelValuesDeleteModal {...modalProps} />
        </>
    );
};
