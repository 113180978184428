import { TitleAsLabel } from "@app/core/components/TitleInLabel";
import { brandAmethyst, gray10 } from "@rubicon/antd-components";
import { Badge, Card, Form, Space, Tabs } from "antd";
import { FC, useEffect, useState } from "react";
import {
    BULK_OPERATION_FORM_ITEMS,
    OPERATION_FIND_ENTITIES_TAB,
    OPERATION_SELECTED_ENTITIES_TAB,
} from "../../../constants";
import { startCase } from "lodash";
import { FindOperationEntitiesTab } from "./FindOperationEntitiesTab";
import { SelectedOperationEntitiesTable } from "./SelectedOperationEntitiesTable";
import { useSelector } from "react-redux";
import { BulkOperationEntityType } from "@app/core/services";
import {
    selectAdSourceSelectedRowKeys,
    selectAdUnitSelectedRowKeys,
    selectBuyerDealSelectedRowKeys,
    selectDemandDealSelectedRowKeys,
    selectIsAdSourceEntitySelectionTouched,
    selectIsAdUnitEntitySelectionTouched,
    selectIsBuyerDealEntitySelectionTouched,
    selectIsDemandDealEntitySelectionTouched,
} from "../../../reducer";

const getRowKeysSelector = (entityType: BulkOperationEntityType) => {
    switch (entityType) {
        case BulkOperationEntityType.AdSource:
            return selectAdSourceSelectedRowKeys;
        case BulkOperationEntityType.AdUnit:
            return selectAdUnitSelectedRowKeys;
        case BulkOperationEntityType.BuyerDeal:
            return selectBuyerDealSelectedRowKeys;
        case BulkOperationEntityType.DemandDeal:
            return selectDemandDealSelectedRowKeys;
    }
};

const getIsEntitySelectionTouchedSelector = (entityType: BulkOperationEntityType) => {
    switch (entityType) {
        case BulkOperationEntityType.AdSource:
            return selectIsAdSourceEntitySelectionTouched;
        case BulkOperationEntityType.AdUnit:
            return selectIsAdUnitEntitySelectionTouched;
        case BulkOperationEntityType.BuyerDeal:
            return selectIsBuyerDealEntitySelectionTouched;
        case BulkOperationEntityType.DemandDeal:
            return selectIsDemandDealEntitySelectionTouched;
    }
};

const SelectedRowsCount: FC<{ selectedRowsCount: number; isActive: boolean }> = ({ selectedRowsCount, isActive }) => {
    return (
        <Badge
            data-sdet="selected-entities-count"
            size="small"
            count={selectedRowsCount}
            style={{
                backgroundColor: isActive ? brandAmethyst : gray10,
                marginTop: "-3px",
            }}
        />
    );
};

export const BulkOperationEntitySelectSection: FC<{ entityType: BulkOperationEntityType }> = ({ entityType }) => {
    const form = Form.useFormInstance();
    const [currentTab, setCurrentTab] = useState(OPERATION_FIND_ENTITIES_TAB);
    const rowKeysSelector = getRowKeysSelector(entityType);
    const selectedRowsKeys = useSelector(rowKeysSelector);

    const isEntitySelectionTouchedSelector = getIsEntitySelectionTouchedSelector(entityType);
    const isEntitySelectionTouched = useSelector(isEntitySelectionTouchedSelector);

    useEffect(() => {
        isEntitySelectionTouched &&
            form.validateFields([BULK_OPERATION_FORM_ITEMS.OPERATION_ENTITIES_SELECT_SECTION_ERROR_MESSAGE.name]);
    }, [isEntitySelectionTouched, selectedRowsKeys, form]);

    return (
        <Card>
            <Form.Item
                required
                name={BULK_OPERATION_FORM_ITEMS.OPERATION_ENTITIES_SELECT_SECTION_ERROR_MESSAGE.name}
                rules={[
                    {
                        validator: () =>
                            selectedRowsKeys.length
                                ? Promise.resolve()
                                : Promise.reject(`Please select at least one ${startCase(entityType)} to edit`),
                    },
                ]}
                style={{ marginBottom: 0 }}
            >
                <TitleAsLabel
                    required
                    label={`Select ${startCase(entityType)}s to Edit`}
                    style={{ display: "contents" }}
                />
            </Form.Item>
            <Tabs
                activeKey={currentTab}
                onChange={setCurrentTab}
                items={[
                    {
                        label: (
                            <span data-sdet="find-entities-tab" style={{ fontSize: 16 }}>{`Find ${startCase(
                                entityType
                            )}s`}</span>
                        ),
                        key: OPERATION_FIND_ENTITIES_TAB,
                        children: <FindOperationEntitiesTab entityType={entityType} setCurrentTab={setCurrentTab} />,
                    },
                    {
                        label: (
                            <Space size={4} data-sdet="selected-entities-tab">
                                <span style={{ fontSize: 16 }}>Selected</span>
                                {entityType && (
                                    <SelectedRowsCount
                                        selectedRowsCount={selectedRowsKeys.length}
                                        isActive={currentTab === OPERATION_SELECTED_ENTITIES_TAB}
                                    />
                                )}
                            </Space>
                        ),
                        key: OPERATION_SELECTED_ENTITIES_TAB,
                        children: <SelectedOperationEntitiesTable />,
                    },
                ]}
            />
        </Card>
    );
};
