import { HelpKeysDrawer, HelpKeysDrawerContent } from "@app/core/components/HelpKeysDrawer";

export const CLASSIFICATIONS_ADVERTISERS_DEFINITION_FIELDS = [
    { label: "Name", key: "name", helpPath: "advertisers.name" },
    { label: "Advertiser Code", key: "code", helpPath: "advertisers.code" },
    {
        label: "Description",
        key: "description",
        helpPath: "advertisers.description",
    },
    { label: "Parent Advertiser", key: "parentAdvertiser", helpPath: "advertisers.parentadvertiser" },
    { label: "Advertiser Domains", key: "domains", helpPath: "advertisers.domains" },
    { label: "Industry", key: "industry", helpPath: "advertisers.industry" },
];

export const ClassificationsAdvertiserDefinitionsContent = () => (
    <HelpKeysDrawerContent definitionsFields={CLASSIFICATIONS_ADVERTISERS_DEFINITION_FIELDS} />
);

export const ClassificationsAdvertiserDefinitionsButtonAndDrawer = () => (
    <HelpKeysDrawer
        buttonName="Advertisers"
        panelLabel="Advertisers Definitions"
        definitionsFields={CLASSIFICATIONS_ADVERTISERS_DEFINITION_FIELDS}
    />
);
