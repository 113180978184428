import { useEffect, useMemo } from "react";
import { matchPath, useHistory } from "react-router-dom";
import { notification } from "antd";
import { ROUTES } from "@app/core/routing/routes";
import { useLazyGetSeatAccessQuery, useLazyGetSeatSelfQuery } from "@app/core/services";
import { useAppDispatch } from "@app/core/store";
import { setIsLoadingSeatAccess, setIsLoadingSeatContext, setSeatAccess, setSeatContext } from "./reducer";

export const useWatchSeatPermissions = (): void => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [getSeatSelf] = useLazyGetSeatSelfQuery();
    const [getSeatAccess] = useLazyGetSeatAccessQuery();

    const pathSeatId = useMemo(
        () =>
            Number(
                matchPath<{ seatId: string }>(history.location.pathname, {
                    path: "/seats/:seatId",
                })?.params?.seatId
            ),
        [history.location.pathname]
    );

    useEffect(() => {
        if (!pathSeatId) {
            return;
        }

        (async () => {
            dispatch(setIsLoadingSeatContext(true));
            const seatSelfSeatData = (await getSeatSelf(pathSeatId)).data;
            dispatch(setSeatContext(seatSelfSeatData));

            if (!seatSelfSeatData?.seat) {
                // A seat route was hit while the user is not logged in. Do nothing here and return because <SessionRequiredRoute /> in
                // <AppSwitch /> will redirect to the login form with the appropriate redirect parameter.
                return;
            }

            if (seatSelfSeatData.seat.id !== pathSeatId) {
                notification.error({ message: "You do not have access to this seat" });
                history.push(ROUTES.LOGIN_FORM_CONTEXT_STEP);
                return;
            }

            dispatch(setIsLoadingSeatAccess(true));
            const seatAccessData = (await getSeatAccess(pathSeatId)).data;
            dispatch(setSeatAccess(seatAccessData));
        })();
    }, [pathSeatId, history, dispatch, getSeatAccess, getSeatSelf]);
};
