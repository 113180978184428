import { FC } from "react";
import { AdSourceListItem } from "@app/core/services";
import { getRateTypeToBeDisplayed } from "./getRateTypeToBeDisplayed";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { formatNumber } from "@rubicon/utils";
import { Dashes } from "@app/core/components";

interface Props {
    currencyCode: string;
    cpmRate: number;
    adSource: AdSourceListItem;
}
export const SeatAdSourcesCpmRate: FC<Props> = ({ cpmRate, currencyCode, adSource }) => {
    const rateType = getRateTypeToBeDisplayed(adSource);

    if (rateType === "--") {
        return <Dashes />;
    }

    if (rateType === "CpmDown") {
        return (
            <span style={{ color: "#1890FF" }}>
                {formatNumber.asMoneyVerbose(cpmRate / 1000, currencyCode)}
                <Space />
                <ArrowDownOutlined />
            </span>
        );
    }
    if (rateType === "CpmUp") {
        return (
            <span style={{ color: "#1890FF" }}>
                {formatNumber.asMoneyVerbose(cpmRate / 1000, currencyCode)}
                <Space />
                <ArrowUpOutlined />
            </span>
        );
    }
    if (rateType === "PercentUp") {
        return (
            <span style={{ color: "#1890FF" }}>
                {formatNumber.asPercent(cpmRate, -3)}
                <Space />
                <ArrowUpOutlined />
            </span>
        );
    }
    if (rateType === "PercentDown") {
        return (
            <span style={{ color: "#1890FF" }}>
                {formatNumber.asPercent(cpmRate, -3)}
                <Space />
                <ArrowDownOutlined />
            </span>
        );
    }

    return <span style={{ color: "#1890FF" }}>{formatNumber.asMoneyVerbose(cpmRate / 1000, currencyCode)}</span>;
};
