import { StoreValue } from "rc-field-form/lib/interface";

interface UseAdSourceMaxDurationField {
    validator: (minDuration: StoreValue, value: StoreValue) => Promise<void>;
}

export const useAdSourceMaxDurationField = (): UseAdSourceMaxDurationField => {
    const validator = (minDuration: StoreValue, value: StoreValue): Promise<void> => {
        if (value != null && Number(minDuration) > Number(value))
            return Promise.reject(new Error("Max Duration can't be less than Min Duration"));
        return Promise.resolve();
    };

    return {
        validator,
    };
};
