import { FC } from "react";
import { useErrorFeedback } from "./useErrorFeedback";
import { ErrorPage } from "@rubicon/antd-components";
import { Link } from "react-router-dom";

interface Props {
    error: Error;
    resetErrorState: () => void;
}

export const ErrorBoundaryPage: FC<Props> = ({ error, resetErrorState }) => {
    useErrorFeedback(error);

    return (
        <div style={{ height: "100vh" }}>
            <ErrorPage
                title="Something went wrong"
                message="An unknown error occured. The issue has been logged, and the support team has been notified."
                extra={
                    <Link to="/" onClick={resetErrorState}>
                        Go Back
                    </Link>
                }
            />
        </div>
    );
};
