import { consoleApi, UDE_LID_DISCOVERY_MODES_TAG } from "./console";

export interface UdeLidDiscoveryMode {
    creationDate: string;
    entityType: "UdeLidDiscoveryMode";
    id: number;
    name: string;
    sort: number;
}

export const udeLidDiscoveryModesApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getUdeLidDiscoveryModes: builder.query<UdeLidDiscoveryMode[], void>({
            query: () => "udeLidDiscoveryModes",
            providesTags: [UDE_LID_DISCOVERY_MODES_TAG],
        }),
    }),
});

export const { useGetUdeLidDiscoveryModesQuery } = udeLidDiscoveryModesApi;
