import { Col, Row, Space } from "antd";
import { FC, useState } from "react";
import { PerformanceStatsTypeButtons } from "@app/features/inventory/InventoryHealthDetailsPages/SharedSections/PerformanceStatsTypeButtons";
import { DealsLiveChart } from "@app/features/deals/DealChartDrawer/DealCharts/DealsLiveChart/DealsLiveChart";
import { DealsPerformanceMetrics } from "../DealsPerformanceMetrics";

interface Props {
    dealId: number;
    sourceCurrencyCode?: string | undefined;
    handlePerformanceTypeSwitch: (isLiveActive: boolean) => void;
    isInsideDrawer?: boolean;
}

export const LiveStats: FC<Props> = ({ dealId, sourceCurrencyCode, handlePerformanceTypeSwitch, isInsideDrawer }) => {
    const [isExtended, setIsExtended] = useState(false);

    const handleExtend = () => {
        setIsExtended(!isExtended);
    };

    return (
        <Space direction="vertical" size={50} style={{ display: "flex" }}>
            <Row gutter={[16, 16]}>
                <Col span={isExtended ? 12 : 6}>
                    <DealsPerformanceMetrics
                        dealId={dealId}
                        sourceCurrencyCode={sourceCurrencyCode}
                        handleExtend={handleExtend}
                        isExtended={isExtended}
                    />
                </Col>
                <Col span={isExtended ? 12 : 18}>
                    <Row
                        justify={"space-between"}
                        style={{
                            margin: "0 0 16px 0",
                            padding: "0 16px",
                        }}
                    >
                        <PerformanceStatsTypeButtons handlePerformanceTypeSwitch={handlePerformanceTypeSwitch} />
                    </Row>
                    <DealsLiveChart
                        chartId={dealId ? (isInsideDrawer ? "drawer-direct" : "direct") : "nested"}
                        dealId={dealId}
                        sourceCurrencyCode={sourceCurrencyCode}
                    />
                </Col>
            </Row>
        </Space>
    );
};
