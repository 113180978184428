import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { uri } from "@rubicon/utils";
import { SearchParam } from "..";
import { TablePaginationConfig } from "antd";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../constants";

type TablePaginationPosition = "topLeft" | "topCenter" | "topRight" | "bottomLeft" | "bottomCenter" | "bottomRight";

interface UsePagination {
    handleChange: (page: number, pageSize: number) => void;
    paginationConfig: TablePaginationConfig;
}
interface UsePaginationParams {
    pageParam?: SearchParam;
    pageSizeParam?: SearchParam;
    position?: TablePaginationPosition[];
    totalCount: number;
    showTotal?: boolean;
}

export const PAGE: SearchParam = { key: "page", default: "1" };
export const SIZE: SearchParam = { key: "size", default: "25" };

export const usePagination = ({
    pageParam = PAGE,
    pageSizeParam = SIZE,
    totalCount,
    position, // only used when need to config table builtin pagination
    showTotal = true,
}: UsePaginationParams): UsePagination => {
    const history = useHistory();
    const { search } = useLocation();
    const curPage = Number(uri.getSearchParam(search, pageParam.key) || pageParam.default);
    const curPageSize = Number(uri.getSearchParam(search, pageSizeParam.key) || pageSizeParam.default);
    useEffect(() => {
        if (curPage !== 1 && totalCount && Math.ceil(totalCount / curPageSize) < curPage) {
            const nextSearchPage = uri.setSearchParam(search, pageParam.key, pageParam.default);
            history.push({ search: nextSearchPage });
        }
    }, [history, search, curPageSize, totalCount, curPage, pageParam.key, pageParam.default]);

    const handleChange = (page: number, pageSize: number) => {
        const nextSearchPage = uri.setSearchParam(search, pageParam.key, String(page));
        const nextSearchPageSize = uri.setSearchParam(nextSearchPage, pageSizeParam.key, String(pageSize));

        return history.push({ search: nextSearchPageSize });
    };

    return {
        handleChange,
        paginationConfig: {
            current: curPage,
            pageSize: curPageSize,
            position: position || ["bottomRight"],
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            defaultPageSize: DEFAULT_PAGE_SIZE,
            showTotal: (total) => (showTotal ? `${total} Total` : null),
            showSizeChanger: Boolean(totalCount),
            total: totalCount,
        },
    };
};
