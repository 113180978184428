import { ApiChangeLog } from "./changeLogs";
import { ENTITY_CLASS_TYPES } from "./constants";
import {
    formatBlockedDomains,
    formatDomainFilters,
    formatEntityWithName,
    formatMetaDataVideoId,
    formatTvSeries,
    formatUserGroupMemberChange,
    formatUserRole,
    geoResultFormatter,
    formatBundleIdFilters,
    getSimpleName,
} from "./legacyUtils";
import { ChangeLogField, ChangeLogFields } from "./types";
import {
    getAttributeName,
    softIncludes,
    parseChangeLogChanges,
    getAttributeMetaData,
    AttributeMetaData,
} from "./utils";

export const getChangeLogEntityName = (apiLog: ApiChangeLog): string => {
    const elementName = apiLog.element?.name;
    const lineageFloorName =
        apiLog.elementType === ENTITY_CLASS_TYPES.FLOOR
            ? `${"Floor for"} ${getSimpleName(apiLog.lineage?.type)} ${apiLog.lineage?.name}`
            : null;
    return elementName ?? lineageFloorName ?? "n/a";
};

export const getChangeLogUser = (apiLog: ApiChangeLog): string => {
    if (apiLog.realUserId) {
        return `${apiLog.userId} (${apiLog.realUserId})`;
    } else {
        return apiLog.userId;
    }
};

export const getNukeStatusChange = (apiChange: ChangeLogField) => {
    const { attribute, current } = apiChange;
    const isUnNuked = softIncludes(current, "un-nuked");
    return {
        attribute: getAttributeName(attribute),
        previous: isUnNuked ? "1" : "0",
        current: !isUnNuked ? "0" : "1", // TODO - use action text here to match legacy code?
    };
};

export const getGeoChange = (apiChange: ChangeLogField, meta: AttributeMetaData) => {
    const { previous, current } = apiChange;
    const { isMarketplaceInfo, isIncludeOrExclude, isInclude, isExclude } = meta;

    const attributeText: string[] = [];
    if (isMarketplaceInfo && isIncludeOrExclude) {
        attributeText.push("Marketplace Info");
    }

    if (isInclude) {
        attributeText.push("Included");
    } else if (isExclude) {
        attributeText.push("Excluded");
    }

    attributeText.push("Geo");
    return {
        attribute: attributeText.join(" "),
        previous: geoResultFormatter(previous),
        current: geoResultFormatter(current),
    };
};

export const getUserRoleChange = (apiChange: ChangeLogField) => {
    const { attribute, previous, current } = apiChange;
    return {
        attribute: getAttributeName(attribute),
        previous: formatUserRole(previous),
        current: formatUserRole(current),
    };
};

export const getTargetingChange = (apiChange: ChangeLogField, meta: AttributeMetaData) => {
    const { attribute, previous, current } = apiChange;
    const { isIncludeOrExclude, isMarketplaceInfo } = meta;

    if (isIncludeOrExclude) {
        const marketplaceInfoText = isMarketplaceInfo ? "Marketplace Info" : "";
        const attributeText = `${marketplaceInfoText}${getAttributeName(attribute)}`;
        return {
            attribute: attributeText,
            previous: formatEntityWithName(previous),
            current: formatEntityWithName(current),
        };
    } else {
        return {
            attribute: getAttributeName(attribute),
            previous,
            current,
        };
    }
};

export const getMarketplaceInfoChange = (apiChange: ChangeLogField) => {
    const { attribute, previous, current } = apiChange;
    return {
        attribute: getAttributeName(attribute),
        previous: formatEntityWithName(previous),
        current: formatEntityWithName(current),
    };
};

export const getNetworkEndpointChange = (apiChange: ChangeLogField, meta: AttributeMetaData) => {
    const { attribute, previous, current } = apiChange;
    const { isNetworkEndpoints, isNetworkUrl } = meta;

    let attributeText = "";
    if (isNetworkEndpoints) {
        attributeText = "Endpoint";
    } else if (isNetworkUrl) {
        attributeText = "Demand";
    } else {
        attributeText = getAttributeName(attribute);
    }

    return {
        attribute: attributeText,
        previous: formatEntityWithName(previous),
        current: formatEntityWithName(current),
    };
};

export const getBlockDomainsChange = (apiChange: ChangeLogField) => {
    const { previous, current } = apiChange;
    return {
        attribute: "Block Domains",
        previous: formatBlockedDomains(previous),
        current: formatBlockedDomains(current),
    };
};

export const getPriorityChange = (apiChange: ChangeLogField) => {
    const { previous, current } = apiChange;
    return {
        attribute: "Priority",
        previous,
        current,
    };
};

export const getAffiliateCostValueFixedChange = (apiChange: ChangeLogField) => {
    const { attribute, previous, current } = apiChange;
    return {
        attribute: getAttributeName(attribute),
        previous,
        current: String(parseInt(current) / 1000),
    };
};

export const getUserGroupMemberChange = (apiChange: ChangeLogField) => {
    const { attribute, previous, current } = apiChange;
    return {
        attribute: getAttributeName(attribute),
        previous: formatUserGroupMemberChange(previous),
        current: formatUserGroupMemberChange(current),
    };
};

export const getContentSeriesChange = (apiChange: ChangeLogField) => {
    const { attribute, previous, current } = apiChange;
    return {
        attribute: getAttributeName(attribute),
        previous: formatTvSeries(previous),
        current: formatTvSeries(current),
    };
};

export const getContentVideoIdChange = (apiChange: ChangeLogField) => {
    const { attribute, previous, current } = apiChange;
    return {
        attribute: getAttributeName(attribute),
        previous: formatMetaDataVideoId(previous),
        current: formatMetaDataVideoId(current),
    };
};
export const getDomainFilterChange = (apiChange: ChangeLogField) => {
    const { attribute, previous, current } = apiChange;
    return {
        attribute: getAttributeName(attribute),
        previous: formatDomainFilters(previous),
        current: formatDomainFilters(current),
    };
};

export const getBundleIdFiltersChange = (apiChange: ChangeLogField) => {
    const { previous, current } = apiChange;
    return {
        attribute: "Bundle Id Filters",
        previous: formatBundleIdFilters(previous),
        current: formatBundleIdFilters(current),
    };
};
export const getBlockedChange = (apiChange: ChangeLogField) => {
    const { attribute, previous, current } = apiChange;
    // TODO - check simple name here to match legacy code?
    return {
        attribute: getAttributeName(attribute),
        previous,
        current, // TODO - append user message to match legacy code?
    };
};

export const getDefaultChange = (apiChange: ChangeLogField) => {
    const { attribute, previous, current } = apiChange;
    return {
        attribute: getAttributeName(attribute),
        previous,
        current,
    };
};

export const getChangeLogChange = (apiChange: ChangeLogField): ChangeLogField => {
    const apiChangeMetaData = getAttributeMetaData(apiChange.attribute);
    const {
        isNukeStatus,
        isGeo,
        isUserRole,
        isTargeting,
        isMarketplaceInfo,
        isNetworkEndpoint,
        isBlockDomains,
        isPriority,
        isAffiliateCostValueFixed,
        isUserGroupMemberChange,
        isContentSeries,
        isContentVideoId,
        isDomainFilter,
        isBundleIdFilters,
        isBlocked,
    } = apiChangeMetaData;

    if (isNukeStatus) return getNukeStatusChange(apiChange);
    else if (isGeo) return getGeoChange(apiChange, apiChangeMetaData);
    else if (isUserRole) return getUserRoleChange(apiChange);
    else if (isTargeting) return getTargetingChange(apiChange, apiChangeMetaData);
    else if (isMarketplaceInfo) return getMarketplaceInfoChange(apiChange);
    else if (isNetworkEndpoint) return getNetworkEndpointChange(apiChange, apiChangeMetaData);
    else if (isBlockDomains) return getBlockDomainsChange(apiChange);
    else if (isPriority) return getPriorityChange(apiChange);
    else if (isAffiliateCostValueFixed) return getPriorityChange(apiChange);
    else if (isUserGroupMemberChange) return getUserGroupMemberChange(apiChange);
    else if (isContentSeries) return getContentSeriesChange(apiChange);
    else if (isContentVideoId) return getContentVideoIdChange(apiChange);
    else if (isDomainFilter) return getDomainFilterChange(apiChange);
    else if (isBundleIdFilters) return getBundleIdFiltersChange(apiChange);
    else if (isBlocked) return getBlockedChange(apiChange);
    else return getDefaultChange(apiChange);
};

export const getChangeLogChanges = (apiLog: ApiChangeLog): ChangeLogFields => {
    const changes = parseChangeLogChanges(apiLog);

    const nonPasswordChanges = changes.filter((change: ChangeLogField) => !softIncludes(change.attribute, "password"));
    const mappedChanges = nonPasswordChanges
        .map((change: ChangeLogField) => {
            try {
                return getChangeLogChange(change);
            } catch (e) {
                console.error("Failed to map change log entry.", e);
                return null;
            }
        })
        .filter((change) => change) as ChangeLogFields;

    return mappedChanges;
};
