import { useGetAvailableNetworksQuery, useGetExtendedIdsQuery } from "@app/core/services";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { Form } from "antd";
import { useParams } from "react-router-dom";

const { useFormInstance } = Form;

export const useExtendedUserIdItem = (name: number) => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data: extendedIds = [], isFetching: isExtendedIdsFetching } = useGetExtendedIdsQuery(Number(seatId));
    const { setFieldValue } = useFormInstance<AdSourcesForm>();

    const selectedExtendedId = Form.useWatch<{ code: string }>([
        AD_SOURCE_FIELDS.EXTENDED_USER_ID.name,
        name,
        "extendedId",
    ]);

    const handleExtendedIdChange = () => {
        setFieldValue([AD_SOURCE_FIELDS.EXTENDED_USER_ID.name, name, "network"], null);
    };

    const { data: availableNetworks = [], isFetching: isAvailableNetworksFetching } = useGetAvailableNetworksQuery(
        {
            seatId: Number(seatId),
            extendedId: selectedExtendedId?.code,
        },
        { skip: !selectedExtendedId }
    );
    return {
        extendedIds,
        availableNetworks,
        isExtendedIdsFetching,
        isAvailableNetworksFetching,
        isNetworksFieldDisabled: !selectedExtendedId,
        handleExtendedIdChange,
    };
};
