import { FC } from "react";
import { Col, Row, Typography } from "antd";
import { CustomRuleIdentiferApi } from "@app/features/targeting/types";
import {
    getCustomRuleIdLabel,
    getCustomRuleOperatorLabel,
} from "../../CreateTargeting/DimensionRows/CustomRulesDimensionRow/helpers";
import { CustomRuleOperators } from "../../constants";
import { Dashes } from "@app/core/components";

interface Props {
    rule: CustomRuleIdentiferApi;
}

export const CustomRuleIdentifierRow: FC<Props> = ({ rule }) => {
    const idLabel = getCustomRuleIdLabel(rule.id);
    const operatorLabel = getCustomRuleOperatorLabel(rule.operator);

    return (
        <Row gutter={[8, 8]}>
            <Col>
                <Typography.Text>
                    {idLabel} {operatorLabel}{" "}
                    {![CustomRuleOperators.IsNull, CustomRuleOperators.IsNotNull].includes(rule.operator) &&
                        (rule.value ? (
                            Array.isArray(rule.value) ? (
                                rule.value.map((value, i, col) => (
                                    <>
                                        <Typography.Text strong key={`${value}-${i}`}>
                                            {value}
                                        </Typography.Text>
                                        {i < col.length - 1 && <Typography.Text>, </Typography.Text>}
                                    </>
                                ))
                            ) : (
                                <Typography.Text strong>{rule.value}</Typography.Text>
                            )
                        ) : (
                            <Dashes />
                        ))}
                </Typography.Text>
            </Col>
        </Row>
    );
};
