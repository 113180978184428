import { FC } from "react";
import { Form, Collapse } from "antd";
import { Loading } from "@app/core/components";
import { Title } from "@app/core/components/Title";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import {
    LabelsSection,
    GeneralSection,
    FinancialSection,
    BrandSafetySection,
    CustomPixelsSection,
    SeatDefaultsSection,
} from "@app/features/inventory/InventorySeat/SeatDetailsPage/SeatDetailsSection";
import { AffixFormButtonsBar } from "@app/features/inventory/components/AffixFormButtonsBar";
import { INVENTORY_HEADER_COLLAPSE_CLASS_LIST } from "@app/features/inventory/InventorySeat/constants";
import { useSeatDetailsForm } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/useSeatDetailsForm";
import { useSeatDetailsDefenitionsFields } from "@app/features/inventory/InventorySeat/SeatDetailsPage/useSeatDetailsDefenitionsFields";

export const SeatDetailsForm: FC = () => {
    const { form, initialValues, seat, supplySeat, isLoading, handleCancel, handleSubmit, isUpdating } =
        useSeatDetailsForm();
    const {
        labelsDefinitionsFields,
        generalDefinitionsFields,
        financialDefinitionsFields,
        seatDefaultsDefinitionsFields,
        brandSafetyDefinitionsFields,
        customPixelsDefinitionsFields,
    } = useSeatDetailsDefenitionsFields();

    if (isLoading || !seat) return <Loading />;

    return (
        <Form
            form={form}
            layout="vertical"
            name="seatDetailsForm"
            initialValues={initialValues}
            scrollToFirstError={{ behavior: "smooth", block: "center" }}
        >
            <Collapse
                bordered
                ghost={false}
                className={INVENTORY_HEADER_COLLAPSE_CLASS_LIST}
                defaultActiveKey={["0", "1", "2", "3", "4", "5"]}
                items={[
                    {
                        key: "0",
                        children: <GeneralSection seat={seat} supplySeat={supplySeat} edit />,
                        label: <Title level={5} title="General" />,
                        extra: <HelpKeysDrawer panelLabel="General" definitionsFields={generalDefinitionsFields} />,
                    },
                    {
                        key: "1",
                        children: <FinancialSection seat={seat} edit />,
                        label: <Title level={5} title="Financial" />,
                        extra: <HelpKeysDrawer panelLabel="Financial" definitionsFields={financialDefinitionsFields} />,
                    },
                    {
                        key: "2",
                        children: <SeatDefaultsSection seat={seat} edit />,
                        label: <Title level={5} title="Seat Defaults" />,
                        extra: (
                            <HelpKeysDrawer
                                panelLabel="Seat Defaults"
                                definitionsFields={seatDefaultsDefinitionsFields}
                            />
                        ),
                    },
                    {
                        key: "3",
                        children: <BrandSafetySection seat={seat} edit />,
                        label: <Title level={5} title="Brand Safety" />,
                        extra: (
                            <HelpKeysDrawer
                                panelLabel="Brand Safety"
                                definitionsFields={brandSafetyDefinitionsFields}
                            />
                        ),
                    },
                    {
                        key: "4",
                        children: <CustomPixelsSection seat={seat} edit />,
                        label: <Title level={5} title="Custom Pixels" />,
                        extra: (
                            <HelpKeysDrawer
                                panelLabel="Custom Pixels"
                                definitionsFields={customPixelsDefinitionsFields}
                            />
                        ),
                    },
                    {
                        key: "5",
                        children: <LabelsSection seat={seat} edit />,
                        label: <Title level={5} title="Labels" />,
                        extra: <HelpKeysDrawer panelLabel="Labels" definitionsFields={labelsDefinitionsFields} />,
                    },
                ]}
            />
            <AffixFormButtonsBar
                loading={isUpdating}
                disabled={isUpdating}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                submitButtonTitle="Save"
            />
        </Form>
    );
};
