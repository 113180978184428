import { FC } from "react";
import { Form, Typography } from "antd";
import { gray10 } from "@rubicon/antd-components";

interface Props {
    index: number;
    name?: string | null;
    isNewTargetingBlock?: boolean;
}

export const TargetingBlockHeader: FC<Props> = ({ index, name, isNewTargetingBlock }) => {
    const targeting = Form.useWatch("targeting");
    const targetingBlockIndex = targeting?.length > 0 && isNewTargetingBlock ? targeting.length + index + 1 : index + 1;
    const header = name || `Targeting Group ${targetingBlockIndex}`;

    return <Typography.Text style={{ color: gray10, fontSize: "16px" }}>{header}</Typography.Text>;
};
