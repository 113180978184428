import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useEffect } from "react";
import { closeDrawer, closeDrawerWithoutType, selectIsPageInventoryDetailsDrawer } from "../reducer";

export const NoDrawerContent = () => {
    const dispatch = useAppDispatch();
    const isUnitPage = useAppSelector(selectIsPageInventoryDetailsDrawer);

    useEffect(() => {
        if (isUnitPage) {
            dispatch(closeDrawerWithoutType());
            return;
        }
        dispatch(closeDrawer());
    }, [dispatch, isUnitPage]);
    return null;
};
