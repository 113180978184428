import { formatDateTime, formatEntity } from "@app/core/components/ChangeHistoryTable/ChangeLogTable";
import { NONE } from "@app/core/components/constants";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ChangeLogField } from "@app/core/services";
import { BRAND_SAFETY_ADVANCEDL_KEY } from "@app/features/inventory/InventoryBrandSafety/InventoryBrandSafetyPage";
import { INVENTORY_FLOORS_ADVANCED } from "@app/features/inventory/InventoryFloors";
import { Table, TableColumnsType, Tag } from "antd";
import { Link } from "react-router-dom";

const tagColorMap = {
    created: "success",
    changed: "warning",
    deleted: "error",
};

const getToEntityLink = (elementType: string, seatId: string, recordId: string, lineage: string) => {
    const entity = formatEntity(elementType);
    const parent = JSON.parse(lineage);
    const parentEntity = formatEntity(parent?.type || "");

    switch (entity) {
        case "Advertiser":
            return ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISER_DETAILS_PAGE(seatId, recordId);
        case "Ad Source":
            return ROUTE_FORMATTERS.SEAT_AD_SOURCES_DETAILS(seatId, recordId);
        case "Buyer Deal":
        case "Demand Deal":
        case "Deal":
            return ROUTE_FORMATTERS.SEAT_DEAL_DETAILS(seatId, recordId);
        case "Ad Source":
            return ROUTE_FORMATTERS.SEAT_AD_SOURCES_DETAILS(seatId, recordId);
        case "Seat":
            return ROUTE_FORMATTERS.SEAT_INVENTORY_SEAT_DETAILS(seatId);
        case "Advanced Floor":
            return ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_TABS(seatId, INVENTORY_FLOORS_ADVANCED); // currently does not support details uri
        case "Advanced Brand Safety":
            return ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_TABS(seatId, BRAND_SAFETY_ADVANCEDL_KEY); // currently does not support details drawer uri
        case "Industry":
            return ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_DETAILS_PAGE(seatId, recordId);
        case "Brand Safety":
            switch (parentEntity) {
                case "Publisher":
                    return ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_PUBLISHER(seatId, parent.id);
                case "Brand":
                    return ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_BRAND(seatId, parent.id);
                case "Supply":
                    return ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_SUPPLY(seatId, parent.id);
                case "Ad Unit":
                    return ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_AD_UNIT(seatId, parent.id);
            }
        case "Floor":
            switch (parentEntity) {
                case "Publisher":
                    return ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_PUBLISHER(seatId, parent.id);
                case "Brand":
                    return ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_PUBLISHER_BRAND(seatId, parent.id);
                case "Supply":
                    return ROUTE_FORMATTERS.SEAT_INVENTORY_SUPPLY_FLOORS(seatId, parent.id);
                case "Ad Unit":
                    return ROUTE_FORMATTERS.SEAT_INVENTORY_AD_UNITS_FLOORS(seatId, parent.id);
            }
        default:
            return "";
    }
};

export const getAuditLogLabel = (element, lineage) => {
    return element?.name || JSON.parse(lineage)?.name || NONE;
};

export const getAuditLogColumns = (seatId: string) => [
    {
        key: "name",
        dataIndex: "name",
        title: "Entity Name",
        render: (_, { element, elementType, elementId, lineage }) => {
            const to = getToEntityLink(elementType, seatId, elementId, lineage);
            const label = getAuditLogLabel(element, lineage);
            return to ? <Link to={to}>{label}</Link> : label;
        },
    },
    {
        key: "id",
        dataIndex: "id",
        title: "Record ID",
    },
    {
        key: "userId",
        dataIndex: "userId",
        title: "User",
    },
    {
        key: "elementType",
        dataIndex: "elementType",
        title: "Entity",
        render: (elementType) => formatEntity(elementType),
    },
    {
        key: "action",
        dataIndex: "action",
        title: "Action",
        render: (action) => (
            <Tag style={{ textTransform: "capitalize" }} color={tagColorMap[action]}>
                {action}
            </Tag>
        ),
    },
    {
        key: "changeDate",
        dataIndex: "changeDate",
        title: "Date/Time",
        render: (changeDate) => formatDateTime(changeDate),
    },
];

export const AUDIT_LOG_EXPANDABLE_TABLE_COLUMNS: TableColumnsType<ChangeLogField> = [
    {
        title: "Field Name",
        dataIndex: "attribute",
        key: "attribute",
    },
    {
        title: "Changed From",
        dataIndex: "previous",
        key: "previous",
    },
    {
        title: "Changed To",
        dataIndex: "current",
        key: "current",
    },
];
export const auditLogExpandedRowRender = (record) => (
    <Table
        data-sdet={AUDIT_LOG_EXPANDABLE_TABLE_SDET}
        columns={AUDIT_LOG_EXPANDABLE_TABLE_COLUMNS}
        dataSource={JSON.parse(record.changes)}
        pagination={false}
    />
);
export const AUDIT_LOG_TABLE_SDET = "audit-log-table";
export const AUDIT_LOG_EXPANDABLE_TABLE_SDET = "audit-log-expandable-table";
export const AUDIT_LOG_TABLE_REFRESH_BUTTON_SDET = "audit-log-table-refresh-button";
