import { useState } from "react";

export const useSnapshotsDrawer = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const handleOpenDrawer = () => setIsOpen(true);
    const handleCloseDrawer = () => setIsOpen(false);

    return {
        isOpen,
        handleOpenDrawer,
        handleCloseDrawer,
    };
};
