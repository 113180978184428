import { FC } from "react";
import { cardBodyStyle } from "@app/features/deals/DealForm";
import { Card, Col, Row, Typography } from "antd";
import { GUTTER } from "@app/features/deals/constants";
import {
    AdSourceCustomPacing,
    AdSourceDailyImpressionField,
    AdSourceDailyImpressionRadio,
    AdSourceFallbackOpportunity,
    AdSourcePacingTypeField,
    AdSourceRedistributionField,
} from "./Fields";
import { ReadOnlyField } from "@app/core/components/Fields/ReadOnlyField";
import { DealFormMode } from "../../../types";
import {
    getPacingPeriodText,
    isDailyImpressionCapEnabledEligible,
    isDailyImpressionCapEligible,
    isFallbackOpportunityEstimateEligible,
    isPacingRedistributionEligible,
    isPacingPeriodEligible,
    isPacingTypeCustom,
    isPacingTypeEligible,
} from "@app/features/seatAdSources/SeatAdSourcesForm/utils/pacingUtils";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";

interface AdSourcePacingSectionProps {
    mode: DealFormMode;
    adSourceTypeId: number;
    pacingTypeId: number | null;
    showFallbackOpportunity: boolean;
    isAdSourceDailyImpressionCapEnabled: boolean | null;
}

export const AdSourcePacingSection: FC<AdSourcePacingSectionProps> = ({
    mode,
    adSourceTypeId,
    pacingTypeId,
    showFallbackOpportunity,
    isAdSourceDailyImpressionCapEnabled,
}) => {
    return (
        <Card bordered={false} styles={{ body: cardBodyStyle }}>
            <Row gutter={GUTTER}>
                {isPacingTypeEligible(adSourceTypeId) && (
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                        <AdSourcePacingTypeField mode={mode} adSourceTypeId={adSourceTypeId} />
                    </Col>
                )}
                {isPacingPeriodEligible(adSourceTypeId, pacingTypeId) && (
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                        <ReadOnlyField label={AD_SOURCE_FIELDS.PACING_PERIOD.label}>
                            <Typography.Text>{getPacingPeriodText(pacingTypeId)}</Typography.Text>
                        </ReadOnlyField>
                    </Col>
                )}
            </Row>
            {isPacingTypeCustom(pacingTypeId) && <AdSourceCustomPacing />}
            <Row gutter={GUTTER}>
                {isDailyImpressionCapEnabledEligible(adSourceTypeId, pacingTypeId) && (
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                        <AdSourceDailyImpressionRadio />
                    </Col>
                )}
                {isPacingRedistributionEligible(adSourceTypeId, pacingTypeId) && (
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                        <AdSourceRedistributionField />
                    </Col>
                )}
            </Row>
            {isDailyImpressionCapEligible(adSourceTypeId, pacingTypeId, isAdSourceDailyImpressionCapEnabled) && (
                <Row gutter={GUTTER}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                        <AdSourceDailyImpressionField />
                    </Col>
                </Row>
            )}
            {isFallbackOpportunityEstimateEligible(adSourceTypeId, pacingTypeId, showFallbackOpportunity) && (
                <Row gutter={GUTTER}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                        <AdSourceFallbackOpportunity />
                    </Col>
                </Row>
            )}
        </Card>
    );
};
