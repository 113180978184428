import { intl } from "@rubicon/utils";
import { FC } from "react";
import { SplineData } from "../helpers";
import { ChartTooltipItem } from "./ChartTooltipItem";
import { TOOLTIP_NAMES } from "./FilledUnFilled";

interface Props {
    title: string;
    combinedData: SplineData[];
}
export const CumulativeTotal: FC<Props> = ({ combinedData, title }) => {
    const item = combinedData.find(({ time }) => time === title);
    const cumulativeTotal = intl.numberFormatter(item?.cumulativeTotal || 0);
    const cumulativeTotalPerc = intl.numberFormatter(Number((item?.count || 0).toFixed(1)));

    return (
        <>
            <ChartTooltipItem name={TOOLTIP_NAMES.cumulativeTotal} value={cumulativeTotal} />
            <ChartTooltipItem name={TOOLTIP_NAMES.cumulativeTotalPerc} value={cumulativeTotalPerc} />
        </>
    );
};
