import { useUserAccess } from "@app/core/auth";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useDeleteAdvancedBrandSafetyMutation } from "@app/core/services";
import { Button, Space } from "antd";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { BrandSafetyDeleteModal } from "../BrandSafetyDeleteModal";
import { useBrandSafetyDeleteModal } from "../BrandSafetyDeleteModal/useBrandSafetyDeleteModal";

interface Props {
    seatId: string | number;
    id: number;
    name: string;
    postDeleteEvent?: () => void;
}
export const AdvancedBrandSafetyActions: FC<Props> = ({ seatId, id, name, postDeleteEvent }) => {
    const { canEditSeat } = useUserAccess();
    const history = useHistory();
    const [deleteTrigger, { isLoading: isDeleting }] = useDeleteAdvancedBrandSafetyMutation();
    const { handleOpen, ...restModalProps } = useBrandSafetyDeleteModal(deleteTrigger, postDeleteEvent);

    if (!canEditSeat) {
        return null;
    }

    return (
        <>
            <Space>
                <Button
                    data-sdet="edit-button"
                    type="primary"
                    onClick={() =>
                        history.push(
                            ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_EDIT_PAGE(seatId, id)
                        )
                    }
                >
                    Edit
                </Button>
                <Button danger data-sdet="delete-button" type="primary" onClick={() => handleOpen({ id, name })}>
                    Delete
                </Button>
            </Space>
            <BrandSafetyDeleteModal isDeleting={isDeleting} {...restModalProps} />
        </>
    );
};
