import { Col, Button, Space } from "antd";
import { useUserAccess } from "@app/core/auth";
import DiscardChangesModal from "./DiscardChangesModal";

export const EDIT_MODE_SAVE_SDET = "edit-mode-save-btn";
export const EDIT_MODE_CANCEL_SDET = "edit-mode-cancel-btn";
export const EDIT_MODE_TO_EDIT_SDET = "edit-mode-to-edit-btn";

export interface Props {
    disabled?: boolean;
    discardText?: string;
    handleDiscardModalCancel: () => void;
    handleOk: () => void;
    open: boolean;
    isEdit: boolean;
    isSaving: boolean;
    handleEditClick: () => void;
    handleCancelClick: () => void;
    handleSaveClick?: (values: unknown) => void;
}
export const EditModeControls = ({
    disabled = false,
    discardText = "Are you sure you want to proceed without saving the changes to Tag Params?",
    handleDiscardModalCancel,
    handleOk,
    open,
    isEdit,
    isSaving,
    handleEditClick,
    handleCancelClick,
    handleSaveClick,
}: Props) => {
    const { canEditSeat } = useUserAccess();

    if (canEditSeat) {
        if (isEdit) {
            return (
                <>
                    <Col style={{ flex: 1, textAlign: "right", alignSelf: "center" }}>
                        <Space align="end">
                            <Button data-sdet={EDIT_MODE_CANCEL_SDET} onClick={handleCancelClick}>
                                Cancel
                            </Button>
                            <Button
                                data-sdet={EDIT_MODE_SAVE_SDET}
                                loading={isSaving}
                                onClick={(values) => handleSaveClick?.(values)}
                                disabled={disabled}
                                type="primary"
                                htmlType="submit"
                            >
                                Save
                            </Button>
                        </Space>
                    </Col>
                    <DiscardChangesModal
                        handleOk={handleOk}
                        handleCancel={handleDiscardModalCancel}
                        open={Boolean(open)}
                        text={discardText}
                    />
                </>
            );
        }
        return (
            <Col style={{ flex: 1, textAlign: "right", alignSelf: "center" }}>
                <Button data-sdet={EDIT_MODE_TO_EDIT_SDET} onClick={handleEditClick} type="primary">
                    Edit
                </Button>
            </Col>
        );
    }

    return null;
};

export default EditModeControls;
