import { Form } from "antd";
import { FC } from "react";
import { LimitInput } from "@app/features/inventory/components/Fields";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

export const TierThreeMinimumQPS: FC = () => {
    return (
        <Form.Item
            label={INVENTORY_FORM_FIELDS.THREE_QPS_LIMIT.label}
            name={INVENTORY_FORM_FIELDS.THREE_QPS_LIMIT.name}
            wrapperCol={{ xs: 24, sm: 14, md: 12, lg: 8, xl: 6, xxl: 4 }}
            rules={[
                {
                    validator(_, value) {
                        if (value >= 1 && value <= 35) return Promise.resolve();
                        return Promise.reject(
                            new Error("QPS Minimum Percent Limit needs to be between 1.00% - 35.00%")
                        );
                    },
                },
            ]}
        >
            <LimitInput addonAfter="%" style={{ width: "100%" }} />
        </Form.Item>
    );
};
