import { useSeatAuthContext } from "@app/core/auth";
import { Brand } from "@app/core/services";
import { getEnabledDisabled } from "@app/core/utils/labels";
import { InternalOnly } from "@app/features/inventory/components/InternalOnly";
import { GUTTER, TWO_COL_SIZES } from "@app/features/inventory/constants";
import { SeeMoreUnorderedList } from "@app/features/inventory/SeeMoreUnorderedList";
import { Col, Form, Row } from "antd";
import { FC } from "react";
import { BRAND_SUPPLY_DETAILS_LABELS, FORM_ITEM_OFFSET } from "../../../constants";

const { Item } = Form;

interface Props {
    brand: Brand;
}
export const SupplyDetails: FC<Props> = ({ brand }) => {
    const { context } = useSeatAuthContext();
    const isAdvertisersAsAllowListShown = Boolean(context?.enableAdvertisersWhitelist);

    return (
        <Row gutter={GUTTER} data-sdet="brand-supply-details-section">
            <Col {...TWO_COL_SIZES}>
                <Item label={BRAND_SUPPLY_DETAILS_LABELS.categories} className={FORM_ITEM_OFFSET}>
                    <SeeMoreUnorderedList values={brand?.iabCategories?.map((value) => value.name)} />
                </Item>
            </Col>
            <Col hidden={!isAdvertisersAsAllowListShown} {...TWO_COL_SIZES}>
                <InternalOnly>
                    <Item label={BRAND_SUPPLY_DETAILS_LABELS.advAsAllowList} className={FORM_ITEM_OFFSET}>
                        {getEnabledDisabled(brand?.enableAdvertisersAsWhitelist)}
                    </Item>
                </InternalOnly>
            </Col>
        </Row>
    );
};
