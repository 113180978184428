import { FC } from "react";
import { SeatIFrame } from "@app/core/components";
import conf from "@app/core/conf";
import { useParams } from "react-router-dom";

export const IntegrationsConsolePage: FC = () => {
    const { tab } = useParams<{ tab: string }>();
    let urlSuffix = "/integrations";
    if (tab) {
        urlSuffix += `/${tab}`;
    }
    return (
        <SeatIFrame src={(seatId) => `${conf.mctvConsoleRoot}/ssp/seats/${seatId}${urlSuffix}`} title="Integrations" />
    );
};
