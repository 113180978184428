import debounce from "lodash.debounce";
import { LabeledValue } from "antd/es/select";
import { useCallback, useMemo, useState } from "react";
import { useGetGeographiesCountriesQuery } from "@app/core/services";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";

interface UseCountrySelect {
    isFetching: boolean;
    localValue: string;
    options: LabeledValue[];
    handleReset: () => void;
    handleChange: (v: string) => void;
}

export const useCountrySelect = (): UseCountrySelect => {
    const [keyword, setKeyword] = useState<string>("");
    const [localValue, setLocalValue] = useState<string>("");
    const { data, isFetching } = useGetGeographiesCountriesQuery({ keyword });

    const handleSearch = useCallback((value: string): void => {
        setKeyword(value);
    }, []);

    const debouncedHandleSearch = useMemo(
        () => debounce((value: string) => handleSearch(value), FILTER_INPUT_DEBOUNCE_TIME),
        [handleSearch]
    );

    const handleReset = (): void => setLocalValue("");

    const handleChange = (v: string): void => {
        setLocalValue(v);
        debouncedHandleSearch(v);
    };

    const options: LabeledValue[] = useMemo(
        () => (data || []).map((datum) => ({ value: datum.geo.id, label: datum.geo.countryName })),
        [data]
    );

    return {
        options,
        isFetching,
        localValue,
        handleReset,
        handleChange,
    };
};
