import { FC } from "react";
import { Form, Select } from "antd";
import { Seat } from "@app/core/services";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { useCurrency } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/SeatDetailsFormFields/Currency/useCurrency";

interface CurrencyProps {
    seat: Seat;
    edit?: boolean;
}

export const Currency: FC<CurrencyProps> = ({ seat, edit = false, ...rest }) => {
    const { isFetching, options } = useCurrency();
    const { floorCurrency } = seat;

    return (
        <Form.Item
            label={INVENTORY_FORM_FIELDS.FLOOR_CURRENCY.label}
            name={INVENTORY_FORM_FIELDS.FLOOR_CURRENCY.name}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 24, xxl: 20 }}
            {...rest}
        >
            {edit ? (
                <Select
                    showSearch
                    labelInValue
                    options={options}
                    loading={isFetching}
                    optionFilterProp="label"
                    style={{ width: "100%" }}
                />
            ) : (
                floorCurrency.code
            )}
        </Form.Item>
    );
};
