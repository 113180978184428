import { FC } from "react";
import { Table, Typography } from "antd";
import { getVastErrorCodeDescriptions, VastErrors } from "@app/core/services";
import { sortVastErrorDescriptionsByCode } from "@app/core/services/diagnostics/vastErrors/utils";

enum ColumnKey {
    ErrorCode = "errorCode",
    Description = "description",
}

const columns = [
    {
        title: "Error Code",
        dataIndex: ColumnKey.ErrorCode,
        key: ColumnKey.ErrorCode,
        width: "100px",
    },
    {
        title: "Description",
        dataIndex: ColumnKey.Description,
        key: ColumnKey.Description,
    },
];

interface Props {
    vastErrors: VastErrors[] | undefined;
}

export const SeatAdSourcesVastErrorDescriptionTable: FC<Props> = ({ vastErrors = [] }) => {
    const errorDescriptions = getVastErrorCodeDescriptions(vastErrors);
    const sortedErrorDescriptions = sortVastErrorDescriptionsByCode(errorDescriptions);

    return (
        <div data-sdet="SeatAdSourcesVastErrorDescriptionTable" className="vast-error-table">
            <Typography.Title level={5}>Video Errors displayed in Query Tool Definition</Typography.Title>
            <Table
                size="small"
                bordered={true}
                columns={columns}
                dataSource={sortedErrorDescriptions}
                pagination={false}
            />
        </div>
    );
};
