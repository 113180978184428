import { useIsFieldVisible } from "./useIsFieldVisible";
import { Form } from "antd";
import { AD_SOURCE_FIELDS, AD_SOURCE_TYPES_NEXT_UI_NAMES, AdSourceTypes } from "@app/features/seatAdSources/constants";
import { SeatAdSourcesType } from "@app/core/services";
import { useEffect } from "react";
import { AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm";

const { useWatch, useFormInstance } = Form;

export const useAlwaysSendFloorRadio = () => {
    const { setFieldsValue } = useFormInstance<AdSourcesForm>();
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);
    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSourceType?.name];

    const isVisible = useIsFieldVisible();

    useEffect(() => {
        if (adSourceTypeName === AdSourceTypes.LINEAR_AUCTION_PRICE) {
            setFieldsValue({
                [AD_SOURCE_FIELDS.ALWAYS_SEND_FLOORS.name]: false,
            });
        }
    }, [setFieldsValue, adSourceTypeName]);

    return { isVisible };
};
