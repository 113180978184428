import { useGetFloorByIdQuery } from "@app/core/services/console";
import { useParams } from "react-router-dom";

interface FloorDetailsPageParams {
    floorId: string;
}

export const useFloorById = (id?: number) => {
    const { floorId } = useParams<FloorDetailsPageParams>();
    const currentFloorId = id ? id : Number(floorId);
    const { data: floor, isFetching: isFetchingFloor } = useGetFloorByIdQuery(currentFloorId, {
        skip: !currentFloorId,
    });

    return {
        floor,
        isLoading: isFetchingFloor,
    };
};
