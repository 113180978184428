import { FC, ReactEventHandler, ReactNode } from "react";
import { SeatIFrame } from "@app/core/components";
import { IFRAMED_APP_ID } from "@app/features/reports/constants";

interface Props {
    src: (seatId: string) => string;
    onLoad?: ReactEventHandler<HTMLIFrameElement>;
    title: ReactNode;
    navBarExtra?: ReactNode;
}

export const ReportsIFrame: FC<Props> = ({ src, onLoad, title, navBarExtra }) => {
    return <SeatIFrame src={src} title={title} navBarExtra={navBarExtra} name={IFRAMED_APP_ID} onLoad={onLoad} />;
};
