import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormInputFieldChange } from "@app/features/deals/DealForm/reducer";
import { useMemo, useState } from "react";
import { useDispatchOnBlur } from "@app/features/deals/DealForm/hooks";
import { useSyncedFieldsProducer } from "@app/features/syncedFields";

interface UseAdSourceOverrideFloorCpmField {
    label: string;
    value: number | null;
    handleChange: (value: number) => void;
    handleBlur: (event) => void;
    handlePressEnter: (event) => void;
}

export const useAdSourceOverrideFloorCpmField = (): UseAdSourceOverrideFloorCpmField => {
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_PRICE]);
    const { onBlur, onChange } = useDispatchOnBlur(value);
    const floorTypeId = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_FLOOR_TYPE]
    );
    const { updateSyncedFields } = useSyncedFieldsProducer();

    const label = useMemo(() => {
        let suffixText = "",
            prefixText = "";
        switch (floorTypeId) {
            case 1:
                prefixText = "Override";
                suffixText = "(CPM)";
                break;
            case 2:
                prefixText = "Fallback";
                suffixText = "(CPM)";
                break;
            case 3:
                prefixText = "Increase";
                suffixText = "by CPM";
                break;
            case 4:
                prefixText = "Decrease";
                suffixText = "by CPM";
                break;
            case 5:
                prefixText = "Increase";
                suffixText = "by %";
                break;
            case 6:
                prefixText = "Decrease";
                suffixText = "by %";
                break;
            default:
                suffixText = "";
                break;
        }

        return `${prefixText} Floor ${suffixText}`;
    }, [floorTypeId]);

    const [isDirty, setIsDirty] = useState<boolean>(false);
    const handleChange = (value: number) => {
        setIsDirty(true);
        onChange(value);
    };

    const handleBlur = (event): void => {
        onBlur(dealFormInputFieldChange, CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_PRICE);
        if (isDirty) {
            updateSyncedFields({ cpmRate: event.target.value });
            setIsDirty(false);
        }
    };

    const handlePressEnter = (event): void => {
        handleBlur(event);
    };

    return {
        handleBlur,
        handleChange,
        handlePressEnter,
        value,
        label,
    };
};
