import { FC } from "react";
import { Col, InputNumber, Row, Space, Typography } from "antd";
import { LabeledValue } from "antd/lib/select";
import { Conditions, MIN_AD_DURATION, TargetingDimensionTypeLabels } from "../../../constants";
import { MaxDurationTarget, MaxDurationTargetDimension, UsedDimensions } from "../../../types";
import { ConditionSelect } from "../../ConditionSelect";
import { MoreMenu } from "../../MoreMenu";
import { MaxDurationTargetSelect } from "./MaxDurationTargetSelect/MaxDurationTargetSelect";

interface Props {
    dimension: MaxDurationTargetDimension;
    onChangeCondition: (value: Conditions) => void;
    onChangeDimensionValues: (value: MaxDurationTarget) => void;
    onClickDelete: () => void;
    onClickDownload: () => void;
    onClickDuplicate: () => void;
    usedDimensions: UsedDimensions;
}

export const MaxDurationTargetDimensionRow: FC<Props> = ({
    dimension,
    onChangeCondition,
    onChangeDimensionValues,
    onClickDelete,
    onClickDownload,
    onClickDuplicate,
    usedDimensions,
}) => {
    const handleChangeSelect = ({ label, value }: LabeledValue) => {
        const nextValue: MaxDurationTarget = {
            operator: {
                id: value as number,
                name: label as string,
            },
            maxDuration: dimension.values?.maxDuration || MIN_AD_DURATION,
        };
        onChangeDimensionValues(nextValue);
    };
    const handleChangeInput = (value: number) => {
        const nextValue: MaxDurationTarget = {
            operator: dimension.values?.operator,
            maxDuration: value,
        };
        onChangeDimensionValues(nextValue);
    };

    return (
        <Row gutter={8}>
            <Col flex="150px">
                <Typography.Text strong>{TargetingDimensionTypeLabels.MaxDurationTarget}</Typography.Text>
            </Col>
            <Col flex="128px">
                <ConditionSelect
                    onChange={onChangeCondition}
                    isExcludesDisabled={
                        dimension.condition !== Conditions.Excludes && usedDimensions.maxDurationTarget.excludes
                    }
                    isIncludesDisabled={
                        dimension.condition !== Conditions.Includes && usedDimensions.maxDurationTarget.includes
                    }
                    value={dimension.condition}
                />
            </Col>
            <Col flex="180px">
                <MaxDurationTargetSelect onChange={handleChangeSelect} value={dimension.values?.operator} />
            </Col>
            <Col flex="1">
                <InputNumber
                    placeholder={`Enter ${TargetingDimensionTypeLabels.MaxDurationTarget}`}
                    style={{ width: "100%" }}
                    min={MIN_AD_DURATION}
                    onChange={handleChangeInput}
                    value={dimension.values?.maxDuration}
                />
            </Col>
            <Col flex="146px" style={{ display: "flex", justifyContent: "flex-end" }}>
                <Space>
                    <MoreMenu
                        onClickDelete={onClickDelete}
                        onClickDownload={onClickDownload}
                        onClickDuplicate={onClickDuplicate}
                        isDuplicateDisabled={
                            usedDimensions.maxDurationTarget.includes || usedDimensions.maxDurationTarget.excludes
                        }
                    />
                </Space>
            </Col>
        </Row>
    );
};
