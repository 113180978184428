import { BrandChannel, Seat, useGetSeatSelfQuery } from "@app/core/services";
import { Form, FormInstance } from "antd";
import { createContext, FC, useContext } from "react";
import { HierarchyFormsMode } from "../constants";
import { PublisherChannelForm } from "./usePublisherChannelForm";
import { useParams } from "react-router-dom";

export interface PublisherChannelFormInstanceProps {
    mode: Exclude<HierarchyFormsMode, "copy">;
    channel?: BrandChannel;
}

interface PublisherChannelFormInstanceContextType extends PublisherChannelFormInstanceProps {
    seat: Seat | null;
    seatId: string;
    isLoading: boolean;
    form: FormInstance<PublisherChannelForm>;
    useWatch: typeof Form.useWatch;
}

const PublisherChannelFormInstanceContext = createContext<PublisherChannelFormInstanceContextType | null>(null);

export const PublisherChannelFormInstance: FC<PublisherChannelFormInstanceProps> = ({ children, ...rest }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data: seatSelf, isFetching } = useGetSeatSelfQuery(seatId);
    const [form] = Form.useForm();

    return (
        <PublisherChannelFormInstanceContext.Provider
            value={{
                form,
                seatId,
                isLoading: isFetching,
                useWatch: Form.useWatch,
                seat: seatSelf?.seat || null,
                ...rest,
            }}
        >
            {children}
        </PublisherChannelFormInstanceContext.Provider>
    );
};

export const usePublisherChannelFormInstance = () => {
    const context = useContext<PublisherChannelFormInstanceContextType | null>(PublisherChannelFormInstanceContext);
    if (!context)
        throw new Error("usePublisherChannelForm instance must be used within a PublisherChannelFormInstance");
    return context;
};
