import { resetAppAction, useAppDispatch } from "@app/core/store";
import { useEffect, useMemo, useState } from "react";
import { getSeatsPicklist, SEAT_ENTITY_TYPE, SeatPicklistItem } from "@app/core/clients/console";
import { useHistory } from "react-router-dom";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { OptionType } from "@app/features/login/Steps/hooks/useContextSelectForm";
import { notification } from "antd";

const fetchSeatsPicklist = (
    search: string,
    setSeatOptions: (options: SeatPicklistItem[]) => void,
    setIsSeatsLoading: (isLoading: boolean) => void
): void => {
    setIsSeatsLoading(true);
    getSeatsPicklist({ keyword: search })
        .then((res) => {
            setSeatOptions(res?.data);
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.errorDescription });
        })
        .finally(() => {
            setIsSeatsLoading(false);
        });
};

interface UseSearchContextInputGroup {
    handleChangeSearch: (value: string) => void;
    handleChangeValue: (value: number | null, option: OptionType | null) => void;
    isLoading: boolean;
    options: OptionType[];
    search: string;
    value: number | null;
}

export const useSearchContextInputGroup = (): UseSearchContextInputGroup => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [seatOptions, setSeatOptions] = useState<SeatPicklistItem[]>([]);
    const [value, setValue] = useState<number | null>(null);
    const history = useHistory();

    const debouncedFetchSeatsPicklist = useMemo(
        () =>
            debounce(
                (search: string) => fetchSeatsPicklist(search, setSeatOptions, setIsLoading),
                FILTER_INPUT_DEBOUNCE_TIME
            ),
        []
    );

    const handleChangeSearch = (search: string): void => {
        setSearch(search);
        debouncedFetchSeatsPicklist(search.trim());
    };

    const handleChangeValue = (value: number | null, option: OptionType): void => {
        setValue(value);
        dispatch(resetAppAction());
        if (option?.entityType === SEAT_ENTITY_TYPE) {
            history.push(ROUTE_FORMATTERS.SEAT_DASHBOARD(option?.value));
            return;
        }
        history.push(ROUTE_FORMATTERS.BUYER_DASHBOARD(option?.value));
    };

    useEffect(() => {
        fetchSeatsPicklist("", setSeatOptions, setIsLoading);
    }, []);

    const options: OptionType[] = useMemo(
        () =>
            seatOptions.map((option) => ({
                label: `${option.name} (${option.entityType})`,
                value: option.id,
                entityType: option.entityType,
            })),
        [seatOptions]
    );

    return {
        handleChangeSearch,
        handleChangeValue,
        isLoading,
        options,
        search,
        value,
    };
};
