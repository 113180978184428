import { FC } from "react";
import { BaseType } from "antd/lib/typography/Base";
import { Checkbox, Col, Form, Input, Row, Typography } from "antd";
import { SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useBundleID } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/AppDetailsSection/Fields/BundleID/useBundleID";

export const BundleID: FC = () => {
    const { extra, onChange, disabled, validateStatus, help } = useBundleID();
    return (
        <Row gutter={[16, 16]} align="middle">
            <Col xs={24} sm={24} md={24} lg={14} xl={10} xxl={9}>
                <Form.Item
                    extra={extra}
                    hasFeedback={!!validateStatus}
                    validateStatus={validateStatus}
                    name={SUPPLY_FORM_FIELDS.BUNDLE_ID.name}
                    label={SUPPLY_FORM_FIELDS.BUNDLE_ID.label}
                    help={help && <Typography.Text type={validateStatus as BaseType}>{help}</Typography.Text>}
                    rules={[{ required: true, whitespace: true, message: SUPPLY_FORM_FIELDS.BUNDLE_ID.rulesMessage }]}
                >
                    <Input
                        allowClear
                        onChange={onChange}
                        disabled={disabled}
                        placeholder={SUPPLY_FORM_FIELDS.BUNDLE_ID.placeholder}
                    />
                </Form.Item>
            </Col>
            <Col>
                <Form.Item name={SUPPLY_FORM_FIELDS.BUNDLE_ID_IS_OVERRIDE.name} valuePropName="checked">
                    <Checkbox>{SUPPLY_FORM_FIELDS.BUNDLE_ID_IS_OVERRIDE.label}</Checkbox>
                </Form.Item>
            </Col>
        </Row>
    );
};
