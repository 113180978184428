import { useEffect, useMemo } from "react";
import { WaterfallPacing, useGetWaterfallPacingQuery, useGetSeatAdSourcesQuery } from "@app/core/services/console";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import {
    ALL_STATUS_WATERFALL_FILTER,
    selectSeatAdSourcesPacingPagination,
    setPagination,
    setPaginationTotalResults,
} from "../reducer";
import { useSeatAdSourcesPacingTableFilters } from "../SeatAdSourcesPacingTableFilters/useSeatAdSourcesPacingTableFilters";
import { PAGE_SIZE_OPTIONS } from "@app/core/components/constants";
import { PacingTypes } from "../../constants";

interface UseSeatAdSourcesPacingTable {
    data: WaterfallPacing[];
    isFetching: boolean;
    pageSizeOptions: number[];
    pageSize: number;
    current: number;
    total: number;
    onPaginationChange: (page: number, max: number) => void;
}

const mapPacingDeltaToStatusFilterOption = (pacingDelta: string): number => {
    const delta = parseFloat(pacingDelta);
    if (Math.abs(delta) <= 10) {
        return 3; // normal pacing
    } else if (delta > 10) {
        return 4; // over pacing
    } else if (delta < -10) {
        return 2; // under pacing
    }
    return 1; // all pacing
};

export const useSeatAdSourcesPacingTable = (): UseSeatAdSourcesPacingTable => {
    const dispatch = useAppDispatch();
    const { seatAllAdSourcesParams, seatWaterfallPacingParams } = useSeatAdSourcesPacingTableFilters();
    const { data: pacingData, isFetching: pacingIsFetching } = useGetWaterfallPacingQuery(seatWaterfallPacingParams);
    const { data: adSourcesData, isFetching: adSourcesIsFetching } = useGetSeatAdSourcesQuery(seatAllAdSourcesParams);
    const seatAdSourcesPacingLabelKey = useAppSelector(
        (state) => state.seatAdSourcesPacing.filters.seatAdSourcesLabelKey
    );
    const seatWaterfallStatusFilter = useAppSelector((state) => state.seatAdSourcesPacing.filters.seatAdSourcesStatus);
    const pagination = useAppSelector(selectSeatAdSourcesPacingPagination);

    const adSourceIdsSet = useMemo(
        () => (adSourcesData ? new Set(adSourcesData.adSources.map(({ id }) => id)) : null),
        [adSourcesData]
    );

    const labelFilterEnabled = useMemo(
        () => Boolean(seatAdSourcesPacingLabelKey?.length),
        [seatAdSourcesPacingLabelKey]
    );

    const statusFilterEnabled = useMemo(
        () => seatWaterfallStatusFilter?.value !== ALL_STATUS_WATERFALL_FILTER.value,
        [seatWaterfallStatusFilter]
    );

    const filteredData = useMemo(
        () =>
            (pacingData && adSourcesData && !adSourcesIsFetching ? pacingData : []).filter(
                ({ adSourceId, pacingType, pacingDelta }) =>
                    pacingType !== PacingTypes.NONE &&
                    (!labelFilterEnabled || adSourceIdsSet?.has(adSourceId)) &&
                    (!statusFilterEnabled ||
                        mapPacingDeltaToStatusFilterOption(pacingDelta) === seatWaterfallStatusFilter?.value)
            ),
        [
            pacingData,
            adSourcesData,
            adSourcesIsFetching,
            labelFilterEnabled,
            adSourceIdsSet,
            statusFilterEnabled,
            seatWaterfallStatusFilter?.value,
        ]
    );

    const paginatedData = useMemo(() => {
        const { page: currentPage, max: pageSize } = pagination;
        return filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    }, [filteredData, pagination]);

    const onPaginationChange = (page: number, max: number) => {
        dispatch(setPagination({ page, max }));
    };

    useEffect(() => {
        dispatch(setPaginationTotalResults(filteredData.length));
    }, [filteredData.length, dispatch]);

    return {
        data: paginatedData,
        isFetching: pacingIsFetching || adSourcesIsFetching,
        total: pagination.total,
        current: pagination.page,
        pageSize: pagination.max,
        pageSizeOptions: PAGE_SIZE_OPTIONS,
        onPaginationChange,
    };
};
