import { Form } from "antd";
const { useFormInstance } = Form;
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

interface UseCuePoints {
    onChange: (v: string[]) => void;
}

export const useCuePoints = (): UseCuePoints => {
    const form = useFormInstance();

    const onChange = (values: string[]) => {
        const nums = values.filter((val) => !isNaN(Number(val)));
        form.setFieldValue(CHANNEL_FORM_FIELDS.CUE_POINTS.name, nums);
    };

    return {
        onChange,
    };
};
