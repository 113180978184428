import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SeatSelf, SeatAccess, Seat } from "@app/core/services/console";
import { Buyer } from "@app/core/services/console/buyers";
import { RootState } from "@app/core/store";

export interface AuthState {
    seat: {
        isLoadingAccess: boolean;
        isLoadingContext: boolean;
        access: SeatAccess;
        context: SeatSelf | null;
    };
    buyer: {
        isLoading: boolean;
        context: Buyer | null;
    };
}

const DefaultSeatAccess = { read: false, write: false, report: false, administration: false } as const;

export const initialState: AuthState = {
    seat: {
        isLoadingAccess: false,
        isLoadingContext: false,
        access: DefaultSeatAccess,
        context: null,
    } as const,
    buyer: {
        isLoading: false,
        context: null,
    } as const,
} as const;

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setIsLoadingSeatAccess: (state, action: PayloadAction<boolean>) => {
            state.seat.isLoadingAccess = action.payload;
        },
        setIsLoadingSeatContext: (state, action: PayloadAction<boolean>) => {
            state.seat.isLoadingContext = action.payload;
        },
        setSeatAccess: (state, action: PayloadAction<SeatAccess | null | undefined>) => {
            state.seat.access = action.payload || DefaultSeatAccess;
            state.seat.isLoadingAccess = false;
        },
        setSeatContext: (state, action: PayloadAction<SeatSelf | null | undefined>) => {
            state.seat.context = action.payload || null;
            state.seat.isLoadingContext = false;
        },
        updateSeatInContext: (state, action: PayloadAction<Seat>) => {
            const updatedSeat = action.payload;
            if (updatedSeat.id && updatedSeat.id === state.seat.context?.seat?.id) {
                state.seat.context.seat = action.payload;
            }
        },
        setIsLoadingBuyer: (state, action: PayloadAction<boolean>) => {
            state.buyer.isLoading = action.payload;
        },
        setBuyerContext: (state, action: PayloadAction<Buyer | null | undefined>) => {
            state.buyer.context = action.payload || null;
            state.buyer.isLoading = false;
        },
        resetAuthState: () => initialState,
    },
});

export const selectIsLoadingSeat = (state: RootState): boolean =>
    state.auth.seat.isLoadingAccess || state.auth.seat.isLoadingContext;
export const selectSeatAccess = (state: RootState): SeatAccess => state.auth.seat.access;
export const selectSeatContext = (state: RootState): SeatSelf | null => state.auth.seat.context;
export const selectIsSeatDemoContext = (state: RootState): boolean =>
    Boolean(selectSeatContext(state)?.seat?.code?.toLowerCase().includes("!demo!"));

export const selectIsLoadingBuyer = (state: RootState): boolean => state.auth.buyer.isLoading;
export const selectBuyerContext = (state: RootState): Buyer | null => state.auth.buyer.context;
export const selectIsBuyerDemoContext = (state: RootState): boolean =>
    Boolean(selectBuyerContext(state)?.code.toLowerCase().includes("!demo!"));

export const {
    setIsLoadingSeatAccess,
    setIsLoadingSeatContext,
    setSeatAccess,
    setSeatContext,
    updateSeatInContext,
    setIsLoadingBuyer,
    setBuyerContext,
    resetAuthState,
} = authSlice.actions;

export default authSlice.reducer;
