import { FC } from "react";
import { Form, Radio } from "antd";
import { useSupplyFormInstance } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormInstance";
import { INVENTORY_CODE_TYPE_OPTIONS, SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const CodeType: FC = () => {
    const { mode } = useSupplyFormInstance();
    const hidden: boolean = mode === "edit";
    return (
        <Form.Item hidden={hidden} name={SUPPLY_FORM_FIELDS.CODE_TYPE.name} label={SUPPLY_FORM_FIELDS.CODE_TYPE.label}>
            <Radio.Group options={INVENTORY_CODE_TYPE_OPTIONS} />
        </Form.Item>
    );
};
