import { FC } from "react";
import { Form } from "antd";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { usePacingFormData } from "../usePacingFormData";
import { usePacingTypeOptions } from "./usePacingTypeOptions";
import { usePacingTypeSelect } from "./usePacingTypeSelect";

const { label, name } = AD_SOURCE_FIELDS.PACING_TYPE;

export const PacingTypeSelect: FC = () => {
    const { adSourceTypeId } = usePacingFormData();
    const { options } = usePacingTypeOptions(adSourceTypeId);
    const { isReadonly, isVisible, helpText, onChange } = usePacingTypeSelect();

    if (!isVisible) {
        return null;
    }

    return (
        <Form.Item
            label={label}
            name={name}
            rules={[{ required: true, message: `${label} is required` }]}
            help={helpText}
            {...VERTICAL_LAYOUT_FIX}
        >
            <SelectWithValue
                data-sdet="pacing-type-select"
                showSearch
                optionFilterProp="label"
                options={options}
                placeholder={`Select ${label}...`}
                fieldAsLabel={["name"]}
                fieldAsValue={["id"]}
                disabled={isReadonly}
                onChange={onChange}
            />
        </Form.Item>
    );
};
