import { FC } from "react";
import { Button, Modal, Typography } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { gold6 } from "@rubicon/antd-components";
import { useAppVersionRefreshModal } from "./useAppVersionRefreshModal";

export const AppVersionRefreshModal: FC = () => {
    const { isModalOpen, refreshApplication } = useAppVersionRefreshModal();

    return (
        <Modal
            data-sdet="app-version-modal"
            open={isModalOpen}
            closable={false}
            title={
                <Typography.Text>
                    <WarningOutlined data-sdet="warning-icon" style={{ color: gold6 }} /> New Version Detected
                </Typography.Text>
            }
            footer={
                <Button type="primary" onClick={refreshApplication}>
                    Ok
                </Button>
            }
        >
            <Typography.Paragraph>
                A new version of Streaming has been detected. The application will refresh.
            </Typography.Paragraph>
        </Modal>
    );
};
