import { FC } from "react";
import { Radio } from "antd";
import { TRANSPARENCY_OPTIONS } from "@app/features/deals/constants";
import { useAdSourceSupplyPageContentUrlField } from "./useAdSourceSupplyPageContentUrlField";
import { TransparencyFormItem } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceTransparencySection/Fields";

export const AdSourceSupplyPageContentUrlField: FC = () => {
    const { value, onChange, isDisabled, overrideText } = useAdSourceSupplyPageContentUrlField();
    return (
        <TransparencyFormItem extra={overrideText} label="Supply Page/Content URL" data-sdet="supply-page-content-url">
            <Radio.Group
                disabled={isDisabled}
                onChange={(e) => onChange(e?.target?.value)}
                value={value}
                options={TRANSPARENCY_OPTIONS}
            />
        </TransparencyFormItem>
    );
};
