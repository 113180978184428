import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { SeatAdSourcesCopyPage } from "./SeatAdSourcesCopyPage";
import { SeatAdSourcesCreatePage } from "./SeatAdSourcesCreatePage";
import { SeatAdSourcesDetailsPage } from "./SeatAdSourcesDetailsPage";
import { SeatAdSourcesEditPage } from "./SeatAdSourcesEditPage";
import { SeatAdSourcesListPage } from "./SeatAdSourcesListPage";
import { SeatAdSourcesPacingPage } from "./SeatAdSourcesPacingPage";

export const SeatAdSourcesSwitch: FC = () => {
    return (
        <Switch>
            <Route exact path={ROUTES.SEAT_AD_SOURCES_COPY}>
                <SeatAdSourcesCopyPage />
            </Route>
            <Route exact path={ROUTES.SEAT_AD_SOURCES_CREATE}>
                <SeatAdSourcesCreatePage />
            </Route>
            <Route
                exact
                path={[
                    ROUTES.SEAT_AD_SOURCES_PACING,
                    ROUTES.SEAT_AD_SOURCES_PACING_DETAILS,
                    ROUTES.SEAT_AD_SOURCES_PACING_DETAILS_SECONDARY_VIEW,
                    ROUTES.SEAT_AD_SOURCES_PACING_SECONDARY_VIEW_DRAWER,
                ]}
            >
                <SeatAdSourcesPacingPage />
            </Route>
            <Route
                exact
                path={[
                    ROUTES.SEAT_AD_SOURCES_DETAILS,
                    ROUTES.SEAT_AD_SOURCES_DETAILS_PAGE_DETAILS_DRAWER,
                    ROUTES.SEAT_AD_SOURCES_DETAILS_PAGE_SECONDARY_VIEW,
                ]}
            >
                <SeatAdSourcesDetailsPage />
            </Route>
            <Route exact path={ROUTES.SEAT_AD_SOURCES_EDIT}>
                <SeatAdSourcesEditPage />
            </Route>
            <Route
                exact
                path={[
                    ROUTES.SEAT_AD_SOURCES_LIST,
                    ROUTES.SEAT_AD_SOURCES_DETAILS_DRAWER,
                    ROUTES.SEAT_AD_SOURCES_DETAILS_DRAWER_SECONDARY_VIEW,
                    ROUTES.SEAT_AD_SOURCES_SECONDARY_VIEW_DRAWER,
                ]}
            >
                <SeatAdSourcesListPage />
            </Route>
        </Switch>
    );
};
