import { Form, Radio, Space, Spin, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { BULK_OPERATION_FORM_ITEMS, YES_NO_BOOLEAN_OPTIONS } from "../../../constants";
import { BulkOperationSourceEntity, useGetBulkOperationCopyScopeMutation } from "@app/core/services";
import { useNotificationError } from "@app/core/components/hooks";
import { InfoCircleFilled } from "@ant-design/icons";
import { warningColor } from "@rubicon/antd-components";

interface HelperProps {
    scope: number;
    isLoading?: boolean;
}

export const getCopyScopeMessage = (copyScopy: number) =>
    copyScopy > 1
        ? `This will create ${copyScopy} new pieces of Inventory.`
        : "The inventory selected has no child entities.";

export const RecursiveCopyHelper: FC<HelperProps> = ({ scope, isLoading }) => (
    <Space>
        <InfoCircleFilled style={{ color: warningColor }} />
        {isLoading ? <Spin /> : <Typography.Text>{getCopyScopeMessage(scope)}</Typography.Text>}
    </Space>
);

export const CopyInventoryChildrenField: FC = () => {
    const [copyScope, setCopyScope] = useState<number>(0);
    const [getCopyScope, { isLoading, error }] = useGetBulkOperationCopyScopeMutation();
    const form = Form.useFormInstance();
    const sourceEntity = Form.useWatch(BULK_OPERATION_FORM_ITEMS.SOURCE_ENTITY.name, { form, preserve: true });
    const sourceEntityIdField = Form.useWatch("sourceEntityId", { form, preserve: true });
    const recursiveCopy = Form.useWatch(BULK_OPERATION_FORM_ITEMS.COPY_INVENTORY_CHILDREN.name, {
        form,
        preserve: true,
    });
    const showFormItem = sourceEntity && sourceEntity !== BulkOperationSourceEntity.AdUnit && sourceEntityIdField;

    useNotificationError(error);

    useEffect(() => {
        if (showFormItem && recursiveCopy) {
            getCopyScope({ sourceEntity, sourceEntityId: sourceEntityIdField.value, type: "copy" })
                .unwrap()
                .then((response: number) => {
                    setCopyScope(response);
                    form.setFieldValue(BULK_OPERATION_FORM_ITEMS.COPY_SCOPE.name, response);
                });
        }
    }, [recursiveCopy, showFormItem, sourceEntity, sourceEntityIdField, getCopyScope, setCopyScope, form]);

    if (!showFormItem) {
        return null;
    }

    return (
        <Form.Item
            {...BULK_OPERATION_FORM_ITEMS.COPY_INVENTORY_CHILDREN}
            wrapperCol={{ xs: 24 }}
            help={recursiveCopy ? <RecursiveCopyHelper isLoading={isLoading} scope={copyScope} /> : null}
        >
            <Radio.Group data-sdet="copy-inventory-children-field" options={YES_NO_BOOLEAN_OPTIONS} />
        </Form.Item>
    );
};
