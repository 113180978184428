import { Col, Form, Row } from "antd";
import { EntityTypeFilter } from "./EntityTypeFilter/";
import { DebounceUriSearch } from "@app/core/components/DebounceUriSearch";

export const SEARCH_FILTER_SDET = "recycle-bin-search-filter";

export const RecycleBinToolbar = () => (
    <Form layout="vertical">
        <Row gutter={16}>
            <Col xs={12} sm={5}>
                <EntityTypeFilter />
            </Col>
            <Col xs={12} sm={5}>
                <DebounceUriSearch sdet={SEARCH_FILTER_SDET} />
            </Col>
        </Row>
    </Form>
);

export default RecycleBinToolbar;
