import { FC } from "react";
import { InventoryPublishersListTableActions } from "./InventoryPublishersListTableActions";
import { InventoryHealthBrandListTableActions } from "./InventoryHealthBrandListTableActions";
import { InventorySupplyTableActions } from "./InventorySupplyTableActions";
import { InventoryAdUnitsTableActions } from "./InventoryAdUnitsTableActions";
import { InventorySeatTableActions } from "./InventorySeatTableActions";
import {
    isAdUnit,
    isBrandOrBrandChannel,
    isPublisherOrPublisherChannel,
    isSeat,
    isSupply,
    TableDatum,
} from "../useInventoryHealthInventoryTable";

interface Props {
    context: { channelsEnabled: boolean } | null | undefined;
    entity: TableDatum;
}

export const InventoryHealthInventoryTableActions: FC<Props> = ({ context, entity }) => {
    if (isSeat(entity)) {
        return <InventorySeatTableActions />;
    }
    if (isPublisherOrPublisherChannel(entity)) {
        return (
            <InventoryPublishersListTableActions
                channelsEnabled={Boolean(context?.channelsEnabled)}
                publisher={entity}
            />
        );
    }
    if (isBrandOrBrandChannel(entity)) {
        return <InventoryHealthBrandListTableActions publisherId={entity.publisher.id} brand={entity} />;
    }
    if (isSupply(entity)) {
        return (
            <InventorySupplyTableActions
                brandId={entity.brand.id}
                publisherId={entity.brand.publisher.id}
                supply={entity}
            />
        );
    }
    if (isAdUnit(entity)) {
        return (
            <InventoryAdUnitsTableActions
                adUnit={entity}
                brandId={entity.supply.brand.id}
                publisherId={entity.supply.brand.publisher.id}
                supplyId={entity.supply.id}
            />
        );
    }
    return null;
};
