import { useEffect } from "react";
import { useAppDispatch } from "@app/core/store";
import { setInitialSettings } from "@app/features/dashboard/reducer";
import { useSeatAuthContext } from "@app/core/auth";

export const useSetInitialSettings = (): void => {
    const dispatch = useAppDispatch();
    const { session, context, fetchSession } = useSeatAuthContext();

    useEffect(() => {
        if (session) {
            dispatch(setInitialSettings(session.user.userPreferenceMap));
        }
    }, [dispatch, session, context]);

    useEffect(() => {
        fetchSession();
    }, [fetchSession]);
};
