import { useUserAccess } from "@app/core/auth";
import { Route, Switch } from "react-router-dom";
import { SeatContactsPage } from "./SeatContactsPage";
import { ROUTES } from "@app/core/routing";
import { FC } from "react";
import { SeatContactsCreatePage } from "./SeatContactsCreatePage/SeatContactsCreatePage";
import { SeatContactsEditPage } from "./SeatContactsEditPage/SeatContactsEditPage";
import { SeatContactsDetailsPage } from "./SeatContactsDetailsPage";

export const SeatContactsSwitch: FC = () => {
    const { canEditSeat } = useUserAccess();

    return (
        <Switch>
            <Route exact path={[ROUTES.SEAT_CONTROLS_SEAT_CONTACTS]}>
                <SeatContactsPage />
            </Route>
            {canEditSeat && (
                <Route exact path={ROUTES.SEAT_CONTROLS_SEAT_CONTACTS_CREATE}>
                    <SeatContactsCreatePage />
                </Route>
            )}
            {canEditSeat && (
                <Route exact path={ROUTES.SEAT_CONTROLS_SEAT_CONTACTS_EDIT}>
                    <SeatContactsEditPage />
                </Route>
            )}
            {canEditSeat && (
                <Route exact path={ROUTES.SEAT_CONTROLS_SEAT_CONTACTS_DETAILS}>
                    <SeatContactsDetailsPage />
                </Route>
            )}
        </Switch>
    );
};
