import { EventTypes } from "@app/core/socket/types";

export enum EntityTypes {
    Seat = "seat",
    Publisher = "publisher",
    Brand = "brand",
    Supply = "supply",
    AdUnit = "adUnit",
    Deal = "deal",
    AdSource = "adSource",
    AdSourceAdServing = "adSourceAdServing",
}
export interface StatsByCurrency {
    cImps?: number;
    cFills?: number;
    cost: number;
    dFeeCost: number;
    edf: number;
    edi: number;
    edr: number;
    fee: number;
    filledRequests: number;
    impressions: number;
    impressionsWithFallbacks: number;
    rejections: number;
    rev: number;
    trev: number;
    udepf: number;
    udeu: number;
    udeupf: number;
    udeupr: number;
}

export interface AdStat {
    cpFills?: number;
    cpRequests?: number;
    csRequests?: number;
    adBreakStats: {
        adBreakCounts: Record<string, never>;
        adCounts: Record<string, never>;
        adDurations: Record<string, never>;
        playlistAdCounts: Record<string, never>;
        playlistDurations: Record<string, never>;
    };
    edt: number;
    fallbacks: number;
    ntime: number;
    otime: number;
    playlistStats: {
        ntime: number;
        otime: number;
        playlistCount: number;
        podsCount: number;
        slotsCount: number;
        statsByCurrency: {
            [currencyId: number]: StatsByCurrency;
        };
    };
    podStats: {
        ntime: number;
        otime: number;
        podsCount: number;
        slotsCount: number;
        statsByCurrency: {
            [currencyId: number]: StatsByCurrency;
        };
    };
    requests: number;
    results:
        | {
              "0": number; // no ad
              "1": number; // filled
              "-3": number; // bad ad call
              "-4": number; // non human traffic
              "-5": number; // user rejections
              "-6": number; // seat rejections
              "-7": number; // supply domain
              "-8": number; // supply domain not allow listed
              "-10": number; // bundle ID deny listed
              "-11": number; // bundle ID not allow listed
              "-12": number; // IP deny list
              "-13": number; // test ad unit
              "-14": number; // fraud traffic
              "-16": number; // VAST unwrap error
              "-18": number; // seat rejections tier 1
              "-19": number; // seat rejections tier 2
              "-20": number; // seat rejections tier 3
              "-27": number; // mokas
              "-999": number; // skips
          }
        | Record<string, number>;
    statsByCurrency: {
        [currencyId: number]: StatsByCurrency;
    };
    totalTime: number;
    tries: number;
    udepc: number;
    uder: number;
    udes: number;
    udeupc: number;
    watStats?: Record<string, never>;
}

export interface AdStatsById {
    [id: number]: AdStat | null;
}

export interface AdStatEventMessage {
    [id: number]: AdStat;
}

export interface AdStatHistoryById {
    [id: number]: AdStat[] | null;
}

export interface AdStatHistoryEventMessage {
    [id: number]: AdStat[];
}

export interface AdStatEvent {
    type: EventTypes.AdStat;
    message: AdStatEventMessage;
}

export interface AdStatHistoryEvent {
    type: EventTypes.AdStatHistory;
    message: AdStatHistoryEventMessage;
}

export interface AuthResultEvent {
    type: EventTypes.AuthResult;
    message: boolean;
}

export interface LogEvent {
    type: EventTypes.Log;
    message: string;
}

export interface LoginEvent {
    type: EventTypes.Login;
    message: string;
}
