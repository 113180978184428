import { FC } from "react";
import {
    SplineData,
    TimingStatsCumulativeEtity,
    TimingStatsEntity,
    TooltipCustomContentData,
    TooltipFilledUnFilledCumulativeData,
    TooltipFilledUnFilledData,
} from "../helpers";
import { CumulativeTotal } from "./CumulativeTotal";
import { FilledUnFilled } from "./FilledUnFilled";

interface Props {
    title: string;
    datum: TooltipCustomContentData;
    combinedData: SplineData[];
}
export const ChartTooltipContent: FC<Props> = ({ title, datum = [], combinedData }) => {
    //only if all columns and lines are shown
    //datum: 0 - filled, 1 - unfilled, 2 - cumulFilled, 3 - cumulUnfilled, 4 - Combined Total
    const filled = datum.find(
        (item: TooltipFilledUnFilledData) => item?.data?.type === TimingStatsEntity.FILLED
    ) as TooltipFilledUnFilledData;
    const unFilled = datum.find(
        (item: TooltipFilledUnFilledData) => item?.data?.type === TimingStatsEntity.UNFILLED
    ) as TooltipFilledUnFilledData;
    const filledCumulative = datum.find(
        (item: TooltipFilledUnFilledCumulativeData) => item?.data?.name === TimingStatsCumulativeEtity.FILLED_CUMULATIVE
    ) as TooltipFilledUnFilledCumulativeData;
    const unFilledCumulative = datum.find(
        (item: TooltipFilledUnFilledCumulativeData) =>
            item?.data?.name === TimingStatsCumulativeEtity.UNFILLED_CUMULATIVE
    ) as TooltipFilledUnFilledCumulativeData;
    const hasfilledAndUnfilled = Boolean(filled && unFilled);

    return (
        <div className="g2-tooltip-list">
            <div className="g2-tooltip-list-item">
                <div className="g2-tooltip-name">{title} ms</div>
            </div>

            {filled && (
                <FilledUnFilled
                    filledUnfilledData={filled}
                    cumulativeData={filledCumulative}
                    hasDivider={hasfilledAndUnfilled}
                />
            )}

            {unFilled && (
                <FilledUnFilled
                    filledUnfilledData={unFilled}
                    cumulativeData={unFilledCumulative}
                    hasDivider={hasfilledAndUnfilled}
                />
            )}

            {hasfilledAndUnfilled && <CumulativeTotal title={title} combinedData={combinedData} />}
        </div>
    );
};
