import { Form, Radio } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAdSourceExtendedTimeoutField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceConfigurationSection/Fields/AdSourceExtendTimeoutField/useAdSourceExtendedTimeoutField";

export const AdSourceExtendTimeoutField = () => {
    const { options, value, onChange } = useAdSourceExtendedTimeoutField();
    return (
        <Form.Item
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_EXTEND_TIMEOUT}
            label="Extend Timeout"
            data-sdet="ad-source-extend-timeout-field"
        >
            <Radio.Group options={options} value={value} onChange={onChange} />
        </Form.Item>
    );
};
