import { Brand, BrandSupply } from "./brands";
import {
    AD_UNIT_FLOORS_TAG,
    BRAND_FLOORS_TAG,
    consoleApi,
    FLOOR_TAG,
    PUBLISHER_FLOORS_TAG,
    SEAT_ADVANCED_FLOORS_LIST_TAG,
    SEAT_ADVANCED_FLOOR_TAG,
    SEAT_FLOORS_LIST_TAG,
    SUPPLY_FLOORS_TAG,
} from "./console";
import { Publisher } from "./publishers";
import { Seat } from "./seats";
import { Targeting, TargetingCreatePayload, TargetingUpdatePayload } from "./targeting";
import { toQueryString } from "./utils";
import { AdUnit, TargetingMode } from "@app/core/services";

interface Entity {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
}

export interface Floor {
    id: number;
    name: string;
    price: number;
    seat: null | Seat;
    publisher: null | Publisher;
    brand: null | Brand;
    supply: null | BrandSupply;
    adUnit: null | AdUnit;
    targeting: Targeting[];
    targetingMode: TargetingMode;
    type: Entity;
}

export interface AdvancedFloor extends Entity {
    seat: Pick<Seat, "id" | "code" | "name" | "entityType">;
    price: number;
    targeting: Targeting[];
    targetingMode: Entity;
}

interface PublisherFloorsParams {
    publisherId: number;
    list?: "all";
}

interface BrandFloorsParams {
    brandId: number;
    list?: "all";
}

interface SupplyFloorsParams {
    supplyId: number;
    list?: "all";
}

interface AdUnitFloorsParams {
    adUnitId: number;
    list?: "all";
}

export interface FloorCreatePayload {
    id: number | null;
    name?: string;
    price: number;
    seat?: {
        id: number;
    };
    publisher?: {
        id: number;
    };
    brand?: {
        id: number;
    };
    supply?: {
        id: number;
    };
    adUnit?: {
        id: number;
    };
    targeting?: (TargetingCreatePayload | TargetingUpdatePayload)[];
    targetingMode?: TargetingMode;
}

export interface FloorUpdatePayload {
    name?: string;
    price: number;
    seat?: {
        id: number;
    };
    publisher?: {
        id: number;
    };
    brand?: {
        id: number;
    };
    supply?: {
        id: number;
    };
    adUnit?: {
        id: number;
    };
    targeting: (TargetingCreatePayload | TargetingUpdatePayload)[];
    targetingMode?: TargetingMode;
}

interface CreateSeatFloorParams {
    seatId: number;
    body: FloorCreatePayload;
}

interface CreatePublisherFloorParams {
    publisherId: number;
    body: FloorCreatePayload;
}

interface CreateBrandFloorParams {
    brandId: number;
    body: FloorCreatePayload;
}

interface CreateSupplyFloorParams {
    supplyId: number;
    body: FloorCreatePayload;
}

interface CreateAdUnitFloorParams {
    adUnitId: number;
    body: FloorCreatePayload;
}

export interface UpdateFloorParams {
    id: number;
    body: Partial<FloorUpdatePayload>;
}

export const floorsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getFloorById: builder.query<Floor, number>({
            query: (id: number) => `/floors/${id}`,
            providesTags: (_, __, id) => [{ type: FLOOR_TAG, id }],
        }),
        getSeatFloors: builder.query<Floor[], number>({
            query: (id: number) => `seats/${id}/floors;list=all`,
            providesTags: (_, __, id) => [{ type: SEAT_FLOORS_LIST_TAG, id }],
        }),
        getSeatAdvancedFloors: builder.query<Floor[], number>({
            query: (id: number) => `seats/${id}/advancedFloors`,
            providesTags: (_, __, id) => [{ type: SEAT_ADVANCED_FLOORS_LIST_TAG, id }],
        }),
        getSeatAdvancedFloorById: builder.query<AdvancedFloor, number>({
            query: (id: number) => `seats/advancedFloors/${id}`,
            providesTags: (_, __, id) => [{ type: SEAT_ADVANCED_FLOOR_TAG, id }],
        }),
        getPublisherFloors: builder.query<Floor[], PublisherFloorsParams>({
            query: ({ publisherId, ...rest }: PublisherFloorsParams) => {
                const params: Omit<PublisherFloorsParams, "publisherId"> = {
                    ...rest,
                };
                return `publishers/${publisherId}/floors;${toQueryString(params)}`;
            },
            providesTags: (_, __, { publisherId }) => [{ type: PUBLISHER_FLOORS_TAG, id: publisherId }],
        }),
        getBrandFloors: builder.query<Floor[], BrandFloorsParams>({
            query: ({ brandId, ...rest }: BrandFloorsParams) => {
                const params: Omit<BrandFloorsParams, "brandId"> = {
                    ...rest,
                };
                return `brands/${brandId}/floors;${toQueryString(params)}`;
            },
            providesTags: (_, __, { brandId }) => [{ type: BRAND_FLOORS_TAG, id: brandId }],
        }),
        getSupplyFloors: builder.query<Floor[], SupplyFloorsParams>({
            query: ({ supplyId, ...rest }: SupplyFloorsParams) => {
                const params: Omit<SupplyFloorsParams, "supplyId"> = {
                    ...rest,
                };
                return `supply/${supplyId}/floors;${toQueryString(params)}`;
            },
            providesTags: (_, __, { supplyId }) => [{ type: SUPPLY_FLOORS_TAG, id: supplyId }],
        }),
        getAdUnitFloors: builder.query<Floor[], AdUnitFloorsParams>({
            query: ({ adUnitId, ...rest }: AdUnitFloorsParams) => {
                const params: Omit<AdUnitFloorsParams, "adUnitId"> = {
                    ...rest,
                };
                return `adUnits/${adUnitId}/floors;${toQueryString(params)}`;
            },
            providesTags: (_, __, { adUnitId }) => [{ type: AD_UNIT_FLOORS_TAG, id: adUnitId }],
        }),
        createAdvancedFloor: builder.mutation<Floor, CreateSeatFloorParams>({
            query: (arg: CreateSeatFloorParams) => {
                const { seatId, body } = arg;
                return {
                    url: `seats/${seatId}/advancedFloors`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (_, err) => (err ? [] : [SEAT_ADVANCED_FLOORS_LIST_TAG]),
        }),
        createSeatFloor: builder.mutation<Floor, CreateSeatFloorParams>({
            query: (arg: CreateSeatFloorParams) => {
                const { seatId, body } = arg;
                return {
                    url: `seats/${seatId}/floors`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (_, err) => (err ? [] : [SEAT_FLOORS_LIST_TAG]),
        }),
        createPublisherFloor: builder.mutation<Floor, CreatePublisherFloorParams>({
            query: (arg: CreatePublisherFloorParams) => {
                const { publisherId, body } = arg;
                return {
                    url: `publishers/${publisherId}/floors`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (_, err) => (err ? [] : [PUBLISHER_FLOORS_TAG]),
        }),
        createBrandFloor: builder.mutation<Floor, CreateBrandFloorParams>({
            query: (arg: CreateBrandFloorParams) => {
                const { brandId, body } = arg;
                return {
                    url: `brands/${brandId}/floors`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (_, err) => (err ? [] : [BRAND_FLOORS_TAG]),
        }),
        createSupplyFloor: builder.mutation<Floor, CreateSupplyFloorParams>({
            query: (arg: CreateSupplyFloorParams) => {
                const { supplyId, body } = arg;
                return {
                    url: `supply/${supplyId}/floors`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (_, err) => (err ? [] : [SUPPLY_FLOORS_TAG]),
        }),
        createAdUnitFloor: builder.mutation<Floor, CreateAdUnitFloorParams>({
            query: (arg: CreateAdUnitFloorParams) => {
                const { adUnitId, body } = arg;
                return {
                    url: `adUnits/${adUnitId}/floors`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (_, err) => (err ? [] : [AD_UNIT_FLOORS_TAG]),
        }),
        updateFloor: builder.mutation<Floor, UpdateFloorParams>({
            query: ({ id, body }: UpdateFloorParams) => ({
                url: `/floors/${id}`,
                method: "PUT",
                body: body,
            }),
            invalidatesTags: (_, err) =>
                err
                    ? []
                    : [
                          SEAT_FLOORS_LIST_TAG,
                          PUBLISHER_FLOORS_TAG,
                          BRAND_FLOORS_TAG,
                          SUPPLY_FLOORS_TAG,
                          AD_UNIT_FLOORS_TAG,
                      ],
        }),
        updateAdvancedFloor: builder.mutation<AdvancedFloor, CreateSeatFloorParams>({
            query: ({ seatId: floorId, body }) => ({
                url: `seats/advancedFloors/${floorId}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: (_, err) => (err ? [] : [SEAT_ADVANCED_FLOORS_LIST_TAG, SEAT_ADVANCED_FLOOR_TAG]),
        }),
        deleteFloor: builder.mutation<unknown, number>({
            query: (id: number) => ({
                url: `floors/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err) =>
                err
                    ? []
                    : [
                          SEAT_FLOORS_LIST_TAG,
                          PUBLISHER_FLOORS_TAG,
                          BRAND_FLOORS_TAG,
                          SUPPLY_FLOORS_TAG,
                          AD_UNIT_FLOORS_TAG,
                      ],
        }),
        deleteAdvancedFloor: builder.mutation<unknown, number>({
            query: (id: number) => ({
                url: `seats/advancedFloors/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err) => (err ? [] : [SEAT_ADVANCED_FLOORS_LIST_TAG]),
        }),
    }),
    overrideExisting: false,
});

export const {
    useCreateAdvancedFloorMutation,
    useCreateBrandFloorMutation,
    useCreatePublisherFloorMutation,
    useCreateAdUnitFloorMutation,
    useCreateSupplyFloorMutation,
    useCreateSeatFloorMutation,
    useDeleteAdvancedFloorMutation,
    useDeleteFloorMutation,
    useGetBrandFloorsQuery,
    useGetFloorByIdQuery,
    useGetPublisherFloorsQuery,
    useGetSupplyFloorsQuery,
    useGetAdUnitFloorsQuery,
    useGetSeatAdvancedFloorsQuery,
    useGetSeatAdvancedFloorByIdQuery,
    useGetSeatFloorsQuery,
    useUpdateFloorMutation,
    useUpdateAdvancedFloorMutation,
} = floorsApi;
