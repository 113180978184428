import { Form, InputNumber } from "antd";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { useIsFIeldVisible } from "./useIsFieldVisible";

export const ShareOfVoiceInput = () => {
    const isVisible = useIsFIeldVisible();
    if (!isVisible) {
        return null;
    }
    return (
        <Form.Item
            name={AD_SOURCE_FIELDS.SHARE_OF_VOICE_PERCENT.name}
            label={AD_SOURCE_FIELDS.SHARE_OF_VOICE_PERCENT.label}
            data-sdet={`test-${AD_SOURCE_FIELDS.SHARE_OF_VOICE_PERCENT.name}-field`}
            rules={[
                {
                    required: true,
                    message: `${AD_SOURCE_FIELDS.SHARE_OF_VOICE_PERCENT.label} is required`,
                },
            ]}
            {...VERTICAL_LAYOUT_FIX}
        >
            <InputNumber
                style={{ width: "100%" }}
                placeholder={`Enter ${AD_SOURCE_FIELDS.SHARE_OF_VOICE_PERCENT.label}`}
                data-sdet={`test-${AD_SOURCE_FIELDS.SHARE_OF_VOICE_PERCENT.name}-input`}
            />
        </Form.Item>
    );
};
