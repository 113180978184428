import { FC } from "react";
import { Button, Col, Row, Space, Typography } from "antd";
import { LabeledValue } from "antd/lib/select";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { BracketGroup } from "@rubicon/antd-components";
import { red5 } from "@rubicon/antd-components";
import { GroupConditions, SegmentRuleIdentifierOperators } from "@app/features/targeting/constants";
import { SegmentRuleGroup, SegmentRule, SegmentRuleIdentifier } from "@app/features/targeting/types";
import { GroupConditionSelect } from "../../GroupConditionSelect";
import { SegmentRuleIdentifierRow } from "./SegmentRuleIdentifierRow";

interface Props {
    group: SegmentRuleGroup;
    isValidationShown: boolean;
    onChangeCondition: (path: number[], value: GroupConditions) => void;
    onChangeOperator: (path: number[], value: SegmentRuleIdentifierOperators) => void;
    onChangeValue: (path: number[], value: LabeledValue | null) => void;
    onClickAddRule: (path: number[]) => void;
    onClickAddRuleGroup: (path: number[]) => void;
    onClickDeleteRule: (path: number[]) => void;
    path: number[];
}

const isSegmentRuleIdentifier = (rule: SegmentRule): rule is SegmentRuleIdentifier => {
    return "id" in rule;
};

const getGroupColor = (condition: GroupConditions) => (condition === GroupConditions.Or ? "#7666F9" : "#C1715B");

const MAX_SUBGROUP_DEPTH = 2; // 3 total depth with root

export const SegmentRuleGroupRow: FC<Props> = ({
    group,
    isValidationShown,
    onChangeCondition,
    onChangeOperator,
    onChangeValue,
    onClickAddRule,
    onClickAddRuleGroup,
    onClickDeleteRule,
    path,
}) => {
    return (
        <Row>
            <Col xs={24}>
                <BracketGroup
                    isSubBracket={Boolean(path.length)}
                    color={getGroupColor(group.condition)}
                    label={
                        <Space>
                            <GroupConditionSelect
                                value={group.condition}
                                onChange={(nextCondition) => onChangeCondition(path, nextCondition)}
                            />
                            {path.length > 0 && (
                                <Button
                                    type="link"
                                    size="small"
                                    icon={<DeleteOutlined />}
                                    onClick={() => onClickDeleteRule(path)}
                                />
                            )}
                        </Space>
                    }
                >
                    <Space direction="vertical" style={{ width: "100%", paddingTop: "5px" }}>
                        {isValidationShown && !group.rules.length && (
                            <Typography.Text style={{ color: red5 }}>
                                At least one condition or subgroup is required
                            </Typography.Text>
                        )}
                        {group.rules.map((rule, groupIndex) => {
                            const pathStr = path.join(",");
                            if (isSegmentRuleIdentifier(rule)) {
                                return (
                                    <SegmentRuleIdentifierRow
                                        isValidationShown={isValidationShown}
                                        key={`${rule.value}-${pathStr}-${groupIndex}`}
                                        rule={rule}
                                        path={[...path, groupIndex]}
                                        onChangeOperator={(nextPath, operator) => onChangeOperator(nextPath, operator)}
                                        onChangeValue={(nextPath, value) => onChangeValue(nextPath, value)}
                                        onClickDeleteRule={(nextPath) => onClickDeleteRule(nextPath)}
                                    />
                                );
                            }
                            return (
                                <SegmentRuleGroupRow
                                    group={rule}
                                    isValidationShown={isValidationShown}
                                    key={`ruleGroup-${pathStr}-${groupIndex}`}
                                    onChangeCondition={onChangeCondition}
                                    onChangeOperator={onChangeOperator}
                                    onChangeValue={onChangeValue}
                                    onClickAddRule={onClickAddRule}
                                    onClickAddRuleGroup={onClickAddRuleGroup}
                                    onClickDeleteRule={onClickDeleteRule}
                                    path={[...path, groupIndex]}
                                />
                            );
                        })}
                        <Row>
                            <Col xs={24}>
                                <Space>
                                    <Button
                                        type="link"
                                        size="small"
                                        icon={<PlusOutlined />}
                                        onClick={() => onClickAddRule(path)}
                                    >
                                        Condition
                                    </Button>
                                    {path.length < MAX_SUBGROUP_DEPTH && (
                                        <Button
                                            type="link"
                                            size="small"
                                            icon={<PlusOutlined />}
                                            onClick={() => onClickAddRuleGroup(path)}
                                        >
                                            Subgroup
                                        </Button>
                                    )}
                                </Space>
                            </Col>
                        </Row>
                    </Space>
                </BracketGroup>
            </Col>
        </Row>
    );
};
