import { useSeatAuthContext } from "@app/core/auth";
import { useAppSelector } from "@app/core/store";
import { InventoryDetailsDrawerType, selectInventoryDetailsDrawerType } from "../../reducer";
import { useNestedData } from "../useNestedData";

interface UseTagParams {
    isLoading: boolean;
    title: string;
    breadcrumbs?: string;
}

export const useTagParamsContent = (id: number | string): UseTagParams => {
    const { nestedBreadcrumbs, isLoading } = useNestedData(id);
    const { context } = useSeatAuthContext();
    const detailsDrawerType = useAppSelector(selectInventoryDetailsDrawerType);
    const isSeat = detailsDrawerType === InventoryDetailsDrawerType.SEAT;
    const title = isSeat ? `Seat Inbound Request Params: ${String(context?.name)}` : "Inbound Request Params";
    const breadcrumbs = isSeat ? undefined : nestedBreadcrumbs;

    return {
        isLoading,
        title,
        breadcrumbs,
    };
};
