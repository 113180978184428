import { Typography, Space } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { blue6 } from "@rubicon/antd-components";

export const InternalUseOnlyInfo = () => (
    <Typography.Text>
        <Space size="small">
            <InfoCircleOutlined style={{ color: blue6 }} />
            <Typography.Text>
                The following info is for internal use only. External users do not have access to this section.
            </Typography.Text>
        </Space>
    </Typography.Text>
);
