import { useGetDealOriginsQuery } from "@app/core/services";
import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAppDispatch, useAppSelector } from "@app/core/store";

interface UseDealOriginField {
    isFetching: boolean;
    options: LabeledValue[];
    value: number | null;
    handleSelect: (value: number) => void;
}

export const useDealOriginField = (): UseDealOriginField => {
    const dispatch = useAppDispatch();
    const { data, isFetching } = useGetDealOriginsQuery();
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.ORIGIN]);
    const options = useMemo(() => (data || []).map((item) => ({ label: item.name, value: item.id })), [data]);

    const handleSelect = (value: number): void => {
        dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.ORIGIN, value }));
    };

    return {
        isFetching,
        options,
        value,
        handleSelect,
    };
};
