import { VERSION_TAG, appsApi } from "./appsApi";

interface AppVersion {
    timestamp: string;
    version: string;
}

const version = appsApi.injectEndpoints({
    endpoints: (builder) => ({
        getAppVersion: builder.query<AppVersion, void>({
            query: () => "version.json",
            providesTags: () => [VERSION_TAG],
            transformResponse: (response: AppVersion) => ({
                ...response,
                version: window["qaAppVersion"] || response.version, // the window check is purely for QA purposes
            }),
        }),
    }),
});

export const { useGetAppVersionQuery } = version;
