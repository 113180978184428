import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdStat, AdStatsById, AdStatHistoryById } from "@app/features/adStats";
import { RootState } from "@app/core/store";
import { useSelector } from "react-redux";

export interface AdStatsState {
    isAuthenticated: boolean;
    isConnected: boolean;
    error: Error | null;
    events: {
        adStatById: AdStatsById;
        adStatHistoryById: AdStatHistoryById;
        adStatDealById: AdStatsById;
        adStatDealHistoryById: AdStatHistoryById;
    };
    subscriberCount: number;
}

const initialState: AdStatsState = {
    isConnected: false,
    isAuthenticated: false,
    error: null,
    events: {
        adStatById: {},
        adStatHistoryById: {},
        adStatDealById: {},
        adStatDealHistoryById: {},
    },
    subscriberCount: 0,
};

const adStatsSlice = createSlice({
    name: "adStats",
    initialState,
    reducers: {
        setAdStatsIsAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload;
        },
        setAdStatsIsConnected: (state, action: PayloadAction<boolean>) => {
            state.isConnected = action.payload;
        },
        setAdStatsError: (state, action: PayloadAction<Error>) => {
            state.error = action.payload;
        },
        setAdStatsAdStatEvent: (state, action: PayloadAction<{ [id: number]: AdStat }>) => {
            state.events.adStatById = action.payload;
        },
        setAdStatsAdStatHistoryEvent: (state, action: PayloadAction<{ [id: number]: AdStat[] }>) => {
            state.events.adStatHistoryById = action.payload;
        },
        // Deals Ad Stats
        setAdStatsDealAdStatEvent: (state, action: PayloadAction<{ [id: number]: AdStat }>) => {
            state.events.adStatDealById = action.payload;
        },
        setAdStatsDealAdStatHistoryEvent: (state, action: PayloadAction<{ [id: number]: AdStat[] }>) => {
            state.events.adStatDealHistoryById = action.payload;
        },
        addAdStatsDealSubscriber: (state) => {
            ++state.subscriberCount;
        },
        removeAdStatsDealSubscriber: (state) => {
            if (state.subscriberCount > 0) {
                --state.subscriberCount;
            }
        },
    },
});

// Selectors
export const selectAdStatsById = (state: RootState) => state.adStats.events.adStatById;
export const selectAdStatHistoryById = (state: RootState, seatId: number) =>
    state.adStats.events.adStatHistoryById[seatId];
export const useSelectAdStatDealById = <T>(dealId: number, callback: (adStat: AdStat | null) => T) => {
    const adStat: AdStat | null = useSelector((state: RootState) => state.adStats.events.adStatDealById[dealId]);
    return callback(adStat);
};
export const selectAdStatDealById = (state: RootState, dealId: number) => state.adStats.events.adStatDealById[dealId];
export const selectAdStatDealHistoryById = (state: RootState, dealId: number) =>
    state.adStats.events.adStatDealHistoryById[dealId];
export const selectAdStatDealSubscriberCount = (state: RootState) => state.adStats.subscriberCount;

export const {
    setAdStatsAdStatEvent,
    setAdStatsAdStatHistoryEvent,
    setAdStatsError,
    setAdStatsIsAuthenticated,
    setAdStatsIsConnected,
    setAdStatsDealAdStatEvent,
    setAdStatsDealAdStatHistoryEvent,
    addAdStatsDealSubscriber,
    removeAdStatsDealSubscriber,
} = adStatsSlice.actions;

export default adStatsSlice.reducer;
