import { LabeledValue } from "antd/lib/select";
import { useGetAdSourcePrioritiesQuery } from "@app/core/services";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";

interface UseAdSourcePriority {
    options: LabeledValue[];
    isFetching: boolean;
    onSelect: (value: number) => void;
    value: number;
}

export const useAdSourcePriority = (): UseAdSourcePriority => {
    const dispatch = useAppDispatch();
    const { data, isFetching } = useGetAdSourcePrioritiesQuery();
    const options = useMemo(() => (data || []).map((item) => ({ label: item.name, value: item.id })), [data]);
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PRIORITY]);

    const onSelect = (value: number): void => {
        dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PRIORITY, value }));
    };
    return {
        options,
        isFetching,
        value,
        onSelect,
    };
};
