import { FC } from "react";
import { Row, Col, Tabs } from "antd";
import { Loading } from "@app/core/components";
import { DetailsDrawerLayout } from "@app/features/inventory/DetailsDrawer/DetailsDrawerLayout";
import {
    FloorAnalysisTabsType,
    useFloorAnalysisContent,
} from "@app/features/inventory/DetailsDrawer/DetailsDrawerContent/FloorAnalysisContent/useFloorAnalysisContent";
import { useInventoryDetailsDrawerUrlId } from "@app/features/inventory/DetailsDrawer/useInventoryDetailsDrawerUrlId";
import { EstimatedFloorBreakdown } from "@app/features/inventory/DetailsDrawer/DetailsDrawerContent/FloorAnalysisContent/EstimatedFloorBreakdown";
import { HistoricalFloorAnalysis } from "@app/features/inventory/DetailsDrawer/DetailsDrawerContent/FloorAnalysisContent/HistoricalFloorAnalysis";

export const FloorAnalysisContent: FC = () => {
    const unitId = useInventoryDetailsDrawerUrlId();
    const { title, breadcrumbs, isLoading, activeTab, handleChangeTab } = useFloorAnalysisContent(unitId);

    if (isLoading) return <Loading />;

    return (
        <Row>
            <DetailsDrawerLayout title={title} breadcrumbs={breadcrumbs} />
            <Col style={{ padding: "1.5rem", width: "100%" }}>
                <Tabs
                    activeKey={activeTab}
                    destroyInactiveTabPane
                    onChange={handleChangeTab}
                    items={[
                        {
                            label: "Estimated Floor Breakdown",
                            key: FloorAnalysisTabsType.estimatedFloorBreakdown,
                            children: <EstimatedFloorBreakdown id={unitId} activeTab={activeTab} />,
                        },
                        {
                            label: "Historical Floor Analysis",
                            key: FloorAnalysisTabsType.historicalFloorAnalysis,
                            children: <HistoricalFloorAnalysis id={unitId} activeTab={activeTab} />,
                        },
                    ]}
                />
            </Col>
        </Row>
    );
};
