import { FC } from "react";
import { Form, Radio } from "antd";
import { RadioProps } from "antd/es/radio";
import { PLAYLIST_DEFINITION_MODE } from "@app/features/inventory/HierarchyForms/constants";
import { useChannelPlaylistDefinitionMode } from "@app/features/inventory/components/FormItems/ChannelPlaylistDefinitionMode/useChannelPlaylistDefinitionMode";

interface Props extends RadioProps {
    name: typeof PLAYLIST_DEFINITION_MODE;
    label: string;
}

export const ChannelPlaylistDefinitionMode: FC<Props> = ({ label, name, ...rest }) => {
    const { options } = useChannelPlaylistDefinitionMode();
    return (
        <Form.Item name={name} label={label}>
            <Radio.Group size="large" options={options} {...rest} />
        </Form.Item>
    );
};
