import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { Form, Input } from "antd";
import { useBundleIdOverride } from "./useBundleIdOverride";

export const BundleIdOverride = () => {
    const { isVisible } = useBundleIdOverride();
    return isVisible ? (
        <Form.Item
            labelCol={{ lg: 8 }}
            labelAlign="left"
            name={AD_SOURCE_FIELDS.BUNDLE_ID_OVERRIDE.name}
            label={AD_SOURCE_FIELDS.BUNDLE_ID_OVERRIDE.label}
            colon={false}
            style={{ marginBottom: 8 }}
            rules={[{ required: true, whitespace: true, message: "Bundle ID Override is required" }]}
        >
            <Input />
        </Form.Item>
    ) : null;
};
