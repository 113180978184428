import { FC } from "react";
import { GroupConditions, GROUP_CONDITION_OPTIONS } from "@app/features/targeting/constants";
import { DropdownSelect } from "@app/features/targeting/CreateTargeting/DropdownSelect";

interface Props {
    value: GroupConditions;
    onChange: (value: GroupConditions) => void;
}

const getPrimaryColor = (condition: GroupConditions) => (condition === GroupConditions.Or ? "#7666F9" : "#C1715B");

export const GroupConditionSelect: FC<Props> = ({ value, onChange }) => {
    const primaryColor = getPrimaryColor(value);

    return <DropdownSelect color={primaryColor} onChange={onChange} options={GROUP_CONDITION_OPTIONS} value={value} />;
};
