import { Form } from "antd";
import { FC } from "react";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import { COMPETITIVE_SEPARATION_MODE } from "@app/features/inventory/HierarchyForms/constants";
import { useAdUnitCompetitiveSeparationMode } from "@app/features/inventory/components/FormItems/AdUnitCompetitiveSeparationMode/useAdUnitCompetitiveSeparationMode";

interface AdUnitLinearityProps {
    name: typeof COMPETITIVE_SEPARATION_MODE;
    label: string;
}

const falseLabel = "Industries";
const trueLabel = "IAB Categories";

export const AdUnitCompetitiveSeparationMode: FC<AdUnitLinearityProps> = ({ name, label }) => {
    const { onChange } = useAdUnitCompetitiveSeparationMode();
    return (
        <Form.Item name={name} label={label}>
            <BooleanRadioGroup onChange={onChange} trueLabel={trueLabel} falseLabel={falseLabel} />
        </Form.Item>
    );
};
