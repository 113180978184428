import { NONE } from "@app/core/components/constants";
import { AdomainFreqCapping, LabelValue, ThirdPartyPixel } from "@app/core/services";
import { GUTTER, INNER_COL_SIZES, TWO_COL_SIZES } from "@app/features/inventory/constants";
import { Col, Form, Row, Tag } from "antd";
import { FC } from "react";
import {
    AD_UNIT_BRAND_SAFETY_LABELS,
    AD_UNIT_SUPPLY_DETAILS_LABELS,
    CHANNEL_DETAILED_CONFIGURATION_LABELS,
    FORM_ITEM_OFFSET,
    PUBLISHER_GENERAL_LABELS,
    SUPPLY_SUPPLY_DETAILS_LABELS,
} from "../../../constants";
import { Pixels } from "../../../SharedSections";
import { getEnabledDisabled, getYesNoLabel } from "@app/core/utils/labels";
import { getDimension } from "@app/features/inventory/helpers";
import { InternalOnly } from "@app/features/inventory/components/InternalOnly";

const { Item } = Form;

interface Props {
    isTremorUser: boolean;
    isInternalOnly: boolean;
    unwrapVast: boolean;
    allowVastWrappers: boolean;
    impressionWaitTime: string;
    adomainFreqCappings: AdomainFreqCapping[];
    businessDomain?: string;
    enableNoAdCookieSync: boolean | null;
    labels?: LabelValue[];
    thirdPartyPixles?: ThirdPartyPixel[];
    publisherReauction?: boolean | null;
    enableAdvertisersAsWhitelist: boolean;
    internalLabelValues?: LabelValue[];
}

export const DetailedConfiguration: FC<Props> = ({
    isTremorUser,
    isInternalOnly,
    unwrapVast,
    allowVastWrappers,
    impressionWaitTime,
    adomainFreqCappings,
    businessDomain,
    enableNoAdCookieSync,
    thirdPartyPixles,
    enableAdvertisersAsWhitelist,
    publisherReauction,
}) => {
    return (
        <Row data-sdet="channel-detailed-configuration">
            <Col {...TWO_COL_SIZES}>
                <Row gutter={GUTTER}>
                    <Col {...INNER_COL_SIZES}>
                        <Item label={PUBLISHER_GENERAL_LABELS.businessDomain} className={FORM_ITEM_OFFSET}>
                            {businessDomain ? businessDomain : NONE}
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...INNER_COL_SIZES}>
                        <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.unwrapVast} className={FORM_ITEM_OFFSET}>
                            {getYesNoLabel(unwrapVast)}
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...INNER_COL_SIZES}>
                        <Item label={SUPPLY_SUPPLY_DETAILS_LABELS.wrapper} className={FORM_ITEM_OFFSET}>
                            {getYesNoLabel(allowVastWrappers)}
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...INNER_COL_SIZES}>
                        <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.extendedImpWaitTime} className={FORM_ITEM_OFFSET}>
                            {impressionWaitTime ?? NONE}
                        </Item>
                    </Col>
                </Row>

                <Pixels columnProps={{ ...INNER_COL_SIZES }} id={-1} thirdPartyPixels={thirdPartyPixles} />

                <Row gutter={GUTTER}>
                    <Col {...INNER_COL_SIZES}>
                        <Item label={AD_UNIT_BRAND_SAFETY_LABELS.adFreqCaps} className={FORM_ITEM_OFFSET}>
                            {adomainFreqCappings?.length
                                ? adomainFreqCappings.map((capp) => (
                                      <Tag key={capp.id}>{`${capp.total} ${capp?.mode?.name} in ${getDimension(
                                          capp.timeDurationMin
                                      )}`}</Tag>
                                  ))
                                : NONE}
                        </Item>
                    </Col>
                </Row>
            </Col>
            <Col hidden={!isTremorUser} {...TWO_COL_SIZES}>
                <InternalOnly>
                    <Item label={CHANNEL_DETAILED_CONFIGURATION_LABELS.noAdCookieSync} className={FORM_ITEM_OFFSET}>
                        {getYesNoLabel(Boolean(enableNoAdCookieSync))}
                    </Item>
                    <Item
                        hidden={!isInternalOnly}
                        label={CHANNEL_DETAILED_CONFIGURATION_LABELS.advAsAllowList}
                        className={FORM_ITEM_OFFSET}
                    >
                        {getEnabledDisabled(enableAdvertisersAsWhitelist)}
                    </Item>
                    <Item
                        hidden={!isInternalOnly}
                        label={CHANNEL_DETAILED_CONFIGURATION_LABELS.publisherReAuction}
                        className={FORM_ITEM_OFFSET}
                    >
                        {publisherReauction === null ? "Not Specified" : getYesNoLabel(Boolean(publisherReauction))}
                    </Item>
                </InternalOnly>
            </Col>
        </Row>
    );
};
