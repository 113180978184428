import { TransparencyDefaultForm, useGetAvailableNetworksQuery, useGetExtendedIdsQuery } from "@app/core/services";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Form } from "antd";
import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { uri } from "@rubicon/utils";
import { useNotificationError } from "@app/core/components/hooks";
import { nameIdToLabelValueMapper } from "../helpers";
import { DRAWER } from "../constants";

export const useTransparencyExtenderUserDrawer = () => {
    const [form] = Form.useForm<TransparencyDefaultForm>();

    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();
    const { search } = useLocation();
    const drawerId = useMemo(() => uri.getSearchParam(search, "drawer"), [search]);
    const {
        data: extendedUserRaw,
        isLoading: isUserOptionsLoading,
        error: errorUserOptions,
    } = useGetExtendedIdsQuery(Number(seatId), { skip: !drawerId }); // TODO remove skip?
    const {
        data: availableNetworkRaw,
        isLoading: isNetworkLoading,
        error: errorDspsOptions,
    } = useGetAvailableNetworksQuery({ seatId: Number(seatId), extendedId: null }, { skip: !drawerId });

    useNotificationError(errorUserOptions || errorDspsOptions);

    const handleDrawerClose = () => {
        const nextSearch = uri.deleteSearchParam(search, DRAWER.key);
        history.push({ search: nextSearch });
    };

    const extendedUserIdsOptions: LabeledValue[] = useMemo(
        () => (extendedUserRaw || []).map(nameIdToLabelValueMapper),
        [extendedUserRaw]
    );

    const networkOptions: LabeledValue[] = useMemo(
        () => (availableNetworkRaw || []).map(nameIdToLabelValueMapper),
        [availableNetworkRaw]
    );

    return {
        isLoading: isUserOptionsLoading || isNetworkLoading,
        form,
        drawerId,
        extendedUserIdsOptions,
        extendedUserRaw,
        isNetworkLoading,
        isUserOptionsLoading,
        availableNetworkRaw,
        networkOptions,
        handleDrawerClose,
    };
};
