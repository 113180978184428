import { HelpKeysDrawer, HelpKeysDrawerContent } from "@app/core/components/HelpKeysDrawer";
import { useLocation } from "react-router-dom";

export const CLASSIFICATIONS_INDUSTRIES_DEFINITION_FIELDS = [
    { label: "Name", key: "name", helpPath: "industries.name" },
    { label: "Code", key: "code", helpPath: "industries.code" },
    { label: "Description", key: "description", helpPath: "industries.description" },
    { label: "Advertisers", key: "advertisers", helpPath: "industry.advertisers" },
    { label: "Date Created", key: "dateCreated", helpPath: "industry.creationdate" },
    { label: "Last Updated", key: "updateDate", helpPath: "industry.updateDate" },
];

export const ClassificationsIndustryDefinitionsContent = () => (
    <HelpKeysDrawerContent definitionsFields={CLASSIFICATIONS_INDUSTRIES_DEFINITION_FIELDS} />
);

export const ClassificationsIndustryDefinitionsButtonAndDrawer = () => {
    const { pathname } = useLocation();
    const isCreateMode = pathname.includes("/create");
    const definitionsFields = isCreateMode
        ? CLASSIFICATIONS_INDUSTRIES_DEFINITION_FIELDS.slice(0, -2)
        : CLASSIFICATIONS_INDUSTRIES_DEFINITION_FIELDS;

    return (
        <HelpKeysDrawer
            buttonName="Industries"
            panelLabel="Industry Definitions"
            definitionsFields={definitionsFields}
        />
    );
};
