import { FC } from "react";
import { AdUnitSupportedProtocols } from "@app/features/inventory/components/FormItems";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const SupportedProtocols: FC = () => (
    <AdUnitSupportedProtocols
        name={AD_UNIT_FORM_FIELDS.SUPPORTED_PROTOCOLS.name}
        label={AD_UNIT_FORM_FIELDS.SUPPORTED_PROTOCOLS.label}
        placeholder={AD_UNIT_FORM_FIELDS.SUPPORTED_PROTOCOLS.placeholder}
    />
);
