import { ROUTE_FORMATTERS } from "@app/core/routing";
import {
    AD_UNITS_MULTIPLICITIES,
    CHANNEL_FORM_FIELDS,
    DEFAULT_PLAYLIST_DEFINITION_MODE,
} from "@app/features/inventory/HierarchyForms/constants";
import { CONTROLS_ROUTES } from "@app/features/controls/constants";
import { usePublisherChannelFormInstance } from "@app/features/inventory/HierarchyForms/PublisherChannelForm/PublisherChannelFormInstance";

interface UseAdvancedFeaturesSection {
    isPodSectionShown: boolean;
    advancedAdBreaksUrl: string;
    isFloorsSectionShown: boolean;
    isPlaylistSectionShown: boolean;
    isAdBreakRulesSectionShown: boolean;
}

const JSTREE_NEW = -999; //TODO remove when we have own controls instead of iframe

export const useAdvancedFeaturesSection = (): UseAdvancedFeaturesSection => {
    const { useWatch, mode, seatId } = usePublisherChannelFormInstance();

    const multiplicity: number = useWatch(CHANNEL_FORM_FIELDS.MULTIPLICITY.name);
    const playlistDifinitionMode = useWatch(CHANNEL_FORM_FIELDS.PLAYLIST_DEFINITION_MODE.name);

    const isPodSectionShown: boolean = multiplicity === AD_UNITS_MULTIPLICITIES.POD.value;
    const isPlaylistSectionShown: boolean = multiplicity === AD_UNITS_MULTIPLICITIES.PLAYLIST.value;

    const isAdBreakRulesSectionShown: boolean = playlistDifinitionMode === DEFAULT_PLAYLIST_DEFINITION_MODE.value;

    const isFloorsSectionShown: boolean = mode === "edit";

    const advancedAdBreaksUrl: string = ROUTE_FORMATTERS.SEAT_CONTROLS_TABS_CREATE(
        seatId,
        CONTROLS_ROUTES.ADVANCED_AD_BREAKS,
        JSTREE_NEW
    );
    return {
        isFloorsSectionShown,
        isPodSectionShown,
        isPlaylistSectionShown,
        isAdBreakRulesSectionShown,
        advancedAdBreaksUrl,
    };
};
