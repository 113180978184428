import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { Form } from "antd";
import { useEffect } from "react";
import { DEAL_FORM_FIELDS } from "../../../../constants";
import { RunDateMode } from "../RunDateModeRadio";
import { useSyncedFieldsProducer } from "@app/features/syncedFields";
import moment from "moment-timezone";

export const useEndDatePicker = () => {
    const { setFieldValue } = Form.useFormInstance();
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const runDateMode = Form.useWatch<RunDateMode>(DEAL_FORM_FIELDS.RUN_DATE_MODE.name);
    useEffect(() => {
        if (runDateMode === "evergreen") {
            setFieldValue(DEAL_FORM_FIELDS.END_DATE.name, null);
        }
    }, [setFieldValue, runDateMode]);
    const isVisible = runDateMode !== "evergreen";
    const isDisabled = dealObjectMode === "edit";
    const { updateSyncedFields } = useSyncedFieldsProducer();
    const onChange = (endDate: moment.Moment | null) => {
        updateSyncedFields({ endDate });
    };
    return { isVisible, isDisabled, onChange };
};
