import { CUSTOM_MAX_EXTENDED, AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useAdUnitFormInstance } from "@app/features/inventory/HierarchyForms/AdUnitForm/AdUnitFormInstance";

interface UseDemandRequirementsSection {
    isAdditionalTimeShown: boolean;
}

export const useDemandRequirementsSection = (): UseDemandRequirementsSection => {
    const { useWatch } = useAdUnitFormInstance();
    const maxExtended: number | string = useWatch(AD_UNIT_FORM_FIELDS.MAX_EXTENDED.name);
    const isAdditionalTimeShown: boolean = maxExtended === CUSTOM_MAX_EXTENDED.value;
    return { isAdditionalTimeShown };
};
