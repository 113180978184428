import { Form } from "antd";
import { useState } from "react";
import { LabeledValue } from "antd/lib/select";
import { RuleObject } from "rc-field-form/lib/interface";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { ViewabilityMesurementOption } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm/useExchangeProtectionsForm";

const { useFormInstance, useWatch } = Form;

interface UseAdSourceTypeLimitsField {
    hidden: boolean;
    vendor: number | undefined;
    handleAddTypeViewabilityMesurement: () => void;
    sampleRate: string | number | undefined;
    handleChangeSampleRate: (value: string | number) => void;
    viewabilityMesurementOptions: ViewabilityMesurementOption[];
    validator: (_: RuleObject, value: number | string) => Promise<void>;
    onChange: (value: LabeledValue[], options: ViewabilityMesurementOption[]) => void;
    handleChangeVendor: (value: number, option: { label: string; value: number }) => void;
}

export const useViewabilityMesurementField = (): UseAdSourceTypeLimitsField => {
    const form = useFormInstance();
    const [vendor, setVendor] = useState<number | undefined>(undefined);
    const [sampleRate, setSampleRate] = useState<string | number>(0);
    const [viewabilityMesurement, setViewabilityMesurement] = useState<{ label: string; value: number }>({
        label: "",
        value: 0,
    });
    const viewabilityMesurementOptions = useWatch(INVENTORY_FORM_FIELDS.VIEWABILITY_MESUREMENT.name) ?? [];
    const hidden = !viewabilityMesurementOptions.length;

    const handleChangeVendor = (value: number, option: { label: string; value: number }): void => {
        setViewabilityMesurement(option);
        setVendor(value);
    };

    const handleChangeSampleRate = (value: string | number): void => {
        setSampleRate(value);
    };

    const handleChange = (option: ViewabilityMesurementOption[]): void => {
        form.setFieldValue(INVENTORY_FORM_FIELDS.VIEWABILITY_MESUREMENT.name, option);
        form.validateFields([INVENTORY_FORM_FIELDS.VIEWABILITY_MESUREMENT.name]);
    };

    const onChange = (_, options: ViewabilityMesurementOption[]): void => handleChange(options);

    const handleAddTypeViewabilityMesurement = (): void => {
        if (sampleRate < 0 || sampleRate > 1 || !viewabilityMesurement.value) return;

        const option: ViewabilityMesurementOption = {
            label: `${viewabilityMesurement.label} - ${sampleRate}`,
            value: viewabilityMesurement.value,
            viewabilityMeasurement: {
                sampleRate: Number(sampleRate),
                vendor: {
                    id: viewabilityMesurement.value,
                },
            },
        };

        const options: ViewabilityMesurementOption[] = [...viewabilityMesurementOptions, option];
        handleChange(options);
        setVendor(undefined);
        setSampleRate(0);
    };

    const validator = (_: RuleObject, value: number | string): Promise<void> => {
        if (value < 0 || value > 1 || isNaN(Number(value))) {
            return Promise.reject(new Error("Sample Rate required and must be between 0 and 1"));
        }
        return Promise.resolve();
    };

    return {
        hidden,
        vendor,
        sampleRate,
        onChange,
        validator,
        handleChangeVendor,
        handleChangeSampleRate,
        handleAddTypeViewabilityMesurement,
        viewabilityMesurementOptions,
    };
};
