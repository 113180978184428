import { DealObjectForm } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { string } from "@rubicon/utils";
import { Form } from "antd";

export const useDealIdInput = () => {
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const { setFieldValue } = Form.useFormInstance<DealObjectForm>();
    const handleGenerateId = (): void => {
        setFieldValue(["dealUnderEdit", "code"], string.createUUID());
    };
    const isReadonly = dealObjectMode === "view" || dealObjectMode === "edit";
    const isRequired = dealObjectMode !== "view";
    return { handleGenerateId, isReadonly, isRequired };
};
