import { ChangeEvent, CompositionEvent, useCallback, useMemo, useState } from "react";
import { Rule } from "antd/lib/form";
import { currency as currencyUtils } from "@rubicon/utils";
const { getCurrencyInfo } = currencyUtils;

interface UseCurrencyInput {
    prefix: string | null;
    rules: Rule[];
    placeholder: string;
    formItemDataSdet: string;
    inputDataSdet: string;
    onBeforeInput: (event: CompositionEvent<HTMLInputElement>) => void;
    onBlur: (event: ChangeEvent<HTMLInputElement>) => void;
    onChange: () => void;
    onPressEnter: (event) => void;
}

type OnBeforeInputEvent = CompositionEvent & ChangeEvent<HTMLInputElement>;

const ALLOWED_CHARACTERS = /^\d*\.?\d*$/;
const FRACTION_DIGITS = 2;

const isValidInput = (value: string): boolean => new RegExp(ALLOWED_CHARACTERS).test(value);

const getNewValue = (value: string): string => {
    const parsedPriceAmount = Number(parseFloat(value));
    const isValidNumber = !Number.isNaN(parsedPriceAmount);
    const numberValue = isValidNumber
        ? Math.abs(parsedPriceAmount).toFixed(FRACTION_DIGITS)
        : Number(0).toFixed(FRACTION_DIGITS);
    return Number(numberValue) > 0 ? numberValue : "";
};

export const useCurrencyInput = (
    name: string | string[],
    label: string,
    currencyCode: string,
    dataSdetPrefix: string | undefined,
    required: boolean | undefined,
    hidden: boolean | undefined,
    additionalRules: Rule[],
    onBlurCallback: ((value: string, isDirty?: boolean) => void) | undefined,
    setFieldValue: (name: string | string[], value: string) => void
): UseCurrencyInput => {
    const { prefix } = useMemo(() => {
        const { symbol } = getCurrencyInfo(currencyCode);
        return {
            prefix: symbol,
        };
    }, [currencyCode]);

    const rules = useMemo(
        () =>
            hidden
                ? []
                : [...(required ? [{ required: true, message: `${label} is required` }] : []), ...additionalRules],
        [hidden, required, label, additionalRules]
    );

    const placeholder = useMemo(() => `Enter ${label}`, [label]);

    const { formItemDataSdet, inputDataSdet } = useMemo(
        () => ({
            formItemDataSdet: `${dataSdetPrefix || ""}-field`,
            inputDataSdet: `${dataSdetPrefix || ""}-input`,
        }),
        [dataSdetPrefix]
    );

    const onBeforeInput = useCallback((event: OnBeforeInputEvent): void => {
        const newValueCandidate = `${event.target.value}${event.data}`;
        if (!isValidInput(newValueCandidate)) {
            event.preventDefault();
        }
    }, []);

    const [isDirty, setIsDirty] = useState<boolean>(false);

    const onBlur = useCallback(
        (event: ChangeEvent<HTMLInputElement>): void => {
            const newValueCandidate = event.target.value;
            if (isValidInput(newValueCandidate)) {
                const newValue = getNewValue(newValueCandidate);
                setFieldValue(name, newValue);
                if (onBlurCallback) {
                    onBlurCallback(newValue, isDirty);
                    setIsDirty(false);
                }
            }
        },
        [name, isDirty, setFieldValue, onBlurCallback]
    );

    const onChange = useCallback(() => {
        setIsDirty(true);
    }, []);

    const onPressEnter = useCallback(
        (event) => {
            onBlur(event);
        },
        [onBlur]
    );

    return {
        prefix,
        rules,
        placeholder,
        formItemDataSdet,
        inputDataSdet,
        onBeforeInput,
        onBlur,
        onChange,
        onPressEnter,
    };
};
