import { Button, Card, Form, Input, Layout, Tag, Typography, notification } from "antd";
import { FC, useState } from "react";
import {
    CLASSIFICATIONS_INDUSTRY_ADVERTISERS_READONLY_SDET,
    CLASSIFICATIONS_INDUSTRY_CODE_INPUT_SDET,
    CLASSIFICATIONS_INDUSTRY_DELETE_BUTTON_SDET,
    CLASSIFICATIONS_INDUSTRY_DESCRIPTION_INPUT_SDET,
    CLASSIFICATIONS_INDUSTRY_FORM_ITEMS,
    CLASSIFICATIONS_INDUSTRY_NAME_INPUT_SDET,
} from "./constants";
import { FixedSaveButtons } from "@app/core/components";
import { IndustryDetails, useCreateIndustryMutation, useUpdateIndustryMutation } from "@app/core/services";
import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { getClassificationsIndustryFormInitialValues, getClassificationsIndustryPayload } from "./helpers";
import {
    ClassificationsIndustryDeleteModal,
    useClassificationsIndustryDeleteModal,
} from "../ClassificationsIndustryDeleteModal";
import { NONE } from "@app/core/components/constants";

interface Props {
    industry?: IndustryDetails;
}
export const ClassificationsIndustryForm: FC<Props> = ({ industry }) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();

    const [createIndustry] = useCreateIndustryMutation();
    const [updateIndustry] = useUpdateIndustryMutation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { handleOpen, ...deleteModalProps } = useClassificationsIndustryDeleteModal();

    const isEditMode = Boolean(industry);

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={getClassificationsIndustryFormInitialValues(industry)}
            wrapperCol={{ xs: 24, md: 12 }}
            onFinish={(values) => {
                setIsSubmitting(true);
                const payload = getClassificationsIndustryPayload(values, Number(seatId));
                const submitTrigger = isEditMode ? updateIndustry : createIndustry;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                submitTrigger({ seatId: Number(seatId), payload })
                    .unwrap()
                    .then((response) => {
                        setIsSubmitting(false);
                        notification.success({
                            message: (
                                <span>
                                    <Typography.Text strong>{response.name}</Typography.Text> has successfully been{" "}
                                    {isEditMode ? "updated" : "created"}
                                </span>
                            ),
                        });
                        history.push(
                            ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_DETAILS_PAGE(seatId, response.id)
                        );
                    })
                    .catch((err) => {
                        setIsSubmitting(false);
                        notification.error({ message: err.message || err.data.errorDescription });
                    });
            }}
        >
            <Layout.Content>
                <Card>
                    <Form.Item {...CLASSIFICATIONS_INDUSTRY_FORM_ITEMS.ID} />
                    <Form.Item {...CLASSIFICATIONS_INDUSTRY_FORM_ITEMS.NAME}>
                        <Input placeholder="Enter Name" data-sdet={CLASSIFICATIONS_INDUSTRY_NAME_INPUT_SDET} />
                    </Form.Item>
                    <Form.Item {...CLASSIFICATIONS_INDUSTRY_FORM_ITEMS.CODE}>
                        <Input
                            placeholder="Platform will generate if left empty"
                            data-sdet={CLASSIFICATIONS_INDUSTRY_CODE_INPUT_SDET}
                        />
                    </Form.Item>
                    <Form.Item {...CLASSIFICATIONS_INDUSTRY_FORM_ITEMS.DESCRIPTION}>
                        <Input.TextArea
                            placeholder="Enter Description"
                            autoSize={{ minRows: 4, maxRows: 6 }}
                            data-sdet={CLASSIFICATIONS_INDUSTRY_DESCRIPTION_INPUT_SDET}
                        />
                    </Form.Item>
                    {isEditMode && (
                        <Form.Item
                            label={CLASSIFICATIONS_INDUSTRY_FORM_ITEMS.ADVERTISERS.label}
                            data-sdet={CLASSIFICATIONS_INDUSTRY_ADVERTISERS_READONLY_SDET}
                        >
                            {industry?.advertisers.length
                                ? industry.advertisers.map(({ name, id }) => <Tag key={id}>{name}</Tag>)
                                : NONE}
                        </Form.Item>
                    )}
                </Card>
            </Layout.Content>
            <FixedSaveButtons
                isLoading={isSubmitting}
                submitLabel={isEditMode ? "Save" : "Submit"}
                suffixAddOn={
                    industry ? (
                        <>
                            <Button
                                danger
                                onClick={() => handleOpen(industry)}
                                data-sdet={CLASSIFICATIONS_INDUSTRY_DELETE_BUTTON_SDET}
                                type="primary"
                            >
                                Delete
                            </Button>
                            <ClassificationsIndustryDeleteModal {...deleteModalProps} />
                        </>
                    ) : null
                }
                onCancel={() =>
                    history.push(
                        industry
                            ? ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_DETAILS_PAGE(seatId, industry.id)
                            : ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_TABS(seatId, "industry")
                    )
                }
            />
        </Form>
    );
};
