import { useParams } from "react-router-dom";
import { useUserAccess } from "@app/core/auth";
import { Publisher, SupplySeat, useGetPublisherByIdQuery, useGetSupplySeatQuery } from "@app/core/services";
import { useInventoryDetailsDrawerUrlId } from "@app/features/inventory/DetailsDrawer/useInventoryDetailsDrawerUrlId";

interface UseGeneral
    extends Pick<
            Publisher,
            | "name"
            | "code"
            | "businessDomain"
            | "description"
            | "externalComment"
            | "updateDate"
            | "creationDate"
            | "accountType"
            | "publisherNameOverride"
        >,
        Partial<Pick<SupplySeat, "lastIndexed">> {
    isInternalOnly: boolean;
}

export const useGeneral = (): UseGeneral => {
    const { seatId } = useParams<{ seatId: string }>();
    const unitId = useInventoryDetailsDrawerUrlId();
    const { isSysAdmin, isPubAcctMgr } = useUserAccess();
    const isInternalOnly: boolean = isSysAdmin || isPubAcctMgr;
    const { data } = useGetPublisherByIdQuery(Number(unitId));
    const {
        name,
        code,
        businessDomain,
        description,
        externalComment,
        updateDate,
        creationDate,
        accountType,
        publisherNameOverride,
    } = data as Publisher;
    const { data: supplySeat } = useGetSupplySeatQuery(Number(seatId));

    return {
        code,
        creationDate,
        updateDate,
        externalComment,
        accountType,
        isInternalOnly,
        description,
        businessDomain,
        name,
        publisherNameOverride,
        lastIndexed: supplySeat?.lastIndexed,
    };
};
