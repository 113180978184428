import { useUserAccess } from "@app/core/auth";
import { Loading, SectionTitle } from "@app/core/components";
import { HelpKeyItem } from "@app/core/components/HelpKeysDrawer/HelpKeyItem";
import { useAppSelector } from "@app/core/store";
import { DETAILS_UNITS, SECTIONS_NAMES } from "@app/features/inventory/InventoryHealthDetailsPages/constants";
import { css } from "@emotion/css";
import { Collapse, Flex } from "antd";
import { FC } from "react";
import { DetailsDrawerLayout } from "../../DetailsDrawerLayout";
import { InventoryDetailsDrawerType, selectInventoryDetailsDrawerType } from "../../reducer";
import { useInventoryDetailsDrawerUrlId } from "../../useInventoryDetailsDrawerUrlId";
import { useDefinitionUnitContent } from "./useDefinitionUnitContent";

export const configurationCollapseClassList = css`
    width: 100%;

    & .ant-collapse-content-box {
        padding-left: 3.3rem;
    }

    & .ant-collapse-item > .ant-collapse-header {
        padding-left: 1.5rem;
    }

    & .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        margin-right: 18px;
    }

    .ant-collapse-expand-icon {
        align-self: flex-end;
    }
`;

interface Props {
    definitionType: SECTIONS_NAMES;
}

export const DefinitionUnitContent: FC<Props> = ({ definitionType }) => {
    const { isSysAdmin, isPubAcctMgr } = useUserAccess();
    const isInternalOnly = isSysAdmin || isPubAcctMgr;
    const unitId = useInventoryDetailsDrawerUrlId();
    const { breadcrumbs, isLoading } = useDefinitionUnitContent(unitId);
    const detailsDrawerType = useAppSelector(selectInventoryDetailsDrawerType) as InventoryDetailsDrawerType;
    const { label, helpKeys: definitionsFields } = DETAILS_UNITS(isInternalOnly)[detailsDrawerType][definitionType];
    const allKeys = definitionsFields.map((field) => field.key);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Flex vertical data-sdet="inventory-defintion-unit-content">
            <DetailsDrawerLayout title={label} breadcrumbs={breadcrumbs} />
            <Collapse
                defaultActiveKey={allKeys}
                className={configurationCollapseClassList}
                items={definitionsFields.map((field) => ({
                    key: field.key,
                    forceRender: true,
                    label: <SectionTitle title={field.label} />,
                    children: field.children ? (
                        <Collapse
                            className={configurationCollapseClassList}
                            defaultActiveKey={field.children.map((field) => field.key)}
                            items={field.children.map((child) => ({
                                key: child.key,
                                forceRender: true,
                                label: child.label,
                                children: <HelpKeyItem helpPath={child.helpPath} />,
                            }))}
                        />
                    ) : (
                        <HelpKeyItem helpPath={field.helpPath} />
                    ),
                }))}
            />
        </Flex>
    );
};
