import { useEffect } from "react";
import { useInventoryDetailsDrawer } from "./useInventoryDetailsDrawer";
import { css } from "@emotion/css";
import { Route, Switch, useParams } from "react-router-dom";
import { useAppDispatch } from "@app/core/store";
import { ROUTES } from "@app/core/routing";
import { InventoryDetailsDrawerType, pickDrawerType } from "./reducer";
import {
    AdsTxtContent,
    CreativeReviewContent,
    DefinitionCreativeReviewContent,
    DefinitionLadleContent,
    DefinitionUnitContent,
    LadleContent,
    NoDrawerContent,
    SnapshotsUnitContent,
    TagParamsContent,
    UnitContent,
} from "./DetailsDrawerContent";
import { DefinitionDiagnosticsContent, DiagnosticsContent } from "./DetailsDrawerContent/DiagnosticsContent";
import { UnitUrlIds } from "./useInventoryDetailsDrawerUrlId";
import { SECTIONS_NAMES } from "../InventoryHealthDetailsPages/constants";
import { FloorAnalysisContent } from "@app/features/inventory/DetailsDrawer/DetailsDrawerContent/FloorAnalysisContent";
import { PermissionsContent } from "./DetailsDrawerContent/PermissionsContent";
import { ResizableDrawer } from "@rubicon/antd-components";

const DEFAULT_DRAWER_WIDTH = "65%";

interface Params extends UnitUrlIds {
    drawerType: InventoryDetailsDrawerType;
    creativeReview?: string;
    key: SECTIONS_NAMES;
}

export const DetailsDrawer = () => {
    const dispatch = useAppDispatch();
    const { isOpen, openDetailsDrawer, closeDetailsDrawer } = useInventoryDetailsDrawer();
    const { drawerType, key, publisherId } = useParams<Params>();

    useEffect(() => {
        if (drawerType) {
            dispatch(openDetailsDrawer);
            dispatch(pickDrawerType(drawerType));
            return;
        }
        //When urls has changed and no matches in hook we close drawer to not show empty drawer
        !publisherId && closeDetailsDrawer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerType]);

    return (
        <ResizableDrawer
            data-sdet="inventory-details-drawer"
            open={isOpen}
            placement="right"
            width={DEFAULT_DRAWER_WIDTH}
            destroyOnClose
            closable={false}
            onClose={closeDetailsDrawer}
            className={css`
                .ant-drawer-body {
                    padding: 0;
                }
            `}
        >
            <Switch>
                <Route
                    exact
                    path={[
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_DEFINITION,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_DEFINITION,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_DEFINITION,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_DEFINITION,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_DEFINITION,
                    ]}
                >
                    <DefinitionUnitContent definitionType={key} />
                </Route>

                <Route
                    exact
                    path={[
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SEAT_DIAGNOSTICS,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_DIAGNOSTICS,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_DIAGNOSTICS,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_DIAGNOSTICS,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_DIAGNOSTICS,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_DIAGNOSTICS,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_DIAGNOSTICS,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_DIAGNOSTICS,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_DIAGNOSTICS,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_DIAGNOSTICS,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_DIAGNOSTICS,
                    ]}
                >
                    <DiagnosticsContent />
                </Route>

                <Route
                    exact
                    path={[
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_PERMISSIONS,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_PERMISSIONS,
                    ]}
                >
                    <PermissionsContent />
                </Route>

                <Route
                    exact
                    path={[
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SEAT_FLOOR_ANALYSIS,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_FLOOR_ANALYSIS,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_FLOOR_ANALYSIS,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_FLOOR_ANALYSIS,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_FLOOR_ANALYSIS,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_FLOOR_ANALYSIS,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_FLOOR_ANALYSIS,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_FLOOR_ANALYSIS,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_FLOOR_ANALYSIS,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_FLOOR_ANALYSIS,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_FLOOR_ANALYSIS,
                    ]}
                >
                    <FloorAnalysisContent />
                </Route>

                <Route
                    exact
                    path={[
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SEAT_DIAGNOSTICS_DEFINITION,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_DIAGNOSTICS_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_DIAGNOSTICS_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_DIAGNOSTICS_DEFINITION,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_DIAGNOSTICS_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_DIAGNOSTICS_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_DIAGNOSTICS_DEFINITION,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_DIAGNOSTICS_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_DIAGNOSTICS_DEFINITION,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_DIAGNOSTICS_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_DIAGNOSTICS_DEFINITION,
                    ]}
                >
                    <DefinitionDiagnosticsContent />
                </Route>

                <Route
                    exact
                    path={[
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SEAT_TAG_PARAMS,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_TAG_PARAMS,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_TAG_PARAMS,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_TAG_PARAMS,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_TAG_PARAMS,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_TAG_PARAMS,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_TAG_PARAMS,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_TAG_PARAMS,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_TAG_PARAMS,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_TAG_PARAMS,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_TAG_PARAMS,
                    ]}
                >
                    <TagParamsContent />
                </Route>

                <Route
                    exact
                    path={[
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SEAT_CREATIVE_REVIEW,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_CREATIVE_REVIEW,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_CREATIVE_REVIEW,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_CREATIVE_REVIEW,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_CREATIVE_REVIEW,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CREATIVE_REVIEW,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CREATIVE_REVIEW,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_CREATIVE_REVIEW,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_CREATIVE_REVIEW,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_CREATIVE_REVIEW,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_CREATIVE_REVIEW,
                    ]}
                >
                    <CreativeReviewContent />
                </Route>

                <Route
                    path={[
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_ADS_TXT,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_ADS_TXT,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_ADS_TXT,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_ADS_TXT,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_ADS_TXT,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_CHANNEL_ADS_TXT,
                    ]}
                >
                    <AdsTxtContent />
                </Route>

                <Route
                    exact
                    path={[
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SEAT_CREATIVE_REVIEW_DEFINITION,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_CREATIVE_REVIEW_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_CREATIVE_REVIEW_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_CREATIVE_REVIEW_DEFINITION,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_CREATIVE_REVIEW_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CREATIVE_REVIEW_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CREATIVE_REVIEW_DEFINITION,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_CREATIVE_REVIEW_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_CREATIVE_REVIEW_DEFINITION,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_CREATIVE_REVIEW_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_CREATIVE_REVIEW_DEFINITION,
                    ]}
                >
                    <DefinitionCreativeReviewContent />
                </Route>

                <Route
                    exact
                    path={[
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_LADLE_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_CHANNEL_LADLE_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_LADLE_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CHANNEL_LADLE_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_LADLE_DEFINITION,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_LADLE_DEFINITION,
                    ]}
                >
                    <DefinitionLadleContent />
                </Route>

                <Route
                    exact
                    path={[
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_LADLE,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_CHANNEL_LADLE,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_LADLE,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CHANNEL_LADLE,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_LADLE,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_LADLE,
                    ]}
                >
                    <LadleContent />
                </Route>

                <Route
                    exact
                    path={[
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT,
                    ]}
                >
                    <UnitContent />
                </Route>

                <Route
                    exact
                    path={[
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_SNAPSHOT,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_SNAPSHOT,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_SNAPSHOT,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_SNAPSHOT,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_SNAPSHOT,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_SNAPSHOT,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_SNAPSHOT,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_SNAPSHOT,

                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_SNAPSHOT,
                        ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_SNAPSHOT,
                    ]}
                >
                    <SnapshotsUnitContent />
                </Route>
                <Route>
                    <NoDrawerContent />
                </Route>
            </Switch>
        </ResizableDrawer>
    );
};
