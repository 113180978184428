import { MarketplaceInfo, NetworkBuyerSeat } from "@app/core/services";
import { ReadonlyControl, SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { Col, Form } from "antd";
import { FC } from "react";
import { BuyerSeatsSelectLabel, DspSyncInfoBlock, useBuyerSeatsSelect } from "@app/core/components/BuyerSeatsSelect";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { DEAL_TYPES } from "../DealTypeSelect";
import { DealTypeIds } from "@app/features/seatAdSources/constants";

export const BuyerMultiselect: FC = () => {
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const dealType = Form.useWatch<MarketplaceInfo["deal"]["dealType"]>(["dealUnderEdit", "dealType"]);
    const selectedDspNetworkId = Form.useWatch<MarketplaceInfo["deal"]["network"]>(["dealUnderEdit", "network"])?.id;
    const selectedBuyerSeatIds = Form.useWatch<NetworkBuyerSeat[]>(["dealUnderEdit", "buyerSeats"])?.map(
        (buyerSeat) => buyerSeat.id
    );
    const createdWithBuyerSeats = Form.useWatch<boolean>(["dealOrigin", "createdWithBuyerSeats"]);
    const selectedDealTypeId = Form.useWatch<number>(["dealUnderEdit", "dealType", "id"]);
    const isProgrammaticGuaranteed = selectedDealTypeId === DealTypeIds.PROGRAMMATIC_GUARANTEED;

    const {
        isBuyerSelected,
        isDisabled,
        isExternalDsp,
        isFetching,
        hasValidationError,
        search,
        validationRules,
        handleSearch,
        buyerSeats,
    } = useBuyerSeatsSelect(
        dealObjectMode,
        selectedDspNetworkId,
        selectedBuyerSeatIds,
        createdWithBuyerSeats,
        isProgrammaticGuaranteed,
        true
    );

    const isReadonly = dealObjectMode === "view";
    const isVisible = dealType?.name !== DEAL_TYPES.DEAL_TYPE_LEGACY.name;

    if (!isVisible) {
        return null;
    }

    return (
        <Col sm={12}>
            <Form.Item
                name={["dealUnderEdit", "buyerSeats"]}
                label={
                    <BuyerSeatsSelectLabel
                        selectedDspNetworkId={selectedDspNetworkId}
                        formMode={dealObjectMode}
                        isBuyerSelected={isBuyerSelected}
                        isProgrammaticGuaranteed={isProgrammaticGuaranteed}
                    />
                }
                help={
                    hasValidationError ? undefined : (
                        <DspSyncInfoBlock isBuyerSelected={isBuyerSelected} isExternalDsp={isExternalDsp} />
                    )
                }
                rules={validationRules}
            >
                {isReadonly ? (
                    <ReadonlyControl<MarketplaceInfo["deal"]["buyerSeats"]>
                        getLabel={(value) =>
                            value?.map((buyerSeat) => `${buyerSeat?.code} [${buyerSeat?.name}]`).join(", ")
                        }
                    />
                ) : (
                    <SelectWithValue
                        onSearch={handleSearch}
                        placeholder="Select…"
                        disabled={isDisabled}
                        loading={isFetching}
                        options={buyerSeats}
                        searchValue={search}
                        mode="multiple"
                        showArrow
                        fieldAsValue={["id"]}
                        fieldAsLabel={(buyerSeat) => `${buyerSeat?.code} [${buyerSeat?.name}]`}
                        onBlur={() => handleSearch("")}
                        filterOption={false}
                    />
                )}
            </Form.Item>
        </Col>
    );
};
