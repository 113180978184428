import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useCreateSeatThirdPartyPixelMutation } from "@app/core/services";
import { Layout } from "antd";

import { useHistory, useParams } from "react-router-dom";
import {
    CUSTOM_PIXELS_CREATE_FORM_INITIAL_VALUES,
    CUSTOM_PIXELS_CREATE_PAGE_SDET,
    CUSTOM_PIXELS_CREATE_PAGE_TITLE,
} from "./constants";
import { onFinishCreatePixel } from "./helpers";
import { CustomPixelForm } from "../CustomPixelForm";
import { PageHeader } from "@ant-design/pro-layout";

export const CustomPixelCreatePage = () => {
    const history = useHistory();
    const [createSeatThirdPartyPixel, { isLoading }] = useCreateSeatThirdPartyPixelMutation();
    const { seatId } = useParams<{ seatId: string }>();

    return (
        <Layout data-sdet={CUSTOM_PIXELS_CREATE_PAGE_SDET}>
            <PageHeader
                title={CUSTOM_PIXELS_CREATE_PAGE_TITLE}
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, "custom-pixels"))}
            />
            <CustomPixelForm
                isSubmitting={isLoading}
                initialValues={CUSTOM_PIXELS_CREATE_FORM_INITIAL_VALUES}
                onFinish={(values) => onFinishCreatePixel(values, seatId, createSeatThirdPartyPixel, history)}
            />
        </Layout>
    );
};
