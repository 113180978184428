import { FC } from "react";
import { Form, InputNumber } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAdSourceDailyImpressionField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourcePacingSection/Fields/AdSourceDailyImpressionField/useAdSourceDailyImpressionField";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";

const { label } = AD_SOURCE_FIELDS.DAILY_IMPRESSION_CAP;

export const AdSourceDailyImpressionField: FC = () => {
    const { value, onChange, handleBlur } = useAdSourceDailyImpressionField();

    return (
        <Form.Item
            data-sdet="ad-source-daily-impression-field"
            label={label}
            required
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_IMPRESSION_CAP}
            rules={[{ message: `${label} is required`, required: true }]}
        >
            <InputNumber
                onBlur={handleBlur}
                min={0}
                value={value}
                onChange={onChange}
                style={{ width: "100%" }}
                placeholder={`Enter ${label}`}
            />
        </Form.Item>
    );
};
