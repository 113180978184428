import { FC } from "react";
import { ItemType } from "rc-collapse/es/interface";
import { Form, Collapse, Space, Button } from "antd";
import { formRequiredMark, Title } from "@app/core/components";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import {
    FloorsSection,
    LabelsSection,
    GeneralSection,
    InternalSection,
    AffiliateSection,
    BrandSafetySection,
    CustomPixelsSection,
    AdvancedFeaturesSection,
} from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormSections";
import { ConfirmationModal } from "@app/features/inventory/components/ConfirmationModal";
import { AffixFormButtonsBar } from "@app/features/inventory/components/AffixFormButtonsBar";
import { INVENTORY_HEADER_COLLAPSE_CLASS_LIST } from "@app/features/inventory/InventorySeat/constants";
import { usePublisherForm } from "@app/features/inventory/HierarchyForms/PublisherForm/usePublisherForm";
import { usePublisherFormDefinitionsFields } from "@app/features/inventory/HierarchyForms/PublisherForm/usePublisherFormDefinitionsFields";

export const PublisherForm: FC = () => {
    const {
        open,
        form,
        loading,
        showModal,
        publisher,
        isDeleting,
        isEditMode,
        isSysAdmin,
        handleCancel,
        handleSubmit,
        handleDelete,
        isTremorUser,
        isPubAcctMgr,
        initialValues,
        handleCancelModal,
        submitButtonTitle,
        handleAdvancedFloors,
        isFloorsSectionShown,
        isAffiliateSectionShown,
        hasSeatWriteAccess,
    } = usePublisherForm();

    const {
        generalDefinitionsFields,
        brandSafetyDefinitionsFields,
        internalDefinitionsFields,
        advancedFeaturesDefinitionsFields,
        customPixelsDefinitionsFields,
        labelsDefinitionsFields,
        affiliateDefinitionsFields,
    } = usePublisherFormDefinitionsFields();
    return (
        <Form
            form={form}
            layout="vertical"
            name="publisherForm"
            initialValues={initialValues}
            requiredMark={formRequiredMark}
            scrollToFirstError={{ behavior: "smooth", block: "center" }}
        >
            <Collapse
                bordered
                ghost={false}
                className={INVENTORY_HEADER_COLLAPSE_CLASS_LIST}
                defaultActiveKey={["0", "1", "2", "3", "4", "5", "6", "7"]}
                collapsible="header"
                items={
                    [
                        {
                            key: "0",
                            children: <GeneralSection />,
                            label: <Title level={5} title="General" />,
                            extra: <HelpKeysDrawer panelLabel="General" definitionsFields={generalDefinitionsFields} />,
                        },
                        isAffiliateSectionShown && {
                            key: "1",
                            children: <AffiliateSection />,
                            label: <Title level={5} title="Affiliate (Internal only)" />,
                            extra: (
                                <HelpKeysDrawer panelLabel="Affiliate" definitionsFields={affiliateDefinitionsFields} />
                            ),
                        },
                        {
                            key: "2",
                            children: <BrandSafetySection />,
                            label: <Title level={5} title="Brand Safety" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Brand Safety"
                                    definitionsFields={brandSafetyDefinitionsFields}
                                />
                            ),
                        },
                        isTremorUser && {
                            key: "3",
                            children: <InternalSection />,
                            label: <Title level={5} title="Internal (Internal only)" />,
                            extra: (
                                <HelpKeysDrawer panelLabel="Internal" definitionsFields={internalDefinitionsFields} />
                            ),
                        },
                        (isSysAdmin || isPubAcctMgr) && {
                            key: "4",
                            children: <AdvancedFeaturesSection />,
                            label: <Title level={5} title="Advanced Features (Internal only)" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Advanced Features"
                                    definitionsFields={advancedFeaturesDefinitionsFields}
                                />
                            ),
                        },
                        {
                            key: "5",
                            children: <CustomPixelsSection />,
                            label: <Title level={5} title="Custom Pixels" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Custom Pixels"
                                    definitionsFields={customPixelsDefinitionsFields}
                                />
                            ),
                        },
                        {
                            key: "6",
                            children: <LabelsSection />,
                            label: <Title level={5} title="Labels" />,
                            extra: <HelpKeysDrawer panelLabel="Labels" definitionsFields={labelsDefinitionsFields} />,
                        },
                        isFloorsSectionShown && {
                            key: "7",
                            children: <FloorsSection />,
                            label: (
                                <Space>
                                    <Title level={5} title="Floors" />
                                    {hasSeatWriteAccess && (
                                        <Button onClick={handleAdvancedFloors} type="link" size="small">
                                            Advanced Floors
                                        </Button>
                                    )}
                                </Space>
                            ),
                        },
                    ].filter(Boolean) as ItemType[]
                }
            />
            <AffixFormButtonsBar
                loading={loading}
                disabled={loading}
                onSubmit={handleSubmit}
                onDelete={showModal}
                onCancel={handleCancel}
                isDeleting={isDeleting}
                isDeleteButtonShown={isEditMode}
                submitButtonTitle={submitButtonTitle}
            />
            <ConfirmationModal
                open={open}
                onOk={handleDelete}
                name={publisher?.name}
                onDelete={handleDelete}
                confirmLoading={isDeleting}
                onCancel={handleCancelModal}
            />
        </Form>
    );
};
