import { createHashHistory, History } from "history";
import { analyticsService } from "@app/core/services";

const setupHashHistory = (): History => {
    const history = createHashHistory();

    analyticsService.trackPageview({ url: `${history.location.pathname}${history.location.search}` });

    history.listen((location) => {
        analyticsService.trackPageview({ url: `${location.pathname}${location.search}` });
    });

    return history;
};

export default setupHashHistory;
