import { useState } from "react";
import { useParams } from "react-router-dom";
import { LabeledValue } from "antd/lib/select";
import { useValidateContentMetadataTvSeriesMutation } from "@app/core/services/console";
import { contentMetadataTvSeriesToLabelValue } from "@app/features/targeting/helpers";

export const useContentMetadataTvSeriesDrawerButton = (
    onChange: (value: LabeledValue[]) => void,
    values: LabeledValue[]
) => {
    const { seatId } = useParams<{ seatId: string }>();
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState("");
    const [isLoadingAdd, setIsLoadingAdd] = useState(false);
    const [isLoadingReplace, setIsLoadingReplace] = useState(false);

    const [validateTvSeries] = useValidateContentMetadataTvSeriesMutation();

    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);
    const handleChangeValue = (v) => setValue(v);

    const handleReplace = async () => {
        setIsLoadingReplace(true);
        const tvSeries = await validateTvSeries({ seatId: Number(seatId), body: value.split("\n") }).unwrap();
        onChange(contentMetadataTvSeriesToLabelValue(tvSeries.valid));
        setValue("");
        setIsOpen(false);
        setIsLoadingReplace(false);
    };

    const handleAdd = async () => {
        setIsLoadingAdd(true);
        const tvSeries = await validateTvSeries({ seatId: Number(seatId), body: value.split("\n") }).unwrap();
        const existingValuesByValue = values.reduce<{
            [value: string | number]: LabeledValue;
        }>((byValue, value) => {
            byValue[value.value] = value;
            return byValue;
        }, {});
        const combinedValueByValue = contentMetadataTvSeriesToLabelValue(tvSeries.valid).reduce((byValue, value) => {
            byValue[value.value] = value;
            return byValue;
        }, existingValuesByValue);

        const combinedValues = Object.values<LabeledValue>(combinedValueByValue);

        onChange(combinedValues);
        setValue("");
        setIsOpen(false);
        setIsLoadingAdd(false);
    };

    return {
        handleAdd,
        handleChangeValue,
        handleClose,
        handleOpen,
        handleReplace,
        isLoadingAdd,
        isLoadingReplace,
        isOpen,
        value,
    };
};
