import { FC } from "react";
import { Typography } from "antd";
import {
    GroupConditions,
    SegmentRuleIdentifierOperators,
    TargetingDimensionTypeLabels,
} from "@app/features/targeting/constants";
import { DefinitionsAndTipsDrawerButton } from "../../DefinitionsAndTipsDrawerButton";
import { SegmentRuleGroupRow } from "./SegmentRuleGroupRow";

const MOCK_SIMPLE_USE_CASE_GROUP = {
    condition: GroupConditions.And,
    rules: [
        {
            id: "USER_ID",
            field: "USER_ID",
            type: "segment" as const,
            input: "text",
            operator: SegmentRuleIdentifierOperators.IsMember,
            value: { label: "Adobe > Adobe: AgeGroup 30-44 (TV CPM: 0) (Digital CPM: 0)", value: "1" },
            readonly: false,
        },
        {
            id: "USER_ID",
            field: "USER_ID",
            type: "segment" as const,
            input: "text",
            operator: SegmentRuleIdentifierOperators.IsMember,
            value: { label: "Adobe > Adobe: Gender-Male (TV CPM: 0) (Digital CPM: 0)", value: "2" },
            readonly: false,
        },
    ],
    valid: true,
    readonly: false,
};

const MOCK_SUBGROUP_GROUP = {
    condition: GroupConditions.And,
    rules: [
        {
            id: "USER_ID",
            field: "USER_ID",
            type: "segment" as const,
            input: "text",
            operator: SegmentRuleIdentifierOperators.IsMember,
            value: { label: "Adobe > Adobe: AgeGroup 30-44 (TV CPM: 0) (Digital CPM: 0)", value: "1" },
            readonly: false,
        },
        {
            id: "USER_ID",
            field: "USER_ID",
            type: "segment" as const,
            input: "text",
            operator: SegmentRuleIdentifierOperators.IsMember,
            value: { label: "Adobe > Adobe: Gender-Male (TV CPM: 0) (Digital CPM: 0)", value: "2" },
            readonly: false,
        },
        {
            condition: GroupConditions.Or,
            rules: [
                {
                    id: "USER_ID",
                    field: "USER_ID",
                    type: "segment" as const,
                    input: "text",
                    operator: SegmentRuleIdentifierOperators.IsMember,
                    value: {
                        label: "Adobe > Adobe: EQ-Home Owners - Mortgage (TV CPM: 0) (Digital CPM: 0)",
                        value: "3",
                    },
                    readonly: false,
                },
                {
                    id: "USER_ID",
                    field: "USER_ID",
                    type: "segment" as const,
                    input: "text",
                    operator: SegmentRuleIdentifierOperators.IsMember,
                    value: {
                        label: "Adobe > Adobe: EQ-Low Risk of Credit Default (TV CPM: 0) (Digital CPM: 0)",
                        value: "4",
                    },
                    readonly: false,
                },
            ],
            readonly: false,
        },
    ],
    valid: true,
    readonly: false,
};

const noOp = () => {
    /* no-op */
};

const mockGroupCommonProps = {
    index: 0,
    isValidationShown: false,
    onChangeCondition: noOp,
    onChangeOperator: noOp,
    onChangeValue: noOp,
    onClickAddRule: noOp,
    onClickAddRuleGroup: noOp,
    onClickDeleteRule: noOp,
    path: [],
};

export const AdvancedRuleDefinitionsAndTipsDrawerButton: FC = () => {
    return (
        <DefinitionsAndTipsDrawerButton
            label={TargetingDimensionTypeLabels.SegmentRules}
            examples={
                <>
                    <Typography.Title level={5} style={{ marginTop: "2rem" }}>
                        EXAMPLE 1 - Simple use case
                    </Typography.Title>
                    <Typography.Paragraph>
                        For example, let&lsquo;s say that you wanted to target people that are in certain age backets...
                        You want to use Adobe&lsquo;s segments and target people in age group 30-44 AND Gender-Male
                    </Typography.Paragraph>
                    <SegmentRuleGroupRow group={MOCK_SIMPLE_USE_CASE_GROUP} {...mockGroupCommonProps} />
                    <Typography.Title level={5} style={{ marginTop: "2rem" }}>
                        EXAMPLE 2 - Use of subgroup
                    </Typography.Title>
                    <Typography.Paragraph>
                        For example, let&lsquo;s say that you wanted to target people that are likely to refinance their
                        homes. You want to target people that are in age group 30-44, AND Gender-Male AND they will need
                        to be either Homeowners with mortgage OR have low risk credit default.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        The rule below is stating that the outer AND group must be met AND the subgroup within it must
                        be either EQ-Home Owners - Mortgage OR EQ-Low Risk of Credit Default
                    </Typography.Paragraph>
                    <SegmentRuleGroupRow group={MOCK_SUBGROUP_GROUP} {...mockGroupCommonProps} />
                </>
            }
        />
    );
};
