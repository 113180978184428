import { AdSourcesForm } from "../SeatAdSourcesForm";

export const parseCostModelToApi = (costModel: AdSourcesForm["costModel"] | undefined) => {
    if (costModel === undefined) {
        return undefined;
    }
    return (
        costModel || {
            id: 1,
            name: "Revshare Percentage",
        }
    );
};
