import { Loading, SectionTitle } from "@app/core/components";
import { HelpKeyItem } from "@app/core/components/HelpKeysDrawer/HelpKeyItem";
import { css } from "@emotion/css";
import { Collapse, Flex } from "antd";
import { DetailsDrawerLayout } from "../../DetailsDrawerLayout";
import { useInventoryDetailsDrawerUrlId } from "../../useInventoryDetailsDrawerUrlId";
import { diagnosticsDefinitionsFields } from "./helpKeys";
import { useDefinitionDiagnosticsContent } from "./useDefinitionDiagnosticsContent";

const parentCollpaseClassName = css`
    width: 100%;

    & .ant-collapse-item > .ant-collapse-header {
        padding-left: 1.5rem;
    }

    .ant-collapse-content-box {
        padding: 0 0 32px 0;
    }

    .ant-typography {
        margin-top: 0;
    }
`;

const innerCollapseClassName = css`
    &.ant-collapse-ghost .ant-collapse-content .ant-collapse-content-box {
        padding: 0px 48px;
    }
`;

export const DefinitionDiagnosticsContent = () => {
    const unitId = useInventoryDetailsDrawerUrlId();
    const { isLoading, breadcrumbs } = useDefinitionDiagnosticsContent(unitId);
    const allKeys = diagnosticsDefinitionsFields.map(({ tab }) => tab);

    if (isLoading) {
        return <Loading />;
    }
    return (
        <Flex vertical data-sdet="inventory-definition-diagnostics">
            <DetailsDrawerLayout title="Diagnostics Defintion" breadcrumbs={breadcrumbs} />

            <Collapse
                defaultActiveKey={allKeys}
                className={parentCollpaseClassName}
                items={diagnosticsDefinitionsFields.map((field) => ({
                    key: field.tab,
                    label: <SectionTitle title={field.tab} />,
                    forceRender: true,
                    children: (
                        <Collapse
                            ghost
                            className={innerCollapseClassName}
                            defaultActiveKey={field.children.map((field) => field.label)}
                            items={field.children.map((child) => ({
                                key: child.label,
                                forceRender: true,
                                label: child.label,
                                children: <HelpKeyItem helpPath={child.helpPath} />,
                            }))}
                        />
                    ),
                }))}
            />
        </Flex>
    );
};
