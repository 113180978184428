import { MouseEvent, useState } from "react";
import { DayPartTarget } from "@app/core/services/console";
import { HALF_HOURS_COUNT, TimeZoneModeIds } from "@app/features/targeting/constants";

export const useDayPartingDrawer = (
    values: DayPartTarget[],
    timeZoneMode: TimeZoneModeIds,
    onChange: (newValues: DayPartTarget[], newTimeZoneMode: TimeZoneModeIds) => void
) => {
    const [updatedValues, setUpdatedValues] = useState(values);
    const [updatedTimeZoneMode, setUpdatedTimeZoneMode] = useState(timeZoneMode);
    const [isOpen, setIsOpen] = useState(false);
    const [mouseDragCoords, setMouseDragCoords] = useState([]);

    const selectedDayLookupByHalfHourOfDay = updatedValues.reduce<Record<number, number[]>>(
        (obj, { dayOfWeek, halfHourOfDay }) => {
            if (obj[halfHourOfDay] === undefined) obj[halfHourOfDay] = [];
            obj[halfHourOfDay].push(dayOfWeek);
            return obj;
        },
        {}
    );
    const tableCellIsSelected = (dayOfWeek: number, halfHourOfDay: number) =>
        (selectedDayLookupByHalfHourOfDay[halfHourOfDay] || []).includes(dayOfWeek);
    const handleRadioChange = (e) => setUpdatedTimeZoneMode(e.target.value);
    const handleClose = () => {
        setUpdatedValues(values);
        setUpdatedTimeZoneMode(timeZoneMode);
        setMouseDragCoords([]);
        setIsOpen(false);
    };
    const handleOpen = () => setIsOpen(true);
    const handleApply = () => {
        onChange(updatedValues, updatedTimeZoneMode);
        setIsOpen(false);
    };
    const sortNumbers = (a, b) => a - b;
    const applyHighlightedCells = () => {
        const [dayStart, dayEnd] = [mouseDragCoords[0], mouseDragCoords[2]].sort(sortNumbers);
        const [halfHourStart, halfHourEnd] = [mouseDragCoords[1], mouseDragCoords[3]].sort(sortNumbers);
        const newSelections: DayPartTarget[] = [];
        for (let dayOfWeek = dayStart; dayOfWeek <= dayEnd; dayOfWeek++)
            for (let halfHourOfDay = halfHourStart; halfHourOfDay <= halfHourEnd; halfHourOfDay++)
                if (!tableCellIsSelected(dayOfWeek, halfHourOfDay)) newSelections.push({ dayOfWeek, halfHourOfDay });
        setUpdatedValues(updatedValues.concat(newSelections));
    };

    const handleClick = (e: MouseEvent<HTMLElement, MouseEvent>) => {
        const dayOfWeek = Number(e.currentTarget.dataset.dayOfWeek);
        const halfHourOfDay = Number(e.currentTarget.dataset.halfHourOfDay);
        if (e.type === "click") {
            if (!tableCellIsSelected(dayOfWeek, halfHourOfDay))
                setUpdatedValues(updatedValues.concat([{ dayOfWeek, halfHourOfDay }]));
        } else if (e.type === "contextmenu") {
            setUpdatedValues(
                updatedValues.filter((v) => v.dayOfWeek !== dayOfWeek || v.halfHourOfDay !== halfHourOfDay)
            );
        }
        e.preventDefault();
    };

    const handleMouseDown = (e: MouseEvent) => {
        if (!(e.target instanceof HTMLElement)) return;
        const dayOfWeek = Number(e.target.dataset.dayOfWeek);
        const halfHourOfDay = Number(e.target.dataset.halfHourOfDay);
        if (e.type === "mousedown") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setMouseDragCoords([dayOfWeek, halfHourOfDay]);
        } else if (e.type === "contextmenu") {
            setUpdatedValues(
                updatedValues.filter((v) => v.dayOfWeek !== dayOfWeek || v.halfHourOfDay !== halfHourOfDay)
            );
        }
        e.preventDefault();
    };

    const handleMouseUp = () => {
        applyHighlightedCells();
        setMouseDragCoords([]);
    };

    const handleHeaderClick = (e: MouseEvent) => {
        if (!(e.target instanceof HTMLElement)) return;
        const headerDayOfWeek = Number(e.target.dataset.columnIndex);
        if (e.type === "click") {
            const newHalfHourSelections: DayPartTarget[] = [];
            for (let halfHourOfDay = 0; halfHourOfDay < HALF_HOURS_COUNT; halfHourOfDay++)
                if (!tableCellIsSelected(headerDayOfWeek, halfHourOfDay))
                    newHalfHourSelections.push({ dayOfWeek: headerDayOfWeek, halfHourOfDay });
            setUpdatedValues(updatedValues.concat(newHalfHourSelections));
        } else if (e.type === "contextmenu") {
            setUpdatedValues(updatedValues.filter(({ dayOfWeek }) => dayOfWeek !== headerDayOfWeek));
        }
        e.preventDefault();
    };

    const handleMouseOver = (e: MouseEvent) => {
        if (!(e.target instanceof HTMLElement) || e.buttons !== 1) return;
        const dayOfWeek = Number(e.target.dataset.dayOfWeek);
        const halfHourOfDay = Number(e.target.dataset.halfHourOfDay);
        if (mouseDragCoords.length > 0) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setMouseDragCoords([...mouseDragCoords.slice(0, 2), dayOfWeek, halfHourOfDay]);
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setMouseDragCoords([dayOfWeek, halfHourOfDay]);
        }
    };

    return {
        handleApply,
        handleClick,
        handleClose,
        handleHeaderClick,
        handleMouseDown,
        handleMouseOver,
        handleMouseUp,
        handleOpen,
        handleRadioChange,
        isOpen,
        mouseDragCoords,
        tableCellIsSelected,
        updatedTimeZoneMode,
    };
};
