import { FC, useMemo } from "react";
import { Col, Row } from "antd";
import { FloorAnalysisFilters } from "@app/features/inventory/DetailsDrawer/DetailsDrawerContent/FloorAnalysisContent/FloorAnalysisFilters";
import { FloorAnalysisTabsType } from "@app/features/inventory/DetailsDrawer/DetailsDrawerContent/FloorAnalysisContent/useFloorAnalysisContent";
import {
    FloorAnalysisType,
    useFloorAnalysisFilters,
} from "@app/features/inventory/DetailsDrawer/DetailsDrawerContent/FloorAnalysisContent/FloorAnalysisFilters/useFloorAnalysisFilters";
import { EstimatedFloorBreakdownChart } from "@app/features/inventory/DetailsDrawer/DetailsDrawerContent/FloorAnalysisContent/EstimatedFloorBreakdown/EstimatedFloorBreakdownChart";
import { useEstimatedFloorBreakdownCharts } from "@app/features/inventory/DetailsDrawer/DetailsDrawerContent/FloorAnalysisContent/EstimatedFloorBreakdown/EstimatedFloorBreakdownChart/useEstimatedFloorBreakdownCharts";

interface EstimatedFloorBreakdownProps {
    id: number | string;
    activeTab: FloorAnalysisTabsType;
}

export const EstimatedFloorBreakdown: FC<EstimatedFloorBreakdownProps> = ({ activeTab, id }) => {
    const {
        country,
        dateRange,
        adSourceType,
        isFetchingCountries,
        isFetchingQueryRanges,
        countriesOptions,
        queryRangesOptions,
        adSourceTypeOptions,
        handleChangeCountry,
        handleChangeDateRange,
        handleSearchCountries,
        handleChangeAdSourceType,
    } = useFloorAnalysisFilters(FloorAnalysisType.EST);

    const { isFetching, handleRunAnalysis, queryResults } = useEstimatedFloorBreakdownCharts({
        id,
        country,
        dateRange,
        adSourceType,
    });

    const chart = useMemo(() => <EstimatedFloorBreakdownChart queryResults={queryResults} />, [queryResults]);

    return (
        <div data-sdet="estimated-floor-breakdowns-stats">
            <Row>
                <Col span={24}>
                    <FloorAnalysisFilters
                        country={country}
                        activeTab={activeTab}
                        dateRange={dateRange}
                        isFetching={isFetching}
                        adSourceType={adSourceType}
                        countriesOptions={countriesOptions}
                        handleRunAnalysis={handleRunAnalysis}
                        queryRangesOptions={queryRangesOptions}
                        adSourceTypeOptions={adSourceTypeOptions}
                        isFetchingCountries={isFetchingCountries}
                        handleChangeCountry={handleChangeCountry}
                        isFetchingQueryRanges={isFetchingQueryRanges}
                        handleSearchCountries={handleSearchCountries}
                        handleChangeDateRange={handleChangeDateRange}
                        handleChangeAdSourceType={handleChangeAdSourceType}
                    />
                </Col>
                {queryResults && <Col span={24}>{chart}</Col>}
            </Row>
        </div>
    );
};
