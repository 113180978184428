import { FC } from "react";
import { Table, TableColumnsType, Tag } from "antd";
import { ChangeLog, ChangeLogField, ChangeLogs } from "@app/core/services";
import { YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE } from "../../constants";
import { brandHighlightLight } from "@rubicon/antd-components";
import { css } from "@emotion/css";
import moment from "moment-timezone";

enum ColumnKey {
    DateTime = "changeDate",
    User = "user",
    Action = "action",
    Entity = "elementType",
    EntityName = "entityName",
    RecordId = "id",
    FieldName = "attribute",
    ChangedFrom = "previous",
    ChangedTo = "current",
}

const tagColorMap = {
    created: "success",
    changed: "warning",
    deleted: "error",
};

export const formatDateTime = (changeDate: string) => {
    const changeLogMoment = moment.utc(changeDate);
    const dateTime = changeLogMoment.format(YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE);
    const timePassed = changeLogMoment.fromNow();
    return `${dateTime} (${timePassed})`;
};

export const formatEntity = (elementType: string) => {
    const elementTypeParts = elementType.split(".");
    const rawElementTypeName = elementTypeParts.slice(-1)[0];
    const elementTypeNameWithSpaces = rawElementTypeName.replace(/(?<!^)([A-Z][a-z])/g, " $1").replace(/(\d)/g, " $1");
    return elementTypeNameWithSpaces;
};

const columns: TableColumnsType<ChangeLog> = [
    {
        title: "Date/Time",
        dataIndex: ColumnKey.DateTime,
        key: ColumnKey.DateTime,
        render: (value) => formatDateTime(value),
    },
    {
        title: "User",
        dataIndex: ColumnKey.User,
        key: ColumnKey.User,
    },
    {
        title: "Action",
        dataIndex: ColumnKey.Action,
        key: ColumnKey.Action,
        render: (value) => (
            <Tag style={{ textTransform: "capitalize" }} color={tagColorMap[value]}>
                {value}
            </Tag>
        ),
    },
    {
        title: "Entity",
        dataIndex: ColumnKey.Entity,
        key: ColumnKey.Entity,
        render: (value) => formatEntity(value),
    },
    {
        title: "Entity Name",
        dataIndex: ColumnKey.EntityName,
        key: ColumnKey.EntityName,
    },
    {
        title: "Record ID",
        dataIndex: ColumnKey.RecordId,
        key: ColumnKey.RecordId,
    },
];

const expandedColumns: TableColumnsType<ChangeLogField> = [
    {
        title: "Field Name",
        dataIndex: ColumnKey.FieldName,
        key: ColumnKey.FieldName,
    },
    {
        title: "Changed From",
        dataIndex: ColumnKey.ChangedFrom,
        key: ColumnKey.ChangedFrom,
    },
    {
        title: "Changed To",
        dataIndex: ColumnKey.ChangedTo,
        key: ColumnKey.ChangedTo,
    },
];

interface Props {
    changeLogs: ChangeLogs;
    isLoading: boolean;
}

export const ChangeLogTable: FC<Props> = ({ changeLogs, isLoading }) => {
    const expandedRowRender = (record: ChangeLog) => (
        <Table
            columns={expandedColumns}
            dataSource={record.changes}
            pagination={false}
            rowKey={({ previous, attribute, current }) => `${attribute}-${previous}-${current}`}
        />
    );

    return (
        <Table<ChangeLog>
            className={css`
                .ant-table-expanded-row > td {
                    background: ${brandHighlightLight} !important;
                    .ant-table-wrapper .ant-table {
                        margin: 0 !important;
                    }
                }
            `}
            size="small"
            loading={isLoading}
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={changeLogs}
            pagination={false}
            expandable={{ expandedRowRender, expandRowByClick: true }} // in future, if other buttons exists, have onClick e.stopPropogation()
        />
    );
};
