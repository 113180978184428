import { useSeatAuthContext, useUserAccess } from "@app/core/auth";

interface UseDemandAcquisitionCostUtils {
    isDemandAcquisitionEnabled: boolean;
    isDemandAcquisitionReadonly: boolean;
}

export const useDemandAcquisitionCostUtils = (): UseDemandAcquisitionCostUtils => {
    const { context } = useSeatAuthContext();
    const { hasInternalAccess } = useUserAccess();

    const isDemandAcquisitionEnabled = Boolean(context?.dacEnabled);

    const isDemandAcquisitionReadonly = !hasInternalAccess;

    return {
        isDemandAcquisitionEnabled,
        isDemandAcquisitionReadonly,
    };
};
