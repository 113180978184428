import { FC } from "react";
import { Form, Select } from "antd";
import { TagRender } from "@app/core/components";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { useSupplyDomainListSelect } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm/ExchangeProtectionsFormSections/QualityControlSection/Fields/SupplyDomainListField/useSupplyDomainListSelect";

export const SupplyDomainList: FC = () => {
    const { isFetching, options, onSearch, required, localValue } = useSupplyDomainListSelect();
    return (
        <Form.Item
            name={INVENTORY_FORM_FIELDS.SUPPLY_DOMAIN_FILTER.name}
            label={INVENTORY_FORM_FIELDS.SUPPLY_DOMAIN_FILTER.label}
            wrapperCol={{ xs: 24, sm: 24, md: 19, lg: 15, xl: 13, xxl: 10 }}
            rules={[{ required, message: INVENTORY_FORM_FIELDS.SUPPLY_DOMAIN_FILTER.rulesMessage }]}
        >
            <Select
                allowClear
                showSearch
                labelInValue
                mode="multiple"
                options={options}
                onSearch={onSearch}
                loading={isFetching}
                tagRender={TagRender}
                searchValue={localValue}
                optionFilterProp="label"
                autoClearSearchValue={false}
                placeholder={INVENTORY_FORM_FIELDS.SUPPLY_DOMAIN_FILTER.placeholder}
            />
        </Form.Item>
    );
};
