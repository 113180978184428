import { FC } from "react";
import { Layout } from "antd";
import Header from "./Header";
import { Loading, SeatIFrame } from "@app/core/components";
import { useSeatAuthContext } from "@app/core/auth";
import { DashboardPage } from "./DashboardPage";
import { Footer } from "@app/core/components";
import { useSetInitialSettings } from "./useSetInitialSettings";
import { useGetCurrenciesPrefetch, useGetTimeZonesPrefetch } from "@app/core/services/console";
import { isDemandOrRtbSeat } from "@app/core/utils";
import conf from "@app/core/conf";

export const Dashboard: FC = () => {
    const { context, session } = useSeatAuthContext();
    useGetCurrenciesPrefetch();
    useGetTimeZonesPrefetch();
    useSetInitialSettings();

    if (context && isDemandOrRtbSeat(context)) {
        return (
            <SeatIFrame
                src={(seatId) => `${conf.mctvConsoleRoot}/ssp/seats/${seatId}/home/demand/`}
                title="Dashboard"
            />
        );
    }

    if (!context || !session) {
        return (
            <Layout.Content>
                <Loading />
            </Layout.Content>
        );
    }

    return (
        <>
            <Header seatName={context?.name || ""} />
            <Layout.Content>
                <DashboardPage />
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
