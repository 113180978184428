import { SectionTitle } from "@app/core/components";
import { HelpKeyItem } from "@app/core/components/HelpKeysDrawer/HelpKeyItem";
import { SEAT_INTERNAL_CONTACTS_HELP_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { css } from "@emotion/css";
import { Collapse } from "antd";

const configurationCollapseClassList = css`
    width: 100%;

    & .ant-collapse-content-box {
        padding-left: 3.3rem;
    }

    & .ant-collapse-item > .ant-collapse-header {
        padding-left: 1.5rem;
    }

    & .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        margin-right: 18px;
    }

    .ant-collapse-expand-icon {
        align-self: flex-end;
    }
`;

export const Definition = () => {
    const defValue: { allKeys: string[]; definitionsFields: { helpPath: string; key: string; label: string }[] } = {
        allKeys: [],
        definitionsFields: [],
    };
    const { allKeys, definitionsFields } = SEAT_INTERNAL_CONTACTS_HELP_FIELDS.reduce(
        (acc, { key, helpPath, label }) => {
            acc.allKeys.push(key);
            acc.definitionsFields.push({ helpPath, key, label });
            return acc;
        },
        defValue
    );
    return (
        <Collapse defaultActiveKey={allKeys} className={configurationCollapseClassList}>
            {definitionsFields.map((field) => (
                <Collapse.Panel key={field.key} header={<SectionTitle title={field.label} />} forceRender>
                    <HelpKeyItem helpPath={field.helpPath} />
                </Collapse.Panel>
            ))}
        </Collapse>
    );
};
