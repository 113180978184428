import { Drawer } from "antd";
import DOMPurify from "dompurify";
import { FC, useState } from "react";
import { StyledButtonLink } from "@app/core/components";
import { useHelpArticles } from "@app/core/components/hooks";
import { EMPTY_HELP_KEYS_BODY } from "@app/features/inventory/constants";

const BRAND_SAFETY_HIERARCHICAL_OVERVIEW_HELP_KEY = "brandsafety.hierarchicaloverview";

export const BrandSafetyHierarchicalHelp: FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { helpArticles } = useHelpArticles([BRAND_SAFETY_HIERARCHICAL_OVERVIEW_HELP_KEY]);
    return (
        <>
            <StyledButtonLink onClick={() => setIsOpen(true)} type="link">
                How is Brand Safety applied?
            </StyledButtonLink>
            <Drawer width={400} title="How is Brand Safety applied?" open={isOpen} onClose={() => setIsOpen(false)}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            helpArticles[BRAND_SAFETY_HIERARCHICAL_OVERVIEW_HELP_KEY]?.body || EMPTY_HELP_KEYS_BODY
                        ),
                    }}
                />
            </Drawer>
        </>
    );
};
