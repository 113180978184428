import { Form, Layout } from "antd";
import { FC } from "react";
import BrandSafetyToolbar from "./BrandSafetyToolbar";
import BrandSafetyHeader from "./BrandSafetyHeader";
import { BrandSafetyTableSwitch } from "./BrandSafetyTableSwitch";
import { useTransparencyEditModeControls } from "./TransparencyDefault/useTransparencyEditModeControls";
import { selectTransparencyFormTouchedFields, setTransparencyFormTouchedFields } from "./reducer";
import { useAppDispatch, useAppSelector } from "@app/core/store";

export const BrandSafetyPage: FC = () => {
    const { handleSaveClick: handleSubmit } = useTransparencyEditModeControls();
    const touchedFields = useAppSelector(selectTransparencyFormTouchedFields);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            onFieldsChange={(changedValue) => {
                dispatch(setTransparencyFormTouchedFields([...new Set([...touchedFields, changedValue[0].name[1]])]));
            }}
            wrapperCol={{ xs: 24, md: 12 }}
            scrollToFirstError={{ behavior: "smooth", block: "center" }}
        >
            <Layout>
                <BrandSafetyHeader />
                <Layout.Content>
                    <BrandSafetyToolbar />
                    <BrandSafetyTableSwitch />
                </Layout.Content>
            </Layout>
        </Form>
    );
};
