import { Title } from "@app/core/components";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import { InventoryCurrencyMode } from "@app/features/inventory/InventoryCurrencyMode/InventoryCurrencyMode";
import { Last24HoursStatsTabContent } from "@app/features/inventory/InventorySeat/SeatDetailsPage/SeatDetailsSection/PerformanceSection/Last24HoursStatsTabContent";
import { LiveStatsTabContent } from "@app/features/inventory/InventorySeat/SeatDetailsPage/SeatDetailsSection/PerformanceSection/LiveStatsTabContent";
import { css } from "@emotion/css";
import { Card, Collapse, Space } from "antd";
import { useState } from "react";
import { useSeatDetailsDefenitionsFields } from "@app/features/inventory/InventorySeat/SeatDetailsPage/useSeatDetailsDefenitionsFields";
import { PerformanceSectionContext } from "./usePerformanceSectionContext";

const INVENTORY_HEADER_COLLAPSE_CLASS_LIST = css`
    .ant-typography {
        margin-top: 0;
    }

    .ant-collapse-expand-icon {
        align-self: flex-end;
    }

    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
    }

    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
    }

    .ant-collapse-item > .ant-collapse-header {
        padding: 8px 24px;
        align-items: center;
    }

    .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
        height: 32px;
    }

    .ant-card-body {
        padding: 16px 56px;
    }
`;

export const PerformanceSection = () => {
    const [isLiveActive, setIsLiveActive] = useState(false);
    const { performanceDefinitionsFields } = useSeatDetailsDefenitionsFields();
    const handlePerformanceTypeSwitch = (isLiveActive: boolean) => {
        setIsLiveActive(isLiveActive);
    };

    return (
        <PerformanceSectionContext.Provider value={{ isLiveActive, handlePerformanceTypeSwitch }}>
            <Collapse
                defaultActiveKey={["0"]}
                bordered
                className={INVENTORY_HEADER_COLLAPSE_CLASS_LIST}
                items={[
                    {
                        key: "0",
                        children: (
                            <Card>
                                {!isLiveActive && (
                                    <Last24HoursStatsTabContent
                                        handlePerformanceTypeSwitch={handlePerformanceTypeSwitch}
                                    />
                                )}
                                {isLiveActive && (
                                    <LiveStatsTabContent handlePerformanceTypeSwitch={handlePerformanceTypeSwitch} />
                                )}
                            </Card>
                        ),
                        collapsible: "header",
                        label: (
                            <Space>
                                <Title level={5} title="Performance" />
                                <InventoryCurrencyMode />
                            </Space>
                        ),
                        extra: (
                            <HelpKeysDrawer panelLabel="Performance" definitionsFields={performanceDefinitionsFields} />
                        ),
                    },
                ]}
            />
        </PerformanceSectionContext.Provider>
    );
};
