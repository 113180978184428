import { useHistory, useParams } from "react-router-dom";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import InventoryBrandSafetyToolbar from "../InventoryBrandSafetyToolbar";
import { InventoryBrandSafetyPageSwitch } from "./InventoryBrandSafetyPageSwitch";

export const BRAND_SAFETY_HIERARCHICAL_KEY = "hierarchical";
export const BRAND_SAFETY_ADVANCEDL_KEY = "advanced";

const BRAND_SAFETY_TABS: TabsProps["items"] = [
    { key: BRAND_SAFETY_HIERARCHICAL_KEY, label: "Hierarchical" },
    {
        key: BRAND_SAFETY_ADVANCEDL_KEY,
        label: "Advanced",
    },
];

export const InventoryBrandSafetyPage = () => {
    const history = useHistory();
    const { tab, seatId } = useParams<{ tab: string; seatId: string }>();

    return (
        <>
            <InventoryBrandSafetyToolbar
                footer={
                    <Tabs
                        size="large"
                        items={BRAND_SAFETY_TABS}
                        activeKey={tab}
                        tabBarStyle={{ marginBottom: 0 }}
                        defaultActiveKey={tab}
                        onChange={(tab) => {
                            history.push(
                                ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_TABS(
                                    seatId,
                                    tab as typeof BRAND_SAFETY_HIERARCHICAL_KEY as typeof BRAND_SAFETY_ADVANCEDL_KEY
                                )
                            );
                        }}
                    />
                }
            />
            <InventoryBrandSafetyPageSwitch />
        </>
    );
};
