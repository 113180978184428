import { Col, Typography } from "antd";
import { useIsFieldVisible } from "./useIsFieldVisible";
import { AdSource } from "@app/core/services";

export const CurrencySelect = ({ adSource }: { adSource: AdSource }) => {
    const isVisible = useIsFieldVisible(adSource);

    if (!isVisible) {
        return null;
    }

    const currencyCode = adSource?.currencyType.code;

    return (
        <Col sm={8}>
            <Typography.Text strong>Currency</Typography.Text>
            <Typography.Paragraph>{currencyCode || "--"}</Typography.Paragraph>
        </Col>
    );
};
