import { FC } from "react";
import { Card, Col, Row } from "antd";
import {
    FillMode,
    PodEnforcement,
    MaxAdsPerAdvertiser,
    PlaylistDefinitionMode,
    CompetitiveSeparationMode,
    CompetitiveSeparationGroups,
} from "@app/features/inventory/HierarchyForms/AdUnitForm/PlaylistConfigurationSection/Fields";

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 };

export const PlaylistConfigurationSection: FC = () => {
    return (
        <Card bordered={false}>
            <Row>
                <Col {...COL_SIZES}>
                    <FillMode />
                    <PodEnforcement />
                    <CompetitiveSeparationMode />
                </Col>
            </Row>
            <CompetitiveSeparationGroups />
            <Row>
                <Col {...COL_SIZES}>
                    <MaxAdsPerAdvertiser />
                    <PlaylistDefinitionMode />
                </Col>
            </Row>
        </Card>
    );
};
