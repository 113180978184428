import { Form } from "antd";
import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { useSeatAuthContext } from "@app/core/auth";
import {
    AD_UNITS_PROTOCOLS,
    SUPPORTED_PROTOCOLS,
    AD_UNITS_MULTIPLICITIES,
} from "@app/features/inventory/HierarchyForms/constants";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { CheckboxOptionType } from "antd/lib/checkbox/Group";
import { useGetAdUnitsMultiplicityQuery } from "@app/core/services";

const { useWatch, useFormInstance } = Form;

interface UseAdUnitMultiplicity {
    options: CheckboxOptionType[];
    onChange: (e: RadioChangeEvent) => void;
}

export const useAdUnitMultiplicity = (): UseAdUnitMultiplicity => {
    const form = useFormInstance();
    const { context } = useSeatAuthContext();
    const supportedProtocols: LabeledValue[] = useWatch(SUPPORTED_PROTOCOLS);
    const { data } = useGetAdUnitsMultiplicityQuery();
    const options: CheckboxOptionType[] = useMemo(
        () =>
            (data || []).map(({ id, name }) => {
                if (
                    (id === AD_UNITS_MULTIPLICITIES.POD.value || id === AD_UNITS_MULTIPLICITIES.PLAYLIST.value) &&
                    !context?.adPodEnabled
                ) {
                    return { value: id, label: name, disabled: true };
                }

                if (id === AD_UNITS_MULTIPLICITIES.UDE.value && !context?.udeEnabled) {
                    return { value: id, label: name, disabled: true };
                }
                return { value: id, label: name };
            }),
        [data, context]
    );
    const onChange = (e: RadioChangeEvent): void => {
        const { value } = e.target;
        const isPodMultiplicity: boolean = value === AD_UNITS_MULTIPLICITIES.POD.value;
        const isPlaylistMultiplicity: boolean = value === AD_UNITS_MULTIPLICITIES.PLAYLIST.value;
        const hasRequiredProtocols = supportedProtocols.some(
            ({ value }) => value === AD_UNITS_PROTOCOLS.VAST_3.value || value === AD_UNITS_PROTOCOLS.VAST_4.value
        );
        form.validateFields([SUPPORTED_PROTOCOLS]);
        if ((isPodMultiplicity || isPlaylistMultiplicity) && !hasRequiredProtocols)
            form.scrollToField(SUPPORTED_PROTOCOLS, { behavior: "smooth", block: "center" });
    };
    return {
        options,
        onChange,
    };
};
