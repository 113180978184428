import { Form } from "antd";
import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import {
    INVENTORY_FORM_FIELDS,
    PREBID_AD_SOURCE_EXTERNAL_AD_UNIT_CODE_NAMESPACE,
} from "@app/features/inventory/InventorySeat/constants";
import { useGetPbsServersQuery } from "@app/core/services/console/pbsServers";
import { useUserAccess } from "@app/core/auth";

const { useWatch } = Form;

interface UseAuthorizedPBSServers {
    isFetching: boolean;
    options: LabeledValue[];
    hasInternalAccess: boolean;
    authorizedPBSServersShown: boolean;
}

export const useAuthorizedPBSServers = (): UseAuthorizedPBSServers => {
    const { isSysAdmin, isPubAcctMgr } = useUserAccess();
    const { data = [], isFetching } = useGetPbsServersQuery();
    const pbsServersData: LabeledValue[] = useMemo(
        () =>
            (data || []).map((server) => ({
                label: server.name,
                value: server.id,
            })),
        [data]
    );
    const pbsServers: LabeledValue[] = useWatch(INVENTORY_FORM_FIELDS.AUTHORIZED_PBS_SERVERS.name) || [];
    const adUnitCodeNamespaces: LabeledValue[] =
        useWatch(INVENTORY_FORM_FIELDS.EXTERNAL_AD_UNIT_CODE_NAMESPACES.name) || [];

    const hasPrebidAdSource: boolean = adUnitCodeNamespaces.some(
        ({ value }) => value === PREBID_AD_SOURCE_EXTERNAL_AD_UNIT_CODE_NAMESPACE
    );

    const values = pbsServers.map((server) => server?.value);
    const options = pbsServersData.filter((options) => !values.includes(options.value));

    return {
        options,
        isFetching,
        hasInternalAccess: isSysAdmin || isPubAcctMgr,
        authorizedPBSServersShown: hasPrebidAdSource,
    };
};
