import { Form } from "antd";
import { useIsVisible } from "./useIsVisible";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { TagRender } from "@app/core/components";
import { useCuratorMarketplaces } from "./useCuratorMarketplaces";
import { CuratorMarketplace } from "@app/core/services";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";

export const AssociatedCuratorMarketplacesSelect = () => {
    const isVisible = useIsVisible();
    const { handleChangeSearch, hasMore, isFetching, loadMore, options, search } = useCuratorMarketplaces();

    if (!isVisible) {
        return null;
    }

    return (
        <Form.Item
            {...VERTICAL_LAYOUT_FIX}
            label={AD_SOURCE_FIELDS.ASSOCIATED_CURATOR_MARKETPLACES.label}
            name={AD_SOURCE_FIELDS.ASSOCIATED_CURATOR_MARKETPLACES.name}
            rules={[{ required: true, message: "Associated Marketplaces are required" }]}
        >
            <SelectWithValue<CuratorMarketplace>
                allowClear
                data-sdet="adsource-associated-marketplaces"
                fieldAsLabel={(v) => v.name}
                fieldAsValue={(v) => v.id}
                filterOption={false}
                hasMore={hasMore}
                loadMore={loadMore}
                loading={isFetching}
                mode="multiple"
                onSearch={handleChangeSearch}
                optionFilterProp="label"
                options={options}
                placeholder="Select…"
                searchValue={search}
                showSearch
                tagRender={TagRender}
            />
        </Form.Item>
    );
};
