import { FC } from "react";
import { Layout } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { css } from "@emotion/css";
import { Footer } from "@app/core/components";
import { useSeatAdSourcesPageNavigator } from "@app/features/seatAdSources/utils/index";
import { SeatAdSourcesForm } from "../SeatAdSourcesForm";

export const SeatAdSourcesCreatePage: FC = () => {
    const { goToSeatAdSourcesListPage } = useSeatAdSourcesPageNavigator();

    return (
        <div
            data-sdet="seat-ad-sources-create-page"
            className={css`
                #adSourceForm > .ant-collapse {
                    border: 0;
                    &:first-child {
                        border-top: 1px solid #d9d9d9;
                    }
                }
                #adSourceForm > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
                    padding: 0 16px;
                    line-height: 42px;
                    .ant-collapse-header-text {
                        font-size: 20px;
                        font-weight: 500;
                        flex: auto;
                        .ant-typography {
                            line-height: 42px;
                        }
                    }
                    .ant-collapse-arrow {
                        font-size: 16px !important;
                        vertical-align: -3px;
                    }
                    .ant-collapse-expand-icon {
                        cursor: pointer;
                    }
                }
            `}
        >
            <PageHeader title="Create Ad Source" onBack={goToSeatAdSourcesListPage} />
            <SeatAdSourcesForm />
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </div>
    );
};
