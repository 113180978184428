import { FC } from "react";
import { ReadOnlyDemandConfigurationTargeting } from "@app/features/targeting";
import { AdSource, DealListItem } from "@app/core/services";
import { useAdSourceTargetingToggle } from "@app/features/targeting/ReadOnlyTargeting/useAdSourceTargetingToggle";
import { TargetingFormKeys } from "@app/features/targeting/constants";

interface Props {
    adSource: AdSource;
    deal: DealListItem;
}

export const DealDetailsDemandTargeting: FC<Props> = ({ adSource, deal }) => {
    const { showExistingAdSourceTargeting, setShowExistingAdSourceTargeting } = useAdSourceTargetingToggle();

    const adSourceMarketplaceInfoTargeting = adSource.marketplaceInfoList
        .filter((mpi) => mpi.deal.id === deal.id)
        .map((mpi) => mpi.targeting)
        .flat();

    return (
        <ReadOnlyDemandConfigurationTargeting
            additionalTargetingBlocks={adSourceMarketplaceInfoTargeting}
            adSourceTargetingBlocks={adSource.targeting}
            showExistingAdSourceTargeting={showExistingAdSourceTargeting}
            setShowExistingAdSourceTargeting={setShowExistingAdSourceTargeting}
            adSourceTargetingFormKey={TargetingFormKeys.Deal}
            additionalTargetingFormKey={TargetingFormKeys.DealAdditionalTargeting}
            existingAdSourceTargetingModeId={adSource.targetingMode.id}
        />
    );
};
