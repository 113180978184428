import { Form, Input } from "antd";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";

export const NameInput = () => {
    return (
        <Form.Item
            {...VERTICAL_LAYOUT_FIX}
            required
            rules={[
                { required: AD_SOURCE_FIELDS.NAME.validations.required, message: "Name is required" },
                {
                    max: AD_SOURCE_FIELDS.NAME.validations.max,
                    message: `Name cannot be longer than ${AD_SOURCE_FIELDS.NAME.validations.max} characters`,
                },
                () => ({
                    validator(_, value) {
                        if (AD_SOURCE_FIELDS.NAME.validations.isOnlySpaces(value)) {
                            return Promise.reject("Name should not contain only spaces");
                        }
                        return Promise.resolve();
                    },
                }),
            ]}
            label={AD_SOURCE_FIELDS.NAME.label}
            name={AD_SOURCE_FIELDS.NAME.name}
        >
            <Input data-sdet="adsource-name" />
        </Form.Item>
    );
};
