import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME, TRANSPARENCY_SPECIFIC_VALUE } from "@app/features/deals/constants";
import { dealFormAdSourceExtendedUserId, dealFormRadioGroupChange } from "@app/features/deals/DealForm/reducer";
import { DealAdSourceTransparencySpecific } from "@app/features/deals/DealForm/types";

interface UseAdSourceIncludedExtendedIdsField {
    onChange: (value: DealAdSourceTransparencySpecific) => void;
    value?: DealAdSourceTransparencySpecific | null;
}

export const useAdSourceIncludedExtendedIdsField = (): UseAdSourceIncludedExtendedIdsField => {
    const dispatch = useAppDispatch();
    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_ALL_EXTENDED_ID]
    );
    const onChange = (value: DealAdSourceTransparencySpecific) => {
        dispatch(
            dealFormRadioGroupChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_ALL_EXTENDED_ID, value })
        );
        if (value !== TRANSPARENCY_SPECIFIC_VALUE) {
            dispatch(dealFormAdSourceExtendedUserId([]));
        }
    };

    return {
        onChange,
        value,
    };
};
