import { Form, InputNumber } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAdSourceOverrideFloorCpmField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceFloorSettingsSection/Fields/AdSourceOverrideFloorCpmField/useAdSourceOverrideFloorCpmField";

export const AdSourceOverrideFloorCpmField = () => {
    const { handleChange, value, label, handleBlur, handlePressEnter } = useAdSourceOverrideFloorCpmField();

    return (
        <Form.Item
            data-sdet="override-floor-cpm-field"
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_PRICE}
            rules={[{ message: "Booking Price is required", required: true }]}
            label={label}
        >
            <InputNumber
                onBlur={handleBlur}
                placeholder={`Enter ${label}`}
                min={0}
                value={value}
                onChange={handleChange}
                onPressEnter={handlePressEnter}
                style={{ width: "100%" }}
            />
        </Form.Item>
    );
};
