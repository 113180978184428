import { consoleApi, AUDIT_LOG_TAG, AUDIT_LOG_ENTITY_TYPES_TAG } from "./console";
import { toQueryString } from "./utils";

export type AuditLogListItem = {
    id: number;
    action: string;
    changeDate: string;
    userId: string;
    realUserId: null;
    elementType: string;
    elementId: string; // as a number
    lineage: string; // as JSON stringified object
    element: {
        deleted: boolean;
        id: number;
        seat: {
            id: number;
            name: string;
            code: string;
            entityType: string;
        };
        name: string;
        assignee: null;
        marketplace: null;
        associatedMarketplace: null;
        tagUrl: null;
        defaultAdvertiserDomain: null;
        adSourceMaxDuration: null;
        adSourceMinDuration: null;
        synthesizedAdSourceStatus: string;
    };
    changes: string; // as JSON stringified object
    message: null;
    accessKey: null;
};

type AuditLogParams = {
    rootType?: string;
    rootId?: number;
    maxRows?: number;
    page?: number;
    actionType?: string;
    type?: string;
    startDate?: string;
    endDate?: string;
};
export const auditLogsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getAuditLog: builder.query<AuditLogListItem[], AuditLogParams>({
            query: (params) => {
                return `audit/search;${toQueryString(params)}`;
            },
            providesTags: (_, __, params) => [{ type: AUDIT_LOG_TAG, id: toQueryString(params) }],
            keepUnusedDataFor: 0,
        }),
        getEntityTypes: builder.query<string[], void>({
            query: () => {
                return "audit/entityTypes";
            },
            providesTags: () => [{ type: AUDIT_LOG_ENTITY_TYPES_TAG }],
        }),
    }),
    overrideExisting: false,
});

export const { useGetAuditLogQuery, useLazyGetAuditLogQuery, useGetEntityTypesQuery } = auditLogsApi;
