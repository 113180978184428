import { FC, useEffect, useRef, useMemo } from "react";
import { Row, Col, Typography } from "antd";
import { Line, LineOptions } from "@antv/g2plot";
import { ValueMetric1, getMaxValue1 } from "@app/core/components/charts/DualAxesChart";
import { DEFAULT_COLOR, LABEL_COLORS } from "@app/core/components/charts/constants";
import { formatNumber } from "@rubicon/utils";
import moment from "moment-timezone";

interface Props {
    data: ValueMetric1[];
    isLoading?: boolean;
    id?: string;
}

const MAX_VALUE_MULTIPLIER = 1.1;
const getFormattedDate = (timestamp) =>
    moment
        .unix(timestamp / 1000)
        .utc()
        .format("HH:ss");

export const VastErrorsChart: FC<Props> = ({ data = [], id = "inventory-diagnostics-vast-erros-chart" }) => {
    const ref = useRef<Line | null>(null);

    const config: LineOptions = useMemo(
        () => ({
            data,
            autoFit: true,
            xField: "time",
            yField: "value1",
            seriesField: "name1",
            legend: false,
            tooltip: {
                title: (time) => `Time: ${getFormattedDate(time)}:00`,
            },
            xAxis: {
                label: {
                    formatter: (time) => getFormattedDate(time),
                },
            },
            yAxis: {
                min: 0,
                max: getMaxValue1(data, MAX_VALUE_MULTIPLIER),
                title: {
                    text: "Errors",
                },
                label: {
                    formatter: (v) => formatNumber.asAbbreviated(+v),
                },
            },
            color: (d) => LABEL_COLORS[d.name1] || DEFAULT_COLOR,
        }),
        [data]
    );

    useEffect(() => {
        const chart = new Line(id, config);
        ref.current = chart;
        chart.render();

        return () => {
            chart.destroy();
        };
    }, [id, config]);

    useEffect(() => {
        ref.current?.changeData(data);
    }, [data]);

    return (
        <>
            <Row justify="center">
                <Col>
                    <Typography.Title level={5}>Vast Errors Past 24 Hours</Typography.Title>
                </Col>
            </Row>
            <div id={id} />
        </>
    );
};
