import { FC } from "react";
import { Space } from "antd";
import { CurrencyConversionModeField } from "@app/features/seatAdSources/SeatAdSourcesListPage/SeatAdSourcesCurrencyFilters/CurrencyConversionModeField";
import { PreferredCurrencyField } from "@app/features/seatAdSources/SeatAdSourcesListPage/SeatAdSourcesCurrencyFilters/PreferredCurrencyField";
import { useUpdateSettingsOnChange } from "./useUpdateSettingsOnChange";
import { useAppSelector } from "@app/core/store";
import { CurrencyConversionModes } from "@app/core/clients/console";

export const SeatAdSourcesCurrencyFilters: FC = () => {
    const currencyConversionModeType = useAppSelector(
        (state) => state.seatAdSources.settings.values.adSourcesCurrencyConversionMode
    );
    useUpdateSettingsOnChange();
    return (
        <Space.Compact>
            <CurrencyConversionModeField />
            {currencyConversionModeType === CurrencyConversionModes.CONVERT && <PreferredCurrencyField />}
        </Space.Compact>
    );
};
