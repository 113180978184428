import { LiveAdSourceListItem, useFetchLiveSeatAdSources } from "../../data";
import { useSeatAdSourcesListFilters } from "../SeatAdSourcesListFilters";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { selectSeatAdSourcesListTableViewPagination, setTableViewPagination, setTableViewSort } from "../reducer";
import { SorterResult } from "antd/lib/table/interface";
import { SORT_ORDER } from "@app/features/deals/constants";
import { TableItem } from "./SeatAdSourcesListTable";

interface UseSeatAdSourcesListTable {
    liveAdSources: LiveAdSourceListItem[];
    isFetching: boolean;
    total: number;
    pageSize: number;
    current: number;
    onPaginationChange: (page: number, max: number) => void;
    handleChange: (_: unknown, __: unknown, sorter: SorterResult<TableItem>) => void;
}

const getSeatAdSourceListTableSort = (sortBy: string, sortOrder: string) => {
    if (sortBy && sortOrder === SORT_ORDER.ASCENDING) {
        return { orderBy: sortBy, asc: true };
    }
    if (sortBy && sortOrder === SORT_ORDER.DESCENDING) {
        return { orderBy: sortBy, asc: false };
    }
    return { orderBy: "", asc: null };
};

export const useSeatAdSourcesListTable = (): UseSeatAdSourcesListTable => {
    const dispatch = useAppDispatch();
    const { page, max } = useAppSelector(selectSeatAdSourcesListTableViewPagination);
    const { seatAllAdSourcesParams } = useSeatAdSourcesListFilters();
    const { liveAdSourcesListResponse, isFetching } = useFetchLiveSeatAdSources(seatAllAdSourcesParams, true);

    const handleChange = (_a: unknown, _b: unknown, sort): void => {
        const { field, order } = sort;
        dispatch(setTableViewSort(getSeatAdSourceListTableSort(field, order)));
    };

    const onPaginationChange = (page: number, max: number) => {
        dispatch(setTableViewPagination({ page, max }));
    };

    return {
        liveAdSources: liveAdSourcesListResponse?.liveAdSources,
        isFetching,
        total: liveAdSourcesListResponse?.totalResults,
        current: page,
        pageSize: max,
        onPaginationChange,
        handleChange,
    };
};
