import { FC } from "react";
import { BulkOperationEntityTypeSection } from "./BulkOperationEntityTypeSection";
import { BulkOperationEntitySelectSection } from "./BulkOperationEntitySelectSection";
import { Form, Space } from "antd";
import { BulkOperationType } from "@app/core/services";
import { BULK_OPERATION_FORM_ITEMS } from "../../constants";

export const BulkOperationSetupStep: FC = () => {
    const type = Form.useWatch(BULK_OPERATION_FORM_ITEMS.OPERATION_TYPE.name);
    const entityType = Form.useWatch(BULK_OPERATION_FORM_ITEMS.ENTITY_TYPE.name);

    return (
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <BulkOperationEntityTypeSection />
            {type === BulkOperationType.Edit && entityType && (
                <BulkOperationEntitySelectSection entityType={entityType} />
            )}
        </Space>
    );
};
