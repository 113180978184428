export const HEIGHT = 350;
export const LEGEND = false;
export const MAXIMUM_FRACTION_DIGITS = 4;
export const SLIDING_AVERAGE_WINDOW = 5;
export const X_AXIS_TICK_COUNT = 10;
export const Y_AXIS_MAX_SCALE = 1.2;
export const Y_AXIS_TITLE_TEXT_EVENTS_PER_SECOND = "Events / Sec";
export const Y_AXIS_TITLE_TEXT_REVENUE_PER_SECOND = "Rev / Sec";
export const Y_AXIS_MIN = 0;
export const Y_AXIS_TICK_COUNT = 6;
