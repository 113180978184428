import { useEffect, useState } from "react";

export const useSeatTreeHeight = (): number => {
    const [treeHeight, setTreeHeight] = useState(0);
    useEffect(() => {
        const handleWindowResize = () => {
            const el = document.querySelector("div[role='tree']");
            if (!el) {
                return;
            }
            const rect = el.getBoundingClientRect();
            const height = document.body.clientHeight - rect.y;
            setTreeHeight(height);
        };
        handleWindowResize();
        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    return treeHeight;
};
