import moment from "moment-timezone";
import { MomentRange } from "@app/core/utils/types";
import { VastErrorDescription, VastErrorOffender, VastErrors } from "./types";

export const filterVastErrorsByNtime = (vastErrors: VastErrors[], ntimeRange: MomentRange): VastErrors[] =>
    vastErrors.filter((vastError) => moment(vastError.ntime).isBetween(ntimeRange.start, ntimeRange.end));

export const sortVastErrorOffendersByErrorCount = (vastErrorOffenders: VastErrorOffender[]): VastErrorOffender[] =>
    vastErrorOffenders.sort((a, b) => b.errorCount - a.errorCount);

export const sortVastErrorDescriptionsByCode = (vastErrorDescrptions: VastErrorDescription[]): VastErrorDescription[] =>
    vastErrorDescrptions.sort((a, b) => Number(a.errorCode) - Number(b.errorCode));
