import { FC } from "react";
import { Form, Input } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME, DEAL_NAME_MAX_LENGTH } from "@app/features/deals/constants";
import { useDealNameField } from "@app/features/deals/DealForm/DealDemandFormSections/DealTermsSection/Fields/DealNameField/useDealNameField";
import { DealFormMode } from "@app/features/deals/DealForm/types";

interface Props {
    mode: DealFormMode;
}

export const DealNameField: FC<Props> = ({ mode }) => {
    const { value, handleChange, handleBlur } = useDealNameField(mode);

    return (
        <Form.Item
            data-sdet="deal-name-field"
            rules={[
                {
                    required: true,
                    message: "Deal Name is required",
                    whitespace: true,
                },
                {
                    max: DEAL_NAME_MAX_LENGTH,
                    message: `Deal Name cannot be longer than ${DEAL_NAME_MAX_LENGTH} characters`,
                },
            ]}
            label="Deal Name"
            name={CREATE_DEAL_FORM_ITEMS_NAME.NAME}
        >
            <Input value={value} onChange={handleChange} onBlur={handleBlur} />
        </Form.Item>
    );
};
