import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { LabeledValue } from "antd/es/select";
import { useGetSeatBuyerSeatListsQuery } from "@app/core/services";

interface UseSeatBuyerSeatLists {
    isFetching: boolean;
    options: LabeledValue[];
}

export const useSeatBuyerSeatLists = (): UseSeatBuyerSeatLists => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data = [], isFetching } = useGetSeatBuyerSeatListsQuery({ seatId: Number(seatId) });
    const options: LabeledValue[] = useMemo(
        () => (data || []).map((seat) => ({ value: seat.id, label: seat.name })),
        [data]
    );

    return {
        isFetching,
        options,
    };
};
