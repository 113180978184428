// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Target = Record<string, any>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getEscapedKeyValuePair = (key: string, value: any) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

export const toQueryString = (target: Target | void): string => {
    if (!target) {
        return "";
    }
    return Object.entries(target)
        .filter(([, value]) => {
            if (Array.isArray(value)) {
                return Boolean(value.length);
            }
            return value !== undefined && value !== null;
        })
        .map(([key, value]) => {
            if (Array.isArray(value)) {
                return value.map((v) => getEscapedKeyValuePair(key, v)).join(";");
            }
            return getEscapedKeyValuePair(key, value);
        })
        .join(";");
};
