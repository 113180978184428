import { useMemo } from "react";
import { SupplyType, useGetSupplyTypesQuery } from "@app/core/services";
import { DEFAULT_SUPPLY_TYPE, SUPPLY_TYPE_APPLICATION, SUPPLY_TYPE_SITE } from "@app/features/targeting/constants";
import { supplyTypesToLabelValue } from "@app/features/targeting/helpers";
import { UsedDimensions } from "@app/features/targeting/types";
import { useParams } from "react-router-dom";

const isOptionDisabled = (value: number, bundleIdRowPresent: boolean, supplyDomainRowPresent: boolean): boolean => {
    if (value === SUPPLY_TYPE_APPLICATION) {
        return supplyDomainRowPresent;
    }
    if (value === SUPPLY_TYPE_SITE) {
        return bundleIdRowPresent;
    }
    return false;
};

export const useSupplyTypes = (usedDimensions: UsedDimensions) => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data, isFetching } = useGetSupplyTypesQuery({ seatId });
    const options = useMemo(() => {
        if (!data) {
            return [];
        }

        const apiOptions = supplyTypesToLabelValue(data).map((option) => {
            return {
                ...option,
                disabled: isOptionDisabled(
                    (JSON.parse(option.value as string) as SupplyType).id,
                    usedDimensions.bundleIdTargets.includes || usedDimensions.bundleIdTargets.excludes,
                    usedDimensions.supplyDomainTargets.includes || usedDimensions.supplyDomainTargets.excludes
                ),
            };
        });

        // Insert the default option which doesn't come back from the API
        // (and must be removed later if the user selects it before sending the targeting payload)
        return [DEFAULT_SUPPLY_TYPE, ...apiOptions];
    }, [
        data,
        usedDimensions.bundleIdTargets.includes,
        usedDimensions.bundleIdTargets.excludes,
        usedDimensions.supplyDomainTargets.includes,
        usedDimensions.supplyDomainTargets.excludes,
    ]);

    return {
        isLoading: isFetching,
        options,
    };
};
