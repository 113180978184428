import { FC } from "react";
import { Form } from "antd";
import { CurrencyInput } from "@app/core/components/Fields/CurrencyInput";
import { CREATE_FLOOR_FORM_ITEMS_NAME } from "@app/features/inventory/constants";

export const FloorPriceField: FC = () => {
    return (
        <Form.Item
            label="Price"
            rules={[
                { required: true, message: `Price is required` },
                {
                    validator(_, value) {
                        if (!value || parseFloat(value) > 0) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error("Price can not be negative"));
                    },
                },
            ]}
            name={CREATE_FLOOR_FORM_ITEMS_NAME.PRICE}
        >
            <CurrencyInput currency="USD" />
        </Form.Item>
    );
};
