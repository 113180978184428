import { FC } from "react";
import { ColumnsType } from "antd/es/table";
import { useUserAccess } from "@app/core/auth";
import { DeleteOutlined } from "@ant-design/icons";
import { Table, Space, Col, Button, Row, Popconfirm } from "antd";
import { getFinanceCostValue, getFinanceType } from "@app/features/inventory/helpers";
import { TypeSelect } from "@app/features/inventory/InventorySeat/SeatDefaultPricesPage/TypeSelect";
import { PriceInput } from "@app/features/inventory/InventorySeat/SeatDefaultPricesPage/PriceInput";
import { DEFAULT_PRICES_TABLE_COLUMNS_COLUMN_KEY } from "@app/features/inventory/InventorySeat/constants";
import {
    Price,
    useDefaultPricesForm,
} from "@app/features/inventory/InventorySeat/SeatDefaultPricesPage/DefaultPricesTable/useDefaultPricesForm";
import { PricingModelSelect } from "@app/features/inventory/InventorySeat/SeatDefaultPricesPage/PricingModelSelect";

export const DefaultPricesTable: FC = () => {
    const { showDefaultPrices } = useUserAccess();
    const {
        prices,
        validate,
        isFetching,
        isSavingIndex,
        isAddingIndex,
        isDeletingIndex,
        isMoreThanOneNewPrice,
        handleAdd,
        handleSave,
        handleDelete,
        handleDeleteNew,
        handleChangeType,
        handleChangePrice,
        handleChangeCostModel,
    } = useDefaultPricesForm();
    return (
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Row gutter={[0, 24]} justify="end">
                <Col>
                    {showDefaultPrices && (
                        <Button disabled={isMoreThanOneNewPrice} type="primary" onClick={handleAdd}>
                            Add Default Price
                        </Button>
                    )}
                </Col>
                <Col span={24}>
                    <Table
                        size="small"
                        loading={isFetching}
                        scroll={{ x: 1000 }}
                        columns={
                            [
                                {
                                    ...DEFAULT_PRICES_TABLE_COLUMNS_COLUMN_KEY.COST,
                                    render: (record) => {
                                        if (isAddingIndex.has(record.key)) {
                                            return (
                                                <PriceInput
                                                    price={record}
                                                    onChange={(value) => handleChangePrice(record.key, value)}
                                                />
                                            );
                                        }
                                        return getFinanceCostValue(record?.price);
                                    },
                                    sorter: (a, b) =>
                                        Number(a?.price?.costValuePercent) - Number(b?.price?.costValuePercent),
                                },
                                {
                                    ...DEFAULT_PRICES_TABLE_COLUMNS_COLUMN_KEY.COST_MODEL,
                                    render: (record) => {
                                        if (isAddingIndex.has(record?.key)) {
                                            return (
                                                <PricingModelSelect
                                                    price={record}
                                                    onChange={(value: number) =>
                                                        handleChangeCostModel(record.key, value)
                                                    }
                                                />
                                            );
                                        }
                                        return record?.price?.costModel?.name;
                                    },
                                    sorter: (a, b) =>
                                        String(a?.price?.costModel?.name).localeCompare(
                                            String(b?.price?.costModel?.name)
                                        ),
                                },
                                {
                                    ...DEFAULT_PRICES_TABLE_COLUMNS_COLUMN_KEY.TYPE,
                                    render: (record) => {
                                        if (isAddingIndex.has(record?.key)) {
                                            return (
                                                <TypeSelect
                                                    validate={validate}
                                                    price={record}
                                                    onChange={(value: number) => handleChangeType(record.key, value)}
                                                />
                                            );
                                        }
                                        return getFinanceType(record?.price);
                                    },
                                    sorter: (a, b) =>
                                        String(a?.price?.adSourceType?.name).localeCompare(
                                            String(b?.price?.adSourceType?.name)
                                        ),
                                },
                                showDefaultPrices && {
                                    ...DEFAULT_PRICES_TABLE_COLUMNS_COLUMN_KEY.ACTIONS,
                                    render: (record) => {
                                        if (isAddingIndex.has(record.key)) {
                                            return (
                                                <Button.Group>
                                                    <Button
                                                        style={{ marginRight: "0.5rem" }}
                                                        onClick={() => handleDeleteNew(record.key)}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        type="primary"
                                                        loading={isSavingIndex.has(record.key)}
                                                        onClick={() => handleSave(record.key)}
                                                    >
                                                        Save
                                                    </Button>
                                                </Button.Group>
                                            );
                                        }
                                        return (
                                            <Button.Group>
                                                <Popconfirm
                                                    title="Are you sure?"
                                                    onConfirm={() => handleDelete(record.key)}
                                                >
                                                    <Button
                                                        icon={<DeleteOutlined />}
                                                        loading={isDeletingIndex.has(record.key)}
                                                    />
                                                </Popconfirm>
                                            </Button.Group>
                                        );
                                    },
                                },
                            ].filter(Boolean) as ColumnsType<Price>
                        }
                        dataSource={prices}
                        pagination={false}
                        showSorterTooltip={false}
                    />
                </Col>
            </Row>
        </Space>
    );
};
