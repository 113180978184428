import { PriceOverrideForm } from "./PriceOverrideForm";
import { useHistory, useParams } from "react-router-dom";
import { useNotificationError } from "@app/core/components/hooks";
import { Loading } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useGetPriceOverrideConfigByIdQuery } from "@app/core/services";
import { PriceOverrideDefinitionsButtonAndDrawer } from "./PriceOverridesPage/PriceOverrideDefinitions";
import { PageHeader } from "@ant-design/pro-layout";

export const PriceOverrideEditPage = () => {
    const { seatId, priceOverrideId } = useParams<{ seatId: string; priceOverrideId: string }>();

    const { data: priceOverride, isLoading, error } = useGetPriceOverrideConfigByIdQuery(Number(priceOverrideId));

    useNotificationError(error);

    const history = useHistory();
    if (isLoading) return <Loading />;

    if (!priceOverride) return null;

    return (
        <>
            <PageHeader
                onBack={() =>
                    history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_PRICE_OVERRIDE_DETAILS_PAGE(seatId, priceOverrideId))
                }
                title={`Edit: ${priceOverride.name}`}
                extra={<PriceOverrideDefinitionsButtonAndDrawer />}
            />
            <PriceOverrideForm priceOverride={priceOverride} />
        </>
    );
};
