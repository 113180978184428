import { FC } from "react";
import { Card, Typography, Row, Col } from "antd";

import {
    BlockBuyerSeaList,
    BlockedCategories,
    BlockedAdvertiserDomains,
    BlockAdvertiserDomainLists,
} from "@app/features/inventory/HierarchyForms/PublisherChannelForm/PublisherChannelFormSections/AdvancedFeaturesSection/Sections/BrandSafetySection/Fields";

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 };
export const BrandSafetySection: FC = () => (
    <Card bordered={false}>
        <Row>
            <Col {...COL_SIZES}>
                <Typography.Paragraph style={{ marginBottom: "1.5rem" }}>
                    The following values will be merged with the values passed in the ad request
                </Typography.Paragraph>
                <BlockAdvertiserDomainLists />
                <BlockedAdvertiserDomains />
                <BlockBuyerSeaList />
                <BlockedCategories />
            </Col>
        </Row>
    </Card>
);
