import { Col, Form, Radio } from "antd";
import { AD_SOURCE_FIELDS, COL_SIZES, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { useIsFieldVisible } from "./useIsFieldVisible";

export const RunUnderInboundFloorRadio = () => {
    const isVisible = useIsFieldVisible();
    if (!isVisible) {
        return null;
    }
    return (
        <Col {...COL_SIZES}>
            <Form.Item
                name={AD_SOURCE_FIELDS.RUN_UNDER_INBOUND_FLOOR.name}
                label={AD_SOURCE_FIELDS.RUN_UNDER_INBOUND_FLOOR.label}
                data-sdet={`test-${AD_SOURCE_FIELDS.RUN_UNDER_INBOUND_FLOOR.name}-field`}
                {...VERTICAL_LAYOUT_FIX}
            >
                <Radio.Group data-sdet={`test-${AD_SOURCE_FIELDS.RUN_UNDER_INBOUND_FLOOR.name}-radio-group`}>
                    <Radio value={true} data-sdet={`test-${AD_SOURCE_FIELDS.RUN_UNDER_INBOUND_FLOOR.name}-yes`}>
                        Yes
                    </Radio>
                    <Radio value={false} data-sdet={`test-${AD_SOURCE_FIELDS.RUN_UNDER_INBOUND_FLOOR.name}-no`}>
                        No
                    </Radio>
                </Radio.Group>
            </Form.Item>
        </Col>
    );
};
