import { FC } from "react";
import { Form, Radio } from "antd";
import { INVENTORY_YES_NO_OPTIONS, CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const AllowVast: FC = () => {
    return (
        <Form.Item
            name={CHANNEL_FORM_FIELDS.ALLOW_VAST_WRAPPERS.name}
            label={CHANNEL_FORM_FIELDS.ALLOW_VAST_WRAPPERS.label}
        >
            <Radio.Group options={INVENTORY_YES_NO_OPTIONS} />
        </Form.Item>
    );
};
