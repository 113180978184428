import { FC, useEffect, useRef } from "react";
import { DualAxes, DualAxesOptions } from "@antv/g2plot";

interface Props {
    chartId: string;
    config?: DualAxesOptions;
}

export const DualAxesChart: FC<Props> = (props) => {
    const dualAxesRef = useRef<DualAxes | null>(null);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const dualAxes = new DualAxes(props.chartId, props.config);
        dualAxesRef.current = dualAxes;
        dualAxes.render();

        return () => {
            dualAxes.destroy();
        };
    }, [props.config, props.chartId]);

    return <div id={props.chartId} />;
};
