import { FC } from "react";
import { LabeledValue } from "antd/lib/select";
import { useHistoricalChartDealFilter } from "./useHistoricalChartDealFilter";
import { Form, Select } from "antd";
import { TagRender } from "@app/core/components/TagRender";
import { MarketplaceInfo } from "@app/core/services";

interface HistoricalChartDealFilterProps {
    marketplaceInfoList: MarketplaceInfo[];
}

export const HistoricalChartDealFilter: FC<HistoricalChartDealFilterProps> = ({ marketplaceInfoList }) => {
    const { options, handleChange, value } = useHistoricalChartDealFilter(marketplaceInfoList);

    return (
        <Form.Item data-sdet={"ad-source-chart-historical-deal-filter"} label={"Deal"} style={{ marginBottom: 0 }}>
            <Select
                allowClear
                placeholder={"All"}
                onChange={(_, option) => handleChange(option as LabeledValue)}
                options={options}
                style={{ width: "100%" }}
                value={value}
                tagRender={TagRender}
            />
        </Form.Item>
    );
};
