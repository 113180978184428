import { FC } from "react";
import { Form, Input } from "antd";
import { MAX_DESCRIPTION_LENGTH, SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

const { TextArea } = Input;

export const Description: FC = () => {
    return (
        <Form.Item
            name={SUPPLY_FORM_FIELDS.DESCRIPTION.name}
            label={SUPPLY_FORM_FIELDS.DESCRIPTION.label}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 22, xxl: 22 }}
            rules={[{ max: MAX_DESCRIPTION_LENGTH, message: SUPPLY_FORM_FIELDS.DESCRIPTION.rulesMessage.maxLength }]}
        >
            <TextArea rows={4} allowClear placeholder={SUPPLY_FORM_FIELDS.DESCRIPTION.placeholder} />
        </Form.Item>
    );
};
