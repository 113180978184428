import { Form, FormInstance } from "antd";
import { useParams } from "react-router-dom";
import { createContext, FC, useContext } from "react";
import {
    Seat,
    Brand,
    Publisher,
    BrandSupply,
    useGetBrandByIdQuery,
    useGetSeatSelfQuery,
    useGetPublisherByIdQuery,
} from "@app/core/services";
import { HierarchyFormsMode } from "@app/features/inventory/HierarchyForms/constants";
import { SupplyForm } from "@app/features/inventory/HierarchyForms/SupplyForm/useSupplyForm";

export interface SupplyFormInstanceProps {
    supply?: BrandSupply;
    mode: HierarchyFormsMode;
}

type SupplyFormInstanceContextType = SupplyFormInstanceProps & {
    brandId: number;
    seatId: string;
    seat: Seat | null;
    isLoading: boolean;
    publisherId: number;
    brand: Brand | undefined;
    useWatch: typeof Form.useWatch;
    form: FormInstance<SupplyForm>;
    publisher: Publisher | undefined;
};

const SupplyFormInstanceContext = createContext<SupplyFormInstanceContextType | null>(null);

export const SupplyFormInstance: FC<SupplyFormInstanceProps> = ({ children, ...rest }) => {
    const [form] = Form.useForm<SupplyForm>();
    const { seatId, publisherId, brandId } = useParams<{ seatId: string; publisherId: string; brandId: string }>();
    const { data: seatSelf, isFetching: isLoadingSeat } = useGetSeatSelfQuery(seatId);
    const { data: brand, isFetching: isBrandFetching } = useGetBrandByIdQuery(Number(brandId));
    const { data: publisher, isFetching: isPublisherFetching } = useGetPublisherByIdQuery(Number(publisherId));

    const isLoading: boolean = isPublisherFetching || isBrandFetching || isLoadingSeat;
    return (
        <SupplyFormInstanceContext.Provider
            value={{
                form,
                brand,
                publisher,
                seatId,
                isLoading,
                useWatch: Form.useWatch,
                brandId: Number(brandId),
                seat: seatSelf?.seat || null,
                publisherId: Number(publisherId),
                ...rest,
            }}
        >
            {children}
        </SupplyFormInstanceContext.Provider>
    );
};
export const useSupplyFormInstance = (): SupplyFormInstanceContextType => {
    const context: SupplyFormInstanceContextType | null = useContext(SupplyFormInstanceContext);
    if (!context) throw new Error("useSupplyFormInstance must be used within a SupplyFormInstance");
    return context;
};
