import { consoleApi } from "./console";
import { Entity } from "./supply";

interface ViewabilityMesurementVendor extends Entity {
    entityType: "ViewabilityMeasurementVendor";
    sort: number;
}

export const viewabilityMesurementVendorApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getViewabilityMesurementVendor: builder.query<ViewabilityMesurementVendor[], void>({
            query: () => "viewabilityMeasurementVendor",
        }),
    }),
});

export const { useGetViewabilityMesurementVendorQuery } = viewabilityMesurementVendorApi;
