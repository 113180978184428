import { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { Layout } from "antd";
import { useControlsNavAccess } from "./useControlsNavAccess";
import { CustomPixelsSwitch } from "./customPixels/CustomPixelsSwitch";
import { EncryptionKeysPage } from "./encryptionKeys";
import { ClassificationsSwitch } from "./classifications/ClassificationsSwitch";
import { TagParamsPage } from "./tagParams";
import { AuditLogPage } from "./auditLog";
import { PriceOverridesSwitch } from "./adResponses/PriceOverridesSwitch";
import { RecycleBinPage } from "./recycleBin/RecycleBinPage";
import { SeatContactsSwitch } from "./seatContacts/SeatContactsSwitch";
import { BulkOperationsSwitch } from "./bulkOperations/BulkOperationsSwitch";
import { TargetingSwitch } from "./targeting";
import { BrandSafetySwitch } from "./brandSafety/BrandSafetySwitch";

export const ControlsSwitch: FC = () => {
    const {
        hasTagParametersAccess,
        hasSeatContactsAccess,
        hasEncryptionKeysAccess,
        hasCustomPixelsAccess,
        hasAdvancedFloorsAccess,
        hasTargetingAccess,
        hasBrandSafetyAccess,
        hasFiltersAccess,
        hasBulkOperationsAccess,
        hasClassificationsAccess,
        hasAuditLogsAccess,
        hasRecycleBinAccess,
        hasPriceOverridesAccess,
        controlsNavItems,
    } = useControlsNavAccess();

    return (
        <Switch>
            <Redirect
                exact
                from={ROUTES.SEAT_CONTROLS}
                to={
                    hasTargetingAccess
                        ? ROUTES.SEAT_CONTROLS_TARGETING
                        : `${ROUTES.SEAT_CONTROLS}/${controlsNavItems[0].key}`
                }
            />
            {hasTagParametersAccess && (
                <Route exact path={ROUTES.SEAT_CONTROLS_TAG_PARAMETERS}>
                    <TagParamsPage />
                </Route>
            )}
            {hasSeatContactsAccess && (
                <Route path={ROUTES.SEAT_CONTROLS_SEAT_CONTACTS}>
                    <SeatContactsSwitch />
                </Route>
            )}
            {hasEncryptionKeysAccess && (
                <Route exact path={ROUTES.SEAT_CONTROLS_ENCRYPTION_KEYS}>
                    <EncryptionKeysPage />
                </Route>
            )}
            {hasCustomPixelsAccess && (
                <Route path={ROUTES.SEAT_CONTROLS_CUSTOM_PIXELS}>
                    <CustomPixelsSwitch />
                </Route>
            )}
            {hasAdvancedFloorsAccess && (
                <Route exact path={ROUTES.SEAT_CONTROLS_ADVANCED_FLOORS}>
                    <Layout.Content>
                        <p>Placeholder for Advanced Floors Tab Content</p>
                    </Layout.Content>
                </Route>
            )}
            {hasTargetingAccess && (
                <Route path={ROUTES.SEAT_CONTROLS_TARGETING}>
                    <TargetingSwitch />
                </Route>
            )}
            {hasBrandSafetyAccess && (
                <Route path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY}>
                    <BrandSafetySwitch />
                </Route>
            )}
            {hasFiltersAccess && (
                <Route exact path={ROUTES.SEAT_CONTROLS_FILTERS}>
                    <Layout.Content>
                        <p>Placeholder for Filters Tab Content</p>
                    </Layout.Content>
                </Route>
            )}
            {hasBulkOperationsAccess && (
                <Route path={ROUTES.SEAT_CONTROLS_BULK_OPERATIONS}>
                    <BulkOperationsSwitch />
                </Route>
            )}
            {hasClassificationsAccess && (
                <Route path={ROUTES.SEAT_CONTROLS_CLASSIFICATIONS}>
                    <ClassificationsSwitch />
                </Route>
            )}
            {hasAuditLogsAccess && (
                <Route exact path={ROUTES.SEAT_CONTROLS_AUDIT_LOGS}>
                    <AuditLogPage />
                </Route>
            )}
            {hasRecycleBinAccess && (
                <Route exact path={ROUTES.SEAT_CONTROLS_RECYCLE_BIN}>
                    <RecycleBinPage />
                </Route>
            )}
            {hasPriceOverridesAccess && (
                <Route path={ROUTES.SEAT_CONTROLS_PRICE_OVERRIDES}>
                    <PriceOverridesSwitch />
                </Route>
            )}
        </Switch>
    );
};
