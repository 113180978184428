import { UNDEFINED_VAST_ERROR, VastErrorDescriptionsByCode } from "./constants";
import { VastErrorDescription, VastErrorOffender, VastErrors, VastErrorSum } from "./types";

interface VastErrorOffendersByCreativeId {
    [key: number]: {
        networkId: number;
        networkName: string;
        adomain: string;
        errorCount: number;
    };
}

export const getVastErrorsSums = (vastErrorNap: VastErrors): VastErrorSum => {
    const vastErrors = vastErrorNap.errorCountsByNap.map((nap) => nap.errors);
    return vastErrors.reduce((map, errors) => {
        Object.keys(errors).forEach((errorCode) => {
            if (map[errorCode]) {
                map[errorCode] += errors[errorCode];
            } else {
                map[errorCode] = errors[errorCode];
            }
        });
        return map;
    }, {});
};

export const getVastErrorOffenders = (vastErrors: VastErrors[]): VastErrorOffender[] => {
    const allOffenders = vastErrors
        .map((vastError) =>
            vastError.errorCountsByNap.map((errorCount) => ({
                networkId: errorCount.networkId,
                networkName: errorCount.networkName,
                adomain: errorCount.adomain,
                creativeId: errorCount.creativeId,
                errorCount: Object.values(errorCount.errors).reduce((sum, error) => sum + error, 0),
            }))
        )
        .flat(1);

    const offendersByCreativeId: VastErrorOffendersByCreativeId = allOffenders.reduce((offenders, offender) => {
        const { networkId, networkName, adomain, creativeId, errorCount } = offender;
        if (offenders[creativeId]) {
            offenders[creativeId].errorCount += errorCount;
        } else {
            offenders[creativeId] = {
                networkId,
                networkName,
                adomain,
                errorCount,
            };
        }
        return offenders;
    }, {});

    const offenders = Object.entries(offendersByCreativeId).map((offendersByDspEntry) => {
        const creativeId = offendersByDspEntry[0];
        const { networkId, networkName, adomain, errorCount } = offendersByDspEntry[1];
        return {
            networkId,
            networkName,
            adomain,
            creativeId,
            errorCount,
        };
    });

    return offenders;
};

export const getUniqueVastErrorsCodes = (vastErrors: VastErrors[]): string[] => {
    const allErrorCodes = vastErrors
        .map((vastError) => vastError.errorCountsByNap.map((errorCount) => Object.keys(errorCount.errors)))
        .flat(2);
    return [...new Set(allErrorCodes)];
};

export const getVastErrorCodeDescriptions = (vastErrors: VastErrors[]): VastErrorDescription[] => {
    const vastErrorCodes = getUniqueVastErrorsCodes(vastErrors);
    return vastErrorCodes.map((errorCode) => ({
        errorCode,
        description: VastErrorDescriptionsByCode[errorCode] ?? UNDEFINED_VAST_ERROR,
    }));
};
