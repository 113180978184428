import { Typography } from "antd";
import { FC } from "react";

interface Props {
    title: string;
}

export const SectionTitle: FC<Props> = ({ title }) => {
    return (
        <div
            style={{
                padding: "16px",
                backgroundColor: "#fafafa",
                borderTop: "1px solid #d9d9d9",
                borderBottom: "1px solid #d9d9d9",
            }}
        >
            <Typography.Text strong>{title}</Typography.Text>
        </div>
    );
};
