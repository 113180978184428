import { BulkOperationEntityType } from "@app/core/services";
import { Form } from "antd";
import { FC } from "react";
import { BulkOperationAdSourceEntitiesEditTable } from "./BulkOperationAdSourceEntitiesEditTable";
import { BulkOperationAdUnitEntitiesEditTable } from "./BulkOperationAdUnitEntitiesEditTable";
import { BulkOperationBuyerDealEntitiesEditTable } from "./BulkOperationBuyerDealEntitiesEditTable";
import { BulkOperationDemandDealEntitiesEditTable } from "./BulkOperationDemandDealEntitiesEditTable";

export const BulkOperationEntitiesEditTable: FC = () => {
    const form = Form.useFormInstance();
    const { entityType } = form.getFieldsValue(true);

    switch (entityType) {
        case BulkOperationEntityType.AdSource:
            return <BulkOperationAdSourceEntitiesEditTable />;
        case BulkOperationEntityType.AdUnit:
            return <BulkOperationAdUnitEntitiesEditTable />;
        case BulkOperationEntityType.BuyerDeal:
            return <BulkOperationBuyerDealEntitiesEditTable />;
        case BulkOperationEntityType.DemandDeal:
            return <BulkOperationDemandDealEntitiesEditTable />;
        default:
            return null;
    }
};
