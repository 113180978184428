import { useEffect, useState, useCallback } from "react";

export const useEmailLocalStorage = (key: string) => {
    const [email, setEmailValue] = useState<string | null>("");

    const setEmail = useCallback(
        (value: string) => {
            try {
                localStorage.setItem(key, value);
            } catch (e) {
                console.error(e);
            }
            setEmailValue(value);
        },
        [key]
    );

    const removeEmail = useCallback(() => {
        try {
            localStorage.removeItem(key);
        } catch (e) {
            console.error(e);
        }
        setEmailValue(null);
    }, [key]);

    useEffect(() => {
        try {
            const value = localStorage.getItem(key);
            if (value) {
                setEmailValue(value);
            }
        } catch (e) {
            console.error(e);
        }
    }, [key]);

    return { email, setEmail, removeEmail };
};
