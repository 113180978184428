import { FC } from "react";
import { LabeledValue } from "antd/lib/select";
import { applyCustomLabel, TagRender } from "@app/core/components/TagRender";
import { InventoryCategoryTypes, InventoryLabelValue } from "@app/features/targeting/constants";
import { useDimensionsSelect } from "./useDimensionsSelect";
import { LoadOnScrollSelect } from "@app/core/components";
import { SelectAllButtonDropdownRender } from "@app/core/components/SelectAllButtonDropdownRender";

interface Props {
    onChange: (value: LabeledValue[]) => void;
    value: InventoryLabelValue[];
    category: InventoryCategoryTypes;
}

export const DimensionsSelect: FC<Props> = ({ onChange, value, category }) => {
    const { isCurrent, isLoading, onSearch, options, onBlur, hasMore, loadMore, search } =
        useDimensionsSelect(category);

    return (
        <LoadOnScrollSelect
            allowClear
            data-sdet="DimensionSelect"
            filterOption={false}
            hasMore={hasMore}
            labelInValue
            loadMore={loadMore}
            loading={isLoading}
            mode="multiple"
            onBlur={onBlur}
            onChange={onChange}
            onSearch={onSearch}
            options={options}
            placeholder="Select Values"
            searchValue={search}
            showSearch
            style={{ width: "100%" }}
            dropdownRender={(menu) => (
                <SelectAllButtonDropdownRender<InventoryLabelValue>
                    getPrimaryKey={(v) => v.value}
                    hasMore={hasMore}
                    isCurrent={isCurrent}
                    menu={menu}
                    onChange={onChange}
                    options={options}
                    search={search}
                    value={value}
                />
            )}
            tagRender={(props) => <TagRender {...props} label={applyCustomLabel(props.label)} />}
            value={value}
        />
    );
};
