import { ReactNode, PropsWithChildren } from "react";
import { Col, Row, Space } from "antd";
import { BracketGroup } from "@rubicon/antd-components";
import { RuleGroup, RuleIdentifier, Rule } from "@app/features/targeting/types";
import { GroupConditions } from "../constants";
import { GroupCondition } from "./GroupCondition";

interface Props<T extends RuleIdentifier> {
    group: RuleGroup<T>;
    path: number[];
    identifierRowRender: (ruleIdentifier: T, pathStr: string, groupIndex: number) => ReactNode;
    groupRowRender: (ruleGroup: RuleGroup<T>, pathStr: string, groupIndex: number) => ReactNode;
}

const isRuleIdentifier = <T extends RuleIdentifier>(rule: Rule<T>): rule is T => {
    return "id" in rule;
};

const getGroupColor = (condition: GroupConditions) => (condition === GroupConditions.Or ? "#7666F9" : "#C1715B");

export function RuleGroupRow<T extends RuleIdentifier>({
    group,
    path,
    identifierRowRender,
    groupRowRender,
}: PropsWithChildren<Props<T>>) {
    return (
        <Row>
            <Col xs={24}>
                <BracketGroup
                    isSubBracket={Boolean(path.length)}
                    color={getGroupColor(group.condition)}
                    label={<GroupCondition value={group.condition} />}
                >
                    <Space direction="vertical" style={{ width: "100%", paddingTop: "5px" }}>
                        {group.rules.map((rule, groupIndex) => {
                            const pathStr = path.join(",");
                            if (isRuleIdentifier(rule)) {
                                return identifierRowRender(rule, pathStr, groupIndex);
                            }
                            return groupRowRender(rule, pathStr, groupIndex);
                        })}
                    </Space>
                </BracketGroup>
            </Col>
        </Row>
    );
}
