import { useLoadOnScroll } from "@app/core/components";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { useGetBuyerSeatListBuyerSeatsQuery } from "@app/core/services";
import { Form } from "antd";
import { LabeledValue } from "antd/es/select";
import debounce from "lodash.debounce";
import { useMemo, useState } from "react";
import { COLUMN_KEYS } from "../../../constants";

const MAX = 200;
interface UseBuyerSeatsSelect {
    value: LabeledValue[];
    hasMore: boolean;
    isFetching: boolean;
    loadMore: () => void;
    onChange: (value: LabeledValue[]) => void;
    onSearch: (value: string) => void;
    options: LabeledValue[];
    searchValue: string | undefined;
}
export const useBuyerSeatsSelect = (): UseBuyerSeatsSelect => {
    const { setFieldValue, validateFields, getFieldValue } = Form.useFormInstance();
    const [searchValue, setSearchValue] = useState<string>("");
    const [keyword, setKeyword] = useState<string>("");
    const [pageByKeyword, setPageByKeyword] = useState({ "": 1 });
    const page = pageByKeyword[keyword] || 1;

    const { data, isFetching, originalArgs } = useGetBuyerSeatListBuyerSeatsQuery({ keyword, max: MAX, page });

    const {
        options: rawOptions,
        hasMore,
        loadMore,
    } = useLoadOnScroll(data, isFetching, keyword, originalArgs?.page || 1, data?.length === MAX, () =>
        setPageByKeyword((prev) => {
            const currentPage = prev[keyword] || 1;
            return {
                ...prev,
                [keyword]: currentPage + 1,
            };
        })
    );

    const options = useMemo(
        () =>
            (rawOptions || []).map(({ buyerSeat, network }) => ({
                label: `${network.name} > ${buyerSeat.code} - ${buyerSeat.name}`,
                value: buyerSeat.id,
                key: String(buyerSeat.id),
            })),

        [rawOptions]
    );

    const onChange = (values: LabeledValue[]): void => {
        setFieldValue(COLUMN_KEYS.BUYER_SEATS, values);
        validateFields([COLUMN_KEYS.BUYER_SEATS]);
    };

    const debouncedSetKeyword = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setKeyword]
    );

    const onSearch = (value: string): void => {
        setSearchValue(value);
        debouncedSetKeyword(value);
    };

    return {
        value: getFieldValue(COLUMN_KEYS.BUYER_SEATS),
        searchValue,
        isFetching,
        loadMore,
        onChange,
        onSearch,
        hasMore,
        options,
    };
};
