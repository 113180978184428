import { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
    SUPPLY_FORM_FIELDS,
    SUPPLY_LINEAR_TYPE,
    SUPPLY_STATIC_MODE,
} from "@app/features/inventory/HierarchyForms/constants";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { CheckboxOptionType } from "antd/lib/checkbox/Group";
import { BrandSupply, useGetSupplyTypesQuery } from "@app/core/services";
import { useSupplyFormInstance } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormInstance";

interface UseSupplyType {
    isEditMode: boolean;
    options: CheckboxOptionType[];
    supply: BrandSupply | undefined;
    onChange: (e: RadioChangeEvent) => void;
}

export const useSupplyType = (): UseSupplyType => {
    const { form, mode, supply } = useSupplyFormInstance();
    const { seatId } = useParams<{ seatId: string }>();
    const isEditMode: boolean = mode === "edit";
    const { data } = useGetSupplyTypesQuery({ seatId });

    const options: CheckboxOptionType[] = useMemo(
        () => (data || []).map(({ id, name }) => ({ value: id, label: name })),
        [data]
    );
    const onChange = (e: RadioChangeEvent): void => {
        const { value } = e.target;
        if (value === SUPPLY_LINEAR_TYPE.value)
            return form.setFieldValue(SUPPLY_FORM_FIELDS.MODE.name, SUPPLY_STATIC_MODE);
    };
    return {
        supply,
        options,
        onChange,
        isEditMode,
    };
};
