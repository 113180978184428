import { useGetOperatorsQuery } from "@app/core/services";
import { durationTargetToLabelValue } from "@app/features/targeting/helpers";
import { useMemo } from "react";

export const useMaxDurationTargetSelect = () => {
    const { data, isFetching } = useGetOperatorsQuery();
    const options = useMemo(() => durationTargetToLabelValue(data || []), [data]);

    return {
        isLoading: isFetching,
        options,
    };
};
