import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DEFAULT_PAGE_SIZE } from "@app/core/components/constants";
import { PublishersTablePagination, PublishersTableSort } from "./types";

export interface BrandSafetyPublishersTableState {
    sort: PublishersTableSort;
    pagination: PublishersTablePagination;
}

const initialState: BrandSafetyPublishersTableState = {
    sort: {
        orderBy: "",
        asc: null,
    },
    pagination: {
        page: 1,
        max: DEFAULT_PAGE_SIZE,
    },
};

const BrandSafetyPublishersTableSlice = createSlice({
    name: "BrandSafetyPublishersTable",
    initialState,
    reducers: {
        setBrandSafetyPublishersTableSort: (state, action: PayloadAction<PublishersTableSort>) => {
            state.sort = action.payload;
        },
        setBrandSafetyPublishersPagination: (state, action: PayloadAction<PublishersTablePagination>) => {
            state.pagination = action.payload;
        },
    },
});

export const { setBrandSafetyPublishersTableSort, setBrandSafetyPublishersPagination } =
    BrandSafetyPublishersTableSlice.actions;
export default BrandSafetyPublishersTableSlice.reducer;
