import { DealList, useGetDealAdSourceByIdQuery } from "@app/core/services/console";
import { useParams } from "react-router-dom";

interface DealDetailsPageParams {
    dealId: string;
}

interface UseDealAdSourceById {
    dealAdSource: DealList;
    isLoading: boolean;
}

export const useDealAdSourceById = (id?: number): UseDealAdSourceById => {
    const { dealId } = useParams<DealDetailsPageParams>();
    const currentId = id ? id : Number(dealId);
    const { data: dealAdSource, isFetching } = useGetDealAdSourceByIdQuery(currentId, {
        skip: !currentId,
    });

    return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dealAdSource,
        isLoading: isFetching,
    };
};
