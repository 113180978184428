import { ChangeEvent, useState, useMemo, useCallback } from "react";
import { RootState, useAppDispatch, useAppSelector } from "@app/core/store";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";

interface SearchFilter {
    searchKeyword: string;
}

interface TableFilterSearchParams {
    selectSearchFilter: (state: RootState) => SearchFilter;
    setSearchFilter: (searchFilter: SearchFilter) => void;
}

interface UseTableFilterSearch {
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleReset: () => void;
    localValue: string;
    handleSearch: (value: string) => void;
}

export const useTableFilterSearch = ({
    selectSearchFilter,
    setSearchFilter,
}: TableFilterSearchParams): UseTableFilterSearch => {
    const dispatch = useAppDispatch();
    const { searchKeyword } = useAppSelector<{ searchKeyword: string }>(selectSearchFilter);
    const [localValue, setLocalValue] = useState(searchKeyword);

    const handleSearch = useCallback(
        (value: string): void => {
            dispatch(setSearchFilter({ searchKeyword: value.trim() }));
        },
        [dispatch, setSearchFilter]
    );

    const debouncedHandleSearch = useMemo(
        () => debounce((value: string) => handleSearch(value), FILTER_INPUT_DEBOUNCE_TIME),
        [handleSearch]
    );

    const handleReset = (): void => {
        setLocalValue("");
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setLocalValue(e?.target?.value);
        debouncedHandleSearch(e?.target?.value);
    };

    return {
        handleChange,
        handleReset,
        localValue,
        handleSearch,
    };
};
