import { FC } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { useHistory } from "react-router-dom";
import { BrandSupply } from "@app/core/services";
import { SupplyTrafficSwitch } from "@app/features/inventory/components/SupplyTrafficSwitch";

interface SupplyEditPageHeaderProps {
    supply: BrandSupply;
}

export const SupplyEditPageHeader: FC<SupplyEditPageHeaderProps> = ({ supply }) => {
    const history = useHistory();
    const onBack = (): void => history.goBack();

    return (
        <PageHeader
            onBack={onBack}
            subTitle={supply.code}
            title={`Edit Supply: ${supply.name}`}
            extra={[<SupplyTrafficSwitch key="trafficSwitch" supply={supply} />]}
        />
    );
};
