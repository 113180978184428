import { useGetLabelValuesQuery, useGetSeatLabelsQuery } from "@app/core/services";
import { useParams } from "react-router-dom";
import { LabeledValue } from "antd/lib/select";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useEffect, useMemo, useState } from "react";
import { dealFormAdSourceLabels } from "@app/features/deals/DealForm/reducer";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";

interface UseAdSourceLabel {
    labels: LabeledValue[];
    isLoadingLabel: boolean;
    isLoadingValue: boolean;
    labelId: number | null;
    valueId: number | null;
    labelOptions: LabeledValue[];
    valueOptions: LabeledValue[];
    handleChangeLabel: (id: number) => void;
    handleChangeValue: (id: number) => void;
    handleAddLabelValue: () => void;
    handleRemoveLabelValue: (id: number) => void;
}
export const useAdSourceLabel = (): UseAdSourceLabel => {
    const dispatch = useAppDispatch();
    const { seatId } = useParams<{ seatId: string }>();

    const adSourceLabels = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_LABELS]
    );

    const [labelId, setLabelId] = useState<number | null>(null);
    const [valueId, setValueId] = useState<number | null>(null);

    const { data: labelData, isFetching: isFetchingLabel } = useGetSeatLabelsQuery({ seatId: Number(seatId) });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { data: valueData, isFetching: isFetchingValue } = useGetLabelValuesQuery(labelId, { skip: !labelId });

    const labelOptions = useMemo(
        () => (labelData || []).map(({ key, id }) => ({ label: key, value: id })),
        [labelData]
    );
    const valueOptions = useMemo(
        () => (valueData || []).map(({ value, id }) => ({ label: value, value: id })),
        [valueData]
    );

    const labels = useMemo(
        () => (adSourceLabels || []).map(({ id, value, label: { key } }) => ({ label: `${key}: ${value}`, value: id })),
        [adSourceLabels]
    );

    const handleChangeLabel = (id: number) => {
        setLabelId(id);
    };

    const handleChangeValue = (id: number) => {
        setValueId(id);
    };

    const handleAddLabelValue = () => {
        if (!valueId || !labelId || adSourceLabels?.find(({ id }) => id === valueId)) {
            return;
        }

        const newLabel = valueData?.find(({ id }) => id === valueId);
        let labels = [newLabel];

        if (adSourceLabels?.length) {
            labels = [...adSourceLabels, ...labels];
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(dealFormAdSourceLabels(labels));
        setLabelId(null);
        setValueId(null);
    };

    const handleRemoveLabelValue = (id: number) => {
        const newLabels = adSourceLabels?.filter((label) => label.id !== id);
        dispatch(dealFormAdSourceLabels(newLabels?.length ? newLabels : null));
    };

    useEffect(() => {
        setValueId(null);
    }, [labelId]);

    return {
        labels,
        isLoadingLabel: isFetchingLabel,
        isLoadingValue: isFetchingValue,
        labelId,
        valueId,
        labelOptions,
        valueOptions,
        handleChangeLabel,
        handleChangeValue,
        handleAddLabelValue,
        handleRemoveLabelValue,
    };
};
