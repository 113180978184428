import {
    consoleApi,
    SSAI_TYPES_TAG,
    AD_UNIT_WAIT_TIMES_TAG,
    AD_UNIT_TYPES_TAG,
    AD_UNIT_STATUS_TAG,
    AD_UNIT_PLACEMENTS_TAG,
    AD_UNITS_SUPPORTED_PROTOCOLS_TAG,
    AD_UNITS_POD_ENFORCEMENTS_TAG,
    AD_UNITS_PLAYLIST_DEFINITION_MODES_TAG,
    AD_UNITS_MULTIPLICITY_TAG,
    AD_UNITS_MIME_TAG,
    AD_UNITS_LINEARITIES_TAG,
    AD_UNITS_DELIVERY_METHODS,
    AD_UNITS_API_FRAMEWORK_TAG,
    AD_UNITS_ALLOWED_PLAYBACK_METHODS_TAG,
    AD_UNITS_AD_POD_FILL_MODES_TAG,
    AD_UNIT,
    SUPPLY_AD_UNITS_TAG,
    AD_UNITS_CREATIVE_ATTRIBUTES_TAG,
    AD_UNITS_INHERITED_PIXELS_TAG,
    CHANGELOG_TAG,
    SEAT_AD_UNITS_TAG,
    SEAT_CTV_NEXT_AD_UNITS_TAG,
} from "./console";
import { AdomainFreqCapping, AdUnit, Entity, IndustriesCategory } from "./supply";
import { toQueryString } from "./utils";
import { ChangeLogEntityType, IabCategory, Label } from "@app/core/services";

export interface AdUnitListResponse {
    page: number;
    maxResults: number;
    totalResults: number;
    adUnits: AdUnit[];
}

export type AdUnitsMime = {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    entityType: "MimeType";
};

export type AdUnitsApiFramework = {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    key: number;
    entityType: "ApiFramework";
};
export type AdUnitsSupportedProtocol = {
    creationDate: string;
    updateDate: string;
    id: number;
    sort: number;
    name: string;
    entityType: "SupportedProtocol";
};
export type AdUnitsMultiplicity = {
    creationDate: string;
    updateDate: string;
    id: number;
    sort: number;
    name: string;
    entityType: "AdUnitMultiplicity";
};

export type AdPodFillMode = {
    creationDate: string;
    updateDate: string;
    id: number;
    sort: number;
    name: string;
    entityType: "AdPodFillMode";
};

export interface CreativeAttribute {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    entityType: "CreativeAttribute";
}

export type PlaylistDefinitionMode = {
    creationDate: string;
    updateDate: string;
    id: number;
    sort: number;
    name: string;
    entityType: "PlaylistDefinitionMode";
};
export type PodEnforcement = {
    creationDate: string;
    updateDate: string;
    id: number;
    sort: number;
    name: string;
    entityType: "PodEnforcement";
};
export type Linearity = {
    creationDate: string;
    updateDate: string;
    id: number;
    key: number;
    name: string;
    entityType: "Linearity";
};

export interface AdUnitSeatPicklistParams {
    keyword?: string;
    max?: number;
    seatId: string;
    page?: number;
}

export interface GetSeatAdUnitsParams {
    seatId: number;
    page?: number;
    max?: number;
    adUnitIds?: (number | string)[];
    impressionWaitTime?: (number | string)[];
    keyword?: string;
    mimeIds?: (number | string)[];
    multiplicity?: number | string;
    placementIds?: (number | string)[];
    statusIds?: (number | string)[];
    supportedApiIds?: (number | string)[];
    supportedProtocolIds?: (number | string)[];
}

export interface ImpressionWaitTime {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    sort: number;
    entityType: "ImpressionWaitTime";
    waitTimeMin: number;
}
export interface AdUnitStatus {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    sort: number;
    entityType: "AdUnitStatus";
}
export interface AdUnitType {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    sort: number;
    entityType: "AdUnitType";
}
export interface AdUnitSsaiTypes {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    sort: number;
    entityType: "SsaiType";
}
export interface VideoPlacementType {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    sort: number;
    entityType: "VideoPlacementType";
}

interface AdUnitValidationPayloadParams {
    seatId: number;
    body: string[];
}

export interface UpdateAdUnitBrandSafetyPayload {
    id: number;
    advertiserBlockDomains: string[];
    buyerSeatList: { id: number } | null;
    seatAdvertiserDomainFilterListDefs: { id: number }[];
    adomainFreqCappingType: { id: number } | null;
    adomainFreqCappings: AdomainFreqCapping[] | null;
}

export interface AdUnitPayload {
    supply: { id: number };

    // General
    name: string;
    code: string;
    description: string;
    status: { id: number };

    // Brand Safety Section
    seatAdvertiserDomainFilterListDefs: { id: number; name?: string }[];
    advertiserBlockDomains: string[] | null;
    buyerSeatList: { id: number; name?: string } | null;
    adomainFreqCappingType: { id: number; name?: string };
    adomainFreqCappings: AdomainFreqCapping[] | null;

    // Demand Requiements Section
    blockedCreativeAttributes: { id: number; name?: string }[] | null;
    minDuration: number;
    maxDuration: number;
    maxExtended: number;
    minBitrate: number | null;
    maxBitrate: number | null;
    mimes: { id: number; name?: string }[];
    placement: { id: number; name?: string };
    supportedApis: { id: number; name?: string }[];
    supportedProtocols: { id: number; name?: string }[];

    // Supply Details Section
    linearity: { id: number; name?: string } | null;
    multiplicity: { id: number; name?: string } | null;
    type: { id: number; name?: string } | null;
    startDelay: number | null;
    allowedPlaybackMethods: { id: number; name?: string }[] | null;
    videoDeliveries: { id: number; name?: string }[] | null;
    skip: boolean | null;
    skipafter?: number | null;
    skipmin?: number | null;
    isInterstitial: boolean;
    isBoxingAllowed: boolean;
    ssaiType: { id: number; name?: string } | null;
    impressionWaitTime: { id: number; name?: string } | null;
    creativeSamplesMinute: number | null;
    unwrapVast: boolean;
    isLiveStream: boolean;
    contentChannel: string | null;
    contentNetwork: string | null;
    udeOptOut: boolean;
    overrideDynamicFloor: boolean;
    lsaEnabled: boolean;

    // Ad Pod Section
    maxPodSeconds: number | null;
    maxAdsPerPod: number | null;
    adPodFillMode: { id: number; name?: string } | null;
    podEnforcement: { id: number; name?: string } | null;
    separationGroups:
        | {
              iabCategories: IabCategory[];
              id: number;
              industries: IndustriesCategory[];
              name: string;
          }[]
        | null;

    // Playlist Configuration Section
    maxAdsPerAdvertiser?: number | null;
    playlistDefinitionMode?: { id: number; name?: string } | null;

    //Ad Break Rules Section
    adBreakRules: {
        adBreakLabel: string;
        maxAdsPerPod: number;
        maxPodLengthSeconds: number;
        midRollIndex: number | null;
    }[];
    cuepoints: string[] | null;

    // Custom Pixels
    thirdPartyPixels: { id: number; name?: string }[];

    // Labels
    labelValues: { id: number; value: string; label: Label }[];
    internalLabelValues?: { id: number; value: string; label: Label }[];

    // Waterfall Prefiltering
    prefilterLabelValues: { id: number; value: string; label: Label }[];
}

interface CreateAdUnitParams {
    supplyId: number;
    body: AdUnitPayload;
}

interface UpdateAdUnitPayload extends AdUnitPayload {
    id: number;
}

export const adUnitsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getAdUnit: builder.query<AdUnit, number>({
            query: (id: number) => `adUnits/${id}`,
            providesTags: (_, __, id) => [{ type: AD_UNIT, id }],
        }),
        getAdUnitByCode: builder.query<AdUnit, string>({
            query: (adUnitCode: string) => `adUnits/adCodes/${adUnitCode}`,
            providesTags: (adUnit, err) => (err || !adUnit ? [] : [{ type: AD_UNIT, id: adUnit.id }]),
        }),
        createAdUnit: builder.mutation<AdUnit, CreateAdUnitParams>({
            query: ({ body, supplyId }: CreateAdUnitParams) => {
                return {
                    url: `/supply/${supplyId}/adUnits`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (_, err, { supplyId }) => (err ? [] : [{ type: SUPPLY_AD_UNITS_TAG, id: supplyId }]),
        }),
        updateAdUnit: builder.mutation<AdUnit, UpdateAdUnitPayload>({
            query: (body: UpdateAdUnitPayload) => {
                return {
                    url: `/adUnits/${body.id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: (_, err, { id, supply }) =>
                err
                    ? []
                    : [
                          { type: SUPPLY_AD_UNITS_TAG, id: supply.id },
                          { type: AD_UNIT, id },
                          { type: AD_UNITS_INHERITED_PIXELS_TAG, id },
                          { type: CHANGELOG_TAG, id: `${ChangeLogEntityType.AdUnit}-${id}` },
                      ],
        }),
        deleteAdUnit: builder.mutation<unknown, number>({
            query: (id: number) => ({
                url: `adUnits/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err) => (err ? [] : [SUPPLY_AD_UNITS_TAG]),
        }),

        getAdUnitWaitTimes: builder.query<ImpressionWaitTime[], void>({
            query: () => `adUnits/waitTimes`,
            providesTags: [AD_UNIT_WAIT_TIMES_TAG],
        }),
        getAdUnitTypes: builder.query<AdUnitType[], void>({
            query: () => `adUnits/types`,
            providesTags: [AD_UNIT_TYPES_TAG],
        }),
        getSsaiTypes: builder.query<AdUnitSsaiTypes[], void>({
            query: () => "ssaiTypes",
            providesTags: [SSAI_TYPES_TAG],
        }),
        getAdUniPlacements: builder.query<VideoPlacementType[], void>({
            query: () => `adUnits/placements`,
            providesTags: [AD_UNIT_PLACEMENTS_TAG],
        }),
        getAdUnitStatuses: builder.query<AdUnitStatus[], void>({
            query: () => `adUnits/statuses`,
            providesTags: [AD_UNIT_STATUS_TAG],
        }),
        getAdUnitPicklist: builder.query<AdUnit[], AdUnitSeatPicklistParams>({
            query: (params: AdUnitSeatPicklistParams) => {
                const { seatId, ...rest } = params;
                return `seats/${seatId}/adUnits/picklist;${toQueryString(rest)}`;
            },
        }),
        getSeatAdUnits: builder.query<AdUnit[], GetSeatAdUnitsParams>({
            query: (params: GetSeatAdUnitsParams) => {
                const { seatId, ...rest } = params;
                return `seats/${seatId}/adUnits;${toQueryString(rest)}`;
            },
            providesTags: [SEAT_AD_UNITS_TAG],
        }),
        getSeatCtvNextAdUnits: builder.query<AdUnitListResponse, GetSeatAdUnitsParams>({
            query: (params: GetSeatAdUnitsParams) => {
                const { seatId, ...rest } = params;
                return `seats/ctvNext/${seatId}/adUnits;${toQueryString(rest)}`;
            },
            providesTags: [SEAT_CTV_NEXT_AD_UNITS_TAG],
        }),
        getAdUnitPlaybackMethods: builder.query<Entity[], void>({
            query: () => "adUnits/playbackMethods",
            providesTags: [AD_UNITS_ALLOWED_PLAYBACK_METHODS_TAG],
        }),
        getAdUnitDeliveryMethods: builder.query<Entity[], void>({
            query: () => "adUnits/deliveries",
            providesTags: [AD_UNITS_DELIVERY_METHODS],
        }),
        getAdUnitsMimes: builder.query<AdUnitsMime[], void>({
            query: () => "adUnits/mimes",
            providesTags: [AD_UNITS_MIME_TAG],
        }),
        getAdUnitsApiFrameworks: builder.query<AdUnitsApiFramework[], void>({
            query: () => "adUnits/apiFrameworks",
            providesTags: [AD_UNITS_API_FRAMEWORK_TAG],
        }),
        getAdUnitsSupportedProtocols: builder.query<AdUnitsSupportedProtocol[], void>({
            query: () => "adUnits/supportedProtocols",
            providesTags: [AD_UNITS_SUPPORTED_PROTOCOLS_TAG],
        }),
        getAdUnitsMultiplicity: builder.query<AdUnitsMultiplicity[], void>({
            query: () => "adUnits/multiplicity",
            providesTags: [AD_UNITS_MULTIPLICITY_TAG],
        }),
        getAdUnitsAdPodFillModes: builder.query<AdPodFillMode[], void>({
            query: () => "adUnits/adPodFillModes",
            providesTags: [AD_UNITS_AD_POD_FILL_MODES_TAG],
        }),
        getAdUnitsPlaylistDefinitionModes: builder.query<PlaylistDefinitionMode[], void>({
            query: () => "adUnits/modes",
            providesTags: [AD_UNITS_PLAYLIST_DEFINITION_MODES_TAG],
        }),
        getAdUnitsPodEnforcements: builder.query<PodEnforcement[], void>({
            query: () => "adUnits/podEnforcements",
            providesTags: [AD_UNITS_POD_ENFORCEMENTS_TAG],
        }),
        getAdUnitsLinearities: builder.query<Linearity[], void>({
            query: () => "adUnits/linearities",
            providesTags: [AD_UNITS_LINEARITIES_TAG],
        }),
        getAdUnitsCreativeAttributes: builder.query<CreativeAttribute[], void>({
            query: () => "adUnits/creativeAttributes",
            providesTags: [AD_UNITS_CREATIVE_ATTRIBUTES_TAG],
        }),
        validateAdUnits: builder.mutation<AdUnit[], AdUnitValidationPayloadParams>({
            query: ({ seatId, body }: AdUnitValidationPayloadParams) => {
                return {
                    url: `validations/access;seatId=${seatId};type=adUnit`,
                    method: "POST",
                    body,
                };
            },
        }),
        updateAdUnitBrandSafety: builder.mutation<AdUnit, UpdateAdUnitBrandSafetyPayload>({
            query: (payload: UpdateAdUnitBrandSafetyPayload) => ({
                url: `adUnits/${payload.id}`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: (_, err, payload) => (err ? [] : [{ type: AD_UNIT, id: payload.id }]),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetAdUniPlacementsQuery,
    useGetAdUnitDeliveryMethodsQuery,
    useGetAdUnitPicklistQuery,
    useGetAdUnitPlaybackMethodsQuery,
    useGetAdUnitQuery,
    useGetAdUnitByCodeQuery,
    useGetAdUnitStatusesQuery,
    useGetAdUnitTypesQuery,
    useGetAdUnitWaitTimesQuery,
    useCreateAdUnitMutation,
    useUpdateAdUnitMutation,
    useDeleteAdUnitMutation,
    useGetAdUnitsMimesQuery,
    useGetAdUnitsAdPodFillModesQuery,
    useGetAdUnitsApiFrameworksQuery,
    useGetAdUnitsCreativeAttributesQuery,
    useGetAdUnitsLinearitiesQuery,
    useGetAdUnitsMultiplicityQuery,
    useGetAdUnitsPlaylistDefinitionModesQuery,
    useGetAdUnitsPodEnforcementsQuery,
    useGetAdUnitsSupportedProtocolsQuery,
    useGetSeatAdUnitsQuery,
    useLazyGetSeatCtvNextAdUnitsQuery,
    useGetSsaiTypesQuery,
    useUpdateAdUnitBrandSafetyMutation,
    useValidateAdUnitsMutation,
} = adUnitsApi;
