import { LabeledValue } from "antd/lib/select";

export const HISTORICAL_CHART_DATE_RANGE_OPTIONS: LabeledValue[] = [
    { key: "LAST_3_DAYS", label: "Last 3 Days", value: "lastThreeDays" },
    { key: "LAST_7_DAYS", label: "Last 7 Days", value: "lastSevenDays" },
    { key: "LAST_30_DAYS", label: "Last 30 Days", value: "lastThirtyDays" },
];

export const HISTORICAL_CHART_GROUP_BY_OPTIONS = [
    { label: "Day", value: "day" },
    { label: "Hour", value: "hour" },
];

export const ELIGIBLE_BIDS_LABEL = "Eligible Bids";
export const ELIGIBLE_BID_RATE_LABEL = "Eligible Bid Rate";
export const SKIPS_LABEL = "Skips";
export const FILLS_LABEL = "Fills";
export const IMPRESSIONS_LABEL = "Impressions";
export const NET_REVENUE_LABEL = "Net Revenue";
export const TRIES_LABEL = "Tries";
export const USE_RATE_LABEL = "Use Rate";
export const VALID_BIDS_LABEL = "Valid Bids";
export const VALID_BID_RATE_LABEL = "Valid Bid Rate";
export const WINS_LABEL = "Wins";
export const PERFORMANCE_REQUESTS_LABEL = "Requests";
export const PERFORMANCE_AD_POD_REQUESTS_LABEL = "Ad-Pod Requests";
export const PERFORMANCE_PLAYLIST_REQUESTS_LABEL = "Playlist Requests";
export const PERFORMANCE_POD_SLOT_REQUESTS_LABEL = "Pod-Slot Requests";
export const PERFORMANCE_LSA_POD_REQUESTS_LABEL = "LSA Pod Requests";
export const PERFORMANCE_LSA_SLOT_REQUESTS_LABEL = "LSA Slot Requests";
export const PERFORMANCE_LSA_POTENTIAL_FILLS_LABEL = "LSA Potential Fills";
export const PERFORMANCE_LSA_FILLS_LABEL = "LSA Fills";
export const PERFORMANCE_LSA_IMPS_LABEL = "LSA Imps";
export const PERFORMANCE_FILLS_LABEL = "Fills";
export const PERFORMANCE_IMPRESSIONS_LABEL = "Impressions";
export const PERFORMANCE_NET_REVENUE_LABEL = "Net Revenue";
export const PERFORMANCE_FALL_THROUGHS_LABEL = "Fall Throughs";
export const PERFORMANCE_ERRORS_LABEL = "Errors";
export const PERFORMANCE_FALL_BACKS_LABEL = "Fall Backs";
export const PERFORMANCE_SEAT_REJECTIONS_LABEL = "Seat Rejections";
export const PERFORMANCE_USER_REJECTIONS_LABEL = "User Rejections";
export const PERFORMANCE_BLOCKED_LABEL = "Blocked";
export const PERFORMANCE_MOKAS_LABEL = "MOKA";
export const PENDING_100_LABEL = "100";
export const SUCCESS_200_LABEL = "200";
export const SUCCESS_202_LABEL = "202";
export const ERROR_300_LABEL = "300";
export const ERROR_301_LABEL = "301";
export const ERROR_303_LABEL = "303";
export const ERROR_400_LABEL = "400";
export const ERROR_402_LABEL = "402";
export const ERROR_405_LABEL = "405";
export const ERROR_407_LABEL = "407";
export const ERROR_900_LABEL = "900";
export const ERROR_901_LABEL = "901";
export const ERROR_1005_LABEL = "1005";
export const ERROR_1010_LABEL = "1010";
export const ESTIMATED_WIN_RATE_LABEL = "Est. Win Rate";
export const ESTIMATED_REVENUE_LABEL = "Est. Revenue";

const FILLS_LABEL_COLOR = "#B2DC39";
const IMPRESSIONS_LABEL_COLOR = "#FFC107";
const NET_REVENUE_LABEL_COLOR = "#9351E6";
const PERFORMANCE_REQUESTS_LABEL_COLOR = "#60CDFE";
const PERFORMANCE_AD_POD_REQUESTS_LABEL_COLOR = "#3947CA";
const PERFORMANCE_PLAYLIST_REQUESTS_LABEL_COLOR = "#198BE5";
const PERFORMANCE_POD_SLOT_REQUESTS_LABEL_COLOR = "#91B6FE";
const PERFORMANCE_LSA_POD_REQUESTS_LABEL_COLOR = "#5DE5E5";
const PERFORMANCE_LSA_SLOT_REQUESTS_LABEL_COLOR = "#0BB4B4";
const PERFORMANCE_LSA_POTENTIAL_FILLS_LABEL_COLOR = "#78C34A";
const PERFORMANCE_LSA_FILLS_LABEL_COLOR = "#38973C";
const PERFORMANCE_LSA_IMPS_LABEL_COLOR = "#FF9800";
const PERFORMANCE_FALL_THROUGHS_LABEL_COLOR = "#E4977B";
const PERFORMANCE_ERRORS_LABEL_COLOR = "#FFC6B4";
const PERFORMANCE_FALL_BACKS_LABEL_COLOR = "#B5755E";
const PERFORMANCE_SEAT_REJECTIONS_LABEL_COLOR = "#FF6C6C";
const PERFORMANCE_USER_REJECTIONS_LABEL_COLOR = "#F02929";
const PERFORMANCE_BLOCKED_LABEL_COLOR = "#A30A0A";
const PERFORMANCE_MOKAS_LABEL_COLOR = "#795548";
const ELIGIBLE_BIDS_LABEL_COLOR = "#7666F9";
const ELIGIBLE_BID_RATE_LABEL_COLOR = "#2F9999";
const TRIES_LABEL_COLOR = "#5b8ff9";
const USE_RATE_LABEL_COLOR = "#9A67BD";
const VALID_BIDS_LABEL_COLOR = "#5ad8a6";
const VALID_BID_RATE_LABEL_COLOR = "#FF9D4F";
const WINS_LABEL_COLOR = "#eb2f96";
const PENDING_100_LABEL_COLOR = "#6395F9";
const SUCCESS_200_LABEL_COLOR = "#63DAAB";
const SUCCESS_202_LABEL_COLOR = "#389E0D";
const ERROR_300_LABEL_COLOR = "#657899";
const ERROR_301_LABEL_COLOR = "#7666F9";
const ERROR_303_LABEL_COLOR = "#2F9999";
const ERROR_400_LABEL_COLOR = "#5ad8a6";
const ERROR_402_LABEL_COLOR = "#5d7092";
const ERROR_405_LABEL_COLOR = "#FF9FC6";
const ERROR_407_LABEL_COLOR = "#C41D7F";
const ERROR_900_LABEL_COLOR = "#f6bd16";
const ERROR_901_LABEL_COLOR = "#AD6800";
const ERROR_1005_LABEL_COLOR = "#531DAB";
const ERROR_1010_LABEL_COLOR = "#5b8ff9";
const ESTIMATED_WIN_RATE_LABEL_COLOR = "#1890FF";
const ESTIMATED_REVENUE_LABEL_COLOR = "#83D7AE";

export const DEFAULT_COLOR = "#5b8ff9";

export const LABEL_COLORS = {
    [ELIGIBLE_BIDS_LABEL]: ELIGIBLE_BIDS_LABEL_COLOR,
    [ELIGIBLE_BID_RATE_LABEL]: ELIGIBLE_BID_RATE_LABEL_COLOR,
    [FILLS_LABEL]: FILLS_LABEL_COLOR,
    [IMPRESSIONS_LABEL]: IMPRESSIONS_LABEL_COLOR,
    [NET_REVENUE_LABEL]: NET_REVENUE_LABEL_COLOR,
    [TRIES_LABEL]: TRIES_LABEL_COLOR,
    [USE_RATE_LABEL]: USE_RATE_LABEL_COLOR,
    [VALID_BIDS_LABEL]: VALID_BIDS_LABEL_COLOR,
    [VALID_BID_RATE_LABEL]: VALID_BID_RATE_LABEL_COLOR,
    [WINS_LABEL]: WINS_LABEL_COLOR,
    [PERFORMANCE_REQUESTS_LABEL]: PERFORMANCE_REQUESTS_LABEL_COLOR,
    [PERFORMANCE_AD_POD_REQUESTS_LABEL]: PERFORMANCE_AD_POD_REQUESTS_LABEL_COLOR,
    [PERFORMANCE_PLAYLIST_REQUESTS_LABEL]: PERFORMANCE_PLAYLIST_REQUESTS_LABEL_COLOR,
    [PERFORMANCE_POD_SLOT_REQUESTS_LABEL]: PERFORMANCE_POD_SLOT_REQUESTS_LABEL_COLOR,
    [PERFORMANCE_LSA_POD_REQUESTS_LABEL]: PERFORMANCE_LSA_POD_REQUESTS_LABEL_COLOR,
    [PERFORMANCE_LSA_SLOT_REQUESTS_LABEL]: PERFORMANCE_LSA_SLOT_REQUESTS_LABEL_COLOR,
    [PERFORMANCE_LSA_POTENTIAL_FILLS_LABEL]: PERFORMANCE_LSA_POTENTIAL_FILLS_LABEL_COLOR,
    [PERFORMANCE_LSA_FILLS_LABEL]: PERFORMANCE_LSA_FILLS_LABEL_COLOR,
    [PERFORMANCE_LSA_IMPS_LABEL]: PERFORMANCE_LSA_IMPS_LABEL_COLOR,
    [PERFORMANCE_FALL_THROUGHS_LABEL]: PERFORMANCE_FALL_THROUGHS_LABEL_COLOR,
    [PERFORMANCE_ERRORS_LABEL]: PERFORMANCE_ERRORS_LABEL_COLOR,
    [PERFORMANCE_FALL_BACKS_LABEL]: PERFORMANCE_FALL_BACKS_LABEL_COLOR,
    [PERFORMANCE_SEAT_REJECTIONS_LABEL]: PERFORMANCE_SEAT_REJECTIONS_LABEL_COLOR,
    [PERFORMANCE_USER_REJECTIONS_LABEL]: PERFORMANCE_USER_REJECTIONS_LABEL_COLOR,
    [PERFORMANCE_BLOCKED_LABEL]: PERFORMANCE_BLOCKED_LABEL_COLOR,
    [PERFORMANCE_MOKAS_LABEL]: PERFORMANCE_MOKAS_LABEL_COLOR,
    [PENDING_100_LABEL]: PENDING_100_LABEL_COLOR,
    [SUCCESS_200_LABEL]: SUCCESS_200_LABEL_COLOR,
    [SUCCESS_202_LABEL]: SUCCESS_202_LABEL_COLOR,
    [ERROR_300_LABEL]: ERROR_300_LABEL_COLOR,
    [ERROR_301_LABEL]: ERROR_301_LABEL_COLOR,
    [ERROR_303_LABEL]: ERROR_303_LABEL_COLOR,
    [ERROR_400_LABEL]: ERROR_400_LABEL_COLOR,
    [ERROR_402_LABEL]: ERROR_402_LABEL_COLOR,
    [ERROR_405_LABEL]: ERROR_405_LABEL_COLOR,
    [ERROR_407_LABEL]: ERROR_407_LABEL_COLOR,
    [ERROR_900_LABEL]: ERROR_900_LABEL_COLOR,
    [ERROR_901_LABEL]: ERROR_901_LABEL_COLOR,
    [ERROR_1005_LABEL]: ERROR_1005_LABEL_COLOR,
    [ERROR_1010_LABEL]: ERROR_1010_LABEL_COLOR,
    [ESTIMATED_WIN_RATE_LABEL]: ESTIMATED_WIN_RATE_LABEL_COLOR,
    [ESTIMATED_REVENUE_LABEL]: ESTIMATED_REVENUE_LABEL_COLOR,
};

export const DEFAULT_LEGEND_DATA_POINTS = [
    {
        label: TRIES_LABEL,
        color: TRIES_LABEL_COLOR,
        checked: true,
    },
    {
        label: FILLS_LABEL,
        color: FILLS_LABEL_COLOR,
        checked: true,
    },
    {
        label: IMPRESSIONS_LABEL,
        color: IMPRESSIONS_LABEL_COLOR,
        checked: true,
    },
    {
        label: NET_REVENUE_LABEL,
        color: NET_REVENUE_LABEL_COLOR,
        checked: true,
    },
    {
        label: VALID_BIDS_LABEL,
        color: VALID_BIDS_LABEL_COLOR,
        checked: true,
    },
    {
        label: ELIGIBLE_BIDS_LABEL,
        color: ELIGIBLE_BIDS_LABEL_COLOR,
        checked: true,
    },
    {
        label: USE_RATE_LABEL,
        color: USE_RATE_LABEL_COLOR,
        checked: true,
    },
    {
        label: VALID_BID_RATE_LABEL,
        color: VALID_BID_RATE_LABEL_COLOR,
        checked: true,
    },
    {
        label: ELIGIBLE_BID_RATE_LABEL,
        color: ELIGIBLE_BID_RATE_LABEL_COLOR,
        checked: true,
    },
    { label: PERFORMANCE_REQUESTS_LABEL, color: PERFORMANCE_REQUESTS_LABEL_COLOR, checked: true },
    { label: PERFORMANCE_AD_POD_REQUESTS_LABEL, color: PERFORMANCE_AD_POD_REQUESTS_LABEL_COLOR, checked: true },
    { label: PERFORMANCE_PLAYLIST_REQUESTS_LABEL, color: PERFORMANCE_PLAYLIST_REQUESTS_LABEL_COLOR, checked: true },
    { label: PERFORMANCE_POD_SLOT_REQUESTS_LABEL, color: PERFORMANCE_POD_SLOT_REQUESTS_LABEL_COLOR, checked: true },
    { label: PERFORMANCE_LSA_POD_REQUESTS_LABEL, color: PERFORMANCE_LSA_POD_REQUESTS_LABEL_COLOR, checked: true },
    { label: PERFORMANCE_LSA_SLOT_REQUESTS_LABEL, color: PERFORMANCE_LSA_SLOT_REQUESTS_LABEL_COLOR, checked: true },
    { label: PERFORMANCE_LSA_POTENTIAL_FILLS_LABEL, color: PERFORMANCE_LSA_POTENTIAL_FILLS_LABEL_COLOR, checked: true },
    { label: PERFORMANCE_LSA_FILLS_LABEL, color: PERFORMANCE_LSA_FILLS_LABEL_COLOR, checked: true },
    { label: PERFORMANCE_LSA_IMPS_LABEL, color: PERFORMANCE_LSA_IMPS_LABEL_COLOR, checked: true },
    { label: PERFORMANCE_FALL_THROUGHS_LABEL, color: PERFORMANCE_FALL_THROUGHS_LABEL_COLOR, checked: true },
    { label: PERFORMANCE_ERRORS_LABEL, color: PERFORMANCE_ERRORS_LABEL_COLOR, checked: true },
    { label: PERFORMANCE_FALL_BACKS_LABEL, color: PERFORMANCE_FALL_BACKS_LABEL_COLOR, checked: true },
    { label: PERFORMANCE_SEAT_REJECTIONS_LABEL, color: PERFORMANCE_SEAT_REJECTIONS_LABEL_COLOR, checked: true },
    { label: PERFORMANCE_USER_REJECTIONS_LABEL, color: PERFORMANCE_USER_REJECTIONS_LABEL_COLOR, checked: true },
    { label: PERFORMANCE_BLOCKED_LABEL, color: PERFORMANCE_BLOCKED_LABEL_COLOR, checked: true },
    { label: PERFORMANCE_MOKAS_LABEL, color: PERFORMANCE_MOKAS_LABEL_COLOR, checked: true },
    { label: PENDING_100_LABEL, color: PENDING_100_LABEL_COLOR, checked: true },
    { label: SUCCESS_200_LABEL, color: SUCCESS_200_LABEL_COLOR, checked: true },
    { label: SUCCESS_202_LABEL, color: SUCCESS_202_LABEL_COLOR, checked: true },
    { label: ERROR_300_LABEL, color: ERROR_300_LABEL_COLOR, checked: true },
    { label: ERROR_301_LABEL, color: ERROR_301_LABEL_COLOR, checked: true },
    { label: ERROR_303_LABEL, color: ERROR_303_LABEL_COLOR, checked: true },
    { label: ERROR_400_LABEL, color: ERROR_400_LABEL_COLOR, checked: true },
    { label: ERROR_402_LABEL, color: ERROR_402_LABEL_COLOR, checked: true },
    { label: ERROR_405_LABEL, color: ERROR_405_LABEL_COLOR, checked: true },
    { label: ERROR_407_LABEL, color: ERROR_407_LABEL_COLOR, checked: true },
    { label: ERROR_900_LABEL, color: ERROR_900_LABEL_COLOR, checked: true },
    { label: ERROR_901_LABEL, color: ERROR_901_LABEL_COLOR, checked: true },
    { label: ERROR_1005_LABEL, color: ERROR_1005_LABEL_COLOR, checked: true },
    { label: ERROR_1010_LABEL, color: ERROR_1010_LABEL_COLOR, checked: true },
    { label: ESTIMATED_WIN_RATE_LABEL, color: ESTIMATED_WIN_RATE_LABEL_COLOR, checked: true },
    { label: ESTIMATED_REVENUE_LABEL, color: ESTIMATED_REVENUE_LABEL_COLOR, checked: true },
];
