import { useUserAccess } from "@app/core/auth";

interface UseGeneralSection {
    isInternalOnly: boolean;
    hasSeatWriteAccess: boolean;
}

export const useGeneralSection = (): UseGeneralSection => {
    const { isPubAcctMgr, isSysAdmin, hasSeatWriteAccess } = useUserAccess();
    const isInternalOnly: boolean = isPubAcctMgr || isSysAdmin;
    return { isInternalOnly, hasSeatWriteAccess };
};
