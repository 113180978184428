import { FC } from "react";
import { NavMenu } from "@rubicon/antd-components";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { INVENTORY_LIST_PAGES } from "./constants";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ItemType } from "antd/lib/menu/interface";

const menuItems: ItemType[] = [
    {
        label: "Inventory Health",
        key: INVENTORY_LIST_PAGES.HEALTH,
    },
    {
        label: "Seat",
        key: INVENTORY_LIST_PAGES.SEAT,
    },
    {
        label: "Floors",
        key: INVENTORY_LIST_PAGES.FLOORS,
    },
    {
        label: "Brand Safety",
        key: INVENTORY_LIST_PAGES.BRAND_SAFETY,
    },
    {
        label: "Permissions",
        key: INVENTORY_LIST_PAGES.PERMISSIONS,
    },
];

// TODO: Placeholder for demo. Replace with something more robust later
const getSelectedKey = (pathNames: string[]) => {
    if (pathNames.includes(INVENTORY_LIST_PAGES.HEALTH)) {
        return INVENTORY_LIST_PAGES.HEALTH;
    }

    if (pathNames.includes(INVENTORY_LIST_PAGES.SEAT)) {
        return INVENTORY_LIST_PAGES.SEAT;
    }

    if (pathNames.includes(INVENTORY_LIST_PAGES.FLOORS)) {
        return INVENTORY_LIST_PAGES.FLOORS;
    }

    if (pathNames.includes(INVENTORY_LIST_PAGES.BRAND_SAFETY)) {
        return INVENTORY_LIST_PAGES.BRAND_SAFETY;
    }

    if (pathNames.includes(INVENTORY_LIST_PAGES.PERMISSIONS)) {
        return INVENTORY_LIST_PAGES.PERMISSIONS;
    }

    return INVENTORY_LIST_PAGES.HEALTH;
};

export const InventoryNavMenu: FC = () => {
    const { pathname } = useLocation();
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();

    const handleClick = ({ key }) => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY(Number(seatId), key));
    };

    return <NavMenu selectedKey={getSelectedKey(pathname.split("/"))} onClick={handleClick} items={menuItems} />;
};
