import { FC, useEffect } from "react";
import { Col, Row, Form } from "antd";
import { HistoricalChart } from "@app/core/components/charts/HistoricalChart/HistoricalChart";
import { DealListSource } from "@app/core/services";
import { HistoricalChartAdSourceFilter } from "@app/features/deals/DealChartDrawer/DealCharts/DealsHistoricalChart/HistoricalChartAdSourceFilter/HistoricalChartAdSourceFilter";
import { HistoricalChartDateRangeFilter } from "@app/core/components/charts/HistoricalChart/HistoricalChartDateRangeFilter/HistoricalChartDateRangeFilter";
import { HistoricalChartGroupBy } from "@app/core/components/charts/HistoricalChart/HistoricalChartGroupBy/HistoricalChartGroupBy";
import { useAppDispatch } from "@app/core/store";
import {
    resetHistoricalChartState,
    setHistoricalChartAdSource,
    setHistoricalChartDealId,
} from "@app/core/components/charts/HistoricalChart/reducer";
import { HistoricalChartExportButton } from "@app/core/components/charts/HistoricalChart/HistoricalChartExportButton/HistoricalChartExportButton";

interface DealsHistoricalChartProps {
    adSources?: DealListSource[];
    dealId: number;
}

export const DealsHistoricalChart: FC<DealsHistoricalChartProps> = ({ adSources, dealId }) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (adSources && adSources?.length > 0) {
            dispatch(setHistoricalChartAdSource(adSources[0]?.id));
            dispatch(setHistoricalChartDealId(dealId));
        }

        return () => {
            dispatch(resetHistoricalChartState());
        };
    });

    return (
        <>
            <Form layout="vertical">
                <Row justify="space-between" align="bottom" style={{ paddingBottom: "26px" }}>
                    <Col xs={24} sm={24} md={24} lg={10}>
                        <HistoricalChartAdSourceFilter adSources={adSources} />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6}>
                        <HistoricalChartDateRangeFilter />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4}>
                        <HistoricalChartGroupBy />
                    </Col>
                    <Col>
                        <HistoricalChartExportButton />
                    </Col>
                </Row>
            </Form>
            <HistoricalChart />
        </>
    );
};
