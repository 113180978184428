import { consoleApi, PBS_SERVERS_TAG } from "./console";

export type PbsServer = {
    creationDate: string;
    id: number;
    name: string;
    updateDate: string;
    url: string;
};

export const pbsServersApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getPbsServers: builder.query<PbsServer[], void>({
            query: () => "pbsServers",
            providesTags: [PBS_SERVERS_TAG],
        }),
    }),
    overrideExisting: false,
});

export const { useGetPbsServersQuery } = pbsServersApi;
