import { BulkOperationDefinition, BulkOperationEntityType, BulkOperationType } from "@app/core/services";
import {
    AD_SOURCE_FIELDS_TO_EDIT_OPTIONS,
    AD_UNIT_FIELDS_TO_EDIT_OPTIONS,
    BUYER_DEMAND_DEAL_FIELDS_TO_EDIT_OPTIONS,
    FormItemCommonConfig,
    RESTRICTED_AD_SOURCE_FIELDS,
} from "./constants";
import { Button, Form, TableColumnsType, Tooltip, Typography } from "antd";
import { NONE } from "@app/core/components/constants";
import { RollbackOutlined } from "@ant-design/icons";
import { TouchedFieldInfo } from "./reducer";
import { isMoment } from "moment-timezone";
import { NOT_SPECIFIED_OPTION } from "@app/features/inventory/HierarchyForms/constants";

export const getEditableFieldsOptions = (
    entityType: BulkOperationEntityType | undefined,
    showRestrictedFields: boolean
) => {
    switch (entityType) {
        case BulkOperationEntityType.AdSource:
            return AD_SOURCE_FIELDS_TO_EDIT_OPTIONS.filter(({ value }) => {
                if (RESTRICTED_AD_SOURCE_FIELDS.includes(value)) {
                    return showRestrictedFields;
                }
                return true;
            });
        case BulkOperationEntityType.AdUnit:
            return AD_UNIT_FIELDS_TO_EDIT_OPTIONS;
        case BulkOperationEntityType.BuyerDeal:
        case BulkOperationEntityType.DemandDeal:
            return BUYER_DEMAND_DEAL_FIELDS_TO_EDIT_OPTIONS;
        default:
            return [];
    }
};

export const getSharedHiddenStringValue = (value?: boolean) => (value ? "shared" : "hidden");
export const getYesNoStringValue = (value: boolean | null) =>
    value ? "yes" : value === false ? "no" : NOT_SPECIFIED_OPTION.value;
export const getYesNoApiValue = (value?: string) => (value === "Yes" ? true : value === "No" ? false : null);
export const getSharedHiddenOptionByValue = (value?: boolean) =>
    value ? { label: "Shared", value: "shared" } : { label: "Hidden", value: "hidden" };

export const getColumnsConfig = <T extends { id: number }>(
    seatId: number,
    editableFields: string[],
    formItemConfig: FormItemCommonConfig<T>[],
    readonlyColumns: TableColumnsType,
    formFieldName: "adSourceUpdates" | "adUnitUpdates" | "buyerDealUpdates" | "demandDealUpdates",
    handleValueChange: (record, key, title, newValue) => void,
    touchedRecordsInfo: Record<number, TouchedFieldInfo[] | undefined>,
    handleRevert: (record: T) => void
) => {
    const editableColumns: TableColumnsType = formItemConfig
        .filter(({ key }) => editableFields.includes(key))
        .map(
            ({
                key,
                title,
                getIsDisabled,
                getQueryArgs,
                formProps,
                props,
                component: Component,
                ...restColumnProps
            }) => ({
                ...restColumnProps,
                title,
                render: (_, record: T) => (
                    <Form.Item name={[formFieldName, String(record.id), key]} {...formProps}>
                        <Component
                            // @ts-expect-error args is not prop for Antd component, but is for customized component
                            args={getQueryArgs?.(seatId) || []}
                            disabled={getIsDisabled?.(record) || false}
                            onChange={(v) => handleValueChange(record, key, title, v)}
                            {...props}
                        />
                    </Form.Item>
                ),
            })
        );

    const revertColumn = {
        key: "revert",
        dataIndex: "revert",
        title: "Revert",
        fixed: "right" as const,
        width: "50px",
        render: (_, record) => (
            <Tooltip title="Revert Change">
                <Button
                    data-sdet="revert-button"
                    disabled={!touchedRecordsInfo[record.id]?.some(({ isChanged }) => isChanged)}
                    onClick={() => handleRevert(record)}
                    icon={<RollbackOutlined />}
                />
            </Tooltip>
        ),
    };

    editableColumns.splice(1, 0, ...readonlyColumns);
    editableColumns.push(revertColumn);

    return editableColumns;
};

export const getEntitiesIds = ({
    type,
    entityType,
    adSourceUpdates,
    adUnitUpdates,
    buyerDealUpdates,
    sourceEntityId,
}: BulkOperationDefinition) => {
    if (type === BulkOperationType.Edit) {
        const updates =
            entityType === BulkOperationEntityType.AdSource
                ? adSourceUpdates
                : entityType === BulkOperationEntityType.AdUnit
                ? adUnitUpdates
                : buyerDealUpdates;

        return updates?.map(({ id }) => id).join(", ");
    } else {
        return sourceEntityId;
    }
};

export const getFailedEntities = (error: null | string) => {
    if (!error) {
        return NONE;
    }
    if (error.startsWith("Failed to update")) {
        // get sub string between [ ]
        return error.split(/\[|\]/)[1];
    }
    return error;
};

export const getExecutionErrorsMessage = (error: null | string) => {
    if (!error) {
        return NONE;
    }

    if (error.startsWith("Failed to update")) {
        const errorMessages = error.split(/<|>/).filter((str) => str !== "\n");

        return errorMessages.map((message, i) => (
            <Typography.Paragraph
                key={message}
                copyable={i === errorMessages.length - 1 ? { text: errorMessages.join("\n") } : false}
            >
                {message}
            </Typography.Paragraph>
        ));
    }

    return <Typography.Paragraph copyable>{error}</Typography.Paragraph>;
};

// value is not: undefined, null, "", [].
export const isPresent = (value: unknown) => {
    if (value === undefined || value === null) {
        return false;
    }

    if (typeof value === "string" && value === "") {
        return false;
    }

    if (Array.isArray(value) && !value.length) {
        return false;
    }

    return true;
};

// treat the following values as no change ("equal")
// a: undefined, b: null --> true
// a: null, b: "" --> true
// a: null, b: [] --> true
// a: { value: 1, label: xxx}, b: { value 1, label: xxx, someOtherKey: xxx}  --> true
export const customizerOfEqual = (a, b) => {
    if (!isPresent(a) && !isPresent(b)) {
        return true;
    }

    if (isPresent(a) && isPresent(b)) {
        if (isMoment(a) && isMoment(b)) {
            return a.format() === b.format();
        }

        if (typeof a === "object" && typeof b === "object") {
            return a.value === b.value;
        }

        return a === b;
    }

    return false;
};
