import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { useGetAdUnitTypesQuery } from "@app/core/services";

interface UseAdUnitType {
    isFetching: boolean;
    options: LabeledValue[];
}

export const useAdUnitType = (): UseAdUnitType => {
    const { data, isFetching } = useGetAdUnitTypesQuery();
    const options: LabeledValue[] = useMemo(
        () => (data || []).map(({ id, name }) => ({ value: id, label: name })),
        [data]
    );

    return {
        isFetching,
        options,
    };
};
