import { FC } from "react";
import { CreativeReview } from "@app/core/components";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/types";
import {
    SeatAdSourcesDetailsAdServing as AdServing,
    SeatAdSourcesDetailsVastErrors as VastErrors,
    SeatAdSourcesDetailsLadle as Ladle,
    SeatAdSourcesDetailsTimingStats as TimingStats,
    SeatAdSourcesDetailsPermissions as Permissions,
} from "./secondaryViews";
import { AdSource, ChangeLogEntityType, CreativeSamplesEntityTypes } from "@app/core/services";
import { css } from "@emotion/css";
import { ChangeHistoryTable } from "@app/core/components/ChangeHistoryTable";
import { SeatAdSourcesDetails } from "@app/features/seatAdSources/SeatAdSourcesDetails/SeatAdSourcesDetails";

const Wrapper: FC = ({ children, ...rest }) => (
    <div
        className={css`
            padding-left: 1rem;
            padding-right: 1rem;
        `}
        {...rest}
    >
        {children}
    </div>
);

interface Props {
    adSource: AdSource;
    secondaryDetailsView: SecondaryDetailsView;
}

export const SeatAdSourcesDetailsDrawerViewSwitch: FC<Props> = ({ adSource, secondaryDetailsView }) => {
    switch (secondaryDetailsView) {
        case SecondaryDetailsView.AdServing:
            return (
                <Wrapper>
                    <AdServing adSourceId={adSource.id} />
                </Wrapper>
            );
        case SecondaryDetailsView.ChangeHistory:
            return (
                <Wrapper data-sdet="seat-ad-sources-details-change-history">
                    <ChangeHistoryTable entityId={adSource.id} entityType={ChangeLogEntityType.AdSource} />
                </Wrapper>
            );
        case SecondaryDetailsView.CreativeReview:
            return (
                <Wrapper data-sdet="seat-ad-sources-details-creative-review">
                    <CreativeReview id={adSource.id} entityType={CreativeSamplesEntityTypes.AdSource} />
                </Wrapper>
            );
        case SecondaryDetailsView.Ladle:
            return <Ladle adSource={adSource} />;
        case SecondaryDetailsView.Permissions:
            return (
                <Wrapper>
                    <Permissions adSource={adSource} />
                </Wrapper>
            );
        case SecondaryDetailsView.TimingStats:
            return (
                <Wrapper>
                    <TimingStats adSourceId={adSource.id} />
                </Wrapper>
            );
        case SecondaryDetailsView.VastErrors:
            return (
                <Wrapper>
                    <VastErrors adSourceId={adSource.id} />
                </Wrapper>
            );
        default:
            return <SeatAdSourcesDetails adSource={adSource} />;
    }
};
