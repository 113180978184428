import { Button, Dropdown } from "antd";
import { useSeatAuthContext, useUserAccess } from "@app/core/auth";
import { MoreOutlined } from "@ant-design/icons";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ItemType } from "antd/lib/menu/interface";
import { useParams, useHistory } from "react-router-dom";
import { InventoryDetailsDrawerType } from "./DetailsDrawer/reducer";
import { useState } from "react";
import { AdsTextDrawer } from "./AdsTextDrawer";
import { downloadTags } from "./helpers";
import { openChangelog, setChangelogEntity } from "@app/features/inventory/InventoryChangelog";
import { ChangeLogEntityType } from "@app/core/services";
import { useAppDispatch } from "@app/core/store";

export const InventorySeatActions = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { context } = useSeatAuthContext();
    const { hasSeatWriteAccess } = useUserAccess();

    const { seatId } = useParams<{ seatId: string; subDrawerType?: string }>();

    const [isAdsTextDrawerVisible, setIsAdsTextDrawerVisible] = useState(false);

    const handleManageSeat = (): void => history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_SEAT_DETAILS(seatId));

    const handleAddFloor = (): void => history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_HIERARCHICAL(seatId));

    const handleTagParams = (): void =>
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_TAG_PARAMS(false, seatId, InventoryDetailsDrawerType.SEAT)
        );

    const handleCreativeReview = (): void =>
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_CREATIVE_REVIEW(
                false,
                seatId,
                InventoryDetailsDrawerType.SEAT
            )
        );

    const handleAddChannel = () =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_CHANNEL_CREATE(seatId));

    const handleAddPublisher = (): void =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_CREATE(seatId));

    const handleDiagnostics = (): void => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_DIAGNOSTICS(false, seatId, InventoryDetailsDrawerType.SEAT)
        );
    };
    const floorAnalysis = (): void =>
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_FLOOR_ANALYSIS(
                false,
                seatId,
                InventoryDetailsDrawerType.SEAT
            )
        );

    const handleChangelog = (): void => {
        if (!context) return;
        dispatch(openChangelog());
        dispatch(setChangelogEntity({ entity: context, entityType: ChangeLogEntityType.Seat }));
    };

    const handleAdsTextClick = () => {
        setIsAdsTextDrawerVisible(true);
    };

    const handleGetTags = () => {
        downloadTags(seatId);
    };

    const handleDetails = (): void => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_SEAT_DETAILS(seatId));
    };

    const items: ItemType[] = [
        { label: "Creative Review", key: "creativeReview", onClick: handleCreativeReview },
        { label: "Floor Analysis", key: "floorAnalysis", onClick: floorAnalysis },
        { label: "Change History", key: "changeHistory", onClick: handleChangelog },
        hasSeatWriteAccess && { label: "Manage Seat", key: "manageSeat", onClick: handleManageSeat },
        { label: "Ads.txt Pub ID", key: "adsTxtPubId", onClick: handleAdsTextClick },
        { label: "Get Tags", key: "getTags", onClick: handleGetTags },
        hasSeatWriteAccess && { label: "Add a Channel for Seat", key: "addChannel", onClick: handleAddChannel },
        hasSeatWriteAccess && { label: "Add a Publisher for Seat", key: "addPublisher", onClick: handleAddPublisher },
        hasSeatWriteAccess && { label: "Add a Floor for Seat", key: "addFloor", onClick: handleAddFloor },
    ].filter(Boolean) as ItemType[];

    return (
        <>
            <Button.Group>
                <Button onClick={handleDetails} type="primary">
                    Details
                </Button>
                <Button onClick={handleTagParams}>Inbound Request Params</Button>
                <Button onClick={handleDiagnostics}>Diagnostics</Button>
                <Dropdown trigger={["click"]} menu={{ items }}>
                    <Button icon={<MoreOutlined rotate={90} />} />
                </Dropdown>
                <AdsTextDrawer visible={isAdsTextDrawerVisible} handleClose={() => setIsAdsTextDrawerVisible(false)} />
            </Button.Group>
        </>
    );
};
