import { Form } from "antd";
import { useState } from "react";
import { LabeledValue } from "antd/lib/select";
import { AdSourceCountLimit } from "@app/core/services";
import { getLimitLabel } from "@app/features/inventory/helpers";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { AdSourceCountLimitOption } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm/useExchangeProtectionsForm";

const { useFormInstance, useWatch } = Form;

interface UseAdSourceTypeLimitsField {
    hidden: boolean;
    typeId: number | undefined;
    handleAddTypeLimit: () => void;
    limit: string | number | undefined;
    handleChangeLimit: (value: string | number) => void;
    adSourceCountLimitsOptions: AdSourceCountLimitOption[];
    onChange: (value: LabeledValue[], options: AdSourceCountLimitOption[]) => void;
    handleChangeType: (value: number, option: { label: string; value: number }) => void;
}

export const useAdSourceTypeLimitsField = (): UseAdSourceTypeLimitsField => {
    const form = useFormInstance();
    const [typeId, setTypeId] = useState<number | undefined>(undefined);
    const [limit, setLimit] = useState<string | number | undefined>(undefined);
    const [adSource, setAdSource] = useState<{ label: string; value: number }>({ label: "", value: 0 });
    const adSourceCountLimitsOptions = useWatch(INVENTORY_FORM_FIELDS.COUNT_LIMITS.name) ?? [];
    const hidden = !adSourceCountLimitsOptions.length;

    const handleChangeType = (value: number, option: { label: string; value: number }): void => {
        setAdSource(option);
        setTypeId(value);
    };

    const handleChangeLimit = (value: string | number): void => setLimit(value);

    const handleChange = (option: AdSourceCountLimitOption[]): void =>
        form.setFieldValue(INVENTORY_FORM_FIELDS.COUNT_LIMITS.name, option);

    const onChange = (_, options: AdSourceCountLimitOption[]): void => handleChange(options);

    const handleAddTypeLimit = (): void => {
        if (!adSource.value || !adSource.label) return;

        const newAdSourceCountLimitsOptions = [...adSourceCountLimitsOptions];

        const adSourceCountLimit: AdSourceCountLimit = {
            adSourceType: { id: adSource.value, name: adSource.label },
            maxAllowed: Number(limit) || 0,
        };
        const option: AdSourceCountLimitOption = {
            value: adSource.value,
            label: getLimitLabel(adSourceCountLimit),
            adSourceCountLimit,
        };
        const options: AdSourceCountLimitOption[] = [...newAdSourceCountLimitsOptions, option];
        handleChange(options);
        setTypeId(undefined);
        setLimit(undefined);
    };

    return {
        hidden,
        typeId,
        limit,
        onChange,
        handleChangeType,
        handleChangeLimit,
        handleAddTypeLimit,
        adSourceCountLimitsOptions,
    };
};
