export const UNDEFINED_VAST_ERROR = "Undefined error";

export const VastErrorDescriptionsByCode = {
    100: "XML Parsing error",
    101: "VAST schema validation error",
    102: "VAST version of response not supported",
    200: "Trafficking error. Video player received an ad type that it was not expecting and/or cannot display",
    201: "Video player expecting different linearity",
    202: "Video player expecting different duration",
    203: "Video player expecting different size",
    204: "Ad category was required but not provided",
    205: "Inline Category violates Wrapper BlockedAdCategories",
    206: "Ad Break shortened. Ad was not served",
    300: "General Wrapper error",
    301: "Timeout of VAST URI provided in Wrapper element, or of VAST URI provided in a subsequent Wrapper element. Includes request errors such as invalid URI, unreachable or request timeout for URI, and security or other exceptions related to requesting a VAST URI",
    302: "Wrapper limit reached, as defined by the video player. Too many Wrapper responses have been received with no InLine response",
    303: "No ads VAST response after one or more Wrappers. Also includes number of empty VAST responses from fallback",
    304: "InLine response returned ad unit that failed to result in ad display within defined time limit",
    400: "General linear error. Video player is unable to display the linear ad",
    401: "File not found. Unable to find Linear/MediaFile from URI",
    402: "Timeout of MediaFile URI",
    403: "Could not find MediaFile that is supported by this video player, based on the attributes of the MediaFile element",
    405: "Problem displaying MediaFile",
    406: "Mezzanine was required but not provided. Ad not served",
    407: "Mezzanine is in the process of being downloaded for the first time. Download may take several hours. Ad will not be served until mezzanine is downloaded and transcoded",
    408: "Conditional ad rejected",
    409: "Interactive unit in the InteractiveCreativeFile node was not executed",
    410: "Verification unit in the Verification node was not executed",
    411: "Mezzanine was provided as required, but file did not meet required specification. Ad not served",
    500: "General NonLinearAds error",
    501: "Unable to display NonLinear Ad because creative dimensions do not align with creative display area (i.e., creative dimension too large)",
    502: "Unable to fetch NonLinearAds/NonLinear resource",
    503: "Could not find NonLinear resource with supported type",
    600: "General CompanionAds error",
    601: "Unable to display companion because creative dimensions do not fit within Companion display area (i.e., no available space)",
    602: "Unable to display Required Companion",
    603: "Unable to fetch CompanionAds/Companion resource",
    604: "Could not find Companion resource with supported type",
    900: UNDEFINED_VAST_ERROR,
    901: "General VPAID error",
};
