import { FC } from "react";
import { useDealOriginFilter } from "./useDealOriginFilter";
import { FilterSelect } from "@app/core/components/FilterSelect";
import { HelpTooltip } from "@app/core/components";
import { DealsHelpKeys } from "@app/features/deals/constants";
import { useUserAccess } from "@app/core/auth";

export const DealOriginFilter: FC = () => {
    const { options, value, isLoading, handleChangeValue } = useDealOriginFilter();
    const { hasInternalAccess } = useUserAccess();

    if (!hasInternalAccess) {
        return null;
    }

    return (
        <FilterSelect
            isLoading={isLoading}
            label={
                <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealOrigin} popover={true}>
                    Deal Origin
                </HelpTooltip>
            }
            onChange={handleChangeValue}
            options={options}
            value={value}
            dataSdet="deal-origin-filter"
            placeholder="All"
        />
    );
};
