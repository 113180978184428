import { Loading } from "@app/core/components";
import { Row } from "antd";
import { DetailsDrawerUnitLayout } from "../../DetailsDrawerLayout";
import { useInventoryDetailsDrawerUrlId } from "../../useInventoryDetailsDrawerUrlId";
import { DetailsDrawerViewSwitch } from "./DetailsDrawerView/DetailsDrawerViewSwitch";
import { useUnitContent } from "./useUnitContent";

export const UnitContent = () => {
    const unitId = useInventoryDetailsDrawerUrlId();

    const { title, isLoading, publisherData, brandData, supplyData, adUnitData, drawerType, channelData } =
        useUnitContent(unitId);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Row data-sdet="inventory-details-drawer-content">
            <DetailsDrawerUnitLayout title={title} />
            <DetailsDrawerViewSwitch
                drawerType={drawerType}
                publisher={publisherData}
                brand={brandData}
                supply={supplyData}
                adUnit={adUnitData}
                channel={channelData}
            />
        </Row>
    );
};
