import { FC } from "react";
import { EditDemandConfigurationTargeting } from "@app/features/targeting/EditTargeting/EditDemandConfigurationTargeting";
import { useAdSourceTargetingToggle } from "@app/features/targeting/ReadOnlyTargeting/useAdSourceTargetingToggle";
import { useDealAdditionalTargetingSection } from "./useDealAdditionalTargetingSection";
import { TargetingFormKeys } from "@app/features/targeting/constants";
import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { AdSourceTypeNames } from "@app/features/seatAdSources/constants";
import { BaseOption } from "@app/core/services";
import { useCurrencyOptions } from "../../useCurrencyOptions";

export const DealAdditionalTargetingSection: FC = () => {
    const { showExistingAdSourceTargeting, setShowExistingAdSourceTargeting } = useAdSourceTargetingToggle();
    const { adSourceTargeting, additionalTargeting } = useDealAdditionalTargetingSection();
    const dealName = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.NAME]);
    const dealTypeId = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TYPE]);
    const currencyType = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.CURRENCY]);
    const { optionsByValue } = useCurrencyOptions();

    const currencyCode = String(optionsByValue[currencyType]?.label || "USD");
    const dealType = { name: AdSourceTypeNames[dealTypeId] } as BaseOption;

    return (
        <div style={{ marginTop: 8 }} data-sdet="deal-create-form-additional-targeting-section">
            <EditDemandConfigurationTargeting
                additionalTargetingBlocks={additionalTargeting}
                additionalTargetingFormKey={TargetingFormKeys.DealAdditionalTargeting}
                adSourceTargetingBlocks={adSourceTargeting}
                adSourceTargetingFormKey={TargetingFormKeys.Deal}
                setShowExistingAdSourceTargeting={setShowExistingAdSourceTargeting}
                showExistingAdSourceTargeting={showExistingAdSourceTargeting}
                adSourceType={dealType}
                dealName={dealName}
                currencyCode={currencyCode}
            />
        </div>
    );
};
