import { FixedSaveButtons } from "@app/core/components";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { useNotificationError } from "@app/core/components/hooks";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import {
    PriceOverrideConfig,
    useCreatePriceOverrideConfigMutation,
    useGetAdUnitPicklistQuery,
    useGetPriceOverrideTypesQuery,
    useUpdatePriceOverrideConfigMutation,
} from "@app/core/services";
import { Card, Form, Input, InputNumber, Layout, Select, Typography, notification } from "antd";
import debounce from "lodash.debounce";
import { FC, useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FIXED_CLEARING_PRICE_TYPE } from "./constants";
import { PriceOverrideDeleteButtonModal } from "./PriceOverrideDetailsActions/PriceOverrideDeleteButtonModal";

interface Props {
    priceOverride?: PriceOverrideConfig;
}

export const PriceOverrideForm: FC<Props> = ({ priceOverride }) => {
    const [form] = Form.useForm();
    const priceOverrideType = Form.useWatch("priceOverrideType", form);
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const [adUnitKeyword, setAdUnitKeyword] = useState("");

    const {
        data: adUnitsData = [],
        isLoading: isLoadingAdUnits,
        error: adUnitsError,
    } = useGetAdUnitPicklistQuery({
        seatId,
        max: 100,
        keyword: adUnitKeyword,
    });

    const {
        data: priceOverrideTypesData = [],
        isLoading: isLoadingPriceOverrideTypes,
        error: priceOverrideTypesError,
    } = useGetPriceOverrideTypesQuery();

    useNotificationError(adUnitsError || priceOverrideTypesError);

    const handleAdUnitsSearch = useCallback((value: string) => setAdUnitKeyword(value), []);

    const debouncedAdUnitsSearch = useMemo(
        () => debounce((value: string) => handleAdUnitsSearch(value), FILTER_INPUT_DEBOUNCE_TIME),
        [handleAdUnitsSearch]
    );

    const adUnitsOptions = useMemo(
        () => adUnitsData.map(({ id, name }) => ({ label: name, value: id })),
        [adUnitsData]
    );
    const priceOverrideTypesOptions = useMemo(
        () => priceOverrideTypesData.map(({ id, name }) => ({ label: name, value: id })),
        [priceOverrideTypesData]
    );

    const [createPriceOverride] = useCreatePriceOverrideConfigMutation();
    const [updatePriceOverride] = useUpdatePriceOverrideConfigMutation();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const isEditMode = Boolean(priceOverride);
    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={priceOverride}
            wrapperCol={{ xs: 24, md: 12 }}
            onFinish={(values) => {
                setIsSubmitting(true);
                const payload = { ...values, name: values.name.trim(), seat: { id: Number(seatId) } };
                const submitTrigger = isEditMode ? updatePriceOverride : createPriceOverride;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                submitTrigger({ seatId: Number(seatId), payload })
                    .unwrap()
                    .then((response) => {
                        setIsSubmitting(false);
                        notification.success({
                            message: (
                                <span>
                                    <Typography.Text strong>{response.name}</Typography.Text> has successfully been{" "}
                                    {isEditMode ? "updated" : "created"}
                                </span>
                            ),
                        });
                        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_PRICE_OVERRIDE_DETAILS_PAGE(seatId, response.id));
                    })
                    .catch((err) => {
                        setIsSubmitting(false);
                        notification.error({ message: err.message || err.data.errorDescription });
                    });
            }}
        >
            <Layout.Content>
                <Card>
                    <Form.Item hidden name="id" />
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, whitespace: true, message: "Name is required" }]}
                    >
                        <Input data-sdet="price-override-name-field" />
                    </Form.Item>
                    <Form.Item
                        label="Ad Unit"
                        name={["adUnit", "id"]}
                        rules={[{ required: true, message: "Ad Unit is required" }]}
                    >
                        <Select
                            allowClear
                            showSearch
                            data-sdet="price-override-ad-unit-field"
                            onChange={() => setAdUnitKeyword("")}
                            filterOption={false} // do search on API side, given only fetch 100 items per API call
                            onSearch={debouncedAdUnitsSearch}
                            optionFilterProp="label"
                            options={adUnitsOptions}
                            loading={isLoadingAdUnits}
                            placeholder="Select…"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Price Override Type"
                        name={["priceOverrideType", "id"]}
                        rules={[{ required: true, message: "Price Override Type is required" }]}
                    >
                        <Select
                            allowClear
                            showSearch
                            data-sdet="price-override-type-field"
                            optionFilterProp="label"
                            options={priceOverrideTypesOptions}
                            loading={isLoadingPriceOverrideTypes}
                            placeholder="Select…"
                        />
                    </Form.Item>
                    {priceOverrideType?.id === FIXED_CLEARING_PRICE_TYPE && (
                        <Form.Item
                            label="Override Price"
                            name="overridePrice"
                            required
                            rules={[{ required: true, message: "Override Price is required" }]}
                        >
                            <InputNumber data-sdet="price-override-price-field" min={0} style={{ width: "100%" }} />
                        </Form.Item>
                    )}
                </Card>
            </Layout.Content>
            <FixedSaveButtons
                isLoading={isSubmitting}
                submitLabel={isEditMode ? "Save" : "Submit"}
                suffixAddOn={isEditMode ? <PriceOverrideDeleteButtonModal priceOverride={priceOverride} /> : null}
                onCancel={() =>
                    history.push(
                        priceOverride
                            ? ROUTE_FORMATTERS.SEAT_CONTROLS_PRICE_OVERRIDE_DETAILS_PAGE(seatId, priceOverride.id)
                            : ROUTE_FORMATTERS.SEAT_CONTROLS_PRICE_OVERRIDES(seatId)
                    )
                }
            />
        </Form>
    );
};
