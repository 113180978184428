export const DEAL_TRANSACTIONS_TYPE_OPTIONS = {
    RESERVED: {
        id: 1,
        name: "Reserved",
        value: "RESERVED",
    },
    NON_RESERVED: {
        id: 2,
        name: "Non Reserved",
        value: "NON_RESERVED",
    },
};
