import DOMPurify from "dompurify";
import { FC, useState } from "react";
import { Button, Drawer } from "antd";
import { brandCobalt } from "@rubicon/antd-components";
import { useHelpArticles } from "@app/core/components/hooks";
import { EMPTY_HELP_KEYS_BODY } from "@app/features/inventory/constants";

const ADVANCED_FLOORS_TARGETING_HELP_KEY = "advancedfloor.targeting";

export const InventoryAdvancedFloorHelpDrawer: FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { helpArticles } = useHelpArticles([ADVANCED_FLOORS_TARGETING_HELP_KEY]);
    return (
        <>
            <Button
                onClick={() => setIsOpen(true)}
                type="link"
                style={{ color: brandCobalt, padding: 0, height: "fit-content" }}
            >
                How are advanced floors targeted?
            </Button>
            <Drawer
                width={400}
                title="How are advanced floors targeted?"
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <div
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            helpArticles[ADVANCED_FLOORS_TARGETING_HELP_KEY]?.body || EMPTY_HELP_KEYS_BODY
                        ),
                    }}
                />
            </Drawer>
        </>
    );
};
