import { Collapse } from "antd";
import { FC } from "react";
import { configurationCollapseClassList } from "@app/features/inventory/DetailsDrawer/DetailsDrawerContent";
import { advancedBrandSafetyHelpKeys } from "@app/features/inventory/InventoryBrandSafety/InventoryAdvancedBrandSafetyPageForm/helpkeys";
import { BrandSafetySectionKey } from "./helpKeysButton";
import { SectionTitle } from "@app/core/components";
import { HelpKeyItem } from "@app/core/components/HelpKeysDrawer/HelpKeyItem";

interface Props {
    sectionKey: BrandSafetySectionKey;
}
export const BrandSafetyDefinition: FC<Props> = ({ sectionKey }) => {
    const definitionsFields = advancedBrandSafetyHelpKeys[sectionKey].helpKeys;
    const allKeys = definitionsFields.map(({ key }) => key);
    return (
        <Collapse defaultActiveKey={allKeys} className={configurationCollapseClassList}>
            {definitionsFields.map((field) => (
                <Collapse.Panel key={field.key} header={<SectionTitle title={field.label} />} forceRender>
                    <HelpKeyItem helpPath={field.helpPath} />
                </Collapse.Panel>
            ))}
        </Collapse>
    );
};
