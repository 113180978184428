import { FC } from "react";
import { Form, Select } from "antd";
import { Seat } from "@app/core/services";
import { ConditionalTagRender, TagRender } from "@app/core/components";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { useAllowedFMTJSONOptions } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/AdvancedFeaturesForm/AdvancedFeaturesFormFields/AllowedFMTJSONOptions/useAllowedFMTJSONOptions";

interface ExternalCodeNamespacesProps {
    seat: Seat;
    edit?: boolean;
}

export const AllowedFMTJSONOptions: FC<ExternalCodeNamespacesProps> = ({ edit = false, seat }) => {
    const { isFetching, options } = useAllowedFMTJSONOptions();
    return (
        <Form.Item
            wrapperCol={{ xs: 24, sm: 24, lg: 16, xl: 24, xxl: 22 }}
            name={INVENTORY_FORM_FIELDS.ALLOWED_FMT_JSON_OPTIONS.name}
            label={INVENTORY_FORM_FIELDS.ALLOWED_FMT_JSON_OPTIONS.label}
        >
            {edit ? (
                <Select
                    showArrow
                    allowClear
                    showSearch
                    labelInValue
                    mode="multiple"
                    options={options}
                    loading={isFetching}
                    tagRender={TagRender}
                    optionFilterProp="label"
                    autoClearSearchValue={false}
                    placeholder={INVENTORY_FORM_FIELDS.ALLOWED_FMT_JSON_OPTIONS.placeholder}
                />
            ) : (
                <ConditionalTagRender data={seat} property={INVENTORY_FORM_FIELDS.ALLOWED_FMT_JSON_OPTIONS.name} />
            )}
        </Form.Item>
    );
};
