import { ChangeEvent } from "react";
import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormInputFieldChange } from "@app/features/deals/DealForm/reducer";
import { useDispatchOnBlur } from "@app/features/deals/DealForm/hooks";

interface UseDealPublisherEmailField {
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleBlur: () => void;
    value: string;
}

export const useDealPublisherEmailField = (): UseDealPublisherEmailField => {
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.PUBLISHER_EMAIL]);
    const { onChange, onBlur } = useDispatchOnBlur(value);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        onChange(value);
    };
    const handleBlur = () => {
        onBlur(dealFormInputFieldChange, CREATE_DEAL_FORM_ITEMS_NAME.PUBLISHER_EMAIL);
    };

    return {
        handleChange,
        handleBlur,
        value,
    };
};
