import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { useGetCurrenciesQuery } from "@app/core/services";

interface UseDealTransactionCurrencyField {
    options: LabeledValue[];
    isFetching: boolean;
    value: number;
    handleSelect: (value: number) => void;
}

export const useDealTransactionCurrencyField = (): UseDealTransactionCurrencyField => {
    const dispatch = useAppDispatch();
    const { data, isFetching } = useGetCurrenciesQuery();

    const options = useMemo(() => (data || []).map(({ id, code }) => ({ label: code, value: id })), [data]);
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TRANSACTION_CURRENCY]);

    const handleSelect = (value: number): void => {
        dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.TRANSACTION_CURRENCY, value }));
    };

    return {
        options,
        isFetching,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value,
        handleSelect,
    };
};
