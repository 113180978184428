import { FC } from "react";
import { AdUnitMaxExtended } from "@app/features/inventory/components/FormItems";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const MaxExtended: FC = () => {
    return (
        <AdUnitMaxExtended
            name={CHANNEL_FORM_FIELDS.MAX_EXTENDED.name}
            label={CHANNEL_FORM_FIELDS.MAX_EXTENDED.label}
        />
    );
};
