import { useAppDispatch, useAppSelector } from "@app/core/store";
import {
    CREATE_AD_SOURCE_TYPES,
    CREATE_AD_SOURCE_TYPES_ADD,
    CREATE_DEAL_FORM_ITEMS_NAME,
    DEAL_TYPES,
    PRICE_MODELS,
} from "@app/features/deals/constants";
import {
    dealFormInputFieldChange,
    dealFormResetAdSourceFieldsWithDealDefaults,
} from "@app/features/deals/DealForm/reducer";
import { DealAdSourceModeConfiguration, DealType } from "@app/features/deals/DealForm/types";
import { LabeledValue } from "antd/lib/select";
import { RadioChangeEvent } from "antd";
import { AdSourceTypeIds } from "@app/features/seatAdSources/constants";

const { AUCTION_PRICE, UNRESERVED_FIXED_RATE } = AdSourceTypeIds;

export const getCreateAdSourceOptions = (dealType: DealType, priceModel: number) => {
    if (dealType === DEAL_TYPES.GUARANTEE || priceModel === PRICE_MODELS.TIERED) {
        return Object.values(CREATE_AD_SOURCE_TYPES)
            .filter((item) => item !== CREATE_AD_SOURCE_TYPES_ADD)
            .map((item) => ({
                label: item,
                value: item,
            }));
    }
    return Object.values(CREATE_AD_SOURCE_TYPES).map((item) => ({
        label: item,
        value: item,
    }));
};

const getSyncWarningText = (
    mode: DealAdSourceModeConfiguration,
    dealType: DealType,
    selectedAdSourceId: number | null
): string | null => {
    if (mode !== CREATE_AD_SOURCE_TYPES_ADD || !selectedAdSourceId) {
        return null;
    }
    if (dealType === AUCTION_PRICE) {
        return "Adding a Deal to an existing Ad Source will not sync fields between Deal and Ad Source but the Ad Source will apply the latest End Date.";
    }
    if (dealType === UNRESERVED_FIXED_RATE) {
        return "Adding a Deal to an existing Ad Source will not sync fields between Deal and Ad Source but the Ad Source will apply the latest End Date and lowest CPM.";
    }
    return null;
};

interface UseAdSourceModeConfiguration {
    options: LabeledValue[];
    value: DealAdSourceModeConfiguration;
    syncWarningText: string | null;
    onChange: (e: RadioChangeEvent) => void;
}

export const useAdSourceModeConfiguration = (): UseAdSourceModeConfiguration => {
    const dispatch = useAppDispatch();
    const dealType = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TYPE]);
    const priceModel = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.PRICE_MODEL]);
    const selectedAdSourceId = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_COPY_ID]
    );

    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE_CONFIGURATION]
    );

    const options = getCreateAdSourceOptions(dealType, priceModel);

    const syncWarningText = getSyncWarningText(value, dealType, selectedAdSourceId);

    const onChange = (event: RadioChangeEvent): void => {
        const { value } = event.target;
        dispatch(dealFormResetAdSourceFieldsWithDealDefaults());
        dispatch(dealFormInputFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE_CONFIGURATION, value }));
    };

    return {
        value,
        options,
        syncWarningText,
        onChange,
    };
};
