import { Select } from "antd";
import { FC } from "react";
import { LabeledValue } from "antd/lib/select";
import { useCountrySelect } from "@app/features/inventory/components/Fields/CountrySelect/useCountrySelect";

interface CountrySelectProps {
    onChange: (value: LabeledValue) => void;
    value: LabeledValue | null;
}

export const CountrySelect: FC<CountrySelectProps> = ({ value, onChange }) => {
    const { handleChange, handleReset, isFetching, options, localValue } = useCountrySelect();

    return (
        <Select
            showSearch
            allowClear
            labelInValue
            value={value}
            options={options}
            onChange={onChange}
            loading={isFetching}
            placeholder="All Geos"
            onClear={handleReset}
            onSearch={handleChange}
            searchValue={localValue}
            optionFilterProp="label"
            style={{ width: "100%" }}
            autoClearSearchValue={false}
        />
    );
};
