import { FC } from "react";
import { Row, Col, Button, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { DealSelect } from "..";
import { useSelectOrCreateDeal } from "./useSelectOrCreateDeal";

interface Props {
    isFormVisible: boolean;
}

export const SelectOrCreateDeal: FC<Props> = ({ isFormVisible }) => {
    const { isCreateNewDealVisible, isSelectExistingDealVisible, handleClickCreateNewDeal } = useSelectOrCreateDeal();
    const isOrTextVisible = isCreateNewDealVisible && isSelectExistingDealVisible;

    return (
        <Row justify="start" align="middle" gutter={[8, 8]} style={{ marginBottom: `${isFormVisible ? 16 : 0}px` }}>
            <Col hidden={!isCreateNewDealVisible}>
                <Button
                    data-sdet="create-new-deal-button"
                    onClick={handleClickCreateNewDeal}
                    block
                    icon={<PlusOutlined />}
                >
                    <Typography.Text>Create New Deal</Typography.Text>
                </Button>
            </Col>
            <Col hidden={!isOrTextVisible}>
                <Typography.Text>or</Typography.Text>
            </Col>
            <Col hidden={!isSelectExistingDealVisible} span={16}>
                <DealSelect isVisible={isSelectExistingDealVisible} />
            </Col>
        </Row>
    );
};
