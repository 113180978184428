import { PerformanceSectionContext } from "@app/features/inventory/InventoryHealth/InventoryHealthPage/PerformanceSection/usePerformanceSectionContext";
import { FC, useState } from "react";
import { Last24Hours } from "./Last24Hours/Last24Hours";
import { LiveStats } from "./LiveStats/LiveStats";

interface Props {
    adSourceId: number;
    adSourceTypeId: number;
    adSouceName: string;
}

export const Overview: FC<Props> = ({ adSourceId, adSourceTypeId, adSouceName }) => {
    const [isLiveActive, setIsLiveActive] = useState(false);
    const handlePerformanceTypeSwitch = (isLiveActive: boolean) => {
        setIsLiveActive(isLiveActive);
    };

    return (
        <PerformanceSectionContext.Provider value={{ isLiveActive, handlePerformanceTypeSwitch }}>
            {!isLiveActive && (
                <Last24Hours
                    adSourceName={adSouceName}
                    handlePerformanceTypeSwitch={handlePerformanceTypeSwitch}
                    adSourceTypeId={adSourceTypeId}
                    adSourceId={adSourceId}
                />
            )}
            {isLiveActive && (
                <LiveStats
                    adSourceId={adSourceId}
                    adSourceTypeId={adSourceTypeId}
                    handlePerformanceTypeSwitch={handlePerformanceTypeSwitch}
                />
            )}
        </PerformanceSectionContext.Provider>
    );
};
