import {
    AD_UNIT_VAST_STATS,
    BRAND_VAST_STATS,
    diagnosticsApi,
    PUBLISHER_VAST_STATS,
    SEAT_VAST_STATS,
    SUPPLY_VAST_STATS,
} from "./diagnostics";

export interface VastStatsResponse {
    [k: string]: Record<string, number | string>;
}

export const vastStatsApi = diagnosticsApi.injectEndpoints({
    endpoints: (builder) => ({
        getSeatsVastStats: builder.query<VastStatsResponse, number>({
            query: (id) => `seats/${id}/vastStats/latest`,
            providesTags: (_, __, id) => [{ type: SEAT_VAST_STATS, id }],
        }),
        getPublisherVastStats: builder.query<VastStatsResponse, number>({
            query: (id) => `publishers/${id}/vastStats/latest`,
            providesTags: (_, __, id) => [{ type: PUBLISHER_VAST_STATS, id }],
        }),
        getBrandVastStats: builder.query<VastStatsResponse, number>({
            query: (id) => `brands/${id}/vastStats/latest`,
            providesTags: (_, __, id) => [{ type: BRAND_VAST_STATS, id }],
        }),
        getSupplyVastStats: builder.query<VastStatsResponse, number>({
            query: (id) => `supply/${id}/vastStats/latest`,
            providesTags: (_, __, id) => [{ type: SUPPLY_VAST_STATS, id }],
        }),
        getAdUnitVastStats: builder.query<VastStatsResponse, number>({
            query: (id) => `adUnits/${id}/vastStats/latest`,
            providesTags: (_, __, id) => [{ type: AD_UNIT_VAST_STATS, id }],
        }),
    }),
});

export const {
    useGetBrandVastStatsQuery,
    useGetSeatsVastStatsQuery,
    useGetAdUnitVastStatsQuery,
    useGetSupplyVastStatsQuery,
    useGetPublisherVastStatsQuery,
} = vastStatsApi;
