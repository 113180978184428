import { FC } from "react";
import { Card } from "antd";

import {
    AppBundleIDLists,
    SupplyDomainLists,
} from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/ExchangeSafetySection/Fields";

export const ExchangeSafetySection: FC = () => {
    return (
        <Card bordered={false}>
            <SupplyDomainLists />
            <AppBundleIDLists />
        </Card>
    );
};
