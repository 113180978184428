import { useUpdateSeatInContext } from "@app/core/auth";
import { Seat, UpdateSeatPayload } from "@app/core/services";
import { notification } from "antd";
import { useState } from "react";

export interface DeleteContactData {
    name: string;
    id: number;
}

interface UseInternalContactsTable {
    open: boolean;
    isDeleting: boolean;
    deleteContactName?: string;
    handleDelete: () => Promise<void>;
    handleShowModal: (deleteContact: DeleteContactData) => void;
    handleCancelModal: () => void;
}

export const useInternalContactsTable = (seat: Seat): UseInternalContactsTable => {
    const [deleteContact, setDeleteContact] = useState<DeleteContactData | undefined>();
    const [open, setOpen] = useState<boolean>(false);
    const { updateSeatSelf, isLoading: isDeleting } = useUpdateSeatInContext();

    const handleCancelModal = () => {
        setDeleteContact(undefined);
        setOpen(false);
    };

    const handleShowModal = (deleteData: DeleteContactData) => {
        setDeleteContact(deleteData);
        setOpen(true);
    };

    const handleDelete = async (): Promise<void> => {
        const body = {
            ...seat,
            internalSeatContacts: seat.internalSeatContacts.filter(({ id }) => id !== deleteContact?.id),
        } as unknown as UpdateSeatPayload;
        try {
            await updateSeatSelf(body);
            notification.success({ message: `${deleteContact?.name} deleted successfully` });
        } catch (err) {
            notification.error({ message: err.data.errorDescription });
        } finally {
            handleCancelModal();
        }
    };

    return {
        open,
        isDeleting,
        handleDelete,
        handleShowModal,
        handleCancelModal,
        deleteContactName: deleteContact?.name,
    };
};
