import { FC } from "react";
import { Col, Row, Typography } from "antd";
import { LabeledValue } from "antd/lib/select";
import { Conditions, TargetingDimensionTypeLabels } from "../../../constants";
import { MimeTypesTargetingDimension, UsedDimensions } from "../../../types";
import { ConditionSelect } from "../../ConditionSelect";
import { MoreMenu } from "../../MoreMenu";
import { DimensionsSelect } from "./DimensionsSelect";

interface Props {
    dimension: MimeTypesTargetingDimension;
    onChangeCondition: (value: Conditions) => void;
    onChangeDimensionValues: (value: LabeledValue[]) => void;
    onClickDelete: () => void;
    onClickDownload: () => void;
    onClickDuplicate: () => void;
    usedDimensions: UsedDimensions;
}

export const MimeTypesTargetsDimensionRow: FC<Props> = ({
    dimension,
    onChangeCondition,
    onChangeDimensionValues,
    onClickDelete,
    onClickDownload,
    onClickDuplicate,
    usedDimensions,
}) => {
    return (
        <Row gutter={8}>
            <Col flex="150px">
                <Typography.Text strong>{TargetingDimensionTypeLabels.MimeTypes}</Typography.Text>
            </Col>
            <Col flex="128px">
                <ConditionSelect
                    onChange={onChangeCondition}
                    isExcludesDisabled={
                        dimension.condition !== Conditions.Excludes && usedDimensions.mimeTypes.excludes
                    }
                    isIncludesDisabled={
                        dimension.condition !== Conditions.Includes && usedDimensions.mimeTypes.includes
                    }
                    value={dimension.condition}
                />
            </Col>
            <Col flex="1">
                <DimensionsSelect onChange={onChangeDimensionValues} value={dimension.values} />
            </Col>
            <Col flex="146px" style={{ display: "flex", justifyContent: "flex-end" }}>
                <MoreMenu
                    onClickDelete={onClickDelete}
                    onClickDownload={onClickDownload}
                    onClickDuplicate={onClickDuplicate}
                    isDuplicateDisabled={usedDimensions.mimeTypes.includes && usedDimensions.mimeTypes.excludes}
                />
            </Col>
        </Row>
    );
};
