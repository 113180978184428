import { FC } from "react";
import { Col, Layout, Row, Space, Button } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { DownloadOutlined } from "@ant-design/icons";
import { SeatAdSourcesPacingTable } from "./SeatAdSourcesPacingTable/SeatAdSourcesPacingTable";
import { SeatAdSourcesPacingTableFilters } from "./SeatAdSourcesPacingTableFilters";
import { SeatAdSourcesPacingFilterSearch } from "./SeatAdSourcesPacingFilterSearch";
import { useDownloadPacingSummary } from "./useDownloadPacingSummary";
import { AdSourcesDetailsDrawer } from "../SeatAdSourcesDetailsDrawer/AdSourcesDetailsDrawer";

export const SeatAdSourcesPacingPage: FC = () => {
    const { downloadPacingSummaryAsCsv } = useDownloadPacingSummary();

    return (
        <>
            <PageHeader title="Pacing Summary" />
            <Layout.Content data-sdet="seat-ad-sources-pacing-page">
                <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                    <SeatAdSourcesPacingTableFilters />
                    <Row justify="space-between" gutter={[16, 16]}>
                        <Col>
                            <SeatAdSourcesPacingFilterSearch />
                        </Col>
                        <Col>
                            <Row gutter={[16, 16]}>
                                <Col>
                                    <Button
                                        data-sdet="download-pacing-summary-button"
                                        icon={<DownloadOutlined />}
                                        onClick={downloadPacingSummaryAsCsv}
                                    >
                                        CSV
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <SeatAdSourcesPacingTable />
                    </Row>
                </Space>
            </Layout.Content>
            <AdSourcesDetailsDrawer />
        </>
    );
};
