import { CSSProperties, FC } from "react";
import { Input } from "antd";
import { Dashes } from "@app/core/components";

interface InventoryDomainListProps {
    values: string[];
    style?: CSSProperties;
    rows?: number;
}

const defaultRowsSize = 10;

const getRowsSize = (valueLength: number, rows: number): number => {
    if (rows) {
        return rows;
    }
    if (valueLength <= defaultRowsSize) {
        return valueLength;
    }
    return defaultRowsSize;
};

export const TextAreaReadOnlyList: FC<InventoryDomainListProps> = ({ values, style, rows }) => {
    if (!values.length) {
        return (
            <span>
                <Dashes />
            </span>
        );
    }

    return (
        <Input.TextArea
            readOnly
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            rows={getRowsSize(values?.length, rows)}
            style={{ ...style, resize: "none" }}
            value={values?.join("\r\n")}
        />
    );
};
