import { Button, Form, Typography } from "antd";
import { AdBreakLine } from "../AdBreakLine";
import { PlusOutlined } from "@ant-design/icons";
import { AdBreakRuleAds, AdBreakRuleDuration, AdBreakRuleName } from "../../AdBreakRules";
import { AD_UNIT_AD_BREAK_RULES_LABELS } from "@app/features/inventory/InventoryHealthDetailsPages/constants";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { brandCobalt } from "@rubicon/antd-components";

const { useWatch } = Form;
export const PreRoll = () => {
    const midValues = useWatch(CHANNEL_FORM_FIELDS.AD_BREAK_RULES_MID.name);
    const postValues = useWatch(CHANNEL_FORM_FIELDS.AD_BREAK_RULES_POST.name);

    return (
        <>
            <Typography.Title level={5}>{AD_UNIT_AD_BREAK_RULES_LABELS.preRoll}</Typography.Title>
            <Form.List
                name={CHANNEL_FORM_FIELDS.AD_BREAK_RULES_PRE.name}
                rules={[
                    {
                        validator: (_, values) => {
                            if (!values.length && !midValues?.length && !postValues?.length) {
                                return Promise.reject(new Error());
                            }
                            return Promise.resolve();
                        },
                    },
                ]}
            >
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field) => (
                            <AdBreakLine
                                field={field}
                                key={field.key}
                                name={AdBreakRuleName.PRE_ROLL_NAME}
                                ads={AdBreakRuleAds.PRE_ROLL_ADS}
                                duration={AdBreakRuleDuration.PRE_ROLL_DURATION}
                                onRemove={remove}
                            />
                        ))}

                        {!fields.length && (
                            <Button
                                type="link"
                                onClick={add}
                                icon={<PlusOutlined />}
                                style={{
                                    paddingLeft: "0.2rem",
                                    paddingRight: 0,
                                    color: brandCobalt,
                                }}
                            >
                                Add
                            </Button>
                        )}
                    </>
                )}
            </Form.List>
        </>
    );
};
