import { FC, useEffect } from "react";
import { Card, Collapse, Form, Space, Typography } from "antd";
import { DealDetailsTerms } from "@app/features/deals/DealDetailsPage/DealDetailsTerms";
import { DealDetailsPricing } from "@app/features/deals/DealDetailsPage/DealDetailsPricing";
import { DealDetailsDemandConfiguration } from "@app/features/deals/DealDetailsPage/DealDetailsDemandConfiguration";
import { DealList, useGetAdSourceByIdQuery } from "@app/core/services/console";
import {
    AdSourceConfigurationSidePanel,
    DealObjectSidePanel,
    DemandConfigurationSidePanel,
    PacingStatusSidePanel,
    PricingDefinitionSidePanel,
    ChartsSidePanel,
} from "../DealForm/DefinitionSidePanels";
import { DEAL_SECTIONS_NAME, DEAL_SECTION_KEYS, DEAL_TYPES } from "../constants";
import { css, cx } from "@emotion/css";
import {
    classNamePanel,
    DealDetailsPageAdSource,
} from "./DealDetailsPageAdSourceConfiguration/DealDetailsPageAdSource";
import { DealDetailsPageTableAdSource } from "./DealDetailsPageAdSourceConfiguration/DealDetailsPageTableAdSource";
import { useDealAdStats } from "@app/features/adStats";
import { PacingStatus } from "@app/core/components/PacingStatus";
import { DealDetailsDemandTargeting } from "./DealDetailsDemandTargeting";
import { TextProps } from "antd/lib/typography/Text";
import { useTargetingForm } from "@app/features/targeting/useTargetingForm";
import { Operations, TargetingFormKeys } from "@app/features/targeting/constants";
import { OR_ID } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections";
import { Link, useParams } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";
import { getDealMarketplaceInfo } from "@app/features/deals/DealForm/utils";
import { format } from "@rubicon/utils";
import { DealCurrencyMode } from "../shared/DealCurrencyMode/DealCurrencyMode";
import { gray10 } from "@rubicon/antd-components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { collapseChange, selectCollapseKeys } from "../DealDetailsDrawer/reducer";
import { PerformanceSection } from "./PerformanceSection/PerformanceSection";

export const classNameDealDetailsCardBody = css`
    > .ant-card-body {
        padding: 0.75rem 2.5rem 1rem;
    }
`;
const className = css`
    &.ant-collapse-item {
        border-bottom: 0;
    }

    & > .ant-collapse-content > .ant-collapse-content-box {
        padding-left: 16px;
    }
`;

export const mainStepSectionClassList = css`
    .ant-collapse-expand-icon {
        align-self: center;
    }

    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
    }

    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
    }
`;
export const adSourcePanel = cx(mainStepSectionClassList, className);
export const adSourceTablePanelCalssList = css`
    .ant-collapse-content > .ant-collapse-content-box {
        padding-left: 40px;
    }
`;

const classNameInnerPanelBorderless = css`
    &.ant-collapse-item {
        border-bottom: 0;
    }
`;

export const classNamePanelHeader = css`
    font-weight: 500;
    color: ${gray10};
    font-size: 20px;
`;

const { Text } = Typography;

interface PanelHeaderProps extends TextProps {
    title: string;
}

export const PanelHeader: FC<PanelHeaderProps> = ({ title, ...props }) => (
    <Text {...props} className={classNamePanelHeader}>
        {title}
    </Text>
);

interface Props {
    dealAdSource: DealList;
    isInsideDrawer: boolean;
}

export const BuyerDealDetailsForm: FC<Props> = ({ dealAdSource, isInsideDrawer }) => {
    const { setSubEntityIds } = useDealAdStats();
    const { deal, dealListSources } = dealAdSource;
    const {
        id,
        name,
        code,
        dealTransactionType,
        dealPricingType,
        status,
        timeZone,
        dealType,
        network,
        buyerSeats,
        sharedMarketplaces,
        sharedSeats,
        dealOrigin,
        startTime,
        endTime,
        priceModel,
        currencyType,
        rate,
        bookingVolume,
        entityType,
        publisherEmail,
        publisherName,
    } = deal;
    const { seatId } = useParams<{ seatId: string; dealId: string }>();

    const keys = useAppSelector(selectCollapseKeys);
    const dispatch = useAppDispatch();
    const { setTargetingOperation } = useTargetingForm();
    const hasBuyer = buyerSeats && buyerSeats.length;
    const adSourceLen = dealListSources?.length;
    const isSingleAdSource = adSourceLen === 1;
    const singleAdSourceId = isSingleAdSource ? dealListSources[0].id : null;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { data } = useGetAdSourceByIdQuery(singleAdSourceId, { skip: !isSingleAdSource && !singleAdSourceId });
    const adSourceOperation = data?.targetingMode.id === OR_ID ? Operations.Any : Operations.All;

    useEffect(() => {
        if (id) {
            setSubEntityIds([String(id)]);
        }

        if (adSourceOperation) {
            setTargetingOperation(TargetingFormKeys.Deal, adSourceOperation);
        }
    }, [id, setSubEntityIds, adSourceOperation, setTargetingOperation]);

    const hasPacingStatus = Boolean(data?.type?.id === DEAL_TYPES.GUARANTEE);
    const marketplaceInfo = getDealMarketplaceInfo(data?.marketplaceInfoList, deal);

    useEffect(() => {
        const visibleKeys: Set<string> = new Set([DEAL_SECTION_KEYS.DEAL_TERMS, DEAL_SECTION_KEYS.PRICING]);
        if (Boolean(adSourceLen)) {
            visibleKeys.add(DEAL_SECTION_KEYS.CHARTS);
            visibleKeys.add(DEAL_SECTION_KEYS.AD_SOURCE_CONFIGURATION);
        }

        if (hasPacingStatus) {
            visibleKeys.add(DEAL_SECTION_KEYS.PACING_STATUS);
        }

        if (isSingleAdSource) {
            visibleKeys.add(DEAL_SECTION_KEYS.DEMAND_CONFIGURATION);
        }
        const uniqKeys = Array.from(visibleKeys);
        dispatch(
            collapseChange({
                keys: uniqKeys,
                availableKeys: uniqKeys,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isSingleAdSource, adSourceLen, hasPacingStatus]);

    return (
        <Form layout="vertical">
            <Collapse
                activeKey={keys}
                onChange={(keys: string[]) => {
                    dispatch(collapseChange({ keys }));
                }}
                bordered
                ghost={false}
                collapsible="header"
            >
                {Boolean(adSourceLen) && (
                    <Collapse.Panel
                        key={DEAL_SECTION_KEYS.CHARTS}
                        collapsible="header"
                        header={
                            <Space>
                                <PanelHeader title={DEAL_SECTIONS_NAME.PERFORMANCE} />
                                <DealCurrencyMode />
                            </Space>
                        }
                        extra={<ChartsSidePanel />}
                        className={mainStepSectionClassList}
                    >
                        <Card bordered={false} className={classNameDealDetailsCardBody}>
                            <PerformanceSection
                                isInsideDrawer={isInsideDrawer}
                                adSources={dealAdSource.deal?.adSources}
                                sourceCurrencyCode={dealAdSource.deal?.currencyType?.code}
                                dealId={id}
                            />
                        </Card>
                    </Collapse.Panel>
                )}
                {hasPacingStatus && data && (
                    <Collapse.Panel
                        key={DEAL_SECTION_KEYS.PACING_STATUS}
                        header={<PanelHeader title={DEAL_SECTIONS_NAME.PACING_STATUS} />}
                        extra={<PacingStatusSidePanel />}
                        className={mainStepSectionClassList}
                        collapsible="header"
                    >
                        <Card bordered={false} className={classNameDealDetailsCardBody}>
                            <PacingStatus adSource={data} />
                        </Card>
                    </Collapse.Panel>
                )}
                <Collapse.Panel
                    key={DEAL_SECTION_KEYS.DEAL_TERMS}
                    header={<PanelHeader title={DEAL_SECTIONS_NAME.DEAL_TERMS} />}
                    extra={<DealObjectSidePanel label={DEAL_SECTIONS_NAME.DEAL_TERMS} />}
                    className={mainStepSectionClassList}
                    collapsible="header"
                >
                    <Card bordered={false} className={classNameDealDetailsCardBody}>
                        <DealDetailsTerms
                            dealTransactionName={format.asSelf(dealTransactionType?.name)}
                            dealPricingTypeName={format.asSelf(dealPricingType?.name)}
                            statusName={status.name}
                            timeZoneName={timeZone.name}
                            timeZoneCode={timeZone.code}
                            dealType={dealType.name}
                            dealOriginName={dealOrigin.name}
                            dealId={code}
                            name={name}
                            dsp={network.name}
                            buyers={hasBuyer ? buyerSeats : null}
                            startDate={startTime}
                            endDate={endTime}
                            publisherName={publisherName}
                            publisherEmail={publisherEmail}
                            sharedSeats={sharedSeats}
                            sharedMarketplaces={sharedMarketplaces}
                        />
                    </Card>
                </Collapse.Panel>
                <Collapse.Panel
                    key={DEAL_SECTION_KEYS.PRICING}
                    header={<PanelHeader title={DEAL_SECTIONS_NAME.PRICING} />}
                    extra={<PricingDefinitionSidePanel />}
                    className={mainStepSectionClassList}
                    collapsible="header"
                >
                    <Card bordered={false} className={classNameDealDetailsCardBody}>
                        <DealDetailsPricing
                            pricingModel={priceModel?.name}
                            cpmRate={rate}
                            impressions={bookingVolume}
                            currency={currencyType}
                        />
                    </Card>
                </Collapse.Panel>
                {isSingleAdSource && (
                    <Collapse.Panel
                        key={DEAL_SECTION_KEYS.DEMAND_CONFIGURATION}
                        header={<PanelHeader title={DEAL_SECTIONS_NAME.DEMAND_CONFIGURATION} />}
                        extra={<DemandConfigurationSidePanel />}
                        className={mainStepSectionClassList}
                        collapsible="header"
                    >
                        <Card bordered={false} className={classNameDealDetailsCardBody}>
                            <DealDetailsDemandConfiguration
                                enforcement={marketplaceInfo?.enforcement?.name}
                                dealFrequencyCapping={marketplaceInfo?.dealFreqCappings}
                                frequencyCappingType={marketplaceInfo?.freqCappingType}
                                transactionCurrency={marketplaceInfo?.currencyType?.code}
                            />
                        </Card>
                        <Collapse defaultActiveKey={[1]}>
                            <Collapse.Panel
                                key="1"
                                header={
                                    <PanelHeader
                                        title={DEAL_SECTIONS_NAME.DEMAND_TARGETING}
                                        style={{ fontSize: "16px" }}
                                    />
                                }
                                collapsible="header"
                                className={cx(classNamePanel, classNameInnerPanelBorderless)}
                            >
                                <Card bordered={false} className={classNameDealDetailsCardBody}>
                                    {data && <DealDetailsDemandTargeting adSource={data} deal={deal} />}
                                </Card>
                            </Collapse.Panel>
                        </Collapse>
                    </Collapse.Panel>
                )}
                {Boolean(adSourceLen) && (
                    <Collapse.Panel
                        key={DEAL_SECTION_KEYS.AD_SOURCE_CONFIGURATION}
                        collapsible="header"
                        header={
                            <Space size="small">
                                <PanelHeader title={DEAL_SECTIONS_NAME.AD_SOURCE_CONFIGURATION} />

                                {singleAdSourceId && (
                                    <Link to={ROUTE_FORMATTERS.SEAT_AD_SOURCES_DETAILS(seatId, singleAdSourceId)}>
                                        Go to Ad Source <ArrowRightOutlined />
                                    </Link>
                                )}
                            </Space>
                        }
                        extra={<AdSourceConfigurationSidePanel />}
                        className={isSingleAdSource ? adSourcePanel : adSourceTablePanelCalssList}
                    >
                        {isSingleAdSource && data && (
                            <DealDetailsPageAdSource adSource={data} entityType={entityType} />
                        )}
                        {adSourceLen > 1 && (
                            <DealDetailsPageTableAdSource adSources={dealListSources}>
                                <Typography.Paragraph style={{ marginBottom: "1.5rem" }}>
                                    This deal is configured in multiple ad sources
                                </Typography.Paragraph>
                            </DealDetailsPageTableAdSource>
                        )}
                    </Collapse.Panel>
                )}
            </Collapse>
        </Form>
    );
};
