import { SelectAllDropdownRender, TagRender } from "@app/core/components";
import { Form, Select } from "antd";
import { NamePath } from "antd/es/form/interface";
import { LabeledValue } from "antd/es/select";
import { FC } from "react";

interface Props {
    sdet: string;
    mode?: "multiple";
    options: LabeledValue[];
    value?: number[];
    namePath?: NamePath;
    hasSelectAll?: boolean;
}
export const SelectFieldWithLocalOptions: FC<Props> = ({
    sdet,
    options,
    mode,
    value,
    namePath,
    hasSelectAll,
    ...restProps
}) => {
    const form = Form.useFormInstance();

    const areAllOptionsSelected = Boolean(options && value?.length === options.length);

    const toggleSelectDeselectAll = () => {
        if (areAllOptionsSelected) {
            form.setFieldValue(namePath, []);
        } else {
            form.setFieldValue(
                namePath,
                options.map(({ value }) => value)
            );
        }
    };

    return (
        <Select
            allowClear
            showSearch
            data-sdet={sdet}
            dropdownRender={(menu) =>
                hasSelectAll && namePath ? (
                    <SelectAllDropdownRender
                        menu={menu}
                        onClick={toggleSelectDeselectAll}
                        areAllOptionsSelected={areAllOptionsSelected}
                    />
                ) : (
                    menu
                )
            }
            options={options}
            optionFilterProp="label"
            mode={mode}
            autoClearSearchValue={mode !== "multiple"}
            placeholder="Select…"
            tagRender={TagRender}
            value={value}
            {...restProps}
        />
    );
};
