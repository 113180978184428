import { consoleApi, CURATOR_MARKETPLACES_TAG } from "./console";
import { toQueryString } from "./utils";

export interface CuratorMarketplace {
    id: number;
    name: string;
    description: string;
}

export interface GetCuratorMarketplacesSearchParams {
    max?: number;
    page?: number;
    sort?: "id" | "-id" | "-name" | "name";
    keyword?: string;
}

export const curatorsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getCuratorMarketplaces: builder.query<CuratorMarketplace[], GetCuratorMarketplacesSearchParams | void>({
            query: (params?: GetCuratorMarketplacesSearchParams) => `curators/marketplaces;${toQueryString(params)}`,
            providesTags: [CURATOR_MARKETPLACES_TAG],
        }),
    }),
    overrideExisting: false,
});

export const { useGetCuratorMarketplacesQuery } = curatorsApi;
