import {
    ExchangeProtectionsDrawerTitles,
    seatExchangeProtectionsHelpKeys,
} from "@app/features/inventory/InventorySeat/constants";
import { DefinationField } from "@app/core/components/HelpKeysDrawer";

interface UseExchangeProtectionsDefenitionsFields {
    limitsDefinitionsFields: DefinationField[];
    controlDefinitionsFields: DefinationField[];
}

export const useExchangeProtectionsDefenitionsFields = (): UseExchangeProtectionsDefenitionsFields => {
    const limitsDefinitionsFields: DefinationField[] =
        seatExchangeProtectionsHelpKeys[ExchangeProtectionsDrawerTitles.Limits];
    const controlDefinitionsFields: DefinationField[] =
        seatExchangeProtectionsHelpKeys[ExchangeProtectionsDrawerTitles.Control];
    return { limitsDefinitionsFields, controlDefinitionsFields };
};
