import { FC } from "react";
import { Button, Tooltip } from "antd";
import { PlaySquareOutlined } from "@ant-design/icons";
import { useSeatAdSourcesDetailsDrawer } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/useSeatAdSourcesDetailsDrawer";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/types";
interface Props {
    adSourceId: number;
}

export const SeatAdSourcesCreativeReviewActionButton: FC<Props> = ({ adSourceId }) => {
    const { openSeatAdSourcesDetailsDrawer } = useSeatAdSourcesDetailsDrawer();

    return (
        <Tooltip title="Creative Review">
            <Button
                data-sdet="seat-ad-sources-creative-review-action-button"
                onClick={() => {
                    openSeatAdSourcesDetailsDrawer(adSourceId, SecondaryDetailsView.CreativeReview);
                }}
                icon={<PlaySquareOutlined />}
            />
        </Tooltip>
    );
};
