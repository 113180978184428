import { FC } from "react";
import { Form } from "antd";
import { Seat } from "@app/core/services";
import { getSeatFallbackFloor } from "@app/features/inventory/helpers";
import { CurrencyInput } from "@app/core/components/Fields/CurrencyInput";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { useFallBackFloor } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/SeatDetailsFormFields/FallbackFloor/useFallBackFloor";

interface FallbackFloorProps {
    edit?: boolean;
    seat: Seat;
}

export const FallbackFloor: FC<FallbackFloorProps> = ({ edit = false, seat }) => {
    const { currencyCode } = useFallBackFloor();
    return (
        <Form.Item
            name={INVENTORY_FORM_FIELDS.FALLBACK_FLOOR.name}
            label={INVENTORY_FORM_FIELDS.FALLBACK_FLOOR.label}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 24, xxl: 20 }}
        >
            {edit ? <CurrencyInput currency={currencyCode} /> : getSeatFallbackFloor(seat)}
        </Form.Item>
    );
};
