import { FC } from "react";
import { Tabs } from "antd";

export const HISTORICAL = "HISTORICAL";
export const OVERVIEW = "OVERVIEW";

export type DealChartDrawerMenuItemKey = typeof HISTORICAL | typeof OVERVIEW;

type DealChartDrawerMenuItem = {
    label: string;
    key: DealChartDrawerMenuItemKey;
};

const menuItems: DealChartDrawerMenuItem[] = [
    { label: "Overview", key: OVERVIEW },
    { label: "Historical Charts", key: HISTORICAL },
];

interface DealChartDrawerNavMenuProps {
    activeKey: DealChartDrawerMenuItemKey;
    onChange: (key: DealChartDrawerMenuItemKey) => void;
}

const DealChartDrawerNavMenu: FC<DealChartDrawerNavMenuProps> = ({ activeKey, onChange }) => {
    return (
        <Tabs items={menuItems} activeKey={activeKey} onChange={(key: DealChartDrawerMenuItemKey) => onChange(key)} />
    );
};

export default DealChartDrawerNavMenu;
