import { FC } from "react";
import { Form } from "antd";
import {
    AFFILIATE_COST_MODEL,
    AFFILIATE_COST_VALUE_FIXED,
    AFFILIATE_COST_VALUE_PERCENT,
} from "@app/features/inventory/constants";
import { FormItemProps } from "antd/es/form";
import { LabeledValue } from "antd/lib/select";
import { useSeatAuthContext } from "@app/core/auth";
import { currency as currencyUtils } from "@rubicon/utils";
import { LimitInput } from "@app/features/inventory/components/Fields";
import { REVSHARE_PERCENTAGE_COST_MODEL } from "@app/features/inventory/InventorySeat/constants";

const { useWatch } = Form;

export const AffiliateCostValue: FC<FormItemProps> = (props) => {
    const { context } = useSeatAuthContext();
    const affiliateCostModel: LabeledValue = useWatch(AFFILIATE_COST_MODEL);
    const isRevsharePercentageCostModel: boolean = affiliateCostModel?.value === REVSHARE_PERCENTAGE_COST_MODEL.value;

    const currencyInfo = currencyUtils.getCurrencyInfo((context?.floorCurrency?.code as string) || "USD");

    const suffix: string = isRevsharePercentageCostModel ? "%" : (context?.floorCurrency?.code as string);
    const prefix: string | undefined = isRevsharePercentageCostModel ? undefined : currencyInfo?.symbol;

    const toFixed: number = isRevsharePercentageCostModel ? 2 : 3;

    const name: string = isRevsharePercentageCostModel ? AFFILIATE_COST_VALUE_PERCENT : AFFILIATE_COST_VALUE_FIXED;

    const placeholder: string = isRevsharePercentageCostModel
        ? "Enter Revshare Percentage value"
        : "Enter Fixed CPM value";

    const label: string = "Affiliate Cost Value";

    return (
        <Form.Item name={name} label={label} {...props}>
            <LimitInput
                prefix={prefix}
                suffix={suffix}
                toFixed={toFixed}
                style={{ width: "100%" }}
                placeholder={placeholder}
            />
        </Form.Item>
    );
};
