import { Button, Modal, Typography } from "antd";
import { useSeatContactsDelete } from "./useSeatContactsDelete";
import { DeleteOutlined } from "@ant-design/icons";
import { FC } from "react";
import { SeatContactPayload } from "../types";
import { SEAT_CONTACT_DELETE_BTN_SDET } from "../SeatContactsForm/constants";

interface Props {
    isIcon?: boolean;
    contact: SeatContactPayload;
}
export const SeatContactsDeleteModal: FC<Props> = ({ isIcon, contact }) => {
    const { handleOpenModal, handleCloseModal, isLoading, handleDelete, isOpen } = useSeatContactsDelete(contact);

    return (
        <>
            {isIcon ? (
                <Button data-sdet={SEAT_CONTACT_DELETE_BTN_SDET} onClick={handleOpenModal}>
                    <DeleteOutlined />
                </Button>
            ) : (
                <Button data-sdet={SEAT_CONTACT_DELETE_BTN_SDET} danger type="primary" onClick={handleOpenModal}>
                    Delete
                </Button>
            )}
            <Modal
                open={isOpen}
                okText="Delete"
                okType="danger"
                okButtonProps={{ style: { backgroundColor: "#ff4d4f", color: "white" } }}
                onOk={handleDelete}
                onCancel={handleCloseModal}
                confirmLoading={isLoading}
                title="Confirmation"
            >
                Are you sure you want to <Typography.Text strong>Delete</Typography.Text> {contact?.name}?
            </Modal>
        </>
    );
};
