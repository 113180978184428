import { FC } from "react";
import { Table, Tag, Typography } from "antd";
import { formatDateInUtc } from "@app/core/utils";
import { YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE } from "@app/core/components/constants";
import { ColumnsType } from "antd/es/table";
import { BulkOperationExecution, useGetBulkOperationExecutionsQuery } from "@app/core/services";
import { useNotificationError, useTableChanges } from "@app/core/components/hooks";
import { SortOrder } from "antd/es/table/interface";
import { startCase } from "lodash";
import { toTitleCase } from "@rubicon/utils/dist/string";
import { getEntitiesIds, getFailedEntities } from "../helpers";
import { BulkOperationDetailsDrawer, useBulkOperationDetailsDrawer } from "./BulkOperationDetailsDrawer";

export const BULK_OPERATIONS_EXECUTION_DATE_COLUMN_SDET = "bulk-operations-table-execution-date-column";
export const BULK_OPERATIONS_STATUS_COLUMN_SDET = "bulk-operations-table-status-column";
export const BULK_OPERATIONS_EXECUTION_CODE_COLUMN_SDET = "bulk-operations-table-execution-code-column";
export const BULK_OPERATIONS_OP_TYPE_COLUMN_SDET = "bulk-operations-table-op-type-column";
export const BULK_OPERATIONS_ENTITY_TYPE_COLUMN_SDET = "bulk-operations-table-entity-type-column";
export const BULK_OPERATIONS_TABLE_ENTITIES_INCLUDED_COLUMN_SDET = "bulk-operations-table-entities-included-column";
export const BULK_OPERATIONS_TABLE_ENTITIES_FAILED_COLUMN_SDET = "bulk-operations-table-entities-failed-column";

export const getBulkOperationsColumns = (
    sortBy: string,
    sortOrder: SortOrder,
    handleOpen: (item: BulkOperationExecution) => void
): ColumnsType<BulkOperationExecution> => [
    {
        key: "updateDate",
        dataIndex: "updateDate",
        title: <span data-sdet={BULK_OPERATIONS_EXECUTION_DATE_COLUMN_SDET}>Execution Date</span>,
        sorter: (a, b) => new Date(a.updateDate).getTime() - new Date(b.updateDate).getTime(),
        sortOrder: sortBy === "updateDate" ? sortOrder : null,
        fixed: "left" as const,
        width: 200,
        render: (updateDate, record) => (
            <Typography.Link onClick={() => handleOpen(record)}>
                {formatDateInUtc(updateDate, YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE)}
            </Typography.Link>
        ),
    },
    {
        key: "status",
        dataIndex: "status",
        title: <span data-sdet={BULK_OPERATIONS_STATUS_COLUMN_SDET}>Status</span>,
        sorter: (a, b) => a.status.name.localeCompare(b.status.name),
        sortOrder: sortBy === "status" ? sortOrder : null,
        render: (status) => <Tag color={status.name.startsWith("Completed") ? "success" : "error"}>{status.name}</Tag>,
    },
    {
        key: "code",
        dataIndex: "code",
        width: 130,
        title: <span data-sdet={BULK_OPERATIONS_EXECUTION_CODE_COLUMN_SDET}>Execution Code</span>,
        sorter: (a, b) => a.code.localeCompare(b.code),
        sortOrder: sortBy === "code" ? sortOrder : null,
    },
    {
        key: "opType",
        dataIndex: ["definition", "type"],
        width: 100,
        title: <span data-sdet={BULK_OPERATIONS_OP_TYPE_COLUMN_SDET}>OP Type</span>,
        sorter: (a, b) => a.definition.type.localeCompare(b.definition.type),
        sortOrder: sortBy === "opType" ? sortOrder : null,
        render: (type) => toTitleCase(type),
    },
    {
        key: "entityType",
        dataIndex: "definition",
        width: 120,
        title: <span data-sdet={BULK_OPERATIONS_ENTITY_TYPE_COLUMN_SDET}>Entity Type</span>,
        sorter: (a, b) =>
            (a.definition.entityType || a.definition.sourceEntity || "").localeCompare(
                b.definition.entityType || b.definition.sourceEntity || ""
            ),
        sortOrder: sortBy === "entityType" ? sortOrder : null,
        render: (definition) => startCase(definition.entityType || definition.sourceEntity),
    },
    {
        key: "entitiesIncluded",
        dataIndex: "definition",
        title: <span data-sdet={BULK_OPERATIONS_TABLE_ENTITIES_INCLUDED_COLUMN_SDET}>Entities Included</span>,
        sorter: false,
        width: 500,
        render: (definition) => <Typography.Text>{getEntitiesIds(definition)}</Typography.Text>,
    },
    {
        key: "entitiesFailed",
        dataIndex: "error",
        title: <span data-sdet={BULK_OPERATIONS_TABLE_ENTITIES_FAILED_COLUMN_SDET}>Entities Failed</span>,
        sorter: false,
        width: 500,
        render: (error) => <Typography.Text>{getFailedEntities(error)}</Typography.Text>,
    },
];

export const BulkOperationsTable: FC = () => {
    const { data = [], isFetching, error } = useGetBulkOperationExecutionsQuery();

    const { onChange, sortBy, sortOrder } = useTableChanges({ sortParam: { key: "sort", default: "-updateDate" } });

    const { bulkOperationExecution, isOpen, handleClose, handleOpen, handleAfterOpenChange } =
        useBulkOperationDetailsDrawer();

    useNotificationError(error);

    return (
        <>
            <Table
                size="small"
                onChange={onChange}
                dataSource={data}
                columns={getBulkOperationsColumns(sortBy, sortOrder, handleOpen)}
                loading={isFetching}
                pagination={false}
                rowKey="id"
                scroll={{ x: "max-content" }}
                showSorterTooltip={false}
            />
            {bulkOperationExecution && (
                <BulkOperationDetailsDrawer
                    isOpen={isOpen}
                    handleClose={handleClose}
                    handleAfterOpenChange={handleAfterOpenChange}
                    bulkOperationExecution={bulkOperationExecution}
                />
            )}
        </>
    );
};
