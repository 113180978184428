import { Form } from "antd";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { useSeatAuthContext } from "@app/core/auth";
import { useIsVisible } from "./useIsVisible";

export const AssociatedMarketplaceSelect = () => {
    const { context } = useSeatAuthContext();
    const isVisible = useIsVisible();

    if (!isVisible) {
        return null;
    }

    return (
        <Form.Item
            {...VERTICAL_LAYOUT_FIX}
            label={AD_SOURCE_FIELDS.ASSOCIATED_MARKETPLACE.label}
            name={AD_SOURCE_FIELDS.ASSOCIATED_MARKETPLACE.name}
            rules={[{ required: true, message: "Associated Marketplace is required" }]}
        >
            <SelectWithValue
                data-sdet="adsource-assignee"
                fieldAsLabel={["name"]}
                fieldAsValue={["id"]}
                optionFilterProp="label" // For internal LabelValue
                options={context?.authorizedMarketplaces}
                placeholder="Select…"
                showSearch
            />
        </Form.Item>
    );
};
