import { FC } from "react";
import { Form, Radio } from "antd";
import { useHistoricalChartGroupBy } from "@app/core/components/charts/HistoricalChart/HistoricalChartGroupBy/useHistoricalChartGroupBy";

export const HistoricalChartGroupBy: FC = () => {
    const { options, value, handleChange } = useHistoricalChartGroupBy();

    return (
        <Form.Item label="Group By" style={{ marginBottom: 0 }}>
            <Radio.Group onChange={handleChange} value={value} options={options} />
        </Form.Item>
    );
};
