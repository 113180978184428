import { useState } from "react";
import { FieldData } from "rc-field-form/lib/interface";
import { ChangeLogEntityType, useGetChangeLogsQuery } from "@app/core/services";

const mapFormToServiceParams = (values) => {
    return values.reduce((acc, curr) => {
        const [key] = curr.name;
        const value = typeof curr.value === "object" ? curr.value?.value : curr.value;
        return { ...acc, [key]: value };
    }, {});
};

export const useChangeHistoryTable = (entityId: number | undefined, entityType: ChangeLogEntityType | null) => {
    const [changesApiParams, setChangesApiParams] = useState({ actionType: undefined, email: undefined });
    const { data, isFetching, error } = useGetChangeLogsQuery(
        {
            id: entityId as number,
            entityType: entityType as ChangeLogEntityType,
            actionType: changesApiParams.actionType,
            email: changesApiParams.email,
        },
        { skip: !entityId }
    );

    const handleFilterChange = (_: FieldData[], filterFields: FieldData[]) => {
        const apiParams = mapFormToServiceParams(filterFields);
        setChangesApiParams(apiParams);
    };

    return {
        changeLogs: data ?? [],
        isLoading: isFetching,
        handleFilterChange,
        error,
    };
};
