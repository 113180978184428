import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM, NONE } from "@app/core/components/constants";
import { GUTTER, INVENTORY_CARD_BODY_STYLE } from "@app/features/inventory/constants";
import { Form, Row, Col, Card } from "antd";
import { FORM_ITEM_OFFSET, PUBLISHER_GENERAL_LABELS } from "../../../constants";
import { useGeneral } from "./useGeneral";
import moment from "moment-timezone";
import { TextWithCopy } from "@app/features/inventory/components/TextWithCopy";

const { Item } = Form;

const COL_SIZES = { xs: 12, sm: 10, md: 24 };
const BASE_COL_SIZES = { xs: 24, sm: 24, md: 12 };
export const General = () => {
    const {
        code,
        creationDate,
        updateDate,
        externalComment,
        description,
        businessDomain,
        name,
        lastIndexed,
        accountType,
        isInternalOnly,
        publisherNameOverride,
    } = useGeneral();
    return (
        <div data-sdet="publisher-general">
            <Row>
                <Col {...BASE_COL_SIZES}>
                    <Row gutter={GUTTER}>
                        <Col {...COL_SIZES}>
                            <Item label={PUBLISHER_GENERAL_LABELS.name} className={FORM_ITEM_OFFSET}>
                                <TextWithCopy notificationEntity="Name" text={name} />
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={GUTTER}>
                        <Col {...COL_SIZES}>
                            <Item label={PUBLISHER_GENERAL_LABELS.code} className={FORM_ITEM_OFFSET}>
                                <TextWithCopy notificationEntity="Code" text={code} />
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={GUTTER}>
                        <Col {...COL_SIZES}>
                            <Item label={PUBLISHER_GENERAL_LABELS.description} className={FORM_ITEM_OFFSET}>
                                {description ? description : NONE}
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={GUTTER}>
                        <Col {...COL_SIZES}>
                            <Item label={PUBLISHER_GENERAL_LABELS.publisherName} className={FORM_ITEM_OFFSET}>
                                {publisherNameOverride ? publisherNameOverride : code}
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={GUTTER}>
                        <Col {...COL_SIZES}>
                            <Item label={PUBLISHER_GENERAL_LABELS.lastUpdated} className={FORM_ITEM_OFFSET}>
                                {updateDate ? moment(updateDate).format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM) : NONE}
                            </Item>
                        </Col>
                        <Col {...COL_SIZES}>
                            <Item label={PUBLISHER_GENERAL_LABELS.lastIndexed} className={FORM_ITEM_OFFSET}>
                                {lastIndexed ? moment(lastIndexed).format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM) : NONE}
                            </Item>
                        </Col>
                        <Col {...COL_SIZES}>
                            <Item label={PUBLISHER_GENERAL_LABELS.created} className={FORM_ITEM_OFFSET}>
                                {creationDate ? moment(creationDate).format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM) : NONE}
                            </Item>
                        </Col>
                    </Row>
                </Col>
                <Col hidden={!isInternalOnly} {...BASE_COL_SIZES}>
                    <Row gutter={GUTTER}>
                        <Col span={24}>
                            <Card title="Internal Only" type="inner" bodyStyle={INVENTORY_CARD_BODY_STYLE}>
                                <Row gutter={GUTTER}>
                                    <Col {...COL_SIZES}>
                                        <Item label={PUBLISHER_GENERAL_LABELS.accountType} className={FORM_ITEM_OFFSET}>
                                            {accountType?.name || NONE}
                                        </Item>
                                    </Col>
                                    <Col {...COL_SIZES}>
                                        <Item
                                            label={PUBLISHER_GENERAL_LABELS.businessDomain}
                                            className={FORM_ITEM_OFFSET}
                                        >
                                            {businessDomain ? businessDomain : NONE}
                                        </Item>
                                    </Col>
                                    <Col {...COL_SIZES}>
                                        <Item
                                            label={PUBLISHER_GENERAL_LABELS.externalComment}
                                            className={FORM_ITEM_OFFSET}
                                        >
                                            {externalComment ? externalComment : NONE}
                                        </Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
