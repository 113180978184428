import { FC } from "react";
import { Form, Radio } from "antd";
import { BRAND_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { CREATIVE_BLOCK_MODE_OPTIONS } from "@app/features/inventory/InventorySeat/constants";

export const CreativeBlockMode: FC = () => {
    return (
        <Form.Item
            name={BRAND_FORM_FIELDS.CREATIVE_BLOCK_MODE.name}
            label={BRAND_FORM_FIELDS.CREATIVE_BLOCK_MODE.label}
        >
            <Radio.Group size="large" options={CREATIVE_BLOCK_MODE_OPTIONS} />
        </Form.Item>
    );
};
