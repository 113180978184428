import { useCallback, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useGetAppVersionQuery } from "@app/core/services";

const APP_VERSION_POLLING_INTERVAL_MINUTES = 1;

interface UseAppVersionRefreshModal {
    isModalOpen: boolean;
    refreshApplication: () => void;
}

export const useAppVersionRefreshModal = (): UseAppVersionRefreshModal => {
    const { go, location } = useHistory();
    const { data } = useGetAppVersionQuery(undefined, {
        pollingInterval: APP_VERSION_POLLING_INTERVAL_MINUTES * 60 * 1000,
    });

    const lastVersionRef = useRef<string>();
    const currentVersion = useMemo<string | undefined>(() => data?.version, [data]);
    const hasVersionUpdate = useMemo<boolean>(() => {
        if (!currentVersion) {
            return false;
        }

        const hasUpdate = Boolean(lastVersionRef.current) && lastVersionRef.current !== currentVersion;
        lastVersionRef.current = currentVersion;
        return hasUpdate;
    }, [currentVersion]);

    const isRefreshable = useMemo(
        () =>
            !["create", "edit", "copy"].some((nonRefreshableRoutes) =>
                location.pathname.includes(nonRefreshableRoutes)
            ),
        [location]
    );

    const isModalOpen = useMemo(() => hasVersionUpdate && isRefreshable, [hasVersionUpdate, isRefreshable]);

    const refreshApplication = useCallback(() => go(0), [go]);

    return {
        isModalOpen,
        refreshApplication,
    };
};
