import { Button, Col, Modal, Row, Select, Space, Typography } from "antd";
import { FC } from "react";
import { BulkEditAction, FormItemCommonConfig } from "../../../constants";
import { NormalizedInput } from "./NormalizedInput";
import { useBulkOperationBulkEditButtonModal } from "./useBulkOperationBulkEditButtonModal";
import { TouchedFieldInfo } from "../../../reducer";
import { AnyAction } from "@reduxjs/toolkit";

export const BulkEditModalFieldListTitles: FC = ({}) => (
    <Row gutter={8}>
        {[
            { text: "Field", span: 6 },
            { text: "Action", span: 6 },
            { text: "Value", span: 12 },
        ].map(({ text, span }) => (
            <Col span={span} key={text}>
                <Typography.Title level={5} style={{ margin: 0 }}>
                    {text}
                </Typography.Title>
            </Col>
        ))}
    </Row>
);

export const BULK_OPERATION_BULK_EDIT_MODAL_PROPS = {
    destroyOnClose: true,
    "data-sdet": "bulk-edit-modal",
    maskClosable: false,
    title: "Bulk Edit",
    okText: <span data-sdet="modal-apply-button">Apply</span>,
    width: "800px",
};

const FIELDS_WITH_ADD_ACTION = [
    "labelValues",
    "mimes",
    "supportedProtocols",
    "videoDeliveries",
    "blockedCreativeAttributes",
    "supportedApis",
    "sharedSeats",
    "sharedMarketplaces",
];
const FIELDS_WITH_REMOVE_ACTION = [
    "mimes",
    "supportedProtocols",
    "videoDeliveries",
    "blockedCreativeAttributes",
    "supportedApis",
    "sharedSeats",
    "sharedMarketplaces",
];
const FIELDS_WITH_CLEAR_ACTION = ["supportedApis"];

const getActionFieldOptions = (fieldName: string) => {
    const options = [{ value: BulkEditAction.Replace, label: "Replace with" }];

    if (FIELDS_WITH_ADD_ACTION.includes(fieldName)) {
        options.push({ value: BulkEditAction.Add, label: "Add if not included" });
    }

    if (FIELDS_WITH_REMOVE_ACTION.includes(fieldName)) {
        options.push({ value: BulkEditAction.Remove, label: "Remove if included" });
    }

    if (FIELDS_WITH_CLEAR_ACTION.includes(fieldName)) {
        options.push({ value: BulkEditAction.Clear, label: "Clear all" });
    }

    return options;
};

interface FieldListColumnsProps<T = unknown> {
    editableFields: string[];
    entityLabel: string;
    formItemConfig: FormItemCommonConfig<T>[];
    formItemName: "adSourceUpdates" | "adUnitUpdates" | "buyerDealUpdates" | "demandDealUpdates";
    seatId: number;
    selectedRows: T[];
    setBulkChange: (bulkChangeInfo: Record<number, TouchedFieldInfo[]>) => AnyAction;
}

export const BulkOperationBulkEditButtonModal = <T extends { id: number; externalName?: string | null; name: string }>({
    editableFields,
    entityLabel,
    formItemConfig,
    formItemName,
    seatId,
    selectedRows,
    setBulkChange,
}: FieldListColumnsProps<T>) => {
    const { isOpen, handleActionChange, handleValueChange, handleOpen, handleClose, onOk, fieldsInfo } =
        useBulkOperationBulkEditButtonModal<T>(formItemName, setBulkChange);

    const bulkEditCount = selectedRows.length;

    return (
        <>
            <Button data-sdet="bulk-edit-button" disabled={!bulkEditCount} type="primary" onClick={handleOpen}>
                Bulk Edit
            </Button>
            <Modal
                {...BULK_OPERATION_BULK_EDIT_MODAL_PROPS}
                open={isOpen}
                onCancel={handleClose}
                onOk={() => onOk(selectedRows)}
            >
                <Typography.Paragraph>{`${bulkEditCount} ${entityLabel}${
                    bulkEditCount === 1 ? "" : "s"
                } selected`}</Typography.Paragraph>
                <Space direction="vertical" style={{ display: "flex" }}>
                    <BulkEditModalFieldListTitles />
                    {formItemConfig
                        .filter(({ key }) => editableFields.includes(key))
                        .map(({ key, title, getIsDisabled, getQueryArgs, formProps, props, component: Component }) => {
                            const options = getActionFieldOptions(key);

                            return (
                                <Row gutter={8} key={key} align="middle" style={{ minHeight: 32 }}>
                                    <Col span={6}>{title}</Col>
                                    <Col span={6} style={{ display: "grid" }}>
                                        {options.length === 1 ? (
                                            options[0].label
                                        ) : (
                                            <Select
                                                defaultValue="replace"
                                                onChange={(value) =>
                                                    handleActionChange(value, {
                                                        key,
                                                        title,
                                                        getIsDisabled,
                                                    })
                                                }
                                                options={getActionFieldOptions(key)}
                                            />
                                        )}
                                    </Col>
                                    {fieldsInfo[key]?.action !== BulkEditAction.Clear && (
                                        <Col span={12}>
                                            {formProps?.normalize ? (
                                                <NormalizedInput
                                                    {...props}
                                                    component={Component}
                                                    onChange={(value) =>
                                                        handleValueChange(value, {
                                                            key,
                                                            title,
                                                            getIsDisabled,
                                                        })
                                                    }
                                                    placeholder="No Changes"
                                                    style={{ width: "100%" }}
                                                />
                                            ) : (
                                                <Component
                                                    {...props}
                                                    // @ts-expect-error // Component here is either AntD component or customized component
                                                    args={getQueryArgs?.(seatId) || []}
                                                    onChange={(value) =>
                                                        handleValueChange(value?.target ? value.target.value : value, {
                                                            key,
                                                            title,
                                                            getIsDisabled,
                                                        })
                                                    }
                                                    placeholder="No Changes"
                                                    style={{ width: "100%" }}
                                                />
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            );
                        })}
                </Space>
            </Modal>
        </>
    );
};
