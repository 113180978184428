import { FC, useState } from "react";
import { Button, Modal, Tooltip, Typography, notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Floor, useDeleteFloorMutation } from "@app/core/services";

interface Props {
    floor: Floor;
}

export const DeleteFloorButton: FC<Props> = ({ floor }) => {
    const [removeFloor, { isLoading }] = useDeleteFloorMutation();
    const [isOpen, setIsOpen] = useState(false);

    const open = () => {
        setIsOpen(true);
    };

    const close = () => {
        setIsOpen(false);
    };

    const handleRemove = async () => {
        try {
            await removeFloor(floor.id);
            notification.success({ message: "Floor deleted successfully" });
        } catch (err) {
            notification.error({ message: err.data.errorDescription });
        }
    };

    return (
        <>
            <Tooltip title="Remove Floor">
                <Button icon={<DeleteOutlined />} onClick={open} />
            </Tooltip>
            <Modal
                open={isOpen}
                title="Confirmation"
                onOk={handleRemove}
                onCancel={close}
                confirmLoading={isLoading}
                footer={
                    <>
                        <Button onClick={close} disabled={isLoading}>
                            Cancel
                        </Button>
                        <Button type="primary" danger loading={isLoading} onClick={handleRemove}>
                            Delete
                        </Button>
                    </>
                }
            >
                <Typography.Paragraph>Are you sure you want to delete floor?</Typography.Paragraph>
                {floor.name && <Typography.Text strong>{floor.name}</Typography.Text>}
            </Modal>
        </>
    );
};
