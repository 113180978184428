import { AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { FC } from "react";
import { InventoryDistributionGroupsField } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/DeliverySection/Fields/InventoryDistributionGroupsList/InventoryDistributionGroupsField";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { InventoryDistributionGroup } from "@app/core/services";
import { useSeatAuthContext } from "@app/core/auth";

interface Props {
    mode: DealFormMode | undefined;
}

export const AdSourceInventoryDistributionGroupsField: FC<Props> = ({ mode }) => {
    const { context } = useSeatAuthContext();
    const dispatch = useAppDispatch();
    const onChange = (value: InventoryDistributionGroup[]): void => {
        dispatch(
            dealFormSelectFieldChange({
                field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_INVENTORY_DISTRIBUTION_GROUPS,
                value,
            })
        );
    };
    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_INVENTORY_DISTRIBUTION_GROUPS]
    );
    const dealType = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TYPE]);

    return (
        <InventoryDistributionGroupsField
            isEditing={mode === "edit"}
            isHidden={dealType !== AdSourceTypeIds.AUTOMATED_GUARANTEED || !context?.distributionGroupsEnabled}
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_INVENTORY_DISTRIBUTION_GROUPS}
            label="Inventory Distribution Groups"
            onChange={onChange}
            value={value}
        />
    );
};
