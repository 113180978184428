import { css } from "@emotion/css";
import { useHistory } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useBuyerAuthContext, useUserAccess } from "@app/core/auth";
import { Button, Form, Space, Tag, Typography } from "antd";
import { SearchContextInputGroup, useBuyerSideNavState } from "@app/core/components";
import { LogoutOutlined, UserOutlined, SettingOutlined } from "@ant-design/icons";
import { useHasMultipleContextsAccess } from "../hooks";
import { SideDrawer } from "../SideDrawer";
import { RunAsUser, useHasRunAsUserAccess } from "@app/features/runAsUser";
import { useAppDispatch } from "@app/core/store";
import { resetAuthState } from "@app/core/auth/reducer";
import { useAuthClient } from "@app/core/authClient";

export const BuyerAccountDrawer = () => {
    const { hasAdminAccess, hasInternalAccess } = useUserAccess();
    const { context, session } = useBuyerAuthContext();
    const { logout } = useAuthClient();
    const { isOpen, isAccount, handleMenuClose, handleAccountClose } = useBuyerSideNavState();
    const history = useHistory();
    const hasMultipleContextsAccess = useHasMultipleContextsAccess();
    const hasRunAsUserAccess = useHasRunAsUserAccess();
    const dispatch = useAppDispatch();

    const handleLogout = () => {
        dispatch(resetAuthState());
        logout();
        handleAccountClose();
    };

    const handleUserPreferencesClick = () => {
        if (context) {
            history.push(ROUTE_FORMATTERS.BUYER_USER_PREFERENCES(context.id));
        }
        handleMenuClose();
    };
    const handleSwitchToAdmin = () => {
        if (context) {
            history.push(ROUTE_FORMATTERS.BUYER_USER_ADMIN(context.id));
        }
        handleMenuClose();
    };

    if (!context) {
        return null;
    }

    const accountName = context?.name;
    const accountType = context?.entityType || "Buyer";

    return (
        <SideDrawer isOpen={isOpen && isAccount} handleClose={handleMenuClose} handleTypeClose={handleAccountClose}>
            <Form layout="vertical">
                <Form.Item label="Buyer Name" className={css({ marginBottom: "0.75rem" })}>
                    <Space>
                        <Typography.Text data-sdet="account-name">{accountName}</Typography.Text>
                        <span>
                            <Tag data-sdet="account-type-tag">{accountType}</Tag>
                        </span>
                    </Space>
                </Form.Item>
                <Form.Item label="User" className={css({ marginBottom: "0.75rem" })}>
                    <Typography.Text data-sdet="account-name">{session?.user?.firstName}</Typography.Text>
                </Form.Item>
                <Form.Item className={css({ marginBottom: "0.75rem" })} label="User Email">
                    <Typography.Text data-sdet="user-email">
                        <Space>{session?.user?.emailAddress}</Space>
                    </Typography.Text>
                </Form.Item>
                {(hasInternalAccess || hasMultipleContextsAccess) && (
                    <Form.Item label="Change User Context">
                        <SearchContextInputGroup handleSelect={handleMenuClose} />
                    </Form.Item>
                )}
                {hasRunAsUserAccess && (
                    <Form.Item label="Run As User">
                        <RunAsUser handleMenuClose={handleMenuClose} />
                    </Form.Item>
                )}
                <Form.Item className={css({ marginBottom: "1.5rem" })}>
                    <Space>
                        <Button onClick={handleLogout} icon={<LogoutOutlined />} data-sdet="account-logout">
                            Logout
                        </Button>
                        {hasAdminAccess && (
                            <Button onClick={handleSwitchToAdmin} icon={<SettingOutlined />}>
                                Admin
                            </Button>
                        )}
                        <Button
                            onClick={handleUserPreferencesClick}
                            icon={<UserOutlined />}
                            data-sdet="user-preferences"
                        >
                            User Preferences
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </SideDrawer>
    );
};
