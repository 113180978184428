import { MarketplaceInfo } from "@app/core/services";
import { ReadonlyControl, SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { Form } from "antd";
import { FC } from "react";
import { DEAL_FORM_FIELDS } from "../../../../constants";
import { useDemandSelect } from "./useDemandSelect";
import { XandrWarningModal } from "@app/core/components";

export const DemandSelect: FC = () => {
    const { availableNetworks, dealObjectMode, isLoading, isDisabled, isReadonly, selectedDspNetworkId, onChange } =
        useDemandSelect();
    return (
        <>
            <XandrWarningModal selectedDspNetworkId={selectedDspNetworkId} formMode={dealObjectMode} />
            <Form.Item
                rules={[{ required: !isReadonly, message: "Demand is required" }]}
                name={DEAL_FORM_FIELDS.DEMAND.name}
                label={DEAL_FORM_FIELDS.DEMAND.label}
            >
                {isReadonly ? (
                    <ReadonlyControl<MarketplaceInfo["network"]> getLabel={(network) => network.name} />
                ) : (
                    <SelectWithValue
                        disabled={isDisabled}
                        fieldAsLabel={["name"]}
                        fieldAsValue={["id"]}
                        loading={isLoading}
                        onChange={onChange}
                        optionFilterProp="label" // For internal LabelValue
                        options={availableNetworks}
                        placeholder="Select…"
                        showSearch
                    />
                )}
            </Form.Item>
        </>
    );
};
