import { Footer } from "@app/core/components";
import { useScrollToTopOnMount } from "@app/core/components/hooks";
import { PublisherChannelForm } from "@app/features/inventory/HierarchyForms/PublisherChannelForm";
import { PublisherChannelFormInstance } from "@app/features/inventory/HierarchyForms/PublisherChannelForm/PublisherChannelFormInstance";
import { Layout } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useHistory } from "react-router-dom";

export const PublisherChannelCreatePage = () => {
    useScrollToTopOnMount();
    const history = useHistory();
    const onBack = (): void => history.goBack();

    return (
        <>
            <PageHeader title="New Channel" onBack={onBack} />
            <Layout.Content>
                <PublisherChannelFormInstance mode="create">
                    <PublisherChannelForm />
                </PublisherChannelFormInstance>
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
