import { FC } from "react";
import { Form, Collapse } from "antd";
import { ItemType } from "rc-collapse/es/interface";
import { Loading, Title } from "@app/core/components";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import {
    CTVToolsSection,
    AdminToolsSection,
    AdResponsesSection,
    CreativeControlSection,
    DemandManagementSection,
    UnifiedDecisioningSection,
    InventoryManagementSection,
    IdentityAndRegulationSection,
} from "@app/features/inventory/InventorySeat/AdvancedFeaturesPage/AdvancedFeaturesSections";
import { AffixFormButtonsBar } from "@app/features/inventory/components/AffixFormButtonsBar";
import { INVENTORY_HEADER_COLLAPSE_CLASS_LIST } from "@app/features/inventory/InventorySeat/constants";
import { useAdvancedFeaturesForm } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/AdvancedFeaturesForm/useAdvancedFeaturesForm";
import { useAdvancedFeaturesDefenitionsFields } from "@app/features/inventory/InventorySeat/AdvancedFeaturesPage/useAdvancedFeaturesDefenitionsFields";

export const AdvancedFeaturesForm: FC = () => {
    const { form, seat, isUpdating, handleCancel, handleSubmit, initialValues, isFetching, hasInternalAccess } =
        useAdvancedFeaturesForm();
    const {
        CTVToolsDefinitionsFields,
        adminToolsDefinitionsFields,
        adResponsesDefinitionsFields,
        creativeControlDefinitionsFields,
        demandManagementDefinitionsFields,
        unifiedDecisioningDefinitionsFields,
        inventoryManagementDefinitionsFields,
        identityAndRegulationsDefinitionsFields,
    } = useAdvancedFeaturesDefenitionsFields();

    if (!seat || isFetching) return <Loading />;

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={initialValues}
                name="advancedFeaturesForm"
                scrollToFirstError={{ behavior: "smooth", block: "center" }}
            >
                <Collapse
                    bordered
                    ghost={false}
                    className={INVENTORY_HEADER_COLLAPSE_CLASS_LIST}
                    defaultActiveKey={["0", "1", "2", "3", "4", "5", "6", "7"]}
                    items={
                        [
                            {
                                key: "0",
                                children: <InventoryManagementSection seat={seat} edit />,
                                label: <Title level={5} title="Inventory Management" />,
                                extra: (
                                    <HelpKeysDrawer
                                        panelLabel="Inventory Management"
                                        definitionsFields={inventoryManagementDefinitionsFields}
                                    />
                                ),
                            },
                            {
                                key: "1",
                                children: <CTVToolsSection seat={seat} edit />,
                                label: <Title level={5} title="CTV Tools" />,
                                extra: (
                                    <HelpKeysDrawer
                                        panelLabel="CTV Tools"
                                        definitionsFields={CTVToolsDefinitionsFields}
                                    />
                                ),
                            },
                            {
                                key: "2",
                                children: <IdentityAndRegulationSection seat={seat} edit />,
                                label: <Title level={5} title="Identity and Regulations" />,
                                extra: (
                                    <HelpKeysDrawer
                                        panelLabel="Identity and Regulations"
                                        definitionsFields={identityAndRegulationsDefinitionsFields}
                                    />
                                ),
                            },
                            hasInternalAccess && {
                                key: "3",
                                children: <CreativeControlSection seat={seat} edit />,
                                label: <Title level={5} title="Creative Control (Internal Only)" />,
                                extra: (
                                    <HelpKeysDrawer
                                        panelLabel="Creative Control"
                                        definitionsFields={creativeControlDefinitionsFields}
                                    />
                                ),
                            },
                            hasInternalAccess && {
                                key: "4",
                                children: <AdResponsesSection seat={seat} edit />,
                                label: <Title level={5} title="Ad Responses (Internal Only)" />,
                                extra: (
                                    <HelpKeysDrawer
                                        panelLabel="Ad Responses"
                                        definitionsFields={adResponsesDefinitionsFields}
                                    />
                                ),
                            },
                            {
                                key: "5",
                                children: <DemandManagementSection seat={seat} edit />,
                                label: <Title level={5} title="Demand Management" />,
                                extra: (
                                    <HelpKeysDrawer
                                        panelLabel="Demand Management"
                                        definitionsFields={demandManagementDefinitionsFields}
                                    />
                                ),
                            },
                            hasInternalAccess && {
                                key: "6",
                                children: <UnifiedDecisioningSection seat={seat} edit />,
                                label: <Title level={5} title="Unified Decisioning (Internal Only)" />,
                                extra: (
                                    <HelpKeysDrawer
                                        panelLabel="Unified Decisioning"
                                        definitionsFields={unifiedDecisioningDefinitionsFields}
                                    />
                                ),
                            },
                            hasInternalAccess && {
                                key: "7",
                                children: <AdminToolsSection seat={seat} edit />,
                                label: <Title level={5} title="Admin Tools (Internal Only)" />,
                                extra: (
                                    <HelpKeysDrawer
                                        panelLabel="Admin Tools"
                                        definitionsFields={adminToolsDefinitionsFields}
                                    />
                                ),
                            },
                        ].filter(Boolean) as ItemType[]
                    }
                />
                <AffixFormButtonsBar
                    loading={isUpdating}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    disabled={isUpdating}
                    submitButtonTitle="Save"
                />
            </Form>
        </>
    );
};
