import { red5 } from "@rubicon/antd-components";
import { Fragment, ReactNode } from "react";

export const Asterisk = () => <span style={{ color: red5, marginLeft: "0.125rem" }}>*</span>;

export const formRequiredMark = (label: ReactNode, { required }: { required: boolean }) => {
    return required ? (
        <Fragment>
            {label}
            <Asterisk />
        </Fragment>
    ) : (
        label
    );
};
