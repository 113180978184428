import { AdSourceTypes } from "@app/features/seatAdSources/constants";

export const isExtendTimeoutValueVisible = (adSourceTypeName: string) => {
    switch (adSourceTypeName) {
        case AdSourceTypes.PROGRAMATIC_GUARANTEED:
        case AdSourceTypes.CONDITIONAL_PROGRAMATIC_GUARANTEED:
        case AdSourceTypes.FIXED_PRICE:
        case AdSourceTypes.AUCTION_PRICE:
        case AdSourceTypes.OPEN_AUCTION:
        case AdSourceTypes.LINEAR_AUCTION_PRICE:
        case AdSourceTypes.LINEAR_FIXED_PRICE:
        case AdSourceTypes.SERVER_SIDE_TAG_GUARANTEED:
        case AdSourceTypes.SERVER_SIDE_DYNAMIC_PRICE:
        case AdSourceTypes.SERVER_SIDE_TAG_NON_GUARANTEED:
            return true;
        default:
            return false;
    }
};
