import { AdSourceFormsContext, DealObjectForm } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { Form } from "antd";
import { useContext, useEffect, useMemo } from "react";
import { PRICE_MODEL_OPTIONS } from "./PricingSection/Fields";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { useMapStatuses } from "@app/features/syncedFields";
import moment from "moment-timezone";
import { useIsAdSourceStartDateApiReadOnly } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/DeliverySection/Fields/StartDatePicker";

const DEFAULT_INITIAL_DEAL_STATUS = {
    creationDate: "2021-08-11T00:00:00.000+0000",
    updateDate: "2021-08-11T00:00:00.000+0000",
    id: 1,
    name: "Active",
};

export const useDealObjectInitials = () => {
    const { forms } = useContext(AdSourceFormsContext);
    const { adSourceForm, dealObjectForm } = forms;
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const adSourceTimeZone = Form.useWatch(AD_SOURCE_FIELDS.TIME_ZONE.name, adSourceForm);
    const adSourceStartDate = Form.useWatch(AD_SOURCE_FIELDS.START_DATE.name, adSourceForm);
    const adSourceEndDate = Form.useWatch(AD_SOURCE_FIELDS.END_DATE.name, adSourceForm);
    const adSourceCurrencyType = Form.useWatch(AD_SOURCE_FIELDS.CURRENCY.name, adSourceForm);
    const adSourceImpressions = Form.useWatch(AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.name, adSourceForm);
    const adSourceCpm = Form.useWatch(AD_SOURCE_FIELDS.FIXED_CPM.name, adSourceForm);
    const adSourcePriceModel = Form.useWatch(AD_SOURCE_FIELDS.PRICE_MODEL.name, adSourceForm);
    const adSourceStatus = Form.useWatch(AD_SOURCE_FIELDS.STATUS.name, adSourceForm);
    const { mapAdSourceStatusToDealStatus } = useMapStatuses();
    const initialDealStatus = useMemo(
        () => (adSourceStatus?.id ? mapAdSourceStatusToDealStatus(adSourceStatus.id) : DEFAULT_INITIAL_DEAL_STATUS),
        [adSourceStatus?.id, mapAdSourceStatusToDealStatus]
    );
    const isAdSourceStartDateApiLocked = useIsAdSourceStartDateApiReadOnly();

    const dealObjectFormInitials: Partial<DealObjectForm["dealUnderEdit"]> = {
        entityType: "DemandDeal",
        status: initialDealStatus,
        priceModel: PRICE_MODEL_OPTIONS.FIXED_PRICE,
    };

    useEffect(() => {
        if (dealObjectMode === "create") {
            dealObjectForm.setFieldsValue({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dealOrigin: null,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dealUnderEdit: {
                    timeZone: adSourceTimeZone,
                    startTime: adSourceStartDate && !isAdSourceStartDateApiLocked ? adSourceStartDate : moment(),
                    endTime: adSourceEndDate,
                    currencyType: {
                        ...adSourceCurrencyType,
                        creationDate: "",
                        updateDate: "",
                    },
                    bookingVolume: adSourceImpressions,
                    rate: adSourceCpm ? String(adSourceCpm) : undefined,
                    priceModel: adSourcePriceModel,
                },
            });
        }
    }, [
        adSourceTimeZone,
        adSourceStartDate,
        adSourceEndDate,
        adSourcePriceModel,
        dealObjectMode,
        dealObjectForm,
        adSourceCurrencyType,
        adSourceImpressions,
        adSourceCpm,
        isAdSourceStartDateApiLocked,
    ]);

    return { dealObjectFormInitials };
};
