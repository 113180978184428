import { FC } from "react";
import { Card, Col, Row } from "antd";
import { GUTTER } from "@app/features/deals/constants";
import { cardBodyStyle } from "@app/features/deals/DealForm";
import { AdSourcePixelsField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceCustomPixelsSection/Fields";

export const AdSourceCustomPixelsSection: FC = () => {
    return (
        <Card bordered={false} styles={{ body: cardBodyStyle }} data-sdet="deal-form-ad-source-custom-pixels-section">
            <Row gutter={GUTTER}>
                <Col xs={24} sm={24} md={12} lg={12} xl={16} xxl={14}>
                    <AdSourcePixelsField />
                </Col>
            </Row>
        </Card>
    );
};
