import { Form, Select } from "antd";
import { useAdSourceFloorTypeField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceFloorSettingsSection/Fields/AdSourceFloorTypeField/useAdSourceFloorTypeField";

export const AdSourceFloorTypeField = () => {
    const { handleChange, value, isLoading, options } = useAdSourceFloorTypeField();
    return (
        <Form.Item label="Floor Type" data-sdet="ad-source-floor-type-field">
            <Select
                placeholder="Select Floor Type"
                options={options}
                value={value}
                loading={isLoading}
                onChange={handleChange}
            />
        </Form.Item>
    );
};
