import { FC } from "react";
import { useSeatAdSourcesLivePerformanceChart } from "./useSeatAdSourcesLivePerformanceChart";
import { AdSourceStat } from "../../data";
import { TogglableChart } from "@app/core/components/charts/TogglableChart/TogglableChart";
import { MixCountAreaCurrencyLineChart } from "@app/core/components/charts/DualAxesChart/MixChartWithDualAxesCountAreaCurrencyLineChart";

interface Props {
    adSourceStat: AdSourceStat;
}

export const SeatAdSourcesLivePerformanceChart: FC<Props> = ({ adSourceStat }) => {
    const { chartId, disablePoints, metricOne, metricOneYAxisTitle, metricTwo, metricTwoYAxisTitle, smoothLine } =
        useSeatAdSourcesLivePerformanceChart(adSourceStat);

    return (
        <TogglableChart
            metricOne={metricOne}
            metricTwo={metricTwo}
            chartRenderer={(filteredMetricOne, filteredMetricTwo) => (
                <MixCountAreaCurrencyLineChart
                    height={300}
                    chartId={chartId}
                    metricOne={filteredMetricOne}
                    metricTwo={filteredMetricTwo}
                    metricOneYAxisTitle={metricOneYAxisTitle}
                    metricTwoYAxisTitle={metricTwoYAxisTitle}
                    disablePoints={disablePoints}
                    smoothLine={smoothLine}
                />
            )}
        />
    );
};
