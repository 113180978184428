import { EncryptionKey } from "@app/core/services";

export const formatEncryptionKey = (encryptionKey: EncryptionKey | null) => {
    if (!encryptionKey) return "";
    const nextEncryptionKey = {
        seatKeyId: String(encryptionKey.id),
        encryptionKey: encryptionKey.encryptionKey,
        integrityKey: encryptionKey.integrityKey,
        creationDate: encryptionKey.creationDate,
        status: encryptionKey.deleted ? "Expired" : "Active",
    };

    return JSON.stringify(nextEncryptionKey, null, 2);
};
