import { FC } from "react";
import { Card, Col, Row } from "antd";
import {
    CostModel,
    CostValue,
} from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/AffiliateSection/Fields";

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 12 };
const INPUTS_COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 };
export const AffiliateSection: FC = () => {
    return (
        <Card bordered={false}>
            <Row gutter={16}>
                <Col {...COL_SIZES}>
                    <Row gutter={16}>
                        <Col {...INPUTS_COL_SIZES}>
                            <CostModel />
                        </Col>
                        <Col {...INPUTS_COL_SIZES}>
                            <CostValue />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};
