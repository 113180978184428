import { FC } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { useHistory } from "react-router-dom";
import { BrandChannel } from "@app/core/services";

interface BrandEditPageHeaderProps {
    brandChannel: BrandChannel;
}

export const BrandChannelEditPageHeader: FC<BrandEditPageHeaderProps> = ({ brandChannel }) => {
    const history = useHistory();
    const onBack = (): void => history.goBack();
    return <PageHeader title={`Edit Channel: ${brandChannel.name}`} onBack={onBack} />;
};
