import { FC, UIEvent } from "react";
import { Select, SelectProps, Row, Col, Spin } from "antd";

export type LoadOnScrollSelectProps = Omit<SelectProps, "onPopupScroll"> & {
    hasMore: boolean;
    loadMore: () => void;
};

const isAtBottomOfMenu = (e: UIEvent) =>
    e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight < 1;

const MIN_OPTIONS_NO_SCROLL = 9;

export const LoadOnScrollSelect: FC<LoadOnScrollSelectProps> = ({ loading, options, loadMore, hasMore, ...rest }) => {
    return (
        <Select
            {...rest}
            loading={loading}
            options={
                loading
                    ? [
                          ...(options || []),
                          {
                              label: (
                                  <Row justify="center">
                                      <Col>
                                          <Spin size="small" />
                                      </Col>
                                  </Row>
                              ),
                              value: -100,
                              disabled: true,
                          },
                      ]
                    : options
            }
            onPopupScroll={(e) => {
                if (hasMore && isAtBottomOfMenu(e)) {
                    loadMore();
                }
            }}
            onDropdownVisibleChange={(open) => {
                if (open && (options || []).length < MIN_OPTIONS_NO_SCROLL && hasMore) {
                    loadMore();
                }
            }}
        />
    );
};
