export const CLASSIFICATIONS_INDUSTRY_NAME_INPUT_SDET = "classifications-industry-name-input";
export const CLASSIFICATIONS_INDUSTRY_CODE_INPUT_SDET = "classifications-industry-code-input";
export const CLASSIFICATIONS_INDUSTRY_DESCRIPTION_INPUT_SDET = "classifications-industry-description-input";
export const CLASSIFICATIONS_INDUSTRY_ADVERTISERS_READONLY_SDET = "classifications-industry-advertisers-readonly";
export const CLASSIFICATIONS_INDUSTRY_DELETE_BUTTON_SDET = "classifications-industry-delete-btn";

export const ID_FORM_NAME = "id";
export const NAME_FORM_NAME = "name";
export const CODE_FORM_NAME = "code";
export const DESCRIPTION_FORM_NAME = "description";
export const ADVERTISERS_FORM_NAME = "advertisers";

export const CLASSIFICATIONS_INDUSTRY_FORM_ITEMS = {
    ID: {
        hidden: true,
        name: ID_FORM_NAME,
    },
    NAME: {
        required: true,
        name: NAME_FORM_NAME,
        label: "Name",
        rules: [
            { required: true, message: "Name is required.", whitespace: true },
            { max: 70, message: "Name cannot be longer than 70 characters." },
        ],
    },
    CODE: {
        name: CODE_FORM_NAME,
        label: "Industry Code",
    },
    DESCRIPTION: {
        name: DESCRIPTION_FORM_NAME,
        label: "Description",
        rules: [{ max: 1024, message: "Description cannot be longer than 1024 characters." }],
    },
    ADVERTISERS: {
        name: ADVERTISERS_FORM_NAME,
        label: "Advertisers",
    },
};

export type ClassificationsIndustryFormValues = {
    [ID_FORM_NAME]: number | null;
    [NAME_FORM_NAME]: string;
    [CODE_FORM_NAME]?: string;
    [DESCRIPTION_FORM_NAME]?: string;
    [ADVERTISERS_FORM_NAME]?: number[];
};
