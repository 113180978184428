import { FC } from "react";
import { Row, Typography } from "antd";
import { PacedProgress, gray1 } from "@rubicon/antd-components";
import { formatNumber } from "@rubicon/utils";
import { AdSourcePacingData, DealPacing } from "@app/core/services";
import { Dashes } from "../Dashes";
import { getPacingStatusAttributes } from "./utils";

const { Text } = Typography;

interface Props {
    pacingData: AdSourcePacingData | DealPacing | null;
    showTooltip?: boolean;
}

const TooltipContent: FC<{
    percent: number | null;
    onPace: number;
    projected: number;
    paceLabel: string;
}> = ({ percent, onPace, projected, paceLabel }) => {
    return (
        <>
            <Row>
                <Text style={{ color: gray1 }}>Campaign {paceLabel}</Text>
            </Row>
            <Row>
                <Text style={{ color: gray1 }}>Projected: {formatNumber.asWhole(projected)}</Text>
            </Row>
            <Row>
                <Text style={{ color: gray1 }}>
                    On Pace: {formatNumber.asWhole(onPace)} ({formatNumber.asPercent(percent)})
                </Text>
            </Row>
        </>
    );
};

export const PacingProgressBar: FC<Props> = ({ pacingData, showTooltip = true }) => {
    if (!pacingData?.pacingProgress) {
        return <Dashes />;
    }

    const { expectedProgress, goalCompletionPct, pacingDeltaPercentage, pacingStatus, timeCompletionPct } =
        pacingData.pacingProgress;
    const { barColor, description } = getPacingStatusAttributes(pacingStatus?.id);
    const tooltipProps = showTooltip
        ? {
              title: (
                  <TooltipContent
                      percent={pacingDeltaPercentage}
                      onPace={expectedProgress}
                      projected={pacingData.todayImpCount}
                      paceLabel={description}
                  />
              ),
          }
        : null;

    return (
        <PacedProgress
            tooltipProps={tooltipProps}
            strokeColor={barColor}
            percent={goalCompletionPct}
            pacerPercent={timeCompletionPct}
        />
    );
};
