import { Form } from "antd";
import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { getAuthorizedExportStreamLabel } from "@app/features/inventory/helpers";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { useGetExternalDataStreamsQuery } from "@app/core/services/console/externalDataStreams";

const { useWatch } = Form;

interface UseExternalDataStreams {
    options: LabeledValue[];
    isFetching: boolean;
}

export const useExternalDataStreams = (): UseExternalDataStreams => {
    const { data, isFetching } = useGetExternalDataStreamsQuery();
    const externalDataStreams: LabeledValue[] = useMemo(
        () =>
            (data || []).map((stream) => ({
                label: getAuthorizedExportStreamLabel(stream),
                value: stream.id,
            })),
        [data]
    );
    const dataStreams = useWatch(INVENTORY_FORM_FIELDS.EXTERNAL_DATA_STREAMS.name) || [];
    const values = dataStreams.map((option) => option?.value);
    const options = externalDataStreams.filter((options) => !values.includes(options.value));

    return {
        options,
        isFetching,
    };
};
