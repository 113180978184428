import { ChangeEvent, useState, useMemo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import {
    selectSeatAdSourcesPacingSearchFilter,
    setSearchFilter,
} from "@app/features/seatAdSources/SeatAdSourcesPacingPage/reducer";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { TableSearchInputProps } from "@app/core/components";

export const useSeatAdSourcesPacingFilterSearch = (): TableSearchInputProps => {
    const dispatch = useAppDispatch();
    const { keyword } = useAppSelector(selectSeatAdSourcesPacingSearchFilter);
    const [value, setValue] = useState<string>(keyword || "");

    const handleSearch = useCallback(
        (v: string): void => {
            dispatch(setSearchFilter({ keyword: v.trim() }));
        },
        [dispatch]
    );

    const debouncedHandleSearch = useMemo(
        () => debounce((v: string) => handleSearch(v), FILTER_INPUT_DEBOUNCE_TIME),
        [handleSearch]
    );

    const onReset = (): void => {
        setValue("");
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setValue(e?.target?.value);
        debouncedHandleSearch(e?.target?.value);
    };

    return {
        dataSdet: "seat-ad-sources-pacing-filter-search",
        entityType: "Ad Source",
        onReset,
        onChange,
        value,
        style: { width: "300px" },
    };
};
