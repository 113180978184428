import { FC } from "react";
import { Card, Col, Collapse, Row } from "antd";
import { cardBodyStyle } from "@app/features/deals/DealForm";
import { COL_SIZES, GUTTER, RANGE_COL_SIZES } from "@app/features/deals/constants";
import {
    DealDspField,
    DealEndDateField,
    DealIdField,
    DealNameField,
    DealOriginField,
    DealPublisherEmailField,
    DealPublisherNameField,
    DealStartDateField,
    DealStatusField,
    DealTimeZoneField,
    DealTypeField,
    DealBuyerField,
    DealSharedSeatsField,
} from "@app/features/deals/DealForm/DealDemandFormSections/DealTermsSection/Fields";
import { DealSharedMarketplacesField } from "@app/features/deals/DealForm/DealDemandFormSections/DealTermsSection/Fields/DealSharedMarketplacesField";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import { useDealTermsSection } from "./useDealTermsSection";
import { css } from "@emotion/css";
import { colorLink } from "@rubicon/antd-components";

const classNameInternal = css`
    .ant-collapse-item > .ant-collapse-header {
        width: fit-content;
        padding-inline: 0 !important;
        color: ${colorLink};
    }
`;

export interface DealTermsSectionProps {
    dealOriginShown: boolean;
    publisherCredentialShown: boolean;
    internalFieldsShowm: boolean;
    mode: DealFormMode;
    createdWithBuyerSeats: boolean;
}

export const DealTermsSection: FC<DealTermsSectionProps> = ({
    dealOriginShown,
    publisherCredentialShown,
    internalFieldsShowm,
    mode,
    createdWithBuyerSeats,
}) => {
    const { handleChangeSyncDsp } = useDealTermsSection();

    return (
        <Card bordered={false} styles={{ body: cardBodyStyle }} data-sdet="deal-create-form-terms-section">
            <Row gutter={GUTTER} align="bottom">
                <Col {...COL_SIZES}>
                    <DealTypeField mode={mode} />
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <DealIdField mode={mode} />
                </Col>
                <Col {...COL_SIZES}>
                    <DealNameField mode={mode} />
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <DealDspField mode={mode} changeSyncDsp={handleChangeSyncDsp} />
                </Col>
                <Col {...COL_SIZES}>
                    <DealBuyerField mode={mode} createdWithBuyerSeats={createdWithBuyerSeats} />
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...RANGE_COL_SIZES}>
                    <DealStartDateField />
                </Col>
                <Col {...RANGE_COL_SIZES}>
                    <DealEndDateField />
                </Col>
                <Col {...COL_SIZES}>
                    <DealTimeZoneField mode={mode} />
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <DealStatusField />
                </Col>
                {dealOriginShown && (
                    <Col {...COL_SIZES}>
                        <DealOriginField />
                    </Col>
                )}
            </Row>
            {publisherCredentialShown && (
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <DealPublisherNameField />
                    </Col>
                    <Col {...COL_SIZES}>
                        <DealPublisherEmailField />
                    </Col>
                </Row>
            )}
            {internalFieldsShowm && (
                <Collapse
                    expandIconPosition="end"
                    className={classNameInternal}
                    ghost
                    items={[
                        {
                            key: "1",
                            children: (
                                <>
                                    <Row gutter={GUTTER}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
                                            <DealSharedSeatsField />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
                                            <DealSharedMarketplacesField />
                                        </Col>
                                    </Row>
                                </>
                            ),
                            label: "Internal",
                        },
                    ]}
                />
            )}
        </Card>
    );
};
