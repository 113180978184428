import { FC } from "react";
import { Form, Input } from "antd";
import { SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useStoreUrl } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/AppDetailsSection/Fields/StoreUrl/useStoreUrl";

export const StoreUrl: FC = () => {
    const { isOtherAppStore } = useStoreUrl();
    return (
        <Form.Item
            name={SUPPLY_FORM_FIELDS.STORE_URL.name}
            label={SUPPLY_FORM_FIELDS.STORE_URL.label}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 }}
            rules={[
                { required: isOtherAppStore, whitespace: true, message: SUPPLY_FORM_FIELDS.STORE_URL.rulesMessage },
            ]}
        >
            <Input placeholder={SUPPLY_FORM_FIELDS.STORE_URL.placeholder} disabled={isOtherAppStore} allowClear />
        </Form.Item>
    );
};
