import { Card } from "antd";
import { FC } from "react";
import { INVENTORY_CARD_BODY_STYLE } from "@app/features/inventory/constants";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { ChannelLabels } from "@app/features/inventory/HierarchyForms/PublisherChannelForm/PublisherChannelFormSections/AdvancedFeaturesSection/Sections/LabelsSection/Fields/ChannelLabels";

export const LabelsInternalOnly: FC = () => {
    return (
        <Card title="Internal Only" type="inner" bodyStyle={INVENTORY_CARD_BODY_STYLE}>
            <ChannelLabels {...CHANNEL_FORM_FIELDS.INTERNAL_LABELS} />
        </Card>
    );
};
