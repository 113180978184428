import { useMemo, useState } from "react";
import { Rule } from "antd/lib/form";
import { LabeledValue } from "antd/lib/select";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import { Mode } from "@app/features/seatAdSources/SeatAdSourcesForm/useAdSourceForm";
import { NetworkBuyerSeat, useGetNetworkBuyerSeatsQuery } from "@app/core/services/console/networks";
import { useLoadOnScroll } from "../LoadOnScrollSelect";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "../constants";
import { EXTERNAL_SYNC_BUYER_SEAT_IDS, GOOGLE_DV360_ID, TRADE_DESK_ID, XANDR_ID } from "./constants";

const BUYER_SEAT_PAGE_SIZE = 25;

interface UseBuyerSeatsSelect {
    hasMore: boolean;
    hasNoAvailableBuyers: boolean;
    isBuyerSelected: boolean;
    isDisabled: boolean;
    isExternalDsp: boolean;
    isFetching: boolean;
    hasValidationError: boolean;
    options: LabeledValue[];
    search: string;
    validationRules: Rule[];
    loadMore: () => void;
    handleSearch: (value: string) => void;
    buyerSeats: NetworkBuyerSeat[];
}

export const useBuyerSeatsSelect = (
    formMode: DealFormMode | Mode,
    selectedDspNetworkId: number | null,
    selectedBuyerSeatIds: number[] | null | undefined = [],
    createdWithBuyerSeats: boolean,
    isProgrammticGuaranteed: boolean,
    fetchAll = false
): UseBuyerSeatsSelect => {
    const [search, setSearch] = useState<string>("");
    const [keyword, setKeyword] = useState<string>("");
    const [pageByKey, setPageByKey] = useState({
        [`${selectedDspNetworkId}-`]: 1,
    });
    const key = `${selectedDspNetworkId}-${keyword}`;
    const page = pageByKey[key] || 1;

    const { data, isFetching, originalArgs } = useGetNetworkBuyerSeatsQuery(
        {
            id: String(selectedDspNetworkId),
            keyword,
            page: fetchAll ? undefined : page,
            max: fetchAll ? undefined : BUYER_SEAT_PAGE_SIZE,
        },
        { skip: !selectedDspNetworkId }
    );

    const {
        options: rawOptions,
        hasMore,
        loadMore,
    } = useLoadOnScroll(data, isFetching, key, originalArgs?.page || 1, data?.length === BUYER_SEAT_PAGE_SIZE, () =>
        setPageByKey((prev) => {
            const currentPage = prev[key] || 1;
            return {
                ...prev,
                [key]: currentPage + 1,
            };
        })
    );

    const options = useMemo(
        () =>
            (rawOptions || []).map((buyer) => ({
                label: `${buyer.code}${buyer.name ? ` [${buyer.name}]` : ""}`,
                value: JSON.stringify(buyer),
            })),
        [rawOptions]
    );

    const hasNoAvailableBuyers = Boolean(selectedDspNetworkId) && !isFetching && !options.length;

    const isDisabled = useMemo(() => {
        if (!selectedDspNetworkId) {
            return true;
        }
        return (
            formMode === "edit" && (!createdWithBuyerSeats || [TRADE_DESK_ID, XANDR_ID].includes(selectedDspNetworkId))
        );
    }, [selectedDspNetworkId, createdWithBuyerSeats, formMode]);

    const isBuyerSelected = Boolean(selectedBuyerSeatIds?.length);

    const isExternalDsp = useMemo(
        () => (selectedDspNetworkId ? EXTERNAL_SYNC_BUYER_SEAT_IDS.includes(selectedDspNetworkId) : false),
        [selectedDspNetworkId]
    );

    const isRequired = useMemo(() => formMode === "edit" && createdWithBuyerSeats, [formMode, createdWithBuyerSeats]);
    const isSingleBuyerOnly = useMemo(
        () =>
            (formMode === "create" || formMode === "edit") &&
            selectedDspNetworkId === GOOGLE_DV360_ID &&
            isBuyerSelected &&
            isProgrammticGuaranteed,
        [formMode, selectedDspNetworkId, isBuyerSelected, isProgrammticGuaranteed]
    );
    const selectedBuyerSeatIdsLength = selectedBuyerSeatIds ? selectedBuyerSeatIds.length : 0;
    const hasValidationError = useMemo(
        () => (isRequired && !isBuyerSelected) || (isSingleBuyerOnly && selectedBuyerSeatIdsLength > 1),
        [isRequired, isSingleBuyerOnly, isBuyerSelected, selectedBuyerSeatIdsLength]
    );
    const validationRules = useMemo(() => {
        return [
            isRequired
                ? {
                      required: true,
                      message: "A buyer is required. You can't go from BuyerDeal to DemandDeal at this time",
                  }
                : null,
            isSingleBuyerOnly
                ? {
                      validator: (_, value) => {
                          if (value.length === 1) {
                              return Promise.resolve();
                          }
                          return Promise.reject();
                      },
                      message: "You can only have 1 buyer for PG deals",
                  }
                : null,
        ].filter((rule) => rule) as Rule[];
    }, [isRequired, isSingleBuyerOnly]);

    const debouncedSetKeyword = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setKeyword]
    );

    const handleSearch = (value: string) => {
        setSearch(value);
        debouncedSetKeyword(value);
    };

    return {
        isDisabled,
        isExternalDsp,
        hasValidationError,
        validationRules,
        hasMore,
        hasNoAvailableBuyers,
        isFetching,
        options,
        search,
        loadMore,
        handleSearch,
        isBuyerSelected,
        buyerSeats: rawOptions,
    };
};
