import { Button, Dropdown, MenuProps, Space } from "antd";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { useCreateSeatStatsSnapshotMutation } from "@app/core/services/adStats/statsSnapshots";
import { useSeatAuthContext } from "@app/core/auth";
import { SnapshotsDrawer } from "../SnapshotsDrawer";
import { useSnapshotsDrawer } from "../SnapshotsDrawer/useSnapshotsDrawers";

export const SnapshotsControls = () => {
    const { context } = useSeatAuthContext();
    const [createSnapshot, { isLoading }] = useCreateSeatStatsSnapshotMutation();
    const { isOpen, handleCloseDrawer, handleOpenDrawer } = useSnapshotsDrawer();
    const items: MenuProps["items"] = [
        {
            label: "Take Snapshot",
            key: "takeSnapshot",
            onClick: async () => {
                await createSnapshot({ seatId: context?.id as number, message: "" });
            },
        },
        {
            label: "View Snapshots",
            key: "viewSnapshots",
            onClick: handleOpenDrawer,
        },
    ];
    return (
        <Space size="middle">
            <Dropdown menu={{ items }} disabled={isLoading}>
                <Button>
                    <Space>
                        Snapshot
                        {isLoading ? <LoadingOutlined spin /> : <DownOutlined />}
                    </Space>
                </Button>
            </Dropdown>
            {/* TODO: Commented out until download functionality is implemented */}
            {/* <Button icon={<DownloadOutlined />} /> */}
            <SnapshotsDrawer isOpen={isOpen} onClose={handleCloseDrawer} seat={context} />
        </Space>
    );
};
