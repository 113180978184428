import { Select } from "antd";
import { css } from "@emotion/css";
import { CaretDownOutlined } from "@ant-design/icons";
import { gray1 } from "@rubicon/antd-components";
import { LabeledValue } from "antd/lib/select";

interface Props<T> {
    color: string;
    onChange: (value: T) => void;
    options: LabeledValue[];
    value: T;
}

export const DropdownSelect = <T,>({ value, onChange, color, options }: Props<T>) => {
    return (
        <Select
            className={css(`
                .ant-select-selector {
                    background-color: ${color} !important;
                }

                .ant-select-selection-item {
                    color: ${gray1};
                }

                .ant-select-single.ant-select-open .ant-select-selection-item {
                    color: ${gray1} !important;
                }
            `)}
            bordered={false}
            options={options}
            value={value}
            onChange={onChange}
            size="small"
            suffixIcon={<CaretDownOutlined style={{ color: gray1, pointerEvents: "none" }} />}
        />
    );
};
