import { FC, useEffect } from "react";
import { Layout } from "antd";
import { BulkOperationsTable } from "./BulkOperationsTable";
import BulkOperationsPageHeader from "./BulkOperationsPageHeader";
import { useSelector } from "react-redux";
import { RootState } from "@app/core/store";
import { useDispatch } from "react-redux";
import { setCompleteWithErrorsMessage } from "../reducer";
import { BulkOperationExecutionCompleteWithErrorsModal } from "./BulkOperationExecutionCompleteWithErrorsModal";

export const BulkOperationsPage: FC = () => {
    const dispatch = useDispatch();
    const completeWithErrorsMessage = useSelector((state: RootState) => state.bulkOperations.completeWithErrorsMessage);

    useEffect(
        () => () => {
            completeWithErrorsMessage && dispatch(setCompleteWithErrorsMessage(null));
        },
        [completeWithErrorsMessage, dispatch]
    );

    return (
        <>
            <BulkOperationsPageHeader />
            <Layout.Content>
                <BulkOperationsTable />
                {completeWithErrorsMessage ? (
                    <BulkOperationExecutionCompleteWithErrorsModal errorMessage={completeWithErrorsMessage} />
                ) : null}
            </Layout.Content>
        </>
    );
};
