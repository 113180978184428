import { ArrowLeftOutlined } from "@ant-design/icons";
import { fixedBottomButtonsStyle } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Button, Space } from "antd";
import { FC } from "react";
import { useHistory, useParams } from "react-router-dom";

interface Props {
    goToPrev: () => void;
    onClickRun: () => void;
    isLoading: boolean;
}

export const ConfirmationStepBottomButtons: FC<Props> = ({ goToPrev, onClickRun, isLoading }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();

    return (
        <div className={fixedBottomButtonsStyle}>
            <Space direction="horizontal">
                <Button data-sdet="step3-previous-button" onClick={goToPrev}>
                    <ArrowLeftOutlined />
                    Previous
                </Button>
                <Button data-sdet="step3-run-button" type="primary" loading={isLoading} onClick={onClickRun}>
                    Run
                </Button>
                <Button
                    data-sdet="step3-cancel-button"
                    onClick={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BULK_OPERATIONS(seatId))}
                >
                    Cancel
                </Button>
            </Space>
        </div>
    );
};
