import { useState } from "react";
import { GeoTarget, useValidatePostalCodesMutation } from "@app/core/services/console";

const getUniqueId = (value: GeoTarget) => `${value.geo.id}-${value.postalCode || ""}`;

export const useBulkPostalCodeDrawerButton = (onChange: (value: GeoTarget[]) => void, values: GeoTarget[]) => {
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState("");
    const [isLoadingAdd, setIsLoadingAdd] = useState(false);
    const [isLoadingReplace, setIsLoadingReplace] = useState(false);
    const [countryCode, setCountryCode] = useState("US");

    const [validatePostalCodes] = useValidatePostalCodesMutation();

    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);
    const handleChangeValue = (v) => setValue(v);

    const handleReplace = async () => {
        setIsLoadingReplace(true);
        const postalCodes = await validatePostalCodes({ body: value.split("\n"), countryCode }).unwrap();

        onChange(postalCodes);
        setValue("");
        setIsOpen(false);
        setIsLoadingReplace(false);
    };

    const handleAdd = async () => {
        setIsLoadingAdd(true);
        const postalCodes = await validatePostalCodes({ body: value.split("\n"), countryCode }).unwrap();
        const existingValuesByValue = values.reduce((byValue, value) => {
            const id = getUniqueId(value);
            byValue[id] = value;
            return byValue;
        }, {});
        const combinedValueByValue = postalCodes.reduce((byValue, value) => {
            const id = getUniqueId(value);
            byValue[id] = value;
            return byValue;
        }, existingValuesByValue);

        const combinedValues = Object.values<GeoTarget>(combinedValueByValue);

        onChange(combinedValues);
        setValue("");
        setIsOpen(false);
        setIsLoadingAdd(false);
    };

    const handleChangeCountryCode = (countryCode: string) => {
        setCountryCode(countryCode);
    };

    return {
        countryCode,
        handleAdd,
        handleChangeCountryCode,
        handleChangeValue,
        handleClose,
        handleOpen,
        handleReplace,
        isLoadingAdd,
        isLoadingReplace,
        isOpen,
        value,
    };
};
