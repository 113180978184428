import { ROUTES } from "@app/core/routing";
import { FC } from "react";
import { Switch, Route } from "react-router-dom";
import { InventorySeatPage } from "./InventorySeatPage";
import InventorySeatEditPage from "./InventorySeatEditPage/InventorySeatEditPage";

const InventorySeatSwitch: FC = () => (
    <Switch>
        <Route
            exact
            path={[
                ROUTES.SEAT_INVENTORY_SEAT,
                ROUTES.SEAT_INVENTORY_SEAT_EDIT_INTERNAL,
                ROUTES.SEAT_INVENTORY_SEAT_INTERNAL_DEFINITION,
            ]}
        >
            <InventorySeatPage />
        </Route>
        <Route exact path={ROUTES.SEAT_INVENTORY_SEAT_EDIT}>
            <InventorySeatEditPage />
        </Route>
    </Switch>
);

export default InventorySeatSwitch;
