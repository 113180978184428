import { Select } from "antd";
import { FC } from "react";
import { SelectProps } from "antd/lib/select";
import { TagRender } from "@app/core/components";
import { useSeatAdvertiserDomainFilterList } from "@app/features/inventory/components/Fields/SeatAdvertiserDomainFilterList/useSeatAdvertiserDomainFilterList";

export const SeatAdvertiserDomainFilterList: FC<SelectProps> = (props) => {
    const { isFetching, options } = useSeatAdvertiserDomainFilterList();

    return (
        <Select
            {...props}
            showSearch
            allowClear
            labelInValue
            mode="multiple"
            options={options}
            loading={isFetching}
            tagRender={TagRender}
            optionFilterProp="label"
            style={{ width: "100%" }}
        />
    );
};
