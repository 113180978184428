import { FC } from "react";
import { Card } from "antd";
import { Domain, PartnerDomain } from "@app/features/inventory/HierarchyForms/SupplyForm/Fields";
import { ExternalName } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/SiteDetailsSection/Fields";

export const SiteDetailsSection: FC = () => {
    return (
        <Card bordered={false}>
            <ExternalName />
            <Domain />
            <PartnerDomain />
        </Card>
    );
};
