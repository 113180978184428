import { Form, FormInstance } from "antd";
import { useParams } from "react-router-dom";
import { createContext, FC, useContext } from "react";
import { HierarchyFormsMode } from "@app/features/inventory/HierarchyForms/constants";
import { BrandForm } from "@app/features/inventory/HierarchyForms/BrandForm/useBrandForm";
import { Brand, Publisher, Seat, useGetPublisherByIdQuery, useGetSeatSelfQuery } from "@app/core/services";

export interface BrandFormInstanceProps {
    brand?: Brand;
    mode: HierarchyFormsMode;
}

type BrandFormInstanceContextType = BrandFormInstanceProps & {
    seatId: string;
    seat: Seat | null;
    isLoading: boolean;
    publisherId: number;
    form: FormInstance<BrandForm>;
    useWatch: typeof Form.useWatch;
    publisher: Publisher | undefined;
};

const BrandFormInstanceContext = createContext<BrandFormInstanceContextType | null>(null);

export const BrandFormInstance: FC<BrandFormInstanceProps> = ({ children, ...rest }) => {
    const [form] = Form.useForm<BrandForm>();
    const { seatId, publisherId } = useParams<{ seatId: string; publisherId: string }>();
    const { data: seatSelf, isFetching: isLoadingSeat } = useGetSeatSelfQuery(seatId);
    const { data: publisher, isFetching } = useGetPublisherByIdQuery(Number(publisherId));
    const isLoading: boolean = isFetching || isLoadingSeat;
    return (
        <BrandFormInstanceContext.Provider
            value={{
                form,
                seatId,
                publisher,
                isLoading,
                useWatch: Form.useWatch,
                seat: seatSelf?.seat || null,
                publisherId: Number(publisherId),
                ...rest,
            }}
        >
            {children}
        </BrandFormInstanceContext.Provider>
    );
};
export const useBrandFormInstance = (): BrandFormInstanceContextType => {
    const context: BrandFormInstanceContextType | null = useContext(BrandFormInstanceContext);
    if (!context) throw new Error("useBrandFormInstance must be used within a BrandFormInstance");
    return context;
};
