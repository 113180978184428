import { consoleApi, REGIONS_TAG } from "./console";

export interface Region {
    creationDate: string;
    entityType: string;
    id: number;
    name: string;
    sort: number;
    updateDate: string;
}

export const regionsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getRegions: builder.query<Region[], void>({
            query: () => "regions",
            providesTags: [REGIONS_TAG],
        }),
    }),
    overrideExisting: false,
});

export const { useGetRegionsQuery } = regionsApi;
