import { AdSourceTypeIds, PriceModelEligibleAdSourceTypeIds, PricingModels } from "../../constants";

const AdditionalPriceModelDefaultedAdSourceTypeIds = Object.freeze([
    AdSourceTypeIds.AUCTION_PRICE,
    AdSourceTypeIds.LINEAR_INVITE_ONLY_AUCTION,
    AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED,
    AdSourceTypeIds.SERVER_SIDE_TAG_NON_GUARANTEED,
    AdSourceTypeIds.FALLBACK_TAG,
    AdSourceTypeIds.CURATOR_MARKETPLACE,
    AdSourceTypeIds.MARKETPLACE,
    AdSourceTypeIds.OPEN_AUCTION,
]);

interface ParsePriceModelFieldsToApi {
    priceModel: { id: number } | null;
    resetFrequency: null;
    tierResetMode: null;
    tierOverflowAllowed: null;
    dealPrices: null;
}

export const parsePriceModelFieldsToApi = (adSourceTypeId: number | undefined): ParsePriceModelFieldsToApi => ({
    priceModel:
        PriceModelEligibleAdSourceTypeIds.includes(Number(adSourceTypeId)) ||
        AdditionalPriceModelDefaultedAdSourceTypeIds.includes(Number(adSourceTypeId))
            ? { id: PricingModels.FIXED_PRICE }
            : null,
    resetFrequency: null,
    tierResetMode: null,
    tierOverflowAllowed: null,
    dealPrices: null,
});
