import { FC } from "react";
import { Card, Col, Form, Row, Skeleton } from "antd";
import { css } from "@emotion/css";
import { Metrics } from "@rubicon/antd-components";
import { ChartMetricsFilters } from "../ChartMetricsFilters";
import { TimeFrameFilter } from "../TimeFrameFilter";
import { ViewFilter } from "../ViewFilter";
import { PerformanceQuickViewChart } from "../PerformanceQuickViewChart";
import { useReportBySeatId } from "./useReportsBySeatId";
import { useCurrencyConversion } from "../useCurrencyConversion";
import { useAppSelector } from "@app/core/store";
import { getDateLabel } from "../helpers";
import { formatNumber } from "@rubicon/utils";
import { useAuthClient } from "@app/core/authClient";
import { RevenueTypes } from "@app/core/clients/console";
import { useSeatAuthContext } from "@app/core/auth";
import { selectDashboardRevenueType, selectDashboardTimeFrameFilter } from "../reducer";

const getPercentageDifferenceSuffix = (percentageDifference) => {
    if (isNaN(percentageDifference)) {
        return {};
    }

    return {
        suffixColor: percentageDifference < 0 ? ("red" as const) : ("green" as const),
        suffix: `${percentageDifference > 0 ? "+" : ""}${percentageDifference}%`,
    };
};

export const PerformanceQuickViewMetrics: FC = () => {
    const { context } = useSeatAuthContext();
    const { session } = useAuthClient();
    const { currencyConfig } = useCurrencyConversion();
    const revenueType = useAppSelector(selectDashboardRevenueType);
    const period = useAppSelector(selectDashboardTimeFrameFilter);
    const timeZone = session?.user?.userPreferenceMap.timezone as string;

    const {
        performanceMetrics: {
            acceptRateThisPeriod,
            acceptRateLastPeriod,
            acceptRatePercentageDifference,
            revenueThisPeriod,
            revenueLastPeriod,
            revenuePercentageDifference,
            revenueCPMThisPeriod,
            revenueCPMLastPeriod,
            revenueCPMPercentageDifference,
            requestsThisPeriod,
            requestsLastPeriod,
            requestsPercentageDifference,
            opportunitiesThisPeriod,
            opportunitiesLastPeriod,
            opportunitiesPercentageDifference,
            fillsThisPeriod,
            fillsLastPeriod,
            fillsPercentageDifference,
            fillRateThisPeriod,
            fillRateLastPeriod,
            fillRatePercentageDifference,
            impressionsThisPeriod,
            impressionsLastPeriod,
            impressionsPercentageDifference,
            useRateThisPeriod,
            useRateLastPeriod,
            useRatePercentageDifference,
        },
        isLoading,
        metricOne,
        metricTwo,
        metricOneType,
        metricTwoType,
    } = useReportBySeatId();

    const fourthStatsCell = context?.adPodEnabled
        ? {
              title: "Opportunities",
              value: opportunitiesThisPeriod,
              precision: 0,
              loading: isLoading,
              description: (
                  <Skeleton loading={isLoading} paragraph={{ rows: 1 }} round={true}>
                      {formatNumber.asNumber(opportunitiesLastPeriod)}
                  </Skeleton>
              ),
              ...getPercentageDifferenceSuffix(opportunitiesPercentageDifference),
          }
        : {
              title: "Accept Rate",
              value: `${acceptRateThisPeriod}%`,
              precision: 0,
              loading: isLoading,
              description: (
                  <Skeleton loading={isLoading} paragraph={{ rows: 1 }} round={true}>
                      {formatNumber.asNumber(acceptRateLastPeriod)}%
                  </Skeleton>
              ),
              ...getPercentageDifferenceSuffix(acceptRatePercentageDifference),
          };

    const metrics = [
        {
            className: css`
                border-left: 0 !important;
            `,
            title: `est. ${revenueType === RevenueTypes.NET_REVENUE ? "Net" : "Gross"} Rev`,
            prefix: currencyConfig?.symbol || "",
            value: revenueThisPeriod,
            loading: isLoading,
            description: (
                <Skeleton loading={isLoading} paragraph={{ rows: 1 }} round={true}>
                    {formatNumber.asMoney(revenueLastPeriod, currencyConfig?.code)}
                </Skeleton>
            ),
            ...getPercentageDifferenceSuffix(revenuePercentageDifference),
        },
        {
            title: `est. ${revenueType === RevenueTypes.NET_REVENUE ? "Net" : "Gross"} eCPM`,
            prefix: currencyConfig?.symbol || "",
            value: revenueCPMThisPeriod,
            loading: isLoading,
            description: (
                <Skeleton loading={isLoading} paragraph={{ rows: 1 }} round={true}>{`${
                    currencyConfig?.symbol || ""
                }${revenueCPMLastPeriod}`}</Skeleton>
            ),
            ...getPercentageDifferenceSuffix(revenueCPMPercentageDifference),
        },
        {
            title: context?.adPodEnabled ? "SSP Requests" : "Requests",
            value: requestsThisPeriod,
            precision: 0,
            loading: isLoading,
            description: (
                <Skeleton loading={isLoading} paragraph={{ rows: 1 }} round={true}>
                    {formatNumber.asNumber(requestsLastPeriod)}
                </Skeleton>
            ),
            ...getPercentageDifferenceSuffix(requestsPercentageDifference),
        },
        fourthStatsCell,
        {
            className: css`
                border-left: 0 !important;
            `,
            title: "Fills",
            value: fillsThisPeriod,
            precision: 0,
            loading: isLoading,
            description: (
                <Skeleton loading={isLoading} paragraph={{ rows: 1 }} round={true}>
                    {formatNumber.asNumber(fillsLastPeriod)}
                </Skeleton>
            ),
            ...getPercentageDifferenceSuffix(fillsPercentageDifference),
        },
        {
            title: "Fill Rate",
            value: `${fillRateThisPeriod}%`,
            precision: 0,
            loading: isLoading,
            description: (
                <Skeleton loading={isLoading} paragraph={{ rows: 1 }} round={true}>{`${fillRateLastPeriod}%`}</Skeleton>
            ),
            ...getPercentageDifferenceSuffix(fillRatePercentageDifference),
        },
        {
            title: "Impressions",
            value: impressionsThisPeriod,
            precision: 0,
            loading: isLoading,
            description: (
                <Skeleton loading={isLoading} paragraph={{ rows: 1 }} round={true}>
                    {formatNumber.asNumber(impressionsLastPeriod)}
                </Skeleton>
            ),
            ...getPercentageDifferenceSuffix(impressionsPercentageDifference),
        },
        {
            title: "Use Rate",
            value: `${useRateThisPeriod}%`,
            precision: 0,
            loading: isLoading,
            description: (
                <Skeleton loading={isLoading} paragraph={{ rows: 1 }} round={true}>{`${useRateLastPeriod}%`}</Skeleton>
            ),
            ...getPercentageDifferenceSuffix(useRatePercentageDifference),
        },
    ];

    return (
        <Card
            bodyStyle={{ padding: 0 }}
            className={css`
                border: 1px solid #d9d9d9;
                background-color: #fafafa;

                .ant-card-head {
                    .ant-card-head-wrapper {
                        flex-wrap: wrap;

                        .ant-card-head-title {
                            padding: 16px 0;
                            flex-basis: 100%;
                        }
                        .ant-card-extra {
                            padding: 16px 0;
                            margin-left: 0;
                            width: 100%;
                        }
                    }
                }
            `}
            bordered={true}
            title="Performance Quick View"
            extra={[
                <Form key="form">
                    <Row gutter={[16, 0]} align="middle">
                        {context?.adPodEnabled && (
                            <Col xs={6}>
                                <Form.Item style={{ marginBottom: 0 }} label="View">
                                    <ViewFilter style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        )}
                        <Col xs={6}>
                            <Form.Item style={{ marginBottom: 0 }} label="Time Frame">
                                <TimeFrameFilter style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col
                            className={css`
                                margin-left: auto;
                            `}
                            data-sdet="dateRange"
                        >
                            {getDateLabel(period, timeZone)}
                        </Col>
                    </Row>
                </Form>,
            ]}
        >
            <Metrics items={metrics} />
            <Card
                className={css`
                    border-top: 1px solid #d9d9d9;
                    .ant-card-head {
                        border-bottom: 0;
                    }
                `}
                bordered={false}
                title={<ChartMetricsFilters />}
            >
                <PerformanceQuickViewChart
                    metricOne={metricOne}
                    metricTwo={metricTwo}
                    metricOneType={String(metricOneType)}
                    metricTwoType={String(metricTwoType)}
                    isLoading={isLoading}
                />
            </Card>
        </Card>
    );
};
