import { SeatAdSourcesType } from "@app/core/services";
import { AdSourceTypes, AD_SOURCE_FIELDS, AD_SOURCE_TYPES_NEXT_UI_NAMES } from "@app/features/seatAdSources/constants";
import { useWatch } from "antd/lib/form/Form";

export const useIsFieldVisible = () => {
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);

    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSourceType?.name] ?? adSourceType?.name ?? null;

    switch (adSourceTypeName) {
        case null:
        case AdSourceTypes.TREMOR_NETWORK:
        case AdSourceTypes.PROGRAMATIC_GUARANTEED:
        case AdSourceTypes.CONDITIONAL_PROGRAMATIC_GUARANTEED:
        case AdSourceTypes.FIXED_PRICE:
        case AdSourceTypes.AUCTION_PRICE:
        case AdSourceTypes.OPEN_AUCTION:
        case AdSourceTypes.LINEAR_FIXED_PRICE:
        case AdSourceTypes.LINEAR_AUCTION_PRICE:
        case AdSourceTypes.SERVER_SIDE_TAG_GUARANTEED:
        case AdSourceTypes.CLIENT_SIDE_TAG_GUARANTEED:
        case AdSourceTypes.SERVER_SIDE_DYNAMIC_PRICE:
        case AdSourceTypes.SERVER_SIDE_TAG_NON_GUARANTEED:
        case AdSourceTypes.FALLBACK_TAG:
        case AdSourceTypes.PREBID_AD_SOURCE:
            return true;
        default:
            return false;
    }
};
