import { useAppDispatch, useAppSelector } from "@app/core/store";
import {
    SeatAdSourcesListDisplayMode,
    selectSeatAdSourcesListDisplayMode,
    switchSeatAdSourcesListDisplayMode,
} from "./reducer";

interface UseSeatAdSourcesListViewRadio {
    activeDisplayMode: SeatAdSourcesListDisplayMode;
    toggleDisplayMode: () => void;
}

export const useSeatAdSourcesListPage = (): UseSeatAdSourcesListViewRadio => {
    const dispatch = useAppDispatch();
    const activeDisplayMode = useAppSelector(selectSeatAdSourcesListDisplayMode);

    const toggleDisplayMode = () => {
        dispatch(switchSeatAdSourcesListDisplayMode());
    };

    return {
        activeDisplayMode,
        toggleDisplayMode,
    };
};
