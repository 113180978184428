import { FC } from "react";
import { Space, Divider } from "antd";
import { BrandSafetyHierarchicalHelp } from "../BrandSafetyHierarchicalHelp";
import { StyledButtonLink } from "@app/core/components";

interface Props {
    expandAll: () => void;
    collapseAll: () => void;
}

export const BrandSafetyHeaderControls: FC<Props> = ({ expandAll, collapseAll }) => {
    return (
        <Space split={<Divider type="vertical" />}>
            <StyledButtonLink onClick={expandAll} type="link">
                Expand All
            </StyledButtonLink>
            <StyledButtonLink onClick={collapseAll} type="link">
                Collapse All
            </StyledButtonLink>
            <BrandSafetyHierarchicalHelp />
        </Space>
    );
};
