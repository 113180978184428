import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { useGetAppStoreQuery } from "@app/core/services";

interface UseAppStoreSelect {
    isFetching: boolean;
    options: LabeledValue[];
}

export const useAppStoreSelect = (): UseAppStoreSelect => {
    const { data = [], isFetching } = useGetAppStoreQuery();

    const options = useMemo(() => (data || []).map((store) => ({ value: store.id, label: store.name })), [data]);
    return {
        isFetching,
        options,
    };
};
