import { FC } from "react";
import { Button, Col, Row, Space, Table, Tooltip, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { CustomPixelsTableFilterSearch } from "./CustomPixelsTableFilterSearch";
import { formatDateInUtc } from "@app/core/utils";
import { YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE } from "@app/core/components/constants";
import { useCustomPixelsTable } from "./useCustomPixelsTable";
import { useUserAccess } from "@app/core/auth";
import { Link, Route, useHistory, useLocation, useParams } from "react-router-dom";
import { ROUTES, ROUTE_FORMATTERS } from "@app/core/routing";
import { useCustomPixelDeleteModal } from "../../useCustomPixelDeleteModal";
import { CustomPixelDeleteModal } from "../../CustomPixelDeleteModal";
import { SeatThirdPartyPixel } from "@app/core/services";
import { useSelector } from "react-redux";
import { selectCustomPixelsSort } from "../reducer";
import { CustomPixelDetailsDrawer } from "./CustomPixelDetailsDrawer";
import { brandCobalt } from "@rubicon/antd-components";
import { ColumnsType } from "antd/es/table";

const getBooleanText = (value: boolean) => (value ? "Yes" : "No");

export const CustomPixelsTable: FC = () => {
    const { isLoading, customPixels, handleChange } = useCustomPixelsTable();
    const { canEditSeat } = useUserAccess();
    const history = useHistory();
    const { search: queryString } = useLocation();
    const { seatId } = useParams<{ seatId: string }>();
    const { handleOpen: handleModalOpen, ...restModalProps } = useCustomPixelDeleteModal();
    const { sortBy, sortOrder } = useSelector(selectCustomPixelsSort);

    return (
        <>
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
                <Row>
                    <Col xs={6}>
                        <CustomPixelsTableFilterSearch />
                    </Col>
                </Row>
                <Table
                    loading={isLoading}
                    size="middle"
                    dataSource={customPixels}
                    onChange={handleChange}
                    rowKey="id"
                    scroll={{ x: "max-content" }}
                    showSorterTooltip={false}
                    pagination={false}
                    sortDirections={["descend", "ascend", "descend"]}
                    columns={
                        [
                            {
                                key: "name",
                                dataIndex: "name",
                                title: "Name",
                                render: (name, { id }) => (
                                    <Link
                                        replace
                                        component={Typography.Link} // To break word
                                        style={{ color: brandCobalt }}
                                        to={{
                                            pathname: ROUTE_FORMATTERS.SEAT_CONTROLS_CUSTOM_PIXELS_DETAILS_DRAWER(
                                                seatId,
                                                id
                                            ),
                                            search: queryString,
                                        }}
                                    >
                                        {name}
                                    </Link>
                                ),
                                sorter: (a, b) => a.name.localeCompare(b.name),
                                sortOrder: sortBy === "name" ? sortOrder : null,
                                width: 300,
                            },
                            {
                                key: "addToAdSourceByDefault",
                                dataIndex: "addToAdSourceByDefault",
                                title: "Add to Ad Source by Default",
                                render: (addToAdSourceByDefault) => getBooleanText(addToAdSourceByDefault),
                                sorter: (a, b) =>
                                    getBooleanText(a.addToAdSourceByDefault).localeCompare(
                                        getBooleanText(b.addToAdSourceByDefault)
                                    ),
                                sortOrder: sortBy === "addToAdSourceByDefault" ? sortOrder : null,
                            },
                            {
                                key: "type",
                                dataIndex: "type",
                                title: "Type",
                                render: (type) => type.name,
                                sorter: (a, b) => a.type.name.localeCompare(b.type.name),
                                sortOrder: sortBy === "type" ? sortOrder : null,
                            },
                            {
                                key: "value",
                                dataIndex: "value",
                                // Typography helps break word
                                render: (value) => <Typography.Text>{value}</Typography.Text>,
                                title: "Pixel URL",
                                sorter: (a, b) => a.value.localeCompare(b.value),
                                width: 300,
                                sortOrder: sortBy === "value" ? sortOrder : null,
                            },
                            {
                                key: "creationDate",
                                dataIndex: "creationDate",
                                title: "Date Created",
                                render: (creationDate) =>
                                    formatDateInUtc(creationDate, YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE),
                                sorter: (a, b) =>
                                    new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime(),
                                sortOrder: sortBy === "creationDate" ? sortOrder : null,
                            },
                            {
                                key: "updateDate",
                                dataIndex: "updateDate",
                                title: "Date Updated",
                                render: (updateDate) =>
                                    formatDateInUtc(updateDate, YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE),
                                sorter: (a, b) => new Date(a.updateDate).getTime() - new Date(b.updateDate).getTime(),
                                sortOrder: sortBy === "updateDate" ? sortOrder : null,
                            },
                            canEditSeat && {
                                key: "actions",
                                dataIndex: "",
                                fixed: "right",
                                title: "Actions",
                                render: (_, { id, name }) => (
                                    <Button.Group>
                                        <Tooltip title="Edit">
                                            <Button
                                                onClick={() =>
                                                    history.push(
                                                        ROUTE_FORMATTERS.SEAT_CONTROLS_CUSTOM_PIXELS_EDIT(seatId, id)
                                                    )
                                                }
                                                icon={<EditOutlined />}
                                            />
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <Button
                                                onClick={() => handleModalOpen({ id, name })}
                                                icon={<DeleteOutlined />}
                                            />
                                        </Tooltip>
                                    </Button.Group>
                                ),
                                sorter: false,
                            },
                        ].filter(Boolean) as ColumnsType<SeatThirdPartyPixel>
                    }
                />
            </Space>
            <CustomPixelDeleteModal {...restModalProps} />
            <Route path={[ROUTES.SEAT_CONTROLS_CUSTOM_PIXELS_DETAILS_DRAWER]}>
                <CustomPixelDetailsDrawer />
            </Route>
        </>
    );
};
