import { FC } from "react";
import { Form, Typography } from "antd";

import { Dashes } from "@app/core/components";
import { SeatAdvertiserDomainFilterListDef } from "@app/core/services/console/seatAdvertiserDomainFilterListDefs";

interface Props {
    seatAdvertiserDomainFilterListDefs: SeatAdvertiserDomainFilterListDef[];
}

export const BlockedAdvertiserDomainListFormItem: FC<Props> = ({ seatAdvertiserDomainFilterListDefs }) => {
    return (
        <Form.Item label="Blocked Advertiser Domain Lists">
            {seatAdvertiserDomainFilterListDefs.length ? (
                seatAdvertiserDomainFilterListDefs.map(({ name, id }) => (
                    <Typography.Text key={id}>{name} </Typography.Text>
                ))
            ) : (
                <Dashes />
            )}
        </Form.Item>
    );
};
