import { useUserAccess } from "@app/core/auth";
import { useAdUnitFormInstance } from "../AdUnitFormInstance";

interface UseGeneralSection {
    isInternalOnly: boolean;
    isEditMode: boolean;
    hasCode: boolean;
}

export const useGeneralSection = (): UseGeneralSection => {
    const { mode } = useAdUnitFormInstance();
    const isCreateMode: boolean = mode === "create";
    const isCopyMode: boolean = mode === "copy";
    const isEditMode: boolean = mode === "edit";
    const { isSysAdmin, isPubAcctMgr, hasSeatWriteAccess } = useUserAccess();
    const isInternalOnly: boolean = isSysAdmin || isPubAcctMgr;
    const hasCode = (hasSeatWriteAccess && (isCreateMode || isCopyMode)) || !isInternalOnly;

    return {
        isInternalOnly,
        isEditMode,
        hasCode,
    };
};
