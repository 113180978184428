import { FC } from "react";
import { css } from "@emotion/css";
import { Button, Flex, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useDealDetailsDrawerContent } from "@app/features/deals/DealDetailsDrawer/useDealDetailsDrawerContent";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import { DealDetailsDrawerTitles, dealDetailsHelpKeys } from "@app/features/deals/constants";
import { gray7, gray8 } from "@rubicon/antd-components";

interface Props {
    dealName: string;
    secondaryDetailsView: DealDetailsDrawerTitles | null;
}
export const DealDetailsDrawerNestedTitle: FC<Props> = ({ secondaryDetailsView, dealName }) => {
    const { switchToDefaultDetailsView } = useDealDetailsDrawerContent();
    return (
        <Flex justify="space-between">
            <Flex data-sdet="seat-ad-sources-details-drawer-nested-title">
                <Button
                    data-sdet="seat-ad-sources-details-drawer-nested-title-back-button"
                    type="text"
                    onClick={switchToDefaultDetailsView}
                    className={css`
                        color: ${gray7};
                        padding-left: 0;

                        &:hover {
                            color: ${gray8};
                            background-color: transparent;
                        }
                    `}
                >
                    <ArrowLeftOutlined
                        className={css`
                            svg {
                                width: 1em;
                                height: 1em;
                            }
                        `}
                    />
                </Button>
                <Typography.Title level={5} style={{ fontWeight: "normal", marginTop: "0.25em", marginBottom: "0" }}>
                    {dealName}
                    <Typography.Text
                        style={{ fontWeight: "bold", color: "black" }}
                    >{` / ${secondaryDetailsView}`}</Typography.Text>
                </Typography.Title>
            </Flex>
            <HelpKeysDrawer
                panelLabel={secondaryDetailsView as string}
                definitionsFields={dealDetailsHelpKeys[secondaryDetailsView as string]}
            />
        </Flex>
    );
};
