import { FormInstance } from "antd";
import { useHistory } from "react-router-dom";
import { UseLoginFormData } from "@app/features/login/LoginForm/useLoginFormData";
import { ROUTES } from "@app/core/routing";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useEmailLocalStorage } from "./useEmailLocalStorage";
import { useAuthClient } from "@app/core/authClient";

enum EMAIL_FORM_FIELDS {
    Email = "email",
}

export type EmailFormFiedsValueType = {
    [key in EMAIL_FORM_FIELDS]: string;
};

interface UseEmailForm {
    form: FormInstance;
    isRememberMe: boolean;
    handleFormChange: (values: EmailFormFiedsValueType) => void;
    handleSubmit: () => void;
    handleChangeCheckbox: (e: CheckboxChangeEvent) => void;
    EMAIL_FORM_FIELDS: typeof EMAIL_FORM_FIELDS;
}

export const useEmailForm = (loginFormData: UseLoginFormData): UseEmailForm => {
    const { setFormData } = loginFormData;
    const [form] = useForm();
    const [isRememberMe, setIsRememberMe] = useState<boolean>(false);
    const history = useHistory();
    const { email, removeEmail, setEmail } = useEmailLocalStorage(EMAIL_FORM_FIELDS.Email);
    const { isLoggedIn } = useAuthClient();

    useEffect(() => {
        const searchHash = window.location.hash.split("?")[1];
        const searchNative = window.location.search;
        const search = searchNative || (searchHash ? `?${searchHash}` : "");

        if (isLoggedIn) {
            history.replace({ pathname: ROUTES.LOGIN_FORM_CONTEXT_STEP, search });
        }
    }, [isLoggedIn, history]);

    const handleFormChange = (values: EmailFormFiedsValueType): void => {
        for (const [key, value] of Object.entries(values)) {
            setFormData((prevFormData) => ({ ...prevFormData, [key]: value }));
        }
    };

    const handleSubmit = (): void => {
        if (isRememberMe) {
            setEmail(form.getFieldValue(EMAIL_FORM_FIELDS.Email));
        }

        history.push(`${ROUTES.LOGIN_FORM_PASSWORD_STEP}${history.location.search}`);
    };

    const handleChangeCheckbox = (e: CheckboxChangeEvent) => {
        if (!e.target.checked) {
            removeEmail();
        }
        setIsRememberMe(e.target.checked);
    };

    useEffect(() => {
        if (email) {
            setFormData((prevFormData) => ({ ...prevFormData, [EMAIL_FORM_FIELDS.Email]: email }));
            setIsRememberMe(true);
            form.setFieldValue(EMAIL_FORM_FIELDS.Email, email);
        }
    }, [form, setFormData, email]);

    return {
        form,
        isRememberMe,
        handleSubmit,
        handleFormChange,
        handleChangeCheckbox,
        EMAIL_FORM_FIELDS,
    };
};
