import { FC, useEffect } from "react";
import { Route, RouteProps, useHistory, useLocation } from "react-router-dom";
import { useAuthClient } from "../authClient";
import { ROUTES } from "./routes";

const _getLoginUrl = (pathname: string, search: string, isLoggedOutByUser: boolean): string => {
    if (isLoggedOutByUser) {
        return ROUTES.LOGIN_MAGNITE;
    }

    const [, searchInHash = ""] = pathname.split("?");
    const eitherSearch = search || searchInHash;
    const urlSearchParams = new URLSearchParams(eitherSearch);
    if (pathname && !urlSearchParams.get("redirect") && pathname.toString() === "help.magnite.com") {
        urlSearchParams.append("redirect", pathname);
        const searchParamsString = urlSearchParams.toString();
        const searchParams = searchParamsString ? `?${searchParamsString}` : "";
        return `${ROUTES.LOGIN_FORM_EMAIL_STEP}${searchParams}`;
    } else {
        return ROUTES.LOGIN_MAGNITE;
    }
};

export const SessionRequiredRoute: FC<RouteProps> = (props) => {
    const history = useHistory();
    const { pathname, search } = useLocation();
    const { isLoggedIn, isLoggingIn, isLoggingOut, isLoggedOutExplicitly, isLoggedInViaAuth0 } = useAuthClient();

    useEffect(() => {
        if (!isLoggedIn && !isLoggingIn && !isLoggingOut && !isLoggedInViaAuth0) {
            const loginRedirectUrl = _getLoginUrl(pathname, search, isLoggedOutExplicitly);
            history.replace(loginRedirectUrl);
        }
    }, [isLoggedIn, isLoggingIn, isLoggingOut, isLoggedOutExplicitly, isLoggedInViaAuth0, pathname, search, history]);

    return <Route {...props} />;
};
