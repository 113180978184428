import { FC, ReactNode } from "react";
import { Typography } from "antd";
import { TargetingDimensionTypeLabels, TargetingSupplyLabels } from "../constants";

export interface ReadOnlyCategoryPanelProps {
    category: string;
    targets: boolean[];
    header: ReactNode;
}

const DIMENSION_TYPE_LABEL_TO_SUPPLY_LABEL: Partial<Record<TargetingDimensionTypeLabels, TargetingSupplyLabels>> = {
    [TargetingDimensionTypeLabels.Coppa]: TargetingSupplyLabels.Coppa,
    [TargetingDimensionTypeLabels.Dnt]: TargetingSupplyLabels.Dnt,
    [TargetingDimensionTypeLabels.LiveStream]: TargetingSupplyLabels.LiveStream,
};

export const ReadOnlyCategoryPanel: FC<ReadOnlyCategoryPanelProps> = ({ category, header, targets }) => {
    if (!targets.length) {
        return null;
    }

    return (
        <div style={{ marginLeft: 25, padding: "6px 0" }}>
            {header}
            <Typography.Text>{DIMENSION_TYPE_LABEL_TO_SUPPLY_LABEL[category]} Only</Typography.Text>
        </div>
    );
};
