import { useCallback } from "react";
import { useAppDispatch } from "@app/core/store";
import { updateSyncedFieldsState } from "./reducer";
import { SyncedFields } from "./types";

interface UseSyncedFieldsProducer {
    updateSyncedFields: (fields: SyncedFields) => void;
}

export const useSyncedFieldsProducer = (): UseSyncedFieldsProducer => {
    const dispatch = useAppDispatch();

    const updateSyncedFields = useCallback(
        (fields: SyncedFields) => {
            if (Boolean(Object.keys(fields).length)) {
                dispatch(updateSyncedFieldsState(fields));
            }
        },
        [dispatch]
    );

    return {
        updateSyncedFields,
    };
};
