import { FC } from "react";
import { Button } from "antd";

interface ManageColumnsButtonProps {
    handleOpen: () => void;
}

export const ManageColumnsButton: FC<ManageColumnsButtonProps> = ({ handleOpen }) => {
    return <Button onClick={handleOpen}>Manage Columns</Button>;
};
