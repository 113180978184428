import { FC } from "react";
import { AdUnitMinBitrate } from "@app/features/inventory/components/FormItems";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const MinBitrate: FC = () => (
    <AdUnitMinBitrate
        name={CHANNEL_FORM_FIELDS.MIN_BITRATE.name}
        label={CHANNEL_FORM_FIELDS.MIN_BITRATE.label}
        placeholder={CHANNEL_FORM_FIELDS.MIN_BITRATE.placeholder}
    />
);
