import { Form, Radio } from "antd";
import { ALWAYS_SEND_FLOOR_OPTIONS } from "@app/features/deals/constants";
import { useAdSourceAlwaysSandFloorField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceFloorSettingsSection/Fields/AdSourceAlwaysSendFloorField/useAdSourceAlwaysSendFloorField";

export const AdSourceAlwaysSendFloorField = () => {
    const { handleChange, value } = useAdSourceAlwaysSandFloorField();
    return (
        <Form.Item label="Always Send Floor" data-sdet="ad-source-always-send-floor">
            <Radio.Group
                onChange={(e) => handleChange(e?.target?.value)}
                defaultValue={value}
                value={value}
                options={ALWAYS_SEND_FLOOR_OPTIONS}
            />
        </Form.Item>
    );
};
