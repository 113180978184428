import { FC } from "react";
import { CustomRuleGroupApi, CustomRuleIdentiferApi } from "@app/features/targeting/types";
import { CustomRuleIdentifierRow } from "./CustomRuleIdentifierRow";
import { RuleGroupRow } from "../ReadOnlyRuleGroupRow";

interface Props {
    group: CustomRuleGroupApi;
    path: number[];
}

export const CustomRuleGroupRow: FC<Props> = ({ group, path }) => {
    return (
        <RuleGroupRow<CustomRuleIdentiferApi>
            group={group}
            path={path}
            identifierRowRender={(rule, pathStr, groupIndex) => (
                <CustomRuleIdentifierRow key={`custom-rule-${pathStr}-${groupIndex}`} rule={rule} />
            )}
            groupRowRender={(rule, pathStr, groupIndex) => (
                <CustomRuleGroupRow
                    group={rule}
                    key={`custom-ruleGroup-${pathStr}-${groupIndex}`}
                    path={[...path, groupIndex]}
                />
            )}
        />
    );
};
