import { useSeatAuthContext } from "@app/core/auth";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useAppSelector } from "@app/core/store";
import { uri } from "@rubicon/utils";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
    InventoryDetailsDrawerType,
    selectInventoryDetailsDrawerType,
    selectIsPageInventoryDetailsDrawer,
} from "../../reducer";
import { UnitUrlIds } from "../../useInventoryDetailsDrawerUrlId";
import { useNestedData } from "../useNestedData";

export const DIAGNOSTICS = "Diagnostics";

export const enum DiagnosticsTabsType {
    VastErrors = "vastErrors",
    VastStats = "vastStats",
    CalculonStats = "calculonStats",
    UniquesCount = "uniquesCount",
    TimingStats = "timingStats",
}

const enum DiagnosticsTabsUrlSerachParams {
    activeTab = "activeTab",
}

interface UrlParams extends UnitUrlIds {
    seatId: string;
}

interface UseDiagnosticsContent {
    activeTab: DiagnosticsTabsType;
    isLoading: boolean;
    title: string;
    breadcrumbs?: string;
    isSeat: boolean;
    isAdUnit: boolean;
    handleDefinition: () => void;
    handleChangeTab: (activeTab: DiagnosticsTabsType) => void;
}

export const useDiagnosticsContent = (id: number | string): UseDiagnosticsContent => {
    const { seatId, publisherId, brandId, supplyId, adUnitId } = useParams<UrlParams>();
    const { search } = useLocation();
    const history = useHistory();

    const drawerType = useAppSelector(selectInventoryDetailsDrawerType) as InventoryDetailsDrawerType;
    const [activeTab, setActiveTab] = useState<DiagnosticsTabsType>(
        (new URLSearchParams(search).get(DiagnosticsTabsUrlSerachParams.activeTab) as DiagnosticsTabsType) ||
            DiagnosticsTabsType.VastErrors
    );
    const { nestedBreadcrumbs, isLoading } = useNestedData(id);
    const { context } = useSeatAuthContext();
    const detailsDrawerType = useAppSelector(selectInventoryDetailsDrawerType);
    const isDetailsUnitPage = useAppSelector(selectIsPageInventoryDetailsDrawer);
    const isSeat = detailsDrawerType === InventoryDetailsDrawerType.SEAT;
    const isAdUnit = detailsDrawerType === InventoryDetailsDrawerType.AD_UNIT;
    const title = isSeat ? `Seat ${DIAGNOSTICS}: ${String(context?.name)}` : DIAGNOSTICS;
    const breadcrumbs = isSeat ? undefined : nestedBreadcrumbs;

    const handleDefinition = () => {
        //TODO
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_DIAGNOSTICS_DEFINITION(
                !isSeat && isDetailsUnitPage,
                seatId,
                drawerType,
                Number(publisherId),
                Number(brandId),
                Number(supplyId),
                Number(adUnitId)
            )
        );
    };

    const handleChangeTab = (value: DiagnosticsTabsType) => {
        const nextSearchUpdated = uri.setSearchParam("", DiagnosticsTabsUrlSerachParams.activeTab, value);
        history.push({ search: nextSearchUpdated });
    };

    useEffect(() => {
        const urlSerachParams = new URLSearchParams(search);
        const savedActiveTab = urlSerachParams.get(DiagnosticsTabsUrlSerachParams.activeTab);

        if (urlSerachParams.get(DiagnosticsTabsUrlSerachParams.activeTab)) {
            setActiveTab(savedActiveTab as DiagnosticsTabsType);
            return;
        }
        setActiveTab(DiagnosticsTabsType.VastErrors);
    }, [search]);

    return {
        activeTab,
        title,
        breadcrumbs,
        isLoading,
        isSeat,
        isAdUnit,
        handleDefinition,
        handleChangeTab,
    };
};
