import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { Form, Radio } from "antd";
import { useReferUrl } from "./useReferUrl";

export const ReferUrlRadio = () => {
    const { isDisabled, extraText } = useReferUrl();
    return (
        <Form.Item
            name={AD_SOURCE_FIELDS.REFER_URL.name}
            label={AD_SOURCE_FIELDS.REFER_URL.label}
            extra={extraText}
            labelCol={{ lg: 8 }}
            labelAlign="left"
            colon={false}
            style={{ marginBottom: 8 }}
        >
            <Radio.Group disabled={isDisabled}>
                <Radio value={true}>Shared</Radio>
                <Radio value={false}>Hidden</Radio>
            </Radio.Group>
        </Form.Item>
    );
};
