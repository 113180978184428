import { Form, FormInstance } from "antd";
import { useParams } from "react-router-dom";
import { createContext, FC, useContext } from "react";
import {
    AdUnit,
    Brand,
    BrandSupply,
    Publisher,
    Seat,
    useGetBrandByIdQuery,
    useGetPublisherByIdQuery,
    useGetSeatSelfQuery,
    useGetSupplyQuery,
} from "@app/core/services";
import { HierarchyFormsMode } from "@app/features/inventory/HierarchyForms/constants";
import { AdUnitForm } from "@app/features/inventory/HierarchyForms/AdUnitForm/useAdUnitForm";

export interface AdUnitFormInstanceProps {
    adUnit?: AdUnit;
    mode: HierarchyFormsMode;
}

type AdUnitFormInstanceContextType = AdUnitFormInstanceProps & {
    seatId: string;
    seat: Seat | null;
    isLoading: boolean;
    publisherId: number;
    brand: Brand | undefined;
    supply: BrandSupply | undefined;
    brandId: number;
    supplyId: number;
    useWatch: typeof Form.useWatch;
    form: FormInstance<AdUnitForm>;
    publisher: Publisher | undefined;
};

const AdUnitInstanceContext = createContext<AdUnitFormInstanceContextType | null>(null);

export const AdUnitFormInstance: FC<AdUnitFormInstanceProps> = ({ children, ...rest }) => {
    const [form] = Form.useForm<AdUnitForm>();
    const { seatId, publisherId, brandId, supplyId } = useParams<{
        seatId: string;
        publisherId: string;
        brandId: string;
        supplyId: string;
    }>();
    const { data: seatSelf, isFetching: isLoadingSeat } = useGetSeatSelfQuery(seatId);
    const { data: publisher, isFetching: isFetchingPublisher } = useGetPublisherByIdQuery(Number(publisherId));
    const { data: brand, isFetching: isFetchingBrand } = useGetBrandByIdQuery(Number(brandId));
    const { data: supply, isFetching: isFetchingSupply } = useGetSupplyQuery(Number(supplyId));
    const isLoading: boolean = isFetchingBrand || isFetchingPublisher || isLoadingSeat || isFetchingSupply;

    return (
        <AdUnitInstanceContext.Provider
            value={{
                brand,
                brandId: Number(brandId),
                form,
                isLoading,
                seatId,
                publisher,
                publisherId: Number(publisherId),
                seat: seatSelf?.seat || null,
                supplyId: Number(supplyId),
                supply,
                useWatch: Form.useWatch,
                ...rest,
            }}
        >
            {children}
        </AdUnitInstanceContext.Provider>
    );
};
export const useAdUnitFormInstance = (): AdUnitFormInstanceContextType => {
    const context: AdUnitFormInstanceContextType | null = useContext(AdUnitInstanceContext);
    if (!context) throw new Error("useAdUnitFormInstance must be used within a AdUnitFormInstance");
    return context;
};
