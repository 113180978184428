import { FC } from "react";
import { useThrottle } from "@app/core/utils";
import { useAppSelector } from "@app/core/store";
import { NONE } from "@app/core/components/constants";
import { RevenueTypes } from "@app/core/clients/console";
import { LoadingCell } from "@app/core/components/LoadingCell";
import { useCurrencyConversion } from "@app/features/inventory/useCurrencyConversion";
import { PercentageMetric } from "@app/features/inventory/components/PercentageMetric";
import {
    AdStat,
    AdStatsById,
    getAdStatsTotalRevenue,
    getOtimesForThrottleUnit,
    getTotalRevenue,
} from "@app/features/adStats";
import { selectInventoryHealthCurrencyConversionMode } from "@app/features/inventory/InventoryHealth/InventoryHealthPage/reducer";
import { INVENTORY_HEALTH_FRACTIONS_DIGITS } from "@app/features/inventory/InventoryHealth/InventoryHealthPage/constants";
import { LoadableAnimatedNumberCell } from "@app/core/components";

interface InventoryHealthInventoryNetRevenueProps {
    adStats: AdStatsById;
    sourceId: number;
    isConnected: boolean;
    showPercentage?: boolean;
}

export const InventoryHealthInventoryNetRevenue: FC<InventoryHealthInventoryNetRevenueProps> = ({
    adStats,
    sourceId,
    isConnected,
    showPercentage = true,
}) => {
    const adStat: AdStat | null = adStats[sourceId];
    const currencyConversionMode = useAppSelector(selectInventoryHealthCurrencyConversionMode);
    const { preferredCurrency, currencyConversions } = useCurrencyConversion();

    const otime = getOtimesForThrottleUnit(adStat);
    const totalRevenue: number | undefined = getAdStatsTotalRevenue(
        adStats,
        preferredCurrency,
        currencyConversions,
        currencyConversionMode,
        RevenueTypes.NET_REVENUE
    );
    const netRevenue: number | undefined = getTotalRevenue(
        adStat,
        preferredCurrency,
        currencyConversions,
        currencyConversionMode,
        RevenueTypes.NET_REVENUE
    );

    const throttledNetRevenue = useThrottle<number | undefined>(netRevenue || undefined, {
        forceUpdateDependencies: [otime, currencyConversionMode, preferredCurrency],
    });

    if (!isConnected || !adStat) return <LoadingCell />;

    if (throttledNetRevenue === undefined) return <>{NONE}</>;

    const percentage: string = ((netRevenue / totalRevenue) * 100).toFixed(INVENTORY_HEALTH_FRACTIONS_DIGITS);

    return (
        <LoadableAnimatedNumberCell
            isMoney
            value={throttledNetRevenue}
            currencyCode={preferredCurrency?.code}
            suffix={
                showPercentage ? (
                    <>
                        {" "}
                        <PercentageMetric percentage={percentage} />
                    </>
                ) : undefined
            }
        />
    );
};
