import { useEffect, useState } from "react";
import { Form, FormInstance } from "antd";
import { useHistory } from "react-router-dom";
import { UseLoginFormData } from "@app/features/login/LoginForm/useLoginFormData";
import { countdownValueType } from "antd/es/statistic/utils";
import { ROUTES, ROUTE_FORMATTERS } from "@app/core/routing";
import { useLazyGetSeatSelfQuery, useOpenHelpCenter } from "@app/core/services/console";
import { useAuthClient } from "@app/core/authClient";
import moment from "moment-timezone";

export enum PASSWORD_FORM_FIELDS {
    Password = "password",
    IsPasswordVisible = "isPasswordVisible",
}

export type PasswordFormFieldsValueType = {
    [key in PASSWORD_FORM_FIELDS]: string;
};

interface UserLockOutData {
    duration: null | countdownValueType;
    isUserLockedOut: boolean;
}

const initialUserLockOutData = {
    duration: null,
    isUserLockedOut: false,
};

interface UsePasswordForm {
    form: FormInstance;
    handleFormChange: (values: PasswordFormFieldsValueType) => void;
    handleSubmit: () => void;
    isLoggingIn: boolean;
    isPasswordVisible: boolean;
    onFinish: () => void;
    PASSWORD_FORM_FIELDS: typeof PASSWORD_FORM_FIELDS;
    userLockOutData: UserLockOutData;
}

export const usePasswordForm = (loginFormData: UseLoginFormData): UsePasswordForm => {
    const { formData, setFormData } = loginFormData;
    const [form] = Form.useForm();
    const history = useHistory();
    const openHelpCenter = useOpenHelpCenter();
    const [getSeatSelf] = useLazyGetSeatSelfQuery();

    const { isLoggedIn, session, login } = useAuthClient();

    const { isLoggingIn } = useAuthClient();
    const { email, password, isPasswordVisible } = formData;

    const [userLockOutData, setUserLockOutData] = useState<UserLockOutData>(initialUserLockOutData);

    const handleFormChange = (values: PasswordFormFieldsValueType): void => {
        for (const [key, value] of Object.entries(values)) {
            setFormData((prevFormData) => ({ ...prevFormData, [key]: value }));
        }
    };

    const onFinish = (): void => {
        setUserLockOutData(initialUserLockOutData);
    };

    const handleSubmit = async () => {
        try {
            await login(email, password);
        } catch (error) {
            if (error?.response?.data?.errorCode === "invalid-credentials") {
                form.setFields([
                    {
                        name: PASSWORD_FORM_FIELDS.Password,
                        errors: ["Invalid email or password"],
                    },
                ]);
            } else if (error.response?.data?.errorCode === "session-expired") {
                form.setFields([
                    {
                        name: PASSWORD_FORM_FIELDS.Password,
                        errors: ["Session Expired"],
                    },
                ]);
            } else if (error.response?.data?.errorCode === "user-locked-out") {
                setUserLockOutData({
                    duration: moment(Number(error?.response?.data?.data?.lockDate)).add(
                        Number(error?.response?.data?.data?.lockoutMinutes),
                        "minute"
                    ) as unknown as countdownValueType,
                    isUserLockedOut: true,
                });
            } else {
                form.setFields([
                    {
                        name: PASSWORD_FORM_FIELDS.Password,
                        errors: [error?.response?.data?.errorDescription || "Something went wrong"],
                    },
                ]);
            }
        }
    };

    useEffect(() => {
        if (!email) {
            history.push(ROUTES.LOGIN_FORM_EMAIL_STEP);
        }
    }, [email, history]);

    useEffect(() => {
        if (isLoggedIn) {
            if (
                session?.user?.userPreferenceMap?.preferredSeat &&
                Number(session?.user?.userPreferenceMap?.preferredSeat) > 0
            ) {
                const urlParams = new URLSearchParams(history.location.search);
                const redirect = urlParams.get("redirect");

                if (redirect === "help.magnite.com") {
                    openHelpCenter(false, true);
                    return;
                }

                if (redirect) {
                    history.push(redirect);
                    return;
                }

                const preferredSeatId = Number(session?.user?.userPreferenceMap?.preferredSeat);

                getSeatSelf(preferredSeatId)
                    .unwrap()
                    .then(() => {
                        history.push(ROUTE_FORMATTERS.SEAT_DASHBOARD(preferredSeatId));
                    })
                    .catch(() => {
                        // User doesn't have access to the seat, or it doesn't exist
                        history.push(`${ROUTES.LOGIN_FORM_CONTEXT_STEP}${history.location.search}`);
                    });

                return;
            }

            history.push(`${ROUTES.LOGIN_FORM_CONTEXT_STEP}${history.location.search}`);
        }
    }, [isLoggedIn, session, history, openHelpCenter, getSeatSelf]);

    return {
        form,
        isLoggingIn,
        isPasswordVisible,
        handleSubmit,
        PASSWORD_FORM_FIELDS,
        handleFormChange,
        onFinish,
        userLockOutData,
    };
};
