import { IndustryDetails, IndustryMutationPayload } from "@app/core/services";
import { ClassificationsIndustryFormValues } from "./constants";

export const getClassificationsIndustryFormInitialValues = (industry?: IndustryDetails) =>
    industry
        ? {
              id: industry.id,
              name: industry.name,
              code: industry.code,
              description: industry.description,
          }
        : {};

export const getClassificationsIndustryPayload = (
    values: ClassificationsIndustryFormValues,
    seatId: number
): IndustryMutationPayload => {
    const { id, name, code, description } = values;

    return {
        id,
        name: name.trim(),
        code: code?.trim(),
        description: description?.trim(),
        seat: { id: seatId },
    };
};
