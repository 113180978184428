import { FC, useState } from "react";
import { Collapse, Drawer, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { DrawerCloseButton, SectionTitle, StyledButtonLink } from "@app/core/components";

export const AdSourceFloorSettingsDefinitionSidePanel: FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    return (
        <>
            <StyledButtonLink onClick={() => setIsOpen(true)} type="link">
                <InfoCircleOutlined />
                Floor Settings
            </StyledButtonLink>
            <Drawer
                width="40%"
                title="Floor Settings"
                styles={{ body: { padding: 0 } }}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                closable={false}
                extra={<DrawerCloseButton onClose={() => setIsOpen(false)} />}
            >
                <Typography.Paragraph style={{ padding: "0.5rem 1rem 0" }}>
                    Pricing for Auction Price Deals are set at the Ad Source level and not at the Deal object. If you
                    want to manipulate floor rules you will need to do so in the Ad Source that’s configured to the deal
                    object.
                </Typography.Paragraph>

                <Collapse defaultActiveKey={["1", "2", "3", "4"]}>
                    <Collapse.Panel key="1" header={<SectionTitle title="Override Floor (CPM)" />}>
                        <Typography.Text>Reserved for guaranteed deals</Typography.Text>
                    </Collapse.Panel>
                    <Collapse.Panel key="2" header={<SectionTitle title="Auction Type" />}>
                        <Typography.Paragraph>
                            In a first price auction the clearing price is the winning bid. In a second price auction
                            the clearing price is $0.01 higher than an either the second highest bid or the auction
                            floor, whichever is higher.
                        </Typography.Paragraph>
                    </Collapse.Panel>

                    <Collapse.Panel key="3" header={<SectionTitle title="Floor Type" />}>
                        <Typography.Paragraph>
                            When defining a price in an auction, you must establish a price floor, that is, the lowest
                            price that the publisher will accept in a bid. The price floor defined in an auction can
                            override or augment the price floor defined at a given inventory level, but in order for
                            that to happen, you must define the rules for that override up front in the pricing section
                            of the auction definition, using the appropriate Floor Type designation:
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            Fallback - If a floor price has not been set in the inventory hierarchy the floor will be
                            set to the value in the Fallback Floor (CPM) field.
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            Override - Will override the floor price that has been set in the inventory hierarchy with
                            the value set in the Override Floor (CPM) field below.
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            Increase CPM - Increase the floor price set in the inventory hierarchy by the value set in
                            the Increase Floor by CPM by field below.
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            Decrease CPM - Decrease the floor price set in the inventory hierarchy by the value set in
                            the Decrease Floor by CPM by field below.
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            Increase Percent - Increase the floor price set in the inventory hierarchy by the percentage
                            set in the Increase Floor by % by field below.
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            Decrease Percent - Decrease the floor price set in the inventory hierarchy by the percentage
                            set in the Decrease Floor by % by field below.
                        </Typography.Paragraph>
                    </Collapse.Panel>

                    <Collapse.Panel key="4" header={<SectionTitle title="Always Send Floor" />}>
                        <Typography.Paragraph>N/A</Typography.Paragraph>
                    </Collapse.Panel>
                </Collapse>
            </Drawer>
        </>
    );
};
