import { FixedSaveButtons, TagRender } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Button, Card, Form, Input, Layout, Select, Typography, notification } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useNotificationError } from "@app/core/components/hooks";
import { getClassificationsAdvertiserFormInitialValues, getClassificationsAdvertiserPayload } from "./helpers";
import { FC, useMemo, useState } from "react";
import {
    CLASSIFICATIONS_ADVERTISER_ADVERTISER_DOMAINS_INPUT_SDET,
    CLASSIFICATIONS_ADVERTISER_CODE_INPUT_SDET,
    CLASSIFICATIONS_ADVERTISER_DELETE_BUTTON_SDET,
    CLASSIFICATIONS_ADVERTISER_DESCRIPTION_INPUT_SDET,
    CLASSIFICATIONS_ADVERTISER_FORM_ITEMS,
    CLASSIFICATIONS_ADVERTISER_INDUSTRY_SELECT_SDET,
    CLASSIFICATIONS_ADVERTISER_NAME_INPUT_SDET,
    CLASSIFICATIONS_ADVERTISER_PARENT_ADVERTISER_SELECT_SDET,
} from "./constants";
import {
    ClassificationsAdvertiserDeleteModal,
    useClassificationsAdvertiserDeleteModal,
} from "../ClassificationsAdvertisersTab/ClassificationsAdvertisersTabDrawer/ClassificationsAdvertiserDeleteModal";
import {
    AdvertiserDetails,
    useCreateAdvertiserMutation,
    useGetIndustriesQuery,
    useGetSeatAdvertisersQuery,
    useUpdateAdvertiserMutation,
} from "@app/core/services";

interface Props {
    advertiser?: AdvertiserDetails;
}
export const ClassificationsAdvertiserForm: FC<Props> = ({ advertiser }) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();

    const {
        data: advertisersData = [],
        isLoading: isLoadingAdvertisers,
        error: advertisersError,
    } = useGetSeatAdvertisersQuery({
        seatId: Number(seatId),
    });
    const advertisersOptions = useMemo(
        () => advertisersData.map(({ name, id }) => ({ label: name, value: id })),
        [advertisersData]
    );

    const {
        data: industriesData = [],
        isLoading: isLoadingIndustries,
        error: industriesError,
    } = useGetIndustriesQuery({ seatId: Number(seatId) });
    const industriesOptions = useMemo(
        () => industriesData.map(({ name, id }) => ({ label: name, value: id })),
        [industriesData]
    );

    const initialValues = getClassificationsAdvertiserFormInitialValues(advertiser);

    useNotificationError(advertisersError || industriesError);

    const [createAdvertiser] = useCreateAdvertiserMutation();
    const [updateAdvertiser] = useUpdateAdvertiserMutation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { handleOpen, ...deleteModalProps } = useClassificationsAdvertiserDeleteModal();

    const isEditMode = Boolean(advertiser);

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={initialValues}
            onFinish={async (values) => {
                setIsSubmitting(true);
                const payload = getClassificationsAdvertiserPayload(values, Number(seatId));
                try {
                    const submitTrigger = isEditMode ? updateAdvertiser : createAdvertiser;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const newAdvertiser = await submitTrigger({ seatId: Number(seatId), payload }).unwrap();
                    setIsSubmitting(false);
                    notification.success({
                        message: (
                            <span>
                                <Typography.Text strong>{newAdvertiser.name}</Typography.Text> has successfully been{" "}
                                {isEditMode ? "updated" : "created"}
                            </span>
                        ),
                    });
                    history.push(
                        ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISER_DETAILS_PAGE(seatId, newAdvertiser.id)
                    );
                } catch (err) {
                    setIsSubmitting(false);
                    notification.error({ message: err.message || err.data.errorDescription });
                }
            }}
            wrapperCol={{ xs: 24, md: 12 }}
        >
            <Layout.Content>
                <Card>
                    <Form.Item {...CLASSIFICATIONS_ADVERTISER_FORM_ITEMS.ID} />
                    <Form.Item {...CLASSIFICATIONS_ADVERTISER_FORM_ITEMS.NAME}>
                        <Input placeholder="Enter Name" data-sdet={CLASSIFICATIONS_ADVERTISER_NAME_INPUT_SDET} />
                    </Form.Item>
                    <Form.Item {...CLASSIFICATIONS_ADVERTISER_FORM_ITEMS.CODE}>
                        <Input placeholder="Enter Code" data-sdet={CLASSIFICATIONS_ADVERTISER_CODE_INPUT_SDET} />
                    </Form.Item>
                    <Form.Item {...CLASSIFICATIONS_ADVERTISER_FORM_ITEMS.DESCRIPTION}>
                        <Input.TextArea
                            placeholder="Enter Description"
                            autoSize={{ minRows: 4, maxRows: 6 }}
                            data-sdet={CLASSIFICATIONS_ADVERTISER_DESCRIPTION_INPUT_SDET}
                        />
                    </Form.Item>
                    <Form.Item {...CLASSIFICATIONS_ADVERTISER_FORM_ITEMS.PARENT_ADVERTISER}>
                        <Select
                            allowClear
                            showSearch
                            labelInValue
                            disabled={advertiser?.isParent}
                            data-sdet={CLASSIFICATIONS_ADVERTISER_PARENT_ADVERTISER_SELECT_SDET}
                            placeholder="Select…"
                            optionFilterProp="label"
                            tagRender={(props) => <TagRender {...props} />}
                            loading={isLoadingAdvertisers}
                            options={advertisersOptions}
                        />
                    </Form.Item>
                    <Form.Item {...CLASSIFICATIONS_ADVERTISER_FORM_ITEMS.ADVERTISER_DOMAINS}>
                        <Input.TextArea
                            placeholder="Enter Domains"
                            autoSize={{ minRows: 4, maxRows: 6 }}
                            data-sdet={CLASSIFICATIONS_ADVERTISER_ADVERTISER_DOMAINS_INPUT_SDET}
                        />
                    </Form.Item>
                    <Form.Item {...CLASSIFICATIONS_ADVERTISER_FORM_ITEMS.INDUSTRY}>
                        <Select
                            allowClear
                            showSearch
                            labelInValue
                            data-sdet={CLASSIFICATIONS_ADVERTISER_INDUSTRY_SELECT_SDET}
                            optionFilterProp="label"
                            placeholder="Select…"
                            tagRender={(props) => <TagRender {...props} />}
                            loading={isLoadingIndustries}
                            options={industriesOptions}
                        />
                    </Form.Item>
                </Card>
            </Layout.Content>
            <FixedSaveButtons
                isLoading={isSubmitting}
                submitLabel={isEditMode ? "Save" : "Submit"}
                suffixAddOn={
                    advertiser ? (
                        <>
                            <Button
                                danger
                                onClick={() => handleOpen(advertiser)}
                                data-sdet={CLASSIFICATIONS_ADVERTISER_DELETE_BUTTON_SDET}
                                type="primary"
                            >
                                Delete
                            </Button>
                            <ClassificationsAdvertiserDeleteModal {...deleteModalProps} />
                        </>
                    ) : null
                }
                onCancel={() =>
                    history.push(
                        advertiser
                            ? ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISER_DETAILS_PAGE(
                                  seatId,
                                  advertiser.id
                              )
                            : ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_TABS(seatId, "advertisers")
                    )
                }
            />
        </Form>
    );
};
