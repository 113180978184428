import { Table, notification } from "antd";
import { ENCRYPTION_KEYS_TABLE_COLUMNS, ENCRYPTION_KEYS_TABLE_SDET } from "./constants";
import { ErrorResponse, useGetSeatEncryptionKeysQuery } from "@app/core/services";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export const EncryptionKeysTable = () => {
    const { seatId } = useParams<{ seatId: string }>();

    const { data = [], isLoading, error } = useGetSeatEncryptionKeysQuery(seatId || skipToken);
    const sortDataByRecentCreationDate = [...data].sort(
        (a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
    );

    if (error) {
        notification.error({
            message:
                (error as ErrorResponse).data?.errorDescription ||
                (error as ErrorResponse)?.data?.errors?.[0]?.message ||
                "Something wrong has occurred, please contact your account manager",
        });
    }
    return (
        <Table
            data-sdet={ENCRYPTION_KEYS_TABLE_SDET}
            columns={ENCRYPTION_KEYS_TABLE_COLUMNS}
            dataSource={sortDataByRecentCreationDate}
            rowKey="id"
            pagination={false}
            loading={isLoading}
        />
    );
};
