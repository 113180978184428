// TODO: Declare global instead of accessing window directly
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const windowConf = window.__config || {};

const conf = {
    ...windowConf,
};

export default conf;
