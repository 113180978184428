import { FC } from "react";
import { Form, Select } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAdSourceCostModelField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceAdminOptionsSection/Fields/AdSourceCostModelField/useAdSourceCostModelField";

export const AdSourceCostModelField: FC = () => {
    const { value, onChange, options, isFetching, defaultCostModel } = useAdSourceCostModelField();
    return (
        <Form.Item
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_COST_MODEL}
            data-sdet="ad-source-cost-model-field"
            label="Cost Model"
            help={defaultCostModel ? `Default Cost Model: ${defaultCostModel}` : null}
        >
            <Select
                showSearch
                options={options}
                loading={isFetching}
                optionFilterProp="label"
                placeholder="Select Cost Model"
                onChange={onChange}
                value={value}
                allowClear
            />
        </Form.Item>
    );
};
