import { Form, Typography } from "antd";
import { FC } from "react";
import { SeatContactPayload } from "../types";
import {
    SEAT_CONTACT_FIRSTNAME_INPUT_SDET,
    SEAT_CONTACT_LASTNAME_INPUT_SDET,
    SEAT_CONTACT_EMAIL_INPUT_SDET,
    SEAT_CONTACT_PHONE_INPUT_SDET,
    SEAT_CONTACT_ADDRESS1_INPUT_SDET,
    SEAT_CONTACT_ADDRESS2_INPUT_SDET,
    SEAT_CONTACT_CITY_INPUT_SDET,
    SEAT_CONTACT_STATE_INPUT_SDET,
    SEAT_CONTACT_POSTAL_CODE_INPUT_SDET,
    SEAT_CONTACT_COUNTRY_SDET,
    SEAT_CONTACT_TYPE_SDET,
} from "../SeatContactsForm/constants";
import { NONE } from "@app/core/components/constants";

interface Props {
    seatContact?: SeatContactPayload;
}
export const SEAT_CONTACTS_DETAILS_CONTENT_SDET = "seat-contacts-details-content";

export const SeatContactsDetailsContent: FC<Props> = ({ seatContact }) => {
    return seatContact ? (
        <Form
            size="small"
            layout="vertical"
            data-sdet={SEAT_CONTACTS_DETAILS_CONTENT_SDET}
            wrapperCol={{ xs: 24, md: 12 }}
        >
            <Form.Item label="First Name">
                <Typography.Text data-sdet={SEAT_CONTACT_FIRSTNAME_INPUT_SDET}>{seatContact.firstName}</Typography.Text>
            </Form.Item>
            <Form.Item label="Last Name">
                <Typography.Text data-sdet={SEAT_CONTACT_LASTNAME_INPUT_SDET}>{seatContact.lastName}</Typography.Text>
            </Form.Item>
            <Form.Item label="Email">
                <Typography.Text data-sdet={SEAT_CONTACT_EMAIL_INPUT_SDET}>{seatContact.emailAddress}</Typography.Text>
            </Form.Item>
            <Form.Item label="Contact Type">
                <Typography.Text data-sdet={SEAT_CONTACT_TYPE_SDET}>{seatContact.type.name}</Typography.Text>
            </Form.Item>
            <Form.Item label="Phone number">
                <Typography.Text data-sdet={SEAT_CONTACT_PHONE_INPUT_SDET}>{seatContact.phone || NONE}</Typography.Text>
            </Form.Item>
            <Form.Item label="Address 1">
                <Typography.Text data-sdet={SEAT_CONTACT_ADDRESS1_INPUT_SDET}>
                    {seatContact.address1 || NONE}
                </Typography.Text>
            </Form.Item>
            <Form.Item label="Address 2">
                <Typography.Text data-sdet={SEAT_CONTACT_ADDRESS2_INPUT_SDET}>
                    {seatContact.address2 || NONE}
                </Typography.Text>
            </Form.Item>
            <Form.Item label="City">
                <Typography.Text data-sdet={SEAT_CONTACT_CITY_INPUT_SDET}>{seatContact.city || NONE}</Typography.Text>
            </Form.Item>
            <Form.Item label="State">
                <Typography.Text data-sdet={SEAT_CONTACT_STATE_INPUT_SDET}>{seatContact.state || NONE}</Typography.Text>
            </Form.Item>
            <Form.Item label="Postal Code">
                <Typography.Text data-sdet={SEAT_CONTACT_POSTAL_CODE_INPUT_SDET}>
                    {seatContact.postalCode || NONE}
                </Typography.Text>
            </Form.Item>
            <Form.Item label="Country">
                <Typography.Text data-sdet={SEAT_CONTACT_COUNTRY_SDET}>
                    {seatContact.countryCode || NONE}
                </Typography.Text>
            </Form.Item>
        </Form>
    ) : null;
};
