import { FC } from "react";
import { css } from "@emotion/css";
import { PAGE_SIZE_OPTIONS } from "@app/core/components/constants";
import { Divider, Flex, Pagination, Typography, Collapse } from "antd";
import { useSeatAdSourcesPriorityCollapse } from "./useSeatAdSourcesPriorityCollapse";
import { SeatAdSourcesPriorityCollapseContents } from "./SeatAdSourcesPriorityCollapseContents";
import { useSeatAdSourcesListGridPriorityPagination } from "./useSeatAdSourcesListGridPriorityPagination";

interface Props {
    priority: number;
    allPrioritiesLoaded: boolean;
}

const collapseClasses = css`
    .ant-collapse-item {
        padding: 4px;
        > .ant-collapse-header {
            align-items: baseline;
        }
    }

    .ant-collapse-header {
        .ant-typography {
            margin: 8px 0 !important;
        }
    }
`;

export const SeatAdSourcesPriorityCollapse: FC<Props> = ({ priority, allPrioritiesLoaded }) => {
    const { adSources, total, isUpdatingPagination, hasAdSources, primaryPriority, isExpanded, toggleExpanded } =
        useSeatAdSourcesPriorityCollapse(priority);
    const { pageSize, onChange, current } = useSeatAdSourcesListGridPriorityPagination(priority);

    if (!allPrioritiesLoaded || !hasAdSources) {
        return null;
    }

    return (
        <div data-sdet="seat-ad-sources-priority-collapse">
            <Collapse
                key={priority}
                collapsible="header"
                className={collapseClasses}
                defaultActiveKey={[primaryPriority]}
                onChange={(expandedKeys) => {
                    toggleExpanded(expandedKeys as string[]);
                }}
                ghost
                bordered={false}
                items={[
                    {
                        key: priority,
                        label: (
                            <>
                                <Typography.Text style={{ fontSize: "20px", color: "black" }}>
                                    Priority {priority}
                                </Typography.Text>
                                <Typography.Text
                                    style={{
                                        fontSize: "14px",
                                        paddingLeft: "16px",
                                        color: "595959",
                                        fontWeight: "normal",
                                    }}
                                >
                                    {total} ad sources
                                </Typography.Text>
                            </>
                        ),
                        children: isExpanded && (
                            <Flex vertical gap={16}>
                                <SeatAdSourcesPriorityCollapseContents
                                    priority={priority}
                                    adSources={adSources}
                                    isUpdatingPagination={isUpdatingPagination}
                                />
                                <Pagination
                                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                                    pageSize={pageSize}
                                    onChange={onChange}
                                    current={current}
                                    total={total}
                                    className={css`
                                        align-self: flex-end;
                                    `}
                                />
                            </Flex>
                        ),
                    },
                ]}
            />
            <Divider style={{ margin: "0" }} />
        </div>
    );
};
