import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useAppSelector } from "@app/core/store";
import { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { InventoryDetailsDrawerType, selectInventoryDetailsDrawerType } from "../../reducer";
import { useInventoryDetailsDrawer } from "../../useInventoryDetailsDrawer";
import { UnitUrlIds } from "../../useInventoryDetailsDrawerUrlId";

interface UseDetailsDrawerUnitLayout {
    visibleCopy: boolean;
    handleVisibleCopy: () => void;
    handleHideCopy: () => void;
    gotoInventory: () => void;
    copyUrl: string;
}

export const useDetailsDrawerUnitLayout = (): UseDetailsDrawerUnitLayout => {
    const history = useHistory();
    const { seatId, publisherId, brandId, adUnitId, supplyId } = useParams<UnitUrlIds>();
    const drawerType = useAppSelector(selectInventoryDetailsDrawerType) as InventoryDetailsDrawerType;
    const { closeDetailsDrawer } = useInventoryDetailsDrawer();
    const [visibleCopy, setVisibleCopy] = useState<boolean>(false);

    const { origin, pathname } = window.location;
    const url = ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT(
        drawerType,
        seatId,
        publisherId,
        brandId,
        supplyId,
        adUnitId
    ) as string;
    const copyUrl = useMemo(() => `${origin}${pathname}#${url}`, [url, origin, pathname]);

    const gotoInventory = () => {
        closeDetailsDrawer();
        history.push(url);
    };

    const handleVisibleCopy = () => {
        setVisibleCopy(true);
    };
    const handleHideCopy = () => {
        setVisibleCopy(false);
    };

    return {
        handleVisibleCopy,
        handleHideCopy,
        gotoInventory,
        copyUrl,
        visibleCopy,
    };
};
