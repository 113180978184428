import { useGetContentTransparencyRulesQuery } from "@app/core/services";
import { useParams } from "react-router-dom";
import { format } from "@rubicon/utils";

const { SHARED, HIDDEN } = format.constants;

const DEFAULT_CONTENT_OPTIONS = [
    { name: SHARED, value: true },
    { name: HIDDEN, value: false },
];

export const useContentSelect = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data: contentTransparencyRules = [], isFetching } = useGetContentTransparencyRulesQuery(Number(seatId));
    const contentOptions = [...DEFAULT_CONTENT_OPTIONS, ...contentTransparencyRules];
    return {
        contentOptions,
        isLoading: isFetching,
    };
};
