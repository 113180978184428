import { FC } from "react";
import { LoadOnScrollSelect } from "@app/core/components";
import { LabeledValue } from "antd/lib/select";
import { TagRender } from "@app/core/components/TagRender";
import {
    useGetContentMetadataChannelsQuery,
    useValidateContentMetadataChannelsMutation,
} from "@app/core/services/console";
import { Conditions, TargetingDimensionTypeLabels, TargetingDimensionTypes } from "../../constants";
import { contentMetadataContentChannelToLabelValue, isAddDisabled } from "../../helpers";
import { ContentChannelsTargetingDimension, UsedDimensions } from "../../types";
import { ConditionSelect } from "../ConditionSelect";
import { MoreMenu } from "../MoreMenu";
import { DimensionRow } from "./DimensionRow";
import { useDimensionRowSearch } from "./useDimensionRowSearch";
import { BulkUploadDrawerButtonContainer } from "./BulkUploadDrawerButtonContainer";
import { SelectAllButtonDropdownRender } from "@app/core/components/SelectAllButtonDropdownRender";

interface Props {
    dimension: ContentChannelsTargetingDimension;
    onChangeCondition: (value: Conditions) => void;
    onChangeDimensionValues: (value: LabeledValue[]) => void;
    onClickDownload: () => void;
    onClickDelete: () => void;
    onClickDuplicate: () => void;
    usedDimensions: UsedDimensions;
}

export const ContentMetadataContentChannelDimensionRow: FC<Props> = ({
    dimension,
    onChangeCondition,
    onChangeDimensionValues,
    onClickDelete,
    onClickDownload,
    onClickDuplicate,
    usedDimensions,
}) => {
    const [search, handleChangeSearch, options, isFetching, hasMore, loadMore, isCurrent] = useDimensionRowSearch(
        useGetContentMetadataChannelsQuery,
        contentMetadataContentChannelToLabelValue
    );

    return (
        <DimensionRow
            label={TargetingDimensionTypeLabels.ContentChannels}
            condition={<ConditionSelect onChange={onChangeCondition} value={dimension.condition} />}
            value={
                <LoadOnScrollSelect
                    allowClear
                    labelInValue
                    data-sdet="ContentChannelDimensionSelect"
                    dropdownRender={(menu) => (
                        <SelectAllButtonDropdownRender<LabeledValue>
                            isCurrent={isCurrent}
                            hasMore={hasMore}
                            getPrimaryKey={(v) => v.value}
                            menu={menu}
                            onChange={onChangeDimensionValues}
                            options={options}
                            search={search}
                            value={dimension.values}
                        />
                    )}
                    filterOption={false}
                    hasMore={hasMore}
                    loadMore={loadMore}
                    loading={isFetching}
                    mode="multiple"
                    onBlur={() => handleChangeSearch("")}
                    onChange={onChangeDimensionValues}
                    onSearch={handleChangeSearch}
                    optionFilterProp="label"
                    options={options}
                    placeholder="Select Values"
                    searchValue={search}
                    style={{ width: "100%" }}
                    tagRender={TagRender}
                    value={dimension.values}
                />
            }
            menu={
                <>
                    <BulkUploadDrawerButtonContainer
                        label={TargetingDimensionTypeLabels.ContentChannels}
                        mutation={useValidateContentMetadataChannelsMutation}
                        onChange={onChangeDimensionValues}
                        toLabelValueMapper={contentMetadataContentChannelToLabelValue}
                        values={dimension.values}
                    />
                    <MoreMenu
                        onClickDelete={onClickDelete}
                        onClickDownload={onClickDownload}
                        onClickDuplicate={onClickDuplicate}
                        isDuplicateDisabled={isAddDisabled(TargetingDimensionTypes.ContentChannels, usedDimensions)}
                    />
                </>
            }
        />
    );
};
