import { FC } from "react";
import { AdUnitSupportedAPIs } from "@app/features/inventory/components/FormItems";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const SupportedAPIs: FC = () => (
    <AdUnitSupportedAPIs
        name={AD_UNIT_FORM_FIELDS.SUPPORTED_APIS.name}
        label={AD_UNIT_FORM_FIELDS.SUPPORTED_APIS.label}
        placeholder={AD_UNIT_FORM_FIELDS.SUPPORTED_APIS.placeholder}
    />
);
