import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { useGetAdUnitWaitTimesQuery } from "@app/core/services";
import { OFF_WAIT_TIME } from "@app/features/inventory/HierarchyForms/constants";

interface UseAdUnitExtendedImpWaitTime {
    isFetching: boolean;
    options: LabeledValue[];
}

export const useAdUnitExtendedImpWaitTime = (): UseAdUnitExtendedImpWaitTime => {
    const { data = [], isFetching } = useGetAdUnitWaitTimesQuery();
    const options = useMemo(() => (data || []).map((time) => ({ value: time.id, label: time.name })), [data]);

    return {
        isFetching,
        options: [OFF_WAIT_TIME, ...options],
    };
};
