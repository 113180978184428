import { AD_SOURCE_FIELDS, COL_SIZES, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { Col, Form, Radio } from "antd";
import { useAlwaysSendFloorRadio } from "./useAlwaysSendFloorRadio";

export const AlwaysSendFloorRadio = () => {
    const { isVisible } = useAlwaysSendFloorRadio();

    if (!isVisible) {
        return null;
    }
    return (
        <Col {...COL_SIZES}>
            <Form.Item
                name={AD_SOURCE_FIELDS.ALWAYS_SEND_FLOORS.name}
                label={AD_SOURCE_FIELDS.ALWAYS_SEND_FLOORS.label}
                data-sdet={`test-${AD_SOURCE_FIELDS.ALWAYS_SEND_FLOORS.name}-field`}
                initialValue={true}
                {...VERTICAL_LAYOUT_FIX}
            >
                <Radio.Group data-sdet={`test-${AD_SOURCE_FIELDS.ALWAYS_SEND_FLOORS.name}-radio-group`}>
                    <Radio value={true} data-sdet={`test-${AD_SOURCE_FIELDS.ALWAYS_SEND_FLOORS.name}-yes`}>
                        Yes
                    </Radio>
                    <Radio value={false} data-sdet={`test-${AD_SOURCE_FIELDS.ALWAYS_SEND_FLOORS.name}-no`}>
                        No
                    </Radio>
                </Radio.Group>
            </Form.Item>
        </Col>
    );
};
