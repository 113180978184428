import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "@app/core/store";
import { AdStatHistoryById } from "../types";
import { useSeatAuthContext } from "@app/core/auth";
import { AdSourceAdStatsWebSocket } from "./AdSourceAdStatsWebSocket";
import { selectHistoryAdStats } from "./reducer";

export const useHistoryAdSourceAdStatsById = (adSourceIds: number[]): AdStatHistoryById => {
    const { session } = useSeatAuthContext();
    const sessionRef = useRef(session);
    const [adSourceAdStatsSocket, setAdSourceAdStatsSocket] = useState<AdSourceAdStatsWebSocket | null>(null);
    const adStatsById = useAppSelector(selectHistoryAdStats(adSourceIds));
    const adSourceIdsRef = useRef<number[]>([]);

    useEffect(() => {
        if (!sessionRef.current) {
            console.error("Attempted to connect to history ad source ad stats without a valid session.");
            return;
        }

        const socketHandle = AdSourceAdStatsWebSocket.getInstance(sessionRef.current);
        setAdSourceAdStatsSocket(socketHandle);

        return () => {
            if (socketHandle) {
                socketHandle.historySubscriberFinished(adSourceIdsRef.current);
            }
        };
        // Intentional empty dependency array to only run this effect once for initialization.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (adSourceAdStatsSocket) {
            const addedAdSourceIds = adSourceIds.filter(
                (adSourceId: number) => !adSourceIdsRef.current.includes(adSourceId)
            );
            const removedAdSourceIds = adSourceIdsRef.current.filter(
                (adSourceId: number) => !adSourceIds.includes(adSourceId)
            );

            const hasAdSourceIdUpdates = addedAdSourceIds.length > 0 || removedAdSourceIds.length > 0;
            if (hasAdSourceIdUpdates) {
                adSourceAdStatsSocket.updateHistoryAdStatSubscriptions(addedAdSourceIds, removedAdSourceIds);
            }

            adSourceIdsRef.current = adSourceIds;
        }
    }, [adSourceIds, adSourceAdStatsSocket]);

    return adStatsById;
};
