import { consoleApi } from "./console";
import { Permissions } from "./types";

export const enum PERMISSIONS_ENTITY_TYPES {
    SEAT = "seats",
    PUBLISHER = "publishers",
}

export interface Grants {
    userId: number;
    emailAddress: string;
    groupId: number | null;
    groupName: string | null;
    permission: string;
    entity: {
        id: number;
        name: string;
    };
    objectId: number;
    objectClass: string;
}

export interface GrantsParams {
    emailAddress: string | null;
    permission: Permissions;
    groupId: number | null;
}

export interface GrantsPayload {
    id: number;
    body: GrantsParams;
    entityType: PERMISSIONS_ENTITY_TYPES;
}

export interface DeleteGrants {
    emailAddress: string;
    permission: Permissions;
    groupId: number | null;
}

export interface DeleteGrantsPayload {
    id: number;
    body: DeleteGrants[];
    entityType: PERMISSIONS_ENTITY_TYPES;
}

export interface GetGrantsPayload {
    id: number;
    entityType: PERMISSIONS_ENTITY_TYPES;
}

export const grantsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getGrants: builder.query<Grants[], GetGrantsPayload>({
            query: ({ id, entityType }) => `${entityType}/${id}/grants`,
        }),
        createGrants: builder.mutation<Grants[], GrantsPayload>({
            query: ({ id, body, entityType }) => ({
                url: `${entityType}/${id}/grants`,
                method: "POST",
                body,
            }),
        }),
        deleteGrants: builder.mutation<unknown, DeleteGrantsPayload>({
            query: ({ id, body, entityType }) => ({
                url: `${entityType}/${id}/grants`,
                method: "DELETE",
                body,
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useGetGrantsQuery, useCreateGrantsMutation, useDeleteGrantsMutation } = grantsApi;
