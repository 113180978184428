import { FC } from "react";
import { Row, Col, Typography } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useUserAccess } from "@app/core/auth";
import { GoToEntityLink } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { AdvertiserDomainRaw } from "@app/core/services";
import { HelpKeysButton } from "@app/core/components/HelpKeysDrawer";
import { uri } from "@rubicon/utils";
import { CONTROLS_ROUTES } from "@app/features/controls/constants";
import { AdvertiserDomainActions } from "./AdvertiserDomainActions";

interface Props {
    aDomain?: AdvertiserDomainRaw;
}

export const ADOMAIN_DRAWER_TITLE_SDET = "advertiser-domain-details-drawer-title";
export const ADOMAIN_DRAWER_TITLE_NAME_SDET = "advertiser-domain-details-drawer-title-name";
export const ADOMAIN_DETAILS_ACTIONS_SDET = "advertiser-domain-details-action";
export const ADOMAIN_ACTIONS_EDIT_BUTTON_SDET = "advertiser-domain-details-action-edit-btn";

export const AdvertiserDomainDrawerTitle: FC<Props> = ({ aDomain }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const { pathname, search } = useLocation();
    const { origin } = window.location;
    const copyUrl = `${origin}${pathname}${search}`;
    const history = useHistory();
    const { canEditSeat } = useUserAccess();

    const handlePostDelete = () => {
        history.replace(ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.BRAND_SAFETY));
    };

    return aDomain ? (
        <Row data-sdet={ADOMAIN_DRAWER_TITLE_SDET} gutter={[0, 16]} style={{ marginRight: "1rem" }}>
            <Col span={24}>
                <Row justify="space-between">
                    <Col>
                        <Row gutter={[8, 4]} style={{ alignItems: "baseline" }}>
                            <Col>
                                <Typography.Title
                                    data-sdet={ADOMAIN_DRAWER_TITLE_NAME_SDET}
                                    ellipsis={{
                                        tooltip: {
                                            title: `${aDomain.name}`,
                                            placement: "bottom",
                                        },
                                    }}
                                    level={5}
                                    style={{
                                        margin: 0,
                                        maxWidth: "18rem",
                                    }}
                                >
                                    {aDomain.name}
                                </Typography.Title>
                            </Col>
                            <Col>
                                {aDomain.id && (
                                    <GoToEntityLink
                                        entity="Advertiser Domain"
                                        linkTo={ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAIN_DETAILS(
                                            seatId,
                                            aDomain.id
                                        )}
                                        copyUrl={copyUrl}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <HelpKeysButton
                            onClick={() => {
                                const nextQueryString = uri.setSearchParam(search, "drawer", "definitions");
                                history.push({ search: nextQueryString });
                            }}
                            buttonName="Advertiser Domains"
                        />
                    </Col>
                </Row>
            </Col>
            {canEditSeat && (
                <Col span={24}>
                    <Row data-sdet={ADOMAIN_DETAILS_ACTIONS_SDET}>
                        <Col>
                            <AdvertiserDomainActions
                                seatId={seatId}
                                aDomainId={aDomain.id}
                                name={aDomain.name}
                                postDeleteEvent={handlePostDelete}
                            />
                        </Col>
                    </Row>
                </Col>
            )}
        </Row>
    ) : null;
};
