import { FC } from "react";
import { Form, Radio } from "antd";
import { INVENTORY_YES_NO_OPTIONS, AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useAdUnitFormInstance } from "../../../AdUnitFormInstance";

export const UnwrapVast: FC = () => {
    const { seat } = useAdUnitFormInstance();
    if (!seat?.allowVastUnwrapping) {
        return null;
    }
    return (
        <Form.Item name={AD_UNIT_FORM_FIELDS.UNWRAP_VAST.name} label={AD_UNIT_FORM_FIELDS.UNWRAP_VAST.label}>
            <Radio.Group options={INVENTORY_YES_NO_OPTIONS} />
        </Form.Item>
    );
};
