import { FC, ReactNode } from "react";
import { Button, Col, Row, Space } from "antd";
import { css } from "@emotion/css";

interface Props {
    onCancel: () => void;
    onSubmit?: () => void;
    submitLabel?: string;
    cancelLabel?: string;
    isLoading?: boolean;
    suffixAddOn?: ReactNode;
    endAddOn?: ReactNode;
}

export const fixedBottomButtonsStyle = css(`
position: sticky;
bottom: 0;
z-index: 999;
background: #FFF;
width: 100%;
padding: 12px 12px;
box-shadow: 0 1px 4px #00152914;
`);

export const FixedSaveButtons: FC<Props> = ({
    isLoading = false,
    onCancel,
    onSubmit,
    cancelLabel = "Cancel",
    submitLabel = "Submit",
    suffixAddOn,
    endAddOn,
}) => {
    return (
        <div className={fixedBottomButtonsStyle}>
            <Row justify="space-between">
                <Col>
                    <Space direction="horizontal">
                        <Button type="primary" htmlType="submit" onClick={() => onSubmit?.()} loading={isLoading}>
                            {submitLabel}
                        </Button>
                        <Button onClick={onCancel}>{cancelLabel}</Button>
                        {suffixAddOn}
                    </Space>
                </Col>
                {endAddOn && <Col>{endAddOn}</Col>}
            </Row>
        </div>
    );
};
