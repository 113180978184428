import { SEARCH } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { SeparationGroup, useGetSeatSeparationGroupByIdQuery } from "@app/core/services";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

interface UseCompetitiveSeparationGroupsDetailsDrawer {
    open: boolean;
    showDefinitionContent: boolean;
    data: SeparationGroup;
    isLoading: boolean;
    handleClose: () => void;
    handleDefinition: () => void;
    handleBack: () => void;
    handlePostDeleteEvent: () => void;
    handleAfterOpenChange: (open: boolean) => void;
}

export const useCompetitiveSeparationGroupsDetailsDrawer = (
    seatId: string,
    id: string
): UseCompetitiveSeparationGroupsDetailsDrawer => {
    const history = useHistory();
    const { search } = useLocation();
    const searchValue = new URLSearchParams(search).get(SEARCH.key) || "";
    const nextSearchParams = searchValue ? `${SEARCH.key}=${searchValue}` : "";
    const [open, setOpen] = useState<boolean>(false);
    const [showDefinitionContent, setShowDefinitionContent] = useState<boolean>(false);

    const { data, isFetching } = useGetSeatSeparationGroupByIdQuery(Number(id));

    const handleClose = () => {
        setOpen(false);
        setShowDefinitionContent(false);
    };

    const handleDefinition = () => setShowDefinitionContent(true);

    const handleBack = () => setShowDefinitionContent(false);

    const handlePostDeleteEvent = () => {
        history.replace({
            pathname: ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS(seatId),
            search: nextSearchParams,
        });
    };

    const handleAfterOpenChange = (open: boolean) => {
        !open && handlePostDeleteEvent();
    };

    useEffect(() => {
        setOpen(true);
    }, []);

    return {
        open,
        showDefinitionContent,
        data: data as SeparationGroup,
        isLoading: isFetching,
        handleClose,
        handleDefinition,
        handleBack,
        handlePostDeleteEvent,
        handleAfterOpenChange,
    };
};
