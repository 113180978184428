import { useEffect } from "react";
import { consoleApi, TIME_ZONES_TAG } from "./console";

export interface SimpleTimeZone {
    creationDate: string;
    updateDate: string;
    id: number;
    code: string;
    name: string;
}
export interface TimeZone extends SimpleTimeZone {
    dayLightTime: boolean;
    dayLightSavingsOffset: number;
    utcOffset: number;
    entityType: "TimeZone";
}

export const timeZonesApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getTimeZones: builder.query<TimeZone[], void>({
            query: () => "timeZones",
            providesTags: [TIME_ZONES_TAG],
        }),
    }),
    overrideExisting: false,
});

export const { useGetTimeZonesQuery, usePrefetch: useTimeZonesPrefetch } = timeZonesApi;

export const useGetTimeZonesPrefetch = () => {
    const getTimeZonesPrefetch = useTimeZonesPrefetch("getTimeZones");

    useEffect(() => {
        getTimeZonesPrefetch();
    }, [getTimeZonesPrefetch]);
};

export const selectGetTimeZonesResult = timeZonesApi.endpoints.getTimeZones.select();

export const getTimeZoneLabel = (timeZone: { name: string; code: string; dayLightTime?: boolean }): string =>
    `${timeZone?.name}: ${timeZone?.code}${timeZone?.dayLightTime ? " (Daylight Sensitive)" : ""}`;
