import { FC, useEffect, useMemo, useState } from "react";
import { Col, Row, Space } from "antd";
import { EntityTypes, useInventoryHealthStats } from "@app/features/adStats";
import { Last24HoursStatsMetrics } from "../Last24HoursStatsMetrics";
import { Last24HoursPerformanceStatsGraph } from "../Last24HoursPerformanceStatsGraph";
import { SnapshotsControls } from "../Snapshots";
import { useUnitPrefetchSnapshos } from "./useUnitSnapshotPrefetch";
import { PerformanceStatsTypeButtons } from "../../PerformanceStatsTypeButtons/PerformanceStatsTypeButtons";
import { SnapshotsEntityTypes } from "@app/core/services/adStats/statsSnapshots";

interface Props {
    adStatsEntity: EntityTypes;
    snapShotEntity: SnapshotsEntityTypes;
    id: number | string;
    handlePerformanceTypeSwitch: (isLiveActive: boolean) => void;
}

export const Last24HoursStatsTabContent: FC<Props> = ({
    adStatsEntity,
    snapShotEntity,
    id,
    handlePerformanceTypeSwitch,
}) => {
    const { setEntityIds, adStatsById, adStatsHistoryById } = useInventoryHealthStats(adStatsEntity);
    const adStat = useMemo(() => adStatsById[id], [adStatsById, id]);
    const adStatsHistory = useMemo(() => adStatsHistoryById[id], [adStatsHistoryById, id]);
    const [isExtended, setIsExtended] = useState(false);

    useUnitPrefetchSnapshos(snapShotEntity);

    useEffect(() => setEntityIds([Number(id)]), [id, setEntityIds]);

    const handleExtend = () => {
        setIsExtended(!isExtended);
    };

    return (
        <Space direction="vertical" size={50} style={{ display: "flex" }}>
            <Row gutter={[16, 16]}>
                <Col span={isExtended ? 12 : 6}>
                    <Last24HoursStatsMetrics handleExtend={handleExtend} isExtended={isExtended} adStat={adStat} />
                </Col>
                <Col span={isExtended ? 12 : 18}>
                    <Row
                        justify={"space-between"}
                        style={{
                            margin: "0 0 16px 0",
                            padding: "0 16px",
                        }}
                    >
                        <PerformanceStatsTypeButtons handlePerformanceTypeSwitch={handlePerformanceTypeSwitch} />
                        <Col>
                            <SnapshotsControls message="" id={id} />
                        </Col>
                    </Row>
                    {adStatsHistory && <Last24HoursPerformanceStatsGraph adStat={adStatsHistory || null} />}
                </Col>
            </Row>
        </Space>
    );
};
