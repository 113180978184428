import { FC } from "react";
import { Button, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useUserAccess } from "@app/core/auth";
import { isAdSourceEditEligible } from "@app/features/seatAdSources/utils";
import { useSeatAdSourcesPageNavigator } from "@app/features/seatAdSources/utils/index";

interface Props {
    adSourceId: number;
}

export const SeatAdSourcesEditActionButton: FC<Props> = ({ adSourceId }) => {
    const { canEditSeat } = useUserAccess();
    const { goToSeatAdSourcesEditPage } = useSeatAdSourcesPageNavigator();

    if (!isAdSourceEditEligible(canEditSeat)) {
        return null;
    }

    return (
        <Tooltip title="Edit">
            <Button
                data-sdet="seat-ad-sources-edit-action-button"
                onClick={() => goToSeatAdSourcesEditPage(adSourceId)}
                icon={<EditOutlined />}
            />
        </Tooltip>
    );
};
