import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { useGetSupplyVideoQualityQuery } from "@app/core/services/console";

interface UseVideoQuality {
    isFetching: boolean;
    options: LabeledValue[];
}

export const useVideoQuality = (): UseVideoQuality => {
    const { data, isFetching } = useGetSupplyVideoQualityQuery();

    const options = useMemo(() => (data || []).map(({ id, name }) => ({ value: id, label: name })), [data]);
    return {
        isFetching,
        options,
    };
};
