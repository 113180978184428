import { Button, Checkbox, Col, Collapse, Form, Layout, Row, Space, Table, Typography } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { TouchedFieldInfo, selectTouchedRecordsInfo } from "../../reducer";
import { format } from "@rubicon/utils";
import { gray1, gray5 } from "@rubicon/antd-components";
import { isPresent } from "../../helpers";
import { isMoment } from "moment-timezone";
import { YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE } from "@app/core/components/constants";
import { BULK_OPERATION_FORM_ITEMS } from "../../constants";
import { BulkOperationEditConfirmationStepBottomButtons } from "./BulkOperationConfirmationStepBottomButtons/BulkOperationEditConfirmationStepBottomButtons";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDisplayValue = (v: any) => {
    if (!isPresent(v)) {
        return format.constants.DEFAULT_DASHES;
    } else if (isMoment(v)) {
        return v.format(YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE);
    } else if (Array.isArray(v)) {
        return v.map(({ label }) => label).join(", ");
    } else if (typeof v === "object") {
        return v.label;
    } else if (typeof v === "boolean") {
        return v ? "Yes" : "No";
    }
    return v;
};
export const BulkOperationEntitiesEditReview: FC<{ goToPrev: () => void }> = ({ goToPrev }) => {
    const form = Form.useFormInstance();
    const { entityType } = form.getFieldsValue(true);
    const touchedRecordsInfo = useSelector((state) => selectTouchedRecordsInfo(state, entityType));

    const changedRecords = useMemo(
        () =>
            Object.entries(touchedRecordsInfo)
                .map(([id, touchedFieldsInfo]) => [Number(id), touchedFieldsInfo?.filter(({ isChanged }) => isChanged)])
                .filter(([_, touchedFieldsInfo]: [number, TouchedFieldInfo[] | undefined]) =>
                    Boolean(touchedFieldsInfo?.length)
                ),
        [touchedRecordsInfo]
    ) as [number, TouchedFieldInfo[]][];

    const changedRecordIds = useMemo(
        () => changedRecords.map(([id]: [number, TouchedFieldInfo[]]) => id),
        [changedRecords]
    );

    const [includedJobIds, setIncludedJobIds] = useState<number[]>(changedRecordIds);
    const [activeKey, setActiveKey] = useState<number[]>(changedRecordIds);

    useEffect(() => {
        form.validateFields([BULK_OPERATION_FORM_ITEMS.OPERATION_ENTITIES_EDIT_REVIEW_ERROR_MESSAGE.name]);
    }, [includedJobIds, form]);

    const includedJobsCount = includedJobIds.length;

    const items = changedRecords.map(([id, touchedFieldsInfo]: [number, TouchedFieldInfo[]], i) => ({
        key: id,
        label: (
            <Typography.Title level={5} style={{ margin: 0 }}>
                {touchedFieldsInfo[0].name || format.constants.DEFAULT_DASHES}
            </Typography.Title>
        ),
        children: (
            <Table
                size="small"
                dataSource={touchedFieldsInfo}
                columns={[
                    { key: "title", dataIndex: "title", title: "Field Name" },
                    {
                        key: "originValue",
                        dataIndex: "originValue",
                        title: "Changed From",
                        render: (originValue) => getDisplayValue(originValue),
                    },
                    {
                        key: "currentValue",
                        dataIndex: "currentValue",
                        title: "Changed To",
                        render: (currentValue) => getDisplayValue(currentValue),
                    },
                ]}
                rowKey="field"
                pagination={false}
                showSorterTooltip={false}
                style={{ border: `solid ${gray5}`, marginLeft: "24px" }}
            />
        ),
        extra: (
            <Checkbox
                checked={includedJobIds.includes(id)}
                onClick={(e) => {
                    // do not trigger collapse on click checkbox
                    e.stopPropagation();

                    includedJobIds.includes(id)
                        ? setIncludedJobIds(includedJobIds.filter((v) => v !== id))
                        : setIncludedJobIds([...includedJobIds, id]);
                }}
            >
                Include in job
            </Checkbox>
        ),
        style: { border: `solid ${gray5}`, borderWidth: i === 0 ? "1px" : "0 1px 1px" },
    }));

    return (
        <>
            <Layout.Content>
                <Row align="middle" justify="space-between" style={{ marginBottom: "1rem" }}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name={BULK_OPERATION_FORM_ITEMS.OPERATION_ENTITIES_EDIT_REVIEW_ERROR_MESSAGE.name}
                            rules={[
                                {
                                    validator: () =>
                                        includedJobIds.length
                                            ? Promise.resolve()
                                            : Promise.reject("Please include at least one entity"),
                                },
                            ]}
                            style={{ marginBottom: 0 }}
                        >
                            <Typography.Text>
                                {`The ${includedJobsCount} ${
                                    includedJobsCount === 1 ? "entity" : "entities"
                                } listed below are currently included in this job.`}{" "}
                            </Typography.Text>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Space size="middle">
                            <Button.Group>
                                <Button
                                    disabled={includedJobIds.length === changedRecords.length}
                                    onClick={() => setIncludedJobIds(changedRecordIds)}
                                >
                                    Include All In Job
                                </Button>
                                <Button disabled={!includedJobIds.length} onClick={() => setIncludedJobIds([])}>
                                    Remove All From Job
                                </Button>
                            </Button.Group>
                            <Button.Group>
                                <Button
                                    disabled={activeKey.length === changedRecords.length}
                                    onClick={() => setActiveKey(changedRecordIds)}
                                >
                                    Expand All
                                </Button>
                                <Button disabled={!activeKey.length} onClick={() => setActiveKey([])}>
                                    Collapse All
                                </Button>
                            </Button.Group>
                        </Space>
                    </Col>
                </Row>
                <Collapse
                    ghost
                    items={items}
                    activeKey={activeKey}
                    onChange={(activeKey: string[]) => {
                        setActiveKey(activeKey.map(Number));
                    }}
                    style={{ backgroundColor: gray1 }}
                />
            </Layout.Content>
            <BulkOperationEditConfirmationStepBottomButtons
                changedRecords={changedRecords}
                includedJobIds={includedJobIds}
                goToPrev={goToPrev}
                form={form}
            />
        </>
    );
};
