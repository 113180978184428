import { Targeting, TargetingCreatePayload, TargetingIncludeExclude } from "@app/core/services/console";
import { MaxDurationTarget, MinDurationTarget } from "@app/features/targeting";

const OR_ID = 2;

// TODO: This function is very inefficient due to the stringifies and parses,
// but saves us from having to have specific logic for each targeting type
// If this become a performance issue, rewrite to have separate logic depending on what key is being merged
const mergeTargetingKey = (key: string, source: TargetingIncludeExclude | null, target: TargetingIncludeExclude) => {
    const values = new Set<string>();

    if (source !== null && Array.isArray(source[key])) {
        source[key].forEach((value) => values.add(JSON.stringify(value)));
        target[key]?.forEach((value) => values.add(JSON.stringify(value)));
        target[key] = Array.from(values, (value) => JSON.parse(value));
    }
};

const getTargetingIncludeExclude = (): TargetingIncludeExclude => ({
    accountTypes: [],
    adBreakPositionTargets: [],
    adUnits: [],
    apiFrameworks: [],
    audiences: [],
    brands: [],
    bundleIdTargets: [],
    categories: [],
    contentCategories: [],
    contentChannels: [],
    contentLengths: [],
    contentNetworks: [],
    contentRatings: [],
    contentSeries: [],
    coppa: [],
    customRules: [],
    customTargets: [],
    dayPartTargets: [],
    dnt: [],
    genres: [],
    geoTargets: [],
    labelValues: [],
    liveStream: [],
    maxDurationTarget: [],
    mimeTypes: [],
    minDurationTarget: [],
    operatingSystems: [],
    oztamDemographics: [],
    platforms: [],
    pmpDealIdTargets: [],
    podSlotPositionTargets: [],
    producers: [],
    publishers: [],
    seats: [],
    segmentRules: [],
    segments: [],
    sizes: [],
    supply: [],
    supplyDomainTargets: [],
    supplyTypes: [],
    targetedSegments: [],
    timeZoneMode: null,
    videoIds: [],
});

const getTargetingBase = () => ({
    id: -1,
    name: null,
    include: getTargetingIncludeExclude(),
    exclude: getTargetingIncludeExclude(),
});

export const mergeTargeting = (targeting: Targeting[], targetingModeId: number): Targeting[] => {
    if (targetingModeId === OR_ID || !targeting.length || targeting.length === 1) {
        return targeting;
    }
    const targetingBase = getTargetingBase();

    targeting.forEach((target) => {
        if (target.include) {
            Object.keys(target.include).forEach((key) => mergeTargetingKey(key, target.include, targetingBase.include));
        }
        if (target.exclude) {
            Object.keys(target.exclude).forEach((key) => mergeTargetingKey(key, target.exclude, targetingBase.exclude));
        }
    });

    return [targetingBase];
};

export const targetingCreatePayloadToTargeting = (targeting: TargetingCreatePayload[]): Targeting[] => {
    const realTimeTargeting: Targeting[] = [];
    const conditionKeys: string[] = ["include", "exclude"];

    targeting.forEach((target) => {
        if (!target.hasOwnProperty("name") || target.name === null) {
            //todo:: find a better way to do below check!!
            if (
                target.include.hasOwnProperty("maxDurationTarget") ||
                target.exclude.hasOwnProperty("maxDurationTarget") ||
                target.include.hasOwnProperty("minDurationTarget") ||
                target.exclude.hasOwnProperty("minDurationTarget")
            ) {
                const override = { ...target };

                conditionKeys.forEach((condition) => {
                    if (target[condition].maxDurationTarget && target[condition].minDurationTarget) {
                        override[condition] = {
                            ...override[condition],
                            maxDurationTarget: [target[condition].maxDurationTarget as MaxDurationTarget],
                            minDurationTarget: [target[condition].minDurationTarget as MinDurationTarget],
                        };
                        realTimeTargeting.push(override as Targeting);
                    } else if (target[condition].maxDurationTarget) {
                        override[condition] = {
                            ...override[condition],
                            maxDurationTarget: [target[condition].maxDurationTarget as MaxDurationTarget],
                        };
                        realTimeTargeting.push(override as Targeting);
                    } else if (target[condition].minDurationTarget) {
                        override[condition] = {
                            ...override[condition],
                            minDurationTarget: [target[condition].minDurationTarget as MinDurationTarget],
                        };
                        realTimeTargeting.push(override as Targeting);
                    }
                });
            } else {
                realTimeTargeting.push(target as Targeting);
            }
        } else {
            realTimeTargeting.push(target as Targeting);
        }
    });

    return realTimeTargeting;
};
