import { NONE } from "@app/core/components/constants";
import { AdUnit } from "@app/core/services";
import { GUTTER } from "@app/features/inventory/constants";
import { Col, Form, Row } from "antd";
import { FC } from "react";
import { AD_UNIT_PLAYLIST_CONFIGURATION_LABELS, FORM_ITEM_OFFSET } from "../../../constants";
import { FillModeRow, PodEnforcement, SeparationGroups } from "../PlaylistConfiguration";

const { Item } = Form;

interface Props {
    adUnit: AdUnit;
}

export const AdPod: FC<Props> = ({ adUnit }) => {
    return (
        <div data-sdet="ad-unit-ad-pod">
            <Row gutter={GUTTER}>
                <Col span={8}>
                    <Item label={AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.isAdPod} className={FORM_ITEM_OFFSET}>
                        Yes
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col span={8}>
                    <Item label={AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.maxPodSeconds} className={FORM_ITEM_OFFSET}>
                        {adUnit?.maxPodSeconds ?? NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col span={8}>
                    <Item label={AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.maxAdsPerPod} className={FORM_ITEM_OFFSET}>
                        {adUnit?.maxAdsPerPod ?? NONE}
                    </Item>
                </Col>
            </Row>

            <FillModeRow name={adUnit?.adPodFillMode?.name} />
            <PodEnforcement name={adUnit?.podEnforcement?.name} />
            <SeparationGroups separationGroups={adUnit.separationGroups} />
        </div>
    );
};
