import { AdServingStat } from "./types";
import { AdSourceAdServingResultCode as ResultCode, AdSourceAdServingResultMap as ResultMap } from "./constants";
import { addAdServingStats, canHaveSuccessStats, getSumOfResultCodes, initializeAdServingStatWithTimes } from "./utils";
import moment from "moment-timezone";

export const getAdServingNTime = (stat: AdServingStat): number => stat?.ntime ?? 0;

export const getAdServingOTime = (stat: AdServingStat): number => stat?.otime ?? 0;

export const getAdServingResultsByCodeKey = (stat: AdServingStat, resultCode: number): number =>
    stat?.results?.[resultCode] ?? 0;

export const getAdServingResultCode = (resultCode: number): string => ResultMap[resultCode]?.code ?? "";

export const getAdServingResultDescription = (resultCode: number): string => ResultMap[resultCode]?.description ?? "";

export const getAdServingResultName = (resultCode: number): string => ResultMap[resultCode]?.name ?? "";

export const getAdServingResultLegendText = (resultCode: number): string => {
    if (!ResultMap[resultCode]) {
        return "";
    }
    const resultName = getAdServingResultName(resultCode);
    const resultDescription = getAdServingResultDescription(resultCode);
    return `${resultName}: ${resultDescription}`;
};

export const getAdServingOpportunities = (stat: AdServingStat): number => stat?.req ?? 0;

export const getAdServingFloors = (stat: AdServingStat): number => {
    const opportunities = getAdServingOpportunities(stat);
    const excludedByPriceFloor = getSumOfResultCodes(stat, [ResultCode.EXCLUDED_PRICE_BELOW_FLOOR]);
    return opportunities - excludedByPriceFloor;
};

export const getAdServingFrequencyCapping = (stat: AdServingStat): number => {
    const floors = getAdServingFloors(stat);
    const excludedByFrequencyCapping = getSumOfResultCodes(stat, [ResultCode.EXCLUDED_EXCEEDED_FREQUENCY_CAP]);
    return floors - excludedByFrequencyCapping;
};

export const getAdServingPacing = (stat: AdServingStat): number => {
    const frequencyCapping = getAdServingFrequencyCapping(stat);
    const excludedByPacing = getSumOfResultCodes(stat, [ResultCode.EXCLUDED_PACING]);
    return frequencyCapping - excludedByPacing;
};

export const getAdServingChosen = (stat: AdServingStat): number => {
    const pacing = getAdServingPacing(stat);
    const notChosen = getSumOfResultCodes(stat, [ResultCode.NOT_CHOSEN_RANDOMLY, ResultCode.NOT_CHOSEN_COMPETITION]);
    return pacing - notChosen;
};

export const getAdServingBidSuccess = (stat: AdServingStat): number | null => {
    if (!canHaveSuccessStats(stat)) {
        return null;
    }

    const chosen = getAdServingChosen(stat);
    const noBids = getSumOfResultCodes(stat, [
        ResultCode.CHOSEN_NO_COMPETITION_NO_BID,
        ResultCode.CHOSEN_RANDOM_NO_BID,
        ResultCode.CHOSEN_PACING_PRIORITIZED_NO_BID,
        ResultCode.NOT_CHOSEN_RANDOMLY_FALLBACK_NO_BID,
        ResultCode.NOT_CHOSEN_COMPETITION_FALLBACK_NO_BID,
    ]);
    return chosen - noBids;
};

export const getAdServingCreativeSuccess = (stat: AdServingStat): number | null => {
    if (!canHaveSuccessStats(stat)) {
        return null;
    }

    const bidSuccess = getAdServingBidSuccess(stat);
    if (bidSuccess === null) {
        return null;
    }

    const errors = getSumOfResultCodes(stat, [
        ResultCode.CHOSEN_NO_COMPETITION_ERROR,
        ResultCode.CHOSEN_RANDOM_ERROR,
        ResultCode.CHOSEN_PACING_PRIORITIZED_ERROR,
        ResultCode.NOT_CHOSEN_RANDOMLY_FALLBACK_ERROR,
        ResultCode.NOT_CHOSEN_COMPETITION_FALLBACK_ERROR,
    ]);
    return bidSuccess - errors;
};

export const getBucketedAdServingStats = (stats: AdServingStat[], granularityHours: number): AdServingStat[] => {
    if (!stats || stats.length === 0) {
        return [];
    }

    const finalBucketStats: AdServingStat[] = [];

    let workingStatSum = initializeAdServingStatWithTimes(stats[0].ntime, stats[0].otime);
    stats.forEach((nextStat) => {
        // compare the ntime differences between the next stat and working bucket to determine if a new bucket should be started
        const currentStatNtime = moment(nextStat.ntime);
        const lastBucketNtime = moment(workingStatSum.ntime);
        const hoursSinceLastBucket = moment.duration(currentStatNtime.diff(lastBucketNtime)).asHours();
        const lastBucketComplete = hoursSinceLastBucket > granularityHours;

        if (lastBucketComplete) {
            finalBucketStats.push(workingStatSum);
            workingStatSum = initializeAdServingStatWithTimes(nextStat.ntime, nextStat.otime);
        } else {
            workingStatSum = addAdServingStats(workingStatSum, nextStat, workingStatSum.ntime, workingStatSum.otime);
        }
    });

    return finalBucketStats;
};
