import { consoleApi, ENRICHMENT_COST_MODELS_TAG } from "./console";

interface EnrichmentCostModel {
    creationDate: string;
    entityType: "EnrichmentCostModel";
    id: number;
    name: string;
    updateDate: string;
}

export const enrichmentCostModels = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getEnrichmentCostModels: builder.query<EnrichmentCostModel[], void>({
            query: () => "enrichmentCostModels",
            providesTags: [ENRICHMENT_COST_MODELS_TAG],
        }),
    }),
});

export const { useGetEnrichmentCostModelsQuery } = enrichmentCostModels;
