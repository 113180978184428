import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { BulkOperationsPage } from "./BulkOperationsPage";
import { BulkOperationCreatePage } from "./BulkOperationCreatePage";

export const BulkOperationsSwitch: FC = () => (
    <Switch>
        <Route exact path={ROUTES.SEAT_CONTROLS_BULK_OPERATIONS}>
            <BulkOperationsPage />
        </Route>
        <Route exact path={ROUTES.SEAT_CONTROLS_BULK_OPERATIONS_CREATE}>
            <BulkOperationCreatePage />
        </Route>
    </Switch>
);
