import { FC } from "react";
import { AdSource } from "@app/core/services";
import { Card, Space, Typography, Collapse } from "antd";
import {
    cardBodyStyle,
    sectionHeaderCollapseStyle,
    secondaryStepsSectionClassList,
} from "@app/features/deals/DealForm";
import { AdSourceDealsListTable } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceDemandSection/AdSourceDealsListTable";

interface AdSourceDemandSectionProps {
    dealAdSourceData: AdSource | undefined;
}

export const AdSourceDemandSection: FC<AdSourceDemandSectionProps> = ({ dealAdSourceData }) => {
    return (
        <Collapse
            className={secondaryStepsSectionClassList}
            defaultActiveKey={["0"]}
            items={[
                {
                    key: "0",
                    style: sectionHeaderCollapseStyle,
                    children: (
                        <Card bordered={false} styles={{ body: cardBodyStyle }}>
                            <AdSourceDealsListTable dealAdSourceData={dealAdSourceData} />
                        </Card>
                    ),
                    label: (
                        <Space
                            align="center"
                            style={{ fontSize: "1rem" }}
                            data-sdet="deal-form-ad-source-demand-section"
                        >
                            Demand Section
                            <Typography.Text style={{ fontWeight: "normal" }}>
                                (Deals that use this Ad Source Container)
                            </Typography.Text>
                        </Space>
                    ),
                },
            ]}
        />
    );
};
