import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { Form, Input } from "antd";
const MAX_CHARS = 36;
export const BillingCode = () => {
    return (
        <Form.Item
            label={CHANNEL_FORM_FIELDS.BILLING_CODE.label}
            name={CHANNEL_FORM_FIELDS.BILLING_CODE.name}
            wrapperCol={{ xs: 24, sm: 24, md: 10, lg: 9, xl: 12, xxl: 11 }}
            rules={[
                { required: true, message: CHANNEL_FORM_FIELDS.BILLING_CODE.rulesMessage },
                { max: MAX_CHARS, message: CHANNEL_FORM_FIELDS.BILLING_CODE.rulesMaxCharsMessage },
            ]}
            normalize={(value) => value?.trim()}
        >
            <Input placeholder={CHANNEL_FORM_FIELDS.BILLING_CODE.placeholder} allowClear />
        </Form.Item>
    );
};
