import { useUserAccess } from "@app/core/auth";
import { useOpenHelpCenter } from "@app/core/services/console";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { NavMenuItemProps } from "../Nav";
import { getMoreMenuItems } from "./helpers";

interface UseMoreSubMenuItems {
    moreSubMenuItems: NavMenuItemProps[];
    hasMoreMenu: boolean;
}

export const useMoreSubMenuItems = (contextId: string | number, handleMenuClose: () => void): UseMoreSubMenuItems => {
    const history = useHistory();
    const { seatAccessFlags } = useUserAccess();
    const openHelpCenter = useOpenHelpCenter();
    const [moreSubMenuItems, setMoreSubMenuItems] = useState<NavMenuItemProps[]>(
        getMoreMenuItems(handleMenuClose, openHelpCenter)
    );
    const hasMoreMenu = Boolean(moreSubMenuItems.length);

    useEffect(() => {
        setMoreSubMenuItems(getMoreMenuItems(handleMenuClose, openHelpCenter));
    }, [seatAccessFlags, contextId, history, handleMenuClose, openHelpCenter]);

    return {
        moreSubMenuItems,
        hasMoreMenu,
    };
};
