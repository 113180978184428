import { useUserAccess } from "@app/core/auth";
import {
    SUPPLY_CTV_TYPE,
    IFA_SPECIFY_MODE,
    SUPPLY_FORM_FIELDS,
} from "@app/features/inventory/HierarchyForms/constants";
import { useSupplyFormInstance } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormInstance";

interface UseSupplyDetailsSection {
    isIfaTypeShown: boolean;
    isIfaModeShown: boolean;
    isViewabilityVendorsShown: boolean;
}

export const useSupplyDetailsSection = (): UseSupplyDetailsSection => {
    const { useWatch } = useSupplyFormInstance();
    const { isSysAdmin } = useUserAccess();
    const type: number = useWatch(SUPPLY_FORM_FIELDS.TYPE.name);
    const ifaMode: boolean = useWatch(SUPPLY_FORM_FIELDS.IFA_MODE.name);

    const isDynamic: boolean = useWatch(SUPPLY_FORM_FIELDS.MODE.name);
    const isCTVType: boolean = type === SUPPLY_CTV_TYPE.value;

    const isIfaTypeShown: boolean = ifaMode === IFA_SPECIFY_MODE;
    const isIfaModeShown: boolean = isCTVType && !isDynamic;
    const isViewabilityVendorsShown: boolean = isCTVType && isSysAdmin;

    return { isIfaTypeShown, isIfaModeShown, isViewabilityVendorsShown };
};
