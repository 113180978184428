import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { setFiltersStatus } from "@app/features/deals/DealListPage/reducer";
import { useGetDealStatusQuery } from "@app/core/services";

interface UseStatusFilter {
    handleChange: (value: string, option: LabeledValue[]) => void;
    options: LabeledValue[];
    isLoading: boolean;
    value: LabeledValue[] | null;
    areAllOptionsSelected: boolean;
    toggleSelectDeselectAll: () => void;
}

export const useStatusFilter = (): UseStatusFilter => {
    const dispatch = useAppDispatch();
    const { data, isLoading } = useGetDealStatusQuery();
    const status = useAppSelector((state) => state.deals.filters.filterStatus);

    const options = useMemo(
        () =>
            (data || []).map((status) => ({
                value: status.id,
                label: status.name,
            })),
        [data]
    );

    const handleChange = (value: string | undefined, option: LabeledValue[]) => {
        dispatch(setFiltersStatus(option));
    };

    const areAllOptionsSelected = options.length === status?.length;

    const toggleSelectDeselectAll = () => {
        if (areAllOptionsSelected) {
            handleChange(undefined, []);
        } else {
            handleChange(undefined, options);
        }
    };

    return {
        options,
        handleChange,
        isLoading,
        value: status,
        areAllOptionsSelected,
        toggleSelectDeselectAll,
    };
};
