import { FC } from "react";
import { Select } from "antd";
import { useSearchContextInputGroup } from "@app/core/components/SearchContextInputGroup/useSearchContextInputGroup";
import { OptionType } from "@app/features/login/Steps/hooks/useContextSelectForm";

interface SearchContextInputGroupProps {
    handleSelect?: (value: number | string) => void;
}

export const SearchContextInputGroup: FC<SearchContextInputGroupProps> = ({ handleSelect }) => {
    const { isLoading, options, search, handleChangeSearch, handleChangeValue, value } = useSearchContextInputGroup();

    const handleSelectValue = (value: number | null, option: OptionType): void => {
        if (value !== null) {
            handleSelect?.(value);
            handleChangeValue(value, option);
        }
    };
    return (
        <Select
            style={{ width: "100%" }}
            allowClear
            autoClearSearchValue={false}
            loading={isLoading}
            onSearch={handleChangeSearch}
            onSelect={handleSelectValue}
            placeholder="e.g. seat"
            filterOption={false}
            options={options}
            searchValue={search}
            showSearch
            value={value}
        />
    );
};
