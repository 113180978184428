import { Col, Form } from "antd";
import { useCurrencySelect } from "./useCurrencySelect";
import { AD_SOURCE_FIELDS, COL_SIZES, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";

export const CurrencySelect = () => {
    const { currencies, isLoading, isVisible, isReadOnly, isRequiredRule, onChange } = useCurrencySelect();

    if (!isVisible) {
        return null;
    }
    return (
        <Col {...COL_SIZES}>
            <Form.Item
                name={AD_SOURCE_FIELDS.CURRENCY.name}
                label={AD_SOURCE_FIELDS.CURRENCY.label}
                data-sdet={`test-${AD_SOURCE_FIELDS.CURRENCY.name}-field`}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                rules={[isRequiredRule]}
                help={isReadOnly ? "Inherited from Demand Deal" : undefined}
                {...VERTICAL_LAYOUT_FIX}
            >
                <SelectWithValue
                    disabled={isReadOnly}
                    placeholder={`Select ${AD_SOURCE_FIELDS.CURRENCY.label}`}
                    data-sdet={`test-${AD_SOURCE_FIELDS.CURRENCY.name}-select`}
                    loading={isLoading}
                    showSearch
                    options={currencies}
                    optionFilterProp="label"
                    fieldAsLabel={["code"]}
                    fieldAsValue={["id"]}
                    onChange={onChange}
                />
            </Form.Item>
        </Col>
    );
};
