import { FC } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { useHistory } from "react-router-dom";
import { Publisher } from "@app/core/services";

interface PublisherEditPageHeaderProps {
    publisher: Publisher;
}

export const PublisherEditPageHeader: FC<PublisherEditPageHeaderProps> = ({ publisher }) => {
    const history = useHistory();
    const onBack = (): void => history.goBack();
    return <PageHeader title={`Edit Publisher: ${publisher.name}`} subTitle={publisher.code} onBack={onBack} />;
};
