import { FC, useState } from "react";
import { Form, Card, Alert, Typography, Button, Collapse } from "antd";
import { TargetingFormKeys } from "@app/features/targeting/constants";
import { mainStepSectionClassList, AD_SOURCE_SECTIONS_NAME, AD_SOURCE_FIELDS } from "../../../constants";
import { useIsPbsDealType } from "../../useIsPbsDealType";
import { AdSourcesForm } from "../..";
import { cardBodyStyle } from "@app/features/seatAdSources/constants";
import { AdSourceTargetingSectionHeader } from "@app/features/targeting/TargetingSectionHeader/AdSourceTargetingSectionHeader";
import { CreateTargeting } from "@app/features/targeting";
import { useAppSelector } from "@app/core/store";
import {
    getAudiencesFromTargetingForm,
    getSegmentsFromTargetingForm,
    getIsRangedCost,
} from "../EnrichmentCostSection/utils";
import { OneLevelEcDetailsDrawer } from "../EnrichmentCostSection/EnrichmentCostDetailsDrawer/OneLevelEcDetailsDrawer";

export const AND_ID = 1;
export const OR_ID = 2;

export const TargetingSection: FC = () => {
    const isPbsDealType = useIsPbsDealType();
    const isVisible = !isPbsDealType;
    const operation = useAppSelector((state) => state.targeting.targetingFormsByKey.adSource.targetingOperation);

    const existingAdSourceTargeting = Form.useWatch<AdSourcesForm["targeting"]>(
        AD_SOURCE_FIELDS.READ_ONLY_TARGETING.name
    );
    const adSourceLevelAudiences = useAppSelector((state) =>
        getAudiencesFromTargetingForm(state.targeting.targetingFormsByKey.adSource.targetingBlocks)
    );
    const adSourceLevelSegments = useAppSelector((state) =>
        getSegmentsFromTargetingForm(state.targeting.targetingFormsByKey.adSource.targetingBlocks)
    );

    const hasAudienceTargeting = Boolean(adSourceLevelAudiences.length);
    const hasSegmentTargeting = Boolean(adSourceLevelSegments.length);
    const [isSegmentsDrawerOpen, setIsSegmentsDrawerOpen] = useState(false);

    const adSourceType = Form.useWatch<AdSourcesForm["type"]>(AD_SOURCE_FIELDS.TYPE.name);
    const adSourceCurrency =
        Form.useWatch<AdSourcesForm["currencyType"]>(AD_SOURCE_FIELDS.CURRENCY.name)?.code || "USD";

    const isRangedCost = getIsRangedCost(adSourceType);

    if (!isVisible) {
        return null;
    }

    return (
        <Collapse
            bordered
            ghost={false}
            className={mainStepSectionClassList}
            defaultActiveKey={["1"]}
            items={[
                {
                    key: "1",
                    label: AD_SOURCE_SECTIONS_NAME.TARGETING,
                    forceRender: true,
                    children: (
                        <Card bordered={false} styles={{ body: cardBodyStyle }}>
                            <AdSourceTargetingSectionHeader
                                operation={operation}
                                targetingBlocksCount={(existingAdSourceTargeting || []).length}
                                reusableTargetingBlocksCount={0} // TODO: implement this
                                dealsWithTargeting={0} // TODO: implement this
                            />
                            {(hasAudienceTargeting || hasSegmentTargeting) && (
                                <>
                                    <Alert
                                        type="info"
                                        showIcon
                                        banner
                                        style={{ alignItems: "baseline", marginBottom: "16px" }}
                                        message={
                                            <Typography.Text>
                                                Ad Source Audience Enrichment Cost will be updated if you target 3rd
                                                party data segments with associated CPM costs{" "}
                                                <Button onClick={() => setIsSegmentsDrawerOpen(true)} type="link">
                                                    View Details
                                                </Button>
                                            </Typography.Text>
                                        }
                                    />
                                    <OneLevelEcDetailsDrawer
                                        adSourceCurrencyCode={adSourceCurrency}
                                        isRangedCost={isRangedCost}
                                        mode="adSource"
                                        onClose={() => setIsSegmentsDrawerOpen(false)}
                                        audiences={adSourceLevelAudiences}
                                        segments={adSourceLevelSegments}
                                        visible={isSegmentsDrawerOpen}
                                    />
                                </>
                            )}
                            <CreateTargeting
                                formKey={TargetingFormKeys.AdSource}
                                operation={operation}
                                validationFormKey={TargetingFormKeys.AdSourceAdditionalTargeting}
                            />
                        </Card>
                    ),
                },
            ]}
        />
    );
};
