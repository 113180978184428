import { useEffect } from "react";
import { Form } from "antd";
import { usePrioritySelect } from "./usePrioritySelect";
import { AdSourceTypes, AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { SeatAdSourcesType } from "@app/core/services";

const { useFormInstance, useWatch } = Form;

export const PrioritySelect = () => {
    const { isLoading, priorities } = usePrioritySelect();
    const instance = useFormInstance();
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);

    useEffect(() => {
        if (adSourceType?.name === AdSourceTypes.FALLBACK_TAG) {
            instance.setFieldValue(AD_SOURCE_FIELDS.PRIORITY.name, {
                creationDate: "2014-05-22T18:12:27.000+0000",
                updateDate: "2014-05-22T18:12:27.000+0000",
                id: 16,
                name: "16",
                sort: 16,
                entityType: "Priority",
            });
        }
    }, [adSourceType, instance]);

    return (
        <Form.Item
            {...VERTICAL_LAYOUT_FIX}
            required
            label={AD_SOURCE_FIELDS.PRIORITY.label}
            name={AD_SOURCE_FIELDS.PRIORITY.name}
            rules={[{ required: true, message: "Priority is required" }]}
        >
            <SelectWithValue
                data-sdet="adsource-priority"
                disabled={adSourceType?.name === AdSourceTypes.FALLBACK_TAG}
                fieldAsLabel={["name"]}
                fieldAsValue={["id"]}
                loading={isLoading}
                optionFilterProp="label" // For internal LabelValue
                options={priorities}
                placeholder="Select…"
                showSearch
            />
        </Form.Item>
    );
};
