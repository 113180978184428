import { ReactNode } from "react";
import { format, formatNumber } from "@rubicon/utils";
import { MarketplaceInfo } from "@app/core/services";
import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED } from "@app/core/components/constants";
import { formatLocalDate, parseDateStringFromApi } from "@app/core/utils";
import { DEAL_TYPES } from "../../../DealFormDrawer/DealFormContent/DealFormSections/DealObjectSection/DealTermsSection/Fields";
import { AdSourcesForm } from "../../../SeatAdSourcesForm";

const { DEFAULT_DASHES } = format.constants;

export interface DemandTableRecord {
    key: number;
    buyerSeat: string;
    buyerStatus: string;
    currency: string;
    dealFreqCappings: MarketplaceInfo["dealFreqCappings"];
    deleted?: boolean;
    endDate: string;
    freqCapping: string;
    internalStatus: string;
    marketplaceInfo: MarketplaceInfo;
    name: string;
    network: string;
    rate?: string;
    searchIndex: string;
    startDate: string;
    targeting: string;
    timeZone: string;
    actions?: ReactNode;
}

export const getBuyerSeats = (
    buyerSeats?: MarketplaceInfo["buyerSeats"] | { buyerSeat: MarketplaceInfo["buyerSeats"][number] }[]
) => {
    return String(
        buyerSeats?.length
            ? buyerSeats?.map((buyerSeat) => {
                  if (buyerSeat.buyerSeat) {
                      return `${buyerSeat.buyerSeat?.code} [${buyerSeat.buyerSeat?.name}]`;
                  }
                  return `${buyerSeat?.code} [${buyerSeat?.name}]`;
              })
            : DEFAULT_DASHES
    );
};

export const formatInheritedValue = (value: string | number | null | undefined) => {
    if (!value) {
        return DEFAULT_DASHES;
    }
    return `${value} *`;
};

export const getDataSource = (
    marketplaceInfoList: MarketplaceInfo[],
    adSourceLevelData: {
        adSourceFloorType: AdSourcesForm["adSourceFloorType"];
        currency: AdSourcesForm["currencyType"];
        endDate: AdSourcesForm["bookingEndDate"];
        rate: AdSourcesForm["bookingPrice"] | null;
        startDate: AdSourcesForm["bookingStartDate"];
        timeZone: AdSourcesForm["timeZone"] | null;
        type: AdSourcesForm["type"];
    }
): DemandTableRecord[] => {
    return marketplaceInfoList.map((marketplaceInfo, index) => {
        const isLegacyDeal = marketplaceInfo.deal.dealType.name === DEAL_TYPES.DEAL_TYPE_LEGACY.name;

        const demandCurrencyCode = marketplaceInfo.currencyType?.code;
        const dealCurrencyCode = marketplaceInfo.deal.currencyType?.code;
        const adSourceCurrencyCode = adSourceLevelData.currency?.code;

        const dealRate = marketplaceInfo.deal.rate;
        const adSourceRate = adSourceLevelData.rate;

        const dealTimeZoneCode = marketplaceInfo.deal.timeZone?.code;
        const adSourceTimeZoneCode = adSourceLevelData.timeZone?.code;

        const demandStartDate = parseDateStringFromApi(marketplaceInfo.startDate, adSourceTimeZoneCode);
        const dealStartDate = parseDateStringFromApi(marketplaceInfo.deal.startTime, dealTimeZoneCode);

        const demandEndDate = parseDateStringFromApi(marketplaceInfo.endDate, adSourceTimeZoneCode);
        const dealEndDate = parseDateStringFromApi(marketplaceInfo.deal.endTime, dealTimeZoneCode);

        const getCurrencyType = () => {
            if (isLegacyDeal && demandCurrencyCode) {
                return format.asSelf(demandCurrencyCode);
            }
            if (dealCurrencyCode) {
                return format.asSelf(dealCurrencyCode);
            }
            return formatInheritedValue(adSourceCurrencyCode);
        };
        const getRate = () => {
            if (isLegacyDeal) {
                return formatInheritedValue(
                    formatNumber.asMoney(adSourceRate, demandCurrencyCode || adSourceCurrencyCode)
                );
            }
            return formatNumber.asMoney(dealRate, dealCurrencyCode);
        };
        const getStartDate = (): string => {
            if (isLegacyDeal && demandStartDate) {
                return formatLocalDate(demandStartDate, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED);
            }
            if (dealStartDate) {
                return formatLocalDate(dealStartDate, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED);
            }
            return DEFAULT_DASHES;
        };
        const getEndDate = (): string => {
            if (isLegacyDeal && demandEndDate) {
                return formatLocalDate(demandEndDate, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED);
            }
            if (dealEndDate) {
                return formatLocalDate(dealEndDate, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED);
            }
            return DEFAULT_DASHES;
        };
        const getTimeZone = () => {
            if (isLegacyDeal || !marketplaceInfo.deal.timeZone?.name) {
                return format.asSelf(adSourceLevelData.timeZone?.name);
            }
            return format.asSelf(marketplaceInfo.deal.timeZone?.name);
        };

        const name = marketplaceInfo.deal?.name || "";
        const code = marketplaceInfo.deal?.code || "";

        const data: DemandTableRecord = {
            key: index,
            buyerSeat: getBuyerSeats(!isLegacyDeal ? marketplaceInfo.deal.buyerSeats : marketplaceInfo.buyerSeats),
            buyerStatus: !isLegacyDeal ? format.asSelf(marketplaceInfo.deal?.externalStatus) : DEFAULT_DASHES,
            currency: getCurrencyType(),
            dealFreqCappings: marketplaceInfo.dealFreqCappings,
            deleted: !isLegacyDeal && Boolean(marketplaceInfo.deal?.deleted),
            endDate: getEndDate(),
            freqCapping: marketplaceInfo.freqCappingType?.name || "",
            internalStatus: marketplaceInfo.deal.status?.name,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            marketplaceInfo,
            name,
            network: !isLegacyDeal ? marketplaceInfo.deal.network?.name : marketplaceInfo.network?.name,
            rate: getRate(),
            searchIndex: `${name.toLowerCase()}|${code.toLowerCase()}`,
            startDate: getStartDate(),
            targeting: format.asYesOrNo(marketplaceInfo.targeting?.length),
            timeZone: getTimeZone(),
        };
        return data;
    });
};
