import { Form } from "antd";
import { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { formatCategorySearchValue } from "@app/features/targeting";
import { IabCategory, useGetIabCategoriesQuery } from "@app/core/services";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { AdSourceAdvertiserDomainModeIds, AdSourceIabCategoryModeIds } from "@app/features/seatAdSources/constants";

interface UseAdSourceIabCategoriesField {
    search: string;
    disabled: boolean;
    onBlur: () => void;
    isFetching: boolean;
    value: IabCategory[];
    options: IabCategory[];
    isShowingEmptyWarning: boolean;
    onSearch: (value: string) => void;
    onChange: (value: IabCategory[]) => void;
}

const { useFormInstance } = Form;

export const useAdSourceIabCategoriesField = (): UseAdSourceIabCategoriesField => {
    const dispatch = useAppDispatch();
    const { validateFields } = useFormInstance();
    const [search, setSearch] = useState("");
    const { data = [], isFetching } = useGetIabCategoriesQuery();
    const orderedData = useMemo(
        () =>
            [...data]
                .sort((a, b) => a.code.localeCompare(b.code, undefined, { numeric: true }))
                .map((option) => ({ ...option, search: formatCategorySearchValue(option) }))
                .filter((option) => option.search.toLowerCase().includes(search.toLowerCase())),
        [data, search]
    );
    const value: IabCategory[] = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_IAB_CATEGORIES]
    );
    const adSourceMode: number | null = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE]
    );
    const disabled: boolean = adSourceMode === AdSourceAdvertiserDomainModeIds.ALLOW_OVERRIDE_INVENTORY;

    const iabCategoryBlockMode: number | null = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_IAB_CATEGORY_BLOCK_MODE]
    );
    const onChange = (value: IabCategory[]): void => {
        dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_IAB_CATEGORIES, value }));
        validateFields([CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES]);
    };

    return {
        value,
        search,
        disabled,
        onChange,
        isFetching,
        onSearch: setSearch,
        onBlur: () => setSearch(""),
        options: orderedData || [],
        isShowingEmptyWarning:
            iabCategoryBlockMode === AdSourceIabCategoryModeIds.BLOCK_OVERRIDE_INVENTORY && !value.length,
    };
};
