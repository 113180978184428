import { useState, useMemo } from "react";
import { useGetCuratorMarketplacesQuery } from "@app/core/services/console";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { useLoadOnScroll } from "@app/core/components";

const MAX = 50;

export const useCuratorMarketplaces = () => {
    const [pageByKeyword, setPageByKeyword] = useState({
        "": 1,
    });
    const [search, setSearch] = useState("");
    const [keyword, setKeyword] = useState("");
    const { data, isFetching, originalArgs } = useGetCuratorMarketplacesQuery({
        max: MAX,
        keyword,
        page: pageByKeyword[`${keyword}`] || 1,
    });
    const { loadMore, hasMore, options } = useLoadOnScroll(
        data,
        isFetching,
        `${originalArgs?.keyword || ""}`,
        originalArgs?.page || 1,
        data?.length === MAX,
        () =>
            setPageByKeyword((prev) => {
                const key = `${originalArgs?.keyword || ""}`;
                const currentPage = prev[key] || 1;
                return {
                    ...prev,
                    [key]: currentPage + 1,
                };
            })
    );

    const debouncedHandleSearch = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        []
    );

    const handleChangeSearch = (search: string): void => {
        setSearch(search);
        debouncedHandleSearch(search);
    };

    return {
        handleChangeSearch,
        hasMore,
        isFetching,
        loadMore,
        options,
        search,
    };
};
