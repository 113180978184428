import { FC } from "react";
import { Seat } from "@app/core/services";
import { Form, Card, Row, Col } from "antd";
import { useUserAccess } from "@app/core/auth";
import { getYesNoLabel } from "@app/core/utils/labels";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import { INVENTORY_FORM_FIELDS, SEAT_DETAILS_SECTION_COL_SIZES } from "@app/features/inventory/InventorySeat/constants";
import { AllowedFMTJSONOptions } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/AdvancedFeaturesForm/AdvancedFeaturesFormFields";

interface AdResponsesSectionProps {
    seat: Seat;
    edit?: boolean;
}

export const AdResponsesSection: FC<AdResponsesSectionProps> = ({ seat, edit }) => {
    const { isSysAdmin } = useUserAccess();

    return (
        <Card bordered={false}>
            <Row gutter={16}>
                <Col {...SEAT_DETAILS_SECTION_COL_SIZES}>
                    <AllowedFMTJSONOptions seat={seat} edit={edit} />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={24} sm={24} md={12} xl={5} xxl={4}>
                    <Form.Item
                        label={INVENTORY_FORM_FIELDS.EMIT_PRICEING_IN_VAST.label}
                        name={INVENTORY_FORM_FIELDS.EMIT_PRICEING_IN_VAST.name}
                    >
                        {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.emitPricingInVast)}
                    </Form.Item>
                </Col>
                {isSysAdmin && (
                    <>
                        <Col xs={24} sm={24} md={12} xl={5} xxl={4}>
                            <Form.Item
                                label={INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_DSP.label}
                                name={INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_DSP.name}
                            >
                                {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.allowVastExtensionDSP)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} xl={5} xxl={4}>
                            <Form.Item
                                label={INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_CRID.label}
                                name={INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_CRID.name}
                            >
                                {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.allowVastExtensionCRID)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} xl={5} xxl={4}>
                            <Form.Item
                                label={INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_ADOMAIN.label}
                                name={INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_ADOMAIN.name}
                            >
                                {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.allowVastExtensionAdomain)}
                            </Form.Item>
                        </Col>
                    </>
                )}
            </Row>
            {isSysAdmin && (
                <>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} xl={5} xxl={4}>
                            <Form.Item
                                label={INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_BRAND.label}
                                name={INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_BRAND.name}
                            >
                                {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.allowVastExtensionBrand)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} xl={5} xxl={4}>
                            <Form.Item
                                label={INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_BID_PRICE.label}
                                name={INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_BID_PRICE.name}
                            >
                                {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.allowVastExtensionBidPrice)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} xl={5} xxl={4}>
                            <Form.Item
                                label={INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_AD_SOURCE_TYPE.label}
                                name={INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_AD_SOURCE_TYPE.name}
                            >
                                {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.allowVastExtensionAdSourceType)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} xl={5} xxl={4}>
                            <Form.Item
                                label={INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_IAB_CATEGORY.label}
                                name={INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_IAB_CATEGORY.name}
                            >
                                {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.allowVastExtensionIabCategory)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} xl={5} xxl={4}>
                            <Form.Item
                                label={INVENTORY_FORM_FIELDS.ALLOW_AD_SOURCE_NAME_VAST_EXTENSION.label}
                                name={INVENTORY_FORM_FIELDS.ALLOW_AD_SOURCE_NAME_VAST_EXTENSION.name}
                            >
                                {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.allowVastExtensionAdSourceName)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} xl={5} xxl={4}>
                            <Form.Item
                                label={INVENTORY_FORM_FIELDS.ALLOW_AD_RESPONSE_PRICE_OVERRIDE.label}
                                name={INVENTORY_FORM_FIELDS.ALLOW_AD_RESPONSE_PRICE_OVERRIDE.name}
                            >
                                {edit ? (
                                    <BooleanRadioGroup />
                                ) : (
                                    getYesNoLabel(Boolean(seat.seatFlags?.adResponsePriceOverrideTypeEnabled))
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} xl={5} xxl={4}>
                            <Form.Item
                                label={INVENTORY_FORM_FIELDS.SEAT_FORMAT_OVERRIDE_ENABLED.label}
                                name={INVENTORY_FORM_FIELDS.SEAT_FORMAT_OVERRIDE_ENABLED.name}
                            >
                                {edit ? (
                                    <BooleanRadioGroup />
                                ) : (
                                    getYesNoLabel(Boolean(seat.seatFlags?.seatFormatOverrideEnabled))
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} xl={5} xxl={4}>
                            <Form.Item
                                label={INVENTORY_FORM_FIELDS.TELARIA_CHAIN_DISABLED.label}
                                name={INVENTORY_FORM_FIELDS.TELARIA_CHAIN_DISABLED.name}
                            >
                                {edit ? (
                                    <BooleanRadioGroup />
                                ) : (
                                    getYesNoLabel(Boolean(seat.seatFlags?.telariaChainDisabled))
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            )}
        </Card>
    );
};
