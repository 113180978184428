import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@app/core/store";

interface BrandSafetyBrand {
    isOpenBrandSupply?: boolean;
    openedBrandSupply: {
        id: number | null;
        name: string | null;
    };

    isOpenBrandAdUnit?: boolean;
    openedBrandAdUnit: {
        id: number | null;
        name: string | null;
    };
}

interface BrandSafetyAction {
    name: string;
    id: number;
    isOpenBrandSupply?: boolean;
    isOpenBrandAdUnit?: boolean;
}

const initialState: BrandSafetyBrand = {
    isOpenBrandAdUnit: false,
    isOpenBrandSupply: false,
    openedBrandAdUnit: {
        id: null,
        name: null,
    },
    openedBrandSupply: {
        id: null,
        name: null,
    },
};

const inventoryBrandSafetyBrand = createSlice({
    name: "inventoryBrandSafetyBrand",
    initialState,
    reducers: {
        openBrandSupply: (state, action: PayloadAction<BrandSafetyAction>) => {
            const { isOpenBrandSupply, id, name } = action.payload;
            state.isOpenBrandSupply = isOpenBrandSupply;

            state.openedBrandSupply = {
                id,
                name,
            };
        },
        closeBrandSupply: (state) => {
            state.isOpenBrandSupply = false;
            state.openedBrandSupply = {
                id: null,
                name: null,
            };
        },

        openBrandAdUnit: (state, action: PayloadAction<BrandSafetyAction>) => {
            const { isOpenBrandAdUnit, id, name } = action.payload;
            state.isOpenBrandAdUnit = isOpenBrandAdUnit;

            state.openedBrandAdUnit = {
                id,
                name,
            };
        },
        closeBrandAdUnit: (state) => {
            state.isOpenBrandAdUnit = false;
            state.openedBrandAdUnit = { id: null, name: null };
        },
    },
});

export const selectInventoryBrandSafetySupply = (state: RootState) => state.inventoryBrandSafetyBrand.isOpenBrandSupply;
export const selectInventoryBrandSafetySupplyDetils = (state: RootState) =>
    state.inventoryBrandSafetyBrand.openedBrandSupply;
export const selectInventoryBrandSafetyAdUnit = (state: RootState) => state.inventoryBrandSafetyBrand.isOpenBrandAdUnit;
export const selectInventoryBrandSafetyAdUnitDetils = (state: RootState) =>
    state.inventoryBrandSafetyBrand.openedBrandAdUnit;
export const { openBrandSupply, closeBrandSupply, openBrandAdUnit, closeBrandAdUnit } =
    inventoryBrandSafetyBrand.actions;
export default inventoryBrandSafetyBrand.reducer;
