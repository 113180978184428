import {
    PLATFORM_NOTIFICATIONS_TAG,
    PLATFORM_NOTIFICATIONS_ALL_TAG,
    PLATFORM_NOTIFICATIONS_COUNT_TAG,
    consoleApi,
} from "./console";
import { toQueryString } from "./utils";

interface Access {
    creationDate: string;
    updateDate: string;
    id: number;
    status: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string | null;
    };
}

export interface NotificationAction {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    details: string; // JSON string or path
}

export interface Notification {
    creationDate: string;
    updateDate: string;
    id: number;
    targetSeat: {
        id: number;
        name: string | null;
        code: string | null;
        entityType: "Seat";
    } | null;
    targetUser: {
        id: number;
        emailAddress: string | null;
        name: string;
    } | null;
    name: string;
    message: string;
    contentType: "String" | unknown;
    priority: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string | null;
    };
    actions: NotificationAction[];
}

export interface PlatformNotification {
    access: Access;
    notification: Notification;
}

export interface PlatformNotificationAllArgs {
    page?: number;
    max?: number;
}

export const platformNotificationsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getPlatformNotifications: builder.query<Notification[], void>({
            query: () => "/platformNotifications",
            providesTags: (_, err) => (err ? [] : [PLATFORM_NOTIFICATIONS_TAG]),
        }),
        getPlatformNotificationsAll: builder.query<PlatformNotification[], PlatformNotificationAllArgs>({
            query: (args?: PlatformNotificationAllArgs) => {
                const params = {
                    page: 1,
                    max: 20,
                    ...args,
                };
                return `/platformNotifications/all;${toQueryString(params)}}`;
            },
            providesTags: (_, err) => (err ? [] : [PLATFORM_NOTIFICATIONS_ALL_TAG]),
        }),
        getPlatformNotificationsCount: builder.query<number, void>({
            query: () => "/platformNotifications/count",
            providesTags: (_, err) => (err ? [] : [PLATFORM_NOTIFICATIONS_COUNT_TAG]),
        }),
        markPlatformNotificationAccessed: builder.mutation<void, number>({
            query: (id) => ({
                url: `/platformNotifications/${id}/accesses`,
                method: "PUT",
                body: {},
            }),
            invalidatesTags: (_, err) => (err ? [] : [PLATFORM_NOTIFICATIONS_TAG, PLATFORM_NOTIFICATIONS_COUNT_TAG]),
        }),
        markAllPlatformNotificationAccessed: builder.mutation<void, never>({
            query: () => ({
                url: `/platformNotifications/accesses/users`,
                method: "PUT",
                body: {},
            }),
            invalidatesTags: (_, err) => (err ? [] : [PLATFORM_NOTIFICATIONS_TAG, PLATFORM_NOTIFICATIONS_COUNT_TAG]),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetPlatformNotificationsAllQuery,
    useGetPlatformNotificationsCountQuery,
    useGetPlatformNotificationsQuery,
    useMarkPlatformNotificationAccessedMutation,
    useMarkAllPlatformNotificationAccessedMutation,
} = platformNotificationsApi;
