import { useSeatAuthContext, useUpdateSeatInContext } from "@app/core/auth";
import { useLoadOnScroll } from "@app/core/components";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { UpdateSeatPayload, useGetUsersQuery } from "@app/core/services";
import { FormInstance, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { LabeledValue } from "antd/lib/select";
import debounce from "lodash.debounce";
import { Store } from "rc-field-form/es/interface";
import { useMemo, useState } from "react";
import { useInternalContactsPage } from "../../useInternalContactsPage";
import { getInitialValueFromApi, getLabel, parseValueForApi } from "../helper";

const MAX = 200;

export const INTERNAL_CONTACT_IDS = {
    SALES: 1,
    ACCOUNT: 2,
    TECHNICAL: 3,
    BILLING: 4,
    D_SALES: 5,
    D_ACCOUNT: 6,
    D_TECHNICAL: 7,
    D_BILLING: 8,
};

export const INTERNAL_CONTACTS_FORM_FILEDS = {
    PUBLISHER_TECHNICAL_ACCOUNT: {
        name: "publisherTechnicalAccount",
        label: "Publisher Technical Account Contact",
    },
    PUBLISHER_ACCOUNT: {
        name: "publisherAccount",
        label: "Publisher Account Contact",
    },
    PUBLISHER_SALES: {
        name: "publisherSales",
        label: "Publisher Sales Contact",
    },
    PUBLISHER_BILLING: {
        name: "publisherBilling",
        label: "Publisher Billing Contact",
    },
    DEMAND_TECHNICAL_ACCOUNT: {
        name: "demandTechnicalAccount",
        label: "Demand Technical Account Contact",
    },
    DEMAND_ACCOUNT: {
        name: "demandAccount",
        label: "Demand Account Contact",
    },
    DEMAND_SALES: {
        name: "demandSalesContact",
        label: "Demand Sales Contact",
    },
    DEMAND_BILLING_ACCOUNT: {
        name: "demandBillingAccount",
        label: "Demand Billing Account",
    },
} as const;

export interface InternalContact {
    id: number | null;
    isPrimaryContact: boolean;
    type: {
        id: number;
    };
    user: {
        id: number;
    };
}

interface InternalContactsOption extends LabeledValue {
    internalContact: InternalContact;
}

export interface InternalContactsForm {
    [INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_TECHNICAL_ACCOUNT.name]: InternalContactsOption | null;
    [INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_ACCOUNT.name]: InternalContactsOption | null;
    [INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_SALES.name]: InternalContactsOption | null;
    [INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_BILLING.name]: InternalContactsOption | null;
    [INTERNAL_CONTACTS_FORM_FILEDS.PUBLISHER_BILLING.name]: InternalContactsOption | null;
    [INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_TECHNICAL_ACCOUNT.name]: InternalContactsOption | null;
    [INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_ACCOUNT.name]: InternalContactsOption | null;
    [INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_SALES.name]: InternalContactsOption | null;
    [INTERNAL_CONTACTS_FORM_FILEDS.DEMAND_BILLING_ACCOUNT.name]: InternalContactsOption | null;
}

interface UseInternalContactsPageEdit {
    initialValues: Store | undefined;
    form: FormInstance;
    isSubmitting: boolean;
    handleSubmit: (values: InternalContactsForm) => void;
    hasMore: boolean;
    isFetching: boolean;
    loadMore: () => void;
    onChange: (name: string, value: LabeledValue, typeId: number) => void;
    onSearch: (value: string) => void;
    options: LabeledValue[];
    searchValue: string | undefined;
    isUpdating: boolean;
}

export const useInternalContactsPageEditForm = (): UseInternalContactsPageEdit => {
    const { context } = useSeatAuthContext();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [form] = useForm<InternalContactsForm>();
    const { handleClose } = useInternalContactsPage();

    const initialValues = getInitialValueFromApi(context?.internalSeatContacts);

    const [searchValue, setSearchValue] = useState<string>("");
    const [keyword, setKeyword] = useState<string>("");
    const [pageByKeyword, setPageByKeyword] = useState({ "": 1 });
    const page = pageByKeyword[keyword] || 1;

    const { data, isFetching, originalArgs } = useGetUsersQuery({ keyword, max: MAX, page });
    const { updateSeatSelf, isLoading: isUpdating } = useUpdateSeatInContext();

    const {
        options: rawOptions,
        hasMore,
        loadMore,
    } = useLoadOnScroll(data, isFetching, keyword, originalArgs?.page || 1, data?.length === MAX, () =>
        setPageByKeyword((prev) => {
            const currentPage = prev[keyword] || 1;
            return {
                ...prev,
                [keyword]: currentPage + 1,
            };
        })
    );

    const options = useMemo(
        () =>
            (rawOptions || []).map((item) => ({
                label: getLabel(item.name, item.emailAddress),
                value: item.id,
                internalContact: {
                    id: null,
                },
            })),

        [rawOptions]
    );

    const onChange = (name: string, value: LabeledValue, typeId: number): void => {
        form.setFieldValue(
            name,
            value
                ? {
                      ...value,
                      internalContact: {
                          id: null,
                          isPrimaryContact: true,
                          type: {
                              id: typeId,
                          },
                          user: {
                              id: value.value,
                          },
                      },
                  }
                : null
        );
    };

    const debouncedSetKeyword = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setKeyword]
    );

    const onSearch = (value: string): void => {
        setSearchValue(value);
        debouncedSetKeyword(value);
    };

    const handleSubmit = async (values: InternalContactsForm) => {
        const body = {
            ...context,
            internalSeatContacts: parseValueForApi(values),
        } as UpdateSeatPayload;

        try {
            setIsSubmitting(true);
            await updateSeatSelf(body);
            notification.success({ message: "Internal contacts updated successfully" });
            handleClose();
        } catch (error) {
            notification.error({ message: error.data.errorDescription });
        } finally {
            setIsSubmitting(false);
        }
    };

    return {
        initialValues,
        isSubmitting,
        handleSubmit,
        searchValue,
        isFetching,
        isUpdating,
        loadMore,
        onChange,
        onSearch,
        hasMore,
        options,
        form,
    };
};
