import { PageHeader } from "@ant-design/pro-layout";
import { Loading } from "@app/core/components";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import { Layout } from "antd";
import { useParams } from "react-router-dom";
import { CompetitiveSeparationGroupsActions } from "../CompetitiveSeparationGroupsActions";
import { CompetitiveSeparationGroupsReadOnlyForm } from "../CompetitiveSeparationGroupsForm";
import { HELP_KEYS } from "../constants";
import { useCompetitiveSeparationGroupDetailsPage } from "./useCompetitiveSeparationGroupDetailsPage";

export const CompetitiveSeparationGroupDetailsPage = () => {
    const { seatId, id } = useParams<{ seatId: string; id: string }>();
    const { name, data, isLoading, handleGoBack, postDelete } = useCompetitiveSeparationGroupDetailsPage(seatId, id);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <PageHeader title={name} onBack={handleGoBack}>
                <CompetitiveSeparationGroupsActions
                    seatId={seatId}
                    id={Number(id)}
                    name={name}
                    postDeleteEvent={postDelete}
                />
            </PageHeader>
            <Layout.Content>
                <CompetitiveSeparationGroupsReadOnlyForm separationGroup={data}>
                    <HelpKeysDrawer panelLabel="Separation Group" definitionsFields={HELP_KEYS} />
                </CompetitiveSeparationGroupsReadOnlyForm>
            </Layout.Content>
        </>
    );
};
