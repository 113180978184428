import { getSnapshotDateLabel } from "@app/features/inventory/InventorySeat/SeatDetailsPage/SeatDetailsSection/PerformanceSection/Snapshots/SnapshotsDrawer/useSnapshots";
import { LabeledValue } from "antd/lib/select";
import { useMemo, useState } from "react";
import { useGetUnitSnapshots } from "./useGetUnitSnapshots";

export const useStatsSnapshotViewer = (id: number) => {
    const [value, setValue] = useState<string | null>(null);
    const [snapshotTimestamp, setSnapshotTimestamp] = useState<string | null>(null);
    const [selectedSnapshotLabel, setSelectedSnapshotLabel] = useState<string | null>(null);
    const { data, isLoading } = useGetUnitSnapshots(id);

    const options = useMemo(
        () =>
            data?.map((snapshot, index) => ({
                label: getSnapshotDateLabel(snapshot),
                value: snapshot.filename,
                filename: snapshot.filename,
                key: index.toString(),
            })),
        [data]
    );

    const handleChange = (e: LabeledValue & { filename: string }): void => {
        setValue(e.value as string);
        setSnapshotTimestamp(e.filename.match(/\/(\d+)\.json/)?.[1] || null);
        setSelectedSnapshotLabel(e.label as string);
    };

    return {
        data,
        isLoading,
        options,
        value,
        handleChange,
        snapshotTimestamp,
        selectedSnapshotLabel,
    };
};
