import { FC } from "react";
import { LabeledValue } from "antd/lib/select";
import { Conditions, TargetingDimensionTypes, TargetingDimensionTypeLabels } from "../../../constants";
import { isAddDisabled } from "../../../helpers";
import { OperatingSystemsTargetingDimension, UsedDimensions } from "../../../types";
import { ConditionSelect } from "../../ConditionSelect";
import { MoreMenu } from "../../MoreMenu";
import { DimensionRow } from "../DimensionRow";
import { DimensionsSelect } from "./DimensionsSelect";

interface Props {
    dimension: OperatingSystemsTargetingDimension;
    onChangeCondition: (value: Conditions) => void;
    onChangeDimensionValues: (value: LabeledValue[]) => void;
    onClickDelete: () => void;
    onClickDownload: () => void;
    onClickDuplicate: () => void;
    usedDimensions: UsedDimensions;
}

export const OperatingSystemsTargetsDimensionRow: FC<Props> = ({
    dimension,
    onChangeCondition,
    onChangeDimensionValues,
    onClickDelete,
    onClickDownload,
    onClickDuplicate,
    usedDimensions,
}) => {
    return (
        <DimensionRow
            label={TargetingDimensionTypeLabels.OperatingSystems}
            condition={<ConditionSelect onChange={onChangeCondition} value={dimension.condition} />}
            value={<DimensionsSelect onChange={onChangeDimensionValues} value={dimension.values} />}
            menu={
                <MoreMenu
                    onClickDelete={onClickDelete}
                    onClickDownload={onClickDownload}
                    onClickDuplicate={onClickDuplicate}
                    isDuplicateDisabled={isAddDisabled(TargetingDimensionTypes.OperatingSystems, usedDimensions)}
                />
            }
        />
    );
};
