import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { Form, Radio } from "antd";
import { PRE_FILTER_MODE_OPTIONS_NAME, PRE_FILTER_MODE_OPTIONS_VALUES } from "./constants";
import { usePreFilterModeDescription } from "./usePreFilterModeDescription";

export const PreFilterModeRadio = () => {
    const description = usePreFilterModeDescription();
    return (
        <Form.Item
            label={AD_SOURCE_FIELDS.WATERFALL_PRE_FILTER_MODE.label}
            name={AD_SOURCE_FIELDS.WATERFALL_PRE_FILTER_MODE.name}
            extra={description}
            {...VERTICAL_LAYOUT_FIX}
        >
            <Radio.Group>
                <Radio value={PRE_FILTER_MODE_OPTIONS_VALUES.ALL_INVENTORY}>
                    {PRE_FILTER_MODE_OPTIONS_NAME.ALL_INVENTORY}
                </Radio>
                <Radio value={PRE_FILTER_MODE_OPTIONS_VALUES.MATCH_INVENTORY_LABEL}>
                    {PRE_FILTER_MODE_OPTIONS_NAME.MATCH_INVENTORY_LABEL}
                </Radio>
            </Radio.Group>
        </Form.Item>
    );
};
