import { creativeReviewHelpKeys } from "@app/core/components/CreativeReview/helpKeys";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "../../../../types";

export const adSourceDetailsHelpKeys = {
    [SecondaryDetailsView.Performance]: [
        {
            key: "AD_STATS",
            label: "Ad Stats",
            helpPath: "",
            children: [
                {
                    key: "AD_STATS_DEAL",
                    label: "Deal",
                    helpPath: "adsourcedetails.adstats",
                },
            ],
        },

        {
            key: "LIVE_STATS",
            label: "Live Stats",
            helpPath: "",
            children: [
                {
                    key: "Tries",
                    label: "Tries",
                    helpPath: "adsourcedetails.tries",
                },
                {
                    key: "Fills",
                    label: "Fills",
                    helpPath: "adsourcedetails.fills",
                },
                {
                    key: "Impressions",
                    label: "Impressions",
                    helpPath: "adsourcedetails.impressions",
                },
                {
                    key: "Net Revenue",
                    label: "Net Revenue",
                    helpPath: "adsourcedetails.netrevenue",
                },
                {
                    key: "Errors",
                    label: "Errors",
                    helpPath: "adsourcedetails.errors",
                },
                {
                    key: "Fall Backs",
                    label: "Fall Backs",
                    helpPath: "adsourcedetails.fallbacks",
                },
                {
                    key: "Skips",
                    label: "Skips",
                    helpPath: "adsourcedetails.skips",
                },
            ],
        },
        {
            key: "LAST_24_HOURS",
            label: "Last 24 Hours",
            helpPath: "",
            children: [
                {
                    key: "LAST_24_HOURS",
                    label: "Last 24 Hours",
                    helpPath: "adsourcedetails.perflast24",
                },
            ],
        },
        {
            key: "BIDDER_STATS",
            label: "Bidder Stats",
            helpPath: "",
            children: [
                {
                    key: "BIDDER_STATS_DEAL",
                    label: "Deal",
                    helpPath: "adsourcedetails.bidderstats",
                },
            ],
        },
    ],
    [SecondaryDetailsView.CreativeReview]: creativeReviewHelpKeys,
    [SecondaryDetailsView.VastErrors]: [
        {
            key: "DSP",
            label: "DSP",
            helpPath: "deal.demand",
        },
        {
            key: "ADVERTISER_DOMAIN",
            label: "Advertiser Domain",
            helpPath: "adstats-publisher.advertiserDomain",
        },
        {
            key: "CREATIVE_ID",
            label: "Creative ID",
            helpPath: "adserverladle.creativeid",
        },
        {
            key: "ERROR_COUNT",
            label: "Error Count",
            helpPath: "adsourcedetails.vasterrors",
        },
    ],
    [SecondaryDetailsView.TimingStats]: [
        {
            key: "REGION",
            label: "Region",
            helpPath: "timingstats.region",
        },
        {
            key: "EVENT",
            label: "Event yAxis Type",
            helpPath: "timingstats.yaxisscale",
        },
    ],
    [SecondaryDetailsView.Permissions]: [
        {
            key: "PERMISSIONS",
            label: "Permissions",
            helpPath: "permissions.levels",
        },
        {
            key: "EMAIL",
            label: "Email",
            helpPath: "permissions.email",
        },
        {
            key: "GROUP",
            label: "Group",
            helpPath: "permissions.group",
        },
    ],
    [SecondaryDetailsView.AdServing]: [
        {
            key: "AD_SERVING",
            label: "Ad Serving",
            helpPath: "adsourcedetails.adserving",
        },
    ],
    [SecondaryDetailsView.Ladle]: [
        {
            label: "Region",
            helpPath: "adserverladle.region",
            key: "REGION",
        },
        {
            label: "Ladle Name",
            helpPath: "adserverladle.name",
            key: "LADLE_NAME",
        },
        {
            label: "Supply Domain",
            helpPath: "adserverladle.supplydomain",
            key: "SUPPLY_DOMAIN",
        },
        {
            label: "Bundle ID",
            helpPath: "adserverladle.bundleid",
            key: "BUNDLE_ID",
        },
        {
            label: "Line Item ID",
            helpPath: "adserverladle.lineitemid",
            key: "LINE_ITEM_ID",
        },
        {
            label: "Order ID",
            helpPath: "adserverladle.orderid",
            key: "ORDER_ID",
        },
        {
            label: "Advertiser Domain",
            helpPath: "adserverladle.adomain",
            key: "ADVERTISER_DOMAIN",
        },
        {
            label: "Creative ID",
            helpPath: "adserverladle.creativeid",
            key: "CREATIVE_ID",
        },
        {
            label: "Creative",
            helpPath: "adserverladle.creative",
            key: "CREATIVE",
        },
        {
            label: "Demand",
            helpPath: "adserverladle.demand",
            key: "DEMAND",
        },
    ],
};
