import { FC, HTMLAttributes, ReactNode } from "react";
import { Form } from "antd";

interface EditableCellProps extends HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    index: number;
    children: ReactNode;
    renderInputNode?: () => ReactNode;
    inputNodeValuePropName?: string | undefined;
}

export const EditableCell: FC<EditableCellProps> = ({
    editing,
    dataIndex,
    index,
    children,
    renderInputNode,
    inputNodeValuePropName,
    ...restProps
}) => {
    return (
        <td {...restProps}>
            {editing && renderInputNode ? (
                <Form.Item name={dataIndex} style={{ margin: 0 }} valuePropName={inputNodeValuePropName}>
                    {renderInputNode()}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};
