import { ADVANCED_FLOOR_LABELS, ADVANCED_FLOOR_SECTION_NAMES } from "../SeatFloorsAdvancedDetails/constants";

const ADVANCED_FLOORS_GENERAL_HELP_KEYS = [
    {
        label: ADVANCED_FLOOR_LABELS.floorName,
        helpPath: "advancedfloor.name",
        key: ADVANCED_FLOOR_LABELS.floorName.toUpperCase(),
    },
    {
        label: ADVANCED_FLOOR_LABELS.floorPrice,
        helpPath: "advancedfloor.price",
        key: ADVANCED_FLOOR_LABELS.floorPrice.toUpperCase(),
    },
    {
        label: ADVANCED_FLOOR_LABELS.lastUpdate,
        helpPath: "entity.updatedate",
        key: ADVANCED_FLOOR_LABELS.lastUpdate.toUpperCase(),
    },
    {
        label: ADVANCED_FLOOR_LABELS.dateCreated,
        helpPath: "entity.creationdate",
        key: ADVANCED_FLOOR_LABELS.dateCreated.toUpperCase(),
    },
];

const ADVANCED_FLOORS_TARGETING_HELP_KEYS = [
    {
        label: "Targeting Blocks",
        helpPath: "targetingdetails.blocks",
        key: "TARGETING_BLOCKS",
    },
];

export const ADVANCED_FLOOR_SECTION_HELP_KEYS = {
    [ADVANCED_FLOOR_SECTION_NAMES.GENERAL]: ADVANCED_FLOORS_GENERAL_HELP_KEYS,
    [ADVANCED_FLOOR_SECTION_NAMES.TARGETING]: ADVANCED_FLOORS_TARGETING_HELP_KEYS,
};
