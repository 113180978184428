import { Button, Form, Typography } from "antd";
import { AdBreakLine } from "../AdBreakLine";
import { PlusOutlined } from "@ant-design/icons";
import { AdBreakRuleAds, AdBreakRuleDuration, AdBreakRuleName } from "../../AdBreakRules";
import { AD_UNIT_AD_BREAK_RULES_LABELS } from "@app/features/inventory/InventoryHealthDetailsPages/constants";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { brandCobalt, errorColor } from "@rubicon/antd-components";
import { css } from "@emotion/css";
import { useRef } from "react";

const { useWatch } = Form;

export const PostRoll = () => {
    const ref = useRef<HTMLFormElement>(null);
    const preRollValues = useWatch(CHANNEL_FORM_FIELDS.AD_BREAK_RULES_PRE.name);
    const midRollValues = useWatch(CHANNEL_FORM_FIELDS.AD_BREAK_RULES_MID.name);

    return (
        <>
            <Typography.Title ref={ref} level={5} style={{ marginTop: "1.5rem" }}>
                {AD_UNIT_AD_BREAK_RULES_LABELS.postRoll}
            </Typography.Title>
            <Form.List
                name={CHANNEL_FORM_FIELDS.AD_BREAK_RULES_POST.name}
                rules={[
                    {
                        validator: (_, values) => {
                            if (!values.length && !midRollValues?.length && !preRollValues?.length) {
                                ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                                return Promise.reject(new Error("Please add ad break rule"));
                            }
                            return Promise.resolve();
                        },
                    },
                ]}
            >
                {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field) => (
                            <AdBreakLine
                                field={field}
                                key={field.key}
                                name={AdBreakRuleName.POST_ROLL_NAME}
                                ads={AdBreakRuleAds.POST_ROLL_ADS}
                                duration={AdBreakRuleDuration.POST_ROLL_DURATION}
                                onRemove={() => remove(field.key)}
                            />
                        ))}

                        {!fields.length && (
                            <Button
                                type="link"
                                onClick={add}
                                icon={<PlusOutlined />}
                                style={{
                                    paddingLeft: "0.2rem",
                                    paddingRight: 0,
                                    color: brandCobalt,
                                }}
                            >
                                Add
                            </Button>
                        )}

                        <Form.ErrorList
                            errors={errors}
                            className={css`
                                .ant-form-item-explain-error {
                                    color: ${errorColor};
                                }
                            `}
                        />
                    </>
                )}
            </Form.List>
        </>
    );
};
