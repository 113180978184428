import { useEffect } from "react";
import { css } from "@emotion/css";
import { Route, Switch, useLocation, useParams } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { ResizableDrawer } from "@rubicon/antd-components";
import { SeatAdSourcesDetailsDrawerContent } from "./SeatAdSourcesDetailsDrawerContent/SeatAdSourcesDetailsDrawerContent";
import { useSeatAdSourcesDetailsDrawer } from "./useSeatAdSourcesDetailsDrawer";
import { convertSecondaryViewFromURL } from "./normalizeSecondaryViewFromURL";
import { AD_SOURCE_DRAWER_LOCATION } from "../constants";
import { Loading } from "@app/core/components";

const DEFAULT_DRAWER_WIDTH = "65%";

interface Params {
    adSourceId: string;
    secondaryView: string;
}

export const AdSourcesDetailsDrawer = () => {
    const {
        isSeatAdSourcesDetailsDrawerOpen: isDrawerOpen,
        closeDrawerWhithoutHistoryChange: closeDrawerWithoutHistoryChange,
        openSeatAdSourcesDetailsDrawer: openDrawer,
        closeDrawerBackToPage,
        openSeatAdSourceDetailsPageDrawer,
        openSeadAdSourceDrawerDeals,
        openSeatAdSourceDrawerPacing,
    } = useSeatAdSourcesDetailsDrawer();
    const location = useLocation();
    const { adSourceId, secondaryView } = useParams<Params>();

    useEffect(() => {
        if (adSourceId) {
            if (secondaryView) {
                const secondaryViewConverted = convertSecondaryViewFromURL(secondaryView);
                if (location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.DEALS)) {
                    openSeadAdSourceDrawerDeals(Number(adSourceId), secondaryViewConverted);
                    return;
                }
                if (location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.PACING)) {
                    openSeatAdSourceDrawerPacing(Number(adSourceId), secondaryViewConverted);
                    return;
                }
                if (location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.DETAILS_PAGE)) {
                    openSeatAdSourceDetailsPageDrawer(Number(adSourceId), secondaryViewConverted);
                    return;
                }
                openDrawer(
                    Number(adSourceId),
                    secondaryViewConverted,
                    location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.DETAILS_DRAWER)
                );
                return;
            }
            openDrawer(Number(adSourceId));
            return;
        }
        //When url has changed and no matches in hook we close drawer to not show empty drawer
        !adSourceId && closeDrawerWithoutHistoryChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adSourceId, secondaryView]);

    return (
        <ResizableDrawer
            data-sdet="ad-sources-details-drawer"
            open={isDrawerOpen}
            placement="right"
            width={DEFAULT_DRAWER_WIDTH}
            destroyOnClose
            closable={false}
            onClose={closeDrawerBackToPage}
            className={css`
                .ant-drawer-body {
                    padding: 0;
                }
                .full-width {
                    .ant-space,
                    .ant-space-item {
                        width: 100%;
                    }
                }
                .ant-collapse {
                    border: 0;
                }
                .ant-collapse > .ant-collapse-item > .ant-collapse-header {
                    padding: 0 16px;
                    line-height: 42px;
                    .ant-collapse-header-text {
                        font-size: 20px;
                        font-weight: 500;
                        .ant-typography {
                            line-height: 42px;
                        }
                    }
                    .ant-collapse-arrow {
                        font-size: 1rem;
                        vertical-align: -3px;
                    }
                }
                .drawer-view-content {
                    padding: 1rem 0;
                }
                .drawer-view-tabs {
                    .ant-tabs-tab {
                        font-size: 1rem;
                        font-weight: 400;
                    }
                }
                .vast-error-table {
                    margin-top: 32px;
                }
            `}
        >
            <Switch>
                <Route
                    exact
                    path={[
                        ROUTES.SEAT_AD_SOURCES_DETAILS_DRAWER,
                        ROUTES.SEAT_AD_SOURCES_DETAILS_PAGE_DETAILS_DRAWER,
                        ROUTES.SEAT_AD_SOURCES_DETAILS_DRAWER_SECONDARY_VIEW,
                        ROUTES.SEAT_AD_SOURCES_SECONDARY_VIEW_DRAWER,
                        ROUTES.SEAT_AD_SOURCES_DETAILS_PAGE_SECONDARY_VIEW,
                        ROUTES.SEAT_AD_SOURCES_PACING_DETAILS,
                        ROUTES.SEAT_AD_SOURCES_PACING_DETAILS_SECONDARY_VIEW,
                        ROUTES.SEAT_AD_SOURCES_PACING_SECONDARY_VIEW_DRAWER,
                        ROUTES.SEAT_DEALS_HEALTH_AD_SOURCES_DRAWER,
                        ROUTES.SEAT_DEALS_HEALTH_AD_SOURCES_DRAWER_SECONDARY_VIEW,
                        ROUTES.SEAT_DEAL_DETAILS_AD_SOURCES_DRAWER,
                        ROUTES.SEAT_DEAL_DETAILS_AD_SOURCES_DRAWER_SECONDARY_VIEW,
                        ROUTES.SEAT_DEAL_EDIT_AD_SOURCES_DRAWER,
                        ROUTES.SEAT_DEAL_EDIT_AD_SOURCES_DRAWER_SECONDARY_VIEW,
                        ROUTES.SEAT_DEALS_AD_SOURCES_DRAWER,
                        ROUTES.SEAT_DEALS_AD_SOURCES_DRAWER_SECONDARY_VIEW,
                        ROUTES.SEAT_DEAL_CREATE_TYPE_AD_SOURCES_DRAWER,
                        ROUTES.SEAT_DEAL_CREATE_TYPE_AD_SOURCES_DRAWER_SECONDARY_VIEW,
                        ROUTES.SEAT_DEAL_COPY_AD_SOURCES_DRAWER,
                        ROUTES.SEAT_DEAL_COPY_AD_SOURCES_DRAWER_SECONDARY_VIEW,
                    ]}
                >
                    {isDrawerOpen && <SeatAdSourcesDetailsDrawerContent />}
                </Route>

                <Route>
                    <Loading />
                </Route>
            </Switch>
        </ResizableDrawer>
    );
};
