import { FC } from "react";
import { Form, Input } from "antd";
import { Seat } from "@app/core/services";
import { useUserAccess } from "@app/core/auth";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { TextWithCopy } from "@app/features/inventory/components/TextWithCopy";
interface NameProps {
    seat: Seat;
    edit?: boolean;
}

export const Name: FC<NameProps> = ({ edit = false, seat }) => {
    const { hasInternalAccess } = useUserAccess();
    const { name } = seat;
    return (
        <Form.Item
            name={INVENTORY_FORM_FIELDS.NAME.name}
            label={INVENTORY_FORM_FIELDS.NAME.label}
            wrapperCol={{ xs: 24, sm: 24, md: 20, lg: 18, xl: 22, xxl: 20 }}
            rules={[
                {
                    whitespace: true,
                    required: edit && hasInternalAccess,
                    message: INVENTORY_FORM_FIELDS.NAME.rulesMessage,
                },
            ]}
        >
            {edit && hasInternalAccess ? (
                <Input placeholder={INVENTORY_FORM_FIELDS.NAME.placeholder} allowClear />
            ) : (
                <TextWithCopy text={name} notificationEntity="Name" />
            )}
        </Form.Item>
    );
};
