import { FC } from "react";
import { ColumnChart } from "@app/features/seatAdSources/seatAdSourcesCharts/charts";
import { useSeatAdSources24HourAdCandidateSelectionChart } from "./useSeatAdSources24HourAdCandidateSelectionChart";
import { LoadableComponent } from "@app/core/components";

interface Props {
    adSourceId: number;
}

export const SeatAdSources24HourAdCandidateSelectionChart: FC<Props> = ({ adSourceId }) => {
    const { loadingState, columnChartProps } = useSeatAdSources24HourAdCandidateSelectionChart(adSourceId);

    return (
        <LoadableComponent loadingState={loadingState}>
            {columnChartProps && <ColumnChart {...columnChartProps} />}
        </LoadableComponent>
    );
};
