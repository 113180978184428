import { FC } from "react";
import { Tag } from "antd";
import { GroupConditions } from "@app/features/targeting/constants";

interface Props {
    value: GroupConditions;
}

const getPrimaryColor = (condition: GroupConditions) => (condition === GroupConditions.Or ? "#7666F9" : "#C1715B");

export const GroupCondition: FC<Props> = ({ value }) => {
    const primaryColor = getPrimaryColor(value);

    return <Tag color={primaryColor}>{value}</Tag>;
};
