import {
    backfillMissingSeries,
    LegendOptions,
    LineChartOptions,
    LineChartProps,
    X_AXIS_TICK_COUNT,
} from "@app/features/seatAdSources/seatAdSourcesCharts/charts";
import { vastErrorsColorMapper, VastErrorsColors } from "../colors";
import { getVastErrorsSums } from "@app/core/services/diagnostics/vastErrors/mapper";
import { VastErrors } from "@app/core/services/diagnostics/vastErrors";
import moment from "moment-timezone";

const chartOptions: LineChartOptions = {
    color: vastErrorsColorMapper,
    xAxis: {
        tickCount: X_AXIS_TICK_COUNT,
    },
    yAxis: {
        title: {
            text: "Errors",
        },
    },
    tooltip: {
        title: (v) => `Time: ${v} (UTC)`,
    },
} as const;

const legendOptions: LegendOptions = {
    colorMap: VastErrorsColors,
    showToggleAllButton: false,
} as const;

export const useSeatAdSourcesVastErrorsChart = (vastErrors: VastErrors[] = []): LineChartProps => {
    const lineChartData = vastErrors.map((vastError) => {
        const errorTotals = getVastErrorsSums(vastError);
        const yValues = Object.keys(errorTotals).map((errorCode) => {
            return {
                yValue: errorTotals[errorCode],
                series: errorCode,
            };
        });

        return {
            xValue: moment(vastError.ntime).format("HH:mm"),
            yValues,
        };
    });
    const completeLineChartData = backfillMissingSeries(lineChartData);

    return {
        id: "seat-ad-sources-vast-errors-chart",
        data: completeLineChartData,
        chartOptions,
        legendOptions,
    };
};
