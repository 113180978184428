import { PlusOutlined } from "@ant-design/icons";
import { Typography, Row, Col, Form, Select, Button, Tag } from "antd";
import { useTransparencyExtenderUserDrawer } from "./useTransparencyExtenderUserDrawer";
import { LV } from "../constants";
import { css } from "@emotion/css";
import { gray6 } from "@rubicon/antd-components";

export const TRANSPARENCY_EXTEND_USER_SELECT_SDET = "transparency-extended-user-id-select";
export const TRANSPARENCY_EXTEND_DSP_SELECT_SDET = "transparency-extended-dsp-select";

const containerStyles = css`
    padding-top: 1.4em;
`;
const tagStyles = css`
    outline: 1px solid ${gray6};
    padding: 0.4em;
`;
interface Props {
    extendedIdsByAdSourceId: LV[];
    handleAdd: (v1: LV, v2: LV, callback: () => void) => void;
    handleRemove: (val: string, adSourceTypeId: number, callback?: () => void) => void;
}

export const ExtendedUserIdDrawerContent = ({ extendedIdsByAdSourceId, handleAdd, handleRemove }: Props) => {
    const { drawerId, networkOptions, isNetworkLoading, extendedUserIdsOptions, isUserOptionsLoading } =
        useTransparencyExtenderUserDrawer();
    const { getFieldValue, resetFields } = Form.useFormInstance();
    const selectedUserId = Form.useWatch("extendedId");
    const selectedNetwork = Form.useWatch("network");

    return (
        <>
            <Typography.Text>Specify Extended User Id and DSP.</Typography.Text>
            <Row gutter={[4, 0]} align="stretch" className={containerStyles}>
                <Col span={10}>
                    <Form.Item name="extendedId">
                        <Select
                            labelInValue
                            data-sdet={TRANSPARENCY_EXTEND_USER_SELECT_SDET}
                            loading={isUserOptionsLoading}
                            options={extendedUserIdsOptions}
                            value={getFieldValue("extendedId")}
                            placeholder="Select Extended User Id"
                        />
                    </Form.Item>
                </Col>
                <Col span={1} style={{ textAlign: "center" }}>
                    <Typography.Text strong>:</Typography.Text>
                </Col>
                <Col span={10}>
                    <Form.Item name="network">
                        <Select
                            labelInValue
                            data-sdet={TRANSPARENCY_EXTEND_DSP_SELECT_SDET}
                            loading={isNetworkLoading}
                            disabled={!selectedUserId}
                            options={networkOptions}
                            value={getFieldValue("network")}
                            placeholder="Select DSP"
                        />
                    </Form.Item>
                </Col>

                <Col span={3}>
                    <Button
                        block
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={() => handleAdd(selectedUserId, selectedNetwork, resetFields)}
                        style={{ paddingLeft: "0.2rem" }}
                        disabled={!selectedUserId || !selectedNetwork}
                    >
                        Add
                    </Button>
                </Col>
            </Row>
            {Boolean(extendedIdsByAdSourceId?.length) && (
                <Row className={tagStyles}>
                    {extendedIdsByAdSourceId?.map(({ label, value }) => (
                        <Tag
                            color="processing"
                            key={value}
                            closable
                            onClose={() => handleRemove(String(value), Number(drawerId))}
                        >
                            {label}
                        </Tag>
                    ))}
                </Row>
            )}
        </>
    );
};
