import { FC } from "react";
import { Col, Form } from "antd";
import { CurrencyInput } from "@app/core/components/CurrencyInput";
import { MarketplaceInfo } from "@app/core/services";
import { ReadonlyControl } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { useCpmRate } from "./useCpmRate";
import { formatNumber } from "@rubicon/utils";

export const CpmRateInput: FC = () => {
    const { isReadonly, addOnContent, currencyCode, isRequired, helpText, onBlur } = useCpmRate();

    if (isReadonly) {
        return (
            <Col sm={12}>
                <Form.Item data-sdet="deal-rate-field" label="CPM Rate" name={["dealUnderEdit", "rate"]}>
                    <ReadonlyControl<MarketplaceInfo["deal"]["rate"]>
                        getLabel={(rate) => `${addOnContent} ${formatNumber.asFixed(rate)}`}
                    />
                </Form.Item>
            </Col>
        );
    }

    return (
        <Col sm={12}>
            <CurrencyInput
                dataSdetPrefix="deal-rate"
                name={["dealUnderEdit", "rate"]}
                label="CPM Rate"
                help={helpText}
                required={isRequired}
                currencyCode={currencyCode}
                onBlur={onBlur}
            />
        </Col>
    );
};
