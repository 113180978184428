import {
    AdUnit,
    Brand,
    ChannelItem,
    Publisher,
    SearchItem,
    SearchItemChannel,
    Supply,
} from "@app/core/services/console";
import { LabeledValue } from "antd/lib/select";
import { InventoryLabelValue, InventoryLabelValueEntityType } from "../constants";

export const getInventoryDimensionsByEntityType = (dimensionValues: LabeledValue[]) => {
    const inventoryDimensions: Record<"publishers" | "brands" | "adUnits" | "supply", { name: string; id: number }[]> =
        {
            publishers: [],
            brands: [],
            adUnits: [],
            supply: [],
        };

    dimensionValues.forEach(({ value }) => {
        const dimension: SearchItem | ChannelItem | Brand | Publisher | Supply | AdUnit = JSON.parse(value as string);

        switch (dimension.entityType) {
            case InventoryLabelValueEntityType.PUBLISHER:
                inventoryDimensions.publishers.push(dimension);
                break;
            case InventoryLabelValueEntityType.BRAND:
                inventoryDimensions.brands.push(dimension);
                break;
            case InventoryLabelValueEntityType.SUPPLY:
                inventoryDimensions.supply.push(dimension);
                break;
            case InventoryLabelValueEntityType.ADUNIT:
                inventoryDimensions.adUnits.push(dimension);
                break;
            case InventoryLabelValueEntityType.CHANNEL:
                if (dimension.publisher) {
                    inventoryDimensions.brands.push(dimension);
                } else {
                    inventoryDimensions.publishers.push(dimension);
                }
                break;
        }
    });
    return inventoryDimensions;
};

const getChannelLabel = (channel: ChannelItem | SearchItemChannel): string => {
    if (channel.seat) {
        return `${channel.seat.name} > ${channel.name}`;
    }
    if (channel.publisher) {
        return `${channel.publisher.seat?.name} > ${channel.name}`;
    }
    return channel.name;
};

const getInventoryLabel = (target: SearchItem | ChannelItem | Brand | Publisher | Supply | AdUnit): string => {
    let label = `${target.entityType}: `;
    switch (target.entityType) {
        case "Channel":
            label += getChannelLabel(target as ChannelItem | SearchItemChannel);
            break;
        case "Publisher":
            label += `${target.seat.name} > ${target.name}`;
            break;
        case "Brand":
            label += `${target.publisher.seat.name} > ${target.publisher.name} > ${target.name}`;
            break;
        case "Supply":
            label += `${target.brand.publisher.seat.name} > ${target.brand.publisher.name} > ${target.brand.name} > ${target.name}`;
            break;
        case "AdUnit":
            label += `${target.supply.brand.publisher.seat.name} > ${target.supply.brand.publisher.name} > ${target.supply.brand.name} > ${target.supply.name} > ${target.name}`;
            break;
    }
    return label;
};

export const inventoryToLabelValue = (
    targets: SearchItem[] | ChannelItem[] | Brand[] | Publisher[] | Supply[] | AdUnit[] | null
): InventoryLabelValue[] =>
    (targets || [])
        .map((target) => {
            return {
                entityType: target.entityType,
                value: JSON.stringify(target),
                label: getInventoryLabel(target),
            };
        })
        .filter((target) => target.entityType !== "Seat"); // We don't target Seats
