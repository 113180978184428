import { AdSourceTypes } from "@app/features/seatAdSources/constants";
import { getNextUiAdSourceTypeName } from "./getNextUiAdSourceTypeName";

export const isDemandSectionVisible = (adSourceTypeNameRaw: string) => {
    const adSourceTypeName = getNextUiAdSourceTypeName(adSourceTypeNameRaw);
    return (
        adSourceTypeName === AdSourceTypes.PROGRAMATIC_GUARANTEED ||
        adSourceTypeName === AdSourceTypes.CONDITIONAL_PROGRAMATIC_GUARANTEED ||
        adSourceTypeName === AdSourceTypes.FIXED_PRICE ||
        adSourceTypeName === AdSourceTypes.AUCTION_PRICE ||
        adSourceTypeName === AdSourceTypes.OPEN_AUCTION ||
        adSourceTypeName === AdSourceTypes.LINEAR_FIXED_PRICE ||
        adSourceTypeName === AdSourceTypes.LINEAR_AUCTION_PRICE ||
        adSourceTypeName === AdSourceTypes.MARKETPLACE ||
        adSourceTypeName === AdSourceTypes.PREBID_DEALS_CONTAINER
    );
};
