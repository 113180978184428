import { FC, ReactNode, useState } from "react";
import { ResizableDrawer } from "@rubicon/antd-components";
import { Button, Col, Row, Typography } from "antd";

interface Props {
    label: ReactNode;
    examples: ReactNode;
}

export const DefinitionsAndTipsDrawerButton: FC<Props> = ({ label, examples }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <ResizableDrawer
                placement="right"
                width="70%"
                title={`${label} - Definitions & Tips`}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                footer={
                    <Row justify="end" gutter={8}>
                        <Col>
                            <Button data-sdet="definition-and-tips-close" onClick={() => setIsOpen(false)}>
                                Close
                            </Button>
                        </Col>
                    </Row>
                }
            >
                <Typography.Title level={5}>DEFINITIONS:</Typography.Title>
                <Typography.Paragraph>
                    <Typography.Text strong>+ Condition: </Typography.Text>
                    allows you to add another row to define a rule (select source, category, operator, and values)
                </Typography.Paragraph>
                <Typography.Paragraph>
                    <Typography.Text strong>+ Subgroup: </Typography.Text>
                    allows you to add an AND/OR group inside of an outer group.
                </Typography.Paragraph>
                <Typography.Paragraph>
                    <Typography.Text strong>Value box: </Typography.Text>
                    Multiple items can be selected in the values input box and it is an OR logic
                </Typography.Paragraph>
                <Typography.Paragraph>
                    <Typography.Text strong>AND/OR brackets: </Typography.Text>
                    The bracket shows you the AND/OR grouping. You can change the AND/OR for the main outer group as
                    well as the subgroups.
                </Typography.Paragraph>
                {examples}
            </ResizableDrawer>
            <Button type="link" size="small" onClick={() => setIsOpen(true)}>
                Definitions & Tips
            </Button>
        </>
    );
};
