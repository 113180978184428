import { FC } from "react";
import { Button, Col, Flex, Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { gray7, gray8 } from "@rubicon/antd-components";
import { Loading } from "@app/core/components";
import { SeatAdSourcesDealCountTable } from "@app/features/seatAdSources/SeatAdSourcesDealCountTable";
import { useFetchSeatAdSource } from "@app/features/seatAdSources/data";

interface Props {
    adSourceId: number;
    adSourceName: string;
    dealCount: number;
    closeDealCountDrawer: () => void;
}

export const SeatAdSourcesDealCountDrawerContents: FC<Props> = ({
    adSourceId,
    adSourceName,
    dealCount,
    closeDealCountDrawer,
}) => {
    const { adSource, isFetching } = useFetchSeatAdSource(adSourceId);

    return (
        <div data-sdet="seat-ad-sources-deal-count-drawer-content">
            <Col span={24} className="ant-drawer-header" style={{ padding: 16, borderBottom: "1px solid #d9d9d9" }}>
                <Flex justify="space-between" style={{ width: "100%" }}>
                    <Typography.Title level={5} style={{ margin: 0 }}>
                        {`Total ${dealCount} ${dealCount === 1 ? "Deal" : "Deals"} in ${adSourceName}`}
                    </Typography.Title>
                    <Button
                        type="text"
                        onClick={closeDealCountDrawer}
                        className={css`
                            color: ${gray7};
                            padding: 0;
                            height: auto;
                            &:hover {
                                color: ${gray8};
                                background-color: transparent;
                            }
                        `}
                    >
                        <CloseOutlined
                            className={css`
                                svg {
                                    width: 1.15em;
                                    height: 1.15em;
                                }
                            `}
                        />
                    </Button>
                </Flex>
            </Col>
            {isFetching ? (
                <Loading />
            ) : (
                <div className="ant-drawer-content" style={{ padding: 16 }}>
                    {adSource && <SeatAdSourcesDealCountTable adSource={adSource} />}
                </div>
            )}
        </div>
    );
};
