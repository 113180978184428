import { FormInstance } from "antd";
import { FC } from "react";
import { ConfirmationStepBottomButtons } from "./ConfirmationStepBottomButtons";
import { BULK_OPERATION_FORM_ITEMS } from "../../../constants";
import { useBulkOperationFormRunButton } from "./useBulkOperationFormRunButton";
import { BulkOperationDefinition, BulkOperationEntityType } from "@app/core/services";
import { DerivedAdUnit, TouchedFieldInfo } from "../../../reducer";
import { getYesNoApiValue, isPresent } from "../../../helpers";
import { isMoment } from "moment-timezone";

interface Props {
    changedRecords: [number, TouchedFieldInfo[]][];
    includedJobIds: number[];
    goToPrev: () => void;
    form: FormInstance;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertformValueToPayload = (formValue: any) => {
    if (typeof formValue === "string") {
        return formValue.trim();
    } else if (isMoment(formValue)) {
        return formValue.format();
    } else if (Array.isArray(formValue)) {
        return formValue.map(({ value }) => ({ id: value }));
    } else if (isPresent(formValue) && typeof formValue === "object") {
        // some customized select option has -1 for unspecified or off option value
        return formValue.value !== -1 ? { id: formValue.value } : null;
    } else {
        return formValue;
    }
};

const buildAdSourceEditPayload = (changedRecords: [number, TouchedFieldInfo[]][], includedJobIds: number[]) =>
    changedRecords
        .filter(([id]) => includedJobIds.includes(id))
        .map(([id, touchedFieldsInfo]) => {
            const dataObj = touchedFieldsInfo.reduce(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (acc, { field, currentValue }: { field: string; currentValue: any }) => {
                    if (field === "allowContent") {
                        acc[field] = currentValue.value === "hidden" ? false : true;
                        acc["contentTransparencyRule"] =
                            currentValue.value === "hidden" || currentValue.value === "shared"
                                ? null
                                : { id: Number(currentValue.value) };

                        return acc;
                    }
                    if (field === "allowUserId") {
                        acc[field] = currentValue === "hidden" ? false : true;
                        acc["maskUserId"] = currentValue === "masked" ? true : false;
                        return acc;
                    }

                    if (field === "bookingPrice" || field === "costValueFixed") {
                        acc[field] = currentValue * 1000;
                        return acc;
                    }

                    acc[field] = convertformValueToPayload(currentValue);
                    return acc;
                },
                {}
            );

            return { id, ...dataObj };
        });

const buildAdUnitEditPayload = (
    entityUpdates: Record<number, DerivedAdUnit>,
    changedRecords: [number, TouchedFieldInfo[]][],
    includedJobIds: number[]
) =>
    changedRecords
        .filter(([id]) => includedJobIds.includes(id))
        .map(([id, touchedFieldsInfo]) => {
            const dataObj = touchedFieldsInfo.reduce(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (acc, { field, currentValue }: { field: string; currentValue: any }) => {
                    if (field === "publisherReauction") {
                        const calculatedPublisherReauction = entityUpdates[id].calculatedPublisherReauction;
                        acc[field] = calculatedPublisherReauction !== null ? null : getYesNoApiValue(currentValue);

                        return acc;
                    }
                    if (field === "allowUserId") {
                        acc[field] = currentValue === "hidden" ? false : true;
                        acc["maskUserId"] = currentValue === "masked" ? true : false;
                        return acc;
                    }

                    if (field === "bookingPrice" || field === "costValueFixed") {
                        acc[field] = currentValue * 1000;
                        return acc;
                    }

                    acc[field] = convertformValueToPayload(currentValue);
                    return acc;
                },
                {}
            );

            return { id, ...dataObj };
        });

const buildBuyerDemandDealEditPayload = (changedRecords: [number, TouchedFieldInfo[]][], includedJobIds: number[]) =>
    changedRecords
        .filter(([id]) => includedJobIds.includes(id))
        .map(([id, touchedFieldsInfo]) => {
            const dataObj = touchedFieldsInfo.reduce(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (acc, { field, currentValue }: { field: string; currentValue: any }) => {
                    if (field === "externalName") {
                        acc[field] = currentValue;
                        acc["externalParentName"] = currentValue;
                        return acc;
                    }
                    acc[field] = convertformValueToPayload(currentValue);
                    return acc;
                },
                {}
            );

            return { id, ...dataObj };
        });

export const BulkOperationEditConfirmationStepBottomButtons: FC<Props> = ({
    changedRecords,
    includedJobIds,
    goToPrev,
    form,
}) => {
    const { isLoading, handleSubmit } = useBulkOperationFormRunButton();

    const buildEditPayload = () => {
        const { type, entityType, adUnitUpdates } = form.getFieldsValue(true);
        if (entityType === BulkOperationEntityType.AdSource) {
            return {
                type,
                entityType,
                adSourceUpdates: buildAdSourceEditPayload(changedRecords, includedJobIds),
            };
        }

        if (entityType === BulkOperationEntityType.AdUnit) {
            return {
                type,
                entityType,
                adUnitUpdates: buildAdUnitEditPayload(adUnitUpdates, changedRecords, includedJobIds),
            };
        }

        if (entityType === BulkOperationEntityType.BuyerDeal) {
            return {
                type,
                entityType,
                buyerDealUpdates: buildBuyerDemandDealEditPayload(changedRecords, includedJobIds),
            };
        }

        if (entityType === BulkOperationEntityType.DemandDeal) {
            return {
                type,
                entityType,
                // should be buyerDealUpdates not demandDealUpdates when submit to API
                buyerDealUpdates: buildBuyerDemandDealEditPayload(changedRecords, includedJobIds),
            };
        }
    };

    const onClickRun = () => {
        form.validateFields([BULK_OPERATION_FORM_ITEMS.OPERATION_ENTITIES_EDIT_REVIEW_ERROR_MESSAGE.name]).then(() => {
            const bulkOperationEditPayload = buildEditPayload();
            bulkOperationEditPayload && handleSubmit(bulkOperationEditPayload as BulkOperationDefinition);
        });
    };

    return <ConfirmationStepBottomButtons isLoading={isLoading} goToPrev={goToPrev} onClickRun={onClickRun} />;
};
