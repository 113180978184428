import { Layout } from "antd";
import SeatFloorsAdvancedTable from "../SeatFloorsAdvancedTable";

const InventoryFloorAdvancedPage = () => {
    return (
        <Layout.Content>
            <SeatFloorsAdvancedTable />
        </Layout.Content>
    );
};

export default InventoryFloorAdvancedPage;
