import { CSSProperties, FC } from "react";
import { Select } from "antd";
import { useTimeFrameFilter } from "./useTimeFrameFilter";

interface Props {
    style?: CSSProperties;
}

export const TimeFrameFilter: FC<Props> = ({ style }) => {
    const { options, value, handleChangeValue } = useTimeFrameFilter();

    return (
        <Select
            data-sdet="time-frame-filter"
            onChange={handleChangeValue}
            optionFilterProp="label"
            options={options}
            showSearch
            style={style}
            value={value}
        />
    );
};
