import { FC } from "react";
import { AffiliateCostModel } from "@app/features/inventory/components/FormItems";
import { SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useSupplyFormInstance } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormInstance";

export const CostModel: FC = () => {
    const { seat, publisher, brand, supply } = useSupplyFormInstance();
    const extra: string = `Inherited value: ${
        supply?.affiliateCostModel?.name ||
        brand?.affiliateCostModel?.name ||
        publisher?.affiliateCostModel?.name ||
        seat?.affiliateCostModel?.name
    }`;

    return (
        <AffiliateCostModel
            extra={extra}
            name={SUPPLY_FORM_FIELDS.AFFILIATE_COST_MODEL.name}
            label={SUPPLY_FORM_FIELDS.AFFILIATE_COST_MODEL.label}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 24, xxl: 20 }}
            placeholder={SUPPLY_FORM_FIELDS.AFFILIATE_COST_MODEL.placeholder}
        />
    );
};
