import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@app/core/components";
import { Flex, Space, Typography } from "antd";
import { useSeatTree } from "../../SeatTree/useSeatTree";
import { Seat, useGetSeatSelfQuery } from "@app/core/services";
import { BrandSafetyHeaderControls } from "../../BrandSafetyHeaderControls";
import { BrandSafetyCollapsePanel, BrandSafetyCollapsePanelKeys } from "../BrandSafetyCollapsePanel";
import { useBrandSafetyCollapsePanel } from "../BrandSafetyPublisherPage/useBrandSafetyCollapsePanel";

export const BrandSafetySeatPage: FC = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data: seatSelf, isFetching } = useGetSeatSelfQuery(seatId);
    const seat: Seat | null = seatSelf?.seat || null;
    const { expandKeys, setSelectedKeys } = useSeatTree();
    const { activeKey, onChange, expandAll, collapseAll } = useBrandSafetyCollapsePanel({
        defaultActiveKey: BrandSafetyCollapsePanelKeys.SEAT,
    });

    useEffect((): void => {
        if (seat) {
            expandKeys([`seat-${seat.id}`]);
            setSelectedKeys([`seat-${seat.id}`]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seat]);

    if (!seat || isFetching) return <Loading />;

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Flex justify="space-between" align="baseline">
                <Typography.Title level={5}>Seat Brand Safety: {seat.name}</Typography.Title>
                <BrandSafetyHeaderControls expandAll={expandAll} collapseAll={collapseAll} />
            </Flex>
            <BrandSafetyCollapsePanel seat={seat} activeKey={activeKey} onChange={onChange} />
        </Space>
    );
};
