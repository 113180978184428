import { useAppDispatch, useAppSelector } from "@app/core/store";
import { dealFormDealTypeFieldChange, dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { DealFormMode, DealType } from "@app/features/deals/DealForm/types";
import { CREATE_DEAL_FORM_ITEMS_NAME, FORM_DEAL_TYPES_OPTIONS } from "@app/features/deals/constants";
import { AdSourceTypeIds, DefaultDemandAcquisitionCostModel } from "@app/features/seatAdSources/constants";

interface UseDealTypeField {
    onSelect: (value: number) => void;
    value: DealType;
    readonlyLabel: string | undefined;
    isReadonly: boolean;
}

export const useDealTypeField = (mode: DealFormMode): UseDealTypeField => {
    const dispatch = useAppDispatch();
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TYPE]);

    const onSelect = (value: DealType): void => {
        dispatch(dealFormDealTypeFieldChange(value));
        if (value === AdSourceTypeIds.UNRESERVED_FIXED_RATE) {
            dispatch(
                dealFormSelectFieldChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DEMAND_ACQUISITION_COST_MODEL,
                    value: DefaultDemandAcquisitionCostModel.id,
                })
            );
        }
    };
    const readonlyLabel = FORM_DEAL_TYPES_OPTIONS.find((item) => item?.value === value)?.label;
    const isReadonly = mode !== "create";

    return {
        onSelect,
        value,
        readonlyLabel,
        isReadonly,
    };
};
