import { FC } from "react";
import { useDimensionsSelect } from "./useDimensionsSelect";
import { applyCustomLabel, TagRender } from "@app/core/components/TagRender";
import { GeoTarget, GetGeographiesParams } from "@app/core/services/console";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { getGeoTargetLabel, getGeoTargetValue } from "../../../../helpers";
import { LabeledValue } from "antd/lib/select";
import { SelectAllButtonDropdownRender } from "@app/core/components/SelectAllButtonDropdownRender";

interface Props {
    filter: LabeledValue;
    onChange: (value: GeoTarget[]) => void;
    value: GeoTarget[];
}

export const DimensionsSelect: FC<Props> = ({ filter, onChange, value }) => {
    const { isCurrent, isLoading, options, handleChangeSearch, search, loadMore, hasMore } = useDimensionsSelect(
        filter.value as GetGeographiesParams["filter"]
    );

    return (
        <SelectWithValue<GeoTarget>
            allowClear
            filterOption={false}
            fieldAsLabel={(v) => getGeoTargetLabel(v)}
            fieldAsValue={(v) => getGeoTargetValue(v)}
            hasMore={hasMore}
            labelInValue
            loading={isLoading}
            loadMore={loadMore}
            mode="multiple"
            onBlur={() => handleChangeSearch("")}
            onChange={onChange}
            onSearch={handleChangeSearch}
            options={options}
            placeholder="Select Values"
            searchValue={search}
            style={{ width: "100%" }}
            tagRender={(props) => {
                return <TagRender {...props} label={applyCustomLabel(props.label)} />;
            }}
            dropdownRender={(menu) => (
                <SelectAllButtonDropdownRender<GeoTarget>
                    getPrimaryKey={getGeoTargetValue}
                    hasMore={hasMore}
                    isCurrent={isCurrent}
                    menu={menu}
                    onChange={onChange}
                    options={options}
                    search={search}
                    value={value}
                />
            )}
            value={value}
        />
    );
};
