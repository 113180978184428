import { FC } from "react";
import { Form } from "antd";
import { css } from "@emotion/css";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { MomentDatePicker } from "@app/core/components/MomentDatePicker";
import { useTargetDatePicker } from "./useTargetDatePicker";

const { label, name } = AD_SOURCE_FIELDS.TARGET_DATE;

export const TargetDatePicker: FC = () => {
    const { isVisible, validator } = useTargetDatePicker();

    if (!isVisible) {
        return null;
    }

    return (
        <Form.Item
            data-sdet={`test-${name}-field`}
            rules={[
                { required: true, message: `${label} is required` },
                {
                    validator,
                },
            ]}
            label={label}
            name={name}
            {...VERTICAL_LAYOUT_FIX}
        >
            <MomentDatePicker
                data-sdet={`test-${name}-date-picker`}
                allowClear
                className={css(`
        .ant-picker-input>input::placeholder {
                color: gray;
             }
        `)}
                placeholder="Select Date..."
                style={{ width: "100%" }}
            />
        </Form.Item>
    );
};
