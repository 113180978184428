import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@app/core/store";

export interface CustomPixelsSearchFilter {
    searchKeyword: string;
}

export interface CustomPixelsTableSort {
    sortBy: string;
    sortOrder: "ascend" | "descend";
}

export interface CustomPixelsState {
    searchFilter: CustomPixelsSearchFilter;
    sort: CustomPixelsTableSort;
}

const initialState: CustomPixelsState = {
    searchFilter: {
        searchKeyword: "",
    },
    sort: {
        sortBy: "updateDate",
        sortOrder: "descend",
    },
};

const customPixelsSlice = createSlice({
    name: "customPixels",
    initialState,
    reducers: {
        setSearchFilter: (state, action: PayloadAction<CustomPixelsSearchFilter>) => {
            state.searchFilter = action.payload;
        },
        setSort: (state, action: PayloadAction<CustomPixelsTableSort>) => {
            state.sort = action.payload;
        },
    },
});

export const selectCustomPixelsSearchFilter = (state: RootState) => state.customPixels.searchFilter;
export const selectCustomPixelsSort = (state: RootState) => state.customPixels.sort;

export const { setSearchFilter, setSort } = customPixelsSlice.actions;
export default customPixelsSlice.reducer;
