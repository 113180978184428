import { PageHeader } from "@ant-design/pro-layout";
import { css } from "@emotion/css";
import { Form, Layout, Steps, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { startCase } from "lodash";
import { BulkOperationSetupStep } from "./BulkOperationSetupStep";
import { BulkOperationDetailsStep } from "./BulkOperationDetailsStep";
import { BulkOperationConfirmationStep } from "./BulkOperationConfirmationStep";
import { useDispatch } from "react-redux";
import { resetBulkOperationSliceState } from "../reducer";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { BulkOperationSetupStepBottomButtons } from "./BulkOperationSetupStep/BulkOperationSetupStepBottomButtons";
import { BULK_OPERATION_FORM_ITEMS, initialBulkOperationValues } from "../constants";
import { BulkOperationEntityType, BulkOperationSourceEntity, BulkOperationType } from "@app/core/services";
import { BulkOperationDetailsStepBottomButtons } from "./BulkOperationDetailsStep/BulkOperationDetailsStepBottomButtons";
import { BulkOperationsUrlState } from "@app/core/components";
import { useGetEntityLabeledValue } from "./useGetEntityLabeledValue";

const getHeaderDescription = (
    type: BulkOperationType,
    entityType?: BulkOperationEntityType,
    sourceEntity?: BulkOperationSourceEntity
) => {
    if (type) {
        const descriptionBase = `Operation Type: ${startCase(type)}`;

        if (entityType || sourceEntity) {
            return `${descriptionBase} | Entity Type: ${startCase(entityType || sourceEntity)}`;
        }
        return descriptionBase;
    }

    return "";
};

export const BulkOperationCreatePage: FC = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const { state = { type: null, entityType: null, entityId: null, withChildren: undefined } } =
        useLocation<BulkOperationsUrlState>();
    // spread state to prevent useEffect from rerunning form.setFieldValue when back to step2 from step3
    const {
        type: typeFromLocation,
        entityType: entityTypeFromLocation,
        entityId: entityIdFromLocation,
        withChildren: withChildrenFromLocation,
    } = state;

    // spread object to prevent useEffect from rerunning form.setFieldValue when back to step2 from step3
    const { label: labelFromLocation, value: valueFromLocation } = useGetEntityLabeledValue(
        entityTypeFromLocation,
        entityIdFromLocation ? Number(entityIdFromLocation) : null
    );

    const history = useHistory();
    const dispatch = useDispatch();

    const [current, setCurrent] = useState(0);

    const [form] = Form.useForm();
    // add preserve: true, to get step one's fields value when on step two or three
    const type = Form.useWatch(BULK_OPERATION_FORM_ITEMS.OPERATION_TYPE.name, { form, preserve: true });
    const entityType = Form.useWatch(BULK_OPERATION_FORM_ITEMS.ENTITY_TYPE.name, { form, preserve: true });
    const sourceEntity = Form.useWatch(BULK_OPERATION_FORM_ITEMS.SOURCE_ENTITY.name, { form, preserve: true });

    //INFO: set form values from route state
    useEffect(() => {
        //INFO: we use PUBLISHER because all entities have the same field name
        if (labelFromLocation && valueFromLocation) {
            form.setFieldValue([BULK_OPERATION_FORM_ITEMS.PUBLISHER_TO_COPY.name], {
                label: labelFromLocation,
                value: valueFromLocation,
            });
        }
    }, [form, labelFromLocation, valueFromLocation]);

    useEffect(() => {
        if (typeFromLocation && entityTypeFromLocation && entityIdFromLocation) {
            const prefilledValues = {
                [BULK_OPERATION_FORM_ITEMS.OPERATION_TYPE.name]: typeFromLocation,
                [BULK_OPERATION_FORM_ITEMS.SOURCE_ENTITY.name]: entityTypeFromLocation,
                // [BULK_OPERATION_FORM_ITEMS.PUBLISHER_TO_COPY.name]: { value: Number(entityId) }, // selected value should be {value: number} format
                [BULK_OPERATION_FORM_ITEMS.COPY_INVENTORY_CHILDREN.name]: withChildrenFromLocation,
            };

            form.setFieldsValue(prefilledValues);
            setCurrent(1);
        }
    }, [form, typeFromLocation, entityTypeFromLocation, entityIdFromLocation, withChildrenFromLocation]);

    useEffect(
        () => () => {
            dispatch(resetBulkOperationSliceState());
        },
        [dispatch]
    );
    const goToNext = () => setCurrent(current + 1);
    const goToPrev = () => setCurrent(current - 1);

    return (
        <>
            <PageHeader
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BULK_OPERATIONS(seatId))}
                title={<span data-sdet="bulk-operation-create-header-title">Bulk Operations</span>}
            >
                <Steps
                    size="small"
                    current={current}
                    items={[
                        {
                            title: "Operation Setup",
                            description: (
                                <Typography.Text type="secondary">
                                    {getHeaderDescription(type, entityType, sourceEntity)}
                                </Typography.Text>
                            ),
                        },
                        { title: "Operation Details" },
                        { title: "Confirmation" },
                    ]}
                    // to make sure the "description" take up the line space
                    className={css`
                        .ant-steps-item-description {
                            display: table-row;
                        }
                    `}
                    style={{ width: "60%" }}
                />
            </PageHeader>
            <Form scrollToFirstError layout="vertical" form={form} initialValues={initialBulkOperationValues}>
                {current !== 2 && (
                    <Layout.Content>
                        {current === 0 && <BulkOperationSetupStep />}
                        {current === 1 && <BulkOperationDetailsStep />}
                    </Layout.Content>
                )}
                {current === 2 && <BulkOperationConfirmationStep goToPrev={goToPrev} />}
            </Form>
            {current === 0 && <BulkOperationSetupStepBottomButtons form={form} goToNext={goToNext} />}
            {current === 1 && (
                <BulkOperationDetailsStepBottomButtons form={form} goToNext={goToNext} goToPrev={goToPrev} />
            )}
        </>
    );
};
