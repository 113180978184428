import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Floor, useGetSupplyFloorsQuery } from "@app/core/services";
import { compareFloorsByPriority } from "../helpers";

interface UseDealListTable {
    floors?: Array<Floor>;
    isFetching: boolean;
    supplyId: string;
}

export const useSupplyFloorsTable = (): UseDealListTable => {
    const { supplyId } = useParams<{ supplyId: string }>();
    const { data, isFetching } = useGetSupplyFloorsQuery({ supplyId: Number(supplyId), list: "all" });

    const floors = useMemo(() => {
        return [...(data || [])].sort(compareFloorsByPriority);
    }, [data]);

    return {
        floors,
        isFetching,
        supplyId,
    };
};
