import { AdvertiserDomainRaw, useUpdateSequesteredDomainMutation } from "@app/core/services";
import { useHistory, useParams } from "react-router-dom";
import { useAdvertiserDomains } from "../../AdvertiserDomains/useAdvertiserDomains";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { CheckboxProps, Typography, notification } from "antd";
import { useMemo, useState } from "react";
import { LabeledValue } from "antd/es/select";

export const useSequesteredDomainsDrawer = () => {
    const { seatId, domain } = useParams<{ seatId: string; domain: string }>();
    const history = useHistory();
    const { isLoading, data } = useAdvertiserDomains({ isIncludeDomains: true });
    const requests = [] as unknown[];
    const [updateSequesteredDomainMutation, { isLoading: isUpdating }] = useUpdateSequesteredDomainMutation();
    const defaultCheckedList = [];

    const handleDrawerClose = () => {
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_TABS(seatId, "sequestered-domains"));
    };
    const vlOptions: LabeledValue[] = useMemo(
        () =>
            (data || [])?.reduce<LabeledValue[]>((acc, adv) => {
                if (!adv?.advertiserDomains?.includes(domain)) {
                    acc.push({ value: adv.id, label: adv.name });
                }
                return acc;
            }, []),
        [data, domain]
    );
    const [checkedList, setCheckedList] = useState<unknown[]>(defaultCheckedList);

    const checkedAll = vlOptions.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < vlOptions.length;

    const handleChange = (list: unknown[]) => {
        setCheckedList(list);
    };

    const handleCheckAllChange: CheckboxProps["onChange"] = (e) => {
        setCheckedList(e.target.checked ? vlOptions.map(({ value }) => value) : []);
    };
    const handleCancelClick = () => {
        setCheckedList(defaultCheckedList);
    };

    const handleSubmit = () => {
        checkedList.forEach((id) => {
            return requests.push(updateSequesteredDomainMutation({ id: Number(id), domain }).unwrap());
        });
        Promise.all(requests)
            .then((res) =>
                notification.success({
                    message: (
                        <Typography.Text>
                            <Typography.Text strong>{domain}</Typography.Text> has been added to{" "}
                            <Typography.Text strong>{(res?.[0] as AdvertiserDomainRaw).name}</Typography.Text>{" "}
                            successfully
                        </Typography.Text>
                    ),
                })
            )
            .catch((err) => {
                notification.error({
                    message:
                        err.data?.errorDescription ||
                        err.data?.errorCode ||
                        "Something wrong has occurred, please contact your account manager",
                });
            })
            .finally(() => {
                handleDrawerClose();
            });
    };

    return {
        isLoading: isLoading || isUpdating,
        data,
        domain,
        checkedAll,
        checkedList,
        vlOptions,
        indeterminate,
        handleDrawerClose,
        handleCancelClick,
        handleChange,
        handleSubmit,
        handleCheckAllChange,
    };
};
