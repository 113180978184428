import { FC } from "react";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "../../types";
import {
    SeatAdSourcesDetailsDrawerDirectTitle as DirectOpenTitle,
    SeatAdSourcesDetailsDrawerNavBarTitle as MainNavBarTitle,
    SeatAdSourcesDetailsDrawerNestedTitle as NestedBreadcrumbTitle,
} from "./titles";
import { useSeatAdSourcesDetailsDrawerTitle } from "./useSeatAdSourcesDetailsDrawerTitle";

export enum SeatAdSourcesDetailsDrawerSecondaryHeader {
    NestedHeader = "NestedHeader",
    DirectHeader = "DirectHeader",
}

interface SeatAdSourcesDetailsDrawerTitleInfo {
    adSourceId: number;
    adSourceName: string;
    secondaryDetailsView: SecondaryDetailsView;
}

interface Props {
    titleInfo: SeatAdSourcesDetailsDrawerTitleInfo;
    adSourceTypeId: number;
}

export enum SeatAdSourcesDetailsDrawerTitle {
    NestedHeader = "NestedHeader",
    DirectHeader = "DirectHeader",
    MainHeader = "MainHeader",
}

export const SeatAdSourcesDetailsDrawerTitleSwitch: FC<Props> = ({ titleInfo, adSourceTypeId }) => {
    const { title } = useSeatAdSourcesDetailsDrawerTitle();
    switch (title) {
        case SeatAdSourcesDetailsDrawerSecondaryHeader.NestedHeader:
            return (
                <NestedBreadcrumbTitle
                    adSourceName={titleInfo.adSourceName}
                    secondaryDetailsView={titleInfo.secondaryDetailsView}
                />
            );
        case SeatAdSourcesDetailsDrawerSecondaryHeader.DirectHeader:
            return (
                <DirectOpenTitle
                    adSourceName={titleInfo.adSourceName}
                    secondaryDetailsView={titleInfo.secondaryDetailsView}
                />
            );
        default:
            return (
                <MainNavBarTitle
                    adSourceId={titleInfo.adSourceId}
                    adSourceName={titleInfo.adSourceName}
                    adSourceTypeId={adSourceTypeId}
                />
            );
    }
};
