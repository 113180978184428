import { FC } from "react";
import { Typography } from "antd";
import { PacingProgress } from "@app/core/services";
import { getPacingStatusAttributes } from "../PacingProgressBar/utils";

const { Text } = Typography;

interface Props {
    pacingProgress: PacingProgress;
}

export const PacingIndicatorStatusText: FC<Props> = ({ pacingProgress }) => {
    const { textColor, text } = getPacingStatusAttributes(pacingProgress?.pacingStatus?.id);

    return (
        <div data-sdet="pacing-indicator-status-text">
            <Text strong style={{ color: textColor }}>
                {text}
            </Text>
            {pacingProgress?.opportunitiesLessThanTarget && (
                <Text>&nbsp;Current impression opportunities less than target. Increase supply to reach goal.</Text>
            )}
        </div>
    );
};
