import { useEffect, useMemo } from "react";
import { message } from "antd";
import { useAppSelector } from "@app/core/store";
import { useUpdateUserPreferencesMutation, UserPreference } from "@app/core/services";
import { DealSettings } from "@app/features/deals/DealListPage/reducer";
import { useSeatAuthContext } from "@app/core/auth";

export const useUpdateSettingsOnChange = () => {
    const { session } = useSeatAuthContext();
    const settings = useAppSelector((state) => state.deals.settings);

    const [updateUserPreferences] = useUpdateUserPreferencesMutation();

    const handleUpdateUserPreferences = useMemo(
        () => async (userPreferences: DealSettings) => {
            const body = Object.keys(userPreferences)
                .map((key: keyof typeof userPreferences) => ({
                    key,
                    value: userPreferences[key],
                }))
                .filter((preference) => preference.value !== null) as Partial<UserPreference>[];
            try {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                await updateUserPreferences({ id: session.user.id, body });
            } catch (err) {
                message.error("Failed to Update Deals Settings");
            }
        },
        [session, updateUserPreferences]
    );

    useEffect(() => {
        const { values, shouldUpdate } = settings;
        if (shouldUpdate) {
            handleUpdateUserPreferences(values);
        }
    }, [settings, handleUpdateUserPreferences]);
};
