import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { Form } from "antd";

export const useDomainNameOverride = () => {
    const allowDomainName = Form.useWatch<AdSourcesForm["allowDomainName"]>(AD_SOURCE_FIELDS.DOMAIN_NAME.name);
    const isVisible = allowDomainName === "override";
    return {
        isVisible,
    };
};
