import { Button, Form, Space, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { AD_UNIT_AD_BREAK_RULES_LABELS } from "@app/features/inventory/InventoryHealthDetailsPages/constants";
import { AdBreakLine } from "../AdBreakLine";
import { AdBreakRuleAds, AdBreakRuleDuration, AdBreakRuleIndex, AdBreakRuleName } from "../../AdBreakRules";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useMidRoll } from "./useMidRoll";
import { brandCobalt } from "@rubicon/antd-components";
import { css } from "@emotion/css";

export const MidRoll = () => {
    const { handleRemoveAll, getSubTitle, handleAdd, postRollValues, preRollValues } = useMidRoll();
    return (
        <Form.List
            name={CHANNEL_FORM_FIELDS.AD_BREAK_RULES_MID.name}
            rules={[
                {
                    validator: (_, values) => {
                        if (!values.length && !preRollValues?.length && !postRollValues?.length) {
                            return Promise.reject(new Error());
                        }
                        return Promise.resolve();
                    },
                },
            ]}
        >
            {(fields, { add, remove }) => (
                <>
                    <Typography.Title
                        level={5}
                        className={css`
                            &.ant-typography {
                                margin-top: 1.5rem;
                            }
                            margin-bottom: ${fields.length ? "6px" : "12px"};
                        `}
                    >
                        {AD_UNIT_AD_BREAK_RULES_LABELS.midRoll}
                    </Typography.Title>
                    {fields.map((field) => (
                        <>
                            <Typography.Text style={{ display: "block", marginBottom: "6px" }}>
                                {getSubTitle(field)}
                            </Typography.Text>
                            <AdBreakLine
                                key={field.name.toString()}
                                field={field}
                                isVisibleIndex={field.name > 0}
                                name={AdBreakRuleName.MID_ROLL_NAME}
                                ads={AdBreakRuleAds.MID_ROLL_ADS}
                                duration={AdBreakRuleDuration.MID_ROLL_DURATION}
                                indexName={AdBreakRuleIndex.MID_ROLL_INDEX}
                                onRemove={remove}
                                disabledRemove={field.key === 0 && fields.length > 1}
                            />
                        </>
                    ))}
                    <Space size="middle">
                        <Button
                            type="link"
                            onClick={() => handleAdd(add)}
                            icon={<PlusOutlined />}
                            style={{
                                paddingLeft: "0.2rem",
                                paddingRight: 0,
                                paddingTop: 0,
                                paddingBottom: 0,
                                height: 26,
                                color: brandCobalt,
                            }}
                        >
                            Add
                        </Button>
                        {Boolean(fields.length) && (
                            <>
                                <div style={{ color: brandCobalt }}>|</div>
                                <Button
                                    onClick={() => handleRemoveAll(remove, fields.length)}
                                    block
                                    type="link"
                                    style={{
                                        padding: 0,
                                        height: 26,
                                        color: brandCobalt,
                                    }}
                                >
                                    Remove All
                                </Button>
                            </>
                        )}
                    </Space>
                </>
            )}
        </Form.List>
    );
};
