import { Form, Select } from "antd";
import { useAdSourceAuctionTypeField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceFloorSettingsSection/Fields/AdSourceAuctionTypeField/useAdSourceAuctionTypeField";

export const AdSourceAuctionTypeField = () => {
    const { options, value, isLoading, handleChange } = useAdSourceAuctionTypeField();
    return (
        <Form.Item label="Auction Type" data-sdet="ad-source-auction-type-field">
            <Select
                placeholder="Select Auction Type"
                options={options}
                value={value}
                loading={isLoading}
                onChange={handleChange}
            />
        </Form.Item>
    );
};
