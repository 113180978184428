import { useNotificationError } from "@app/core/components/hooks";
import { useSalesContactSelect } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/AdSourceTerms/Fields/SalesContact/useSalesContactSelect";
import { FC } from "react";
import { LoadOnScrollSelect, TagRender } from "@app/core/components";
import { Select } from "antd";
import { UNSPECIFIED_OPTION } from "@app/features/controls/bulkOperations/constants";

interface Props {
    disabled?: boolean;
    mode?: "multiple" | "tags" | undefined;
    value?: number[];
    placeholder?: string;
}
export const SalesContactFilter: FC<Props> = ({ value, placeholder = "Select…", ...restProps }) => {
    const { contacts, isLoading, onSearch, hasMore, loadMore, error } = useSalesContactSelect();
    useNotificationError(error);

    const options = contacts
        ? [UNSPECIFIED_OPTION].concat(
              contacts.map(({ id, name, emailAddress }) => ({ label: `${name} [${emailAddress}]`, value: id }))
          )
        : [];

    const commonProps = {
        "data-sdet": "sales-contact-filter",
        filterOption: false,
        hasMore,
        loading: isLoading,
        loadMore,
        onBlur: () => onSearch(""),
        onSearch,
        options,
        popupMatchSelectWidth: false,
        showSearch: true,
        tagRender: TagRender,
        value,
        placeholder,
        ...restProps,
    };

    if (loadMore) {
        return <LoadOnScrollSelect {...commonProps} hasMore={Boolean(hasMore)} loadMore={loadMore} />;
    }

    return <Select {...commonProps} />;
};
