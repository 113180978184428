import { FC, ReactNode, useState } from "react";
import { ValueMetric1, ValueMetric2 } from "../DualAxesChart";
import { ChartLegend, LegendDataPoint } from "./ChartLegend/ChartLegend";
import { DEFAULT_LEGEND_DATA_POINTS } from "@app/core/components/charts/constants";

interface TogglableChartProps {
    metricOne?: ValueMetric1[];
    metricTwo?: ValueMetric2[];
    chartRenderer: (metricOne, metricTwo) => ReactNode;
    showClearAll?: boolean;
}

export const filterValue1Metrics = (metrics: ValueMetric1[], legendCheckedData: LegendDataPoint[]) => {
    return filterMetrics(metrics, legendCheckedData, "name1");
};

export const filterValue2Metrics = (metrics: ValueMetric2[], legendCheckedData: LegendDataPoint[]) => {
    return filterMetrics(metrics, legendCheckedData, "name2");
};

export const filterMetrics = (
    metrics: { name1?: string; name2?: string }[],
    legendCheckedData: LegendDataPoint[],
    field: "name1" | "name2"
) => {
    if (!legendCheckedData) {
        return metrics;
    }

    return metrics.filter((metric) => {
        const ld = legendCheckedData.find((ld) => ld.label === metric[field]);
        return ld?.checked;
    });
};

const getDefaultLegendDataPoints = (legendLabels: string[]) => {
    return DEFAULT_LEGEND_DATA_POINTS.map((ldp) => ({ ...ldp })).filter((ldp) => legendLabels.includes(ldp.label)); //creating copy of DEFAULT_LEGEND_DATA_POINTS so they dont change for all graphs
};
export const TogglableChart: FC<TogglableChartProps> = ({
    metricOne = [],
    metricTwo = [],
    chartRenderer,
    showClearAll = true,
}) => {
    const [legendDataState, setLegendDataState] = useState<LegendDataPoint[] | null>(null);

    let legendData: LegendDataPoint[] | null = legendDataState;
    if (legendData === null) {
        // 1st pass through this function, legendDataState is null - so we filter the DEFAULT_LEGEND_DATA_POINTS until we have state to use
        const name1s = new Set(metricOne.map((m) => m.name1));
        const name2s = new Set(metricTwo.map((m) => m.name2));
        const legendLabels = [...name1s, ...name2s];
        legendData = getDefaultLegendDataPoints(legendLabels);
    }

    return (
        <>
            {chartRenderer(
                filterValue1Metrics(metricOne, legendData) as ValueMetric1[],
                filterValue2Metrics(metricTwo, legendData) as ValueMetric2[]
            )}
            {legendData && legendData.length > 0 && (
                <ChartLegend data={legendData} onChange={(ld) => setLegendDataState(ld)} showClearAll={showClearAll} />
            )}
        </>
    );
};
