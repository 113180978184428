import { FC } from "react";
import { Form, Radio, RadioProps } from "antd";
import { MAX_EXTENDED, AD_UNIT_MAX_EXTENDED_OPTIONS } from "@app/features/inventory/HierarchyForms/constants";

interface AdUnitMaxExtendedProps extends RadioProps {
    name: typeof MAX_EXTENDED;
    label: string;
}

export const AdUnitMaxExtended: FC<AdUnitMaxExtendedProps> = ({ name, label, ...rest }) => (
    <Form.Item name={name} label={label}>
        <Radio.Group size="large" options={AD_UNIT_MAX_EXTENDED_OPTIONS} {...rest} />
    </Form.Item>
);
