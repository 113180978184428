import {
    ThirdPartyPixel,
    useGetAdUnitInheritedPixelsQuery,
    useGetBrandInheritedPixelsQuery,
    useGetPublisherInheritedPixelsQuery,
    useGetSupplyInheritedPixelsQuery,
} from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import {
    selectInventoryDetailsDrawerType,
    InventoryDetailsDrawerType,
} from "@app/features/inventory/DetailsDrawer/reducer";
import { useMemo } from "react";

interface UsePixels {
    pixels: ThirdPartyPixel[];
}

export const usePixels = (id: number): UsePixels => {
    const detailsDrawerType = useAppSelector(selectInventoryDetailsDrawerType);
    const { data: publisherPixels } = useGetPublisherInheritedPixelsQuery(
        {
            publisherId: id,
            includeMine: true,
        },
        { skip: detailsDrawerType !== InventoryDetailsDrawerType.PUBLISHER }
    );
    const { data: brandPixels } = useGetBrandInheritedPixelsQuery(
        {
            brandId: id,
            includeMine: true,
        },
        { skip: detailsDrawerType !== InventoryDetailsDrawerType.BRAND }
    );
    const { data: supplyPixels } = useGetSupplyInheritedPixelsQuery(
        {
            supplyId: id,
            includeMine: true,
        },
        { skip: detailsDrawerType !== InventoryDetailsDrawerType.SUPPLY }
    );
    const { data: adUnitPixels } = useGetAdUnitInheritedPixelsQuery(
        {
            adUnitId: id,
            includeMine: true,
        },
        { skip: detailsDrawerType !== InventoryDetailsDrawerType.AD_UNIT }
    );

    const pixels = useMemo(
        () => publisherPixels || brandPixels || supplyPixels || adUnitPixels || [],
        [publisherPixels, brandPixels, supplyPixels, adUnitPixels]
    );

    return {
        pixels,
    };
};
