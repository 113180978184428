import { Button, Col, Row, Space } from "antd";
import { ClassificationsLabelDeleteButton } from "./ClassificationsLabelDeleteButton";
import { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Label } from "@app/core/services";

interface Props {
    label?: Label;
    isDrawer?: boolean;
}

export const CLASSIFICATIONS_LABEL_DETAILS_ACTIONS_SDET = "classifications-label-details-actions";
export const CLASSIFICATIONS_LABEL_DETAILS_ACTIONS_EDIT_BUTTON_SDET = "classifications-label-details-actions-edit-btn";
export const CLASSIFICATIONS_LABEL_DETAILS_ACTIONS_DELETE_BUTTON_SDET =
    "classifications-label-details-actions-delete-btn";

export const ClassificationsLabelDetailsActions: FC<Props> = ({ label, isDrawer = false }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();
    return (
        <Row data-sdet={CLASSIFICATIONS_LABEL_DETAILS_ACTIONS_SDET}>
            <Col>
                <Space>
                    <Button
                        onClick={() =>
                            label &&
                            history.push(
                                ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_LABEL_EDIT_PAGE(seatId, label.id)
                            )
                        }
                        data-sdet={CLASSIFICATIONS_LABEL_DETAILS_ACTIONS_EDIT_BUTTON_SDET}
                        type="primary"
                    >
                        Edit
                    </Button>
                    <ClassificationsLabelDeleteButton isDrawer={isDrawer} label={label} />
                </Space>
            </Col>
        </Row>
    );
};
