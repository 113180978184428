import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { DealCreatePage } from "./DealCreatePage";
import { DealHealthPage } from "./DealHealthPage";
import { DealListPage } from "./DealListPage";
import { DealDetailsPage } from "./DealDetailsPage";
import { DealCreateFromPage } from "@app/features/deals/DealCreateFormPage";
import { DealEditFormPage } from "./DealEditFormPage";
import { DealCopyFormPage } from "./DealCopyFormPage";

export const DealsSwitch: FC = () => {
    return (
        <Switch>
            <Route exact path={ROUTES.SEAT_DEAL_CREATE}>
                <DealCreatePage />
            </Route>
            <Route
                exact
                path={[
                    ROUTES.SEAT_DEAL_HEALTH,
                    ROUTES.SEAT_DEALS_HEALTH_AD_SOURCES_DRAWER,
                    ROUTES.SEAT_DEALS_HEALTH_AD_SOURCES_DRAWER_SECONDARY_VIEW,
                    ROUTES.SEAT_DEAL_HEALTH_DEAL_DETAILS_DRAWER,
                    ROUTES.SEAT_DEAL_HEALTH_DEAL_DETAILS_DRAWER_SECONDARY_VIEW,
                    ROUTES.SEAT_DEAL_HEALTH_DEAL_SECONDARY_VIEW,
                ]}
            >
                <DealHealthPage />
            </Route>
            <Route
                exact
                path={[
                    ROUTES.SEAT_DEAL_CREATE_TYPE,
                    ROUTES.SEAT_DEAL_CREATE_TYPE_AD_SOURCES_DRAWER,
                    ROUTES.SEAT_DEAL_CREATE_TYPE_AD_SOURCES_DRAWER_SECONDARY_VIEW,
                ]}
            >
                <DealCreateFromPage />
            </Route>
            <Route
                exact
                path={[
                    ROUTES.SEAT_DEAL_DETAILS,
                    ROUTES.SEAT_DEAL_DETAILS_AD_SOURCES_DRAWER,
                    ROUTES.SEAT_DEAL_DETAILS_AD_SOURCES_DRAWER_SECONDARY_VIEW,
                ]}
            >
                <DealDetailsPage />
            </Route>
            <Route
                exact
                path={[
                    ROUTES.SEAT_DEAL_EDIT,
                    ROUTES.SEAT_DEAL_EDIT_AD_SOURCES_DRAWER,
                    ROUTES.SEAT_DEAL_EDIT_AD_SOURCES_DRAWER_SECONDARY_VIEW,
                ]}
            >
                <DealEditFormPage />
            </Route>
            <Route
                exact
                path={[
                    ROUTES.SEAT_DEAL_COPY,
                    ROUTES.SEAT_DEAL_COPY_AD_SOURCES_DRAWER,
                    ROUTES.SEAT_DEAL_COPY_AD_SOURCES_DRAWER_SECONDARY_VIEW,
                ]}
            >
                <DealCopyFormPage />
            </Route>
            <Route
                exact
                path={[
                    ROUTES.SEAT_DEAL_LIST,
                    ROUTES.SEAT_DEALS_AD_SOURCES_DRAWER,
                    ROUTES.SEAT_DEALS_AD_SOURCES_DRAWER_SECONDARY_VIEW,
                    ROUTES.SEAT_DEALS_DEAL_DETAILS_DRAWER,
                    ROUTES.SEAT_DEALS_DEAL_DETAILS_DRAWER_SECONDARY_VIEW,
                    ROUTES.SEAT_DEALS_SECONDARY_VIEW,
                ]}
            >
                <DealListPage />
            </Route>
        </Switch>
    );
};
