import { FC } from "react";
import { Footer, InventoryBulkOperationsLink } from "@app/core/components";
import { useParams } from "react-router-dom";
import { PageLoader } from "@rubicon/antd-components";
import { Typography, Layout } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useGetAdUnitQuery } from "@app/core/services";
import { AdUnitForm } from "@app/features/inventory/HierarchyForms/AdUnitForm";
import { AdUnitFormInstance } from "@app/features/inventory/HierarchyForms/AdUnitForm/AdUnitFormInstance";
import { useHistory } from "react-router-dom";
import { useScrollToTopOnMount } from "@app/core/components/hooks";

export const AdUnitCopyPage: FC = () => {
    useScrollToTopOnMount();
    const history = useHistory();
    const { seatId, adUnitId } = useParams<{ seatId: string; adUnitId: string }>();
    const { data: adUnit, isFetching } = useGetAdUnitQuery(Number(adUnitId));

    if (isFetching || !adUnit) {
        return <PageLoader />;
    }

    return (
        <>
            <PageHeader onBack={() => history.goBack()} title="Copy Ad Unit">
                <>
                    <Typography.Text>
                        Ad Unit will be placed in same parent destination. To do advanced copy, go to{" "}
                    </Typography.Text>
                    <InventoryBulkOperationsLink seatId={seatId} id={adUnitId} entityType="adUnit" />
                </>
            </PageHeader>
            <Layout.Content>
                <AdUnitFormInstance mode="copy" adUnit={adUnit}>
                    <AdUnitForm />
                </AdUnitFormInstance>
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
