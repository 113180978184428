import { DualAxes, DualAxesConfig } from "@ant-design/plots";
import { ChartLegend, LegendDataPoint } from "@app/core/components/charts/TogglableChart/ChartLegend/ChartLegend";
import { Flex, Typography } from "antd";
import { FC } from "react";

interface Props {
    config: DualAxesConfig;
    legendData: LegendDataPoint[];
    setLegendData: (data: LegendDataPoint[]) => void;
    isFetching: boolean;
}
export const HistoricalFloorAnalysisChart: FC<Props> = ({ config, legendData, setLegendData, isFetching }) => {
    return (
        <Flex vertical data-sdet="historical-floor-analysis-chart-wrapper">
            {!isFetching && (
                <Flex justify="space-between">
                    <Typography.Title level={5} style={{ fontSize: "14px", marginTop: 0 }}>
                        Floor Price
                    </Typography.Title>
                    <Typography.Title level={5} style={{ fontSize: "14px", marginTop: 0 }}>
                        Revenue
                    </Typography.Title>
                </Flex>
            )}
            <DualAxes {...config} />
            {Boolean(legendData?.length) && (
                <ChartLegend data={legendData} onChange={(ld) => setLegendData(ld)} showClearAll={false} />
            )}
        </Flex>
    );
};
