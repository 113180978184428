import { FC } from "react";
import { Form, Select } from "antd";
import { Seat } from "@app/core/services";
import { NONE } from "@app/core/components/constants";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { useTestCreativeId } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/AdvancedFeaturesForm/AdvancedFeaturesFormFields/TestCreativeId/useTestCreativeId";

interface TestCreativeIdProps {
    seat: Seat;
    edit?: boolean;
}

export const TestCreativeId: FC<TestCreativeIdProps> = ({ edit = false, seat }) => {
    const { isFetching, options, isEnableTestCreativeUpload, optionsByValue } = useTestCreativeId();
    const { testCreativeId } = seat;

    if (edit && !isEnableTestCreativeUpload) return null;

    return (
        <Form.Item
            name={INVENTORY_FORM_FIELDS.TEST_CREATIVE_ID.name}
            label={INVENTORY_FORM_FIELDS.TEST_CREATIVE_ID.label}
        >
            {edit ? (
                <Select
                    showSearch
                    allowClear
                    options={options}
                    loading={isFetching}
                    optionFilterProp="label"
                    placeholder={INVENTORY_FORM_FIELDS.TEST_CREATIVE_ID.placeholder}
                />
            ) : testCreativeId ? (
                optionsByValue[testCreativeId]?.label
            ) : (
                NONE
            )}
        </Form.Item>
    );
};
