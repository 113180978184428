import { useEffect, useRef } from "react";

import { Card, Col, Form, Row, Typography } from "antd";
import { Creative } from "./CreativeReview";
import { CreativeReviewLoudnessChart } from "./CreativeReviewLoudnessChart";

import { bytesToSize, millisToMinutesAndSeconds } from "./utils";
import { css, cx } from "@emotion/css";
import { useCreativeReviewContent } from "./useCreativeReviewContent";
import moment from "moment-timezone";

const classListCard = css`
    border-bottom: 0;
    border-radius: 0;
`;

const classListCardLeft = css`
    height: 100%;
    border-right: 0;
`;

const classListText = css`
    font-weight: normal;
`;

interface Props {
    creative: Creative;
}

export const CreativeReviewContent = ({ creative }: Props) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const { mediaData, details, loudness, rawResponseUrl, parsedFromRawCreative } = useCreativeReviewContent(creative);

    useEffect(() => {
        if (mediaData?.url && videoRef.current) {
            videoRef.current.load();
        }
    }, [mediaData?.url]);
    return (
        <div data-sdet="creative-review-content">
            <Row>
                <Col xs={14}>
                    <Card
                        title={
                            <Row justify="space-between">
                                <Col>Creative Loudness and Player</Col>
                                <Col>
                                    <Typography.Text className={classListText}>
                                        Last Seen: {moment(mediaData?.lastSeen).format("DD MMM YYYY hh:mm a")}
                                    </Typography.Text>
                                </Col>
                            </Row>
                        }
                        type="inner"
                        styles={{ header: { padding: "0 1rem" }, body: { padding: "0 1rem" } }}
                        className={cx(classListCardLeft, classListCard)}
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                {mediaData && (
                                    <CreativeReviewLoudnessChart loudnessSamples={details?.loudnessSamples || []} />
                                )}
                            </Col>
                            <Col span={24}>
                                {mediaData && (
                                    <video
                                        ref={videoRef}
                                        controls
                                        className={css(`
                                    width: 100%;
                                    height: 300px;
                                    border: 1px solid #ccc;
                                `)}
                                    >
                                        <source src={mediaData?.url} />
                                    </video>
                                )}
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={10}>
                    <Card
                        title="Creative Details"
                        type="inner"
                        styles={{ header: { padding: "0 1rem" } }}
                        className={classListCard}
                    >
                        <Row>
                            <Col xs={24}>
                                <Form>
                                    <Form.Item label="Ad System" colon={false} style={{ marginBottom: "8px" }}>
                                        {parsedFromRawCreative.adSystem || "--"}
                                    </Form.Item>
                                    <Form.Item
                                        label="Ad Title"
                                        colon={false}
                                        style={{
                                            marginBottom: "8px",

                                            wordWrap: "break-word",
                                            wordBreak: "break-word",
                                        }}
                                    >
                                        {parsedFromRawCreative.adTitle || "--"}
                                    </Form.Item>
                                    <Form.Item label="Loudness" colon={false} style={{ marginBottom: "8px" }}>
                                        <Typography.Text type="warning">{loudness}</Typography.Text>
                                    </Form.Item>
                                    <Form.Item label="Codec" colon={false} style={{ marginBottom: "8px" }}>
                                        {details?.codecName || "--"}
                                    </Form.Item>
                                    <Form.Item label="Frame Rate" colon={false} style={{ marginBottom: "8px" }}>
                                        {details?.framerate || 0}
                                    </Form.Item>
                                    <Form.Item label="Format" colon={false} style={{ marginBottom: "8px" }}>
                                        {details?.formatName || "--"}
                                    </Form.Item>
                                    <Form.Item label="Duration" colon={false} style={{ marginBottom: "8px" }}>
                                        {millisToMinutesAndSeconds(details?.duration || 0)}
                                    </Form.Item>
                                    <Form.Item label="Bit Rate" colon={false} style={{ marginBottom: "8px" }}>
                                        {bytesToSize(details?.bitRate || 0, 2)}
                                    </Form.Item>
                                    <Form.Item label="Width" colon={false} style={{ marginBottom: "8px" }}>
                                        {details?.width || 0}
                                    </Form.Item>
                                    <Form.Item label="Height" colon={false} style={{ marginBottom: "8px" }}>
                                        {details?.height || 0}
                                    </Form.Item>
                                    <Form.Item label="Audio Bit Rate" colon={false} style={{ marginBottom: "8px" }}>
                                        {bytesToSize(details?.audioBitRate || 0, 2)}
                                    </Form.Item>
                                    <Form.Item label="Audio Channels" colon={false} style={{ marginBottom: "8px" }}>
                                        {details?.audioChannels || 0}
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <Card
                        title="VAST Summary"
                        type="inner"
                        styles={{
                            header: { padding: "0 1rem" },
                            body: {
                                paddingLeft: "1rem",
                                paddingRight: "1rem",
                                paddingBottom: 0,
                            },
                        }}
                        className={classListCard}
                    >
                        <Row>
                            <Col style={{ marginBottom: "16px" }} span={24}>
                                <a href={rawResponseUrl} target="_blank" rel="noopener noreferrer">
                                    View raw VAST response
                                </a>
                            </Col>
                            <Col span={24}>
                                <Form>
                                    <Typography.Text strong>Vast Ad</Typography.Text>
                                    <Form.Item label="Ad System" colon={false} style={{ marginBottom: "8px" }}>
                                        {parsedFromRawCreative.adSystem || "--"}
                                    </Form.Item>
                                    <Form.Item label="Ad Title" colon={false} style={{ marginBottom: "8px" }}>
                                        {parsedFromRawCreative.adTitle || "--"}
                                    </Form.Item>
                                    <Form.Item label="Linear Creative -" colon={false} style={{ marginBottom: "8px" }}>
                                        <Typography.Text strong>
                                            Duration {parsedFromRawCreative.duration || 0}
                                        </Typography.Text>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col span={24}>
                                <Row gutter={[16, 16]}>
                                    {parsedFromRawCreative.mediaFiles?.map((mediaFile) => (
                                        <Col span={12} key={mediaFile.url}>
                                            <Card title="Media File">
                                                <Form>
                                                    <Form.Item
                                                        label="Type"
                                                        colon={false}
                                                        style={{ marginBottom: "8px" }}
                                                    >
                                                        {mediaFile.type || "--"}
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Height"
                                                        colon={false}
                                                        style={{ marginBottom: "8px" }}
                                                    >
                                                        {mediaFile.height || 0}
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Width"
                                                        colon={false}
                                                        style={{ marginBottom: "8px" }}
                                                    >
                                                        {mediaFile.width || 0}
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Delivery"
                                                        colon={false}
                                                        style={{ marginBottom: "8px" }}
                                                    >
                                                        {mediaFile.delivery || "--"}
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Bitrate"
                                                        colon={false}
                                                        style={{ marginBottom: "8px" }}
                                                    >
                                                        {bytesToSize(Number(mediaFile.bitrate || 0), 2)}
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="ApiFramework"
                                                        colon={false}
                                                        style={{ marginBottom: "8px" }}
                                                    >
                                                        {mediaFile.apiFramework || "--"}
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="URL"
                                                        colon={false}
                                                        style={{
                                                            marginBottom: "8px",
                                                            wordWrap: "break-word",
                                                            wordBreak: "break-word",
                                                            maxWidth: "400px",
                                                        }}
                                                    >
                                                        {mediaFile.url || "--"}
                                                    </Form.Item>
                                                </Form>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};
