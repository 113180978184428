import { FC } from "react";
import { Form, Select } from "antd";
import { usePreferredCurrencyField } from "./usePreferredCurrencyField";

export const PreferredCurrencyField: FC = () => {
    const { options, value, isLoading, handleChangeValue } = usePreferredCurrencyField();

    return (
        <Form.Item colon={false} data-sdet="preferred-currency-field">
            <Select
                loading={isLoading}
                onChange={handleChangeValue}
                optionFilterProp="label"
                options={options}
                showSearch
                value={value}
            />
        </Form.Item>
    );
};
