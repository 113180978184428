import { SelectAllDropdownRender, TagRender } from "@app/core/components";
import { useNotificationError } from "@app/core/components/hooks";
import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { QueryDefinition } from "@reduxjs/toolkit/query";
import { Form, Select } from "antd";
import { FC, useMemo } from "react";

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useGetOptionsQuery: UseQuery<QueryDefinition<any, any, any, any>>;
    value?: number[];
    nameKey?: string;
}
export const StatusFilter: FC<Props> = ({ nameKey = "status", useGetOptionsQuery, value, ...restProps }) => {
    const form = Form.useFormInstance();
    const { data, isFetching, error } = useGetOptionsQuery(undefined);
    useNotificationError(error);

    const options = useMemo(() => (data || []).map(({ name, id }) => ({ label: name, value: id })), [data]);
    const areAllOptionsSelected = Boolean(data && value?.length === data.length);

    const toggleSelectDeselectAll = () => {
        if (areAllOptionsSelected) {
            form.setFieldValue(["filters", nameKey], []);
        } else {
            form.setFieldValue(
                ["filters", nameKey],
                options.map(({ value }) => value)
            );
        }
    };

    return (
        <Select
            allowClear
            showSearch
            data-sdet="status-filter"
            dropdownRender={(menu) => (
                <SelectAllDropdownRender
                    menu={menu}
                    onClick={toggleSelectDeselectAll}
                    areAllOptionsSelected={areAllOptionsSelected}
                />
            )}
            loading={isFetching}
            mode="multiple"
            autoClearSearchValue={false}
            options={options}
            optionFilterProp="label"
            placeholder="Select…"
            tagRender={TagRender}
            value={value}
            {...restProps}
        />
    );
};
