import { css } from "@emotion/css";
import { ResizableDrawer } from "@rubicon/antd-components";
import { Button, Input, Space } from "antd";
import { FC } from "react";

interface Props {
    open: boolean;
    onClose: () => void;
    onEdit: () => void;
    domains: string[];
}
export const AdvancedBrandSafetyAdvDomainsDrawer: FC<Props> = ({ domains, open, onClose, onEdit }) => {
    return (
        <ResizableDrawer
            title={
                <Space direction="vertical">
                    Advertiser Domains
                    <Button type="primary" onClick={onEdit}>
                        Edit
                    </Button>
                </Space>
            }
            onClose={onClose}
            open={open}
            placement="right"
            width="40%"
            // move Drawer close icon to right end
            className={css`
                .ant-drawer-close {
                    position: absolute;
                    top: 19px;
                    right: 0;
                }
            `}
        >
            <Input.TextArea autoSize value={domains.join("\n")} readOnly />
        </ResizableDrawer>
    );
};
