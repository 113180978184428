import { FC, Fragment, useEffect } from "react";
import { Form, Button, Col, Row, Space, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTargetingForm } from "../useTargetingForm";
import { AddExistingTargetingButton } from "./AddExistingTargetingButton";
import { CreateTargetingBlock } from "./CreateTargetingBlock";
import { ReusableTargetingModal } from "./ReusableTargetingModal";
import { ExistingTargetingBlock } from "./ExistingTargetingBlock";
import { Operations, TargetingDimensionHelpKeys, TargetingFormKeys } from "../constants";
import { HelpTooltip } from "@app/core/components";
import { ReadOnlyOperation } from "@app/features/targeting/ReadOnlyTargeting/ReadOnlyOperation";
import {
    getHasMixedAudienceAndSegmentTargeting,
    getAudienceTargetingCount,
    targetingToTargetingBlocks,
} from "../helpers";
import { TargetingMode } from "./TargetingMode";

interface Props {
    formKey: TargetingFormKeys;
    validationFormKey?: TargetingFormKeys;
    operation?: Operations;
    operationReadOnly?: boolean;
}

export const CreateTargeting: FC<Props> = ({ formKey, validationFormKey, operation, operationReadOnly }) => {
    const {
        addExistingTargetingBlock,
        addTargetingBlock,
        addTargetingBlockDimension,
        addTargetingBlockDimensionRule,
        addTargetingBlockDimensionRuleGroup,
        copyTargetingBlock,
        copyTargetingBlockDimension,
        deleteTargetingBlock,
        deleteTargetingBlockDimension,
        deleteTargetingBlockDimensionRule,
        downloadTargetingBlockDimension,
        replaceTargetingBlock,
        setTargetingBlockDimensionCondition,
        setTargetingBlockDimensionMode,
        setTargetingBlockDimensionOperator,
        setTargetingBlockDimensionPmpCondition,
        setTargetingBlockDimensionRuleCondition,
        setTargetingBlockDimensionRuleGroup,
        setTargetingBlockDimensionRuleId,
        setTargetingBlockDimensionRuleOperator,
        setTargetingBlockDimensionRuleValue,
        setTargetingBlockDimensionTimeZoneMode,
        setTargetingBlockDimensionValues,
        setTargetingBlockIsReusable,
        setTargetingBlockIsReusableModalOpen,
        setTargetingOperation,
        targetingFormsByFormKey,
        targetingPayloadsByFormKey,
    } = useTargetingForm(formKey);

    useEffect(() => {
        if (operation) {
            setTargetingOperation(formKey, operation);
        }
    }, [formKey, operation, setTargetingOperation]);

    const targetingForm = targetingFormsByFormKey[formKey];
    const validationTargetingForm = validationFormKey ? targetingFormsByFormKey[validationFormKey] : null;
    const targetingPayloads = targetingPayloadsByFormKey[formKey];
    const targetingBlocks = targetingToTargetingBlocks(targetingForm.targetingBlocks);
    const validationTargetingBlocks = targetingToTargetingBlocks(targetingForm.validationTargetingBlocks);

    const additionalValidationTargetingBlocks = validationTargetingForm
        ? targetingToTargetingBlocks(validationTargetingForm.targetingBlocks)
        : [];

    const hasMixedAudienceAndSegmentTargeting = getHasMixedAudienceAndSegmentTargeting(
        targetingBlocks,
        validationTargetingBlocks,
        additionalValidationTargetingBlocks
    );
    const audienceTargetingCount = getAudienceTargetingCount(
        targetingBlocks,
        validationTargetingBlocks,
        additionalValidationTargetingBlocks
    );
    const hasMultipleAudienceTargeting = audienceTargetingCount > 1;

    return (
        <Space direction="vertical" size="middle" style={{ width: "100%" }} data-sdet="create-targeting">
            <Typography.Text>
                <Typography.Paragraph style={{ marginBottom: 0 }}>
                    If no targeting is applied, All/Any is the default unless targeting is specified.
                    <Typography.Text strong> Exception:</Typography.Text> Inbound PMP targeting is defaulted to{" "}
                    <Typography.Text strong italic>
                        exclude
                    </Typography.Text>{" "}
                    all deals unless specified
                </Typography.Paragraph>
                <Typography.Paragraph style={{ marginBottom: 0 }}>
                    There is a max limit of 1,000 postal codes for entire targeting rule.
                </Typography.Paragraph>
            </Typography.Text>
            <Form.Item
                name="targetingValidationMixedAudienceSegment"
                style={{ marginBottom: 0 }}
                noStyle={!hasMixedAudienceAndSegmentTargeting}
                rules={[
                    {
                        validator: () => {
                            return new Promise((resolve, reject) => {
                                if (hasMixedAudienceAndSegmentTargeting) {
                                    return reject();
                                }
                                return resolve(true);
                            });
                        },
                        message:
                            "You cannot mix DMP Segment Targeting and Audience targeting across multiple targeting blocks",
                    },
                ]}
            />
            <Form.Item
                name="targetingValidationMultipleAudience"
                style={{ marginBottom: 0 }}
                noStyle={!hasMultipleAudienceTargeting}
                rules={[
                    {
                        validator: () => {
                            return new Promise((resolve, reject) => {
                                if (hasMultipleAudienceTargeting) {
                                    return reject();
                                }
                                return resolve(true);
                            });
                        },
                        message:
                            "You cannot target multiple Audiences across multiple targeting blocks. Please ensure your Targeting and Additional Targeting only contain a single Audience.",
                    },
                ]}
            />
            {targetingForm.targetingBlocks.length > 0 && (
                <TargetingMode
                    formKey={formKey}
                    setTargetingOperation={setTargetingOperation}
                    targetingForm={targetingForm}
                    operationReadOnly={operationReadOnly}
                />
            )}
            {targetingForm.targetingBlocks.map((targetingBlock, i) => (
                <Fragment key={`create-targeting-block-operation-${i}`}>
                    <Row key={`create-targeting-block-${i}`}>
                        <Col xs={24}>
                            {"isReusable" in targetingBlock ? (
                                <>
                                    <CreateTargetingBlock
                                        formKey={formKey}
                                        index={i}
                                        onChangeDimensionCondition={setTargetingBlockDimensionCondition}
                                        onChangeDimensionMode={setTargetingBlockDimensionMode}
                                        onChangeDimensionOperator={setTargetingBlockDimensionOperator}
                                        onChangeDimensionPmpCondition={setTargetingBlockDimensionPmpCondition}
                                        onChangeDimensionRuleCondition={setTargetingBlockDimensionRuleCondition}
                                        onChangeDimensionRuleGroup={setTargetingBlockDimensionRuleGroup}
                                        onChangeDimensionRuleId={setTargetingBlockDimensionRuleId}
                                        onChangeDimensionRuleOperator={setTargetingBlockDimensionRuleOperator}
                                        onChangeDimensionRuleValue={setTargetingBlockDimensionRuleValue}
                                        onChangeDimensionTimeZoneMode={setTargetingBlockDimensionTimeZoneMode}
                                        onChangeDimensionValues={setTargetingBlockDimensionValues}
                                        onChangeIsReusable={setTargetingBlockIsReusable}
                                        onClickAddDimension={addTargetingBlockDimension}
                                        onClickAddDimensionRule={addTargetingBlockDimensionRule}
                                        onClickAddDimensionRuleGroup={addTargetingBlockDimensionRuleGroup}
                                        onClickCopy={copyTargetingBlock}
                                        onClickDelete={deleteTargetingBlock}
                                        onClickDeleteDimension={deleteTargetingBlockDimension}
                                        onClickDeleteDimensionRule={deleteTargetingBlockDimensionRule}
                                        onClickDownload={downloadTargetingBlockDimension}
                                        onClickDuplicateDimension={copyTargetingBlockDimension}
                                        targetingBlock={targetingBlock}
                                        targetingBlocks={targetingBlocks}
                                        validationTargetingBlocks={validationTargetingBlocks.concat(
                                            additionalValidationTargetingBlocks
                                        )}
                                    />
                                    <ReusableTargetingModal
                                        targetingBlock={targetingBlock}
                                        targetingBlockIndex={i}
                                        setTargetingBlockIsReusable={(i, isReusable) =>
                                            setTargetingBlockIsReusable(formKey, i, isReusable)
                                        }
                                        setTargetingBlockIsReusableModalOpen={(i, isOpen) =>
                                            setTargetingBlockIsReusableModalOpen(formKey, i, isOpen)
                                        }
                                        targetingPayload={targetingPayloads[i]}
                                        replaceTargetingBlock={(i, targeting) =>
                                            replaceTargetingBlock(formKey, i, targeting)
                                        }
                                    />
                                </>
                            ) : (
                                <ExistingTargetingBlock
                                    index={i}
                                    targetingBlock={targetingBlock}
                                    replaceTargetingBlock={(i, targetingBlock) =>
                                        replaceTargetingBlock(formKey, i, targetingBlock)
                                    }
                                    onClickCopy={() => copyTargetingBlock(formKey, i)}
                                    onClickDelete={() => deleteTargetingBlock(formKey, i)}
                                />
                            )}
                        </Col>
                    </Row>
                    {i !== targetingForm.targetingBlocks.length - 1 && (
                        <Row key={`operation-select-${i}`}>
                            <Col xs={24}>
                                <HelpTooltip
                                    helpKeyList={TargetingDimensionHelpKeys}
                                    helpKey={TargetingDimensionHelpKeys.TargetingDealsWithTargeting}
                                    tooltipPlacement={"topRight"}
                                    popover={true}
                                >
                                    {/*Below empty tag required to show tooltip*/}
                                    <>
                                        <ReadOnlyOperation operation={operation || targetingForm.targetingOperation} />
                                    </>
                                </HelpTooltip>
                            </Col>
                        </Row>
                    )}
                </Fragment>
            ))}
            <Row>
                <Col>
                    <Button
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={() => addTargetingBlock(formKey)}
                        data-sdet="add-new-targeting-block"
                    >
                        Add New Targeting Group
                    </Button>
                </Col>
                <Col>
                    <AddExistingTargetingButton
                        addExistingTargetingBlock={(targeting) => addExistingTargetingBlock(formKey, targeting)}
                        selectedIds={targetingForm.targetingBlocks
                            .filter((v) => "id" in v && v.id !== null)
                            .map((v) => Number(v.id))}
                    />
                </Col>
            </Row>
        </Space>
    );
};
