import { FC } from "react";
import { useForgotPasswordForm } from "@app/features/login/Steps/hooks/useForgotPasswordForm";
import { Button, Col, Form, Input, Row, Typography } from "antd";
import { isValidEmail } from "@rubicon/utils";
import { green7 } from "@rubicon/antd-components";
import { ROUTES } from "@app/core/routing";
import { Link } from "react-router-dom";
import { css } from "@emotion/css";
import { UseLoginFormData } from "../LoginForm/useLoginFormData";

const ForgotPassword: FC<UseLoginFormData> = (loginFormData) => {
    const { formData } = loginFormData;
    const { onFocus, handleSubmit, handleFormChange, form, EMAIL_FORM_FIELDS, isLoading, notification } =
        useForgotPasswordForm(loginFormData);

    return (
        <Form
            form={form}
            onValuesChange={handleFormChange}
            onFinish={handleSubmit}
            initialValues={formData}
            validateTrigger="onBlur"
            layout="vertical"
        >
            <Row>
                <Col xs={24}>
                    <Form.Item
                        label="Forgot Password?"
                        help={
                            notification && (
                                <Typography.Paragraph style={{ marginTop: "0.625rem", color: green7 }}>
                                    {notification}
                                </Typography.Paragraph>
                            )
                        }
                        name={EMAIL_FORM_FIELDS.Email}
                        rules={[
                            {
                                required: true,
                                message: "Please enter your email",
                            },
                            {
                                validator(_, value) {
                                    if (!value || isValidEmail.isValid(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error("Invalid email address"));
                                },
                            },
                        ]}
                        className={css`
                            .ant-form-item-label > label {
                                font-size: 1rem;
                            }
                            .ant-form-item-control-input {
                                min-height: 42px;
                            }
                            .ant-input {
                                min-height: 42px;
                            }
                        `}
                    >
                        <Input
                            onFocus={onFocus}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            style={notification && { borderColor: green7 }}
                            disabled={isLoading}
                            placeholder="Enter your email address for reset instructions"
                            type={EMAIL_FORM_FIELDS.Email}
                            autoFocus
                            data-sdet="email"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[0, 10]} justify="end">
                <Col xs={24}>
                    <Button
                        disabled={isLoading}
                        htmlType="submit"
                        data-sdet="reset-password"
                        block
                        type="primary"
                        className={css`
                            height: 42px;
                            margin-top: 32px;
                        `}
                    >
                        Reset Password
                    </Button>
                </Col>
            </Row>
            <Row justify="space-between" align="bottom" style={{ marginTop: "1.5rem" }}>
                <Col>
                    <Typography.Link href="https://www.magnite.com/contact-us" target="_blank">
                        Don&#39;t have an account?
                    </Typography.Link>
                </Col>
                <Col style={{ textAlign: "right" }} data-sdet="back-to-login">
                    <Link to={ROUTES.LOGIN_FORM_EMAIL_STEP}>Back to log in</Link>
                </Col>
            </Row>
        </Form>
    );
};

export default ForgotPassword;
