import { FC } from "react";
import { Card, Col, Collapse, Form, Row } from "antd";
import { FloorNameField, FloorPriceField } from "../FloorFormFields";
import { useFloorAdvancedForm } from "./useFloorAdvancedForm";
import { AdvancedFloor, Floor, Targeting } from "@app/core/services";
import { AffixFormButtonsBar } from "@app/features/inventory/components/AffixFormButtonsBar";
import { COL_SIZES, GUTTER } from "@app/features/inventory/constants";
import { CreateTargeting } from "@app/features/targeting";
import { TargetingFormKeys } from "@app/features/targeting/constants";
import { SectionTitle } from "@app/core/components";
import { ADVANCED_FLOOR_SECTION_NAMES } from "../SeatFloorsAdvancedSwtich/SeatFloorsAdvancedDetails/constants";
import { classNameCardBody } from "../../InventoryHealthDetailsPages/constants";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import { ADVANCED_FLOOR_SECTION_HELP_KEYS } from "../SeatFloorsAdvancedSwtich/SeatFloorsAdvancedDefinition/helpkeys";

interface Props {
    mode: "create" | "edit";
    floor?: Floor | AdvancedFloor;
    targeting?: Targeting[];
}

export const FloorAdvancedForm: FC<Props> = ({ mode, floor }) => {
    const { form, handleCancel, handleSubmit, isLoading, initialValues } = useFloorAdvancedForm(mode, floor);

    return (
        <Form
            layout="vertical"
            form={form}
            initialValues={initialValues}
            scrollToFirstError={{ behavior: "smooth", block: "center" }}
        >
            <Collapse defaultActiveKey={[0, 1]} bordered collapsible="header" style={{ margin: "-24px -24px 16px" }}>
                <Collapse.Panel
                    header={<SectionTitle title={ADVANCED_FLOOR_SECTION_NAMES.GENERAL} />}
                    key="0"
                    extra={
                        <HelpKeysDrawer
                            panelLabel={ADVANCED_FLOOR_SECTION_NAMES.GENERAL}
                            definitionsFields={ADVANCED_FLOOR_SECTION_HELP_KEYS[ADVANCED_FLOOR_SECTION_NAMES.GENERAL]}
                        />
                    }
                >
                    <Card bordered={false} className={classNameCardBody}>
                        <Row gutter={GUTTER}>
                            <Col {...COL_SIZES}>
                                <FloorNameField />
                            </Col>
                        </Row>
                        <Row gutter={GUTTER}>
                            <Col {...COL_SIZES}>
                                <FloorPriceField />
                            </Col>
                        </Row>
                    </Card>
                </Collapse.Panel>
                <Collapse.Panel
                    header={<SectionTitle title="Targeting" />}
                    key="1"
                    extra={
                        <HelpKeysDrawer
                            panelLabel={ADVANCED_FLOOR_SECTION_NAMES.TARGETING}
                            definitionsFields={ADVANCED_FLOOR_SECTION_HELP_KEYS[ADVANCED_FLOOR_SECTION_NAMES.TARGETING]}
                        />
                    }
                >
                    <Card bordered={false} className={classNameCardBody}>
                        <CreateTargeting formKey={TargetingFormKeys.InventoryAdvancedFloors} />
                    </Card>
                </Collapse.Panel>
            </Collapse>

            <AffixFormButtonsBar
                loading={isLoading}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                disabled={isLoading}
                submitButtonTitle="Save"
            />
        </Form>
    );
};
