import { DualAxesOptions } from "@antv/g2plot";
import { formatNumber } from "@rubicon/utils";
import { HEIGHT, LEGEND, Y_AXIS_MIN, Y_AXIS_TICK_COUNT } from "../utils";
import { DualAxesChartOptions } from "./types";

export const RequiredFields = {
    xField: "xValue",
    yAxisA: "yAxisA",
    yAxisB: "yAxisB",
    seriesA: "seriesA",
    seriesB: "seriesB",
} as const;

export const RequiredOptions: Pick<DualAxesOptions, "data" | "xField" | "yField" | "geometryOptions"> = {
    data: [[], []],
    xField: RequiredFields.xField,
    yField: [RequiredFields.yAxisA, RequiredFields.yAxisB],
    geometryOptions: [
        {
            seriesField: RequiredFields.seriesA,
        },
        {
            seriesField: RequiredFields.seriesB,
        },
    ],
};

export const DefaultOptions: DualAxesChartOptions = {
    legend: LEGEND,
    height: HEIGHT,
    yAxis: {
        yAxisA: {
            min: Y_AXIS_MIN,
            tickCount: Y_AXIS_TICK_COUNT,
            label: {
                formatter: formatNumber.asAbbreviated,
            },
        },
        yAxisB: {
            top: true,
            min: Y_AXIS_MIN,
            tickCount: Y_AXIS_TICK_COUNT,
            title: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                rotate: Math.PI / 2,
            },
        },
    },
} as const;
