import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { GrantingUser } from "../clients/console";
import { ROUTES } from "../routing";
import { User } from "../services";
import { useAppSelector } from "../store";
import {
    selectAuthClientSession,
    deriveAuthClientIsLoggedIn,
    selectAuthClientIsLoggingIn,
    deriveAuthClientIsRunningAsOther,
    selectAuthClientIsLoggingOut,
    selectAuthClientIsLoggedOutExplicitly,
    deriveAuthClientIsLoggedOut,
    selectAuthClientIsFetchingSession,
    AuthClientState,
    deriveAuthClientHasFetchedSession,
    deriveAuthClientIsRunningAsSelf,
    selectIsLoggedInViaAuth0,
} from "./reducer";
import { AuthRequest, Authenticator } from "./AuthClient";
import { useAuthenticatorInstance } from "./useAuthenticatorInstance";

interface UseAuthClient extends AuthClientState, Authenticator {
    hasFetchedSession: boolean;
    isLoggedIn: boolean;
    isLoggedInViaAuth0: boolean;
    isLoggedOut: boolean;
    isRunningAsOther: boolean;
    isRunningAsSelf: boolean;
    logout: () => AuthRequest;
    runAsSelf: () => AuthRequest;
}

export const useAuthClient = (): UseAuthClient => {
    const { authenticatorInstance } = useAuthenticatorInstance();
    const auth0 = useAuth0();

    const session = useAppSelector(selectAuthClientSession);
    const isFetchingSession = useAppSelector(selectAuthClientIsFetchingSession);
    const isLoggingIn = useAppSelector(selectAuthClientIsLoggingIn);
    const isLoggedInViaAuth0 = useAppSelector(selectIsLoggedInViaAuth0);
    const isLoggingOut = useAppSelector(selectAuthClientIsLoggingOut);
    const isLoggedOutExplicitly = useAppSelector(selectAuthClientIsLoggedOutExplicitly);

    const hasFetchedSession = useAppSelector(deriveAuthClientHasFetchedSession);
    const isLoggedIn = useAppSelector(deriveAuthClientIsLoggedIn);
    const isLoggedOut = useAppSelector(deriveAuthClientIsLoggedOut);
    const isRunningAsOther = useAppSelector(deriveAuthClientIsRunningAsOther);
    const isRunningAsSelf = useAppSelector(deriveAuthClientIsRunningAsSelf);

    const history = useHistory();

    const logout = useCallback(() => authenticatorInstance.logout(auth0), [authenticatorInstance, auth0]);

    const runAsUser = useCallback(
        async (user: User | GrantingUser, sessionCode: string): AuthRequest => {
            await authenticatorInstance.runAsUser(user, sessionCode);
            history.push({
                pathname: ROUTES.LOGIN_FORM_CONTEXT_STEP,
                search: "?runAsRefresh",
            });
        },
        [history, authenticatorInstance]
    );

    const runAsSelf = useCallback(async (): AuthRequest => {
        if (!session?.grantingUser && !session?.sessionCode) {
            return Promise.reject({ message: "Run as Self Error: no granting user" });
        }
        await authenticatorInstance.runAsUser(session.grantingUser, session.sessionCode);
        window.location.reload();
    }, [session, authenticatorInstance]);

    return {
        session,
        isFetchingSession,
        isLoggingIn,
        isLoggedInViaAuth0,
        isLoggingOut,
        isLoggedOutExplicitly,
        hasFetchedSession,
        isLoggedIn,
        isLoggedOut,
        isRunningAsOther,
        isRunningAsSelf,
        fetchSession: authenticatorInstance.fetchSession,
        login: authenticatorInstance.login,
        logout,
        runAsUser,
        runAsSelf,
    };
};
