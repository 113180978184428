import { CSSProperties, FC } from "react";
import { Affix, Button, Card, Space } from "antd";

interface AffixFormButtonsBarProps {
    loading: boolean;
    disabled?: boolean;
    isDeleting?: boolean;
    onSubmit: () => void;
    onCancel: () => void;
    onDelete?: () => void;
    submitButtonTitle?: string;
    isDeleteButtonShown?: boolean;
    affixStyle?: CSSProperties;
}

export const AffixFormButtonsBar: FC<AffixFormButtonsBarProps> = ({
    loading,
    onSubmit,
    onCancel,
    disabled,
    onDelete,
    isDeleting,
    affixStyle = { margin: "1rem -1.5rem" },
    submitButtonTitle = "Submit",
    isDeleteButtonShown = false,
}) => {
    return (
        <Affix offsetBottom={46} style={affixStyle}>
            <Card bodyStyle={{ padding: "0.75rem 1rem" }} style={{ marginTop: "3rem" }}>
                <Space>
                    <Button loading={loading} disabled={isDeleting} type="primary" onClick={onSubmit}>
                        {submitButtonTitle}
                    </Button>
                    <Button onClick={onCancel} disabled={disabled || isDeleting}>
                        Cancel
                    </Button>
                    {isDeleteButtonShown && (
                        <Button type="primary" danger loading={isDeleting} onClick={onDelete}>
                            Delete
                        </Button>
                    )}
                </Space>
            </Card>
        </Affix>
    );
};
