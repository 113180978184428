import { LabeledValue } from "antd/lib/select";
import { useGetCostModelsQuery, useGetSeatFinanceDefaultsQuery } from "@app/core/services";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { getDefaultCostModel } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/getDefaultCostModel";
import { useParams } from "react-router-dom";

interface UseAdSourceCostModelField {
    options: LabeledValue[];
    isFetching: boolean;
    onChange: (value: number) => void;
    value: number | null;
    defaultCostModel: string | null;
}

export const useAdSourceCostModelField = (): UseAdSourceCostModelField => {
    const dispatch = useAppDispatch();
    const { seatId } = useParams<{ seatId: string }>();
    const { data, isFetching } = useGetCostModelsQuery();
    const { data: financeDefaults } = useGetSeatFinanceDefaultsQuery(Number(seatId));

    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_COST_MODEL]);
    const dealType = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TYPE]);
    const defaultCostModel = getDefaultCostModel(dealType, financeDefaults);

    const options = useMemo(() => (data || []).map((item) => ({ label: item.name, value: item.id })), [data]);

    const onChange = (value: number): void => {
        dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_COST_MODEL, value }));
    };

    return {
        options,
        isFetching,
        value,
        onChange,
        defaultCostModel: defaultCostModel?.costModel.name || null,
    };
};
