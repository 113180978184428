import { FC } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import type { TabsProps } from "antd";
import { Tabs } from "antd";
import { ROUTES, ROUTE_FORMATTERS } from "@app/core/routing";
import InventoryFloorHierarchicalCreatePage from "./InventoryFloorHierarchicalCreatePage";
import InventoryFloorAdvancedCreatePage from "./InventoryFloorAdvancedCreatePage";
import InventoryFloorHierarchicalEditPage from "./InventoryFloorHierarchicalEditPage";
import InventoryFloorsPage from "./InventoryFloorsPage";
import InventoryFloorsToolbar from "./InventoryFloorsToolbar";
import { InventoryFloorsAdvancedEditPage } from "./InventoryFloorsAdvancedEditPage";

export const INVENTORY_FLOORS_HIERARCHICAL = "hierarchical";
export const INVENTORY_FLOORS_ADVANCED = "advanced";

const INVENTORY_FLOORS_TABS: TabsProps["items"] = [
    { key: INVENTORY_FLOORS_HIERARCHICAL, label: "Hierarchical Floors" },
    {
        key: INVENTORY_FLOORS_ADVANCED,
        label: "Advanced Floors",
    },
];

export const InventoryFloorsSwitch: FC = () => {
    const history = useHistory();
    const { tab, seatId } = useParams<{ tab: string; seatId: string }>();

    return (
        <Switch>
            <Route
                exact
                path={[
                    ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL_CREATE,
                    ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL_PUBLISHER_FLOOR_CREATE,
                    ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL_BRAND_FLOOR_CREATE,
                    ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL_SUPPLY_FLOOR_CREATE,
                    ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL_AD_UNIT_FLOOR_CREATE,
                ]}
            >
                <InventoryFloorHierarchicalCreatePage />
            </Route>
            <Route
                exact
                path={[
                    ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL_EDIT,
                    ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL_PUBLISHER_FLOOR_EDIT,
                    ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL_BRAND_FLOOR_EDIT,
                ]}
            >
                <InventoryFloorHierarchicalEditPage />
            </Route>
            <Route exact path={[ROUTES.SEAT_INVENTORY_FLOOR_ADVANCED_CREATE]}>
                <InventoryFloorAdvancedCreatePage />
            </Route>
            <Route
                exact
                path={[ROUTES.SEAT_INVENTORY_FLOOR_ADVANCED_EDIT, ROUTES.SEAT_INVENTORY_FLOOR_ADVANCED_EDIT_DEFINITION]}
            >
                <InventoryFloorsAdvancedEditPage />
            </Route>
            <Route>
                <InventoryFloorsToolbar
                    footer={
                        <Tabs
                            size="large"
                            tabBarStyle={{ marginBottom: 0 }}
                            activeKey={tab}
                            defaultActiveKey={tab}
                            items={INVENTORY_FLOORS_TABS}
                            onChange={(tab) =>
                                history.push(
                                    ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_TABS(
                                        seatId,
                                        tab as typeof INVENTORY_FLOORS_HIERARCHICAL | typeof INVENTORY_FLOORS_ADVANCED
                                    )
                                )
                            }
                        />
                    }
                />
                <Switch>
                    <Route path={ROUTES.SEAT_INVENTORY_FLOOR}>
                        <InventoryFloorsPage />
                    </Route>
                </Switch>
            </Route>
        </Switch>
    );
};
