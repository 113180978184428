import { TagRender } from "@app/core/components";
import { useNotificationError } from "@app/core/components/hooks";
import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { QueryDefinition } from "@reduxjs/toolkit/query";
import { Select } from "antd";
import { LabeledValue } from "antd/es/select";
import { ReactNode, useMemo } from "react";

interface Props<T = unknown> {
    allowClear?: boolean;
    mode?: "multiple";
    sdet: string;
    value?: number[];
    args?: unknown[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useGetOptionsQuery: UseQuery<QueryDefinition<any, any, any, any>>;
    optionMapper: (item: T) => LabeledValue;
    placeholder?: string;
    unspecifiedOptions?: LabeledValue[];
    disabled?: boolean;
    children?: ReactNode;
}
export const GenericSelectField = <T,>({
    allowClear = true,
    sdet,
    mode,
    args = [],
    useGetOptionsQuery,
    optionMapper,
    unspecifiedOptions = [],
    value,
    placeholder = "Select…",
    ...restProps
}: Props<T>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { data, isFetching, error } = useGetOptionsQuery(...args);
    useNotificationError(error);

    const options = useMemo(
        () => (data ? unspecifiedOptions.concat(data.map(optionMapper)) : []),
        [data, optionMapper, unspecifiedOptions]
    );

    return (
        <Select
            showSearch
            allowClear={allowClear}
            data-sdet={sdet}
            popupMatchSelectWidth={false} // dropdown options will be auto wider than input
            loading={isFetching}
            mode={mode}
            options={options}
            optionFilterProp="label"
            autoClearSearchValue={mode !== "multiple"}
            placeholder={placeholder}
            tagRender={TagRender}
            value={value}
            {...restProps}
        />
    );
};
