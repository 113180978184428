import { FC } from "react";
import { merge } from "lodash";
import { Legend, useLegend } from "../Legend";
import { ColumnPlotConfig, Plot, PlotTypes } from "../Plot";
import { mapXYSeriesDataToPlotData } from "../utils/utils";
import { DefaultOptions, RequiredFields, RequiredOptions } from "./constants";
import { ColumnChartProps } from "./types";

export const ColumnChart: FC<ColumnChartProps> = ({ id, chartOptions, data = [], legendOptions }) => {
    const { seriesFilters, requiredLegendProps } = useLegend([data]);

    const columnPlotConfg: ColumnPlotConfig = {
        type: PlotTypes.Column,
        options: merge({}, DefaultOptions, chartOptions, RequiredOptions),
        data: mapXYSeriesDataToPlotData(data, RequiredFields.yField, RequiredFields.seriesField, seriesFilters),
    };

    return (
        <div data-sdet="column-chart">
            <Plot id={id} plotConfig={columnPlotConfg} />
            <Legend {...requiredLegendProps} legendOptions={legendOptions} />
        </div>
    );
};
