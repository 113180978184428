import { useAppDispatch, useAppSelector } from "@app/core/store";
import { LabeledValue } from "antd/lib/select";
import { setFiltersDealHealthPriority } from "@app/features/deals/DealHealthPage/reducer";
import { useGetAdSourcePrioritiesQuery } from "@app/core/services/console/adSources";
import { useMemo } from "react";

interface UseDealHealthPriorityFilter {
    handleChange: (value: string | number | undefined, option: LabeledValue | LabeledValue[]) => void;
    options: LabeledValue[];
    value: LabeledValue[] | string | null;
    isLoading: boolean;
    areAllOptionsSelected: boolean;
    toggleSelectDeselectAll: () => void;
}

export const useDealHealthPriorityFilter = (): UseDealHealthPriorityFilter => {
    const dispatch = useAppDispatch();
    const filterPriorities = useAppSelector((state) => state.dealHealth.filters.filterPriorities);
    const { data, isFetching } = useGetAdSourcePrioritiesQuery();
    const options = useMemo(() => (data || []).map((filter) => ({ label: filter.name, value: filter.id })), [data]);

    const handleChange = (value: string | number | undefined, option: LabeledValue[]) => {
        dispatch(setFiltersDealHealthPriority(option));
    };

    const areAllOptionsSelected = options.length === filterPriorities?.length;

    const toggleSelectDeselectAll = () => {
        if (areAllOptionsSelected) {
            handleChange(undefined, []);
        } else {
            handleChange(undefined, options);
        }
    };

    return {
        handleChange,
        options: options,
        value: filterPriorities,
        isLoading: isFetching,
        areAllOptionsSelected,
        toggleSelectDeselectAll,
    };
};
