export const PRE_FILTER_MODE_OPTIONS = {
    MATCH_INVENTORY_LABEL: "MATCH_INVENTORY_LABEL",
    ALL_INVENTORY: "ALL_INVENTORY",
} as const;

export const PRE_FILTER_MODE_OPTIONS_NAME = {
    [PRE_FILTER_MODE_OPTIONS.MATCH_INVENTORY_LABEL]: "Match Inventory Label(s)",
    [PRE_FILTER_MODE_OPTIONS.ALL_INVENTORY]: "All Inventory",
} as const;

export const PRE_FILTER_MODE_OPTIONS_DESCRIPTION = {
    [PRE_FILTER_MODE_OPTIONS.MATCH_INVENTORY_LABEL]:
        "Match Inventory Label(s): The Ad Source is only eligible to serve when the supply explicitly nominates the Waterfall Pre-Filter label(s) in question.",
    [PRE_FILTER_MODE_OPTIONS.ALL_INVENTORY]:
        "All Inventory: An Ad Source with a Waterfall Pre-Filter label can consume any supply implied by its targeting.",
};

export const PRE_FILTER_MODE_OPTIONS_VALUES = {
    [PRE_FILTER_MODE_OPTIONS.ALL_INVENTORY]: false,
    [PRE_FILTER_MODE_OPTIONS.MATCH_INVENTORY_LABEL]: true,
};
