import { Form, Radio, Space, Typography } from "antd";
import { css } from "@emotion/css";
import { useAdSourceWaterfall } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceLabelsSection/Fields/AdSourceWaterfall/useAdSourceWaterfall";
import { gray7 } from "@rubicon/antd-components";

const className = css`
    color: ${gray7};
`;

export const AdSourceWaterfall = () => {
    const { strictMode, options, handleChangeMode } = useAdSourceWaterfall();

    return (
        <Form.Item label="Waterfall Pre-Filter Mode" data-sdet="waterfall-pre-filter-mode">
            <Space size="small" direction="vertical">
                <Radio.Group value={strictMode} onChange={(e) => handleChangeMode(e.target.value)} options={options} />
                {strictMode ? (
                    <Typography.Text className={className}>
                        Match Inventory Label(s): The Ad Source is only eligible to serve when the supply explicitly
                        nominates the Waterfall Pre-Filter label(s) in question.
                    </Typography.Text>
                ) : (
                    <Typography.Text className={className}>
                        * All Inventory: An Ad Source with a Waterfall Pre-Filter label can consume any supply implied
                        by its targeting.
                    </Typography.Text>
                )}
            </Space>
        </Form.Item>
    );
};
