import {
    StaticAdStats,
    StaticAdStatsParams,
    useGetStaticAdStatsQuery,
} from "@app/core/services/adSourceStats/staticAdStats";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useEffect } from "react";
import { reset, setAllSeatAdSourcesDealOptions, setFiltersSeatAdSourcesDeal } from "./reducer";
import { LabeledValue } from "antd/lib/select";

interface UseSeatAdSourcesPerformanceAdBidStats {
    handleChangeValue: (value: LabeledValue | undefined, option: LabeledValue) => void;
    dealFilterOptions: LabeledValue[] | undefined;
    dealFilterValue: LabeledValue | undefined;
    adBidStatsTableData: BidderStatsTableRow[];
    adStatsTableData: AdStatsTable[];
    isFetching: boolean;
}

interface BidderStatsTableRow {
    dsp: string;
    auctionCount: number;
    averageBidPrice: number | string;
    noBid: number;
    belowFloor: number;
    losses: number;
    wins: number;
    dealMismatch: number;
    timeouts: number;
    belowDeal: number;
    aboveDeal: number;
    throttled: number;
    errors: number;
}

interface AdStatsTable {
    dealName: string;
    dsp: string;
    requestsFilled: number;
    impressions: number;
    revenue: number;
    cost: number;
    aDomainCount: number;
    auctionCount: number;
}

export type BidderStatsColumnKey = keyof BidderStatsTableRow;

export type MappedBidResultCodes = Extract<
    BidderStatsColumnKey,
    "noBid" | "losses" | "wins" | "belowDeal" | "aboveDeal" | "belowFloor" | "dealMismatch" | "timeouts" | "throttled"
>;

const BidResultMap: Record<MappedBidResultCodes, number[]> = {
    noBid: [1],
    losses: [2, 15],
    wins: [3],
    belowDeal: [13],
    aboveDeal: [14],
    belowFloor: [17],
    dealMismatch: [207],
    timeouts: [6, 8, 20, 24, 25, 26],
    throttled: [16, 22, 23, 30, 320],
};

export const useAdBidStats = (adSourceId: number): UseSeatAdSourcesPerformanceAdBidStats => {
    const dispatch = useAppDispatch();
    const dealFilterValue = useAppSelector(
        (state) => state.seatAdSourcesPerformanceAdBidStats.filters.seatAdSourcesDeal
    );
    const StaticAdStatsParams: StaticAdStatsParams = {
        adSourceId: Number(adSourceId),
    };
    const { data, isFetching } = useGetStaticAdStatsQuery(StaticAdStatsParams);
    const dealFilterOptions = useAppSelector((state) => state.seatAdSourcesPerformanceAdBidStats.filters.options);
    const dealStats = data?.dealStatMap?.stats;

    useEffect(() => {
        if (dealStats) {
            const dealNames = Object.keys(dealStats);
            const options = dealNames.map((dealName, index) => ({ label: dealName, value: index }));
            dispatch(setAllSeatAdSourcesDealOptions(options));
        }
    }, [dealStats, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(reset());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeValue = (_, option: LabeledValue) => dispatch(setFiltersSeatAdSourcesDeal(option));

    const mapAdStatsTableData = (data: StaticAdStats): AdStatsTable[] => {
        if (!data?.dealStatMap || !dealFilterValue?.label) {
            return [];
        }
        const dealName = dealFilterValue?.label as string;
        const deal = data?.dealStatMap?.stats[dealName];
        return Object.keys(deal?.adStatsByNetwork?.stats || {}).map((dspName) => {
            const dsp = deal?.adStatsByNetwork?.stats[dspName];
            const dspBidderStats = deal?.bidderStatsByNetwork?.stats[dspName];
            return {
                dealName: dealName,
                dsp: dspName,
                requestsFilled: dsp?.filledRequests || 0,
                impressions: dsp?.impressions || 0,
                revenue: dsp?.rev || 0,
                cost: dsp?.cost || 0,
                aDomainCount: Object.keys(dsp?.advertiserDomains || {}).length,
                auctionCount: dspBidderStats?.auctionCount ?? 0,
            };
        });
    };

    const mapBidStatsData = (data: StaticAdStats): BidderStatsTableRow[] => {
        if (!data?.dealStatMap || !dealFilterValue?.label) {
            return [];
        }
        const dealName = dealFilterValue?.label as string;
        const deal = data?.dealStatMap?.stats[dealName];
        return Object.keys(deal?.bidderStatsByNetwork?.stats || {}).map((dsp) => {
            const {
                bidResults = {},
                sumBidPriceMicros = 0,
                auctionCount = 0,
            } = deal?.bidderStatsByNetwork?.stats[dsp] || {};
            const sumBidResults = (codes: number[]): number =>
                codes.reduce((sum, code) => sum + (bidResults?.[code] ?? 0), 0);

            const losses = sumBidResults(BidResultMap.losses);
            const wins = sumBidResults(BidResultMap.wins);
            const belowFloor = sumBidResults(BidResultMap.belowFloor);
            const belowDeal = sumBidResults(BidResultMap.belowDeal);
            const aboveDeal = sumBidResults(BidResultMap.aboveDeal);
            const numBids = losses + wins + belowFloor + belowDeal + aboveDeal;
            const sumBidPrice = sumBidPriceMicros / 1000;
            const averageBidPrice = numBids === 0 ? 0 : sumBidPrice / numBids;
            const noBid = sumBidResults(BidResultMap.noBid);
            const dealMismatch = sumBidResults(BidResultMap.dealMismatch);
            const timeouts = sumBidResults(BidResultMap.timeouts);
            const throttled = sumBidResults(BidResultMap.throttled);

            // classify all remaining codes as errors
            // TODO - although this is the behavior of the legacy UI, we should probably audit the bidder stat codes and make sure they are being counted properly
            const nonErrorCodeSet = new Set(Object.values(BidResultMap).flat(1));
            const errorCodes = Object.keys(bidResults)
                .map((code) => Number(code))
                .filter((code) => !nonErrorCodeSet.has(code))
                .map((code) => code);
            const errors = sumBidResults(errorCodes);

            return {
                dealName,
                dsp,
                auctionCount,
                averageBidPrice,
                noBid,
                belowFloor,
                losses,
                wins,
                dealMismatch,
                timeouts,
                belowDeal,
                aboveDeal,
                throttled,
                errors,
            };
        });
    };

    const adStatsTableData = data?.dealStatMap ? mapAdStatsTableData(data) : [];
    const adBidStatsTableData = data?.dealStatMap ? mapBidStatsData(data) : [];

    return {
        handleChangeValue,
        dealFilterOptions,
        dealFilterValue,
        adBidStatsTableData,
        adStatsTableData,
        isFetching,
    };
};
