import { FC } from "react";
import { css } from "@emotion/css";
import { SIDE_NAV_WIDTH } from "../constants";
import { Divider, Layout, Tooltip } from "antd";
import { Icons } from "@rubicon/antd-components";
import { useBuyerAuthContext } from "@app/core/auth";
import {
    Nav,
    useBuyerSideNavState,
    LogoText,
    NavGroup,
    NavGroupItem,
    AccountNavGroupItem,
    BuyerAccountDrawer,
} from "@app/core/components";
import { BuyerMoreDrawer } from "../BuyerMoreDrawer/BuyerMoreDrawer";
import { getMenuItems } from "./helpers";
import { useBuyerMoreSubMenuItems } from "./useBuyerSubMoreMenuItems";
import { useWatchBuyerPermissions } from "@app/core/auth/useWatchBuyerPermissions";

const menuMoreItem = {
    key: "more",
    label: "More",
    icon: <Icons.MoreIcon />,
};

export const BuyerSideNav: FC = () => {
    useWatchBuyerPermissions();
    const {
        activeApp,
        activeGroup,
        handleAppClick,
        handleMenuClose,
        handleGroupClick,
        handleGroupClose,
        isAccount,
        handleAccountClick,
        isOpen,
    } = useBuyerSideNavState();
    const { context } = useBuyerAuthContext();
    const { hasMoreMenu, moreSubMenuItems } = useBuyerMoreSubMenuItems(context?.id, handleMenuClose);

    const accountLabel = context ? `${context?.name} (${context?.entityType || "Buyer"})` : "";

    return (
        <Layout.Sider width={SIDE_NAV_WIDTH}>
            <Nav>
                <LogoText />
                <NavGroup>
                    {getMenuItems(Number(context?.id)).map((app) => (
                        <NavGroupItem
                            route={app.route}
                            isActive={activeApp === app.key}
                            key={app.key}
                            icon={app.icon}
                            label={app.label}
                            onClick={() => handleAppClick(app.key)}
                        />
                    ))}
                    {hasMoreMenu && (
                        <NavGroupItem
                            isActive={activeGroup === menuMoreItem.key}
                            key={menuMoreItem.key}
                            icon={menuMoreItem.icon}
                            label={menuMoreItem.label}
                            onClick={() => handleGroupClick(menuMoreItem.key)}
                        />
                    )}
                    <Divider className={css({ margin: "0.5rem 0", borderTop: "1px solid rgba(255,255,255,0.5)" })} />
                    {context && (
                        <Tooltip placement="right" title={accountLabel}>
                            <div>
                                <AccountNavGroupItem
                                    icon={<Icons.MyAccountIcon isOpen={isAccount && isOpen} />}
                                    label={accountLabel}
                                    onClick={handleAccountClick}
                                    isActive={isAccount}
                                />
                            </div>
                        </Tooltip>
                    )}
                </NavGroup>
            </Nav>
            <BuyerAccountDrawer />
            {hasMoreMenu && (
                <BuyerMoreDrawer
                    isOpen={Boolean(isOpen && activeGroup)}
                    handleClose={handleMenuClose}
                    handleTypeClose={handleGroupClose}
                    menuItems={moreSubMenuItems}
                />
            )}
        </Layout.Sider>
    );
};
