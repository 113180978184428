import { SeatAdSourcesType } from "@app/core/services";
import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { Form } from "antd";

const { useWatch } = Form;

export const useIsVisible = () => {
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);
    const adSourceTypeId = adSourceType?.id;

    switch (adSourceTypeId) {
        case AdSourceTypeIds.CURATOR_MARKETPLACE:
            return true;
        default:
            return false;
    }
};
