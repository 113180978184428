import { useState } from "react";
import {
    Publisher,
    PublisherPayload,
    useUpdatePublisherMutation,
    useCreatePublisherMutation,
    useDeletePublisherMutation,
} from "@app/core/services";
import { useHistory } from "react-router-dom";
import { useUserAccess } from "@app/core/auth";
import { LabeledValue } from "antd/lib/select";
import { FormInstance, notification } from "antd";
import { Store } from "rc-field-form/lib/interface";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { DEFAULT_FALLBACK_MESSAGE } from "@app/features/inventory/constants";
import {
    getPublisherFormInitialValues,
    parsePublisherFormValuesToApi,
} from "@app/features/inventory/HierarchyForms/PublisherForm/publisherFormUtils";
import { INVENTORY_FLOORS_ADVANCED } from "@app/features/inventory/InventoryFloors";
import { InventoryCodeType, PUBLISHER_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { usePublisherFormInstance } from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormInstance";
import { LabelValueOption } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/useSeatDetailsForm";
import { InventoryDetailsDrawerType } from "../../DetailsDrawer/reducer";

export interface PublisherForm {
    // General Section
    [PUBLISHER_FORM_FIELDS.NAME.name]: string;
    [PUBLISHER_FORM_FIELDS.CODE_TYPE.name]: InventoryCodeType;
    [PUBLISHER_FORM_FIELDS.CODE.name]: string;
    [PUBLISHER_FORM_FIELDS.DESCRIPTION.name]: string;
    [PUBLISHER_FORM_FIELDS.PUBLISHER_NAME.name]: string;
    [PUBLISHER_FORM_FIELDS.OVERRIDE.name]: boolean;
    [PUBLISHER_FORM_FIELDS.EXTERNAL_COMMENT.name]: string;
    [PUBLISHER_FORM_FIELDS.BUSINESS_DOMAIN.name]: string;
    [PUBLISHER_FORM_FIELDS.ACCOUNT_TYPE.name]: LabeledValue | null;
    // Affiliate Section
    [PUBLISHER_FORM_FIELDS.AFFILIATE_COST_MODEL.name]: LabeledValue;
    [PUBLISHER_FORM_FIELDS.AFFILIATE_COST_VALUE_PERCENT.name]: number | null;
    [PUBLISHER_FORM_FIELDS.AFFILIATE_COST_VALUE_FIXED.name]: number | null;
    // Brand Safety Section
    [PUBLISHER_FORM_FIELDS.BLOCK_ADVERTISER_DOMAIN_LISTS.name]: LabeledValue[];
    [PUBLISHER_FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: string;
    [PUBLISHER_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.name]: LabeledValue | null;
    [PUBLISHER_FORM_FIELDS.CREATIVE_BLOCK_MODE.name]: number;
    // Internal Section
    [PUBLISHER_FORM_FIELDS.BILLING_CODE.name]: string | null;
    [PUBLISHER_FORM_FIELDS.LOWER_CALCULON_USE_RATE_OVERRIDE.name]: number | null;
    [PUBLISHER_FORM_FIELDS.UPPER_CALCULON_USE_RATE_OVERRIDE.name]: number | null;
    [PUBLISHER_FORM_FIELDS.PUBLISHER_RE_AUCTION.name]: boolean | string;
    //Advanced Features Section
    [PUBLISHER_FORM_FIELDS.EMIT_PRICEING_IN_VAST.name]: boolean;
    [PUBLISHER_FORM_FIELDS.ALLOW_VAST_EXTENSION_DSP.name]: boolean;
    [PUBLISHER_FORM_FIELDS.ALLOW_VAST_EXTENSION_CRID.name]: boolean;
    [PUBLISHER_FORM_FIELDS.ALLOW_VAST_EXTENSION_ADOMAIN.name]: boolean;
    [PUBLISHER_FORM_FIELDS.ALLOW_VAST_EXTENSION_BRAND.name]: boolean;
    [PUBLISHER_FORM_FIELDS.ALLOW_VAST_EXTENSION_BID_PRICE.name]: boolean;
    [PUBLISHER_FORM_FIELDS.ALLOW_AD_SOURCE_VAST_EXTENSION.name]: boolean;
    [PUBLISHER_FORM_FIELDS.ALLOW_VAST_EXTENSION_IAB_CATEGORY.name]: boolean;
    [PUBLISHER_FORM_FIELDS.ALLOW_AD_SOURCE_NAME_VAST_EXTENSION.name]: boolean;
    // Custom Pixels
    [PUBLISHER_FORM_FIELDS.CUSTOM_PIXELS.name]: LabeledValue[];
    // Labels
    [PUBLISHER_FORM_FIELDS.LABELS.name]: LabelValueOption[];
    [PUBLISHER_FORM_FIELDS.INTERNAL_LABELS.name]: LabelValueOption[];
}

export interface UsePublisherForm {
    open: boolean;
    loading: boolean;
    isSysAdmin: boolean;
    isPubAcctMgr: boolean;
    isEditMode: boolean;
    isDeleting: boolean;
    initialValues: Store;
    isTremorUser: boolean;
    showModal: () => void;
    handleCancel: () => void;
    handleDelete: () => void;
    submitButtonTitle: string;
    hasSeatWriteAccess: boolean;
    handleCancelModal: () => void;
    isFloorsSectionShown: boolean;
    isAffiliateSectionShown: boolean;
    publisher: Publisher | undefined;
    handleAdvancedFloors: () => void;
    form: FormInstance<PublisherForm>;
    handleSubmit: () => void;
}

export const usePublisherForm = (): UsePublisherForm => {
    const history = useHistory();
    const [open, setOpen] = useState<boolean>(false);
    const { isTremorUser, isSysAdmin, isPubAcctMgr, hasInternalAccess, hasSeatWriteAccess } = useUserAccess();
    const { publisher, mode, seat, form, seatId } = usePublisherFormInstance();

    const isEditMode: boolean = mode === "edit";
    const submitButtonTitle = mode !== "create" ? "Save" : "Submit";
    const isAffiliateSectionShown: boolean = isTremorUser && !!seat?.affiliateCostEnabled;

    const initialValues: Store = getPublisherFormInitialValues({ publisher, mode });

    const [createPublisher, { isLoading: isCreating }] = useCreatePublisherMutation();
    const [updatePublisher, { isLoading: isUpdating }] = useUpdatePublisherMutation();
    const [deletePublisher, { isLoading: isDeleting }] = useDeletePublisherMutation();

    const handleCancelModal = (): void => setOpen(false);
    const showModal = (): void => setOpen(true);
    const handleCancel = (): void => history.goBack();

    const handleCreate = async (body: PublisherPayload): Promise<void> => {
        if (!seat) return notification.error({ message: DEFAULT_FALLBACK_MESSAGE });

        try {
            const res = await createPublisher({ seatId: seat.id, body }).unwrap();
            notification.success({ message: "Publisher created successfully" });
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT(
                    InventoryDetailsDrawerType.PUBLISHER,
                    seat.id,
                    res.id
                )
            );
        } catch (err) {
            notification.error({ message: err?.data?.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        }
    };
    const handleUpdate = async (body: PublisherPayload): Promise<void> => {
        if (!seat || !publisher) return notification.error({ message: DEFAULT_FALLBACK_MESSAGE });

        try {
            await updatePublisher({ ...body, id: publisher.id, seat: { id: seat.id } }).unwrap();
            notification.success({ message: "Publisher updated successfully" });
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT(
                    InventoryDetailsDrawerType.PUBLISHER,
                    seat.id,
                    publisher.id
                )
            );
        } catch (err) {
            notification.error({ message: err?.data?.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        }
    };
    const handleDelete = async (): Promise<void> => {
        if (!isEditMode || !publisher) return notification.error({ message: DEFAULT_FALLBACK_MESSAGE });
        try {
            await deletePublisher(publisher.id).unwrap();
            notification.success({ message: "Publisher deleted successfully" });
            history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH(seatId));
        } catch (err) {
            notification.error({ message: err?.data?.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        }
    };
    const handleSubmit = async (): Promise<void> => {
        try {
            await form.validateFields();
            const payload: PublisherPayload = parsePublisherFormValuesToApi({
                mode,
                seat,
                values: form.getFieldsValue(),
                publisher,
                isSysAdmin,
                isTremorUser,
                isPubAcctMgr,
                hasInternalAccess,
            });
            if (isEditMode) return handleUpdate(payload);
            return handleCreate(payload);
        } catch (error) {
            form.scrollToField(error.errorFields[0].name, { behavior: "smooth", block: "center" });
        }
    };

    const handleAdvancedFloors = (): void =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_TABS(seatId, INVENTORY_FLOORS_ADVANCED));

    return {
        open,
        form,
        showModal,
        publisher,
        isEditMode,
        isSysAdmin,
        isDeleting,
        handleCancel,
        isPubAcctMgr,
        handleSubmit,
        handleDelete,
        isTremorUser,
        initialValues,
        handleCancelModal,
        submitButtonTitle,
        hasSeatWriteAccess,
        handleAdvancedFloors,
        isAffiliateSectionShown,
        isFloorsSectionShown: isEditMode,
        loading: isCreating || isUpdating,
    };
};
