import { FC } from "react";
import { Button, Card, Col, Form, Row, Space } from "antd";
import { CountryField, FloorPriceField } from "../FloorFormFields";
import { useFloorForm } from "./useFloorForm";
import { Floor, Targeting } from "@app/core/services";
import { COL_SIZES, GUTTER } from "@app/features/inventory/constants";
interface Props {
    mode: "create" | "edit";
    floor?: Floor;
    targeting?: Targeting[];
}

export const FloorForm: FC<Props> = ({ mode, floor }) => {
    const { form, handleCancel, handleSubmit, isSubmitting, initialValues } = useFloorForm(mode, floor);

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            initialValues={initialValues}
            scrollToFirstError={{ behavior: "smooth", block: "center" }}
        >
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                <Card>
                    <Row gutter={GUTTER}>
                        <Col {...COL_SIZES}>
                            <FloorPriceField />
                        </Col>
                    </Row>
                </Card>
                <Card>
                    <Row gutter={GUTTER}>
                        <Col {...COL_SIZES}>
                            <CountryField />
                        </Col>
                    </Row>
                </Card>

                <Space>
                    <Button type="primary" loading={isSubmitting}>
                        Save
                    </Button>
                    <Button onClick={handleCancel} disabled={isSubmitting}>
                        Cancel
                    </Button>
                </Space>
            </Space>
        </Form>
    );
};
