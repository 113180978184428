import { FC } from "react";
import { useSeatAuthContext } from "@app/core/auth";
import { NavBar, Loading } from "@app/core/components";
import { DealsNavMenu } from "./DealsNavMenu";
import { DealsSwitch } from "./DealsSwitch";
import { useSetInitialSettings } from "./useSetInitialSettings";
import { AdSourceDetailsDrawerDealsWrapper } from "./DealDetailsPage/DealDetailsPageAdSourceConfiguration/AdSourceSections/AdSourcesDetailsDrawerDealsWrapper";
import { DealDetailsDrawerWrapper } from "./DealDetailsDrawer/DealDetailsDrawerWrapper";

export const Deals: FC = () => {
    const { context, session } = useSeatAuthContext();
    useSetInitialSettings();

    if (!context || !session) {
        return <Loading />;
    }

    return (
        <>
            <NavBar label="Deals Management" />
            <DealsNavMenu />
            <DealsSwitch />
            <DealDetailsDrawerWrapper />
            <AdSourceDetailsDrawerDealsWrapper />
        </>
    );
};
