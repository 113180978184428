import { LabeledValue } from "antd/lib/select";
import { setFiltersSeatAdSourcesAssignee } from "../../reducer";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useGetSeatExternalUsersQuery } from "@app/core/services";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

interface UseSeatAdSourcesAssigneeFilter {
    handleChangeValue: (value: string | number | undefined, option: LabeledValue | LabeledValue[]) => void;
    options: LabeledValue[];
    value: LabeledValue[] | string | null;
    isLoading: boolean;
}

export const useSeatAdSourcesAssigneeFilter = (): UseSeatAdSourcesAssigneeFilter => {
    const dispatch = useAppDispatch();
    const { seatId } = useParams<{ seatId: string }>();
    const seatAdSourcesAssignee = useAppSelector((state) => state.seatAdSourcesList.filters.seatAdSourcesAssignee);
    const { data, isFetching } = useGetSeatExternalUsersQuery(Number(seatId));
    const options = useMemo(() => (data || []).map((filter) => ({ label: filter.name, value: filter.id })), [data]);

    const handleChangeValue = (_, option: LabeledValue[]) => {
        dispatch(setFiltersSeatAdSourcesAssignee(option));
    };

    return {
        handleChangeValue,
        options: options,
        value: seatAdSourcesAssignee,
        isLoading: isFetching,
    };
};
