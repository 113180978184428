import { Select } from "antd";
import { FC } from "react";
import { LabeledValue } from "antd/lib/select";
import { useAwsRegions } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm/ExchangeProtectionsFormSections/InventoryLimitsSection/Fields/QPSLimitsField/AwsRegionsSelect/useAwsRegions";

interface AwsRegionsSelectProps {
    onChange: (value: number, option: LabeledValue) => void;
    value: number | undefined;
}

export const AwsRegionsSelect: FC<AwsRegionsSelectProps> = ({ onChange, value }) => {
    const { isFetching, options } = useAwsRegions();
    return (
        <Select
            allowClear
            showSearch
            value={value}
            options={options}
            onChange={onChange}
            loading={isFetching}
            optionFilterProp="label"
            style={{ width: "100%" }}
            placeholder="Select Region..."
        />
    );
};
