import { consoleApi, PRIVATE_LABELS_TAG } from "./console";

export interface PrivateLabel {
    id: number;
    name: string;
    domain: string;
    uiThemeId: number;
    description: string;
    uiLogoId: number;
    uiLogoAltId: number;
    uiIconId: number;
    uiUrls: {
        logo: string;
        logoAlt: string;
        icon: string;
        appleTouchIcon: string;
        favicon16: string;
        favicon32: string;
        faviconIco: string;
    };
    deletedAt: null;
    createdAt: string;
    updatedAt: string;
    uiTheme: {
        id: number;
        name: string;
        description: string;
        cacheBuster: string;
        wikiInstructions: null;
        macroWikiUrl: null;
        primaryColor: string;
        primaryDarkColor: string;
        primaryLightColor: string;
        secondaryColor: string;
        chartPrimaryColor: string;
        chartSecondaryColor: string;
        chartTertiaryColor: string;
        chartQuaternaryColor: string;
        navbarBackgroundColor: string;
        navbarBackgroundHighlightColor: string;
        navbarTextColor: string;
        navbarTextActiveColor: string;
        broadfallColor: string;
        navbarTextHoverColor: string;
        navbarAccentColor: string;
        createdAt: string;
        updatedAt: string;
    };
}
interface PrivateLabelParams {
    id: number;
    test?: boolean;
}

export const privateLabelsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getSeatPrivateLabel: builder.query<PrivateLabel, PrivateLabelParams>({
            query: (params: PrivateLabelParams) => {
                const urlSearchParams = new URLSearchParams();
                if (params.test) {
                    urlSearchParams.set("test", "true");
                }
                return {
                    url: `/seats/${params.id}/privateLabel?${urlSearchParams.toString()}`,
                };
            },
            providesTags: (_, err, params) => (err ? [] : [{ type: PRIVATE_LABELS_TAG, id: params.id }]),
        }),
    }),
});

export const { useGetSeatPrivateLabelQuery } = privateLabelsApi;
