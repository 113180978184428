import { uri } from "@rubicon/utils";
import { useHistory, useLocation } from "react-router-dom";
import { getParamFromSearch } from "../../tagParams/helpers";
import { ENTITY_TYPE, getEntityOptionsByContext } from "../constants";
import { useSeatAuthContext } from "@app/core/auth/useSeatAuthContext";

export const useEntityTypeFilter = () => {
    const { search } = useLocation();
    const selected = getParamFromSearch(search, ENTITY_TYPE);
    const { push } = useHistory();
    const { context } = useSeatAuthContext();

    const options = getEntityOptionsByContext(context);

    const handleChange = (label: string) => {
        const nextSearch = uri.setSearchParam(search, ENTITY_TYPE.key, label);
        push({ search: nextSearch });
    };

    return {
        selected,
        handleChange,
        options,
    };
};
