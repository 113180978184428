import { FC } from "react";
import { Col, Form, Input, Row, Select, Typography } from "antd";
import { CurrencyInput } from "@app/core/components";
import { useDemandAcquisitionCostFormItem } from "./useDemandAcquisitionCostFormItem";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import {
    AD_SOURCE_FIELDS,
    DEFAULT_ENRICHMENT_COST_CURRENCY_CODE,
    DemandAcquisitionCostModelId,
} from "@app/features/seatAdSources/constants";
import {
    getDemandAcquisitionCostFormValue,
    getDemandAcquisitionCostHelpText,
    getDemandAcquisitionCostLabel,
} from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/EnrichmentCostSection/utils";
import { useDemandAcquisitionCostUtils } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/EnrichmentCostSection/useDemandAcquisitionCostUtils";

export const DemandAcquisitionCostFormItem: FC = () => {
    const { isDemandAcquisitionEnabled, isDemandAcquisitionReadonly } = useDemandAcquisitionCostUtils();
    const { dac, dacModelId, dacModelOptions, dacOnBlur, dacModelOnSelect } = useDemandAcquisitionCostFormItem();

    if (!isDemandAcquisitionEnabled) {
        return null;
    }

    const dacLabel = getDemandAcquisitionCostLabel(dacModelId);
    const dacHelpText = getDemandAcquisitionCostHelpText(dac, dacModelId);
    const isDacModelPercent = dacModelId === DemandAcquisitionCostModelId.PERCENTAGE;

    return (
        <div data-sdet="demand-acquisition-cost-fields">
            <Row>
                <Col>
                    <Typography.Text strong>{AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.label}</Typography.Text>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <Form.Item name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DEMAND_ACQUISITION_COST_MODEL}>
                        <Select
                            data-sdet="ad-source-demand-acquisition-cost-model"
                            disabled={isDemandAcquisitionReadonly}
                            options={dacModelOptions}
                            placeholder="Select..."
                            onSelect={dacModelOnSelect}
                            value={dacModelId}
                        />
                    </Form.Item>
                </Col>
                <Col span={isDacModelPercent ? 12 : 14}>
                    {isDacModelPercent ? (
                        <Form.Item
                            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DEMAND_ACQUISITION_COST}
                            data-sdet="ad-source-demand-acquisition-cost-field"
                            help={dacHelpText}
                        >
                            <Input
                                data-sdet="ad-source-demand-acquisition-cost-input"
                                placeholder={`Enter ${dacLabel}`}
                                suffix="%"
                                disabled={isDemandAcquisitionReadonly}
                                value={getDemandAcquisitionCostFormValue(dac)}
                                onBlur={(event) => dacOnBlur(event.target.value)}
                            />
                        </Form.Item>
                    ) : (
                        <CurrencyInput
                            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DEMAND_ACQUISITION_COST}
                            dataSdetPrefix="ad-source-demand-acquisition-cost"
                            label={dacLabel}
                            hideLabel
                            disabled={isDemandAcquisitionReadonly}
                            currencyCode={DEFAULT_ENRICHMENT_COST_CURRENCY_CODE}
                            help={dacHelpText}
                            value={getDemandAcquisitionCostFormValue(dac)}
                            onBlur={dacOnBlur}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
};
