import { FC } from "react";
import { Seat } from "@app/core/services";
import { Form, Card, Row, Col } from "antd";
import { useUserAccess } from "@app/core/auth";
import { getYesNoLabel } from "@app/core/utils/labels";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import {
    AudienceLock,
    TestCreativeId,
    AudienceLockAllowedDomains,
} from "@app/features/inventory/InventorySeat/InventorySeatEditPage/AdvancedFeaturesForm/AdvancedFeaturesFormFields";
import { INVENTORY_FORM_FIELDS, SEAT_DETAILS_SECTION_COL_SIZES } from "@app/features/inventory/InventorySeat/constants";

interface AdminToolsSectionProps {
    seat: Seat;
    edit?: boolean;
}

export const AdminToolsSection: FC<AdminToolsSectionProps> = ({ seat, edit = false }) => {
    const { isSysAdmin } = useUserAccess();
    return (
        <Card bordered={false}>
            <Row gutter={16}>
                <Col {...SEAT_DETAILS_SECTION_COL_SIZES}>
                    <Form.Item
                        label={INVENTORY_FORM_FIELDS.TEST_CREATIVES_ENABLED.label}
                        name={INVENTORY_FORM_FIELDS.TEST_CREATIVES_ENABLED.name}
                    >
                        {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.enableTestCreativeUpload)}
                    </Form.Item>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} xl={12} xxl={11}>
                            <TestCreativeId seat={seat} edit={edit} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={6} xl={5} xxl={4}>
                    <Form.Item
                        label={INVENTORY_FORM_FIELDS.ALERTS_ENABLED.label}
                        name={INVENTORY_FORM_FIELDS.ALERTS_ENABLED.name}
                    >
                        {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.alertsEnabled)}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} xl={5} xxl={4}>
                    <Form.Item
                        label={INVENTORY_FORM_FIELDS.ALLOW_OR_TARGETING.label}
                        name={INVENTORY_FORM_FIELDS.ALLOW_OR_TARGETING.name}
                    >
                        {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.orTargetingAllowed)}
                    </Form.Item>
                </Col>
                {isSysAdmin && (
                    <Col xs={24} sm={24} md={6} xl={5} xxl={4}>
                        <Form.Item
                            label={INVENTORY_FORM_FIELDS.CLICK_TRACKING.label}
                            name={INVENTORY_FORM_FIELDS.CLICK_TRACKING.name}
                        >
                            {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.clickTrackingEnabled)}
                        </Form.Item>
                    </Col>
                )}
                <Col xs={24} sm={24} md={6} xl={5} xxl={4}>
                    <Form.Item
                        label={INVENTORY_FORM_FIELDS.ALLOW_VAST_UNWRAPPING.label}
                        name={INVENTORY_FORM_FIELDS.ALLOW_VAST_UNWRAPPING.name}
                    >
                        {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.allowVastUnwrapping)}
                    </Form.Item>
                </Col>
            </Row>
            <AudienceLock seat={seat} edit={edit} />
            <AudienceLockAllowedDomains seat={seat} edit={edit} />
        </Card>
    );
};
