import { AdUnit } from "@app/core/services";
import { GUTTER } from "@app/features/inventory/constants";
import { css } from "@emotion/css";
import { Col, Form, Row, Space, Table, Typography } from "antd";
import { FC } from "react";
import { Link } from "react-router-dom";
import { AD_UNIT_AD_BREAK_RULES_LABELS } from "../../../constants";
import { AdBreak, useAdBreakRules } from "./useAdBreakRules";
import { brandCobalt } from "@rubicon/antd-components";

const { Item } = Form;
const { Text, Paragraph /*Link*/ } = Typography;

const labelClassName = css`
    .ant-form-item-label label {
        font-size: 1rem;
    }
`;
const className = css`
    display: block;
    font-size: 1rem;
    margin-bottom: 0.5rem;
`;

const adBreakClassName = css`
    margin-bottom: 1rem;
`;

const linkClassName = css`
    &.ant-typography {
        color: ${brandCobalt};
    }
`;

interface Props {
    adUnit: AdUnit;
}

export const AdBreakRules: FC<Props> = ({ adUnit }) => {
    const {
        columns,
        adBreakColumns,
        postRollDataSource,
        preRollDataSource,
        adBreakDataSource,
        defaultDataSource,
        isCuepoints,
        advancedAdBreaksUrl,
    } = useAdBreakRules(adUnit);
    return (
        <div data-sdet="ad-unit-ad-break-rules">
            <Typography className={adBreakClassName}>
                To do add Advanced Ad Break Rules, go to{" "}
                <Link to={advancedAdBreaksUrl} target="_blank" className={linkClassName}>
                    Controls Bulk Advanced Ad Breaks.
                </Link>
            </Typography>

            <Row gutter={GUTTER}>
                <Col span={12}>
                    <Item label={AD_UNIT_AD_BREAK_RULES_LABELS.preRoll} className={labelClassName}>
                        <Table<AdBreak>
                            pagination={false}
                            size="small"
                            columns={columns}
                            dataSource={postRollDataSource}
                        />
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col span={12}>
                    <Item label={AD_UNIT_AD_BREAK_RULES_LABELS.midRoll} className={labelClassName}>
                        <Space direction="vertical" style={{ width: "100%" }} size="middle">
                            <div>
                                <Text className={className}>Default</Text>
                                <Table
                                    pagination={false}
                                    size="small"
                                    columns={columns}
                                    dataSource={defaultDataSource}
                                />
                            </div>
                            <div>
                                <Text className={className}>Ad Break</Text>
                                <Table
                                    pagination={false}
                                    size="small"
                                    columns={adBreakColumns}
                                    dataSource={adBreakDataSource}
                                    className={adBreakClassName}
                                />
                                <Paragraph>
                                    {isCuepoints ? (
                                        <>
                                            {adUnit.cuepoints.join(" ")} These cuepoints will be used as fallback if
                                            they aren&apos;t found in the Content Metadata feed
                                        </>
                                    ) : (
                                        "No Cue points have been added we will be looking in Content Metadata for them. If not found there will be no Mid-Roll Ad Breaks"
                                    )}
                                </Paragraph>
                            </div>
                        </Space>
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col span={12}>
                    <Item label={AD_UNIT_AD_BREAK_RULES_LABELS.postRoll} className={labelClassName}>
                        <Table pagination={false} size="small" columns={columns} dataSource={preRollDataSource} />
                    </Item>
                </Col>
            </Row>
        </div>
    );
};
