import { Col, Form, Row } from "antd";
import { AuditLogActionTypeSelect } from "./AuditLogActionTypeSelect";
import { AuditLogEntitySelect } from "./AuditLogEntitySelect";
import { AuditLogUserSelect } from "./AuditLogUserSelect";
import { AuditLogDateRangePickers } from "./AuditLogDateRangePickers";

export const AUDIT_LOG_FILTERS_SDET = "audit-log-filters";

export const AuditLogFilters = () => (
    <Form layout="vertical" data-sdet={AUDIT_LOG_FILTERS_SDET}>
        <Row gutter={16}>
            <Col xs={24} md={8}>
                <AuditLogActionTypeSelect />
            </Col>
            <Col xs={24} md={8}>
                <AuditLogEntitySelect />
            </Col>
            <Col xs={24} md={8}>
                <AuditLogUserSelect />
            </Col>
        </Row>
        <Row>
            <Col xs={24} md={8}>
                <AuditLogDateRangePickers />
            </Col>
        </Row>
    </Form>
);
