import { useHistory, useLocation } from "react-router-dom";
import { DrawerBreadcrumbHeader } from "@app/core/components";
import { FC } from "react";
import { IndustryDetails } from "@app/core/services";
import { uri } from "@rubicon/utils";

interface Props {
    industry?: IndustryDetails;
    showDirectLabel: boolean;
}
export const CLASSIFICATIONS_INDUSTRY_CHANGE_HISTORY_DRAWER_TITLE_SDET =
    "classifications-industry-change-history-drawer-title";

export const ClassificationsIndustryChangeHistoryDrawerTitle: FC<Props> = ({ industry, showDirectLabel }) => {
    const history = useHistory();
    const { search: queryString } = useLocation();

    return industry ? (
        showDirectLabel ? (
            <span data-sdet={CLASSIFICATIONS_INDUSTRY_CHANGE_HISTORY_DRAWER_TITLE_SDET}>Industry: {industry.name}</span>
        ) : (
            <DrawerBreadcrumbHeader
                sdet={CLASSIFICATIONS_INDUSTRY_CHANGE_HISTORY_DRAWER_TITLE_SDET}
                onBack={() => {
                    const nextQueryString = uri.setSearchParam(queryString, "drawer", "details");
                    history.push({ search: nextQueryString });
                }}
                parentLabel={`Industry: ${industry.name}`}
                childLabel={"Change History"}
            />
        )
    ) : null;
};
