import { useNotificationError } from "@app/core/components/hooks";
import { useGetAdvertiserDomainsQuery } from "@app/core/services";
import { useLocation, useParams } from "react-router-dom";

export const useAdvertiserDomains = ({ isIncludeDomains = false }: { isIncludeDomains?: boolean }) => {
    const { search } = useLocation();
    const { seatId } = useParams<{ seatId: string }>();

    const { data, isLoading, isFetching, error } = useGetAdvertiserDomainsQuery({ seatId, isIncludeDomains });
    useNotificationError(error);

    return {
        isLoading: isLoading || isFetching,
        data,
        seatId,
        search,
    };
};
