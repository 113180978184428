import { FC } from "react";
import { AdSource } from "@app/core/services";
import { Col, Row, Tag } from "antd";
import { DetailsItem } from "../DetailsItem";
import { format } from "@rubicon/utils";

const { NONE } = format.constants;

interface Props {
    adSource: AdSource;
}

export const SeatAdSourcesCustomPixelsDetails: FC<Props> = ({ adSource }) => {
    return (
        <Row>
            <Col sm={12}>
                <DetailsItem label="Pixels">
                    {typeof adSource?.thirdPartyPixels?.length === "number"
                        ? adSource?.thirdPartyPixels.length > 0
                            ? adSource?.thirdPartyPixels.map((pixel) => <Tag key={pixel.id}>{pixel.name}</Tag>)
                            : NONE
                        : NONE}
                </DetailsItem>
            </Col>
        </Row>
    );
};
