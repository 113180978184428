import { FC } from "react";
import { Card, Col, Row } from "antd";
import { useUserAccess } from "@app/core/auth";
import { GUTTER } from "@app/features/inventory/constants";
import { CodeType, Description, GeneralInternalOnly, Name, Status } from "./Fields";
import { AdUnitCode } from "./Fields/GeneralInternalOnly/Fields";
import { usePublisherChannelFormInstance } from "../../PublisherChannelFormInstance";

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 12 };
export const GeneralSection: FC = () => {
    const { mode } = usePublisherChannelFormInstance();
    const { isSysAdmin, isPubAcctMgr, hasSeatWriteAccess } = useUserAccess();
    const isInternalOnly = isSysAdmin || isPubAcctMgr;
    const isCreateMode = mode === "create";
    const isEditingMode = mode === "edit";

    return (
        <Card bordered={false}>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Name />
                    <CodeType />
                    {((hasSeatWriteAccess && isCreateMode) || !isInternalOnly) && <AdUnitCode />}
                    <Description />
                    <Status />
                </Col>
                {isInternalOnly && (
                    <Col {...COL_SIZES}>
                        <GeneralInternalOnly isEditing={isEditingMode} />
                    </Col>
                )}
            </Row>
        </Card>
    );
};
