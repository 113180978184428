import { Form } from "antd";
import { useMemo } from "react";
import { useGetAdSourcesTypeQuery } from "@app/core/services";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { AdSourceCountLimitOption } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm/useExchangeProtectionsForm";

const { useWatch } = Form;

interface UseTypeSelect {
    options: { label: string; value: number }[];
    isFetching: boolean;
}

export const useTypeSelect = (): UseTypeSelect => {
    const { data = [], isFetching } = useGetAdSourcesTypeQuery();
    const types = useMemo(() => (data || []).map((type) => ({ value: type.id, label: type.name })), [data]);
    const adSourceCountLimitsOptions: AdSourceCountLimitOption[] =
        useWatch(INVENTORY_FORM_FIELDS.COUNT_LIMITS.name) ?? [];
    const selectedTypes = adSourceCountLimitsOptions.map((option) => option?.adSourceCountLimit?.adSourceType?.id);
    const options = types.filter((type) => !selectedTypes.includes(type?.value));
    return {
        isFetching,
        options,
    };
};
