import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { Card } from "antd";
import { FC } from "react";
import { EntityTypeRadio } from "./Fields";

export const EntityType: FC = () => {
    const { dealObjectMode, marketplaceInfoMode } = useMarketplaceInfoWidget();
    if (dealObjectMode === "create") {
        return (
            <Card style={{ border: "1px solid #d9d9d9", borderBottom: "none" }}>
                <EntityTypeRadio marketplaceInfoMode={marketplaceInfoMode} />
            </Card>
        );
    }
    return <EntityTypeRadio marketplaceInfoMode={marketplaceInfoMode} hidden />;
};
