import { formatNumber } from "@rubicon/utils";
import { BRAND_TYPES, ENTITY_TYPES, GEO_TYPES } from "./constants";

// TODO - these methods are derived from the legacy code base and should be cleaned up / refactored.

export const isEmpty = (value: unknown): boolean => {
    try {
        if (!value) return true;
        if (Array.isArray(value) && value.length == 0) return true;
        if (typeof value == "object" && Object.keys(value).length === 0) return true;
        if (typeof value == "string") return value.trim().length == 0;
    } catch (e) {
        console.error("isEmpty check failed.", e);
    }
    return false;
};

export const entityResultFormatter = (obj) => {
    try {
        let text = "";
        if (obj.hasOwnProperty("name")) {
            text = obj.name ? obj.name : "[Unnamed]";
        } else if (obj.hasOwnProperty("text")) {
            text = obj.text;
        } else if (obj.hasOwnProperty("code")) {
            text = obj.code;
        } else if (obj.hasOwnProperty("id")) {
            text = obj.id;
        }

        if (obj.hasOwnProperty("emailAddress")) {
            text = text + ` [${obj.emailAddress}]`;
        } else if (obj.hasOwnProperty("_ogValue")) {
            if (obj._ogValue && obj._ogValue.hasOwnProperty("emailAddress")) {
                text = text + ` [${obj._ogValue.emailAddress}]`;
            }
        }

        return text;
    } catch (e) {
        console.error("Failed to format result entity.");
        return obj;
    }
};

const getGeoText = (id, text = "") => {
    let formattedText = "";
    switch (id) {
        case GEO_TYPES.COUNTRY:
            formattedText = `${text || "Country:"}`;
            break;
        case GEO_TYPES.STATE:
            formattedText = `${text || "State:"}`;
            break;
        case GEO_TYPES.CITY:
            formattedText = `${text || "City:"}`;
            break;
        case GEO_TYPES.DMA:
            formattedText = `${text || "DMA:"}`;
            break;
        case GEO_TYPES.CMA:
            formattedText = `${text || "CMA:"}`;
            break;
        case GEO_TYPES.POSTAL_CODE:
            formattedText = `${text || "Postal Code:"}`;
            break;
        default:
            formattedText = "All Geos";
            break;
    }

    return formattedText;
};

export const geoResultFormatter = (geoJson: string) => {
    let entity;
    try {
        entity = JSON.parse(geoJson);
    } catch (e) {
        console.error("Error parsing geo result.", e);
        return "";
    }

    if (entity && entity.hasOwnProperty("geo") && entity.hasOwnProperty("type")) {
        const theGeo = entity.geo;
        const geoType = entity.type;
        const city = theGeo.city || "";
        let subdiv = theGeo.subDivisionName || "";
        let metro = theGeo.metroName || "";
        let country = theGeo.countryName || "";
        const postalCode = entity.postalCode || null;

        if (city.length > 0) {
            subdiv = city + " - " + subdiv;
        }

        if (geoType && (geoType.id === GEO_TYPES.DMA || geoType.id === GEO_TYPES.CMA)) {
            if (subdiv.length > 0) {
                metro += ""; // Just show the metro code instead of everything
            }
        } else {
            metro = subdiv;
        }

        if (metro.length > 0) {
            country = metro + " - " + country;
        }

        if (postalCode && geoType.id === GEO_TYPES.POSTAL_CODE) {
            country = postalCode + " - " + country;
        }

        const formattedText = geoType && geoType.id ? getGeoText(geoType.id) : "";

        country = `${formattedText} ${country}`;

        return country;
    } else if (entity && entity.hasOwnProperty("text")) {
        return entity && entity.hasOwnProperty("text") ? entity.text : entity;
    } else {
        // account for the old format in audit logs
        return entity && entity.hasOwnProperty("countryCode") ? entity.countryCode : entity;
    }
};

export const getEntityLineage = (entity) => {
    if (!entity) {
        return "";
    }

    if (typeof entity === "string") {
        return entity;
    }

    if (entity.entityType && entity.entityType.toLowerCase() === ENTITY_TYPES.CHANNEL_AD_UNIT.toLowerCase()) {
        const pub = entity.supply.brand.publisher;
        const brand = entity.supply.brand;
        const seat = entity.supply.brand.publisher.seat;

        if (brand.type && brand.type.id === BRAND_TYPES.CHANNEL) {
            return entityResultFormatter(seat) + (" > " + pub.name + " > CHANNEL: " + brand.name);
        }

        return getEntityLineage(seat) + (" > CHANNEL: " + pub.name);
    }

    if (entity.hasOwnProperty("seat") && entity.seat) {
        return getEntityLineage(entity.seat) + (" > " + entity.name);
    } else if (entity.hasOwnProperty("publisher")) {
        return getEntityLineage(entity.publisher) + " > " + entity.name;
    } else if (entity.hasOwnProperty("brand")) {
        return getEntityLineage(entity.brand) + " > " + entity.name;
    } else if (entity.hasOwnProperty("supply")) {
        return getEntityLineage(entity.supply) + " > " + entity.name;
    } else if (entity.hasOwnProperty("network")) {
        return getEntityLineage(entity.network) + " > " + entity.name;
    } else if (entity.hasOwnProperty("name") && !isEmpty(entity.name)) {
        return entity.name;
    } else if (entity.hasOwnProperty("code")) {
        return entity.code;
    } else if (entity.hasOwnProperty("geo")) {
        return geoResultFormatter(entity);
    } else if (entity.hasOwnProperty("text") && !isEmpty(entity.text)) {
        return entity.text;
    } else {
        return "";
    }
};

export const formatEntityWithName = (anEntityWithName: string) => {
    if (anEntityWithName) {
        let parsed = null;
        try {
            parsed = JSON.parse(anEntityWithName);
        } catch (e) {
            return anEntityWithName;
        }
        if (typeof parsed === "number") {
            return parsed;
        } else if (typeof parsed === "boolean") {
            return parsed;
        } else if (typeof parsed === "string") {
            return parsed;
        } else {
            return getEntityLineage(parsed);
        }
    } else {
        return null;
    }
};

export const formatUserRole = (aUserRoleChange) => {
    if (aUserRoleChange) {
        const toJson = JSON.parse(aUserRoleChange).roleType || "";
        return toJson.typeName ? toJson.typeName : null;
    } else {
        return null;
    }
};

export const formatBlockedDomains = (blockedDomainsJson: string) => {
    let blockedDomains;
    try {
        blockedDomains = JSON.parse(blockedDomainsJson);
    } catch (e) {
        console.error("Failed to parse blocked domains.", e);
        return "";
    }

    return blockedDomains ?? "null";
};

export const formatUserGroupMemberChange = (groupChangeJson: string): string => {
    let groupChange;
    try {
        groupChange = JSON.parse(groupChangeJson);
    } catch (e) {
        console.error("Failed to parse group member change", e);
        return "";
    }

    return groupChange?.emailAddress ?? "";
};

export const formatTvSeries = (tvSeriesJson: string): string => {
    if (!tvSeriesJson) {
        return "[No value]";
    }

    let tvSeries;
    try {
        tvSeries = JSON.parse(tvSeriesJson);
    } catch (e) {
        console.error("Failed to parse tv series.", e);
        return "";
    }

    let series = "",
        season = "",
        episode = "";

    series += tvSeries.seriesName ? "Series: " + tvSeries.seriesName : "";

    if (tvSeries.seriesName) {
        season += tvSeries.season ? ", Season: " + tvSeries.season : "";
    } else {
        season += tvSeries.season ? "Season: " + tvSeries.season : "";
    }

    if (tvSeries.seriesName || tvSeries.season) {
        episode += tvSeries.episode ? ", Episode: " + tvSeries.episode : "";
    } else {
        episode += tvSeries.episode ? "Episode: " + tvSeries.episode : "";
    }

    // TODO - add isAdmin check to match legacy code?
    // const adminText = tvSeries?.seatName ? `${tvSeries.seatName} -> ` : "";
    const tvSeriesText = `${series}${season}${episode}`;

    return `${tvSeriesText}`;
};

export const formatMetaDataVideoId = (videoIdMetaDataJson) => {
    if (!videoIdMetaDataJson) {
        return "[No value]";
    }

    let videoIdMetaData;
    try {
        videoIdMetaData = JSON.parse(videoIdMetaDataJson);
    } catch (e) {
        console.error("Failed to parse meta data video id", e);
        return "";
    }
    let title = "Title: ",
        id = ", ID: ";

    title += videoIdMetaData.videoTitle ? videoIdMetaData.videoTitle : "[no title]";
    id += videoIdMetaData.videoId ? videoIdMetaData.videoId : "[no id]";

    // TODO - add isAdmin check to match legacy code?
    // const adminText = videoIdMetaData.seatName ? `${videoIdMetaData.seatName} -> ` : "";
    const videoIdText = `${title}${id}`;
    return videoIdText;
};

export const formatDomainFilters = (domainFiltersJson: string): string => {
    let domainFilters;
    try {
        domainFilters = JSON.parse(domainFiltersJson);
    } catch (e) {
        console.error("Failed to parse domain filters.", e);
        return "";
    }

    return domainFilters?.supplyDomain ?? "0";
};

export const formatBundleIdFilters = (bundleIdFiltersJson: string): string => {
    let bundleIdFilters;
    try {
        bundleIdFilters = JSON.parse(bundleIdFiltersJson);
    } catch (e) {
        console.error("Failed to parse domain filters.", e);
        return "";
    }

    return bundleIdFilters?.bundleId ?? "0";
};

export const formatWithUserMessage = (value: string, message?: string) => {
    const userMessageText = message ? ` User Message: ${message}` : "";
    return `${value}${userMessageText}`;
};

export const formatDefault = (value) => {
    return isNaN(value) ? formatEntityWithName(value) : formatNumber.asNumber(value);
};

export const getSimpleName = (fullyQualifiedClassName: string) => {
    const nameParts = fullyQualifiedClassName.split(".");
    return nameParts
        .slice(-1)[0]
        .replace(/([A-Z][a-z])/g, " $1")
        .replace(/(\d)/g, " $1");
};
