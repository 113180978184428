import { useAppDispatch, useAppSelector } from "@app/core/store";
import { selectDashboardRevenueType, setSettingsDashboardRevenueType } from "@app/features/dashboard/reducer";
import { LabeledValue } from "antd/lib/select";
import { REVENUE_TYPE_OPTIONS } from "./constants";
import { RevenueTypes } from "@app/core/clients/console";

interface UseRevenueTypeField {
    handleChangeValue: (value: string) => void;
    options: LabeledValue[];
    value: RevenueTypes | null;
}

export const useRevenueTypeField = (): UseRevenueTypeField => {
    const dispatch = useAppDispatch();
    const dashboardRevenueType = useAppSelector(selectDashboardRevenueType);

    const handleChangeValue = (value: RevenueTypes) => {
        dispatch(setSettingsDashboardRevenueType(value));
    };

    return {
        options: REVENUE_TYPE_OPTIONS,
        value: dashboardRevenueType,
        handleChangeValue,
    };
};
