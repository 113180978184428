import { FC } from "react";
import { Layout, Tabs, TabsProps } from "antd";
import { Footer } from "@app/core/components";
import { useUserAccess } from "@app/core/auth";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useHistory, useParams } from "react-router-dom";
import InventorySeatToolbar from "@app/features/inventory/InventorySeat/InventorySeatToolbar";
import { INVENTORY_SEAT_TABS, INVENTORY_SEAT_TABS_TYPES } from "@app/features/inventory/InventorySeat/constants";
import { InventorySeatPageSwitch } from "@app/features/inventory/InventorySeat/InventorySeatPage/InventorySeatPageSwitch";
import { SupplyTrafficAlertBanner } from "@app/features/inventory/components/SupplyTrafficAlertBanner";

export const InventorySeatPage: FC = () => {
    const history = useHistory();
    const { isSysAdmin, isPubAcctMgr, isSysReadOnly } = useUserAccess();
    const isInternalOnly: boolean = isSysAdmin || isPubAcctMgr;
    const isDefaultPricesVisible: boolean = isInternalOnly || isSysReadOnly;
    const { tab, seatId } = useParams<{ tab: INVENTORY_SEAT_TABS_TYPES; seatId: string }>();

    const items = [
        INVENTORY_SEAT_TABS.DETAILS,
        INVENTORY_SEAT_TABS.FLOORS,
        isDefaultPricesVisible && INVENTORY_SEAT_TABS.PRICES,
        INVENTORY_SEAT_TABS.ADVANCED,
        isInternalOnly && INVENTORY_SEAT_TABS.PROTECTIONS,
        isInternalOnly && INVENTORY_SEAT_TABS.INTERNAL,
    ].filter(Boolean) as TabsProps["items"];

    return (
        <>
            <InventorySeatToolbar
                title="Manage"
                footer={
                    <Tabs
                        size="large"
                        activeKey={tab}
                        defaultActiveKey={tab}
                        tabBarStyle={{ marginBottom: 0 }}
                        onChange={(tab: INVENTORY_SEAT_TABS_TYPES) =>
                            history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_SEAT_TABS(seatId, tab))
                        }
                        items={items}
                    ></Tabs>
                }
            />
            <SupplyTrafficAlertBanner sourceSelf="Seat" />
            <Layout.Content>
                <InventorySeatPageSwitch />
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
