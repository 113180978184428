import { Form, InputNumber } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { useAdSourceFallbackOpportunity } from "./useAdSourceFallbackOpportunity";

const { AD_SOURCE_PACING_FALLBACK } = CREATE_DEAL_FORM_ITEMS_NAME;
const { label } = AD_SOURCE_FIELDS.FALLBACK_OPPORTUNITY;

export const AdSourceFallbackOpportunity = () => {
    const { isVisible, handleBlur, onChange, value } = useAdSourceFallbackOpportunity();

    if (!isVisible) {
        return null;
    }

    return (
        <Form.Item
            name={AD_SOURCE_PACING_FALLBACK}
            label={label}
            data-sdet={`test-${AD_SOURCE_PACING_FALLBACK}-field`}
            {...VERTICAL_LAYOUT_FIX}
        >
            <InputNumber
                value={value}
                min={0}
                onBlur={handleBlur}
                onChange={onChange}
                data-sdet={`test-${AD_SOURCE_PACING_FALLBACK}-input`}
                placeholder={`Enter ${label}`}
                style={{ width: "100%" }}
            />
        </Form.Item>
    );
};
