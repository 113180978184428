import { FC } from "react";
import { usePendo, getStreamingPendoPayload } from "@rubicon/component-pendo";
import { Session } from "@app/core/clients/console";
import conf from "@app/core/conf";
import { Seat, Buyer } from "@app/core/services";

interface Props {
    session: Session | null;
    context: Seat | Buyer | null;
}

export const Pendo: FC<Props> = ({ session, context }) => {
    usePendo(conf.pendoStreamingApiKey, getStreamingPendoPayload(session, context));

    return null;
};
