import { LabeledValue } from "antd/lib/select";
import { css } from "@emotion/css";
import { brandCobalt } from "@rubicon/antd-components";

export const additionalTargetingCollapsePanelClassList = css`
    border-bottom: none !important;
    & > .ant-collapse-header > .ant-collapse-header-text {
        color: ${brandCobalt} !important;
        font-size: 14px !important;
        font-weight: normal !important;
    }
    & > .ant-collapse-content {
        border-top: none !important;
    }
    & > .ant-collapse-header {
        width: fit-content;
        padding: 0 !important;
        background: none !important;
    }
    & > .ant-collapse-header > .ant-collapse-expand-icon {
        color: ${brandCobalt} !important;
    }
`;

export enum TargetingFormKeys {
    AdSource = "adSource",
    AdSourceAdditionalTargeting = "adSourceAdditionalTargeting",
    Deal = "deal",
    DealAdditionalTargeting = "dealAdditionalTargeting",
    InventoryAdvancedFloors = "inventoryAdvancedFloors",
    BrandSafety = "brandSafetyAdvanced",
    Controls = "controls",
}

export enum GeoTargetTypeIds {
    Country = 1,
    State = 2,
    City = 3,
    Dma = 4,
    PostalCode = 5,
    Cma = 6,
}

// The enum value matches the Targeting includes/excludes payload key, except for:
// Inventory, which maps to adUnits, brands, supply, and publishers
// SegmentRules, which maps to segmentRules and segments
export enum TargetingDimensionTypes {
    AdBreakPositionTargets = "adBreakPositionTargets",
    Audiences = "audiences",
    ApiFrameworks = "apiFrameworks",
    BundleIdTargets = "bundleIdTargets",
    Bvod = "oztamDemographics",
    Categories = "categories",
    ContentCategories = "contentCategories",
    ContentChannels = "contentChannels",
    ContentLengths = "contentLengths",
    ContentNetworks = "contentNetworks",
    ContentRatings = "contentRatings",
    ContentSeries = "contentSeries",
    Coppa = "coppa",
    CustomRules = "customRules",
    CustomTargets = "customTargets",
    DayPartTargets = "dayPartTargets",
    Dnt = "dnt",
    Genres = "genres",
    GeoTargets = "geoTargets",
    Inventory = "inventory",
    LabelValues = "labelValues",
    LiveStream = "liveStream",
    MaxDurationTarget = "maxDurationTarget",
    MimeTypes = "mimeTypes",
    MinDurationTarget = "minDurationTarget",
    OperatingSystems = "operatingSystems",
    Platforms = "platforms",
    Pmp = "pmpDealIdTargets",
    PodSlotPositionTargets = "podSlotPositionTargets",
    Producers = "producers",
    Segments = "segments",
    SegmentRules = "segmentRules",
    Sizes = "sizes",
    SupplyDomainTargets = "supplyDomainTargets",
    SupplyTypes = "supplyTypes",
    TimeZoneMode = "timeZoneMode",
    VideoIds = "videoIds",
}

export enum TargetingDimensionTypeLabels {
    AdBreakPositionTargets = "AdBreak Position",
    Audiences = "Audiences",
    ApiFrameworks = "Supported APIs",
    BundleIdTargets = "Bundle IDs",
    Bvod = "BVOD Connect Demographics",
    Categories = "Categories",
    ContentCategories = "Content Categories",
    ContentChannels = "Content Channel",
    ContentLengths = "Content Length",
    ContentNetworks = "Content Network",
    ContentRatings = "Content Ratings",
    ContentSeries = "Content TV Series",
    Coppa = "COPPA",
    CustomRules = "Custom Rule",
    CustomTargets = "Custom ID",
    DayPartTargets = "Dayparting",
    Dnt = "DNT/LMT",
    Genres = "Genre",
    GeoTargets = "Geography",
    Inventory = "Inventory",
    LabelValues = "Inventory Label",
    LiveStream = "LiveStream",
    MaxDurationTarget = "Max. Ad Duration",
    MimeTypes = "MIME Type",
    MinDurationTarget = "Min. Ad Duration",
    OperatingSystems = "OS",
    Platforms = "Platform",
    Pmp = "Inbound PMP Deals",
    PodSlotPositionTargets = "Pod Slot Position",
    Producers = "Content Producer",
    SegmentRules = "DMP Segment",
    Sizes = "Player Size",
    SupplyDomainTargets = "Supply Domains",
    SupplyTypes = "Supply Type",
    TimeZoneMode = "TimeZone Mode",
    VideoIds = "Content Video Title",
}

export enum TargetingDimensionHelpKeys {
    AdBreakPositionTargets = "targeting.adBreaks",
    Audiences = "",
    ApiFrameworks = "targeting.apiframeworks",
    BundleIdTargets = "targeting.bundleids",
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    Bvod = "",
    Categories = "targeting.categories",
    ContentCategories = "targeting.contentcategories",
    ContentChannels = "targeting.contentChannels",
    ContentLengths = "targeting.contentlength",
    ContentNetworks = "targeting.contentNetworks",
    ContentRatings = "targeting.contentrating",
    ContentSeries = "targeting.includeseries",
    Coppa = "targeting.coppatargeting",
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    CustomRules = "",
    CustomTargets = "targeting.customtargeting",
    DayPartTargets = "targeting.dayparttargets",
    Dnt = "targeting.tracking",
    Genres = "targeting.genre",
    GeoTargets = "targeting.includegeos",
    Inventory = "targeting.includeinventory",
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    LabelValues = "",
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    LiveStream = "",
    MaxDurationTarget = "targeting.minadduration",
    MimeTypes = "targeting.mimes",
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    MinDurationTarget = "targeting.minadduration",
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    OperatingSystems = "",
    Platforms = "targeting.platforms",
    Pmp = "targeting.pmptargeting",
    PodSlotPositionTargets = "targeting.podSlots",
    Producers = "targeting.producer",
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    Segments = "",
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    SegmentRules = "",
    Sizes = "targeting.sizes",
    SupplyDomainTargets = "targeting.supplydomains",
    SupplyTypes = "targeting.supplytypes",
    VideoIds = "targeting.videoid",
    TargetingDealsWithTargeting = "targetingdetails.dealswithtgt",
}

export enum TargetingSupplyLabels {
    Coppa = "Child Directed Supply",
    Dnt = "DNT/LMT Supply",
    LiveStream = "LiveStream Supply",
}

export enum Conditions {
    Includes = "includes",
    Excludes = "excludes",
}

export enum PmpConditions {
    IncludesAny = "includesAny",
    IncludesSpecific = "includesSpecific",
    ExcludesAny = "excludesAny",
    ExcludesSpecific = "excludesSpecific",
}

export const PMP_ANY_VALUE = "*";
export const PMP_ALL_VALUE = "ALL";

export const DEFAULT_PMP_EXCLUDE_TARGETING_VALUE = [PMP_ANY_VALUE];

export const PMP_CONDITION_OPTIONS = [
    {
        label: "Include Any",
        value: PmpConditions.IncludesAny,
    },
    {
        label: "Include Specific",
        value: PmpConditions.IncludesSpecific,
    },
    {
        label: "Exclude Specific",
        value: PmpConditions.ExcludesSpecific,
    },
];

export enum Operations {
    Any = "any",
    All = "all",
}

export const DEFAULT_SUPPLY_TYPE: LabeledValue = {
    value: -1,
    label: "All (App/CTV + Site)",
};

export const SUPPLY_TYPE_APPLICATION = 1;
export const SUPPLY_TYPE_SITE = 2;
export const MIN_AD_DURATION = 0;

export enum InventoryCategoryTypes {
    All = "all",
    AdUnits = "adUnits",
    Brands = "brands",
    Channels = "channels",
    Publishers = "publishers",
    Supply = "supply",
}

export const INVENTORY_CATEGORIES = {
    [InventoryCategoryTypes.All]: { label: "All", value: InventoryCategoryTypes.All },
    [InventoryCategoryTypes.Channels]: { label: "Channels", value: InventoryCategoryTypes.Channels },
    [InventoryCategoryTypes.Publishers]: { label: "Publishers", value: InventoryCategoryTypes.Publishers },
    [InventoryCategoryTypes.Brands]: { label: "Brands", value: InventoryCategoryTypes.Brands },
    [InventoryCategoryTypes.Supply]: { label: "Supply", value: InventoryCategoryTypes.Supply },
    [InventoryCategoryTypes.AdUnits]: { label: "Ad Units", value: InventoryCategoryTypes.AdUnits },
};

export enum InventoryLabelValueEntityType {
    PUBLISHER = "Publisher",
    SUPPLY = "Supply",
    BRAND = "Brand",
    ADUNIT = "AdUnit",
    CHANNEL = "Channel",
}

export interface InventoryLabelValue {
    entityType: InventoryLabelValueEntityType;
    label: string;
    value: string;
}

export enum AdBreakPositionLabel {
    First = "First in List",
    Last = "Last in List",
}

export enum PositionValue {
    First = 0,
    Last = -1,
}

export const AD_BREAK_VALUE_TO_LABEL = {
    [PositionValue.First]: AdBreakPositionLabel.First,
    [PositionValue.Last]: AdBreakPositionLabel.Last,
};

export const AD_BREAK_POSITION_OPTIONS = [
    { label: AdBreakPositionLabel.First, value: PositionValue.First },
    { label: AdBreakPositionLabel.Last, value: PositionValue.Last },
];

export const enum PodSlotPositionLabel {
    First = "First in Break",
    Last = "Last in Break",
}

export const POD_SLOT_VALUE_TO_LABEL = {
    [PositionValue.First]: PodSlotPositionLabel.First,
    [PositionValue.Last]: PodSlotPositionLabel.Last,
};

export const POD_SLOT_POSITION_OPTIONS = [
    { label: PodSlotPositionLabel.First, value: PositionValue.First },
    { label: PodSlotPositionLabel.Last, value: PositionValue.Last },
];

export enum SegmentRuleModes {
    Advanced = "advanced",
    Simple = "simple",
}

export const SEGMENT_RULE_MODE_OPTIONS = [
    { label: "Simple", value: SegmentRuleModes.Simple },
    { label: "Advanced", value: SegmentRuleModes.Advanced },
];

export enum GroupConditions {
    And = "AND",
    Or = "OR",
}

export const GROUP_CONDITION_OPTIONS = [
    { label: GroupConditions.And, value: GroupConditions.And },
    { label: GroupConditions.Or, value: GroupConditions.Or },
];

export enum SegmentRuleIdentifierOperators {
    IsMember = "is_member",
    IsNotMember = "not_is_member",
}

export enum SegmentRuleIdentifierOperatorsLabels {
    IsMember = "Is a member of",
    IsNotMember = "Is not a member of",
}

export const SEGMENT_RULE_IDENTIFIER_OPERATOR_OPTIONS = [
    {
        label: SegmentRuleIdentifierOperatorsLabels.IsMember,
        value: SegmentRuleIdentifierOperators.IsMember,
    },
    {
        label: SegmentRuleIdentifierOperatorsLabels.IsNotMember,
        value: SegmentRuleIdentifierOperators.IsNotMember,
    },
];

export enum CustomRuleIds {
    CustomString = "custom_string",
    CustomString2 = "c2_string",
    CustomString3 = "c3_string",
    CustomString4 = "c4_string",
    CustomDouble = "custom_double",
    CustomDouble2 = "c2_double",
    CustomDouble3 = "c3_double",
    CustomDouble4 = "c4_double",
}

export enum CustomRuleFields {
    CustomString = "custom",
    CustomString2 = "c2",
    CustomString3 = "c3",
    CustomString4 = "c4",
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    CustomDouble = "custom",
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    CustomDouble2 = "c2",
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    CustomDouble3 = "c3",
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    CustomDouble4 = "c4",
}

export const CUSTOM_RULE_ID_TO_FIELD = {
    [CustomRuleIds.CustomString]: CustomRuleFields.CustomString,
    [CustomRuleIds.CustomString2]: CustomRuleFields.CustomString2,
    [CustomRuleIds.CustomString3]: CustomRuleFields.CustomString3,
    [CustomRuleIds.CustomString4]: CustomRuleFields.CustomString4,
    [CustomRuleIds.CustomDouble]: CustomRuleFields.CustomDouble,
    [CustomRuleIds.CustomDouble2]: CustomRuleFields.CustomDouble2,
    [CustomRuleIds.CustomDouble3]: CustomRuleFields.CustomDouble3,
    [CustomRuleIds.CustomDouble4]: CustomRuleFields.CustomDouble4,
} as const;

export const CUSTOM_RULE_ID_TO_INPUT = {
    [CustomRuleIds.CustomString]: "textarea",
    [CustomRuleIds.CustomString2]: "textarea",
    [CustomRuleIds.CustomString3]: "textarea",
    [CustomRuleIds.CustomString4]: "textarea",
    [CustomRuleIds.CustomDouble]: "number",
    [CustomRuleIds.CustomDouble2]: "number",
    [CustomRuleIds.CustomDouble3]: "number",
    [CustomRuleIds.CustomDouble4]: "number",
} as const;

export const CUSTOM_RULE_ID_TO_TYPE = {
    [CustomRuleIds.CustomString]: "string",
    [CustomRuleIds.CustomString2]: "string",
    [CustomRuleIds.CustomString3]: "string",
    [CustomRuleIds.CustomString4]: "string",
    [CustomRuleIds.CustomDouble]: "double",
    [CustomRuleIds.CustomDouble2]: "double",
    [CustomRuleIds.CustomDouble3]: "double",
    [CustomRuleIds.CustomDouble4]: "double",
} as const;

export const CUSTOM_RULE_ID_OPTIONS = [
    { label: "Custom 1 (String)", value: CustomRuleIds.CustomString },
    { label: "Custom 2 (String)", value: CustomRuleIds.CustomString2 },
    { label: "Custom 3 (String)", value: CustomRuleIds.CustomString3 },
    { label: "Custom 4 (String)", value: CustomRuleIds.CustomString4 },
    { label: "Custom 1 (Number)", value: CustomRuleIds.CustomDouble },
    { label: "Custom 2 (Number)", value: CustomRuleIds.CustomDouble2 },
    { label: "Custom 3 (Number)", value: CustomRuleIds.CustomDouble3 },
    { label: "Custom 4 (Number)", value: CustomRuleIds.CustomDouble4 },
];

export const CUSTOM_RULE_ID_LABELS = CUSTOM_RULE_ID_OPTIONS.reduce<{ [value: string]: string }>(
    (acc, { label, value }) => {
        acc[value] = label;
        return acc;
    },
    {}
);

export enum CustomRuleOperators {
    Equal = "equal",
    NotEqual = "not_equal",
    In = "in",
    NotIn = "not_in",
    Less = "less",
    LessOrEqual = "less_or_equal",
    Greater = "greater",
    GreaterOrEqual = "greater_or_equal",
    Between = "between",
    NotBetween = "not_between",
    BeginsWith = "begins_with",
    NotBeginsWith = "not_begins_with",
    Contains = "contains",
    NotContains = "not_contains",
    EndsWith = "ends_with",
    NotEndsWith = "not_ends_with",
    IsEmpty = "is_empty",
    IsNotEmpty = "is_not_empty",
    IsNull = "is_null",
    IsNotNull = "is_not_null",
    IsInList = "is_in_list",
    NotInList = "not_in_list",
    IsMember = "is_member",
    NotIsMember = "not_is_member",
}

const CUSTOM_RULE_OPERATOR_EQUAL_OPTION = { label: "Equals", value: CustomRuleOperators.Equal };
const CUSTOM_RULE_OPERATOR_NOT_EQUAL_OPTION = { label: "Doesn't Equal", value: CustomRuleOperators.NotEqual };
const CUSTOM_RULE_OPERATOR_LESS_OPTION = { label: "Less Than", value: CustomRuleOperators.Less };
const CUSTOM_RULE_OPERATOR_LESS_OR_EQUAL_OPTION = {
    label: "Less Than or Equal To",
    value: CustomRuleOperators.LessOrEqual,
};
const CUSTOM_RULE_OPERATOR_GREATER_OPTION = { label: "Greater Than", value: CustomRuleOperators.Greater };
const CUSTOM_RULE_OPERATOR_GREATER_OR_EQUAL_OPTION = {
    label: "Greater Than or Equal To",
    value: CustomRuleOperators.GreaterOrEqual,
};
const CUSTOM_RULE_OPERATOR_BEGINS_WITH_OPTION = { label: "Begins With", value: CustomRuleOperators.BeginsWith };
const CUSTOM_RULE_OPERATOR_NOT_BEGINS_WITH_OPTION = {
    label: "Doesn't Begin With",
    value: CustomRuleOperators.NotBeginsWith,
};
const CUSTOM_RULE_OPERATOR_CONTAINS_OPTION = { label: "Contains", value: CustomRuleOperators.Contains };
const CUSTOM_RULE_OPERATOR_NOT_CONTAINS_OPTION = { label: "Doesn't Contain", value: CustomRuleOperators.NotContains };
const CUSTOM_RULE_OPERATOR_ENDS_WITH_OPTION = { label: "Ends With", value: CustomRuleOperators.EndsWith };
const CUSTOM_RULE_OPERATOR_NOT_ENDS_WITH_OPTION = { label: "Doesn't End With", value: CustomRuleOperators.NotEndsWith };
const CUSTOM_RULE_OPERATOR_IS_NULL_OPTION = { label: "Is Null", value: CustomRuleOperators.IsNull };
const CUSTOM_RULE_OPERATOR_IS_NOT_NULL_OPTION = { label: "Isn't Null", value: CustomRuleOperators.IsNotNull };
const CUSTOM_RULE_OPERATOR_IS_IN_LIST_OPTION = { label: "Is In List", value: CustomRuleOperators.IsInList };
const CUSTOM_RULE_OPERATOR_NOT_IN_LIST_OPTION = { label: "Not In List", value: CustomRuleOperators.NotInList };

export const CUSTOM_RULE_STRING_OPERATOR_OPTIONS = [
    CUSTOM_RULE_OPERATOR_EQUAL_OPTION,
    CUSTOM_RULE_OPERATOR_NOT_EQUAL_OPTION,
    CUSTOM_RULE_OPERATOR_BEGINS_WITH_OPTION,
    CUSTOM_RULE_OPERATOR_NOT_BEGINS_WITH_OPTION,
    CUSTOM_RULE_OPERATOR_CONTAINS_OPTION,
    CUSTOM_RULE_OPERATOR_NOT_CONTAINS_OPTION,
    CUSTOM_RULE_OPERATOR_ENDS_WITH_OPTION,
    CUSTOM_RULE_OPERATOR_NOT_ENDS_WITH_OPTION,
    CUSTOM_RULE_OPERATOR_IS_IN_LIST_OPTION,
    CUSTOM_RULE_OPERATOR_NOT_IN_LIST_OPTION,
    CUSTOM_RULE_OPERATOR_IS_NULL_OPTION,
    CUSTOM_RULE_OPERATOR_IS_NOT_NULL_OPTION,
];

export const CUSTOM_RULE_STRING_OPERATOR_LABELS = CUSTOM_RULE_STRING_OPERATOR_OPTIONS.reduce<{
    [value: string]: string;
}>((acc, { label, value }) => {
    acc[value] = label;
    return acc;
}, {});

export const CUSTOM_RULE_DOUBLE_OPERATOR_OPTIONS = [
    CUSTOM_RULE_OPERATOR_EQUAL_OPTION,
    CUSTOM_RULE_OPERATOR_NOT_EQUAL_OPTION,
    CUSTOM_RULE_OPERATOR_LESS_OPTION,
    CUSTOM_RULE_OPERATOR_LESS_OR_EQUAL_OPTION,
    CUSTOM_RULE_OPERATOR_GREATER_OPTION,
    CUSTOM_RULE_OPERATOR_GREATER_OR_EQUAL_OPTION,
    CUSTOM_RULE_OPERATOR_IS_NULL_OPTION,
    CUSTOM_RULE_OPERATOR_IS_NOT_NULL_OPTION,
];

export const CUSTOM_RULE_DOUBLE_OPERATOR_LABELS = CUSTOM_RULE_DOUBLE_OPERATOR_OPTIONS.reduce<{
    [value: string]: string;
}>((acc, { label, value }) => {
    acc[value] = label;
    return acc;
}, {});

export const DAY_PARTING_COLUMN_HEADERS = [
    "Time/Day",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
];

export const HALF_HOURS_COUNT = 48;

export enum TimeZoneModeLabels {
    UTC = "UTC",
    ClientTimeZone = "Client Time Zone",
}

export enum TimeZoneModeIds {
    UTC = "2",
    ClientTimeZone = "3",
}

export const TIME_ZONE_MODE_OPTIONS = [
    { label: TimeZoneModeLabels.UTC, value: TimeZoneModeIds.UTC },
    { label: TimeZoneModeLabels.ClientTimeZone, value: TimeZoneModeIds.ClientTimeZone },
];

export const TARGETING_MODE_PAYLOAD_MERGE = { id: 1, name: "MERGE" } as const;
export const TARGETING_MODE_PAYLOAD_OR = { id: 2, name: "OR" } as const;
