import { FC } from "react";
import { SectionTitle } from "@app/core/components";
import { AdvancedFloor, Targeting } from "@app/core/services";
import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM, NONE } from "@app/core/components/constants";
import { getFloorPriceLabel } from "@app/features/inventory/InventoryFloors/helpers";
import { classNameCardBody } from "@app/features/inventory/InventoryHealthDetailsPages/constants";
import { mergeTargeting } from "@app/features/targeting/ReadOnlyTargeting/helpers";
import { ReadOnlyInvnetoryAdvancedFloorsTargeting } from "@app/features/targeting/ReadOnlyTargeting/ReadOnlyInvnetoryAdvancedFloorsTargeting";
import { Form, Collapse, Card } from "antd";
import { ADVANCED_FLOOR_LABELS, ADVANCED_FLOOR_SECTION_NAMES } from "./constants";
import { HelpKeysButton } from "./HelpKeysButton";
import moment from "moment-timezone";

interface Props {
    advancedFloor?: AdvancedFloor;
}

export const SeatFloorsAdvancedDetails: FC<Props> = ({ advancedFloor }) => {
    return (
        <Form layout="vertical">
            <Collapse defaultActiveKey={[0, 1]} bordered collapsible="header">
                <Collapse.Panel
                    header={<SectionTitle title={ADVANCED_FLOOR_SECTION_NAMES.GENERAL} />}
                    key="0"
                    extra={
                        <HelpKeysButton
                            label={ADVANCED_FLOOR_SECTION_NAMES.GENERAL}
                            sectionKey={ADVANCED_FLOOR_SECTION_NAMES.GENERAL}
                        />
                    }
                >
                    <Card bordered={false} className={classNameCardBody}>
                        <Form.Item label={ADVANCED_FLOOR_LABELS.floorName}>{advancedFloor?.name}</Form.Item>
                        <Form.Item label={ADVANCED_FLOOR_LABELS.floorPrice}>
                            {getFloorPriceLabel(advancedFloor as AdvancedFloor)}
                        </Form.Item>
                        <Form.Item label={ADVANCED_FLOOR_LABELS.lastUpdate}>
                            {moment(advancedFloor?.updateDate).format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM) || NONE}
                        </Form.Item>
                        <Form.Item label={ADVANCED_FLOOR_LABELS.dateCreated}>
                            {moment(advancedFloor?.creationDate).format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM) || NONE}
                        </Form.Item>
                    </Card>
                </Collapse.Panel>
                <Collapse.Panel
                    header={<SectionTitle title="Targeting" />}
                    key="1"
                    extra={
                        <HelpKeysButton
                            label={ADVANCED_FLOOR_SECTION_NAMES.TARGETING}
                            sectionKey={ADVANCED_FLOOR_SECTION_NAMES.TARGETING}
                        />
                    }
                >
                    <Card bordered={false} className={classNameCardBody}>
                        <ReadOnlyInvnetoryAdvancedFloorsTargeting
                            targetingBlocks={mergeTargeting(
                                advancedFloor?.targeting as Targeting[],
                                advancedFloor?.targetingMode.id as number
                            )}
                            targetingModeId={Number(advancedFloor?.targetingMode.id)}
                            dealsWithTargeting={0}
                        />
                    </Card>
                </Collapse.Panel>
            </Collapse>
        </Form>
    );
};
