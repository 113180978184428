import { PAGE, SIZE, useNotificationError, usePagination, useTableChanges } from "@app/core/components/hooks";
import { useGetDeletedEntititesQuery, useRestoreEntityMutation } from "@app/core/services";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ENTITY_TYPE, ENTITY_TYPES_QUERY_MAP, SEARCH } from "../constants";
import { uri } from "@rubicon/utils";
import { selectSelectedEntities, pickSelected } from "../reducer";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { message, notification } from "antd";
import { useEffect } from "react";

export const useRecycleBinTable = () => {
    const { search } = useLocation();
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const selectedBy = useAppSelector(selectSelectedEntities);

    const entity = uri.getSearchParam(search, ENTITY_TYPE.key) || ENTITY_TYPE.default;
    const keyword = uri.getSearchParam(search, SEARCH.key) || SEARCH.default;
    const hasTypeId = Boolean(ENTITY_TYPES_QUERY_MAP[entity]?.typeId);
    const page = Number(new URLSearchParams(search).get(PAGE.key)) || Number(PAGE.default);
    const maxRows = Number(new URLSearchParams(search).get(SIZE.key)) || Number(SIZE.default);
    const requestParams = {
        id: Number(seatId),
        entity: ENTITY_TYPES_QUERY_MAP[entity]?.entity,
        keyword,
        max: maxRows,
        page,
        ...(hasTypeId && { typeId: ENTITY_TYPES_QUERY_MAP[entity]?.typeId }),
    };
    const [restoreEntity, { isLoading: isUpdating }] = useRestoreEntityMutation();
    const { data, isFetching, error, refetch } = useGetDeletedEntititesQuery(requestParams);
    const isLoading = isFetching || isUpdating;
    useNotificationError(error);

    // since totalCount is based on page and size and we  don't have any pagination metadata, need to reset page
    useEffect(() => {
        if (page !== 1) {
            const nextSearchPage = uri.setSearchParam(search, PAGE.key, PAGE.default);
            history.push({ search: nextSearchPage });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keyword, maxRows]);
    // Faked to only ever show next page since we don't have any pagination metadata
    const totalCount = page * maxRows + (data?.length === maxRows ? 1 : 0);

    const handleRestoreClick = () => {
        const requests = [] as unknown[];
        selectedBy[entity].forEach((id) => {
            requests.push(restoreEntity({ entity: ENTITY_TYPES_QUERY_MAP[entity].entity, id }).unwrap());
        });
        Promise.all(requests)
            .then(() => {
                message.success("Successfully Restored");
                dispatch(pickSelected({ entity, ids: [] }));
            })
            .catch((error) => {
                notification.error({ message: error.data?.errorDescription });
            });
    };

    const { handleChange, paginationConfig } = usePagination({
        totalCount,
        showTotal: false,
    });
    const { onChange: handleTableChange } = useTableChanges({
        handlePaginateChange: handleChange,
    });

    const handleChangeCheckbox = (entityIds: number[]) => dispatch(pickSelected({ entity, ids: entityIds }));

    return {
        isLoading,
        data,
        entity,
        search,
        selectedBy,
        paginationConfig,
        handleTableChange,
        handleRefreshClick: refetch,
        handleRestoreClick,
        handleChangeCheckbox,
    };
};
