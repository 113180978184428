import { Layout } from "antd";
import { FC } from "react";
import { Footer } from "@app/core/components";
import { useParams } from "react-router-dom";
import { PageLoader } from "@rubicon/antd-components";
import { useGetSupplyQuery } from "@app/core/services";
import { SupplyForm } from "@app/features/inventory/HierarchyForms/SupplyForm";
import { SupplyFormInstance } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormInstance";
import { SupplyCopyPageHeader } from "@app/features/inventory/HierarchyFormsPages/SupplyPages/SupplyCopyPage/SupplyCopyPageHeader";
import { useScrollToTopOnMount } from "@app/core/components/hooks";

export const SupplyCopyPage: FC = () => {
    useScrollToTopOnMount();
    const { supplyId } = useParams<{ supplyId: string }>();
    const { data, isFetching } = useGetSupplyQuery(Number(supplyId));

    if (isFetching || !data) return <PageLoader />;

    return (
        <>
            <SupplyCopyPageHeader />
            <Layout.Content>
                <SupplyFormInstance mode="copy" supply={data}>
                    <SupplyForm />
                </SupplyFormInstance>
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
