import { FC } from "react";
import { Collapse, Col, Row, Button, Space } from "antd";
import { TagParamsFrequencyChart } from "./TagParamsFrequencyChart";
import { useTagParams } from "./useTagParams";
import DOMPurify from "dompurify";
import { ErrorContainer } from "@app/core/components";

interface Props {
    id: string | number;
}
export const TagParams: FC<Props> = ({ id }) => {
    const { chartOptions, activeKeys, keys, samples, error, handleExpandAll, handleCollapseAll, handleKeyChange } =
        useTagParams(id);

    return (
        <>
            <ErrorContainer error={error}>
                <TagParamsFrequencyChart chartOptions={chartOptions} />
            </ErrorContainer>
            <Row gutter={[16, 16]} style={{ marginTop: "2rem" }}>
                <Col span={24}>
                    <Space>
                        <Button onClick={handleExpandAll}>Expand All</Button>
                        <Button onClick={handleCollapseAll}>Collapse All</Button>
                    </Space>
                </Col>
                {keys.map((key) => (
                    <Col span={12} key={key}>
                        <Collapse
                            activeKey={activeKeys}
                            onChange={handleKeyChange}
                            style={{ overflowWrap: "break-word" }}
                        >
                            <Collapse.Panel key={key} header={key}>
                                {Object.values(samples[key] || []).map((value, index) => (
                                    <p
                                        key={index}
                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value as string) }}
                                    />
                                ))}
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                ))}
            </Row>
        </>
    );
};
