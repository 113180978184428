import { FC } from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { NotificationAction } from "@app/core/services";
import { isViewLadleResultsAction, getParamsFromViewLadleResultsDetails } from "./helpers";
import { ROUTE_FORMATTERS } from "@app/core/routing";

interface Props {
    onClick: () => void;
    action: NotificationAction;
}

export const ViewLadleResultsButton: FC<Props> = ({ action, onClick }) => {
    if (!isViewLadleResultsAction(action.details)) {
        return null;
    }

    const params = getParamsFromViewLadleResultsDetails(action.details);

    if (!params.seatId) {
        return null;
    }

    return (
        <Button size="small" onClick={onClick}>
            <Link to={ROUTE_FORMATTERS.SEAT_DIAGNOSTICS_LADLE_RESULTS(params.seatId, params.ladleName)}>
                {action.name}
            </Link>
        </Button>
    );
};
