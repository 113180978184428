import { FC } from "react";
import { BuyerDealDetailsForm } from "@app/features/deals/DealDetailsPage/BuyerDealDetailsForm";
import { LegacyDealDetailsForm } from "@app/features/deals/DealDetailsPage/LegacyDealDetailsForm";
import { DealList } from "@app/core/services/console";

interface Props {
    dealAdSource: DealList;
    isInsideDrawer?: boolean;
}

export const DealDetailsContent: FC<Props> = ({ dealAdSource, isInsideDrawer = false }) => {
    if (!dealAdSource || !dealAdSource.deal) {
        return null;
    }
    if (dealAdSource.deal.entityType === "Deal") {
        return <LegacyDealDetailsForm dealAdSource={dealAdSource} />;
    }
    return <BuyerDealDetailsForm isInsideDrawer={isInsideDrawer} dealAdSource={dealAdSource} />;
};
