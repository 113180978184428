import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM } from "@app/core/components/constants";
import { format } from "@rubicon/utils";
import moment from "moment-timezone";

interface UseAdSourceStartDateField {
    value: string;
}

export const getStartDateValue = (value?: string | null): string => {
    if (value) {
        return moment(value).format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM);
    }
    return format.constants.DEFAULT_DASHES;
};

export const useAdSourceStartDateField = (): UseAdSourceStartDateField => {
    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_START_DATE]
    );
    return {
        value: getStartDateValue(value),
    };
};
