import { createContext, useState } from "react";
import { Badge, Card, Layout, Space } from "antd";
import { SeatTree } from "../SeatTree/SeatTree";
import { InventoryFloorHierarchicalPageSwitch } from "./InventoryFloorHierarchicalPageSwitch";
import { InventorySearch } from "../InventorySearch";
import { css } from "@emotion/css";

const { Sider, Content } = Layout;

interface SeatTreeNavigationContextType {
    expandedKeys: string[];
    selectedKeys: string[];
    expandKeys: (keys: string[]) => void;
    setSelectedKeys: (keys: string[]) => void;
    foldKey: (key: string) => void;
}

const contentClasses = css`
    .ant-typography {
        margin-top: 0;
    }
`;

export const SeatTreeNavigationContext = createContext<SeatTreeNavigationContextType | null>(null);

const InventoryFloorHierarchicalPage = () => {
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    const expandKeys = (keys: string[]) => {
        setExpandedKeys((prev) => {
            return [...prev, ...keys];
        });
    };

    const foldKey = (key: string) => {
        setExpandedKeys((prev) => {
            return [...prev].filter((v) => v !== key);
        });
    };

    return (
        <SeatTreeNavigationContext.Provider
            value={{ expandedKeys, selectedKeys, expandKeys, setSelectedKeys, foldKey }}
        >
            <Layout style={{ flexDirection: "row" }}>
                <Sider width="300">
                    <Card style={{ height: "100%" }}>
                        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                            <InventorySearch />
                            <Badge color="#6395F9" text="Custom floor price set at given level" />
                            <SeatTree />
                        </Space>
                    </Card>
                </Sider>
                <Content className={contentClasses}>
                    <InventoryFloorHierarchicalPageSwitch />
                </Content>
            </Layout>
        </SeatTreeNavigationContext.Provider>
    );
};

export default InventoryFloorHierarchicalPage;
