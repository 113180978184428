import { FC, useMemo } from "react";
import { DualAxesOptions, StyleAttr } from "@antv/g2plot";
import { formatNumber } from "@rubicon/utils";
import {
    DualAxesChartProps,
    getMaxValue1,
    getMaxValue2,
} from "@app/core/components/charts/DualAxesChart/DualAxesCountLineCurrencyLineChart";
import { useCurrencyConversion } from "@app/features/dashboard/useCurrencyConversion";
import { DualAxesChart } from "@app/core/components/charts/DualAxesChart/DualAxesChart";
import { DEFAULT_COLOR, LABEL_COLORS } from "@app/core/components/charts/constants";

interface DualAxesCountBarRateLineChartProps extends DualAxesChartProps {
    options: {
        /**
         * Defines which metrics are currency values
         */
        currencyMetricLabels?: string[];
        /**
         * Function to provide different line style for the given label.
         * If no line style function is provided, or if it returns a falsy value for the given label,
         * the default line style will be given.
         */
        lineStyles?: (label: string) => StyleAttr;
    };
}

/**
 * Dual Axes chart with count metrics in @param metricOne represented as a bar and rate metrics in @param metricTwo represented as a line.
 * Count metrics are raw numbers eg: impressions
 * Rate metrics are numbers between 0 and 100, a percentage eg Use Rate
 */
export const DualAxesCountBarRateLineChart: FC<DualAxesCountBarRateLineChartProps> = ({
    metricOne,
    metricTwo,
    chartId = "historical-chart",
    options: { lineStyles },
}) => {
    const { preferredCurrency } = useCurrencyConversion();

    const config = useMemo<DualAxesOptions>(
        () => ({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data: [metricOne, metricTwo],
            height: 400,
            xField: "time",
            yField: ["value1", "value2"],
            legend: false,
            tooltip: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                formatter: (data) => {
                    if (data.value1) {
                        return {
                            name: data.name1,
                            value:
                                data.name1 === "Net Revenue"
                                    ? formatNumber.asMoneyAbbreviated(data.value1, preferredCurrency?.code)
                                    : formatNumber.asAbbreviated(data.value1),
                        };
                    } else if (data.value2) {
                        return {
                            name: data.name2,
                            value: formatNumber.asPercent(data.value2),
                        };
                    }
                },
            },
            yAxis: {
                value1: {
                    top: true,
                    min: 0,
                    max: getMaxValue1(metricOne),
                    tickCount: 8,
                    title: {
                        text: "Event Count",
                    },
                    label: {
                        formatter: (v) => formatNumber.asAbbreviated(v),
                    },
                },
                value2: {
                    top: true,
                    min: 0,
                    max: getMaxValue2(metricTwo),
                    tickCount: 8,
                    title: {
                        text: "Rate",
                    },
                    label: {
                        formatter: (v) => formatNumber.asPercent(v, 2),
                    },
                },
            },
            geometryOptions: [
                {
                    geometry: "column",
                    isGroup: true,
                    seriesField: "name1",
                    color: (d) => LABEL_COLORS[d.name1] || DEFAULT_COLOR,
                },
                {
                    geometry: "line",
                    seriesField: "name2",
                    color: (d) => LABEL_COLORS[d.name2] || DEFAULT_COLOR,
                    lineStyle: ({ name2 }) => (lineStyles && lineStyles(name2)) || { opacity: 1 },
                },
            ],
        }),
        [lineStyles, metricOne, metricTwo, preferredCurrency?.code]
    );
    return DualAxesChart({ chartId, config });
};
