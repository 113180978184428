import { FC } from "react";
import { LabeledValue } from "antd/lib/select";
import { PlusOutlined } from "@ant-design/icons";
import { ResizableDrawer } from "@rubicon/antd-components";
import { Button, Form, FormInstance, Input, Row, Col, Space } from "antd";
import { IabCategorieTree, Industries } from "@app/features/inventory/components/FormItems";

export const SEPARATION_GROUPS_FORM_FIELDS = {
    NAME: {
        name: "name",
        label: "Group Name",
        placeholder: "Enter Group Name",
        rulesMessage: "Group Name is required",
    },
    MODE: {
        label: "Mode",
    },
    IAB_CATEGORIES: {
        name: "iabCategories",
        label: "IAB Categories",
        placeholder: "Select Categories...",
        rulesMessage: "IAB Categories is required",
    },
    INDUSTRIES: {
        name: "industries",
        label: "Industries",
        placeholder: "Select Industries...",
        rulesMessage: "Industries is required",
    },
} as const;

export interface SeparationGroupsForm {
    [SEPARATION_GROUPS_FORM_FIELDS.NAME.name]: string;
    [SEPARATION_GROUPS_FORM_FIELDS.IAB_CATEGORIES.name]: LabeledValue[];
    [SEPARATION_GROUPS_FORM_FIELDS.INDUSTRIES.name]: LabeledValue[];
}

const initialValues: SeparationGroupsForm = {
    [SEPARATION_GROUPS_FORM_FIELDS.NAME.name]: "",
    [SEPARATION_GROUPS_FORM_FIELDS.IAB_CATEGORIES.name]: [],
    [SEPARATION_GROUPS_FORM_FIELDS.INDUSTRIES.name]: [],
};

interface AdUnitSeparationGroupFormDrawerProps {
    open: boolean;
    isLoading: boolean;
    onClose: () => void;
    handleSubmit: () => void;
    isIndustriesMode: boolean;
    handleAddNewGroup: () => void;
    onFinish: (values: SeparationGroupsForm) => void;
    separationGroupForm: FormInstance<SeparationGroupsForm>;
}

export const AdUnitSeparationGroupFormDrawer: FC<AdUnitSeparationGroupFormDrawerProps> = ({
    open,
    onClose,
    onFinish,
    isLoading,
    handleSubmit,
    isIndustriesMode,
    handleAddNewGroup,
    separationGroupForm,
}) => {
    return (
        <>
            <Button type="link" icon={<PlusOutlined />} onClick={handleAddNewGroup} style={{ marginTop: "24px" }}>
                New Group
            </Button>
            <ResizableDrawer
                width="30%"
                open={open}
                placement="right"
                onClose={onClose}
                styles={{ body: { padding: "0.75rem 1.5rem" } }}
                title="Create Competitive Separation Group"
                footer={[
                    <Row justify="end" key="SeparationGroupButtons">
                        <Col>
                            <Space>
                                <Button onClick={onClose} disabled={isLoading}>
                                    Cancel
                                </Button>
                                <Button type="primary" onClick={handleSubmit} loading={isLoading}>
                                    Create Group
                                </Button>
                            </Space>
                        </Col>
                    </Row>,
                ]}
            >
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    form={separationGroupForm}
                    name="separationGroupsForm"
                    initialValues={initialValues}
                    validateTrigger={["onChange", "onBlur"]}
                >
                    <Form.Item
                        name={SEPARATION_GROUPS_FORM_FIELDS.NAME.name}
                        label={SEPARATION_GROUPS_FORM_FIELDS.NAME.label}
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: SEPARATION_GROUPS_FORM_FIELDS.NAME.rulesMessage,
                            },
                        ]}
                    >
                        <Input placeholder={SEPARATION_GROUPS_FORM_FIELDS.NAME.placeholder} allowClear />
                    </Form.Item>
                    <Form.Item label={SEPARATION_GROUPS_FORM_FIELDS.MODE.label}>
                        {isIndustriesMode
                            ? SEPARATION_GROUPS_FORM_FIELDS.INDUSTRIES.label
                            : SEPARATION_GROUPS_FORM_FIELDS.IAB_CATEGORIES.label}
                    </Form.Item>
                    {isIndustriesMode ? (
                        <Industries
                            rules={[
                                {
                                    required: true,
                                    validator(_, value) {
                                        if (!value.length)
                                            return Promise.reject(
                                                new Error(SEPARATION_GROUPS_FORM_FIELDS.INDUSTRIES.rulesMessage)
                                            );
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                            name={SEPARATION_GROUPS_FORM_FIELDS.INDUSTRIES.name}
                            label={SEPARATION_GROUPS_FORM_FIELDS.INDUSTRIES.label}
                            placeholder={SEPARATION_GROUPS_FORM_FIELDS.INDUSTRIES.placeholder}
                        />
                    ) : (
                        <IabCategorieTree
                            rules={[
                                {
                                    required: true,
                                    validator(_, value) {
                                        if (!value.length)
                                            return Promise.reject(
                                                new Error(SEPARATION_GROUPS_FORM_FIELDS.IAB_CATEGORIES.rulesMessage)
                                            );
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                            name={SEPARATION_GROUPS_FORM_FIELDS.IAB_CATEGORIES.name}
                            label={SEPARATION_GROUPS_FORM_FIELDS.IAB_CATEGORIES.label}
                            placeholder={SEPARATION_GROUPS_FORM_FIELDS.IAB_CATEGORIES.placeholder}
                        />
                    )}
                </Form>
            </ResizableDrawer>
        </>
    );
};
