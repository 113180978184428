import { Col, Row, Table, TableColumnsType, Typography } from "antd";
import { FC } from "react";

export interface ErrorDefinition {
    code: number;
    description: string;
}

interface Props {
    data: ErrorDefinition[];
}

export const ErrorDefinition: FC<Props> = ({ data }) => {
    const columns: TableColumnsType = [
        {
            title: "Error Code",
            dataIndex: "code",
            key: "code",
            sorter: (a: ErrorDefinition, b: ErrorDefinition) => a.code - b.code,
            width: "25%",
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            sorter: false,
        },
    ];
    return (
        <>
            <Row>
                <Col>
                    <Typography.Title level={5}>Video Errors Displayed in Query Tool Definition</Typography.Title>
                </Col>
            </Row>
            <Table
                style={{ marginBottom: "16px" }}
                size="small"
                pagination={false}
                columns={columns}
                dataSource={data}
            />
        </>
    );
};
