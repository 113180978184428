import { FrequencyCaps } from "@app/features/inventory/components/Fields";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const AdvertiserDomainFrequencyCaps = () => {
    return (
        <FrequencyCaps
            label={AD_UNIT_FORM_FIELDS.ADV_DOMAIN_FREQ_CAPPS.label}
            name={AD_UNIT_FORM_FIELDS.ADV_DOMAIN_FREQ_CAPPS.name}
        />
    );
};
