import { PERMISSIONS_ENTITY_TYPES } from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import { InventoryDetailsDrawerType, selectInventoryDetailsDrawerType } from "../../reducer";
import { useNestedData } from "../useNestedData";

interface UsePermissionsContent {
    permissionsEntityType: PERMISSIONS_ENTITY_TYPES;
    isLoading: boolean;
    breadcrumbs?: string;
}

export const usePermissionsContent = (id: string | number): UsePermissionsContent => {
    const { nestedBreadcrumbs, isLoading } = useNestedData(id);
    const drawerType = useAppSelector(selectInventoryDetailsDrawerType) as InventoryDetailsDrawerType;
    let permissionsEntityType: PERMISSIONS_ENTITY_TYPES = PERMISSIONS_ENTITY_TYPES.SEAT;

    switch (drawerType) {
        case InventoryDetailsDrawerType.SEAT:
            permissionsEntityType = PERMISSIONS_ENTITY_TYPES.SEAT;
            break;
        case InventoryDetailsDrawerType.PUBLISHER:
        case InventoryDetailsDrawerType.CHANNEL:
            permissionsEntityType = PERMISSIONS_ENTITY_TYPES.PUBLISHER;
            break;
    }

    return {
        permissionsEntityType,
        isLoading,
        breadcrumbs: nestedBreadcrumbs,
    };
};
