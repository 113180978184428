import { ReactNode } from "react";
import { SeatAccessFlagsTypes } from "@app/core/auth";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Icons } from "@rubicon/antd-components";
import { History } from "history";
import { NavMenuItemProps } from "../Nav";

interface MenuItems {
    key: string;
    label: string;
    icon: ReactNode;
    route?: string;
}

export const getMenuItems = (buyerId: number): MenuItems[] => {
    return [
        {
            key: "notifications",
            label: "Notifications",
            icon: <Icons.NotificationsIcon />,
        },
        {
            key: "dashboard",
            label: "Dashboard",
            icon: <Icons.DashboardIcon />,
            route: ROUTE_FORMATTERS.BUYER_DASHBOARD(buyerId),
        },
        {
            key: "deal-dashboard",
            label: "Deal Dashboard",
            icon: <Icons.PerformanceIcon />,
            route: ROUTE_FORMATTERS.BUYER_DEAL_DASHBOARD(buyerId),
        },
        {
            key: "ad-campaigns",
            label: "Ad Campaigns",
            icon: <Icons.CampaignsIcon />,
            route: ROUTE_FORMATTERS.BUYER_DEAL_AD_CAMPAIGNS(buyerId),
        },
        {
            key: "transparency",
            label: "Transparency",
            icon: <Icons.TransparencyIcon />,
            route: ROUTE_FORMATTERS.BUYER_DEAL_TRANSPARENCY(buyerId),
        },
        {
            key: "diagnostics",
            label: "Diagnostics",
            icon: <Icons.DiagnosticsIcon />,
            route: ROUTE_FORMATTERS.BUYER_DIAGNOSTICS(buyerId),
        },
        {
            key: "reports",
            label: "Reports",
            icon: <Icons.ReportingIcon />,
            route: ROUTE_FORMATTERS.BUYER_REPORTS(buyerId),
        },
        {
            key: "discounts",
            label: "Discounts",
            icon: <Icons.DiscountIcon />,
            route: ROUTE_FORMATTERS.BUYER_DISCOUNTS(buyerId),
        },
    ];
};

export const getMoreMenuItems = (
    { hasDealLibraryAccess }: SeatAccessFlagsTypes,
    buyerId: string | number | null | undefined,
    history: History,
    handleMenuClose: () => void,
    openHelpCenter: (openInNewWindow: boolean, isSeat: boolean) => Promise<unknown>
): NavMenuItemProps[] =>
    [
        hasDealLibraryAccess && {
            id: "deal-library",
            title: "Deal Library",
            description:
                "The Deal Library is a collection curated deals that we can offer to buyers through Magnite Marketplaces",
            onClick: () => {
                handleMenuClose();
                if (buyerId) {
                    history.push(ROUTE_FORMATTERS.BUYER_DEAL_LIBRARY(buyerId));
                }
            },
        },
        {
            id: "help-center",
            title: "Help Center",
            description: "Find product documentation, developer resources, and platform information.",
            onClick: () => {
                openHelpCenter(true, false).finally(handleMenuClose);
            },
        },
    ].filter(Boolean) as NavMenuItemProps[];
