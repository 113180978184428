import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Floor, useGetPublisherFloorsQuery } from "@app/core/services";
import { compareFloorsByPriority } from "../helpers";

interface UsePublisherFloorsTable {
    floors?: Array<Floor>;
    isFetching: boolean;
    publisherId: string;
}

export const usePublisherFloorsTable = (): UsePublisherFloorsTable => {
    const { publisherId } = useParams<{ publisherId: string }>();
    const { data, isFetching } = useGetPublisherFloorsQuery({ publisherId: Number(publisherId), list: "all" });

    const floors = useMemo(() => {
        return [...(data || [])].sort(compareFloorsByPriority);
    }, [data]);

    return {
        floors,
        isFetching,
        publisherId,
    };
};
