import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useCreateSeatContactMutation } from "@app/core/services";
import { Layout, Typography, notification } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { SeatContactsForm } from "../SeatContactsForm/SeatContactsForm";
import { SEAT_CONTACT_CREATE_FORM_INITIAL_VALUES } from "../SeatContactsForm/constants";
import { SeatContactPayload } from "../types";
import { PageHeader } from "@ant-design/pro-layout";

export const SEAT_CONTACTS_CREATE_PAGE_SDET = "seat-contacts-create-page";
export const SeatContactsCreatePage = () => {
    const history = useHistory();
    const [createSeatContact, { isLoading }] = useCreateSeatContactMutation();
    const { seatId } = useParams<{ seatId: string }>();

    const handleSubmit = (values: SeatContactPayload) => {
        const payload = {
            ...values,
            name: values?.name?.trim(),
        };
        createSeatContact({ seatId, body: payload })
            .unwrap()
            .then((res) => {
                notification.success({
                    message: (
                        <>
                            <Typography.Text strong>{res.name}</Typography.Text> has successfully been created
                        </>
                    ),
                });
                history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_SEAT_CONTACT_DETAILS_PAGE(seatId, res.id));
            })
            .catch((err) => {
                notification.error({
                    message:
                        err.data?.errorDescription ||
                        err.data?.errorCode ||
                        "Something wrong has occurred, please contact your account manager",
                });
            });
    };

    return (
        <Layout data-sdet={SEAT_CONTACTS_CREATE_PAGE_SDET}>
            <PageHeader
                title="Create New Contact"
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, "seat-contacts"))}
            />
            <SeatContactsForm
                isLoading={isLoading}
                initialValues={SEAT_CONTACT_CREATE_FORM_INITIAL_VALUES}
                handleSubmit={handleSubmit}
            />
        </Layout>
    );
};
