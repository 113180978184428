import { useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { useLoadOnScroll } from "@app/core/components";
import { useSearchQuery, SearchItem } from "@app/core/services";
import { notification } from "antd";
import { useAuthClient } from "@app/core/authClient";

const MAX = 50;

const userToLabelValue = (user: { id: number; emailAddress?: string; name: string }) => ({
    label: `${user.name} [${"emailAddress" in user ? user.emailAddress : ""}]`,
    value: JSON.stringify({
        id: user.id,
        emailAddress: "emailAddress" in user ? user.emailAddress : "",
        name: user.name,
    }),
});

const usersToLabelValue = (users: SearchItem[]) => users.map(userToLabelValue);

export const useRunAsUserSelect = (handleMenuClose: () => void) => {
    const { session, runAsUser } = useAuthClient();
    const [search, setSearch] = useState("");
    const [keyword, setKeyword] = useState("");
    const [pageByKeyword, setPageByKeyword] = useState({
        "": 1,
    });
    const params = {
        keyword,
        max: MAX,
        page: pageByKeyword[keyword] || 1,
        type: "user",
    };

    const { data, isFetching, originalArgs } = useSearchQuery(params);
    const {
        options: rawOptions,
        hasMore,
        loadMore,
    } = useLoadOnScroll(
        data,
        isFetching,
        originalArgs?.keyword || "",
        originalArgs?.page || 1,
        data?.length === MAX,
        () =>
            setPageByKeyword((prev) => {
                const currentPage = prev[originalArgs?.keyword || ""] || 1;
                return {
                    ...prev,
                    [originalArgs?.keyword || ""]: currentPage + 1,
                };
            })
    );

    const options = useMemo(() => usersToLabelValue(rawOptions || []), [rawOptions]);
    const debouncedHandleSearch = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        []
    );
    const handleChangeSearch = (search: string): void => {
        setSearch(search);
        debouncedHandleSearch(search);
    };
    const handleChange = (userStr: string): void => {
        const user = JSON.parse(userStr);

        if (session?.sessionCode) {
            runAsUser(user, session.sessionCode)
                .then(() => {
                    handleMenuClose();
                })
                .catch((error) => {
                    notification.error({
                        message: "Run as User Error",
                        description: error?.data?.errorDescription || "Something went wrong.",
                    });
                });
        }
    };

    return {
        handleChange,
        handleChangeSearch,
        hasMore,
        isFetching,
        loadMore,
        options,
        search,
    };
};
