import { MarketplaceInfo } from "@app/core/services";
import { ReadonlyControl, SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { Form } from "antd";
import { FC } from "react";
import { DEAL_FORM_FIELDS } from "../../../../constants";
import { useTransactionCurrencySelect } from "./useTransactionCurrencySelect";

export const TransactionCurrencySelect: FC = () => {
    const { currencies, isLoading, isReadonly, isDisabled } = useTransactionCurrencySelect();
    return (
        <Form.Item name={DEAL_FORM_FIELDS.CURRENCY_TYPE.name} label={DEAL_FORM_FIELDS.CURRENCY_TYPE.label}>
            {isReadonly ? (
                <ReadonlyControl<MarketplaceInfo["currencyType"]> getLabel={(currency) => currency?.code} />
            ) : (
                <SelectWithValue
                    disabled={isDisabled}
                    fieldAsLabel={["code"]}
                    fieldAsValue={["id"]}
                    loading={isLoading}
                    optionFilterProp="label" // For internal LabelValue options
                    options={currencies}
                    placeholder="Select…"
                    showSearch
                />
            )}
        </Form.Item>
    );
};
