import { useUserAccess } from "@app/core/auth";
import { useSupplyFormInstance } from "../../SupplyFormInstance";

interface UseGeneralSection {
    isInternalOnly: boolean;
    isEditMode: boolean;
    hasCode: boolean;
}

export const useGeneralSection = (): UseGeneralSection => {
    const { isPubAcctMgr, isSysAdmin, hasSeatWriteAccess } = useUserAccess();
    const { mode } = useSupplyFormInstance();
    const isInternalOnly: boolean = isPubAcctMgr || isSysAdmin;
    const isCreateMode: boolean = mode === "create";
    const isCopyMode: boolean = mode === "copy";
    const isEditMode: boolean = mode === "edit";

    const hasCode = (hasSeatWriteAccess && (isCreateMode || isCopyMode)) || !isInternalOnly;
    return { isInternalOnly, isEditMode, hasCode };
};
