import { FC, useMemo } from "react";
import { Loading } from "@app/core/components";
import { ResizableDrawer } from "@rubicon/antd-components";
import { ContentTransparencyRulesDrawerTitle } from "./ContentTransparencyRulesDrawerTitle";
import { useContentTransparencyRulesDrawer } from "./useContentTransparencyRulesDrawer";
import { useLocation } from "react-router-dom";
import { uri } from "@rubicon/utils";
import { HelperKeysDrawerBreadcrumbHeader } from "@app/core/components/HelperKeysDrawerBreadcrumbHeader";
import { ContentTransparencyRuleDetailsContent } from "./ContentTransparencyRuleDetailsContent";
import { ContentTransparencyRulesDefinitionsContent } from "./ContentTransparencyRulesDefinitionDrawer";
import { drawerRightClosePosition } from "@app/features/controls/constants";

export const ContentTransparencyRulesDrawer: FC = () => {
    const { isLoading, entity, id, handleDrawerClose } = useContentTransparencyRulesDrawer();

    isLoading && <Loading />;

    const { search } = useLocation();
    const drawerParam = useMemo(() => uri.getSearchParam(search, "drawer"), [search]);

    return (
        <ResizableDrawer
            title={
                <>
                    {drawerParam !== "definitions" && <ContentTransparencyRulesDrawerTitle entity={entity} />}
                    {drawerParam === "definitions" && (
                        <HelperKeysDrawerBreadcrumbHeader
                            parentLabel={entity?.name}
                            childLabel="Content Transparency Definitions"
                        />
                    )}
                </>
            }
            open={Boolean(id)}
            onClose={handleDrawerClose}
            placement="right"
            width="45%"
            className={drawerRightClosePosition}
        >
            {drawerParam !== "definitions" && <ContentTransparencyRuleDetailsContent entity={entity} />}
            {drawerParam === "definitions" && <ContentTransparencyRulesDefinitionsContent />}
        </ResizableDrawer>
    );
};
