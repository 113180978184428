import { FC, useEffect, useState } from "react";
import { Space, Row, Col } from "antd";
import { EntityTypes, useAdStats } from "@app/features/adStats";
import { Last24HoursStatsMetrics } from "../Last24HoursStatsMetrics";
import { Last24HoursPerformanceStatsGraph } from "../Last24HoursPerformanceStatsGraph";
import { useSeatAuthContext } from "@app/core/auth";
import { SnapshotsControls } from "../Snapshots";
import { SnapshotsEntityTypes, useSnapshotsPrefetch } from "@app/core/services/adStats/statsSnapshots";
import { PerformanceStatsTypeButtons } from "@app/features/inventory/InventoryHealthDetailsPages/SharedSections/PerformanceStatsTypeButtons/PerformanceStatsTypeButtons";

interface Props {
    handlePerformanceTypeSwitch: (isLiveActive: boolean) => void;
}

export const Last24HoursStatsTabContent: FC<Props> = ({ handlePerformanceTypeSwitch }) => {
    const { adStat, adStatHistory } = useAdStats(EntityTypes.Seat);
    const { context } = useSeatAuthContext();
    const prefetchSnapshotFolders = useSnapshotsPrefetch("getSeatStatsSnapshotsFolders");
    const [isExtended, setIsExtended] = useState(false);

    const handleExtend = () => {
        setIsExtended(!isExtended);
    };

    useEffect(() => {
        if (context) {
            prefetchSnapshotFolders({ seatId: context.id, entityType: SnapshotsEntityTypes.Seat });
        }
    }, [context, prefetchSnapshotFolders]);

    return (
        <Space direction="vertical" size={50} style={{ display: "flex" }}>
            <Row gutter={[16, 16]}>
                <Col span={isExtended ? 12 : 6}>
                    <Last24HoursStatsMetrics handleExtend={handleExtend} isExtended={isExtended} adStat={adStat} />
                </Col>
                <Col span={isExtended ? 12 : 18}>
                    <Row
                        justify={"space-between"}
                        style={{
                            margin: "0 0 16px 0",
                            padding: "0 16px",
                        }}
                    >
                        <PerformanceStatsTypeButtons handlePerformanceTypeSwitch={handlePerformanceTypeSwitch} />
                        <Col>
                            <SnapshotsControls />
                        </Col>
                    </Row>
                    {context && (
                        <Last24HoursPerformanceStatsGraph
                            chartId="last-24-hours-performance-stats-inventory-health"
                            adStat={adStatHistory[context.id] || null}
                        />
                    )}
                </Col>
            </Row>
        </Space>
    );
};
