import { FC } from "react";
import { Form, Select } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { CREATE_DEAL_FORM_ITEMS_NAME, FORM_DEAL_TYPES_OPTIONS } from "@app/features/deals/constants";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import { ReadOnlyField } from "@app/core/components/Fields/ReadOnlyField";
import { useDealTypeField } from "./useDealTypeField";
import { brandCobalt } from "@rubicon/antd-components";

const TooltipContent = () => (
    <>
        <p>
            <strong>Programmatic Guaranteed</strong>: Fixed rate deals, to enable guaranteed programmatic spend between
            the buyer & seller. They include additional delivery controls associated with guaranteed delivery such as
            pacing/flighting etc
        </p>
        <p>
            <strong>Fixed Price Deal</strong>: Fixed rate PMP based buying to buyers, but with no specific stated
            delivery goal.
        </p>
        <p>
            <strong>Auction Price Deal</strong>: These allow you to establish specific deals for specific supply with
            buyers, set floors, and support dynamic priced bidding.
        </p>
    </>
);

export interface DealTypeFieldProps {
    mode: DealFormMode;
}

export const DealTypeField: FC<DealTypeFieldProps> = ({ mode }) => {
    const { onSelect, value, readonlyLabel, isReadonly } = useDealTypeField(mode);

    if (isReadonly) {
        return (
            <ReadOnlyField dataSdet="deal-type-read-only-field" label="Deal Type">
                {readonlyLabel}
            </ReadOnlyField>
        );
    }

    return (
        <Form.Item
            data-sdet="deal-type-field"
            name={CREATE_DEAL_FORM_ITEMS_NAME.TYPE}
            label="Deal Type"
            rules={[{ required: true, message: "DealType is required" }]}
            tooltip={{
                placement: "topRight",
                overlayInnerStyle: { width: "30rem" },
                title: <TooltipContent />,
                icon: <QuestionCircleOutlined style={{ color: brandCobalt }} />,
            }}
        >
            <Select
                onSelect={onSelect}
                optionFilterProp="label"
                options={FORM_DEAL_TYPES_OPTIONS}
                placeholder="Select Deal Type"
                showSearch
                value={value}
            />
        </Form.Item>
    );
};
