import { useMemo } from "react";
import { BaseOptionType } from "antd/lib/select";
import { IabCategory, useGetIabCategoriesQuery } from "@app/core/services";

interface UseIabCategorieTree {
    isFetching: boolean;
    treeData: BaseOptionType[];
}

export const getCodeTuple = (code: string) => code.slice(3).split("-").map(Number);

export const toAntTree = (categories: IabCategory[]) => {
    const categoriesByParentId = categories.reduce((acc, category) => {
        if (!acc[category.parentId]) {
            acc[category.parentId] = [];
        }
        acc[category.parentId].push(category);
        return acc;
    }, {});

    const treeData = categories
        .filter((category) => category.parentId === null || category.id === category.parentId)
        .map((parentCategory) => {
            if (parentCategory.id === parentCategory.parentId) {
                return {
                    title: parentCategory.name,
                    value: parentCategory.id,
                    key: parentCategory.id,
                    code: [0, 0],
                    children: [],
                };
            }
            return {
                title: `${parentCategory.code}: ${parentCategory.name}`,
                value: parentCategory.id,
                key: parentCategory.id,
                code: getCodeTuple(parentCategory.code),
                children: (categoriesByParentId[parentCategory.id] || []).map((category) => ({
                    title: `${category.code}: ${category.parentName}: ${category.name}`,
                    value: category.id,
                    key: category.id,
                    code: getCodeTuple(category.code),
                })),
            };
        });

    treeData.sort((a, b) => a.code[0] - b.code[0] || a.code[1] - b.code[1]);

    return treeData;
};

export const useIabCategorieTree = (): UseIabCategorieTree => {
    const { data = [], isFetching } = useGetIabCategoriesQuery();
    const treeData: BaseOptionType[] = useMemo(() => toAntTree(data), [data]);

    return { isFetching, treeData };
};
