import { FC } from "react";
import { AppProviders } from "./AppProviders";
import { Main } from "./components";
import { ErrorBoundary } from "@app/core/components/ErrorBoundary";

export const App: FC = () => {
    return (
        <AppProviders>
            <ErrorBoundary>
                <Main />
            </ErrorBoundary>
        </AppProviders>
    );
};
