import { FC, useState } from "react";
import { Card, Col, Row, Alert, Typography, Button } from "antd";
import { cardBodyStyle } from "@app/features/deals/DealForm";
import { TargetingFormKeys } from "@app/features/targeting/constants";
import { CreateTargeting } from "@app/features/targeting";
import { useAppSelector } from "@app/core/store";
import {
    getAudiencesFromTargetingForm,
    getIsRangedCostDeal,
    getSegmentsFromTargetingForm,
} from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/EnrichmentCostSection/utils";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { OneLevelEcDetailsDrawer } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/EnrichmentCostSection/EnrichmentCostDetailsDrawer/OneLevelEcDetailsDrawer";
import { useCurrencyOptions } from "../../../useCurrencyOptions";

export const AdSourceTargetingSection: FC = () => {
    const adSourceLevelAudiences = useAppSelector((state) =>
        getAudiencesFromTargetingForm(state.targeting.targetingFormsByKey.deal.targetingBlocks)
    );
    const adSourceLevelSegments = useAppSelector((state) =>
        getSegmentsFromTargetingForm(state.targeting.targetingFormsByKey.deal.targetingBlocks)
    );
    const dealTypeId = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TYPE]);

    const hasAudienceTargeting = Boolean(adSourceLevelAudiences.length);
    const hasSegmentTargeting = Boolean(adSourceLevelSegments.length);
    const [isSegmentsDrawerOpen, setIsSegmentsDrawerOpen] = useState(false);

    const currency = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.CURRENCY]);
    const { optionsByValue } = useCurrencyOptions();
    const currencyCode = String(optionsByValue[currency]?.label || "USD");

    const isRangedCost = getIsRangedCostDeal(dealTypeId);

    return (
        <Card bordered={false} styles={{ body: cardBodyStyle }} data-sdet="deal-form-ad-source-targeting-section">
            <Row style={{ marginBottom: "1.5rem" }}>
                <Col xs={24}>
                    {(hasAudienceTargeting || hasSegmentTargeting) && (
                        <>
                            <Alert
                                type="info"
                                showIcon
                                banner
                                style={{ alignItems: "baseline", marginBottom: "16px" }}
                                message={
                                    <Typography.Text>
                                        Ad Source Audience Enrichment Cost will be updated if you target 3rd party data
                                        segments with associated CPM costs{" "}
                                        <Button onClick={() => setIsSegmentsDrawerOpen(true)} type="link">
                                            View Details
                                        </Button>
                                    </Typography.Text>
                                }
                            />
                            <OneLevelEcDetailsDrawer
                                adSourceCurrencyCode={currencyCode}
                                isRangedCost={isRangedCost}
                                mode="adSource"
                                onClose={() => setIsSegmentsDrawerOpen(false)}
                                audiences={adSourceLevelAudiences}
                                segments={adSourceLevelSegments}
                                visible={isSegmentsDrawerOpen}
                            />
                        </>
                    )}
                    {/* The formKey key is based on this component appearing in the deal form, not the model its being added to */}
                    <CreateTargeting
                        formKey={TargetingFormKeys.Deal}
                        validationFormKey={TargetingFormKeys.DealAdditionalTargeting}
                    />
                </Col>
            </Row>
        </Card>
    );
};
