import { FINANCE_DEFAULTS_TAG, consoleApi } from "./console";

type HasDateMetadata = {
    creationDate: string;
    updateDate: string;
};

type HasIdName = {
    id: number;
    name: string;
};

export type FinanceDefaultModel = HasIdName & HasDateMetadata;

export interface FinanceDefaultsCreatePayload {
    seat: {
        id: number;
    };
    adSourceType: {
        id: number;
    };
    costModel: {
        id: number;
    };
    costValueFixed: number | null;
    costValuePercent: number | null;
    id: null;
    lineItemType: null;
}

export interface CreateSeatFinanceDefaultsParams {
    seatId: number;
    body: FinanceDefaultsCreatePayload;
}

export interface FinanceDefault {
    id: number;
    seat: HasIdName & {
        code: string;
        entityType: "Seat";
    };
    adSourceType: FinanceDefaultModel | null;
    lineItemType: FinanceDefaultModel | null;
    costModel: FinanceDefaultModel;
    costValuePercent: number | null;
    costValueFixed: number | null;
}

export const financeDefaultsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getSeatFinanceDefaults: builder.query<FinanceDefault[], number>({
            query: (id: number) => `seats/${id}/financeDefaults`,
            providesTags: [FINANCE_DEFAULTS_TAG],
        }),
        createSeatFinanceDefaults: builder.mutation<FinanceDefault, CreateSeatFinanceDefaultsParams>({
            query: (arg: CreateSeatFinanceDefaultsParams) => {
                const { seatId, body } = arg;
                return {
                    url: `seats/${seatId}/financeDefaults`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (_, err) => (err ? [] : [FINANCE_DEFAULTS_TAG]),
        }),
        deleteSeatFinanceDefaults: builder.mutation<unknown, number>({
            query: (id: number) => ({
                url: `seats/financeDefaults/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err) => (err ? [] : [FINANCE_DEFAULTS_TAG]),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetSeatFinanceDefaultsQuery,
    useCreateSeatFinanceDefaultsMutation,
    useDeleteSeatFinanceDefaultsMutation,
} = financeDefaultsApi;
