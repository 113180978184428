import { LabeledValue } from "antd/lib/select";
import { useGetSeatAdvertiserDomainFilterListDefsQuery } from "@app/core/services";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { useParams } from "react-router-dom";
import { REUSABLE_ADVERTISER_DOMAIN_ID } from "@app/core/components/constants";

interface UseAdSourceReusableAdvertiserDomainField {
    options: LabeledValue[];
    isFetching: boolean;
    onChange: (value: number[]) => void;
    value: number[];
}

export const useAdSourceReusableAdvertiserDomainField = (): UseAdSourceReusableAdvertiserDomainField => {
    const dispatch = useAppDispatch();
    const mode = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE]);
    const { seatId } = useParams<{ seatId: string }>();
    const { data, isFetching } = useGetSeatAdvertiserDomainFilterListDefsQuery(
        {
            seatId: Number(seatId),
            typeId: REUSABLE_ADVERTISER_DOMAIN_ID[Number(mode)],
        },
        { skip: !mode || !seatId }
    );

    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_REUSABLE_ADVERTISER_DOMAIN]
    );
    const options = useMemo(() => (data || []).map((item) => ({ label: item.name, value: item.id })), [data]);

    const onChange = (value: number[]): void => {
        dispatch(
            dealFormSelectFieldChange({
                field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_REUSABLE_ADVERTISER_DOMAIN,
                value,
            })
        );
    };
    return {
        options,
        isFetching,
        value,
        onChange,
    };
};
