import { FC } from "react";
import { useGetHistoricalChartData } from "@app/core/components/charts/HistoricalChart/useHistoricalDealData";
import { useAppSelector } from "@app/core/store";
import { selectHistoricalChartGroupBy } from "@app/core/components/charts/HistoricalChart/reducer";
import { HistoricalDealReport } from "@app/core/services";
import {
    ValueMetric1,
    ValueMetric2,
} from "@app/core/components/charts/DualAxesChart/DualAxesCountLineCurrencyLineChart";
import { TogglableChart } from "@app/core/components/charts/TogglableChart/TogglableChart";
import { DualAxesCountBarRateLineChart } from "@app/core/components/charts/DualAxesChart";
import * as labelConstants from "@app/core/components/charts/constants";
import { Loading } from "../../Loading";
import { NoDataAvailable } from "../../LoadableComponent/NoDataAvailable";
import moment from "moment-timezone";

const createMetrics = (data: HistoricalDealReport[] | undefined, groupBy: string) => {
    const barChartMetrics: ValueMetric1[] = [];
    const lineGraphMetrics: ValueMetric2[] = [];

    (data || []).forEach((record) => {
        const curDayFormatted = moment(record.day).format("ddd MMM Do");
        const recordHourFormatted = moment()
            .set("hour", record.hour || 0)
            .set("minute", 0)
            .format("HH:mm");
        const hourSuffix = groupBy === "hour" ? ` - ${recordHourFormatted}` : "";
        const time = `${curDayFormatted}${hourSuffix}`;

        if (groupBy === "hour" && !record.hour) {
            return;
        }

        barChartMetrics.push(
            { time: time, value1: record.eligibleBids, name1: labelConstants.ELIGIBLE_BIDS_LABEL },
            { time: time, value1: record.impressions, name1: labelConstants.IMPRESSIONS_LABEL },
            { time: time, value1: record.tries, name1: labelConstants.TRIES_LABEL },
            { time: time, value1: record.validBids, name1: labelConstants.VALID_BIDS_LABEL },
            { time: time, value1: record.wins, name1: labelConstants.WINS_LABEL },
            { time: time, value1: record.netRevenue, name1: labelConstants.NET_REVENUE_LABEL }
        );
        lineGraphMetrics.push(
            { time: time, value2: record.eligibleBidRate, name2: labelConstants.ELIGIBLE_BID_RATE_LABEL },
            { time: time, value2: record.useRate, name2: labelConstants.USE_RATE_LABEL },
            { time: time, value2: record.validBidRate, name2: labelConstants.VALID_BID_RATE_LABEL }
        );
    });
    return { barChartMetrics, lineGraphMetrics };
};

export const HistoricalChart: FC = () => {
    const { data, isFetching } = useGetHistoricalChartData();
    const groupBy = useAppSelector(selectHistoricalChartGroupBy);
    const metrics = createMetrics(data, groupBy);

    const metricOne = metrics.barChartMetrics;
    const metricTwo = metrics.lineGraphMetrics;

    if (isFetching) {
        return <Loading position="relative" height={50} />;
    } else if (!metricOne.length && !metricTwo.length) {
        return <NoDataAvailable />;
    }
    return (
        <TogglableChart
            metricOne={metricOne}
            metricTwo={metricTwo}
            chartRenderer={(filteredMetricOne, filteredMetricTwo) => (
                <DualAxesCountBarRateLineChart
                    chartId="historical-chart"
                    metricOne={filteredMetricOne}
                    metricTwo={filteredMetricTwo}
                    options={{
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        lineStyles: (label: string) => {
                            switch (label) {
                                case labelConstants.USE_RATE_LABEL:
                                case labelConstants.VALID_BID_RATE_LABEL:
                                    return {
                                        lineDash: [1, 4],
                                        opacity: 1,
                                    };
                            }
                        },
                    }}
                />
            )}
        />
    );
};
