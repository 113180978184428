import { ChangeHistoryTable } from "@app/core/components/ChangeHistoryTable";
import { ChangeLogEntityType } from "@app/core/services";
import { FC } from "react";

export const CLASSIFICATIONS_ADVERTISER_CHANGE_HISTORY_TABLE_SDET = "classifications-advertiser-change-history-table";

interface Props {
    advertiserId?: number;
}

export const ClassificationsAdvertiserChangeHistoryTable: FC<Props> = ({ advertiserId }) => {
    return advertiserId ? (
        <ChangeHistoryTable
            entityId={advertiserId}
            entityType={ChangeLogEntityType.Advertiser}
            sdet={CLASSIFICATIONS_ADVERTISER_CHANGE_HISTORY_TABLE_SDET}
        />
    ) : null;
};
