import { Form } from "antd";
import { useMemo } from "react";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { useGetViewabilityMesurementVendorQuery } from "@app/core/services/console/viewabilityMesurementVendor";
import { ViewabilityMesurementOption } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm/useExchangeProtectionsForm";

const { useWatch } = Form;

interface UseTypeSelect {
    options: { label: string; value: number }[];
    isFetching: boolean;
}

export const useVendorSelect = (): UseTypeSelect => {
    const { data = [], isFetching } = useGetViewabilityMesurementVendorQuery();
    const vendors = useMemo(() => (data || []).map(({ id, name }) => ({ value: id, label: name })), [data]);
    const vendorsOptions: ViewabilityMesurementOption[] =
        useWatch(INVENTORY_FORM_FIELDS.VIEWABILITY_MESUREMENT.name) ?? [];
    const selectedTypes = vendorsOptions.map((option) => option?.value);
    const options = vendors.filter((vendor) => !selectedTypes.includes(vendor?.value));

    return {
        isFetching,
        options,
    };
};
