import { Button, Form, Table, Tooltip } from "antd";
import { FC } from "react";
import { BULK_OPERATION_FORM_ITEMS } from "../../../constants";
import { BulkOperationEntityType } from "@app/core/services";
import { useSelector } from "react-redux";
import {
    removeSelectedAdSourceById,
    removeSelectedAdUnitById,
    removeSelectedBuyerDealById,
    removeSelectedDemandDealById,
    selectAdSourceSelectedRows,
    selectAdUnitSelectedRows,
    selectBuyerDealSelectedRows,
    selectDemandDealSelectedRows,
} from "../../../reducer";
import { getColumns } from "./FindOperationEntitiesTab/OperationEntitiesTable";
import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

const getActionColumn = (handleRemove: (id: number) => void) => ({
    key: "action",
    dataIndex: "action",
    title: "",
    fixed: "right" as const,
    width: "100",
    render: (_, record) => (
        <Tooltip title="Delete">
            <Button onClick={() => handleRemove(record.id)} icon={<DeleteOutlined />} />
        </Tooltip>
    ),
});

const SelectedAdSourceOperationEntitiesTable: FC = () => {
    const selectedRows = useSelector(selectAdSourceSelectedRows);
    const fieldsToEdit = Form.useWatch(BULK_OPERATION_FORM_ITEMS.FIELDS_TO_EDIT.name);
    const dispatch = useDispatch();

    const handleRemove = (id: number) => dispatch(removeSelectedAdSourceById(id));

    return (
        <Table
            dataSource={selectedRows}
            columns={getColumns(BulkOperationEntityType.AdSource, fieldsToEdit).concat(getActionColumn(handleRemove))}
            rowKey="id"
            showSorterTooltip={false}
            scroll={{ x: "max-content" }}
            pagination={false}
        />
    );
};

const SelectedAdUnitOperationEntitiesTable: FC = () => {
    const selectedRows = useSelector(selectAdUnitSelectedRows);
    const fieldsToEdit = Form.useWatch(BULK_OPERATION_FORM_ITEMS.FIELDS_TO_EDIT.name);
    const dispatch = useDispatch();

    const handleRemove = (id: number) => dispatch(removeSelectedAdUnitById(id));

    return (
        <Table
            dataSource={selectedRows}
            columns={getColumns(BulkOperationEntityType.AdUnit, fieldsToEdit).concat(getActionColumn(handleRemove))}
            rowKey="id"
            showSorterTooltip={false}
            scroll={{ x: "max-content" }}
            pagination={false}
        />
    );
};
const SelectedBuyerDealOperationEntitiesTable: FC = () => {
    const selectedRows = useSelector(selectBuyerDealSelectedRows);
    const fieldsToEdit = Form.useWatch(BULK_OPERATION_FORM_ITEMS.FIELDS_TO_EDIT.name);
    const dispatch = useDispatch();

    const handleRemove = (id: number) => dispatch(removeSelectedBuyerDealById(id));

    return (
        <Table
            dataSource={selectedRows}
            columns={getColumns(BulkOperationEntityType.BuyerDeal, fieldsToEdit).concat(getActionColumn(handleRemove))}
            rowKey="id"
            showSorterTooltip={false}
            scroll={{ x: "max-content" }}
            pagination={false}
        />
    );
};
const SelectedDemandDealOperationEntitiesTable: FC = () => {
    const selectedRows = useSelector(selectDemandDealSelectedRows);
    const fieldsToEdit = Form.useWatch(BULK_OPERATION_FORM_ITEMS.FIELDS_TO_EDIT.name);
    const dispatch = useDispatch();

    const handleRemove = (id: number) => dispatch(removeSelectedDemandDealById(id));

    return (
        <Table
            dataSource={selectedRows}
            columns={getColumns(BulkOperationEntityType.DemandDeal, fieldsToEdit).concat(getActionColumn(handleRemove))}
            rowKey="id"
            showSorterTooltip={false}
            scroll={{ x: "max-content" }}
            pagination={false}
        />
    );
};

export const SelectedOperationEntitiesTable: FC = () => {
    const entityType = Form.useWatch(BULK_OPERATION_FORM_ITEMS.ENTITY_TYPE.name);

    switch (entityType) {
        case BulkOperationEntityType.AdSource:
            return <SelectedAdSourceOperationEntitiesTable />;
        case BulkOperationEntityType.AdUnit:
            return <SelectedAdUnitOperationEntitiesTable />;
        case BulkOperationEntityType.BuyerDeal:
            return <SelectedBuyerDealOperationEntitiesTable />;
        case BulkOperationEntityType.DemandDeal:
            return <SelectedDemandDealOperationEntitiesTable />;
        default:
            return null;
    }
};
