import { FC } from "react";
import { Seat } from "@app/core/services";
import { Form, Space, Input } from "antd";
import { ColProps } from "antd/lib/grid/col";
import { useUserAccess } from "@app/core/auth";
import { gold6 } from "@rubicon/antd-components";
import {
    getCodeAddonBefore,
    getCodeExtraMessage,
    getCodeRequiredMark,
} from "@app/features/inventory/HierarchyForms/helpers";
import {
    CODE_TYPE,
    InventoryCodeType,
    HierarchyFormsMode,
    INVENTORY_GENERATED_CODE_TYPE,
} from "@app/features/inventory/HierarchyForms/constants";
import { ExclamationCircleOutlined } from "@ant-design/icons";

interface CodeFormItemProps {
    name: string;
    label: string;
    seat: Seat | null;
    labelCol?: ColProps;
    placeholder: string;
    wrapperCol?: ColProps;
    mode: HierarchyFormsMode;
    dataSdet?: string;
    useWatch: typeof Form.useWatch;
}

export const CodeFormItem: FC<CodeFormItemProps> = ({
    useWatch,
    mode,
    name,
    label,
    seat,
    wrapperCol,
    labelCol,
    placeholder,
    dataSdet,
}) => {
    const { isTremorUser, isPubAcctMgr, isSysAdmin } = useUserAccess();
    const hasEditAccess: boolean = isPubAcctMgr || isSysAdmin;
    const codeType: InventoryCodeType = useWatch(CODE_TYPE);

    const isGeneratedCodeType: boolean = codeType === INVENTORY_GENERATED_CODE_TYPE.value;
    const code: string = useWatch(name);

    const isEditMode: boolean = mode === "edit";

    const hidden: boolean = !isEditMode && isGeneratedCodeType;
    //INFO use conditional rendering instead of hidden prperty of Form.Item becaues it still collect data and validate it
    if (hidden) {
        return null;
    }
    const isReadOnly: boolean = !hasEditAccess && isEditMode;

    const extra: string | undefined = getCodeExtraMessage(seat, isTremorUser, mode, code);
    const required: boolean = getCodeRequiredMark(isGeneratedCodeType, mode, isTremorUser, hasEditAccess);
    const addonBefore: string | undefined = getCodeAddonBefore(seat, isTremorUser, mode);

    return (
        <Form.Item
            name={name}
            label={label}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            rules={[
                {
                    required,
                    whitespace: true,
                    validator(_, value) {
                        if (addonBefore) {
                            if (addonBefore.length + value.length > 37)
                                return Promise.reject(new Error("Maximum 37 characters"));
                            return Promise.resolve();
                        }
                        if (value.length > 38) return Promise.reject(new Error("Maximum 38 characters"));
                        if (String(value).trim().length > 0) return Promise.resolve();
                        return Promise.reject(new Error("Code is required"));
                    },
                },
            ]}
            extra={
                extra && (
                    <Space>
                        <ExclamationCircleOutlined style={{ color: gold6 }} />
                        {extra}
                    </Space>
                )
            }
        >
            {isReadOnly ? (
                code
            ) : (
                <Input addonBefore={addonBefore} placeholder={placeholder} allowClear data-sdet={dataSdet} />
            )}
        </Form.Item>
    );
};
