import { Button, List, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";

const classList = css`
    align-items: start;

    .ant-list-item-meta-description {
        max-width: 80%;
    }

    .ant-list-item-action {
        margin-left: 0;

        .ant-btn {
            color: rgba(0, 0, 0, 0.45);
            &:hover {
                background-color: transparent;
                color: rgba(0, 0, 0, 0.75);
            }
        }
    }
`;

export interface NavMenuItemProps {
    id: string;
    description?: string;
    onClick?: () => void;
    onClose?: () => void;
    title?: string;
    target?: string;
    url?: string;
}
const NavMenuItem = (props: NavMenuItemProps) => {
    const { description, id, onClose, onClick, target = "_self", title, url } = props;

    return (
        <List.Item
            id={id}
            className={classList}
            actions={[onClose && <Button type="text" key="close-item" icon={<DeleteOutlined />} onClick={onClose} />]}
        >
            <List.Item.Meta
                title={
                    <Typography.Link href={url} target={target} onClick={onClick}>
                        {title}
                    </Typography.Link>
                }
                description={description}
            />
        </List.Item>
    );
};

export default NavMenuItem;
