import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { setFiltersDealHealthStatus } from "@app/features/deals/DealHealthPage/reducer";
import { useGetDealStatusQuery } from "@app/core/services";

interface UseStatusFilter {
    handleChange: (value: string, option: LabeledValue[]) => void;
    options: LabeledValue[];
    isFetching: boolean;
    value: LabeledValue[] | null;
    areAllOptionsSelected: boolean;
    toggleSelectDeselectAll: () => void;
}

export const useStatusFilter = (): UseStatusFilter => {
    const dispatch = useAppDispatch();
    const { data, isFetching } = useGetDealStatusQuery();
    const status = useAppSelector((state) => state.dealHealth.filters.status);

    const options = useMemo(
        () =>
            (data || []).map((status) => ({
                value: status.id,
                label: status.name,
            })),
        [data]
    );

    const handleChange = (value: string | undefined, option: LabeledValue[]) => {
        dispatch(setFiltersDealHealthStatus(option));
    };

    const areAllOptionsSelected = options.length === status?.length;

    const toggleSelectDeselectAll = () => {
        if (areAllOptionsSelected) {
            handleChange(undefined, []);
        } else {
            handleChange(undefined, options);
        }
    };

    return {
        options,
        handleChange,
        isFetching,
        value: status,
        areAllOptionsSelected,
        toggleSelectDeselectAll,
    };
};
