import { useContext } from "react";
import { RadioChangeEvent } from "antd";
import moment from "moment-timezone";
import { useSyncedFieldsConsumer } from "@app/features/syncedFields";
import { DealEntityTypes } from "@app/features/seatAdSources/constants";
import { AdSourceFormsContext } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { MarketplaceInfoDrawerMode } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { useIsAdSourceStartDateApiReadOnly } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/DeliverySection/Fields/StartDatePicker";

interface UseEntityTypeRadio {
    onChange: (event: RadioChangeEvent) => void;
}

export const useEntityTypeRadio = (marketplaceInfoMode: MarketplaceInfoDrawerMode): UseEntityTypeRadio => {
    const { adSourceForm, demandConfigurationForm } = useContext(AdSourceFormsContext).forms;
    const { consumeAllSyncedFields } = useSyncedFieldsConsumer();
    const isAdSourceStartDateApiLocked = useIsAdSourceStartDateApiReadOnly();

    const onChange = (event: RadioChangeEvent) => {
        const isCreateMode = marketplaceInfoMode.name === "create";
        const isLegacyDeal = event.target.value === DealEntityTypes.Legacy;

        if (isCreateMode && isLegacyDeal) {
            const { bookingStartDate, bookingEndDate } = adSourceForm.getFieldsValue();

            demandConfigurationForm.setFieldsValue({
                startDate: bookingStartDate && !isAdSourceStartDateApiLocked ? bookingStartDate : moment(),
                endDate: bookingEndDate || null,
                runDateMode: bookingEndDate ? "fixed" : "evergreen",
                freqCappingType: { name: "Off", id: 1 },
                dealFreqCappings: [],
                currencyType: { code: "Inherited From Ad Source", id: NaN },
            });
        }

        consumeAllSyncedFields();
    };

    return { onChange };
};
