import { useCallback } from "react";
import { consoleApi, HELP_ARTICLE_TAG } from "./console";

export interface GetHelpArticlesParams {
    helpKeys: string[];
}

export interface HelpArticle {
    creationDate: string;
    updateDate: string;
    id: number;
    helpKey: string;
    title: string;
    secondaryTitle: string | null;
    categoryValues: unknown[];
    body: string; // HTML string
    internalBody: "internal" | unknown;
    isPublished: boolean;
    entityType: "ContextualHelpArticle";
}

const toHelpKeyQueryString = (params: GetHelpArticlesParams): string =>
    params.helpKeys.map((key) => `helpKeys=${key}`).join("&");

export const helpApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getHelpKnowledgeOwlAuth: builder.query<{ token: string }, { userType: "buyer" | "seller" }>({
            query: ({ userType }) => `/help/knowledgeowl/auth?userType=${userType}`,
            // Intentionally no tags, do not cache the token result
        }),
        getHelpArticles: builder.query<HelpArticle[], GetHelpArticlesParams>({
            query: (args: GetHelpArticlesParams) => {
                return `/help/helpArticles?${toHelpKeyQueryString(args)}`;
            },
            providesTags: (results, err) =>
                err ? [] : (results || []).map((result) => ({ type: HELP_ARTICLE_TAG, id: result.helpKey })),
        }),
        getHelpArticle: builder.query<HelpArticle, string>({
            query: (helpKey) => {
                return `/help/topic/${helpKey}`;
            },
            providesTags: (_, err, helpKey) => (err ? [] : [{ type: HELP_ARTICLE_TAG, id: helpKey }]),
        }),
    }),
    overrideExisting: false,
});

export const { useLazyGetHelpKnowledgeOwlAuthQuery, useGetHelpArticlesQuery, useGetHelpArticleQuery } = helpApi;

export const useOpenHelpCenter = () => {
    const [getHelpKnowledgeOwlAuth] = useLazyGetHelpKnowledgeOwlAuthQuery();

    const openHelpCenter = useCallback(
        (isInNewWindow: boolean, isSeat: boolean) => {
            const openUrl = (url: string, isInNewWindow: boolean) => {
                if (isInNewWindow) {
                    window.open(url, "_blank");
                } else {
                    window.location.replace(url);
                }
            };

            return getHelpKnowledgeOwlAuth({ userType: isSeat ? "seller" : "buyer" })
                .unwrap()
                .then((data) => {
                    openUrl(`https://help.magnite.com/help/remote-auth?n=${data.token}`, isInNewWindow);
                })
                .catch(() => {
                    openUrl("https://help.magnite.com", isInNewWindow);
                });
        },
        [getHelpKnowledgeOwlAuth]
    );

    return openHelpCenter;
};
