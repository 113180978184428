import { useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { useLoadOnScroll } from "@app/core/components";
import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { QueryDefinition } from "@reduxjs/toolkit/query";

const MAX = 200;

export const useLoadOnScrollField = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useGetOptionsQuery: UseQuery<QueryDefinition<any, any, any, any>>,
    extraParams?: Record<string, number | string | boolean>
) => {
    const [searchValue, setSearchValue] = useState<string>("");
    const [keyword, setKeyword] = useState<string>("");
    const [pageByKeyword, setPageByKeyword] = useState({ "": 1 });
    const page = pageByKeyword[keyword] || 1;

    const {
        data,
        isFetching: isLoading,
        originalArgs,
        error,
    } = useGetOptionsQuery({ keyword, max: MAX, page, ...extraParams });

    const { options, hasMore, loadMore } = useLoadOnScroll(
        data,
        isLoading,
        keyword,
        originalArgs?.page || 1,
        data?.length === MAX,
        () =>
            setPageByKeyword((prev) => {
                const currentPage = prev[keyword] || 1;
                return {
                    ...prev,
                    [keyword]: currentPage + 1,
                };
            })
    );

    const debouncedSetKeyword = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setKeyword]
    );

    const onSearch = (value: string) => {
        setSearchValue(value);
        debouncedSetKeyword(value);
    };

    return { options, isLoading, onSearch, searchValue, hasMore, loadMore, error };
};
