import { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "antd";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useUserAccess } from "@app/core/auth";
import { PageHeader } from "@ant-design/pro-layout";

const CustomPixelsPageHeader: FC = () => {
    const history = useHistory();
    const { canEditSeat } = useUserAccess();
    const { seatId } = useParams<{ seatId: string }>();
    const handleCreatePixel = (): void => {
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_CUSTOM_PIXELS_CREATE(seatId));
    };

    return (
        <PageHeader
            title={<span data-sdet="custom-pixels-header-title">Custom Pixels</span>}
            extra={
                canEditSeat && (
                    <Button type="primary" onClick={handleCreatePixel} data-sdet="add-pixel-button">
                        Add Pixel
                    </Button>
                )
            }
        />
    );
};

export default CustomPixelsPageHeader;
