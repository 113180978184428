import { TablePaginationConfig } from "antd";
import { Key } from "react";
import unionWith from "lodash.unionwith";
import { DEFAULT_PAGE_SIZE } from "@app/core/components/constants";
import {
    AdSourceListItem,
    AdSourcesListResponse,
    AdUnit,
    AdUnitListResponse,
    BulkOperationEntityType,
    BuyerDeal,
    BuyerDealListResponse,
    DemandDeal,
    DemandDealListResponse,
} from "@app/core/services";
import { RootState } from "@app/core/store";
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { LabeledValue } from "antd/es/select";
import { Moment } from "moment-timezone";

// some entity's name is null from db
export type TouchedFieldInfo = {
    name: string | null;
    field: string;
    title: string;
    originValue: unknown;
    currentValue: unknown;
    isChanged: boolean;
};
export interface BulkOperationsState {
    adSource: {
        isEntitySelectionTouched: boolean;
        isEntityEditTouched: boolean;
        selectedRowKeys: Key[];
        selectedRows: AdSourceListItem[];
        touchedRecordsInfo: Record<number, TouchedFieldInfo[] | undefined>;
        current: number;
        pageSize: number;
        response: undefined | AdSourcesListResponse;
    };
    adUnit: {
        isEntitySelectionTouched: boolean;
        isEntityEditTouched: boolean;
        selectedRowKeys: Key[];
        selectedRows: AdUnit[];
        touchedRecordsInfo: Record<number, TouchedFieldInfo[] | undefined>;
        current: number;
        pageSize: number;
        response: undefined | AdUnitListResponse;
    };
    buyerDeal: {
        isEntitySelectionTouched: boolean;
        isEntityEditTouched: boolean;
        selectedRowKeys: Key[];
        selectedRows: BuyerDeal[];
        touchedRecordsInfo: Record<number, TouchedFieldInfo[] | undefined>;
        current: number;
        pageSize: number;
        response: undefined | BuyerDealListResponse;
    };
    demandDeal: {
        isEntitySelectionTouched: boolean;
        isEntityEditTouched: boolean;
        selectedRowKeys: Key[];
        selectedRows: DemandDeal[];
        touchedRecordsInfo: Record<number, TouchedFieldInfo[] | undefined>;
        current: number;
        pageSize: number;
        response: undefined | DemandDealListResponse;
    };
    completeWithErrorsMessage: string | null;
}

const initialState: BulkOperationsState = {
    adSource: {
        isEntitySelectionTouched: false,
        isEntityEditTouched: false,
        selectedRowKeys: [],
        selectedRows: [],
        touchedRecordsInfo: {},
        current: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        response: undefined,
    },
    adUnit: {
        isEntitySelectionTouched: false,
        isEntityEditTouched: false,
        selectedRowKeys: [],
        selectedRows: [],
        touchedRecordsInfo: {},
        current: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        response: undefined,
    },
    buyerDeal: {
        isEntitySelectionTouched: false,
        isEntityEditTouched: false,
        selectedRowKeys: [],
        selectedRows: [],
        touchedRecordsInfo: {},
        current: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        response: undefined,
    },
    demandDeal: {
        isEntitySelectionTouched: false,
        isEntityEditTouched: false,
        selectedRowKeys: [],
        selectedRows: [],
        touchedRecordsInfo: {},
        current: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        response: undefined,
    },
    completeWithErrorsMessage: null,
};

export type DerivedAdSourceListItem = Omit<
    AdSourceListItem,
    | "priority"
    | "region"
    | "salesContact"
    | "status"
    | "timeZone"
    | "currencyType"
    | "costModel"
    | "adSourceFloorType"
    | "allowContent"
    | "auctionType"
    | "labelValues"
    | "bookingStartDate"
    | "bookingEndDate"
    | "allowUserId"
> & {
    priority: LabeledValue;
    region: LabeledValue;
    salesContact: LabeledValue;
    status: LabeledValue;
    timeZone: LabeledValue | null;
    currencyType: LabeledValue;
    costModel: LabeledValue;
    adSourceFloorType: LabeledValue | null;
    allowContent: LabeledValue;
    auctionType: LabeledValue;
    labelValues: LabeledValue[];
    bookingStartDate: Moment;
    bookingEndDate: Moment | null;
    allowUserId: string;
};

export type DerivedAdUnit = Omit<
    AdUnit,
    | "status"
    | "placement"
    | "supportedProtocols"
    | "mimes"
    | "supportedApis"
    | "ssaiType"
    | "impressionWaitTime"
    | "blockedCreativeAttributes"
    | "videoDeliveries"
    | "linearity"
    | "type"
    | "labelValues"
    | "publisherReauction"
    | "skip"
> & {
    status: LabeledValue;
    placement: LabeledValue;
    supportedProtocols: LabeledValue[];
    mimes: LabeledValue[];
    supportedApis: LabeledValue[];
    ssaiType: LabeledValue;
    impressionWaitTime: LabeledValue;
    blockedCreativeAttributes: LabeledValue[];
    videoDeliveries: LabeledValue[];
    linearity: LabeledValue;
    type: LabeledValue | null;
    labelValues: LabeledValue[];
    publisherReauction: string;
    skip: boolean;
};

export type DerivedBuyerDeal = Omit<
    BuyerDeal,
    "timeZone" | "currencyType" | "sharedSeats" | "sharedMarketplaces" | "startTime" | "endTime"
> & {
    timeZone: LabeledValue;
    currencyType: LabeledValue;
    sharedSeats: LabeledValue[];
    sharedMarketplaces: LabeledValue[];
    startTime: Moment;
    endTime: Moment | null;
};

export type DerivedDemandDeal = Omit<
    DemandDeal,
    "timeZone" | "currencyType" | "sharedSeats" | "sharedMarketplaces" | "startTime" | "endTime"
> & {
    timeZone: LabeledValue;
    currencyType: LabeledValue;
    sharedSeats: LabeledValue[];
    sharedMarketplaces: LabeledValue[];
    startTime: Moment;
    endTime: Moment | null;
};

const bulkOperationsSlice = createSlice({
    name: "bulkOperations",
    initialState,
    reducers: {
        setAdSourceBulkOperationSelections: (
            state,
            action: PayloadAction<{
                selectedRowKeys: Key[];
                selectedRows: AdSourceListItem[];
            }>
        ) => {
            state.adSource.selectedRowKeys = action.payload.selectedRowKeys;
            // state.adSource.selectedRows may contain uploaded items, which may not be in the table selections, so unionWith them,
            // but state.adSource.selectedRows may also contain items that table just unselected, so use selectedRowKeys to filter out the final selectedRows
            state.adSource.selectedRows = unionWith(
                action.payload.selectedRows,
                state.adSource.selectedRows,
                (a: AdSourceListItem, b: AdSourceListItem) => a.id === b.id
            ).filter(({ id }) => action.payload.selectedRowKeys.includes(id));
            state.adSource.isEntitySelectionTouched = true;
            // remove touchedFieldInfo if the entry is unselected from table row
            Object.keys(state.adSource.touchedRecordsInfo).forEach((id: string) => {
                if (!action.payload.selectedRowKeys.includes(Number(id))) {
                    state.adSource.touchedRecordsInfo[id] = undefined;
                }
            });
        },
        setAdUnitBulkOperationSelections: (
            state,
            action: PayloadAction<{ selectedRowKeys: Key[]; selectedRows: AdUnit[] }>
        ) => {
            state.adUnit.selectedRowKeys = action.payload.selectedRowKeys;
            state.adUnit.selectedRows = unionWith(
                action.payload.selectedRows,
                state.adUnit.selectedRows,
                (a: AdUnit, b: AdUnit) => a.id === b.id
            ).filter(({ id }) => action.payload.selectedRowKeys.includes(id));
            state.adUnit.isEntitySelectionTouched = true;
            Object.keys(state.adUnit.touchedRecordsInfo).forEach((id: string) => {
                if (!action.payload.selectedRowKeys.includes(Number(id))) {
                    state.adUnit.touchedRecordsInfo[id] = undefined;
                }
            });
        },
        setBuyerDealBulkOperationSelections: (
            state,
            action: PayloadAction<{
                selectedRowKeys: Key[];
                selectedRows: BuyerDeal[];
            }>
        ) => {
            state.buyerDeal.selectedRowKeys = action.payload.selectedRowKeys;
            state.buyerDeal.selectedRows = unionWith(
                action.payload.selectedRows,
                state.buyerDeal.selectedRows,
                (a: BuyerDeal, b: BuyerDeal) => a.id === b.id
            ).filter(({ id }) => action.payload.selectedRowKeys.includes(id));
            state.buyerDeal.isEntitySelectionTouched = true;
            Object.keys(state.buyerDeal.touchedRecordsInfo).forEach((id: string) => {
                if (!action.payload.selectedRowKeys.includes(Number(id))) {
                    state.buyerDeal.touchedRecordsInfo[id] = undefined;
                }
            });
        },
        setDemandDealBulkOperationSelections: (
            state,
            action: PayloadAction<{
                selectedRowKeys: Key[];
                selectedRows: DemandDeal[];
            }>
        ) => {
            state.demandDeal.selectedRowKeys = action.payload.selectedRowKeys;
            state.demandDeal.selectedRows = unionWith(
                action.payload.selectedRows,
                state.demandDeal.selectedRows,
                (a: DemandDeal, b: DemandDeal) => a.id === b.id
            ).filter(({ id }) => action.payload.selectedRowKeys.includes(id));
            state.demandDeal.isEntitySelectionTouched = true;
            Object.keys(state.demandDeal.touchedRecordsInfo).forEach((id: string) => {
                if (!action.payload.selectedRowKeys.includes(Number(id))) {
                    state.demandDeal.touchedRecordsInfo[id] = undefined;
                }
            });
        },
        setAdSourceBulkOperationResponse: (
            state,
            action: PayloadAction<{
                response: AdSourcesListResponse;
            }>
        ) => {
            state.adSource.response = action.payload.response;
        },
        setAdUnitBulkOperationResponse: (state, action: PayloadAction<{ response: AdUnitListResponse }>) => {
            state.adUnit.response = action.payload.response;
        },
        setBuyerDealBulkOperationResponse: (
            state,
            action: PayloadAction<{
                response: BuyerDealListResponse;
            }>
        ) => {
            state.buyerDeal.response = action.payload.response;
        },
        setDemandDealBulkOperationResponse: (
            state,
            action: PayloadAction<{
                response?: undefined | DemandDealListResponse;
            }>
        ) => {
            state.demandDeal.response = action.payload.response;
        },
        setAdSourceBulkOperationPageInfo: (state, action: PayloadAction<TablePaginationConfig>) => {
            state.adSource = { ...state.adSource, ...action.payload };
        },
        setAdUnitBulkOperationPageInfo: (state, action: PayloadAction<TablePaginationConfig>) => {
            state.adUnit = { ...state.adUnit, ...action.payload };
        },
        setBuyerDealBulkOperationPageInfo: (state, action: PayloadAction<TablePaginationConfig>) => {
            state.buyerDeal = { ...state.buyerDeal, ...action.payload };
        },
        setDemandDealBulkOperationPageInfo: (state, action: PayloadAction<TablePaginationConfig>) => {
            state.demandDeal = { ...state.demandDeal, ...action.payload };
        },
        setUploadedAdSources: (state, action: PayloadAction<{ adSources: AdSourceListItem[]; ids: number[] }>) => {
            state.adSource.selectedRows = unionWith(
                action.payload.adSources,
                state.adSource.selectedRows,
                (a: AdSourceListItem, b: AdSourceListItem) => a.id === b.id
            );
            state.adSource.selectedRowKeys = unionWith(
                action.payload.ids,
                state.adSource.selectedRowKeys,
                (a: number, b: number) => a === b
            );
            state.adSource.isEntitySelectionTouched = true;
        },
        setUploadedAdUnits: (state, action: PayloadAction<{ adUnits: AdUnit[]; ids: number[] }>) => {
            state.adUnit.selectedRows = unionWith(
                action.payload.adUnits,
                state.adUnit.selectedRows,
                (a: AdUnit, b: AdUnit) => a.id === b.id
            );
            state.adUnit.selectedRowKeys = unionWith(
                action.payload.ids,
                state.adUnit.selectedRowKeys,
                (a: number, b: number) => a === b
            );
            state.adSource.isEntitySelectionTouched = true;
        },
        setUploadedBuyerDeals: (state, action: PayloadAction<{ buyerDeals: BuyerDeal[]; ids: number[] }>) => {
            state.buyerDeal.selectedRows = unionWith(
                action.payload.buyerDeals,
                state.buyerDeal.selectedRows,
                (a: BuyerDeal, b: BuyerDeal) => a.id === b.id
            );
            state.buyerDeal.selectedRowKeys = unionWith(
                action.payload.ids,
                state.buyerDeal.selectedRowKeys,
                (a: number, b: number) => a === b
            );
            state.adSource.isEntitySelectionTouched = true;
        },
        setUploadedDemandDeals: (state, action: PayloadAction<{ demandDeals: DemandDeal[]; ids: number[] }>) => {
            state.demandDeal.selectedRows = unionWith(
                action.payload.demandDeals,
                state.demandDeal.selectedRows,
                (a: DemandDeal, b: DemandDeal) => a.id === b.id
            );
            state.demandDeal.selectedRowKeys = unionWith(
                action.payload.ids,
                state.demandDeal.selectedRowKeys,
                (a: number, b: number) => a === b
            );
            state.adSource.isEntitySelectionTouched = true;
        },
        setAdSourceFieldChange: (state, action: PayloadAction<TouchedFieldInfo & { id: number }>) => {
            const { id, ...fieldInfo } = action.payload;
            // replace by id if exist, add if not exist; do not switch the args of unionWith here,
            state.adSource.touchedRecordsInfo[id] = unionWith(
                [fieldInfo],
                state.adSource.touchedRecordsInfo[id],
                (a, b) => a.field === b.field
            );
            state.adSource.isEntityEditTouched = true;
        },
        setAdSourceFieldBulkChange: (state, action: PayloadAction<Record<number, TouchedFieldInfo[]>>) => {
            const touchedFieldsInfoById = action.payload;
            Object.entries(touchedFieldsInfoById).forEach(([id, touchedFieldsInfo]) => {
                state.adSource.touchedRecordsInfo[id] = unionWith(
                    touchedFieldsInfo,
                    state.adSource.touchedRecordsInfo[id],
                    (a, b) => a.field === b.field
                );
            });
            state.adSource.isEntityEditTouched = true;
        },
        setAdUnitFieldChange: (state, action: PayloadAction<TouchedFieldInfo & { id: number }>) => {
            const { id, ...fieldInfo } = action.payload;
            // replace by id if exist, add if not exist; do not switch the args of unionWith here,
            state.adUnit.touchedRecordsInfo[id] = unionWith(
                [fieldInfo],
                state.adUnit.touchedRecordsInfo[id],
                (a, b) => a.field === b.field
            );
            state.adUnit.isEntityEditTouched = true;
        },
        setAdUnitFieldBulkChange: (state, action: PayloadAction<Record<number, TouchedFieldInfo[]>>) => {
            const touchedFieldsInfoById = action.payload;
            Object.entries(touchedFieldsInfoById).forEach(([id, touchedFieldsInfo]) => {
                state.adUnit.touchedRecordsInfo[id] = unionWith(
                    touchedFieldsInfo,
                    state.adUnit.touchedRecordsInfo[id],
                    (a, b) => a.field === b.field
                );
            });
            state.adUnit.isEntityEditTouched = true;
        },
        setBuyerDealFieldChange: (state, action: PayloadAction<TouchedFieldInfo & { id: number }>) => {
            const { id, ...fieldInfo } = action.payload;
            // replace by id if exist, add if not exist; do not switch the args of unionWith here,
            state.buyerDeal.touchedRecordsInfo[id] = unionWith(
                [fieldInfo],
                state.buyerDeal.touchedRecordsInfo[id],
                (a, b) => a.field === b.field
            );
            state.buyerDeal.isEntityEditTouched = true;
        },
        setBuyerDealFieldBulkChange: (state, action: PayloadAction<Record<number, TouchedFieldInfo[]>>) => {
            const touchedFieldsInfoById = action.payload;
            Object.entries(touchedFieldsInfoById).forEach(([id, touchedFieldsInfo]) => {
                state.buyerDeal.touchedRecordsInfo[id] = unionWith(
                    touchedFieldsInfo,
                    state.buyerDeal.touchedRecordsInfo[id],
                    (a, b) => a.field === b.field
                );
            });
            state.buyerDeal.isEntityEditTouched = true;
        },
        setDemandDealFieldChange: (state, action: PayloadAction<TouchedFieldInfo & { id: number }>) => {
            const { id, ...fieldInfo } = action.payload;
            // replace by id if exist, add if not exist; do not switch the args of unionWith here,
            // since if arg1.field === arg2.field, arg1 will take the priority, so arg2 can get updated with arg1
            state.demandDeal.touchedRecordsInfo[id] = unionWith(
                [fieldInfo],
                state.demandDeal.touchedRecordsInfo[id],
                (a, b) => a.field === b.field
            );
            state.demandDeal.isEntityEditTouched = true;
        },
        setDemandDealFieldBulkChange: (state, action: PayloadAction<Record<number, TouchedFieldInfo[]>>) => {
            const touchedFieldsInfoById = action.payload;
            Object.entries(touchedFieldsInfoById).forEach(([id, touchedFieldsInfo]) => {
                state.demandDeal.touchedRecordsInfo[id] = unionWith(
                    touchedFieldsInfo,
                    state.demandDeal.touchedRecordsInfo[id],
                    (a, b) => a.field === b.field
                );
            });
            state.demandDeal.isEntityEditTouched = true;
        },
        removeSelectedAdSourceById: (state, action: PayloadAction<number>) => {
            state.adSource.selectedRows = state.adSource.selectedRows.filter(({ id }) => id !== action.payload);
            state.adSource.selectedRowKeys = state.adSource.selectedRowKeys.filter((id) => id !== action.payload);
            state.adSource.touchedRecordsInfo[action.payload] = undefined;
        },
        removeSelectedAdUnitById: (state, action: PayloadAction<number>) => {
            state.adUnit.selectedRows = state.adUnit.selectedRows.filter(({ id }) => id !== action.payload);
            state.adUnit.selectedRowKeys = state.adUnit.selectedRowKeys.filter((id) => id !== action.payload);
            state.adUnit.touchedRecordsInfo[action.payload] = undefined;
        },
        removeSelectedBuyerDealById: (state, action: PayloadAction<number>) => {
            state.buyerDeal.selectedRows = state.buyerDeal.selectedRows.filter(({ id }) => id !== action.payload);
            state.buyerDeal.selectedRowKeys = state.buyerDeal.selectedRowKeys.filter((id) => id !== action.payload);
            state.buyerDeal.touchedRecordsInfo[action.payload] = undefined;
        },
        removeSelectedDemandDealById: (state, action: PayloadAction<number>) => {
            state.demandDeal.selectedRows = state.demandDeal.selectedRows.filter(({ id }) => id !== action.payload);
            state.demandDeal.selectedRowKeys = state.demandDeal.selectedRowKeys.filter((id) => id !== action.payload);
            state.demandDeal.touchedRecordsInfo[action.payload] = undefined;
        },
        removeAdSourceTouchedRecordInfo: (state, action: PayloadAction<number | undefined>) => {
            const id = action.payload;
            if (id) {
                state.adSource.touchedRecordsInfo[id] = undefined;
            } else {
                // if no id provided, means remove all
                state.adSource.touchedRecordsInfo = {};
            }
        },
        removeAdUnitTouchedRecordInfo: (state, action: PayloadAction<number | undefined>) => {
            const id = action.payload;
            if (id) {
                state.adUnit.touchedRecordsInfo[id] = undefined;
            } else {
                // if no id provided, means remove all
                state.adUnit.touchedRecordsInfo = {};
            }
        },
        removeBuyerDealTouchedRecordInfo: (state, action: PayloadAction<number | undefined>) => {
            const id = action.payload;
            if (id) {
                state.buyerDeal.touchedRecordsInfo[id] = undefined;
            } else {
                // if no id provided, means remove all
                state.buyerDeal.touchedRecordsInfo = {};
            }
        },
        removeDemandDealTouchedRecordInfo: (state, action: PayloadAction<number | undefined>) => {
            const id = action.payload;
            if (id) {
                state.demandDeal.touchedRecordsInfo[id] = undefined;
            } else {
                // if no id provided, means remove all
                state.demandDeal.touchedRecordsInfo = {};
            }
        },
        setCompleteWithErrorsMessage: (state, action: PayloadAction<string | null>) => {
            state.completeWithErrorsMessage = action.payload;
        },
        resetBulkOperationSliceState: (state) => {
            return { ...initialState, completeWithErrorsMessage: state.completeWithErrorsMessage };
        },
    },
});

export const {
    removeSelectedAdSourceById,
    removeSelectedAdUnitById,
    removeSelectedBuyerDealById,
    removeSelectedDemandDealById,
    removeAdSourceTouchedRecordInfo,
    removeAdUnitTouchedRecordInfo,
    removeBuyerDealTouchedRecordInfo,
    removeDemandDealTouchedRecordInfo,
    resetBulkOperationSliceState,
    setAdSourceBulkOperationPageInfo,
    setAdSourceBulkOperationResponse,
    setAdSourceBulkOperationSelections,
    setAdUnitBulkOperationPageInfo,
    setAdUnitBulkOperationResponse,
    setAdUnitBulkOperationSelections,
    setBuyerDealBulkOperationPageInfo,
    setBuyerDealBulkOperationResponse,
    setBuyerDealBulkOperationSelections,
    setAdSourceFieldChange,
    setAdSourceFieldBulkChange,
    setAdUnitFieldChange,
    setAdUnitFieldBulkChange,
    setBuyerDealFieldChange,
    setBuyerDealFieldBulkChange,
    setDemandDealBulkOperationPageInfo,
    setDemandDealBulkOperationResponse,
    setDemandDealBulkOperationSelections,
    setDemandDealFieldChange,
    setDemandDealFieldBulkChange,
    setUploadedAdSources,
    setUploadedAdUnits,
    setUploadedBuyerDeals,
    setUploadedDemandDeals,
    setCompleteWithErrorsMessage,
} = bulkOperationsSlice.actions;

export const selectAdSourceSelectedRowKeys = (state: RootState) => state.bulkOperations.adSource.selectedRowKeys;
export const selectAdUnitSelectedRowKeys = (state: RootState) => state.bulkOperations.adUnit.selectedRowKeys;
export const selectBuyerDealSelectedRowKeys = (state: RootState) => state.bulkOperations.buyerDeal.selectedRowKeys;
export const selectDemandDealSelectedRowKeys = (state: RootState) => state.bulkOperations.demandDeal.selectedRowKeys;

export const selectAdSourceSelectedRows = (state: RootState) => state.bulkOperations.adSource.selectedRows;
export const selectAdUnitSelectedRows = (state: RootState) => state.bulkOperations.adUnit.selectedRows;
export const selectBuyerDealSelectedRows = (state: RootState) => state.bulkOperations.buyerDeal.selectedRows;
export const selectDemandDealSelectedRows = (state: RootState) => state.bulkOperations.demandDeal.selectedRows;

export const selectAdSourceTouchedRecordsInfo = (state: RootState) => state.bulkOperations.adSource.touchedRecordsInfo;
export const selectAdUnitTouchedRecordsInfo = (state: RootState) => state.bulkOperations.adUnit.touchedRecordsInfo;
export const selectBuyerDealTouchedRecordsInfo = (state: RootState) =>
    state.bulkOperations.buyerDeal.touchedRecordsInfo;
export const selectDemandDealTouchedRecordsInfo = (state: RootState) =>
    state.bulkOperations.demandDeal.touchedRecordsInfo;

export const selectAdSourcePageInfo = createSelector(
    (state: RootState) => state.bulkOperations.adSource,
    ({ current, pageSize }) => ({ current, pageSize })
);
export const selectAdUnitPageInfo = createSelector(
    (state: RootState) => state.bulkOperations.adUnit,
    ({ current, pageSize }) => ({ current, pageSize })
);
export const selectBuyerDealPageInfo = createSelector(
    (state: RootState) => state.bulkOperations.buyerDeal,
    ({ current, pageSize }) => ({ current, pageSize })
);
export const selectDemandDealPageInfo = createSelector(
    (state: RootState) => state.bulkOperations.demandDeal,
    ({ current, pageSize }) => ({ current, pageSize })
);

export const selectAdSourceResponse = (state: RootState) => state.bulkOperations.adSource.response;
export const selectAdUnitResponse = (state: RootState) => state.bulkOperations.adUnit.response;
export const selectBuyerDealResponse = (state: RootState) => state.bulkOperations.buyerDeal.response;
export const selectDemandDealResponse = (state: RootState) => state.bulkOperations.demandDeal.response;

export const selectIsAdSourceEntitySelectionTouched = (state: RootState) =>
    state.bulkOperations.adSource.isEntitySelectionTouched;
export const selectIsAdUnitEntitySelectionTouched = (state: RootState) =>
    state.bulkOperations.adUnit.isEntitySelectionTouched;
export const selectIsBuyerDealEntitySelectionTouched = (state: RootState) =>
    state.bulkOperations.buyerDeal.isEntitySelectionTouched;
export const selectIsDemandDealEntitySelectionTouched = (state: RootState) =>
    state.bulkOperations.demandDeal.isEntitySelectionTouched;

export const selectHasTableSelection = (state: RootState) => {
    const { adSource, adUnit, buyerDeal, demandDeal } = state.bulkOperations;

    return Boolean(
        adSource.selectedRowKeys.length ||
            adUnit.selectedRowKeys.length ||
            buyerDeal.selectedRowKeys.length ||
            demandDeal.selectedRowKeys.length
    );
};

const getHasChangedRecord = (touchedRecordsInfo: Record<number, TouchedFieldInfo[] | undefined>) =>
    Object.values(touchedRecordsInfo)
        .filter(Boolean)
        .flat()
        .some(({ isChanged }: TouchedFieldInfo) => isChanged);

export const selectHasChangedEditField = createSelector(
    (state: RootState) => state.bulkOperations,
    (_, entityType) => entityType,
    (bulkOperations: BulkOperationsState, entityType: BulkOperationEntityType) =>
        getHasChangedRecord(bulkOperations[entityType].touchedRecordsInfo)
);

export const selectTouchedRecordsInfo = createSelector(
    (state: RootState) => state.bulkOperations,
    (_, entityType) => entityType,
    (bulkOperations: BulkOperationsState, entityType: BulkOperationEntityType) =>
        bulkOperations[entityType].touchedRecordsInfo
);

export const selectIsEntityEditTouched = createSelector(
    (state: RootState) => state.bulkOperations,
    (_, entityType) => entityType,
    (bulkOperations: BulkOperationsState, entityType: BulkOperationEntityType) =>
        bulkOperations[entityType].isEntityEditTouched
);

export default bulkOperationsSlice;
