import { useState } from "react";
import { BrandSafetyCollapsePanelKeys } from "../BrandSafetyCollapsePanel";

export const useBrandSafetyCollapsePanel = ({ defaultActiveKey }) => {
    const [activeKey, setActiveKey] = useState(defaultActiveKey);

    const onChange = (key) => {
        setActiveKey(key);
    };

    const expandAll = () => {
        setActiveKey(Object.values(BrandSafetyCollapsePanelKeys));
    };

    const collapseAll = () => {
        setActiveKey([]);
    };

    return {
        activeKey,
        onChange,
        expandAll,
        collapseAll,
    };
};
