import { FC } from "react";
import { AdUnitMaxExtended } from "@app/features/inventory/components/FormItems";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const MaxExtended: FC = () => {
    return (
        <AdUnitMaxExtended
            name={AD_UNIT_FORM_FIELDS.MAX_EXTENDED.name}
            label={AD_UNIT_FORM_FIELDS.MAX_EXTENDED.label}
        />
    );
};
