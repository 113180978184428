import { EditOutlined } from "@ant-design/icons";
import { Space, Row, Col, Select, Button, Tag, Form, Flex, FormListFieldData } from "antd";
import { EXTENDED_USER_ID_SDET, EXTENDED_USER_ID_BUTTON_SDET } from "./helpers";
import { LV, ExtendedUserSelectValueType, EXTENDED_USER_ID_OPTIONS, SPECIFIC } from "./constants";

export interface Props {
    extendedIds: Record<string, LV[]>;
    handleExtendedUserSelect: (val: ExtendedUserSelectValueType) => (id: number) => void;
    handleRemove: (val: string, id: number) => void;
    id: number;
    i: number;
    fields: FormListFieldData[];
}
export const ControlTableExtendedUserIdSelect = ({
    extendedIds,
    handleExtendedUserSelect,
    handleRemove,
    id,
    i,
    fields,
}: Props) => {
    const transparency = Form.useWatch("transparency");
    const value = transparency?.[i]?.controlExtendedUserId;

    return (
        <Space direction="vertical">
            <Flex>
                <Space align="start">
                    <Col>
                        <Form.Item name={[fields[i].name, "controlExtendedUserId"]}>
                            <Select
                                data-sdet={EXTENDED_USER_ID_SDET}
                                options={EXTENDED_USER_ID_OPTIONS}
                                value={value}
                                onSelect={(value: ExtendedUserSelectValueType) => handleExtendedUserSelect(value)(id)}
                            />
                        </Form.Item>
                    </Col>
                    {value === SPECIFIC && Boolean(extendedIds?.[id]?.length) && (
                        <Col>
                            <Button
                                data-sdet={EXTENDED_USER_ID_BUTTON_SDET}
                                icon={<EditOutlined />}
                                onClick={() => handleExtendedUserSelect(SPECIFIC)(id)}
                            />
                        </Col>
                    )}
                </Space>
            </Flex>

            {value === SPECIFIC && (
                <Row>
                    <Space direction="vertical">
                        {extendedIds?.[id]?.map(({ label, value }) => (
                            <Tag
                                color="processing"
                                data-sdet={EXTENDED_USER_ID_SDET.concat(`ed-${value}`)}
                                key={value}
                                closable
                                onClose={() => handleRemove(String(value), id)}
                            >
                                {label}
                            </Tag>
                        ))}
                    </Space>
                </Row>
            )}
        </Space>
    );
};
