import {
    AdUnitsApiFramework,
    Audience,
    Category,
    ContentCategory,
    ContentMetadataContentLength,
    ContentMetadataTvSeries,
    GeoTarget,
    LabelValue,
    MimeType,
    OperatingSystem,
    OztamDemo,
    Platform,
    PlayerSize,
    SupplyType,
    Targeting,
    TargetingIncludeExclude,
} from "@app/core/services/console";
import {
    addIncludeExcludesDimensions,
    addMultiSourceIncludeExcludesDimensions,
    getTargetingBlockDefault,
} from "./utils";
import {
    Conditions,
    PmpConditions,
    SegmentRuleModes,
    TargetingDimensionTypeLabels,
    TargetingDimensionTypes,
    TimeZoneModeIds,
} from "../../constants";
import {
    AdBreakPositionTargetsTargetingDimension,
    ApiFrameworksTargetingDimension,
    AudiencesTargetingDimension,
    BundleIdTargetsDimension,
    CategoriesTargetingDimension,
    ContentCategoriesTargetingDimension,
    ContentChannelsTargetingDimension,
    ContentLengthsTargetingDimension,
    ContentNetworksTargetingDimension,
    ContentRatingsTargetingDimension,
    ContentSeriesTargetingDimension,
    CoppaTargetingDimension,
    CustomRuleGroup,
    CustomRuleTargetingDimension,
    CustomTargetsDimension,
    DayPartTargetsTargetingDimension,
    DntDimension,
    GenresTargetingDimension,
    GeoTargetsTargetingDimension,
    InventoryDimension,
    LabelValuesTargetingDimension,
    LiveStreamDimension,
    MaxDurationTarget,
    MaxDurationTargetDimension,
    MimeTypesTargetingDimension,
    MinDurationTarget,
    MinDurationTargetDimension,
    OperatingSystemsTargetingDimension,
    OztamDemoDimension,
    PlatformTargetingDimension,
    PmpDimension,
    PodSlotPositionTargetsTargetingDimension,
    ProducersTargetingDimension,
    SegmentRulesTargetingDimension,
    SizesTargetingDimension,
    SupplyDomainTargetsDimension,
    SupplyTypesTargetsDimension,
    TargetingBlock,
    TargetingStub,
    UsedDimensions,
    VideoIdsTargetingDimension,
} from "../../types";
import {
    apiFrameworkToLabelValue,
    categoryToLabelValue,
    contentMetadataContentChannelToLabelValue,
    contentMetadataGenresToLabelValue,
    contentMetadataNetworksToLabelValue,
    contentMetadataTitleToLabelValue,
    contentMetadataTvSeriesToLabelValue,
    getDefaultSegmentRuleGroup,
    hasIdNameToLabelValue,
    inventoryToLabelValue,
    operatingSystemToLabelValue,
    playerSizesToLabelValue,
    replaceSegmentRuleGroupValues,
    segmentsToLabelValue,
    supplyTypesToLabelValue,
} from "@app/features/targeting";

export const targetingToTargetingBlock = (targetingPayload: Targeting): Targeting | TargetingBlock => {
    const isReusableTargeting = targetingPayload.id && targetingPayload.name;

    if (isReusableTargeting) {
        // If the targeting is already reusable, no need to change it at all
        return targetingPayload;
    }

    return toTargetingBlock(targetingPayload);
};

// This should only be used to calculate properties across targeting blocks, like for validation, and not for any payloads
export const targetingToTargetingBlocks = (targeting: (Targeting | TargetingStub | TargetingBlock)[]) => {
    return targeting.map((target) => {
        if ("include" in target && "exclude" in target) {
            return toTargetingBlock(target);
        }
        return target;
    });
};

export const toTargetingBlock = (targetingPayload: Targeting): TargetingBlock => {
    const targetingBlock = getTargetingBlockDefault();

    addIncludeExcludesDimensions<TargetingIncludeExclude["adBreakPositionTargets"]>(
        targetingPayload,
        targetingBlock,
        "adBreakPositionTargets",
        adBreakPositionTargetingsPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["audiences"]>(
        targetingPayload,
        targetingBlock,
        "audiences",
        audiencesDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["apiFrameworks"]>(
        targetingPayload,
        targetingBlock,
        "apiFrameworks",
        apiFrameworksDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["supplyTypes"]>(
        targetingPayload,
        targetingBlock,
        "supplyTypes",
        supplyTypesDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["supplyDomainTargets"]>(
        targetingPayload,
        targetingBlock,
        "supplyDomainTargets",
        supplyDomainsDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["bundleIdTargets"]>(
        targetingPayload,
        targetingBlock,
        "bundleIdTargets",
        bundleIdDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["categories"]>(
        targetingPayload,
        targetingBlock,
        "categories",
        categoriesDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["customRules"]>(
        targetingPayload,
        targetingBlock,
        "customRules",
        customRuleDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["coppa"]>(
        targetingPayload,
        targetingBlock,
        "coppa",
        coppaDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["contentCategories"]>(
        targetingPayload,
        targetingBlock,
        "contentCategories",
        contentCategoriesDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["contentChannels"]>(
        targetingPayload,
        targetingBlock,
        "contentChannels",
        contentChannelsDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["contentLengths"]>(
        targetingPayload,
        targetingBlock,
        "contentLengths",
        contentLengthsDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["contentNetworks"]>(
        targetingPayload,
        targetingBlock,
        "contentNetworks",
        contentNetworksDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["contentRatings"]>(
        targetingPayload,
        targetingBlock,
        "contentRatings",
        contentRatingsDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["contentSeries"]>(
        targetingPayload,
        targetingBlock,
        "contentSeries",
        contentSeriesDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["geoTargets"]>(
        targetingPayload,
        targetingBlock,
        "geoTargets",
        geoDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["platforms"]>(
        targetingPayload,
        targetingBlock,
        "platforms",
        platformDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["maxDurationTarget"]>(
        targetingPayload,
        targetingBlock,
        "maxDurationTarget",
        maxDurationDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["minDurationTarget"]>(
        targetingPayload,
        targetingBlock,
        "minDurationTarget",
        minDurationDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["mimeTypes"]>(
        targetingPayload,
        targetingBlock,
        "mimeTypes",
        mimeTypeDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["oztamDemographics"]>(
        targetingPayload,
        targetingBlock,
        "oztamDemographics",
        bvodDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["genres"]>(
        targetingPayload,
        targetingBlock,
        "genres",
        genresDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["operatingSystems"]>(
        targetingPayload,
        targetingBlock,
        "operatingSystems",
        operatingSystemDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["labelValues"]>(
        targetingPayload,
        targetingBlock,
        "labelValues",
        labelValueDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["podSlotPositionTargets"]>(
        targetingPayload,
        targetingBlock,
        "podSlotPositionTargets",
        podSlotPositionTargetingDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["videoIds"]>(
        targetingPayload,
        targetingBlock,
        "videoIds",
        videoIdsDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["dnt"]>(
        targetingPayload,
        targetingBlock,
        "dnt",
        dntDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["liveStream"]>(
        targetingPayload,
        targetingBlock,
        "liveStream",
        liveStreamDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["sizes"]>(
        targetingPayload,
        targetingBlock,
        "sizes",
        playerSizesDimensionPayloadToViewModel
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["customTargets"]>(
        targetingPayload,
        targetingBlock,
        "customTargets",
        customTargetsDimensionPayloadToViewModel
    );

    addMultiSourceIncludeExcludesDimensions(
        targetingPayload,
        targetingBlock,
        inventoryDimensionsPayloadToViewModel,
        (targetingPayload) => hasInventoryTargeting(targetingPayload, "include"),
        (targetingPayload) => hasInventoryTargeting(targetingPayload, "exclude")
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["pmpDealIdTargets"]>(
        targetingPayload,
        targetingBlock,
        "pmpDealIdTargets",
        pmpDimensionPayloadToViewModel,
        (targetingPayload) => Boolean(targetingPayload?.include?.pmpDealIdTargets?.length),
        (targetingPayload) =>
            Boolean(
                targetingPayload?.exclude?.pmpDealIdTargets?.length &&
                    targetingPayload?.exclude?.pmpDealIdTargets[0] !== "*"
            )
    );

    addMultiSourceIncludeExcludesDimensions(
        targetingPayload,
        targetingBlock,
        dayPartingDimensionPayloadToViewModel,
        (targetingPayload) =>
            Boolean(targetingPayload.include?.dayPartTargets?.length && targetingPayload.include.timeZoneMode),
        (targetingPayload) =>
            Boolean(targetingPayload.exclude?.dayPartTargets?.length && targetingPayload.exclude.timeZoneMode)
    );

    addIncludeExcludesDimensions<TargetingIncludeExclude["producers"]>(
        targetingPayload,
        targetingBlock,
        "producers",
        producersDimensionPayloadToViewModel
    );

    addMultiSourceIncludeExcludesDimensions(
        targetingPayload,
        targetingBlock,
        segmentRulesDimensionPayloadToViewModel,
        (targetingPayload) =>
            Boolean(targetingPayload?.include?.segmentRules?.length || targetingPayload?.include?.segments?.length),
        (targetingPayload) =>
            Boolean(targetingPayload?.exclude?.segmentRules?.length || targetingPayload?.exclude?.segments?.length)
    );

    return targetingBlock;
};

const adBreakPositionTargetingsPayloadToViewModel = (
    dimension: number[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): AdBreakPositionTargetsTargetingDimension => {
    const viewModel: AdBreakPositionTargetsTargetingDimension = {
        type: TargetingDimensionTypes.AdBreakPositionTargets,
        condition: condition,
        values: dimension,
    };

    usedDimensions.adBreakPositionTargets[condition] = true;

    return viewModel;
};

const audiencesDimensionPayloadToViewModel = (
    dimension: Audience[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): AudiencesTargetingDimension => {
    const viewModel: AudiencesTargetingDimension = {
        type: TargetingDimensionTypes.Audiences,
        condition,
        values: dimension.length ? dimension[0] : null,
    };

    usedDimensions.audiences[condition] = true;

    return viewModel;
};

const apiFrameworksDimensionPayloadToViewModel = (
    dimension: AdUnitsApiFramework[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): ApiFrameworksTargetingDimension => {
    const viewModel: ApiFrameworksTargetingDimension = {
        type: TargetingDimensionTypes.ApiFrameworks,
        condition,
        values: apiFrameworkToLabelValue(dimension),
    };

    usedDimensions.apiFrameworks[condition] = true;

    return viewModel;
};

const customRuleDimensionPayloadToViewModel = (
    dimension: string[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): CustomRuleTargetingDimension => {
    const viewModel: CustomRuleTargetingDimension = {
        type: TargetingDimensionTypes.CustomRules,
        condition,
        ruleGroup: JSON.parse(dimension[0]) as CustomRuleGroup,
    };

    usedDimensions.customRules[condition] = true;

    return viewModel;
};

const geoDimensionPayloadToViewModel = (
    dimension: GeoTarget[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): GeoTargetsTargetingDimension => {
    const viewModel: GeoTargetsTargetingDimension = {
        type: TargetingDimensionTypes.GeoTargets,
        condition: condition,
        values: dimension,
    };

    usedDimensions.geoTargets[condition] = true;

    return viewModel;
};

const supplyDomainsDimensionPayloadToViewModel = (
    dimension: string[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): SupplyDomainTargetsDimension => {
    const viewModel: SupplyDomainTargetsDimension = {
        type: TargetingDimensionTypes.SupplyDomainTargets,
        condition,
        values: dimension,
    };

    usedDimensions.supplyDomainTargets[condition] = true;

    return viewModel;
};

const supplyTypesDimensionPayloadToViewModel = (
    dimension: SupplyType[],
    condition: Conditions,
    usedDimensions: UsedDimensions
) => {
    const viewModel: SupplyTypesTargetsDimension = {
        type: TargetingDimensionTypes.SupplyTypes,
        condition,
        values: supplyTypesToLabelValue(dimension),
    };

    usedDimensions.supplyTypes[condition] = true;

    return viewModel;
};

const platformDimensionPayloadToViewModel = (
    dimension: Platform[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): PlatformTargetingDimension => {
    const viewModel: PlatformTargetingDimension = {
        type: TargetingDimensionTypes.Platforms,
        condition,
        values: hasIdNameToLabelValue(dimension),
    };

    usedDimensions.platforms[condition] = true;

    return viewModel;
};

const maxDurationDimensionPayloadToViewModel = (
    dimension: MaxDurationTarget[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): MaxDurationTargetDimension => {
    const viewModel: MaxDurationTargetDimension = {
        type: TargetingDimensionTypes.MaxDurationTarget,
        condition: condition,
        values: dimension[0],
    };

    usedDimensions.maxDurationTarget[condition] = true;

    return viewModel;
};

const minDurationDimensionPayloadToViewModel = (
    dimension: MinDurationTarget[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): MinDurationTargetDimension => {
    const viewModel: MinDurationTargetDimension = {
        type: TargetingDimensionTypes.MinDurationTarget,
        condition: condition,
        values: dimension[0],
    };

    usedDimensions.minDurationTarget[condition] = true;

    return viewModel;
};

const mimeTypeDimensionPayloadToViewModel = (
    dimension: MimeType[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): MimeTypesTargetingDimension => {
    const viewModel: MimeTypesTargetingDimension = {
        type: TargetingDimensionTypes.MimeTypes,
        condition: condition,
        values: hasIdNameToLabelValue(dimension),
    };

    usedDimensions.mimeTypes[condition] = true;

    return viewModel;
};

const labelValueDimensionPayloadToViewModel = (
    dimension: LabelValue[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): LabelValuesTargetingDimension => {
    const viewModel: LabelValuesTargetingDimension = {
        type: TargetingDimensionTypes.LabelValues,
        condition,
        values: dimension.map((target) => ({
            value: JSON.stringify(target),
            label: `${target.label.key}: ${target.value}`,
        })),
    };

    usedDimensions.labelValues[condition] = true;

    return viewModel;
};

// Inventory
const inventoryKeys = ["adUnits", "brands", "publishers", "supply"] as const;

const hasInventoryTargeting = (targetingPayload: Targeting, conditionKey: "include" | "exclude") => {
    return inventoryKeys.some((key) => targetingPayload[conditionKey]?.[key]?.length);
};

export const inventoryDimensionsPayloadToViewModel = (
    targetingPayloadIncludeExclude: TargetingIncludeExclude,
    condition: Conditions,
    usedDimensions: UsedDimensions
) => {
    const inventoryViewModel: InventoryDimension = {
        type: TargetingDimensionTypes.Inventory as const,
        condition,
        values: [],
    };

    inventoryKeys.forEach((key) => {
        if (targetingPayloadIncludeExclude[key]?.length) {
            inventoryViewModel.values.push(...inventoryToLabelValue(targetingPayloadIncludeExclude[key]));
            usedDimensions.inventory[condition] = true;
        }
    });

    return inventoryViewModel;
};

const bundleIdDimensionPayloadToViewModel = (
    dimension: string[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): BundleIdTargetsDimension => {
    const viewModel: BundleIdTargetsDimension = {
        type: TargetingDimensionTypes.BundleIdTargets,
        condition,
        values: dimension,
    };

    usedDimensions.bundleIdTargets[condition] = true;

    return viewModel;
};

const categoriesDimensionPayloadToViewModel = (
    dimension: Category[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): CategoriesTargetingDimension => {
    const viewModel: CategoriesTargetingDimension = {
        type: TargetingDimensionTypes.Categories,
        condition,
        values: categoryToLabelValue(dimension, TargetingDimensionTypeLabels.Categories),
    };

    usedDimensions.categories[condition] = true;

    return viewModel;
};

const contentCategoriesDimensionPayloadToViewModel = (
    dimension: ContentCategory[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): ContentCategoriesTargetingDimension => {
    const viewModel: ContentCategoriesTargetingDimension = {
        type: TargetingDimensionTypes.ContentCategories,
        condition,
        values: categoryToLabelValue(dimension, TargetingDimensionTypeLabels.ContentCategories),
    };

    usedDimensions.contentCategories[condition] = true;

    return viewModel;
};

const bvodDimensionPayloadToViewModel = (
    dimension: OztamDemo[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): OztamDemoDimension => {
    const viewModel: OztamDemoDimension = {
        type: TargetingDimensionTypes.Bvod,
        condition: condition,
        values: hasIdNameToLabelValue(dimension),
    };

    usedDimensions.oztamDemographics[condition] = true;

    return viewModel;
};

const genresDimensionPayloadToViewModel = (
    dimension: string[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): GenresTargetingDimension => {
    const viewModel: GenresTargetingDimension = {
        type: TargetingDimensionTypes.Genres,
        condition: condition,
        values: contentMetadataGenresToLabelValue(dimension),
    };

    usedDimensions.genres[condition] = true;

    return viewModel;
};

const operatingSystemDimensionPayloadToViewModel = (
    dimension: OperatingSystem[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): OperatingSystemsTargetingDimension => {
    const viewModel: OperatingSystemsTargetingDimension = {
        type: TargetingDimensionTypes.OperatingSystems,
        condition: condition,
        values: operatingSystemToLabelValue(dimension),
    };

    usedDimensions.operatingSystems[condition] = true;

    return viewModel;
};

const dayPartingDimensionPayloadToViewModel = (
    targetingPayloadIncludeExclude: TargetingIncludeExclude,
    condition: Conditions,
    usedDimensions: UsedDimensions
): DayPartTargetsTargetingDimension => {
    const viewModel: DayPartTargetsTargetingDimension = {
        type: TargetingDimensionTypes.DayPartTargets,
        condition,
        values: targetingPayloadIncludeExclude.dayPartTargets || [],
        timeZoneMode:
            String(targetingPayloadIncludeExclude.timeZoneMode?.id) === TimeZoneModeIds.ClientTimeZone
                ? TimeZoneModeIds.ClientTimeZone
                : TimeZoneModeIds.UTC,
    };

    usedDimensions.dayPartTargets[condition] = true;

    return viewModel;
};

const contentChannelsDimensionPayloadToViewModel = (
    dimension: string[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): ContentChannelsTargetingDimension => {
    const viewModel: ContentChannelsTargetingDimension = {
        type: TargetingDimensionTypes.ContentChannels,
        condition,
        values: contentMetadataContentChannelToLabelValue(dimension),
    };

    usedDimensions.contentChannels[condition] = true;

    return viewModel;
};

const contentLengthsDimensionPayloadToViewModel = (
    dimension: ContentMetadataContentLength[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): ContentLengthsTargetingDimension => {
    const viewModel: ContentLengthsTargetingDimension = {
        type: TargetingDimensionTypes.ContentLengths,
        condition,
        values: hasIdNameToLabelValue(dimension),
    };

    usedDimensions.contentLengths[condition] = true;

    return viewModel;
};

const contentNetworksDimensionPayloadToViewModel = (
    dimension: string[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): ContentNetworksTargetingDimension => {
    const viewModel: ContentNetworksTargetingDimension = {
        type: TargetingDimensionTypes.ContentNetworks,
        condition,
        values: contentMetadataNetworksToLabelValue(dimension),
    };

    usedDimensions.contentNetworks[condition] = true;

    return viewModel;
};

const contentRatingsDimensionPayloadToViewModel = (
    dimension: string[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): ContentRatingsTargetingDimension => {
    const viewModel: ContentRatingsTargetingDimension = {
        type: TargetingDimensionTypes.ContentRatings,
        condition,
        // TODO: Label will different than what we generate from the API value, though it looks like the legacy app has the same problem
        values: dimension.map((v) => ({ label: v, value: v })),
    };

    usedDimensions.contentRatings[condition] = true;

    return viewModel;
};

const contentSeriesDimensionPayloadToViewModel = (
    dimension: ContentMetadataTvSeries[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): ContentSeriesTargetingDimension => {
    const viewModel: ContentSeriesTargetingDimension = {
        type: TargetingDimensionTypes.ContentSeries,
        condition,
        values: contentMetadataTvSeriesToLabelValue(dimension),
    };

    usedDimensions.contentSeries[condition] = true;

    return viewModel;
};

const getPmpCondition = (dimension: string[], condition: Conditions): PmpConditions => {
    if (condition === Conditions.Includes) {
        return dimension[0] === "*" ? PmpConditions.IncludesAny : PmpConditions.IncludesSpecific;
    }
    return dimension[0] === "*" ? PmpConditions.ExcludesAny : PmpConditions.ExcludesSpecific;
};

const pmpDimensionPayloadToViewModel = (
    dimension: string[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): PmpDimension => {
    const viewModel: PmpDimension = {
        type: TargetingDimensionTypes.Pmp,
        condition,
        pmpCondition: getPmpCondition(dimension, condition),
        values: dimension,
    };

    usedDimensions.pmpDealIdTargets[condition] = true;

    return viewModel;
};

const coppaDimensionPayloadToViewModel = (
    dimension: [true],
    condition: Conditions,
    usedDimensions: UsedDimensions
): CoppaTargetingDimension => {
    const viewModel: CoppaTargetingDimension = {
        type: TargetingDimensionTypes.Coppa,
        condition,
        values: dimension,
    };

    usedDimensions.coppa[condition] = true;

    return viewModel;
};

const podSlotPositionTargetingDimensionPayloadToViewModel = (
    dimension: number[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): PodSlotPositionTargetsTargetingDimension => {
    const viewModel: PodSlotPositionTargetsTargetingDimension = {
        type: TargetingDimensionTypes.PodSlotPositionTargets,
        condition,
        values: dimension,
    };

    usedDimensions.podSlotPositionTargets[condition] = true;

    return viewModel;
};

const producersDimensionPayloadToViewModel = (
    dimension: string[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): ProducersTargetingDimension => {
    const viewModel: ProducersTargetingDimension = {
        type: TargetingDimensionTypes.Producers,
        condition: condition,
        // TODO: This will create a label that is different from what we show when we get the value from the API, though it looks like the legacy app has the same problem
        values: dimension.map((v) => ({ label: v, value: v })),
    };

    usedDimensions.producers[condition] = true;

    return viewModel;
};

const videoIdsDimensionPayloadToViewModel = (
    dimension: [],
    condition: Conditions,
    usedDimensions: UsedDimensions
): VideoIdsTargetingDimension => {
    const viewModel: VideoIdsTargetingDimension = {
        type: TargetingDimensionTypes.VideoIds,
        condition,
        values: contentMetadataTitleToLabelValue(dimension),
    };

    usedDimensions.videoIds[condition] = true;

    return viewModel;
};

const dntDimensionPayloadToViewModel = (
    dimension: [true],
    condition: Conditions,
    usedDimensions: UsedDimensions
): DntDimension => {
    const viewModel: DntDimension = {
        type: TargetingDimensionTypes.Dnt,
        condition,
        values: dimension,
    };

    usedDimensions.dnt[condition] = true;

    return viewModel;
};

const liveStreamDimensionPayloadToViewModel = (
    dimension: [true],
    condition: Conditions,
    usedDimensions: UsedDimensions
): LiveStreamDimension => {
    const viewModel: LiveStreamDimension = {
        type: TargetingDimensionTypes.LiveStream,
        condition,
        values: dimension,
    };

    usedDimensions.liveStream[condition] = true;

    return viewModel;
};

const playerSizesDimensionPayloadToViewModel = (
    dimension: PlayerSize[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): SizesTargetingDimension => {
    const viewModel: SizesTargetingDimension = {
        type: TargetingDimensionTypes.Sizes,
        condition,
        values: playerSizesToLabelValue(dimension),
    };

    usedDimensions.sizes[condition] = true;

    return viewModel;
};

const customTargetsDimensionPayloadToViewModel = (
    dimension: string[],
    condition: Conditions,
    usedDimensions: UsedDimensions
): CustomTargetsDimension => {
    const viewModel: CustomTargetsDimension = {
        type: TargetingDimensionTypes.CustomTargets,
        condition,
        values: dimension,
    };

    usedDimensions.customTargets[condition] = true;

    return viewModel;
};

const segmentRulesDimensionPayloadToViewModel = (
    targetingPayloadIncludeExclude: TargetingIncludeExclude,
    condition: Conditions,
    usedDimensions: UsedDimensions
) => {
    const viewModel: SegmentRulesTargetingDimension = {
        type: TargetingDimensionTypes.SegmentRules,
        condition,
        mode: targetingPayloadIncludeExclude.segmentRules?.length ? SegmentRuleModes.Advanced : SegmentRuleModes.Simple,
        ruleGroup: targetingPayloadIncludeExclude.segmentRules?.length
            ? replaceSegmentRuleGroupValues(
                  JSON.parse(targetingPayloadIncludeExclude.segmentRules[0]),
                  targetingPayloadIncludeExclude.targetedSegments || []
              )
            : getDefaultSegmentRuleGroup(),
        values: segmentsToLabelValue(
            targetingPayloadIncludeExclude.segmentRules?.length
                ? targetingPayloadIncludeExclude.targetedSegments
                : targetingPayloadIncludeExclude.segments
        ),
    };

    usedDimensions.segmentRules[condition] = true;

    return viewModel;
};
