import { FC } from "react";
import { useTableFilterSearch } from "@app/core/components/hooks/useTableFilterSearch";
import { selectCustomPixelsSearchFilter, setSearchFilter } from "../reducer";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { brandSlate } from "@rubicon/antd-components";

export const CustomPixelsTableFilterSearch: FC = () => {
    const { localValue, handleChange } = useTableFilterSearch({
        selectSearchFilter: selectCustomPixelsSearchFilter,
        setSearchFilter,
    });

    return (
        <Input
            allowClear
            data-sdet="custom-pixels-table-search-input"
            onChange={handleChange}
            placeholder="Search Name, Type or Pixel URL"
            suffix={!localValue && <SearchOutlined style={{ color: brandSlate }} />}
            value={localValue}
        />
    );
};
