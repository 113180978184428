export const TAG_TYPE = { key: "tagType", default: "2" };
export const MACROS_PICKER = { key: "macrosPicker", default: "" };
export const EDIT_MODE = { key: "_editMode", default: "" };

export type URL_PARAM = typeof TAG_TYPE | typeof MACROS_PICKER | typeof EDIT_MODE;

export const MACROS_PICKER_OPTIONS = [
    { value: "1", label: "DFP" },
    { value: "2", label: "Freewheel" },
    { value: "3", label: "JW Player" },
    { value: "4", label: "OpenX" },
];

export const DFP_MACRO = [{ name: "srcPageUrl", macro: "%%REFERRER_URL_ESC_ESC%%" }];

export const FREEWHEEL_MACRO = [
    { name: "playerWidth", macro: "#{slot.width}" },
    { name: "playerHeight", macro: "#{slot.height}" },
    { name: "mediaId", macro: "#{video.customId}" },
    { name: "mediaTitle", macro: "#{asset.title}" },
];

export const JWPLAYER_MACRO = [
    { name: "playerWidth", macro: "__player-width__" },
    { name: "playerHeight", macro: "__player-height__" },
    { name: "mediaId", macro: "__item-mediaid__" },
    { name: "mediaTitle", macro: "__item-title__" },
    { name: "srcPageUrl", macro: "__page-url__" },
];

export const OPENX_MACRO = [{ name: "srcPageUrl", macro: "{techno.page_url_enc}" }];

export const MACROS_PICKER_MAPPER = {
    "1": DFP_MACRO,
    "2": FREEWHEEL_MACRO,
    "3": JWPLAYER_MACRO,
    "4": OPENX_MACRO,
};
