import { useMemo, useState } from "react";
import { LabeledValue } from "antd/lib/select";
import { useGetAdUnitQuery, useGetTimingStatsAdUnitSnapshotsQuery } from "@app/core/services";
import { getSnapshotDateLabel } from "@app/features/inventory/InventorySeat/SeatDetailsPage/SeatDetailsSection/PerformanceSection/Snapshots/SnapshotsDrawer/useSnapshots";

export const LIMITS_SELECTED_SNAPSHOTS = 6;

export interface SnapshotData {
    label: string;
    value: string;
    id: 0 | 1;
}

interface UseSnapshots {
    isLoading: boolean;
    values: LabeledValue[];
    options: LabeledValue[];
    handleChange: (options: LabeledValue[]) => void;
    handleDeselect: (value: string) => void;
}

export const useSnapshots = (id: number): UseSnapshots => {
    const [values, setValues] = useState<LabeledValue[]>([]);
    const { data } = useGetAdUnitQuery(id);
    const multiplicity = useMemo(() => data?.multiplicity.id, [data]) as number;

    const { data: snapshots, isFetching } = useGetTimingStatsAdUnitSnapshotsQuery({ id, multiplicity });

    const options = useMemo(
        () =>
            (snapshots || []).map((snapshot) => ({
                label: getSnapshotDateLabel(snapshot),
                value: snapshot.filename,
            })),
        [snapshots]
    ).sort((a, b) => b.value.localeCompare(a.value));

    const handleChange = (options: LabeledValue[]) => {
        if (options.length > LIMITS_SELECTED_SNAPSHOTS) {
            return;
        }
        setValues(options);
    };

    const handleDeselect = (value: string) => {
        setValues((v) => v.filter((item) => item.value !== value));
    };

    return {
        values,
        options,
        isLoading: isFetching,
        handleChange,
        handleDeselect,
    };
};
