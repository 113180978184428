import { FC } from "react";
import { Col, Row, Typography } from "antd";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "../../../../types";
import { adSourceDetailsHelpKeys } from "../SeatAdSourcesDetailsDrawerNestedTitle/constants";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";

interface Props {
    adSourceName: string;
    secondaryDetailsView: SecondaryDetailsView;
}

export const SeatAdSourcesDetailsDrawerDirectTitle: FC<Props> = ({ adSourceName, secondaryDetailsView }) => {
    return (
        <Row data-sdet="seat-ad-sources-details-drawer-direct-title" justify="space-between" align="middle">
            <Col>
                <Typography.Title level={5} style={{ fontWeight: "bold", margin: 0 }}>
                    {`${secondaryDetailsView}: `}
                    <Typography.Text style={{ fontWeight: "normal" }}>{adSourceName}</Typography.Text>
                </Typography.Title>
            </Col>
            <Row align="middle" style={{ marginRight: "12px" }}>
                <HelpKeysDrawer
                    panelLabel={secondaryDetailsView}
                    definitionsFields={adSourceDetailsHelpKeys[secondaryDetailsView]}
                />
            </Row>
        </Row>
    );
};
