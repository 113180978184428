import { FC } from "react";
import { Col, Row, Select, Typography } from "antd";
import { css } from "@emotion/css";
import { useAdBidStats } from "./useAdBidStats";
import { AdStatsCard } from "./AdStats/AdStatsCard";
import { BidStatsCard } from "./BidStats/BidStatsCard";

interface Props {
    adSourceId: number;
}

export const AdBidStats: FC<Props> = ({ adSourceId }) => {
    const { dealFilterOptions, dealFilterValue, handleChangeValue } = useAdBidStats(adSourceId);

    return (
        <>
            <Col
                className={css`
                    padding: 1.5rem;
                    padding-bottom: 0;
                `}
            >
                <Row justify="start">
                    <Typography.Text style={{ fontWeight: "bold" }}>Deal</Typography.Text>
                </Row>
                <Row>
                    <Col span={10}>
                        <Select
                            style={{ width: "100%" }}
                            placeholder="Deal Name"
                            options={dealFilterOptions}
                            value={dealFilterValue}
                            onChange={handleChangeValue}
                        />
                    </Col>
                </Row>
            </Col>
            <Row>
                <Col
                    className={css`
                        padding: 1.5rem;
                        padding-right: 0;
                    `}
                    span={8}
                >
                    <AdStatsCard adSourceId={adSourceId} />
                </Col>
                <Col
                    className={css`
                        padding: 1.5rem;
                    `}
                    span={16}
                >
                    <BidStatsCard adSourceId={adSourceId} />
                </Col>
            </Row>
        </>
    );
};
