import { FC, HTMLAttributes, Key, ReactNode, useEffect, useState } from "react";
import { Grants } from "@app/core/services";
import { Permissions } from "@app/core/services/console/types";
import {
    Button,
    Checkbox,
    Col,
    Form,
    Modal,
    Row,
    Select,
    Space,
    Table,
    Tooltip,
    Typography,
    notification,
    Input,
} from "antd";
import { DeleteOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import { useInventoryPermissionsTable } from "./useInventoryPermissionsTable";
import { UsersEmail } from "@app/core/components/Fields/UsersEmail";
import { UserGroups } from "@app/core/components/Fields/UserGroups";
import { INVENTORY_PERMISSIONS_FORM_NAMES } from "../constants";
import { useRemoveConfirmationModal } from "./RemoveConfirmationModal/useRemoveConfirmationModal";
import { PERMISSIONS_ENTITY_TYPES } from "@app/core/services/console/grants";
import { useUserAccess } from "@app/core/auth";

const { useWatch } = Form;

const COLUMN_KEY = {
    EAMIL: "email",
    GROUP_NAME: "group_name",
    EMAIL_GROUP: "email_group",
    NAME: "name",
    PERMISSIONS: "permissions",
    ADMIN: "administration",
    WRITE: "write",
    READ: "read",
    ACTIONS: "actions",
};

export interface SeatGrantsTableRecord extends Grants, Pick<typeof COLUMN_KEY, "EMAIL_GROUP"> {
    key: string;
    editable?: boolean;
}

const EMPTY = "- -";
const HAS_PERMISSIONS = "Yes";
const EMAIL_LABEL = "Email";
const GROUP_LABEL = "Group";

const getPermission = (permission: Permissions, permissionName: Permissions) => {
    return permission.includes(permissionName);
};

const getTableItems = (seatGrants: Grants[]) =>
    seatGrants.map((seatGrant, i) => ({
        key: i,
        [COLUMN_KEY.EMAIL_GROUP]: seatGrant.userId ? EMAIL_LABEL : GROUP_LABEL,
        [COLUMN_KEY.NAME]: seatGrant.userId ? seatGrant.emailAddress : seatGrant.groupName,
        [COLUMN_KEY.ADMIN]: getPermission(seatGrant.permission as Permissions, Permissions.ADMIN),
        [COLUMN_KEY.WRITE]: getPermission(seatGrant.permission as Permissions, Permissions.WRITE),
        [COLUMN_KEY.READ]: getPermission(seatGrant.permission as Permissions, Permissions.READ),
        ...seatGrant,
    }));

interface EditableCellProps extends HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    index: number;
    children: ReactNode;
    renderInputNode?: () => ReactNode;
    inputNodeValuePropName?: string | undefined;
}

const EditableCell: FC<EditableCellProps> = ({
    editing,
    dataIndex,
    index,
    children,
    renderInputNode,
    inputNodeValuePropName,
    ...restProps
}) => {
    return (
        <td {...restProps}>
            {editing && renderInputNode ? (
                <Form.Item name={dataIndex} style={{ margin: 0 }} valuePropName={inputNodeValuePropName}>
                    {renderInputNode()}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

interface Props {
    entityType?: PERMISSIONS_ENTITY_TYPES;
}

export const InventroyPermissionsTable: FC<Props> = ({ entityType = PERMISSIONS_ENTITY_TYPES.SEAT }) => {
    const { hasSeatWriteAccess } = useUserAccess();
    const [searchValue, setSearchValue] = useState("");
    const {
        dataSource,
        isLoading,
        refetch,
        editPermissions,
        createPermissions,
        isSaving,
        handleDeleteGrants,
        isLoadingDeletePermissions,
    } = useInventoryPermissionsTable(entityType, searchValue);
    const [permissionOnEditing, setPermissionOnEditing] = useState<
        (Partial<SeatGrantsTableRecord> & { key: Key }) | null
    >(null);
    const [editingKey, setEditingKey] = useState<Key | null>(null);
    const [form] = Form.useForm();
    const emailOrGroupType = useWatch<typeof EMAIL_LABEL | typeof GROUP_LABEL>(COLUMN_KEY.EMAIL_GROUP, form);
    const isEditing = (record: SeatGrantsTableRecord) => record.key === editingKey;
    const [isCreating, setIsCreating] = useState(false);
    const [permissions, setPermissions] = useState<Grants[]>([]);
    const { isOpen, open, close, permission } = useRemoveConfirmationModal();

    useEffect(() => {
        const permissions = [...dataSource];
        if (isCreating) {
            permissions.unshift({
                permission: "",
            } as Grants);
            setEditingKey(0);
            form.setFieldsValue({ [COLUMN_KEY.EMAIL_GROUP]: "Email" });
        }
        setPermissions(permissions);
    }, [dataSource, isCreating, form]);

    const handleDelete = async () => {
        if (!permission) {
            return;
        }
        try {
            await handleDeleteGrants({
                body: permission.permission.split(" ").map((p: Permissions) => ({
                    emailAddress: permission.emailAddress,
                    groupId: permission.groupId,
                    permission: p,
                })),
            });
            notification.success({ message: "Permissions deleted successfully" });
        } catch (ex) {
            notification.error({ message: "Delete Permissions Error", description: ex.message });
        }
        close();
        refetch();
    };

    const edit = (record: Partial<SeatGrantsTableRecord> & { key: Key }) => {
        setPermissionOnEditing(record);
        form.setFieldsValue({
            [COLUMN_KEY.EMAIL_GROUP]: record[COLUMN_KEY.EMAIL_GROUP],
            [COLUMN_KEY.ADMIN]: record[COLUMN_KEY.ADMIN],
            [COLUMN_KEY.WRITE]: record[COLUMN_KEY.WRITE],
            [COLUMN_KEY.READ]: record[COLUMN_KEY.READ],
            [INVENTORY_PERMISSIONS_FORM_NAMES.GROUP]: record.groupId,
            [INVENTORY_PERMISSIONS_FORM_NAMES.EMAIL]: record.emailAddress,
        });
        setEditingKey(record.key);
    };

    const save = async () => {
        const { administration, write, read } = form.getFieldsValue();
        if (isCreating) {
            try {
                await createPermissions(form.getFieldsValue());
                if (administration || write || read)
                    notification.success({ message: "Permissions added successfully" });
            } catch (ex) {
                notification.error({ message: "Add Permissions Error", description: ex.message });
            }
            setIsCreating(false);
        } else {
            try {
                await editPermissions(permissionOnEditing as SeatGrantsTableRecord, form.getFieldsValue());
                notification.success({ message: "Permissions updated successfully" });
            } catch (ex) {
                notification.error({ message: "Update Permissions Error", description: ex.message });
            }
        }
        setEditingKey(null);
        form.resetFields();
        refetch();
    };

    const cancel = () => {
        setIsCreating(false);
        setEditingKey(null);
        form.resetFields();
    };

    const handleSearch = (value: string) => {
        setSearchValue(value);
    };

    const columns = [
        {
            editable: true,
            dataIndex: COLUMN_KEY.EMAIL_GROUP,
            title: "Email / Group",
            width: "25%",
            sorter: (a: SeatGrantsTableRecord, b: SeatGrantsTableRecord) =>
                a[COLUMN_KEY.EMAIL_GROUP].localeCompare(b[COLUMN_KEY.EMAIL_GROUP]),
            renderInputNode: () => (
                <Select
                    options={[
                        { value: "Email", label: "Email" },
                        { value: "Group", label: "Group" },
                    ]}
                />
            ),
        },
        {
            editable: true,
            dataIndex: COLUMN_KEY.NAME,
            title: "Name",
            width: "25%",
            sorter: (a: SeatGrantsTableRecord, b: SeatGrantsTableRecord) =>
                a[COLUMN_KEY.NAME].localeCompare(b[COLUMN_KEY.NAME]),
            renderInputNode: () =>
                emailOrGroupType === "Email" ? <UsersEmail margin={0} /> : <UserGroups margin={0} />,
        },
        {
            editable: true,
            dataIndex: COLUMN_KEY.PERMISSIONS,
            title: "Permissions",
            children: [
                {
                    editable: true,
                    dataIndex: COLUMN_KEY.ADMIN,
                    title: "Admin",
                    renderInputNode: () => <Checkbox>Admin</Checkbox>,
                    inputNodeValuePropName: "checked",
                    render: (_, record: SeatGrantsTableRecord) => (record[COLUMN_KEY.ADMIN] ? HAS_PERMISSIONS : EMPTY),
                },
                {
                    editable: true,
                    dataIndex: COLUMN_KEY.WRITE,
                    title: "Write",
                    renderInputNode: () => <Checkbox>Write</Checkbox>,
                    inputNodeValuePropName: "checked",
                    render: (_, record: SeatGrantsTableRecord) => (record[COLUMN_KEY.WRITE] ? HAS_PERMISSIONS : EMPTY),
                },
                {
                    editable: true,
                    dataIndex: COLUMN_KEY.READ,
                    title: "Read",
                    renderInputNode: () => <Checkbox>Read</Checkbox>,
                    inputNodeValuePropName: "checked",
                    render: (_, record: SeatGrantsTableRecord) => (record[COLUMN_KEY.READ] ? HAS_PERMISSIONS : EMPTY),
                },
            ],
        },
        {
            dataIndex: COLUMN_KEY.ACTIONS,
            title: "Actions",
            width: "200px",
            render: (_, record: SeatGrantsTableRecord) => {
                return !isEditing(record) ? (
                    <Button.Group>
                        {hasSeatWriteAccess && (
                            <Tooltip title="Edit permission">
                                <Button
                                    onClick={() => {
                                        edit(record);
                                    }}
                                    icon={<EditOutlined />}
                                />
                            </Tooltip>
                        )}
                        <Tooltip title="Remove permission">
                            <Button onClick={() => open(record)} icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Button.Group>
                ) : (
                    <Row>
                        <Col>
                            <Space>
                                <Button data-sdet="" onClick={cancel} disabled={isSaving}>
                                    Cancel
                                </Button>
                                <Button data-sdet="" type="primary" onClick={save} loading={isSaving}>
                                    Save
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                );
            },
        },
    ];

    const mapColumns = (col) => {
        if (!col.editable) {
            return col;
        }
        const newCol = {
            ...col,
            onCell: (record: SeatGrantsTableRecord) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                renderInputNode: col.renderInputNode,
                inputNodeValuePropName: col.inputNodeValuePropName,
            }),
        };

        if (col.children) {
            newCol.children = col.children.map(mapColumns);
        }

        return newCol;
    };

    const mergedColumns = columns.map(mapColumns);

    return (
        <Space size="large" direction="vertical" style={{ width: "100%" }}>
            <Row justify="space-between">
                <Col xs={24} sm={24} md={8} xl={6} xxl={5}>
                    <Input
                        disabled={editingKey !== null}
                        onChange={(e) => handleSearch(e.target.value)}
                        suffix={<SearchOutlined />}
                        allowClear
                        placeholder="Search Name or Group"
                    />
                </Col>

                {hasSeatWriteAccess && (
                    <Col>
                        <Button disabled={editingKey !== null} type="primary" onClick={() => setIsCreating(true)}>
                            Add Permissions
                        </Button>
                    </Col>
                )}
            </Row>

            <Form form={form} component={false}>
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    size="small"
                    columns={mergedColumns}
                    dataSource={getTableItems(permissions)}
                    showSorterTooltip={false}
                    bordered
                    loading={isLoading}
                    pagination={false}
                />
            </Form>
            <Modal
                open={isOpen}
                title="Confirmation"
                onOk={handleDelete}
                onCancel={close}
                confirmLoading={isLoadingDeletePermissions}
                footer={
                    <>
                        <Button onClick={close} disabled={isLoadingDeletePermissions}>
                            Cancel
                        </Button>
                        <Button type="primary" danger loading={isLoadingDeletePermissions} onClick={handleDelete}>
                            Delete
                        </Button>
                    </>
                }
            >
                <Typography.Paragraph>Are you sure you want to delete permissions?</Typography.Paragraph>
                <Typography.Text strong>{permission?.emailAddress || permission?.groupName}</Typography.Text>
            </Modal>
        </Space>
    );
};
