import { css } from "@emotion/css";
import { FullscreenOutlined } from "@ant-design/icons";
import { Badge, Button, Typography } from "antd";
import { FC, useState, useEffect } from "react";
import { LegendData, PinnedLegend, PinnedType } from "./types";
import { gray5, gray8 } from "@rubicon/antd-components";

interface Props extends LegendData {
    pinnedLegend: PinnedLegend;
    showMore: (v: boolean) => void;
    selectLegend: (item: LegendData) => void;
    outOfDefault: boolean;
}

export const CustomLegend: FC<Props> = ({
    name,
    value,
    percentage,
    color,
    showMore,
    selectLegend,
    pinnedLegend,
    outOfDefault,
}) => {
    const [showPinIcon, setShowPinIcon] = useState(false);
    const matchItem = pinnedLegend?.value?.name === name && pinnedLegend?.value?.value === value;

    const isSelectedLegend = (matchItem && pinnedLegend.type !== PinnedType.Hover) || !pinnedLegend?.value;
    const isHoveredLegend = pinnedLegend.type === PinnedType.Hover;

    let badgeColor: string = gray5;
    let textColor: string = gray5;

    if (isSelectedLegend) {
        badgeColor = color;
        textColor = gray8;
    }

    if (isHoveredLegend) {
        badgeColor = color;
        textColor = gray8;
    }

    useEffect(() => {
        if (isHoveredLegend && outOfDefault) {
            showMore(true);
        }
    }, [isHoveredLegend, pinnedLegend, outOfDefault, showMore]);

    return (
        <Button
            data-sdet={`custom-legend-${name}`}
            type="text"
            className={css`
                display: block;
                height: auto;
                padding: 0 0.5rem;
                ${isHoveredLegend && matchItem && `background-color: #e6edff;`}
            `}
            onMouseOver={() => setShowPinIcon(true)}
            onMouseLeave={() => setShowPinIcon(false)}
            onClick={() => selectLegend({ name, value, percentage, color })}
        >
            <Badge
                key={name + percentage}
                className={css`
                    .ant-badge-status-dot {
                        width: 8px;
                        height: 8px;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                `}
                style={{ display: "block" }}
                color={badgeColor}
                text={
                    <>
                        <Typography.Text style={{ color: textColor }}>
                            {name}: {percentage}
                        </Typography.Text>
                        {showPinIcon && <FullscreenOutlined style={{ marginLeft: "4px", fontSize: "12px" }} />}
                    </>
                }
            />
        </Button>
    );
};
