import { FC, useState } from "react";
import DOMPurify from "dompurify";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Collapse, Drawer, Typography } from "antd";
import { configurationCollapseClassList } from "@app/features/deals/DealForm/DefinitionSidePanels/DealObjectSidePanel/DealObjectSidePanel";
import { useHelpArticles } from "@app/core/components/hooks";
import { DealsHelpKeys } from "../../../constants";
import { DrawerCloseButton, SectionTitle, StyledButtonLink } from "@app/core/components";

export const DemandConfigurationSidePanel: FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { helpArticles } = useHelpArticles(DealsHelpKeys);
    const transactionCurrencyFields = helpArticles[DealsHelpKeys.DealTransactionCurrency];
    const enforcementFields = helpArticles[DealsHelpKeys.DealEnforcement];
    const freqCappingFields = helpArticles[DealsHelpKeys.DealFreqCapping];

    return (
        <>
            <StyledButtonLink onClick={() => setIsOpen(true)} type="link" data-sdet="demand-configuration-button">
                <InfoCircleOutlined />
                Demand Configuration
            </StyledButtonLink>
            <Drawer
                width="40%"
                title="Demand Configuration Definitions"
                styles={{ body: { padding: 0 } }}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                closable={false}
                extra={<DrawerCloseButton onClose={() => setIsOpen(false)} />}
            >
                <Collapse className={configurationCollapseClassList} defaultActiveKey={["1", "2", "3", "4", "5"]}>
                    <Collapse.Panel key="1" header={<SectionTitle title="Demand Configuration" />}>
                        <Typography.Text>
                            This section is to allow additional configuration to connect this deal to the Ad Source
                        </Typography.Text>
                    </Collapse.Panel>
                    <Collapse.Panel
                        key="2"
                        header={<SectionTitle title={transactionCurrencyFields?.title ?? "Transaction Currency"} />}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(transactionCurrencyFields?.body),
                            }}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel key="3" header={<SectionTitle title={enforcementFields?.title ?? "Enforcement"} />}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(enforcementFields?.body),
                            }}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel
                        key="4"
                        header={<SectionTitle title={freqCappingFields?.title ?? "Deal Frequency Capping"} />}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(freqCappingFields?.body),
                            }}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel key="5" header={<SectionTitle title="Demand Targeting" />}>
                        <Typography.Text>
                            You are able to add additional demand targeting but you will need to use the Ad Source
                            workflow to create the deal and configure it to an Ad Source. The Ad Source workflow allows
                            you to do more advanced configuration.
                        </Typography.Text>
                    </Collapse.Panel>
                </Collapse>
            </Drawer>
        </>
    );
};
