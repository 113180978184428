import { MarketplaceInfo, useGetDealStatusQuery } from "@app/core/services";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { Form } from "antd";
import { DEAL_TYPES } from "../DealTypeSelect";
import { useCallback, useMemo } from "react";
import { availableStatuses } from "@app/features/deals/DealForm/DealDemandFormSections/DealTermsSection/Fields/DealStatusField/useDealStatusField";
import { useSyncedFieldsProducer } from "@app/features/syncedFields";

export const useStatusSelect = () => {
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const isReadonly = dealObjectMode === "view";
    const dealType = Form.useWatch<MarketplaceInfo["deal"]["dealType"]>(["dealUnderEdit", "dealType"]);
    const isVisible = dealType?.name !== DEAL_TYPES.DEAL_TYPE_LEGACY.name;
    const { data: dealStatusesRaw = [], isFetching: isLoading } = useGetDealStatusQuery(undefined, {
        skip: isReadonly || !isVisible,
    });
    const dealStatuses = useMemo(
        () => dealStatusesRaw.filter((item) => availableStatuses.has(item.id)),
        [dealStatusesRaw]
    );

    const { updateSyncedFields } = useSyncedFieldsProducer();
    const onChange = useCallback(
        (value) => {
            updateSyncedFields({ status: value });
        },
        [updateSyncedFields]
    );

    return {
        dealStatuses,
        isLoading,
        isReadonly,
        isVisible,
        onChange,
    };
};
