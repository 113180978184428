import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import conf from "@app/core/conf";

export const VERSION_TAG = "version";

export const appsApi = createApi({
    reducerPath: "apps",
    tagTypes: [VERSION_TAG],
    baseQuery: fetchBaseQuery({
        baseUrl: conf.streamingAppRoot,
    }),
    endpoints: () => ({}),
});
