import { useAuthClient } from "@app/core/authClient";
import { Session } from "@app/core/clients/console";
import { Seat, SeatAccess } from "@app/core/services/console";
import { useAppSelector } from "@app/core/store";
import { AuthRequest } from "../authClient/AuthClient";
import { selectIsLoadingSeat, selectSeatAccess, selectSeatContext, selectIsSeatDemoContext } from "./reducer";

interface UseSeatAuthContext {
    context: Seat | null;
    contextType: "seat";
    isDemoContext: boolean;
    isLoading: boolean;
    seatAccess: SeatAccess;
    seatCount: number | null;
    session: Session | null;
    fetchSession: () => AuthRequest;
}

export const useSeatAuthContext = (): UseSeatAuthContext => {
    const { session, fetchSession } = useAuthClient();

    const isLoading = useAppSelector(selectIsLoadingSeat);
    const seatAccess = useAppSelector(selectSeatAccess);
    const seatSelf = useAppSelector(selectSeatContext);
    const isDemoContext = useAppSelector(selectIsSeatDemoContext);

    return {
        fetchSession,
        context: seatSelf?.seat || null,
        contextType: "seat",
        isDemoContext,
        isLoading,
        seatAccess: seatAccess,
        seatCount: seatSelf?.count || null,
        session: session || null,
    };
};
