import { useSeatAuthContext } from "@app/core/auth";
import { AD_SOURCE_TYPES_NEXT_UI_NAMES } from "@app/features/seatAdSources/constants";
import { useAdSourceTypeLimits } from "./useAdSourceTypeLimits";

const pbsNamespaceId = 9;

export const useAdSourceTypeFilter = (types) => {
    const { context } = useSeatAuthContext();

    let filteredTypes = [...types];

    if (!context?.externalAdUnitCodeNamespaces.find((elem) => elem.id === pbsNamespaceId)) {
        filteredTypes = filteredTypes.filter((type) => {
            if (
                type.name === AD_SOURCE_TYPES_NEXT_UI_NAMES["Prebid Ad Source"] ||
                type.name === AD_SOURCE_TYPES_NEXT_UI_NAMES["Prebid Deals Container"]
            ) {
                return false;
            }
            return true;
        });
    }

    filteredTypes = filteredTypes.filter((type) => {
        return type.name !== AD_SOURCE_TYPES_NEXT_UI_NAMES["Direct AdServing"];
    });

    if (!context?.authorizedMarketplaces.length) {
        filteredTypes = filteredTypes?.filter((type) => type.name !== AD_SOURCE_TYPES_NEXT_UI_NAMES.Marketplace);
    }

    const filteredTypesWithLimits = useAdSourceTypeLimits(filteredTypes);

    return filteredTypesWithLimits;
};
