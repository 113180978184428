import { FC } from "react";
import { Layout } from "antd";
import { Footer } from "@app/core/components";
import { useSeatAuthContext } from "@app/core/auth";
import { useHistory, useParams } from "react-router-dom";
import { INVENTORY_SEAT_TABS_TYPES } from "@app/features/inventory/InventorySeat/constants";
import { SupplyTrafficAlertBanner } from "@app/features/inventory/components/SupplyTrafficAlertBanner";
import { InventorySeatEditPageSwitch } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/InventorySeatEditPageSwitch";
import { PageHeader } from "@ant-design/pro-layout";

const getPageHeaderTitle = (tab: INVENTORY_SEAT_TABS_TYPES, name: string | undefined): string | null => {
    switch (tab) {
        case "details":
            return `Edit Seat: ${name}`;
        case "advanced":
            return "Edit Advanced Features";
        case "protections":
            return "Edit Exchange Protections";
        default:
            return null;
    }
};
const InventorySeatEditPage: FC = () => {
    const history = useHistory();
    const onBack = (): void => history.goBack();
    const { context } = useSeatAuthContext();
    const { tab } = useParams<{ tab: INVENTORY_SEAT_TABS_TYPES }>();
    return (
        <>
            <PageHeader title={getPageHeaderTitle(tab, context?.name)} onBack={onBack} />
            <SupplyTrafficAlertBanner sourceSelf="Seat" />
            <Layout.Content>
                <InventorySeatEditPageSwitch />
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};

export default InventorySeatEditPage;
