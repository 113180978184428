import { FC } from "react";
import { Col, Row, Card } from "antd";
import { useUserAccess } from "@app/core/auth";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import {
    ChannelLabels,
    LabelsInternalOnly,
} from "@app/features/inventory/HierarchyForms/PublisherChannelForm/PublisherChannelFormSections/AdvancedFeaturesSection/Sections/LabelsSection/Fields";

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 };
export const LabelsSection: FC = () => {
    const { hasInternalAccess } = useUserAccess();
    return (
        <Card bordered={false}>
            <Row gutter={16}>
                <Col {...COL_SIZES}>
                    <ChannelLabels {...CHANNEL_FORM_FIELDS.LABELS} />
                </Col>
                {hasInternalAccess && (
                    <Col {...COL_SIZES}>
                        <LabelsInternalOnly />
                    </Col>
                )}
            </Row>
        </Card>
    );
};
