import { Form, Select } from "antd";
import { useMemo, useState } from "react";
import { LabeledValue } from "antd/lib/select";
import { TagRender } from "@app/core/components";
import { blue6, red5 } from "@rubicon/antd-components";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import { RuleObject, StoreValue } from "rc-field-form/lib/interface";
import { IabCategory, useGetIabCategoriesQuery } from "@app/core/services";
import { AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { TargetingDimensionTypeLabels } from "@app/features/targeting/constants";
import { formatCategoryLabel, formatCategorySearchValue } from "@app/features/targeting";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { SelectAllButtonDropdownRender } from "@app/core/components/SelectAllButtonDropdownRender";

export const AdSourceIabCategoryBlockExceptionSelect = () => {
    const { setFieldValue, validateFields } = Form.useFormInstance();
    const { data, isFetching } = useGetIabCategoriesQuery();
    const [search, setSearch] = useState("");
    const [invalidIabCategories, setInvalidIabCategories] = useState<number[]>([]);
    const value = Form.useWatch<AdSourcesForm["blockExceptionIabCategories"]>(
        AD_SOURCE_FIELDS.AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES.name
    );

    const orderedData: IabCategory[] = useMemo(
        () => [...(data || [])].sort((a, b) => a.code.localeCompare(b.code, undefined, { numeric: true })),
        [data]
    );

    const options: LabeledValue[] = useMemo(
        () =>
            (orderedData || [])
                .map((target) => ({
                    value: target.id,
                    label: formatCategoryLabel(target, TargetingDimensionTypeLabels.ContentCategories),
                    search: formatCategorySearchValue(target, TargetingDimensionTypeLabels.ContentCategories),
                }))
                .filter((option) => option.search.toLowerCase().includes(search.toLowerCase())),
        [orderedData, search]
    );

    const blockedIabCategories = Form.useWatch<AdSourcesForm["blockedIabCategories"]>(
        AD_SOURCE_FIELDS.IAB_BLOCKED_CATEGORIES.name
    );

    const validator = (_: RuleObject, value: StoreValue): Promise<void> => {
        const selectedBlockedIabCategories: number[] = (blockedIabCategories || []).map(({ id }) => id);
        const selectedIabCategoryBlockExceptions: number[] = (value || []).map(({ value }) => value);

        const { isValid, invalidIds } = selectedBlockedIabCategories.reduce<{
            isValid: boolean;
            invalidIds: number[];
        }>(
            (accumulator, id) => {
                if (selectedIabCategoryBlockExceptions.includes(id)) {
                    return { isValid: false, invalidIds: [...accumulator.invalidIds, id] };
                }
                return accumulator;
            },
            { isValid: true, invalidIds: [] }
        );

        setInvalidIabCategories(invalidIds);

        if (!isValid)
            return Promise.reject(
                "Can not have the same category selected in both IAB Categories and Block Exceptions"
            );

        return Promise.resolve();
    };
    const handleMenuChange = (v: LabeledValue[]): void => {
        setFieldValue(AD_SOURCE_FIELDS.AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES.name, v);
        validateFields([AD_SOURCE_FIELDS.AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES.name]);
    };

    return (
        <Form.Item
            rules={[{ validator }]}
            {...VERTICAL_LAYOUT_FIX}
            label={AD_SOURCE_FIELDS.AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES.label}
            name={AD_SOURCE_FIELDS.AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES.name}
        >
            <Select
                allowClear
                showSearch
                labelInValue
                dropdownRender={(menu) => (
                    <SelectAllButtonDropdownRender<LabeledValue>
                        menu={menu}
                        value={value}
                        search={search}
                        options={options}
                        onChange={handleMenuChange}
                        getPrimaryKey={(v) => v.value}
                    />
                )}
                mode="multiple"
                options={options}
                loading={isFetching}
                filterOption={false}
                searchValue={search}
                onSearch={setSearch}
                autoClearSearchValue={false}
                placeholder="Select IAB Category Block Exceptions"
                tagRender={(props: CustomTagProps) => (
                    <TagRender
                        closeIconColor={invalidIabCategories.includes(props.value) ? red5 : blue6}
                        color={invalidIabCategories.includes(props.value) ? "error" : "processing"}
                        {...props}
                    />
                )}
            />
        </Form.Item>
    );
};
