import { Col, Form, Row, Select } from "antd";
import { LabeledValue } from "antd/lib/select";
import { FC } from "react";

interface Props {
    handleChangePlatformFamily: (value: number) => void;
    handleChangeCountry: (value: string) => void;
    handleSearchCountries: (value: string) => void;
    platformsFamilyOptions: LabeledValue[];
    countriesOptions: LabeledValue[];
    isFetchingCountries: boolean;
    isLoadingPlatformFamily: boolean;
    platformFamily?: number;
    country?: string;
}

export const UniquesStatsFilter: FC<Props> = ({
    platformFamily,
    country,
    isLoadingPlatformFamily,
    isFetchingCountries,
    countriesOptions,
    platformsFamilyOptions,
    handleSearchCountries,
    handleChangeCountry,
    handleChangePlatformFamily,
}) => {
    return (
        <Form layout="vertical" data-sdet="uniques-stats-filter">
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item label="Platform Family">
                        <Select
                            allowClear
                            loading={isLoadingPlatformFamily}
                            options={platformsFamilyOptions}
                            value={platformFamily}
                            onChange={handleChangePlatformFamily}
                            style={{ width: "100%" }}
                            placeholder="All"
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Country">
                        <Select
                            allowClear
                            showSearch
                            loading={isFetchingCountries}
                            options={countriesOptions}
                            value={country}
                            onChange={handleChangeCountry}
                            onSearch={handleSearchCountries}
                            style={{ width: "100%" }}
                            placeholder="All"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
