import { Drawer } from "antd";
import DOMPurify from "dompurify";
import { FC, useState } from "react";
import { StyledButtonLink } from "@app/core/components";
import { useHelpArticles } from "@app/core/components/hooks";
import { EMPTY_HELP_KEYS_BODY } from "@app/features/inventory/constants";

const ADVANCED_BRAND_SAFETYHELP_HELP_KEY = "advancedbrandsafety.targeting";

export const AdvancedBrandSafetyHelp: FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { helpArticles } = useHelpArticles([ADVANCED_BRAND_SAFETYHELP_HELP_KEY]);
    return (
        <>
            <StyledButtonLink onClick={() => setIsOpen(true)} type="link">
                How is advanced brand safety targeted?
            </StyledButtonLink>
            <Drawer
                width={400}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                title="How is advanced brand safety targeted?"
            >
                <div
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            helpArticles[ADVANCED_BRAND_SAFETYHELP_HELP_KEY]?.body || EMPTY_HELP_KEYS_BODY
                        ),
                    }}
                />
            </Drawer>
        </>
    );
};
