import { LoadingState } from "@app/core/components/LoadableComponent";
import {
    AdSourceStat,
    useConnectHistoryAdSourceAdStatsPerSecond,
    useConnectLiveAdSourceAdStat,
} from "@app/features/seatAdSources/data";
import { useCurrencyConversion } from "@app/features/dashboard/useCurrencyConversion";
import moment from "moment-timezone";

interface UseSeatAdSourcesPerformanceLast24Hours {
    loadingState: LoadingState;
    adSourceHistoryStats: AdSourceStat[] | undefined;
    totalAdSourceStat: AdSourceStat | undefined;
    currencyCode: string;
}

export const useSeatAdSourcesPerformanceLast24Hours = (adSourceId: number): UseSeatAdSourcesPerformanceLast24Hours => {
    const momentRange = { start: moment().subtract(1, "day"), end: moment() };
    const { loadingState, data: adSourceHistoryStats } = useConnectHistoryAdSourceAdStatsPerSecond(
        adSourceId,
        momentRange
    );
    const { data: throttledTotalAdSourceStat } = useConnectLiveAdSourceAdStat(adSourceId, true);
    const currencyCode = useCurrencyConversion().currencyConfig?.code ?? "USD";

    return {
        loadingState,
        adSourceHistoryStats,
        totalAdSourceStat: throttledTotalAdSourceStat,
        currencyCode,
    };
};
