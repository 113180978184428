import { FC } from "react";
import { Button, Dropdown, MenuProps, Space } from "antd";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { useUnitStatsSnapshotMutation } from "./useUnitStatsSnapshotMutation";
import { useAppSelector } from "@app/core/store";
import {
    InventoryDetailsDrawerType,
    selectInventoryDetailsDrawerType,
    selectIsPageInventoryDetailsDrawer,
} from "@app/features/inventory/DetailsDrawer/reducer";
import { PerformanceProps } from "../../Performance";
import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { UnitUrlIds } from "@app/features/inventory/DetailsDrawer/useInventoryDetailsDrawerUrlId";

interface UrlParams extends UnitUrlIds {
    seatId: string;
}

interface Props extends Pick<PerformanceProps, "id"> {
    message: string;
}

export const SnapshotsControls: FC<Props> = ({ id, message }) => {
    const { seatId, publisherId, brandId, supplyId, adUnitId } = useParams<UrlParams>();

    const history = useHistory();
    const drawerType = useAppSelector(selectInventoryDetailsDrawerType) as InventoryDetailsDrawerType;
    const isDetailsUnitPage = useAppSelector(selectIsPageInventoryDetailsDrawer);
    const isNotSeat = drawerType !== InventoryDetailsDrawerType.SEAT;
    const { unitStatsSanpshotMutation, isLoading } = useUnitStatsSnapshotMutation();

    const items: MenuProps["items"] = [
        {
            label: "Take Snapshot",
            key: "takeSnapshot",
            onClick: () => {
                unitStatsSanpshotMutation(drawerType, id, message);
            },
        },
        {
            label: "View Snapshots",
            key: "viewSnapshots",
            onClick: () => {
                history.push(
                    ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_UNIT_SNAPSHOT(
                        isDetailsUnitPage && isNotSeat,
                        seatId,
                        drawerType,
                        Number(publisherId),
                        Number(brandId),
                        Number(supplyId),
                        Number(adUnitId)
                    ) as string
                );
            },
        },
    ];

    return (
        <Space size="middle">
            <Dropdown menu={{ items }} disabled={isLoading}>
                <Button>
                    <Space>
                        Snapshot
                        {isLoading ? <LoadingOutlined spin /> : <DownOutlined />}
                    </Space>
                </Button>
            </Dropdown>
            {/* TODO: Commented out until download functionality is implemented */}
            {/* <SnapshotsDownload /> */}
        </Space>
    );
};
