import { PLATFORMS_TAG, consoleApi, PLATFORMS_FAMILY_TAG } from "./console";
import { Platform } from "@app/core/services";

export const platformsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getPlatforms: builder.query<Platform[], void>({
            query: () => "/platforms",
            providesTags: [PLATFORMS_TAG],
        }),
        getPlatfromsFamily: builder.query<Platform[], void>({
            query: () => "/platforms/family",
            providesTags: [PLATFORMS_FAMILY_TAG],
        }),
    }),
    overrideExisting: false,
});

export const { useGetPlatformsQuery, useGetPlatfromsFamilyQuery } = platformsApi;
