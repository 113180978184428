import { Seat, UpdateSeatPayload, useUpdateSeatSelfMutation } from "../services";
import { useAppDispatch } from "../store";
import { updateSeatInContext } from "./reducer";

interface UseUpdateSeatInContext {
    isLoading: boolean;
    updateSeatSelf: (body: UpdateSeatPayload) => Promise<Seat>;
}

export const useUpdateSeatInContext = (): UseUpdateSeatInContext => {
    const dispatch = useAppDispatch();
    const [_updateSeatSelf, { isLoading }] = useUpdateSeatSelfMutation();

    // Wrapper for the useUpdateSeatSelfMutation request that updates our store state to prevent stale values after updateing the seat.
    // A better fix would be to remove the duplicate store state and rely on the cache from the useUpdateSeatSelfMutation hook as the
    // single source of truth.
    const updateSeatSelf = async (body: UpdateSeatPayload): Promise<Seat> => {
        const seat: Seat = await _updateSeatSelf(body).unwrap();
        dispatch(updateSeatInContext(seat));
        return seat;
    };

    return {
        isLoading,
        updateSeatSelf,
    };
};
