import { useContext, useEffect } from "react";
import { Form } from "antd";
import { SeatAdSourcesType, useGetAdSourceFloorTypesQuery } from "@app/core/services";
import { AD_SOURCE_FIELDS, AD_SOURCE_TYPES_NEXT_UI_NAMES, AdSourceTypes } from "@app/features/seatAdSources/constants";
import { AdSourceFormsContext, AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { useIsFieldVisible } from "./useIsFieldVisible";

const { useWatch, useFormInstance } = Form;

export const useFloorTypeSelect = () => {
    const { forms } = useContext(AdSourceFormsContext);
    const { adSourceFormMode } = forms;
    const { setFieldsValue } = useFormInstance<AdSourcesForm>();
    const isVisible = useIsFieldVisible();
    const { data: floorTypes, isLoading } = useGetAdSourceFloorTypesQuery(undefined, { skip: !isVisible });
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);

    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSourceType?.name];

    useEffect(() => {
        if (adSourceFormMode === "edit" || adSourceFormMode === "copy") {
            return;
        }
        if (
            adSourceTypeName === AdSourceTypes.OPEN_AUCTION ||
            adSourceTypeName === AdSourceTypes.MARKETPLACE ||
            adSourceTypeName === AdSourceTypes.CURATOR_MARKETPLACE
        ) {
            const fallbackFloorType = floorTypes?.find((floorType) => floorType.id === 2);
            if (fallbackFloorType) {
                setFieldsValue({
                    [AD_SOURCE_FIELDS.FLOOR_TYPE.name]: fallbackFloorType,
                });
            }
        }
        if (adSourceTypeName === AdSourceTypes.LINEAR_AUCTION_PRICE) {
            const overrideFloorType = floorTypes?.find((floorType) => floorType.id === 1);
            if (overrideFloorType) {
                setFieldsValue({
                    [AD_SOURCE_FIELDS.FLOOR_TYPE.name]: overrideFloorType,
                });
            }
        }
    }, [adSourceTypeName, setFieldsValue, adSourceFormMode, floorTypes]);

    return { floorTypes, isLoading, isVisible };
};
