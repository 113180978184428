import { FC } from "react";
import { Space } from "antd";
import { LabeledValue } from "antd/lib/select";
import { DayPartTarget } from "@app/core/services/console";
import {
    Conditions,
    CustomRuleIds,
    CustomRuleOperators,
    GroupConditions,
    PmpConditions,
    SegmentRuleIdentifierOperators,
    SegmentRuleModes,
    TargetingDimensionTypes,
    TimeZoneModeIds,
} from "../constants";
import {
    CustomRuleGroup,
    MaxDurationTarget,
    MinDurationTarget,
    TargetingBlock,
    SegmentRuleGroup,
    TargetingDimension,
} from "../types";
import { TargetingBlockCollapse } from "../TargetingBlockCollapse";
import { AddDimensionButton } from "./AddDimensionButton";
import { CreateTargetingBlockActions } from "./CreateTargetingBlockActions";
import { CreateTargetingBlockHeader } from "./CreateTargetingBlockHeader";
import {
    AdBreakPositionTargetsDimensionRow,
    ApiFrameworksDimensionRow,
    BundleIdTargetsDimensionRow,
    BvodDimensionRow,
    CategoriesDimensionRow,
    ContentCategoriesDimensionRow,
    ContentLengthsTargetsDimensionRow,
    ContentMetadataContentChannelDimensionRow,
    ContentMetadataGenresDimensionRow,
    ContentMetadataNetworksDimensionRow,
    ContentMetadataProducersDimensionRow,
    ContentMetadataRatingsDimensionRow,
    ContentMetadataTitleDimensionRow,
    ContentMetadataTvSeriesDimensionRow,
    CoppaDimensionRow,
    CustomRulesDimensionRow,
    CustomTargetsDimensionRow,
    DayPartingDimensionRow,
    DntDimensionRow,
    GeoTargetsDimensionRow,
    InventoryDimensionRow,
    InventoryLabelDimensionRow,
    LiveStreamDimensionRow,
    MaxDurationTargetDimensionRow,
    MimeTypesTargetsDimensionRow,
    MinDurationTargetDimensionRow,
    OperatingSystemsTargetsDimensionRow,
    PlatformsDimensionRow,
    PmpDimensionRow,
    PodSlotPositionTargetsDimensionRow,
    SegmentRuleDimensionRow,
    SizesDimensionRow,
    SupplyDomainTargetsDimensionRow,
    SupplyTypesDimensionRow,
} from "./DimensionRows";
import { useSupplyTypeDimension } from "./useSupplyTypeDimension";
import { TargetingFormKeys, TargetingDimensionHelpKeys } from "@app/features/targeting/constants";
import { useHelpArticles } from "@app/core/components/hooks";
import { AudiencesDimensionRow } from "./DimensionRows/AudiencesDimensionRow";

interface Props {
    formKey: TargetingFormKeys;
    index: number;
    onChangeDimensionCondition: (
        formKey: TargetingFormKeys,
        index: number,
        dimensionIndex: number,
        value: Conditions
    ) => void;
    onChangeDimensionMode: (
        formKey: TargetingFormKeys,
        index: number,
        dimensionIndex: number,
        mode: SegmentRuleModes
    ) => void;
    onChangeDimensionOperator: (
        formKey: TargetingFormKeys,
        index: number,
        dimensionIndex: number,
        path: number[],
        operator: SegmentRuleIdentifierOperators
    ) => void;
    onChangeDimensionPmpCondition: (
        formKey: TargetingFormKeys,
        index: number,
        dimensionIndex: number,
        value: PmpConditions
    ) => void;
    onChangeDimensionRuleCondition: (
        formKey: TargetingFormKeys,
        index: number,
        dimensionIndex: number,
        path: number[],
        condition: GroupConditions
    ) => void;
    onChangeDimensionRuleGroup: (
        formKey: TargetingFormKeys,
        index: number,
        dimensionIndex: number,
        group: SegmentRuleGroup | CustomRuleGroup
    ) => void;
    onChangeDimensionRuleId: (
        formKey: TargetingFormKeys,
        index: number,
        dimensionIndex: number,
        path: number[],
        id: CustomRuleIds
    ) => void;
    onChangeDimensionRuleOperator: (
        formKey: TargetingFormKeys,
        index: number,
        dimensionIndex: number,
        path: number[],
        value: CustomRuleOperators
    ) => void;
    onChangeDimensionRuleValue: (
        formKey: TargetingFormKeys,
        index: number,
        dimensionIndex: number,
        path: number[],
        value: LabeledValue | string | string[] | number | number[] | null
    ) => void;
    onChangeDimensionTimeZoneMode: (
        formKey: TargetingFormKeys,
        index: number,
        dimensionIndex: number,
        value: TimeZoneModeIds
    ) => void;
    onChangeDimensionValues: (
        formKey: TargetingFormKeys,
        index: number,
        dimensionIndex: number,
        value:
            | LabeledValue[]
            | string[]
            | boolean[]
            | number[]
            | DayPartTarget[]
            | MinDurationTarget
            | MaxDurationTarget
    ) => void;
    onChangeIsReusable: (formKey: TargetingFormKeys, index: number, isReusable: boolean) => void;
    onClickAddDimension: (formKey: TargetingFormKeys, index: number, key: string) => void;
    onClickAddDimensionRule: (
        formKey: TargetingFormKeys,
        index: number,
        dimensionIndex: number,
        path: number[]
    ) => void;
    onClickAddDimensionRuleGroup: (
        formKey: TargetingFormKeys,
        index: number,
        dimensionIndex: number,
        path: number[]
    ) => void;
    onClickDownload: (formKey: TargetingFormKeys, index: number, dimension: TargetingDimension) => void;
    onClickCopy: (formKey: TargetingFormKeys, index: number) => void;
    onClickDelete: (formKey: TargetingFormKeys, index: number) => void;
    onClickDeleteDimension: (formKey: TargetingFormKeys, index: number, dimensionIndex: number) => void;
    onClickDeleteDimensionRule: (
        formKey: TargetingFormKeys,
        index: number,
        dimensionIndex: number,
        path: number[]
    ) => void;
    onClickDuplicateDimension: (formKey: TargetingFormKeys, index: number, dimensionIndex: number) => void;
    targetingBlock: TargetingBlock;
    targetingBlocks: TargetingBlock[];
    validationTargetingBlocks: TargetingBlock[];
}

export const CreateTargetingBlock: FC<Props> = ({
    formKey,
    index,
    onChangeDimensionCondition,
    onChangeDimensionMode,
    onChangeDimensionOperator,
    onChangeDimensionPmpCondition,
    onChangeDimensionRuleCondition,
    onChangeDimensionRuleGroup,
    onChangeDimensionRuleId,
    onChangeDimensionRuleOperator,
    onChangeDimensionRuleValue,
    onChangeDimensionTimeZoneMode,
    onChangeDimensionValues,
    onChangeIsReusable,
    onClickAddDimension,
    onClickAddDimensionRule,
    onClickAddDimensionRuleGroup,
    onClickCopy,
    onClickDownload,
    onClickDelete,
    onClickDeleteDimension,
    onClickDeleteDimensionRule,
    onClickDuplicateDimension,
    targetingBlock,
    targetingBlocks,
    validationTargetingBlocks,
}) => {
    const { helpArticles, isLoading } = useHelpArticles(TargetingDimensionHelpKeys);
    const usedDimensionsTargetingBlocks = targetingBlocks.map((block) => block.usedDimensions);
    const usedDimensionsValidationTargetingBlocks = validationTargetingBlocks.map((block) => block.usedDimensions);
    const usedDimensionsAll = usedDimensionsTargetingBlocks.concat(usedDimensionsValidationTargetingBlocks);

    const getCommonDimensionRowProps = (dimension, dimensionIndex) => ({
        dimension,
        key: `${dimension.type}-${dimension.condition}`,
        onChangeCondition: (condition) => onChangeDimensionCondition(formKey, index, dimensionIndex, condition),
        onChangeDimensionValues: (values) => onChangeDimensionValues(formKey, index, dimensionIndex, values),
        onClickDelete: () => onClickDeleteDimension(formKey, index, dimensionIndex),
        onClickDuplicate: () => onClickDuplicateDimension(formKey, index, dimensionIndex),
        onClickDownload: () => onClickDownload(formKey, index, dimension),
        usedDimensions: targetingBlock.usedDimensions,
        usedDimensionsAll,
    });
    const { supplyTypeDimension } = useSupplyTypeDimension(targetingBlock.dimensions);

    return (
        <TargetingBlockCollapse
            header={
                <CreateTargetingBlockHeader
                    index={index}
                    onChangeIsReusable={(i, isReusable) => onChangeIsReusable(formKey, i, isReusable)}
                    targetingBlock={targetingBlock}
                />
            }
            extra={
                <CreateTargetingBlockActions
                    onClickCopy={() => onClickCopy(formKey, index)}
                    onClickDelete={() => onClickDelete(formKey, index)}
                />
            }
        >
            <Space direction="vertical" style={{ width: "100%" }}>
                {targetingBlock.dimensions.map((dimension, i) => {
                    const commonDimensionRowProps = getCommonDimensionRowProps(dimension, i);
                    switch (dimension.type) {
                        case TargetingDimensionTypes.AdBreakPositionTargets:
                            return <AdBreakPositionTargetsDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.Audiences:
                            return <AudiencesDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.ApiFrameworks:
                            return <ApiFrameworksDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.BundleIdTargets:
                            return <BundleIdTargetsDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.Bvod:
                            return <BvodDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.Categories:
                            return <CategoriesDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.ContentCategories:
                            return <ContentCategoriesDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.ContentChannels:
                            return <ContentMetadataContentChannelDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.Genres:
                            return <ContentMetadataGenresDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.ContentLengths:
                            return <ContentLengthsTargetsDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.ContentNetworks:
                            return <ContentMetadataNetworksDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.ContentRatings:
                            return <ContentMetadataRatingsDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.ContentSeries:
                            return <ContentMetadataTvSeriesDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.Coppa:
                            return <CoppaDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.CustomRules:
                            return (
                                <CustomRulesDimensionRow
                                    {...commonDimensionRowProps}
                                    onChangeRuleCondition={(path, condition) =>
                                        onChangeDimensionRuleCondition(formKey, index, i, path, condition)
                                    }
                                    onChangeRuleGroup={(group) => onChangeDimensionRuleGroup(formKey, index, i, group)}
                                    onChangeRuleId={(path, id) => onChangeDimensionRuleId(formKey, index, i, path, id)}
                                    onChangeRuleOperator={(path, operator) =>
                                        onChangeDimensionRuleOperator(formKey, index, i, path, operator)
                                    }
                                    onChangeRuleValue={(path, value) =>
                                        onChangeDimensionRuleValue(formKey, index, i, path, value)
                                    }
                                    onClickAddRule={(path) => onClickAddDimensionRule(formKey, index, i, path)}
                                    onClickAddRuleGroup={(path) =>
                                        onClickAddDimensionRuleGroup(formKey, index, i, path)
                                    }
                                    onClickDeleteRule={(path) => onClickDeleteDimensionRule(formKey, index, i, path)}
                                />
                            );
                        case TargetingDimensionTypes.CustomTargets:
                            return <CustomTargetsDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.DayPartTargets:
                            return (
                                <DayPartingDimensionRow
                                    {...commonDimensionRowProps}
                                    onChangeTimeZoneMode={(value) =>
                                        onChangeDimensionTimeZoneMode(formKey, index, i, value)
                                    }
                                />
                            );
                        case TargetingDimensionTypes.Dnt:
                            return <DntDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.GeoTargets:
                            return <GeoTargetsDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.Inventory:
                            return <InventoryDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.LiveStream:
                            return <LiveStreamDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.MaxDurationTarget:
                            return <MaxDurationTargetDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.MimeTypes:
                            return <MimeTypesTargetsDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.MinDurationTarget:
                            return <MinDurationTargetDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.OperatingSystems:
                            return <OperatingSystemsTargetsDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.Platforms:
                            return <PlatformsDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.Pmp:
                            return (
                                <PmpDimensionRow
                                    {...commonDimensionRowProps}
                                    onChangePmpCondition={(pmpCondition) =>
                                        onChangeDimensionPmpCondition(formKey, index, i, pmpCondition)
                                    }
                                />
                            );
                        case TargetingDimensionTypes.PodSlotPositionTargets:
                            return <PodSlotPositionTargetsDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.Producers:
                            return <ContentMetadataProducersDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.SegmentRules:
                            return (
                                <SegmentRuleDimensionRow
                                    {...commonDimensionRowProps}
                                    onChangeMode={(mode) => onChangeDimensionMode(formKey, index, i, mode)}
                                    onChangeOperator={(path, operator) =>
                                        onChangeDimensionOperator(formKey, index, i, path, operator)
                                    }
                                    onChangeRuleCondition={(path, condition) =>
                                        onChangeDimensionRuleCondition(formKey, index, i, path, condition)
                                    }
                                    onChangeRuleGroup={(group) => onChangeDimensionRuleGroup(formKey, index, i, group)}
                                    onChangeRuleValue={(path, value) =>
                                        onChangeDimensionRuleValue(formKey, index, i, path, value)
                                    }
                                    onClickAddRule={(path) => onClickAddDimensionRule(formKey, index, i, path)}
                                    onClickAddRuleGroup={(path) =>
                                        onClickAddDimensionRuleGroup(formKey, index, i, path)
                                    }
                                    onClickDeleteRule={(path) => onClickDeleteDimensionRule(formKey, index, i, path)}
                                />
                            );
                        case TargetingDimensionTypes.Sizes:
                            return <SizesDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.SupplyDomainTargets:
                            return <SupplyDomainTargetsDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.SupplyTypes:
                            return <SupplyTypesDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.VideoIds:
                            return <ContentMetadataTitleDimensionRow {...commonDimensionRowProps} />;
                        case TargetingDimensionTypes.LabelValues:
                            return <InventoryLabelDimensionRow {...commonDimensionRowProps} />;
                        default:
                            return "TODO";
                    }
                })}
                <AddDimensionButton
                    helpArticles={helpArticles}
                    isLoading={isLoading}
                    onClick={(key) => onClickAddDimension(formKey, index, key)}
                    supplyTypes={supplyTypeDimension}
                    usedDimensions={targetingBlock.usedDimensions}
                    usedDimensionsAll={usedDimensionsAll}
                />
            </Space>
        </TargetingBlockCollapse>
    );
};
