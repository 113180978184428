import { LabeledValue } from "antd/es/select";

export const COLUMN_KEYS = {
    NAME: "name",
    DESCRIPTION: "description",
    BUYER_SEATS: "buyerSeats",
    ACTIONS: "actions",
};

export const HELP_KEYS = [
    {
        key: "name",
        label: "Name",
        helpPath: "buyerseatlists.name",
    },
    {
        key: "description",
        label: "Description",
        helpPath: "",
    },
    {
        key: "buyerSeats",
        label: "Buyer Seats",
        helpPath: "buyerseatlists.buyerseats",
    },
];

export interface InitialValuesType {
    id: number | null;
    name: string;
    description?: string;
    buyerSeats?: LabeledValue[];
}
