import {
    SUPPLY_CTV_TYPE,
    SUPPLY_SITE_TYPE,
    SUPPLY_FORM_FIELDS,
    SUPPLY_LINEAR_TYPE,
} from "@app/features/inventory/HierarchyForms/constants";
import { useSupplyFormInstance } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormInstance";

interface UseMode {
    extra: string | undefined;
    disabled: boolean;
}

interface GetSupplyModeExtra {
    isLinearType: boolean;
    isSiteType: boolean;
    isCTVType: boolean;
}

const getSupplyModeExtra = ({ isSiteType, isCTVType, isLinearType }: GetSupplyModeExtra): string | undefined => {
    if (isLinearType) return "Linear TV supply can't be dynamic.";
    if (isSiteType)
        return "If more than one domain will be running under this Supply, select Dynamic, otherwise select Static.";
    if (isCTVType)
        return "If more than one bundleID will be running under this Supply, select Dynamic, otherwise select Static.";
};
export const useMode = (): UseMode => {
    const { useWatch } = useSupplyFormInstance();
    const type: number = useWatch(SUPPLY_FORM_FIELDS.TYPE.name);
    const isLinearType: boolean = type === SUPPLY_LINEAR_TYPE.value;
    const isSiteType: boolean = type === SUPPLY_SITE_TYPE.value;
    const isCTVType: boolean = type === SUPPLY_CTV_TYPE.value;
    const extra: string | undefined = getSupplyModeExtra({ isSiteType, isCTVType, isLinearType });
    return { extra, disabled: isLinearType };
};
