import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Form, FormListFieldData, Input, InputNumber, Row } from "antd";
import { FC } from "react";
import { AdBreakRuleAds, AdBreakRuleDuration, AdBreakRuleIndex, AdBreakRuleName } from "../../AdBreakRules";

const MAX_ADS = 8;
const MAX_DURATION = 240;

const nameWidth = "295px";
const adsDurationWidth = "280px";
const indexWidth = "140px";

interface Props {
    name: AdBreakRuleName;
    indexName?: AdBreakRuleIndex;
    ads: AdBreakRuleAds;
    duration: AdBreakRuleDuration;
    isVisibleIndex?: boolean;
    field: FormListFieldData;
    disabledRemove?: boolean;
    onRemove: (index: number | number[]) => void;
    formItemClassList?: string;
}

export const AdBreakLine: FC<Props> = ({
    name,
    indexName,
    ads,
    duration,
    onRemove,
    field,
    isVisibleIndex = false,
    disabledRemove = false,
}) => {
    return (
        <Row gutter={16} align="bottom">
            <Col flex={nameWidth}>
                <Form.Item name={[String(field?.name), name]} label={CHANNEL_FORM_FIELDS.AD_BREAK_RULES.labelName}>
                    <Input style={{ width: "100%" }} />
                </Form.Item>
            </Col>
            {isVisibleIndex && (
                <Col flex={indexWidth}>
                    <Form.Item
                        rules={[{ required: true, message: CHANNEL_FORM_FIELDS.AD_BREAK_RULES.rulesMessage }]}
                        name={[String(field?.name), String(indexName)]}
                        label={CHANNEL_FORM_FIELDS.AD_BREAK_RULES.labelIndex}
                    >
                        <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
            )}
            <Col flex={isVisibleIndex ? indexWidth : adsDurationWidth}>
                <Form.Item
                    rules={[
                        { required: true, message: CHANNEL_FORM_FIELDS.AD_BREAK_RULES.rulesMessage },
                        () => ({
                            validator(_, value) {
                                if (value > MAX_ADS) return Promise.reject(new Error(`Ads can't be over ${MAX_ADS}`));
                                return Promise.resolve();
                            },
                        }),
                    ]}
                    name={[String(field?.name), ads]}
                    label={CHANNEL_FORM_FIELDS.AD_BREAK_RULES.labelAds}
                >
                    <InputNumber style={{ width: "100%" }} />
                </Form.Item>
            </Col>
            <Col flex={adsDurationWidth}>
                <Form.Item
                    rules={[
                        { required: true, message: CHANNEL_FORM_FIELDS.AD_BREAK_RULES.rulesMessage },
                        () => ({
                            validator(_, value) {
                                if (value > MAX_DURATION)
                                    return Promise.reject(new Error(`Duration can't be over ${MAX_DURATION}`));
                                return Promise.resolve();
                            },
                        }),
                    ]}
                    name={[String(field?.name), duration]}
                    label={CHANNEL_FORM_FIELDS.AD_BREAK_RULES.labelDuration}
                >
                    <InputNumber style={{ width: "100%" }} />
                </Form.Item>
            </Col>
            <Col>
                <Form.Item>
                    <Button disabled={disabledRemove} onClick={() => onRemove(field.name)} icon={<DeleteOutlined />} />
                </Form.Item>
            </Col>
        </Row>
    );
};
