import { FC } from "react";
import { Radio } from "antd";
import { SPECIFIED_TRANSPARENCY_OPTIONS } from "@app/features/deals/constants";
import { useAdSourceIncludedExtendedIdsField } from "./useAdSourceIncludedExtendedIdsField";
import { TransparencyFormItem } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceTransparencySection/Fields";

export const AdSourceIncludedExtendedIdsField: FC = () => {
    const { onChange, value } = useAdSourceIncludedExtendedIdsField();
    return (
        <TransparencyFormItem label="Included Extended IDs" data-sdet="included-extended-ids">
            <Radio.Group
                onChange={(e) => onChange(e?.target?.value)}
                value={value}
                options={SPECIFIED_TRANSPARENCY_OPTIONS}
            />
        </TransparencyFormItem>
    );
};
