import { PageHeader } from "@ant-design/pro-layout";
import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";

interface DealCopyFormPageHeaderProps {
    title: string;
}

export const DealCopyFormPageHeader = ({ title }: DealCopyFormPageHeaderProps) => {
    const { seatId, dealId } = useParams<{ seatId?: string; dealId?: string }>();
    const history = useHistory();

    return (
        <PageHeader
            title={title}
            data-sdet="deal-copy-form-page-header"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onBack={() => history.push(ROUTE_FORMATTERS.SEAT_DEAL_DETAILS(seatId, dealId))}
        />
    );
};
