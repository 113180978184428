import { FC } from "react";
import { Form, Input } from "antd";
import { Seat } from "@app/core/services";
import { ConditionalTagRender } from "@app/core/components";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { useAudienceLockAllowedDomains } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/AdvancedFeaturesForm/AdvancedFeaturesFormFields/AudienceLockAllowedDomains/useAudienceLockAllowedDomains";

const { TextArea } = Input;

interface AudienceLockAllowedDomainsProps {
    seat: Seat;
    edit?: boolean;
}

export const AudienceLockAllowedDomains: FC<AudienceLockAllowedDomainsProps> = ({ edit = false, seat }) => {
    const { isOffEntireSeat } = useAudienceLockAllowedDomains();

    if (edit && isOffEntireSeat) return null;

    return (
        <Form.Item
            label={INVENTORY_FORM_FIELDS.AUDIENCE_LOCK_ALLOWED_DOMAIN.label}
            name={INVENTORY_FORM_FIELDS.AUDIENCE_LOCK_ALLOWED_DOMAIN.name}
            wrapperCol={edit ? { xs: 24, sm: 24, md: 24, lg: 18, xl: 16, xxl: 14 } : undefined}
        >
            {edit ? (
                <TextArea
                    rows={4}
                    allowClear
                    placeholder={INVENTORY_FORM_FIELDS.AUDIENCE_LOCK_ALLOWED_DOMAIN.placeholder}
                />
            ) : (
                <ConditionalTagRender data={seat} property={INVENTORY_FORM_FIELDS.AUDIENCE_LOCK_ALLOWED_DOMAIN.name} />
            )}
        </Form.Item>
    );
};
