import { consoleApi, DEAL_SOURCE_TYPES_TAG } from "./console";

export interface DealSourceType {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    sort: number;
    entityType: "DealSourceType";
}

export const dealSourcesApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getDealSourceTypes: builder.query<DealSourceType[], void>({
            query: () => "dealSources/types",
            providesTags: [DEAL_SOURCE_TYPES_TAG],
        }),
    }),
    overrideExisting: false,
});

export const { useGetDealSourceTypesQuery } = dealSourcesApi;
