import { ReadonlyControl } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { css } from "@emotion/css";
import { Form } from "antd";
import { MomentDatePicker } from "@app/core/components/MomentDatePicker";
import { DEAL_FORM_FIELDS } from "../../../../constants";
import { useEndDatePicker } from "./useEndDatePicker";
import { END_OF_DAY, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM } from "@app/core/components/constants";
import { disablePastDates } from "@app/core/utils/disablePastDates";
import moment from "moment-timezone";

export const EndDatePicker = () => {
    const { marketplaceInfoMode } = useMarketplaceInfoWidget();
    const { isVisible, isDisabled, onChange } = useEndDatePicker();
    const isReadonly = marketplaceInfoMode.name === "view";

    if (!isVisible) {
        return null;
    }
    return (
        <Form.Item label={DEAL_FORM_FIELDS.END_DATE.label} name={DEAL_FORM_FIELDS.END_DATE.name}>
            {isReadonly ? (
                <ReadonlyControl<moment.Moment> getLabel={(date) => date.format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM)} />
            ) : (
                <MomentDatePicker
                    allowClear
                    showTime={{ defaultValue: END_OF_DAY }}
                    className={css(`
                .ant-picker-input>input::placeholder {
                        color: gray;
                     }
                `)}
                    placeholder="Select Date..."
                    style={{ width: "100%" }}
                    disabled={isDisabled}
                    disabledDate={disablePastDates}
                    onChange={onChange}
                />
            )}
        </Form.Item>
    );
};
