import { FC } from "react";
import { DealAdSource, DealListItem } from "@app/core/services";
import { Popover, Col, Row, Typography } from "antd";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/types";
import { Dashes } from "@app/core/components";
import { brandCobalt } from "@rubicon/antd-components";

type Props = {
    deal: DealListItem;
    openDrawer: (adSourceId: number, secondaryDetailsView?: SecondaryDetailsView) => void;
};

type PopoverProps = {
    adSources: DealAdSource[];
    deal: DealListItem;
    handleOpenAdSourceDrawer: (number) => void;
};

const PopoverContent: FC<PopoverProps> = ({ adSources, handleOpenAdSourceDrawer }) => {
    return (
        <>
            <Typography.Paragraph>
                The deal is configured to multiple ad sources with <br />
                different priorities <br />
            </Typography.Paragraph>
            <Row>
                <Col span={20}>Name</Col>
                <Col span={4}>Priority</Col>
            </Row>
            {adSources.map((asp) => (
                <Row key={asp.id}>
                    <Col span={20}>
                        <a
                            style={{ textTransform: "capitalize", color: brandCobalt }}
                            onClick={() => {
                                handleOpenAdSourceDrawer(asp.id);
                            }}
                        >
                            {asp.name}
                        </a>
                    </Col>

                    <Col span={4}>{asp.priority.name}</Col>
                </Row>
            ))}
        </>
    );
};

const DealPriority: FC<Props> = ({ deal, openDrawer }) => {
    const adSourcePrioritySort: DealAdSource[] = [...(deal.adSources || [])].sort(
        (a, b) => parseInt(a.priority.name) - parseInt(b.priority.name)
    );

    if (!deal.adSources) {
        return <Dashes />;
    }

    return deal.adSources.length > 1 ? (
        <>
            <Popover
                title="Multiple Priorities"
                content={
                    <PopoverContent
                        adSources={adSourcePrioritySort}
                        handleOpenAdSourceDrawer={openDrawer}
                        deal={deal}
                    />
                }
            >
                <span style={{ color: brandCobalt }}>{adSourcePrioritySort[0].priority.name}, more</span>
            </Popover>
        </>
    ) : (
        <span>{deal.adSources[0]?.priority?.name}</span>
    );
};

export default DealPriority;
