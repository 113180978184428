import { FC } from "react";
import { AccountType, BillingCode } from "./Fields";
import { AdUnitCode } from "./Fields/AdUnitCode/AdUnitCode";
import { InternalOnly } from "@app/features/inventory/components/InternalOnly";

interface Props {
    isEditing: boolean;
}
export const GeneralInternalOnly: FC<Props> = ({ isEditing }) => {
    return (
        <InternalOnly>
            <AccountType />
            <BillingCode />
            {isEditing && <AdUnitCode wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 }} />}
        </InternalOnly>
    );
};
