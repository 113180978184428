import { FC } from "react";
import { FilterSelect } from "@app/core/components/FilterSelect";
import { useSeatAdSourcesRegionFilter } from "./useSeatAdSourcesRegionFilter";
import { HelpTooltip } from "@app/core/components";
import { AdSourcesHealthHelpKeys } from "@app/features/seatAdSources/constants";

export const SeatAdSourcesRegionFilter: FC = () => {
    const { handleChangeValue, options, value, isLoading } = useSeatAdSourcesRegionFilter();

    return (
        <FilterSelect
            dataSdet="seat-ad-sources-region-filter"
            isLoading={isLoading}
            label={
                <HelpTooltip
                    helpKey={AdSourcesHealthHelpKeys.Region}
                    helpKeyList={AdSourcesHealthHelpKeys}
                    popover={true}
                >
                    Region
                </HelpTooltip>
            }
            mode="multiple"
            onChange={handleChangeValue}
            options={options}
            value={value}
        />
    );
};
