import { useUserAccess } from "@app/core/auth";
import {
    SeatHelpKeys,
    AdvancedFeaturesDrawerTitles,
    seatAdvancedFeaturesHelpKeys,
} from "@app/features/inventory/InventorySeat/constants";
import { DefinationField } from "@app/core/components/HelpKeysDrawer";
import { filterDefinitionsFieldsByHelpPathKeys } from "@app/features/inventory/helpers";

interface UseAdvancedFeaturesDefenitionsFields {
    CTVToolsDefinitionsFields: DefinationField[];
    adminToolsDefinitionsFields: DefinationField[];
    adResponsesDefinitionsFields: DefinationField[];
    creativeControlDefinitionsFields: DefinationField[];
    demandManagementDefinitionsFields: DefinationField[];
    unifiedDecisioningDefinitionsFields: DefinationField[];
    inventoryManagementDefinitionsFields: DefinationField[];
    identityAndRegulationsDefinitionsFields: DefinationField[];
}

export const useAdvancedFeaturesDefenitionsFields = (): UseAdvancedFeaturesDefenitionsFields => {
    const { isSysAdmin, isPubAcctMgr, isTremorUser } = useUserAccess();

    const inventoryManagementDefinitionsFields: DefinationField[] =
        isSysAdmin || isPubAcctMgr
            ? seatAdvancedFeaturesHelpKeys[AdvancedFeaturesDrawerTitles.InventoryManagement]
            : filterDefinitionsFieldsByHelpPathKeys(
                  seatAdvancedFeaturesHelpKeys[AdvancedFeaturesDrawerTitles.InventoryManagement],
                  [SeatHelpKeys.SSPConnect, SeatHelpKeys.EnabledChannels, SeatHelpKeys.SeatDistributionGroupsEnabled]
              );

    const CTVToolsDefinitionsFields: DefinationField[] =
        isSysAdmin || isPubAcctMgr
            ? seatAdvancedFeaturesHelpKeys[AdvancedFeaturesDrawerTitles.CTVTools]
            : filterDefinitionsFieldsByHelpPathKeys(
                  seatAdvancedFeaturesHelpKeys[AdvancedFeaturesDrawerTitles.CTVTools],
                  [
                      SeatHelpKeys.SeatEnableContentMetaData,
                      SeatHelpKeys.SeatEnableAdPods,
                      SeatHelpKeys.EnablelinearTv,
                      SeatHelpKeys.SeatEnableLsa,
                  ]
              );

    const identityAndRegulationsDefinitionsFields: DefinationField[] =
        isSysAdmin || isPubAcctMgr || isTremorUser
            ? seatAdvancedFeaturesHelpKeys[AdvancedFeaturesDrawerTitles.IdentityAndRegulations]
            : filterDefinitionsFieldsByHelpPathKeys(
                  seatAdvancedFeaturesHelpKeys[AdvancedFeaturesDrawerTitles.IdentityAndRegulations],
                  [
                      SeatHelpKeys.SeatDmpCreationEnabled,
                      SeatHelpKeys.SeatIdlEnabled,
                      SeatHelpKeys.SeatBVODConnect,
                      SeatHelpKeys.SeatMaskIfa,
                      SeatHelpKeys.SeatGdrpGoogleConsentAll,
                      SeatHelpKeys.SeatEnableAudienceTargeting,
                      SeatHelpKeys.SeatEnableMagniteMatch,
                  ]
              );

    const creativeControlDefinitionsFields: DefinationField[] =
        seatAdvancedFeaturesHelpKeys[AdvancedFeaturesDrawerTitles.CreativeControl];

    const adResponsesDefinitionsFields: DefinationField[] = isSysAdmin
        ? seatAdvancedFeaturesHelpKeys[AdvancedFeaturesDrawerTitles.AdResponses]
        : filterDefinitionsFieldsByHelpPathKeys(
              seatAdvancedFeaturesHelpKeys[AdvancedFeaturesDrawerTitles.AdResponses],
              [
                  SeatHelpKeys.SeatAllowVastExtensionAdomain,
                  SeatHelpKeys.SeatAllowVastExtensionAdsourceType,
                  SeatHelpKeys.SeatAllowVastExtenstionDsp,
                  SeatHelpKeys.SeatAllowVastExtensionBrand,
                  SeatHelpKeys.SeatAllowVastExtenstionIABcat,
                  SeatHelpKeys.SeatAllowVastExtenstionCrid,
                  SeatHelpKeys.SeatAllowVastExtensionAdomain,
                  SeatHelpKeys.SeatAllowVastExtensionAdSourceName,
              ]
          );

    const demandManagementDefinitionsFields: DefinationField[] =
        isSysAdmin || isPubAcctMgr || isTremorUser
            ? seatAdvancedFeaturesHelpKeys[AdvancedFeaturesDrawerTitles.DemandManagement]
            : filterDefinitionsFieldsByHelpPathKeys(
                  seatAdvancedFeaturesHelpKeys[AdvancedFeaturesDrawerTitles.DemandManagement],
                  [
                      SeatHelpKeys.SeatAllowForwardMarketDealBlocks,
                      SeatHelpKeys.SeatAsapFeatheringEnabled,
                      SeatHelpKeys.SeatAuthorizedExportStreamsList,
                      SeatHelpKeys.SeatAllowHighCpm,
                      SeatHelpKeys.SeatEnableAdServing,
                      SeatHelpKeys.seatDacEnabled,
                      SeatHelpKeys.SeatAllowTakeRateTransparency,
                  ]
              );

    const unifiedDecisioningDefinitionsFields: DefinationField[] =
        seatAdvancedFeaturesHelpKeys[AdvancedFeaturesDrawerTitles.UnifiedDecisioning];

    const adminToolsDefinitionsFields: DefinationField[] = isSysAdmin
        ? seatAdvancedFeaturesHelpKeys[AdvancedFeaturesDrawerTitles.AdminTools]
        : filterDefinitionsFieldsByHelpPathKeys(seatAdvancedFeaturesHelpKeys[AdvancedFeaturesDrawerTitles.AdminTools], [
              SeatHelpKeys.SeatClickTracking,
          ]);

    return {
        CTVToolsDefinitionsFields,
        adminToolsDefinitionsFields,
        adResponsesDefinitionsFields,
        creativeControlDefinitionsFields,
        demandManagementDefinitionsFields,
        unifiedDecisioningDefinitionsFields,
        inventoryManagementDefinitionsFields,
        identityAndRegulationsDefinitionsFields,
    };
};
