import { DrawerLayout } from "@app/core/components";
import { useCreateSeatStatsSnapshotMutation } from "@app/core/services/adStats/statsSnapshots";
import { css } from "@emotion/css";
import { Breadcrumb, Button, Drawer, Space, Typography } from "antd";
import { useMemo } from "react";
import { EntityTypes, useAdStats } from "@app/features/adStats";
import { useSeatAuthContext } from "@app/core/auth";
import { Last24HoursPerformanceStatsGraph } from "../../Last24HoursPerformanceStatsGraph";
import { StatsSnapshotViewer } from "../StatsSnapshotViewer";

const drawerClasses = css`
    .ant-drawer-body {
        padding: 0;
    }

    .ant-typography ol {
        list-style-type: none;
    }

    .ant-typography ol li,
    .ant-typography ul li {
        margin: 0;
        padding: 0;
    }
`;

export const SnapshotsDrawer = ({ seat, isOpen, onClose }) => {
    const { adStatHistory } = useAdStats(EntityTypes.Seat);
    const { context } = useSeatAuthContext();
    const [createSnapshot, { isLoading }] = useCreateSeatStatsSnapshotMutation();

    const takeSnapshot = async () => {
        await createSnapshot({ seatId: seat?.id as number, message: "05.09" });
    };

    const graph = useMemo(() => {
        if (!context) {
            return null;
        }
        return (
            <Last24HoursPerformanceStatsGraph
                adStat={adStatHistory[context?.id] || null}
                chartId="last-24-hours-performance-stats-snapshots"
            />
        );
    }, [context, adStatHistory]);

    return (
        <Drawer
            width="80%"
            placement="right"
            open={isOpen}
            closable={false}
            className={drawerClasses}
            destroyOnClose={true}
            onClose={onClose}
        >
            <DrawerLayout
                closeHandler={onClose}
                title={
                    <Breadcrumb>
                        <Breadcrumb.Item>{seat?.name}</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Typography.Text strong>Snapshots</Typography.Text>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                }
            >
                <Space direction="vertical" size="middle">
                    <Space size="middle">
                        <Typography.Text strong>Last 24 Hrs</Typography.Text>
                        <Button loading={isLoading} onClick={takeSnapshot}>
                            Take Snapshot
                        </Button>
                        {/* TODO: Commented out until download functionality is implemented */}
                        {/* <Button icon={<DownloadOutlined />} /> */}
                    </Space>
                    {graph}
                    {seat && <StatsSnapshotViewer seat={seat} />}
                </Space>
            </DrawerLayout>
        </Drawer>
    );
};
