import { Button, Form, InputNumber, Select, Space, Typography } from "antd";
import { FCAP_MODES_OPTIONS, FCAP_TIME_OPTIONS } from "../constants";
import { PlusOutlined } from "@ant-design/icons";
import { useAddFrequencyCappingItem } from "./useAddFrequencyCappingItem";
import { css } from "@emotion/css";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";

export const AddFrequencyCappingItem = () => {
    const { total, handleSetTotal, setMode, mode, minutes, handleSetMinutes, timing, setTiming, handleAddItem } =
        useAddFrequencyCappingItem();
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const isDisabled = dealObjectMode === "edit";
    return (
        <Space
            className={css`
                .ant-space-item {
                    min-width: 32px;
                }

                .ant-space-item:nth-of-type(odd) {
                    width: 100%;
                }
            `}
        >
            <Form.Item label=" ">
                <Space.Compact style={{ display: "flex" }}>
                    <InputNumber
                        placeholder="Total..."
                        value={total}
                        style={{ width: "100%" }}
                        onChange={handleSetTotal}
                        disabled={isDisabled}
                    />
                    <Select
                        disabled={isDisabled}
                        onChange={setMode}
                        value={mode}
                        style={{ width: "100%" }}
                        options={FCAP_MODES_OPTIONS}
                    />
                </Space.Compact>
            </Form.Item>
            <Typography.Text>Per</Typography.Text>
            <Space.Compact style={{ display: "flex" }}>
                <InputNumber
                    placeholder="Time Duration..."
                    style={{ width: "100%" }}
                    value={minutes}
                    onChange={handleSetMinutes}
                    disabled={isDisabled}
                />
                <Select
                    disabled={isDisabled}
                    value={timing}
                    onChange={setTiming}
                    style={{ width: "100%" }}
                    options={FCAP_TIME_OPTIONS}
                />
            </Space.Compact>
            <Button
                disabled={isDisabled}
                type="link"
                onClick={handleAddItem}
                block
                icon={<PlusOutlined />}
                style={{ paddingLeft: 0 }}
            >
                Add
            </Button>
        </Space>
    );
};
