import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useCreteSeatBuyerSeatsListMutation } from "@app/core/services";
import { CONTROLS_ROUTES } from "@app/features/controls/constants";
import { notification } from "antd";
import { useHistory } from "react-router-dom";
import { InitialValuesType } from "../constants";
import { parseValueToApi } from "../helpers";

interface UseBuyerSeatListsCreatePage {
    handleGoBack: () => void;
    handleCancel: () => void;
    handleSubmit: (values: InitialValuesType) => void;
    isSaving: boolean;
}

export const useBuyerSeatListsCreatePage = (seatId: string): UseBuyerSeatListsCreatePage => {
    const history = useHistory();
    const [creteSeatBuyerSeatsList, { isLoading }] = useCreteSeatBuyerSeatsListMutation();

    const handleSubmit = async (values: InitialValuesType) => {
        const body = parseValueToApi(values);
        try {
            const { id } = await creteSeatBuyerSeatsList({
                seatId,
                body,
            }).unwrap();
            history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_DETAILS_PAGE(seatId, id));
            notification.success({ message: "Buyer Seat List created successfully" });
        } catch (error) {
            notification.error({
                message: `Failed to create Buyer Seat List: ${
                    error?.data?.errorDescription || error?.data?.errors?.map(({ message }) => message).join(", ")
                }`,
            });
        }
    };

    const handleCancel = () => {
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_TABS(seatId, CONTROLS_ROUTES.BUYER_SEAT_LIST));
    };

    const handleGoBack = () => {
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_TABS(seatId, CONTROLS_ROUTES.BUYER_SEAT_LIST));
    };

    return {
        isSaving: isLoading,
        handleGoBack,
        handleCancel,
        handleSubmit,
    };
};
