import { FC } from "react";
import { Form, Select } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useDealSharedMarketplacesField } from "@app/features/deals/DealForm/DealDemandFormSections/DealTermsSection/Fields/DealSharedMarketplacesField/useDealSharedMarketplacesField";

export const DealSharedMarketplacesField: FC = () => {
    const { isFetching, options, value, handleChange } = useDealSharedMarketplacesField();
    return (
        <Form.Item
            data-sdet="deal-shared-marketplaces-field"
            name={CREATE_DEAL_FORM_ITEMS_NAME.SHARED_MARKEPLACES}
            label="Shared Marketplaces"
            extra="Additional marketplaces selected above determine where else this demand deal may be utilized."
        >
            <Select
                style={{ width: "100%" }}
                placeholder="Select…"
                mode="multiple"
                optionFilterProp="label"
                allowClear
                onChange={handleChange}
                loading={isFetching}
                options={options}
                value={value}
            />
        </Form.Item>
    );
};
