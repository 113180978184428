import { ROUTE_FORMATTERS } from "@app/core/routing";
import { BulkOperationSourceEntity } from "@app/core/services";
import { BulkOperationsUrlParams } from "@app/features/controls/bulkOperations/constants";
import { FC } from "react";
import { Link } from "react-router-dom";

const text = "Controls > Bulk Operations.";

export interface BulkOperationsUrlState {
    [BulkOperationsUrlParams.Type]: string | null;
    [BulkOperationsUrlParams.EntityType]: BulkOperationSourceEntity | null;
    [BulkOperationsUrlParams.EntityId]: string | null;
    withChildren?: boolean;
}

interface Props {
    seatId: string;
    id: string;
    entityType: string;
}

export const InventoryBulkOperationsLink: FC<Props> = ({ seatId, id, entityType }) => {
    const state: BulkOperationsUrlState = {
        [BulkOperationsUrlParams.Type]: "copy",
        [BulkOperationsUrlParams.EntityType]: entityType as BulkOperationSourceEntity,
        [BulkOperationsUrlParams.EntityId]: id,
    };

    return (
        <Link
            data-sdet="bulk-operations-link"
            to={{
                pathname: ROUTE_FORMATTERS.SEAT_CONTROLS_BULK_OPERATIONS_CREATE(seatId),
                state,
            }}
        >
            {text}
        </Link>
    );
};
