import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import conf from "@app/core/conf";

export const AD_SOURCE_STATS_SNAPSHOTS_TAG = "AdSourceStatsSnapshots";
export const AD_UNIT_STATS_SNAPSHOTS_TAG = "AdUnitStatsSnapshots";
export const SUPPLY_STATS_SNAPSHOTS_TAG = "SupplyStatsSnapshots";
export const BRAND_STATS_SNAPSHOTS_TAG = "BrandStatsSnapshots";
export const PUBLISHER_STATS_SNAPSHOTS_TAG = "PublisherStatsSnapshots";
export const SEAT_STATS_SNAPSHOTS_FOLDERS_TAG = "SeatStatsSnapshotsFolders";
export const SEAT_STATS_SNAPSHOTS_TAG = "SeatStatsSnapshots";

export const adStatsApi = createApi({
    reducerPath: "adStatsApi",
    tagTypes: [
        AD_SOURCE_STATS_SNAPSHOTS_TAG,
        AD_UNIT_STATS_SNAPSHOTS_TAG,
        SUPPLY_STATS_SNAPSHOTS_TAG,
        BRAND_STATS_SNAPSHOTS_TAG,
        PUBLISHER_STATS_SNAPSHOTS_TAG,
        SEAT_STATS_SNAPSHOTS_FOLDERS_TAG,
        SEAT_STATS_SNAPSHOTS_TAG,
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: `${conf.mctvAdStatsCastApiRoot}/cast/resources/stats/`,
        credentials: "include",
    }),
    endpoints: () => ({}),
});
