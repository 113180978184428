import { FC } from "react";
import { rowContentStyle } from "@app/features/deals/DealForm";
import { Col, Row, Typography } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { warningColor } from "@rubicon/antd-components";
import { COL_SIZES, GUTTER } from "@app/features/deals/constants";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import {
    AdSourceAssigneeField,
    AdSourceDescriptionField,
    AdSourceExtendTimeoutField,
    AdSourceExternalContractNumberField,
    AdSourceModeConfiguration,
    AdSourceNameField,
    AdSourcePriorityField,
    AdSourceSalesContactField,
    AdSourceStatusField,
    ExistingAdSourceField,
} from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceConfigurationSection/Fields";

export interface AdSourceConfigurationSectionProps {
    mode: DealFormMode;
    hasMultipleDeals: boolean;
    executeScroll: () => void;
    hasExistingAdSource: boolean;
    hasCreateFields: number | boolean | null;
    isProgrammaticGuaranteed: boolean;
}

export const AdSourceConfigurationSection: FC<AdSourceConfigurationSectionProps> = ({
    hasMultipleDeals,
    mode,
    executeScroll,
    hasCreateFields,
    hasExistingAdSource,
    isProgrammaticGuaranteed,
}) => {
    return (
        <Row style={rowContentStyle} data-sdet="deal-form-ad-source-configuration-section">
            <Col xs={24}>
                {hasMultipleDeals && mode === "edit" ? (
                    <Typography.Paragraph data-sdet="multiple-deal-warning" style={{ marginBottom: "1.5rem" }}>
                        <WarningOutlined data-sdet="warning-icon" style={{ color: warningColor }} /> This Ad Source
                        contains multiple Deals which includes the Deal you&apos;re editing. Be aware that any changes
                        made to the Ad Source details will apply to all of the Deals that are configured to this Ad
                        Source. See below which <Typography.Link onClick={executeScroll}>Deals</Typography.Link> will be
                        effected.
                    </Typography.Paragraph>
                ) : (
                    <Typography.Paragraph data-sdet="configure-ad-source-warning" style={{ marginBottom: "1.5rem" }}>
                        A Deal must be configured to an Ad Source to go live.{" "}
                        {!isProgrammaticGuaranteed &&
                            "An Ad Source is a container that holds one or more Deals, in which you can define common settings."}{" "}
                        <br />
                        {mode !== "edit" &&
                            !isProgrammaticGuaranteed &&
                            "You have a choice to configure this deal to a new Ad Source, to an existing Ad Source or copy an existing Ad Source."}
                    </Typography.Paragraph>
                )}
                {mode !== "edit" && !isProgrammaticGuaranteed && <AdSourceModeConfiguration />}
            </Col>
            {hasExistingAdSource && (
                <Col {...COL_SIZES}>
                    <ExistingAdSourceField />
                </Col>
            )}
            {hasCreateFields && (
                <Col xs={24}>
                    <Row gutter={GUTTER}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                            <AdSourceNameField />
                        </Col>
                    </Row>
                    <Row gutter={GUTTER}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={14}>
                            <AdSourceDescriptionField />
                        </Col>
                    </Row>
                    <Row gutter={GUTTER}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                            <AdSourcePriorityField />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                            <AdSourceStatusField formMode={mode} />
                        </Col>
                    </Row>
                    <Row gutter={GUTTER}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                            <AdSourceAssigneeField />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                            <AdSourceSalesContactField />
                        </Col>
                    </Row>
                    <Row gutter={GUTTER}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                            <AdSourceExternalContractNumberField />
                        </Col>
                        <Col>
                            <AdSourceExtendTimeoutField />
                        </Col>
                    </Row>
                </Col>
            )}
        </Row>
    );
};
