import { FC, useState } from "react";
import { Form, Modal, Radio, RadioChangeEvent } from "antd";
import { BulkOperationType } from "@app/core/services";
import { BULK_OPERATION_FORM_ITEMS } from "../../../constants";
import { useDispatch } from "react-redux";
import { resetBulkOperationSliceState } from "../../../reducer";

const initialModalInfo = { isOpen: false, value: null };

export const BulkOperationTypeField: FC = () => {
    const dispatch = useDispatch();

    const { useFormInstance, useWatch } = Form;
    const form = useFormInstance();
    const entityType = useWatch(BULK_OPERATION_FORM_ITEMS.ENTITY_TYPE.name);
    const sourceEntity = useWatch(BULK_OPERATION_FORM_ITEMS.SOURCE_ENTITY.name);

    const [info, setInfo] = useState<{ isOpen: boolean; value: string | null } | null>(initialModalInfo);

    const switchType = (value) => {
        form.resetFields();
        form.setFieldValue("type", value);
        dispatch(resetBulkOperationSliceState());
    };

    const handleRadioClick = (e: RadioChangeEvent) => {
        if (entityType || sourceEntity) {
            setInfo({ isOpen: true, value: e.target.value });
        } else {
            switchType(e.target.value);
        }
    };

    const handleClose = () => setInfo(initialModalInfo);

    const handleConfirm = () => {
        switchType(info?.value);
        handleClose();
    };

    return (
        <>
            <Form.Item {...BULK_OPERATION_FORM_ITEMS.OPERATION_TYPE} trigger="handleRadioClick">
                <Radio.Group
                    data-sdet="bulk-operation-type"
                    onChange={handleRadioClick}
                    options={[
                        { label: "Edit", value: BulkOperationType.Edit },
                        { label: "Copy", value: BulkOperationType.Copy },
                    ]}
                />
            </Form.Item>
            <Modal
                data-sdet="operation-type-switch-modal"
                title="Confirmation"
                okText="Yes"
                open={info?.isOpen}
                onCancel={handleClose}
                onOk={handleConfirm}
            >
                Are you sure you want to switch operation type? All your changes will be cleared up.
            </Modal>
        </>
    );
};
