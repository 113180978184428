import { FC } from "react";
import { Form, RadioProps, Radio } from "antd";
import { POD_ENFORCEMENT } from "@app/features/inventory/HierarchyForms/constants";
import { useAdUnitPodEnforcement } from "@app/features/inventory/components/FormItems/AdUnitPodEnforcement/useAdUnitPodEnforcement";

interface AdUnitFillModeProps extends RadioProps {
    name: typeof POD_ENFORCEMENT;
    label: string;
}

export const AdUnitPodEnforcement: FC<AdUnitFillModeProps> = ({ name, label, ...rest }) => {
    const { options } = useAdUnitPodEnforcement();
    return (
        <Form.Item name={name} label={label}>
            <Radio.Group size="large" options={options} {...rest} />
        </Form.Item>
    );
};
