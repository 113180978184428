import { FC } from "react";
import { Col, Form, Input } from "antd";
import { MarketplaceInfo } from "@app/core/services";
import { ReadonlyControl } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { usePublisherName } from "./usePublisherName";

export const PublisherNameInput: FC = () => {
    const { isReadonly, isVisible, placeholder } = usePublisherName();
    if (!isVisible) {
        return null;
    }
    return (
        <Col sm={12}>
            <Form.Item name={["dealUnderEdit", "publisherName"]} label="Publisher Name">
                {isReadonly ? (
                    <ReadonlyControl<MarketplaceInfo["deal"]["publisherName"]> getLabel={(name) => name} />
                ) : (
                    <Input placeholder={placeholder} />
                )}
            </Form.Item>
        </Col>
    );
};
