import { FC } from "react";
import { Checkbox, Col, Row } from "antd";
import { TargetingBlockHeader } from "../TargetingBlockHeader";
import { TargetingBlock } from "../types";

interface Props {
    index: number;
    onChangeIsReusable: (index: number, isReusable: boolean) => void;
    targetingBlock: TargetingBlock;
}

export const CreateTargetingBlockHeader: FC<Props> = ({ index, onChangeIsReusable, targetingBlock }) => {
    return (
        <Row align="middle" style={{ height: "32px" }}>
            <Col>
                <TargetingBlockHeader
                    index={index}
                    name={targetingBlock.name}
                    isNewTargetingBlock={!targetingBlock.id}
                />
                {!targetingBlock.name && (
                    <span onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                            style={{ marginLeft: "32px" }}
                            checked={targetingBlock.isReusable}
                            onChange={(e) => onChangeIsReusable(index, e.target.checked)}
                        >
                            Make this targeting reusable
                        </Checkbox>
                    </span>
                )}
            </Col>
        </Row>
    );
};
