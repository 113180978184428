import { Select, SelectProps } from "antd";
import { useGetDealOriginsQuery } from "@app/core/services";
import { useMemo } from "react";

export const DealOriginField = (props: SelectProps) => {
    const { data, isFetching } = useGetDealOriginsQuery();
    const options = useMemo(() => (data || []).map((item) => ({ label: item.name, value: item.id })), [data]);

    return (
        <Select
            {...props}
            data-sdet="legacy-deal-origin-field"
            labelInValue
            loading={isFetching}
            optionFilterProp="label"
            options={options}
            showSearch
        />
    );
};
