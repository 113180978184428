import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormInputFieldChange } from "@app/features/deals/DealForm/reducer";
import { useDispatchOnBlur } from "@app/features/deals/DealForm/hooks";

interface UseAdSourceDailyImpressionField {
    value: number | null;
    onChange: (value: number) => void;
    handleBlur: () => void;
}

export const useAdSourceDailyImpressionField = (): UseAdSourceDailyImpressionField => {
    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_IMPRESSION_CAP]
    );
    const { onChange, onBlur } = useDispatchOnBlur(value);

    const handleBlur = () => {
        onBlur(dealFormInputFieldChange, CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_IMPRESSION_CAP);
    };

    return {
        value,
        handleBlur,
        onChange,
    };
};
