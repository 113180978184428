import { FC } from "react";
import { Form, Radio } from "antd";
import { CHANNEL_FORM_FIELDS, INVENTORY_CODE_TYPE_OPTIONS } from "@app/features/inventory/HierarchyForms/constants";
import { useBrandChannelFormInstance } from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormInstance";

export const CodeType: FC = () => {
    const { mode } = useBrandChannelFormInstance();
    const hidden: boolean = mode === "edit";
    return (
        <Form.Item
            hidden={hidden}
            name={CHANNEL_FORM_FIELDS.CODE_TYPE.name}
            label={CHANNEL_FORM_FIELDS.CODE_TYPE.label}
        >
            <Radio.Group options={INVENTORY_CODE_TYPE_OPTIONS} />
        </Form.Item>
    );
};
