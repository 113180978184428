import cloneDeep from "lodash/cloneDeep";
import {
    MarketplaceInfo,
    useCreateSeatDealLegacyMutation,
    useUpdateBuyerDealMutation,
    useUpdateDealMutation,
} from "@app/core/services";
import { useUpdateDemandDealMutation } from "@app/core/services/console/demandDeals";
import { AdSourceFormsContext, DealInForm } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { notification } from "antd";
import { useWatch } from "antd/lib/form/Form";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDealObjectInitials } from "./useDealObjectInitials";
import { convertLocalDateToApi, parseDateStringFromApi } from "@app/core/utils/date";
import { useAdSourceSyncDealToAdSource } from "@app/features/seatAdSources/SeatAdSourcesForm/sync";
import { useAppDispatch } from "@app/core/store";
import { setAdSourceHasDealChanges } from "@app/features/seatAdSources/reducer";
import { useUserAccess } from "@app/core/auth";
import { parsePriceModelFieldsToApi } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/parsePriceModelFieldsToApi";

const DEAL_CLASS_NAME = {
    BuyerDeal: "com.tremorvideo.ssp.dealsync.model.BuyerDeal",
    DemandDeal: "com.tremorvideo.ssp.dealsync.model.DemandDeal",
    Deal: "com.tremorvideo.ssp.model.Deal",
};

export const useIsDealShared = () => {
    const { forms } = useContext(AdSourceFormsContext);
    const { dealObjectForm } = forms;
    const dealOrigin = useWatch<MarketplaceInfo["deal"]>("dealOrigin", dealObjectForm);

    const [isDealShared, setIsDealShared] = useState(false);
    useEffect(() => {
        if (dealOrigin) {
            const isDealShared = dealOrigin.sharedMarketplaces?.length > 0 || dealOrigin?.sharedSeats?.length > 0;
            setIsDealShared(isDealShared);
        }
    }, [dealOrigin]);
    return { isDealShared };
};

// see DealFactory.java in streaming-platform-api for a list of incompatible legacy fields
const omitLegacyIncompatibleFields = (dealBody) => {
    const dealBodyCopy = cloneDeep(dealBody);
    const {
        network: _a,
        dealTransactionType: _b,
        dealPricingType: _c,
        startTime: _d,
        endTime: _e,
        timeZone: _f,
        currencyType: _g,
        priceModel: _h,
        buyerSeats: _i,
        mediumType: _j,
        bookingVolume: _k,
        resetFrequency: _l,
        tierResetMode: _m,
        tierOverflowAllowed: _n,
        dealPrices: _o,
        ...redactedDealBody
    } = dealBodyCopy;
    return redactedDealBody;
};

export const useDealObjectForm = () => {
    const dispatch = useAppDispatch();
    const { hasInternalAccess } = useUserAccess();
    const { forms } = useContext(AdSourceFormsContext);
    const { adSourceForm, dealObjectForm } = forms;
    const { setDealObjectMode, dealObjectMode, marketplaceInfoMode } = useMarketplaceInfoWidget();
    const dealOrigin = useWatch<MarketplaceInfo["deal"]>("dealOrigin", dealObjectForm);
    const dealUnderEdit = useWatch<MarketplaceInfo["deal"]>("dealUnderEdit", dealObjectForm);
    const adSourceTypeId = useWatch("type", adSourceForm)?.id;
    const { seatId } = useParams<{ seatId: string }>();
    const [
        updateBuyerSeatDeal,
        { data: buyerDealUpdateResponse, isSuccess: isBuyerDealUpdateSuccess, isLoading: isBuyerDealUpdating },
    ] = useUpdateBuyerDealMutation();
    const [
        updateDemandSeatDeal,
        { data: demandDealUpdateResponse, isSuccess: isDemandDealUpdateSuccess, isLoading: isDemandDealUpdating },
    ] = useUpdateDemandDealMutation();
    const [
        updateLegacyDeal,
        { data: legacyDealUpdateResponse, isSuccess: isLegacyDealUpdateSuccess, isLoading: isLegacyDealUpdating },
    ] = useUpdateDealMutation();
    const [createSeatDeal, { data: createDealResponse, isSuccess: isCreateDealSuccess, isLoading: isDealCreating }] =
        useCreateSeatDealLegacyMutation();

    const { dealObjectFormInitials } = useDealObjectInitials();

    const { syncDealFieldsToAdSource, onSaveUpdateMarketplaceInfo, consumeAllSyncedFields } =
        useAdSourceSyncDealToAdSource(dealOrigin?.id);

    useEffect(() => {
        const submitResponse =
            buyerDealUpdateResponse || demandDealUpdateResponse || createDealResponse || legacyDealUpdateResponse;
        const isSuccess =
            isBuyerDealUpdateSuccess || isDemandDealUpdateSuccess || isCreateDealSuccess || isLegacyDealUpdateSuccess;

        if (submitResponse && isSuccess) {
            setDealObjectMode("view");
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dealObjectForm.setFieldsValue({ dealOrigin: submitResponse });
            return;
        }
    }, [
        buyerDealUpdateResponse,
        demandDealUpdateResponse,
        createDealResponse,
        legacyDealUpdateResponse,
        isBuyerDealUpdateSuccess,
        isDemandDealUpdateSuccess,
        isCreateDealSuccess,
        isLegacyDealUpdateSuccess,
        dealObjectForm,
        setDealObjectMode,
    ]);

    useEffect(() => {
        if (dealOrigin) {
            dealObjectForm.setFieldsValue({
                dealUnderEdit: {
                    ...dealOrigin,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    startTime: dealOrigin.startTime
                        ? parseDateStringFromApi(dealOrigin.startTime, dealOrigin.timeZone?.code)
                        : null,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    endTime: dealOrigin.endTime
                        ? parseDateStringFromApi(dealOrigin.endTime, dealOrigin.timeZone?.code)
                        : null,
                    buyerSeats: dealOrigin.buyerSeats,
                },
            });
        }
    }, [dealObjectForm, dealOrigin]);

    const syncFieldsIfEditingAttachedDeal = (dealFormData: DealInForm, dealId: number, isLegacy: boolean) => {
        const isEdit = marketplaceInfoMode.name === "edit";
        if (!isEdit) {
            return;
        }

        const deal = {
            ...dealFormData,
            id: dealId,
        };
        onSaveUpdateMarketplaceInfo(deal);
        if (!isLegacy) {
            syncDealFieldsToAdSource(deal, marketplaceInfoMode);
            dispatch(setAdSourceHasDealChanges(true));
        }
    };

    const handleSubmit = async () => {
        try {
            const formData = await dealObjectForm.validateFields();
            let entityType;

            if (formData.dealUnderEdit.entityType === "Deal") {
                entityType = "Deal";
            } else {
                entityType = formData.dealUnderEdit.buyerSeats?.length ? "BuyerDeal" : "DemandDeal";
            }

            const dealTimeZoneCode = formData.dealUnderEdit.timeZone?.code;
            const { priceModel } = parsePriceModelFieldsToApi(adSourceTypeId);

            const isLegacyDeal = entityType === "Deal";
            const dealBody = {
                ...formData.dealUnderEdit,
                dealOrigin: hasInternalAccess
                    ? formData.dealUnderEdit.dealOrigin
                    : dealOrigin?.dealOrigin || (dealObjectMode === "create" ? { id: 1, name: "Pub Direct" } : null),
                externalName: formData.dealUnderEdit.name,
                externalParentName: formData.dealUnderEdit.name,
                buyerSeats: formData.dealUnderEdit.buyerSeats?.length ? formData.dealUnderEdit.buyerSeats : null,
                rate: entityType !== "Deal" ? formData.dealUnderEdit?.rate : undefined,
                priceModel,
                entityType,
                startTime: convertLocalDateToApi(formData.dealUnderEdit.startTime, dealTimeZoneCode),
                endTime: convertLocalDateToApi(formData.dealUnderEdit.endTime, dealTimeZoneCode),
                "@class": DEAL_CLASS_NAME[entityType],
                seat: seatId ? { id: Number(seatId) } : null,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any;

            try {
                if (dealObjectMode === "create") {
                    await createSeatDeal({
                        seatId: Number(seatId),
                        body: isLegacyDeal ? omitLegacyIncompatibleFields(dealBody) : dealBody,
                    }).unwrap();
                    return;
                }

                if (entityType === "BuyerDeal") {
                    await updateBuyerSeatDeal({
                        id: formData.dealOrigin.id,
                        body: dealBody,
                    }).unwrap();
                    syncFieldsIfEditingAttachedDeal(formData.dealUnderEdit, formData.dealOrigin.id, false);
                    return;
                }
                if (entityType === "DemandDeal") {
                    await updateDemandSeatDeal({
                        id: formData.dealOrigin.id,
                        body: dealBody,
                    }).unwrap();
                    syncFieldsIfEditingAttachedDeal(formData.dealUnderEdit, formData.dealOrigin.id, false);
                    return;
                }
                if (isLegacyDeal) {
                    await updateLegacyDeal({
                        id: formData.dealOrigin.id,
                        body: omitLegacyIncompatibleFields(dealBody),
                    }).unwrap();
                    syncFieldsIfEditingAttachedDeal(formData.dealUnderEdit, formData.dealOrigin.id, true);
                    return;
                }
            } catch (error) {
                notification.error({ message: "Deal Submit Error", description: error.data.errorDescription });
            } finally {
                return;
            }
        } catch (error) {
            dealObjectForm.scrollToField(error.errorFields[0].name, { behavior: "smooth", block: "center" });
        }
    };

    const isPricingSectionVisible =
        dealObjectMode === "create" ? dealUnderEdit?.entityType !== "Deal" : dealOrigin?.entityType !== "Deal";

    const isFormControlsVisible = dealObjectMode !== "view";

    const isFormVisible = dealOrigin || dealObjectMode === "create";

    const formTitle = dealUnderEdit?.name || "";

    const isLoading = isBuyerDealUpdating || isDemandDealUpdating || isDealCreating || isLegacyDealUpdating;

    const handleCancel = () => {
        setDealObjectMode("view");
        dealObjectForm.setFieldsValue({
            dealUnderEdit: {
                ...dealOrigin,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                startTime: dealOrigin?.startTime
                    ? parseDateStringFromApi(dealOrigin.startTime, dealOrigin.timeZone?.code)
                    : null,
                endTime: dealOrigin?.endTime
                    ? parseDateStringFromApi(dealOrigin.endTime, dealOrigin.timeZone?.code)
                    : null,
            },
        });
        consumeAllSyncedFields();
    };
    return {
        handleSubmit,
        handleCancel,
        isPricingSectionVisible,
        isFormControlsVisible,
        isFormVisible,
        dealObjectFormInitials,
        formTitle,
        isLoading,
        dealOriginId: dealOrigin?.id,
        dealOriginTypeId: dealOrigin?.dealType?.id,
    };
};
