import { SkipToken } from "@reduxjs/toolkit/dist/query";
import { GetSeatAdSourcesParams } from "@app/core/services";
import { LiveAdSourceListItem } from "./types";
import { useFetchSeatAdSources } from "./useFetchSeatAdSources";
import { useConnectLiveAdSourcesAdStats } from "./useConnectAdSourceAdStats";

export interface LiveAdSourcesListResponse {
    page: number;
    maxResults: number;
    totalResults: number;
    liveAdSources: LiveAdSourceListItem[];
}

interface UseFetchLiveSeatAdSources {
    liveAdSourcesListResponse: LiveAdSourcesListResponse;
    isFetching: boolean;
}

export const useFetchLiveSeatAdSources = (
    seatAllAdSourcesParams: GetSeatAdSourcesParams | SkipToken,
    throttle = false
): UseFetchLiveSeatAdSources => {
    const { adSourcesListResponse, isFetching } = useFetchSeatAdSources(seatAllAdSourcesParams);
    const liveAdSources = useConnectLiveAdSourcesAdStats(adSourcesListResponse?.adSources, throttle);

    const liveAdSourcesListResponse = Object.assign({}, adSourcesListResponse, { liveAdSources: liveAdSources });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete liveAdSourcesListResponse?.adSources;

    return {
        liveAdSourcesListResponse,
        isFetching,
    };
};
