import { FC } from "react";
import { Form } from "antd";
import { Dashes } from "@app/core/components";
import { AdUnit, Brand, BrandSupply, Publisher, Seat } from "@app/core/services";

interface Props {
    buyerSeatList:
        | Seat["buyerSeatList"]
        | Publisher["buyerSeatList"]
        | Brand["buyerSeatList"]
        | BrandSupply["buyerSeatList"]
        | AdUnit["buyerSeatList"];
}

export const BlockedBuyerSeatListFormItem: FC<Props> = ({ buyerSeatList }) => {
    return (
        <Form.Item label="Blocked Buyer Seat List">
            <Dashes value={buyerSeatList?.name} />
        </Form.Item>
    );
};
