import { FC } from "react";
import { Form, Select } from "antd";
import { Seat } from "@app/core/services";
import { NONE } from "@app/core/components/constants";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { useAccountType } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/SeatDetailsFormFields/GeneralInternalOnly/Fields/AccountType/useAccountType";

interface AccountTypeProps {
    seat: Seat;
    edit?: boolean;
}

export const AccountType: FC<AccountTypeProps> = ({ seat, edit = false, ...rest }) => {
    const { isFetching, options } = useAccountType();
    const { accountType } = seat;
    return (
        <Form.Item
            label={INVENTORY_FORM_FIELDS.ACCOUNT_TYPE.label}
            name={INVENTORY_FORM_FIELDS.ACCOUNT_TYPE.name}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 24, xxl: 20 }}
            {...rest}
        >
            {edit ? (
                <Select
                    showSearch
                    labelInValue
                    options={options}
                    loading={isFetching}
                    optionFilterProp="label"
                    style={{ width: "100%" }}
                    placeholder={INVENTORY_FORM_FIELDS.ACCOUNT_TYPE.placeholder}
                />
            ) : (
                accountType?.name || NONE
            )}
        </Form.Item>
    );
};
