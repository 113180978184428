import { FC } from "react";
import { Alert, Typography } from "antd";
import { getEligibleSyncedFieldLabelsAsList } from "@app/features/syncedFields";
import { useIsDealUsedInOtherAdSources } from "./useIsDealUsedInOtherAdSources";

interface Props {
    adSourceId: number | undefined;
    dealId: number;
    adSourceTypeId: number | undefined;
    adSourceFloorTypeId: number | undefined;
}

const getWarningText = (
    isDealUsedInOtherAdSources: boolean,
    adSourceTypeId: number | undefined,
    adSourceFloorTypeId: number | undefined
): string | null => {
    if (!isDealUsedInOtherAdSources) {
        return null;
    }

    const syncedFieldsList = getEligibleSyncedFieldLabelsAsList(adSourceTypeId, adSourceFloorTypeId);
    if (!syncedFieldsList) {
        return null;
    }

    return `This Deal is used in multiple Ad Sources. ${syncedFieldsList} will not sync with any other Ad Sources. Any changes on this Deal will apply at the Deal level in other Ad Sources.`;
};

export const DealSyncWarningAlert: FC<Props> = ({ adSourceId, dealId, adSourceTypeId, adSourceFloorTypeId }) => {
    const isDealUsedInOtherAdSources = useIsDealUsedInOtherAdSources(adSourceId, dealId);
    const warningText = getWarningText(isDealUsedInOtherAdSources, adSourceTypeId, adSourceFloorTypeId);

    if (!warningText) {
        return null;
    }

    return (
        <Alert
            data-sdet="deal-sync-warning-alert"
            type="warning"
            showIcon
            style={{ alignItems: "baseline", marginBottom: 16 }}
            message={<Typography.Text>{warningText}</Typography.Text>}
        />
    );
};
