import { Typography } from "antd";
import { useSeatAuthContext } from "@app/core/auth";
import { PageHeaderProps } from "@ant-design/pro-layout";
import { StyledPageHeader } from "@app/features/inventory/styled";
import { FC } from "react";

const InventorySeatToolbar: FC<PageHeaderProps> = (props) => {
    const { context } = useSeatAuthContext();

    return (
        <StyledPageHeader {...props} title={`${props.title} Seat: ${context?.name}`}>
            <Typography.Text>View and manage details for this seat.</Typography.Text>
        </StyledPageHeader>
    );
};

export default InventorySeatToolbar;
