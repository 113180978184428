import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { Form, Radio } from "antd";
import { useBvodConnectDemographic } from "./useBvodConnectDemographic";

export const BvodConnectDemographicRadio = () => {
    const { isVisible } = useBvodConnectDemographic();
    if (!isVisible) {
        return null;
    }
    return (
        <Form.Item
            name={AD_SOURCE_FIELDS.BVOD_CONNECT_DEMOGRAPHIC.name}
            label={AD_SOURCE_FIELDS.BVOD_CONNECT_DEMOGRAPHIC.label}
            labelCol={{ lg: 8 }}
            labelAlign="left"
            colon={false}
            style={{ marginBottom: 8 }}
        >
            <Radio.Group>
                <Radio value={1}>Shared</Radio>
                <Radio value={0}>Hidden</Radio>
            </Radio.Group>
        </Form.Item>
    );
};
