import { FC } from "react";
import { Form } from "antd";
import { MomentDatePicker } from "@app/core/components/MomentDatePicker";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useDealStartDateField } from "@app/features/deals/DealForm/DealDemandFormSections/DealTermsSection/Fields/DealStartDateField/useDealStartDateField";
import { disablePastDates } from "@app/core/utils/disablePastDates";

export const DealStartDateField: FC = () => {
    const { handleChange, value } = useDealStartDateField();

    return (
        <Form.Item
            data-sdet="deal-start-date-field"
            rules={[{ required: true, message: "Start date is required" }]}
            label="Start Date"
            name={CREATE_DEAL_FORM_ITEMS_NAME.START_DATE}
        >
            <MomentDatePicker
                value={value}
                showTime
                onChange={handleChange}
                disabledDate={disablePastDates}
                style={{ width: "100%" }}
            />
        </Form.Item>
    );
};
