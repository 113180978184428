import { FC } from "react";
import { Button, Col, Form, Row } from "antd";
import { MomentDatePicker } from "@app/core/components/MomentDatePicker";
import { useEndDateField } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/DeliverySection/Fields/EndDatePicker/useEndDateField";
import { css } from "@emotion/css";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { useIsDatePickerReadOnly } from "../hooks/useIsDatePickerReadOnly";
import { END_OF_DAY } from "@app/core/components/constants";
import { disablePastDates } from "@app/core/utils/disablePastDates";

const datePickerPopupClassname = css`
    .ant-picker-datetime-panel .ant-picker-time-panel .ant-picker-content .ant-picker-time-panel-column {
        &:after {
            height: 230px;
        }
    }
`;

export const EndDatePicker: FC = () => {
    const { onClick, isFieldDisabled, rules, isNoEndDateButtonVisible, onChange } = useEndDateField();
    const isReadOnly = useIsDatePickerReadOnly();

    return (
        <Form.Item
            data-sdet="adsource-end-date"
            rules={rules}
            help={isReadOnly ? "Inherited from Demand Deal" : undefined}
            required={!isNoEndDateButtonVisible}
            className={css(`
                label {
                    width: 100%;
                }
                .ant-picker-input>input::placeholder {
                    color: gray;
                        }
         `)}
            label={
                isNoEndDateButtonVisible ? (
                    <Row justify="space-between" align="middle" style={{ width: "100%" }}>
                        <Col>{AD_SOURCE_FIELDS.END_DATE.label}</Col>
                        <Col>
                            <Button
                                data-sdet={`test-${AD_SOURCE_FIELDS.END_DATE.name}-field-button`}
                                onClick={onClick}
                                type="link"
                                size="small"
                            >
                                {!isFieldDisabled
                                    ? `No ${AD_SOURCE_FIELDS.END_DATE.label}`
                                    : `Select ${AD_SOURCE_FIELDS.END_DATE.label}`}
                            </Button>
                        </Col>
                    </Row>
                ) : (
                    AD_SOURCE_FIELDS.END_DATE.label
                )
            }
            name={AD_SOURCE_FIELDS.END_DATE.name}
            {...VERTICAL_LAYOUT_FIX}
        >
            <MomentDatePicker
                data-sdet={`test-${AD_SOURCE_FIELDS.END_DATE.name}-date-picker`}
                allowClear
                showTime={{ defaultValue: END_OF_DAY }}
                disabled={(isNoEndDateButtonVisible && isFieldDisabled) || isReadOnly}
                placeholder={
                    isNoEndDateButtonVisible && isFieldDisabled
                        ? `No ${AD_SOURCE_FIELDS.END_DATE.label}`
                        : `Select ${AD_SOURCE_FIELDS.END_DATE.label}`
                }
                style={{ width: "100%" }}
                disabledDate={disablePastDates}
                onChange={onChange}
                popupClassName={datePickerPopupClassname}
            />
        </Form.Item>
    );
};
