import { VIEW_LADLE_RESULTS_PATH, VIEW_REPORT_NAME } from "./constants";
import { match } from "path-to-regexp";

interface Report {
    executionCode: string;
    resultCount: number;
    resultLocation: string;
    seatId: number;
    reportId: number;
}

const matchViewLadleResults = match(VIEW_LADLE_RESULTS_PATH);

export const isViewLadleResultsAction = (details: string) => {
    const matched = matchViewLadleResults(details);

    return Boolean(matched);
};

export const getParamsFromViewLadleResultsDetails = (details: string) => {
    const matched = matchViewLadleResults(details);

    if (!matched) {
        return { seatId: null, ladleName: null };
    }

    return matched.params as { seatId: string; ladleName: string };
};

export const isViewReportAction = (name: string) => name === VIEW_REPORT_NAME;

export const getReportFromViewReportDetails = (details: string): Report | null => {
    try {
        return JSON.parse(details);
    } catch (e) {
        return null;
    }
};
