import { AdServingStat } from "./types";

const hasFullResultCodeThatEndsWith = (stat: AdServingStat, code: number): boolean => {
    if (!stat?.results) {
        return false;
    }

    return Object.keys(stat.results).reduce((hasResultCode, resultCode) => {
        const codeMatchesResultCode = resultCode.length === 6 && resultCode.endsWith(String(code));
        return hasResultCode || codeMatchesResultCode;
    }, false);
};

export const canHaveSuccessStats = (stat: AdServingStat): boolean => {
    const hasNoBids = hasFullResultCodeThatEndsWith(stat, 1);
    const hasErrors = hasFullResultCodeThatEndsWith(stat, 2);
    return hasNoBids || hasErrors;
};

export const getSumOfResultCodes = (stat: AdServingStat, codes: number[]): number => {
    if (!stat?.results) {
        return 0;
    }

    return codes.reduce((sum, code) => {
        return sum + (stat.results?.[code] ?? 0);
    }, 0);
};

export const initializeAdServingStatWithTimes = (ntime: number, otime: number): AdServingStat => {
    return {
        ntime,
        otime,
        req: 0,
        results: {},
    };
};

export const addAdServingStats = (
    statA: AdServingStat,
    statB: AdServingStat,
    ntimeOverride: number,
    otimeOverride: number
): AdServingStat => {
    const resultsA = statA.results;
    const resultsB = statB.results;

    const resultsSum = {};
    Object.keys(resultsA).forEach((keyA) => {
        if (resultsB[keyA]) {
            // sum results that exist in both A and B
            resultsSum[keyA] = resultsA[keyA] + statB.results[keyA];
        } else {
            // include A results that do not exist in B
            resultsSum[keyA] = resultsA[keyA];
        }
    });
    Object.keys(resultsB).forEach((keyB) => {
        if (!resultsSum[keyB]) {
            // include B results that do not exist in A
            resultsSum[keyB] = resultsB[keyB];
        }
    });

    return {
        ntime: ntimeOverride,
        otime: otimeOverride,
        req: statA.req + statB.req,
        results: resultsSum,
    };
};
