import { FormListFieldData, Space, Button, Select } from "antd";
import { FC } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { useAdSourceExtendedUserIdField } from "./useAdSourceExtendedUserIdField";
import { css } from "@emotion/css";
import { LabeledValue } from "antd/lib/select";

const classNameSelect = css`
    &.ant-select.ant-select-in-form-item {
        width: 189px;
    }
`;

interface Props {
    field: FormListFieldData;
    remove: (index: number | number[]) => void;
}

export const AdSourceExtendedUserIdItemField: FC<Props> = ({ remove, field: { name } }) => {
    const {
        sourceOptions,
        networkOptions,
        onChangeSource,
        isLoadingSource,
        onChangeNetwork,
        isLoadingNetwork,
        sourceValue,
        networkValue,
        onRemove,
    } = useAdSourceExtendedUserIdField(name, remove);

    return (
        <Space style={{ width: "100%", marginBottom: "24px" }} align="baseline">
            <Select
                placeholder="Select Source"
                options={sourceOptions}
                loading={isLoadingSource}
                onChange={(value: number, option: LabeledValue) => onChangeSource(value, option, name)}
                className={classNameSelect}
                data-sdet="select-source"
                value={sourceValue}
            />
            <Select
                placeholder="Select Network"
                options={networkOptions}
                loading={isLoadingNetwork}
                disabled={!Boolean(sourceValue)}
                onChange={(value: number, option: LabeledValue) => onChangeNetwork(value, option, name)}
                className={classNameSelect}
                data-sdet="select-network"
                value={networkValue}
            />
            <Button icon={<DeleteOutlined />} onClick={() => onRemove(sourceValue, networkValue, name)} />
        </Space>
    );
};
