import { FC } from "react";
import { Card, Col, Row } from "antd";
import { useUserAccess } from "@app/core/auth";
import {
    Name,
    Code,
    CodeType,
    Description,
    PublisherName,
    GeneralInternalOnly,
} from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormSections/GeneralSection/Fields";
import { usePublisherFormInstance } from "../../PublisherFormInstance";

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 12 };
export const GeneralSection: FC = () => {
    const { mode } = usePublisherFormInstance();
    const { isSysAdmin, isPubAcctMgr, hasSeatWriteAccess } = useUserAccess();
    const isInternalOnly: boolean = isSysAdmin || isPubAcctMgr;
    const isCreateMode: boolean = mode === "create";
    const isCopyMode: boolean = mode === "copy";
    const isEditingMode: boolean = mode === "edit";

    const hasCode = (hasSeatWriteAccess && (isCreateMode || isCopyMode)) || !isInternalOnly;

    return (
        <Card bordered={false}>
            <Row gutter={16}>
                <Col {...COL_SIZES}>
                    <Name />
                    <CodeType />
                    {hasCode && <Code />}
                    <Description />
                    <PublisherName />
                </Col>
                {isInternalOnly && (
                    <Col {...COL_SIZES}>
                        <GeneralInternalOnly isEditing={isEditingMode} />
                    </Col>
                )}
            </Row>
        </Card>
    );
};
