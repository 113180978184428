import { consoleApi, EXTERNAL_DATA_STREAMS_TAG } from "./console";

export interface AuthorizedExportStream {
    active: boolean;
    buyerSeats: {
        code: string;
        creationDate: string;
        id: number;
        name: string;
        updateDate: string;
    }[];
    buyers: {
        code: string;
        creationDate: string;
        id: number;
        name: string;
        updateDate: string;
    }[];
    creationDate: string;
    description: null | string;
    excludedGeos: {
        countryCode: string;
        countryName: string;
        creationDate: string;
        id: number;
        updateDate: string;
    }[];
    excludedNetworks: {
        entityType: "Network";
        id: number;
        name: string;
        seat: {
            code: string;
            entityType: "Seat";
            id: number;
            name: string;
        };
    }[];
    id: number;
    marketplaces: {
        authorizedDmps: {
            code: string;
            config: {
                checkpointSize: number;
                chunkSize: number;
                creationDate: string;
                datastoreCode: null | string;
                id: number;
                prefix: string;
                preprocess: boolean;
                processEphemeral: boolean;
                readConcurrency: number;
                regex: null | string;
                updateDate: string;
                writeConcurrency: number;
            };
            creationDate: string;
            daysToKeep: number;
            description: string;
            dmpDataType: { creationDate: string; id: number; name: string; updateDate: string };
            dmpVendor: { creationDate: string; id: number; name: string; updateDate: string };
            entityType: "Dmp";
            id: number;
            name: string;
            seatId: null | number;
            serviceRegions: unknown[];
            supportedIdentifiers: {
                creationDate: string;
                id: number;
                name: string;
                updateDate: string;
            }[];
            updateDate: string;
        }[];
        creationDate: string;
        description: string;
        id: number;
        name: string;
        updateDate: string;
    }[];
    name: string;
    type: {
        creationDate: string;
        id: number;
        name: "Buyer" | "Seller" | "Attribution";
        updateDate: string;
        sort: number;
    };
    updateDate: string;
}

export const externalDataStreamsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getExternalDataStreams: builder.query<AuthorizedExportStream[], void>({
            query: () => "externalDataStreams",
            providesTags: [EXTERNAL_DATA_STREAMS_TAG],
        }),
    }),
});

export const { useGetExternalDataStreamsQuery } = externalDataStreamsApi;
