import { Form, Input, Space, Typography } from "antd";
import {
    AD_SOURCE_FIELDS,
    AdSourceAdvertiserDomainModeIds,
    VERTICAL_LAYOUT_FIX,
} from "@app/features/seatAdSources/constants";
import { AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { getAdditionalAdvertiserDomainsValidator } from "../../utils";
import { gray7 } from "@rubicon/antd-components";

const { TextArea } = Input;

export const AdditionalAdvertiserDomainsTextArea = () => {
    //TODO: add validation for domain name
    const advertiserDomainMode = Form.useWatch<AdSourcesForm["advertiserBlockingMode"]>(AD_SOURCE_FIELDS.MODE.name);
    const reusableAdvertiserDomains = Form.useWatch<AdSourcesForm["seatAdvertiserDomainFilterListDefs"]>(
        AD_SOURCE_FIELDS.REUSABLE_ADVERTISER_DOMAINS.name
    );
    const value = Form.useWatch<AdSourcesForm["advertiserBlockDomains"]>(
        AD_SOURCE_FIELDS.ADDITIONAL_ADVERTISER_DOMAINS.name
    );
    const isAdvertiserDomainModeBlockOverride =
        advertiserDomainMode?.id === AdSourceAdvertiserDomainModeIds.BLOCK_OVERRIDE_INVENTORY;
    const isShowingEmptyWarning = isAdvertiserDomainModeBlockOverride && !value && !reusableAdvertiserDomains?.length;

    return (
        <Form.Item
            {...VERTICAL_LAYOUT_FIX}
            label={
                <Space>
                    {AD_SOURCE_FIELDS.ADDITIONAL_ADVERTISER_DOMAINS.label}
                    <Typography.Text style={{ fontWeight: 400, color: gray7 }}>(enter one per line)</Typography.Text>
                </Space>
            }
            name={AD_SOURCE_FIELDS.ADDITIONAL_ADVERTISER_DOMAINS.name}
            dependencies={[AD_SOURCE_FIELDS.MODE.name, AD_SOURCE_FIELDS.REUSABLE_ADVERTISER_DOMAINS.name]}
            extra={isShowingEmptyWarning ? AD_SOURCE_FIELDS.ADDITIONAL_ADVERTISER_DOMAINS.extra : undefined}
            rules={[
                getAdditionalAdvertiserDomainsValidator(
                    AD_SOURCE_FIELDS.MODE.name,
                    AD_SOURCE_FIELDS.REUSABLE_ADVERTISER_DOMAINS.name
                ),
            ]}
        >
            <TextArea rows={3} placeholder="Enter domains one per line…" />
        </Form.Item>
    );
};
