import { FC, useState } from "react";
import { Button, Col, Form, Radio, Row, Space, Select, Table, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useSeatAdSourcesDetailsPermissions } from "./useSeatAdSourcesDetailsPermissions";
import { AdSource, SeatGrants } from "@app/core/services";
import { Permissions } from "@app/core/services/console/types";
import { UserGroups } from "@app/core/components/Fields/UserGroups";
import { UsersEmail } from "@app/core/components/Fields/UsersEmail";
import { Dashes } from "@app/core/components/Dashes";

interface Props {
    adSource: AdSource;
}

export const SeatAdSourcesDetailsPermissions: FC<Props> = ({ adSource }) => {
    const {
        form,
        handleSubmit,
        handleClear,
        handleDelete,
        PERMISSIONS_FORM_FIELDS,
        COLUMN_KEYS,
        email,
        group,
        isSubmitting,
        permissions,
        columns,
        tableData,
        isFetchingTableData,
    } = useSeatAdSourcesDetailsPermissions(Number(adSource.id));
    const [type, setType] = useState<string>(email);
    const width = "300px";

    const getTableItems = (
        seatGrants: SeatGrants[],
        handleDelete: (emailAddress: string, permission: Permissions, groupId: number | null) => void
    ) =>
        seatGrants?.map(({ userId, emailAddress, permission, groupName, groupId }) => ({
            key: userId,
            [COLUMN_KEYS.EMAIL]: <Dashes value={emailAddress} />,
            [COLUMN_KEYS.GROUP_NAME]: <Dashes value={groupName} />,
            [COLUMN_KEYS.PERMISSIONS]: <Dashes value={permission} />,
            [COLUMN_KEYS.ACTIONS]: (
                <Tooltip title="Remove permission">
                    <Button
                        onClick={() => handleDelete(emailAddress, permission as Permissions, groupId)}
                        icon={<DeleteOutlined />}
                    />
                </Tooltip>
            ),
        }));

    return (
        <div data-sdet="seat-ad-sources-details-permissions">
            <Col>
                <Form layout="vertical" form={form} style={{ marginBottom: 24 }}>
                    <Form.Item>
                        <Radio.Group value={type} onChange={(e) => setType(e.target.value)}>
                            <Radio value={email}>Email</Radio>
                            <Radio value={group}>Group</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {type === email ? <UsersEmail width={width} /> : <UserGroups width={width} />}
                    <Form.Item name={PERMISSIONS_FORM_FIELDS.PERMISSIONS} label="Permissions" style={{ width: width }}>
                        <Select placeholder="Choose one" options={permissions} />
                    </Form.Item>
                    <Row justify="start">
                        <Col>
                            <Space>
                                <Button
                                    type="primary"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                    onClick={handleSubmit}
                                >
                                    Add
                                </Button>
                                <Button type="text" disabled={isSubmitting} onClick={handleClear}>
                                    Clear
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                <Table
                    size="small"
                    columns={columns}
                    dataSource={getTableItems(tableData, handleDelete)}
                    showSorterTooltip={false}
                    bordered
                    loading={isFetchingTableData}
                    pagination={false}
                />
            </Col>
        </div>
    );
};
