import { format } from "@rubicon/utils";
import { CustomRuleIdentifier, CustomRuleGroup } from "@app/features/targeting/types";
import {
    CustomRuleOperators,
    CustomRuleIds,
    CUSTOM_RULE_STRING_OPERATOR_OPTIONS,
    CUSTOM_RULE_DOUBLE_OPERATOR_OPTIONS,
    CUSTOM_RULE_ID_LABELS,
    CUSTOM_RULE_STRING_OPERATOR_LABELS,
    CUSTOM_RULE_DOUBLE_OPERATOR_LABELS,
} from "@app/features/targeting/constants";

export const isCustomRuleGroupEmpty = (group: CustomRuleGroup) => {
    return group.rules.length === 1 && "value" in group.rules[0] && group.rules[0].value === null;
};

export const isCustomRuleValid = (rule: CustomRuleIdentifier) =>
    Boolean(rule.value) || [CustomRuleOperators.IsNull, CustomRuleOperators.IsNotNull].includes(rule.operator);

export const isCustomRuleTargetingValid = (group: CustomRuleGroup) => {
    return group.rules.every((rule) => {
        if ("rules" in rule) {
            return Boolean(rule.rules.length) && isCustomRuleTargetingValid(rule);
        }
        return isCustomRuleValid(rule);
    });
};

export const getCustomRuleOperatorOptions = (id: CustomRuleIds) => {
    if (
        [
            CustomRuleIds.CustomString,
            CustomRuleIds.CustomString2,
            CustomRuleIds.CustomString3,
            CustomRuleIds.CustomString4,
        ].includes(id)
    ) {
        return CUSTOM_RULE_STRING_OPERATOR_OPTIONS;
    }
    return CUSTOM_RULE_DOUBLE_OPERATOR_OPTIONS;
};

export const getCustomRuleIdLabel = (id: CustomRuleIds) => CUSTOM_RULE_ID_LABELS[id];

export const getCustomRuleOperatorLabel = (operator: CustomRuleOperators) =>
    (CUSTOM_RULE_STRING_OPERATOR_LABELS[operator] || CUSTOM_RULE_DOUBLE_OPERATOR_LABELS[operator] || "").toLowerCase();

const getCustomRuleLabel = (rule: CustomRuleIdentifier) => {
    if ([CustomRuleOperators.IsNull, CustomRuleOperators.IsNotNull].includes(rule.operator)) {
        return `${getCustomRuleIdLabel(rule.id)} ${getCustomRuleOperatorLabel(rule.operator)}`;
    }
    return `${getCustomRuleIdLabel(rule.id)} ${getCustomRuleOperatorLabel(rule.operator)} ${
        Array.isArray(rule.value) ? rule.value.join(", ") : format.asSelf(rule.value)
    }`;
};

export const getCustomRuleGroupString = (group: CustomRuleGroup) => {
    return group.rules
        .map((rule) => {
            if ("rules" in rule) {
                return `(${getCustomRuleGroupString(rule)})`;
            }
            return getCustomRuleLabel(rule);
        })
        .join(` ${group.condition} `);
};
