import { Form } from "antd";
import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import {
    MULTIPLICITY,
    AD_UNITS_PROTOCOLS,
    AD_UNITS_MULTIPLICITIES,
} from "@app/features/inventory/HierarchyForms/constants";
import { RuleObject, StoreValue } from "rc-field-form/lib/interface";
import { useGetAdUnitsSupportedProtocolsQuery } from "@app/core/services";
const { useWatch } = Form;

interface UseAdUnitSupportedProtocols {
    isFetching: boolean;
    options: LabeledValue[];
    validator: (rule: RuleObject, value: StoreValue) => Promise<void>;
}

export const useAdUnitSupportedProtocols = (): UseAdUnitSupportedProtocols => {
    const multiplicity = useWatch(MULTIPLICITY);
    const { data, isFetching } = useGetAdUnitsSupportedProtocolsQuery();
    const options: LabeledValue[] = useMemo(
        () => (data || []).map((protocol) => ({ value: protocol.id, label: protocol.name })),
        [data]
    );
    const validator = (_: RuleObject, value: StoreValue): Promise<void> => {
        if (!value?.length) return Promise.reject(new Error("At least one protocol is required"));
        const isPodMultiplicity: boolean = multiplicity === AD_UNITS_MULTIPLICITIES.POD.value;
        const isPlaylistMultiplicity: boolean = multiplicity === AD_UNITS_MULTIPLICITIES.PLAYLIST.value;
        const hasRequiredProtocols = value.some(
            ({ value }) => value === AD_UNITS_PROTOCOLS.VAST_3.value || value === AD_UNITS_PROTOCOLS.VAST_4.value
        );
        if ((isPodMultiplicity || isPlaylistMultiplicity) && !hasRequiredProtocols)
            return Promise.reject(new Error("Pods and Playlists require VAST3 or VAST4 to be supported"));
        return Promise.resolve();
    };

    return {
        isFetching,
        validator,
        options,
    };
};
