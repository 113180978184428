import { FC } from "react";
import { Col, Row, Table, Typography } from "antd";
import { AdSource, DealListItem, MarketplaceInfo, TimeZone } from "@app/core/services";
import { COLUMN_KEY } from "@app/features/deals/DealListPage/constants";
import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED } from "@app/core/components/constants";
import {
    formatInheritedValue,
    FrequencyCappingPopover,
    getBuyerSeats,
    tableStyles,
} from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections";
import DealStatusBadge from "@app/features/deals/DealStatusBadge";
import { DEAL_TYPES } from "@app/features/seatAdSources/SeatAdSourcesForm/DealFormDrawer/DealFormContent/DealFormSections/DealObjectSection/DealTermsSection/Fields";
import { useAdSourceDealsListTable } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceDemandSection/AdSourceDealsListTable/useAdSourceDealsListTable";
import { useDealDetailsDrawer } from "@app/features/deals/DealDetailsDrawer/useDealDetailsDrawer";
import { formatDateInTimeZone } from "@app/core/utils/date";
import { format, formatNumber } from "@rubicon/utils";
import { StyledLink } from "@app/core/components";

const DEAL_MANAGEMENT_DEFAULT_COLUMNS = [
    {
        dataIndex: COLUMN_KEY.NAME,
        title: "Deal Name",
        fixed: "left" as const,
    },
    {
        dataIndex: COLUMN_KEY.NETWORK_NAME,
        title: "DSP",
    },
    {
        dataIndex: COLUMN_KEY.BUYER_SEAT,
        title: "Buyer Seat",
    },
    {
        dataIndex: COLUMN_KEY.CPM_RATE,
        title: "Rate",
    },
    {
        dataIndex: COLUMN_KEY.CURRENCY,
        title: "Currency",
    },
    {
        dataIndex: COLUMN_KEY.START_DATE,
        title: "Start Date",
    },
    {
        dataIndex: COLUMN_KEY.END_DATE,
        title: "End Date",
    },
    {
        dataIndex: COLUMN_KEY.FREQ_CAPPING,
        title: "Freq Capping",
        render: (capping, record) => {
            return capping === "Off" ? (
                "OFF"
            ) : (
                <FrequencyCappingPopover freqCapping={capping} dealFreqCappings={record?.dealFreqCappings} />
            );
        },
    },
    {
        dataIndex: COLUMN_KEY.TARGETING,
        title: "Has Targeting",
    },
    {
        dataIndex: COLUMN_KEY.DEAL_STATUS_NAME,
        title: "Status",
    },
    {
        dataIndex: COLUMN_KEY.DEAL_EXTERNAL_STATUS,
        title: "Buyer Status",
    },
];

interface AdSourceDealsListTableProps {
    dealAdSourceData: AdSource | undefined;
}

export const getTableItems = (
    data: MarketplaceInfo[],
    adSourceLevelData: {
        rate: number | null;
        startDate: string | null;
        endDate: string | null;
        currencyCode: string;
        timeZone: TimeZone | null;
    },
    openDealDetailsDrawer: (dealId: number) => void
) => {
    return data?.map(
        ({
            deal,
            freqCappingType,
            network,
            buyerSeats,
            startDate,
            endDate,
            dealFreqCappings,
            targeting,
            currencyType,
        }) => {
            const isLegacyDeal = deal.dealType.name === DEAL_TYPES.DEAL_TYPE_LEGACY.name;

            const demandCurrencyCode = currencyType?.code;
            const dealCurrencyCode = deal.currencyType?.code;
            const adSourceCurrencyCode = adSourceLevelData.currencyCode;

            const dealRate = deal.rate;
            const adSourceRate = adSourceLevelData.rate;

            const getCurrencyType = () => {
                if (isLegacyDeal && demandCurrencyCode) {
                    return format.asSelf(demandCurrencyCode);
                }
                if (dealCurrencyCode) {
                    return format.asSelf(dealCurrencyCode);
                }
                return formatInheritedValue(adSourceCurrencyCode);
            };
            const getStartDate = () => {
                if (!isLegacyDeal) {
                    return formatDateInTimeZone(deal.startTime, deal.timeZone, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED);
                }
                return startDate
                    ? formatDateInTimeZone(
                          startDate,
                          adSourceLevelData.timeZone,
                          MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED
                      )
                    : formatInheritedValue(
                          formatDateInTimeZone(
                              adSourceLevelData.startDate,
                              adSourceLevelData.timeZone,
                              MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED
                          )
                      );
            };
            const getEndDate = () => {
                if (!isLegacyDeal) {
                    if (deal.endTime) {
                        return formatDateInTimeZone(
                            deal.endTime,
                            deal.timeZone,
                            MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED
                        );
                    }
                    return formatInheritedValue(
                        adSourceLevelData?.endDate &&
                            formatDateInTimeZone(
                                adSourceLevelData.endDate,
                                adSourceLevelData.timeZone,
                                MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED
                            )
                    );
                }
                if (endDate) {
                    return formatDateInTimeZone(
                        endDate,
                        adSourceLevelData.timeZone,
                        MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED
                    );
                }
                return formatInheritedValue(
                    adSourceLevelData?.endDate &&
                        formatDateInTimeZone(
                            adSourceLevelData.endDate,
                            adSourceLevelData.timeZone,
                            MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED
                        )
                );
            };
            const getRate = () => {
                if (isLegacyDeal) {
                    return formatInheritedValue(
                        formatNumber.asMoney(adSourceRate, demandCurrencyCode || adSourceCurrencyCode)
                    );
                }
                return formatNumber.asMoney(dealRate, dealCurrencyCode);
            };
            return {
                key: deal?.id,
                [COLUMN_KEY.NAME]: (
                    <StyledLink data-sdet="deals-list-table-link" onClick={() => openDealDetailsDrawer(deal?.id)}>
                        {format.asSelf(deal?.name)}
                    </StyledLink>
                ),
                [COLUMN_KEY.NETWORK_NAME]: !isLegacyDeal ? deal.network?.name : network?.name,
                [COLUMN_KEY.BUYER_SEAT]: !isLegacyDeal ? getBuyerSeats(deal.buyerSeats) : getBuyerSeats(buyerSeats),
                [COLUMN_KEY.CPM_RATE]: getRate(),
                [COLUMN_KEY.CURRENCY]: getCurrencyType(),
                [COLUMN_KEY.START_DATE]: getStartDate(),
                [COLUMN_KEY.END_DATE]: getEndDate(),
                [COLUMN_KEY.FREQ_CAPPING]: freqCappingType?.name,
                [COLUMN_KEY.FREQ_CAPPING_ITEMS]: dealFreqCappings,
                [COLUMN_KEY.TARGETING]: format.asYesOrNo(targeting?.length),
                [COLUMN_KEY.DEAL_STATUS_NAME]: <DealStatusBadge deal={deal as unknown as DealListItem} />,
                [COLUMN_KEY.DEAL_EXTERNAL_STATUS]: format.asSelf(!isLegacyDeal ? deal?.externalStatus : null),
            };
        }
    );
};

export const AdSourceDealsListTable: FC<AdSourceDealsListTableProps> = ({ dealAdSourceData }) => {
    const { openDealDetailsDrawer } = useDealDetailsDrawer();
    const { isDealRateColumnVisible, rate, endDate, startDate, currencyCode, timeZone } = useAdSourceDealsListTable();
    const columns = DEAL_MANAGEMENT_DEFAULT_COLUMNS.filter(
        (item) => !(!isDealRateColumnVisible && item.dataIndex === COLUMN_KEY.CPM_RATE)
    );
    return (
        <Row style={{ marginBottom: "1.5rem" }}>
            <Col span={24}>
                <Table
                    data-sdet="deal-form-ad-source-deals-list-table"
                    columns={columns}
                    dataSource={getTableItems(
                        dealAdSourceData?.marketplaceInfoList ?? [],
                        {
                            rate,
                            endDate,
                            startDate,
                            currencyCode,
                            timeZone,
                        },
                        openDealDetailsDrawer
                    )}
                    pagination={false}
                    bordered
                    scroll={{ x: 1300 }}
                    className={tableStyles}
                />
            </Col>
            <Col span={24}>
                <Typography.Text type="secondary">* Inherited from Ad Source</Typography.Text>
            </Col>
        </Row>
    );
};
