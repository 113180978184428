import { consoleApi } from "./console";

export interface Feedback {
    body: string;
    companyName: string;
    feedbackCategory: string;
    name: string;
    userEmail: string;
}

export const feedbackApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        submitFeedback: builder.mutation<void, Feedback>({
            query: (body) => {
                return {
                    url: `feedback/submit`,
                    method: "POST",
                    body,
                };
            },
        }),
    }),
    overrideExisting: false,
});

export const { useSubmitFeedbackMutation } = feedbackApi;
