import { FC } from "react";
import { Form, Radio } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME, DAILY_IMPRESSIONS_OPTIONS } from "@app/features/deals/constants";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { useAdSourceDailyImpressionRadio } from "./useAdSourceDailyImpressionRadio";

export const AdSourceDailyImpressionRadio: FC = () => {
    const { value, handleChange } = useAdSourceDailyImpressionRadio();

    return (
        <Form.Item
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_DAILY_IMPRESSION_CAP_ENABLED}
            label={AD_SOURCE_FIELDS.DAILY_IMPRESSION_CAP_ENABLED.label}
            data-sdet="daily-impression-cap-enabled"
        >
            <Radio.Group
                onChange={(e) => handleChange(e?.target?.value)}
                value={value}
                options={DAILY_IMPRESSIONS_OPTIONS}
            />
        </Form.Item>
    );
};
