import { Select } from "antd";
import { CONTENT_TRANSPARENCY_ALLOW_FIELDS_SDET } from "./ContentTransparencyRulesForm";
import { useAllowContentSelect } from "./useAllowContentSelect";
import { TagRender } from "@app/core/components";

export const AllowContentSelect = () => {
    const { onChange, options, isLoading, value } = useAllowContentSelect();

    return (
        <Select
            allowClear
            labelInValue
            showSearch
            data-sdet={CONTENT_TRANSPARENCY_ALLOW_FIELDS_SDET}
            options={options}
            loading={isLoading}
            mode="multiple"
            value={value}
            tagRender={TagRender}
            onChange={onChange}
            placeholder="Select..."
        />
    );
};
