import { Form } from "antd";
import debounce from "lodash.debounce";
import { LabeledValue } from "antd/lib/select";
import { useCallback, useMemo, useState } from "react";
import { useGetSeatBundleIdsQuery } from "@app/core/services";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

const { useWatch } = Form;

interface UseBundleIdListsSelect {
    required: boolean;
    localValue: string;
    isFetching: boolean;
    options: LabeledValue[];
    onSearch: (value: string) => void;
}

export const useBundleIdListsSelect = (): UseBundleIdListsSelect => {
    const bundleFilterMode: boolean = useWatch(INVENTORY_FORM_FIELDS.BUNDLE_ID_FILTER_MODE.name);
    const [localValue, setLocalValue] = useState<string>("");
    const [search, setSearch] = useState<string>("");
    const { data = [], isFetching } = useGetSeatBundleIdsQuery({
        keyword: search,
    });

    const handleSearch = useCallback((value: string): void => {
        setSearch(value);
    }, []);

    const debouncedHandleSearch = useMemo(
        () => debounce((value: string) => handleSearch(value), FILTER_INPUT_DEBOUNCE_TIME),
        [handleSearch]
    );
    const onSearch = (value: string): void => {
        setLocalValue(value);
        debouncedHandleSearch(value);
    };

    const options: LabeledValue[] = useMemo(
        () => (data || []).map((bundle) => ({ value: bundle.id, label: bundle.name })),
        [data]
    );

    return {
        options,
        onSearch,
        isFetching,
        localValue,
        required: !bundleFilterMode,
    };
};
