import { useEffect, useMemo, useState } from "react";
import { FieldData } from "rc-field-form/lib/interface";
import { Form, FormInstance } from "antd";
import { LabeledValue } from "antd/lib/select";
import { TimingStats, TimingStatsEntityTypes, useGetTimingStatsQuery } from "@app/core/services";
import { getTimingStatsRegions } from "@app/core/services/timingStats/utils";
import { LoadingState } from "@app/core/components/LoadableComponent";

export interface UseSeatAdSourcesDetailsTimingStats {
    timingStats: TimingStats | undefined;
    loadingState: LoadingState;
    form: FormInstance;
    regionSelectOptions: LabeledValue[];
    selectedRegion: string;
    onOptionsChange: (changed: FieldData[]) => void;
    refetchTimingStats: () => void;
}

export const useSeatAdSourcesDetailsTimingStats = (adSourceId: number): UseSeatAdSourcesDetailsTimingStats => {
    const {
        data: timingStats,
        isFetching,
        refetch: refetchTimingStats,
    } = useGetTimingStatsQuery({
        entityId: adSourceId,
        entityType: TimingStatsEntityTypes.AdSource,
    });

    const [form] = Form.useForm();

    const [selectedRegion, setSelectedRegion] = useState<string>("");
    const regionSelectOptions = useMemo(() => {
        return timingStats?.data
            ? getTimingStatsRegions(timingStats).map((region) => ({ label: region, value: region }))
            : [];
    }, [timingStats]);

    useEffect(() => {
        if (timingStats && selectedRegion === "" && regionSelectOptions?.[0]?.value) {
            setSelectedRegion(regionSelectOptions[0].value);
        }
    }, [timingStats, selectedRegion, regionSelectOptions, setSelectedRegion]);

    const onOptionsChange = (changedField: FieldData[]) => {
        const isRegionSelectChange = changedField[0].name[0] === "region";
        if (isRegionSelectChange) {
            setSelectedRegion(changedField[0].value);
        }
    };

    const loadingState = isFetching
        ? "loading"
        : timingStats && Object.keys(timingStats.data).length > 0
        ? "loaded"
        : "loadedNoData";

    return {
        timingStats,
        loadingState,
        form,
        regionSelectOptions,
        selectedRegion,
        onOptionsChange,
        refetchTimingStats,
    };
};
