import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormRadioGroupChange } from "@app/features/deals/DealForm/reducer";
import { DealAdSourceTransparencey } from "@app/features/deals/DealForm/types";

interface UseAdSourceFullIpAddressField {
    onChange: (value: DealAdSourceTransparencey) => void;
    value: DealAdSourceTransparencey | null;
}

export const useAdSourceFullIpAddressField = (): UseAdSourceFullIpAddressField => {
    const dispatch = useAppDispatch();
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_IP]);
    const onChange = (value: DealAdSourceTransparencey) => {
        dispatch(dealFormRadioGroupChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_IP, value }));
    };

    return {
        onChange,
        value,
    };
};
