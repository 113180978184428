import { StoreValue } from "rc-field-form/lib/interface";

interface UseAdSourceMinDurationField {
    validator: (maxDuration: StoreValue, value: StoreValue) => Promise<void>;
}

export const useAdSourceMinDurationField = (): UseAdSourceMinDurationField => {
    const validator = (maxDuration: StoreValue, value: StoreValue): Promise<void> => {
        if (maxDuration != null && Number(value) > Number(maxDuration))
            return Promise.reject(new Error("Min Duration can't be greater than Max Duration"));
        return Promise.resolve();
    };

    return {
        validator,
    };
};
