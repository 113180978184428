import { useInventoryDetailsDrawer } from "@app/features/inventory/DetailsDrawer/useInventoryDetailsDrawer";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

export function useSeatSideNav() {
    const menuRef = useRef<HTMLDivElement>();
    const { pathname } = useLocation();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isAccount, setIsAccount] = useState<boolean>(false);
    const [activeApp, setActiveApp] = useState<string | null>(null);
    const [activeGroup, setActiveGroup] = useState<string | null>(null);
    const [prevActive, setPrevActive] = useState<string | null>(null);
    const { handleLeftPage } = useInventoryDetailsDrawer();

    const handleMenuClose = () => {
        handleCloseInventoryDetailsDrawer();
        if (prevActive) {
            setActiveApp(prevActive);
            setPrevActive(null);
        }
        setIsOpen(false);
    };

    const handleAccountClose = () => {
        handleCloseInventoryDetailsDrawer();
        setIsAccount(false);
    };

    const handleGroupClose = () => {
        handleCloseInventoryDetailsDrawer();
        setActiveGroup(null);
    };

    const handleAppClick = (app: { route: string; key: string }) => {
        handleCloseInventoryDetailsDrawer();
        setActiveApp(app.key);
        setIsAccount(false);
        setIsOpen(false);
        // TODO: Make more robust with map of each scene's entry route
        // const [, seat, id] = pathname.split("/");
        // const path = `/${seat}/${id}/${app}`;
        history.push(app.route);
    };

    const handleGroupClick = (group: string) => {
        handleCloseInventoryDetailsDrawer();
        if (!isOpen) {
            setPrevActive(activeApp);
        }
        setActiveApp(null);
        setActiveGroup(group);
        setIsAccount(false);
        setIsOpen(true);
    };

    const handleAccountClick = () => {
        handleCloseInventoryDetailsDrawer();
        setIsAccount(true);
        if (!isOpen) {
            setPrevActive(activeApp);
        }
        setActiveApp(null);
        setActiveGroup(null);
        setIsOpen(true);
    };

    const handleCloseInventoryDetailsDrawer = () => {
        handleLeftPage();
    };

    useEffect(() => {
        // TODO: Make more robust. This will silently break if we add any routes that are not in the form of /seat/:seatId/:activeRoute
        const [, , , activeRoute] = pathname.split("/");
        setActiveApp(activeRoute);
        setPrevActive(null);
    }, [pathname]);

    return {
        menuRef,
        isOpen,
        isAccount,
        activeApp,
        activeGroup,

        handleAppClick,
        handleGroupClick,
        handleAccountClick,
        handleMenuClose,
        handleAccountClose,
        handleGroupClose,
    };
}
