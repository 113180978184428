import { ColumnsType } from "antd/es/table";
import { TableRows } from "./types";
import { FixedType } from "../../recycleBin/RecycleBinTable/types";
import { Button, Tag, Tooltip, Typography } from "antd";
import { SeatContentTransparencyRule } from "@app/core/services";
import { Link } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { NONE } from "@app/core/components/constants";
import { DeleteCandidate } from "../AdvancedBrandSafety";

export const getColumns = (): ColumnsType<TableRows> =>
    [
        {
            dataIndex: "name",
            key: "name",
            sorter: false,
            title: "Name",
            width: 200,
        },
        {
            dataIndex: "description",
            key: "description",
            sorter: false,
            title: "Description",
            width: 150,
        },
        {
            dataIndex: "allowContent",
            key: "allowContent",
            sorter: false,
            title: "Allowed Content Fields",
            width: 200,
        },
        {
            dataIndex: "actions",
            fixed: "right" as FixedType,
            key: "actions",
            sorter: false,
            title: "Actions",
            width: 50,
        },
    ].filter(Boolean);

export const getTableRows = (
    data: SeatContentTransparencyRule[] | undefined,
    seatId: string,
    handleDrawerOpen: (id: string) => void,
    handleDeleteModal: (candidate: DeleteCandidate) => void
) =>
    data
        ? data.map<TableRows>((entity) => {
              return {
                  className: "",
                  id: entity.id,
                  name: (
                      <Typography.Link onClick={() => handleDrawerOpen(String(entity.id))}>
                          {entity.name}
                      </Typography.Link>
                  ),
                  key: entity.id,
                  description: entity.description || NONE,
                  allowContent: entity.contentFields.map(({ name, id }) => <Tag key={id}>{name}</Tag>),
                  actions: (
                      <Button.Group>
                          <Link
                              to={ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_TRANSPARENCY_RULES_EDIT(
                                  seatId,
                                  entity.id
                              )}
                          >
                              <Tooltip title="Edit">
                                  <Button icon={<EditOutlined />} />
                              </Tooltip>
                          </Link>
                          <Tooltip title="Delete">
                              <Button
                                  icon={<DeleteOutlined />}
                                  onClick={() => handleDeleteModal({ id: entity.id, name: entity.name })}
                              />
                          </Tooltip>
                      </Button.Group>
                  ),
              };
          })
        : [];
