import { SectionTitle } from "@app/core/components";
import { AdvancedBrandSafety } from "@app/core/services";
import { Collapse, Form } from "antd";
import { FC } from "react";
import { advancedBrandSafetyHelpKeys } from "../InventoryBrandSafety/InventoryAdvancedBrandSafetyPageForm/helpkeys";
import { HelpKeysButton } from "./helpKeysButton";
import { BrandSafetyGeneralSection, BrandSafetyTargetingSection } from "./sections";

interface Props {
    brandSafety?: AdvancedBrandSafety;
}
export const BrandSafetyDetails: FC<Props> = ({ brandSafety }) => {
    return (
        <Collapse defaultActiveKey={[0, 1]} bordered collapsible="header">
            <Collapse.Panel
                key={0}
                header={<SectionTitle title={advancedBrandSafetyHelpKeys.general.label} />}
                extra={<HelpKeysButton label={advancedBrandSafetyHelpKeys.general.label} sectionKey="general" />}
            >
                <Form layout="vertical">
                    <BrandSafetyGeneralSection brandSafety={brandSafety} />
                </Form>
            </Collapse.Panel>
            <Collapse.Panel
                key={1}
                header={<SectionTitle title={advancedBrandSafetyHelpKeys.targeting.label} />}
                extra={<HelpKeysButton label={advancedBrandSafetyHelpKeys.targeting.label} sectionKey="targeting" />}
            >
                <BrandSafetyTargetingSection brandSafety={brandSafety} />
            </Collapse.Panel>
        </Collapse>
    );
};
