import { Table } from "antd";
import { FC } from "react";
import { convertPrice } from "../utils";
import { SeatSegment } from "@app/core/services";
import { tableStyles } from "../../DemandSection";
import { getAddOnContent } from "@app/core/components/Fields/CurrencyInput";

interface Props {
    segments: SeatSegment[];
    currencyCode: string;
    conversionRate: number;
}

export const SegmentsDetailsTable: FC<Props> = ({ segments, currencyCode, conversionRate }) => {
    const [currencySymbol] = getAddOnContent(currencyCode || "USD") as [string];
    return (
        <Table
            className={tableStyles}
            bordered
            columns={[
                {
                    title: <span>Vendor Name</span>,
                    dataIndex: "dmp",
                    render: (dmp) => dmp.name,
                },
                { title: <span>Segment Name</span>, dataIndex: "name", key: "name" },
                {
                    title: <span>Digital CPM</span>,
                    dataIndex: "digitalPrice",
                    key: "digitalPrice",
                    render: (price) => convertPrice(price, conversionRate, currencySymbol),
                },
                {
                    title: <span>TV CPM</span>,
                    dataIndex: "ctvPrice",
                    key: "ctvPrice",
                    render: (price) => convertPrice(price, conversionRate, currencySymbol),
                },
            ]}
            dataSource={segments}
            pagination={false}
        />
    );
};
