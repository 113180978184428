import { FC } from "react";
import { Card, Col, Row } from "antd";
import {
    Name,
    Code,
    Status,
    CodeType,
    Description,
} from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormSections/GeneralSection/Fields";
import { InternalOnly } from "@app/features/inventory/components/InternalOnly";
import { useBrandChannelFormInstance } from "../../BrandChannelFormInstance";
import { useUserAccess } from "@app/core/auth";

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 12 };
export const GeneralSection: FC = () => {
    const { mode } = useBrandChannelFormInstance();
    const { hasSeatWriteAccess, isSysAdmin, isPubAcctMgr } = useUserAccess();
    const isInternalOnly = isSysAdmin || isPubAcctMgr;
    const isCreateMode = mode === "create";
    const isEditingMode = mode === "edit";

    return (
        <Card bordered={false}>
            <Row gutter={16}>
                <Col {...COL_SIZES}>
                    <Name />
                    <Description />
                    <Status />
                    <CodeType />
                    {((hasSeatWriteAccess && isCreateMode) || !isInternalOnly) && <Code />}
                </Col>
                {isInternalOnly && isEditingMode && (
                    <Col {...COL_SIZES}>
                        <InternalOnly>
                            <Code />
                        </InternalOnly>
                    </Col>
                )}
            </Row>
        </Card>
    );
};
