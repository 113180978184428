import { formatEntity } from "@app/core/components/ChangeHistoryTable/ChangeLogTable";
import { useNotificationError } from "@app/core/components/hooks";
import { useGetEntityTypesQuery } from "@app/core/services";
import { uri } from "@rubicon/utils";
import { Form, Select } from "antd";
import { useHistory, useLocation } from "react-router-dom";

export const AUDIT_LOG_ENTITY_SELECT_SDET = "audit-log-entity-select";
export const ENTITY_QUERY_PARAM = "type";
export const getEntityQueryParam = (queryString: string) =>
    new URLSearchParams(queryString).get(ENTITY_QUERY_PARAM) || undefined;

export const AuditLogEntitySelect = () => {
    const { search: queryString } = useLocation();
    const type = getEntityQueryParam(queryString);
    const history = useHistory();
    const onChange = (value: string | void) => {
        const nextQueryString = value
            ? uri.setSearchParam(queryString, ENTITY_QUERY_PARAM, value)
            : uri.deleteSearchParam(queryString, ENTITY_QUERY_PARAM);
        history.push({
            search: nextQueryString,
        });
    };

    const { data = [], isLoading, error } = useGetEntityTypesQuery();
    useNotificationError(error);

    return (
        <Form.Item label="Entity">
            <Select
                allowClear
                showSearch
                data-sdet={AUDIT_LOG_ENTITY_SELECT_SDET}
                loading={isLoading}
                value={type}
                options={data.map((entityType) => ({
                    label: formatEntity(entityType),
                    value: entityType,
                }))}
                onChange={onChange}
            />
        </Form.Item>
    );
};
