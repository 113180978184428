import { useIsFieldVisible } from "./useIsFieldVisible";
import { useOverrideFloorCpmInputLabel } from "./useOverrideFloorCpmInputLabel";
import { useCallback } from "react";
import { useSyncedFieldsProducer } from "@app/features/syncedFields";
import { useOverrideFloorCurrencyCodeLabel } from "./useOverrideFloorCurrencyCodeLabel";

export const useCpmInput = () => {
    const isVisible = useIsFieldVisible();
    const fieldLabel = useOverrideFloorCpmInputLabel();
    const currencyCode = useOverrideFloorCurrencyCodeLabel();
    const { updateSyncedFields } = useSyncedFieldsProducer();

    const onBlur = useCallback(
        (value: string, isDirty: boolean) => {
            if (isDirty) {
                updateSyncedFields({ cpmRate: value });
            }
        },
        [updateSyncedFields]
    );

    return { isVisible, fieldLabel, currencyCode, onBlur };
};
