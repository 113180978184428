import { Form, Select as AntdSelect } from "antd";
import { useEntityTypeFilter } from "./useEntityTypeFilter";
import { LabeledValue } from "antd/lib/select";

export const ENTITY_TYPE_FILTER = "entity-type-filter";

export const EntityTypeFilter = () => {
    const { selected, options, handleChange } = useEntityTypeFilter();

    return (
        <Form.Item label="">
            <AntdSelect
                data-sdet={ENTITY_TYPE_FILTER}
                onChange={handleChange}
                options={options as LabeledValue[]}
                value={selected}
            />
        </Form.Item>
    );
};

export default EntityTypeFilter;
