import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useMemo } from "react";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { LabeledValue } from "antd/lib/select";
import { useGetMarketplacesQuery } from "@app/core/services";

interface UseDealSharedMarketplacesField {
    isFetching: boolean;
    options: LabeledValue[];
    value: number[] | null;
    handleChange: (value: number[]) => void;
}

export const useDealSharedMarketplacesField = (): UseDealSharedMarketplacesField => {
    const dispatch = useAppDispatch();
    const { data, isFetching } = useGetMarketplacesQuery();
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.SHARED_MARKEPLACES]);
    const options = useMemo(() => (data || []).map((item) => ({ label: item.name, value: item.id })), [data]);

    const handleChange = (value: number[]): void => {
        dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.SHARED_MARKEPLACES, value }));
    };

    return {
        isFetching,
        options,
        value,
        handleChange,
    };
};
