import { FC, useEffect, useState } from "react";
import { Col, Row, Typography } from "antd";
import { ErrorResponse, useGetSeatThirdPartyPixelByIdQuery } from "@app/core/services";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { DrawerBreadcrumbHeader, GoToLink, Loading } from "@app/core/components";
import { CustomPixelDetailsContentActions } from "../../CustomPixelDetailsContentActions";
import { CustomPixelFormReadonly } from "../../CustomPixelForm/CustomPixelFormReadonly";
import { useUserAccess } from "@app/core/auth";
import { HelpKeysButton, HelpKeysDrawerContent } from "@app/core/components/HelpKeysDrawer";
import { ResizableDrawer } from "@rubicon/antd-components";
import { CUSTOM_PIXELS_DEFINITION_FIELDS, drawerRightClosePosition } from "@app/features/controls/constants";
import useNotificationError from "@app/core/components/hooks/useNotificationError";

interface DrawerNavTitleProps {
    name: string;
    customPixelId: number;
    seatId: string;
    handleDefinitionIconClick: () => void;
}

const CustomPixelDetailsDrawerNavTitle: FC<DrawerNavTitleProps> = ({
    name,
    seatId,
    customPixelId,
    handleDefinitionIconClick,
}) => {
    const detailPagePathname = ROUTE_FORMATTERS.SEAT_CONTROLS_CUSTOM_PIXELS_DETAILS(seatId, customPixelId);

    return (
        <Row justify="space-between" align="middle">
            <Col>
                <Row gutter={[8, 4]} align="middle">
                    <Col>
                        <Typography.Title
                            data-sdet="custom-pixel-drawer-title"
                            ellipsis={{ tooltip: { title: name, placement: "bottom" } }}
                            level={5}
                            style={{
                                margin: 0,
                                maxWidth: "18rem",
                            }}
                        >
                            {name}
                        </Typography.Title>
                    </Col>
                    <Col>
                        <GoToLink
                            targetPath={detailPagePathname}
                            goToText="Go to Pixel"
                            tooltipProps={{ title: "Copy Custom Pixel Link" }}
                        />
                    </Col>
                </Row>
            </Col>
            <Col>
                <HelpKeysButton onClick={handleDefinitionIconClick} buttonName="Custom Pixels" />
            </Col>
        </Row>
    );
};

export const CustomPixelDetailsDrawer: FC = () => {
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const { search: queryString } = useLocation();
    const { seatId, pixelId } = useParams<{ seatId: string; pixelId: string }>();
    const { data: customPixel, isLoading, error } = useGetSeatThirdPartyPixelByIdQuery(pixelId);

    const { canEditSeat } = useUserAccess();
    const [showDefinitionContent, setShowDefinitionContent] = useState(false);

    // Show slide in animation on mount
    useEffect(() => {
        setOpen(true);
    }, []);

    useNotificationError(error as ErrorResponse);

    const handleDrawerClose = () => {
        setOpen(false);
        setShowDefinitionContent(false);
    };

    const handleAfterOpenChange = (open: boolean) =>
        !open && history.push({ pathname: ROUTE_FORMATTERS.SEAT_CONTROLS_CUSTOM_PIXELS(seatId), search: queryString });

    const handleDefinitionIconClick = () => setShowDefinitionContent(true);

    const handleBack = () => setShowDefinitionContent(false);

    return (
        <ResizableDrawer
            open={open}
            afterOpenChange={handleAfterOpenChange}
            onClose={handleDrawerClose}
            placement="right"
            width="45%"
            title={
                customPixel ? (
                    showDefinitionContent ? (
                        <DrawerBreadcrumbHeader
                            onBack={handleBack}
                            parentLabel={customPixel.name}
                            childLabel="Custom Pixels"
                        />
                    ) : (
                        <Row gutter={[0, 16]} style={{ marginRight: "1rem" }}>
                            <Col span={24}>
                                <CustomPixelDetailsDrawerNavTitle
                                    name={customPixel.name}
                                    customPixelId={customPixel.id}
                                    seatId={seatId}
                                    handleDefinitionIconClick={handleDefinitionIconClick}
                                />
                            </Col>
                            {canEditSeat && (
                                <Col span={24}>
                                    <CustomPixelDetailsContentActions
                                        seatId={seatId}
                                        customPixelId={customPixel.id}
                                        name={customPixel.name}
                                        postDeleteEvent={handleDrawerClose}
                                    />
                                </Col>
                            )}
                        </Row>
                    )
                ) : (
                    ""
                )
            }
            className={drawerRightClosePosition}
        >
            {isLoading && <Loading />}
            {customPixel &&
                (showDefinitionContent ? (
                    <HelpKeysDrawerContent definitionsFields={CUSTOM_PIXELS_DEFINITION_FIELDS} />
                ) : (
                    <CustomPixelFormReadonly customPixel={customPixel} />
                ))}
        </ResizableDrawer>
    );
};
