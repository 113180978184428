import { MarketplaceInfo } from "@app/core/services";
import { ReadonlyControl } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { Col, Form } from "antd";
import { FC, useEffect } from "react";
import { DEAL_TYPES } from "../DealTypeSelect";
import { DEAL_TRANSACTIONS_TYPE_OPTIONS } from "./constants";

export const TransactionTypeSelect: FC = () => {
    const dealType = Form.useWatch<MarketplaceInfo["deal"]["dealType"]>(["dealUnderEdit", "dealType"]);
    const dealObjectForm = Form.useFormInstance();
    const isVisible = dealType?.name !== DEAL_TYPES.DEAL_TYPE_LEGACY.name;
    const { dealObjectMode } = useMarketplaceInfoWidget();

    useEffect(() => {
        if (dealType && dealObjectMode === "create") {
            let dealTransactionType;
            if (dealType.name === "Programmatic Guaranteed") {
                dealTransactionType = DEAL_TRANSACTIONS_TYPE_OPTIONS.RESERVED;
            }
            if (dealType.name === "Auction Price Deal" || dealType.name === "Fixed Price Deal") {
                dealTransactionType = DEAL_TRANSACTIONS_TYPE_OPTIONS.NON_RESERVED;
            }
            return dealObjectForm.setFieldValue(["dealUnderEdit", "dealTransactionType"], dealTransactionType);
        }
    }, [dealObjectForm, dealObjectMode, dealType]);

    if (!isVisible) {
        return null;
    }
    return (
        <Col sm={12}>
            <Form.Item label="Transaction Type" name={["dealUnderEdit", "dealTransactionType"]}>
                <ReadonlyControl<MarketplaceInfo["deal"]["dealTransactionType"]> getLabel={(value) => value.name} />
            </Form.Item>
        </Col>
    );
};
