import { FC } from "react";
import { Form, InputNumber } from "antd";
import { Seat } from "@app/core/services";
import { NONE } from "@app/core/components/constants";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

interface CalculonUseRateOverrideProps {
    seat: Seat;
    edit?: boolean;
}

export const CalculonUseRateOverride: FC<CalculonUseRateOverrideProps> = ({ edit = false, seat }) => {
    return (
        <Form.Item
            label={INVENTORY_FORM_FIELDS.CALCULON_USE_RATE_OVERRIDE.label}
            name={INVENTORY_FORM_FIELDS.CALCULON_USE_RATE_OVERRIDE.name}
            rules={[
                {
                    validator(_, value) {
                        if (value === 0)
                            return Promise.reject(
                                new Error(INVENTORY_FORM_FIELDS.CALCULON_USE_RATE_OVERRIDE.rulesMessage)
                            );
                        if ((value >= 0.001 && value <= 0.999) || !value) return Promise.resolve();
                        return Promise.reject(new Error(INVENTORY_FORM_FIELDS.CALCULON_USE_RATE_OVERRIDE.rulesMessage));
                    },
                },
            ]}
        >
            {edit ? (
                <InputNumber
                    step={0.001}
                    style={{ width: "100%" }}
                    placeholder={INVENTORY_FORM_FIELDS.CALCULON_USE_RATE_OVERRIDE.placeholder}
                />
            ) : (
                seat.calculonUseRateOverride || NONE
            )}
        </Form.Item>
    );
};
