import { FC } from "react";
import { Typography } from "antd";
import { Conditions } from "../../constants";
import { brandAquamarine, brandRuby } from "@rubicon/antd-components";

interface Props {
    condition: Conditions;
    label: string;
}

export const ConditionTitle: FC<Props> = ({ condition, label }) => {
    return (
        <Typography.Title level={5}>
            <Typography.Text style={{ color: condition === Conditions.Excludes ? brandRuby : brandAquamarine }}>
                {condition === Conditions.Includes ? "Include" : "Exclude"}
            </Typography.Text>{" "}
            {label}:
        </Typography.Title>
    );
};
