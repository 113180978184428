import { Form, Select } from "antd";
import { FC, useState } from "react";
import { LabeledValue } from "antd/lib/select";
import { TagRender } from "@app/core/components";
import { red5, blue6 } from "@rubicon/antd-components";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import { RuleObject, StoreValue } from "rc-field-form/lib/interface";
import { SelectAllButtonDropdownRender } from "@app/core/components/SelectAllButtonDropdownRender";
import { CREATE_BRAND_SAFETY_FORM_ITEMS_NAME } from "@app/features/inventory/InventoryBrandSafety/InventoryAdvancedBrandSafetyPage/constants";
import { useCategories } from "@app/features/inventory/InventoryBrandSafety/InventoryAdvancedBrandSafetyPageForm/BrandSafetyFormFields/useCategories";

const { useWatch, useFormInstance } = Form;

interface IabCategoryBlockExceptionsFieldProps {
    isSubmitting: boolean;
}

export const IABCategoryBlockExceptionsField: FC<IabCategoryBlockExceptionsFieldProps> = ({ isSubmitting }) => {
    const { setFieldValue, validateFields } = useFormInstance();
    const { search, options, isFetching, setSearch } = useCategories();
    const [invalidIabCategories, setInvalidIabCategories] = useState<number[]>([]);

    const blockedIabCategories = useWatch(CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.IABCATEGORIES);
    const IabCategoryBlockExceptions = useWatch(CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.IAB_CATEGORY_BLOCK_EXCEPTIONS);

    const validator = (_: RuleObject, value: StoreValue): Promise<void> => {
        const selectedBlockedIabCategories: number[] = (blockedIabCategories || []).map(({ value }) => value);
        const selectedIabCategoryBlockExceptions: number[] = (value || []).map(({ value }) => value);

        const { isValid, invalidIds } = selectedBlockedIabCategories.reduce<{
            isValid: boolean;
            invalidIds: number[];
        }>(
            (accumulator, id) => {
                if (selectedIabCategoryBlockExceptions.includes(id)) {
                    return { isValid: false, invalidIds: [...accumulator.invalidIds, id] };
                }
                return accumulator;
            },
            { isValid: true, invalidIds: [] }
        );

        setInvalidIabCategories(invalidIds);

        if (!isValid)
            return Promise.reject(
                "Can not have the same category selected in both IAB Categories and Block Exceptions"
            );

        return Promise.resolve();
    };

    const handleMenuChange = (value: LabeledValue[]): void => {
        setFieldValue(CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.IAB_CATEGORY_BLOCK_EXCEPTIONS, value);
        validateFields([CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.IAB_CATEGORY_BLOCK_EXCEPTIONS]);
    };

    return (
        <Form.Item
            rules={[{ validator }]}
            label="IAB Category Block Exceptions"
            name={CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.IAB_CATEGORY_BLOCK_EXCEPTIONS}
        >
            <Select
                allowClear
                showSearch
                labelInValue
                dropdownRender={(menu) => (
                    <SelectAllButtonDropdownRender<LabeledValue>
                        menu={menu}
                        search={search}
                        options={options}
                        onChange={handleMenuChange}
                        value={IabCategoryBlockExceptions}
                        getPrimaryKey={(v) => v.value}
                    />
                )}
                mode="multiple"
                options={options}
                filterOption={false}
                searchValue={search}
                onSearch={setSearch}
                loading={isFetching}
                disabled={isSubmitting}
                autoClearSearchValue={false}
                placeholder="Select IAB Category Block Exceptions"
                tagRender={(props: CustomTagProps) => (
                    <TagRender
                        closeIconColor={invalidIabCategories.includes(props.value) ? red5 : blue6}
                        color={invalidIabCategories.includes(props.value) ? "error" : "processing"}
                        {...props}
                    />
                )}
            />
        </Form.Item>
    );
};
