import { useAppDispatch, useAppSelector } from "@app/core/store";
import { selectDealPagination, setPagination } from "@app/features/deals/DealListPage/reducer";
import { DealListTablePagination } from "@app/features/deals/DealListPage/types";
import { PAGE_SIZE_OPTIONS } from "@app/core/components/constants";

interface UseDealListTablePagination extends DealListTablePagination {
    pageSizeOptions: typeof PAGE_SIZE_OPTIONS;
    handleChange: (page: number, max: number) => void;
}

export const useDealListTablePagination = (totalResults: number): UseDealListTablePagination => {
    const dispatch = useAppDispatch();
    const { page, maxResults } = useAppSelector(selectDealPagination);

    const handleChange = (page: number, maxResults: number) => {
        dispatch(setPagination({ page, maxResults, totalResults }));
    };

    return {
        handleChange,
        maxResults,
        page,
        totalResults,
        pageSizeOptions: PAGE_SIZE_OPTIONS,
    };
};
