import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { FC } from "react";

interface Props {
    sectionFields: string[];
    panelLabel: string;
}

export const DefinitionSidePanel: FC<Props> = ({ sectionFields, panelLabel }) => {
    return (
        <HelpKeysDrawer
            panelLabel={panelLabel}
            definitionsFields={sectionFields.map((field) => ({
                key: field,
                label: AD_SOURCE_FIELDS[field].label,
                helpPath: AD_SOURCE_FIELDS[field].helpPath,
            }))}
        />
    );
};
