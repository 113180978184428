import { useMemo, useState } from "react";
import { useGetUsersQuery } from "@app/core/services";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { useLoadOnScroll } from "@app/core/components";

const MAX = 200;

export const useSalesContactSelect = () => {
    const [searchValue, setSearchValue] = useState<string>("");
    const [keyword, setKeyword] = useState<string>("");
    const [pageByKeyword, setPageByKeyword] = useState({ "": 1 });
    const page = pageByKeyword[keyword] || 1;

    const { data, isFetching: isLoading, originalArgs, error } = useGetUsersQuery({ keyword, max: MAX, page });

    const {
        options: contacts,
        hasMore,
        loadMore,
    } = useLoadOnScroll(data, isLoading, keyword, originalArgs?.page || 1, data?.length === MAX, () =>
        setPageByKeyword((prev) => {
            const currentPage = prev[keyword] || 1;
            return {
                ...prev,
                [keyword]: currentPage + 1,
            };
        })
    );

    const debouncedSetKeyword = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setKeyword]
    );

    const onSearch = (value: string) => {
        setSearchValue(value);
        debouncedSetKeyword(value);
    };

    return { contacts, isLoading, onSearch, searchValue, hasMore, loadMore, error };
};
