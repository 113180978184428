import { useState } from "react";
import { CustomRuleGroup } from "@app/features/targeting/types";
import { isCustomRuleTargetingValid } from "../helpers";

export const useCustomRulesDrawer = (
    onChangeRuleGroup: (customRuleGroup: CustomRuleGroup | null) => void,
    customRuleGroup: CustomRuleGroup
) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isValidationShown, setIsValidationShown] = useState(false);
    const [originalRuleGroup, setOriginalRuleGroup] = useState(null);

    const handleCancel = () => {
        onChangeRuleGroup(originalRuleGroup);
        setOriginalRuleGroup(null);
        setIsOpen(false);
    };

    const handleOpen = (ruleGroup: CustomRuleGroup) => {
        setOriginalRuleGroup(JSON.parse(JSON.stringify(ruleGroup)));
        setIsOpen(true);
        setIsValidationShown(false);
    };

    const handleApply = () => {
        if (isCustomRuleTargetingValid(customRuleGroup)) {
            setIsOpen(false);
            setIsValidationShown(false);
        } else {
            setIsValidationShown(true);
        }
    };

    return {
        handleApply,
        handleCancel,
        handleOpen,
        isOpen,
        isValidationShown,
    };
};
