import { FC } from "react";
import { useUserAccess } from "@app/core/auth";
import { Button, Col, Layout, Row, Space } from "antd";
import { BrandSafetyDetailsDrawerSwtich } from "../../BrandSafetyDetailsDrawer";
import { ConfirmationModal } from "@app/features/inventory/components/ConfirmationModal";
import { useInventoryAdvancedBrandSafetyPage } from "./useInventoryAdvancedBrandSafetyPage";
import { AdvancedBrandSafetyHelp } from "@app/features/inventory/InventoryBrandSafety/AdvancedBrandSafetyHelp";
import InventoryAdvancedBrandSafetyTable from "@app/features/inventory/InventoryBrandSafety/InventoryAdvancedBrandSafetyPage/InventoryAdvancedBrandSafetyTable";
import InventoryAdvancedBrandSafetyPageSearchFilter from "@app/features/inventory/InventoryBrandSafety/InventoryAdvancedBrandSafetyPage/InventoryAdvancedBrandSafetyPageSearchFilter";

const InventoryAdvancedBrandSafetyPage: FC = () => {
    const { hasSeatWriteAccess } = useUserAccess();
    const {
        deleteName,
        handleEdit,
        handleAdd,
        isDeleting,
        handleDelete,
        handleDetails,
        openDeleteModa,
        handleDeleteModal,
        handleCancelDeleteModal,
        handleSearch,
        searchValue,
    } = useInventoryAdvancedBrandSafetyPage();

    return (
        <Layout>
            <Layout.Content>
                <Row justify="space-between" gutter={[16, 16]}>
                    <Col xs={24} sm={10} md={9} lg={7} xl={6} xxl={4}>
                        <InventoryAdvancedBrandSafetyPageSearchFilter
                            handleSearch={handleSearch}
                            searchValue={searchValue}
                        />
                    </Col>
                    <Col>
                        <Space>
                            <AdvancedBrandSafetyHelp />
                            {hasSeatWriteAccess && (
                                <Button type="primary" onClick={handleAdd}>
                                    Add Advanced Brand Safety
                                </Button>
                            )}
                        </Space>
                    </Col>
                </Row>
                <InventoryAdvancedBrandSafetyTable
                    searchValue={searchValue}
                    handleDetails={handleDetails}
                    handleEdit={handleEdit}
                    handleDeleteAdvancedBrandSafety={handleDeleteModal}
                />

                <BrandSafetyDetailsDrawerSwtich />

                <ConfirmationModal
                    open={openDeleteModa}
                    onOk={handleDelete}
                    name={deleteName}
                    onDelete={handleDelete}
                    confirmLoading={isDeleting}
                    onCancel={handleCancelDeleteModal}
                />
            </Layout.Content>
        </Layout>
    );
};

export default InventoryAdvancedBrandSafetyPage;
