import { FC } from "react";
import { Form, Select } from "antd";
import { CREATE_BRAND_SAFETY_FORM_ITEMS_NAME } from "@app/features/inventory/InventoryBrandSafety/InventoryAdvancedBrandSafetyPage/constants";
import { useReusableAdvertiserDomainsField } from "./useReusableAdvertiserDomainsField";

interface ReusableAdvertiserDomainsFieldProps {
    onChange: (value: Array<number>) => void;
    isSubmitting: boolean;
    type: number;
}

export const ReusableAdvertiserDomainsField: FC<ReusableAdvertiserDomainsFieldProps> = ({
    onChange,
    isSubmitting,
    type,
}) => {
    const { options, isLoading } = useReusableAdvertiserDomainsField(type);

    return (
        <Form.Item
            label="Reusable Advertiser Domains"
            name={CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.REUSABLE_ADVERTISER_DOMAINS}
        >
            <Select
                allowClear
                showSearch
                placeholder="Select Reusable Advertiser Domains"
                options={options}
                loading={isLoading}
                onChange={onChange}
                disabled={isSubmitting}
                optionFilterProp="label"
                mode="multiple"
            />
        </Form.Item>
    );
};
