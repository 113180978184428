import { FC } from "react";
import { Alert, Col, Row } from "antd";
import { Targeting } from "@app/core/services/console";
import { Operations, TargetingFormKeys } from "../../constants";
import { ReadOnlyTargetingBlocks } from "../ReadOnlyTargetingBlocks";
import { mergeTargeting } from "../helpers";
import { AdSourceTargetingSectionHeader } from "../../TargetingSectionHeader/AdSourceTargetingSectionHeader";
import { OR_ID } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections";

interface Props {
    targetingBlocks: Targeting[];
    targetingModeId: number;
    dealsWithTargeting: number;
}

export const ReadOnlyAdSourceTargeting: FC<Props> = ({ targetingBlocks, targetingModeId, dealsWithTargeting }) => {
    const operation = targetingModeId === OR_ID ? Operations.Any : Operations.All;

    return (
        <Alert.ErrorBoundary>
            <Row>
                <Col xs={24}>
                    <AdSourceTargetingSectionHeader
                        operation={operation}
                        targetingBlocksCount={targetingBlocks.length}
                        reusableTargetingBlocksCount={targetingBlocks.filter((tb) => tb.name != null).length}
                        dealsWithTargeting={dealsWithTargeting || 0}
                    />
                    <ReadOnlyTargetingBlocks
                        targetingBlocks={mergeTargeting(targetingBlocks, targetingModeId)}
                        operation={operation}
                        formKey={TargetingFormKeys.AdSource}
                    />
                </Col>
            </Row>
        </Alert.ErrorBoundary>
    );
};
