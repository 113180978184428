import { FC } from "react";
import { Form, Tag, Typography } from "antd";
import { formatDateInUtc } from "@app/core/utils";
import { IndustryDetails } from "@app/core/services";
import { NONE } from "@app/core/components/constants";

export const CLASSIFICATIONS_INDUSTRY_DETAILS_CONTENT_SDET = "classifications-industry-details-content";

interface Props {
    industry?: IndustryDetails;
}

export const ClassificationsIndustryDetailsContent: FC<Props> = ({ industry }) => {
    return industry ? (
        <Form layout="vertical" data-sdet={CLASSIFICATIONS_INDUSTRY_DETAILS_CONTENT_SDET}>
            <Form.Item label="Industry Name">
                <Typography.Text>{industry.name}</Typography.Text>
            </Form.Item>
            <Form.Item label="Industry Code">
                <Typography.Text>{industry.code}</Typography.Text>
            </Form.Item>
            <Form.Item label="Description">
                <Typography.Text>{industry.description || NONE}</Typography.Text>
            </Form.Item>
            <Form.Item label="Advertisers">
                {industry.advertisers.length
                    ? industry.advertisers.map(({ name, id }) => <Tag key={id}>{name}</Tag>)
                    : NONE}
            </Form.Item>
            <Form.Item label="Date Created">
                <Typography.Text>{formatDateInUtc(industry.creationDate)}</Typography.Text>
            </Form.Item>
            <Form.Item label="Last Updated">
                <Typography.Text>{formatDateInUtc(industry.updateDate)}</Typography.Text>
            </Form.Item>
        </Form>
    ) : null;
};
