import { Button } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

export const DemandTableControls = ({
    handleDelete,
    handleEdit,
}: {
    handleDelete: () => void;
    handleEdit: () => void;
}) => (
    <Button.Group style={{ width: "100%" }}>
        <Button onClick={handleDelete} icon={<DeleteOutlined />} />
        <Button onClick={handleEdit} icon={<EditOutlined />} />
    </Button.Group>
);
