import { FC } from "react";
import { NONE } from "@app/core/components/constants";
import { AdUnit } from "@app/core/services";
import { GUTTER, TWO_COL_SIZES } from "@app/features/inventory/constants";
import { Col, Form, Row, Tag } from "antd";
import { AD_UNIT_SUPPLY_DETAILS_LABELS, FORM_ITEM_OFFSET } from "../../../constants";
import { getAllowedNotLabel, getYesNoLabel } from "@app/core/utils/labels";
import { UNKNOWN_OPTION } from "@app/features/inventory/HierarchyForms/constants";
import { InternalOnly } from "@app/features/inventory/components/InternalOnly";

const { Item } = Form;
const SECONDS = "Seconds";

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 12, xl: 12, xxl: 12 };

interface Props {
    adUnit: AdUnit;
    isInternalOnly: boolean;
}

export const SupplyDetails: FC<Props> = ({ adUnit, isInternalOnly }) => {
    return (
        <Row data-sdet="ad-unit-supply-details-section">
            <Col {...TWO_COL_SIZES}>
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.linearity} className={FORM_ITEM_OFFSET}>
                            {adUnit?.linearity?.name ?? NONE}
                        </Item>
                    </Col>
                    <Col {...COL_SIZES}>
                        <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.multiplicity} className={FORM_ITEM_OFFSET}>
                            {adUnit?.multiplicity?.name ?? NONE}
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.type} className={FORM_ITEM_OFFSET}>
                            {adUnit?.type?.name || NONE}
                        </Item>
                    </Col>
                    <Col {...COL_SIZES}>
                        <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.videoPlaybacks} className={FORM_ITEM_OFFSET}>
                            {adUnit?.allowedPlaybackMethods?.length
                                ? adUnit.allowedPlaybackMethods.map(({ name, id }) => <Tag key={id}>{name}</Tag>)
                                : NONE}
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.deliveryMethods} className={FORM_ITEM_OFFSET}>
                            {adUnit?.videoDeliveries?.length
                                ? adUnit.videoDeliveries.map(({ name, id }) => <Tag key={id}>{name}</Tag>)
                                : NONE}
                        </Item>
                    </Col>
                    <Col {...COL_SIZES}>
                        <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.skippable} className={FORM_ITEM_OFFSET}>
                            {adUnit?.skip === null ? UNKNOWN_OPTION.label : getYesNoLabel(adUnit?.skip)}
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.skippableMin} className={FORM_ITEM_OFFSET}>
                            {adUnit?.skipmin ? `${adUnit.skipmin} ${SECONDS}` : NONE}
                        </Item>
                    </Col>
                    <Col {...COL_SIZES}>
                        <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.skippableAfter} className={FORM_ITEM_OFFSET}>
                            {adUnit?.skipafter ? `${adUnit.skipafter} ${SECONDS}` : NONE}
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.interstitial} className={FORM_ITEM_OFFSET}>
                            {getYesNoLabel(adUnit?.isInterstitial)}
                        </Item>
                    </Col>
                    <Col {...COL_SIZES}>
                        <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.boxing} className={FORM_ITEM_OFFSET}>
                            {getAllowedNotLabel(adUnit?.isBoxingAllowed)}
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.ssaiType} className={FORM_ITEM_OFFSET}>
                            {adUnit?.ssaiType?.name ?? NONE}
                        </Item>
                    </Col>
                    <Col {...COL_SIZES}>
                        <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.creativeSamples} className={FORM_ITEM_OFFSET}>
                            {Boolean(adUnit?.creativeSamplesMinute) ? adUnit.creativeSamplesMinute : NONE}
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.unwrapVast} className={FORM_ITEM_OFFSET}>
                            {getYesNoLabel(adUnit?.unwrapVast)}
                        </Item>
                    </Col>
                    <Col {...COL_SIZES}>
                        <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.liveStream} className={FORM_ITEM_OFFSET}>
                            {getYesNoLabel(adUnit?.isLiveStream)}
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.contentChannel} className={FORM_ITEM_OFFSET}>
                            {adUnit?.contentChannel ?? NONE}
                        </Item>
                    </Col>
                    <Col {...COL_SIZES}>
                        <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.contentNetwork} className={FORM_ITEM_OFFSET}>
                            {adUnit?.contentNetwork ?? NONE}
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.udOptUout} className={FORM_ITEM_OFFSET}>
                            {getYesNoLabel(adUnit?.udeOptOut)}
                        </Item>
                    </Col>
                    <Col {...COL_SIZES}>
                        <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.liveStreamAcceleration} className={FORM_ITEM_OFFSET}>
                            {getYesNoLabel(adUnit?.isLiveStream)}
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <Item
                            label={AD_UNIT_SUPPLY_DETAILS_LABELS.allowDemandUnderInboundFloor}
                            className={FORM_ITEM_OFFSET}
                        >
                            {getYesNoLabel(adUnit?.overrideDynamicFloor)}
                        </Item>
                    </Col>
                </Row>
            </Col>
            <Col hidden={!isInternalOnly} {...TWO_COL_SIZES}>
                <InternalOnly>
                    <Item label={AD_UNIT_SUPPLY_DETAILS_LABELS.extendedImpWaitTime} className={FORM_ITEM_OFFSET}>
                        {adUnit?.impressionWaitTime?.name ?? NONE}
                    </Item>
                </InternalOnly>
            </Col>
        </Row>
    );
};
