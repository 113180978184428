import { FC } from "react";
import { Form, Radio, RadioProps } from "antd";
import { MULTIPLICITY } from "@app/features/inventory/HierarchyForms/constants";
import { useAdUnitMultiplicity } from "@app/features/inventory/components/FormItems/AdUnitMultiplicity/useAdUnitMultiplicity";

interface AdUnitMultiplicityProps extends RadioProps {
    name: typeof MULTIPLICITY;
    label: string;
}

export const AdUnitMultiplicity: FC<AdUnitMultiplicityProps> = ({ name, label, ...rest }) => {
    const { options, onChange } = useAdUnitMultiplicity();
    return (
        <Form.Item name={name} label={label}>
            <Radio.Group size="large" options={options} onChange={onChange} {...rest} />
        </Form.Item>
    );
};
