import { LegendTextMap } from "@app/features/seatAdSources/seatAdSourcesCharts/charts";
import { AdSourceAdServingResultMap } from "@app/features/adStats/adSourceAdServing/constants";

export const AdServingLegendTextMap: LegendTextMap = Object.values(AdSourceAdServingResultMap).reduce(
    (legendMap, result) => {
        legendMap[result.code] = `[${result.code}] ${result.name}: ${result.description}`;
        return legendMap;
    },
    {}
);
