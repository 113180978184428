import { FC } from "react";
import { Badge, Button, Collapse, Space, Typography, Row, Col, Form } from "antd";
import { brandText2 } from "@rubicon/antd-components";
import { DealOriginFilter } from "./DealOriginFilter";
import { InternalStatusFilter } from "./StatusFilter";
import { useDealListTableFilters } from "./useDealListTableFilters";
import DealListDspFilter from "@app/features/deals/DealListPage/DealListTableFilters/DealListDspFilter";
import { useUserAccess } from "@app/core/auth";
import { DealListPriorityFilter } from "@app/features/deals/DealListPage/DealListTableFilters/DealListPriorityFilter";
import { DealListTypeFilter } from "@app/features/deals/DealListPage/DealListTableFilters/DealListTypeFilter";

export const DealListTableFilters: FC = () => {
    const { filterCount, handleClearFilters } = useDealListTableFilters();
    const { hasInternalAccess } = useUserAccess();

    return (
        <Collapse collapsible="header" defaultActiveKey="filters">
            <Collapse.Panel
                key="filters"
                header={
                    <Space>
                        <Typography.Text>Filters</Typography.Text>
                        <Badge count={filterCount} style={{ backgroundColor: brandText2 }} />
                    </Space>
                }
                extra={
                    <Button
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClearFilters();
                        }}
                    >
                        Clear
                    </Button>
                }
            >
                <Form layout="vertical">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
                            <DealListTypeFilter />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
                            <DealListDspFilter />
                        </Col>
                        <Col hidden={!hasInternalAccess} xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
                            <DealOriginFilter />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
                            <InternalStatusFilter />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
                            <DealListPriorityFilter />
                        </Col>
                    </Row>
                </Form>
            </Collapse.Panel>
        </Collapse>
    );
};
