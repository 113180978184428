import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { Button, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ExtendedUserIdItem } from "./ExtendedUserIdItem";
import { useExtendedUserIdList } from "./useExtendedUserIdList";

export const ExtendedUserIdList = () => {
    const { isVisible } = useExtendedUserIdList();
    if (!isVisible) {
        return null;
    }
    return (
        <Form.Item
            label={AD_SOURCE_FIELDS.EXTENDED_USER_ID.label}
            labelCol={{ lg: 8 }}
            labelAlign="left"
            colon={false}
            style={{ marginBottom: 8 }}
        >
            <Form.List name={AD_SOURCE_FIELDS.EXTENDED_USER_ID.name}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field) => (
                            <ExtendedUserIdItem key={field.key} field={field} remove={remove} />
                        ))}
                        <Button
                            style={{ width: "auto", paddingLeft: 0 }}
                            type="link"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                            data-sdet="add-extended-user-id"
                        >
                            Add Extended User ID
                        </Button>
                    </>
                )}
            </Form.List>
        </Form.Item>
    );
};
