import { AD_SOURCE_FIELDS, COL_SIZES, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { Col, Form, InputNumber } from "antd";
import { useIsFieldVisible } from "./useIsFieldVisible";
import { vastDynamicPricingMultiplierValidator } from "./validator";

export const VastDynamicPricingMultiplierInput = () => {
    const isVisible = useIsFieldVisible();
    if (!isVisible) {
        return null;
    }
    return (
        <Col {...COL_SIZES}>
            <Form.Item
                name={AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_MULTIPLIER.name}
                label={AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_MULTIPLIER.label}
                help={"Enter a multiplier value from 0.5 to 1.5"}
                data-sdet={`test-${AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_MULTIPLIER.name}-field`}
                rules={[
                    {
                        required: true,
                        message: `${AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_MULTIPLIER.label} is required`,
                    },
                    {
                        validator: vastDynamicPricingMultiplierValidator,
                    },
                ]}
                {...VERTICAL_LAYOUT_FIX}
            >
                <InputNumber
                    style={{ width: "100%" }}
                    placeholder={`Enter ${AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_MULTIPLIER.label}`}
                    data-sdet={`test-${AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_MULTIPLIER.name}-input`}
                />
            </Form.Item>
        </Col>
    );
};
