import { css } from "@emotion/css";
import { Drawer } from "antd";
import { FC } from "react";

interface Props {
    handleClose: () => void;
}

export const InternalContactsEditDrawer: FC<Props> = ({ handleClose, children }) => {
    return (
        <Drawer
            width="32rem"
            open={true}
            closable={false}
            onClose={handleClose}
            placement="right"
            className={css`
                .ant-drawer-body {
                    padding: 0;
                }
            `}
        >
            {children}
        </Drawer>
    );
};
