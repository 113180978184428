import { AdvertiserDetails } from "@app/core/services";
import { Col, Row, Typography } from "antd";
import { FC } from "react";

export const CLASSIFICATIONS_ADVERTISER_DOMAINS_DRAWER_CONTENT_SDET =
    "classifications-advertiser-domains-drawer-content";

interface Props {
    advertiser?: AdvertiserDetails;
}
export const ClassificationsAdvertiserDomainsDrawerContent: FC<Props> = ({ advertiser }) => {
    return advertiser ? (
        <Row data-sdet={CLASSIFICATIONS_ADVERTISER_DOMAINS_DRAWER_CONTENT_SDET}>
            {advertiser.advertiserDomains.map((advertiserDomain) => (
                <Col key={advertiserDomain.adomain} span={24}>
                    <Typography.Text>{advertiserDomain.adomain}</Typography.Text>
                </Col>
            ))}
        </Row>
    ) : null;
};
