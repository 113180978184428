import { FC } from "react";
import { Seat } from "@app/core/services";
import { Card, Form, Row, Col } from "antd";
import { useUserAccess } from "@app/core/auth";
import { getYesNoLabel } from "@app/core/utils/labels";
import { INVENTORY_CARD_BODY_STYLE } from "@app/features/inventory/constants";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import { INVENTORY_FORM_FIELDS, SEAT_DETAILS_SECTION_COL_SIZES } from "@app/features/inventory/InventorySeat/constants";
import {
    AuthorizedPBSServers,
    ExternalCodeNamespaces,
} from "@app/features/inventory/InventorySeat/InventorySeatEditPage/AdvancedFeaturesForm/AdvancedFeaturesFormFields";

interface InventoryManagementSectionProps {
    seat: Seat;
    edit?: boolean;
}

const INTERNAL_COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 };
export const InventoryManagementSection: FC<InventoryManagementSectionProps> = ({ seat, edit = false }) => {
    const { isSysAdmin, isPubAcctMgr } = useUserAccess();
    const hasInternalAccess = isSysAdmin || isPubAcctMgr;
    return (
        <Card bordered={false}>
            <Row gutter={16}>
                <Col {...SEAT_DETAILS_SECTION_COL_SIZES}>
                    <ExternalCodeNamespaces edit={edit} seat={seat} />
                    <AuthorizedPBSServers edit={edit} seat={seat} />
                </Col>
                {hasInternalAccess && (
                    <Col {...SEAT_DETAILS_SECTION_COL_SIZES}>
                        <Card type="inner" title="Internal Only" bodyStyle={INVENTORY_CARD_BODY_STYLE}>
                            <Row gutter={16}>
                                <Col {...INTERNAL_COL_SIZES}>
                                    <Form.Item
                                        label={INVENTORY_FORM_FIELDS.SSP_CONNECT_ENABLED.label}
                                        name={INVENTORY_FORM_FIELDS.SSP_CONNECT_ENABLED.name}
                                    >
                                        {edit ? (
                                            <BooleanRadioGroup />
                                        ) : (
                                            getYesNoLabel(Boolean(seat.externalAdUnitCodeNamespaces.length))
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col {...INTERNAL_COL_SIZES}>
                                    <Form.Item
                                        label={INVENTORY_FORM_FIELDS.CHANNELS_ENABLED.label}
                                        name={INVENTORY_FORM_FIELDS.CHANNELS_ENABLED.name}
                                    >
                                        {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.channelsEnabled)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col {...INTERNAL_COL_SIZES}>
                                    <Form.Item
                                        label={INVENTORY_FORM_FIELDS.INVENTORY_DISTRIBUTION_GROUPS.label}
                                        name={INVENTORY_FORM_FIELDS.INVENTORY_DISTRIBUTION_GROUPS.name}
                                    >
                                        {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.distributionGroupsEnabled)}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                )}
            </Row>
        </Card>
    );
};
