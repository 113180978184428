import { getAddOnContent } from "@app/core/components/Fields/CurrencyInput";
import { Audience } from "@app/core/services";
import { Table } from "antd";
import { FC } from "react";
import { tableStyles } from "../DemandSection";
import { convertPrice } from "./utils";

interface Props {
    audiences: Audience[];
    currencyCode: string;
    conversionRate: number;
}

export const AudiencesDetailsTable: FC<Props> = ({ audiences, currencyCode, conversionRate }) => {
    const [currencySymbol] = getAddOnContent(currencyCode || "USD") as [string];
    return (
        <Table
            className={tableStyles}
            bordered
            columns={[
                { title: <span>Audience Name</span>, dataIndex: "name", key: "name" },
                { title: <span>Description</span>, dataIndex: "description", key: "description" },
                {
                    title: <span>Min CPM</span>,
                    dataIndex: "minPriceUSD",
                    key: "minPriceUSD",
                    render: (price) => convertPrice(price, conversionRate, currencySymbol),
                },
                {
                    title: <span>Max CPM</span>,
                    dataIndex: "maxPriceUSD",
                    key: "maxPriceUSD",
                    render: (price) => convertPrice(price, conversionRate, currencySymbol),
                },
            ]}
            dataSource={audiences}
            pagination={false}
        />
    );
};
