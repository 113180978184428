import { useHistory, useParams } from "react-router-dom";
import { Tooltip } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { DealList } from "@app/core/services";
import { DealToolbar } from "@app/features/deals/DealToolbar";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { InfoCircleOutlined } from "@ant-design/icons";
import { brandCobalt } from "@rubicon/antd-components";

interface DealDetailsPageHeaderProps {
    dealAdSource: DealList;
}

export const DealDetailsPageHeader = ({ dealAdSource }: DealDetailsPageHeaderProps) => {
    const { seatId } = useParams<{ seatId?: string }>();
    const history = useHistory();
    const { deal } = dealAdSource;
    const { sharedSeats, sharedMarketplaces, adSources, id, entityType, name } = deal;
    const isDealShared =
        (sharedMarketplaces && sharedMarketplaces.length > 0) || (sharedSeats && sharedSeats?.length > 0) || false;

    return (
        <PageHeader
            title={
                <>
                    {name}
                    {isDealShared && (
                        <Tooltip title="This deal has been shared via an external entity, and cannot be edited here">
                            <InfoCircleOutlined style={{ color: brandCobalt, marginLeft: 8 }} />
                        </Tooltip>
                    )}
                </>
            }
            data-sdet="seat-details-page-header"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onBack={() => history.push(ROUTE_FORMATTERS.SEAT_DEAL_HEALTH(seatId))}
        >
            <DealToolbar
                seatId={Number(seatId)}
                dealId={id}
                isNested={false}
                adSources={adSources}
                isDealShared={isDealShared}
                dealEntityType={entityType}
            />
        </PageHeader>
    );
};
