import { DIAGNOSTICS, useDiagnosticsContent } from "./useDiagnosticsContent";

interface UseDefinitionDiagnosticsContent {
    isLoading: boolean;
    breadcrumbs: string;
}

export const useDefinitionDiagnosticsContent = (id: string | number): UseDefinitionDiagnosticsContent => {
    const { isLoading, breadcrumbs, isSeat, title } = useDiagnosticsContent(id);
    const breadCrumbsWithDiagnostics = isSeat
        ? title
        : breadcrumbs
        ? `${breadcrumbs} ${breadcrumbs && "/"} ${DIAGNOSTICS}`
        : DIAGNOSTICS;

    return {
        isLoading,
        breadcrumbs: breadCrumbsWithDiagnostics,
    };
};
