import { uri } from "@rubicon/utils";
import debounce from "lodash.debounce";
import { LabeledValue } from "antd/lib/select";
import { useHistory, useLocation } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetGeographiesCountriesQuery } from "@app/core/services";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { useGetQueryRangesQuery } from "@app/core/services/console/queryRanges";
import { useSeatAuthContext } from "@app/core/auth";

const DEFAULT_DATA_RANGE = "lastSevenDays";
const DEFAULT_AD_SOURCE_TYPE = 4;
const CUSTOM_DATA_RANGE_ID = 13;

const DISABLED_HIST_QUERY_RAGES = [1, 2, CUSTOM_DATA_RANGE_ID];
const DISABLED_EST_QUERY_RAGES = [CUSTOM_DATA_RANGE_ID];

export enum FloorAnalysisType {
    EST = "est",
    HIST = "hist",
}

export const enum FloorAnalysisParams {
    DateRange = "date-range",
    AdSourceType = "adSourceType",
    Country = "country",
}

interface UseFloorAnalysisFilters {
    dateRange: string;
    country?: string;
    adSourceType: number;
    handleChangeDateRange: (value: string) => void;
    handleChangeAdSourceType: (value: number) => void;
    handleChangeCountry: (value: string) => void;
    isFetchingQueryRanges: boolean;
    queryRangesOptions: LabeledValue[];
    adSourceTypeOptions: LabeledValue[];
    countriesOptions: LabeledValue[];
    isFetchingCountries: boolean;
    handleSearchCountries: (value: string) => void;
}

export const useFloorAnalysisFilters = (type: FloorAnalysisType): UseFloorAnalysisFilters => {
    const isHistorical = type === FloorAnalysisType.HIST;
    const isEstimated = type === FloorAnalysisType.EST;

    const { isDemoContext } = useSeatAuthContext();
    const { search } = useLocation();
    const history = useHistory();
    const loadDateRange: string | null = new URLSearchParams(search).get(FloorAnalysisParams.DateRange);
    const [dateRange, setDateRange] = useState<string>(loadDateRange ? loadDateRange : DEFAULT_DATA_RANGE);

    const loadAdSourceType = new URLSearchParams(search).get(FloorAnalysisParams.AdSourceType);
    const [adSourceType, setAdSourceType] = useState<number>(
        loadAdSourceType ? Number(loadAdSourceType) : DEFAULT_AD_SOURCE_TYPE
    );

    const loadCountry: string | null = new URLSearchParams(search).get(FloorAnalysisParams.Country);
    const [country, setCountry] = useState<string | undefined>(loadCountry ? loadCountry : undefined);
    const [keyword, setKeyword] = useState("");

    const { data: countries = [], isFetching: isFetchingCountries } = useGetGeographiesCountriesQuery({ keyword });
    const { data: queryRanges = [], isLoading: isFetchingQueryRanges } = useGetQueryRangesQuery(undefined, {
        skip: isDemoContext,
    });

    const queryRangesOptions: LabeledValue[] = useMemo(() => {
        return queryRanges.reduce((acc, { name, code, id }) => {
            const inHistoricalList = !(isHistorical && DISABLED_HIST_QUERY_RAGES.includes(Number(id)));
            const inEstimatedList = !(isEstimated && DISABLED_EST_QUERY_RAGES.includes(Number(id)));

            if (inHistoricalList && inEstimatedList) {
                acc.push({ label: name, value: code });
            }
            return acc;
        }, [] as LabeledValue[]);
    }, [queryRanges, isHistorical, isEstimated]);

    const countriesOptions: LabeledValue[] = useMemo(
        () =>
            countries.map((datum) => {
                return {
                    value: datum.geo.countryCode as string,
                    key: String(datum.geo.id),
                    label: datum.geo.countryName,
                };
            }),
        [countries]
    );

    const adSourceTypeOptions: LabeledValue[] = [
        {
            value: 3,
            label: "Invite Only Auction",
        },
        {
            value: 4,
            label: "Open Auction",
        },
    ];
    const handleChangeDateRange = (value: string): void => {
        const newSearchParams: string = uri.setSearchParam(search, FloorAnalysisParams.DateRange, value ? value : "");
        setDateRange(value);
        history.push({ search: newSearchParams });
    };
    const handleChangeAdSourceType = (value: number): void => {
        const newSearchParams: string = uri.setSearchParam(search, FloorAnalysisParams.AdSourceType, String(value));
        setAdSourceType(value);
        history.push({ search: newSearchParams });
    };

    const handleSearch = useCallback((value: string): void => {
        setKeyword(value);
    }, []);

    const debouncedHandleSearch = useMemo(
        () => debounce((value: string) => handleSearch(value), FILTER_INPUT_DEBOUNCE_TIME),
        [handleSearch]
    );

    const handleSearchCountries = (value: string): void => debouncedHandleSearch(value);

    const handleChangeCountry = (value: string): void => {
        const newSearchParams: string = uri.setSearchParam(search, FloorAnalysisParams.Country, value ? value : "");
        setCountry(value);
        history.push({ search: newSearchParams });
    };

    useEffect((): void => {
        const urlSearchParams: URLSearchParams = new URLSearchParams(search);
        const dateRangeUrlSearchParams: string | null = urlSearchParams.get(FloorAnalysisParams.DateRange);
        const adSourceTypeUrlSearchParams: string | null = urlSearchParams.get(FloorAnalysisParams.AdSourceType);
        const countryUrlSearchParams: string | null = urlSearchParams.get(FloorAnalysisParams.Country);

        if (dateRangeUrlSearchParams) {
            setDateRange(dateRangeUrlSearchParams);
        } else {
            setDateRange(DEFAULT_DATA_RANGE);
        }

        if (adSourceTypeUrlSearchParams) {
            setAdSourceType(Number(adSourceTypeUrlSearchParams));
        } else {
            setAdSourceType(DEFAULT_AD_SOURCE_TYPE);
        }

        if (countryUrlSearchParams) {
            setCountry(countryUrlSearchParams);
        } else {
            setCountry(undefined);
        }
    }, [search]);

    return {
        dateRange,
        country,
        adSourceType,
        adSourceTypeOptions,
        handleChangeAdSourceType,
        handleChangeDateRange,
        isFetchingQueryRanges,
        queryRangesOptions,
        handleChangeCountry,
        countriesOptions,
        isFetchingCountries,
        handleSearchCountries,
    };
};
