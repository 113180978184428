import { CurrencyConversionModes } from "@app/core/clients/console";
import { useAppSelector } from "@app/core/store";
import { useCurrency } from "./useCurrency";

export const useDealCurrencyConversion = (sourceCurrencyCode: string | undefined) => {
    const { currencyConversions, getCurrencyById } = useCurrency();
    const { dealPreferredCurrency, dealCurrencyConversionMode } = useAppSelector(
        (state) => state.deals.settings.values
    );
    const preferredCurrency = getCurrencyById(dealPreferredCurrency);
    const targetCurrencyCode =
        dealCurrencyConversionMode === CurrencyConversionModes.ORIGINATING_CURRENCY
            ? sourceCurrencyCode
            : getCurrencyById(dealPreferredCurrency)?.code;

    return {
        currencyConversions,
        targetCurrencyCode,
        currencyConversionMode: dealCurrencyConversionMode,
        preferredCurrency,
    };
};
