import { ChangeEvent, useEffect } from "react";
import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormDealNameFieldChange, dealFormDealNameFieldChangeEditMode } from "@app/features/deals/DealForm/reducer";
import { useDispatchOnBlur } from "@app/features/deals/DealForm/hooks";
import { DealFormMode } from "@app/features/deals/DealForm/types";

interface UseDealNameField {
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
    value: string | number | readonly string[] | undefined;
    handleBlur: () => void;
}

export const useDealNameField = (mode: DealFormMode): UseDealNameField => {
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.NAME]);
    const { onChange, onBlur } = useDispatchOnBlur(value);

    useEffect(() => {
        onChange(value);
        // Should only change on value change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        onChange(value);
    };
    const handleBlur = () => {
        if (mode === "create" || mode === "copy") {
            onBlur(dealFormDealNameFieldChange, CREATE_DEAL_FORM_ITEMS_NAME.NAME);
        } else {
            onBlur(dealFormDealNameFieldChangeEditMode, CREATE_DEAL_FORM_ITEMS_NAME.NAME);
        }
    };

    return {
        handleChange,
        handleBlur,
        value,
    };
};
