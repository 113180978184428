import { FC } from "react";
import { RuleObject, StoreValue } from "rc-field-form/lib/interface";
import { Form, InputNumber } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAdSourceMinDurationField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceDeliveryDetailsSection/Fields/AdSourceMinDurationField/useAdSourceMinDurationField";
import { numberNormalize } from "@app/core/components/helpers";

export const AdSourceMinDurationField: FC = () => {
    const { validator } = useAdSourceMinDurationField();
    return (
        <Form.Item
            label="Min Duration"
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MIN_DURATION}
            dependencies={[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MAX_DURATION]}
            normalize={numberNormalize}
            data-sdet="deals-ad-source-min-duration-field"
            rules={[
                ({ getFieldValue }) => ({
                    validator: (_: RuleObject, value: StoreValue) => {
                        const maxDuration = getFieldValue(CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MAX_DURATION);
                        return validator(maxDuration, value);
                    },
                }),
            ]}
        >
            <InputNumber
                data-sdet="deals-ad-source-min-duration-field-input"
                addonAfter="Seconds"
                style={{ width: "100%" }}
            />
        </Form.Item>
    );
};
