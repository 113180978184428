import { ArrowRightOutlined } from "@ant-design/icons";
import { fixedBottomButtonsStyle } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Button, FormInstance, Space } from "antd";
import { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { BULK_OPERATION_FORM_ITEMS } from "../../constants";
import { BulkOperationType } from "@app/core/services";

interface Props {
    form: FormInstance;
    goToNext: () => void;
}

export const BulkOperationSetupStepBottomButtons: FC<Props> = ({ form, goToNext }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();

    return (
        <div className={fixedBottomButtonsStyle}>
            <Space direction="horizontal">
                <Button
                    data-sdet="step1-next-button"
                    type="primary"
                    onClick={() => {
                        const type = form.getFieldValue(BULK_OPERATION_FORM_ITEMS.OPERATION_TYPE.name);

                        form.validateFields(
                            type === BulkOperationType.Edit
                                ? [
                                      BULK_OPERATION_FORM_ITEMS.ENTITY_TYPE.name,
                                      BULK_OPERATION_FORM_ITEMS.OPERATION_ENTITIES_SELECT_SECTION_ERROR_MESSAGE.name,
                                  ]
                                : [BULK_OPERATION_FORM_ITEMS.SOURCE_ENTITY.name]
                        ).then(() => goToNext());
                    }}
                >
                    Next
                    <ArrowRightOutlined />
                </Button>
                <Button
                    data-sdet="step1-cancel-button"
                    onClick={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BULK_OPERATIONS(seatId))}
                >
                    Cancel
                </Button>
            </Space>
        </div>
    );
};
