import {
    consoleApi,
    NETWORK_BUYER_SEATS,
    NETWORKS_AUCTION_PACKAGE_DEAL_TAG,
    NETWORKS_GLOBAL_TAG,
    NETWORKS_TAG,
    SEAT_AVAILABLE_NETWORKS_TAG,
} from "./console";
import { toQueryString } from "./utils";

export interface Network {
    id: number;
    seat: {
        id: number;
        name: string;
        code: string;
        entityType: "Seat";
    };
    name: string;
    code: string;
    generateFallbackDeviceId: boolean;
    externalDealSyncIntegrations: unknown[];
    preferredCurrency: null;
    allowedCurrencies: unknown[];
    entityType: "Network";
}

export interface GlobalNetwork {
    id: number;
    seat: {
        id: number;
        name: string;
        code: string;
        entityType: string;
    };
    name: string;
    code: string;
    generateFallbackDeviceId: boolean;
    externalDealSyncIntegrations: unknown[];
    preferredCurrency: string;
    allowedCurrencies: unknown[];
    entityType: string;
}

export interface GetGlobalNetworksParams {
    page?: number;
    max?: number;
    keyword?: string;
    adSourceTypeId?: number;
}

export interface NetworkBuyerSeat {
    creationDate: string;
    updateDate: string;
    id: number;
    code: string;
    name: string;
    externalName: null | string;
    blocked: boolean;
}

export interface NetworkBuyerSeatParams {
    id: number | string;
    page?: number;
    max?: number;
    keyword?: string;
    syncedOnly?: boolean;
}

export interface NetworksAuctionPackageDeal {
    code: string;
    creationDate: string;
    entityType: "Network";
    id: number;
    name: string;
    preferredCurrency: {
        code: string;
        id: number;
        creationDate: string;
        updateDate: string;
    };
    status: {
        creationDate: string;
        id: number;
        name: string;
        updateDate: string;
    };
    type: {
        creationDate: string;
        id: number;
        name: string;
        updateDate: string;
    };
    updateDate: string;
}

export const networksApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getGlobalNetworks: builder.query<GlobalNetwork[], GetGlobalNetworksParams>({
            query: (args: GetGlobalNetworksParams) => {
                const params: GetGlobalNetworksParams = {
                    page: 1,
                    max: 50,
                    keyword: "",
                    ...args,
                };
                return `networks/global;${toQueryString(params)}`;
            },
            providesTags: () => [NETWORKS_GLOBAL_TAG],
        }),
        getNetworks: builder.query<Network[], void>({
            query: () => "networks",
            providesTags: () => [NETWORKS_TAG],
        }),
        getNetworkBuyerSeats: builder.query<NetworkBuyerSeat[], NetworkBuyerSeatParams>({
            query: ({ id, ...rest }: NetworkBuyerSeatParams) => {
                const params = {
                    page: 1,
                    max: 200,
                    syncedOnly: true,
                    keyword: "",
                    ...rest,
                };
                return `networks/${id}/buyerDeals/buyerSeats;${toQueryString(params)}`;
            },
            providesTags: (_, __, { id }) => [{ type: NETWORK_BUYER_SEATS, id }],
        }),
        // TODO: Fix return type
        getNetworksAuctionPackageDeal: builder.query<{ id: number; name: string }[], void>({
            query: () => `networks/auctionpackagedealsync`,
            providesTags: [NETWORKS_AUCTION_PACKAGE_DEAL_TAG],
        }),
        getSeatAvailableNetworks: builder.query<Network[], number>({
            query: (id: number) => `seats/${id}/availableNetworks`,
            providesTags: (_, __, id) => [{ type: SEAT_AVAILABLE_NETWORKS_TAG, id }],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetGlobalNetworksQuery,
    useGetNetworkBuyerSeatsQuery,
    useGetNetworksQuery,
    useGetNetworksAuctionPackageDealQuery,
    useGetSeatAvailableNetworksQuery,
    useLazyGetGlobalNetworksQuery,
} = networksApi;
