import { FC } from "react";
import { Space } from "antd";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { gray6, gold6 } from "@rubicon/antd-components";

interface Props {
    isBuyerSelected: boolean;
    isExternalDsp: boolean;
}

export const DspSyncInfoBlock: FC<Props> = ({ isBuyerSelected, isExternalDsp }) =>
    isExternalDsp ? (
        <Space data-sdet="deal-buyer-info-block">
            {isBuyerSelected ? (
                <>
                    <CheckCircleOutlined data-sdet="deal-buyer-success" style={{ color: gray6 }} />
                    Deal will sync
                </>
            ) : (
                <>
                    <ExclamationCircleOutlined data-sdet="deal-buyer-warning" style={{ color: gold6 }} />
                    Deal will not sync, select buyer
                </>
            )}
        </Space>
    ) : null;
