import { FC } from "react";
import { Form, Input } from "antd";

interface Props {
    advertiserBlockDomains: string[];
}

export const BlockedAdvertiserDomainsFormItem: FC<Props> = ({ advertiserBlockDomains }) => {
    return (
        <Form.Item label="Blocked Advertiser Domains">
            <Input.TextArea readOnly rows={10} style={{ resize: "none" }} value={advertiserBlockDomains.join("\r\n")} />
        </Form.Item>
    );
};
