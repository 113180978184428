import { useMemo } from "react";
import { useGetCurrenciesQuery } from "@app/core/services/console";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { selectDashboardPreferredCurrency, setSettingsPreferredCurrency } from "@app/features/dashboard/reducer";
import { LabeledValue } from "antd/lib/select";

interface UsePreferredCurrencyField {
    handleChangeValue: (value: number | undefined, option: LabeledValue | LabeledValue[]) => void;
    isLoading: boolean;
    options: LabeledValue[];
    value: number | null;
}

export const usePreferredCurrencyField = (): UsePreferredCurrencyField => {
    const dispatch = useAppDispatch();
    const preferredCurrencyCodeOrId = useAppSelector(selectDashboardPreferredCurrency);
    const { data, isFetching } = useGetCurrenciesQuery();
    const preferredCurrency =
        typeof preferredCurrencyCodeOrId === "number"
            ? preferredCurrencyCodeOrId
            : (data || []).find((currency) => currency.code === preferredCurrencyCodeOrId)?.id || null;

    const handleChangeValue = (id: number | undefined, option: LabeledValue | LabeledValue[]) => {
        if (!id) {
            dispatch(setSettingsPreferredCurrency(null));
            return;
        }
        if (Array.isArray(option)) {
            dispatch(setSettingsPreferredCurrency(Number(option[0].value)));
            return;
        }
        dispatch(setSettingsPreferredCurrency(Number(option.value)));
    };

    const options = useMemo(
        () =>
            (data || []).map((currency) => ({
                value: currency.id,
                label: currency.code,
            })),
        [data]
    );

    return {
        options,
        isLoading: isFetching,
        value: preferredCurrency,
        handleChangeValue,
    };
};
