import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormRadioGroupChange } from "@app/features/deals/DealForm/reducer";
import { DealAdSourceTransparencey } from "@app/features/deals/DealForm/types";

interface UseAdSourceSupplyNameAppNameField {
    onChange: (value: DealAdSourceTransparencey) => void;
    value: DealAdSourceTransparencey;
}

export const useAdSourceSupplyNameAppNameField = (): UseAdSourceSupplyNameAppNameField => {
    const dispatch = useAppDispatch();
    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_SITE_NAME]
    );
    const onChange = (value: DealAdSourceTransparencey) => {
        dispatch(dealFormRadioGroupChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_SITE_NAME, value }));
    };

    return {
        onChange,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value,
    };
};
