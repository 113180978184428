import { FC } from "react";
import {
    isAdUnit,
    isBrandOrBrandChannel,
    isPublisherOrPublisherChannel,
    isSeat,
    isSupply,
    TableDatum,
} from "./useInventoryHealthInventoryTable";
import { Tooltip } from "antd";
import { StyledReactLink } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { InventoryDetailsDrawerType } from "@app/features/inventory/DetailsDrawer/reducer";

interface Props {
    entity: TableDatum;
}

const getBrandChannelUrl = (seatId: number, publisherId: number, brandId: number, entityType: string) => {
    switch (entityType) {
        case "Brand":
            return ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_UNIT(
                seatId,
                InventoryDetailsDrawerType.BRAND,
                publisherId,
                brandId
            );
        case "Channel":
            return ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_UNIT(
                seatId,
                InventoryDetailsDrawerType.BRAND_CHANNEL,
                publisherId,
                brandId
            );
    }
};

const getPublisherChannelUrl = (seatId: number, id: number, entityType: string) => {
    switch (entityType) {
        case "Channel":
            return ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS(seatId, InventoryDetailsDrawerType.CHANNEL, id);
        case "Publisher":
            return ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS(seatId, InventoryDetailsDrawerType.PUBLISHER, id);
    }
};

export const NameLink: FC<Props> = ({ entity }) => {
    if (isSeat(entity)) {
        return (
            <Tooltip title={`Seat: ${entity?.name}`}>
                <StyledReactLink
                    onClick={(e) => e.stopPropagation()}
                    to={ROUTE_FORMATTERS.SEAT_INVENTORY_SEAT_DETAILS(entity?.id as number)}
                >
                    {entity?.name}
                </StyledReactLink>
            </Tooltip>
        );
    }
    if (isPublisherOrPublisherChannel(entity)) {
        return (
            <Tooltip title={`${entity.entityType}: ${entity.name}`}>
                <StyledReactLink
                    onClick={(e) => e.stopPropagation()}
                    to={getPublisherChannelUrl(entity.seat.id, entity.id, entity.entityType)!}
                >
                    {entity?.name}
                </StyledReactLink>
            </Tooltip>
        );
    }
    if (isBrandOrBrandChannel(entity)) {
        return (
            <Tooltip title={`${entity?.entityType}: ${entity?.name}`}>
                <StyledReactLink
                    onClick={(e) => e.stopPropagation()}
                    to={
                        getBrandChannelUrl(
                            entity.publisher.seat.id,
                            entity.publisher.id,
                            entity?.id,
                            entity?.entityType
                        )!
                    }
                >
                    {entity.name}
                </StyledReactLink>
            </Tooltip>
        );
    }
    if (isSupply(entity)) {
        return (
            <Tooltip title={`${entity?.entityType}: ${entity?.name}`}>
                <StyledReactLink
                    onClick={(e) => e.stopPropagation()}
                    to={
                        ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_UNIT(
                            entity.brand.publisher.seat.id,
                            InventoryDetailsDrawerType.SUPPLY,
                            entity.brand.publisher.id,
                            entity.brand.id,
                            entity.id
                        )!
                    }
                >
                    {entity.name}
                </StyledReactLink>
            </Tooltip>
        );
    }
    if (isAdUnit(entity)) {
        return (
            <Tooltip title={`${entity?.entityType}: ${entity?.name}`}>
                <StyledReactLink
                    onClick={(e) => e.stopPropagation()}
                    to={
                        ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_UNIT(
                            entity.supply.brand.publisher.seat.id,
                            InventoryDetailsDrawerType.AD_UNIT,
                            entity.supply.brand.publisher.id,
                            entity.supply.brand.id,
                            entity.supply.id,
                            entity.id
                        )!
                    }
                >
                    {entity.name}
                </StyledReactLink>
            </Tooltip>
        );
    }
    return null;
};
