import { useCallback, useEffect, useState, useMemo } from "react";

export const useLoadOnScroll = <T extends unknown[]>(
    data: T | undefined,
    isLoading: boolean,
    key: string,
    page: number,
    hasMore: boolean,
    onLoadMore: () => void
) => {
    const [optionsByKeyword, setOptionsByKeyword] = useState(() => {
        const initialOptionsByKeyword = [] as unknown as T;
        initialOptionsByKeyword[page] = data || ([] as unknown as T);

        return {
            [key]: initialOptionsByKeyword,
        };
    });

    const loadMore = useCallback(() => {
        if (hasMore && !isLoading) {
            onLoadMore();
        }
    }, [hasMore, isLoading, onLoadMore]);

    useEffect(() => {
        if (data) {
            setOptionsByKeyword((prev) => {
                const prevKeyword = (prev[key] as T) || ([] as unknown as T);
                prevKeyword[page] = data;

                return {
                    ...prev,
                    [key]: prevKeyword,
                };
            });
        }
        // We only want to update on data change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const options = useMemo(() => {
        const optionsByKeywordVal = optionsByKeyword[key] || ([] as unknown as T);
        return (optionsByKeywordVal as unknown as T).filter(Boolean).flat() as unknown as T;
    }, [optionsByKeyword, key]);

    return {
        hasMore,
        loadMore,
        options,
    };
};
