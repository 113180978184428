import { Form } from "antd";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

const { useWatch } = Form;

interface UseFallBackFloor {
    currencyCode: string;
}

export const useFallBackFloor = (): UseFallBackFloor => {
    const currencyCode = useWatch(INVENTORY_FORM_FIELDS.FLOOR_CURRENCY.name)?.label;
    return { currencyCode };
};
