import { calucateBookingBudget } from "@app/features/seatAdSources/SeatAdSourcesForm/utils";
import { getAddOnContent } from "@app/core/components/Fields/CurrencyInput";
import { useIsFieldVisible } from "./useIsFieldVisible";
import { AdSource } from "@app/core/services";
import { formatNumber } from "@rubicon/utils";

export const useBookingBudgetField = (adSource: AdSource) => {
    const totalImpressions = adSource.bookingVolume;
    const fixedCpm = adSource?.bookingPrice ? adSource?.bookingPrice / 1000 : 0;

    const [currencySymbol] = getAddOnContent(adSource.currencyType.code || "USD");

    const amount = calucateBookingBudget(totalImpressions, fixedCpm);

    const bookingBudgetValue = `${currencySymbol} ${formatNumber.asFixed(amount)}`;

    const isVisible = useIsFieldVisible(adSource);

    return { bookingBudgetValue, isVisible };
};
