import { useMemo } from "react";
import { useGetTimeZonesQuery, getTimeZoneLabel } from "@app/core/services/console";
import { LabeledValue } from "antd/lib/select";
import { useUpdateSettings } from "./useUpdateSettings";
import { useAppSelector } from "@app/core/store";
import { selectUserTimezone } from "@app/core/authClient/reducer";

interface UseTimeZoneField {
    handleChangeValue: (value: string | undefined, option: LabeledValue | LabeledValue[]) => void;
    isLoading: boolean;
    options: { label: string; options: LabeledValue[] }[];
    value: string | null;
}

const ALL_TIMEZONES_PREFIX = "ALL_TIMEZONES-";
const commonTimeZones = [
    { code: "America/Los_Angeles", name: "Pacific Standard Time" },
    { code: "America/Denver", name: "Mountain Standard Time" },
    { code: "America/Chicago", name: "Central Standard Time" },
    { code: "America/New_York", name: "Eastern Standard Time" },
    { code: "UTC", name: "Coordinated Universal Time" },
];
const commonTimeZoneCodeSet = commonTimeZones.reduce((acc, timeZone) => {
    acc.add(timeZone.code);
    return acc;
}, new Set<string>());

export const useTimeZoneField = (): UseTimeZoneField => {
    const timeZone = useAppSelector(selectUserTimezone);
    const { data, isFetching } = useGetTimeZonesQuery();
    const updateSettings = useUpdateSettings();

    const handleChangeValue = (id: string | undefined, option: LabeledValue | LabeledValue[]) => {
        if (!id) {
            return;
        }
        if (Array.isArray(option)) {
            const cleanedValue = String(option[0].value).replace(ALL_TIMEZONES_PREFIX, "");
            updateSettings({ timezone: cleanedValue });
            return;
        }
        const cleanedValue = String(option.value).replace(ALL_TIMEZONES_PREFIX, "");
        updateSettings({ timezone: cleanedValue });
    };

    const options = useMemo(() => {
        return [
            {
                label: "Common Timezones",
                options: (commonTimeZones || []).map((timeZone) => {
                    const mappedTzToApiTz = data?.find((dataTz) => dataTz.code === timeZone.code);
                    return {
                        value: mappedTzToApiTz?.code,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        label: getTimeZoneLabel(mappedTzToApiTz),
                    };
                }),
            },
            {
                label: "All Timezones",
                options: (data || []).map((timeZone) => ({
                    value: `${ALL_TIMEZONES_PREFIX}${timeZone.code}`,
                    label: getTimeZoneLabel(timeZone),
                })),
            },
        ];
    }, [data]);

    return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        options,
        isLoading: isFetching,
        value: commonTimeZoneCodeSet.has(timeZone) ? timeZone : `${ALL_TIMEZONES_PREFIX}${timeZone}`,
        handleChangeValue,
    };
};
