import { useContext, useEffect } from "react";
import { Form } from "antd";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { useGetSeatAdvertiserDomainFilterListDefsQuery } from "@app/core/services";
import { useParams } from "react-router-dom";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { REUSABLE_ADVERTISER_DOMAIN_ID } from "@app/core/components/constants";
import { AdSourceFormsContext } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { getReusableAdvertiserDomainsValidator } from "../../utils";

export const ReusableAdvertiserDomainSelect = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const modeId = Form.useWatch<Partial<{ id: number }>>(AD_SOURCE_FIELDS.MODE.name)?.id;
    const values = Form.useWatch<[{ id: number }] | null>(AD_SOURCE_FIELDS.REUSABLE_ADVERTISER_DOMAINS.name);
    const { forms } = useContext(AdSourceFormsContext);

    const { isLoading, data: options = [] } = useGetSeatAdvertiserDomainFilterListDefsQuery(
        {
            seatId: Number(seatId),
            typeId: REUSABLE_ADVERTISER_DOMAIN_ID[Number(modeId)],
        },
        { skip: !modeId || !seatId }
    );

    useEffect(() => {
        if (!isLoading) {
            let isReset = false;
            for (const value of values || []) {
                if (!options.some(({ id }) => id === value.id)) {
                    isReset = true;
                }
            }
            if (isReset) {
                forms.adSourceForm.setFieldValue(AD_SOURCE_FIELDS.REUSABLE_ADVERTISER_DOMAINS.name, []);
            }
        }
    }, [options, values, forms.adSourceForm, isLoading]);

    return (
        <Form.Item
            {...VERTICAL_LAYOUT_FIX}
            label={AD_SOURCE_FIELDS.REUSABLE_ADVERTISER_DOMAINS.label}
            name={AD_SOURCE_FIELDS.REUSABLE_ADVERTISER_DOMAINS.name}
            dependencies={[AD_SOURCE_FIELDS.MODE.name, AD_SOURCE_FIELDS.ADDITIONAL_ADVERTISER_DOMAINS.name]}
            rules={[
                getReusableAdvertiserDomainsValidator(
                    AD_SOURCE_FIELDS.MODE.name,
                    AD_SOURCE_FIELDS.ADDITIONAL_ADVERTISER_DOMAINS.name
                ),
            ]}
        >
            <SelectWithValue
                mode="multiple"
                placeholder="Select Reusable Advertiser Domains…"
                loading={isLoading}
                options={options}
                fieldAsLabel={["name"]}
                fieldAsValue={["id"]}
            />
        </Form.Item>
    );
};
