import { FC } from "react";
import { Provider } from "react-redux";
import { Analytics } from "@rubicon/component-analytics";
import { HashRouter, ROUTES } from "@app/core/routing";
import { store } from "./store";
import { SeatSideNavProvider } from "@app/core/components/SeatSideNav";
import { BuyerSideNavProvider } from "@app/core/components";
import { useInitializeIFrameEventListeners } from "@app/core/services/iFrameCommsService";
import { Auth0Provider } from "@auth0/auth0-react";
import { GA4_ID } from "@app/core/constants";
import conf from "@app/core/conf";
import { ThemeProvider } from "./theme";
import { AppConfig } from "@app/core/AppConfig";

export const AppProviders: FC = ({ children }) => {
    useInitializeIFrameEventListeners();
    return (
        <AppConfig>
            <Analytics measurementId={GA4_ID} debug={conf.environment !== "production"}>
                <Provider store={store}>
                    <ThemeProvider>
                        <Auth0Provider
                            domain={conf.auth0SsoDomain}
                            clientId={conf.auth0SsoClientId}
                            authorizationParams={{
                                redirect_uri: `${window.location.origin}/#${ROUTES.LOGIN_TOKEN_EXCHANGE}`,
                                audience: conf.auth0SsoAudience,
                            }}
                        >
                            <HashRouter>
                                <BuyerSideNavProvider>
                                    <SeatSideNavProvider>{children}</SeatSideNavProvider>
                                </BuyerSideNavProvider>
                            </HashRouter>
                        </Auth0Provider>
                    </ThemeProvider>
                </Provider>
            </Analytics>
        </AppConfig>
    );
};
