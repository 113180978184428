import { FC } from "react";
import { Form, Select } from "antd";
import { useUserAccess } from "@app/core/auth";
import { getTimeZoneLabel, Seat } from "@app/core/services";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { useTimeZone } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/SeatDetailsFormFields/TimeZone/useTimeZone";

interface TimeZoneProps {
    edit?: boolean;
    seat: Seat;
}

export const TimeZone: FC<TimeZoneProps> = ({ seat, edit = false, ...rest }) => {
    const { isFetching, options } = useTimeZone();
    const { hasInternalAccess } = useUserAccess();
    const { timeZone } = seat;

    return (
        <Form.Item
            name={INVENTORY_FORM_FIELDS.TIME_ZONE.name}
            label={INVENTORY_FORM_FIELDS.TIME_ZONE.label}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 24, xxl: 20 }}
            {...rest}
        >
            {edit && hasInternalAccess ? (
                <Select
                    showSearch
                    allowClear
                    labelInValue
                    options={options}
                    loading={isFetching}
                    optionFilterProp="label"
                    style={{ width: "100%" }}
                    placeholder={INVENTORY_FORM_FIELDS.TIME_ZONE.placeholder}
                />
            ) : (
                getTimeZoneLabel(timeZone)
            )}
        </Form.Item>
    );
};
