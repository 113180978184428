import { useState } from "react";
import { IdNameEntity } from "@app/core/services";
import { Typography, message, notification } from "antd";

const initialState = {
    isOpen: false,
    id: null,
};

interface UseBrandSafetyDeleteModal {
    isOpen: boolean;
    handleOpen: (id: IdNameEntity) => void;
    handleCancel: () => void;
    handleConfirm: () => void;
    name: string;
}

export const useBrandSafetyDeleteModal = (
    deleteTrigger: (id: number) => { unwrap: () => Promise<void> },
    postDeleteEvent?: () => void
): UseBrandSafetyDeleteModal => {
    const [state, setState] = useState<{ isOpen: boolean; id: null | IdNameEntity }>(initialState);

    const handleOpen = (id: IdNameEntity) => setState({ isOpen: true, id });

    const handleCancel = () => setState(initialState);

    const handleConfirm = () => {
        state.id &&
            deleteTrigger(state.id.id)
                .unwrap()
                .then(() => {
                    setState(initialState);
                    message.success(
                        <>
                            <Typography.Text strong>{state.id?.name}</Typography.Text> is deleted successfully!
                        </>
                    );
                    postDeleteEvent?.();
                })
                .catch((error) => {
                    setState({ ...state, isOpen: false });
                    notification.error({ message: error.message || error.data.errorDescription });
                });
    };

    return {
        isOpen: state.isOpen,
        name: state.id?.name || "",
        handleOpen,
        handleCancel,
        handleConfirm,
    };
};
