import { FC } from "react";
import { Tag } from "antd";
import { DealListItem } from "@app/core/services";

type Props = {
    deal: DealListItem;
};
export const DEAL_STATUS_ID = {
    ACTIVE: 1,
    PAUSED: 2,
    UNDER_PERFORMING: 3,
    AUTO_PAUSED: 4,
};

const getDealStatusBadgeColor = (id: number): string | undefined => {
    switch (id) {
        case DEAL_STATUS_ID.ACTIVE:
            return "success";
        case DEAL_STATUS_ID.PAUSED:
            return "default";
        case DEAL_STATUS_ID.UNDER_PERFORMING:
        case DEAL_STATUS_ID.AUTO_PAUSED:
            return "warning";
    }
};

const DealStatusBadge: FC<Props> = ({ deal }) => {
    return <Tag color={getDealStatusBadgeColor(deal?.status?.id)}>{deal?.status?.name}</Tag>;
};

export default DealStatusBadge;
