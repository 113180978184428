import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useHistory, useParams } from "react-router-dom";
import { INVENTORY_SEAT_INTERNAL } from "../../constants";

interface UseInternalContactsPage {
    handleOpen: () => void;
    handleClose: () => void;
}

export const useInternalContactsPage = (): UseInternalContactsPage => {
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();

    const handleOpen = () => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_SEAT_EDIT_TABS(seatId, INVENTORY_SEAT_INTERNAL));
    };

    const handleClose = () => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_SEAT_TABS(seatId, INVENTORY_SEAT_INTERNAL));
    };

    return {
        handleOpen,
        handleClose,
    };
};
