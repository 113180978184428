import { ROUTE_FORMATTERS } from "@app/core/routing";
import {
    AdvertiserDomainFormPayload,
    useCreateAdvertiserDomainMutation,
    useGetAdvertiserDomainTypesQuery,
} from "@app/core/services";
import { Layout, Typography, notification } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { PageHeader } from "@ant-design/pro-layout";
import {
    ADVERTISER_DOMAIN_CREATE_FORM_INITIAL_VALUES,
    AdvertiserDomainForm,
    DEFAULT_DOMAIN_TYPE,
} from "./AdvertiserDomainForm";
import { CONTROLS_ROUTES } from "@app/features/controls/constants";

export const ADVERTISER_DOMAIN_CREATE_PAGE_SDET = "advertiser-domain-create-page";

export const AdvertiserDomainCreatePage = () => {
    const history = useHistory();
    const [createAdvertiserDomain, { isLoading }] = useCreateAdvertiserDomainMutation();
    const { seatId } = useParams<{ seatId: string }>();
    const { data: domainTypes } = useGetAdvertiserDomainTypesQuery();

    const handleSubmit = (values: AdvertiserDomainFormPayload) => {
        const advertiserDomains = values.advertiserDomains.split(/\r?\n/);

        const payload = {
            ...values,
            advertiserDomainListType:
                domainTypes?.find(({ id }) => id === values.advertiserDomainListType.id) || DEFAULT_DOMAIN_TYPE,
            advertiserDomains,
        };

        createAdvertiserDomain({
            body: payload,
        })
            .unwrap()
            .then((res) => {
                notification.success({
                    message: (
                        <>
                            <Typography.Text strong>{res.name}</Typography.Text> has successfully been created
                        </>
                    ),
                });
                history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAIN_DETAILS(seatId, res.id));
            })
            .catch((err) => {
                notification.error({
                    message:
                        err.data?.errorDescription ||
                        err.data?.errorCode ||
                        "Something wrong has occurred, please contact your account manager",
                });
            });
    };

    return (
        <Layout data-sdet={ADVERTISER_DOMAIN_CREATE_PAGE_SDET}>
            <PageHeader
                title="Advertiser Domains New List"
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.BRAND_SAFETY))}
            />
            <AdvertiserDomainForm
                isLoading={isLoading}
                initialValues={ADVERTISER_DOMAIN_CREATE_FORM_INITIAL_VALUES}
                handleSubmit={handleSubmit}
            />
        </Layout>
    );
};
