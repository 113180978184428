import { PlotOptions, PlotData } from "./types";

export const optionsIgnoreData = (options: PlotOptions | undefined): string =>
    JSON.stringify(Object.assign({}, options, { data: null }));

export const getOptionsWithData = (options: PlotOptions, data: PlotData[]): PlotOptions =>
    Object.assign(options, {
        ...options,
        data,
    });
