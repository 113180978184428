import { CHANGELOG_TAG, consoleApi } from "../console";
import { toQueryString } from "../utils";
import { getChangeLogChanges, getChangeLogEntityName, getChangeLogUser } from "./mapper";
import { BaseChangeLog, ChangeLogEntityType, ChangeLogs, ChangesApiParams } from "./types";

export interface ApiChangeLog extends BaseChangeLog {
    changes: string;
    element: {
        accountType: null;
        code: string;
        entityType: ChangeLogEntityType;
        id: number;
        name: string;
        publisherNameOverride: string | null;
        seat?: ChangeLogSeat;
    };
    lineage: {
        type: string;
        name: string;
    };
    realUserId: string | null;
    userId: string;
}

export type ApiChangeLogs = ApiChangeLog[];

export interface ChangeLogSeat {
    id: number;
    name: string;
    code: string;
    entityType: string;
}

export const changeLogsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getChangeLogs: builder.query<ChangeLogs, ChangesApiParams>({
            query: (query) => {
                const { id, entityType, ...rest } = query;
                const params: Omit<ChangesApiParams, "id" | "entityType"> = {
                    ...rest,
                };
                Object.keys(params).forEach((paramKey) => {
                    if (params[paramKey] === "") {
                        params[paramKey] = undefined;
                    }
                });
                return `/${entityType}/${id}/changes;${toQueryString(params)}`;
            },
            providesTags: (_, err, { id, entityType }) =>
                err ? [] : [{ type: CHANGELOG_TAG, id: `${entityType}-${id}` }],
            transformResponse: (response: ApiChangeLogs): ChangeLogs => {
                const changeLogs: ChangeLogs = response.map((apiChangeLog) => {
                    const { action, id, changeDate, elementType } = apiChangeLog;
                    return {
                        action,
                        id,
                        changeDate,
                        elementType,
                        changes: getChangeLogChanges(apiChangeLog),
                        entityName: getChangeLogEntityName(apiChangeLog),
                        user: getChangeLogUser(apiChangeLog),
                    };
                });
                return changeLogs;
            },
        }),
    }),
    overrideExisting: false,
});

export const { useGetChangeLogsQuery } = changeLogsApi;
