import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { ClassificationsIndustriesTab } from "./ClassificationsIndustriesTab";
import { useUserAccess } from "@app/core/auth";
import { ClassificationsIndustryCreatePage } from "./ClassificationsIndustryCreatePage";
import { ClassificationsIndustryDetailsPage } from "./ClassificationsIndustryDetailsPage";
import { ClassificationsIndustryEditPage } from "./ClassificationsIndustryEditPage";

export const ClassificationsIndustriesSwitch: FC = () => {
    const { canEditSeat } = useUserAccess();

    return (
        <Switch>
            <Route exact path={ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_DETAILS_PAGE}>
                <ClassificationsIndustryDetailsPage />
            </Route>
            {canEditSeat && (
                <Route exact path={ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_CREATE_PAGE}>
                    <ClassificationsIndustryCreatePage />
                </Route>
            )}
            {canEditSeat && (
                <Route exact path={ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_EDIT_PAGE}>
                    <ClassificationsIndustryEditPage />
                </Route>
            )}
            <Route exact path={ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRIES}>
                <ClassificationsIndustriesTab />
            </Route>
        </Switch>
    );
};
