import { Card } from "antd";
import { FC } from "react";
import { INVENTORY_CARD_BODY_STYLE } from "@app/features/inventory/constants";
import { CreativeBlockMode } from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormSections/BrandSafetySection/Fields/BrandSafetyInternalOnly/Fields";

export const BrandSafetyInternalOnly: FC = () => {
    return (
        <Card title="Internal Only" type="inner" bodyStyle={INVENTORY_CARD_BODY_STYLE}>
            <CreativeBlockMode />
        </Card>
    );
};
