import { MouseEvent } from "react";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { Form, Tag } from "antd";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { FilterFilled } from "@ant-design/icons";
import { AddLabelRow } from "./AddLabelRow";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";

const tagRender = (props: CustomTagProps) => {
    const { value, closable, onClose, label } = props;
    const onPreventMouseDown = (event: MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };
    const currentValue = (value || []).find((item) => item.id === label);
    const labelNode = currentValue ? `${currentValue.label.key} : ${currentValue.value}` : "";
    return (
        <Tag
            color="blue"
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3 }}
        >
            {labelNode}
        </Tag>
    );
};

export const LabelMultiselect = () => {
    const labels = Form.useWatch<string[]>(AD_SOURCE_FIELDS.LABEL.name);
    const isHidden = !labels?.length;
    return (
        <Form.Item
            label={AD_SOURCE_FIELDS.LABEL.label}
            {...VERTICAL_LAYOUT_FIX}
            extra={
                <>
                    <FilterFilled /> : Waterfall Pre-Filter labels
                </>
            }
        >
            <AddLabelRow />
            <Form.Item name={AD_SOURCE_FIELDS.LABEL.name} style={{ marginBottom: 8 }} hidden={isHidden}>
                <SelectWithValue
                    allowClear
                    tagRender={tagRender}
                    mode="multiple"
                    showSearch={false}
                    open={false}
                    fieldAsLabel={["id"]}
                    fieldAsValue={["id"]}
                />
            </Form.Item>
        </Form.Item>
    );
};
