import { CSSProperties, FC } from "react";
import { Select } from "antd";
import { usePublisherFilter } from "./usePublisherFilter";
import { SelectAllDropdownRender } from "@app/core/components";

interface Props {
    style?: CSSProperties;
}

export const PublisherFilter: FC<Props> = ({ style }) => {
    const { options, value, isLoading, handleChangeValue, areAllOptionsSelected, toggleSelectDeselectAll } =
        usePublisherFilter();

    return (
        <Select
            data-sdet="publisher-filter"
            allowClear={Boolean(value)}
            loading={isLoading}
            onChange={handleChangeValue}
            optionFilterProp="label"
            placeholder="All"
            options={options}
            showSearch
            style={style}
            value={value}
            mode="multiple"
            dropdownRender={(menu) => (
                <SelectAllDropdownRender
                    menu={menu}
                    onClick={toggleSelectDeselectAll}
                    areAllOptionsSelected={areAllOptionsSelected}
                />
            )}
        />
    );
};
