import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@app/core/store";

export interface SeatTagParamForm {
    name: string;
    macro: string;
    tagParamId: number;
    id: number;
    selected: boolean;
    isDirty: boolean;
}

export interface KeyedSeatTag {
    [key: number]: SeatTagParamForm;
}
export interface SeatTagParams {
    macro: KeyedSeatTag | undefined;
    initial: KeyedSeatTag | undefined;
}

export const initialState: SeatTagParams = {
    macro: undefined,
    initial: undefined,
};

const seatTagParamsSlice = createSlice({
    name: "consoleTagParams",
    initialState,
    reducers: {
        setTagParamsFormInitial: (state, action: PayloadAction<KeyedSeatTag | undefined>) => {
            if (!state.initial) {
                state.initial = action.payload;
            }
            state.macro = action.payload;
        },
        setTagParamsForm: (state, action: PayloadAction<KeyedSeatTag | undefined>) => {
            state.macro = action.payload;
        },
        resetForm: (state) => {
            state.macro = state.initial;
        },
    },
});

export const selectMacroFields = (state: RootState) => state.consoleTagParams.macro;
export const { setTagParamsFormInitial, setTagParamsForm, resetForm } = seatTagParamsSlice.actions;

export const consoleTagParamsReducer = seatTagParamsSlice.reducer;
