import { OS_TAG, consoleApi } from "./console";
import { OperatingSystem } from "@app/core/services";

export const operatingSystemsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getOperatingSystems: builder.query<OperatingSystem[], void>({
            query: () => "/os",
            providesTags: [OS_TAG],
        }),
    }),
    overrideExisting: false,
});

export const { useGetOperatingSystemsQuery } = operatingSystemsApi;
