import { useGetCurrenciesQuery } from "@app/core/services";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";

export const useTransactionCurrencySelect = () => {
    const { marketplaceInfoMode, dealObjectMode } = useMarketplaceInfoWidget();
    const isReadonly = marketplaceInfoMode.name === "view";
    const isDisabled = dealObjectMode === "edit";
    const { data: currencies = [], isFetching } = useGetCurrenciesQuery(undefined, { skip: isReadonly || isDisabled });
    return {
        isLoading: isFetching,
        currencies,
        isReadonly,
        isDisabled,
    };
};
