import { FreqCappingTypes } from "@app/features/inventory/components/FormItems";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const AdvertiserFrequencyCappingType = () => {
    return (
        <FreqCappingTypes
            label={AD_UNIT_FORM_FIELDS.ADVERTISER_FREQUENCY_CAPPING_TYPE.label}
            name={AD_UNIT_FORM_FIELDS.ADVERTISER_FREQUENCY_CAPPING_TYPE.name}
        />
    );
};
