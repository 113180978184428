import debounce from "lodash.debounce";
import { ValidateStatus } from "antd/lib/form/FormItem";
import { useGetAppStoreDataQuery } from "@app/core/services";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { OTHER_APP_STORE_ID, SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useSupplyFormInstance } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormInstance";

interface UseBundleID {
    extra: string;
    disabled: boolean;
    isFetching: boolean;
    help: string | undefined;
    validateStatus: ValidateStatus | undefined;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const useBundleID = (): UseBundleID => {
    const { useWatch, form } = useSupplyFormInstance();
    const [bundleId, setBundleId] = useState<string>("");
    const [validateStatus, setValidateStatus] = useState<ValidateStatus | undefined>(undefined);
    const [help, setHelp] = useState<string | undefined>(undefined);
    const appStore = useWatch(SUPPLY_FORM_FIELDS.APP_STORE.name);
    const appStoreId: number = appStore?.value;
    const disabled: boolean = !appStoreId && appStoreId !== OTHER_APP_STORE_ID;

    const extra = "When 'override' is checked, any values passed on the tag will be ignored. Use only when required.";

    const { data, isFetching, error, isLoading } = useGetAppStoreDataQuery(
        { appStoreId, bundleId },
        { skip: !appStoreId || !bundleId }
    );

    const handleSearch = useCallback((value: string): void => {
        setValidateStatus(undefined);
        setHelp(undefined);
        setBundleId(value);
    }, []);

    const debouncedHandleSearch = useMemo(
        () => debounce((value: string) => handleSearch(value), FILTER_INPUT_DEBOUNCE_TIME),
        [handleSearch]
    );
    const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
        debouncedHandleSearch(event.target.value);
    };
    useEffect((): void => {
        if (isFetching || isLoading) {
            setValidateStatus("validating");
            setHelp("Searching...");
            return;
        }
        if (error) {
            setValidateStatus("warning");
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setHelp(error?.data?.errorDescription);
            form.setFieldValue(SUPPLY_FORM_FIELDS.APP_NAME.name, "");
            form.setFieldValue(SUPPLY_FORM_FIELDS.STORE_URL.name, "");
            return;
        }
        if (data?.bundleId === "notFound") {
            setValidateStatus("warning");
            setHelp("App not found, is your Id correct?");
            form.setFieldValue(SUPPLY_FORM_FIELDS.APP_NAME.name, "");
            form.setFieldValue(SUPPLY_FORM_FIELDS.STORE_URL.name, "");
            return;
        }
        if (data?.bundleId) {
            setValidateStatus("success");
            setHelp("App found! Results below.");
            form.setFieldValue(SUPPLY_FORM_FIELDS.APP_NAME.name, data?.name);
            form.setFieldValue(SUPPLY_FORM_FIELDS.STORE_URL.name, data?.storeUrl);
            form.validateFields([SUPPLY_FORM_FIELDS.APP_NAME.name, SUPPLY_FORM_FIELDS.STORE_URL.name]);
            return;
        }
    }, [data, form, error, isFetching, isLoading]);

    return { extra, onChange, disabled, validateStatus, help, isFetching };
};
