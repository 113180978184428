import { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { BrandSafetyMenu } from "./BrandSafetyMenu";
import { BrandSafetyPage } from "./BrandSafetyPage";
import { BuyerSeatListsDetailsPage } from "./BuyerSeatLists";
import { AdvertiserDomainCreatePage } from "./AdvertiserDomains/AdvertiserDomainForm/AdvertiserDomainCreatePage";
import { useUserAccess } from "@app/core/auth";
import { AdvertiserDomainEditPage } from "./AdvertiserDomains/AdvertiserDomainForm/AdvertiserDomainEditPage";
import { BuyerSeatListsCreatePage } from "./BuyerSeatLists/BuyerSeatListsCreatePage";
import { BuyerSeatListsEditPage } from "./BuyerSeatLists/BuyerSeatListsEditPage";
import { CompetitiveSeparationGroupEditPage } from "./CompetitiveSeparationGroups/CompetitiveSeparationGroupEditPage";
import { CompetitiveSeparationGroupCreatePage } from "./CompetitiveSeparationGroups/CompetitiveSeparationGroupCreatePage";
import { CompetitiveSeparationGroupDetailsPage } from "./CompetitiveSeparationGroups/CompetitiveSeparationGroupDetailsPage";
import { AdvancedBrandSafetyDetailsPage } from "./AdvancedBrandSafety/AdvancedBrandSafetyDetailsPage";
import { ContentTransparencyRulesCreatePage } from "./ContentTransparencyRules/ContentTransparencyRulesForm/ContentTransparencyRulesCreatePage";
import { ContentTransparencyRulesEditPage } from "./ContentTransparencyRules/ContentTransparencyRulesForm/ContentTransparencyRulesEditPage";
import {
    InventoryAdvancedBrandSafetyPageForm,
    InventoryAdvancedBrandSafetyPageFormMode,
    InventoryAdvancedBrandSafetyPageFormSource,
} from "@app/features/inventory/InventoryBrandSafety/InventoryAdvancedBrandSafetyPageForm";
import { AdvertiserDomainDetailsPage } from "./AdvertiserDomains/AdvertiserDomainDetailsPage";

export const BrandSafetySwitch: FC = () => {
    const { canEditSeat } = useUserAccess();

    return (
        <>
            <BrandSafetyMenu />
            <Switch>
                <Redirect
                    exact
                    from={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_NO_TAB}
                    to={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAINS}
                />

                <Route
                    exact
                    path={[
                        ROUTES.SEAT_CONTROLS_BRAND_SAFETY,
                        ROUTES.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_DETAILS_DRAWER,
                    ]}
                >
                    <BrandSafetyPage />
                </Route>
                <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAIN_DETAILS}>
                    <AdvertiserDomainDetailsPage />
                </Route>
                {canEditSeat && (
                    <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAIN_CREATE}>
                        <AdvertiserDomainCreatePage />
                    </Route>
                )}
                {canEditSeat && (
                    <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAIN_EDIT}>
                        <AdvertiserDomainEditPage />
                    </Route>
                )}
                {canEditSeat && (
                    <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_TRANSPARENCY_RULES_CREATE}>
                        <ContentTransparencyRulesCreatePage />
                    </Route>
                )}
                {canEditSeat && (
                    <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_TRANSPARENCY_RULES_EDIT}>
                        <ContentTransparencyRulesEditPage />
                    </Route>
                )}
                {canEditSeat && (
                    <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_CREATE_PAGE}>
                        <CompetitiveSeparationGroupCreatePage />
                    </Route>
                )}
                {canEditSeat && (
                    <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_EDIT_PAGE}>
                        <CompetitiveSeparationGroupEditPage />
                    </Route>
                )}
                <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_DETAILS_PAGE}>
                    <CompetitiveSeparationGroupDetailsPage />
                </Route>

                {canEditSeat && (
                    <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_EDIT_PAGE}>
                        <InventoryAdvancedBrandSafetyPageForm
                            mode={InventoryAdvancedBrandSafetyPageFormMode.EDIT}
                            source={InventoryAdvancedBrandSafetyPageFormSource.CONTROLS}
                        />
                    </Route>
                )}
                {canEditSeat && (
                    <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_CREATE_PAGE}>
                        <InventoryAdvancedBrandSafetyPageForm
                            mode={InventoryAdvancedBrandSafetyPageFormMode.CREATE}
                            source={InventoryAdvancedBrandSafetyPageFormSource.CONTROLS}
                        />
                    </Route>
                )}
                <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_DETAILS_PAGE}>
                    <AdvancedBrandSafetyDetailsPage />
                </Route>

                <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_DETAILS_PAGE}>
                    <BuyerSeatListsDetailsPage />
                </Route>
                {canEditSeat && (
                    <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_EDIT_PAGE}>
                        <BuyerSeatListsEditPage />
                    </Route>
                )}
                {canEditSeat && (
                    <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_CREATE_PAGE}>
                        <BuyerSeatListsCreatePage />
                    </Route>
                )}
            </Switch>
        </>
    );
};
