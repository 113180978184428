export enum EventTypes {
    None = "",
    AdServingStat = "adServingStat",
    AdServingStatHistory = "adServingStatHistory",
    AdStat = "adStat",
    AdStatHistory = "adStatHistory",
    AuthResult = "authResult",
    Heartbeat = "heartbeat",
    Log = "log",
    Login = "login",
    Notification = "notification",
    StartHistoryStats = "startHistoryStats",
    StartStats = "startStats",
    StopStats = "stopStats",
}

export interface RawEventData {
    type: EventTypes;
    message?: string;
}

export interface RawEventDataWithMessage extends RawEventData {
    message: string;
}

export interface OutgoingEvent {
    type: EventTypes;
    message?: string;
}

export interface LoginEvent {
    type: EventTypes.Login;
    message: string;
}

export interface NotificationEvent {
    type: EventTypes.Notification;
    message: string;
}

export interface HeartbeatEvent {
    type: EventTypes.Heartbeat;
}
