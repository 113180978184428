import { FC } from "react";
import { Col, Form, Row, Tag } from "antd";
import { BrandSupply } from "@app/core/services";
import { NONE } from "@app/core/components/constants";
import { SUPPLY_EXCHANGE_SAFETY_LABELS } from "../../../constants";

const { Item } = Form;

interface InternalSectionProps {
    supply: BrandSupply;
}

export const ExchangeSafetySection: FC<InternalSectionProps> = ({ supply }) => {
    return (
        <Row gutter={16}>
            <Col span={24}>
                <Item label={SUPPLY_EXCHANGE_SAFETY_LABELS.supplyDomainLists}>
                    {supply.supplySupplyDomainFilterLists?.length
                        ? supply.supplySupplyDomainFilterLists.map(({ name, id }) => <Tag key={id}>{name}</Tag>)
                        : NONE}
                </Item>
            </Col>
            <Col span={24}>
                <Item label={SUPPLY_EXCHANGE_SAFETY_LABELS.appBundleIdLists}>
                    {supply.supplyBundleIdFilterLists?.length
                        ? supply.supplyBundleIdFilterLists.map(({ name, id }) => <Tag key={id}>{name}</Tag>)
                        : NONE}
                </Item>
            </Col>
        </Row>
    );
};
