import { createContext, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { Badge, Card, Form, FormInstance, Layout, Space } from "antd";
import Sider from "antd/lib/layout/Sider";
import { Content } from "antd/lib/layout/layout";
import { ROUTES } from "@app/core/routing";
import { BrandSafetyBrandPage } from "../BrandSafetyBrandPage";
import { BrandSafetyPublisherPage } from "../BrandSafetyPublisherPage";
import { SeatTree } from "../../SeatTree/SeatTree";
import { BrandSafetySeatPage } from "../BrandSafetySeatPage";
import { BrandSafetySupplyPage } from "../BrandSafetySupplyPage";
import { BrandSafetyAdUnitPage } from "../BrandSafetyAdUnitPage";
import { InventorySearch } from "../../InventorySearch";
import { gold6 } from "@rubicon/antd-components";
import { css } from "@emotion/css";
import { SeatDetailsFormValues } from "@app/features/inventory/InventoryBrandSafety/BrandSafetyForm/useSeatBrandSafetyEditForm";
import { PublisherDetailsFormValues } from "@app/features/inventory/InventoryBrandSafety/BrandSafetyForm/usePublisherBrandSafetyEditForm";
import { BrandDetailsFormValues } from "@app/features/inventory/InventoryBrandSafety/BrandSafetyForm/useBrandBrandSafetyEditForm";
import { SupplyDetailsFormValues } from "@app/features/inventory/InventoryBrandSafety/BrandSafetyForm/useSupplyBrandSafetyEditForm";
import { AdUnitDetailsFormValues } from "@app/features/inventory/InventoryBrandSafety/BrandSafetyForm/useAdUnitBrandSafetyEditForm";

interface SeatTreeNavigationContextType {
    seatForm: FormInstance<SeatDetailsFormValues>;
    brandForm: FormInstance<BrandDetailsFormValues>;
    publisherForm: FormInstance<PublisherDetailsFormValues>;
    supplyForm: FormInstance<SupplyDetailsFormValues>;
    adUnitForm: FormInstance<AdUnitDetailsFormValues>;
    expandedKeys: string[];
    selectedKeys: string[];
    expandKeys: (keys: string[]) => void;
    resetFormsFields: () => void;
    setSelectedKeys: (keys: string[]) => void;
    foldKey: (key: string) => void;
}

const contentClasses = css`
    .ant-typography {
        margin-top: 0;
    }
`;

export const SeatTreeNavigationContext = createContext<SeatTreeNavigationContextType | null>(null);

export const HierarchicalPage = () => {
    const [seatForm] = Form.useForm();
    const [publisherForm] = Form.useForm();
    const [brandForm] = Form.useForm();
    const [supplyForm] = Form.useForm();
    const [adUnitForm] = Form.useForm();

    const resetFormsFields = (): void => {
        seatForm.resetFields();
        publisherForm.resetFields();
        brandForm.resetFields();
        supplyForm.resetFields();
        adUnitForm.resetFields();
    };

    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    const expandKeys = (keys: string[]) => {
        setExpandedKeys((prev) => {
            return [...prev, ...keys];
        });
    };

    const foldKey = (key: string) => {
        setExpandedKeys((prev) => {
            return [...prev].filter((v) => v !== key);
        });
    };

    return (
        <SeatTreeNavigationContext.Provider
            value={{
                seatForm,
                brandForm,
                supplyForm,
                adUnitForm,
                expandedKeys,
                selectedKeys,
                publisherForm,
                foldKey,
                expandKeys,
                setSelectedKeys,
                resetFormsFields,
            }}
        >
            <Layout style={{ flexDirection: "row" }}>
                <Sider width="300">
                    <Card style={{ height: "100%" }}>
                        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                            <InventorySearch />
                            <Badge color={gold6} text="Brand safety set at given level" />
                            <SeatTree />
                        </Space>
                    </Card>
                </Sider>
                <Content className={contentClasses}>
                    <Switch>
                        <Route exact path={ROUTES.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL}>
                            <BrandSafetySeatPage />
                        </Route>
                        <Route exact path={ROUTES.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_PUBLISHER}>
                            <BrandSafetyPublisherPage />
                        </Route>
                        <Route exact path={ROUTES.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_BRAND}>
                            <BrandSafetyBrandPage />
                        </Route>
                        <Route exact path={ROUTES.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_SUPPLY}>
                            <BrandSafetySupplyPage />
                        </Route>
                        <Route exact path={ROUTES.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_AD_UNIT}>
                            <BrandSafetyAdUnitPage />
                        </Route>
                    </Switch>
                </Content>
            </Layout>
        </SeatTreeNavigationContext.Provider>
    );
};
