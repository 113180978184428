import { Form } from "antd";
import { useSalesContactSelect } from "./useSalesContactSelect";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { useIsFieldVisible } from "./useSalesContactIsVisible";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { User } from "@app/core/services/console";

export const SalesContactSelect = () => {
    const isVisible = useIsFieldVisible();
    const { contacts, isLoading, onSearch, searchValue, hasMore, loadMore } = useSalesContactSelect();

    return isVisible ? (
        <Form.Item
            {...VERTICAL_LAYOUT_FIX}
            label={AD_SOURCE_FIELDS.SALES_CONTACT.label}
            name={AD_SOURCE_FIELDS.SALES_CONTACT.name}
        >
            <SelectWithValue<User>
                data-sdet="adsource-sales-contact"
                fieldAsLabel={(user) => `${user.name} [${user.emailAddress}]`}
                fieldAsValue={["id"]}
                filterOption={false}
                hasMore={hasMore}
                loading={isLoading}
                loadMore={loadMore}
                onSearch={onSearch}
                options={contacts}
                placeholder="Select…"
                searchValue={searchValue}
                showSearch
            />
        </Form.Item>
    ) : null;
};
