import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { SeatAdSourcesStatus, useGetSeatAdSourcesStatusesQuery } from "@app/core/services";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import { AdSourceStatusIds } from "../constants";
import { AdSourceFormMode } from "../SeatAdSourcesForm";

const { EXPIRED, PAUSED, RUNNING } = AdSourceStatusIds;

interface UseFilteredAdSourceStatuses {
    statuses: SeatAdSourcesStatus[];
    statusOptions: LabeledValue[];
    isFetching: boolean;
}

const FormModeStatusMap = {
    create: [RUNNING, PAUSED] as const,
    edit: [RUNNING, PAUSED, EXPIRED] as const,
    copy: [RUNNING, PAUSED] as const,
} as const;

export const useFilteredAdSourceStatuses = (formMode: AdSourceFormMode | DealFormMode): UseFilteredAdSourceStatuses => {
    const { data, isFetching } = useGetSeatAdSourcesStatusesQuery();

    const statuses = useMemo(
        () =>
            (data && formMode ? data : []).filter((status) =>
                FormModeStatusMap[formMode].some((allowedStatusId) => allowedStatusId === status.id)
            ),
        [data, formMode]
    );

    const statusOptions = useMemo(
        () =>
            statuses.map((status) => ({
                label: status.name,
                value: status.id,
            })),
        [statuses]
    );

    return {
        statuses,
        statusOptions,
        isFetching,
    };
};
