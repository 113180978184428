import { Button, Space, notification, Flex } from "antd";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { FC } from "react";
import { matchPath, useHistory } from "react-router-dom";
import { DealListSource } from "@app/core/services";
import { ROUTES, ROUTE_FORMATTERS } from "@app/core/routing";
import { useUserAccess } from "@app/core/auth";
import { HelpTooltip } from "@app/core/components";
import { DealDetailsDrawerTitles, DealsHelpKeys } from "@app/features/deals/constants";
import { useDealDetailsDrawer } from "@app/features/deals/DealDetailsDrawer/useDealDetailsDrawer";
import { useDealDetailsDrawerContent } from "@app/features/deals/DealDetailsDrawer/useDealDetailsDrawerContent";

interface Props {
    seatId: number | string;
    dealId: number;
    dealEntityType: string;
    isDealShared: boolean;
    adSources?: DealListSource[];
    isNested?: boolean;
}

export const DealToolbar: FC<Props> = ({ seatId, isDealShared, dealId, dealEntityType, isNested = false }) => {
    const { openDealDetailsDrawer, isExpandAll, handleChangeCollapse, closeDealDetailsDrawerWithoutHistory } =
        useDealDetailsDrawer();
    const { switchToNestedDetailsView } = useDealDetailsDrawerContent();
    const { canEditSeat } = useUserAccess();
    const history = useHistory();
    const isLegacyDeal = dealEntityType === "Deal";
    const isReadonly = matchPath<{ seatId: string }>(history.location.pathname, {
        path: ROUTES.SEAT_DEAL_EDIT,
    })?.params?.seatId;

    const handleCopy = () => {
        if (isLegacyDeal) {
            notification.open({ message: "Demo Feature - Production will Copy Deal" });
            return;
        }
        closeDealDetailsDrawerWithoutHistory();
        history.push(ROUTE_FORMATTERS.SEAT_DEAL_COPY(seatId, dealId));
    };

    const handleEdit = () => {
        closeDealDetailsDrawerWithoutHistory();
        history.push(ROUTE_FORMATTERS.SEAT_DEAL_EDIT(seatId, dealId));
    };

    const handleOpenLadleDrawer = () => {
        if (isNested) {
            switchToNestedDetailsView(DealDetailsDrawerTitles.Ladle);
        } else {
            openDealDetailsDrawer(dealId, DealDetailsDrawerTitles.Ladle);
        }
    };

    const handleOpenChangeHistiry = () => {
        if (isNested) {
            switchToNestedDetailsView(DealDetailsDrawerTitles.ChangeHistory);
        } else {
            openDealDetailsDrawer(dealId, DealDetailsDrawerTitles.ChangeHistory);
        }
    };

    return (
        <Flex justify="space-between" flex={1}>
            <Space size="small">
                {canEditSeat && !isReadonly && (
                    <>
                        {!isDealShared && (
                            <Button onClick={handleEdit} type="primary">
                                Edit
                            </Button>
                        )}
                        {!isLegacyDeal && <Button onClick={handleCopy}>Copy</Button>}
                    </>
                )}
                <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealLadle} popover={true}>
                    <Button onClick={handleOpenLadleDrawer} icon={<CloudDownloadOutlined />}>
                        {DealDetailsDrawerTitles.Ladle}
                    </Button>
                </HelpTooltip>
                <Button type="link" onClick={handleChangeCollapse}>
                    {isExpandAll ? "Expand All" : "Collapse All"}
                </Button>
            </Space>
            <Space size="small">
                <Button onClick={handleOpenChangeHistiry}>Change History</Button>
            </Space>
        </Flex>
    );
};
