import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormStartEndDateFieldChange } from "@app/features/deals/DealForm/reducer";
import { ISO_8601_FORMAT } from "@app/core/components/constants";
import { useSyncedFieldsProducer } from "@app/features/syncedFields/useSyncedFieldsProducer";
import moment from "moment-timezone";

interface UseDealStartDateField {
    handleChange: (date: moment.Moment) => void;
    value: moment.Moment;
}

export const useDealStartDateField = (): UseDealStartDateField => {
    const dispatch = useAppDispatch();
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.START_DATE]);
    const { updateSyncedFields } = useSyncedFieldsProducer();

    const handleChange = (startDate: moment.Moment): void => {
        updateSyncedFields({ startDate });
        const value = startDate.format(ISO_8601_FORMAT);
        dispatch(dealFormStartEndDateFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.START_DATE, value }));
    };

    return {
        handleChange,
        value: moment(value),
    };
};
