import { FC, useEffect } from "react";
import { Col, Row, Spin } from "antd";
import { Targeting, useGetSeatReusableTargetingByIdQuery } from "@app/core/services";
import { TargetingBlockCollapse } from "../TargetingBlockCollapse";
import { TargetingBlockHeader } from "../TargetingBlockHeader";
import { ReadOnlyIncludeTargetingCollapse } from "../ReadOnlyTargeting/ReadOnlyTargetingCollapseInclude";
import { ReadOnlyExcludeTargetingCollapse } from "../ReadOnlyTargeting/ReadOnlyTargetingCollapseExclude";
import { TargetingStub } from "../types";
import { CreateTargetingBlockActions } from "./CreateTargetingBlockActions";

interface Props {
    index: number;
    targetingBlock: Targeting | TargetingStub;
    replaceTargetingBlock: (index: number, targetingBlock: Targeting) => void;
    onClickCopy: () => void;
    onClickDelete: () => void;
}

export const ExistingTargetingBlock: FC<Props> = ({
    index,
    targetingBlock,
    replaceTargetingBlock,
    onClickCopy,
    onClickDelete,
}) => {
    const { data, isFetching } = useGetSeatReusableTargetingByIdQuery(targetingBlock.id);
    useEffect(() => {
        // Replace targeting block with data if it's currently a stub targeting block
        if (data && targetingBlock.isStub) {
            replaceTargetingBlock(index, data);
        }
    }, [data, targetingBlock, index, replaceTargetingBlock]);
    return (
        <TargetingBlockCollapse
            header={<TargetingBlockHeader index={index} name={targetingBlock.name} />}
            extra={<CreateTargetingBlockActions onClickCopy={onClickCopy} onClickDelete={onClickDelete} />}
        >
            {isFetching ? (
                <Row justify="center">
                    <Col>
                        <Spin />
                    </Col>
                </Row>
            ) : (
                <>
                    <ReadOnlyIncludeTargetingCollapse targetingBlock={targetingBlock} />
                    <ReadOnlyExcludeTargetingCollapse targetingBlock={targetingBlock} />
                </>
            )}
        </TargetingBlockCollapse>
    );
};
