import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useGetTimeZonesQuery, getTimeZoneLabel, TimeZone } from "@app/core/services";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import { IS_GOOGLE_DEAL } from "@app/features/deals/DealForm/useDealForm";
import { useSyncedFieldsProducer } from "@app/features/syncedFields/useSyncedFieldsProducer";

interface UseDealTimeZoneField {
    isFetching: boolean;
    options: LabeledValue[];
    optionsByValue: { [id: number]: TimeZone };
    handleSelect: (value: number) => void;
    value: number;
    disabled: boolean;
}

export const useDealTimeZoneField = (mode: DealFormMode): UseDealTimeZoneField => {
    const dispatch = useAppDispatch();
    const { updateSyncedFields } = useSyncedFieldsProducer();
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TIME_ZONE]);
    const dsp = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.DSP]);
    const { data, isFetching } = useGetTimeZonesQuery();
    const options: LabeledValue[] = useMemo(
        () =>
            (data || []).map((timeZone) => ({
                label: getTimeZoneLabel(timeZone),
                value: timeZone.id,
            })),
        [data]
    );
    const optionsByValue = useMemo(
        () =>
            (data || []).reduce((byValue, datum) => {
                byValue[datum.id] = datum;
                return byValue;
            }, {}),
        [data]
    );

    const handleSelect = (value: number): void => {
        const timeZone = optionsByValue[value] || null;
        updateSyncedFields({ timeZone });
        dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.TIME_ZONE, value }));
    };
    const disabled = mode === "edit" && dsp === IS_GOOGLE_DEAL;

    return {
        isFetching,
        handleSelect,
        disabled,
        options,
        optionsByValue,
        value,
    };
};
