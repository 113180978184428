import { MarketplaceInfo } from "@app/core/services";
import { ReadonlyControl } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { css } from "@emotion/css";
import { gray10, red5 } from "@rubicon/antd-components";
import { Button, Col, Form, Input, Row, Typography } from "antd";
import { FC } from "react";
import { useDealIdInput } from "./useDealIdInput";

export const DealIdInput: FC = () => {
    const { handleGenerateId, isReadonly, isRequired } = useDealIdInput();
    return (
        <Col sm={12}>
            <Form.Item
                required={false}
                rules={[{ required: isRequired, message: "Deal ID is required" }]}
                name={["dealUnderEdit", "code"]}
                className={css(`
                label {
                    width: 100%;
                }
            `)}
                data-sdet="deal-id-field"
                label={
                    isReadonly ? (
                        "Deal ID"
                    ) : (
                        <Row justify="space-between" align="middle" style={{ width: "100%" }}>
                            <Col>
                                <Typography.Text style={{ marginRight: "0.25rem", color: gray10 }}>
                                    Deal ID
                                </Typography.Text>
                                <span style={{ color: red5, marginLeft: "0.125rem" }}>*</span>
                            </Col>
                            <Col>
                                <Button onClick={handleGenerateId} type="link" size="small">
                                    Auto Generate ID
                                </Button>
                            </Col>
                        </Row>
                    )
                }
            >
                {isReadonly ? (
                    <ReadonlyControl<MarketplaceInfo["deal"]["code"]> getLabel={(value) => value} />
                ) : (
                    <Input placeholder="Deal ID" />
                )}
            </Form.Item>
        </Col>
    );
};
