import { FC } from "react";
import { Form, Radio } from "antd";
import { INVENTORY_YES_NO_OPTIONS, CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useAdvertisersAsWhitelist } from "./useAdvertisersAsWhitelist";

export const AdvertisersAsWhitelist: FC = () => {
    const hidden = useAdvertisersAsWhitelist();
    return (
        <Form.Item
            hidden={hidden}
            name={CHANNEL_FORM_FIELDS.ADV_AS_WHITELIST.name}
            label={CHANNEL_FORM_FIELDS.ADV_AS_WHITELIST.label}
        >
            <Radio.Group options={INVENTORY_YES_NO_OPTIONS} />
        </Form.Item>
    );
};
