import { Loading } from "@app/core/components";
import { Line } from "@ant-design/plots";
import { Col, Row, Typography } from "antd";
import { FC } from "react";
import { UniquesStatsFilter } from "./UniquesStatsFilter";
import { useUniquesStatsFilter } from "./UniquesStatsFilter/useUniquesStatsFilter";
import { useUniquesStats } from "./useUniquesStats";

const { Title } = Typography;

interface Props {
    id: number | string;
}

export const UniquesStats: FC<Props> = ({ id }) => {
    const {
        isLoadingPlatformFamily,
        handleChangePlatformFamily,
        handleChangeCountry,
        platformsFamilyOptions,
        isFetchingCountries,
        countriesOptions,
        handleSearchCountries,
        country,
        platformFamily,
    } = useUniquesStatsFilter();
    const { dailyConfig, monthlyConfig, lastDailyAverage, last30DayAverage, isLoading, isFetching } = useUniquesStats(
        Number(id),
        platformFamily,
        country
    );

    if (isLoading) {
        return <Loading position="relative" />;
    }

    return (
        <div data-sdet="uniques-stats">
            <Row>
                <Col span={24}>
                    <UniquesStatsFilter
                        platformFamily={platformFamily}
                        country={country}
                        isLoadingPlatformFamily={isLoadingPlatformFamily}
                        isFetchingCountries={isFetchingCountries}
                        handleChangePlatformFamily={handleChangePlatformFamily}
                        handleChangeCountry={handleChangeCountry}
                        handleSearchCountries={handleSearchCountries}
                        platformsFamilyOptions={platformsFamilyOptions}
                        countriesOptions={countriesOptions}
                    />
                </Col>
            </Row>
            {isFetching ? (
                <Loading position="relative" />
            ) : (
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Title level={5} style={{ textAlign: "center" }}>
                                    Avg. Daily Uniques: {lastDailyAverage}
                                </Title>
                            </Col>
                            <Col span={24}>
                                <Line {...dailyConfig} />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={24}>
                                <Title level={5} style={{ textAlign: "center" }}>
                                    Avg. 30 Day Uniques: {last30DayAverage}
                                </Title>
                            </Col>
                            <Col span={24}>
                                <Line {...monthlyConfig} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </div>
    );
};
