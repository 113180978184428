import { ReactNode, useMemo } from "react";
import { NavGroupItem } from "@app/core/components";

export interface AccountNavGroupItemProps {
    icon: ReactNode;
    label: string;
    indicator?: number | string | null;
    isActive?: boolean;
    onClick?: () => void;
    backgroundColor?: string;
    backgroundHighlightColor?: string;
}

const MAX_WIDTH = 81;
const DEFAULT_FONT_SIZE_REM = 0.75;
const MIN_FONT_SIZE_REM = 0.62;
const FONT_DECREMENT = 0.01;

// TODO: Remove this once account label is displayed in the upper right corner
const getWidestWord = (label: string, fontSize = DEFAULT_FONT_SIZE_REM) =>
    label
        .split(" ")
        .map((word) => {
            const text = document.createElement("span");
            document.body.appendChild(text);

            text.style.fontFamily = "DM Sans";
            text.style.fontSize = `${fontSize}rem`;
            text.style.lineHeight = "1.3333333333";
            text.style.height = "auto";
            text.style.width = "auto";
            text.style.position = "absolute";
            text.style.whiteSpace = "no-wrap";
            text.innerHTML = word;

            const width = text.clientWidth;

            document.body.removeChild(text);

            return width;
        })
        .reduce((widest, cur) => Math.max(widest, cur));

const getWouldOverflow = (label, fontSize = DEFAULT_FONT_SIZE_REM) => getWidestWord(label, fontSize) > MAX_WIDTH;

const getFontSize = (label, fontSize = DEFAULT_FONT_SIZE_REM) => {
    const wouldOverflow = getWouldOverflow(label, fontSize);

    // If we're going to overflow at the minimum fontSize anyways,
    // we might as well make it more readable
    if (wouldOverflow && fontSize <= MIN_FONT_SIZE_REM) {
        return DEFAULT_FONT_SIZE_REM;
    }

    if (fontSize <= MIN_FONT_SIZE_REM) {
        return MIN_FONT_SIZE_REM;
    }

    if (wouldOverflow) {
        return getFontSize(label, fontSize - FONT_DECREMENT);
    }

    return fontSize;
};

export const AccountNavGroupItem = ({ label, ...props }: AccountNavGroupItemProps) => {
    const fontSize = useMemo(() => getFontSize(label), [label]);
    return <NavGroupItem {...props} label={label} labelFontSize={fontSize} />;
};
