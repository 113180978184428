import { INVENTORY_YES_NO_OPTIONS, CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { Form, Radio } from "antd";

export const NoAdCookiesSync = () => {
    return (
        <Form.Item name={CHANNEL_FORM_FIELDS.NO_AD_SYNC.name} label={CHANNEL_FORM_FIELDS.NO_AD_SYNC.label}>
            <Radio.Group options={INVENTORY_YES_NO_OPTIONS} />
        </Form.Item>
    );
};
