import { Col } from "antd";
import { DualAxes, Column } from "@ant-design/plots";
import { useAdSourceCustomPacingCharts } from "./useCustomPacingCharts";

export const CustomPacingCharts = () => {
    const { chartConfig, histogramChartConfig, isChartsVisible } = useAdSourceCustomPacingCharts();

    if (!isChartsVisible) {
        return null;
    }
    return (
        <>
            <Col span={10}>
                <DualAxes {...chartConfig} />
            </Col>
            <Col span={10}>
                <Column {...histogramChartConfig} />
            </Col>
        </>
    );
};
