import { FC } from "react";
import { Form, Input } from "antd";
import { CREATE_BRAND_SAFETY_FORM_ITEMS_NAME } from "@app/features/inventory/InventoryBrandSafety/InventoryAdvancedBrandSafetyPage/constants";

interface BrandSafetyNameFieldProps {
    isSubmitting: boolean;
}

export const BrandSafetyNameField: FC<BrandSafetyNameFieldProps> = ({ isSubmitting }) => {
    return (
        <Form.Item
            rules={[{ required: true, message: "Name is required", whitespace: true }]}
            label="Name"
            name={CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.NAME}
        >
            <Input disabled={isSubmitting} allowClear />
        </Form.Item>
    );
};
