import { Loading } from "@app/core/components";
import { Button, Col, Row, Typography } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { FC } from "react";
import { useVastStats } from "./useVastStats";
import { CardPieChart } from "./CardPieChart";

interface Props {
    id: number;
}
export const VastStats: FC<Props> = ({ id }) => {
    const { generatedPieCounts, isLoading, isFetching, handleUpdate } = useVastStats(id);

    if (isLoading) {
        return <Loading position="relative" />;
    }

    return (
        <Row gutter={[26, 32]}>
            <Col span={24}>
                <Typography.Text>
                    Showing last 30 minute data &nbsp;
                    <Button
                        disabled={isFetching}
                        onClick={handleUpdate}
                        style={{ width: 54 }}
                        icon={<SyncOutlined spin={isFetching} />}
                    ></Button>
                </Typography.Text>
            </Col>

            {/* <CardPieChart title={"Test"} data={[]} /> */}
            {isFetching ? (
                <Loading position="relative" />
            ) : (
                Object.keys(generatedPieCounts || {}).map((name) => {
                    const item = generatedPieCounts[name];
                    return (
                        <Col key={name} xs={24} sm={12}>
                            <CardPieChart title={item.name} data={item.data} />
                        </Col>
                    );
                })
            )}
        </Row>
    );
};
