import { FC } from "react";
import { Form, Select } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import { useAdSourceStatusField } from "./useAdSourceStatusField";

interface Props {
    formMode: DealFormMode;
}

export const AdSourceStatusField: FC<Props> = ({ formMode }) => {
    const { isFetching, options, value, onChange } = useAdSourceStatusField(formMode);

    return (
        <Form.Item
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_STATUS}
            data-sdet="ad-source-status-field"
            label="Status"
        >
            <Select
                allowClear={false}
                filterOption={false}
                loading={isFetching}
                onChange={onChange}
                optionFilterProp="label"
                options={options}
                placeholder="Select Status..."
                showSearch
                value={value}
            />
        </Form.Item>
    );
};
