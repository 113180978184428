import { FC } from "react";
import { Form, Input } from "antd";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const PartnerDomain: FC = () => {
    return (
        <Form.Item name={CHANNEL_FORM_FIELDS.PARTNER_DOMAIN.name} label={CHANNEL_FORM_FIELDS.PARTNER_DOMAIN.label}>
            <Input allowClear placeholder={CHANNEL_FORM_FIELDS.PARTNER_DOMAIN.placeholder} />
        </Form.Item>
    );
};
