export enum ChangeLogEntityType {
    Seat = "seats",
    AdSource = "adSources",
    AdUnit = "adUnits",
    Brand = "brands",
    Channel = "channels",
    Deal = "deals",
    Publisher = "publishers",
    Supply = "supply",
    Advertiser = "advertisers",
    Industry = "industries",
    Targeting = "targeting",
}

export interface ChangesApiParams {
    id: number;
    entityType: ChangeLogEntityType;
    actionType?: string;
    email?: string;
}

export interface BaseChangeLog {
    action: string;
    id: number;
    changeDate: string;
    elementType: string;
}

export interface ChangeLog extends BaseChangeLog {
    changes: ChangeLogFields;
    entityName: string;
    user: string;
}

export type ChangeLogs = ChangeLog[];

export interface ChangeLogField {
    attribute: string;
    previous: string;
    current: string;
}

export type ChangeLogFields = ChangeLogField[];
