import { FC } from "react";
import { Form, Input } from "antd";
import { CHANNEL_FORM_FIELDS, MAX_DESCRIPTION_LENGTH } from "@app/features/inventory/HierarchyForms/constants";

const { TextArea } = Input;

export const Description: FC = () => {
    return (
        <Form.Item
            name={CHANNEL_FORM_FIELDS.DESCRIPTION.name}
            label={CHANNEL_FORM_FIELDS.DESCRIPTION.label}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 22, xxl: 22 }}
            rules={[{ max: MAX_DESCRIPTION_LENGTH, message: CHANNEL_FORM_FIELDS.DESCRIPTION.rulesMessage.maxLength }]}
        >
            <TextArea rows={4} allowClear placeholder={CHANNEL_FORM_FIELDS.DESCRIPTION.placeholder} />
        </Form.Item>
    );
};
