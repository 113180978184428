import { FC } from "react";
import { Input, InputProps } from "antd";
import { useLimitInput } from "@app/features/inventory/components/Fields/LimitInput/useLimitInput";

interface LimitInputProps extends Omit<InputProps, "onChange" | "value"> {
    toFixed?: number;
    value?: string | number | undefined;
    onChange?: (value: string | number) => void;
}

export const LimitInput: FC<LimitInputProps> = ({ value, toFixed, onChange, ...rest }) => {
    const { onKeyPress, displayValue, handleBlur, handleChange } = useLimitInput({
        value,
        onChange,
        toFixed,
    });
    return <Input {...rest} onBlur={handleBlur} onKeyPress={onKeyPress} onChange={handleChange} value={displayValue} />;
};
