import { FC } from "react";
import { Col, Row, Typography } from "antd";
import { format } from "@rubicon/utils";
import { SegmentRuleIdentifier } from "@app/features/targeting/types";
import { getSegmentRuleIdentifierFieldLabel } from "@app/features/targeting/CreateTargeting/DimensionRows/SegmentRuleDimensionRow/helpers";
import { SegmentRuleIdentifierOperators, SegmentRuleIdentifierOperatorsLabels } from "../../constants";

interface Props {
    rule: SegmentRuleIdentifier;
}

export const SegmentRuleIdentifierRow: FC<Props> = ({ rule }) => {
    return (
        <Row gutter={[8, 8]}>
            <Col>
                <Typography.Text>
                    {getSegmentRuleIdentifierFieldLabel(rule.field)}{" "}
                    {rule.operator === SegmentRuleIdentifierOperators.IsMember
                        ? SegmentRuleIdentifierOperatorsLabels.IsMember.toLowerCase()
                        : SegmentRuleIdentifierOperatorsLabels.IsNotMember.toLowerCase()}{" "}
                    <Typography.Text strong>{format.asSelf(rule.value?.label)}</Typography.Text>
                </Typography.Text>
            </Col>
        </Row>
    );
};
