import { InfoCircleOutlined } from "@ant-design/icons";
import { Collapse, Drawer, Typography } from "antd";
import { FC, useState } from "react";
import DOMPurify from "dompurify";
import { configurationCollapseClassList } from "@app/features/deals/DealForm/DefinitionSidePanels/DealObjectSidePanel/DealObjectSidePanel";
import { AdSourceHelpKeys, AD_SOURCE_CONFIGURATION_HELP_FIELDS } from "@app/features/deals/constants";
import { useHelpArticles } from "@app/core/components/hooks";
import { DrawerCloseButton, SectionTitle, StyledButtonLink } from "@app/core/components";

export const AdSourceConfigurationSidePanel: FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { helpArticles } = useHelpArticles(AdSourceHelpKeys);
    return (
        <>
            <StyledButtonLink onClick={() => setIsOpen(true)} type="link" data-sdet="adsource-configuration">
                <InfoCircleOutlined />
                Ad Source Configuration
            </StyledButtonLink>
            <Drawer
                styles={{ body: { padding: 0 } }}
                width="40%"
                title="Ad Source Configuration Definitions"
                open={isOpen}
                onClose={() => setIsOpen(false)}
                closable={false}
                extra={<DrawerCloseButton onClose={() => setIsOpen(false)} />}
            >
                <Collapse defaultActiveKey={["1", "2"]} className={configurationCollapseClassList}>
                    <Collapse.Panel key="1" header={<SectionTitle title="Ad Source Configuration Options" />}>
                        <Typography.Paragraph>
                            You have the option to attach this deal to an existing Ad Source (s), copy an existing Ad
                            Source or fully define a new ad source.
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            Ad Sources are containers for one or more deals. They allow you to define settings (such as
                            transparency, brand safety, floor pricing etc) that apply to all deals contained within,
                            making it easier for you to manage your waterfall and group multiple deals that share the
                            same requirements, business rules and targeting
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            You must attach an ad source in order for the deal to go live.
                        </Typography.Paragraph>
                    </Collapse.Panel>
                    <Collapse.Panel key="2" header={<SectionTitle title="General" />}>
                        {Object.values(AD_SOURCE_CONFIGURATION_HELP_FIELDS).map((field) => (
                            <div key={field.label}>
                                <Typography.Text strong>{field.label}</Typography.Text>
                                <Typography.Paragraph>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(helpArticles[field.helpPath]?.body) || "N/A",
                                        }}
                                    />
                                </Typography.Paragraph>
                            </div>
                        ))}
                    </Collapse.Panel>
                </Collapse>
            </Drawer>
        </>
    );
};
