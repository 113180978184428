import { adSources } from "./adSources";
import { adUnitsApi } from "./adUnits";
import { buyerDealsApi } from "./buyerDeals";
import {
    AD_SOURCE_TAG,
    AD_UNIT,
    BULK_OPERATION_EXECUTIONS_TAG,
    DEAL_TAG,
    SEAT_AD_SOURCES_TAG,
    SEAT_AD_UNITS_TAG,
    SEAT_CTV_NEXT_BUYER_DEALS_TAG,
    SEAT_CTV_NEXT_AD_UNITS_TAG,
    SEAT_CTV_NEXT_DEMAND_DEALS_TAG,
    consoleApi,
} from "./console";
import { dealsApi } from "./deals";
import { demandDealsApi } from "./demandDeals";
import { UserPreferenceMap, UserRole } from "./users";

export interface EntityBase {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
}
export enum BulkOperationType {
    Edit = "edit",
    Copy = "copy",
}

// for "Edit" operation
export enum BulkOperationEntityType {
    AdSource = "adSource",
    AdUnit = "adUnit",
    BuyerDeal = "buyerDeal",
    DemandDeal = "demandDeal",
}

// for "Copy" operation
export enum BulkOperationSourceEntity {
    Publisher = "publisher",
    Brand = "brand",
    Supply = "supply",
    AdUnit = "adUnit",
}

export interface BuyerDealUpdate {
    id: number;
    externalName: string;
    externalParentName: string;
}
// TODO: add more details
export interface AdSourceUpdate {
    id: number;
    name: string;
    allowAutoscale?: boolean;
    labelValues: EntityBase &
        {
            value: string;
            label: {
                creationDate: string;
                updateDate: string;
                id: number;
                namespace: string;
                key: string;
                multiValue: boolean;
                enablePrefilter: true;
            };
        }[];
    allowContent: boolean;
    contentTransparencyRule?: EntityBase;
}
export interface AdUnitUpdate {
    id: number;
    mimes: EntityBase[];
}

export interface BulkOperationDefinition {
    type: BulkOperationType;
    // the following fields are for "Edit" type
    entityType?: BulkOperationEntityType;
    adSourceUpdates?: AdSourceUpdate[];
    adUnitUpdates?: AdUnitUpdate[];
    buyerDealUpdates?: BuyerDealUpdate[];
    // the following fields are for "Copy" type
    recursiveCopy?: boolean;
    sourceEntity?: BulkOperationSourceEntity;
    sourceEntityId?: number;
    destinationEntity?: string;
    destinationEntityId?: number;
    destinationEntityName?: string;
}

export interface BulkOperationExecution {
    creationDate: string;
    updateDate: string;
    id: number;
    status: EntityBase & {
        sort: number;
        deleted: boolean;
        entityType: string;
    };
    code: string;
    executionHost: string;
    restartCount: number;
    startedDate: string;
    error: null | string;
    definition: BulkOperationDefinition;
    initiatingUser: EntityBase & {
        emailAddress: string;
        password: string;
        firstName: string;
        lastName: string;
        countryCode: string;
        timezoneCode: string;
        currencyCode: string;
        localeCode: string;
        company: string;
        resetCode: string;
        resetCodeDate: string;
        userSeat: EntityBase & {
            code: string;
            status: number;
            type: number;
            entityType: string;
        };
        userRoles: {
            id: 1090;
            roleType: UserRole; // part of UserRole. but this is not being used, so skip the rest
            dateCreated: string;
            lastUpdated: string;
        }[];
        userPreferences: [];
        numFailedAttempts: null | number;
        lockoutMinutes: null | number;
        lockDate: null | string;
        entityType: "User";
        timeToWaitBeforeLoggingInMs: number;
        timeToWaitBeforeLoggingInMins: number;
        userPreferenceMap: UserPreferenceMap;
    };
    entityType: string;
}
interface CopyScopePayload {
    sourceEntity: BulkOperationSourceEntity;
    sourceEntityId: number;
    type: "copy";
}

export enum ExecutionStatus {
    Started = 1,
    Running = 2,
    Completed = 3,
    Error = 4,
    CompletedWithErrors = 5,
}
export interface BulkOperationExecutionStatus {
    code: string;
    error: string | null;
    startTime: string;
    status: ExecutionStatus;
}

export const bulkOperationApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getBulkOperationExecutions: builder.query<BulkOperationExecution[], void>({
            query: () => {
                return "bulkoperation/executions";
            },
            providesTags: [BULK_OPERATION_EXECUTIONS_TAG],
        }),
        getBulkOperationCopyScope: builder.mutation<number, CopyScopePayload>({
            query: (payload: CopyScopePayload) => {
                return {
                    url: "bulkoperation/copyScope",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        createBulkOperation: builder.mutation<BulkOperationExecutionStatus, BulkOperationDefinition>({
            query: (payload) => {
                return {
                    url: "bulkoperation",
                    method: "POST",
                    body: payload,
                };
            },
            invalidatesTags: (result, err) =>
                err
                    ? []
                    : result?.status === ExecutionStatus.Completed ||
                      result?.status === ExecutionStatus.CompletedWithErrors
                    ? [
                          BULK_OPERATION_EXECUTIONS_TAG,
                          SEAT_AD_SOURCES_TAG,
                          AD_SOURCE_TAG,
                          SEAT_AD_UNITS_TAG,
                          SEAT_CTV_NEXT_AD_UNITS_TAG,
                          AD_UNIT,
                          SEAT_CTV_NEXT_BUYER_DEALS_TAG,
                          SEAT_CTV_NEXT_DEMAND_DEALS_TAG,
                          DEAL_TAG,
                      ]
                    : [],
        }),
        getBulkOperationExecutionStatus: builder.query<BulkOperationExecutionStatus, string>({
            query: (code: string) => {
                return `bulkoperation/${code}`;
            },
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(({ data }) => {
                    if (
                        data.status === ExecutionStatus.Completed ||
                        data.status === ExecutionStatus.CompletedWithErrors
                    ) {
                        dispatch(bulkOperationApi.util.invalidateTags([BULK_OPERATION_EXECUTIONS_TAG]));
                        dispatch(adSources.util.invalidateTags([SEAT_AD_SOURCES_TAG, AD_SOURCE_TAG]));
                        dispatch(
                            adUnitsApi.util.invalidateTags([SEAT_AD_UNITS_TAG, SEAT_CTV_NEXT_AD_UNITS_TAG, AD_UNIT])
                        );
                        dispatch(buyerDealsApi.util.invalidateTags([SEAT_CTV_NEXT_BUYER_DEALS_TAG]));
                        dispatch(demandDealsApi.util.invalidateTags([SEAT_CTV_NEXT_DEMAND_DEALS_TAG]));
                        dispatch(dealsApi.util.invalidateTags([DEAL_TAG]));
                    }
                });
            },
        }),
    }),
});

export const {
    useGetBulkOperationExecutionsQuery,
    useGetBulkOperationCopyScopeMutation,
    useCreateBulkOperationMutation,
    useLazyGetBulkOperationExecutionStatusQuery,
} = bulkOperationApi;
