import { Tooltip } from "antd";
import { FC } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { brandCobalt } from "@rubicon/antd-components";

export const IsCapableOfSyncTooltip: FC = () => (
    <Tooltip title="This DSP is capable of sync.">
        <ExclamationCircleOutlined style={{ color: brandCobalt }} />
    </Tooltip>
);
