import { DrawerBreadcrumbHeader, GoToLink, Loading } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { BuyerSeatList } from "@app/core/services";
import { ResizableDrawer } from "@rubicon/antd-components";
import { HelpKeysButton, HelpKeysDrawerContent } from "@app/core/components/HelpKeysDrawer";
import { Flex, Typography } from "antd";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { BuyerSeatListsActions } from "../BuyerSeatListsActions";
import { HELP_KEYS } from "../constants";
import { BuyerSeatListsReadOnlyForm } from "../BuyerSeatListsForm";
import { useBuyerSeatListsDetailsDrawer } from "./useBuyerSeatListsDetailsDrawer";
import { drawerRightClosePosition } from "@app/features/controls/constants";

interface DrawerTitleProps {
    seatId: string;
    buyerSeatListId: number;
    name: string;
    detailsPageUrl: string;
    handleDefinition: () => void;
    postDeleteEvent: () => void;
}
const DrawerTitle: FC<DrawerTitleProps> = ({
    name,
    detailsPageUrl,
    handleDefinition,
    seatId,
    buyerSeatListId,
    postDeleteEvent,
}) => {
    return (
        <Flex vertical gap="small" style={{ marginRight: "1rem" }}>
            <Flex flex="1 1 0" justify="space-between">
                <Flex gap="small" align="center">
                    <Typography.Title
                        level={5}
                        style={{
                            margin: 0,
                            maxWidth: "15rem",
                        }}
                        ellipsis={{ tooltip: { title: name, placement: "bottom" } }}
                    >
                        {name}
                    </Typography.Title>
                    <GoToLink
                        targetPath={detailsPageUrl}
                        goToText="Go To Buyer Seat List"
                        tooltipProps={{ title: "Copy Buyer Seat List Link" }}
                    />
                </Flex>
                <HelpKeysButton onClick={handleDefinition} buttonName="Buyer Seat Lists" />
            </Flex>
            <BuyerSeatListsActions
                seatId={seatId}
                buyerSeatListId={buyerSeatListId}
                name={name}
                postDeleteEvent={postDeleteEvent}
            />
        </Flex>
    );
};

export const BuyerSeatListsDetailsDrawer = () => {
    const { seatId, buyerSeatListId: id } = useParams<{ seatId: string; buyerSeatListId: string }>();
    const {
        open,
        data,
        showDefinitionContent,
        handleBack,
        isLoading,
        handleClose,
        handleDefinition,
        handleAfterOpenChange,
        handlePostDeleteEvent,
    } = useBuyerSeatListsDetailsDrawer(seatId, id);

    return (
        <ResizableDrawer
            open={open}
            placement="right"
            title={
                data ? (
                    showDefinitionContent ? (
                        <DrawerBreadcrumbHeader
                            onBack={handleBack}
                            parentLabel={data.name}
                            childLabel="Buyer Seat Lists"
                        />
                    ) : (
                        <DrawerTitle
                            name={data.name}
                            seatId={seatId}
                            buyerSeatListId={Number(id)}
                            detailsPageUrl={ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_DETAILS_PAGE(
                                seatId,
                                id
                            )}
                            handleDefinition={handleDefinition}
                            postDeleteEvent={handlePostDeleteEvent}
                        />
                    )
                ) : undefined
            }
            width="45%"
            onClose={handleClose}
            afterOpenChange={handleAfterOpenChange}
            className={drawerRightClosePosition}
        >
            {isLoading && <Loading />}
            {showDefinitionContent && <HelpKeysDrawerContent definitionsFields={HELP_KEYS} />}
            {data && !showDefinitionContent && <BuyerSeatListsReadOnlyForm buyerSeat={data as BuyerSeatList} />}
        </ResizableDrawer>
    );
};
