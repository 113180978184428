import {
    Column,
    ColumnOptions,
    DualAxes,
    DualAxesOptions,
    Funnel,
    FunnelOptions,
    Line,
    LineOptions,
} from "@antv/g2plot";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PlotData = Record<string, any>;

export type Plots = Column | DualAxes | Funnel | Line;

export enum PlotTypes {
    Column = "column",
    DualAxes = "dualAxes",
    Funnel = "funnel",
    Line = "line",
}

export type PlotOptions = ColumnOptions | DualAxesOptions | FunnelOptions | LineOptions;

export interface PlotProps {
    id: string;
    plotConfig: ColumnPlotConfig | DualAxesPlotConfig | FunnelPlotConfig | LinePlotConfig;
}

export interface ColumnPlotConfig {
    type: PlotTypes.Column;
    options: ColumnOptions;
    data: PlotData[];
}

export interface DualAxesPlotConfig {
    type: PlotTypes.DualAxes;
    options: DualAxesOptions;
    data: PlotData[];
}

export interface FunnelPlotConfig {
    type: PlotTypes.Funnel;
    options: FunnelOptions;
    data: PlotData[];
}

export interface LinePlotConfig {
    type: PlotTypes.Line;
    options: LineOptions;
    data: PlotData[];
}
