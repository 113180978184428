import { LabeledValue } from "antd/lib/select";
import { useGetAdResponsePriceOverridesQuery } from "@app/core/services";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { useUserAccess } from "@app/core/auth";

interface UseAdSourceAdResponsePriceOverrideField {
    isFetching: boolean;
    isVisible: boolean;
    options: LabeledValue[];
    value: number | null;
    handleChange: (value: number) => void;
}

export const useAdSourceAdResponsePriceOverrideField = (): UseAdSourceAdResponsePriceOverrideField => {
    const dispatch = useAppDispatch();
    const { showAdResponsePriceOverride } = useUserAccess();
    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_AD_RESPONSE_PRICE_OVERRIDE]
    );
    const { data, isFetching } = useGetAdResponsePriceOverridesQuery();

    const options = (data || []).map((priceOverride) => ({
        label: priceOverride.name,
        value: priceOverride.id,
    }));

    const handleChange = (priceOverrideId: number) => {
        dispatch(
            dealFormSelectFieldChange({
                field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_AD_RESPONSE_PRICE_OVERRIDE,
                value: priceOverrideId,
            })
        );
    };

    return {
        options,
        isFetching,
        isVisible: showAdResponsePriceOverride,
        value,
        handleChange,
    };
};
