import { FC } from "react";
import { DealList } from "@app/core/services";
import { DealCreateFormDemand } from "../DealForm/DealFormDemand";
import { DealFormLegacy } from "../DealForm/DealFormLegacy";

interface Props {
    dealAdSource: DealList | null;
}

export const DealEditFormPageContent: FC<Props> = ({ dealAdSource }) => {
    if (!dealAdSource) {
        return null;
    }
    if (dealAdSource?.deal?.entityType === "Deal") {
        return <DealFormLegacy dealAdSource={dealAdSource} />;
    }

    return <DealCreateFormDemand mode="edit" dealAdSource={dealAdSource} />;
};
