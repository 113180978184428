import { Typography } from "antd";
import { FC } from "react";

const YES_LABEL_COLOR = "#2F9999";
const NO_LABEL_COLOR = "#595959";

interface Props {
    isActualPrice: boolean;
}

export const ActualPriceLabel: FC<Props> = ({ isActualPrice }) => {
    return (
        <Typography.Text style={{ color: isActualPrice ? YES_LABEL_COLOR : NO_LABEL_COLOR }}>
            {isActualPrice ? "Yes" : "No"}
        </Typography.Text>
    );
};
