import { css } from "@emotion/css";
import { Typography } from "antd";
import { gray7 } from "@rubicon/antd-components";

export const InfoMessage = () => {
    return (
        <Typography.Paragraph
            data-sdet="info-message"
            className={css`
                &.ant-typography {
                    margin-bottom: 1.5rem;
                }
                color: ${gray7};
            `}
        >
            The following defaults values will be use unless they are passed in the ad request
        </Typography.Paragraph>
    );
};
