import { FC } from "react";
import { Layout } from "antd";
import SeatContactsHeader from "./SeatContactsHeader";
import SeatContactsToolbar from "./SeatContactsToolbar";
import { SeatContactsTable } from "./SeatContactsTable";
import { SeatContactDrawer } from "./SeatContactDrawer/SeatContactDrawer";

export const SeatContactsPage: FC = () => (
    <Layout>
        <SeatContactsHeader />
        <Layout.Content>
            <SeatContactsToolbar />
            <SeatContactsTable />
            <SeatContactDrawer />
        </Layout.Content>
    </Layout>
);

export default SeatContactsPage;
