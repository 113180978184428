import { FC } from "react";
import merge from "lodash.merge";
import { FunnelPlotConfig, Plot, PlotTypes } from "../Plot";
import { DefaultOptions, RequiredOptions } from "./constants";
import { FunnelChartProps } from "./types";

export const FunnelChart: FC<FunnelChartProps> = ({ id, chartOptions, data = [] }) => {
    const funnelPlotConfig: FunnelPlotConfig = {
        type: PlotTypes.Funnel,
        options: merge({}, DefaultOptions, chartOptions, RequiredOptions),
        // workaround to display half of a comparison funnel chart for styling purposes
        data: data
            .map((d) => [
                { ...d, yValue: 0, compareValue: "hidden" as const },
                { ...d, compareValue: "shown" as const },
            ])
            .flat(1),
    };

    return (
        <div data-sdet="funnel-chart">
            <Plot id={id} plotConfig={funnelPlotConfig} />
        </div>
    );
};
