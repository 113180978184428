import { SeatAdSourcesType } from "@app/core/services";
import { AdSourceTypes, AD_SOURCE_FIELDS, AD_SOURCE_TYPES_NEXT_UI_NAMES } from "@app/features/seatAdSources/constants";
import { useWatch } from "antd/lib/form/Form";

export const useAutoscaleIsVisible = () => {
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);

    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSourceType?.name] ?? adSourceType?.name;

    switch (adSourceTypeName) {
        case AdSourceTypes.MARKETPLACE:
            return false;
        default:
            return true;
    }
};
