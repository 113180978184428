import { useMemo, useState } from "react";
import { LoadingState } from "@app/core/components/LoadableComponent";
import { useGetVastErrorsQuery, VastErrors, VastErrorsEntityTypes } from "@app/core/services";
import { useLast24HoursMomentRange } from "@app/core/utils";
import { LabeledValue } from "antd/lib/select";

export interface UseSeatAdSourcesVastErrors {
    vastErrors: VastErrors[] | undefined;
    loadingState: LoadingState;
    networks: LabeledValue[];
    selectedNetwork: LabeledValue;
    setSelectedNetwork: (selectedNetwork: LabeledValue) => void;
    refreshLast24Hours: () => void;
}

const ShowAllNetworksOption = { label: "All", value: 0 } as const;

export const useSeatAdSourcesDetailsVastErrors = (adSourceId: number): UseSeatAdSourcesVastErrors => {
    const [selectedNetwork, setSelectedNetwork] = useState<LabeledValue>(ShowAllNetworksOption);
    const { last24Hours, refreshLast24Hours } = useLast24HoursMomentRange();
    const { data: vastErrors, isFetching } = useGetVastErrorsQuery({
        entityType: VastErrorsEntityTypes.AdSource,
        entityId: adSourceId,
        ntimeFilter: last24Hours,
    });

    const networks = useMemo<LabeledValue[]>(() => {
        const networkIdSet = new Set<number>();
        return [
            ShowAllNetworksOption,
            ...((vastErrors ?? [])
                .map((vastError) =>
                    vastError.errorCountsByNap.map((errorData) => {
                        const { networkId, networkName } = errorData;
                        if (networkIdSet.has(networkId)) {
                            return;
                        }
                        networkIdSet.add(networkId);
                        return {
                            label: networkName,
                            value: networkId,
                        };
                    })
                )
                .flat(3)
                .filter((network) => network) as LabeledValue[]),
        ];
    }, [vastErrors]);

    const filteredVastErrors = useMemo(() => {
        const showAllNetworks = selectedNetwork.value === ShowAllNetworksOption.value;
        return showAllNetworks
            ? vastErrors
            : vastErrors
                  ?.map((vastError) => ({
                      ...vastError,
                      errorCountsByNap: vastError.errorCountsByNap.filter(
                          (errorData) => errorData.networkId === selectedNetwork.value
                      ),
                  }))
                  .filter((vastError) => vastError.errorCountsByNap.length > 0);
    }, [vastErrors, selectedNetwork]);

    const loadingState = isFetching
        ? "loading"
        : filteredVastErrors && filteredVastErrors?.length > 0
        ? "loaded"
        : "loadedNoData";

    return {
        vastErrors: filteredVastErrors,
        loadingState,
        networks,
        selectedNetwork,
        setSelectedNetwork,
        refreshLast24Hours,
    };
};
