import { Affix } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useParams } from "react-router-dom";
import { ALLOWED_CONTENT_TRANSPARENCY_RULES, BRAND_SAFETY_HEADER_SDET, BRAND_SAFETY_LIST } from "./constants";
import { useUserAccess } from "@app/core/auth";
import { Alert } from "antd/lib";
import { CONTROLS_ROUTES } from "../constants";
import { useContentTransparencyRules } from "./ContentTransparencyRules/useContentTransparencyRules";

export const BrandSafetyHeader = () => {
    const { canEditSeat } = useUserAccess();
    const { seatId, tab } = useParams<{ seatId: string; tab: string }>();
    const { data } = useContentTransparencyRules();
    const overreached = data && data.length >= ALLOWED_CONTENT_TRANSPARENCY_RULES;

    return (
        <Affix>
            <PageHeader
                data-sdet={BRAND_SAFETY_HEADER_SDET?.[tab]}
                ghost={false}
                title={BRAND_SAFETY_LIST?.[tab]?.label}
                extra={BRAND_SAFETY_LIST?.[tab]?.extra?.(seatId, canEditSeat, overreached)}
            />
            {tab === CONTROLS_ROUTES.CONTENT_TRANSPARENCY_RULES && overreached && (
                <Alert banner message="You are only allowed up to 3 content transparency rules per seat." />
            )}
        </Affix>
    );
};

export default BrandSafetyHeader;
