import { Currency, useGetCurrenciesQuery } from "@app/core/services";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { useSyncedFieldsProducer } from "@app/features/syncedFields";
import { useCallback } from "react";

export const useCurrencySelect = () => {
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const isReadonly = dealObjectMode === "view";
    const { data: currencies, isFetching: isLoading } = useGetCurrenciesQuery(undefined, { skip: isReadonly });
    const { updateSyncedFields } = useSyncedFieldsProducer();

    const onChange = useCallback(
        (value: Currency) => {
            updateSyncedFields({ currency: value });
        },
        [updateSyncedFields]
    );

    return { currencies, isLoading, isReadonly, onChange };
};
