import { FC, useMemo } from "react";
import { Loading } from "@app/core/components";
import { ResizableDrawer } from "@rubicon/antd-components";
import { AdvertiserDomainDetailsContent } from "./AdvertiserDomainDetailsContent";
import { AdvertiserDomainDrawerTitle } from "./AdvertiserDomainDrawerTitle";
import { useAdvertiserDomainsDrawer } from "./useAdvertiserDomainsDrawer";
import { AdvertiserDomainDefinitionsContent } from "./AdvertiserDomainDefinitionDrawer";
import { useLocation } from "react-router-dom";
import { uri } from "@rubicon/utils";
import { HelperKeysDrawerBreadcrumbHeader } from "@app/core/components/HelperKeysDrawerBreadcrumbHeader";
import { drawerRightClosePosition } from "@app/features/controls/constants";

export const AdvertiserDomainDrawer: FC = () => {
    const { isLoading, aDomain, id, handleDrawerClose } = useAdvertiserDomainsDrawer();

    isLoading && <Loading />;

    const { search } = useLocation();
    const drawerParam = useMemo(() => uri.getSearchParam(search, "drawer"), [search]);

    return (
        <ResizableDrawer
            title={
                <>
                    {drawerParam !== "definitions" && <AdvertiserDomainDrawerTitle aDomain={aDomain} />}
                    {drawerParam === "definitions" && (
                        <HelperKeysDrawerBreadcrumbHeader
                            parentLabel={aDomain?.name}
                            childLabel="Advertiser Domain Definitions"
                        />
                    )}
                </>
            }
            open={Boolean(id)}
            onClose={handleDrawerClose}
            placement="right"
            width="45%"
            className={drawerRightClosePosition}
        >
            {drawerParam !== "definitions" && <AdvertiserDomainDetailsContent aDomain={aDomain} />}
            {drawerParam === "definitions" && <AdvertiserDomainDefinitionsContent />}
        </ResizableDrawer>
    );
};
