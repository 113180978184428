import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { useGetSsaiTypesQuery } from "@app/core/services";
import { NONE_OPTION } from "@app/features/inventory/HierarchyForms/constants";

interface UseAdUnitSSAIType {
    isFetching: boolean;
    options: LabeledValue[];
}

export const useAdUnitSSAIType = (): UseAdUnitSSAIType => {
    const { data, isFetching } = useGetSsaiTypesQuery();
    const options: LabeledValue[] = useMemo(
        () => (data || []).map(({ id, name }) => ({ value: id, label: name })),
        [data]
    );

    return {
        isFetching,
        options: [NONE_OPTION, ...options],
    };
};
