import { FC } from "react";
import { Typography } from "antd";
import { gray10 } from "@rubicon/antd-components";
import { TargetingFormKeys } from "../constants";

interface Props {
    index: number;
    blockCount: number;
    formKey: TargetingFormKeys;
    name?: string | null;
}

// TODO: Very brittle if any more targeting form keys are added
const getHeaderLabel = (formKey: TargetingFormKeys, blockCount: number, index: number) => {
    // const baseLabel = [
    //     TargetingFormKeys.AdSourceAdditionalTargeting,
    //     TargetingFormKeys.DealAdditionalTargeting,
    // ].includes(formKey)
    //     ? "Deal Targeting"
    //     : "Ad Source Targeting";

    let baseLabel = "Deal Targeting";

    switch (formKey) {
        case TargetingFormKeys.DealAdditionalTargeting:
            baseLabel = "Deal Targeting";
            break;
        case TargetingFormKeys.AdSourceAdditionalTargeting:
            baseLabel = "Ad Source Targeting";
            break;
        case TargetingFormKeys.InventoryAdvancedFloors:
            baseLabel = "Targeting";
            break;

        default:
            baseLabel = "Ad Source Targeting";
            break;
    }

    const indexLabel = blockCount > 1 ? `: Group ${index + 1}` : "";

    return `${baseLabel}${indexLabel}`;
};

export const ReadOnlyTargetingBlockHeader: FC<Props> = ({ index, name, blockCount, formKey }) => {
    return (
        <Typography.Text style={{ color: gray10, fontSize: "16px" }}>
            {name || getHeaderLabel(formKey, blockCount, index)}
        </Typography.Text>
    );
};
