export const CUSTOM_REPORTS_TAB_ROUTES = {
    TAG_PARAMETERS: "tag-parameters",
    RECENT_ACTIVITIES: "recent-activities",
    MY_REPORTS: "my-reports",
    SHARED_WITH_YOU: "shared-with-you",
} as const;

export const CUSTOM_REPORTS_ROUTES = {
    ...CUSTOM_REPORTS_TAB_ROUTES,
    NEW_REPORT: "new-report",
} as const;

type CustomReportTabRouteKey = keyof typeof CUSTOM_REPORTS_TAB_ROUTES;

export type CustomReportTabRoute = typeof CUSTOM_REPORTS_TAB_ROUTES[CustomReportTabRouteKey];
