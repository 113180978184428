import { FC } from "react";
import { Card, Row, Col } from "antd";
import { TWO_COL_SIZES } from "@app/features/inventory/constants";
import { InternalOnly } from "@app/features/inventory/components/InternalOnly";

import {
    Code,
    Name,
    Mode,
    CodeType,
    SupplyType,
    Description,
} from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/GeneralSection/Fields";
import { useGeneralSection } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/GeneralSection/useGeneralSection";

export const GeneralSection: FC = () => {
    const { isInternalOnly, isEditMode, hasCode } = useGeneralSection();
    return (
        <Card bordered={false}>
            <Row>
                <Col {...TWO_COL_SIZES}>
                    <Name />
                    <CodeType />
                    {hasCode && <Code wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 22, xxl: 22 }} />}
                    <Description />
                    <SupplyType />
                    <Mode />
                </Col>
                {isInternalOnly && isEditMode && (
                    <Col {...TWO_COL_SIZES}>
                        <InternalOnly>
                            <Code wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 }} />
                        </InternalOnly>
                    </Col>
                )}
            </Row>
        </Card>
    );
};
