import { consoleApi, LIBRARY_CREATIVES_TAG } from "./console";
import { toQueryString } from "@app/core/services/console/utils";

export interface LibraryCreativeParams {
    keyword?: string;
}
export interface LibraryCreativePayload {
    seatId: number;
    params?: LibraryCreativeParams;
}

export interface LibraryCreative {
    advertiser: {
        advertiserDomains: unknown[];
        code: string;
        creationDate: string;
        description: string;
        id: number;
        isParent: boolean;
        name: string;
        parentAdvertiser: unknown;
        seat: {
            code: string;
            entityType: "Seat";
            id: number;
            name: string;
        };
        updateDate: string;
    };
    advertiserDomain: string;
    creationDate: string;
    duration: number;
    id: number;
    lineItemCount: number;
    mode: {
        creationDate: string;
        id: number;
        name: string;
        updateDate: string;
    };
    name: string;
    network: unknown;
    seat: {
        code: string;
        entityType: "Seat";
        id: number;
        name: string;
    };
    status: {
        creationDate: string;
        id: number;
        name: string;
        updateDate: string;
    };
    type: {
        creationDate: string;
        id: number;
        name: string;
        updateDate: string;
    };
    universalAdId: string;
    universalAdRegistrar: string;
    updateDate: string;
}

export const libraryCreativesApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getLibraryCreatives: builder.query<LibraryCreative[], LibraryCreativePayload>({
            query: ({ seatId, params = {} }: LibraryCreativePayload) =>
                `seats/${seatId}/libraryCreatives;${toQueryString(params)}`,
            providesTags: [LIBRARY_CREATIVES_TAG],
        }),
    }),
});

export const { useGetLibraryCreativesQuery } = libraryCreativesApi;
