import { AdSourceFormsContext } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { useContext, useEffect } from "react";
import { MarketplaceInfo } from "@app/core/services";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { Form } from "antd";
import { useSeatAuthContext } from "@app/core/auth/useSeatAuthContext";

export const usePublisherName = () => {
    const { context } = useSeatAuthContext();
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const { forms } = useContext(AdSourceFormsContext);
    const { dealObjectForm } = forms;
    const buyers = Form.useWatch<MarketplaceInfo["deal"]["buyerSeats"]>(["dealUnderEdit", "buyerSeats"]);
    const isVisible = Boolean(buyers?.length);
    const isReadonly = dealObjectMode === "view";

    useEffect(() => {
        if (isVisible && dealObjectMode === "create") {
            dealObjectForm.setFieldsValue({
                dealUnderEdit: {
                    publisherName: context?.name || "",
                },
            });
        }
    }, [isVisible, dealObjectMode, context, dealObjectForm]);

    return {
        isVisible,
        isReadonly,
        placeholder: context?.name || "",
    };
};
