import { useContext } from "react";
import { SeatTreeNavigationContext } from "../InventoryFloorHierarchicalPage/InventoryFloorHierarchicalPage";

export const useSeatTree = () => {
    const seatTreeNavigationContext = useContext(SeatTreeNavigationContext);

    if (!seatTreeNavigationContext) {
        throw new Error("useSeatTree has to be used within <SeatTreeNavigationContext.Provider>");
    }

    return seatTreeNavigationContext;
};
