import { LabeledValue } from "antd/lib/select";
import {
    YES_OPTION,
    LINEARITY_LINEAR,
    AD_UNIT_CUSTOM_TYPE,
    CUSTOM_MAX_EXTENDED,
    CHANNEL_FORM_FIELDS,
} from "@app/features/inventory/HierarchyForms/constants";
import { useBrandChannelFormInstance } from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormInstance";

interface UseDefaultsSection {
    isTypeShown: boolean;
    isStartDelayShown: boolean;
    isAdditionalTimeShown: boolean;
    isSkippableMinimumAfterShown: boolean;
}

export const useDefaultsSection = (): UseDefaultsSection => {
    const { useWatch } = useBrandChannelFormInstance();
    const type: LabeledValue = useWatch(CHANNEL_FORM_FIELDS.TYPE.name);
    const linearity: number = useWatch(CHANNEL_FORM_FIELDS.LINEARITY.name);
    const skippable: boolean | string = useWatch(CHANNEL_FORM_FIELDS.SKIPPABLE.name);
    const maxExtended: number | string = useWatch(CHANNEL_FORM_FIELDS.MAX_EXTENDED.name);

    const isTypeShown: boolean = linearity === LINEARITY_LINEAR.value;
    const isSkippableMinimumAfterShown: boolean = skippable === YES_OPTION.value;
    const isStartDelayShown: boolean = type?.value === AD_UNIT_CUSTOM_TYPE.value;
    const isAdditionalTimeShown: boolean = maxExtended === CUSTOM_MAX_EXTENDED.value;
    return { isTypeShown, isStartDelayShown, isAdditionalTimeShown, isSkippableMinimumAfterShown };
};
