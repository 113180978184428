import { FC } from "react";
import { Select } from "antd";
import { LabeledValue } from "antd/lib/select";
import { TagRender } from "@app/core/components/TagRender";
import { useSizesSelect } from "./useSizesSelect";
import { SelectAllButtonDropdownRender } from "@app/core/components/SelectAllButtonDropdownRender";

interface Props {
    onChange: (value: LabeledValue[]) => void;
    value: LabeledValue[];
}

export const PlayerSizesSelect: FC<Props> = ({ onChange, value }) => {
    const { isLoading, options, search, onBlur, onSearch } = useSizesSelect();

    return (
        <Select
            allowClear
            labelInValue
            data-sdet="PlayerSizeDimensionSelect"
            dropdownRender={(menu) => (
                <SelectAllButtonDropdownRender<LabeledValue>
                    getPrimaryKey={(v) => v.value}
                    menu={menu}
                    onChange={onChange}
                    options={options}
                    search={search}
                    value={value}
                />
            )}
            filterOption={false}
            loading={isLoading}
            mode="multiple"
            onBlur={onBlur}
            onChange={onChange}
            onSearch={onSearch}
            options={options}
            placeholder="Select Values"
            searchValue={search}
            style={{ width: "100%" }}
            tagRender={TagRender}
            value={value}
        />
    );
};
