import moment from "moment-timezone";
import { MONTH_DAY_YEAR_DASHED } from "@app/core/components/constants";
import { HistoricalQueriesResults } from "@app/core/services";
import {
    LineChartData,
    LegendDataItem,
    MapByDay,
    ParsedData,
    ColumnChartData,
    GROUP_LINE,
    GROUP_COLUMN,
} from "./types";

export const COLORS = {
    [GROUP_LINE.LINE1]: "#9351E6", //name=line1
    [GROUP_LINE.LINE2]: "#602485", //name=line2
    [GROUP_COLUMN.COL1]: "#147387", //type=col1
    [GROUP_COLUMN.COL2]: "#0BB4B4", //type=col2
};

export const CATEGORIES = {
    //line1
    [GROUP_LINE.LINE1]: {
        label: "Est. Revenue",
        color: COLORS[GROUP_LINE.LINE1],
        checked: true,
        type: GROUP_LINE.LINE1,
    },
    //line2
    [GROUP_LINE.LINE2]: {
        label: "Recorded Revenue",
        color: COLORS[GROUP_LINE.LINE2],
        checked: true,
        type: GROUP_LINE.LINE2,
    },
    //col1
    [GROUP_COLUMN.COL1]: {
        label: "Recorded Avg. Floor Price",
        color: COLORS[GROUP_COLUMN.COL1],
        checked: true,
        type: GROUP_COLUMN.COL1,
    },
    //col2
    [GROUP_COLUMN.COL2]: {
        label: "Optimal",
        color: COLORS[GROUP_COLUMN.COL2],
        checked: true,
        type: GROUP_COLUMN.COL2,
    },
};

export const EST_PRICE_OPTIMAL = { label: "Optimal", value: -1 };

export const getEstPriceLabel = (estPrice: string) => `Est Floor Price ${estPrice}`;

export const getDefaultLegend = (isOptimal: boolean, estPrice: string) =>
    [
        CATEGORIES[GROUP_LINE.LINE1],
        CATEGORIES[GROUP_LINE.LINE2],
        CATEGORIES[GROUP_COLUMN.COL1],
        isOptimal && CATEGORIES[GROUP_COLUMN.COL2],
        !isOptimal && {
            ...CATEGORIES[GROUP_COLUMN.COL2],
            label: getEstPriceLabel(estPrice),
        },
    ].filter(Boolean) as LegendDataItem[];

export const formatter = (time: number) => moment(time).format(MONTH_DAY_YEAR_DASHED);

export const getParsedDataFromApi = (data: HistoricalQueriesResults[]) => {
    const defaultValue: ParsedData = {
        estPrice: new Set([EST_PRICE_OPTIMAL.value]),
        mapByDay: {},
        mapByPotentialFloor: {},
    };

    return data.reduce((acc, { day, potentialFloor, recordedRevenue, estimatedRevenue, recordedAvgFloorPrice }) => {
        acc.estPrice.add(potentialFloor);

        const ts = moment.utc(day).unix() * 1000;
        const item: MapByDay = {
            ts,
            potentialFloor,
            recordedRevenue,
            estimatedRevenue,
            recordedAvgFloorPrice,
            day,
        };

        if (!acc.mapByDay[day]) {
            acc.mapByDay[day] = [];
        }
        acc.mapByDay[day].push(item);

        if (!acc.mapByPotentialFloor[potentialFloor]) {
            acc.mapByPotentialFloor[potentialFloor] = [];
        }
        acc.mapByPotentialFloor[potentialFloor].push(item);

        return acc;
    }, defaultValue);
};

export const generateSeries = (data: MapByDay[]) =>
    data.reduce(
        (acc, { ts, potentialFloor, recordedRevenue, estimatedRevenue, recordedAvgFloorPrice, optimalFloor }) => {
            acc.lineSeries.push({
                time: ts,
                count: estimatedRevenue || 0,
                name: GROUP_LINE.LINE1,
            });

            acc.lineSeries.push({
                time: ts,
                count: recordedRevenue || 0,
                name: GROUP_LINE.LINE2,
            });

            acc.columnSeries.push({
                time: ts,
                value: recordedAvgFloorPrice || 0,
                type: GROUP_COLUMN.COL1,
            });

            acc.columnSeries.push({
                time: ts,
                value: potentialFloor || optimalFloor || 0,
                type: GROUP_COLUMN.COL2,
            });

            return acc;
        },
        {
            lineSeries: [],
            columnSeries: [],
        } as { lineSeries: LineChartData[]; columnSeries: ColumnChartData[] }
    );

export const getLineMax = (lineSeries: LineChartData[]) => Math.max(...lineSeries.map(({ count }) => count)) * 1.28;
