import { FC } from "react";
import { Row, Select, Typography } from "antd";
import { LabeledValue } from "antd/lib/select";
import { INVENTORY_CATEGORIES, TargetingDimensionTypeLabels } from "@app/features/targeting/constants";
import { BulkUploadDrawerButton } from "../../BulkUploadDrawerButton";
import { useBulkInventoryDrawerButton } from "./useBulkInventoryDrawer";

interface Props {
    onChange: (value: LabeledValue[]) => void;
    values: LabeledValue[];
}

export const BulkInventoryDrawerButton: FC<Props> = ({ onChange, values }) => {
    const {
        handleAdd,
        handleChangeCategory,
        handleChangeValue,
        handleClose,
        handleOpen,
        handleReplace,
        isLoadingAdd,
        isLoadingReplace,
        isOpen,
        value,
        category,
    } = useBulkInventoryDrawerButton(onChange, values);

    return (
        <BulkUploadDrawerButton
            handleAdd={handleAdd}
            handleChangeValue={handleChangeValue}
            handleClose={handleClose}
            handleOpen={handleOpen}
            handleReplace={handleReplace}
            isLoadingAdd={isLoadingAdd}
            isLoadingReplace={isLoadingReplace}
            isOpen={isOpen}
            value={value}
            title={`${TargetingDimensionTypeLabels.Inventory} Targeting - Bulk Load`}
            placeholder="Enter list of Inventory Codes one per row"
            extra={
                <Row align={"middle"}>
                    <Typography.Paragraph>
                        <span>Add list of: &nbsp;&nbsp;</span>
                        <Select
                            options={[
                                INVENTORY_CATEGORIES.channels,
                                INVENTORY_CATEGORIES.publishers,
                                INVENTORY_CATEGORIES.brands,
                                INVENTORY_CATEGORIES.supply,
                                INVENTORY_CATEGORIES.adUnits,
                            ]}
                            onChange={handleChangeCategory}
                            defaultValue={INVENTORY_CATEGORIES.channels.value}
                            value={category}
                            labelInValue
                        />
                    </Typography.Paragraph>
                    <Typography.Paragraph>Please use Inventory Code to bulk load.</Typography.Paragraph>
                </Row>
            }
        />
    );
};
