import { FC } from "react";
import { css } from "@emotion/css";
import { Button, Empty, Form } from "antd";
import { useSnapshot } from "./useSnapshot";
import { DualAxes } from "@ant-design/plots";
import { LabeledValue } from "antd/lib/select";
import { DeleteOutlined } from "@ant-design/icons";
import { TimingStatsChartType } from "../useTimingStats";

interface Props extends LabeledValue {
    chartType: TimingStatsChartType;
    region?: LabeledValue;
    multiplicityId: number;
    handleDeselect: (value: string) => void;
}

export const Snapshot: FC<Props> = ({ label, value, handleDeselect, chartType, region, multiplicityId }) => {
    const { config, hasSelectedRegion } = useSnapshot(
        chartType,
        region as LabeledValue,
        multiplicityId,
        value as string
    );
    return (
        <>
            <Form.Item
                label="Snapshot"
                className={css`
                    .ant-form-item-control-input-content {
                        justify-content: space-between;
                        display: flex;
                        align-items: center;
                    }
                `}
            >
                {label}
                <Button icon={<DeleteOutlined />} onClick={() => handleDeselect(value as string)} />
            </Form.Item>

            {hasSelectedRegion ? <DualAxes {...config} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </>
    );
};
