import { NONE } from "@app/core/components/constants";
import { BrandSupply } from "@app/core/services";
import { GUTTER } from "@app/features/inventory/constants";
import { Col, Form, Row } from "antd";
import { FC } from "react";
import { FORM_ITEM_OFFSET, SUPPLY_APP_DETAILS_LABELS } from "../../../constants";

const { Item } = Form;

const getType = (bundleIdIsOverride: boolean) => (bundleIdIsOverride ? "Override" : "Default");

interface Props {
    supply: BrandSupply;
}

export const AppDetails: FC<Props> = ({ supply }) => {
    return (
        <div data-sdet="supply-app-details">
            <Row gutter={GUTTER}>
                <Col span={12}>
                    <Item label={SUPPLY_APP_DETAILS_LABELS.appStore} className={FORM_ITEM_OFFSET}>
                        {supply?.appStore?.name ?? NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col span={12}>
                    <Item label={SUPPLY_APP_DETAILS_LABELS.bundleId} className={FORM_ITEM_OFFSET}>
                        {supply?.bundleId ? `${supply?.bundleId} Type: ${getType(supply.bundleIdIsOverride)}` : NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col span={12}>
                    <Item label={SUPPLY_APP_DETAILS_LABELS.appName} className={FORM_ITEM_OFFSET}>
                        {supply?.storeUrl ? (
                            <a href={supply.storeUrl} target="_blank" rel="noreferrer">
                                {supply?.supplyNameOverride}
                            </a>
                        ) : (
                            supply?.supplyNameOverride
                        )}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col span={12}>
                    <Item label={SUPPLY_APP_DETAILS_LABELS.storeUrl} className={FORM_ITEM_OFFSET}>
                        {supply?.storeUrl ?? NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col span={12}>
                    <Item label={SUPPLY_APP_DETAILS_LABELS.paidType} className={FORM_ITEM_OFFSET}>
                        {supply?.paidType?.name ?? NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col span={12}>
                    <Item label={SUPPLY_APP_DETAILS_LABELS.domain} className={FORM_ITEM_OFFSET}>
                        {supply?.domain ?? NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col span={12}>
                    <Item label={SUPPLY_APP_DETAILS_LABELS.partnerDomain} className={FORM_ITEM_OFFSET}>
                        {supply?.inventoryPartnerDomain ?? NONE}
                    </Item>
                </Col>
            </Row>
        </div>
    );
};
