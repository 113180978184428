import { GUTTER, COL_SIZES } from "@app/features/deals/constants";
import { Row, Col } from "antd";
import { FC } from "react";
import { BuyerSeatsSelect, DemandSelect, EndDatePicker, RunDateModeRadio, StartDatePicker } from "./Fields";

interface Props {
    isProgrammaticGuaranteedOrLegacy: boolean;
}

export const LegacyDealSubsection: FC<Props> = ({ isProgrammaticGuaranteedOrLegacy }) => {
    return (
        <>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <DemandSelect />
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <BuyerSeatsSelect isProgrammaticGuaranteedOrLegacy={isProgrammaticGuaranteedOrLegacy} />
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <RunDateModeRadio />
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col>
                    <StartDatePicker />
                </Col>
                <Col>
                    <EndDatePicker />
                </Col>
            </Row>
        </>
    );
};
