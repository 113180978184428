import { FC } from "react";
import { Card } from "antd";

import {
    BlockBuyerSeaList,
    BlockedAdvertiserDomains,
    BlockAdvertiserDomainLists,
} from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/BrandSafetySection/Fields";

export const BrandSafetySection: FC = () => {
    return (
        <Card bordered={false}>
            <BlockAdvertiserDomainLists />
            <BlockedAdvertiserDomains />
            <BlockBuyerSeaList />
        </Card>
    );
};
