import { FC } from "react";
import { formRequiredMark } from "@app/core/components";
import { ConfigProvider, ConfigProviderProps } from "antd";

// Override All Forms config Globally
const formConfig: ConfigProviderProps["form"] = {
    // use custom render for asterisk to the right
    requiredMark: formRequiredMark,
};

export const AppConfig: FC = ({ children }) => {
    return <ConfigProvider form={formConfig}>{children}</ConfigProvider>;
};
