import { FunnelOptions } from "@antv/g2plot";
import { ChartColors } from "../../../../../core/components/constants";
import { LEGEND } from "../utils";
import { FunnelChartOptions } from "./types";
import { formatNumber } from "@rubicon/utils";

export const RequiredFields = {
    xField: "xValue",
    yField: "yValue",
    compareField: "compareValue",
} as const;

export const RequiredOptions: Pick<FunnelOptions, "data" | "xField" | "yField" | "compareField"> = {
    data: [],
    ...RequiredFields,
};

export const DefaultOptions: FunnelChartOptions = {
    animation: false,
    legend: LEGEND,
    tooltip: false,
    conversionTag: false,
    showFacetTitle: false,
    minSize: 0.04,
    label: {
        formatter: (v) => (v.compareValue === "shown" ? `${formatNumber.asNumber(v.yValue)} ${v.xValue}` : ""),
        style: {
            fill: ChartColors.TextHeader,
            fontSize: 14,
            fontFamily: "Roboto",
            fontWeight: 500,
            textAlign: "right",
        },
        offsetX: -50,
    },
    padding: [0, 200, 0, 0] as number[],
} as const;
