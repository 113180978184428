import { ClassificationsAdvertiserFormValues } from "./constants";
import { AdvertiserDetails, AdvertiserMutationPayload } from "@app/core/services";

export const getClassificationsAdvertiserFormInitialValues = (advertiser?: AdvertiserDetails) =>
    advertiser
        ? {
              id: advertiser.id,
              name: advertiser.name,
              code: advertiser.code,
              description: advertiser.description,
              parentAdvertiser: advertiser.parentAdvertiser
                  ? { label: advertiser.parentAdvertiser.name, value: advertiser.parentAdvertiser.id }
                  : null,
              industry: advertiser.industry ? { label: advertiser.industry.name, value: advertiser.industry.id } : null,
              advertiserDomains: advertiser.advertiserDomains.map(({ adomain }) => adomain).join("\n"),
          }
        : {};
export const getClassificationsAdvertiserPayload = (
    values: ClassificationsAdvertiserFormValues,
    seatId: number
): AdvertiserMutationPayload => {
    const { id, name, code, description, advertiserDomains, industry, parentAdvertiser } = values;
    const nextAdvertiserDomains =
        advertiserDomains?.split("\n").reduce((acc, domain) => {
            domain.trim() && acc.push({ adomain: domain.trim() });
            return acc;
        }, [] as { adomain: string }[]) || [];

    return {
        id,
        name: name.trim(),
        code: code?.trim(),
        description: description?.trim(),
        advertiserDomains: nextAdvertiserDomains,
        industry: industry ? { id: industry.value, name: industry.label } : null,
        parentAdvertiser: parentAdvertiser ? { id: parentAdvertiser.value, name: parentAdvertiser.label } : null,
        seat: { id: seatId },
    };
};
