export const PRICE = "price";
export const COUNTRY = "country";
export const ACTUAL_PRICE = "actualPrice";
export const ACTIONS = "actions";
export const PUBLISHERS = "publishers";
export const INHERIT_PRICING = "inheritPricing";
export const PUBLISHER_PRICE = "publisherPrice";
export const SOURCE = "source";
export const INHERTIED_FROM = "inheritedFrom";
export const BRANDS = "brands";
export const FLOOR = "floor";

export const COLUMN_KEY = {
    PRICE,
    COUNTRY,
    SOURCE,
    INHERTIED_FROM,
    ACTUAL_PRICE,
    ACTIONS,
};

export const PUBLISHERS_TABLE_COLUMN_KEY = { PUBLISHERS, INHERIT_PRICING };

export const PUBLISHER_FLOOR_PRICING_RULES_COLUMN_KEY = {
    PUBLISHER_PRICE,
    COUNTRY,
    SOURCE,
    INHERTIED_FROM,
    ACTUAL_PRICE,
    ACTIONS,
};

export const BRANDS_FLOOR_LIST_TABLE_COLUMN_KEY = {
    BRANDS,
    INHERIT_PRICING,
};

export const ADVANCED_FLOORS_TABLE_COLUMN_KEY = {
    FLOOR,
    PRICE,
    ACTIONS,
};
