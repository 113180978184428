import { ChangeHistoryTable } from "@app/core/components/ChangeHistoryTable";
import { ChangeLogEntityType, DealList } from "@app/core/services";
import { css } from "@emotion/css";
import { Col } from "antd";
import { FC } from "react";
import { DealDetailsDrawerTitles } from "@app/features/deals/constants";
import { DealDetailsContent } from "@app/features/deals/DealDetailsPage/DealDetailsContent";
import { DealDetailsDrawerLadle } from "@app/features/deals/DealDetailsDrawer/DealDetailsDrawerView/secondaryDetailsView";

const className = css`
    padding: 16px 24px;
`;
interface Props {
    dealAdSource: DealList | null;
    secondaryDetailsView: DealDetailsDrawerTitles | null;
}

export const DealDetailsDrawerViewSwitch: FC<Props> = ({ dealAdSource, secondaryDetailsView }) => {
    if (!dealAdSource) {
        return null;
    }
    switch (secondaryDetailsView) {
        case DealDetailsDrawerTitles.Ladle:
            return (
                <Col span={24} className={className}>
                    <DealDetailsDrawerLadle deal={dealAdSource.deal} />
                </Col>
            );

        case DealDetailsDrawerTitles.ChangeHistory:
            return (
                <Col span={24} className={className}>
                    <ChangeHistoryTable entityId={dealAdSource.deal?.id} entityType={ChangeLogEntityType.Deal} />
                </Col>
            );

        default:
            return (
                <Col span={24}>
                    <DealDetailsContent isInsideDrawer dealAdSource={dealAdSource} />
                </Col>
            );
    }
};
