import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import {
    collapseAll,
    expandAll,
    selectIsSecondaryCollapseExpandedAll,
} from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/reducer";
import { useAppDispatch, useAppSelector } from "@app/core/store";

type AdSourceIdNavigator = (adSourceId: number | string) => void;
type EmptyNavigator = () => void;

interface UseSeatAdSourcesPageNavigator {
    goToSeatAdSourcesListPage: EmptyNavigator;
    goToSeatAdSourcesDetailsPage: AdSourceIdNavigator;
    goToSeatAdSourcesCreatePage: EmptyNavigator;
    goToSeatAdSourcesEditPage: AdSourceIdNavigator;
    goToSeatAdSourcesCopyPage: AdSourceIdNavigator;
    goBack: () => void;
    isExpandAll: boolean;
    handleChangeCollapse: () => void;
}

export const useSeatAdSourcesPageNavigator = (): UseSeatAdSourcesPageNavigator => {
    const dispatch = useAppDispatch();
    const isExpandAll = useAppSelector(selectIsSecondaryCollapseExpandedAll);
    const {
        SEAT_AD_SOURCES_LIST,
        SEAT_AD_SOURCES_DETAILS,
        SEAT_AD_SOURCES_CREATE,
        SEAT_AD_SOURCES_EDIT,
        SEAT_AD_SOURCES_COPY,
    } = ROUTE_FORMATTERS;

    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();

    const goBack = () => {
        history.goBack();
    };

    const goToSeatAdSourcesListPage = () => {
        history.push(SEAT_AD_SOURCES_LIST(seatId));
    };

    const goToSeatAdSourcesDetailsPage = (adSourceId: number) => {
        history.push(SEAT_AD_SOURCES_DETAILS(seatId, adSourceId));
    };

    const goToSeatAdSourcesCreatePage = () => {
        history.push(SEAT_AD_SOURCES_CREATE(seatId));
    };

    const goToSeatAdSourcesEditPage = (adSourceId: number) => {
        history.push(SEAT_AD_SOURCES_EDIT(seatId, adSourceId));
    };

    const goToSeatAdSourcesCopyPage = (adSourceId: number) => {
        history.push(SEAT_AD_SOURCES_COPY(seatId, adSourceId));
    };

    const handleChangeCollapse = () => {
        if (isExpandAll) {
            dispatch(expandAll());
        } else {
            dispatch(collapseAll());
        }
    };

    return {
        goToSeatAdSourcesListPage,
        goToSeatAdSourcesDetailsPage,
        goToSeatAdSourcesCreatePage,
        goToSeatAdSourcesEditPage,
        goToSeatAdSourcesCopyPage,
        goBack,
        isExpandAll,
        handleChangeCollapse,
    };
};
