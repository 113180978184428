import client from "./client";

export interface UserRole {
    roleType: {
        id: number;
        typeCode: string;
        typeName: string;
        userRoleTypeClass: {
            id: number;
            name: string;
            description: string;
            sort: number;
            dateCreated: string;
            lastUpdated: string;
        };
    };
}

export enum RevenueTypes {
    NET_REVENUE = "netRev",
    GROSS_REVENUE = "grossRev",
}

export enum CurrencyConversionModes {
    CONVERT = "convert",
    FILTER = "filter",
    ORIGINATING_CURRENCY = "originatingCurrency",
}

export interface User {
    creationDate: string;
    id: number;
    emailAddress: string;
    firstName: string;
    lastName: string;
    countryCode: string;
    timezoneCode: string;
    currencyCode: string;
    localeCode: string;
    company: string;
    userRoles: UserRole[];
    userPreferenceMap: {
        adSourcesCurrencyConversionMode: CurrencyConversionModes;
        adSourcesPreferredCurrency?: string | number;
        currencyConversionMode: CurrencyConversionModes;
        dashboardRevenueType: RevenueTypes;
        dealCurrencyConversionMode: CurrencyConversionModes;
        dealPreferredCurrency?: string | number;
        preferredCurrency?: string | number;
        timezone?: string;
        preferredBuyer?: number;
        preferredSeat?: number;
    };
    name: string;
}

export type GrantingUser = Omit<User, "userRoles" | "userPreferenceMap"> | null;

export interface Session {
    id: number;
    sessionCode: string;
    sessionStartTime: string;
    lastValidated: string;
    lastLogin: string;
    user: User;
    grantingUser: GrantingUser;
}

export const runAsUser = (
    user: {
        emailAddress: string;
        id: number;
    },
    sessionCode: string
) => {
    return client.put<Session>(
        `/platform/resources/sessions/${sessionCode}`,
        { user },
        {
            withCredentials: true,
        }
    );
};

export const login = (emailAddress: string, password: string) => {
    return client.post<Session>(
        `/platform/resources/sessions`,
        {
            emailAddress,
            password,
        },
        {
            // We want to get the session cookie back, but not send an expired cookie to the server
            headers: {
                Cookie: "",
            },
            withCredentials: true,
        }
    );
};

export const logout = () => {
    return client.delete(`/platform/resources/sessions/self`);
};

export const getSessionSelf = () => {
    return client.get<Session>(`/platform/resources/sessions/self`);
};
