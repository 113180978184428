import { useGetHelpArticleQuery } from "@app/core/services/console/help";
import { FC } from "react";
import DOMPurify from "dompurify";

interface Props {
    helpPath: string;
}

export const HelpKeyItem: FC<Props> = ({ helpPath }) => {
    const { data, isLoading } = useGetHelpArticleQuery(helpPath);

    return isLoading ? (
        <span>Loading...</span>
    ) : (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.body || "") }} />
    );
};
