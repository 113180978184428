import { Store } from "rc-field-form/lib/interface";
import {
    parseIsCoppa,
    parseFreqCaps,
    parseCodeValue,
    parseAdUnitType,
    parseLsaEnabled,
    parseAdUnitSkip,
    parseUnwrapVast,
    parseAdUnitMimes,
    parseLabelValues,
    parseAdBreakRules,
    parseAdUnitStatus,
    parseIabCategories,
    parseFreqCapsToApi,
    parseAdUnitSkipmin,
    parseBuyerSeatList,
    parseAdUnitSsaiType,
    parseAdUnitSkipafter,
    parseAdUnitPlacement,
    parseAdUnitLinearity,
    parseAdUnitMinBitrate,
    parseThirdPartyPixels,
    parseAdUnitLiveStream,
    parseAdUnitMaxBitrate,
    parseHasPrivacyPolicy,
    parseAdUnitStartDelay,
    parseAdUnitMaxDuration,
    parseAdUnitMinDuration,
    parseAdBreakRulesToApi,
    parseAllowVastWrappers,
    parseAdUnitMaxExtended,
    parseAdUnitInterstitial,
    parseImpressionWaitTime,
    parseAdUnitMultiplicity,
    parseAdUnitMaxAdsPerPod,
    parsePublisherReauction,
    parseAdUnitMaxPodSeconds,
    parseMaxAdsPerAdvertiser,
    parseInternalLabelValues,
    parseAdUnitSupportedApis,
    parseAdUnitAdPodFillMode,
    parseLsaWindowSizeSeconds,
    parseAdUnitAdditionalTime,
    parseBlockedIabCategories,
    parseAdUnitSeparationMode,
    parseEnableNoAdCookieSync,
    parseAdUnitPodEnforcement,
    parseAdUnitSeparationGroups,
    parseAdvertiserBlockDomains,
    parseAdomainFreqCappingType,
    parseInventoryPartnerDomain,
    parseAdUnitSupportedProtocols,
    parseBlockedAdvertiserDomains,
    parseEnableAdvertisersAsWhitelist,
    parseDistributionGroupLabelValues,
    parseChannelPlayListDefinitionMode,
} from "@app/features/inventory/HierarchyForms/helpers";
import {
    YES_OPTION,
    NONE_OPTION,
    OFF_WAIT_TIME,
    UNKNOWN_OPTION,
    HierarchyFormsMode,
    CUSTOM_MAX_EXTENDED,
    DEFAULT_UNWRAP_VAST,
    CHANNEL_FORM_FIELDS,
    NOT_SPECIFIED_OPTION,
    AD_UNIT_PRE_ROLL_TYPE,
    LINEARITY_BOTH_ALLOWED,
    INVENTORY_CUSTOM_CODE_TYPE,
    DEFAULT_AD_UNIT_START_DELAY,
    INVENTORY_GENERATED_CODE_TYPE,
    DEFAULT_LIVE_STREAM_ACCELERATION,
    DEFAULT_ADVERTISERS_AS_ALLOW_LIST,
} from "@app/features/inventory/HierarchyForms/constants";
import { BrandChannel, BrandChannelPayload, Seat } from "@app/core/services";
import { BrandChannelForm } from "@app/features/inventory/HierarchyForms/BrandChannelForm/useBrandChannelForm";

interface ParseBrandChannelFormValuesToApi {
    seat: Seat | null;
    publisherId: number;
    isSysAdmin: boolean;
    isTremorUser: boolean;
    isPubAcctMgr: boolean;
    values: BrandChannelForm;
    mode: HierarchyFormsMode;
    hasInternalAccess: boolean;
    brandChannel: BrandChannel | undefined;
}

interface GetBrandChannelFormInitialValues {
    mode: Exclude<HierarchyFormsMode, "copy">;
    brandChannel: BrandChannel | undefined;
}

export const getBrandChannelFormInitialValues = ({ brandChannel, mode }: GetBrandChannelFormInitialValues): Store => {
    const { preRoll, midRoll, postRoll } = parseAdBreakRules(mode, brandChannel?.adUnit?.adBreakRules);
    const isCreateMode = mode === "create";

    return {
        // General Section
        [CHANNEL_FORM_FIELDS.NAME.name]: isCreateMode ? "" : brandChannel?.name,
        [CHANNEL_FORM_FIELDS.DESCRIPTION.name]: isCreateMode ? "" : brandChannel?.brand?.description,
        [CHANNEL_FORM_FIELDS.STATUS.name]: parseAdUnitStatus(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.CODE_TYPE.name]: isCreateMode
            ? INVENTORY_GENERATED_CODE_TYPE.value
            : INVENTORY_CUSTOM_CODE_TYPE.value,
        [CHANNEL_FORM_FIELDS.CODE.name]: isCreateMode ? "" : brandChannel?.adUnit?.code,

        // Defaults Section
        [CHANNEL_FORM_FIELDS.MIMES.name]: parseAdUnitMimes(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.SUPPORTED_APIS.name]: parseAdUnitSupportedApis(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.LINEARITY.name]: parseAdUnitLinearity(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.TYPE.name]: parseAdUnitType(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.START_DELAY.name]: parseAdUnitStartDelay(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.INTERSTITIAL.name]: parseAdUnitInterstitial(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.PLACEMENT.name]: parseAdUnitPlacement(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.COPPA.name]: parseIsCoppa(mode, brandChannel?.supply),
        [CHANNEL_FORM_FIELDS.PRIVACY_POLICY.name]: parseHasPrivacyPolicy(mode, brandChannel?.supply),
        [CHANNEL_FORM_FIELDS.CATEGORIES.name]: parseIabCategories(mode, brandChannel?.brand),
        [CHANNEL_FORM_FIELDS.SUPPORTED_PROTOCOLS.name]: parseAdUnitSupportedProtocols(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.MIN_DURATION.name]: parseAdUnitMinDuration(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.MAX_DURATION.name]: parseAdUnitMaxDuration(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.MAX_EXTENDED.name]: parseAdUnitMaxExtended(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.ADDITIONAL_TIME.name]: parseAdUnitAdditionalTime(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.MIN_BITRATE.name]: parseAdUnitMinBitrate(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.MAX_BITRATE.name]: parseAdUnitMaxBitrate(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.SSAI_TYPE.name]: parseAdUnitSsaiType(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.LIVE_STREAM.name]: parseAdUnitLiveStream(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.SKIPPABLE.name]: parseAdUnitSkip(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.SKIPPABLE_AFTER.name]: parseAdUnitSkipafter(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.SKIPPABLE_MINIMUM.name]: parseAdUnitSkipmin(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.MULTIPLICITY.name]: parseAdUnitMultiplicity(mode, brandChannel?.adUnit),

        // Ad Pod Section
        [CHANNEL_FORM_FIELDS.MAX_POD_SECONDS.name]: parseAdUnitMaxPodSeconds(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.MAX_ADS_PER_POD.name]: parseAdUnitMaxAdsPerPod(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.FILL_MODE.name]: parseAdUnitAdPodFillMode(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.POD_ENFORCEMENT.name]: parseAdUnitPodEnforcement(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.COMPETITIVE_SEPARATION_MODE.name]: parseAdUnitSeparationMode(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.COMPETITIVE_SEPARATIO_GROUPS.name]: parseAdUnitSeparationGroups(
            mode,
            brandChannel?.adUnit
        ),

        // Playlist Configuration Section
        [CHANNEL_FORM_FIELDS.MAX_ADS_PER_ADV.name]: parseMaxAdsPerAdvertiser(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.PLAYLIST_DEFINITION_MODE.name]: parseChannelPlayListDefinitionMode(
            mode,
            brandChannel?.adUnit?.playlistDefinitionMode?.id
        ),

        //Ad Break Rules Section
        [CHANNEL_FORM_FIELDS.AD_BREAK_RULES_PRE.name]: preRoll,
        [CHANNEL_FORM_FIELDS.AD_BREAK_RULES_MID.name]: midRoll,
        [CHANNEL_FORM_FIELDS.AD_BREAK_RULES_POST.name]: postRoll,
        [CHANNEL_FORM_FIELDS.CUE_POINTS.name]: isCreateMode ? [] : brandChannel?.adUnit?.cuepoints,

        // Brand Safety Section
        [CHANNEL_FORM_FIELDS.BLOCK_ADVERTISER_DOMAIN_LISTS.name]: parseBlockedAdvertiserDomains(
            mode,
            brandChannel?.brand
        ),
        [CHANNEL_FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: parseAdvertiserBlockDomains(mode, brandChannel?.brand),
        [CHANNEL_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.name]: parseBuyerSeatList(mode, brandChannel?.brand),
        [CHANNEL_FORM_FIELDS.BLOCKED_CATEGORIES.name]: parseBlockedIabCategories(mode, brandChannel?.brand),

        //Detailed Configuration Section
        [CHANNEL_FORM_FIELDS.ALLOW_VAST_WRAPPERS.name]: parseAllowVastWrappers(mode, brandChannel?.supply),
        [CHANNEL_FORM_FIELDS.LIVE_STREAM_ACCELERATION.name]: parseLsaEnabled(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.LSA_WINDOW_SIZE_SECONDS.name]: parseLsaWindowSizeSeconds(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.UNWRAP_VAST.name]: parseUnwrapVast(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.EXTENDED_IMP_WAIT_TIME.name]: parseImpressionWaitTime(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.ADVERTISERS_AS_ALLOW_LIST.name]: parseEnableAdvertisersAsWhitelist(
            mode,
            brandChannel?.brand
        ),
        [CHANNEL_FORM_FIELDS.NO_AD_SYNC.name]: parseEnableNoAdCookieSync(mode, brandChannel?.supply),
        [CHANNEL_FORM_FIELDS.PUBLISHER_RE_AUCTION.name]: parsePublisherReauction(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.ADV_FREQ_CAPP.name]: parseAdomainFreqCappingType(mode, brandChannel?.adUnit),
        [CHANNEL_FORM_FIELDS.ADV_DOMAIN_FREQ_CAPPS.name]: parseFreqCaps(
            mode,
            brandChannel?.adUnit?.adomainFreqCappings
        ),
        [CHANNEL_FORM_FIELDS.PARTNER_DOMAIN.name]: parseInventoryPartnerDomain(mode, brandChannel?.supply),

        // Custom Pixels Section
        [CHANNEL_FORM_FIELDS.CUSTOM_PIXELS.name]: parseThirdPartyPixels(mode, brandChannel?.brand),

        // Labels Section
        [CHANNEL_FORM_FIELDS.LABELS.name]: parseLabelValues(mode, brandChannel?.brand),
        [CHANNEL_FORM_FIELDS.INTERNAL_LABELS.name]: parseInternalLabelValues(mode, brandChannel?.brand),
        [CHANNEL_FORM_FIELDS.DISTRIBUTION_GROUP_LABELS.name]: parseDistributionGroupLabelValues(
            mode,
            brandChannel?.brand
        ),
    };
};

export const parseBrandChannelFormValuesToApi = ({
    seat,
    mode,
    values,
    isSysAdmin,
    publisherId,
    isPubAcctMgr,
    isTremorUser,
    brandChannel,
    hasInternalAccess,
}: ParseBrandChannelFormValuesToApi): BrandChannelPayload => {
    const isEditMode = mode === "edit";
    const {
        // General Section
        name,
        description,
        status,
        codeType,
        code,
        // Defaults Section
        mimes,
        supportedApis,
        linearity,
        type,
        startDelay,
        isInterstitial,
        placement,
        isCoppa,
        hasPrivacyPolicy,
        iabCategories,
        supportedProtocols,
        minDuration,
        maxDuration,
        maxExtended,
        additionalTime,
        maxBitrate,
        minBitrate,
        ssaiType,
        isLiveStream,
        skip,
        skipafter,
        skipmin,
        multiplicity,
        // Ad Pod Section
        maxPodSeconds,
        maxAdsPerPod,
        adPodFillMode,
        podEnforcement,
        separationGroups,
        // Playlist Configuration Section
        maxAdsPerAdvertiser,
        playlistDefinitionMode,
        //Ad Break Rules Section
        adBreakRulesPre,
        adBreakRulesMid,
        adBreakRulesPost,
        cuepoints,
        // Brand Safety Section
        seatAdvertiserDomainFilterListDefs,
        advertiserBlockDomains,
        buyerSeatList,
        blockedIabCategories,
        //Detailed Configuration Section
        allowVastWrappers,
        lsaEnabled,
        lsaWindowSizeSeconds,
        unwrapVast,
        impressionWaitTime,
        enableAdvertisersAsWhitelist,
        enableNoAdCookieSync,
        publisherReauction,
        adomainFreqCappingType,
        adomainFreqCappings,
        inventoryPartnerDomain,
        // Custom Pixels
        thirdPartyPixels,
        // Labels
        labelValues,
        internalLabelValues,
        distributionGroupLabelValues,
    } = values;

    const payload: BrandChannelPayload = {
        // General Section
        name,
        publisher: {
            id: publisherId,
            seat: {
                code: seat?.code as string,
                id: seat?.id as number,
                name: seat?.name as string,
            },
        },
        brand: {
            id: isEditMode ? brandChannel?.brand?.id : null,
            name: name,
            description,
            iabCategories: (iabCategories || []).map((option) => ({
                id: option.value as number,
            })),
            // Brand Safety Section
            seatAdvertiserDomainFilterListDefs: (seatAdvertiserDomainFilterListDefs || []).map((option) => ({
                id: option.value as number,
                name: option.label as string,
            })),
            advertiserBlockDomains: advertiserBlockDomains.trim() ? advertiserBlockDomains.trim().split("\n") : null,
            buyerSeatList: buyerSeatList
                ? { id: buyerSeatList.value as number, name: buyerSeatList.label as string }
                : null,
            blockedIabCategories: (blockedIabCategories || []).map((option) => ({
                id: option.value as number,
            })),
            // Custom Pixels
            thirdPartyPixels: (thirdPartyPixels || []).map((pixel) => ({
                id: pixel.value as number,
                name: pixel.label as string,
            })),
            // Labels
            labelValues: (labelValues || []).map(({ value, seatLabel, labelValue }) => ({
                id: value,
                value: labelValue,
                label: seatLabel,
            })),
            //Detailed Configuration Section
            enableAdvertisersAsWhitelist: enableAdvertisersAsWhitelist || DEFAULT_ADVERTISERS_AS_ALLOW_LIST,
        },
        supply: {
            id: isEditMode ? brandChannel?.supply?.id : null,
            name: name,
            // Defaults Section
            isCoppa: isCoppa === NOT_SPECIFIED_OPTION.value ? null : (isCoppa as boolean),
            hasPrivacyPolicy: hasPrivacyPolicy === NOT_SPECIFIED_OPTION.value ? null : (hasPrivacyPolicy as boolean),
            //Detailed Configuration Section
            allowVastWrappers,
            inventoryPartnerDomain: inventoryPartnerDomain || null,
        },
        adUnit: {
            id: isEditMode ? brandChannel?.adUnit?.id : null,
            name: name,
            status: { id: status },
            code: parseCodeValue(codeType, code),
            // Defaults Section
            mimes: (mimes || []).map((option) => ({
                id: option.value as number,
                name: option.label as string,
            })),
            supportedApis: (supportedApis || []).map((option) => ({
                id: option.value as number,
                name: option.label as string,
            })),
            linearity: linearity === LINEARITY_BOTH_ALLOWED.value ? null : { id: linearity as number },
            type: type
                ? { id: type.value as number, name: type.label as string }
                : { id: AD_UNIT_PRE_ROLL_TYPE.value, name: AD_UNIT_PRE_ROLL_TYPE.label },
            startDelay: startDelay ?? DEFAULT_AD_UNIT_START_DELAY,
            isInterstitial,
            iabCategories: (iabCategories || []).map((option) => ({
                id: option.value as number,
            })),
            placement: { id: placement.value as number, name: placement.label as string },
            supportedProtocols: (supportedProtocols || []).map((option) => ({
                id: option.value as number,
                name: option.label as string,
            })),
            minDuration,
            maxDuration,
            minBitrate,
            maxBitrate,
            maxExtended: maxExtended === CUSTOM_MAX_EXTENDED.value ? additionalTime : (maxExtended as number),
            ssaiType:
                ssaiType.value !== NONE_OPTION.value
                    ? { id: ssaiType.value as number, name: ssaiType.label as string }
                    : null,
            isLiveStream,
            skip: skip === UNKNOWN_OPTION.value ? null : (skip as boolean),
            skipafter: skip === YES_OPTION.value ? skipafter : null,
            skipmin: skip === YES_OPTION.value ? skipmin : null,
            multiplicity: { id: multiplicity },
            // Ad Pod Section
            maxPodSeconds: maxPodSeconds || null,
            maxAdsPerPod: maxAdsPerPod || null,
            adPodFillMode: adPodFillMode ? { id: adPodFillMode } : null,
            podEnforcement: typeof podEnforcement === "number" ? { id: podEnforcement } : null,
            separationGroups: (separationGroups || []).map(({ value, label, iabCategories, industries }) => ({
                id: value,
                name: label,
                iabCategories,
                industries,
            })),
            // Playlist Configuration Section
            maxAdsPerAdvertiser: maxAdsPerAdvertiser ?? null,
            playlistDefinitionMode: playlistDefinitionMode ? { id: playlistDefinitionMode } : null,
            //Ad Break Rules Section
            adBreakRules: parseAdBreakRulesToApi(adBreakRulesPre, adBreakRulesMid, adBreakRulesPost),
            cuepoints: cuepoints ? cuepoints : null,
            //Detailed Configuration Section
            lsaEnabled: lsaEnabled || DEFAULT_LIVE_STREAM_ACCELERATION,
            lsaWindowSizeSeconds: lsaWindowSizeSeconds || null,
            unwrapVast: unwrapVast || DEFAULT_UNWRAP_VAST,
            impressionWaitTime:
                impressionWaitTime.value === OFF_WAIT_TIME.value
                    ? null
                    : { id: impressionWaitTime.value as number, name: impressionWaitTime.label as string },
            adomainFreqCappingType: {
                id: adomainFreqCappingType.value as number,
                name: adomainFreqCappingType.label as string,
            },
            adomainFreqCappings: adomainFreqCappings ? parseFreqCapsToApi(adomainFreqCappings) : null,
            // Custom Pixels
            thirdPartyPixels: (thirdPartyPixels || []).map((pixel) => ({
                id: pixel.value as number,
                name: pixel.label as string,
            })),
        },
    };
    if (isTremorUser) {
        Object.assign(payload.supply, {
            //Detailed Configuration Section Internal Only
            enableNoAdCookieSync,
        });
        if (isPubAcctMgr || isSysAdmin)
            //Detailed Configuration Section Internal Only
            Object.assign(payload.adUnit, {
                publisherReauction: publisherReauction === NOT_SPECIFIED_OPTION.value ? null : publisherReauction,
            });
    }

    if (hasInternalAccess)
        Object.assign(payload.brand, {
            // Labels
            internalLabelValues: (internalLabelValues || []).map(({ value, seatLabel, labelValue }) => ({
                id: value,
                value: labelValue,
                label: seatLabel,
            })),
            distributionGroupLabelValues: (distributionGroupLabelValues || []).map(
                ({ value, seatLabel, labelValue }) => ({
                    id: value,
                    value: labelValue,
                    label: seatLabel,
                })
            ),
        });
    return payload;
};
