import { FC } from "react";
import { LabeledValue } from "antd/lib/select";
import { CountrySelect } from "@app/features/inventory/components/Fields";
import { FloorValue } from "@app/features/inventory/components/FloorsTable";

interface FloorTableCountryProps {
    record: FloorValue;
    editingIds: Set<number | null>;
    handleChangeCountry: (id: number | null, value: LabeledValue | null) => void;
}

export const FloorTableCountry: FC<FloorTableCountryProps> = ({ record, editingIds, handleChangeCountry }) => {
    const onChange = (value: LabeledValue): void => handleChangeCountry(record.id, value);

    if (editingIds.has(record.id)) return <CountrySelect value={record.country} onChange={onChange} />;

    return <>{record?.country?.label || "All Geos"}</>;
};
