import { FC, useState } from "react";
import { Last24Hours } from "./Last24Hours";
import { LiveStats } from "./LiveStats";
import { PerformanceSectionContext } from "@app/features/inventory/InventoryHealth/InventoryHealthPage/PerformanceSection/usePerformanceSectionContext";

interface Props {
    dealId: number;
    sourceCurrencyCode: string | undefined;
    isInsideDrawer: boolean;
}

export const Overview: FC<Props> = ({ dealId, sourceCurrencyCode, isInsideDrawer }) => {
    const [isLiveActive, setIsLiveActive] = useState(false);
    const handlePerformanceTypeSwitch = (isLiveActive: boolean) => {
        setIsLiveActive(isLiveActive);
    };

    return (
        <PerformanceSectionContext.Provider value={{ isLiveActive, handlePerformanceTypeSwitch }}>
            {!isLiveActive && (
                <Last24Hours
                    isInsideDrawer={isInsideDrawer}
                    dealId={dealId}
                    sourceCurrencyCode={sourceCurrencyCode}
                    handlePerformanceTypeSwitch={handlePerformanceTypeSwitch}
                />
            )}
            {isLiveActive && (
                <LiveStats
                    isInsideDrawer={isInsideDrawer}
                    dealId={dealId}
                    sourceCurrencyCode={sourceCurrencyCode}
                    handlePerformanceTypeSwitch={handlePerformanceTypeSwitch}
                />
            )}
        </PerformanceSectionContext.Provider>
    );
};
