import { Space } from "antd";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { ClipBoardButton } from "../ClipBoardButton";
import { ArrowRightOutlined } from "@ant-design/icons";
import { LocationDescriptor } from "history";
import { string } from "@rubicon/utils";
import { brandCobalt } from "@rubicon/antd-components";

interface Props {
    entity: string;
    linkTo: LocationDescriptor<unknown>;
    copyUrl: string;
}

export const GoToEntityLink: FC<Props> = ({ entity, linkTo, copyUrl }) => {
    const [isClipButtonVisible, setIsClipButtonVisible] = useState(false);

    return (
        <Space
            onMouseEnter={() => setIsClipButtonVisible(true)}
            onMouseLeave={() => setIsClipButtonVisible(false)}
            align="baseline"
        >
            <Link
                data-sdet={`go-to-${string.kebabCase(entity)}-link`}
                to={linkTo}
                style={{
                    color: brandCobalt,
                    fontWeight: "normal",
                }}
            >
                Go to {entity} <ArrowRightOutlined />
            </Link>
            {isClipButtonVisible && (
                <ClipBoardButton
                    copyText={copyUrl}
                    tooltipProps={{
                        title: `Copy ${entity} Link`,
                        placement: "bottom",
                    }}
                    type="link"
                    sdet={`go-to-${string.kebabCase(entity)}-clipboard-btn`}
                />
            )}
        </Space>
    );
};
