import { FC } from "react";
import { SideDrawer } from "../SideDrawer";
import { Notification, useMarkAllPlatformNotificationAccessedMutation } from "@app/core/services";
import { NotificationAlert } from "./NotificationAlert";
import { Space, Typography, Row, Col } from "antd";

interface Props {
    notifications: Notification[];
    isOpen: boolean;
    handleClose: () => void;
    handleTypeClose: () => void;
}

export const NotificationsDrawer: FC<Props> = ({ notifications, handleClose, ...restProps }) => {
    const [markAllPlatformNotificationAccessed] = useMarkAllPlatformNotificationAccessedMutation();

    return (
        <SideDrawer {...restProps} handleClose={handleClose}>
            <Space direction="vertical" style={{ width: "96%" }}>
                <Row>
                    <Col>
                        <Typography.Title level={5}>Notifications</Typography.Title>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Space size="small">
                            <Typography.Link
                                disabled={!notifications.length}
                                onClick={markAllPlatformNotificationAccessed}
                            >
                                Clear All
                            </Typography.Link>
                        </Space>
                    </Col>
                </Row>
                {notifications.map((notification) => {
                    return (
                        <NotificationAlert
                            key={notification.id}
                            notification={notification}
                            onClickAction={handleClose}
                        />
                    );
                })}
                {!notifications.length && (
                    <>
                        <Row justify="center">
                            <Col>
                                <Typography.Title level={5}>You&apos;re all caught up!</Typography.Title>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col>
                                <Typography.Paragraph>There are no unread notifications</Typography.Paragraph>
                            </Col>
                        </Row>
                    </>
                )}
            </Space>
        </SideDrawer>
    );
};
