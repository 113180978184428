import { useSeatAuthContext } from "@app/core/auth";
import { useParams } from "react-router-dom";
import { useGetAdSourceTypesCountsQuery } from "@app/core/services";
import { AdSourceTypeIds } from "@app/features/seatAdSources/constants";

const DEFAULT_OPEN_AUCTION_LIMIT = 2;

export const useAdSourceTypeLimits = (types) => {
    const { context } = useSeatAuthContext();
    const { seatId } = useParams<{ seatId: string }>();
    const { data: currentCountForType } = useGetAdSourceTypesCountsQuery(seatId);

    const curSeatLimits = context?.adSourceCountLimits || [];
    const seatLimitsMap = {};

    for (let i = 0; i < curSeatLimits.length; i++) {
        seatLimitsMap[curSeatLimits[i].adSourceType.id] = curSeatLimits[i].maxAllowed;
    }

    if (!seatLimitsMap.hasOwnProperty(AdSourceTypeIds.OPEN_AUCTION)) {
        seatLimitsMap[AdSourceTypeIds.OPEN_AUCTION] = DEFAULT_OPEN_AUCTION_LIMIT;
    }

    currentCountForType?.forEach((currentCountForType) => {
        if (seatLimitsMap.hasOwnProperty(currentCountForType?.typeId)) {
            if (currentCountForType?.count >= seatLimitsMap[currentCountForType.typeId]) {
                seatLimitsMap[currentCountForType.typeId] = null;
            }
        }
    });

    return types?.filter((type) => {
        if (seatLimitsMap[type.id] || !seatLimitsMap.hasOwnProperty(type.id)) {
            return true;
        }
        return false;
    });
};
