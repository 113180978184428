import { FC } from "react";
import { Col, Row, Typography } from "antd";
import { DayPartTarget } from "@app/core/services/console";
import { Conditions, TargetingDimensionTypeLabels, TimeZoneModeIds } from "@app/features/targeting/constants";
import { DayPartTargetsTargetingDimension, UsedDimensions } from "@app/features/targeting/types";
import { ConditionSelect } from "../../ConditionSelect";
import { MoreMenu } from "../../MoreMenu";
import { DayPartingDrawerButton } from "./DayPartingDrawerButton";

interface Props {
    dimension: DayPartTargetsTargetingDimension;
    onChangeCondition: (value: Conditions) => void;
    onChangeDimensionValues: (value: DayPartTarget[]) => void;
    onChangeTimeZoneMode: (dimensiontimeZoneMode: TimeZoneModeIds) => void;
    onClickDelete: () => void;
    onClickDownload: () => void;
    onClickDuplicate: () => void;
    usedDimensions: UsedDimensions;
}

export const DayPartingDimensionRow: FC<Props> = ({
    dimension,
    onChangeCondition,
    onChangeDimensionValues,
    onChangeTimeZoneMode,
    onClickDelete,
    onClickDownload,
    onClickDuplicate,
    usedDimensions,
}) => {
    return (
        <Row gutter={8}>
            <Col flex="150px">
                <Typography.Text strong>{TargetingDimensionTypeLabels.DayPartTargets}</Typography.Text>
            </Col>
            <Col flex="128px">
                <ConditionSelect
                    onChange={onChangeCondition}
                    isExcludesDisabled={
                        dimension.condition !== Conditions.Excludes && usedDimensions.dayPartTargets.excludes
                    }
                    isIncludesDisabled={
                        dimension.condition !== Conditions.Includes && usedDimensions.dayPartTargets.includes
                    }
                    value={dimension.condition}
                />
            </Col>
            <Col flex="1" style={{ display: "flex", justifyContent: "space-between" }}>
                <DayPartingDrawerButton
                    onChange={onChangeDimensionValues}
                    onChangeTimeZoneMode={onChangeTimeZoneMode}
                    timeZoneMode={dimension.timeZoneMode}
                    title="Dayparting Targeting"
                    values={dimension.values}
                />
                <MoreMenu
                    onClickDelete={onClickDelete}
                    onClickDownload={onClickDownload}
                    onClickDuplicate={onClickDuplicate}
                    isDuplicateDisabled={
                        usedDimensions.dayPartTargets.includes && usedDimensions.dayPartTargets.excludes
                    }
                />
            </Col>
        </Row>
    );
};
