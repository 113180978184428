import { useSeatAuthContext } from "@app/core/auth";
import { PageLoader } from "@rubicon/antd-components";
import { Button, Col, Row, Space, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useBrandById } from "../useBrandById";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { InventoryFloorHelp } from "../InventoryFloorHelp";
import { useBrandFloorsTable } from "./useBrandFloorsTable";
import { FloorsTable } from "../FloorsTable";
import { useSeatTree } from "../SeatTree/useSeatTree";
import { InventoryFloorsBreadcrumb } from "../InventoryFloorsBreadcrumb";

const InventoryFloorsPublisherBrandPage: FC = () => {
    const { context } = useSeatAuthContext();
    const { expandKeys, setSelectedKeys } = useSeatTree();
    const { brand, isFetching: isFetchingBrand } = useBrandById();
    const { floors, isFetching: isFetchingFloors } = useBrandFloorsTable();
    const [isCreating, setIsCreating] = useState(false);

    useEffect(() => {
        if (context && brand) {
            expandKeys([`seat-${context.id}`, `publisher-${brand.publisher.id}`, `brand-${brand.id}`]);
            setSelectedKeys([`brand-${brand.id}`]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context, brand]);

    if (isFetchingBrand || !context || !brand || !floors) {
        return <PageLoader />;
    }

    const handleAddFloor = () => {
        setIsCreating(true);
    };

    const handleCancelAddingFloor = () => {
        setIsCreating(false);
    };

    const items = [
        { path: ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_HIERARCHICAL(context.id), title: context.name },
        {
            path: ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_PUBLISHER(context.id, brand.publisher.id),
            title: brand.publisher.name,
        },
        { title: brand.name },
    ];

    return (
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Row justify="space-between">
                <Col>
                    <Row>
                        <Space align="baseline">
                            <Typography.Title level={5}>{brand.entityType} Floors: </Typography.Title>
                            <InventoryFloorsBreadcrumb items={items} />
                        </Space>
                    </Row>
                    <Typography.Text>Pricing rules for this brand below: </Typography.Text>
                </Col>
                <Col style={{ alignSelf: "flex-end" }}>
                    <Space size="large">
                        <InventoryFloorHelp />
                        <Button type="primary" onClick={handleAddFloor}>
                            Add Floor
                        </Button>
                    </Space>
                </Col>
            </Row>
            <FloorsTable
                data={floors}
                isFetching={isFetchingFloors}
                isCreating={isCreating}
                onCancelCreating={handleCancelAddingFloor}
                sourceSelf="Brand"
            />
        </Space>
    );
};

export default InventoryFloorsPublisherBrandPage;
