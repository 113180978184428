import React from "react";
import { StyledReactLink } from "@app/core/components";
import { Breadcrumb, BreadcrumbProps, Typography } from "antd";

interface Props {
    items: BreadcrumbProps["items"];
}

export const InventoryFloorsBreadcrumb = ({ items }: Props) => {
    return (
        <Breadcrumb
            items={items}
            itemRender={(currentRoute) =>
                currentRoute.path ? (
                    <StyledReactLink to={currentRoute.path}>{currentRoute.title}</StyledReactLink>
                ) : (
                    <Typography.Title level={5}>{currentRoute.title}</Typography.Title>
                )
            }
        />
    );
};
