import { MarketplaceInfo } from "@app/core/services";
import { ReadonlyControl } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { Col, Form, Input, Typography } from "antd";
import { FC } from "react";
import { DEAL_NAME_MAX_LENGTH } from "@app/features/deals/constants";

export const DealNameInput: FC = () => {
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const isReadonly = dealObjectMode === "view";
    return (
        <Col sm={12}>
            <Form.Item
                data-sdet="deal-name-field"
                rules={[
                    {
                        required: !isReadonly,
                        message: "Deal Name is required",
                    },
                    {
                        max: DEAL_NAME_MAX_LENGTH,
                        message: `Deal Name cannot be longer than ${DEAL_NAME_MAX_LENGTH} characters`,
                    },
                ]}
                label="Deal Name"
                name={["dealUnderEdit", "name"]}
            >
                {isReadonly ? (
                    <ReadonlyControl<MarketplaceInfo["deal"]["name"]>
                        getLabel={(value) => <Typography.Text>{value}</Typography.Text>}
                    />
                ) : (
                    <Input placeholder="Deal Name" />
                )}
            </Form.Item>
        </Col>
    );
};
