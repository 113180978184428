import { FC } from "react";
import { Select } from "antd";
import { TagRender } from "@app/core/components/TagRender";
import {
    Conditions,
    POD_SLOT_POSITION_OPTIONS,
    TargetingDimensionTypes,
    TargetingDimensionTypeLabels,
} from "../../constants";
import { isAddDisabled, validatePositions } from "../../helpers";
import { PodSlotPositionTargetsTargetingDimension, UsedDimensions } from "../../types";
import { ConditionSelect } from "../ConditionSelect";
import { MoreMenu } from "../MoreMenu";
import { DimensionRow } from "./DimensionRow";

interface Props {
    dimension: PodSlotPositionTargetsTargetingDimension;
    onChangeCondition: (value: Conditions) => void;
    onChangeDimensionValues: (value: number[]) => void;
    onClickDelete: () => void;
    onClickDownload: () => void;
    onClickDuplicate: () => void;
    usedDimensions: UsedDimensions;
}

export const PodSlotPositionTargetsDimensionRow: FC<Props> = ({
    dimension,
    onChangeCondition,
    onChangeDimensionValues,
    onClickDelete,
    onClickDownload,
    onClickDuplicate,
    usedDimensions,
}) => {
    return (
        <DimensionRow
            label={TargetingDimensionTypeLabels.PodSlotPositionTargets}
            condition={<ConditionSelect onChange={onChangeCondition} value={dimension.condition} />}
            value={
                <Select
                    allowClear
                    mode="tags"
                    onChange={(vals) => onChangeDimensionValues(validatePositions(vals))}
                    optionFilterProp="label"
                    options={POD_SLOT_POSITION_OPTIONS}
                    placeholder="Select Values"
                    style={{ width: "100%" }}
                    tagRender={TagRender}
                    value={dimension.values}
                />
            }
            menu={
                <MoreMenu
                    onClickDelete={onClickDelete}
                    onClickDownload={onClickDownload}
                    onClickDuplicate={onClickDuplicate}
                    isDuplicateDisabled={isAddDisabled(TargetingDimensionTypes.PodSlotPositionTargets, usedDimensions)}
                />
            }
        />
    );
};
