import { FC } from "react";
import { Layout } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { Footer } from "@app/core/components";
import { PageLoader } from "@rubicon/antd-components";
import { useGetAdUnitQuery } from "@app/core/services";
import { useHistory, useParams } from "react-router-dom";
import { AdUnitForm } from "@app/features/inventory/HierarchyForms/AdUnitForm";
import { SupplyTrafficAlertBanner } from "@app/features/inventory/components/SupplyTrafficAlertBanner";
import { AdUnitFormInstance } from "@app/features/inventory/HierarchyForms/AdUnitForm/AdUnitFormInstance";
import { useScrollToTopOnMount } from "@app/core/components/hooks";

export const AdUnitEditPage: FC = () => {
    useScrollToTopOnMount();
    const { adUnitId } = useParams<{ adUnitId: string }>();
    const { data: adUnit, isFetching } = useGetAdUnitQuery(Number(adUnitId));
    const history = useHistory();

    if (isFetching || !adUnit) return <PageLoader />;

    return (
        <>
            <PageHeader title={`Edit Ad Unit: ${adUnit.name}`} subTitle={adUnit.code} onBack={() => history.goBack()} />
            <SupplyTrafficAlertBanner sourceSelf="Ad Unit" />
            <Layout.Content>
                <AdUnitFormInstance mode="edit" adUnit={adUnit}>
                    <AdUnitForm />
                </AdUnitFormInstance>
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
