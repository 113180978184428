import React, { FC } from "react";
import { useUserAccess } from "@app/core/auth";
import { useAppDispatch } from "@app/core/store";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ItemType } from "antd/lib/menu/interface";
import { Space, Button, Dropdown, Tooltip } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { BrandSupply, BulkOperationSourceEntity, ChangeLogEntityType } from "@app/core/services";
import { InventoryDetailsDrawerType } from "@app/features/inventory/DetailsDrawer/reducer";
import { TagsOutlined, EditOutlined, MoreOutlined, MonitorOutlined, PlusOutlined } from "@ant-design/icons";
import { openChangelog, setChangelogEntity } from "@app/features/inventory/InventoryChangelog";
import { BulkOperationsUrlState } from "@app/core/components";

interface InventorySupplyTableActionsProps {
    supply: BrandSupply;
    publisherId: number | null;
    brandId: number | null;
}

export const InventorySupplyTableActions: FC<InventorySupplyTableActionsProps> = ({ supply, brandId, publisherId }) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { hasSeatWriteAccess } = useUserAccess();
    const { seatId } = useParams<{ seatId: string }>();

    const handleTagParams = (): void => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_TAG_PARAMS(
                false,
                seatId,
                InventoryDetailsDrawerType.SUPPLY,
                publisherId as number,
                brandId as number,
                supply.id
            )
        );
    };

    const handleChangelog = (): void => {
        dispatch(openChangelog());
        dispatch(setChangelogEntity({ entity: supply, entityType: ChangeLogEntityType.Supply }));
    };

    const handleCreativeReview = (): void => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_CREATIVE_REVIEW(
                false,
                seatId,
                InventoryDetailsDrawerType.SUPPLY,
                supply.id
            )
        );
    };
    const handleDiagnostics = () => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_DIAGNOSTICS(
                false,
                seatId,
                InventoryDetailsDrawerType.SUPPLY,
                publisherId as number,
                brandId as number,
                supply.id
            )
        );
    };
    const floorAnalysis = (): void =>
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_FLOOR_ANALYSIS(
                false,
                seatId,
                InventoryDetailsDrawerType.SUPPLY,
                publisherId as number,
                brandId as number,
                supply.id
            )
        );

    const handleCopySupply = (): void => {
        if (publisherId && brandId && supply) {
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_COPY(
                    seatId,
                    publisherId,
                    brandId,
                    supply.id
                )
            );
        }
    };

    const handleCopyWithChildren = (): void => {
        const routeState: BulkOperationsUrlState = {
            type: "copy",
            entityType: supply.entityType.toLowerCase() as BulkOperationSourceEntity,
            entityId: String(supply.id),
            withChildren: true,
        };

        history.push({
            pathname: ROUTE_FORMATTERS.SEAT_CONTROLS_BULK_OPERATIONS_CREATE(Number(seatId)),
            state: routeState,
        });
    };

    const handleEditSupply = (): void => {
        if (publisherId && brandId && supply) {
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_EDIT(
                    seatId,
                    publisherId,
                    brandId,
                    supply.id
                )
            );
        }
    };

    const handleAddFloor = (): void => history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_SUPPLY_FLOORS(seatId, supply.id));

    const handleCreateAdUnit = (): void => {
        if (publisherId && brandId && supply)
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_CREATE(
                    seatId,
                    publisherId,
                    brandId,
                    supply.id
                )
            );
    };

    const items: ItemType[] = [
        {
            label: "Creative Review",
            key: "creativeReview",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleCreativeReview();
            },
        },
        {
            label: "Floor Analysis",
            key: "floorAnalysis",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                floorAnalysis();
            },
        },
        hasSeatWriteAccess && {
            label: "Change History",
            key: "changeHistory",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleChangelog();
            },
        },
    ].filter(Boolean) as ItemType[];

    const createActionsItems: ItemType[] = [
        hasSeatWriteAccess && {
            label: "Copy Supply Only",
            key: "copySupply",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleCopySupply();
            },
        },
        hasSeatWriteAccess && {
            label: "Copy Supply + Children",
            key: "copySupplyChildren",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleCopyWithChildren();
            },
        },
        hasSeatWriteAccess && {
            label: "Add Ad Unit for this Supply",
            key: "addAdUnitForSupply",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleCreateAdUnit();
            },
        },
        hasSeatWriteAccess && {
            label: "Add a Floor",
            key: "createFloor",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleAddFloor();
            },
        },
    ].filter(Boolean) as ItemType[];

    return (
        <Space style={{ width: "100%" }}>
            <Button.Group>
                <Tooltip title="Inbound Request Params">
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleTagParams();
                        }}
                        icon={<TagsOutlined />}
                        size="small"
                    />
                </Tooltip>
                <Tooltip title="Diagnostic">
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDiagnostics();
                        }}
                        icon={<MonitorOutlined />}
                        size="small"
                    />
                </Tooltip>
                {hasSeatWriteAccess && (
                    <Tooltip title="Edit">
                        <Button
                            icon={<EditOutlined />}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleEditSupply();
                            }}
                            size="small"
                        />
                    </Tooltip>
                )}
                {hasSeatWriteAccess && (
                    <Tooltip title="Create">
                        <Dropdown placement="bottomLeft" trigger={["click"]} menu={{ items: createActionsItems }}>
                            <Button
                                icon={<PlusOutlined rotate={90} />}
                                size="small"
                                onClick={(e) => e.stopPropagation()}
                            />
                        </Dropdown>
                    </Tooltip>
                )}
                <Dropdown menu={{ items }} placement="bottomLeft" trigger={["click"]}>
                    <Button icon={<MoreOutlined rotate={90} />} size="small" onClick={(e) => e.stopPropagation()} />
                </Dropdown>
            </Button.Group>
        </Space>
    );
};
