import { AdSource, AdSourceKey } from "@app/core/services";
import {
    AdServingEligibleAdSourceTypeIds,
    DealPerformanceEligibleAdSourceTypeIds,
    PrebidAdSourceTypeIds,
} from "./constants";

export const isAdSourceAdServingEligible = (adSourceTypeId: number): boolean =>
    AdServingEligibleAdSourceTypeIds.includes(adSourceTypeId);

export const isAdSourceDealPerformanceEligible = (adSourceTypeId: number): boolean =>
    DealPerformanceEligibleAdSourceTypeIds.includes(adSourceTypeId);

export const isAdSourcePrebid = (adSourceTypeId: number): boolean => PrebidAdSourceTypeIds.includes(adSourceTypeId);

export const isAdSourceCopyEligible = (canEditSeat: boolean, isReadonly?: boolean): boolean =>
    canEditSeat && !isReadonly;

export const isAdSourceEditEligible = (canEditSeat: boolean, isReadonly?: boolean): boolean =>
    canEditSeat && !isReadonly;

export const isAdSourceFieldReadOnly = (adSource: AdSource | undefined, adSourceKey: AdSourceKey): boolean =>
    isAdSourceFieldReadOnlyByKeys(adSource?.readOnlyFields, adSourceKey);

export const isAdSourceFieldReadOnlyByKeys = (
    readOnlyAdSourceFields: AdSourceKey[] | null | undefined,
    adSourceKey: AdSourceKey
): boolean => Boolean(readOnlyAdSourceFields?.includes(adSourceKey));
