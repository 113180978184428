import { Button, Tooltip } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { FC } from "react";
import { useClipBoardButton } from "@app/core/components/ClipBoardButton/useClipBoardButton";

interface Props {
    text: string;
    notificationEntity: string;
}

export const TextWithCopy: FC<Props> = ({ notificationEntity, text }) => {
    const { handleCopy } = useClipBoardButton();

    return (
        <>
            {text}{" "}
            <Tooltip title="Copy">
                <Button
                    style={{ border: "none", background: "none", boxShadow: "none" }}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleCopy(text, notificationEntity);
                    }}
                    icon={<CopyOutlined style={{ fontSize: "16x" }} />}
                />
            </Tooltip>
        </>
    );
};
