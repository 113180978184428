import { ArrowRightOutlined } from "@ant-design/icons";
import { brandCobalt } from "@rubicon/antd-components";
import { Flex, TooltipProps } from "antd";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { ClipBoardButton } from "../ClipBoardButton";

interface Props {
    targetPath: string;
    goToText: string;
    tooltipProps?: TooltipProps;
}

export const GoToLink: FC<Props> = ({ targetPath, goToText, tooltipProps }) => {
    const [isClipButtonVisible, setIsClipButtonVisible] = useState(false);
    const { origin, pathname } = window.location;
    const copyUrl = `${origin}${pathname}#${targetPath}`;

    const tooltip: TooltipProps = Object.assign({}, { placement: "bottom" }, tooltipProps);

    return (
        <Flex
            data-sdet="go-to-link"
            gap={0}
            align="baseline"
            onMouseEnter={() => setIsClipButtonVisible(true)}
            onMouseLeave={() => setIsClipButtonVisible(false)}
        >
            <Link to={targetPath} style={{ color: brandCobalt, fontWeight: "normal" }}>
                {goToText} <ArrowRightOutlined />
            </Link>

            {isClipButtonVisible && <ClipBoardButton copyText={copyUrl} tooltipProps={tooltip} type="link" />}
        </Flex>
    );
};
