import { ROUTES } from "@app/core/routing";
import { Route, Switch } from "react-router-dom";
import { BrandSafetyDetailsDrawer } from "./BrandSafetyDetailsDrawer";

export const BrandSafetyDetailsDrawerSwtich = () => {
    return (
        <Switch>
            <Route
                exact
                path={[
                    ROUTES.SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_DETAILS,
                    ROUTES.SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_DEFINITION,
                ]}
            >
                <BrandSafetyDetailsDrawer />
            </Route>
        </Switch>
    );
};
