import { FC } from "react";
import { Col, Row, Select } from "antd";
import { LabeledValue } from "antd/lib/select";
import {
    Conditions,
    GroupConditions,
    SegmentRuleModes,
    SEGMENT_RULE_MODE_OPTIONS,
    SegmentRuleIdentifierOperators,
    TargetingDimensionTypeLabels,
    TargetingDimensionTypes,
} from "../../../constants";
import { isAddDisabled } from "../../../helpers";
import { SegmentRuleGroup, SegmentRulesTargetingDimension, UsedDimensions } from "../../../types";
import { ConditionSelect } from "../../ConditionSelect";
import { MoreMenu } from "../../MoreMenu";
import { DimensionRow } from "../DimensionRow";
import { AdvancedRuleDrawerButton } from "./AdvancedRuleDrawerButton";
import { getSegmentRuleGroupString, isSegmentRuleGroupEmpty } from "./helpers";
import { SegmentsSelect } from "./SegmentsSelect";

interface Props {
    dimension: SegmentRulesTargetingDimension;
    onChangeCondition: (value: Conditions) => void;
    onChangeDimensionValues: (value: LabeledValue[]) => void;
    onChangeMode: (value: SegmentRuleModes) => void;
    onChangeOperator: (path: number[], value: SegmentRuleIdentifierOperators) => void;
    onChangeRuleCondition: (path: number[], value: GroupConditions) => void;
    onChangeRuleGroup: (group: SegmentRuleGroup) => void;
    onChangeRuleValue: (path: number[], value: LabeledValue) => void;
    onClickAddRule: (path: number[]) => void;
    onClickAddRuleGroup: (path: number[]) => void;
    onClickDelete: () => void;
    onClickDeleteRule: (path: number[]) => void;
    onClickDownload: () => void;
    onClickDuplicate: () => void;
    usedDimensions: UsedDimensions;
    usedDimensionsAll: UsedDimensions[];
}

export const SegmentRuleDimensionRow: FC<Props> = ({
    dimension,
    onChangeCondition,
    onChangeDimensionValues,
    onChangeMode,
    onChangeOperator,
    onChangeRuleCondition,
    onChangeRuleGroup,
    onChangeRuleValue,
    onClickAddRule,
    onClickAddRuleGroup,
    onClickDelete,
    onClickDeleteRule,
    onClickDownload,
    onClickDuplicate,
    usedDimensions,
    usedDimensionsAll,
}) => {
    return (
        <DimensionRow
            label={TargetingDimensionTypeLabels.SegmentRules}
            condition={
                <ConditionSelect
                    onChange={onChangeCondition}
                    value={dimension.condition}
                    isExcludesDisabled={
                        dimension.condition !== Conditions.Excludes && usedDimensions.segmentRules.excludes
                    }
                    isIncludesDisabled={
                        dimension.condition !== Conditions.Includes && usedDimensions.segmentRules.includes
                    }
                />
            }
            mode={
                <Select
                    onChange={onChangeMode}
                    options={SEGMENT_RULE_MODE_OPTIONS}
                    value={dimension.mode}
                    style={{ width: "100%" }}
                />
            }
            value={
                dimension.mode === SegmentRuleModes.Simple ? (
                    <SegmentsSelect onChange={onChangeDimensionValues} value={dimension.values} />
                ) : (
                    !isSegmentRuleGroupEmpty(dimension.ruleGroup) && (
                        <Row>
                            <Col style={{ padding: "4px" }}>{getSegmentRuleGroupString(dimension.ruleGroup)}</Col>
                        </Row>
                    )
                )
            }
            menu={
                <>
                    {dimension.mode === SegmentRuleModes.Advanced && (
                        <AdvancedRuleDrawerButton
                            dimension={dimension}
                            onChangeCondition={onChangeRuleCondition}
                            onChangeOperator={onChangeOperator}
                            onChangeRuleGroup={onChangeRuleGroup}
                            onChangeValue={onChangeRuleValue}
                            onClickAddRule={onClickAddRule}
                            onClickAddRuleGroup={onClickAddRuleGroup}
                            onClickDeleteRule={onClickDeleteRule}
                        />
                    )}
                    <MoreMenu
                        onClickDownload={onClickDownload}
                        onClickDelete={onClickDelete}
                        onClickDuplicate={onClickDuplicate}
                        isDuplicateDisabled={isAddDisabled(
                            TargetingDimensionTypes.SegmentRules,
                            usedDimensions,
                            usedDimensionsAll
                        )}
                    />
                </>
            }
        />
    );
};
