import { FC } from "react";
import { Seat } from "@app/core/services";
import { Form, Card, Row, Col } from "antd";
import { useUserAccess } from "@app/core/auth";
import { getYesNoLabel } from "@app/core/utils/labels";
import { INVENTORY_CARD_BODY_STYLE } from "@app/features/inventory/constants";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import {
    ExternalDataStreams,
    AuthorizedMarketplaces,
} from "@app/features/inventory/InventorySeat/InventorySeatEditPage/AdvancedFeaturesForm/AdvancedFeaturesFormFields";
import { INVENTORY_FORM_FIELDS, SEAT_DETAILS_SECTION_COL_SIZES } from "@app/features/inventory/InventorySeat/constants";

interface DemandManagementSectionProps {
    seat: Seat;
    edit?: boolean;
}

const INTERNAL_COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 };

export const DemandManagementSection: FC<DemandManagementSectionProps> = ({ seat, edit = false }) => {
    const { isSysAdmin, isPubAcctMgr, isTremorUser } = useUserAccess();
    const hasInternalAccess: boolean = isSysAdmin || isPubAcctMgr || isTremorUser;
    return (
        <Card bordered={false}>
            <Row gutter={16}>
                <Col {...SEAT_DETAILS_SECTION_COL_SIZES}>
                    <AuthorizedMarketplaces seat={seat} edit={edit} />
                </Col>
                {hasInternalAccess && (
                    <Col {...SEAT_DETAILS_SECTION_COL_SIZES}>
                        <Card type="inner" title="Internal Only" bodyStyle={INVENTORY_CARD_BODY_STYLE}>
                            {(isSysAdmin || isPubAcctMgr) && (
                                <Row gutter={16}>
                                    <Col {...INTERNAL_COL_SIZES}>
                                        <Form.Item
                                            label={INVENTORY_FORM_FIELDS.ALLOW_FORWARD_MARKET_DEAL_BLOCKS.label}
                                            name={INVENTORY_FORM_FIELDS.ALLOW_FORWARD_MARKET_DEAL_BLOCKS.name}
                                        >
                                            {edit ? (
                                                <BooleanRadioGroup />
                                            ) : (
                                                getYesNoLabel(seat.allowForwardMarketDealBlocks)
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col {...INTERNAL_COL_SIZES}>
                                        <Form.Item
                                            label={INVENTORY_FORM_FIELDS.ALLOW_HIGH_CPM.label}
                                            name={INVENTORY_FORM_FIELDS.ALLOW_HIGH_CPM.name}
                                        >
                                            {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.doohEnabled)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                            <Row gutter={16}>
                                {isSysAdmin && (
                                    <Col {...INTERNAL_COL_SIZES}>
                                        <Form.Item
                                            label={INVENTORY_FORM_FIELDS.ASAP_FEATHERING.label}
                                            name={INVENTORY_FORM_FIELDS.ASAP_FEATHERING.name}
                                        >
                                            {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.asapFeatheringEnabled)}
                                        </Form.Item>
                                    </Col>
                                )}
                                {isTremorUser && (
                                    <Col {...INTERNAL_COL_SIZES}>
                                        <Form.Item
                                            label={INVENTORY_FORM_FIELDS.ENABLE_DEMAND_ACQUISITION_COST.label}
                                            name={INVENTORY_FORM_FIELDS.ENABLE_DEMAND_ACQUISITION_COST.name}
                                        >
                                            {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.dacEnabled)}
                                        </Form.Item>
                                    </Col>
                                )}
                            </Row>
                            {(isSysAdmin || isPubAcctMgr) && (
                                <Row gutter={16}>
                                    <Col {...INTERNAL_COL_SIZES}>
                                        <Form.Item
                                            label={INVENTORY_FORM_FIELDS.ALLOW_TAKE_RATE_TRANSPARENCY.label}
                                            name={INVENTORY_FORM_FIELDS.ALLOW_TAKE_RATE_TRANSPARENCY.name}
                                        >
                                            {edit ? (
                                                <BooleanRadioGroup />
                                            ) : (
                                                getYesNoLabel(seat.allowTakeRateTransparency)
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                            {isSysAdmin && <ExternalDataStreams seat={seat} edit={edit} />}
                        </Card>
                    </Col>
                )}
            </Row>
        </Card>
    );
};
