import { LineOptions } from "@antv/g2plot";
import { HEIGHT, Y_AXIS_MIN, Y_AXIS_TICK_COUNT } from "../utils";
import { FlatLineSeriesData, LineChartOptions } from "./types";
import { formatNumber } from "@rubicon/utils";

export const RequiredFields = {
    xField: "xValue",
    yField: "yValue",
    seriesField: "series",
} as const;

export const RequiredOptions: Pick<LineOptions, "data" | "xField" | "yField" | "seriesField"> = {
    data: [],
    ...RequiredFields,
};

export const DefaultOptions: LineChartOptions = {
    height: HEIGHT,
    legend: false,
    yAxis: {
        min: Y_AXIS_MIN,
        tickCount: Y_AXIS_TICK_COUNT,
        label: {
            formatter: formatNumber.asAbbreviated,
        },
    },
    tooltip: {
        formatter: (seriesData: FlatLineSeriesData) => ({
            name: seriesData.series,
            value: formatNumber.asAbbreviated(seriesData.yValue),
        }),
    },
} as const;
