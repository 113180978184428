import { FC } from "react";
import { Card, Col, Form, Row } from "antd";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import { PUBLISHER_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

const COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 6, xxl: 5 };
export const AdvancedFeaturesSection: FC = () => {
    return (
        <Card bordered={false}>
            <Row gutter={16}>
                <Col {...COL_SIZES}>
                    <Form.Item {...PUBLISHER_FORM_FIELDS.EMIT_PRICEING_IN_VAST}>
                        <BooleanRadioGroup />
                    </Form.Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Form.Item {...PUBLISHER_FORM_FIELDS.ALLOW_VAST_EXTENSION_DSP}>
                        <BooleanRadioGroup />
                    </Form.Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Form.Item {...PUBLISHER_FORM_FIELDS.ALLOW_VAST_EXTENSION_CRID}>
                        <BooleanRadioGroup />
                    </Form.Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Form.Item {...PUBLISHER_FORM_FIELDS.ALLOW_VAST_EXTENSION_ADOMAIN}>
                        <BooleanRadioGroup />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col {...COL_SIZES}>
                    <Form.Item {...PUBLISHER_FORM_FIELDS.ALLOW_VAST_EXTENSION_BRAND}>
                        <BooleanRadioGroup />
                    </Form.Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Form.Item {...PUBLISHER_FORM_FIELDS.ALLOW_VAST_EXTENSION_BID_PRICE}>
                        <BooleanRadioGroup />
                    </Form.Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Form.Item {...PUBLISHER_FORM_FIELDS.ALLOW_AD_SOURCE_VAST_EXTENSION}>
                        <BooleanRadioGroup />
                    </Form.Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Form.Item {...PUBLISHER_FORM_FIELDS.ALLOW_VAST_EXTENSION_IAB_CATEGORY}>
                        <BooleanRadioGroup />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col {...COL_SIZES}>
                    <Form.Item {...PUBLISHER_FORM_FIELDS.ALLOW_AD_SOURCE_NAME_VAST_EXTENSION}>
                        <BooleanRadioGroup />
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    );
};
