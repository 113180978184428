import { CurrencyConversionModes } from "@app/core/clients/console";
import { Currency, CurrencyConversion } from "@app/core/services";
import { AdStat } from "@app/features/adStats";
import {
    getErrors,
    getFallbacks,
    getFills,
    getImpressions,
    getIsAtRisk,
    getNtime,
    getOTime,
    getSkips,
    getTimePerTry,
    getTotalCurrencyStat,
    getTotalNetRev,
    getTries,
} from "@app/features/adStats/mapper";
import { AdServingStat } from "@app/features/adStats/adSourceAdServing";
import {
    getAdServingResultCode,
    getAdServingResultLegendText,
    getAdServingResultName,
    getAdServingNTime,
    getAdServingOTime,
    getAdServingOpportunities,
    getAdServingResultsByCodeKey,
    getAdServingFloors,
    getAdServingFrequencyCapping,
    getAdServingPacing,
    getAdServingChosen,
    getAdServingBidSuccess,
    getAdServingCreativeSuccess,
} from "@app/features/adStats/adSourceAdServing/mapper";
import { AdSourceTypeIds } from "../constants";
import { AdSourceServingStat, AdSourceStat } from "./types";

export const DefaultAdSourceStat = (): AdSourceStat => ({
    errors: 0,
    fallbacks: 0,
    fills: 0,
    impressions: 0,
    isAtRisk: false,
    netRevenue: 0,
    nTime: 0,
    oTime: 0,
    skips: 0,
    time: 0,
    tries: 0,
    currencyCode: undefined,
    getTagRevenue: (_: number) => 0,
});

export const mapAdStatToAdSourceStat = (
    adStat: AdStat,
    preferredCurrency: Currency | undefined,
    currencyConversions: CurrencyConversion[],
    currencyConversionMode: CurrencyConversionModes,
    isTremorUser: boolean,
    getCurrencyById: (id: number) => Currency | undefined
): AdSourceStat | undefined => {
    if (!adStat) {
        return;
    }

    const adStatCurrencyId = Object.keys(adStat.statsByCurrency)?.[0];
    const adStatCurrency = adStatCurrencyId ? getCurrencyById(Number(adStatCurrencyId)) : undefined;
    const targetCurrencyCode =
        currencyConversionMode === CurrencyConversionModes.ORIGINATING_CURRENCY
            ? adStatCurrency?.code
            : preferredCurrency?.code;

    return {
        errors: getErrors(adStat, isTremorUser),
        fallbacks: getFallbacks(adStat),
        fills: getFills(adStat, preferredCurrency, currencyConversionMode),
        impressions: getImpressions(adStat, preferredCurrency, currencyConversionMode),
        isAtRisk: getIsAtRisk(adStat, preferredCurrency, currencyConversionMode),
        netRevenue: getTotalNetRev(adStat, preferredCurrency, currencyConversions, currencyConversionMode),
        nTime: getNtime(adStat),
        oTime: getOTime(adStat),
        skips: getSkips(adStat),
        time: getTimePerTry(adStat),
        tries: getTries(adStat),
        currencyCode: targetCurrencyCode,
        getTagRevenue: (adSourceTypeId: number) => {
            if (adSourceTypeId === AdSourceTypeIds.SERVER_SIDE_DYNAMIC_PRICE) {
                const tagRevenue = getTotalCurrencyStat(
                    "trev",
                    adStat,
                    preferredCurrency,
                    currencyConversions,
                    currencyConversionMode
                );
                const dynamicFeeCost = getTotalCurrencyStat(
                    "dFeeCost",
                    adStat,
                    preferredCurrency,
                    currencyConversions,
                    currencyConversionMode
                );
                return tagRevenue - dynamicFeeCost;
            }
            if (
                [
                    AdSourceTypeIds.FALLBACK_TAG,
                    AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED,
                    AdSourceTypeIds.SERVER_SIDE_TAG_NON_GUARANTEED,
                    AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED,
                    AdSourceTypeIds.SERVER_SIDE_DYNAMIC_PRICE,
                    AdSourceTypeIds.PRE_BID_AD_SOURCE,
                ].includes(adSourceTypeId)
            ) {
                const tagRevenue = getTotalCurrencyStat(
                    "trev",
                    adStat,
                    preferredCurrency,
                    currencyConversions,
                    currencyConversionMode
                );
                const fee = getTotalCurrencyStat(
                    "fee",
                    adStat,
                    preferredCurrency,
                    currencyConversions,
                    currencyConversionMode
                );
                return tagRevenue - fee;
            }
            return 0;
        },
    };
};

export const mapAdServingStatToAdSourceAdServingStat = (adServingStat: AdServingStat): AdSourceServingStat => {
    return {
        nTime: getAdServingNTime(adServingStat),
        oTime: getAdServingOTime(adServingStat),
        opportunities: getAdServingOpportunities(adServingStat),
        floors: getAdServingFloors(adServingStat),
        frequencyCapping: getAdServingFrequencyCapping(adServingStat),
        pacing: getAdServingPacing(adServingStat),
        chosen: getAdServingChosen(adServingStat),
        bidSuccess: getAdServingBidSuccess(adServingStat),
        creativeSuccess: getAdServingCreativeSuccess(adServingStat),
        results: Object.keys(adServingStat.results)
            .map((resultKey) => {
                const resultCodeKey = Number(resultKey);
                return {
                    code: getAdServingResultCode(resultCodeKey),
                    name: getAdServingResultName(resultCodeKey),
                    legendText: getAdServingResultLegendText(resultCodeKey),
                    events: getAdServingResultsByCodeKey(adServingStat, resultCodeKey),
                };
            })
            .filter((result) => result.code !== ""),
    };
};

export const mapAdServingStatToAdSourceAdServingStats = (
    adServingStats: AdServingStat[]
): AdSourceServingStat[] | undefined => {
    if (!adServingStats) {
        return;
    }

    return adServingStats
        .map((stat) => mapAdServingStatToAdSourceAdServingStat(stat))
        .filter((stat) => stat) as AdSourceServingStat[];
};

export const getAdSourceStatUseRate = (stat: AdSourceStat): number => stat.impressions / stat.fills;
