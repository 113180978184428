import { FC } from "react";
import { Form } from "antd";
import { TagRender } from "@app/core/components";
import { IabCategory } from "@app/core/services";
import { formatCategoryLabel } from "@app/features/targeting";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAdSourceIabCategoriesField } from "./useAdSourceIabCategoriesField";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { SelectAllButtonDropdownRender } from "@app/core/components/SelectAllButtonDropdownRender";

export const AdSourceIABCategoriesField: FC = () => {
    const { value, onChange, options, isFetching, disabled, isShowingEmptyWarning, onSearch, onBlur, search } =
        useAdSourceIabCategoriesField();
    return (
        <Form.Item
            label="IAB Categories"
            data-sdet="ad-source-iab_categories-field"
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_IAB_CATEGORIES}
            extra={isShowingEmptyWarning ? AD_SOURCE_FIELDS.IAB_BLOCKED_CATEGORIES.extra : undefined}
        >
            <SelectWithValue<IabCategory>
                allowClear
                disabled={disabled}
                dropdownRender={(menu) => (
                    <SelectAllButtonDropdownRender<IabCategory>
                        menu={menu}
                        value={value}
                        search={search}
                        options={options}
                        onChange={onChange}
                        getPrimaryKey={(v) => v.id}
                    />
                )}
                showSearch
                value={value}
                mode="multiple"
                onBlur={onBlur}
                options={options}
                onChange={onChange}
                onSearch={onSearch}
                fieldAsValue={["id"]}
                loading={isFetching}
                searchValue={search}
                tagRender={TagRender}
                optionFilterProp="search"
                placeholder="Select IAB Categories…"
                fieldAsLabel={(category) => formatCategoryLabel(category)}
            />
        </Form.Item>
    );
};
