import { FC } from "react";
import { Form, Radio } from "antd";
import { SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useSupplyType } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/GeneralSection/Fields/SupplyType/useSupplyType";

export const SupplyType: FC = () => {
    const { options, onChange, isEditMode, supply } = useSupplyType();
    return (
        <Form.Item name={SUPPLY_FORM_FIELDS.TYPE.name} label={SUPPLY_FORM_FIELDS.TYPE.label}>
            {isEditMode ? supply?.type?.name : <Radio.Group size="large" options={options} onChange={onChange} />}
        </Form.Item>
    );
};
