import { FC } from "react";
import { Button, Tooltip } from "antd";
import { LineChartOutlined } from "@ant-design/icons";
import { useSeatAdSourcesDetailsDrawer } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/useSeatAdSourcesDetailsDrawer";
import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";

interface Props {
    adSourceId: number;
}

export const SeatAdSourcesPerformanceActionButton: FC<Props> = ({ adSourceId }) => {
    const { openSeatAdSourcesDetailsDrawer } = useSeatAdSourcesDetailsDrawer();
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();

    return (
        <Tooltip title="Performance Charts">
            <Button
                data-sdet="seat-ad-sources-performance-action-button"
                onClick={() => {
                    openSeatAdSourcesDetailsDrawer(adSourceId);
                    history.push(ROUTE_FORMATTERS.SEAT_AD_SOURCES_DETAILS_DRAWER(seatId, adSourceId));
                }}
                icon={<LineChartOutlined />}
            />
        </Tooltip>
    );
};
