import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LabeledValue } from "antd/lib/select";
import { RootState } from "@app/core/store";

interface SeatAdSourcesPerfomanceAdStatsTableFilters {
    seatAdSourcesDeal: LabeledValue | undefined;
    options: LabeledValue[] | undefined;
}

export interface SeatAdSourcesPerfomanceAdStatsState {
    filters: SeatAdSourcesPerfomanceAdStatsTableFilters;
}

export const initialState: SeatAdSourcesPerfomanceAdStatsState = {
    filters: {
        options: undefined,
        seatAdSourcesDeal: undefined,
    },
};

const seatAdSourcesPerfomanceAdStatsSlice = createSlice({
    name: "seatAdSourcesPerformanceAdStats",
    initialState,
    reducers: {
        setFiltersSeatAdSourcesDeal: (state, action: PayloadAction<LabeledValue>) => {
            state.filters.seatAdSourcesDeal = action.payload;
        },
        setAllSeatAdSourcesDealOptions: (state, action: PayloadAction<LabeledValue[]>) => {
            if (!state.filters.seatAdSourcesDeal) {
                state.filters.seatAdSourcesDeal = action.payload[0];
            }
            state.filters.options = action.payload;
        },
        reset: (state) => {
            state.filters = initialState.filters;
        },
    },
});

export const selectSeatAdSourcesPerfomanceAdStatsFilters = (state: RootState) =>
    state.seatAdSourcesPerformanceAdStats.filters;
export const { setFiltersSeatAdSourcesDeal, setAllSeatAdSourcesDealOptions, reset } =
    seatAdSourcesPerfomanceAdStatsSlice.actions;

export const seatAdSourcesPerformanceAdStatsReducer = seatAdSourcesPerfomanceAdStatsSlice.reducer;
