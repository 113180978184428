import { ClipBoardButton, DrawerBreadcrumbHeader, Loading } from "@app/core/components";
import { ArrowRightOutlined } from "@ant-design/icons";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { css } from "@emotion/css";
import { ResizableDrawer, brandCobalt } from "@rubicon/antd-components";
import { Button, Card, Col, Flex, Row, Space, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useUserAccess } from "@app/core/auth";
import { TargetingFormReadOnly } from "../../TargetingForm";
import { TargetingDetailsActions } from "../../TargetingDetailsActions";
import { useGetTargetingAndReferences } from "../../useTargeting";
import { HelpKeysDrawerContent } from "@app/core/components/HelpKeysDrawer";
import { ChangeHistoryTable } from "@app/core/components/ChangeHistoryTable";
import { ChangeLogEntityType } from "@app/core/services";
import { useTargetingDetailsDefinitionDrawer } from "../../TargetingDrawer/useTargetingDefinitionDrawer";
import { useTargetingChangeHistory } from "../../TargetingDrawer/useTargetingChangeHistory";
import { useRedirectErrorTargeting } from "../../useRedirectErrorTargeting";

interface DrawerNavTitleProps {
    name?: string;
    route: string;
    destinationName?: string;
}
export const TargetingDrawerTitle: FC<DrawerNavTitleProps> = ({ name, route, destinationName = "Targeting" }) => {
    const [isClipButtonVisible, setIsClipButtonVisible] = useState(false);
    const { origin, pathname } = window.location;
    const copyUrl = `${origin}${pathname}#${route}`;

    return (
        <Row gutter={[8, 4]} align="bottom">
            {name && (
                <Col>
                    <Typography.Title
                        data-sdet="targeting-drawer-title"
                        ellipsis={{ tooltip: { title: name, placement: "bottom" } }}
                        level={5}
                        style={{
                            marginTop: 0,
                            marginBottom: 0,
                            maxWidth: "18rem",
                        }}
                    >
                        {name}
                    </Typography.Title>
                </Col>
            )}
            <Col>
                <Space
                    onMouseEnter={() => setIsClipButtonVisible(true)}
                    onMouseLeave={() => setIsClipButtonVisible(false)}
                    align="baseline"
                >
                    <Link
                        data-sdet="go-to-full-page-link"
                        to={route}
                        style={{ color: brandCobalt, fontWeight: "normal" }}
                    >
                        Go to {destinationName} <ArrowRightOutlined />
                    </Link>
                    {isClipButtonVisible && (
                        <ClipBoardButton
                            copyText={copyUrl}
                            tooltipProps={{ title: `Copy ${destinationName} Link`, placement: "bottom" }}
                            type="link"
                        />
                    )}
                </Space>
            </Col>
        </Row>
    );
};

interface TargetingDirectTitleProps {
    seatId: string;
    targetingName: string;
    targetingId: number;
    hasDelete: boolean;
    canEditSeat: boolean;
    hasAdminAccess: boolean;
    handleChangeHistory: () => void;
}
const TargetingDirectTitle: FC<TargetingDirectTitleProps> = ({
    seatId,
    hasDelete,
    targetingId,
    canEditSeat,
    hasAdminAccess,
    targetingName,
    handleChangeHistory,
}) => (
    <Row gutter={canEditSeat ? [0, 16] : [0, 0]} style={{ marginRight: "1rem" }}>
        <Col span={24}>
            <TargetingDrawerTitle
                name={targetingName}
                route={ROUTE_FORMATTERS.SEAT_CONTROLS_TARGETING_DETAILS(seatId, targetingId)}
            />
        </Col>
        <Col span={24}>
            <Row justify="space-between">
                {canEditSeat && (
                    <Col>
                        <TargetingDetailsActions
                            canEdit={canEditSeat}
                            hasDelete={hasDelete}
                            targeting={{ id: targetingId, name: targetingName }}
                        />
                    </Col>
                )}
                {hasAdminAccess && (
                    <Col>
                        <Button data-sdet="change-history" onClick={handleChangeHistory}>
                            Change History
                        </Button>
                    </Col>
                )}
            </Row>
        </Col>
    </Row>
);

export const TargetingDetailsDrawer = () => {
    const [open, setOpen] = useState<boolean>(false);
    const { showDefinition, definitions, title, handleShowDefinition, handleCloseDefinition } =
        useTargetingDetailsDefinitionDrawer();
    const { showChangeHistory, handleShowChangeHistory, handleCloseChangeHistory } = useTargetingChangeHistory();
    const history = useHistory();

    const { seatId, targetingId } = useParams<{ seatId: string; targetingId: string }>();
    const { canEditSeat, hasAdminAccess } = useUserAccess();
    const { targeting, references, hasReferences, isLoading, error } = useGetTargetingAndReferences();

    useRedirectErrorTargeting({ error, targetingId, seatId });

    const handleCloseDrawer = () => {
        setOpen(false);
        handleCloseDefinition();
        handleCloseChangeHistory();
    };

    const handleAfterOpenChange = (open: boolean) =>
        !open && history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TARGETING(seatId));

    useEffect(() => setOpen(true), []);

    return (
        <ResizableDrawer
            open={open}
            onClose={handleCloseDrawer}
            afterOpenChange={handleAfterOpenChange}
            title={
                targeting ? (
                    showDefinition ? (
                        <DrawerBreadcrumbHeader
                            onBack={handleCloseDefinition}
                            parentLabel={targeting.name as string}
                            childLabel={title}
                        />
                    ) : showChangeHistory ? (
                        <Flex gap="small">
                            <DrawerBreadcrumbHeader
                                onBack={handleCloseChangeHistory}
                                parentLabel={targeting?.name as string}
                                childLabel="Change History"
                            />

                            <TargetingDrawerTitle
                                route={ROUTE_FORMATTERS.SEAT_CONTROLS_TARGETING_CHANGE_HISTORY(seatId, targetingId)}
                                destinationName="Change History"
                            />
                        </Flex>
                    ) : (
                        <TargetingDirectTitle
                            seatId={seatId}
                            hasDelete={!hasReferences && !isLoading}
                            targetingName={targeting?.name as string}
                            targetingId={targeting?.id}
                            canEditSeat={canEditSeat}
                            hasAdminAccess={hasAdminAccess}
                            handleChangeHistory={handleShowChangeHistory}
                        />
                    )
                ) : (
                    ""
                )
            }
            width="50%"
            placement="right"
            className={css`
                .ant-drawer-body {
                    padding: 0;
                }
                .ant-drawer-close {
                    position: absolute;
                    top: 20px;
                    right: 0;
                }
            `}
        >
            {isLoading ? (
                <Loading />
            ) : targeting && showDefinition ? (
                <HelpKeysDrawerContent definitionsFields={definitions} />
            ) : showChangeHistory ? (
                <Card
                    type="inner"
                    bordered={false}
                    className={css`
                        &.ant-card {
                            box-shadow: none;
                        }
                    `}
                >
                    <ChangeHistoryTable entityId={targeting?.id} entityType={ChangeLogEntityType.Targeting} />
                </Card>
            ) : (
                <TargetingFormReadOnly
                    targeting={targeting}
                    references={references}
                    handleDefinition={handleShowDefinition}
                />
            )}
        </ResizableDrawer>
    );
};
