import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { Form } from "antd";
import { targetDateValidator } from "./validator";
import { useEffect } from "react";
import moment from "moment-timezone";
import { isTargetDateEligible } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/pacingUtils";
import { usePacingFormData } from "../usePacingFormData";

const { useWatch, useFormInstance } = Form;

export const useTargetDatePicker = () => {
    const { validateFields } = useFormInstance();
    const { adSourceTypeId, pacingTypeId } = usePacingFormData();
    const startDate = useWatch<moment.Moment>(AD_SOURCE_FIELDS.START_DATE.name);
    const endDate = useWatch<moment.Moment>(AD_SOURCE_FIELDS.END_DATE.name);

    useEffect(() => {
        validateFields([AD_SOURCE_FIELDS.TARGET_DATE.name]);
    }, [startDate, endDate, validateFields]);

    const isVisible = isTargetDateEligible(adSourceTypeId, pacingTypeId);
    const validator = targetDateValidator(startDate, endDate);

    return { isVisible, validator };
};
