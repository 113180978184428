import { FC } from "react";
import { Select } from "antd";
import { LabeledValue } from "antd/lib/select";
import { durationTargetToLabelValue } from "@app/features/targeting/helpers";
import { useMaxDurationTargetSelect } from "./useMaxDurationTargetSelect";

interface Props {
    onChange: (value: LabeledValue) => void;
    value: { id: number; name: string } | undefined | null;
}

export const MaxDurationTargetSelect: FC<Props> = ({ onChange, value }) => {
    const { isLoading, options } = useMaxDurationTargetSelect();
    const [labeledValue] = value ? durationTargetToLabelValue([value]) : [null];

    return (
        <Select
            data-sdet="SecondaryConditionSelect"
            loading={isLoading}
            onChange={onChange}
            options={options}
            placeholder="Select Value"
            style={{ width: "100%" }}
            labelInValue
            optionFilterProp="label"
            value={labeledValue}
        />
    );
};
