import { FC } from "react";
import { Select } from "antd";
import { LabeledValue } from "antd/lib/select";
import { TagRender } from "@app/core/components/TagRender";
import { useDimensionsSelect } from "./useDimensionsSelect";

interface Props {
    onChange: (value: LabeledValue[]) => void;
    value: LabeledValue[];
}

export const DimensionsSelect: FC<Props> = ({ onChange, value }) => {
    const { isLoading, options, handleChangeSearch, search } = useDimensionsSelect();

    return (
        <Select
            allowClear
            data-sdet="MIMETypeDimensionSelect"
            filterOption={false}
            loading={isLoading}
            mode="multiple"
            onBlur={() => handleChangeSearch("")}
            onChange={onChange}
            onSearch={handleChangeSearch}
            options={options}
            placeholder="Select Values"
            searchValue={search}
            style={{ width: "100%" }}
            labelInValue
            tagRender={TagRender}
            value={value}
        />
    );
};
