import { FC } from "react";
import { Form, Input } from "antd";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { isDailyImpressionCapEligible } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/pacingUtils";
import { usePacingFormData } from "../usePacingFormData";

const { label, name } = AD_SOURCE_FIELDS.DAILY_IMPRESSION_CAP;

export const DailyImpressionInput: FC = () => {
    const { adSourceTypeId, pacingTypeId, isDailyImpressionEnabled } = usePacingFormData();

    const isVisible = isDailyImpressionCapEligible(adSourceTypeId, pacingTypeId, isDailyImpressionEnabled);

    if (!isVisible) {
        return null;
    }

    return (
        <Form.Item
            name={name}
            label={label}
            data-sdet={`test-${name}-field`}
            rules={[{ required: isVisible, message: `${label} is required` }]}
            hidden={!isVisible}
            {...VERTICAL_LAYOUT_FIX}
        >
            <Input type="number" data-sdet={`test-${name}-input`} placeholder={`Enter ${label}`} />
        </Form.Item>
    );
};
