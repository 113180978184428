import { LabeledValue } from "antd/lib/select";
import { FC } from "react";
import { useHistoricalChartAdSourceFilter } from "./useHistoricalChartAdSourceFilter";
import { Form, Select } from "antd";
import { TagRender } from "@app/core/components/TagRender";
import { DealListSource } from "@app/core/services";

interface HistoricalChartAdSourceFilterProps {
    adSources?: DealListSource[];
}

export const HistoricalChartAdSourceFilter: FC<HistoricalChartAdSourceFilterProps> = ({ adSources }) => {
    const { options, handleChange, value } = useHistoricalChartAdSourceFilter(adSources);

    return (
        <Form.Item
            data-sdet={"deal-chart-historical-ad-source-filter"}
            label={"Ad Sources configured with this Deal"}
            style={{ marginBottom: 0 }}
        >
            <Select
                allowClear={false}
                onChange={(_, option) => handleChange(option as LabeledValue)}
                options={options}
                style={{ width: "100%" }}
                value={value}
                tagRender={TagRender}
            />
        </Form.Item>
    );
};
