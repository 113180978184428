import { FC } from "react";
import { Card, Col, Row } from "antd";
import { TWO_COL_SIZES } from "@app/features/inventory/constants";
import { InternalOnly } from "@app/features/inventory/components/InternalOnly";
import {
    Categories,
    AdvertisersAsAllowList,
} from "@app/features/inventory/HierarchyForms/BrandForm/BrandFormSections/SupplyDetailsSection/Fields";
import { useBrandFormInstance } from "@app/features/inventory/HierarchyForms/BrandForm/BrandFormInstance";

export const SupplyDetailsSection: FC = () => {
    const { seat } = useBrandFormInstance();
    const isAdvertisersAsAllowListShown = Boolean(seat?.enableAdvertisersWhitelist);
    return (
        <Card bordered={false}>
            <Row>
                <Col {...TWO_COL_SIZES}>
                    <Categories />
                </Col>
                <Col hidden={!isAdvertisersAsAllowListShown} {...TWO_COL_SIZES}>
                    <InternalOnly>
                        <AdvertisersAsAllowList />
                    </InternalOnly>
                </Col>
            </Row>
        </Card>
    );
};
