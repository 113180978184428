import { FC } from "react";
import { Col, Form, Row, Tooltip, Typography } from "antd";
import { ExclamationCircleFilled, InfoCircleFilled } from "@ant-design/icons";
import { gold6, blue6 } from "@rubicon/antd-components";
import { EnrichmentCost } from "../utils";
import { AD_SOURCE_FIELDS, DEFAULT_ENRICHMENT_COST_CURRENCY_CODE } from "@app/features/seatAdSources/constants";

interface Props {
    adSourceName: string;
    cpm: string;
    currencyCode: string;
    dealName: string;
    demandAcquisitionCost: string;
    demandAcquisitionCostVal: number;
    demandAcquisitionCostModelId: number | null;
    finalEc: string;
    finalEcVal: EnrichmentCost;
    finalizedCpm: string;
    isDemandAcquisitionCostEnabled: boolean;
    isFixedCost: boolean;
    isRangedCost: boolean;
}

const getFinalizedLabel = (currencyCode: string) => {
    const roundingWarningNode = currencyCode !== DEFAULT_ENRICHMENT_COST_CURRENCY_CODE && (
        <Tooltip
            title="Value is approximate due to currency conversion rates and may not sum
        exactly due to rounding"
            placement="bottom"
        >
            <ExclamationCircleFilled style={{ color: gold6 }} />
        </Tooltip>
    );

    return <span>Finalized CPM {roundingWarningNode}</span>;
};

const getDealCostLabel = (isFixedCost: boolean) => {
    if (isFixedCost) {
        return "Fixed CPM";
    }
    return "Floor CPM";
};

const getEnrichmentCostLabel = (isRangedCost: boolean) => {
    return (
        <span>
            Final Audience Enrichment Cost{" "}
            <Tooltip
                title={
                    isRangedCost
                        ? "The final Audience Enrichment Cost CPM range is the lowest cost targeted segment CPM to the highest cost targeted segment CPM across the Ad Source and Deal"
                        : "The final Audience Enrichment Cost CPM is the highest cost segment CPM targeted across the Ad Source and Deal"
                }
                placement="bottom"
            >
                <InfoCircleFilled style={{ color: blue6 }} />
            </Tooltip>
        </span>
    );
};

export const FinalizedCpmSection: FC<Props> = ({
    adSourceName,
    cpm,
    currencyCode,
    dealName,
    demandAcquisitionCost,
    finalEc,
    finalizedCpm,
    isDemandAcquisitionCostEnabled,
    isFixedCost,
    isRangedCost,
}) => {
    return (
        <div style={{ padding: "16px" }}>
            <Row gutter={[0, 16]}>
                <Col span={24}>
                    <Row align="middle" gutter={[8, 0]}>
                        <Col>
                            <div
                                style={{
                                    padding: "8px",
                                    border: "1px solid rgb(134, 90, 236)",
                                    borderRadius: "2px",
                                }}
                            >
                                <Form.Item label={getFinalizedLabel(currencyCode)} style={{ marginBottom: 0 }}>
                                    <Typography.Text>{finalizedCpm}</Typography.Text>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col>
                            <Typography.Text>=</Typography.Text>
                        </Col>
                        <Col>
                            <div
                                style={{
                                    padding: "8px",
                                    border: "1px solid #d9d9d9",
                                    borderRadius: "2px",
                                }}
                            >
                                <Form.Item label={getDealCostLabel(isFixedCost)} style={{ marginBottom: 0 }}>
                                    <Typography.Text>{cpm}</Typography.Text>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col>
                            <Typography.Text>+</Typography.Text>
                        </Col>
                        <Col>
                            <div
                                style={{
                                    padding: "8px",
                                    border: "1px solid #d9d9d9",
                                    borderRadius: "2px",
                                }}
                            >
                                <Form.Item label={getEnrichmentCostLabel(isRangedCost)} style={{ marginBottom: 0 }}>
                                    <Typography.Text>{finalEc}</Typography.Text>
                                </Form.Item>
                            </div>
                        </Col>
                        {isDemandAcquisitionCostEnabled && (
                            <>
                                <Col>
                                    <Typography.Text>+</Typography.Text>
                                </Col>
                                <Col>
                                    <div
                                        style={{
                                            padding: "8px",
                                            border: "1px solid #d9d9d9",
                                            borderRadius: "2px",
                                        }}
                                    >
                                        <Form.Item
                                            label={AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.label}
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Typography.Text>{demandAcquisitionCost}</Typography.Text>
                                        </Form.Item>
                                    </div>
                                </Col>
                            </>
                        )}
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={[16, 16]}>
                        <Col>
                            <Form.Item label="Ad Source" style={{ marginBottom: 0 }}>
                                <Typography.Text>{adSourceName}</Typography.Text>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label="Deal" style={{ marginBottom: 0 }}>
                                <Typography.Text>{dealName}</Typography.Text>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
