import { useAppDispatch } from "@app/core/store";
import { useHistory, useLocation } from "react-router-dom";
import { resetForm } from "../reducer";
import { uri } from "@rubicon/utils";
import { EDIT_MODE, MACROS_PICKER } from "../constants";
import { useState } from "react";

export const useTagParamsChangesDiscardModal = () => {
    const { search } = useLocation();
    const { push } = useHistory();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const handleModalOpen = () => setIsOpen(true);

    const handleOk = () => {
        setIsOpen(false);
        const nextSearchNoEditModeKey = uri.setSearchParam(search, EDIT_MODE.key, "");
        const nextSearch = uri.setSearchParam(nextSearchNoEditModeKey, MACROS_PICKER.key, "");

        push({ search: nextSearch });
        dispatch(resetForm());
    };
    const handleCancel = () => setIsOpen(false);

    return {
        handleCancel,
        handleOk,
        handleModalOpen,
        open: isOpen,
    };
};
