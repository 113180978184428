import { useEffect, useMemo, useState } from "react";
import { notification, RadioChangeEvent } from "antd";
import { LabeledValue } from "antd/lib/select";
import { DualAxesConfig } from "@ant-design/plots";
import { useTimingStatsChartConfig } from "./useTimingStatsChartConfig";
import {
    TimingStatsEntityTypes,
    useCreateTimingStatsAdUnitSnapshotMutation,
    useGetAdUnitQuery,
    useGetTimingStatsQuery,
} from "@app/core/services";

export const enum TimingStatsChartType {
    Linear = "linear",
    Logarithmic = "log",
}

export const GRAPHIC_TYPE = [
    {
        label: "Linear",
        value: TimingStatsChartType.Linear,
    },
    {
        label: "Logarithmic",
        value: TimingStatsChartType.Logarithmic,
    },
];

const AD_UNIT_MULTIPLICITIES_IDS = {
    SINGLE: 1,
    AD_POD: 2,
    PLAYLIST: 3,
    UDE: 4,
};

const AD_UNIT_MULTIPLICITIES = [
    { id: AD_UNIT_MULTIPLICITIES_IDS.SINGLE, label: "Ad Unit Timing Data" },
    { id: AD_UNIT_MULTIPLICITIES_IDS.AD_POD, label: "Pod Ad Unit Timing Data" },
    { id: AD_UNIT_MULTIPLICITIES_IDS.PLAYLIST, label: "Playlist Ad Unit Timing Data" },
    { id: AD_UNIT_MULTIPLICITIES_IDS.UDE, label: "Ext. Demand Fetch Time by UDE Ad Unit" },
];

interface UseTimingStats {
    chartType: TimingStatsChartType;
    handleChangeChartType: (e: RadioChangeEvent) => void;
    eventsOptions: LabeledValue[];
    regionOptions: LabeledValue[];
    region?: LabeledValue;
    multiplicityId: number;
    handleChangeRegion: (value: LabeledValue) => void;
    handleRefresh: () => void;
    handleCreateSnapshot: () => void;
    config: DualAxesConfig;
    isLoadingTimingStats: boolean;
    isCreatingSnapshot: boolean;
}

export const useTimingStats = (id: number): UseTimingStats => {
    const [isCreatingSnapshot, setIsCreatingSnapshot] = useState<boolean>(false);
    const [chartType, setChartType] = useState<TimingStatsChartType>(TimingStatsChartType.Linear);
    const [region, setRegion] = useState<LabeledValue>();

    const [createSnapshot] = useCreateTimingStatsAdUnitSnapshotMutation();
    const { data, refetch: adUnitRefetch } = useGetAdUnitQuery(id);
    const multiplicityId = useMemo(() => data?.multiplicity.id, [data]) as number;
    const {
        data: timingStatsData,
        refetch: timingStatsRefetch,
        isLoading: isLoadingTimingStats,
    } = useGetTimingStatsQuery(
        { entityId: id, entityType: TimingStatsEntityTypes.AdUnit, multiplicity: multiplicityId },
        { skip: !id || !data }
    );
    const { config } = useTimingStatsChartConfig(chartType, region, timingStatsData);

    const eventsOptions = useMemo(
        () =>
            (AD_UNIT_MULTIPLICITIES.filter(({ id }) => id === multiplicityId) || []).map(({ id, label }) => ({
                label,
                value: id,
            })),
        [multiplicityId]
    );

    const regionOptions = useMemo(() => {
        const keys = Object.keys(timingStatsData?.data || {});
        return keys.map((key) => ({ label: key.charAt(0).toUpperCase() + key.slice(1), value: key }));
    }, [timingStatsData]);

    const handleChangeChartType = (e: RadioChangeEvent) => {
        setChartType(e.target.value);
    };

    const handleChangeRegion = (value: LabeledValue) => {
        setRegion(value);
    };

    const handleRefresh = () => {
        adUnitRefetch();
        timingStatsRefetch();
    };

    const handleCreateSnapshot = async () => {
        setIsCreatingSnapshot(true);
        try {
            await createSnapshot({
                id,
                multiplicity: Number(multiplicityId),
            });
            notification.success({ message: "Snapshot was created" });
            setIsCreatingSnapshot(false);
        } catch (error) {
            notification.error({ message: "Snapshot error" });
            setIsCreatingSnapshot(false);
        }
    };

    useEffect(() => {
        if (regionOptions?.length) {
            setRegion(regionOptions[0]);
        }
    }, [regionOptions]);

    return {
        chartType,
        handleChangeChartType,
        eventsOptions,
        regionOptions,
        region,
        multiplicityId,
        handleChangeRegion,
        handleRefresh,
        handleCreateSnapshot,
        config,
        isLoadingTimingStats,
        isCreatingSnapshot,
    };
};
