import { FC, useMemo, useState } from "react";
import { Select } from "antd";
import { LabeledValue } from "antd/lib/select";
import { TagRender } from "@app/core/components/TagRender";
import { useGetContentMetadataContentLengthsQuery } from "@app/core/services/console";
import { Conditions, TargetingDimensionTypes, TargetingDimensionTypeLabels } from "../../constants";
import { contentLengthsToLabelValue, isAddDisabled } from "../../helpers";
import { ContentLengthsTargetingDimension, UsedDimensions } from "../../types";
import { ConditionSelect } from "../ConditionSelect";
import { MoreMenu } from "../MoreMenu";
import { DimensionRow } from "./DimensionRow";
import { SelectAllButtonDropdownRender } from "@app/core/components/SelectAllButtonDropdownRender";

interface Props {
    dimension: ContentLengthsTargetingDimension;
    onChangeCondition: (value: Conditions) => void;
    onChangeDimensionValues: (value: LabeledValue[]) => void;
    onClickDelete: () => void;
    onClickDownload: () => void;
    onClickDuplicate: () => void;
    usedDimensions: UsedDimensions;
}

export const ContentLengthsTargetsDimensionRow: FC<Props> = ({
    dimension,
    onChangeCondition,
    onChangeDimensionValues,
    onClickDelete,
    onClickDownload,
    onClickDuplicate,
    usedDimensions,
}) => {
    const [search, setSearch] = useState("");
    const { data, isFetching } = useGetContentMetadataContentLengthsQuery();
    const options = useMemo(
        () =>
            contentLengthsToLabelValue(data || []).filter((option) =>
                (option.label as string).toLowerCase().includes(search.toLowerCase())
            ),
        [data, search]
    );
    return (
        <DimensionRow
            label={TargetingDimensionTypeLabels.ContentLengths}
            condition={<ConditionSelect onChange={onChangeCondition} value={dimension.condition} />}
            value={
                <Select
                    allowClear
                    labelInValue
                    data-sdet="ContentLengthDimensionSelect"
                    dropdownRender={(menu) => (
                        <SelectAllButtonDropdownRender<LabeledValue>
                            getPrimaryKey={(v) => v.value}
                            menu={menu}
                            onChange={onChangeDimensionValues}
                            options={options}
                            search={search}
                            value={dimension.values}
                        />
                    )}
                    filterOption={false}
                    loading={isFetching}
                    mode="multiple"
                    onBlur={() => setSearch("")}
                    onChange={onChangeDimensionValues}
                    onSearch={setSearch}
                    options={options}
                    placeholder="Select Values"
                    searchValue={search}
                    style={{ width: "100%" }}
                    tagRender={TagRender}
                    value={dimension.values}
                />
            }
            menu={
                <MoreMenu
                    onClickDelete={onClickDelete}
                    onClickDownload={onClickDownload}
                    onClickDuplicate={onClickDuplicate}
                    isDuplicateDisabled={isAddDisabled(TargetingDimensionTypes.ContentLengths, usedDimensions)}
                />
            }
        />
    );
};
