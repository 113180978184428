import { useGetSeatSelfQuery } from "@app/core/services";
import { Form, FormInstance } from "antd";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BULK_OPERATION_FORM_ITEMS } from "../../../constants";

export const useDestinationSeat = (form: FormInstance) => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data: seatSelfData } = useGetSeatSelfQuery(seatId);
    const seat = seatSelfData?.seat;

    const destinationSeat = Form.useWatch(BULK_OPERATION_FORM_ITEMS.DESTINATION_SEAT.name, form);

    useEffect(() => {
        if (!destinationSeat && seat) {
            // set label with value, in case the current seat is not in the default lazy loaded option list
            form.setFieldValue(BULK_OPERATION_FORM_ITEMS.DESTINATION_SEAT.name, {
                value: seat.id,
                label: seat.name,
            });
        }
    }, [seat, destinationSeat, form]);

    return {
        seatId,
        destinationSeat,
    };
};
