import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
    AdUnit,
    Brand,
    BrandChannel,
    BrandSupply,
    ChangeLogEntityType,
    Publisher,
    Seat,
    SeatPublisher,
} from "@app/core/services";

export type ChangelogEntity = Seat | Publisher | SeatPublisher | BrandChannel | Brand | BrandSupply | AdUnit;

export interface InventoryChangelogState {
    isDrawerOpen: boolean;
    entity: ChangelogEntity | null;
    entityType: ChangeLogEntityType | null;
}

const initialState: InventoryChangelogState = {
    isDrawerOpen: false,
    entity: null,
    entityType: null,
};

export const inventoryChangelogSlice = createSlice({
    name: "inventoryChangelog",
    initialState,
    reducers: {
        openChangelog: (state: InventoryChangelogState): void => {
            state.isDrawerOpen = true;
        },
        closeChangelog: (state: InventoryChangelogState): void => {
            state.isDrawerOpen = false;
        },
        setChangelogEntity: (
            state: InventoryChangelogState,
            action: PayloadAction<{ entity: ChangelogEntity; entityType: ChangeLogEntityType }>
        ): void => {
            state.entity = action.payload.entity;
            state.entityType = action.payload.entityType;
        },
    },
});

export const { openChangelog, setChangelogEntity, closeChangelog } = inventoryChangelogSlice.actions;

export const inventoryChangelogReducer = inventoryChangelogSlice.reducer;
