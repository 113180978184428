import { useState } from "react";
import { useParams } from "react-router-dom";
import { message } from "antd";
import { LabeledValue } from "antd/lib/select";
import { BaseQueryFn, MutationDefinition } from "@reduxjs/toolkit/dist/query/react";
import { UseMutation } from "@reduxjs/toolkit/dist/query/react/buildHooks";

export const useBulkTargetingDrawerButton = <T,>(
    onChange: (value: LabeledValue[]) => void,
    values: LabeledValue[],
    useBulkValidationMutation: UseMutation<
        MutationDefinition<{ seatId: number; body: string[] }, BaseQueryFn, string, T[], string>
    >,
    transformToLabeledValues: (input: T[] | null) => LabeledValue[]
) => {
    const { seatId } = useParams<{ seatId: string }>();
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState("");
    const [isLoadingAdd, setIsLoadingAdd] = useState(false);
    const [isLoadingReplace, setIsLoadingReplace] = useState(false);

    const [validateBulkInputs] = useBulkValidationMutation();

    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);
    const handleChangeValue = (v) => setValue(v);

    const handleReplace = async () => {
        setIsLoadingReplace(true);
        try {
            const validBulkInputs = await validateBulkInputs({
                seatId: Number(seatId),
                body: value.split("\n"),
            }).unwrap();
            onChange(transformToLabeledValues(validBulkInputs));
        } catch (_) {
            // TODO: add more robust error handling, if possible
            message.error("Validation failed");
        }
        setValue("");
        setIsOpen(false);
        setIsLoadingReplace(false);
    };

    const handleAdd = async () => {
        setIsLoadingAdd(true);
        const validBulkInputs = await validateBulkInputs({
            seatId: Number(seatId),
            body: value.split("\n"),
        }).unwrap(); // TODO: add error handling here
        const existingValuesByValue = values.reduce<{
            [value: string | number]: LabeledValue;
        }>((byValue, value) => {
            byValue[value.value] = value;
            return byValue;
        }, {});
        const combinedValueByValue = transformToLabeledValues(validBulkInputs).reduce((byValue, value) => {
            byValue[value.value] = value;
            return byValue;
        }, existingValuesByValue);
        const combinedValues = Object.values<LabeledValue>(combinedValueByValue);

        onChange(combinedValues);
        setValue("");
        setIsOpen(false);
        setIsLoadingAdd(false);
    };

    return {
        handleAdd,
        handleChangeValue,
        handleClose,
        handleOpen,
        handleReplace,
        isLoadingAdd,
        isLoadingReplace,
        isOpen,
        value,
    };
};
