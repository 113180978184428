import { FC } from "react";
import { ReportsIFrame } from "./ReportsIFrame";
import { useHose } from "@app/core/services/useHose";
import conf from "@app/core/conf";
import { IFRAMED_APP_ID } from "@app/features/reports/constants";

export const ReportsStreamingIFrame: FC = () => {
    const { init, launchApplication } = useHose(IFRAMED_APP_ID);
    const srcBuilder = (seatId) => `${conf.appsMagniteRoot}/streaming-reporting/#/seats/${seatId}/reports`;
    const onLoad = () => {
        init();
        launchApplication();
    };
    return (
        <ReportsIFrame
            src={srcBuilder}
            title="Reports"
            onLoad={onLoad}
        />
    );
}
