import { MarketplaceInfo } from "@app/core/services";
import { Form } from "antd";
import { currency as currencyUtils } from "@rubicon/utils";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { XANDR_ID } from "@app/core/components/BuyerSeatsSelect";
import { DealTypeIds } from "@app/features/seatAdSources/constants";
import { useSyncedFieldsProducer } from "@app/features/syncedFields";
import { AUCTION_PRICE_CPM_HELP_TEXT } from "@app/features/deals/constants";

const getAddOnContent = (currency) => {
    if (!currency) {
        return [null];
    }
    const info = currencyUtils.getCurrencyInfo(currency);
    return [info?.symbol];
};

export const useCpmRate = () => {
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const currency = Form.useWatch<MarketplaceInfo["deal"]["currencyType"]>(["dealUnderEdit", "currencyType"]);
    const dspNetwork = Form.useWatch(["dealUnderEdit", "network"]);
    const dealType = Form.useWatch(["dealUnderEdit", "dealType"]);
    const isXandr = dspNetwork?.id === XANDR_ID;
    const isAuctionPriceDeal = dealType?.id === DealTypeIds.AUCTION_PRICE;

    const [addOnContent] = getAddOnContent(currency?.code);
    const isReadonly = dealObjectMode === "view";
    const currencyCode = currency?.code;
    const isRequired = !isAuctionPriceDeal || !isXandr;
    const helpText = isAuctionPriceDeal ? AUCTION_PRICE_CPM_HELP_TEXT : undefined;

    const { updateSyncedFields } = useSyncedFieldsProducer();

    const onBlur = (value: string, isDirty: boolean) => {
        if (isDirty) {
            updateSyncedFields({ cpmRate: value });
        }
    };

    return { addOnContent, isReadonly, currencyCode, isRequired, helpText, onBlur };
};
