import { consoleApi, MARKETPLACES_TAG } from "./console";

export interface Marketplace {
    authorizedDmps: {
        code: string;
        config: unknown;
        dmpVendor: { id: number; name: string };
        entityType: "Dmp";
        id: number;
        name: string;
        seatId: number;
    }[];
    description: string;
    id: number;
    name: string;
    orTargetingAllowed: boolean;
    transparency: boolean;
}

export const marketplacesApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getMarketplaces: builder.query<Marketplace[], void>({
            query: () => "marketplaces",
            providesTags: [MARKETPLACES_TAG],
        }),
    }),
    overrideExisting: false,
});

export const { useGetMarketplacesQuery } = marketplacesApi;
