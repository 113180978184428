import { BuyerSeat, BuyerSeatListsBuyerSeatsBody } from "@app/core/services";
import { InitialValuesType } from "./constants";

export const getSelectBuyerSeats = (buyerSeats?: BuyerSeat[]) =>
    buyerSeats?.map(({ code, name, id }) => ({
        key: String(id),
        label: `${code} ${name ? "- " + name : ""}`,
        value: id,
    }));

export const parseValueToApi = (value: InitialValuesType): BuyerSeatListsBuyerSeatsBody => {
    return {
        id: value.id,
        name: value.name?.trim(),
        description: value.description,
        buyerSeats: value.buyerSeats?.map(({ value }) => ({ id: Number(value) })),
    };
};
