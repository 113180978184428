import { ROUTE_FORMATTERS } from "@app/core/routing";
import { SeparationGroup, useGetSeatSeparationGroupByIdQuery } from "@app/core/services";
import { useHistory } from "react-router-dom";

interface UseCompetitiveSeparationGroupDetailsPage {
    name: string;
    isLoading: boolean;
    handleGoBack: () => void;
    postDelete: () => void;
    data: SeparationGroup;
}

export const useCompetitiveSeparationGroupDetailsPage = (
    seatId: string,
    id: string
): UseCompetitiveSeparationGroupDetailsPage => {
    const history = useHistory();
    const { data, isFetching } = useGetSeatSeparationGroupByIdQuery(Number(id));

    const handleGoBack = () => {
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS(seatId));
    };

    const postDelete = () => {
        history.replace(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS(seatId));
    };

    return {
        name: data?.name as string,
        isLoading: isFetching,
        handleGoBack,
        postDelete,
        data: data as SeparationGroup,
    };
};
