import { FC } from "react";
import { Row, Col, Form } from "antd";
import { format, formatNumber } from "@rubicon/utils";
import { AdSourceCurrency } from "./AdSourcePricingFields";
import { AdSourceProperties, BaseOption, Currency, Targeting } from "@app/core/services";
import { DEAL_TYPES, PRICE_MODELS } from "@app/features/deals/constants";
import { calucateBookingBudget } from "@app/features/seatAdSources/SeatAdSourcesForm/utils";
import { useOverrideFloorCpmInputLabel } from "@app/features/seatAdSources/SeatAdSourcesDetails/SeatAdSourcesDetailsCollapses/SeatAdSourcesPricingDetails/Fields/OverrideFloorCpmInput/useOverrideFloorCpmInputLabel";
import { useUserAccess } from "@app/core/auth";

interface Props {
    type: BaseOption;
    targeting: Targeting[];
    floorType: string;
    alwaysSendFloor: boolean;
    currency: Currency;
    auctionType: string;
    priceModel: AdSourceProperties;
    bookingVolume: number | null;
    bookingPrice: number | null;
    resetFrequency?: string;
    tierResetMode?: string;
    tierOverflowAllowed?: boolean | null;
    overrideDynamicFloor: boolean;
    adResponsePriceOverrideType?: string;
}

export const AdSourcePricing: FC<Props> = ({
    type,
    floorType,
    alwaysSendFloor,
    auctionType,
    currency,
    bookingPrice,
    priceModel,
    bookingVolume,
    overrideDynamicFloor,
    adResponsePriceOverrideType,
}) => {
    const { showAdResponsePriceOverride } = useUserAccess();
    const isFixedPrice = priceModel.id === PRICE_MODELS.FIXED;
    const isAuctionPrice = type.id === DEAL_TYPES.AUCTION;
    const { fieldLabel: cpmLabel, isMoney } = useOverrideFloorCpmInputLabel(floorType);

    const fixedCpm = (bookingPrice || 0) / 1000;
    const currencyCode = currency.code;

    return (
        <>
            {!isAuctionPrice && (
                <Row>
                    <Col sm={8}>
                        <Form.Item label="Price Model">{priceModel?.name}</Form.Item>
                    </Col>
                    <Col sm={8}>
                        <AdSourceCurrency currency={currencyCode} />
                    </Col>
                </Row>
            )}
            {!isAuctionPrice && (
                <Row>
                    {isFixedPrice && (
                        <>
                            <Col sm={8}>
                                <Form.Item label="Fixed CPM">{formatNumber.asMoney(fixedCpm, currencyCode)}</Form.Item>
                            </Col>
                            <Col sm={8}>
                                <Form.Item data-sdet="ad-source-pricing-booking-budget" label="Booking Budget">
                                    {formatNumber.asMoney(
                                        bookingVolume && bookingPrice
                                            ? calucateBookingBudget(bookingVolume, fixedCpm)
                                            : undefined,
                                        currencyCode
                                    )}
                                </Form.Item>
                            </Col>
                        </>
                    )}
                </Row>
            )}
            {/*Floor Settings*/}
            {isAuctionPrice && (
                <Row>
                    <Col sm={8}>
                        <Form.Item label="Floor Type">{floorType}</Form.Item>
                        <Form.Item label="Auction Type">{auctionType}</Form.Item>
                    </Col>
                    <Col sm={8}>
                        <Form.Item label={cpmLabel}>
                            {isMoney ? formatNumber.asMoney(fixedCpm, currencyCode) : fixedCpm}
                        </Form.Item>
                        <Form.Item data-sdet="ad-source-pricing-always-send-floor" label="Always Send Floor">
                            {format.asYesOrNo(alwaysSendFloor)}
                        </Form.Item>
                    </Col>
                    <Col sm={8}>
                        <AdSourceCurrency currency={currencyCode} />
                    </Col>
                </Row>
            )}
            <Row>
                <Col sm={8}>
                    <Form.Item label="Run Under Inbound Floor">{format.asYesOrNo(overrideDynamicFloor)}</Form.Item>
                </Col>
                {showAdResponsePriceOverride && adResponsePriceOverrideType && (
                    <Col sm={8}>
                        <Form.Item label="Ad Response Price Override">{adResponsePriceOverrideType}</Form.Item>
                    </Col>
                )}
            </Row>
        </>
    );
};
