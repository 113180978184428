import { FC, useMemo } from "react";
import { Form, Select } from "antd";
import { useGetAdUnitsCreativeAttributesQuery } from "@app/core/services";
import { AD_UNIT_FORM_FIELDS } from "../../../constants";
import { TagRender } from "@app/core/components";

export const BlockedAttributes: FC = () => {
    const { data = [], isLoading } = useGetAdUnitsCreativeAttributesQuery();
    const options = useMemo(() => data.map((datum) => ({ label: datum.name, value: datum.id })), [data]);

    return (
        <Form.Item
            label={AD_UNIT_FORM_FIELDS.BLOCKED_ATTRIBUTES.label}
            name={AD_UNIT_FORM_FIELDS.BLOCKED_ATTRIBUTES.name}
        >
            <Select
                allowClear
                autoClearSearchValue={false}
                labelInValue
                loading={isLoading}
                mode="multiple"
                optionFilterProp="label"
                options={options}
                placeholder={AD_UNIT_FORM_FIELDS.BLOCKED_ATTRIBUTES.placeholder}
                showSearch
                style={{ width: "100%" }}
                tagRender={TagRender}
            />
        </Form.Item>
    );
};
