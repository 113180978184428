import { useParams } from "react-router-dom";
import { AdUnit } from "@app/core/services/console";
import { useGetAdUnitByCodeQuery } from "@app/core/services/console/adUnits";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useEntityRedirect } from "./useEntityRedirect";
import { InventoryDetailsDrawerType } from "../inventory/DetailsDrawer/reducer";

const getSuccessRoute = (data: AdUnit, isInventoryBetaEnabled: boolean) => {
    const adUnitId = data.id;
    const supplyId = data.supply.id;
    const brandId = data.supply.brand.id;
    const publisherId = data.supply.brand.publisher.id;
    const seatId = data.supply.brand.publisher.seat.id;

    if (!isInventoryBetaEnabled) {
        return ROUTE_FORMATTERS.SEAT_INVENTORY_CONSOLE_AD_UNIT(seatId, publisherId, brandId, supplyId, adUnitId);
    }

    return ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT(
        InventoryDetailsDrawerType.AD_UNIT,
        seatId,
        publisherId,
        brandId,
        supplyId,
        adUnitId
    );
};

export const useAdUnitRedirect = () => {
    const { adUnitCode } = useParams<{ adUnitCode: string }>();

    return useEntityRedirect<AdUnit, string>(
        adUnitCode,
        useGetAdUnitByCodeQuery,
        "Ad Unit",
        (data, context) => getSuccessRoute(data, Boolean(context?.inventoryBetaEnabled)),
        (data: AdUnit) => data.supply.brand.publisher.seat.id
    );
};
