import { Col, Flex, Space } from "antd";
import { useFetchSeatAdSource } from "@app/features/seatAdSources/data/useFetchSeatAdSources";
import { useSeatAdSourcesDetailsDrawerContent } from "./useSeatAdSourcesDetailsDrawerContent";
import { SeatAdSourcesDetailsDrawerViewSwitch } from "./SeatAdSourcesDetailsDrawerView/SeatAdSourcesDetailsDrawerViewSwitch";
import {
    SeatAdSourcesDetailsDrawerHeader,
    SeatAdSourcesDetailsDrawerTitleSwitch,
} from "./SeatAdSourcesDetailsDrawerHeader";
import { Loading } from "@app/core/components";

export const SeatAdSourcesDetailsDrawerContent = () => {
    const { requestedAdSourceId, secondaryDetailsView } = useSeatAdSourcesDetailsDrawerContent();
    const { adSource, isFetching } = useFetchSeatAdSource(requestedAdSourceId);

    if (isFetching) {
        return <Loading />;
    }

    if (!adSource) {
        return null;
    }

    const titleInfo = { adSourceId: adSource.id, adSourceName: adSource.name, secondaryDetailsView };
    const contentCss = secondaryDetailsView !== null ? "drawer-view-content" : "";
    return (
        <Flex vertical data-sdet="seat-ad-sources-details-drawer-content">
            <SeatAdSourcesDetailsDrawerHeader>
                <SeatAdSourcesDetailsDrawerTitleSwitch titleInfo={titleInfo} adSourceTypeId={adSource.type.id} />
            </SeatAdSourcesDetailsDrawerHeader>
            <Col span={24} className={`full-width ${contentCss}`}>
                <Space>
                    <SeatAdSourcesDetailsDrawerViewSwitch
                        adSource={adSource}
                        secondaryDetailsView={secondaryDetailsView}
                    />
                </Space>
            </Col>
        </Flex>
    );
};
