import { FC } from "react";
import { Card, Row, Col } from "antd";
import { Seat } from "@app/core/services";
import { useUserAccess } from "@app/core/auth";
import { SEAT_DETAILS_SECTION_COL_SIZES } from "@app/features/inventory/InventorySeat/constants";
import {
    Currency,
    FallbackFloor,
    FinancialInternalOnly,
} from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/SeatDetailsFormFields";

interface FinancialSectionProps {
    edit?: boolean;
    seat: Seat;
}

const COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 };
export const FinancialSection: FC<FinancialSectionProps> = ({ edit = false, seat }) => {
    const { isSysAdmin, isPubAcctMgr } = useUserAccess();
    const isInternalOnly: boolean = isSysAdmin || isPubAcctMgr;
    return (
        <Card bordered={false}>
            <Row gutter={16}>
                <Col {...SEAT_DETAILS_SECTION_COL_SIZES}>
                    <Row gutter={16}>
                        <Col {...COL_SIZES}>
                            <Currency seat={seat} edit={edit} />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col {...COL_SIZES}>
                            <FallbackFloor seat={seat} edit={edit} />
                        </Col>
                    </Row>
                </Col>
                {isInternalOnly && (
                    <Col {...SEAT_DETAILS_SECTION_COL_SIZES}>
                        <FinancialInternalOnly seat={seat} edit={edit} />
                    </Col>
                )}
            </Row>
        </Card>
    );
};
