import { GoToEntityLink } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Col, Row, Typography } from "antd";
import { FC } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { HelpKeysButton } from "@app/core/components/HelpKeysDrawer";
import { uri } from "@rubicon/utils";
import { ClassificationsLabelDetailsActions } from "./ClassificationsLabelDetailsActions";
import { useUserAccess } from "@app/core/auth";
import { Label } from "@app/core/services";

export const CLASSIFICATIONS_LABEL_DETAILS_DRAWER_TITLE_SDET = "classifications-label-details-drawer-title";
export const CLASSIFICATIONS_LABEL_DETAILS_DRAWER_TITLE_NAME_SDET = "classifications-label-details-drawer-title-name";
interface Props {
    label?: Label;
}

export const ClassificationsLabelDetailsTitle: FC<Props> = ({ label }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const detailPagePathname = ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_LABEL_DETAILS_PAGE(
        seatId,
        label?.id as number
    );
    const { origin, pathname } = window.location;
    const copyUrl = `${origin}${pathname}#${detailPagePathname}`;
    const { search: queryString } = useLocation();
    const history = useHistory();
    const { canEditSeat } = useUserAccess();

    return label ? (
        <>
            <Row
                data-sdet={CLASSIFICATIONS_LABEL_DETAILS_DRAWER_TITLE_SDET}
                gutter={[0, 16]}
                style={{ marginRight: "1rem" }}
            >
                <Col span={24}>
                    <Row justify="space-between" align="middle">
                        <Col>
                            <Row gutter={[8, 4]} align="middle">
                                <Col>
                                    <Typography.Title
                                        data-sdet={CLASSIFICATIONS_LABEL_DETAILS_DRAWER_TITLE_NAME_SDET}
                                        ellipsis={{
                                            tooltip: { title: `Label: ${label.key}`, placement: "bottom" },
                                        }}
                                        level={5}
                                        style={{
                                            margin: 0,
                                            maxWidth: "18rem",
                                        }}
                                    >
                                        Label: {label.key}
                                    </Typography.Title>
                                </Col>
                                <Col>
                                    <GoToEntityLink
                                        entity="Label"
                                        linkTo={ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_LABEL_DETAILS_PAGE(
                                            seatId,
                                            label.id
                                        )}
                                        copyUrl={copyUrl}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <HelpKeysButton
                                onClick={() => {
                                    const nextQueryString = uri.setSearchParam(queryString, "drawer", "definitions");
                                    history.push({ search: nextQueryString });
                                }}
                                buttonName="Labels"
                            />
                        </Col>
                    </Row>
                </Col>
                {canEditSeat && (
                    <Col span={24}>
                        <ClassificationsLabelDetailsActions isDrawer label={label} />
                    </Col>
                )}
            </Row>
        </>
    ) : null;
};
