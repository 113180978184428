import { Dashes } from "@app/core/components";
import { ThirdPartyPixels } from "@app/core/services";
import { Form, Tag } from "antd";
import { FC } from "react";

interface Props {
    pixels: ThirdPartyPixels[];
}
export const AdSourceCustomPixels: FC<Props> = ({ pixels }) => {
    return (
        <Form.Item label="Pixels">
            {pixels?.length ? pixels.map(({ id, name }) => <Tag key={id}>{name}</Tag>) : <Dashes />}
        </Form.Item>
    );
};
