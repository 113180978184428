import { message } from "antd";
import { useUpdateUserPreferencesMutation, UserPreference } from "@app/core/services";
import { useSeatAuthContext } from "@app/core/auth";

export const useUpdateSettings = () => {
    const { session } = useSeatAuthContext();
    const settings = session?.user.userPreferenceMap;

    const [updateUserPreferences] = useUpdateUserPreferencesMutation();

    const handleUpdateUserPreferences = async (newSettings: Partial<typeof settings>) => {
        if (!settings) {
            return;
        }

        const updatedSettings = { ...settings, ...newSettings };
        const body = Object.keys(updatedSettings)
            .map((key: keyof typeof settings) => ({
                key,
                value: updatedSettings[key],
            }))
            .filter((preference) => preference.value !== null) as Partial<UserPreference>[];
        try {
            await updateUserPreferences({ id: session.user.id, body });
        } catch (err) {
            message.error("Failed to Update Dashboard Settings");
        }
    };

    return handleUpdateUserPreferences;
};
