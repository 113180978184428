import { Form } from "antd";
import { AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { calucateBookingBudget } from "@app/features/seatAdSources/SeatAdSourcesForm/utils";
import { getAddOnContent } from "@app/core/components/Fields/CurrencyInput";
import { useIsFieldVisible } from "./useIsFieldVisible";
import { formatNumber } from "@rubicon/utils";

const { useFormInstance, useWatch } = Form;

export const useBookingBudgetField = () => {
    const adSourcesFormFormInstance = useFormInstance<AdSourcesForm>();
    const currency = useWatch(AD_SOURCE_FIELDS.CURRENCY.name, adSourcesFormFormInstance);
    const totalImpressions = useWatch(AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.name, adSourcesFormFormInstance);
    const fixedCpm = useWatch(AD_SOURCE_FIELDS.FIXED_CPM.name, adSourcesFormFormInstance);

    const [currencySymbol] = getAddOnContent(currency?.code || "USD");

    const amount = calucateBookingBudget(totalImpressions, Number(fixedCpm));

    const bookingBudgetValue = `${currencySymbol} ${formatNumber.asFixed(amount)}`;

    const isVisible = useIsFieldVisible();

    return { bookingBudgetValue, isVisible };
};
