import { useSeatAuthContext } from "@app/core/auth";
import { TagRender } from "@app/core/components";
import { useNotificationError } from "@app/core/components/hooks";
import { useGetSeatAdSourcesTypeQuery } from "@app/core/services";
import {
    AD_SOURCE_TYPE_PROGRAMMATIC_IDS,
    AD_SOURCE_TYPE_TAG_BASED_IDS,
    AD_SOURCE_TYPE_THIRD_PARTY_IDS,
    AdSourceTypeIds,
} from "@app/features/controls/bulkOperations/constants";
import { Select } from "antd";
import { LabeledValue } from "antd/es/select";
import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";

interface Props {
    value?: number[];
}
const TREMOR_SEAT_ID = 60000;
export const AdSourceTypeFilter: FC<Props> = ({ value, ...restProps }) => {
    const { context } = useSeatAuthContext();
    const { seatId } = useParams<{ seatId: string }>();
    const { data, isFetching, error } = useGetSeatAdSourcesTypeQuery(Number(seatId));
    useNotificationError(error);

    const { programmaticTypes, tagBasedTypes, thirdPartyTypes } = useMemo(
        () =>
            (data || [])
                // remove unqualified options
                .filter(({ id }) => {
                    // ids [7,8,9]
                    if (
                        Number(seatId) === TREMOR_SEAT_ID &&
                        [
                            AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED,
                            AdSourceTypeIds.SERVER_SIDE_TAG_NON_GUARANTEED,
                            AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED,
                        ].includes(id)
                    ) {
                        return false;
                    }
                    if (!context?.authorizedMarketplaces.length && AdSourceTypeIds.MARKETPLACE === id) {
                        return false;
                    }
                    return true;
                })
                // group options
                .reduce<{
                    programmaticTypes: LabeledValue[];
                    tagBasedTypes: LabeledValue[];
                    thirdPartyTypes: LabeledValue[];
                }>(
                    (acc, { name, id }) => {
                        const option = { label: name, value: id };
                        if (AD_SOURCE_TYPE_PROGRAMMATIC_IDS.includes(id)) {
                            acc.programmaticTypes.push(option);
                        }
                        if (AD_SOURCE_TYPE_TAG_BASED_IDS.includes(id)) {
                            acc.tagBasedTypes.push(option);
                        }
                        if (AD_SOURCE_TYPE_THIRD_PARTY_IDS.includes(id)) {
                            acc.thirdPartyTypes.push(option);
                        }
                        return acc;
                    },
                    { programmaticTypes: [], tagBasedTypes: [], thirdPartyTypes: [] }
                ),
        [data, seatId, context?.authorizedMarketplaces.length]
    );

    const options = [
        {
            label: "Programmatic (Full Featured)",
            options: programmaticTypes,
        },
        {
            label: "Tag Based",
            options: tagBasedTypes,
        },
        {
            label: "Third Party",
            options: thirdPartyTypes,
        },
    ].filter(({ options }) => options.length > 0);

    return (
        <Select
            allowClear
            showSearch
            data-sdet="ad-source-type-filter"
            loading={isFetching}
            mode="multiple"
            autoClearSearchValue={false}
            options={options}
            optionFilterProp="label"
            placeholder="Select…"
            tagRender={TagRender}
            value={value}
            {...restProps}
        />
    );
};
