import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@app/core/store";
import { SeatAdSourcesDetailsDrawerSecondaryHeader as SecondaryHeaderView } from "./SeatAdSourcesDetailsDrawerContent/SeatAdSourcesDetailsDrawerHeader";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "./types";

interface SeatAdSourcesDetailsDrawerState {
    openedFromDetailsView: boolean;
    requestedAdSourceId: number;
    secondaryDrawerHeader: SecondaryHeaderView;
    secondaryDetailsView: SecondaryDetailsView;
    isReadonly: boolean;
    collapse: {
        availableKeys: string[];
        keys: string[];
    };
}

const initialState: SeatAdSourcesDetailsDrawerState = {
    openedFromDetailsView: false,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    requestedAdSourceId: null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    secondaryDrawerHeader: null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    secondaryDetailsView: null,
    isReadonly: true,
    collapse: {
        availableKeys: [],
        keys: [],
    },
};

interface OpenDrawerAction {
    requestedAdSourceId: number;
    secondaryDetailsView?: SecondaryDetailsView;
    openedFromDetailsView?: boolean;
    isReadonly: boolean;
}

const seatAdSourcesDetailsDrawerSlice = createSlice({
    name: "AdSourceDetailsDrawer",
    initialState,
    reducers: {
        closeDrawer: (state) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.requestedAdSourceId = null;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.secondaryDrawerHeader = null;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.secondaryDetailsView = null;
            state.openedFromDetailsView = false;
        },
        openDrawer: (state, action: PayloadAction<OpenDrawerAction>) => {
            state.requestedAdSourceId = action.payload.requestedAdSourceId;
            state.openedFromDetailsView = action.payload.openedFromDetailsView || false;
            const secondaryDetailsView = action.payload.secondaryDetailsView;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.secondaryDrawerHeader = secondaryDetailsView ? SecondaryHeaderView.DirectHeader : null;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.secondaryDetailsView = secondaryDetailsView ? secondaryDetailsView : null;
            state.isReadonly = action.payload.isReadonly;
        },
        switchToDefaultView: (state) => {
            if (!state.openedFromDetailsView) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                state.requestedAdSourceId = null;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.secondaryDrawerHeader = null;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.secondaryDetailsView = null;
            state.openedFromDetailsView = false;
        },
        switchToNestedView: (state, action: PayloadAction<SecondaryDetailsView>) => {
            state.secondaryDrawerHeader = SecondaryHeaderView.NestedHeader;
            state.secondaryDetailsView = action.payload;
            state.openedFromDetailsView = true;
        },
        collapseAll: (state) => {
            state.collapse.keys = [];
        },
        expandAll: (state) => {
            state.collapse.keys = state.collapse.availableKeys;
        },
        collapseChange: (
            state,
            { payload: { keys, availableKeys } }: PayloadAction<{ keys: string[]; availableKeys?: string[] }>
        ) => {
            state.collapse.keys = keys;
            if (availableKeys) {
                state.collapse.availableKeys = availableKeys;
            }
        },
    },
});

export const selectRequestedAdSourceId = (state: RootState) => state.seatAdSourcesDetailsDrawer.requestedAdSourceId;
export const selectSecondaryDrawerHeader = (state: RootState) => state.seatAdSourcesDetailsDrawer.secondaryDrawerHeader;
export const selectSecondaryDrawerView = (state: RootState): SecondaryDetailsView =>
    state.seatAdSourcesDetailsDrawer.secondaryDetailsView;
export const selectIsDrawerOpen = (state: RootState): boolean =>
    typeof state.seatAdSourcesDetailsDrawer.requestedAdSourceId === "number";

export const selectSecondaryCollapseKeys = (state: RootState): string[] =>
    state.seatAdSourcesDetailsDrawer.collapse.keys;
export const selectSecondaryCollapseAvailableKeys = (state: RootState): string[] =>
    state.seatAdSourcesDetailsDrawer.collapse.availableKeys;
export const selectIsSecondaryCollapseExpandedAll = (state: RootState): boolean =>
    state.seatAdSourcesDetailsDrawer.collapse.availableKeys.length !==
    state.seatAdSourcesDetailsDrawer.collapse.keys.length;
export const selectIsAdSourceDetailsDrawerReadonly = (state: RootState): boolean =>
    state.seatAdSourcesDetailsDrawer.isReadonly;
export const {
    closeDrawer,
    openDrawer,
    switchToDefaultView,
    switchToNestedView,
    expandAll,
    collapseAll,
    collapseChange,
} = seatAdSourcesDetailsDrawerSlice.actions;

export const adSourcesDetailsDrawerReducer = seatAdSourcesDetailsDrawerSlice.reducer;
