import { FC } from "react";
import { Col, Form } from "antd";
import { AD_SOURCE_FIELDS, COL_SIZES, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { ReadonlyControl } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { usePriceModelSelect } from "./usePriceModelSelect";

export const PriceModelSelect: FC = () => {
    const { isVisible, isInheritedReadOnly } = usePriceModelSelect();

    if (!isVisible) {
        return null;
    }

    return (
        <Col {...COL_SIZES}>
            <Form.Item
                name={AD_SOURCE_FIELDS.PRICE_MODEL.name}
                label={AD_SOURCE_FIELDS.PRICE_MODEL.label}
                data-sdet={`test-${AD_SOURCE_FIELDS.PRICE_MODEL.name}-field`}
                help={isInheritedReadOnly ? "Inherited from Demand Deal" : undefined}
                {...VERTICAL_LAYOUT_FIX}
            >
                <ReadonlyControl<AdSourcesForm["priceModel"]> getLabel={(model) => model.name} />
            </Form.Item>
        </Col>
    );
};
