import { Auth0ContextInterface } from "@auth0/auth0-react";
import { default as consoleClient } from "../clients/console/client";
import { GrantingUser, Session } from "../clients/console";
import { ROUTES } from "../routing";
import { User } from "../services";
import { store } from "@app/core/store";
import {
    onFetchSession,
    onFetchSessionError,
    onFetchSessionSuccess,
    onLogin,
    onLoginError,
    onLoginSuccess,
    onLogout,
    onLogoutError,
    onLogoutSuccess,
    onRunAs,
    onRunAsError,
    onRunAsSuccess,
} from "./reducer";

export type AuthRequest = Promise<void>;

export interface Authenticator {
    readonly fetchSession: () => AuthRequest;
    readonly login: (emailAddress: string, password: string) => AuthRequest;
    readonly logout: (auth0: Auth0ContextInterface) => AuthRequest;
    readonly runAsUser: (user: User | GrantingUser, sessionCode: string) => AuthRequest;
}

export class AuthClient implements Authenticator {
    private static _instance: AuthClient;

    public static getInstance = (mockClient?: typeof consoleClient): AuthClient => {
        if (!this._instance) {
            this._instance = new AuthClient(mockClient);
        }
        return this._instance;
    };

    private readonly _consoleClient: typeof consoleClient;

    private constructor(mockClient?: typeof consoleClient) {
        this._consoleClient = mockClient || consoleClient;
    }

    public readonly fetchSession = async (): AuthRequest => {
        store.dispatch(onFetchSession());
        try {
            const response = await this._consoleClient.get<Session>("/platform/resources/sessions/self");
            const session = response.data;
            store.dispatch(onFetchSessionSuccess(session));
            return Promise.resolve();
        } catch (error) {
            store.dispatch(onFetchSessionError());
            return Promise.reject(error);
        }
    };

    public readonly login = async (emailAddress: string, password: string): AuthRequest => {
        store.dispatch(onLogin());
        try {
            const response = await this._consoleClient.post(
                "/platform/resources/sessions",
                {
                    emailAddress,
                    password,
                },
                {
                    headers: {
                        Cookie: "",
                    },
                    withCredentials: true,
                }
            );
            const session = response.data;
            store.dispatch(onLoginSuccess({ session, isAuth0: false }));
            return Promise.resolve();
        } catch (error) {
            store.dispatch(onLoginError());
            return Promise.reject(error);
        }
    };

    public readonly logout = async (auth0: Auth0ContextInterface): AuthRequest => {
        store.dispatch(onLogout());
        try {
            await this._consoleClient.delete("/platform/resources/sessions/self");
            if (auth0.isAuthenticated) {
                await this.auth0Logout(auth0);
            } else {
                store.dispatch(onLogoutSuccess());
            }
        } catch (error) {
            store.dispatch(onLogoutError());
            if (auth0.isAuthenticated) {
                await this.auth0Logout(auth0);
            }
        }
    };

    public readonly runAsUser = async (user: User | GrantingUser, sessionCode: string): AuthRequest => {
        store.dispatch(onRunAs());
        try {
            const response = await this._consoleClient.put<Session>(
                `/platform/resources/sessions/${sessionCode}`,
                { user },
                {
                    withCredentials: true,
                }
            );
            const session = response.data;

            store.dispatch(onRunAsSuccess(session));

            return Promise.resolve();
        } catch (error) {
            store.dispatch(onRunAsError());
            return Promise.reject(error);
        }
    };

    private readonly auth0Logout = (auth0: Auth0ContextInterface): AuthRequest => {
        return auth0.logout({
            logoutParams: {
                returnTo: `${window.location.origin}/#${ROUTES.LOGIN_MAGNITE}`,
            },
        });
    };
}
