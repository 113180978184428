import { FC } from "react";
import { Form, Radio } from "antd";
import {
    SUPPLY_FORM_FIELDS,
    INVENTORY_YES_NO_SPECIFIED_OPTIONS,
} from "@app/features/inventory/HierarchyForms/constants";

export const COPPA: FC = () => {
    return (
        <Form.Item name={SUPPLY_FORM_FIELDS.COPPA.name} label={SUPPLY_FORM_FIELDS.COPPA.label}>
            <Radio.Group size="large" options={INVENTORY_YES_NO_SPECIFIED_OPTIONS} />
        </Form.Item>
    );
};
