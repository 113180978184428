import { DrawerBreadcrumbHeader, GoToLink, Loading } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ResizableDrawer } from "@rubicon/antd-components";
import { HelpKeysButton, HelpKeysDrawerContent } from "@app/core/components/HelpKeysDrawer";
import { Flex, Typography } from "antd";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useCompetitiveSeparationGroupsDetailsDrawer } from "./useCompetitiveSeparationGroupsDetailsDrawer";
import { CompetitiveSeparationGroupsActions } from "../CompetitiveSeparationGroupsActions";
import { HELP_KEYS } from "../constants";
import { CompetitiveSeparationGroupsReadOnlyForm } from "../CompetitiveSeparationGroupsForm";
import { drawerRightClosePosition } from "@app/features/controls/constants";

interface DrawerTitleProps {
    seatId: string;
    id: number;
    name: string;
    detailsPageUrl: string;
    handleDefinition: () => void;
    postDeleteEvent: () => void;
}
const DrawerTitle: FC<DrawerTitleProps> = ({ name, detailsPageUrl, handleDefinition, seatId, id, postDeleteEvent }) => {
    return (
        <Flex vertical gap="small" style={{ marginRight: "1rem" }}>
            <Flex flex="1 1 0" justify="space-between">
                <Flex gap="small" align="center">
                    <Typography.Title
                        level={5}
                        style={{
                            margin: 0,
                            maxWidth: "15rem",
                        }}
                        ellipsis={{ tooltip: { title: name, placement: "bottom" } }}
                    >
                        {name}
                    </Typography.Title>
                    <GoToLink
                        targetPath={detailsPageUrl}
                        goToText="Go to Separation Groups"
                        tooltipProps={{ title: "Copy Separation Groups" }}
                    />
                </Flex>
                <HelpKeysButton onClick={handleDefinition} buttonName="Competitive Separation Group" />
            </Flex>
            <CompetitiveSeparationGroupsActions seatId={seatId} id={id} name={name} postDeleteEvent={postDeleteEvent} />
        </Flex>
    );
};

export const CompetitiveSeparationGroupsDetailsDrawer = () => {
    const { seatId, id } = useParams<{ seatId: string; id: string }>();
    const {
        open,
        data,
        showDefinitionContent,
        handleBack,
        isLoading,
        handleClose,
        handleDefinition,
        handleAfterOpenChange,
        handlePostDeleteEvent,
    } = useCompetitiveSeparationGroupsDetailsDrawer(seatId, id);

    return (
        <ResizableDrawer
            open={open}
            placement="right"
            title={
                data ? (
                    showDefinitionContent ? (
                        <DrawerBreadcrumbHeader
                            onBack={handleBack}
                            parentLabel={data.name}
                            childLabel="Competitive Separation Group"
                        />
                    ) : (
                        <DrawerTitle
                            name={data.name}
                            seatId={seatId}
                            id={Number(id)}
                            detailsPageUrl={ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_DETAILS_PAGE(
                                seatId,
                                id
                            )}
                            handleDefinition={handleDefinition}
                            postDeleteEvent={handlePostDeleteEvent}
                        />
                    )
                ) : undefined
            }
            width="45%"
            onClose={handleClose}
            afterOpenChange={handleAfterOpenChange}
            className={drawerRightClosePosition}
        >
            {isLoading && <Loading />}
            {showDefinitionContent && <HelpKeysDrawerContent definitionsFields={HELP_KEYS} />}
            {data && !showDefinitionContent && <CompetitiveSeparationGroupsReadOnlyForm separationGroup={data} />}
        </ResizableDrawer>
    );
};
