import { FC } from "react";
import { TimingStats } from "@app/core/services";
import { DualAxesChart } from "@app/features/seatAdSources/seatAdSourcesCharts/charts";
import { useSeatAdSourcesTimingStatsChart } from "./useSeatAdSourcesTimingStatsChart";

interface Props {
    timingStats: TimingStats | undefined;
    region: string;
}

export const SeatAdSourcesTimingStatsChart: FC<Props> = ({ timingStats, region }) => {
    const dualAxesChartProps = useSeatAdSourcesTimingStatsChart(timingStats, region);

    return <DualAxesChart {...dualAxesChartProps} />;
};
