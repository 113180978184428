import { MarketplaceInfo } from "@app/core/services";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { Form } from "antd";

export const usePublisherEmail = () => {
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const buyers = Form.useWatch<MarketplaceInfo["deal"]["buyerSeats"]>(["dealUnderEdit", "buyerSeats"]);
    const isVisible = Boolean(buyers?.length);
    const isReadonly = dealObjectMode === "view";

    return { isVisible, isReadonly };
};
