import { FC } from "react";
import { Layout } from "antd";
import { Loading, SeatIFrame } from "@app/core/components";
import { useSeatAuthContext } from "@app/core/auth";
import conf from "@app/core/conf";

export const DiagnosticsDealDashboardPage: FC = () => {
    const { context, session } = useSeatAuthContext();

    if (!context || !session) {
        return (
            <Layout.Content>
                <Loading />
            </Layout.Content>
        );
    }

    return (
        <SeatIFrame
            src={(seatId) => `${conf.mctvConsoleRoot}/ssp/seats/${seatId}/demand-diagnostics/deal-dashboard`}
            title="Deal Dashboard"
        />
    );
};
