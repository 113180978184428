import { useState } from "react";

export enum SeatAdSourcesPerformanceTab {
    Historical = "historical",
    AdBid = "adBidStats",
    Overview = "overview",
}

const DefaultPerformanceTab = SeatAdSourcesPerformanceTab.Overview;

interface UseSeatAdSourcesDetailsPerformance {
    activeTab: string;
    onSwitchTab: (tab: string) => void;
}

export const useSeatAdSourcesDetailsPerformance = (): UseSeatAdSourcesDetailsPerformance => {
    const [activeTab, setActiveTab] = useState<string>(DefaultPerformanceTab);

    const onSwitchTab = (tab: string) => {
        setActiveTab(tab);
    };

    return {
        activeTab,
        onSwitchTab,
    };
};
