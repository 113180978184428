import { FC } from "react";
import { Select, Form } from "antd";
import { ColProps } from "antd/lib/grid/col";
import { SelectProps } from "antd/lib/select";
import { TagRender } from "@app/core/components";
import { SUPPORTED_PROTOCOLS } from "@app/features/inventory/HierarchyForms/constants";
import { useAdUnitSupportedProtocols } from "@app/features/inventory/components/FormItems/AdUnitSupportedProtocols/useAdUnitSupportedProtocols";

interface AdUnitSupportedProtocolsProps extends SelectProps {
    name: typeof SUPPORTED_PROTOCOLS;
    wrapperCol?: ColProps;
    labelCol?: ColProps;
    label: string;
}

export const AdUnitSupportedProtocols: FC<AdUnitSupportedProtocolsProps> = ({
    label,
    name,
    labelCol,
    wrapperCol,
    ...rest
}) => {
    const { isFetching, options, validator } = useAdUnitSupportedProtocols();

    return (
        <Form.Item
            name={name}
            label={label}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            rules={[{ required: true, validator }]}
        >
            <Select
                showSearch
                allowClear
                labelInValue
                mode="multiple"
                options={options}
                loading={isFetching}
                tagRender={TagRender}
                optionFilterProp="label"
                autoClearSearchValue={false}
                {...rest}
            />
        </Form.Item>
    );
};
