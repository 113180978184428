import { CustomPixelFormValues, PIXEL_URL_PREFIX } from "./constants";

export const LINK_PREFIX_REGEX_NOTATION = /^(http|https):\/\//i; // prefix only at the beginning

export const trimPixelUrlPrefix = (url: string) => url.replace(LINK_PREFIX_REGEX_NOTATION, "");

export const pixelPayload = (seatId: number, values: CustomPixelFormValues) => {
    const { name, value, ...rest } = values;
    const trimmedPixelUrl = `${PIXEL_URL_PREFIX}${value.trim().replace(LINK_PREFIX_REGEX_NOTATION, "")}`;
    const trimmedName = name.trim();

    return {
        ...rest,
        seat: { id: seatId },
        name: trimmedName,
        value: trimmedPixelUrl,
    };
};
