import { useEffect, useState } from "react";
import { exchangeAccessTokenForSession } from "@app/features/login/exchangeAccessTokenForSession";
import { store } from "@app/core/store";
import { onLoginError, onLoginSuccess } from "@app/core/authClient/reducer";
import { useAuth0 } from "@auth0/auth0-react";
import { ROUTES } from "@app/core/routing/routes";

export const useTokenExchange = () => {
    const [loading, setLoading] = useState(true);
    const { getAccessTokenSilently, isAuthenticated, logout: auth0Logout } = useAuth0();

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (isAuthenticated) {
                    const accessToken = await getAccessTokenSilently();
                    const { sessionObj } = await exchangeAccessTokenForSession(accessToken);
                    store.dispatch(onLoginSuccess({ session: sessionObj.data, isAuth0: true }));
                    setLoading(false);
                }
            } catch (error) {
                store.dispatch(onLoginError());
                await auth0Logout({
                    logoutParams: {
                        returnTo: window.location.origin + "/#" + ROUTES.LOGIN_MAGNITE,
                    },
                });
            }
        };
        fetchData();
    }, [getAccessTokenSilently, isAuthenticated, auth0Logout]);
    return { loading };
};
