import { FC } from "react";
import { Typography } from "antd";

interface TitleProps {
    title: string;
    level: 1 | 2 | 3 | 4 | 5;
}

export const Title: FC<TitleProps> = ({ title, level }) => {
    return (
        <Typography.Title level={level} style={{ marginBottom: 0 }}>
            {title}
        </Typography.Title>
    );
};
