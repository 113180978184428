import { Layout } from "antd";
import { FC } from "react";
import { Footer } from "@app/core/components";
import { useParams } from "react-router-dom";
import { PageLoader } from "@rubicon/antd-components";
import { useGetBrandChannelQuery } from "@app/core/services";
import { BrandChannelForm } from "@app/features/inventory/HierarchyForms/BrandChannelForm";
import { BrandChannelFormInstance } from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormInstance";
import { BrandChannelEditPageHeader } from "@app/features/inventory/HierarchyFormsPages/BrandChannelPages/BrandChannelEditPage/BrandChannelEditPageHeader";
import { useScrollToTopOnMount } from "@app/core/components/hooks";

export const BrandChannelEditPage: FC = () => {
    useScrollToTopOnMount();
    const { brandId } = useParams<{ brandId: string }>();
    const { data, isFetching } = useGetBrandChannelQuery(Number(brandId));

    if (isFetching || !data) return <PageLoader />;

    return (
        <>
            <BrandChannelEditPageHeader brandChannel={data} />
            <Layout.Content>
                <BrandChannelFormInstance mode="edit" brandChannel={data}>
                    <BrandChannelForm />
                </BrandChannelFormInstance>
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
