import { TagRender } from "@app/core/components";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { Form } from "antd";
import { usePbsBidderConfigs } from "./usePbsBidderConfigs";

export const ChooseConfigsMultiselect = () => {
    const { pbsBidderConfigs, isLoading } = usePbsBidderConfigs();
    return (
        <Form.Item
            label={AD_SOURCE_FIELDS.PBS_BIDDER_CONFIGS.label}
            name={AD_SOURCE_FIELDS.PBS_BIDDER_CONFIGS.name}
            colon={false}
            rules={[{ required: true, message: "At least One Prebid Server Bidder is required" }]}
            {...VERTICAL_LAYOUT_FIX}
        >
            <SelectWithValue
                allowClear
                showSearch
                optionFilterProp="label"
                options={pbsBidderConfigs}
                loading={isLoading}
                placeholder="Select PBS Bidder Configs..."
                mode="multiple"
                tagRender={TagRender}
                fieldAsLabel={["name"]}
                fieldAsValue={["id"]}
            />
        </Form.Item>
    );
};
