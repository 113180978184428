export const advancedBrandSafetyHelpKeys = {
    general: {
        label: "General",
        helpKeys: [
            {
                key: "name",
                label: "Name",
                helpPath: "advancedbrandsafety.name",
            },
            {
                key: "type",
                label: "Type",
                helpPath: "advancedbrandsafety.type",
            },
            {
                key: "iabCategories",
                label: "Block IAB Categories",
                helpPath: "advancedbrandsafety.iabcategories",
            },
            {
                key: "reusableadvertiserdomains",
                label: "Reusable Advertiser Domains",
                helpPath: "advancedbrandsafety.reusableadvertiserdomains",
            },
            {
                key: "adomains",
                label: "Advertiser Domains",
                helpPath: "advancedbrandsafety.domains",
            },
        ],
    },
    targeting: {
        label: "Targeting",
        helpKeys: [
            {
                key: "TARGETING_BLOCKS",
                label: "Targeting Blocks",
                helpPath: "targetingdetails.blocks",
            },
        ],
    },
};
