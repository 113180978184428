import { FC } from "react";
import { css } from "@emotion/css";
import { Typography, Row, Tooltip, Button, Space, Col, Form, Input, Select, Radio } from "antd";
import { useSeatAdSourcesDetailsLadleForm } from "./useSeatAdSourcesDetailsLadleForm";
import { AdSource } from "@app/core/services";

const CREATIVE_OPTIPONS = [
    {
        label: "All",
        value: null,
    },
    {
        label: "Filled",
        value: true,
    },
    {
        label: "Unfilled",
        value: false,
    },
];

interface Props {
    adSource: AdSource;
}

export const SeatAdSourcesDetailsLadle: FC<Props> = ({ adSource }) => {
    const {
        initialFormData,
        defaultRegion,
        form,
        formData,
        LADLE_FORM_FIELDS,
        isFetchingAwsRegions,
        isFetchingNetworks,
        isFetchingLineItems,
        isFetchingOrders,
        networksOptions,
        awsRegionsOptions,
        lineItemsOptions,
        lineItemsSearch,
        ordersOptions,
        ordersSearch,
        handleActivate,
        handleSaveAndActivate,
        handleFormChange,
        handleChangeLineItemSearch,
        handleChangeOrderSearch,
        hasAdminAccess,
        onClose,
        isSubmitting,
    } = useSeatAdSourcesDetailsLadleForm(adSource);

    return (
        <div
            data-sdet="seat-ad-sources-details-ladle"
            className={css`
                padding: 16px 0 54px 16px;
            `}
        >
            <Tooltip
                placement="topRight"
                title="Ladle is a troubleshooting tool that allows you to debug bid response in JSON for tag-based and open RTB traffic."
            ></Tooltip>
            <Form
                layout="vertical"
                form={form}
                onFinish={handleActivate}
                onValuesChange={handleFormChange}
                initialValues={{ ...initialFormData, [LADLE_FORM_FIELDS.Region]: defaultRegion }}
            >
                <Row>
                    <Col>
                        <Typography.Paragraph>
                            Ladle is a troubleshooting tool that allows you to debug bid response in JSON for tag-based
                            and open RTB traffic. Please fill out the necessary information below to generate a ladle.
                            Once the ladle is generated you can find the results in the diagnostics under &quot;Ladle
                            Results&quot;.
                        </Typography.Paragraph>
                    </Col>
                    <Col span={22}>
                        <Form.Item name={LADLE_FORM_FIELDS.Region} label="Region">
                            <Select
                                showSearch
                                optionFilterProp="label"
                                options={awsRegionsOptions}
                                loading={isFetchingAwsRegions}
                                placeholder="Select Region..."
                            />
                        </Form.Item>
                    </Col>
                    <Col span={22}>
                        <Form.Item name={LADLE_FORM_FIELDS.LadleName} label="Ladle Name">
                            <Input placeholder="Enter Name..." />
                        </Form.Item>
                    </Col>
                    <Col span={22}>
                        <Form.Item name={LADLE_FORM_FIELDS.SupplyDomain} label="Supply Domain">
                            <Input placeholder="Enter Domain..." />
                        </Form.Item>
                    </Col>
                    <Col span={22}>
                        <Form.Item name={LADLE_FORM_FIELDS.BundleId} label="Bundle ID">
                            <Input placeholder="Enter Bundle ID..." />
                        </Form.Item>
                    </Col>
                    <Col span={22}>
                        <Form.Item name={LADLE_FORM_FIELDS.LineItem} label="Line Item">
                            <Select
                                allowClear
                                showSearch
                                onSearch={handleChangeLineItemSearch}
                                searchValue={lineItemsSearch}
                                optionFilterProp="label"
                                options={lineItemsOptions}
                                loading={isFetchingLineItems}
                                placeholder="Select…"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={22}>
                        <Form.Item name={LADLE_FORM_FIELDS.Order} label="Order">
                            <Select
                                allowClear
                                showSearch
                                onSearch={handleChangeOrderSearch}
                                searchValue={ordersSearch}
                                optionFilterProp="label"
                                options={ordersOptions}
                                loading={isFetchingOrders}
                                placeholder="Select…"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={22}>
                        <Form.Item name={LADLE_FORM_FIELDS.AdvertiserDomain} label="Advertiser Domain">
                            <Input placeholder="Enter Advertiser Domain..." />
                        </Form.Item>
                    </Col>
                    <Col span={22}>
                        <Form.Item name={LADLE_FORM_FIELDS.CreativeId} label="Creative ID">
                            <Input placeholder="Enter Creative ID..." />
                        </Form.Item>
                    </Col>
                    <Col span={22}>
                        <Form.Item label="Creative" name={LADLE_FORM_FIELDS.Creative}>
                            <Radio.Group options={CREATIVE_OPTIPONS} />
                        </Form.Item>
                    </Col>
                    <Col span={22}>
                        <Form.Item name={LADLE_FORM_FIELDS.Demand} label="Demand">
                            <Select
                                allowClear
                                showSearch
                                optionFilterProp="label"
                                options={networksOptions}
                                loading={isFetchingNetworks}
                                placeholder="Select…"
                            />
                        </Form.Item>
                    </Col>
                    {formData[LADLE_FORM_FIELDS.Creative] && (
                        <Col span={22}>
                            <Form.Item name={LADLE_FORM_FIELDS.NurlDomain} label="Nurl Domain">
                                <Input placeholder="Enter Nurl Domain..." />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                <Row
                    className={css`
                        border-top: 1px solid #d9d9d9;
                        margin: 0 0 0 -16px;
                        position: fixed;
                        bottom: 0;
                        width: 100%;
                        background: #fff;
                        height: 52px;
                        line-height: 52px;
                    `}
                >
                    <Col
                        className={css`
                            position: fixed;
                            right: 16px;
                        `}
                    >
                        <Space>
                            <Button onClick={onClose} disabled={isSubmitting}>
                                Cancel
                            </Button>
                            {hasAdminAccess && (
                                <Button onClick={handleSaveAndActivate}>Admin Only: Save and Activate</Button>
                            )}
                            <Button type="primary" htmlType="submit" loading={isSubmitting}>
                                Activate
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};
