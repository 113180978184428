import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { ClassificationsAdvertiserDetailsPage } from "./ClassificationsAdvertiserDetailsPage";
import { ClassificationAdvertiserCreatePage } from "./ClassificationsAdvertiserCreatePage";
import { ClassificationAdvertiserEditPage } from "./ClassificationsAdvertiserEditPage";
import { useUserAccess } from "@app/core/auth";
import { ClassificationsAdvertisersTab } from "./ClassificationsAdvertisersTab";

export const ClassificationsAdvertisersSwitch: FC = () => {
    const { canEditSeat } = useUserAccess();

    return (
        <Switch>
            <Route exact path={ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISER_DETAILS_PAGE}>
                <ClassificationsAdvertiserDetailsPage />
            </Route>
            {canEditSeat && (
                <Route exact path={ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISER_CREATE_PAGE}>
                    <ClassificationAdvertiserCreatePage />
                </Route>
            )}
            {canEditSeat && (
                <Route exact path={ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISER_EDIT_PAGE}>
                    <ClassificationAdvertiserEditPage />
                </Route>
            )}
            <Route exact path={ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISERS}>
                <ClassificationsAdvertisersTab />
            </Route>
        </Switch>
    );
};
