import { NONE } from "@app/core/components/constants";
import { AccountType } from "@app/core/services";
import { InternalOnly } from "@app/features/inventory/components/InternalOnly";
import { GUTTER, INNER_COL_SIZES, TWO_COL_SIZES } from "@app/features/inventory/constants";
import { Col, Form, Row } from "antd";
import { FC } from "react";
import { CHANNEL_GENERAL_LABELS, FORM_ITEM_OFFSET } from "../../../constants";
import { TextWithCopy } from "@app/features/inventory/components/TextWithCopy";

const { Item } = Form;

interface Props {
    isInternalOnly: boolean;
    isPublisherChannel: boolean;
    name: string;
    description?: string;
    status: string;
    publisherCode: string;
    brandCode: string;
    adUnitCode: string;
    billingCode?: string;
    accountType?: AccountType | null;
}
export const General: FC<Props> = ({
    isInternalOnly,
    name,
    description,
    publisherCode,
    brandCode,
    adUnitCode,
    status,
    isPublisherChannel,
    billingCode,
    accountType,
}) => {
    return (
        <Row data-sdet="channel-general-section">
            <Col {...TWO_COL_SIZES}>
                <Row gutter={GUTTER}>
                    <Col {...INNER_COL_SIZES}>
                        <Item label={CHANNEL_GENERAL_LABELS.name} className={FORM_ITEM_OFFSET}>
                            <TextWithCopy notificationEntity="Name" text={name} />
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...INNER_COL_SIZES}>
                        <Item label={CHANNEL_GENERAL_LABELS.description} className={FORM_ITEM_OFFSET}>
                            {description ? description : NONE}
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...INNER_COL_SIZES}>
                        <Item label={CHANNEL_GENERAL_LABELS.publisherCode} className={FORM_ITEM_OFFSET}>
                            <TextWithCopy notificationEntity="Publisher Code" text={publisherCode} />
                        </Item>
                    </Col>
                </Row>
                {!isPublisherChannel && (
                    <Row gutter={GUTTER}>
                        <Col {...INNER_COL_SIZES}>
                            <Item label={CHANNEL_GENERAL_LABELS.brandCode} className={FORM_ITEM_OFFSET}>
                                {brandCode}
                            </Item>
                        </Col>
                    </Row>
                )}
                <Row gutter={GUTTER}>
                    <Col {...INNER_COL_SIZES}>
                        <Item label={CHANNEL_GENERAL_LABELS.adUnitCode} className={FORM_ITEM_OFFSET}>
                            <TextWithCopy notificationEntity="Ad Unit Code" text={adUnitCode} />
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...INNER_COL_SIZES}>
                        <Item label={CHANNEL_GENERAL_LABELS.status} className={FORM_ITEM_OFFSET}>
                            {status}
                        </Item>
                    </Col>
                </Row>
            </Col>
            <Col hidden={!(isInternalOnly && isPublisherChannel)} {...TWO_COL_SIZES}>
                <InternalOnly>
                    <Item label={CHANNEL_GENERAL_LABELS.accountType} className={FORM_ITEM_OFFSET}>
                        {accountType?.name || NONE}
                    </Item>
                    <Item label={CHANNEL_GENERAL_LABELS.billingCode} className={FORM_ITEM_OFFSET}>
                        {billingCode}
                    </Item>
                </InternalOnly>
            </Col>
        </Row>
    );
};
