import { FC } from "react";
import { SeatIFrame } from "@app/core/components";
import conf from "@app/core/conf";
import { useLocation, useParams } from "react-router-dom";

export const ControlsIFrame: FC = () => {
    const { tab } = useParams<{ tab: string }>();
    const { search } = useLocation();
    const parameters = new URLSearchParams(search);

    const createCode = parameters.get("code");
    const type = parameters.get("type");

    let urlSuffix = "/controlpanel";
    if (tab) {
        urlSuffix += `/${tab.replace("_", "/")}`;
    }

    //INFO Query params to navigate to bulkoperations with preseledted data for copy mode.
    // should navigate on 3th step
    if (type) {
        const queries: Array<string> = [];
        for (const parameter of parameters.keys()) {
            const value = parameters.get(parameter);

            if (value) {
                queries.push(`${parameter}=${value}`);
            }
        }
        urlSuffix += `/bulkoperations/?${queries.join("&")}`;
    }

    if (createCode) {
        urlSuffix += `/${createCode}`;
    }

    return <SeatIFrame src={(seatId) => `${conf.mctvConsoleRoot}/ssp/seats/${seatId}${urlSuffix}`} title="Controls" />;
};
