import { FC } from "react";
import { Form, Select } from "antd";
import { useDealCurrencyField } from "@app/features/deals/DealForm/DealDemandFormSections/DealPricingSection/Fields/DealCurrencyField/useDealCurrencyField";
import { DealFormFieldType, DealFormMode } from "@app/features/deals/DealForm/types";

interface DealCurrencyFieldProps {
    name: DealFormFieldType;
    mode: DealFormMode;
}

export const DealCurrencyField: FC<DealCurrencyFieldProps> = ({ name, mode }) => {
    const { options, isFetching, value, handleSelect, disabled } = useDealCurrencyField(name, mode);

    return (
        <Form.Item
            data-sdet="deal-currency-field"
            label="Currency"
            rules={[{ required: true, message: "Currency is required" }]}
            name={name}
        >
            <Select
                showSearch
                value={value}
                loading={isFetching}
                disabled={disabled}
                options={options}
                optionFilterProp="label"
                onSelect={handleSelect}
            />
        </Form.Item>
    );
};
