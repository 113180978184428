import { toQueryString } from "./utils";
import { consoleApi, PRICE_OVERRIDE_BY_ID_TAG, PRICE_OVERRIDE_TYPES, PRICE_OVERRIDES_TAG } from "./console";

export interface PriceOverrideType {
    creationDate: string;
    entityType: "PriceOverrideType";
    id: number;
    name: string;
    sort: number;
    updateDate: string;
}

export interface PriceOverrideConfig {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    seat: {
        id: number;
        name: string;
        code: string;
        entityType: string;
    };
    adUnit: {
        id: number;
        name: string;
        // unused fields are not listed
    };
    priceOverrideType: Omit<PriceOverrideType, "entityType" | "sort">;
    overridePrice?: number;
}

export interface PriceOverrideConfigUpdatePayload {
    id: number;
    name: string;
    adUnit: { id: number };
    overridePrice?: number;
    priceOverrideType: { id: number };
}

export const priceOverrideConfigsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getPriceOverrideConfigs: builder.query<
            PriceOverrideConfig[],
            {
                seatId: string;
                keyword?: string;
            }
        >({
            query: ({ seatId, keyword, ...rest }) =>
                `seats/${seatId}/priceOverrideConfigs;${toQueryString({ keyword, ...rest })}`,
            providesTags: (_, __, { seatId }) => [{ type: PRICE_OVERRIDES_TAG, seatId }],
        }),
        getPriceOverrideConfigById: builder.query<PriceOverrideConfig, number | string>({
            query: (priceOverrideId: number | string) => `seats/priceOverrideConfig/${priceOverrideId}`,
            providesTags: (_, __, priceOverrideId) => [{ type: PRICE_OVERRIDE_BY_ID_TAG, id: Number(priceOverrideId) }],
        }),
        getPriceOverrideTypes: builder.query<PriceOverrideType[], void>({
            query: () => "priceOverrideTypes",
            providesTags: [{ type: PRICE_OVERRIDE_TYPES }],
        }),
        createPriceOverrideConfig: builder.mutation<
            PriceOverrideConfig,
            { seatId: number; payload: Omit<PriceOverrideConfigUpdatePayload, "id"> }
        >({
            query: ({ seatId, payload }) => ({
                url: `seats/${seatId}/priceOverrideConfig`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: (_, err) => (err ? [] : [{ type: PRICE_OVERRIDES_TAG }]),
        }),
        deletePriceOverrideConfig: builder.mutation<PriceOverrideConfig, number>({
            query: (priceOverrideId) => ({
                url: `seats/priceOverrideConfig/${priceOverrideId}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err) => (err ? [] : [{ type: PRICE_OVERRIDES_TAG }]),
        }),
        updatePriceOverrideConfig: builder.mutation<PriceOverrideConfig, { payload: PriceOverrideConfigUpdatePayload }>(
            {
                query: ({ payload }) => ({
                    url: `seats/priceOverrideConfig/${payload.id}`,
                    method: "PUT",
                    body: payload,
                }),
                invalidatesTags: (_, err, { payload }) =>
                    err ? [] : [{ type: PRICE_OVERRIDE_BY_ID_TAG, id: payload.id }, { type: PRICE_OVERRIDES_TAG }],
            }
        ),
    }),
    overrideExisting: false,
});

export const {
    useGetPriceOverrideConfigsQuery,
    useGetPriceOverrideConfigByIdQuery,
    useGetPriceOverrideTypesQuery,
    useCreatePriceOverrideConfigMutation,
    useDeletePriceOverrideConfigMutation,
    useUpdatePriceOverrideConfigMutation,
} = priceOverrideConfigsApi;
