import { Loading } from "@app/core/components";
import { InventroyPermissionsTable } from "@app/features/inventory/InventoryPermissions/InventoryPermissionsTable";
import { css } from "@emotion/css";
import { Col, Row } from "antd";
import { DetailsDrawerLayout } from "../../DetailsDrawerLayout";
import { useInventoryDetailsDrawerUrlId } from "../../useInventoryDetailsDrawerUrlId";
import { usePermissionsContent } from "./usePermissionsContent";

export const PermissionsContent = () => {
    const unitId = useInventoryDetailsDrawerUrlId();
    const { breadcrumbs, isLoading, permissionsEntityType } = usePermissionsContent(unitId);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Row data-sdet="inventory-permissions-content">
            <DetailsDrawerLayout title="Permissions" breadcrumbs={breadcrumbs} />
            <Col
                className={css`
                    padding: 1.5rem;
                    width: 100%;
                `}
            >
                <InventroyPermissionsTable entityType={permissionsEntityType} />
            </Col>
        </Row>
    );
};
