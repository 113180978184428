import { FC } from "react";
import { Form, InputNumber } from "antd";
import { ColProps } from "antd/lib/grid/col";
import { InputNumberProps } from "antd/lib/input-number";
import { MAX_BITRATE } from "@app/features/inventory/HierarchyForms/constants";
import { useAdUnitMaxBitrate } from "@app/features/inventory/components/FormItems/AdUnitMaxBitrate/useAdUnitMaxBitrate";

export interface AdUnitMaxBitrateProps extends InputNumberProps {
    name: typeof MAX_BITRATE;
    wrapperCol?: ColProps;
    labelCol?: ColProps;
    label: string;
}

export const AdUnitMaxBitrate: FC<AdUnitMaxBitrateProps> = ({ name, label, wrapperCol, labelCol, ...rest }) => {
    const { validator, onChange } = useAdUnitMaxBitrate();
    return (
        <Form.Item name={name} label={label} labelCol={labelCol} wrapperCol={wrapperCol} rules={[{ validator }]}>
            <InputNumber onChange={onChange} style={{ width: "100%" }} {...rest} />
        </Form.Item>
    );
};
