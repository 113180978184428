import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal, Typography } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import { Mode } from "@app/features/seatAdSources/SeatAdSourcesForm/useAdSourceForm";
import { XANDR_ID } from "../BuyerSeatsSelect";
import { gold6 } from "@rubicon/antd-components";

interface Props {
    selectedDspNetworkId: number | null | undefined;
    formMode: DealFormMode | Mode;
}

export const XandrWarningModal: FC<Props> = ({ selectedDspNetworkId, formMode }) => {
    const lastSelectedNetworkRef = useRef<number | null | undefined>();

    const isCreateOrCopy = useMemo(() => ["create", "copy"].includes(formMode), [formMode]);
    const isNetworkChange = useMemo(
        () => selectedDspNetworkId !== lastSelectedNetworkRef.current,
        [selectedDspNetworkId]
    );
    const isXandr = useMemo(() => selectedDspNetworkId === XANDR_ID, [selectedDspNetworkId]);
    const [isAcknowledged, setIsAcknowledged] = useState<boolean>(false);

    useEffect(() => {
        setIsAcknowledged(false);
    }, [selectedDspNetworkId]);

    const showModal = useMemo(
        () => isCreateOrCopy && isNetworkChange && isXandr && !isAcknowledged,
        [isCreateOrCopy, isNetworkChange, isXandr, isAcknowledged]
    );

    lastSelectedNetworkRef.current = selectedDspNetworkId;

    if (!showModal) {
        return null;
    }

    return (
        <Modal
            data-sdet="xandr-warning-modal"
            open={showModal}
            closable={false}
            title={
                <Typography.Text>
                    <WarningOutlined data-sdet="warning-icon" style={{ color: gold6 }} /> Warning
                </Typography.Text>
            }
            footer={
                <Button type="primary" onClick={() => setIsAcknowledged(true)}>
                    Ok
                </Button>
            }
        >
            <Typography.Paragraph>
                We notice you are creating or adding a Xandr Deal. If you plan to apply 3rd party DMP data to this deal
                or the associated ad source targeting, please reach out to your account team to facilitate the setup
                with Xandr. We are working with Xandr around DSP specific API restrictions to remove this requirement.
            </Typography.Paragraph>
        </Modal>
    );
};
