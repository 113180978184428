import { useMemo, useState } from "react";
import { useGetOperatingSystemsQuery } from "@app/core/services/console";
import { operatingSystemToLabelValue } from "../../../../helpers";

export const useDimensionsSelect = () => {
    const [search, setSearch] = useState("");
    const { data, isFetching } = useGetOperatingSystemsQuery();
    // Group the data by type, then sort by sort order within type. The default return order from the API gets weird
    // because later versions of operating systems sometimes get added at the end, and it makes sense to group them by type.
    // Not strictly necessary, so this can be removed if there are performance concerns.
    // TODO: Remove when endpoint fixes sort order
    const orderedData = useMemo(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        () => [...(data || [])].sort((a, b) => a.type.localeCompare(b.type) || a.sort - b.sort),
        [data]
    );
    const options = useMemo(
        () =>
            operatingSystemToLabelValue(orderedData || []).filter((option) =>
                (option.label as string).toLowerCase().includes(search.toLowerCase())
            ),
        [orderedData, search]
    );

    return {
        isLoading: isFetching,
        onBlur: () => setSearch(""),
        onSearch: setSearch,
        options,
        search,
    };
};
