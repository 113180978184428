import {
    useGetAdUnitStatsSnapshotQuery,
    useGetBrandStatsSnapshotQuery,
    useGetPublisherStatsSnapshotQuery,
    useGetSupplyStatsSnapshotQuery,
} from "@app/core/services/adStats/statsSnapshots";
import { useAppSelector } from "@app/core/store";
import { AdStat } from "@app/features/adStats";
import {
    InventoryDetailsDrawerType,
    selectInventoryDetailsDrawerType,
} from "@app/features/inventory/DetailsDrawer/reducer";

interface UseGetUnitSnapshots {
    data?: AdStat[];
}
export const useGetUnitSnapshot = (id: number, timestamp: string): UseGetUnitSnapshots => {
    const drawerType = useAppSelector(selectInventoryDetailsDrawerType);

    const { data: publisherData } = useGetPublisherStatsSnapshotQuery(
        { publisherId: id, timestamp },
        {
            skip:
                (drawerType !== InventoryDetailsDrawerType.PUBLISHER &&
                    drawerType !== InventoryDetailsDrawerType.CHANNEL) ||
                !timestamp,
        }
    );
    const { data: brandData } = useGetBrandStatsSnapshotQuery(
        { brandId: id, timestamp },
        {
            skip:
                (drawerType !== InventoryDetailsDrawerType.BRAND &&
                    drawerType !== InventoryDetailsDrawerType.BRAND_CHANNEL) ||
                !timestamp,
        }
    );
    const { data: supplyData } = useGetSupplyStatsSnapshotQuery(
        { supplyId: id, timestamp },
        { skip: drawerType !== InventoryDetailsDrawerType.SUPPLY || !timestamp }
    );
    const { data: adUnitData } = useGetAdUnitStatsSnapshotQuery(
        { adUnitId: id, timestamp },
        { skip: drawerType !== InventoryDetailsDrawerType.AD_UNIT || !timestamp }
    );

    return {
        data: publisherData || brandData || supplyData || adUnitData,
    };
};
