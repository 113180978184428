import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { useGetCurrenciesQuery } from "@app/core/services";

interface UseCurrency {
    isFetching: boolean;
    options: LabeledValue[];
}

export const useCurrency = (): UseCurrency => {
    const { data = [], isFetching } = useGetCurrenciesQuery();
    const options = useMemo(
        () => (data || []).map((currency) => ({ value: currency.id, label: currency.code })),
        [data]
    );
    return {
        isFetching,
        options,
    };
};
