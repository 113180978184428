import { useSeatAuthContext } from "@app/core/auth";
import { currency as currencyUtils } from "@rubicon/utils";
import { AD_SOURCE_COST_MODEL_FIXED } from "@app/features/deals/DealForm/useDealForm";
import { Price } from "@app/features/inventory/InventorySeat/SeatDefaultPricesPage/DefaultPricesTable/useDefaultPricesForm";

interface UsePriceInput {
    toFixed: number;
    value: string | undefined;
    prefix: string | undefined;
    suffix: string | undefined;
}

export const usePriceInput = (price: Price): UsePriceInput => {
    const { context } = useSeatAuthContext();

    const currencyInfo = currencyUtils.getCurrencyInfo(context?.floorCurrency?.code || "USD");

    const suffix = price?.costModel === AD_SOURCE_COST_MODEL_FIXED ? context?.floorCurrency?.code : "%";
    const prefix = price?.costModel === AD_SOURCE_COST_MODEL_FIXED ? currencyInfo?.symbol : undefined;
    const value = (price?.costModel === AD_SOURCE_COST_MODEL_FIXED
        ? price?.costValueFixed
        : price?.costValuePercent) as unknown as string;

    const toFixed = price?.costModel === AD_SOURCE_COST_MODEL_FIXED ? 3 : 2;
    return {
        value,
        prefix,
        suffix,
        toFixed,
    };
};
