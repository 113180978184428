import { useGetAvailableNetworksQuery } from "@app/core/services";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { Form } from "antd";
import { useParams } from "react-router-dom";

export const useExcludeDemand = () => {
    const adSourceType = Form.useWatch<AdSourcesForm["type"]>(AD_SOURCE_FIELDS.TYPE.name);
    const { seatId } = useParams<{ seatId: string }>();
    const { data: availableNetworks = [], isFetching: isLoading } = useGetAvailableNetworksQuery(
        {
            adSourceTypeId: adSourceType?.id,
            seatId: Number(seatId),
        },
        { skip: !adSourceType }
    );
    return {
        availableNetworks,
        isLoading,
    };
};
