import {
    useGetAdUnitVastStatsQuery,
    useGetBrandVastStatsQuery,
    useGetPublisherVastStatsQuery,
    useGetSeatsVastStatsQuery,
    useGetSupplyVastStatsQuery,
} from "@app/core/services/diagnostics/vastStats";
import { useAppSelector } from "@app/core/store";
import { useMemo } from "react";
import { InventoryDetailsDrawerType, selectInventoryDetailsDrawerType } from "../../../reducer";

export interface VastStatsPieProps {
    name: string;
    value: number;
    percentage: string;
}

interface GeneratedPieCount {
    [key: string]: {
        name: string;
        data: VastStatsPieProps[];
    };
}

interface UseVastStats {
    isLoading: boolean;
    isFetching: boolean;
    generatedPieCounts: GeneratedPieCount;
    handleUpdate: () => void;
}

export const useVastStats = (id: number): UseVastStats => {
    const detailsDrawer = useAppSelector(selectInventoryDetailsDrawerType) as InventoryDetailsDrawerType;

    const {
        data: seatData,
        isLoading: isSeatLoading,
        isFetching: isSeatFetching,
        refetch: refetchSeat,
    } = useGetSeatsVastStatsQuery(id, {
        skip: detailsDrawer !== InventoryDetailsDrawerType.SEAT,
    });

    const {
        data: publisherData,
        isFetching: isPublisherFetching,
        isLoading: isPublisherLoading,
        refetch: refetchPublisher,
    } = useGetPublisherVastStatsQuery(id, {
        skip:
            detailsDrawer !== InventoryDetailsDrawerType.PUBLISHER &&
            detailsDrawer !== InventoryDetailsDrawerType.CHANNEL,
    });
    const {
        data: brandData,
        isFetching: isBrandFetching,
        isLoading: isBrandLoading,
        refetch: refetchBrand,
    } = useGetBrandVastStatsQuery(id, {
        skip:
            detailsDrawer !== InventoryDetailsDrawerType.BRAND &&
            detailsDrawer !== InventoryDetailsDrawerType.BRAND_CHANNEL,
    });
    const {
        data: supplyData,
        isFetching: isSupplyFetching,
        isLoading: isSupplyLoading,
        refetch: refetchSupply,
    } = useGetSupplyVastStatsQuery(id, {
        skip: detailsDrawer !== InventoryDetailsDrawerType.SUPPLY,
    });
    const {
        data: adUnitData,
        isFetching: isAdUnitFetching,
        isLoading: isAdUnitLoading,
        refetch: refetchAdUnit,
    } = useGetAdUnitVastStatsQuery(id, {
        skip: detailsDrawer !== InventoryDetailsDrawerType.AD_UNIT,
    });

    const data = useMemo(
        () => seatData || publisherData || brandData || supplyData || adUnitData,
        [seatData, publisherData, brandData, supplyData, adUnitData]
    );

    const isLoading = useMemo(
        () => isSeatLoading || isPublisherLoading || isBrandLoading || isSupplyLoading || isAdUnitLoading,
        [isSeatLoading, isPublisherLoading, isBrandLoading, isSupplyLoading, isAdUnitLoading]
    );
    const isFetching = useMemo(
        () => isSeatFetching || isPublisherFetching || isBrandFetching || isSupplyFetching || isAdUnitFetching,
        [isSeatFetching, isPublisherFetching, isBrandFetching, isSupplyFetching, isAdUnitFetching]
    );

    const handleUpdate = () => {
        switch (detailsDrawer) {
            case InventoryDetailsDrawerType.PUBLISHER:
            case InventoryDetailsDrawerType.CHANNEL:
                refetchPublisher();
                break;
            case InventoryDetailsDrawerType.BRAND:
            case InventoryDetailsDrawerType.BRAND_CHANNEL:
                refetchBrand();
                break;
            case InventoryDetailsDrawerType.SUPPLY:
                refetchSupply();
                break;
            case InventoryDetailsDrawerType.AD_UNIT:
                refetchAdUnit();
                break;
            case InventoryDetailsDrawerType.SEAT:
            default:
                refetchSeat();
                break;
        }
    };

    const generatedPieCounts = useMemo(() => {
        const generatedPieCounts: GeneratedPieCount = {
            contentTypeCounts: {
                name: "MediaFile Content Types",
                data: [],
            },
            durationCounts: {
                name: "MediaFile Durations",
                data: [],
            },
            mediaFileCounts: {
                name: "MediaFile Counts per Creative",
                data: [],
            },
            bitRateCounts: {
                name: "MediaFile BitRates",
                data: [],
            },
            apiCounts: {
                name: "API Counts",
                data: [],
            },
            vastVersionCounts: {
                name: "Vast Versions",
                data: [],
            },
            vastSizeCounts: {
                name: "Vast Size",
                data: [],
            },
        };

        for (const childMapName in data || {}) {
            const retSeries: { name: string; value: number; percentage: string }[] = [];
            const element = data?.[childMapName];
            const total = Object.values(element || {}).reduce(
                (acc: number, value: number) => (acc += value),
                0
            ) as number;

            for (const paramName in element) {
                const paramValue = element?.[paramName];

                if (paramValue) {
                    let yValueName = paramName;

                    if (childMapName === "bitRateCounts") {
                        const parsed = parseInt(paramName);

                        if (parsed < 0) {
                            yValueName = "Unknown";
                        }
                    }
                    const value = Math.round(Number(paramValue));
                    const percentage = `${((value / total) * 100).toFixed(1)}%`;

                    retSeries.push({ name: yValueName, value, percentage });
                }
            }

            const item = generatedPieCounts[childMapName];
            if (item) {
                item.data = retSeries.sort((a, b) => {
                    return b.value - a.value;
                });
            }
        }
        return generatedPieCounts;
    }, [data]);

    return {
        generatedPieCounts,
        isLoading,
        isFetching,
        handleUpdate,
    };
};
