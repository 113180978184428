import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LabeledValue } from "antd/lib/select";
import { RootState } from "@app/core/store";

interface SeatAdSourcesPerfomanceAdBidStatsTableFilters {
    seatAdSourcesDeal: LabeledValue | undefined;
    options: LabeledValue[] | undefined;
}

export interface SeatAdSourcesPerfomanceAdBidStatsState {
    filters: SeatAdSourcesPerfomanceAdBidStatsTableFilters;
}

export const initialState: SeatAdSourcesPerfomanceAdBidStatsState = {
    filters: {
        options: undefined,
        seatAdSourcesDeal: undefined,
    },
};

const seatAdSourcesPerformanceAdBidStatsSlice = createSlice({
    name: "seatAdSourcesPerformanceAdBidStats",
    initialState,
    reducers: {
        setFiltersSeatAdSourcesDeal: (state, action: PayloadAction<LabeledValue>) => {
            state.filters.seatAdSourcesDeal = action.payload;
        },
        setAllSeatAdSourcesDealOptions: (state, action: PayloadAction<LabeledValue[]>) => {
            if (!state.filters.seatAdSourcesDeal) {
                state.filters.seatAdSourcesDeal = action.payload[0];
            }
            state.filters.options = action.payload;
        },
        reset: (state) => {
            state.filters = initialState.filters;
        },
    },
});

export const selectSeatAdSourcesPerfomanceAdBidStatsFilters = (state: RootState) =>
    state.seatAdSourcesPerformanceAdBidStats.filters;
export const { setFiltersSeatAdSourcesDeal, setAllSeatAdSourcesDealOptions, reset } =
    seatAdSourcesPerformanceAdBidStatsSlice.actions;

export const seatAdSourcesPerformanceAdBidStatsReducer = seatAdSourcesPerformanceAdBidStatsSlice.reducer;
