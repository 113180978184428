import { FC } from "react";
import { Col, Row, Typography } from "antd";
import { LoadableCell } from "@app/core/components";
import { SeatAdSourcesCpmRate } from "./SeatAdSourcesCpmRate";
import { getPriceTypeToBeDisplayed } from "./getPriceTypeToBeDisplayed";
import { AdSourceListItem, Currency } from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import { CurrencyConversionModes } from "@app/core/clients/console";
import { useCurrency } from "@app/features/seatAdSources/useCurrency";
import { convert } from "@app/features/seatAdSources/helpers";
import {
    selectAdSourcesCurrencyConversionMode,
    selectAdSourcesPreferredCurrencyId,
} from "@app/features/seatAdSources/reducer";

interface Props {
    adSource: AdSourceListItem;
    cpmRate: number;
    currency: Currency;
}

export const SeatAdSourcesListTableCpmRateCell: FC<Props> = ({ cpmRate, currency, adSource }) => {
    const adSourcesCurrencyConversionMode = useAppSelector(selectAdSourcesCurrencyConversionMode);
    const adSourcesPreferredCurrency = useAppSelector(selectAdSourcesPreferredCurrencyId);
    const { currencyConversions, getCurrencyById } = useCurrency();

    const isOriginatingCurrency = adSourcesCurrencyConversionMode === CurrencyConversionModes.ORIGINATING_CURRENCY;
    const filteredCpmRate = isOriginatingCurrency
        ? cpmRate
        : convert(cpmRate, currency.id, currencyConversions, adSourcesPreferredCurrency as number);
    const currencyCode = isOriginatingCurrency
        ? currency.code
        : (getCurrencyById(adSourcesPreferredCurrency as number)?.code as string);

    const cpmCell = (
        <Row>
            <Col span={24}>
                <Typography.Text>
                    <SeatAdSourcesCpmRate cpmRate={filteredCpmRate} currencyCode={currencyCode} adSource={adSource} />
                </Typography.Text>
            </Col>
            <Col span={24}>
                <Typography.Text type="secondary">{getPriceTypeToBeDisplayed(adSource)}</Typography.Text>
            </Col>
        </Row>
    );

    return <LoadableCell value={cpmCell} isLoading={cpmRate === undefined} />;
};
