import { Layout } from "antd";
import { ClassificationsLabelsTable } from "./ClassificationsLabelsTable";
import { ClassificationsLabelsTabDrawer } from "./ClassificationsLabelsTabDrawer";

export const ClassificationsLabelsTab = () => {
    return (
        <Layout.Content>
            <ClassificationsLabelsTable />
            <ClassificationsLabelsTabDrawer />
        </Layout.Content>
    );
};
