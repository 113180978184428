import { useGetUserGroupsQuery } from "@app/core/services";
import debounce from "lodash.debounce";
import { useMemo, useState } from "react";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";

interface UseUserGroups {
    userGroups: {
        label: string;
        value: number;
    }[];
    isLoading: boolean;
    search: string;
    handleChangeSearch: (value: string) => void;
}

export const useUserGroups = (): UseUserGroups => {
    const [search, setSearch] = useState("");
    const [keyword, setKeyword] = useState("");
    const debouncedKeyword = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setKeyword]
    );
    const handleChangeSearch = (value: string) => {
        setSearch(value);
        debouncedKeyword(value);
    };
    const { data, isFetching } = useGetUserGroupsQuery({ keyword: keyword });
    const userGroups = useMemo(
        () =>
            (data || []).map(({ id, name }) => ({
                label: name,
                value: id,
            })),
        [data]
    );

    return {
        userGroups,
        isLoading: isFetching,
        search,
        handleChangeSearch,
    };
};
