import { FC } from "react";
import { getExtraUseRate } from "@app/features/inventory/HierarchyForms/helpers";
import { BRAND_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { CalculonUseRateOverride } from "@app/features/inventory/components/FormItems";
import { useBrandFormInstance } from "@app/features/inventory/HierarchyForms/BrandForm/BrandFormInstance";
import { FormItemProps } from "antd";

type RateFormFieldsProps =
    | typeof BRAND_FORM_FIELDS.LOWER_CALCULON_USE_RATE_OVERRIDE
    | typeof BRAND_FORM_FIELDS.UPPER_CALCULON_USE_RATE_OVERRIDE;

type RateFormItemProps = RateFormFieldsProps & {
    formItemProps: Omit<FormItemProps, "label" | "name" | "extra">;
    type: "min" | "max";
};

export const RateOverride: FC<RateFormItemProps> = ({ name, ...rest }) => {
    const { mode, brand } = useBrandFormInstance();
    const isEditMode = mode === "edit";
    const isLowerRate: boolean = name === BRAND_FORM_FIELDS.LOWER_CALCULON_USE_RATE_OVERRIDE.name;
    const extra: string | undefined = getExtraUseRate(isEditMode, brand, isLowerRate);
    return (
        <CalculonUseRateOverride
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 24, xxl: 20 }}
            extra={extra}
            name={name}
            {...rest}
        />
    );
};
