import { PageHeader } from "@ant-design/pro-layout";
import { Loading } from "@app/core/components";
import { useDeleteSeatBuyerSeatListMutation } from "@app/core/services";
import { Button, Layout } from "antd";
import { BrandSafetyDeleteModal } from "../../BrandSafetyDeleteModal";
import { useBrandSafetyDeleteModal } from "../../BrandSafetyDeleteModal/useBrandSafetyDeleteModal";
import { BuyerSeatListsForm } from "../BuyerSeatListsForm";
import { BuyerSeatListsHelpKeys } from "../BuyerSeatListsHelpKeys";
import { useBuyerSeatListsEditPage } from "./useBuyerSeatListsEditPage";

export const BuyerSeatListsEditPage = () => {
    const {
        name,
        isLoading,
        isSubmitting,
        handleBack,
        handleCancel,
        handlePostDelete,
        handleSubmit,
        initialValues,
        buyerSeatListId,
    } = useBuyerSeatListsEditPage();
    const [deleteTrigger, { isLoading: isDeleting }] = useDeleteSeatBuyerSeatListMutation();
    const { handleOpen, ...restModalProps } = useBrandSafetyDeleteModal(deleteTrigger, handlePostDelete);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Layout>
            <PageHeader title={`Edit: ${name}`} onBack={handleBack} />
            <BuyerSeatListsForm
                isSaving={isSubmitting}
                initialValues={initialValues}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
                deleteButton={
                    <>
                        <Button
                            danger
                            data-sdet="delete-button"
                            type="primary"
                            onClick={() => handleOpen({ id: buyerSeatListId, name })}
                        >
                            Delete
                        </Button>
                        <BrandSafetyDeleteModal isDeleting={isDeleting} {...restModalProps} />
                    </>
                }
            >
                <BuyerSeatListsHelpKeys />
            </BuyerSeatListsForm>
        </Layout>
    );
};
