import { selectGetCurrenciesConversionsResult, selectGetCurrenciesResult } from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import { CURRENCY_DEFS } from "@app/core/utils/currencies";

export const useCurrencyConversion = () => {
    const currencies = useAppSelector(selectGetCurrenciesResult);
    const currencyConversions = useAppSelector(selectGetCurrenciesConversionsResult);
    const preferredCurrencyId = useAppSelector(
        (state) => state.inventoryHealth.settings.values.inventoryPreferredCurrency
    );
    const preferredCurrency = currencies.data?.find((currency) => currency.id === preferredCurrencyId);

    const currencyConfig = CURRENCY_DEFS[preferredCurrency?.code as string];

    const getCurrencyById = (id: number) => {
        return currencies.data?.find((currency) => currency.id === id);
    };

    return {
        preferredCurrency,
        currencyConversions: currencyConversions?.data || [],
        currencyConfig,
        getCurrencyById,
    };
};
