import { FC } from "react";
import { Form, Input } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { useAdSourceNameField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceConfigurationSection/Fields/AdSourceNameField/useAdSourceNameField";

export const AdSourceNameField: FC = () => {
    const { value, handleChange, handleBlur } = useAdSourceNameField();
    return (
        <Form.Item
            data-sdet="ad-source-name-field"
            label="Ad Source Name"
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_NAME}
            rules={[
                { required: AD_SOURCE_FIELDS.NAME.validations.required, message: "Ad Source Name is required" },
                {
                    max: AD_SOURCE_FIELDS.NAME.validations.max,
                    message: `Ad Source Name cannot be longer than ${AD_SOURCE_FIELDS.NAME.validations.max} characters`,
                },
                () => ({
                    validator(_, value) {
                        if (AD_SOURCE_FIELDS.NAME.validations.isOnlySpaces(value)) {
                            return Promise.reject("Ad Source Name should not contain only spaces");
                        }
                        return Promise.resolve();
                    },
                }),
            ]}
        >
            <Input
                data-sdet="ad-source-name-input"
                onChange={handleChange}
                value={value}
                onBlur={handleBlur}
                allowClear
            />
        </Form.Item>
    );
};
