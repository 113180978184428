import { NONE } from "@app/core/components/constants";
import { BrandSupply } from "@app/core/services";
import { GUTTER, TWO_COL_SIZES } from "@app/features/inventory/constants";
import { Col, Form, Row, Tag } from "antd";
import { FC } from "react";
import { FORM_ITEM_OFFSET, SUPPLY_SUPPLY_DETAILS_LABELS } from "../../../constants";
import { getYesNoLabel } from "@app/core/utils/labels";
import { InternalOnly } from "@app/features/inventory/components/InternalOnly";

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 12, xl: 12, xxl: 12 };
const { Item } = Form;
interface Props {
    supply: BrandSupply;
    isInternalOnly: boolean;
}
export const SupplyDetails: FC<Props> = ({ isInternalOnly, supply }) => {
    return (
        <Row data-sdet="supply-supply-details">
            <Col {...TWO_COL_SIZES}>
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <Item label={SUPPLY_SUPPLY_DETAILS_LABELS.video} className={FORM_ITEM_OFFSET}>
                            {supply?.videoQuality?.name ?? NONE}
                        </Item>
                    </Col>
                    <Col {...COL_SIZES}>
                        <Item label={SUPPLY_SUPPLY_DETAILS_LABELS.srcRelationship} className={FORM_ITEM_OFFSET}>
                            {supply?.sourceRelationship?.name ?? NONE}
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <Item label={SUPPLY_SUPPLY_DETAILS_LABELS.ifa} className={FORM_ITEM_OFFSET}>
                            {supply?.ifaType?.name ?? "Automatically Detect"}
                        </Item>
                    </Col>
                    <Col {...COL_SIZES}>
                        <Item label={SUPPLY_SUPPLY_DETAILS_LABELS.wrapper} className={FORM_ITEM_OFFSET}>
                            {getYesNoLabel(supply?.allowVastWrappers)}
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <Item label={SUPPLY_SUPPLY_DETAILS_LABELS.coppa} className={FORM_ITEM_OFFSET}>
                            {getYesNoLabel(Boolean(supply?.isCoppa))}
                        </Item>
                    </Col>
                    <Col {...COL_SIZES}>
                        <Item label={SUPPLY_SUPPLY_DETAILS_LABELS.privacyPolicy} className={FORM_ITEM_OFFSET}>
                            {getYesNoLabel(Boolean(supply?.hasPrivacyPolicy))}
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col span={12}>
                        <Item label={SUPPLY_SUPPLY_DETAILS_LABELS.keywords} className={FORM_ITEM_OFFSET}>
                            {supply?.keywords?.length ? supply.keywords.map((key) => <Tag key={key}>{key}</Tag>) : NONE}
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col span={12}>
                        <Item label={SUPPLY_SUPPLY_DETAILS_LABELS.mask} className={FORM_ITEM_OFFSET}>
                            {supply?.maskIFAForNetworks?.length
                                ? supply.maskIFAForNetworks.map(({ id, name, seat }) => (
                                      <Tag key={id}>{`${seat?.name} > ${name}`}</Tag>
                                  ))
                                : NONE}
                        </Item>
                    </Col>
                </Row>
            </Col>
            <Col hidden={!isInternalOnly} {...TWO_COL_SIZES}>
                <InternalOnly>
                    <Item label={SUPPLY_SUPPLY_DETAILS_LABELS.viewability} className={FORM_ITEM_OFFSET}>
                        {supply?.viewabilityVendors?.length
                            ? supply.viewabilityVendors.map((vendor) => <Tag key={vendor.id}>{vendor.name}</Tag>)
                            : NONE}
                    </Item>
                </InternalOnly>
            </Col>
        </Row>
    );
};
