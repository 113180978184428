import { FC, ReactNode } from "react";
import { Form, Select } from "antd";
import { useParams } from "react-router-dom";
import { useGetSeatAdvertiserDomainFilterListDefsQuery } from "@app/core/services";

interface Props {
    name: string;
    label: ReactNode;
}

const BLOCK_TYPE_ID = 1;

export const BlockedAdvertiserDomainListsFormItem: FC<Props> = ({ name, label }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data = [], isFetching } = useGetSeatAdvertiserDomainFilterListDefsQuery({
        seatId: Number(seatId),
        typeId: BLOCK_TYPE_ID,
    });

    return (
        <Form.Item name={name} label={label} style={{ maxWidth: "615px" }}>
            <Select
                allowClear
                labelInValue
                showSearch
                mode="multiple"
                optionFilterProp="label"
                options={data.map((list) => ({
                    value: list.id,
                    label: list.name,
                }))}
                loading={isFetching}
                placeholder="Select Blocked Advertiser Domain Lists"
            />
        </Form.Item>
    );
};
