import { useInventoryDetailsDrawerUrlId } from "../../../useInventoryDetailsDrawerUrlId";
import { useNestedData } from "../../useNestedData";

interface UseDefinitionLadleContent {
    isLoading: boolean;
    breadcrumbs?: string;
}

export const useDefinitionLadleContent = (): UseDefinitionLadleContent => {
    const unitId = useInventoryDetailsDrawerUrlId();
    const { nestedBreadcrumbs: breadcrumbs, isLoading } = useNestedData(unitId);
    const breadCrumbsWithCreative = breadcrumbs ? `${breadcrumbs} ${breadcrumbs && "/"} Ladle` : "Ladle";

    return {
        isLoading,
        breadcrumbs: breadCrumbsWithCreative,
    };
};
