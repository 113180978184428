import { AdStat, EntityTypes, useInventoryHealthStats } from "@app/features/adStats";
import { useEffect, useMemo } from "react";
import { InventoryDetailsDrawerType } from "../../../reducer";
import { useNestedData } from "../../useNestedData";

interface UseSnapshotsUnit {
    adStatHistory: AdStat[] | null;
    isLoading: boolean;
    breadcrumbs?: string;
}

export const useSnapshotsUnitContent = (
    drawerType: InventoryDetailsDrawerType,
    id: number | string
): UseSnapshotsUnit => {
    const { nestedBreadcrumbs, isLoading } = useNestedData(id);

    let adStatsEntity = EntityTypes.Publisher;
    switch (drawerType) {
        case InventoryDetailsDrawerType.PUBLISHER:
        case InventoryDetailsDrawerType.CHANNEL:
            adStatsEntity = EntityTypes.Publisher;
            break;

        case InventoryDetailsDrawerType.BRAND:
        case InventoryDetailsDrawerType.BRAND_CHANNEL:
            adStatsEntity = EntityTypes.Brand;
            break;
        case InventoryDetailsDrawerType.SUPPLY:
            adStatsEntity = EntityTypes.Supply;
            break;
        case InventoryDetailsDrawerType.AD_UNIT:
            adStatsEntity = EntityTypes.AdUnit;
            break;
    }
    const { setEntityIds, adStatsHistoryById } = useInventoryHealthStats(adStatsEntity);
    const adStatHistory = useMemo(() => adStatsHistoryById[id], [adStatsHistoryById, id]);

    useEffect(() => setEntityIds([Number(id)]), [id, setEntityIds]);

    return {
        adStatHistory,
        isLoading,
        breadcrumbs: nestedBreadcrumbs,
    };
};
