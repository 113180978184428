import { Form, Radio } from "antd";
import { REDISTRIBUTION_OPTIONS } from "@app/features/deals/constants";
import { useAdSourceRedistributionField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourcePacingSection/Fields/AdSourceRedistributionField/useAdSourceRedistributionField";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";

export const AdSourceRedistributionField = () => {
    const { value, onChange } = useAdSourceRedistributionField();

    return (
        <Form.Item label={AD_SOURCE_FIELDS.PACING_REDISTRIBUTION.label} data-sdet="pacing-redistribution">
            <Radio.Group onChange={(e) => onChange(e?.target?.value)} value={value} options={REDISTRIBUTION_OPTIONS} />
        </Form.Item>
    );
};
