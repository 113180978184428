import { Form } from "antd";
import { FC } from "react";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const Boxing: FC = () => {
    return (
        <Form.Item name={AD_UNIT_FORM_FIELDS.BOXING.name} label={AD_UNIT_FORM_FIELDS.BOXING.label}>
            <BooleanRadioGroup />
        </Form.Item>
    );
};
