import { FC } from "react";
import { Form } from "antd";
import { LabeledValue } from "antd/lib/select";
import { ReadonlyControl } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { LoadOnScrollSelect } from "../LoadOnScrollSelect";
import { TagRender } from "../TagRender";
import { Mode } from "@app/features/seatAdSources/SeatAdSourcesForm/useAdSourceForm";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import { BuyerSeatsSelectLabel } from "./BuyerSeatsSelectLabel";
import { DspSyncInfoBlock } from "./DspSyncInfoBlock";
import { useBuyerSeatsSelect } from "./useBuyerSeatsSelect";

interface Props {
    formItemName: string | string[];
    formMode: DealFormMode | Mode;
    selectedDspNetworkId: number | null;
    selectedBuyerSeatIds: number[] | null | undefined;
    createdWithBuyerSeats: boolean;
    isProgrammaticGuaranteed: boolean;
    isReadonly?: boolean;
    handleChange?: (value: LabeledValue[]) => void;
}

export const BuyerSeatsSelect: FC<Props> = ({
    formItemName,
    formMode,
    selectedDspNetworkId,
    selectedBuyerSeatIds = [],
    createdWithBuyerSeats,
    isReadonly,
    isProgrammaticGuaranteed,
    handleChange,
}) => {
    const {
        hasMore,
        hasNoAvailableBuyers,
        isBuyerSelected,
        isDisabled,
        isExternalDsp,
        isFetching,
        hasValidationError,
        options,
        search,
        validationRules,
        loadMore,
        handleSearch,
    } = useBuyerSeatsSelect(
        formMode,
        selectedDspNetworkId,
        selectedBuyerSeatIds,
        createdWithBuyerSeats,
        isProgrammaticGuaranteed
    );

    return (
        <Form.Item
            data-sdet="buyer-seats-select"
            label={
                <BuyerSeatsSelectLabel
                    selectedDspNetworkId={selectedDspNetworkId}
                    formMode={formMode}
                    isBuyerSelected={isBuyerSelected}
                    isProgrammaticGuaranteed={isProgrammaticGuaranteed}
                />
            }
            help={
                hasValidationError ? undefined : (
                    <DspSyncInfoBlock isBuyerSelected={isBuyerSelected} isExternalDsp={isExternalDsp} />
                )
            }
            name={formItemName}
            rules={validationRules}
        >
            {isReadonly ? (
                <ReadonlyControl<LabeledValue[]> getLabel={(value) => value?.map(({ label }) => label).join(", ")} />
            ) : (
                <LoadOnScrollSelect
                    allowClear
                    data-sdet="deal-buyer-select"
                    disabled={isDisabled}
                    filterOption={false}
                    hasMore={hasMore}
                    labelInValue
                    loading={isFetching}
                    loadMore={loadMore}
                    mode="multiple"
                    notFoundContent={hasNoAvailableBuyers ? "No Buyers Available" : undefined}
                    onBlur={() => handleSearch("")}
                    onSearch={handleSearch}
                    options={options}
                    placeholder="Search Buyers"
                    searchValue={search}
                    tagRender={TagRender}
                    onChange={handleChange}
                />
            )}
        </Form.Item>
    );
};
