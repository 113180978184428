import { useState, useMemo, useRef, useEffect, useCallback, ReactNode } from "react";
import { Typography } from "antd";
import { useHistory } from "react-router-dom";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { ROUTE_FORMATTERS } from "@app/core/routing/routes";
import { SearchItem, SearchItemSeat, useSearchQuery } from "@app/core/services/console/search";
import { INVENTORY_LIST_PAGES } from "../inventory/constants";
import { REPORTS_ROUTES } from "../reports/constants";
import { CONTROLS_ROUTES } from "../controls/constants";
import { INTEGRATIONS_ROUTES_CONSOLE } from "../integrations/integrationsConsole/constant";
import { isDemandOrRtbSeat } from "@app/core/utils";
import { InventoryDetailsDrawerType } from "../inventory/DetailsDrawer/reducer";

const SEAT_URLS = [
    { formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_DASHBOARD(seatId), label: "Dashboard" },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_INVENTORY(seatId, INVENTORY_LIST_PAGES.HEALTH),
        label: "Inventory",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_AD_SOURCES(seatId),
        label: "Ad Sources",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_REPORTS_CONSOLE_TABS(seatId),
        label: "Custom Reports",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_REPORTS_CONSOLE_TABS(seatId, REPORTS_ROUTES.HIERARCHICAL),
        label: "Hierarchical Reports",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_REPORTS_CONSOLE_TABS(seatId, REPORTS_ROUTES.AUCTIONS),
        label: "Auction Reports",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_REPORTS_CONSOLE_TABS(seatId, REPORTS_ROUTES.DEMAND),
        label: "Demand Reports",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_REPORTS_CONSOLE_TABS(seatId, REPORTS_ROUTES.SUPPLY),
        label: "Supply Reports",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_REPORTS_CONSOLE_TABS(seatId, REPORTS_ROUTES.FLOORS),
        label: "Floor Reports",
    },
    { formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_DEAL_HEALTH(seatId), label: "Deals Overview" },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.TAG_PARAMETERS),
        label: "Tag Parameters",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.SEAT_CONTACTS),
        label: "Seat Contacts",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.ENCRYPTION_KEYS),
        label: "Encryption Keys",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.CUSTOM_PIXELS),
        label: "Custom Pixels",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.ADVANCED_FLOORS),
        label: "Advanced Floors",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.ADVANCED_AD_BREAKS),
        label: "Advanced Ad Breaks",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.REUSABLE_TARGETING),
        label: "Targeting",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.ADVERTISER_DOMAINS),
        label: "Advertiser Domains",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.SEQUESTERED_DOMAINS),
        label: "Sequestered Domains",
    },
    {
        formatter: (seatId: number) =>
            ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.TRANSPARENCY_DEFAULT),
        label: "Transparency Default",
    },
    {
        formatter: (seatId: number) =>
            ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.ADVANCED_BRAND_SAFETY),
        label: "Advanced Brand Safety",
    },
    {
        formatter: (seatId: number) =>
            ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.COMPETITIVE_SEPARATION_GROUPS),
        label: "Competitive Separation Groups",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.BULK_OPRATIONS),
        label: "Bulk Operations",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.ADVERTISERS),
        label: "Advertisers",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.INDUSTRIES),
        label: "Industries",
    },
    {
        formatter: (seatId: number) =>
            ROUTE_FORMATTERS.SEAT_INTEGRATIONS_TABS_CONSOLE(seatId, INTEGRATIONS_ROUTES_CONSOLE.SSP_CONNECT),
        label: "SSP Connect",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.ADMIN_SEAT(seatId),
        label: "Admin",
    },
];

const SEAT_RTB_DEMAND_URLS = [
    { formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_DASHBOARD(seatId), label: "Dashboard" },
    { formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_DEMAND(seatId), label: "Demand" },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_DIAGNOSTICS_DEAL_DASHBOARD(seatId),
        label: "Deal Dashboard",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_DIAGNOSTICS_ADVERTISER_DASHBOARD(seatId),
        label: "Advertiser Dashboard",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_DIAGNOSTICS_BIDDER_DIANOSTICS(seatId),
        label: "Bidder Diagnostics",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_REPORTS_CONSOLE_TABS(seatId),
        label: "Custom Reports",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_DIAGNOSTICS_LADLE_RESULTS_ALL(seatId),
        label: "Ladle Results",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.REUSABLE_TARGETING),
        label: "Targeting",
    },
    {
        formatter: (seatId: number) =>
            ROUTE_FORMATTERS.SEAT_INTEGRATIONS_TABS_CONSOLE(seatId, INTEGRATIONS_ROUTES_CONSOLE.SSP_CONNECT),
        label: "SSP Connect",
    },
    {
        formatter: (seatId: number) => ROUTE_FORMATTERS.ADMIN_SEAT(seatId),
        label: "Admin",
    },
];

const generateSeatOptions = (seat: SearchItemSeat) => {
    return (isDemandOrRtbSeat(seat) ? SEAT_RTB_DEMAND_URLS : SEAT_URLS).map((url) => ({
        url: url.formatter(seat.id),
        label: (
            <>
                {seat.entityType}: {seat.name} - <Typography.Text strong>{url.label}</Typography.Text>
            </>
        ),
    }));
};

const generatePublisherChannelOptions = (datum) => {
    return {
        url: ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS(
            datum.seat.id,
            InventoryDetailsDrawerType.CHANNEL,
            datum.id
        ),
        label: `${datum.entityType}: ${datum.seat.name} > ${datum.name}`,
    };
};

const generateBrandChannelOptions = (datum) => {
    return {
        url: ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_UNIT(
            datum.publisher.seat.id,
            InventoryDetailsDrawerType.BRAND_CHANNEL,
            datum.publisher.id,
            datum.id
        ),
        label: `${datum.entityType}: ${datum.publisher.seat.name} > ${datum.publisher.name} > ${datum.name}`,
    };
};

const generatePublisherOptions = (datum) => {
    return {
        url: ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS(
            datum.seat.id,
            InventoryDetailsDrawerType.PUBLISHER,
            datum.id
        ),
        label: `${datum.entityType}: ${datum.seat.name} > ${datum.name}`,
    };
};

const generateBrandOptions = (datum) => {
    return {
        url: ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_UNIT(
            datum.publisher.seat.id,
            InventoryDetailsDrawerType.BRAND,
            datum.publisher.id,
            datum.id
        ),
        label: `${datum.entityType}: ${datum.publisher.seat.name} > ${datum.publisher.name} > ${datum.name}`,
    };
};

const generateSupplyOptions = (datum) => {
    return {
        url: ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_UNIT(
            datum.brand.publisher.seat.id,
            InventoryDetailsDrawerType.SUPPLY,
            datum.brand.publisher.id,
            datum.brand.id,
            datum.id
        ),
        label: `${datum.entityType}: ${datum.brand.publisher.seat.name} >  ${datum.brand.publisher.name} > ${datum.brand.name} > ${datum.name}`,
    };
};

const generateAdUnitOptions = (datum) => {
    return {
        url: ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_UNIT(
            datum.supply.brand.publisher.seat.id,
            InventoryDetailsDrawerType.AD_UNIT,
            datum.supply.brand.publisher.id,
            datum.supply.brand.id,
            datum.supply.id,
            datum.id
        ),
        label: `${datum.entityType}: ${datum.supply.brand.publisher.seat.name} >  ${datum.supply.brand.publisher.name} > ${datum.supply.brand.name} > ${datum.supply.name} > ${datum.name}`,
    };
};

const generateChannelAdUnitOptions = (datum) => {
    return {
        url: ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_UNIT(
            datum.supply.brand.publisher.seat.id,
            InventoryDetailsDrawerType.BRAND_CHANNEL,
            datum.supply.brand.publisher.id,
            datum.supply.brand.id
        ),
        label: `${datum.entityType}: ${datum.supply.brand.publisher.seat.name} >  ${datum.supply.brand.publisher.name} > ${datum.supply.brand.name} > ${datum.supply.name} > ${datum.name}`,
    };
};

const generateAdSourceOptions = (datum) => {
    if (!datum.seat) {
        // TODO: Connect to /ssp/admin/marketplaces/1/sources/52046 when we migrate admin app
        return [];
    }
    return {
        url: ROUTE_FORMATTERS.SEAT_AD_SOURCES_DETAILS(datum.seat.id, datum.id),
        label: `${datum.entityType}: ${datum.seat.name} > ${datum.name}`,
    };
};

const generateDealOptions = (datum) => {
    if (!datum.seat) {
        // TODO: Connect to /ssp/admin/marketplaces/1/deals/65759 when we migrate admin app
        return [];
    }
    return {
        url: ROUTE_FORMATTERS.SEAT_DEAL_DETAILS(datum.seat.id, datum.id),
        label: `${datum.entityType}: ${datum.seat.name} > ${datum.name}`,
    };
};

export const useSearchBar = () => {
    const history = useHistory();
    const ref = useRef(null);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState<number>(1);
    const [keyword, setKeyword] = useState("");
    const { data, isFetching } = useSearchQuery({ keyword, page, max: 25 }, { skip: !keyword });

    const getOptionConfigurations = (datum: SearchItem) => {
        switch (datum.entityType) {
            case "Seat":
                return generateSeatOptions(datum);
            case "Channel":
                if (datum.seat) {
                    return generatePublisherChannelOptions(datum);
                }
                if (datum.publisher) {
                    return generateBrandChannelOptions(datum);
                }
            case "Publisher":
                return generatePublisherOptions(datum);
            case "Brand":
                return generateBrandOptions(datum);
            case "Supply":
                return generateSupplyOptions(datum);
            case "ChannelAdUnit":
                return generateChannelAdUnitOptions(datum);
            case "AdUnit":
                return generateAdUnitOptions(datum);
            case "AdSource":
                return generateAdSourceOptions(datum);
            case "DemandDeal":
            case "BuyerDeal":
            case "Deal": // legacy deal
                return generateDealOptions(datum);
            default:
                return [];
        }
    };

    const getOptions = useCallback((datum: SearchItem) => {
        const optionConfigurations = getOptionConfigurations(datum);

        return (Array.isArray(optionConfigurations) ? optionConfigurations : [optionConfigurations]).map(
            (configuration) => ({
                label: <Typography.Text>{configuration.label}</Typography.Text>,
                url: configuration.url,
                value: `${datum.entityType}-${datum.id}-${configuration.url}`,
            })
        );
    }, []);

    const options = useMemo(() => {
        return (ref.current || []).concat(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (data || []).map((datum) => getOptions(datum)).flat()
        );
    }, [data, getOptions]);

    const debouncedSetKeyword = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setKeyword]
    );

    const handleChangeSearch = (value: string) => {
        setSearch(value);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref.current = [];
        debouncedSetKeyword(value);
    };

    const handleSearchSelected = (option: { value: string; url: string; label: ReactNode }) => {
        history.push(option.url);
    };

    const handleScroll = ({ target }) => {
        const isEndOfList = target.scrollTop + target.offsetHeight === target.scrollHeight;
        if (isEndOfList && data?.length && !isFetching) {
            setPage(page + 1);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref.current = options;
    }, [options]);

    return {
        handleScroll,
        handleChangeSearch,
        handleSearchSelected,
        isLoading: isFetching,
        options,
        search,
    };
};
