import { ColumnConfig } from "@ant-design/charts";
import { CreativeDetails } from "@app/core/services/diagnostics/mediaUrls";
import { useMemo } from "react";

// cap loudness chart data to -65 lufs
// anything at or smaller is considered a zero on chart
// assumes loudness is always negative
const getUpperBoundLoudness = (loudness: number) => {
    return loudness === 0 || loudness < -65 ? 0 : +(65 - Math.abs(loudness)).toFixed(1);
};
const getLowerBoundLoudness = (loudness: number) => {
    return loudness === 0 || loudness < -65 ? 0 : +(-65 - loudness).toFixed(1);
};

export const useCreativeReviewLoudnessChart = (loudnessSamples: CreativeDetails["loudnessSamples"]) => {
    const samples = useMemo(
        () =>
            loudnessSamples.length
                ? [
                      { loudness: 0, clipTime: 0.0 },
                      ...loudnessSamples,
                      {
                          loudness: 0,
                          clipTime: (loudnessSamples.at(-1)?.clipTime ?? 0) + 0.1,
                      },
                  ]
                : [],
        [loudnessSamples]
    );
    const chartData = samples.map((item) => ({
        clipTime: `${item.clipTime}`,
        loudness: [getUpperBoundLoudness(item.loudness), getLowerBoundLoudness(item.loudness)],
    }));

    const config: ColumnConfig = {
        color: "#878484",
        data: chartData,
        xField: "clipTime",
        yField: "loudness",
        axis: {
            y: {
                labelFormatter: () => "",
            },
        },
        yAxis: {
            label: {
                formatter: () => "",
            },
            grid: {
                line: {
                    style: {
                        lineWidth: 0,
                        opacity: 0,
                    },
                },
            },
            line: {
                style: {
                    lineWidth: 0,
                    opacity: 0,
                },
            },
        },
        xAxis: {
            label: {
                formatter() {
                    return "";
                },
            },
            grid: {
                line: {
                    style: {
                        opacity: 0,
                    },
                },
            },
            line: {
                style: {
                    opacity: 0,
                },
            },
            tickLine: {
                style: {
                    opacity: 0,
                },
            },
        },
        brush: {
            enabled: true,
            type: "x-rect",
        },
        isRange: true,
        theme: "dark",
        tooltip: (datum) => {
            return {
                value: `${datum.loudness[1] === 0 ? -(65).toFixed(1) : (-65 - datum.loudness[1]).toFixed(1)} LUFS`, // calculate back to lufs from chartData
            };
        },
    };

    return { config };
};
