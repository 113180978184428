import { download } from "@rubicon/utils";
import { TargetingDimension } from "../types";
import { TargetingDimensionTypes, SegmentRuleModes } from "../constants";
import { MinDurationTarget, MaxDurationTarget } from "@app/features/targeting";
import {
    Audience,
    Category,
    ContentCategory,
    ContentMetadataContentLength,
    ContentMetadataTitles,
    ContentMetadataTvSeries,
    DayPartTarget,
    GeoTarget,
    HasIdName,
    LabelValue,
    MimeType,
    OperatingSystem,
    OztamDemo,
    Platform,
    Size,
    SupplyType,
    TargetingCreatePayload,
} from "@app/core/services/console";
import { Conditions } from "../constants";

const TYPE_TO_DOWNLOAD_ARGS = {
    [TargetingDimensionTypes.AdBreakPositionTargets]: {
        headers: {
            adBreakPosition: "Ad Break Position",
        },
        mapper: (v: number) => ({
            adBreakPosition: v,
        }),
    },
    [TargetingDimensionTypes.Audiences]: {
        headers: {
            id: "ID",
            name: "Name",
            description: "Description",
            minPriceUSD: "Min Price USD",
            maxPriceUSD: "Max Price USD",
        },
        mapper: (v: Audience) => ({
            id: v.id,
            name: v.name,
            description: v.description,
            minPriceUSD: v.minPriceUSD,
            maxPriceUSD: v.maxPriceUSD,
        }),
    },
    [TargetingDimensionTypes.ApiFrameworks]: {
        headers: {
            id: "ID",
            name: "Name",
        },
        mapper: (v: HasIdName) => ({
            id: v.id,
            name: v.name,
        }),
    },
    [TargetingDimensionTypes.BundleIdTargets]: {
        headers: {
            bundleId: "Bundle ID",
        },
        mapper: (v: string) => ({
            bundleId: v,
        }),
    },
    [TargetingDimensionTypes.Bvod]: {
        headers: {
            id: "ID",
            name: "Name",
        },
        mapper: (v: OztamDemo) => ({
            id: v.id,
            name: v.name,
        }),
    },
    [TargetingDimensionTypes.Categories]: {
        headers: {
            id: "ID",
            name: "Name",
        },
        mapper: (v: Category) => ({
            id: v.id,
            name: v.name,
        }),
    },
    [TargetingDimensionTypes.ContentCategories]: {
        headers: {
            id: "ID",
            name: "Name",
            parentId: "Parent ID",
            parentName: "Parent Name",
        },
        mapper: (v: ContentCategory) => ({
            id: v.id,
            name: v.name,
            parentId: v.parentId || "",
            parentName: v.parentName || "",
        }),
    },
    [TargetingDimensionTypes.ContentChannels]: {
        headers: {
            contentChannel: "Content Channel",
        },
        mapper: (v: string) => ({
            contentChannel: v,
        }),
    },
    [TargetingDimensionTypes.ContentLengths]: {
        headers: {
            id: "ID",
            name: "Name",
        },
        mapper: (v: ContentMetadataContentLength) => ({
            id: v.id,
            name: v.name,
        }),
    },
    [TargetingDimensionTypes.ContentNetworks]: {
        headers: {
            contentNetwork: "Content Network",
        },
        mapper: (v: string) => ({
            contentNetwork: v,
        }),
    },
    [TargetingDimensionTypes.ContentRatings]: {
        headers: {
            contentRating: "Content Rating",
        },
        mapper: (v: string) => ({
            contentRating: v,
        }),
    },
    [TargetingDimensionTypes.ContentSeries]: {
        headers: {
            seriesName: "Series Name",
            season: "Season",
            episode: "Episode",
        },
        mapper: (v: ContentMetadataTvSeries) => ({
            seriesName: v.seriesName,
            season: v.season,
            episode: v.episode,
        }),
    },
    [TargetingDimensionTypes.Coppa]: {
        headers: {
            coppa: "COPPA",
        },
        mapper: (v: boolean) => ({
            coppa: v ? "True" : "False",
        }),
    },
    [TargetingDimensionTypes.CustomRules]: {
        headers: {
            rule: "Custom Rule",
        },
        mapper: (v: string) => ({
            rule: v, // TODO: We're just sending a JSON string here - can we do better?
        }),
    },
    [TargetingDimensionTypes.CustomTargets]: {
        headers: {
            customTarget: "Custom Target",
        },
        mapper: (v: string) => ({
            customTarget: v,
        }),
    },
    [TargetingDimensionTypes.DayPartTargets]: {
        headers: {
            dayOfWeek: "Day of Week",
            halfHourOfDay: "Half Hour of Day",
        },
        mapper: (v: DayPartTarget) => ({
            dayOfWeek: v.dayOfWeek,
            halfHourOfDay: v.halfHourOfDay,
        }),
    },
    [TargetingDimensionTypes.Dnt]: {
        headers: {
            dnt: "DNT",
        },
        mapper: (v: boolean) => ({
            dnt: v ? "True" : "False",
        }),
    },
    [TargetingDimensionTypes.Genres]: {
        headers: {
            genre: "Genre",
        },
        mapper: (v: string) => ({
            genre: v,
        }),
    },
    [TargetingDimensionTypes.GeoTargets]: {
        headers: {
            id: "ID",
            countryCode: "Country Code",
            countryName: "Country Name",
            subDivisionName: "Sub-division Name",
            subDivisionCode: "Sub-division Code",
            city: "City",
            metroCode: "Metro Code",
            metroName: "Metro Name",
            postalCode: "Postal Code",
        },
        mapper: (v: GeoTarget) => ({
            id: v.geo.id,
            countryCode: v.geo.countryCode || "",
            countryName: v.geo.countryName || "",
            subDivisionName: v.geo.subDivisionName || "",
            subDivisionCode: v.geo.subDivisionCode || "",
            city: v.geo.city || "",
            metroCode: v.geo.metroCode || "",
            metroName: v.geo.metroName || "",
            postalCode: v.postalCode || "",
        }),
    },
    [TargetingDimensionTypes.LabelValues]: {
        headers: {
            id: "ID",
            labelId: "Label ID",
            labelKey: "Label Key",
            labelMultiValue: "Label Multi Value",
            value: "Value",
        },
        mapper: (v: LabelValue) => ({
            id: v.id,
            labelId: v.label.id,
            labelKey: v.label.key,
            labelMultiValue: v.label.multiValue ? "True" : "False",
            value: v.value,
        }),
    },
    [TargetingDimensionTypes.LiveStream]: {
        headers: {
            liveStream: "Live Stream",
        },
        mapper: (v: boolean) => ({
            liveStream: v ? "True" : "False",
        }),
    },
    [TargetingDimensionTypes.MaxDurationTarget]: {
        headers: {
            maxDuration: "Max Duration",
            name: "Operator Name",
        },
        mapper: (v: MaxDurationTarget) => ({
            maxDuration: v.maxDuration,
            name: v.operator?.name,
        }),
    },
    [TargetingDimensionTypes.MimeTypes]: {
        headers: {
            id: "ID",
            name: "Name",
        },
        mapper: (v: MimeType) => ({
            id: v.id,
            name: v.name,
        }),
    },
    [TargetingDimensionTypes.MinDurationTarget]: {
        headers: {
            minDuration: "Min Duration",
            name: "Operator Name",
        },
        mapper: (v: MinDurationTarget) => ({
            minDuration: v.minDuration,
            name: v.operator?.name,
        }),
    },
    [TargetingDimensionTypes.OperatingSystems]: {
        headers: {
            id: "ID",
            name: "Name",
        },
        mapper: (v: OperatingSystem) => ({
            id: v.id,
            name: v.name,
        }),
    },
    [TargetingDimensionTypes.Platforms]: {
        headers: {
            id: "ID",
            name: "Name",
        },
        mapper: (v: Platform) => ({
            id: v.id,
            name: v.name,
        }),
    },
    [TargetingDimensionTypes.Pmp]: {
        headers: {
            pmpDealId: "PMP Deal ID",
        },
        mapper: (v: string) => ({
            pmpDealId: v,
        }),
    },
    [TargetingDimensionTypes.PodSlotPositionTargets]: {
        headers: {
            podSlotPosition: "Pod Slot Position",
        },
        mapper: (v: number) => ({
            podSlotPosition: v,
        }),
    },
    [TargetingDimensionTypes.Producers]: {
        headers: {
            producer: "Producer",
        },
        mapper: (v: string) => ({
            producer: v,
        }),
    },
    [TargetingDimensionTypes.Sizes]: {
        headers: {
            id: "ID",
            name: "Name",
        },
        mapper: (v: Size) => ({
            id: v.id,
            name: v.name,
        }),
    },
    [TargetingDimensionTypes.SupplyDomainTargets]: {
        headers: {
            supplyDomainTarget: "Supply Domain Target",
            name: "Name",
        },
        mapper: (v: string) => ({
            supplyDomainTarget: v,
        }),
    },
    [TargetingDimensionTypes.SupplyTypes]: {
        headers: {
            id: "ID",
            name: "Name",
        },
        mapper: (v: SupplyType) => ({
            id: v.id,
            name: v.name,
        }),
    },
    [TargetingDimensionTypes.VideoIds]: {
        headers: {
            videoId: "Video ID",
            videoTitle: "Video Title",
        },
        mapper: (v: ContentMetadataTitles) => ({
            videoId: v.videoId,
            videoTitle: v.videoTitle,
        }),
    },
};

const getFileName = (dimension: TargetingDimension) => {
    return `${dimension.type}_${dimension.condition}_${new Date().toISOString()}.csv`;
};

const downloadCsv = (
    payload: TargetingCreatePayload,
    dimension: TargetingDimension,
    headers: Record<string, string>,
    mapper: (value: unknown) => Record<string, unknown>
) => {
    const payloadIncludeExcludeKey = dimension.condition === Conditions.Includes ? "include" : "exclude";
    const payloadByCondition = payload[payloadIncludeExcludeKey];
    const values = payloadByCondition[dimension.type];

    if (values) {
        download.csvFromArray(
            Array.isArray(values) ? values.map(mapper) : [mapper(values)],
            getFileName(dimension),
            headers
        );
    }
};

export const targetingBlockDimensionToCsv = (payload: TargetingCreatePayload, dimension: TargetingDimension) => {
    switch (dimension.type) {
        case TargetingDimensionTypes.Audiences: {
            const { headers, mapper } = TYPE_TO_DOWNLOAD_ARGS[dimension.type];
            const values = "values" in dimension ? dimension.values : null;

            if (values) {
                download.csvFromArray(
                    Array.isArray(values) ? values.map(mapper) : [mapper(values)],
                    getFileName(dimension),
                    headers
                );
            }
            return;
        }
        case TargetingDimensionTypes.AdBreakPositionTargets:
        case TargetingDimensionTypes.ApiFrameworks:
        case TargetingDimensionTypes.BundleIdTargets:
        case TargetingDimensionTypes.Bvod:
        case TargetingDimensionTypes.Categories:
        case TargetingDimensionTypes.ContentCategories:
        case TargetingDimensionTypes.ContentChannels:
        case TargetingDimensionTypes.ContentLengths:
        case TargetingDimensionTypes.ContentNetworks:
        case TargetingDimensionTypes.ContentRatings:
        case TargetingDimensionTypes.ContentSeries:
        case TargetingDimensionTypes.Coppa:
        case TargetingDimensionTypes.CustomRules:
        case TargetingDimensionTypes.CustomTargets:
        case TargetingDimensionTypes.DayPartTargets:
        case TargetingDimensionTypes.Dnt:
        case TargetingDimensionTypes.Genres:
        case TargetingDimensionTypes.GeoTargets:
        case TargetingDimensionTypes.LabelValues:
        case TargetingDimensionTypes.LiveStream:
        case TargetingDimensionTypes.MaxDurationTarget:
        case TargetingDimensionTypes.MimeTypes:
        case TargetingDimensionTypes.MinDurationTarget:
        case TargetingDimensionTypes.OperatingSystems:
        case TargetingDimensionTypes.Platforms:
        case TargetingDimensionTypes.Pmp:
        case TargetingDimensionTypes.PodSlotPositionTargets:
        case TargetingDimensionTypes.Producers:
        case TargetingDimensionTypes.Sizes:
        case TargetingDimensionTypes.SupplyDomainTargets:
        case TargetingDimensionTypes.SupplyTypes:
        case TargetingDimensionTypes.VideoIds: {
            const { headers, mapper } = TYPE_TO_DOWNLOAD_ARGS[dimension.type];
            return downloadCsv(payload, dimension, headers, mapper);
        }
        case TargetingDimensionTypes.Inventory: {
            const payloadIncludeExcludeKey = dimension.condition === Conditions.Includes ? "include" : "exclude";
            const payloadByCondition = payload[payloadIncludeExcludeKey];
            const adUnitValues = (payloadByCondition.adUnits || []).map((v) => ({ ...v, type: "Ad Unit" }));
            const brandValues = (payloadByCondition.brands || []).map((v) => ({ ...v, type: "Brand" }));
            const supplyValues = (payloadByCondition.supply || []).map((v) => ({ ...v, type: "Supply" }));
            const publisherValues = (payloadByCondition.publishers || []).map((v) => ({ ...v, type: "Publisher" }));
            const values = [adUnitValues, brandValues, supplyValues, publisherValues].flat();

            return download.csvFromArray(values, getFileName(dimension), {
                id: "ID",
                name: "Name",
                type: "Type",
            });
        }
        case TargetingDimensionTypes.SegmentRules: {
            const payloadIncludeExcludeKey = dimension.condition === Conditions.Includes ? "include" : "exclude";
            const payloadByCondition = payload[payloadIncludeExcludeKey];

            if (dimension.mode === SegmentRuleModes.Advanced) {
                const values = payloadByCondition.segmentRules;
                if (values) {
                    return download.csvFromArray(
                        values.map((v) => ({ segmentRule: v })), // TODO: Just a JSON string - can we do better?
                        getFileName(dimension),
                        {
                            segmentRule: "Segment Rule",
                        }
                    );
                }
            }
            if (dimension.mode === SegmentRuleModes.Simple) {
                const values = payloadByCondition.segments;
                if (values) {
                    return download.csvFromArray(
                        values.map((v) => ({ id: v.id, name: v.name })),
                        getFileName(dimension),
                        {
                            id: "ID",
                            name: "Name",
                        }
                    );
                }
            }
            return;
        }
    }
};
