export const creativeReviewHelpKeys = [
    {
        key: "CREATIVE_DETAILS",
        label: "Creative Details",
        helpPath: "",
        children: [
            {
                key: "AD_SYSTEM",
                label: "Ad System",
                helpPath: "creativereview.adsystem",
            },
            {
                key: "AD_TITLE",
                label: "Ad Title",
                helpPath: "creativereview.adtitle",
            },
            {
                key: "LOUDNESS",
                label: "Loudness",
                helpPath: "creativereview.scannedloudness",
            },
            {
                key: "CODEC",
                label: "Codec",
                helpPath: "creativereview.scannedcodec",
            },
            {
                key: "FRAME_RATE",
                label: "Frame Rate",
                helpPath: "creativereview.scannedframerate",
            },
            {
                key: "FORMAT",
                label: "Format",
                helpPath: "creativereview.scannedformat",
            },
            {
                key: "DURATION",
                label: "Duration",
                helpPath: "creativereview.scannedduration",
            },
            {
                key: "BIT_RATE",
                label: "Bit Rate",
                helpPath: "creativereview.scannedbitrate",
            },
            {
                key: "WIDTH",
                label: "Width",
                helpPath: "creativereview.scannedwidth",
            },
            {
                key: "HEIGHT",
                label: "Height",
                helpPath: "creativereview.scannedheight",
            },
            {
                key: "AUDIO_BIT_RATE",
                label: "Audio Bit Rate",
                helpPath: "creativereview.scannedaudiobitrate",
            },
            {
                key: "AUDIO_CHANNELS",
                label: "Audio Channels",
                helpPath: "creativereview.scannedaudiochannels",
            },
        ],
    },
    {
        key: "MEDIA_FILE",
        label: "Media File",
        helpPath: "",
        children: [
            {
                key: "TYPE",
                label: "Type",
                helpPath: "creativereview.mimetype",
            },
            {
                key: "HEIGHT",
                label: "Height",
                helpPath: "creativereview.height",
            },
            {
                key: "WIDTH",
                label: "Width",
                helpPath: "creativereview.width",
            },
            {
                key: "DELIVERY",
                label: "Delivery",
                helpPath: "creativereview.deliverytype",
            },
            {
                key: "BITRATE",
                label: "Bitrate",
                helpPath: "creativereview.bitrate",
            },
            {
                key: "API_FRAMEWORK",
                label: "ApiFramework",
                helpPath: "creativereview.apiframework",
            },
            {
                key: "URL",
                label: "URL",
                helpPath: "creativereview.asseturl",
            },
        ],
    },
];
