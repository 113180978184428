import { Button } from "antd";
import { ClassificationsLabelDeleteModal, useClassificationsLabelDeleteModal } from "./ClassificationsLabelDeleteModal";
import { FC } from "react";
import { Label } from "@app/core/services";

interface Props {
    label?: Label;
    isDrawer?: boolean;
}

export const ClassificationsLabelDeleteButton: FC<Props> = ({ label, isDrawer }) => {
    const { handleOpen, ...restModalProps } = useClassificationsLabelDeleteModal(isDrawer);

    return (
        <>
            <Button
                danger
                disabled={!label}
                data-sdet="delete-button"
                type="primary"
                onClick={() => label && handleOpen(label)}
            >
                Delete
            </Button>
            <ClassificationsLabelDeleteModal {...restModalProps} />
        </>
    );
};
