import { Card } from "antd";
import { FC } from "react";
import { INVENTORY_CARD_BODY_STYLE } from "@app/features/inventory/constants";
import {
    NoAdCookiesSync,
    PublisherReAuction,
} from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormSections/AdvancedFeaturesSection/Sections/DetailedConfigurationSection/Fields/InternalOnly/Fields";
import { useInternalOnly } from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormSections/AdvancedFeaturesSection/Sections/DetailedConfigurationSection/Fields/InternalOnly/useInternalOnly";

export const InternalOnly: FC = () => {
    const { isPublisherReAuctionShown } = useInternalOnly();
    return (
        <Card title="Internal Only" type="inner" bodyStyle={INVENTORY_CARD_BODY_STYLE}>
            <NoAdCookiesSync />
            {isPublisherReAuctionShown && <PublisherReAuction />}
        </Card>
    );
};
