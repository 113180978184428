import { Table } from "antd";

import { getColumns, getTableRows, styles } from "./helpers";
import { useRecycleBinTable } from "./useRecycleBinTable";

export const RECYCLE_BIN_TABLE_SDET = "recycle-bin-list-table";

export const RecycleBinTable = () => {
    const { isLoading, entity, selectedBy, data, paginationConfig, handleChangeCheckbox, handleTableChange } =
        useRecycleBinTable();

    return (
        <Table
            className={styles}
            data-sdet={RECYCLE_BIN_TABLE_SDET}
            columns={getColumns()}
            dataSource={getTableRows(data)}
            onChange={handleTableChange}
            loading={isLoading}
            scroll={{ x: 1000 }}
            rowSelection={{
                preserveSelectedRowKeys: true,
                selectedRowKeys: selectedBy[entity],
                onChange: (selectedRowKeys: number[]) => handleChangeCheckbox(selectedRowKeys),
            }}
            showSorterTooltip={false}
            pagination={paginationConfig}
        />
    );
};

export default RecycleBinTable;
