import { FC, useState } from "react";
import { Drawer, Typography } from "antd";
import { StyledButtonLink } from "@app/core/components";

export const InventoryFloorHelp: FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    return (
        <>
            <StyledButtonLink onClick={() => setIsOpen(true)} type="link">
                How are Hierarchical Floors applied?
            </StyledButtonLink>
            <Drawer
                width={400}
                title="How are Hierarchical Floors applied?"
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <Typography.Paragraph>
                    Hierarchical Floors provide the ability to attach simple static Floors to Inventory entities. These
                    Floors can be set for specific coutries, or for all geos. By default, Floors will be inherited by
                    any child entities underneath them, unless the child entity has a floor set on it.
                </Typography.Paragraph>
                <Typography.Text strong>Understanding Inheritance</Typography.Text>
                <Typography.Paragraph>
                    The entire hierarchy is represented in the expanding tree view to the left. Items that have a floor
                    set on them are indicated with a blue dot. When viewing an Inventory entity, if it is inheriting a
                    Floor setting from a parent entity, this will be indicated via the Inherited From column. The Source
                    column will also indicate the Hierarchy level the Inherited From entity sits on.
                </Typography.Paragraph>
                <Typography.Text strong>How other Floors interact with Hierarchical Floors</Typography.Text>
                <Typography.Paragraph>
                    In addition to Hiearchical Floors, floors may also be set via: • Advanced Floors: Floors that
                    leverage full targeting capability. If one or more targeted Advanced Floors match against an Ad
                    Request, and a Hierarchical Floor is also set for that supply, the platform will use the highest CPM
                    from either Hiearchical Floors or the matched Advanced Floors • Ad Request Floors: These are Floors
                    passed on the Ad Request to the Magnite Streaming platform. These will supercede any Hiearchical or
                    targeted Advanced Floor rules. • Ad Source Floors: Ad Sources themselves can opt to override all
                    supply Floors and set their own.
                </Typography.Paragraph>
            </Drawer>
        </>
    );
};
