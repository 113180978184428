import { Col } from "antd";
import { CurrencyInput } from "@app/core/components/CurrencyInput";
import { AD_SOURCE_FIELDS, COL_SIZES } from "@app/features/seatAdSources/constants";
import { useCpmInput } from "./useCpmInput";

export const OverrideFloorCpmInput = () => {
    const { isVisible, fieldLabel, currencyCode, onBlur } = useCpmInput();

    if (!isVisible) {
        return null;
    }

    return (
        <Col {...COL_SIZES}>
            <CurrencyInput
                dataSdetPrefix="test-bookingPrice"
                name={AD_SOURCE_FIELDS.OVERRIDE_FLOOR_CPM.name}
                label={fieldLabel}
                required
                currencyCode={currencyCode}
                onBlur={onBlur}
            />
        </Col>
    );
};
