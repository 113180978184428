import { BulkOperationType } from "@app/core/services";
import { Card, Form } from "antd";
import { FC } from "react";
import { BulkOperationEntitiesEditTable } from "./BulkOperationEntitiesEditTable";
import { BulkOperationCopyForm } from "./BulkOperationCopyForm";

export const BulkOperationDetailsStep: FC = () => {
    const form = Form.useFormInstance();
    const { type } = form.getFieldsValue(true);

    if (type === BulkOperationType.Edit) {
        return <BulkOperationEntitiesEditTable />;
    }

    if (type === BulkOperationType.Copy) {
        return (
            <Card>
                <BulkOperationCopyForm />
            </Card>
        );
    }

    return null;
};
