import { FC } from "react";
import { Loading } from "@app/core/components";
import { DetailsDrawer } from "../../DetailsDrawer";
import { InventoryDetailsDrawerType } from "../../DetailsDrawer/reducer";
import { DetailsDrawerUnitLayoutToolbar } from "../../DetailsDrawer/DetailsDrawerLayout/DetailsDrawerUnitLayout/DetailsDrawerUnitLayoutToolbar";
import { useInventoryHealthUnitPage } from "./useInventorHealthUnitPage";
import { PageHeader } from "@ant-design/pro-layout";
import { useScrollToTopOnMount } from "@app/core/components/hooks";

interface Props {
    type: InventoryDetailsDrawerType;
}
export const InventoryHealthUnitPage: FC<Props> = ({ type }) => {
    useScrollToTopOnMount();
    const { isLoading, onBack, title, unitSections } = useInventoryHealthUnitPage(type);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <PageHeader onBack={onBack} title={title}>
                <DetailsDrawerUnitLayoutToolbar />
            </PageHeader>
            {unitSections}
            <DetailsDrawer />
        </>
    );
};
