import {
    AdSourcePriority,
    BulkOperationEntityType,
    CostModel,
    Region,
    TimeZone,
    getTimeZoneLabel,
    useGetAdSourcePrioritiesQuery,
    useGetCostModelsQuery,
    useGetCurrenciesQuery,
    useGetRegionsQuery,
    useGetTimeZonesQuery,
    useGetSeatAdSourcesStatusesQuery,
    useGetAdUnitStatusesQuery,
    useGetAdUnitsMimesQuery,
    AdUnitsMime,
    useGetAdUnitsApiFrameworksQuery,
    AdUnitsApiFramework,
    useGetAdUnitsSupportedProtocolsQuery,
    AdUnitsSupportedProtocol,
    useGetAdUniPlacementsQuery,
    VideoPlacementType,
    ImpressionWaitTime,
    useGetAdUnitWaitTimesQuery,
    DealSourceType,
    useGetDealSourceTypesQuery,
} from "@app/core/services";
import { sectionHeaderClassname } from "@app/features/controls/constants";
import { Badge, Button, Col, Collapse, Form, Input, Row, Space, Typography } from "antd";
import { FC } from "react";
import { MinMaxDurationFilter } from "./MinMaxDurationFilter";
import { StatusFilter } from "./StatusFilter";
import { AdSourceTypeFilter } from "./AdSourceTypeFilter";
import { useUserAccess } from "@app/core/auth";
import { SalesContactFilter } from "./SalesContactFilter";
import { GenericSelectField } from "../../../../../shared/GenericSelectField";
import { SelectFieldWithLocalOptions } from "../../../../../shared/SelectFieldWithLocalOptions";
import { Currency } from "@app/core/services/console/currencies";
import {
    BUYER_DEMAND_DEAL_STATUS_OPTIONS,
    OFF_OPTION,
    MULTIPLICITY_OPTIONS,
    NONE_OPTION,
    UNSPECIFIED_OPTION,
} from "@app/features/controls/bulkOperations/constants";
import { brandText1 } from "@rubicon/antd-components";

const getFiltersCount = (filters?: Record<string, string | number | string[] | number[] | undefined | null>): number =>
    filters
        ? Object.values(filters).filter((filter) => filter && (!Array.isArray(filter) || filter.length > 0)).length
        : 0;

const getAdSourcesFilterItems = (hasTremorRole: boolean) =>
    [
        {
            label: "Status",
            key: "adSourceStatus",
            initialValue: [1],
            component: <StatusFilter nameKey="adSourceStatus" useGetOptionsQuery={useGetSeatAdSourcesStatusesQuery} />,
        },
        {
            label: "Name",
            key: "adSourceKeyword",
            component: <Input />,
        },
        {
            label: "Priority",
            key: "priorityIds",
            component: (
                <GenericSelectField<AdSourcePriority>
                    sdet="priority-filter"
                    mode="multiple"
                    useGetOptionsQuery={useGetAdSourcePrioritiesQuery}
                    optionMapper={({ name, id }: AdSourcePriority) => ({ label: name, value: id })}
                />
            ),
        },
        {
            label: "Ad Source Type",
            key: "adSourceType",
            component: <AdSourceTypeFilter />,
        },
        hasTremorRole && {
            label: "Region",
            key: "region",
            component: (
                <GenericSelectField<Region>
                    sdet="region-filter"
                    mode="multiple"
                    useGetOptionsQuery={useGetRegionsQuery}
                    unspecifiedOptions={[UNSPECIFIED_OPTION]}
                    optionMapper={({ name, id }: Region) => ({ label: name, value: id })}
                />
            ),
        },
        {
            label: "Currency",
            key: "currencyIds",
            component: (
                <GenericSelectField<Currency>
                    sdet="currency-filter"
                    mode="multiple"
                    useGetOptionsQuery={useGetCurrenciesQuery}
                    optionMapper={({ code, id }: Currency) => ({ label: code, value: id })}
                />
            ),
        },
        {
            asSelf: true,
            key: "duration",
            component: <MinMaxDurationFilter />,
        },
        {
            label: "Time Zone",
            key: "timeZoneIds",
            component: (
                <GenericSelectField<TimeZone>
                    sdet="time-zone-filter"
                    mode="multiple"
                    useGetOptionsQuery={useGetTimeZonesQuery}
                    optionMapper={(timeZone: TimeZone) => ({ label: getTimeZoneLabel(timeZone), value: timeZone.id })}
                />
            ),
        },
        hasTremorRole && {
            label: "Sales Contact",
            key: "salesContactIds",
            component: <SalesContactFilter mode="multiple" />,
        },
        hasTremorRole && {
            label: "Cost Model",
            key: "costModelIds",
            component: (
                <GenericSelectField<CostModel>
                    sdet="cost-model-filter"
                    mode="multiple"
                    useGetOptionsQuery={useGetCostModelsQuery}
                    optionMapper={({ name, id }: CostModel) => ({ label: name, value: id })}
                />
            ),
        },
    ].filter(Boolean);

const getAdUnitsFilterItems = (hasTremorRole: boolean) =>
    [
        {
            label: "Status",
            key: "adUnitStatus",
            initialValue: [1],
            component: <StatusFilter nameKey="adUnitStatus" useGetOptionsQuery={useGetAdUnitStatusesQuery} />,
        },
        {
            label: "Name",
            key: "adUnitKeyword",
            component: <Input data-sdet="keyword-filter" />,
        },
        {
            label: "Multiplicity",
            key: "multiplicity",
            component: <SelectFieldWithLocalOptions sdet="multiplicity-filter" options={MULTIPLICITY_OPTIONS} />,
        },
        {
            label: "Mime",
            key: "mimeIds",
            component: (
                <GenericSelectField<AdUnitsMime>
                    sdet="mime-filter"
                    mode="multiple"
                    useGetOptionsQuery={useGetAdUnitsMimesQuery}
                    optionMapper={({ name, id }: AdUnitsMime) => ({ label: name, value: id })}
                />
            ),
        },
        {
            label: "Supported API",
            key: "supportedApiIds",
            component: (
                <GenericSelectField<AdUnitsApiFramework>
                    sdet="supported-api-filter"
                    mode="multiple"
                    unspecifiedOptions={[NONE_OPTION]}
                    useGetOptionsQuery={useGetAdUnitsApiFrameworksQuery}
                    optionMapper={({ name, id }: AdUnitsApiFramework) => ({ label: name, value: id })}
                />
            ),
        },
        {
            label: "Supported Protocol",
            key: "supportedProtocolIds",
            component: (
                <GenericSelectField<AdUnitsSupportedProtocol>
                    sdet="supported-protocol-filter"
                    mode="multiple"
                    useGetOptionsQuery={useGetAdUnitsSupportedProtocolsQuery}
                    optionMapper={({ name, id }: AdUnitsSupportedProtocol) => ({ label: name, value: id })}
                />
            ),
        },
        {
            asSelf: true,
            key: "duration",
            component: <MinMaxDurationFilter />,
        },
        {
            label: "Placement",
            key: "placementIds",
            component: (
                <GenericSelectField<VideoPlacementType>
                    sdet="placement-filter"
                    mode="multiple"
                    useGetOptionsQuery={useGetAdUniPlacementsQuery}
                    optionMapper={({ name, id }: VideoPlacementType) => ({ label: name, value: id })}
                />
            ),
        },
        hasTremorRole && {
            label: "Impression Wait Time",
            key: "impressionWaitTime",
            component: (
                <GenericSelectField<ImpressionWaitTime>
                    sdet="impression-wait-time-filter"
                    mode="multiple"
                    unspecifiedOptions={[OFF_OPTION]}
                    useGetOptionsQuery={useGetAdUnitWaitTimesQuery}
                    optionMapper={({ name, id }: ImpressionWaitTime) => ({ label: name, value: id })}
                />
            ),
        },
    ].filter(Boolean);

const buyerDealsFilterItems = [
    {
        label: "Status",
        key: "externalStatus",
        component: <SelectFieldWithLocalOptions sdet="status-filter" options={BUYER_DEMAND_DEAL_STATUS_OPTIONS} />,
    },
    {
        label: "Name",
        key: "buyerDealKeyword",
        component: <Input data-sdet="keyword-filter" />,
    },
    {
        label: "Buyer Seat Code",
        key: "buyerSeatCode",
        component: <Input data-sdet="buyer-seat-code-filter" />,
    },
    {
        label: "Type",
        key: "buyerDealType",
        component: (
            <GenericSelectField<DealSourceType>
                sdet="buyer-deal-type-filter"
                mode="multiple"
                useGetOptionsQuery={useGetDealSourceTypesQuery}
                optionMapper={({ name, id }: DealSourceType) => ({ label: name, value: id })}
            />
        ),
    },
];

const demandDealsFilterItems = [
    {
        label: "Name",
        key: "demandDealKeyword",
        component: <Input data-sdet="keyword-filter" />,
    },
    {
        label: "Type",
        key: "demandDealType",
        component: (
            <GenericSelectField<DealSourceType>
                sdet="demand-deal-type-filter"
                mode="multiple"
                useGetOptionsQuery={useGetDealSourceTypesQuery}
                optionMapper={({ name, id }: DealSourceType) => ({ label: name, value: id })}
            />
        ),
    },
];

const getFilterItems = (entityType: BulkOperationEntityType, hasTremorRole: boolean) => {
    switch (entityType) {
        case BulkOperationEntityType.AdSource:
            return getAdSourcesFilterItems(hasTremorRole);
        case BulkOperationEntityType.AdUnit:
            return getAdUnitsFilterItems(hasTremorRole);
        case BulkOperationEntityType.BuyerDeal:
            return buyerDealsFilterItems;
        case BulkOperationEntityType.DemandDeal:
            return demandDealsFilterItems;
        default:
            return [];
    }
};

const FilterItems: FC<{ entityType: BulkOperationEntityType }> = ({ entityType }) => {
    const { hasTremorRole } = useUserAccess();
    const filterItems = getFilterItems(entityType, hasTremorRole);

    return (
        <Row gutter={16}>
            {filterItems.map(({ asSelf, initialValue, label, key, component }) => (
                <Col xs={24} md={12} lg={8} key={key}>
                    {asSelf ? (
                        component
                    ) : (
                        <Form.Item initialValue={initialValue} label={label} name={["filters", key]}>
                            {component}
                        </Form.Item>
                    )}
                </Col>
            ))}
        </Row>
    );
};

export const OperationEntitiesFilters: FC = () => {
    const form = Form.useFormInstance();
    const entityType = Form.useWatch("entityType");
    const filters = Form.useWatch("filters");
    const handleClearFilters = () => form.setFieldValue("filters", null);

    return (
        <Collapse
            defaultActiveKey={[1]}
            collapsible="header"
            className={sectionHeaderClassname}
            items={[
                {
                    key: "1",
                    children: <FilterItems entityType={entityType} />,
                    label: (
                        <Space>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Filters
                            </Typography.Title>
                            <Badge
                                data-sdet="filters-count"
                                count={getFiltersCount(filters)}
                                style={{ backgroundColor: brandText1 }}
                            />
                        </Space>
                    ),
                    extra: (
                        <Button data-sdet="filters-clear-button" onClick={handleClearFilters} size="small">
                            Clear
                        </Button>
                    ),
                },
            ]}
        />
    );
};
