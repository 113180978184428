import { DebounceUriSearch } from "@app/core/components";
import { Form, Row, Col, Button } from "antd";
import { useParams } from "react-router-dom";
import { BRAND_SAFETY_SEARCH_FILTER_SDET, BRAND_SAFETY_LIST } from "./constants";
import { CONTROLS_ROUTES } from "../constants";
import { FC } from "react";
import { useAppDispatch } from "@app/core/store";
import { BRANDS_SAFETY_SEQUESTERED_DOMAINS_TAG, consoleApi } from "@app/core/services";

export const BrandSafetyToolbar: FC = () => {
    const { tab } = useParams<{ seatId: string; tab: string }>();
    const dispatch = useAppDispatch();

    return (
        BRAND_SAFETY_LIST?.[tab]?.hasSearch && (
            <Form layout="vertical">
                <Row gutter={16} justify="space-between">
                    <Col span={12}>
                        <Form.Item>
                            <DebounceUriSearch sdet={BRAND_SAFETY_SEARCH_FILTER_SDET?.[tab]} />
                        </Form.Item>
                    </Col>
                    {tab === CONTROLS_ROUTES.SEQUESTERED_DOMAINS && (
                        <Col span={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Form.Item>
                                <Button
                                    onClick={() =>
                                        dispatch(
                                            consoleApi.util.invalidateTags([BRANDS_SAFETY_SEQUESTERED_DOMAINS_TAG])
                                        )
                                    }
                                >
                                    Refresh{" "}
                                </Button>
                            </Form.Item>
                        </Col>
                    )}
                </Row>
            </Form>
        )
    );
};

export default BrandSafetyToolbar;
