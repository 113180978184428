import { FC } from "react";
import { Select, Form } from "antd";
import { ColProps } from "antd/lib/grid/col";
import { SelectProps } from "antd/lib/select";
import { TagRender } from "@app/core/components";
import { MIMES } from "@app/features/inventory/HierarchyForms/constants";
import { useAdUnitMimes } from "@app/features/inventory/components/FormItems/AdUnitMimes/useAdUnitMimes";

interface AdUnitMimesProps extends SelectProps {
    wrapperCol?: ColProps;
    labelCol?: ColProps;
    name: typeof MIMES;
    label: string;
}

export const AdUnitMimes: FC<AdUnitMimesProps> = ({ label, name, wrapperCol, labelCol, ...rest }) => {
    const { isFetching, options, validator } = useAdUnitMimes();

    return (
        <Form.Item
            name={name}
            label={label}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            rules={[{ required: true, validator }]}
        >
            <Select
                showSearch
                allowClear
                labelInValue
                mode="multiple"
                options={options}
                loading={isFetching}
                tagRender={TagRender}
                optionFilterProp="label"
                autoClearSearchValue={false}
                {...rest}
            />
        </Form.Item>
    );
};
