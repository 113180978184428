import { FC } from "react";
import { Form, Select } from "antd";
import { Seat } from "@app/core/services";
import { NONE } from "@app/core/components/constants";
import { ConditionalTagRender, TagRender } from "@app/core/components";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { useExternalCodeNamespaces } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/AdvancedFeaturesForm/AdvancedFeaturesFormFields/ExternalCodeNamespaces/useExternalCodeNamespaces";

interface ExternalCodeNamespacesProps {
    seat: Seat;
    edit?: boolean;
}

export const ExternalCodeNamespaces: FC<ExternalCodeNamespacesProps> = ({ edit = false, seat }) => {
    const { isFetching, options, externalCodeNamespacesShown, isSspConnectEnabled, hasInternalAccess } =
        useExternalCodeNamespaces();

    if (edit && !isSspConnectEnabled && hasInternalAccess) {
        return (
            <Form.Item
                wrapperCol={{ xs: 24, sm: 24, lg: 16, xl: 24, xxl: 22 }}
                label={INVENTORY_FORM_FIELDS.EXTERNAL_AD_UNIT_CODE_NAMESPACES.label}
            >
                {NONE}
            </Form.Item>
        );
    }
    if (edit && externalCodeNamespacesShown) {
        return (
            <Form.Item
                wrapperCol={{ xs: 24, sm: 24, lg: 16, xl: 24, xxl: 22 }}
                name={INVENTORY_FORM_FIELDS.EXTERNAL_AD_UNIT_CODE_NAMESPACES.name}
                label={INVENTORY_FORM_FIELDS.EXTERNAL_AD_UNIT_CODE_NAMESPACES.label}
                rules={[
                    { required: edit, message: INVENTORY_FORM_FIELDS.EXTERNAL_AD_UNIT_CODE_NAMESPACES.rulesMessage },
                ]}
            >
                <Select
                    allowClear
                    labelInValue
                    showArrow
                    showSearch
                    mode="multiple"
                    options={options}
                    loading={isFetching}
                    tagRender={TagRender}
                    optionFilterProp="label"
                    autoClearSearchValue={false}
                    placeholder={INVENTORY_FORM_FIELDS.EXTERNAL_AD_UNIT_CODE_NAMESPACES.placeholder}
                />
            </Form.Item>
        );
    }

    return (
        <Form.Item label={INVENTORY_FORM_FIELDS.EXTERNAL_AD_UNIT_CODE_NAMESPACES.label}>
            <ConditionalTagRender data={seat} property={INVENTORY_FORM_FIELDS.EXTERNAL_AD_UNIT_CODE_NAMESPACES.name} />
        </Form.Item>
    );
};
