import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { usePublisherChannelFormInstance } from "@app/features/inventory/HierarchyForms/PublisherChannelForm/PublisherChannelFormInstance";

interface UseCodeType {
    isReadOnly: boolean;
    code?: string;
}
export const useCodeType = (): UseCodeType => {
    const { mode, useWatch } = usePublisherChannelFormInstance();
    const isReadOnly: boolean = mode === "edit";
    const code = useWatch(CHANNEL_FORM_FIELDS.PUBLISHER_CODE.name);

    return {
        code,
        isReadOnly,
    };
};
