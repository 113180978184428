import { useMemo } from "react";
import { useParams } from "react-router-dom";

export interface UnitUrlIds {
    seatId: string;
    publisherId?: string;
    brandId?: string;
    supplyId?: string;
    adUnitId?: string;
}

export const useInventoryDetailsDrawerUrlId = () => {
    const { seatId, publisherId, brandId, supplyId, adUnitId } = useParams<UnitUrlIds>();
    const unitId = Number(
        useMemo(
            () => adUnitId || supplyId || brandId || publisherId || seatId,
            [seatId, publisherId, brandId, supplyId, adUnitId]
        )
    );
    return unitId;
};
