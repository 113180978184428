import { useNotificationError } from "@app/core/components/hooks";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { BuyerSeatList, useGetSeatBuyerSeatListsByIdQuery } from "@app/core/services";
import { CONTROLS_ROUTES } from "@app/features/controls/constants";
import { useHistory } from "react-router-dom";

interface UseBuyerSeatListsDetailsPage {
    name: string;
    isLoading: boolean;
    handleGoBack: () => void;
    postDelete: () => void;
    data: BuyerSeatList | undefined;
}

export const useBuyerSeatListsDetailsPage = (seatId: string, id: string): UseBuyerSeatListsDetailsPage => {
    const history = useHistory();
    const { data, isFetching, error } = useGetSeatBuyerSeatListsByIdQuery(id);

    useNotificationError(error);

    const handleGoBack = () => {
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST(seatId));
    };

    const postDelete = () => {
        history.replace(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_TABS(seatId, CONTROLS_ROUTES.BUYER_SEAT_LIST));
    };

    return {
        name: data?.name as string,
        isLoading: isFetching,
        handleGoBack,
        postDelete,
        data,
    };
};
