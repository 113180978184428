import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Button, Space } from "antd";
import { FC } from "react";
import { useHistory, useParams } from "react-router-dom";

export const CLASSIFICATIONS_ADVERTISER_DOMAINS_DRAWER_TITLE_SDET =
    "classifications-advertiser-domains-drawer-title-sdet";

interface Props {
    advertiserId?: number;
}
export const ClassificationsAdvertiserDomainsDrawerTitle: FC<Props> = ({ advertiserId }) => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();

    return advertiserId ? (
        <Space data-sdet={CLASSIFICATIONS_ADVERTISER_DOMAINS_DRAWER_TITLE_SDET} direction="vertical">
            Advertiser Domains
            <Button
                type="primary"
                onClick={() =>
                    history.push(
                        ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISER_EDIT_PAGE(seatId, advertiserId)
                    )
                }
            >
                Edit
            </Button>
        </Space>
    ) : null;
};
