import { Button, Col, Row, Space, Table, Tooltip, Typography } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useNotificationError, usePagination, useTableChanges } from "@app/core/components/hooks";
import { useUserAccess } from "@app/core/auth";
import { ColumnsType } from "antd/es/table";
import { DebounceUriSearch, SEARCH } from "@app/core/components";
import { EditOutlined, HistoryOutlined } from "@ant-design/icons";
import { uri } from "@rubicon/utils";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Industry, useGetIndustriesQuery } from "@app/core/services";
import { SortOrder } from "antd/lib/table/interface";
import { NONE } from "@app/core/components/constants";
import { brandCobalt } from "@rubicon/antd-components";

export const INDUSTRIES_TABLE_SDET = "industries-table";
export const INDUSTRIES_TABLE_NAME_COLUMN_SDET = "industries-table-name-column";
export const INDUSTRIES_TABLE_CODE_COLUMN_SDET = "industries-table-code-column";
export const INDUSTRIES_TABLE_DESCRIPTION_COLUMN_SDET = "industries-table-description-column";
export const INDUSTRIES_TABLE_ACTIONS_COLUMN_SDET = "industries-table-actions-column";
export const INDUSTRIES_TABLE_SEARCH_SDET = "industries-table-search";
export const ADD_INDUSTRY_BUTTON_SDET = "industry-add-btn";
export const INDUSTRIES_TABLE_EDIT_BUTTON_SDET = "industries-table-edit-btn";
export const INDUSTRIES_TABLE_CHANGE_HISTORY_BUTTON_SDET = "industries-table-change-history-btn";

export const getIndustriesColumns = (
    sortBy: string,
    sortOrder: SortOrder,
    canEditSeat: boolean,
    isSeatAdmin: boolean,
    onClickName: (industryId: string | number) => void,
    onClickEdit: (industryId: string | number) => void,
    onClickChangeHistory: (industryId: string | number) => void
): ColumnsType<Industry> =>
    [
        {
            key: "name",
            dataIndex: "name",
            title: <span data-sdet={INDUSTRIES_TABLE_NAME_COLUMN_SDET}>Name</span>,
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortOrder: sortBy === "name" ? sortOrder : null,
            render: (name, { id }) => (
                <Typography.Link onClick={() => onClickName(id)} style={{ color: brandCobalt }}>
                    {name}
                </Typography.Link>
            ),
        },
        {
            key: "code",
            dataIndex: "code",
            title: <span data-sdet={INDUSTRIES_TABLE_CODE_COLUMN_SDET}>Code</span>,
            sorter: (a, b) => a.code.localeCompare(b.code),
            sortOrder: sortBy === "code" ? sortOrder : null,
        },
        {
            key: "description",
            dataIndex: "description",
            title: <span data-sdet={INDUSTRIES_TABLE_DESCRIPTION_COLUMN_SDET}>Description</span>,
            sorter: (a, b) => (a.description || "").localeCompare(b.description || ""), // description may be null
            sortOrder: sortBy === "description" ? sortOrder : null,
            render: (description) => <Typography.Text>{description || NONE}</Typography.Text>,
        },
        (canEditSeat || isSeatAdmin) && {
            key: "actions",
            dataIndex: "actions",
            title: <span data-sdet={INDUSTRIES_TABLE_ACTIONS_COLUMN_SDET}>Actions</span>,
            width: 80,
            render: (_, { id }) => (
                <>
                    {canEditSeat && (
                        <Tooltip title="Edit">
                            <Button
                                data-sdet={INDUSTRIES_TABLE_EDIT_BUTTON_SDET}
                                onClick={() => onClickEdit(id)}
                                icon={<EditOutlined />}
                            />
                        </Tooltip>
                    )}
                    {isSeatAdmin && (
                        <Tooltip title="Change History">
                            <Button
                                data-sdet={INDUSTRIES_TABLE_CHANGE_HISTORY_BUTTON_SDET}
                                onClick={() => onClickChangeHistory(id)}
                                icon={<HistoryOutlined />}
                            />
                        </Tooltip>
                    )}
                </>
            ),
        },
    ].filter(Boolean) as ColumnsType<Industry>;

export const ClassificationsIndustriesTable = () => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const { search: queryString } = useLocation();

    const keyword = new URLSearchParams(queryString).get(SEARCH.key) || SEARCH.default;
    const { data = [], isFetching, error } = useGetIndustriesQuery({ seatId: Number(seatId), params: { keyword } });

    const { handleChange, paginationConfig } = usePagination({ totalCount: data?.length || 0 });
    const { onChange, sortBy, sortOrder } = useTableChanges({ handlePaginateChange: handleChange });
    const { canEditSeat, isSeatAdmin } = useUserAccess();

    useNotificationError(error);

    const onClickName = (industryId: string | number) => {
        const setDrawerParam = uri.setSearchParam(queryString, "drawer", "details");
        const nextQueryString = uri.setSearchParam(setDrawerParam, "industryId", String(industryId));
        history.push({
            search: nextQueryString,
        });
    };

    const onClickEdit = (industryId: string | number) => {
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_EDIT_PAGE(seatId, industryId));
    };

    const onClickChangeHistory = (industryId: string | number) => {
        const setDrawerParam = uri.setSearchParam(queryString, "drawer", "history");
        const nextQueryString = uri.setSearchParam(setDrawerParam, "industryId", String(industryId));
        history.push({
            search: nextQueryString,
        });
    };

    return (
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Row justify="space-between">
                <Col>
                    <DebounceUriSearch sdet={INDUSTRIES_TABLE_SEARCH_SDET} />
                </Col>
                {canEditSeat && (
                    <Col>
                        <Button
                            data-sdet={ADD_INDUSTRY_BUTTON_SDET}
                            type="primary"
                            onClick={() =>
                                history.push(
                                    ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_CREATE_PAGE(seatId)
                                )
                            }
                        >
                            Add Industry
                        </Button>
                    </Col>
                )}
            </Row>
            <Table
                size="small"
                data-sdet={INDUSTRIES_TABLE_SDET}
                onChange={onChange}
                showSorterTooltip={false}
                dataSource={data}
                columns={getIndustriesColumns(
                    sortBy,
                    sortOrder,
                    canEditSeat,
                    isSeatAdmin,
                    onClickName,
                    onClickEdit,
                    onClickChangeHistory
                )}
                loading={isFetching}
                pagination={paginationConfig}
                rowKey="id"
            />
        </Space>
    );
};
