import { SeatAdSourcesType } from "@app/core/services";
import { AD_SOURCE_FIELDS, AD_SOURCE_TYPES_NEXT_UI_NAMES, AdSourceTypes } from "@app/features/seatAdSources/constants";
import { Form } from "antd";

const { useWatch } = Form;

export const useIsFieldVisible = () => {
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);
    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSourceType?.name] ?? adSourceType?.name;

    return adSourceTypeName !== AdSourceTypes.FALLBACK_TAG;
};
