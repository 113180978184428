import { AdSource } from "@app/core/services";
import { AD_SOURCE_TYPES_NEXT_UI_NAMES, AdSourceTypes } from "@app/features/seatAdSources/constants";

export const useIsFieldVisible = (adSource: AdSource) => {
    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSource.type.name];

    switch (adSourceTypeName) {
        case AdSourceTypes.PROGRAMATIC_GUARANTEED:
        case AdSourceTypes.CONDITIONAL_PROGRAMATIC_GUARANTEED:
        case AdSourceTypes.FIXED_PRICE:
        case AdSourceTypes.LINEAR_FIXED_PRICE:
            return true;
        default:
            return false;
    }
};
