import { ChangeHistoryTable } from "@app/core/components/ChangeHistoryTable";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ChangeLogEntityType } from "@app/core/services";
import { css } from "@emotion/css";
import { Card } from "antd";
import { FC } from "react";
import { TargetingDrawerTitle } from "../TargetingPage/TargetingTable/TargetingDetailsDrawer";
import { TargetingDrawer } from "./TargetingDrawer";

interface TargetingDrawerChangeHistoryProps {
    seatId: number;
    targetingId: number;
    open: boolean;
    handleClose: () => void;
}
export const TargetingDrawerChangeHistory: FC<TargetingDrawerChangeHistoryProps> = ({
    open,
    targetingId,
    seatId,
    handleClose,
}) => (
    <TargetingDrawer
        open={open}
        title={
            <TargetingDrawerTitle
                name="Change History"
                route={ROUTE_FORMATTERS.SEAT_CONTROLS_TARGETING_CHANGE_HISTORY(seatId, targetingId)}
            />
        }
        closeDrawer={handleClose}
    >
        <Card
            type="inner"
            bordered={false}
            className={css`
                &.ant-card {
                    box-shadow: none;
                }
            `}
        >
            <ChangeHistoryTable entityId={targetingId} entityType={ChangeLogEntityType.Targeting} />
        </Card>
    </TargetingDrawer>
);
