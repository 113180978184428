import { FC } from "react";
import { FilterSelect } from "@app/core/components/FilterSelect";
import { useSeatAdSourcesPacingStatusFilter } from "./useSeatAdSourcesPacingStatusFilter";
import { HelpTooltip } from "@app/core/components";
import { AdSourcesPacingHelpKeys } from "@app/features/seatAdSources/constants";

export const SeatAdSourcesPacingStatusFilter: FC = () => {
    const { handleChangeValue, options, value, isLoading } = useSeatAdSourcesPacingStatusFilter();

    return (
        <FilterSelect
            dataSdet="seat-ad-sources-pacing-status-filter"
            isLoading={isLoading}
            label={
                <HelpTooltip
                    helpKeyList={AdSourcesPacingHelpKeys}
                    helpKey={AdSourcesPacingHelpKeys.Status}
                    popover={true}
                >
                    Status
                </HelpTooltip>
            }
            onChange={handleChangeValue}
            options={options}
            value={value}
        />
    );
};
