import { RuleObject } from "antd/lib/form";
import { FormInstance } from "antd/lib/form";
import { AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { getConvertedMaxDate, isDateMaximum, parseDateStringFromApi } from "@app/core/utils";
import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED } from "@app/core/components/constants";

export const minEndDateValidator =
    (startDate: AdSourcesForm[typeof AD_SOURCE_FIELDS.START_DATE.name]) =>
    (_: RuleObject, adSourceEndDate: AdSourcesForm[typeof AD_SOURCE_FIELDS.END_DATE.name]) => {
        if (!adSourceEndDate) {
            return Promise.resolve();
        }
        if (startDate.isAfter(adSourceEndDate)) {
            return Promise.reject(
                `${AD_SOURCE_FIELDS.END_DATE.label} can not be earlier than ${AD_SOURCE_FIELDS.START_DATE.label}`
            );
        }
        return Promise.resolve();
    };

export const minGroupEndDateValidator =
    (adSourceForm: FormInstance<AdSourcesForm>) =>
    (_: RuleObject, adSourceEndDate: AdSourcesForm[typeof AD_SOURCE_FIELDS.END_DATE.name]) => {
        const adSource = adSourceForm.getFieldsValue();
        const adSourceTypeId = adSource[AD_SOURCE_FIELDS.TYPE.name]?.id;
        const adSourceTimeZone = adSource[AD_SOURCE_FIELDS.TIME_ZONE.name];
        const marketplaceInfoList = adSource[AD_SOURCE_FIELDS.DEMAND.name];

        const isGroupFixedOrAuction =
            marketplaceInfoList?.length &&
            marketplaceInfoList.length >= 2 &&
            [AdSourceTypeIds.UNRESERVED_FIXED_RATE, AdSourceTypeIds.AUCTION_PRICE].includes(adSourceTypeId);

        if (!isGroupFixedOrAuction) {
            return Promise.resolve();
        }

        const dealEndDates = marketplaceInfoList.map((marketplaceInfo) => {
            const isLegacyDeal = marketplaceInfo.deal.entityType === "Deal";
            const dealOrMarketplaceInfoEndDate = isLegacyDeal ? marketplaceInfo.endDate : marketplaceInfo.deal.endTime;
            const dealOrAdSourceTimeZoneCode = isLegacyDeal
                ? adSource.timeZone.code
                : marketplaceInfo.deal.timeZone.code;
            return {
                // date should already be a moment at this point, but parse just in case
                date: parseDateStringFromApi(dealOrMarketplaceInfoEndDate, dealOrAdSourceTimeZoneCode),
                timeZoneCode: dealOrAdSourceTimeZoneCode,
            };
        });

        if (!adSourceEndDate || isDateMaximum(adSourceEndDate, adSourceTimeZone.code, dealEndDates)) {
            return Promise.resolve();
        }

        if (dealEndDates.some((dealEndDate) => !dealEndDate.date)) {
            return Promise.reject("End Date must be deselected if any Deal End Dates are not set");
        }

        const maxDealEndDateInAdSourceTimeZone = getConvertedMaxDate(dealEndDates, adSourceTimeZone.code)?.format(
            MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED
        );
        return Promise.reject(
            `End Date cannot be earlier than the latest Deal End Date (${maxDealEndDateInAdSourceTimeZone} ${adSourceTimeZone.name})`
        );
    };
