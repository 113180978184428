import { FormLayout } from "antd/lib/form/Form";

export const NAME = "name";
export const BLOCKED_ADOMAIN_LIST = "blockedAdomainList";
export const BLOCKED_ADOMAINS = "blockedAdomains";
export const BLOCKED_BUYER_SEAT_LIST = "blockedBuyerSeatList";
export const BLOCKED_CATEGORIES = "blockedCategories";
export const ADVERTISER_FREQ_CAPPING = "advertiserFreqCapping";
export const ACTIONS = "actions";
export const ENTITY_TYPE = "entityType";

export const COLUMN_KEY = {
    NAME,
    BLOCKED_ADOMAIN_LIST,
    BLOCKED_ADOMAINS,
    BLOCKED_BUYER_SEAT_LIST,
    ADVERTISER_FREQ_CAPPING,
    ACTIONS,
    ENTITY_TYPE,
};

export const FORM_GRID_OPTIONS: { [key in FormLayout]: { labelCol: { span: number }; wrapperCol: { span: number } } } =
    {
        horizontal: { labelCol: { span: 4 }, wrapperCol: { span: 8 } },
        vertical: { labelCol: { span: 24 }, wrapperCol: { span: 24 } },
        inline: { labelCol: { span: 24 }, wrapperCol: { span: 24 } },
    };
