import { useAppSelector } from "@app/core/store";
import {
    InventoryDetailsDrawerType,
    selectInventoryDetailsDrawerType,
    selectIsPageInventoryDetailsDrawer,
} from "../reducer";
import { useUnitData } from "./useUnitData";

export const useNestedData = (id: number | string) => {
    const isPage = useAppSelector(selectIsPageInventoryDetailsDrawer);
    const detailsDrawerType = useAppSelector(selectInventoryDetailsDrawerType) as InventoryDetailsDrawerType;
    const { brandData, publisherData, supplyData, adUnitData, channelData, isLoading } = useUnitData(
        id,
        detailsDrawerType
    );
    let nestedBreadcrumbs: string | undefined = undefined;

    if (!isPage) {
        switch (detailsDrawerType) {
            case InventoryDetailsDrawerType.PUBLISHER:
                nestedBreadcrumbs = publisherData?.name;
                break;
            case InventoryDetailsDrawerType.BRAND:
                nestedBreadcrumbs = brandData?.name;
                break;
            case InventoryDetailsDrawerType.SUPPLY:
                nestedBreadcrumbs = supplyData?.name;
                break;
            case InventoryDetailsDrawerType.AD_UNIT:
                nestedBreadcrumbs = adUnitData?.name;
                break;
            case InventoryDetailsDrawerType.CHANNEL:
            case InventoryDetailsDrawerType.BRAND_CHANNEL:
                nestedBreadcrumbs = channelData?.name;
                break;
        }
    }

    return {
        nestedBreadcrumbs,
        isLoading,
    };
};
