import { Form } from "antd";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { AdResponsePriceOverride, useGetAdResponsePriceOverridesQuery } from "@app/core/services";
import { useIsAdResponsePriceOverrideVisible } from "./useIsAdResponsePriceOverrideVisible";

interface UseAdResponsePriceOverrideSelect {
    isFetching: boolean;
    isVisible: boolean;
    options: AdResponsePriceOverride[];
}

export const useAdResponsePriceOverrideSelect = (): UseAdResponsePriceOverrideSelect => {
    const { data, isFetching } = useGetAdResponsePriceOverridesQuery();
    const adSourceTypeId = Form.useWatch(AD_SOURCE_FIELDS.TYPE.name)?.id;
    const isVisible = useIsAdResponsePriceOverrideVisible(adSourceTypeId);

    return {
        isFetching,
        isVisible,
        options: data || [],
    };
};
