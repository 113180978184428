import { FC } from "react";
import { useParams } from "react-router-dom";
import { Form, Select } from "antd";
import { useGetSeatBuyerSeatListsQuery } from "@app/core/services/console";

interface Props {
    name: string;
    label: string;
}

export const BlockedBuyerSeatListFormItem: FC<Props> = ({ name, label }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data = [], isFetching } = useGetSeatBuyerSeatListsQuery({ seatId: Number(seatId) });

    return (
        <Form.Item style={{ maxWidth: "275px" }} name={name} label={label}>
            <Select
                allowClear
                labelInValue
                showSearch
                loading={isFetching}
                optionFilterProp="label"
                options={data.map((seatList) => ({ value: seatList.id, label: seatList.name }))}
                placeholder="Select Blocked Buyer Seat List"
            />
        </Form.Item>
    );
};
