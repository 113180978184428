import { MarketplaceInfo } from "@app/core/services";
import { ReadonlyControl, SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { Col, Form, Space } from "antd";
import { FC } from "react";
import { IsCapableOfSyncTooltip } from "./IsCapableOfSyncTooltip";
import { useNetworkSelect } from "./useNetworkSelect";
import { XandrWarningModal } from "@app/core/components";

export const NetworkSelect: FC = () => {
    const { availableNetworks, dealObjectMode, isLoading, isVisible, isReadonly, selectedDspNetworkId, onChange } =
        useNetworkSelect();
    if (!isVisible) {
        return null;
    }
    return (
        <Col sm={12}>
            <XandrWarningModal selectedDspNetworkId={selectedDspNetworkId} formMode={dealObjectMode} />
            <Form.Item
                data-sdet="deal-dsp-field"
                rules={[{ required: !isReadonly, message: "DSP is required" }]}
                label="DSP"
                name={["dealUnderEdit", "network"]}
            >
                {isReadonly ? (
                    <ReadonlyControl<MarketplaceInfo["deal"]["network"]> getLabel={(network) => network.name} />
                ) : (
                    <SelectWithValue
                        showSearch
                        placeholder="Select a DSP"
                        options={availableNetworks}
                        loading={isLoading}
                        optionFilterProp="label"
                        fieldAsLabel={({ name, externalDealSyncIntegrations }) => (
                            <Space>
                                {name}
                                {externalDealSyncIntegrations?.length ? <IsCapableOfSyncTooltip /> : null}
                            </Space>
                        )}
                        fieldAsValue={["id"]}
                        filterOption={(input, option) => {
                            const network = availableNetworks.find((network) => network.id === option?.value);
                            return Boolean((network?.name || "").toLowerCase().includes(input.toLowerCase()));
                        }}
                        onChange={onChange}
                    />
                )}
            </Form.Item>
        </Col>
    );
};
