import { FC } from "react";
import { Card, Col, Row } from "antd";
import { BRAND_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import {
    RateOverride,
    PublisherReAuction,
} from "@app/features/inventory/HierarchyForms/BrandForm/BrandFormSections/InternalSection/Fields";

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 24, xl: 14, xxl: 12 };
const INPUTS_COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 };
export const InternalSection: FC = () => {
    return (
        <Card bordered={false}>
            <PublisherReAuction />
            <Row>
                <Col {...COL_SIZES}>
                    <Row gutter={16}>
                        <Col {...INPUTS_COL_SIZES}>
                            <RateOverride
                                {...BRAND_FORM_FIELDS.LOWER_CALCULON_USE_RATE_OVERRIDE}
                                formItemProps={{
                                    dependencies: [BRAND_FORM_FIELDS.UPPER_CALCULON_USE_RATE_OVERRIDE.name],
                                }}
                                type="min"
                            />
                        </Col>
                        <Col {...INPUTS_COL_SIZES}>
                            <RateOverride
                                {...BRAND_FORM_FIELDS.UPPER_CALCULON_USE_RATE_OVERRIDE}
                                formItemProps={{
                                    dependencies: [BRAND_FORM_FIELDS.LOWER_CALCULON_USE_RATE_OVERRIDE.name],
                                }}
                                type="max"
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};
