import { FC, ReactNode, useCallback, useState, useMemo } from "react";
import { useGetGeographiesCountriesQuery } from "@app/core/services/console";
import { Form, Select } from "antd";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { CREATE_FLOOR_FORM_ITEMS_NAME } from "@app/features/inventory/constants";

export const useCountryField = () => {
    const [localValue, setLocalValue] = useState("");
    const [keyword, setKeyword] = useState("");
    const { data = [], isFetching } = useGetGeographiesCountriesQuery({ keyword });

    const handleSearch = useCallback((value: string): void => {
        setKeyword(value);
    }, []);

    const debouncedHandleSearch = useMemo(
        () => debounce((value: string) => handleSearch(value), FILTER_INPUT_DEBOUNCE_TIME),
        [handleSearch]
    );

    const handleReset = (): void => {
        setLocalValue("");
    };

    const handleChange = (v: string): void => {
        setLocalValue(v);
        debouncedHandleSearch(v);
    };

    return {
        handleChange,
        handleReset,
        localValue,
        handleSearch,
        isLoading: isFetching,
        countries: data,
    };
};

interface Props {
    label?: ReactNode | null;
    margin?: string | number;
}

export const CountryField: FC<Props> = ({ label = "Country", margin }) => {
    const { handleChange, handleReset, isLoading, countries } = useCountryField();

    return (
        <Form.Item label={label} name={CREATE_FLOOR_FORM_ITEMS_NAME.COUNTRY} style={{ margin }}>
            <Select
                allowClear
                labelInValue
                loading={isLoading}
                onClear={handleReset}
                onSearch={handleChange}
                optionFilterProp="label"
                options={countries.map((datum) => ({ value: datum.geo.id, label: datum.geo.countryName }))}
                placeholder="All Geos"
                showSearch
                style={{ width: "100%" }}
            />
        </Form.Item>
    );
};
