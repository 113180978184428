import { LabeledValue } from "antd/lib/select";
import { useUserAccess } from "@app/core/auth";
import {
    CHANNEL_FORM_FIELDS,
    DEFAULT_ADVERTISER_FREQUENCY_CAPPING,
} from "@app/features/inventory/HierarchyForms/constants";
import { useBrandChannelFormInstance } from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormInstance";

interface UseDetailedConfigurationSection {
    isUnwrapVASTShown: boolean;
    isInternalOnlyShown: boolean;
    isLSAWindowSizeSecondsShown: boolean;
    isLiveStreamAccelerationShown: boolean;
    isAdvertisersAsAllowListShown: boolean;
    isAdvertiserDomainFrequencyCapsShown: boolean;
}

export const useDetailedConfigurationSection = (): UseDetailedConfigurationSection => {
    const { isTremorUser } = useUserAccess();
    const { useWatch, seat } = useBrandChannelFormInstance();
    const liveStreamAcceleration: boolean = useWatch(CHANNEL_FORM_FIELDS.LIVE_STREAM_ACCELERATION.name);
    const freqCapp: LabeledValue = useWatch(CHANNEL_FORM_FIELDS.ADV_FREQ_CAPP.name);

    const isAdvertiserDomainFrequencyCapsShown: boolean =
        freqCapp?.value !== DEFAULT_ADVERTISER_FREQUENCY_CAPPING.value;
    const isUnwrapVASTShown = !!seat?.allowVastUnwrapping;
    const isLiveStreamAccelerationShown = !!seat?.lsaEnabled;
    const isAdvertisersAsAllowListShown = !!seat?.enableAdvertisersWhitelist;
    const isLSAWindowSizeSecondsShown: boolean = liveStreamAcceleration && isLiveStreamAccelerationShown;

    return {
        isUnwrapVASTShown,
        isLSAWindowSizeSecondsShown,
        isLiveStreamAccelerationShown,
        isAdvertisersAsAllowListShown,
        isInternalOnlyShown: isTremorUser,
        isAdvertiserDomainFrequencyCapsShown,
    };
};
