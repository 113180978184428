import { useMemo } from "react";
import { useSeatAuthContext } from "@app/core/auth/useSeatAuthContext";
import { UserRole } from "@app/core/clients/console";
import { SeatAccess } from "@app/core/services";

export enum USER_ROLES {
    ROLE_USER = 1,
    ROLE_FULL_ADMIN = 2,
    ROLE_PUB_ACCT_MGR = 3,
    ROLE_NET_ACCT_MGR = 4,
    ROLE_REPORTING = 5,
    ROLE_SUPPLY_SYNC = 6,
    ROLE_RUN_AS = 7,
    ROLE_FULL_READ_ONLY = 8,
    ROLE_NOTIFIER_DAEMON = 9,
    ROLE_UBER_ADMIN = 10,
    ROLE_DEMAND_SALES_MGR = 11,
    ROLE_API_ACCESS = 12,
    ROLE_DEMAND_PARTNER = 13,
    ROLE_BUYER_USER = 14,
    ROLE_CONDITIONER_DAEMON = 15,
    ROLE_ORG_ACCT_ADMIN = 16,
    ROLE_ADV_NET_ACCT_MGR = 17,
    ROLE_DEAL_LIBRARY_MANAGER = 18,
    ROLE_SUPPLY_FILTER_MGR = 20,
    ROLE_DMP_MGR = 21,
    ROLE_BUYER_DISCOUNT = 24,
    ROLE_ADMIN_DEBUG = 25,
}

const getInitialActiveUserRoles = () => ({
    [USER_ROLES.ROLE_USER]: false,
    [USER_ROLES.ROLE_FULL_ADMIN]: false,
    [USER_ROLES.ROLE_PUB_ACCT_MGR]: false,
    [USER_ROLES.ROLE_NET_ACCT_MGR]: false,
    [USER_ROLES.ROLE_REPORTING]: false,
    [USER_ROLES.ROLE_SUPPLY_SYNC]: false,
    [USER_ROLES.ROLE_RUN_AS]: false,
    [USER_ROLES.ROLE_FULL_READ_ONLY]: false,
    [USER_ROLES.ROLE_NOTIFIER_DAEMON]: false,
    [USER_ROLES.ROLE_UBER_ADMIN]: false,
    [USER_ROLES.ROLE_DEMAND_SALES_MGR]: false,
    [USER_ROLES.ROLE_API_ACCESS]: false,
    [USER_ROLES.ROLE_DEMAND_PARTNER]: false,
    [USER_ROLES.ROLE_BUYER_USER]: false,
    [USER_ROLES.ROLE_CONDITIONER_DAEMON]: false,
    [USER_ROLES.ROLE_ORG_ACCT_ADMIN]: false,
    [USER_ROLES.ROLE_ADV_NET_ACCT_MGR]: false,
    [USER_ROLES.ROLE_DEAL_LIBRARY_MANAGER]: false,
    [USER_ROLES.ROLE_SUPPLY_FILTER_MGR]: false,
    [USER_ROLES.ROLE_DMP_MGR]: false,
    [USER_ROLES.ROLE_BUYER_DISCOUNT]: false,
    [USER_ROLES.ROLE_ADMIN_DEBUG]: false,
});

export type ActiveUserRoles = Record<USER_ROLES, boolean>;

export interface SeatAccessFlagsTypes {
    hasAdServingAccess: boolean;
    hasAdSourcesAccess: boolean;
    hasCreativeReviewBetaAccess: boolean;
    hasDealLibraryAccess: boolean;
    hasDealsAccess: boolean;
    hasDiagnosticsAccess: boolean;
    hasIntegrationsAccess: boolean;
    hasInventoryAccess: boolean;
    hasPacingTypeNoneAccess: boolean;
    hasSeatControlsAccess: boolean;
}

const getActiveUserRoles = (userRoles: UserRole[]): ActiveUserRoles => {
    return userRoles.reduce((activeUserRoles, userRole) => {
        activeUserRoles[userRole.roleType.id] = true;
        return activeUserRoles;
    }, getInitialActiveUserRoles());
};

interface UseUserAccess {
    activeUserRoles: ActiveUserRoles;
    seatAccessFlags: SeatAccessFlagsTypes;
    hasSeatWriteAccess: boolean;
    hasAdminAccess: boolean;
    hasInternalAccess: boolean;
    isTremorUser: boolean;
    isDemandPartner: boolean;
    isSeatAdmin: boolean;
    isSupplySeat: boolean;
    isPubAcctMgr: boolean;
    isUberAdmin: boolean;
    isSysAdmin: boolean;
    canEditSeat: boolean;
    canReadSeat: boolean;
    showFallbackOpportunity: boolean;
    showDefaultPrices: boolean;
    showAdResponsePriceOverride: boolean;
    isSysReadOnly: boolean;
    hasTremorRole: boolean;
    isLoading: boolean;
}

export const useUserAccess = (): UseUserAccess => {
    const { context, seatAccess, seatCount, session, isLoading } = useSeatAuthContext();
    const activeUserRoles = getActiveUserRoles(session?.user?.userRoles ?? []);
    const isSupplySeat = Boolean(context?.isSupplySeat);
    const adServingEnabled = Boolean(context?.enableAdServing);
    const testCreativeUploadEnabled = Boolean(context?.enableTestCreativeUpload);
    const isDemandSeat = Boolean(context?.isDemandSeat);

    const integrationsEnabled =
        context?.moatIntegrationEnabled ||
        context?.contentMetadataEnabled ||
        Boolean(context?.externalAdUnitCodeNamespaces.length) ||
        context?.dmpCreationEnabled ||
        context?.allowDemandCridBlocking ||
        context?.isDemandSeat ||
        context?.isMvpd;

    const isSysAdmin: boolean = activeUserRoles[USER_ROLES.ROLE_FULL_ADMIN];
    const isSysReadOnly: boolean = activeUserRoles[USER_ROLES.ROLE_FULL_READ_ONLY];
    const isDemandPartner: boolean = activeUserRoles[USER_ROLES.ROLE_DEMAND_PARTNER];
    const isPubAcctMgr: boolean = activeUserRoles[USER_ROLES.ROLE_PUB_ACCT_MGR];
    const isSupplyFilterMgr: boolean = activeUserRoles[USER_ROLES.ROLE_SUPPLY_FILTER_MGR];
    const isDemandSalesMgr: boolean = activeUserRoles[USER_ROLES.ROLE_DEMAND_SALES_MGR];
    const isUberAdmin: boolean = activeUserRoles[USER_ROLES.ROLE_UBER_ADMIN];
    const isNetAcctMgr: boolean = activeUserRoles[USER_ROLES.ROLE_NET_ACCT_MGR];
    const isAdvNetAcctMgr: boolean = activeUserRoles[USER_ROLES.ROLE_ADV_NET_ACCT_MGR];
    const isDealLibMgr: boolean = activeUserRoles[USER_ROLES.ROLE_DEAL_LIBRARY_MANAGER];
    const isBuyerUser: boolean = activeUserRoles[USER_ROLES.ROLE_BUYER_USER];

    const seatPermissions = useMemo<SeatAccess>(
        () => (isSysAdmin ? { ...seatAccess, read: true, write: true, administration: true } : seatAccess),
        [isSysAdmin, seatAccess]
    );

    const isTremorUser: boolean = useMemo(
        () =>
            isUberAdmin ||
            isSysAdmin ||
            isSysReadOnly ||
            isPubAcctMgr ||
            isNetAcctMgr ||
            isAdvNetAcctMgr ||
            isDealLibMgr ||
            isSupplyFilterMgr,
        [
            isUberAdmin,
            isSysAdmin,
            isSysReadOnly,
            isPubAcctMgr,
            isNetAcctMgr,
            isAdvNetAcctMgr,
            isDealLibMgr,
            isSupplyFilterMgr,
        ]
    );

    const hasTremorRole: boolean = useMemo(() => {
        return (
            isSysAdmin ||
            isPubAcctMgr ||
            isNetAcctMgr ||
            isDemandSalesMgr ||
            isAdvNetAcctMgr ||
            isDealLibMgr ||
            isSupplyFilterMgr ||
            isSysReadOnly
        );
    }, [
        isSysAdmin,
        isPubAcctMgr,
        isNetAcctMgr,
        isDemandSalesMgr,
        isAdvNetAcctMgr,
        isDealLibMgr,
        isSupplyFilterMgr,
        isSysReadOnly,
    ]);
    const isInternalUser: boolean = useMemo(
        () => isSysAdmin || isUberAdmin || isPubAcctMgr || isDealLibMgr || isDemandSalesMgr,
        [isSysAdmin, isUberAdmin, isPubAcctMgr, isDealLibMgr, isDemandSalesMgr]
    );

    const showAdmin: boolean = useMemo(() => isTremorUser || isDemandSalesMgr, [isTremorUser, isDemandSalesMgr]);
    const isSeatAdmin: boolean = useMemo(
        () => isSysAdmin || seatPermissions?.administration,
        [isSysAdmin, seatPermissions]
    );
    const canEditSeat: boolean = useMemo(() => isSeatAdmin || seatPermissions?.write, [isSeatAdmin, seatPermissions]);
    const canReadSeat: boolean = useMemo(() => canEditSeat || seatPermissions?.read, [canEditSeat, seatPermissions]);

    const hasSeatWriteAccess: boolean = useMemo(
        () => seatPermissions?.write || seatPermissions?.administration,
        [seatPermissions]
    );

    const showInventory = useMemo<boolean>(() => {
        if (!isDemandPartner) {
            if (isSysReadOnly) {
                return isSupplySeat;
            } else if (isSeatAdmin) {
                return isSupplySeat;
            } else if (canEditSeat) {
                return isSupplySeat;
            } else if (typeof seatCount === "number" && seatCount > 0) {
                return isSupplySeat;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }, [isDemandPartner, isSysReadOnly, isSeatAdmin, canEditSeat, isSupplySeat, seatCount]);

    const showAdSourceAndDeal = useMemo<boolean>(() => {
        if (showInventory) {
            return true;
        } else if (isNetAcctMgr) {
            return isSupplySeat;
        } else if (isDemandPartner) {
            return false;
        } else if (typeof seatCount === "number" && seatCount > 0) {
            return isSupplySeat;
        } else {
            return false;
        }
    }, [showInventory, isNetAcctMgr, isSupplySeat, isDemandPartner, seatCount]);

    const showAdServing = useMemo<boolean>(() => {
        if (showInventory) {
            return adServingEnabled || testCreativeUploadEnabled;
        }
        return false;
    }, [showInventory, adServingEnabled, testCreativeUploadEnabled]);

    const showSeatIntegrations = useMemo<boolean>(() => {
        return (isSeatAdmin && integrationsEnabled) || isSysReadOnly;
    }, [isSeatAdmin, integrationsEnabled, isSysReadOnly]);

    const showSeatControls = useMemo<boolean>(() => {
        if (canReadSeat && isDemandPartner) {
            return false;
        }
        return canReadSeat || isSysReadOnly || isPubAcctMgr || isSupplyFilterMgr;
    }, [canReadSeat, isDemandPartner, isSysReadOnly, isPubAcctMgr, isSupplyFilterMgr]);

    const shownDiagnostics: boolean = isSupplySeat || isDemandSeat;

    const showDealsLibrary = useMemo<boolean>(() => {
        return isTremorUser || isBuyerUser || isDemandPartner;
    }, [isTremorUser, isBuyerUser, isDemandPartner]);

    const showDefaultPrices = useMemo<boolean>(() => {
        return isSysAdmin || isPubAcctMgr || isSysReadOnly;
    }, [isSysAdmin, isPubAcctMgr, isSysReadOnly]);

    const showFallbackOpportunity = useMemo<boolean>(() => {
        return isSysAdmin || isPubAcctMgr || isNetAcctMgr;
    }, [isSysAdmin, isPubAcctMgr, isNetAcctMgr]);

    const showAdResponsePriceOverride = useMemo<boolean>(
        () => Boolean(context?.seatFlags?.adResponsePriceOverrideTypeEnabled),
        [context?.seatFlags?.adResponsePriceOverrideTypeEnabled]
    );

    const showCreativeReviewBeta = useMemo<boolean>(
        () => Boolean(context?.seatFlags?.creativeReviewBetaEnabled),
        [context?.seatFlags?.creativeReviewBetaEnabled]
    );

    const showPacingTypeNone = useMemo<boolean>(
        () => Boolean(context?.seatFlags?.allowPacingTypeNone),
        [context?.seatFlags?.allowPacingTypeNone]
    );

    const seatAccessFlags = useMemo<SeatAccessFlagsTypes>(
        () => ({
            hasAdServingAccess: showAdServing,
            hasAdSourcesAccess: showAdSourceAndDeal,
            hasCreativeReviewBetaAccess: showCreativeReviewBeta,
            hasDealLibraryAccess: showDealsLibrary,
            hasDealsAccess: showAdSourceAndDeal,
            hasDiagnosticsAccess: shownDiagnostics,
            hasIntegrationsAccess: showSeatIntegrations,
            hasInventoryAccess: showInventory,
            hasPacingTypeNoneAccess: showPacingTypeNone,
            hasSeatControlsAccess: showSeatControls,
        }),
        [
            showAdServing,
            showAdSourceAndDeal,
            showCreativeReviewBeta,
            showDealsLibrary,
            showInventory,
            showPacingTypeNone,
            showSeatControls,
            showSeatIntegrations,
            shownDiagnostics,
        ]
    );

    return {
        isLoading,
        isSysAdmin,
        isUberAdmin,
        isPubAcctMgr,
        seatAccessFlags,
        showDefaultPrices,
        isSysReadOnly,
        hasAdminAccess: showAdmin,
        hasSeatWriteAccess,
        activeUserRoles,
        hasInternalAccess: isInternalUser,
        isTremorUser,
        isDemandPartner,
        isSeatAdmin,
        isSupplySeat,
        canEditSeat,
        hasTremorRole,
        canReadSeat,
        showFallbackOpportunity,
        showAdResponsePriceOverride,
    };
};
