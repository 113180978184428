import { useAppDispatch, useAppSelector } from "@app/core/store";
import { closeDrawer, openDrawer, selectIsDrawerOpen } from "./reducer";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "./types";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { convertSecondaryViewToURL } from "./normalizeSecondaryViewFromURL";
import { AD_SOURCE_DRAWER_LOCATION } from "../constants";

export interface UseSeatAdSourcesDetailsDrawer {
    isSeatAdSourcesDetailsDrawerOpen: boolean;
    openSeatAdSourcesDetailsDrawer: (
        adSourceId: number,
        secondaryDetailsView?: SecondaryDetailsView,
        openedFromDetailsView?: boolean
    ) => void;
    closeSeatAdSourcesDetailsDrawer: () => void;
    openSeadAdSourceDrawerDeals: (adSourceId: number, secondaryDetailsView?: SecondaryDetailsView) => void;
    closeDrawerWhithoutHistoryChange: () => void;
    closeDrawerBackToPage: () => void;
    openSeatAdSourceDetailsPageDrawer: (adSourceId: number, secondaryDetailsView?: SecondaryDetailsView) => void;
    openSeatAdSourceDrawerPacing: (adSourceId: number, secondaryDetailsView?: SecondaryDetailsView) => void;
}

export const useSeatAdSourcesDetailsDrawer = (): UseSeatAdSourcesDetailsDrawer => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation();
    const { seatId, adSourceId, dealId, dealType } = useParams<{
        seatId: string;
        adSourceId: string;
        dealId: string;
        dealType: "demand" | "inventory" | "legacy";
    }>();
    const isSeatAdSourcesDetailsDrawerOpen = useAppSelector(selectIsDrawerOpen);

    const openSeatAdSourceDrawerPacing = (adSourceId: number, secondaryDetailsView?: SecondaryDetailsView) => {
        dispatch(openDrawer({ requestedAdSourceId: adSourceId, isReadonly: false }));
        if (secondaryDetailsView) {
            history.push(
                ROUTE_FORMATTERS.SEAT_AD_SOURCES_PACING_DETAILS_SECONDARY_VIEW(
                    seatId,
                    adSourceId,
                    convertSecondaryViewToURL(secondaryDetailsView)
                )
            );
            return;
        }
        history.push(ROUTE_FORMATTERS.SEAT_AD_SOURCES_PACING_DETAILS(seatId, adSourceId));
    };

    const openSeadAdSourceDrawerDeals = (adSourceId: number, secondaryDetailsView?: SecondaryDetailsView) => {
        dispatch(openDrawer({ requestedAdSourceId: adSourceId, isReadonly: true }));
        if (
            location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.DEALS) &&
            location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.COPY)
        ) {
            history.push(
                secondaryDetailsView
                    ? ROUTE_FORMATTERS.SEAT_DEAL_COPY_AD_SOURCES_DRAWER_SECONDARY_VIEW(
                          seatId,
                          dealId,
                          adSourceId,
                          convertSecondaryViewToURL(secondaryDetailsView)
                      )
                    : ROUTE_FORMATTERS.SEAT_DEAL_COPY_AD_SOURCES_DRAWER(seatId, dealId, adSourceId)
            );
            return;
        }
        if (location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.DEALS_CREATE_DEMAND)) {
            history.push(
                secondaryDetailsView
                    ? ROUTE_FORMATTERS.SEAT_DEAL_CREATE_TYPE_AD_SOURCES_DRAWER_SECONDARY_VIEW(
                          seatId,
                          adSourceId,
                          dealType,
                          convertSecondaryViewToURL(secondaryDetailsView)
                      )
                    : ROUTE_FORMATTERS.SEAT_DEAL_CREATE_TYPE_AD_SOURCES_DRAWER(seatId, adSourceId, dealType)
            );
            return;
        }
        if (location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.DEALS_HEALTH)) {
            history.push(
                secondaryDetailsView
                    ? ROUTE_FORMATTERS.SEAT_DEALS_HEALTH_AD_SOURCES_DRAWER_SECONDARY_VIEW(
                          seatId,
                          adSourceId,
                          convertSecondaryViewToURL(secondaryDetailsView)
                      )
                    : ROUTE_FORMATTERS.SEAT_DEALS_HEALTH_AD_SOURCES_DRAWER(seatId, adSourceId)
            );
            return;
        }
        if (location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.EDIT)) {
            history.push(
                secondaryDetailsView
                    ? ROUTE_FORMATTERS.SEAT_DEAL_EDIT_AD_SOURCES_DRAWER_SECONDARY_VIEW(
                          seatId,
                          dealId,
                          adSourceId,
                          convertSecondaryViewToURL(secondaryDetailsView)
                      )
                    : ROUTE_FORMATTERS.SEAT_DEAL_EDIT_AD_SOURCES_DRAWER(seatId, dealId, adSourceId)
            );
            return;
        }
        if (location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.DEALS)) {
            if (!!Number(dealId) && !location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.DEAL_DETAILS_DRAWER)) {
                history.push(
                    secondaryDetailsView
                        ? ROUTE_FORMATTERS.SEAT_DEAL_DETAILS_AD_SOURCES_DRAWER_SECONDARY_VIEW(
                              seatId,
                              dealId,
                              adSourceId,
                              convertSecondaryViewToURL(secondaryDetailsView)
                          )
                        : ROUTE_FORMATTERS.SEAT_DEAL_DETAILS_AD_SOURCES_DRAWER(seatId, dealId, adSourceId)
                );
                return;
            }
            history.push(
                secondaryDetailsView
                    ? ROUTE_FORMATTERS.SEAT_DEALS_AD_SOURCES_DRAWER_SECONDARY_VIEW(
                          seatId,
                          adSourceId,
                          convertSecondaryViewToURL(secondaryDetailsView)
                      )
                    : ROUTE_FORMATTERS.SEAT_DEALS_AD_SOURCES_DRAWER(seatId, adSourceId)
            );
            return;
        }
    };

    const openSeatAdSourceDetailsPageDrawer = (adSourceId: number, secondaryDetailsView: SecondaryDetailsView) => {
        dispatch(openDrawer({ requestedAdSourceId: adSourceId, secondaryDetailsView, isReadonly: false }));
        if (secondaryDetailsView) {
            history.push(
                ROUTE_FORMATTERS.SEAT_AD_SOURCES_DETAILS_PAGE_SECONDARY_VIEW(
                    seatId,
                    adSourceId,
                    convertSecondaryViewToURL(secondaryDetailsView)
                )
            );
        }
    };

    const openSeatAdSourcesDetailsDrawer = (
        adSourceId: number,
        secondaryDetailsView?: SecondaryDetailsView,
        openedFromDetailsView = false
    ) => {
        dispatch(
            openDrawer({
                requestedAdSourceId: adSourceId,
                secondaryDetailsView,
                openedFromDetailsView,
                isReadonly: location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.DEALS),
            })
        );
        if (secondaryDetailsView) {
            history.push(
                openedFromDetailsView
                    ? ROUTE_FORMATTERS.SEAT_AD_SOURCES_DETAILS_DRAWER_SECONDARY_VIEW(
                          seatId,
                          adSourceId,
                          convertSecondaryViewToURL(secondaryDetailsView)
                      )
                    : ROUTE_FORMATTERS.SEAT_AD_SOURCES_SECONDARY_VIEW_DRAWER(
                          seatId,
                          adSourceId,
                          convertSecondaryViewToURL(secondaryDetailsView)
                      )
            );
        }
    };

    const closeDrawerWhithoutHistoryChange = () => {
        dispatch(closeDrawer());
    };

    const closeDrawerBackToPage = () => {
        dispatch(closeDrawer());
        if (
            location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.DEALS) &&
            location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.COPY)
        ) {
            history.push(ROUTE_FORMATTERS.SEAT_DEAL_COPY(seatId, dealId));
            return;
        }
        if (location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.DEALS_CREATE_DEMAND)) {
            history.push(ROUTE_FORMATTERS.SEAT_DEAL_CREATE_TYPE(seatId, dealType));
            return;
        }
        if (location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.PACING)) {
            history.push(ROUTE_FORMATTERS.SEAT_AD_SOURCES_PACING(seatId));
            return;
        }
        if (location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.DETAILS_PAGE)) {
            history.push(ROUTE_FORMATTERS.SEAT_AD_SOURCES_DETAILS(seatId, adSourceId));
            return;
        }
        if (location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.DEALS_HEALTH)) {
            history.push(ROUTE_FORMATTERS.SEAT_DEAL_HEALTH(seatId));
            return;
        }
        if (location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.DEALS)) {
            if (location.pathname.includes(AD_SOURCE_DRAWER_LOCATION.EDIT)) {
                history.push(ROUTE_FORMATTERS.SEAT_DEAL_EDIT(seatId, dealId));
                return;
            }
            if (dealId) {
                history.push(ROUTE_FORMATTERS.SEAT_DEAL_DETAILS(seatId, dealId));
                return;
            }
            history.goBack();
            return;
        }
        history.push(ROUTE_FORMATTERS.SEAT_AD_SOURCES_LIST(seatId));
    };

    const closeSeatAdSourcesDetailsDrawer = () => {
        dispatch(closeDrawer());
        history.goBack();
    };

    return {
        isSeatAdSourcesDetailsDrawerOpen,
        openSeatAdSourcesDetailsDrawer,
        closeSeatAdSourcesDetailsDrawer,
        closeDrawerWhithoutHistoryChange,
        closeDrawerBackToPage,
        openSeatAdSourceDetailsPageDrawer,
        openSeadAdSourceDrawerDeals,
        openSeatAdSourceDrawerPacing,
    };
};
