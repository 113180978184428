import { ErrorResponse, NewEncryptionKey, useCreateSeatEncryptionKeyMutation } from "@app/core/services";
import { Modal, notification } from "antd";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";

export const ENCRYPTION_KEY_CREATE_CONFIRMATION_MODAL_PROPS = {
    title: "Confirmation",
    okText: "Create New Key",
    "data-sdet": "encryption-key-create-confirmation-modal",
};
export const ENCRYPTION_KEY_CREATE_WARNING_MODAL_CONTENT = "Proceed to expire previous key and create a new key.";

export const useEncryptionKeyCreateConfirmationModal = (
    onCreateNewKey: (nextEncryptionKey: NewEncryptionKey) => void
) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => setIsOpen(true);

    const onCancel = () => setIsOpen(false);

    const [createNewEncryptionKey, { isLoading }] = useCreateSeatEncryptionKeyMutation();
    const { seatId } = useParams<{ seatId: string }>();

    const onOk = () => {
        createNewEncryptionKey(seatId)
            .unwrap()
            .then((res) => {
                onCreateNewKey(res);
                setIsOpen(false);
            })
            .catch((err) =>
                notification.error({
                    message:
                        (err as ErrorResponse).data?.errorDescription ||
                        (err as ErrorResponse)?.data?.errors?.[0]?.message ||
                        "Something wrong has occurred, please contact your account manager",
                })
            );
    };
    return {
        isOpen,
        handleOpen,
        onCancel,
        onOk,
        isLoading,
    };
};

interface Props {
    onCancel: () => void;
    onOk: () => void;
    isOpen: boolean;
    isLoading: boolean;
}

export const EncryptionKeyCreateConfirmationModal: FC<Props> = ({ onCancel, onOk, isOpen, isLoading }) => {
    return (
        <Modal
            destroyOnClose
            {...ENCRYPTION_KEY_CREATE_CONFIRMATION_MODAL_PROPS}
            open={isOpen}
            onCancel={onCancel}
            onOk={onOk}
            okButtonProps={{ loading: isLoading }}
        >
            {ENCRYPTION_KEY_CREATE_WARNING_MODAL_CONTENT}
        </Modal>
    );
};
