import { FC } from "react";
import { Space } from "antd";
import { css } from "@emotion/css";
import { ChangeLogEntityType } from "@app/core/services";
import { useChangeHistoryTable } from "./useChangeHistoryTable";
import { ChangeLogFilters } from "./ChangeLogFilters/ChangeLogFilters";
import { ChangeLogTable } from "./ChangeLogTable";
import { ErrorContainer } from "@app/core/components";

interface Props {
    entityId: number | undefined;
    entityType: ChangeLogEntityType | null;
    sdet?: string;
}

export const ChangeHistoryTable: FC<Props> = ({ entityId, entityType, sdet = "change-history-table" }) => {
    const { changeLogs, isLoading, error, handleFilterChange } = useChangeHistoryTable(entityId, entityType);

    return (
        <Space
            id="change-history-table"
            direction="vertical"
            className={css`
                width: 100%;
            `}
            data-sdet={sdet}
        >
            <ChangeLogFilters changeLogs={changeLogs} onFiltersChange={handleFilterChange} />
            <ErrorContainer error={error}>
                <ChangeLogTable isLoading={isLoading} changeLogs={changeLogs} />
            </ErrorContainer>
        </Space>
    );
};
