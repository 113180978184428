import { FC } from "react";
import { Select, Form } from "antd";
import { ColProps } from "antd/lib/grid/col";
import { SelectProps } from "antd/lib/select";
import { IMPRESSION_WAIT_TIME } from "@app/features/inventory/HierarchyForms/constants";
import { useAdUnitExtendedImpWaitTime } from "@app/features/inventory/components/FormItems/AdUnitExtendedImpWaitTime/useAdUnitExtendedImpWaitTime";

interface AdUnitExtendedImpWaitTimeProps extends SelectProps {
    name: typeof IMPRESSION_WAIT_TIME;
    wrapperCol?: ColProps;
    labelCol?: ColProps;
    label: string;
}

export const AdUnitExtendedImpWaitTime: FC<AdUnitExtendedImpWaitTimeProps> = ({
    label,
    name,
    wrapperCol,
    labelCol,
    ...rest
}) => {
    const { options, isFetching } = useAdUnitExtendedImpWaitTime();
    return (
        <Form.Item name={name} label={label} labelCol={labelCol} wrapperCol={wrapperCol}>
            <Select
                showSearch
                labelInValue
                options={options}
                loading={isFetching}
                optionFilterProp="label"
                autoClearSearchValue={false}
                {...rest}
            />
        </Form.Item>
    );
};
