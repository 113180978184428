import client from "./client";
import { toQueryString } from "@app/core/services/console/utils";

export const SEAT_ENTITY_TYPE = "Seat";

interface SeatsPicklistParams {
    keyword?: string;
    max?: number;
    page?: number;
}

export interface SeatPicklistItem {
    id: number;
    name: string;
    code: string;
    entityType: typeof SEAT_ENTITY_TYPE;
}

export const getSeatsPicklist = (picklistParams: SeatsPicklistParams = {}) => {
    const params = {
        keyword: "",
        max: 50,
        page: 1,
        ...picklistParams,
    };
    return client.get<SeatPicklistItem[]>(`/platform/resources/seats/picklist;${toQueryString(params)}`, {
        params: { _: Date.now() },
    });
};
