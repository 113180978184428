import { FC } from "react";
import { Checkbox, Col, Form, Input, Row } from "antd";
import { SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useExternalName } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/SiteDetailsSection/Fields/ExternalName/useExternalName";

export const ExternalName: FC = () => {
    const { disabled, extra, onChange, placeholder, required } = useExternalName();
    return (
        <Row gutter={[16, 0]} align="middle">
            <Col xs={24} sm={24} md={24} lg={13} xl={11} xxl={8}>
                <Form.Item
                    extra={extra}
                    name={SUPPLY_FORM_FIELDS.EXTERNAL_NAME.name}
                    label={SUPPLY_FORM_FIELDS.EXTERNAL_NAME.label}
                    rules={[
                        {
                            whitespace: true,
                            required: required,
                            message: SUPPLY_FORM_FIELDS.EXTERNAL_NAME.rulesMessage,
                        },
                    ]}
                >
                    <Input allowClear disabled={disabled} placeholder={placeholder} />
                </Form.Item>
            </Col>
            <Col>
                <Form.Item name={SUPPLY_FORM_FIELDS.OVERRIDE.name} valuePropName="checked">
                    <Checkbox onChange={onChange}>{SUPPLY_FORM_FIELDS.OVERRIDE.label}</Checkbox>
                </Form.Item>
            </Col>
        </Row>
    );
};
