import { Checkbox, Form } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useDealConfigureAdSourceField } from "@app/features/deals/DealForm/DealDemandFormSections/ConfigureAdSourceShownSection/Fields/DealConfigureAdSourceField/useDealConfigureAdSourceField";

export const DealConfigureAdSourceField = () => {
    const { value, handleChange } = useDealConfigureAdSourceField();
    return (
        <Form.Item data-sdet="deal-configure-ad-source-field" name={CREATE_DEAL_FORM_ITEMS_NAME.CONFIGURE_AD_SOURCE}>
            <Checkbox checked={value} value={value} onChange={handleChange}>
                Don’t configure Ad Source
            </Checkbox>
        </Form.Item>
    );
};
