import { FC, useState } from "react";
import { MarketplaceInfo, Targeting } from "@app/core/services/console";
import { useAppSelector } from "@app/core/store";
import { Row, Col, Typography, Alert, Button } from "antd";
import { Operations, TargetingFormKeys } from "../constants";
import { DemandConfigurationTargetingSectionHeader } from "../TargetingSectionHeader/DemandConfigurationTargetingSectionHeader";
import { ReadOnlyTargetingBlocks } from "../ReadOnlyTargeting/ReadOnlyTargetingBlocks";
import { mergeTargeting } from "../ReadOnlyTargeting/helpers";
import { ReadOnlyOperation } from "../ReadOnlyTargeting/ReadOnlyOperation";
import { DemandConfigurationOperatorExplanation } from "../TargetingSectionHeader/DemandConfigurationOperatorExplanation";
import { CreateTargeting } from "../CreateTargeting";
import { toTargetingModePayloadId } from "../helpers";
import { AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { OneLevelEcDetailsDrawer } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/EnrichmentCostSection/EnrichmentCostDetailsDrawer/OneLevelEcDetailsDrawer";
import {
    getAudiencesFromTargetingForm,
    getSegmentsFromTargetingForm,
    getIsRangedCost,
} from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/EnrichmentCostSection/utils";

interface Props {
    additionalTargetingBlocks: Targeting[] | null;
    additionalTargetingFormKey: TargetingFormKeys;
    adSourceTargetingBlocks: Targeting[] | null;
    adSourceTargetingFormKey: TargetingFormKeys;
    setShowExistingAdSourceTargeting: (x: boolean) => void;
    showExistingAdSourceTargeting: boolean;
    adSourceType: AdSourcesForm["type"];
    dealName: MarketplaceInfo["deal"]["name"];
    currencyCode: string;
}

export const EditDemandConfigurationTargeting: FC<Props> = ({
    additionalTargetingBlocks,
    additionalTargetingFormKey,
    adSourceTargetingBlocks,
    adSourceTargetingFormKey,
    setShowExistingAdSourceTargeting,
    showExistingAdSourceTargeting,
    adSourceType,
    dealName,
    currencyCode,
}) => {
    // operation is taken from the ad source targeting key, as the additional targeting key can't be changed
    const operation = useAppSelector(
        (state) => state.targeting.targetingFormsByKey[adSourceTargetingFormKey].targetingOperation
    );
    const isAdditionalTargeting = Boolean(additionalTargetingBlocks?.length);

    const targetingBlocks = useAppSelector(
        (state) => state.targeting.targetingFormsByKey[additionalTargetingFormKey].targetingBlocks
    );
    const dealLevelAudiences = getAudiencesFromTargetingForm(targetingBlocks);
    const dealLevelSegments = getSegmentsFromTargetingForm(targetingBlocks);
    const hasSegmentTargeting = Boolean(dealLevelSegments.length);
    const hasAudienceTargeting = Boolean(dealLevelAudiences.length);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const isRangedCost = getIsRangedCost(adSourceType);

    return (
        <>
            <Row>
                <Col xs={24}>
                    <DemandConfigurationTargetingSectionHeader
                        adSourceTargetingExists={Boolean(adSourceTargetingBlocks?.length)}
                        showExistingAdSourceTargeting={showExistingAdSourceTargeting}
                        onShowExistingAdSourceTargetingChange={setShowExistingAdSourceTargeting}
                    />
                </Col>
            </Row>
            {showExistingAdSourceTargeting && (
                <>
                    <Row style={{ marginBottom: 16 }}>
                        <Col xs={24}>
                            {operation === Operations.Any && (
                                <Typography.Text>
                                    <span>Requests that match </span>
                                    <span style={{ fontWeight: 500 }}>ANY OF</span>
                                    <span> the following criteria...</span>
                                </Typography.Text>
                            )}
                            <ReadOnlyTargetingBlocks
                                targetingBlocks={mergeTargeting(
                                    adSourceTargetingBlocks || [],
                                    toTargetingModePayloadId(operation)
                                )}
                                operation={operation}
                                formKey={adSourceTargetingFormKey}
                            />
                            <div hidden={!isAdditionalTargeting} style={{ marginTop: 16 }}>
                                <ReadOnlyOperation operation={Operations.All} />
                            </div>
                        </Col>
                    </Row>
                </>
            )}
            <Row>
                <Col xs={24}>
                    <div style={{ marginBottom: 16 }}>
                        <DemandConfigurationOperatorExplanation operation={operation} />
                    </div>
                    {(hasAudienceTargeting || hasSegmentTargeting) && (
                        <>
                            <Alert
                                type="info"
                                showIcon
                                banner
                                style={{ alignItems: "baseline", marginBottom: "16px" }}
                                message={
                                    <Typography.Text>
                                        Deal Audience Enrichment Cost CPM will be updated if you target 3rd party data
                                        segments with associated CPM costs{" "}
                                        <Button onClick={() => setIsDrawerOpen(true)} type="link">
                                            View Details
                                        </Button>
                                    </Typography.Text>
                                }
                            />
                            <OneLevelEcDetailsDrawer
                                adSourceCurrencyCode={currencyCode}
                                dealName={dealName || ""}
                                isRangedCost={isRangedCost}
                                mode={"deal"}
                                onClose={() => setIsDrawerOpen(false)}
                                audiences={dealLevelAudiences}
                                segments={dealLevelSegments}
                                visible={isDrawerOpen}
                            />
                        </>
                    )}
                    <CreateTargeting
                        formKey={additionalTargetingFormKey}
                        validationFormKey={adSourceTargetingFormKey}
                        operation={operation}
                        operationReadOnly
                    />
                </Col>
            </Row>
        </>
    );
};
