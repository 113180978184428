import { FC } from "react";
import { Layout } from "antd";
import CustomPixelsPageHeader from "./CustomPixelsPageHeader";
import { CustomPixelsTable } from "./CustomPixelsTable";

export const CustomPixelsPage: FC = () => (
    <>
        <CustomPixelsPageHeader />
        <Layout.Content>
            <CustomPixelsTable />
        </Layout.Content>
    </>
);
