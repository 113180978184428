import { useUserAccess } from "@app/core/auth";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useDeleteSeatBuyerSeatListMutation } from "@app/core/services";
import { Button, Space } from "antd";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { BrandSafetyDeleteModal } from "../BrandSafetyDeleteModal";
import { useBrandSafetyDeleteModal } from "../BrandSafetyDeleteModal/useBrandSafetyDeleteModal";

interface Props {
    seatId: string;
    buyerSeatListId: number;
    name: string;
    postDeleteEvent?: () => void;
}
export const BuyerSeatListsActions: FC<Props> = ({ seatId, buyerSeatListId, name, postDeleteEvent }) => {
    const { canEditSeat } = useUserAccess();
    const history = useHistory();
    const [deleteTrigger, { isLoading: isDeleting }] = useDeleteSeatBuyerSeatListMutation();
    const { handleOpen, ...restModalProps } = useBrandSafetyDeleteModal(deleteTrigger, postDeleteEvent);

    if (!canEditSeat) {
        return null;
    }

    return (
        <>
            <Space>
                <Button
                    data-sdet="edit-button"
                    type="primary"
                    onClick={() =>
                        history.push(
                            ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_EDIT_PAGE(
                                seatId,
                                buyerSeatListId
                            )
                        )
                    }
                >
                    Edit
                </Button>
                <Button
                    danger
                    data-sdet="delete-button"
                    type="primary"
                    onClick={() => {
                        return handleOpen({ id: buyerSeatListId, name });
                    }}
                >
                    Delete
                </Button>
            </Space>
            <BrandSafetyDeleteModal isDeleting={isDeleting} {...restModalProps} />
        </>
    );
};
