import { PlotData } from "../Plot/types";
import { LegendState } from "../Legend";
import { XYSeries } from "../types";

export const getScaledMax = (numbers: number[], scale = 1): number => Math.max(...numbers) * scale;

export const mapXYSeriesDataToPlotData = (
    data: XYSeries[],
    yValueKey: string,
    seriesKey: string,
    seriesFilters?: LegendState
): PlotData[] =>
    data
        ?.map((xYData) =>
            xYData?.yValues.map((yData) => ({
                xValue: xYData.xValue,
                [yValueKey]: yData.yValue,
                [seriesKey]: yData.series,
            }))
        )
        ?.flat(1)
        ?.filter((plotData) => {
            if (seriesFilters) {
                const series = plotData[seriesKey] as string;
                return seriesFilters[series];
            }
            return true;
        });

export const getUniqueSeries = (data: XYSeries[][] = []): string[] => {
    const flatData = data.flat(1);
    const seriesSet = new Set<string>();
    flatData.forEach((xYSeries) =>
        xYSeries?.yValues.forEach((yValue) => {
            if (yValue.series && !seriesSet.has(yValue.series)) {
                seriesSet.add(yValue.series);
            }
        })
    );
    return [...seriesSet.values()];
};

export const backfillMissingSeries = (data: XYSeries[] = [], defaultYValue = 0): XYSeries[] => {
    const completeSeriesSet = new Set(data.map((d) => d.yValues.map((y) => y.series)).flat(1));
    const completeData = data.map((d) => {
        const actualSeriesSet = new Set(d.yValues.map((y) => y.series));
        completeSeriesSet.forEach((series) => {
            if (!actualSeriesSet.has(series)) {
                d.yValues.push({ series, yValue: defaultYValue });
            }
        });
        return d;
    });
    return completeData;
};

export const getCustomTooltipItem = (name: string, value: string) => ({
    name,
    value,
    // required TooltipItem fields, but not needed
    data: {},
    mappingData: {},
    color: "",
    marker: "",
});

export const getXYSeriesAverage = (xYSeries: XYSeries[]): XYSeries | undefined => {
    const xYSeriesLength = xYSeries.length;
    if (xYSeriesLength === 0) {
        return;
    } else if (xYSeriesLength === 1) {
        return xYSeries[0];
    }

    const ySeriesTotalMap = xYSeries
        .map((data) => data.yValues)
        .flat(1)
        .reduce((yValueSums, ySeries) => {
            const series = ySeries.series ?? "";
            if (yValueSums[series]) {
                yValueSums[series] += ySeries.yValue;
            } else {
                yValueSums[series] = ySeries.yValue;
            }
            return yValueSums;
        }, {});

    const ySeriesAverages = Object.keys(ySeriesTotalMap).map((series) => ({
        yValue: ySeriesTotalMap[series] / xYSeries.length,
        series,
    }));

    const mostRecentXYSeries = xYSeries.splice(-1)[0];
    return {
        xValue: mostRecentXYSeries.xValue,
        yValues: ySeriesAverages,
    };
};

export const getWindowedXYSeriesAverages = (xYSeries: XYSeries[], windowSize: number): XYSeries[] =>
    xYSeries.map((data, index) => {
        if (index === 0 || windowSize <= 1) {
            return data;
        }

        let windowStartIndex = index - windowSize + 1;
        if (windowStartIndex < 0) {
            windowStartIndex = 0;
        }
        const windowData = xYSeries.slice(windowStartIndex, index + 1);

        return getXYSeriesAverage(windowData) as XYSeries;
    });
