import { FC, ReactNode } from "react";
import { Collapse } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";

interface Props {
    children: ReactNode;
    extra?: ReactNode;
    header: ReactNode;
}

export const TargetingBlockCollapse: FC<Props> = ({ children, header = null, extra = null }) => {
    return (
        <Collapse
            className={css`
                .ant-collapse-item > .ant-collapse-header {
                    padding: 12px 16px;
                }
                .ant-collapse-expand-icon {
                    align-self: center;
                    margin-top: 4px;
                }
                .ant-collapse-content-box {
                    padding: 16px !important;
                }
            `}
            collapsible="header"
            defaultActiveKey="1"
            expandIcon={({ isActive }) => <RightOutlined style={{ fontSize: "16px" }} rotate={isActive ? 90 : 0} />}
        >
            <Collapse.Panel key="1" header={header} extra={extra}>
                {children}
            </Collapse.Panel>
        </Collapse>
    );
};
