import { FC } from "react";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { AdUnitLSAWindowSizeSeconds } from "@app/features/inventory/components/FormItems";

export const LSAWindowSizeSeconds: FC = () => {
    return (
        <AdUnitLSAWindowSizeSeconds
            name={CHANNEL_FORM_FIELDS.LSA_WINDOW_SIZE_SECONDS.name}
            label={CHANNEL_FORM_FIELDS.LSA_WINDOW_SIZE_SECONDS.label}
            placeholder={CHANNEL_FORM_FIELDS.LSA_WINDOW_SIZE_SECONDS.placeholder}
        />
    );
};
