import { FC, useEffect } from "react";
import { Layout, Space } from "antd";
import { Footer } from "@rubicon/antd-components";
import { useAppDispatch } from "@app/core/store";
import { onPageExit, SeatAdSourcesListDisplayMode } from "./reducer";
import { SeatAdSourcesListToolbar } from "./SeatAdSourcesListToolbar";
import { SeatAdSourcesListFilters } from "./SeatAdSourcesListFilters";
import { SeatAdSourcesListTable } from "./SeatAdSourcesListTable";
import { SeatAdSourcesListGrid } from "./SeatAdSourcesListGrid";
import { useSeatAdSourcesListPage } from "./useSeatAdSourcesListPage";
import { AdSourcesDetailsDrawer } from "../SeatAdSourcesDetailsDrawer/AdSourcesDetailsDrawer";

export const SeatAdSourcesListPage: FC = () => {
    const { activeDisplayMode } = useSeatAdSourcesListPage();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(onPageExit());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderAdSourcesByDisplayMode = () => {
        switch (activeDisplayMode) {
            case SeatAdSourcesListDisplayMode.TableView:
                return <SeatAdSourcesListTable />;
            case SeatAdSourcesListDisplayMode.GridView:
                return <SeatAdSourcesListGrid />;
        }
    };

    return (
        <>
            <SeatAdSourcesListToolbar />
            <Layout.Content data-sdet="seat-ad-sources-list-page">
                <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                    <SeatAdSourcesListFilters />
                    {renderAdSourcesByDisplayMode()}
                </Space>
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
            <AdSourcesDetailsDrawer />
        </>
    );
};
