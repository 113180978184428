import { FC } from "react";
import { Footer, IframeBetaLabel, Loading, NavBar } from "@app/core/components";
import { Layout } from "antd";
import { useSeatAuthContext } from "@app/core/auth";
import { ControlsNavMenu } from "./ControlsNavMenu";
import { ControlsSwitch } from "./ControlsSwitch";
import { FeedbackDrawer } from "@app/core/components/FeedbackDrawer/FeedbackDrawer";

export const Controls: FC = () => {
    const { context, session } = useSeatAuthContext();

    if (!context || !session) {
        return (
            <Layout.Content>
                <Loading />
            </Layout.Content>
        );
    }

    return (
        <>
            <NavBar extra={<FeedbackDrawer name="Controls Beta" />} label={<IframeBetaLabel title="Controls" />} />
            <ControlsNavMenu />
            <ControlsSwitch />
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
