import { Form, FormInstance } from "antd";
import { UseLoginFormData } from "@app/features/login/LoginForm/useLoginFormData";
import { resetPassword } from "@app/core/clients/console";
import { useState } from "react";

enum EMAIL_FORM_FIELDS {
    Email = "email",
}
type EmailFormFiedsValueType = {
    [key in EMAIL_FORM_FIELDS]: string;
};

interface UseForgotPasswordForm {
    form: FormInstance;
    EMAIL_FORM_FIELDS: typeof EMAIL_FORM_FIELDS;
    notification: string | null;
    isLoading: boolean;
    handleFormChange: (values: EmailFormFiedsValueType) => void;
    handleSubmit: () => void;
    onFocus: () => void;
}

export const useForgotPasswordForm = (loginFormData: UseLoginFormData): UseForgotPasswordForm => {
    const { formData, setFormData } = loginFormData;
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [notification, setNotification] = useState<string | null>(null);

    const handleFormChange = (values: EmailFormFiedsValueType): void => {
        for (const [key, value] of Object.entries(values)) {
            setFormData((prevFormData) => ({ ...prevFormData, [key]: value }));
        }
    };

    const handleSubmit = async (): Promise<void> => {
        setIsLoading(true);
        try {
            await resetPassword(formData.email);
            setNotification(
                `Password reset instructions have been sent to ${formData.email}, if the user exists. If you do not receive them, please try again`
            );
        } catch (err) {
            form.setFields([
                {
                    name: EMAIL_FORM_FIELDS.Email,
                    errors: [err?.response?.data?.errorDescription],
                },
            ]);
        }
        setIsLoading(false);
    };

    const onFocus = (): void => {
        setNotification(null);
    };

    return {
        form,
        EMAIL_FORM_FIELDS,
        isLoading,
        notification,
        handleSubmit,
        handleFormChange,
        onFocus,
    };
};
