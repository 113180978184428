import { useAppDispatch } from "@app/core/store";
import { useDealListTableFilters } from "@app/features/deals/DealListPage/DealListTableFilters";
import { useGetSeatAllDealsCTVNextQuery, UIDeal } from "@app/core/services";
import { useParams } from "react-router-dom";
import { setSort } from "@app/features/deals/DealListPage/reducer";
import { getDealTableSort } from "@app/features/deals/helpers";
import { SorterResult } from "antd/lib/table/interface";
import { ReactNode } from "react";

interface UseDealListTable {
    data: UIDeal;
    handleChange: (_: unknown, __: unknown, sorter: SorterResult<TableItem>) => void;
    isFetching: boolean;
    seatId: string;
}

export interface TableItem {
    key: number;
    name: ReactNode;
    sortPriority: ReactNode;
    code: ReactNode;
    networkName: ReactNode;
    entityType: ReactNode;
    sortEndDate: moment.Moment | ReactNode;
    cpmRate: ReactNode;
    statusName: ReactNode;
    externalStatus: ReactNode;
    actions: ReactNode;
    dealOriginName?: ReactNode;
}

export const useDealListTable = (): UseDealListTable => {
    const dispatch = useAppDispatch();
    const { seatAllDealsParams } = useDealListTableFilters();
    const { data, isFetching } = useGetSeatAllDealsCTVNextQuery(seatAllDealsParams);
    const { seatId } = useParams<{ seatId: string }>();

    const handleChange = (_a, _b, sort): void => {
        const { field, order } = sort;
        dispatch(setSort(getDealTableSort(field, order)));
    };

    return {
        handleChange,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data,
        isFetching: isFetching,
        seatId,
    };
};
