import { MarketplaceInfo } from "@app/core/services";
import { ReadonlyControl, SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { Col, Form } from "antd";
import { FC } from "react";
import { useCurrencySelect } from "./useCurrencySelect";

export const CurrencySelect: FC = () => {
    const { currencies, isLoading, isReadonly, onChange } = useCurrencySelect();
    return (
        <Col sm={12}>
            <Form.Item
                data-sdet="deal-currency-field"
                label="Currency"
                rules={[{ required: !isReadonly, message: "Currency is required" }]}
                name={["dealUnderEdit", "currencyType"]}
            >
                {isReadonly ? (
                    <ReadonlyControl<MarketplaceInfo["deal"]["currencyType"]>
                        getLabel={(type) => type?.code || "USD"}
                    />
                ) : (
                    <SelectWithValue
                        showSearch
                        loading={isLoading}
                        options={currencies}
                        optionFilterProp="label"
                        fieldAsLabel={["code"]}
                        fieldAsValue={["id"]}
                        onChange={onChange}
                    />
                )}
            </Form.Item>
        </Col>
    );
};
