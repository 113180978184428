import { FC } from "react";
import { Form, Input, Space, Typography } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAdSourceAdditionalAdvertiserDomainField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceBrandSafetySection/Fields/AdSourceAdditionalAdvertiserDomainField/useAdSourceAdditionalAdvertiserDomainField";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { getAdditionalAdvertiserDomainsValidator } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/BrandSafety/utils";
import { gray7 } from "@rubicon/antd-components";

const { TextArea } = Input;
export const AdSourceAdditionalAdvertiserDomainField: FC = () => {
    const { value, handleChange, handleBlur, isShowingEmptyWarning } = useAdSourceAdditionalAdvertiserDomainField();
    return (
        <Form.Item
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ADDITIONAL_ADVERTISER_DOMAIN}
            data-sdet="ad-source-additional-advertiser-domain-field"
            label={
                <Space>
                    Additional Advertiser Domains
                    <Typography.Text style={{ fontWeight: 400, color: gray7 }}>(enter one per line)</Typography.Text>
                </Space>
            }
            extra={isShowingEmptyWarning ? AD_SOURCE_FIELDS.ADDITIONAL_ADVERTISER_DOMAINS.extra : undefined}
            dependencies={[
                CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE,
                CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_REUSABLE_ADVERTISER_DOMAIN,
            ]}
            rules={[
                getAdditionalAdvertiserDomainsValidator(
                    CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE,
                    CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_REUSABLE_ADVERTISER_DOMAIN
                ),
            ]}
        >
            <TextArea
                onBlur={handleBlur}
                onChange={handleChange}
                rows={4}
                placeholder="Enter domains one per line…"
                value={value}
                allowClear
            />
        </Form.Item>
    );
};
