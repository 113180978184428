import { FC } from "react";
import { ColProps } from "antd/lib/grid/col";
import { PlusOutlined } from "@ant-design/icons";
import { TagRender } from "@app/core/components";
import { useFrequencyCpas } from "./useFrequencyCaps";
import {
    DEFAULT_FILLS,
    MINUTES_IN_MONTH,
    FREQ_CAPS_OPTIONS,
    CHANNEL_FORM_FIELDS,
} from "@app/features/inventory/HierarchyForms/constants";
import { FREQUENCY_CAPPS } from "@app/features/inventory/constants";
import { Button, Col, Form, InputNumber, Row, Select, Space, Typography } from "antd";

const LIMIT_DURATION_MIN = MINUTES_IN_MONTH * 3;

export type FormNameType = typeof FREQUENCY_CAPPS;

interface Props {
    name: FormNameType;
    label: string;
    labelCol?: ColProps;
    wrapperCol?: ColProps;
    totalValueColProps?: ColProps;
    durationValueColProps?: ColProps;
}

const DEFAULT_COL_SIZES = { xs: 24, sm: 24, md: 9, lg: 9, xl: 9, xxl: 9 };
export const FrequencyCaps: FC<Props> = ({
    name,
    label,
    labelCol,
    wrapperCol,
    totalValueColProps = DEFAULT_COL_SIZES,
    durationValueColProps = DEFAULT_COL_SIZES,
}) => {
    const {
        hidden,
        onChange,
        handleAdd,
        totalValue,
        limitMessage,
        durationValue,
        isForbiddenAdd,
        combinedOptions,
        handleChangeTotal,
        durationSelectValue,
        handleChangeDuration,
        handleChangeDurationSelect,
    } = useFrequencyCpas(name);
    return (
        <Form.Item label={label} required labelCol={labelCol} wrapperCol={wrapperCol}>
            <Row style={{ width: "100%" }} gutter={[4, 4]} wrap>
                <Col {...totalValueColProps}>
                    <Form.Item
                        name="totalValue"
                        style={{ marginBottom: 0 }}
                        validateTrigger={["onChange"]}
                        rules={[
                            {
                                required: true,
                                validator(): Promise<void | Error> {
                                    if ((!totalValue || !durationValue) && !combinedOptions.length)
                                        return Promise.reject(new Error("Frequency Caps values are required"));
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <Space.Compact block>
                            <InputNumber
                                value={totalValue}
                                style={{ width: "100%" }}
                                onChange={handleChangeTotal}
                                placeholder={CHANNEL_FORM_FIELDS.ADV_DOMAIN_FREQ_CAPPS.placehoderTota}
                            />
                            <Select
                                showSearch
                                labelInValue
                                optionFilterProp="label"
                                options={[DEFAULT_FILLS]}
                                style={{ width: "120px" }}
                                value={DEFAULT_FILLS.value}
                            />
                        </Space.Compact>
                    </Form.Item>
                </Col>
                <Col>
                    <Typography.Text style={{ lineHeight: "32px" }}>Per</Typography.Text>
                </Col>
                <Col {...durationValueColProps}>
                    <Form.Item
                        style={{ marginBottom: 0 }}
                        name="durationValue"
                        rules={[
                            {
                                validator(_, durationValue): Promise<void | Error> {
                                    if (
                                        durationValue &&
                                        Number(durationValue) * Number(durationSelectValue.value) > LIMIT_DURATION_MIN
                                    )
                                        return Promise.reject(new Error("Max duration can't be over 3 months"));
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <Space.Compact block>
                            <InputNumber
                                placeholder={CHANNEL_FORM_FIELDS.ADV_DOMAIN_FREQ_CAPPS.placehoderDuration}
                                style={{ width: "100%" }}
                                value={durationValue}
                                onChange={handleChangeDuration}
                            />
                            <Select
                                showSearch
                                labelInValue
                                optionFilterProp="label"
                                style={{ width: "120px" }}
                                value={durationSelectValue}
                                options={FREQ_CAPS_OPTIONS}
                                onChange={handleChangeDurationSelect}
                            />
                        </Space.Compact>
                    </Form.Item>
                </Col>
                <Col>
                    <Button
                        block
                        type="link"
                        onClick={handleAdd}
                        icon={<PlusOutlined />}
                        disabled={isForbiddenAdd}
                        style={{ paddingLeft: "0.2rem" }}
                    >
                        Add
                    </Button>
                </Col>
            </Row>
            <Form.Item
                name={name}
                hidden={hidden}
                help={limitMessage}
                validateTrigger={["onChange"]}
                style={{ marginBottom: 0, marginTop: "0.3rem" }}
            >
                <Select
                    allowClear
                    labelInValue
                    open={false}
                    mode="multiple"
                    showSearch={false}
                    onChange={onChange}
                    tagRender={TagRender}
                    options={combinedOptions}
                    style={{ width: "100%" }}
                />
            </Form.Item>
        </Form.Item>
    );
};
