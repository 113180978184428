import { CSSProperties, FC } from "react";
import { Select } from "antd";
import { useViewFilter } from "./useViewFilter";

interface Props {
    style?: CSSProperties;
}

export const ViewFilter: FC<Props> = ({ style }) => {
    const { options, value, handleChangeValue } = useViewFilter();

    return (
        <Select
            data-sdet="view-filter"
            onChange={handleChangeValue}
            optionFilterProp="label"
            options={options}
            showSearch
            style={style}
            value={value}
        />
    );
};
