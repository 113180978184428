import { Form, Radio } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAdSourceAllowAutoscaleField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceAdminOptionsSection/Fields/AdSourceAllowAutoscaleField/useAdSourceAllowAutoscaleField";

export const AdSourceAllowAutoscaleField = () => {
    const { options, value, onChange } = useAdSourceAllowAutoscaleField();
    return (
        <Form.Item
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_AUTOSCALE}
            label="Allow Autoscale"
            data-sdet="ad-source-allow-autoscale-field"
        >
            <Radio.Group options={options} value={value} onChange={onChange} />
        </Form.Item>
    );
};
