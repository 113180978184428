import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import InventoryFloorHierarchicalPage from "../InventoryFloorHierarchicalPage";
import InventoryFloorAdvancedPage from "../InventoryFloorAdvancedPage";

export const InventoryFloorPageSwitch: FC = () => {
    return (
        <Switch>
            <Route path={ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL}>
                <InventoryFloorHierarchicalPage />
            </Route>
            <Route
                exact
                path={[
                    ROUTES.SEAT_INVENTORY_FLOOR_ADVANCED,
                    ROUTES.SEAT_INVENTORY_FLOOR_ADVANCED_DETAILS,
                    ROUTES.SEAT_INVENTORY_FLOOR_ADVANCED_DEFINITION,
                ]}
            >
                <InventoryFloorAdvancedPage />
            </Route>
        </Switch>
    );
};
