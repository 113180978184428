import { FC } from "react";
import { Card, Col, Row, Typography } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import {
    LoadableAnimatedNumberCell,
    SeatAdSourcesActionButtonBar,
    SeatAdSourcesActionButton as ActionButton,
    SeatAdSourcesMoreMenuItem as MoreMenuItem,
    PacingProgressBar,
} from "@app/core/components";
import { AdSourceTypeAcronyms, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { LiveAdSourceListItem } from "@app/features/seatAdSources/data";
import { SeatAdSourceStatusTag } from "@app/features/seatAdSources/SeatAdSourceStatusTag";
import { format } from "@rubicon/utils";
import { SeatAdSourcesListTableNetRevenueCell } from "@app/features/seatAdSources/SeatAdSourcesListPage/SeatAdSourcesListTable/seatAdSourcesListTableCells";
import { useSeatAdSourcesDetailsDrawer } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/useSeatAdSourcesDetailsDrawer";
import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";

interface Props {
    liveAdSource: LiveAdSourceListItem;
}

export const SeatAdSourcesAdSourceCard: FC<Props> = ({ liveAdSource }) => {
    const { openSeatAdSourcesDetailsDrawer } = useSeatAdSourcesDetailsDrawer();
    const { adSource, liveData } = liveAdSource;
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();

    return (
        <div key={adSource.id} data-sdet="seat-ad-sources-ad-source-card">
            <Col>
                <Card
                    title={adSource.name}
                    bordered={true}
                    onClick={() => {
                        openSeatAdSourcesDetailsDrawer(adSource.id);
                        history.push(ROUTE_FORMATTERS.SEAT_AD_SOURCES_DETAILS_DRAWER(seatId, adSource.id));
                    }}
                    extra={
                        <SeatAdSourcesActionButtonBar
                            adSourceId={adSource.id}
                            adSourceTypeId={adSource.type.id}
                            actionButtons={[ActionButton.Edit, ActionButton.Performance]}
                            moreMenuItems={[
                                MoreMenuItem.Copy,
                                MoreMenuItem.CreativeReview,
                                MoreMenuItem.AdServing,
                                MoreMenuItem.VastErrors,
                                MoreMenuItem.Ladle,
                                MoreMenuItem.TimingStats,
                                MoreMenuItem.ChangeHistory,
                            ]}
                        />
                    }
                >
                    <Row justify="space-between">
                        <Col span={6}>
                            <Row>
                                <Typography.Text strong>Tries</Typography.Text>
                            </Row>
                            <Row>
                                <LoadableAnimatedNumberCell value={liveData?.tries} />
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Typography.Text strong>Fills</Typography.Text>
                            </Row>
                            <Row>
                                <LoadableAnimatedNumberCell value={liveData?.fills} />
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Typography.Text strong>Imps.</Typography.Text>
                            </Row>
                            <Row>
                                <LoadableAnimatedNumberCell value={liveData?.impressions} />
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Typography.Text strong>Net Rev.</Typography.Text>
                            </Row>
                            <Row>
                                <SeatAdSourcesListTableNetRevenueCell
                                    netRevenue={liveData?.netRevenue}
                                    currencyCode={liveData?.currencyCode}
                                />
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={14}>
                            {adSource.type?.id === AdSourceTypeIds.AUTOMATED_GUARANTEED ? (
                                <PacingProgressBar pacingData={adSource.adsourcePacingData} />
                            ) : (
                                <div style={{ height: "23px" }} />
                            )}
                        </Col>
                    </Row>
                    <Row justify="space-between" style={{ paddingTop: "10px" }}>
                        <Col>
                            <Typography.Text>
                                <span>Avg. processing time: </span>
                                <LoadableAnimatedNumberCell value={liveData?.time} />
                                <span> ms</span>
                            </Typography.Text>
                        </Col>
                        <Col>
                            <Row>
                                {false &&
                                    liveData?.isAtRisk && ( // TODO: unhide once product determines updated "at risk" business logic
                                        <Col style={{ paddingRight: "0.7em" }}>
                                            <WarningOutlined style={{ color: "#FF4D4F" }} />
                                        </Col>
                                    )}
                                <Col style={{ paddingRight: "0.7em" }}>
                                    {/* TODO: remove AdSourceTypeAcronyms[<id>] abbreviation fallback once API starts returning adSource.type.abbreviation */}
                                    <span>
                                        {adSource.type?.abbreviation ||
                                            AdSourceTypeAcronyms[adSource.type?.id] ||
                                            format.constants.DEFAULT_DASHES}
                                    </span>
                                </Col>
                                <Col>
                                    <SeatAdSourceStatusTag synthesizedStatus={adSource.synthesizedAdSourceStatus} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </div>
    );
};
