import { FC } from "react";
import { useSeatAuthContext } from "@app/core/auth";
import { AdSource } from "@app/core/services";
import { AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import {
    EnrichmentCostSectionInnerCard,
    useAdSourceEnrichmentCost,
} from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/EnrichmentCostSection";
import {
    getAudiencesFromTargetingForm,
    getSegmentsFromTargetingForm,
} from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/EnrichmentCostSection/utils";
import { targetingToTargetingBlock } from "@app/features/targeting";
import { ReadOnlyDemandAcquisitionCostField } from "../../SeatAdSourcesForm/AdSourcesSections/EnrichmentCostSection/ReadOnlyDemandAcquisitionCostField";

interface Props {
    adSource: AdSource;
}

export const SeatAdSourcesEnrichmentCostDetails: FC<Props> = ({ adSource }) => {
    const { context } = useSeatAuthContext();
    const isDemandAcquisitionCostEnabled = Boolean(
        context?.dacEnabled &&
            adSource.type.id !== AdSourceTypeIds.MARKETPLACE &&
            adSource.type.id !== AdSourceTypeIds.CURATOR_MARKETPLACE
    );
    const adSourceDemandAcquisitionCost =
        adSource.demandAcquisitionCost === null ? 0 : adSource.demandAcquisitionCost / 1000;
    const adSourceDemandAcquisitionCostModelId = adSource.demandAcquisitionCostModel?.id;

    const adSourceCpm = adSource.bookingPrice / 1000;

    const {
        adSourceEc,
        adSourceLevelAudiences,
        adSourceLevelSegments,
        dealLevelValues,
        hasEnrichmentCostTargeting,
        isDrawerOpen,
        isRangedCost,
        selectedMarketplace,
        setIsDrawerOpen,
        setSelectedMarketplace,
        shouldUseAdSourceValues,
    } = useAdSourceEnrichmentCost({
        adSourceCpm,
        adSourceCurrencyCode: adSource.currencyType.code,
        adSourceDemandAcquisitionCost: adSourceDemandAcquisitionCost,
        adSourceDemandAcquisitionCostModelId,
        adSourceFloorTypeId: adSource.adSourceFloorType.id,
        adSourceLevelTargeting: adSource.targeting.map(targetingToTargetingBlock),
        adSourceType: adSource.type,
        marketplaceInfoList: adSource.marketplaceInfoList,
    });

    return (
        <EnrichmentCostSectionInnerCard
            adSourceFloorType={adSource.adSourceFloorType}
            adSourceCpm={adSourceCpm}
            adSourceCurrency={adSource.currencyType.code}
            adSourceDemandAcquisitionCost={adSourceDemandAcquisitionCost}
            adSourceDemandAcquisitionCostModelId={adSourceDemandAcquisitionCostModelId}
            adSourceEc={adSourceEc}
            adSourceLevelAudiences={adSourceLevelAudiences}
            adSourceLevelSegments={adSourceLevelSegments}
            adSourceName={adSource.name}
            adSourceType={adSource.type}
            cardBodyStyle={null}
            dealLevelValues={dealLevelValues}
            demandAcquisitionCostField={
                <ReadOnlyDemandAcquisitionCostField
                    dac={adSourceDemandAcquisitionCost}
                    dacModelId={adSourceDemandAcquisitionCostModelId}
                    verticalLayout
                />
            }
            hasEnrichmentCostTargeting={hasEnrichmentCostTargeting}
            isDemandAcquisitionCostEnabled={isDemandAcquisitionCostEnabled}
            isDrawerOpen={isDrawerOpen}
            isRangedCost={isRangedCost}
            selectedDealCurrencyCode={selectedMarketplace?.currencyType?.code || "USD"}
            selectedDealLevelAudiences={getAudiencesFromTargetingForm(
                selectedMarketplace?.targeting.map(targetingToTargetingBlock) || []
            )}
            selectedDealLevelSegments={getSegmentsFromTargetingForm(
                selectedMarketplace?.targeting.map(targetingToTargetingBlock) || []
            )}
            selectedDealName={selectedMarketplace?.deal.name || ""}
            selectedDealRate={Number(selectedMarketplace?.deal.rate || 0)}
            setIsDrawerOpen={setIsDrawerOpen}
            setSelectedDeal={(dealId: string) => {
                setSelectedMarketplace(
                    adSource.marketplaceInfoList?.find((marketplace) => String(marketplace.deal.id) === dealId) || null
                );
                setIsDrawerOpen(true);
            }}
            shouldUseAdSourceValues={shouldUseAdSourceValues}
        />
    );
};
