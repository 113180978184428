import { useGetContentTransparencyRulesQuery } from "@app/core/services";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { TRANSPARENCY_OPTIONS } from "@app/features/deals/constants";
import {
    dealFormAdSourceContentChange,
    selectDealFormAdSourceContentLabelValue,
} from "@app/features/deals/DealForm/reducer";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

interface UseAdSourceContentField {
    isLoading: boolean;
    options: {
        label: string;
        value: string | number;
    }[];
    value: number | string | null;
    onChange: (value: string) => void;
}

export const useAdSourceContentField = (): UseAdSourceContentField => {
    const value = useAppSelector(selectDealFormAdSourceContentLabelValue);
    const dispatch = useAppDispatch();

    const { seatId } = useParams<{ seatId: string }>();
    const { data, isFetching } = useGetContentTransparencyRulesQuery(Number(seatId));

    const options = useMemo(() => {
        const options = (data || []).map(({ name, id }) => ({ label: name, value: String(id) }));
        TRANSPARENCY_OPTIONS.forEach(({ label, value }) => options.push({ label, value }));
        return options;
    }, [data]);

    const onChange = (value: string) => {
        dispatch(dealFormAdSourceContentChange({ value, transparencyRules: data }));
    };

    return {
        isLoading: isFetching,
        options,
        value,
        onChange,
    };
};
