import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { ClassificationsLabelsTab } from "./ClassificationsLabelsTab";
import { ClassificationsLabelDetailsPage } from "./ClassificationsLabelDetailsPage";
import { useUserAccess } from "@app/core/auth";
import { ClassificationsLabelCreatePage } from "./ClassificationsLabelCreatePage";
import { ClassificationsLabelEditPage } from "./ClassificationsLabelEditPage";

export const ClassificationsLabelsSwitch: FC = () => {
    const { canEditSeat } = useUserAccess();

    return (
        <Switch>
            <Route exact path={ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_LABEL_DETAILS_PAGE}>
                <ClassificationsLabelDetailsPage />
            </Route>
            {canEditSeat && (
                <Route exact path={ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_LABEL_CREATE_PAGE}>
                    <ClassificationsLabelCreatePage />
                </Route>
            )}
            {canEditSeat && (
                <Route exact path={ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_LABEL_EDIT_PAGE}>
                    <ClassificationsLabelEditPage />
                </Route>
            )}
            <Route exact path={ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_LABELS}>
                <ClassificationsLabelsTab />
            </Route>
        </Switch>
    );
};
