import { useAppDispatch, useAppSelector } from "@app/core/store";
import { LabeledValue } from "antd/lib/select";
import { setFiltersSeatAdSourcesPriority } from "../../reducer";
import { useGetAdSourcePrioritiesQuery } from "@app/core/services/console/adSources";
import { useMemo } from "react";

interface UseSeatAdSourcesPriorityFilter {
    handleChangeValue: (value: string | number | undefined, option: LabeledValue | LabeledValue[]) => void;
    options: LabeledValue[];
    value: LabeledValue[] | string | null;
    isLoading: boolean;
    areAllOptionsSelected: boolean;
    toggleSelectDeselectAll: () => void;
}

export const useSeatAdSourcesPriorityFilter = (): UseSeatAdSourcesPriorityFilter => {
    const dispatch = useAppDispatch();
    const seatAdSourcesPriority = useAppSelector((state) => state.seatAdSourcesList.filters.seatAdSourcesPriority);
    const { data, isFetching } = useGetAdSourcePrioritiesQuery();
    const options = useMemo(() => (data || []).map((filter) => ({ label: filter.name, value: filter.id })), [data]);

    const handleChangeValue = (value: string | number | undefined, option: LabeledValue[]) => {
        dispatch(setFiltersSeatAdSourcesPriority(option));
    };

    const areAllOptionsSelected = options.length === seatAdSourcesPriority?.length;

    const toggleSelectDeselectAll = () => {
        if (areAllOptionsSelected) {
            handleChangeValue(undefined, []);
        } else {
            handleChangeValue(undefined, options);
        }
    };

    return {
        handleChangeValue,
        options: options,
        value: seatAdSourcesPriority,
        isLoading: isFetching,
        areAllOptionsSelected,
        toggleSelectDeselectAll,
    };
};
