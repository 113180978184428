import { useTokenExchange } from "@app/features";
import { Loading } from "@app/core/components";
import { Redirect } from "react-router-dom";
import { ROUTES } from "@app/core/routing";

export const TokenExchangePage = () => {
    const { loading } = useTokenExchange();
    if (loading) {
        return <Loading />;
    }
    return <Redirect to={ROUTES.LOGIN_FORM_CONTEXT_STEP} />;
};
