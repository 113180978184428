import { FC, useState } from "react";
import { Modal, Form, Input, notification } from "antd";
import { TargetingBlock } from "../types";
import {
    Targeting,
    TargetingCreatePayload,
    TargetingUpdatePayload,
    useCreateSeatReusableTargetingMutation,
} from "@app/core/services";
import { useParams } from "react-router-dom";

interface Props {
    targetingBlock: TargetingBlock;
    targetingBlockIndex: number;
    targetingPayload: TargetingCreatePayload | TargetingUpdatePayload;
    replaceTargetingBlock: (index: number, targeting: Targeting) => void;
    setTargetingBlockIsReusable: (index: number, isReusable: boolean) => void;
    setTargetingBlockIsReusableModalOpen: (index: number, isReusableModalOpen: boolean) => void;
}

export const ReusableTargetingModal: FC<Props> = ({
    targetingBlock,
    targetingBlockIndex,
    targetingPayload,
    replaceTargetingBlock,
    setTargetingBlockIsReusable,
    setTargetingBlockIsReusableModalOpen,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [createSeatReusableTargeting] = useCreateSeatReusableTargetingMutation();
    const { seatId } = useParams<{ seatId?: string }>();
    const handleCancel = () => {
        // Uncheck the reusable targeting box if they don't save
        setTargetingBlockIsReusable(targetingBlockIndex, false);
        setTargetingBlockIsReusableModalOpen(targetingBlockIndex, false);
    };
    const handleOk = () => {
        form.validateFields()
            .then(async (values) => {
                setIsLoading(true);
                targetingPayload.name = values.name;
                try {
                    // Submit targeting payload to reusableTargeting endpoint
                    const targeting = await createSeatReusableTargeting({
                        seatId: Number(seatId),
                        body: targetingPayload,
                    }).unwrap();
                    setIsLoading(false);
                    // Trigger readonly mode
                    replaceTargetingBlock(targetingBlockIndex, targeting);
                } catch (err) {
                    setIsLoading(false);
                    notification.error({
                        message: "Targeting Save Error",
                        description: err?.data?.errorDescription || "Unable to save this targeting block: API error",
                    });
                }
            })
            .catch(() => {
                setIsLoading(false);
                notification.error({
                    message: "Targeting Save Error",
                    description: "Unable to save this targeting block: Form error",
                });
            });
    };
    const [form] = Form.useForm();
    return (
        <Modal
            destroyOnClose={true}
            okText="Save"
            onCancel={handleCancel}
            onOk={handleOk}
            okButtonProps={{ loading: isLoading }}
            open={targetingBlock.isReusableModalOpen}
            title="Make this targeting reusable"
        >
            <Form form={form}>
                <Form.Item
                    name="name"
                    rules={[{ required: true, message: "You must provide a name for this targeting block" }]}
                >
                    <Input placeholder="Enter Name" />
                </Form.Item>
            </Form>
        </Modal>
    );
};
