import { MarketplaceInfo } from "@app/core/services";
import { ReadonlyControl, SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { Form } from "antd";
import { FC } from "react";
import { DEAL_FORM_FIELDS } from "../../../../constants";
import { useEnforcementSelect } from "./useEnforcementSelect";
export const EnforcementSelect: FC = () => {
    const { enforcements, isLoading, isReadonly, isVisible, isDisabled } = useEnforcementSelect();
    if (!isVisible) {
        return null;
    }
    return (
        <Form.Item name={DEAL_FORM_FIELDS.ENFORCEMENTS.name} label={DEAL_FORM_FIELDS.ENFORCEMENTS.label}>
            {isReadonly ? (
                <ReadonlyControl<MarketplaceInfo["enforcement"]> getLabel={(enforcement) => enforcement?.name || ""} />
            ) : (
                <SelectWithValue
                    placeholder="Select…"
                    options={enforcements}
                    loading={isLoading}
                    fieldAsLabel={["name"]}
                    fieldAsValue={["id"]}
                    disabled={isDisabled}
                />
            )}
        </Form.Item>
    );
};
