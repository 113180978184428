import { useParams } from "react-router-dom";
import { SeatReusableTargetingListItem, useGetSeatReusableTargetingQuery } from "@app/core/services";
import { ChangeEvent, useMemo, useState } from "react";
import { SorterResult } from "antd/lib/table/interface";
import { SortOrder } from "antd/es/table/interface";

interface TargetingTableSort {
    sortBy: string;
    sortOrder: "ascend" | "descend" | null;
}

export interface TargetingDataSource extends Pick<SeatReusableTargetingListItem, "id" | "name"> {
    parent: string;
}

interface UseTargetingTable {
    targeting: TargetingDataSource[];
    handleChange: (_: unknown, __: unknown, sorter: SorterResult<TargetingDataSource>) => void;
    handleSearch: (e: ChangeEvent<HTMLInputElement>) => void;
    searchKeyword: string;
    isLoading: boolean;
    seatId: number;
    sort: TargetingTableSort;
}

export const useTargetingTable = (): UseTargetingTable => {
    const { seatId } = useParams<{ seatId: string }>();
    const [sort, setSort] = useState<TargetingTableSort>({ sortBy: "name", sortOrder: "ascend" });
    const [searchKeyword, setSearchKeyword] = useState("");
    const { data = [], isFetching } = useGetSeatReusableTargetingQuery({
        seatId: Number(seatId),
        max: undefined,
        page: undefined,
    });

    const parsedTargeting = useMemo(
        () => data.map(({ id, name, seat }) => ({ id, name, parent: seat?.name as string })),
        [data]
    );

    const targeting = useMemo(
        () =>
            parsedTargeting.filter(({ name, parent }) =>
                [name, parent].find((str: string) => str.toLowerCase().includes(searchKeyword.toLowerCase()))
            ),
        [searchKeyword, parsedTargeting]
    );

    const handleChange = (_a, _b, sort: SorterResult<TargetingDataSource>): void => {
        const { field, order } = sort;

        setSort({ sortBy: field as string, sortOrder: order as SortOrder });
    };

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchKeyword(e.target.value);
    };
    return {
        sort,
        seatId: Number(seatId),
        targeting,
        handleChange,
        handleSearch,
        searchKeyword,
        isLoading: isFetching,
    };
};
