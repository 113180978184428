import { useMemo } from "react";
import { useSeatAuthContext } from "@app/core/auth";
import { useGetSeatPublishersQuery } from "@app/core/services/console";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { setFiltersPublisher } from "@app/features/dashboard/reducer";
import { LabeledValue } from "antd/lib/select";

interface UsePublisherFilter {
    handleChangeValue: (value: LabeledValue[], option: LabeledValue[]) => void;
    isLoading: boolean;
    options: LabeledValue[];
    value: LabeledValue[];
    areAllOptionsSelected: boolean;
    toggleSelectDeselectAll: () => void;
}

export const usePublisherFilter = (): UsePublisherFilter => {
    const dispatch = useAppDispatch();
    const publisherFilter = useAppSelector((state) => state.dashboard.filters.publisher);
    const { context } = useSeatAuthContext();
    const { data, isFetching, isSuccess } = useGetSeatPublishersQuery(context?.id ? { seatId: context.id } : skipToken);

    const handleChangeValue = (id: LabeledValue[], option: LabeledValue | LabeledValue[]) => {
        if (!id) {
            dispatch(setFiltersPublisher([]));
            return;
        }
        // TS wants us to handle the select potentially being multiselect
        if (Array.isArray(option)) {
            dispatch(setFiltersPublisher(option));
            return;
        }
        dispatch(setFiltersPublisher([option]));
    };

    const options = useMemo(
        () =>
            ((isSuccess && data) || []).map((publisher) => ({
                value: publisher.id,
                label: publisher.name,
            })),
        [data, isSuccess]
    );

    const areAllOptionsSelected = options.length === publisherFilter.length;

    const toggleSelectDeselectAll = () => {
        if (areAllOptionsSelected) {
            handleChangeValue([], []);
        } else {
            handleChangeValue(options, options);
        }
    };

    return {
        options,
        isLoading: isFetching,
        value: publisherFilter,
        handleChangeValue,
        areAllOptionsSelected,
        toggleSelectDeselectAll,
    };
};
