import { ClassificationsLabelCreateForm } from "./ClassificationsLabelForm";
import { ClassificationsLabelDefinitionsButtonAndDrawer } from "./ClassificationsLabelsTab/ClassificationsLabelDefinitions";
import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { PageHeader } from "@ant-design/pro-layout";

export const ClassificationsLabelCreatePage = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();

    return (
        <>
            <PageHeader
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_TABS(seatId, "labels"))}
                title="Create New Label"
                extra={<ClassificationsLabelDefinitionsButtonAndDrawer />}
            />
            <ClassificationsLabelCreateForm />
        </>
    );
};
