import { FC } from "react";
import { ROUTES } from "@app/core/routing";
import { Route, Switch } from "react-router-dom";
import { useUserAccess } from "@app/core/auth";
import { SeatFloorsPage } from "@app/features/inventory/InventorySeat/SeatFloorsPage";
import { SeatDetailsPage } from "@app/features/inventory/InventorySeat/SeatDetailsPage";
import { DefaultPricesPage } from "@app/features/inventory/InventorySeat/SeatDefaultPricesPage";
import { AdvancedFeaturesPage } from "@app/features/inventory/InventorySeat/AdvancedFeaturesPage";
import { ExchangeProtectionsPage } from "@app/features/inventory/InventorySeat/ExchangeProtectionsPage";
import { InternalContactsPage } from "@app/features/inventory/InventorySeat/InventorySeatPage/InternalContactsPage";

export const InventorySeatPageSwitch: FC = () => {
    const { isSysAdmin, isPubAcctMgr, isSysReadOnly } = useUserAccess();
    const isInternalOnly: boolean = isSysAdmin || isPubAcctMgr;
    const isDefaultPricesVisible: boolean = isInternalOnly || isSysReadOnly;
    return (
        <Switch>
            <Route exact path={ROUTES.SEAT_INVENTORY_SEAT_DETAILS}>
                <SeatDetailsPage />
            </Route>
            <Route exact path={ROUTES.SEAT_INVENTORY_SEAT_FLOORS}>
                <SeatFloorsPage />
            </Route>
            {isDefaultPricesVisible && (
                <Route exact path={ROUTES.SEAT_INVENTORY_SEAT_PRICES}>
                    <DefaultPricesPage />
                </Route>
            )}
            {isInternalOnly && (
                <Route exact path={ROUTES.SEAT_INVENTORY_SEAT_PROTECTIONS}>
                    <ExchangeProtectionsPage />
                </Route>
            )}
            <Route exact path={ROUTES.SEAT_INVENTORY_SEAT_ADVANCED}>
                <AdvancedFeaturesPage />
            </Route>
            {isInternalOnly && (
                <Route
                    exact
                    path={[
                        ROUTES.SEAT_INVENTORY_SEAT_INTERNAL,
                        ROUTES.SEAT_INVENTORY_SEAT_EDIT_INTERNAL,
                        ROUTES.SEAT_INVENTORY_SEAT_INTERNAL_DEFINITION,
                    ]}
                >
                    <InternalContactsPage />
                </Route>
            )}
        </Switch>
    );
};
