import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useSeatAdSourcesPriorityFilter } from "../SeatAdSourcesListFilters/SeatAdSourcesPriorityFilter/useSeatAdSourcesPriorityFilter";
import {
    resetGridViewPriorityPagination,
    selectSeatAdSourcesFilters,
    selectSeatAdSourcesListGridHasAdSources,
    selectSeatAdSourcesListGridViewAllFilteredPrioritiesLoaded as selectAllFilteredPrioritiesLoaded,
    selectSeatAdSourcesListGridViewPriorities as selectFilteredPriorityCandidates,
    selectSeatAdSourcesSearchFilter,
    setGridViewPriorityCandidates,
} from "../reducer";

interface UseSeatAdSourcesListGrid {
    filteredPriorityCandidates: number[];
    allFilteredPrioritiesLoaded: boolean;
    hasAdSourcesToDisplay: boolean;
}

export const useSeatAdSourcesListGrid = (): UseSeatAdSourcesListGrid => {
    const dispatch = useAppDispatch();
    const { options } = useSeatAdSourcesPriorityFilter();
    const filteredPriorityCandidates = useAppSelector(selectFilteredPriorityCandidates);
    const allFilteredPrioritiesLoaded = useAppSelector(selectAllFilteredPrioritiesLoaded);
    const hasAdSourcesToDisplay = useAppSelector(selectSeatAdSourcesListGridHasAdSources);

    const filters = useAppSelector(selectSeatAdSourcesFilters);
    const searchFilter = useAppSelector(selectSeatAdSourcesSearchFilter);

    useEffect(() => {
        dispatch(resetGridViewPriorityPagination());
    }, [filters, searchFilter, dispatch]);

    useEffect(() => {
        if (options) {
            dispatch(setGridViewPriorityCandidates({ availablePriorities: options }));
        }
    }, [options, dispatch]);

    return {
        filteredPriorityCandidates,
        allFilteredPrioritiesLoaded,
        hasAdSourcesToDisplay,
    };
};
