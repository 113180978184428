import { MEDIA_URLS, diagnosticsApi, CREATIVE_DETAILS } from "./diagnostics";

interface MediaUrl {
    url: string;
    contentType: "video/mp4" | "video/quicktime";
    lastSeen: number;
    naps: string[];
}

type LoudnessSample = {
    loudness: number;
    clipTime: number;
};
export interface CreativeDetails {
    key: string;
    format: string;
    formatName: string;
    duration: number;
    bitRate: number;
    width: number;
    height: number;
    codec: string;
    codecName: string;
    framerate: number;
    videoBitRate: number;
    audioCodec: string;
    audioCodecName: string;
    audioBitRate: number;
    audioChannels: number;
    mediaType: string;
    loudness: number;
    loudnessSamples: LoudnessSample[];
}

export const mediaUrlsApi = diagnosticsApi.injectEndpoints({
    endpoints: (builder) => ({
        getMediaUrl: builder.query<MediaUrl, string>({
            query: (uhash) => `mediaUrls/${uhash}`,
            providesTags: (_, __, uhash) => [{ type: MEDIA_URLS, uhash }],
        }),
        getCreativeDetails: builder.query<CreativeDetails, { uhash: string; sampleTime: number }>({
            query: ({ uhash, sampleTime }) => `mediaUrls/${uhash}/${sampleTime}/creativeDetails`,
            providesTags: (_, __, { uhash, sampleTime }) => [{ type: CREATIVE_DETAILS, uhash, sampleTime }],
        }),
    }),
    overrideExisting: false,
});

export const { useGetMediaUrlQuery, useGetCreativeDetailsQuery } = mediaUrlsApi;
