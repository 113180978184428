import {
    LegendOptions,
    LineChartOptions,
    LineChartProps,
    SLIDING_AVERAGE_WINDOW,
    useSlidingXYSeriesAverages,
    X_AXIS_TICK_COUNT,
    Y_AXIS_TITLE_TEXT_EVENTS_PER_SECOND,
} from "@app/features/seatAdSources/seatAdSourcesCharts/charts";
import { useConnectLiveAdSourceAdServingAdStatPerSecond } from "../../data";
import { adServingColorMapper, AdServingColors } from "../colors";
import { AdServingLegendTextMap } from "../legendText";
import { LoadingState } from "@app/core/components";
import moment from "moment-timezone";

const chartOptions: LineChartOptions = {
    animation: false,
    color: adServingColorMapper,
    yAxis: {
        title: {
            text: Y_AXIS_TITLE_TEXT_EVENTS_PER_SECOND,
        },
    },
    point: {
        shape: "circle",
    },
} as const;

const legendOptions: LegendOptions = {
    textMap: AdServingLegendTextMap,
    colorMap: AdServingColors,
    layout: "vertical",
} as const;

interface UseSeatAdSourcesLiveOpportunityChart {
    loadingState: LoadingState;
    lineChartProps: LineChartProps | undefined;
}

export const useSeatAdSourcesLiveOpportunityChart = (adSourceId: number): UseSeatAdSourcesLiveOpportunityChart => {
    const { loadingState, data } = useConnectLiveAdSourceAdServingAdStatPerSecond(adSourceId);

    const liveData = data
        ? {
              xValue: moment(data.nTime).format("HH:mm:ss"),
              yValues: data.results.map((result) => ({
                  yValue: result.events,
                  series: result.code,
              })),
          }
        : undefined;

    const slidingAdServingAverages = useSlidingXYSeriesAverages(liveData, X_AXIS_TICK_COUNT, SLIDING_AVERAGE_WINDOW);

    return {
        loadingState,
        lineChartProps:
            loadingState === "loaded"
                ? {
                      id: "seat-ad-sources-live-opportunity-chart",
                      data: slidingAdServingAverages,
                      chartOptions,
                      legendOptions,
                  }
                : undefined,
    };
};
