import { ChangeEvent, useState, useMemo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { selectDealSearchFilter, setSearchFilter } from "@app/features/deals/DealListPage/reducer";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { TableSearchInputProps } from "@app/core/components";

export const useDealListTableFilterSearch = (): TableSearchInputProps => {
    const dispatch = useAppDispatch();
    const { searchKeyword } = useAppSelector(selectDealSearchFilter);
    const [value, setValue] = useState(searchKeyword);

    const handleSearch = useCallback(
        (v: string): void => {
            dispatch(setSearchFilter({ searchKeyword: v.trim() }));
        },
        [dispatch]
    );

    const debouncedHandleSearch = useMemo(
        () => debounce((v: string) => handleSearch(v), FILTER_INPUT_DEBOUNCE_TIME),
        [handleSearch]
    );

    const onReset = (): void => setValue("");

    const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setValue(e?.target?.value);
        debouncedHandleSearch(e?.target?.value);
    };

    return {
        dataSdet: "deal-list-table-filter-search",
        entityType: "Deal",
        onChange,
        onReset,
        value,
    };
};
