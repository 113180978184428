import { getUsedDimensionsDefault } from "../../reducer";
import { Targeting, TargetingIncludeExclude } from "@app/core/services/console";
import { Conditions } from "../../constants";
import { TargetingDimension, TargetingBlock, UsedDimensions } from "../../types";

const hasIncludeExcludeTargeting =
    (includeExcludeKey: "include" | "exclude") =>
    (targetingPayload: Targeting, targetingKey: keyof TargetingIncludeExclude) =>
        Boolean((targetingPayload?.[includeExcludeKey]?.[targetingKey] as unknown[])?.length);

export const addIncludeExcludesDimensions = <ValuesType extends TargetingIncludeExclude[keyof TargetingIncludeExclude]>(
    targetingPayload: Targeting,
    targetingBlock: TargetingBlock,
    targetingKey: keyof TargetingIncludeExclude,
    dimensionCreator: (values: ValuesType, condition: Conditions, usedDimensions: UsedDimensions) => TargetingDimension,
    hasIncludeTargeting: (
        targetingPayload: Targeting,
        targetingKey: keyof TargetingIncludeExclude
    ) => boolean = hasIncludeExcludeTargeting("include"),
    hasExcludeTargeting: (
        targetingPayload: Targeting,
        targetingKey: keyof TargetingIncludeExclude
    ) => boolean = hasIncludeExcludeTargeting("exclude")
) => {
    if (hasIncludeTargeting(targetingPayload, targetingKey)) {
        const includesValues = targetingPayload.include?.[targetingKey] as ValuesType;
        targetingBlock.dimensions.push(
            dimensionCreator(includesValues, Conditions.Includes, targetingBlock.usedDimensions)
        );
    }

    if (hasExcludeTargeting(targetingPayload, targetingKey)) {
        const excludesValues = targetingPayload.exclude?.[targetingKey] as ValuesType;
        targetingBlock.dimensions.push(
            dimensionCreator(excludesValues, Conditions.Excludes, targetingBlock.usedDimensions)
        );
    }
};

export const addMultiSourceIncludeExcludesDimensions = (
    targetingPayload: Targeting,
    targetingBlock: TargetingBlock,
    dimensionCreator: (
        includeExclude: TargetingIncludeExclude,
        condition: Conditions,
        usedDimensions: UsedDimensions
    ) => TargetingDimension,
    hasIncludeTargeting: (targetingPayload: Targeting) => boolean,
    hasExcludeTargeting: (targetingPayload: Targeting) => boolean
) => {
    if (hasIncludeTargeting(targetingPayload) && targetingPayload.include) {
        targetingBlock.dimensions.push(
            dimensionCreator(targetingPayload.include, Conditions.Includes, targetingBlock.usedDimensions)
        );
    }

    if (hasExcludeTargeting(targetingPayload) && targetingPayload.exclude) {
        targetingBlock.dimensions.push(
            dimensionCreator(targetingPayload.exclude, Conditions.Excludes, targetingBlock.usedDimensions)
        );
    }
};

export const getTargetingBlockDefault = (): TargetingBlock => {
    return {
        id: null,
        name: null,
        isReusable: false,
        isReusableModalOpen: false,
        dimensions: [],
        usedDimensions: getUsedDimensionsDefault(),
    };
};
