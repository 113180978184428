import { FC } from "react";
import { LineChart } from "@app/features/seatAdSources/seatAdSourcesCharts/charts";
import { VastErrors } from "@app/core/services";
import { useSeatAdSourcesVastErrorsChart } from "./useSeatAdSourcesVastErrorsChart";

interface Props {
    vastErrors: VastErrors[] | undefined;
}

export const SeatAdSourcesVastErrorsChart: FC<Props> = ({ vastErrors }) => {
    const lineChartProps = useSeatAdSourcesVastErrorsChart(vastErrors);

    return <LineChart {...lineChartProps} />;
};
