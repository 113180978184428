import { AdSourceTypes } from "@app/features/seatAdSources/constants";

export const isTagURLVisible = (adSourceTypeName: string) => {
    switch (adSourceTypeName) {
        case AdSourceTypes.SERVER_SIDE_TAG_GUARANTEED:
        case AdSourceTypes.CLIENT_SIDE_TAG_GUARANTEED:
        case AdSourceTypes.SERVER_SIDE_DYNAMIC_PRICE:
        case AdSourceTypes.SERVER_SIDE_TAG_NON_GUARANTEED:
        case AdSourceTypes.FALLBACK_TAG:
        case AdSourceTypes.PREBID_AD_SOURCE:
            return true;
        default:
            return false;
    }
};
