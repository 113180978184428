import { consoleApi, GEOGRAPHIES_TAG, GEOGRAPHIES_COUNTRIES_TAG } from "./console";
import { toQueryString } from "./utils";
import { GeoTarget } from "./targeting";

export interface GetGeographiesParams {
    page?: number;
    max?: number;
    keyword?: string;
    filter?: "" | "country" | "state" | "postal" | "dma";
}

export interface ValidatePostalCodeParams {
    body: string[];
    countryCode?: string;
}

export const geographiesApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getGeographies: builder.query<GeoTarget[], GetGeographiesParams | void>({
            query: (args: GetGeographiesParams = {}) => {
                const params = {
                    page: 1,
                    max: 100,
                    keyword: "",
                    ...args,
                };
                return `/geographies;${toQueryString(params)}`;
            },
            providesTags: () => [GEOGRAPHIES_TAG],
        }),
        getGeographiesCountries: builder.query<GeoTarget[], GetGeographiesParams | void>({
            query: (args: GetGeographiesParams = {}) => {
                const params = {
                    page: 1,
                    max: 100,
                    keyword: "",
                    ...args,
                };
                return `/geographies/countries;${toQueryString(params)}`;
            },
            providesTags: () => [GEOGRAPHIES_COUNTRIES_TAG],
        }),
        validatePostalCodes: builder.mutation<GeoTarget[], ValidatePostalCodeParams>({
            query: (params) => {
                const { body, countryCode = "US" } = params;
                return {
                    url: `/geographies/validatePostalCodes;countryCode=${countryCode}`,
                    method: "POST",
                    body,
                };
            },
        }),
    }),
    overrideExisting: false,
});

export const { useGetGeographiesQuery, useGetGeographiesCountriesQuery, useValidatePostalCodesMutation } =
    geographiesApi;
