import { useGetAdUnitPlaybackMethodsQuery } from "@app/core/services";
import { LabeledValue } from "antd/lib/select";
import { useMemo } from "react";

export const useVideoPlaybacks = () => {
    const { data, isFetching } = useGetAdUnitPlaybackMethodsQuery();

    const options: LabeledValue[] = useMemo(
        () => (data || []).map((playbackMethod) => ({ value: playbackMethod.id, label: playbackMethod.name })),
        [data]
    );

    return { options, isFetching };
};
