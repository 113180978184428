import { FC } from "react";
import { Button, Col, Row, Space } from "antd";
import { LabeledValue } from "antd/lib/select";
import { ResizableDrawer } from "@rubicon/antd-components";
import {
    GroupConditions,
    SegmentRuleIdentifierOperators,
    TargetingDimensionTypeLabels,
} from "@app/features/targeting/constants";
import { SegmentRuleGroup, SegmentRulesTargetingDimension } from "@app/features/targeting/types";
import { ConditionTitle } from "../../ConditionTitle";
import { isSegmentRuleGroupEmpty } from "../helpers";
import { AdvancedRuleDefinitionsAndTipsDrawerButton } from "./AdvancedRuleDefinitionsAndTipsDrawerButton";
import { SegmentRuleGroupRow } from "./SegmentRuleGroupRow";
import { useAdvancedRuleDrawer } from "./useAdvancedRuleDrawer";

interface Props {
    dimension: SegmentRulesTargetingDimension;
    onChangeRuleGroup: (group: SegmentRuleGroup | null) => void;
    onChangeCondition: (path: number[], value: GroupConditions) => void;
    onChangeOperator: (path: number[], value: SegmentRuleIdentifierOperators) => void;
    onChangeValue: (path: number[], value: LabeledValue) => void;
    onClickAddRule: (path: number[]) => void;
    onClickAddRuleGroup: (path: number[]) => void;
    onClickDeleteRule: (path: number[]) => void;
}

export const AdvancedRuleDrawerButton: FC<Props> = ({
    dimension,
    onChangeRuleGroup,
    onChangeCondition,
    onChangeOperator,
    onChangeValue,
    onClickAddRule,
    onClickAddRuleGroup,
    onClickDeleteRule,
}) => {
    const { handleApply, handleCancel, handleOpen, isOpen, isValidationShown } = useAdvancedRuleDrawer(
        onChangeRuleGroup,
        dimension.ruleGroup
    );

    return (
        <>
            <ResizableDrawer
                placement="right"
                width="70%"
                title={`${TargetingDimensionTypeLabels.SegmentRules} - Specify Rule`}
                open={isOpen}
                onClose={handleCancel}
                footer={
                    <Row justify="end" gutter={8}>
                        <Col>
                            <Space>
                                <Button data-sdet="advanced-rule-drawer-cancel" onClick={handleCancel}>
                                    Cancel
                                </Button>
                                <Button data-sdet="advanced-rule-drawer-apply" type="primary" onClick={handleApply}>
                                    Apply
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                }
            >
                <Row align="top" justify="space-between">
                    <Col>
                        <Space align="start">
                            <ConditionTitle label="Segment Rule" condition={dimension.condition} />
                            <Button type="link" size="small" onClick={() => onClickDeleteRule([])}>
                                Clear All
                            </Button>
                        </Space>
                    </Col>
                    <Col>
                        <AdvancedRuleDefinitionsAndTipsDrawerButton />
                    </Col>
                </Row>
                <SegmentRuleGroupRow
                    group={dimension.ruleGroup}
                    isValidationShown={isValidationShown}
                    onChangeCondition={onChangeCondition}
                    onChangeOperator={onChangeOperator}
                    onChangeValue={onChangeValue}
                    onClickAddRule={onClickAddRule}
                    onClickAddRuleGroup={onClickAddRuleGroup}
                    onClickDeleteRule={onClickDeleteRule}
                    path={[]}
                />
            </ResizableDrawer>
            <Button type="link" onClick={() => handleOpen(dimension.ruleGroup)}>
                {isSegmentRuleGroupEmpty(dimension.ruleGroup) ? "Specify" : "Edit"} Rule
            </Button>
        </>
    );
};
