import { AdSource } from "@app/core/services";
import { AD_SOURCE_TYPES_NEXT_UI_NAMES, AdSourceTypes } from "@app/features/seatAdSources/constants";

export const useIsFieldVisible = (adSource: AdSource) => {
    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSource.type.name];

    switch (adSourceTypeName) {
        case AdSourceTypes.AUCTION_PRICE:
        case AdSourceTypes.CURATOR_MARKETPLACE:
        case AdSourceTypes.LINEAR_AUCTION_PRICE:
        case AdSourceTypes.MARKETPLACE:
        case AdSourceTypes.OPEN_AUCTION:
        case AdSourceTypes.PREBID_AD_SOURCE:
            return true;
        default:
            return false;
    }
};
