import { formatNumber } from "@rubicon/utils";
import { getTimingStatsEventCountsByRegion, TimingStats } from "@app/core/services";
import {
    DualAxesChartOptions,
    DualAxesChartProps,
    FlatDualAxesSeriesData,
    getCustomTooltipItem,
    LegendOptions,
    XYSeries,
} from "@app/features/seatAdSources/seatAdSourcesCharts/charts";
import { timingStatsColorMapper, TimingStatsColors } from "../colors";

interface CustomTooltips {
    averageTiming: number;
    runningCount: number;
    runningAverageTiming: number;
}

const getChartOptions = (xValueCustomTooltipMap: Map<string, CustomTooltips>): DualAxesChartOptions => ({
    xAxis: {
        tickCount: 20,
        label: {
            rotate: Math.PI * 1.75,
            offsetY: 30,
            formatter: (v) => `${v} ms`,
        },
    },
    yAxis: {
        yAxisA: {
            title: {
                text: "Number of Events",
            },
        },
        yAxisB: {
            title: {
                text: "Cumulative %",
            },
            label: {
                formatter: (v) => Math.round(Number(v)),
            },
            max: 100,
        },
    },
    tooltip: {
        formatter: (seriesData: FlatDualAxesSeriesData) => {
            const title = `${seriesData.xValue} ms`;
            if (seriesData.yAxisA) {
                return {
                    title,
                    name: seriesData.seriesA ?? "",
                    value: formatNumber.asNumber(seriesData.yAxisA),
                };
            } else {
                return {
                    title,
                    name: seriesData.seriesB ?? "",
                    value: formatNumber.asPercent(seriesData.yAxisB),
                };
            }
        },
        customItems: (originalItems) => {
            const { 0: eventsTooltip, 1: cumulativeEventsTooltip } = originalItems;
            const xValue = eventsTooltip.data.xValue;

            const { averageTiming, runningCount, runningAverageTiming } = xValueCustomTooltipMap.get(
                xValue
            ) as CustomTooltips;

            const prettyAverageTiming = `${formatNumber.asWhole(averageTiming)} ms`;
            const prettyRunningCount = formatNumber.asWhole(runningCount);
            const prettyRunningAverageTiming = `${formatNumber.asWhole(runningAverageTiming)} ms`;

            return [
                eventsTooltip,
                getCustomTooltipItem("Avg. Timing", prettyAverageTiming),
                getCustomTooltipItem("Running Count", prettyRunningCount),
                getCustomTooltipItem("Running Avg. Timing", prettyRunningAverageTiming),
                cumulativeEventsTooltip,
            ];
        },
    },
    geometryOptions: [
        { geometry: "column", color: timingStatsColorMapper },
        { geometry: "line", color: timingStatsColorMapper },
    ],
});

const legendOptions: LegendOptions = {
    showToggleAllButton: false,
    colorMap: TimingStatsColors,
} as const;

export const useSeatAdSourcesTimingStatsChart = (
    timingStats: TimingStats | undefined,
    region: string
): DualAxesChartProps => {
    const numberOfEventsData: XYSeries[] = [];
    const cumulativeEventsData: XYSeries[] = [];

    const xValueCustomTooltipMap = new Map<string, CustomTooltips>();

    if (timingStats?.data?.[region]) {
        const eventCounts = getTimingStatsEventCountsByRegion(timingStats, region);
        eventCounts.forEach((countData) => {
            const {
                millisecondRange,
                eventCount,
                cumulativePercentage,
                averageTiming,
                runningCount,
                runningAverageTiming,
            } = countData;
            numberOfEventsData.push({
                xValue: millisecondRange,
                yValues: [{ yValue: eventCount, series: "# Events" }],
            });
            cumulativeEventsData.push({
                xValue: millisecondRange,
                yValues: [{ yValue: cumulativePercentage, series: "Cumulative # Events" }],
            });

            xValueCustomTooltipMap.set(millisecondRange, {
                averageTiming,
                runningCount,
                runningAverageTiming,
            });
        });
    }

    return {
        id: "seat-ad-sources-timing-stats-chart",
        dataA: numberOfEventsData,
        dataB: cumulativeEventsData,
        chartOptions: getChartOptions(xValueCustomTooltipMap),
        legendOptions,
    };
};
