import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CURRENCIES_BY_CODE } from "@app/core/utils/currencies";
import { CurrencyConversionModes, RevenueTypes } from "@app/core/clients/console";
import { RootState } from "@app/core/store";

export const USD_OPTION_ID = 1;

export interface SeatAdSourcesSettings {
    adSourcesCurrencyConversionMode: CurrencyConversionModes;
    adSourcesPreferredCurrency: number;
}

export interface SeatAdSourcesState {
    settings: {
        shouldUpdate: boolean;
        values: SeatAdSourcesSettings;
    };
    adSourceHasDealChanges: boolean;
}

const initialState: SeatAdSourcesState = {
    settings: {
        shouldUpdate: false,
        values: {
            adSourcesCurrencyConversionMode: CurrencyConversionModes.CONVERT,
            adSourcesPreferredCurrency: USD_OPTION_ID,
        },
    },
    adSourceHasDealChanges: false,
};

const seatAdSourcesSlice = createSlice({
    name: "seatAdSources",
    initialState,
    reducers: {
        setSettingsCurrencyConversionMode: (state, action: PayloadAction<CurrencyConversionModes | null>) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.settings.values.adSourcesCurrencyConversionMode = action.payload;
            state.settings.shouldUpdate = true;
        },
        setSettingsSeatAdSourcesRevenueType: (state, action: PayloadAction<RevenueTypes | null>) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.settings.values.dashboardRevenueType = action.payload;
            state.settings.shouldUpdate = true;
        },
        setSettingsPreferredCurrency: (state, action: PayloadAction<number | null>) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.settings.values.adSourcesPreferredCurrency = action.payload;
            state.settings.shouldUpdate = true;
        },
        setInitialSettings: (state, action: PayloadAction<Partial<SeatAdSourcesSettings>>) => {
            const { adSourcesPreferredCurrency, ...rest } = action.payload;
            state.settings.values = {
                ...state.settings.values,
                ...rest,
            };
            if (adSourcesPreferredCurrency) {
                if (typeof adSourcesPreferredCurrency === "number") {
                    state.settings.values.adSourcesPreferredCurrency = adSourcesPreferredCurrency;
                } else {
                    const currency = CURRENCIES_BY_CODE[adSourcesPreferredCurrency];
                    if (currency) {
                        state.settings.values.adSourcesPreferredCurrency = currency.id;
                    }
                }
            }
            state.settings.shouldUpdate = false;
        },
        setAdSourceHasDealChanges: (state, action: PayloadAction<boolean>) => {
            state.adSourceHasDealChanges = action.payload;
        },
    },
});

export const selectAdSourcesPreferredCurrencyId = (state: RootState): number =>
    state.seatAdSources.settings.values.adSourcesPreferredCurrency;
export const selectAdSourcesCurrencyConversionMode = (state: RootState): CurrencyConversionModes =>
    state.seatAdSources.settings.values.adSourcesCurrencyConversionMode;
export const selectAdSourceHasDealChanges = (state: RootState): boolean => state.seatAdSources.adSourceHasDealChanges;

export const {
    setSettingsCurrencyConversionMode,
    setSettingsSeatAdSourcesRevenueType,
    setSettingsPreferredCurrency,
    setInitialSettings,
    setAdSourceHasDealChanges,
} = seatAdSourcesSlice.actions;

export default seatAdSourcesSlice.reducer;
