import { FC } from "react";
import { Form, Radio } from "antd";
import {
    CHANNEL_FORM_FIELDS,
    INVENTORY_YES_NO_SPECIFIED_OPTIONS,
} from "@app/features/inventory/HierarchyForms/constants";

export const PublisherReAuction: FC = () => {
    return (
        <Form.Item
            name={CHANNEL_FORM_FIELDS.PUBLISHER_RE_AUCTION.name}
            label={CHANNEL_FORM_FIELDS.PUBLISHER_RE_AUCTION.label}
        >
            <Radio.Group size="large" options={INVENTORY_YES_NO_SPECIFIED_OPTIONS} />
        </Form.Item>
    );
};
