import { useAppDispatch, useAppSelector } from "@app/core/store";
import { LabeledValue } from "antd/lib/select";
import { useGetAdSourcePrioritiesQuery } from "@app/core/services/console/adSources";
import { useMemo } from "react";
import { setFiltersDealListPriority } from "@app/features/deals/DealListPage/reducer";

interface UseDealListPriorityFilter {
    handleChange: (value: string | number | undefined, option: LabeledValue | LabeledValue[]) => void;
    options: LabeledValue[];
    value: LabeledValue[] | string | null;
    isLoading: boolean;
}

export const useDealListPriorityFilter = (): UseDealListPriorityFilter => {
    const dispatch = useAppDispatch();
    const filterPriorities = useAppSelector((state) => state.deals.filters.filterPriorities);
    const { data, isFetching } = useGetAdSourcePrioritiesQuery();
    const options = useMemo(() => (data || []).map((filter) => ({ label: filter.name, value: filter.id })), [data]);

    const handleChange = (value: string | number | undefined, option: LabeledValue[]) => {
        dispatch(setFiltersDealListPriority(option));
    };

    return {
        handleChange,
        options: options,
        value: filterPriorities,
        isLoading: isFetching,
    };
};
