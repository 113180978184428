import { ROUTE_FORMATTERS } from "@app/core/routing";
import {
    AdvertiserDomainFormPayload,
    ErrorResponse,
    useGetAdvertiserDomainQuery,
    useGetAdvertiserDomainTypesQuery,
    useUpdateAdvertiserDomainMutation,
} from "@app/core/services";
import { ErrorPage, PageLoader } from "@rubicon/antd-components";
import { Layout, Typography, notification } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { AdvertiserDomainForm, DEFAULT_DOMAIN_TYPE } from "./AdvertiserDomainForm";
import { CONTROLS_ROUTES } from "@app/features/controls/constants";
import { PageHeader } from "@ant-design/pro-layout";

export const ADVERTISER_DOMAIN_EDIT_PAGE_SDET = "advertiser-domain-edit-page";

export const AdvertiserDomainEditPage = () => {
    const history = useHistory();
    const { id, seatId } = useParams<{ id: string; seatId: string }>();
    const { data, isLoading, error } = useGetAdvertiserDomainQuery({ id });
    const { data: domainTypes } = useGetAdvertiserDomainTypesQuery();

    const [updateAdvertiserForm, { isLoading: isLoadingUpdate }] = useUpdateAdvertiserDomainMutation();

    if (isLoading) return <PageLoader />;
    if (error) {
        const { status, data: errorData } = error as ErrorResponse;
        return <ErrorPage code={status} title={errorData?.errorCode} message={errorData?.errorDescription} />;
    }

    const handlePostDelete = () => {
        history.replace(ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.BRAND_SAFETY));
    };
    const handleSubmit = (values: AdvertiserDomainFormPayload) => {
        const advertiserDomains = values.advertiserDomains.split(/\r?\n/);
        const payload = {
            ...values,
            name: values.name.trim(),
            advertiserDomainListType:
                domainTypes?.find(({ id }) => id === values.advertiserDomainListType.id) || DEFAULT_DOMAIN_TYPE,
            advertiserDomains,
        };

        updateAdvertiserForm({ body: payload })
            .unwrap()
            .then((res) => {
                notification.success({
                    message: (
                        <>
                            <Typography.Text strong>{res.name}</Typography.Text> has successfully been updated
                        </>
                    ),
                });
                history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAIN_DETAILS(seatId, id));
            })
            .catch((err) => {
                notification.error({
                    message:
                        err.data?.errorDescription ||
                        err.data?.errorCode ||
                        "Something wrong has occurred, please contact your account manager",
                });
            });
    };

    return (
        <Layout data-sdet={ADVERTISER_DOMAIN_EDIT_PAGE_SDET}>
            <PageHeader
                title={`Edit: ${data?.name}`}
                onBack={() =>
                    history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAIN_DETAILS(seatId, id))
                }
            />
            <AdvertiserDomainForm
                isLoading={isLoadingUpdate}
                initialValues={data}
                handleSubmit={handleSubmit}
                handlePostDelete={handlePostDelete}
            />
        </Layout>
    );
};
