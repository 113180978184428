import { Button, Card, Flex, Form, Input, Layout } from "antd";
import { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FixedSaveButtons } from "@app/core/components";
import { ContentTransparencyRulesFormPayload, useDeleteContentTransparencyRuleMutation } from "@app/core/services";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { CONTROLS_ROUTES } from "@app/features/controls/constants";
import { ContentTransparencyRulesDefinitionsButtonAndDrawer } from "../ContentTransparencyRulesDrawer/ContentTransparencyRulesDefinitionDrawer";
import { NAME_LEN_ERROR_MESSAGE } from "../../AdvertiserDomains/AdvertiserDomainForm/AdvertiserDomainForm";
import { AllowContentSelect } from "./AllowContentSelect";
import { BrandSafetyDeleteModal } from "../../BrandSafetyDeleteModal";
import { useBrandSafetyDeleteModal } from "../../BrandSafetyDeleteModal/useBrandSafetyDeleteModal";

interface Props {
    handleSubmit: (values: ContentTransparencyRulesFormPayload) => void;
    handlePostDelete?: () => void;
    isLoading: boolean;
    initialValues: ContentTransparencyRulesFormPayload;
}

export const CONTENT_TRANSPARENCY_NAME_INPUT_SDET = "content-transparency-name-input";
export const CONTENT_TRANSPARENCY_DESCRIPTION_SDET = "content-transparency-description-field";
export const CONTENT_TRANSPARENCY_ALLOW_FIELDS_SDET = "content-transparency-allow-fields-select";
export const CONTENT_TRANSPARENCY_DELETE_BTN_SDET = "content-transparency-delete-btn";

export const CONTENT_TRANSPARENCY_RULES_CREATE_FORM_INITIAL_VALUES = {
    name: "",
    description: "",
    contentFields: [],
};

export const ContentTransparencyRulesForm: FC<Props> = ({
    isLoading,
    initialValues,
    handleSubmit,
    handlePostDelete,
}) => {
    const [form] = Form.useForm();

    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();
    const [deleteTrigger, { isLoading: isDeleting }] = useDeleteContentTransparencyRuleMutation();
    const { handleOpen: handleDeleteModal, ...restModalProps } = useBrandSafetyDeleteModal(
        deleteTrigger,
        handlePostDelete
    );

    return (
        <>
            <Form
                form={form}
                name="contentTransparencyForm"
                layout="vertical"
                initialValues={initialValues}
                onFinish={handleSubmit}
                wrapperCol={{ xs: 24, md: 12 }}
                scrollToFirstError={{ behavior: "smooth", block: "center" }}
            >
                <Layout.Content>
                    <Card bordered={false} style={{ minHeight: "77vh" }}>
                        <Flex justify="space-between">
                            <Flex flex={1} vertical>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    messageVariables={{ name: "Name" }}
                                    required
                                    rules={[
                                        {
                                            whitespace: true,
                                            required: true,
                                            message: "${name} is required",
                                        },
                                        {
                                            max: 255,
                                            message: NAME_LEN_ERROR_MESSAGE(255),
                                        },
                                    ]}
                                >
                                    <Input data-sdet={CONTENT_TRANSPARENCY_NAME_INPUT_SDET} />
                                </Form.Item>
                                <Form.Item hidden name="id" initialValue={initialValues?.id ?? null} />
                                <Form.Item hidden name="seat" initialValue={{ id: Number(seatId) }} />
                                <Form.Item label="Description" name="description">
                                    <Input.TextArea rows={6} data-sdet={CONTENT_TRANSPARENCY_DESCRIPTION_SDET} />
                                </Form.Item>

                                <Form.Item
                                    label="Allowed Content Fields"
                                    name="contentFields"
                                    messageVariables={{ name: "Content Fields" }}
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: "${name} are required",
                                        },
                                    ]}
                                >
                                    <AllowContentSelect />
                                </Form.Item>
                            </Flex>
                            <ContentTransparencyRulesDefinitionsButtonAndDrawer />
                        </Flex>
                    </Card>
                </Layout.Content>
                <FixedSaveButtons
                    isLoading={isLoading}
                    onCancel={() =>
                        history.push(
                            ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, [
                                CONTROLS_ROUTES.BRAND_SAFETY,
                                CONTROLS_ROUTES.CONTENT_TRANSPARENCY_RULES,
                            ])
                        )
                    }
                    submitLabel={initialValues?.id ? "Save" : "Submit"}
                    suffixAddOn={
                        initialValues?.id ? (
                            <Button
                                data-sdet={CONTENT_TRANSPARENCY_DELETE_BTN_SDET}
                                type="primary"
                                danger
                                onClick={() =>
                                    initialValues?.id &&
                                    initialValues?.name &&
                                    handleDeleteModal({ id: initialValues.id, name: initialValues.name })
                                }
                            >
                                Delete
                            </Button>
                        ) : null
                    }
                />
            </Form>
            <BrandSafetyDeleteModal isDeleting={isDeleting} {...restModalProps} />
        </>
    );
};
