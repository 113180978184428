import { Typography } from "antd";
import { AD_SOURCE_SECTIONS_NAME } from "@app/features/seatAdSources/constants";

const MINUTES_IN = {
    HOUR: 60,
    DAY: 1440,
    WEEK: 10080,
    MONTH: 43200,
};
const pluralChar = "s";

const getFreqCappingTypography = (duration: number, text: string) => (
    <>
        <Typography.Text strong>{duration}&nbsp;</Typography.Text>
        {text}
    </>
);

export const formatTimeDuration = (timeDurationMin: number) => {
    if (timeDurationMin < MINUTES_IN.HOUR) {
        const min = `minute${timeDurationMin === 1 ? "" : pluralChar}`;
        return getFreqCappingTypography(timeDurationMin, min);
    }

    if (timeDurationMin % MINUTES_IN.MONTH === 0) {
        const duration = timeDurationMin / MINUTES_IN.MONTH;
        const month = `month${duration === 1 ? "" : pluralChar}`;
        return getFreqCappingTypography(duration, month);
    }

    if (timeDurationMin % MINUTES_IN.WEEK === 0) {
        const duration = timeDurationMin / MINUTES_IN.WEEK;
        const week = `week${duration === 1 ? "" : pluralChar}`;
        return getFreqCappingTypography(duration, week);
    }

    if (timeDurationMin % MINUTES_IN.DAY === 0) {
        const duration = timeDurationMin / MINUTES_IN.DAY;
        const day = `day${duration === 1 ? "" : pluralChar}`;
        return getFreqCappingTypography(duration, day);
    }

    if (timeDurationMin % MINUTES_IN.HOUR === 0) {
        const duration = timeDurationMin / MINUTES_IN.HOUR;
        const hour = `hour${duration === 1 ? "" : pluralChar}`;
        return getFreqCappingTypography(duration, hour);
    }

    const fullHour = parseInt(String(timeDurationMin / MINUTES_IN.HOUR));
    const mins = timeDurationMin - fullHour * MINUTES_IN.HOUR;
    const hourText = `hour${fullHour === 1 ? "" : pluralChar}`;
    const minText = `minute${mins === 1 ? "" : pluralChar}`;
    return (
        <>
            {getFreqCappingTypography(fullHour, hourText)}
            &nbsp;
            {getFreqCappingTypography(mins, minText)}
        </>
    );
};

//These ids comes from ad source type id, divided by group to determine section header title
const FLOOR_SETTINGS_IDS = [3, 4, 10, 13, 16];
const LEGACY_PRICING_IDS = [6, 12, 2, 15];
const PRICING_IDS = [7, 9, 8, 5];

export const getAdSourceHeaderTitle = (adSourceTypeId: number) => {
    if (FLOOR_SETTINGS_IDS.includes(adSourceTypeId)) {
        return AD_SOURCE_SECTIONS_NAME.FLOOR_SETTINGS;
    }
    if (LEGACY_PRICING_IDS.includes(adSourceTypeId)) {
        return AD_SOURCE_SECTIONS_NAME.LEGACY_PRICING;
    }
    if (PRICING_IDS.includes(adSourceTypeId)) {
        return AD_SOURCE_SECTIONS_NAME.PRICING;
    }
    return AD_SOURCE_SECTIONS_NAME.FLOOR_SETTINGS;
};
