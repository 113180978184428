import { FC } from "react";
import { Form, InputNumber } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAdSourceCostField } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceAdminOptionsSection/Fields/AdSourceCostField/useAdSourceCostField";

export const AdSourceCostField: FC = () => {
    const { value, onChange, label, defaultValue, prefix, handleBlur } = useAdSourceCostField();
    return (
        <Form.Item
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_COST}
            data-sdet="ad-source-cost-field"
            label="Cost"
            help={defaultValue && `Default Cost Value: ${defaultValue}`}
        >
            <InputNumber
                onBlur={handleBlur}
                value={value}
                prefix={prefix}
                onChange={onChange}
                placeholder={label}
                style={{ width: "100%" }}
            />
        </Form.Item>
    );
};
