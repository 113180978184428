import { useEffect } from "react";
import { useAppDispatch } from "@app/core/store";
import {
    InventorySettings,
    setInitialSettings,
} from "@app/features/inventory/InventoryHealth/InventoryHealthPage/reducer";
import { useSeatAuthContext } from "@app/core/auth";

export const useSetInitialSettings = (): void => {
    const dispatch = useAppDispatch();
    const { session, context, fetchSession } = useSeatAuthContext();

    useEffect(() => {
        if (session) {
            dispatch(setInitialSettings(session.user.userPreferenceMap as Partial<InventorySettings>));
        }
    }, [dispatch, session, context]);

    useEffect(() => {
        fetchSession();
    }, [fetchSession]);
};
