import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { RadioChangeEvent } from "antd";
import { dealFormRadioGroupChange } from "@app/features/deals/DealForm/reducer";
import { format } from "@rubicon/utils";

const { YES, NO } = format.constants;

const EXTEND_TIMEOUT_OPTIONS = [
    { label: YES, value: true },
    { label: NO, value: false },
];

interface UseAdSourceExtendedTimeoutField {
    options: typeof EXTEND_TIMEOUT_OPTIONS;
    onChange: (e: RadioChangeEvent) => void;
    value: boolean;
}

export const useAdSourceExtendedTimeoutField = (): UseAdSourceExtendedTimeoutField => {
    const dispatch = useAppDispatch();

    const value = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_EXTEND_TIMEOUT]
    );

    const onChange = (e: RadioChangeEvent): void => {
        const { value } = e.target;
        dispatch(dealFormRadioGroupChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_EXTEND_TIMEOUT, value }));
    };

    return {
        options: EXTEND_TIMEOUT_OPTIONS,
        value,
        onChange,
    };
};
