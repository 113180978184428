import { createSlice } from "@reduxjs/toolkit";

export interface ManageColumnsState {
    isDrawerOpen: boolean;
    // TODO: add state for managing columns in future
}

const initialState: ManageColumnsState = {
    isDrawerOpen: false,
};

export const manageColumnsSlice = createSlice({
    name: "manageColumns",
    initialState,
    reducers: {
        openManageColumns: (state: ManageColumnsState) => {
            state.isDrawerOpen = true;
        },
        closeManageColumns: (state: ManageColumnsState) => {
            state.isDrawerOpen = false;
        },
    },
});

export const { closeManageColumns, openManageColumns } = manageColumnsSlice.actions;

export const manageColumnsReducer = manageColumnsSlice.reducer;
