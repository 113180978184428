import { FC } from "react";
import { MarketplaceInfo } from "@app/core/services";
import { ReadonlyControl, SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { Col, Form } from "antd";
import { useDealOriginSelect } from "./useDealOriginSelect";
import { useUserAccess } from "@app/core/auth";

export const DealOriginSelect: FC = () => {
    const { dealOrigins, isLoading, isReadonly } = useDealOriginSelect();
    const { hasInternalAccess } = useUserAccess();

    if (!hasInternalAccess) {
        return null;
    }

    return (
        <Col sm={12}>
            <Form.Item
                data-sdet="deal-origin-field"
                rules={[{ required: !isReadonly, message: "Deal Origin is required" }]}
                name={["dealUnderEdit", "dealOrigin"]}
                label="Deal Origin"
            >
                {isReadonly ? (
                    <ReadonlyControl<MarketplaceInfo["deal"]["dealOrigin"]> getLabel={(origin) => origin.name} />
                ) : (
                    <SelectWithValue
                        fieldAsLabel={["name"]}
                        fieldAsValue={["id"]}
                        loading={isLoading}
                        optionFilterProp="label" // For internal LabelValue options
                        options={dealOrigins}
                        placeholder="Select an Origin"
                        showSearch
                    />
                )}
            </Form.Item>
        </Col>
    );
};
