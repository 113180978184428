import { useAppDispatch, useAppSelector } from "@app/core/store";
import { LabeledValue } from "antd/lib/select";
import { setFiltersDealListType } from "@app/features/deals/DealListPage/reducer";
import { DEAL_TYPE_OPTIONS } from "@app/features/deals/constants";

interface UseDealListTypeFilter {
    handleChange: (value: LabeledValue[], option: LabeledValue[]) => void;
    options: LabeledValue[];
    value: LabeledValue[];
}

export const useDealListTypeFilter = (): UseDealListTypeFilter => {
    const dispatch = useAppDispatch();
    const dealType = useAppSelector((state) => state.deals.filters.filterDealType);
    const dsp = useAppSelector((state) => state.deals.filters.filterDsp);

    const DEAL_TYPE_OPTIONS_LABEL_VALUE = DEAL_TYPE_OPTIONS.map((option) => ({
        value: option.id,
        label: option.name,
        // option.id === 4 is the legacy deal
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        disabled: dsp.length > 0 && option.id === 4,
    }));

    const handleChange = (value: LabeledValue[], option: LabeledValue[]) => {
        dispatch(setFiltersDealListType(option));
    };

    return {
        options: DEAL_TYPE_OPTIONS_LABEL_VALUE,
        handleChange,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value: dealType,
    };
};
