import { LabeledValue } from "antd/lib/select";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import {
    selectHistoricalChartDealId,
    setHistoricalChartDealId,
} from "@app/core/components/charts/HistoricalChart/reducer";
import { MarketplaceInfo } from "@app/core/services";

interface UseHistoricalChartDealFilter {
    handleChange: (option: LabeledValue) => void;
    options: LabeledValue[];
    value: number | null;
}

export const useHistoricalChartDealFilter = (marketplaceInfoList: MarketplaceInfo[]): UseHistoricalChartDealFilter => {
    const dispatch = useAppDispatch();
    const options: LabeledValue[] = marketplaceInfoList.map((mpi: MarketplaceInfo) => {
        return { key: mpi.deal.id.toString(), value: mpi.deal.id, label: mpi.deal.name };
    });

    const dealId = useAppSelector(selectHistoricalChartDealId);

    const handleChange = (e: LabeledValue): void => {
        if (!e) {
            dispatch(setHistoricalChartDealId(null));
            return;
        }
        dispatch(setHistoricalChartDealId(e.value as number));
    };

    return {
        options,
        handleChange,
        value: dealId,
    };
};
