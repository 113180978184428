import { FC } from "react";
import { AdStat, getImpressionsUseRate } from "@app/features/adStats";
import { useDealCurrencyConversion } from "@app/features/deals/useDealCurrencyConversion";
import { useAppSelector } from "@app/core/store";
import { selectAdStatDealById } from "@app/features/adStats/reducer";
import { useThrottle } from "@app/core/utils";
import { LoadableAnimatedNumberCell } from "@app/core/components";

interface DealHealthTableUseRateCellProps {
    dealId: number;
    sourceCurrencyCode: string | undefined;
}

const DealHealthTableUseRateCell: FC<DealHealthTableUseRateCellProps> = ({ dealId, sourceCurrencyCode }) => {
    const isConnectedToLiveAdStats = useAppSelector((state) => state.adStats.isConnected);
    const { preferredCurrency, currencyConversionMode, targetCurrencyCode } =
        useDealCurrencyConversion(sourceCurrencyCode);

    const { useRate, otime } = useAppSelector((state) => {
        const adStat: AdStat | null = selectAdStatDealById(state, dealId);
        return adStat
            ? { useRate: getImpressionsUseRate(adStat, preferredCurrency, currencyConversionMode), otime: adStat.otime }
            : { useRate: undefined, otime: undefined };
    });
    const throttledUseRate = useThrottle<number | undefined>(useRate, {
        forceUpdateDependencies: [
            otime,
            sourceCurrencyCode,
            preferredCurrency,
            targetCurrencyCode,
            currencyConversionMode,
        ],
    });

    return (
        <LoadableAnimatedNumberCell isLoading={!isConnectedToLiveAdStats} value={throttledUseRate} isPercent={true} />
    );
};

export default DealHealthTableUseRateCell;
