import { useUserAccess } from "@app/core/auth";
import { DefinationField } from "@app/core/components/HelpKeysDrawer";
import { filterDefinitionsFieldsByHelpPathKeys } from "@app/features/inventory/helpers";
import { useBrandFormInstance } from "@app/features/inventory/HierarchyForms/BrandForm/BrandFormInstance";
import { brandHelpKeys, SeatHelpKeys, BrandDrawerTitles } from "@app/features/inventory/InventorySeat/constants";

interface UseBrandFormDefinitionsFields {
    generalDefinitionsFields: DefinationField[];
    brandSafetyDefinitionsFields: DefinationField[];
    intrenalDefinitionsFields: DefinationField[];
    supplyDetailsDefinitionsFields: DefinationField[];
    customPixelsDefinitionsFields: DefinationField[];
    labelsDefinitionsFields: DefinationField[];
    affiliateDefinitionsFields: DefinationField[];
    floorsDefinitionsFields: DefinationField[];
}

const BRAND_SAFETY_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS: string[] = [];

const SUPPLY_DETAILS_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS: string[] = [SeatHelpKeys.BrandAdvertisersAllowList];

const LABELS_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS: string[] = [SeatHelpKeys.GlobalLabels];

export const useBrandFormDefinitionsFields = (): UseBrandFormDefinitionsFields => {
    const { seat } = useBrandFormInstance();
    const isAdvertisersAsAllowListShown = Boolean(seat?.enableAdvertisersWhitelist);
    const { isSysAdmin, hasInternalAccess } = useUserAccess();

    const generalDefinitionsFields: DefinationField[] = brandHelpKeys[BrandDrawerTitles.General];

    const brandSafetyDefinitionsFields: DefinationField[] = isSysAdmin
        ? brandHelpKeys[BrandDrawerTitles.BrandSafety]
        : filterDefinitionsFieldsByHelpPathKeys(
              brandHelpKeys[BrandDrawerTitles.BrandSafety],
              BRAND_SAFETY_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS
          );
    const intrenalDefinitionsFields: DefinationField[] = brandHelpKeys[BrandDrawerTitles.Internal];

    const supplyDetailsDefinitionsFields: DefinationField[] = isAdvertisersAsAllowListShown
        ? brandHelpKeys[BrandDrawerTitles.SupplyDetails]
        : filterDefinitionsFieldsByHelpPathKeys(
              brandHelpKeys[BrandDrawerTitles.SupplyDetails],
              SUPPLY_DETAILS_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS
          );

    const customPixelsDefinitionsFields: DefinationField[] = brandHelpKeys[BrandDrawerTitles.CustomPixels];

    const labelsDefinitionsFields: DefinationField[] = hasInternalAccess
        ? brandHelpKeys[BrandDrawerTitles.Labels]
        : filterDefinitionsFieldsByHelpPathKeys(
              brandHelpKeys[BrandDrawerTitles.Labels],
              LABELS_SECTION_INTERNAL_ONLY_HELP_PATH_KEYS
          );

    const floorsDefinitionsFields: DefinationField[] = brandHelpKeys[BrandDrawerTitles.Floors];
    const affiliateDefinitionsFields: DefinationField[] = brandHelpKeys[BrandDrawerTitles.Affiliate];

    return {
        generalDefinitionsFields,
        brandSafetyDefinitionsFields,
        intrenalDefinitionsFields,
        customPixelsDefinitionsFields,
        labelsDefinitionsFields,
        affiliateDefinitionsFields,
        supplyDetailsDefinitionsFields,
        floorsDefinitionsFields,
    };
};
