import { ApiChangeLog } from "./changeLogs";
import { ChangeLogFields } from "./types";

export interface AttributeMetaData {
    isMarketplaceInfo: boolean;
    isGeo: boolean;
    isUserRole: boolean;
    isTargeting: boolean;
    isNukeStatus: boolean;
    isUserGroupMemberChange: boolean;
    isContentSeries: boolean;
    isContentVideoId: boolean;
    isAffiliateCostValueFixed: boolean;
    isNetworkEndpoint: boolean;
    isBlockDomains: boolean;
    isPriority: boolean;
    isDomainFilter: boolean;
    isBundleIdFilters: boolean;
    isBlocked: boolean;
    isInclude: boolean;
    isExclude: boolean;
    isIncludeOrExclude: boolean;
    isNetworkEndpoints: boolean;
    isNetworkUrl: boolean;
}

export const parseChangeLogChanges = (apiLog: ApiChangeLog): ChangeLogFields => {
    try {
        return JSON.parse(apiLog.changes);
    } catch (error) {
        console.error("Failed to parse Changelog changes.", error);
        return [];
    }
};

export const softIncludes = (apiAttribute: string, searchString: string): boolean => {
    const attributeLowerCaseSpacesIgnored = (apiAttribute || "").replace(" ", "").toLowerCase();
    const searchStringLowerCaseSpacesIgnored = (searchString || "").replace(" ", "").toLowerCase();
    return attributeLowerCaseSpacesIgnored.includes(searchStringLowerCaseSpacesIgnored);
};

export const softIncludesAny = (apiAttribute: string, searchStrings: string[]): boolean => {
    return searchStrings.reduce(
        (includesAny, searchString) => includesAny || softIncludes(apiAttribute, searchString),
        false
    );
};

export const capitalizeFirstLetter = (attribute: string): string => {
    return attribute.charAt(0).toUpperCase() + attribute.slice(1);
};

export const getAttributeName = (apiAttribute: string): string => {
    const nameWithSpaces = apiAttribute
        .split(".")
        .map((s) => capitalizeFirstLetter(s))
        .join(" ");
    return nameWithSpaces;
};

export const getAttributeMetaData = (apiAttribute: string): AttributeMetaData => {
    return {
        isMarketplaceInfo: softIncludes(apiAttribute, "marketplaceInfo"),
        isGeo: softIncludesAny(apiAttribute, ["geoTarget", "geographies"]),
        isUserRole: softIncludes(apiAttribute, "userRole"),
        isTargeting:
            !softIncludesAny(apiAttribute, ["costValueFixed", "buyerSeats", "deal", "contact"]) &&
            softIncludesAny(apiAttribute, [
                "sizes",
                "supplydomaintargets",
                "platforms",
                "operatingsystem",
                "publisher",
                "brand",
                "supply",
                "adunit",
                "seat",
            ]),
        isNukeStatus: softIncludes(apiAttribute, "nuke status"),
        isUserGroupMemberChange: apiAttribute.includes("members"),
        isContentSeries: apiAttribute.includes("contentSeries"),
        isContentVideoId: apiAttribute.includes("videoIds"),
        isAffiliateCostValueFixed: softIncludes(apiAttribute, "affiliateCostValueFixed"),
        isNetworkEndpoint: softIncludesAny(apiAttribute, [
            "network",
            "supportedapi",
            "blockedcreativeattributes",
            "allowedplaybackmethods",
            "internalseatcontacts",
            "seatcontacts",
        ]),
        isBlockDomains: apiAttribute.includes("blockDomains"),
        isPriority: apiAttribute.includes("priority"),
        isDomainFilter: apiAttribute.includes("domainFilters"),
        isBundleIdFilters: apiAttribute.includes("bundleIdFilters"),
        isBlocked: apiAttribute.includes("blocked"),
        isInclude: apiAttribute.includes("include"),
        isExclude: apiAttribute.includes("exclude"),
        isIncludeOrExclude: apiAttribute.includes("include") || apiAttribute.includes("exclude"),
        isNetworkEndpoints: apiAttribute.includes("networkEndpoints"),
        isNetworkUrl: apiAttribute.includes("network") && !softIncludes(apiAttribute, "url"),
    };
};
