import { FC } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { red5 } from "@rubicon/antd-components";
import { Button, Checkbox, Col, Form, Input, Row, Statistic, Typography } from "antd";
import { usePasswordForm } from "@app/features/login/Steps/hooks/usePasswordForm";
import { css } from "@emotion/css";
import { UseLoginFormData } from "../LoginForm/useLoginFormData";

const { Countdown } = Statistic;

const PasswordForm: FC<UseLoginFormData> = (loginFormData) => {
    const { formData } = loginFormData;
    const { handleFormChange, handleSubmit, onFinish, form, userLockOutData, isLoggingIn, PASSWORD_FORM_FIELDS } =
        usePasswordForm(loginFormData);

    const { duration, isUserLockedOut } = userLockOutData;

    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            onValuesChange={handleFormChange}
            initialValues={formData}
            validateTrigger="onBlur"
            layout="vertical"
        >
            <Row>
                <Col xs={24}>
                    <Form.Item
                        label="Password"
                        name={PASSWORD_FORM_FIELDS.Password}
                        rules={[
                            {
                                validator(_, value) {
                                    if (!value) {
                                        return Promise.reject(new Error("Please enter your password"));
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                        help={
                            duration && (
                                <Typography.Paragraph style={{ marginTop: "0.625rem", color: red5 }}>
                                    Your account has been temporarily locked due to too many invalid login attempts
                                    <br />
                                    Contact your account manager to unlock or try again in
                                    <Countdown
                                        onFinish={onFinish}
                                        value={duration}
                                        valueStyle={{ fontSize: "0.875rem", color: red5 }}
                                        style={{ display: "inline-block", paddingLeft: "0.1875rem" }}
                                    />
                                </Typography.Paragraph>
                            )
                        }
                        validateStatus={(duration && "error") || undefined}
                        className={css`
                            margin-bottom: 0.5rem;
                            .ant-form-item-label > label {
                                font-size: 1rem;
                            }
                            .ant-form-item-control-input {
                                min-height: 42px;
                            }
                            .ant-input {
                                min-height: 32px;
                            }
                        `}
                    >
                        <Input.Password
                            autoFocus
                            data-sdet="password"
                            iconRender={() => null}
                            visibilityToggle={{ visible: formData.isPasswordVisible }}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <Form.Item
                        name={PASSWORD_FORM_FIELDS.IsPasswordVisible}
                        valuePropName="checked"
                        style={{ marginBottom: "1rem" }}
                    >
                        <Checkbox data-sdet="show-password">Show Password</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[0, 10]}>
                <Col xs={24}>
                    <Button
                        htmlType="submit"
                        data-sdet="login"
                        block
                        type="primary"
                        disabled={isUserLockedOut}
                        loading={isLoggingIn}
                        className={css`
                            height: 42px;
                            font-weight: 500;
                        `}
                    >
                        Log In
                    </Button>
                </Col>
            </Row>
            <Row justify="space-between" align="bottom" style={{ marginTop: "1.5rem" }}>
                <Col xs={12} data-sdet="having-trouble-logging-in">
                    <Link to={ROUTES.LOGIN_FORM_FORGOT_PASSWORD_STEP}>Having trouble logging in?</Link>
                </Col>
                <Col xs={12} style={{ textAlign: "right" }} data-sdet={formData.email}>
                    <Link to={ROUTES.LOGIN_FORM_EMAIL_STEP}>Not {formData.email}?</Link>
                </Col>
            </Row>
        </Form>
    );
};

export default PasswordForm;
