import {
    consoleApi,
    SEAT_ADVERTISERS_TAG,
    SEAT_DOMAINS_TAG,
    SEAT_ADVERTISER_DETAILS_TAG,
    SEAT_INDUSTRY_DETAILS_TAG,
} from "./console";
import { CommonPageParams, SeatDomain } from "./seats";
import { toQueryString } from "./utils";

interface IdNameEntity {
    id: number;
    name: string;
}

export type Advertiser = {
    advertiserDomains: SeatDomain[];
    advertiserExternalCodes: string[];
    code: string;
    creationDate: string;
    description: string;
    id: number;
    industry: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string;
        seat: {
            id: number;
            name: string;
            code: string;
            entityType: string;
        };
    } | null;
    name: string;
    parentAdvertiser: Advertiser | null;
    seat: {
        id: number;
        name: string;
    };
    updateDate: string;
    isParent: boolean;
};

export type AdvertiserDetails = Advertiser & {
    deleted?: boolean;
    seat: { id: number; name: string; code: string; entityType: string };
};

export type AdvertiserMutationPayload = {
    id: number | null;
    advertiserDomains: { adomain: string }[];
    code?: string;
    description?: string;
    industry: IdNameEntity | null;
    name: string;
    parentAdvertiser?: IdNameEntity | null;
    seat: { id: number };
};

export type AdvertiserTransferPayload = {
    adomains: string[];
    newAdvertiserId: number;
    oldAdvertiserId: number;
};

export const advertisersApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getSeatAdvertisers: builder.query<Advertiser[], { seatId: number; params?: CommonPageParams }>({
            query: ({ seatId, params }) => {
                return `seats/${seatId}/advertisers;${toQueryString(params || {})}`;
            },
            providesTags: [{ type: SEAT_ADVERTISERS_TAG }],
        }),
        getAdvertiserDetails: builder.query<AdvertiserDetails, number>({
            query: (advertiserId) => {
                return `advertisers/${advertiserId}`;
            },
            providesTags: (_, __, advertiserId) => [{ type: SEAT_ADVERTISER_DETAILS_TAG, id: advertiserId }],
        }),
        createAdvertiser: builder.mutation<AdvertiserDetails, { seatId: number; payload: AdvertiserMutationPayload }>({
            query: ({ seatId, payload }) => ({
                url: `seats/${seatId}/advertiser`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: (_, err, { payload }) =>
                err
                    ? []
                    : [
                          { type: SEAT_DOMAINS_TAG },
                          { type: SEAT_ADVERTISERS_TAG },
                          { type: SEAT_INDUSTRY_DETAILS_TAG, id: payload.industry?.id },
                      ],
        }),
        updateAdvertiser: builder.mutation<AdvertiserDetails, { payload: AdvertiserMutationPayload & { id: number } }>({
            query: ({ payload }) => ({
                url: `advertisers/${payload.id}`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: (_, err, { payload }) =>
                err
                    ? []
                    : [
                          { type: SEAT_ADVERTISER_DETAILS_TAG, id: payload.id },
                          { type: SEAT_DOMAINS_TAG },
                          { type: SEAT_ADVERTISERS_TAG },
                          { type: SEAT_INDUSTRY_DETAILS_TAG, id: payload.industry?.id },
                      ],
        }),
        deleteAdvertiser: builder.mutation<void, string | number>({
            query: (advertiserId) => ({
                url: `advertisers/${advertiserId}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, __, advertiserId) => [
                { type: SEAT_ADVERTISER_DETAILS_TAG, id: advertiserId },
                { type: SEAT_DOMAINS_TAG },
                { type: SEAT_ADVERTISERS_TAG },
            ],
        }),
        transferAdvertiser: builder.mutation<void, AdvertiserTransferPayload>({
            query: (body) => ({
                url: "advertisers/transfer",
                method: "PUT",
                body,
            }),
            invalidatesTags: () => [
                { type: SEAT_ADVERTISER_DETAILS_TAG },
                { type: SEAT_DOMAINS_TAG },
                { type: SEAT_ADVERTISERS_TAG },
            ],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetSeatAdvertisersQuery,
    useGetAdvertiserDetailsQuery,
    useCreateAdvertiserMutation,
    useUpdateAdvertiserMutation,
    useDeleteAdvertiserMutation,
    useTransferAdvertiserMutation,
} = advertisersApi;
